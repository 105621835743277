import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import AccountDetailFilterCard from 'components/accounts/AccountDetailFilterCard';
import UploadBalanceHistoryCsvModal from 'components/accounts/UploadBalanceHistoryCsvModal';
import UploadTransactionsCsvModal from 'components/accounts/UploadTransactionsCsvModal';
import Controls from 'components/lib/ui/Controls';
import Dot from 'components/lib/ui/Dot';
import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import Icon from 'components/lib/ui/Icon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';

import { track } from 'lib/analytics/segment';
import useDownloadBalances from 'lib/hooks/accounts/useDownloadBalances';
import useEditBalancesModal from 'lib/hooks/accounts/useEditBalancesModal';
import useFilters from 'lib/hooks/transactions/useFilters';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useModal from 'lib/hooks/useModal';
import useTheme from 'lib/hooks/useTheme';
import { getUpdatedQueryParams, isAnyFilterApplied } from 'lib/transactions/Filters';
import { downloadTransactions } from 'state/transactions/thunks';

import { AccountTypeName } from 'common/constants/accounts';
import { AccountEventNames } from 'common/constants/analytics';
import routes from 'constants/routes';

import type { AccountDetails_GetAccountQuery } from 'common/generated/graphql';

const ACTIVE_FILTER_INDICATOR_DOT_SIZE = 8;

const ChevronDownIcon = styled(Icon).attrs({ name: 'chevron-down', size: 12 })`
  transform: translateY(1px);
`;

const EditButton = styled(DefaultButton)`
  position: relative;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const FilterButton = styled(DefaultButton)`
  position: relative;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const ActiveFilterIndicator = styled(Dot).attrs({ size: ACTIVE_FILTER_INDICATOR_DOT_SIZE })`
  position: absolute;
  right: 28px;
  top: 6px;
`;

type Props = {
  account: NonNullable<AccountDetails_GetAccountQuery['account']>;
  openCopyBalancesModal: () => void;
};

const AccountDetailControls = ({ account, openCopyBalancesModal }: Props) => {
  const isCopyBalanceHistoryEnabled = useIsFeatureFlagOn('copy-balance-history');
  const isInvestmentTransactionsEnabledOn = useIsFeatureFlagOn('investment-transactions-enabled');

  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { downloadBalances } = useDownloadBalances();
  const [UploadTransactionsModal, { open: openUploadTransactionsModal }] = useModal();
  const [UploadBalanceHistoryModal, { open: openUploadBalanceHistoryModal }] = useModal();

  const { id, credential, displayName, isManual } = account;

  const [EditBalancesModal, { open: openEditBalancesModal }] = useEditBalancesModal({
    accountId: id,
    accountName: displayName,
  });

  const institution = account.institution ?? account.credential?.institution;

  const [filters, setFilters] = useFilters({
    onUpdate: (queryParams, override) => {
      const params = getUpdatedQueryParams(queryParams, override);
      const url = `${window.location.pathname}?${queryString.stringify(params)}`;
      // Redirect to the same page, but with the new query params (this is useful because
      // we use this same component in different pages - e.g. cash flow, category/merchant details)
      history.push(url);
    },
  });

  const showDownloadAndUploadTransactions =
    account.type.name !== AccountTypeName.BROKERAGE || isInvestmentTransactionsEnabledOn;
  const showReportIssueItem = !!credential && !!institution;

  const downloadTransactionsCsv = () => dispatch(downloadTransactions({ accounts: [id] }));
  const downloadBalancesCsv = () => downloadBalances([id]);
  const editBalances = () => {
    track(AccountEventNames.EditBalancesButtonClicked);
    openEditBalancesModal();
  };

  const hasFilters = useMemo(() => isAnyFilterApplied(filters), [filters]);

  return (
    <>
      <Controls>
        <OverlayTrigger
          placement="bottom-end"
          overlay={() => (
            <DropdownMenu>
              <DropdownMenuItem linkTo={routes.accounts.accountDetails.edit({ id })}>
                Edit account
              </DropdownMenuItem>
              {showReportIssueItem && (
                <DropdownMenuItem linkTo={routes.settings.institutions()}>
                  Institution settings
                </DropdownMenuItem>
              )}
              {showDownloadAndUploadTransactions && (
                <>
                  <DropdownMenuItem onClick={downloadTransactionsCsv}>
                    Download transactions
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={openUploadTransactionsModal}>
                    Upload transactions
                  </DropdownMenuItem>
                </>
              )}
              {isCopyBalanceHistoryEnabled && (
                <DropdownMenuItem onClick={openCopyBalancesModal}>
                  Copy balance history
                </DropdownMenuItem>
              )}
              <DropdownMenuItem onClick={downloadBalancesCsv}>
                Download balance history
              </DropdownMenuItem>
              <DropdownMenuItem onClick={openUploadBalanceHistoryModal}>
                Upload balance history
              </DropdownMenuItem>
              <DropdownMenuItem onClick={editBalances}>Edit balance history</DropdownMenuItem>
            </DropdownMenu>
          )}
        >
          {({ toggleOpen, isOpen }) => (
            <EditButton onClick={toggleOpen} active={isOpen}>
              Edit <ChevronDownIcon />
            </EditButton>
          )}
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom-end"
          closeOnEscape={false}
          overlay={({ close }) => (
            <Popover>
              <AccountDetailFilterCard
                activeFilters={filters}
                onClickReset={() => {
                  setFilters({}, true);
                  close();
                }}
                onClickApply={(values) => {
                  setFilters(values);
                  close();
                }}
              />
            </Popover>
          )}
        >
          {({ toggleOpen, isOpen }) => (
            <FilterButton onClick={toggleOpen} active={isOpen}>
              Filters <ChevronDownIcon />{' '}
              {hasFilters && <ActiveFilterIndicator color={theme.color.orange} />}
            </FilterButton>
          )}
        </OverlayTrigger>
      </Controls>
      <UploadTransactionsModal>
        <UploadTransactionsCsvModal accountId={id} isManual={isManual} />
      </UploadTransactionsModal>
      <UploadBalanceHistoryModal>
        <UploadBalanceHistoryCsvModal accountId={id} accountName={displayName} />
      </UploadBalanceHistoryModal>
      <EditBalancesModal />
    </>
  );
};

export default AccountDetailControls;
