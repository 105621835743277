import * as React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import ErrorMessage from 'components/errors/ErrorMessage';
import SingleCardPage from 'components/lib/layouts/SingleCardPage';

import routes from 'constants/routes';

const Root = styled(SingleCardPage)`
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const Oops = () => {
  const history = useHistory();
  return (
    <Root name="Oops">
      <ErrorMessage
        buttonText="Back to Dashboard"
        onButtonClick={() => history.push(routes.dashboard())}
      />
    </Root>
  );
};

export default Oops;
