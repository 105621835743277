export const equalsIgnoreCase = (a: string | undefined, b: string | undefined) =>
  a?.toLowerCase() === b?.toLowerCase();

export const includesIgnoreCase = (a: string, b: string) =>
  a.toLowerCase().includes(b.toLowerCase());

export const includesIgnoreCaseAndSpaces = (a: string, b: string) =>
  a.toLowerCase().replace(/\s/g, '').includes(b.toLowerCase().replace(/\s/g, ''));

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const pluralizeArray = (words: string[], separator = 'and') => {
  if (words.length < 3) {
    return words.join(` ${separator} `);
  }

  const joined = words.slice(0, words.length - 1).join(', ');
  return `${joined} ${separator} ${words.slice(-1)}`;
};

export const truncate = (str: string, length: number) => {
  if (str.length <= length) {
    return str;
  }

  return `${str.slice(0, length)}...`;
};

/**
 * Transforms the first word of a given string to lowercase.
 * This function uses a regular expression to identify the first word in a string and
 * converts the initial character to lowercase, leaving the rest of the word unchanged.
 */
export const lowerFirstWordOnly = (str: string) =>
  str.replace(/(\w)(\w+)/, (_, first, rest) => first.toLowerCase() + rest);

export const isEmoji = (str: string) => {
  const regex = /\p{Emoji}/u;
  return regex.test(str);
};
