import { gql } from '@apollo/client';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AdviceItemRow from 'components/advice/AdviceItemRow';
import Empty from 'components/lib/ui/Empty';
import ShowHideToggleDrawer from 'components/lib/ui/ShowHideToggleDrawer';

import useRouteMatch from 'lib/hooks/useRouteMatch';

import routes from 'constants/routes';

import type { AdviceItemListFields } from 'common/generated/graphQlTypes/AdviceItemListFields';

const Root = styled.div``;

const CompletedAdviceItemRow = styled(AdviceItemRow)`
  && {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
    border-radius: 0;
  }
`;

type Props = {
  items: AdviceItemListFields[];
};

const AdviceItemList = ({ items }: Props) => {
  const { push } = useHistory();
  const { params } = useRouteMatch(routes.advice) ?? {};
  const { category } = params ?? {};

  const [incompleteItems, completedItems] = useMemo(
    () => R.partition(({ completedAt }) => !completedAt, items),
    [items],
  );

  const onClickItem = (item: AdviceItemListFields) => () =>
    push(routes.advice.itemDetails({ itemId: item.id, category }));

  return items.length === 0 ? (
    // This shouldn't ever show for real users, mostly for internal testing
    <Empty title="No items" subtitle="There's nothing to show here yet..." />
  ) : (
    <Root>
      {incompleteItems.map((item) => (
        <AdviceItemRow key={item.id} item={item} onClick={onClickItem(item)} />
      ))}
      {completedItems.length > 0 && (
        <ShowHideToggleDrawer
          showText={`Show ${completedItems.length} completed`}
          hideText={`Hide ${completedItems.length} completed`}
        >
          {completedItems.map((item) => (
            <CompletedAdviceItemRow key={item.id} item={item} onClick={onClickItem(item)} />
          ))}
        </ShowHideToggleDrawer>
      )}
    </Root>
  );
};

AdviceItemList.fragments = {
  AdviceItemListFields: gql`
    fragment AdviceItemListFields on AdviceItem {
      id
      ...AdviceItemRowFields
    }
    ${AdviceItemRow.fragments.AdviceItemRowFields}
  `,
};

export default AdviceItemList;
