import * as RA from 'ramda-adjunct';
import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

const DEFAULT_ICON_SIZE_PX = 40;

const Root = styled(FlexContainer).attrs({ center: true, column: true })`
  gap: ${({ theme }) => theme.spacing.xxsmall};
  text-align: center;
  max-width: 50%;
  align-self: center;
`;

const DefaultIcon = styled(Icon).attrs({ size: DEFAULT_ICON_SIZE_PX })`
  color: ${({ theme }) => theme.color.gray};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
`;

type Props = {
  title: string;
  subtitle: string | React.ReactNode;
  className?: string;
  /** Pass a component (e.g. `<Lottie />`) or a valid icon name */
  icon?: React.ReactNode | string;
};

const IconComponent = ({ icon }: Required<Pick<Props, 'icon'>>) => {
  if (React.isValidElement(icon)) {
    return icon;
  } else if (RA.isString(icon)) {
    return <DefaultIcon name={icon} />;
  } else {
    return null;
  }
};

const CardEmpty = ({ title, subtitle, icon, className }: Props) => (
  <Root className={className}>
    {RA.isNotNil(icon) && <IconComponent icon={icon} />}
    <Text weight="medium">{title}</Text>
    {RA.isString(subtitle) ? (
      <Text size="small" color="textLight">
        {subtitle}
      </Text>
    ) : (
      subtitle
    )}
  </Root>
);

export default CardEmpty;
