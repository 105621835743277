import React, { useMemo } from 'react';

import Card from 'components/lib/ui/Card';
import Empty from 'components/lib/ui/Empty';
import TransactionsListContainer from 'components/transactions/TransactionsListContainer';

type Props = {
  goalId: string;
  goalName?: string;
};

const GoalTransactionsCard = ({ goalId, goalName }: Props) => {
  const transactionFilters = useMemo(() => ({ goalId }), [goalId]);

  return (
    <Card title="Transactions">
      <TransactionsListContainer
        hideHeader
        allowBulkUpdate={false}
        transactionFilters={transactionFilters}
        emptyComponent={
          <Empty
            title="No transactions"
            subtitle={`No transactions have been linked to ${goalName ?? 'this goal'} yet.`}
          />
        }
      />
    </Card>
  );
};

export default GoalTransactionsCard;
