import React from 'react';
import styled from 'styled-components';

import Confirmation from 'components/lib/ui/Confirmation';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import useDeleteAccount from 'common/lib/hooks/accounts/useDeleteAccount';
import ApolloClient from 'lib/graphQl/ApolloClient';
import logger from 'lib/logger';

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  background-color: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.default};
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
`;

const List = styled.ul`
  padding-left: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: start;
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const X_CIRCLE_ICON_SIZE_PX = 22;

type Props = {
  account: {
    id: GraphQlUUID;
  };
  onCancel: () => void;
  onDelete: () => void;
};

const DeleteAccountConfirmation = ({ account, onCancel, onDelete }: Props) => {
  const [deleteAccount, { loading }] = useDeleteAccount({
    update() {
      ApolloClient.resetStore();
    },
  });

  const performDelete = async () => {
    const { data, errors } = await deleteAccount(account.id);

    if (data?.deleteAccount?.deleted) {
      onDelete();
    } else {
      logger.error('Account did not delete.', errors, data?.deleteAccount?.errors);
      onCancel();
    }
  };

  return (
    <Confirmation
      title="Are you sure you want to delete account?"
      confirm="Delete account"
      isLoading={loading}
      onCancel={onCancel}
      onConfirm={performDelete}
      useDangerButton
    >
      <Content>
        <Text weight="medium">What will change:</Text>
        <List>
          <ListItem>
            <StyledIcon name="x-circle" size={X_CIRCLE_ICON_SIZE_PX} />
            <Text>All transactions will be deleted</Text>
          </ListItem>
          <ListItem>
            <StyledIcon name="x-circle" size={X_CIRCLE_ICON_SIZE_PX} />
            <Text>All balances will be deleted</Text>
          </ListItem>
          <ListItem>
            <StyledIcon name="x-circle" size={X_CIRCLE_ICON_SIZE_PX} />
            <Text>Account will no longer show in list or net worth</Text>
          </ListItem>
        </List>
      </Content>
    </Confirmation>
  );
};

export default DeleteAccountConfirmation;
