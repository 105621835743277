import type { PaymentMethod } from '@stripe/stripe-js';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import StripeCardInputForm from 'components/lib/external/StripeCardInputForm';
import StripeProvider from 'components/lib/external/StripeProvider';
import { Label } from 'components/lib/form/FormItemContainer';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';
import UpgradeFormPlanOption from 'components/premium/UpgradeFormPlanOption';
import PromoCodeInput from 'components/settings/billing/PromoCodeInput';
import SponsoredSubscriptionBanner from 'components/settings/billing/SponsoredSubscriptionBanner';

import {
  getDaysLeftOfTrial,
  getYearlyBannerText,
  hasSponsoredPlanAvailable,
} from 'common/lib/billing/Billing';
import usePremiumStripeOfferings from 'common/lib/hooks/premium/usePremiumStripeOfferings';
import useTrialStatusQuery from 'common/lib/hooks/premium/useTrialStatusQuery';
import { formatCurrency } from 'common/utils/Currency';
import useCreateSubscription from 'lib/hooks/billing/useCreateSubscription';
import { getTemporaryPromoCode } from 'state/onboarding/selectors';

import * as COPY from 'common/constants/copy';

import { PaymentPeriod } from 'common/generated/graphQlTypes/globalTypes';

import PayWithWalletButton from './PayWithWalletButton';

const Root = styled.div``;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledPlanOption = styled(UpgradeFormPlanOption)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const StyledLabel = styled(Label)`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const PromoCodeLabel = styled(StyledLabel)`
  display: inline-block;
  cursor: pointer;
`;

const StripeLabel = styled(Text)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const LockIcon = styled(Icon).attrs({ name: 'lock', size: 16 })`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const FooterText = styled(Text)`
  display: block;
  margin: 30px 0;
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const SubmitButton = styled(AsyncButton)`
  ${primaryButtonMixin}
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledSponsoredSubscriptionBanner = styled(SponsoredSubscriptionBanner)`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  initialPromoCode?: string;
  onSuccess: () => void;
  className?: string;
  useSubscribeNowButton?: boolean;
};

const PremiumUpgradeForm = ({
  initialPromoCode,
  className,
  useSubscribeNowButton,
  onSuccess,
}: Props) => {
  const { hasPremiumTrialAvailable, trialDurationDays } = useTrialStatusQuery();
  const [promoCode, setPromoCode] = useState(initialPromoCode ?? null);
  const [isPromoCodeOpen, setIsPromoCodeOpen] = useState(initialPromoCode ?? false);

  const {
    plans,
    trialEndsAt,
    promoCodeDescription,
    promoCodeDuration,
    promoCodeDurationInMonths,
    promoCodeError,
    isLoadingInitialData,
    isNetworkRequestInFlight,
  } = usePremiumStripeOfferings({ promoCode });

  const [walletPaymentMethod, setWalletPaymentMethod] = useState<PaymentMethod | null>(null);

  const { createSubscription, loading: isCreateSubscriptionLoading } = useCreateSubscription();

  // Get if user has temporary promo code saved from query param and load it
  const temporaryPromoCode = useSelector(getTemporaryPromoCode);

  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);
  const selectedPlan = plans[selectedPlanIndex];

  const proceedWithPurchase = async (paymentMethod: PaymentMethod | null) => {
    const maybePromoCode = promoCodeDescription ? promoCode : null;
    await createSubscription(paymentMethod, selectedPlan.stripeId ?? '', maybePromoCode);
    onSuccess();
  };

  // Apply a promo code passed as a query param.
  useEffect(() => {
    if (!promoCode && temporaryPromoCode) {
      setPromoCode(temporaryPromoCode);
    }
  }, [promoCode, temporaryPromoCode]);

  useEffect(() => {
    if (walletPaymentMethod) {
      proceedWithPurchase(walletPaymentMethod);
    }
  }, [walletPaymentMethod]);

  // Set default plan when plans are loaded from the backend.
  useEffect(() => {
    const yearlyPlanIndex = R.findIndex(R.propEq('period', PaymentPeriod.YEARLY))(plans);
    setSelectedPlanIndex(yearlyPlanIndex >= 0 ? yearlyPlanIndex : 0);
  }, [plans]);

  const daysLeftOfTrial = useMemo(() => {
    const newTrialEndsAt = selectedPlan?.newTrialEndsAt ?? trialEndsAt;
    return newTrialEndsAt ? getDaysLeftOfTrial(newTrialEndsAt) : trialDurationDays;
  }, [selectedPlan, trialEndsAt, trialDurationDays]);

  const hideCreditCardInput = selectedPlan?.requirePaymentMethod === false;

  let buttonText = hasPremiumTrialAvailable
    ? COPY.PREMIUM.UPGRADE_CTA.TRIAL_AVAILABLE
    : COPY.PREMIUM.UPGRADE_CTA.TRIAL_UNAVAILABLE;

  if (hasSponsoredPlanAvailable(plans ?? [])) {
    buttonText = COPY.PREMIUM.UPGRADE_CTA.HAS_SPONSOR_CODE;
  }

  const shouldDisplayPaymentRequestButton =
    RA.isNotNil(selectedPlan) && R.isNil(walletPaymentMethod);

  return isLoadingInitialData ? (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  ) : (
    <Root className={className}>
      {plans.map(({ pricePerPeriodDollars, discountedPricePerPeriodDollars, period }, index) => (
        <StyledPlanOption
          key={index}
          price={pricePerPeriodDollars}
          discountedPrice={discountedPricePerPeriodDollars}
          duration={promoCodeDuration}
          durationInMonths={promoCodeDurationInMonths}
          period={period}
          isSelected={index === selectedPlanIndex}
          onClick={() => setSelectedPlanIndex(index)}
          promoBadgeText={getYearlyBannerText(plans, period)}
        />
      ))}
      {shouldDisplayPaymentRequestButton && (
        <PayWithWalletButton
          selectedPlanName={selectedPlan?.name}
          selectedPlanAmount={
            selectedPlan?.discountedPricePerPeriodDollars || selectedPlan?.pricePerPeriodDollars
          }
          daysLeftOfTrial={daysLeftOfTrial}
          onPaymentMethodSelected={(method: PaymentMethod) => setWalletPaymentMethod(method)}
        />
      )}
      {!hideCreditCardInput && <StyledLabel>Payment details</StyledLabel>}
      <StripeCardInputForm
        skipPaymentMethod={hideCreditCardInput}
        onSuccess={(paymentMethod) => proceedWithPurchase(paymentMethod)}
      >
        {({ isValid, isLoading: isStripeInputLoading }) => (
          <>
            {!hideCreditCardInput && (
              <StripeLabel>
                <LockIcon />
                Powered by Stripe
              </StripeLabel>
            )}
            <PromoCodeLabel onClick={() => setIsPromoCodeOpen(!isPromoCodeOpen)}>
              <Icon name="tag" /> Add a Promo code
            </PromoCodeLabel>
            {isPromoCodeOpen && (
              <PromoCodeInput
                value={promoCode}
                onChange={setPromoCode}
                isLoading={isNetworkRequestInFlight}
                description={promoCodeDescription}
                error={promoCodeError}
              />
            )}
            {selectedPlan?.sponsoredBy && (
              <StyledSponsoredSubscriptionBanner
                hideRevokeAccessButton
                sponsorName={selectedPlan?.sponsoredBy?.name}
                sponsorProfilePictureUrl={selectedPlan?.sponsoredBy?.profilePictureUrl}
              />
            )}
            <FooterText>
              Billed{' '}
              {daysLeftOfTrial && daysLeftOfTrial > 0 ? `in ${daysLeftOfTrial} days` : 'today'}:{' '}
              {formatCurrency(
                selectedPlan?.discountedPricePerPeriodDollars ??
                  selectedPlan?.pricePerPeriodDollars,
              )}
            </FooterText>
            {useSubscribeNowButton ? (
              <SubmitButton
                size="medium"
                type="submit"
                pending={isStripeInputLoading || isCreateSubscriptionLoading}
              >
                <span>{COPY.PREMIUM.SUBSCRIPTION_ENDED.BUTTONS.SUBSCRIBE_NOW}</span>
              </SubmitButton>
            ) : (
              <SubmitButton
                size="large"
                type="submit"
                pending={isStripeInputLoading || isCreateSubscriptionLoading}
              >
                <ButtonIcon name="diamond" />
                <span>{buttonText}</span>
              </SubmitButton>
            )}
          </>
        )}
      </StripeCardInputForm>
    </Root>
  );
};

const ProviderWrapper = (props: Props) => (
  <StripeProvider>
    <PremiumUpgradeForm {...props} />
  </StripeProvider>
);

export default ProviderWrapper;
