import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

const Root = styled(FlexContainer)`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xsmall};
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PillButton = styled(DefaultButton)`
  border-radius: ${({ theme }) => theme.radius.pill};
`;

type Props = {
  choices: string[];
  onClickChoice?: (choice: string) => void;
};

const MultipleChoiceMessageBlock = ({ choices, onClickChoice }: Props) => (
  <Root>
    {choices.map((choice) => (
      <PillButton key={choice} onClick={() => onClickChoice?.(choice)}>
        {choice}
      </PillButton>
    ))}
  </Root>
);

export default MultipleChoiceMessageBlock;
