import React from 'react';
import type { DroppableProps, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Droppable as BeautifulDroppable } from 'react-beautiful-dnd';
import type { StyledComponent, DefaultTheme } from 'styled-components';
import styled from 'styled-components';

import DroppableContext from 'components/lib/dnd/DroppableContext';
// This is used in the docstring below.
// eslint-disable-next-line unused-imports/no-unused-imports
import DndKitDroppable from 'components/lib/dndKit/Droppable';

const Unstyled = styled.div<{ state: DroppableStateSnapshot }>``;

type Props = Omit<DroppableProps, 'children'> & {
  children: React.ReactNode;
  className?: string;
  /** The placeholder element is placed after children by default. If enabled, you must use DroppableContext to provide the placeholder element. */
  customPlaceholder?: boolean;
  styledComponent?: StyledComponent<'div', DefaultTheme, { state?: DroppableStateSnapshot }, never>;
};

/**
 * This is a custom wrapper around react-beautiful-dnd's Droppable. It abstracts away the ugly
 * render props and instead provides them in a custom context.
 *
 * See Draggable.tsx for other examples
 *
 * -------------------
 * Custom placeholder:
 * -------------------
 * <Droppable droppableId="list" customPlaceholder>
 *    <div>Before placeholder</div>
 *   <DroppableContext.Consumer>
 *     {({ placeholder }) => placeholder}
 *   </DroppableContext.Consumer>
 *   <div>After placeholder</div>
 * </Droppable>
 *
 * -------------------
 * Custom styled component (outline when dragging over):
 * -------------------
 * const DroppableOutline = styled.div<{ state?: DroppableStateSnapshot }>`
    border-radius: ${({ theme }) => theme.radius.medium};
    ${({ state }) =>
    state?.isDraggingOver &&
    css`
      box-shadow: 0 0 0 2px ${({ theme }) => theme.color.blue};
    `}
  `;
  <Droppable droppableId="list" styledComponent={DroppableOutline} />

 * @deprecated Use {@link DndKitDroppable} instead.
 */
const Droppable = ({
  children,
  className,
  customPlaceholder,
  styledComponent,
  ...props
}: Props) => {
  const Root = styledComponent ?? Unstyled;

  return (
    <BeautifulDroppable {...props}>
      {({ innerRef, placeholder, droppableProps }, state) => (
        <Root ref={innerRef} {...droppableProps} className={className} state={state}>
          <DroppableContext.Provider value={{ placeholder, state }}>
            {children}
            {!customPlaceholder && placeholder}
          </DroppableContext.Provider>
        </Root>
      )}
    </BeautifulDroppable>
  );
};

export default Droppable;
