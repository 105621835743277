import { ANYONE_ID } from 'common/lib/hooks/household/useHouseholdUsers';

import type { TransactionFilters } from 'types/filters';

export const getNeedsReviewByUserId = (
  userId: string | 'anyone',
  filters?: Partial<TransactionFilters>,
) => {
  if (!filters || filters.needsReview !== true) {
    return false;
  }

  const { needsReviewByUser, needsReviewUnassigned } = filters;
  return userId === ANYONE_ID
    ? needsReviewUnassigned === true && !needsReviewByUser
    : needsReviewByUser === userId;
};
