import React from 'react';
import { useLocation } from 'react-router-dom';

import type { Props as ErrorBoundaryProps } from 'common/components/higherOrder/ErrorBoundary';
import ErrorBoundary from 'common/components/higherOrder/ErrorBoundary';

import { logErrorSurfaced } from 'actions';
import { useDispatch } from 'lib/hooks';

const LoggedErrorBoundary = ({ onError, ...props }: ErrorBoundaryProps) => {
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <ErrorBoundary
      {...props}
      onError={(error) => {
        dispatch(logErrorSurfaced({ error, fromRoute: location.pathname }));
        onError(error);
      }}
    />
  );
};

export default LoggedErrorBoundary;
