import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import QuestionnaireHeader from 'components/advice/QuestionnaireHeader';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Page from 'components/lib/ui/Page';
import Text from 'components/lib/ui/Text';

import useCreateTemporaryDemoAccount from 'common/lib/hooks/demo/useCreateTemporaryDemoAccount';
import { track } from 'lib/analytics/segment';
import api from 'lib/api';

import routes from 'constants/routes';

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${({ theme }) => theme.color.white};
`;

const Center = styled(FlexContainer).attrs({ center: true, column: true })`
  width: 100%;
  height: 100%;
`;

const LoadingText = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.large};
`;

const StartDemo = () => {
  const { replace } = useHistory();

  const [createDemoAccount] = useCreateTemporaryDemoAccount({
    api,
    track,
    onComplete: () => replace(routes.dashboard()),
    onError: () => replace(routes.signup()),
  });

  useEffect(() => {
    createDemoAccount();
  }, []);

  return (
    <StyledPage
      name="Start Demo"
      overrideTitle="Try Monarch"
      header={<QuestionnaireHeader progress={0} />}
    >
      <Center>
        <LoadingSpinner />
        <LoadingText>Loading demo...</LoadingText>
      </Center>
    </StyledPage>
  );
};

export default StartDemo;
