import pluralize from 'pluralize';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AppleSignInButton from 'components/lib/external/AppleSignInButton';
import GoogleSignInButton from 'components/lib/external/GoogleSignInButton';
import LoggedOutCardPage from 'components/lib/layouts/LoggedOutCardPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import { getProviderLabels } from 'common/lib/externalAuth/utils';
import useToggle from 'common/lib/hooks/useToggle';
import { pluralizeArray } from 'common/utils/String';
import { useQueryParam } from 'lib/hooks';

import { SUPPORTED_AUTH_PROVIDERS } from 'common/constants/auth';
import routes from 'constants/routes';

import type { AuthProvider } from 'common/types/auth';

const AUTH_BUTTONS_MAPPING: Record<AuthProvider, React.ElementType> = {
  google: GoogleSignInButton,
  apple: AppleSignInButton,
};

const ButtonsContainer = styled.div`
  > button:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }
`;

const ContinueWithExternalAuth = () => {
  const history = useHistory();
  const [isLoading, { setOff: disableLoading }] = useToggle(true);
  const providers = (useQueryParam('providers') || '').split(',') as AuthProvider[];
  const labels = getProviderLabels(providers);
  const pluralizeFromProviders = (s: string) => pluralize(s, providers.length);

  useEffect(() => {
    const hasInvalidProviders = R.length(R.difference(providers, SUPPORTED_AUTH_PROVIDERS)) > 0;
    if (hasInvalidProviders) {
      history.push(routes.login());
    } else {
      disableLoading();
    }
  }, []);

  return (
    <LoggedOutCardPage
      displayTitle={`Continue with ${pluralizeArray(labels, 'or')}`}
      description={`Your ${pluralizeArray(labels)} ${pluralizeFromProviders(
        'account',
      )} ${pluralizeFromProviders(
        'is',
      )} used to log in to Monarch, click the ${pluralizeFromProviders('button')} below.`}
      size="small"
      name="externalLogin"
    >
      {isLoading ? (
        <FlexContainer center>
          <LoadingSpinner />
        </FlexContainer>
      ) : (
        <ButtonsContainer>
          {providers.map((provider) => (
            <ProviderButton key={provider} provider={provider} />
          ))}
        </ButtonsContainer>
      )}
    </LoggedOutCardPage>
  );
};

const ProviderButton = ({ provider }: { provider: AuthProvider }) => {
  const Component = AUTH_BUTTONS_MAPPING[provider];
  return Component ? <Component skipConfirmation /> : null;
};

export default ContinueWithExternalAuth;
