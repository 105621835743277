import type { QueryHookOptions, OperationVariables, TypedDocumentNode } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { useSelector } from 'react-redux';

import useMockableQuery from 'common/lib/hooks/useMockableQuery';
import { getDoesNotHaveAccounts } from 'state/emptyState/selectors';

const useMockDataWhenNoAccountsQuery = <
  TData,
  TVariables extends Record<any, unknown> = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> & { forceMockData?: boolean } = {},
) => {
  const doesNotHaveAccounts = useSelector(getDoesNotHaveAccounts);
  const useMockData = doesNotHaveAccounts || options.forceMockData || false;
  // Only assume no accounts if the value is false (not undefined)
  return useMockableQuery<TData, TVariables>(query, useMockData, options);
};

export default useMockDataWhenNoAccountsQuery;
