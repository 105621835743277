import { useMutation, gql } from '@apollo/client';
import React, { useCallback } from 'react';

import type { AddManualAccountFlowData } from 'components/accounts/AddManualAccountFlow';
import AddManualAccountForm from 'components/accounts/AddManualAccountForm';
import type { FormValues as AddManualAccountFormValues } from 'components/accounts/AddManualAccountForm';
import ModalCard from 'components/lib/ui/ModalCard';

import { isManualHoldingsAccount } from 'common/lib/accounts/accountTypes';
import { throwIfHasMutationErrors } from 'common/lib/form/errors';
import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import { useFlowContext } from 'lib/contexts/FlowContext';

import type {
  CreateManualAccount,
  CreateManualAccountVariables,
} from 'common/generated/graphQlTypes/CreateManualAccount';

type Props = AddManualAccountFlowData & {
  next: (data: AddManualAccountFlowData) => void;
  goBack?: () => void;
};

const AddManualAccountFormModal = ({ type, subtype, next, goBack }: Props) => {
  const { skipToComplete } = useFlowContext();
  const [createManualAccount] = useMutation<CreateManualAccount, CreateManualAccountVariables>(
    CREATE_MANUAL_ACCOUNT,
  );

  const handleManualAccountFormSubmit = useCallback(
    async (values: AddManualAccountFormValues) => {
      const { name, type, subtype, investmentTrackingMode } = values;
      if (isManualHoldingsAccount(type, investmentTrackingMode)) {
        next({ name, type, subtype, investmentTrackingMode });
      } else {
        const { investmentTrackingMode, ...createManualAccountInput } = values;
        const { data } = await createManualAccount({
          variables: { input: createManualAccountInput },
        });
        throwIfHasMutationErrors(data);
        const accountId = data?.createManualAccount?.account?.id;
        skipToComplete({ accountIds: accountId ? [accountId] : [] });
      }
    },
    [createManualAccount, next, skipToComplete],
  );

  if (!type) {
    throw new Error(`Type ${type} is not a valid account type`);
  }

  return (
    <ModalCard onClickBackButton={goBack} hideBottomBorder>
      <AddManualAccountForm
        type={type}
        subtype={subtype}
        initialValues={{
          type,
          subtype: subtype ?? undefined,
          includeInNetWorth: true,
        }}
        onSubmit={handleManualAccountFormSubmit}
      />
    </ModalCard>
  );
};

const CREATE_MANUAL_ACCOUNT = gql`
  mutation Web_CreateManualAccount($input: CreateManualAccountMutationInput!) {
    createManualAccount(input: $input) {
      account {
        id
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default AddManualAccountFormModal;
