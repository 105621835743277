import React from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';

import { color, fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';

const HEADER_HEIGHT_PX = 50;

const Root = styled(FlexContainer)<{ $width?: number }>`
  flex-direction: column;
  overflow: hidden;

  ${({ $width }) =>
    $width
      ? css`
          width: ${$width}px;
        `
      : css`
          flex: 1;
        `}

  :not(:last-child) {
    border-right: 1px solid ${color.grayBackground};
  }
`;

const Header = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  height: ${HEADER_HEIGHT_PX}px;
  border-bottom: 1px solid ${color.grayBackground};
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.medium};
  flex-shrink: 0;
`;

const HeaderText = styled.div`
  padding: ${spacing.xsmall} calc(${spacing.small} + ${spacing.xxsmall});
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
`;

type Props = {
  header?: React.ReactNode;
  /** If width is not specified, flex: 1 will be applied. */
  width?: number;
  className?: string;
  children?: React.ReactNode;
};

const FilterMenuColumn = ({ header, width, className, children }: Props) => (
  <Root $width={width} className={className}>
    <Header>{typeof header === 'string' ? <HeaderText>{header}</HeaderText> : header}</Header>
    <Content>{children}</Content>
  </Root>
);

export default FilterMenuColumn;
