import { gql } from '@apollo/client';

import useQuery from 'common/lib/hooks/useQuery';

import type { Common_GetSharedConstantsQuery } from 'common/generated/graphql';

type QueryData = {
  isLoading: boolean;
};

/**
 * Hook to store shared constants between client and API. We can use this as a common
 * interface so if we ever want to move to Redux or something, we won't need to change
 * how it's called.
 */
const useSharedConstants = (): readonly [
  Common_GetSharedConstantsQuery['constants'] | Record<string, never>,
  QueryData,
] => {
  const { data, isLoadingInitialData } = useQuery<Common_GetSharedConstantsQuery>(
    GET_SHARED_CONSTANTS_QUERY,
    {
      fetchPolicy: 'cache-first',
    },
  );

  return [data?.constants ?? {}, { isLoading: isLoadingInitialData }] as const;
};

export const GET_SHARED_CONSTANTS_QUERY = gql(/* GraphQL */ `
  query Common_GetSharedConstants {
    constants {
      monthlyPriceDollars
      referralsTrialDurationDays
      activeReferralCampaign {
        campaign
        creditsEarned
      }
      institutionReportIssueTypes {
        name
        displayName
      }
      budgetRolloverFrequencies {
        value
        label
      }
    }
  }
`);

export default useSharedConstants;
