import { gql } from '@apollo/client';

export const GET_ONBOARDING_FINANCIAL_GOALS_QUERY = gql`
  query GetOnboardingFinancialGoals {
    onboardingFinancialGoals {
      id
      name
    }
    userOnboardingFinancialGoals {
      id
      onboardingFinancialGoal {
        id
      }
      otherText
    }
  }
`;

export const SET_ONBOARDING_FINANCIAL_GOALS_MUTATION = gql`
  mutation Common_SetUserOnboardingFinancialGoals($input: [SetUserOnboardingFinancialGoalInput!]!) {
    setUserOnboardingFinancialGoals(input: $input) {
      userOnboardingFinancialGoals {
        id
        onboardingFinancialGoal {
          id
        }
      }
    }
  }
`;
