import React from 'react';
import styled, { css } from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import MessageCard from 'components/assistant/MessageCard';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';

import { formatCurrency } from 'common/utils/Currency';

import routes from 'constants/routes';

const LOGO_SIZE_PX = 32;

const Root = styled(MessageCard)`
  width: 70%;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakPoints.xl}px) {
    width: 100%;
  }
`;

const ContentContainer = styled(FlexContainer).attrs({ alignCenter: true })<{ $hidden?: boolean }>`
  flex: 1;
  ${({ $hidden }) =>
    $hidden &&
    css`
      opacity: 0;
    `}
`;

const LoadingContainer = styled(FlexContainer).attrs({ alignCenter: true })`
  padding: 0 ${({ theme }) => theme.spacing.default};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const TitleContainer = styled(FlexContainer).attrs({ column: true })`
  flex: 1;
  margin: 0 ${({ theme }) => theme.spacing.small};
  gap: ${({ theme }) => theme.spacing.xxxsmall};
`;

const SmallLoadingSpinner = styled(LoadingSpinner)`
  width: 20px;
  height: 20px;
`;

type Props = {
  id: string;
  name: string;
  subtype: string;
  balance: number | null;
  icon: string;
  logoUrl?: string | null;
  loading?: boolean;
};

const AccountMessageBlock = ({ id, name, subtype, balance, icon, logoUrl, loading }: Props) => (
  <Root linkTo={routes.accounts.accountDetails({ id })}>
    <ContentContainer $hidden={loading}>
      <AccountLogo size={LOGO_SIZE_PX} logoUrl={logoUrl} icon={icon} />
      <TitleContainer>
        <Text>{name ?? ' '}</Text>
        <Text color="textLight" size="small">
          {subtype ?? ' '}
        </Text>
      </TitleContainer>
      <Text weight="medium" clampLines={1}>
        {formatCurrency(balance)}
      </Text>
    </ContentContainer>
    {loading && (
      <LoadingContainer>
        <SmallLoadingSpinner />
      </LoadingContainer>
    )}
  </Root>
);

export default AccountMessageBlock;
