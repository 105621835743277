import React from 'react';
import { FiCheck } from 'react-icons/fi';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { formatThousands } from 'common/utils/Number';

export const IGNORE_ACCOUNT_VALUE = 'ignore';

const Root = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  max-width: 765px;
  width: 100%;
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyledSubmitButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 438px;
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
`;

const RowContainer = styled.div`
  width: 100%;
`;

const OptionRow = styled(FlexContainer)<{ isSelected?: boolean }>`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.default};
  transition: ${({ theme }) => theme.transition.default};
  border: ${({ theme }) => `1px solid ${theme.color.grayFocus}`};
  border-top-width: 0px;
  display: flex;
  align-items: center;

  :first-child {
    border-radius: 6px 6px 0px 0px;
    border-top-width: 1px;
    margin-top: 30px;
  }

  :last-child {
    border-radius: 0px 0px 6px 6px;
  }
`;

const RowTitle = styled(Text).attrs({ size: 'default', weight: 'medium' })``;
const RowDescription = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  display: block;
  padding-right: ${({ theme }) => theme.spacing.default};
`;

const RowButton = styled(DefaultButton)`
  width: 100%;
  max-width: 150px;
  max-height: 50px;
`;

const CheckContainer = styled(FlexContainer).attrs({ center: true })`
  background-color: ${({ theme }) => theme.color.green};
  border-radius: ${({ theme }) => theme.radius.round};
  width: 72px;
  height: 72px;
`;

const CheckIcon = styled(FiCheck)`
  width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.color.textWhite};
`;

type Props = {
  onViewTransactions: () => void;
  onViewCashFlow: () => void;
  onEditCategories: () => void;
  transactionCount: number;
};

const ImportMintPriority = ({
  onViewTransactions,
  onViewCashFlow,
  onEditCategories,
  transactionCount,
}: Props) => (
  <Root>
    <CheckContainer>
      <CheckIcon />
    </CheckContainer>
    <Title>
      {formatThousands(transactionCount)} transactions imported! Here’s what to do next.
    </Title>

    <RowContainer>
      <OptionRow>
        <div>
          <RowTitle>View your cash flow reports</RowTitle>
          <RowDescription>
            You can now look back at your Mint history in Monarch, visualize how your money flows,
            and track how your savings rate has changed over time.
          </RowDescription>
        </div>

        <RowButton onClick={onViewCashFlow}>View cash flow</RowButton>
      </OptionRow>

      <OptionRow>
        <div>
          <RowTitle>Organize your categories</RowTitle>
          <RowDescription>
            If you added new categories with your import, you can customize the emoji, put them in
            the right group, and drag and drop to order them.
          </RowDescription>
        </div>

        <RowButton onClick={onEditCategories}>Edit categories</RowButton>
      </OptionRow>

      <OptionRow>
        <div>
          <RowTitle>Filter transactions by “imported from Mint”</RowTitle>
          <RowDescription>
            You now have the option to filter all of your transactions by “Imported from Mint”, so
            you can always find the transactions that came from a CSV import.
          </RowDescription>
        </div>

        <RowButton onClick={onViewTransactions}>View transactions</RowButton>
      </OptionRow>
    </RowContainer>

    <StyledSubmitButton size="large" onClick={onViewCashFlow}>
      View cash flow report
    </StyledSubmitButton>
  </Root>
);

export default ImportMintPriority;
