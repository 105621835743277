import { gql } from '@apollo/client';
import * as React from 'react';
import styled from 'styled-components';

import InvestmentDashboardWidgetTopMoverRow from 'components/investments/InvestmentsDashboardWidgetTopMoverRow';
import DashboardWidget from 'components/lib/ui/DashboardWidget';
import DashboardWidgetAmountHeader from 'components/lib/ui/DashboardWidgetAmountHeader';
import Empty from 'components/lib/ui/Empty';
import TimeframeTrendIndicator from 'components/lib/ui/TimeframeTrendIndicator';
import NavLink from 'components/lib/ui/link/NavLink';

import { getInvestmentsDashboardWidgetTitle } from 'common/lib/dashboard';
import { fontSize } from 'common/lib/theme/dynamic';
import useIsV2Theme from 'lib/hooks/useIsV2Theme';
import useMockDataWhenNoAccountsQuery from 'lib/hooks/useMockDataWhenNoAccountsQuery';

import routes from 'constants/routes';

import type { Web_GetInvestmentsDashboardCard } from 'common/generated/graphQlTypes/Web_GetInvestmentsDashboardCard';

const HeaderRoot = styled(NavLink)`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: 0;
  display: flex;
  flex-direction: column;
  margin-top: -20px;
`;

const MoversHeaderRoot = styled.div`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.xlarge};
  background-color: ${({ theme }) => theme.color.grayBackground};
`;
const HeaderText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.color.textLight};
  text-transform: uppercase;
  line-height: 150%;
`;

const StyledTimeframeTrendIndicator = styled(TimeframeTrendIndicator)`
  font-size: ${fontSize.base};

  span {
    text-transform: initial;
    display: inline-block;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

const InvestmentsDashboardWidget = () => {
  const isV2Theme = useIsV2Theme();
  const { data, isLoadingInitialData } =
    useMockDataWhenNoAccountsQuery<Web_GetInvestmentsDashboardCard>(GET_TOP_MOVERS_QUERY, {});

  const topMovers = data?.portfolio.performance.topMovers ?? [];
  const value = data?.portfolio.performance.totalValue;
  const oneDayChange = data?.portfolio.performance.oneDayChangeDollars;

  const startAmount = (value ?? 0) - (oneDayChange ?? 0);
  const currentAmount = value ?? 0;
  const timeframeText = 'Today';

  return (
    <DashboardWidget
      title={getInvestmentsDashboardWidgetTitle({
        isV2Theme,
        currentAmount,
        isLoading: isLoadingInitialData,
      })}
      headerLink={routes.investments()}
      description={
        isV2Theme &&
        !isLoadingInitialData && (
          <StyledTimeframeTrendIndicator
            startAmount={startAmount}
            endAmount={currentAmount}
            timeframeDisplay={timeframeText}
          />
        )
      }
      showHeaderBorder={isV2Theme}
      loading={isLoadingInitialData}
    >
      {!isV2Theme && (
        <HeaderRoot to={routes.investments()}>
          <DashboardWidgetAmountHeader
            startAmount={startAmount}
            currentAmount={currentAmount}
            timeframeText={timeframeText}
          />
        </HeaderRoot>
      )}
      <MoversHeaderRoot>
        <HeaderText>top movers today</HeaderText>
      </MoversHeaderRoot>
      {topMovers.length !== 0 ? (
        topMovers.map(({ ticker, name, currentPrice, oneDayChangePercent }) => (
          <InvestmentDashboardWidgetTopMoverRow
            key={ticker}
            ticker={ticker ?? ''}
            securityName={name}
            currentPriceDollars={currentPrice ?? 0}
            returnPercent={oneDayChangePercent}
          />
        ))
      ) : (
        <Empty
          title="No investment holdings with known securities"
          subtitle="Please sync another investment account to see top movers"
        />
      )}
    </DashboardWidget>
  );
};

const GET_TOP_MOVERS_QUERY = gql`
  query Web_GetInvestmentsDashboardCard {
    portfolio {
      performance {
        totalValue
        oneDayChangeDollars
        topMovers {
          id
          name
          ticker
          oneDayChangePercent
          currentPrice
        }
      }
    }
  }
`;

export default InvestmentsDashboardWidget;
