import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import PremiumPlanComparisonTable from 'components/premium/PremiumPlanComparisonTable';

import useSkipPremiumUpsell from 'common/lib/hooks/billing/useSkipPremiumUpsell';
import useTrialStatusQuery from 'common/lib/hooks/premium/useTrialStatusQuery';
import { track } from 'lib/analytics/segment';

import * as COPY from 'common/constants/copy';

const Container = styled(FlexContainer).attrs({ column: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: 0;
`;

const Title = styled(Text)``;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  next: () => void;
  goBack?: () => void;
};

const PremiumPlanComparisonModal = ({ next, goBack }: Props) => {
  const { hasPremiumTrialAvailable, trialDurationDays } = useTrialStatusQuery();

  const skip = useSkipPremiumUpsell({
    track,
    onComplete: goBack,
  });

  return (
    <ModalCard
      title={
        <Title>
          {hasPremiumTrialAvailable
            ? COPY.PREMIUM.PLAN_COMPARISION.TITLE.TRIAL_AVAILABLE(trialDurationDays)
            : COPY.PREMIUM.PLAN_COMPARISION.TITLE.TRIAL_UNAVAILABLE}
        </Title>
      }
      hideBottomBorder
    >
      <Container>
        <PremiumPlanComparisonTable />
        <StyledPrimaryButton onClick={() => next()} size="large">
          <ButtonIcon name="diamond" />
          <span>
            {hasPremiumTrialAvailable
              ? COPY.PREMIUM.UPGRADE_CTA.TRIAL_AVAILABLE
              : COPY.PREMIUM.UPGRADE_CTA.TRIAL_UNAVAILABLE}
          </span>
        </StyledPrimaryButton>
        <DefaultButton size="large" onClick={skip}>
          Skip for now
        </DefaultButton>
      </Container>
    </ModalCard>
  );
};

export default PremiumPlanComparisonModal;
