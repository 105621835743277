import styled from 'styled-components';

const DEFAULT_SIZE_PX = 16;

const Dot = styled.div<{ color?: string; size?: number }>`
  --size: ${({ size }) => size ?? DEFAULT_SIZE_PX}px;

  width: var(--size);
  height: var(--size);
  background-color: ${({ color, theme }) => color ?? theme.color.gray};
  border-radius: ${({ theme }) => theme.radius.round};
  flex-shrink: 0;
`;

export default Dot;
