import { isoDateToMonthAbbreviationAndDay } from 'common/utils/date';

const isDateFormat = (value: string) => {
  const match = value.match(/^\d{4}-\d{2}-\d{2}$/);
  return !!match;
};

export const formatXAxis = (value: string) => {
  if (isDateFormat(value)) {
    return isoDateToMonthAbbreviationAndDay(value);
  }
  return value;
};
