import React from 'react';
import { useSelector } from 'react-redux';

import WithIndicatorContainer from 'components/lib/ui/WithIndicatorContainer';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';
import ReportsFilterMenuPopover from 'components/reports/ReportsFilterMenuPopover';

import { selectReportsHasFilters } from 'state/reports/selectors';

const ReportsFilterMenuButton = () => {
  const hasFilters = useSelector(selectReportsHasFilters);
  return (
    <OverlayTrigger
      placement="bottom-end"
      overlay={({ toggleOpen }) => (
        <Popover>
          <ReportsFilterMenuPopover toggleOpen={toggleOpen} />
        </Popover>
      )}
    >
      {({ toggleOpen, isOpen }) => (
        <WithIndicatorContainer show={hasFilters}>
          <DefaultButton onClick={toggleOpen} active={isOpen}>
            <ButtonIcon name="filter" />
            <span>Filters</span>
          </DefaultButton>
        </WithIndicatorContainer>
      )}
    </OverlayTrigger>
  );
};

export default ReportsFilterMenuButton;
