import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import { HEADER_HEIGHT_PX } from 'components/advisorPortal/ClientsListHeader';
import { ROW_HEIGHT_PX } from 'components/advisorPortal/ClientsListRow';
import ContentLoader from 'components/lib/ui/ContentLoader';

const Root = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  overflow: hidden;
`;

const HeaderRoot = styled.div`
  height: ${HEADER_HEIGHT_PX}px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  width: 100%;
`;

const RowRoot = styled.div`
  height: ${ROW_HEIGHT_PX}px;
  width: 100%;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const HeaderLoading = () => (
  <HeaderRoot>
    <ContentLoader>
      <rect x="24" y="17" rx="5" ry="5" width="48" height="19" />
      <rect x="180" y="17" rx="5" ry="5" width="60" height="19" />
      <rect x="520" y="17" rx="5" ry="5" width="60" height="19" />
      <rect x="680" y="17" rx="5" ry="5" width="72" height="19" />
    </ContentLoader>
  </HeaderRoot>
);

const RowLoading = () => (
  <RowRoot>
    <ContentLoader>
      <rect x="24" y="30" rx="5" ry="5" width="64" height="19" />
      <rect x="180" y="30" rx="5" ry="5" width="96" height="19" />
      <rect x="520" y="30" rx="5" ry="5" width="64" height="19" />
      <rect x="680" y="30" rx="5" ry="5" width="92" height="19" />
      <rect x="calc(89% - 32px)" y="30" rx="5" ry="5" width="96" height="19" />
      <circle cx="calc(97%)" cy="40" r="12" />
    </ContentLoader>
  </RowRoot>
);

const ClientsListLoading = () => (
  <Root>
    <HeaderLoading />
    {R.range(0, 3).map((i) => (
      <RowLoading key={i} />
    ))}
  </Root>
);

export default ClientsListLoading;
