import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Icon from 'components/lib/ui/Icon';
import NavLink from 'components/lib/ui/link/NavLink';

import boxShadow from 'common/lib/styles/boxShadow';

import type { Color } from 'types/Styles';

const SIDE_MARGIN_PX = 8;

type MenuItemType = 'default' | 'danger';

const TEXT_COLOR_FOR_TYPE: { [key in MenuItemType]: Color } = {
  default: 'text',
  danger: 'redText',
};

const HOVER_COLOR_FOR_TYPE: { [key in MenuItemType]: Color } = {
  default: 'grayBackground',
  danger: 'redBackground',
};

export const MenuItemIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  width: ${({ theme }) => theme.spacing.small};
  height: ${({ theme }) => theme.spacing.small};
`;

export const MenuItem = styled.div<{
  selected?: boolean;
  focused?: boolean;
  disabled?: boolean;
  highlightOnHover?: boolean;
  indentLevel?: number;
  type?: MenuItemType;
}>`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
  margin: ${({ theme }) => theme.spacing.xxsmall} ${SIDE_MARGIN_PX}px;
  margin-left: ${({ indentLevel = 0 }) => SIDE_MARGIN_PX + indentLevel * SIDE_MARGIN_PX}px;
  border-radius: ${({ theme }) => theme.radius.small};
  cursor: pointer;
  transition: ${({ theme }) => theme.transition.default};
  user-select: none;
  display: flex;
  align-items: center;
  color: ${({ theme, type = 'default' }) => theme.color[TEXT_COLOR_FOR_TYPE[type]]};

  ${(context) =>
    !context.disabled &&
    css`
      :active {
        ${boxShadow.inset}
      }
    `}

  ${({ highlightOnHover = true, disabled, type = 'default' }) =>
    highlightOnHover &&
    !disabled &&
    css`
      :hover {
        background: ${({ theme }) => theme.color[HOVER_COLOR_FOR_TYPE[type]]};
        color: ${({ theme }) => theme.color[TEXT_COLOR_FOR_TYPE[type]]};
      }
    `};

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.color.textLight};
      cursor: not-allowed;
    `}

  ${({ focused }) =>
    focused &&
    css`
      background: ${({ theme }) => theme.color.grayBackground};
    `};

  ${({ selected }) =>
    selected &&
    css`
      && {
        background: ${({ theme }) => theme.color.blueBackground};
        color: ${({ theme }) => theme.color.blueText};
      }
    `};
`;

type MenuItemLinkProps = {
  to: string;
  children?: React.ReactNode;
};

export const MenuItemLink = ({ to, children }: MenuItemLinkProps) => {
  const match = useRouteMatch(to);

  return (
    <NavLink to={to}>
      <MenuItem selected={match?.isExact}>{children}</MenuItem>
    </NavLink>
  );
};

export const MenuSeparator = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.color.grayBackground};
  margin: ${({ theme }) => theme.spacing.xsmall} 0;
`;

const Menu = styled.div`
  padding: ${({ theme }) => theme.spacing.xxsmall} 0;
`;

export default Menu;
