import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';

const HEIGHT_PX = 20;

const Root = styled(FlexContainer).attrs({ center: true })`
  height: ${HEIGHT_PX}px;
  overflow: hidden;
  position: relative;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.grayFocus};
  position: relative;
`;

const Label = styled.span`
  display: block;
  position: absolute;
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.textLight};
  text-transform: uppercase;
  letter-spacing: 8%;
  background-color: ${({ theme }) => theme.color.white};
  padding: 0 ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  text?: string;
  className?: string;
};

const Divider = ({ text = 'or', className }: Props) => (
  <Root className={className}>
    <Line />
    <Label>{text}</Label>
  </Root>
);

export default Divider;
