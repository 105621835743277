import type { PaymentMethod } from '@stripe/stripe-js';
import React from 'react';
import styled from 'styled-components';

import StripeCardInputForm from 'components/lib/external/StripeCardInputForm';
import StripeProvider from 'components/lib/external/StripeProvider';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const Footer = styled(FlexContainer)`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const LockIcon = styled(Icon).attrs({ name: 'lock' })`
  width: ${({ theme }) => theme.spacing.small};
  height: ${({ theme }) => theme.spacing.small};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const SubmitButton = styled(AsyncButton)`
  ${primaryButtonMixin}
`;

type Props = {
  onSuccess: (paymentMethod: PaymentMethod) => Promise<void>;
  onDone: () => void;
  title?: string;
};

const UpdatePaymentMethodModal = ({ onDone, onSuccess, title }: Props) => (
  <Card title={title ?? 'Update Payment Method'}>
    <CardBody>
      <StripeCardInputForm
        skipPaymentMethod={false}
        onSuccess={(paymentInfo) => {
          if (paymentInfo) {
            onSuccess(paymentInfo);
            onDone();
          }
        }}
      >
        {({ isValid, isLoading }) => (
          <Footer justifyBetween alignCenter>
            <FlexContainer alignCenter>
              <LockIcon />
              Powered by Stripe
            </FlexContainer>
            <SubmitButton size="medium" disabled={!isValid} type="submit" pending={isLoading}>
              Update
            </SubmitButton>
          </Footer>
        )}
      </StripeCardInputForm>
    </CardBody>
  </Card>
);

const ProviderWrapper = (props: Props) => (
  <StripeProvider>
    <UpdatePaymentMethodModal {...props} />
  </StripeProvider>
);

export default ProviderWrapper;
