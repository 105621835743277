import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import GoalInfoRow from 'components/goalsV2/GoalInfoRow';
import GoalMonthlyBudgetInput from 'components/goalsV2/GoalMonthlyBudgetInput';
import AnimatedNumber from 'components/lib/ui/AnimatedNumber';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { sortGoalsByPriority } from 'common/lib/goalsV2/adapters';
import { formatCurrencyNoCents } from 'common/utils/Currency';

import { GOAL_BUDGET_NUM_MONTHS_AVG_SAVINGS } from 'common/constants/goals';

const SumBox = styled.div<{ $value: number }>`
  border-radius: ${({ theme }) => theme.radius.medium};
  background-color: ${({ $value, theme }) =>
    $value > 0 ? theme.color.greenBackground : theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.color.greenText};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const StyledText = styled(Text).attrs({ size: 'xsmall', weight: 'medium', color: 'textLight' })`
  text-align: center;
`;

const List = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const GoalRow = styled(FlexContainer).attrs({ column: true, full: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const Header = styled(FlexContainer)`
  padding: 0 ${({ theme }) => theme.spacing.xlarge};
`;

const StyledGoalInfoRow = styled(GoalInfoRow)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  data?: {
    goalsV2: {
      id: string;
      plannedMonthlyContribution: number | null;
      objective: string;
      name: string;
      priority: number;
      imageStorageProvider: string | null;
      imageStorageProviderId: string | null;
      __typename: 'GoalV2';
    }[];
    aggregates: {
      summary: {
        savings: number;
      };
    }[];
  };
};

const MonthlyBudgetsGoalsList = ({ data }: Props) => {
  const goals = useMemo(() => sortGoalsByPriority(data?.goalsV2 ?? []), [data]);
  const savings = data?.aggregates[0]?.summary.savings;
  const averageSavings = (savings ?? 0) / GOAL_BUDGET_NUM_MONTHS_AVG_SAVINGS;

  const sumPlannedMonthlyContributions = useMemo(
    () => R.sum(goals.map(({ plannedMonthlyContribution }) => plannedMonthlyContribution ?? 0)),
    [goals],
  );

  return (
    <FlexContainer full column>
      <Header column>
        <SumBox $value={averageSavings}>
          <AnimatedNumber
            value={sumPlannedMonthlyContributions}
            formattingFn={formatCurrencyNoCents}
            decimals={2}
          />{' '}
          per month
        </SumBox>

        <StyledText>
          In the last three months, your average savings has been{' '}
          {formatCurrencyNoCents(averageSavings)}.
        </StyledText>
      </Header>

      <List>
        {goals.map((item, idx) => (
          <GoalRow key={idx}>
            <StyledGoalInfoRow data={item} />
            <GoalMonthlyBudgetInput goal={item} valueKey="plannedMonthlyContribution" />
          </GoalRow>
        ))}
      </List>
    </FlexContainer>
  );
};

export default MonthlyBudgetsGoalsList;
