import * as R from 'ramda';

type Edge<T> = {
  node?: T;
};

/** Small util to flat edge nodes and return an array with all of them. */
export const flattenEdgeNodes = <T>(edges: (Edge<T | null> | null)[]): T[] =>
  edges.flatMap((edge) => (edge?.node ? [edge.node] : []));

export const toggleListItem = R.curry((item, list) =>
  R.ifElse(R.contains(item), R.without([item]), R.append(item))(list),
);
