import { useLazyQuery } from '@apollo/client';
import type { Interval } from 'luxon';
import { useCallback, useEffect, useState } from 'react';

import { GET_JOINT_PLANNING_DATA } from 'common/lib/graphQl/planning';
import { mergeFetchMorePlanningData } from 'lib/plan/Adapters';
import { getDataQueryParameters, getDateRangeToFetch } from 'lib/planning';

import type { Common_GetJointPlanningDataQueryVariables } from 'common/generated/graphql';

// Hook to fetch planning data depending on the visibleDateRange
// The implementation is a little tricky because we want the initial fetch to run the standard
// query, but subsequent fetches to use fetchMore so they 'append' to the existing data when
// the user pans their display.
export const usePlanningData = (visibleDateRange: Interval, isTimeframeMonthly: boolean) => {
  // Date range of data we have fetched so far. This is updated _after_ the data is fetched.
  const [fetchedDateRange, setFetchedDateRange] = useState<Interval | undefined>();
  const [initialQueryVariables, setInitialQueryVariables] =
    useState<Common_GetJointPlanningDataQueryVariables>();
  const visibleDateRangeString = visibleDateRange.toFormat('yyyy-MM');

  const [fetchInitialData, { data, fetchMore, refetch: refetchQuery }] = useLazyQuery(
    GET_JOINT_PLANNING_DATA,
    {
      onCompleted: () => afterFetchingData(visibleDateRange),
    },
  );

  const afterFetchingData = (newDateRange: Interval) => {
    if (fetchedDateRange) {
      setFetchedDateRange(fetchedDateRange.union(newDateRange));
    } else {
      setFetchedDateRange(newDateRange);
    }
  };

  const refetch = useCallback(() => {
    if (!fetchedDateRange) {
      return refetchQuery();
    } else {
      // Refetch entire date range that we have fetched so far
      // Use fetchMore instead of refetchQuery() because that wasn't working for dates
      // fetched after initial query. So instead we use fetchMore and replace the cache entirely with the result
      return fetchMore({
        variables: getDataQueryParameters(fetchedDateRange),
        updateQuery: (prev, { fetchMoreResult }) =>
          // Use the newly fetched data over the old (cached) data
          mergeFetchMorePlanningData(prev, fetchMoreResult),
      });
    }
  }, [fetchedDateRange, refetchQuery, fetchMore]);

  useEffect(() => {
    // If rollovers flag changes, we need to refetch everything
    const dateRangeToFetch = getDateRangeToFetch(visibleDateRange, isTimeframeMonthly);
    const queryVariables = getDataQueryParameters(dateRangeToFetch, fetchedDateRange);

    // The first time the query loads, we need to load the initial query to get the base data.
    // After that, we want to use fetchMore so we can use updateQuery.
    if (!data && queryVariables) {
      fetchInitialData({ variables: queryVariables });
      // We save the initial query variables so we can find the correct cache key and
      // update it manually whenever changes are saved
      setInitialQueryVariables(queryVariables);
    } else if (queryVariables) {
      fetchMore({
        variables: queryVariables,
        updateQuery: (prev, { fetchMoreResult }) =>
          mergeFetchMorePlanningData(prev, fetchMoreResult),
      }).then(() => afterFetchingData(dateRangeToFetch));
    }
    // We only want to trigger a data refetch if the visibleDateRange has changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleDateRangeString]);

  // We return initialQueryVariables because we need them to update query cache in other hooks
  // fetchedDateRange is the cumulative date range for which we have fetched planning data
  return { fetchedDateRange, data, initialQueryVariables, refetch };
};
