import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import AbstractButton from 'components/lib/ui/button/AbstractButton';

import useTheme from 'lib/hooks/useTheme';

const Root = styled(FlexContainer).attrs({
  justifyBetween: true,
  alignCenter: true,
})`
  background-color: ${({ theme }) => theme.color.orange};
  border-radius: ${({ theme }) => theme.radius.pill};
  height: 28px;
  position: relative;
  padding: 0 3px;
`;

const Label = styled(Text)`
  color: ${({ theme }) => theme.color.textWhite};
  margin: 0 ${({ theme }) => theme.spacing.xlarge};
`;

const CircleButton = styled(AbstractButton)`
  width: 22px;
  height: 22px;
  border-radius: 11px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.textWhite};
`;

type Props = {
  value: number;
  onChangeValue: (value: number) => void;
};

/**
 * Pill shaped control with plus and minus buttons, with count label in the middle.
 */
const IncrementDecrementPill = ({ value, onChangeValue }: Props) => {
  const theme = useTheme();

  return (
    <Root>
      <CircleButton onClick={() => onChangeValue(value - 1)}>
        <svg width="10" height="2" viewBox="0 0 10 2" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 1C0 0.447715 0.447715 0 1 0H9C9.55229 0 10 0.447715 10 1C10 1.55228 9.55229 2 9 2H1C0.447715 2 0 1.55228 0 1Z"
            fill={theme.color.orange}
          />
        </svg>
      </CircleButton>
      <Label>{value}</Label>
      <CircleButton onClick={() => onChangeValue(value + 1)}>
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
          <path
            d="M5 0C5.55229 0 6 0.447715 6 1V4H9C9.55229 4 10 4.44771 10 5C10 5.55229 9.55229 6 9 6H6V9C6 9.55229 5.55229 10 5 10C4.44771 10 4 9.55229 4 9V6H1C0.447715 6 0 5.55229 0 5C0 4.44771 0.447715 4 1 4H4V1C4 0.447715 4.44771 0 5 0Z"
            fill={theme.color.orange}
          />
        </svg>
      </CircleButton>
    </Root>
  );
};

export default IncrementDecrementPill;
