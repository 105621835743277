import * as React from 'react';
import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';

import { fontSize, fontWeight, radius, spacing } from 'common/lib/theme/dynamic';

type BadgeColors = 'green' | 'orange' | 'grayLight' | 'grayFocus' | 'blue';

const BADGE_COLOR_MAP = (theme: DefaultTheme) => ({
  green: { color: theme.color.greenText, backgroundColor: theme.color.greenBackground },
  orange: { color: theme.color.orangeText, backgroundColor: theme.color.orangeBackground },
  grayLight: { color: theme.color.textLight, backgroundColor: theme.color.grayBackground },
  grayFocus: { color: theme.color.textLight, backgroundColor: theme.color.grayFocus },
  blue: { color: theme.color.blueText, backgroundColor: theme.color.blueBackground },
});

const BadgeRoot = styled.span<{ color: BadgeColors }>`
  color: ${({ color, theme }) => BADGE_COLOR_MAP(theme)[color].color};
  background-color: ${({ color, theme }) => BADGE_COLOR_MAP(theme)[color].backgroundColor};
  margin-left: ${spacing.xsmall};
  font-size: ${fontSize.xxsmall};
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;
  padding: ${spacing.xxsmall} ${spacing.xsmall};
  border-radius: ${radius.small};
  letter-spacing: 0.1em;
`;

type Props = {
  children: React.ReactNode;
  color?: BadgeColors;
  className?: string;
};

const Badge = ({ children, color = 'green', className }: Props) => (
  // Extra span prevents the badge from stretching in Flex
  <span>
    <BadgeRoot color={color} className={className}>
      {children}
    </BadgeRoot>
  </span>
);

export default Badge;
