import React from 'react';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';
import IconButton from 'components/lib/ui/button/IconButton';
import { Popover } from 'components/lib/ui/popover';
import AnimatedPopInOverlay from 'components/lib/ui/popover/AnimatedPopInOverlay';

import { spacing } from 'common/lib/theme/dynamic';

const StyledPopover = styled(Popover)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.xxsmall};
  padding-left: ${spacing.default};
`;

const Title = styled(Text).attrs({ size: 'small' })`
  margin-right: ${spacing.default};
`;

type Props = {
  onClose: () => void;
  children: React.ReactNode;
  animated?: boolean;
};

/**
 * A popover with a title and close button.
 */
const CalloutPopover = ({ onClose, children, animated = true }: Props) => {
  const content = (
    <StyledPopover>
      <Title>{children}</Title>
      <IconButton icon="x" onClick={onClose} />
    </StyledPopover>
  );

  return animated ? <AnimatedPopInOverlay>{content}</AnimatedPopInOverlay> : content;
};

export default CalloutPopover;
