import { useMutation, gql } from '@apollo/client';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useCallback, useEffect, useMemo } from 'react';

import usePollQueryUntil from 'common/lib/hooks/usePollQueryUntil';

import { MS_PER_SECOND, MS_PER_MINUTE } from 'common/constants/time';

import type { RecurringMerchantSearch } from 'common/generated/graphQlTypes/RecurringMerchantSearch';
import type { TriggerRecurringMerchantSearch } from 'common/generated/graphQlTypes/TriggerRecurringMerchantSearch';

const POLL_INTERVAL_MS = MS_PER_SECOND * 5; // 5 seconds
const POLL_TIMEOUT_MS = MS_PER_MINUTE * 3; // 3 minutes

type Options = {
  onDidStartSearch?: () => void;
  onDidFinishSearch?: () => void;
  onTimeoutReached?: () => void;
  onError?: (error: unknown) => void;
};

/**
 * Hook used to search for recurring merchants in the household transactions,
 * and poll the query until the search is done.
 *
 * We have 3 states after it's started:
 * - search still ongoing
 * - search is finished, household doesn't have recurring merchants (we call onTimeoutReached)
 * - search is finished, household has recurring merchants (happy path, we call onDidFinishSearch)
 */
const useRecurringSearch = (options?: Options) => {
  const { onDidStartSearch, onDidFinishSearch, onTimeoutReached, onError } = options ?? {};

  const [startPolling, { data, refetch }] = usePollQueryUntil<RecurringMerchantSearch>(
    RECURRING_MERCHANT_SEARCH,
    {
      pollUntil: (data) => (data?.recurringMerchantSearch?.createdCount ?? 0) > 0,
      onComplete: onDidFinishSearch,
      pollIntervalMs: POLL_INTERVAL_MS,
      skipWhenNotPolling: false,
      pollTimeoutMs: POLL_TIMEOUT_MS,
      onTimeoutReached,
    },
  );
  const { recurringMerchantSearch } = data ?? {};
  const { startedAt, finishedAt, nextAt, createdCount } = recurringMerchantSearch ?? {};
  const currentSearch = useMemo(
    () => ({
      searchStartedAt: startedAt,
      searchFinishedAt: finishedAt,
      searchNextAt: nextAt,
      createdStreamCount: createdCount,
    }),
    [recurringMerchantSearch],
  );

  const [performMutation] = useMutation<TriggerRecurringMerchantSearch>(
    TRIGGER_RECURRING_SEARCH_MUTATION,
    {
      optimisticResponse: {
        triggerRecurringMerchantSearch: {
          __typename: 'TriggerRecurringMerchantSearchMutation',
          success: true,
        },
      },
    },
  );

  const triggerRecurringMerchantSearch = useCallback(async () => {
    try {
      await performMutation();
      await refetch();
      onDidStartSearch?.();
    } catch (error) {
      onError?.(error);
    }
  }, [onDidStartSearch, performMutation, refetch, onError]);

  useEffect(() => {
    if (startedAt) {
      startPolling();
    }
  }, [startedAt, startPolling]);

  return [
    triggerRecurringMerchantSearch,
    {
      isInProgress: RA.isNotNil(recurringMerchantSearch) && R.isNil(finishedAt),
      currentSearch,
    },
  ] as const;
};

export const RECURRING_MERCHANT_SEARCH = gql`
  query RecurringMerchantSearch {
    recurringMerchantSearch {
      startedAt
      nextAt
      finishedAt
      createdCount
    }
    recurringTransactionStreams {
      stream {
        id
      }
    }
  }
`;

const TRIGGER_RECURRING_SEARCH_MUTATION = gql`
  mutation Common_TriggerRecurringMerchantSearch {
    triggerRecurringMerchantSearch {
      success
    }
  }
`;

export default useRecurringSearch;
