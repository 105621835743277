// Transactions
import pluralize from 'pluralize';

export const HIDDEN_TOOLTIP_TEXT =
  'This transaction will not show up in your spending and budget calculations.';

export const SPLIT_PENDING_DISABLED_TEXT =
  'Pending transactions cannot be split because their amount is subject to change.';

export const DEFAULT_ERROR_MESSAGE = 'Sorry, something went wrong. Please try again.';

export const HIDDEN_ACCOUNT_BALANCE_FROM_NET_WORTH_TEXT =
  'Balance is hidden from net worth calculations';

// Dashboard
export const DASHBOARD = {
  CUSTOMIZE_DASHBOARD: {
    TITLE: 'Customize dashboard',
    DESCRIPTION: 'Select the widgets you want to see on your dashboard',
  },
};

export const ADVICE = {
  TOOLTIPS: {
    ESSENTIALS: 'This advice is recommended first to ensure a solid financial foundation.',
    PRIORITIES:
      'This advice was picked and ranked by you. You can update your profile and priorities at anytime.',
  },
  EMPTY: {
    TITLE: 'Get personalized advice by answering a few questions',
    SUBTITLE: `We'll provide guidance on how to prioritize and make progress on your finances.`,
  },
};

export const CSV_TIMEOUT_ERROR =
  'Request timeout. This file was too large, try splitting it into smaller files.';

// Merchant Settings
export const MERCHANT_SETTINGS_INTRO =
  'These are all of the different merchants you have interacted with in your transaction history.' +
  " You can edit how a merchant displays throughout Monarch, and delete merchants you're not " +
  'using.';

export const RECURRING = {
  EMPTY: {
    TITLE: 'Automatically identify and track your recurring transactions',
  },
  EMPTY_PERIOD: {
    TITLE: 'No recurring items yet.',
    SUBTITLE: "We couldn't find recurring items for the selected month.",
  },
};

export const MINT_IMPORTER_MAIN_PAGE = {
  TITLE: 'What Mint data would you like to import?',
  SUBTITLE:
    "The options below do not have to be done in a specific order. You can come back and do the step you don't choose at any time. ",
  OPTIONS: {
    TRANSACTIONS: {
      TITLE: 'Transactions',
      DESCRIPTION:
        "We'll show you how to export your Mint transactions, and import them into Monarch. You'’'ll be able to keep your tags and categories from Mint.",
      CTA: 'Import transactions',
    },
    BALANCE_HISTORY: {
      TITLE: 'Account balance history',
      DESCRIPTION:
        "We'll show you how to export your Mint balance history with our Chrome extension, and import them into Monarch",
      CTA: 'Import balances',
    },
  },
};

export const IMPORT_BALANCE_HISTORY = {
  MAIN_PAGE_NAME: 'Import balance history',
  STEPS: {
    DOWNLOAD_MINT_EXPORTER: {
      TITLE: 'Download our Chrome extension',
      SUBTITLE:
        "We've created a Chrome extension to make it incredibly easy to get all of your daily account balances out of Mint with just one click. Download it with the button below, log in to Mint, and click the “Download Mint account balance history” button.",
      GET_EXPORTER_BUTTON: 'Get “Mint Data Exporter” Chrome extension',
    },
    UPLOAD_BALANCE_HISTORY: {
      TITLE: 'Upload your Mint account balance CSVs',
      SUBTITLE:
        'Unzip the mint-balances.zip file that the Chrome extension downloaded to your computer, and upload all of the CSVs in the folder here.',
      FILE_UPLOADER_TITLE: 'Upload a CSV file',
      MIN_FILES_VALIDATION: 'Please upload at least one file',
    },
    FILE_ACCOUNT_MAPPING: {
      TITLE: 'Assign Mint accounts to Monarch accounts',
      SUBTITLE:
        'Assign each Mint account to a Monarch account so we can merge the balance history. You have the option to ignore Mint accounts if you want to skip those.',
      NO_CSV_FILES: {
        TITLE: 'No CSV files found',
        SUBTITLE:
          "Make sure you've selected files to upload and try again. If the problem continues, please contact support.",
      },
      FIELDS: {
        CSV_FILE: 'CSV file',
        MONARCH_ACCOUNT: 'Monarch Account',
        DAYS_OF_HISTORY: 'Days of History',
      },
      LOADING_MODAL_STEPS: [
        'Mapping the accounts',
        'Processing CSV files',
        'Processing the balance history',
        'Wrapping everything up',
      ],
      LOADING_MODAL_TITLE: 'Importing balance history... this may take a minute',
    },
    IMPORT_SUCCESS: {
      TITLE: (totalAccounts: number) =>
        `Balance history for ${totalAccounts} ${pluralize('account', totalAccounts)} imported!`,
      SUBTITLE:
        'You can now look back at your Mint net worth history in Monarch, and see how all of your assets and liabilities have changed over time.',
      BUTTON: 'View net worth',
    },
  },
  NEXT_BUTTON: 'Next',
  DEFAULT_ERROR_MESSAGE:
    "Something went wrong. If you're dealing with an extensive history spanning many years, consider breaking it down into smaller chunks for upload. If problems persist, reach out to our support team.",
};
