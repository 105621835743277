import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';

import checkmarkAnimation from 'static/animations/checkmark.json';

const Root = styled.div<{ $enabled: boolean; $size: number }>`
  width: ${({ $enabled, $size }) => ($enabled ? $size : 0)}px;
  height: ${({ $size }) => $size}px;
  overflow: hidden;
  transition: width 0.3s ease-out;
`;

type Props = {
  size?: number;
  enabled?: boolean;
  className?: string;
};

const CheckAnimation = ({ size = 40, enabled = true, className }: Props) => (
  <Root $size={size} $enabled={enabled} className={className}>
    {enabled && (
      <Lottie
        height={size}
        width={size}
        options={{ loop: false, autoplay: true, animationData: checkmarkAnimation }}
      />
    )}
  </Root>
);

export default CheckAnimation;
