import * as R from 'ramda';
import React, { useState, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';

import StackContext from 'common/lib/contexts/StackContext';
import type { StackContextType } from 'common/lib/contexts/StackContext';
import useStaticCallback from 'common/lib/hooks/useStaticCallback';
import noop from 'common/utils/noop';

const ComponentContainer = styled.div<{ $visible: boolean }>`
  ${({ $visible }) =>
    !$visible &&
    css`
      display: none;
    `}
`;

type StackRoute<P> = {
  component: React.ComponentType<P>;
  props: P;
};

type Props<P> = {
  initial: StackRoute<P>;
  onComplete?: (...props: any[]) => void;
  /** When pop() is called by the first component in the stack. */
  onPopTop?: () => void;
};

/**
 * General purpose component to render one component at a time with push/pop capabilities.
 * Useful for flows that have branching logic.
 *
 * Example:
 *
 * const StepOne = () => {
 *  const { push } = useStackContext();
 *
 *  return (
 *    <Button onClick={() => push(StepTwo, { value: 'test' })}>
 *      Next
 *    </Button>
 *  )
 * }
 *
 * const StepTwo = ({ value }: { value: string }) => {
 *  const { pop } = useStackContext();
 *
 *  return (
 *    <div>
 *      Value: {value}
 *      <Button onClick={pop}>
 *        Back
 *      </Button>
 *    </div>
 *  )
 * }
 *
 * <Stack initial={{ component: StepOne, props: {} }} />
 */
const Stack = <P extends any>({ initial, onComplete, onPopTop }: Props<P>) => {
  const onCompleteStatic = useStaticCallback(onComplete ?? noop);
  const onPopTopStatic = useStaticCallback(onPopTop ?? noop);

  const [stack, setStack] = useState<StackRoute<any>[]>([initial]);

  const push = useCallback<StackContextType['push']>(
    (component, props) =>
      setStack(
        R.append({
          component,
          props,
        }),
      ),
    [setStack],
  );

  const replace = useCallback<StackContextType['replace']>(
    (component, props) =>
      setStack((prev) => R.adjust(prev.length - 1, () => ({ component, props }), prev)),
    [setStack],
  );

  const pop = useCallback(
    () =>
      setStack((prev) => {
        if (prev.length === 1) {
          onPopTopStatic();
          return [];
        }
        return R.dropLast<StackRoute<any>>(1, prev);
      }),
    [setStack, onPopTopStatic],
  );

  const context = useMemo(
    () => ({ push, replace, pop, onComplete: onCompleteStatic }),
    [push, replace, pop, onCompleteStatic],
  );

  return (
    <StackContext.Provider value={context}>
      {stack.map(({ component: Component, props }, index) => (
        <ComponentContainer
          key={index}
          // render all components, but only make the last one visible so that components keep their state
          $visible={index === stack.length - 1}
        >
          <Component key={index} {...props} />
        </ComponentContainer>
      ))}
    </StackContext.Provider>
  );
};

export default Stack;
