import React from 'react';
import styled from 'styled-components';

import Banner from 'components/lib/ui/Banner';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import TransactionsDownloadInvisibleButton from 'components/transactions/TransactionsDownloadInvisibleButton';

import useDownloadTransactions from 'lib/hooks/statements/useDownloadTransactions';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';
import { DEFAULT_FILTERS } from 'lib/transactions/Filters';

const Spinner = styled(LoadingSpinner)`
  margin: 0 0 0 ${({ theme }) => theme.spacing.xxsmall};
  width: 21px;
  height: 21px;
`;

const ErrorMessage = styled(Banner).attrs({ type: 'error' })`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const DownloadButton = styled(PrimaryButton).attrs({ size: 'medium' })`
  width: 100%;
`;

const DownloadTransactionsButton = () => {
  const { isDemoHousehold } = useDemoHousehold();

  const { currentSession, downloadTransactions, isLoading } = useDownloadTransactions({
    filters: DEFAULT_FILTERS,
  });

  return (
    <>
      {currentSession?.errorMessage && <ErrorMessage>{currentSession?.errorMessage}</ErrorMessage>}
      <DownloadButton onClick={downloadTransactions} disabled={isLoading || isDemoHousehold}>
        Download transactions {isLoading && <Spinner />}
      </DownloadButton>
      <TransactionsDownloadInvisibleButton downloadUrl={currentSession?.url ?? undefined} />
    </>
  );
};

export default DownloadTransactionsButton;
