// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 3911342033
import { createAction } from 'typesafe-actions';

import type { AuthProviderEphemeralData } from 'types/auth';

export const clearOAuthStateString = createAction('AUTH/CLEAR_O_AUTH_STATE_STRING')();

export const clearProviderEphemeralData = createAction('AUTH/CLEAR_PROVIDER_EPHEMERAL_DATA')();

export const setOAuthStateString = createAction('AUTH/SET_O_AUTH_STATE_STRING')<string>();

export const setProviderEphemeralData = createAction(
  'AUTH/SET_PROVIDER_EPHEMERAL_DATA',
)<AuthProviderEphemeralData>();
