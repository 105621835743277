import { DateTime } from 'luxon';

export const isCurrentMonth = (month: DateTime) => month.hasSame(DateTime.local(), 'month');

export const getMonthProgressForMonthStart = (dt: DateTime) => {
  const monthStart = dt.startOf('month');
  const monthEnd = monthStart.plus({ months: 1 });

  const startDiff = DateTime.local().diff(monthStart).milliseconds;
  const endDiff = DateTime.local().diff(monthEnd).milliseconds;
  const endStartDiff = monthEnd.diff(monthStart).milliseconds;

  if (startDiff < 0) {
    return 0;
  } else if (endDiff > 0) {
    return 1;
  } else {
    return startDiff / endStartDiff;
  }
};
