import axios from 'axios';

export type UnsplashPhoto = {
  id: string;
  user: {
    name: string;
    links: {
      self: string;
    };
  };
  urls: {
    raw: string;
    full: string;
    regular: string;
    small: string;
    thumb: string;
  };
  links: {
    self: string;
    html: string;
    download: string;
    download_location: string;
  };
  // there's more here...
};

const UNSPLASH_ACCESS_KEY = 'H47x7-ioE1fOsM-mhldx4aKcG71NhRbOwz6DnW934Ek';

const UnsplashApi = axios.create({
  baseURL: 'https://api.unsplash.com',
  headers: {
    Authorization: `Client-ID ${UNSPLASH_ACCESS_KEY}`,
  },
});

/**
 * Wrapper for Unsplash API.
 * https://unsplash.com/documentation
 */
const unsplash = {
  /** https://unsplash.com/documentation#list-photos */
  listPhotos: async (
    params: {
      page?: number;
      per_page?: number;
      order_by?: 'latest' | 'oldest' | 'popular';
    } = {},
  ) => {
    const response = await UnsplashApi.get('/photos', {
      params,
    });
    return (response.data ?? []) as UnsplashPhoto[];
  },
  /** https://unsplash.com/documentation#search-photos */
  searchPhotos: async (params: {
    query: string;
    page?: number;
    per_page?: number;
    order_by?: 'latest' | 'relevant';
  }) => {
    const response = await UnsplashApi.get('/search/photos', {
      params,
    });
    return response.data as {
      total: number;
      total_pages: number;
      results: UnsplashPhoto[];
    };
  },
  /** https://help.unsplash.com/en/articles/2511258-guideline-triggering-a-download */
  trackDownload: async (photo: UnsplashPhoto) => {
    const response = await UnsplashApi.get(photo.links.download_location);
    return response.data;
  },
};

export default unsplash;
