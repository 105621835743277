import pluralize from 'pluralize';
import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Highlighter from 'components/lib/ui/Highlighter';
import Text from 'components/lib/ui/Text';
import MerchantLogo from 'components/merchants/MerchantLogo';

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  gap: ${({ theme }) => theme.spacing.default};
  padding: ${({ theme }) => theme.spacing.xlarge};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }

  &:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.radius.medium};
    border-bottom-right-radius: ${({ theme }) => theme.radius.medium};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.grayBackground};
    cursor: pointer;
  }
`;

const MerchantInfo = styled(FlexContainer).attrs({ column: true })`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  align-items: flex-start;
`;

type Props = {
  name: string;
  transactionCount: number;
  logoUrl?: string;
  onClick: () => void;
};

const MerchantSearchListItem = ({ name, transactionCount, logoUrl, onClick }: Props) => (
  <Root onClick={onClick}>
    <MerchantLogo url={logoUrl} />
    <MerchantInfo>
      <Text weight="medium">
        <Highlighter>{name}</Highlighter>
      </Text>
      <Text size="small">
        {transactionCount} {pluralize('transaction', transactionCount)}
      </Text>
    </MerchantInfo>
  </Root>
);

export default MerchantSearchListItem;
