import type { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import Helmet from 'react-helmet';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import RecurringAllTable from 'components/recurring/list/RecurringAllTable';

import useQuery from 'common/lib/hooks/useQuery';
import { GroupByValues, splitActiveCanceledItems } from 'common/lib/recurring';
import isV2Theme from 'common/lib/theme/isV2Theme';
import type { RecurringFilters } from 'state/recurring/reducer';

import { gql } from 'common/generated/gql';
import type { Web_GetAllRecurringTransactionItemsQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

type RecurringTransactionStream = ElementOf<
  Web_GetAllRecurringTransactionItemsQuery,
  'recurringTransactionStreams'
>;

const Root = styled(FlexContainer).attrs({ column: true })`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.gutter} ${({ theme }) => theme.spacing.gutter} 0;
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
  gap: ${({ theme }) => theme.spacing.gutter};
  ${isV2Theme(css`
    padding-top: 0;
  `)}
`;

type Props = {
  startDate: DateTime;
  filters: RecurringFilters;
};

const RecurringAll = ({ filters, startDate }: Props) => {
  const { data, isLoadingInitialData, refetch } = useQuery(RECURRING_ALL_QUERY, {
    variables: {
      filters: {
        accounts: filters.accounts,
        isCompleted: undefined,
      },
      includeLiabilities: true,
    },
  });
  const streams = R.pathOr<RecurringTransactionStream[]>([], ['recurringTransactionStreams'], data);
  const { active, canceled } = splitActiveCanceledItems(streams);

  const groupBy = filters.groupBy ?? GroupByValues.Type;

  return (
    <>
      <Helmet>
        <title>Recurring &middot; All Merchants</title>
      </Helmet>
      <Root>
        <RecurringAllTable
          title="Active"
          streams={active}
          refetch={refetch}
          isLoading={isLoadingInitialData}
          startDate={startDate}
          groupBy={groupBy}
        />
        {RA.isNotNilOrEmpty(canceled) && (
          <RecurringAllTable
            title="Canceled"
            streams={canceled}
            refetch={refetch}
            isLoading={isLoadingInitialData}
            startDate={startDate}
            groupBy={groupBy}
            isInitiallyExpanded={RA.isNotNil(filters.groupBy)}
            hideColumns={['nextPayment']}
          />
        )}
      </Root>
    </>
  );
};

const RECURRING_ALL_QUERY = gql(/* GraphQL */ `
  query Web_GetAllRecurringTransactionItems(
    $filters: RecurringTransactionFilter
    $includeLiabilities: Boolean
  ) {
    recurringTransactionStreams(filters: $filters, includeLiabilities: $includeLiabilities) {
      stream {
        id
        frequency
        isActive
        isApproximate
        name
        logoUrl
        merchant {
          id
        }
        creditReportLiabilityAccount {
          id
          account {
            id
          }
        }
      }
      nextForecastedTransaction {
        date
        amount
      }
      category {
        id
        name
        icon
      }
      account {
        id
        displayName
        icon
        logoUrl
      }
    }
  }
`);

export default RecurringAll;
