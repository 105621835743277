import { useMutation } from '@apollo/client';
import type { PaymentMethod } from '@stripe/stripe-js';
import { useCallback } from 'react';

import { throwIfHasMutationErrors } from 'common/lib/form/errors';

import { gql } from 'common/generated/gql';

/** Hook used to reactivate a stripe subscription given a price id & payment method. */
const useReactivateSubscription = () => {
  const [reactivateSubscription] = useMutation(REACTIVATE_SUBSCRIPTION);

  const reactivateSubscriptionWithPriceId = useCallback(
    async (
      paymentMethod: PaymentMethod | null,
      stripePriceId: string,
      stripePromoCode: string | null,
    ) => {
      const { data } = await reactivateSubscription({
        variables: {
          input: { paymentMethodId: paymentMethod?.id || '', stripePriceId, stripePromoCode },
        },
      });
      throwIfHasMutationErrors(data);
    },
    [reactivateSubscription],
  );

  return { reactivateSubscriptionWithPriceId };
};

const REACTIVATE_SUBSCRIPTION = gql(`
  mutation Web_ReactivateSubscription($input: ReactivateStripeSubscriptionMutationInput!) {
    reactivateStripeSubscription(input: $input) {
      subscription {
        id
        ...SubscriptionFields
      }
      errors {
        message
      }
    }
  }
`);

export default useReactivateSubscription;
