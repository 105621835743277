import type { FormikContextType } from 'formik';
import React, { useContext } from 'react';

import type { ValidationOptions } from 'common/lib/form/validation';

export type FormContextType<Values> = {
  registerFieldProps: (options: ValidationOptions) => void;
  unregisterFieldProps: (options: ValidationOptions) => void;
  fieldApiErrors: { [name: string]: string[] };
  generalApiErrors: string[];
  getIdForFieldName: (fieldName: string) => string;
} & FormikContextType<Values>;

// This is how Formik initializes their contexts too
const FormContext = React.createContext<FormContextType<any>>(undefined as any);

export const useFormContext = <TValues extends Record<string, unknown>>() => {
  const context = useContext<FormContextType<TValues>>(FormContext);

  if (!context) {
    throw new Error(
      'useFormContext used outside of FormContext. ' +
        'Ensure you have a <FormContext.Provider> higher in the tree.',
    );
  }

  return context;
};

export default FormContext;
