import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import ContentLoader from 'components/lib/ui/ContentLoader';

const Root = styled.div`
  height: 100%;
`;

const HeaderRoot = styled.div`
  padding: ${({ theme }) => theme.spacing.xxlarge};
`;

const HeaderLoading = () => (
  <HeaderRoot>
    <ContentLoader>
      <rect x="0" y="0" rx="5" ry="5" width="196" height="36" />
      <rect x="calc(100% - 80px)" y="0" rx="5" ry="5" width="80" height="36" />
      <rect x="0" y="72" rx="5" ry="5" width="100%" height="64" />
    </ContentLoader>
  </HeaderRoot>
);

const FieldLoadingRoot = styled.div`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xxlarge};
  height: 96px;
`;

const FormFieldLoading = () => (
  <FieldLoadingRoot>
    <ContentLoader>
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />
    </ContentLoader>
  </FieldLoadingRoot>
);

const TransactionDrawerLoading = () => (
  <Root>
    <HeaderLoading />
    {R.range(0, 5).map((i) => (
      <FormFieldLoading key={i} />
    ))}
  </Root>
);

export default TransactionDrawerLoading;
