import { createSelector } from 'reselect';

import type { RootState } from 'state/types';

const getStatementsState = ({ statements }: RootState) => statements;

export const getCurrentUploadSession = createSelector(
  getStatementsState,
  ({ currentSession }) => currentSession,
);
