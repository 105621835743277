import onError from 'lib/errors/onError';
import type { Middleware } from 'state/types';

/**
 * Middleware that catches errors in the reducer and other middleware
 */
export const createErrorHandlingMiddleware =
  <ReturnT>(onError: (error: Error) => ReturnT | void): Middleware =>
  (store) =>
  (next) =>
  (action) => {
    try {
      return next(action);
    } catch (error: any) {
      // sync error in a reducer
      onError(error);
    }
  };

export default createErrorHandlingMiddleware(onError);
