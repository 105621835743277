import { gql } from 'common/generated/gql';
import type { TransactionOverviewFieldsFragment } from 'common/generated/graphql';

export const TRANSACTION_FIELDS_FRAGMENT = gql(/* GraphQL */ `
  fragment TransactionFields on Transaction {
    id
    amount
    pending
    date
    hideFromReports
    plaidName
    notes
    dataProviderDescription
    attachments {
      id
    }
    account {
      id
      ...AccountFields
    }
    category {
      id
      ...CategoryFields
    }
    merchant {
      id
      name
      transactionsCount
    }
  }
`);

export const TRANSACTION_GOAL_FIELDS = gql(/* GraphQL */ `
  fragment TransactionGoalFields on Transaction {
    goal {
      id
      name
      imageStorageProvider
      imageStorageProviderId
    }
    account {
      id
      displayName
      goalAllocations {
        id
        goal {
          id
          name
          imageStorageProvider
          imageStorageProviderId
          priority
          archivedAt
        }
      }
    }
  }
`);

export const GET_TRANSACTIONS = gql(/* GraphQL */ `
  query GetTransactions($offset: Int, $limit: Int, $filters: TransactionFilterInput) {
    allTransactions(filters: $filters) {
      totalCount
      results(offset: $offset, limit: $limit) {
        id
        ...TransactionFields
      }
    }
  }
`);

export const UPDATE_TRANSACTION = gql(/* GraphQL */ `
  mutation Common_UpdateTransaction($input: UpdateTransactionMutationInput!) {
    updateTransaction(input: $input) {
      transaction {
        id
        ...TransactionFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

// Return an optimisticResponse object you can use in an UpdateTransaction mutation, from
// the transaction object. This has a generic so it can work regardless of which fields are
// being updated.
export const updateTransactionOptimisticResponse = <T extends TransactionOverviewFieldsFragment, _>(
  transaction: T,
) => {
  const optimisticResponse = {
    __typename: 'Mutation',
    updateTransaction: {
      __typename: 'UpdateTransactionMutation',
      transaction,
      errors: null,
    },
  } as const;
  return optimisticResponse;
};

export const setTransactionTagsOptimisticResponse = (transactionId: string, tagIds: string[]) => {
  const optimisticResponse = {
    __typename: 'Mutation',
    setTransactionTags: {
      __typename: 'SetTransactionTags',
      transaction: {
        __typename: 'Transaction',
        id: transactionId,
        tags: tagIds.map((tagId) => ({ __typename: 'TransactionTag' as const, id: tagId })),
      },
      errors: null,
    },
  } as const;
  return optimisticResponse;
};

export const SPLIT_TRANSACTION_MUTATION = gql(/* GraphQL */ `
  mutation Common_SplitTransactionMutation($input: UpdateTransactionSplitMutationInput!) {
    updateTransactionSplit(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      transaction {
        id
        hasSplitTransactions
        splitTransactions {
          id
          amount
          notes
          hideFromReports
          reviewStatus
          merchant {
            id
            name
          }
          category {
            id
            icon
            name
          }
          goal {
            id
          }
          needsReviewByUser {
            id
          }
          tags {
            id
          }
        }
      }
    }
  }
`);

export const TRANSACTION_SPLIT_QUERY = gql(/* GraphQL */ `
  query Common_TransactionSplitQuery($id: UUID!) {
    getTransaction(id: $id) {
      id
      amount
      reviewedAt
      needsReview
      reviewStatus
      hideFromReports
      dataProviderDescription
      notes
      category {
        id
        name
        icon
      }
      merchant {
        id
        name
        logoUrl
      }
      needsReviewByUser {
        id
        name
      }
      tags {
        id
        name
        color
      }

      ...TransactionGoalFields

      splitTransactions {
        id
        amount
        notes
        date
        hideFromReports
        reviewStatus
        needsReview
        merchant {
          id
          name
          logoUrl
        }
        category {
          id
          icon
          name
        }
        goal {
          id
          name
          imageStorageProvider
          imageStorageProviderId
        }
        needsReviewByUser {
          id
        }
        tags {
          id
          name
        }
      }
    }
  }
`);

export const CREATE_TRANSACTION_MUTATION = gql(/* GraphQL */ `
  mutation Common_CreateTransactionMutation($input: CreateTransactionMutationInput!) {
    createTransaction(input: $input) {
      transaction {
        id
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const DELETE_TRANSACTION_MUTATION = gql(/* GraphQL */ `
  mutation Common_DeleteTransactionMutation($input: DeleteTransactionMutationInput!) {
    deleteTransaction(input: $input) {
      deleted
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const BULK_UPDATE_TRANSACTIONS_MUTATION = gql(/* GraphQL */ `
  mutation Common_BulkUpdateTransactionsMutation(
    $selectedTransactionIds: [ID!]
    $excludedTransactionIds: [ID!]
    $allSelected: Boolean!
    $expectedAffectedTransactionCount: Int!
    $updates: TransactionUpdateParams!
    $filters: TransactionFilterInput
  ) {
    bulkUpdateTransactions(
      selectedTransactionIds: $selectedTransactionIds
      excludedTransactionIds: $excludedTransactionIds
      updates: $updates
      allSelected: $allSelected
      expectedAffectedTransactionCount: $expectedAffectedTransactionCount
      filters: $filters
    ) {
      success
      affectedCount
      errors {
        message
      }
    }
  }
`);

export const BULK_DELETE_TRANSACTIONS_MUTATION = gql(/* GraphQL */ `
  mutation Common_BulkDeleteTransactionsMutation(
    $selectedTransactionIds: [ID!]
    $excludedTransactionIds: [ID!]
    $allSelected: Boolean!
    $expectedAffectedTransactionCount: Int!
    $filters: TransactionFilterInput
  ) {
    bulkDeleteTransactions(
      input: {
        selectedTransactionIds: $selectedTransactionIds
        excludedTransactionIds: $excludedTransactionIds
        isAllSelected: $allSelected
        expectedAffectedTransactionCount: $expectedAffectedTransactionCount
        filters: $filters
      }
    ) {
      success
      affectedCount
      errors {
        message
      }
    }
  }
`);

export const GET_OLDEST_DELETABLE_TRANSACTION_DATE = gql(/* GraphQL */ `
  query Common_OldestDeletableTransactionDate(
    $includeSynced: Boolean = false
    $includeUploaded: Boolean = false
    $includeManual: Boolean = false
  ) {
    oldestDeletableTransactionDate(
      includeSynced: $includeSynced
      includeUploaded: $includeUploaded
      includeManual: $includeManual
    )
  }
`);

export const DELETE_SYNCED_TRANSACTIONS_MUTATION = gql(/* GraphQL */ `
  mutation Common_DeleteSyncedTransactionsMutation($input: DeleteSyncedTransactionsInput!) {
    deleteSyncedTransactions(input: $input) {
      success
      errors {
        message
        code
      }
    }
  }
`);

export const TRANSACTION_OVERVIEW_FIELDS_FRAGMENT = gql(/* GraphQL */ `
  fragment TransactionOverviewFields on Transaction {
    id
    amount
    pending
    date
    hideFromReports
    plaidName
    notes
    isRecurring
    reviewStatus
    needsReview
    isSplitTransaction
    dataProviderDescription

    attachments {
      id
    }

    category {
      id
      name
      icon
      group {
        id
        type
      }
    }

    merchant {
      name
      id
      transactionsCount
      logoUrl
      recurringTransactionStream {
        frequency
        isActive
      }
    }

    tags {
      id
      name
      color
      order
    }

    account {
      id
      displayName
      icon
      logoUrl
    }
  }
`);

export const GET_PREVIEW_DELETE_TRANSACTIONS_COUNT = gql(/* GraphQL */ `
  query Common_PreviewDeleteTransactions(
    $beforeDate: Date!
    $includeSynced: Boolean = false
    $includeUploaded: Boolean = false
    $includeManual: Boolean = false
  ) {
    previewDeleteTransactions(
      beforeDate: $beforeDate
      includeSynced: $includeSynced
      includeUploaded: $includeUploaded
      includeManual: $includeManual
    ) {
      totalCount
    }
  }
`);

export const DELETE_TRANSACTIONS = gql(/* GraphQL */ `
  mutation Common_DeleteTransactionsMutation($input: DeleteTransactionsInput!) {
    deleteTransactions(input: $input) {
      success
      errors {
        message
        code
      }
    }
  }
`);

// --------------------------------------
// Transaction tags
// --------------------------------------

export const DELETE_TRANSACTION_TAG = gql(/* GraphQL */ `
  mutation Common_DeleteHouseholdTransactionTag($tagId: ID!) {
    deleteTransactionTag(tagId: $tagId) {
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const UPDATE_TRANSACTION_TAG_ORDER = gql(/* GraphQL */ `
  mutation Common_UpdateTransactionTagOrder($tagId: ID!, $order: Int!) {
    updateTransactionTagOrder(tagId: $tagId, order: $order) {
      householdTransactionTags {
        id
        order
      }
    }
  }
`);

export const UPDATE_TRANSACTION_TAG = gql(/* GraphQL */ `
  mutation Common_UpdateTransactionTag($input: UpdateTransactionTagInput!) {
    updateTransactionTag(input: $input) {
      tag {
        id
        name
        color
        order
      }
      errors {
        message
      }
    }
  }
`);

export const CREATE_TRANSACTION_TAG = gql(/* GraphQL */ `
  mutation Common_CreateTransactionTag($input: CreateTransactionTagInput!) {
    createTransactionTag(input: $input) {
      tag {
        id
        name
        color
        order
        transactionCount
      }
      errors {
        message
      }
    }
  }
`);
