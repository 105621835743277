import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import IconButton from 'components/lib/ui/button/IconButton';

import type { Color } from 'types/Styles';

const Root = styled.div`
  max-width: 280px;
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  overflow: hidden;
  box-shadow: 0px 8px 16px ${({ theme }) => rgba(theme.color.black, 0.16)};
`;

const Header = styled.div<{ color: Color }>`
  height: ${({ theme }) => theme.spacing.xsmall};
  background: ${({ theme, color }) => theme.color[color]};
  width: 100%;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacing.xsmall};
  right: ${({ theme }) => theme.spacing.xsmall};
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.default};
  position: relative;
`;

type Props = {
  onClickClose?: () => void;
  headerColor?: Color;
  className?: string;
  children?: React.ReactNode;
};

const Toast = ({ onClickClose, headerColor = 'green', className, children }: Props) => (
  <Root className={className}>
    <Header color={headerColor} />
    <Content>
      {onClickClose && <CloseButton onClick={onClickClose} icon="x" />}
      {children}
    </Content>
  </Root>
);

export default Toast;
