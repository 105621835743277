import type * as React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  children?: React.ReactNode;
  className?: string;

  inlineBlock?: boolean;
  inlineFlex?: boolean;
  flex?: boolean;
  order?: number;
  basis?: string;
  grow?: number;
  shrink?: number;
  noShrink?: boolean;
} & Pick<React.HTMLProps<HTMLDivElement>, 'onClick' | 'id'>;

const notForwardedProps: string[] = [
  'inlineBlock',
  'inlineFlex',
  'flex',
  'order',
  'basis',
  'grow',
  'shrink',
  'noShrink',
];

// See https://github.com/SaraVieira/styled-flex-component/blob/master/src/item.js
const FlexItem = styled.div.withConfig({
  shouldForwardProp: (prop) => !notForwardedProps.includes(prop),
})`
  order: 0;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  display: block;
  ${(props: Props) =>
    props.inlineBlock &&
    css`
      display: inline-block;
    `};
  ${(props: Props) =>
    props.inlineFlex &&
    css`
      display: inline-flex;
    `};
  ${(props: Props) =>
    props.flex &&
    css`
      display: flex;
    `};
  /********************************** order **********************************/
  /****************** http://cssreference.io/property/order ******************/
  ${(props: Props) =>
    props.order &&
    css`
      order: ${props.order};
    `};
  /******************************** flex-basis ********************************/
  /**************** http://cssreference.io/property/flex-basis ****************/
  ${(props: Props) =>
    props.basis &&
    css`
      flex-basis: ${props.basis};
    `};
  /******************************** flex-grow ********************************/
  /**************** http://cssreference.io/property/flex-grow ****************/
  ${(props: Props) =>
    props.grow &&
    css`
      flex-grow: ${props.grow};
    `};
  /******************************* flex-shrink *******************************/
  /*************** http://cssreference.io/property/flex-shrink ***************/
  ${(props: Props) =>
    props.shrink &&
    css`
      flex-shrink: ${props.shrink};
    `};
  ${(props: Props) =>
    props.noShrink &&
    css`
      flex-shrink: 0;
    `};
`;

export default FlexItem;
