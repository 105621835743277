import type { DropResult } from 'react-beautiful-dnd';

import { dropResultRequiresChanges } from 'lib/dnd/DragAndDrop';

import type { UpdateCategoryGroupOrderVariables } from 'common/generated/graphQlTypes/UpdateCategoryGroupOrder';
import type { UpdateCategoryOrderVariables } from 'common/generated/graphQlTypes/UpdateCategoryOrder';
import type { CategoryGroupType } from 'common/generated/graphql';

const CATEGORY_GROUP_DROPPABLE_PREFIX = 'CATEGORY_GROUP_';

export const getDroppableTypeForGroup = ({ type }: { type: CategoryGroupType }) =>
  `${CATEGORY_GROUP_DROPPABLE_PREFIX}${type}`;

export type MoveCategoryHandler = (
  destination: UpdateCategoryOrderVariables,
  source: UpdateCategoryOrderVariables,
) => void;

export type MoveCategoryGroupHandler = (
  destination: UpdateCategoryGroupOrderVariables,
  source: UpdateCategoryGroupOrderVariables,
) => void;

export const handleDropResult =
  (onMoveCategory: MoveCategoryHandler, onMoveCategoryGroup: MoveCategoryGroupHandler) =>
  (result: DropResult) => {
    if (!dropResultRequiresChanges(result)) {
      return;
    }
    if (result.destination && result.type.startsWith(CATEGORY_GROUP_DROPPABLE_PREFIX)) {
      const categoryGroupId = result.destination.droppableId;
      const id = result.draggableId;
      const order = result.destination.index;
      onMoveCategory(
        { categoryGroupId, id, order },
        {
          categoryGroupId: result.source.droppableId,
          id: result.draggableId,
          order: result.source.index,
        },
      );
    } else if (result.destination) {
      const id = result.draggableId;
      const order = result.destination.index;
      onMoveCategoryGroup({ id, order }, { id, order: result.source.index });
    }
  };
