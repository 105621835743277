import * as RA from 'ramda-adjunct';

import type { ResolutionBlocksData } from 'common/generated/graphql';
import type { ArrayTypenameKeys } from 'common/types';

/**
 * Extract all nested nodes with a `__typename` from the recommended resolution GraphQL query.
 */
export type ResolutionBlocks = Pick<ResolutionBlocksData, ArrayTypenameKeys<ResolutionBlocksData>>;

/**
 * Map the `__typename` to the key of the block in the `ResolutionBlocks` type.
 * Intended to be used internally only to create the `ResolutionBlockComponents` type.
 *
 * @example
 * { ResolutionBlockMessage: 'messages', ... }
 */
type BlockTypenamesToKey = {
  [K in keyof ResolutionBlocks as ResolutionBlocks[K][number]['__typename']]: K;
};

/**
 * Generate common props for all blocks.
 */
export type BlockComponentProps<T extends keyof BlockTypenamesToKey> = {
  data: ResolutionBlocks[BlockTypenamesToKey[T]][number];
  /** Track when a block is clicked. Not required because some blocks don't
   * really have an action, such as ResolutionMessageBlock. */
  onClickEvent?: () => void;
};

/**
 * Map the `__typename` to the React component that renders the block.
 * Intended to be used to render the blocks in the `RecommendedResolutionSection` component,
 * and ensure type safety when adding new blocks to the API.
 */
export type ResolutionBlockComponents = {
  [K in keyof BlockTypenamesToKey]: React.FC<BlockComponentProps<K>>;
};

/**
 * Extract all block types from the `ResolutionBlocks` type.
 * It's useful when we want to track analytics, so we know
 * which props are available among all blocks.
 */
export type BlockAnalyticsProps = {
  [K in keyof ResolutionBlocks]: ResolutionBlocks[K] extends Array<infer U> ? U : never;
}[keyof ResolutionBlocks];

/**
 * Check if a component is a valid block component.
 */
export const isValidBlockComponent = <T extends keyof ResolutionBlockComponents>(
  component: unknown,
): component is React.ComponentType<BlockComponentProps<T>> => RA.isFunction(component);
