import * as RA from 'ramda-adjunct';
import React, { useMemo, useState, useRef, useCallback } from 'react';
import type { ValueType, ActionMeta } from 'react-select';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import ReactSelect, { createFilter } from 'react-select';
import styled from 'styled-components';

import type { Props as SelectProps } from 'components/lib/form/Select';
import Select from 'components/lib/form/Select';
import Dot from 'components/lib/ui/Dot';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Modal from 'components/lib/ui/Modal';
import Text from 'components/lib/ui/Text';
import UpsertTransactionTagModal from 'components/transactions/tags/UpsertTransactionTagModal';

import useTransactionTags from 'common/lib/hooks/transactions/useTransactionTags';
import { color, spacing } from 'common/lib/theme/dynamic';
import { getOptionFromTag } from 'common/lib/transactions/tags';
import { equalsIgnoreCase } from 'common/utils/String';

import { isOptionsType } from 'types/select';
import type { OptionType } from 'types/select';

const DOT_SIZE_PX = 16;
const DOT_LABEL_SIZE_PX = 12;
const CHECK_ICON_SIZE_PX = 21;

const SELECT_FILTERS = createFilter({ ignoreAccents: false });

const Root = styled.div`
  .react-select {
    &__option--is-selected {
      background-color: transparent;
      color: ${({ theme }) => theme.color.text};

      &:hover {
        background-color: ${({ theme }) => theme.color.blueBackground};
        color: ${({ theme }) => theme.color.blue};
      }
    }
  }
`;

const StyledDot = styled(Dot).attrs({ size: DOT_LABEL_SIZE_PX })`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const DotContainer = styled.div`
  ${StyledDot} {
    --size: ${DOT_SIZE_PX}px;
  }
`;

const Label = styled(Text)`
  transform: translateY(-1px);
`;

const Content = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  flex-grow: 1;
`;

const CheckIcon = styled(Icon).attrs({ name: 'check', size: CHECK_ICON_SIZE_PX })`
  margin-left: auto;
`;

const OptionContainer = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  flex-grow: 1;
`;

const Footer = styled(FlexContainer).attrs({ alignCenter: true })`
  padding: ${spacing.xsmall} ${spacing.default};
  border-top: 1px solid ${color.grayFocus};
  color: ${color.blue};
  cursor: pointer;
  :hover {
    color: ${color.blueDark};
  }
`;

type Props = Omit<SelectProps, 'onChange'> & {
  onChange: (value: ValueType<OptionType> | null, action?: ActionMeta) => void;
  className?: string;
};

const Option = ({ label, icon, isChecked }: OptionType & { isChecked: boolean }) => (
  <OptionContainer>
    <Content>
      <DotContainer>{icon}</DotContainer>
      <Label size="base">{label}</Label>
    </Content>
    {isChecked && <CheckIcon />}
  </OptionContainer>
);

const TransactionTagSelect = ({
  className,
  isLoading,
  isCreatable = true,
  onChange,
  ...selectProps
}: Props) => {
  const { tags, isLoadingInitialData, refetch } = useTransactionTags();
  const loading = isLoading || isLoadingInitialData;
  const ref = useRef<ReactSelect>(null);

  const inputValueRef = useRef('');
  const [draftTagName, setDraftTagName] = useState<string | undefined>(undefined);

  const isOptionSelected = useCallback(
    (value: string) => (selectProps.value ?? []).includes(value),
    [selectProps.value],
  );

  const options = useMemo(
    () =>
      // Cast to typeof tags because sortByProps doesn't do type inference
      (RA.sortByProps(['order'], tags) as typeof tags).map(
        ({ id, name, color }) => ({ value: id, label: name, icon: <StyledDot color={color} /> }),
        {},
      ),
    [tags],
  );

  /**
   * If any options are visible, show the "Create new tag" footer.
   * Otherwise, hide the footer and only show the "Create new tag ..." option.
   */
  const isValidNewOption = useCallback(
    (input: string) => {
      const filteredOptions = options.filter(({ value, label }) =>
        SELECT_FILTERS(
          {
            value,
            label,
            data: {},
          },
          input,
        ),
      );
      const anyOptionsVisible = filteredOptions.length > 0;
      const anyMatch = tags.some(({ name }) => equalsIgnoreCase(name, input));
      return !anyMatch && !anyOptionsVisible;
    },
    [tags, options],
  );

  return (
    <Root>
      <Select
        innerRef={ref}
        className={className}
        options={options}
        renderOption={({ value, label, icon }) => (
          <Option value={value} label={label} icon={icon} isChecked={isOptionSelected(value)} />
        )}
        formatOptionLabel={({ label }) => <Label size="xsmall">{label}</Label>}
        formatCreateLabel={(input) => `Create new tag "${input}"`}
        onChange={(value, action) => onChange(value, action)}
        menuPlacement="auto"
        isLoading={loading}
        placeholder={loading ? 'Loading...' : 'Search tags...'}
        hideSelectedOptions={false}
        isMulti
        onInputChange={(input) => (inputValueRef.current = input)}
        isValidNewOption={isValidNewOption}
        menuFooterComponent={
          isCreatable && (
            <Footer onClick={() => setDraftTagName(inputValueRef.current)}>Create new tag</Footer>
          )
        }
        {...selectProps}
      />
      {RA.isNotNil(draftTagName) && (
        <Modal onClose={() => setDraftTagName(undefined)}>
          <UpsertTransactionTagModal
            draft={{ name: draftTagName }}
            onDone={(tag) => {
              refetch();
              if (tag) {
                const value = ref.current?.state.value;
                const currentTags = isOptionsType(value) ? value : [];
                onChange([...currentTags, getOptionFromTag(tag)]);
              }
            }}
          />
        </Modal>
      )}
    </Root>
  );
};

export default TransactionTagSelect;
