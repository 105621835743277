import styled, { css } from 'styled-components';

import { fontSize, fontWeight } from 'common/lib/theme/dynamic';
import variables from 'common/lib/theme/variables';

const CardTitle = styled.span<{ $largeWhenV2Enabled?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.color.textLight};
  text-transform: uppercase;
  line-height: 150%;
  letter-spacing: 1.2px;

  ${({ $largeWhenV2Enabled, theme }) =>
    $largeWhenV2Enabled &&
    theme.isV2ThemeEnabled &&
    css`
      text-transform: none;
      font-size: ${fontSize.large};
      font-weight: ${fontWeight.medium};
      letter-spacing: unset;
      color: ${variables.color.text.primary};
    `}
`;

export default CardTitle;
