import { useMutation } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import Confirmation from 'components/lib/ui/Confirmation';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import { UNDELETE_ACCOUNT_MUTATION } from 'common/lib/graphQl/accounts';
import ApolloClient from 'lib/graphQl/ApolloClient';
import logger from 'lib/logger';

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  background-color: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.default};
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
`;

const List = styled.ul`
  padding-left: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: start;
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const CHECK_CIRCLE_ICON_SIZE_PX = 22;

type Props = {
  account: {
    id: GraphQlUUID;
  };
  onCancel: () => void;
  onUndelete: () => void;
};

const UndeleteAccountConfirmation = ({ account, onCancel, onUndelete }: Props) => {
  const [undeleteAccount, { loading }] = useMutation(UNDELETE_ACCOUNT_MUTATION, {
    update() {
      ApolloClient.resetStore();
    },
  });

  const performUndelete = async () => {
    const { data, errors } = await undeleteAccount({ variables: { input: { id: account.id } } });

    if (data?.undeleteAccount?.undeleted) {
      onUndelete();
    } else {
      logger.error('Account was not undeleted.', errors, data?.undeleteAccount?.errors);
      onCancel();
    }
  };

  return (
    <Confirmation
      title="Are you sure you want to restore this account?"
      confirm="Restore account"
      isLoading={loading}
      onCancel={onCancel}
      onConfirm={performUndelete}
    >
      <Content>
        <Text weight="medium">What will change:</Text>
        <List>
          <ListItem>
            <StyledIcon name="check" size={CHECK_CIRCLE_ICON_SIZE_PX} />
            <Text>Account will re-appear in list</Text>
          </ListItem>
          <ListItem>
            <StyledIcon name="check" size={CHECK_CIRCLE_ICON_SIZE_PX} />
            <Text>Transactions will resume syncing</Text>
          </ListItem>
          <ListItem>
            <StyledIcon name="check" size={CHECK_CIRCLE_ICON_SIZE_PX} />
            <Text>Balances will start being tracked again</Text>
          </ListItem>
        </List>
      </Content>
    </Confirmation>
  );
};

export default UndeleteAccountConfirmation;
