import React from 'react';

import { useTabs } from 'common/components/tabs/TabsContext';

import useUpdateEffect from 'common/lib/hooks/useUpdateEffect';

type Props = {
  onChangeIndex: (index: number) => void;
};

const BreakdownSection = ({ children, onChangeIndex }: React.PropsWithChildren<Props>) => {
  const [activeIndex] = useTabs();

  // We need to do this because the current tabs API doesn't support a way to
  // set the active tab index in state from outside the Tabs component. If we
  // override the onChangeIndex prop, we can't use the Tabs component's internal
  // state to set the active tab index, so this is a workaround.
  useUpdateEffect(() => {
    onChangeIndex(activeIndex);
  }, [activeIndex]);

  return <>{children}</>;
};

export default BreakdownSection;
