import React from 'react';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import type { DateRangePickerProps } from 'components/lib/ui/DateRangePicker';
import DateRangePicker from 'components/lib/ui/DateRangePicker';

const DateRangeField = ({
  className,
  name,
  ...props
}: AbstractFieldProps<DateRangePickerProps>) => (
  <AbstractField {...props} className={className} name={name}>
    {({ id, value, setFieldValue }) => (
      <DateRangePicker
        {...props}
        id={id}
        value={value ?? {}}
        onChange={(value) => setFieldValue(name, value)}
      />
    )}
  </AbstractField>
);

export default DateRangeField;
