import * as R from 'ramda';
import React from 'react';

import ClientsListHeader from 'components/advisorPortal/ClientsListHeader';
import ClientsListLoading from 'components/advisorPortal/ClientsListLoading';
import ClientsListRow from 'components/advisorPortal/ClientsListRow';
import Card from 'components/lib/ui/Card';
import Empty, { Theme as EmptyTheme } from 'components/lib/ui/Empty';
import Link from 'components/lib/ui/Link';

import { SubscriptionSponsorshipInviteStatus } from 'common/lib/sponsorship';

import {
  ADVISOR_FAQ_URL,
  ADVISOR_ONBOARDING_CALENDLY_URL,
  ADVISOR_ONBOARDING_VIDEO_URL,
} from 'common/constants/externalUrls';

import type { Web_GetAdvisorClientsPage_subscriptionSponsorshipInvites } from 'common/generated/graphQlTypes/Web_GetAdvisorClientsPage';

type Props = {
  sponsor?: { name?: string | null } | null;
  invites?: Web_GetAdvisorClientsPage_subscriptionSponsorshipInvites[];
  isLoading: boolean;
  demoUserId?: string;
};

const ClientsList = ({ sponsor, invites = [], isLoading, demoUserId }: Props) => {
  // TODO: @edufschmidt move filtering to backend
  const filteredInvites = R.filter(
    ({ status }) =>
      status === SubscriptionSponsorshipInviteStatus.Pending ||
      status === SubscriptionSponsorshipInviteStatus.Redeemed,
    invites,
  );

  const isEmpty = !isLoading && R.isEmpty(filteredInvites) && !demoUserId;

  const redeemedInvites = R.filter(
    ({ status }) => status === SubscriptionSponsorshipInviteStatus.Redeemed,
    invites,
  );

  const showOnboarding = !isLoading && redeemedInvites?.length <= 1;

  return (
    <Card>
      {isLoading && <ClientsListLoading />}
      {isEmpty && (
        <Empty
          emptyTheme={EmptyTheme.NEW}
          title="No clients yet"
          subtitle="Invite clients to get started"
        />
      )}
      {!isLoading && !isEmpty && (
        <>
          <ClientsListHeader />
          {filteredInvites.map(
            ({ id, code, label, status, createdAt, sponsorship, sentToEmail }) => (
              <ClientsListRow
                key={id}
                id={id}
                code={code}
                label={label}
                sponsor={sponsor}
                createdAt={createdAt}
                inviteStatus={status}
                sponsorship={sponsorship}
                userId={sponsorship?.household?.owner?.id}
                sentToEmail={sentToEmail}
              />
            ),
          )}
          {demoUserId && (
            <ClientsListRow
              code="DEMO"
              key="demo"
              id="demo"
              label="Demo User"
              sponsor={sponsor}
              inviteStatus="demo"
              userId={demoUserId}
            />
          )}
        </>
      )}

      {showOnboarding && (
        <Empty
          emptyTheme={EmptyTheme.NEW}
          title="Need help getting started?"
          subtitle={
            <>
              You can book an{' '}
              <Link target="_blank" href={ADVISOR_ONBOARDING_CALENDLY_URL}>
                onboarding session
              </Link>{' '}
              with our Customer Success team, watch our{' '}
              <Link target="_blank" href={ADVISOR_ONBOARDING_VIDEO_URL}>
                onboarding video
              </Link>
              , or read our{' '}
              <Link target="_blank" href={ADVISOR_FAQ_URL}>
                FAQ
              </Link>
              .
            </>
          }
        />
      )}
    </Card>
  );
};

export default ClientsList;
