import { makeTheme } from 'common/lib/theme';
import makeDynamicTheme from 'common/lib/theme/dynamic/makeDynamicTheme';

const theme = makeTheme({
  // these values don't matter here
  uiTheme: 'light',
  isV2ThemeEnabled: false,
});

/**
This is intended to be used with styled-components. Each theme value
is actually a function that takes { theme } as input and uses the value
from there so it is dynamic.

i.e.
color.text is actually ({ theme }) => theme.color.text

const SomeComponent = styled.div`
  color: ${color.text}; // will respond to dark mode because color.text is a function
`;

DO NOT USE like this:
const SomeComponent = styled.div`
  color: ${() => color.text}; // won't work because it will return a function
`;
 */
const dynamicTheme = makeDynamicTheme(theme, []);

export const { color, fontSize, spacing, radius, fontWeight, transition, breakPoints } =
  dynamicTheme;

export default dynamicTheme;
