import styled from 'styled-components';

const HorizontalTabContainer = styled.div`
  display: flex;
  align-items: center;

  > div:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.xlarge};
  }
`;

export default HorizontalTabContainer;
