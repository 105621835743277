import * as RA from 'ramda-adjunct';
import React from 'react';
import { FiArrowRight, FiEyeOff } from 'react-icons/fi';
import styled from 'styled-components';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Icon from 'components/lib/ui/Icon';
import Tooltip from 'components/lib/ui/Tooltip';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';
import TransactionTagsIcon from 'components/transactions/tags/TransactionTagsIcon';

import { HIDDEN_TOOLTIP_TEXT } from 'constants/copy';

import type { PreviewTransactionRuleQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

type Props = {
  name: string;
  category: string;
  amount: number;
  newName?: string;
  newCategory?: string;
  newHideFromReports?: boolean | null;
  newTags?: ElementOf<PreviewTransactionRuleQuery['transactionRulePreview'], 'results'>['newTags'];
  newSplitTransactions?: boolean | null;
  className?: string;
};

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  flex-shrink: 1;
  flex-basis: 50%;
  padding-right: ${({ theme }) => theme.spacing.default};
`;

const Strikethrough = styled.span`
  color: ${({ theme }) => theme.color.textLight};
  text-decoration: line-through;
`;

const New = styled.span`
  color: ${({ theme }) => theme.color.orange};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  vertical-align: middle;
`;

const Category = styled.div`
  flex-basis: 25%;
  flex-shrink: 1;
  flex-grow: 1;
  padding-right: ${({ theme }) => theme.spacing.default};
`;

const Amount = styled(CashFlowCurrency)`
  text-align: right;
  min-width: 6em;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Arrow = styled(FiArrowRight)`
  display: inline;
  vertical-align: middle;
  color: ${({ theme }) => theme.color.orange};
  margin: 0 ${({ theme }) => theme.spacing.xsmall};
`;

const EyeOffIcon = styled(FiEyeOff).attrs({ size: 12 })`
  color: ${({ theme }) => theme.color.textLight};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const PreviewTransactionChangeRow = ({
  name,
  category,
  amount,
  newName,
  newCategory,
  newHideFromReports,
  newTags,
  newSplitTransactions,
  className,
}: Props) => (
  <Root className={className}>
    <Name {...sensitiveClassProps}>
      {RA.isNotNil(newName) ? (
        <>
          <Strikethrough>{name}</Strikethrough>
          <Arrow />
          <New>{newName}</New>
        </>
      ) : (
        name
      )}
    </Name>
    <Category>
      {RA.isNotNil(newCategory) ? (
        <>
          <Strikethrough>{category}</Strikethrough>
          <Arrow />
          <New>{newCategory}</New>
        </>
      ) : (
        category
      )}
    </Category>
    {newTags && <TransactionTagsIcon tags={newTags} />}
    {newHideFromReports && (
      <Tooltip content={HIDDEN_TOOLTIP_TEXT}>
        <EyeOffIcon />
      </Tooltip>
    )}
    {newSplitTransactions && (
      <Tooltip content="This transaction will be split into multiple transactions." hyphens={false}>
        <Icon name="split" size={16} />
      </Tooltip>
    )}
    <Amount value={amount} type="expense" emphasis={amount > 0} />
  </Root>
);

export default PreviewTransactionChangeRow;
