import { createReducer } from 'typesafe-actions';

import { setHasAccounts } from 'actions';

const INITIAL_STATE: EmptyState = {
  hasAccounts: undefined,
};

export type EmptyState = {
  hasAccounts?: boolean;
};

const emptyStateReducer = createReducer<EmptyState>(INITIAL_STATE).handleAction(
  setHasAccounts,
  (state, { payload: hasAccounts }) => ({ ...state, hasAccounts }),
);

export default emptyStateReducer;
