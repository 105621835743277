import storage from 'redux-persist/lib/storage';
import { createReducer } from 'typesafe-actions';

import { setActiveAccountIds } from 'state/investments/actions';

export const investmentsPersistConfig = {
  key: 'investments',
  storage,
  whitelist: ['activeAccountIds'],
};

export type InvestmentsState = {
  activeAccountIds: string[];
};

const INITIAL_STATE: InvestmentsState = {
  activeAccountIds: [],
};

const investments = createReducer<InvestmentsState>(INITIAL_STATE).handleAction(
  setActiveAccountIds,
  (state, { payload: activeAccountIds }) => ({
    ...state,
    activeAccountIds,
  }),
);

export default investments;
