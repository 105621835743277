/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: { input: string; output: string };
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: string; output: string };
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: { input: number; output: number };
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: {
    input: { [key: string]: Scalars['GenericScalar'] };
    output: { [key: string]: Scalars['GenericScalar'] };
  };
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: string; output: string };
  /** Patched UUID type that can handle non-UUID types (e.g. bigint). */
  UUID: { input: string; output: string };
};

export type Account = {
  __typename: 'Account';
  archivedAt: Maybe<Scalars['Date']['output']>;
  availableBalanceForGoals: Scalars['Float']['output'];
  canBeForceRefreshed: Scalars['Boolean']['output'];
  canInvertBalance: Scalars['Boolean']['output'];
  canUseAvailableBalance: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  credential: Maybe<Credential>;
  creditReportLiabilityAccount: Maybe<CreditReportLiabilityAccount>;
  currentBalance: Maybe<Scalars['Float']['output']>;
  dataProvider: Maybe<Scalars['String']['output']>;
  dataProviderAccountId: Maybe<Scalars['String']['output']>;
  dataProviderId: Maybe<Scalars['String']['output']>;
  deactivatedAt: Maybe<Scalars['DateTime']['output']>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  displayBalance: Maybe<Scalars['Float']['output']>;
  displayBalancePreview: Maybe<Scalars['Float']['output']>;
  displayLastUpdatedAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  goalAllocations: Array<GoalAccountAllocation>;
  hasCustomizedLogo: Maybe<Scalars['Boolean']['output']>;
  hasSyncInProgress: Scalars['Boolean']['output'];
  hasSyncOrRecentRefreshRequest: Scalars['Boolean']['output'];
  hideFromList: Scalars['Boolean']['output'];
  hideTransactionsFromReports: Scalars['Boolean']['output'];
  holdingsCount: Scalars['Int']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  includeBalanceInNetWorth: Scalars['Boolean']['output'];
  includeInGoalBalance: Scalars['Boolean']['output'];
  includeInNetWorth: Scalars['Boolean']['output'];
  institution: Maybe<Institution>;
  invertSyncedBalance: Scalars['Boolean']['output'];
  isAsset: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isLiability: Scalars['Boolean']['output'];
  isManual: Scalars['Boolean']['output'];
  isTaxable: Scalars['Boolean']['output'];
  logoUrl: Maybe<Scalars['String']['output']>;
  manualInvestmentsTrackingMethod: Maybe<Scalars['String']['output']>;
  mask: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  recentBalances: Array<Maybe<Scalars['Float']['output']>>;
  signedBalance: Maybe<Scalars['Float']['output']>;
  subtype: AccountSubtype;
  syncDisabled: Scalars['Boolean']['output'];
  transactionsCount: Scalars['Int']['output'];
  type: AccountType;
  updatedAt: Scalars['DateTime']['output'];
  useAvailableBalance: Maybe<Scalars['Boolean']['output']>;
};

export type AccountDisplayBalanceArgs = {
  date: InputMaybe<Scalars['Date']['input']>;
};

export type AccountDisplayBalancePreviewArgs = {
  invertSyncedBalance: InputMaybe<Scalars['Boolean']['input']>;
  useAvailableBalance: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountRecentBalancesArgs = {
  startDate: InputMaybe<Scalars['Date']['input']>;
};

export type AccountSignedBalanceArgs = {
  date: InputMaybe<Scalars['Date']['input']>;
};

export type AccountFilters = {
  accountType: InputMaybe<Scalars['String']['input']>;
  excludeAccountTypes: InputMaybe<Array<Scalars['String']['input']>>;
  groups: InputMaybe<Array<AccountTypeGroup>>;
  ids: InputMaybe<Array<Scalars['ID']['input']>>;
  ignoreHiddenFromNetWorth: InputMaybe<Scalars['Boolean']['input']>;
  ignoreMappedToLiability: InputMaybe<Scalars['Boolean']['input']>;
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
  includeHidden: InputMaybe<Scalars['Boolean']['input']>;
  includeManual: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountMapping = {
  accountId: Scalars['String']['input'];
  baseDate: InputMaybe<Scalars['Date']['input']>;
  dayOfTheMonth: InputMaybe<Scalars['String']['input']>;
  spinwheelLiabilityId: Scalars['String']['input'];
};

export type AccountRecurringFieldsInput = {
  baseDate: InputMaybe<Scalars['Date']['input']>;
  dayOfTheMonth: InputMaybe<Scalars['String']['input']>;
  defaultPaymentAccountId: InputMaybe<Scalars['ID']['input']>;
  defaultPaymentCategoryId: InputMaybe<Scalars['ID']['input']>;
  isActive: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountSnapshot = {
  __typename: 'AccountSnapshot';
  date: Scalars['Date']['output'];
  signedBalance: Scalars['Float']['output'];
};

export type AccountSubtype = {
  __typename: 'AccountSubtype';
  display: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AccountType = {
  __typename: 'AccountType';
  display: Scalars['String']['output'];
  group: Scalars['String']['output'];
  name: Scalars['String']['output'];
  possibleSubtypes: Array<AccountSubtype>;
  showForSyncedAccounts: Scalars['Boolean']['output'];
};

/** An enumeration. */
export enum AccountTypeGroup {
  ASSET = 'asset',
  LIABILITY = 'liability',
  OTHER = 'other',
}

export type AccountTypeOption = {
  __typename: 'AccountTypeOption';
  subtype: Maybe<AccountSubtype>;
  type: AccountType;
};

export type AccountTypeSummary = {
  __typename: 'AccountTypeSummary';
  accounts: Array<Account>;
  isAsset: Scalars['Boolean']['output'];
  isLiability: Scalars['Boolean']['output'];
  totalDisplayBalance: Scalars['Float']['output'];
  type: AccountType;
};

/** Show a filtered list of accounts. */
export type AccountsListBlock = Block & {
  __typename: 'AccountsListBlock';
  accountType: Maybe<Scalars['String']['output']>;
  displayBalanceAsOf: Maybe<Scalars['Date']['output']>;
  link: Maybe<BlockLink>;
};

export type ActiveReferralCampaign = {
  __typename: 'ActiveReferralCampaign';
  campaign: ReferralCampaign;
  creditsEarned: Scalars['Float']['output'];
};

export type ActivityConnection = {
  __typename: 'ActivityConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActivityEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Activity` and its cursor. */
export type ActivityEdge = {
  __typename: 'ActivityEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<ActivityNode>;
};

/** An enumeration. */
export enum ActivityEventType {
  ACCOUNT_ERROR = 'ACCOUNT_ERROR',
  BUDGET_EXCEEDED = 'BUDGET_EXCEEDED',
  FLEX_BUDGET_EXCEEDED = 'FLEX_BUDGET_EXCEEDED',
  GOAL_BALANCES_CHECKIN = 'GOAL_BALANCES_CHECKIN',
  GOAL_CONTRIBUTION = 'GOAL_CONTRIBUTION',
  GOAL_MILESTONE = 'GOAL_MILESTONE',
  LARGE_TRANSACTION = 'LARGE_TRANSACTION',
  NEW_RECURRING_ACCOUNTS_DETECTED = 'NEW_RECURRING_ACCOUNTS_DETECTED',
  NEW_RECURRING_MERCHANTS_DETECTED = 'NEW_RECURRING_MERCHANTS_DETECTED',
  NEW_STATEMENT_BALANCE_RECEIVED = 'NEW_STATEMENT_BALANCE_RECEIVED',
  RECURRING_ACCOUNT_DUE_DATE_APPROACHING = 'RECURRING_ACCOUNT_DUE_DATE_APPROACHING',
  RECURRING_TRANSACTION_EXPECTED = 'RECURRING_TRANSACTION_EXPECTED',
  REFERRAL_SUBSCRIPTION = 'REFERRAL_SUBSCRIPTION',
  STATEMENT_DUE_DATE_PASSED = 'STATEMENT_DUE_DATE_PASSED',
  TRANSACTIONS_NEED_REVIEW = 'TRANSACTIONS_NEED_REVIEW',
  UNCATEGORIZED_TRANSACTIONS = 'UNCATEGORIZED_TRANSACTIONS',
  VENMO_TRANSACTION_DETECTED_ON_NON_VENMO_ACCOUNT = 'VENMO_TRANSACTION_DETECTED_ON_NON_VENMO_ACCOUNT',
}

export type ActivityNode = Node & {
  __typename: 'ActivityNode';
  action: Maybe<Scalars['String']['output']>;
  actionLabel: Maybe<Scalars['String']['output']>;
  body: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dismissedAt: Maybe<Scalars['Date']['output']>;
  eventPriority: EventPriority;
  eventType: Maybe<ActivityEventType>;
  id: Scalars['ID']['output'];
  isPinned: Maybe<Scalars['Boolean']['output']>;
  logo: Maybe<Scalars['String']['output']>;
  readAt: Maybe<Scalars['Date']['output']>;
  secondaryUrl: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type AdviceItem = {
  __typename: 'AdviceItem';
  category: AdviceItemCategory;
  completedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  numTasks: Scalars['Int']['output'];
  numTasksCompleted: Scalars['Int']['output'];
  numTasksRemaining: Scalars['Int']['output'];
  prerequisiteAdviceItems: Array<AdviceItem>;
  tasks: Array<AdviceItemTask>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AdviceItemCategory = {
  __typename: 'AdviceItemCategory';
  color: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AdviceItemTask = {
  __typename: 'AdviceItemTask';
  completedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  dueDate: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startedAt: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
};

export type AdviceItemTaskV2 = {
  __typename: 'AdviceItemTaskV2';
  completedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  dueDate: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startedAt: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
};

export type AdviceItemType = {
  __typename: 'AdviceItemType';
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AdviceItemV2 = {
  __typename: 'AdviceItemV2';
  benefit: Maybe<Scalars['String']['output']>;
  completedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  dismissedAt: Maybe<Scalars['DateTime']['output']>;
  estimatedCompletionDurationMinutes: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  numTasks: Scalars['Int']['output'];
  numTasksCompleted: Scalars['Int']['output'];
  numTasksRemaining: Scalars['Int']['output'];
  reasonNotRelevant: Maybe<Scalars['String']['output']>;
  reasonRecommended: Maybe<Scalars['String']['output']>;
  tasks: Array<AdviceItemTaskV2>;
  title: Scalars['String']['output'];
  type: AdviceItemType;
  valueBadge: Maybe<Scalars['String']['output']>;
};

export type AggregateData = {
  __typename: 'AggregateData';
  groupBy: AggregateGroupBy;
  summary: TransactionsSummary;
};

export type AggregateGroupBy = {
  __typename: 'AggregateGroupBy';
  account: Maybe<Account>;
  category: Maybe<Category>;
  categoryGroup: Maybe<CategoryGroup>;
  day: Maybe<Scalars['Date']['output']>;
  householdMerchant: Maybe<HouseholdMerchant>;
  merchant: Maybe<Merchant>;
  month: Maybe<Scalars['Date']['output']>;
  needsReviewByUser: Maybe<User>;
  quarter: Maybe<Scalars['Date']['output']>;
  week: Maybe<Scalars['Date']['output']>;
  year: Maybe<Scalars['Date']['output']>;
};

export type AggregateHolding = Node & {
  __typename: 'AggregateHolding';
  allocationPercent: Scalars['Float']['output'];
  basis: Scalars['Float']['output'];
  dayChangeDollars: Scalars['Float']['output'];
  dayChangePercent: Scalars['Float']['output'];
  holdings: Array<Holding>;
  id: Scalars['ID']['output'];
  lastSyncedAt: Maybe<Scalars['Date']['output']>;
  quantity: Scalars['Float']['output'];
  security: Maybe<Security>;
  securityPriceChangeDollars: Maybe<Scalars['Float']['output']>;
  securityPriceChangeForPeriod: Maybe<SecurityPriceChange>;
  securityPriceChangePercent: Maybe<Scalars['Float']['output']>;
  totalGainLossDollars: Maybe<Scalars['Float']['output']>;
  totalGainLossPercent: Maybe<Scalars['Float']['output']>;
  totalValue: Scalars['Float']['output'];
};

export type AggregateHoldingSecurityPriceChangeForPeriodArgs = {
  input: InputMaybe<DateRange>;
};

export type AggregateHoldingConnection = {
  __typename: 'AggregateHoldingConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AggregateHoldingEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AggregateHolding` and its cursor. */
export type AggregateHoldingEdge = {
  __typename: 'AggregateHoldingEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<AggregateHolding>;
};

export type AggregateHoldingsInput = {
  accountIds: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AggregateSnapshot = {
  __typename: 'AggregateSnapshot';
  assetsBalance: Maybe<Scalars['Float']['output']>;
  balance: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  liabilitiesBalance: Maybe<Scalars['Float']['output']>;
};

export type AggregateSnapshotFilters = {
  accountType: InputMaybe<Scalars['String']['input']>;
  endDate: InputMaybe<Scalars['Date']['input']>;
  startDate: InputMaybe<Scalars['Date']['input']>;
  useAdaptiveGranularity: InputMaybe<Scalars['Boolean']['input']>;
};

/** Union type of all blocks. New block types should be added here. */
export type AllBlockTypes =
  | AccountsListBlock
  | DividerBlock
  | GoalContributionsListBlock
  | GroupBlock
  | HorizontalBarChartBlock
  | InfoBlock
  | MerchantsListBlock
  | NetWorthChartBlock
  | PageTitleBlock
  | PieChartBlock
  | ShareMonarchBlock
  | ShareableBlock
  | SparklineRowBlock
  | StackedBarChartBlock
  | SuccessBlock
  | TextBlock
  | TransactionsListBlock;

export type AmountCriteriaFieldV2Input = {
  isExpense: Scalars['Boolean']['input'];
  operator: Scalars['String']['input'];
  value: InputMaybe<Scalars['Float']['input']>;
  valueRange: InputMaybe<DecimalRangeSerializerInput>;
};

export type AmountCriteriaV2 = {
  __typename: 'AmountCriteriaV2';
  isExpense: Scalars['Boolean']['output'];
  operator: Scalars['String']['output'];
  value: Maybe<Scalars['Float']['output']>;
  valueRange: Maybe<DecimalRange>;
};

export type AnswerProfileQuestion = {
  __typename: 'AnswerProfileQuestion';
  answer: Maybe<ProfileQuestionAnswer>;
  nextQuestion: Maybe<ProfileQuestion>;
  questionnaireState: Maybe<ProfileQuestionnaireState>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type AnswerProfileQuestionInput = {
  answer: InputMaybe<Scalars['JSONString']['input']>;
  dontKnow: InputMaybe<Scalars['Boolean']['input']>;
  questionName: Scalars['String']['input'];
  questionnaireName: Scalars['String']['input'];
};

export type AnswerProfileQuestionV2 = {
  __typename: 'AnswerProfileQuestionV2';
  answer: Maybe<ProfileQuestionAnswer>;
  nextQuestion: Maybe<ProfileQuestion>;
  questionnaireState: Maybe<ProfileQuestionnaireState>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type AnswerProfileQuestionV2Input = {
  answer: InputMaybe<Scalars['JSONString']['input']>;
  dontKnow: InputMaybe<Scalars['Boolean']['input']>;
  questionName: Scalars['String']['input'];
  questionnaireName: Scalars['String']['input'];
};

export type AppleAccountBalanceInfo = {
  accountID: Scalars['String']['input'];
  available: InputMaybe<AppleBalance>;
  booked: InputMaybe<AppleBalance>;
  currentBalance: AppleCurrentBalance;
  id: Scalars['String']['input'];
};

export type AppleAccountCreditInformation = {
  creditLimit: InputMaybe<AppleCurrencyAmount>;
  minimumNextPaymentAmount: InputMaybe<AppleCurrencyAmount>;
  nextPaymentDueDate: InputMaybe<Scalars['String']['input']>;
  overduePaymentAmount: InputMaybe<AppleCurrencyAmount>;
};

export type AppleAccountInfo = {
  accountDescription: InputMaybe<Scalars['String']['input']>;
  availableBalance: InputMaybe<AppleBalance>;
  bookedBalance: InputMaybe<AppleBalance>;
  creditInformation: InputMaybe<AppleAccountCreditInformation>;
  currencyCode: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  institutionName: Scalars['String']['input'];
};

export type AppleAccountTransactionInfo = {
  accountID: Scalars['String']['input'];
  creditDebitIndicator: Scalars['Int']['input'];
  foreignCurrencyAmount: InputMaybe<AppleCurrencyAmount>;
  id: Scalars['String']['input'];
  merchantCategoryCode: InputMaybe<Scalars['Int']['input']>;
  merchantName: InputMaybe<Scalars['String']['input']>;
  originalTransactionDescription: Scalars['String']['input'];
  postedDate: InputMaybe<Scalars['String']['input']>;
  status: Scalars['Int']['input'];
  transactionAmount: AppleCurrencyAmount;
  transactionDate: Scalars['String']['input'];
  transactionDescription: Scalars['String']['input'];
  transactionType: Scalars['Int']['input'];
};

export type AppleBalance = {
  amount: AppleCurrencyAmount;
  asOfDate: Scalars['String']['input'];
  creditDebitIndicator: Scalars['Int']['input'];
};

export type AppleCurrencyAmount = {
  amount: Scalars['Decimal']['input'];
  currency: Scalars['String']['input'];
};

export type AppleCurrentBalance = {
  available: InputMaybe<AppleBalance>;
  booked: InputMaybe<AppleBalance>;
};

export type ApplyStripeCancelDiscountOffer = {
  __typename: 'ApplyStripeCancelDiscountOffer';
  discountOffer: Maybe<StripeCancellationDiscountOffer>;
  errors: Maybe<PayloadError>;
  subscription: Maybe<HouseholdSubscription>;
};

export type ApplyStripeCancelDiscountOfferInput = {
  reason: CancellationReason;
};

export type ArchiveGoal = {
  __typename: 'ArchiveGoal';
  errors: Maybe<PayloadError>;
  goal: Maybe<GoalV2>;
};

export type ArchiveGoalInput = {
  id: Scalars['ID']['input'];
};

export type AssistantMessage = MessageInterface & {
  __typename: 'AssistantMessage';
  assistantResponseState: AssistantResponseState;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  debugInfo: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  suggestedPrompts: Maybe<Array<Scalars['String']['output']>>;
  threadId: Scalars['ID']['output'];
};

/** An enumeration. */
export enum AssistantResponseState {
  ERROR = 'error',
  LOADING = 'loading',
  SUCCESS = 'success',
}

export type AssistantStatusMessage = MessageInterface & {
  __typename: 'AssistantStatusMessage';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  threadId: Scalars['ID']['output'];
};

export type BatchedPortfolioPerformance = {
  __typename: 'BatchedPortfolioPerformance';
  historicalCharts: Array<Maybe<HistoricalChartForPeriod>>;
  totalValue: Scalars['Float']['output'];
};

/**
 * Base interface that all block types implement.
 *
 * A "Block" is a UI component that report pages are made up of. The block names correspond
 * to react components on the frontend.
 */
export type Block = {
  link: Maybe<BlockLink>;
};

/**
 * A BlockLink is used to describe what happens when the user clicks on a block. Any block
 * can specify a `link` to make it interactive. The block content will be shown in an overlay modal.
 */
export type BlockLink = {
  __typename: 'BlockLink';
  blocks: Array<AllBlockTypes>;
  title: Maybe<Scalars['String']['output']>;
};

/** Represents a list of monthly amounts for a Category. */
export type BudgetCategoryGroupMonthlyAmounts = {
  __typename: 'BudgetCategoryGroupMonthlyAmounts';
  categoryGroup: CategoryGroup;
  monthlyAmounts: Array<BudgetMonthlyAmounts>;
};

/** Represents a list of monthly amounts for a Category. */
export type BudgetCategoryMonthlyAmounts = {
  __typename: 'BudgetCategoryMonthlyAmounts';
  category: Category;
  monthlyAmounts: Array<BudgetMonthlyAmounts>;
};

/** Overall wrapper object for budget page API */
export type BudgetData = {
  __typename: 'BudgetData';
  monthlyAmountsByCategory: Array<BudgetCategoryMonthlyAmounts>;
  monthlyAmountsByCategoryGroup: Array<BudgetCategoryGroupMonthlyAmounts>;
  monthlyAmountsForFlexExpense: BudgetFlexMonthlyAmounts;
  totalsByMonth: Array<BudgetMonthTotals>;
};

/** Represents a list of monthly amounts for flex. */
export type BudgetFlexMonthlyAmounts = {
  __typename: 'BudgetFlexMonthlyAmounts';
  budgetVariability: BudgetVariability;
  monthlyAmounts: Array<BudgetMonthlyAmounts>;
};

export type BudgetItem = {
  __typename: 'BudgetItem';
  /** @deprecated Renamed to planned_cash_flow_amount */
  budgetAmount: Maybe<Scalars['Float']['output']>;
  budgetTarget: Maybe<Scalars['String']['output']>;
  category: Maybe<Category>;
  categoryGroup: Maybe<CategoryGroup>;
  id: Scalars['ID']['output'];
  plannedCashFlowAmount: Maybe<Scalars['Float']['output']>;
};

export type BudgetMonthTotals = {
  __typename: 'BudgetMonthTotals';
  month: Scalars['Date']['output'];
  totalExpenses: BudgetTotals;
  totalFixedExpenses: BudgetTotals;
  totalFlexibleExpenses: BudgetTotals;
  totalIncome: BudgetTotals;
  totalNonMonthlyExpenses: BudgetTotals;
};

/** A single month's planned/actual/remaining/etc amounts (plus other info) */
export type BudgetMonthlyAmounts = {
  __typename: 'BudgetMonthlyAmounts';
  actualAmount: Maybe<Scalars['Float']['output']>;
  cumulativeActualAmount: Maybe<Scalars['Float']['output']>;
  month: Scalars['Date']['output'];
  plannedAmount: Maybe<Scalars['Float']['output']>;
  plannedCashFlowAmount: Maybe<Scalars['Float']['output']>;
  plannedSetAsideAmount: Maybe<Scalars['Float']['output']>;
  previousMonthRolloverAmount: Maybe<Scalars['Float']['output']>;
  remainingAmount: Maybe<Scalars['Float']['output']>;
  rolloverTargetAmount: Maybe<Scalars['Float']['output']>;
  rolloverType: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum BudgetRolloverFrequency {
  EVERY_2_MONTHS = 'every_2_months',
  EVERY_3_MONTHS = 'every_3_months',
  EVERY_4_MONTHS = 'every_4_months',
  EVERY_5_MONTHS = 'every_5_months',
  EVERY_6_MONTHS = 'every_6_months',
  EVERY_7_MONTHS = 'every_7_months',
  EVERY_8_MONTHS = 'every_8_months',
  EVERY_9_MONTHS = 'every_9_months',
  EVERY_10_MONTHS = 'every_10_months',
  EVERY_11_MONTHS = 'every_11_months',
  EVERY_12_MONTHS = 'every_12_months',
  MONTHLY = 'monthly',
  VARIABLE = 'variable',
}

export type BudgetRolloverFrequencyInfo = {
  __typename: 'BudgetRolloverFrequencyInfo';
  label: Scalars['String']['output'];
  value: BudgetRolloverFrequency;
};

export type BudgetRolloverPeriod = {
  __typename: 'BudgetRolloverPeriod';
  endMonth: Maybe<Scalars['Date']['output']>;
  frequency: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  startMonth: Scalars['Date']['output'];
  startingBalance: Maybe<Scalars['Float']['output']>;
  targetAmount: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type BudgetStatus = {
  __typename: 'BudgetStatus';
  hasBudget: Scalars['Boolean']['output'];
  hasTransactions: Scalars['Boolean']['output'];
  willCreateBudgetFromEmptyDefaultCategories: Scalars['Boolean']['output'];
};

/** An enumeration. */
export enum BudgetSystem {
  FIXED_AND_FLEX = 'fixed_and_flex',
  GROUPS_AND_CATEGORIES = 'groups_and_categories',
}

/** An enumeration. */
export enum BudgetTimeframeInput {
  MONTH = 'month',
}

export type BudgetTotals = {
  __typename: 'BudgetTotals';
  actualAmount: Scalars['Float']['output'];
  plannedAmount: Scalars['Float']['output'];
  previousMonthRolloverAmount: Scalars['Float']['output'];
  remainingAmount: Scalars['Float']['output'];
};

/** An enumeration. */
export enum BudgetVariability {
  FIXED = 'fixed',
  FLEXIBLE = 'flexible',
  NON_MONTHLY = 'non_monthly',
}

export type BulkDeleteTransactionsMutation = {
  __typename: 'BulkDeleteTransactionsMutation';
  affectedCount: Scalars['Int']['output'];
  errors: Maybe<PayloadError>;
  success: Scalars['Boolean']['output'];
};

export type BulkTransactionDataParams = {
  excludedTransactionIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  expectedAffectedTransactionCount: InputMaybe<Scalars['Int']['input']>;
  filters: InputMaybe<TransactionFilterInput>;
  isAllSelected: InputMaybe<Scalars['Boolean']['input']>;
  selectedTransactionIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type BulkUpdateTransactionsMutation = {
  __typename: 'BulkUpdateTransactionsMutation';
  affectedCount: Scalars['Int']['output'];
  errors: Maybe<PayloadError>;
  success: Scalars['Boolean']['output'];
};

export type CancelSponsorship = {
  __typename: 'CancelSponsorship';
  canceled: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

export type CancelSponsorshipInput = {
  /** Sponsorship ID */
  id: Scalars['ID']['input'];
};

export type CancelStripeSubscriptionMutation = {
  __typename: 'CancelStripeSubscriptionMutation';
  canceled: Scalars['Boolean']['output'];
  errors: Maybe<PayloadError>;
  subscription: Maybe<HouseholdSubscription>;
  subscriptionCancellationReason: Maybe<SubscriptionCancellationReason>;
};

export type CancelStripeSubscriptionMutationInput = {
  feedback: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
};

/** An enumeration. */
export enum CancellationReason {
  CONNECTION_ISSUES = 'connection_issues',
  MISSING_FEATURES = 'missing_features',
  NOT_ENOUGH_VALUE = 'not_enough_value',
  OTHER = 'other',
  WILL_TURN_ON_LATER = 'will_turn_on_later',
}

export type Category = {
  __typename: 'Category';
  /** Budget totals for a month */
  budgetAmountsForMonth: Maybe<BudgetMonthlyAmounts>;
  budgetVariability: Maybe<BudgetVariability>;
  createdAt: Scalars['DateTime']['output'];
  excludeFromBudget: Maybe<Scalars['Boolean']['output']>;
  group: CategoryGroup;
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDisabled: Scalars['Boolean']['output'];
  isSystemCategory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  /** Get the main budget rollover period */
  rolloverPeriod: Maybe<BudgetRolloverPeriod>;
  systemCategory: Maybe<Scalars['String']['output']>;
  systemCategoryDisplayName: Maybe<Scalars['String']['output']>;
  /** Number of transactions linked to this category */
  transactionsCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CategoryBudgetAmountsForMonthArgs = {
  month: Scalars['Date']['input'];
};

/**
 * Get some information we might want to show a user before deleting a category.
 *
 * This could live under the category object, but rules_count is subject to O(N) query counts.
 */
export type CategoryDeletionInfo = {
  __typename: 'CategoryDeletionInfo';
  category: Category;
  /** Number of rules linked to this category */
  rulesCount: Scalars['Int']['output'];
  /** Number of transactions linked to this category */
  transactionsCount: Scalars['Int']['output'];
};

export type CategoryGroup = {
  __typename: 'CategoryGroup';
  /** Budget totals for a month */
  budgetAmountsForMonth: Maybe<BudgetMonthlyAmounts>;
  budgetVariability: Maybe<BudgetVariability>;
  categories: Array<Category>;
  color: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  groupLevelBudgetingEnabled: Maybe<Scalars['Boolean']['output']>;
  icon: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  /** Get the main budget rollover period */
  rolloverPeriod: Maybe<BudgetRolloverPeriod>;
  systemGroup: Maybe<Scalars['String']['output']>;
  type: CategoryGroupType;
  updatedAt: Scalars['DateTime']['output'];
};

export type CategoryGroupBudgetAmountsForMonthArgs = {
  month: Scalars['Date']['input'];
};

export type CategoryGroupCategoriesArgs = {
  includeDisabledSystemCategories: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CategoryGroupType {
  E = 'E',
  I = 'I',
  T = 'T',
  EXPENSE = 'expense',
  INCOME = 'income',
  TRANSFER = 'transfer',
}

/** An enumeration. */
export enum CategoryType {
  EXPENSE = 'expense',
  INCOME = 'income',
  TRANSFER = 'transfer',
}

export type ChangeStripeSubscriptionMutation = {
  __typename: 'ChangeStripeSubscriptionMutation';
  errors: Maybe<PayloadError>;
  subscription: Maybe<HouseholdSubscription>;
};

export type ChangeStripeSubscriptionMutationInput = {
  stripePriceId: Scalars['String']['input'];
  /** A case-insensitive promotional code, i.e. FALL20 */
  stripePromoCode: InputMaybe<Scalars['String']['input']>;
};

export type CheckActivityMutation = {
  __typename: 'CheckActivityMutation';
  user: User;
};

export type ClearAllActivitiesMutation = {
  __typename: 'ClearAllActivitiesMutation';
  success: Maybe<Scalars['Boolean']['output']>;
};

export type ClearBudgetMutation = {
  __typename: 'ClearBudgetMutation';
  errors: Maybe<PayloadError>;
};

export type ClearBudgetMutationInput = {
  startDate: InputMaybe<Scalars['Date']['input']>;
};

export type CloudinaryUploadInfo = {
  __typename: 'CloudinaryUploadInfo';
  path: Scalars['String']['output'];
  requestParams: CloudinaryUploadRequestParams;
};

export type CloudinaryUploadRequestParams = {
  __typename: 'CloudinaryUploadRequestParams';
  api_key: Scalars['String']['output'];
  folder: Scalars['String']['output'];
  signature: Scalars['String']['output'];
  timestamp: Scalars['Int']['output'];
  upload_preset: Scalars['String']['output'];
};

/**
 * Confirm a user's unconfirmed TOTPDevice.
 *
 * Will return an error if:
 *  - the user doesnt have a TOTPDevice.
 */
export type ConfirmTotpDevice = {
  __typename: 'ConfirmTOTPDevice';
  device: Maybe<TotpDevice>;
  errors: Maybe<PayloadError>;
  isTokenValid: Scalars['Boolean']['output'];
};

export type ConfirmTotpDeviceInput = {
  token: Scalars['String']['input'];
};

export type Constants = {
  __typename: 'Constants';
  activeReferralCampaign: Maybe<ActiveReferralCampaign>;
  budgetRolloverFrequencies: Array<BudgetRolloverFrequencyInfo>;
  institutionReportIssueTypes: Array<InstitutionReportIssueObjectType>;
  monthlyPriceDollars: Scalars['Float']['output'];
  referralsTrialDurationDays: Scalars['Int']['output'];
};

export type ContactSupportFormData = {
  __typename: 'ContactSupportFormData';
  id: Scalars['ID']['output'];
  impacts: Array<ContactSupportImpactField>;
  reasonsForTopic: Array<ContactSupportReasonField>;
  requiredFieldsForReason: Maybe<Array<Scalars['String']['output']>>;
  topics: Array<ContactSupportTopicField>;
};

export type ContactSupportFormDataReasonsForTopicArgs = {
  topic: InputMaybe<ContactSupportTopic>;
};

export type ContactSupportFormDataRequiredFieldsForReasonArgs = {
  reason: InputMaybe<Scalars['String']['input']>;
};

export type ContactSupportFormDataTopicsArgs = {
  formType: InputMaybe<ContactSupportFormType>;
};

/** An enumeration. */
export enum ContactSupportFormType {
  PRIMARY = 'primary',
  REFUND_REQUEST = 'refund_request',
}

/** An enumeration. */
export enum ContactSupportImpact {
  NO_USE = 'no_use',
  PARTS_UNUSABLE = 'parts_unusable',
  QUESTION_OR_FEEDBACK = 'question_or_feedback',
  SLOWING_ME_DOWN = 'slowing_me_down',
}

export type ContactSupportImpactField = {
  __typename: 'ContactSupportImpactField';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  value: ContactSupportImpact;
};

/** An enumeration. */
export enum ContactSupportReason {
  BALANCE_INVERTED = 'balance_inverted',
  BILLING_ERRORS = 'billing_errors',
  BILLING_SOMETHING_ELSE = 'billing_something_else',
  BUG_REPORT = 'bug_report',
  CANCELLATION_AND_REFUNDS = 'cancellation_and_refunds',
  CONNECTION_SOMETHING_ELSE = 'connection_something_else',
  DELETE_ACCOUNT = 'delete_account',
  DONT_USE_IT_ENOUGH_REFUND_REASON = 'dont_use_it_enough_refund_reason',
  DUPLICATE_ACCOUNTS = 'duplicate_accounts',
  DUPLICATE_TRANSACTIONS = 'duplicate_transactions',
  EXTENDED_AND_NEW_TRIALS = 'extended_and_new_trials',
  FEEDBACK_ABOUT_TRANSACTIONS_VIEW = 'feedback_about_transactions_view',
  FORGOT_TO_CANCEL_REFUND_REASON = 'forgot_to_cancel_refund_reason',
  GENERAL_SOMETHING_ELSE = 'general_something_else',
  HOLDINGS_NOT_SHOWING = 'holdings_not_showing',
  HOW_IT_WORKS = 'how_it_works',
  HOW_IT_WORKS_GOALS = 'how_it_works_goals',
  HOW_IT_WORKS_PLAN_BUDGET = 'how_it_works_plan_budget',
  INCONSISTENT_BUDGETING_DATA = 'inconsistent_budgeting_data',
  INCORRECT_BALANCE = 'incorrect_balance',
  INCORRECT_INVESTMENT_BALANCE = 'incorrect_investment_balance',
  INITIAL_CONNECTION_FAILURE = 'initial_connection_failure',
  INSTITUTION_KEEPS_DISCONNECTING = 'institution_keeps_disconnecting',
  LINKING_ACCOUNTS_TO_GOALS = 'linking_accounts_to_goals',
  LINKING_TRANSACTIONS_TO_GOALS = 'linking_transactions_to_goals',
  MISSING_ACCOUNTS = 'missing_accounts',
  MISSING_DELAYED_TRANS = 'missing_delayed_trans',
  MISSING_OR_DUPE_ACCOUNTS = 'missing_or_dupe_accounts',
  MISSING_STOCK_SYMBOL = 'missing_stock_symbol',
  MISSING_TRANSACTIONS = 'missing_transactions',
  MOBILE_SUBSCRIPTIONS = 'mobile_subscriptions',
  MONARCH_ASSISTANT = 'monarch_assistant',
  NOTIFICATIONS = 'notifications',
  PAYMENT_ISSUE = 'payment_issue',
  PRODUCT_FEATURES_NOT_WORKING_REFUND_REASON = 'product_features_not_working_refund_reason',
  PRODUCT_FEEDBACK = 'product_feedback',
  PRODUCT_QUESTION_GOALS_SOMETHING_ELSE = 'product_question_goals_something_else',
  PRODUCT_QUESTION_INVESTMENTS_SOMETHING_ELSE = 'product_question_investments_something_else',
  PRODUCT_QUESTION_PLAN_BUDGET_SOMETHING_ELSE = 'product_question_plan_budget_something_else',
  PRODUCT_QUESTION_RECURRING_AND_BILLS = 'product_question_recurring_and_bills',
  PRODUCT_QUESTION_TRANSACTIONS_SOMETHING_ELSE = 'product_question_transactions_something_else',
  REFERRALS_AND_PROMOTION_CODES = 'referrals_and_promotion_codes',
  SETTINGS_SOMETHING_ELSE = 'settings_something_else',
  SUBSCRIPTION_CHANGES = 'subscription_changes',
  SWITCH_BILLING_PLATFORMS = 'switch_billing_platforms',
  TOO_EXPENSIVE_REFUND_REASON = 'too_expensive_refund_reason',
  TOO_MANY_ACCOUNT_CONNECTION_ISSUES_REFUND_REASON = 'too_many_account_connection_issues_refund_reason',
  TRANSACTION_RULES = 'transaction_rules',
  WRONG_ACCOUNT_TYPE = 'wrong_account_type',
}

export type ContactSupportReasonField = {
  __typename: 'ContactSupportReasonField';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  requiredFields: Maybe<Array<Scalars['String']['output']>>;
  value: ContactSupportReason;
};

/** An enumeration. */
export enum ContactSupportTopic {
  BILLING = 'billing',
  CONNECTION_ISSUE = 'connection_issue',
  GENERAL = 'general',
  PRODUCT_QUESTION_GOALS = 'product_question_goals',
  PRODUCT_QUESTION_INVESTMENTS = 'product_question_investments',
  PRODUCT_QUESTION_PLAN_BUDGET = 'product_question_plan_budget',
  PRODUCT_QUESTION_RECURRING = 'product_question_recurring',
  PRODUCT_QUESTION_TRANSACTIONS = 'product_question_transactions',
  REFUND = 'refund',
  SETTINGS = 'settings',
}

export type ContactSupportTopicField = {
  __typename: 'ContactSupportTopicField';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  value: ContactSupportTopic;
};

export type CopySnapshots = {
  __typename: 'CopySnapshots';
  errors: Maybe<PayloadError>;
  numSnapshotsCopied: Maybe<Scalars['Int']['output']>;
  toAccount: Maybe<Account>;
};

export type CopySnapshotsInput = {
  cutoverDate: Scalars['Date']['input'];
  fromAccountId: Scalars['ID']['input'];
  toAccountId: Scalars['ID']['input'];
};

export type CreateAccountsFromPlaidToken = {
  __typename: 'CreateAccountsFromPlaidToken';
  accounts: Array<Account>;
  errors: Maybe<PayloadError>;
};

export type CreateBudgetMutation = {
  __typename: 'CreateBudgetMutation';
  errors: Maybe<PayloadError>;
};

export type CreateBudgetMutationInput = {
  startDate: InputMaybe<Scalars['Date']['input']>;
  timeframe: InputMaybe<BudgetTimeframeInput>;
};

export type CreateCategoryGroupInput = {
  budgetVariability: InputMaybe<Scalars['String']['input']>;
  color: InputMaybe<Scalars['String']['input']>;
  groupLevelBudgetingEnabled: InputMaybe<Scalars['Boolean']['input']>;
  icon: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** UUID of the Group the new Group should be inserted before. Leave blank if new group should be at the end. */
  orderBefore: InputMaybe<Scalars['UUID']['input']>;
  rolloverEnabled: InputMaybe<Scalars['Boolean']['input']>;
  rolloverStartMonth: InputMaybe<Scalars['Date']['input']>;
  rolloverStartingBalance: InputMaybe<Scalars['Float']['input']>;
  rolloverType: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type CreateCategoryGroupMutation = {
  __typename: 'CreateCategoryGroupMutation';
  categoryGroup: Maybe<CategoryGroup>;
};

export type CreateCategoryInput = {
  applyRolloverBudgetToFutureMonths: InputMaybe<Scalars['Boolean']['input']>;
  budgetVariability: InputMaybe<Scalars['String']['input']>;
  excludeFromBudget: InputMaybe<Scalars['Boolean']['input']>;
  group: Scalars['ID']['input'];
  icon: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rolloverEnabled: InputMaybe<Scalars['Boolean']['input']>;
  rolloverFrequency: InputMaybe<Scalars['String']['input']>;
  rolloverFutureBudgetAllocation: InputMaybe<Scalars['Float']['input']>;
  rolloverStartMonth: InputMaybe<Scalars['Date']['input']>;
  rolloverStartingBalance: InputMaybe<Scalars['Float']['input']>;
  rolloverTargetAmount: InputMaybe<Scalars['Float']['input']>;
  rolloverType: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<Scalars['String']['input']>;
};

export type CreateCategoryPayload = {
  __typename: 'CreateCategoryPayload';
  category: Maybe<Category>;
  errors: Maybe<PayloadError>;
};

export type CreateCoinbaseAccountInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type CreateCoinbaseAccountMutation = {
  __typename: 'CreateCoinbaseAccountMutation';
  account: Maybe<Account>;
  errors: Maybe<PayloadError>;
};

/** Generates a Connect "Fix" url to re-link an existing credential */
export type CreateFinicityConnectFixUrlMutation = {
  __typename: 'CreateFinicityConnectFixUrlMutation';
  errors: Maybe<PayloadError>;
  url: Maybe<Scalars['String']['output']>;
};

/** Generates a Connect url to begin linking a new credential */
export type CreateFinicityConnectUrlMutation = {
  __typename: 'CreateFinicityConnectUrlMutation';
  errors: Maybe<PayloadError>;
  url: Maybe<Scalars['String']['output']>;
};

/**
 * This is similar to CreateAccountsFromPlaidToken, but for Finicity. It will
 * fetch all of the customer's accounts from Finicity and create Credentials
 * for those that don't already exist.
 */
export type CreateFinicityCredentialsMutation = {
  __typename: 'CreateFinicityCredentialsMutation';
  /** Newly created credentials */
  credentials: Array<Credential>;
  errors: Maybe<PayloadError>;
};

export type CreateGoal = {
  __typename: 'CreateGoal';
  errors: Maybe<PayloadError>;
  goal: Maybe<GoalV2>;
};

export type CreateGoalAccountAllocation = {
  __typename: 'CreateGoalAccountAllocation';
  errors: Maybe<PayloadError>;
  goal: Maybe<GoalV2>;
  goalAccountAllocation: Maybe<GoalAccountAllocation>;
};

export type CreateGoalAccountAllocationInput = {
  accountId: Scalars['ID']['input'];
  amount: InputMaybe<Scalars['Float']['input']>;
  goalId: Scalars['ID']['input'];
  useEntireAccountBalance: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateGoalContributionMutation = {
  __typename: 'CreateGoalContributionMutation';
  errors: Maybe<PayloadError>;
  goalContribution: GoalContribution;
};

export type CreateGoalContributionMutationInput = {
  amount: Scalars['Float']['input'];
  date: Scalars['Date']['input'];
  goal: Scalars['String']['input'];
  notes: InputMaybe<Scalars['String']['input']>;
};

export type CreateGoalContributionsMutation = {
  __typename: 'CreateGoalContributionsMutation';
  errors: Maybe<PayloadError>;
  goalContributions: Array<GoalContribution>;
};

export type CreateGoalContributionsMutationInput = {
  amount: Scalars['Float']['input'];
  date: Scalars['Date']['input'];
  goal: Scalars['String']['input'];
  notes: InputMaybe<Scalars['String']['input']>;
};

export type CreateGoalInput = {
  imageStorageProvider: InputMaybe<Scalars['String']['input']>;
  imageStorageProviderId: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  objective: Scalars['String']['input'];
  plannedMonthlyContribution: InputMaybe<Scalars['Float']['input']>;
  plannedMonthlyPretaxContribution: InputMaybe<Scalars['Float']['input']>;
  startingAmount: InputMaybe<Scalars['Float']['input']>;
  targetAmount: InputMaybe<Scalars['Float']['input']>;
};

export type CreateGoalMutation = {
  __typename: 'CreateGoalMutation';
  errors: Maybe<PayloadError>;
  goal: Goal;
};

export type CreateGoalMutationInput = {
  balancesFromAdjustmentDate: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  goalType: Scalars['String']['input'];
  icon: Scalars['String']['input'];
  name: Scalars['String']['input'];
  plannedMonthlyContribution: Scalars['Float']['input'];
  plannedStartingBalance: InputMaybe<Scalars['Float']['input']>;
  startingBalance: InputMaybe<Scalars['Float']['input']>;
  targetAmount: Scalars['Float']['input'];
  targetDate: Scalars['Date']['input'];
};

export type CreateGoals = {
  __typename: 'CreateGoals';
  errors: Maybe<PayloadError>;
  goals: Maybe<Array<Maybe<GoalV2>>>;
};

export type CreateGoalsInput = {
  goals: Array<CreateGoalInput>;
};

export type CreateHouseholdAccessGrant = {
  __typename: 'CreateHouseholdAccessGrant';
  accessGrant: Maybe<HouseholdAccessGrant>;
  errors: Maybe<PayloadError>;
};

export type CreateHouseholdAccessGrantInput = {
  email: Scalars['String']['input'];
};

export type CreateInstitutionIssueReportMutationInput = {
  accountId: InputMaybe<Scalars['ID']['input']>;
  additionalNotes: InputMaybe<Scalars['String']['input']>;
  credential: Scalars['ID']['input'];
  credentialId: InputMaybe<Scalars['ID']['input']>;
  grantSupportAccountAccess: InputMaybe<Scalars['Boolean']['input']>;
  issues: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateInstitutionIssueReportMutationPayload = {
  __typename: 'CreateInstitutionIssueReportMutationPayload';
  errors: Maybe<PayloadError>;
  institutionIssueReport: Maybe<InstitutionIssueReport>;
};

/** Generates a Connect "Fix" url to re-link an existing credential */
export type CreateMxConnectFixUrlMutation = {
  __typename: 'CreateMXConnectFixUrlMutation';
  errors: Maybe<PayloadError>;
  url: Maybe<Scalars['String']['output']>;
};

/** Request a Connect URL to create a new member */
export type CreateMxConnectUrlMutation = {
  __typename: 'CreateMXConnectUrlMutation';
  errors: Maybe<PayloadError>;
  url: Maybe<Scalars['String']['output']>;
};

/**
 * This is similar to CreateAccountsFromPlaidToken, but for MX. It will
 * create credentials, perform the initial sync, and call extend history.
 */
export type CreateMxCredentialsMutation = {
  __typename: 'CreateMXCredentialsMutation';
  /** Newly created credentials */
  credentials: Array<Credential>;
  errors: Maybe<PayloadError>;
};

export type CreateManualAccountMutation = {
  __typename: 'CreateManualAccountMutation';
  account: Maybe<Account>;
  errors: Maybe<PayloadError>;
};

export type CreateManualAccountMutationInput = {
  currentBalance: InputMaybe<Scalars['Float']['input']>;
  dataProvider: InputMaybe<Scalars['String']['input']>;
  displayBalance: InputMaybe<Scalars['Float']['input']>;
  includeInNetWorth: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  subtype: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateManualHolding = {
  __typename: 'CreateManualHolding';
  errors: Maybe<PayloadError>;
  holding: Maybe<Holding>;
};

export type CreateManualHoldingInput = {
  /** ID of the account where the manual holding will be created */
  accountId: InputMaybe<Scalars['ID']['input']>;
  /** Holding quantity */
  quantity: Scalars['Float']['input'];
  /** ID of the security associated with the manual holding */
  securityId: Scalars['ID']['input'];
};

export type CreateManualInvestmentsAccount = {
  __typename: 'CreateManualInvestmentsAccount';
  account: Maybe<Account>;
  errors: Maybe<PayloadError>;
};

export type CreateManualInvestmentsAccountInput = {
  /** Initial balance for accounts tracking balances */
  initialBalance: InputMaybe<Scalars['Float']['input']>;
  /** Initial holdings for accounts tracking holdings */
  initialHoldings: InputMaybe<Array<InputMaybe<InitialHoldingInput>>>;
  manualInvestmentsTrackingMethod: InputMaybe<ManualInvestmentsAccountTrackingMethod>;
  /** Account name */
  name: Scalars['String']['input'];
  /** Account subtype */
  subtype: Scalars['String']['input'];
};

export type CreateMessageThread = {
  __typename: 'CreateMessageThread';
  messageThread: Maybe<MessageThread>;
};

export type CreateOrUpdateGoalPlannedContribution = {
  __typename: 'CreateOrUpdateGoalPlannedContribution';
  errors: Maybe<PayloadError>;
  goalPlannedContribution: Maybe<GoalPlannedContributionV2>;
};

export type CreateOrUpdateGoalPlannedContributionInput = {
  amount: Scalars['Float']['input'];
  goalId: Scalars['ID']['input'];
  month: Scalars['Date']['input'];
};

export type CreatePlaidLinkTokenMutation = {
  __typename: 'CreatePlaidLinkTokenMutation';
  error: Maybe<Scalars['String']['output']>;
  linkToken: Maybe<Scalars['String']['output']>;
};

export type CreateRecoveryCodesMutation = {
  __typename: 'CreateRecoveryCodesMutation';
  staticTokens: Maybe<Array<Maybe<StaticToken>>>;
};

export type CreateRecurringTransactionStreamMutation = {
  __typename: 'CreateRecurringTransactionStreamMutation';
  recurringTransactionStream: Maybe<RecurringTransactionStream>;
};

export type CreateReviewCheckinInput = {
  reviewStartDate: Scalars['Date']['input'];
  reviewType: InputMaybe<Scalars['String']['input']>;
};

export type CreateReviewCheckinMutation = {
  __typename: 'CreateReviewCheckinMutation';
  checkin: ReviewCheckin;
};

/**
 * Endpoint to create the account mappping in the database.
 * For now it doesn't do anything.
 */
export type CreateSpinwheelAccountMappingMutation = {
  __typename: 'CreateSpinwheelAccountMappingMutation';
  errors: Maybe<PayloadError>;
  success: Scalars['Boolean']['output'];
};

/** Endpoint to create / authenticate a user with Spinwheel and get a token */
export type CreateSpinwheelTokenMutation = {
  __typename: 'CreateSpinwheelTokenMutation';
  errors: Maybe<PayloadError>;
  /** Spinwheel token */
  token: Scalars['String']['output'];
};

export type CreateSponsorshipInvites = {
  __typename: 'CreateSponsorshipInvites';
  errors: Maybe<PayloadError>;
  invites: Array<SubscriptionSponsorshipInvite>;
};

export type CreateSponsorshipInvitesInput = {
  /** Invites */
  invites: InputMaybe<Array<SubscriptionSponsorshipInviteInput>>;
  /** Invites to create */
  quantity: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Creates a Stripe subscription for a user's household. This mutation should only be called
 * in two cases:
 *
 * (1) The user is buying a subscription for the first time on web
 * (2) The user is switching their subscription billing from ReveneueCat to Stripe i.e. mobile
 *     to web
 */
export type CreateStripeSubscriptionMutation = {
  __typename: 'CreateStripeSubscriptionMutation';
  errors: Maybe<PayloadError>;
  subscription: Maybe<HouseholdSubscription>;
};

export type CreateStripeSubscriptionMutationInput = {
  paymentMethodId: InputMaybe<Scalars['String']['input']>;
  stripePriceId: Scalars['String']['input'];
  /** A case-insensitive promotional code, i.e. FALL20 */
  stripePromoCode: InputMaybe<Scalars['String']['input']>;
};

export type CreateSyncedRealEstateAccount = {
  __typename: 'CreateSyncedRealEstateAccount';
  account: Maybe<Account>;
  errors: Maybe<PayloadError>;
};

export type CreateSyncedRealEstateAccountInput = {
  currentBalance: Scalars['Float']['input'];
  includeInNetWorth: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  subtype: Scalars['String']['input'];
  zpid: Scalars['String']['input'];
};

export type CreateSyncedVehicleAccount = {
  __typename: 'CreateSyncedVehicleAccount';
  account: Maybe<Account>;
  errors: Maybe<PayloadError>;
};

export type CreateSyncedVehicleAccountInput = {
  name: Scalars['String']['input'];
  subtype: Scalars['String']['input'];
  vin: Scalars['String']['input'];
};

export type CreateTransactionMutation = {
  __typename: 'CreateTransactionMutation';
  errors: Maybe<PayloadError>;
  transaction: Maybe<Transaction>;
};

export type CreateTransactionMutationInput = {
  accountId: Scalars['ID']['input'];
  amount: Scalars['Float']['input'];
  categoryId: Scalars['ID']['input'];
  date: Scalars['Date']['input'];
  merchantName: Scalars['String']['input'];
  notes: InputMaybe<Scalars['String']['input']>;
  shouldUpdateBalance: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateTransactionRuleInput = {
  accountIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  addTagsAction: InputMaybe<Array<Scalars['ID']['input']>>;
  amountCriteria: InputMaybe<AmountCriteriaFieldV2Input>;
  applyToExistingTransactions: InputMaybe<Scalars['Boolean']['input']>;
  categoryIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  linkGoalAction: InputMaybe<Scalars['ID']['input']>;
  merchantCriteria: InputMaybe<Array<InputMaybe<MerchantCriterionInput>>>;
  merchantCriteriaUseOriginalStatement: InputMaybe<Scalars['Boolean']['input']>;
  needsReviewByUserAction: InputMaybe<Scalars['ID']['input']>;
  reviewStatusAction: InputMaybe<Scalars['String']['input']>;
  sendNotificationAction: InputMaybe<Scalars['Boolean']['input']>;
  setCategoryAction: InputMaybe<Scalars['ID']['input']>;
  setHideFromReportsAction: InputMaybe<Scalars['Boolean']['input']>;
  setMerchantAction: InputMaybe<Scalars['String']['input']>;
  splitTransactionsAction: InputMaybe<SplitTransactionsActionInput>;
};

export type CreateTransactionRuleV2Mutation = {
  __typename: 'CreateTransactionRuleV2Mutation';
  errors: Maybe<PayloadError>;
  transactionRule: Maybe<TransactionRuleV2>;
};

export type CreateTransactionTag = {
  __typename: 'CreateTransactionTag';
  errors: Maybe<PayloadError>;
  tag: Maybe<TransactionTag>;
};

export type CreateTransactionTagInput = {
  color: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/**
 * Create an unconfirmed TOTP device for the user.
 *
 * If the user already has an unconfirmed TOTP device, just return it.
 * If the user has a confirmed TOTP device, return an error.
 */
export type CreateUnconfirmedTotpDevice = {
  __typename: 'CreateUnconfirmedTOTPDevice';
  device: Maybe<TotpDevice>;
  errors: Maybe<PayloadError>;
};

export type Credential = {
  __typename: 'Credential';
  accounts: Array<Account>;
  /** @deprecated use field on account instead */
  canBeForceRefreshed: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  dataProvider: DataProviderLegacy;
  disconnectedFromDataProviderAt: Maybe<Scalars['DateTime']['output']>;
  displayLastSyncedAt: Scalars['DateTime']['output'];
  displayLastUpdatedAt: Maybe<Scalars['DateTime']['output']>;
  displayName: Scalars['String']['output'];
  forceRefreshLastRequestedAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated use field on account instead */
  hasSyncInProgress: Scalars['Boolean']['output'];
  /** @deprecated use field on account instead */
  hasSyncOrRecentRefreshRequest: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  institution: Institution;
  isHistoricalUpdateCompleted: Scalars['Boolean']['output'];
  lastForcedRefreshAt: Maybe<Scalars['DateTime']['output']>;
  updateRequired: Scalars['Boolean']['output'];
};

export type CredentialFilters = {
  institutionId: InputMaybe<Scalars['ID']['input']>;
  plaidInstitutionId: InputMaybe<Scalars['String']['input']>;
};

export type CreditReportLiabilityAccount = {
  __typename: 'CreditReportLiabilityAccount';
  account: Maybe<Account>;
  accountType: Maybe<Scalars['String']['output']>;
  closedDate: Maybe<Scalars['Date']['output']>;
  currentTotalBalance: Maybe<Scalars['Decimal']['output']>;
  description: Maybe<Scalars['String']['output']>;
  household: Household;
  id: Scalars['ID']['output'];
  isOpen: Maybe<Scalars['Boolean']['output']>;
  lastStatement: Maybe<LiabilityStatement>;
  liabilityType: Scalars['String']['output'];
  logoUrl: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  recurringTransactionStream: Maybe<RecurringTransactionStream>;
  reportedDate: Maybe<Scalars['Date']['output']>;
  spinwheelLiabilityId: Scalars['String']['output'];
  spinwheelUser: SpinwheelUser;
  status: Maybe<Scalars['String']['output']>;
  termsFrequency: Maybe<Scalars['String']['output']>;
};

export type DashboardConfig = {
  __typename: 'DashboardConfig';
  mobile: PlatformDashboardConfig;
  web: PlatformDashboardConfig;
};

export enum DataProviderLegacy {
  APPLE_CARD = 'APPLE_CARD',
  APPLE_FINANCE_KIT = 'APPLE_FINANCE_KIT',
  COINBASE = 'COINBASE',
  FINICITY = 'FINICITY',
  MX = 'MX',
  PLAID = 'PLAID',
  VIN_AUDIT = 'VIN_AUDIT',
  ZILLOW = 'ZILLOW',
}

export type DateRange = {
  /** Inclusive end date of the query. Defaults to today. */
  endDate: InputMaybe<Scalars['Date']['input']>;
  /** Inclusive start date of the query. Defaults to 90 days ago. */
  startDate: InputMaybe<Scalars['Date']['input']>;
};

export type DecimalRange = {
  __typename: 'DecimalRange';
  lower: Maybe<Scalars['Float']['output']>;
  upper: Maybe<Scalars['Float']['output']>;
};

export type DecimalRangeSerializerInput = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

export type DeleteAccountLogoMutation = {
  __typename: 'DeleteAccountLogoMutation';
  account: Maybe<Account>;
  errors: Maybe<PayloadError>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteAccountMutation = {
  __typename: 'DeleteAccountMutation';
  deleted: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

export type DeleteAllHouseholdTransactionTags = {
  __typename: 'DeleteAllHouseholdTransactionTags';
  errors: Maybe<PayloadError>;
};

export type DeleteAllTransactionRules = {
  __typename: 'DeleteAllTransactionRules';
  deleted: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

export type DeleteCategoryGroupMutation = {
  __typename: 'DeleteCategoryGroupMutation';
  deleted: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

/**
 * Delete (or disable) a category, and move all rules / transactions to
 * move_to_category_id. If move_to_category_id is not specified, use UNCATEGORIZED.
 */
export type DeleteCategoryMutation = {
  __typename: 'DeleteCategoryMutation';
  deleted: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

export type DeleteCredentialInput = {
  credentialId: Scalars['ID']['input'];
};

export type DeleteCredentialMutation = {
  __typename: 'DeleteCredentialMutation';
  deleted: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

export type DeleteGoal = {
  __typename: 'DeleteGoal';
  errors: Maybe<PayloadError>;
  success: Scalars['Boolean']['output'];
};

export type DeleteGoalAccountAllocation = {
  __typename: 'DeleteGoalAccountAllocation';
  account: Maybe<Account>;
  errors: Maybe<PayloadError>;
  goal: Maybe<GoalV2>;
  success: Scalars['Boolean']['output'];
};

export type DeleteGoalAccountAllocationInput = {
  accountId: Scalars['ID']['input'];
  goalId: Scalars['ID']['input'];
};

export type DeleteGoalContributionMutation = {
  __typename: 'DeleteGoalContributionMutation';
  deleted: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

export type DeleteGoalInput = {
  id: Scalars['ID']['input'];
};

export type DeleteGoalMutation = {
  __typename: 'DeleteGoalMutation';
  deleted: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

export type DeleteHolding = {
  __typename: 'DeleteHolding';
  deleted: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

export type DeleteHouseholdMutation = {
  __typename: 'DeleteHouseholdMutation';
  deleted: Scalars['Boolean']['output'];
  errors: Maybe<PayloadError>;
};

export type DeleteHouseholdMutationInput = {
  feedback: InputMaybe<Scalars['String']['input']>;
  householdId: Scalars['UUID']['input'];
  reason: InputMaybe<Scalars['String']['input']>;
};

export type DeleteMerchantLogoMutation = {
  __typename: 'DeleteMerchantLogoMutation';
  errors: Maybe<PayloadError>;
  merchant: Maybe<Merchant>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteMerchantMutation = {
  __typename: 'DeleteMerchantMutation';
  success: Scalars['Boolean']['output'];
};

export type DeleteMessageThread = {
  __typename: 'DeleteMessageThread';
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteRecurringTransactionStreamMutation = {
  __typename: 'DeleteRecurringTransactionStreamMutation';
  success: Scalars['Boolean']['output'];
};

export type DeleteSyncedSnapshotsInput = {
  beforeDate: Scalars['Date']['input'];
  expectedAffectedSnapshotsCount: Scalars['Int']['input'];
};

export type DeleteSyncedSnapshotsMutation = {
  __typename: 'DeleteSyncedSnapshotsMutation';
  errors: Maybe<PayloadError>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteSyncedTransactionsInput = {
  beforeDate: Scalars['Date']['input'];
  expectedAffectedTransactionsCount: Scalars['Int']['input'];
};

export type DeleteSyncedTransactionsMutation = {
  __typename: 'DeleteSyncedTransactionsMutation';
  errors: Maybe<PayloadError>;
  success: Maybe<Scalars['Boolean']['output']>;
};

/**
 * Delete a user's TOTP device. This assumes the user only has one, which
 * is a safe assumption at the moment.
 */
export type DeleteTotpDevice = {
  __typename: 'DeleteTOTPDevice';
  deleted: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

export type DeleteTransactionAttachmentMutation = {
  __typename: 'DeleteTransactionAttachmentMutation';
  deleted: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

export type DeleteTransactionMutation = {
  __typename: 'DeleteTransactionMutation';
  deleted: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

export type DeleteTransactionMutationInput = {
  transactionId: Scalars['UUID']['input'];
};

export type DeleteTransactionRuleV2Mutation = {
  __typename: 'DeleteTransactionRuleV2Mutation';
  deleted: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

export type DeleteTransactionTag = {
  __typename: 'DeleteTransactionTag';
  errors: Maybe<PayloadError>;
};

export type DeleteTransactionsInput = {
  beforeDate: Scalars['Date']['input'];
  expectedAffectedTransactionsCount: Scalars['Int']['input'];
  includeManual: InputMaybe<Scalars['Boolean']['input']>;
  includeSynced: InputMaybe<Scalars['Boolean']['input']>;
  includeUploaded: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteTransactionsMutation = {
  __typename: 'DeleteTransactionsMutation';
  errors: Maybe<PayloadError>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteUserFromHouseholdMutation = {
  __typename: 'DeleteUserFromHouseholdMutation';
  deleted: Scalars['Boolean']['output'];
  errors: Maybe<PayloadError>;
};

export type DeleteUserFromHouseholdMutationInput = {
  userId: Scalars['UUID']['input'];
};

export type DeleteUserProfilePicture = {
  __typename: 'DeleteUserProfilePicture';
  deleted: Maybe<Scalars['Boolean']['output']>;
  errors: Maybe<PayloadError>;
};

export type DidRequestAppStoreReviewMutation = {
  __typename: 'DidRequestAppStoreReviewMutation';
  user: User;
};

export type DisconnectCredentialFromDataProviderInput = {
  credentialId: Scalars['ID']['input'];
};

export type DisconnectCredentialFromDataProviderMutation = {
  __typename: 'DisconnectCredentialFromDataProviderMutation';
  credential: Maybe<Credential>;
  errors: Maybe<PayloadError>;
};

export type DisconnectCredentialMutation = {
  __typename: 'DisconnectCredentialMutation';
  success: Scalars['Boolean']['output'];
};

export type DisconnectCredentialsFromDataProviderInput = {
  credentialIds: Array<Scalars['ID']['input']>;
};

export type DisconnectCredentialsFromDataProviderMutation = {
  __typename: 'DisconnectCredentialsFromDataProviderMutation';
  credentials: Maybe<Array<Maybe<Credential>>>;
  errors: Maybe<PayloadError>;
};

/** Endpoint to disconnect a user from Spinwheel. */
export type DisconnectSpinwheel = {
  __typename: 'DisconnectSpinwheel';
  errors: Maybe<PayloadError>;
  isDisconnected: Scalars['Boolean']['output'];
};

export type DismissActivity = {
  __typename: 'DismissActivity';
  node: ActivityNode;
};

/** Block used to separate other blocks. */
export type DividerBlock = Block & {
  __typename: 'DividerBlock';
  link: Maybe<BlockLink>;
};

export type DownloadTransactionsSession = {
  __typename: 'DownloadTransactionsSession';
  errorMessage: Maybe<Scalars['String']['output']>;
  sessionKey: Scalars['String']['output'];
  status: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type EntitlementConstantItem = {
  __typename: 'EntitlementConstantItem';
  entitlement: SubscriptionEntitlement;
  maxCredentials: Maybe<Scalars['Int']['output']>;
  maxTransactionRules: Maybe<Scalars['Int']['output']>;
};

export type EntitlementParams = {
  __typename: 'EntitlementParams';
  constants: Array<EntitlementConstantItem>;
  features: Array<FeatureEntitlementItem>;
};

/** An enumeration. */
export enum ErrorCode {
  CREDENTIAL_REFRESH_UNAVAILABLE = 'CREDENTIAL_REFRESH_UNAVAILABLE',
  DELETE_SNAPSHOTS_HISTORY_UNAVAILABLE = 'DELETE_SNAPSHOTS_HISTORY_UNAVAILABLE',
  DELETE_TRANSACTIONS_HISTORY_UNAVAILABLE = 'DELETE_TRANSACTIONS_HISTORY_UNAVAILABLE',
  DOWNGRADE_ACTIONS_REQUIRED = 'DOWNGRADE_ACTIONS_REQUIRED',
  EMAIL_UNVERIFIED_ON_PROVIDER = 'EMAIL_UNVERIFIED_ON_PROVIDER',
  HOUSEHOLD_INVITE_USER_ALREADY_EXISTS = 'HOUSEHOLD_INVITE_USER_ALREADY_EXISTS',
  HOUSEHOLD_SCHEDULED_FOR_DELETION = 'HOUSEHOLD_SCHEDULED_FOR_DELETION',
  SPONSOR_NOT_FOUND = 'SPONSOR_NOT_FOUND',
  SUBSCRIPTION_ENDED = 'SUBSCRIPTION_ENDED',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  USER_ALREADY_SIGNED_UP = 'USER_ALREADY_SIGNED_UP',
  USER_IS_ONBOARDING = 'USER_IS_ONBOARDING',
}

/** An enumeration. */
export enum EventPriority {
  ERROR = 'ERROR',
  INFORMATIONAL = 'INFORMATIONAL',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export type FeatureEntitlementItem = {
  __typename: 'FeatureEntitlementItem';
  feature: ProductFeature;
  requiredEntitlements: Array<SubscriptionEntitlement>;
};

export type FieldError = {
  __typename: 'FieldError';
  field: Scalars['String']['output'];
  messages: Array<Scalars['String']['output']>;
};

/** For use with FileField */
export type FileType = {
  __typename: 'FileType';
  name: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type FixCoinbaseCredentialInput = {
  code: Scalars['String']['input'];
  credentialId: Scalars['UUID']['input'];
  redirectUri: Scalars['String']['input'];
};

export type FixCoinbaseCredentialMutation = {
  __typename: 'FixCoinbaseCredentialMutation';
  credential: Maybe<Credential>;
  errors: Maybe<PayloadError>;
};

export type ForceRefreshAccountInput = {
  accountId: Scalars['ID']['input'];
};

export type ForceRefreshAccountMutation = {
  __typename: 'ForceRefreshAccountMutation';
  errors: Maybe<PayloadError>;
  success: Scalars['Boolean']['output'];
};

export type ForceRefreshAccountsInput = {
  accountIds: Array<Scalars['ID']['input']>;
};

export type ForceRefreshAccountsMutation = {
  __typename: 'ForceRefreshAccountsMutation';
  errors: Maybe<PayloadError>;
  /** @deprecated This mutation is deprecated. Use forceRefreshAllAccounts mutation instead */
  success: Scalars['Boolean']['output'];
};

export type ForceRefreshAllAccountsMutation = {
  __typename: 'ForceRefreshAllAccountsMutation';
  errors: Maybe<PayloadError>;
  success: Scalars['Boolean']['output'];
};

/** Get Cloudinary upload info */
export type GetLogoCloudinaryUploadInfoMutation = {
  __typename: 'GetLogoCloudinaryUploadInfoMutation';
  errors: Maybe<PayloadError>;
  info: Maybe<CloudinaryUploadInfo>;
};

export type GetLogoCloudinaryUploadInfoMutationInput = {
  entityType: Scalars['String']['input'];
};

/**
 * Get Cloudinary upload info
 *
 * DEPRECATED: Removed as part of https://linear.app/monarch/issue/ENG-8714/customize-account-logos
 *             Replaced with monarch.api.graphql.schema.cloudinary_mutation.GetLogoCloudinaryUploadInfoMutation
 */
export type GetMerchantLogoCloudinaryUploadInfoMutation = {
  __typename: 'GetMerchantLogoCloudinaryUploadInfoMutation';
  errors: Maybe<PayloadError>;
  info: Maybe<CloudinaryUploadInfo>;
};

export type GetTransactionAttachmentUploadInfoMutation = {
  __typename: 'GetTransactionAttachmentUploadInfoMutation';
  errors: Maybe<PayloadError>;
  info: Maybe<TransactionAttachmentUploadInfo>;
};

/**
 * Get Cloudinary upload info
 *
 * DEPRECATED: Removed as part of https://linear.app/monarch/issue/ENG-8714/customize-account-logos
 *             Replaced with monarch.api.graphql.schema.cloudinary_mutation.GetLogoCloudinaryUploadInfoMutation
 */
export type GetUserProfilePictureCloudinaryUploadInfoMutation = {
  __typename: 'GetUserProfilePictureCloudinaryUploadInfoMutation';
  errors: Maybe<PayloadError>;
  info: Maybe<CloudinaryUploadInfo>;
};

export type GlobalSearch = {
  __typename: 'GlobalSearch';
  results: Array<SearchResult>;
  searchTerm: Scalars['String']['output'];
};

export type Goal = {
  __typename: 'Goal';
  completedAt: Maybe<Scalars['DateTime']['output']>;
  contributedBalance: Scalars['Float']['output'];
  goalContributions: Maybe<GoalContributionConnection>;
  goalPlannedContributions: Maybe<Array<GoalPlannedContribution>>;
  goalType: GoalGoalType;
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  plannedMonthlyContribution: Scalars['Float']['output'];
  plannedStartingBalance: Scalars['Float']['output'];
  projectedCompletionDateGivenContribution: Maybe<Scalars['Date']['output']>;
  targetAmount: Scalars['Float']['output'];
  targetDate: Scalars['Date']['output'];
  timeline: Array<GoalTimelinePoint>;
  user: User;
};

export type GoalGoalContributionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GoalProjectedCompletionDateGivenContributionArgs = {
  proposedContributionAmount: Scalars['Float']['input'];
};

export type GoalAccountAllocation = {
  __typename: 'GoalAccountAllocation';
  account: Account;
  amount: Maybe<Scalars['Float']['output']>;
  currentAmount: Maybe<Scalars['Float']['output']>;
  currentMonthChange: Maybe<GoalAccountAllocationCurrentMonthChange>;
  goal: GoalV2;
  id: Scalars['ID']['output'];
  useEntireAccountBalance: Scalars['Boolean']['output'];
};

export type GoalAccountAllocationCurrentMonthChange = {
  __typename: 'GoalAccountAllocationCurrentMonthChange';
  amount: Maybe<Scalars['Float']['output']>;
  percent: Maybe<Scalars['Float']['output']>;
};

export type GoalAllocationBalances = {
  __typename: 'GoalAllocationBalances';
  availableBalance: Scalars['Float']['output'];
  totalContributedBalance: Scalars['Float']['output'];
  totalSavingsBalance: Scalars['Float']['output'];
};

/** A single month's planned/actual/remaining/etc amounts (in budget format) */
export type GoalBudgetAmountsForMonth = {
  __typename: 'GoalBudgetAmountsForMonth';
  actualAmount: Maybe<Scalars['Float']['output']>;
  month: Scalars['Date']['output'];
  plannedAmount: Maybe<Scalars['Float']['output']>;
  remainingAmount: Maybe<Scalars['Float']['output']>;
};

export type GoalContribution = Node & {
  __typename: 'GoalContribution';
  amount: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  goal: Goal;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  notes: Maybe<Scalars['String']['output']>;
  type: GoalContributionType;
  user: User;
};

export type GoalContributionConnection = {
  __typename: 'GoalContributionConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GoalContributionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GoalContribution` and its cursor. */
export type GoalContributionEdge = {
  __typename: 'GoalContributionEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Maybe<GoalContribution>;
};

export type GoalContributionFilters = {
  endDate: InputMaybe<Scalars['Date']['input']>;
  includeWithdrawals: InputMaybe<Scalars['Boolean']['input']>;
  startDate: InputMaybe<Scalars['Date']['input']>;
};

export type GoalContributionMonthlySummary = {
  __typename: 'GoalContributionMonthlySummary';
  month: Scalars['Date']['output'];
  plannedAmount: Maybe<Scalars['Float']['output']>;
  sum: Scalars['Float']['output'];
  sumCredit: Scalars['Float']['output'];
  sumDebit: Scalars['Float']['output'];
};

/** An enumeration. */
export enum GoalContributionType {
  MANUAL = 'MANUAL',
  STARTING_BALANCE = 'STARTING_BALANCE',
}

/** Show a filtered list of goal contributions. */
export type GoalContributionsListBlock = Block & {
  __typename: 'GoalContributionsListBlock';
  endDate: Maybe<Scalars['Date']['output']>;
  link: Maybe<BlockLink>;
  startDate: Maybe<Scalars['Date']['output']>;
};

export enum GoalGoalType {
  DOWN_PAYMENT = 'DOWN_PAYMENT',
  EMERGENCY_FUND = 'EMERGENCY_FUND',
  GENERAL_SAVINGS = 'GENERAL_SAVINGS',
  HOLIDAYS = 'HOLIDAYS',
  OTHER = 'OTHER',
  UPCOMING_TRIP = 'UPCOMING_TRIP',
}

/** A single month's planned/actual/remaining/etc amounts (in budget format) */
export type GoalMonthlyBudgetAmounts = {
  __typename: 'GoalMonthlyBudgetAmounts';
  goal: GoalV2;
  monthlyAmounts: Array<GoalBudgetAmountsForMonth>;
};

export type GoalMonthlyContribution = {
  __typename: 'GoalMonthlyContribution';
  goal: Goal;
  goalId: Scalars['ID']['output'];
  monthlyContribution: Maybe<Scalars['Float']['output']>;
  startDate: Scalars['Date']['output'];
};

export type GoalOption = {
  __typename: 'GoalOption';
  allowMultiSelect: Scalars['Boolean']['output'];
  defaultImageStorageProvider: Scalars['String']['output'];
  defaultImageStorageProviderId: Scalars['String']['output'];
  defaultName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type GoalPlannedContribution = {
  __typename: 'GoalPlannedContribution';
  amount: Scalars['Float']['output'];
  goal: Goal;
  id: Scalars['ID']['output'];
  startDate: Scalars['Date']['output'];
  user: User;
};

export type GoalPlannedContributionV2 = {
  __typename: 'GoalPlannedContributionV2';
  amount: Scalars['Float']['output'];
  goal: GoalV2;
  id: Scalars['ID']['output'];
  month: Scalars['Date']['output'];
};

export type GoalTimelinePoint = {
  __typename: 'GoalTimelinePoint';
  actualBalance: Maybe<Scalars['Float']['output']>;
  date: Scalars['Date']['output'];
  plannedBalance: Maybe<Scalars['Float']['output']>;
  projectedBalance: Maybe<Scalars['Float']['output']>;
};

export type GoalType = {
  __typename: 'GoalType';
  display: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GoalV2 = {
  __typename: 'GoalV2';
  accountAllocations: Array<GoalAccountAllocation>;
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  completedAt: Maybe<Scalars['DateTime']['output']>;
  completionPercent: Maybe<Scalars['Float']['output']>;
  currentAmount: Maybe<Scalars['Float']['output']>;
  defaultName: Maybe<Scalars['String']['output']>;
  eligibleAccounts: Array<Account>;
  estimatedCompletionMonth: Maybe<Scalars['Date']['output']>;
  estimatedMonthsUntilCompletion: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  imageStorageProvider: Maybe<Scalars['String']['output']>;
  imageStorageProviderId: Maybe<Scalars['String']['output']>;
  legacyGoal: Maybe<Goal>;
  monthlyContributionSummaries: Array<GoalContributionMonthlySummary>;
  name: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  plannedContributions: Array<GoalPlannedContributionV2>;
  plannedMonthlyContribution: Maybe<Scalars['Float']['output']>;
  plannedMonthlyPretaxContribution: Maybe<Scalars['Float']['output']>;
  priority: Scalars['Int']['output'];
  startingAmount: Maybe<Scalars['Float']['output']>;
  suggestedAccounts: Array<Account>;
  targetAmount: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type GoalV2MonthlyContributionSummariesArgs = {
  endMonth: InputMaybe<Scalars['Date']['input']>;
  numMonths: InputMaybe<Scalars['Int']['input']>;
  startMonth: InputMaybe<Scalars['Date']['input']>;
};

export type GoalV2PlannedContributionsArgs = {
  endMonth: InputMaybe<Scalars['Date']['input']>;
  startMonth: InputMaybe<Scalars['Date']['input']>;
};

export type Gradient = {
  __typename: 'Gradient';
  colors: Array<Scalars['String']['output']>;
};

/**
 * Block used to group other blocks together for spacing purposes.
 *
 * By default all blocks are displayed with `justify-content: space-between`, so if you
 * want some blocks to appear close together in a "group" you can use this block with any
 * children blocks.
 */
export type GroupBlock = Block & {
  __typename: 'GroupBlock';
  centered: Maybe<Scalars['Boolean']['output']>;
  children: Array<AllBlockTypes>;
  link: Maybe<BlockLink>;
};

export type HistoricalChartForPeriod = {
  __typename: 'HistoricalChartForPeriod';
  chart: Array<SecurityPerformanceChartDatum>;
  period: Scalars['String']['output'];
};

export type Holding = {
  __typename: 'Holding';
  account: Maybe<Account>;
  closingPrice: Maybe<Scalars['Float']['output']>;
  closingPriceUpdatedAt: Maybe<Scalars['DateTime']['output']>;
  costBasis: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  isManual: Maybe<Scalars['Boolean']['output']>;
  name: Maybe<Scalars['String']['output']>;
  quantity: Maybe<Scalars['Float']['output']>;
  ticker: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  typeDisplay: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['Float']['output']>;
};

/** Represents one bar of a bar chart. */
export type HorizontalBarChartBar = Block & {
  __typename: 'HorizontalBarChartBar';
  barColor: Scalars['String']['output'];
  barPercent: Scalars['Float']['output'];
  label: Maybe<Scalars['String']['output']>;
  leftLabel: Maybe<Scalars['String']['output']>;
  link: Maybe<BlockLink>;
  rightLabel: Maybe<Scalars['String']['output']>;
};

/** Block used to show a vertically scrolling chart of horizontal bars. */
export type HorizontalBarChartBlock = Block & {
  __typename: 'HorizontalBarChartBlock';
  bars: Array<HorizontalBarChartBar>;
  link: Maybe<BlockLink>;
  smallBarStyle: Maybe<Scalars['Boolean']['output']>;
  topRightLabel: Maybe<Scalars['String']['output']>;
};

export type Household = {
  __typename: 'Household';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  externalId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  preferences: Maybe<HouseholdPreferences>;
  state: Scalars['String']['output'];
  users: Array<User>;
  zipCode: Scalars['String']['output'];
};

export type HouseholdAccessGrant = {
  __typename: 'HouseholdAccessGrant';
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  householdGivingAccessName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  toEmail: Scalars['String']['output'];
  toName: Maybe<Scalars['String']['output']>;
  userGivingAccessId: Scalars['ID']['output'];
};

export type HouseholdInvite = {
  __typename: 'HouseholdInvite';
  createdAt: Scalars['DateTime']['output'];
  fromUser: User;
  id: Scalars['ID']['output'];
  invitedEmail: Scalars['String']['output'];
  isRevoked: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  usedAt: Maybe<Scalars['DateTime']['output']>;
};

export type HouseholdMerchant = {
  __typename: 'HouseholdMerchant';
  createdAt: Scalars['String']['output'];
  household: Household;
  id: Scalars['ID']['output'];
  logoUrl: Maybe<Scalars['String']['output']>;
  merchant: Maybe<Merchant>;
  name: Scalars['String']['output'];
  ruleCount: Scalars['Int']['output'];
  transactionCount: Scalars['Int']['output'];
};

export type HouseholdPreferences = {
  __typename: 'HouseholdPreferences';
  accountGroupOrder: Array<Scalars['String']['output']>;
  aiAssistantEnabled: Maybe<Scalars['Boolean']['output']>;
  dashboardConfig: DashboardConfig;
  id: Maybe<Scalars['ID']['output']>;
  investmentTransactionsEnabled: Maybe<Scalars['Boolean']['output']>;
  llmEnrichmentEnabled: Maybe<Scalars['Boolean']['output']>;
  newTransactionsNeedReview: Maybe<Scalars['Boolean']['output']>;
  pendingTransactionsCanBeEdited: Maybe<Scalars['Boolean']['output']>;
  uncategorizedTransactionsNeedReview: Maybe<Scalars['Boolean']['output']>;
};

export type HouseholdSubscription = {
  __typename: 'HouseholdSubscription';
  activePromoCode: Maybe<PromoCode>;
  activeSponsorship: Maybe<SubscriptionSponsorshipInfo>;
  analyticsFreemiumSummaryStatus: Scalars['String']['output'];
  billingPeriod: Maybe<PaymentPeriod>;
  billingVersion: Scalars['String']['output'];
  currentPeriodEndsAt: Maybe<Scalars['DateTime']['output']>;
  eligibleForTrial: Scalars['Boolean']['output'];
  entitlementParams: EntitlementParams;
  entitlements: Array<SubscriptionEntitlement>;
  hasBillingIssue: Scalars['Boolean']['output'];
  hasChargedForLifetime: Scalars['Boolean']['output'];
  hasLegacyLiteTierAccess: Scalars['Boolean']['output'];
  hasLifetimeSubscription: Scalars['Boolean']['output'];
  hasPremiumEntitlement: Scalars['Boolean']['output'];
  hasPremiumEntitlementAfterSync: Scalars['Boolean']['output'];
  hasStartedFreeTrial: Scalars['Boolean']['output'];
  hasStripeSubscriptionId: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isOnFreeTrial: Scalars['Boolean']['output'];
  isSponsor: Scalars['Boolean']['output'];
  nextPaymentAmount: Maybe<Scalars['Float']['output']>;
  paymentMethod: Maybe<PaymentMethod>;
  paymentSource: Maybe<SubscriptionDetailsPaymentSource>;
  referralCode: Scalars['String']['output'];
  referralRedemption: Maybe<ReferralRedemption>;
  sponsoredBy: Maybe<SubscriptionSponsorInfo>;
  trialDurationDays: Scalars['Int']['output'];
  trialEndsAt: Maybe<Scalars['DateTime']['output']>;
  trialType: Maybe<TrialType>;
  willCancelAtPeriodEnd: Scalars['Boolean']['output'];
};

/** Block used to show a simple information along with a label. */
export type InfoBlock = Block & {
  __typename: 'InfoBlock';
  label: Maybe<Scalars['String']['output']>;
  link: Maybe<BlockLink>;
  value: Maybe<Scalars['String']['output']>;
};

export type InitialHoldingInput = {
  quantity: Scalars['Float']['input'];
  securityId: Scalars['ID']['input'];
};

export type InitializeFlexBudgetMutation = {
  __typename: 'InitializeFlexBudgetMutation';
  budgetItem: Maybe<BudgetItem>;
};

export type InitializeFlexBudgetMutationInput = {
  startDate: Scalars['Date']['input'];
};

export type Institution = {
  __typename: 'Institution';
  active: Scalars['Boolean']['output'];
  balanceStatus: Maybe<InstitutionStatus>;
  finicityInstitutionId: Maybe<Scalars['String']['output']>;
  firstBackupDataProvider: Maybe<DataProviderLegacy>;
  hasIssuesReported: Scalars['Boolean']['output'];
  hasIssuesReportedMessage: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  largeLogo: Maybe<FileType>;
  linkFlowWarnBeforeConnecting: Scalars['Boolean']['output'];
  linkFlowWarningMessage: Maybe<Scalars['String']['output']>;
  linkFlowWarningTitle: Maybe<Scalars['String']['output']>;
  logo: Maybe<Scalars['String']['output']>;
  mxInstitutionId: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  newConnectionsDisabled: Scalars['Boolean']['output'];
  plaidInstitutionId: Maybe<Scalars['String']['output']>;
  plaidStatus: Maybe<Scalars['GenericScalar']['output']>;
  preferredDataProvider: DataProviderLegacy;
  primaryColor: Scalars['String']['output'];
  status: Maybe<InstitutionStatus>;
  topInstitution: Scalars['Boolean']['output'];
  transactionsStatus: Maybe<InstitutionStatus>;
  url: Scalars['String']['output'];
  urlDomain: Scalars['String']['output'];
};

export type InstitutionIssueReport = {
  __typename: 'InstitutionIssueReport';
  additionalNotes: Maybe<Scalars['String']['output']>;
  credential: Credential;
  id: Maybe<Scalars['ID']['output']>;
  institution: Institution;
  issues: Array<Maybe<Scalars['String']['output']>>;
};

export type InstitutionReportIssueObjectType = {
  __typename: 'InstitutionReportIssueObjectType';
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** An enumeration. */
export enum InstitutionStatus {
  DEGRADED = 'DEGRADED',
  DOWN = 'DOWN',
  HEALTHY = 'HEALTHY',
}

export type InviteUserToHouseholdMutation = {
  __typename: 'InviteUserToHouseholdMutation';
  errors: Maybe<PayloadError>;
  invite: Maybe<HouseholdInvite>;
};

export type InviteUserToHouseholdMutationInput = {
  email: InputMaybe<Scalars['String']['input']>;
};

export type LiabilityStatement = {
  __typename: 'LiabilityStatement';
  billAmount: Maybe<Scalars['Decimal']['output']>;
  creditReportLiabilityAccount: CreditReportLiabilityAccount;
  dueDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  minimumPaymentAmount: Maybe<Scalars['Float']['output']>;
  paymentStatus: Maybe<LiabilityStatementPaymentStatus>;
  paymentTransactions: Maybe<Array<Maybe<Transaction>>>;
  remainingBalance: Maybe<Scalars['Float']['output']>;
  userMarkedPaidAt: Maybe<Scalars['DateTime']['output']>;
};

export type LiabilityStatementFilterInput = {
  creditReportLiabilityAccountId: InputMaybe<Scalars['ID']['input']>;
};

/** An enumeration. */
export enum LiabilityStatementPaymentStatus {
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
  UNPAID = 'unpaid',
}

export type ManualAccountShortcut = {
  __typename: 'ManualAccountShortcut';
  accountSubtype: Maybe<AccountSubtype>;
  accountType: AccountType;
  title: Scalars['String']['output'];
};

/** An enumeration. */
export enum ManualInvestmentsAccountTrackingMethod {
  BALANCES = 'balances',
  HOLDINGS = 'holdings',
}

export type MarkActivityAsReadMutation = {
  __typename: 'MarkActivityAsReadMutation';
  node: ActivityNode;
};

export type MarkAdviceItemTaskComplete = {
  __typename: 'MarkAdviceItemTaskComplete';
  adviceItem: Maybe<AdviceItem>;
  adviceItemTask: Maybe<AdviceItemTask>;
  errors: Maybe<PayloadError>;
};

export type MarkAdviceItemTaskCompleteInput = {
  adviceItemTaskId: Scalars['ID']['input'];
};

export type MarkAdviceItemTaskCompleteV2 = {
  __typename: 'MarkAdviceItemTaskCompleteV2';
  adviceItem: Maybe<AdviceItemV2>;
  adviceItemTask: Maybe<AdviceItemTaskV2>;
  errors: Maybe<PayloadError>;
};

export type MarkAdviceItemTaskCompleteV2Input = {
  adviceItemTaskId: Scalars['ID']['input'];
};

export type MarkAdviceItemTaskIncomplete = {
  __typename: 'MarkAdviceItemTaskIncomplete';
  adviceItem: Maybe<AdviceItem>;
  adviceItemTask: Maybe<AdviceItemTask>;
  errors: Maybe<PayloadError>;
};

export type MarkAdviceItemTaskIncompleteInput = {
  adviceItemTaskId: Scalars['ID']['input'];
};

export type MarkAdviceItemTaskIncompleteV2 = {
  __typename: 'MarkAdviceItemTaskIncompleteV2';
  adviceItem: Maybe<AdviceItemV2>;
  adviceItemTask: Maybe<AdviceItemTaskV2>;
  errors: Maybe<PayloadError>;
};

export type MarkAdviceItemTaskIncompleteV2Input = {
  adviceItemTaskId: Scalars['ID']['input'];
};

export type MarkAllActivityAsReadMutation = {
  __typename: 'MarkAllActivityAsReadMutation';
  activityEvents: Maybe<ActivityConnection>;
};

export type MarkAllActivityAsReadMutationActivityEventsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type MarkGoalComplete = {
  __typename: 'MarkGoalComplete';
  errors: Maybe<PayloadError>;
  goal: Maybe<GoalV2>;
};

export type MarkGoalCompleteInput = {
  id: Scalars['ID']['input'];
};

export type MarkGoalIncomplete = {
  __typename: 'MarkGoalIncomplete';
  errors: Maybe<PayloadError>;
  goal: Maybe<GoalV2>;
};

export type MarkGoalIncompleteInput = {
  id: Scalars['ID']['input'];
};

export type MarkStreamAsNotRecurringMutation = {
  __typename: 'MarkStreamAsNotRecurringMutation';
  errors: Maybe<PayloadError>;
  success: Scalars['Boolean']['output'];
};

export type Merchant = {
  __typename: 'Merchant';
  canBeDeleted: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logoUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  recurringTransactionStream: Maybe<RecurringTransactionStream>;
  ruleCount: Scalars['Int']['output'];
  transactionCount: Scalars['Int']['output'];
  /** @deprecated Replaced with transaction_count */
  transactionsCount: Scalars['Int']['output'];
};

export type MerchantCriterion = {
  __typename: 'MerchantCriterion';
  operator: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MerchantCriterionInput = {
  operator: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum MerchantOrdering {
  NAME = 'NAME',
  TRANSACTION_COUNT = 'TRANSACTION_COUNT',
}

/** Show a filtered list of merchants. */
export type MerchantsListBlock = Block & {
  __typename: 'MerchantsListBlock';
  link: Maybe<BlockLink>;
  merchants: Maybe<Array<ReviewMerchant>>;
};

export type Message = AssistantMessage | AssistantStatusMessage | UserMessage;

/** Keep it in sync with the Banner `type` prop. */
export enum MessageBlockAppearance {
  ERROR = 'error',
  INFO = 'info',
  PLAIN = 'plain',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export type MessageCreatedSubscription = {
  __typename: 'MessageCreatedSubscription';
  message: Maybe<Message>;
};

export type MessageInterface = {
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  threadId: Scalars['ID']['output'];
};

export type MessageThread = {
  __typename: 'MessageThread';
  agentType: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  hasOutstandingAssistantRequests: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastMessageSentAt: Maybe<Scalars['DateTime']['output']>;
  messages: Array<Message>;
  subject: Maybe<Scalars['String']['output']>;
};

export type MessageThreadUpdatedSubscription = {
  __typename: 'MessageThreadUpdatedSubscription';
  messageThread: Maybe<MessageThread>;
};

export type MessageUpdatedSubscription = {
  __typename: 'MessageUpdatedSubscription';
  message: Maybe<Message>;
};

/** Mark that household has migration to goals v2 */
export type MigrateToGoalsV2 = {
  __typename: 'MigrateToGoalsV2';
  success: Scalars['Boolean']['output'];
};

export type MobilePackageMetadata = {
  __typename: 'MobilePackageMetadata';
  discountTitle: Maybe<Scalars['String']['output']>;
  packageType: Maybe<Scalars['String']['output']>;
  promoText: Maybe<Scalars['String']['output']>;
};

export type MobileSubscriptionOffering = {
  __typename: 'MobileSubscriptionOffering';
  availableOfferingName: Scalars['String']['output'];
  packageMetadata: Maybe<Array<MobilePackageMetadata>>;
};

export type MoveMoneyMutation = {
  __typename: 'MoveMoneyMutation';
  errors: Maybe<PayloadError>;
  fromBudgetItem: Maybe<BudgetItem>;
  toBudgetItem: Maybe<BudgetItem>;
};

export type MoveMoneyMutationInput = {
  amount: Scalars['Float']['input'];
  fromCategoryGroupId: InputMaybe<Scalars['ID']['input']>;
  fromCategoryId: InputMaybe<Scalars['ID']['input']>;
  startDate: Scalars['Date']['input'];
  timeframe: BudgetTimeframeInput;
  toCategoryGroupId: InputMaybe<Scalars['ID']['input']>;
  toCategoryId: InputMaybe<Scalars['ID']['input']>;
};

export type MoveTransactions = {
  __typename: 'MoveTransactions';
  errors: Maybe<PayloadError>;
  numTransactionsMoved: Maybe<Scalars['Int']['output']>;
};

export type MoveTransactionsInput = {
  excludedTransactionIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  expectedAffectedTransactionCount: InputMaybe<Scalars['Int']['input']>;
  filters: InputMaybe<TransactionFilterInput>;
  fromAccountId: Scalars['ID']['input'];
  isAllSelected: InputMaybe<Scalars['Boolean']['input']>;
  selectedTransactionIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  toAccountId: Scalars['ID']['input'];
};

export type Mutation = {
  __typename: 'Mutation';
  addTransactionAttachment: Maybe<TransactionAddAttachmentMutation>;
  answerProfileQuestion: Maybe<AnswerProfileQuestion>;
  answerProfileQuestionV2: Maybe<AnswerProfileQuestionV2>;
  applyStripeCancelDiscountOffer: Maybe<ApplyStripeCancelDiscountOffer>;
  archiveGoal: Maybe<ArchiveGoal>;
  /** Bulk delete transactions from IDs */
  bulkDeleteTransactions: Maybe<BulkDeleteTransactionsMutation>;
  /** Bulk edit transactions from IDs. */
  bulkUpdateTransactions: Maybe<BulkUpdateTransactionsMutation>;
  cancelStripeSubscription: Maybe<CancelStripeSubscriptionMutation>;
  /** Cancel subscription sponsorship */
  cancelSubscriptionSponsorship: Maybe<CancelSponsorship>;
  /** Change a household's stripe subscription plan, for example from monthly to yearly */
  changeStripeSubscription: Maybe<ChangeStripeSubscriptionMutation>;
  checkActivity: Maybe<CheckActivityMutation>;
  clearAllActivities: Maybe<ClearAllActivitiesMutation>;
  clearBudget: Maybe<ClearBudgetMutation>;
  /**
   * Confirm a user's unconfirmed TOTPDevice.
   *
   * Will return an error if:
   *  - the user doesnt have a TOTPDevice.
   */
  confirmTotpDevice: Maybe<ConfirmTotpDevice>;
  /** Copy snapshots from one account to another */
  copySnapshots: Maybe<CopySnapshots>;
  createAccountsFromPlaidToken: Maybe<CreateAccountsFromPlaidToken>;
  createBudget: Maybe<CreateBudgetMutation>;
  createCategory: Maybe<CreateCategoryPayload>;
  createCategoryGroup: Maybe<CreateCategoryGroupMutation>;
  createCoinbaseAccount: Maybe<CreateCoinbaseAccountMutation>;
  /** Generates a Connect "Fix" url to re-link an existing credential */
  createFinicityConnectFixUrl: Maybe<CreateFinicityConnectFixUrlMutation>;
  /** Generates a Connect url to begin linking a new credential */
  createFinicityConnectUrl: Maybe<CreateFinicityConnectUrlMutation>;
  /**
   * This is similar to CreateAccountsFromPlaidToken, but for Finicity. It will
   * fetch all of the customer's accounts from Finicity and create Credentials
   * for those that don't already exist.
   */
  createFinicityCredentials: Maybe<CreateFinicityCredentialsMutation>;
  createGoal: Maybe<CreateGoalMutation>;
  createGoalAccountAllocation: Maybe<CreateGoalAccountAllocation>;
  createGoalContribution: Maybe<CreateGoalContributionMutation>;
  createGoalContributions: Maybe<CreateGoalContributionsMutation>;
  createGoalV2: Maybe<CreateGoal>;
  createGoals: Maybe<CreateGoals>;
  createHouseholdAccessGrant: Maybe<CreateHouseholdAccessGrant>;
  createInstitutionIssueReport: Maybe<CreateInstitutionIssueReportMutationPayload>;
  createManualAccount: Maybe<CreateManualAccountMutation>;
  createManualHolding: Maybe<CreateManualHolding>;
  createManualInvestmentsAccount: Maybe<CreateManualInvestmentsAccount>;
  createMessageThread: CreateMessageThread;
  /** Generates a Connect "Fix" url to re-link an existing credential */
  createMxConnectFixUrl: Maybe<CreateMxConnectFixUrlMutation>;
  /** Request a Connect URL to create a new member */
  createMxConnectUrl: Maybe<CreateMxConnectUrlMutation>;
  /**
   * This is similar to CreateAccountsFromPlaidToken, but for MX. It will
   * create credentials, perform the initial sync, and call extend history.
   */
  createMxCredentials: Maybe<CreateMxCredentialsMutation>;
  createOrUpdateGoalPlannedContribution: Maybe<CreateOrUpdateGoalPlannedContribution>;
  createPlaidLinkToken: Maybe<CreatePlaidLinkTokenMutation>;
  createRecoveryCodes: Maybe<CreateRecoveryCodesMutation>;
  createRecurringTransactionStream: Maybe<CreateRecurringTransactionStreamMutation>;
  createReviewCheckin: Maybe<CreateReviewCheckinMutation>;
  /**
   * Endpoint to create the account mappping in the database.
   * For now it doesn't do anything.
   */
  createSpinwheelAccountMapping: Maybe<CreateSpinwheelAccountMappingMutation>;
  /** Endpoint to create / authenticate a user with Spinwheel and get a token */
  createSpinwheelToken: Maybe<CreateSpinwheelTokenMutation>;
  /**
   * Creates a Stripe subscription for a user's household. This mutation should only be called
   * in two cases:
   *
   * (1) The user is buying a subscription for the first time on web
   * (2) The user is switching their subscription billing from ReveneueCat to Stripe i.e. mobile
   *     to web
   */
  createStripeSubscription: Maybe<CreateStripeSubscriptionMutation>;
  /** Create subscription sponsorship invites */
  createSubscriptionSponsorshipInvites: Maybe<CreateSponsorshipInvites>;
  createSyncedRealEstateAccount: Maybe<CreateSyncedRealEstateAccount>;
  createSyncedVehicleAccount: Maybe<CreateSyncedVehicleAccount>;
  /** Create a new manual transaction */
  createTransaction: Maybe<CreateTransactionMutation>;
  createTransactionRuleV2: Maybe<CreateTransactionRuleV2Mutation>;
  createTransactionTag: Maybe<CreateTransactionTag>;
  /**
   * Create an unconfirmed TOTP device for the user.
   *
   * If the user already has an unconfirmed TOTP device, just return it.
   * If the user has a confirmed TOTP device, return an error.
   */
  createUnconfirmedTotpDevice: Maybe<CreateUnconfirmedTotpDevice>;
  deleteAccount: Maybe<DeleteAccountMutation>;
  deleteAccountLogo: Maybe<DeleteAccountLogoMutation>;
  deleteAllHouseholdTransactionTags: Maybe<DeleteAllHouseholdTransactionTags>;
  deleteAllTransactionRules: Maybe<DeleteAllTransactionRules>;
  /**
   * Delete (or disable) a category, and move all rules / transactions to
   * move_to_category_id. If move_to_category_id is not specified, use UNCATEGORIZED.
   */
  deleteCategory: Maybe<DeleteCategoryMutation>;
  deleteCategoryGroup: Maybe<DeleteCategoryGroupMutation>;
  deleteCredential: Maybe<DeleteCredentialMutation>;
  deleteGoal: Maybe<DeleteGoalMutation>;
  deleteGoalAccountAllocation: Maybe<DeleteGoalAccountAllocation>;
  deleteGoalContribution: Maybe<DeleteGoalContributionMutation>;
  deleteGoalV2: Maybe<DeleteGoal>;
  deleteHolding: Maybe<DeleteHolding>;
  deleteHousehold: Maybe<DeleteHouseholdMutation>;
  deleteMerchant: Maybe<DeleteMerchantMutation>;
  deleteMerchantLogo: Maybe<DeleteMerchantLogoMutation>;
  deleteMessageThread: DeleteMessageThread;
  deleteRecurringTransactionStream: Maybe<DeleteRecurringTransactionStreamMutation>;
  /** Delete before a specified date */
  deleteSyncedSnapshots: Maybe<DeleteSyncedSnapshotsMutation>;
  /** Delete synced transactions */
  deleteSyncedTransactions: Maybe<DeleteSyncedTransactionsMutation>;
  /**
   * Delete a user's TOTP device. This assumes the user only has one, which
   * is a safe assumption at the moment.
   */
  deleteTotpDevice: Maybe<DeleteTotpDevice>;
  /** Delete a manual transaction */
  deleteTransaction: Maybe<DeleteTransactionMutation>;
  deleteTransactionAttachment: Maybe<DeleteTransactionAttachmentMutation>;
  deleteTransactionRule: Maybe<DeleteTransactionRuleV2Mutation>;
  deleteTransactionTag: Maybe<DeleteTransactionTag>;
  /** Delete transactions */
  deleteTransactions: Maybe<DeleteTransactionsMutation>;
  deleteUserFromHousehold: Maybe<DeleteUserFromHouseholdMutation>;
  deleteUserProfilePicture: Maybe<DeleteUserProfilePicture>;
  didRequestAppStoreReview: Maybe<DidRequestAppStoreReviewMutation>;
  disconnectCredential: Maybe<DisconnectCredentialMutation>;
  disconnectCredentialFromDataProvider: Maybe<DisconnectCredentialFromDataProviderMutation>;
  disconnectCredentialsFromDataProvider: Maybe<DisconnectCredentialsFromDataProviderMutation>;
  /** Endpoint to disconnect a user from Spinwheel. */
  disconnectSpinwheel: Maybe<DisconnectSpinwheel>;
  dismissActivity: Maybe<DismissActivity>;
  fixCoinbaseCredential: Maybe<FixCoinbaseCredentialMutation>;
  forceRefreshAccount: Maybe<ForceRefreshAccountMutation>;
  forceRefreshAccounts: Maybe<ForceRefreshAccountsMutation>;
  forceRefreshAllAccounts: Maybe<ForceRefreshAllAccountsMutation>;
  /** Get Cloudinary upload info */
  getCloudinaryUploadInfo: Maybe<GetLogoCloudinaryUploadInfoMutation>;
  /**
   * Get Cloudinary upload info
   *
   * DEPRECATED: Removed as part of https://linear.app/monarch/issue/ENG-8714/customize-account-logos
   *             Replaced with monarch.api.graphql.schema.cloudinary_mutation.GetLogoCloudinaryUploadInfoMutation
   */
  getMerchantLogoCloudinaryUploadInfo: Maybe<GetMerchantLogoCloudinaryUploadInfoMutation>;
  getTransactionAttachmentUploadInfo: Maybe<GetTransactionAttachmentUploadInfoMutation>;
  /**
   * Get Cloudinary upload info
   *
   * DEPRECATED: Removed as part of https://linear.app/monarch/issue/ENG-8714/customize-account-logos
   *             Replaced with monarch.api.graphql.schema.cloudinary_mutation.GetLogoCloudinaryUploadInfoMutation
   */
  getUserProfilePictureCloudinaryUploadInfo: Maybe<GetUserProfilePictureCloudinaryUploadInfoMutation>;
  initializeFlexBudget: Maybe<InitializeFlexBudgetMutation>;
  inviteUserToHousehold: Maybe<InviteUserToHouseholdMutation>;
  markActivityAsRead: Maybe<MarkActivityAsReadMutation>;
  markAdviceItemTaskComplete: Maybe<MarkAdviceItemTaskComplete>;
  markAdviceItemTaskCompleteV2: Maybe<MarkAdviceItemTaskCompleteV2>;
  markAdviceItemTaskIncomplete: Maybe<MarkAdviceItemTaskIncomplete>;
  markAdviceItemTaskIncompleteV2: Maybe<MarkAdviceItemTaskIncompleteV2>;
  markAllActivityAsRead: Maybe<MarkAllActivityAsReadMutation>;
  markGoalComplete: Maybe<MarkGoalComplete>;
  markGoalIncomplete: Maybe<MarkGoalIncomplete>;
  markStreamAsNotRecurring: Maybe<MarkStreamAsNotRecurringMutation>;
  /** Mark that household has migration to goals v2 */
  migrateToGoalsV2: Maybe<MigrateToGoalsV2>;
  moveMoneyBetweenCategories: Maybe<MoveMoneyMutation>;
  /** Move transactions from one account to another */
  moveTransactions: Maybe<MoveTransactions>;
  parseBalanceHistory: Maybe<ParseBalanceHistoryMutation>;
  parseUploadStatementSession: Maybe<ParseStatementMutation>;
  /** Reactivates a user's household's Stripe subscription after they have canceled. */
  reactivateStripeSubscription: Maybe<ReactivateStripeSubscriptionMutation>;
  refreshTransactionsForCredential: Maybe<RefreshTransactionsForCredentialMutation>;
  /**
   * Employee only endpoint to send a monthly email report to yourself.
   * Only for internal testing.
   */
  requestEmailReport: Maybe<RequestEmailReport>;
  requestInstitution: Maybe<RequestInstitution>;
  resendInviteToHousehold: Maybe<ResendInviteToHouseholdMutation>;
  /** Employee only API to delete all profile question answers and advice */
  resetAdvice: Maybe<ResetAdvice>;
  resetBudget: Maybe<ResetBudgetMutation>;
  resetBudgetRollover: Maybe<ResetBudgetRolloverMutation>;
  resetRecurringMerchantSearch: Maybe<ResetHouseholdMerchantRecurringSearchMutation>;
  /**
   * Delete (or disable) a category, and move all rules / transactions to
   * move_to_category_id. If move_to_category_id is not specified, use UNCATEGORIZED.
   */
  restoreCategory: Maybe<RestoreCategoryMutation>;
  reviewRecurringStream: Maybe<ReviewRecurringStreamMutation>;
  revokeHouseholdAccessGrant: Maybe<RevokeHouseholdAccessGrant>;
  revokeInviteToHousehold: Maybe<RevokeInviteToHouseholdMutation>;
  /** Revoke subscription sponsorship invite */
  revokeSubscriptionSponsorshipInvite: Maybe<RevokeSponsorshipInvite>;
  sendMessage: SendMessage;
  setAccountLogo: Maybe<SetAccountLogoMutation>;
  setMerchantLogo: Maybe<SetMerchantLogoMutation>;
  setTransactionTags: Maybe<SetTransactionTags>;
  /** Set a users goals during onboarding. This will clear any previous goals set by the user. */
  setUserOnboardingFinancialGoals: Maybe<SetUserOnboardingFinancialGoalMutation>;
  setUserProfilePicture: Maybe<SetUserProfilePictureMutation>;
  startDownloadTransactionsSession: Maybe<StartDownloadTransactionsSession>;
  submitAssistantFeedback: SubmitAssistantFeedback;
  syncAppleFinanceKitAccountInfo: SyncAppleFinanceKitAccountInfo;
  toggleSupportAccountAccess: Maybe<ToggleSupportAccountAccessMutation>;
  triggerRecurringMerchantSearch: Maybe<TriggerRecurringMerchantSearchMutation>;
  unarchiveGoal: Maybe<UnarchiveGoal>;
  undeleteAccount: Maybe<UndeleteAccountMutation>;
  updateAccount: Maybe<UpdateAccountMutation>;
  updateAccountGroupOrder: Maybe<UpdateAccountGroupOrderMutation>;
  updateAccountOrder: Maybe<UpdateAccountOrderMutation>;
  updateAccounts: Maybe<UpdateAccountsMutation>;
  /** Endpoint to update the bill amount of a credit report liability statement. */
  updateBillAmount: Maybe<UpdateBillAmountMutation>;
  /** Endpoint to mark a credit report liability statement paid or unpaid. */
  updateBillPaid: Maybe<UpdateBillPaidMutation>;
  updateBudgetSettings: Maybe<UpdateBudgetSettingsMutation>;
  updateCategory: Maybe<UpdateCategoryPayload>;
  updateCategoryGroup: Maybe<UpdateCategoryGroupMutation>;
  updateCategoryGroupOrder: Maybe<UpdateCategoryGroupOrderMutation>;
  updateCategoryOrderInCategoryGroup: Maybe<UpdateCategoryOrderInCategoryGroupMutation>;
  updateCredential: Maybe<UpdateCredentialMutation>;
  updateDashboardConfig: Maybe<UpdateDashboardConfigMutation>;
  updateGoal: Maybe<UpdateGoalMutation>;
  updateGoalAccountAllocation: Maybe<UpdateGoalAccountAllocation>;
  updateGoalContribution: Maybe<UpdateGoalContributionMutation>;
  updateGoalPriorities: Maybe<UpdateGoalPriorities>;
  updateGoalV2: Maybe<UpdateGoal>;
  /** Bulk version of UpdateGoal */
  updateGoals: Maybe<UpdateGoals>;
  updateHolding: Maybe<UpdateHolding>;
  updateHouseholdPreferences: Maybe<UpdateHouseholdPreferences>;
  updateMe: Maybe<UpdateMe>;
  updateMerchant: Maybe<UpdateMerchantMutation>;
  updateMyHousehold: Maybe<UpdateMyHousehold>;
  updateNotificationPreferences: Maybe<UpdateNotificationPreferences>;
  updateOrCreateBudgetItem: Maybe<UpdateOrCreateBudgetItemMutation>;
  updateOrCreateFlexBudgetItem: Maybe<UpdateOrCreateFlexBudgetItemMutation>;
  updateOrCreateGoalPlannedContribution: Maybe<UpdateOrCreateGoalPlannedContributionMutation>;
  updateOrCreateGoalPlannedContributions: Maybe<UpdateOrCreateGoalPlannedContributionsMutation>;
  updateOrCreateStripePaymentMethod: Maybe<UpdateOrCreateStripePaymentMethodMutation>;
  updateRenewalReminder: Maybe<UpdateRenewalReminder>;
  updateReviewCheckin: Maybe<UpdateReviewCheckinMutation>;
  /** Update subscription sponsor */
  updateSubscriptionSponsor: Maybe<UpdateSponsor>;
  /** Update subscription sponsorship invite */
  updateSubscriptionSponsorshipInvite: Maybe<UpdateSponsorshipInvite>;
  updateTransaction: Maybe<UpdateTransactionMutation>;
  updateTransactionRuleOrderV2: Maybe<UpdateTransactionRuleOrderV2Mutation>;
  updateTransactionRuleV2: Maybe<UpdateTransactionRuleV2Mutation>;
  updateTransactionSplit: Maybe<UpdateTransactionSplitMutation>;
  updateTransactionTag: Maybe<UpdateTransactionTag>;
  updateTransactionTagOrder: Maybe<UpdateTransactionTagOrder>;
  updateUserProfile: Maybe<UpdateUserProfile>;
};

export type MutationAddTransactionAttachmentArgs = {
  input: TransactionAddAttachmentMutationInput;
};

export type MutationAnswerProfileQuestionArgs = {
  input: AnswerProfileQuestionInput;
};

export type MutationAnswerProfileQuestionV2Args = {
  input: AnswerProfileQuestionV2Input;
};

export type MutationApplyStripeCancelDiscountOfferArgs = {
  input: ApplyStripeCancelDiscountOfferInput;
};

export type MutationArchiveGoalArgs = {
  input: ArchiveGoalInput;
};

export type MutationBulkDeleteTransactionsArgs = {
  input: BulkTransactionDataParams;
};

export type MutationBulkUpdateTransactionsArgs = {
  allSelected: Scalars['Boolean']['input'];
  excludedTransactionIds: InputMaybe<Array<Scalars['ID']['input']>>;
  expectedAffectedTransactionCount: InputMaybe<Scalars['Int']['input']>;
  filters: InputMaybe<TransactionFilterInput>;
  selectedTransactionIds: InputMaybe<Array<Scalars['ID']['input']>>;
  updates: TransactionUpdateParams;
};

export type MutationCancelStripeSubscriptionArgs = {
  input: CancelStripeSubscriptionMutationInput;
};

export type MutationCancelSubscriptionSponsorshipArgs = {
  input: CancelSponsorshipInput;
};

export type MutationChangeStripeSubscriptionArgs = {
  input: ChangeStripeSubscriptionMutationInput;
};

export type MutationClearBudgetArgs = {
  input: ClearBudgetMutationInput;
};

export type MutationConfirmTotpDeviceArgs = {
  input: ConfirmTotpDeviceInput;
};

export type MutationCopySnapshotsArgs = {
  input: CopySnapshotsInput;
};

export type MutationCreateAccountsFromPlaidTokenArgs = {
  linkSessionId: InputMaybe<Scalars['String']['input']>;
  plaidInstitutionId: Scalars['String']['input'];
  plaidInstitutionName: Scalars['String']['input'];
  publicToken: Scalars['String']['input'];
};

export type MutationCreateBudgetArgs = {
  input: CreateBudgetMutationInput;
};

export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};

export type MutationCreateCategoryGroupArgs = {
  input: CreateCategoryGroupInput;
};

export type MutationCreateCoinbaseAccountArgs = {
  input: CreateCoinbaseAccountInput;
};

export type MutationCreateFinicityConnectFixUrlArgs = {
  credentialId: Scalars['UUID']['input'];
};

export type MutationCreateFinicityConnectUrlArgs = {
  finicityInstitutionId: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateGoalArgs = {
  input: CreateGoalMutationInput;
};

export type MutationCreateGoalAccountAllocationArgs = {
  input: CreateGoalAccountAllocationInput;
};

export type MutationCreateGoalContributionArgs = {
  input: CreateGoalContributionMutationInput;
};

export type MutationCreateGoalContributionsArgs = {
  input: Array<InputMaybe<CreateGoalContributionsMutationInput>>;
};

export type MutationCreateGoalV2Args = {
  input: CreateGoalInput;
};

export type MutationCreateGoalsArgs = {
  input: CreateGoalsInput;
};

export type MutationCreateHouseholdAccessGrantArgs = {
  input: CreateHouseholdAccessGrantInput;
};

export type MutationCreateInstitutionIssueReportArgs = {
  input: CreateInstitutionIssueReportMutationInput;
};

export type MutationCreateManualAccountArgs = {
  input: CreateManualAccountMutationInput;
};

export type MutationCreateManualHoldingArgs = {
  input: CreateManualHoldingInput;
};

export type MutationCreateManualInvestmentsAccountArgs = {
  input: CreateManualInvestmentsAccountInput;
};

export type MutationCreateMessageThreadArgs = {
  agentType: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateMxConnectFixUrlArgs = {
  credentialId: Scalars['UUID']['input'];
  isDarkMode: InputMaybe<Scalars['Boolean']['input']>;
  isMobileWebview: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateMxConnectUrlArgs = {
  clientRedirectUrl: InputMaybe<Scalars['String']['input']>;
  isDarkMode: InputMaybe<Scalars['Boolean']['input']>;
  isMobileWebview: InputMaybe<Scalars['Boolean']['input']>;
  mxInstitutionId: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateOrUpdateGoalPlannedContributionArgs = {
  input: CreateOrUpdateGoalPlannedContributionInput;
};

export type MutationCreatePlaidLinkTokenArgs = {
  androidPackageName: InputMaybe<Scalars['String']['input']>;
  credentialId: InputMaybe<Scalars['UUID']['input']>;
  linkCustomizationName: InputMaybe<Scalars['String']['input']>;
  plaidInstitutionId: InputMaybe<Scalars['String']['input']>;
  redirectUri: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateRecurringTransactionStreamArgs = {
  amount: Scalars['Float']['input'];
  baseDate: Scalars['Date']['input'];
  frequency: Scalars['String']['input'];
  merchantId: Scalars['ID']['input'];
};

export type MutationCreateReviewCheckinArgs = {
  input: CreateReviewCheckinInput;
};

export type MutationCreateSpinwheelAccountMappingArgs = {
  input: SpinwheelAccountMappingInput;
};

export type MutationCreateStripeSubscriptionArgs = {
  input: CreateStripeSubscriptionMutationInput;
};

export type MutationCreateSubscriptionSponsorshipInvitesArgs = {
  input: CreateSponsorshipInvitesInput;
};

export type MutationCreateSyncedRealEstateAccountArgs = {
  input: CreateSyncedRealEstateAccountInput;
};

export type MutationCreateSyncedVehicleAccountArgs = {
  input: CreateSyncedVehicleAccountInput;
};

export type MutationCreateTransactionArgs = {
  input: CreateTransactionMutationInput;
};

export type MutationCreateTransactionRuleV2Args = {
  input: CreateTransactionRuleInput;
};

export type MutationCreateTransactionTagArgs = {
  input: CreateTransactionTagInput;
};

export type MutationDeleteAccountArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationDeleteAccountLogoArgs = {
  accountId: Scalars['ID']['input'];
};

export type MutationDeleteCategoryArgs = {
  id: Scalars['UUID']['input'];
  moveToCategoryId: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationDeleteCategoryGroupArgs = {
  id: Scalars['UUID']['input'];
  moveToGroupId: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationDeleteCredentialArgs = {
  input: DeleteCredentialInput;
};

export type MutationDeleteGoalArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationDeleteGoalAccountAllocationArgs = {
  input: DeleteGoalAccountAllocationInput;
};

export type MutationDeleteGoalContributionArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationDeleteGoalV2Args = {
  input: DeleteGoalInput;
};

export type MutationDeleteHoldingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteHouseholdArgs = {
  input: DeleteHouseholdMutationInput;
};

export type MutationDeleteMerchantArgs = {
  id: Scalars['ID']['input'];
  moveRelationsToMerchantId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteMerchantLogoArgs = {
  merchantId: Scalars['ID']['input'];
};

export type MutationDeleteMessageThreadArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteRecurringTransactionStreamArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSyncedSnapshotsArgs = {
  input: DeleteSyncedSnapshotsInput;
};

export type MutationDeleteSyncedTransactionsArgs = {
  input: DeleteSyncedTransactionsInput;
};

export type MutationDeleteTransactionArgs = {
  input: DeleteTransactionMutationInput;
};

export type MutationDeleteTransactionAttachmentArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationDeleteTransactionRuleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTransactionTagArgs = {
  tagId: Scalars['ID']['input'];
};

export type MutationDeleteTransactionsArgs = {
  input: DeleteTransactionsInput;
};

export type MutationDeleteUserFromHouseholdArgs = {
  input: DeleteUserFromHouseholdMutationInput;
};

export type MutationDeleteUserProfilePictureArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDisconnectCredentialArgs = {
  accountId: Scalars['UUID']['input'];
};

export type MutationDisconnectCredentialFromDataProviderArgs = {
  input: DisconnectCredentialFromDataProviderInput;
};

export type MutationDisconnectCredentialsFromDataProviderArgs = {
  input: DisconnectCredentialsFromDataProviderInput;
};

export type MutationDismissActivityArgs = {
  id: Scalars['ID']['input'];
};

export type MutationFixCoinbaseCredentialArgs = {
  input: FixCoinbaseCredentialInput;
};

export type MutationForceRefreshAccountArgs = {
  input: ForceRefreshAccountInput;
};

export type MutationForceRefreshAccountsArgs = {
  input: ForceRefreshAccountsInput;
};

export type MutationGetCloudinaryUploadInfoArgs = {
  input: GetLogoCloudinaryUploadInfoMutationInput;
};

export type MutationGetTransactionAttachmentUploadInfoArgs = {
  transactionId: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationInitializeFlexBudgetArgs = {
  input: InitializeFlexBudgetMutationInput;
};

export type MutationInviteUserToHouseholdArgs = {
  input: InviteUserToHouseholdMutationInput;
};

export type MutationMarkActivityAsReadArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationMarkAdviceItemTaskCompleteArgs = {
  input: MarkAdviceItemTaskCompleteInput;
};

export type MutationMarkAdviceItemTaskCompleteV2Args = {
  input: MarkAdviceItemTaskCompleteV2Input;
};

export type MutationMarkAdviceItemTaskIncompleteArgs = {
  input: MarkAdviceItemTaskIncompleteInput;
};

export type MutationMarkAdviceItemTaskIncompleteV2Args = {
  input: MarkAdviceItemTaskIncompleteV2Input;
};

export type MutationMarkGoalCompleteArgs = {
  input: MarkGoalCompleteInput;
};

export type MutationMarkGoalIncompleteArgs = {
  input: MarkGoalIncompleteInput;
};

export type MutationMarkStreamAsNotRecurringArgs = {
  streamId: Scalars['ID']['input'];
};

export type MutationMoveMoneyBetweenCategoriesArgs = {
  input: MoveMoneyMutationInput;
};

export type MutationMoveTransactionsArgs = {
  input: MoveTransactionsInput;
};

export type MutationParseBalanceHistoryArgs = {
  input: ParseBalanceHistoryInput;
};

export type MutationParseUploadStatementSessionArgs = {
  input: ParseStatementInput;
};

export type MutationReactivateStripeSubscriptionArgs = {
  input: ReactivateStripeSubscriptionMutationInput;
};

export type MutationRefreshTransactionsForCredentialArgs = {
  input: RefreshTransactionsForCredentialMutationInput;
};

export type MutationRequestEmailReportArgs = {
  month: InputMaybe<Scalars['Date']['input']>;
};

export type MutationRequestInstitutionArgs = {
  input: RequestInstitutionInput;
};

export type MutationResendInviteToHouseholdArgs = {
  input: ResendInviteToHouseholdMutationInput;
};

export type MutationResetBudgetArgs = {
  input: ResetBudgetMutationInput;
};

export type MutationResetBudgetRolloverArgs = {
  input: ResetBudgetRolloverInput;
};

export type MutationResetRecurringMerchantSearchArgs = {
  deleteStreams: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationRestoreCategoryArgs = {
  id: Scalars['UUID']['input'];
};

export type MutationReviewRecurringStreamArgs = {
  input: ReviewRecurringStreamInput;
};

export type MutationRevokeHouseholdAccessGrantArgs = {
  input: RevokeHouseholdAccessGrantInput;
};

export type MutationRevokeInviteToHouseholdArgs = {
  input: RevokeInviteToHouseholdMutationInput;
};

export type MutationRevokeSubscriptionSponsorshipInviteArgs = {
  input: RevokeSponsorshipInviteInput;
};

export type MutationSendMessageArgs = {
  input: SendMessageInput;
};

export type MutationSetAccountLogoArgs = {
  input: SetAccountLogoInput;
};

export type MutationSetMerchantLogoArgs = {
  input: SetMerchantLogoInput;
};

export type MutationSetTransactionTagsArgs = {
  input: SetTransactionTagsInput;
};

export type MutationSetUserOnboardingFinancialGoalsArgs = {
  input: Array<SetUserOnboardingFinancialGoalInput>;
};

export type MutationSetUserProfilePictureArgs = {
  input: SetUserProfilePictureInput;
};

export type MutationStartDownloadTransactionsSessionArgs = {
  filters: InputMaybe<TransactionFilterInput>;
  orderBy: InputMaybe<Scalars['String']['input']>;
};

export type MutationSubmitAssistantFeedbackArgs = {
  input: SubmitAssistantFeedbackInput;
};

export type MutationSyncAppleFinanceKitAccountInfoArgs = {
  input: SyncAppleFinanceKitAccountInfoInput;
};

export type MutationToggleSupportAccountAccessArgs = {
  isEnabled: Scalars['Boolean']['input'];
};

export type MutationUnarchiveGoalArgs = {
  input: UnarchiveGoalInput;
};

export type MutationUndeleteAccountArgs = {
  input: UndeleteAccountMutationInput;
};

export type MutationUpdateAccountArgs = {
  input: UpdateAccountMutationInput;
};

export type MutationUpdateAccountGroupOrderArgs = {
  input: UpdateAccountGroupOrderInput;
};

export type MutationUpdateAccountOrderArgs = {
  input: UpdateAccountOrderInput;
};

export type MutationUpdateAccountsArgs = {
  input: Array<InputMaybe<UpdateAccountsMutationInput>>;
};

export type MutationUpdateBillAmountArgs = {
  creditReportLiabilityStatementId: Scalars['ID']['input'];
  revertToOriginal: InputMaybe<Scalars['Boolean']['input']>;
  userBillAmount: InputMaybe<Scalars['Decimal']['input']>;
};

export type MutationUpdateBillPaidArgs = {
  creditReportLiabilityStatementId: Scalars['ID']['input'];
  isPaid: Scalars['Boolean']['input'];
};

export type MutationUpdateBudgetSettingsArgs = {
  input: UpdateBudgetSettingsMutationInput;
};

export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};

export type MutationUpdateCategoryGroupArgs = {
  input: UpdateCategoryGroupInput;
};

export type MutationUpdateCategoryGroupOrderArgs = {
  id: Scalars['UUID']['input'];
  order: Scalars['Int']['input'];
};

export type MutationUpdateCategoryOrderInCategoryGroupArgs = {
  categoryGroupId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  order: Scalars['Int']['input'];
};

export type MutationUpdateCredentialArgs = {
  input: UpdateCredentialMutationInput;
};

export type MutationUpdateDashboardConfigArgs = {
  input: Scalars['JSONString']['input'];
};

export type MutationUpdateGoalArgs = {
  input: UpdateGoalMutationInput;
};

export type MutationUpdateGoalAccountAllocationArgs = {
  input: UpdateGoalAccountAllocationInput;
};

export type MutationUpdateGoalContributionArgs = {
  input: UpdateGoalContributionMutationInput;
};

export type MutationUpdateGoalPrioritiesArgs = {
  input: UpdateGoalPrioritiesInput;
};

export type MutationUpdateGoalV2Args = {
  input: UpdateGoalInput;
};

export type MutationUpdateGoalsArgs = {
  input: UpdateGoalsInput;
};

export type MutationUpdateHoldingArgs = {
  input: UpdateHoldingInput;
};

export type MutationUpdateHouseholdPreferencesArgs = {
  input: UpdateHouseholdPreferencesInput;
};

export type MutationUpdateMeArgs = {
  input: UpdateMeInput;
};

export type MutationUpdateMerchantArgs = {
  input: UpdateMerchantInput;
};

export type MutationUpdateMyHouseholdArgs = {
  input: UpdateMyHouseholdInput;
};

export type MutationUpdateNotificationPreferencesArgs = {
  input: UpdateNotificationPreferencesInput;
};

export type MutationUpdateOrCreateBudgetItemArgs = {
  input: UpdateOrCreateBudgetItemMutationInput;
};

export type MutationUpdateOrCreateFlexBudgetItemArgs = {
  input: UpdateOrCreateFlexBudgetItemMutationInput;
};

export type MutationUpdateOrCreateGoalPlannedContributionArgs = {
  input: UpdateOrCreateGoalPlannedContributionMutationInput;
};

export type MutationUpdateOrCreateGoalPlannedContributionsArgs = {
  input: Array<InputMaybe<UpdateOrCreateGoalPlannedContributionMutationInput>>;
};

export type MutationUpdateOrCreateStripePaymentMethodArgs = {
  input: UpdateOrCreateStripePaymentMethodMutationInput;
};

export type MutationUpdateRenewalReminderArgs = {
  input: UpdateRenewalReminderInput;
};

export type MutationUpdateReviewCheckinArgs = {
  input: UpdateReviewCheckinInput;
};

export type MutationUpdateSubscriptionSponsorArgs = {
  input: UpdateSponsorInput;
};

export type MutationUpdateSubscriptionSponsorshipInviteArgs = {
  input: UpdateSponsorshipInviteInput;
};

export type MutationUpdateTransactionArgs = {
  input: UpdateTransactionMutationInput;
};

export type MutationUpdateTransactionRuleOrderV2Args = {
  id: Scalars['ID']['input'];
  order: Scalars['Int']['input'];
};

export type MutationUpdateTransactionRuleV2Args = {
  input: UpdateTransactionRuleInput;
};

export type MutationUpdateTransactionSplitArgs = {
  input: UpdateTransactionSplitMutationInput;
};

export type MutationUpdateTransactionTagArgs = {
  input: UpdateTransactionTagInput;
};

export type MutationUpdateTransactionTagOrderArgs = {
  order: Scalars['Int']['input'];
  tagId: Scalars['ID']['input'];
};

export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

/** Block used to show a net worth chart. */
export type NetWorthChartBlock = Block & {
  __typename: 'NetWorthChartBlock';
  link: Maybe<BlockLink>;
  snapshotsByAccountType: Array<SnapshotByType>;
};

/** Block used to show a net worth chart. */
export type NetWorthChartBlockSnapshotsByAccountTypeArgs = {
  startDate: InputMaybe<Scalars['Date']['input']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

export type NotificationPreference = {
  __typename: 'NotificationPreference';
  EMAIL: Scalars['Boolean']['output'];
  PUSH: Scalars['Boolean']['output'];
  additionalPreferences: Maybe<Scalars['GenericScalar']['output']>;
  additionalPreferencesMeta: Maybe<Scalars['GenericScalar']['output']>;
  description: Maybe<Scalars['String']['output']>;
  group: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type OnboardingAttributionItem = {
  __typename: 'OnboardingAttributionItem';
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type OnboardingFinancialGoal = {
  __typename: 'OnboardingFinancialGoal';
  category: Maybe<OnboardingFinancialGoalCategory>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum OnboardingFinancialGoalCategory {
  DEBT_AND_CREDIT = 'DEBT_AND_CREDIT',
  INVESTING = 'INVESTING',
  OTHER = 'OTHER',
  PLANNING = 'PLANNING',
  SAVINGS = 'SAVINGS',
}

/** An enumeration. */
export enum OnboardingStatus {
  ACTIVE = 'active',
  DISCONNECTED = 'disconnected',
  INACTIVE_ERROR = 'inactive_error',
  INACTIVE_ORDERED = 'inactive_ordered',
  INACTIVE_UNVERIFIED = 'inactive_unverified',
  INACTIVE_VERIFIED = 'inactive_verified',
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Block used to show a large page title with optional header & subtitle text. */
export type PageTitleBlock = Block & {
  __typename: 'PageTitleBlock';
  centerSubtitle: Maybe<Scalars['Boolean']['output']>;
  header: Maybe<Scalars['String']['output']>;
  link: Maybe<BlockLink>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type ParseBalanceHistoryInput = {
  accountFilesMapping: InputMaybe<Scalars['JSONString']['input']>;
  sessionKey: Scalars['String']['input'];
};

export type ParseBalanceHistoryMutation = {
  __typename: 'ParseBalanceHistoryMutation';
  uploadBalanceHistorySession: UploadBalanceHistorySession;
};

export type ParseStatementInput = {
  accountId: InputMaybe<Scalars['ID']['input']>;
  accountNameMapping: InputMaybe<Scalars['JSONString']['input']>;
  categoryMapping: InputMaybe<Scalars['JSONString']['input']>;
  importPriority: InputMaybe<Scalars['String']['input']>;
  parserName: Scalars['String']['input'];
  sessionKey: Scalars['String']['input'];
  shouldUpdateBalance: InputMaybe<Scalars['Boolean']['input']>;
  skipCheckForDuplicates: InputMaybe<Scalars['Boolean']['input']>;
  tagMapping: InputMaybe<Scalars['JSONString']['input']>;
};

export type ParseStatementMutation = {
  __typename: 'ParseStatementMutation';
  uploadStatementSession: UploadStatementSession;
};

/**
 * A PayloadError is an error in the response payload of a query or mutation.
 *
 * PayloadError has the following properties (all optional):
 *  - A list of 'fieldErrors' (validation errors specific to a particular field in the input).
 *  - A 'message', possibly something we'd show to the user.
 *  - A 'code' that can be used to respond with a certain behavior on the client.
 *
 * Different calls can decide how to use these fields, but the presence of ANY field implies
 * some error happened during execution.
 */
export type PayloadError = {
  __typename: 'PayloadError';
  code: Maybe<ErrorCode>;
  fieldErrors: Maybe<Array<FieldError>>;
  message: Maybe<Scalars['String']['output']>;
};

export type PaymentMethod = {
  __typename: 'PaymentMethod';
  brand: Scalars['String']['output'];
  lastFour: Scalars['String']['output'];
};

/** An enumeration. */
export enum PaymentPeriod {
  LIFETIME = 'LIFETIME',
  MONTHLY = 'MONTHLY',
  OTHER = 'OTHER',
  YEARLY = 'YEARLY',
}

export type PendingEmailUpdateVerification = {
  __typename: 'PendingEmailUpdateVerification';
  email: Scalars['String']['output'];
  expiresAt: Scalars['DateTime']['output'];
};

/** Block used to show a pie chart with legend. */
export type PieChartBlock = Block & {
  __typename: 'PieChartBlock';
  link: Maybe<BlockLink>;
  slices: Array<PieChartSlice>;
};

/** Represents one slice of a pie chart. */
export type PieChartSlice = Block & {
  __typename: 'PieChartSlice';
  color: Scalars['String']['output'];
  label: Scalars['String']['output'];
  link: Maybe<BlockLink>;
  value: Scalars['Float']['output'];
};

/** An enumeration. */
export enum Platform {
  ANDROID = 'android',
  IOS = 'ios',
  WEB = 'web',
}

export type PlatformDashboardConfig = {
  __typename: 'PlatformDashboardConfig';
  layout: Scalars['GenericScalar']['output'];
  widgets: Scalars['GenericScalar']['output'];
};

export type Portfolio = {
  __typename: 'Portfolio';
  aggregateHoldings: AggregateHoldingConnection;
  allocationSimple: Array<Maybe<SimplePortfolioAliquot>>;
  /** Get performance for a list of date ranges. limited to last year. */
  batchedPerformance: BatchedPortfolioPerformance;
  performance: PortfolioPerformance;
};

export type PortfolioAggregateHoldingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type PortfolioAllocationSimpleArgs = {
  input: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PortfolioBatchedPerformanceArgs = {
  input: InputMaybe<Array<InputMaybe<DateRange>>>;
};

export type PortfolioInput = {
  /** List of accounts to aggregate holdings from. Defaults to all accounts if omitted. */
  accountIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Inclusive end date of the query. Defaults to today. */
  endDate: InputMaybe<Scalars['Date']['input']>;
  includeHiddenHoldings: InputMaybe<Scalars['Boolean']['input']>;
  /** Inclusive start date of the query. Defaults to 90 days ago. */
  startDate: InputMaybe<Scalars['Date']['input']>;
  /** Number of top movers to include. Defaults to 4 */
  topMoversLimit: InputMaybe<Scalars['Int']['input']>;
};

export type PortfolioPerformance = {
  __typename: 'PortfolioPerformance';
  benchmarks: Array<SecurityPerformance>;
  historicalChart: Array<SecurityPerformanceChartDatum>;
  oneDayChangeDollars: Scalars['Float']['output'];
  oneDayChangePercent: Scalars['Float']['output'];
  topMovers: Array<Security>;
  totalBasis: Scalars['Float']['output'];
  totalChangeDollars: Scalars['Float']['output'];
  totalChangePercent: Scalars['Float']['output'];
  totalValue: Scalars['Float']['output'];
};

export type PreviewCopySnapshotsInput = {
  cutoverDate: Scalars['Date']['input'];
  fromAccountId: Scalars['UUID']['input'];
  toAccountId: Scalars['UUID']['input'];
};

/** This is for the "preview_delete_synced_transactions" API */
export type PreviewDeleteSyncedTransactionList = {
  __typename: 'PreviewDeleteSyncedTransactionList';
  results: Array<Transaction>;
  totalCount: Scalars['Int']['output'];
  /** Count of transactions that can be selected for a bulk operation given filters and preferences */
  totalSelectableCount: Maybe<Scalars['Int']['output']>;
};

/** This is for the "preview_delete_synced_transactions" API */
export type PreviewDeleteSyncedTransactionListResultsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<TransactionOrdering>;
};

/** This is for the "preview_delete_transactions" API */
export type PreviewDeleteTransactionList = {
  __typename: 'PreviewDeleteTransactionList';
  results: Array<Transaction>;
  totalCount: Scalars['Int']['output'];
  /** Count of transactions that can be selected for a bulk operation given filters and preferences */
  totalSelectableCount: Maybe<Scalars['Int']['output']>;
};

/** This is for the "preview_delete_transactions" API */
export type PreviewDeleteTransactionListResultsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<TransactionOrdering>;
};

/** An enumeration. */
export enum ProductFeature {
  APPLE_CARD = 'apple_card',
  ATTACHMENTS = 'attachments',
  COINBASE = 'coinbase',
  CUSTOM_CATEGORIES = 'custom_categories',
  CUSTOMER_SUPPORT = 'customer_support',
  DOWNLOAD_CSV = 'download_csv',
  INVESTMENTS = 'investments',
  ROLLOVERS = 'rollovers',
  TAGS = 'tags',
  WEEKLY_REVIEW = 'weekly_review',
  ZILLOW = 'zillow',
}

export type ProfileQuestion = {
  __typename: 'ProfileQuestion';
  answer: Maybe<ProfileQuestionAnswer>;
  description: Maybe<Scalars['String']['output']>;
  index: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  options: Maybe<Array<ProfileQuestionOption>>;
  previousQuestion: Maybe<ProfileQuestion>;
  required: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ProfileQuestionAnswer = {
  __typename: 'ProfileQuestionAnswer';
  answer: Maybe<Scalars['JSONString']['output']>;
  answeredAt: Maybe<Scalars['DateTime']['output']>;
  dontKnow: Scalars['Boolean']['output'];
};

export type ProfileQuestionOption = {
  __typename: 'ProfileQuestionOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Object passed in should be a Questionnaire instance */
export type ProfileQuestionnaire = {
  __typename: 'ProfileQuestionnaire';
  firstQuestion: Maybe<ProfileQuestion>;
  name: Scalars['String']['output'];
  nextQuestion: Maybe<ProfileQuestion>;
  question: Maybe<ProfileQuestion>;
  state: ProfileQuestionnaireState;
};

/** Object passed in should be a Questionnaire instance */
export type ProfileQuestionnaireNextQuestionArgs = {
  currQuestionName: InputMaybe<Scalars['String']['input']>;
};

/** Object passed in should be a Questionnaire instance */
export type ProfileQuestionnaireQuestionArgs = {
  name: Scalars['String']['input'];
};

export type ProfileQuestionnaireState = {
  __typename: 'ProfileQuestionnaireState';
  completed: Scalars['Boolean']['output'];
  numQuestions: Scalars['Int']['output'];
  numQuestionsAnswered: Scalars['Int']['output'];
};

export type PromoCode = {
  __typename: 'PromoCode';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type Query = {
  __typename: 'Query';
  account: Maybe<Account>;
  accountSubtypes: Array<AccountSubtype>;
  accountTypeOptions: Array<AccountTypeOption>;
  accountTypeSummaries: Array<AccountTypeSummary>;
  accountTypes: Array<AccountType>;
  accounts: Array<Account>;
  accountsBelongingTo: Array<Account>;
  accountsWithUnallocatedBalancesForGoals: Array<Account>;
  activityEvents: Maybe<ActivityConnection>;
  adviceItem: Maybe<AdviceItem>;
  adviceItemCategories: Array<AdviceItemCategory>;
  adviceItemTask: Maybe<AdviceItemTask>;
  adviceItemTaskV2: Maybe<AdviceItemTaskV2>;
  adviceItemV2: Maybe<AdviceItemV2>;
  adviceItems: Maybe<Array<AdviceItem>>;
  adviceItemsV2: Maybe<Array<AdviceItemV2>>;
  /** @deprecated Use portfolio > aggregate holdings instead */
  aggregateHoldings: Maybe<AggregateHoldingConnection>;
  aggregateSnapshots: Array<AggregateSnapshot>;
  aggregates: Array<AggregateData>;
  allInstitutions: Array<Institution>;
  allTransactions: TransactionList;
  budgetData: BudgetData;
  budgetMonthlyAmounts: Array<BudgetMonthlyAmounts>;
  budgetStatus: BudgetStatus;
  budgetSystem: BudgetSystem;
  /** Returns the argument passed in. Useful to work around some limitations of Apollo on the frontend. */
  cacheKey: Maybe<Scalars['String']['output']>;
  canSkipPremiumUpsell: Scalars['Boolean']['output'];
  categories: Array<Category>;
  category: Maybe<Category>;
  categoryDeletionInfo: Maybe<CategoryDeletionInfo>;
  categoryGroup: Maybe<CategoryGroup>;
  categoryGroups: Array<CategoryGroup>;
  constants: Constants;
  contactFormData: Maybe<ContactSupportFormData>;
  credential: Maybe<Credential>;
  credentials: Array<Credential>;
  creditBalance: Maybe<Scalars['Float']['output']>;
  creditReportLiabilityAccounts: Array<Maybe<CreditReportLiabilityAccount>>;
  creditReportLiabilityStatements: Maybe<Array<LiabilityStatement>>;
  downgradeActionItems: Array<Scalars['String']['output']>;
  downloadTransactionsSession: Maybe<DownloadTransactionsSession>;
  /** Returns an explanation of the transaction matching the given id. */
  explainTransaction: Maybe<TransactionExplanation>;
  flexExpenseRolloverPeriod: Maybe<BudgetRolloverPeriod>;
  getAccountsForTransactions: Array<Account>;
  /** Returns the transaction matching the given id if it exists for the user, otherwise null. */
  getTransaction: Maybe<Transaction>;
  globalSearch: Maybe<GlobalSearch>;
  goal: Goal;
  goalBalances: GoalAllocationBalances;
  goalMonthlyBudgetAmounts: Array<GoalMonthlyBudgetAmounts>;
  goalMonthlyContributions: Array<GoalMonthlyContribution>;
  goalOptions: Array<GoalOption>;
  goalPlannedContributions: Array<GoalPlannedContribution>;
  goalPlannedContributionsV2: Array<GoalPlannedContributionV2>;
  goalRecentContributions: Maybe<GoalContributionConnection>;
  goalTypes: Array<GoalType>;
  goalV2: Maybe<GoalV2>;
  goals: Array<Goal>;
  goalsV2: Array<GoalV2>;
  hasAccounts: Scalars['Boolean']['output'];
  hasAccountsSyncing: Scalars['Boolean']['output'];
  householdAccessGrants: Maybe<Array<HouseholdAccessGrant>>;
  householdInvites: Array<HouseholdInvite>;
  householdMerchant: Maybe<HouseholdMerchant>;
  householdMerchants: Array<HouseholdMerchant>;
  householdPreferences: Maybe<HouseholdPreferences>;
  householdTransactionTags: Array<TransactionTag>;
  householdTranscriptionKey: Scalars['String']['output'];
  institution: Maybe<Institution>;
  invoices: Maybe<Array<StripeInvoice>>;
  me: User;
  merchant: Maybe<Merchant>;
  merchantCount: Scalars['Int']['output'];
  merchants: Array<Merchant>;
  messageThread: Maybe<MessageThread>;
  messageThreads: Array<MessageThread>;
  migratedToGoalsV2: Scalars['Boolean']['output'];
  mobileSubscriptionOffering: MobileSubscriptionOffering;
  /** @deprecated Use snapshotsByAccountType with `month` as timeframe. */
  monthlySnapshotsByAccountType: Array<SnapshotByType>;
  myHousehold: Household;
  nonExemptEndpoint: Maybe<Scalars['Boolean']['output']>;
  notificationPreference: Maybe<NotificationPreference>;
  notificationPreferences: Maybe<Array<NotificationPreference>>;
  oldestDeletableSyncedSnapshotDate: Maybe<Scalars['Date']['output']>;
  oldestDeletableSyncedTransactionDate: Maybe<Scalars['Date']['output']>;
  /** Filter all transactions before this date. */
  oldestDeletableTransactionDate: Maybe<Scalars['Date']['output']>;
  onboardingFinancialGoals: Array<OnboardingFinancialGoal>;
  portfolio: Portfolio;
  previewCopySnapshots: Maybe<Array<AccountSnapshot>>;
  previewDeleteSyncedSnapshots: Maybe<Scalars['Int']['output']>;
  previewDeleteSyncedTransactions: PreviewDeleteSyncedTransactionList;
  previewDeleteTransactions: PreviewDeleteTransactionList;
  productBoardToken: Scalars['String']['output'];
  profileQuestionnaire: Maybe<ProfileQuestionnaire>;
  profileQuestionnaireV2: Maybe<ProfileQuestionnaire>;
  /** Returns a list of category suggestions for a given transaction. */
  recommendedCategories: Maybe<Array<RecommendedCategory>>;
  /** Returns a list of merchant suggestions for a given transaction. */
  recommendedMerchants: Maybe<Array<RecommendedMerchant>>;
  recommendedResolutionLinks: ResolutionBlocksData;
  recurringMerchantSearch: Maybe<RecurringMerchantSearchData>;
  /** Aggregated info about remaining due items for a household. */
  recurringRemainingDue: RecurringRemainingDueData;
  /** Returns all expected recurring items within the date range. */
  recurringTransactionItems: Array<RecurringTransactionCalendarItem>;
  /** Returns all recurring transaction streams for a household and its expected next charge. */
  recurringTransactionStreams: Array<RecurringTransactionItem>;
  referralStatistics: ReferralStatistics;
  reports: Array<ReportsData>;
  /** Get the timeframe for a report. */
  reportsTimeframe: ReportsGroupByTimeframe;
  review: Maybe<Review>;
  reviewCheckinsByMonth: Array<ReviewCheckinMonthDetails>;
  securities: Maybe<Array<Security>>;
  security: Maybe<Security>;
  securityHistoricalPerformance: Array<SecurityPerformance>;
  securityTypes: Array<SecurityType>;
  shouldShowPremiumUpsell: Scalars['Boolean']['output'];
  snapshotsByAccountType: Array<SnapshotByType>;
  snapshotsForAccount: Array<AccountSnapshot>;
  spinwheelUser: Maybe<SpinwheelUser>;
  sponsorInvoices: Maybe<Array<StripeInvoice>>;
  sponsoredHouseholds: Maybe<Array<SponsoredHouseholdInfo>>;
  staticTokens: Maybe<Array<Maybe<StaticToken>>>;
  stripeCancellationDiscountOffer: Maybe<StripeCancellationDiscountOffer>;
  subscription: HouseholdSubscription;
  subscriptionOffering: StripeSubscriptionOffering;
  subscriptionSponsor: Maybe<SubscriptionSponsor>;
  subscriptionSponsorAccessGrants: Maybe<Array<HouseholdAccessGrant>>;
  subscriptionSponsorshipDemoAccount: Maybe<SubscriptionSponsorshipDemoAccount>;
  subscriptionSponsorshipInvites: Maybe<Array<SubscriptionSponsorshipInvite>>;
  subscriptionSponsorships: Maybe<Array<SubscriptionSponsorship>>;
  topInstitutionGroupByType: Maybe<TopInstitutionGroup>;
  topInstitutionGroups: Array<TopInstitutionGroup>;
  /** @deprecated Moving to top_institution_groups */
  topInstitutions: Array<Institution>;
  transactionAttachment: Maybe<TransactionAttachment>;
  transactionRulePreview: TransactionPreviewList;
  transactionRules: Array<TransactionRuleV2>;
  triggerError: Maybe<Scalars['Boolean']['output']>;
  triggerTimeout: Maybe<Scalars['Boolean']['output']>;
  upcomingSponsorInvoice: UpcomingStripeInvoice;
  uploadBalanceHistorySession: Maybe<UploadBalanceHistorySession>;
  uploadStatementSession: Maybe<UploadStatementSession>;
  userOnboardingFinancialGoals: Array<UserOnboardingFinancialGoal>;
  userProfile: Maybe<UserProfile>;
  userReportedOnboardingAttributionChannels: Array<OnboardingAttributionItem>;
  users: Maybe<Array<User>>;
  vehicles: Array<Vehicle>;
  zestimates: Array<ZestimateResult>;
};

export type QueryAccountArgs = {
  id: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryAccountsArgs = {
  filters: InputMaybe<AccountFilters>;
};

export type QueryAccountsBelongingToArgs = {
  userId: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryActivityEventsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAdviceItemArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAdviceItemTaskArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAdviceItemTaskV2Args = {
  id: Scalars['ID']['input'];
};

export type QueryAdviceItemV2Args = {
  id: Scalars['ID']['input'];
};

export type QueryAdviceItemsArgs = {
  category: InputMaybe<Scalars['String']['input']>;
  group: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<Scalars['String']['input']>;
};

export type QueryAdviceItemsV2Args = {
  type: InputMaybe<Scalars['String']['input']>;
};

export type QueryAggregateHoldingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  input: InputMaybe<AggregateHoldingsInput>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAggregateSnapshotsArgs = {
  filters: InputMaybe<AggregateSnapshotFilters>;
};

export type QueryAggregatesArgs = {
  fillEmptyValues: InputMaybe<Scalars['Boolean']['input']>;
  filters: InputMaybe<TransactionFilterInput>;
  groupBy: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryAllInstitutionsArgs = {
  dataProviders: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  includeInactive: InputMaybe<Scalars['Boolean']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type QueryAllTransactionsArgs = {
  filters: InputMaybe<TransactionFilterInput>;
  merchants: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type QueryBudgetDataArgs = {
  endMonth: Scalars['Date']['input'];
  startMonth: Scalars['Date']['input'];
};

export type QueryBudgetMonthlyAmountsArgs = {
  endMonth: Scalars['Date']['input'];
  startMonth: Scalars['Date']['input'];
};

export type QueryCacheKeyArgs = {
  cacheKey: InputMaybe<Scalars['String']['input']>;
};

export type QueryCategoriesArgs = {
  includeDisabledSystemCategories: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCategoryArgs = {
  id: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryCategoryDeletionInfoArgs = {
  id: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryCategoryGroupArgs = {
  id: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryCredentialArgs = {
  id: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryCredentialsArgs = {
  filters: InputMaybe<CredentialFilters>;
};

export type QueryCreditReportLiabilityStatementsArgs = {
  filters: InputMaybe<LiabilityStatementFilterInput>;
  limit: InputMaybe<Scalars['Int']['input']>;
};

export type QueryDownloadTransactionsSessionArgs = {
  sessionKey: Scalars['String']['input'];
};

export type QueryExplainTransactionArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryGetAccountsForTransactionsArgs = {
  excludedTransactionIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  expectedAffectedTransactionCount: InputMaybe<Scalars['Int']['input']>;
  filters: InputMaybe<TransactionFilterInput>;
  isAllSelected: InputMaybe<Scalars['Boolean']['input']>;
  params: InputMaybe<BulkTransactionDataParams>;
  selectedTransactionIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryGetTransactionArgs = {
  id: Scalars['UUID']['input'];
  redirectPosted: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGlobalSearchArgs = {
  searchTerm: Scalars['String']['input'];
};

export type QueryGoalArgs = {
  id: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryGoalMonthlyBudgetAmountsArgs = {
  endMonth: Scalars['Date']['input'];
  startMonth: Scalars['Date']['input'];
};

export type QueryGoalMonthlyContributionsArgs = {
  endDate: InputMaybe<Scalars['Date']['input']>;
  startDate: InputMaybe<Scalars['Date']['input']>;
};

export type QueryGoalPlannedContributionsArgs = {
  endDate: InputMaybe<Scalars['Date']['input']>;
  startDate: InputMaybe<Scalars['Date']['input']>;
};

export type QueryGoalPlannedContributionsV2Args = {
  endMonth: InputMaybe<Scalars['Date']['input']>;
  startMonth: InputMaybe<Scalars['Date']['input']>;
};

export type QueryGoalRecentContributionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<GoalContributionFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGoalV2Args = {
  id: Scalars['ID']['input'];
};

export type QueryGoalsV2Args = {
  accountId: InputMaybe<Scalars['ID']['input']>;
  ids: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryHasAccountsArgs = {
  onlySynced: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHouseholdInvitesArgs = {
  includeInactive: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHouseholdMerchantArgs = {
  id: Scalars['ID']['input'];
};

export type QueryHouseholdMerchantsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type QueryHouseholdTransactionTagsArgs = {
  bulkParams: InputMaybe<BulkTransactionDataParams>;
  limit: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type QueryInstitutionArgs = {
  finicityId: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
  mxId: InputMaybe<Scalars['String']['input']>;
  plaidId: InputMaybe<Scalars['String']['input']>;
};

export type QueryMerchantArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type QueryMerchantsArgs = {
  includeIds: InputMaybe<Array<Scalars['ID']['input']>>;
  includeMerchantsWithoutTransactions: InputMaybe<Scalars['Boolean']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<MerchantOrdering>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type QueryMessageThreadArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMobileSubscriptionOfferingArgs = {
  platform: InputMaybe<Platform>;
};

export type QueryMonthlySnapshotsByAccountTypeArgs = {
  startDate: InputMaybe<Scalars['Date']['input']>;
};

export type QueryNotificationPreferenceArgs = {
  id: InputMaybe<Scalars['String']['input']>;
};

export type QueryOldestDeletableTransactionDateArgs = {
  includeManual: InputMaybe<Scalars['Boolean']['input']>;
  includeSynced: InputMaybe<Scalars['Boolean']['input']>;
  includeUploaded: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPortfolioArgs = {
  input: InputMaybe<PortfolioInput>;
};

export type QueryPreviewCopySnapshotsArgs = {
  input: PreviewCopySnapshotsInput;
};

export type QueryPreviewDeleteSyncedSnapshotsArgs = {
  beforeDate: InputMaybe<Scalars['Date']['input']>;
};

export type QueryPreviewDeleteSyncedTransactionsArgs = {
  beforeDate: InputMaybe<Scalars['Date']['input']>;
};

export type QueryPreviewDeleteTransactionsArgs = {
  beforeDate: InputMaybe<Scalars['Date']['input']>;
  includeManual: InputMaybe<Scalars['Boolean']['input']>;
  includeSynced: InputMaybe<Scalars['Boolean']['input']>;
  includeUploaded: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryProfileQuestionnaireArgs = {
  name: Scalars['String']['input'];
};

export type QueryProfileQuestionnaireV2Args = {
  name: Scalars['String']['input'];
};

export type QueryRecommendedCategoriesArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRecommendedMerchantsArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRecommendedResolutionLinksArgs = {
  credentialId: InputMaybe<Scalars['String']['input']>;
  reason: InputMaybe<ContactSupportReason>;
};

export type QueryRecurringRemainingDueArgs = {
  endDate: Scalars['Date']['input'];
  filters: InputMaybe<RecurringTransactionFilter>;
  includeLiabilities: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['Date']['input'];
};

export type QueryRecurringTransactionItemsArgs = {
  endDate: Scalars['Date']['input'];
  filters: InputMaybe<RecurringTransactionFilter>;
  includeLiabilities: InputMaybe<Scalars['Boolean']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['Date']['input'];
};

export type QueryRecurringTransactionStreamsArgs = {
  filters: InputMaybe<RecurringTransactionFilter>;
  includeLiabilities?: InputMaybe<Scalars['Boolean']['input']>;
  includePending: InputMaybe<Scalars['Boolean']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};

export type QueryReportsArgs = {
  fillEmptyValues: InputMaybe<Scalars['Boolean']['input']>;
  filters: TransactionFilterInput;
  groupBy: InputMaybe<Array<ReportsGroupByEntity>>;
  isTimeBasedReport: InputMaybe<Scalars['Boolean']['input']>;
  sortBy: InputMaybe<ReportsSortBy>;
};

export type QueryReportsTimeframeArgs = {
  filters: TransactionFilterInput;
};

export type QueryReviewArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryReviewCheckinsByMonthArgs = {
  reviewType: InputMaybe<Scalars['String']['input']>;
};

export type QuerySecuritiesArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  orderByPopularity: InputMaybe<Scalars['Boolean']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type QuerySecurityArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySecurityHistoricalPerformanceArgs = {
  input: InputMaybe<SecurityHistoricalPerformanceInput>;
};

export type QuerySnapshotsByAccountTypeArgs = {
  endDate: InputMaybe<Scalars['Date']['input']>;
  startDate: Scalars['Date']['input'];
  timeframe: Timeframe;
};

export type QuerySnapshotsForAccountArgs = {
  accountId: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryStripeCancellationDiscountOfferArgs = {
  reason: CancellationReason;
};

export type QuerySubscriptionOfferingArgs = {
  stripePromoCode: InputMaybe<Scalars['String']['input']>;
};

export type QueryTopInstitutionGroupByTypeArgs = {
  dataProviders: Array<InputMaybe<Scalars['String']['input']>>;
  topInstitutionGroupType: InputMaybe<Scalars['String']['input']>;
};

export type QueryTopInstitutionGroupsArgs = {
  dataProviders: Array<InputMaybe<Scalars['String']['input']>>;
};

export type QueryTopInstitutionsArgs = {
  includeInactive: InputMaybe<Scalars['Boolean']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  platform: InputMaybe<Platform>;
};

export type QueryTransactionAttachmentArgs = {
  id: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryTransactionRulePreviewArgs = {
  input: TransactionRulePreviewInput;
};

export type QueryTriggerTimeoutArgs = {
  seconds: InputMaybe<Scalars['Int']['input']>;
};

export type QueryUploadBalanceHistorySessionArgs = {
  sessionKey: Scalars['String']['input'];
};

export type QueryUploadStatementSessionArgs = {
  sessionKey: Scalars['String']['input'];
};

export type QueryVehiclesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type QueryZestimatesArgs = {
  address: Scalars['String']['input'];
  limit: InputMaybe<Scalars['Int']['input']>;
};

/** Reactivates a user's household's Stripe subscription after they have canceled. */
export type ReactivateStripeSubscriptionMutation = {
  __typename: 'ReactivateStripeSubscriptionMutation';
  errors: Maybe<PayloadError>;
  subscription: Maybe<HouseholdSubscription>;
};

export type ReactivateStripeSubscriptionMutationInput = {
  paymentMethodId: InputMaybe<Scalars['String']['input']>;
  stripePriceId: Scalars['String']['input'];
  /** A case-insensitive promotional code, i.e. FALL20 */
  stripePromoCode: InputMaybe<Scalars['String']['input']>;
};

export type RecommendedCategory = {
  __typename: 'RecommendedCategory';
  category: Category;
  occurrences: Scalars['Int']['output'];
};

export type RecommendedMerchant = {
  __typename: 'RecommendedMerchant';
  name: Scalars['String']['output'];
  source: Scalars['String']['output'];
  transactionCount: Maybe<Scalars['Int']['output']>;
};

export type RecurringMerchantSearchData = {
  __typename: 'RecurringMerchantSearchData';
  createdCount: Maybe<Scalars['Int']['output']>;
  finishedAt: Maybe<Scalars['DateTime']['output']>;
  nextAt: Maybe<Scalars['DateTime']['output']>;
  startedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RecurringRemainingDueData = {
  __typename: 'RecurringRemainingDueData';
  amount: Scalars['Float']['output'];
};

/** An enumeration. */
export enum RecurringStreamReviewStatus {
  APPROVED = 'approved',
  AUTOMATIC_APPROVED = 'automatic_approved',
  IGNORED = 'ignored',
  PENDING = 'pending',
}

export type RecurringTransactionCalendarItem = {
  __typename: 'RecurringTransactionCalendarItem';
  account: Maybe<Account>;
  amount: Maybe<Scalars['Float']['output']>;
  amountDiff: Maybe<Scalars['Float']['output']>;
  category: Maybe<Category>;
  creditReportLiabilityStatementId: Maybe<Scalars['String']['output']>;
  date: Scalars['Date']['output'];
  isAmountDifferentThanOriginal: Maybe<Scalars['Boolean']['output']>;
  isCompleted: Maybe<Scalars['Boolean']['output']>;
  isLate: Scalars['Boolean']['output'];
  isPast: Scalars['Boolean']['output'];
  liabilityStatement: Maybe<LiabilityStatement>;
  markedPaidAt: Maybe<Scalars['DateTime']['output']>;
  stream: RecurringTransactionStream;
  transactionId: Maybe<Scalars['String']['output']>;
};

export type RecurringTransactionFilter = {
  accounts: InputMaybe<Array<Scalars['ID']['input']>>;
  isCompleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type RecurringTransactionItem = {
  __typename: 'RecurringTransactionItem';
  account: Maybe<Account>;
  category: Maybe<Category>;
  nextForecastedTransaction: RecurringTransactionNextForecastedTransaction;
  stream: RecurringTransactionStream;
};

export type RecurringTransactionNextForecastedTransaction = {
  __typename: 'RecurringTransactionNextForecastedTransaction';
  amount: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
};

export type RecurringTransactionStream = {
  __typename: 'RecurringTransactionStream';
  amount: Maybe<Scalars['Float']['output']>;
  baseDate: Scalars['Date']['output'];
  creditReportLiabilityAccount: Maybe<CreditReportLiabilityAccount>;
  dayOfTheMonth: Maybe<Scalars['Int']['output']>;
  defaultPaymentAccount: Maybe<Account>;
  defaultPaymentCategory: Maybe<Category>;
  frequency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isApproximate: Scalars['Boolean']['output'];
  logoUrl: Maybe<Scalars['String']['output']>;
  merchant: Maybe<Merchant>;
  name: Scalars['String']['output'];
  reviewStatus: Maybe<RecurringStreamReviewStatus>;
};

/** An enumeration. */
export enum ReferralCampaign {
  JUNE_2024_DISCOUNT = 'june_2024_discount',
}

export type ReferralRedemption = {
  __typename: 'ReferralRedemption';
  campaign: Maybe<Scalars['String']['output']>;
};

export type ReferralStatistics = {
  __typename: 'ReferralStatistics';
  creditsEarned: Scalars['Float']['output'];
  signedUp: Scalars['Int']['output'];
  subscribed: Scalars['Int']['output'];
};

export type RefreshTransactionsForCredentialMutation = {
  __typename: 'RefreshTransactionsForCredentialMutation';
  errors: Maybe<PayloadError>;
  success: Scalars['Boolean']['output'];
};

export type RefreshTransactionsForCredentialMutationInput = {
  credentialId: Scalars['UUID']['input'];
};

export type ReportsData = {
  __typename: 'ReportsData';
  groupBy: ReportsGroupByData;
  /** Summary of transactions for this group. */
  summary: TransactionsSummary;
};

export type ReportsGroupByData = {
  __typename: 'ReportsGroupByData';
  category: Maybe<Category>;
  categoryGroup: Maybe<CategoryGroup>;
  /** Date key the report is grouped by in ISO format (e.g. month will be YYYY-MM-01, year will be YYYY-01-01, etc.) */
  date: Maybe<Scalars['Date']['output']>;
  merchant: Maybe<Merchant>;
};

/** An enumeration. */
export enum ReportsGroupByEntity {
  CATEGORY = 'category',
  CATEGORY_GROUP = 'category_group',
  MERCHANT = 'merchant',
}

/** An enumeration. */
export enum ReportsGroupByTimeframe {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

/** An enumeration. */
export enum ReportsSortBy {
  AVG = 'avg',
  AVG_EXPENSE = 'avg_expense',
  AVG_INCOME = 'avg_income',
  COUNT = 'count',
  FIRST = 'first',
  LAST = 'last',
  MAX = 'max',
  MAX_EXPENSE = 'max_expense',
  MAX_INCOME = 'max_income',
  MIN = 'min',
  SUM = 'sum',
  SUM_EXPENSE = 'sum_expense',
  SUM_INCOME = 'sum_income',
  SUM_TRANSFER = 'sum_transfer',
}

/**
 * Employee only endpoint to send a monthly email report to yourself.
 * Only for internal testing.
 */
export type RequestEmailReport = {
  __typename: 'RequestEmailReport';
  success: Scalars['Boolean']['output'];
};

export type RequestInstitution = {
  __typename: 'RequestInstitution';
  success: Maybe<Scalars['Boolean']['output']>;
};

export type RequestInstitutionInput = {
  description: InputMaybe<Scalars['String']['input']>;
  institutionName: Scalars['String']['input'];
  website: Scalars['String']['input'];
};

export type ResendInviteToHouseholdMutation = {
  __typename: 'ResendInviteToHouseholdMutation';
  invite: Maybe<HouseholdInvite>;
};

export type ResendInviteToHouseholdMutationInput = {
  inviteId: Scalars['String']['input'];
};

/** Employee only API to delete all profile question answers and advice */
export type ResetAdvice = {
  __typename: 'ResetAdvice';
  success: Scalars['Boolean']['output'];
};

export type ResetBudgetMutation = {
  __typename: 'ResetBudgetMutation';
  errors: Maybe<PayloadError>;
};

export type ResetBudgetMutationFilters = {
  budgetVariability: InputMaybe<BudgetVariability>;
  categoryIds: InputMaybe<Array<Scalars['ID']['input']>>;
  categoryType: InputMaybe<CategoryType>;
};

export type ResetBudgetMutationInput = {
  filters: InputMaybe<ResetBudgetMutationFilters>;
  overwriteExisting: InputMaybe<Scalars['Boolean']['input']>;
  startDate: InputMaybe<Scalars['Date']['input']>;
};

export type ResetBudgetRolloverInput = {
  categoryGroupId: InputMaybe<Scalars['ID']['input']>;
  categoryId: InputMaybe<Scalars['ID']['input']>;
  startMonth: Scalars['Date']['input'];
  startingBalance: InputMaybe<Scalars['Float']['input']>;
};

export type ResetBudgetRolloverMutation = {
  __typename: 'ResetBudgetRolloverMutation';
  budgetRolloverPeriod: BudgetRolloverPeriod;
  errors: Maybe<PayloadError>;
};

export type ResetHouseholdMerchantRecurringSearchMutation = {
  __typename: 'ResetHouseholdMerchantRecurringSearchMutation';
  success: Scalars['Boolean']['output'];
};

/**
 * Mainly used to represent the recommended resolution blocks that are returned
 * for a chosen reason in different groups so the UI knows how to render them.
 */
export type ResolutionBlocksData = {
  __typename: 'ResolutionBlocksData';
  buttons: Array<ResolutionButtonBlock>;
  /** Shortcut to check if any of the blocks have results */
  hasResults: Scalars['Boolean']['output'];
  links: Array<ResolutionLinkBlock>;
  messages: Array<ResolutionMessageBlock>;
};

export type ResolutionButtonBlock = {
  __typename: 'ResolutionButtonBlock';
  linkTo: Scalars['String']['output'];
  text: Scalars['String']['output'];
  /** @deprecated Use `link_to` instead. */
  url: Maybe<Scalars['String']['output']>;
};

export type ResolutionLinkBlock = {
  __typename: 'ResolutionLinkBlock';
  linkTo: Scalars['String']['output'];
  text: Scalars['String']['output'];
  /** @deprecated Use `link_to` instead. */
  url: Maybe<Scalars['String']['output']>;
};

export type ResolutionMessageBlock = {
  __typename: 'ResolutionMessageBlock';
  appearance: MessageBlockAppearance;
  text: Scalars['String']['output'];
  title: Maybe<Scalars['String']['output']>;
};

/**
 * Delete (or disable) a category, and move all rules / transactions to
 * move_to_category_id. If move_to_category_id is not specified, use UNCATEGORIZED.
 */
export type RestoreCategoryMutation = {
  __typename: 'RestoreCategoryMutation';
  category: Maybe<Category>;
  errors: Maybe<PayloadError>;
};

/**
 * Delete (or disable) a category, and move all rules / transactions to
 * move_to_category_id. If move_to_category_id is not specified, use UNCATEGORIZED.
 */
export type RestoreCategoryMutationCategoryArgs = {
  id: InputMaybe<Scalars['UUID']['input']>;
};

/** Base review type that consists of multiple pages. */
export type Review = {
  __typename: 'Review';
  pages: Array<ReviewPage>;
};

export type ReviewCheckin = {
  __typename: 'ReviewCheckin';
  completedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lastPageIndexViewed: Scalars['Int']['output'];
  reviewStartDate: Scalars['Date']['output'];
};

export type ReviewCheckinMonthDetails = {
  __typename: 'ReviewCheckinMonthDetails';
  completedCheckins: Array<ReviewCheckin>;
  inProgressCheckin: Maybe<ReviewCheckin>;
  reviewStartDate: Scalars['Date']['output'];
  reviewType: Maybe<Scalars['String']['output']>;
};

export type ReviewMerchant = {
  __typename: 'ReviewMerchant';
  id: Scalars['ID']['output'];
  logoUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  transactionCount: Scalars['Int']['output'];
};

/** Single page of a review. Each page contains a list of blocks that represent the UI components. */
export type ReviewPage = {
  __typename: 'ReviewPage';
  analyticsName: Scalars['String']['output'];
  background: Maybe<ReviewPageBackground>;
  blocks: Array<AllBlockTypes>;
  header: Maybe<AllBlockTypes>;
  hideFooter: Maybe<Scalars['Boolean']['output']>;
};

/** Specify whether a page has a solid color background or a gradient. */
export type ReviewPageBackground = {
  __typename: 'ReviewPageBackground';
  color: Maybe<Scalars['String']['output']>;
  gradient: Maybe<Gradient>;
};

export type ReviewRecurringStreamInput = {
  accountId: InputMaybe<Scalars['ID']['input']>;
  amount: InputMaybe<Scalars['Float']['input']>;
  baseDate: InputMaybe<Scalars['Date']['input']>;
  dayOfTheMonth: InputMaybe<Scalars['String']['input']>;
  frequency: InputMaybe<Scalars['String']['input']>;
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  reviewStatus: Scalars['String']['input'];
  streamId: Scalars['ID']['input'];
};

export type ReviewRecurringStreamMutation = {
  __typename: 'ReviewRecurringStreamMutation';
  errors: Maybe<PayloadError>;
  stream: Maybe<RecurringTransactionStream>;
};

export type RevokeHouseholdAccessGrant = {
  __typename: 'RevokeHouseholdAccessGrant';
  revoked: Scalars['Boolean']['output'];
};

export type RevokeHouseholdAccessGrantInput = {
  id: Scalars['ID']['input'];
};

export type RevokeInviteToHouseholdMutation = {
  __typename: 'RevokeInviteToHouseholdMutation';
  errors: Maybe<PayloadError>;
  invite: Maybe<HouseholdInvite>;
};

export type RevokeInviteToHouseholdMutationInput = {
  inviteId: Scalars['String']['input'];
};

export type RevokeSponsorshipInvite = {
  __typename: 'RevokeSponsorshipInvite';
  errors: Maybe<PayloadError>;
  revoked: Maybe<Scalars['Boolean']['output']>;
};

export type RevokeSponsorshipInviteInput = {
  /** Invite ID */
  id: Scalars['ID']['input'];
};

export type SearchResult = {
  __typename: 'SearchResult';
  icon: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  logoUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Security = {
  __typename: 'Security';
  closingPrice: Maybe<Scalars['Float']['output']>;
  closingPriceUpdatedAt: Maybe<Scalars['DateTime']['output']>;
  currentPrice: Maybe<Scalars['Float']['output']>;
  currentPriceUpdatedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  logo: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  oneDayChangeDollars: Maybe<Scalars['Float']['output']>;
  oneDayChangePercent: Maybe<Scalars['Float']['output']>;
  ticker: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  typeDisplay: Scalars['String']['output'];
};

export type SecurityHistoricalPerformanceInput = {
  /** Inclusive end date of the query. Defaults to today. */
  endDate: InputMaybe<Scalars['Date']['input']>;
  securityIds: Array<InputMaybe<Scalars['ID']['input']>>;
  /** Inclusive start date of the query. Defaults to 90 days ago. */
  startDate: InputMaybe<Scalars['Date']['input']>;
};

export type SecurityPerformance = {
  __typename: 'SecurityPerformance';
  historicalChart: Array<SecurityPerformanceChartDatum>;
  security: Security;
};

export type SecurityPerformanceChartDatum = {
  __typename: 'SecurityPerformanceChartDatum';
  date: Scalars['Date']['output'];
  returnPercent: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type SecurityPriceChange = {
  __typename: 'SecurityPriceChange';
  changeDollars: Maybe<Scalars['Float']['output']>;
  changePercent: Maybe<Scalars['Float']['output']>;
};

export type SecurityType = {
  __typename: 'SecurityType';
  type: Scalars['String']['output'];
  typeDisplay: Scalars['String']['output'];
};

export type SendMessage = {
  __typename: 'SendMessage';
  message: Maybe<Message>;
  messageThread: Maybe<MessageThread>;
};

export type SendMessageInput = {
  content: Scalars['String']['input'];
  fromRecommendedPrompt: InputMaybe<Scalars['Boolean']['input']>;
  threadId: Scalars['ID']['input'];
};

export type SetAccountLogoInput = {
  accountId: Scalars['ID']['input'];
  cloudinaryPublicId: InputMaybe<Scalars['String']['input']>;
};

export type SetAccountLogoMutation = {
  __typename: 'SetAccountLogoMutation';
  account: Maybe<Account>;
  errors: Maybe<PayloadError>;
};

export type SetMerchantLogoInput = {
  cloudinaryPublicId: InputMaybe<Scalars['String']['input']>;
  merchantId: Scalars['ID']['input'];
};

export type SetMerchantLogoMutation = {
  __typename: 'SetMerchantLogoMutation';
  errors: Maybe<PayloadError>;
  merchant: Maybe<Merchant>;
};

export type SetTransactionTags = {
  __typename: 'SetTransactionTags';
  errors: Maybe<PayloadError>;
  transaction: Maybe<Transaction>;
};

export type SetTransactionTagsInput = {
  tagIds: Array<Scalars['ID']['input']>;
  transactionId: Scalars['ID']['input'];
};

export type SetUserOnboardingFinancialGoalInput = {
  onboardingFinancialGoal: Scalars['UUID']['input'];
  otherText: InputMaybe<Scalars['String']['input']>;
};

export type SetUserOnboardingFinancialGoalMutation = {
  __typename: 'SetUserOnboardingFinancialGoalMutation';
  userOnboardingFinancialGoals: Array<UserOnboardingFinancialGoal>;
};

export type SetUserProfilePictureInput = {
  cloudinaryPublicId: InputMaybe<Scalars['String']['input']>;
  extension: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};

export type SetUserProfilePictureMutation = {
  __typename: 'SetUserProfilePictureMutation';
  errors: Maybe<PayloadError>;
  profilePicture: Maybe<UserProfilePicture>;
};

/** Block used to group other blocks together for sharing purposes. */
export type ShareMonarchBlock = Block & {
  __typename: 'ShareMonarchBlock';
  link: Maybe<BlockLink>;
};

/** Block used to group other blocks together for sharing purposes. */
export type ShareableBlock = Block & {
  __typename: 'ShareableBlock';
  children: Array<AllBlockTypes>;
  link: Maybe<BlockLink>;
  shareButtonText: Maybe<Scalars['String']['output']>;
  sharedContent: Maybe<Scalars['String']['output']>;
  sharedFooter: Maybe<Scalars['String']['output']>;
  sharedSubtitle: Maybe<Scalars['String']['output']>;
  sharedTitle: Maybe<Scalars['String']['output']>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type SimplePortfolioAliquot = {
  __typename: 'SimplePortfolioAliquot';
  allocationPercent: Scalars['Float']['output'];
  totalChangeDollars: Scalars['Float']['output'];
  totalChangePercent: Scalars['Float']['output'];
  totalValue: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  typeDisplay: Scalars['String']['output'];
};

export type SnapshotByType = {
  __typename: 'SnapshotByType';
  accountType: Scalars['String']['output'];
  balance: Scalars['Float']['output'];
  month: Scalars['String']['output'];
};

/** Block used to show a row with a large title and sparkline. */
export type SparklineRowBlock = Block & {
  __typename: 'SparklineRowBlock';
  hideValueChange: Maybe<Scalars['Boolean']['output']>;
  label: Maybe<Scalars['String']['output']>;
  labelColor: Maybe<Scalars['String']['output']>;
  largeStyle: Maybe<Scalars['Boolean']['output']>;
  lineColor: Maybe<Scalars['String']['output']>;
  link: Maybe<BlockLink>;
  rightLabel: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  values: Array<Scalars['Float']['output']>;
};

export type SpinwheelAccountMappingInput = {
  accountMapping: Array<InputMaybe<AccountMapping>>;
};

export type SpinwheelUser = {
  __typename: 'SpinwheelUser';
  household: Household;
  onboardingErrorMessage: Maybe<Scalars['String']['output']>;
  onboardingStatus: OnboardingStatus;
  spinwheelUserId: Maybe<Scalars['UUID']['output']>;
  user: User;
};

/** An enumeration. */
export enum SplitAmountType {
  ABSOLUTE = 'ABSOLUTE',
  PERCENTAGE = 'PERCENTAGE',
}

export type SplitTransactionsAction = {
  __typename: 'SplitTransactionsAction';
  amountType: SplitAmountType;
  splitsInfo: Array<SplitTransactionsActionInfo>;
};

export type SplitTransactionsActionInfo = {
  __typename: 'SplitTransactionsActionInfo';
  amount: Scalars['Float']['output'];
  categoryId: Scalars['ID']['output'];
  goalId: Maybe<Scalars['String']['output']>;
  hideFromReports: Maybe<Scalars['Boolean']['output']>;
  merchantName: Scalars['String']['output'];
  needsReview: Maybe<Scalars['Boolean']['output']>;
  needsReviewByUserId: Maybe<Scalars['ID']['output']>;
  reviewStatus: Maybe<Scalars['String']['output']>;
  tags: Maybe<Array<Scalars['String']['output']>>;
};

export type SplitTransactionsActionInfoInput = {
  amount: Scalars['Float']['input'];
  categoryId: Scalars['ID']['input'];
  goalId: InputMaybe<Scalars['String']['input']>;
  hideFromReports: InputMaybe<Scalars['Boolean']['input']>;
  merchantName: Scalars['String']['input'];
  needsReview: InputMaybe<Scalars['Boolean']['input']>;
  needsReviewByUserId: InputMaybe<Scalars['ID']['input']>;
  reviewStatus: InputMaybe<Scalars['String']['input']>;
  tags: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SplitTransactionsActionInput = {
  amountType: InputMaybe<Scalars['String']['input']>;
  splitsInfo: Array<SplitTransactionsActionInfoInput>;
};

export type SponsoredHouseholdInfo = {
  __typename: 'SponsoredHouseholdInfo';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lastActivityAt: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  owner: Maybe<User>;
  profilePictureUrl: Maybe<Scalars['String']['output']>;
};

/** Block used to show a bar chart with multiple groups and a total line */
export type StackedBarChartBlock = Block & {
  __typename: 'StackedBarChartBlock';
  data: Array<StackedBarChartDataPoint>;
  groupColors: Array<Scalars['String']['output']>;
  link: Maybe<BlockLink>;
};

/** Represents one data point of a stacked bar chart */
export type StackedBarChartDataPoint = {
  __typename: 'StackedBarChartDataPoint';
  groups: Array<Scalars['Float']['output']>;
  links: Maybe<Array<Maybe<BlockLink>>>;
  total: Maybe<Scalars['Float']['output']>;
  x: Scalars['String']['output'];
};

export type StartDownloadTransactionsSession = {
  __typename: 'StartDownloadTransactionsSession';
  sessionKey: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type StaticToken = {
  __typename: 'StaticToken';
  id: Scalars['ID']['output'];
  token: Scalars['String']['output'];
};

export type StripeCancellationDiscountOffer = {
  __typename: 'StripeCancellationDiscountOffer';
  discountPercent: Scalars['Float']['output'];
  discountedPricePerPeriodDollars: Scalars['Float']['output'];
  duration: StripeCouponDuration;
  /** If duration is repeating, the number of months the coupon applies. Null if coupon duration is forever or once */
  durationInMonths: Maybe<Scalars['Int']['output']>;
  originalPricePerPeriodDollars: Scalars['Float']['output'];
};

/** An enumeration. */
export enum StripeCouponDuration {
  FOREVER = 'forever',
  ONCE = 'once',
  REPEATING = 'repeating',
}

export type StripeInvoice = {
  __typename: 'StripeInvoice';
  amount: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  receiptUrl: Maybe<Scalars['String']['output']>;
};

export type StripeSubscriptionOffering = {
  __typename: 'StripeSubscriptionOffering';
  plans: Array<SubscriptionPlan>;
  promoCodeDescription: Maybe<Scalars['String']['output']>;
  promoCodeDuration: Maybe<StripeCouponDuration>;
  /** Used if promo_code_duration is 'repeating'. */
  promoCodeDurationInMonths: Maybe<Scalars['Int']['output']>;
  promoCodeError: Maybe<Scalars['String']['output']>;
};

export type SubmitAssistantFeedback = {
  __typename: 'SubmitAssistantFeedback';
  success: Scalars['Boolean']['output'];
};

export type SubmitAssistantFeedbackInput = {
  comments: InputMaybe<Scalars['String']['input']>;
  details: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  feedbackType: Scalars['String']['input'];
  messageId: Scalars['ID']['input'];
};

export type Subscription = {
  __typename: 'Subscription';
  onMessageCreated: Maybe<MessageCreatedSubscription>;
  onMessageThreadUpdated: Maybe<MessageThreadUpdatedSubscription>;
  onMessageUpdated: Maybe<MessageUpdatedSubscription>;
};

export type SubscriptionCancellationReason = {
  __typename: 'SubscriptionCancellationReason';
  feedback: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reason: Scalars['String']['output'];
};

export enum SubscriptionDetailsPaymentSource {
  APP_STORE = 'APP_STORE',
  PLAY_STORE = 'PLAY_STORE',
  PROMOTIONAL = 'PROMOTIONAL',
  STRIPE = 'STRIPE',
}

/** An enumeration. */
export enum SubscriptionEntitlement {
  LITE = 'lite',
  PREMIUM = 'premium',
}

export type SubscriptionPlan = {
  __typename: 'SubscriptionPlan';
  discountedPricePerPeriodDollars: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  newTrialEndsAt: Maybe<Scalars['Date']['output']>;
  period: PaymentPeriod;
  pricePerPeriodDollars: Scalars['Float']['output'];
  requirePaymentMethod: Scalars['Boolean']['output'];
  sponsoredBy: Maybe<SubscriptionSponsorInfo>;
  stripeId: Scalars['String']['output'];
};

export type SubscriptionSponsor = {
  __typename: 'SubscriptionSponsor';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SubscriptionSponsorInfo = {
  __typename: 'SubscriptionSponsorInfo';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  profilePictureUrl: Maybe<Scalars['String']['output']>;
};

export type SubscriptionSponsorship = {
  __typename: 'SubscriptionSponsorship';
  createdAt: Scalars['DateTime']['output'];
  household: Maybe<SponsoredHouseholdInfo>;
  id: Scalars['ID']['output'];
  status: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SubscriptionSponsorshipDemoAccount = {
  __typename: 'SubscriptionSponsorshipDemoAccount';
  userId: Scalars['ID']['output'];
};

export type SubscriptionSponsorshipInfo = {
  __typename: 'SubscriptionSponsorshipInfo';
  id: Scalars['String']['output'];
  sponsor: Maybe<SubscriptionSponsorInfo>;
};

export type SubscriptionSponsorshipInvite = {
  __typename: 'SubscriptionSponsorshipInvite';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  label: Maybe<Scalars['String']['output']>;
  sentToEmail: Maybe<Scalars['String']['output']>;
  sponsorship: Maybe<SubscriptionSponsorship>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SubscriptionSponsorshipInviteInput = {
  email: Scalars['String']['input'];
  name: InputMaybe<Scalars['String']['input']>;
};

/** Block used to show the success icon and, optionally, some confetti. */
export type SuccessBlock = Block & {
  __typename: 'SuccessBlock';
  link: Maybe<BlockLink>;
  showConfetti: Maybe<Scalars['Boolean']['output']>;
};

export type SupportAccountAccessGrant = {
  __typename: 'SupportAccountAccessGrant';
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type SyncAppleFinanceKitAccountInfo = {
  __typename: 'SyncAppleFinanceKitAccountInfo';
  errors: Maybe<PayloadError>;
};

export type SyncAppleFinanceKitAccountInfoInput = {
  accountBalances: Array<AppleAccountBalanceInfo>;
  accounts: Array<AppleAccountInfo>;
  initiator: Scalars['String']['input'];
  since: InputMaybe<Scalars['String']['input']>;
  transactions: Array<AppleAccountTransactionInfo>;
};

export type TotpDevice = {
  __typename: 'TOTPDevice';
  configUrl: Maybe<Scalars['String']['output']>;
  confirmed: Scalars['Boolean']['output'];
  secret: Scalars['String']['output'];
};

/** Block used to show a simple text label. */
export type TextBlock = Block & {
  __typename: 'TextBlock';
  color: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  fontSize: Maybe<Scalars['Int']['output']>;
  fontWeight: Maybe<Scalars['String']['output']>;
  link: Maybe<BlockLink>;
};

/** An enumeration. */
export enum Timeframe {
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export type ToggleSupportAccountAccessMutation = {
  __typename: 'ToggleSupportAccountAccessMutation';
  grant: Maybe<SupportAccountAccessGrant>;
};

export type TopInstitutionGroup = {
  __typename: 'TopInstitutionGroup';
  accountsConnectedCount: Scalars['Int']['output'];
  institutions: Array<Institution>;
  manualAccountShortcuts: Maybe<Array<ManualAccountShortcut>>;
  shortTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: TopInstitutionGroupType;
};

export enum TopInstitutionGroupType {
  ASSETS = 'assets',
  BANKS_CREDIT_CARDS = 'banks_credit_cards',
  INVESTMENTS_LOANS = 'investments_loans',
}

export type Transaction = {
  __typename: 'Transaction';
  account: Account;
  amount: Scalars['Float']['output'];
  attachments: Array<TransactionAttachment>;
  category: Category;
  createdAt: Scalars['DateTime']['output'];
  dataProviderDescription: Maybe<Scalars['String']['output']>;
  date: Scalars['Date']['output'];
  externalId: Maybe<Scalars['UUID']['output']>;
  goal: Maybe<GoalV2>;
  hasSplitTransactions: Scalars['Boolean']['output'];
  hideFromReports: Scalars['Boolean']['output'];
  /** @deprecated Replace with merchant */
  householdMerchant: HouseholdMerchant;
  id: Scalars['ID']['output'];
  isManual: Scalars['Boolean']['output'];
  isRecurring: Scalars['Boolean']['output'];
  isSplitTransaction: Scalars['Boolean']['output'];
  merchant: Merchant;
  needsReview: Scalars['Boolean']['output'];
  needsReviewByUser: Maybe<User>;
  notes: Maybe<Scalars['String']['output']>;
  originalDate: Scalars['Date']['output'];
  originalTransaction: Maybe<Transaction>;
  pending: Scalars['Boolean']['output'];
  plaidMerchantName: Maybe<Scalars['String']['output']>;
  /** @deprecated Replace with data_provider_description */
  plaidName: Maybe<Scalars['String']['output']>;
  plaidTransactionId: Maybe<Scalars['String']['output']>;
  reviewStatus: Maybe<Scalars['String']['output']>;
  reviewedAt: Maybe<Scalars['DateTime']['output']>;
  reviewedByUser: Maybe<User>;
  splitTransactions: Array<Transaction>;
  tags: Array<TransactionTag>;
  updatedAt: Scalars['DateTime']['output'];
};

export type TransactionAddAttachmentMutation = {
  __typename: 'TransactionAddAttachmentMutation';
  attachment: Maybe<TransactionAttachment>;
  errors: Maybe<PayloadError>;
};

export type TransactionAddAttachmentMutationInput = {
  extension: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  publicId: Scalars['String']['input'];
  sizeBytes: Scalars['Int']['input'];
  transactionId: Scalars['UUID']['input'];
};

export type TransactionAttachment = {
  __typename: 'TransactionAttachment';
  extension: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  originalAssetUrl: Scalars['String']['output'];
  publicId: Scalars['String']['output'];
  sizeBytes: Scalars['Int']['output'];
  /** @deprecated use client library to generate thumbnail url */
  thumbnailUrl: Scalars['String']['output'];
};

export type TransactionAttachmentUploadInfo = {
  __typename: 'TransactionAttachmentUploadInfo';
  path: Scalars['String']['output'];
  requestParams: TransactionAttachmentUploadRequestParams;
};

export type TransactionAttachmentUploadRequestParams = {
  __typename: 'TransactionAttachmentUploadRequestParams';
  api_key: Scalars['String']['output'];
  folder: Scalars['String']['output'];
  signature: Scalars['String']['output'];
  timestamp: Scalars['Int']['output'];
  upload_preset: Scalars['String']['output'];
};

export type TransactionExplanation = {
  __typename: 'TransactionExplanation';
  explanation: Scalars['String']['output'];
};

export type TransactionFilterInput = {
  absAmountGte: InputMaybe<Scalars['Float']['input']>;
  absAmountLte: InputMaybe<Scalars['Float']['input']>;
  accounts: InputMaybe<Array<Scalars['UUID']['input']>>;
  categories: InputMaybe<Array<Scalars['UUID']['input']>>;
  categoryGroups: InputMaybe<Array<Scalars['UUID']['input']>>;
  categoryType: InputMaybe<CategoryType>;
  creditsOnly: InputMaybe<Scalars['Boolean']['input']>;
  debitsOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** End date of the query, inclusive */
  endDate: InputMaybe<Scalars['Date']['input']>;
  excludeIds: InputMaybe<Array<Scalars['ID']['input']>>;
  goalId: InputMaybe<Scalars['ID']['input']>;
  hasAttachments: InputMaybe<Scalars['Boolean']['input']>;
  hasNotes: InputMaybe<Scalars['Boolean']['input']>;
  hideFromReports: InputMaybe<Scalars['Boolean']['input']>;
  householdMerchants: InputMaybe<Array<Scalars['ID']['input']>>;
  ids: InputMaybe<Array<Scalars['ID']['input']>>;
  importedFromMint: InputMaybe<Scalars['Boolean']['input']>;
  isFlexSpending: InputMaybe<Scalars['Boolean']['input']>;
  isInvestmentAccount: InputMaybe<Scalars['Boolean']['input']>;
  isPending: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring: InputMaybe<Scalars['Boolean']['input']>;
  isSplit: InputMaybe<Scalars['Boolean']['input']>;
  isUncategorized: InputMaybe<Scalars['Boolean']['input']>;
  merchants: InputMaybe<Array<Scalars['UUID']['input']>>;
  needsReview: InputMaybe<Scalars['Boolean']['input']>;
  needsReviewByUser: InputMaybe<Scalars['ID']['input']>;
  needsReviewUnassigned: InputMaybe<Scalars['Boolean']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  /** Start date of the query, inclusive */
  startDate: InputMaybe<Scalars['Date']['input']>;
  syncedFromInstitution: InputMaybe<Scalars['Boolean']['input']>;
  tags: InputMaybe<Array<Scalars['ID']['input']>>;
  uploadedStatement: InputMaybe<Scalars['ID']['input']>;
};

/** This one is for the "all_transactions" API */
export type TransactionList = {
  __typename: 'TransactionList';
  results: Array<Transaction>;
  totalCount: Scalars['Int']['output'];
  /** Count of transactions that can be selected for a bulk operation given filters and preferences */
  totalSelectableCount: Maybe<Scalars['Int']['output']>;
};

/** This one is for the "all_transactions" API */
export type TransactionListResultsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<TransactionOrdering>;
};

/** An enumeration. */
export enum TransactionOrdering {
  AMOUNT = 'amount',
  DATE = 'date',
  INVERSE_AMOUNT = 'inverse_amount',
  INVERSE_DATE = 'inverse_date',
}

export type TransactionPreview = {
  __typename: 'TransactionPreview';
  newCategory: Maybe<Category>;
  newGoal: Maybe<GoalV2>;
  newHideFromReports: Maybe<Scalars['Boolean']['output']>;
  newMerchantName: Maybe<Scalars['String']['output']>;
  newName: Maybe<Scalars['String']['output']>;
  newNeedsReview: Maybe<Scalars['Boolean']['output']>;
  newNeedsReviewByUser: Maybe<User>;
  newReviewStatus: Maybe<Scalars['String']['output']>;
  newReviewedAt: Maybe<Scalars['DateTime']['output']>;
  newSplitTransactions: Maybe<Scalars['Boolean']['output']>;
  newTags: Maybe<Array<TransactionTag>>;
  transaction: Transaction;
};

export type TransactionPreviewList = {
  __typename: 'TransactionPreviewList';
  results: Array<TransactionPreview>;
  totalCount: Scalars['Int']['output'];
};

export type TransactionPreviewListResultsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TransactionRulePreviewInput = {
  accountIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  addTagsAction: InputMaybe<Array<Scalars['ID']['input']>>;
  amountCriteria: InputMaybe<AmountCriteriaFieldV2Input>;
  applyToExistingTransactions: InputMaybe<Scalars['Boolean']['input']>;
  categoryIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  linkGoalAction: InputMaybe<Scalars['ID']['input']>;
  merchantCriteria: InputMaybe<Array<InputMaybe<MerchantCriterionInput>>>;
  merchantCriteriaUseOriginalStatement: InputMaybe<Scalars['Boolean']['input']>;
  needsReviewByUserAction: InputMaybe<Scalars['ID']['input']>;
  reviewStatusAction: InputMaybe<Scalars['String']['input']>;
  sendNotificationAction: InputMaybe<Scalars['Boolean']['input']>;
  setCategoryAction: InputMaybe<Scalars['ID']['input']>;
  setHideFromReportsAction: InputMaybe<Scalars['Boolean']['input']>;
  setMerchantAction: InputMaybe<Scalars['String']['input']>;
  splitTransactionsAction: InputMaybe<SplitTransactionsActionInput>;
};

export type TransactionRuleV2 = {
  __typename: 'TransactionRuleV2';
  accountIds: Maybe<Array<Scalars['ID']['output']>>;
  accounts: Maybe<Array<Account>>;
  addTagsAction: Maybe<Array<TransactionTag>>;
  amountCriteria: Maybe<AmountCriteriaV2>;
  categories: Maybe<Array<Category>>;
  categoryIds: Maybe<Array<Scalars['ID']['output']>>;
  id: Scalars['ID']['output'];
  lastAppliedAt: Maybe<Scalars['DateTime']['output']>;
  linkGoalAction: Maybe<GoalV2>;
  markNeedsReviewAction: Scalars['Boolean']['output'];
  markReviewedAction: Scalars['Boolean']['output'];
  merchantCriteria: Maybe<Array<MerchantCriterion>>;
  merchantCriteriaUseOriginalStatement: Maybe<Scalars['Boolean']['output']>;
  needsReviewByUserAction: Maybe<User>;
  order: Scalars['Int']['output'];
  recentApplicationCount: Scalars['Int']['output'];
  reviewStatusAction: Maybe<Scalars['String']['output']>;
  sendNotificationAction: Scalars['Boolean']['output'];
  setCategoryAction: Maybe<Category>;
  setHideFromReportsAction: Scalars['Boolean']['output'];
  setMerchantAction: Maybe<Merchant>;
  splitTransactionsAction: Maybe<SplitTransactionsAction>;
  unassignNeedsReviewByUserAction: Scalars['Boolean']['output'];
};

export type TransactionRuleV2RecentApplicationCountArgs = {
  daysAgo: InputMaybe<Scalars['Int']['input']>;
};

export type TransactionSplitInputData = {
  amount: InputMaybe<Scalars['Float']['input']>;
  categoryId: InputMaybe<Scalars['UUID']['input']>;
  date: InputMaybe<Scalars['Date']['input']>;
  goalId: InputMaybe<Scalars['ID']['input']>;
  hideFromReports: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['UUID']['input']>;
  merchantName: InputMaybe<Scalars['String']['input']>;
  needsReview: InputMaybe<Scalars['Boolean']['input']>;
  needsReviewByUserId: InputMaybe<Scalars['ID']['input']>;
  notes: InputMaybe<Scalars['String']['input']>;
  reviewStatus: InputMaybe<Scalars['String']['input']>;
  tags: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type TransactionTag = {
  __typename: 'TransactionTag';
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  transactionCount: Scalars['Int']['output'];
};

export type TransactionUpdateParams = {
  categoryId: InputMaybe<Scalars['ID']['input']>;
  date: InputMaybe<Scalars['Date']['input']>;
  goalId: InputMaybe<Scalars['ID']['input']>;
  hide: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring: InputMaybe<Scalars['Boolean']['input']>;
  merchantName: InputMaybe<Scalars['ID']['input']>;
  needsReviewByUserId: InputMaybe<Scalars['ID']['input']>;
  notes: InputMaybe<Scalars['String']['input']>;
  reviewStatus: InputMaybe<Scalars['String']['input']>;
  tags: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Show a filtered list of transactions. */
export type TransactionsListBlock = Block & {
  __typename: 'TransactionsListBlock';
  categories: Maybe<Array<Scalars['UUID']['output']>>;
  endDate: Maybe<Scalars['Date']['output']>;
  link: Maybe<BlockLink>;
  startDate: Maybe<Scalars['Date']['output']>;
};

export type TransactionsSummary = {
  __typename: 'TransactionsSummary';
  /** Average of all transactions, including transfers. */
  avg: Maybe<Scalars['Float']['output']>;
  /** Average of all expense transactions */
  avgExpense: Maybe<Scalars['Float']['output']>;
  /** Average of all income transactions */
  avgIncome: Maybe<Scalars['Float']['output']>;
  /** Count of all transactions, including transfers. */
  count: Scalars['Int']['output'];
  first: Maybe<Scalars['Date']['output']>;
  largest: Scalars['Float']['output'];
  last: Maybe<Scalars['Date']['output']>;
  /** @deprecated Use largest instead */
  max: Scalars['Float']['output'];
  maxExpense: Scalars['Float']['output'];
  maxIncome: Scalars['Float']['output'];
  savings: Scalars['Float']['output'];
  savingsRate: Scalars['Float']['output'];
  /** Sum of all transactions, including transfers. */
  sum: Scalars['Float']['output'];
  /** Sum of all expense transactions, excluding transfers. Expenses are negative by convention. */
  sumExpense: Scalars['Float']['output'];
  /** Sum of all income transactions, excluding transfers. Income is positive by convention. */
  sumIncome: Scalars['Float']['output'];
  /** Sum of all transfer transactions, excluding transfers. */
  sumTransfer: Scalars['Float']['output'];
};

/** An enumeration. */
export enum TrialType {
  FREE_TRIAL = 'free_trial',
  GUEST_PASS = 'guest_pass',
  PREMIUM = 'premium',
  REFERRALS = 'referrals',
}

export type TriggerRecurringMerchantSearchMutation = {
  __typename: 'TriggerRecurringMerchantSearchMutation';
  success: Scalars['Boolean']['output'];
};

export type UnarchiveGoal = {
  __typename: 'UnarchiveGoal';
  errors: Maybe<PayloadError>;
  goal: Maybe<GoalV2>;
};

export type UnarchiveGoalInput = {
  id: Scalars['ID']['input'];
};

export type UndeleteAccountMutation = {
  __typename: 'UndeleteAccountMutation';
  errors: Maybe<PayloadError>;
  undeleted: Maybe<Scalars['Boolean']['output']>;
};

export type UndeleteAccountMutationInput = {
  id: Scalars['UUID']['input'];
};

export type UpcomingStripeInvoice = {
  __typename: 'UpcomingStripeInvoice';
  amount: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
};

export type UpdateAccountGroupOrderInput = {
  accountGroupOrder: Array<InputMaybe<Scalars['String']['input']>>;
};

export type UpdateAccountGroupOrderMutation = {
  __typename: 'UpdateAccountGroupOrderMutation';
  errors: Maybe<PayloadError>;
  household: Household;
};

export type UpdateAccountMutation = {
  __typename: 'UpdateAccountMutation';
  account: Maybe<Account>;
  errors: Maybe<PayloadError>;
};

export type UpdateAccountMutationInput = {
  archivedAt: InputMaybe<Scalars['Date']['input']>;
  currentBalance: InputMaybe<Scalars['Float']['input']>;
  dataProvider: InputMaybe<Scalars['String']['input']>;
  dataProviderAccountId: InputMaybe<Scalars['String']['input']>;
  dataProviderId: InputMaybe<Scalars['String']['input']>;
  deactivatedAt: InputMaybe<Scalars['Date']['input']>;
  displayBalance: InputMaybe<Scalars['Float']['input']>;
  hideFromList: InputMaybe<Scalars['Boolean']['input']>;
  hideTransactionsFromReports: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  includeInGoalBalance: InputMaybe<Scalars['Boolean']['input']>;
  includeInNetWorth: InputMaybe<Scalars['Boolean']['input']>;
  invertSyncedBalance: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  recurrence: InputMaybe<AccountRecurringFieldsInput>;
  subtype: InputMaybe<Scalars['String']['input']>;
  syncDisabled: InputMaybe<Scalars['Boolean']['input']>;
  synced: InputMaybe<Scalars['Boolean']['input']>;
  type: InputMaybe<Scalars['String']['input']>;
  useAvailableBalance: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateAccountOrderInput = {
  destinationPosition: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateAccountOrderMutation = {
  __typename: 'UpdateAccountOrderMutation';
  account: Maybe<Account>;
};

export type UpdateAccountsMutation = {
  __typename: 'UpdateAccountsMutation';
  accounts: Maybe<Array<Maybe<Account>>>;
  errors: Maybe<PayloadError>;
};

export type UpdateAccountsMutationInput = {
  archivedAt: InputMaybe<Scalars['Date']['input']>;
  currentBalance: InputMaybe<Scalars['Float']['input']>;
  dataProvider: InputMaybe<Scalars['String']['input']>;
  dataProviderAccountId: InputMaybe<Scalars['String']['input']>;
  dataProviderId: InputMaybe<Scalars['String']['input']>;
  deactivatedAt: InputMaybe<Scalars['Date']['input']>;
  displayBalance: InputMaybe<Scalars['Float']['input']>;
  hideFromList: InputMaybe<Scalars['Boolean']['input']>;
  hideTransactionsFromReports: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  includeInGoalBalance: InputMaybe<Scalars['Boolean']['input']>;
  includeInNetWorth: InputMaybe<Scalars['Boolean']['input']>;
  invertSyncedBalance: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  recurrence: InputMaybe<AccountRecurringFieldsInput>;
  subtype: InputMaybe<Scalars['String']['input']>;
  syncDisabled: InputMaybe<Scalars['Boolean']['input']>;
  synced: InputMaybe<Scalars['Boolean']['input']>;
  type: InputMaybe<Scalars['String']['input']>;
  useAvailableBalance: InputMaybe<Scalars['Boolean']['input']>;
};

/** Endpoint to update the bill amount of a credit report liability statement. */
export type UpdateBillAmountMutation = {
  __typename: 'UpdateBillAmountMutation';
  creditReportLiabilityStatement: Maybe<LiabilityStatement>;
  errors: Maybe<PayloadError>;
};

/** Endpoint to mark a credit report liability statement paid or unpaid. */
export type UpdateBillPaidMutation = {
  __typename: 'UpdateBillPaidMutation';
  creditReportLiabilityStatement: Maybe<LiabilityStatement>;
  errors: Maybe<PayloadError>;
};

export type UpdateBudgetSettingsMutation = {
  __typename: 'UpdateBudgetSettingsMutation';
  budgetRolloverPeriod: Maybe<BudgetRolloverPeriod>;
  budgetSystem: Maybe<Scalars['String']['output']>;
  /** The list of system categories that had their budget variabilities updated, in case the budget system changed to Flexible. */
  updatedSystemCategories: Maybe<Array<Maybe<Category>>>;
};

export type UpdateBudgetSettingsMutationInput = {
  budgetSystem: InputMaybe<Scalars['String']['input']>;
  rolloverEnabled: InputMaybe<Scalars['Boolean']['input']>;
  rolloverStartMonth: InputMaybe<Scalars['Date']['input']>;
  rolloverStartingBalance: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCategoryGroupInput = {
  budgetVariability: InputMaybe<Scalars['String']['input']>;
  color: InputMaybe<Scalars['String']['input']>;
  groupLevelBudgetingEnabled: InputMaybe<Scalars['Boolean']['input']>;
  icon: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  rolloverEnabled: InputMaybe<Scalars['Boolean']['input']>;
  rolloverStartMonth: InputMaybe<Scalars['Date']['input']>;
  rolloverStartingBalance: InputMaybe<Scalars['Float']['input']>;
  rolloverType: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCategoryGroupMutation = {
  __typename: 'UpdateCategoryGroupMutation';
  categoryGroup: Maybe<CategoryGroup>;
};

export type UpdateCategoryGroupOrderMutation = {
  __typename: 'UpdateCategoryGroupOrderMutation';
  categoryGroups: Array<CategoryGroup>;
};

export type UpdateCategoryInput = {
  applyRolloverBudgetToFutureMonths: InputMaybe<Scalars['Boolean']['input']>;
  budgetVariability: InputMaybe<Scalars['String']['input']>;
  excludeFromBudget: InputMaybe<Scalars['Boolean']['input']>;
  group: InputMaybe<Scalars['ID']['input']>;
  icon: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  rolloverEnabled: InputMaybe<Scalars['Boolean']['input']>;
  rolloverFrequency: InputMaybe<Scalars['String']['input']>;
  rolloverFutureBudgetAllocation: InputMaybe<Scalars['Float']['input']>;
  rolloverStartMonth: InputMaybe<Scalars['Date']['input']>;
  rolloverStartingBalance: InputMaybe<Scalars['Float']['input']>;
  rolloverTargetAmount: InputMaybe<Scalars['Float']['input']>;
  rolloverType: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCategoryOrderInCategoryGroupMutation = {
  __typename: 'UpdateCategoryOrderInCategoryGroupMutation';
  category: Category;
};

export type UpdateCategoryPayload = {
  __typename: 'UpdateCategoryPayload';
  category: Maybe<Category>;
  errors: Maybe<PayloadError>;
};

export type UpdateCredentialMutation = {
  __typename: 'UpdateCredentialMutation';
  credential: Maybe<Credential>;
  errors: Maybe<PayloadError>;
};

export type UpdateCredentialMutationInput = {
  id: InputMaybe<Scalars['String']['input']>;
  updateRequired: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateDashboardConfigMutation = {
  __typename: 'UpdateDashboardConfigMutation';
  errors: Maybe<PayloadError>;
  household: Household;
};

export type UpdateGoal = {
  __typename: 'UpdateGoal';
  errors: Maybe<PayloadError>;
  goal: Maybe<GoalV2>;
};

export type UpdateGoalAccountAllocation = {
  __typename: 'UpdateGoalAccountAllocation';
  errors: Maybe<PayloadError>;
  goal: Maybe<GoalV2>;
  goalAccountAllocation: Maybe<GoalAccountAllocation>;
};

export type UpdateGoalAccountAllocationInput = {
  accountId: Scalars['ID']['input'];
  amount: InputMaybe<Scalars['Float']['input']>;
  goalId: Scalars['ID']['input'];
  useEntireAccountBalance: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateGoalContributionMutation = {
  __typename: 'UpdateGoalContributionMutation';
  errors: Maybe<PayloadError>;
  goalContribution: Maybe<GoalContribution>;
};

export type UpdateGoalContributionMutationInput = {
  amount: InputMaybe<Scalars['Float']['input']>;
  date: InputMaybe<Scalars['Date']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
  notes: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGoalInput = {
  id: Scalars['ID']['input'];
  imageStorageProvider: InputMaybe<Scalars['String']['input']>;
  imageStorageProviderId: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  plannedMonthlyContribution: InputMaybe<Scalars['Float']['input']>;
  plannedMonthlyPretaxContribution: InputMaybe<Scalars['Float']['input']>;
  startingAmount: InputMaybe<Scalars['Float']['input']>;
  targetAmount: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateGoalMutation = {
  __typename: 'UpdateGoalMutation';
  errors: Maybe<PayloadError>;
  goal: Maybe<Goal>;
};

export type UpdateGoalMutationInput = {
  balancesFromAdjustmentDate: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  goalType: InputMaybe<Scalars['String']['input']>;
  icon: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  plannedMonthlyContribution: InputMaybe<Scalars['Float']['input']>;
  plannedStartingBalance: InputMaybe<Scalars['Float']['input']>;
  startingBalance: InputMaybe<Scalars['Float']['input']>;
  targetAmount: InputMaybe<Scalars['Float']['input']>;
  targetDate: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateGoalPriorities = {
  __typename: 'UpdateGoalPriorities';
  errors: Maybe<PayloadError>;
  goals: Maybe<Array<Maybe<GoalV2>>>;
};

export type UpdateGoalPrioritiesInput = {
  goals: Array<UpdateGoalPriorityInput>;
};

export type UpdateGoalPriorityInput = {
  id: Scalars['ID']['input'];
  priority: Scalars['Int']['input'];
};

/** Bulk version of UpdateGoal */
export type UpdateGoals = {
  __typename: 'UpdateGoals';
  errors: Maybe<PayloadError>;
  goals: Maybe<Array<Maybe<GoalV2>>>;
};

export type UpdateGoalsInput = {
  goals: Array<UpdateGoalInput>;
};

export type UpdateHolding = {
  __typename: 'UpdateHolding';
  errors: Maybe<PayloadError>;
  holding: Maybe<Holding>;
};

export type UpdateHoldingInput = {
  /** Updated holding cost basis */
  costBasis: InputMaybe<Scalars['Float']['input']>;
  /** ID of the holding to update */
  id: Scalars['ID']['input'];
  /** Updated holding quantity */
  quantity: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateHouseholdPreferences = {
  __typename: 'UpdateHouseholdPreferences';
  householdPreferences: Maybe<HouseholdPreferences>;
};

export type UpdateHouseholdPreferencesInput = {
  aiAssistantEnabled: InputMaybe<Scalars['Boolean']['input']>;
  investmentTransactionsEnabled: InputMaybe<Scalars['Boolean']['input']>;
  llmEnrichmentEnabled: InputMaybe<Scalars['Boolean']['input']>;
  newTransactionsNeedReview: InputMaybe<Scalars['Boolean']['input']>;
  pendingTransactionsCanBeEdited: InputMaybe<Scalars['Boolean']['input']>;
  uncategorizedTransactionsNeedReview: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateMe = {
  __typename: 'UpdateMe';
  errors: Maybe<PayloadError>;
  user: Maybe<User>;
};

export type UpdateMeInput = {
  birthday: InputMaybe<Scalars['Date']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMerchantInput = {
  merchantId: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  recurrence: InputMaybe<UpdateRecurrenceFieldsInput>;
};

export type UpdateMerchantMutation = {
  __typename: 'UpdateMerchantMutation';
  errors: Maybe<PayloadError>;
  merchant: Maybe<Merchant>;
};

export type UpdateMyHousehold = {
  __typename: 'UpdateMyHousehold';
  errors: Maybe<PayloadError>;
  household: Maybe<Household>;
};

export type UpdateMyHouseholdInput = {
  address: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  state: InputMaybe<Scalars['String']['input']>;
  zipCode: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNotificationPreferences = {
  __typename: 'UpdateNotificationPreferences';
  errors: Maybe<PayloadError>;
  notificationPreference: Maybe<NotificationPreference>;
};

export type UpdateNotificationPreferencesInput = {
  EMAIL: InputMaybe<Scalars['Boolean']['input']>;
  PUSH: InputMaybe<Scalars['Boolean']['input']>;
  additionalPreferences: InputMaybe<Scalars['GenericScalar']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrCreateBudgetItemMutation = {
  __typename: 'UpdateOrCreateBudgetItemMutation';
  budgetItem: Maybe<BudgetItem>;
  errors: Maybe<PayloadError>;
};

export type UpdateOrCreateBudgetItemMutationInput = {
  amount: InputMaybe<Scalars['Float']['input']>;
  applyToFuture: InputMaybe<Scalars['Boolean']['input']>;
  categoryGroupId: InputMaybe<Scalars['String']['input']>;
  categoryId: InputMaybe<Scalars['String']['input']>;
  defaultAmount: InputMaybe<Scalars['Float']['input']>;
  startDate: Scalars['Date']['input'];
  timeframe: BudgetTimeframeInput;
};

export type UpdateOrCreateFlexBudgetItemMutation = {
  __typename: 'UpdateOrCreateFlexBudgetItemMutation';
  budgetItem: Maybe<BudgetItem>;
  errors: Maybe<PayloadError>;
};

export type UpdateOrCreateFlexBudgetItemMutationInput = {
  amount: InputMaybe<Scalars['Float']['input']>;
  applyToFuture: InputMaybe<Scalars['Boolean']['input']>;
  defaultAmount: InputMaybe<Scalars['Float']['input']>;
  startDate: Scalars['Date']['input'];
};

export type UpdateOrCreateGoalPlannedContributionMutation = {
  __typename: 'UpdateOrCreateGoalPlannedContributionMutation';
  goalPlannedContribution: Maybe<GoalPlannedContribution>;
};

export type UpdateOrCreateGoalPlannedContributionMutationInput = {
  amount: Scalars['Float']['input'];
  goalId: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
};

export type UpdateOrCreateGoalPlannedContributionsMutation = {
  __typename: 'UpdateOrCreateGoalPlannedContributionsMutation';
  goalPlannedContributions: Maybe<Array<GoalPlannedContribution>>;
};

export type UpdateOrCreateStripePaymentMethodMutation = {
  __typename: 'UpdateOrCreateStripePaymentMethodMutation';
  errors: Maybe<PayloadError>;
  subscription: Maybe<HouseholdSubscription>;
};

export type UpdateOrCreateStripePaymentMethodMutationInput = {
  paymentMethodId: Scalars['String']['input'];
};

export type UpdateRecurrenceFieldsInput = {
  amount: InputMaybe<Scalars['Float']['input']>;
  baseDate: InputMaybe<Scalars['Date']['input']>;
  dayOfTheMonth: InputMaybe<Scalars['String']['input']>;
  frequency: InputMaybe<Scalars['String']['input']>;
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring: Scalars['Boolean']['input'];
};

export type UpdateRenewalReminder = {
  __typename: 'UpdateRenewalReminder';
  renewalReminderDays: Maybe<Scalars['Int']['output']>;
};

export type UpdateRenewalReminderInput = {
  renewalReminderDays: Scalars['Int']['input'];
};

export type UpdateReviewCheckinInput = {
  completed: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  lastPageIndexViewed: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateReviewCheckinMutation = {
  __typename: 'UpdateReviewCheckinMutation';
  checkin: Maybe<ReviewCheckin>;
  errors: Maybe<PayloadError>;
};

export type UpdateSponsor = {
  __typename: 'UpdateSponsor';
  errors: Maybe<PayloadError>;
  sponsor: Maybe<SubscriptionSponsor>;
};

export type UpdateSponsorInput = {
  /** Subscription sponsor name */
  name: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSponsorshipInvite = {
  __typename: 'UpdateSponsorshipInvite';
  errors: Maybe<PayloadError>;
  invite: Maybe<SubscriptionSponsorshipInvite>;
};

export type UpdateSponsorshipInviteInput = {
  /** Invite ID */
  id: Scalars['ID']['input'];
  /** Invite label */
  label: InputMaybe<Scalars['String']['input']>;
  /** Email to which the invite was sent */
  sentToEmail: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTransactionMutation = {
  __typename: 'UpdateTransactionMutation';
  errors: Maybe<PayloadError>;
  transaction: Maybe<Transaction>;
};

export type UpdateTransactionMutationInput = {
  amount: InputMaybe<Scalars['Float']['input']>;
  category: InputMaybe<Scalars['String']['input']>;
  date: InputMaybe<Scalars['Date']['input']>;
  goalId: InputMaybe<Scalars['ID']['input']>;
  hideFromReports: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
  isRecommendedCategory: InputMaybe<Scalars['Boolean']['input']>;
  isRecommendedName: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  needsReview: InputMaybe<Scalars['Boolean']['input']>;
  needsReviewByUser: InputMaybe<Scalars['ID']['input']>;
  notes: InputMaybe<Scalars['String']['input']>;
  reviewStatus: InputMaybe<Scalars['String']['input']>;
  reviewed: InputMaybe<Scalars['Boolean']['input']>;
  shouldCreateEnrichmentEvidence: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateTransactionRuleInput = {
  accountIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  addTagsAction: InputMaybe<Array<Scalars['ID']['input']>>;
  amountCriteria: InputMaybe<AmountCriteriaFieldV2Input>;
  applyToExistingTransactions: InputMaybe<Scalars['Boolean']['input']>;
  categoryIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id: Scalars['ID']['input'];
  linkGoalAction: InputMaybe<Scalars['ID']['input']>;
  merchantCriteria: InputMaybe<Array<InputMaybe<MerchantCriterionInput>>>;
  merchantCriteriaUseOriginalStatement: InputMaybe<Scalars['Boolean']['input']>;
  needsReviewByUserAction: InputMaybe<Scalars['ID']['input']>;
  reviewStatusAction: InputMaybe<Scalars['String']['input']>;
  sendNotificationAction: InputMaybe<Scalars['Boolean']['input']>;
  setCategoryAction: InputMaybe<Scalars['ID']['input']>;
  setHideFromReportsAction: InputMaybe<Scalars['Boolean']['input']>;
  setMerchantAction: InputMaybe<Scalars['String']['input']>;
  splitTransactionsAction: InputMaybe<SplitTransactionsActionInput>;
};

export type UpdateTransactionRuleOrderV2Mutation = {
  __typename: 'UpdateTransactionRuleOrderV2Mutation';
  transactionRules: Array<TransactionRuleV2>;
};

export type UpdateTransactionRuleV2Mutation = {
  __typename: 'UpdateTransactionRuleV2Mutation';
  errors: Maybe<PayloadError>;
  transactionRule: Maybe<TransactionRuleV2>;
};

export type UpdateTransactionSplitMutation = {
  __typename: 'UpdateTransactionSplitMutation';
  errors: Maybe<PayloadError>;
  transaction: Maybe<Transaction>;
};

export type UpdateTransactionSplitMutationInput = {
  splitData: Array<TransactionSplitInputData>;
  transactionId: Scalars['UUID']['input'];
};

export type UpdateTransactionTag = {
  __typename: 'UpdateTransactionTag';
  errors: Maybe<PayloadError>;
  tag: Maybe<TransactionTag>;
};

export type UpdateTransactionTagInput = {
  color: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateTransactionTagOrder = {
  __typename: 'UpdateTransactionTagOrder';
  errors: Maybe<PayloadError>;
  householdTransactionTags: Maybe<Array<TransactionTag>>;
};

export type UpdateUserProfile = {
  __typename: 'UpdateUserProfile';
  errors: Maybe<PayloadError>;
  userProfile: Maybe<UserProfile>;
};

export type UpdateUserProfileInput = {
  aiAssistantOptedIn: InputMaybe<Scalars['Boolean']['input']>;
  birthday: InputMaybe<Scalars['Date']['input']>;
  collaboratesOnFinances: InputMaybe<Scalars['Boolean']['input']>;
  collaboratesOnFinancesDetailed: InputMaybe<Scalars['String']['input']>;
  completedYearlyReview: InputMaybe<Scalars['Boolean']['input']>;
  dismissedFlexBudgetingWalkthroughAt: InputMaybe<Scalars['DateTime']['input']>;
  dismissedRecurringWalkthroughAt: InputMaybe<Scalars['DateTime']['input']>;
  dismissedSpendingInsightsBanner: InputMaybe<Scalars['Boolean']['input']>;
  dismissedTransactionsListUpdatesTourAt: InputMaybe<Scalars['DateTime']['input']>;
  dismissedYearlyReview: InputMaybe<Scalars['Boolean']['input']>;
  hasDismissedWhatsNewAt: InputMaybe<Scalars['DateTime']['input']>;
  hasSeenCategoriesManagementTour: InputMaybe<Scalars['Boolean']['input']>;
  hasSeenGoalsFeatureTour: InputMaybe<Scalars['Boolean']['input']>;
  hasSeenWeeklyReviewTour: InputMaybe<Scalars['Boolean']['input']>;
  shouldPromptTransactionRuleCreation: InputMaybe<Scalars['Boolean']['input']>;
  skippedPremiumUpsell: InputMaybe<Scalars['Boolean']['input']>;
  userReportedAttributionChannels: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  viewedMarkAsReviewedUpdatesCalloutAt: InputMaybe<Scalars['DateTime']['input']>;
};

export type UploadBalanceHistorySession = {
  __typename: 'UploadBalanceHistorySession';
  sessionKey: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type UploadStatementSession = {
  __typename: 'UploadStatementSession';
  errorMessage: Maybe<Scalars['String']['output']>;
  sessionKey: Scalars['String']['output'];
  skipCheckForDuplicates: Maybe<Scalars['Boolean']['output']>;
  status: Scalars['String']['output'];
  uploadedStatement: Maybe<UploadedStatement>;
};

export type UploadedStatement = {
  __typename: 'UploadedStatement';
  endDate: Scalars['Date']['output'];
  fileHash: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  parserName: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
  transactionCount: Scalars['Int']['output'];
};

export type User = {
  __typename: 'User';
  activeSupportAccountAccessGrant: Maybe<SupportAccountAccessGrant>;
  attributionData: Maybe<UserAttributionData>;
  birthday: Maybe<Scalars['Date']['output']>;
  canRequestAppStoreReview: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  externalAuthProviderNames: Array<Scalars['String']['output']>;
  externalAuthProviders: Array<UserExternalAuth>;
  externalId: Scalars['ID']['output'];
  /** Checks whether to redirect the user to the Advisor portal automatically. */
  forceRedirectSponsor: Scalars['Boolean']['output'];
  hasMfaOn: Scalars['Boolean']['output'];
  hasNewActivity: Scalars['Boolean']['output'];
  hasPassword: Scalars['Boolean']['output'];
  householdRole: UserHouseholdRole;
  id: Scalars['ID']['output'];
  isSponsor: Scalars['Boolean']['output'];
  isSuperuser: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  pendingEmailUpdateVerification: Maybe<PendingEmailUpdateVerification>;
  profile: Maybe<UserProfile>;
  profilePicture: Maybe<UserProfilePicture>;
  profilePictureUrl: Maybe<Scalars['String']['output']>;
  timezone: Maybe<Scalars['String']['output']>;
};

export type UserAttributionData = {
  __typename: 'UserAttributionData';
  anonymousId: Maybe<Scalars['String']['output']>;
};

export type UserExternalAuth = {
  __typename: 'UserExternalAuth';
  email: Scalars['String']['output'];
  provider: Scalars['String']['output'];
};

export enum UserHouseholdRole {
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
}

export type UserMessage = MessageInterface & {
  __typename: 'UserMessage';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  threadId: Scalars['ID']['output'];
  user: User;
};

export type UserOnboardingFinancialGoal = {
  __typename: 'UserOnboardingFinancialGoal';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  onboardingFinancialGoal: OnboardingFinancialGoal;
  otherText: Maybe<Scalars['String']['output']>;
};

export type UserProfile = {
  __typename: 'UserProfile';
  age: Maybe<Scalars['Int']['output']>;
  aiAssistantOptedInAt: Maybe<Scalars['DateTime']['output']>;
  birthday: Maybe<Scalars['String']['output']>;
  collaboratesOnFinances: Maybe<Scalars['Boolean']['output']>;
  collaboratesOnFinancesDetailed: Maybe<Scalars['String']['output']>;
  dismissedFlexBudgetingWalkthroughAt: Maybe<Scalars['DateTime']['output']>;
  dismissedRecurringWalkthroughAt: Maybe<Scalars['DateTime']['output']>;
  dismissedSpendingInsightsBanner: Maybe<Scalars['Boolean']['output']>;
  dismissedTransactionsListUpdatesTourAt: Maybe<Scalars['DateTime']['output']>;
  dismissedYearlyReviewAt: Maybe<Scalars['DateTime']['output']>;
  hasDismissedWhatsNewAt: Maybe<Scalars['DateTime']['output']>;
  hasSeenCategoriesManagementTour: Scalars['Boolean']['output'];
  hasSeenGoalsFeatureTour: Scalars['Boolean']['output'];
  hasSeenWeeklyReviewTour: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastRequestedAppStoreReviewAt: Maybe<Scalars['DateTime']['output']>;
  shouldPromptTransactionRuleCreation: Scalars['Boolean']['output'];
  skippedPremiumUpsellAt: Maybe<Scalars['DateTime']['output']>;
  userReportedAttributionChannels: Array<Maybe<Scalars['String']['output']>>;
  viewedMarkAsReviewedUpdatesCalloutAt: Maybe<Scalars['DateTime']['output']>;
};

export type UserProfilePicture = {
  __typename: 'UserProfilePicture';
  cloudinaryPublicId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  thumbnailUrl: Maybe<Scalars['String']['output']>;
};

export type Vehicle = {
  __typename: 'Vehicle';
  name: Scalars['String']['output'];
  value: Scalars['Float']['output'];
  vin: Scalars['String']['output'];
};

export type ZestimateResult = {
  __typename: 'ZestimateResult';
  addressCity: Scalars['String']['output'];
  addressPostalCode: Scalars['String']['output'];
  addressStateAbbr: Scalars['String']['output'];
  addressStreet: Scalars['String']['output'];
  zestimate: Scalars['Int']['output'];
  zpid: Scalars['String']['output'];
};

export type AccountFieldsFragment = {
  __typename: 'Account';
  id: string;
  displayName: string;
  syncDisabled: boolean;
  deactivatedAt: string | null | undefined;
  isHidden: boolean;
  isAsset: boolean;
  mask: string | null | undefined;
  createdAt: string;
  updatedAt: string;
  displayLastUpdatedAt: string;
  currentBalance: number | null | undefined;
  displayBalance: number | null | undefined;
  includeInNetWorth: boolean;
  hideFromList: boolean;
  hideTransactionsFromReports: boolean;
  includeBalanceInNetWorth: boolean;
  includeInGoalBalance: boolean;
  dataProvider: string | null | undefined;
  dataProviderAccountId: string | null | undefined;
  isManual: boolean;
  transactionsCount: number;
  holdingsCount: number;
  manualInvestmentsTrackingMethod: string | null | undefined;
  order: number;
  icon: string;
  logoUrl: string | null | undefined;
  type: { __typename: 'AccountType'; name: string; display: string; group: string };
  subtype: { __typename: 'AccountSubtype'; name: string; display: string };
  credential:
    | {
        __typename: 'Credential';
        id: string;
        updateRequired: boolean;
        disconnectedFromDataProviderAt: string | null | undefined;
        dataProvider: DataProviderLegacy;
        institution: {
          __typename: 'Institution';
          id: string;
          plaidInstitutionId: string | null | undefined;
          name: string;
          status: InstitutionStatus | null | undefined;
          logo: string | null | undefined;
        };
      }
    | null
    | undefined;
  institution:
    | {
        __typename: 'Institution';
        id: string;
        name: string;
        logo: string | null | undefined;
        primaryColor: string;
        url: string;
      }
    | null
    | undefined;
};

export type Common_GetAccountQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Common_GetAccountQuery = {
  __typename: 'Query';
  account:
    | {
        __typename: 'Account';
        id: string;
        displayName: string;
        dataProvider: string | null | undefined;
        type: { __typename: 'AccountType'; name: string };
        subtype: { __typename: 'AccountSubtype'; name: string };
        institution:
          | {
              __typename: 'Institution';
              id: string;
              logo: string | null | undefined;
              primaryColor: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountsQuery = {
  __typename: 'Query';
  accounts: Array<{
    __typename: 'Account';
    id: string;
    displayName: string;
    syncDisabled: boolean;
    deactivatedAt: string | null | undefined;
    isHidden: boolean;
    isAsset: boolean;
    mask: string | null | undefined;
    createdAt: string;
    updatedAt: string;
    displayLastUpdatedAt: string;
    currentBalance: number | null | undefined;
    displayBalance: number | null | undefined;
    includeInNetWorth: boolean;
    hideFromList: boolean;
    hideTransactionsFromReports: boolean;
    includeBalanceInNetWorth: boolean;
    includeInGoalBalance: boolean;
    dataProvider: string | null | undefined;
    dataProviderAccountId: string | null | undefined;
    isManual: boolean;
    transactionsCount: number;
    holdingsCount: number;
    manualInvestmentsTrackingMethod: string | null | undefined;
    order: number;
    icon: string;
    logoUrl: string | null | undefined;
    type: { __typename: 'AccountType'; name: string; display: string; group: string };
    subtype: { __typename: 'AccountSubtype'; name: string; display: string };
    credential:
      | {
          __typename: 'Credential';
          id: string;
          updateRequired: boolean;
          disconnectedFromDataProviderAt: string | null | undefined;
          dataProvider: DataProviderLegacy;
          institution: {
            __typename: 'Institution';
            id: string;
            plaidInstitutionId: string | null | undefined;
            name: string;
            status: InstitutionStatus | null | undefined;
            logo: string | null | undefined;
          };
        }
      | null
      | undefined;
    institution:
      | {
          __typename: 'Institution';
          id: string;
          name: string;
          logo: string | null | undefined;
          primaryColor: string;
          url: string;
        }
      | null
      | undefined;
  }>;
  householdPreferences:
    | {
        __typename: 'HouseholdPreferences';
        id: string | null | undefined;
        accountGroupOrder: Array<string>;
      }
    | null
    | undefined;
};

export type Common_UpdateAccountMutationVariables = Exact<{
  input: UpdateAccountMutationInput;
}>;

export type Common_UpdateAccountMutation = {
  __typename: 'Mutation';
  updateAccount:
    | {
        __typename: 'UpdateAccountMutation';
        account:
          | {
              __typename: 'Account';
              id: string;
              displayName: string;
              syncDisabled: boolean;
              deactivatedAt: string | null | undefined;
              isHidden: boolean;
              isAsset: boolean;
              mask: string | null | undefined;
              createdAt: string;
              updatedAt: string;
              displayLastUpdatedAt: string;
              currentBalance: number | null | undefined;
              displayBalance: number | null | undefined;
              includeInNetWorth: boolean;
              hideFromList: boolean;
              hideTransactionsFromReports: boolean;
              includeBalanceInNetWorth: boolean;
              includeInGoalBalance: boolean;
              dataProvider: string | null | undefined;
              dataProviderAccountId: string | null | undefined;
              isManual: boolean;
              transactionsCount: number;
              holdingsCount: number;
              manualInvestmentsTrackingMethod: string | null | undefined;
              order: number;
              icon: string;
              logoUrl: string | null | undefined;
              type: { __typename: 'AccountType'; name: string; display: string; group: string };
              subtype: { __typename: 'AccountSubtype'; name: string; display: string };
              credential:
                | {
                    __typename: 'Credential';
                    id: string;
                    updateRequired: boolean;
                    disconnectedFromDataProviderAt: string | null | undefined;
                    dataProvider: DataProviderLegacy;
                    institution: {
                      __typename: 'Institution';
                      id: string;
                      plaidInstitutionId: string | null | undefined;
                      name: string;
                      status: InstitutionStatus | null | undefined;
                      logo: string | null | undefined;
                    };
                  }
                | null
                | undefined;
              institution:
                | {
                    __typename: 'Institution';
                    id: string;
                    name: string;
                    logo: string | null | undefined;
                    primaryColor: string;
                    url: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_BulkUpdateAccountsMutationVariables = Exact<{
  input: Array<InputMaybe<UpdateAccountsMutationInput>> | InputMaybe<UpdateAccountsMutationInput>;
}>;

export type Common_BulkUpdateAccountsMutation = {
  __typename: 'Mutation';
  updateAccounts:
    | {
        __typename: 'UpdateAccountsMutation';
        accounts:
          | Array<
              | {
                  __typename: 'Account';
                  id: string;
                  displayName: string;
                  syncDisabled: boolean;
                  deactivatedAt: string | null | undefined;
                  isHidden: boolean;
                  isAsset: boolean;
                  mask: string | null | undefined;
                  createdAt: string;
                  updatedAt: string;
                  displayLastUpdatedAt: string;
                  currentBalance: number | null | undefined;
                  displayBalance: number | null | undefined;
                  includeInNetWorth: boolean;
                  hideFromList: boolean;
                  hideTransactionsFromReports: boolean;
                  includeBalanceInNetWorth: boolean;
                  includeInGoalBalance: boolean;
                  dataProvider: string | null | undefined;
                  dataProviderAccountId: string | null | undefined;
                  isManual: boolean;
                  transactionsCount: number;
                  holdingsCount: number;
                  manualInvestmentsTrackingMethod: string | null | undefined;
                  order: number;
                  icon: string;
                  logoUrl: string | null | undefined;
                  type: { __typename: 'AccountType'; name: string; display: string; group: string };
                  subtype: { __typename: 'AccountSubtype'; name: string; display: string };
                  credential:
                    | {
                        __typename: 'Credential';
                        id: string;
                        updateRequired: boolean;
                        disconnectedFromDataProviderAt: string | null | undefined;
                        dataProvider: DataProviderLegacy;
                        institution: {
                          __typename: 'Institution';
                          id: string;
                          plaidInstitutionId: string | null | undefined;
                          name: string;
                          status: InstitutionStatus | null | undefined;
                          logo: string | null | undefined;
                        };
                      }
                    | null
                    | undefined;
                  institution:
                    | {
                        __typename: 'Institution';
                        id: string;
                        name: string;
                        logo: string | null | undefined;
                        primaryColor: string;
                        url: string;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_DeleteAccountMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Common_DeleteAccountMutation = {
  __typename: 'Mutation';
  deleteAccount:
    | {
        __typename: 'DeleteAccountMutation';
        deleted: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetAccountTypeOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountTypeOptionsQuery = {
  __typename: 'Query';
  accountTypeOptions: Array<{
    __typename: 'AccountTypeOption';
    type: {
      __typename: 'AccountType';
      name: string;
      display: string;
      group: string;
      possibleSubtypes: Array<{ __typename: 'AccountSubtype'; display: string; name: string }>;
    };
    subtype: { __typename: 'AccountSubtype'; name: string; display: string } | null | undefined;
  }>;
};

export type GetHasAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetHasAccountsQuery = { __typename: 'Query'; hasAccounts: boolean };

export type GetHasSyncedAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetHasSyncedAccountsQuery = { __typename: 'Query'; hasAccounts: boolean };

export type InstitutionStatusFieldsFragment = {
  __typename: 'Institution';
  id: string;
  newConnectionsDisabled: boolean;
  hasIssuesReported: boolean;
  hasIssuesReportedMessage: string;
  plaidStatus: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
  status: InstitutionStatus | null | undefined;
  balanceStatus: InstitutionStatus | null | undefined;
  transactionsStatus: InstitutionStatus | null | undefined;
};

export type GetInstitutionQueryVariables = Exact<{
  plaidId: Scalars['String']['input'];
}>;

export type GetInstitutionQuery = {
  __typename: 'Query';
  institution:
    | {
        __typename: 'Institution';
        name: string;
        logo: string | null | undefined;
        id: string;
        newConnectionsDisabled: boolean;
        hasIssuesReported: boolean;
        hasIssuesReportedMessage: string;
        plaidStatus: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
        status: InstitutionStatus | null | undefined;
        balanceStatus: InstitutionStatus | null | undefined;
        transactionsStatus: InstitutionStatus | null | undefined;
      }
    | null
    | undefined;
};

export type Common_GetAccountsForTransactionsQueryVariables = Exact<{
  selectedTransactionIds: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  excludedTransactionIds: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  isAllSelected: Scalars['Boolean']['input'];
  filters: InputMaybe<TransactionFilterInput>;
}>;

export type Common_GetAccountsForTransactionsQuery = {
  __typename: 'Query';
  getAccountsForTransactions: Array<{
    __typename: 'Account';
    id: string;
    displayName: string;
    dataProvider: string | null | undefined;
    icon: string;
    logoUrl: string | null | undefined;
    type: { __typename: 'AccountType'; name: string };
    subtype: { __typename: 'AccountSubtype'; name: string };
    institution:
      | {
          __typename: 'Institution';
          id: string;
          logo: string | null | undefined;
          primaryColor: string;
        }
      | null
      | undefined;
  }>;
};

export type Common_ForceRefreshAccountMutationVariables = Exact<{
  input: ForceRefreshAccountInput;
}>;

export type Common_ForceRefreshAccountMutation = {
  __typename: 'Mutation';
  forceRefreshAccount:
    | {
        __typename: 'ForceRefreshAccountMutation';
        success: boolean;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_ForceRefreshAccountsMutationVariables = Exact<{ [key: string]: never }>;

export type Common_ForceRefreshAccountsMutation = {
  __typename: 'Mutation';
  forceRefreshAllAccounts:
    | {
        __typename: 'ForceRefreshAllAccountsMutation';
        success: boolean;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_CreateManualInvestmentsAccountMutationVariables = Exact<{
  input: CreateManualInvestmentsAccountInput;
}>;

export type Common_CreateManualInvestmentsAccountMutation = {
  __typename: 'Mutation';
  createManualInvestmentsAccount:
    | {
        __typename: 'CreateManualInvestmentsAccount';
        account: { __typename: 'Account'; id: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AccountLogoFieldsFragment = {
  __typename: 'Account';
  id: string;
  dataProvider: string | null | undefined;
  type: { __typename: 'AccountType'; name: string };
  subtype: { __typename: 'AccountSubtype'; name: string };
  institution:
    | {
        __typename: 'Institution';
        id: string;
        logo: string | null | undefined;
        primaryColor: string;
      }
    | null
    | undefined;
};

export type NewAccountLogoFieldsFragment = {
  __typename: 'Account';
  id: string;
  dataProvider: string | null | undefined;
  type: { __typename: 'AccountType'; name: string };
  subtype: { __typename: 'AccountSubtype'; name: string };
  institution:
    | {
        __typename: 'Institution';
        id: string;
        logo: string | null | undefined;
        primaryColor: string;
      }
    | null
    | undefined;
};

export type Common_DeleteCredentialMutationVariables = Exact<{
  input: DeleteCredentialInput;
}>;

export type Common_DeleteCredentialMutation = {
  __typename: 'Mutation';
  deleteCredential:
    | { __typename: 'DeleteCredentialMutation'; deleted: boolean | null | undefined }
    | null
    | undefined;
};

export type Common_UndeleteAccountMutationVariables = Exact<{
  input: UndeleteAccountMutationInput;
}>;

export type Common_UndeleteAccountMutation = {
  __typename: 'Mutation';
  undeleteAccount:
    | {
        __typename: 'UndeleteAccountMutation';
        undeleted: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetDisplayBalancePreviewQueryVariables = Exact<{
  accountId: Scalars['UUID']['input'];
  invertSyncedBalance: InputMaybe<Scalars['Boolean']['input']>;
  useAvailableBalance: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetDisplayBalancePreviewQuery = {
  __typename: 'Query';
  account:
    | { __typename: 'Account'; id: string; displayBalancePreview: number | null | undefined }
    | null
    | undefined;
};

export type Common_MarkAllActivityAsReadMutationVariables = Exact<{
  first: InputMaybe<Scalars['Int']['input']>;
}>;

export type Common_MarkAllActivityAsReadMutation = {
  __typename: 'Mutation';
  markAllActivityAsRead:
    | {
        __typename: 'MarkAllActivityAsReadMutation';
        activityEvents:
          | {
              __typename: 'ActivityConnection';
              edges: Array<
                | {
                    __typename: 'ActivityEdge';
                    node:
                      | {
                          __typename: 'ActivityNode';
                          id: string;
                          readAt: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_ClearAllActivitiesMutationVariables = Exact<{ [key: string]: never }>;

export type Common_ClearAllActivitiesMutation = {
  __typename: 'Mutation';
  clearAllActivities:
    | { __typename: 'ClearAllActivitiesMutation'; success: boolean | null | undefined }
    | null
    | undefined;
};

export type Common_MarkActivityAsReadMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Common_MarkActivityAsReadMutation = {
  __typename: 'Mutation';
  markActivityAsRead:
    | {
        __typename: 'MarkActivityAsReadMutation';
        node: { __typename: 'ActivityNode'; id: string; readAt: string | null | undefined };
      }
    | null
    | undefined;
};

export type Common_DismissActivityMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type Common_DismissActivityMutation = {
  __typename: 'Mutation';
  dismissActivity:
    | {
        __typename: 'DismissActivity';
        node: { __typename: 'ActivityNode'; id: string; dismissedAt: string | null | undefined };
      }
    | null
    | undefined;
};

export type Common_UpdateSponsorshipInviteMutationVariables = Exact<{
  input: UpdateSponsorshipInviteInput;
}>;

export type Common_UpdateSponsorshipInviteMutation = {
  __typename: 'Mutation';
  updateSubscriptionSponsorshipInvite:
    | {
        __typename: 'UpdateSponsorshipInvite';
        invite:
          | {
              __typename: 'SubscriptionSponsorshipInvite';
              id: string;
              label: string | null | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCashFlowDashboardQueryVariables = Exact<{
  startDate: InputMaybe<Scalars['Date']['input']>;
  endDate: InputMaybe<Scalars['Date']['input']>;
}>;

export type GetCashFlowDashboardQuery = {
  __typename: 'Query';
  byDay: Array<{
    __typename: 'AggregateData';
    summary: { __typename: 'TransactionsSummary'; sumExpense: number };
    groupBy: { __typename: 'AggregateGroupBy'; day: string | null | undefined };
  }>;
};

export type SubscriptionFieldsFragment = {
  __typename: 'HouseholdSubscription';
  id: string;
  trialEndsAt: string | null | undefined;
  trialType: TrialType | null | undefined;
  isOnFreeTrial: boolean;
  hasChargedForLifetime: boolean;
  currentPeriodEndsAt: string | null | undefined;
  billingPeriod: PaymentPeriod | null | undefined;
  nextPaymentAmount: number | null | undefined;
  hasStripeSubscriptionId: boolean;
  hasPremiumEntitlement: boolean;
  entitlements: Array<SubscriptionEntitlement>;
  eligibleForTrial: boolean;
  willCancelAtPeriodEnd: boolean;
  paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
  hasBillingIssue: boolean;
  isSponsor: boolean;
  paymentMethod:
    | { __typename: 'PaymentMethod'; lastFour: string; brand: string }
    | null
    | undefined;
  activePromoCode:
    | { __typename: 'PromoCode'; code: string; description: string }
    | null
    | undefined;
  sponsoredBy:
    | {
        __typename: 'SubscriptionSponsorInfo';
        name: string;
        email: string;
        profilePictureUrl: string | null | undefined;
      }
    | null
    | undefined;
};

export type SponsoredSubscriptionFieldsFragment = {
  __typename: 'HouseholdSubscription';
  activeSponsorship:
    | {
        __typename: 'SubscriptionSponsorshipInfo';
        id: string;
        sponsor:
          | {
              __typename: 'SubscriptionSponsorInfo';
              name: string;
              email: string;
              profilePictureUrl: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetSubscriptionStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetSubscriptionStatusQuery = {
  __typename: 'Query';
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    entitlements: Array<SubscriptionEntitlement>;
    trialEndsAt: string | null | undefined;
    trialType: TrialType | null | undefined;
    isOnFreeTrial: boolean;
    hasChargedForLifetime: boolean;
    currentPeriodEndsAt: string | null | undefined;
    billingPeriod: PaymentPeriod | null | undefined;
    nextPaymentAmount: number | null | undefined;
    hasStripeSubscriptionId: boolean;
    hasPremiumEntitlement: boolean;
    eligibleForTrial: boolean;
    willCancelAtPeriodEnd: boolean;
    paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
    hasBillingIssue: boolean;
    isSponsor: boolean;
    paymentMethod:
      | { __typename: 'PaymentMethod'; lastFour: string; brand: string }
      | null
      | undefined;
    activePromoCode:
      | { __typename: 'PromoCode'; code: string; description: string }
      | null
      | undefined;
    sponsoredBy:
      | {
          __typename: 'SubscriptionSponsorInfo';
          name: string;
          email: string;
          profilePictureUrl: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type Common_GetSubscriptionHasEntitlementAfterSyncQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Common_GetSubscriptionHasEntitlementAfterSyncQuery = {
  __typename: 'Query';
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    hasPremiumEntitlementAfterSync: boolean;
  };
};

export type Common_CancelSubscriptionForHouseholdMutationVariables = Exact<{
  input: CancelStripeSubscriptionMutationInput;
}>;

export type Common_CancelSubscriptionForHouseholdMutation = {
  __typename: 'Mutation';
  cancelStripeSubscription:
    | {
        __typename: 'CancelStripeSubscriptionMutation';
        canceled: boolean;
        subscription:
          | {
              __typename: 'HouseholdSubscription';
              id: string;
              trialEndsAt: string | null | undefined;
              trialType: TrialType | null | undefined;
              isOnFreeTrial: boolean;
              hasChargedForLifetime: boolean;
              currentPeriodEndsAt: string | null | undefined;
              billingPeriod: PaymentPeriod | null | undefined;
              nextPaymentAmount: number | null | undefined;
              hasStripeSubscriptionId: boolean;
              hasPremiumEntitlement: boolean;
              entitlements: Array<SubscriptionEntitlement>;
              eligibleForTrial: boolean;
              willCancelAtPeriodEnd: boolean;
              paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
              hasBillingIssue: boolean;
              isSponsor: boolean;
              paymentMethod:
                | { __typename: 'PaymentMethod'; lastFour: string; brand: string }
                | null
                | undefined;
              activePromoCode:
                | { __typename: 'PromoCode'; code: string; description: string }
                | null
                | undefined;
              sponsoredBy:
                | {
                    __typename: 'SubscriptionSponsorInfo';
                    name: string;
                    email: string;
                    profilePictureUrl: string | null | undefined;
                  }
                | null
                | undefined;
              activeSponsorship:
                | {
                    __typename: 'SubscriptionSponsorshipInfo';
                    id: string;
                    sponsor:
                      | {
                          __typename: 'SubscriptionSponsorInfo';
                          name: string;
                          email: string;
                          profilePictureUrl: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BudgetDataMonthlyAmountsFieldsFragment = {
  __typename: 'BudgetMonthlyAmounts';
  month: string;
  plannedCashFlowAmount: number | null | undefined;
  plannedSetAsideAmount: number | null | undefined;
  actualAmount: number | null | undefined;
  remainingAmount: number | null | undefined;
  previousMonthRolloverAmount: number | null | undefined;
  rolloverType: string | null | undefined;
  cumulativeActualAmount: number | null | undefined;
  rolloverTargetAmount: number | null | undefined;
};

export type BudgetDataTotalsByMonthFieldsFragment = {
  __typename: 'BudgetTotals';
  actualAmount: number;
  plannedAmount: number;
  previousMonthRolloverAmount: number;
  remainingAmount: number;
};

export type BudgetRolloverPeriodFieldsFragment = {
  __typename: 'BudgetRolloverPeriod';
  id: string;
  startMonth: string;
  endMonth: string | null | undefined;
  startingBalance: number | null | undefined;
  targetAmount: number | null | undefined;
  frequency: string | null | undefined;
  type: string;
};

export type BudgetCategoryFieldsFragment = {
  __typename: 'Category';
  id: string;
  name: string;
  icon: string;
  order: number;
  budgetVariability: BudgetVariability | null | undefined;
  excludeFromBudget: boolean | null | undefined;
  isSystemCategory: boolean;
  updatedAt: string;
  group: {
    __typename: 'CategoryGroup';
    id: string;
    type: CategoryGroupType;
    budgetVariability: BudgetVariability | null | undefined;
    groupLevelBudgetingEnabled: boolean | null | undefined;
  };
  rolloverPeriod:
    | {
        __typename: 'BudgetRolloverPeriod';
        id: string;
        startMonth: string;
        endMonth: string | null | undefined;
        startingBalance: number | null | undefined;
        targetAmount: number | null | undefined;
        frequency: string | null | undefined;
        type: string;
      }
    | null
    | undefined;
};

export type BudgetCategoryGroupFieldsFragment = {
  __typename: 'CategoryGroup';
  id: string;
  name: string;
  order: number;
  type: CategoryGroupType;
  budgetVariability: BudgetVariability | null | undefined;
  groupLevelBudgetingEnabled: boolean | null | undefined;
  categories: Array<{
    __typename: 'Category';
    id: string;
    name: string;
    icon: string;
    order: number;
    budgetVariability: BudgetVariability | null | undefined;
    excludeFromBudget: boolean | null | undefined;
    isSystemCategory: boolean;
    updatedAt: string;
    group: {
      __typename: 'CategoryGroup';
      id: string;
      type: CategoryGroupType;
      budgetVariability: BudgetVariability | null | undefined;
      groupLevelBudgetingEnabled: boolean | null | undefined;
    };
    rolloverPeriod:
      | {
          __typename: 'BudgetRolloverPeriod';
          id: string;
          startMonth: string;
          endMonth: string | null | undefined;
          startingBalance: number | null | undefined;
          targetAmount: number | null | undefined;
          frequency: string | null | undefined;
          type: string;
        }
      | null
      | undefined;
  }>;
  rolloverPeriod:
    | {
        __typename: 'BudgetRolloverPeriod';
        id: string;
        type: string;
        startMonth: string;
        endMonth: string | null | undefined;
        startingBalance: number | null | undefined;
        frequency: string | null | undefined;
        targetAmount: number | null | undefined;
      }
    | null
    | undefined;
};

export type BudgetDataGoalsV2FieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  name: string;
  archivedAt: string | null | undefined;
  completedAt: string | null | undefined;
  priority: number;
  imageStorageProvider: string | null | undefined;
  imageStorageProviderId: string | null | undefined;
  plannedContributions: Array<{
    __typename: 'GoalPlannedContributionV2';
    id: string;
    month: string;
    amount: number;
  }>;
  monthlyContributionSummaries: Array<{
    __typename: 'GoalContributionMonthlySummary';
    month: string;
    sum: number;
  }>;
};

export type BudgetDataFieldsFragment = {
  __typename: 'BudgetData';
  monthlyAmountsByCategory: Array<{
    __typename: 'BudgetCategoryMonthlyAmounts';
    category: { __typename: 'Category'; id: string };
    monthlyAmounts: Array<{
      __typename: 'BudgetMonthlyAmounts';
      month: string;
      plannedCashFlowAmount: number | null | undefined;
      plannedSetAsideAmount: number | null | undefined;
      actualAmount: number | null | undefined;
      remainingAmount: number | null | undefined;
      previousMonthRolloverAmount: number | null | undefined;
      rolloverType: string | null | undefined;
      cumulativeActualAmount: number | null | undefined;
      rolloverTargetAmount: number | null | undefined;
    }>;
  }>;
  monthlyAmountsByCategoryGroup: Array<{
    __typename: 'BudgetCategoryGroupMonthlyAmounts';
    categoryGroup: { __typename: 'CategoryGroup'; id: string };
    monthlyAmounts: Array<{
      __typename: 'BudgetMonthlyAmounts';
      month: string;
      plannedCashFlowAmount: number | null | undefined;
      plannedSetAsideAmount: number | null | undefined;
      actualAmount: number | null | undefined;
      remainingAmount: number | null | undefined;
      previousMonthRolloverAmount: number | null | undefined;
      rolloverType: string | null | undefined;
      cumulativeActualAmount: number | null | undefined;
      rolloverTargetAmount: number | null | undefined;
    }>;
  }>;
  monthlyAmountsForFlexExpense: {
    __typename: 'BudgetFlexMonthlyAmounts';
    budgetVariability: BudgetVariability;
    monthlyAmounts: Array<{
      __typename: 'BudgetMonthlyAmounts';
      month: string;
      plannedCashFlowAmount: number | null | undefined;
      plannedSetAsideAmount: number | null | undefined;
      actualAmount: number | null | undefined;
      remainingAmount: number | null | undefined;
      previousMonthRolloverAmount: number | null | undefined;
      rolloverType: string | null | undefined;
      cumulativeActualAmount: number | null | undefined;
      rolloverTargetAmount: number | null | undefined;
    }>;
  };
  totalsByMonth: Array<{
    __typename: 'BudgetMonthTotals';
    month: string;
    totalIncome: {
      __typename: 'BudgetTotals';
      actualAmount: number;
      plannedAmount: number;
      previousMonthRolloverAmount: number;
      remainingAmount: number;
    };
    totalExpenses: {
      __typename: 'BudgetTotals';
      actualAmount: number;
      plannedAmount: number;
      previousMonthRolloverAmount: number;
      remainingAmount: number;
    };
    totalFixedExpenses: {
      __typename: 'BudgetTotals';
      actualAmount: number;
      plannedAmount: number;
      previousMonthRolloverAmount: number;
      remainingAmount: number;
    };
    totalNonMonthlyExpenses: {
      __typename: 'BudgetTotals';
      actualAmount: number;
      plannedAmount: number;
      previousMonthRolloverAmount: number;
      remainingAmount: number;
    };
    totalFlexibleExpenses: {
      __typename: 'BudgetTotals';
      actualAmount: number;
      plannedAmount: number;
      previousMonthRolloverAmount: number;
      remainingAmount: number;
    };
  }>;
};

export type BudgetTotalsByMonthFieldsFragment = {
  __typename: 'BudgetMonthTotals';
  month: string;
  totalIncome: {
    __typename: 'BudgetTotals';
    actualAmount: number;
    plannedAmount: number;
    previousMonthRolloverAmount: number;
    remainingAmount: number;
  };
  totalExpenses: {
    __typename: 'BudgetTotals';
    actualAmount: number;
    plannedAmount: number;
    previousMonthRolloverAmount: number;
    remainingAmount: number;
  };
  totalFixedExpenses: {
    __typename: 'BudgetTotals';
    actualAmount: number;
    plannedAmount: number;
    previousMonthRolloverAmount: number;
    remainingAmount: number;
  };
  totalNonMonthlyExpenses: {
    __typename: 'BudgetTotals';
    actualAmount: number;
    plannedAmount: number;
    previousMonthRolloverAmount: number;
    remainingAmount: number;
  };
  totalFlexibleExpenses: {
    __typename: 'BudgetTotals';
    actualAmount: number;
    plannedAmount: number;
    previousMonthRolloverAmount: number;
    remainingAmount: number;
  };
};

export type BudgetMonthlyAmountsByCategoryGroupFieldsFragment = {
  __typename: 'BudgetCategoryGroupMonthlyAmounts';
  categoryGroup: { __typename: 'CategoryGroup'; id: string };
  monthlyAmounts: Array<{
    __typename: 'BudgetMonthlyAmounts';
    month: string;
    plannedCashFlowAmount: number | null | undefined;
    plannedSetAsideAmount: number | null | undefined;
    actualAmount: number | null | undefined;
    remainingAmount: number | null | undefined;
    previousMonthRolloverAmount: number | null | undefined;
    rolloverType: string | null | undefined;
    cumulativeActualAmount: number | null | undefined;
    rolloverTargetAmount: number | null | undefined;
  }>;
};

export type BudgetMonthlyAmountsByCategoryFieldsFragment = {
  __typename: 'BudgetCategoryMonthlyAmounts';
  category: { __typename: 'Category'; id: string };
  monthlyAmounts: Array<{
    __typename: 'BudgetMonthlyAmounts';
    month: string;
    plannedCashFlowAmount: number | null | undefined;
    plannedSetAsideAmount: number | null | undefined;
    actualAmount: number | null | undefined;
    remainingAmount: number | null | undefined;
    previousMonthRolloverAmount: number | null | undefined;
    rolloverType: string | null | undefined;
    cumulativeActualAmount: number | null | undefined;
    rolloverTargetAmount: number | null | undefined;
  }>;
};

export type BudgetMonthlyAmountsForFlexExpenseFieldsFragment = {
  __typename: 'BudgetFlexMonthlyAmounts';
  budgetVariability: BudgetVariability;
  monthlyAmounts: Array<{
    __typename: 'BudgetMonthlyAmounts';
    month: string;
    plannedCashFlowAmount: number | null | undefined;
    plannedSetAsideAmount: number | null | undefined;
    actualAmount: number | null | undefined;
    remainingAmount: number | null | undefined;
    previousMonthRolloverAmount: number | null | undefined;
    rolloverType: string | null | undefined;
    cumulativeActualAmount: number | null | undefined;
    rolloverTargetAmount: number | null | undefined;
  }>;
};

export type BudgetStatusFieldsFragment = {
  __typename: 'BudgetStatus';
  hasBudget: boolean;
  hasTransactions: boolean;
  willCreateBudgetFromEmptyDefaultCategories: boolean;
};

export type Common_BudgetDataQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type Common_BudgetDataQuery = {
  __typename: 'Query';
  budgetSystem: BudgetSystem;
  budgetStatus: {
    __typename: 'BudgetStatus';
    hasBudget: boolean;
    hasTransactions: boolean;
    willCreateBudgetFromEmptyDefaultCategories: boolean;
  };
  budgetData: {
    __typename: 'BudgetData';
    monthlyAmountsByCategory: Array<{
      __typename: 'BudgetCategoryMonthlyAmounts';
      category: { __typename: 'Category'; id: string };
      monthlyAmounts: Array<{
        __typename: 'BudgetMonthlyAmounts';
        month: string;
        plannedCashFlowAmount: number | null | undefined;
        plannedSetAsideAmount: number | null | undefined;
        actualAmount: number | null | undefined;
        remainingAmount: number | null | undefined;
        previousMonthRolloverAmount: number | null | undefined;
        rolloverType: string | null | undefined;
        cumulativeActualAmount: number | null | undefined;
        rolloverTargetAmount: number | null | undefined;
      }>;
    }>;
    monthlyAmountsByCategoryGroup: Array<{
      __typename: 'BudgetCategoryGroupMonthlyAmounts';
      categoryGroup: { __typename: 'CategoryGroup'; id: string };
      monthlyAmounts: Array<{
        __typename: 'BudgetMonthlyAmounts';
        month: string;
        plannedCashFlowAmount: number | null | undefined;
        plannedSetAsideAmount: number | null | undefined;
        actualAmount: number | null | undefined;
        remainingAmount: number | null | undefined;
        previousMonthRolloverAmount: number | null | undefined;
        rolloverType: string | null | undefined;
        cumulativeActualAmount: number | null | undefined;
        rolloverTargetAmount: number | null | undefined;
      }>;
    }>;
    monthlyAmountsForFlexExpense: {
      __typename: 'BudgetFlexMonthlyAmounts';
      budgetVariability: BudgetVariability;
      monthlyAmounts: Array<{
        __typename: 'BudgetMonthlyAmounts';
        month: string;
        plannedCashFlowAmount: number | null | undefined;
        plannedSetAsideAmount: number | null | undefined;
        actualAmount: number | null | undefined;
        remainingAmount: number | null | undefined;
        previousMonthRolloverAmount: number | null | undefined;
        rolloverType: string | null | undefined;
        cumulativeActualAmount: number | null | undefined;
        rolloverTargetAmount: number | null | undefined;
      }>;
    };
    totalsByMonth: Array<{
      __typename: 'BudgetMonthTotals';
      month: string;
      totalIncome: {
        __typename: 'BudgetTotals';
        actualAmount: number;
        plannedAmount: number;
        previousMonthRolloverAmount: number;
        remainingAmount: number;
      };
      totalExpenses: {
        __typename: 'BudgetTotals';
        actualAmount: number;
        plannedAmount: number;
        previousMonthRolloverAmount: number;
        remainingAmount: number;
      };
      totalFixedExpenses: {
        __typename: 'BudgetTotals';
        actualAmount: number;
        plannedAmount: number;
        previousMonthRolloverAmount: number;
        remainingAmount: number;
      };
      totalNonMonthlyExpenses: {
        __typename: 'BudgetTotals';
        actualAmount: number;
        plannedAmount: number;
        previousMonthRolloverAmount: number;
        remainingAmount: number;
      };
      totalFlexibleExpenses: {
        __typename: 'BudgetTotals';
        actualAmount: number;
        plannedAmount: number;
        previousMonthRolloverAmount: number;
        remainingAmount: number;
      };
    }>;
  };
  categoryGroups: Array<{
    __typename: 'CategoryGroup';
    id: string;
    name: string;
    order: number;
    type: CategoryGroupType;
    budgetVariability: BudgetVariability | null | undefined;
    groupLevelBudgetingEnabled: boolean | null | undefined;
    categories: Array<{
      __typename: 'Category';
      id: string;
      name: string;
      icon: string;
      order: number;
      budgetVariability: BudgetVariability | null | undefined;
      excludeFromBudget: boolean | null | undefined;
      isSystemCategory: boolean;
      updatedAt: string;
      group: {
        __typename: 'CategoryGroup';
        id: string;
        type: CategoryGroupType;
        budgetVariability: BudgetVariability | null | undefined;
        groupLevelBudgetingEnabled: boolean | null | undefined;
      };
      rolloverPeriod:
        | {
            __typename: 'BudgetRolloverPeriod';
            id: string;
            startMonth: string;
            endMonth: string | null | undefined;
            startingBalance: number | null | undefined;
            targetAmount: number | null | undefined;
            frequency: string | null | undefined;
            type: string;
          }
        | null
        | undefined;
    }>;
    rolloverPeriod:
      | {
          __typename: 'BudgetRolloverPeriod';
          id: string;
          type: string;
          startMonth: string;
          endMonth: string | null | undefined;
          startingBalance: number | null | undefined;
          frequency: string | null | undefined;
          targetAmount: number | null | undefined;
        }
      | null
      | undefined;
  }>;
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    name: string;
    archivedAt: string | null | undefined;
    completedAt: string | null | undefined;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
    plannedContributions: Array<{
      __typename: 'GoalPlannedContributionV2';
      id: string;
      month: string;
      amount: number;
    }>;
    monthlyContributionSummaries: Array<{
      __typename: 'GoalContributionMonthlySummary';
      month: string;
      sum: number;
    }>;
  }>;
};

export type Common_CreateBudgetForHouseholdMutationVariables = Exact<{
  input: CreateBudgetMutationInput;
}>;

export type Common_CreateBudgetForHouseholdMutation = {
  __typename: 'Mutation';
  createBudget:
    | {
        __typename: 'CreateBudgetMutation';
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_GetBudgetStatusQueryVariables = Exact<{ [key: string]: never }>;

export type Common_GetBudgetStatusQuery = {
  __typename: 'Query';
  budgetStatus: {
    __typename: 'BudgetStatus';
    hasBudget: boolean;
    hasTransactions: boolean;
    willCreateBudgetFromEmptyDefaultCategories: boolean;
  };
};

export type Common_GetBudgetSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type Common_GetBudgetSettingsQuery = {
  __typename: 'Query';
  budgetSystem: BudgetSystem;
  flexExpenseRolloverPeriod:
    | {
        __typename: 'BudgetRolloverPeriod';
        id: string;
        startMonth: string;
        startingBalance: number | null | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateBudgetSettingsMutationVariables = Exact<{
  input: UpdateBudgetSettingsMutationInput;
}>;

export type Common_UpdateBudgetSettingsMutation = {
  __typename: 'Mutation';
  updateBudgetSettings:
    | {
        __typename: 'UpdateBudgetSettingsMutation';
        budgetSystem: string | null | undefined;
        budgetRolloverPeriod:
          | { __typename: 'BudgetRolloverPeriod'; id: string; startMonth: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateCategoryExcludeFromBudgetMutationVariables = Exact<{
  input: UpdateCategoryInput;
}>;

export type Common_UpdateCategoryExcludeFromBudgetMutation = {
  __typename: 'Mutation';
  updateCategory:
    | {
        __typename: 'UpdateCategoryPayload';
        category:
          | { __typename: 'Category'; id: string; excludeFromBudget: boolean | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CategoryFieldsFragment = {
  __typename: 'Category';
  id: string;
  order: number;
  name: string;
  icon: string;
  systemCategory: string | null | undefined;
  isSystemCategory: boolean;
  isDisabled: boolean;
  group: { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType };
};

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCategoriesQuery = {
  __typename: 'Query';
  categories: Array<{
    __typename: 'Category';
    id: string;
    order: number;
    name: string;
    icon: string;
    systemCategory: string | null | undefined;
    isSystemCategory: boolean;
    isDisabled: boolean;
    group: { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType };
  }>;
};

export type CategoryGroupFieldsFragment = {
  __typename: 'CategoryGroup';
  id: string;
  name: string;
  order: number;
  type: CategoryGroupType;
  color: string | null | undefined;
  groupLevelBudgetingEnabled: boolean | null | undefined;
  budgetVariability: BudgetVariability | null | undefined;
  rolloverPeriod:
    | {
        __typename: 'BudgetRolloverPeriod';
        id: string;
        startMonth: string;
        endMonth: string | null | undefined;
        startingBalance: number | null | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateCategoryGroupMutationVariables = Exact<{
  input: UpdateCategoryGroupInput;
}>;

export type Common_UpdateCategoryGroupMutation = {
  __typename: 'Mutation';
  updateCategoryGroup:
    | {
        __typename: 'UpdateCategoryGroupMutation';
        categoryGroup:
          | {
              __typename: 'CategoryGroup';
              id: string;
              name: string;
              order: number;
              type: CategoryGroupType;
              color: string | null | undefined;
              groupLevelBudgetingEnabled: boolean | null | undefined;
              budgetVariability: BudgetVariability | null | undefined;
              rolloverPeriod:
                | {
                    __typename: 'BudgetRolloverPeriod';
                    id: string;
                    startMonth: string;
                    endMonth: string | null | undefined;
                    startingBalance: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_CreateCategoryGroupMutationVariables = Exact<{
  input: CreateCategoryGroupInput;
}>;

export type Common_CreateCategoryGroupMutation = {
  __typename: 'Mutation';
  createCategoryGroup:
    | {
        __typename: 'CreateCategoryGroupMutation';
        categoryGroup:
          | {
              __typename: 'CategoryGroup';
              id: string;
              name: string;
              order: number;
              type: CategoryGroupType;
              color: string | null | undefined;
              groupLevelBudgetingEnabled: boolean | null | undefined;
              budgetVariability: BudgetVariability | null | undefined;
              rolloverPeriod:
                | {
                    __typename: 'BudgetRolloverPeriod';
                    id: string;
                    startMonth: string;
                    endMonth: string | null | undefined;
                    startingBalance: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCategoryGroupQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  includeDisabledSystemCategories: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetCategoryGroupQuery = {
  __typename: 'Query';
  categoryGroup:
    | {
        __typename: 'CategoryGroup';
        id: string;
        name: string;
        order: number;
        type: CategoryGroupType;
        color: string | null | undefined;
        groupLevelBudgetingEnabled: boolean | null | undefined;
        budgetVariability: BudgetVariability | null | undefined;
        categories: Array<{ __typename: 'Category'; id: string; name: string; icon: string }>;
        rolloverPeriod:
          | {
              __typename: 'BudgetRolloverPeriod';
              id: string;
              startMonth: string;
              endMonth: string | null | undefined;
              startingBalance: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCategoryGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCategoryGroupsQuery = {
  __typename: 'Query';
  categoryGroups: Array<{
    __typename: 'CategoryGroup';
    id: string;
    name: string;
    order: number;
    type: CategoryGroupType;
    color: string | null | undefined;
    groupLevelBudgetingEnabled: boolean | null | undefined;
    budgetVariability: BudgetVariability | null | undefined;
    rolloverPeriod:
      | {
          __typename: 'BudgetRolloverPeriod';
          id: string;
          startMonth: string;
          endMonth: string | null | undefined;
          startingBalance: number | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type Common_DeleteCategoryGroupMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  moveToGroupId: InputMaybe<Scalars['UUID']['input']>;
}>;

export type Common_DeleteCategoryGroupMutation = {
  __typename: 'Mutation';
  deleteCategoryGroup:
    | {
        __typename: 'DeleteCategoryGroupMutation';
        deleted: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCredentialInstitutionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCredentialInstitutionsQuery = {
  __typename: 'Query';
  credentials: Array<{
    __typename: 'Credential';
    id: string;
    institution: {
      __typename: 'Institution';
      id: string;
      name: string;
      plaidInstitutionId: string | null | undefined;
      finicityInstitutionId: string | null | undefined;
      preferredDataProvider: DataProviderLegacy;
      mxInstitutionId: string | null | undefined;
      logo: string | null | undefined;
    };
  }>;
};

export type Common_UpdateCredentialMutationVariables = Exact<{
  input: UpdateCredentialMutationInput;
}>;

export type Common_UpdateCredentialMutation = {
  __typename: 'Mutation';
  updateCredential:
    | {
        __typename: 'UpdateCredentialMutation';
        credential:
          | { __typename: 'Credential'; id: string; updateRequired: boolean }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_DisconnectCredentialsMutationVariables = Exact<{
  input: DisconnectCredentialsFromDataProviderInput;
}>;

export type Common_DisconnectCredentialsMutation = {
  __typename: 'Mutation';
  disconnectCredentialsFromDataProvider:
    | {
        __typename: 'DisconnectCredentialsFromDataProviderMutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_FixCoinbaseCredentialMutationVariables = Exact<{
  input: FixCoinbaseCredentialInput;
}>;

export type Common_FixCoinbaseCredentialMutation = {
  __typename: 'Mutation';
  fixCoinbaseCredential:
    | {
        __typename: 'FixCoinbaseCredentialMutation';
        credential:
          | {
              __typename: 'Credential';
              id: string;
              accounts: Array<{ __typename: 'Account'; id: string }>;
            }
          | null
          | undefined;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PayloadErrorFieldsFragment = {
  __typename: 'PayloadError';
  message: string | null | undefined;
  code: ErrorCode | null | undefined;
  fieldErrors:
    | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
    | null
    | undefined;
};

export type Common_CreateFinicityConnectUrlMutationVariables = Exact<{
  finicityInstitutionId?: InputMaybe<Scalars['String']['input']>;
}>;

export type Common_CreateFinicityConnectUrlMutation = {
  __typename: 'Mutation';
  createFinicityConnectUrl:
    | {
        __typename: 'CreateFinicityConnectUrlMutation';
        url: string | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_CreateFinicityConnectFixUrlMutationVariables = Exact<{
  credentialId: Scalars['UUID']['input'];
}>;

export type Common_CreateFinicityConnectFixUrlMutation = {
  __typename: 'Mutation';
  createFinicityConnectFixUrl:
    | {
        __typename: 'CreateFinicityConnectFixUrlMutation';
        url: string | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_CreateFinicityCredentialsMutationVariables = Exact<{ [key: string]: never }>;

export type Common_CreateFinicityCredentialsMutation = {
  __typename: 'Mutation';
  createFinicityCredentials:
    | {
        __typename: 'CreateFinicityCredentialsMutation';
        credentials: Array<{
          __typename: 'Credential';
          id: string;
          accounts: Array<{ __typename: 'Account'; id: string }>;
        }>;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetGoalTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetGoalTypesQuery = {
  __typename: 'Query';
  goalTypes: Array<{ __typename: 'GoalType'; display: string; icon: string; name: string }>;
};

export type GoalPlannedContributionFieldsFragment = {
  __typename: 'GoalPlannedContribution';
  id: string;
  amount: number;
  startDate: string;
  goal: { __typename: 'Goal'; id: string };
};

export type Common_CreateGoalMutationVariables = Exact<{
  input: CreateGoalMutationInput;
}>;

export type Common_CreateGoalMutation = {
  __typename: 'Mutation';
  createGoal:
    | {
        __typename: 'CreateGoalMutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        goal: {
          __typename: 'Goal';
          id: string;
          name: string;
          icon: string;
          targetAmount: number;
          targetDate: string;
          plannedMonthlyContribution: number;
          goalType: GoalGoalType;
        };
      }
    | null
    | undefined;
};

export type Common_UpdateGoalMutationVariables = Exact<{
  input: UpdateGoalMutationInput;
}>;

export type Common_UpdateGoalMutation = {
  __typename: 'Mutation';
  updateGoal:
    | {
        __typename: 'UpdateGoalMutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        goal:
          | {
              __typename: 'Goal';
              id: string;
              name: string;
              icon: string;
              targetAmount: number;
              targetDate: string;
              plannedMonthlyContribution: number;
              goalType: GoalGoalType;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_DeleteGoalMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Common_DeleteGoalMutation = {
  __typename: 'Mutation';
  deleteGoal:
    | {
        __typename: 'DeleteGoalMutation';
        deleted: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetGoalBalancesQueryVariables = Exact<{ [key: string]: never }>;

export type GetGoalBalancesQuery = {
  __typename: 'Query';
  goalBalances: {
    __typename: 'GoalAllocationBalances';
    availableBalance: number;
    totalSavingsBalance: number;
    totalContributedBalance: number;
  };
};

export type Common_UpdateOrCreateGoalPlannedContributionMutationVariables = Exact<{
  input: UpdateOrCreateGoalPlannedContributionMutationInput;
}>;

export type Common_UpdateOrCreateGoalPlannedContributionMutation = {
  __typename: 'Mutation';
  updateOrCreateGoalPlannedContribution:
    | {
        __typename: 'UpdateOrCreateGoalPlannedContributionMutation';
        goalPlannedContribution:
          | {
              __typename: 'GoalPlannedContribution';
              id: string;
              amount: number;
              startDate: string;
              goal: { __typename: 'Goal'; id: string };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateGoalContributionMutationVariables = Exact<{
  input: UpdateGoalContributionMutationInput;
}>;

export type Common_UpdateGoalContributionMutation = {
  __typename: 'Mutation';
  updateGoalContribution:
    | {
        __typename: 'UpdateGoalContributionMutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        goalContribution:
          | {
              __typename: 'GoalContribution';
              id: string;
              date: string;
              amount: number;
              type: GoalContributionType;
              label: string;
              notes: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_DeleteGoalContributionMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Common_DeleteGoalContributionMutation = {
  __typename: 'Mutation';
  deleteGoalContribution:
    | {
        __typename: 'DeleteGoalContributionMutation';
        deleted: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetDashboardGoalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDashboardGoalsQuery = {
  __typename: 'Query';
  goals: Array<{
    __typename: 'Goal';
    id: string;
    name: string;
    icon: string;
    targetAmount: number;
    targetDate: string;
    contributedBalance: number;
    completedAt: string | null | undefined;
  }>;
  goalBalances: {
    __typename: 'GoalAllocationBalances';
    availableBalance: number;
    totalSavingsBalance: number;
    totalContributedBalance: number;
  };
};

export type Common_CreateGoalContributionsMutationVariables = Exact<{
  input:
    | Array<InputMaybe<CreateGoalContributionsMutationInput>>
    | InputMaybe<CreateGoalContributionsMutationInput>;
}>;

export type Common_CreateGoalContributionsMutation = {
  __typename: 'Mutation';
  createGoalContributions:
    | {
        __typename: 'CreateGoalContributionsMutation';
        goalContributions: Array<{
          __typename: 'GoalContribution';
          id: string;
          amount: number;
          goal: {
            __typename: 'Goal';
            id: string;
            contributedBalance: number;
            name: string;
            icon: string;
            targetDate: string;
            targetAmount: number;
            completedAt: string | null | undefined;
            projectedCompletionDateGivenContribution: string | null | undefined;
          };
        }>;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateHasSeenGoalsTourMutationVariables = Exact<{
  input: UpdateUserProfileInput;
}>;

export type Common_UpdateHasSeenGoalsTourMutation = {
  __typename: 'Mutation';
  updateUserProfile:
    | {
        __typename: 'UpdateUserProfile';
        userProfile:
          | { __typename: 'UserProfile'; id: string; hasSeenGoalsFeatureTour: boolean }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetGoalProjectedCompletionQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  proposedContributionAmount: Scalars['Float']['input'];
}>;

export type GetGoalProjectedCompletionQuery = {
  __typename: 'Query';
  goal: {
    __typename: 'Goal';
    id: string;
    projectedCompletionDateGivenContribution: string | null | undefined;
  };
};

export type Common_CreateOrUpdateGoalV2PlannedContributionMutationVariables = Exact<{
  input: CreateOrUpdateGoalPlannedContributionInput;
}>;

export type Common_CreateOrUpdateGoalV2PlannedContributionMutation = {
  __typename: 'Mutation';
  createOrUpdateGoalPlannedContribution:
    | {
        __typename: 'CreateOrUpdateGoalPlannedContribution';
        goalPlannedContribution:
          | { __typename: 'GoalPlannedContributionV2'; id: string; amount: number; month: string }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_CreateManualHoldingMutationVariables = Exact<{
  input: CreateManualHoldingInput;
}>;

export type Common_CreateManualHoldingMutation = {
  __typename: 'Mutation';
  createManualHolding:
    | {
        __typename: 'CreateManualHolding';
        holding:
          | { __typename: 'Holding'; id: string; ticker: string | null | undefined }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type HouseholdFieldsFragment = {
  __typename: 'Household';
  address: string;
  city: string;
  id: string;
  name: string;
  state: string;
  zipCode: string;
};

export type GetMyHouseholdQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyHouseholdQuery = {
  __typename: 'Query';
  myHousehold: {
    __typename: 'Household';
    address: string;
    city: string;
    id: string;
    name: string;
    state: string;
    zipCode: string;
  };
};

export type Common_UpdateMyHouseholdMutationVariables = Exact<{
  input: UpdateMyHouseholdInput;
}>;

export type Common_UpdateMyHouseholdMutation = {
  __typename: 'Mutation';
  updateMyHousehold:
    | {
        __typename: 'UpdateMyHousehold';
        household:
          | {
              __typename: 'Household';
              address: string;
              city: string;
              id: string;
              name: string;
              state: string;
              zipCode: string;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_DeleteHouseholdMutationVariables = Exact<{
  input: DeleteHouseholdMutationInput;
}>;

export type Common_DeleteHouseholdMutation = {
  __typename: 'Mutation';
  deleteHousehold:
    | {
        __typename: 'DeleteHouseholdMutation';
        deleted: boolean;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_CreateInstitutionIssueReportMutationVariables = Exact<{
  input: CreateInstitutionIssueReportMutationInput;
}>;

export type Common_CreateInstitutionIssueReportMutation = {
  __typename: 'Mutation';
  createInstitutionIssueReport:
    | {
        __typename: 'CreateInstitutionIssueReportMutationPayload';
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TopInstitutionsQueryVariables = Exact<{
  platform: InputMaybe<Platform>;
  limit: InputMaybe<Scalars['Int']['input']>;
}>;

export type TopInstitutionsQuery = {
  __typename: 'Query';
  topInstitutions: Array<{
    __typename: 'Institution';
    id: string;
    name: string;
    url: string;
    logo: string | null | undefined;
    status: InstitutionStatus | null | undefined;
    hasIssuesReported: boolean;
    hasIssuesReportedMessage: string;
    linkFlowWarnBeforeConnecting: boolean;
    linkFlowWarningMessage: string | null | undefined;
    linkFlowWarningTitle: string | null | undefined;
    plaidInstitutionId: string | null | undefined;
    finicityInstitutionId: string | null | undefined;
    mxInstitutionId: string | null | undefined;
    preferredDataProvider: DataProviderLegacy;
    firstBackupDataProvider: DataProviderLegacy | null | undefined;
    largeLogo: { __typename: 'FileType'; url: string | null | undefined } | null | undefined;
  }>;
};

export type GetInstitutionLogoByPlaidIdQueryVariables = Exact<{
  plaidId: Scalars['String']['input'];
}>;

export type GetInstitutionLogoByPlaidIdQuery = {
  __typename: 'Query';
  institution:
    | { __typename: 'Institution'; id: string; logo: string | null | undefined }
    | null
    | undefined;
};

export type GetInstitutionByProviderIdQueryVariables = Exact<{
  id: InputMaybe<Scalars['String']['input']>;
  plaidId: InputMaybe<Scalars['String']['input']>;
  finicityId: InputMaybe<Scalars['String']['input']>;
  mxId: InputMaybe<Scalars['String']['input']>;
}>;

export type GetInstitutionByProviderIdQuery = {
  __typename: 'Query';
  institution:
    | {
        __typename: 'Institution';
        id: string;
        name: string;
        logo: string | null | undefined;
        url: string;
        status: InstitutionStatus | null | undefined;
        preferredDataProvider: DataProviderLegacy;
        firstBackupDataProvider: DataProviderLegacy | null | undefined;
        plaidInstitutionId: string | null | undefined;
        finicityInstitutionId: string | null | undefined;
        mxInstitutionId: string | null | undefined;
        largeLogo: { __typename: 'FileType'; url: string | null | undefined } | null | undefined;
      }
    | null
    | undefined;
};

export type Common_RequestInstitutionMutationVariables = Exact<{
  input: RequestInstitutionInput;
}>;

export type Common_RequestInstitutionMutation = {
  __typename: 'Mutation';
  requestInstitution:
    | { __typename: 'RequestInstitution'; success: boolean | null | undefined }
    | null
    | undefined;
};

export type SecuritySearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  limit: InputMaybe<Scalars['Int']['input']>;
  orderByPopularity: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SecuritySearchQuery = {
  __typename: 'Query';
  securities:
    | Array<{
        __typename: 'Security';
        id: string;
        name: string;
        type: string;
        logo: string | null | undefined;
        ticker: string | null | undefined;
        typeDisplay: string;
        currentPrice: number | null | undefined;
        closingPrice: number | null | undefined;
        oneDayChangeDollars: number | null | undefined;
        oneDayChangePercent: number | null | undefined;
      }>
    | null
    | undefined;
};

export type GetHoldingSummarySecurityDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetHoldingSummarySecurityDetailsQuery = {
  __typename: 'Query';
  security:
    | {
        __typename: 'Security';
        id: string;
        name: string;
        logo: string | null | undefined;
        ticker: string | null | undefined;
        closingPrice: number | null | undefined;
        currentPrice: number | null | undefined;
        oneDayChangeDollars: number | null | undefined;
      }
    | null
    | undefined;
};

export type Common_DeleteHoldingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type Common_DeleteHoldingMutation = {
  __typename: 'Mutation';
  deleteHolding:
    | {
        __typename: 'DeleteHolding';
        deleted: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateHoldingMutationVariables = Exact<{
  input: UpdateHoldingInput;
}>;

export type Common_UpdateHoldingMutation = {
  __typename: 'Mutation';
  updateHolding:
    | {
        __typename: 'UpdateHolding';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        holding: { __typename: 'Holding'; id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type GetHoldingDetailsFormSecurityDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetHoldingDetailsFormSecurityDetailsQuery = {
  __typename: 'Query';
  security:
    | {
        __typename: 'Security';
        id: string;
        name: string;
        logo: string | null | undefined;
        ticker: string | null | undefined;
        type: string;
        closingPrice: number | null | undefined;
        currentPrice: number | null | undefined;
        oneDayChangeDollars: number | null | undefined;
      }
    | null
    | undefined;
};

export type GetMerchantsSearchQueryVariables = Exact<{
  search: InputMaybe<Scalars['String']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetMerchantsSearchQuery = {
  __typename: 'Query';
  merchants: Array<{ __typename: 'Merchant'; id: string; name: string; transactionCount: number }>;
};

export type GetMerchantsSearchWithLogoQueryVariables = Exact<{
  search: InputMaybe<Scalars['String']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetMerchantsSearchWithLogoQuery = {
  __typename: 'Query';
  merchants: Array<{
    __typename: 'Merchant';
    id: string;
    name: string;
    transactionCount: number;
    logoUrl: string | null | undefined;
  }>;
};

export type Common_CreateMxConnectUrlMutationVariables = Exact<{
  mxInstitutionId?: InputMaybe<Scalars['String']['input']>;
  clientRedirectUrl?: InputMaybe<Scalars['String']['input']>;
  isDarkMode: InputMaybe<Scalars['Boolean']['input']>;
  isMobileWebview: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Common_CreateMxConnectUrlMutation = {
  __typename: 'Mutation';
  createMxConnectUrl:
    | {
        __typename: 'CreateMXConnectUrlMutation';
        url: string | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_CreateMxConnectFixUrlMutationVariables = Exact<{
  credentialId: Scalars['UUID']['input'];
  isDarkMode: InputMaybe<Scalars['Boolean']['input']>;
  isMobileWebview: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Common_CreateMxConnectFixUrlMutation = {
  __typename: 'Mutation';
  createMxConnectFixUrl:
    | {
        __typename: 'CreateMXConnectFixUrlMutation';
        url: string | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_CreateMxCredentialsMutationVariables = Exact<{ [key: string]: never }>;

export type Common_CreateMxCredentialsMutation = {
  __typename: 'Mutation';
  createMxCredentials:
    | {
        __typename: 'CreateMXCredentialsMutation';
        credentials: Array<{
          __typename: 'Credential';
          id: string;
          accounts: Array<{ __typename: 'Account'; id: string }>;
        }>;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type NotificationPreferenceFieldsFragment = {
  __typename: 'NotificationPreference';
  id: string;
  group: string | null | undefined;
  type: string | null | undefined;
  additionalPreferences: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
  additionalPreferencesMeta: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
  title: string | null | undefined;
  description: string | null | undefined;
  EMAIL: boolean;
  PUSH: boolean;
};

export type GetNotificationPreferenceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetNotificationPreferenceQuery = {
  __typename: 'Query';
  notificationPreference:
    | {
        __typename: 'NotificationPreference';
        id: string;
        group: string | null | undefined;
        type: string | null | undefined;
        additionalPreferences: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
        additionalPreferencesMeta: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
        title: string | null | undefined;
        description: string | null | undefined;
        EMAIL: boolean;
        PUSH: boolean;
      }
    | null
    | undefined;
};

export type GetNotificationPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetNotificationPreferencesQuery = {
  __typename: 'Query';
  notificationPreferences:
    | Array<{
        __typename: 'NotificationPreference';
        id: string;
        group: string | null | undefined;
        type: string | null | undefined;
        additionalPreferences: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
        additionalPreferencesMeta: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
        title: string | null | undefined;
        description: string | null | undefined;
        EMAIL: boolean;
        PUSH: boolean;
      }>
    | null
    | undefined;
};

export type Common_UpdateNotificationPreferencesMutationVariables = Exact<{
  input: UpdateNotificationPreferencesInput;
}>;

export type Common_UpdateNotificationPreferencesMutation = {
  __typename: 'Mutation';
  updateNotificationPreferences:
    | {
        __typename: 'UpdateNotificationPreferences';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        notificationPreference:
          | {
              __typename: 'NotificationPreference';
              id: string;
              group: string | null | undefined;
              type: string | null | undefined;
              additionalPreferences: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
              additionalPreferencesMeta:
                | { [key: string]: Scalars['GenericScalar'] }
                | null
                | undefined;
              title: string | null | undefined;
              description: string | null | undefined;
              EMAIL: boolean;
              PUSH: boolean;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetOnboardingFinancialGoalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOnboardingFinancialGoalsQuery = {
  __typename: 'Query';
  onboardingFinancialGoals: Array<{
    __typename: 'OnboardingFinancialGoal';
    id: string;
    name: string;
  }>;
  userOnboardingFinancialGoals: Array<{
    __typename: 'UserOnboardingFinancialGoal';
    id: string;
    otherText: string | null | undefined;
    onboardingFinancialGoal: { __typename: 'OnboardingFinancialGoal'; id: string };
  }>;
};

export type Common_SetUserOnboardingFinancialGoalsMutationVariables = Exact<{
  input: Array<SetUserOnboardingFinancialGoalInput> | SetUserOnboardingFinancialGoalInput;
}>;

export type Common_SetUserOnboardingFinancialGoalsMutation = {
  __typename: 'Mutation';
  setUserOnboardingFinancialGoals:
    | {
        __typename: 'SetUserOnboardingFinancialGoalMutation';
        userOnboardingFinancialGoals: Array<{
          __typename: 'UserOnboardingFinancialGoal';
          id: string;
          onboardingFinancialGoal: { __typename: 'OnboardingFinancialGoal'; id: string };
        }>;
      }
    | null
    | undefined;
};

export type Common_CreatePlaidLinkTokenMutationVariables = Exact<{
  credentialId: InputMaybe<Scalars['UUID']['input']>;
  linkCustomizationName: InputMaybe<Scalars['String']['input']>;
  plaidInstitutionId: InputMaybe<Scalars['String']['input']>;
  redirectUri: InputMaybe<Scalars['String']['input']>;
  androidPackageName: InputMaybe<Scalars['String']['input']>;
}>;

export type Common_CreatePlaidLinkTokenMutation = {
  __typename: 'Mutation';
  createPlaidLinkToken:
    | {
        __typename: 'CreatePlaidLinkTokenMutation';
        linkToken: string | null | undefined;
        error: string | null | undefined;
      }
    | null
    | undefined;
};

export type Common_GetJointPlanningDataQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type Common_GetJointPlanningDataQuery = {
  __typename: 'Query';
  budgetSystem: BudgetSystem;
  budgetData: {
    __typename: 'BudgetData';
    monthlyAmountsByCategory: Array<{
      __typename: 'BudgetCategoryMonthlyAmounts';
      category: { __typename: 'Category'; id: string };
      monthlyAmounts: Array<{
        __typename: 'BudgetMonthlyAmounts';
        month: string;
        plannedCashFlowAmount: number | null | undefined;
        plannedSetAsideAmount: number | null | undefined;
        actualAmount: number | null | undefined;
        remainingAmount: number | null | undefined;
        previousMonthRolloverAmount: number | null | undefined;
        rolloverType: string | null | undefined;
        cumulativeActualAmount: number | null | undefined;
        rolloverTargetAmount: number | null | undefined;
      }>;
    }>;
    monthlyAmountsByCategoryGroup: Array<{
      __typename: 'BudgetCategoryGroupMonthlyAmounts';
      categoryGroup: { __typename: 'CategoryGroup'; id: string };
      monthlyAmounts: Array<{
        __typename: 'BudgetMonthlyAmounts';
        month: string;
        plannedCashFlowAmount: number | null | undefined;
        plannedSetAsideAmount: number | null | undefined;
        actualAmount: number | null | undefined;
        remainingAmount: number | null | undefined;
        previousMonthRolloverAmount: number | null | undefined;
        rolloverType: string | null | undefined;
        cumulativeActualAmount: number | null | undefined;
        rolloverTargetAmount: number | null | undefined;
      }>;
    }>;
    monthlyAmountsForFlexExpense: {
      __typename: 'BudgetFlexMonthlyAmounts';
      budgetVariability: BudgetVariability;
      monthlyAmounts: Array<{
        __typename: 'BudgetMonthlyAmounts';
        month: string;
        plannedCashFlowAmount: number | null | undefined;
        plannedSetAsideAmount: number | null | undefined;
        actualAmount: number | null | undefined;
        remainingAmount: number | null | undefined;
        previousMonthRolloverAmount: number | null | undefined;
        rolloverType: string | null | undefined;
        cumulativeActualAmount: number | null | undefined;
        rolloverTargetAmount: number | null | undefined;
      }>;
    };
    totalsByMonth: Array<{
      __typename: 'BudgetMonthTotals';
      month: string;
      totalIncome: {
        __typename: 'BudgetTotals';
        actualAmount: number;
        plannedAmount: number;
        previousMonthRolloverAmount: number;
        remainingAmount: number;
      };
      totalExpenses: {
        __typename: 'BudgetTotals';
        actualAmount: number;
        plannedAmount: number;
        previousMonthRolloverAmount: number;
        remainingAmount: number;
      };
      totalFixedExpenses: {
        __typename: 'BudgetTotals';
        actualAmount: number;
        plannedAmount: number;
        previousMonthRolloverAmount: number;
        remainingAmount: number;
      };
      totalNonMonthlyExpenses: {
        __typename: 'BudgetTotals';
        actualAmount: number;
        plannedAmount: number;
        previousMonthRolloverAmount: number;
        remainingAmount: number;
      };
      totalFlexibleExpenses: {
        __typename: 'BudgetTotals';
        actualAmount: number;
        plannedAmount: number;
        previousMonthRolloverAmount: number;
        remainingAmount: number;
      };
    }>;
  };
  categoryGroups: Array<{
    __typename: 'CategoryGroup';
    id: string;
    name: string;
    order: number;
    type: CategoryGroupType;
    budgetVariability: BudgetVariability | null | undefined;
    groupLevelBudgetingEnabled: boolean | null | undefined;
    categories: Array<{
      __typename: 'Category';
      id: string;
      name: string;
      icon: string;
      order: number;
      budgetVariability: BudgetVariability | null | undefined;
      excludeFromBudget: boolean | null | undefined;
      isSystemCategory: boolean;
      updatedAt: string;
      group: {
        __typename: 'CategoryGroup';
        id: string;
        type: CategoryGroupType;
        budgetVariability: BudgetVariability | null | undefined;
        groupLevelBudgetingEnabled: boolean | null | undefined;
      };
      rolloverPeriod:
        | {
            __typename: 'BudgetRolloverPeriod';
            id: string;
            startMonth: string;
            endMonth: string | null | undefined;
            startingBalance: number | null | undefined;
            targetAmount: number | null | undefined;
            frequency: string | null | undefined;
            type: string;
          }
        | null
        | undefined;
    }>;
    rolloverPeriod:
      | {
          __typename: 'BudgetRolloverPeriod';
          id: string;
          type: string;
          startMonth: string;
          endMonth: string | null | undefined;
          startingBalance: number | null | undefined;
          frequency: string | null | undefined;
          targetAmount: number | null | undefined;
        }
      | null
      | undefined;
  }>;
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    name: string;
    archivedAt: string | null | undefined;
    completedAt: string | null | undefined;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
    plannedContributions: Array<{
      __typename: 'GoalPlannedContributionV2';
      id: string;
      month: string;
      amount: number;
    }>;
    monthlyContributionSummaries: Array<{
      __typename: 'GoalContributionMonthlySummary';
      month: string;
      sum: number;
    }>;
  }>;
};

export type Common_RecurringUpdateMerchantMutationVariables = Exact<{
  input: UpdateMerchantInput;
}>;

export type Common_RecurringUpdateMerchantMutation = {
  __typename: 'Mutation';
  updateMerchant:
    | {
        __typename: 'UpdateMerchantMutation';
        merchant: { __typename: 'Merchant'; id: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_RecurringUpdateLiabilityStatementMutationVariables = Exact<{
  creditReportLiabilityStatementId: Scalars['ID']['input'];
  userBillAmount: InputMaybe<Scalars['Decimal']['input']>;
  revertToOriginal: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Common_RecurringUpdateLiabilityStatementMutation = {
  __typename: 'Mutation';
  updateBillAmount:
    | {
        __typename: 'UpdateBillAmountMutation';
        creditReportLiabilityStatement:
          | { __typename: 'LiabilityStatement'; id: string; billAmount: number | null | undefined }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_MarkRecurringLiabilityStatementAsPaidMutationVariables = Exact<{
  creditReportLiabilityStatementId: Scalars['ID']['input'];
  isPaid: Scalars['Boolean']['input'];
}>;

export type Common_MarkRecurringLiabilityStatementAsPaidMutation = {
  __typename: 'Mutation';
  updateBillPaid:
    | {
        __typename: 'UpdateBillPaidMutation';
        creditReportLiabilityStatement:
          | {
              __typename: 'LiabilityStatement';
              id: string;
              billAmount: number | null | undefined;
              userMarkedPaidAt: string | null | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_MarkAsNotRecurringMutationVariables = Exact<{
  streamId: Scalars['ID']['input'];
}>;

export type Common_MarkAsNotRecurringMutation = {
  __typename: 'Mutation';
  markStreamAsNotRecurring:
    | {
        __typename: 'MarkStreamAsNotRecurringMutation';
        success: boolean;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_CreateTransactionRuleMutationV2MutationVariables = Exact<{
  input: CreateTransactionRuleInput;
}>;

export type Common_CreateTransactionRuleMutationV2Mutation = {
  __typename: 'Mutation';
  createTransactionRuleV2:
    | {
        __typename: 'CreateTransactionRuleV2Mutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateTransactionRuleMutationV2MutationVariables = Exact<{
  input: UpdateTransactionRuleInput;
}>;

export type Common_UpdateTransactionRuleMutationV2Mutation = {
  __typename: 'Mutation';
  updateTransactionRuleV2:
    | {
        __typename: 'UpdateTransactionRuleV2Mutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_DeleteTransactionRuleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type Common_DeleteTransactionRuleMutation = {
  __typename: 'Mutation';
  deleteTransactionRule:
    | {
        __typename: 'DeleteTransactionRuleV2Mutation';
        deleted: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_GetMonthlySnapshotsQueryVariables = Exact<{
  startDate: InputMaybe<Scalars['Date']['input']>;
}>;

export type Common_GetMonthlySnapshotsQuery = {
  __typename: 'Query';
  monthlySnapshotsByAccountType: Array<{
    __typename: 'SnapshotByType';
    accountType: string;
    month: string;
    balance: number;
  }>;
  accountTypes: Array<{ __typename: 'AccountType'; name: string; group: string }>;
};

export type Common_GetSnapshotsByAccountTypeQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  timeframe: Timeframe;
}>;

export type Common_GetSnapshotsByAccountTypeQuery = {
  __typename: 'Query';
  snapshotsByAccountType: Array<{
    __typename: 'SnapshotByType';
    accountType: string;
    month: string;
    balance: number;
  }>;
  accountTypes: Array<{ __typename: 'AccountType'; name: string; group: string }>;
};

export type Common_GetDisplayBalanceAtDateQueryVariables = Exact<{
  date: Scalars['Date']['input'];
}>;

export type Common_GetDisplayBalanceAtDateQuery = {
  __typename: 'Query';
  accounts: Array<{
    __typename: 'Account';
    id: string;
    displayBalance: number | null | undefined;
    includeInNetWorth: boolean;
    type: { __typename: 'AccountType'; name: string };
  }>;
};

export type Common_DeleteSyncedSnapshotsMutationVariables = Exact<{
  input: DeleteSyncedSnapshotsInput;
}>;

export type Common_DeleteSyncedSnapshotsMutation = {
  __typename: 'Mutation';
  deleteSyncedSnapshots:
    | {
        __typename: 'DeleteSyncedSnapshotsMutation';
        success: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_CreateSpinwheelTokenMutationVariables = Exact<{ [key: string]: never }>;

export type Common_CreateSpinwheelTokenMutation = {
  __typename: 'Mutation';
  createSpinwheelToken:
    | {
        __typename: 'CreateSpinwheelTokenMutation';
        token: string;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_GetSpinwheelCreditReportQueryVariables = Exact<{ [key: string]: never }>;

export type Common_GetSpinwheelCreditReportQuery = {
  __typename: 'Query';
  spinwheelUser:
    | {
        __typename: 'SpinwheelUser';
        onboardingStatus: OnboardingStatus;
        onboardingErrorMessage: string | null | undefined;
        user: { __typename: 'User'; id: string; name: string };
      }
    | null
    | undefined;
  creditReportLiabilityAccounts: Array<
    | {
        __typename: 'CreditReportLiabilityAccount';
        spinwheelLiabilityId: string;
        liabilityType: string;
        isOpen: boolean | null | undefined;
        currentTotalBalance: number | null | undefined;
        description: string | null | undefined;
        termsFrequency: string | null | undefined;
        accountType: string | null | undefined;
        account: { __typename: 'Account'; id: string } | null | undefined;
        spinwheelUser: {
          __typename: 'SpinwheelUser';
          user: {
            __typename: 'User';
            id: string;
            name: string;
            profilePictureUrl: string | null | undefined;
          };
        };
        recurringTransactionStream:
          | {
              __typename: 'RecurringTransactionStream';
              frequency: string;
              reviewStatus: RecurringStreamReviewStatus | null | undefined;
              baseDate: string;
              dayOfTheMonth: number | null | undefined;
            }
          | null
          | undefined;
        lastStatement: { __typename: 'LiabilityStatement'; dueDate: string } | null | undefined;
      }
    | null
    | undefined
  >;
};

export type Web_CreateSpinwheelAccountMappingMutationVariables = Exact<{
  input: SpinwheelAccountMappingInput;
}>;

export type Web_CreateSpinwheelAccountMappingMutation = {
  __typename: 'Mutation';
  createSpinwheelAccountMapping:
    | {
        __typename: 'CreateSpinwheelAccountMappingMutation';
        success: boolean;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_DisconnectSpinwheelMutationVariables = Exact<{ [key: string]: never }>;

export type Common_DisconnectSpinwheelMutation = {
  __typename: 'Mutation';
  disconnectSpinwheel:
    | {
        __typename: 'DisconnectSpinwheel';
        isDisconnected: boolean;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TransactionFieldsFragment = {
  __typename: 'Transaction';
  id: string;
  amount: number;
  pending: boolean;
  date: string;
  hideFromReports: boolean;
  plaidName: string | null | undefined;
  notes: string | null | undefined;
  dataProviderDescription: string | null | undefined;
  attachments: Array<{ __typename: 'TransactionAttachment'; id: string }>;
  account: {
    __typename: 'Account';
    id: string;
    displayName: string;
    syncDisabled: boolean;
    deactivatedAt: string | null | undefined;
    isHidden: boolean;
    isAsset: boolean;
    mask: string | null | undefined;
    createdAt: string;
    updatedAt: string;
    displayLastUpdatedAt: string;
    currentBalance: number | null | undefined;
    displayBalance: number | null | undefined;
    includeInNetWorth: boolean;
    hideFromList: boolean;
    hideTransactionsFromReports: boolean;
    includeBalanceInNetWorth: boolean;
    includeInGoalBalance: boolean;
    dataProvider: string | null | undefined;
    dataProviderAccountId: string | null | undefined;
    isManual: boolean;
    transactionsCount: number;
    holdingsCount: number;
    manualInvestmentsTrackingMethod: string | null | undefined;
    order: number;
    icon: string;
    logoUrl: string | null | undefined;
    type: { __typename: 'AccountType'; name: string; display: string; group: string };
    subtype: { __typename: 'AccountSubtype'; name: string; display: string };
    credential:
      | {
          __typename: 'Credential';
          id: string;
          updateRequired: boolean;
          disconnectedFromDataProviderAt: string | null | undefined;
          dataProvider: DataProviderLegacy;
          institution: {
            __typename: 'Institution';
            id: string;
            plaidInstitutionId: string | null | undefined;
            name: string;
            status: InstitutionStatus | null | undefined;
            logo: string | null | undefined;
          };
        }
      | null
      | undefined;
    institution:
      | {
          __typename: 'Institution';
          id: string;
          name: string;
          logo: string | null | undefined;
          primaryColor: string;
          url: string;
        }
      | null
      | undefined;
  };
  category: {
    __typename: 'Category';
    id: string;
    order: number;
    name: string;
    icon: string;
    systemCategory: string | null | undefined;
    isSystemCategory: boolean;
    isDisabled: boolean;
    group: { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType };
  };
  merchant: { __typename: 'Merchant'; id: string; name: string; transactionsCount: number };
};

export type TransactionGoalFieldsFragment = {
  __typename: 'Transaction';
  goal:
    | {
        __typename: 'GoalV2';
        id: string;
        name: string;
        imageStorageProvider: string | null | undefined;
        imageStorageProviderId: string | null | undefined;
      }
    | null
    | undefined;
  account: {
    __typename: 'Account';
    id: string;
    displayName: string;
    goalAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      goal: {
        __typename: 'GoalV2';
        id: string;
        name: string;
        imageStorageProvider: string | null | undefined;
        imageStorageProviderId: string | null | undefined;
        priority: number;
        archivedAt: string | null | undefined;
      };
    }>;
  };
};

export type GetTransactionsQueryVariables = Exact<{
  offset: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  filters: InputMaybe<TransactionFilterInput>;
}>;

export type GetTransactionsQuery = {
  __typename: 'Query';
  allTransactions: {
    __typename: 'TransactionList';
    totalCount: number;
    results: Array<{
      __typename: 'Transaction';
      id: string;
      amount: number;
      pending: boolean;
      date: string;
      hideFromReports: boolean;
      plaidName: string | null | undefined;
      notes: string | null | undefined;
      dataProviderDescription: string | null | undefined;
      attachments: Array<{ __typename: 'TransactionAttachment'; id: string }>;
      account: {
        __typename: 'Account';
        id: string;
        displayName: string;
        syncDisabled: boolean;
        deactivatedAt: string | null | undefined;
        isHidden: boolean;
        isAsset: boolean;
        mask: string | null | undefined;
        createdAt: string;
        updatedAt: string;
        displayLastUpdatedAt: string;
        currentBalance: number | null | undefined;
        displayBalance: number | null | undefined;
        includeInNetWorth: boolean;
        hideFromList: boolean;
        hideTransactionsFromReports: boolean;
        includeBalanceInNetWorth: boolean;
        includeInGoalBalance: boolean;
        dataProvider: string | null | undefined;
        dataProviderAccountId: string | null | undefined;
        isManual: boolean;
        transactionsCount: number;
        holdingsCount: number;
        manualInvestmentsTrackingMethod: string | null | undefined;
        order: number;
        icon: string;
        logoUrl: string | null | undefined;
        type: { __typename: 'AccountType'; name: string; display: string; group: string };
        subtype: { __typename: 'AccountSubtype'; name: string; display: string };
        credential:
          | {
              __typename: 'Credential';
              id: string;
              updateRequired: boolean;
              disconnectedFromDataProviderAt: string | null | undefined;
              dataProvider: DataProviderLegacy;
              institution: {
                __typename: 'Institution';
                id: string;
                plaidInstitutionId: string | null | undefined;
                name: string;
                status: InstitutionStatus | null | undefined;
                logo: string | null | undefined;
              };
            }
          | null
          | undefined;
        institution:
          | {
              __typename: 'Institution';
              id: string;
              name: string;
              logo: string | null | undefined;
              primaryColor: string;
              url: string;
            }
          | null
          | undefined;
      };
      category: {
        __typename: 'Category';
        id: string;
        order: number;
        name: string;
        icon: string;
        systemCategory: string | null | undefined;
        isSystemCategory: boolean;
        isDisabled: boolean;
        group: { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType };
      };
      merchant: { __typename: 'Merchant'; id: string; name: string; transactionsCount: number };
    }>;
  };
};

export type Common_UpdateTransactionMutationVariables = Exact<{
  input: UpdateTransactionMutationInput;
}>;

export type Common_UpdateTransactionMutation = {
  __typename: 'Mutation';
  updateTransaction:
    | {
        __typename: 'UpdateTransactionMutation';
        transaction:
          | {
              __typename: 'Transaction';
              id: string;
              amount: number;
              pending: boolean;
              date: string;
              hideFromReports: boolean;
              plaidName: string | null | undefined;
              notes: string | null | undefined;
              dataProviderDescription: string | null | undefined;
              attachments: Array<{ __typename: 'TransactionAttachment'; id: string }>;
              account: {
                __typename: 'Account';
                id: string;
                displayName: string;
                syncDisabled: boolean;
                deactivatedAt: string | null | undefined;
                isHidden: boolean;
                isAsset: boolean;
                mask: string | null | undefined;
                createdAt: string;
                updatedAt: string;
                displayLastUpdatedAt: string;
                currentBalance: number | null | undefined;
                displayBalance: number | null | undefined;
                includeInNetWorth: boolean;
                hideFromList: boolean;
                hideTransactionsFromReports: boolean;
                includeBalanceInNetWorth: boolean;
                includeInGoalBalance: boolean;
                dataProvider: string | null | undefined;
                dataProviderAccountId: string | null | undefined;
                isManual: boolean;
                transactionsCount: number;
                holdingsCount: number;
                manualInvestmentsTrackingMethod: string | null | undefined;
                order: number;
                icon: string;
                logoUrl: string | null | undefined;
                type: { __typename: 'AccountType'; name: string; display: string; group: string };
                subtype: { __typename: 'AccountSubtype'; name: string; display: string };
                credential:
                  | {
                      __typename: 'Credential';
                      id: string;
                      updateRequired: boolean;
                      disconnectedFromDataProviderAt: string | null | undefined;
                      dataProvider: DataProviderLegacy;
                      institution: {
                        __typename: 'Institution';
                        id: string;
                        plaidInstitutionId: string | null | undefined;
                        name: string;
                        status: InstitutionStatus | null | undefined;
                        logo: string | null | undefined;
                      };
                    }
                  | null
                  | undefined;
                institution:
                  | {
                      __typename: 'Institution';
                      id: string;
                      name: string;
                      logo: string | null | undefined;
                      primaryColor: string;
                      url: string;
                    }
                  | null
                  | undefined;
              };
              category: {
                __typename: 'Category';
                id: string;
                order: number;
                name: string;
                icon: string;
                systemCategory: string | null | undefined;
                isSystemCategory: boolean;
                isDisabled: boolean;
                group: {
                  __typename: 'CategoryGroup';
                  id: string;
                  name: string;
                  type: CategoryGroupType;
                };
              };
              merchant: {
                __typename: 'Merchant';
                id: string;
                name: string;
                transactionsCount: number;
              };
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_SplitTransactionMutationVariables = Exact<{
  input: UpdateTransactionSplitMutationInput;
}>;

export type Common_SplitTransactionMutation = {
  __typename: 'Mutation';
  updateTransactionSplit:
    | {
        __typename: 'UpdateTransactionSplitMutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        transaction:
          | {
              __typename: 'Transaction';
              id: string;
              hasSplitTransactions: boolean;
              splitTransactions: Array<{
                __typename: 'Transaction';
                id: string;
                amount: number;
                notes: string | null | undefined;
                hideFromReports: boolean;
                reviewStatus: string | null | undefined;
                merchant: { __typename: 'Merchant'; id: string; name: string };
                category: { __typename: 'Category'; id: string; icon: string; name: string };
                goal: { __typename: 'GoalV2'; id: string } | null | undefined;
                needsReviewByUser: { __typename: 'User'; id: string } | null | undefined;
                tags: Array<{ __typename: 'TransactionTag'; id: string }>;
              }>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_TransactionSplitQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Common_TransactionSplitQuery = {
  __typename: 'Query';
  getTransaction:
    | {
        __typename: 'Transaction';
        id: string;
        amount: number;
        reviewedAt: string | null | undefined;
        needsReview: boolean;
        reviewStatus: string | null | undefined;
        hideFromReports: boolean;
        dataProviderDescription: string | null | undefined;
        notes: string | null | undefined;
        category: { __typename: 'Category'; id: string; name: string; icon: string };
        merchant: {
          __typename: 'Merchant';
          id: string;
          name: string;
          logoUrl: string | null | undefined;
        };
        needsReviewByUser: { __typename: 'User'; id: string; name: string } | null | undefined;
        tags: Array<{ __typename: 'TransactionTag'; id: string; name: string; color: string }>;
        splitTransactions: Array<{
          __typename: 'Transaction';
          id: string;
          amount: number;
          notes: string | null | undefined;
          date: string;
          hideFromReports: boolean;
          reviewStatus: string | null | undefined;
          needsReview: boolean;
          merchant: {
            __typename: 'Merchant';
            id: string;
            name: string;
            logoUrl: string | null | undefined;
          };
          category: { __typename: 'Category'; id: string; icon: string; name: string };
          goal:
            | {
                __typename: 'GoalV2';
                id: string;
                name: string;
                imageStorageProvider: string | null | undefined;
                imageStorageProviderId: string | null | undefined;
              }
            | null
            | undefined;
          needsReviewByUser: { __typename: 'User'; id: string } | null | undefined;
          tags: Array<{ __typename: 'TransactionTag'; id: string; name: string }>;
        }>;
        goal:
          | {
              __typename: 'GoalV2';
              id: string;
              name: string;
              imageStorageProvider: string | null | undefined;
              imageStorageProviderId: string | null | undefined;
            }
          | null
          | undefined;
        account: {
          __typename: 'Account';
          id: string;
          displayName: string;
          goalAllocations: Array<{
            __typename: 'GoalAccountAllocation';
            id: string;
            goal: {
              __typename: 'GoalV2';
              id: string;
              name: string;
              imageStorageProvider: string | null | undefined;
              imageStorageProviderId: string | null | undefined;
              priority: number;
              archivedAt: string | null | undefined;
            };
          }>;
        };
      }
    | null
    | undefined;
};

export type Common_CreateTransactionMutationVariables = Exact<{
  input: CreateTransactionMutationInput;
}>;

export type Common_CreateTransactionMutation = {
  __typename: 'Mutation';
  createTransaction:
    | {
        __typename: 'CreateTransactionMutation';
        transaction: { __typename: 'Transaction'; id: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_DeleteTransactionMutationVariables = Exact<{
  input: DeleteTransactionMutationInput;
}>;

export type Common_DeleteTransactionMutation = {
  __typename: 'Mutation';
  deleteTransaction:
    | {
        __typename: 'DeleteTransactionMutation';
        deleted: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_BulkUpdateTransactionsMutationVariables = Exact<{
  selectedTransactionIds: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  excludedTransactionIds: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  allSelected: Scalars['Boolean']['input'];
  expectedAffectedTransactionCount: Scalars['Int']['input'];
  updates: TransactionUpdateParams;
  filters: InputMaybe<TransactionFilterInput>;
}>;

export type Common_BulkUpdateTransactionsMutation = {
  __typename: 'Mutation';
  bulkUpdateTransactions:
    | {
        __typename: 'BulkUpdateTransactionsMutation';
        success: boolean;
        affectedCount: number;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_BulkDeleteTransactionsMutationVariables = Exact<{
  selectedTransactionIds: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  excludedTransactionIds: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  allSelected: Scalars['Boolean']['input'];
  expectedAffectedTransactionCount: Scalars['Int']['input'];
  filters: InputMaybe<TransactionFilterInput>;
}>;

export type Common_BulkDeleteTransactionsMutation = {
  __typename: 'Mutation';
  bulkDeleteTransactions:
    | {
        __typename: 'BulkDeleteTransactionsMutation';
        success: boolean;
        affectedCount: number;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_OldestDeletableTransactionDateQueryVariables = Exact<{
  includeSynced?: InputMaybe<Scalars['Boolean']['input']>;
  includeUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  includeManual?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Common_OldestDeletableTransactionDateQuery = {
  __typename: 'Query';
  oldestDeletableTransactionDate: string | null | undefined;
};

export type Common_DeleteSyncedTransactionsMutationVariables = Exact<{
  input: DeleteSyncedTransactionsInput;
}>;

export type Common_DeleteSyncedTransactionsMutation = {
  __typename: 'Mutation';
  deleteSyncedTransactions:
    | {
        __typename: 'DeleteSyncedTransactionsMutation';
        success: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TransactionOverviewFieldsFragment = {
  __typename: 'Transaction';
  id: string;
  amount: number;
  pending: boolean;
  date: string;
  hideFromReports: boolean;
  plaidName: string | null | undefined;
  notes: string | null | undefined;
  isRecurring: boolean;
  reviewStatus: string | null | undefined;
  needsReview: boolean;
  isSplitTransaction: boolean;
  dataProviderDescription: string | null | undefined;
  attachments: Array<{ __typename: 'TransactionAttachment'; id: string }>;
  category: {
    __typename: 'Category';
    id: string;
    name: string;
    icon: string;
    group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
  };
  merchant: {
    __typename: 'Merchant';
    name: string;
    id: string;
    transactionsCount: number;
    logoUrl: string | null | undefined;
    recurringTransactionStream:
      | { __typename: 'RecurringTransactionStream'; frequency: string; isActive: boolean }
      | null
      | undefined;
  };
  tags: Array<{
    __typename: 'TransactionTag';
    id: string;
    name: string;
    color: string;
    order: number;
  }>;
  account: {
    __typename: 'Account';
    id: string;
    displayName: string;
    icon: string;
    logoUrl: string | null | undefined;
  };
};

export type Common_PreviewDeleteTransactionsQueryVariables = Exact<{
  beforeDate: Scalars['Date']['input'];
  includeSynced?: InputMaybe<Scalars['Boolean']['input']>;
  includeUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  includeManual?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Common_PreviewDeleteTransactionsQuery = {
  __typename: 'Query';
  previewDeleteTransactions: { __typename: 'PreviewDeleteTransactionList'; totalCount: number };
};

export type Common_DeleteTransactionsMutationVariables = Exact<{
  input: DeleteTransactionsInput;
}>;

export type Common_DeleteTransactionsMutation = {
  __typename: 'Mutation';
  deleteTransactions:
    | {
        __typename: 'DeleteTransactionsMutation';
        success: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_DeleteHouseholdTransactionTagMutationVariables = Exact<{
  tagId: Scalars['ID']['input'];
}>;

export type Common_DeleteHouseholdTransactionTagMutation = {
  __typename: 'Mutation';
  deleteTransactionTag:
    | {
        __typename: 'DeleteTransactionTag';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateTransactionTagOrderMutationVariables = Exact<{
  tagId: Scalars['ID']['input'];
  order: Scalars['Int']['input'];
}>;

export type Common_UpdateTransactionTagOrderMutation = {
  __typename: 'Mutation';
  updateTransactionTagOrder:
    | {
        __typename: 'UpdateTransactionTagOrder';
        householdTransactionTags:
          | Array<{ __typename: 'TransactionTag'; id: string; order: number }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateTransactionTagMutationVariables = Exact<{
  input: UpdateTransactionTagInput;
}>;

export type Common_UpdateTransactionTagMutation = {
  __typename: 'Mutation';
  updateTransactionTag:
    | {
        __typename: 'UpdateTransactionTag';
        tag:
          | { __typename: 'TransactionTag'; id: string; name: string; color: string; order: number }
          | null
          | undefined;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_CreateTransactionTagMutationVariables = Exact<{
  input: CreateTransactionTagInput;
}>;

export type Common_CreateTransactionTagMutation = {
  __typename: 'Mutation';
  createTransactionTag:
    | {
        __typename: 'CreateTransactionTag';
        tag:
          | {
              __typename: 'TransactionTag';
              id: string;
              name: string;
              color: string;
              order: number;
              transactionCount: number;
            }
          | null
          | undefined;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UserFieldsFragment = {
  __typename: 'User';
  birthday: string | null | undefined;
  email: string;
  id: string;
  isSuperuser: boolean;
  name: string;
  timezone: string | null | undefined;
  hasPassword: boolean;
  hasMfaOn: boolean;
  externalAuthProviderNames: Array<string>;
  profilePictureUrl: string | null | undefined;
  pendingEmailUpdateVerification:
    | { __typename: 'PendingEmailUpdateVerification'; email: string }
    | null
    | undefined;
  profilePicture:
    | {
        __typename: 'UserProfilePicture';
        id: string;
        cloudinaryPublicId: string | null | undefined;
        thumbnailUrl: string | null | undefined;
      }
    | null
    | undefined;
  activeSupportAccountAccessGrant:
    | { __typename: 'SupportAccountAccessGrant'; id: string; createdAt: string; expiresAt: string }
    | null
    | undefined;
};

export type Common_GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type Common_GetMeQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    birthday: string | null | undefined;
    email: string;
    isSuperuser: boolean;
    name: string;
    timezone: string | null | undefined;
    hasPassword: boolean;
    hasMfaOn: boolean;
    externalAuthProviderNames: Array<string>;
    profilePictureUrl: string | null | undefined;
    profile:
      | {
          __typename: 'UserProfile';
          id: string;
          hasSeenCategoriesManagementTour: boolean;
          dismissedTransactionsListUpdatesTourAt: string | null | undefined;
          viewedMarkAsReviewedUpdatesCalloutAt: string | null | undefined;
          dismissedRecurringWalkthroughAt: string | null | undefined;
          hasDismissedWhatsNewAt: string | null | undefined;
        }
      | null
      | undefined;
    pendingEmailUpdateVerification:
      | { __typename: 'PendingEmailUpdateVerification'; email: string }
      | null
      | undefined;
    profilePicture:
      | {
          __typename: 'UserProfilePicture';
          id: string;
          cloudinaryPublicId: string | null | undefined;
          thumbnailUrl: string | null | undefined;
        }
      | null
      | undefined;
    activeSupportAccountAccessGrant:
      | {
          __typename: 'SupportAccountAccessGrant';
          id: string;
          createdAt: string;
          expiresAt: string;
        }
      | null
      | undefined;
  };
};

export type Common_UpdateMeMutationVariables = Exact<{
  input: UpdateMeInput;
}>;

export type Common_UpdateMeMutation = {
  __typename: 'Mutation';
  updateMe:
    | {
        __typename: 'UpdateMe';
        user:
          | {
              __typename: 'User';
              birthday: string | null | undefined;
              email: string;
              id: string;
              isSuperuser: boolean;
              name: string;
              timezone: string | null | undefined;
              hasPassword: boolean;
              hasMfaOn: boolean;
              externalAuthProviderNames: Array<string>;
              profilePictureUrl: string | null | undefined;
              pendingEmailUpdateVerification:
                | { __typename: 'PendingEmailUpdateVerification'; email: string }
                | null
                | undefined;
              profilePicture:
                | {
                    __typename: 'UserProfilePicture';
                    id: string;
                    cloudinaryPublicId: string | null | undefined;
                    thumbnailUrl: string | null | undefined;
                  }
                | null
                | undefined;
              activeSupportAccountAccessGrant:
                | {
                    __typename: 'SupportAccountAccessGrant';
                    id: string;
                    createdAt: string;
                    expiresAt: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_GetAnonymousIdQueryVariables = Exact<{ [key: string]: never }>;

export type Common_GetAnonymousIdQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    attributionData:
      | { __typename: 'UserAttributionData'; anonymousId: string | null | undefined }
      | null
      | undefined;
  };
};

export type Common_HasActivityQueryVariables = Exact<{ [key: string]: never }>;

export type Common_HasActivityQuery = {
  __typename: 'Query';
  me: { __typename: 'User'; id: string; hasNewActivity: boolean };
};

export type Common_CheckActivityMutationVariables = Exact<{ [key: string]: never }>;

export type Common_CheckActivityMutation = {
  __typename: 'Mutation';
  checkActivity:
    | {
        __typename: 'CheckActivityMutation';
        user: { __typename: 'User'; id: string; hasNewActivity: boolean };
      }
    | null
    | undefined;
};

export type Common_UpdateUserProfileMutationVariables = Exact<{
  updateProfileInput: UpdateUserProfileInput;
  updateMeInput: UpdateMeInput;
  updateMyHouseholdInput?: InputMaybe<UpdateMyHouseholdInput>;
}>;

export type Common_UpdateUserProfileMutation = {
  __typename: 'Mutation';
  updateUserProfile:
    | {
        __typename: 'UpdateUserProfile';
        userProfile:
          | {
              __typename: 'UserProfile';
              id: string;
              birthday: string | null | undefined;
              collaboratesOnFinancesDetailed: string | null | undefined;
              hasSeenCategoriesManagementTour: boolean;
              aiAssistantOptedInAt: string | null | undefined;
              viewedMarkAsReviewedUpdatesCalloutAt: string | null | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  updateMe:
    | {
        __typename: 'UpdateMe';
        user: { __typename: 'User'; id: string; name: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  updateMyHousehold:
    | {
        __typename: 'UpdateMyHousehold';
        household: { __typename: 'Household'; country: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type VehiclesSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  limit: InputMaybe<Scalars['Int']['input']>;
}>;

export type VehiclesSearchQuery = {
  __typename: 'Query';
  vehicles: Array<{ __typename: 'Vehicle'; vin: string; name: string; value: number }>;
};

export type Common_SetAccountLogoMutationVariables = Exact<{
  input: SetAccountLogoInput;
}>;

export type Common_SetAccountLogoMutation = {
  __typename: 'Mutation';
  setAccountLogo:
    | {
        __typename: 'SetAccountLogoMutation';
        account:
          | {
              __typename: 'Account';
              id: string;
              name: string | null | undefined;
              logoUrl: string | null | undefined;
              hasCustomizedLogo: boolean | null | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateAccountGroupOrderMutationVariables = Exact<{
  input: UpdateAccountGroupOrderInput;
}>;

export type Common_UpdateAccountGroupOrderMutation = {
  __typename: 'Mutation';
  updateAccountGroupOrder:
    | {
        __typename: 'UpdateAccountGroupOrderMutation';
        household: {
          __typename: 'Household';
          preferences:
            | {
                __typename: 'HouseholdPreferences';
                id: string | null | undefined;
                accountGroupOrder: Array<string>;
              }
            | null
            | undefined;
        };
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetAccountTypesQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetAccountTypesQuery = {
  __typename: 'Query';
  accountTypes: Array<{
    __typename: 'AccountType';
    name: string;
    display: string;
    group: string;
    showForSyncedAccounts: boolean;
    possibleSubtypes: Array<{ __typename: 'AccountSubtype'; display: string; name: string }>;
  }>;
};

export type Common_ForceRefreshAccountQueryVariables = Exact<{
  accountId: Scalars['UUID']['input'];
}>;

export type Common_ForceRefreshAccountQuery = {
  __typename: 'Query';
  account:
    | {
        __typename: 'Account';
        id: string;
        canBeForceRefreshed: boolean;
        hasSyncOrRecentRefreshRequest: boolean;
      }
    | null
    | undefined;
};

export type Common_ForceRefreshAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type Common_ForceRefreshAccountsQuery = { __typename: 'Query'; hasAccountsSyncing: boolean };

export type Common_CreateThreadMutationVariables = Exact<{
  agentType: InputMaybe<Scalars['String']['input']>;
}>;

export type Common_CreateThreadMutation = {
  __typename: 'Mutation';
  createMessageThread: {
    __typename: 'CreateMessageThread';
    messageThread: { __typename: 'MessageThread'; id: string } | null | undefined;
  };
};

export type Common_DeleteMessageThreadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type Common_DeleteMessageThreadMutation = {
  __typename: 'Mutation';
  deleteMessageThread: { __typename: 'DeleteMessageThread'; success: boolean | null | undefined };
};

export type Common_SendMessageMutationVariables = Exact<{
  input: SendMessageInput;
}>;

export type Common_SendMessageMutation = {
  __typename: 'Mutation';
  sendMessage: {
    __typename: 'SendMessage';
    messageThread:
      | { __typename: 'MessageThread'; id: string; lastMessageSentAt: string | null | undefined }
      | null
      | undefined;
  };
};

export type GetTranscriptionKeyQueryVariables = Exact<{ [key: string]: never }>;

export type GetTranscriptionKeyQuery = { __typename: 'Query'; householdTranscriptionKey: string };

export type Common_GetProductboardCodeQueryVariables = Exact<{ [key: string]: never }>;

export type Common_GetProductboardCodeQuery = { __typename: 'Query'; productBoardToken: string };

export type Common_SetHasSkippedPremiumUpsellMutationVariables = Exact<{ [key: string]: never }>;

export type Common_SetHasSkippedPremiumUpsellMutation = {
  __typename: 'Mutation';
  updateUserProfile:
    | {
        __typename: 'UpdateUserProfile';
        userProfile:
          | {
              __typename: 'UserProfile';
              id: string;
              skippedPremiumUpsellAt: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetSubscriptionDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSubscriptionDetailsQuery = {
  __typename: 'Query';
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
    referralCode: string;
    isOnFreeTrial: boolean;
    hasPremiumEntitlement: boolean;
    activeSponsorship: { __typename: 'SubscriptionSponsorshipInfo'; id: string } | null | undefined;
  };
};

export type GetAggregatesGraphQueryVariables = Exact<{
  startDate: InputMaybe<Scalars['Date']['input']>;
  endDate: InputMaybe<Scalars['Date']['input']>;
}>;

export type GetAggregatesGraphQuery = {
  __typename: 'Query';
  aggregates: Array<{
    __typename: 'AggregateData';
    groupBy: {
      __typename: 'AggregateGroupBy';
      month: string | null | undefined;
      category: { __typename: 'Category'; id: string } | null | undefined;
    };
    summary: { __typename: 'TransactionsSummary'; sum: number };
  }>;
};

export type GetAggregatesGraphCategoryGroupQueryVariables = Exact<{
  startDate: InputMaybe<Scalars['Date']['input']>;
  endDate: InputMaybe<Scalars['Date']['input']>;
}>;

export type GetAggregatesGraphCategoryGroupQuery = {
  __typename: 'Query';
  aggregates: Array<{
    __typename: 'AggregateData';
    groupBy: {
      __typename: 'AggregateGroupBy';
      month: string | null | undefined;
      categoryGroup: { __typename: 'CategoryGroup'; id: string } | null | undefined;
    };
    summary: { __typename: 'TransactionsSummary'; sum: number };
  }>;
};

export type GetAggregatesGraphFlexExpenseQueryVariables = Exact<{
  startDate: InputMaybe<Scalars['Date']['input']>;
  endDate: InputMaybe<Scalars['Date']['input']>;
}>;

export type GetAggregatesGraphFlexExpenseQuery = {
  __typename: 'Query';
  aggregates: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; month: string | null | undefined };
    summary: { __typename: 'TransactionsSummary'; sum: number };
  }>;
};

export type Common_InitializeFlexBudgetMutationVariables = Exact<{
  input: InitializeFlexBudgetMutationInput;
}>;

export type Common_InitializeFlexBudgetMutation = {
  __typename: 'Mutation';
  initializeFlexBudget:
    | {
        __typename: 'InitializeFlexBudgetMutation';
        budgetItem:
          | {
              __typename: 'BudgetItem';
              id: string;
              plannedCashFlowAmount: number | null | undefined;
              budgetTarget: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_ResetBudgetMutationVariables = Exact<{
  input: ResetBudgetMutationInput;
}>;

export type Common_ResetBudgetMutation = {
  __typename: 'Mutation';
  resetBudget:
    | {
        __typename: 'ResetBudgetMutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_GetPlanOnboardingStateQueryVariables = Exact<{ [key: string]: never }>;

export type Common_GetPlanOnboardingStateQuery = {
  __typename: 'Query';
  subscription: { __typename: 'HouseholdSubscription'; id: string; isOnFreeTrial: boolean };
  budgetStatus: {
    __typename: 'BudgetStatus';
    hasBudget: boolean;
    hasTransactions: boolean;
    willCreateBudgetFromEmptyDefaultCategories: boolean;
  };
};

export type Common_UpdateBudgetItemMutationVariables = Exact<{
  input: UpdateOrCreateBudgetItemMutationInput;
}>;

export type Common_UpdateBudgetItemMutation = {
  __typename: 'Mutation';
  updateOrCreateBudgetItem:
    | {
        __typename: 'UpdateOrCreateBudgetItemMutation';
        budgetItem:
          | {
              __typename: 'BudgetItem';
              id: string;
              plannedCashFlowAmount: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateCategoryBudgetVariabilityMutationVariables = Exact<{
  input: UpdateCategoryInput;
}>;

export type Common_UpdateCategoryBudgetVariabilityMutation = {
  __typename: 'Mutation';
  updateCategory:
    | {
        __typename: 'UpdateCategoryPayload';
        category:
          | {
              __typename: 'Category';
              id: string;
              budgetVariability: BudgetVariability | null | undefined;
              updatedAt: string;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateCategoryGroupBudgetVariabilityMutationVariables = Exact<{
  input: UpdateCategoryGroupInput;
}>;

export type Common_UpdateCategoryGroupBudgetVariabilityMutation = {
  __typename: 'Mutation';
  updateCategoryGroup:
    | {
        __typename: 'UpdateCategoryGroupMutation';
        categoryGroup:
          | {
              __typename: 'CategoryGroup';
              id: string;
              budgetVariability: BudgetVariability | null | undefined;
              updatedAt: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateFlexBudgetMutationVariables = Exact<{
  input: UpdateOrCreateFlexBudgetItemMutationInput;
}>;

export type Common_UpdateFlexBudgetMutation = {
  __typename: 'Mutation';
  updateOrCreateFlexBudgetItem:
    | {
        __typename: 'UpdateOrCreateFlexBudgetItemMutation';
        budgetItem:
          | { __typename: 'BudgetItem'; id: string; budgetAmount: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_GetCategoriesQueryVariables = Exact<{
  includeSystemDisabledCategories: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Common_GetCategoriesQuery = {
  __typename: 'Query';
  categoryGroups: Array<{
    __typename: 'CategoryGroup';
    id: string;
    name: string;
    order: number;
    type: CategoryGroupType;
    groupLevelBudgetingEnabled: boolean | null | undefined;
    budgetVariability: BudgetVariability | null | undefined;
    rolloverPeriod:
      | {
          __typename: 'BudgetRolloverPeriod';
          id: string;
          startMonth: string;
          startingBalance: number | null | undefined;
          frequency: string | null | undefined;
          targetAmount: number | null | undefined;
          type: string;
        }
      | null
      | undefined;
    categories: Array<{ __typename: 'Category'; id: string }>;
  }>;
  categories: Array<{
    __typename: 'Category';
    id: string;
    name: string;
    order: number;
    icon: string;
    isSystemCategory: boolean;
    excludeFromBudget: boolean | null | undefined;
    budgetVariability: BudgetVariability | null | undefined;
    isDisabled: boolean;
    transactionsCount: number;
    group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
    rolloverPeriod:
      | {
          __typename: 'BudgetRolloverPeriod';
          id: string;
          startMonth: string;
          startingBalance: number | null | undefined;
          frequency: string | null | undefined;
          targetAmount: number | null | undefined;
          type: string;
        }
      | null
      | undefined;
  }>;
};

export type Common_GetCloudinaryUploadInfoMutationVariables = Exact<{
  input: GetLogoCloudinaryUploadInfoMutationInput;
}>;

export type Common_GetCloudinaryUploadInfoMutation = {
  __typename: 'Mutation';
  getCloudinaryUploadInfo:
    | {
        __typename: 'GetLogoCloudinaryUploadInfoMutation';
        info:
          | {
              __typename: 'CloudinaryUploadInfo';
              path: string;
              requestParams: {
                __typename: 'CloudinaryUploadRequestParams';
                timestamp: number;
                folder: string;
                signature: string;
                api_key: string;
                upload_preset: string;
              };
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DashboardConfigFragment = {
  __typename: 'PlatformDashboardConfig';
  layout: { [key: string]: Scalars['GenericScalar'] };
  widgets: { [key: string]: Scalars['GenericScalar'] };
};

export type HouseholdDashboardFragment = {
  __typename: 'Household';
  id: string;
  preferences:
    | {
        __typename: 'HouseholdPreferences';
        dashboardConfig: {
          __typename: 'DashboardConfig';
          web: {
            __typename: 'PlatformDashboardConfig';
            layout: { [key: string]: Scalars['GenericScalar'] };
            widgets: { [key: string]: Scalars['GenericScalar'] };
          };
          mobile: {
            __typename: 'PlatformDashboardConfig';
            layout: { [key: string]: Scalars['GenericScalar'] };
            widgets: { [key: string]: Scalars['GenericScalar'] };
          };
        };
      }
    | null
    | undefined;
};

export type GetDashboardConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetDashboardConfigQuery = {
  __typename: 'Query';
  myHousehold: {
    __typename: 'Household';
    id: string;
    preferences:
      | {
          __typename: 'HouseholdPreferences';
          dashboardConfig: {
            __typename: 'DashboardConfig';
            web: {
              __typename: 'PlatformDashboardConfig';
              layout: { [key: string]: Scalars['GenericScalar'] };
              widgets: { [key: string]: Scalars['GenericScalar'] };
            };
            mobile: {
              __typename: 'PlatformDashboardConfig';
              layout: { [key: string]: Scalars['GenericScalar'] };
              widgets: { [key: string]: Scalars['GenericScalar'] };
            };
          };
        }
      | null
      | undefined;
  };
};

export type Common_UpdateDashboardConfigMutationVariables = Exact<{
  input: Scalars['JSONString']['input'];
}>;

export type Common_UpdateDashboardConfigMutation = {
  __typename: 'Mutation';
  updateDashboardConfig:
    | {
        __typename: 'UpdateDashboardConfigMutation';
        household: {
          __typename: 'Household';
          id: string;
          preferences:
            | {
                __typename: 'HouseholdPreferences';
                dashboardConfig: {
                  __typename: 'DashboardConfig';
                  web: {
                    __typename: 'PlatformDashboardConfig';
                    layout: { [key: string]: Scalars['GenericScalar'] };
                    widgets: { [key: string]: Scalars['GenericScalar'] };
                  };
                  mobile: {
                    __typename: 'PlatformDashboardConfig';
                    layout: { [key: string]: Scalars['GenericScalar'] };
                    widgets: { [key: string]: Scalars['GenericScalar'] };
                  };
                };
              }
            | null
            | undefined;
        };
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type OnboardingChecklistQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardingChecklistQuery = {
  __typename: 'Query';
  hasAccounts: boolean;
  goalsV2: Array<{ __typename: 'GoalV2'; id: string }>;
  goals: Array<{ __typename: 'Goal'; id: string }>;
  budgetStatus: { __typename: 'BudgetStatus'; hasBudget: boolean };
  me: {
    __typename: 'User';
    id: string;
    name: string;
    profile:
      | { __typename: 'UserProfile'; id: string; hasSeenCategoriesManagementTour: boolean }
      | null
      | undefined;
  };
};

export type Common_ArchiveGoalMutationVariables = Exact<{
  input: ArchiveGoalInput;
}>;

export type Common_ArchiveGoalMutation = {
  __typename: 'Mutation';
  archiveGoal:
    | {
        __typename: 'ArchiveGoal';
        goal:
          | { __typename: 'GoalV2'; id: string; archivedAt: string | null | undefined }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GoalAllocationFieldsFragment = {
  __typename: 'GoalAccountAllocation';
  id: string;
  amount: number | null | undefined;
  currentAmount: number | null | undefined;
  useEntireAccountBalance: boolean;
  account: { __typename: 'Account'; id: string; availableBalanceForGoals: number };
};

export type GoalAllocationCacheFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  accountAllocations: Array<{
    __typename: 'GoalAccountAllocation';
    id: string;
    amount: number | null | undefined;
    currentAmount: number | null | undefined;
    useEntireAccountBalance: boolean;
    account: { __typename: 'Account'; id: string; availableBalanceForGoals: number };
  }>;
};

export type Common_CreateGoalAccountAllocationMutationVariables = Exact<{
  input: CreateGoalAccountAllocationInput;
}>;

export type Common_CreateGoalAccountAllocationMutation = {
  __typename: 'Mutation';
  createGoalAccountAllocation:
    | {
        __typename: 'CreateGoalAccountAllocation';
        goalAccountAllocation:
          | {
              __typename: 'GoalAccountAllocation';
              id: string;
              amount: number | null | undefined;
              currentAmount: number | null | undefined;
              useEntireAccountBalance: boolean;
              account: { __typename: 'Account'; id: string; availableBalanceForGoals: number };
            }
          | null
          | undefined;
        goal:
          | {
              __typename: 'GoalV2';
              id: string;
              currentAmount: number | null | undefined;
              completionPercent: number | null | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_DeleteGoalV2MutationVariables = Exact<{
  input: DeleteGoalInput;
}>;

export type Common_DeleteGoalV2Mutation = {
  __typename: 'Mutation';
  deleteGoalV2:
    | {
        __typename: 'DeleteGoal';
        success: boolean;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_DeleteGoalAccountAllocationMutationVariables = Exact<{
  input: DeleteGoalAccountAllocationInput;
}>;

export type Common_DeleteGoalAccountAllocationMutation = {
  __typename: 'Mutation';
  deleteGoalAccountAllocation:
    | {
        __typename: 'DeleteGoalAccountAllocation';
        goal:
          | {
              __typename: 'GoalV2';
              id: string;
              currentAmount: number | null | undefined;
              completionPercent: number | null | undefined;
            }
          | null
          | undefined;
        account:
          | { __typename: 'Account'; id: string; availableBalanceForGoals: number }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetGoalsV2QueryVariables = Exact<{ [key: string]: never }>;

export type GetGoalsV2Query = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    name: string;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
  }>;
};

export type Common_MarkGoalCompleteMutationVariables = Exact<{
  input: MarkGoalCompleteInput;
}>;

export type Common_MarkGoalCompleteMutation = {
  __typename: 'Mutation';
  markGoalComplete:
    | {
        __typename: 'MarkGoalComplete';
        goal:
          | { __typename: 'GoalV2'; id: string; completedAt: string | null | undefined }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_MarkGoalIncompleteMutationVariables = Exact<{
  input: MarkGoalIncompleteInput;
}>;

export type Common_MarkGoalIncompleteMutation = {
  __typename: 'Mutation';
  markGoalIncomplete:
    | {
        __typename: 'MarkGoalIncomplete';
        goal:
          | { __typename: 'GoalV2'; id: string; completedAt: string | null | undefined }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GoalAccountsMapRowFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  name: string;
  priority: number;
  imageStorageProvider: string | null | undefined;
  imageStorageProviderId: string | null | undefined;
  targetAmount: number | null | undefined;
  startingAmount: number | null | undefined;
  currentAmount: number | null | undefined;
  completionPercent: number | null | undefined;
  type: string;
  defaultName: string | null | undefined;
  accountAllocations: Array<{
    __typename: 'GoalAccountAllocation';
    id: string;
    account: {
      __typename: 'Account';
      id: string;
      logoUrl: string | null | undefined;
      icon: string;
      dataProvider: string | null | undefined;
      type: { __typename: 'AccountType'; name: string };
      subtype: { __typename: 'AccountSubtype'; name: string };
      institution:
        | {
            __typename: 'Institution';
            id: string;
            logo: string | null | undefined;
            primaryColor: string;
          }
        | null
        | undefined;
    };
  }>;
};

export type GoalAccountAllocationRowFieldsFragment = {
  __typename: 'Account';
  id: string;
  hideFromList: boolean;
  deactivatedAt: string | null | undefined;
  displayName: string;
  isAsset: boolean;
  availableBalanceForGoals: number;
  displayBalance: number | null | undefined;
  logoUrl: string | null | undefined;
  icon: string;
  dataProvider: string | null | undefined;
  goalAllocations: Array<{
    __typename: 'GoalAccountAllocation';
    id: string;
    amount: number | null | undefined;
    currentAmount: number | null | undefined;
    useEntireAccountBalance: boolean;
    goal: { __typename: 'GoalV2'; id: string; name: string };
  }>;
  type: { __typename: 'AccountType'; name: string };
  subtype: { __typename: 'AccountSubtype'; name: string };
  institution:
    | {
        __typename: 'Institution';
        id: string;
        logo: string | null | undefined;
        primaryColor: string;
      }
    | null
    | undefined;
};

export type AllocationFieldsFragment = {
  __typename: 'GoalAccountAllocation';
  id: string;
  amount: number | null | undefined;
  currentAmount: number | null | undefined;
  useEntireAccountBalance: boolean;
  account: {
    __typename: 'Account';
    id: string;
    hideFromList: boolean;
    deactivatedAt: string | null | undefined;
  };
};

export type GoalSelectAccountsQueryVariables = Exact<{
  goalId: Scalars['ID']['input'];
}>;

export type GoalSelectAccountsQuery = {
  __typename: 'Query';
  goalV2:
    | {
        __typename: 'GoalV2';
        id: string;
        type: string;
        name: string;
        priority: number;
        imageStorageProvider: string | null | undefined;
        imageStorageProviderId: string | null | undefined;
        targetAmount: number | null | undefined;
        startingAmount: number | null | undefined;
        currentAmount: number | null | undefined;
        completionPercent: number | null | undefined;
        defaultName: string | null | undefined;
        eligibleAccounts: Array<{
          __typename: 'Account';
          id: string;
          hideFromList: boolean;
          deactivatedAt: string | null | undefined;
          displayName: string;
          isAsset: boolean;
          availableBalanceForGoals: number;
          displayBalance: number | null | undefined;
          logoUrl: string | null | undefined;
          icon: string;
          dataProvider: string | null | undefined;
          subtype: { __typename: 'AccountSubtype'; name: string };
          goalAllocations: Array<{
            __typename: 'GoalAccountAllocation';
            id: string;
            amount: number | null | undefined;
            currentAmount: number | null | undefined;
            useEntireAccountBalance: boolean;
            goal: { __typename: 'GoalV2'; id: string; name: string };
          }>;
          type: { __typename: 'AccountType'; name: string };
          institution:
            | {
                __typename: 'Institution';
                id: string;
                logo: string | null | undefined;
                primaryColor: string;
              }
            | null
            | undefined;
        }>;
        suggestedAccounts: Array<{
          __typename: 'Account';
          id: string;
          hideFromList: boolean;
          deactivatedAt: string | null | undefined;
          displayName: string;
          isAsset: boolean;
          availableBalanceForGoals: number;
          displayBalance: number | null | undefined;
          logoUrl: string | null | undefined;
          icon: string;
          dataProvider: string | null | undefined;
          subtype: { __typename: 'AccountSubtype'; name: string };
          goalAllocations: Array<{
            __typename: 'GoalAccountAllocation';
            id: string;
            amount: number | null | undefined;
            currentAmount: number | null | undefined;
            useEntireAccountBalance: boolean;
            goal: { __typename: 'GoalV2'; id: string; name: string };
          }>;
          type: { __typename: 'AccountType'; name: string };
          institution:
            | {
                __typename: 'Institution';
                id: string;
                logo: string | null | undefined;
                primaryColor: string;
              }
            | null
            | undefined;
        }>;
        accountAllocations: Array<{
          __typename: 'GoalAccountAllocation';
          id: string;
          amount: number | null | undefined;
          currentAmount: number | null | undefined;
          useEntireAccountBalance: boolean;
          account: {
            __typename: 'Account';
            id: string;
            logoUrl: string | null | undefined;
            icon: string;
            dataProvider: string | null | undefined;
            hideFromList: boolean;
            deactivatedAt: string | null | undefined;
            type: { __typename: 'AccountType'; name: string };
            subtype: { __typename: 'AccountSubtype'; name: string };
            institution:
              | {
                  __typename: 'Institution';
                  id: string;
                  logo: string | null | undefined;
                  primaryColor: string;
                }
              | null
              | undefined;
          };
        }>;
        legacyGoal:
          | { __typename: 'Goal'; id: string; contributedBalance: number }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GoalOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GoalOptionsQuery = {
  __typename: 'Query';
  goalOptions: Array<{
    __typename: 'GoalOption';
    defaultName: string;
    objective: string;
    type: string;
    allowMultiSelect: boolean;
    defaultImageStorageProvider: string;
    defaultImageStorageProviderId: string;
  }>;
};

export type Common_CreateGoalsMutationVariables = Exact<{
  input: CreateGoalsInput;
}>;

export type Common_CreateGoalsMutation = {
  __typename: 'Mutation';
  createGoals:
    | {
        __typename: 'CreateGoals';
        goals:
          | Array<{ __typename: 'GoalV2'; id: string; objective: string } | null | undefined>
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UnarchiveGoalMutationVariables = Exact<{
  input: UnarchiveGoalInput;
}>;

export type Common_UnarchiveGoalMutation = {
  __typename: 'Mutation';
  unarchiveGoal:
    | {
        __typename: 'UnarchiveGoal';
        goal:
          | { __typename: 'GoalV2'; id: string; archivedAt: string | null | undefined }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_UpdateGoalV2MutationVariables = Exact<{
  input: UpdateGoalInput;
}>;

export type Mobile_UpdateGoalV2Mutation = {
  __typename: 'Mutation';
  updateGoalV2:
    | {
        __typename: 'UpdateGoal';
        goal:
          | {
              __typename: 'GoalV2';
              id: string;
              name: string;
              imageStorageProvider: string | null | undefined;
              imageStorageProviderId: string | null | undefined;
              targetAmount: number | null | undefined;
              estimatedCompletionMonth: string | null | undefined;
              plannedMonthlyContribution: number | null | undefined;
              plannedMonthlyPretaxContribution: number | null | undefined;
              startingAmount: number | null | undefined;
              completionPercent: number | null | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateGoalAccountAllocationMutationVariables = Exact<{
  input: UpdateGoalAccountAllocationInput;
}>;

export type Common_UpdateGoalAccountAllocationMutation = {
  __typename: 'Mutation';
  updateGoalAccountAllocation:
    | {
        __typename: 'UpdateGoalAccountAllocation';
        goalAccountAllocation:
          | {
              __typename: 'GoalAccountAllocation';
              id: string;
              amount: number | null | undefined;
              currentAmount: number | null | undefined;
              useEntireAccountBalance: boolean;
              account: { __typename: 'Account'; id: string; availableBalanceForGoals: number };
            }
          | null
          | undefined;
        goal:
          | {
              __typename: 'GoalV2';
              id: string;
              currentAmount: number | null | undefined;
              completionPercent: number | null | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type HouseholdPreferencesFieldsFragment = {
  __typename: 'HouseholdPreferences';
  id: string | null | undefined;
  newTransactionsNeedReview: boolean | null | undefined;
  uncategorizedTransactionsNeedReview: boolean | null | undefined;
  pendingTransactionsCanBeEdited: boolean | null | undefined;
  accountGroupOrder: Array<string>;
  aiAssistantEnabled: boolean | null | undefined;
  llmEnrichmentEnabled: boolean | null | undefined;
  investmentTransactionsEnabled: boolean | null | undefined;
};

export type Common_GetHouseholdPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type Common_GetHouseholdPreferencesQuery = {
  __typename: 'Query';
  budgetSystem: BudgetSystem;
  householdPreferences:
    | {
        __typename: 'HouseholdPreferences';
        id: string | null | undefined;
        newTransactionsNeedReview: boolean | null | undefined;
        uncategorizedTransactionsNeedReview: boolean | null | undefined;
        pendingTransactionsCanBeEdited: boolean | null | undefined;
        accountGroupOrder: Array<string>;
        aiAssistantEnabled: boolean | null | undefined;
        llmEnrichmentEnabled: boolean | null | undefined;
        investmentTransactionsEnabled: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type Common_UpdateHouseholdPreferencesMutationVariables = Exact<{
  input: UpdateHouseholdPreferencesInput;
}>;

export type Common_UpdateHouseholdPreferencesMutation = {
  __typename: 'Mutation';
  updateHouseholdPreferences:
    | {
        __typename: 'UpdateHouseholdPreferences';
        householdPreferences:
          | {
              __typename: 'HouseholdPreferences';
              id: string | null | undefined;
              newTransactionsNeedReview: boolean | null | undefined;
              uncategorizedTransactionsNeedReview: boolean | null | undefined;
              pendingTransactionsCanBeEdited: boolean | null | undefined;
              accountGroupOrder: Array<string>;
              aiAssistantEnabled: boolean | null | undefined;
              llmEnrichmentEnabled: boolean | null | undefined;
              investmentTransactionsEnabled: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_GetHouseholdMembersQueryVariables = Exact<{ [key: string]: never }>;

export type Common_GetHouseholdMembersQuery = {
  __typename: 'Query';
  me: { __typename: 'User'; id: string };
  myHousehold: {
    __typename: 'Household';
    id: string;
    users: Array<{ __typename: 'User'; id: string; name: string }>;
  };
  byNeedsReviewByUser: Array<{
    __typename: 'AggregateData';
    groupBy: {
      __typename: 'AggregateGroupBy';
      needsReviewByUser: { __typename: 'User'; id: string; name: string } | null | undefined;
    };
    summary: { __typename: 'TransactionsSummary'; count: number };
  }>;
};

export type Common_GetMerchantQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type Common_GetMerchantQuery = {
  __typename: 'Query';
  merchant:
    | { __typename: 'Merchant'; id: string; name: string; logoUrl: string | null | undefined }
    | null
    | undefined;
};

export type Common_SetMerchantLogoMutationVariables = Exact<{
  input: SetMerchantLogoInput;
}>;

export type Common_SetMerchantLogoMutation = {
  __typename: 'Mutation';
  setMerchantLogo:
    | {
        __typename: 'SetMerchantLogoMutation';
        merchant:
          | { __typename: 'Merchant'; id: string; name: string; logoUrl: string | null | undefined }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetNotificationCenterQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  afterCursor: InputMaybe<Scalars['String']['input']>;
}>;

export type GetNotificationCenterQuery = {
  __typename: 'Query';
  activityEvents:
    | {
        __typename: 'ActivityConnection';
        pageInfo: {
          __typename: 'PageInfo';
          hasNextPage: boolean;
          endCursor: string | null | undefined;
        };
        edges: Array<
          | {
              __typename: 'ActivityEdge';
              node:
                | {
                    __typename: 'ActivityNode';
                    id: string;
                    createdAt: string;
                    readAt: string | null | undefined;
                    title: string | null | undefined;
                    body: string | null | undefined;
                    action: string | null | undefined;
                    actionLabel: string | null | undefined;
                    eventPriority: EventPriority;
                    isPinned: boolean | null | undefined;
                    logo: string | null | undefined;
                    dismissedAt: string | null | undefined;
                    eventType: ActivityEventType | null | undefined;
                    secondaryUrl: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type GetEntitlementsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEntitlementsQuery = {
  __typename: 'Query';
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    entitlements: Array<SubscriptionEntitlement>;
  };
};

export type GetCanSkipPremiumUpsellQueryVariables = Exact<{ [key: string]: never }>;

export type GetCanSkipPremiumUpsellQuery = { __typename: 'Query'; canSkipPremiumUpsell: boolean };

export type GetFeatureEntitlementParamsQueryVariables = Exact<{ [key: string]: never }>;

export type GetFeatureEntitlementParamsQuery = {
  __typename: 'Query';
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    entitlementParams: {
      __typename: 'EntitlementParams';
      features: Array<{
        __typename: 'FeatureEntitlementItem';
        feature: ProductFeature;
        requiredEntitlements: Array<SubscriptionEntitlement>;
      }>;
      constants: Array<{
        __typename: 'EntitlementConstantItem';
        entitlement: SubscriptionEntitlement;
        maxCredentials: number | null | undefined;
        maxTransactionRules: number | null | undefined;
      }>;
    };
  };
};

export type Common_GetPremiumUpgradePlansQueryVariables = Exact<{
  promoCode: InputMaybe<Scalars['String']['input']>;
}>;

export type Common_GetPremiumUpgradePlansQuery = {
  __typename: 'Query';
  subscriptionOffering: {
    __typename: 'StripeSubscriptionOffering';
    promoCodeError: string | null | undefined;
    promoCodeDescription: string | null | undefined;
    promoCodeDuration: StripeCouponDuration | null | undefined;
    promoCodeDurationInMonths: number | null | undefined;
    plans: Array<{
      __typename: 'SubscriptionPlan';
      name: string;
      period: PaymentPeriod;
      pricePerPeriodDollars: number;
      discountedPricePerPeriodDollars: number | null | undefined;
      stripeId: string;
      newTrialEndsAt: string | null | undefined;
      requirePaymentMethod: boolean;
      sponsoredBy:
        | {
            __typename: 'SubscriptionSponsorInfo';
            name: string;
            email: string;
            profilePictureUrl: string | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    trialEndsAt: string | null | undefined;
    referralRedemption:
      | { __typename: 'ReferralRedemption'; campaign: string | null | undefined }
      | null
      | undefined;
  };
};

export type GetTrialStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetTrialStatusQuery = {
  __typename: 'Query';
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    eligibleForTrial: boolean;
    trialDurationDays: number;
  };
};

export type Common_GetReviewedAccountLastStatementsQueryVariables = Exact<{
  filters: LiabilityStatementFilterInput;
  limit: Scalars['Int']['input'];
}>;

export type Common_GetReviewedAccountLastStatementsQuery = {
  __typename: 'Query';
  creditReportLiabilityStatements:
    | Array<{
        __typename: 'LiabilityStatement';
        id: string;
        dueDate: string;
        billAmount: number | null | undefined;
      }>
    | null
    | undefined;
};

export type Common_GetReviewedMerchantLastTransactionsQueryVariables = Exact<{
  merchantId: Scalars['UUID']['input'];
  endDate: Scalars['Date']['input'];
  isMerchant: Scalars['Boolean']['input'];
}>;

export type Common_GetReviewedMerchantLastTransactionsQuery = {
  __typename: 'Query';
  allTransactions?: {
    __typename: 'TransactionList';
    totalCount: number;
    results: Array<{
      __typename: 'Transaction';
      id: string;
      date: string;
      amount: number;
      isRecurring: boolean;
      category: { __typename: 'Category'; id: string; name: string; icon: string };
    }>;
  };
};

export type RecurringMerchantSearchQueryVariables = Exact<{ [key: string]: never }>;

export type RecurringMerchantSearchQuery = {
  __typename: 'Query';
  recurringMerchantSearch:
    | {
        __typename: 'RecurringMerchantSearchData';
        startedAt: string | null | undefined;
        nextAt: string | null | undefined;
        finishedAt: string | null | undefined;
        createdCount: number | null | undefined;
      }
    | null
    | undefined;
  recurringTransactionStreams: Array<{
    __typename: 'RecurringTransactionItem';
    stream: { __typename: 'RecurringTransactionStream'; id: string };
  }>;
};

export type Common_TriggerRecurringMerchantSearchMutationVariables = Exact<{
  [key: string]: never;
}>;

export type Common_TriggerRecurringMerchantSearchMutation = {
  __typename: 'Mutation';
  triggerRecurringMerchantSearch:
    | { __typename: 'TriggerRecurringMerchantSearchMutation'; success: boolean }
    | null
    | undefined;
};

export type Common_GetRecurringStreamsQueryVariables = Exact<{
  includeLiabilities: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Common_GetRecurringStreamsQuery = {
  __typename: 'Query';
  recurringTransactionStreams: Array<{
    __typename: 'RecurringTransactionItem';
    stream: {
      __typename: 'RecurringTransactionStream';
      id: string;
      reviewStatus: RecurringStreamReviewStatus | null | undefined;
      frequency: string;
      amount: number | null | undefined;
      baseDate: string;
      dayOfTheMonth: number | null | undefined;
      isApproximate: boolean;
      name: string;
      logoUrl: string | null | undefined;
      merchant: { __typename: 'Merchant'; id: string } | null | undefined;
      creditReportLiabilityAccount:
        | {
            __typename: 'CreditReportLiabilityAccount';
            id: string;
            account: { __typename: 'Account'; id: string } | null | undefined;
            lastStatement:
              | { __typename: 'LiabilityStatement'; id: string; dueDate: string }
              | null
              | undefined;
          }
        | null
        | undefined;
    };
  }>;
};

export type Common_ReviewStreamMutationVariables = Exact<{
  input: ReviewRecurringStreamInput;
}>;

export type Common_ReviewStreamMutation = {
  __typename: 'Mutation';
  reviewRecurringStream:
    | {
        __typename: 'ReviewRecurringStreamMutation';
        stream:
          | {
              __typename: 'RecurringTransactionStream';
              id: string;
              reviewStatus: RecurringStreamReviewStatus | null | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ReviewCheckinFieldsFragment = {
  __typename: 'ReviewCheckin';
  id: string;
  completedAt: string | null | undefined;
  lastPageIndexViewed: number;
  reviewStartDate: string;
};

export type GetYearlyReviewStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetYearlyReviewStatusQuery = {
  __typename: 'Query';
  userProfile:
    | { __typename: 'UserProfile'; id: string; dismissedYearlyReviewAt: string | null | undefined }
    | null
    | undefined;
  reviewCheckinsByMonth: Array<{
    __typename: 'ReviewCheckinMonthDetails';
    reviewStartDate: string;
    inProgressCheckin:
      | {
          __typename: 'ReviewCheckin';
          id: string;
          completedAt: string | null | undefined;
          lastPageIndexViewed: number;
          reviewStartDate: string;
        }
      | null
      | undefined;
    completedCheckins: Array<{
      __typename: 'ReviewCheckin';
      id: string;
      completedAt: string | null | undefined;
      lastPageIndexViewed: number;
      reviewStartDate: string;
    }>;
  }>;
};

export type Common_SetHasDismissedYearlyReviewMutationVariables = Exact<{ [key: string]: never }>;

export type Common_SetHasDismissedYearlyReviewMutation = {
  __typename: 'Mutation';
  updateUserProfile:
    | {
        __typename: 'UpdateUserProfile';
        userProfile:
          | {
              __typename: 'UserProfile';
              id: string;
              dismissedYearlyReviewAt: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PreviewTransactionRuleQueryVariables = Exact<{
  rule: TransactionRulePreviewInput;
  offset: InputMaybe<Scalars['Int']['input']>;
}>;

export type PreviewTransactionRuleQuery = {
  __typename: 'Query';
  transactionRulePreview: {
    __typename: 'TransactionPreviewList';
    totalCount: number;
    results: Array<{
      __typename: 'TransactionPreview';
      newName: string | null | undefined;
      newSplitTransactions: boolean | null | undefined;
      newHideFromReports: boolean | null | undefined;
      newCategory:
        | { __typename: 'Category'; id: string; icon: string; name: string }
        | null
        | undefined;
      newTags:
        | Array<{
            __typename: 'TransactionTag';
            id: string;
            name: string;
            color: string;
            order: number;
          }>
        | null
        | undefined;
      newGoal:
        | {
            __typename: 'GoalV2';
            id: string;
            name: string;
            imageStorageProvider: string | null | undefined;
            imageStorageProviderId: string | null | undefined;
          }
        | null
        | undefined;
      transaction: {
        __typename: 'Transaction';
        id: string;
        date: string;
        amount: number;
        merchant: { __typename: 'Merchant'; id: string; name: string };
        category: { __typename: 'Category'; id: string; name: string; icon: string };
      };
    }>;
  };
};

export type Common_GetAggregateSnapshotsQueryVariables = Exact<{
  filters: InputMaybe<AggregateSnapshotFilters>;
}>;

export type Common_GetAggregateSnapshotsQuery = {
  __typename: 'Query';
  aggregateSnapshots: Array<{
    __typename: 'AggregateSnapshot';
    date: string;
    balance: number;
    assetsBalance: number | null | undefined;
    liabilitiesBalance: number | null | undefined;
  }>;
};

export type GetContactSupportFormRecommendedResolutionQueryVariables = Exact<{
  reason: InputMaybe<ContactSupportReason>;
  credentialId: InputMaybe<Scalars['String']['input']>;
}>;

export type GetContactSupportFormRecommendedResolutionQuery = {
  __typename: 'Query';
  recommendedResolutionLinks: {
    __typename: 'ResolutionBlocksData';
    hasResults: boolean;
    messages: Array<{
      __typename: 'ResolutionMessageBlock';
      appearance: MessageBlockAppearance;
      title: string | null | undefined;
      text: string;
    }>;
    links: Array<{
      __typename: 'ResolutionLinkBlock';
      text: string;
      linkTo: string;
      url: string | null | undefined;
    }>;
    buttons: Array<{
      __typename: 'ResolutionButtonBlock';
      text: string;
      linkTo: string;
      url: string | null | undefined;
    }>;
  };
};

export type Web_GetContactSupportFormDataQueryVariables = Exact<{
  formType: InputMaybe<ContactSupportFormType>;
  topic: InputMaybe<ContactSupportTopic>;
  reason: InputMaybe<Scalars['String']['input']>;
}>;

export type Web_GetContactSupportFormDataQuery = {
  __typename: 'Query';
  contactFormData:
    | {
        __typename: 'ContactSupportFormData';
        id: string;
        requiredFieldsForReason: Array<string> | null | undefined;
        topics: Array<{
          __typename: 'ContactSupportTopicField';
          id: string;
          value: ContactSupportTopic;
          label: string;
        }>;
        reasonsForTopic: Array<{
          __typename: 'ContactSupportReasonField';
          id: string;
          value: ContactSupportReason;
          label: string;
        }>;
        impacts: Array<{
          __typename: 'ContactSupportImpactField';
          id: string;
          value: ContactSupportImpact;
          label: string;
        }>;
      }
    | null
    | undefined;
};

export type Mobile_RapidTransactionReviewFormContainerQueryVariables = Exact<{
  transactionId: Scalars['UUID']['input'];
  id: Scalars['ID']['input'];
}>;

export type Mobile_RapidTransactionReviewFormContainerQuery = {
  __typename: 'Query';
  getTransaction:
    | {
        __typename: 'Transaction';
        id: string;
        amount: number;
        pending: boolean;
        date: string;
        notes: string | null | undefined;
        dataProviderDescription: string | null | undefined;
        category: { __typename: 'Category'; id: string; name: string; icon: string };
        merchant: {
          __typename: 'Merchant';
          id: string;
          name: string;
          logoUrl: string | null | undefined;
          transactionCount: number;
        };
        account: {
          __typename: 'Account';
          id: string;
          displayName: string;
          dataProvider: string | null | undefined;
          goalAllocations: Array<{
            __typename: 'GoalAccountAllocation';
            id: string;
            goal: {
              __typename: 'GoalV2';
              id: string;
              name: string;
              imageStorageProvider: string | null | undefined;
              imageStorageProviderId: string | null | undefined;
              priority: number;
              archivedAt: string | null | undefined;
            };
          }>;
          type: { __typename: 'AccountType'; name: string };
          subtype: { __typename: 'AccountSubtype'; name: string };
          institution:
            | {
                __typename: 'Institution';
                id: string;
                logo: string | null | undefined;
                primaryColor: string;
              }
            | null
            | undefined;
        };
        tags: Array<{ __typename: 'TransactionTag'; id: string; name: string; color: string }>;
        goal:
          | {
              __typename: 'GoalV2';
              id: string;
              name: string;
              imageStorageProvider: string | null | undefined;
              imageStorageProviderId: string | null | undefined;
            }
          | null
          | undefined;
        attachments: Array<{
          __typename: 'TransactionAttachment';
          id: string;
          publicId: string;
          extension: string;
          filename: string;
          originalAssetUrl: string;
        }>;
        needsReviewByUser: { __typename: 'User'; id: string } | null | undefined;
      }
    | null
    | undefined;
  recommendedCategories:
    | Array<{
        __typename: 'RecommendedCategory';
        category: { __typename: 'Category'; id: string; name: string; icon: string };
      }>
    | null
    | undefined;
};

export type Mobile_RapidTransactionReviewScreenQueryVariables = Exact<{
  filters: InputMaybe<TransactionFilterInput>;
  orderBy: InputMaybe<TransactionOrdering>;
}>;

export type Mobile_RapidTransactionReviewScreenQuery = {
  __typename: 'Query';
  allTransactions: {
    __typename: 'TransactionList';
    totalCount: number;
    results: Array<{
      __typename: 'Transaction';
      id: string;
      reviewedAt: string | null | undefined;
    }>;
  };
};

export type Common_MarkTransactionAsReviewedMutationVariables = Exact<{
  input: UpdateTransactionMutationInput;
}>;

export type Common_MarkTransactionAsReviewedMutation = {
  __typename: 'Mutation';
  updateTransaction:
    | {
        __typename: 'UpdateTransactionMutation';
        transaction:
          | {
              __typename: 'Transaction';
              id: string;
              reviewedAt: string | null | undefined;
              needsReview: boolean;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetHouseholdTransactionTagsQueryVariables = Exact<{
  search: InputMaybe<Scalars['String']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  bulkParams: InputMaybe<BulkTransactionDataParams>;
  includeTransactionCount?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetHouseholdTransactionTagsQuery = {
  __typename: 'Query';
  householdTransactionTags: Array<{
    __typename: 'TransactionTag';
    id: string;
    name: string;
    color: string;
    order: number;
    transactionCount?: number;
  }>;
};

export type Common_GetTransactionAttachmentUploadInfoMutationVariables = Exact<{
  transactionId: Scalars['UUID']['input'];
}>;

export type Common_GetTransactionAttachmentUploadInfoMutation = {
  __typename: 'Mutation';
  getTransactionAttachmentUploadInfo:
    | {
        __typename: 'GetTransactionAttachmentUploadInfoMutation';
        info:
          | {
              __typename: 'TransactionAttachmentUploadInfo';
              path: string;
              requestParams: {
                __typename: 'TransactionAttachmentUploadRequestParams';
                timestamp: number;
                folder: string;
                signature: string;
                api_key: string;
                upload_preset: string;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_AddTransactionAttachmentMutationVariables = Exact<{
  input: TransactionAddAttachmentMutationInput;
}>;

export type Common_AddTransactionAttachmentMutation = {
  __typename: 'Mutation';
  addTransactionAttachment:
    | {
        __typename: 'TransactionAddAttachmentMutation';
        attachment:
          | {
              __typename: 'TransactionAttachment';
              id: string;
              publicId: string;
              extension: string;
              sizeBytes: number;
              filename: string;
              originalAssetUrl: string;
            }
          | null
          | undefined;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_GetSharedConstantsQueryVariables = Exact<{ [key: string]: never }>;

export type Common_GetSharedConstantsQuery = {
  __typename: 'Query';
  constants: {
    __typename: 'Constants';
    monthlyPriceDollars: number;
    referralsTrialDurationDays: number;
    activeReferralCampaign:
      | { __typename: 'ActiveReferralCampaign'; campaign: ReferralCampaign; creditsEarned: number }
      | null
      | undefined;
    institutionReportIssueTypes: Array<{
      __typename: 'InstitutionReportIssueObjectType';
      name: string;
      displayName: string;
    }>;
    budgetRolloverFrequencies: Array<{
      __typename: 'BudgetRolloverFrequencyInfo';
      value: BudgetRolloverFrequency;
      label: string;
    }>;
  };
};

export type UserProfileFlagsFieldsFragment = {
  __typename: 'UserProfile';
  id: string;
  aiAssistantOptedInAt: string | null | undefined;
  dismissedFlexBudgetingWalkthroughAt: string | null | undefined;
  dismissedRecurringWalkthroughAt: string | null | undefined;
  dismissedSpendingInsightsBanner: boolean | null | undefined;
  dismissedTransactionsListUpdatesTourAt: string | null | undefined;
  dismissedYearlyReviewAt: string | null | undefined;
  hasDismissedWhatsNewAt: string | null | undefined;
  hasSeenCategoriesManagementTour: boolean;
  hasSeenWeeklyReviewTour: boolean;
  viewedMarkAsReviewedUpdatesCalloutAt: string | null | undefined;
};

export type Common_UpdateUserProfileFlagsMutationVariables = Exact<{
  updateProfileInput: UpdateUserProfileInput;
}>;

export type Common_UpdateUserProfileFlagsMutation = {
  __typename: 'Mutation';
  updateUserProfile:
    | {
        __typename: 'UpdateUserProfile';
        userProfile:
          | {
              __typename: 'UserProfile';
              id: string;
              aiAssistantOptedInAt: string | null | undefined;
              dismissedFlexBudgetingWalkthroughAt: string | null | undefined;
              dismissedRecurringWalkthroughAt: string | null | undefined;
              dismissedSpendingInsightsBanner: boolean | null | undefined;
              dismissedTransactionsListUpdatesTourAt: string | null | undefined;
              dismissedYearlyReviewAt: string | null | undefined;
              hasDismissedWhatsNewAt: string | null | undefined;
              hasSeenCategoriesManagementTour: boolean;
              hasSeenWeeklyReviewTour: boolean;
              viewedMarkAsReviewedUpdatesCalloutAt: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Common_UserProfileFlagsQueryVariables = Exact<{ [key: string]: never }>;

export type Common_UserProfileFlagsQuery = {
  __typename: 'Query';
  userProfile:
    | {
        __typename: 'UserProfile';
        id: string;
        aiAssistantOptedInAt: string | null | undefined;
        dismissedFlexBudgetingWalkthroughAt: string | null | undefined;
        dismissedRecurringWalkthroughAt: string | null | undefined;
        dismissedSpendingInsightsBanner: boolean | null | undefined;
        dismissedTransactionsListUpdatesTourAt: string | null | undefined;
        dismissedYearlyReviewAt: string | null | undefined;
        hasDismissedWhatsNewAt: string | null | undefined;
        hasSeenCategoriesManagementTour: boolean;
        hasSeenWeeklyReviewTour: boolean;
        viewedMarkAsReviewedUpdatesCalloutAt: string | null | undefined;
      }
    | null
    | undefined;
};

export type Common_SetUserProfilePictureMutationVariables = Exact<{
  input: SetUserProfilePictureInput;
}>;

export type Common_SetUserProfilePictureMutation = {
  __typename: 'Mutation';
  setUserProfilePicture:
    | {
        __typename: 'SetUserProfilePictureMutation';
        profilePicture:
          | {
              __typename: 'UserProfilePicture';
              id: string;
              cloudinaryPublicId: string | null | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AccountComponentFieldsFragment = {
  __typename: 'Account';
  id: string;
  isAsset: boolean;
  displayName: string;
  includeInNetWorth: boolean;
  syncDisabled: boolean;
  displayBalance: number | null | undefined;
  displayLastUpdatedAt: string;
  dataProvider: string | null | undefined;
  credential:
    | {
        __typename: 'Credential';
        id: string;
        dataProvider: DataProviderLegacy;
        updateRequired: boolean;
        disconnectedFromDataProviderAt: string | null | undefined;
        institution: {
          __typename: 'Institution';
          id: string;
          status: InstitutionStatus | null | undefined;
        };
      }
    | null
    | undefined;
  type: { __typename: 'AccountType'; name: string };
  subtype: { __typename: 'AccountSubtype'; name: string };
  institution:
    | {
        __typename: 'Institution';
        id: string;
        logo: string | null | undefined;
        primaryColor: string;
      }
    | null
    | undefined;
};

export type AccountMaskFieldsMobileFragment = {
  __typename: 'Account';
  id: string;
  mask: string | null | undefined;
  subtype: { __typename: 'AccountSubtype'; display: string };
};

export type AccountSummaryCardAccountFieldsFragment = {
  __typename: 'Account';
  id: string;
  isManual: boolean;
  dataProvider: string | null | undefined;
  dataProviderAccountId: string | null | undefined;
  holdingsCount: number;
  transactionsCount: number;
  displayLastUpdatedAt: string;
  manualInvestmentsTrackingMethod: string | null | undefined;
  type: { __typename: 'AccountType'; name: string };
  subtype: { __typename: 'AccountSubtype'; display: string };
  institution:
    | { __typename: 'Institution'; id: string; name: string; url: string }
    | null
    | undefined;
};

export type ConnectionStatusCardFieldsFragment = {
  __typename: 'Account';
  id: string;
  displayLastUpdatedAt: string;
  syncDisabled: boolean;
  deactivatedAt: string | null | undefined;
  credential:
    | {
        __typename: 'Credential';
        id: string;
        dataProvider: DataProviderLegacy;
        updateRequired: boolean;
        institution: {
          __typename: 'Institution';
          id: string;
          name: string;
          balanceStatus: InstitutionStatus | null | undefined;
          transactionsStatus: InstitutionStatus | null | undefined;
        };
      }
    | null
    | undefined;
};

export type Mobile_AccountsMessageBlockQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type Mobile_AccountsMessageBlockQuery = {
  __typename: 'Query';
  accounts: Array<{
    __typename: 'Account';
    id: string;
    isAsset: boolean;
    displayName: string;
    includeInNetWorth: boolean;
    syncDisabled: boolean;
    displayBalance: number | null | undefined;
    displayLastUpdatedAt: string;
    dataProvider: string | null | undefined;
    credential:
      | {
          __typename: 'Credential';
          id: string;
          dataProvider: DataProviderLegacy;
          updateRequired: boolean;
          disconnectedFromDataProviderAt: string | null | undefined;
          institution: {
            __typename: 'Institution';
            id: string;
            status: InstitutionStatus | null | undefined;
          };
        }
      | null
      | undefined;
    type: { __typename: 'AccountType'; name: string };
    subtype: { __typename: 'AccountSubtype'; name: string };
    institution:
      | {
          __typename: 'Institution';
          id: string;
          logo: string | null | undefined;
          primaryColor: string;
        }
      | null
      | undefined;
  }>;
};

export type Mobile_AssistantMessageListGroupFieldsFragment = {
  __typename: 'AssistantMessage';
  id: string;
  threadId: string;
  createdAt: string;
  content: string;
  name: string;
  suggestedPrompts: Array<string> | null | undefined;
  debugInfo: string | null | undefined;
};

export type Mobile_AssistantStatusMessageListGroupFieldsFragment = {
  __typename: 'AssistantStatusMessage';
  id: string;
  createdAt: string;
  threadId: string;
  content: string;
  name: string;
};

export type Mobile_GoalsMessageBlockQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type Mobile_GoalsMessageBlockQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    name: string;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
    currentAmount: number | null | undefined;
    completionPercent: number | null | undefined;
    completedAt: string | null | undefined;
    archivedAt: string | null | undefined;
    type: string;
    accountAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      currentMonthChange:
        | {
            __typename: 'GoalAccountAllocationCurrentMonthChange';
            percent: number | null | undefined;
            amount: number | null | undefined;
          }
        | null
        | undefined;
    }>;
  }>;
};

type Mobile_MessageListGroupFields_AssistantMessage_Fragment = {
  __typename: 'AssistantMessage';
  id: string;
  threadId: string;
  createdAt: string;
  content: string;
  name: string;
  suggestedPrompts: Array<string> | null | undefined;
  debugInfo: string | null | undefined;
};

type Mobile_MessageListGroupFields_AssistantStatusMessage_Fragment = {
  __typename: 'AssistantStatusMessage';
  id: string;
  createdAt: string;
  threadId: string;
  content: string;
  name: string;
};

type Mobile_MessageListGroupFields_UserMessage_Fragment = {
  __typename: 'UserMessage';
  id: string;
  threadId: string;
  createdAt: string;
  content: string;
  user: {
    __typename: 'User';
    id: string;
    name: string;
    profilePictureUrl: string | null | undefined;
  };
};

export type Mobile_MessageListGroupFieldsFragment =
  | Mobile_MessageListGroupFields_AssistantMessage_Fragment
  | Mobile_MessageListGroupFields_AssistantStatusMessage_Fragment
  | Mobile_MessageListGroupFields_UserMessage_Fragment;

export type Mobile_MessageThreadListItemFieldsFragment = {
  __typename: 'MessageThread';
  id: string;
  subject: string | null | undefined;
  createdAt: string;
  lastMessageSentAt: string | null | undefined;
  hasOutstandingAssistantRequests: boolean;
};

export type Mobile_TransactionsMessageBlockQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type Mobile_TransactionsMessageBlockQuery = {
  __typename: 'Query';
  allTransactions: {
    __typename: 'TransactionList';
    results: Array<{
      __typename: 'Transaction';
      id: string;
      amount: number;
      pending: boolean;
      notes: string | null | undefined;
      hideFromReports: boolean;
      isSplitTransaction: boolean;
      isRecurring: boolean;
      merchant: { __typename: 'Merchant'; id: string; name: string };
      category: { __typename: 'Category'; id: string; name: string; icon: string };
    }>;
  };
};

export type Mobile_UserMessageListGroupFieldsFragment = {
  __typename: 'UserMessage';
  id: string;
  threadId: string;
  createdAt: string;
  content: string;
  user: {
    __typename: 'User';
    id: string;
    name: string;
    profilePictureUrl: string | null | undefined;
  };
};

export type GoalDashboardRowFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  name: string;
  imageStorageProvider: string | null | undefined;
  imageStorageProviderId: string | null | undefined;
  currentAmount: number | null | undefined;
  completionPercent: number | null | undefined;
  completedAt: string | null | undefined;
  archivedAt: string | null | undefined;
  type: string;
  accountAllocations: Array<{
    __typename: 'GoalAccountAllocation';
    id: string;
    currentMonthChange:
      | {
          __typename: 'GoalAccountAllocationCurrentMonthChange';
          percent: number | null | undefined;
          amount: number | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type Mobile_GoalsDashboardCardV2QueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_GoalsDashboardCardV2Query = {
  __typename: 'Query';
  migratedToGoalsV2: boolean;
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    priority: number;
    name: string;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
    currentAmount: number | null | undefined;
    completionPercent: number | null | undefined;
    completedAt: string | null | undefined;
    archivedAt: string | null | undefined;
    type: string;
    accountAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      currentMonthChange:
        | {
            __typename: 'GoalAccountAllocationCurrentMonthChange';
            percent: number | null | undefined;
            amount: number | null | undefined;
          }
        | null
        | undefined;
    }>;
  }>;
  goals: Array<{ __typename: 'Goal'; id: string }>;
};

export type Mobile_GetInvestmentsDashboardCardQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_GetInvestmentsDashboardCardQuery = {
  __typename: 'Query';
  portfolio: {
    __typename: 'Portfolio';
    performance: {
      __typename: 'PortfolioPerformance';
      totalValue: number;
      oneDayChangeDollars: number;
      topMovers: Array<{
        __typename: 'Security';
        id: string;
        ticker: string | null | undefined;
        oneDayChangePercent: number | null | undefined;
        currentPrice: number | null | undefined;
      }>;
    };
  };
};

export type Mobile_GetDashboardUpcomingRecurringTransactionItemsQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
  includeLiabilities: InputMaybe<Scalars['Boolean']['input']>;
  filters: InputMaybe<RecurringTransactionFilter>;
}>;

export type Mobile_GetDashboardUpcomingRecurringTransactionItemsQuery = {
  __typename: 'Query';
  recurringRemainingDue: { __typename: 'RecurringRemainingDueData'; amount: number };
  recurringTransactionItems: Array<{
    __typename: 'RecurringTransactionCalendarItem';
    isPast: boolean;
    date: string;
    amount: number | null | undefined;
    stream: {
      __typename: 'RecurringTransactionStream';
      id: string;
      frequency: string;
      name: string;
      logoUrl: string | null | undefined;
      merchant:
        | { __typename: 'Merchant'; id: string; name: string; logoUrl: string | null | undefined }
        | null
        | undefined;
    };
  }>;
};

export type Mobile_ReviewDashboardQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_ReviewDashboardQuery = {
  __typename: 'Query';
  reviewCheckinsByMonth: Array<{
    __typename: 'ReviewCheckinMonthDetails';
    reviewStartDate: string;
    inProgressCheckin:
      | {
          __typename: 'ReviewCheckin';
          id: string;
          completedAt: string | null | undefined;
          lastPageIndexViewed: number;
          reviewStartDate: string;
        }
      | null
      | undefined;
    completedCheckins: Array<{
      __typename: 'ReviewCheckin';
      id: string;
      completedAt: string | null | undefined;
      lastPageIndexViewed: number;
      reviewStartDate: string;
    }>;
  }>;
  me: { __typename: 'User'; id: string; createdAt: string };
};

export type Mobile_ReviewTransactionsDashboardButtonQueryVariables = Exact<{
  filters: InputMaybe<TransactionFilterInput>;
}>;

export type Mobile_ReviewTransactionsDashboardButtonQuery = {
  __typename: 'Query';
  allTransactions: {
    __typename: 'TransactionList';
    totalCount: number;
    results: Array<{ __typename: 'Transaction'; id: string }>;
  };
};

export type CustomizeGoalFormFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  name: string;
  imageStorageProvider: string | null | undefined;
  imageStorageProviderId: string | null | undefined;
  targetAmount: number | null | undefined;
};

export type GoalAccountBalanceRowFieldsFragment = {
  __typename: 'GoalAccountAllocation';
  id: string;
  currentAmount: number | null | undefined;
  currentMonthChange:
    | {
        __typename: 'GoalAccountAllocationCurrentMonthChange';
        percent: number | null | undefined;
        amount: number | null | undefined;
      }
    | null
    | undefined;
  account: {
    __typename: 'Account';
    id: string;
    displayName: string;
    hideFromList: boolean;
    deactivatedAt: string | null | undefined;
    mask: string | null | undefined;
    dataProvider: string | null | undefined;
    subtype: { __typename: 'AccountSubtype'; display: string; name: string };
    type: { __typename: 'AccountType'; name: string };
    institution:
      | {
          __typename: 'Institution';
          id: string;
          logo: string | null | undefined;
          primaryColor: string;
        }
      | null
      | undefined;
  };
};

export type GoalCardFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  imageStorageProvider: string | null | undefined;
  imageStorageProviderId: string | null | undefined;
  priority: number;
  archivedAt: string | null | undefined;
  name: string;
  targetAmount: number | null | undefined;
  startingAmount: number | null | undefined;
  currentAmount: number | null | undefined;
  completedAt: string | null | undefined;
  type: string;
  defaultName: string | null | undefined;
  completionPercent: number | null | undefined;
  accountAllocations: Array<{
    __typename: 'GoalAccountAllocation';
    id: string;
    account: {
      __typename: 'Account';
      id: string;
      dataProvider: string | null | undefined;
      type: { __typename: 'AccountType'; name: string };
      subtype: { __typename: 'AccountSubtype'; name: string };
      institution:
        | {
            __typename: 'Institution';
            id: string;
            logo: string | null | undefined;
            primaryColor: string;
          }
        | null
        | undefined;
    };
  }>;
};

export type GoalContributionGraphFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  plannedMonthlyContribution: number | null | undefined;
  monthlyContributionSummaries: Array<{
    __typename: 'GoalContributionMonthlySummary';
    month: string;
    sum: number;
    sumCredit: number;
    sumDebit: number;
    plannedAmount: number | null | undefined;
  }>;
};

export type GoalDetailsFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  priority: number;
  plannedMonthlyContribution: number | null | undefined;
  plannedMonthlyPretaxContribution: number | null | undefined;
  targetAmount: number | null | undefined;
  startingAmount: number | null | undefined;
  archivedAt: string | null | undefined;
  type: string;
  objective: string;
  accountAllocations: Array<{
    __typename: 'GoalAccountAllocation';
    id: string;
    currentAmount: number | null | undefined;
    currentMonthChange:
      | {
          __typename: 'GoalAccountAllocationCurrentMonthChange';
          percent: number | null | undefined;
          amount: number | null | undefined;
        }
      | null
      | undefined;
    account: {
      __typename: 'Account';
      id: string;
      displayName: string;
      hideFromList: boolean;
      deactivatedAt: string | null | undefined;
      mask: string | null | undefined;
      dataProvider: string | null | undefined;
      subtype: { __typename: 'AccountSubtype'; display: string; name: string };
      type: { __typename: 'AccountType'; name: string };
      institution:
        | {
            __typename: 'Institution';
            id: string;
            logo: string | null | undefined;
            primaryColor: string;
          }
        | null
        | undefined;
    };
  }>;
  monthlyContributionSummaries: Array<{
    __typename: 'GoalContributionMonthlySummary';
    month: string;
    sum: number;
    sumCredit: number;
    sumDebit: number;
    plannedAmount: number | null | undefined;
  }>;
};

export type GoalInfoRowFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  name: string;
  priority: number;
  imageStorageProvider: string | null | undefined;
  imageStorageProviderId: string | null | undefined;
};

export type GoalNameProgressBarFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  name: string;
  targetAmount: number | null | undefined;
  startingAmount: number | null | undefined;
  currentAmount: number | null | undefined;
  completedAt: string | null | undefined;
  archivedAt: string | null | undefined;
  type: string;
  defaultName: string | null | undefined;
  completionPercent: number | null | undefined;
  accountAllocations: Array<{ __typename: 'GoalAccountAllocation'; id: string }>;
};

export type MobileGoalsAllocateBannerFieldsFragment = {
  __typename: 'Account';
  id: string;
  name: string | null | undefined;
  dataProvider: string | null | undefined;
  type: { __typename: 'AccountType'; name: string };
  subtype: { __typename: 'AccountSubtype'; name: string };
  institution:
    | {
        __typename: 'Institution';
        id: string;
        logo: string | null | undefined;
        primaryColor: string;
      }
    | null
    | undefined;
};

export type RankGoalRowFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  name: string;
  priority: number;
  imageStorageProvider: string | null | undefined;
  imageStorageProviderId: string | null | undefined;
};

export type CredentialCardFieldsFragment = {
  __typename: 'Credential';
  id: string;
  updateRequired: boolean;
  dataProvider: DataProviderLegacy;
  disconnectedFromDataProviderAt: string | null | undefined;
  displayLastUpdatedAt: string | null | undefined;
  institution: {
    __typename: 'Institution';
    id: string;
    name: string;
    logo: string | null | undefined;
    url: string;
    hasIssuesReported: boolean;
    hasIssuesReportedMessage: string;
    status: InstitutionStatus | null | undefined;
    balanceStatus: InstitutionStatus | null | undefined;
    transactionsStatus: InstitutionStatus | null | undefined;
  };
};

export type InstitutionAccountRowFieldsFragment = {
  __typename: 'Account';
  id: string;
  deletedAt: string | null | undefined;
  displayName: string;
  mask: string | null | undefined;
  subtype: { __typename: 'AccountSubtype'; name: string; display: string };
};

export type Mobile_InstitutionInfoFieldsFragment = {
  __typename: 'Credential';
  id: string;
  displayLastUpdatedAt: string | null | undefined;
  disconnectedFromDataProviderAt: string | null | undefined;
  dataProvider: DataProviderLegacy;
  updateRequired: boolean;
  institution: {
    __typename: 'Institution';
    id: string;
    name: string;
    hasIssuesReported: boolean;
    hasIssuesReportedMessage: string;
    logo: string | null | undefined;
    status: InstitutionStatus | null | undefined;
    balanceStatus: InstitutionStatus | null | undefined;
    transactionsStatus: InstitutionStatus | null | undefined;
  };
};

export type Mobile_InstitutionLogoWithStatusFieldsFragment = {
  __typename: 'Credential';
  dataProvider: DataProviderLegacy;
  updateRequired: boolean;
  institution: {
    __typename: 'Institution';
    hasIssuesReported: boolean;
    logo: string | null | undefined;
    status: InstitutionStatus | null | undefined;
    balanceStatus: InstitutionStatus | null | undefined;
    transactionsStatus: InstitutionStatus | null | undefined;
  };
};

export type Mobile_InstitutionRowFieldsFragment = {
  __typename: 'Institution';
  id: string;
  name: string;
  logo: string | null | undefined;
  url: string;
  status: InstitutionStatus | null | undefined;
  preferredDataProvider: DataProviderLegacy;
  firstBackupDataProvider: DataProviderLegacy | null | undefined;
  plaidInstitutionId: string | null | undefined;
  finicityInstitutionId: string | null | undefined;
  mxInstitutionId: string | null | undefined;
  hasIssuesReported: boolean;
  hasIssuesReportedMessage: string;
  linkFlowWarnBeforeConnecting: boolean;
  linkFlowWarningMessage: string | null | undefined;
  linkFlowWarningTitle: string | null | undefined;
  largeLogo: { __typename: 'FileType'; url: string | null | undefined } | null | undefined;
};

export type Mobile_GetInvestmentsHoldingsListQueryVariables = Exact<{
  portfolioInput: InputMaybe<PortfolioInput>;
  oneWeekInput: InputMaybe<DateRange>;
  oneMonthInput: InputMaybe<DateRange>;
  threeMonthsInput: InputMaybe<DateRange>;
  sixMonthsInput: InputMaybe<DateRange>;
  yearToDateInput: InputMaybe<DateRange>;
  oneYearInput: InputMaybe<DateRange>;
}>;

export type Mobile_GetInvestmentsHoldingsListQuery = {
  __typename: 'Query';
  portfolio: {
    __typename: 'Portfolio';
    aggregateHoldings: {
      __typename: 'AggregateHoldingConnection';
      edges: Array<
        | {
            __typename: 'AggregateHoldingEdge';
            node:
              | {
                  __typename: 'AggregateHolding';
                  id: string;
                  totalValue: number;
                  basis: number;
                  quantity: number;
                  oneWeek:
                    | {
                        __typename: 'SecurityPriceChange';
                        changePercent: number | null | undefined;
                        changeDollars: number | null | undefined;
                      }
                    | null
                    | undefined;
                  oneMonth:
                    | {
                        __typename: 'SecurityPriceChange';
                        changePercent: number | null | undefined;
                        changeDollars: number | null | undefined;
                      }
                    | null
                    | undefined;
                  threeMonths:
                    | {
                        __typename: 'SecurityPriceChange';
                        changePercent: number | null | undefined;
                        changeDollars: number | null | undefined;
                      }
                    | null
                    | undefined;
                  sixMonths:
                    | {
                        __typename: 'SecurityPriceChange';
                        changePercent: number | null | undefined;
                        changeDollars: number | null | undefined;
                      }
                    | null
                    | undefined;
                  yearToDate:
                    | {
                        __typename: 'SecurityPriceChange';
                        changePercent: number | null | undefined;
                        changeDollars: number | null | undefined;
                      }
                    | null
                    | undefined;
                  oneYear:
                    | {
                        __typename: 'SecurityPriceChange';
                        changePercent: number | null | undefined;
                        changeDollars: number | null | undefined;
                      }
                    | null
                    | undefined;
                  holdings: Array<{
                    __typename: 'Holding';
                    id: string;
                    type: string | null | undefined;
                    typeDisplay: string | null | undefined;
                    name: string | null | undefined;
                    ticker: string | null | undefined;
                    isManual: boolean | null | undefined;
                    quantity: number | null | undefined;
                    costBasis: number | null | undefined;
                    value: number | null | undefined;
                    closingPrice: number | null | undefined;
                    closingPriceUpdatedAt: string | null | undefined;
                    account:
                      | {
                          __typename: 'Account';
                          id: string;
                          displayName: string;
                          dataProvider: string | null | undefined;
                          subtype: { __typename: 'AccountSubtype'; display: string; name: string };
                          type: { __typename: 'AccountType'; name: string };
                          institution:
                            | {
                                __typename: 'Institution';
                                id: string;
                                logo: string | null | undefined;
                                primaryColor: string;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }>;
                  security:
                    | {
                        __typename: 'Security';
                        id: string;
                        name: string;
                        type: string;
                        ticker: string | null | undefined;
                        typeDisplay: string;
                        currentPrice: number | null | undefined;
                        closingPrice: number | null | undefined;
                        currentPriceUpdatedAt: string | null | undefined;
                        closingPriceUpdatedAt: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >;
    };
  };
};

export type Mobile_GetSecuritiesHistoricalChartQueryVariables = Exact<{
  input: SecurityHistoricalPerformanceInput;
}>;

export type Mobile_GetSecuritiesHistoricalChartQuery = {
  __typename: 'Query';
  securityHistoricalPerformance: Array<{
    __typename: 'SecurityPerformance';
    historicalChart: Array<{
      __typename: 'SecurityPerformanceChartDatum';
      date: string;
      returnPercent: number;
      value: number;
    }>;
  }>;
};

export type AllocationChartFieldsMobileFragment = {
  __typename: 'Portfolio';
  allocationSimple: Array<
    | {
        __typename: 'SimplePortfolioAliquot';
        type: string;
        typeDisplay: string;
        allocationPercent: number;
        totalValue: number;
      }
    | null
    | undefined
  >;
  performance: { __typename: 'PortfolioPerformance'; totalValue: number };
};

export type InvestmentChartsFieldsMobileFragment = {
  __typename: 'Portfolio';
  allocationSimple: Array<
    | {
        __typename: 'SimplePortfolioAliquot';
        type: string;
        typeDisplay: string;
        allocationPercent: number;
        totalValue: number;
      }
    | null
    | undefined
  >;
  performance: { __typename: 'PortfolioPerformance'; totalValue: number };
};

export type Mobile_GetMerchantsListScreenQueryVariables = Exact<{
  offset: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  orderBy: InputMaybe<MerchantOrdering>;
}>;

export type Mobile_GetMerchantsListScreenQuery = {
  __typename: 'Query';
  merchantCount: number;
  merchants: Array<{
    __typename: 'Merchant';
    id: string;
    name: string;
    transactionCount: number;
    logoUrl: string | null | undefined;
  }>;
};

export type Mobile_DrawerMenuQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_DrawerMenuQuery = {
  __typename: 'Query';
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    trialEndsAt: string | null | undefined;
    trialDurationDays: number;
    trialType: TrialType | null | undefined;
  };
};

export type FreeTrialDurationPanelFieldsFragment = {
  __typename: 'HouseholdSubscription';
  id: string;
  trialEndsAt: string | null | undefined;
  trialDurationDays: number;
  trialType: TrialType | null | undefined;
};

export type Mobile_GetEventPopoverTransactionsQueryVariables = Exact<{
  merchantId: Scalars['UUID']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type Mobile_GetEventPopoverTransactionsQuery = {
  __typename: 'Query';
  allTransactions: {
    __typename: 'TransactionList';
    totalCount: number;
    results: Array<{
      __typename: 'Transaction';
      id: string;
      date: string;
      amount: number;
      isRecurring: boolean;
    }>;
  };
};

export type PageBackgroundFieldsFragment = {
  __typename: 'ReviewPageBackground';
  color: string | null | undefined;
  gradient: { __typename: 'Gradient'; colors: Array<string> } | null | undefined;
};

export type ReviewHeaderFieldsFragment = {
  __typename: 'ReviewPage';
  header:
    | {
        __typename: 'AccountsListBlock';
        accountType: string | null | undefined;
        displayBalanceAsOf: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'DividerBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'GoalContributionsListBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'GroupBlock';
        centered: boolean | null | undefined;
        children: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'DividerBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'GoalContributionsListBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'GroupBlock';
              centered: boolean | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | {
              __typename: 'ShareMonarchBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | {
              __typename: 'SuccessBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
        >;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'HorizontalBarChartBlock';
        topRightLabel: string | null | undefined;
        smallBarStyle: boolean | null | undefined;
        bars: Array<{
          __typename: 'HorizontalBarChartBar';
          label: string | null | undefined;
          barPercent: number;
          barColor: string;
          rightLabel: string | null | undefined;
          leftLabel: string | null | undefined;
          link:
            | {
                __typename: 'BlockLink';
                title: string | null | undefined;
                blocks: Array<
                  | {
                      __typename: 'AccountsListBlock';
                      accountType: string | null | undefined;
                      displayBalanceAsOf: string | null | undefined;
                    }
                  | { __typename: 'DividerBlock' }
                  | { __typename: 'GoalContributionsListBlock' }
                  | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                  | {
                      __typename: 'HorizontalBarChartBlock';
                      topRightLabel: string | null | undefined;
                      smallBarStyle: boolean | null | undefined;
                      bars: Array<{
                        __typename: 'HorizontalBarChartBar';
                        label: string | null | undefined;
                        barPercent: number;
                        barColor: string;
                        rightLabel: string | null | undefined;
                        leftLabel: string | null | undefined;
                      }>;
                    }
                  | {
                      __typename: 'InfoBlock';
                      label: string | null | undefined;
                      value: string | null | undefined;
                    }
                  | {
                      __typename: 'MerchantsListBlock';
                      merchants:
                        | Array<{
                            __typename: 'ReviewMerchant';
                            id: string;
                            name: string;
                            logoUrl: string | null | undefined;
                            transactionCount: number;
                          }>
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'NetWorthChartBlock';
                      snapshotsByAccountType: Array<{
                        __typename: 'SnapshotByType';
                        accountType: string;
                        month: string;
                        balance: number;
                      }>;
                    }
                  | {
                      __typename: 'PageTitleBlock';
                      header: string | null | undefined;
                      title: string | null | undefined;
                      subtitle: string | null | undefined;
                      centerSubtitle: boolean | null | undefined;
                    }
                  | {
                      __typename: 'PieChartBlock';
                      slices: Array<{
                        __typename: 'PieChartSlice';
                        label: string;
                        value: number;
                        color: string;
                      }>;
                    }
                  | { __typename: 'ShareMonarchBlock' }
                  | {
                      __typename: 'ShareableBlock';
                      title: string | null | undefined;
                      subtitle: string | null | undefined;
                      sharedTitle: string | null | undefined;
                      sharedSubtitle: string | null | undefined;
                      sharedFooter: string | null | undefined;
                      sharedContent: string | null | undefined;
                      shareButtonText: string | null | undefined;
                    }
                  | {
                      __typename: 'SparklineRowBlock';
                      label: string | null | undefined;
                      rightLabel: string | null | undefined;
                      labelColor: string | null | undefined;
                      lineColor: string | null | undefined;
                      values: Array<number>;
                      title: string | null | undefined;
                      largeStyle: boolean | null | undefined;
                      hideValueChange: boolean | null | undefined;
                    }
                  | {
                      __typename: 'StackedBarChartBlock';
                      groupColors: Array<string>;
                      data: Array<{
                        __typename: 'StackedBarChartDataPoint';
                        x: string;
                        total: number | null | undefined;
                        groups: Array<number>;
                      }>;
                    }
                  | { __typename: 'SuccessBlock' }
                  | {
                      __typename: 'TextBlock';
                      content: string;
                      fontSize: number | null | undefined;
                      fontWeight: string | null | undefined;
                      color: string | null | undefined;
                    }
                  | {
                      __typename: 'TransactionsListBlock';
                      categories: Array<string> | null | undefined;
                      startDate: string | null | undefined;
                      endDate: string | null | undefined;
                    }
                >;
              }
            | null
            | undefined;
        }>;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'InfoBlock';
        label: string | null | undefined;
        value: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'MerchantsListBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
        merchants:
          | Array<{
              __typename: 'ReviewMerchant';
              id: string;
              name: string;
              logoUrl: string | null | undefined;
              transactionCount: number;
            }>
          | null
          | undefined;
      }
    | {
        __typename: 'NetWorthChartBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
        snapshotsByAccountType: Array<{
          __typename: 'SnapshotByType';
          accountType: string;
          month: string;
          balance: number;
        }>;
      }
    | {
        __typename: 'PageTitleBlock';
        header: string | null | undefined;
        title: string | null | undefined;
        subtitle: string | null | undefined;
        centerSubtitle: boolean | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'PieChartBlock';
        slices: Array<{
          __typename: 'PieChartSlice';
          label: string;
          value: number;
          color: string;
          link:
            | {
                __typename: 'BlockLink';
                title: string | null | undefined;
                blocks: Array<
                  | {
                      __typename: 'AccountsListBlock';
                      accountType: string | null | undefined;
                      displayBalanceAsOf: string | null | undefined;
                    }
                  | { __typename: 'DividerBlock' }
                  | { __typename: 'GoalContributionsListBlock' }
                  | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                  | {
                      __typename: 'HorizontalBarChartBlock';
                      topRightLabel: string | null | undefined;
                      smallBarStyle: boolean | null | undefined;
                      bars: Array<{
                        __typename: 'HorizontalBarChartBar';
                        label: string | null | undefined;
                        barPercent: number;
                        barColor: string;
                        rightLabel: string | null | undefined;
                        leftLabel: string | null | undefined;
                      }>;
                    }
                  | {
                      __typename: 'InfoBlock';
                      label: string | null | undefined;
                      value: string | null | undefined;
                    }
                  | {
                      __typename: 'MerchantsListBlock';
                      merchants:
                        | Array<{
                            __typename: 'ReviewMerchant';
                            id: string;
                            name: string;
                            logoUrl: string | null | undefined;
                            transactionCount: number;
                          }>
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'NetWorthChartBlock';
                      snapshotsByAccountType: Array<{
                        __typename: 'SnapshotByType';
                        accountType: string;
                        month: string;
                        balance: number;
                      }>;
                    }
                  | {
                      __typename: 'PageTitleBlock';
                      header: string | null | undefined;
                      title: string | null | undefined;
                      subtitle: string | null | undefined;
                      centerSubtitle: boolean | null | undefined;
                    }
                  | {
                      __typename: 'PieChartBlock';
                      slices: Array<{
                        __typename: 'PieChartSlice';
                        label: string;
                        value: number;
                        color: string;
                      }>;
                    }
                  | { __typename: 'ShareMonarchBlock' }
                  | {
                      __typename: 'ShareableBlock';
                      title: string | null | undefined;
                      subtitle: string | null | undefined;
                      sharedTitle: string | null | undefined;
                      sharedSubtitle: string | null | undefined;
                      sharedFooter: string | null | undefined;
                      sharedContent: string | null | undefined;
                      shareButtonText: string | null | undefined;
                    }
                  | {
                      __typename: 'SparklineRowBlock';
                      label: string | null | undefined;
                      rightLabel: string | null | undefined;
                      labelColor: string | null | undefined;
                      lineColor: string | null | undefined;
                      values: Array<number>;
                      title: string | null | undefined;
                      largeStyle: boolean | null | undefined;
                      hideValueChange: boolean | null | undefined;
                    }
                  | {
                      __typename: 'StackedBarChartBlock';
                      groupColors: Array<string>;
                      data: Array<{
                        __typename: 'StackedBarChartDataPoint';
                        x: string;
                        total: number | null | undefined;
                        groups: Array<number>;
                      }>;
                    }
                  | { __typename: 'SuccessBlock' }
                  | {
                      __typename: 'TextBlock';
                      content: string;
                      fontSize: number | null | undefined;
                      fontWeight: string | null | undefined;
                      color: string | null | undefined;
                    }
                  | {
                      __typename: 'TransactionsListBlock';
                      categories: Array<string> | null | undefined;
                      startDate: string | null | undefined;
                      endDate: string | null | undefined;
                    }
                >;
              }
            | null
            | undefined;
        }>;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'ShareMonarchBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'ShareableBlock';
        title: string | null | undefined;
        subtitle: string | null | undefined;
        sharedTitle: string | null | undefined;
        sharedSubtitle: string | null | undefined;
        sharedFooter: string | null | undefined;
        sharedContent: string | null | undefined;
        shareButtonText: string | null | undefined;
        children: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'DividerBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'GoalContributionsListBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'GroupBlock';
              centered: boolean | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | {
              __typename: 'ShareMonarchBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | {
              __typename: 'SuccessBlock';
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
              link:
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined;
            }
        >;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'SparklineRowBlock';
        label: string | null | undefined;
        rightLabel: string | null | undefined;
        labelColor: string | null | undefined;
        lineColor: string | null | undefined;
        values: Array<number>;
        title: string | null | undefined;
        largeStyle: boolean | null | undefined;
        hideValueChange: boolean | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'StackedBarChartBlock';
        groupColors: Array<string>;
        data: Array<{
          __typename: 'StackedBarChartDataPoint';
          x: string;
          total: number | null | undefined;
          groups: Array<number>;
          links:
            | Array<
                | {
                    __typename: 'BlockLink';
                    title: string | null | undefined;
                    blocks: Array<
                      | {
                          __typename: 'AccountsListBlock';
                          accountType: string | null | undefined;
                          displayBalanceAsOf: string | null | undefined;
                        }
                      | { __typename: 'DividerBlock' }
                      | { __typename: 'GoalContributionsListBlock' }
                      | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                      | {
                          __typename: 'HorizontalBarChartBlock';
                          topRightLabel: string | null | undefined;
                          smallBarStyle: boolean | null | undefined;
                          bars: Array<{
                            __typename: 'HorizontalBarChartBar';
                            label: string | null | undefined;
                            barPercent: number;
                            barColor: string;
                            rightLabel: string | null | undefined;
                            leftLabel: string | null | undefined;
                          }>;
                        }
                      | {
                          __typename: 'InfoBlock';
                          label: string | null | undefined;
                          value: string | null | undefined;
                        }
                      | {
                          __typename: 'MerchantsListBlock';
                          merchants:
                            | Array<{
                                __typename: 'ReviewMerchant';
                                id: string;
                                name: string;
                                logoUrl: string | null | undefined;
                                transactionCount: number;
                              }>
                            | null
                            | undefined;
                        }
                      | {
                          __typename: 'NetWorthChartBlock';
                          snapshotsByAccountType: Array<{
                            __typename: 'SnapshotByType';
                            accountType: string;
                            month: string;
                            balance: number;
                          }>;
                        }
                      | {
                          __typename: 'PageTitleBlock';
                          header: string | null | undefined;
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          centerSubtitle: boolean | null | undefined;
                        }
                      | {
                          __typename: 'PieChartBlock';
                          slices: Array<{
                            __typename: 'PieChartSlice';
                            label: string;
                            value: number;
                            color: string;
                          }>;
                        }
                      | { __typename: 'ShareMonarchBlock' }
                      | {
                          __typename: 'ShareableBlock';
                          title: string | null | undefined;
                          subtitle: string | null | undefined;
                          sharedTitle: string | null | undefined;
                          sharedSubtitle: string | null | undefined;
                          sharedFooter: string | null | undefined;
                          sharedContent: string | null | undefined;
                          shareButtonText: string | null | undefined;
                        }
                      | {
                          __typename: 'SparklineRowBlock';
                          label: string | null | undefined;
                          rightLabel: string | null | undefined;
                          labelColor: string | null | undefined;
                          lineColor: string | null | undefined;
                          values: Array<number>;
                          title: string | null | undefined;
                          largeStyle: boolean | null | undefined;
                          hideValueChange: boolean | null | undefined;
                        }
                      | {
                          __typename: 'StackedBarChartBlock';
                          groupColors: Array<string>;
                          data: Array<{
                            __typename: 'StackedBarChartDataPoint';
                            x: string;
                            total: number | null | undefined;
                            groups: Array<number>;
                          }>;
                        }
                      | { __typename: 'SuccessBlock' }
                      | {
                          __typename: 'TextBlock';
                          content: string;
                          fontSize: number | null | undefined;
                          fontWeight: string | null | undefined;
                          color: string | null | undefined;
                        }
                      | {
                          __typename: 'TransactionsListBlock';
                          categories: Array<string> | null | undefined;
                          startDate: string | null | undefined;
                          endDate: string | null | undefined;
                        }
                    >;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
        }>;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'SuccessBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'TextBlock';
        content: string;
        fontSize: number | null | undefined;
        fontWeight: string | null | undefined;
        color: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'TransactionsListBlock';
        categories: Array<string> | null | undefined;
        startDate: string | null | undefined;
        endDate: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AccountsListBlockFieldsFragment = {
  __typename: 'AccountsListBlock';
  accountType: string | null | undefined;
  displayBalanceAsOf: string | null | undefined;
};

export type Mobile_GetAccountsListBlockQueryVariables = Exact<{
  filters: InputMaybe<AccountFilters>;
  displayBalanceAsOf: InputMaybe<Scalars['Date']['input']>;
}>;

export type Mobile_GetAccountsListBlockQuery = {
  __typename: 'Query';
  accounts: Array<{
    __typename: 'Account';
    id: string;
    isAsset: boolean;
    displayName: string;
    includeInNetWorth: boolean;
    includeBalanceInNetWorth: boolean;
    syncDisabled: boolean;
    isHidden: boolean;
    order: number;
    displayBalance: number | null | undefined;
    displayLastUpdatedAt: string;
    dataProvider: string | null | undefined;
    type: { __typename: 'AccountType'; name: string; display: string };
    credential:
      | {
          __typename: 'Credential';
          id: string;
          dataProvider: DataProviderLegacy;
          updateRequired: boolean;
          disconnectedFromDataProviderAt: string | null | undefined;
          institution: {
            __typename: 'Institution';
            id: string;
            status: InstitutionStatus | null | undefined;
          };
        }
      | null
      | undefined;
    subtype: { __typename: 'AccountSubtype'; name: string };
    institution:
      | {
          __typename: 'Institution';
          id: string;
          logo: string | null | undefined;
          primaryColor: string;
        }
      | null
      | undefined;
  }>;
  householdPreferences:
    | {
        __typename: 'HouseholdPreferences';
        id: string | null | undefined;
        accountGroupOrder: Array<string>;
      }
    | null
    | undefined;
};

export type GroupBlockFieldsFragment = {
  __typename: 'GroupBlock';
  centered: boolean | null | undefined;
};

type AllBlockFields_AccountsListBlock_Fragment = {
  __typename: 'AccountsListBlock';
  accountType: string | null | undefined;
  displayBalanceAsOf: string | null | undefined;
};

type AllBlockFields_DividerBlock_Fragment = { __typename: 'DividerBlock' };

type AllBlockFields_GoalContributionsListBlock_Fragment = {
  __typename: 'GoalContributionsListBlock';
};

type AllBlockFields_GroupBlock_Fragment = {
  __typename: 'GroupBlock';
  centered: boolean | null | undefined;
};

type AllBlockFields_HorizontalBarChartBlock_Fragment = {
  __typename: 'HorizontalBarChartBlock';
  topRightLabel: string | null | undefined;
  smallBarStyle: boolean | null | undefined;
  bars: Array<{
    __typename: 'HorizontalBarChartBar';
    label: string | null | undefined;
    barPercent: number;
    barColor: string;
    rightLabel: string | null | undefined;
    leftLabel: string | null | undefined;
  }>;
};

type AllBlockFields_InfoBlock_Fragment = {
  __typename: 'InfoBlock';
  label: string | null | undefined;
  value: string | null | undefined;
};

type AllBlockFields_MerchantsListBlock_Fragment = {
  __typename: 'MerchantsListBlock';
  merchants:
    | Array<{
        __typename: 'ReviewMerchant';
        id: string;
        name: string;
        logoUrl: string | null | undefined;
        transactionCount: number;
      }>
    | null
    | undefined;
};

type AllBlockFields_NetWorthChartBlock_Fragment = {
  __typename: 'NetWorthChartBlock';
  snapshotsByAccountType: Array<{
    __typename: 'SnapshotByType';
    accountType: string;
    month: string;
    balance: number;
  }>;
};

type AllBlockFields_PageTitleBlock_Fragment = {
  __typename: 'PageTitleBlock';
  header: string | null | undefined;
  title: string | null | undefined;
  subtitle: string | null | undefined;
  centerSubtitle: boolean | null | undefined;
};

type AllBlockFields_PieChartBlock_Fragment = {
  __typename: 'PieChartBlock';
  slices: Array<{ __typename: 'PieChartSlice'; label: string; value: number; color: string }>;
};

type AllBlockFields_ShareMonarchBlock_Fragment = { __typename: 'ShareMonarchBlock' };

type AllBlockFields_ShareableBlock_Fragment = {
  __typename: 'ShareableBlock';
  title: string | null | undefined;
  subtitle: string | null | undefined;
  sharedTitle: string | null | undefined;
  sharedSubtitle: string | null | undefined;
  sharedFooter: string | null | undefined;
  sharedContent: string | null | undefined;
  shareButtonText: string | null | undefined;
};

type AllBlockFields_SparklineRowBlock_Fragment = {
  __typename: 'SparklineRowBlock';
  label: string | null | undefined;
  rightLabel: string | null | undefined;
  labelColor: string | null | undefined;
  lineColor: string | null | undefined;
  values: Array<number>;
  title: string | null | undefined;
  largeStyle: boolean | null | undefined;
  hideValueChange: boolean | null | undefined;
};

type AllBlockFields_StackedBarChartBlock_Fragment = {
  __typename: 'StackedBarChartBlock';
  groupColors: Array<string>;
  data: Array<{
    __typename: 'StackedBarChartDataPoint';
    x: string;
    total: number | null | undefined;
    groups: Array<number>;
  }>;
};

type AllBlockFields_SuccessBlock_Fragment = { __typename: 'SuccessBlock' };

type AllBlockFields_TextBlock_Fragment = {
  __typename: 'TextBlock';
  content: string;
  fontSize: number | null | undefined;
  fontWeight: string | null | undefined;
  color: string | null | undefined;
};

type AllBlockFields_TransactionsListBlock_Fragment = {
  __typename: 'TransactionsListBlock';
  categories: Array<string> | null | undefined;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
};

export type AllBlockFieldsFragment =
  | AllBlockFields_AccountsListBlock_Fragment
  | AllBlockFields_DividerBlock_Fragment
  | AllBlockFields_GoalContributionsListBlock_Fragment
  | AllBlockFields_GroupBlock_Fragment
  | AllBlockFields_HorizontalBarChartBlock_Fragment
  | AllBlockFields_InfoBlock_Fragment
  | AllBlockFields_MerchantsListBlock_Fragment
  | AllBlockFields_NetWorthChartBlock_Fragment
  | AllBlockFields_PageTitleBlock_Fragment
  | AllBlockFields_PieChartBlock_Fragment
  | AllBlockFields_ShareMonarchBlock_Fragment
  | AllBlockFields_ShareableBlock_Fragment
  | AllBlockFields_SparklineRowBlock_Fragment
  | AllBlockFields_StackedBarChartBlock_Fragment
  | AllBlockFields_SuccessBlock_Fragment
  | AllBlockFields_TextBlock_Fragment
  | AllBlockFields_TransactionsListBlock_Fragment;

type BlockLinkFields_AccountsListBlock_Fragment = {
  __typename: 'AccountsListBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_DividerBlock_Fragment = {
  __typename: 'DividerBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_GoalContributionsListBlock_Fragment = {
  __typename: 'GoalContributionsListBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_GroupBlock_Fragment = {
  __typename: 'GroupBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_HorizontalBarChartBar_Fragment = {
  __typename: 'HorizontalBarChartBar';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_HorizontalBarChartBlock_Fragment = {
  __typename: 'HorizontalBarChartBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_InfoBlock_Fragment = {
  __typename: 'InfoBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_MerchantsListBlock_Fragment = {
  __typename: 'MerchantsListBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_NetWorthChartBlock_Fragment = {
  __typename: 'NetWorthChartBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_PageTitleBlock_Fragment = {
  __typename: 'PageTitleBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_PieChartBlock_Fragment = {
  __typename: 'PieChartBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_PieChartSlice_Fragment = {
  __typename: 'PieChartSlice';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_ShareMonarchBlock_Fragment = {
  __typename: 'ShareMonarchBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_ShareableBlock_Fragment = {
  __typename: 'ShareableBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_SparklineRowBlock_Fragment = {
  __typename: 'SparklineRowBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_StackedBarChartBlock_Fragment = {
  __typename: 'StackedBarChartBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_SuccessBlock_Fragment = {
  __typename: 'SuccessBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_TextBlock_Fragment = {
  __typename: 'TextBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockLinkFields_TransactionsListBlock_Fragment = {
  __typename: 'TransactionsListBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

export type BlockLinkFieldsFragment =
  | BlockLinkFields_AccountsListBlock_Fragment
  | BlockLinkFields_DividerBlock_Fragment
  | BlockLinkFields_GoalContributionsListBlock_Fragment
  | BlockLinkFields_GroupBlock_Fragment
  | BlockLinkFields_HorizontalBarChartBar_Fragment
  | BlockLinkFields_HorizontalBarChartBlock_Fragment
  | BlockLinkFields_InfoBlock_Fragment
  | BlockLinkFields_MerchantsListBlock_Fragment
  | BlockLinkFields_NetWorthChartBlock_Fragment
  | BlockLinkFields_PageTitleBlock_Fragment
  | BlockLinkFields_PieChartBlock_Fragment
  | BlockLinkFields_PieChartSlice_Fragment
  | BlockLinkFields_ShareMonarchBlock_Fragment
  | BlockLinkFields_ShareableBlock_Fragment
  | BlockLinkFields_SparklineRowBlock_Fragment
  | BlockLinkFields_StackedBarChartBlock_Fragment
  | BlockLinkFields_SuccessBlock_Fragment
  | BlockLinkFields_TextBlock_Fragment
  | BlockLinkFields_TransactionsListBlock_Fragment;

type BlockFields_AccountsListBlock_Fragment = {
  __typename: 'AccountsListBlock';
  accountType: string | null | undefined;
  displayBalanceAsOf: string | null | undefined;
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_DividerBlock_Fragment = {
  __typename: 'DividerBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_GoalContributionsListBlock_Fragment = {
  __typename: 'GoalContributionsListBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_GroupBlock_Fragment = {
  __typename: 'GroupBlock';
  centered: boolean | null | undefined;
  children: Array<
    | {
        __typename: 'AccountsListBlock';
        accountType: string | null | undefined;
        displayBalanceAsOf: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'DividerBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'GoalContributionsListBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'GroupBlock';
        centered: boolean | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'HorizontalBarChartBlock';
        topRightLabel: string | null | undefined;
        smallBarStyle: boolean | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
        bars: Array<{
          __typename: 'HorizontalBarChartBar';
          label: string | null | undefined;
          barPercent: number;
          barColor: string;
          rightLabel: string | null | undefined;
          leftLabel: string | null | undefined;
        }>;
      }
    | {
        __typename: 'InfoBlock';
        label: string | null | undefined;
        value: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'MerchantsListBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
        merchants:
          | Array<{
              __typename: 'ReviewMerchant';
              id: string;
              name: string;
              logoUrl: string | null | undefined;
              transactionCount: number;
            }>
          | null
          | undefined;
      }
    | {
        __typename: 'NetWorthChartBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
        snapshotsByAccountType: Array<{
          __typename: 'SnapshotByType';
          accountType: string;
          month: string;
          balance: number;
        }>;
      }
    | {
        __typename: 'PageTitleBlock';
        header: string | null | undefined;
        title: string | null | undefined;
        subtitle: string | null | undefined;
        centerSubtitle: boolean | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'PieChartBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
        slices: Array<{ __typename: 'PieChartSlice'; label: string; value: number; color: string }>;
      }
    | {
        __typename: 'ShareMonarchBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'ShareableBlock';
        title: string | null | undefined;
        subtitle: string | null | undefined;
        sharedTitle: string | null | undefined;
        sharedSubtitle: string | null | undefined;
        sharedFooter: string | null | undefined;
        sharedContent: string | null | undefined;
        shareButtonText: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'SparklineRowBlock';
        label: string | null | undefined;
        rightLabel: string | null | undefined;
        labelColor: string | null | undefined;
        lineColor: string | null | undefined;
        values: Array<number>;
        title: string | null | undefined;
        largeStyle: boolean | null | undefined;
        hideValueChange: boolean | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'StackedBarChartBlock';
        groupColors: Array<string>;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
        data: Array<{
          __typename: 'StackedBarChartDataPoint';
          x: string;
          total: number | null | undefined;
          groups: Array<number>;
        }>;
      }
    | {
        __typename: 'SuccessBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'TextBlock';
        content: string;
        fontSize: number | null | undefined;
        fontWeight: string | null | undefined;
        color: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'TransactionsListBlock';
        categories: Array<string> | null | undefined;
        startDate: string | null | undefined;
        endDate: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
  >;
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_HorizontalBarChartBlock_Fragment = {
  __typename: 'HorizontalBarChartBlock';
  topRightLabel: string | null | undefined;
  smallBarStyle: boolean | null | undefined;
  bars: Array<{
    __typename: 'HorizontalBarChartBar';
    label: string | null | undefined;
    barPercent: number;
    barColor: string;
    rightLabel: string | null | undefined;
    leftLabel: string | null | undefined;
    link:
      | {
          __typename: 'BlockLink';
          title: string | null | undefined;
          blocks: Array<
            | {
                __typename: 'AccountsListBlock';
                accountType: string | null | undefined;
                displayBalanceAsOf: string | null | undefined;
              }
            | { __typename: 'DividerBlock' }
            | { __typename: 'GoalContributionsListBlock' }
            | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
            | {
                __typename: 'HorizontalBarChartBlock';
                topRightLabel: string | null | undefined;
                smallBarStyle: boolean | null | undefined;
                bars: Array<{
                  __typename: 'HorizontalBarChartBar';
                  label: string | null | undefined;
                  barPercent: number;
                  barColor: string;
                  rightLabel: string | null | undefined;
                  leftLabel: string | null | undefined;
                }>;
              }
            | {
                __typename: 'InfoBlock';
                label: string | null | undefined;
                value: string | null | undefined;
              }
            | {
                __typename: 'MerchantsListBlock';
                merchants:
                  | Array<{
                      __typename: 'ReviewMerchant';
                      id: string;
                      name: string;
                      logoUrl: string | null | undefined;
                      transactionCount: number;
                    }>
                  | null
                  | undefined;
              }
            | {
                __typename: 'NetWorthChartBlock';
                snapshotsByAccountType: Array<{
                  __typename: 'SnapshotByType';
                  accountType: string;
                  month: string;
                  balance: number;
                }>;
              }
            | {
                __typename: 'PageTitleBlock';
                header: string | null | undefined;
                title: string | null | undefined;
                subtitle: string | null | undefined;
                centerSubtitle: boolean | null | undefined;
              }
            | {
                __typename: 'PieChartBlock';
                slices: Array<{
                  __typename: 'PieChartSlice';
                  label: string;
                  value: number;
                  color: string;
                }>;
              }
            | { __typename: 'ShareMonarchBlock' }
            | {
                __typename: 'ShareableBlock';
                title: string | null | undefined;
                subtitle: string | null | undefined;
                sharedTitle: string | null | undefined;
                sharedSubtitle: string | null | undefined;
                sharedFooter: string | null | undefined;
                sharedContent: string | null | undefined;
                shareButtonText: string | null | undefined;
              }
            | {
                __typename: 'SparklineRowBlock';
                label: string | null | undefined;
                rightLabel: string | null | undefined;
                labelColor: string | null | undefined;
                lineColor: string | null | undefined;
                values: Array<number>;
                title: string | null | undefined;
                largeStyle: boolean | null | undefined;
                hideValueChange: boolean | null | undefined;
              }
            | {
                __typename: 'StackedBarChartBlock';
                groupColors: Array<string>;
                data: Array<{
                  __typename: 'StackedBarChartDataPoint';
                  x: string;
                  total: number | null | undefined;
                  groups: Array<number>;
                }>;
              }
            | { __typename: 'SuccessBlock' }
            | {
                __typename: 'TextBlock';
                content: string;
                fontSize: number | null | undefined;
                fontWeight: string | null | undefined;
                color: string | null | undefined;
              }
            | {
                __typename: 'TransactionsListBlock';
                categories: Array<string> | null | undefined;
                startDate: string | null | undefined;
                endDate: string | null | undefined;
              }
          >;
        }
      | null
      | undefined;
  }>;
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_InfoBlock_Fragment = {
  __typename: 'InfoBlock';
  label: string | null | undefined;
  value: string | null | undefined;
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_MerchantsListBlock_Fragment = {
  __typename: 'MerchantsListBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
  merchants:
    | Array<{
        __typename: 'ReviewMerchant';
        id: string;
        name: string;
        logoUrl: string | null | undefined;
        transactionCount: number;
      }>
    | null
    | undefined;
};

type BlockFields_NetWorthChartBlock_Fragment = {
  __typename: 'NetWorthChartBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
  snapshotsByAccountType: Array<{
    __typename: 'SnapshotByType';
    accountType: string;
    month: string;
    balance: number;
  }>;
};

type BlockFields_PageTitleBlock_Fragment = {
  __typename: 'PageTitleBlock';
  header: string | null | undefined;
  title: string | null | undefined;
  subtitle: string | null | undefined;
  centerSubtitle: boolean | null | undefined;
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_PieChartBlock_Fragment = {
  __typename: 'PieChartBlock';
  slices: Array<{
    __typename: 'PieChartSlice';
    label: string;
    value: number;
    color: string;
    link:
      | {
          __typename: 'BlockLink';
          title: string | null | undefined;
          blocks: Array<
            | {
                __typename: 'AccountsListBlock';
                accountType: string | null | undefined;
                displayBalanceAsOf: string | null | undefined;
              }
            | { __typename: 'DividerBlock' }
            | { __typename: 'GoalContributionsListBlock' }
            | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
            | {
                __typename: 'HorizontalBarChartBlock';
                topRightLabel: string | null | undefined;
                smallBarStyle: boolean | null | undefined;
                bars: Array<{
                  __typename: 'HorizontalBarChartBar';
                  label: string | null | undefined;
                  barPercent: number;
                  barColor: string;
                  rightLabel: string | null | undefined;
                  leftLabel: string | null | undefined;
                }>;
              }
            | {
                __typename: 'InfoBlock';
                label: string | null | undefined;
                value: string | null | undefined;
              }
            | {
                __typename: 'MerchantsListBlock';
                merchants:
                  | Array<{
                      __typename: 'ReviewMerchant';
                      id: string;
                      name: string;
                      logoUrl: string | null | undefined;
                      transactionCount: number;
                    }>
                  | null
                  | undefined;
              }
            | {
                __typename: 'NetWorthChartBlock';
                snapshotsByAccountType: Array<{
                  __typename: 'SnapshotByType';
                  accountType: string;
                  month: string;
                  balance: number;
                }>;
              }
            | {
                __typename: 'PageTitleBlock';
                header: string | null | undefined;
                title: string | null | undefined;
                subtitle: string | null | undefined;
                centerSubtitle: boolean | null | undefined;
              }
            | {
                __typename: 'PieChartBlock';
                slices: Array<{
                  __typename: 'PieChartSlice';
                  label: string;
                  value: number;
                  color: string;
                }>;
              }
            | { __typename: 'ShareMonarchBlock' }
            | {
                __typename: 'ShareableBlock';
                title: string | null | undefined;
                subtitle: string | null | undefined;
                sharedTitle: string | null | undefined;
                sharedSubtitle: string | null | undefined;
                sharedFooter: string | null | undefined;
                sharedContent: string | null | undefined;
                shareButtonText: string | null | undefined;
              }
            | {
                __typename: 'SparklineRowBlock';
                label: string | null | undefined;
                rightLabel: string | null | undefined;
                labelColor: string | null | undefined;
                lineColor: string | null | undefined;
                values: Array<number>;
                title: string | null | undefined;
                largeStyle: boolean | null | undefined;
                hideValueChange: boolean | null | undefined;
              }
            | {
                __typename: 'StackedBarChartBlock';
                groupColors: Array<string>;
                data: Array<{
                  __typename: 'StackedBarChartDataPoint';
                  x: string;
                  total: number | null | undefined;
                  groups: Array<number>;
                }>;
              }
            | { __typename: 'SuccessBlock' }
            | {
                __typename: 'TextBlock';
                content: string;
                fontSize: number | null | undefined;
                fontWeight: string | null | undefined;
                color: string | null | undefined;
              }
            | {
                __typename: 'TransactionsListBlock';
                categories: Array<string> | null | undefined;
                startDate: string | null | undefined;
                endDate: string | null | undefined;
              }
          >;
        }
      | null
      | undefined;
  }>;
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_ShareMonarchBlock_Fragment = {
  __typename: 'ShareMonarchBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_ShareableBlock_Fragment = {
  __typename: 'ShareableBlock';
  title: string | null | undefined;
  subtitle: string | null | undefined;
  sharedTitle: string | null | undefined;
  sharedSubtitle: string | null | undefined;
  sharedFooter: string | null | undefined;
  sharedContent: string | null | undefined;
  shareButtonText: string | null | undefined;
  children: Array<
    | {
        __typename: 'AccountsListBlock';
        accountType: string | null | undefined;
        displayBalanceAsOf: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'DividerBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'GoalContributionsListBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'GroupBlock';
        centered: boolean | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'HorizontalBarChartBlock';
        topRightLabel: string | null | undefined;
        smallBarStyle: boolean | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
        bars: Array<{
          __typename: 'HorizontalBarChartBar';
          label: string | null | undefined;
          barPercent: number;
          barColor: string;
          rightLabel: string | null | undefined;
          leftLabel: string | null | undefined;
        }>;
      }
    | {
        __typename: 'InfoBlock';
        label: string | null | undefined;
        value: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'MerchantsListBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
        merchants:
          | Array<{
              __typename: 'ReviewMerchant';
              id: string;
              name: string;
              logoUrl: string | null | undefined;
              transactionCount: number;
            }>
          | null
          | undefined;
      }
    | {
        __typename: 'NetWorthChartBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
        snapshotsByAccountType: Array<{
          __typename: 'SnapshotByType';
          accountType: string;
          month: string;
          balance: number;
        }>;
      }
    | {
        __typename: 'PageTitleBlock';
        header: string | null | undefined;
        title: string | null | undefined;
        subtitle: string | null | undefined;
        centerSubtitle: boolean | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'PieChartBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
        slices: Array<{ __typename: 'PieChartSlice'; label: string; value: number; color: string }>;
      }
    | {
        __typename: 'ShareMonarchBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'ShareableBlock';
        title: string | null | undefined;
        subtitle: string | null | undefined;
        sharedTitle: string | null | undefined;
        sharedSubtitle: string | null | undefined;
        sharedFooter: string | null | undefined;
        sharedContent: string | null | undefined;
        shareButtonText: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'SparklineRowBlock';
        label: string | null | undefined;
        rightLabel: string | null | undefined;
        labelColor: string | null | undefined;
        lineColor: string | null | undefined;
        values: Array<number>;
        title: string | null | undefined;
        largeStyle: boolean | null | undefined;
        hideValueChange: boolean | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'StackedBarChartBlock';
        groupColors: Array<string>;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
        data: Array<{
          __typename: 'StackedBarChartDataPoint';
          x: string;
          total: number | null | undefined;
          groups: Array<number>;
        }>;
      }
    | {
        __typename: 'SuccessBlock';
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'TextBlock';
        content: string;
        fontSize: number | null | undefined;
        fontWeight: string | null | undefined;
        color: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'TransactionsListBlock';
        categories: Array<string> | null | undefined;
        startDate: string | null | undefined;
        endDate: string | null | undefined;
        link:
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined;
      }
  >;
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_SparklineRowBlock_Fragment = {
  __typename: 'SparklineRowBlock';
  label: string | null | undefined;
  rightLabel: string | null | undefined;
  labelColor: string | null | undefined;
  lineColor: string | null | undefined;
  values: Array<number>;
  title: string | null | undefined;
  largeStyle: boolean | null | undefined;
  hideValueChange: boolean | null | undefined;
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_StackedBarChartBlock_Fragment = {
  __typename: 'StackedBarChartBlock';
  groupColors: Array<string>;
  data: Array<{
    __typename: 'StackedBarChartDataPoint';
    x: string;
    total: number | null | undefined;
    groups: Array<number>;
    links:
      | Array<
          | {
              __typename: 'BlockLink';
              title: string | null | undefined;
              blocks: Array<
                | {
                    __typename: 'AccountsListBlock';
                    accountType: string | null | undefined;
                    displayBalanceAsOf: string | null | undefined;
                  }
                | { __typename: 'DividerBlock' }
                | { __typename: 'GoalContributionsListBlock' }
                | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                | {
                    __typename: 'HorizontalBarChartBlock';
                    topRightLabel: string | null | undefined;
                    smallBarStyle: boolean | null | undefined;
                    bars: Array<{
                      __typename: 'HorizontalBarChartBar';
                      label: string | null | undefined;
                      barPercent: number;
                      barColor: string;
                      rightLabel: string | null | undefined;
                      leftLabel: string | null | undefined;
                    }>;
                  }
                | {
                    __typename: 'InfoBlock';
                    label: string | null | undefined;
                    value: string | null | undefined;
                  }
                | {
                    __typename: 'MerchantsListBlock';
                    merchants:
                      | Array<{
                          __typename: 'ReviewMerchant';
                          id: string;
                          name: string;
                          logoUrl: string | null | undefined;
                          transactionCount: number;
                        }>
                      | null
                      | undefined;
                  }
                | {
                    __typename: 'NetWorthChartBlock';
                    snapshotsByAccountType: Array<{
                      __typename: 'SnapshotByType';
                      accountType: string;
                      month: string;
                      balance: number;
                    }>;
                  }
                | {
                    __typename: 'PageTitleBlock';
                    header: string | null | undefined;
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    centerSubtitle: boolean | null | undefined;
                  }
                | {
                    __typename: 'PieChartBlock';
                    slices: Array<{
                      __typename: 'PieChartSlice';
                      label: string;
                      value: number;
                      color: string;
                    }>;
                  }
                | { __typename: 'ShareMonarchBlock' }
                | {
                    __typename: 'ShareableBlock';
                    title: string | null | undefined;
                    subtitle: string | null | undefined;
                    sharedTitle: string | null | undefined;
                    sharedSubtitle: string | null | undefined;
                    sharedFooter: string | null | undefined;
                    sharedContent: string | null | undefined;
                    shareButtonText: string | null | undefined;
                  }
                | {
                    __typename: 'SparklineRowBlock';
                    label: string | null | undefined;
                    rightLabel: string | null | undefined;
                    labelColor: string | null | undefined;
                    lineColor: string | null | undefined;
                    values: Array<number>;
                    title: string | null | undefined;
                    largeStyle: boolean | null | undefined;
                    hideValueChange: boolean | null | undefined;
                  }
                | {
                    __typename: 'StackedBarChartBlock';
                    groupColors: Array<string>;
                    data: Array<{
                      __typename: 'StackedBarChartDataPoint';
                      x: string;
                      total: number | null | undefined;
                      groups: Array<number>;
                    }>;
                  }
                | { __typename: 'SuccessBlock' }
                | {
                    __typename: 'TextBlock';
                    content: string;
                    fontSize: number | null | undefined;
                    fontWeight: string | null | undefined;
                    color: string | null | undefined;
                  }
                | {
                    __typename: 'TransactionsListBlock';
                    categories: Array<string> | null | undefined;
                    startDate: string | null | undefined;
                    endDate: string | null | undefined;
                  }
              >;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  }>;
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_SuccessBlock_Fragment = {
  __typename: 'SuccessBlock';
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_TextBlock_Fragment = {
  __typename: 'TextBlock';
  content: string;
  fontSize: number | null | undefined;
  fontWeight: string | null | undefined;
  color: string | null | undefined;
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

type BlockFields_TransactionsListBlock_Fragment = {
  __typename: 'TransactionsListBlock';
  categories: Array<string> | null | undefined;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  link:
    | {
        __typename: 'BlockLink';
        title: string | null | undefined;
        blocks: Array<
          | {
              __typename: 'AccountsListBlock';
              accountType: string | null | undefined;
              displayBalanceAsOf: string | null | undefined;
            }
          | { __typename: 'DividerBlock' }
          | { __typename: 'GoalContributionsListBlock' }
          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
          | {
              __typename: 'HorizontalBarChartBlock';
              topRightLabel: string | null | undefined;
              smallBarStyle: boolean | null | undefined;
              bars: Array<{
                __typename: 'HorizontalBarChartBar';
                label: string | null | undefined;
                barPercent: number;
                barColor: string;
                rightLabel: string | null | undefined;
                leftLabel: string | null | undefined;
              }>;
            }
          | {
              __typename: 'InfoBlock';
              label: string | null | undefined;
              value: string | null | undefined;
            }
          | {
              __typename: 'MerchantsListBlock';
              merchants:
                | Array<{
                    __typename: 'ReviewMerchant';
                    id: string;
                    name: string;
                    logoUrl: string | null | undefined;
                    transactionCount: number;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'NetWorthChartBlock';
              snapshotsByAccountType: Array<{
                __typename: 'SnapshotByType';
                accountType: string;
                month: string;
                balance: number;
              }>;
            }
          | {
              __typename: 'PageTitleBlock';
              header: string | null | undefined;
              title: string | null | undefined;
              subtitle: string | null | undefined;
              centerSubtitle: boolean | null | undefined;
            }
          | {
              __typename: 'PieChartBlock';
              slices: Array<{
                __typename: 'PieChartSlice';
                label: string;
                value: number;
                color: string;
              }>;
            }
          | { __typename: 'ShareMonarchBlock' }
          | {
              __typename: 'ShareableBlock';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              sharedTitle: string | null | undefined;
              sharedSubtitle: string | null | undefined;
              sharedFooter: string | null | undefined;
              sharedContent: string | null | undefined;
              shareButtonText: string | null | undefined;
            }
          | {
              __typename: 'SparklineRowBlock';
              label: string | null | undefined;
              rightLabel: string | null | undefined;
              labelColor: string | null | undefined;
              lineColor: string | null | undefined;
              values: Array<number>;
              title: string | null | undefined;
              largeStyle: boolean | null | undefined;
              hideValueChange: boolean | null | undefined;
            }
          | {
              __typename: 'StackedBarChartBlock';
              groupColors: Array<string>;
              data: Array<{
                __typename: 'StackedBarChartDataPoint';
                x: string;
                total: number | null | undefined;
                groups: Array<number>;
              }>;
            }
          | { __typename: 'SuccessBlock' }
          | {
              __typename: 'TextBlock';
              content: string;
              fontSize: number | null | undefined;
              fontWeight: string | null | undefined;
              color: string | null | undefined;
            }
          | {
              __typename: 'TransactionsListBlock';
              categories: Array<string> | null | undefined;
              startDate: string | null | undefined;
              endDate: string | null | undefined;
            }
        >;
      }
    | null
    | undefined;
};

export type BlockFieldsFragment =
  | BlockFields_AccountsListBlock_Fragment
  | BlockFields_DividerBlock_Fragment
  | BlockFields_GoalContributionsListBlock_Fragment
  | BlockFields_GroupBlock_Fragment
  | BlockFields_HorizontalBarChartBlock_Fragment
  | BlockFields_InfoBlock_Fragment
  | BlockFields_MerchantsListBlock_Fragment
  | BlockFields_NetWorthChartBlock_Fragment
  | BlockFields_PageTitleBlock_Fragment
  | BlockFields_PieChartBlock_Fragment
  | BlockFields_ShareMonarchBlock_Fragment
  | BlockFields_ShareableBlock_Fragment
  | BlockFields_SparklineRowBlock_Fragment
  | BlockFields_StackedBarChartBlock_Fragment
  | BlockFields_SuccessBlock_Fragment
  | BlockFields_TextBlock_Fragment
  | BlockFields_TransactionsListBlock_Fragment;

export type HorizontalBarChartBarFieldsFragment = {
  __typename: 'HorizontalBarChartBar';
  label: string | null | undefined;
  barPercent: number;
  barColor: string;
  rightLabel: string | null | undefined;
  leftLabel: string | null | undefined;
};

export type HorizontalBarChartBlockFieldsFragment = {
  __typename: 'HorizontalBarChartBlock';
  topRightLabel: string | null | undefined;
  smallBarStyle: boolean | null | undefined;
  bars: Array<{
    __typename: 'HorizontalBarChartBar';
    label: string | null | undefined;
    barPercent: number;
    barColor: string;
    rightLabel: string | null | undefined;
    leftLabel: string | null | undefined;
  }>;
};

export type InfoBlockFieldsFragment = {
  __typename: 'InfoBlock';
  label: string | null | undefined;
  value: string | null | undefined;
};

export type MerchantsListBlockFieldsFragment = {
  __typename: 'MerchantsListBlock';
  merchants:
    | Array<{
        __typename: 'ReviewMerchant';
        id: string;
        name: string;
        logoUrl: string | null | undefined;
        transactionCount: number;
      }>
    | null
    | undefined;
};

export type NetWorthChartBlockFieldsFragment = {
  __typename: 'NetWorthChartBlock';
  snapshotsByAccountType: Array<{
    __typename: 'SnapshotByType';
    accountType: string;
    month: string;
    balance: number;
  }>;
};

export type PageTitleBlockFieldsFragment = {
  __typename: 'PageTitleBlock';
  header: string | null | undefined;
  title: string | null | undefined;
  subtitle: string | null | undefined;
  centerSubtitle: boolean | null | undefined;
};

export type PieChartBlockFieldsFragment = {
  __typename: 'PieChartBlock';
  slices: Array<{ __typename: 'PieChartSlice'; label: string; value: number; color: string }>;
};

export type ShareableBlockFieldsFragment = {
  __typename: 'ShareableBlock';
  title: string | null | undefined;
  subtitle: string | null | undefined;
  sharedTitle: string | null | undefined;
  sharedSubtitle: string | null | undefined;
  sharedFooter: string | null | undefined;
  sharedContent: string | null | undefined;
  shareButtonText: string | null | undefined;
};

export type SparklineRowBlockFieldsFragment = {
  __typename: 'SparklineRowBlock';
  label: string | null | undefined;
  rightLabel: string | null | undefined;
  labelColor: string | null | undefined;
  lineColor: string | null | undefined;
  values: Array<number>;
  title: string | null | undefined;
  largeStyle: boolean | null | undefined;
  hideValueChange: boolean | null | undefined;
};

export type StackedBarChartBlockFieldsFragment = {
  __typename: 'StackedBarChartBlock';
  groupColors: Array<string>;
  data: Array<{
    __typename: 'StackedBarChartDataPoint';
    x: string;
    total: number | null | undefined;
    groups: Array<number>;
  }>;
};

export type TextBlockFieldsFragment = {
  __typename: 'TextBlock';
  content: string;
  fontSize: number | null | undefined;
  fontWeight: string | null | undefined;
  color: string | null | undefined;
};

export type TransactionsListBlockFieldsFragment = {
  __typename: 'TransactionsListBlock';
  categories: Array<string> | null | undefined;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
};

export type Mobile_CreateEditRuleSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_CreateEditRuleSettingsQuery = {
  __typename: 'Query';
  categories: Array<{
    __typename: 'Category';
    id: string;
    order: number;
    name: string;
    icon: string;
    systemCategory: string | null | undefined;
    isSystemCategory: boolean;
    isDisabled: boolean;
    group: { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType };
  }>;
  accounts: Array<{
    __typename: 'Account';
    id: string;
    displayName: string;
    syncDisabled: boolean;
    deactivatedAt: string | null | undefined;
    isHidden: boolean;
    isAsset: boolean;
    mask: string | null | undefined;
    createdAt: string;
    updatedAt: string;
    displayLastUpdatedAt: string;
    currentBalance: number | null | undefined;
    displayBalance: number | null | undefined;
    includeInNetWorth: boolean;
    hideFromList: boolean;
    hideTransactionsFromReports: boolean;
    includeBalanceInNetWorth: boolean;
    includeInGoalBalance: boolean;
    dataProvider: string | null | undefined;
    dataProviderAccountId: string | null | undefined;
    isManual: boolean;
    transactionsCount: number;
    holdingsCount: number;
    manualInvestmentsTrackingMethod: string | null | undefined;
    order: number;
    icon: string;
    logoUrl: string | null | undefined;
    type: { __typename: 'AccountType'; name: string; display: string; group: string };
    subtype: { __typename: 'AccountSubtype'; name: string; display: string };
    credential:
      | {
          __typename: 'Credential';
          id: string;
          updateRequired: boolean;
          disconnectedFromDataProviderAt: string | null | undefined;
          dataProvider: DataProviderLegacy;
          institution: {
            __typename: 'Institution';
            id: string;
            plaidInstitutionId: string | null | undefined;
            name: string;
            status: InstitutionStatus | null | undefined;
            logo: string | null | undefined;
          };
        }
      | null
      | undefined;
    institution:
      | {
          __typename: 'Institution';
          id: string;
          name: string;
          logo: string | null | undefined;
          primaryColor: string;
          url: string;
        }
      | null
      | undefined;
  }>;
  goalsV2: Array<{ __typename: 'GoalV2'; id: string; name: string }>;
};

export type TransactionRuleFieldsMobileFragment = {
  __typename: 'TransactionRuleV2';
  id: string;
  merchantCriteriaUseOriginalStatement: boolean | null | undefined;
  categoryIds: Array<string> | null | undefined;
  accountIds: Array<string> | null | undefined;
  sendNotificationAction: boolean;
  setHideFromReportsAction: boolean;
  reviewStatusAction: string | null | undefined;
  lastAppliedAt: string | null | undefined;
  recentApplicationCount: number;
  unassignNeedsReviewByUserAction: boolean;
  merchantCriteria:
    | Array<{ __typename: 'MerchantCriterion'; operator: string; value: string }>
    | null
    | undefined;
  amountCriteria:
    | {
        __typename: 'AmountCriteriaV2';
        operator: string;
        isExpense: boolean;
        value: number | null | undefined;
        valueRange:
          | {
              __typename: 'DecimalRange';
              lower: number | null | undefined;
              upper: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  categories:
    | Array<{ __typename: 'Category'; id: string; name: string; icon: string }>
    | null
    | undefined;
  accounts: Array<{ __typename: 'Account'; id: string; displayName: string }> | null | undefined;
  setMerchantAction: { __typename: 'Merchant'; id: string; name: string } | null | undefined;
  setCategoryAction:
    | { __typename: 'Category'; id: string; name: string; icon: string }
    | null
    | undefined;
  addTagsAction:
    | Array<{ __typename: 'TransactionTag'; id: string; name: string; color: string }>
    | null
    | undefined;
  linkGoalAction:
    | {
        __typename: 'GoalV2';
        id: string;
        name: string;
        imageStorageProvider: string | null | undefined;
        imageStorageProviderId: string | null | undefined;
      }
    | null
    | undefined;
  needsReviewByUserAction: { __typename: 'User'; id: string; name: string } | null | undefined;
  splitTransactionsAction:
    | {
        __typename: 'SplitTransactionsAction';
        amountType: SplitAmountType;
        splitsInfo: Array<{
          __typename: 'SplitTransactionsActionInfo';
          amount: number;
          categoryId: string;
          merchantName: string;
          goalId: string | null | undefined;
          tags: Array<string> | null | undefined;
          hideFromReports: boolean | null | undefined;
          reviewStatus: string | null | undefined;
          needsReviewByUserId: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type Mobile_GetLinkedCredentialsQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_GetLinkedCredentialsQuery = {
  __typename: 'Query';
  credentials: Array<{
    __typename: 'Credential';
    id: string;
    institution: {
      __typename: 'Institution';
      id: string;
      logo: string | null | undefined;
      name: string;
    };
  }>;
};

export type RapidReviewTransactionFormFieldsFragment = {
  __typename: 'Transaction';
  id: string;
  amount: number;
  pending: boolean;
  date: string;
  notes: string | null | undefined;
  dataProviderDescription: string | null | undefined;
  category: { __typename: 'Category'; id: string; name: string; icon: string };
  merchant: {
    __typename: 'Merchant';
    id: string;
    name: string;
    logoUrl: string | null | undefined;
    transactionCount: number;
  };
  account: {
    __typename: 'Account';
    id: string;
    displayName: string;
    dataProvider: string | null | undefined;
    goalAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      goal: {
        __typename: 'GoalV2';
        id: string;
        name: string;
        imageStorageProvider: string | null | undefined;
        imageStorageProviderId: string | null | undefined;
        priority: number;
        archivedAt: string | null | undefined;
      };
    }>;
    type: { __typename: 'AccountType'; name: string };
    subtype: { __typename: 'AccountSubtype'; name: string };
    institution:
      | {
          __typename: 'Institution';
          id: string;
          logo: string | null | undefined;
          primaryColor: string;
        }
      | null
      | undefined;
  };
  tags: Array<{ __typename: 'TransactionTag'; id: string; name: string; color: string }>;
  goal:
    | {
        __typename: 'GoalV2';
        id: string;
        name: string;
        imageStorageProvider: string | null | undefined;
        imageStorageProviderId: string | null | undefined;
      }
    | null
    | undefined;
  attachments: Array<{
    __typename: 'TransactionAttachment';
    id: string;
    publicId: string;
    extension: string;
    filename: string;
    originalAssetUrl: string;
  }>;
  needsReviewByUser: { __typename: 'User'; id: string } | null | undefined;
};

export type RapidReviewCategoryFieldsFragment = {
  __typename: 'Category';
  id: string;
  name: string;
  icon: string;
};

export type Mobile_UpdateTransactionRapidReviewMutationVariables = Exact<{
  input: UpdateTransactionMutationInput;
}>;

export type Mobile_UpdateTransactionRapidReviewMutation = {
  __typename: 'Mutation';
  updateTransaction:
    | {
        __typename: 'UpdateTransactionMutation';
        transaction:
          | {
              __typename: 'Transaction';
              id: string;
              amount: number;
              pending: boolean;
              date: string;
              notes: string | null | undefined;
              dataProviderDescription: string | null | undefined;
              category: { __typename: 'Category'; id: string; name: string; icon: string };
              merchant: {
                __typename: 'Merchant';
                id: string;
                name: string;
                logoUrl: string | null | undefined;
                transactionCount: number;
              };
              account: {
                __typename: 'Account';
                id: string;
                displayName: string;
                dataProvider: string | null | undefined;
                goalAllocations: Array<{
                  __typename: 'GoalAccountAllocation';
                  id: string;
                  goal: {
                    __typename: 'GoalV2';
                    id: string;
                    name: string;
                    imageStorageProvider: string | null | undefined;
                    imageStorageProviderId: string | null | undefined;
                    priority: number;
                    archivedAt: string | null | undefined;
                  };
                }>;
                type: { __typename: 'AccountType'; name: string };
                subtype: { __typename: 'AccountSubtype'; name: string };
                institution:
                  | {
                      __typename: 'Institution';
                      id: string;
                      logo: string | null | undefined;
                      primaryColor: string;
                    }
                  | null
                  | undefined;
              };
              tags: Array<{
                __typename: 'TransactionTag';
                id: string;
                name: string;
                color: string;
              }>;
              goal:
                | {
                    __typename: 'GoalV2';
                    id: string;
                    name: string;
                    imageStorageProvider: string | null | undefined;
                    imageStorageProviderId: string | null | undefined;
                  }
                | null
                | undefined;
              attachments: Array<{
                __typename: 'TransactionAttachment';
                id: string;
                publicId: string;
                extension: string;
                filename: string;
                originalAssetUrl: string;
              }>;
              needsReviewByUser: { __typename: 'User'; id: string } | null | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_SetTransactionTagsRapidReviewMutationVariables = Exact<{
  input: SetTransactionTagsInput;
}>;

export type Mobile_SetTransactionTagsRapidReviewMutation = {
  __typename: 'Mutation';
  setTransactionTags:
    | {
        __typename: 'SetTransactionTags';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        transaction:
          | {
              __typename: 'Transaction';
              id: string;
              amount: number;
              pending: boolean;
              date: string;
              notes: string | null | undefined;
              dataProviderDescription: string | null | undefined;
              category: { __typename: 'Category'; id: string; name: string; icon: string };
              merchant: {
                __typename: 'Merchant';
                id: string;
                name: string;
                logoUrl: string | null | undefined;
                transactionCount: number;
              };
              account: {
                __typename: 'Account';
                id: string;
                displayName: string;
                dataProvider: string | null | undefined;
                goalAllocations: Array<{
                  __typename: 'GoalAccountAllocation';
                  id: string;
                  goal: {
                    __typename: 'GoalV2';
                    id: string;
                    name: string;
                    imageStorageProvider: string | null | undefined;
                    imageStorageProviderId: string | null | undefined;
                    priority: number;
                    archivedAt: string | null | undefined;
                  };
                }>;
                type: { __typename: 'AccountType'; name: string };
                subtype: { __typename: 'AccountSubtype'; name: string };
                institution:
                  | {
                      __typename: 'Institution';
                      id: string;
                      logo: string | null | undefined;
                      primaryColor: string;
                    }
                  | null
                  | undefined;
              };
              tags: Array<{
                __typename: 'TransactionTag';
                id: string;
                name: string;
                color: string;
              }>;
              goal:
                | {
                    __typename: 'GoalV2';
                    id: string;
                    name: string;
                    imageStorageProvider: string | null | undefined;
                    imageStorageProviderId: string | null | undefined;
                  }
                | null
                | undefined;
              attachments: Array<{
                __typename: 'TransactionAttachment';
                id: string;
                publicId: string;
                extension: string;
                filename: string;
                originalAssetUrl: string;
              }>;
              needsReviewByUser: { __typename: 'User'; id: string } | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_OriginalTransactionFieldsFragment = {
  __typename: 'Transaction';
  id: string;
  date: string;
  amount: number;
  merchant: { __typename: 'Merchant'; id: string; name: string };
};

export type Mobile_GetTransactionsListQueryVariables = Exact<{
  offset: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  cacheKey: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<TransactionFilterInput>;
  orderBy: InputMaybe<TransactionOrdering>;
}>;

export type Mobile_GetTransactionsListQuery = {
  __typename: 'Query';
  cacheKey: string | null | undefined;
  allTransactions: {
    __typename: 'TransactionList';
    totalCount: number;
    totalSelectableCount: number | null | undefined;
    results: Array<{
      __typename: 'Transaction';
      id: string;
      amount: number;
      date: string;
      pending: boolean;
      notes: string | null | undefined;
      hideFromReports: boolean;
      isSplitTransaction: boolean;
      isRecurring: boolean;
      category: {
        __typename: 'Category';
        id: string;
        name: string;
        icon: string;
        group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
      };
      merchant: { __typename: 'Merchant'; id: string; name: string };
      attachments: Array<{ __typename: 'TransactionAttachment'; id: string }>;
      tags: Array<{ __typename: 'TransactionTag'; id: string; color: string }>;
    }>;
  };
};

export type Mobile_UserAvatarQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_UserAvatarQuery = {
  __typename: 'Query';
  me: { __typename: 'User'; id: string; profilePictureUrl: string | null | undefined };
};

export type Mobile_CreateReviewCheckinMutationVariables = Exact<{
  input: CreateReviewCheckinInput;
}>;

export type Mobile_CreateReviewCheckinMutation = {
  __typename: 'Mutation';
  createReviewCheckin:
    | {
        __typename: 'CreateReviewCheckinMutation';
        checkin: {
          __typename: 'ReviewCheckin';
          id: string;
          completedAt: string | null | undefined;
          lastPageIndexViewed: number;
          reviewStartDate: string;
        };
      }
    | null
    | undefined;
};

export type Mobile_GetAccountOptionsQueryVariables = Exact<{
  filters: InputMaybe<AccountFilters>;
}>;

export type Mobile_GetAccountOptionsQuery = {
  __typename: 'Query';
  accounts: Array<{
    __typename: 'Account';
    id: string;
    displayName: string;
    syncDisabled: boolean;
    manualInvestmentsTrackingMethod: string | null | undefined;
    isManual: boolean;
    dataProvider: string | null | undefined;
    type: { __typename: 'AccountType'; name: string };
    subtype: { __typename: 'AccountSubtype'; name: string };
    institution:
      | {
          __typename: 'Institution';
          id: string;
          logo: string | null | undefined;
          primaryColor: string;
        }
      | null
      | undefined;
  }>;
};

export type Mobile_DeleteAccountLogoMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;

export type Mobile_DeleteAccountLogoMutation = {
  __typename: 'Mutation';
  deleteAccountLogo:
    | {
        __typename: 'DeleteAccountLogoMutation';
        success: boolean | null | undefined;
        account:
          | {
              __typename: 'Account';
              id: string;
              logoUrl: string | null | undefined;
              hasCustomizedLogo: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_GetEditAccountScreenQueryVariables = Exact<{
  accountId: Scalars['UUID']['input'];
}>;

export type Mobile_GetEditAccountScreenQuery = {
  __typename: 'Query';
  account:
    | {
        __typename: 'Account';
        id: string;
        name: string | null | undefined;
        includeInNetWorth: boolean;
        hideFromList: boolean;
        hideTransactionsFromReports: boolean;
        displayName: string;
        displayBalance: number | null | undefined;
        isAsset: boolean;
        isManual: boolean;
        deactivatedAt: string | null | undefined;
        dataProvider: string | null | undefined;
        dataProviderAccountId: string | null | undefined;
        invertSyncedBalance: boolean;
        canInvertBalance: boolean;
        useAvailableBalance: boolean | null | undefined;
        canUseAvailableBalance: boolean;
        logoUrl: string | null | undefined;
        hasCustomizedLogo: boolean | null | undefined;
        institution:
          | { __typename: 'Institution'; logo: string | null | undefined; primaryColor: string }
          | null
          | undefined;
        type: { __typename: 'AccountType'; name: string };
        subtype: { __typename: 'AccountSubtype'; name: string };
        credential: { __typename: 'Credential'; id: string; createdAt: string } | null | undefined;
        creditReportLiabilityAccount:
          | {
              __typename: 'CreditReportLiabilityAccount';
              id: string;
              lastStatement:
                | { __typename: 'LiabilityStatement'; id: string; dueDate: string }
                | null
                | undefined;
              recurringTransactionStream:
                | {
                    __typename: 'RecurringTransactionStream';
                    id: string;
                    isActive: boolean;
                    dayOfTheMonth: number | null | undefined;
                    frequency: string;
                    defaultPaymentAccount:
                      | { __typename: 'Account'; id: string; name: string | null | undefined }
                      | null
                      | undefined;
                    defaultPaymentCategory:
                      | { __typename: 'Category'; id: string; name: string }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_GetCategoriesQueryVariables = Exact<{
  includeSystemDisabledCategories: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Mobile_GetCategoriesQuery = {
  __typename: 'Query';
  categoryGroups: Array<{
    __typename: 'CategoryGroup';
    id: string;
    name: string;
    order: number;
    type: CategoryGroupType;
    groupLevelBudgetingEnabled: boolean | null | undefined;
    budgetVariability: BudgetVariability | null | undefined;
    rolloverPeriod:
      | {
          __typename: 'BudgetRolloverPeriod';
          id: string;
          startMonth: string;
          startingBalance: number | null | undefined;
          frequency: string | null | undefined;
          targetAmount: number | null | undefined;
          type: string;
        }
      | null
      | undefined;
    categories: Array<{ __typename: 'Category'; id: string }>;
  }>;
  categories: Array<{
    __typename: 'Category';
    id: string;
    name: string;
    order: number;
    icon: string;
    isSystemCategory: boolean;
    excludeFromBudget: boolean | null | undefined;
    budgetVariability: BudgetVariability | null | undefined;
    isDisabled: boolean;
    transactionsCount: number;
    group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
    rolloverPeriod:
      | {
          __typename: 'BudgetRolloverPeriod';
          id: string;
          startMonth: string;
          startingBalance: number | null | undefined;
          frequency: string | null | undefined;
          targetAmount: number | null | undefined;
          type: string;
        }
      | null
      | undefined;
  }>;
};

export type Mobile_UpdateCategoryGroupOrderMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  order: Scalars['Int']['input'];
}>;

export type Mobile_UpdateCategoryGroupOrderMutation = {
  __typename: 'Mutation';
  updateCategoryGroupOrder:
    | {
        __typename: 'UpdateCategoryGroupOrderMutation';
        categoryGroups: Array<{ __typename: 'CategoryGroup'; id: string; order: number }>;
      }
    | null
    | undefined;
};

export type ReorderedCategoryFragment = {
  __typename: 'Category';
  id: string;
  order: number;
  group: { __typename: 'CategoryGroup'; id: string };
};

export type Mobile_UpdateCategoryOrderMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  categoryGroupId: Scalars['UUID']['input'];
  order: Scalars['Int']['input'];
}>;

export type Mobile_UpdateCategoryOrderMutation = {
  __typename: 'Mutation';
  updateCategoryOrderInCategoryGroup:
    | {
        __typename: 'UpdateCategoryOrderInCategoryGroupMutation';
        category: { __typename: 'Category'; id: string };
      }
    | null
    | undefined;
};

export type Mobile_LinkGoalOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_LinkGoalOptionsQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    name: string;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
    archivedAt: string | null | undefined;
    accountAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      account: { __typename: 'Account'; id: string };
    }>;
  }>;
};

export type Mobile_SelectGoalsScreenQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_SelectGoalsScreenQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    name: string;
    priority: number;
    archivedAt: string | null | undefined;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
  }>;
};

export type Mobile_CreatePlaidLinkTokenMutationVariables = Exact<{
  credentialId: InputMaybe<Scalars['UUID']['input']>;
  linkCustomizationName: InputMaybe<Scalars['String']['input']>;
  plaidInstitutionId: InputMaybe<Scalars['String']['input']>;
  redirectUri: InputMaybe<Scalars['String']['input']>;
  androidPackageName: InputMaybe<Scalars['String']['input']>;
}>;

export type Mobile_CreatePlaidLinkTokenMutation = {
  __typename: 'Mutation';
  createPlaidLinkToken:
    | {
        __typename: 'CreatePlaidLinkTokenMutation';
        linkToken: string | null | undefined;
        error: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetMobileSubscriptionOfferingQueryVariables = Exact<{
  platform: InputMaybe<Platform>;
}>;

export type GetMobileSubscriptionOfferingQuery = {
  __typename: 'Query';
  mobileSubscriptionOffering: {
    __typename: 'MobileSubscriptionOffering';
    availableOfferingName: string;
    packageMetadata:
      | Array<{
          __typename: 'MobilePackageMetadata';
          packageType: string | null | undefined;
          promoText: string | null | undefined;
          discountTitle: string | null | undefined;
        }>
      | null
      | undefined;
  };
};

export type Mobile_UpdateReviewCheckinMutationVariables = Exact<{
  input: UpdateReviewCheckinInput;
}>;

export type Mobile_UpdateReviewCheckinMutation = {
  __typename: 'Mutation';
  updateReviewCheckin:
    | {
        __typename: 'UpdateReviewCheckinMutation';
        checkin:
          | {
              __typename: 'ReviewCheckin';
              id: string;
              completedAt: string | null | undefined;
              lastPageIndexViewed: number;
              reviewStartDate: string;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_SetTransactionTagsMutationVariables = Exact<{
  input: SetTransactionTagsInput;
}>;

export type Mobile_SetTransactionTagsMutation = {
  __typename: 'Mutation';
  setTransactionTags:
    | {
        __typename: 'SetTransactionTags';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        transaction:
          | {
              __typename: 'Transaction';
              id: string;
              tags: Array<{ __typename: 'TransactionTag'; id: string }>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCanRequestReviewQueryVariables = Exact<{ [key: string]: never }>;

export type GetCanRequestReviewQuery = {
  __typename: 'Query';
  me: { __typename: 'User'; id: string; canRequestAppStoreReview: boolean };
};

export type Mobile_DidRequestReviewMutationVariables = Exact<{ [key: string]: never }>;

export type Mobile_DidRequestReviewMutation = {
  __typename: 'Mutation';
  didRequestAppStoreReview:
    | {
        __typename: 'DidRequestAppStoreReviewMutation';
        user: { __typename: 'User'; id: string; canRequestAppStoreReview: boolean };
      }
    | null
    | undefined;
};

export type Mobile_InitialPermissionRequestModalQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_InitialPermissionRequestModalQuery = {
  __typename: 'Query';
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    trialDurationDays: number;
    hasPremiumEntitlement: boolean;
  };
};

export type Mobile_SideDrawerButtonQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_SideDrawerButtonQuery = {
  __typename: 'Query';
  me: { __typename: 'User'; id: string; hasNewActivity: boolean };
};

export type AccountFromCacheFragment = {
  __typename: 'Account';
  id: string;
  displayName: string;
  credential:
    | {
        __typename: 'Credential';
        id: string;
        dataProvider: DataProviderLegacy;
        disconnectedFromDataProviderAt: string | null | undefined;
        institution: {
          __typename: 'Institution';
          id: string;
          logo: string | null | undefined;
          primaryColor: string;
        };
      }
    | null
    | undefined;
};

export type Mobile_GetAccountDetailScreenQueryVariables = Exact<{
  accountId: Scalars['UUID']['input'];
}>;

export type Mobile_GetAccountDetailScreenQuery = {
  __typename: 'Query';
  allTransactions: {
    __typename: 'TransactionList';
    totalCount: number;
    results: Array<{
      __typename: 'Transaction';
      id: string;
      amount: number;
      pending: boolean;
      merchant: { __typename: 'Merchant'; id: string; name: string };
      category: { __typename: 'Category'; id: string; icon: string };
    }>;
  };
  snapshots: Array<{ __typename: 'AccountSnapshot'; date: string; signedBalance: number }>;
  account:
    | {
        __typename: 'Account';
        id: string;
        holdingsCount: number;
        transactionsCount: number;
        manualInvestmentsTrackingMethod: string | null | undefined;
        displayName: string;
        displayBalance: number | null | undefined;
        displayLastUpdatedAt: string;
        includeInNetWorth: boolean;
        dataProvider: string | null | undefined;
        isManual: boolean;
        isLiability: boolean;
        deletedAt: string | null | undefined;
        dataProviderAccountId: string | null | undefined;
        syncDisabled: boolean;
        deactivatedAt: string | null | undefined;
        credential:
          | {
              __typename: 'Credential';
              id: string;
              dataProvider: DataProviderLegacy;
              updateRequired: boolean;
              disconnectedFromDataProviderAt: string | null | undefined;
              isHistoricalUpdateCompleted: boolean;
              institution: {
                __typename: 'Institution';
                id: string;
                balanceStatus: InstitutionStatus | null | undefined;
                transactionsStatus: InstitutionStatus | null | undefined;
                name: string;
                status: InstitutionStatus | null | undefined;
              };
            }
          | null
          | undefined;
        type: { __typename: 'AccountType'; name: string };
        subtype: { __typename: 'AccountSubtype'; display: string; name: string };
        institution:
          | {
              __typename: 'Institution';
              id: string;
              logo: string | null | undefined;
              primaryColor: string;
              name: string;
              url: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_GetAggregateSnapshotsQueryVariables = Exact<{
  filters: InputMaybe<AggregateSnapshotFilters>;
}>;

export type Mobile_GetAggregateSnapshotsQuery = {
  __typename: 'Query';
  aggregateSnapshots: Array<{ __typename: 'AggregateSnapshot'; date: string; balance: number }>;
};

export type Mobile_GetDuplicateCredentialsQueryVariables = Exact<{
  filters: CredentialFilters;
}>;

export type Mobile_GetDuplicateCredentialsQuery = {
  __typename: 'Query';
  credentials: Array<{
    __typename: 'Credential';
    id: string;
    createdAt: string;
    accounts: Array<{
      __typename: 'Account';
      id: string;
      isAsset: boolean;
      displayName: string;
      includeInNetWorth: boolean;
      syncDisabled: boolean;
      displayBalance: number | null | undefined;
      displayLastUpdatedAt: string;
      dataProvider: string | null | undefined;
      credential:
        | {
            __typename: 'Credential';
            id: string;
            dataProvider: DataProviderLegacy;
            updateRequired: boolean;
            disconnectedFromDataProviderAt: string | null | undefined;
            institution: {
              __typename: 'Institution';
              id: string;
              status: InstitutionStatus | null | undefined;
            };
          }
        | null
        | undefined;
      type: { __typename: 'AccountType'; name: string };
      subtype: { __typename: 'AccountSubtype'; name: string };
      institution:
        | {
            __typename: 'Institution';
            id: string;
            logo: string | null | undefined;
            primaryColor: string;
          }
        | null
        | undefined;
    }>;
  }>;
};

export type Mobile_CreateZillowAccountMutationVariables = Exact<{
  input: CreateSyncedRealEstateAccountInput;
}>;

export type Mobile_CreateZillowAccountMutation = {
  __typename: 'Mutation';
  createSyncedRealEstateAccount:
    | {
        __typename: 'CreateSyncedRealEstateAccount';
        account: { __typename: 'Account'; id: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_CreateSyncedVehicleAccountMutationVariables = Exact<{
  input: CreateSyncedVehicleAccountInput;
}>;

export type Mobile_CreateSyncedVehicleAccountMutation = {
  __typename: 'Mutation';
  createSyncedVehicleAccount:
    | {
        __typename: 'CreateSyncedVehicleAccount';
        account: { __typename: 'Account'; id: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_CreateManualAccountMutationVariables = Exact<{
  input: CreateManualAccountMutationInput;
}>;

export type Mobile_CreateManualAccountMutation = {
  __typename: 'Mutation';
  createManualAccount:
    | {
        __typename: 'CreateManualAccountMutation';
        account: { __typename: 'Account'; id: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_SearchVehiclesQueryVariables = Exact<{
  search: Scalars['String']['input'];
  limit: InputMaybe<Scalars['Int']['input']>;
}>;

export type Mobile_SearchVehiclesQuery = {
  __typename: 'Query';
  vehicles: Array<{ __typename: 'Vehicle'; vin: string; name: string; value: number }>;
};

export type Mobile_GetZestimateQueryVariables = Exact<{
  address: Scalars['String']['input'];
}>;

export type Mobile_GetZestimateQuery = {
  __typename: 'Query';
  zestimates: Array<{
    __typename: 'ZestimateResult';
    zpid: string;
    addressStreet: string;
    addressCity: string;
    addressStateAbbr: string;
    addressPostalCode: string;
    zestimate: number;
  }>;
};

export type Mobile_SubmitAssistantFeedbackMutationVariables = Exact<{
  input: SubmitAssistantFeedbackInput;
}>;

export type Mobile_SubmitAssistantFeedbackMutation = {
  __typename: 'Mutation';
  submitAssistantFeedback: { __typename: 'SubmitAssistantFeedback'; success: boolean };
};

export type Mobile_GetMessageThreadsQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_GetMessageThreadsQuery = {
  __typename: 'Query';
  messageThreads: Array<{
    __typename: 'MessageThread';
    id: string;
    subject: string | null | undefined;
    createdAt: string;
    lastMessageSentAt: string | null | undefined;
    hasOutstandingAssistantRequests: boolean;
  }>;
  me: {
    __typename: 'User';
    id: string;
    profile:
      | { __typename: 'UserProfile'; id: string; aiAssistantOptedInAt: string | null | undefined }
      | null
      | undefined;
  };
};

export type Mobile_GetMessageThreadQueryVariables = Exact<{
  threadId: Scalars['ID']['input'];
}>;

export type Mobile_GetMessageThreadQuery = {
  __typename: 'Query';
  messageThread:
    | {
        __typename: 'MessageThread';
        id: string;
        subject: string | null | undefined;
        hasOutstandingAssistantRequests: boolean;
        messages: Array<
          | {
              __typename: 'AssistantMessage';
              id: string;
              threadId: string;
              createdAt: string;
              content: string;
              name: string;
              suggestedPrompts: Array<string> | null | undefined;
              debugInfo: string | null | undefined;
            }
          | {
              __typename: 'AssistantStatusMessage';
              id: string;
              createdAt: string;
              threadId: string;
              content: string;
              name: string;
            }
          | {
              __typename: 'UserMessage';
              id: string;
              threadId: string;
              createdAt: string;
              content: string;
              user: {
                __typename: 'User';
                id: string;
                name: string;
                profilePictureUrl: string | null | undefined;
              };
            }
        >;
      }
    | null
    | undefined;
};

export type Mobile_OnMessageUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type Mobile_OnMessageUpdatedSubscription = {
  __typename: 'Subscription';
  onMessageUpdated:
    | {
        __typename: 'MessageUpdatedSubscription';
        message:
          | {
              __typename: 'AssistantMessage';
              id: string;
              threadId: string;
              createdAt: string;
              content: string;
              name: string;
              suggestedPrompts: Array<string> | null | undefined;
              debugInfo: string | null | undefined;
            }
          | {
              __typename: 'AssistantStatusMessage';
              id: string;
              createdAt: string;
              threadId: string;
              content: string;
              name: string;
            }
          | {
              __typename: 'UserMessage';
              id: string;
              threadId: string;
              createdAt: string;
              content: string;
              user: {
                __typename: 'User';
                id: string;
                name: string;
                profilePictureUrl: string | null | undefined;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_OnMessageCreatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type Mobile_OnMessageCreatedSubscription = {
  __typename: 'Subscription';
  onMessageCreated:
    | {
        __typename: 'MessageCreatedSubscription';
        message:
          | {
              __typename: 'AssistantMessage';
              id: string;
              threadId: string;
              createdAt: string;
              content: string;
              name: string;
              suggestedPrompts: Array<string> | null | undefined;
              debugInfo: string | null | undefined;
            }
          | {
              __typename: 'AssistantStatusMessage';
              id: string;
              createdAt: string;
              threadId: string;
              content: string;
              name: string;
            }
          | {
              __typename: 'UserMessage';
              id: string;
              threadId: string;
              createdAt: string;
              content: string;
              user: {
                __typename: 'User';
                id: string;
                name: string;
                profilePictureUrl: string | null | undefined;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_OnMessageThreadUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type Mobile_OnMessageThreadUpdatedSubscription = {
  __typename: 'Subscription';
  onMessageThreadUpdated:
    | {
        __typename: 'MessageThreadUpdatedSubscription';
        messageThread:
          | {
              __typename: 'MessageThread';
              id: string;
              subject: string | null | undefined;
              createdAt: string;
              lastMessageSentAt: string | null | undefined;
              hasOutstandingAssistantRequests: boolean;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type MoveMoneyScreenQueryVariables = Exact<{ [key: string]: never }>;

export type MoveMoneyScreenQuery = {
  __typename: 'Query';
  categories: Array<{ __typename: 'Category'; id: string; name: string; icon: string }>;
  categoryGroups: Array<{ __typename: 'CategoryGroup'; id: string; name: string }>;
};

export type Mobile_MoveMoneyScreenMutationVariables = Exact<{
  input: MoveMoneyMutationInput;
}>;

export type Mobile_MoveMoneyScreenMutation = {
  __typename: 'Mutation';
  moveMoneyBetweenCategories:
    | {
        __typename: 'MoveMoneyMutation';
        fromBudgetItem:
          | { __typename: 'BudgetItem'; id: string; budgetAmount: number | null | undefined }
          | null
          | undefined;
        toBudgetItem:
          | { __typename: 'BudgetItem'; id: string; budgetAmount: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_ResetRolloverMutationVariables = Exact<{
  input: ResetBudgetRolloverInput;
}>;

export type Mobile_ResetRolloverMutation = {
  __typename: 'Mutation';
  resetBudgetRollover:
    | {
        __typename: 'ResetBudgetRolloverMutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SelectBudgetCategoryScreenQueryVariables = Exact<{ [key: string]: never }>;

export type SelectBudgetCategoryScreenQuery = {
  __typename: 'Query';
  categoryGroups: Array<{
    __typename: 'CategoryGroup';
    id: string;
    name: string;
    order: number;
    type: CategoryGroupType;
    groupLevelBudgetingEnabled: boolean | null | undefined;
    categories: Array<{
      __typename: 'Category';
      id: string;
      name: string;
      order: number;
      icon: string;
    }>;
  }>;
};

export type Mobile_UpdateNonMonthlyCategoryMutationVariables = Exact<{
  input: UpdateCategoryInput;
}>;

export type Mobile_UpdateNonMonthlyCategoryMutation = {
  __typename: 'Mutation';
  updateCategory:
    | {
        __typename: 'UpdateCategoryPayload';
        category:
          | {
              __typename: 'Category';
              id: string;
              name: string;
              icon: string;
              order: number;
              budgetVariability: BudgetVariability | null | undefined;
              excludeFromBudget: boolean | null | undefined;
              isSystemCategory: boolean;
              updatedAt: string;
              group: {
                __typename: 'CategoryGroup';
                id: string;
                type: CategoryGroupType;
                budgetVariability: BudgetVariability | null | undefined;
                groupLevelBudgetingEnabled: boolean | null | undefined;
              };
              rolloverPeriod:
                | {
                    __typename: 'BudgetRolloverPeriod';
                    id: string;
                    startMonth: string;
                    endMonth: string | null | undefined;
                    startingBalance: number | null | undefined;
                    targetAmount: number | null | undefined;
                    frequency: string | null | undefined;
                    type: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CashFlowScreenAggregatesQueryVariables = Exact<{ [key: string]: never }>;

export type CashFlowScreenAggregatesQuery = {
  __typename: 'Query';
  byYear: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; year: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      savings: number;
      savingsRate: number;
      sumIncome: number;
      sumExpense: number;
    };
  }>;
  byMonth: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; month: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      savings: number;
      savingsRate: number;
      sumIncome: number;
      sumExpense: number;
    };
  }>;
  byQuarter: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; quarter: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      savings: number;
      savingsRate: number;
      sumIncome: number;
      sumExpense: number;
    };
  }>;
};

export type Mobile_GetCashFlowScreenQueryVariables = Exact<{
  startDate: InputMaybe<Scalars['Date']['input']>;
  endDate: InputMaybe<Scalars['Date']['input']>;
  cacheKey: InputMaybe<Scalars['String']['input']>;
}>;

export type Mobile_GetCashFlowScreenQuery = {
  __typename: 'Query';
  cacheKey: string | null | undefined;
  byCategory: Array<{
    __typename: 'AggregateData';
    groupBy: {
      __typename: 'AggregateGroupBy';
      category:
        | {
            __typename: 'Category';
            id: string;
            name: string;
            icon: string;
            group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
          }
        | null
        | undefined;
    };
    summary: { __typename: 'TransactionsSummary'; sum: number };
  }>;
  byCategoryGroup: Array<{
    __typename: 'AggregateData';
    groupBy: {
      __typename: 'AggregateGroupBy';
      categoryGroup:
        | { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType }
        | null
        | undefined;
    };
    summary: { __typename: 'TransactionsSummary'; sum: number };
  }>;
  byMerchant: Array<{
    __typename: 'AggregateData';
    groupBy: {
      __typename: 'AggregateGroupBy';
      merchant:
        | { __typename: 'Merchant'; id: string; name: string; logoUrl: string | null | undefined }
        | null
        | undefined;
    };
    summary: { __typename: 'TransactionsSummary'; sumIncome: number; sumExpense: number };
  }>;
  summary: Array<{
    __typename: 'AggregateData';
    summary: {
      __typename: 'TransactionsSummary';
      sumIncome: number;
      sumExpense: number;
      savings: number;
      savingsRate: number;
    };
  }>;
};

export type Mobile_GetCategoryDeletionInfoQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Mobile_GetCategoryDeletionInfoQuery = {
  __typename: 'Query';
  categoryDeletionInfo:
    | {
        __typename: 'CategoryDeletionInfo';
        transactionsCount: number;
        rulesCount: number;
        category: {
          __typename: 'Category';
          id: string;
          name: string;
          icon: string;
          isSystemCategory: boolean;
        };
      }
    | null
    | undefined;
};

export type Mobile_DeleteCategoryMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  moveToCategoryId: InputMaybe<Scalars['UUID']['input']>;
}>;

export type Mobile_DeleteCategoryMutation = {
  __typename: 'Mutation';
  deleteCategory:
    | {
        __typename: 'DeleteCategoryMutation';
        deleted: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_CategoryDetailQueryVariables = Exact<{
  categoryId: Scalars['UUID']['input'];
  month: Scalars['Date']['input'];
}>;

export type Mobile_CategoryDetailQuery = {
  __typename: 'Query';
  category:
    | {
        __typename: 'Category';
        id: string;
        name: string;
        icon: string;
        excludeFromBudget: boolean | null | undefined;
        group: {
          __typename: 'CategoryGroup';
          id: string;
          name: string;
          type: CategoryGroupType;
          groupLevelBudgetingEnabled: boolean | null | undefined;
        };
        rolloverPeriod:
          | {
              __typename: 'BudgetRolloverPeriod';
              id: string;
              startingBalance: number | null | undefined;
            }
          | null
          | undefined;
        budgetAmountsForMonth:
          | {
              __typename: 'BudgetMonthlyAmounts';
              remainingAmount: number | null | undefined;
              plannedAmount: number | null | undefined;
              actualAmount: number | null | undefined;
              previousMonthRolloverAmount: number | null | undefined;
              rolloverType: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  byYear: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; year: string | null | undefined };
    summary: { __typename: 'TransactionsSummary'; sum: number; avg: number | null | undefined };
  }>;
  byMonth: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; month: string | null | undefined };
    summary: { __typename: 'TransactionsSummary'; sum: number; avg: number | null | undefined };
  }>;
  byQuarter: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; quarter: string | null | undefined };
    summary: { __typename: 'TransactionsSummary'; sum: number; avg: number | null | undefined };
  }>;
};

export type BudgetMonthAmountsFragment = {
  __typename: 'BudgetMonthlyAmounts';
  remainingAmount: number | null | undefined;
  plannedAmount: number | null | undefined;
  actualAmount: number | null | undefined;
  previousMonthRolloverAmount: number | null | undefined;
  rolloverType: string | null | undefined;
};

export type Mobile_DeleteCategoryGroupMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  moveToGroupId: InputMaybe<Scalars['UUID']['input']>;
}>;

export type Mobile_DeleteCategoryGroupMutation = {
  __typename: 'Mutation';
  deleteCategoryGroup:
    | {
        __typename: 'DeleteCategoryGroupMutation';
        deleted: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_CategoryGroupDetailContainerQueryVariables = Exact<{
  categoryGroupId: Scalars['UUID']['input'];
  month: Scalars['Date']['input'];
}>;

export type Mobile_CategoryGroupDetailContainerQuery = {
  __typename: 'Query';
  categoryGroup:
    | {
        __typename: 'CategoryGroup';
        id: string;
        name: string;
        type: CategoryGroupType;
        groupLevelBudgetingEnabled: boolean | null | undefined;
        budgetVariability: BudgetVariability | null | undefined;
        rolloverPeriod:
          | {
              __typename: 'BudgetRolloverPeriod';
              id: string;
              startMonth: string;
              startingBalance: number | null | undefined;
            }
          | null
          | undefined;
        budgetAmountsForMonth:
          | {
              __typename: 'BudgetMonthlyAmounts';
              remainingAmount: number | null | undefined;
              plannedAmount: number | null | undefined;
              actualAmount: number | null | undefined;
              previousMonthRolloverAmount: number | null | undefined;
              rolloverType: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AggregatesSummaryFieldsFragment = {
  __typename: 'AggregateData';
  summary: {
    __typename: 'TransactionsSummary';
    count: number;
    sum: number;
    avg: number | null | undefined;
  };
};

export type Mobile_CategoryGroupDetailChartQueryVariables = Exact<{
  categoryGroupId: Scalars['UUID']['input'];
}>;

export type Mobile_CategoryGroupDetailChartQuery = {
  __typename: 'Query';
  summary: Array<{
    __typename: 'AggregateData';
    summary: {
      __typename: 'TransactionsSummary';
      count: number;
      sum: number;
      avg: number | null | undefined;
    };
  }>;
  byYear: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; year: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      count: number;
      sum: number;
      avg: number | null | undefined;
    };
  }>;
  byMonth: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; month: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      count: number;
      sum: number;
      avg: number | null | undefined;
    };
  }>;
  byQuarter: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; quarter: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      count: number;
      sum: number;
      avg: number | null | undefined;
    };
  }>;
};

export type Mobile_CustomizeGoalScreenQueryVariables = Exact<{
  goalId: Scalars['ID']['input'];
}>;

export type Mobile_CustomizeGoalScreenQuery = {
  __typename: 'Query';
  goalV2:
    | {
        __typename: 'GoalV2';
        id: string;
        name: string;
        imageStorageProvider: string | null | undefined;
        imageStorageProviderId: string | null | undefined;
        targetAmount: number | null | undefined;
      }
    | null
    | undefined;
};

export type Mobile_GoalDetailScreenV2QueryVariables = Exact<{
  goalId: Scalars['ID']['input'];
}>;

export type Mobile_GoalDetailScreenV2Query = {
  __typename: 'Query';
  goalV2:
    | {
        __typename: 'GoalV2';
        id: string;
        imageStorageProvider: string | null | undefined;
        imageStorageProviderId: string | null | undefined;
        archivedAt: string | null | undefined;
        name: string;
        targetAmount: number | null | undefined;
        startingAmount: number | null | undefined;
        currentAmount: number | null | undefined;
        completedAt: string | null | undefined;
        type: string;
        defaultName: string | null | undefined;
        completionPercent: number | null | undefined;
        priority: number;
        plannedMonthlyContribution: number | null | undefined;
        plannedMonthlyPretaxContribution: number | null | undefined;
        objective: string;
        accountAllocations: Array<{
          __typename: 'GoalAccountAllocation';
          id: string;
          currentAmount: number | null | undefined;
          currentMonthChange:
            | {
                __typename: 'GoalAccountAllocationCurrentMonthChange';
                percent: number | null | undefined;
                amount: number | null | undefined;
              }
            | null
            | undefined;
          account: {
            __typename: 'Account';
            id: string;
            displayName: string;
            hideFromList: boolean;
            deactivatedAt: string | null | undefined;
            mask: string | null | undefined;
            dataProvider: string | null | undefined;
            subtype: { __typename: 'AccountSubtype'; display: string; name: string };
            type: { __typename: 'AccountType'; name: string };
            institution:
              | {
                  __typename: 'Institution';
                  id: string;
                  logo: string | null | undefined;
                  primaryColor: string;
                }
              | null
              | undefined;
          };
        }>;
        monthlyContributionSummaries: Array<{
          __typename: 'GoalContributionMonthlySummary';
          month: string;
          sum: number;
          sumCredit: number;
          sumDebit: number;
          plannedAmount: number | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type Mobile_GoalsAllocateBalanceScreenQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_GoalsAllocateBalanceScreenQuery = {
  __typename: 'Query';
  accountsWithUnallocatedBalancesForGoals: Array<{
    __typename: 'Account';
    id: string;
    displayName: string;
    displayBalance: number | null | undefined;
    availableBalanceForGoals: number;
    dataProvider: string | null | undefined;
    goalAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      amount: number | null | undefined;
      currentAmount: number | null | undefined;
      account: { __typename: 'Account'; id: string; availableBalanceForGoals: number };
      goal: {
        __typename: 'GoalV2';
        id: string;
        targetAmount: number | null | undefined;
        currentAmount: number | null | undefined;
        completionPercent: number | null | undefined;
        completedAt: string | null | undefined;
        archivedAt: string | null | undefined;
        name: string;
        priority: number;
        imageStorageProvider: string | null | undefined;
        imageStorageProviderId: string | null | undefined;
        startingAmount: number | null | undefined;
        type: string;
        defaultName: string | null | undefined;
        accountAllocations: Array<{
          __typename: 'GoalAccountAllocation';
          id: string;
          account: {
            __typename: 'Account';
            id: string;
            logoUrl: string | null | undefined;
            icon: string;
            dataProvider: string | null | undefined;
            type: { __typename: 'AccountType'; name: string };
            subtype: { __typename: 'AccountSubtype'; name: string };
            institution:
              | {
                  __typename: 'Institution';
                  id: string;
                  logo: string | null | undefined;
                  primaryColor: string;
                }
              | null
              | undefined;
          };
        }>;
      };
    }>;
    type: { __typename: 'AccountType'; name: string };
    subtype: { __typename: 'AccountSubtype'; name: string };
    institution:
      | {
          __typename: 'Institution';
          id: string;
          logo: string | null | undefined;
          primaryColor: string;
        }
      | null
      | undefined;
  }>;
};

export type Mobile_GoalsMapAccountsScreenQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_GoalsMapAccountsScreenQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    archivedAt: string | null | undefined;
    name: string;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
    targetAmount: number | null | undefined;
    startingAmount: number | null | undefined;
    currentAmount: number | null | undefined;
    completionPercent: number | null | undefined;
    type: string;
    defaultName: string | null | undefined;
    accountAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      account: {
        __typename: 'Account';
        id: string;
        logoUrl: string | null | undefined;
        icon: string;
        dataProvider: string | null | undefined;
        type: { __typename: 'AccountType'; name: string };
        subtype: { __typename: 'AccountSubtype'; name: string };
        institution:
          | {
              __typename: 'Institution';
              id: string;
              logo: string | null | undefined;
              primaryColor: string;
            }
          | null
          | undefined;
      };
    }>;
  }>;
};

export type Mobile_GoalsMonthlyBudgetsScreenQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type Mobile_GoalsMonthlyBudgetsScreenQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    plannedMonthlyContribution: number | null | undefined;
    objective: string;
    name: string;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
  }>;
  aggregates: Array<{
    __typename: 'AggregateData';
    summary: { __typename: 'TransactionsSummary'; savings: number };
  }>;
};

export type Mobile_GoalsPreTaxBudgetsScreenQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_GoalsPreTaxBudgetsScreenQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    plannedMonthlyPretaxContribution: number | null | undefined;
    objective: string;
    name: string;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
  }>;
};

export type Mobile_GoalsScreenV2QueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_GoalsScreenV2Query = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
    priority: number;
    archivedAt: string | null | undefined;
    name: string;
    targetAmount: number | null | undefined;
    startingAmount: number | null | undefined;
    currentAmount: number | null | undefined;
    completedAt: string | null | undefined;
    type: string;
    defaultName: string | null | undefined;
    completionPercent: number | null | undefined;
    accountAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      account: {
        __typename: 'Account';
        id: string;
        dataProvider: string | null | undefined;
        type: { __typename: 'AccountType'; name: string };
        subtype: { __typename: 'AccountSubtype'; name: string };
        institution:
          | {
              __typename: 'Institution';
              id: string;
              logo: string | null | undefined;
              primaryColor: string;
            }
          | null
          | undefined;
      };
    }>;
  }>;
  accountsWithUnallocatedBalancesForGoals: Array<{
    __typename: 'Account';
    id: string;
    name: string | null | undefined;
    dataProvider: string | null | undefined;
    type: { __typename: 'AccountType'; name: string };
    subtype: { __typename: 'AccountSubtype'; name: string };
    institution:
      | {
          __typename: 'Institution';
          id: string;
          logo: string | null | undefined;
          primaryColor: string;
        }
      | null
      | undefined;
  }>;
};

export type Mobile_GoalsTargetAmountsScreenQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_GoalsTargetAmountsScreenQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    targetAmount: number | null | undefined;
    startingAmount: number | null | undefined;
    type: string;
    name: string;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
  }>;
};

export type Mobile_RankGoalsScreenQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_RankGoalsScreenQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    archivedAt: string | null | undefined;
    name: string;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
  }>;
};

export type Mobile_UpdateGoalsPrioritiesMutationVariables = Exact<{
  input: UpdateGoalPrioritiesInput;
}>;

export type Mobile_UpdateGoalsPrioritiesMutation = {
  __typename: 'Mutation';
  updateGoalPriorities:
    | {
        __typename: 'UpdateGoalPriorities';
        goals:
          | Array<{ __typename: 'GoalV2'; id: string; priority: number } | null | undefined>
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_GetInvestmentsPortfolioQueryVariables = Exact<{
  portfolioInput: InputMaybe<PortfolioInput>;
  batchedPerformanceInput: InputMaybe<Array<InputMaybe<DateRange>> | InputMaybe<DateRange>>;
}>;

export type Mobile_GetInvestmentsPortfolioQuery = {
  __typename: 'Query';
  portfolio: {
    __typename: 'Portfolio';
    batchedPerformance: {
      __typename: 'BatchedPortfolioPerformance';
      totalValue: number;
      historicalCharts: Array<
        | {
            __typename: 'HistoricalChartForPeriod';
            period: string;
            chart: Array<{
              __typename: 'SecurityPerformanceChartDatum';
              date: string;
              value: number;
              returnPercent: number;
            }>;
          }
        | null
        | undefined
      >;
    };
    allocationSimple: Array<
      | {
          __typename: 'SimplePortfolioAliquot';
          type: string;
          typeDisplay: string;
          allocationPercent: number;
          totalValue: number;
        }
      | null
      | undefined
    >;
    performance: { __typename: 'PortfolioPerformance'; totalValue: number };
  };
};

export type Mobile_GetSecuritiesHistoricalPerformanceQueryVariables = Exact<{
  input: SecurityHistoricalPerformanceInput;
}>;

export type Mobile_GetSecuritiesHistoricalPerformanceQuery = {
  __typename: 'Query';
  securityHistoricalPerformance: Array<{
    __typename: 'SecurityPerformance';
    security: { __typename: 'Security'; id: string };
    historicalChart: Array<{
      __typename: 'SecurityPerformanceChartDatum';
      date: string;
      returnPercent: number;
      value: number;
    }>;
  }>;
};

export type Mobile_GetInvestmentsAccountsQueryVariables = Exact<{
  filters: InputMaybe<AccountFilters>;
}>;

export type Mobile_GetInvestmentsAccountsQuery = {
  __typename: 'Query';
  accounts: Array<{
    __typename: 'Account';
    id: string;
    displayName: string;
    holdingsCount: number;
    dataProvider: string | null | undefined;
    type: { __typename: 'AccountType'; name: string };
    subtype: { __typename: 'AccountSubtype'; name: string };
    institution:
      | {
          __typename: 'Institution';
          id: string;
          logo: string | null | undefined;
          primaryColor: string;
        }
      | null
      | undefined;
  }>;
};

export type Mobile_GetPortfolioBenchmarksQueryVariables = Exact<{
  portfolioInput: InputMaybe<PortfolioInput>;
}>;

export type Mobile_GetPortfolioBenchmarksQuery = {
  __typename: 'Query';
  portfolio: {
    __typename: 'Portfolio';
    performance: {
      __typename: 'PortfolioPerformance';
      benchmarks: Array<{
        __typename: 'SecurityPerformance';
        security: { __typename: 'Security'; id: string; ticker: string | null | undefined };
      }>;
    };
  };
};

export type Mobile_GetEditMerchantQueryVariables = Exact<{
  merchantId: Scalars['ID']['input'];
}>;

export type Mobile_GetEditMerchantQuery = {
  __typename: 'Query';
  merchant:
    | {
        __typename: 'Merchant';
        id: string;
        name: string;
        logoUrl: string | null | undefined;
        transactionCount: number;
        ruleCount: number;
        canBeDeleted: boolean | null | undefined;
        recurringTransactionStream:
          | {
              __typename: 'RecurringTransactionStream';
              id: string;
              frequency: string;
              amount: number | null | undefined;
              baseDate: string;
              isActive: boolean;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_UpdateMerchantMutationVariables = Exact<{
  input: UpdateMerchantInput;
}>;

export type Mobile_UpdateMerchantMutation = {
  __typename: 'Mutation';
  updateMerchant:
    | {
        __typename: 'UpdateMerchantMutation';
        merchant:
          | {
              __typename: 'Merchant';
              id: string;
              name: string;
              recurringTransactionStream:
                | {
                    __typename: 'RecurringTransactionStream';
                    id: string;
                    frequency: string;
                    amount: number | null | undefined;
                    baseDate: string;
                    isActive: boolean;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_DeleteMerchantMutationVariables = Exact<{
  merchantId: Scalars['ID']['input'];
  moveToId: InputMaybe<Scalars['ID']['input']>;
}>;

export type Mobile_DeleteMerchantMutation = {
  __typename: 'Mutation';
  deleteMerchant: { __typename: 'DeleteMerchantMutation'; success: boolean } | null | undefined;
};

export type Mobile_SearchMerchantsQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;

export type Mobile_SearchMerchantsQuery = {
  __typename: 'Query';
  merchants: Array<{
    __typename: 'Merchant';
    id: string;
    name: string;
    transactionCount: number;
    logoUrl: string | null | undefined;
  }>;
};

export type Mobile_GetAllRecurringTransactionItemsQueryVariables = Exact<{
  filters: InputMaybe<RecurringTransactionFilter>;
  includeLiabilities: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Mobile_GetAllRecurringTransactionItemsQuery = {
  __typename: 'Query';
  recurringTransactionStreams: Array<{
    __typename: 'RecurringTransactionItem';
    stream: {
      __typename: 'RecurringTransactionStream';
      id: string;
      frequency: string;
      isActive: boolean;
      isApproximate: boolean;
      name: string;
      logoUrl: string | null | undefined;
      merchant:
        | { __typename: 'Merchant'; id: string; name: string; logoUrl: string | null | undefined }
        | null
        | undefined;
      creditReportLiabilityAccount:
        | {
            __typename: 'CreditReportLiabilityAccount';
            id: string;
            account: { __typename: 'Account'; id: string } | null | undefined;
          }
        | null
        | undefined;
    };
    category: { __typename: 'Category'; id: string; name: string; icon: string } | null | undefined;
    nextForecastedTransaction: {
      __typename: 'RecurringTransactionNextForecastedTransaction';
      date: string;
      amount: number;
    };
  }>;
};

export type Mobile_GetUpcomingRecurringTransactionItemsQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
  filters: InputMaybe<RecurringTransactionFilter>;
  includeLiabilities: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Mobile_GetUpcomingRecurringTransactionItemsQuery = {
  __typename: 'Query';
  recurringTransactionItems: Array<{
    __typename: 'RecurringTransactionCalendarItem';
    date: string;
    isPast: boolean;
    markedPaidAt: string | null | undefined;
    isCompleted: boolean | null | undefined;
    transactionId: string | null | undefined;
    amount: number | null | undefined;
    amountDiff: number | null | undefined;
    isAmountDifferentThanOriginal: boolean | null | undefined;
    creditReportLiabilityStatementId: string | null | undefined;
    stream: {
      __typename: 'RecurringTransactionStream';
      id: string;
      frequency: string;
      isActive: boolean;
      amount: number | null | undefined;
      isApproximate: boolean;
      name: string;
      logoUrl: string | null | undefined;
      merchant:
        | { __typename: 'Merchant'; id: string; name: string; logoUrl: string | null | undefined }
        | null
        | undefined;
      creditReportLiabilityAccount:
        | {
            __typename: 'CreditReportLiabilityAccount';
            id: string;
            liabilityType: string;
            account: { __typename: 'Account'; id: string } | null | undefined;
          }
        | null
        | undefined;
    };
    category: { __typename: 'Category'; id: string; name: string; icon: string } | null | undefined;
    account:
      | {
          __typename: 'Account';
          id: string;
          displayName: string;
          icon: string;
          logoUrl: string | null | undefined;
        }
      | null
      | undefined;
    liabilityStatement:
      | {
          __typename: 'LiabilityStatement';
          id: string;
          paymentStatus: LiabilityStatementPaymentStatus | null | undefined;
          remainingBalance: number | null | undefined;
          minimumPaymentAmount: number | null | undefined;
          paymentTransactions:
            | Array<
                | {
                    __typename: 'Transaction';
                    id: string;
                    amount: number;
                    date: string;
                    category: {
                      __typename: 'Category';
                      id: string;
                      name: string;
                      icon: string;
                      group: {
                        __typename: 'CategoryGroup';
                        id: string;
                        name: string;
                        type: CategoryGroupType;
                      };
                    };
                  }
                | null
                | undefined
              >
            | null
            | undefined;
        }
      | null
      | undefined;
  }>;
};

export type Mobile_NetWorthChartOverlyQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_NetWorthChartOverlyQuery = {
  __typename: 'Query';
  accountTypes: Array<{ __typename: 'AccountType'; name: string; display: string }>;
};

export type ReviewQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type ReviewQuery = {
  __typename: 'Query';
  review:
    | {
        __typename: 'Review';
        pages: Array<{
          __typename: 'ReviewPage';
          hideFooter: boolean | null | undefined;
          analyticsName: string;
          background:
            | {
                __typename: 'ReviewPageBackground';
                color: string | null | undefined;
                gradient: { __typename: 'Gradient'; colors: Array<string> } | null | undefined;
              }
            | null
            | undefined;
          blocks: Array<
            | {
                __typename: 'AccountsListBlock';
                accountType: string | null | undefined;
                displayBalanceAsOf: string | null | undefined;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'DividerBlock';
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'GoalContributionsListBlock';
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'GroupBlock';
                centered: boolean | null | undefined;
                children: Array<
                  | {
                      __typename: 'AccountsListBlock';
                      accountType: string | null | undefined;
                      displayBalanceAsOf: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'DividerBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'GoalContributionsListBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'GroupBlock';
                      centered: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'HorizontalBarChartBlock';
                      topRightLabel: string | null | undefined;
                      smallBarStyle: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      bars: Array<{
                        __typename: 'HorizontalBarChartBar';
                        label: string | null | undefined;
                        barPercent: number;
                        barColor: string;
                        rightLabel: string | null | undefined;
                        leftLabel: string | null | undefined;
                      }>;
                    }
                  | {
                      __typename: 'InfoBlock';
                      label: string | null | undefined;
                      value: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'MerchantsListBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      merchants:
                        | Array<{
                            __typename: 'ReviewMerchant';
                            id: string;
                            name: string;
                            logoUrl: string | null | undefined;
                            transactionCount: number;
                          }>
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'NetWorthChartBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      snapshotsByAccountType: Array<{
                        __typename: 'SnapshotByType';
                        accountType: string;
                        month: string;
                        balance: number;
                      }>;
                    }
                  | {
                      __typename: 'PageTitleBlock';
                      header: string | null | undefined;
                      title: string | null | undefined;
                      subtitle: string | null | undefined;
                      centerSubtitle: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'PieChartBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      slices: Array<{
                        __typename: 'PieChartSlice';
                        label: string;
                        value: number;
                        color: string;
                      }>;
                    }
                  | {
                      __typename: 'ShareMonarchBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'ShareableBlock';
                      title: string | null | undefined;
                      subtitle: string | null | undefined;
                      sharedTitle: string | null | undefined;
                      sharedSubtitle: string | null | undefined;
                      sharedFooter: string | null | undefined;
                      sharedContent: string | null | undefined;
                      shareButtonText: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'SparklineRowBlock';
                      label: string | null | undefined;
                      rightLabel: string | null | undefined;
                      labelColor: string | null | undefined;
                      lineColor: string | null | undefined;
                      values: Array<number>;
                      title: string | null | undefined;
                      largeStyle: boolean | null | undefined;
                      hideValueChange: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'StackedBarChartBlock';
                      groupColors: Array<string>;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      data: Array<{
                        __typename: 'StackedBarChartDataPoint';
                        x: string;
                        total: number | null | undefined;
                        groups: Array<number>;
                      }>;
                    }
                  | {
                      __typename: 'SuccessBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'TextBlock';
                      content: string;
                      fontSize: number | null | undefined;
                      fontWeight: string | null | undefined;
                      color: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'TransactionsListBlock';
                      categories: Array<string> | null | undefined;
                      startDate: string | null | undefined;
                      endDate: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                >;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'HorizontalBarChartBlock';
                topRightLabel: string | null | undefined;
                smallBarStyle: boolean | null | undefined;
                bars: Array<{
                  __typename: 'HorizontalBarChartBar';
                  label: string | null | undefined;
                  barPercent: number;
                  barColor: string;
                  rightLabel: string | null | undefined;
                  leftLabel: string | null | undefined;
                  link:
                    | {
                        __typename: 'BlockLink';
                        title: string | null | undefined;
                        blocks: Array<
                          | {
                              __typename: 'AccountsListBlock';
                              accountType: string | null | undefined;
                              displayBalanceAsOf: string | null | undefined;
                            }
                          | { __typename: 'DividerBlock' }
                          | { __typename: 'GoalContributionsListBlock' }
                          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                          | {
                              __typename: 'HorizontalBarChartBlock';
                              topRightLabel: string | null | undefined;
                              smallBarStyle: boolean | null | undefined;
                              bars: Array<{
                                __typename: 'HorizontalBarChartBar';
                                label: string | null | undefined;
                                barPercent: number;
                                barColor: string;
                                rightLabel: string | null | undefined;
                                leftLabel: string | null | undefined;
                              }>;
                            }
                          | {
                              __typename: 'InfoBlock';
                              label: string | null | undefined;
                              value: string | null | undefined;
                            }
                          | {
                              __typename: 'MerchantsListBlock';
                              merchants:
                                | Array<{
                                    __typename: 'ReviewMerchant';
                                    id: string;
                                    name: string;
                                    logoUrl: string | null | undefined;
                                    transactionCount: number;
                                  }>
                                | null
                                | undefined;
                            }
                          | {
                              __typename: 'NetWorthChartBlock';
                              snapshotsByAccountType: Array<{
                                __typename: 'SnapshotByType';
                                accountType: string;
                                month: string;
                                balance: number;
                              }>;
                            }
                          | {
                              __typename: 'PageTitleBlock';
                              header: string | null | undefined;
                              title: string | null | undefined;
                              subtitle: string | null | undefined;
                              centerSubtitle: boolean | null | undefined;
                            }
                          | {
                              __typename: 'PieChartBlock';
                              slices: Array<{
                                __typename: 'PieChartSlice';
                                label: string;
                                value: number;
                                color: string;
                              }>;
                            }
                          | { __typename: 'ShareMonarchBlock' }
                          | {
                              __typename: 'ShareableBlock';
                              title: string | null | undefined;
                              subtitle: string | null | undefined;
                              sharedTitle: string | null | undefined;
                              sharedSubtitle: string | null | undefined;
                              sharedFooter: string | null | undefined;
                              sharedContent: string | null | undefined;
                              shareButtonText: string | null | undefined;
                            }
                          | {
                              __typename: 'SparklineRowBlock';
                              label: string | null | undefined;
                              rightLabel: string | null | undefined;
                              labelColor: string | null | undefined;
                              lineColor: string | null | undefined;
                              values: Array<number>;
                              title: string | null | undefined;
                              largeStyle: boolean | null | undefined;
                              hideValueChange: boolean | null | undefined;
                            }
                          | {
                              __typename: 'StackedBarChartBlock';
                              groupColors: Array<string>;
                              data: Array<{
                                __typename: 'StackedBarChartDataPoint';
                                x: string;
                                total: number | null | undefined;
                                groups: Array<number>;
                              }>;
                            }
                          | { __typename: 'SuccessBlock' }
                          | {
                              __typename: 'TextBlock';
                              content: string;
                              fontSize: number | null | undefined;
                              fontWeight: string | null | undefined;
                              color: string | null | undefined;
                            }
                          | {
                              __typename: 'TransactionsListBlock';
                              categories: Array<string> | null | undefined;
                              startDate: string | null | undefined;
                              endDate: string | null | undefined;
                            }
                        >;
                      }
                    | null
                    | undefined;
                }>;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'InfoBlock';
                label: string | null | undefined;
                value: string | null | undefined;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'MerchantsListBlock';
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
                merchants:
                  | Array<{
                      __typename: 'ReviewMerchant';
                      id: string;
                      name: string;
                      logoUrl: string | null | undefined;
                      transactionCount: number;
                    }>
                  | null
                  | undefined;
              }
            | {
                __typename: 'NetWorthChartBlock';
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
                snapshotsByAccountType: Array<{
                  __typename: 'SnapshotByType';
                  accountType: string;
                  month: string;
                  balance: number;
                }>;
              }
            | {
                __typename: 'PageTitleBlock';
                header: string | null | undefined;
                title: string | null | undefined;
                subtitle: string | null | undefined;
                centerSubtitle: boolean | null | undefined;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'PieChartBlock';
                slices: Array<{
                  __typename: 'PieChartSlice';
                  label: string;
                  value: number;
                  color: string;
                  link:
                    | {
                        __typename: 'BlockLink';
                        title: string | null | undefined;
                        blocks: Array<
                          | {
                              __typename: 'AccountsListBlock';
                              accountType: string | null | undefined;
                              displayBalanceAsOf: string | null | undefined;
                            }
                          | { __typename: 'DividerBlock' }
                          | { __typename: 'GoalContributionsListBlock' }
                          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                          | {
                              __typename: 'HorizontalBarChartBlock';
                              topRightLabel: string | null | undefined;
                              smallBarStyle: boolean | null | undefined;
                              bars: Array<{
                                __typename: 'HorizontalBarChartBar';
                                label: string | null | undefined;
                                barPercent: number;
                                barColor: string;
                                rightLabel: string | null | undefined;
                                leftLabel: string | null | undefined;
                              }>;
                            }
                          | {
                              __typename: 'InfoBlock';
                              label: string | null | undefined;
                              value: string | null | undefined;
                            }
                          | {
                              __typename: 'MerchantsListBlock';
                              merchants:
                                | Array<{
                                    __typename: 'ReviewMerchant';
                                    id: string;
                                    name: string;
                                    logoUrl: string | null | undefined;
                                    transactionCount: number;
                                  }>
                                | null
                                | undefined;
                            }
                          | {
                              __typename: 'NetWorthChartBlock';
                              snapshotsByAccountType: Array<{
                                __typename: 'SnapshotByType';
                                accountType: string;
                                month: string;
                                balance: number;
                              }>;
                            }
                          | {
                              __typename: 'PageTitleBlock';
                              header: string | null | undefined;
                              title: string | null | undefined;
                              subtitle: string | null | undefined;
                              centerSubtitle: boolean | null | undefined;
                            }
                          | {
                              __typename: 'PieChartBlock';
                              slices: Array<{
                                __typename: 'PieChartSlice';
                                label: string;
                                value: number;
                                color: string;
                              }>;
                            }
                          | { __typename: 'ShareMonarchBlock' }
                          | {
                              __typename: 'ShareableBlock';
                              title: string | null | undefined;
                              subtitle: string | null | undefined;
                              sharedTitle: string | null | undefined;
                              sharedSubtitle: string | null | undefined;
                              sharedFooter: string | null | undefined;
                              sharedContent: string | null | undefined;
                              shareButtonText: string | null | undefined;
                            }
                          | {
                              __typename: 'SparklineRowBlock';
                              label: string | null | undefined;
                              rightLabel: string | null | undefined;
                              labelColor: string | null | undefined;
                              lineColor: string | null | undefined;
                              values: Array<number>;
                              title: string | null | undefined;
                              largeStyle: boolean | null | undefined;
                              hideValueChange: boolean | null | undefined;
                            }
                          | {
                              __typename: 'StackedBarChartBlock';
                              groupColors: Array<string>;
                              data: Array<{
                                __typename: 'StackedBarChartDataPoint';
                                x: string;
                                total: number | null | undefined;
                                groups: Array<number>;
                              }>;
                            }
                          | { __typename: 'SuccessBlock' }
                          | {
                              __typename: 'TextBlock';
                              content: string;
                              fontSize: number | null | undefined;
                              fontWeight: string | null | undefined;
                              color: string | null | undefined;
                            }
                          | {
                              __typename: 'TransactionsListBlock';
                              categories: Array<string> | null | undefined;
                              startDate: string | null | undefined;
                              endDate: string | null | undefined;
                            }
                        >;
                      }
                    | null
                    | undefined;
                }>;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'ShareMonarchBlock';
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'ShareableBlock';
                title: string | null | undefined;
                subtitle: string | null | undefined;
                sharedTitle: string | null | undefined;
                sharedSubtitle: string | null | undefined;
                sharedFooter: string | null | undefined;
                sharedContent: string | null | undefined;
                shareButtonText: string | null | undefined;
                children: Array<
                  | {
                      __typename: 'AccountsListBlock';
                      accountType: string | null | undefined;
                      displayBalanceAsOf: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'DividerBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'GoalContributionsListBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'GroupBlock';
                      centered: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'HorizontalBarChartBlock';
                      topRightLabel: string | null | undefined;
                      smallBarStyle: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      bars: Array<{
                        __typename: 'HorizontalBarChartBar';
                        label: string | null | undefined;
                        barPercent: number;
                        barColor: string;
                        rightLabel: string | null | undefined;
                        leftLabel: string | null | undefined;
                      }>;
                    }
                  | {
                      __typename: 'InfoBlock';
                      label: string | null | undefined;
                      value: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'MerchantsListBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      merchants:
                        | Array<{
                            __typename: 'ReviewMerchant';
                            id: string;
                            name: string;
                            logoUrl: string | null | undefined;
                            transactionCount: number;
                          }>
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'NetWorthChartBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      snapshotsByAccountType: Array<{
                        __typename: 'SnapshotByType';
                        accountType: string;
                        month: string;
                        balance: number;
                      }>;
                    }
                  | {
                      __typename: 'PageTitleBlock';
                      header: string | null | undefined;
                      title: string | null | undefined;
                      subtitle: string | null | undefined;
                      centerSubtitle: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'PieChartBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      slices: Array<{
                        __typename: 'PieChartSlice';
                        label: string;
                        value: number;
                        color: string;
                      }>;
                    }
                  | {
                      __typename: 'ShareMonarchBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'ShareableBlock';
                      title: string | null | undefined;
                      subtitle: string | null | undefined;
                      sharedTitle: string | null | undefined;
                      sharedSubtitle: string | null | undefined;
                      sharedFooter: string | null | undefined;
                      sharedContent: string | null | undefined;
                      shareButtonText: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'SparklineRowBlock';
                      label: string | null | undefined;
                      rightLabel: string | null | undefined;
                      labelColor: string | null | undefined;
                      lineColor: string | null | undefined;
                      values: Array<number>;
                      title: string | null | undefined;
                      largeStyle: boolean | null | undefined;
                      hideValueChange: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'StackedBarChartBlock';
                      groupColors: Array<string>;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      data: Array<{
                        __typename: 'StackedBarChartDataPoint';
                        x: string;
                        total: number | null | undefined;
                        groups: Array<number>;
                      }>;
                    }
                  | {
                      __typename: 'SuccessBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'TextBlock';
                      content: string;
                      fontSize: number | null | undefined;
                      fontWeight: string | null | undefined;
                      color: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'TransactionsListBlock';
                      categories: Array<string> | null | undefined;
                      startDate: string | null | undefined;
                      endDate: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                >;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'SparklineRowBlock';
                label: string | null | undefined;
                rightLabel: string | null | undefined;
                labelColor: string | null | undefined;
                lineColor: string | null | undefined;
                values: Array<number>;
                title: string | null | undefined;
                largeStyle: boolean | null | undefined;
                hideValueChange: boolean | null | undefined;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'StackedBarChartBlock';
                groupColors: Array<string>;
                data: Array<{
                  __typename: 'StackedBarChartDataPoint';
                  x: string;
                  total: number | null | undefined;
                  groups: Array<number>;
                  links:
                    | Array<
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }>;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'SuccessBlock';
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'TextBlock';
                content: string;
                fontSize: number | null | undefined;
                fontWeight: string | null | undefined;
                color: string | null | undefined;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'TransactionsListBlock';
                categories: Array<string> | null | undefined;
                startDate: string | null | undefined;
                endDate: string | null | undefined;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
          >;
          header:
            | {
                __typename: 'AccountsListBlock';
                accountType: string | null | undefined;
                displayBalanceAsOf: string | null | undefined;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'DividerBlock';
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'GoalContributionsListBlock';
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'GroupBlock';
                centered: boolean | null | undefined;
                children: Array<
                  | {
                      __typename: 'AccountsListBlock';
                      accountType: string | null | undefined;
                      displayBalanceAsOf: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'DividerBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'GoalContributionsListBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'GroupBlock';
                      centered: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'HorizontalBarChartBlock';
                      topRightLabel: string | null | undefined;
                      smallBarStyle: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      bars: Array<{
                        __typename: 'HorizontalBarChartBar';
                        label: string | null | undefined;
                        barPercent: number;
                        barColor: string;
                        rightLabel: string | null | undefined;
                        leftLabel: string | null | undefined;
                      }>;
                    }
                  | {
                      __typename: 'InfoBlock';
                      label: string | null | undefined;
                      value: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'MerchantsListBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      merchants:
                        | Array<{
                            __typename: 'ReviewMerchant';
                            id: string;
                            name: string;
                            logoUrl: string | null | undefined;
                            transactionCount: number;
                          }>
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'NetWorthChartBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      snapshotsByAccountType: Array<{
                        __typename: 'SnapshotByType';
                        accountType: string;
                        month: string;
                        balance: number;
                      }>;
                    }
                  | {
                      __typename: 'PageTitleBlock';
                      header: string | null | undefined;
                      title: string | null | undefined;
                      subtitle: string | null | undefined;
                      centerSubtitle: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'PieChartBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      slices: Array<{
                        __typename: 'PieChartSlice';
                        label: string;
                        value: number;
                        color: string;
                      }>;
                    }
                  | {
                      __typename: 'ShareMonarchBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'ShareableBlock';
                      title: string | null | undefined;
                      subtitle: string | null | undefined;
                      sharedTitle: string | null | undefined;
                      sharedSubtitle: string | null | undefined;
                      sharedFooter: string | null | undefined;
                      sharedContent: string | null | undefined;
                      shareButtonText: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'SparklineRowBlock';
                      label: string | null | undefined;
                      rightLabel: string | null | undefined;
                      labelColor: string | null | undefined;
                      lineColor: string | null | undefined;
                      values: Array<number>;
                      title: string | null | undefined;
                      largeStyle: boolean | null | undefined;
                      hideValueChange: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'StackedBarChartBlock';
                      groupColors: Array<string>;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      data: Array<{
                        __typename: 'StackedBarChartDataPoint';
                        x: string;
                        total: number | null | undefined;
                        groups: Array<number>;
                      }>;
                    }
                  | {
                      __typename: 'SuccessBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'TextBlock';
                      content: string;
                      fontSize: number | null | undefined;
                      fontWeight: string | null | undefined;
                      color: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'TransactionsListBlock';
                      categories: Array<string> | null | undefined;
                      startDate: string | null | undefined;
                      endDate: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                >;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'HorizontalBarChartBlock';
                topRightLabel: string | null | undefined;
                smallBarStyle: boolean | null | undefined;
                bars: Array<{
                  __typename: 'HorizontalBarChartBar';
                  label: string | null | undefined;
                  barPercent: number;
                  barColor: string;
                  rightLabel: string | null | undefined;
                  leftLabel: string | null | undefined;
                  link:
                    | {
                        __typename: 'BlockLink';
                        title: string | null | undefined;
                        blocks: Array<
                          | {
                              __typename: 'AccountsListBlock';
                              accountType: string | null | undefined;
                              displayBalanceAsOf: string | null | undefined;
                            }
                          | { __typename: 'DividerBlock' }
                          | { __typename: 'GoalContributionsListBlock' }
                          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                          | {
                              __typename: 'HorizontalBarChartBlock';
                              topRightLabel: string | null | undefined;
                              smallBarStyle: boolean | null | undefined;
                              bars: Array<{
                                __typename: 'HorizontalBarChartBar';
                                label: string | null | undefined;
                                barPercent: number;
                                barColor: string;
                                rightLabel: string | null | undefined;
                                leftLabel: string | null | undefined;
                              }>;
                            }
                          | {
                              __typename: 'InfoBlock';
                              label: string | null | undefined;
                              value: string | null | undefined;
                            }
                          | {
                              __typename: 'MerchantsListBlock';
                              merchants:
                                | Array<{
                                    __typename: 'ReviewMerchant';
                                    id: string;
                                    name: string;
                                    logoUrl: string | null | undefined;
                                    transactionCount: number;
                                  }>
                                | null
                                | undefined;
                            }
                          | {
                              __typename: 'NetWorthChartBlock';
                              snapshotsByAccountType: Array<{
                                __typename: 'SnapshotByType';
                                accountType: string;
                                month: string;
                                balance: number;
                              }>;
                            }
                          | {
                              __typename: 'PageTitleBlock';
                              header: string | null | undefined;
                              title: string | null | undefined;
                              subtitle: string | null | undefined;
                              centerSubtitle: boolean | null | undefined;
                            }
                          | {
                              __typename: 'PieChartBlock';
                              slices: Array<{
                                __typename: 'PieChartSlice';
                                label: string;
                                value: number;
                                color: string;
                              }>;
                            }
                          | { __typename: 'ShareMonarchBlock' }
                          | {
                              __typename: 'ShareableBlock';
                              title: string | null | undefined;
                              subtitle: string | null | undefined;
                              sharedTitle: string | null | undefined;
                              sharedSubtitle: string | null | undefined;
                              sharedFooter: string | null | undefined;
                              sharedContent: string | null | undefined;
                              shareButtonText: string | null | undefined;
                            }
                          | {
                              __typename: 'SparklineRowBlock';
                              label: string | null | undefined;
                              rightLabel: string | null | undefined;
                              labelColor: string | null | undefined;
                              lineColor: string | null | undefined;
                              values: Array<number>;
                              title: string | null | undefined;
                              largeStyle: boolean | null | undefined;
                              hideValueChange: boolean | null | undefined;
                            }
                          | {
                              __typename: 'StackedBarChartBlock';
                              groupColors: Array<string>;
                              data: Array<{
                                __typename: 'StackedBarChartDataPoint';
                                x: string;
                                total: number | null | undefined;
                                groups: Array<number>;
                              }>;
                            }
                          | { __typename: 'SuccessBlock' }
                          | {
                              __typename: 'TextBlock';
                              content: string;
                              fontSize: number | null | undefined;
                              fontWeight: string | null | undefined;
                              color: string | null | undefined;
                            }
                          | {
                              __typename: 'TransactionsListBlock';
                              categories: Array<string> | null | undefined;
                              startDate: string | null | undefined;
                              endDate: string | null | undefined;
                            }
                        >;
                      }
                    | null
                    | undefined;
                }>;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'InfoBlock';
                label: string | null | undefined;
                value: string | null | undefined;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'MerchantsListBlock';
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
                merchants:
                  | Array<{
                      __typename: 'ReviewMerchant';
                      id: string;
                      name: string;
                      logoUrl: string | null | undefined;
                      transactionCount: number;
                    }>
                  | null
                  | undefined;
              }
            | {
                __typename: 'NetWorthChartBlock';
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
                snapshotsByAccountType: Array<{
                  __typename: 'SnapshotByType';
                  accountType: string;
                  month: string;
                  balance: number;
                }>;
              }
            | {
                __typename: 'PageTitleBlock';
                header: string | null | undefined;
                title: string | null | undefined;
                subtitle: string | null | undefined;
                centerSubtitle: boolean | null | undefined;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'PieChartBlock';
                slices: Array<{
                  __typename: 'PieChartSlice';
                  label: string;
                  value: number;
                  color: string;
                  link:
                    | {
                        __typename: 'BlockLink';
                        title: string | null | undefined;
                        blocks: Array<
                          | {
                              __typename: 'AccountsListBlock';
                              accountType: string | null | undefined;
                              displayBalanceAsOf: string | null | undefined;
                            }
                          | { __typename: 'DividerBlock' }
                          | { __typename: 'GoalContributionsListBlock' }
                          | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                          | {
                              __typename: 'HorizontalBarChartBlock';
                              topRightLabel: string | null | undefined;
                              smallBarStyle: boolean | null | undefined;
                              bars: Array<{
                                __typename: 'HorizontalBarChartBar';
                                label: string | null | undefined;
                                barPercent: number;
                                barColor: string;
                                rightLabel: string | null | undefined;
                                leftLabel: string | null | undefined;
                              }>;
                            }
                          | {
                              __typename: 'InfoBlock';
                              label: string | null | undefined;
                              value: string | null | undefined;
                            }
                          | {
                              __typename: 'MerchantsListBlock';
                              merchants:
                                | Array<{
                                    __typename: 'ReviewMerchant';
                                    id: string;
                                    name: string;
                                    logoUrl: string | null | undefined;
                                    transactionCount: number;
                                  }>
                                | null
                                | undefined;
                            }
                          | {
                              __typename: 'NetWorthChartBlock';
                              snapshotsByAccountType: Array<{
                                __typename: 'SnapshotByType';
                                accountType: string;
                                month: string;
                                balance: number;
                              }>;
                            }
                          | {
                              __typename: 'PageTitleBlock';
                              header: string | null | undefined;
                              title: string | null | undefined;
                              subtitle: string | null | undefined;
                              centerSubtitle: boolean | null | undefined;
                            }
                          | {
                              __typename: 'PieChartBlock';
                              slices: Array<{
                                __typename: 'PieChartSlice';
                                label: string;
                                value: number;
                                color: string;
                              }>;
                            }
                          | { __typename: 'ShareMonarchBlock' }
                          | {
                              __typename: 'ShareableBlock';
                              title: string | null | undefined;
                              subtitle: string | null | undefined;
                              sharedTitle: string | null | undefined;
                              sharedSubtitle: string | null | undefined;
                              sharedFooter: string | null | undefined;
                              sharedContent: string | null | undefined;
                              shareButtonText: string | null | undefined;
                            }
                          | {
                              __typename: 'SparklineRowBlock';
                              label: string | null | undefined;
                              rightLabel: string | null | undefined;
                              labelColor: string | null | undefined;
                              lineColor: string | null | undefined;
                              values: Array<number>;
                              title: string | null | undefined;
                              largeStyle: boolean | null | undefined;
                              hideValueChange: boolean | null | undefined;
                            }
                          | {
                              __typename: 'StackedBarChartBlock';
                              groupColors: Array<string>;
                              data: Array<{
                                __typename: 'StackedBarChartDataPoint';
                                x: string;
                                total: number | null | undefined;
                                groups: Array<number>;
                              }>;
                            }
                          | { __typename: 'SuccessBlock' }
                          | {
                              __typename: 'TextBlock';
                              content: string;
                              fontSize: number | null | undefined;
                              fontWeight: string | null | undefined;
                              color: string | null | undefined;
                            }
                          | {
                              __typename: 'TransactionsListBlock';
                              categories: Array<string> | null | undefined;
                              startDate: string | null | undefined;
                              endDate: string | null | undefined;
                            }
                        >;
                      }
                    | null
                    | undefined;
                }>;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'ShareMonarchBlock';
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'ShareableBlock';
                title: string | null | undefined;
                subtitle: string | null | undefined;
                sharedTitle: string | null | undefined;
                sharedSubtitle: string | null | undefined;
                sharedFooter: string | null | undefined;
                sharedContent: string | null | undefined;
                shareButtonText: string | null | undefined;
                children: Array<
                  | {
                      __typename: 'AccountsListBlock';
                      accountType: string | null | undefined;
                      displayBalanceAsOf: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'DividerBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'GoalContributionsListBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'GroupBlock';
                      centered: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'HorizontalBarChartBlock';
                      topRightLabel: string | null | undefined;
                      smallBarStyle: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      bars: Array<{
                        __typename: 'HorizontalBarChartBar';
                        label: string | null | undefined;
                        barPercent: number;
                        barColor: string;
                        rightLabel: string | null | undefined;
                        leftLabel: string | null | undefined;
                      }>;
                    }
                  | {
                      __typename: 'InfoBlock';
                      label: string | null | undefined;
                      value: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'MerchantsListBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      merchants:
                        | Array<{
                            __typename: 'ReviewMerchant';
                            id: string;
                            name: string;
                            logoUrl: string | null | undefined;
                            transactionCount: number;
                          }>
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'NetWorthChartBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      snapshotsByAccountType: Array<{
                        __typename: 'SnapshotByType';
                        accountType: string;
                        month: string;
                        balance: number;
                      }>;
                    }
                  | {
                      __typename: 'PageTitleBlock';
                      header: string | null | undefined;
                      title: string | null | undefined;
                      subtitle: string | null | undefined;
                      centerSubtitle: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'PieChartBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      slices: Array<{
                        __typename: 'PieChartSlice';
                        label: string;
                        value: number;
                        color: string;
                      }>;
                    }
                  | {
                      __typename: 'ShareMonarchBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'ShareableBlock';
                      title: string | null | undefined;
                      subtitle: string | null | undefined;
                      sharedTitle: string | null | undefined;
                      sharedSubtitle: string | null | undefined;
                      sharedFooter: string | null | undefined;
                      sharedContent: string | null | undefined;
                      shareButtonText: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'SparklineRowBlock';
                      label: string | null | undefined;
                      rightLabel: string | null | undefined;
                      labelColor: string | null | undefined;
                      lineColor: string | null | undefined;
                      values: Array<number>;
                      title: string | null | undefined;
                      largeStyle: boolean | null | undefined;
                      hideValueChange: boolean | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'StackedBarChartBlock';
                      groupColors: Array<string>;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                      data: Array<{
                        __typename: 'StackedBarChartDataPoint';
                        x: string;
                        total: number | null | undefined;
                        groups: Array<number>;
                      }>;
                    }
                  | {
                      __typename: 'SuccessBlock';
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'TextBlock';
                      content: string;
                      fontSize: number | null | undefined;
                      fontWeight: string | null | undefined;
                      color: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename: 'TransactionsListBlock';
                      categories: Array<string> | null | undefined;
                      startDate: string | null | undefined;
                      endDate: string | null | undefined;
                      link:
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined;
                    }
                >;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'SparklineRowBlock';
                label: string | null | undefined;
                rightLabel: string | null | undefined;
                labelColor: string | null | undefined;
                lineColor: string | null | undefined;
                values: Array<number>;
                title: string | null | undefined;
                largeStyle: boolean | null | undefined;
                hideValueChange: boolean | null | undefined;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'StackedBarChartBlock';
                groupColors: Array<string>;
                data: Array<{
                  __typename: 'StackedBarChartDataPoint';
                  x: string;
                  total: number | null | undefined;
                  groups: Array<number>;
                  links:
                    | Array<
                        | {
                            __typename: 'BlockLink';
                            title: string | null | undefined;
                            blocks: Array<
                              | {
                                  __typename: 'AccountsListBlock';
                                  accountType: string | null | undefined;
                                  displayBalanceAsOf: string | null | undefined;
                                }
                              | { __typename: 'DividerBlock' }
                              | { __typename: 'GoalContributionsListBlock' }
                              | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                              | {
                                  __typename: 'HorizontalBarChartBlock';
                                  topRightLabel: string | null | undefined;
                                  smallBarStyle: boolean | null | undefined;
                                  bars: Array<{
                                    __typename: 'HorizontalBarChartBar';
                                    label: string | null | undefined;
                                    barPercent: number;
                                    barColor: string;
                                    rightLabel: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                  }>;
                                }
                              | {
                                  __typename: 'InfoBlock';
                                  label: string | null | undefined;
                                  value: string | null | undefined;
                                }
                              | {
                                  __typename: 'MerchantsListBlock';
                                  merchants:
                                    | Array<{
                                        __typename: 'ReviewMerchant';
                                        id: string;
                                        name: string;
                                        logoUrl: string | null | undefined;
                                        transactionCount: number;
                                      }>
                                    | null
                                    | undefined;
                                }
                              | {
                                  __typename: 'NetWorthChartBlock';
                                  snapshotsByAccountType: Array<{
                                    __typename: 'SnapshotByType';
                                    accountType: string;
                                    month: string;
                                    balance: number;
                                  }>;
                                }
                              | {
                                  __typename: 'PageTitleBlock';
                                  header: string | null | undefined;
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  centerSubtitle: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'PieChartBlock';
                                  slices: Array<{
                                    __typename: 'PieChartSlice';
                                    label: string;
                                    value: number;
                                    color: string;
                                  }>;
                                }
                              | { __typename: 'ShareMonarchBlock' }
                              | {
                                  __typename: 'ShareableBlock';
                                  title: string | null | undefined;
                                  subtitle: string | null | undefined;
                                  sharedTitle: string | null | undefined;
                                  sharedSubtitle: string | null | undefined;
                                  sharedFooter: string | null | undefined;
                                  sharedContent: string | null | undefined;
                                  shareButtonText: string | null | undefined;
                                }
                              | {
                                  __typename: 'SparklineRowBlock';
                                  label: string | null | undefined;
                                  rightLabel: string | null | undefined;
                                  labelColor: string | null | undefined;
                                  lineColor: string | null | undefined;
                                  values: Array<number>;
                                  title: string | null | undefined;
                                  largeStyle: boolean | null | undefined;
                                  hideValueChange: boolean | null | undefined;
                                }
                              | {
                                  __typename: 'StackedBarChartBlock';
                                  groupColors: Array<string>;
                                  data: Array<{
                                    __typename: 'StackedBarChartDataPoint';
                                    x: string;
                                    total: number | null | undefined;
                                    groups: Array<number>;
                                  }>;
                                }
                              | { __typename: 'SuccessBlock' }
                              | {
                                  __typename: 'TextBlock';
                                  content: string;
                                  fontSize: number | null | undefined;
                                  fontWeight: string | null | undefined;
                                  color: string | null | undefined;
                                }
                              | {
                                  __typename: 'TransactionsListBlock';
                                  categories: Array<string> | null | undefined;
                                  startDate: string | null | undefined;
                                  endDate: string | null | undefined;
                                }
                            >;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }>;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'SuccessBlock';
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'TextBlock';
                content: string;
                fontSize: number | null | undefined;
                fontWeight: string | null | undefined;
                color: string | null | undefined;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename: 'TransactionsListBlock';
                categories: Array<string> | null | undefined;
                startDate: string | null | undefined;
                endDate: string | null | undefined;
                link:
                  | {
                      __typename: 'BlockLink';
                      title: string | null | undefined;
                      blocks: Array<
                        | {
                            __typename: 'AccountsListBlock';
                            accountType: string | null | undefined;
                            displayBalanceAsOf: string | null | undefined;
                          }
                        | { __typename: 'DividerBlock' }
                        | { __typename: 'GoalContributionsListBlock' }
                        | { __typename: 'GroupBlock'; centered: boolean | null | undefined }
                        | {
                            __typename: 'HorizontalBarChartBlock';
                            topRightLabel: string | null | undefined;
                            smallBarStyle: boolean | null | undefined;
                            bars: Array<{
                              __typename: 'HorizontalBarChartBar';
                              label: string | null | undefined;
                              barPercent: number;
                              barColor: string;
                              rightLabel: string | null | undefined;
                              leftLabel: string | null | undefined;
                            }>;
                          }
                        | {
                            __typename: 'InfoBlock';
                            label: string | null | undefined;
                            value: string | null | undefined;
                          }
                        | {
                            __typename: 'MerchantsListBlock';
                            merchants:
                              | Array<{
                                  __typename: 'ReviewMerchant';
                                  id: string;
                                  name: string;
                                  logoUrl: string | null | undefined;
                                  transactionCount: number;
                                }>
                              | null
                              | undefined;
                          }
                        | {
                            __typename: 'NetWorthChartBlock';
                            snapshotsByAccountType: Array<{
                              __typename: 'SnapshotByType';
                              accountType: string;
                              month: string;
                              balance: number;
                            }>;
                          }
                        | {
                            __typename: 'PageTitleBlock';
                            header: string | null | undefined;
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            centerSubtitle: boolean | null | undefined;
                          }
                        | {
                            __typename: 'PieChartBlock';
                            slices: Array<{
                              __typename: 'PieChartSlice';
                              label: string;
                              value: number;
                              color: string;
                            }>;
                          }
                        | { __typename: 'ShareMonarchBlock' }
                        | {
                            __typename: 'ShareableBlock';
                            title: string | null | undefined;
                            subtitle: string | null | undefined;
                            sharedTitle: string | null | undefined;
                            sharedSubtitle: string | null | undefined;
                            sharedFooter: string | null | undefined;
                            sharedContent: string | null | undefined;
                            shareButtonText: string | null | undefined;
                          }
                        | {
                            __typename: 'SparklineRowBlock';
                            label: string | null | undefined;
                            rightLabel: string | null | undefined;
                            labelColor: string | null | undefined;
                            lineColor: string | null | undefined;
                            values: Array<number>;
                            title: string | null | undefined;
                            largeStyle: boolean | null | undefined;
                            hideValueChange: boolean | null | undefined;
                          }
                        | {
                            __typename: 'StackedBarChartBlock';
                            groupColors: Array<string>;
                            data: Array<{
                              __typename: 'StackedBarChartDataPoint';
                              x: string;
                              total: number | null | undefined;
                              groups: Array<number>;
                            }>;
                          }
                        | { __typename: 'SuccessBlock' }
                        | {
                            __typename: 'TextBlock';
                            content: string;
                            fontSize: number | null | undefined;
                            fontWeight: string | null | undefined;
                            color: string | null | undefined;
                          }
                        | {
                            __typename: 'TransactionsListBlock';
                            categories: Array<string> | null | undefined;
                            startDate: string | null | undefined;
                            endDate: string | null | undefined;
                          }
                      >;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
  userProfile:
    | { __typename: 'UserProfile'; id: string; hasSeenWeeklyReviewTour: boolean }
    | null
    | undefined;
};

export type Mobile_GetAddTransactionScreenQueryVariables = Exact<{
  accountId: Scalars['UUID']['input'];
}>;

export type Mobile_GetAddTransactionScreenQuery = {
  __typename: 'Query';
  account:
    | {
        __typename: 'Account';
        id: string;
        displayName: string;
        displayBalance: number | null | undefined;
        isManual: boolean;
        isLiability: boolean;
        dataProvider: string | null | undefined;
        type: { __typename: 'AccountType'; name: string };
        subtype: { __typename: 'AccountSubtype'; name: string };
        institution:
          | {
              __typename: 'Institution';
              id: string;
              logo: string | null | undefined;
              primaryColor: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_MerchantDetailContainerQueryVariables = Exact<{
  merchantId: Scalars['ID']['input'];
}>;

export type Mobile_MerchantDetailContainerQuery = {
  __typename: 'Query';
  merchant: { __typename: 'Merchant'; id: string; name: string } | null | undefined;
};

export type Mobile_MerchantChartQueryVariables = Exact<{
  merchants: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
  startDate: InputMaybe<Scalars['Date']['input']>;
  endDate: InputMaybe<Scalars['Date']['input']>;
}>;

export type Mobile_MerchantChartQuery = {
  __typename: 'Query';
  summary: Array<{
    __typename: 'AggregateData';
    summary: {
      __typename: 'TransactionsSummary';
      count: number;
      sum: number;
      avg: number | null | undefined;
    };
  }>;
  byYear: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; year: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      count: number;
      sum: number;
      avg: number | null | undefined;
    };
  }>;
  byMonth: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; month: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      count: number;
      sum: number;
      avg: number | null | undefined;
    };
  }>;
  byQuarter: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; quarter: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      count: number;
      sum: number;
      avg: number | null | undefined;
    };
  }>;
};

export type Mobile_GetSelectCategoryScreenQueryVariables = Exact<{
  transactionId: Scalars['UUID']['input'];
}>;

export type Mobile_GetSelectCategoryScreenQuery = {
  __typename: 'Query';
  getTransaction:
    | {
        __typename: 'Transaction';
        id: string;
        dataProviderDescription: string | null | undefined;
        merchant: { __typename: 'Merchant'; id: string; name: string };
        category: { __typename: 'Category'; id: string; name: string };
      }
    | null
    | undefined;
};

export type Mobile_CategoryRecommendationForTransactionQueryVariables = Exact<{
  transactionId: Scalars['ID']['input'];
}>;

export type Mobile_CategoryRecommendationForTransactionQuery = {
  __typename: 'Query';
  recommendedCategories:
    | Array<{
        __typename: 'RecommendedCategory';
        occurrences: number;
        category: { __typename: 'Category'; id: string; name: string; order: number; icon: string };
      }>
    | null
    | undefined;
};

export type Mobile_GetCategoryGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_GetCategoryGroupsQuery = {
  __typename: 'Query';
  categoryGroups: Array<{
    __typename: 'CategoryGroup';
    id: string;
    name: string;
    order: number;
    type: CategoryGroupType;
    categories: Array<{
      __typename: 'Category';
      id: string;
      name: string;
      order: number;
      icon: string;
    }>;
  }>;
};

export type Mobile_GetSelectMerchantScreenTransactionDetailsQueryVariables = Exact<{
  transactionId: Scalars['UUID']['input'];
}>;

export type Mobile_GetSelectMerchantScreenTransactionDetailsQuery = {
  __typename: 'Query';
  getTransaction:
    | {
        __typename: 'Transaction';
        id: string;
        dataProviderDescription: string | null | undefined;
        merchant: {
          __typename: 'Merchant';
          id: string;
          name: string;
          logoUrl: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type Mobile_GetSelectMerchantScreenHouseholdMerchantsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: InputMaybe<MerchantOrdering>;
  search: InputMaybe<Scalars['String']['input']>;
}>;

export type Mobile_GetSelectMerchantScreenHouseholdMerchantsQuery = {
  __typename: 'Query';
  merchants: Array<{
    __typename: 'Merchant';
    id: string;
    name: string;
    logoUrl: string | null | undefined;
    transactionCount: number;
  }>;
};

export type Mobile_GetSelectMerchantScreenRecommendedMerchantsQueryVariables = Exact<{
  transactionId: Scalars['ID']['input'];
}>;

export type Mobile_GetSelectMerchantScreenRecommendedMerchantsQuery = {
  __typename: 'Query';
  recommendedMerchants:
    | Array<{ __typename: 'RecommendedMerchant'; name: string; source: string }>
    | null
    | undefined;
};

export type Mobile_GetAttachmentDetailsQueryVariables = Exact<{
  attachmentId: Scalars['UUID']['input'];
}>;

export type Mobile_GetAttachmentDetailsQuery = {
  __typename: 'Query';
  transactionAttachment:
    | { __typename: 'TransactionAttachment'; id: string; originalAssetUrl: string }
    | null
    | undefined;
};

export type Mobile_DeleteAttachmentMutationVariables = Exact<{
  attachmentId: Scalars['UUID']['input'];
}>;

export type Mobile_DeleteAttachmentMutation = {
  __typename: 'Mutation';
  deleteTransactionAttachment:
    | { __typename: 'DeleteTransactionAttachmentMutation'; deleted: boolean | null | undefined }
    | null
    | undefined;
};

export type Mobile_GetTransactionDetailScreenQueryVariables = Exact<{
  transactionId: Scalars['UUID']['input'];
  redirectPosted: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Mobile_GetTransactionDetailScreenQuery = {
  __typename: 'Query';
  getTransaction:
    | {
        __typename: 'Transaction';
        id: string;
        amount: number;
        date: string;
        isRecurring: boolean;
        originalDate: string;
        hideFromReports: boolean;
        needsReview: boolean;
        reviewedAt: string | null | undefined;
        plaidName: string | null | undefined;
        notes: string | null | undefined;
        isSplitTransaction: boolean;
        isManual: boolean;
        hasSplitTransactions: boolean;
        pending: boolean;
        needsReviewByUser: { __typename: 'User'; id: string; name: string } | null | undefined;
        reviewedByUser: { __typename: 'User'; id: string; name: string } | null | undefined;
        attachments: Array<{
          __typename: 'TransactionAttachment';
          id: string;
          publicId: string;
          extension: string;
          filename: string;
          originalAssetUrl: string;
        }>;
        merchant: {
          __typename: 'Merchant';
          id: string;
          name: string;
          logoUrl: string | null | undefined;
          transactionCount: number;
          recurringTransactionStream:
            | { __typename: 'RecurringTransactionStream'; id: string }
            | null
            | undefined;
        };
        category: { __typename: 'Category'; id: string; name: string; icon: string };
        account: {
          __typename: 'Account';
          id: string;
          displayName: string;
          dataProvider: string | null | undefined;
          goalAllocations: Array<{
            __typename: 'GoalAccountAllocation';
            id: string;
            goal: {
              __typename: 'GoalV2';
              id: string;
              name: string;
              imageStorageProvider: string | null | undefined;
              imageStorageProviderId: string | null | undefined;
              priority: number;
              archivedAt: string | null | undefined;
            };
          }>;
          type: { __typename: 'AccountType'; name: string };
          subtype: { __typename: 'AccountSubtype'; name: string };
          institution:
            | {
                __typename: 'Institution';
                id: string;
                logo: string | null | undefined;
                primaryColor: string;
              }
            | null
            | undefined;
        };
        tags: Array<{
          __typename: 'TransactionTag';
          id: string;
          name: string;
          color: string;
          order: number;
        }>;
        splitTransactions: Array<{
          __typename: 'Transaction';
          id: string;
          amount: number;
          notes: string | null | undefined;
          hideFromReports: boolean;
          merchant: { __typename: 'Merchant'; id: string; name: string };
          category: { __typename: 'Category'; id: string; icon: string; name: string };
          tags: Array<{
            __typename: 'TransactionTag';
            id: string;
            name: string;
            color: string;
            order: number;
          }>;
        }>;
        originalTransaction:
          | {
              __typename: 'Transaction';
              id: string;
              date: string;
              amount: number;
              merchant: { __typename: 'Merchant'; id: string; name: string };
            }
          | null
          | undefined;
        goal:
          | {
              __typename: 'GoalV2';
              id: string;
              name: string;
              imageStorageProvider: string | null | undefined;
              imageStorageProviderId: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_UpdateTransactionDetailScreenMutationVariables = Exact<{
  input: UpdateTransactionMutationInput;
}>;

export type Mobile_UpdateTransactionDetailScreenMutation = {
  __typename: 'Mutation';
  updateTransaction:
    | {
        __typename: 'UpdateTransactionMutation';
        transaction:
          | {
              __typename: 'Transaction';
              id: string;
              amount: number;
              pending: boolean;
              date: string;
              hideFromReports: boolean;
              needsReview: boolean;
              reviewedAt: string | null | undefined;
              notes: string | null | undefined;
              category: { __typename: 'Category'; id: string; name: string };
              merchant: {
                __typename: 'Merchant';
                id: string;
                name: string;
                transactionCount: number;
              };
              goal:
                | {
                    __typename: 'GoalV2';
                    id: string;
                    name: string;
                    imageStorageProvider: string | null | undefined;
                    imageStorageProviderId: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_GetAccountQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Mobile_GetAccountQuery = {
  __typename: 'Query';
  account:
    | {
        __typename: 'Account';
        id: string;
        displayName: string;
        dataProvider: string | null | undefined;
        type: { __typename: 'AccountType'; name: string };
        subtype: { __typename: 'AccountSubtype'; name: string };
        institution:
          | {
              __typename: 'Institution';
              id: string;
              logo: string | null | undefined;
              primaryColor: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_CreateAppleCardAccountMutationVariables = Exact<{
  input: CreateManualAccountMutationInput;
}>;

export type Mobile_CreateAppleCardAccountMutation = {
  __typename: 'Mutation';
  createManualAccount:
    | {
        __typename: 'CreateManualAccountMutation';
        account: { __typename: 'Account'; id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type Mobile_DirectLinkInstitutionModalQueryVariables = Exact<{
  plaidId: InputMaybe<Scalars['String']['input']>;
  finicityId: InputMaybe<Scalars['String']['input']>;
  mxId: InputMaybe<Scalars['String']['input']>;
}>;

export type Mobile_DirectLinkInstitutionModalQuery = {
  __typename: 'Query';
  institution:
    | {
        __typename: 'Institution';
        id: string;
        name: string;
        logo: string | null | undefined;
        url: string;
        status: InstitutionStatus | null | undefined;
        preferredDataProvider: DataProviderLegacy;
        firstBackupDataProvider: DataProviderLegacy | null | undefined;
        plaidInstitutionId: string | null | undefined;
        finicityInstitutionId: string | null | undefined;
        mxInstitutionId: string | null | undefined;
        hasIssuesReported: boolean;
        hasIssuesReportedMessage: string;
        linkFlowWarnBeforeConnecting: boolean;
        linkFlowWarningMessage: string | null | undefined;
        linkFlowWarningTitle: string | null | undefined;
        largeLogo: { __typename: 'FileType'; url: string | null | undefined } | null | undefined;
      }
    | null
    | undefined;
};

export type SearchInstitutionsQueryVariables = Exact<{
  search: InputMaybe<Scalars['String']['input']>;
  dataProviders:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type SearchInstitutionsQuery = {
  __typename: 'Query';
  allInstitutions: Array<{
    __typename: 'Institution';
    id: string;
    name: string;
    logo: string | null | undefined;
    url: string;
    status: InstitutionStatus | null | undefined;
    preferredDataProvider: DataProviderLegacy;
    firstBackupDataProvider: DataProviderLegacy | null | undefined;
    plaidInstitutionId: string | null | undefined;
    finicityInstitutionId: string | null | undefined;
    mxInstitutionId: string | null | undefined;
    hasIssuesReported: boolean;
    hasIssuesReportedMessage: string;
    linkFlowWarnBeforeConnecting: boolean;
    linkFlowWarningMessage: string | null | undefined;
    linkFlowWarningTitle: string | null | undefined;
    largeLogo: { __typename: 'FileType'; url: string | null | undefined } | null | undefined;
  }>;
};

export type Mobile_GetTopInstitutionGroupsQueryVariables = Exact<{
  dataProviders:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type Mobile_GetTopInstitutionGroupsQuery = {
  __typename: 'Query';
  credentials: Array<{ __typename: 'Credential'; id: string }>;
  topInstitutionGroups: Array<{
    __typename: 'TopInstitutionGroup';
    type: TopInstitutionGroupType;
    title: string;
    shortTitle: string;
    accountsConnectedCount: number;
    institutions: Array<{
      __typename: 'Institution';
      id: string;
      name: string;
      logo: string | null | undefined;
      url: string;
      status: InstitutionStatus | null | undefined;
      preferredDataProvider: DataProviderLegacy;
      firstBackupDataProvider: DataProviderLegacy | null | undefined;
      plaidInstitutionId: string | null | undefined;
      finicityInstitutionId: string | null | undefined;
      mxInstitutionId: string | null | undefined;
      hasIssuesReported: boolean;
      hasIssuesReportedMessage: string;
      linkFlowWarnBeforeConnecting: boolean;
      linkFlowWarningMessage: string | null | undefined;
      linkFlowWarningTitle: string | null | undefined;
      largeLogo: { __typename: 'FileType'; url: string | null | undefined } | null | undefined;
    }>;
    manualAccountShortcuts:
      | Array<{
          __typename: 'ManualAccountShortcut';
          title: string;
          accountType: { __typename: 'AccountType'; name: string; display: string };
          accountSubtype: { __typename: 'AccountSubtype'; name: string } | null | undefined;
        }>
      | null
      | undefined;
  }>;
};

export type LinkSearchInstitutionsQueryVariables = Exact<{
  search: InputMaybe<Scalars['String']['input']>;
  dataProviders:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type LinkSearchInstitutionsQuery = {
  __typename: 'Query';
  allInstitutions: Array<{
    __typename: 'Institution';
    id: string;
    name: string;
    logo: string | null | undefined;
    url: string;
    status: InstitutionStatus | null | undefined;
    preferredDataProvider: DataProviderLegacy;
    firstBackupDataProvider: DataProviderLegacy | null | undefined;
    plaidInstitutionId: string | null | undefined;
    finicityInstitutionId: string | null | undefined;
    mxInstitutionId: string | null | undefined;
    hasIssuesReported: boolean;
    hasIssuesReportedMessage: string;
    linkFlowWarnBeforeConnecting: boolean;
    linkFlowWarningMessage: string | null | undefined;
    linkFlowWarningTitle: string | null | undefined;
    largeLogo: { __typename: 'FileType'; url: string | null | undefined } | null | undefined;
  }>;
};

export type Mobile_CreateCoinbaseAccountMutationVariables = Exact<{
  input: CreateCoinbaseAccountInput;
}>;

export type Mobile_CreateCoinbaseAccountMutation = {
  __typename: 'Mutation';
  createCoinbaseAccount:
    | {
        __typename: 'CreateCoinbaseAccountMutation';
        account: { __typename: 'Account'; id: string; displayName: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_AddCategoryGroupMutationVariables = Exact<{
  input: CreateCategoryGroupInput;
}>;

export type Mobile_AddCategoryGroupMutation = {
  __typename: 'Mutation';
  createCategoryGroup:
    | {
        __typename: 'CreateCategoryGroupMutation';
        categoryGroup:
          | { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_UpdateCategoryGroupMutationVariables = Exact<{
  input: UpdateCategoryGroupInput;
}>;

export type Mobile_UpdateCategoryGroupMutation = {
  __typename: 'Mutation';
  updateCategoryGroup:
    | {
        __typename: 'UpdateCategoryGroupMutation';
        categoryGroup:
          | { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_CreateCategoryMutationVariables = Exact<{
  input: CreateCategoryInput;
}>;

export type Mobile_CreateCategoryMutation = {
  __typename: 'Mutation';
  createCategory:
    | {
        __typename: 'CreateCategoryPayload';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        category:
          | {
              __typename: 'Category';
              id: string;
              order: number;
              name: string;
              icon: string;
              systemCategory: string | null | undefined;
              systemCategoryDisplayName: string | null | undefined;
              budgetVariability: BudgetVariability | null | undefined;
              excludeFromBudget: boolean | null | undefined;
              isSystemCategory: boolean;
              isDisabled: boolean;
              group: {
                __typename: 'CategoryGroup';
                id: string;
                type: CategoryGroupType;
                groupLevelBudgetingEnabled: boolean | null | undefined;
              };
              rolloverPeriod:
                | {
                    __typename: 'BudgetRolloverPeriod';
                    id: string;
                    startMonth: string;
                    startingBalance: number | null | undefined;
                    type: string;
                    frequency: string | null | undefined;
                    targetAmount: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_UpdateCategoryMutationVariables = Exact<{
  input: UpdateCategoryInput;
}>;

export type Mobile_UpdateCategoryMutation = {
  __typename: 'Mutation';
  updateCategory:
    | {
        __typename: 'UpdateCategoryPayload';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        category:
          | {
              __typename: 'Category';
              id: string;
              order: number;
              name: string;
              icon: string;
              systemCategory: string | null | undefined;
              systemCategoryDisplayName: string | null | undefined;
              budgetVariability: BudgetVariability | null | undefined;
              excludeFromBudget: boolean | null | undefined;
              isSystemCategory: boolean;
              isDisabled: boolean;
              group: {
                __typename: 'CategoryGroup';
                id: string;
                type: CategoryGroupType;
                groupLevelBudgetingEnabled: boolean | null | undefined;
              };
              rolloverPeriod:
                | {
                    __typename: 'BudgetRolloverPeriod';
                    id: string;
                    startMonth: string;
                    startingBalance: number | null | undefined;
                    type: string;
                    frequency: string | null | undefined;
                    targetAmount: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetBillingSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBillingSettingsQuery = {
  __typename: 'Query';
  creditBalance: number | null | undefined;
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    currentPeriodEndsAt: string | null | undefined;
    paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
    nextPaymentAmount: number | null | undefined;
    hasPremiumEntitlement: boolean;
    willCancelAtPeriodEnd: boolean;
    billingPeriod: PaymentPeriod | null | undefined;
    paymentMethod:
      | { __typename: 'PaymentMethod'; lastFour: string; brand: string }
      | null
      | undefined;
  };
};

export type Mobile_RestoreCategoryMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Mobile_RestoreCategoryMutation = {
  __typename: 'Mutation';
  restoreCategory:
    | {
        __typename: 'RestoreCategoryMutation';
        category:
          | { __typename: 'Category'; id: string; isDisabled: boolean; order: number }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_ResetHouseholdRecurringMerchantSearchMutationVariables = Exact<{
  deleteStreams: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Mobile_ResetHouseholdRecurringMerchantSearchMutation = {
  __typename: 'Mutation';
  resetRecurringMerchantSearch:
    | { __typename: 'ResetHouseholdMerchantRecurringSearchMutation'; success: boolean }
    | null
    | undefined;
};

export type Mobile_OldestDeletableSyncedSnapshotDateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Mobile_OldestDeletableSyncedSnapshotDateQuery = {
  __typename: 'Query';
  oldestDeletableSyncedSnapshotDate: string | null | undefined;
};

export type Mobile_PreviewDeleteSyncedSnapshotsQueryVariables = Exact<{
  beforeDate: Scalars['Date']['input'];
}>;

export type Mobile_PreviewDeleteSyncedSnapshotsQuery = {
  __typename: 'Query';
  previewDeleteSyncedSnapshots: number | null | undefined;
};

export type MyHouseholdUsersFieldsFragment = {
  __typename: 'User';
  id: string;
  name: string;
  householdRole: UserHouseholdRole;
  hasMfaOn: boolean;
  profilePictureUrl: string | null | undefined;
  profilePicture:
    | { __typename: 'UserProfilePicture'; id: string; thumbnailUrl: string | null | undefined }
    | null
    | undefined;
};

export type HouseholdAccessGrantsFieldsFragment = {
  __typename: 'HouseholdAccessGrant';
  id: string;
  toEmail: string;
  toName: string | null | undefined;
  createdAt: string;
  expiresAt: string;
};

export type Mobile_GetHouseholdMembersQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_GetHouseholdMembersQuery = {
  __typename: 'Query';
  myHousehold: {
    __typename: 'Household';
    id: string;
    users: Array<{
      __typename: 'User';
      id: string;
      name: string;
      householdRole: UserHouseholdRole;
      hasMfaOn: boolean;
      profilePictureUrl: string | null | undefined;
      profilePicture:
        | { __typename: 'UserProfilePicture'; id: string; thumbnailUrl: string | null | undefined }
        | null
        | undefined;
    }>;
  };
  householdInvites: Array<{
    __typename: 'HouseholdInvite';
    id: string;
    invitedEmail: string;
    createdAt: string;
    isRevoked: boolean;
    usedAt: string | null | undefined;
  }>;
  householdAccessGrants:
    | Array<{
        __typename: 'HouseholdAccessGrant';
        id: string;
        toEmail: string;
        toName: string | null | undefined;
        createdAt: string;
        expiresAt: string;
      }>
    | null
    | undefined;
};

export type Mobile_RevokeInviteToHouseholdMutationVariables = Exact<{
  input: RevokeInviteToHouseholdMutationInput;
}>;

export type Mobile_RevokeInviteToHouseholdMutation = {
  __typename: 'Mutation';
  revokeInviteToHousehold:
    | {
        __typename: 'RevokeInviteToHouseholdMutation';
        invite:
          | { __typename: 'HouseholdInvite'; id: string; isRevoked: boolean }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_DeleteUserFromHouseholdMutationVariables = Exact<{
  input: DeleteUserFromHouseholdMutationInput;
}>;

export type Mobile_DeleteUserFromHouseholdMutation = {
  __typename: 'Mutation';
  deleteUserFromHousehold:
    | {
        __typename: 'DeleteUserFromHouseholdMutation';
        deleted: boolean;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_RevokeHouseholdAccessGrantMutationVariables = Exact<{
  input: RevokeHouseholdAccessGrantInput;
}>;

export type Mobile_RevokeHouseholdAccessGrantMutation = {
  __typename: 'Mutation';
  revokeHouseholdAccessGrant:
    | { __typename: 'RevokeHouseholdAccessGrant'; revoked: boolean }
    | null
    | undefined;
};

export type Mobile_GetInstitutionSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type Mobile_GetInstitutionSettingsQuery = {
  __typename: 'Query';
  credentials: Array<{
    __typename: 'Credential';
    id: string;
    updateRequired: boolean;
    dataProvider: DataProviderLegacy;
    disconnectedFromDataProviderAt: string | null | undefined;
    displayLastUpdatedAt: string | null | undefined;
    institution: {
      __typename: 'Institution';
      id: string;
      name: string;
      logo: string | null | undefined;
      url: string;
      hasIssuesReported: boolean;
      hasIssuesReportedMessage: string;
      status: InstitutionStatus | null | undefined;
      balanceStatus: InstitutionStatus | null | undefined;
      transactionsStatus: InstitutionStatus | null | undefined;
    };
  }>;
  accounts: Array<{
    __typename: 'Account';
    id: string;
    deletedAt: string | null | undefined;
    displayName: string;
    mask: string | null | undefined;
    credential: { __typename: 'Credential'; id: string } | null | undefined;
    subtype: { __typename: 'AccountSubtype'; name: string; display: string };
  }>;
};

export type Mobile_CreateHouseholdAccessGrantMutationVariables = Exact<{
  input: CreateHouseholdAccessGrantInput;
}>;

export type Mobile_CreateHouseholdAccessGrantMutation = {
  __typename: 'Mutation';
  createHouseholdAccessGrant:
    | {
        __typename: 'CreateHouseholdAccessGrant';
        accessGrant: { __typename: 'HouseholdAccessGrant'; toEmail: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              code: ErrorCode | null | undefined;
              message: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_InviteEmailMutationVariables = Exact<{
  input: InviteUserToHouseholdMutationInput;
}>;

export type Mobile_InviteEmailMutation = {
  __typename: 'Mutation';
  inviteUserToHousehold:
    | {
        __typename: 'InviteUserToHouseholdMutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        invite:
          | { __typename: 'HouseholdInvite'; id: string; invitedEmail: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_DeleteUserProfilePictureMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type Mobile_DeleteUserProfilePictureMutation = {
  __typename: 'Mutation';
  deleteUserProfilePicture:
    | { __typename: 'DeleteUserProfilePicture'; deleted: boolean | null | undefined }
    | null
    | undefined;
};

export type RulesSettingsScreenQueryVariables = Exact<{ [key: string]: never }>;

export type RulesSettingsScreenQuery = {
  __typename: 'Query';
  transactionRules: Array<{
    __typename: 'TransactionRuleV2';
    id: string;
    order: number;
    merchantCriteriaUseOriginalStatement: boolean | null | undefined;
    categoryIds: Array<string> | null | undefined;
    accountIds: Array<string> | null | undefined;
    sendNotificationAction: boolean;
    setHideFromReportsAction: boolean;
    reviewStatusAction: string | null | undefined;
    lastAppliedAt: string | null | undefined;
    recentApplicationCount: number;
    unassignNeedsReviewByUserAction: boolean;
    merchantCriteria:
      | Array<{ __typename: 'MerchantCriterion'; operator: string; value: string }>
      | null
      | undefined;
    amountCriteria:
      | {
          __typename: 'AmountCriteriaV2';
          operator: string;
          isExpense: boolean;
          value: number | null | undefined;
          valueRange:
            | {
                __typename: 'DecimalRange';
                lower: number | null | undefined;
                upper: number | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    categories:
      | Array<{ __typename: 'Category'; id: string; name: string; icon: string }>
      | null
      | undefined;
    accounts: Array<{ __typename: 'Account'; id: string; displayName: string }> | null | undefined;
    setMerchantAction: { __typename: 'Merchant'; id: string; name: string } | null | undefined;
    setCategoryAction:
      | { __typename: 'Category'; id: string; name: string; icon: string }
      | null
      | undefined;
    addTagsAction:
      | Array<{ __typename: 'TransactionTag'; id: string; name: string; color: string }>
      | null
      | undefined;
    linkGoalAction:
      | {
          __typename: 'GoalV2';
          id: string;
          name: string;
          imageStorageProvider: string | null | undefined;
          imageStorageProviderId: string | null | undefined;
        }
      | null
      | undefined;
    needsReviewByUserAction: { __typename: 'User'; id: string; name: string } | null | undefined;
    splitTransactionsAction:
      | {
          __typename: 'SplitTransactionsAction';
          amountType: SplitAmountType;
          splitsInfo: Array<{
            __typename: 'SplitTransactionsActionInfo';
            amount: number;
            categoryId: string;
            merchantName: string;
            goalId: string | null | undefined;
            tags: Array<string> | null | undefined;
            hideFromReports: boolean | null | undefined;
            reviewStatus: string | null | undefined;
            needsReviewByUserId: string | null | undefined;
          }>;
        }
      | null
      | undefined;
  }>;
};

export type Mobile_ToggleSupportAccountAccessMutationVariables = Exact<{
  isEnabled: Scalars['Boolean']['input'];
}>;

export type Mobile_ToggleSupportAccountAccessMutation = {
  __typename: 'Mutation';
  toggleSupportAccountAccess:
    | {
        __typename: 'ToggleSupportAccountAccessMutation';
        grant:
          | { __typename: 'SupportAccountAccessGrant'; id: string; expiresAt: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Mobile_GetUserReportedOnboardingAttributionChannelsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Mobile_GetUserReportedOnboardingAttributionChannelsQuery = {
  __typename: 'Query';
  userReportedOnboardingAttributionChannels: Array<{
    __typename: 'OnboardingAttributionItem';
    name: string;
    displayName: string;
  }>;
};

export type Mobile_SetUserReportedAttributionChannelsMutationVariables = Exact<{
  names: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;

export type Mobile_SetUserReportedAttributionChannelsMutation = {
  __typename: 'Mutation';
  updateUserProfile:
    | {
        __typename: 'UpdateUserProfile';
        userProfile:
          | {
              __typename: 'UserProfile';
              id: string;
              userReportedAttributionChannels: Array<string | null | undefined>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_AccountEditModalQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Web_AccountEditModalQuery = {
  __typename: 'Query';
  account:
    | {
        __typename: 'Account';
        id: string;
        displayName: string;
        deactivatedAt: string | null | undefined;
        displayBalance: number | null | undefined;
        logoUrl: string | null | undefined;
        hasCustomizedLogo: boolean | null | undefined;
        includeInNetWorth: boolean;
        hideFromList: boolean;
        hideTransactionsFromReports: boolean;
        dataProvider: string | null | undefined;
        dataProviderAccountId: string | null | undefined;
        isManual: boolean;
        manualInvestmentsTrackingMethod: string | null | undefined;
        isAsset: boolean;
        invertSyncedBalance: boolean;
        canInvertBalance: boolean;
        useAvailableBalance: boolean | null | undefined;
        canUseAvailableBalance: boolean;
        type: { __typename: 'AccountType'; name: string; display: string };
        subtype: { __typename: 'AccountSubtype'; name: string; display: string };
        creditReportLiabilityAccount:
          | {
              __typename: 'CreditReportLiabilityAccount';
              id: string;
              lastStatement:
                | { __typename: 'LiabilityStatement'; id: string; dueDate: string }
                | null
                | undefined;
              recurringTransactionStream:
                | {
                    __typename: 'RecurringTransactionStream';
                    id: string;
                    isActive: boolean;
                    dayOfTheMonth: number | null | undefined;
                    frequency: string;
                    defaultPaymentAccount:
                      | { __typename: 'Account'; id: string; name: string | null | undefined }
                      | null
                      | undefined;
                    defaultPaymentCategory:
                      | { __typename: 'Category'; id: string; name: string }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AccountLinkFieldsFragment = {
  __typename: 'Account';
  id: string;
  displayName: string;
  icon: string;
  logoUrl: string | null | undefined;
};

export type AccountListItemFieldsFragment = {
  __typename: 'Account';
  id: string;
  displayName: string;
  displayBalance: number | null | undefined;
  signedBalance: number | null | undefined;
  updatedAt: string;
  syncDisabled: boolean;
  icon: string;
  logoUrl: string | null | undefined;
  isHidden: boolean;
  isAsset: boolean;
  includeInNetWorth: boolean;
  includeBalanceInNetWorth: boolean;
  displayLastUpdatedAt: string;
  mask: string | null | undefined;
  credential:
    | {
        __typename: 'Credential';
        id: string;
        updateRequired: boolean;
        dataProvider: DataProviderLegacy;
        disconnectedFromDataProviderAt: string | null | undefined;
      }
    | null
    | undefined;
  institution:
    | {
        __typename: 'Institution';
        id: string;
        logo: string | null | undefined;
        name: string;
        status: InstitutionStatus | null | undefined;
        plaidStatus: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
        newConnectionsDisabled: boolean;
        hasIssuesReported: boolean;
        url: string;
        hasIssuesReportedMessage: string;
        transactionsStatus: InstitutionStatus | null | undefined;
        balanceStatus: InstitutionStatus | null | undefined;
      }
    | null
    | undefined;
  subtype: { __typename: 'AccountSubtype'; display: string };
};

export type AccountMaskFieldsFragment = {
  __typename: 'Account';
  id: string;
  mask: string | null | undefined;
  subtype: { __typename: 'AccountSubtype'; display: string };
};

export type AccountsListFieldsFragment = {
  __typename: 'Account';
  id: string;
  syncDisabled: boolean;
  isHidden: boolean;
  isAsset: boolean;
  includeInNetWorth: boolean;
  order: number;
  displayName: string;
  displayBalance: number | null | undefined;
  signedBalance: number | null | undefined;
  updatedAt: string;
  icon: string;
  logoUrl: string | null | undefined;
  includeBalanceInNetWorth: boolean;
  displayLastUpdatedAt: string;
  mask: string | null | undefined;
  type: { __typename: 'AccountType'; name: string; display: string };
  credential:
    | {
        __typename: 'Credential';
        id: string;
        updateRequired: boolean;
        dataProvider: DataProviderLegacy;
        disconnectedFromDataProviderAt: string | null | undefined;
      }
    | null
    | undefined;
  institution:
    | {
        __typename: 'Institution';
        id: string;
        logo: string | null | undefined;
        name: string;
        status: InstitutionStatus | null | undefined;
        plaidStatus: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
        newConnectionsDisabled: boolean;
        hasIssuesReported: boolean;
        url: string;
        hasIssuesReportedMessage: string;
        transactionsStatus: InstitutionStatus | null | undefined;
        balanceStatus: InstitutionStatus | null | undefined;
      }
    | null
    | undefined;
  subtype: { __typename: 'AccountSubtype'; display: string };
};

export type Web_CreateManualAccountMutationVariables = Exact<{
  input: CreateManualAccountMutationInput;
}>;

export type Web_CreateManualAccountMutation = {
  __typename: 'Mutation';
  createManualAccount:
    | {
        __typename: 'CreateManualAccountMutation';
        account: { __typename: 'Account'; id: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreateSyncedVehicleAccountMutationVariables = Exact<{
  input: CreateSyncedVehicleAccountInput;
}>;

export type CreateSyncedVehicleAccountMutation = {
  __typename: 'Mutation';
  createSyncedVehicleAccount:
    | {
        __typename: 'CreateSyncedVehicleAccount';
        account: { __typename: 'Account'; id: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_CreateZillowAccountMutationVariables = Exact<{
  input: CreateSyncedRealEstateAccountInput;
}>;

export type Web_CreateZillowAccountMutation = {
  __typename: 'Mutation';
  createSyncedRealEstateAccount:
    | {
        __typename: 'CreateSyncedRealEstateAccount';
        account: { __typename: 'Account'; id: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_CopySnapshotsMutationVariables = Exact<{
  input: CopySnapshotsInput;
}>;

export type Web_CopySnapshotsMutation = {
  __typename: 'Mutation';
  copySnapshots:
    | {
        __typename: 'CopySnapshots';
        numSnapshotsCopied: number | null | undefined;
        toAccount: { __typename: 'Account'; id: string; displayName: string } | null | undefined;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_PreviewCopySnapshotsQueryVariables = Exact<{
  input: PreviewCopySnapshotsInput;
}>;

export type Web_PreviewCopySnapshotsQuery = {
  __typename: 'Query';
  previewCopySnapshots:
    | Array<{ __typename: 'AccountSnapshot'; date: string; signedBalance: number }>
    | null
    | undefined;
};

export type Web_SnapshotsForAccountCopyBalancePreviewQueryVariables = Exact<{
  accountId: Scalars['UUID']['input'];
}>;

export type Web_SnapshotsForAccountCopyBalancePreviewQuery = {
  __typename: 'Query';
  snapshotsForAccount: Array<{ __typename: 'AccountSnapshot'; date: string }>;
};

export type EditAccountFormFieldsFragment = {
  __typename: 'Account';
  id: string;
  displayName: string;
  deactivatedAt: string | null | undefined;
  displayBalance: number | null | undefined;
  logoUrl: string | null | undefined;
  hasCustomizedLogo: boolean | null | undefined;
  includeInNetWorth: boolean;
  hideFromList: boolean;
  hideTransactionsFromReports: boolean;
  dataProvider: string | null | undefined;
  dataProviderAccountId: string | null | undefined;
  isManual: boolean;
  manualInvestmentsTrackingMethod: string | null | undefined;
  isAsset: boolean;
  invertSyncedBalance: boolean;
  canInvertBalance: boolean;
  useAvailableBalance: boolean | null | undefined;
  canUseAvailableBalance: boolean;
  type: { __typename: 'AccountType'; name: string; display: string };
  subtype: { __typename: 'AccountSubtype'; name: string; display: string };
  creditReportLiabilityAccount:
    | {
        __typename: 'CreditReportLiabilityAccount';
        id: string;
        lastStatement:
          | { __typename: 'LiabilityStatement'; id: string; dueDate: string }
          | null
          | undefined;
        recurringTransactionStream:
          | {
              __typename: 'RecurringTransactionStream';
              id: string;
              isActive: boolean;
              dayOfTheMonth: number | null | undefined;
              frequency: string;
              defaultPaymentAccount:
                | { __typename: 'Account'; id: string; name: string | null | undefined }
                | null
                | undefined;
              defaultPaymentCategory:
                | { __typename: 'Category'; id: string; name: string }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetCategoriesQuery = {
  __typename: 'Query';
  categoryGroups: Array<{
    __typename: 'CategoryGroup';
    id: string;
    name: string;
    order: number;
    type: CategoryGroupType;
    groupLevelBudgetingEnabled: boolean | null | undefined;
    categories: Array<{
      __typename: 'Category';
      id: string;
      name: string;
      order: number;
      icon: string;
    }>;
  }>;
  categories: Array<{
    __typename: 'Category';
    id: string;
    name: string;
    order: number;
    icon: string;
    group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
  }>;
};

export type InstitutionStatusIndicatorFieldsFragment = {
  __typename: 'Institution';
  id: string;
  status: InstitutionStatus | null | undefined;
  newConnectionsDisabled: boolean;
  hasIssuesReported: boolean;
  hasIssuesReportedMessage: string;
};

export type GetDuplicateCredentialsQueryVariables = Exact<{
  filters: CredentialFilters;
}>;

export type GetDuplicateCredentialsQuery = {
  __typename: 'Query';
  credentials: Array<{
    __typename: 'Credential';
    id: string;
    updateRequired: boolean;
    disconnectedFromDataProviderAt: string | null | undefined;
    displayLastUpdatedAt: string | null | undefined;
    dataProvider: DataProviderLegacy;
    institution: {
      __typename: 'Institution';
      id: string;
      name: string;
      logo: string | null | undefined;
      url: string;
      newConnectionsDisabled: boolean;
      hasIssuesReported: boolean;
      hasIssuesReportedMessage: string;
      status: InstitutionStatus | null | undefined;
      balanceStatus: InstitutionStatus | null | undefined;
      transactionsStatus: InstitutionStatus | null | undefined;
    };
  }>;
};

export type LinkInstitutionFieldsFragment = {
  __typename: 'Institution';
  id: string;
  name: string;
  plaidInstitutionId: string | null | undefined;
  finicityInstitutionId: string | null | undefined;
  mxInstitutionId: string | null | undefined;
  preferredDataProvider: DataProviderLegacy;
  firstBackupDataProvider: DataProviderLegacy | null | undefined;
  logo: string | null | undefined;
  status: InstitutionStatus | null | undefined;
  newConnectionsDisabled: boolean;
  hasIssuesReported: boolean;
  hasIssuesReportedMessage: string;
  linkFlowWarnBeforeConnecting: boolean;
  linkFlowWarningMessage: string | null | undefined;
  linkFlowWarningTitle: string | null | undefined;
};

export type Web_CreateCoinbaseAccountMutationVariables = Exact<{
  input: CreateCoinbaseAccountInput;
}>;

export type Web_CreateCoinbaseAccountMutation = {
  __typename: 'Mutation';
  createCoinbaseAccount:
    | {
        __typename: 'CreateCoinbaseAccountMutation';
        account: { __typename: 'Account'; id: string; displayName: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetTopInstitutionGroupsQueryVariables = Exact<{
  dataProviders:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type Web_GetTopInstitutionGroupsQuery = {
  __typename: 'Query';
  credentials: Array<{ __typename: 'Credential'; id: string }>;
  topInstitutionGroups: Array<{
    __typename: 'TopInstitutionGroup';
    type: TopInstitutionGroupType;
    title: string;
    shortTitle: string;
    accountsConnectedCount: number;
    institutions: Array<{
      __typename: 'Institution';
      id: string;
      name: string;
      logo: string | null | undefined;
      url: string;
      status: InstitutionStatus | null | undefined;
      newConnectionsDisabled: boolean;
      hasIssuesReported: boolean;
      hasIssuesReportedMessage: string;
      linkFlowWarnBeforeConnecting: boolean;
      linkFlowWarningMessage: string | null | undefined;
      linkFlowWarningTitle: string | null | undefined;
      preferredDataProvider: DataProviderLegacy;
      firstBackupDataProvider: DataProviderLegacy | null | undefined;
      plaidInstitutionId: string | null | undefined;
      finicityInstitutionId: string | null | undefined;
      mxInstitutionId: string | null | undefined;
      largeLogo: { __typename: 'FileType'; url: string | null | undefined } | null | undefined;
    }>;
    manualAccountShortcuts:
      | Array<{
          __typename: 'ManualAccountShortcut';
          title: string;
          accountType: { __typename: 'AccountType'; name: string };
          accountSubtype: { __typename: 'AccountSubtype'; name: string } | null | undefined;
        }>
      | null
      | undefined;
  }>;
};

export type Web_MoveTransactionsMutationVariables = Exact<{
  input: MoveTransactionsInput;
}>;

export type Web_MoveTransactionsMutation = {
  __typename: 'Mutation';
  moveTransactions:
    | {
        __typename: 'MoveTransactions';
        numTransactionsMoved: number | null | undefined;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetZestimateQueryVariables = Exact<{
  address: Scalars['String']['input'];
}>;

export type Web_GetZestimateQuery = {
  __typename: 'Query';
  zestimates: Array<{
    __typename: 'ZestimateResult';
    zpid: string;
    addressStreet: string;
    addressCity: string;
    addressStateAbbr: string;
    addressPostalCode: string;
    zestimate: number;
  }>;
};

export type AdviceCategoryIconFieldsFragment = {
  __typename: 'AdviceItemCategory';
  name: string;
  displayName: string;
  color: string;
};

export type AdviceItemDrawerQuery_WebQueryVariables = Exact<{
  itemId: Scalars['ID']['input'];
  previousItemId: Scalars['ID']['input'];
  skipPrevious: Scalars['Boolean']['input'];
}>;

export type AdviceItemDrawerQuery_WebQuery = {
  __typename: 'Query';
  previousItem?: { __typename: 'AdviceItem'; id: string; title: string } | null | undefined;
  adviceItem:
    | {
        __typename: 'AdviceItem';
        id: string;
        name: string;
        title: string;
        description: string | null | undefined;
        completedAt: string | null | undefined;
        category: {
          __typename: 'AdviceItemCategory';
          name: string;
          displayName: string;
          color: string;
        };
        prerequisiteAdviceItems: Array<{
          __typename: 'AdviceItem';
          id: string;
          title: string;
          numTasksCompleted: number;
          numTasks: number;
          completedAt: string | null | undefined;
          category: {
            __typename: 'AdviceItemCategory';
            name: string;
            displayName: string;
            color: string;
          };
        }>;
        tasks: Array<{
          __typename: 'AdviceItemTask';
          id: string;
          title: string;
          completedAt: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type AdviceItemListFieldsFragment = {
  __typename: 'AdviceItem';
  id: string;
  title: string;
  description: string | null | undefined;
  numTasksCompleted: number;
  numTasksRemaining: number;
  numTasks: number;
  completedAt: string | null | undefined;
  category: { __typename: 'AdviceItemCategory'; name: string; displayName: string; color: string };
};

export type AdviceItemRowFieldsFragment = {
  __typename: 'AdviceItem';
  id: string;
  title: string;
  description: string | null | undefined;
  numTasksCompleted: number;
  numTasksRemaining: number;
  numTasks: number;
  completedAt: string | null | undefined;
  category: { __typename: 'AdviceItemCategory'; name: string; displayName: string; color: string };
};

export type AdviceTaskDrawerQuery_WebQueryVariables = Exact<{
  itemId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
}>;

export type AdviceTaskDrawerQuery_WebQuery = {
  __typename: 'Query';
  adviceItem:
    | { __typename: 'AdviceItem'; id: string; name: string; title: string }
    | null
    | undefined;
  adviceItemTask:
    | {
        __typename: 'AdviceItemTask';
        id: string;
        name: string;
        title: string;
        description: string | null | undefined;
        completedAt: string | null | undefined;
      }
    | null
    | undefined;
};

export type AdviceTaskRowFieldsFragment = {
  __typename: 'AdviceItemTask';
  id: string;
  title: string;
  completedAt: string | null | undefined;
};

export type CondensedAdviceItemRowFieldsFragment = {
  __typename: 'AdviceItem';
  id: string;
  title: string;
  numTasksCompleted: number;
  numTasks: number;
  completedAt: string | null | undefined;
  category: { __typename: 'AdviceItemCategory'; name: string; displayName: string; color: string };
};

export type QuestionFormFieldsFragment = {
  __typename: 'ProfileQuestion';
  name: string;
  type: string;
  required: boolean;
  options:
    | Array<{ __typename: 'ProfileQuestionOption'; value: string; label: string }>
    | null
    | undefined;
  answer:
    | {
        __typename: 'ProfileQuestionAnswer';
        answer: string | null | undefined;
        dontKnow: boolean;
        answeredAt: string | null | undefined;
      }
    | null
    | undefined;
};

export type QuestionHeaderFieldsFragment = {
  __typename: 'ProfileQuestion';
  title: string;
  description: string | null | undefined;
};

export type Web_CreateSubscriptionSponsorshipInvitesMutationVariables = Exact<{
  input: CreateSponsorshipInvitesInput;
}>;

export type Web_CreateSubscriptionSponsorshipInvitesMutation = {
  __typename: 'Mutation';
  createSubscriptionSponsorshipInvites:
    | {
        __typename: 'CreateSponsorshipInvites';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        invites: Array<{ __typename: 'SubscriptionSponsorshipInvite'; id: string; code: string }>;
      }
    | null
    | undefined;
};

export type Web_CancelSponsorshipMutationVariables = Exact<{
  input: CancelSponsorshipInput;
}>;

export type Web_CancelSponsorshipMutation = {
  __typename: 'Mutation';
  cancelSubscriptionSponsorship:
    | {
        __typename: 'CancelSponsorship';
        canceled: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_RevokeSponsorshipInviteMutationVariables = Exact<{
  input: RevokeSponsorshipInviteInput;
}>;

export type Web_RevokeSponsorshipInviteMutation = {
  __typename: 'Mutation';
  revokeSubscriptionSponsorshipInvite:
    | {
        __typename: 'RevokeSponsorshipInvite';
        revoked: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_SubmitAssistantFeedbackMutationVariables = Exact<{
  input: SubmitAssistantFeedbackInput;
}>;

export type Web_SubmitAssistantFeedbackMutation = {
  __typename: 'Mutation';
  submitAssistantFeedback: { __typename: 'SubmitAssistantFeedback'; success: boolean };
};

export type AssistantMessageListGroupFieldsFragment = {
  __typename: 'AssistantMessage';
  id: string;
  threadId: string;
  createdAt: string;
  content: string;
  name: string;
  suggestedPrompts: Array<string> | null | undefined;
  debugInfo: string | null | undefined;
};

export type AssistantStatusMessageListGroupFieldsFragment = {
  __typename: 'AssistantStatusMessage';
  id: string;
  createdAt: string;
  threadId: string;
  content: string;
  name: string;
};

type MessageListGroupFields_AssistantMessage_Fragment = {
  __typename: 'AssistantMessage';
  id: string;
  threadId: string;
  createdAt: string;
  content: string;
  name: string;
  suggestedPrompts: Array<string> | null | undefined;
  debugInfo: string | null | undefined;
};

type MessageListGroupFields_AssistantStatusMessage_Fragment = {
  __typename: 'AssistantStatusMessage';
  id: string;
  createdAt: string;
  threadId: string;
  content: string;
  name: string;
};

type MessageListGroupFields_UserMessage_Fragment = {
  __typename: 'UserMessage';
  id: string;
  threadId: string;
  createdAt: string;
  content: string;
  user: {
    __typename: 'User';
    id: string;
    name: string;
    profilePictureUrl: string | null | undefined;
  };
};

export type MessageListGroupFieldsFragment =
  | MessageListGroupFields_AssistantMessage_Fragment
  | MessageListGroupFields_AssistantStatusMessage_Fragment
  | MessageListGroupFields_UserMessage_Fragment;

export type MessageThreadListItemFieldsFragment = {
  __typename: 'MessageThread';
  id: string;
  subject: string | null | undefined;
  createdAt: string;
  lastMessageSentAt: string | null | undefined;
  hasOutstandingAssistantRequests: boolean;
};

export type UserMessageListGroupFieldsFragment = {
  __typename: 'UserMessage';
  id: string;
  threadId: string;
  createdAt: string;
  content: string;
  user: {
    __typename: 'User';
    id: string;
    name: string;
    profilePictureUrl: string | null | undefined;
  };
};

export type Web_AccountsMessageBlockQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type Web_AccountsMessageBlockQuery = {
  __typename: 'Query';
  accounts: Array<{
    __typename: 'Account';
    id: string;
    displayName: string;
    displayBalance: number | null | undefined;
    icon: string;
    logoUrl: string | null | undefined;
    subtype: { __typename: 'AccountSubtype'; display: string };
  }>;
};

export type Web_GoalsMessageBlockQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type Web_GoalsMessageBlockQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    name: string;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
    currentAmount: number | null | undefined;
    completionPercent: number | null | undefined;
    completedAt: string | null | undefined;
    archivedAt: string | null | undefined;
    type: string;
    accountAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      currentMonthChange:
        | {
            __typename: 'GoalAccountAllocationCurrentMonthChange';
            percent: number | null | undefined;
            amount: number | null | undefined;
          }
        | null
        | undefined;
    }>;
  }>;
};

export type Web_TransactionsMessageBlockQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type Web_TransactionsMessageBlockQuery = {
  __typename: 'Query';
  allTransactions: {
    __typename: 'TransactionList';
    results: Array<{
      __typename: 'Transaction';
      id: string;
      amount: number;
      merchant: { __typename: 'Merchant'; id: string; name: string };
      category: { __typename: 'Category'; id: string; name: string; icon: string };
    }>;
  };
};

export type Web_MoveMoneyMutationVariables = Exact<{
  input: MoveMoneyMutationInput;
}>;

export type Web_MoveMoneyMutation = {
  __typename: 'Mutation';
  moveMoneyBetweenCategories:
    | {
        __typename: 'MoveMoneyMutation';
        fromBudgetItem:
          | { __typename: 'BudgetItem'; id: string; budgetAmount: number | null | undefined }
          | null
          | undefined;
        toBudgetItem:
          | { __typename: 'BudgetItem'; id: string; budgetAmount: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CategoryPlanSummaryFieldsFragment = {
  __typename: 'BudgetMonthlyAmounts';
  month: string;
  plannedAmount: number | null | undefined;
  actualAmount: number | null | undefined;
  remainingAmount: number | null | undefined;
  previousMonthRolloverAmount: number | null | undefined;
  rolloverType: string | null | undefined;
};

export type FilteredCashFlowSummaryFieldsFragment = {
  __typename: 'TransactionsSummary';
  count: number;
  max: number;
  avg: number | null | undefined;
  sum: number;
};

export type GetCategorySelectOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCategorySelectOptionsQuery = {
  __typename: 'Query';
  categoryGroups: Array<{
    __typename: 'CategoryGroup';
    id: string;
    name: string;
    order: number;
    type: CategoryGroupType;
    groupLevelBudgetingEnabled: boolean | null | undefined;
    categories: Array<{
      __typename: 'Category';
      id: string;
      name: string;
      order: number;
      icon: string;
    }>;
  }>;
  categories: Array<{
    __typename: 'Category';
    id: string;
    name: string;
    order: number;
    icon: string;
    group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
  }>;
};

export type Web_CreateCategoryMutationVariables = Exact<{
  input: CreateCategoryInput;
}>;

export type Web_CreateCategoryMutation = {
  __typename: 'Mutation';
  createCategory:
    | {
        __typename: 'CreateCategoryPayload';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        category:
          | {
              __typename: 'Category';
              id: string;
              order: number;
              name: string;
              icon: string;
              systemCategory: string | null | undefined;
              systemCategoryDisplayName: string | null | undefined;
              budgetVariability: BudgetVariability | null | undefined;
              excludeFromBudget: boolean | null | undefined;
              isSystemCategory: boolean;
              isDisabled: boolean;
              group: {
                __typename: 'CategoryGroup';
                id: string;
                type: CategoryGroupType;
                groupLevelBudgetingEnabled: boolean | null | undefined;
              };
              rolloverPeriod:
                | {
                    __typename: 'BudgetRolloverPeriod';
                    id: string;
                    startMonth: string;
                    startingBalance: number | null | undefined;
                    type: string;
                    frequency: string | null | undefined;
                    targetAmount: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CategoryFormFieldsFragment = {
  __typename: 'Category';
  id: string;
  order: number;
  name: string;
  icon: string;
  systemCategory: string | null | undefined;
  systemCategoryDisplayName: string | null | undefined;
  budgetVariability: BudgetVariability | null | undefined;
  excludeFromBudget: boolean | null | undefined;
  isSystemCategory: boolean;
  isDisabled: boolean;
  group: {
    __typename: 'CategoryGroup';
    id: string;
    type: CategoryGroupType;
    groupLevelBudgetingEnabled: boolean | null | undefined;
  };
  rolloverPeriod:
    | {
        __typename: 'BudgetRolloverPeriod';
        id: string;
        startMonth: string;
        startingBalance: number | null | undefined;
        type: string;
        frequency: string | null | undefined;
        targetAmount: number | null | undefined;
      }
    | null
    | undefined;
};

export type CategoryDeletionInfoQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type CategoryDeletionInfoQuery = {
  __typename: 'Query';
  categoryDeletionInfo:
    | { __typename: 'CategoryDeletionInfo'; transactionsCount: number; rulesCount: number }
    | null
    | undefined;
  category:
    | { __typename: 'Category'; id: string; name: string; icon: string; isSystemCategory: boolean }
    | null
    | undefined;
};

export type Web_GetCategoryGroupModalQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  includeDisabledSystemCategories: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Web_GetCategoryGroupModalQuery = {
  __typename: 'Query';
  categoryGroup:
    | {
        __typename: 'CategoryGroup';
        id: string;
        name: string;
        order: number;
        type: CategoryGroupType;
        color: string | null | undefined;
        groupLevelBudgetingEnabled: boolean | null | undefined;
        budgetVariability: BudgetVariability | null | undefined;
        categories: Array<{
          __typename: 'Category';
          id: string;
          name: string;
          icon: string;
          rolloverPeriod:
            | {
                __typename: 'BudgetRolloverPeriod';
                id: string;
                startMonth: string;
                startingBalance: number | null | undefined;
              }
            | null
            | undefined;
        }>;
        rolloverPeriod:
          | {
              __typename: 'BudgetRolloverPeriod';
              id: string;
              startMonth: string;
              endMonth: string | null | undefined;
              startingBalance: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetEditCategoryQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Web_GetEditCategoryQuery = {
  __typename: 'Query';
  category:
    | {
        __typename: 'Category';
        id: string;
        order: number;
        name: string;
        icon: string;
        systemCategory: string | null | undefined;
        systemCategoryDisplayName: string | null | undefined;
        budgetVariability: BudgetVariability | null | undefined;
        excludeFromBudget: boolean | null | undefined;
        isSystemCategory: boolean;
        isDisabled: boolean;
        group: {
          __typename: 'CategoryGroup';
          id: string;
          type: CategoryGroupType;
          groupLevelBudgetingEnabled: boolean | null | undefined;
        };
        rolloverPeriod:
          | {
              __typename: 'BudgetRolloverPeriod';
              id: string;
              startMonth: string;
              startingBalance: number | null | undefined;
              type: string;
              frequency: string | null | undefined;
              targetAmount: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_UpdateCategoryMutationVariables = Exact<{
  input: UpdateCategoryInput;
}>;

export type Web_UpdateCategoryMutation = {
  __typename: 'Mutation';
  updateCategory:
    | {
        __typename: 'UpdateCategoryPayload';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        category:
          | {
              __typename: 'Category';
              id: string;
              order: number;
              name: string;
              icon: string;
              systemCategory: string | null | undefined;
              systemCategoryDisplayName: string | null | undefined;
              budgetVariability: BudgetVariability | null | undefined;
              excludeFromBudget: boolean | null | undefined;
              isSystemCategory: boolean;
              isDisabled: boolean;
              group: {
                __typename: 'CategoryGroup';
                id: string;
                type: CategoryGroupType;
                groupLevelBudgetingEnabled: boolean | null | undefined;
              };
              rolloverPeriod:
                | {
                    __typename: 'BudgetRolloverPeriod';
                    id: string;
                    startMonth: string;
                    startingBalance: number | null | undefined;
                    type: string;
                    frequency: string | null | undefined;
                    targetAmount: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_RestoreCategoryMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Web_RestoreCategoryMutation = {
  __typename: 'Mutation';
  restoreCategory:
    | {
        __typename: 'RestoreCategoryMutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        category:
          | {
              __typename: 'Category';
              id: string;
              order: number;
              name: string;
              icon: string;
              systemCategory: string | null | undefined;
              systemCategoryDisplayName: string | null | undefined;
              budgetVariability: BudgetVariability | null | undefined;
              excludeFromBudget: boolean | null | undefined;
              isSystemCategory: boolean;
              isDisabled: boolean;
              group: {
                __typename: 'CategoryGroup';
                id: string;
                type: CategoryGroupType;
                groupLevelBudgetingEnabled: boolean | null | undefined;
              };
              rolloverPeriod:
                | {
                    __typename: 'BudgetRolloverPeriod';
                    id: string;
                    startMonth: string;
                    startingBalance: number | null | undefined;
                    type: string;
                    frequency: string | null | undefined;
                    targetAmount: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetCategorySelectOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetCategorySelectOptionsQuery = {
  __typename: 'Query';
  categoryGroups: Array<{
    __typename: 'CategoryGroup';
    id: string;
    name: string;
    order: number;
    type: CategoryGroupType;
    groupLevelBudgetingEnabled: boolean | null | undefined;
    categories: Array<{
      __typename: 'Category';
      id: string;
      name: string;
      order: number;
      icon: string;
    }>;
  }>;
  categories: Array<{
    __typename: 'Category';
    id: string;
    name: string;
    order: number;
    icon: string;
    group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
  }>;
};

export type Web_GetRecommendedCategoriesQueryVariables = Exact<{
  transactionId: Scalars['ID']['input'];
}>;

export type Web_GetRecommendedCategoriesQuery = {
  __typename: 'Query';
  recommendedCategories:
    | Array<{
        __typename: 'RecommendedCategory';
        occurrences: number;
        category: {
          __typename: 'Category';
          id: string;
          name: string;
          order: number;
          icon: string;
          group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
        };
      }>
    | null
    | undefined;
};

export type ManageGetCategoryGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type ManageGetCategoryGroupsQuery = {
  __typename: 'Query';
  categoryGroups: Array<{
    __typename: 'CategoryGroup';
    id: string;
    name: string;
    order: number;
    type: CategoryGroupType;
  }>;
  categories: Array<{
    __typename: 'Category';
    id: string;
    name: string;
    order: number;
    icon: string;
    isSystemCategory: boolean;
    systemCategory: string | null | undefined;
    isDisabled: boolean;
    group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType; name: string };
  }>;
};

export type Web_ResetRolloverMutationVariables = Exact<{
  input: ResetBudgetRolloverInput;
}>;

export type Web_ResetRolloverMutation = {
  __typename: 'Mutation';
  resetBudgetRollover:
    | {
        __typename: 'ResetBudgetRolloverMutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetAdviceDashboardWidgetQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetAdviceDashboardWidgetQuery = {
  __typename: 'Query';
  adviceItems:
    | Array<{
        __typename: 'AdviceItem';
        id: string;
        title: string;
        numTasksCompleted: number;
        numTasks: number;
        completedAt: string | null | undefined;
        category: {
          __typename: 'AdviceItemCategory';
          name: string;
          displayName: string;
          color: string;
        };
      }>
    | null
    | undefined;
  profileQuestionnaire:
    | {
        __typename: 'ProfileQuestionnaire';
        state: {
          __typename: 'ProfileQuestionnaireState';
          numQuestionsAnswered: number;
          completed: boolean;
        };
      }
    | null
    | undefined;
};

export type Web_GoalsDashboardCardV2QueryVariables = Exact<{ [key: string]: never }>;

export type Web_GoalsDashboardCardV2Query = {
  __typename: 'Query';
  migratedToGoalsV2: boolean;
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    priority: number;
    name: string;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
    currentAmount: number | null | undefined;
    completionPercent: number | null | undefined;
    completedAt: string | null | undefined;
    archivedAt: string | null | undefined;
    type: string;
    accountAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      currentMonthChange:
        | {
            __typename: 'GoalAccountAllocationCurrentMonthChange';
            percent: number | null | undefined;
            amount: number | null | undefined;
          }
        | null
        | undefined;
    }>;
  }>;
  goals: Array<{ __typename: 'Goal'; id: string }>;
};

export type Web_GetInvestmentsDashboardCardQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetInvestmentsDashboardCardQuery = {
  __typename: 'Query';
  portfolio: {
    __typename: 'Portfolio';
    performance: {
      __typename: 'PortfolioPerformance';
      totalValue: number;
      oneDayChangeDollars: number;
      topMovers: Array<{
        __typename: 'Security';
        id: string;
        name: string;
        ticker: string | null | undefined;
        oneDayChangePercent: number | null | undefined;
        currentPrice: number | null | undefined;
      }>;
    };
  };
};

export type Web_GetDashboardUpcomingRecurringTransactionItemsQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
  includeLiabilities: InputMaybe<Scalars['Boolean']['input']>;
  filters: InputMaybe<RecurringTransactionFilter>;
}>;

export type Web_GetDashboardUpcomingRecurringTransactionItemsQuery = {
  __typename: 'Query';
  recurringRemainingDue: { __typename: 'RecurringRemainingDueData'; amount: number };
  recurringTransactionItems: Array<{
    __typename: 'RecurringTransactionCalendarItem';
    isPast: boolean;
    date: string;
    amount: number | null | undefined;
    stream: {
      __typename: 'RecurringTransactionStream';
      id: string;
      frequency: string;
      name: string;
      logoUrl: string | null | undefined;
      merchant: { __typename: 'Merchant'; id: string } | null | undefined;
      creditReportLiabilityAccount:
        | {
            __typename: 'CreditReportLiabilityAccount';
            id: string;
            account: { __typename: 'Account'; id: string } | null | undefined;
          }
        | null
        | undefined;
    };
    account:
      | { __typename: 'Account'; id: string; logoUrl: string | null | undefined; icon: string }
      | null
      | undefined;
  }>;
};

export type GetTransactionsListDashboardQueryVariables = Exact<{
  offset: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  filters: InputMaybe<TransactionFilterInput>;
}>;

export type GetTransactionsListDashboardQuery = {
  __typename: 'Query';
  allTransactions: {
    __typename: 'TransactionList';
    totalCount: number;
    totalSelectableCount: number | null | undefined;
    results: Array<{
      __typename: 'Transaction';
      id: string;
      amount: number;
      pending: boolean;
      date: string;
      hideFromReports: boolean;
      plaidName: string | null | undefined;
      notes: string | null | undefined;
      isRecurring: boolean;
      reviewStatus: string | null | undefined;
      needsReview: boolean;
      isSplitTransaction: boolean;
      dataProviderDescription: string | null | undefined;
      attachments: Array<{ __typename: 'TransactionAttachment'; id: string }>;
      category: {
        __typename: 'Category';
        id: string;
        name: string;
        icon: string;
        group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
      };
      merchant: {
        __typename: 'Merchant';
        name: string;
        id: string;
        transactionsCount: number;
        logoUrl: string | null | undefined;
        recurringTransactionStream:
          | { __typename: 'RecurringTransactionStream'; frequency: string; isActive: boolean }
          | null
          | undefined;
      };
      tags: Array<{
        __typename: 'TransactionTag';
        id: string;
        name: string;
        color: string;
        order: number;
      }>;
      account: {
        __typename: 'Account';
        id: string;
        displayName: string;
        icon: string;
        logoUrl: string | null | undefined;
      };
    }>;
  };
  transactionRules: Array<{ __typename: 'TransactionRuleV2'; id: string }>;
  aggregates: Array<{
    __typename: 'AggregateData';
    summary: { __typename: 'TransactionsSummary'; count: number };
  }>;
};

export type Web_RequestEmailReportMutationVariables = Exact<{
  month: Scalars['Date']['input'];
}>;

export type Web_RequestEmailReportMutation = {
  __typename: 'Mutation';
  requestEmailReport: { __typename: 'RequestEmailReport'; success: boolean } | null | undefined;
};

export type Web_DisconnectCredentialMutationVariables = Exact<{
  accountId: Scalars['UUID']['input'];
}>;

export type Web_DisconnectCredentialMutation = {
  __typename: 'Mutation';
  disconnectCredential:
    | { __typename: 'DisconnectCredentialMutation'; success: boolean }
    | null
    | undefined;
};

export type Web_ResetHouseholdRecurringMerchantSearchMutationVariables = Exact<{
  deleteStreams: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Web_ResetHouseholdRecurringMerchantSearchMutation = {
  __typename: 'Mutation';
  resetRecurringMerchantSearch:
    | { __typename: 'ResetHouseholdMerchantRecurringSearchMutation'; success: boolean }
    | null
    | undefined;
};

export type Web_DeleteAllHouseholdTransactionTagsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type Web_DeleteAllHouseholdTransactionTagsMutation = {
  __typename: 'Mutation';
  deleteAllHouseholdTransactionTags:
    | {
        __typename: 'DeleteAllHouseholdTransactionTags';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_ResetAdviceMutationVariables = Exact<{ [key: string]: never }>;

export type Web_ResetAdviceMutation = {
  __typename: 'Mutation';
  resetAdvice: { __typename: 'ResetAdvice'; success: boolean } | null | undefined;
};

export type Web_GetGlobalSearchQueryVariables = Exact<{
  searchTerm: Scalars['String']['input'];
}>;

export type Web_GetGlobalSearchQuery = {
  __typename: 'Query';
  globalSearch:
    | {
        __typename: 'GlobalSearch';
        searchTerm: string;
        results: Array<{
          __typename: 'SearchResult';
          id: string;
          name: string;
          icon: string | null | undefined;
          logoUrl: string | null | undefined;
          type: string;
        }>;
      }
    | null
    | undefined;
};

export type EditGoalAccountRowFieldsFragment = {
  __typename: 'Account';
  id: string;
  icon: string;
  logoUrl: string | null | undefined;
  displayName: string;
  displayBalance: number | null | undefined;
  signedBalance: number | null | undefined;
  mask: string | null | undefined;
  subtype: { __typename: 'AccountSubtype'; display: string };
};

export type EditGoalFieldsFragment = {
  __typename: 'Goal';
  id: string;
  name: string;
  icon: string;
  goalType: GoalGoalType;
  targetAmount: number;
  targetDate: string;
  plannedMonthlyContribution: number;
  contributedBalance: number;
  timeline: Array<{
    __typename: 'GoalTimelinePoint';
    date: string;
    actualBalance: number | null | undefined;
    projectedBalance: number | null | undefined;
    plannedBalance: number | null | undefined;
  }>;
};

export type EditPlanFormGraphFieldsFragment = {
  __typename: 'Goal';
  id: string;
  targetAmount: number;
  targetDate: string;
  plannedMonthlyContribution: number;
  contributedBalance: number;
  timeline: Array<{
    __typename: 'GoalTimelinePoint';
    date: string;
    actualBalance: number | null | undefined;
    projectedBalance: number | null | undefined;
    plannedBalance: number | null | undefined;
  }>;
};

export type GoalContributeCardAnimateProgressFieldsFragment = {
  __typename: 'Goal';
  id: string;
  targetAmount: number;
  contributedBalance: number;
  name: string;
  icon: string;
  targetDate: string;
  projectedCompletionDateGivenContribution: string | null | undefined;
  completedAt: string | null | undefined;
};

export type GoalContributeCardBaseFieldsFragment = {
  __typename: 'Goal';
  id: string;
  name: string;
  icon: string;
  targetDate: string;
  projectedCompletionDateGivenContribution: string | null | undefined;
  completedAt: string | null | undefined;
};

export type GoalContributeCardInputFieldsFragment = {
  __typename: 'Goal';
  id: string;
  targetAmount: number;
  contributedBalance: number;
  plannedMonthlyContribution: number;
  name: string;
  icon: string;
  targetDate: string;
  projectedCompletionDateGivenContribution: string | null | undefined;
  completedAt: string | null | undefined;
};

export type GoalTimelinePointFieldsFragment = {
  __typename: 'GoalTimelinePoint';
  date: string;
  actualBalance: number | null | undefined;
  projectedBalance: number | null | undefined;
  plannedBalance: number | null | undefined;
};

export type WebGoalBalancesCardFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  type: string;
  defaultName: string | null | undefined;
  accountAllocations: Array<{
    __typename: 'GoalAccountAllocation';
    id: string;
    currentAmount: number | null | undefined;
    currentMonthChange:
      | {
          __typename: 'GoalAccountAllocationCurrentMonthChange';
          percent: number | null | undefined;
          amount: number | null | undefined;
        }
      | null
      | undefined;
    account: {
      __typename: 'Account';
      id: string;
      displayName: string;
      hideFromList: boolean;
      deactivatedAt: string | null | undefined;
      icon: string;
      logoUrl: string | null | undefined;
      mask: string | null | undefined;
      type: { __typename: 'AccountType'; name: string };
      subtype: { __typename: 'AccountSubtype'; display: string };
    };
  }>;
};

export type WebGoalCardFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  imageStorageProvider: string | null | undefined;
  imageStorageProviderId: string | null | undefined;
  priority: number;
  archivedAt: string | null | undefined;
  name: string;
  defaultName: string | null | undefined;
  targetAmount: number | null | undefined;
  startingAmount: number | null | undefined;
  currentAmount: number | null | undefined;
  completedAt: string | null | undefined;
  type: string;
  completionPercent: number | null | undefined;
  accountAllocations: Array<{
    __typename: 'GoalAccountAllocation';
    id: string;
    account: {
      __typename: 'Account';
      id: string;
      logoUrl: string | null | undefined;
      icon: string;
    };
  }>;
};

export type WebGoalContributionGraphFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  plannedMonthlyContribution: number | null | undefined;
  monthlyContributionSummaries: Array<{
    __typename: 'GoalContributionMonthlySummary';
    month: string;
    sum: number;
    sumCredit: number;
    sumDebit: number;
    plannedAmount: number | null | undefined;
  }>;
};

export type GoalCustomizeModalFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  name: string;
  imageStorageProvider: string | null | undefined;
  imageStorageProviderId: string | null | undefined;
};

export type Web_GoalDashboardRowFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  name: string;
  imageStorageProvider: string | null | undefined;
  imageStorageProviderId: string | null | undefined;
  currentAmount: number | null | undefined;
  completionPercent: number | null | undefined;
  completedAt: string | null | undefined;
  archivedAt: string | null | undefined;
  type: string;
  accountAllocations: Array<{
    __typename: 'GoalAccountAllocation';
    id: string;
    currentMonthChange:
      | {
          __typename: 'GoalAccountAllocationCurrentMonthChange';
          percent: number | null | undefined;
          amount: number | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type WebGoalDetailsCardFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  priority: number;
  plannedMonthlyContribution: number | null | undefined;
  plannedMonthlyPretaxContribution: number | null | undefined;
  targetAmount: number | null | undefined;
  startingAmount: number | null | undefined;
  type: string;
  objective: string;
};

export type Web_GoalInfoRowFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  name: string;
  priority: number;
  imageStorageProvider: string | null | undefined;
  imageStorageProviderId: string | null | undefined;
};

export type WebGoalNameProgressBarFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  name: string;
  defaultName: string | null | undefined;
  targetAmount: number | null | undefined;
  startingAmount: number | null | undefined;
  currentAmount: number | null | undefined;
  completedAt: string | null | undefined;
  archivedAt: string | null | undefined;
  type: string;
  completionPercent: number | null | undefined;
  accountAllocations: Array<{ __typename: 'GoalAccountAllocation'; id: string }>;
};

export type GoalRankCardFieldsFragment = {
  __typename: 'GoalV2';
  id: string;
  name: string;
  priority: number;
  imageStorageProvider: string | null | undefined;
  imageStorageProviderId: string | null | undefined;
};

export type Web_GoalSelectTransactionQueryVariables = Exact<{
  transactionId: Scalars['UUID']['input'];
}>;

export type Web_GoalSelectTransactionQuery = {
  __typename: 'Query';
  getTransaction:
    | {
        __typename: 'Transaction';
        id: string;
        pending: boolean;
        account: {
          __typename: 'Account';
          id: string;
          goalAllocations: Array<{
            __typename: 'GoalAccountAllocation';
            id: string;
            goal: {
              __typename: 'GoalV2';
              id: string;
              name: string;
              imageStorageProvider: string | null | undefined;
              imageStorageProviderId: string | null | undefined;
              priority: number;
              archivedAt: string | null | undefined;
            };
          }>;
        };
        goal:
          | {
              __typename: 'GoalV2';
              id: string;
              name: string;
              imageStorageProvider: string | null | undefined;
              imageStorageProviderId: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GoalSelectGoalsQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GoalSelectGoalsQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    name: string;
    priority: number;
    archivedAt: string | null | undefined;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
    accountAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      account: { __typename: 'Account'; id: string };
    }>;
  }>;
};

export type Web_GoalsAllocateBalanceModalQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GoalsAllocateBalanceModalQuery = {
  __typename: 'Query';
  accountsWithUnallocatedBalancesForGoals: Array<{
    __typename: 'Account';
    id: string;
    displayName: string;
    displayBalance: number | null | undefined;
    logoUrl: string | null | undefined;
    icon: string;
    availableBalanceForGoals: number;
    goalAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      amount: number | null | undefined;
      currentAmount: number | null | undefined;
      account: { __typename: 'Account'; id: string; availableBalanceForGoals: number };
      goal: {
        __typename: 'GoalV2';
        id: string;
        targetAmount: number | null | undefined;
        currentAmount: number | null | undefined;
        completionPercent: number | null | undefined;
        completedAt: string | null | undefined;
        archivedAt: string | null | undefined;
        name: string;
        priority: number;
        imageStorageProvider: string | null | undefined;
        imageStorageProviderId: string | null | undefined;
        startingAmount: number | null | undefined;
        type: string;
        defaultName: string | null | undefined;
        accountAllocations: Array<{
          __typename: 'GoalAccountAllocation';
          id: string;
          account: {
            __typename: 'Account';
            id: string;
            logoUrl: string | null | undefined;
            icon: string;
            dataProvider: string | null | undefined;
            type: { __typename: 'AccountType'; name: string };
            subtype: { __typename: 'AccountSubtype'; name: string };
            institution:
              | {
                  __typename: 'Institution';
                  id: string;
                  logo: string | null | undefined;
                  primaryColor: string;
                }
              | null
              | undefined;
          };
        }>;
      };
    }>;
  }>;
};

export type Web_GoalsAllocateBannerFieldsFragment = {
  __typename: 'Account';
  id: string;
  name: string | null | undefined;
  logoUrl: string | null | undefined;
  icon: string;
};

export type Web_GoalsMapAccountsModalQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GoalsMapAccountsModalQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    archivedAt: string | null | undefined;
    name: string;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
    targetAmount: number | null | undefined;
    startingAmount: number | null | undefined;
    currentAmount: number | null | undefined;
    completionPercent: number | null | undefined;
    type: string;
    defaultName: string | null | undefined;
    accountAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      account: {
        __typename: 'Account';
        id: string;
        logoUrl: string | null | undefined;
        icon: string;
        dataProvider: string | null | undefined;
        type: { __typename: 'AccountType'; name: string };
        subtype: { __typename: 'AccountSubtype'; name: string };
        institution:
          | {
              __typename: 'Institution';
              id: string;
              logo: string | null | undefined;
              primaryColor: string;
            }
          | null
          | undefined;
      };
    }>;
  }>;
};

export type Web_MonthlyBudgetGoalsModalQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type Web_MonthlyBudgetGoalsModalQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    plannedMonthlyContribution: number | null | undefined;
    objective: string;
    name: string;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
  }>;
  aggregates: Array<{
    __typename: 'AggregateData';
    summary: { __typename: 'TransactionsSummary'; savings: number };
  }>;
};

export type Web_PreTaxBudgetGoalsListQueryVariables = Exact<{ [key: string]: never }>;

export type Web_PreTaxBudgetGoalsListQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    plannedMonthlyPretaxContribution: number | null | undefined;
    objective: string;
    name: string;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
  }>;
};

export type Web_RankGoalsListQueryVariables = Exact<{ [key: string]: never }>;

export type Web_RankGoalsListQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    archivedAt: string | null | undefined;
    name: string;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
  }>;
};

export type Web_UpdateGoalsPrioritiesMutationVariables = Exact<{
  input: UpdateGoalPrioritiesInput;
}>;

export type Web_UpdateGoalsPrioritiesMutation = {
  __typename: 'Mutation';
  updateGoalPriorities:
    | {
        __typename: 'UpdateGoalPriorities';
        goals:
          | Array<{ __typename: 'GoalV2'; id: string; priority: number } | null | undefined>
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_TargetAmountsGoalsListQueryVariables = Exact<{ [key: string]: never }>;

export type Web_TargetAmountsGoalsListQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    targetAmount: number | null | undefined;
    startingAmount: number | null | undefined;
    type: string;
    name: string;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
  }>;
};

export type Web_GetHoldingsQueryVariables = Exact<{
  input: InputMaybe<PortfolioInput>;
}>;

export type Web_GetHoldingsQuery = {
  __typename: 'Query';
  portfolio: {
    __typename: 'Portfolio';
    aggregateHoldings: {
      __typename: 'AggregateHoldingConnection';
      edges: Array<
        | {
            __typename: 'AggregateHoldingEdge';
            node:
              | {
                  __typename: 'AggregateHolding';
                  id: string;
                  quantity: number;
                  basis: number;
                  totalValue: number;
                  securityPriceChangeDollars: number | null | undefined;
                  securityPriceChangePercent: number | null | undefined;
                  lastSyncedAt: string | null | undefined;
                  holdings: Array<{
                    __typename: 'Holding';
                    id: string;
                    type: string | null | undefined;
                    typeDisplay: string | null | undefined;
                    name: string | null | undefined;
                    ticker: string | null | undefined;
                    closingPrice: number | null | undefined;
                    isManual: boolean | null | undefined;
                    closingPriceUpdatedAt: string | null | undefined;
                    costBasis: number | null | undefined;
                    quantity: number | null | undefined;
                  }>;
                  security:
                    | {
                        __typename: 'Security';
                        id: string;
                        name: string;
                        type: string;
                        ticker: string | null | undefined;
                        typeDisplay: string;
                        currentPrice: number | null | undefined;
                        currentPriceUpdatedAt: string | null | undefined;
                        closingPrice: number | null | undefined;
                        closingPriceUpdatedAt: string | null | undefined;
                        oneDayChangePercent: number | null | undefined;
                        oneDayChangeDollars: number | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >;
    };
  };
};

export type Web_GetHoldingDrawerHistoricalPerformanceQueryVariables = Exact<{
  input: SecurityHistoricalPerformanceInput;
}>;

export type Web_GetHoldingDrawerHistoricalPerformanceQuery = {
  __typename: 'Query';
  securityHistoricalPerformance: Array<{
    __typename: 'SecurityPerformance';
    security: {
      __typename: 'Security';
      id: string;
      currentPrice: number | null | undefined;
      closingPrice: number | null | undefined;
      currentPriceUpdatedAt: string | null | undefined;
      closingPriceUpdatedAt: string | null | undefined;
    };
    historicalChart: Array<{
      __typename: 'SecurityPerformanceChartDatum';
      date: string;
      returnPercent: number;
      value: number;
    }>;
  }>;
};

export type CredentialSettingsCardFieldsFragment = {
  __typename: 'Credential';
  id: string;
  updateRequired: boolean;
  disconnectedFromDataProviderAt: string | null | undefined;
  displayLastUpdatedAt: string | null | undefined;
  dataProvider: DataProviderLegacy;
  institution: {
    __typename: 'Institution';
    id: string;
    name: string;
    logo: string | null | undefined;
    url: string;
    newConnectionsDisabled: boolean;
    hasIssuesReported: boolean;
    hasIssuesReportedMessage: string;
    status: InstitutionStatus | null | undefined;
    balanceStatus: InstitutionStatus | null | undefined;
    transactionsStatus: InstitutionStatus | null | undefined;
  };
};

export type InstitutionRowFieldsFragment = {
  __typename: 'Institution';
  id: string;
  name: string;
  logo: string | null | undefined;
  url: string;
  status: InstitutionStatus | null | undefined;
  newConnectionsDisabled: boolean;
  hasIssuesReported: boolean;
  hasIssuesReportedMessage: string;
  linkFlowWarnBeforeConnecting: boolean;
  linkFlowWarningMessage: string | null | undefined;
  linkFlowWarningTitle: string | null | undefined;
  preferredDataProvider: DataProviderLegacy;
  firstBackupDataProvider: DataProviderLegacy | null | undefined;
  plaidInstitutionId: string | null | undefined;
  finicityInstitutionId: string | null | undefined;
  mxInstitutionId: string | null | undefined;
  largeLogo: { __typename: 'FileType'; url: string | null | undefined } | null | undefined;
};

export type InstitutionStatusTooltipFieldsFragment = {
  __typename: 'Institution';
  id: string;
  logo: string | null | undefined;
  name: string;
  status: InstitutionStatus | null | undefined;
  plaidStatus: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
  newConnectionsDisabled: boolean;
  hasIssuesReported: boolean;
  url: string;
  hasIssuesReportedMessage: string;
  transactionsStatus: InstitutionStatus | null | undefined;
  balanceStatus: InstitutionStatus | null | undefined;
};

export type Web_GetInvestmentsHoldingDrawerHistoricalPerformanceQueryVariables = Exact<{
  input: SecurityHistoricalPerformanceInput;
}>;

export type Web_GetInvestmentsHoldingDrawerHistoricalPerformanceQuery = {
  __typename: 'Query';
  securityHistoricalPerformance: Array<{
    __typename: 'SecurityPerformance';
    security: { __typename: 'Security'; id: string };
    historicalChart: Array<{
      __typename: 'SecurityPerformanceChartDatum';
      date: string;
      returnPercent: number;
      value: number;
    }>;
  }>;
};

export type Web_AdvisorPortalGetSubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type Web_AdvisorPortalGetSubscriptionQuery = {
  __typename: 'Query';
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    paymentMethod:
      | { __typename: 'PaymentMethod'; brand: string; lastFour: string }
      | null
      | undefined;
  };
};

export type Web_GetUserOptedInQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetUserOptedInQuery = {
  __typename: 'Query';
  householdPreferences:
    | {
        __typename: 'HouseholdPreferences';
        id: string | null | undefined;
        aiAssistantEnabled: boolean | null | undefined;
      }
    | null
    | undefined;
  me: {
    __typename: 'User';
    id: string;
    profile:
      | {
          __typename: 'UserProfile';
          id: string;
          aiAssistantOptedInAt: string | null | undefined;
          dismissedSpendingInsightsBanner: boolean | null | undefined;
        }
      | null
      | undefined;
  };
};

export type UpdateDismissedSpendingInsightsBannerMutationVariables = Exact<{
  dismissedSpendingInsightsBanner: Scalars['Boolean']['input'];
}>;

export type UpdateDismissedSpendingInsightsBannerMutation = {
  __typename: 'Mutation';
  updateUserProfile:
    | {
        __typename: 'UpdateUserProfile';
        userProfile:
          | {
              __typename: 'UserProfile';
              dismissedSpendingInsightsBanner: boolean | null | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetMerchantSelectTransactionDetailsQueryVariables = Exact<{
  transactionId: Scalars['UUID']['input'];
}>;

export type Web_GetMerchantSelectTransactionDetailsQuery = {
  __typename: 'Query';
  getTransaction:
    | {
        __typename: 'Transaction';
        id: string;
        dataProviderDescription: string | null | undefined;
        merchant: { __typename: 'Merchant'; id: string; name: string };
      }
    | null
    | undefined;
};

export type Web_GetMerchantSelectHouseholdMerchantsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: InputMaybe<MerchantOrdering>;
  search: InputMaybe<Scalars['String']['input']>;
}>;

export type Web_GetMerchantSelectHouseholdMerchantsQuery = {
  __typename: 'Query';
  merchants: Array<{
    __typename: 'Merchant';
    id: string;
    name: string;
    logoUrl: string | null | undefined;
    transactionCount: number;
  }>;
};

export type Web_GetMerchantSelectRecommendedMerchantsQueryVariables = Exact<{
  transactionId: Scalars['ID']['input'];
}>;

export type Web_GetMerchantSelectRecommendedMerchantsQuery = {
  __typename: 'Query';
  recommendedMerchants:
    | Array<{
        __typename: 'RecommendedMerchant';
        name: string;
        source: string;
        transactionCount: number | null | undefined;
      }>
    | null
    | undefined;
};

export type Web_SearchMerchantsQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;

export type Web_SearchMerchantsQuery = {
  __typename: 'Query';
  merchants: Array<{
    __typename: 'Merchant';
    id: string;
    name: string;
    transactionCount: number;
    logoUrl: string | null | undefined;
  }>;
};

export type Web_OnboardingTopInstitutionsQueryVariables = Exact<{
  dataProviders:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type Web_OnboardingTopInstitutionsQuery = {
  __typename: 'Query';
  credentials: Array<{ __typename: 'Credential'; id: string }>;
  topInstitutionGroups: Array<{
    __typename: 'TopInstitutionGroup';
    institutions: Array<{
      __typename: 'Institution';
      id: string;
      name: string;
      logo: string | null | undefined;
      url: string;
      status: InstitutionStatus | null | undefined;
      newConnectionsDisabled: boolean;
      hasIssuesReported: boolean;
      hasIssuesReportedMessage: string;
      linkFlowWarnBeforeConnecting: boolean;
      linkFlowWarningMessage: string | null | undefined;
      linkFlowWarningTitle: string | null | undefined;
      preferredDataProvider: DataProviderLegacy;
      firstBackupDataProvider: DataProviderLegacy | null | undefined;
      plaidInstitutionId: string | null | undefined;
      finicityInstitutionId: string | null | undefined;
      mxInstitutionId: string | null | undefined;
      largeLogo: { __typename: 'FileType'; url: string | null | undefined } | null | undefined;
    }>;
  }>;
};

export type Web_GetUserReportedOnboardingAttributionChannelsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Web_GetUserReportedOnboardingAttributionChannelsQuery = {
  __typename: 'Query';
  userReportedOnboardingAttributionChannels: Array<{
    __typename: 'OnboardingAttributionItem';
    name: string;
    displayName: string;
  }>;
};

export type Web_SetUserReportedAttributionChannelsMutationVariables = Exact<{
  priorities:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type Web_SetUserReportedAttributionChannelsMutation = {
  __typename: 'Mutation';
  updateUserProfile:
    | {
        __typename: 'UpdateUserProfile';
        userProfile:
          | {
              __typename: 'UserProfile';
              id: string;
              userReportedAttributionChannels: Array<string | null | undefined>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_RecalculateBudgetMutationVariables = Exact<{
  input: ResetBudgetMutationInput;
}>;

export type Web_RecalculateBudgetMutation = {
  __typename: 'Mutation';
  resetBudget:
    | {
        __typename: 'ResetBudgetMutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_ClearAllMutationVariables = Exact<{
  input: ClearBudgetMutationInput;
}>;

export type Web_ClearAllMutation = {
  __typename: 'Mutation';
  clearBudget:
    | {
        __typename: 'ClearBudgetMutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetFlexibleGroupRolloverSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetFlexibleGroupRolloverSettingsQuery = {
  __typename: 'Query';
  budgetSystem: BudgetSystem;
  flexExpenseRolloverPeriod:
    | {
        __typename: 'BudgetRolloverPeriod';
        id: string;
        startMonth: string;
        startingBalance: number | null | undefined;
      }
    | null
    | undefined;
};

export type Web_UpdateFlexibleGroupRolloverSettingsMutationVariables = Exact<{
  input: UpdateBudgetSettingsMutationInput;
}>;

export type Web_UpdateFlexibleGroupRolloverSettingsMutation = {
  __typename: 'Mutation';
  updateBudgetSettings:
    | {
        __typename: 'UpdateBudgetSettingsMutation';
        budgetRolloverPeriod:
          | {
              __typename: 'BudgetRolloverPeriod';
              id: string;
              startMonth: string;
              startingBalance: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_ReviewStreamMutationVariables = Exact<{
  input: ReviewRecurringStreamInput;
}>;

export type Web_ReviewStreamMutation = {
  __typename: 'Mutation';
  reviewRecurringStream:
    | {
        __typename: 'ReviewRecurringStreamMutation';
        stream:
          | {
              __typename: 'RecurringTransactionStream';
              id: string;
              reviewStatus: RecurringStreamReviewStatus | null | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetEventPopoverTransactionsQueryVariables = Exact<{
  merchantId: Scalars['UUID']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type Web_GetEventPopoverTransactionsQuery = {
  __typename: 'Query';
  allTransactions: {
    __typename: 'TransactionList';
    totalCount: number;
    results: Array<{
      __typename: 'Transaction';
      id: string;
      date: string;
      amount: number;
      isRecurring: boolean;
      category: { __typename: 'Category'; id: string; name: string; icon: string };
    }>;
  };
};

export type AccountDetails_GetAccountQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  filters: InputMaybe<TransactionFilterInput>;
}>;

export type AccountDetails_GetAccountQuery = {
  __typename: 'Query';
  account:
    | {
        __typename: 'Account';
        id: string;
        isLiability: boolean;
        displayName: string;
        syncDisabled: boolean;
        deactivatedAt: string | null | undefined;
        isHidden: boolean;
        isAsset: boolean;
        mask: string | null | undefined;
        createdAt: string;
        updatedAt: string;
        displayLastUpdatedAt: string;
        currentBalance: number | null | undefined;
        displayBalance: number | null | undefined;
        includeInNetWorth: boolean;
        hideFromList: boolean;
        hideTransactionsFromReports: boolean;
        includeBalanceInNetWorth: boolean;
        includeInGoalBalance: boolean;
        dataProvider: string | null | undefined;
        dataProviderAccountId: string | null | undefined;
        isManual: boolean;
        transactionsCount: number;
        holdingsCount: number;
        manualInvestmentsTrackingMethod: string | null | undefined;
        order: number;
        icon: string;
        logoUrl: string | null | undefined;
        hasCustomizedLogo: boolean | null | undefined;
        invertSyncedBalance: boolean;
        canInvertBalance: boolean;
        useAvailableBalance: boolean | null | undefined;
        canUseAvailableBalance: boolean;
        credential:
          | {
              __typename: 'Credential';
              id: string;
              hasSyncOrRecentRefreshRequest: boolean;
              canBeForceRefreshed: boolean;
              disconnectedFromDataProviderAt: string | null | undefined;
              dataProvider: DataProviderLegacy;
              updateRequired: boolean;
              institution: {
                __typename: 'Institution';
                id: string;
                plaidInstitutionId: string | null | undefined;
                url: string;
                name: string;
                status: InstitutionStatus | null | undefined;
                logo: string | null | undefined;
                newConnectionsDisabled: boolean;
                hasIssuesReported: boolean;
                hasIssuesReportedMessage: string;
                plaidStatus: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
                balanceStatus: InstitutionStatus | null | undefined;
                transactionsStatus: InstitutionStatus | null | undefined;
              };
            }
          | null
          | undefined;
        institution:
          | {
              __typename: 'Institution';
              id: string;
              plaidInstitutionId: string | null | undefined;
              url: string;
              name: string;
              logo: string | null | undefined;
              primaryColor: string;
              newConnectionsDisabled: boolean;
              hasIssuesReported: boolean;
              hasIssuesReportedMessage: string;
              plaidStatus: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
              status: InstitutionStatus | null | undefined;
              balanceStatus: InstitutionStatus | null | undefined;
              transactionsStatus: InstitutionStatus | null | undefined;
            }
          | null
          | undefined;
        type: { __typename: 'AccountType'; name: string; display: string; group: string };
        subtype: { __typename: 'AccountSubtype'; display: string; name: string };
        creditReportLiabilityAccount:
          | {
              __typename: 'CreditReportLiabilityAccount';
              id: string;
              lastStatement:
                | { __typename: 'LiabilityStatement'; id: string; dueDate: string }
                | null
                | undefined;
              recurringTransactionStream:
                | {
                    __typename: 'RecurringTransactionStream';
                    id: string;
                    isActive: boolean;
                    dayOfTheMonth: number | null | undefined;
                    frequency: string;
                    defaultPaymentAccount:
                      | { __typename: 'Account'; id: string; name: string | null | undefined }
                      | null
                      | undefined;
                    defaultPaymentCategory:
                      | { __typename: 'Category'; id: string; name: string }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  transactions: {
    __typename: 'TransactionList';
    totalCount: number;
    results: Array<{
      __typename: 'Transaction';
      id: string;
      amount: number;
      pending: boolean;
      date: string;
      hideFromReports: boolean;
      plaidName: string | null | undefined;
      notes: string | null | undefined;
      isRecurring: boolean;
      reviewStatus: string | null | undefined;
      needsReview: boolean;
      isSplitTransaction: boolean;
      dataProviderDescription: string | null | undefined;
      attachments: Array<{ __typename: 'TransactionAttachment'; id: string }>;
      category: {
        __typename: 'Category';
        id: string;
        name: string;
        icon: string;
        group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
      };
      merchant: {
        __typename: 'Merchant';
        name: string;
        id: string;
        transactionsCount: number;
        logoUrl: string | null | undefined;
        recurringTransactionStream:
          | { __typename: 'RecurringTransactionStream'; frequency: string; isActive: boolean }
          | null
          | undefined;
      };
      tags: Array<{
        __typename: 'TransactionTag';
        id: string;
        name: string;
        color: string;
        order: number;
      }>;
      account: {
        __typename: 'Account';
        id: string;
        displayName: string;
        icon: string;
        logoUrl: string | null | undefined;
      };
    }>;
  };
  snapshots: Array<{ __typename: 'AccountSnapshot'; date: string; signedBalance: number }>;
};

export type Web_GetAccountsPageQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetAccountsPageQuery = {
  __typename: 'Query';
  hasAccounts: boolean;
  accountTypeSummaries: Array<{
    __typename: 'AccountTypeSummary';
    isAsset: boolean;
    totalDisplayBalance: number;
    type: { __typename: 'AccountType'; name: string; display: string; group: string };
    accounts: Array<{
      __typename: 'Account';
      id: string;
      syncDisabled: boolean;
      isHidden: boolean;
      isAsset: boolean;
      includeInNetWorth: boolean;
      order: number;
      displayName: string;
      displayBalance: number | null | undefined;
      signedBalance: number | null | undefined;
      updatedAt: string;
      icon: string;
      logoUrl: string | null | undefined;
      includeBalanceInNetWorth: boolean;
      displayLastUpdatedAt: string;
      mask: string | null | undefined;
      type: { __typename: 'AccountType'; name: string; display: string };
      credential:
        | {
            __typename: 'Credential';
            id: string;
            updateRequired: boolean;
            dataProvider: DataProviderLegacy;
            disconnectedFromDataProviderAt: string | null | undefined;
          }
        | null
        | undefined;
      institution:
        | {
            __typename: 'Institution';
            id: string;
            logo: string | null | undefined;
            name: string;
            status: InstitutionStatus | null | undefined;
            plaidStatus: { [key: string]: Scalars['GenericScalar'] } | null | undefined;
            newConnectionsDisabled: boolean;
            hasIssuesReported: boolean;
            url: string;
            hasIssuesReportedMessage: string;
            transactionsStatus: InstitutionStatus | null | undefined;
            balanceStatus: InstitutionStatus | null | undefined;
          }
        | null
        | undefined;
      subtype: { __typename: 'AccountSubtype'; display: string };
    }>;
  }>;
  householdPreferences:
    | {
        __typename: 'HouseholdPreferences';
        id: string | null | undefined;
        accountGroupOrder: Array<string>;
      }
    | null
    | undefined;
};

export type Web_GetAccountsPageRecentBalanceQueryVariables = Exact<{
  startDate: InputMaybe<Scalars['Date']['input']>;
}>;

export type Web_GetAccountsPageRecentBalanceQuery = {
  __typename: 'Query';
  accounts: Array<{
    __typename: 'Account';
    id: string;
    recentBalances: Array<number | null | undefined>;
    includeInNetWorth: boolean;
    type: { __typename: 'AccountType'; name: string; display: string; group: string };
  }>;
};

export type Web_GetAggregateSnapshotsQueryVariables = Exact<{
  filters: InputMaybe<AggregateSnapshotFilters>;
}>;

export type Web_GetAggregateSnapshotsQuery = {
  __typename: 'Query';
  aggregateSnapshots: Array<{
    __typename: 'AggregateSnapshot';
    date: string;
    balance: number;
    assetsBalance: number | null | undefined;
    liabilitiesBalance: number | null | undefined;
  }>;
};

export type Web_GetMessageThreadsQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetMessageThreadsQuery = {
  __typename: 'Query';
  messageThreads: Array<{
    __typename: 'MessageThread';
    id: string;
    subject: string | null | undefined;
    createdAt: string;
    lastMessageSentAt: string | null | undefined;
    hasOutstandingAssistantRequests: boolean;
  }>;
  householdPreferences:
    | {
        __typename: 'HouseholdPreferences';
        id: string | null | undefined;
        aiAssistantEnabled: boolean | null | undefined;
      }
    | null
    | undefined;
  me: {
    __typename: 'User';
    id: string;
    profile:
      | { __typename: 'UserProfile'; id: string; aiAssistantOptedInAt: string | null | undefined }
      | null
      | undefined;
  };
};

export type Web_GetMessageThreadQueryVariables = Exact<{
  threadId: Scalars['ID']['input'];
}>;

export type Web_GetMessageThreadQuery = {
  __typename: 'Query';
  messageThread:
    | {
        __typename: 'MessageThread';
        id: string;
        subject: string | null | undefined;
        hasOutstandingAssistantRequests: boolean;
        messages: Array<
          | {
              __typename: 'AssistantMessage';
              id: string;
              threadId: string;
              createdAt: string;
              content: string;
              name: string;
              suggestedPrompts: Array<string> | null | undefined;
              debugInfo: string | null | undefined;
            }
          | {
              __typename: 'AssistantStatusMessage';
              id: string;
              createdAt: string;
              threadId: string;
              content: string;
              name: string;
            }
          | {
              __typename: 'UserMessage';
              id: string;
              threadId: string;
              createdAt: string;
              content: string;
              user: {
                __typename: 'User';
                id: string;
                name: string;
                profilePictureUrl: string | null | undefined;
              };
            }
        >;
      }
    | null
    | undefined;
};

export type Web_OnMessageUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type Web_OnMessageUpdatedSubscription = {
  __typename: 'Subscription';
  onMessageUpdated:
    | {
        __typename: 'MessageUpdatedSubscription';
        message:
          | {
              __typename: 'AssistantMessage';
              id: string;
              threadId: string;
              createdAt: string;
              content: string;
              name: string;
              suggestedPrompts: Array<string> | null | undefined;
              debugInfo: string | null | undefined;
            }
          | {
              __typename: 'AssistantStatusMessage';
              id: string;
              createdAt: string;
              threadId: string;
              content: string;
              name: string;
            }
          | {
              __typename: 'UserMessage';
              id: string;
              threadId: string;
              createdAt: string;
              content: string;
              user: {
                __typename: 'User';
                id: string;
                name: string;
                profilePictureUrl: string | null | undefined;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_OnMessageCreatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type Web_OnMessageCreatedSubscription = {
  __typename: 'Subscription';
  onMessageCreated:
    | {
        __typename: 'MessageCreatedSubscription';
        message:
          | {
              __typename: 'AssistantMessage';
              id: string;
              threadId: string;
              createdAt: string;
              content: string;
              name: string;
              suggestedPrompts: Array<string> | null | undefined;
              debugInfo: string | null | undefined;
            }
          | {
              __typename: 'AssistantStatusMessage';
              id: string;
              createdAt: string;
              threadId: string;
              content: string;
              name: string;
            }
          | {
              __typename: 'UserMessage';
              id: string;
              threadId: string;
              createdAt: string;
              content: string;
              user: {
                __typename: 'User';
                id: string;
                name: string;
                profilePictureUrl: string | null | undefined;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_OnMessageThreadUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type Web_OnMessageThreadUpdatedSubscription = {
  __typename: 'Subscription';
  onMessageThreadUpdated:
    | {
        __typename: 'MessageThreadUpdatedSubscription';
        messageThread:
          | {
              __typename: 'MessageThread';
              id: string;
              subject: string | null | undefined;
              createdAt: string;
              lastMessageSentAt: string | null | undefined;
              hasOutstandingAssistantRequests: boolean;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_CashFlowAggregatesQueryVariables = Exact<{
  filters: InputMaybe<TransactionFilterInput>;
}>;

export type Web_CashFlowAggregatesQuery = {
  __typename: 'Query';
  byYear: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; year: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      savings: number;
      savingsRate: number;
      sumIncome: number;
      sumExpense: number;
    };
  }>;
  byMonth: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; month: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      savings: number;
      savingsRate: number;
      sumIncome: number;
      sumExpense: number;
    };
  }>;
  byQuarter: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; quarter: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      savings: number;
      savingsRate: number;
      sumIncome: number;
      sumExpense: number;
    };
  }>;
};

export type Web_GetCashFlowPageQueryVariables = Exact<{
  filters: InputMaybe<TransactionFilterInput>;
}>;

export type Web_GetCashFlowPageQuery = {
  __typename: 'Query';
  byCategory: Array<{
    __typename: 'AggregateData';
    groupBy: {
      __typename: 'AggregateGroupBy';
      category:
        | {
            __typename: 'Category';
            id: string;
            name: string;
            icon: string;
            group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
          }
        | null
        | undefined;
    };
    summary: { __typename: 'TransactionsSummary'; sum: number };
  }>;
  byCategoryGroup: Array<{
    __typename: 'AggregateData';
    groupBy: {
      __typename: 'AggregateGroupBy';
      categoryGroup:
        | { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType }
        | null
        | undefined;
    };
    summary: { __typename: 'TransactionsSummary'; sum: number };
  }>;
  byMerchant: Array<{
    __typename: 'AggregateData';
    groupBy: {
      __typename: 'AggregateGroupBy';
      merchant:
        | { __typename: 'Merchant'; id: string; name: string; logoUrl: string | null | undefined }
        | null
        | undefined;
    };
    summary: { __typename: 'TransactionsSummary'; sumIncome: number; sumExpense: number };
  }>;
  summary: Array<{
    __typename: 'AggregateData';
    summary: {
      __typename: 'TransactionsSummary';
      sumIncome: number;
      sumExpense: number;
      savings: number;
      savingsRate: number;
    };
  }>;
};

export type Web_CashFlowSankeyAggregatesQueryVariables = Exact<{
  filters: InputMaybe<TransactionFilterInput>;
}>;

export type Web_CashFlowSankeyAggregatesQuery = {
  __typename: 'Query';
  byYear: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; year: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      savings: number;
      savingsRate: number;
      sumIncome: number;
      sumExpense: number;
    };
  }>;
  byMonth: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; month: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      savings: number;
      savingsRate: number;
      sumIncome: number;
      sumExpense: number;
    };
  }>;
  byQuarter: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; quarter: string | null | undefined };
    summary: {
      __typename: 'TransactionsSummary';
      savings: number;
      savingsRate: number;
      sumIncome: number;
      sumExpense: number;
    };
  }>;
};

export type GetCashFlowSankeyPageQueryVariables = Exact<{
  filters: InputMaybe<TransactionFilterInput>;
}>;

export type GetCashFlowSankeyPageQuery = {
  __typename: 'Query';
  byCategory: Array<{
    __typename: 'AggregateData';
    groupBy: {
      __typename: 'AggregateGroupBy';
      category:
        | {
            __typename: 'Category';
            id: string;
            name: string;
            icon: string;
            group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
          }
        | null
        | undefined;
    };
    summary: { __typename: 'TransactionsSummary'; sum: number };
  }>;
  byCategoryGroup: Array<{
    __typename: 'AggregateData';
    groupBy: {
      __typename: 'AggregateGroupBy';
      categoryGroup:
        | { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType }
        | null
        | undefined;
    };
    summary: { __typename: 'TransactionsSummary'; sum: number };
  }>;
  byMerchant: Array<{
    __typename: 'AggregateData';
    groupBy: {
      __typename: 'AggregateGroupBy';
      merchant:
        | { __typename: 'Merchant'; id: string; name: string; logoUrl: string | null | undefined }
        | null
        | undefined;
    };
    summary: { __typename: 'TransactionsSummary'; sumIncome: number; sumExpense: number };
  }>;
  summary: Array<{
    __typename: 'AggregateData';
    summary: {
      __typename: 'TransactionsSummary';
      sumIncome: number;
      sumExpense: number;
      savings: number;
      savingsRate: number;
    };
  }>;
};

export type GetCategoryDetailsQueryVariables = Exact<{
  id: InputMaybe<Scalars['UUID']['input']>;
  month: Scalars['Date']['input'];
  includeBudgetAmounts: Scalars['Boolean']['input'];
}>;

export type GetCategoryDetailsQuery = {
  __typename: 'Query';
  category:
    | {
        __typename: 'Category';
        id: string;
        order: number;
        name: string;
        icon: string;
        isSystemCategory: boolean;
        systemCategory: string | null | undefined;
        excludeFromBudget: boolean | null | undefined;
        isDisabled: boolean;
        group: { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType };
        rolloverPeriod:
          | {
              __typename: 'BudgetRolloverPeriod';
              id: string;
              startingBalance: number | null | undefined;
            }
          | null
          | undefined;
        budgetAmountsForMonth?:
          | {
              __typename: 'BudgetMonthlyAmounts';
              month: string;
              plannedAmount: number | null | undefined;
              actualAmount: number | null | undefined;
              remainingAmount: number | null | undefined;
              previousMonthRolloverAmount: number | null | undefined;
              rolloverType: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetCategoryGroupDetailsQueryVariables = Exact<{
  id: InputMaybe<Scalars['UUID']['input']>;
  month: Scalars['Date']['input'];
  includeBudgetAmounts: Scalars['Boolean']['input'];
}>;

export type Web_GetCategoryGroupDetailsQuery = {
  __typename: 'Query';
  categoryGroup:
    | {
        __typename: 'CategoryGroup';
        id: string;
        name: string;
        type: CategoryGroupType;
        groupLevelBudgetingEnabled: boolean | null | undefined;
        rolloverPeriod:
          | {
              __typename: 'BudgetRolloverPeriod';
              id: string;
              startingBalance: number | null | undefined;
            }
          | null
          | undefined;
        budgetAmountsForMonth?:
          | {
              __typename: 'BudgetMonthlyAmounts';
              month: string;
              plannedAmount: number | null | undefined;
              actualAmount: number | null | undefined;
              remainingAmount: number | null | undefined;
              previousMonthRolloverAmount: number | null | undefined;
              rolloverType: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_DirectLinkAccountSelectDataProviderQueryVariables = Exact<{
  institutionId: Scalars['String']['input'];
}>;

export type Web_DirectLinkAccountSelectDataProviderQuery = {
  __typename: 'Query';
  institution:
    | {
        __typename: 'Institution';
        id: string;
        name: string;
        plaidInstitutionId: string | null | undefined;
        finicityInstitutionId: string | null | undefined;
        mxInstitutionId: string | null | undefined;
        preferredDataProvider: DataProviderLegacy;
        firstBackupDataProvider: DataProviderLegacy | null | undefined;
        logo: string | null | undefined;
        status: InstitutionStatus | null | undefined;
        newConnectionsDisabled: boolean;
        hasIssuesReported: boolean;
        hasIssuesReportedMessage: string;
        linkFlowWarnBeforeConnecting: boolean;
        linkFlowWarningMessage: string | null | undefined;
        linkFlowWarningTitle: string | null | undefined;
      }
    | null
    | undefined;
};

export type Web_GetFilteredCashFlowPageQueryVariables = Exact<{
  categories: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
  categoryGroups: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
  accounts: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
  merchants: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
  startDate: InputMaybe<Scalars['Date']['input']>;
  endDate: InputMaybe<Scalars['Date']['input']>;
  tags: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type Web_GetFilteredCashFlowPageQuery = {
  __typename: 'Query';
  summary: Array<{
    __typename: 'AggregateData';
    summary: {
      __typename: 'TransactionsSummary';
      count: number;
      max: number;
      avg: number | null | undefined;
      sum: number;
    };
  }>;
  byYear: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; year: string | null | undefined };
    summary: { __typename: 'TransactionsSummary'; sum: number };
  }>;
  byMonth: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; month: string | null | undefined };
    summary: { __typename: 'TransactionsSummary'; sum: number };
  }>;
  byQuarter: Array<{
    __typename: 'AggregateData';
    groupBy: { __typename: 'AggregateGroupBy'; quarter: string | null | undefined };
    summary: { __typename: 'TransactionsSummary'; sum: number };
  }>;
};

export type GetMerchantDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetMerchantDetailsQuery = {
  __typename: 'Query';
  merchant:
    | { __typename: 'Merchant'; id: string; name: string; logoUrl: string | null | undefined }
    | null
    | undefined;
};

export type Web_GetSidebarDataQueryVariables = Exact<{
  promoCode: InputMaybe<Scalars['String']['input']>;
}>;

export type Web_GetSidebarDataQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    name: string;
    email: string;
    profilePictureUrl: string | null | undefined;
    profilePicture:
      | {
          __typename: 'UserProfilePicture';
          id: string;
          cloudinaryPublicId: string | null | undefined;
        }
      | null
      | undefined;
  };
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    billingPeriod: PaymentPeriod | null | undefined;
    paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
    isOnFreeTrial: boolean;
    hasPremiumEntitlement: boolean;
    nextPaymentAmount: number | null | undefined;
    trialEndsAt: string | null | undefined;
    trialDurationDays: number;
    trialType: TrialType | null | undefined;
    activeSponsorship: { __typename: 'SubscriptionSponsorshipInfo'; id: string } | null | undefined;
  };
  subscriptionOffering: {
    __typename: 'StripeSubscriptionOffering';
    promoCodeError: string | null | undefined;
    promoCodeDescription: string | null | undefined;
    plans: Array<{
      __typename: 'SubscriptionPlan';
      period: PaymentPeriod;
      pricePerPeriodDollars: number;
    }>;
  };
};

export type Web_GetTransactionsPageQueryVariables = Exact<{
  filters: InputMaybe<TransactionFilterInput>;
}>;

export type Web_GetTransactionsPageQuery = {
  __typename: 'Query';
  aggregates: Array<{
    __typename: 'AggregateData';
    summary: {
      __typename: 'TransactionsSummary';
      avg: number | null | undefined;
      count: number;
      max: number;
      maxExpense: number;
      sum: number;
      sumIncome: number;
      sumExpense: number;
      first: string | null | undefined;
      last: string | null | undefined;
    };
  }>;
};

export type AdviceQuery_WebQueryVariables = Exact<{
  categoryName: InputMaybe<Scalars['String']['input']>;
}>;

export type AdviceQuery_WebQuery = {
  __typename: 'Query';
  essentials:
    | Array<{
        __typename: 'AdviceItem';
        id: string;
        title: string;
        description: string | null | undefined;
        numTasksCompleted: number;
        numTasksRemaining: number;
        numTasks: number;
        completedAt: string | null | undefined;
        category: {
          __typename: 'AdviceItemCategory';
          name: string;
          displayName: string;
          color: string;
        };
      }>
    | null
    | undefined;
  objectives:
    | Array<{
        __typename: 'AdviceItem';
        id: string;
        title: string;
        description: string | null | undefined;
        numTasksCompleted: number;
        numTasksRemaining: number;
        numTasks: number;
        completedAt: string | null | undefined;
        category: {
          __typename: 'AdviceItemCategory';
          name: string;
          displayName: string;
          color: string;
        };
      }>
    | null
    | undefined;
  adviceItemCategories: Array<{
    __typename: 'AdviceItemCategory';
    name: string;
    displayName: string;
    description: string;
  }>;
  profileQuestionnaire:
    | {
        __typename: 'ProfileQuestionnaire';
        state: {
          __typename: 'ProfileQuestionnaireState';
          numQuestionsAnswered: number;
          completed: boolean;
        };
        firstQuestion: { __typename: 'ProfileQuestion'; name: string } | null | undefined;
      }
    | null
    | undefined;
  objectivesQuestionnaire:
    | {
        __typename: 'ProfileQuestionnaire';
        firstQuestion: { __typename: 'ProfileQuestion'; name: string } | null | undefined;
      }
    | null
    | undefined;
};

export type StateFieldsFragment = {
  __typename: 'ProfileQuestionnaireState';
  numQuestionsAnswered: number;
  numQuestions: number;
  completed: boolean;
};

export type QuestionFieldsFragment = {
  __typename: 'ProfileQuestion';
  index: number | null | undefined;
  title: string;
  description: string | null | undefined;
  name: string;
  type: string;
  required: boolean;
  options:
    | Array<{ __typename: 'ProfileQuestionOption'; value: string; label: string }>
    | null
    | undefined;
  answer:
    | {
        __typename: 'ProfileQuestionAnswer';
        answer: string | null | undefined;
        dontKnow: boolean;
        answeredAt: string | null | undefined;
      }
    | null
    | undefined;
};

export type Web_AdviceQuestionnaireQueryVariables = Exact<{
  questionnaireName: Scalars['String']['input'];
  questionName: Scalars['String']['input'];
}>;

export type Web_AdviceQuestionnaireQuery = {
  __typename: 'Query';
  profileQuestionnaire:
    | {
        __typename: 'ProfileQuestionnaire';
        name: string;
        state: {
          __typename: 'ProfileQuestionnaireState';
          numQuestionsAnswered: number;
          numQuestions: number;
          completed: boolean;
        };
        question:
          | {
              __typename: 'ProfileQuestion';
              index: number | null | undefined;
              title: string;
              description: string | null | undefined;
              name: string;
              type: string;
              required: boolean;
              options:
                | Array<{ __typename: 'ProfileQuestionOption'; value: string; label: string }>
                | null
                | undefined;
              answer:
                | {
                    __typename: 'ProfileQuestionAnswer';
                    answer: string | null | undefined;
                    dontKnow: boolean;
                    answeredAt: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_AnswerQuestionMutationVariables = Exact<{
  input: AnswerProfileQuestionInput;
}>;

export type Web_AnswerQuestionMutation = {
  __typename: 'Mutation';
  answerProfileQuestion:
    | {
        __typename: 'AnswerProfileQuestion';
        success: boolean | null | undefined;
        nextQuestion:
          | {
              __typename: 'ProfileQuestion';
              index: number | null | undefined;
              title: string;
              description: string | null | undefined;
              name: string;
              type: string;
              required: boolean;
              options:
                | Array<{ __typename: 'ProfileQuestionOption'; value: string; label: string }>
                | null
                | undefined;
              answer:
                | {
                    __typename: 'ProfileQuestionAnswer';
                    answer: string | null | undefined;
                    dontKnow: boolean;
                    answeredAt: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        questionnaireState:
          | {
              __typename: 'ProfileQuestionnaireState';
              numQuestionsAnswered: number;
              numQuestions: number;
              completed: boolean;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type QuestionnaireIntroQuery_WebQueryVariables = Exact<{
  questionnaireName: Scalars['String']['input'];
}>;

export type QuestionnaireIntroQuery_WebQuery = {
  __typename: 'Query';
  me: { __typename: 'User'; id: string; name: string };
  profileQuestionnaire:
    | {
        __typename: 'ProfileQuestionnaire';
        nextQuestion: { __typename: 'ProfileQuestion'; name: string } | null | undefined;
        state: { __typename: 'ProfileQuestionnaireState'; numQuestionsAnswered: number };
      }
    | null
    | undefined;
};

export type Web_GetAdvisorBillingPageQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetAdvisorBillingPageQuery = {
  __typename: 'Query';
  creditBalance: number | null | undefined;
  subscriptionSponsorships:
    | Array<{
        __typename: 'SubscriptionSponsorship';
        id: string;
        status: string | null | undefined;
      }>
    | null
    | undefined;
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    trialEndsAt: string | null | undefined;
    trialType: TrialType | null | undefined;
    isOnFreeTrial: boolean;
    hasChargedForLifetime: boolean;
    currentPeriodEndsAt: string | null | undefined;
    billingPeriod: PaymentPeriod | null | undefined;
    nextPaymentAmount: number | null | undefined;
    hasStripeSubscriptionId: boolean;
    hasPremiumEntitlement: boolean;
    entitlements: Array<SubscriptionEntitlement>;
    eligibleForTrial: boolean;
    willCancelAtPeriodEnd: boolean;
    paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
    hasBillingIssue: boolean;
    isSponsor: boolean;
    paymentMethod:
      | { __typename: 'PaymentMethod'; lastFour: string; brand: string }
      | null
      | undefined;
    activePromoCode:
      | { __typename: 'PromoCode'; code: string; description: string }
      | null
      | undefined;
    sponsoredBy:
      | {
          __typename: 'SubscriptionSponsorInfo';
          name: string;
          email: string;
          profilePictureUrl: string | null | undefined;
        }
      | null
      | undefined;
  };
  sponsorInvoices:
    | Array<{
        __typename: 'StripeInvoice';
        id: string;
        date: string;
        amount: number;
        receiptUrl: string | null | undefined;
      }>
    | null
    | undefined;
  upcomingSponsorInvoice: { __typename: 'UpcomingStripeInvoice'; date: string; amount: number };
  constants: { __typename: 'Constants'; monthlyPriceDollars: number };
};

export type Web_UpdateOrCreateAdvisorStripePaymentMethodMutationVariables = Exact<{
  input: UpdateOrCreateStripePaymentMethodMutationInput;
}>;

export type Web_UpdateOrCreateAdvisorStripePaymentMethodMutation = {
  __typename: 'Mutation';
  updateOrCreateStripePaymentMethod:
    | {
        __typename: 'UpdateOrCreateStripePaymentMethodMutation';
        subscription:
          | {
              __typename: 'HouseholdSubscription';
              id: string;
              trialEndsAt: string | null | undefined;
              trialType: TrialType | null | undefined;
              isOnFreeTrial: boolean;
              hasChargedForLifetime: boolean;
              currentPeriodEndsAt: string | null | undefined;
              billingPeriod: PaymentPeriod | null | undefined;
              nextPaymentAmount: number | null | undefined;
              hasStripeSubscriptionId: boolean;
              hasPremiumEntitlement: boolean;
              entitlements: Array<SubscriptionEntitlement>;
              eligibleForTrial: boolean;
              willCancelAtPeriodEnd: boolean;
              paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
              hasBillingIssue: boolean;
              isSponsor: boolean;
              paymentMethod:
                | { __typename: 'PaymentMethod'; lastFour: string; brand: string }
                | null
                | undefined;
              activePromoCode:
                | { __typename: 'PromoCode'; code: string; description: string }
                | null
                | undefined;
              sponsoredBy:
                | {
                    __typename: 'SubscriptionSponsorInfo';
                    name: string;
                    email: string;
                    profilePictureUrl: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetAdvisorClientsPageQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetAdvisorClientsPageQuery = {
  __typename: 'Query';
  subscriptionSponsor:
    | { __typename: 'SubscriptionSponsor'; id: string; name: string }
    | null
    | undefined;
  me: { __typename: 'User'; email: string };
  subscriptionSponsorshipInvites:
    | Array<{
        __typename: 'SubscriptionSponsorshipInvite';
        id: string;
        code: string;
        label: string | null | undefined;
        sentToEmail: string | null | undefined;
        status: string;
        createdAt: string;
        sponsorship:
          | {
              __typename: 'SubscriptionSponsorship';
              id: string;
              status: string | null | undefined;
              household:
                | {
                    __typename: 'SponsoredHouseholdInfo';
                    id: string;
                    name: string;
                    profilePictureUrl: string | null | undefined;
                    lastActivityAt: string | null | undefined;
                    owner: { __typename: 'User'; id: string } | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
  subscriptionSponsorAccessGrants:
    | Array<{
        __typename: 'HouseholdAccessGrant';
        id: string;
        toEmail: string;
        householdGivingAccessName: string;
        userGivingAccessId: string;
        expiresAt: string;
      }>
    | null
    | undefined;
  subscriptionSponsorshipDemoAccount:
    | { __typename: 'SubscriptionSponsorshipDemoAccount'; userId: string }
    | null
    | undefined;
};

export type Web_GetAdvisorProfilePageQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetAdvisorProfilePageQuery = {
  __typename: 'Query';
  subscriptionSponsor:
    | { __typename: 'SubscriptionSponsor'; id: string; name: string }
    | null
    | undefined;
};

export type Web_UpdateSubscriptionSponsorMutationVariables = Exact<{
  input: UpdateSponsorInput;
}>;

export type Web_UpdateSubscriptionSponsorMutation = {
  __typename: 'Mutation';
  updateSubscriptionSponsor:
    | {
        __typename: 'UpdateSponsor';
        sponsor: { __typename: 'SubscriptionSponsor'; id: string; name: string } | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_BudgetOnboardingGetAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type Web_BudgetOnboardingGetAccountsQuery = {
  __typename: 'Query';
  visibleAccounts: Array<{ __typename: 'Account'; id: string }>;
  accountTypeSummaries: Array<{
    __typename: 'AccountTypeSummary';
    isAsset: boolean;
    totalDisplayBalance: number;
    type: { __typename: 'AccountType'; name: string };
    accounts: Array<{
      __typename: 'Account';
      id: string;
      displayName: string;
      displayBalance: number | null | undefined;
      icon: string;
      logoUrl: string | null | undefined;
    }>;
  }>;
};

export type Web_UpdateCategoryFromBudgetOnboardingMutationVariables = Exact<{
  input: UpdateCategoryInput;
}>;

export type Web_UpdateCategoryFromBudgetOnboardingMutation = {
  __typename: 'Mutation';
  updateCategory:
    | {
        __typename: 'UpdateCategoryPayload';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        category:
          | {
              __typename: 'Category';
              id: string;
              order: number;
              name: string;
              icon: string;
              systemCategory: string | null | undefined;
              systemCategoryDisplayName: string | null | undefined;
              budgetVariability: BudgetVariability | null | undefined;
              excludeFromBudget: boolean | null | undefined;
              isSystemCategory: boolean;
              isDisabled: boolean;
              group: {
                __typename: 'CategoryGroup';
                id: string;
                type: CategoryGroupType;
                groupLevelBudgetingEnabled: boolean | null | undefined;
              };
              rolloverPeriod:
                | {
                    __typename: 'BudgetRolloverPeriod';
                    id: string;
                    startMonth: string;
                    startingBalance: number | null | undefined;
                    type: string;
                    frequency: string | null | undefined;
                    targetAmount: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type WebGoalAccountBalanceRowFieldsFragment = {
  __typename: 'GoalAccountAllocation';
  id: string;
  currentAmount: number | null | undefined;
  currentMonthChange:
    | {
        __typename: 'GoalAccountAllocationCurrentMonthChange';
        percent: number | null | undefined;
        amount: number | null | undefined;
      }
    | null
    | undefined;
  account: {
    __typename: 'Account';
    id: string;
    displayName: string;
    hideFromList: boolean;
    deactivatedAt: string | null | undefined;
    icon: string;
    logoUrl: string | null | undefined;
    mask: string | null | undefined;
    type: { __typename: 'AccountType'; name: string };
    subtype: { __typename: 'AccountSubtype'; display: string };
  };
};

export type Web_GoalDetailV2QueryVariables = Exact<{
  goalId: Scalars['ID']['input'];
}>;

export type Web_GoalDetailV2Query = {
  __typename: 'Query';
  goalV2:
    | {
        __typename: 'GoalV2';
        id: string;
        imageStorageProvider: string | null | undefined;
        imageStorageProviderId: string | null | undefined;
        archivedAt: string | null | undefined;
        priority: number;
        plannedMonthlyContribution: number | null | undefined;
        plannedMonthlyPretaxContribution: number | null | undefined;
        targetAmount: number | null | undefined;
        startingAmount: number | null | undefined;
        type: string;
        objective: string;
        defaultName: string | null | undefined;
        name: string;
        currentAmount: number | null | undefined;
        completedAt: string | null | undefined;
        completionPercent: number | null | undefined;
        accountAllocations: Array<{
          __typename: 'GoalAccountAllocation';
          id: string;
          currentAmount: number | null | undefined;
          account: {
            __typename: 'Account';
            id: string;
            logoUrl: string | null | undefined;
            icon: string;
            displayName: string;
            hideFromList: boolean;
            deactivatedAt: string | null | undefined;
            mask: string | null | undefined;
            type: { __typename: 'AccountType'; name: string };
            subtype: { __typename: 'AccountSubtype'; display: string };
          };
          currentMonthChange:
            | {
                __typename: 'GoalAccountAllocationCurrentMonthChange';
                percent: number | null | undefined;
                amount: number | null | undefined;
              }
            | null
            | undefined;
        }>;
        monthlyContributionSummaries: Array<{
          __typename: 'GoalContributionMonthlySummary';
          month: string;
          sum: number;
          sumCredit: number;
          sumDebit: number;
          plannedAmount: number | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type Web_GoalsMapAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GoalsMapAccountsQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    name: string;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
    targetAmount: number | null | undefined;
    startingAmount: number | null | undefined;
    currentAmount: number | null | undefined;
    completionPercent: number | null | undefined;
    type: string;
    defaultName: string | null | undefined;
    accountAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      account: {
        __typename: 'Account';
        id: string;
        logoUrl: string | null | undefined;
        icon: string;
        dataProvider: string | null | undefined;
        type: { __typename: 'AccountType'; name: string };
        subtype: { __typename: 'AccountSubtype'; name: string };
        institution:
          | {
              __typename: 'Institution';
              id: string;
              logo: string | null | undefined;
              primaryColor: string;
            }
          | null
          | undefined;
      };
    }>;
  }>;
};

export type Web_GoalsMonthlyBudgetsQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type Web_GoalsMonthlyBudgetsQuery = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    plannedMonthlyContribution: number | null | undefined;
    objective: string;
    name: string;
    priority: number;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
  }>;
  aggregates: Array<{
    __typename: 'AggregateData';
    summary: { __typename: 'TransactionsSummary'; savings: number };
  }>;
};

export type Web_GoalsV2QueryVariables = Exact<{ [key: string]: never }>;

export type Web_GoalsV2Query = {
  __typename: 'Query';
  goalsV2: Array<{
    __typename: 'GoalV2';
    id: string;
    imageStorageProvider: string | null | undefined;
    imageStorageProviderId: string | null | undefined;
    priority: number;
    archivedAt: string | null | undefined;
    name: string;
    defaultName: string | null | undefined;
    targetAmount: number | null | undefined;
    startingAmount: number | null | undefined;
    currentAmount: number | null | undefined;
    completedAt: string | null | undefined;
    type: string;
    completionPercent: number | null | undefined;
    accountAllocations: Array<{
      __typename: 'GoalAccountAllocation';
      id: string;
      account: {
        __typename: 'Account';
        id: string;
        logoUrl: string | null | undefined;
        icon: string;
      };
    }>;
  }>;
  accountsWithUnallocatedBalancesForGoals: Array<{
    __typename: 'Account';
    id: string;
    name: string | null | undefined;
    logoUrl: string | null | undefined;
    icon: string;
  }>;
};

export type Web_GetInvestmentsAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetInvestmentsAccountsQuery = {
  __typename: 'Query';
  accounts: Array<{
    __typename: 'Account';
    id: string;
    displayName: string;
    isTaxable: boolean;
    icon: string;
    order: number;
    logoUrl: string | null | undefined;
    includeInNetWorth: boolean;
    syncDisabled: boolean;
    subtype: { __typename: 'AccountSubtype'; display: string };
  }>;
};

export type Web_GetAllocationQueryVariables = Exact<{
  porfolioInput: InputMaybe<PortfolioInput>;
}>;

export type Web_GetAllocationQuery = {
  __typename: 'Query';
  portfolio: {
    __typename: 'Portfolio';
    allocationSimple: Array<
      | {
          __typename: 'SimplePortfolioAliquot';
          type: string;
          typeDisplay: string;
          allocationPercent: number;
          totalValue: number;
        }
      | null
      | undefined
    >;
    performance: { __typename: 'PortfolioPerformance'; totalValue: number };
  };
};

export type Web_GetPortfolioQueryVariables = Exact<{
  portfolioInput: InputMaybe<PortfolioInput>;
}>;

export type Web_GetPortfolioQuery = {
  __typename: 'Query';
  portfolio: {
    __typename: 'Portfolio';
    performance: {
      __typename: 'PortfolioPerformance';
      totalValue: number;
      totalBasis: number;
      totalChangePercent: number;
      totalChangeDollars: number;
      oneDayChangePercent: number;
      historicalChart: Array<{
        __typename: 'SecurityPerformanceChartDatum';
        date: string;
        returnPercent: number;
      }>;
      benchmarks: Array<{
        __typename: 'SecurityPerformance';
        security: {
          __typename: 'Security';
          id: string;
          ticker: string | null | undefined;
          name: string;
          oneDayChangePercent: number | null | undefined;
        };
        historicalChart: Array<{
          __typename: 'SecurityPerformanceChartDatum';
          date: string;
          returnPercent: number;
        }>;
      }>;
    };
    aggregateHoldings: {
      __typename: 'AggregateHoldingConnection';
      edges: Array<
        | {
            __typename: 'AggregateHoldingEdge';
            node:
              | {
                  __typename: 'AggregateHolding';
                  id: string;
                  quantity: number;
                  basis: number;
                  totalValue: number;
                  securityPriceChangeDollars: number | null | undefined;
                  securityPriceChangePercent: number | null | undefined;
                  lastSyncedAt: string | null | undefined;
                  holdings: Array<{
                    __typename: 'Holding';
                    id: string;
                    type: string | null | undefined;
                    typeDisplay: string | null | undefined;
                    name: string | null | undefined;
                    ticker: string | null | undefined;
                    closingPrice: number | null | undefined;
                    closingPriceUpdatedAt: string | null | undefined;
                    quantity: number | null | undefined;
                    value: number | null | undefined;
                    account:
                      | {
                          __typename: 'Account';
                          id: string;
                          mask: string | null | undefined;
                          icon: string;
                          logoUrl: string | null | undefined;
                          displayName: string;
                          currentBalance: number | null | undefined;
                          institution:
                            | { __typename: 'Institution'; id: string; name: string }
                            | null
                            | undefined;
                          type: { __typename: 'AccountType'; name: string; display: string };
                          subtype: { __typename: 'AccountSubtype'; name: string; display: string };
                        }
                      | null
                      | undefined;
                  }>;
                  security:
                    | {
                        __typename: 'Security';
                        id: string;
                        name: string;
                        ticker: string | null | undefined;
                        currentPrice: number | null | undefined;
                        currentPriceUpdatedAt: string | null | undefined;
                        closingPrice: number | null | undefined;
                        closingPriceUpdatedAt: string | null | undefined;
                        type: string;
                        typeDisplay: string;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >;
    };
  };
};

export type Web_GetSecuritiesHistoricalPerformanceQueryVariables = Exact<{
  input: SecurityHistoricalPerformanceInput;
}>;

export type Web_GetSecuritiesHistoricalPerformanceQuery = {
  __typename: 'Query';
  securityHistoricalPerformance: Array<{
    __typename: 'SecurityPerformance';
    security: { __typename: 'Security'; id: string };
    historicalChart: Array<{
      __typename: 'SecurityPerformanceChartDatum';
      date: string;
      returnPercent: number;
    }>;
  }>;
};

export type Web_GetUpcomingRecurringTransactionItemsQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
  filters: InputMaybe<RecurringTransactionFilter>;
  includeLiabilities: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Web_GetUpcomingRecurringTransactionItemsQuery = {
  __typename: 'Query';
  recurringTransactionItems: Array<{
    __typename: 'RecurringTransactionCalendarItem';
    date: string;
    isPast: boolean;
    isLate: boolean;
    markedPaidAt: string | null | undefined;
    isCompleted: boolean | null | undefined;
    isAmountDifferentThanOriginal: boolean | null | undefined;
    transactionId: string | null | undefined;
    amount: number | null | undefined;
    amountDiff: number | null | undefined;
    creditReportLiabilityStatementId: string | null | undefined;
    stream: {
      __typename: 'RecurringTransactionStream';
      id: string;
      frequency: string;
      amount: number | null | undefined;
      isApproximate: boolean;
      name: string;
      logoUrl: string | null | undefined;
      merchant: { __typename: 'Merchant'; id: string } | null | undefined;
      creditReportLiabilityAccount:
        | {
            __typename: 'CreditReportLiabilityAccount';
            id: string;
            liabilityType: string;
            account: { __typename: 'Account'; id: string } | null | undefined;
          }
        | null
        | undefined;
    };
    category: { __typename: 'Category'; id: string; name: string; icon: string } | null | undefined;
    account:
      | {
          __typename: 'Account';
          id: string;
          displayName: string;
          icon: string;
          logoUrl: string | null | undefined;
        }
      | null
      | undefined;
    liabilityStatement:
      | {
          __typename: 'LiabilityStatement';
          id: string;
          paymentStatus: LiabilityStatementPaymentStatus | null | undefined;
          remainingBalance: number | null | undefined;
          minimumPaymentAmount: number | null | undefined;
          paymentTransactions:
            | Array<
                | {
                    __typename: 'Transaction';
                    id: string;
                    amount: number;
                    date: string;
                    category: {
                      __typename: 'Category';
                      id: string;
                      name: string;
                      icon: string;
                      group: {
                        __typename: 'CategoryGroup';
                        id: string;
                        name: string;
                        type: CategoryGroupType;
                      };
                    };
                  }
                | null
                | undefined
              >
            | null
            | undefined;
        }
      | null
      | undefined;
  }>;
};

export type Web_GetAllRecurringTransactionItemsQueryVariables = Exact<{
  filters: InputMaybe<RecurringTransactionFilter>;
  includeLiabilities: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Web_GetAllRecurringTransactionItemsQuery = {
  __typename: 'Query';
  recurringTransactionStreams: Array<{
    __typename: 'RecurringTransactionItem';
    stream: {
      __typename: 'RecurringTransactionStream';
      id: string;
      frequency: string;
      isActive: boolean;
      isApproximate: boolean;
      name: string;
      logoUrl: string | null | undefined;
      merchant: { __typename: 'Merchant'; id: string } | null | undefined;
      creditReportLiabilityAccount:
        | {
            __typename: 'CreditReportLiabilityAccount';
            id: string;
            account: { __typename: 'Account'; id: string } | null | undefined;
          }
        | null
        | undefined;
    };
    nextForecastedTransaction: {
      __typename: 'RecurringTransactionNextForecastedTransaction';
      date: string;
      amount: number;
    };
    category: { __typename: 'Category'; id: string; name: string; icon: string } | null | undefined;
    account:
      | {
          __typename: 'Account';
          id: string;
          displayName: string;
          icon: string;
          logoUrl: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type Web_UpdateOrCreateStripePaymentMethodMutationVariables = Exact<{
  input: UpdateOrCreateStripePaymentMethodMutationInput;
}>;

export type Web_UpdateOrCreateStripePaymentMethodMutation = {
  __typename: 'Mutation';
  updateOrCreateStripePaymentMethod:
    | {
        __typename: 'UpdateOrCreateStripePaymentMethodMutation';
        subscription:
          | {
              __typename: 'HouseholdSubscription';
              id: string;
              trialEndsAt: string | null | undefined;
              trialType: TrialType | null | undefined;
              isOnFreeTrial: boolean;
              hasChargedForLifetime: boolean;
              currentPeriodEndsAt: string | null | undefined;
              billingPeriod: PaymentPeriod | null | undefined;
              nextPaymentAmount: number | null | undefined;
              hasStripeSubscriptionId: boolean;
              hasPremiumEntitlement: boolean;
              entitlements: Array<SubscriptionEntitlement>;
              eligibleForTrial: boolean;
              willCancelAtPeriodEnd: boolean;
              paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
              hasBillingIssue: boolean;
              isSponsor: boolean;
              paymentMethod:
                | { __typename: 'PaymentMethod'; lastFour: string; brand: string }
                | null
                | undefined;
              activePromoCode:
                | { __typename: 'PromoCode'; code: string; description: string }
                | null
                | undefined;
              sponsoredBy:
                | {
                    __typename: 'SubscriptionSponsorInfo';
                    name: string;
                    email: string;
                    profilePictureUrl: string | null | undefined;
                  }
                | null
                | undefined;
              activeSponsorship:
                | {
                    __typename: 'SubscriptionSponsorshipInfo';
                    id: string;
                    sponsor:
                      | {
                          __typename: 'SubscriptionSponsorInfo';
                          name: string;
                          email: string;
                          profilePictureUrl: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetSubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type GetSubscriptionQuery = {
  __typename: 'Query';
  creditBalance: number | null | undefined;
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    trialEndsAt: string | null | undefined;
    trialType: TrialType | null | undefined;
    isOnFreeTrial: boolean;
    hasChargedForLifetime: boolean;
    currentPeriodEndsAt: string | null | undefined;
    billingPeriod: PaymentPeriod | null | undefined;
    nextPaymentAmount: number | null | undefined;
    hasStripeSubscriptionId: boolean;
    hasPremiumEntitlement: boolean;
    entitlements: Array<SubscriptionEntitlement>;
    eligibleForTrial: boolean;
    willCancelAtPeriodEnd: boolean;
    paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
    hasBillingIssue: boolean;
    isSponsor: boolean;
    paymentMethod:
      | { __typename: 'PaymentMethod'; lastFour: string; brand: string }
      | null
      | undefined;
    activePromoCode:
      | { __typename: 'PromoCode'; code: string; description: string }
      | null
      | undefined;
    sponsoredBy:
      | {
          __typename: 'SubscriptionSponsorInfo';
          name: string;
          email: string;
          profilePictureUrl: string | null | undefined;
        }
      | null
      | undefined;
    activeSponsorship:
      | {
          __typename: 'SubscriptionSponsorshipInfo';
          id: string;
          sponsor:
            | {
                __typename: 'SubscriptionSponsorInfo';
                name: string;
                email: string;
                profilePictureUrl: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
  invoices:
    | Array<{
        __typename: 'StripeInvoice';
        id: string;
        date: string;
        amount: number;
        receiptUrl: string | null | undefined;
      }>
    | null
    | undefined;
  constants: { __typename: 'Constants'; monthlyPriceDollars: number };
};

export type Web_BillingSettingsCancelSponsorshipMutationVariables = Exact<{
  input: CancelSponsorshipInput;
}>;

export type Web_BillingSettingsCancelSponsorshipMutation = {
  __typename: 'Mutation';
  cancelSubscriptionSponsorship:
    | {
        __typename: 'CancelSponsorship';
        canceled: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetInstitutionSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetInstitutionSettingsQuery = {
  __typename: 'Query';
  credentials: Array<{
    __typename: 'Credential';
    id: string;
    updateRequired: boolean;
    disconnectedFromDataProviderAt: string | null | undefined;
    displayLastUpdatedAt: string | null | undefined;
    dataProvider: DataProviderLegacy;
    institution: {
      __typename: 'Institution';
      id: string;
      name: string;
      logo: string | null | undefined;
      url: string;
      newConnectionsDisabled: boolean;
      hasIssuesReported: boolean;
      hasIssuesReportedMessage: string;
      status: InstitutionStatus | null | undefined;
      balanceStatus: InstitutionStatus | null | undefined;
      transactionsStatus: InstitutionStatus | null | undefined;
    };
  }>;
  accounts: Array<{
    __typename: 'Account';
    id: string;
    displayName: string;
    mask: string | null | undefined;
    deletedAt: string | null | undefined;
    subtype: { __typename: 'AccountSubtype'; display: string };
    credential: { __typename: 'Credential'; id: string } | null | undefined;
  }>;
  subscription: {
    __typename: 'HouseholdSubscription';
    isOnFreeTrial: boolean;
    hasPremiumEntitlement: boolean;
  };
};

export type GetHouseHoldMemberSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetHouseHoldMemberSettingsQuery = {
  __typename: 'Query';
  householdInvites: Array<{
    __typename: 'HouseholdInvite';
    id: string;
    invitedEmail: string;
    createdAt: string;
    isRevoked: boolean;
    usedAt: string | null | undefined;
  }>;
  me: { __typename: 'User'; id: string; householdRole: UserHouseholdRole };
  myHousehold: {
    __typename: 'Household';
    id: string;
    users: Array<{
      __typename: 'User';
      id: string;
      name: string;
      householdRole: UserHouseholdRole;
      hasMfaOn: boolean;
      profilePictureUrl: string | null | undefined;
    }>;
  };
  householdAccessGrants:
    | Array<{
        __typename: 'HouseholdAccessGrant';
        id: string;
        toEmail: string;
        toName: string | null | undefined;
        expiresAt: string;
      }>
    | null
    | undefined;
};

export type Web_RevokeInviteToHouseholdMutationVariables = Exact<{
  input: RevokeInviteToHouseholdMutationInput;
}>;

export type Web_RevokeInviteToHouseholdMutation = {
  __typename: 'Mutation';
  revokeInviteToHousehold:
    | {
        __typename: 'RevokeInviteToHouseholdMutation';
        invite:
          | { __typename: 'HouseholdInvite'; id: string; isRevoked: boolean }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_ResendInviteToHouseholdMutationVariables = Exact<{
  input: ResendInviteToHouseholdMutationInput;
}>;

export type Web_ResendInviteToHouseholdMutation = {
  __typename: 'Mutation';
  resendInviteToHousehold:
    | {
        __typename: 'ResendInviteToHouseholdMutation';
        invite: { __typename: 'HouseholdInvite'; id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type Web_RevokeHouseholdAccessGrantMutationVariables = Exact<{
  input: RevokeHouseholdAccessGrantInput;
}>;

export type Web_RevokeHouseholdAccessGrantMutation = {
  __typename: 'Mutation';
  revokeHouseholdAccessGrant:
    | { __typename: 'RevokeHouseholdAccessGrant'; revoked: boolean }
    | null
    | undefined;
};

export type Web_GetMerchantSettingsPageQueryVariables = Exact<{
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<MerchantOrdering>;
  search: InputMaybe<Scalars['String']['input']>;
}>;

export type Web_GetMerchantSettingsPageQuery = {
  __typename: 'Query';
  merchantCount: number;
  merchants: Array<{
    __typename: 'Merchant';
    id: string;
    name: string;
    transactionCount: number;
    createdAt: string;
    logoUrl: string | null | undefined;
    recurringTransactionStream:
      | { __typename: 'RecurringTransactionStream'; id: string }
      | null
      | undefined;
  }>;
};

export type Web_GetReferralSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetReferralSettingsQuery = {
  __typename: 'Query';
  referralStatistics: {
    __typename: 'ReferralStatistics';
    creditsEarned: number;
    subscribed: number;
    signedUp: number;
  };
};

export type GetTransactionRulesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTransactionRulesQuery = {
  __typename: 'Query';
  transactionRules: Array<{
    __typename: 'TransactionRuleV2';
    id: string;
    order: number;
    merchantCriteriaUseOriginalStatement: boolean | null | undefined;
    categoryIds: Array<string> | null | undefined;
    accountIds: Array<string> | null | undefined;
    unassignNeedsReviewByUserAction: boolean;
    sendNotificationAction: boolean;
    setHideFromReportsAction: boolean;
    reviewStatusAction: string | null | undefined;
    recentApplicationCount: number;
    lastAppliedAt: string | null | undefined;
    merchantCriteria:
      | Array<{ __typename: 'MerchantCriterion'; operator: string; value: string }>
      | null
      | undefined;
    amountCriteria:
      | {
          __typename: 'AmountCriteriaV2';
          operator: string;
          isExpense: boolean;
          value: number | null | undefined;
          valueRange:
            | {
                __typename: 'DecimalRange';
                lower: number | null | undefined;
                upper: number | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    categories:
      | Array<{ __typename: 'Category'; id: string; name: string; icon: string }>
      | null
      | undefined;
    accounts:
      | Array<{
          __typename: 'Account';
          id: string;
          displayName: string;
          icon: string;
          logoUrl: string | null | undefined;
        }>
      | null
      | undefined;
    setMerchantAction: { __typename: 'Merchant'; id: string; name: string } | null | undefined;
    setCategoryAction:
      | { __typename: 'Category'; id: string; name: string; icon: string }
      | null
      | undefined;
    addTagsAction:
      | Array<{ __typename: 'TransactionTag'; id: string; name: string; color: string }>
      | null
      | undefined;
    linkGoalAction:
      | {
          __typename: 'GoalV2';
          id: string;
          name: string;
          imageStorageProvider: string | null | undefined;
          imageStorageProviderId: string | null | undefined;
        }
      | null
      | undefined;
    needsReviewByUserAction: { __typename: 'User'; id: string; name: string } | null | undefined;
    splitTransactionsAction:
      | {
          __typename: 'SplitTransactionsAction';
          amountType: SplitAmountType;
          splitsInfo: Array<{
            __typename: 'SplitTransactionsActionInfo';
            categoryId: string;
            merchantName: string;
            amount: number;
            goalId: string | null | undefined;
            tags: Array<string> | null | undefined;
            hideFromReports: boolean | null | undefined;
            reviewStatus: string | null | undefined;
            needsReviewByUserId: string | null | undefined;
          }>;
        }
      | null
      | undefined;
  }>;
};

export type Web_UpdateRuleOrderMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  order: Scalars['Int']['input'];
}>;

export type Web_UpdateRuleOrderMutation = {
  __typename: 'Mutation';
  updateTransactionRuleOrderV2:
    | {
        __typename: 'UpdateTransactionRuleOrderV2Mutation';
        transactionRules: Array<{
          __typename: 'TransactionRuleV2';
          id: string;
          order: number;
          merchantCriteriaUseOriginalStatement: boolean | null | undefined;
          categoryIds: Array<string> | null | undefined;
          accountIds: Array<string> | null | undefined;
          unassignNeedsReviewByUserAction: boolean;
          sendNotificationAction: boolean;
          setHideFromReportsAction: boolean;
          reviewStatusAction: string | null | undefined;
          recentApplicationCount: number;
          lastAppliedAt: string | null | undefined;
          merchantCriteria:
            | Array<{ __typename: 'MerchantCriterion'; operator: string; value: string }>
            | null
            | undefined;
          amountCriteria:
            | {
                __typename: 'AmountCriteriaV2';
                operator: string;
                isExpense: boolean;
                value: number | null | undefined;
                valueRange:
                  | {
                      __typename: 'DecimalRange';
                      lower: number | null | undefined;
                      upper: number | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          categories:
            | Array<{ __typename: 'Category'; id: string; name: string; icon: string }>
            | null
            | undefined;
          accounts:
            | Array<{
                __typename: 'Account';
                id: string;
                displayName: string;
                icon: string;
                logoUrl: string | null | undefined;
              }>
            | null
            | undefined;
          setMerchantAction:
            | { __typename: 'Merchant'; id: string; name: string }
            | null
            | undefined;
          setCategoryAction:
            | { __typename: 'Category'; id: string; name: string; icon: string }
            | null
            | undefined;
          addTagsAction:
            | Array<{ __typename: 'TransactionTag'; id: string; name: string; color: string }>
            | null
            | undefined;
          linkGoalAction:
            | {
                __typename: 'GoalV2';
                id: string;
                name: string;
                imageStorageProvider: string | null | undefined;
                imageStorageProviderId: string | null | undefined;
              }
            | null
            | undefined;
          needsReviewByUserAction:
            | { __typename: 'User'; id: string; name: string }
            | null
            | undefined;
          splitTransactionsAction:
            | {
                __typename: 'SplitTransactionsAction';
                amountType: SplitAmountType;
                splitsInfo: Array<{
                  __typename: 'SplitTransactionsActionInfo';
                  categoryId: string;
                  merchantName: string;
                  amount: number;
                  goalId: string | null | undefined;
                  tags: Array<string> | null | undefined;
                  hideFromReports: boolean | null | undefined;
                  reviewStatus: string | null | undefined;
                  needsReviewByUserId: string | null | undefined;
                }>;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type Web_DeleteAllTransactionRulesMutationVariables = Exact<{ [key: string]: never }>;

export type Web_DeleteAllTransactionRulesMutation = {
  __typename: 'Mutation';
  deleteAllTransactionRules:
    | { __typename: 'DeleteAllTransactionRules'; deleted: boolean | null | undefined }
    | null
    | undefined;
};

export type Web_GetSecuritySettingsQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetSecuritySettingsQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    email: string;
    hasMfaOn: boolean;
    isVerified: boolean;
    hasPassword: boolean;
    externalAuthProviders: Array<{
      __typename: 'UserExternalAuth';
      provider: string;
      email: string;
    }>;
    pendingEmailUpdateVerification:
      | { __typename: 'PendingEmailUpdateVerification'; email: string }
      | null
      | undefined;
    activeSupportAccountAccessGrant:
      | {
          __typename: 'SupportAccountAccessGrant';
          id: string;
          createdAt: string;
          expiresAt: string;
        }
      | null
      | undefined;
  };
};

export type Web_ToggleSupportAccountAccessMutationVariables = Exact<{
  isEnabled: Scalars['Boolean']['input'];
}>;

export type Web_ToggleSupportAccountAccessMutation = {
  __typename: 'Mutation';
  toggleSupportAccountAccess:
    | {
        __typename: 'ToggleSupportAccountAccessMutation';
        grant:
          | { __typename: 'SupportAccountAccessGrant'; id: string; expiresAt: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type OldestDeletableSyncedSnapshotDateQueryVariables = Exact<{ [key: string]: never }>;

export type OldestDeletableSyncedSnapshotDateQuery = {
  __typename: 'Query';
  oldestDeletableSyncedSnapshotDate: string | null | undefined;
};

export type PreviewDeleteSyncedSnapshotsQueryVariables = Exact<{
  beforeDate: Scalars['Date']['input'];
}>;

export type PreviewDeleteSyncedSnapshotsQuery = {
  __typename: 'Query';
  previewDeleteSyncedSnapshots: number | null | undefined;
};

export type Web_DeleteAccountLogoMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;

export type Web_DeleteAccountLogoMutation = {
  __typename: 'Mutation';
  deleteAccountLogo:
    | {
        __typename: 'DeleteAccountLogoMutation';
        success: boolean | null | undefined;
        account:
          | {
              __typename: 'Account';
              id: string;
              logoUrl: string | null | undefined;
              hasCustomizedLogo: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_DeleteMerchantLogoMutationVariables = Exact<{
  merchantId: Scalars['ID']['input'];
}>;

export type Web_DeleteMerchantLogoMutation = {
  __typename: 'Mutation';
  deleteMerchantLogo:
    | {
        __typename: 'DeleteMerchantLogoMutation';
        success: boolean | null | undefined;
        merchant:
          | { __typename: 'Merchant'; id: string; logoUrl: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_DeleteUserProfilePictureMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type Web_DeleteUserProfilePictureMutation = {
  __typename: 'Mutation';
  deleteUserProfilePicture:
    | { __typename: 'DeleteUserProfilePicture'; deleted: boolean | null | undefined }
    | null
    | undefined;
};

export type Web_GetSubscriptionModalQueryVariables = Exact<{
  promoCode: InputMaybe<Scalars['String']['input']>;
}>;

export type Web_GetSubscriptionModalQuery = {
  __typename: 'Query';
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    billingPeriod: PaymentPeriod | null | undefined;
    trialEndsAt: string | null | undefined;
    hasPremiumEntitlement: boolean;
    willCancelAtPeriodEnd: boolean;
    isOnFreeTrial: boolean;
    paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
    hasStripeSubscriptionId: boolean;
    hasChargedForLifetime: boolean;
    analyticsFreemiumSummaryStatus: string;
    paymentMethod:
      | { __typename: 'PaymentMethod'; brand: string; lastFour: string }
      | null
      | undefined;
    referralRedemption:
      | { __typename: 'ReferralRedemption'; campaign: string | null | undefined }
      | null
      | undefined;
  };
  subscriptionOffering: {
    __typename: 'StripeSubscriptionOffering';
    promoCodeError: string | null | undefined;
    promoCodeDescription: string | null | undefined;
    promoCodeDuration: StripeCouponDuration | null | undefined;
    promoCodeDurationInMonths: number | null | undefined;
    plans: Array<{
      __typename: 'SubscriptionPlan';
      name: string;
      period: PaymentPeriod;
      pricePerPeriodDollars: number;
      discountedPricePerPeriodDollars: number | null | undefined;
      stripeId: string;
      newTrialEndsAt: string | null | undefined;
      requirePaymentMethod: boolean;
      sponsoredBy:
        | {
            __typename: 'SubscriptionSponsorInfo';
            name: string;
            email: string;
            profilePictureUrl: string | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
};

export type ApplyStripeCancelDiscountOfferMutationVariables = Exact<{
  input: ApplyStripeCancelDiscountOfferInput;
}>;

export type ApplyStripeCancelDiscountOfferMutation = {
  __typename: 'Mutation';
  applyStripeCancelDiscountOffer:
    | {
        __typename: 'ApplyStripeCancelDiscountOffer';
        discountOffer:
          | {
              __typename: 'StripeCancellationDiscountOffer';
              discountPercent: number;
              duration: StripeCouponDuration;
              durationInMonths: number | null | undefined;
            }
          | null
          | undefined;
        subscription:
          | {
              __typename: 'HouseholdSubscription';
              id: string;
              trialEndsAt: string | null | undefined;
              trialType: TrialType | null | undefined;
              isOnFreeTrial: boolean;
              hasChargedForLifetime: boolean;
              currentPeriodEndsAt: string | null | undefined;
              billingPeriod: PaymentPeriod | null | undefined;
              nextPaymentAmount: number | null | undefined;
              hasStripeSubscriptionId: boolean;
              hasPremiumEntitlement: boolean;
              entitlements: Array<SubscriptionEntitlement>;
              eligibleForTrial: boolean;
              willCancelAtPeriodEnd: boolean;
              paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
              hasBillingIssue: boolean;
              isSponsor: boolean;
              paymentMethod:
                | { __typename: 'PaymentMethod'; lastFour: string; brand: string }
                | null
                | undefined;
              activePromoCode:
                | { __typename: 'PromoCode'; code: string; description: string }
                | null
                | undefined;
              sponsoredBy:
                | {
                    __typename: 'SubscriptionSponsorInfo';
                    name: string;
                    email: string;
                    profilePictureUrl: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetStripeCancellationDiscountOfferQueryVariables = Exact<{
  reason: CancellationReason;
}>;

export type GetStripeCancellationDiscountOfferQuery = {
  __typename: 'Query';
  stripeCancellationDiscountOffer:
    | {
        __typename: 'StripeCancellationDiscountOffer';
        discountPercent: number;
        duration: StripeCouponDuration;
        durationInMonths: number | null | undefined;
        originalPricePerPeriodDollars: number;
        discountedPricePerPeriodDollars: number;
      }
    | null
    | undefined;
  subscription: {
    __typename: 'HouseholdSubscription';
    id: string;
    billingPeriod: PaymentPeriod | null | undefined;
  };
};

export type Web_UpdateRenewalReminderMutationVariables = Exact<{
  input: UpdateRenewalReminderInput;
}>;

export type Web_UpdateRenewalReminderMutation = {
  __typename: 'Mutation';
  updateRenewalReminder:
    | { __typename: 'UpdateRenewalReminder'; renewalReminderDays: number | null | undefined }
    | null
    | undefined;
};

export type InstitutionInfoFieldsFragment = {
  __typename: 'Credential';
  id: string;
  displayLastUpdatedAt: string | null | undefined;
  dataProvider: DataProviderLegacy;
  updateRequired: boolean;
  disconnectedFromDataProviderAt: string | null | undefined;
  institution: {
    __typename: 'Institution';
    id: string;
    name: string;
    newConnectionsDisabled: boolean;
    hasIssuesReported: boolean;
    hasIssuesReportedMessage: string;
    logo: string | null | undefined;
    status: InstitutionStatus | null | undefined;
    balanceStatus: InstitutionStatus | null | undefined;
    transactionsStatus: InstitutionStatus | null | undefined;
  };
};

export type InstitutionLogoWithStatusFieldsFragment = {
  __typename: 'Credential';
  dataProvider: DataProviderLegacy;
  updateRequired: boolean;
  institution: {
    __typename: 'Institution';
    hasIssuesReported: boolean;
    logo: string | null | undefined;
    status: InstitutionStatus | null | undefined;
    balanceStatus: InstitutionStatus | null | undefined;
    transactionsStatus: InstitutionStatus | null | undefined;
  };
};

export type Web_DeleteUserFromHouseholdMutationVariables = Exact<{
  input: DeleteUserFromHouseholdMutationInput;
}>;

export type Web_DeleteUserFromHouseholdMutation = {
  __typename: 'Mutation';
  deleteUserFromHousehold:
    | {
        __typename: 'DeleteUserFromHouseholdMutation';
        deleted: boolean;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetAccountsBelongingToUserQueryVariables = Exact<{
  userId: Scalars['UUID']['input'];
}>;

export type GetAccountsBelongingToUserQuery = {
  __typename: 'Query';
  accountsBelongingTo: Array<{
    __typename: 'Account';
    id: string;
    displayName: string;
    transactionsCount: number;
    credential:
      | {
          __typename: 'Credential';
          id: string;
          institution: { __typename: 'Institution'; id: string; logo: string | null | undefined };
        }
      | null
      | undefined;
  }>;
};

export type CreateHouseholdAccessGrantMutationVariables = Exact<{
  input: CreateHouseholdAccessGrantInput;
}>;

export type CreateHouseholdAccessGrantMutation = {
  __typename: 'Mutation';
  createHouseholdAccessGrant:
    | {
        __typename: 'CreateHouseholdAccessGrant';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        accessGrant: { __typename: 'HouseholdAccessGrant'; toEmail: string } | null | undefined;
      }
    | null
    | undefined;
};

export type Web_InviteEmailMutationVariables = Exact<{
  input: InviteUserToHouseholdMutationInput;
}>;

export type Web_InviteEmailMutation = {
  __typename: 'Mutation';
  inviteUserToHousehold:
    | {
        __typename: 'InviteUserToHouseholdMutation';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        invite:
          | { __typename: 'HouseholdInvite'; id: string; invitedEmail: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetEditMerchantQueryVariables = Exact<{
  merchantId: Scalars['ID']['input'];
}>;

export type Web_GetEditMerchantQuery = {
  __typename: 'Query';
  merchant:
    | {
        __typename: 'Merchant';
        id: string;
        name: string;
        logoUrl: string | null | undefined;
        transactionCount: number;
        ruleCount: number;
        canBeDeleted: boolean | null | undefined;
        recurringTransactionStream:
          | {
              __typename: 'RecurringTransactionStream';
              id: string;
              frequency: string;
              amount: number | null | undefined;
              baseDate: string;
              isActive: boolean;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_DeleteMerchantMutationVariables = Exact<{
  merchantId: Scalars['ID']['input'];
  moveToId: InputMaybe<Scalars['ID']['input']>;
}>;

export type Web_DeleteMerchantMutation = {
  __typename: 'Mutation';
  deleteMerchant: { __typename: 'DeleteMerchantMutation'; success: boolean } | null | undefined;
};

export type Web_UpdateMerchantMutationVariables = Exact<{
  input: UpdateMerchantInput;
}>;

export type Web_UpdateMerchantMutation = {
  __typename: 'Mutation';
  updateMerchant:
    | {
        __typename: 'UpdateMerchantMutation';
        merchant:
          | {
              __typename: 'Merchant';
              id: string;
              name: string;
              logoUrl: string | null | undefined;
              recurringTransactionStream:
                | {
                    __typename: 'RecurringTransactionStream';
                    id: string;
                    frequency: string;
                    baseDate: string;
                    amount: number | null | undefined;
                    isActive: boolean;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_DisableMfaMutationVariables = Exact<{ [key: string]: never }>;

export type Web_DisableMfaMutation = {
  __typename: 'Mutation';
  deleteTotpDevice:
    | {
        __typename: 'DeleteTOTPDevice';
        deleted: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_CreateUnconfirmedTotpDeviceMutationVariables = Exact<{ [key: string]: never }>;

export type Web_CreateUnconfirmedTotpDeviceMutation = {
  __typename: 'Mutation';
  createUnconfirmedTotpDevice:
    | {
        __typename: 'CreateUnconfirmedTOTPDevice';
        device:
          | { __typename: 'TOTPDevice'; configUrl: string | null | undefined; secret: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_ConfirmTotpDeviceMutationVariables = Exact<{
  input: ConfirmTotpDeviceInput;
}>;

export type Web_ConfirmTotpDeviceMutation = {
  __typename: 'Mutation';
  confirmTotpDevice:
    | {
        __typename: 'ConfirmTOTPDevice';
        isTokenValid: boolean;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_CreateRecoveryCodeMutationVariables = Exact<{ [key: string]: never }>;

export type Web_CreateRecoveryCodeMutation = {
  __typename: 'Mutation';
  createRecoveryCodes:
    | {
        __typename: 'CreateRecoveryCodesMutation';
        staticTokens:
          | Array<{ __typename: 'StaticToken'; id: string; token: string } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type FreeTrialDurationPanelDataFragment = {
  __typename: 'HouseholdSubscription';
  id: string;
  trialEndsAt: string | null | undefined;
  trialDurationDays: number;
  trialType: TrialType | null | undefined;
};

export type Web_GetLinkedCredentialsQueryVariables = Exact<{ [key: string]: never }>;

export type Web_GetLinkedCredentialsQuery = {
  __typename: 'Query';
  credentials: Array<{
    __typename: 'Credential';
    id: string;
    institution: {
      __typename: 'Institution';
      id: string;
      logo: string | null | undefined;
      name: string;
    };
  }>;
};

export type GetMerchantsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMerchantsQuery = {
  __typename: 'Query';
  merchants: Array<{ __typename: 'Merchant'; id: string; name: string; transactionCount: number }>;
};

export type OriginalTransactionFieldsFragment = {
  __typename: 'Transaction';
  id: string;
  date: string;
  amount: number;
  merchant: { __typename: 'Merchant'; id: string; name: string };
};

export type TransactionExplainQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type TransactionExplainQuery = {
  __typename: 'Query';
  explainTransaction:
    | { __typename: 'TransactionExplanation'; explanation: string }
    | null
    | undefined;
};

export type TransactionRuleFieldsFragment = {
  __typename: 'TransactionRuleV2';
  id: string;
  merchantCriteriaUseOriginalStatement: boolean | null | undefined;
  categoryIds: Array<string> | null | undefined;
  accountIds: Array<string> | null | undefined;
  unassignNeedsReviewByUserAction: boolean;
  sendNotificationAction: boolean;
  setHideFromReportsAction: boolean;
  reviewStatusAction: string | null | undefined;
  recentApplicationCount: number;
  lastAppliedAt: string | null | undefined;
  merchantCriteria:
    | Array<{ __typename: 'MerchantCriterion'; operator: string; value: string }>
    | null
    | undefined;
  amountCriteria:
    | {
        __typename: 'AmountCriteriaV2';
        operator: string;
        isExpense: boolean;
        value: number | null | undefined;
        valueRange:
          | {
              __typename: 'DecimalRange';
              lower: number | null | undefined;
              upper: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  categories:
    | Array<{ __typename: 'Category'; id: string; name: string; icon: string }>
    | null
    | undefined;
  accounts:
    | Array<{
        __typename: 'Account';
        id: string;
        displayName: string;
        icon: string;
        logoUrl: string | null | undefined;
      }>
    | null
    | undefined;
  setMerchantAction: { __typename: 'Merchant'; id: string; name: string } | null | undefined;
  setCategoryAction:
    | { __typename: 'Category'; id: string; name: string; icon: string }
    | null
    | undefined;
  addTagsAction:
    | Array<{ __typename: 'TransactionTag'; id: string; name: string; color: string }>
    | null
    | undefined;
  linkGoalAction:
    | {
        __typename: 'GoalV2';
        id: string;
        name: string;
        imageStorageProvider: string | null | undefined;
        imageStorageProviderId: string | null | undefined;
      }
    | null
    | undefined;
  needsReviewByUserAction: { __typename: 'User'; id: string; name: string } | null | undefined;
  splitTransactionsAction:
    | {
        __typename: 'SplitTransactionsAction';
        amountType: SplitAmountType;
        splitsInfo: Array<{
          __typename: 'SplitTransactionsActionInfo';
          categoryId: string;
          merchantName: string;
          amount: number;
          goalId: string | null | undefined;
          tags: Array<string> | null | undefined;
          hideFromReports: boolean | null | undefined;
          reviewStatus: string | null | undefined;
          needsReviewByUserId: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type GetTransactionFiltersCardQueryVariables = Exact<{ [key: string]: never }>;

export type GetTransactionFiltersCardQuery = {
  __typename: 'Query';
  allTransactions: { __typename: 'TransactionList'; totalCount: number };
};

export type TransactionsListFieldsFragment = {
  __typename: 'Transaction';
  id: string;
  amount: number;
  pending: boolean;
  date: string;
  hideFromReports: boolean;
  plaidName: string | null | undefined;
  notes: string | null | undefined;
  isRecurring: boolean;
  reviewStatus: string | null | undefined;
  needsReview: boolean;
  isSplitTransaction: boolean;
  dataProviderDescription: string | null | undefined;
  attachments: Array<{ __typename: 'TransactionAttachment'; id: string }>;
  category: {
    __typename: 'Category';
    id: string;
    name: string;
    icon: string;
    group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
  };
  merchant: {
    __typename: 'Merchant';
    name: string;
    id: string;
    transactionsCount: number;
    logoUrl: string | null | undefined;
    recurringTransactionStream:
      | { __typename: 'RecurringTransactionStream'; frequency: string; isActive: boolean }
      | null
      | undefined;
  };
  tags: Array<{
    __typename: 'TransactionTag';
    id: string;
    name: string;
    color: string;
    order: number;
  }>;
  account: {
    __typename: 'Account';
    id: string;
    displayName: string;
    icon: string;
    logoUrl: string | null | undefined;
  };
};

export type Web_UpdateTransactionOverviewMutationVariables = Exact<{
  input: UpdateTransactionMutationInput;
}>;

export type Web_UpdateTransactionOverviewMutation = {
  __typename: 'Mutation';
  updateTransaction:
    | {
        __typename: 'UpdateTransactionMutation';
        transaction:
          | {
              __typename: 'Transaction';
              id: string;
              amount: number;
              pending: boolean;
              date: string;
              hideFromReports: boolean;
              plaidName: string | null | undefined;
              notes: string | null | undefined;
              isRecurring: boolean;
              reviewStatus: string | null | undefined;
              needsReview: boolean;
              isSplitTransaction: boolean;
              dataProviderDescription: string | null | undefined;
              attachments: Array<{ __typename: 'TransactionAttachment'; id: string }>;
              category: {
                __typename: 'Category';
                id: string;
                name: string;
                icon: string;
                group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
              };
              merchant: {
                __typename: 'Merchant';
                name: string;
                id: string;
                transactionsCount: number;
                logoUrl: string | null | undefined;
                recurringTransactionStream:
                  | {
                      __typename: 'RecurringTransactionStream';
                      frequency: string;
                      isActive: boolean;
                    }
                  | null
                  | undefined;
              };
              tags: Array<{
                __typename: 'TransactionTag';
                id: string;
                name: string;
                color: string;
                order: number;
              }>;
              account: {
                __typename: 'Account';
                id: string;
                displayName: string;
                icon: string;
                logoUrl: string | null | undefined;
              };
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetTransactionsListQueryVariables = Exact<{
  offset: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  filters: InputMaybe<TransactionFilterInput>;
  orderBy: InputMaybe<TransactionOrdering>;
}>;

export type Web_GetTransactionsListQuery = {
  __typename: 'Query';
  allTransactions: {
    __typename: 'TransactionList';
    totalCount: number;
    totalSelectableCount: number | null | undefined;
    results: Array<{
      __typename: 'Transaction';
      id: string;
      amount: number;
      pending: boolean;
      date: string;
      hideFromReports: boolean;
      plaidName: string | null | undefined;
      notes: string | null | undefined;
      isRecurring: boolean;
      reviewStatus: string | null | undefined;
      needsReview: boolean;
      isSplitTransaction: boolean;
      dataProviderDescription: string | null | undefined;
      attachments: Array<{ __typename: 'TransactionAttachment'; id: string }>;
      category: {
        __typename: 'Category';
        id: string;
        name: string;
        icon: string;
        group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
      };
      merchant: {
        __typename: 'Merchant';
        name: string;
        id: string;
        transactionsCount: number;
        logoUrl: string | null | undefined;
        recurringTransactionStream:
          | { __typename: 'RecurringTransactionStream'; frequency: string; isActive: boolean }
          | null
          | undefined;
      };
      tags: Array<{
        __typename: 'TransactionTag';
        id: string;
        name: string;
        color: string;
        order: number;
      }>;
      account: {
        __typename: 'Account';
        id: string;
        displayName: string;
        icon: string;
        logoUrl: string | null | undefined;
      };
    }>;
  };
  transactionRules: Array<{ __typename: 'TransactionRuleV2'; id: string }>;
};

export type Web_GetTransactionsSummaryCardQueryVariables = Exact<{
  filters: TransactionFilterInput;
}>;

export type Web_GetTransactionsSummaryCardQuery = {
  __typename: 'Query';
  allTransactions: { __typename: 'TransactionList'; totalCount: number };
};

export type TransactionsSummaryFieldsFragment = {
  __typename: 'TransactionsSummary';
  avg: number | null | undefined;
  count: number;
  max: number;
  maxExpense: number;
  sum: number;
  sumIncome: number;
  sumExpense: number;
  first: string | null | undefined;
  last: string | null | undefined;
};

export type TransactionDrawerFieldsFragment = {
  __typename: 'Transaction';
  id: string;
  amount: number;
  pending: boolean;
  isRecurring: boolean;
  date: string;
  originalDate: string;
  hideFromReports: boolean;
  needsReview: boolean;
  reviewedAt: string | null | undefined;
  plaidName: string | null | undefined;
  notes: string | null | undefined;
  hasSplitTransactions: boolean;
  isSplitTransaction: boolean;
  isManual: boolean;
  reviewStatus: string | null | undefined;
  dataProviderDescription: string | null | undefined;
  reviewedByUser: { __typename: 'User'; id: string; name: string } | null | undefined;
  splitTransactions: Array<{
    __typename: 'Transaction';
    id: string;
    amount: number;
    merchant: { __typename: 'Merchant'; id: string; name: string };
    category: { __typename: 'Category'; id: string; icon: string; name: string };
  }>;
  originalTransaction:
    | {
        __typename: 'Transaction';
        id: string;
        date: string;
        amount: number;
        merchant: { __typename: 'Merchant'; id: string; name: string };
      }
    | null
    | undefined;
  attachments: Array<{
    __typename: 'TransactionAttachment';
    id: string;
    publicId: string;
    extension: string;
    sizeBytes: number;
    filename: string;
    originalAssetUrl: string;
  }>;
  account: {
    __typename: 'Account';
    id: string;
    hideTransactionsFromReports: boolean;
    displayName: string;
    icon: string;
    logoUrl: string | null | undefined;
  };
  category: {
    __typename: 'Category';
    id: string;
    name: string;
    icon: string;
    group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
  };
  goal: { __typename: 'GoalV2'; id: string } | null | undefined;
  merchant: {
    __typename: 'Merchant';
    id: string;
    name: string;
    transactionCount: number;
    logoUrl: string | null | undefined;
    transactionsCount: number;
    recurringTransactionStream:
      | {
          __typename: 'RecurringTransactionStream';
          id: string;
          frequency: string;
          isActive: boolean;
        }
      | null
      | undefined;
  };
  tags: Array<{
    __typename: 'TransactionTag';
    id: string;
    name: string;
    color: string;
    order: number;
  }>;
  needsReviewByUser: { __typename: 'User'; id: string } | null | undefined;
};

export type GetTransactionDrawerQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  redirectPosted: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetTransactionDrawerQuery = {
  __typename: 'Query';
  getTransaction:
    | {
        __typename: 'Transaction';
        id: string;
        amount: number;
        pending: boolean;
        isRecurring: boolean;
        date: string;
        originalDate: string;
        hideFromReports: boolean;
        needsReview: boolean;
        reviewedAt: string | null | undefined;
        plaidName: string | null | undefined;
        notes: string | null | undefined;
        hasSplitTransactions: boolean;
        isSplitTransaction: boolean;
        isManual: boolean;
        reviewStatus: string | null | undefined;
        dataProviderDescription: string | null | undefined;
        reviewedByUser: { __typename: 'User'; id: string; name: string } | null | undefined;
        splitTransactions: Array<{
          __typename: 'Transaction';
          id: string;
          amount: number;
          merchant: { __typename: 'Merchant'; id: string; name: string };
          category: { __typename: 'Category'; id: string; icon: string; name: string };
        }>;
        originalTransaction:
          | {
              __typename: 'Transaction';
              id: string;
              date: string;
              amount: number;
              merchant: { __typename: 'Merchant'; id: string; name: string };
            }
          | null
          | undefined;
        attachments: Array<{
          __typename: 'TransactionAttachment';
          id: string;
          publicId: string;
          extension: string;
          sizeBytes: number;
          filename: string;
          originalAssetUrl: string;
        }>;
        account: {
          __typename: 'Account';
          id: string;
          hideTransactionsFromReports: boolean;
          displayName: string;
          icon: string;
          logoUrl: string | null | undefined;
        };
        category: {
          __typename: 'Category';
          id: string;
          name: string;
          icon: string;
          group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
        };
        goal: { __typename: 'GoalV2'; id: string } | null | undefined;
        merchant: {
          __typename: 'Merchant';
          id: string;
          name: string;
          transactionCount: number;
          logoUrl: string | null | undefined;
          transactionsCount: number;
          recurringTransactionStream:
            | {
                __typename: 'RecurringTransactionStream';
                id: string;
                frequency: string;
                isActive: boolean;
              }
            | null
            | undefined;
        };
        tags: Array<{
          __typename: 'TransactionTag';
          id: string;
          name: string;
          color: string;
          order: number;
        }>;
        needsReviewByUser: { __typename: 'User'; id: string } | null | undefined;
      }
    | null
    | undefined;
  myHousehold: {
    __typename: 'Household';
    id: string;
    users: Array<{ __typename: 'User'; id: string; name: string }>;
  };
};

export type Web_TransactionDrawerUpdateTransactionMutationVariables = Exact<{
  input: UpdateTransactionMutationInput;
}>;

export type Web_TransactionDrawerUpdateTransactionMutation = {
  __typename: 'Mutation';
  updateTransaction:
    | {
        __typename: 'UpdateTransactionMutation';
        transaction:
          | {
              __typename: 'Transaction';
              id: string;
              amount: number;
              pending: boolean;
              isRecurring: boolean;
              date: string;
              originalDate: string;
              hideFromReports: boolean;
              needsReview: boolean;
              reviewedAt: string | null | undefined;
              plaidName: string | null | undefined;
              notes: string | null | undefined;
              hasSplitTransactions: boolean;
              isSplitTransaction: boolean;
              isManual: boolean;
              reviewStatus: string | null | undefined;
              dataProviderDescription: string | null | undefined;
              reviewedByUser: { __typename: 'User'; id: string; name: string } | null | undefined;
              splitTransactions: Array<{
                __typename: 'Transaction';
                id: string;
                amount: number;
                merchant: { __typename: 'Merchant'; id: string; name: string };
                category: { __typename: 'Category'; id: string; icon: string; name: string };
              }>;
              originalTransaction:
                | {
                    __typename: 'Transaction';
                    id: string;
                    date: string;
                    amount: number;
                    merchant: { __typename: 'Merchant'; id: string; name: string };
                  }
                | null
                | undefined;
              attachments: Array<{
                __typename: 'TransactionAttachment';
                id: string;
                publicId: string;
                extension: string;
                sizeBytes: number;
                filename: string;
                originalAssetUrl: string;
              }>;
              account: {
                __typename: 'Account';
                id: string;
                hideTransactionsFromReports: boolean;
                displayName: string;
                icon: string;
                logoUrl: string | null | undefined;
              };
              category: {
                __typename: 'Category';
                id: string;
                name: string;
                icon: string;
                group: { __typename: 'CategoryGroup'; id: string; type: CategoryGroupType };
              };
              goal: { __typename: 'GoalV2'; id: string } | null | undefined;
              merchant: {
                __typename: 'Merchant';
                id: string;
                name: string;
                transactionCount: number;
                logoUrl: string | null | undefined;
                transactionsCount: number;
                recurringTransactionStream:
                  | {
                      __typename: 'RecurringTransactionStream';
                      id: string;
                      frequency: string;
                      isActive: boolean;
                    }
                  | null
                  | undefined;
              };
              tags: Array<{
                __typename: 'TransactionTag';
                id: string;
                name: string;
                color: string;
                order: number;
              }>;
              needsReviewByUser: { __typename: 'User'; id: string } | null | undefined;
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_TransactionDrawerDeleteAttachmentMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Web_TransactionDrawerDeleteAttachmentMutation = {
  __typename: 'Mutation';
  deleteTransactionAttachment:
    | { __typename: 'DeleteTransactionAttachmentMutation'; deleted: boolean | null | undefined }
    | null
    | undefined;
};

export type Web_SetTransactionTagsMutationVariables = Exact<{
  input: SetTransactionTagsInput;
}>;

export type Web_SetTransactionTagsMutation = {
  __typename: 'Mutation';
  setTransactionTags:
    | {
        __typename: 'SetTransactionTags';
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
        transaction:
          | {
              __typename: 'Transaction';
              id: string;
              tags: Array<{ __typename: 'TransactionTag'; id: string }>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TransactionDrawerSplitMessageFieldsFragment = {
  __typename: 'Transaction';
  id: string;
  amount: number;
  merchant: { __typename: 'Merchant'; id: string; name: string };
  category: { __typename: 'Category'; id: string; icon: string; name: string };
};

export type AccountOrderFragment = { __typename: 'Account'; id: string; order: number };

export type Web_UpdateAccountOrderMutationVariables = Exact<{
  input: UpdateAccountOrderInput;
}>;

export type Web_UpdateAccountOrderMutation = {
  __typename: 'Mutation';
  updateAccountOrder:
    | {
        __typename: 'UpdateAccountOrderMutation';
        account: { __typename: 'Account'; id: string; order: number } | null | undefined;
      }
    | null
    | undefined;
};

export type Web_MarkAdviceTaskCompleteMutationVariables = Exact<{
  input: MarkAdviceItemTaskCompleteInput;
}>;

export type Web_MarkAdviceTaskCompleteMutation = {
  __typename: 'Mutation';
  markAdviceItemTaskComplete:
    | {
        __typename: 'MarkAdviceItemTaskComplete';
        adviceItemTask:
          | { __typename: 'AdviceItemTask'; id: string; completedAt: string | null | undefined }
          | null
          | undefined;
        adviceItem:
          | {
              __typename: 'AdviceItem';
              id: string;
              title: string;
              description: string | null | undefined;
              numTasksCompleted: number;
              numTasksRemaining: number;
              numTasks: number;
              completedAt: string | null | undefined;
              category: {
                __typename: 'AdviceItemCategory';
                name: string;
                displayName: string;
                color: string;
              };
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_MarkAdviceTaskIncompleteMutationVariables = Exact<{
  input: MarkAdviceItemTaskIncompleteInput;
}>;

export type Web_MarkAdviceTaskIncompleteMutation = {
  __typename: 'Mutation';
  markAdviceItemTaskIncomplete:
    | {
        __typename: 'MarkAdviceItemTaskIncomplete';
        adviceItemTask:
          | { __typename: 'AdviceItemTask'; id: string; completedAt: string | null | undefined }
          | null
          | undefined;
        adviceItem:
          | {
              __typename: 'AdviceItem';
              id: string;
              title: string;
              description: string | null | undefined;
              numTasksCompleted: number;
              numTasksRemaining: number;
              numTasks: number;
              completedAt: string | null | undefined;
              category: {
                __typename: 'AdviceItemCategory';
                name: string;
                displayName: string;
                color: string;
              };
            }
          | null
          | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_ChangeSubscriptionMutationVariables = Exact<{
  input: ChangeStripeSubscriptionMutationInput;
}>;

export type Web_ChangeSubscriptionMutation = {
  __typename: 'Mutation';
  changeStripeSubscription:
    | {
        __typename: 'ChangeStripeSubscriptionMutation';
        subscription:
          | {
              __typename: 'HouseholdSubscription';
              id: string;
              trialEndsAt: string | null | undefined;
              trialType: TrialType | null | undefined;
              isOnFreeTrial: boolean;
              hasChargedForLifetime: boolean;
              currentPeriodEndsAt: string | null | undefined;
              billingPeriod: PaymentPeriod | null | undefined;
              nextPaymentAmount: number | null | undefined;
              hasStripeSubscriptionId: boolean;
              hasPremiumEntitlement: boolean;
              entitlements: Array<SubscriptionEntitlement>;
              eligibleForTrial: boolean;
              willCancelAtPeriodEnd: boolean;
              paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
              hasBillingIssue: boolean;
              isSponsor: boolean;
              paymentMethod:
                | { __typename: 'PaymentMethod'; lastFour: string; brand: string }
                | null
                | undefined;
              activePromoCode:
                | { __typename: 'PromoCode'; code: string; description: string }
                | null
                | undefined;
              sponsoredBy:
                | {
                    __typename: 'SubscriptionSponsorInfo';
                    name: string;
                    email: string;
                    profilePictureUrl: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_CreateSubscriptionMutationVariables = Exact<{
  input: CreateStripeSubscriptionMutationInput;
}>;

export type Web_CreateSubscriptionMutation = {
  __typename: 'Mutation';
  createStripeSubscription:
    | {
        __typename: 'CreateStripeSubscriptionMutation';
        subscription:
          | {
              __typename: 'HouseholdSubscription';
              id: string;
              trialEndsAt: string | null | undefined;
              trialType: TrialType | null | undefined;
              isOnFreeTrial: boolean;
              hasChargedForLifetime: boolean;
              currentPeriodEndsAt: string | null | undefined;
              billingPeriod: PaymentPeriod | null | undefined;
              nextPaymentAmount: number | null | undefined;
              hasStripeSubscriptionId: boolean;
              hasPremiumEntitlement: boolean;
              entitlements: Array<SubscriptionEntitlement>;
              eligibleForTrial: boolean;
              willCancelAtPeriodEnd: boolean;
              paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
              hasBillingIssue: boolean;
              isSponsor: boolean;
              paymentMethod:
                | { __typename: 'PaymentMethod'; lastFour: string; brand: string }
                | null
                | undefined;
              activePromoCode:
                | { __typename: 'PromoCode'; code: string; description: string }
                | null
                | undefined;
              sponsoredBy:
                | {
                    __typename: 'SubscriptionSponsorInfo';
                    name: string;
                    email: string;
                    profilePictureUrl: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_ReactivateSubscriptionMutationVariables = Exact<{
  input: ReactivateStripeSubscriptionMutationInput;
}>;

export type Web_ReactivateSubscriptionMutation = {
  __typename: 'Mutation';
  reactivateStripeSubscription:
    | {
        __typename: 'ReactivateStripeSubscriptionMutation';
        subscription:
          | {
              __typename: 'HouseholdSubscription';
              id: string;
              trialEndsAt: string | null | undefined;
              trialType: TrialType | null | undefined;
              isOnFreeTrial: boolean;
              hasChargedForLifetime: boolean;
              currentPeriodEndsAt: string | null | undefined;
              billingPeriod: PaymentPeriod | null | undefined;
              nextPaymentAmount: number | null | undefined;
              hasStripeSubscriptionId: boolean;
              hasPremiumEntitlement: boolean;
              entitlements: Array<SubscriptionEntitlement>;
              eligibleForTrial: boolean;
              willCancelAtPeriodEnd: boolean;
              paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
              hasBillingIssue: boolean;
              isSponsor: boolean;
              paymentMethod:
                | { __typename: 'PaymentMethod'; lastFour: string; brand: string }
                | null
                | undefined;
              activePromoCode:
                | { __typename: 'PromoCode'; code: string; description: string }
                | null
                | undefined;
              sponsoredBy:
                | {
                    __typename: 'SubscriptionSponsorInfo';
                    name: string;
                    email: string;
                    profilePictureUrl: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        errors:
          | { __typename: 'PayloadError'; message: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetCategoryForDeletionQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;

export type Web_GetCategoryForDeletionQuery = {
  __typename: 'Query';
  category:
    | { __typename: 'Category'; id: string; name: string; icon: string; isSystemCategory: boolean }
    | null
    | undefined;
};

export type Web_DeleteCategoryMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  moveToCategoryId: InputMaybe<Scalars['UUID']['input']>;
}>;

export type Web_DeleteCategoryMutation = {
  __typename: 'Mutation';
  deleteCategory:
    | {
        __typename: 'DeleteCategoryMutation';
        deleted: boolean | null | undefined;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_GetTopInstitutionGroupByTypeQueryVariables = Exact<{
  dataProviders:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  topInstitutionGroupType: Scalars['String']['input'];
}>;

export type Web_GetTopInstitutionGroupByTypeQuery = {
  __typename: 'Query';
  credentials: Array<{ __typename: 'Credential'; id: string }>;
  topInstitutionGroupByType:
    | {
        __typename: 'TopInstitutionGroup';
        type: TopInstitutionGroupType;
        title: string;
        shortTitle: string;
        accountsConnectedCount: number;
        institutions: Array<{
          __typename: 'Institution';
          id: string;
          name: string;
          logo: string | null | undefined;
          url: string;
          status: InstitutionStatus | null | undefined;
          newConnectionsDisabled: boolean;
          hasIssuesReported: boolean;
          hasIssuesReportedMessage: string;
          linkFlowWarnBeforeConnecting: boolean;
          linkFlowWarningMessage: string | null | undefined;
          linkFlowWarningTitle: string | null | undefined;
          preferredDataProvider: DataProviderLegacy;
          firstBackupDataProvider: DataProviderLegacy | null | undefined;
          plaidInstitutionId: string | null | undefined;
          finicityInstitutionId: string | null | undefined;
          mxInstitutionId: string | null | undefined;
          largeLogo: { __typename: 'FileType'; url: string | null | undefined } | null | undefined;
        }>;
        manualAccountShortcuts:
          | Array<{
              __typename: 'ManualAccountShortcut';
              title: string;
              accountType: { __typename: 'AccountType'; name: string };
              accountSubtype: { __typename: 'AccountSubtype'; name: string } | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_SearchInstitutionsQueryVariables = Exact<{
  search: InputMaybe<Scalars['String']['input']>;
  dataProviders:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type Web_SearchInstitutionsQuery = {
  __typename: 'Query';
  allInstitutions: Array<{
    __typename: 'Institution';
    id: string;
    name: string;
    logo: string | null | undefined;
    url: string;
    status: InstitutionStatus | null | undefined;
    newConnectionsDisabled: boolean;
    hasIssuesReported: boolean;
    hasIssuesReportedMessage: string;
    linkFlowWarnBeforeConnecting: boolean;
    linkFlowWarningMessage: string | null | undefined;
    linkFlowWarningTitle: string | null | undefined;
    preferredDataProvider: DataProviderLegacy;
    firstBackupDataProvider: DataProviderLegacy | null | undefined;
    plaidInstitutionId: string | null | undefined;
    finicityInstitutionId: string | null | undefined;
    mxInstitutionId: string | null | undefined;
    largeLogo: { __typename: 'FileType'; url: string | null | undefined } | null | undefined;
  }>;
};

export type BudgetGroupFragment = { __typename: 'CategoryGroup'; order: number };

export type Web_UpdateCategoryGroupOrderMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  order: Scalars['Int']['input'];
}>;

export type Web_UpdateCategoryGroupOrderMutation = {
  __typename: 'Mutation';
  updateCategoryGroupOrder:
    | {
        __typename: 'UpdateCategoryGroupOrderMutation';
        categoryGroups: Array<{ __typename: 'CategoryGroup'; id: string }>;
      }
    | null
    | undefined;
};

export type BudgetCategoryFragment = {
  __typename: 'Category';
  order: number;
  group: { __typename: 'CategoryGroup'; id: string };
};

export type Web_UpdateCategoryOrderMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  categoryGroupId: Scalars['UUID']['input'];
  order: Scalars['Int']['input'];
}>;

export type Web_UpdateCategoryOrderMutation = {
  __typename: 'Mutation';
  updateCategoryOrderInCategoryGroup:
    | {
        __typename: 'UpdateCategoryOrderInCategoryGroupMutation';
        category: { __typename: 'Category'; id: string };
      }
    | null
    | undefined;
};

export type Common_GetReportsDataQueryVariables = Exact<{
  filters: TransactionFilterInput;
  groupBy: InputMaybe<Array<ReportsGroupByEntity> | ReportsGroupByEntity>;
  isTimeBasedReport: Scalars['Boolean']['input'];
  sortBy: InputMaybe<ReportsSortBy>;
  includeCategory?: InputMaybe<Scalars['Boolean']['input']>;
  includeCategoryGroup?: InputMaybe<Scalars['Boolean']['input']>;
  includeMerchant?: InputMaybe<Scalars['Boolean']['input']>;
  fillEmptyValues?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type Common_GetReportsDataQuery = {
  __typename: 'Query';
  reportsTimeframe: ReportsGroupByTimeframe;
  reports: Array<{
    __typename: 'ReportsData';
    groupBy: {
      __typename: 'ReportsGroupByData';
      date: string | null | undefined;
      category:
        | {
            __typename: 'Category';
            id: string;
            name: string;
            icon: string;
            group: {
              __typename: 'CategoryGroup';
              id: string;
              name: string;
              type: CategoryGroupType;
            };
          }
        | null
        | undefined;
      categoryGroup:
        | { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType }
        | null
        | undefined;
      merchant: { __typename: 'Merchant'; id: string; name: string } | null | undefined;
    };
    summary: {
      __typename: 'TransactionsSummary';
      sum: number;
      avg: number | null | undefined;
      count: number;
      max: number;
      sumIncome: number;
      sumExpense: number;
      savings: number;
      savingsRate: number;
    };
  }>;
  aggregates: Array<{
    __typename: 'AggregateData';
    summary: {
      __typename: 'TransactionsSummary';
      sum: number;
      avg: number | null | undefined;
      count: number;
      max: number;
      sumIncome: number;
      sumExpense: number;
      savings: number;
      savingsRate: number;
    };
  }>;
};

export type ReportsSummaryFieldsFragment = {
  __typename: 'TransactionsSummary';
  sum: number;
  avg: number | null | undefined;
  count: number;
  max: number;
  sumIncome: number;
  sumExpense: number;
  savings: number;
  savingsRate: number;
};

export type ReportsCategoryFieldsFragment = {
  __typename: 'ReportsGroupByData';
  category:
    | {
        __typename: 'Category';
        id: string;
        name: string;
        icon: string;
        group: { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType };
      }
    | null
    | undefined;
};

export type ReportsCategoryGroupFieldsFragment = {
  __typename: 'ReportsGroupByData';
  categoryGroup:
    | { __typename: 'CategoryGroup'; id: string; name: string; type: CategoryGroupType }
    | null
    | undefined;
};

export type ReportsMerchantFieldsFragment = {
  __typename: 'ReportsGroupByData';
  merchant: { __typename: 'Merchant'; id: string; name: string } | null | undefined;
};

export type Web_GetDownloadTransactionsSessionQueryVariables = Exact<{
  sessionKey: Scalars['String']['input'];
}>;

export type Web_GetDownloadTransactionsSessionQuery = {
  __typename: 'Query';
  downloadTransactionsSession:
    | {
        __typename: 'DownloadTransactionsSession';
        sessionKey: string;
        status: string;
        errorMessage: string | null | undefined;
        url: string | null | undefined;
      }
    | null
    | undefined;
};

export type Web_DownloadTransactionsMutationVariables = Exact<{
  filters: TransactionFilterInput;
  orderBy: InputMaybe<Scalars['String']['input']>;
}>;

export type Web_DownloadTransactionsMutation = {
  __typename: 'Mutation';
  startDownloadTransactionsSession:
    | { __typename: 'StartDownloadTransactionsSession'; sessionKey: string; status: string }
    | null
    | undefined;
};

export type UploadBalanceHistorySessionFieldsFragment = {
  __typename: 'UploadBalanceHistorySession';
  sessionKey: string;
  status: string;
};

export type Web_GetUploadBalanceHistorySessionQueryVariables = Exact<{
  sessionKey: Scalars['String']['input'];
}>;

export type Web_GetUploadBalanceHistorySessionQuery = {
  __typename: 'Query';
  uploadBalanceHistorySession:
    | { __typename: 'UploadBalanceHistorySession'; sessionKey: string; status: string }
    | null
    | undefined;
};

export type Web_ParseUploadBalanceHistorySessionMutationVariables = Exact<{
  input: ParseBalanceHistoryInput;
}>;

export type Web_ParseUploadBalanceHistorySessionMutation = {
  __typename: 'Mutation';
  parseBalanceHistory:
    | {
        __typename: 'ParseBalanceHistoryMutation';
        uploadBalanceHistorySession: {
          __typename: 'UploadBalanceHistorySession';
          sessionKey: string;
          status: string;
        };
      }
    | null
    | undefined;
};

export type UploadStatementSessionFieldsFragment = {
  __typename: 'UploadStatementSession';
  sessionKey: string;
  status: string;
  errorMessage: string | null | undefined;
  skipCheckForDuplicates: boolean | null | undefined;
  uploadedStatement:
    | { __typename: 'UploadedStatement'; id: string; transactionCount: number }
    | null
    | undefined;
};

export type GetUploadStatementSessionQueryVariables = Exact<{
  sessionKey: Scalars['String']['input'];
}>;

export type GetUploadStatementSessionQuery = {
  __typename: 'Query';
  uploadStatementSession:
    | {
        __typename: 'UploadStatementSession';
        sessionKey: string;
        status: string;
        errorMessage: string | null | undefined;
        skipCheckForDuplicates: boolean | null | undefined;
        uploadedStatement:
          | { __typename: 'UploadedStatement'; id: string; transactionCount: number }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_ParseUploadStatementSessionMutationVariables = Exact<{
  input: ParseStatementInput;
}>;

export type Web_ParseUploadStatementSessionMutation = {
  __typename: 'Mutation';
  parseUploadStatementSession:
    | {
        __typename: 'ParseStatementMutation';
        uploadStatementSession: {
          __typename: 'UploadStatementSession';
          sessionKey: string;
          status: string;
          errorMessage: string | null | undefined;
          skipCheckForDuplicates: boolean | null | undefined;
          uploadedStatement:
            | { __typename: 'UploadedStatement'; id: string; transactionCount: number }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type Web_GetFilteredAccountsQueryVariables = Exact<{
  filters: InputMaybe<AccountFilters>;
}>;

export type Web_GetFilteredAccountsQuery = {
  __typename: 'Query';
  accounts: Array<{
    __typename: 'Account';
    id: string;
    displayName: string;
    displayBalance: number | null | undefined;
    icon: string;
    logoUrl: string | null | undefined;
    order: number;
    isAsset: boolean;
    includeBalanceInNetWorth: boolean;
    deactivatedAt: string | null | undefined;
    manualInvestmentsTrackingMethod: string | null | undefined;
    isManual: boolean;
    syncDisabled: boolean;
    type: { __typename: 'AccountType'; display: string; name: string };
  }>;
};

export type CreateAccountsFromPlaidTokenMutationVariables = Exact<{
  publicToken: Scalars['String']['input'];
  plaidInstitutionId: Scalars['String']['input'];
  plaidInstitutionName: Scalars['String']['input'];
  linkSessionId: Scalars['String']['input'];
}>;

export type CreateAccountsFromPlaidTokenMutation = {
  __typename: 'Mutation';
  createAccountsFromPlaidToken:
    | {
        __typename: 'CreateAccountsFromPlaidToken';
        accounts: Array<{
          __typename: 'Account';
          id: string;
          displayName: string;
          syncDisabled: boolean;
          deactivatedAt: string | null | undefined;
          isHidden: boolean;
          isAsset: boolean;
          mask: string | null | undefined;
          createdAt: string;
          updatedAt: string;
          displayLastUpdatedAt: string;
          currentBalance: number | null | undefined;
          displayBalance: number | null | undefined;
          includeInNetWorth: boolean;
          hideFromList: boolean;
          hideTransactionsFromReports: boolean;
          includeBalanceInNetWorth: boolean;
          includeInGoalBalance: boolean;
          dataProvider: string | null | undefined;
          dataProviderAccountId: string | null | undefined;
          isManual: boolean;
          transactionsCount: number;
          holdingsCount: number;
          manualInvestmentsTrackingMethod: string | null | undefined;
          order: number;
          icon: string;
          logoUrl: string | null | undefined;
          type: { __typename: 'AccountType'; name: string; display: string; group: string };
          subtype: { __typename: 'AccountSubtype'; name: string; display: string };
          credential:
            | {
                __typename: 'Credential';
                id: string;
                updateRequired: boolean;
                disconnectedFromDataProviderAt: string | null | undefined;
                dataProvider: DataProviderLegacy;
                institution: {
                  __typename: 'Institution';
                  id: string;
                  plaidInstitutionId: string | null | undefined;
                  name: string;
                  status: InstitutionStatus | null | undefined;
                  logo: string | null | undefined;
                };
              }
            | null
            | undefined;
          institution:
            | {
                __typename: 'Institution';
                id: string;
                name: string;
                logo: string | null | undefined;
                primaryColor: string;
                url: string;
              }
            | null
            | undefined;
        }>;
        errors:
          | {
              __typename: 'PayloadError';
              message: string | null | undefined;
              code: ErrorCode | null | undefined;
              fieldErrors:
                | Array<{ __typename: 'FieldError'; field: string; messages: Array<string> }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type Web_TransactionsFilterQueryVariables = Exact<{
  search: InputMaybe<Scalars['String']['input']>;
  includeIds: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type Web_TransactionsFilterQuery = {
  __typename: 'Query';
  categoryGroups: Array<{
    __typename: 'CategoryGroup';
    id: string;
    name: string;
    order: number;
    categories: Array<{
      __typename: 'Category';
      id: string;
      name: string;
      icon: string;
      order: number;
    }>;
  }>;
  merchants: Array<{ __typename: 'Merchant'; id: string; name: string; transactionCount: number }>;
  accounts: Array<{
    __typename: 'Account';
    id: string;
    displayName: string;
    logoUrl: string | null | undefined;
    icon: string;
    type: { __typename: 'AccountType'; name: string; display: string };
  }>;
  householdTransactionTags: Array<{
    __typename: 'TransactionTag';
    id: string;
    name: string;
    order: number;
    color: string;
  }>;
  myHousehold: {
    __typename: 'Household';
    id: string;
    users: Array<{ __typename: 'User'; id: string; name: string }>;
  };
};

export type Web_MintTransactionsCountQueryVariables = Exact<{ [key: string]: never }>;

export type Web_MintTransactionsCountQuery = {
  __typename: 'Query';
  allMintTransactions: { __typename: 'TransactionList'; totalCount: number };
};

export const InstitutionStatusFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstitutionStatusFieldsFragment, unknown>;
export const SubscriptionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChargedForLifetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasStripeSubscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eligibleForTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'willCancelAtPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBillingIssue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePromoCode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsoredBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionFieldsFragment, unknown>;
export const SponsoredSubscriptionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SponsoredSubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSponsorship' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sponsor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SponsoredSubscriptionFieldsFragment, unknown>;
export const BudgetRolloverPeriodFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetRolloverPeriodFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetRolloverPeriod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetRolloverPeriodFieldsFragment, unknown>;
export const BudgetCategoryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetCategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetRolloverPeriodFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetRolloverPeriodFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetRolloverPeriod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetCategoryFieldsFragment, unknown>;
export const BudgetCategoryGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetCategoryGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BudgetCategoryFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetRolloverPeriodFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetRolloverPeriod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetCategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetRolloverPeriodFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetCategoryGroupFieldsFragment, unknown>;
export const BudgetDataGoalsV2FieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataGoalsV2Fields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plannedContributions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyContributionSummaries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetDataGoalsV2FieldsFragment, unknown>;
export const BudgetDataMonthlyAmountsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthlyAmounts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedCashFlowAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedSetAsideAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cumulativeActualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverTargetAmount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetDataMonthlyAmountsFieldsFragment, unknown>;
export const BudgetMonthlyAmountsByCategoryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BudgetCategoryMonthlyAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthlyAmounts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedCashFlowAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedSetAsideAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cumulativeActualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverTargetAmount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetMonthlyAmountsByCategoryFieldsFragment, unknown>;
export const BudgetMonthlyAmountsByCategoryGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryGroupFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BudgetCategoryGroupMonthlyAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthlyAmounts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedCashFlowAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedSetAsideAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cumulativeActualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverTargetAmount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetMonthlyAmountsByCategoryGroupFieldsFragment, unknown>;
export const BudgetMonthlyAmountsForFlexExpenseFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthlyAmountsForFlexExpenseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BudgetFlexMonthlyAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthlyAmounts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedCashFlowAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedSetAsideAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cumulativeActualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverTargetAmount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetMonthlyAmountsForFlexExpenseFieldsFragment, unknown>;
export const BudgetDataTotalsByMonthFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetTotals' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetDataTotalsByMonthFieldsFragment, unknown>;
export const BudgetTotalsByMonthFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetTotalsByMonthFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthTotals' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalIncome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalFixedExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalNonMonthlyExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalFlexibleExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetTotals' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetTotalsByMonthFieldsFragment, unknown>;
export const BudgetDataFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmountsByCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmountsByCategoryGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryGroupFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmountsForFlexExpense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetMonthlyAmountsForFlexExpenseFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalsByMonth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetTotalsByMonthFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthlyAmounts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedCashFlowAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedSetAsideAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cumulativeActualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverTargetAmount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetTotals' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BudgetCategoryMonthlyAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryGroupFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BudgetCategoryGroupMonthlyAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthlyAmountsForFlexExpenseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BudgetFlexMonthlyAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetTotalsByMonthFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthTotals' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalIncome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalFixedExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalNonMonthlyExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalFlexibleExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetDataFieldsFragment, unknown>;
export const BudgetStatusFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hasBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTransactions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'willCreateBudgetFromEmptyDefaultCategories' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetStatusFieldsFragment, unknown>;
export const CategoryGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CategoryGroupFieldsFragment, unknown>;
export const PayloadErrorFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PayloadErrorFieldsFragment, unknown>;
export const GoalPlannedContributionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalPlannedContributionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalPlannedContribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalPlannedContributionFieldsFragment, unknown>;
export const HouseholdFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HouseholdFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Household' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HouseholdFieldsFragment, unknown>;
export const NotificationPreferenceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationPreferenceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationPreference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'group' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalPreferences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalPreferencesMeta' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'EMAIL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PUSH' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NotificationPreferenceFieldsFragment, unknown>;
export const AccountFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInGoalBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountFieldsFragment, unknown>;
export const CategoryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CategoryFieldsFragment, unknown>;
export const TransactionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInGoalBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionFieldsFragment, unknown>;
export const TransactionGoalFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionGoalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProvider' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProviderId' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionGoalFieldsFragment, unknown>;
export const UserFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasMfaOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalAuthProviderNames' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingEmailUpdateVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cloudinaryPublicId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSupportAccountAccessGrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFieldsFragment, unknown>;
export const DashboardConfigFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PlatformDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'layout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'widgets' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardConfigFragment, unknown>;
export const HouseholdDashboardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HouseholdDashboard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Household' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dashboardConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'web' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DashboardConfig' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DashboardConfig' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PlatformDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'layout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'widgets' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HouseholdDashboardFragment, unknown>;
export const GoalAllocationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAllocationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalAccountAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useEntireAccountBalance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableBalanceForGoals' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalAllocationFieldsFragment, unknown>;
export const GoalAllocationCacheFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAllocationCacheFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalAllocationFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAllocationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalAccountAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useEntireAccountBalance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableBalanceForGoals' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalAllocationCacheFieldsFragment, unknown>;
export const GoalAccountsMapRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAccountsMapRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtype' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalAccountsMapRowFieldsFragment, unknown>;
export const NewAccountLogoFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewAccountLogoFieldsFragment, unknown>;
export const GoalAccountAllocationRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAccountAllocationRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availableBalanceForGoals' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useEntireAccountBalance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalAccountAllocationRowFieldsFragment, unknown>;
export const AllocationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllocationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalAccountAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useEntireAccountBalance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllocationFieldsFragment, unknown>;
export const HouseholdPreferencesFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HouseholdPreferencesFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdPreferences' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newTransactionsNeedReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uncategorizedTransactionsNeedReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pendingTransactionsCanBeEdited' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountGroupOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiAssistantEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'llmEnrichmentEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investmentTransactionsEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HouseholdPreferencesFieldsFragment, unknown>;
export const ReviewCheckinFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReviewCheckinFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewCheckin' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastPageIndexViewed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStartDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewCheckinFieldsFragment, unknown>;
export const UserProfileFlagsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserProfileFlagsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiAssistantOptedInAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedFlexBudgetingWalkthroughAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedRecurringWalkthroughAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedSpendingInsightsBanner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dismissedTransactionsListUpdatesTourAt' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedYearlyReviewAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasDismissedWhatsNewAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSeenCategoriesManagementTour' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSeenWeeklyReviewTour' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewedMarkAsReviewedUpdatesCalloutAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserProfileFlagsFieldsFragment, unknown>;
export const AccountLogoFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountLogoFieldsFragment, unknown>;
export const AccountComponentFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountComponentFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountLogoFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountComponentFieldsFragment, unknown>;
export const AccountSummaryCardAccountFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountSummaryCardAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountSummaryCardAccountFieldsFragment, unknown>;
export const ConnectionStatusCardFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionStatusCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConnectionStatusCardFieldsFragment, unknown>;
export const Mobile_UserMessageListGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_UserMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_UserMessageListGroupFieldsFragment, unknown>;
export const Mobile_AssistantMessageListGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_AssistantMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedPrompts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_AssistantMessageListGroupFieldsFragment, unknown>;
export const Mobile_AssistantStatusMessageListGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_AssistantStatusMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantStatusMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_AssistantStatusMessageListGroupFieldsFragment, unknown>;
export const Mobile_MessageListGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_MessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_UserMessageListGroupFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_AssistantMessageListGroupFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_AssistantStatusMessageListGroupFields' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_UserMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_AssistantMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedPrompts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_AssistantStatusMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantStatusMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_MessageListGroupFieldsFragment, unknown>;
export const Mobile_MessageThreadListItemFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_MessageThreadListItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastMessageSentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOutstandingAssistantRequests' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_MessageThreadListItemFieldsFragment, unknown>;
export const GoalDashboardRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDashboardRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentMonthChange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalDashboardRowFieldsFragment, unknown>;
export const CustomizeGoalFormFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomizeGoalFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomizeGoalFormFieldsFragment, unknown>;
export const GoalNameProgressBarFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalNameProgressBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalNameProgressBarFieldsFragment, unknown>;
export const GoalCardFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AccountLogoFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalNameProgressBarFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalNameProgressBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalCardFieldsFragment, unknown>;
export const AccountMaskFieldsMobileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountMaskFieldsMobile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountMaskFieldsMobileFragment, unknown>;
export const GoalAccountBalanceRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAccountBalanceRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalAccountAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMonthChange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountMaskFieldsMobile' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountMaskFieldsMobile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalAccountBalanceRowFieldsFragment, unknown>;
export const GoalContributionGraphFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalContributionGraphFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyContributionSummaries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sumCredit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sumDebit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalContributionGraphFieldsFragment, unknown>;
export const GoalDetailsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDetailsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyPretaxContribution' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objective' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalAccountBalanceRowFields' },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalContributionGraphFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountMaskFieldsMobile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAccountBalanceRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalAccountAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMonthChange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountMaskFieldsMobile' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalContributionGraphFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyContributionSummaries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sumCredit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sumDebit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalDetailsFieldsFragment, unknown>;
export const MobileGoalsAllocateBannerFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MobileGoalsAllocateBannerFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MobileGoalsAllocateBannerFieldsFragment, unknown>;
export const GoalInfoRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalInfoRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalInfoRowFieldsFragment, unknown>;
export const RankGoalRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankGoalRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalInfoRowFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalInfoRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RankGoalRowFieldsFragment, unknown>;
export const Mobile_InstitutionLogoWithStatusFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_InstitutionLogoWithStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_InstitutionLogoWithStatusFieldsFragment, unknown>;
export const Mobile_InstitutionInfoFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_InstitutionInfoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_InstitutionLogoWithStatusFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_InstitutionLogoWithStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_InstitutionInfoFieldsFragment, unknown>;
export const CredentialCardFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CredentialCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Mobile_InstitutionInfoFields' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_InstitutionLogoWithStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_InstitutionInfoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_InstitutionLogoWithStatusFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CredentialCardFieldsFragment, unknown>;
export const InstitutionAccountRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionAccountRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstitutionAccountRowFieldsFragment, unknown>;
export const Mobile_InstitutionRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_InstitutionRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'largeLogo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarnBeforeConnecting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningTitle' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_InstitutionRowFieldsFragment, unknown>;
export const AllocationChartFieldsMobileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllocationChartFieldsMobile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationSimple' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeDisplay' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocationPercent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalValue' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllocationChartFieldsMobileFragment, unknown>;
export const InvestmentChartsFieldsMobileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestmentChartsFieldsMobile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllocationChartFieldsMobile' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllocationChartFieldsMobile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationSimple' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeDisplay' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocationPercent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalValue' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvestmentChartsFieldsMobileFragment, unknown>;
export const FreeTrialDurationPanelFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreeTrialDurationPanelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialDurationDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreeTrialDurationPanelFieldsFragment, unknown>;
export const PageBackgroundFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageBackgroundFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewPageBackground' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gradient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'colors' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageBackgroundFieldsFragment, unknown>;
export const PageTitleBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTitleBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTitleBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'header' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'centerSubtitle' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageTitleBlockFieldsFragment, unknown>;
export const TextBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TextBlockFieldsFragment, unknown>;
export const HorizontalBarChartBarFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HorizontalBarChartBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HorizontalBarChartBar' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLabel' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HorizontalBarChartBarFieldsFragment, unknown>;
export const HorizontalBarChartBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HorizontalBarChartBlockFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HorizontalBarChartBlock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'topRightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smallBarStyle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bars' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HorizontalBarChartBarFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HorizontalBarChartBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HorizontalBarChartBar' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLabel' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HorizontalBarChartBlockFieldsFragment, unknown>;
export const PieChartBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PieChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PieChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PieChartBlockFieldsFragment, unknown>;
export const SparklineRowBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SparklineRowBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SparklineRowBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labelColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lineColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'values' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'largeStyle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideValueChange' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SparklineRowBlockFieldsFragment, unknown>;
export const MerchantsListBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MerchantsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MerchantsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MerchantsListBlockFieldsFragment, unknown>;
export const TransactionsListBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionsListBlockFieldsFragment, unknown>;
export const AccountsListBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalanceAsOf' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountsListBlockFieldsFragment, unknown>;
export const NetWorthChartBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetWorthChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NetWorthChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotsByAccountType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetWorthChartBlockFieldsFragment, unknown>;
export const StackedBarChartBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StackedBarChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StackedBarChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'groupColors' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StackedBarChartBlockFieldsFragment, unknown>;
export const ShareableBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShareableBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShareableBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedSubtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedFooter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareButtonText' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShareableBlockFieldsFragment, unknown>;
export const InfoBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfoBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InfoBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InfoBlockFieldsFragment, unknown>;
export const GroupBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'centered' } }],
      },
    },
  ],
} as unknown as DocumentNode<GroupBlockFieldsFragment, unknown>;
export const AllBlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllBlockTypes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PageTitleBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextBlockFields' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'HorizontalBarChartBlockFields' },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PieChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SparklineRowBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MerchantsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NetWorthChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StackedBarChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShareableBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InfoBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GroupBlockFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HorizontalBarChartBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HorizontalBarChartBar' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLabel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTitleBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTitleBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'header' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'centerSubtitle' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HorizontalBarChartBlockFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HorizontalBarChartBlock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'topRightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smallBarStyle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bars' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HorizontalBarChartBarFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PieChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PieChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SparklineRowBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SparklineRowBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labelColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lineColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'values' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'largeStyle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideValueChange' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MerchantsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MerchantsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalanceAsOf' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetWorthChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NetWorthChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotsByAccountType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StackedBarChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StackedBarChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'groupColors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShareableBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShareableBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedSubtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedFooter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareButtonText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfoBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InfoBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'centered' } }],
      },
    },
  ],
} as unknown as DocumentNode<AllBlockFieldsFragment, unknown>;
export const BlockLinkFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BlockLinkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Block' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blocks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllBlockFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTitleBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTitleBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'header' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'centerSubtitle' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HorizontalBarChartBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HorizontalBarChartBar' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLabel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HorizontalBarChartBlockFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HorizontalBarChartBlock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'topRightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smallBarStyle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bars' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HorizontalBarChartBarFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PieChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PieChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SparklineRowBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SparklineRowBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labelColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lineColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'values' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'largeStyle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideValueChange' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MerchantsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MerchantsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalanceAsOf' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetWorthChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NetWorthChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotsByAccountType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StackedBarChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StackedBarChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'groupColors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShareableBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShareableBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedSubtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedFooter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareButtonText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfoBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InfoBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'centered' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllBlockTypes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PageTitleBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextBlockFields' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'HorizontalBarChartBlockFields' },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PieChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SparklineRowBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MerchantsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NetWorthChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StackedBarChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShareableBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InfoBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GroupBlockFields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlockLinkFieldsFragment, unknown>;
export const BlockFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllBlockTypes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllBlockFields' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupBlock' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllBlockFields' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShareableBlock' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllBlockFields' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HorizontalBarChartBlock' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bars' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PieChartBlock' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StackedBarChartBlock' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'links' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'blocks' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AllBlockFields' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTitleBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTitleBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'header' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'centerSubtitle' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HorizontalBarChartBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HorizontalBarChartBar' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLabel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HorizontalBarChartBlockFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HorizontalBarChartBlock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'topRightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smallBarStyle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bars' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HorizontalBarChartBarFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PieChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PieChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SparklineRowBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SparklineRowBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labelColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lineColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'values' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'largeStyle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideValueChange' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MerchantsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MerchantsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalanceAsOf' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetWorthChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NetWorthChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotsByAccountType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StackedBarChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StackedBarChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'groupColors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShareableBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShareableBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedSubtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedFooter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareButtonText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfoBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InfoBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'centered' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllBlockTypes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PageTitleBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextBlockFields' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'HorizontalBarChartBlockFields' },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PieChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SparklineRowBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MerchantsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NetWorthChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StackedBarChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShareableBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InfoBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GroupBlockFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BlockLinkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Block' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blocks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllBlockFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlockFieldsFragment, unknown>;
export const ReviewHeaderFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReviewHeaderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewPage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'header' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTitleBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTitleBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'header' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'centerSubtitle' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HorizontalBarChartBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HorizontalBarChartBar' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLabel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HorizontalBarChartBlockFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HorizontalBarChartBlock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'topRightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smallBarStyle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bars' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HorizontalBarChartBarFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PieChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PieChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SparklineRowBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SparklineRowBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labelColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lineColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'values' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'largeStyle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideValueChange' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MerchantsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MerchantsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalanceAsOf' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetWorthChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NetWorthChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotsByAccountType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StackedBarChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StackedBarChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'groupColors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShareableBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShareableBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedSubtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedFooter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareButtonText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfoBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InfoBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'centered' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllBlockTypes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PageTitleBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextBlockFields' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'HorizontalBarChartBlockFields' },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PieChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SparklineRowBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MerchantsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NetWorthChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StackedBarChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShareableBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InfoBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GroupBlockFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BlockLinkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Block' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blocks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllBlockFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllBlockTypes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllBlockFields' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupBlock' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllBlockFields' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShareableBlock' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllBlockFields' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HorizontalBarChartBlock' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bars' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PieChartBlock' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StackedBarChartBlock' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'links' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'blocks' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AllBlockFields' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewHeaderFieldsFragment, unknown>;
export const TransactionRuleFieldsMobileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionRuleFieldsMobile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionRuleV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'merchantCriteriaUseOriginalStatement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchantCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isExpense' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'categoryIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setMerchantAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setCategoryAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTagsAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkGoalAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendNotificationAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'setHideFromReportsAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatusAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastAppliedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recentApplicationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unassignNeedsReviewByUserAction' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsReviewByUserAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'splitTransactionsAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amountType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'splitsInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'goalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'needsReviewByUserId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionRuleFieldsMobileFragment, unknown>;
export const RapidReviewTransactionFormFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RapidReviewTransactionFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProvider' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProviderId' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalAssetUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsReviewByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RapidReviewTransactionFormFieldsFragment, unknown>;
export const RapidReviewCategoryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RapidReviewCategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RapidReviewCategoryFieldsFragment, unknown>;
export const Mobile_OriginalTransactionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_OriginalTransactionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_OriginalTransactionFieldsFragment, unknown>;
export const ReorderedCategoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReorderedCategory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReorderedCategoryFragment, unknown>;
export const AccountFromCacheFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFromCache' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountFromCacheFragment, unknown>;
export const BudgetMonthAmountsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthAmounts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthlyAmounts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetMonthAmountsFragment, unknown>;
export const AggregatesSummaryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AggregatesSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AggregateData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AggregatesSummaryFieldsFragment, unknown>;
export const MyHouseholdUsersFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyHouseholdUsersFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'householdRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasMfaOn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyHouseholdUsersFieldsFragment, unknown>;
export const HouseholdAccessGrantsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HouseholdAccessGrantsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdAccessGrant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'toEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'toName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HouseholdAccessGrantsFieldsFragment, unknown>;
export const AccountMaskFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountMaskFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountMaskFieldsFragment, unknown>;
export const InstitutionStatusTooltipFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionStatusTooltipFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstitutionStatusTooltipFieldsFragment, unknown>;
export const AccountListItemFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountListItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountMaskFields' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstitutionStatusTooltipFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountMaskFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionStatusTooltipFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountListItemFieldsFragment, unknown>;
export const AccountsListFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountsListFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountListItemFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountMaskFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionStatusTooltipFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountListItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountMaskFields' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstitutionStatusTooltipFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountsListFieldsFragment, unknown>;
export const EditAccountFormFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditAccountFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasCustomizedLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invertSyncedBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canInvertBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useAvailableBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canUseAvailableBalance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditReportLiabilityAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastStatement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dayOfTheMonth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultPaymentAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultPaymentCategory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditAccountFormFieldsFragment, unknown>;
export const InstitutionStatusIndicatorFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionStatusIndicatorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstitutionStatusIndicatorFieldsFragment, unknown>;
export const LinkInstitutionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LinkInstitutionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarnBeforeConnecting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningTitle' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LinkInstitutionFieldsFragment, unknown>;
export const AdviceCategoryIconFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItemCategory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdviceCategoryIconFieldsFragment, unknown>;
export const AdviceItemRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceItemRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasksCompleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasksRemaining' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItemCategory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdviceItemRowFieldsFragment, unknown>;
export const AdviceItemListFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceItemListFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdviceItemRowFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItemCategory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceItemRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasksCompleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasksRemaining' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdviceItemListFieldsFragment, unknown>;
export const AdviceTaskRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceTaskRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItemTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdviceTaskRowFieldsFragment, unknown>;
export const CondensedAdviceItemRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CondensedAdviceItemRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasksCompleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItemCategory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CondensedAdviceItemRowFieldsFragment, unknown>;
export const UserMessageListGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserMessageListGroupFieldsFragment, unknown>;
export const AssistantMessageListGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssistantMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedPrompts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssistantMessageListGroupFieldsFragment, unknown>;
export const AssistantStatusMessageListGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssistantStatusMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantStatusMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssistantStatusMessageListGroupFieldsFragment, unknown>;
export const MessageListGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserMessageListGroupFields' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'AssistantMessageListGroupFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'AssistantStatusMessageListGroupFields' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssistantMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedPrompts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssistantStatusMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantStatusMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageListGroupFieldsFragment, unknown>;
export const MessageThreadListItemFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageThreadListItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastMessageSentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOutstandingAssistantRequests' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageThreadListItemFieldsFragment, unknown>;
export const CategoryPlanSummaryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryPlanSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthlyAmounts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CategoryPlanSummaryFieldsFragment, unknown>;
export const FilteredCashFlowSummaryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FilteredCashFlowSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionsSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilteredCashFlowSummaryFieldsFragment, unknown>;
export const CategoryFormFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategoryDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CategoryFormFieldsFragment, unknown>;
export const EditGoalAccountRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditGoalAccountRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountMaskFields' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedBalance' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountMaskFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditGoalAccountRowFieldsFragment, unknown>;
export const GoalTimelinePointFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalTimelinePointFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalTimelinePoint' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectedBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedBalance' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalTimelinePointFieldsFragment, unknown>;
export const EditPlanFormGraphFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditPlanFormGraphFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Goal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contributedBalance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalTimelinePointFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalTimelinePointFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalTimelinePoint' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectedBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedBalance' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditPlanFormGraphFieldsFragment, unknown>;
export const EditGoalFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditGoalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Goal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'goalType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EditPlanFormGraphFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalTimelinePointFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalTimelinePoint' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectedBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedBalance' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditPlanFormGraphFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Goal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contributedBalance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalTimelinePointFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditGoalFieldsFragment, unknown>;
export const GoalContributeCardBaseFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalContributeCardBaseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Goal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectedCompletionDateGivenContribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'proposedContributionAmount' },
                value: { kind: 'IntValue', value: '0' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalContributeCardBaseFieldsFragment, unknown>;
export const GoalContributeCardAnimateProgressFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalContributeCardAnimateProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Goal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contributedBalance' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalContributeCardBaseFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalContributeCardBaseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Goal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectedCompletionDateGivenContribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'proposedContributionAmount' },
                value: { kind: 'IntValue', value: '0' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalContributeCardAnimateProgressFieldsFragment, unknown>;
export const GoalContributeCardInputFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalContributeCardInputFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Goal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contributedBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalContributeCardBaseFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalContributeCardBaseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Goal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectedCompletionDateGivenContribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'proposedContributionAmount' },
                value: { kind: 'IntValue', value: '0' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalContributeCardInputFieldsFragment, unknown>;
export const WebGoalAccountBalanceRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalAccountBalanceRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalAccountAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMonthChange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountMaskFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountMaskFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WebGoalAccountBalanceRowFieldsFragment, unknown>;
export const WebGoalBalancesCardFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalBalancesCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WebGoalAccountBalanceRowFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountMaskFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalAccountBalanceRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalAccountAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMonthChange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountMaskFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WebGoalBalancesCardFieldsFragment, unknown>;
export const WebGoalNameProgressBarFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalNameProgressBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WebGoalNameProgressBarFieldsFragment, unknown>;
export const WebGoalCardFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WebGoalNameProgressBarFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalNameProgressBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WebGoalCardFieldsFragment, unknown>;
export const WebGoalContributionGraphFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalContributionGraphFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyContributionSummaries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sumCredit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sumDebit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WebGoalContributionGraphFieldsFragment, unknown>;
export const GoalCustomizeModalFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalCustomizeModalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalCustomizeModalFieldsFragment, unknown>;
export const Web_GoalDashboardRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Web_GoalDashboardRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentMonthChange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GoalDashboardRowFieldsFragment, unknown>;
export const WebGoalDetailsCardFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalDetailsCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyPretaxContribution' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objective' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WebGoalDetailsCardFieldsFragment, unknown>;
export const Web_GoalInfoRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Web_GoalInfoRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GoalInfoRowFieldsFragment, unknown>;
export const GoalRankCardFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRankCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalRankCardFieldsFragment, unknown>;
export const Web_GoalsAllocateBannerFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Web_GoalsAllocateBannerFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GoalsAllocateBannerFieldsFragment, unknown>;
export const InstitutionLogoWithStatusFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionLogoWithStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstitutionLogoWithStatusFieldsFragment, unknown>;
export const InstitutionInfoFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionInfoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'InstitutionLogoWithStatusFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionLogoWithStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstitutionInfoFieldsFragment, unknown>;
export const CredentialSettingsCardFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CredentialSettingsCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstitutionInfoFields' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionLogoWithStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionInfoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'InstitutionLogoWithStatusFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CredentialSettingsCardFieldsFragment, unknown>;
export const InstitutionRowFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'largeLogo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarnBeforeConnecting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstitutionRowFieldsFragment, unknown>;
export const StateFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StateFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfileQuestionnaireState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'numQuestionsAnswered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numQuestions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StateFieldsFragment, unknown>;
export const QuestionHeaderFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionHeaderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuestionHeaderFieldsFragment, unknown>;
export const QuestionFormFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'answer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dontKnow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answeredAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuestionFormFieldsFragment, unknown>;
export const QuestionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'QuestionHeaderFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'QuestionFormFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionHeaderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'answer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dontKnow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answeredAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuestionFieldsFragment, unknown>;
export const FreeTrialDurationPanelDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreeTrialDurationPanelData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialDurationDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreeTrialDurationPanelDataFragment, unknown>;
export const TransactionRuleFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionRuleFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionRuleV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'merchantCriteriaUseOriginalStatement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchantCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isExpense' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'categoryIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setMerchantAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setCategoryAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTagsAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkGoalAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsReviewByUserAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'unassignNeedsReviewByUserAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendNotificationAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'setHideFromReportsAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatusAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recentApplicationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastAppliedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'splitTransactionsAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amountType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'splitsInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'goalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'needsReviewByUserId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionRuleFieldsFragment, unknown>;
export const TransactionOverviewFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionOverviewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionOverviewFieldsFragment, unknown>;
export const TransactionsListFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionsListFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionOverviewFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionOverviewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionsListFieldsFragment, unknown>;
export const TransactionsSummaryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionsSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionsSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxExpense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionsSummaryFieldsFragment, unknown>;
export const TransactionDrawerSplitMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionDrawerSplitMessageFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionDrawerSplitMessageFieldsFragment, unknown>;
export const OriginalTransactionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OriginalTransactionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OriginalTransactionFieldsFragment, unknown>;
export const AccountLinkFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLinkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountLinkFieldsFragment, unknown>;
export const TransactionDrawerFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionDrawerFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSplitTransactions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'splitTransactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransactionDrawerSplitMessageFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalTransaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OriginalTransactionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sizeBytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalAssetUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountLinkFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsReviewByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionOverviewFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionDrawerSplitMessageFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OriginalTransactionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLinkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionOverviewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionDrawerFieldsFragment, unknown>;
export const AccountOrderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountOrder' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountOrderFragment, unknown>;
export const BudgetGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'order' } }],
      },
    },
  ],
} as unknown as DocumentNode<BudgetGroupFragment, unknown>;
export const BudgetCategoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetCategory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BudgetCategoryFragment, unknown>;
export const ReportsSummaryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionsSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportsSummaryFieldsFragment, unknown>;
export const ReportsCategoryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsCategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportsGroupByData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportsCategoryFieldsFragment, unknown>;
export const ReportsCategoryGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsCategoryGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportsGroupByData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportsCategoryGroupFieldsFragment, unknown>;
export const ReportsMerchantFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsMerchantFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportsGroupByData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportsMerchantFieldsFragment, unknown>;
export const UploadBalanceHistorySessionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UploadBalanceHistorySessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UploadBalanceHistorySession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sessionKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadBalanceHistorySessionFieldsFragment, unknown>;
export const UploadStatementSessionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UploadStatementSessionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadStatementSession' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sessionKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skipCheckForDuplicates' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadedStatement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadStatementSessionFieldsFragment, unknown>;
export const Common_GetAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountLogoFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_GetAccountQuery, Common_GetAccountQueryVariables>;
export const GetAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'householdPreferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountGroupOrder' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInGoalBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAccountsQuery, GetAccountsQueryVariables>;
export const Common_UpdateAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAccountMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInGoalBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_UpdateAccountMutation, Common_UpdateAccountMutationVariables>;
export const Common_BulkUpdateAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_BulkUpdateAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'UpdateAccountsMutationInput' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInGoalBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_BulkUpdateAccountsMutation,
  Common_BulkUpdateAccountsMutationVariables
>;
export const Common_DeleteAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_DeleteAccountMutation, Common_DeleteAccountMutationVariables>;
export const GetAccountTypeOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountTypeOptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountTypeOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'possibleSubtypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtype' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAccountTypeOptionsQuery, GetAccountTypeOptionsQueryVariables>;
export const GetHasAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHasAccounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasAccounts' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetHasAccountsQuery, GetHasAccountsQueryVariables>;
export const GetHasSyncedAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHasSyncedAccounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasAccounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'onlySynced' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHasSyncedAccountsQuery, GetHasSyncedAccountsQueryVariables>;
export const GetInstitutionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInstitution' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plaidId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'plaidId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'plaidId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstitutionStatusFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetInstitutionQuery, GetInstitutionQueryVariables>;
export const Common_GetAccountsForTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetAccountsForTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedTransactionIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'excludedTransactionIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isAllSelected' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAccountsForTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'selectedTransactionIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'selectedTransactionIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludedTransactionIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'excludedTransactionIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'isAllSelected' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'isAllSelected' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtype' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetAccountsForTransactionsQuery,
  Common_GetAccountsForTransactionsQueryVariables
>;
export const Common_ForceRefreshAccountMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_ForceRefreshAccountMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ForceRefreshAccountInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forceRefreshAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_ForceRefreshAccountMutation,
  Common_ForceRefreshAccountMutationVariables
>;
export const Common_ForceRefreshAccountsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_ForceRefreshAccountsMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forceRefreshAllAccounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_ForceRefreshAccountsMutation,
  Common_ForceRefreshAccountsMutationVariables
>;
export const Common_CreateManualInvestmentsAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateManualInvestmentsAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateManualInvestmentsAccountInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createManualInvestmentsAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateManualInvestmentsAccountMutation,
  Common_CreateManualInvestmentsAccountMutationVariables
>;
export const Common_DeleteCredentialMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteCredentialMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteCredentialInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCredential' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deleted' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DeleteCredentialMutation,
  Common_DeleteCredentialMutationVariables
>;
export const Common_UndeleteAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UndeleteAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UndeleteAccountMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'undeleteAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'undeleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UndeleteAccountMutation,
  Common_UndeleteAccountMutationVariables
>;
export const GetDisplayBalancePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDisplayBalancePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invertSyncedBalance' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'useAvailableBalance' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'displayBalancePreview' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'invertSyncedBalance' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'invertSyncedBalance' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'useAvailableBalance' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'useAvailableBalance' },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDisplayBalancePreviewQuery, GetDisplayBalancePreviewQueryVariables>;
export const Common_MarkAllActivityAsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_MarkAllActivityAsRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markAllActivityAsRead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityEvents' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'readAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_MarkAllActivityAsReadMutation,
  Common_MarkAllActivityAsReadMutationVariables
>;
export const Common_ClearAllActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_ClearAllActivities' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clearAllActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_ClearAllActivitiesMutation,
  Common_ClearAllActivitiesMutationVariables
>;
export const Common_MarkActivityAsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_MarkActivityAsRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markActivityAsRead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'readAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_MarkActivityAsReadMutation,
  Common_MarkActivityAsReadMutationVariables
>;
export const Common_DismissActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DismissActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dismissActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dismissedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DismissActivityMutation,
  Common_DismissActivityMutationVariables
>;
export const Common_UpdateSponsorshipInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateSponsorshipInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSponsorshipInviteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSubscriptionSponsorshipInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invite' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateSponsorshipInviteMutation,
  Common_UpdateSponsorshipInviteMutationVariables
>;
export const GetCashFlowDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCashFlowDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byDay' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'day', block: false }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'day' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCashFlowDashboardQuery, GetCashFlowDashboardQueryVariables>;
export const GetSubscriptionStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSubscriptionStatus' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChargedForLifetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasStripeSubscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eligibleForTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'willCancelAtPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBillingIssue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePromoCode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsoredBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSubscriptionStatusQuery, GetSubscriptionStatusQueryVariables>;
export const Common_GetSubscriptionHasEntitlementAfterSyncDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetSubscriptionHasEntitlementAfterSync' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlementAfterSync' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetSubscriptionHasEntitlementAfterSyncQuery,
  Common_GetSubscriptionHasEntitlementAfterSyncQueryVariables
>;
export const Common_CancelSubscriptionForHouseholdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CancelSubscriptionForHousehold' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CancelStripeSubscriptionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelStripeSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canceled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SubscriptionFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SponsoredSubscriptionFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChargedForLifetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasStripeSubscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eligibleForTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'willCancelAtPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBillingIssue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePromoCode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsoredBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SponsoredSubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSponsorship' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sponsor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CancelSubscriptionForHouseholdMutation,
  Common_CancelSubscriptionForHouseholdMutationVariables
>;
export const Common_BudgetDataQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_BudgetDataQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'budgetSystem' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'budgetStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BudgetStatusFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'budgetData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BudgetDataFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetCategoryGroupFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataGoalsV2Fields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthlyAmounts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedCashFlowAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedSetAsideAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cumulativeActualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverTargetAmount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BudgetCategoryMonthlyAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryGroupFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BudgetCategoryGroupMonthlyAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthlyAmountsForFlexExpenseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BudgetFlexMonthlyAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetTotals' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetTotalsByMonthFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthTotals' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalIncome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalFixedExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalNonMonthlyExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalFlexibleExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetRolloverPeriodFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetRolloverPeriod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetCategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetRolloverPeriodFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hasBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTransactions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'willCreateBudgetFromEmptyDefaultCategories' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmountsByCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmountsByCategoryGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryGroupFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmountsForFlexExpense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetMonthlyAmountsForFlexExpenseFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalsByMonth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetTotalsByMonthFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetCategoryGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BudgetCategoryFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataGoalsV2Fields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plannedContributions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyContributionSummaries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_BudgetDataQuery, Common_BudgetDataQueryVariables>;
export const Common_CreateBudgetForHouseholdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateBudgetForHousehold' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateBudgetMutationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBudget' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateBudgetForHouseholdMutation,
  Common_CreateBudgetForHouseholdMutationVariables
>;
export const Common_GetBudgetStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetBudgetStatus' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'budgetStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BudgetStatusFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hasBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTransactions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'willCreateBudgetFromEmptyDefaultCategories' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_GetBudgetStatusQuery, Common_GetBudgetStatusQueryVariables>;
export const Common_GetBudgetSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetBudgetSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'budgetSystem' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flexExpenseRolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_GetBudgetSettingsQuery, Common_GetBudgetSettingsQueryVariables>;
export const Common_UpdateBudgetSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateBudgetSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBudgetSettingsMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBudgetSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'budgetSystem' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'budgetRolloverPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateBudgetSettingsMutation,
  Common_UpdateBudgetSettingsMutationVariables
>;
export const Common_UpdateCategoryExcludeFromBudgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateCategoryExcludeFromBudget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCategoryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateCategoryExcludeFromBudgetMutation,
  Common_UpdateCategoryExcludeFromBudgetMutationVariables
>;
export const GetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const Common_UpdateCategoryGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateCategoryGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCategoryGroupInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategoryGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CategoryGroupFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateCategoryGroupMutation,
  Common_UpdateCategoryGroupMutationVariables
>;
export const Common_CreateCategoryGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateCategoryGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateCategoryGroupInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCategoryGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CategoryGroupFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateCategoryGroupMutation,
  Common_CreateCategoryGroupMutationVariables
>;
export const GetCategoryGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCategoryGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeDisabledSystemCategories' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryGroupFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'includeDisabledSystemCategories' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'includeDisabledSystemCategories' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCategoryGroupQuery, GetCategoryGroupQueryVariables>;
export const GetCategoryGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCategoryGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryGroupFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCategoryGroupsQuery, GetCategoryGroupsQueryVariables>;
export const Common_DeleteCategoryGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteCategoryGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'moveToGroupId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCategoryGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moveToGroupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'moveToGroupId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DeleteCategoryGroupMutation,
  Common_DeleteCategoryGroupMutationVariables
>;
export const GetCredentialInstitutionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCredentialInstitutions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credentials' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCredentialInstitutionsQuery,
  GetCredentialInstitutionsQueryVariables
>;
export const Common_UpdateCredentialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateCredential' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCredentialMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCredential' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'credential' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateCredentialMutation,
  Common_UpdateCredentialMutationVariables
>;
export const Common_DisconnectCredentialsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DisconnectCredentials' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DisconnectCredentialsFromDataProviderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disconnectCredentialsFromDataProvider' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DisconnectCredentialsMutation,
  Common_DisconnectCredentialsMutationVariables
>;
export const Common_FixCoinbaseCredentialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_FixCoinbaseCredential' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FixCoinbaseCredentialInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixCoinbaseCredential' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'credential' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_FixCoinbaseCredentialMutation,
  Common_FixCoinbaseCredentialMutationVariables
>;
export const Common_CreateFinicityConnectUrlMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateFinicityConnectUrlMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'finicityInstitutionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          defaultValue: { kind: 'NullValue' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFinicityConnectUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'finicityInstitutionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'finicityInstitutionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateFinicityConnectUrlMutation,
  Common_CreateFinicityConnectUrlMutationVariables
>;
export const Common_CreateFinicityConnectFixUrlMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateFinicityConnectFixUrlMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'credentialId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFinicityConnectFixUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'credentialId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'credentialId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateFinicityConnectFixUrlMutation,
  Common_CreateFinicityConnectFixUrlMutationVariables
>;
export const Common_CreateFinicityCredentialsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateFinicityCredentialsMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFinicityCredentials' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'credentials' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateFinicityCredentialsMutation,
  Common_CreateFinicityCredentialsMutationVariables
>;
export const GetGoalTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoalTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGoalTypesQuery, GetGoalTypesQueryVariables>;
export const Common_CreateGoalMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateGoalMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateGoalMutationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGoal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plannedMonthlyContribution' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'goalType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_CreateGoalMutation, Common_CreateGoalMutationVariables>;
export const Common_UpdateGoalMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateGoalMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateGoalMutationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGoal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plannedMonthlyContribution' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'goalType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_UpdateGoalMutation, Common_UpdateGoalMutationVariables>;
export const Common_DeleteGoalMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteGoalMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteGoal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_DeleteGoalMutation, Common_DeleteGoalMutationVariables>;
export const GetGoalBalancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoalBalances' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalBalances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availableBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSavingsBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContributedBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGoalBalancesQuery, GetGoalBalancesQueryVariables>;
export const Common_UpdateOrCreateGoalPlannedContributionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateOrCreateGoalPlannedContributionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateOrCreateGoalPlannedContributionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrCreateGoalPlannedContribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalPlannedContribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'GoalPlannedContributionFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalPlannedContributionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalPlannedContribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateOrCreateGoalPlannedContributionMutation,
  Common_UpdateOrCreateGoalPlannedContributionMutationVariables
>;
export const Common_UpdateGoalContributionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateGoalContributionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateGoalContributionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGoalContribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalContribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateGoalContributionMutation,
  Common_UpdateGoalContributionMutationVariables
>;
export const Common_DeleteGoalContributionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteGoalContributionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteGoalContribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DeleteGoalContributionMutation,
  Common_DeleteGoalContributionMutationVariables
>;
export const GetDashboardGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDashboardGoals' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contributedBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalBalances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availableBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSavingsBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContributedBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDashboardGoalsQuery, GetDashboardGoalsQueryVariables>;
export const Common_CreateGoalContributionsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateGoalContributionsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CreateGoalContributionsMutationInput' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGoalContributions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalContributions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contributedBalance' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'projectedCompletionDateGivenContribution',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'proposedContributionAmount' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateGoalContributionsMutation,
  Common_CreateGoalContributionsMutationVariables
>;
export const Common_UpdateHasSeenGoalsTourDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateHasSeenGoalsTour' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserProfileInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSeenGoalsFeatureTour' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateHasSeenGoalsTourMutation,
  Common_UpdateHasSeenGoalsTourMutationVariables
>;
export const GetGoalProjectedCompletionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoalProjectedCompletion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'proposedContributionAmount' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectedCompletionDateGivenContribution' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'proposedContributionAmount' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'proposedContributionAmount' },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGoalProjectedCompletionQuery,
  GetGoalProjectedCompletionQueryVariables
>;
export const Common_CreateOrUpdateGoalV2PlannedContributionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateOrUpdateGoalV2PlannedContributionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateOrUpdateGoalPlannedContributionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrUpdateGoalPlannedContribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalPlannedContribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateOrUpdateGoalV2PlannedContributionMutation,
  Common_CreateOrUpdateGoalV2PlannedContributionMutationVariables
>;
export const Common_CreateManualHoldingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateManualHolding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateManualHoldingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createManualHolding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateManualHoldingMutation,
  Common_CreateManualHoldingMutationVariables
>;
export const GetMyHouseholdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMyHousehold' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myHousehold' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HouseholdFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HouseholdFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Household' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMyHouseholdQuery, GetMyHouseholdQueryVariables>;
export const Common_UpdateMyHouseholdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateMyHousehold' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMyHouseholdInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMyHousehold' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'household' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HouseholdFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HouseholdFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Household' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateMyHouseholdMutation,
  Common_UpdateMyHouseholdMutationVariables
>;
export const Common_DeleteHouseholdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteHousehold' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteHouseholdMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteHousehold' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DeleteHouseholdMutation,
  Common_DeleteHouseholdMutationVariables
>;
export const Common_CreateInstitutionIssueReportMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateInstitutionIssueReportMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateInstitutionIssueReportMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInstitutionIssueReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateInstitutionIssueReportMutation,
  Common_CreateInstitutionIssueReportMutationVariables
>;
export const TopInstitutionsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TopInstitutionsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platform' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topInstitutions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platform' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platform' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'largeLogo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarnBeforeConnecting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopInstitutionsQuery, TopInstitutionsQueryVariables>;
export const GetInstitutionLogoByPlaidIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInstitutionLogoByPlaidId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plaidId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'plaidId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'plaidId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInstitutionLogoByPlaidIdQuery,
  GetInstitutionLogoByPlaidIdQueryVariables
>;
export const GetInstitutionByProviderIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInstitutionByProviderId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plaidId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'finicityId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mxId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'plaidId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'plaidId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'finicityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'finicityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mxId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'largeLogo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInstitutionByProviderIdQuery,
  GetInstitutionByProviderIdQueryVariables
>;
export const Common_RequestInstitutionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_RequestInstitution' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestInstitutionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestInstitution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_RequestInstitutionMutation,
  Common_RequestInstitutionMutationVariables
>;
export const SecuritySearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SecuritySearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderByPopularity' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'securities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderByPopularity' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderByPopularity' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeDisplay' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closingPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'oneDayChangeDollars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'oneDayChangePercent' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SecuritySearchQuery, SecuritySearchQueryVariables>;
export const GetHoldingSummarySecurityDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHoldingSummarySecurityDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closingPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'oneDayChangeDollars' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHoldingSummarySecurityDetailsQuery,
  GetHoldingSummarySecurityDetailsQueryVariables
>;
export const Common_DeleteHoldingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteHolding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteHolding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_DeleteHoldingMutation, Common_DeleteHoldingMutationVariables>;
export const Common_UpdateHoldingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateHolding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateHoldingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateHolding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_UpdateHoldingMutation, Common_UpdateHoldingMutationVariables>;
export const GetHoldingDetailsFormSecurityDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHoldingDetailsFormSecurityDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closingPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'oneDayChangeDollars' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHoldingDetailsFormSecurityDetailsQuery,
  GetHoldingDetailsFormSecurityDetailsQueryVariables
>;
export const GetMerchantsSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMerchantsSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'TRANSACTION_COUNT' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMerchantsSearchQuery, GetMerchantsSearchQueryVariables>;
export const GetMerchantsSearchWithLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMerchantsSearchWithLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'TRANSACTION_COUNT' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMerchantsSearchWithLogoQuery,
  GetMerchantsSearchWithLogoQueryVariables
>;
export const Common_CreateMxConnectUrlMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateMXConnectUrlMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mxInstitutionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          defaultValue: { kind: 'NullValue' },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientRedirectUrl' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          defaultValue: { kind: 'NullValue' },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDarkMode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMobileWebview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMxConnectUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mxInstitutionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mxInstitutionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isDarkMode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isDarkMode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isMobileWebview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isMobileWebview' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientRedirectUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientRedirectUrl' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateMxConnectUrlMutation,
  Common_CreateMxConnectUrlMutationVariables
>;
export const Common_CreateMxConnectFixUrlMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateMXConnectFixUrlMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'credentialId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isDarkMode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMobileWebview' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMxConnectFixUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'credentialId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'credentialId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isDarkMode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isDarkMode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isMobileWebview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isMobileWebview' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateMxConnectFixUrlMutation,
  Common_CreateMxConnectFixUrlMutationVariables
>;
export const Common_CreateMxCredentialsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateMXCredentialsMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMxCredentials' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'credentials' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateMxCredentialsMutation,
  Common_CreateMxCredentialsMutationVariables
>;
export const GetNotificationPreferenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotificationPreference' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationPreference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'NotificationPreferenceFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationPreferenceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationPreference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'group' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalPreferences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalPreferencesMeta' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'EMAIL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PUSH' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNotificationPreferenceQuery,
  GetNotificationPreferenceQueryVariables
>;
export const GetNotificationPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotificationPreferences' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationPreferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'NotificationPreferenceFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationPreferenceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationPreference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'group' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalPreferences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalPreferencesMeta' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'EMAIL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PUSH' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNotificationPreferencesQuery,
  GetNotificationPreferencesQueryVariables
>;
export const Common_UpdateNotificationPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateNotificationPreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateNotificationPreferencesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNotificationPreferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'NotificationPreferenceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationPreferenceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationPreference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'group' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalPreferences' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalPreferencesMeta' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'EMAIL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PUSH' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateNotificationPreferencesMutation,
  Common_UpdateNotificationPreferencesMutationVariables
>;
export const GetOnboardingFinancialGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOnboardingFinancialGoals' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onboardingFinancialGoals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userOnboardingFinancialGoals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onboardingFinancialGoal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'otherText' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOnboardingFinancialGoalsQuery,
  GetOnboardingFinancialGoalsQueryVariables
>;
export const Common_SetUserOnboardingFinancialGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_SetUserOnboardingFinancialGoals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'SetUserOnboardingFinancialGoalInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setUserOnboardingFinancialGoals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userOnboardingFinancialGoals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onboardingFinancialGoal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_SetUserOnboardingFinancialGoalsMutation,
  Common_SetUserOnboardingFinancialGoalsMutationVariables
>;
export const Common_CreatePlaidLinkTokenMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreatePlaidLinkTokenMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'credentialId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkCustomizationName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUri' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'androidPackageName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPlaidLinkToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'credentialId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'credentialId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkCustomizationName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkCustomizationName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'plaidInstitutionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'plaidInstitutionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectUri' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUri' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'androidPackageName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'androidPackageName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'linkToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreatePlaidLinkTokenMutation,
  Common_CreatePlaidLinkTokenMutationVariables
>;
export const Common_GetJointPlanningDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetJointPlanningData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'budgetSystem' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'budgetData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BudgetDataFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetCategoryGroupFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataGoalsV2Fields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthlyAmounts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedCashFlowAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedSetAsideAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cumulativeActualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverTargetAmount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BudgetCategoryMonthlyAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryGroupFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BudgetCategoryGroupMonthlyAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthlyAmountsForFlexExpenseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BudgetFlexMonthlyAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataMonthlyAmountsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetTotals' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetTotalsByMonthFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthTotals' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalIncome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalFixedExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalNonMonthlyExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalFlexibleExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetDataTotalsByMonthFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetRolloverPeriodFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetRolloverPeriod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetCategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetRolloverPeriodFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmountsByCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmountsByCategoryGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetMonthlyAmountsByCategoryGroupFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyAmountsForFlexExpense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetMonthlyAmountsForFlexExpenseFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalsByMonth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetTotalsByMonthFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetCategoryGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BudgetCategoryFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetDataGoalsV2Fields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plannedContributions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyContributionSummaries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endMonth' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetJointPlanningDataQuery,
  Common_GetJointPlanningDataQueryVariables
>;
export const Common_RecurringUpdateMerchantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_RecurringUpdateMerchant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMerchantInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMerchant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'merchant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_RecurringUpdateMerchantMutation,
  Common_RecurringUpdateMerchantMutationVariables
>;
export const Common_RecurringUpdateLiabilityStatementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_RecurringUpdateLiabilityStatement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creditReportLiabilityStatementId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userBillAmount' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'revertToOriginal' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBillAmount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditReportLiabilityStatementId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creditReportLiabilityStatementId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userBillAmount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userBillAmount' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'revertToOriginal' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'revertToOriginal' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditReportLiabilityStatement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billAmount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_RecurringUpdateLiabilityStatementMutation,
  Common_RecurringUpdateLiabilityStatementMutationVariables
>;
export const Common_MarkRecurringLiabilityStatementAsPaidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_MarkRecurringLiabilityStatementAsPaid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creditReportLiabilityStatementId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isPaid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBillPaid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditReportLiabilityStatementId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creditReportLiabilityStatementId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPaid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isPaid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditReportLiabilityStatement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userMarkedPaidAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_MarkRecurringLiabilityStatementAsPaidMutation,
  Common_MarkRecurringLiabilityStatementAsPaidMutationVariables
>;
export const Common_MarkAsNotRecurringDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_MarkAsNotRecurring' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'streamId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markStreamAsNotRecurring' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'streamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'streamId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_MarkAsNotRecurringMutation,
  Common_MarkAsNotRecurringMutationVariables
>;
export const Common_CreateTransactionRuleMutationV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateTransactionRuleMutationV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateTransactionRuleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTransactionRuleV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateTransactionRuleMutationV2Mutation,
  Common_CreateTransactionRuleMutationV2MutationVariables
>;
export const Common_UpdateTransactionRuleMutationV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateTransactionRuleMutationV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTransactionRuleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTransactionRuleV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateTransactionRuleMutationV2Mutation,
  Common_UpdateTransactionRuleMutationV2MutationVariables
>;
export const Common_DeleteTransactionRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteTransactionRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTransactionRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DeleteTransactionRuleMutation,
  Common_DeleteTransactionRuleMutationVariables
>;
export const Common_GetMonthlySnapshotsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetMonthlySnapshots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlySnapshotsByAccountType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetMonthlySnapshotsQuery,
  Common_GetMonthlySnapshotsQueryVariables
>;
export const Common_GetSnapshotsByAccountTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetSnapshotsByAccountType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'timeframe' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Timeframe' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotsByAccountType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timeframe' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'timeframe' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetSnapshotsByAccountTypeQuery,
  Common_GetSnapshotsByAccountTypeQueryVariables
>;
export const Common_GetDisplayBalanceAtDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetDisplayBalanceAtDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'displayBalance' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'date' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetDisplayBalanceAtDateQuery,
  Common_GetDisplayBalanceAtDateQueryVariables
>;
export const Common_DeleteSyncedSnapshotsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteSyncedSnapshotsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteSyncedSnapshotsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSyncedSnapshots' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DeleteSyncedSnapshotsMutation,
  Common_DeleteSyncedSnapshotsMutationVariables
>;
export const Common_CreateSpinwheelTokenMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateSpinwheelTokenMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSpinwheelToken' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateSpinwheelTokenMutation,
  Common_CreateSpinwheelTokenMutationVariables
>;
export const Common_GetSpinwheelCreditReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetSpinwheelCreditReport' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spinwheelUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'onboardingStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'onboardingErrorMessage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditReportLiabilityAccounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'spinwheelLiabilityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'liabilityType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpen' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentTotalBalance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'termsFrequency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spinwheelUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dayOfTheMonth' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastStatement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'dueDate' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetSpinwheelCreditReportQuery,
  Common_GetSpinwheelCreditReportQueryVariables
>;
export const Web_CreateSpinwheelAccountMappingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_CreateSpinwheelAccountMapping' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SpinwheelAccountMappingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSpinwheelAccountMapping' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_CreateSpinwheelAccountMappingMutation,
  Web_CreateSpinwheelAccountMappingMutationVariables
>;
export const Common_DisconnectSpinwheelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DisconnectSpinwheel' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disconnectSpinwheel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isDisconnected' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DisconnectSpinwheelMutation,
  Common_DisconnectSpinwheelMutationVariables
>;
export const GetTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TransactionFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInGoalBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTransactionsQuery, GetTransactionsQueryVariables>;
export const Common_UpdateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateTransaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTransactionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TransactionFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInGoalBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateTransactionMutation,
  Common_UpdateTransactionMutationVariables
>;
export const Common_SplitTransactionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_SplitTransactionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTransactionSplitMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTransactionSplit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSplitTransactions' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'splitTransactions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'merchant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'needsReviewByUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_SplitTransactionMutation,
  Common_SplitTransactionMutationVariables
>;
export const Common_TransactionSplitQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_TransactionSplitQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reviewedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'merchant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'needsReviewByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionGoalFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'splitTransactions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProvider' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProviderId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsReviewByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionGoalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProvider' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProviderId' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_TransactionSplitQuery, Common_TransactionSplitQueryVariables>;
export const Common_CreateTransactionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateTransactionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateTransactionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateTransactionMutation,
  Common_CreateTransactionMutationVariables
>;
export const Common_DeleteTransactionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteTransactionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteTransactionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DeleteTransactionMutation,
  Common_DeleteTransactionMutationVariables
>;
export const Common_BulkUpdateTransactionsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_BulkUpdateTransactionsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedTransactionIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'excludedTransactionIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'allSelected' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'expectedAffectedTransactionCount' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updates' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionUpdateParams' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpdateTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedTransactionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'selectedTransactionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludedTransactionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'excludedTransactionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updates' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updates' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'allSelected' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'allSelected' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'expectedAffectedTransactionCount' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'expectedAffectedTransactionCount' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'affectedCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_BulkUpdateTransactionsMutation,
  Common_BulkUpdateTransactionsMutationVariables
>;
export const Common_BulkDeleteTransactionsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_BulkDeleteTransactionsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedTransactionIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'excludedTransactionIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'allSelected' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'expectedAffectedTransactionCount' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkDeleteTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'selectedTransactionIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'selectedTransactionIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludedTransactionIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'excludedTransactionIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'isAllSelected' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'allSelected' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'expectedAffectedTransactionCount' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'expectedAffectedTransactionCount' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'affectedCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_BulkDeleteTransactionsMutation,
  Common_BulkDeleteTransactionsMutationVariables
>;
export const Common_OldestDeletableTransactionDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_OldestDeletableTransactionDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeSynced' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUploaded' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeManual' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oldestDeletableTransactionDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeSynced' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeSynced' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeUploaded' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUploaded' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeManual' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeManual' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_OldestDeletableTransactionDateQuery,
  Common_OldestDeletableTransactionDateQueryVariables
>;
export const Common_DeleteSyncedTransactionsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteSyncedTransactionsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteSyncedTransactionsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSyncedTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DeleteSyncedTransactionsMutation,
  Common_DeleteSyncedTransactionsMutationVariables
>;
export const Common_PreviewDeleteTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_PreviewDeleteTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'beforeDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeSynced' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUploaded' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeManual' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewDeleteTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'beforeDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'beforeDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeSynced' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeSynced' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeUploaded' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUploaded' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeManual' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeManual' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_PreviewDeleteTransactionsQuery,
  Common_PreviewDeleteTransactionsQueryVariables
>;
export const Common_DeleteTransactionsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteTransactionsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteTransactionsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DeleteTransactionsMutation,
  Common_DeleteTransactionsMutationVariables
>;
export const Common_DeleteHouseholdTransactionTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteHouseholdTransactionTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tagId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTransactionTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tagId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tagId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DeleteHouseholdTransactionTagMutation,
  Common_DeleteHouseholdTransactionTagMutationVariables
>;
export const Common_UpdateTransactionTagOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateTransactionTagOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tagId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTransactionTagOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tagId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tagId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'householdTransactionTags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateTransactionTagOrderMutation,
  Common_UpdateTransactionTagOrderMutationVariables
>;
export const Common_UpdateTransactionTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateTransactionTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateTransactionTagInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTransactionTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateTransactionTagMutation,
  Common_UpdateTransactionTagMutationVariables
>;
export const Common_CreateTransactionTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateTransactionTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateTransactionTagInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTransactionTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateTransactionTagMutation,
  Common_CreateTransactionTagMutationVariables
>;
export const Common_GetMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetMe' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasSeenCategoriesManagementTour' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dismissedTransactionsListUpdatesTourAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewedMarkAsReviewedUpdatesCalloutAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dismissedRecurringWalkthroughAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasDismissedWhatsNewAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasMfaOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalAuthProviderNames' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingEmailUpdateVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cloudinaryPublicId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSupportAccountAccessGrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_GetMeQuery, Common_GetMeQueryVariables>;
export const Common_UpdateMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateMe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSuperuser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasMfaOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalAuthProviderNames' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingEmailUpdateVerification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cloudinaryPublicId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSupportAccountAccessGrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_UpdateMeMutation, Common_UpdateMeMutationVariables>;
export const Common_GetAnonymousIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetAnonymousId' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributionData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'anonymousId' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_GetAnonymousIdQuery, Common_GetAnonymousIdQueryVariables>;
export const Common_HasActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_HasActivity' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNewActivity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_HasActivityQuery, Common_HasActivityQueryVariables>;
export const Common_CheckActivityMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CheckActivityMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNewActivity' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_CheckActivityMutation, Common_CheckActivityMutationVariables>;
export const Common_UpdateUserProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateUserProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateProfileInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserProfileInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateMeInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMeInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateMyHouseholdInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMyHouseholdInput' } },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateProfileInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'collaboratesOnFinancesDetailed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasSeenCategoriesManagementTour' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'aiAssistantOptedInAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewedMarkAsReviewedUpdatesCalloutAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateMeInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMyHousehold' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateMyHouseholdInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'household' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'country' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateUserProfileMutation,
  Common_UpdateUserProfileMutationVariables
>;
export const VehiclesSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VehiclesSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VehiclesSearchQuery, VehiclesSearchQueryVariables>;
export const Common_SetAccountLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_SetAccountLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SetAccountLogoInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setAccountLogo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasCustomizedLogo' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_SetAccountLogoMutation, Common_SetAccountLogoMutationVariables>;
export const Common_UpdateAccountGroupOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateAccountGroupOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAccountGroupOrderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccountGroupOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'household' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preferences' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accountGroupOrder' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateAccountGroupOrderMutation,
  Common_UpdateAccountGroupOrderMutationVariables
>;
export const Web_GetAccountTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetAccountTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showForSyncedAccounts' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'possibleSubtypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetAccountTypesQuery, Web_GetAccountTypesQueryVariables>;
export const Common_ForceRefreshAccountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_ForceRefreshAccountQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canBeForceRefreshed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasSyncOrRecentRefreshRequest' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_ForceRefreshAccountQuery,
  Common_ForceRefreshAccountQueryVariables
>;
export const Common_ForceRefreshAccountsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_ForceRefreshAccountsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasAccountsSyncing' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_ForceRefreshAccountsQuery,
  Common_ForceRefreshAccountsQueryVariables
>;
export const Common_CreateThreadMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateThreadMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'agentType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMessageThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'agentType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'agentType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messageThread' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_CreateThreadMutation, Common_CreateThreadMutationVariables>;
export const Common_DeleteMessageThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteMessageThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMessageThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DeleteMessageThreadMutation,
  Common_DeleteMessageThreadMutationVariables
>;
export const Common_SendMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_SendMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SendMessageInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messageThread' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastMessageSentAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_SendMessageMutation, Common_SendMessageMutationVariables>;
export const GetTranscriptionKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTranscriptionKey' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'householdTranscriptionKey' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetTranscriptionKeyQuery, GetTranscriptionKeyQueryVariables>;
export const Common_GetProductboardCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetProductboardCode' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'productBoardToken' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetProductboardCodeQuery,
  Common_GetProductboardCodeQueryVariables
>;
export const Common_SetHasSkippedPremiumUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_SetHasSkippedPremiumUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skippedPremiumUpsell' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skippedPremiumUpsellAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_SetHasSkippedPremiumUpsellMutation,
  Common_SetHasSkippedPremiumUpsellMutationVariables
>;
export const GetSubscriptionDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSubscriptionDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referralCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeSponsorship' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSubscriptionDetailsQuery, GetSubscriptionDetailsQueryVariables>;
export const GetAggregatesGraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAggregatesGraph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'StringValue', value: 'category', block: false },
                    { kind: 'StringValue', value: 'month', block: false },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sum' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAggregatesGraphQuery, GetAggregatesGraphQueryVariables>;
export const GetAggregatesGraphCategoryGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAggregatesGraphCategoryGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'StringValue', value: 'categoryGroup', block: false },
                    { kind: 'StringValue', value: 'month', block: false },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categoryGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sum' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAggregatesGraphCategoryGroupQuery,
  GetAggregatesGraphCategoryGroupQueryVariables
>;
export const GetAggregatesGraphFlexExpenseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAggregatesGraphFlexExpense' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'isFlexSpending' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'month', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'month' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sum' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAggregatesGraphFlexExpenseQuery,
  GetAggregatesGraphFlexExpenseQueryVariables
>;
export const Common_InitializeFlexBudgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_InitializeFlexBudget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InitializeFlexBudgetMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initializeFlexBudget' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'budgetItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plannedCashFlowAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetTarget' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_InitializeFlexBudgetMutation,
  Common_InitializeFlexBudgetMutationVariables
>;
export const Common_ResetBudgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_ResetBudget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResetBudgetMutationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetBudget' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_ResetBudgetMutation, Common_ResetBudgetMutationVariables>;
export const Common_GetPlanOnboardingStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetPlanOnboardingState' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'budgetStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BudgetStatusFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hasBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasTransactions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'willCreateBudgetFromEmptyDefaultCategories' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetPlanOnboardingStateQuery,
  Common_GetPlanOnboardingStateQueryVariables
>;
export const Common_UpdateBudgetItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateBudgetItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateOrCreateBudgetItemMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrCreateBudgetItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'budgetItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plannedCashFlowAmount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateBudgetItemMutation,
  Common_UpdateBudgetItemMutationVariables
>;
export const Common_UpdateCategoryBudgetVariabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateCategoryBudgetVariability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCategoryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateCategoryBudgetVariabilityMutation,
  Common_UpdateCategoryBudgetVariabilityMutationVariables
>;
export const Common_UpdateCategoryGroupBudgetVariabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateCategoryGroupBudgetVariability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCategoryGroupInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategoryGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateCategoryGroupBudgetVariabilityMutation,
  Common_UpdateCategoryGroupBudgetVariabilityMutationVariables
>;
export const Common_UpdateFlexBudgetMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateFlexBudgetMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateOrCreateFlexBudgetItemMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrCreateFlexBudgetItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'budgetItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetAmount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateFlexBudgetMutation,
  Common_UpdateFlexBudgetMutationVariables
>;
export const Common_GetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeSystemDisabledCategories' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rolloverPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeDisabledSystemCategories' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeSystemDisabledCategories' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rolloverPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_GetCategoriesQuery, Common_GetCategoriesQueryVariables>;
export const Common_GetCloudinaryUploadInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_GetCloudinaryUploadInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetLogoCloudinaryUploadInfoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCloudinaryUploadInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'info' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestParams' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'folder' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signature' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'api_key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'upload_preset' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetCloudinaryUploadInfoMutation,
  Common_GetCloudinaryUploadInfoMutationVariables
>;
export const GetDashboardConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDashboardConfig' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myHousehold' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HouseholdDashboard' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PlatformDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'layout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'widgets' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HouseholdDashboard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Household' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dashboardConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'web' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DashboardConfig' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DashboardConfig' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDashboardConfigQuery, GetDashboardConfigQueryVariables>;
export const Common_UpdateDashboardConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateDashboardConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSONString' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDashboardConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'household' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'HouseholdDashboard' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PlatformDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'layout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'widgets' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HouseholdDashboard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Household' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dashboardConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'web' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DashboardConfig' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DashboardConfig' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateDashboardConfigMutation,
  Common_UpdateDashboardConfigMutationVariables
>;
export const OnboardingChecklistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OnboardingChecklist' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hasAccounts' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'budgetStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasBudget' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasSeenCategoriesManagementTour' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OnboardingChecklistQuery, OnboardingChecklistQueryVariables>;
export const Common_ArchiveGoalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_ArchiveGoal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArchiveGoalInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveGoal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_ArchiveGoalMutation, Common_ArchiveGoalMutationVariables>;
export const Common_CreateGoalAccountAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateGoalAccountAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateGoalAccountAllocationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGoalAccountAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalAccountAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'GoalAllocationFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAllocationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalAccountAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useEntireAccountBalance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableBalanceForGoals' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_CreateGoalAccountAllocationMutation,
  Common_CreateGoalAccountAllocationMutationVariables
>;
export const Common_DeleteGoalV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteGoalV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteGoalInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteGoalV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_DeleteGoalV2Mutation, Common_DeleteGoalV2MutationVariables>;
export const Common_DeleteGoalAccountAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_DeleteGoalAccountAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteGoalAccountAllocationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteGoalAccountAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availableBalanceForGoals' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_DeleteGoalAccountAllocationMutation,
  Common_DeleteGoalAccountAllocationMutationVariables
>;
export const GetGoalsV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoalsV2' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGoalsV2Query, GetGoalsV2QueryVariables>;
export const Common_MarkGoalCompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_MarkGoalComplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarkGoalCompleteInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markGoalComplete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_MarkGoalCompleteMutation,
  Common_MarkGoalCompleteMutationVariables
>;
export const Common_MarkGoalIncompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_MarkGoalIncomplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarkGoalIncompleteInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markGoalIncomplete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_MarkGoalIncompleteMutation,
  Common_MarkGoalIncompleteMutationVariables
>;
export const GoalSelectAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GoalSelectAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'goalId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'goalId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eligibleAccounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtype' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'GoalAccountAllocationRowFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'suggestedAccounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtype' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'GoalAccountAllocationRowFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllocationFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'legacyGoal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contributedBalance' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalAccountsMapRowFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAccountAllocationRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availableBalanceForGoals' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useEntireAccountBalance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllocationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalAccountAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useEntireAccountBalance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAccountsMapRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtype' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalSelectAccountsQuery, GoalSelectAccountsQueryVariables>;
export const GoalOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GoalOptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objective' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allowMultiSelect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultImageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultImageStorageProviderId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalOptionsQuery, GoalOptionsQueryVariables>;
export const Common_CreateGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_CreateGoals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateGoalsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGoals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'objective' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_CreateGoalsMutation, Common_CreateGoalsMutationVariables>;
export const Common_UnarchiveGoalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UnarchiveGoal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UnarchiveGoalInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unarchiveGoal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_UnarchiveGoalMutation, Common_UnarchiveGoalMutationVariables>;
export const Mobile_UpdateGoalV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_UpdateGoalV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateGoalInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGoalV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'estimatedCompletionMonth' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plannedMonthlyContribution' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plannedMonthlyPretaxContribution' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_UpdateGoalV2Mutation, Mobile_UpdateGoalV2MutationVariables>;
export const Common_UpdateGoalAccountAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateGoalAccountAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateGoalAccountAllocationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGoalAccountAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalAccountAllocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'GoalAllocationFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAllocationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalAccountAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useEntireAccountBalance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableBalanceForGoals' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateGoalAccountAllocationMutation,
  Common_UpdateGoalAccountAllocationMutationVariables
>;
export const Common_GetHouseholdPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetHouseholdPreferences' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'householdPreferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HouseholdPreferencesFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetSystem' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HouseholdPreferencesFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdPreferences' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newTransactionsNeedReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uncategorizedTransactionsNeedReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pendingTransactionsCanBeEdited' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountGroupOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiAssistantEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'llmEnrichmentEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investmentTransactionsEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetHouseholdPreferencesQuery,
  Common_GetHouseholdPreferencesQueryVariables
>;
export const Common_UpdateHouseholdPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateHouseholdPreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateHouseholdPreferencesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateHouseholdPreferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'householdPreferences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'HouseholdPreferencesFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HouseholdPreferencesFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdPreferences' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newTransactionsNeedReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uncategorizedTransactionsNeedReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pendingTransactionsCanBeEdited' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountGroupOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiAssistantEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'llmEnrichmentEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investmentTransactionsEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateHouseholdPreferencesMutation,
  Common_UpdateHouseholdPreferencesMutationVariables
>;
export const Common_GetHouseholdMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetHouseholdMembers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myHousehold' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byNeedsReviewByUser' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'needsReviewByUser', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'needsReview' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsReviewByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetHouseholdMembersQuery,
  Common_GetHouseholdMembersQueryVariables
>;
export const Common_GetMerchantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetMerchant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_GetMerchantQuery, Common_GetMerchantQueryVariables>;
export const Common_SetMerchantLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_SetMerchantLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SetMerchantLogoInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setMerchantLogo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'merchant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_SetMerchantLogoMutation,
  Common_SetMerchantLogoMutationVariables
>;
export const GetNotificationCenterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotificationCenter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'afterCursor' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'readAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'actionLabel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventPriority' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPinned' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dismissedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'secondaryUrl' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNotificationCenterQuery, GetNotificationCenterQueryVariables>;
export const GetEntitlementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEntitlements' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEntitlementsQuery, GetEntitlementsQueryVariables>;
export const GetCanSkipPremiumUpsellDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCanSkipPremiumUpsell' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'canSkipPremiumUpsell' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetCanSkipPremiumUpsellQuery, GetCanSkipPremiumUpsellQueryVariables>;
export const GetFeatureEntitlementParamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFeatureEntitlementParams' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entitlementParams' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'features' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'feature' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'requiredEntitlements' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'constants' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entitlement' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxCredentials' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxTransactionRules' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFeatureEntitlementParamsQuery,
  GetFeatureEntitlementParamsQueryVariables
>;
export const Common_GetPremiumUpgradePlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetPremiumUpgradePlans' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stripePromoCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'promoCodeError' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCodeDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCodeDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCodeDurationInMonths' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'period' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricePerPeriodDollars' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discountedPricePerPeriodDollars' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newTrialEndsAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requirePaymentMethod' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sponsoredBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referralRedemption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'campaign' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetPremiumUpgradePlansQuery,
  Common_GetPremiumUpgradePlansQueryVariables
>;
export const GetTrialStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTrialStatus' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eligibleForTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trialDurationDays' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTrialStatusQuery, GetTrialStatusQueryVariables>;
export const Common_GetReviewedAccountLastStatementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetReviewedAccountLastStatements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LiabilityStatementFilterInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditReportLiabilityStatements' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetReviewedAccountLastStatementsQuery,
  Common_GetReviewedAccountLastStatementsQueryVariables
>;
export const Common_GetReviewedMerchantLastTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetReviewedMerchantLastTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isMerchant' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'merchants' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'isMerchant' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '3' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetReviewedMerchantLastTransactionsQuery,
  Common_GetReviewedMerchantLastTransactionsQueryVariables
>;
export const RecurringMerchantSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RecurringMerchantSearch' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringMerchantSearch' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'finishedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringTransactionStreams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecurringMerchantSearchQuery, RecurringMerchantSearchQueryVariables>;
export const Common_TriggerRecurringMerchantSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_TriggerRecurringMerchantSearch' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'triggerRecurringMerchantSearch' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_TriggerRecurringMerchantSearchMutation,
  Common_TriggerRecurringMerchantSearchMutationVariables
>;
export const Common_GetRecurringStreamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetRecurringStreams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringTransactionStreams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includePending' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeLiabilities' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dayOfTheMonth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isApproximate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creditReportLiabilityAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastStatement' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetRecurringStreamsQuery,
  Common_GetRecurringStreamsQueryVariables
>;
export const Common_ReviewStreamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_ReviewStream' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReviewRecurringStreamInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewRecurringStream' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_ReviewStreamMutation, Common_ReviewStreamMutationVariables>;
export const GetYearlyReviewStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetYearlyReviewStatus' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dismissedYearlyReviewAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewCheckinsByMonth' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reviewType' },
                value: { kind: 'StringValue', value: 'yearly', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reviewStartDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inProgressCheckin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReviewCheckinFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'completedCheckins' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReviewCheckinFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReviewCheckinFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewCheckin' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastPageIndexViewed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStartDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetYearlyReviewStatusQuery, GetYearlyReviewStatusQueryVariables>;
export const Common_SetHasDismissedYearlyReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_SetHasDismissedYearlyReview' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dismissedYearlyReview' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dismissedYearlyReviewAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_SetHasDismissedYearlyReviewMutation,
  Common_SetHasDismissedYearlyReviewMutationVariables
>;
export const PreviewTransactionRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PreviewTransactionRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rule' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TransactionRulePreviewInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactionRulePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rule' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '30' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'newName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newSplitTransactions' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newCategory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'newHideFromReports' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newTags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newGoal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProvider' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProviderId' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'newSplitTransactions' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transaction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'merchant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreviewTransactionRuleQuery, PreviewTransactionRuleQueryVariables>;
export const Common_GetAggregateSnapshotsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetAggregateSnapshots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AggregateSnapshotFilters' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aggregateSnapshots' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'assetsBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'liabilitiesBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetAggregateSnapshotsQuery,
  Common_GetAggregateSnapshotsQueryVariables
>;
export const GetContactSupportFormRecommendedResolutionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContactSupportFormRecommendedResolution' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactSupportReason' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'credentialId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendedResolutionLinks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'credentialId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'credentialId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasResults' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'appearance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'links' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'buttons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContactSupportFormRecommendedResolutionQuery,
  GetContactSupportFormRecommendedResolutionQueryVariables
>;
export const Web_GetContactSupportFormDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetContactSupportFormData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'formType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactSupportFormType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'topic' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactSupportTopic' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactFormData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'topics' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'formType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'formType' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reasonsForTopic' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'topic' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'topic' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'impacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredFieldsForReason' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reason' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetContactSupportFormDataQuery,
  Web_GetContactSupportFormDataQueryVariables
>;
export const Mobile_RapidTransactionReviewFormContainerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_RapidTransactionReviewFormContainer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RapidReviewTransactionFormFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendedCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RapidReviewCategoryFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RapidReviewTransactionFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProvider' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProviderId' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalAssetUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsReviewByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RapidReviewCategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_RapidTransactionReviewFormContainerQuery,
  Mobile_RapidTransactionReviewFormContainerQueryVariables
>;
export const Mobile_RapidTransactionReviewScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_RapidTransactionReviewScreen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionOrdering' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_RapidTransactionReviewScreenQuery,
  Mobile_RapidTransactionReviewScreenQueryVariables
>;
export const Common_MarkTransactionAsReviewedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_MarkTransactionAsReviewed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTransactionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_MarkTransactionAsReviewedMutation,
  Common_MarkTransactionAsReviewedMutationVariables
>;
export const GetHouseholdTransactionTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHouseholdTransactionTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bulkParams' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BulkTransactionDataParams' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeTransactionCount' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'householdTransactionTags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bulkParams' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bulkParams' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionCount' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'includeTransactionCount' },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHouseholdTransactionTagsQuery,
  GetHouseholdTransactionTagsQueryVariables
>;
export const Common_GetTransactionAttachmentUploadInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_GetTransactionAttachmentUploadInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransactionAttachmentUploadInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'transactionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'info' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestParams' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'folder' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signature' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'api_key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'upload_preset' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetTransactionAttachmentUploadInfoMutation,
  Common_GetTransactionAttachmentUploadInfoMutationVariables
>;
export const Common_AddTransactionAttachmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_AddTransactionAttachment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TransactionAddAttachmentMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTransactionAttachment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sizeBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalAssetUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_AddTransactionAttachmentMutation,
  Common_AddTransactionAttachmentMutationVariables
>;
export const Common_GetSharedConstantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetSharedConstants' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'constants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyPriceDollars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referralsTrialDurationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeReferralCampaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'campaign' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'creditsEarned' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institutionReportIssueTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'budgetRolloverFrequencies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_GetSharedConstantsQuery,
  Common_GetSharedConstantsQueryVariables
>;
export const Common_UpdateUserProfileFlagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_UpdateUserProfileFlags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateProfileInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserProfileInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateProfileInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserProfileFlagsFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserProfileFlagsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiAssistantOptedInAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedFlexBudgetingWalkthroughAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedRecurringWalkthroughAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedSpendingInsightsBanner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dismissedTransactionsListUpdatesTourAt' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedYearlyReviewAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasDismissedWhatsNewAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSeenCategoriesManagementTour' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSeenWeeklyReviewTour' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewedMarkAsReviewedUpdatesCalloutAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_UpdateUserProfileFlagsMutation,
  Common_UpdateUserProfileFlagsMutationVariables
>;
export const Common_UserProfileFlagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_UserProfileFlags' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserProfileFlagsFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserProfileFlagsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiAssistantOptedInAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedFlexBudgetingWalkthroughAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedRecurringWalkthroughAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedSpendingInsightsBanner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dismissedTransactionsListUpdatesTourAt' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dismissedYearlyReviewAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasDismissedWhatsNewAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSeenCategoriesManagementTour' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSeenWeeklyReviewTour' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewedMarkAsReviewedUpdatesCalloutAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_UserProfileFlagsQuery, Common_UserProfileFlagsQueryVariables>;
export const Common_SetUserProfilePictureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Common_SetUserProfilePicture' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SetUserProfilePictureInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setUserProfilePicture' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cloudinaryPublicId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Common_SetUserProfilePictureMutation,
  Common_SetUserProfilePictureMutationVariables
>;
export const Mobile_AccountsMessageBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_AccountsMessageBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ids' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountComponentFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountComponentFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountLogoFields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_AccountsMessageBlockQuery,
  Mobile_AccountsMessageBlockQueryVariables
>;
export const Mobile_GoalsMessageBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GoalsMessageBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDashboardRowFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDashboardRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentMonthChange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_GoalsMessageBlockQuery, Mobile_GoalsMessageBlockQueryVariables>;
export const Mobile_TransactionsMessageBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_TransactionsMessageBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ids' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_TransactionsMessageBlockQuery,
  Mobile_TransactionsMessageBlockQueryVariables
>;
export const Mobile_GoalsDashboardCardV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GoalsDashboardCardV2' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'migratedToGoalsV2' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDashboardRowFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDashboardRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentMonthChange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GoalsDashboardCardV2Query,
  Mobile_GoalsDashboardCardV2QueryVariables
>;
export const Mobile_GetInvestmentsDashboardCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetInvestmentsDashboardCard' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'oneDayChangeDollars' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topMovers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'oneDayChangePercent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPrice' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetInvestmentsDashboardCardQuery,
  Mobile_GetInvestmentsDashboardCardQueryVariables
>;
export const Mobile_GetDashboardUpcomingRecurringTransactionItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetDashboardUpcomingRecurringTransactionItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RecurringTransactionFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringRemainingDue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeLiabilities' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringTransactionItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeLiabilities' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPast' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetDashboardUpcomingRecurringTransactionItemsQuery,
  Mobile_GetDashboardUpcomingRecurringTransactionItemsQueryVariables
>;
export const Mobile_ReviewDashboardQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_ReviewDashboardQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewCheckinsByMonth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reviewStartDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inProgressCheckin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReviewCheckinFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'completedCheckins' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReviewCheckinFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReviewCheckinFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewCheckin' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastPageIndexViewed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStartDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_ReviewDashboardQuery, Mobile_ReviewDashboardQueryVariables>;
export const Mobile_ReviewTransactionsDashboardButtonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_ReviewTransactionsDashboardButton' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_ReviewTransactionsDashboardButtonQuery,
  Mobile_ReviewTransactionsDashboardButtonQueryVariables
>;
export const Mobile_GetInvestmentsHoldingsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetInvestmentsHoldingsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PortfolioInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oneWeekInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRange' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oneMonthInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRange' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'threeMonthsInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRange' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sixMonthsInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRange' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'yearToDateInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRange' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oneYearInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRange' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregateHoldings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalValue' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'basis' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'oneWeek' },
                                    name: { kind: 'Name', value: 'securityPriceChangeForPeriod' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'input' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'oneWeekInput' },
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'changePercent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'changeDollars' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'oneMonth' },
                                    name: { kind: 'Name', value: 'securityPriceChangeForPeriod' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'input' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'oneMonthInput' },
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'changePercent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'changeDollars' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'threeMonths' },
                                    name: { kind: 'Name', value: 'securityPriceChangeForPeriod' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'input' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'threeMonthsInput' },
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'changePercent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'changeDollars' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'sixMonths' },
                                    name: { kind: 'Name', value: 'securityPriceChangeForPeriod' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'input' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'sixMonthsInput' },
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'changePercent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'changeDollars' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'yearToDate' },
                                    name: { kind: 'Name', value: 'securityPriceChangeForPeriod' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'input' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'yearToDateInput' },
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'changePercent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'changeDollars' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'oneYear' },
                                    name: { kind: 'Name', value: 'securityPriceChangeForPeriod' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'input' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'oneYearInput' },
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'changePercent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'changeDollars' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'holdings' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'typeDisplay' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isManual' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'quantity' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'costBasis' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'closingPrice' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'closingPriceUpdatedAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'account' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtype' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'display' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'displayName' },
                                              },
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'AccountLogoFields' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'security' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'typeDisplay' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentPrice' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'closingPrice' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentPriceUpdatedAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'closingPriceUpdatedAt' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetInvestmentsHoldingsListQuery,
  Mobile_GetInvestmentsHoldingsListQueryVariables
>;
export const Mobile_GetSecuritiesHistoricalChartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetSecuritiesHistoricalChart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SecurityHistoricalPerformanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'securityHistoricalPerformance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'historicalChart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnPercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetSecuritiesHistoricalChartQuery,
  Mobile_GetSecuritiesHistoricalChartQueryVariables
>;
export const Mobile_GetMerchantsListScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetMerchantsListScreen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MerchantOrdering' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'merchantCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetMerchantsListScreenQuery,
  Mobile_GetMerchantsListScreenQueryVariables
>;
export const Mobile_DrawerMenuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_DrawerMenu' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FreeTrialDurationPanelFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreeTrialDurationPanelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialDurationDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_DrawerMenuQuery, Mobile_DrawerMenuQueryVariables>;
export const Mobile_GetEventPopoverTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetEventPopoverTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'merchants' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '3' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetEventPopoverTransactionsQuery,
  Mobile_GetEventPopoverTransactionsQueryVariables
>;
export const Mobile_GetAccountsListBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetAccountsListBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountFilters' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayBalanceAsOf' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'credential' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'displayBalance' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'displayBalanceAsOf' },
                      },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountLogoFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'householdPreferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountGroupOrder' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetAccountsListBlockQuery,
  Mobile_GetAccountsListBlockQueryVariables
>;
export const Mobile_CreateEditRuleSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_CreateEditRuleSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInGoalBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_CreateEditRuleSettingsQuery,
  Mobile_CreateEditRuleSettingsQueryVariables
>;
export const Mobile_GetLinkedCredentialsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetLinkedCredentials' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credentials' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetLinkedCredentialsQuery,
  Mobile_GetLinkedCredentialsQueryVariables
>;
export const Mobile_UpdateTransactionRapidReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_UpdateTransactionRapidReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTransactionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RapidReviewTransactionFormFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RapidReviewTransactionFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProvider' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProviderId' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalAssetUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsReviewByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_UpdateTransactionRapidReviewMutation,
  Mobile_UpdateTransactionRapidReviewMutationVariables
>;
export const Mobile_SetTransactionTagsRapidReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_SetTransactionTagsRapidReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SetTransactionTagsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setTransactionTags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RapidReviewTransactionFormFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RapidReviewTransactionFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProvider' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProviderId' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalAssetUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsReviewByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_SetTransactionTagsRapidReviewMutation,
  Mobile_SetTransactionTagsRapidReviewMutationVariables
>;
export const Mobile_GetTransactionsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetTransactionsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cacheKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionOrdering' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cacheKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cacheKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cacheKey' } },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSelectableCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'group' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attachments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetTransactionsListQuery,
  Mobile_GetTransactionsListQueryVariables
>;
export const Mobile_UserAvatarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_UserAvatar' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_UserAvatarQuery, Mobile_UserAvatarQueryVariables>;
export const Mobile_CreateReviewCheckinMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_CreateReviewCheckinMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateReviewCheckinInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReviewCheckin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'checkin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReviewCheckinFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReviewCheckinFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewCheckin' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastPageIndexViewed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStartDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_CreateReviewCheckinMutation,
  Mobile_CreateReviewCheckinMutationVariables
>;
export const Mobile_GetAccountOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetAccountOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountFilters' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_GetAccountOptionsQuery, Mobile_GetAccountOptionsQueryVariables>;
export const Mobile_DeleteAccountLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_DeleteAccountLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAccountLogo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasCustomizedLogo' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_DeleteAccountLogoMutation,
  Mobile_DeleteAccountLogoMutationVariables
>;
export const Mobile_GetEditAccountScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetEditAccountScreen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invertSyncedBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canInvertBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useAvailableBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canUseAvailableBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasCustomizedLogo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtype' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'credential' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditReportLiabilityAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastStatement' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurringTransactionStream' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dayOfTheMonth' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'defaultPaymentAccount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'defaultPaymentCategory' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetEditAccountScreenQuery,
  Mobile_GetEditAccountScreenQueryVariables
>;
export const Mobile_GetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeSystemDisabledCategories' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rolloverPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeDisabledSystemCategories' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeSystemDisabledCategories' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rolloverPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_GetCategoriesQuery, Mobile_GetCategoriesQueryVariables>;
export const Mobile_UpdateCategoryGroupOrderMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_UpdateCategoryGroupOrderMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategoryGroupOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_UpdateCategoryGroupOrderMutation,
  Mobile_UpdateCategoryGroupOrderMutationVariables
>;
export const Mobile_UpdateCategoryOrderMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_UpdateCategoryOrderMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategoryOrderInCategoryGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categoryGroupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroupId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_UpdateCategoryOrderMutation,
  Mobile_UpdateCategoryOrderMutationVariables
>;
export const Mobile_LinkGoalOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_LinkGoalOptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_LinkGoalOptionsQuery, Mobile_LinkGoalOptionsQueryVariables>;
export const Mobile_SelectGoalsScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_SelectGoalsScreen' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_SelectGoalsScreenQuery, Mobile_SelectGoalsScreenQueryVariables>;
export const Mobile_CreatePlaidLinkTokenMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_CreatePlaidLinkTokenMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'credentialId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkCustomizationName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUri' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'androidPackageName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPlaidLinkToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'credentialId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'credentialId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkCustomizationName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkCustomizationName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'plaidInstitutionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'plaidInstitutionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectUri' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUri' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'androidPackageName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'androidPackageName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'linkToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_CreatePlaidLinkTokenMutation,
  Mobile_CreatePlaidLinkTokenMutationVariables
>;
export const GetMobileSubscriptionOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMobileSubscriptionOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platform' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mobileSubscriptionOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platform' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platform' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'availableOfferingName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'packageMetadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'packageType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'promoText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountTitle' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMobileSubscriptionOfferingQuery,
  GetMobileSubscriptionOfferingQueryVariables
>;
export const Mobile_UpdateReviewCheckinMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_UpdateReviewCheckinMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateReviewCheckinInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateReviewCheckin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'checkin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReviewCheckinFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReviewCheckinFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewCheckin' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastPageIndexViewed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStartDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_UpdateReviewCheckinMutation,
  Mobile_UpdateReviewCheckinMutationVariables
>;
export const Mobile_SetTransactionTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_SetTransactionTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SetTransactionTagsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setTransactionTags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_SetTransactionTagsMutation,
  Mobile_SetTransactionTagsMutationVariables
>;
export const GetCanRequestReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCanRequestReview' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canRequestAppStoreReview' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCanRequestReviewQuery, GetCanRequestReviewQueryVariables>;
export const Mobile_DidRequestReviewMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_DidRequestReviewMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'didRequestAppStoreReview' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canRequestAppStoreReview' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_DidRequestReviewMutation,
  Mobile_DidRequestReviewMutationVariables
>;
export const Mobile_InitialPermissionRequestModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_InitialPermissionRequestModal' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trialDurationDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_InitialPermissionRequestModalQuery,
  Mobile_InitialPermissionRequestModalQueryVariables
>;
export const Mobile_SideDrawerButtonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_SideDrawerButton' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNewActivity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_SideDrawerButtonQuery, Mobile_SideDrawerButtonQueryVariables>;
export const Mobile_GetAccountDetailScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetAccountDetailScreen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accounts' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'Variable', name: { kind: 'Name', value: 'accountId' } }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '4' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'snapshots' },
            name: { kind: 'Name', value: 'snapshotsForAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedBalance' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'credential' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isHistoricalUpdateCompleted' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLiability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountLogoFields' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountSummaryCardAccountFields' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ConnectionStatusCardFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountSummaryCardAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionStatusCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetAccountDetailScreenQuery,
  Mobile_GetAccountDetailScreenQueryVariables
>;
export const Mobile_GetAggregateSnapshotsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetAggregateSnapshots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AggregateSnapshotFilters' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aggregateSnapshots' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetAggregateSnapshotsQuery,
  Mobile_GetAggregateSnapshotsQueryVariables
>;
export const Mobile_GetDuplicateCredentialsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetDuplicateCredentials' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CredentialFilters' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credentials' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AccountComponentFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountComponentFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountLogoFields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetDuplicateCredentialsQuery,
  Mobile_GetDuplicateCredentialsQueryVariables
>;
export const Mobile_CreateZillowAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_CreateZillowAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSyncedRealEstateAccountInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSyncedRealEstateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_CreateZillowAccountMutation,
  Mobile_CreateZillowAccountMutationVariables
>;
export const Mobile_CreateSyncedVehicleAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_CreateSyncedVehicleAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSyncedVehicleAccountInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSyncedVehicleAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_CreateSyncedVehicleAccountMutation,
  Mobile_CreateSyncedVehicleAccountMutationVariables
>;
export const Mobile_CreateManualAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_CreateManualAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateManualAccountMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createManualAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_CreateManualAccountMutation,
  Mobile_CreateManualAccountMutationVariables
>;
export const Mobile_SearchVehiclesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_SearchVehiclesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_SearchVehiclesQuery, Mobile_SearchVehiclesQueryVariables>;
export const Mobile_GetZestimateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetZestimate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zestimates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'zpid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressStreet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressCity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressStateAbbr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressPostalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zestimate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_GetZestimateQuery, Mobile_GetZestimateQueryVariables>;
export const Mobile_SubmitAssistantFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_SubmitAssistantFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubmitAssistantFeedbackInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitAssistantFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_SubmitAssistantFeedbackMutation,
  Mobile_SubmitAssistantFeedbackMutationVariables
>;
export const Mobile_GetMessageThreadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetMessageThreads' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageThreads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Mobile_MessageThreadListItemFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aiAssistantOptedInAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_MessageThreadListItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastMessageSentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOutstandingAssistantRequests' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_GetMessageThreadsQuery, Mobile_GetMessageThreadsQueryVariables>;
export const Mobile_GetMessageThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetMessageThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'threadId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'threadId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasOutstandingAssistantRequests' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Mobile_MessageListGroupFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_UserMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_AssistantMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedPrompts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_AssistantStatusMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantStatusMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_MessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_UserMessageListGroupFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_AssistantMessageListGroupFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_AssistantStatusMessageListGroupFields' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_GetMessageThreadQuery, Mobile_GetMessageThreadQueryVariables>;
export const Mobile_OnMessageUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'Mobile_OnMessageUpdated' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onMessageUpdated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Mobile_MessageListGroupFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_UserMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_AssistantMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedPrompts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_AssistantStatusMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantStatusMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_MessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_UserMessageListGroupFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_AssistantMessageListGroupFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_AssistantStatusMessageListGroupFields' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_OnMessageUpdatedSubscription,
  Mobile_OnMessageUpdatedSubscriptionVariables
>;
export const Mobile_OnMessageCreatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'Mobile_OnMessageCreated' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onMessageCreated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Mobile_MessageListGroupFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_UserMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_AssistantMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedPrompts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_AssistantStatusMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantStatusMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_MessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_UserMessageListGroupFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_AssistantMessageListGroupFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_AssistantStatusMessageListGroupFields' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_OnMessageCreatedSubscription,
  Mobile_OnMessageCreatedSubscriptionVariables
>;
export const Mobile_OnMessageThreadUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'Mobile_OnMessageThreadUpdated' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onMessageThreadUpdated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messageThread' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Mobile_MessageThreadListItemFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_MessageThreadListItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastMessageSentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOutstandingAssistantRequests' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_OnMessageThreadUpdatedSubscription,
  Mobile_OnMessageThreadUpdatedSubscriptionVariables
>;
export const MoveMoneyScreenQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MoveMoneyScreenQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoveMoneyScreenQuery, MoveMoneyScreenQueryVariables>;
export const Mobile_MoveMoneyScreenMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_MoveMoneyScreenMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MoveMoneyMutationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveMoneyBetweenCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fromBudgetItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetAmount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'toBudgetItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetAmount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_MoveMoneyScreenMutation,
  Mobile_MoveMoneyScreenMutationVariables
>;
export const Mobile_ResetRolloverMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_ResetRolloverMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResetBudgetRolloverInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetBudgetRollover' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_ResetRolloverMutation, Mobile_ResetRolloverMutationVariables>;
export const SelectBudgetCategoryScreenQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SelectBudgetCategoryScreenQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelectBudgetCategoryScreenQuery,
  SelectBudgetCategoryScreenQueryVariables
>;
export const Mobile_UpdateNonMonthlyCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_UpdateNonMonthlyCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCategoryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BudgetCategoryFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetRolloverPeriodFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetRolloverPeriod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetCategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BudgetRolloverPeriodFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_UpdateNonMonthlyCategoryMutation,
  Mobile_UpdateNonMonthlyCategoryMutationVariables
>;
export const CashFlowScreenAggregatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CashFlowScreenAggregates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byYear' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'year', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'year' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byMonth' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'month', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'month' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byQuarter' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'quarter', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'quarter' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CashFlowScreenAggregatesQuery, CashFlowScreenAggregatesQueryVariables>;
export const Mobile_GetCashFlowScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetCashFlowScreen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cacheKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cacheKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cacheKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cacheKey' } },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byCategory' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'category', block: false }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'group' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sum' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byCategoryGroup' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'categoryGroup', block: false }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categoryGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sum' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byMerchant' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'merchant', block: false }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'summary' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_GetCashFlowScreenQuery, Mobile_GetCashFlowScreenQueryVariables>;
export const Mobile_GetCategoryDeletionInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetCategoryDeletionInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryDeletionInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rulesCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetCategoryDeletionInfoQuery,
  Mobile_GetCategoryDeletionInfoQueryVariables
>;
export const Mobile_DeleteCategoryMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_DeleteCategoryMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'moveToCategoryId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moveToCategoryId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'moveToCategoryId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_DeleteCategoryMutation, Mobile_DeleteCategoryMutationVariables>;
export const Mobile_CategoryDetailQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_CategoryDetailQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'categoryId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'month' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'categoryId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rolloverPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'budgetAmountsForMonth' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'month' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'month' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BudgetMonthAmounts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byYear' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'year', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categories' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'Variable', name: { kind: 'Name', value: 'categoryId' } }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'year' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byMonth' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'month', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categories' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'Variable', name: { kind: 'Name', value: 'categoryId' } }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'month' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byQuarter' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'quarter', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categories' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'Variable', name: { kind: 'Name', value: 'categoryId' } }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'quarter' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthAmounts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthlyAmounts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_CategoryDetailQuery, Mobile_CategoryDetailQueryVariables>;
export const Mobile_DeleteCategoryGroupMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_DeleteCategoryGroupMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'moveToGroupId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCategoryGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moveToGroupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'moveToGroupId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_DeleteCategoryGroupMutation,
  Mobile_DeleteCategoryGroupMutationVariables
>;
export const Mobile_CategoryGroupDetailContainerQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_CategoryGroupDetailContainerQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'month' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroupId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rolloverPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'budgetAmountsForMonth' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'month' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'month' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BudgetMonthAmounts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BudgetMonthAmounts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthlyAmounts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_CategoryGroupDetailContainerQuery,
  Mobile_CategoryGroupDetailContainerQueryVariables
>;
export const Mobile_CategoryGroupDetailChartQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_CategoryGroupDetailChartQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'summary' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categoryGroups' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroupId' } },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AggregatesSummaryFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byYear' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'year', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categoryGroups' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroupId' } },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'year' } }],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AggregatesSummaryFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byMonth' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'month', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categoryGroups' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroupId' } },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'month' } }],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AggregatesSummaryFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byQuarter' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'quarter', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categoryGroups' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroupId' } },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'quarter' } }],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AggregatesSummaryFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AggregatesSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AggregateData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_CategoryGroupDetailChartQuery,
  Mobile_CategoryGroupDetailChartQueryVariables
>;
export const Mobile_CustomizeGoalScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_CustomizeGoalScreen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'goalId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'goalId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomizeGoalFormFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomizeGoalFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_CustomizeGoalScreenQuery,
  Mobile_CustomizeGoalScreenQueryVariables
>;
export const Mobile_GoalDetailScreenV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GoalDetailScreenV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'goalId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'goalId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalNameProgressBarFields' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalDetailsFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountMaskFieldsMobile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAccountBalanceRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalAccountAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMonthChange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountMaskFieldsMobile' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalContributionGraphFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyContributionSummaries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sumCredit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sumDebit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalNameProgressBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalDetailsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyPretaxContribution' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objective' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalAccountBalanceRowFields' },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalContributionGraphFields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GoalDetailScreenV2Query,
  Mobile_GoalDetailScreenV2QueryVariables
>;
export const Mobile_GoalsAllocateBalanceScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GoalsAllocateBalanceScreen' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountsWithUnallocatedBalancesForGoals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableBalanceForGoals' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'availableBalanceForGoals' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'GoalAccountsMapRowFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAccountsMapRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtype' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GoalsAllocateBalanceScreenQuery,
  Mobile_GoalsAllocateBalanceScreenQueryVariables
>;
export const Mobile_GoalsMapAccountsScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GoalsMapAccountsScreen' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalAccountsMapRowFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAccountsMapRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtype' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GoalsMapAccountsScreenQuery,
  Mobile_GoalsMapAccountsScreenQueryVariables
>;
export const Mobile_GoalsMonthlyBudgetsScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GoalsMonthlyBudgetsScreen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objective' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalInfoRowFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'savings' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalInfoRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GoalsMonthlyBudgetsScreenQuery,
  Mobile_GoalsMonthlyBudgetsScreenQueryVariables
>;
export const Mobile_GoalsPreTaxBudgetsScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GoalsPreTaxBudgetsScreen' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plannedMonthlyPretaxContribution' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'objective' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalInfoRowFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalInfoRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GoalsPreTaxBudgetsScreenQuery,
  Mobile_GoalsPreTaxBudgetsScreenQueryVariables
>;
export const Mobile_GoalsScreenV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GoalsScreenV2' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalCardFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountsWithUnallocatedBalancesForGoals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MobileGoalsAllocateBannerFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalNameProgressBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AccountLogoFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalNameProgressBarFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MobileGoalsAllocateBannerFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_GoalsScreenV2Query, Mobile_GoalsScreenV2QueryVariables>;
export const Mobile_GoalsTargetAmountsScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GoalsTargetAmountsScreen' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalInfoRowFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalInfoRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GoalsTargetAmountsScreenQuery,
  Mobile_GoalsTargetAmountsScreenQueryVariables
>;
export const Mobile_RankGoalsScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_RankGoalsScreen' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RankGoalRowFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalInfoRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankGoalRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalInfoRowFields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_RankGoalsScreenQuery, Mobile_RankGoalsScreenQueryVariables>;
export const Mobile_UpdateGoalsPrioritiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_UpdateGoalsPriorities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateGoalPrioritiesInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGoalPriorities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_UpdateGoalsPrioritiesMutation,
  Mobile_UpdateGoalsPrioritiesMutationVariables
>;
export const Mobile_GetInvestmentsPortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetInvestmentsPortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PortfolioInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchedPerformanceInput' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRange' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'batchedPerformance' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'batchedPerformanceInput' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalValue' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'historicalCharts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'period' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'chart' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'returnPercent' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InvestmentChartsFieldsMobile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllocationChartFieldsMobile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationSimple' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeDisplay' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocationPercent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalValue' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestmentChartsFieldsMobile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllocationChartFieldsMobile' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetInvestmentsPortfolioQuery,
  Mobile_GetInvestmentsPortfolioQueryVariables
>;
export const Mobile_GetSecuritiesHistoricalPerformanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetSecuritiesHistoricalPerformance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SecurityHistoricalPerformanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'securityHistoricalPerformance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'security' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'historicalChart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnPercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetSecuritiesHistoricalPerformanceQuery,
  Mobile_GetSecuritiesHistoricalPerformanceQueryVariables
>;
export const Mobile_GetInvestmentsAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetInvestmentsAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountFilters' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountLogoFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetInvestmentsAccountsQuery,
  Mobile_GetInvestmentsAccountsQueryVariables
>;
export const Mobile_GetPortfolioBenchmarksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetPortfolioBenchmarks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PortfolioInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'benchmarks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'security' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetPortfolioBenchmarksQuery,
  Mobile_GetPortfolioBenchmarksQueryVariables
>;
export const Mobile_GetEditMerchantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetEditMerchant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ruleCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canBeDeleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_GetEditMerchantQuery, Mobile_GetEditMerchantQueryVariables>;
export const Mobile_UpdateMerchantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_UpdateMerchant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMerchantInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMerchant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'merchant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurringTransactionStream' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'baseDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_UpdateMerchantMutation, Mobile_UpdateMerchantMutationVariables>;
export const Mobile_DeleteMerchantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_DeleteMerchant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'moveToId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMerchant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moveRelationsToMerchantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'moveToId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_DeleteMerchantMutation, Mobile_DeleteMerchantMutationVariables>;
export const Mobile_SearchMerchantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_SearchMerchants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_SearchMerchantsQuery, Mobile_SearchMerchantsQueryVariables>;
export const Mobile_GetAllRecurringTransactionItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetAllRecurringTransactionItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RecurringTransactionFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringTransactionStreams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeLiabilities' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isApproximate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creditReportLiabilityAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextForecastedTransaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetAllRecurringTransactionItemsQuery,
  Mobile_GetAllRecurringTransactionItemsQueryVariables
>;
export const Mobile_GetUpcomingRecurringTransactionItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetUpcomingRecurringTransactionItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RecurringTransactionFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringTransactionItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeLiabilities' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isApproximate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creditReportLiabilityAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'liabilityType' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPast' } },
                { kind: 'Field', name: { kind: 'Name', value: 'markedPaidAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amountDiff' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAmountDifferentThanOriginal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditReportLiabilityStatementId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'liabilityStatement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remainingBalance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minimumPaymentAmount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentTransactions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'group' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetUpcomingRecurringTransactionItemsQuery,
  Mobile_GetUpcomingRecurringTransactionItemsQueryVariables
>;
export const Mobile_NetWorthChartOverlyQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_NetWorthChartOverlyQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_NetWorthChartOverlyQuery,
  Mobile_NetWorthChartOverlyQueryVariables
>;
export const ReviewQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReviewQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'review' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hideFooter' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'analyticsName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'background' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PageBackgroundFields' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blocks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'BlockFields' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReviewHeaderFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasSeenWeeklyReviewTour' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageTitleBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageTitleBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'header' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'centerSubtitle' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HorizontalBarChartBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HorizontalBarChartBar' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLabel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HorizontalBarChartBlockFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HorizontalBarChartBlock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'topRightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smallBarStyle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bars' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HorizontalBarChartBarFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PieChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PieChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SparklineRowBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SparklineRowBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rightLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labelColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lineColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'values' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'largeStyle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideValueChange' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MerchantsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MerchantsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountsListBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountsListBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalanceAsOf' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetWorthChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NetWorthChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotsByAccountType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StackedBarChartBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StackedBarChartBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'groupColors' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShareableBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShareableBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedSubtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedFooter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareButtonText' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfoBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InfoBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupBlock' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'centered' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllBlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllBlockTypes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PageTitleBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextBlockFields' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'HorizontalBarChartBlockFields' },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PieChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SparklineRowBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MerchantsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountsListBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NetWorthChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StackedBarChartBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShareableBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InfoBlockFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GroupBlockFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BlockLinkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Block' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blocks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllBlockFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BlockFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllBlockTypes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllBlockFields' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GroupBlock' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllBlockFields' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShareableBlock' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllBlockFields' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HorizontalBarChartBlock' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bars' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PieChartBlock' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StackedBarChartBlock' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'links' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'blocks' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AllBlockFields' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockLinkFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageBackgroundFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewPageBackground' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gradient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'colors' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReviewHeaderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewPage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'header' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewQuery, ReviewQueryVariables>;
export const Mobile_GetAddTransactionScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetAddTransactionScreen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLiability' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountLogoFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetAddTransactionScreenQuery,
  Mobile_GetAddTransactionScreenQueryVariables
>;
export const Mobile_MerchantDetailContainerQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_MerchantDetailContainerQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_MerchantDetailContainerQuery,
  Mobile_MerchantDetailContainerQueryVariables
>;
export const Mobile_MerchantChartQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_MerchantChartQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchants' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'summary' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'merchants' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchants' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AggregatesSummaryFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byYear' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'year', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'merchants' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchants' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'year' } }],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AggregatesSummaryFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byMonth' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'month', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'merchants' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchants' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'month' } }],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AggregatesSummaryFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byQuarter' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'quarter', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'merchants' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchants' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'quarter' } }],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AggregatesSummaryFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AggregatesSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AggregateData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_MerchantChartQuery, Mobile_MerchantChartQueryVariables>;
export const Mobile_GetSelectCategoryScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetSelectCategoryScreen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'merchant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetSelectCategoryScreenQuery,
  Mobile_GetSelectCategoryScreenQueryVariables
>;
export const Mobile_CategoryRecommendationForTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_CategoryRecommendationForTransaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendedCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'occurrences' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_CategoryRecommendationForTransactionQuery,
  Mobile_CategoryRecommendationForTransactionQueryVariables
>;
export const Mobile_GetCategoryGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetCategoryGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_GetCategoryGroupsQuery, Mobile_GetCategoryGroupsQueryVariables>;
export const Mobile_GetSelectMerchantScreenTransactionDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetSelectMerchantScreenTransactionDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'merchant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetSelectMerchantScreenTransactionDetailsQuery,
  Mobile_GetSelectMerchantScreenTransactionDetailsQueryVariables
>;
export const Mobile_GetSelectMerchantScreenHouseholdMerchantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetSelectMerchantScreenHouseholdMerchants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MerchantOrdering' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeMerchantsWithoutTransactions' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetSelectMerchantScreenHouseholdMerchantsQuery,
  Mobile_GetSelectMerchantScreenHouseholdMerchantsQueryVariables
>;
export const Mobile_GetSelectMerchantScreenRecommendedMerchantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetSelectMerchantScreenRecommendedMerchants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendedMerchants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetSelectMerchantScreenRecommendedMerchantsQuery,
  Mobile_GetSelectMerchantScreenRecommendedMerchantsQueryVariables
>;
export const Mobile_GetAttachmentDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetAttachmentDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attachmentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactionAttachment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attachmentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalAssetUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetAttachmentDetailsQuery,
  Mobile_GetAttachmentDetailsQueryVariables
>;
export const Mobile_DeleteAttachmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_DeleteAttachment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attachmentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTransactionAttachment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attachmentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deleted' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_DeleteAttachmentMutation,
  Mobile_DeleteAttachmentMutationVariables
>;
export const Mobile_GetTransactionDetailScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetTransactionDetailScreen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectPosted' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectPosted' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectPosted' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
                { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'needsReviewByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reviewedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviewedByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalAssetUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'merchant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurringTransactionStream' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AccountLogoFields' },
                      },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionGoalFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasSplitTransactions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'splitTransactions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'originalTransaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Mobile_OriginalTransactionFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionGoalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProvider' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProviderId' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_OriginalTransactionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetTransactionDetailScreenQuery,
  Mobile_GetTransactionDetailScreenQueryVariables
>;
export const Mobile_UpdateTransactionDetailScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_UpdateTransactionDetailScreen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTransactionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProvider' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageStorageProviderId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_UpdateTransactionDetailScreenMutation,
  Mobile_UpdateTransactionDetailScreenMutationVariables
>;
export const Mobile_GetAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NewAccountLogoFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewAccountLogoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_GetAccountQuery, Mobile_GetAccountQueryVariables>;
export const Mobile_CreateAppleCardAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_CreateAppleCardAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateManualAccountMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createManualAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_CreateAppleCardAccountMutation,
  Mobile_CreateAppleCardAccountMutationVariables
>;
export const Mobile_DirectLinkInstitutionModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_DirectLinkInstitutionModal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plaidId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'finicityId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mxId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'plaidId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'plaidId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'finicityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'finicityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mxId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Mobile_InstitutionRowFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_InstitutionRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'largeLogo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarnBeforeConnecting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningTitle' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_DirectLinkInstitutionModalQuery,
  Mobile_DirectLinkInstitutionModalQueryVariables
>;
export const SearchInstitutionsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchInstitutionsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allInstitutions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataProviders' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Mobile_InstitutionRowFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_InstitutionRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'largeLogo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarnBeforeConnecting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningTitle' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchInstitutionsQuery, SearchInstitutionsQueryVariables>;
export const Mobile_GetTopInstitutionGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetTopInstitutionGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credentials' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topInstitutionGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataProviders' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountsConnectedCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institutions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Mobile_InstitutionRowFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manualAccountShortcuts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountSubtype' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_InstitutionRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'largeLogo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarnBeforeConnecting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningTitle' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetTopInstitutionGroupsQuery,
  Mobile_GetTopInstitutionGroupsQueryVariables
>;
export const LinkSearchInstitutionsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LinkSearchInstitutionsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allInstitutions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataProviders' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Mobile_InstitutionRowFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_InstitutionRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'largeLogo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarnBeforeConnecting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningTitle' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LinkSearchInstitutionsQuery, LinkSearchInstitutionsQueryVariables>;
export const Mobile_CreateCoinbaseAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_CreateCoinbaseAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCoinbaseAccountInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCoinbaseAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_CreateCoinbaseAccountMutation,
  Mobile_CreateCoinbaseAccountMutationVariables
>;
export const Mobile_AddCategoryGroupMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_AddCategoryGroupMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateCategoryGroupInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCategoryGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_AddCategoryGroupMutation,
  Mobile_AddCategoryGroupMutationVariables
>;
export const Mobile_UpdateCategoryGroupMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_UpdateCategoryGroupMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCategoryGroupInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategoryGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_UpdateCategoryGroupMutation,
  Mobile_UpdateCategoryGroupMutationVariables
>;
export const Mobile_CreateCategoryMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_CreateCategoryMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateCategoryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'systemCategoryDisplayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolloverPeriod' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_CreateCategoryMutation, Mobile_CreateCategoryMutationVariables>;
export const Mobile_UpdateCategoryMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_UpdateCategoryMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCategoryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'systemCategoryDisplayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolloverPeriod' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_UpdateCategoryMutation, Mobile_UpdateCategoryMutationVariables>;
export const GetBillingSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBillingSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEndsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'willCancelAtPeriodEnd' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creditBalance' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBillingSettingsQuery, GetBillingSettingsQueryVariables>;
export const Mobile_RestoreCategoryMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_RestoreCategoryMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restoreCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_RestoreCategoryMutation,
  Mobile_RestoreCategoryMutationVariables
>;
export const Mobile_ResetHouseholdRecurringMerchantSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_ResetHouseholdRecurringMerchantSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deleteStreams' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetRecurringMerchantSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deleteStreams' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deleteStreams' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_ResetHouseholdRecurringMerchantSearchMutation,
  Mobile_ResetHouseholdRecurringMerchantSearchMutationVariables
>;
export const Mobile_OldestDeletableSyncedSnapshotDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_OldestDeletableSyncedSnapshotDate' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'oldestDeletableSyncedSnapshotDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_OldestDeletableSyncedSnapshotDateQuery,
  Mobile_OldestDeletableSyncedSnapshotDateQueryVariables
>;
export const Mobile_PreviewDeleteSyncedSnapshotsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_PreviewDeleteSyncedSnapshots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'beforeDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewDeleteSyncedSnapshots' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'beforeDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'beforeDate' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_PreviewDeleteSyncedSnapshotsQuery,
  Mobile_PreviewDeleteSyncedSnapshotsQueryVariables
>;
export const Mobile_GetHouseholdMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetHouseholdMembers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myHousehold' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MyHouseholdUsersFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'householdInvites' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invitedEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRevoked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'householdAccessGrants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HouseholdAccessGrantsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyHouseholdUsersFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'householdRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasMfaOn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HouseholdAccessGrantsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdAccessGrant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'toEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'toName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetHouseholdMembersQuery,
  Mobile_GetHouseholdMembersQueryVariables
>;
export const Mobile_RevokeInviteToHouseholdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_RevokeInviteToHousehold' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RevokeInviteToHouseholdMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revokeInviteToHousehold' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invite' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRevoked' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_RevokeInviteToHouseholdMutation,
  Mobile_RevokeInviteToHouseholdMutationVariables
>;
export const Mobile_DeleteUserFromHouseholdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_DeleteUserFromHousehold' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteUserFromHouseholdMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserFromHousehold' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_DeleteUserFromHouseholdMutation,
  Mobile_DeleteUserFromHouseholdMutationVariables
>;
export const Mobile_RevokeHouseholdAccessGrantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_RevokeHouseholdAccessGrant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RevokeHouseholdAccessGrantInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revokeHouseholdAccessGrant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'revoked' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_RevokeHouseholdAccessGrantMutation,
  Mobile_RevokeHouseholdAccessGrantMutationVariables
>;
export const Mobile_GetInstitutionSettingsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetInstitutionSettingsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credentials' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CredentialCardFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeDeleted' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'credential' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstitutionAccountRowFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_InstitutionLogoWithStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Mobile_InstitutionInfoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Mobile_InstitutionLogoWithStatusFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CredentialCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Mobile_InstitutionInfoFields' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionAccountRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetInstitutionSettingsQuery,
  Mobile_GetInstitutionSettingsQueryVariables
>;
export const Mobile_CreateHouseholdAccessGrantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_CreateHouseholdAccessGrant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateHouseholdAccessGrantInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHouseholdAccessGrant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accessGrant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'toEmail' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_CreateHouseholdAccessGrantMutation,
  Mobile_CreateHouseholdAccessGrantMutationVariables
>;
export const Mobile_InviteEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_InviteEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InviteUserToHouseholdMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteUserToHousehold' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invite' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invitedEmail' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Mobile_InviteEmailMutation, Mobile_InviteEmailMutationVariables>;
export const Mobile_DeleteUserProfilePictureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_DeleteUserProfilePicture' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserProfilePicture' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deleted' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_DeleteUserProfilePictureMutation,
  Mobile_DeleteUserProfilePictureMutationVariables
>;
export const RulesSettingsScreenQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RulesSettingsScreenQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactionRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransactionRuleFieldsMobile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionRuleFieldsMobile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionRuleV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'merchantCriteriaUseOriginalStatement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchantCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isExpense' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'categoryIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setMerchantAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setCategoryAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTagsAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkGoalAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendNotificationAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'setHideFromReportsAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatusAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastAppliedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recentApplicationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unassignNeedsReviewByUserAction' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsReviewByUserAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'splitTransactionsAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amountType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'splitsInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'goalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'needsReviewByUserId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RulesSettingsScreenQuery, RulesSettingsScreenQueryVariables>;
export const Mobile_ToggleSupportAccountAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_ToggleSupportAccountAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleSupportAccountAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isEnabled' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isEnabled' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_ToggleSupportAccountAccessMutation,
  Mobile_ToggleSupportAccountAccessMutationVariables
>;
export const Mobile_GetUserReportedOnboardingAttributionChannelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Mobile_GetUserReportedOnboardingAttributionChannels' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReportedOnboardingAttributionChannels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_GetUserReportedOnboardingAttributionChannelsQuery,
  Mobile_GetUserReportedOnboardingAttributionChannelsQueryVariables
>;
export const Mobile_SetUserReportedAttributionChannelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mobile_SetUserReportedAttributionChannels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'names' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userReportedAttributionChannels' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'names' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userReportedAttributionChannels' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Mobile_SetUserReportedAttributionChannelsMutation,
  Mobile_SetUserReportedAttributionChannelsMutationVariables
>;
export const Web_AccountEditModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_AccountEditModal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EditAccountFormFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditAccountFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasCustomizedLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invertSyncedBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canInvertBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useAvailableBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canUseAvailableBalance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditReportLiabilityAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastStatement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dayOfTheMonth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultPaymentAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultPaymentCategory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_AccountEditModalQuery, Web_AccountEditModalQueryVariables>;
export const Web_CreateManualAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_CreateManualAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateManualAccountMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createManualAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_CreateManualAccountMutation,
  Web_CreateManualAccountMutationVariables
>;
export const CreateSyncedVehicleAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSyncedVehicleAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSyncedVehicleAccountInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSyncedVehicleAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSyncedVehicleAccountMutation,
  CreateSyncedVehicleAccountMutationVariables
>;
export const Web_CreateZillowAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_CreateZillowAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSyncedRealEstateAccountInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSyncedRealEstateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_CreateZillowAccountMutation,
  Web_CreateZillowAccountMutationVariables
>;
export const Web_CopySnapshotsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_CopySnapshots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CopySnapshotsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copySnapshots' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'numSnapshotsCopied' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'toAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_CopySnapshotsMutation, Web_CopySnapshotsMutationVariables>;
export const Web_PreviewCopySnapshotsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_PreviewCopySnapshots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PreviewCopySnapshotsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewCopySnapshots' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_PreviewCopySnapshotsQuery, Web_PreviewCopySnapshotsQueryVariables>;
export const Web_SnapshotsForAccountCopyBalancePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_SnapshotsForAccountCopyBalancePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotsForAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'date' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_SnapshotsForAccountCopyBalancePreviewQuery,
  Web_SnapshotsForAccountCopyBalancePreviewQueryVariables
>;
export const Web_GetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetCategoriesQuery, Web_GetCategoriesQueryVariables>;
export const GetDuplicateCredentialsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDuplicateCredentials' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CredentialFilters' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credentials' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CredentialSettingsCardFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionLogoWithStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionInfoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'InstitutionLogoWithStatusFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CredentialSettingsCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstitutionInfoFields' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDuplicateCredentialsQuery, GetDuplicateCredentialsQueryVariables>;
export const Web_CreateCoinbaseAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_CreateCoinbaseAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCoinbaseAccountInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCoinbaseAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_CreateCoinbaseAccountMutation,
  Web_CreateCoinbaseAccountMutationVariables
>;
export const Web_GetTopInstitutionGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetTopInstitutionGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credentials' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topInstitutionGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataProviders' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountsConnectedCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institutions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InstitutionRowFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manualAccountShortcuts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountSubtype' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'largeLogo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarnBeforeConnecting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetTopInstitutionGroupsQuery,
  Web_GetTopInstitutionGroupsQueryVariables
>;
export const Web_MoveTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_MoveTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MoveTransactionsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'numTransactionsMoved' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_MoveTransactionsMutation, Web_MoveTransactionsMutationVariables>;
export const Web_GetZestimateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetZestimate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zestimates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'zpid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressStreet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressCity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressStateAbbr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressPostalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zestimate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetZestimateQuery, Web_GetZestimateQueryVariables>;
export const AdviceItemDrawerQuery_WebDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdviceItemDrawerQuery_Web' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'itemId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'previousItemId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skipPrevious' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'previousItem' },
            name: { kind: 'Name', value: 'adviceItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'previousItemId' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'skip' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'skipPrevious' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adviceItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'itemId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prerequisiteAdviceItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CondensedAdviceItemRowFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdviceTaskRowFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItemCategory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CondensedAdviceItemRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasksCompleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceTaskRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItemTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdviceItemDrawerQuery_WebQuery,
  AdviceItemDrawerQuery_WebQueryVariables
>;
export const AdviceTaskDrawerQuery_WebDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdviceTaskDrawerQuery_Web' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'itemId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adviceItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'itemId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adviceItemTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdviceTaskDrawerQuery_WebQuery,
  AdviceTaskDrawerQuery_WebQueryVariables
>;
export const Web_CreateSubscriptionSponsorshipInvitesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_CreateSubscriptionSponsorshipInvites' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSponsorshipInvitesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSubscriptionSponsorshipInvites' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invites' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_CreateSubscriptionSponsorshipInvitesMutation,
  Web_CreateSubscriptionSponsorshipInvitesMutationVariables
>;
export const Web_CancelSponsorshipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_CancelSponsorship' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelSponsorshipInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelSubscriptionSponsorship' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canceled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_CancelSponsorshipMutation, Web_CancelSponsorshipMutationVariables>;
export const Web_RevokeSponsorshipInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_RevokeSponsorshipInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RevokeSponsorshipInviteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revokeSubscriptionSponsorshipInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'revoked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_RevokeSponsorshipInviteMutation,
  Web_RevokeSponsorshipInviteMutationVariables
>;
export const Web_SubmitAssistantFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_SubmitAssistantFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubmitAssistantFeedbackInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitAssistantFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_SubmitAssistantFeedbackMutation,
  Web_SubmitAssistantFeedbackMutationVariables
>;
export const Web_AccountsMessageBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_AccountsMessageBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ids' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtype' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_AccountsMessageBlockQuery, Web_AccountsMessageBlockQueryVariables>;
export const Web_GoalsMessageBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GoalsMessageBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Web_GoalDashboardRowFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Web_GoalDashboardRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentMonthChange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GoalsMessageBlockQuery, Web_GoalsMessageBlockQueryVariables>;
export const Web_TransactionsMessageBlockDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_TransactionsMessageBlock' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ids' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_TransactionsMessageBlockQuery,
  Web_TransactionsMessageBlockQueryVariables
>;
export const Web_MoveMoneyMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_MoveMoneyMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MoveMoneyMutationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveMoneyBetweenCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fromBudgetItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetAmount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'toBudgetItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetAmount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_MoveMoneyMutation, Web_MoveMoneyMutationVariables>;
export const GetCategorySelectOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCategorySelectOptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCategorySelectOptionsQuery, GetCategorySelectOptionsQueryVariables>;
export const Web_CreateCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_CreateCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateCategoryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CategoryFormFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategoryDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_CreateCategoryMutation, Web_CreateCategoryMutationVariables>;
export const CategoryDeletionInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CategoryDeletionInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryDeletionInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rulesCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CategoryDeletionInfoQuery, CategoryDeletionInfoQueryVariables>;
export const Web_GetCategoryGroupModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetCategoryGroupModal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeDisabledSystemCategories' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryGroupFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'includeDisabledSystemCategories' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'includeDisabledSystemCategories' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rolloverPeriod' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetCategoryGroupModalQuery,
  Web_GetCategoryGroupModalQueryVariables
>;
export const Web_GetEditCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetEditCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryFormFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategoryDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetEditCategoryQuery, Web_GetEditCategoryQueryVariables>;
export const Web_UpdateCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCategoryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CategoryFormFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategoryDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_UpdateCategoryMutation, Web_UpdateCategoryMutationVariables>;
export const Web_RestoreCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_RestoreCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restoreCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CategoryFormFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategoryDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_RestoreCategoryMutation, Web_RestoreCategoryMutationVariables>;
export const Web_GetCategorySelectOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetCategorySelectOptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetCategorySelectOptionsQuery,
  Web_GetCategorySelectOptionsQueryVariables
>;
export const Web_GetRecommendedCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetRecommendedCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendedCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'occurrences' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetRecommendedCategoriesQuery,
  Web_GetRecommendedCategoriesQueryVariables
>;
export const ManageGetCategoryGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ManageGetCategoryGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeDisabledSystemCategories' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManageGetCategoryGroupsQuery, ManageGetCategoryGroupsQueryVariables>;
export const Web_ResetRolloverMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_ResetRolloverMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResetBudgetRolloverInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetBudgetRollover' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_ResetRolloverMutation, Web_ResetRolloverMutationVariables>;
export const Web_GetAdviceDashboardWidgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetAdviceDashboardWidget' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adviceItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: { kind: 'StringValue', value: 'objective', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CondensedAdviceItemRowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileQuestionnaire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'StringValue', value: 'general', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'state' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numQuestionsAnswered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItemCategory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CondensedAdviceItemRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasksCompleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetAdviceDashboardWidgetQuery,
  Web_GetAdviceDashboardWidgetQueryVariables
>;
export const Web_GoalsDashboardCardV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GoalsDashboardCardV2' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'migratedToGoalsV2' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Web_GoalDashboardRowFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Web_GoalDashboardRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentMonthChange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GoalsDashboardCardV2Query, Web_GoalsDashboardCardV2QueryVariables>;
export const Web_GetInvestmentsDashboardCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetInvestmentsDashboardCard' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'oneDayChangeDollars' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topMovers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'oneDayChangePercent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPrice' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetInvestmentsDashboardCardQuery,
  Web_GetInvestmentsDashboardCardQueryVariables
>;
export const Web_GetDashboardUpcomingRecurringTransactionItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetDashboardUpcomingRecurringTransactionItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RecurringTransactionFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringRemainingDue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeLiabilities' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringTransactionItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeLiabilities' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creditReportLiabilityAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPast' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetDashboardUpcomingRecurringTransactionItemsQuery,
  Web_GetDashboardUpcomingRecurringTransactionItemsQueryVariables
>;
export const GetTransactionsListDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTransactionsListDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSelectableCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TransactionsListFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactionRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionOverviewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionsListFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionOverviewFields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTransactionsListDashboardQuery,
  GetTransactionsListDashboardQueryVariables
>;
export const Web_RequestEmailReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_RequestEmailReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'month' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestEmailReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'month' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'month' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_RequestEmailReportMutation,
  Web_RequestEmailReportMutationVariables
>;
export const Web_DisconnectCredentialMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_DisconnectCredentialMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disconnectCredential' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_DisconnectCredentialMutation,
  Web_DisconnectCredentialMutationVariables
>;
export const Web_ResetHouseholdRecurringMerchantSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_ResetHouseholdRecurringMerchantSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deleteStreams' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetRecurringMerchantSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deleteStreams' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deleteStreams' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_ResetHouseholdRecurringMerchantSearchMutation,
  Web_ResetHouseholdRecurringMerchantSearchMutationVariables
>;
export const Web_DeleteAllHouseholdTransactionTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_DeleteAllHouseholdTransactionTags' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAllHouseholdTransactionTags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_DeleteAllHouseholdTransactionTagsMutation,
  Web_DeleteAllHouseholdTransactionTagsMutationVariables
>;
export const Web_ResetAdviceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_ResetAdviceMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetAdvice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_ResetAdviceMutation, Web_ResetAdviceMutationVariables>;
export const Web_GetGlobalSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetGlobalSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTerm' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchTerm' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetGlobalSearchQuery, Web_GetGlobalSearchQueryVariables>;
export const Web_GoalSelectTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GoalSelectTransaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goalAllocations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goal' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageStorageProvider' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageStorageProviderId' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GoalSelectTransactionQuery,
  Web_GoalSelectTransactionQueryVariables
>;
export const Web_GoalSelectGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GoalSelectGoals' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GoalSelectGoalsQuery, Web_GoalSelectGoalsQueryVariables>;
export const Web_GoalsAllocateBalanceModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GoalsAllocateBalanceModal' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountsWithUnallocatedBalancesForGoals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableBalanceForGoals' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goalAllocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'availableBalanceForGoals' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'goal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'GoalAccountsMapRowFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAccountsMapRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtype' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GoalsAllocateBalanceModalQuery,
  Web_GoalsAllocateBalanceModalQueryVariables
>;
export const Web_GoalsMapAccountsModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GoalsMapAccountsModal' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalAccountsMapRowFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAccountsMapRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtype' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GoalsMapAccountsModalQuery,
  Web_GoalsMapAccountsModalQueryVariables
>;
export const Web_MonthlyBudgetGoalsModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_MonthlyBudgetGoalsModal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objective' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Web_GoalInfoRowFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'savings' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Web_GoalInfoRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_MonthlyBudgetGoalsModalQuery,
  Web_MonthlyBudgetGoalsModalQueryVariables
>;
export const Web_PreTaxBudgetGoalsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_PreTaxBudgetGoalsList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plannedMonthlyPretaxContribution' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'objective' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Web_GoalInfoRowFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Web_GoalInfoRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_PreTaxBudgetGoalsListQuery,
  Web_PreTaxBudgetGoalsListQueryVariables
>;
export const Web_RankGoalsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_RankGoalsList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GoalRankCardFields' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalCustomizeModalFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRankCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalCustomizeModalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_RankGoalsListQuery, Web_RankGoalsListQueryVariables>;
export const Web_UpdateGoalsPrioritiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateGoalsPriorities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateGoalPrioritiesInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGoalPriorities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'goals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_UpdateGoalsPrioritiesMutation,
  Web_UpdateGoalsPrioritiesMutationVariables
>;
export const Web_TargetAmountsGoalsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_TargetAmountsGoalsList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Web_GoalInfoRowFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Web_GoalInfoRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_TargetAmountsGoalsListQuery,
  Web_TargetAmountsGoalsListQueryVariables
>;
export const Web_GetHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PortfolioInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregateHoldings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'basis' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalValue' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'securityPriceChangeDollars' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'securityPriceChangePercent' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastSyncedAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'holdings' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'typeDisplay' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'closingPrice' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isManual' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'closingPriceUpdatedAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'costBasis' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'quantity' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'security' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'typeDisplay' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentPrice' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentPriceUpdatedAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'closingPrice' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'closingPriceUpdatedAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'oneDayChangePercent' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'oneDayChangeDollars' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetHoldingsQuery, Web_GetHoldingsQueryVariables>;
export const Web_GetHoldingDrawerHistoricalPerformanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetHoldingDrawerHistoricalPerformance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SecurityHistoricalPerformanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'securityHistoricalPerformance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'security' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closingPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPriceUpdatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closingPriceUpdatedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'historicalChart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnPercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetHoldingDrawerHistoricalPerformanceQuery,
  Web_GetHoldingDrawerHistoricalPerformanceQueryVariables
>;
export const Web_GetInvestmentsHoldingDrawerHistoricalPerformanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetInvestmentsHoldingDrawerHistoricalPerformance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SecurityHistoricalPerformanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'securityHistoricalPerformance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'security' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'historicalChart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnPercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetInvestmentsHoldingDrawerHistoricalPerformanceQuery,
  Web_GetInvestmentsHoldingDrawerHistoricalPerformanceQueryVariables
>;
export const Web_AdvisorPortalGetSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_AdvisorPortalGetSubscription' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_AdvisorPortalGetSubscriptionQuery,
  Web_AdvisorPortalGetSubscriptionQueryVariables
>;
export const Web_GetUserOptedInDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetUserOptedIn' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'householdPreferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aiAssistantEnabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aiAssistantOptedInAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dismissedSpendingInsightsBanner' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetUserOptedInQuery, Web_GetUserOptedInQueryVariables>;
export const UpdateDismissedSpendingInsightsBannerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDismissedSpendingInsightsBanner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dismissedSpendingInsightsBanner' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dismissedSpendingInsightsBanner' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dismissedSpendingInsightsBanner' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dismissedSpendingInsightsBanner' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDismissedSpendingInsightsBannerMutation,
  UpdateDismissedSpendingInsightsBannerMutationVariables
>;
export const Web_GetMerchantSelectTransactionDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetMerchantSelectTransactionDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'merchant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetMerchantSelectTransactionDetailsQuery,
  Web_GetMerchantSelectTransactionDetailsQueryVariables
>;
export const Web_GetMerchantSelectHouseholdMerchantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetMerchantSelectHouseholdMerchants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MerchantOrdering' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeMerchantsWithoutTransactions' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetMerchantSelectHouseholdMerchantsQuery,
  Web_GetMerchantSelectHouseholdMerchantsQueryVariables
>;
export const Web_GetMerchantSelectRecommendedMerchantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetMerchantSelectRecommendedMerchants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendedMerchants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetMerchantSelectRecommendedMerchantsQuery,
  Web_GetMerchantSelectRecommendedMerchantsQueryVariables
>;
export const Web_SearchMerchantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_SearchMerchants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_SearchMerchantsQuery, Web_SearchMerchantsQueryVariables>;
export const Web_OnboardingTopInstitutionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_OnboardingTopInstitutions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credentials' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topInstitutionGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataProviders' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institutions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InstitutionRowFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'largeLogo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarnBeforeConnecting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_OnboardingTopInstitutionsQuery,
  Web_OnboardingTopInstitutionsQueryVariables
>;
export const Web_GetUserReportedOnboardingAttributionChannelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetUserReportedOnboardingAttributionChannels' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReportedOnboardingAttributionChannels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetUserReportedOnboardingAttributionChannelsQuery,
  Web_GetUserReportedOnboardingAttributionChannelsQueryVariables
>;
export const Web_SetUserReportedAttributionChannelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_SetUserReportedAttributionChannels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'priorities' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userReportedAttributionChannels' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'priorities' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userReportedAttributionChannels' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_SetUserReportedAttributionChannelsMutation,
  Web_SetUserReportedAttributionChannelsMutationVariables
>;
export const Web_RecalculateBudgetMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_RecalculateBudgetMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResetBudgetMutationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetBudget' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_RecalculateBudgetMutation, Web_RecalculateBudgetMutationVariables>;
export const Web_ClearAllMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_ClearAllMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ClearBudgetMutationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clearBudget' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_ClearAllMutation, Web_ClearAllMutationVariables>;
export const Web_GetFlexibleGroupRolloverSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetFlexibleGroupRolloverSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'budgetSystem' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flexExpenseRolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetFlexibleGroupRolloverSettingsQuery,
  Web_GetFlexibleGroupRolloverSettingsQueryVariables
>;
export const Web_UpdateFlexibleGroupRolloverSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateFlexibleGroupRolloverSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBudgetSettingsMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBudgetSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'budgetRolloverPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_UpdateFlexibleGroupRolloverSettingsMutation,
  Web_UpdateFlexibleGroupRolloverSettingsMutationVariables
>;
export const Web_ReviewStreamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_ReviewStream' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReviewRecurringStreamInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewRecurringStream' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_ReviewStreamMutation, Web_ReviewStreamMutationVariables>;
export const Web_GetEventPopoverTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetEventPopoverTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'merchants' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '3' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetEventPopoverTransactionsQuery,
  Web_GetEventPopoverTransactionsQueryVariables
>;
export const AccountDetails_GetAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountDetails_getAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EditAccountFormFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLiability' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'credential' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasSyncOrRecentRefreshRequest' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'canBeForceRefreshed' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'InstitutionStatusFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InstitutionStatusFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'transactions' },
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '20' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TransactionsListFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'snapshots' },
            name: { kind: 'Name', value: 'snapshotsForAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedBalance' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionOverviewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInGoalBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditAccountFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasCustomizedLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invertSyncedBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canInvertBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useAvailableBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canUseAvailableBalance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditReportLiabilityAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastStatement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dayOfTheMonth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultPaymentAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultPaymentCategory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionsListFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionOverviewFields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountDetails_GetAccountQuery,
  AccountDetails_GetAccountQueryVariables
>;
export const Web_GetAccountsPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetAccountsPage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hasAccounts' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountTypeSummaries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AccountsListFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalDisplayBalance' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'householdPreferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountGroupOrder' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountMaskFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionStatusTooltipFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountListItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountMaskFields' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstitutionStatusTooltipFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountsListFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountListItemFields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetAccountsPageQuery, Web_GetAccountsPageQueryVariables>;
export const Web_GetAccountsPageRecentBalanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetAccountsPageRecentBalance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recentBalances' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetAccountsPageRecentBalanceQuery,
  Web_GetAccountsPageRecentBalanceQueryVariables
>;
export const Web_GetAggregateSnapshotsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetAggregateSnapshots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AggregateSnapshotFilters' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aggregateSnapshots' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'assetsBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'liabilitiesBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetAggregateSnapshotsQuery,
  Web_GetAggregateSnapshotsQueryVariables
>;
export const Web_GetMessageThreadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetMessageThreads' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageThreads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessageThreadListItemFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'householdPreferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aiAssistantEnabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aiAssistantOptedInAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageThreadListItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastMessageSentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOutstandingAssistantRequests' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetMessageThreadsQuery, Web_GetMessageThreadsQueryVariables>;
export const Web_GetMessageThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetMessageThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'threadId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'threadId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasOutstandingAssistantRequests' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MessageListGroupFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssistantMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedPrompts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssistantStatusMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantStatusMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserMessageListGroupFields' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'AssistantMessageListGroupFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'AssistantStatusMessageListGroupFields' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetMessageThreadQuery, Web_GetMessageThreadQueryVariables>;
export const Web_OnMessageUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'Web_OnMessageUpdated' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onMessageUpdated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MessageListGroupFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssistantMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedPrompts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssistantStatusMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantStatusMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserMessageListGroupFields' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'AssistantMessageListGroupFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'AssistantStatusMessageListGroupFields' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_OnMessageUpdatedSubscription,
  Web_OnMessageUpdatedSubscriptionVariables
>;
export const Web_OnMessageCreatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'Web_OnMessageCreated' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onMessageCreated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MessageListGroupFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssistantMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedPrompts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'debugInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssistantStatusMessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssistantStatusMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageListGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserMessageListGroupFields' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'AssistantMessageListGroupFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'AssistantStatusMessageListGroupFields' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_OnMessageCreatedSubscription,
  Web_OnMessageCreatedSubscriptionVariables
>;
export const Web_OnMessageThreadUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'Web_OnMessageThreadUpdated' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onMessageThreadUpdated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messageThread' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MessageThreadListItemFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageThreadListItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageThread' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastMessageSentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOutstandingAssistantRequests' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_OnMessageThreadUpdatedSubscription,
  Web_OnMessageThreadUpdatedSubscriptionVariables
>;
export const Web_CashFlowAggregatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_CashFlowAggregates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byYear' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'year', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'year' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byMonth' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'month', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'month' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byQuarter' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'quarter', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'quarter' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_CashFlowAggregatesQuery, Web_CashFlowAggregatesQueryVariables>;
export const Web_GetCashFlowPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetCashFlowPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byCategory' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'category', block: false }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'group' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sum' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byCategoryGroup' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'categoryGroup', block: false }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categoryGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sum' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byMerchant' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'merchant', block: false }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'summary' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetCashFlowPageQuery, Web_GetCashFlowPageQueryVariables>;
export const Web_CashFlowSankeyAggregatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_CashFlowSankeyAggregates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byYear' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'year', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'year' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byMonth' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'month', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'month' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byQuarter' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'quarter', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'quarter' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_CashFlowSankeyAggregatesQuery,
  Web_CashFlowSankeyAggregatesQueryVariables
>;
export const GetCashFlowSankeyPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCashFlowSankeyPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byCategory' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'category', block: false }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'group' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sum' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byCategoryGroup' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'categoryGroup', block: false }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categoryGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sum' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byMerchant' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'merchant', block: false }],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'summary' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCashFlowSankeyPageQuery, GetCashFlowSankeyPageQueryVariables>;
export const GetCategoryDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCategoryDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'month' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeBudgetAmounts' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rolloverPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'budgetAmountsForMonth' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'month' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'month' } },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'includeBudgetAmounts' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CategoryPlanSummaryFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryPlanSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthlyAmounts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCategoryDetailsQuery, GetCategoryDetailsQueryVariables>;
export const Web_GetCategoryGroupDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetCategoryGroupDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'month' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeBudgetAmounts' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rolloverPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'budgetAmountsForMonth' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'month' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'month' } },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'includeBudgetAmounts' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CategoryPlanSummaryFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryPlanSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetMonthlyAmounts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previousMonthRolloverAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rolloverType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetCategoryGroupDetailsQuery,
  Web_GetCategoryGroupDetailsQueryVariables
>;
export const Web_DirectLinkAccountSelectDataProviderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_DirectLinkAccountSelectDataProvider' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'institutionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'institutionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LinkInstitutionFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LinkInstitutionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarnBeforeConnecting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningTitle' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_DirectLinkAccountSelectDataProviderQuery,
  Web_DirectLinkAccountSelectDataProviderQueryVariables
>;
export const Web_GetFilteredCashFlowPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetFilteredCashFlowPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'categories' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroups' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accounts' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchants' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'summary' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categories' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'categories' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categoryGroups' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroups' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'merchants' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchants' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accounts' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accounts' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tags' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FilteredCashFlowSummaryFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byYear' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'year', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categories' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'categories' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categoryGroups' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroups' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'merchants' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchants' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accounts' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accounts' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tags' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'year' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sum' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byMonth' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'month', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categories' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'categories' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categoryGroups' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroups' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'merchants' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchants' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accounts' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accounts' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tags' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'month' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sum' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'byQuarter' },
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'StringValue', value: 'quarter', block: false }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categories' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'categories' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'categoryGroups' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroups' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'merchants' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchants' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accounts' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accounts' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tags' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'quarter' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sum' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FilteredCashFlowSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionsSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetFilteredCashFlowPageQuery,
  Web_GetFilteredCashFlowPageQueryVariables
>;
export const GetMerchantDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMerchantDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMerchantDetailsQuery, GetMerchantDetailsQueryVariables>;
export const Web_GetSidebarDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetSidebarData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cloudinaryPublicId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeSponsorship' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FreeTrialDurationPanelData' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stripePromoCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'promoCodeError' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCodeDescription' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'period' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricePerPeriodDollars' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreeTrialDurationPanelData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialDurationDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetSidebarDataQuery, Web_GetSidebarDataQueryVariables>;
export const Web_GetTransactionsPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetTransactionsPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TransactionsSummaryFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionsSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionsSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxExpense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetTransactionsPageQuery, Web_GetTransactionsPageQueryVariables>;
export const AdviceQuery_WebDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdviceQuery_Web' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'categoryName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'essentials' },
            name: { kind: 'Name', value: 'adviceItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: { kind: 'StringValue', value: 'essential', block: false },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'categoryName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdviceItemListFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'objectives' },
            name: { kind: 'Name', value: 'adviceItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: { kind: 'StringValue', value: 'objective', block: false },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'categoryName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdviceItemListFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adviceItemCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileQuestionnaire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'StringValue', value: 'general', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'state' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numQuestionsAnswered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstQuestion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'objectivesQuestionnaire' },
            name: { kind: 'Name', value: 'profileQuestionnaire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'StringValue', value: 'objectives', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstQuestion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItemCategory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceItemRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasksCompleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasksRemaining' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceItemListFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdviceItemRowFields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdviceQuery_WebQuery, AdviceQuery_WebQueryVariables>;
export const Web_AdviceQuestionnaireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_AdviceQuestionnaire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'questionnaireName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'questionName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileQuestionnaire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'questionnaireName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'state' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StateFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'questionName' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'QuestionFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionHeaderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'answer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dontKnow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answeredAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StateFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfileQuestionnaireState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'numQuestionsAnswered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numQuestions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'QuestionHeaderFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'QuestionFormFields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_AdviceQuestionnaireQuery, Web_AdviceQuestionnaireQueryVariables>;
export const Web_AnswerQuestionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_AnswerQuestionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AnswerProfileQuestionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'answerProfileQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextQuestion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'QuestionFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionnaireState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StateFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionHeaderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'answer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dontKnow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answeredAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'QuestionHeaderFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'QuestionFormFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StateFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfileQuestionnaireState' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'numQuestionsAnswered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numQuestions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_AnswerQuestionMutation, Web_AnswerQuestionMutationVariables>;
export const QuestionnaireIntroQuery_WebDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QuestionnaireIntroQuery_Web' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'questionnaireName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileQuestionnaire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'questionnaireName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextQuestion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'state' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numQuestionsAnswered' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  QuestionnaireIntroQuery_WebQuery,
  QuestionnaireIntroQuery_WebQueryVariables
>;
export const Web_GetAdvisorBillingPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetAdvisorBillingPage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionSponsorships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsorInvoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'receiptUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upcomingSponsorInvoice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creditBalance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'constants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'monthlyPriceDollars' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChargedForLifetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasStripeSubscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eligibleForTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'willCancelAtPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBillingIssue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePromoCode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsoredBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetAdvisorBillingPageQuery,
  Web_GetAdvisorBillingPageQueryVariables
>;
export const Web_UpdateOrCreateAdvisorStripePaymentMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateOrCreateAdvisorStripePaymentMethod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateOrCreateStripePaymentMethodMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrCreateStripePaymentMethod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SubscriptionFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChargedForLifetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasStripeSubscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eligibleForTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'willCancelAtPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBillingIssue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePromoCode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsoredBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_UpdateOrCreateAdvisorStripePaymentMethodMutation,
  Web_UpdateOrCreateAdvisorStripePaymentMethodMutationVariables
>;
export const Web_GetAdvisorClientsPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetAdvisorClientsPage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionSponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionSponsorshipInvites' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentToEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sponsorship' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'household' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'owner' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastActivityAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionSponsorAccessGrants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'householdGivingAccessName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userGivingAccessId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionSponsorshipDemoAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'userId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetAdvisorClientsPageQuery,
  Web_GetAdvisorClientsPageQueryVariables
>;
export const Web_GetAdvisorProfilePageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetAdvisorProfilePage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionSponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetAdvisorProfilePageQuery,
  Web_GetAdvisorProfilePageQueryVariables
>;
export const Web_UpdateSubscriptionSponsorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateSubscriptionSponsor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSponsorInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSubscriptionSponsor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sponsor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_UpdateSubscriptionSponsorMutation,
  Web_UpdateSubscriptionSponsorMutationVariables
>;
export const Web_BudgetOnboardingGetAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_BudgetOnboardingGetAccounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'visibleAccounts' },
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeHidden' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountTypeSummaries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalDisplayBalance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_BudgetOnboardingGetAccountsQuery,
  Web_BudgetOnboardingGetAccountsQueryVariables
>;
export const Web_UpdateCategoryFromBudgetOnboardingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateCategoryFromBudgetOnboarding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCategoryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CategoryFormFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFormFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Category' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemCategoryDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetVariability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBudget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupLevelBudgetingEnabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rolloverPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startingBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_UpdateCategoryFromBudgetOnboardingMutation,
  Web_UpdateCategoryFromBudgetOnboardingMutationVariables
>;
export const Web_GoalDetailV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GoalDetailV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'goalId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'goalId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WebGoalDetailsCardFields' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WebGoalBalancesCardFields' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WebGoalCardFields' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WebGoalContributionGraphFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountMaskFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalAccountBalanceRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalAccountAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMonthChange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountMaskFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalNameProgressBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalDetailsCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyPretaxContribution' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objective' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalBalancesCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WebGoalAccountBalanceRowFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WebGoalNameProgressBarFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalContributionGraphFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyContributionSummaries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sumCredit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sumDebit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plannedAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GoalDetailV2Query, Web_GoalDetailV2QueryVariables>;
export const Web_GoalsMapAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GoalsMapAccounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalAccountsMapRowFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalAccountsMapRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subtype' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GoalsMapAccountsQuery, Web_GoalsMapAccountsQueryVariables>;
export const Web_GoalsMonthlyBudgetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GoalsMonthlyBudgets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plannedMonthlyContribution' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objective' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Web_GoalInfoRowFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'savings' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Web_GoalInfoRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GoalsMonthlyBudgetsQuery, Web_GoalsMonthlyBudgetsQueryVariables>;
export const Web_GoalsV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GoalsV2' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalsV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WebGoalCardFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountsWithUnallocatedBalancesForGoals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Web_GoalsAllocateBannerFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalNameProgressBarFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startingAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completionPercent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebGoalCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GoalV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WebGoalNameProgressBarFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Web_GoalsAllocateBannerFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GoalsV2Query, Web_GoalsV2QueryVariables>;
export const Web_GetInvestmentsAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetInvestmentsAccounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountType' },
                      value: { kind: 'StringValue', value: 'brokerage', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeManual' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeHidden' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ignoreHiddenFromNetWorth' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTaxable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtype' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetInvestmentsAccountsQuery,
  Web_GetInvestmentsAccountsQueryVariables
>;
export const Web_GetAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'porfolioInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PortfolioInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'porfolioInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allocationSimple' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'typeDisplay' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'allocationPercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalValue' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalValue' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetAllocationQuery, Web_GetAllocationQueryVariables>;
export const Web_GetPortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetPortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PortfolioInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'portfolioInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalBasis' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalChangePercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalChangeDollars' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'oneDayChangePercent' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'historicalChart' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'returnPercent' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'benchmarks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'security' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'oneDayChangePercent' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'historicalChart' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'returnPercent' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregateHoldings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'basis' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalValue' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'securityPriceChangeDollars' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'securityPriceChangePercent' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastSyncedAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'holdings' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'typeDisplay' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'closingPrice' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'closingPriceUpdatedAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'quantity' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'account' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'mask' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'icon' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'logoUrl' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'institution' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'display' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtype' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'display' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'displayName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'currentBalance' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'security' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentPrice' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currentPriceUpdatedAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'closingPrice' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'closingPriceUpdatedAt' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'typeDisplay' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetPortfolioQuery, Web_GetPortfolioQueryVariables>;
export const Web_GetSecuritiesHistoricalPerformanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetSecuritiesHistoricalPerformance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SecurityHistoricalPerformanceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'securityHistoricalPerformance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'security' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'historicalChart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnPercent' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetSecuritiesHistoricalPerformanceQuery,
  Web_GetSecuritiesHistoricalPerformanceQueryVariables
>;
export const Web_GetUpcomingRecurringTransactionItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetUpcomingRecurringTransactionItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RecurringTransactionFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringTransactionItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeLiabilities' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isApproximate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creditReportLiabilityAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'liabilityType' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPast' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'markedPaidAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAmountDifferentThanOriginal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amountDiff' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditReportLiabilityStatementId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'liabilityStatement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remainingBalance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minimumPaymentAmount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentTransactions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'group' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetUpcomingRecurringTransactionItemsQuery,
  Web_GetUpcomingRecurringTransactionItemsQueryVariables
>;
export const Web_GetAllRecurringTransactionItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetAllRecurringTransactionItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RecurringTransactionFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringTransactionStreams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeLiabilities' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeLiabilities' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isApproximate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creditReportLiabilityAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'account' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextForecastedTransaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetAllRecurringTransactionItemsQuery,
  Web_GetAllRecurringTransactionItemsQueryVariables
>;
export const Web_UpdateOrCreateStripePaymentMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateOrCreateStripePaymentMethod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateOrCreateStripePaymentMethodMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrCreateStripePaymentMethod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SubscriptionFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SponsoredSubscriptionFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChargedForLifetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasStripeSubscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eligibleForTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'willCancelAtPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBillingIssue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePromoCode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsoredBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SponsoredSubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSponsorship' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sponsor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_UpdateOrCreateStripePaymentMethodMutation,
  Web_UpdateOrCreateStripePaymentMethodMutationVariables
>;
export const GetSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSubscription' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionFields' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SponsoredSubscriptionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'receiptUrl' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creditBalance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'constants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'monthlyPriceDollars' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChargedForLifetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasStripeSubscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eligibleForTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'willCancelAtPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBillingIssue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePromoCode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsoredBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SponsoredSubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSponsorship' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sponsor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSubscriptionQuery, GetSubscriptionQueryVariables>;
export const Web_BillingSettingsCancelSponsorshipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_BillingSettingsCancelSponsorship' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelSponsorshipInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelSubscriptionSponsorship' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canceled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_BillingSettingsCancelSponsorshipMutation,
  Web_BillingSettingsCancelSponsorshipMutationVariables
>;
export const Web_GetInstitutionSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetInstitutionSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credentials' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CredentialSettingsCardFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeDeleted' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtype' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'display' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'credential' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionLogoWithStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balanceStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsStatus' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionInfoFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'InstitutionLogoWithStatusFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CredentialSettingsCardFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credential' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstitutionInfoFields' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetInstitutionSettingsQuery,
  Web_GetInstitutionSettingsQueryVariables
>;
export const GetHouseHoldMemberSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHouseHoldMemberSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'householdInvites' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invitedEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRevoked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'householdRole' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myHousehold' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'householdRole' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasMfaOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'householdAccessGrants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHouseHoldMemberSettingsQuery,
  GetHouseHoldMemberSettingsQueryVariables
>;
export const Web_RevokeInviteToHouseholdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_RevokeInviteToHousehold' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RevokeInviteToHouseholdMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revokeInviteToHousehold' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invite' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRevoked' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_RevokeInviteToHouseholdMutation,
  Web_RevokeInviteToHouseholdMutationVariables
>;
export const Web_ResendInviteToHouseholdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_ResendInviteToHousehold' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResendInviteToHouseholdMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendInviteToHousehold' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invite' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_ResendInviteToHouseholdMutation,
  Web_ResendInviteToHouseholdMutationVariables
>;
export const Web_RevokeHouseholdAccessGrantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_RevokeHouseholdAccessGrant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RevokeHouseholdAccessGrantInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revokeHouseholdAccessGrant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'revoked' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_RevokeHouseholdAccessGrantMutation,
  Web_RevokeHouseholdAccessGrantMutationVariables
>;
export const Web_GetMerchantSettingsPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetMerchantSettingsPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MerchantOrdering' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'merchantCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetMerchantSettingsPageQuery,
  Web_GetMerchantSettingsPageQueryVariables
>;
export const Web_GetReferralSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetReferralSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referralStatistics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'creditsEarned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscribed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedUp' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetReferralSettingsQuery, Web_GetReferralSettingsQueryVariables>;
export const GetTransactionRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTransactionRules' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactionRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionRuleFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionRuleFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionRuleV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'merchantCriteriaUseOriginalStatement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchantCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isExpense' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'categoryIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setMerchantAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setCategoryAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTagsAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkGoalAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsReviewByUserAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'unassignNeedsReviewByUserAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendNotificationAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'setHideFromReportsAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatusAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recentApplicationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastAppliedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'splitTransactionsAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amountType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'splitsInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'goalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'needsReviewByUserId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTransactionRulesQuery, GetTransactionRulesQueryVariables>;
export const Web_UpdateRuleOrderMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateRuleOrderMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTransactionRuleOrderV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionRules' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TransactionRuleFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionRuleFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionRuleV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'merchantCriteriaUseOriginalStatement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchantCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amountCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'operator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isExpense' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'categoryIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setMerchantAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setCategoryAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTagsAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkGoalAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageStorageProviderId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsReviewByUserAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'unassignNeedsReviewByUserAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendNotificationAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'setHideFromReportsAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatusAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recentApplicationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastAppliedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'splitTransactionsAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amountType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'splitsInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'merchantName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'goalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'needsReviewByUserId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_UpdateRuleOrderMutation, Web_UpdateRuleOrderMutationVariables>;
export const Web_DeleteAllTransactionRulesMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_DeleteAllTransactionRulesMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAllTransactionRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deleted' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_DeleteAllTransactionRulesMutation,
  Web_DeleteAllTransactionRulesMutationVariables
>;
export const Web_GetSecuritySettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetSecuritySettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasMfaOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPassword' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalAuthProviders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingEmailUpdateVerification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeSupportAccountAccessGrant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetSecuritySettingsQuery, Web_GetSecuritySettingsQueryVariables>;
export const Web_ToggleSupportAccountAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_ToggleSupportAccountAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleSupportAccountAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isEnabled' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isEnabled' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_ToggleSupportAccountAccessMutation,
  Web_ToggleSupportAccountAccessMutationVariables
>;
export const OldestDeletableSyncedSnapshotDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OldestDeletableSyncedSnapshotDate' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'oldestDeletableSyncedSnapshotDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OldestDeletableSyncedSnapshotDateQuery,
  OldestDeletableSyncedSnapshotDateQueryVariables
>;
export const PreviewDeleteSyncedSnapshotsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PreviewDeleteSyncedSnapshots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'beforeDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewDeleteSyncedSnapshots' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'beforeDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'beforeDate' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PreviewDeleteSyncedSnapshotsQuery,
  PreviewDeleteSyncedSnapshotsQueryVariables
>;
export const Web_DeleteAccountLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_DeleteAccountLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAccountLogo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasCustomizedLogo' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_DeleteAccountLogoMutation, Web_DeleteAccountLogoMutationVariables>;
export const Web_DeleteMerchantLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'web_DeleteMerchantLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMerchantLogo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'merchantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'merchant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_DeleteMerchantLogoMutation,
  Web_DeleteMerchantLogoMutationVariables
>;
export const Web_DeleteUserProfilePictureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'web_DeleteUserProfilePicture' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserProfilePicture' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deleted' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_DeleteUserProfilePictureMutation,
  Web_DeleteUserProfilePictureMutationVariables
>;
export const Web_GetSubscriptionModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetSubscriptionModal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
                { kind: 'Field', name: { kind: 'Name', value: 'willCancelAtPeriodEnd' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasStripeSubscriptionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasChargedForLifetime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referralRedemption' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'campaign' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'analyticsFreemiumSummaryStatus' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stripePromoCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'promoCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'promoCodeError' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCodeDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCodeDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCodeDurationInMonths' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'period' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricePerPeriodDollars' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discountedPricePerPeriodDollars' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newTrialEndsAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requirePaymentMethod' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sponsoredBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetSubscriptionModalQuery, Web_GetSubscriptionModalQueryVariables>;
export const ApplyStripeCancelDiscountOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApplyStripeCancelDiscountOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApplyStripeCancelDiscountOfferInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyStripeCancelDiscountOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discountOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'discountPercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'durationInMonths' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SubscriptionFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChargedForLifetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasStripeSubscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eligibleForTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'willCancelAtPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBillingIssue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePromoCode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsoredBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplyStripeCancelDiscountOfferMutation,
  ApplyStripeCancelDiscountOfferMutationVariables
>;
export const GetStripeCancellationDiscountOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStripeCancellationDiscountOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancellationReason' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeCancellationDiscountOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'discountPercent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'durationInMonths' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalPricePerPeriodDollars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountedPricePerPeriodDollars' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetStripeCancellationDiscountOfferQuery,
  GetStripeCancellationDiscountOfferQueryVariables
>;
export const Web_UpdateRenewalReminderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateRenewalReminder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateRenewalReminderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRenewalReminder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'renewalReminderDays' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_UpdateRenewalReminderMutation,
  Web_UpdateRenewalReminderMutationVariables
>;
export const Web_DeleteUserFromHouseholdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_DeleteUserFromHousehold' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteUserFromHouseholdMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserFromHousehold' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_DeleteUserFromHouseholdMutation,
  Web_DeleteUserFromHouseholdMutationVariables
>;
export const GetAccountsBelongingToUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountsBelongingToUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountsBelongingTo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'credential' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAccountsBelongingToUserQuery,
  GetAccountsBelongingToUserQueryVariables
>;
export const CreateHouseholdAccessGrantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateHouseholdAccessGrant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateHouseholdAccessGrantInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHouseholdAccessGrant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accessGrant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'toEmail' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateHouseholdAccessGrantMutation,
  CreateHouseholdAccessGrantMutationVariables
>;
export const Web_InviteEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_InviteEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InviteUserToHouseholdMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteUserToHousehold' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invite' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invitedEmail' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_InviteEmailMutation, Web_InviteEmailMutationVariables>;
export const Web_GetEditMerchantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetEditMerchant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ruleCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canBeDeleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetEditMerchantQuery, Web_GetEditMerchantQueryVariables>;
export const Web_DeleteMerchantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_DeleteMerchant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'moveToId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMerchant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moveRelationsToMerchantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'moveToId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_DeleteMerchantMutation, Web_DeleteMerchantMutationVariables>;
export const Web_UpdateMerchantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateMerchant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMerchantInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMerchant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'merchant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurringTransactionStream' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'baseDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_UpdateMerchantMutation, Web_UpdateMerchantMutationVariables>;
export const Web_DisableMfaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_DisableMfa' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTotpDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_DisableMfaMutation, Web_DisableMfaMutationVariables>;
export const Web_CreateUnconfirmedTotpDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_createUnconfirmedTotpDevice' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUnconfirmedTotpDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'device' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'configUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'secret' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_CreateUnconfirmedTotpDeviceMutation,
  Web_CreateUnconfirmedTotpDeviceMutationVariables
>;
export const Web_ConfirmTotpDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_confirmTotpDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConfirmTOTPDeviceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmTotpDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isTokenValid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_ConfirmTotpDeviceMutation, Web_ConfirmTotpDeviceMutationVariables>;
export const Web_CreateRecoveryCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_createRecoveryCode' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRecoveryCodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'staticTokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_CreateRecoveryCodeMutation,
  Web_CreateRecoveryCodeMutationVariables
>;
export const Web_GetLinkedCredentialsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetLinkedCredentials' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credentials' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetLinkedCredentialsQuery, Web_GetLinkedCredentialsQueryVariables>;
export const GetMerchantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMerchants' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMerchantsQuery, GetMerchantsQueryVariables>;
export const TransactionExplainQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TransactionExplainQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'explainTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'explanation' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionExplainQuery, TransactionExplainQueryVariables>;
export const GetTransactionFiltersCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTransactionFiltersCard' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'importedFromMint' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTransactionFiltersCardQuery,
  GetTransactionFiltersCardQueryVariables
>;
export const Web_UpdateTransactionOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateTransactionOverview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTransactionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TransactionOverviewFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionOverviewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_UpdateTransactionOverviewMutation,
  Web_UpdateTransactionOverviewMutationVariables
>;
export const Web_GetTransactionsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetTransactionsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionOrdering' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSelectableCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TransactionOverviewFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactionRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionOverviewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetTransactionsListQuery, Web_GetTransactionsListQueryVariables>;
export const Web_GetTransactionsSummaryCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetTransactionsSummaryCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetTransactionsSummaryCardQuery,
  Web_GetTransactionsSummaryCardQueryVariables
>;
export const GetTransactionDrawerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTransactionDrawer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectPosted' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectPosted' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectPosted' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransactionDrawerFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myHousehold' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionDrawerSplitMessageFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OriginalTransactionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLinkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionOverviewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionDrawerFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSplitTransactions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'splitTransactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransactionDrawerSplitMessageFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalTransaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OriginalTransactionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sizeBytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalAssetUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountLinkFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsReviewByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionOverviewFields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTransactionDrawerQuery, GetTransactionDrawerQueryVariables>;
export const Web_TransactionDrawerUpdateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_TransactionDrawerUpdateTransaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTransactionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TransactionDrawerFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionDrawerSplitMessageFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OriginalTransactionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountLinkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionOverviewFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionDrawerFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Transaction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'needsReview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSplitTransactions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSplitTransaction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'splitTransactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TransactionDrawerSplitMessageFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalTransaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OriginalTransactionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sizeBytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalAssetUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountLinkFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringTransactionStream' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'needsReviewByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TransactionOverviewFields' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_TransactionDrawerUpdateTransactionMutation,
  Web_TransactionDrawerUpdateTransactionMutationVariables
>;
export const Web_TransactionDrawerDeleteAttachmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_TransactionDrawerDeleteAttachment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTransactionAttachment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deleted' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_TransactionDrawerDeleteAttachmentMutation,
  Web_TransactionDrawerDeleteAttachmentMutationVariables
>;
export const Web_SetTransactionTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_SetTransactionTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SetTransactionTagsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setTransactionTags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transaction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_SetTransactionTagsMutation,
  Web_SetTransactionTagsMutationVariables
>;
export const Web_UpdateAccountOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateAccountOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAccountOrderInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccountOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_UpdateAccountOrderMutation,
  Web_UpdateAccountOrderMutationVariables
>;
export const Web_MarkAdviceTaskCompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_MarkAdviceTaskComplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarkAdviceItemTaskCompleteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markAdviceItemTaskComplete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'adviceItemTask' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'adviceItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdviceItemRowFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItemCategory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceItemRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasksCompleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasksRemaining' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_MarkAdviceTaskCompleteMutation,
  Web_MarkAdviceTaskCompleteMutationVariables
>;
export const Web_MarkAdviceTaskIncompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_MarkAdviceTaskIncomplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarkAdviceItemTaskIncompleteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markAdviceItemTaskIncomplete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'adviceItemTask' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'adviceItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdviceItemRowFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItemCategory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdviceItemRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdviceItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdviceCategoryIconFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasksCompleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasksRemaining' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numTasks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_MarkAdviceTaskIncompleteMutation,
  Web_MarkAdviceTaskIncompleteMutationVariables
>;
export const Web_ChangeSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_ChangeSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ChangeStripeSubscriptionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeStripeSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SubscriptionFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChargedForLifetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasStripeSubscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eligibleForTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'willCancelAtPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBillingIssue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePromoCode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsoredBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_ChangeSubscriptionMutation,
  Web_ChangeSubscriptionMutationVariables
>;
export const Web_CreateSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_CreateSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateStripeSubscriptionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStripeSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SubscriptionFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChargedForLifetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasStripeSubscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eligibleForTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'willCancelAtPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBillingIssue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePromoCode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsoredBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_CreateSubscriptionMutation,
  Web_CreateSubscriptionMutationVariables
>;
export const Web_ReactivateSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_ReactivateSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReactivateStripeSubscriptionMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reactivateStripeSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SubscriptionFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HouseholdSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnFreeTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasChargedForLifetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasStripeSubscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPremiumEntitlement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eligibleForTrial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'willCancelAtPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBillingIssue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSponsor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePromoCode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsoredBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_ReactivateSubscriptionMutation,
  Web_ReactivateSubscriptionMutationVariables
>;
export const Web_GetCategoryForDeletionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetCategoryForDeletion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSystemCategory' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetCategoryForDeletionQuery,
  Web_GetCategoryForDeletionQueryVariables
>;
export const Web_DeleteCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_DeleteCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'moveToCategoryId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moveToCategoryId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'moveToCategoryId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_DeleteCategoryMutation, Web_DeleteCategoryMutationVariables>;
export const Web_GetTopInstitutionGroupByTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetTopInstitutionGroupByType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'topInstitutionGroupType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credentials' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topInstitutionGroupByType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataProviders' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'topInstitutionGroupType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'topInstitutionGroupType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountsConnectedCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institutions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InstitutionRowFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manualAccountShortcuts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountSubtype' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'largeLogo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarnBeforeConnecting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetTopInstitutionGroupByTypeQuery,
  Web_GetTopInstitutionGroupByTypeQueryVariables
>;
export const Web_SearchInstitutionsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_SearchInstitutionsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allInstitutions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataProviders' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataProviders' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InstitutionRowFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionRowFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Institution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'largeLogo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newConnectionsDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIssuesReportedMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarnBeforeConnecting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkFlowWarningTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstBackupDataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finicityInstitutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mxInstitutionId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_SearchInstitutionsQuery, Web_SearchInstitutionsQueryVariables>;
export const Web_UpdateCategoryGroupOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateCategoryGroupOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategoryGroupOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_UpdateCategoryGroupOrderMutation,
  Web_UpdateCategoryGroupOrderMutationVariables
>;
export const Web_UpdateCategoryOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_UpdateCategoryOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategoryOrderInCategoryGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categoryGroupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'categoryGroupId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_UpdateCategoryOrderMutation,
  Web_UpdateCategoryOrderMutationVariables
>;
export const Common_GetReportsDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Common_GetReportsData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportsGroupByEntity' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isTimeBasedReport' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportsSortBy' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeCategory' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeCategoryGroup' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeMerchant' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fillEmptyValues' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsTimeframe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isTimeBasedReport' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isTimeBasedReport' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fillEmptyValues' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportsCategoryFields' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeCategory' },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportsCategoryGroupFields' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeCategoryGroup' },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportsMerchantFields' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeMerchant' },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportsSummaryFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aggregates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fillEmptyValues' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fillEmptyValues' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportsSummaryFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsCategoryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportsGroupByData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsCategoryGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportsGroupByData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsMerchantFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportsGroupByData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionsSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sumIncome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sumExpense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'savingsRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_GetReportsDataQuery, Common_GetReportsDataQueryVariables>;
export const Web_GetDownloadTransactionsSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetDownloadTransactionsSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadTransactionsSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sessionKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetDownloadTransactionsSessionQuery,
  Web_GetDownloadTransactionsSessionQueryVariables
>;
export const Web_DownloadTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_DownloadTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransactionFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startDownloadTransactionsSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sessionKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_DownloadTransactionsMutation,
  Web_DownloadTransactionsMutationVariables
>;
export const Web_GetUploadBalanceHistorySessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetUploadBalanceHistorySession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadBalanceHistorySession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UploadBalanceHistorySessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UploadBalanceHistorySessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UploadBalanceHistorySession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sessionKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_GetUploadBalanceHistorySessionQuery,
  Web_GetUploadBalanceHistorySessionQueryVariables
>;
export const Web_ParseUploadBalanceHistorySessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_ParseUploadBalanceHistorySession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ParseBalanceHistoryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parseBalanceHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploadBalanceHistorySession' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UploadBalanceHistorySessionFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UploadBalanceHistorySessionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UploadBalanceHistorySession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sessionKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_ParseUploadBalanceHistorySessionMutation,
  Web_ParseUploadBalanceHistorySessionMutationVariables
>;
export const GetUploadStatementSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUploadStatementSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadStatementSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UploadStatementSessionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UploadStatementSessionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadStatementSession' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sessionKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skipCheckForDuplicates' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadedStatement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUploadStatementSessionQuery,
  GetUploadStatementSessionQueryVariables
>;
export const Web_ParseUploadStatementSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Web_ParseUploadStatementSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ParseStatementInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parseUploadStatementSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploadStatementSession' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UploadStatementSessionFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UploadStatementSessionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadStatementSession' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sessionKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skipCheckForDuplicates' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadedStatement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_ParseUploadStatementSessionMutation,
  Web_ParseUploadStatementSessionMutationVariables
>;
export const Web_GetFilteredAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_GetFilteredAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountFilters' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
                { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
                { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_GetFilteredAccountsQuery, Web_GetFilteredAccountsQueryVariables>;
export const CreateAccountsFromPlaidTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAccountsFromPlaidToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plaidInstitutionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plaidInstitutionName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkSessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAccountsFromPlaidToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'publicToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'plaidInstitutionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'plaidInstitutionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'plaidInstitutionName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'plaidInstitutionName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkSessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkSessionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PayloadErrorFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncDisabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAsset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayLastUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideFromList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideTransactionsFromReports' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeBalanceInNetWorth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeInGoalBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataProviderAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdingsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualInvestmentsTrackingMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtype' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credential' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updateRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromDataProviderAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataProvider' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plaidInstitutionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PayloadErrorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PayloadError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAccountsFromPlaidTokenMutation,
  CreateAccountsFromPlaidTokenMutationVariables
>;
export const Web_TransactionsFilterQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_TransactionsFilterQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'merchants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'householdTransactionTags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myHousehold' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Web_TransactionsFilterQuery, Web_TransactionsFilterQueryVariables>;
export const Web_MintTransactionsCountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Web_MintTransactionsCountQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allMintTransactions' },
            name: { kind: 'Name', value: 'allTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'importedFromMint' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Web_MintTransactionsCountQuery,
  Web_MintTransactionsCountQueryVariables
>;
