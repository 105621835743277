import React from 'react';
import type { Props as ConfettiProps } from 'react-confetti';
import ReactConfetti from 'react-confetti';
import { createPortal } from 'react-dom';

import useTheme from 'lib/hooks/useTheme';

type Props = ConfettiProps & {
  /** Portal to document.body. Useful for showing confetti in a modal */
  portal?: boolean;
};

const Confetti = ({ portal, ...props }: Props) => {
  const theme = useTheme();

  const confetti = (
    <ReactConfetti
      recycle={false}
      gravity={0.15}
      tweenDuration={10000}
      initialVelocityY={0}
      colors={[
        theme.color.navy,
        theme.color.red,
        theme.color.orange,
        theme.color.yellow,
        theme.color.green,
        theme.color.teal,
        theme.color.blue,
        theme.color.purple,
      ]}
      {...props}
    />
  );

  if (portal) {
    return createPortal(confetti, document.body);
  } else {
    return confetti;
  }
};

export default Confetti;
