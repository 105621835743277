import { gql, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import type {
  MarkGoalComplete,
  MarkGoalCompleteVariables,
} from 'common/generated/graphQlTypes/MarkGoalComplete';

const useMarkGoalComplete = () => {
  const [performMutation, mutationInfo] = useMutation<MarkGoalComplete, MarkGoalCompleteVariables>(
    MUTATION,
  );

  const markGoalComplete = useCallback(
    (id: string) =>
      performMutation({
        variables: {
          input: {
            id,
          },
        },
        optimisticResponse: {
          markGoalComplete: {
            __typename: 'MarkGoalComplete',
            goal: {
              __typename: 'GoalV2',
              id,
              completedAt: DateTime.local().toISO(),
            },
            errors: null,
          },
        },
      }),
    [performMutation],
  );

  return [markGoalComplete, mutationInfo] as const;
};

const MUTATION = gql`
  mutation Common_MarkGoalComplete($input: MarkGoalCompleteInput!) {
    markGoalComplete(input: $input) {
      goal {
        id
        completedAt
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default useMarkGoalComplete;
