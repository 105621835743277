import React from 'react';
import { FiCheck } from 'react-icons/fi';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

type Props = {
  institutionName: string;
  onClickContinue: () => void;
};

const HeaderText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin: ${({ theme }) => theme.spacing.default} 0px;
`;
const BodyText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.color.textLight};
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
  text-align: center;
`;

const CheckContainer = styled(Flex).attrs({ center: true })`
  background-color: ${({ theme }) => theme.color.green};
  border-radius: ${({ theme }) => theme.radius.round};
  width: 72px;
  height: 72px;
`;

const CheckIcon = styled(FiCheck)`
  width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.color.textWhite};
`;

const ButtonContainer = styled(Flex)`
  width: 100%;
`;

const Root = styled(Flex)`
  width: 100%;
`;

const AddAccountsInstitutionSynced = ({ institutionName, onClickContinue }: Props) => (
  <Root column alignCenter>
    <CheckContainer>
      <CheckIcon />
    </CheckContainer>
    <HeaderText>Account Synced</HeaderText>
    <BodyText>{`Your ${institutionName} account has successfully synced with Monarch`}</BodyText>
    <ButtonContainer justifyEnd>
      <PrimaryButton size="medium" onClick={onClickContinue}>
        Continue
      </PrimaryButton>
    </ButtonContainer>
  </Root>
);

export default AddAccountsInstitutionSynced;
