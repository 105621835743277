import { rgba } from 'polished';
import styled from 'styled-components';

import AbstractButton from 'components/lib/ui/button/AbstractButton';

import buttonIconMixin from 'lib/styles/buttonIconMixin';
import buttonShadowMixin from 'lib/styles/buttonShadowMixin';
import type { ButtonSize } from 'lib/styles/buttonSizeMixin';
import buttonSizeMixin from 'lib/styles/buttonSizeMixin';

import type { StyleMixin } from 'common/types/Styles';

export const primaryButtonMixin: StyleMixin<{ size?: ButtonSize }> = ({
  theme,
  size = 'small',
}) => `
  background-color: ${theme.color.orange};
  color: ${theme.color.textWhite};
  font-weight: ${theme.fontWeight.medium};

  :hover {
    background-color: ${theme.color.orangeDark};
    color: ${theme.color.textWhite};
  }

  :disabled {
    opacity: 1;
    background: ${rgba(theme.color.orange, 0.5)};
  }

  ${buttonShadowMixin({ theme })};
  ${buttonIconMixin({ theme, size })};
  ${buttonSizeMixin({ theme, size })};
`;

const PrimaryButton = styled(AbstractButton)<{ size?: ButtonSize }>`
  ${primaryButtonMixin}
`;

export default PrimaryButton;
