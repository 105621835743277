import * as RA from 'ramda-adjunct';
import React from 'react';
import styled, { css } from 'styled-components';

const Root = styled.div<{ $borderTopLeftRadius?: string; $borderBottomLeftRadius?: string }>`
  padding: ${({ theme }) => theme.spacing.small};
  background: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.medium};

  ${({ $borderTopLeftRadius }) =>
    RA.isNotNil($borderTopLeftRadius) &&
    css`
      border-top-left-radius: ${$borderTopLeftRadius};
    `}
  ${({ $borderBottomLeftRadius }) =>
    RA.isNotNil($borderBottomLeftRadius) &&
    css`
      border-bottom-left-radius: ${$borderBottomLeftRadius};
    `}
`;

type Props = {
  children?: React.ReactNode;
  className?: string;
  borderTopLeftRadius?: string;
  borderBottomLeftRadius?: string;
};

/** Non-interactive chat bubble background. */
const MessageBubble = ({
  children,
  className,
  borderTopLeftRadius,
  borderBottomLeftRadius,
}: Props) => (
  <Root
    className={className}
    $borderTopLeftRadius={borderTopLeftRadius}
    $borderBottomLeftRadius={borderBottomLeftRadius}
  >
    {children}
  </Root>
);

export default MessageBubble;
