import * as R from 'ramda';
import React from 'react';
import Chart from 'react-google-charts';

import Card from 'components/lib/ui/Card';
import CardBody from 'components/lib/ui/CardBody';
import CardHeader from 'components/lib/ui/CardHeader';

import { formatCurrencyNoCents } from 'common/utils/Currency';
import type { BreakdownEntry } from 'lib/cashFlow/BreakdownAdapters';

export type Props = {
  expenses: BreakdownEntry[];
  incomes: BreakdownEntry[];
  title: string;
};

const formatEntry = (entry: BreakdownEntry, isExpense: boolean) =>
  formatNameAndAmount(entry.name, entry.value, isExpense);

const formatNameAndAmount = (name: string, amount: number, isExpense: boolean) =>
  `${name}: ${formatCurrencyNoCents((isExpense ? -1 : 1) * amount)}`;

const CashFlowSankey = ({ expenses, incomes, title }: Props) => {
  const totalExpense = -R.sum(R.map(R.prop('value'), expenses));
  const totalIncome = R.sum(R.map(R.prop('value'), incomes));
  const savings = totalIncome - totalExpense;

  const spendingLabel = `${formatNameAndAmount('Spending', totalExpense, false)}`;
  const cashLabel = `${formatNameAndAmount('Cash', totalIncome, false)}`;
  const savingsLabel = `${formatNameAndAmount('Savings', savings, false)}`;

  const sankeyExpenses = expenses.map((expense) => [
    spendingLabel,
    formatEntry(expense, true),
    -expense.value,
  ]);
  const sankeyIncomes = incomes.map((income) => [
    formatEntry(income, false),
    cashLabel,
    income.value,
  ]);

  const sankeyData = [
    ['From', 'To', 'Amount'],
    [cashLabel, spendingLabel, totalExpense],
    [cashLabel, savingsLabel, savings],
    ...sankeyExpenses,
    ...sankeyIncomes,
  ];

  return (
    <Card>
      <CardHeader title={title} />
      <CardBody>
        <Chart chartType="Sankey" data={sankeyData} height="600px" />
      </CardBody>
    </Card>
  );
};

export default CashFlowSankey;
