import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

const SPINNER_SIZE_PX = 32;

const Root = styled(FlexContainer).attrs({ center: true })`
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.default} 0;
`;

type Props = {
  hide: boolean;
};

const TransactionListFooter = ({ hide }: Props) =>
  !hide ? (
    <Root>
      <LoadingSpinner $size={SPINNER_SIZE_PX} />
    </Root>
  ) : null;

export default TransactionListFooter;
