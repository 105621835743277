import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';

import type { AddManualAccountFlowData } from 'components/accounts/AddManualAccountFlow';
import AddManualHoldingFlow from 'components/accounts/AddManualHoldingFlow';
import type { AddManualHoldingFlowOutput } from 'components/accounts/AddManualHoldingFlow';
import AddVehicleAccountFlow from 'components/accounts/AddVehicleAccountFlow';
import type { AddVehicleAccountFlowOutput } from 'components/accounts/AddVehicleAccountFlow';

import { isManualHoldingsAccount, isVehicleAccount } from 'common/lib/accounts/accountTypes';
import { throwIfHasMutationErrors } from 'common/lib/form/errors';
import { CREATE_MANUAL_INVESTMENTS_ACCOUNT } from 'common/lib/graphQl/accounts';
import { useFlowContext } from 'lib/contexts/FlowContext';
import { errorToast } from 'lib/ui/toast';

import { gql as newGql } from 'common/generated/gql';
import type {
  CreateManualInvestmentsAccount,
  CreateManualInvestmentsAccountVariables,
} from 'common/generated/graphQlTypes/CreateManualInvestmentsAccount';
import { ManualInvestmentsAccountTrackingMethod } from 'common/generated/graphQlTypes/globalTypes';

type Props = AddManualAccountFlowData & {
  goBack?: () => void;
};

// Modal that can be used as part of the account creation flow
// and that renders a different subflow depending on choices
// made in the previous step. It can be used, e.g. when creating
// a new manual holdings account, which cannot be created
// without any holdings.
const AddManualAccountTypeSpecificSubflow = ({
  name,
  type,
  subtype,
  investmentTrackingMode,
  goBack,
}: Props) => {
  const { skipToComplete } = useFlowContext();

  const [createManualInvestmentsAccount] = useMutation<
    CreateManualInvestmentsAccount,
    CreateManualInvestmentsAccountVariables
  >(CREATE_MANUAL_INVESTMENTS_ACCOUNT);

  const [createSyncedVehicleAccount] = useMutation(CREATE_SYNCED_VEHICLE_ACCOUNT);

  const handleAddManualHoldingSubflowCompleted = useCallback(
    async (values: AddManualHoldingFlowOutput) => {
      const { data } = await createManualInvestmentsAccount({
        variables: {
          input: {
            name: name!,
            subtype: subtype!,
            manualInvestmentsTrackingMethod: ManualInvestmentsAccountTrackingMethod.holdings,
            initialHoldings: [{ securityId: values.securityId!, quantity: values.quantity! }],
          },
        },
      });
      throwIfHasMutationErrors(data);
      const accountId = data?.createManualInvestmentsAccount?.account?.id;
      skipToComplete({ accountIds: accountId ? [accountId] : [] });
    },
    [skipToComplete],
  );

  const handleAddVehicleAccountSubflowCompleted = useCallback(
    async (values: AddVehicleAccountFlowOutput) => {
      const { data } = await createSyncedVehicleAccount({
        variables: {
          input: {
            vin: values.vin!,
            name: values.name!,
            subtype: values.subtype!,
          },
        },
      });
      if (data?.createSyncedVehicleAccount?.errors) {
        errorToast(data?.createSyncedVehicleAccount?.errors?.message);
      } else {
        const accountId = data?.createSyncedVehicleAccount?.account?.id;
        skipToComplete({ accountIds: accountId ? [accountId] : [] });
      }
    },
    [skipToComplete],
  );

  if (isManualHoldingsAccount(type!, investmentTrackingMode)) {
    return (
      <AddManualHoldingFlow
        initialProps={{
          accountType: type,
          accountSubtype: subtype,
          accountName: name,
          accountInvestmentTrackingMode: investmentTrackingMode,
          skipAccountSelection: true,
        }}
        onComplete={handleAddManualHoldingSubflowCompleted}
        onBack={goBack}
      />
    );
  } else if (isVehicleAccount(type!)) {
    return (
      <AddVehicleAccountFlow onComplete={handleAddVehicleAccountSubflowCompleted} onBack={goBack} />
    );
  }

  return null;
};

const CREATE_SYNCED_VEHICLE_ACCOUNT = newGql(/* GraphQL */ `
  mutation CreateSyncedVehicleAccount($input: CreateSyncedVehicleAccountInput!) {
    createSyncedVehicleAccount(input: $input) {
      account {
        id
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export default AddManualAccountTypeSpecificSubflow;
