import React from 'react';

import Select from 'components/lib/form/Select';

import type { Filters } from 'common/lib/hooks/accounts/useCachedAccountTypeOptions';
import useCachedAccountTypeOptions from 'common/lib/hooks/accounts/useCachedAccountTypeOptions';

import { isSingleValue } from 'types/select';

type CombinedTypeSubtype = {
  type: string;
  subtype: string;
};

type Props = {
  filters?: Filters;
  value?: CombinedTypeSubtype;
  onChange?: (value: CombinedTypeSubtype) => void;
  className?: string;
};

/**
 * Single Select component that handles values for both account type & subtype in one.
 */
const AccountTypeSubtypeSelect = ({ filters, value, onChange, className }: Props) => {
  const { groupedOptions, isLoadingInitialData } = useCachedAccountTypeOptions(filters);

  return (
    <Select
      className={className}
      isLoading={isLoadingInitialData}
      options={groupedOptions}
      // @ts-ignore Select type is wrong...
      value={value ? JSON.stringify(value) : undefined}
      onChange={(value) => isSingleValue(value) && onChange?.(JSON.parse(value.value))}
    />
  );
};

export default AccountTypeSubtypeSelect;
