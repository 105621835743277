import * as React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import type { MerchantOption } from 'components/transactions/MerchantSearchSelect';
import MerchantSearchSelect from 'components/transactions/MerchantSearchSelect';

import { makeRelationSentence } from 'common/lib/merchant/delete';

import type { GetMerchantsSearch_merchants } from 'common/generated/graphQlTypes/GetMerchantsSearch';

type Merchant = GetMerchantsSearch_merchants; // alias

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const Intro = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  merchantId: string;
  transactionCount: number;
  ruleCount: number;
  isLoading: boolean;
  onConfirm: (moveToId: string) => void;
  onCancel: () => void;
};

const merchantOptionsFormatter = (merchants: Merchant[], activeMerchantId: string) =>
  merchants
    .filter(({ id }) => id !== activeMerchantId)
    .map(({ id, name, transactionCount }) => ({
      label: name,
      value: id,
      transactionCount,
    }));

const merchantValueFormatter = (value: MerchantOption) => value;

const DeleteMerchantMoveModal = ({
  merchantId,
  transactionCount,
  ruleCount,
  isLoading,
  onConfirm,
  onCancel,
}: Props) => (
  <ModalCard title="Delete merchant">
    <Form onSubmit={({ merchantId }: { merchantId: string }) => onConfirm(merchantId)}>
      <Content>
        <Intro>
          <Text weight="medium">
            There {makeRelationSentence(transactionCount, ruleCount)} still tied to this merchant
          </Text>
          , select a new merchant to update these relations to before deleting.
        </Intro>
        <SelectField
          name="merchantId"
          label="Update relations to merchant"
          placeholder="Select a merchant..."
          InputComponent={MerchantSearchSelect}
          formatOptions={(merchants: Merchant[]) => merchantOptionsFormatter(merchants, merchantId)}
          formatValue={merchantValueFormatter}
          isCreatable={false}
          defaultOptions
          required
        />
      </Content>
      <CardFooter>
        <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
        <FormSubmitButton pending={isLoading} size="small">
          Delete merchant
        </FormSubmitButton>
      </CardFooter>
    </Form>
  </ModalCard>
);

export default DeleteMerchantMoveModal;
