import useFeatureFlag from 'lib/hooks/useFeatureFlag';

type BlockAppWithMessageInfo = {
  title: string;
  description: string;
};

const DEFAULT_TITLE = 'We are undergoing some maintenance';
const DEFAULT_DESCRIPTION = 'Monarch should be back in the next hour.';

const useBlockAppWithMessage = () => {
  const { treatment, config } = useFeatureFlag<BlockAppWithMessageInfo>('block-app-with-message');
  const isBlocked = treatment === 'on';
  const info = {
    title: config?.title ?? DEFAULT_TITLE,
    description: config?.description ?? DEFAULT_DESCRIPTION,
  };
  return [isBlocked, info] as const;
};

export default useBlockAppWithMessage;
