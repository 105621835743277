import React, { useMemo } from 'react';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';

import useTransactionTags from 'common/lib/hooks/transactions/useTransactionTags';

const Root = styled.div`
  text-align: right;
`;

const Label = styled(Text)<{ $isRemoved: boolean }>`
  &:not(:last-child):after {
    content: ', ';
    text-decoration: none;
  }
`;

type Props = {
  tagIds: string[];
  removedIds: string[];
};

const BulkUpdateTagLabels = ({ tagIds, removedIds }: Props) => {
  const { getTags } = useTransactionTags();

  const data = useMemo(() => {
    const tags = getTags(tagIds);
    return tags.map(({ name, id }) => ({
      name,
      isRemoved: removedIds.includes(id),
    }));
  }, [tagIds, removedIds, getTags]);

  return (
    <Root>
      {data.map(({ name, isRemoved }) => (
        <Label key={name} $isRemoved={isRemoved}>
          {isRemoved ? <s>{name}</s> : name}
        </Label>
      ))}
    </Root>
  );
};

export default BulkUpdateTagLabels;
