import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useState, useEffect } from 'react';

const useFingerprint = () => {
  const [fingerprint, setFingerprint] = useState<string>();
  useEffect(() => {
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        const { visitorId } = result;
        setFingerprint(visitorId);
      });
  }, [setFingerprint]);

  return fingerprint;
};

export default useFingerprint;
