import React from 'react';
import styled from 'styled-components';

import ChartTooltip from 'components/lib/charts/ChartTooltip';
import ChartTooltipDataRow from 'components/lib/charts/ChartTooltipDataRow';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';

import { formatPercent } from 'common/utils/Number';
import useTheme from 'lib/hooks/useTheme';

type Props = {
  title: string;
  income: number;
  expenses: number;
  savings: number;
  savingsRate: number;
  active: boolean;
};

const Header = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  text-align: left;
`;

const CashFlowBarChartTooltip = ({
  income,
  expenses,
  savings,
  title,
  savingsRate,
  active,
}: Props) => {
  const theme = useTheme();
  const rate = Math.max(0, savingsRate);

  return (
    <ChartTooltip active={active} header={<Header>{title}</Header>}>
      <ChartTooltipDataRow
        label="Income"
        value={<CashFlowCurrency value={income} type="income" round />}
        dotFillColor={theme.color.green}
      />
      <ChartTooltipDataRow
        label="Expenses"
        value={<CashFlowCurrency value={expenses} type="expense" round />}
        dotFillColor={theme.color.red}
      />
      <ChartTooltipDataRow
        label="Savings"
        value={<CashFlowCurrency value={savings} type="income" round />}
        strokeColor={theme.color.gray}
      />
      <ChartTooltipDataRow
        label="Savings Rate"
        value={formatPercent(rate)}
        strokeColor={theme.color.gray}
      />
    </ChartTooltip>
  );
};

export default CashFlowBarChartTooltip;
