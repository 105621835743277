export const MOCK_SPINWHEEL_CREDIT_REPORT = {
  status: {
    code: 200,
    desc: 'success',
  },
  data: {
    userId: 'fb16f6f7-8268-4d78-8ee9-9be93bc9b2a1',
    extUserId: '443115a2-c289-4153-aa0b-e2a5312df76d',
    isBorrower: false,
    isChampion: false,
    updatedOn: 1678724487194,
    createdOn: 1678724465287,
    studentLoanAccounts: [],
    profile: {
      firstName: 'CHRISTY',
      middleName: 'L',
      lastName: 'JENOVAL',
      phoneNumber: '+19198094325',
      ssnLastFourDigits: '1234',
      addresses: [
        {
          addressLine1: '21 Pacific St',
          city: 'Pittsfield',
          state: 'MA',
          zip: '01201-3918',
        },
      ],
      dob: '1967-06-08',
      creditScore: 691,
    },
    autoLoanSummary: {
      noOfLoans: 4,
      currentOutstandingBalance: 11123,
      minimumPaymentAmount: 501,
      updatedOn: 1678724486968,
    },
    homeLoanSummary: {
      noOfLoans: 3,
      currentOutstandingBalance: 7469,
      minimumPaymentAmount: 189,
      updatedOn: 1678724487040,
    },
    creditCardSummary: {
      noOfCreditCards: 1,
      currentOutstandingBalance: 100,
      availableCredit: 500,
      updatedOn: 1678724487080,
      creditUtilization: 16.67,
    },
    studentLoanSummary: {
      noOfLoans: 2,
      currentOutstandingBalance: 6268,
      minimumPaymentAmount: 229,
      updatedOn: 1678724487132,
    },
    personalLoanSummary: {
      noOfLoans: 1,
      currentOutstandingBalance: 2327,
      minimumPaymentAmount: 98,
      updatedOn: 1678724487171,
    },
    miscellaneousLiabilitySummary: {
      noOfLoans: 1,
      currentOutstandingBalance: 0,
      minimumPaymentAmount: 0,
      updatedOn: 1678724486907,
    },
    creditReports: [
      {
        id: '54b1918e-e232-4ef0-9ae3-b85d4d29ff3e',
        profile: {
          firstName: 'CHRISTY',
          middleName: 'L',
          lastName: 'JENOVAL',
          creditScore: 691,
          dateOfBirth: '1967-06-08',
          ssn: '6112',
          addresses: [
            {
              addressLine1: '305 LINDEN AVE',
              city: 'ATLANTA',
              state: 'GA',
              zip: '30316',
              residencyType: 'CURRENT',
              residencyDurationInMonths: 2,
              reportedDate: '2022-05-04',
            },
            {
              addressLine1: '712 MCDOUNGH RD',
              city: 'HAMPTON',
              state: 'GA',
              zip: '30228',
              residencyType: 'PREVIOUS',
              residencyDurationInMonths: 2,
              reportedDate: '2022-03-02',
            },
            {
              addressLine1: 'PO BOX 30',
              city: 'HELEN',
              state: 'GA',
              zip: '30545',
              residencyType: 'PREVIOUS',
              residencyDurationInMonths: 2,
              reportedDate: '2022-03-27',
            },
            {
              addressLine1: '1260 NELMS RD',
              city: 'CARNESVILLE',
              state: 'GA',
              zip: '30521',
              residencyType: 'PREVIOUS',
              residencyDurationInMonths: 2,
              reportedDate: '2022-03-27',
            },
            {
              addressLine1: '2732 SENECA ST 1FLRR',
              city: 'MARIETTA',
              state: 'GA',
              zip: '30062',
              residencyType: 'PREVIOUS',
              residencyDurationInMonths: 1,
            },
          ],
          employmentHistory: [
            {
              employerName: 'Acme Inc',
              startDate: '2022-05-01',
              startDateWithFormat: {
                value: '2022-05',
                format: 'YYYY-MM',
              },
              reportedDate: '2022-05-01',
              reportedDateWithFormat: {
                value: '2022-05',
                format: 'YYYY-MM',
              },
              employmentStatus: 'CURRENT',
            },
            {
              position: 'Software Engineer',
              startDate: '2020-05-01',
              employmentStatus: 'PREVIOUS',
            },
          ],
          updatedOn: 1678724487191,
          createdOn: 1678724480988,
        },
        inquiries: [
          {
            inquirerName: 'MBGA',
            phoneNumber: '1234567890',
            inquiryDate: '2020-08-13',
            inquirerIndustryCode: 'H',
            purposeType: 'HARD',
            sourceBureau: 'Equifax',
            bureauSubscriberCode: '404HA00354',
            address: {
              addressLine1: 'BR. C GIM-REGION TP',
              city: 'ROSWELL',
              state: 'GA',
              zip: '30076',
            },
          },
        ],
        creditScoreDetails: [
          {
            reportedDate: '2022-05-06',
            riskBasedPricingPercentage: 40,
            riskBasedPricingMin: 691,
            riskBasedPricingMax: 694,
            creditScore: 691,
            modelName: 'EquifaxVantageScore3.0',
            sourceBureau: 'Equifax',
            factors: [
              {
                description:
                  'Available credit on your open bankcard or revolving accounts is too low',
                code: '39',
              },
              {
                description: 'Total of all balances on bankcard or revolving accounts is too high',
                code: '34',
              },
              {
                description: 'You have too many delinquent or derogatory accounts',
                code: '7',
              },
              {
                description: 'You have too many inquiries on your credit report.',
                code: '85',
              },
            ],
          },
        ],
        creditAttributes: [
          {
            description: 'Number of tradelines',
            value: '24',
          },
          {
            description: 'Average age of open tradelines',
            value: '81',
          },
          {
            description: 'Number of hard inquiries',
            value: '63',
          },
          {
            description: 'Number of payments',
            value: '5',
          },
        ],
        updatedOn: 1678724487204,
        createdOn: 1678724480988,
        connectionStatus: {
          statusCode: 2000,
          description: 'Authentication is successful.',
          lastAuthAttemptOn: 1678724481033,
          isAuthSuccessful: true,
          isValid: true,
          isSecurityStepPending: false,
          statusChangeLogs: [],
          lastSuccessfulAuthOn: 1678724481033,
          dataStatus: {
            status: 'COMPLETED',
            updatedOn: 1678724487181,
          },
        },
      },
    ],
    homeLoans: [
      {
        homeLoanId: 'd7004135-3e8f-4e85-8c10-391ba89db622',
        displayName: 'ATLANTIC MORTGAGE',
        logoUrl: 'https://spinwheel.io/logos/1226cd1b-51dd-4bd1-8dc8-098deb9e0c7b.png',
        liabilityProfile: {
          ecoaCode: 'J',
          accountOriginationDate: '1998-05-08',
          status: 'OPEN',
          interestRateDerived: 0.1122,
          loanOriginationAmount: 17500,
          accountRating: '1',
          liabilitySubtype: 'ConventionalRealEstateMortgage',
          pendingLoanTermInMonthsDerived: 83,
          loanTermInMonths: 93,
          accountOwnershipType: 'JOINT_CONTRACTUAL_LIABILITY',
          accountType: 'MORTGAGE',
          reportedDate: '2022-03-08',
          reportedDateWithFormat: {
            value: '2022-03-08',
            format: 'YYYY-MM-DD',
          },
          consumerDisputeStatus: 'NOT_DISPUTED',
          derogatoryDataStatus: 'NOT_DEROGATORY',
          reviewedInMonths: 10,
          collectionStatus: 'NOT_IN_COLLECTION',
          chargeOffStatus: 'NOT_CHARGED_OFF',
          accountTypeCode: 'I',
        },
        balanceDetails: {
          outstandingBalance: 7469,
        },
        statementSummary: {
          statementBalance: 7469,
          principalBalance: 7469,
          minimumPaymentAmount: 189,
          lastPaymentDate: '2022-02-01',
          lastPaymentDateWithFormat: {
            value: '2022-02',
            format: 'YYYY-MM',
          },
          statementDate: '2022-03-01',
          statementDateWithFormat: {
            value: '2022-03',
            format: 'YYYY-MM',
          },
          overduePeriod: 'NOT_OVERDUE',
        },
        creditor: {
          industryType: 'MortgageCompanies',
          industryCode: 'FM',
          phoneNumber: '9042961400',
          bureauSubscriberCode: '401BB00573',
          address: {
            addressLine1: '4348 SOUTHPOINT BLVD',
            city: 'JACKSONVILLE',
            state: 'FL',
            zip: '32216',
            zipExtension: '0986',
          },
        },
        capabilities: {
          payments: {
            billPayment: {
              availability: 'SUPPORTED',
            },
          },
        },
        updatedOn: 1678724486994,
        createdOn: 1678724486994,
      },
    ],
    autoLoans: [
      {
        autoLoanId: '75cf4a56-12b0-4fdf-8239-0cd150dc4eb2',
        displayName: 'BANK OF AMERICA-BARN',
        logoUrl: 'https://spinwheel.io/logos/1226cd1b-51dd-4bd1-8dc8-098deb9e0c7b.png',
        liabilityProfile: {
          ecoaCode: 'J',
          accountOriginationDate: '2020-03-08',
          status: 'OPEN',
          interestRateDerived: 0.1122,
          loanOriginationAmount: 11195,
          accountRating: '1',
          liabilitySubtype: 'AutoLease',
          pendingLoanTermInMonthsDerived: 23,
          loanTermInMonths: 48,
          accountOwnershipType: 'JOINT_CONTRACTUAL_LIABILITY',
          accountType: 'INSTALLMENT',
          reportedDate: '2022-03-08',
          reportedDateWithFormat: {
            value: '2022-03-08',
            format: 'YYYY-MM-DD',
          },
          consumerDisputeStatus: 'NOT_DISPUTED',
          derogatoryDataStatus: 'NOT_DEROGATORY',
          reviewedInMonths: 25,
          collectionStatus: 'NOT_IN_COLLECTION',
          chargeOffStatus: 'NOT_CHARGED_OFF',
          accountTypeCode: 'I',
        },
        balanceDetails: {
          outstandingBalance: 7632,
        },
        statementSummary: {
          statementBalance: 7632,
          principalBalance: 7632,
          minimumPaymentAmount: 237,
          lastPaymentDate: '2022-03-01',
          lastPaymentDateWithFormat: {
            value: '2022-03',
            format: 'YYYY-MM',
          },
          statementDate: '2022-03-01',
          statementDateWithFormat: {
            value: '2022-03',
            format: 'YYYY-MM',
          },
          overduePeriod: 'NOT_OVERDUE',
        },
        creditor: {
          industryType: 'AllBanks',
          industryCode: 'BB',
          bureauSubscriberCode: '401BB00573',
          address: {
            addressLine1: 'PO BOX 2759',
            city: 'JACKSONVILLE',
            state: 'FL',
            zip: '32203',
            zipExtension: '2759',
          },
        },
        capabilities: {
          payments: {
            billPayment: {
              availability: 'SUPPORTED',
            },
          },
        },
        updatedOn: 1678724486921,
        createdOn: 1678724486921,
      },
    ],
    creditCards: [
      {
        creditCardId: 'e455bf2f-6a00-4313-a7de-922d1f530828',
        updatedOn: 1678724487063,
        createdOn: 1678724487063,
        displayName: 'Mastercard',
        cardProfile: {
          ecoaCode: 'I',
          accountOriginationDate: '2020-08-02T00:00:00.000Z',
          status: 'OPEN',
          creditLimit: 500,
          accountRating: '1',
          creditUtilization: 16.67,
          creditCardNumberMasked: '123456******9993',
          liabilitySubtype: 'CreditCard',
          closedDate: '2016-07-01T00:00:00.000Z',
          closedDateWithFormat: {
            value: '2016-07',
            format: 'YYYY-MM',
          },
          availableCreditDerived: 500,
          accountOwnershipType: 'INDIVIDUAL',
          reviewedInMonths: 25,
          reportedDate: '2023-02-14',
          reportedDateWithFormat: {
            value: '2023-02-14',
            format: 'YYYY-MM-DD',
          },
          accountType: 'REVOLVING',
          consumerDisputeStatus: 'NOT_DISPUTED',
          derogatoryDataStatus: 'NOT_DEROGATORY',
          collectionStatus: 'NOT_IN_COLLECTION',
          chargeOffStatus: 'NOT_CHARGED_OFF',
          accountTypeCode: 'R',
          limitType: 'REGULAR',
          paymentHistory: {
            lastAssessedStatementDate: '2023-01-14',
            details: [
              {
                date: '2023-01-14',
                description: 'The account is current',
              },
              {
                date: '2022-12-14',
                description: 'The account has been late for 1 billing cycle',
              },
              {
                date: '2022-11-14',
                description: 'The account is part of a Chapter 13 bankruptcy',
              },
              {
                date: '2022-10-14',
                description: 'The account is in collections',
              },
              {
                date: '2022-09-14',
                description: 'The account was voluntarily surrendered',
              },
              {
                date: '2022-08-14',
                description: 'No data available for this period',
              },
            ],
          },
        },
        balanceDetails: {
          outstandingBalance: 100,
          updatedOn: 1678724487063,
        },
        statementSummary: {
          minimumPaymentAmount: 0,
          statementBalance: 0,
          lastPaymentDate: '2022-03-01T00:00:00.000Z',
          lastPaymentDateWithFormat: {
            value: '2022-03',
            format: 'YYYY-MM',
          },
          statementDate: '2022-03-01',
          statementDateWithFormat: {
            value: '2022-03',
            format: 'YYYY-MM',
          },
          overduePeriod: 'NOT_OVERDUE',
          amountPastDue: 700,
          refreshedFields: {
            lastPaymentDate: {
              value: '2021-08-21',
              format: 'YYYY-MM-DD',
              updatedOn: 1643917894780,
            },
            dueDay: {
              value: '08',
              format: 'DD',
              updatedOn: 1643917894780,
            },
            lastPaymentAmount: {
              value: 400,
              updatedOn: 1643917894780,
            },
          },
        },
        logoUrl: 'https://spinwheel.io/logos/1226cd1b-51dd-4bd1-8dc8-098deb9e0c7b.png',
        creditor: {
          originalName: 'TC BANK - LTD.',
          industryType: 'SalesFinancing',
          industryCode: 'FF',
          phoneNumber: '8663968254',
          bureauSubscriberCode: '401BB00573',
          address: {
            addressLine1: 'C/O P.O. BOX 965036',
            city: 'ORLANDO',
            state: 'FL',
            zip: '32896',
            zipExtension: '5036',
          },
        },
        capabilities: {
          data: {
            realtimeBalance: {
              availability: 'SUPPORTED',
            },
          },
          payments: {
            billPayment: {
              availability: 'SUPPORTED',
            },
          },
        },
      },
    ],
    personalLoans: [
      {
        personalLoanId: '3969d448-b5ea-47fd-a126-f2677d6b6eb3',
        displayName: 'WACHOVIA BANK, NA/FT',
        logoUrl: 'https://spinwheel.io/logos/1226cd1b-51dd-4bd1-8dc8-098deb9e0c7b.png',
        liabilityProfile: {
          ecoaCode: 'I',
          accountOriginationDate: '2021-06-08',
          status: 'OPEN',
          interestRateDerived: 0.1122,
          loanOriginationAmount: 3093,
          accountRating: '1',
          liabilitySubtype: 'InstallmentLoan',
          pendingLoanTermInMonthsDerived: 23,
          loanTermInMonths: 32,
          accountOwnershipType: 'INDIVIDUAL',
          accountType: 'INSTALLMENT',
          reportedDate: '2022-03-08',
          reportedDateWithFormat: {
            value: '2022-03-08',
            format: 'YYYY-MM-DD',
          },
          consumerDisputeStatus: 'NOT_DISPUTED',
          derogatoryDataStatus: 'NOT_DEROGATORY',
          reviewedInMonths: 9,
          collectionStatus: 'NOT_IN_COLLECTION',
          chargeOffStatus: 'NOT_CHARGED_OFF',
          accountTypeCode: 'I',
        },
        balanceDetails: {
          outstandingBalance: 2327,
          updatedOn: 1678724487153,
        },
        statementSummary: {
          statementBalance: 2327,
          principalBalance: 2327,
          minimumPaymentAmount: 98,
          lastPaymentDate: '2022-03-01',
          lastPaymentDateWithFormat: {
            value: '2022-03',
            format: 'YYYY-MM',
          },
          statementDate: '2022-03-01',
          statementDateWithFormat: {
            value: '2022-03',
            format: 'YYYY-MM',
          },
          overduePeriod: 'NOT_OVERDUE',
        },
        creditor: {
          industryType: 'AllBanks',
          industryCode: 'BB',
          phoneNumber: '4048277100',
          bureauSubscriberCode: '401BB00573',
          address: {
            addressLine1: 'P.O. BOX 3117',
            city: 'WINSTON-SALEM',
            state: 'NC',
            zip: '27102',
          },
        },
        capabilities: {
          payments: {
            billPayment: {
              availability: 'SUPPORTED',
            },
          },
        },
        updatedOn: 1678724487153,
        createdOn: 1678724487153,
      },
    ],
    studentLoans: [
      {
        studentLoanId: '18cf0dcb-41ef-498b-b340-eef4f5e7fcb0',
        displayName: 'Wachovia Bank',
        logoUrl: 'https://spinwheel.io/logos/1226cd1b-51dd-4bd1-8dc8-098deb9e0c7b.png',
        liabilityProfile: {
          ecoaCode: 'I',
          accountOriginationDate: '2020-12-08',
          status: 'OPEN',
          interestRateDerived: 0.1122,
          loanOriginationAmount: 5174,
          accountRating: '1',
          liabilitySubtype: 'Educational',
          loanType: 'UNVERIFIED',
          pendingLoanTermInMonthsDerived: 40,
          loanTermInMonths: 40,
          accountOwnershipType: 'INDIVIDUAL',
          accountType: 'INSTALLMENT',
          reportedDate: '2022-03-08',
          reportedDateWithFormat: {
            value: '2022-03-08',
            format: 'YYYY-MM-DD',
          },
          consumerDisputeStatus: 'NOT_DISPUTED',
          derogatoryDataStatus: 'NOT_DEROGATORY',
          reviewedInMonths: 0,
          collectionStatus: 'NOT_IN_COLLECTION',
          chargeOffStatus: 'NOT_CHARGED_OFF',
          accountTypeCode: 'I',
        },
        balanceDetails: {
          outstandingBalance: 3941,
        },
        statementSummary: {
          statementBalance: 3941,
          principalBalance: 3941,
          minimumPaymentAmount: 131,
          lastPaymentDate: '2022-03-01',
          lastPaymentDateWithFormat: {
            value: '2022-03',
            format: 'YYYY-MM',
          },
          statementDate: '2022-03-01',
          statementDateWithFormat: {
            value: '2022-03',
            format: 'YYYY-MM',
          },
          overduePeriod: 'NOT_OVERDUE',
        },
        creditor: {
          industryType: 'AllBanks',
          industryCode: 'BB',
          phoneNumber: '4048277100',
          bureauSubscriberCode: '401BB00573',
          address: {
            addressLine1: 'P.O. BOX 3117',
            city: 'WINSTON-SALEM',
            state: 'NC',
            zip: '27102',
          },
        },
        capabilities: {
          payments: {
            billPayment: {
              availability: 'SUPPORTED',
            },
          },
        },
        updatedOn: 1678724487099,
        createdOn: 1678724487099,
      },
    ],
    miscellaneousLiabilities: [
      {
        miscellaneousLiabilityId: '02c3cec9-75f7-427d-a1d7-f9252f93cb40',
        displayName: 'HOME FURNISHING RENTALS',
        logoUrl: 'https://spinwheel.io/logos/1226cd1b-51dd-4bd1-8dc8-098deb9e0c7b.png',
        liabilityProfile: {
          ecoaCode: 'I',
          accountOriginationDate: '1998-08-01',
          status: 'OPEN',
          interestRateDerived: 0.1122,
          loanOriginationAmount: 0,
          accountRating: '1',
          liabilitySubtype: 'OtherDepartmentAndMailOrder',
          pendingLoanTermInMonthsDerived: 0,
          loanTermInMonths: 0,
          accountOwnershipType: 'INDIVIDUAL',
          accountType: 'REVOLVING',
          reportedDate: '2022-03-01',
          reportedDateWithFormat: {
            value: '2022-03-01',
            format: 'YYYY-MM-DD',
          },
          consumerDisputeStatus: 'NOT_DISPUTED',
          derogatoryDataStatus: 'DEROGATORY',
          reviewedInMonths: 10,
          collectionStatus: 'NOT_IN_COLLECTION',
          chargeOffStatus: 'NOT_CHARGED_OFF',
          accountTypeCode: 'R',
          firstDelinquencyDate: '2021-12-01',
          firstDelinquencyDateWithFormat: {
            value: '2021-12',
            format: 'YYYY-MM',
          },
          paymentHistory: {
            lastAssessedStatementDate: '2022-02-01',
            details: [
              {
                date: '2022-02-01',
                description: 'No data available for this period',
              },
              {
                date: '2022-01-01',
                description: 'No data available for this period',
              },
              {
                date: '2021-12-01',
                description: 'The account has been late for 4 billing cycles',
              },
            ],
          },
          liabilityTransfers: {
            transferredFrom: {
              creditorName: 'IKEA',
            },
          },
        },
        balanceDetails: {
          outstandingBalance: 3347,
        },
        statementSummary: {
          statementBalance: 3347,
          principalBalance: 3347,
          minimumPaymentAmount: 80,
          lastPaymentDate: '2022-03-01',
          lastPaymentDateWithFormat: {
            value: '2022-03',
            format: 'YYYY-MM',
          },
          statementDate: '2022-03-01',
          statementDateWithFormat: {
            value: '2022-03',
            format: 'YYYY-MM',
          },
          overduePeriod: 'NOT_OVERDUE',
        },
        creditor: {
          industryType: 'CompleteDept.Stores',
          industryCode: 'DC',
          bureauSubscriberCode: '401BB00573',
          address: {
            addressLine1: '8725 W. SAHARA AVE.',
            city: 'THE LAKES',
            state: 'NV',
            zip: '89163',
          },
        },
        capabilities: {
          payments: {
            billPayment: {
              availability: 'SUPPORTED',
            },
          },
        },
        updatedOn: 1678724486907,
        createdOn: 1678724486907,
      },
    ],
  },
};
