import { gql } from '@apollo/client';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

export const CREATE_MANUAL_HOLDING_MUTATION = gql`
  mutation Common_CreateManualHolding($input: CreateManualHoldingInput!) {
    createManualHolding(input: $input) {
      holding {
        id
        ticker
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;
