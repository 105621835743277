import { useMutation } from '@apollo/client';
import type { DateTime } from 'luxon';

import { useOptimisticBudgetUpdates } from 'common/lib/hooks/budget/useOptimisticBudgetUpdates';

import { gql } from 'common/generated/gql';
import type { MutationHookOptionsFromDocument } from 'common/types/graphql';

type Options = MutationHookOptionsFromDocument<typeof UPDATE_FLEX_BUDGET_MUTATION>;

type BudgetUpdateDetails = {
  amount: number | undefined;
  applyToFuture?: boolean;
};

const useUpdateFlexBudgetMutation = (options: Options, optimisticCacheUpdate = true) => {
  const [performMutation, { loading }] = useMutation(UPDATE_FLEX_BUDGET_MUTATION, options);
  const { optimisticUpdateCacheForFlexBudget } = useOptimisticBudgetUpdates();

  const createFlexBudgetUpdater =
    (startDate: DateTime) => async (updateDetails: BudgetUpdateDetails) => {
      const { amount, applyToFuture } = updateDetails;

      if (optimisticCacheUpdate && amount) {
        optimisticUpdateCacheForFlexBudget({ startDate, amount });
      }

      await performMutation({
        variables: {
          input: {
            startDate: startDate.toISODate(),
            amount,
            applyToFuture,
            defaultAmount: undefined,
          },
        },
      });
    };

  return {
    loading,
    createFlexBudgetUpdater,
  };
};

const UPDATE_FLEX_BUDGET_MUTATION = gql(/* GraphQL */ `
  mutation Common_UpdateFlexBudgetMutation($input: UpdateOrCreateFlexBudgetItemMutationInput!) {
    updateOrCreateFlexBudgetItem(input: $input) {
      budgetItem {
        id
        budgetAmount
      }
    }
  }
`);

export default useUpdateFlexBudgetMutation;
