import { useMutation, gql } from '@apollo/client';
import type { GraphQlMutationError } from 'common/errors';
import * as React from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextAreaInput from 'components/lib/form/TextAreaInput';
import TextField from 'components/lib/form/TextField';
import Banner from 'components/lib/ui/Banner';
import CopyToClipboardButton from 'components/lib/ui/CopyToClipboardButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Link from 'components/lib/ui/Link';
import Modal from 'components/lib/ui/Modal';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import useToggle from 'common/lib/hooks/useToggle';

import { HOUSEHOLD_ACCESS_GRANTS } from 'common/constants/copy';

import type {
  CreateHouseholdAccessGrant,
  CreateHouseholdAccessGrantVariables,
} from 'common/generated/graphQlTypes/CreateHouseholdAccessGrant';
import { ErrorCode } from 'common/generated/graphQlTypes/globalTypes';

type Props = {
  onClose: () => void;
  onGrantSuccess: () => void;
};

const WarningContainer = styled(Banner).attrs({ type: 'warning' })`
  display: flex;
  align-items: center;
`;

const InfoIcon = styled(Icon).attrs({ name: 'info' })`
  width: 32px;
  height: 32px;
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const CheckIcon = styled(Icon).attrs({ name: 'check' })`
  color: ${({ theme }) => theme.color.textWhite};
  width: 30px;
  height: 30px;
`;

const LineBreak = styled.hr`
  margin: ${({ theme }) => theme.spacing.xlarge} 0;
  width: 100%;
  height: 1px;
  color: ${({ theme }) => theme.color.grayBackground};
  background-color: ${({ theme }) => theme.color.grayBackground};
  border: none;
`;

const SuccessCard = styled(ModalCard)`
  width: 446px;
  margin: auto;
`;

const CheckContainer = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.color.green};
  width: 56px;
  height: 56px;
  border-radius: 100%;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const OkayButton = styled(PrimaryButton)`
  margin: ${({ theme }) => theme.spacing.xxlarge} 0;
`;

const MUTATION = gql`
  mutation CreateHouseholdAccessGrant($input: CreateHouseholdAccessGrantInput!) {
    createHouseholdAccessGrant(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      accessGrant {
        toEmail
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

const ADVISOR_SIGNUP_URL = 'https://www.monarchmoney.com/advisors';

const NEED_ADVISOR_ACCOUNT_MESSAGE = `
Hi there,

I'd like to invite you to collaborate with me on my finances in Monarch Money.

To do this, you'll need to sign up for the Monarch Advisor program. You can do that here:

  https://www.monarchmoney.com/advisors

Once you've signed up, let me know and I'll grant you access to my household.
`;

const StyledTextAreaInput = styled(TextAreaInput)`
  width: 100%;
  background: ${(props) => props.theme.color.grayBackground};
  font-size: ${(props) => props.theme.fontSize.small};
  box-sizing: border-box;
  pointer-events: none;
  margin: ${(props) => props.theme.spacing.large} 0;
`;

const GrantAccessModal = ({ onClose, onGrantSuccess }: Props) => {
  const [grantAccessMutation] = useMutation<
    CreateHouseholdAccessGrant,
    CreateHouseholdAccessGrantVariables
  >(MUTATION);
  const [isSuccess, { setOn: success }] = useToggle(false);
  const [noSponsorAccountForEmail, { setOn: setNoSponsorAccountForEmailError }] = useToggle(false);

  return (
    <Modal onClose={onClose}>
      <Form
        mutation={grantAccessMutation}
        onSubmitSuccess={() => {
          onGrantSuccess();
          success();
        }}
        onError={({ errors: { code } }: GraphQlMutationError) => {
          if (code === ErrorCode.SPONSOR_NOT_FOUND) {
            setNoSponsorAccountForEmailError();
          }
        }}
      >
        {!isSuccess ? (
          <ModalCard title="Grant Access to Advisor">
            <FlexContainer padding="large" column>
              {!noSponsorAccountForEmail ? (
                <>
                  <WarningContainer>
                    <InfoIcon />
                    <span>
                      <Text size="small" weight="medium">
                        {'IMPORTANT: '}
                      </Text>
                      <Text size="small">
                        {"Advisors you give access to will have full access to your household's data. You " +
                          `should only invite people you fully trust. For security reasons, invites expire after ${HOUSEHOLD_ACCESS_GRANTS.ACCESS_GRANT_DURATION_DAYS} days.`}
                      </Text>
                    </span>
                  </WarningContainer>

                  <LineBreak />
                  <TextField name="email" label="Grant Access via Email Address" email required />
                  <FormSubmitButton>Send Invite</FormSubmitButton>
                </>
              ) : (
                <>
                  <WarningContainer>
                    <Text>
                      <b>
                        This email address does not have a{' '}
                        <Link target="_blank" href={ADVISOR_SIGNUP_URL}>
                          Monarch Advisor account
                        </Link>
                        .
                      </b>{' '}
                      To collaborate with you, they will need to sign-up for one. Once they have
                      signed up, you can return here to send grant them access to your household.
                    </Text>
                  </WarningContainer>
                  <LineBreak />
                  We will send them a note, but please send them the message below:
                  <StyledTextAreaInput value={NEED_ADVISOR_ACCOUNT_MESSAGE} />
                  <CopyToClipboardButton messageToCopy={NEED_ADVISOR_ACCOUNT_MESSAGE} />
                </>
              )}
            </FlexContainer>
          </ModalCard>
        ) : (
          <SuccessCard>
            <FormContext.Consumer>
              {({ values }) => (
                <FlexContainer column center>
                  <CheckContainer center>
                    <CheckIcon />
                  </CheckContainer>
                  <Text weight="bold">{'Access granted to '}</Text>
                  <Text color="orange">{values.email}</Text>
                  <OkayButton onClick={onClose}>Okay</OkayButton>
                </FlexContainer>
              )}
            </FormContext.Consumer>
          </SuccessCard>
        )}
      </Form>
    </Modal>
  );
};

export default GrantAccessModal;
