import { gql } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import type { LinkAccountSuccessMetadata } from 'components/accounts/LinkAccountDataProviderModalWithFallback';
import LinkAccountSelectDataProviderModal from 'components/accounts/LinkAccountSelectDataProviderModal';
import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Modal from 'components/lib/ui/Modal';
import ModalCard from 'components/lib/ui/ModalCard';
import Stack from 'components/utils/Stack';

import useQuery from 'common/lib/hooks/useQuery';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

import type {
  Web_DirectLinkAccountSelectDataProviderQuery,
  Web_DirectLinkAccountSelectDataProviderQueryVariables,
} from 'common/generated/graphql';
import type RouteProps from 'types/RouteProps';

const Container = styled(FlexContainer).attrs({ center: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: 0;
`;

type Props = RouteProps<typeof routes.accounts.addAccountSelectDataProvider> & {
  onComplete: (metadata: LinkAccountSuccessMetadata) => void;
};

const DirectLinkAccountSelectDataProvider = ({
  match: {
    params: { institutionId },
  },
  onComplete,
}: Props) => {
  const { push } = useHistory();

  const { data, isLoadingInitialData } = useQuery<
    Web_DirectLinkAccountSelectDataProviderQuery,
    Web_DirectLinkAccountSelectDataProviderQueryVariables
  >(QUERY, {
    variables: { institutionId },
  });
  const { institution } = data ?? {};

  return (
    <Modal onClose={() => push(routes.accounts())}>
      {({ close }) =>
        institution ? (
          <Stack
            initial={{
              component: LinkAccountSelectDataProviderModal,
              props: { institution, hideBackButton: true },
            }}
            onPopTop={close}
            onComplete={onComplete}
          />
        ) : (
          <ModalCard hideBottomBorder>
            <Container>
              {isLoadingInitialData ? (
                <LoadingSpinner />
              ) : (
                <Banner type="error">{COPY.DEFAULT_ERROR_MESSAGE}</Banner>
              )}
            </Container>
          </ModalCard>
        )
      }
    </Modal>
  );
};

const QUERY = gql`
  query Web_DirectLinkAccountSelectDataProvider($institutionId: String!) {
    institution(id: $institutionId) {
      id
      ...LinkInstitutionFields
    }
  }
`;

export default DirectLinkAccountSelectDataProvider;
