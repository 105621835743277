import React from 'react';

import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';

import { HELP_CENTER_MONEY_BACK_GUARANTEE_URL } from 'common/constants/externalUrls';

const RequestRefundHelpCenterText = () => (
  <Text>
    Check out{' '}
    <Link href={HELP_CENTER_MONEY_BACK_GUARANTEE_URL} target="_blank" rel="noopener noreferrer">
      Monarch&apos;s Money-back Guarantee
    </Link>{' '}
    for information on our refund policy. If you&apos;d like to proceed with requesting a refund,
    please fill out the form below.
  </Text>
);

export default RequestRefundHelpCenterText;
