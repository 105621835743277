import * as R from 'ramda';
import React, { useContext } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import CategorySelect from 'components/categories/CategorySelect';
import CurrencyField from 'components/lib/form/CurrencyField';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';
import IconButton from 'components/lib/ui/button/IconButton';
import {
  TransactionRuleFormSide,
  TransactionRuleFormTitle,
  TransactionRuleFormToggleCard,
} from 'components/transactions/rules/TransactionRuleFormComponents';

import { color, spacing, fontSize, fontWeight, transition } from 'common/lib/theme/dynamic';
import {
  AmountOperator,
  AMOUNT_OPERATOR_OPTIONS,
  INITIAL_ENABLED_VALUES,
  MERCHANT_ORIGINAL_STATEMENT_OPTIONS,
  STRING_OPERATOR_OPTIONS,
} from 'common/lib/transactions/Rules';
import useTransactionRulesForm from 'lib/hooks/transactions/useTransactionRulesForm';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';

import type { UpdateTransactionRuleInput } from 'common/generated/graphql';

const StyledTextField = styled(TextField)`
  input {
    background-color: ${color.white};
  }
`;

const InputRow = styled.div`
  display: flex;
  flex: 1;

  & > div {
    flex: 3;

    &:not(:first-child) {
      flex: 4;
      margin-left: ${spacing.default};
    }
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

const RightArrow = styled(FiArrowRight)`
  margin: 0 ${spacing.default};
  margin-top: 14px;
`;

const Row = styled.div`
  margin-top: ${spacing.default};
  display: flex;
`;

const GrowCurrencyField = styled(CurrencyField).attrs({ maskOptions: { allowDecimal: true } })`
  flex: 1;
  && {
    margin-bottom: 0;
  }
  input {
    width: 100%;
    background-color: ${color.white};
  }
`;

const CriteriaSeparator = styled.div`
  font-size: ${fontSize.xsmall};
  font-weight: ${fontWeight.bold};
  margin: ${spacing.small} ${spacing.xsmall};
`;

const ButtonsContainer = styled(FlexContainer).attrs({ justifyEnd: true })<{ $wide: boolean }>`
  width: ${({ $wide }) => ($wide ? '70px' : '40px')};
  transition: ${transition.default};
`;

type ValueRangeKeys = 'upper' | 'lower';

const INITIAL_VALUE_RANGE_VALUE: { [key in ValueRangeKeys]: any } = {
  upper: undefined,
  lower: undefined,
};

const TransactionRuleFormCriteriaContainer = () => {
  const { setFieldValue, getFieldMeta } = useContext(FormContext);
  const { toggleProps } = useTransactionRulesForm();

  const [isLoadingAccounts, accountOptions] = useAccountSelectOptions();

  const isBetweenOperator =
    getFieldMeta('amountCriteria.operator').value === AmountOperator.Between;

  const { value: merchantCriteria } =
    getFieldMeta<UpdateTransactionRuleInput['merchantCriteria']>('merchantCriteria');

  const addMerchantCriteria = () =>
    setFieldValue(
      'merchantCriteria',
      R.append(INITIAL_ENABLED_VALUES.merchantCriteria[0], merchantCriteria ?? []),
    );

  const removeMerchantCriteria = (index: number) =>
    setFieldValue('merchantCriteria', R.remove(index, 1, merchantCriteria ?? []));

  return (
    <TransactionRuleFormSide>
      <TransactionRuleFormTitle>If transaction matches criteria...</TransactionRuleFormTitle>
      <TransactionRuleFormToggleCard title="Merchants" {...toggleProps(`merchantCriteria`)}>
        <SelectField
          name="merchantCriteriaUseOriginalStatement"
          menuPortalTarget={document.body}
          hideLabel
          options={MERCHANT_ORIGINAL_STATEMENT_OPTIONS}
        />
        {merchantCriteria &&
          R.range(0, merchantCriteria?.length ?? 0).map((index) => (
            <React.Fragment key={index}>
              {index > 0 && <CriteriaSeparator>OR</CriteriaSeparator>}
              <FlexContainer alignCenter>
                <InputRow {...sensitiveClassProps}>
                  <SelectField
                    name={`merchantCriteria[${index}].operator`}
                    hideLabel
                    options={STRING_OPERATOR_OPTIONS}
                    menuPortalTarget={document.body}
                  />
                  <StyledTextField
                    name={`merchantCriteria[${index}].value`}
                    placeholder="Name"
                    hideLabel
                  />
                </InputRow>
                <ButtonsContainer $wide={merchantCriteria.length > 1}>
                  {index === merchantCriteria.length - 1 && (
                    <>
                      {index !== 0 && (
                        <IconButton
                          onClick={() => removeMerchantCriteria(index)}
                          icon="minus-circle"
                        />
                      )}
                      <IconButton onClick={addMerchantCriteria} icon="plus-circle" />
                    </>
                  )}
                </ButtonsContainer>
              </FlexContainer>
            </React.Fragment>
          ))}
      </TransactionRuleFormToggleCard>
      <TransactionRuleFormToggleCard title="Amount" {...toggleProps('amountCriteria')}>
        <InputRow>
          <SelectField
            name="amountCriteria.isExpense"
            hideLabel
            options={[
              { label: 'Expense', value: true },
              { label: 'Income', value: false },
            ]}
            menuPortalTarget={document.body}
          />
          <SelectField
            name="amountCriteria.operator"
            hideLabel
            options={AMOUNT_OPERATOR_OPTIONS}
            menuPortalTarget={document.body}
            onChange={(operator) => {
              // Prevent invalid ranges from being submitted when operator is toggled to "between" and then back
              if (
                operator !== AmountOperator.Between &&
                getFieldMeta('amountCriteria.valueRange').value !==
                  INITIAL_ENABLED_VALUES.amountCriteria.valueRange
              ) {
                setFieldValue(
                  'amountCriteria.valueRange',
                  INITIAL_ENABLED_VALUES.amountCriteria.valueRange,
                );
              }

              // When a user changes to between, we need to layout valueRange or the currency input will not work
              if (
                operator === AmountOperator.Between &&
                getFieldMeta('amountCriteria.valueRange').value ===
                  INITIAL_ENABLED_VALUES.amountCriteria.valueRange
              ) {
                setFieldValue('amountCriteria.valueRange', INITIAL_VALUE_RANGE_VALUE);
              }
            }}
          />
        </InputRow>
        <Row>
          {isBetweenOperator ? (
            <>
              <GrowCurrencyField
                name="amountCriteria.valueRange.lower"
                placeholder="$123"
                hideLabel
              />
              <RightArrow />
              <GrowCurrencyField
                name="amountCriteria.valueRange.upper"
                placeholder="$456"
                hideLabel
              />
            </>
          ) : (
            <GrowCurrencyField name="amountCriteria.value" placeholder="$123" hideLabel />
          )}
        </Row>
      </TransactionRuleFormToggleCard>
      <TransactionRuleFormToggleCard title="Categories" {...toggleProps('categoryIds')}>
        <SelectField
          name="categoryIds"
          placeholder="Category equals..."
          hideLabel
          menuPortalTarget={document.body}
          InputComponent={CategorySelect}
          isMulti
        />
      </TransactionRuleFormToggleCard>
      <TransactionRuleFormToggleCard title="Accounts" {...toggleProps('accountIds')}>
        <SelectField
          name="accountIds"
          placeholder="Account equals..."
          hideLabel
          menuPortalTarget={document.body}
          isLoading={isLoadingAccounts}
          options={accountOptions}
          isMulti
        />
      </TransactionRuleFormToggleCard>
    </TransactionRuleFormSide>
  );
};

export default TransactionRuleFormCriteriaContainer;
