import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import SecurityLogo from 'components/securities/SecurityLogo';

import { GET_HOLDING_SUMMARY_SECURITY_DETAILS_QUERY } from 'common/lib/graphQl/investments';
import useQuery from 'common/lib/hooks/useQuery';
import { getHoldingValue } from 'common/lib/investments/holdings';
import { formatCurrency } from 'common/utils/Currency';
import { formatThousands } from 'common/utils/Number';

import type {
  GetHoldingSummarySecurityDetails,
  GetHoldingSummarySecurityDetailsVariables,
} from 'common/generated/graphQlTypes/GetHoldingSummarySecurityDetails';

type Props = {
  securityId: string;
  quantity: number;
};

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  gap: ${({ theme }) => theme.spacing.small};
`;

const SecurityInfoContainer = styled(FlexContainer)`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  align-items: center;
  justify-content: space-between;
`;

const GeneralSecurityInfoContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  align-items: flex-start;
`;

const HoldingValueContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  align-items: center;
  > * {
    width: 100%;
  }
`;

const HoldingSummary = ({ securityId, quantity }: Props) => {
  const { data } = useQuery<
    GetHoldingSummarySecurityDetails,
    GetHoldingSummarySecurityDetailsVariables
  >(GET_HOLDING_SUMMARY_SECURITY_DETAILS_QUERY, {
    variables: {
      id: securityId,
    },
  });

  const { name, ticker, logo, currentPrice } = data?.security ?? {};

  const value = useMemo(
    () => getHoldingValue(!quantity || quantity === 0 ? 1 : quantity, currentPrice ?? 0),
    [quantity, currentPrice],
  );

  return (
    <Root>
      <SecurityLogo logo={logo} />
      <SecurityInfoContainer>
        <GeneralSecurityInfoContainer>
          <Text weight="medium">{ticker}</Text>
          <Text size="xsmall" color="textLight">
            {name}
          </Text>
        </GeneralSecurityInfoContainer>

        <HoldingValueContainer>
          <Text size="base" color="text" align="right">
            {data?.security && currentPrice ? formatCurrency(value) : '--'}
          </Text>
          <Text size="xsmall" color="textLight" align="right">
            {`${formatThousands(quantity || 1)} ${pluralize('share', quantity || 1)}`}
          </Text>
        </HoldingValueContainer>
      </SecurityInfoContainer>
    </Root>
  );
};

export default HoldingSummary;
