import type { DragEndEvent } from '@dnd-kit/core';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import DndKitContext from 'components/lib/dndKit/DndContext';
import Card from 'components/lib/ui/Card';
import OnboardingVariabilityCardSection from 'components/plan/onboarding/OnboardingVariabilityCardSection';
import OnboardingVariabilityItem from 'components/plan/onboarding/OnboardingVariabilityItem';

import { DEFAULT_BUDGET_VARIABILITY_IF_UNSET } from 'common/lib/budget/constants';
import { color } from 'common/lib/theme/dynamic';
import type { PlanCategory } from 'lib/plan/types';

import { FIXED_FLEXIBLE_NON_MONTHLY_INTRODUCTION } from 'common/constants/copy';

import type { BudgetVariability, Common_GetJointPlanningDataQuery } from 'common/generated/graphql';
import type { PickPropsFromComponent } from 'common/types/utility';

const Container = styled(Card)`
  margin: 0 auto;
  border: 1px solid ${color.grayFocus};
`;

type Props = {
  groups: Common_GetJointPlanningDataQuery['categoryGroups'];
  onUpdateVariability: (id: string, budgetVariability: BudgetVariability) => Promise<void>;
} & PickPropsFromComponent<
  typeof OnboardingVariabilityCardSection,
  'onEditCategory' | 'onHideFromBudget' | 'onDeleteOrDisableCategory'
>;

const OnboardingVariabilityCard = ({
  groups,
  onUpdateVariability,
  onEditCategory,
  onHideFromBudget,
  onDeleteOrDisableCategory,
}: Props) => {
  const allCategoriesById = useMemo(
    () =>
      groups
        .flatMap((group) => group.categories)
        .reduce(
          (acc, category) => {
            acc[category.id] = category;
            return acc;
          },
          {} as Record<string, PlanCategory>,
        ),
    [groups],
  );

  const categoriesByVariability = useMemo(
    () =>
      Object.values(allCategoriesById).reduce(
        (acc, category) => {
          const variability = category.budgetVariability ?? DEFAULT_BUDGET_VARIABILITY_IF_UNSET;
          acc[variability] = [...(acc[variability] ?? []), category];
          return acc;
        },
        {} as Record<BudgetVariability, PlanCategory[]>,
      ),
    [allCategoriesById],
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    const activeVariability = active.data.current?.variability;
    const overVariability = over?.data.current?.variability;

    if (over && active.id !== over.id && activeVariability !== overVariability) {
      await onUpdateVariability(String(active.id), over.id as BudgetVariability);
    }
  };

  return (
    <Container>
      <DndKitContext
        onDragEnd={handleDragEnd}
        dragOverlay={(activeId) => (
          <OnboardingVariabilityItem item={allCategoriesById[activeId]} isActive />
        )}
      >
        {FIXED_FLEXIBLE_NON_MONTHLY_INTRODUCTION.map(
          ({ title, description, variability }, index) => (
            <OnboardingVariabilityCardSection
              key={variability}
              title={title}
              description={description}
              variability={variability}
              items={categoriesByVariability[variability] ?? []}
              isLast={index === FIXED_FLEXIBLE_NON_MONTHLY_INTRODUCTION.length - 1}
              onEditCategory={onEditCategory}
              onHideFromBudget={onHideFromBudget}
              onDeleteOrDisableCategory={onDeleteOrDisableCategory}
              onMoveTo={onUpdateVariability}
            />
          ),
        )}
      </DndKitContext>
    </Container>
  );
};

export default OnboardingVariabilityCard;
