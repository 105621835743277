import React from 'react';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import SegmentedControl from 'components/lib/ui/SegmentedControl';

const SegmentedControlField = (
  props: AbstractFieldProps<React.ComponentProps<typeof SegmentedControl>>,
) => (
  <AbstractField {...props}>
    {({ value, setFieldValue }) => (
      <SegmentedControl
        {...props}
        value={value}
        onChange={(value) => {
          setFieldValue(props.name, value);
        }}
      />
    )}
  </AbstractField>
);

export default SegmentedControlField;
