import React from 'react';
import styled from 'styled-components';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Flex from 'components/lib/ui/Flex';
import Header from 'components/routes/Header';

const FlexNoOverflow = styled(Flex)`
  overflow: hidden;
  height: 100%;
`;

const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const Title = styled.span`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  icon?: React.ReactNode;
  /** The name of the page */
  name: string;
  /** Dynamic title to override the page. Used just for display. */
  overrideTitle?: string;
  controls?: React.ReactNode;
  /** Tabs component to show when page has sub routes. */
  tabs?: React.ReactNode;
  titleIsSensitive?: boolean;
};

const PageHeader = ({ controls, icon, titleIsSensitive, overrideTitle, tabs, name }: Props) => (
  <Header controls={controls}>
    <FlexNoOverflow alignCenter {...(titleIsSensitive ? sensitiveClassProps : {})}>
      {!!icon && <IconWrapper>{icon}</IconWrapper>}
      <Title title={overrideTitle ?? name}>{overrideTitle ?? name}</Title>
      {tabs}
    </FlexNoOverflow>
  </Header>
);

export default PageHeader;
