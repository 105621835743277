import { gql } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import GoalImage from 'components/goalsV2/GoalImage';
import DragDots from 'components/lib/ui/DragDots';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import IconButton from 'components/lib/ui/button/IconButton';

import type { GoalRankCardFields } from 'common/generated/graphQlTypes/GoalRankCardFields';

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyCenter: true })`
  border-radius: ${({ theme }) => theme.radius.medium};
  background-color: ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.xsmall};
`;

const StyledIconButton = styled(IconButton)`
  margin-left: auto;
`;

const StyledGoalImage = styled(GoalImage)`
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 2px;
  margin-left: ${({ theme }) => theme.spacing.default};
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const StyledDragDots = styled(DragDots)`
  margin-left: ${({ theme }) => theme.spacing.small};
  margin-right: ${({ theme }) => theme.spacing.xxxsmall};
`;

type Props = {
  goalData: GoalRankCardFields;
  onClickEdit?: () => void;
};

const GoalRankCard = ({ goalData, onClickEdit }: Props) => (
  <Root>
    <StyledDragDots />
    <StyledGoalImage
      imageStorageProvider={goalData.imageStorageProvider}
      imageStorageProviderId={goalData.imageStorageProviderId}
      size="small"
      showGradient={false}
    />
    <FlexContainer column>
      <Text color="textLight" weight="bold" size="xsmall">
        #{goalData.priority}
      </Text>
      <Text color="text" weight="medium">
        {goalData.name}
      </Text>
    </FlexContainer>
    {onClickEdit && <StyledIconButton icon="edit" onClick={onClickEdit} />}
  </Root>
);

GoalRankCard.fragments = {
  GoalRankCardFields: gql`
    fragment GoalRankCardFields on GoalV2 {
      id
      name
      priority
      imageStorageProvider
      imageStorageProviderId
    }
  `,
};

export default GoalRankCard;
