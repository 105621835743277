import { MXConnectEventNames } from 'common/constants/analytics';

/** https://docs.mx.com/api#connect_postmessage_events */
export enum MXEventName {
  /** Triggers when the Connect widget loads. It is often useful to know what “step” or view the user started on. */
  ConnectLoaded = 'mx/connect/loaded',
  /** Triggered when a user submits credentials for a given institution for the first time. */
  EnterCredentials = 'mx/connect/enterCredentials',
  /** Triggered when the end user searches for an institution. This is useful in determining what users are searching for. */
  InstitutionSearch = 'mx/connect/institutionSearch',
  /** Triggered when an end user selects an institution from the institution list. */
  InstitutionSelected = 'mx/connect/selectedInstitution',
  /** Triggered when a member has successfully connected the account. */
  MemberConnected = 'mx/connect/memberConnected',
  /** Triggered when the user selects the primary button on the connected step. */
  MemberConnectedPrimaryAction = 'mx/connect/connected/primaryAction',
  /** Triggered when a member has been deleted in the widget. */
  MemberDeleted = 'mx/connect/memberDeleted',
  /** Triggered when a member failed to get created when credentials were entered. */
  MemberCreateError = 'mx/connect/createMemberError',
  /** Undocumented observed error */
  MemberError = 'mx/connect/memberError',
  /** Triggered when a member’s connection status has changed while connecting. This is useful in determining the current connection status of the member. */
  MemberStatusUpdate = 'mx/connect/memberStatusUpdate',
  /** Triggered when the user lands on the OAuth error page. This is a general error message and could represent a problem with the OAuth provider or MX. */
  OauthError = 'mx/connect/oauthError',
  /** Triggered when the user navigates to the OAuth provider’s site. Note that the redirect does not happen in WebViews. The native app will need to use this postMessage to send the user to the URL contained in the metadata. */
  OauthRequested = 'mx/connect/oauthRequested',
  /** Triggered when the end user changes from one “step” to another. This is useful for determining what transitions users are making. */
  StepChange = 'mx/connect/stepChange',
  /** Triggered when a user submits an MFA answer. */
  SubmitMFA = 'mx/connect/submitMFA',
  /** Triggered when a user submits credentials while trying to update their existing credentials. */
  UpdateCredentials = 'mx/connect/updateCredentials',
  /** Triggered when the app is loaded. */
  WidgetLoad = 'mx/load',
  /** Used to keep the widget session alive. */
  WidgetPing = 'mx/ping',
}

/** Mapping from MX raw event names to our analytics names */
export const MXAnalyticsNameMapping: { [name in MXEventName]: MXConnectEventNames | null } = {
  [MXEventName.ConnectLoaded]: MXConnectEventNames.Loaded,
  [MXEventName.EnterCredentials]: MXConnectEventNames.CredentialsEntered,
  [MXEventName.InstitutionSearch]: MXConnectEventNames.InstitutionsSearched,
  [MXEventName.InstitutionSelected]: MXConnectEventNames.InstitutionSelected,
  [MXEventName.MemberConnected]: MXConnectEventNames.MemberConnected,
  [MXEventName.MemberConnectedPrimaryAction]: MXConnectEventNames.MemberConnectedPrimaryAction,
  [MXEventName.MemberDeleted]: MXConnectEventNames.MemberDeleted,
  [MXEventName.MemberCreateError]: MXConnectEventNames.Error,
  [MXEventName.MemberError]: MXConnectEventNames.Error,
  [MXEventName.MemberStatusUpdate]: MXConnectEventNames.MemberStatusUpdate,
  [MXEventName.OauthError]: MXConnectEventNames.Error,
  [MXEventName.OauthRequested]: MXConnectEventNames.OauthRequested,
  [MXEventName.StepChange]: MXConnectEventNames.StepChange,
  [MXEventName.SubmitMFA]: MXConnectEventNames.MFASubmitted,
  [MXEventName.UpdateCredentials]: MXConnectEventNames.CredentialsUpdated,
  [MXEventName.WidgetLoad]: null,
  [MXEventName.WidgetPing]: null,
};
