import { DateTime } from 'luxon';
import * as R from 'ramda';

import { useParamState } from 'lib/hooks/useParamState';

import { Tuple } from 'common/types';
import type DateRange from 'common/types/DateRange';

const DATE_RANGE_DEFAULT = {
  startDate: DateTime.local().startOf('month').toISODate(),
  endDate: DateTime.local().endOf('month').toISODate(),
};

const useDateRangeQueryParams = (initial?: DateRange) => {
  const [dateRange, setDateRange] = useParamState<DateRange>(
    R.merge(initial ?? DATE_RANGE_DEFAULT),
  );

  return Tuple(dateRange, setDateRange);
};

export default useDateRangeQueryParams;
