import * as R from 'ramda';

export const randomNumber = (max: number): number => Math.floor(Math.random() * Math.floor(max));
export const randomArrayElement = <T>(arr: T[]): T => arr[randomNumber(arr.length - 1)];
export const shuffleArray = <T>(arr: T[]): T[] =>
  R.pipe(
    R.map((value: T) => ({ value, sort: Math.random() })),
    R.sortBy(R.prop('sort')),
    R.map(R.prop('value')),
  )(arr);

export const shuffleArrayWithFixedLastOption = <T>(
  arr: T[],
  lastOptionValue: string,
  comparisonProp: keyof T,
): T[] => {
  const [fixedOption, rest] = R.partition(
    (item: T) => item[comparisonProp] === lastOptionValue,
    arr,
  );

  const shuffledRest = R.sortBy(() => Math.random(), rest);

  return [...shuffledRest, ...fixedOption];
};
