import { gql } from '@apollo/client';
import * as R from 'ramda';
import { isNull } from 'ramda-adjunct';
import * as React from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import CategorySelect from 'components/categories/CategorySelect';
import { Text as CheckboxText } from 'components/lib/form/Checkbox';
import CheckboxField from 'components/lib/form/CheckboxField';
import DateRangeField from 'components/lib/form/DateRangeField';
import Form from 'components/lib/form/Form';
import FormSection from 'components/lib/form/FormSection';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import Card from 'components/lib/ui/Card';
import Drawer from 'components/lib/ui/Drawer';
import Icon from 'components/lib/ui/Icon';
import TextButton from 'components/lib/ui/TextButton';
import TransactionAmountFilter from 'components/transactions/TransactionAmountFilter';
import TransactionTagSelect from 'components/transactions/tags/TransactionTagSelect';

import useQuery from 'common/lib/hooks/useQuery';
import useToggle from 'common/lib/hooks/useToggle';
import { FILTER_FIELDS_LABELS } from 'common/lib/transactions/filters';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';
import { DEFAULT_FILTERS, resetAmountFilters } from 'lib/transactions/Filters';

import type { GetTransactionFiltersCard } from 'common/generated/graphQlTypes/GetTransactionFiltersCard';
import type { TransactionFilters } from 'types/filters';

const StyledCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
`;

const MoreButton = styled(TextButton)`
  padding-left: 0;
  margin-top: ${({ theme }) => theme.spacing.large};
`;

const MoreIcon = styled(Icon)<{ $expanded: boolean }>`
  margin-right: ${({ theme }) => theme.spacing.xxsmall};
  transition: ${({ theme }) => theme.transition.default};
  transform: ${({ $expanded }) => ($expanded ? 'rotate(90deg)' : 'none')};
`;

const MoreContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.small};
`;

const StyledCheckboxField = styled(CheckboxField)`
  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }

  ${CheckboxText} {
    font-size: ${({ theme }) => theme.fontSize.base};
    font-weight: ${({ theme }) => theme.fontWeight.book};
    margin-left: ${({ theme }) => theme.spacing.small};
  }
`;

const MORE_FILTERS: (keyof TransactionFilters)[] = [
  'isSplit',
  'isRecurring',
  'hideFromReports',
  'hasAttachments',
  'hasNotes',
  'importedFromMint',
  'syncedFromInstitution',
];
type Props = {
  filters: TransactionFilters;
  onChangeFilters: (filters: TransactionFilters) => void;
};

const TransactionsFilterCard = ({ filters, onChangeFilters }: Props) => {
  const [isLoadingAccounts, accountOptions] = useAccountSelectOptions({
    includeSyncDisabled: true,
  });

  const expandedInitial = MORE_FILTERS.some((key) => !!filters[key]);
  const [moreExpanded, { toggle: toggleMoreExpanded }] = useToggle(expandedInitial);

  const { data } = useQuery<GetTransactionFiltersCard>(QUERY);
  const hasTransactionsImportedFromMint = (data?.allTransactions.totalCount ?? 0) > 0;

  return (
    <StyledCard
      title="Filter"
      controls={
        !R.equals(filters, DEFAULT_FILTERS) ? (
          <TextButton onClick={() => onChangeFilters(DEFAULT_FILTERS)}>Clear</TextButton>
        ) : undefined
      }
    >
      <Form
        initialValues={{
          ...filters,
          dateRange: {
            startDate: filters.startDate,
            endDate: filters.endDate,
          },
        }}
        onSubmit={async ({ dateRange, ...values }) =>
          onChangeFilters({
            ...DEFAULT_FILTERS,
            ...resetAmountFilters(values, values.amountFilter),
            ...R.reject(R.isNil, dateRange),
            search: values.search,
          })
        }
        submitOnChange
      >
        <FormSection>
          <FormContext.Consumer>
            {({ handleSubmit }) => (
              <>
                <TextField name="search" />
                <TransactionAmountFilter />
                <SelectField
                  name="accounts"
                  label="Accounts"
                  placeholder="All accounts"
                  isMulti
                  isLoading={isLoadingAccounts}
                  options={accountOptions}
                />
                <SelectField
                  name="categories"
                  label="Categories"
                  placeholder="All categories"
                  isMulti
                  InputComponent={CategorySelect}
                />
                <DateRangeField
                  name="dateRange"
                  label="Date range"
                  minDate={new Date(2000, 1, 1)} // TODO: use date of first transaction as min date
                  maxDate={new Date()}
                  anchorDirection="right"
                  onChange={({ startDate, endDate }) => {
                    if (startDate && endDate) {
                      handleSubmit();
                    } else if (isNull(startDate) && isNull(endDate)) {
                      // If both are null, it means that the user cleared the date range
                      onChangeFilters({ ...filters, startDate: null, endDate: null });
                    }
                  }}
                  showClearDates
                />
                <SelectField
                  name="tags"
                  label="Tags"
                  InputComponent={TransactionTagSelect}
                  isCreatable={false}
                  placeholder="All tags..."
                  isMulti
                />
                <MoreButton onClick={toggleMoreExpanded}>
                  <MoreIcon name="chevron-right" $expanded={moreExpanded} />
                  More options
                </MoreButton>
                <Drawer open={moreExpanded}>
                  <MoreContainer>
                    <StyledCheckboxField name="hideFromReports" hideLabel>
                      {FILTER_FIELDS_LABELS.hideFromReports}
                    </StyledCheckboxField>
                    <StyledCheckboxField name="isSplit" hideLabel>
                      {FILTER_FIELDS_LABELS.isSplit}
                    </StyledCheckboxField>
                    <StyledCheckboxField name="isRecurring" hideLabel>
                      {FILTER_FIELDS_LABELS.isRecurring}
                    </StyledCheckboxField>
                    <StyledCheckboxField name="hasNotes" hideLabel>
                      {FILTER_FIELDS_LABELS.hasNotes}
                    </StyledCheckboxField>
                    <StyledCheckboxField name="hasAttachments" hideLabel>
                      {FILTER_FIELDS_LABELS.hasAttachments}
                    </StyledCheckboxField>
                    {(hasTransactionsImportedFromMint || filters.importedFromMint) && (
                      <StyledCheckboxField name="importedFromMint" hideLabel>
                        Imported from Mint
                      </StyledCheckboxField>
                    )}
                    <StyledCheckboxField name="syncedFromInstitution" hideLabel>
                      Synced from institution
                    </StyledCheckboxField>
                  </MoreContainer>
                </Drawer>
              </>
            )}
          </FormContext.Consumer>
        </FormSection>
      </Form>
    </StyledCard>
  );
};

const QUERY = gql`
  query GetTransactionFiltersCard {
    allTransactions(filters: { importedFromMint: true }) {
      totalCount
    }
  }
`;

export default TransactionsFilterCard;
