import { Image as CloudinaryImage, Transformation } from 'cloudinary-react';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import styled, { css } from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import CloseButton from 'components/lib/ui/button/CloseButton';

type Props = {
  name: string;
  progressPercent: number;
  displaySize: string;
  onRemoveClick: () => void;
  publicId?: string;
  localUri?: string;
  originalAssetUrl?: string;
  useThumbnailPlaceholder: boolean;
  className?: string;
  disabled?: boolean;
  singleItem?: boolean;
};

const Root = styled.div<{ $disabled?: boolean; singleItem?: boolean }>`
  border: 1px solid ${({ theme }) => theme.color.gray};

  ${({ $disabled }) =>
    !$disabled &&
    css`
      :hover {
        border: 1px solid ${({ theme }) => theme.color.blue};
      }
    `}

  border-radius: ${({ theme }) => theme.radius.medium};
  height: 56px;
  display: flex;
  flex: 1;
  padding: ${({ theme }) => theme.spacing.small};
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme, singleItem }) => (singleItem ? '0' : theme.spacing.small)};
  cursor: pointer;
`;

const FileItemProgress = styled.span<{ progressPercent: number }>`
  width: 96px;
  height: 8px;
  border-radius: ${({ theme }) => theme.radius.small};
  background-color: ${({ theme }) => theme.color.grayLight};
  position: relative;

  ::after {
    content: '';
    background-color: ${({ theme }) => theme.color.blue};
    border-radius: ${({ theme }) => theme.radius.small};
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ progressPercent }) => progressPercent}%;
  }
`;

const ImgContainer = styled.div`
  width: 32px;
  height: 32px;
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const PdfThumbnailPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.grayBackground};
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.radius.small};
`;

const RemoveButton = styled(CloseButton)`
  color: ${({ theme }) => theme.color.textLight};
  margin-left: ${({ theme }) => theme.spacing.default};

  &:hover {
    color: ${({ theme }) => theme.color.text};
  }
`;

const OverflowText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
  white-space: nowrap;
`;

const StyledCloudinaryImage = styled(CloudinaryImage)`
  border-radius: ${({ theme }) => theme.radius.small};
`;

const StyledLocalImage = styled.img`
  border-radius: ${({ theme }) => theme.radius.small};
`;

const AttachmentItem = ({
  name,
  progressPercent,
  displaySize,
  publicId,
  onRemoveClick,
  localUri,
  originalAssetUrl,
  useThumbnailPlaceholder,
  className,
  disabled,
  singleItem,
}: Props) => (
  <Root key={name} className={className} $disabled={disabled} singleItem={singleItem}>
    <FlexContainer alignCenter>
      <ImgContainer>
        <Switch>
          <Case when={useThumbnailPlaceholder}>
            <PdfThumbnailPlaceholder>
              <Icon name="paperclip" />
            </PdfThumbnailPlaceholder>
          </Case>
          <Case when={RA.isNotNil(publicId)}>
            <StyledCloudinaryImage publicId={publicId!} type="private" secure>
              <Transformation width="32" height="32" crop="scale" />
            </StyledCloudinaryImage>
          </Case>
          <Case default>
            <StyledLocalImage src={localUri} width={32} height={32} alt="Your attachment" />
          </Case>
        </Switch>
      </ImgContainer>
      <FlexContainer alignStart onClick={() => originalAssetUrl && window.open(originalAssetUrl)}>
        <OverflowText size="small">{name}</OverflowText>
      </FlexContainer>
    </FlexContainer>
    {publicId ? (
      <FlexContainer>
        <Text weight="medium" color="textLight" transform="uppercase" size="small">
          {displaySize}
        </Text>
        <RemoveButton
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            if (!disabled) {
              onRemoveClick();
            }
          }}
        />
      </FlexContainer>
    ) : (
      <FileItemProgress progressPercent={progressPercent} />
    )}
  </Root>
);

export default AttachmentItem;
