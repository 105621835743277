import React from 'react';

import LinkAccountFlow from 'components/accounts/LinkAccountFlow';
import EmptyCreateCard from 'components/lib/ui/EmptyCreateCard';
import NewOnboardingConnectAccountCard from 'components/onboarding/NewOnboardingConnectAccountCard';

import useGetInstitutionGroup from 'lib/hooks/institutions/useGetInstitutionGroup';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useModal from 'lib/hooks/useModal';

import { TopInstitutionGroupType } from 'common/generated/graphQlTypes/globalTypes';
import type {
  LinkInstitutionFieldsFragment,
  Web_GetTopInstitutionGroupsQuery,
} from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

const DEFAULT_SELECTED_GROUP_TYPE = TopInstitutionGroupType.banks_credit_cards;

type Props = {
  emptyTitle: string;
  emptyIcon: string;
  emptyButtonText: string;
  onAccountAdded: () => void;
};

const OnboardingConnectAccountPrompt = ({
  emptyTitle,
  emptyIcon,
  emptyButtonText,
  onAccountAdded,
}: Props) => {
  const [LinkModal, { open: openLink, close: closeLink, context }] = useModal<{
    institution?: LinkInstitutionFieldsFragment;
    selectedTopGroup?: ElementOf<Web_GetTopInstitutionGroupsQuery, 'topInstitutionGroups'>;
  }>();

  const useNewFlow = useIsFeatureFlagOn('new-onboarding-connect-account', {
    trackImpression: true,
  });

  const { topInstitutionGroupByType } = useGetInstitutionGroup(DEFAULT_SELECTED_GROUP_TYPE) ?? {};

  return (
    <>
      {useNewFlow ? (
        <NewOnboardingConnectAccountCard
          onSelectInstitution={(institution) => openLink({ institution })}
        />
      ) : (
        <EmptyCreateCard
          title={emptyTitle}
          icon={emptyIcon}
          button={{
            text: emptyButtonText,
            onClick: () => {
              openLink({ selectedTopGroup: topInstitutionGroupByType ?? undefined });
            },
            gradient: true,
          }}
        />
      )}
      <LinkModal onClose={onAccountAdded}>
        <LinkAccountFlow
          allowManualAccount
          selectedTopGroup={context?.selectedTopGroup}
          institution={context?.institution}
          onClose={closeLink}
          onComplete={closeLink}
        />
      </LinkModal>
    </>
  );
};

export default OnboardingConnectAccountPrompt;
