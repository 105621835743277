import React, { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components';

import LoggedOutCardPage from 'components/lib/layouts/LoggedOutCardPage';
import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';

import useToggle from 'common/lib/hooks/useToggle';
import useAuthProvider from 'lib/hooks/auth/useAuthProvider';
import useToast from 'lib/hooks/useToast';
import { onOpenReportEmail } from 'lib/support';

import { SUPPORTED_AUTH_PROVIDERS, AUTH_PROVIDER_TO_LABEL } from 'common/constants/auth';
import routes from 'constants/routes';

import type { AuthProvider } from 'common/types/auth';

const Button = styled(AsyncButton)`
  ${primaryButtonMixin}
  width: 100%;
  margin: ${({ theme }) => theme.spacing.default} 0;
`;

const Email = styled(Text).attrs({ weight: 'medium' })`
  margin: ${({ theme }) => theme.spacing.default} 0 0;
`;

const FooterText = styled(Text)`
  margin: ${({ theme }) => theme.spacing.default} 0;
`;

const StyledBanner = styled(Banner)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const AuthConfirmation = () => {
  const { openErrorToast } = useToast();
  const [error, setError] = useState<string | undefined>(undefined);
  const [isSubmitting, { setOn: setIsSubmitting, setOff: disableIsSubmitting }] = useToggle(false);
  const { provider } = useParams<{ provider: AuthProvider }>();
  const isProviderValid = SUPPORTED_AUTH_PROVIDERS.includes(provider);

  const { ephemeralData, authenticate, onCancel } = useAuthProvider({
    provider,
    onError: (error) => {
      disableIsSubmitting();
      setError(error?.detail);
      openErrorToast({
        onReport: () =>
          onOpenReportEmail(`Issue signing in with ${AUTH_PROVIDER_TO_LABEL[provider]}`),
      });
    },
  });

  if (!isProviderValid || !ephemeralData) {
    return <Redirect to={routes.login()} />;
  }

  const { email, ...data } = ephemeralData;

  return (
    <LoggedOutCardPage name="auth-create-confirmation" displayTitle="Create an account">
      <FlexContainer column>
        {!!error && <StyledBanner type="error">{error}</StyledBanner>}
        <Text>
          There&apos;s no existing Monarch account for the {AUTH_PROVIDER_TO_LABEL[provider]} email:
        </Text>
        <Email>{email}</Email>
        <FooterText>Would you like to sign up?</FooterText>
        <Button
          size="medium"
          pending={isSubmitting}
          onClick={() => {
            setIsSubmitting();
            authenticate({ ...data, skipConfirmation: true });
          }}
        >
          Sign up now
        </Button>
        <DefaultButton size="medium" linkTo={routes.login.path} onClick={onCancel}>
          Go back
        </DefaultButton>
      </FlexContainer>
    </LoggedOutCardPage>
  );
};

export default AuthConfirmation;
