import React from 'react';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import Icon from 'components/lib/ui/Icon';

import { ACCOUNT_TYPE_TO_ICON_MAP } from 'common/constants/accounts';

type Props = {
  name: string;
  displayName: string;
  onClick: () => void;
};

const Root = styled(Flex).attrs({ alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  cursor: pointer;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }

  :hover {
    background-color: ${({ theme }) => theme.color.grayLightBackground};
  }
`;

const StyledIcon = styled(Icon)`
  height: 14px;
  width: 14px;
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const AddManualAccountModalListItem = ({ name, displayName, onClick }: Props) => (
  <Root onClick={onClick}>
    <StyledIcon name={ACCOUNT_TYPE_TO_ICON_MAP[name]} />
    <span>{displayName}</span>
  </Root>
);

export default AddManualAccountModalListItem;
