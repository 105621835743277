import { transparentize } from 'polished';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import OnboardingCarousel from 'components/onboarding/OnboardingCarousel';
import { GET_ACCOUNTS_PAGE } from 'components/routes/Accounts';

import useQuery from 'common/lib/hooks/useQuery';
import useIsFeatureFlagOff from 'lib/hooks/useIsFeatureFlagOff';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';

import * as COPY from 'common/constants/copy';

import OnboardingConnectAccountSankey from './OnboardingConnectAccountSankey';

const screenshotDesktopDeviceUrl =
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/connect-account/flat-desktop-cash-flow.png';

const screenshotMobileDeviceUrl =
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/connect-account/flat-phone-cash-flow.png';

const screenshotDashboardDesktopDeviceUrl =
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/connect-account/flat-desktop-dashboard.png';

const screenshotDashboardMobileDeviceUrl =
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/connect-account/flat-phone-dashboard.png';

const screenshotTransactionsDesktopDeviceUrl =
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/connect-account/flat-desktop-transactions.png';

const screenshotTransactionsMobileDeviceUrl =
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/connect-account/flat-phone-transactions.png';

const StyledText = styled(Text)`
  width: 100%;
  max-width: 500px;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    max-width: 400px;
  }
`;

const PageTitle = styled(StyledText)`
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    font-size: 20px;
    height: 60px;
    display: block;
  }
`;

const PageContainer = styled(FlexContainer).attrs({ column: true })`
  margin-top: ${({ theme }) => theme.spacing.xxxxlarge};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: ${({ theme }) => theme.spacing.small};
  }
`;

const Device = styled(FlexContainer)`
  width: 100%;
  max-width: 672px;
  margin: auto;
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
`;

const DeviceGradientOverlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 180px;
  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.color.white} 80%,
    ${({ theme }) => transparentize(1, theme.color.white)} 100%
  );

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.color.white} 80%,
      ${({ theme }) => transparentize(1, theme.color.white)} 100%
    );

    height: 120px;
    padding: ${({ theme }) => theme.spacing.xxxxlarge} 0;
  }
`;

// setting 92% width to balance with the transparent pixels in the phone image
const ContinueButton = styled(PrimaryButton)`
  width: 100%;
  margin: ${({ theme }) => theme.spacing.default} auto;
  max-width: 450px;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    max-width: 100%;
  }
`;

const DeviceDesktopScreen = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    display: none;
  }
`;

const DeviceMobileScreen = styled.img`
  width: 100%;
  height: auto;
  display: none;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    display: block;
  }
`;

const CarouselItemText = styled(StyledText)`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.book};

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

type Props = {
  next: () => void;
};

const CAROUSEL_ITEMS = [
  {
    title: 'Start feeling in control of your finances',
    description:
      'See a breakdown of where your money is going. Easily identify subscriptions and other unwanted spend. Plan for your financial goals.',
    desktopImage: screenshotDesktopDeviceUrl,
    mobileImage: screenshotMobileDeviceUrl,
  },
  {
    title: 'Customize your dashboards and charts',
    description:
      'Use our beautiful reports, charts, and visualizations to identify trends in your finances, like how you much you’re saving and spending each month.',
    desktopImage: screenshotDashboardDesktopDeviceUrl,
    mobileImage: screenshotDashboardMobileDeviceUrl,
  },
  {
    title: 'Stay on top of your money',
    description:
      'Get alerts for large cash flows or other activity. Review important transactions.',
    desktopImage: screenshotTransactionsDesktopDeviceUrl,
    mobileImage: screenshotTransactionsMobileDeviceUrl,
  },
];

const OnboardingConnectSingleAccountDone = ({ next }: Props) => {
  const EXPERIMENT = 'ab-test-connect-single-account-before-paywall';

  const { data, isLoadingInitialData } = useQuery(GET_ACCOUNTS_PAGE);

  const { hasAccounts } = data ?? {};

  /*
    I am checking if it is on and off because I want to avoid control.
    If it is off it will skip the page and I don't want to blink any content.
  */
  const isAbTestConnectSingleAccountBeforePaywallOff = useIsFeatureFlagOff(EXPERIMENT, {
    trackImpression: true,
  });

  const isAbTestConnectSingleAccountBeforePaywallOn = useIsFeatureFlagOn(EXPERIMENT);

  /*
    at this stage I don't want to track impressions, but only know if I should display the Sankey chart.
    It tracks impressions on the Onboarding Sankey page
  */
  const isAbTestOnboardingSankeyOn = useIsFeatureFlagOn('ab-test-onboarding-sankey');

  /*
    skips the page when the ab-test-connect-accounts-before-paywall feature flag is off or if the user hasn't connected an account.
    This feature flag is used to let users connect accounts before the paywall. If it is off, that means the user should not see that page.
  */
  useEffect(() => {
    if (isAbTestConnectSingleAccountBeforePaywallOff || (!isLoadingInitialData && !hasAccounts)) {
      next();
    }
  }, [isAbTestConnectSingleAccountBeforePaywallOff, isLoadingInitialData, hasAccounts]);

  // returns the Sankey version if the ab-test-onboarding-sankey feature flag is on
  return isAbTestOnboardingSankeyOn ? (
    <OnboardingConnectAccountSankey next={next} />
  ) : (
    <PageContainer>
      {isAbTestConnectSingleAccountBeforePaywallOn && !isLoadingInitialData && (
        <>
          <OnboardingCarousel>
            {CAROUSEL_ITEMS.map(({ title, description, desktopImage, mobileImage }) => (
              <div key={title}>
                <PageTitle>{title}</PageTitle>
                <Device>
                  <DeviceDesktopScreen src={desktopImage} />
                  <DeviceMobileScreen src={mobileImage} />
                  <DeviceGradientOverlay>
                    <CarouselItemText>{description}</CarouselItemText>
                  </DeviceGradientOverlay>
                </Device>
              </div>
            ))}
          </OnboardingCarousel>
        </>
      )}

      <ContinueButton size="large" type="button" onClick={next}>
        {COPY.ONBOARDING.SPENDING_INSIGHTS.BUTTON}
      </ContinueButton>
    </PageContainer>
  );
};

export default OnboardingConnectSingleAccountDone;
