import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import Checkbox from 'components/lib/form/Checkbox';
import CurrencyField from 'components/lib/form/CurrencyField';
import Form from 'components/lib/form/Form';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import Toggle from 'components/lib/ui/Toggle';

import useCreateGoalAccountAllocation from 'common/lib/hooks/goalsV2/useCreateGoalAccountAllocation';
import useDeleteGoalAccountAllocation from 'common/lib/hooks/goalsV2/useDeleteGoalAccountAllocation';
import useUpdateGoalAccountAllocation from 'common/lib/hooks/goalsV2/useUpdateGoalAccountAllocation';
import { formatCurrency } from 'common/utils/Currency';
import { pluralizeArray } from 'common/utils/String';

import type {
  GoalAccountAllocationRowFieldsFragment,
  AllocationFieldsFragment,
} from 'common/generated/graphql';

const ACCOUNT_LOGO_SIZE_PT = 24;

const StyledAccountLogo = styled(AccountLogo)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const Root = styled(FlexContainer).attrs({ column: true })<{ $isHidden: boolean }>`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.default};
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  margin-bottom: ${({ theme }) => theme.spacing.default};
  border-radius: ${({ theme }) => theme.radius.small};
  color: ${({ theme, $isHidden }) => ($isHidden ? theme.color.textLight : theme.color.text)};
`;

const Header = styled(FlexContainer).attrs({ full: true, alignCenter: true })``;

const TitleContainer = styled(FlexContainer).attrs({ column: true })``;

const Title = styled(Text).attrs({ weight: 'medium' })`
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
`;

const Subtitle = styled(Text).attrs({ size: 'small' })``;

const StyledToggle = styled(Toggle)`
  margin-left: auto;
`;

const StyledForm = styled(Form)`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small};
`;

type Props = {
  goalId: string;
  account: GoalAccountAllocationRowFieldsFragment;
  isDebtGoal: boolean;
  allocation?: AllocationFieldsFragment;
  className?: string;
};

const GoalAccountAllocationRow = ({
  goalId,
  account,
  isDebtGoal,
  allocation,
  className,
}: Props) => {
  const isOn = Boolean(allocation);
  const { id: accountId, goalAllocations, availableBalanceForGoals, displayBalance } = account;
  const { useEntireAccountBalance, currentAmount } = allocation ?? {};

  const availableAmount = availableBalanceForGoals;

  const otherGoalAllocations = useMemo(
    () => goalAllocations.filter(({ goal }) => goal.id !== goalId),
    [goalAllocations, goalId],
  );

  const otherUseEntireBalanceAllocation = useMemo(
    () => otherGoalAllocations.find(({ useEntireAccountBalance }) => useEntireAccountBalance),
    [otherGoalAllocations],
  );
  const amountSumForOtherGoals = useMemo(
    () => R.sum(otherGoalAllocations.map(({ currentAmount }) => currentAmount ?? 0)),
    [otherGoalAllocations],
  );
  const showSwitch = !otherUseEntireBalanceAllocation;
  const hasAllocationsForOtherGoals = !!otherGoalAllocations.length;

  const [createAllocation] = useCreateGoalAccountAllocation();
  const [updateAllocation] = useUpdateGoalAccountAllocation();
  const [deleteAllocation] = useDeleteGoalAccountAllocation();

  const isHidden = account.hideFromList || !!account.deactivatedAt;

  return (
    <Root className={className} $isHidden={isHidden}>
      <Header full alignCenter>
        <StyledAccountLogo
          logoUrl={account.logoUrl}
          icon={account.icon}
          size={ACCOUNT_LOGO_SIZE_PT}
        />
        <TitleContainer>
          <StyledFlex>
            <Title>{account.displayName}</Title>
            {isHidden && <Icon name="eye-off" size={14} />}
          </StyledFlex>
          {isDebtGoal ? (
            <Subtitle>{formatCurrency(displayBalance)} current balance</Subtitle>
          ) : (
            <Subtitle>
              {otherUseEntireBalanceAllocation
                ? `Saved for ${otherUseEntireBalanceAllocation.goal.name}`
                : `${formatCurrency(availableAmount)} ${account.isAsset ? 'available' : ''}`}
            </Subtitle>
          )}
        </TitleContainer>
        {showSwitch && (
          <StyledToggle
            checked={isOn}
            onChange={({ target: { checked } }) => {
              if (checked) {
                createAllocation({
                  goalId,
                  accountId,
                  useEntireAccountBalance: !hasAllocationsForOtherGoals,
                  amount: hasAllocationsForOtherGoals ? availableAmount : null,
                });
              } else {
                deleteAllocation({
                  goalId,
                  accountId,
                });
              }
            }}
          />
        )}
      </Header>
      {isOn && !isDebtGoal && (
        <StyledForm
          initialValues={{
            amount: currentAmount ?? 0,
          }}
          submitOnBlur
          onSubmit={({ amount }) => {
            allocation &&
              updateAllocation({
                id: allocation.id,
                goalId,
                accountId,
                amount: amount ?? 0,
                availableBalanceForGoals,
              });
          }}
        >
          <CurrencyField
            name="amount"
            hideLabel
            disabled={useEntireAccountBalance}
            maskOptions={{ allowDecimal: true }}
          />
          {
            // Only show "Use entire balance" checkbox if no other goals use this account already
            hasAllocationsForOtherGoals ? (
              <Text weight="medium" size="small">
                {formatCurrency(amountSumForOtherGoals)} saved for{' '}
                {pluralizeArray(otherGoalAllocations.map(({ goal }) => goal.name))}
              </Text>
            ) : (
              <Checkbox
                checked={useEntireAccountBalance ?? false}
                onChange={({ target: { checked } }) => {
                  allocation &&
                    updateAllocation({
                      id: allocation.id,
                      goalId,
                      accountId,
                      amount: checked ? null : 0,
                      useEntireAccountBalance: checked,
                      availableBalanceForGoals,
                    });
                }}
              >
                <Text size="small">Always use the entire account balance</Text>
              </Checkbox>
            )
          }
        </StyledForm>
      )}
    </Root>
  );
};

export default GoalAccountAllocationRow;
