import React from 'react';
import styled from 'styled-components';

import RankGoalsList from 'components/goalsV2/RankGoalsList';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { useModalContext } from 'lib/contexts/ModalContext';

import * as COPY from 'common/constants/copy';

const Container = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacing.xlarge};
  width: 100%;
`;

const RankGoalsModal = () => {
  const { close } = useModalContext();

  return (
    <ModalCard
      title={COPY.GOALS.RANK_GOALS.TITLE}
      description={COPY.GOALS.RANK_GOALS.SUBTITLE}
      hideBottomBorder
    >
      <Container>
        <RankGoalsList />
      </Container>

      <CardFooter>
        <PrimaryButton onClick={close}>Done</PrimaryButton>
      </CardFooter>
    </ModalCard>
  );
};

export default RankGoalsModal;
