import { useQuery, gql } from '@apollo/client';
import React from 'react';

import DefaultButton from 'components/lib/ui/button/DefaultButton';
import MerchantLogo from 'components/merchants/MerchantLogo';
import FilteredCashFlowPage from 'components/routes/FilteredCashFlowPage';
import EditMerchantModalBody from 'components/settings/merchants/EditMerchantModal';

import useModal from 'lib/hooks/useModal';

import type routes from 'constants/routes';

import type { GetMerchantDetails } from 'common/generated/graphQlTypes/GetMerchantDetails';
import type RouteProps from 'types/RouteProps';
import type { TransactionFilters } from 'types/filters';

const MERCHANT_LOGO_SIZE_PX = 32;

const MerchantDetails = ({
  match: {
    params: { id },
  },
}: RouteProps<typeof routes.merchants>) => {
  // Add categories as undefined to prevent applying filters from other pages here
  const transactionFilters: Partial<TransactionFilters> = {
    merchants: [id],
    categories: undefined,
  };

  const [EditMerchantModal, { open: openEditMerchantModal, close: closeEditMerchantModal }] =
    useModal();

  const { data } = useQuery<GetMerchantDetails>(QUERY, {
    variables: { id },
  });

  const { name, logoUrl } = data?.merchant ?? {};

  return (
    <>
      <FilteredCashFlowPage
        title={name ?? ''}
        icon={<MerchantLogo url={logoUrl} size={MERCHANT_LOGO_SIZE_PX} />}
        transactionFilters={transactionFilters}
        additionalControls={
          <DefaultButton onClick={openEditMerchantModal}>Edit merchant</DefaultButton>
        }
      />

      <EditMerchantModal onClose={closeEditMerchantModal}>
        <EditMerchantModalBody merchantId={id} />
      </EditMerchantModal>
    </>
  );
};

const QUERY = gql`
  query GetMerchantDetails($id: ID!) {
    merchant(id: $id) {
      id
      name
      logoUrl
    }
  }
`;

export default MerchantDetails;
