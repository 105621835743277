import React, { useMemo } from 'react';
import { ReferenceLine } from 'recharts';
import styled from 'styled-components';

import InvestmentsPerformanceChartTooltip from 'components/investments/InvestmentsPerformanceChartTooltip';
import MultipleLineChart from 'components/lib/charts/MultipleLineChart';

import { isoDateToMonthAbbreviationAndDay } from 'common/utils/date';
import useMeasure from 'lib/hooks/useMeasure';
import useTheme from 'lib/hooks/useTheme';
import { getHistoricalChartTooltipData } from 'lib/investments/adapters';

import type { HistoricalGraphDataPoint } from 'types/investments';

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const HeaderRoot = styled.div`
  margin-top: ${({ theme }) => theme.spacing.default};
  width: 100%;
`;

const ChartRoot = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.spacing.default};
  padding: 0 ${({ theme }) => theme.spacing.default};
  width: 100%;
`;

type Props = {
  securityId: GraphQlUUID;
  securityTicker?: string;
  securityName?: string | null;
  dataKey: string;
  data: HistoricalGraphDataPoint[];
  isLoading: boolean;
  emptyComponent?: React.ReactNode;
  emptyData?: HistoricalGraphDataPoint[];
};

const HoldingValueChart = ({
  data: _data,
  securityId,
  securityTicker,
  securityName,
  dataKey,
  emptyComponent,
  emptyData,
  isLoading,
}: Props) => {
  /**
   * If data or width change too quickly upon initial render, the rechart animation fails.
   * To fix this, we need to memoize the data.
   */
  const dataString = JSON.stringify(_data);
  const data = useMemo(() => _data, [dataString]);
  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();
  const theme = useTheme();
  const padding = 2 * parseInt(theme.spacing.default, 10);

  const showEmptyState = !isLoading && data.length === 0;

  return (
    <Root>
      <HeaderRoot />
      <ChartRoot ref={ref}>
        <MultipleLineChart
          hideActiveDot={showEmptyState}
          width={width - padding / 2}
          height={288}
          xDataKey="date"
          yDataKeys={[dataKey]}
          lineOptions={[
            { lineColor: showEmptyState ? 'rgba(0,0,0,0)' : theme.color.text, hasArea: false },
          ]}
          formatXAxis={isoDateToMonthAbbreviationAndDay}
          formatYAxis={(val) => `${val}%`}
          isAnimationActive
          isLoadingNewData={isLoading}
          // @ts-ignore
          data={showEmptyState ? emptyData : data}
          referenceLine={<ReferenceLine y={0} position="start" stroke={theme.color.gray} />}
          tooltipComponent={({ active, payload }) => {
            if (showEmptyState) {
              return null;
            }
            const tooltipData = getHistoricalChartTooltipData(
              [
                {
                  id: securityId,
                  name: securityName ?? '',
                  ticker: securityTicker ?? null,
                  isBenchmark: false,
                  color: theme.color.orange,
                  dataKey,
                },
              ],
              data,
              active,
              payload,
            );
            return tooltipData ? (
              <InvestmentsPerformanceChartTooltip
                active={active}
                header={tooltipData.header}
                data={tooltipData.data}
              />
            ) : null;
          }}
        />
        {showEmptyState && emptyComponent}
      </ChartRoot>
    </Root>
  );
};

export default HoldingValueChart;
