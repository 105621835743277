import React from 'react';

import RouteLink from 'components/lib/ui/link/RouteLink';

import routes from 'constants/routes';

const MerchantLink = ({
  id,
  queryParams,
  children,
}: {
  id: string;
  queryParams: Record<string, unknown>;
  children: React.ReactNode;
}) => (
  <RouteLink to={routes.merchants({ id, queryParams })} stealthy>
    {children}
  </RouteLink>
);

export default MerchantLink;
