import { gql } from '@apollo/client';
import React from 'react';

import MessageListGroupBase, { AVATAR_SIZE_PX } from 'components/assistant/MessageListGroupBase';
import Avatar from 'components/lib/ui/Avatar';

import type { UserMessageListGroupFields } from 'common/generated/graphQlTypes/UserMessageListGroupFields';

type Props = {
  messages: UserMessageListGroupFields[];
  demo?: boolean;
  className?: string;
};

const UserMessageListGroup = ({ messages, demo, className }: Props) => {
  const [{ createdAt, user }] = messages;

  return (
    <MessageListGroupBase
      avatar={<Avatar $url={user.profilePictureUrl} $size={AVATAR_SIZE_PX} />}
      name={user.name}
      timestamp={createdAt}
      messages={messages}
      demo={demo}
      className={className}
    />
  );
};
UserMessageListGroup.fragments = {
  UserMessageListGroupFields: gql`
    fragment UserMessageListGroupFields on UserMessage {
      id
      threadId
      createdAt
      content
      user {
        id
        name
        profilePictureUrl
      }
    }
  `,
};

export default UserMessageListGroup;
