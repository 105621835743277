import styled, { keyframes } from 'styled-components';

const POP_IN = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
`;

const AnimatedPopInOverlay = styled.div`
  animation: 150ms cubic-bezier(0, 0.63, 0.63, 1.32) ${POP_IN};

  [data-popper-placement^='right'] & {
    transform-origin: center left;
  }
  [data-popper-placement^='left'] & {
    transform-origin: center right;
  }
  [data-popper-placement^='bottom'] & {
    transform-origin: top center;
  }
  [data-popper-placement^='top'] & {
    transform-origin: bottom center;
  }
`;

export default AnimatedPopInOverlay;
