import * as React from 'react';
import styled from 'styled-components';

import BalanceDiffIndicator from 'components/accounts/BalanceDiffIndicator';
import ChartTooltip, { TooltipHeader, TooltipValue } from 'components/lib/charts/ChartTooltip';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { formatCurrency } from 'common/utils/Currency';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';

const StyledChartTooltip = styled(ChartTooltip)`
  width: 280px;

  ${TooltipHeader} {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
  ${TooltipValue} {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const Label = styled.div``;

const Value = styled.div``;

const Diff = styled(BalanceDiffIndicator)`
  margin-top: -${({ theme }) => theme.spacing.xxsmall};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const Percentage = styled.span`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  date: string;
  value: number;
  label: string;
  active: boolean;
  isAsset?: boolean;
  comparisonData: {
    initialDate: string;
    activeDate: string | undefined;
    diff: number;
    percentageChange: number | null;
  };
};

const BigDateValueTooltip = ({ date, value, label, active, isAsset, comparisonData }: Props) => (
  <StyledChartTooltip
    header={`${isoDateToAbbreviatedMonthDayAndYear(
      comparisonData.initialDate,
    )} - ${isoDateToAbbreviatedMonthDayAndYear(date)}`}
    active={active}
  >
    <FlexContainer justifyBetween>
      <Label>{label}</Label>
      <FlexContainer column alignEnd>
        <Value>{formatCurrency(value)}</Value>
        <FlexContainer>
          <Diff
            inverted
            balance={value}
            previousBalance={value - comparisonData.diff}
            isLiability={!isAsset}
          >
            <Percentage>
              {comparisonData.percentageChange
                ? `(${comparisonData.percentageChange.toFixed(2)}%)`
                : ''}
            </Percentage>
          </Diff>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  </StyledChartTooltip>
);

export default BigDateValueTooltip;
