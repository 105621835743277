import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import MonarchLogo from 'components/lib/ui/MonarchLogo';

export type Props = {
  children: React.ReactNode;
  controls?: React.ReactNode;
};

const MAX_WIDTH_PX = 400;
const LOGO_WIDTH_PX = 153;

const Backdrop = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.xxlarge};
  padding-bottom: ${({ theme }) => theme.spacing.xxxlarge};

  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${MAX_WIDTH_PX}px;

  height: 100%;
  max-height: 800px;
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const Controls = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
`;

const LogoContainer = styled.div`
  width: ${LOGO_WIDTH_PX}px;
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
`;

const Logo = styled(MonarchLogo)`
  max-width: 100%;
`;

// This takes over the whole page and blocks the content under it. It's not quite a modal since
// it takes over the whole page.
// Generally you would use this on mobile for a blocking message that doesn't need it's own route.
const BlockingPage = ({ children, controls }: Props) => (
  <Backdrop>
    <Root>
      <LogoContainer>
        <Logo dark />
      </LogoContainer>
      <Content>{children}</Content>
      <Controls>{controls}</Controls>
    </Root>
  </Backdrop>
);

export default BlockingPage;
