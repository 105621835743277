import { useMutation } from '@apollo/client';
import { Buffer } from 'buffer';
import * as RA from 'ramda-adjunct';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import { clearOAuthStateString } from 'actions';
import { FIX_COINBASE_CREDENTIAL_MUTATION } from 'common/lib/graphQl/credentials';
import { getCoinbaseOAuthRedirectUri } from 'lib/external/coinbase';
import { useDispatch, useQueryParam } from 'lib/hooks';
import type { CoinbaseOAuthFlowState } from 'lib/hooks/accounts/useCoinbaseOAuthFlow';
import useToast from 'lib/hooks/useToast';
import { onOpenReportEmail } from 'lib/support';
import { getOAuthStateString } from 'selectors';

import routes from 'constants/routes';

const StyledFlexContainer = styled(FlexContainer)`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.grayBackground};
`;

const CoinbaseOAuth = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { openToast, openErrorToast } = useToast();

  const returnedCode = useQueryParam('code');
  const returnedOAuthStateString = useQueryParam('state');
  const currentOAuthStateString = useSelector(getOAuthStateString);

  const [fixCoinbaseCredential] = useMutation(FIX_COINBASE_CREDENTIAL_MUTATION, {
    refetchQueries: ['Web_GetInstitutionSettings'],
  });

  const clearQueryParams = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  const handleNewCoinbaseConnection = async (returnedCode: string) => {
    // Get the Coinbase connection state
    const state: CoinbaseOAuthFlowState = JSON.parse(
      Buffer.from(currentOAuthStateString ?? '', 'base64').toString(),
    );
    const { credentialId: returnedCredentialId, onSuccessRedirectTo, withParams } = state;

    await dispatch(clearOAuthStateString());
    clearQueryParams();

    // If a valid credential ID was returned, then it means
    // we are reconnecting an existing account, and need to
    // call the fix credential mutation.
    if (returnedCredentialId) {
      const response = await fixCoinbaseCredential({
        variables: {
          input: {
            credentialId: returnedCredentialId,
            redirectUri: getCoinbaseOAuthRedirectUri(),
            code: returnedCode,
          },
        },
      });

      const { credential, errors } = response?.data?.fixCoinbaseCredential || {};

      if (RA.isNotNilOrEmpty(errors)) {
        openErrorToast({
          title: 'Error',
          description: 'Something went wrong. Please try again or submit a ticket.',
          onReport: () => onOpenReportEmail(`Issue reconnecting Coinbase account`),
        });
        return;
      } else {
        openToast({
          title: 'Success',
          description: 'Coinbase account is now reconnected.',
          actions: [
            {
              text: 'Open account',
              onClick: () => {
                history.push(routes.accounts.accountDetails({ id: credential!.accounts[0].id }));
              },
            },
          ],
        });
      }
      if (onSuccessRedirectTo) {
        history.push(onSuccessRedirectTo, withParams);
      } else {
        history.push(routes.accounts.path);
      }
    }
  };

  useEffect(() => {
    if (!returnedCode && !returnedOAuthStateString) {
      history.push(routes.dashboard.path);
    }

    if (returnedCode && returnedOAuthStateString && currentOAuthStateString) {
      if (returnedOAuthStateString === currentOAuthStateString) {
        handleNewCoinbaseConnection(returnedCode!);
      } else {
        throw Error(
          `mismatched coinbase oauth csrf token returned: ${returnedOAuthStateString},` +
            `current: ${currentOAuthStateString}. Blocked!`,
        );
      }
    }
  }, [returnedCode, returnedOAuthStateString, currentOAuthStateString]);

  return (
    <StyledFlexContainer>
      <LoadingSpinner />
    </StyledFlexContainer>
  );
};

export default CoinbaseOAuth;
