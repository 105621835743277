import { useMutation } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ProductLaunchPrompt from 'components/lib/ui/ProductLaunchPrompt';
import Text from 'components/lib/ui/Text';
import ManualLink from 'components/lib/ui/link/ManualLink';

import { UPDATE_USER_PROFILE_MUTATION } from 'common/lib/graphQl/user';
import useCreateMessageThread from 'common/lib/hooks/assistant/useCreateMessageThread';
import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';

import * as COPY from 'common/constants/copy';
import { externalUrls } from 'constants/routes';

const { OPT_IN } = COPY.ASSISTANT;

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 100px ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.default};
  position: absolute;
  overflow: auto;
  background-color: ${({ theme }) =>
    theme.uiTheme === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 40, 100, 0.4)'};
`;

const OptInCard = styled(Card)`
  width: 100%;
  max-height: auto;
  max-width: 540px;
`;

const BetaBadge = styled.div`
  height: 24px;
  line-height: 24px;
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  padding: 0 ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.radius.small};
  background-color: ${({ theme }) => theme.color.blue};
  color: ${({ theme }) => theme.color.textWhite};
`;

const Footer = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.default};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.color.textLight};
`;

type Props = {
  onCreateMessageThread: (threadId: string) => void;
  agentType?: string | null | undefined;
};

const AssistantOptInModal = ({ onCreateMessageThread, agentType }: Props) => {
  const { updateHouseholdPreferences, isLoadingInitialData } = useHouseholdPreferences();

  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE_MUTATION);

  const [createMessageThread, { loading: creatingMessageThread }] = useCreateMessageThread({
    variables: {
      agentType,
    },
  });

  return (
    <Root>
      <OptInCard>
        <ProductLaunchPrompt
          icon={OPT_IN.ICON}
          title={
            <FlexContainer alignCenter>
              <div>{OPT_IN.TITLE}</div>
              <BetaBadge>Beta</BetaBadge>
            </FlexContainer>
          }
          rows={OPT_IN.ROWS}
          button={{
            title: OPT_IN.BUTTON,
            pending: isLoadingInitialData || creatingMessageThread,
            onClick: async () => {
              const { data } = await createMessageThread();
              const threadId = data?.createMessageThread?.messageThread?.id;
              threadId && onCreateMessageThread(threadId);

              await updateUserProfile({
                variables: {
                  updateProfileInput: {
                    aiAssistantOptedIn: true,
                  },
                  updateMeInput: {},
                } as any, // casting just to get around the type error. this component is not used anymore.
              });

              await updateHouseholdPreferences({
                aiAssistantEnabled: true,
              });
            },
          }}
          footer={
            <Footer>
              By clicking the “Confirm” button above, you understand and confirm that your use of
              Monarch AI Assistant (the “Assistant”) is subject to our current{' '}
              <ManualLink target="_blank" href={externalUrls.termsOfUse}>
                Terms of Service
              </ManualLink>{' '}
              and{' '}
              <ManualLink target="_blank" href={externalUrls.privacyPolicy}>
                Privacy Policy
              </ManualLink>
              , and you confirm your understanding of the above.
            </Footer>
          }
        />
      </OptInCard>
    </Root>
  );
};

export default AssistantOptInModal;
