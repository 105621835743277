import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import type {
  UnarchiveGoal,
  UnarchiveGoalVariables,
} from 'common/generated/graphQlTypes/UnarchiveGoal';

/** Hook used to un-archive a goal and update cache optimistically. */
const useUnarchiveGoal = () => {
  const [unarchiveGoalMutation, mutationInfo] = useMutation<UnarchiveGoal, UnarchiveGoalVariables>(
    MUTATION,
  );

  const unarchiveGoal = useCallback(
    (id: string) =>
      unarchiveGoalMutation({
        variables: {
          input: {
            id,
          },
        },
        optimisticResponse: {
          unarchiveGoal: {
            __typename: 'UnarchiveGoal',
            goal: {
              __typename: 'GoalV2',
              id,
              archivedAt: null,
            },
            errors: null,
          },
        },
      }),
    [unarchiveGoalMutation],
  );

  return [unarchiveGoal, mutationInfo] as const;
};

const MUTATION = gql`
  mutation Common_UnarchiveGoal($input: UnarchiveGoalInput!) {
    unarchiveGoal(input: $input) {
      goal {
        id
        archivedAt
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default useUnarchiveGoal;
