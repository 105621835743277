import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo, useState } from 'react';

import HoldingsList from 'components/holdings/HoldingsList';
import HoldingsListLoading from 'components/holdings/HoldingsListLoading';

import groupHoldingsByTypeDisplay from 'common/utils/groupHoldingsByTypeDisplay';
import useMockDataWhenNoAccountsQuery from 'lib/hooks/useMockDataWhenNoAccountsQuery';

import { gql } from 'common/generated/gql';
import type { Web_GetHoldingsQueryHoldingAggregateHolding } from 'common/types/investments';

export type Props = {
  pageSize?: number;
  accountIds: GraphQlUUID[];
  hideHeader?: boolean;
  emptyComponent: React.ReactNode;
  selectedTimePeriod?: { startDate: string; endDate: string };
  onAddHoldingButtonClick?: () => void;
};

const HoldingsListContainer = ({
  accountIds,
  selectedTimePeriod,
  emptyComponent,
  hideHeader,
  onAddHoldingButtonClick,
}: Props) => {
  const { data, isNetworkRequestInFlight: isLoadingHoldings } = useMockDataWhenNoAccountsQuery(
    GET_HOLDING_AGGREGATES,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          accountIds,
          includeHiddenHoldings: true,
          startDate: selectedTimePeriod?.startDate,
          endDate: selectedTimePeriod?.endDate,
          topMoversLimit: 4,
        },
      },
    },
  );

  const [selectedHolding, setSelectedHolding] = useState<
    NonNullable<Web_GetHoldingsQueryHoldingAggregateHolding> | undefined
  >(undefined);
  const { edges = [] } = data?.portfolio.aggregateHoldings ?? {};

  const holdings = useMemo(
    () =>
      RA.compact(edges).map((el) => {
        const { ...nodeFields } = el.node;
        const [firstHolding] = nodeFields.holdings;
        return {
          typeDisplay: firstHolding.typeDisplay!,
          ...nodeFields,
        };
      }),
    [edges],
  );

  const sections = useMemo(() => groupHoldingsByTypeDisplay(holdings), [holdings]);

  const totalValue = useMemo(() => R.sum(R.map((el) => el.totalValue || 0, holdings)), [holdings]);

  return isLoadingHoldings ? (
    <HoldingsListLoading />
  ) : (
    <HoldingsList
      sections={sections}
      totalValue={totalValue}
      onHoldingDrawerClose={() => setSelectedHolding(undefined)}
      selectedHolding={selectedHolding}
      onSelectHolding={setSelectedHolding}
      emptyComponent={emptyComponent}
      hideHeader={hideHeader || holdings.length === 0}
      onAddHoldingButtonClick={onAddHoldingButtonClick}
    />
  );
};

const GET_HOLDING_AGGREGATES = gql(`
  query Web_GetHoldings($input: PortfolioInput) {
    portfolio(input: $input) {
      aggregateHoldings {
        edges {
          node {
            id
            quantity
            basis
            totalValue
            securityPriceChangeDollars
            securityPriceChangePercent
            lastSyncedAt
            holdings {
              id
              type
              typeDisplay
              name
              ticker
              closingPrice
              isManual
              closingPriceUpdatedAt
              costBasis
              quantity
            }
            security {
              id
              name
              type
              ticker
              typeDisplay
              currentPrice
              currentPriceUpdatedAt
              closingPrice
              closingPriceUpdatedAt
              oneDayChangePercent
              oneDayChangeDollars
            }
          }
        }
      }
    }
  }
`);

export default HoldingsListContainer;
