import { gql, useMutation } from '@apollo/client';
import React from 'react';

import NestedDropdownMenu from 'components/lib/ui/NestedDropdownMenu';
import NestedDropdownSubMenu from 'components/lib/ui/NestedDropdownSubMenu';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import type { DeleteAllHouseholdTransactionTags } from 'common/generated/graphQlTypes/DeleteAllHouseholdTransactionTags';

const EmployeeMenuTags = () => {
  const [deleteHouseholdTags] = useMutation<DeleteAllHouseholdTransactionTags>(
    DELETE_ALL_HOUSEHOLD_TAGS,
    {
      refetchQueries: ['GetHouseholdTransactionTags'],
    },
  );

  return (
    <NestedDropdownSubMenu title="Tags">
      <NestedDropdownMenu
        items={[
          {
            title: 'Delete all household tags',
            type: 'danger',
            onClick: deleteHouseholdTags,
          },
        ]}
      />
    </NestedDropdownSubMenu>
  );
};

const DELETE_ALL_HOUSEHOLD_TAGS = gql`
  mutation Web_DeleteAllHouseholdTransactionTags {
    deleteAllHouseholdTransactionTags {
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default EmployeeMenuTags;
