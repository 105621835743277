import { gql } from 'common/generated/gql';

export const SEARCH_VEHICLES_QUERY = gql(`
  query VehiclesSearch($search: String!, $limit: Int) {
    vehicles(search: $search, limit: $limit) {
      vin
      name
      value
    }
  }
`);
