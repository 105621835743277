import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import readFragmentOrNull from 'common/lib/graphQl/readFragmentOrNull';
import { GOAL_CACHE_FRAGMENT } from 'common/lib/hooks/goalsV2/useCreateGoalAccountAllocation';

import type {
  DeleteGoalAccountAllocation,
  DeleteGoalAccountAllocationVariables,
} from 'common/generated/graphQlTypes/DeleteGoalAccountAllocation';
import type { GoalAllocationCacheFields } from 'common/generated/graphQlTypes/GoalAllocationCacheFields';
import type { DeleteGoalAccountAllocationInput } from 'common/generated/graphQlTypes/globalTypes';

/**
 * Hook used to delete a goal account allocation and update the cache optimistically.
 */
const useDeleteGoalAccountAllocation = () => {
  const [deleteAllocationMutation, mutationInfo] = useMutation<
    DeleteGoalAccountAllocation,
    DeleteGoalAccountAllocationVariables
  >(MUTATION);

  const deleteAllocation = useCallback(
    async (input: DeleteGoalAccountAllocationInput) => {
      const { goalId, accountId } = input;

      const response = await deleteAllocationMutation({
        variables: {
          input,
        },
        optimisticResponse: {
          deleteGoalAccountAllocation: {
            __typename: 'DeleteGoalAccountAllocation',
            goal: null,
            account: null,
            errors: null,
          },
        },
        update: (cache, { data }) => {
          if (!data || data?.deleteGoalAccountAllocation?.errors) {
            return;
          }

          const cacheId = `GoalV2:${goalId}`;
          const fragmentName = 'GoalAllocationCacheFields';

          const goal = readFragmentOrNull<GoalAllocationCacheFields>(cache, {
            id: cacheId,
            fragment: GOAL_CACHE_FRAGMENT,
            fragmentName,
          });

          if (!goal) {
            return;
          }

          cache.writeFragment({
            id: cacheId,
            fragment: GOAL_CACHE_FRAGMENT,
            fragmentName,
            data: {
              ...goal,
              accountAllocations: goal.accountAllocations.filter(
                ({ account: { id } }) => id !== accountId,
              ),
            },
          });
        },
      });

      return response;
    },
    [deleteAllocationMutation],
  );

  return [deleteAllocation, mutationInfo] as const;
};

const MUTATION = gql`
  mutation Common_DeleteGoalAccountAllocation($input: DeleteGoalAccountAllocationInput!) {
    deleteGoalAccountAllocation(input: $input) {
      goal {
        id
        currentAmount
        completionPercent
      }
      account {
        id
        availableBalanceForGoals
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default useDeleteGoalAccountAllocation;
