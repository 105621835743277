import React from 'react';
import styled from 'styled-components';

import Mark from 'components/brand/Mark';
import FlexContainer from 'components/lib/ui/FlexContainer';

import useTheme from 'lib/hooks/useTheme';

const Root = styled(FlexContainer).attrs({ center: true })<{
  $size: number;
  $backgroundColor: string;
}>`
  border-radius: ${({ theme }) => theme.radius.medium};
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

type Props = {
  backgroundColor?: string;
  color?: string;
  size?: number;
  padding?: number;
  className?: string;
};

const MarkSquare = ({ backgroundColor, color, className, size = 56, padding = 8 }: Props) => {
  const theme = useTheme();
  return (
    <Root
      className={className}
      $size={size}
      $backgroundColor={backgroundColor ?? theme.color.orange}
    >
      <Mark color={color ?? theme.color.white} size={size - padding * 2} />
    </Root>
  );
};

export default MarkSquare;
