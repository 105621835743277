import React from 'react';

import Tooltip from 'components/lib/ui/Tooltip';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { copyToClipboard } from 'utils/clipboard';

type Props = {
  messageToCopy: string;
};

const HIDE_COPIED_TOOLTIP_DELAY_MS = 300;

const CopyToClipboardButton = ({ messageToCopy }: Props) => (
  <Tooltip
    event="click"
    content="Copied!"
    eventOff="mouseleave"
    delayHide={HIDE_COPIED_TOOLTIP_DELAY_MS}
    afterShow={() => {
      copyToClipboard(messageToCopy);
    }}
  >
    <DefaultButton>Copy text to clipboard</DefaultButton>
  </Tooltip>
);

export default CopyToClipboardButton;
