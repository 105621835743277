import { DateTime } from 'luxon';

import type { BalanceDataPoint } from 'components/accounts/CopyBalancePreviewGraph';

import { isSameDay } from 'common/utils/date';

import type { Web_PreviewCopySnapshotsQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

type Snapshot = ElementOf<Web_PreviewCopySnapshotsQuery, 'previewCopySnapshots'>;

export type CopySnapshotsFormValues = {
  cutoverDate: string;
  toAccountId: string;
};

export const snapshotsToPreviewBalanceDataPoints = (
  snapshots: Snapshot[],
  cutoverDateIso: string,
): BalanceDataPoint[] => {
  const cutoverDate = DateTime.fromISO(cutoverDateIso);
  const snapshotToPreviewDataPointConverter = snapshotToPreviewDataPoint(cutoverDate);
  return snapshots.map(snapshotToPreviewDataPointConverter);
};

const snapshotToPreviewDataPoint =
  (cutoverDate: DateTime) =>
  (snapshot: Snapshot): BalanceDataPoint => {
    const { date, signedBalance } = snapshot;
    const balanceDate = DateTime.fromISO(date);
    const isBeforeCutover = cutoverDate < balanceDate;

    if (isBeforeCutover) {
      return {
        date,
        balance: signedBalance,
      };
    }

    return {
      date,
      previewBalance: signedBalance,
      ...(isSameDay(cutoverDate, balanceDate) ? { balance: signedBalance } : {}),
    };
  };
