import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';

import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';

import * as COPY from 'common/constants/copy';

const Root = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  max-width: 555px;
  width: 100%;
  align-items: stretch;
`;

const TitleTimelineIcon = styled(Icon).attrs(({ name }) => ({
  size: 32,
  name,
}))`
  color: ${({ theme }) => theme.color.white};
  position: absolute;
  left: 8px;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    font-size: 24px;
    line-height: 24px;
    width: 24px;
    height: 24px;
  }
`;

const TimelineDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: ${({ theme }) => theme.spacing.large};
`;

const TimelineDescriptionItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 40px;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
  }
`;

const TimelineTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.text};

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    font-size: ${({ theme }) => theme.fontSize.base};
  }
`;

const TimelineText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  color: ${({ theme }) => theme.color.text};

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const Timeline = styled.div`
  width: 48px;
  min-width: 48px;
  border-radius: ${({ theme }) => theme.radius.pill};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing.large};
  margin-right: ${({ theme }) => theme.spacing.xlarge};
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.color.redDark} 37%,
    ${({ theme }) => theme.color.orangeDark} 60%,
    ${({ theme }) => theme.color.orangeDark} 85%,
    ${({ theme }) => theme.color.white} 93%
  );

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    width: 36px;
    min-width: 36px;
  }
`;

type Props = {
  trialDays: number;
  trialEndDate: string;
};

const PremiumPlanTimeline = ({ trialDays, trialEndDate }: Props) => {
  const isGoalOrientedSignUpTestOn = useIsFeatureFlagOn('ab-test-goal-oriented-signup', {
    trackImpression: true,
  });

  return isGoalOrientedSignUpTestOn ? (
    <Root>
      <Timeline />
      <TimelineDescription>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="unlock" />
            Today
          </TimelineTitle>
          <TimelineText>Start your free trial and see all your finances in one place.</TimelineText>
        </TimelineDescriptionItem>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="sun" />
            Within a week
          </TimelineTitle>
          <TimelineText>
            You’ll begin experiencing clarity, confidence, and peace of mind in managing your money.
          </TimelineText>
        </TimelineDescriptionItem>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="bell" />
            Every Day
          </TimelineTitle>
          <TimelineText>
            You can keep track of your remaining trial days right on your dashboard. You can cancel
            anytime.
          </TimelineText>
        </TimelineDescriptionItem>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="award" />
            {COPY.PREMIUM.TIMELINE.PROGRESS.IN_X_DAYS.TITLE(trialDays)}
          </TimelineTitle>
          <TimelineText>
            {COPY.PREMIUM.TIMELINE.PROGRESS.IN_X_DAYS.DESCRIPTION(trialEndDate)}
          </TimelineText>
        </TimelineDescriptionItem>
      </TimelineDescription>
    </Root>
  ) : (
    <Root>
      <Timeline />
      <TimelineDescription>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="unlock" />
            {COPY.PREMIUM.TIMELINE.PROGRESS.TODAY.TITLE}
          </TimelineTitle>
          <TimelineText>{COPY.PREMIUM.TIMELINE.PROGRESS.TODAY.DESCRIPTION}</TimelineText>
        </TimelineDescriptionItem>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="bell" />
            {COPY.PREMIUM.TIMELINE.PROGRESS.IN_X_DAYS.TITLE(trialDays - 1)}
          </TimelineTitle>
          <TimelineText>{COPY.PREMIUM.TIMELINE.PROGRESS.BEFORE_LAST_DAY.DESCRIPTION}</TimelineText>
        </TimelineDescriptionItem>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="award" />
            {COPY.PREMIUM.TIMELINE.PROGRESS.IN_X_DAYS.TITLE(trialDays)}
          </TimelineTitle>
          <TimelineText>
            {COPY.PREMIUM.TIMELINE.PROGRESS.IN_X_DAYS.DESCRIPTION(trialEndDate)}
          </TimelineText>
        </TimelineDescriptionItem>
      </TimelineDescription>
    </Root>
  );
};

export default PremiumPlanTimeline;
