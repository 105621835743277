import { DateTime, Interval } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { togglePlanGroupCollapsed, toggleUnplannedExpandedForGroup } from 'actions';
import { ensureEnumValue } from 'common/utils/Enum';
import type { UpdateQueryParamOptions } from 'lib/hooks/useQueryParams';
import { useUpdatableQueryParam } from 'lib/hooks/useQueryParams';
import { getDoesNotHaveAccounts } from 'state/emptyState/selectors';

export enum PlanTimeframe {
  /** "Budget" view */
  SingleMonth = 'month',
  /** Grid view by month */
  Monthly = 'monthly',
  /** Grid view by year */
  Yearly = 'yearly',
}

const DEFAULT_TIMEFRAME = PlanTimeframe.SingleMonth;
const YEARLY_COLUMNS_COUNT = 10;

/** Encompasses all state used in Plan, whether it be stored in redux, useState, or a query param. */
export type PlanState = {
  doesNotHaveAccounts: boolean;
  thisMonth: DateTime;
  thisYear: DateTime;
  columnDisplayInterval: Interval;
  timeframe: PlanTimeframe;
  highlightedDate: DateTime;
};

/** Methods used to mutate PlanState */
export type PlanActions = {
  toggleIsGroupCollapsed: (groupId: string) => void;
  toggleIsUnplannedExpandedForGroup: (groupId: string) => void;
  setTimeframe: (timeframe: PlanTimeframe, options?: UpdateQueryParamOptions) => void;
  setHighlightedDate: (date: DateTime) => void;
  moveHighlightedDate: (forward: boolean) => void;
};

/**
 * This hook handles all of the state and actions for the Plan page. It abstracts
 * away the different ways we store state (redux, useState, query param) so the UI
 * doesn't have to worry about it.
 */
const usePlanState = (): [PlanState, PlanActions] => {
  const dispatch = useDispatch();

  // State
  const doesNotHaveAccounts = useSelector(getDoesNotHaveAccounts);
  const thisMonth = useMemo(() => DateTime.local().startOf('month'), []);
  const thisYear = useMemo(() => DateTime.local().startOf('year'), []);
  const [timeframeQueryParam, setTimeframe] = useUpdatableQueryParam('timeframe');
  const timeframe = ensureEnumValue(PlanTimeframe, timeframeQueryParam, DEFAULT_TIMEFRAME);

  const [dateQueryParam, setDateQueryParam] = useUpdatableQueryParam('date');
  const parsedDateQueryParam = useMemo(
    () => DateTime.fromISO(dateQueryParam ?? ''),
    [dateQueryParam],
  );
  const highlightedDate = parsedDateQueryParam.isValid ? parsedDateQueryParam : thisMonth;

  const columnDisplayInterval = useMemo(() => {
    if (timeframe === PlanTimeframe.Monthly) {
      // Year start - year end
      return Interval.fromDateTimes(highlightedDate.startOf('year'), highlightedDate.endOf('year'));
    } else if (timeframe === PlanTimeframe.Yearly) {
      // Year start - 10 years
      return Interval.fromDateTimes(
        highlightedDate.startOf('year'),
        highlightedDate.plus({ years: YEARLY_COLUMNS_COUNT - 1 }).endOf('year'),
      );
    } else {
      // Single month
      return Interval.fromDateTimes(highlightedDate, highlightedDate.endOf('month'));
    }
  }, [highlightedDate, timeframe]);

  const state: PlanState = {
    doesNotHaveAccounts,
    thisMonth,
    thisYear,
    columnDisplayInterval,
    timeframe,
    highlightedDate,
  };

  // Actions
  const toggleIsGroupCollapsed = useCallback(
    (groupId: string) => dispatch(togglePlanGroupCollapsed(groupId)),
    [dispatch],
  );
  const toggleIsUnplannedExpandedForGroup = useCallback(
    (groupId: string) => dispatch(toggleUnplannedExpandedForGroup(groupId)),
    [dispatch],
  );
  const setHighlightedDate = useCallback(
    (date: DateTime) => setDateQueryParam(date.toISODate()),
    [setDateQueryParam],
  );
  const moveHighlightedDate = useCallback(
    (forward: boolean) => {
      const months = Math.round(columnDisplayInterval.length('months'));
      const diff = timeframe === PlanTimeframe.Yearly ? { years: 1 } : { months };
      const newDate = forward ? highlightedDate.plus(diff) : highlightedDate.minus(diff);
      setHighlightedDate(newDate);
    },
    [columnDisplayInterval, highlightedDate, setHighlightedDate, timeframe],
  );

  const actions: PlanActions = {
    toggleIsGroupCollapsed,
    toggleIsUnplannedExpandedForGroup,
    setTimeframe,
    setHighlightedDate,
    moveHighlightedDate,
  };

  return [state, actions];
};

export default usePlanState;
