import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

export const ButtonGroup = styled(FlexContainer)`
  ${DefaultButton} {
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    :first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    :last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    :not(:first-child) {
      margin-left: -1px;
    }
  }
`;
