import * as React from 'react';
import styled from 'styled-components';

import ExternalLink from 'components/lib/ui/Link';
import WindowBanner from 'components/lib/ui/WindowBanner';

import useAppStatus from 'common/lib/hooks/useAppStatus';

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const MessageText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LearnMoreLink = styled(ExternalLink)`
  margin-left: 5px;
  text-decoration: underline;
  min-width: 40px;
  text-align: left;
`;

const OutageBanner = () => {
  const { shouldShowStatus, loading, link, message } = useAppStatus();
  return !loading && shouldShowStatus ? (
    <WindowBanner variant="status">
      <MessageContainer>
        <MessageText>{message}</MessageText>
        &nbsp;Learn more on our
        <LearnMoreLink href={link} target="_blank">
          status
        </LearnMoreLink>
        &nbsp;page.
      </MessageContainer>
    </WindowBanner>
  ) : (
    <></>
  );
};

export default styled(OutageBanner)``;
