import * as RA from 'ramda-adjunct';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import Emoji from 'components/lib/ui/Emoji';
import Modal from 'components/lib/ui/Modal';
import { toast as customToast } from 'components/lib/ui/toast/Toast';
import PremiumUpgradeFlow from 'components/premium/PremiumUpgradeFlow';
import TransactionRuleModal from 'components/transactions/TransactionRuleModal';

import useGetCategory from 'common/lib/hooks/categories/useGetCategory';
import useFeatureEntitlementLimit from 'common/lib/hooks/premium/useFeatureEntitlementLimit';
import useTrialStatusQuery from 'common/lib/hooks/premium/useTrialStatusQuery';
import useToggle from 'common/lib/hooks/useToggle';
import { StringOperator } from 'common/lib/transactions/Rules';
import { isExpense } from 'common/utils/formatTransactionAmount';
import TransactionRuleToastContext from 'lib/contexts/TransactionRuleToastContext';
import { useTransactionListContext } from 'lib/contexts/TransactionsListContext';
import useModal from 'lib/hooks/useModal';
import toast from 'lib/ui/toast';

import * as COPY from 'common/constants/copy';
import { getLimitPopoverCopy, ProductFeatureLimit } from 'common/constants/premium';

import { SplitAmountType } from 'common/generated/graphql';
import type { UpdateTransactionRuleInput } from 'common/generated/graphql';
import type { TransactionRulePrefillValues } from 'types/rules';

const Yellow = styled.span`
  color: ${({ theme }) => theme.color.yellow};
`;

const TransactionRuleToastTrigger = ({ children }: { children: React.ReactNode }) => {
  const { refetchAll, currentRuleCount } = useTransactionListContext();

  const [createRuleValues, setCreateRuleValues] = useState<TransactionRulePrefillValues>();
  const [showCreateModal, { setOn: openCreateModal, setOff: closeCreateModal }] = useToggle(false);

  const { getCategory } = useGetCategory(undefined, {
    // Use cache-first because this is rendered for each transaction and we want to avoid
    // duplicate network requests.
    fetchPolicy: 'cache-first',
  });

  const [UpgradeModal, { open: openUpgradeModal, close: closeUpgradeModal }] = useModal();
  const { meetsOrExceedsLimit, limit } = useFeatureEntitlementLimit(
    ProductFeatureLimit.TransactionRuleLimit,
    currentRuleCount ?? 0,
  );
  const { hasPremiumTrialAvailable } = useTrialStatusQuery();

  const showToast = useCallback(
    (values: TransactionRulePrefillValues) => {
      setCreateRuleValues(values);

      const { newMerchantName, newCategoryId, newGoalId, newSplits, toastId } = values;
      const newCategory = newCategoryId ? getCategory(newCategoryId) : null;
      const updatedValue = RA.isNotNil(newCategory) ? (
        <React.Fragment>
          <Emoji>{newCategory.icon}</Emoji> {newCategory.name}
        </React.Fragment>
      ) : (
        newMerchantName ?? newGoalId
      );

      let toastTitle;
      if (newGoalId) {
        toastTitle = <span>Linked to your goal</span>;
      } else if (newSplits) {
        toastTitle = <span>{newSplits.length} split transactions created</span>;
      } else {
        toastTitle = <span>Updated to {updatedValue}</span>;
      }

      if (meetsOrExceedsLimit) {
        // User has exceeded their rule limit, so show the upsell toast
        toast({
          title: toastTitle,
          description: getLimitPopoverCopy(
            ProductFeatureLimit.TransactionRuleLimit,
            limit ?? 0,
            currentRuleCount ?? 0,
          ).description,
          actions: [
            {
              onClick: openUpgradeModal,
              text: (
                <Yellow>
                  {hasPremiumTrialAvailable
                    ? COPY.PREMIUM.UPGRADE_CTA.TRIAL_AVAILABLE
                    : COPY.PREMIUM.UPGRADE_CTA.TRIAL_UNAVAILABLE}
                </Yellow>
              ),
            },
          ],
          ...(toastId ? { id: toastId } : {}),
        });
      } else {
        // Show the normal toast
        customToast({
          title: toastTitle,
          description: 'Create a rule to do this automatically in the future.',
          actions: [{ onClick: () => openCreateModal(), text: 'Create Rule' }],
          ...(toastId ? { id: toastId } : {}),
        });
      }
    },
    [
      openCreateModal,
      getCategory,
      meetsOrExceedsLimit,
      openUpgradeModal,
      limit,
      hasPremiumTrialAvailable,
      currentRuleCount,
    ],
  );

  const createRuleModalInitialValues = useMemo((): Partial<UpdateTransactionRuleInput> => {
    const {
      accountIds,
      merchantName,
      categoryId,
      amountEquals,
      newMerchantName,
      newCategoryId,
      newGoalId,
      newSplits,
    } = createRuleValues ?? {};

    // @ts-expect-error TS doesn't like the splitsInfo data we're passing because of nil states
    return {
      // Criteria
      ...(accountIds ? { accountIds } : {}),
      ...(merchantName
        ? { merchantCriteria: [{ value: merchantName, operator: StringOperator.Equals }] }
        : {}),
      ...(categoryId ? { categoryIds: [categoryId] } : {}),
      ...(amountEquals
        ? {
            amountCriteria: {
              isExpense: isExpense(amountEquals),
              operator: StringOperator.Equals,
              value: Math.abs(amountEquals),
              valueRange: null,
            },
          }
        : {}),
      // Actions
      ...(newCategoryId ? { setCategoryAction: newCategoryId } : {}),
      ...(newMerchantName ? { setMerchantAction: newMerchantName } : {}),
      ...(newGoalId ? { linkGoalAction: newGoalId } : {}),
      ...(newSplits
        ? {
            splitTransactionsAction: {
              amountType: SplitAmountType.ABSOLUTE,
              splitsInfo: newSplits,
            },
          }
        : {}),
    };
  }, [createRuleValues]);

  const context = useMemo(() => ({ showToast }), [showToast]);

  return (
    <TransactionRuleToastContext.Provider value={context}>
      {children}
      {showCreateModal && (
        <Modal onClose={closeCreateModal} full large>
          {({ close }) => (
            <TransactionRuleModal
              onDone={close}
              refetchTransactions={refetchAll}
              initialValues={createRuleModalInitialValues}
            />
          )}
        </Modal>
      )}
      <UpgradeModal>
        <PremiumUpgradeFlow
          analyticsName={ProductFeatureLimit.TransactionRuleLimit}
          onBack={closeUpgradeModal}
          onComplete={closeUpgradeModal}
        />
      </UpgradeModal>
    </TransactionRuleToastContext.Provider>
  );
};

export default TransactionRuleToastTrigger;
