import { useState } from 'react';

import useEventCallback from 'common/lib/hooks/useEventCallback';

const useToggle = (initialState: boolean) => {
  const [isOn, setIsOn] = useState<boolean>(initialState);

  const toggle = useEventCallback(() => setIsOn((currentValue) => !currentValue));
  const setOn = useEventCallback(() => setIsOn(true));
  const setOff = useEventCallback(() => setIsOn(false));

  return [
    isOn,
    {
      toggle,
      setOn,
      setOff,
    },
  ] as const;
};

export default useToggle;
