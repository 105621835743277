import React, { useState } from 'react';
import styled from 'styled-components';

import GoalsLoading from 'components/goalsV2/GoalsLoading';
import GoalsSetupHeader from 'components/goalsV2/GoalsSetupHeader';
import PreTaxBudgetGoalsList from 'components/goalsV2/PreTaxBudgetGoalsList';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Page from 'components/lib/ui/Page';
import SetupContentHeader from 'components/lib/ui/SetupContentHeader';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { getProgressPercent } from 'lib/goals/progress';
import useModal from 'lib/hooks/useModal';

import * as COPY from 'common/constants/copy';

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${({ theme }) => theme.color.white};
`;

const StyledSetupContentHeader = styled(SetupContentHeader)`
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

const NextButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 438px;
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
`;

const Container = styled(FlexContainer).attrs({ column: true, center: true })`
  max-width: 502px;
  margin: auto;
`;

const ButtonContainer = styled(FlexContainer).attrs({ center: true, column: true, full: true })`
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
`;

const GoalsPreTaxBudgets = () => {
  const [GoalsLoadingModal, { open: openGoalsLoadingModal }] = useModal();

  const [loading, setLoading] = useState(true);

  return (
    <StyledPage name="Goals setup" header={<GoalsSetupHeader progress={getProgressPercent(6)} />}>
      <Container>
        <StyledSetupContentHeader
          title={COPY.GOALS.PRE_TAX_BUDGETS.TITLE}
          description={COPY.GOALS.PRE_TAX_BUDGETS.SUBTITLE}
        />
        <PreTaxBudgetGoalsList onDoneLoading={() => setLoading(false)} />

        <ButtonContainer>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <NextButton
              size="large"
              onClick={() => {
                openGoalsLoadingModal();
              }}
            >
              Next
            </NextButton>
          )}
        </ButtonContainer>
      </Container>
      <GoalsLoadingModal>
        <GoalsLoading />
      </GoalsLoadingModal>
    </StyledPage>
  );
};

export default GoalsPreTaxBudgets;
