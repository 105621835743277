import type { StyleMixin } from 'common/types/Styles';

const fieldErrorBorderMixin: StyleMixin<{ error?: boolean }> = ({ theme, error }) =>
  error
    ? `
      && {
        border-color: ${theme.color.red};
        box-shadow: none;
      }
    `
    : '';

export default fieldErrorBorderMixin;
