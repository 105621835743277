import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import CardFooter from 'components/lib/ui/CardFooter';
import FileDropzone from 'components/lib/ui/FileDropzone';
import InformationList, { InformationItem } from 'components/lib/ui/InformationList';
import Link from 'components/lib/ui/Link';
import ModalCard from 'components/lib/ui/ModalCard';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';
import { ACCOUNT_DETAILS_GET_ACCOUNT_QUERY } from 'components/routes/AccountDetails';

import useLoading from 'common/lib/hooks/useLoading';
import { useModalContext } from 'lib/contexts/ModalContext';
import refetchQueriesWithVariables from 'lib/graphQl/refetchQueriesWithVariables';
import useDownloadBalances from 'lib/hooks/accounts/useDownloadBalances';
import useUploadBalanceHistorySession from 'lib/hooks/statements/useUploadBalanceHistorySession';

import { HELP_CENTER_UPLOAD_BALANCE_HISTORY_ARTICLE_URL } from 'common/constants/externalUrls';

const SubmitButton = styled(AsyncButton)`
  ${primaryButtonMixin}
`;

const StyledInformationList = styled(InformationList)`
  margin: ${({ theme }) => theme.spacing.xxxlarge} 0;
`;

type Props = {
  accountId: string;
  accountName: string;
};

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const UploadBalanceHistoryCsvModal = ({ accountId, accountName }: Props) => {
  const [sessionKey, setSessionKey] = useState<string | null>(null);

  const { startSession, startParsing } = useUploadBalanceHistorySession({
    onParseSuccess: () => {
      refetchQueriesWithVariables([
        {
          query: ACCOUNT_DETAILS_GET_ACCOUNT_QUERY,
          variables: { id: accountId },
        },
      ]);
      close();
    },
  });

  const { close } = useModalContext();

  const { downloadBalances } = useDownloadBalances();
  const downloadBalancesCsv = () => downloadBalances([accountId]);
  const [file, setFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false);
  const [uploadPreviewData, setUploadPreviewData] = useState<{
    firstDate: DateTime;
    lastDate: DateTime;
    count: number;
  } | null>(null);

  const getUploadPreview = useCallback(
    async (file: File) => {
      const data = new FormData();
      const accountFilesMapping = {
        [file.name]: accountId,
      };
      data.append('files', file, file.name);
      data.append('account_files_mapping', JSON.stringify(accountFilesMapping));
      data.append('preview', 'true');

      try {
        const { sessionKey: uploadSessionKey, previews } = await startSession(data);
        if (previews.length > 0) {
          const { first_date, last_date, count } = previews?.[0];

          setUploadPreviewData({
            firstDate: DateTime.fromISO(first_date),
            lastDate: DateTime.fromISO(last_date),
            count,
          });

          setSessionKey(uploadSessionKey);
        }
        return file;
      } catch (e: any) {
        setUploadPreviewData(null);
        setErrorMessage(JSON.parse(e.message).detail);
        return null;
      }
    },
    [accountId],
  );

  const [isLoading, uploadFile] = useLoading(async () => {
    if (RA.isNotNil(file)) {
      const data = new FormData();
      const accountFilesMapping = {
        [file.name]: accountId,
      };
      data.append('files', file, file.name);
      data.append('account_files_mapping', JSON.stringify(accountFilesMapping));

      if (sessionKey) {
        await startParsing({ sessionKey });
      }
    }
  });

  return (
    <ModalCard
      title="Upload balances CSV"
      description={
        <div>
          <p>
            Note that this will overwrite existing balances within the date range of your uploaded
            file. We recommend{' '}
            <Link onClick={downloadBalancesCsv}>downloading your existing balances</Link> beforehand
            just so you have a backup.
          </p>

          <p>
            Find out more about how to format your CSV for success{' '}
            <Link href={HELP_CENTER_UPLOAD_BALANCE_HISTORY_ARTICLE_URL} target="_blank">
              here
            </Link>
            .
          </p>
        </div>
      }
    >
      <Container>
        <FileDropzone
          acceptFileTypes={['text/csv', '.csv']}
          maxNumFiles={1}
          title="Upload a CSV File"
          files={file ? [file] : []}
          errorMessage={errorMessage}
          onChangeFiles={async (files) => {
            setIsLoadingPreview(true);
            setSessionKey(null);
            setUploadPreviewData(null);
            const file = R.head(files);

            if (file) {
              const validatedFile = await getUploadPreview(file);
              setFile(validatedFile);
            }
            setIsLoadingPreview(false);
          }}
          onDropRejected={RA.noop}
        />
        {RA.isNotNil(uploadPreviewData) && RA.isNotNil(file) ? (
          <StyledInformationList title="What will change:">
            <InformationItem iconName="x-circle">
              {/** TODO add account name */}
              {`Balance history between ${uploadPreviewData.firstDate.toLocaleString(
                DateTime.DATE_MED,
              )} ` +
                `and ${uploadPreviewData.lastDate.toLocaleString(
                  DateTime.DATE_MED,
                )} will be deleted in ${accountName}`}
            </InformationItem>
            <InformationItem iconName="check-circle">
              {`Balance history between ${uploadPreviewData.firstDate.toLocaleString(
                DateTime.DATE_MED,
              )} ` +
                `and ${uploadPreviewData.lastDate.toLocaleString(
                  DateTime.DATE_MED,
                )} will be replaced with ${file.name}`}
            </InformationItem>
            <InformationItem iconName="alert-triangle">This cannot be undone</InformationItem>
          </StyledInformationList>
        ) : null}
      </Container>
      <CardFooter>
        <DefaultButton onClick={close}>Cancel</DefaultButton>
        <SubmitButton
          disabled={R.isNil(file) || R.isNil(sessionKey)}
          onClick={uploadFile}
          pending={isLoading || isLoadingPreview}
        >
          Add to account
        </SubmitButton>
      </CardFooter>
    </ModalCard>
  );
};

export default UploadBalanceHistoryCsvModal;
