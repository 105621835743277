import { DateTime } from 'luxon';
import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import CashFlowDashboardWidgetGraph from 'components/dashboard/CashFlowDashboardWidgetGraph';
import Select from 'components/lib/form/Select';
import DashboardWidget from 'components/lib/ui/DashboardWidget';

import {
  CashFlowTimeRange,
  dropdownFilterOptions,
  getMergedBalanceDataForRangeType,
  TIME_RANGE_LABELS,
} from 'common/lib/cashFlow/DashboardAdapters';
import { GET_CASH_FLOW_DASHBOARD_QUERY } from 'common/lib/graphQl/aggregates';
import useDashboardConfig from 'common/lib/hooks/dashboard/useDashboardConfig';
import { DashboardWidgetName } from 'common/state/dashboard/types';
import { formatCurrency } from 'common/utils/Currency';
import useIsV2Theme from 'lib/hooks/useIsV2Theme';
import useMockDataWhenNoAccountsQuery from 'lib/hooks/useMockDataWhenNoAccountsQuery';

import routes from 'constants/routes';

const StyledSelect = styled(Select)`
  min-width: 250px;
`;

const Container = styled.div`
  padding-top: ${({ theme }) => theme.spacing.xlarge};
  padding-bottom: ${({ theme }) => theme.spacing.default};
`;

const CashFlowDashboardWidget = () => {
  const isV2Theme = useIsV2Theme();
  const [today] = useState(DateTime.local());
  const [dashboardConfig, setDashboardConfig] = useDashboardConfig('web');
  const selectedRange =
    (dashboardConfig.widgets[DashboardWidgetName.CASH_FLOW].filter as CashFlowTimeRange) ??
    CashFlowTimeRange.ThisMonthVersusLastMonth;

  const optionToDateRangeStart = {
    [CashFlowTimeRange.ThisWeekVersusLastWeek]: today
      .minus({ weeks: 1 })
      .startOf('week')
      .toISODate(),
    [CashFlowTimeRange.ThisMonthVersusLastMonth]: today
      .minus({ months: 1 })
      .startOf('month')
      .toISODate(),
    [CashFlowTimeRange.ThisMonthVersusLastYear]: today.startOf('month').toISODate(),
  };

  const { data, isLoadingInitialData } = useMockDataWhenNoAccountsQuery(
    GET_CASH_FLOW_DASHBOARD_QUERY,
    {
      variables: {
        startDate: optionToDateRangeStart[selectedRange],
        endDate: today.toISODate(),
      },
    },
  );

  const { data: previousYearMonthData, isLoadingInitialData: isLoadingPreviousYearMonthData } =
    useMockDataWhenNoAccountsQuery(GET_CASH_FLOW_DASHBOARD_QUERY, {
      variables: {
        startDate: DateTime.local().minus({ years: 1 }).startOf('month').toISODate(),
        endDate: DateTime.local().minus({ years: 1 }).endOf('month').toISODate(),
      },
      skip: selectedRange !== CashFlowTimeRange.ThisMonthVersusLastYear,
    });

  const mergedData = useMemo(
    () =>
      !isLoadingPreviousYearMonthData && !isLoadingInitialData
        ? getMergedBalanceDataForRangeType(previousYearMonthData, data, selectedRange)
        : [],
    [
      selectedRange,
      isLoadingInitialData,
      isLoadingPreviousYearMonthData,
      previousYearMonthData,
      data,
    ],
  );

  const lastBalance = useMemo(
    () => R.findLast((entry) => !!entry.balance, mergedData ?? []),
    [mergedData],
  );

  return (
    <DashboardWidget
      title="Spending"
      description={
        isV2Theme && lastBalance
          ? `${formatCurrency(lastBalance.balance)} ${TIME_RANGE_LABELS[selectedRange].currentPeriodLegend.toLowerCase()}`
          : selectedRange
      }
      loading={isLoadingInitialData || isLoadingPreviousYearMonthData}
      headerLink={routes.cashFlow()}
      headerRight={
        <StyledSelect
          small
          options={dropdownFilterOptions}
          value={selectedRange}
          onChange={({ value }: { value: CashFlowTimeRange }) => {
            setDashboardConfig(
              R.mergeDeepLeft(
                {
                  widgets: {
                    [DashboardWidgetName.CASH_FLOW]: {
                      filter: value,
                    },
                  },
                },
                dashboardConfig,
              ),
              true,
            );
          }}
          isDisabled={isLoadingInitialData}
        />
      }
    >
      <Container>
        {!!mergedData?.length && (
          <CashFlowDashboardWidgetGraph
            mergedData={mergedData}
            lastBalance={lastBalance}
            selectedTimeRange={selectedRange}
          />
        )}
      </Container>
    </DashboardWidget>
  );
};

export default CashFlowDashboardWidget;
