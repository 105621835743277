import * as React from 'react';

import MerchantSearchModalCard from 'components/merchants/MerchantSearchModalCard';
import type { InitialValues } from 'components/settings/merchants/EditMerchantModal';
import EditMerchantModal from 'components/settings/merchants/EditMerchantModal';
import Flow from 'components/utils/Flow';

export type Metadata = { merchantId: string; initialValues: InitialValues };

type Props = {
  initialValues?: InitialValues;
  onComplete?: () => void;
};

const MerchantSearchFlow = ({ initialValues, onComplete }: Props) => (
  <Flow
    initialProps={{ title: 'Add recurring', initialValues }}
    steps={[MerchantSearchModalCard, EditMerchantModal]}
    onComplete={onComplete}
  />
);

export default MerchantSearchFlow;
