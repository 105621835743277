import { gql } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import AccountLogosGroup from 'components/accounts/AccountLogosGroup';
import GoalsAllocateBalanceModal from 'components/goalsV2/GoalsAllocateBalanceModal';
import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import boxShadow from 'common/lib/styles/boxShadow';
import useModal from 'lib/hooks/useModal';

import * as COPY from 'common/constants/copy';

import type { Web_GoalsAllocateBannerFields } from 'common/generated/graphQlTypes/Web_GoalsAllocateBannerFields';

const Root = styled(FlexContainer).attrs({ alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.default};
`;

const ACCOUNT_LOGOS_SIZE_PX = 36;

const StyledBanner = styled(Banner)`
  padding: ${({ theme }) => theme.spacing.default};
  ${boxShadow.medium}
  width: 100%;
`;

const StyledText = styled(Text)`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  accounts: Web_GoalsAllocateBannerFields[]; // todo fix type
  onFinishAllocation: () => void;
};

const GoalsAllocateBanner = ({ accounts, onFinishAllocation }: Props) => {
  const [AllocateBalanceModal, { open: openModal }] = useModal();

  return (
    <Root>
      <StyledBanner type="info">
        <FlexContainer justifyBetween>
          <FlexContainer alignCenter>
            <AccountLogosGroup size={ACCOUNT_LOGOS_SIZE_PX} accounts={accounts} />

            <StyledText weight="medium" color="blueText">
              {COPY.GOALS.ALLOCATE.BANNER}
            </StyledText>
          </FlexContainer>
          <DefaultButton onClick={openModal}>Update now</DefaultButton>
        </FlexContainer>
      </StyledBanner>
      <AllocateBalanceModal>
        <GoalsAllocateBalanceModal onFinishAllocation={onFinishAllocation} />
      </AllocateBalanceModal>
    </Root>
  );
};

GoalsAllocateBanner.fragments = {
  Web_GoalsAllocateBannerFields: gql`
    fragment Web_GoalsAllocateBannerFields on Account {
      id
      name
      logoUrl
      icon
    }
  `,
};

export default GoalsAllocateBanner;
