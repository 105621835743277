import { useGoogleLogin } from '@react-oauth/google';
import React from 'react';

import Confirmation from 'components/lib/ui/Confirmation';
import Text from 'components/lib/ui/Text';

import getUserApi from 'common/lib/api/user';
import api from 'lib/api';
import useAppleId from 'lib/hooks/external/useAppleId';
import { errorToast } from 'lib/ui/toast';

import type { AuthProvider } from 'common/types/auth';

type Props = {
  provider: AuthProvider;
  providerLabel: string;
  providerEmail: string;
  onCancel: () => void;
  onDisconnect: () => void;
};

const DisconnectSSOConfirmation = ({
  provider,
  providerLabel,
  providerEmail,
  onCancel,
  onDisconnect,
}: Props) => {
  const googleSignIn = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        await getUserApi(api).revokeProviderSSO(provider, {
          token: response.access_token,
        });
        onDisconnect();
      } catch {
        errorToast();
      }
    },
    flow: 'implicit',
  });

  const { isLoaded, onClick: appleSignIn } = useAppleId({
    onSuccess: async (response) => {
      const {
        authorization: { code },
      } = response;

      try {
        await getUserApi(api).revokeProviderSSO(provider, {
          token: code,
        });
        onDisconnect();
      } catch {
        errorToast();
      }
    },
  });

  const doProviderSignIn = () => {
    if (provider === 'google') {
      googleSignIn();
    } else if (provider === 'apple') {
      appleSignIn();
    }
  };

  return (
    <Confirmation
      title={`Remove Log in with ${providerLabel}`}
      confirm="Log out"
      onCancel={onCancel}
      onConfirm={doProviderSignIn}
      isLoading={provider === 'apple' && !isLoaded}
      useDangerButton
    >
      <div>
        Are you sure you want to disconnect <Text weight="medium">{providerEmail}</Text> from{' '}
        {providerLabel} Log in?
      </div>
    </Confirmation>
  );
};

export default DisconnectSSOConfirmation;
