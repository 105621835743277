import React from 'react';
import styled from 'styled-components';

import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { OverlayTrigger } from 'components/lib/ui/popover';

import type { CashFlowFilter } from 'state/filters/types';

export const VIEW_TO_LABEL_MAP: Record<CashFlowFilter['view'], string> = {
  breakdown: 'Bar Chart',
  sankey: 'Sankey Diagram',
};

const Root = styled(FlexContainer).attrs({ alignCenter: true })`
  gap: ${({ theme }) => theme.spacing.small};
`;

const ChevronDownIcon = styled(Icon).attrs({ name: 'chevron-down', size: 12 })`
  transform: translateY(0px);
`;

const Button = styled(DefaultButton)`
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const Menu = styled(DropdownMenu)`
  width: 180px;
  min-width: unset;
`;

type Props = {
  selectedView: CashFlowFilter['view'];
  onChangeView: (view: CashFlowFilter['view']) => void;
};

const CashFlowViewSelect = ({ selectedView, onChangeView }: Props) => (
  <Root>
    <Text color="textLight" weight="medium" size="small">
      View
    </Text>
    <OverlayTrigger
      placement="bottom-end"
      overlay={
        <Menu>
          {Object.entries(VIEW_TO_LABEL_MAP).map(([view, label]) => (
            <DropdownMenuItem
              key={view}
              onClick={() => onChangeView(view as CashFlowFilter['view'])}
              selected={view === selectedView}
            >
              {label}
            </DropdownMenuItem>
          ))}
        </Menu>
      }
    >
      {({ open }) => (
        <Button onClick={open}>
          {VIEW_TO_LABEL_MAP[selectedView]} <ChevronDownIcon />
        </Button>
      )}
    </OverlayTrigger>
  </Root>
);

export default CashFlowViewSelect;
