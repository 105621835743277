import { DateTime } from 'luxon';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import styled from 'styled-components';

import { cardDropShadowStyleMixin } from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { isNYSEOpen } from 'common/utils/date';

export const HeaderCardRoot = styled.div<{
  $highlightColor: string | null;
  $canSelect: boolean;
  $rightMargin: boolean;
}>`
  height: 128px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  border-top: 8px solid ${({ $highlightColor, theme }) => $highlightColor ?? theme.color.black};
  opacity: ${({ $highlightColor }) => ($highlightColor ? 1 : 0.3)};
  flex-grow: 1;
  cursor: ${({ $canSelect }) => ($canSelect ? 'pointer' : 'default')};
  margin-right: ${({ theme, $rightMargin }) => ($rightMargin ? theme.spacing.gutter : '0px')};
  transition: opacity 0.1s ease-out;

  ${({ theme }) => cardDropShadowStyleMixin({ theme })}

  &:hover {
    opacity: ${({ $highlightColor }) => ($highlightColor ? 1 : 0.6)};
  }
`;

export const HeaderCardMainText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin: ${({ theme }) => theme.spacing.default};
  display: inline-block;
`;

export const HeaderCardTimeRangeText = styled.span`
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const HeaderCardDivider = styled.div`
  background-color: ${({ theme }) => theme.color.grayLight};
  width: 1px;
  height: 32px;
  margin: 0px ${({ theme }) => theme.spacing.xlarge};
`;

type HeaderCardProps = {
  title: string;
  dateRangeReturn: number;
  oneDayChangePercent: number | null;
  timeFrameText: string;
  highlightColor: string | null;
  canSelect?: boolean;
  rightMargin: boolean;
  onClick: () => void;
};

const InvestmentsBenchmarkHeaderCard = ({
  title,
  onClick,
  dateRangeReturn,
  oneDayChangePercent,
  highlightColor,
  timeFrameText,
  canSelect = false,
  rightMargin,
}: HeaderCardProps) => (
  <HeaderCardRoot
    $highlightColor={highlightColor}
    $canSelect={canSelect}
    onClick={onClick}
    $rightMargin={rightMargin}
  >
    <HeaderCardMainText>{title}</HeaderCardMainText>
    <FlexContainer marginLeft="default" alignCenter>
      <FlexContainer column>
        <HeaderCardTimeRangeText>{timeFrameText}</HeaderCardTimeRangeText>
        <Text weight="medium">{dateRangeReturn.toFixed(2)}%</Text>
      </FlexContainer>
      <HeaderCardDivider />
      <FlexContainer column>
        <HeaderCardTimeRangeText>Today</HeaderCardTimeRangeText>
        <Text weight="medium">
          {isNYSEOpen(DateTime.local())
            ? `${RA.isNotNil(oneDayChangePercent) ? oneDayChangePercent.toFixed(2) : '--'}%`
            : '--'}
        </Text>
      </FlexContainer>
    </FlexContainer>
  </HeaderCardRoot>
);

export default InvestmentsBenchmarkHeaderCard;
