import { MockList } from '@graphql-tools/mock';
import { DateTime, Duration } from 'luxon';
import * as R from 'ramda';
import uuid from 'uuid/v4';

import lightPalette from 'common/lib/theme/lightPalette';
import {
  genFromArray as _genFromArray,
  genIncreasingIsoDate as _genIncreasingIsoDate,
  genToFunc,
} from 'common/utils/Generators';
import { randomArrayElement, randomNumber, shuffleArray } from 'common/utils/Random';
import { MOCK_INSTITUTION_LOGOS } from 'common/utils/graphQl/__mocks__/institutionLogos';
import {
  MOCK_ACCOUNTS_LIST,
  NET_WORTH_CHART_MOCK_DATA,
  ACCOUNT_SUMMARY_DATA,
  ACCOUNT_AMEX,
  ACCOUNT_DEPOSITORY_WELLS_FARGO,
} from 'common/utils/graphQl/__mocks__/mockAccounts';
import {
  MOCK_ADVICE_CATEGORIES,
  MOCK_ADVICE_ITEMS,
} from 'common/utils/graphQl/__mocks__/mockAdvice';
import { MOCK_GOALS } from 'common/utils/graphQl/__mocks__/mockGoals';
import { MOCK_GOALS_V2 } from 'common/utils/graphQl/__mocks__/mockGoalsV2';
import {
  MOCK_AGGREGATED_HOLDINGS,
  MOCK_PERFORMANCE_DATA,
} from 'common/utils/graphQl/__mocks__/mockHoldings';
import { MOCK_BUDGET_NEW } from 'common/utils/graphQl/__mocks__/mockPlan';
import { MOCK_RECURRING_TRANSACTION_ITEMS } from 'common/utils/graphQl/__mocks__/mockRecurring';
import { MOCK_TRANSACTIONS } from 'common/utils/graphQl/__mocks__/mockTransactions';
import noop from 'common/utils/noop';

import { MOCK_SPINWHEEL_CREDIT_REPORT } from '../mockSpinwheelCreditReport';

const genIncreasingIsoDate = genToFunc(_genIncreasingIsoDate);
const genFromArray = genToFunc(_genFromArray);

const MOCKS = {
  Query: () => ({
    aggregates: (_: unknown, { filters, groupBy }: any, __: unknown, { path, operation }: any) => {
      if (path.key === 'byDay') {
        const dateGenerator = genIncreasingIsoDate(
          DateTime.fromISO(filters?.startDate),
          DateTime.fromISO(filters?.endDate),
        );
        return new MockList(60, () => ({
          summary: { sumExpense: -randomNumber(100) },
          groupBy: {
            day: dateGenerator,
          },
        }));
      }

      if (path.key === 'byMonth') {
        const monthsBefore = 5;
        const dateGenerator = genIncreasingIsoDate(
          DateTime.local().startOf('month').minus({ month: monthsBefore }),
          DateTime.local().startOf('month'),
          Duration.fromObject({ month: 1 }),
        );
        return new MockList(monthsBefore + 1, () => {
          const income = randomNumber(500);
          const expense = -randomNumber(500);
          return {
            summary: {
              sumIncome: income,
              sumExpense: expense,
              savings: income - -expense,
              savingsRate: randomNumber(5),
            },
            groupBy: {
              month: dateGenerator,
            },
          };
        });
      }

      if (path.key === 'byCategory') {
        const categories = R.map(R.prop('category'), MOCK_TRANSACTIONS);
        return new MockList(categories.length, () => ({
          summary: {
            sum: randomNumber(200),
          },
          groupBy: {
            category: randomArrayElement(categories),
          },
        }));
      }

      return new MockList(1, () => ({
        summary: {
          sumIncome: 2500,
          sumExpense: -2000,
          savings: 500,
          savingsRate: randomNumber(1),
        },
      }));
    },
    monthlySnapshotsByAccountType: () => NET_WORTH_CHART_MOCK_DATA,
    GetCashFlowDashboard: () => noop(),
    allTransactions: () => ({
      totalCount: 205,
      results: (_: unknown, { limit }: any) =>
        new MockList(limit ?? 2, () => ({
          ...randomArrayElement(MOCK_TRANSACTIONS),
          amount: -randomNumber(250),
        })),
    }),
    accounts: () => {
      const genNames = genFromArray(MOCK_ACCOUNTS_LIST);
      return new MockList(6, genNames);
    },
    categoryGroups: () => MOCK_BUDGET_NEW.categoryGroups,
    budgetData: () => MOCK_BUDGET_NEW.budgetData,
    goals: () => MOCK_GOALS,
    goalsV2: () => MOCK_GOALS_V2,
    budgetStatus: () => ({
      hasBudget: true,
    }),
    aggregateHoldings: () => ({
      edges: MOCK_AGGREGATED_HOLDINGS.map((node) => ({ node })),
    }),
    portfolio: () => ({
      performance: MOCK_PERFORMANCE_DATA,
      aggregateHoldings: () => ({
        edges: MOCK_AGGREGATED_HOLDINGS.map((node) => ({ node })),
      }),
    }),
    adviceItems: () => MOCK_ADVICE_ITEMS,
    adviceItemCategories: () => MOCK_ADVICE_CATEGORIES,
    accountTypeSummaries: () => ACCOUNT_SUMMARY_DATA,
    recurringTransactionItems: () => MOCK_RECURRING_TRANSACTION_ITEMS,
    accountsWithUnallocatedBalancesForGoals: () => [],
    creditReport: () => MOCK_SPINWHEEL_CREDIT_REPORT,
  }),
  Date: () => DateTime.local().toISODate(),
  DateTime: () => DateTime.local(),
  User: () => ({
    name: 'Michael Bluth',
  }),
  UUID: () => uuid(),
  Account: () => {
    const shuffledArray = shuffleArray([ACCOUNT_AMEX, ACCOUNT_DEPOSITORY_WELLS_FARGO]);

    return genFromArray(shuffledArray)();
  },
  Credential: () => ({
    updateRequired: false,
  }),
  Category: () => ({
    icon: '💲',
  }),
  GoalContribution: () => ({
    amount: randomNumber(100),
    goal: randomArrayElement(MOCK_GOALS),
  }),
  TransactionsSummary: () => ({
    count: randomNumber(120),
    groupBy: {},
  }),
  Transaction: () => ({
    pending: false,
    hideFromReports: false,
    notes: null,
    amount: randomNumber(120),
  }),
  TransactionList: () => ({
    totalCount: 15,
  }),
  TransactionTag: () => ({
    name: randomArrayElement(['Food', 'Groceries', 'Gas', 'Rent', 'Utilities']),
    color: randomArrayElement(Object.values(lightPalette)),
  }),
  Institution: () =>
    randomArrayElement([
      {
        status: 'HEALTHY',
        logo: MOCK_INSTITUTION_LOGOS.CHASE,
        name: 'Chase',
        primaryColor: '#004481',
      },
      {
        status: 'HEALTHY',
        logo: MOCK_INSTITUTION_LOGOS.WELLS_FARGO,
        name: 'Wells Fargo',
        primaryColor: '#004481',
      },
    ]),
  String: () => 'Example',
};

export default MOCKS;
