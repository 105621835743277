import { gql } from 'common/generated/gql';

export const SUBSCRIPTION_FIELDS_FRAGMENT = gql(`
  fragment SubscriptionFields on HouseholdSubscription {
    id
    trialEndsAt
    trialType
    isOnFreeTrial
    hasChargedForLifetime
    currentPeriodEndsAt
    billingPeriod
    nextPaymentAmount
    hasStripeSubscriptionId
    hasPremiumEntitlement
    entitlements
    eligibleForTrial
    willCancelAtPeriodEnd
    paymentSource
    hasBillingIssue
    isSponsor

    paymentMethod {
      lastFour
      brand
    }

    activePromoCode {
      code
      description
    }

    sponsoredBy {
      name
      email
      profilePictureUrl
    }
  }
`);

export const SPONSORED_SUBSCRIPTION_FIELDS_FRAGMENT = gql(`
  fragment SponsoredSubscriptionFields on HouseholdSubscription {
    activeSponsorship {
      id
      sponsor {
        name
        email
        profilePictureUrl
      }
    }
  }
`);

export const GET_SUBSCRIPTION_STATUS_QUERY = gql(`
  query GetSubscriptionStatus {
    subscription {
      id
      entitlements
      ...SubscriptionFields
    }
  }
`);

export const GET_SUBSCRIPTION_HAS_ENTITLEMENT_AFTER_SYNC = gql(`
  query Common_GetSubscriptionHasEntitlementAfterSync {
    subscription {
      id
      hasPremiumEntitlementAfterSync
    }
  }
`);

export const CANCEL_SUBSCRIPTION_MUTATION = gql(`
  mutation Common_CancelSubscriptionForHousehold($input: CancelStripeSubscriptionMutationInput!) {
    cancelStripeSubscription(input: $input) {
      canceled
      subscription {
        id
        ...SubscriptionFields
        ...SponsoredSubscriptionFields
      }
      errors {
        message
      }
    }
  }
`);
