import { gql, useMutation } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import CheckboxField from 'components/lib/form/CheckboxField';
import Form from 'components/lib/form/Form';
import FormItemContainer from 'components/lib/form/FormItemContainer';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextAreaField from 'components/lib/form/TextAreaField';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';

import type { FeedbackType } from 'common/lib/assistant/constants';
import { FEEDBACK_COPY, FEEDBACK_OPTIONS } from 'common/lib/assistant/constants';
import { useModalContext } from 'lib/contexts/ModalContext';
import useToast from 'lib/hooks/useToast';

import type {
  Web_SubmitAssistantFeedback,
  Web_SubmitAssistantFeedbackVariables,
} from 'common/generated/graphQlTypes/Web_SubmitAssistantFeedback';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  feedbackType: FeedbackType;
  messageId: string;
};

const AssistantFeedbackModal = ({ feedbackType, messageId }: Props) => {
  const options = FEEDBACK_OPTIONS[feedbackType];
  const { title, placeholder } = FEEDBACK_COPY[feedbackType];

  const { close } = useModalContext();
  const { openToast } = useToast();

  const [submitFeedback] = useMutation<
    Web_SubmitAssistantFeedback,
    Web_SubmitAssistantFeedbackVariables
  >(MUTATION);

  const onSubmit = async ({
    details: detailsObj,
    comments,
  }: {
    details?: Record<string, boolean>;
    comments?: string;
  }) => {
    const details = Object.keys(detailsObj ?? {}).filter((key) => detailsObj?.[key]);

    await submitFeedback({
      variables: {
        input: {
          feedbackType,
          messageId,
          comments,
          details,
        },
      },
    });
    close();
    openToast({
      title: 'Thanks for your feedback.',
    });
  };

  return (
    <ModalCard title="Thanks for the feedback!">
      <Form onSubmit={onSubmit}>
        <Container>
          <FormItemContainer name="details" fieldId="details" label={title}>
            {options.map((option) => (
              <CheckboxField key={option} name={`details.${option}`} hideLabel>
                {option}
              </CheckboxField>
            ))}
          </FormItemContainer>
          <TextAreaField
            name="comments"
            label="Is there anything else you'd like to share?"
            placeholder={placeholder}
            minRows={2}
          />
        </Container>
        <CardFooter justifyEnd>
          <FormSubmitButton size="small">Submit</FormSubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

const MUTATION = gql`
  mutation Web_SubmitAssistantFeedback($input: SubmitAssistantFeedbackInput!) {
    submitAssistantFeedback(input: $input) {
      success
    }
  }
`;

export default AssistantFeedbackModal;
