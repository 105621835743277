import React from 'react';
import styled from 'styled-components';

import Banner from 'components/lib/ui/Banner';
import Grid, { GridItem } from 'components/lib/ui/Grid';
import Icon from 'components/lib/ui/Icon';
import Link from 'components/lib/ui/Link';

import { externalUrls } from 'constants/routes';

type Props = {
  bannerMessage: string;
  className?: string;
};

const Root = styled(Banner)`
  padding: 0; /* Reset banner padding */
  margin: 0; /* Reset banner padding */
`;

const StyledGrid = styled(Grid)`
  --spacing: ${({ theme }) => theme.spacing.xsmall};

  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding: ${({ theme }) => theme.spacing.default};
`;

const IconGridItem = styled(GridItem)`
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  display: inline-grid;
  align-items: center;
  justify-content: start;
`;

const StyledLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.color.red};
  }
`;

const InstitutionNewConnectionsDisabledBanner = ({ bannerMessage, className }: Props) => (
  <Root type="error" className={className}>
    <StyledGrid template={`"icon text button" / 32px 1fr 100px`}>
      <IconGridItem area="icon">
        <Icon name="alert-triangle" />
      </IconGridItem>
      <GridItem area="text">{bannerMessage}</GridItem>
      <GridItem area="button" style={{ marginLeft: 'auto' }}>
        <StyledLink href={externalUrls.currentConnectionStatusUS} target="_blank">
          Learn more
        </StyledLink>
      </GridItem>
    </StyledGrid>
  </Root>
);

export default InstitutionNewConnectionsDisabledBanner;
