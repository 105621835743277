import React, { useContext } from 'react';

type MessagesListContextType = {
  threadId: string;
};

const MessagesListContext = React.createContext<MessagesListContextType>(undefined as any);

export const useMessagesListContext = () => {
  const context = useContext(MessagesListContext);
  if (!context) {
    throw new Error(
      'useMessagesListContext used outside of MessagesListContext. Ensure you have a <MessagesListContext.Provider> higher in the tree.',
    );
  }

  return context;
};

export default MessagesListContext;
