import { CATEGORIES_TOUR } from 'common/constants/copy';

const CATEGORIES_TOUR_ROOT_URL = 'https://monarch-static-assets.s3.amazonaws.com/categories';

export const CATEGORIES_TOUR_STEPS = [
  {
    title: CATEGORIES_TOUR.INCOME.TITLE,
    subtitle: CATEGORIES_TOUR.INCOME.SUBTITLE,
    image: {
      url: `${CATEGORIES_TOUR_ROOT_URL}/income@2x.png`,
      height: 227,
    },
  },
  {
    title: CATEGORIES_TOUR.EXPENSE.TITLE,
    subtitle: CATEGORIES_TOUR.EXPENSE.SUBTITLE,
    image: {
      url: `${CATEGORIES_TOUR_ROOT_URL}/expense@2x.png`,
      height: 180,
    },
  },
  {
    title: CATEGORIES_TOUR.TRANSFER.TITLE,
    subtitle: CATEGORIES_TOUR.TRANSFER.SUBTITLE,
    image: {
      url: `${CATEGORIES_TOUR_ROOT_URL}/transfer@2x.png`,
      height: 135,
    },
  },
];
