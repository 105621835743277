import { pick } from 'ramda';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import DividerLine from 'components/lib/ui/DividerLine';
import { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import DropdownMenuButton from 'components/lib/ui/DropdownMenuButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ReportsFilterMenuButton from 'components/reports/ReportsFilterMenuButton';
import TransactionDateRangeButton from 'components/transactions/header/TransactionDateRangeButton';

import { setGroupByEntity, patchReportsFilters } from 'actions';
import { ENTITY_TO_LABEL } from 'common/lib/reports';
import { useDispatch } from 'lib/hooks';
import { INITIAL_STATE as REPORTS_INITIAL_STATE } from 'state/reports/reducer';
import { selectReportsFilters, selectReportsGroupBy } from 'state/reports/selectors';

import type { ReportsGroupByEntity } from 'common/generated/graphql';
import type DateRange from 'common/types/DateRange';

const REPORTS_DEFAULT_DATE_RANGE = pick(['startDate', 'endDate'], REPORTS_INITIAL_STATE.filters);

type Props = {
  isGroupByEnabled: boolean;
};

const ReportsHeaderControls = ({ isGroupByEnabled }: Props) => {
  const { startDate, endDate } = useSelector(selectReportsFilters);
  const groupBy = useSelector(selectReportsGroupBy);

  const dispatch = useDispatch();
  const handleGroupByChange = useCallback(
    (entity: ReportsGroupByEntity) => dispatch(setGroupByEntity(entity)),
    [dispatch],
  );

  const handleDateRangeChange = useCallback(
    ({ startDate, endDate }: DateRange) => {
      if (startDate && endDate) {
        dispatch(patchReportsFilters({ startDate, endDate }));
      } else {
        // Reset to the default date range for Reports
        dispatch(patchReportsFilters(REPORTS_DEFAULT_DATE_RANGE));
      }
    },
    [dispatch],
  );

  // Doing this in case one of the date range values is missing
  // to make sure we always have a valid date range and the screen is not stuck
  useEffect(() => {
    if (!startDate || !endDate) {
      dispatch(patchReportsFilters(REPORTS_DEFAULT_DATE_RANGE));
    }
  }, [startDate, endDate, dispatch]);

  return (
    <FlexContainer gap="small" alignCenter justifyEnd>
      {isGroupByEnabled && (
        <>
          <DropdownMenuButton title={groupBy ? `By ${ENTITY_TO_LABEL[groupBy]}` : 'Group by'}>
            {Object.entries(ENTITY_TO_LABEL).map(([entity, label]) => (
              <DropdownMenuItem
                key={entity}
                onClick={() => handleGroupByChange?.(entity as ReportsGroupByEntity)}
                active={groupBy === entity}
              >
                By {label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuButton>
          <DividerLine />
        </>
      )}
      <TransactionDateRangeButton
        dateRange={{ startDate, endDate }}
        defaultRange={REPORTS_DEFAULT_DATE_RANGE}
        onChangeDateRange={handleDateRangeChange}
      />
      <ReportsFilterMenuButton />
    </FlexContainer>
  );
};

export default ReportsHeaderControls;
