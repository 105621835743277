import type { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import RouteLink from 'components/lib/ui/link/RouteLink';

import routes from 'constants/routes';

import type { Category } from 'common/generated/graphql';

const Icon = styled.span`
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const StyledRouteLink = styled(RouteLink)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100vw - 1200px);
  min-width: 120px;
`;

const Name = styled.span`
  flex-grow: 1;
`;

type Props = {
  category: Maybe<Pick<Category, 'id' | 'icon' | 'name'>>;
  startDate: DateTime;
};

const RecurringCategory = ({ category, startDate }: Props) =>
  category ? (
    <StyledRouteLink
      to={routes.categories({ id: category.id, queryParams: { date: startDate.toISODate() } })}
      stealthy
      title={`${category?.icon} ${category?.name}`}
    >
      <Icon>{category?.icon}</Icon>
      <Name>{category?.name}</Name>
    </StyledRouteLink>
  ) : (
    <Name>No recurring transactions</Name>
  );

export default RecurringCategory;
