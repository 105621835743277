import React from 'react';
import styled, { css } from 'styled-components';

const DEFAULT_DURATION_MS = 1200;

const Root = styled.div<{
  enabled: boolean;
  repeat: boolean;
  durationMs: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  ${({ enabled, durationMs, repeat }) =>
    enabled &&
    css`
      animation: ${durationMs}ms ease-in-out ${repeat ? 'infinite' : 1} rotate;
    `}
`;

type Props = {
  enabled?: boolean;
  repeat?: boolean;
  durationMs?: number;
  className?: string;
  children?: React.ReactNode;
};

const RotateAnimation = ({
  enabled = true,
  repeat = true,
  durationMs = DEFAULT_DURATION_MS,
  className,
  children,
}: Props) => (
  <Root className={className} enabled={enabled} repeat={repeat} durationMs={durationMs}>
    {children}
  </Root>
);

export default RotateAnimation;
