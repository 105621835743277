import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import Currency from 'components/lib/ui/currency/Currency';

import type { StripeInvoice } from 'common/generated/graphql';

type Props = {
  invoices: StripeInvoice[];
};

const Row = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }

  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Amount = styled(Column)`
  text-align: right;
`;

const openReceiptUrl = (url: Maybe<string>) => {
  if (!url) {
    return;
  }
  window.open(url, '_blank');
};

const InvoiceRow = ({ invoice }: { invoice: StripeInvoice }) => (
  <Row onClick={() => openReceiptUrl(invoice.receiptUrl)} key={invoice.id}>
    <Column>{DateTime.fromISO(invoice.date).toLocaleString(DateTime.DATE_FULL)}</Column>

    <Amount>
      <Currency value={invoice.amount} />
    </Amount>
  </Row>
);

const InvoicesCard = ({ invoices }: Props) => (
  <Card title="Invoices">
    {invoices.map((invoice) => (
      <InvoiceRow key={invoice.id} invoice={invoice} />
    ))}
  </Card>
);

export default InvoicesCard;
