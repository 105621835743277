import { useCallback, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { Tuple } from 'common/types';

/**
 * Measures a DOM node and updates the measurement whenever it is resized.
 *
 * Uses a ref callback to handle cases where the ref changes or isn't set immediately.
 * https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
 * https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
 *
 * Uses ResizeObserver to update the measurements when they change.
 * https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
 */
const useMeasure = <T extends Element>() => {
  const [rect, setRect] = useState<Partial<DOMRectReadOnly>>({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });
  // @ts-ignore
  const [ro] = useState(() => new ResizeObserver(([entry]) => setRect(entry.contentRect)));
  const ref = useCallback(
    (node: T | null) => {
      if (node !== null) {
        ro.disconnect();
        setRect(node.getBoundingClientRect());
        ro.observe(node);
      }
    },
    [ro],
  );
  return Tuple(ref, rect);
};

export default useMeasure;
