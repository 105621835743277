import ReactContentLoader from 'react-content-loader';
import styled from 'styled-components';

const ContentLoader = styled(ReactContentLoader).attrs(({ theme }) => ({
  backgroundColor: theme.color.grayBackground,
  foregroundColor: theme.color.grayLight,
  foregroundOpacity: 0.5,
}))`
  height: 100%;
  width: 100%;
`;

export default ContentLoader;
