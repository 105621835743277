import React from 'react';
import styled, { css } from 'styled-components';

import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';

import type { PlanSectionType } from 'lib/plan/Adapters';

const TOOLTIP_DELAY_SHOW_MS = 300;
const TOOLTIP_MAX_WIDTH_PX = 320;

const StyledCashFlowCurrency = styled(CashFlowCurrency)<{ gray?: boolean; _header: boolean }>`
  font-size: ${({ _header, theme }) => (_header ? theme.fontSize.large : theme.fontSize.base)};
  ${({ gray }) =>
    gray &&
    css`
      color: ${({ theme }) => theme.color.textLight};
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      user-select: none;
      cursor: pointer;
      transition: ${({ theme }) => theme.transition.default};
      :hover {
        filter: brightness(90%);
      }
    `}
`;

const ToolTipContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.small};
`;

type Props = {
  value?: number;
  itemId?: string;
  itemName?: string;
  isAggregate: boolean;
  isUnplannedAggregate?: boolean;
  isLoading?: boolean;
  groupLevelBudgetingEnabled?: boolean;
  isRemainingAmountType: boolean;
  isBudgetType: boolean;
  availableDisplayAmount: number;
  currencyType: 'savings' | 'income';
  sectionType: PlanSectionType;
  openEditGroupModal?: () => void;
  isCategoryGroup?: boolean;
};

const PlanCellAmount = ({
  itemId,
  itemName,
  isAggregate,
  isUnplannedAggregate,
  groupLevelBudgetingEnabled,
  isRemainingAmountType,
  isBudgetType,
  availableDisplayAmount,
  currencyType,
  sectionType,
  value,
  openEditGroupModal,
  isCategoryGroup,
}: Props) => {
  const [shouldHideTooltip, setShouldHideTooltip] = React.useState(false);

  const shouldShowTooltip =
    !shouldHideTooltip &&
    isBudgetType &&
    sectionType === 'expense' &&
    !groupLevelBudgetingEnabled &&
    itemId &&
    isCategoryGroup &&
    openEditGroupModal;

  React.useEffect(() => {
    if (shouldHideTooltip) {
      setShouldHideTooltip(false);
    }
    // this workaround is necessary to prevent the tooltip from staying visible
    // for a split second after the modal is open
  }, [shouldHideTooltip]);

  const onClickOpenModal = () => {
    setShouldHideTooltip(true);
    openEditGroupModal?.();
  };

  return (
    <Tooltip
      portal
      clickable
      maxWidth={TOOLTIP_MAX_WIDTH_PX}
      delayShow={TOOLTIP_DELAY_SHOW_MS}
      content={
        shouldShowTooltip && (
          <ToolTipContentWrapper>
            <Text>
              To set a budget for the {itemName} group as a whole,{' '}
              <Link onClick={onClickOpenModal} color="blue">
                click here
              </Link>{' '}
              to enable budgeting &quot;By Group&quot;.
            </Text>
          </ToolTipContentWrapper>
        )
      }
      opacity={1}
    >
      <div>
        <StyledCashFlowCurrency
          type={currencyType}
          value={isRemainingAmountType ? availableDisplayAmount : value}
          round
          emphasis={isRemainingAmountType}
          gray={isUnplannedAggregate && (!isRemainingAmountType || availableDisplayAmount > 0)}
          _header={isAggregate}
        />
      </div>
    </Tooltip>
  );
};

export default React.memo(PlanCellAmount);
