import { createSelector } from 'reselect';

import type { RootState } from 'state/types';

const getInvestmentsState = ({ investments }: RootState) => investments;

export const getActiveAccountIds = createSelector(
  getInvestmentsState,
  ({ activeAccountIds }) => activeAccountIds,
);
