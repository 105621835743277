import { DateTime } from 'luxon';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import Link from 'components/lib/ui/Link';
import ModalCard from 'components/lib/ui/ModalCard';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { REDDIT_COMMUNITY_URL } from 'common/constants/externalUrls';
import routes from 'constants/routes';

const GoToDashboardButton = styled(PrimaryButton).attrs({ size: 'small' })``;

const ModalCopy = styled.div``;

const ModalCopyText = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const RenewalDate = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const RenewalReminderDays = styled(RenewalDate)``;

const JoinOurCommunityLink = styled(Link).attrs({
  href: REDDIT_COMMUNITY_URL,
  target: '_blank',
})``;

export type FormValues = {
  renewalReminderDays: number;
};

export type Props = {
  goBack?: () => void;
  currentPeriodEndsAt?: string;
  renewalReminderDays?: number | null;
};

const SubscriptionSetReminderSuccessModal = ({
  goBack,
  currentPeriodEndsAt,
  renewalReminderDays,
}: Props) => {
  const history = useHistory();

  const renewDate = currentPeriodEndsAt
    ? DateTime.fromISO(currentPeriodEndsAt).toLocaleString(DateTime.DATE_FULL)
    : '';

  return (
    <ModalCard
      title="You set your personalized reminder!"
      onClickBackButton={goBack}
      hideBottomBorder
    >
      <CardBody>
        <ModalCopy>
          <ModalCopyText>
            Your Monarch Premium will renew at <RenewalDate>{renewDate}</RenewalDate>, and
            you&apos;ll receive the renewal email{' '}
            <RenewalReminderDays>{renewalReminderDays} days</RenewalReminderDays> prior to your
            renewal date.
          </ModalCopyText>
          <ModalCopyText>
            If you haven&apos;t already, please{' '}
            <JoinOurCommunityLink>join our community</JoinOurCommunityLink> to provide input on what
            you&apos;d like to see us build. Stay tuned for more updates from us soon.
          </ModalCopyText>
        </ModalCopy>
      </CardBody>
      <CardFooter>
        <GoToDashboardButton onClick={() => history.push(routes.dashboard.path)}>
          Go To Dashboard
        </GoToDashboardButton>
      </CardFooter>
    </ModalCard>
  );
};

export default SubscriptionSetReminderSuccessModal;
