import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import type { ValidationOptions } from 'common/lib/form/validation';
import fieldStyleMixin from 'lib/styles/fieldStyleMixin';

export type Props = Pick<
  React.HTMLProps<HTMLInputElement>,
  | 'type'
  | 'disabled'
  | 'onBlur'
  | 'autoFocus'
  | 'onFocus'
  | 'onChange'
  | 'value'
  | 'autoComplete'
  | 'placeholder'
  | 'id'
  | 'defaultValue'
  | 'onKeyDown'
> &
  Pick<ValidationOptions, 'name' | 'password'> & {
    small?: boolean;
    large?: boolean;
    isSensitive?: boolean;
    autoFocusOnMobile?: boolean;
  };

const Input = styled.input<{ small: boolean; large: boolean; disabled?: boolean }>`
  ${({ theme, disabled, small, large }) =>
    fieldStyleMixin({ theme, dynamic: !disabled, small, large })};
`;

const TextInput: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    id,
    name,
    password,
    type = password ? 'password' : 'text',
    disabled,
    small = false,
    large = false,
    autoFocusOnMobile = true,
    ...props
  },
  ref,
) => {
  // enables autoFocus only on Desktop devices or if the property autoFocusOnMobile is true
  const shouldAutoFocus = props.autoFocus && (autoFocusOnMobile || !isMobile);

  return (
    <Input
      {...props}
      ref={ref}
      id={id}
      type={type}
      name={name}
      disabled={disabled}
      small={small}
      large={large}
      autoFocus={shouldAutoFocus}
    />
  );
};

export default React.forwardRef(TextInput);
