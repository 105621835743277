import styled, { keyframes } from 'styled-components';

const DEFAULT_SIZE_PX = 40;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = styled.div<{ $size?: number }>`
  --size: ${({ $size }) => $size ?? DEFAULT_SIZE_PX}px;

  opacity: 1;
  position: relative;
  animation: 1.5s linear infinite ${spin};
  border: solid 3px ${({ theme }) => theme.color.grayLight};
  border-bottom-color: ${({ theme }) => theme.color.blue};
  border-radius: 50%;
  content: '';
  transform-origin: center;
  height: var(--size);
  width: var(--size);
  will-change: transform;
`;

export default LoadingSpinner;
