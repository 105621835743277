import { useMutation } from '@apollo/client';

import useEventCallback from 'common/lib/hooks/useEventCallback';

import { gql } from 'common/generated';
import type { BudgetVariability } from 'common/generated/graphql';
import type {
  ExtractResponseFromDocument,
  MutationHookOptionsFromDocument,
} from 'common/types/graphql';

type Options = MutationHookOptionsFromDocument<typeof UPDATE_CATEGORY_BUDGET_VARIABILITY>;

export const useUpdateCategoryBudgetVariability = (options?: Options) => {
  const [updateCategoryBudgetVariability, mutationResult] = useMutation(
    UPDATE_CATEGORY_BUDGET_VARIABILITY,
    options,
  );

  const setCategoryBudgetVariability = useEventCallback(
    (id: string, budgetVariability: BudgetVariability) =>
      updateCategoryBudgetVariability({
        // @ts-expect-error: TODO: change graphql codegen to use optionals for nullable fields
        variables: { input: { id, budgetVariability } },
        optimisticResponse: getOptimisticResponse(id, budgetVariability),
      }),
  );

  return [setCategoryBudgetVariability, mutationResult] as const;
};

const getOptimisticResponse = (
  id: string,
  budgetVariability: BudgetVariability,
): ExtractResponseFromDocument<typeof UPDATE_CATEGORY_BUDGET_VARIABILITY> => ({
  __typename: 'Mutation',
  updateCategory: {
    __typename: 'UpdateCategoryPayload',
    category: {
      __typename: 'Category',
      id,
      budgetVariability,
      updatedAt: new Date().toISOString(),
    },
    errors: null,
  },
});

const UPDATE_CATEGORY_BUDGET_VARIABILITY = gql(/* GraphQL */ `
  mutation Common_UpdateCategoryBudgetVariability($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      category {
        id
        budgetVariability
        updatedAt
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);
