import React from 'react';

import Confirmation from 'components/lib/ui/Confirmation';
import Text from 'components/lib/ui/Text';

type Props = {
  sponsoredHouseholdName?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  isLoading?: boolean;
};

const CancelSponsorshipConfirmation = ({
  sponsoredHouseholdName,
  onConfirm,
  onCancel,
  isLoading,
}: Props) => (
  <Confirmation
    title="Stop sponsoring client"
    confirm="Stop sponsoring client"
    isLoading={isLoading}
    onCancel={onCancel}
    onConfirm={onConfirm}
  >
    <Text>
      Are you sure you want to stop paying for your client {sponsoredHouseholdName}? This action
      cannot be undone.
    </Text>
  </Confirmation>
);

export default CancelSponsorshipConfirmation;
