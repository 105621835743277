import React, { useMemo } from 'react';
import styled from 'styled-components';

import TextAreaInput from 'components/lib/form/TextAreaInput';
import CopyToClipboardButton from 'components/lib/ui/CopyToClipboardButton';
import ModalCard from 'components/lib/ui/ModalCard';

import useSubscriptionDetails from 'common/lib/hooks/billing/useSubscriptionDetails';
import { formatReferralCode } from 'common/lib/referral';
import getStringEnvVar from 'lib/getStringEnvVar';

const ActionsRow = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.default};
  margin-top: ${(props) => props.theme.spacing.default};
  margin-bottom: ${(props) => props.theme.spacing.default};
`;

const Container = styled.div`
  padding: ${({ theme }) => `${theme.spacing.default} ${theme.spacing.xlarge}`};
`;

const StyledTextAreaInput = styled(TextAreaInput)`
  width: 100%;
  background: ${(props) => props.theme.color.grayBackground};
  font-size: ${(props) => props.theme.fontSize.small};
  box-sizing: border-box;
  pointer-events: none;
`;

type Props = {
  email: string;
};

const baseUrl = getStringEnvVar('REACT_APP_BASE_URL');

const getMessage = (email: string, referralUrl: string) => `
Hi,

I'd like to collaborate with you on your finances in Monarch Money. 

If you don't already have one, you can head here to learn more and sign-up:

  ${referralUrl}

Once you have an account (or if you already have one), you can head to the following link to grant me temporary access to your account:

  ${baseUrl}/settings/members

From there, you can grant access to my email address:

  ${email}
`;

const GetAccessGrantModal = ({ email }: Props) => {
  const { referralCode } = useSubscriptionDetails();
  const referralUrl = useMemo(() => formatReferralCode(referralCode || ''), [referralCode]);
  const message = useMemo(() => getMessage(email, referralUrl), [email, referralUrl]);

  return (
    <ModalCard title="Get Access to Client">
      <Container>
        Clients will need to have their own Monarch account to grant you temporary access. If you
        need permanent access, we recommend sponsoring them instead.
        <br />
        <br />
        To get temporary access, you can copy the template below.
        <br />
        <br />
        Note that new Monarch users will get an extended 30-day trial if they use the embedded
        referral link (the extended trial will not apply for existing customers).
        <ActionsRow>
          <CopyToClipboardButton messageToCopy={message} />
        </ActionsRow>
        <StyledTextAreaInput value={message} hideResizeHandle readOnly />
      </Container>
    </ModalCard>
  );
};

export default GetAccessGrantModal;
