import { useMutation } from '@apollo/client';

import useUploadLogo from 'common/lib/hooks/cloudinary/useUploadLogo';

import { gql } from 'common/generated/gql';
import type { FileData } from 'common/types/File';

const SET_MERCHANT_LOGO = gql(`
  mutation Common_SetMerchantLogo($input: SetMerchantLogoInput!) {
    setMerchantLogo(input: $input) {
      merchant {
        id
        name
        logoUrl
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

const useUploadMerchantLogo = () => {
  const [setMerchantLogo] = useMutation(SET_MERCHANT_LOGO);

  const handleSetLogo = async (file: FileData, cloudinaryPublicId: string, merchantId?: string) => {
    if (!merchantId) {
      throw new Error('Merchant ID is required');
    }

    const setMerchantLogoResponse = await setMerchantLogo({
      variables: {
        input: {
          merchantId,
          cloudinaryPublicId,
        },
      },
    });
    const { merchant } = setMerchantLogoResponse.data?.setMerchantLogo ?? {};

    if (merchant?.logoUrl) {
      return merchant.logoUrl;
    } else {
      throw new Error('Merchant logo upload failed. Please try again later.');
    }
  };

  return useUploadLogo('merchant', handleSetLogo);
};

export default useUploadMerchantLogo;
