import type { DateTime } from 'luxon';
import { isNotNil } from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import MoveMoney from 'components/budget/MoveMoney';
import AmountPill from 'components/lib/ui/AmountPill';
import { TooltipV2 } from 'components/lib/ui/TooltipV2';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';
import PlanAmountsTooltip from 'components/plan/PlanAmountsTooltip';

import { formatCurrencyNoCents } from 'common/utils/Currency';
import { isEmoji } from 'common/utils/String';
import type { PlanAmounts } from 'lib/plan';
import { SAVINGS_PLAN_SECTION_TYPE } from 'lib/plan';
import type { PlanSectionType } from 'lib/plan/Adapters';

import { CategoryGroupType } from 'common/generated/graphql';

const PILL_X_OFFSET_PX = 12;

const StyledAmountPill = styled(AmountPill)<{ $isHeader: boolean }>`
  font-size: ${({ $isHeader, theme }) => ($isHeader ? theme.fontSize.large : theme.fontSize.base)};
  margin-right: -${PILL_X_OFFSET_PX}px;
`;

type Props = {
  date: DateTime;
  amounts: PlanAmounts | undefined;
  sectionType: PlanSectionType;
  itemId?: string;
  itemName?: string;
  itemIcon?: React.ReactNode | string;
  isAggregate?: boolean;
  isCategoryGroup?: boolean;
  showTooltip: boolean;
  availableDisplayAmount: number;
  isMovable: boolean;
  showRolloverIcon: boolean;
  warningText?: string;
  refetch?: () => Promise<void>;
};

const PlanCellMoveMoneyPill = ({
  date,
  amounts,
  sectionType,
  itemId,
  itemName,
  itemIcon,
  showTooltip = false,
  isAggregate = false,
  isCategoryGroup = false,
  availableDisplayAmount,
  isMovable,
  showRolloverIcon,
  refetch,
  warningText,
}: Props) => {
  // Sometimes the itemIcon comes as a string (e.g., in group-level budgeting, it will be "chevron").
  // We don't want to render the word "chevron" so we limit to only render things that are emojis.
  const tooltipTitleIcon = isEmoji(itemIcon?.toString() ?? '') ? itemIcon : '';

  const pillStyles = usePillStyles(
    sectionType,
    warningText,
    availableDisplayAmount,
    isMovable,
    isCategoryGroup,
    isAggregate,
  );

  return (
    <OverlayTrigger
      placement="bottom-end"
      overlay={({ close }) => (
        <Popover>
          <MoveMoney
            budgetStartDate={date}
            onDone={async () => {
              await refetch?.();
              close();
            }}
            initialCategoryId={itemId}
            isGroup={isCategoryGroup}
            categoryType={
              sectionType === SAVINGS_PLAN_SECTION_TYPE ? CategoryGroupType.INCOME : sectionType
            }
          />
        </Popover>
      )}
    >
      {({ toggleOpen, isOpen }) => (
        <TooltipV2
          content={
            showTooltip && amounts ? (
              <PlanAmountsTooltip
                amounts={amounts}
                title={`${tooltipTitleIcon} ${itemName}`}
                warningText={warningText}
              />
            ) : null
          }
        >
          <div>
            <StyledAmountPill
              value={(() => {
                const displayAmount = Math.round(availableDisplayAmount);
                return displayAmount;
              })()}
              formatter={formatCurrencyNoCents}
              onClick={isMovable ? toggleOpen : undefined}
              icon={showRolloverIcon ? 'rotate-cw' : undefined}
              active={isOpen}
              {...pillStyles}
              $isHeader={isAggregate}
            />
          </div>
        </TooltipV2>
      )}
    </OverlayTrigger>
  );
};

const usePillStyles = (
  sectionType: PlanSectionType,
  warningText: string | undefined,
  availableDisplayAmount: number,
  isMovable: boolean,
  isCategoryGroup: boolean,
  isAggregate: boolean,
) =>
  useMemo(() => {
    let styles: Record<string, string> = {};

    if (sectionType === SAVINGS_PLAN_SECTION_TYPE) {
      styles = {
        positiveBackgroundColor: 'blueBackground',
        positiveTextColor: 'blue',
        negativeBackgroundColor: 'grayFocus',
        negativeTextColor: 'textLight',
      };
    }

    if (isNotNil(warningText) && availableDisplayAmount < 0) {
      styles = {
        negativeTextColor: 'yellowText',
        negativeBackgroundColor: 'yellowBackground',
        negativeHoverBackgroundColor: 'yellowBackground',
      };
    }

    if (!isMovable && (isCategoryGroup || isAggregate)) {
      styles = {
        ...styles,
        positiveBackgroundColor: 'transparent',
        negativeBackgroundColor: 'transparent',
        neutralBackgroundColor: 'transparent',
      };
    }

    return styles;
  }, [sectionType, warningText, availableDisplayAmount, isMovable, isCategoryGroup, isAggregate]);

export default React.memo(PlanCellMoveMoneyPill);
