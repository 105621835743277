import { gql } from '@apollo/client';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import AdviceCategoryIcon from 'components/advice/AdviceCategoryIcon';
import AdviceTaskRow from 'components/advice/AdviceTaskRow';
import CondensedAdviceItemRow from 'components/advice/CondensedAdviceItemRow';
import CollapsibleSection from 'components/lib/ui/CollapsibleSection';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Markdown from 'components/lib/ui/Markdown';
import SideDrawer from 'components/lib/ui/SideDrawer';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';

import useQuery from 'common/lib/hooks/useQuery';
import typewriter from 'lib/analytics/typewriter';
import SideDrawerContext from 'lib/contexts/SideDrawerContext';
import useAdviceDrawerRoutes from 'lib/hooks/advice/useAdviceDrawerRoutes';
import useToggleCompleteAdviceTask from 'lib/hooks/advice/useToggleCompleteAdviceTask';

import { AdviceItemType, HOME_CATEGORY } from 'constants/advice';
import routes from 'constants/routes';

import type {
  AdviceItemDrawerQuery_Web,
  AdviceItemDrawerQuery_WebVariables,
} from 'common/generated/graphQlTypes/AdviceItemDrawerQuery_Web';
import type RouteProps from 'types/RouteProps';

const MAX_DISPLAY_PREREQUISITE_ITEMS = 4;

const Container = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.xxxlarge};
`;

const TitleContainer = styled(FlexContainer).attrs({ column: true, alignStart: true })<{
  hasPrevious: boolean;
}>`
  padding: 0 ${({ theme }) => theme.spacing.xxlarge} ${({ theme }) => theme.spacing.xlarge}
    ${({ theme }) => theme.spacing.xxlarge};
  margin-top: ${({ hasPrevious, theme }) => (hasPrevious ? theme.spacing.default : 0)};
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  display: block;
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

const Description = styled(Markdown)`
  font-size: ${({ theme }) => theme.fontSize.base};
  line-height: 150%;
  display: block;
  margin-top: ${({ theme }) => theme.spacing.small};
  white-space: pre-wrap;
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  flex: 1;
  height: 80%;
`;

const StyledAdviceTaskRow = styled(AdviceTaskRow)`
  border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
`;

const StyledCondensedAdviceItemRow = styled(CondensedAdviceItemRow)`
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  }
`;

const StyledTextButton = styled(TextButton)`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xxlarge};
  justify-content: flex-start;
`;

const AdviceItemDrawer = ({
  match: {
    params: { itemId },
  },
}: RouteProps<typeof routes.advice.itemDetails>) => {
  const { previousItemId, navigateToAdviceItem, navigateToAdviceTask, goBack, onClose } =
    useAdviceDrawerRoutes({ itemId });

  const { data, isLoadingInitialData } = useQuery<
    AdviceItemDrawerQuery_Web,
    AdviceItemDrawerQuery_WebVariables
  >(QUERY, {
    variables: { itemId, previousItemId: previousItemId ?? '', skipPrevious: !previousItemId },
  });
  const { adviceItem, previousItem } = data ?? {};
  const {
    category,
    title,
    description,
    tasks = [],
    prerequisiteAdviceItems = [],
    completedAt,
    name,
  } = adviceItem ?? {};

  const toggleCompleteTask = useToggleCompleteAdviceTask();

  useEffect(() => {
    name && typewriter.viewedAdviceItem({ name });
  }, [name]);

  return (
    <SideDrawer
      onClose={onClose}
      title={previousItem?.title}
      onClickBack={previousItem ? goBack : undefined}
      key={itemId}
    >
      {isLoadingInitialData ? (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ) : (
        <Container>
          <TitleContainer hasPrevious={!!previousItem}>
            {category && <AdviceCategoryIcon adviceCategory={category} completed={!!completedAt} />}
            <Title>{title}</Title>
            {!!description && <Description source={description} />}
          </TitleContainer>
          {prerequisiteAdviceItems.length > 0 && (
            <CollapsibleSection
              title="Essentials recommended"
              subtitle="This advice is important to complete before focusing on other priorities. We recommend completing these first to have a solid foundation."
              initiallyExpanded
            >
              {R.take(MAX_DISPLAY_PREREQUISITE_ITEMS, prerequisiteAdviceItems).map((item) => (
                <StyledCondensedAdviceItemRow
                  key={item.id}
                  item={item}
                  onClick={navigateToAdviceItem(item.id)}
                />
              ))}
              {prerequisiteAdviceItems.length > MAX_DISPLAY_PREREQUISITE_ITEMS && (
                <SideDrawerContext.Consumer>
                  {({ close }) => (
                    <StyledTextButton
                      linkTo={routes.advice({
                        category: HOME_CATEGORY,
                        queryParams: { scroll: AdviceItemType.Essential },
                      })}
                      onClick={close}
                    >
                      View all {prerequisiteAdviceItems.length}
                    </StyledTextButton>
                  )}
                </SideDrawerContext.Consumer>
              )}
            </CollapsibleSection>
          )}
          <CollapsibleSection title="Tasks" initiallyExpanded>
            {tasks.map((task) => (
              <StyledAdviceTaskRow
                key={task.id}
                task={task}
                onClick={navigateToAdviceTask(task.id)}
                onClickCheckbox={() => toggleCompleteTask(task)}
              />
            ))}
          </CollapsibleSection>
        </Container>
      )}
    </SideDrawer>
  );
};

const QUERY = gql`
  query AdviceItemDrawerQuery_Web($itemId: ID!, $previousItemId: ID!, $skipPrevious: Boolean!) {
    previousItem: adviceItem(id: $previousItemId) @skip(if: $skipPrevious) {
      id
      title
    }
    adviceItem(id: $itemId) {
      id
      name
      title
      description
      completedAt
      category {
        ...AdviceCategoryIconFields
      }
      prerequisiteAdviceItems {
        id
        ...CondensedAdviceItemRowFields
      }
      tasks {
        id
        ...AdviceTaskRowFields
      }
    }
  }
  ${AdviceCategoryIcon.fragments.AdviceCategoryIconFields}
  ${AdviceTaskRow.fragments.AdviceTaskRowFields}
  ${CondensedAdviceItemRow.fragments.CondensedAdviceItemRowFields}
`;

export default AdviceItemDrawer;
