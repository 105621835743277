import { createSelector } from 'reselect';

import type { DashboardState } from 'common/state/dashboard/reducer';

type RootStateDashboard = {
  dashboard: DashboardState;
};

export const getDashboardState = ({ dashboard }: RootStateDashboard) => dashboard;

export const getDashboardConfig = createSelector(getDashboardState, ({ config }) => config);
