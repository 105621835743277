import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';

const LinkIcon = styled(Flex).attrs({ center: true })`
  margin-right: ${({ theme }) => theme.spacing.small};
  position: relative;
`;

export default LinkIcon;
