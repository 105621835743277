import React, { useRef } from 'react';
import styled from 'styled-components';

import useSyncScroll from 'lib/hooks/useSyncScrollSticky';

const DEFAULT_PADDING_PX = 16;

const Root = styled.div`
  @media (max-width: ${({ theme }) => theme.breakPoints.md}px) {
    position: relative !important;
    top: 0 !important;
  }
`;

type Props = {
  children?: React.ReactNode;
  padding?: number;
  className?: string;
};

const StickyScroll = ({ children, padding = DEFAULT_PADDING_PX, className }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollState, offset } = useSyncScroll(ref, padding);

  return (
    <Root
      ref={ref}
      style={{
        position: ['fixedBottom', 'fixedTop'].includes(scrollState) ? 'sticky' : 'relative',
        top: `${offset}px`,
      }}
      className={className}
    >
      {children}
    </Root>
  );
};

export default StickyScroll;
