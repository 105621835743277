import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import LinkAccountFlow from 'components/accounts/LinkAccountFlow';
import SelectField from 'components/lib/form/SelectField';

import { escapeFieldName } from 'common/lib/form/field';
import type useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';
import useModal from 'lib/hooks/useModal';

const StyledSelectField = styled(SelectField)`
  min-width: 280px;
  text-align: left;
`;

const SelectFooterButton = styled.div`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
  border-top: 1px solid ${({ theme }) => theme.color.grayFocus};
  color: ${({ theme }) => theme.color.blue};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.blueDark};
  }
`;

type AccountSelectFieldProps = {
  accountName: string;
  options: ReturnType<typeof useAccountSelectOptions>[1];
  isLoading: boolean;
  ignoreAccountValue?: string;
  hideIgnoreOption?: boolean;
  hideAddAccountOption?: boolean;
  onAccountCreated: () => void;
  label?: string;
};

const AccountSelectField = ({
  accountName,
  options,
  isLoading,
  ignoreAccountValue = 'ignore',
  onAccountCreated,
  hideIgnoreOption = false,
  hideAddAccountOption = false,
  label,
}: AccountSelectFieldProps) => {
  const IGNORE_ACCOUNT_VALUE = ignoreAccountValue;

  const { setFieldValue } = useFormContext();
  const fieldName = escapeFieldName(accountName);
  const [AddAccountModal, { open: openAddAccountModal, close: closeAddAccountModal }] = useModal();

  return (
    <>
      <StyledSelectField
        autoScrollToTop
        name={fieldName}
        hideLabel={!label}
        label={label}
        placeholder="Select an account..."
        isLoading={isLoading}
        options={
          hideIgnoreOption
            ? options
            : [
                ...(options ?? []),
                {
                  label: 'Other',
                  options: [{ label: 'Ignore this account', value: IGNORE_ACCOUNT_VALUE }],
                },
              ]
        }
        required
        menuFooterComponent={
          <>
            {!hideIgnoreOption && (
              <SelectFooterButton
                onClick={() => {
                  setFieldValue(fieldName, IGNORE_ACCOUNT_VALUE);
                  // @ts-ignore
                  document.activeElement?.blur?.(); // Get select menu to close
                }}
              >
                Ignore this account
              </SelectFooterButton>
            )}
            {!hideAddAccountOption && (
              <SelectFooterButton onClick={openAddAccountModal}>Add an account</SelectFooterButton>
            )}
          </>
        }
      />
      <AddAccountModal>
        <LinkAccountFlow
          onComplete={({ accountIds }) => {
            closeAddAccountModal();
            onAccountCreated();
            const firstAccountId = R.head(accountIds ?? []);
            if (firstAccountId) {
              setFieldValue(fieldName, firstAccountId);
            }
          }}
          onClose={closeAddAccountModal}
          allowManualAccount
        />
      </AddAccountModal>
    </>
  );
};

export default AccountSelectField;
