import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import FlexContainer from 'components/lib/ui/FlexContainer';

const Root = styled(FlexContainer)`
  transform: scale(1); /* create stacking context */
`;

const StyledAccountLogo = styled(AccountLogo)<{ $zIndex: number }>`
  margin-left: -4px;
  z-index: ${({ $zIndex }) => $zIndex}; /* stylelint-disable-line plugin/no-z-index */
  border: 1px solid ${({ theme }) => theme.color.textWhite};
`;

const Circle = styled(FlexContainer).attrs({ center: true })<{ $size: number }>`
  width: ${({ $size }: { $size: number }) => $size}px;
  height: ${({ $size }: { $size: number }) => $size}px;
  border-radius: ${({ $size }: { $size: number }) => $size / 2}px;
  margin-left: -${({ theme }) => theme.spacing.xxsmall};
  background-color: ${({ theme }) => theme.color.grayDark};
  border: 1px solid ${({ theme }) => theme.color.textWhite};
`;

const CircleText = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  color: ${({ theme }) => theme.color.textWhite};
`;

type Props = {
  accounts: { id: string; logoUrl?: string | null; icon?: string | null }[];
  size?: number;
  /** Defaults to 3, with overflow going into a "+X" circle */
  maxDisplayCount?: number;
  className?: string;
};

/**
 * Show multiple account logos with some horizontal overlap.
 */
const AccountLogosGroup = ({ accounts, size = 24, maxDisplayCount = 3, className }: Props) => {
  const overflowCount = Math.max(0, accounts.length - maxDisplayCount);

  return (
    <Root alignCenter className={className}>
      {R.take(maxDisplayCount, accounts).map((account, i) => (
        <StyledAccountLogo
          key={account.id}
          logoUrl={account.logoUrl}
          icon={account.icon}
          size={size}
          $zIndex={accounts.length - i} // we want icons on the left to be above icons on the right
        />
      ))}
      {!!overflowCount && (
        <Circle $size={size}>
          <CircleText>+{overflowCount}</CircleText>
        </Circle>
      )}
    </Root>
  );
};

export default AccountLogosGroup;
