import { createContext, useContext } from 'react';

type ChartContextType = {
  isPreview: boolean;
  hideAmounts: boolean;
};

/**
 * Creates a React context for chart rendering, primarily used to determine whether
 * the chart is in a preview state, but can be used for other chart-specific context
 * in the future.
 *
 * This context is not mandatory for chart rendering but is currently used by some components
 * to display a chart preview. If `isPreview` is `true`, the chart should be rendered
 * with preview-specific behavior, such as disabling interactions or animations.
 */
const ChartContext = createContext<ChartContextType>({ isPreview: false, hideAmounts: false });

export const useChartContext = ({
  strict = true,
}: {
  /** Forces an error if used outside of a `ChartContext.Provider`. */
  strict?: boolean;
}) => {
  const context = useContext(ChartContext);

  if (!context && strict) {
    throw new Error(
      'useChartContext used outside of ChartContext. ' +
        'Ensure you have a <ChartContext.Provider> higher in the tree.',
    );
  }

  return context;
};

/**
 * Hook to determine whether the chart is in preview mode. Won't error
 * if used outside of a ChartContext.Provider, but will return false.
 */
export const useIsChartPreview = () => {
  const { isPreview } = useChartContext({ strict: false });
  return !!isPreview;
};

export default ChartContext;
