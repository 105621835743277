import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setGlobalSearchModalVisible as setModalVisible } from 'actions';
import typewriter from 'lib/analytics/typewriter';
import useGlobalKey from 'lib/hooks/useGlobalKey';
import { getIsGlobalSearchModalVisible } from 'selectors';

const useGlobalSearch = () => {
  const dispatch = useDispatch();
  const isGlobalSearchModalVisible = useSelector(getIsGlobalSearchModalVisible);

  const setGlobalSearchModalVisible = useCallback(
    (state: boolean) => dispatch(setModalVisible(state)),
    [dispatch],
  );

  useGlobalKey('mod+k', (e) => {
    typewriter.searchOpened({ method: 'keyboard' });
    setGlobalSearchModalVisible(!isGlobalSearchModalVisible);
    e.preventDefault();
  });

  return {
    isGlobalSearchModalVisible,
    setGlobalSearchModalVisible,
  };
};

export default useGlobalSearch;
