import React from 'react';
import styled from 'styled-components';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import type { Props as NumericInputProps } from 'components/lib/form/NumericInput';
import NumericInput from 'components/lib/form/NumericInput';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';

import fieldErrorBorderMixin from 'lib/styles/fieldErrorBorderMixin';

const Numeric = styled(NumericInput)<{ error?: boolean }>`
  ${fieldErrorBorderMixin}
`;

const NumericField: React.ForwardRefRenderFunction<
  HTMLElement,
  AbstractFieldProps<NumericInputProps> & { InputComponent?: typeof Numeric }
> = ({ className, InputComponent = Numeric, onBlur, ...props }, ref) => (
  <AbstractField {...props} {...sensitiveClassProps} className={className}>
    {({ id, value, handleBlur, displayErrors, setFieldValue }) => (
      <InputComponent
        {...props}
        id={id}
        value={value}
        onBlur={(e) => {
          handleBlur(e);
          onBlur?.(e);
        }}
        onChange={(value) => setFieldValue(props.name, value ?? undefined)}
        error={displayErrors.length > 0}
        ref={ref}
      />
    )}
  </AbstractField>
);

export default React.forwardRef(NumericField);
