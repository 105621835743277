import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import AnimatedNumber from 'components/lib/ui/AnimatedNumber';
import CheckCircle from 'components/lib/ui/CheckCircle';
import Column from 'components/lib/ui/Column';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { color, spacing, fontSize, fontWeight } from 'common/lib/theme/dynamic';
import { formatCurrency } from 'common/utils/Currency';
import { formatPercentNoDecimals } from 'common/utils/Number';
import { copyToClipboard } from 'utils/clipboard';

export const COLUMN_GUTTER_PX = 40;

const DEFAULT_TEXT = 'Copy amount';
const COPIED_TEXT = 'Copied amount!';
const HIDE_TOOLTIP_DELAY_MS = 1000;

const AmountLeft = styled(AnimatedNumber)`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.xlarge};
  color: ${({ value, theme }) => (value < 0 ? theme.color.red : theme.color.text)};
  margin-left: ${spacing.xsmall};
  line-height: 150%;
`;

const AmountLeftLabel = styled.div`
  font-size: ${fontSize.xsmall};
  font-weight: ${fontWeight.medium};
  letter-spacing: 0.08em;
  color: ${color.textLight};
  margin-top: ${spacing.xxxsmall};
`;

type Props = {
  className?: string;
  onAddFormSplit?: () => void;
  amountLeftToSplit: number;
  isPercentage?: boolean;
};

const SplitFormValidationRow = ({
  className,
  onAddFormSplit,
  amountLeftToSplit,
  isPercentage = false,
}: Props) => {
  const [copyTooltipText, setCopyTooltipText] = useState<string>(DEFAULT_TEXT);

  const valueToCopy = useMemo(
    () =>
      (isPercentage ? amountLeftToSplit * 100 : amountLeftToSplit)
        .toFixed(isPercentage ? 0 : 2)
        .toString(),
    [amountLeftToSplit, isPercentage],
  );

  const copyValue = useCallback(() => {
    copyToClipboard(valueToCopy);
    setCopyTooltipText(COPIED_TEXT);
  }, [valueToCopy]);

  const formatNumber = useCallback(
    (n: number) => (isPercentage ? formatPercentNoDecimals(n / 100) : formatCurrency(n / 100)),
    [isPercentage],
  );

  const addFormSplit = useCallback(() => {
    if (onAddFormSplit) {
      onAddFormSplit();
    }
  }, [onAddFormSplit]);

  return (
    <FlexContainer className={className} alignStart justifyBetween>
      <Column xs={4}>
        <FlexContainer marginTop="default">
          {onAddFormSplit && (
            <DefaultButton onClick={addFormSplit}>
              <ButtonIcon name="plus-circle" size={12} />
              <Text size="small">Add a split</Text>
            </DefaultButton>
          )}
        </FlexContainer>
      </Column>
      <Column xs={8}>
        <FlexContainer column alignEnd marginTop="default">
          <Tooltip
            content={copyTooltipText}
            place="left"
            opacity={1}
            delayHide={HIDE_TOOLTIP_DELAY_MS}
            afterHide={() => setCopyTooltipText(DEFAULT_TEXT)}
            offset={{ left: 8 }}
          >
            <FlexContainer alignCenter onClick={copyValue}>
              {amountLeftToSplit === 0 && <CheckCircle />}
              <AmountLeft
                value={amountLeftToSplit * 100}
                formattingFn={formatNumber}
                {...sensitiveClassProps}
              />
            </FlexContainer>
          </Tooltip>
          <AmountLeftLabel>LEFT TO SPLIT</AmountLeftLabel>
        </FlexContainer>
      </Column>
    </FlexContainer>
  );
};

export default SplitFormValidationRow;
