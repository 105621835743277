import { gql } from '@apollo/client';
import React from 'react';
import styled, { css } from 'styled-components';

import Checkbox from 'components/lib/form/Checkbox';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LinkArrow from 'components/lib/ui/LinkArrow';
import Text from 'components/lib/ui/Text';

import type { AdviceTaskRowFields } from 'common/generated/graphQlTypes/AdviceTaskRowFields';

const Root = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xxlarge};
`;

const Title = styled(Text)<{ completed: boolean }>`
  transition: ${({ theme }) => theme.transition.default};
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing.default};
  :hover {
    color: ${({ theme }) => theme.color.blue};
  }

  ${({ completed }) =>
    completed &&
    css`
      color: ${({ theme }) => theme.color.textLight};
      text-decoration: line-through;
    `}
`;

type Props = {
  task: AdviceTaskRowFields;
  onClickCheckbox: () => void;
  onClick?: () => void;
  className?: string;
};

const AdviceTaskRow = ({
  task: { id, title, completedAt },
  onClickCheckbox,
  onClick,
  className,
}: Props) => (
  <Root className={className}>
    <Checkbox checked={!!completedAt} onChange={onClickCheckbox} />
    <Title onClick={onClick} completed={!!completedAt}>
      {title}
      <LinkArrow hoverTarget={Title} />
    </Title>
  </Root>
);

AdviceTaskRow.fragments = {
  AdviceTaskRowFields: gql`
    fragment AdviceTaskRowFields on AdviceItemTask {
      id
      title
      completedAt
    }
  `,
};

export default AdviceTaskRow;
