import { getDisplayHouseholdRole } from 'common/lib/household';
import { formatFullDate, parseISODate } from 'common/utils/date';

import { UserHouseholdRole } from 'common/generated/graphql';
import type { GetHouseHoldMemberSettingsQuery } from 'common/generated/graphql';

export const getHouseholdInvites = (queryData: GetHouseHoldMemberSettingsQuery | undefined) => {
  const invites = queryData?.householdInvites ?? [];
  return invites.map(({ createdAt, ...invite }) => ({
    ...invite,
    createdAt: formatFullDate(parseISODate(createdAt)),
  }));
};

export const getHouseholdMembers = (queryData: GetHouseHoldMemberSettingsQuery | undefined) => {
  if (!queryData) {
    return queryData;
  }
  const {
    myHousehold: { users },
  } = queryData;

  return users.map(({ name, householdRole, id, hasMfaOn, profilePictureUrl }) => ({
    id,
    name,
    householdRole,
    hasMfaOn,
    displayRole: getDisplayHouseholdRole(householdRole),
    profilePictureUrl,
  }));
};

export const getHouseholdRole = (queryData: GetHouseHoldMemberSettingsQuery | undefined) => {
  if (!queryData) {
    return UserHouseholdRole.MEMBER;
  }

  const {
    me: { householdRole },
  } = queryData;

  return householdRole;
};
