import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.textLight};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.small};
`;

type Props = {
  title: string;
  children: React.ReactNode;
  controls?: React.ReactNode;
  className?: string;
};

const BillingRow = ({ title, children, controls, className }: Props) => (
  <Root className={className}>
    <FlexContainer alignCenter justifyBetween>
      <Title>{title}</Title>
      {controls}
    </FlexContainer>
    <Content>{children}</Content>
  </Root>
);

export default BillingRow;
