import React from 'react';
import styled from 'styled-components';

import Dot from 'components/lib/ui/Dot';

import { color } from 'common/lib/theme/dynamic';

const Root = styled.div`
  position: relative;
`;

const ActiveIndicator = styled(Dot).attrs({ size: 12 })`
  background-color: ${color.orange};
  position: absolute;
  top: -4px;
  right: -4px;
`;

const WithIndicatorContainer: React.FC<React.PropsWithChildren<{ show: Maybe<boolean> }>> = ({
  children,
  show = false,
}) => (
  <Root>
    {children}
    {show && <ActiveIndicator />}
  </Root>
);

export default WithIndicatorContainer;
