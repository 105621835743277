import React from 'react';

import { TransactionRuleFormSide } from 'components/transactions/rules/TransactionRuleFormComponents';
import TransactionRuleFormCoreActionsContainer from 'components/transactions/rules/TransactionRuleFormCoreActionsContainer';
import TransactionRuleFormSplitActionsContainer from 'components/transactions/rules/TransactionRuleFormSplitActionsContainer';

type Props = {
  isSettingSplitAction: boolean;
  onSetUpSplitAction: () => void;
  onCancelSplitAction: () => void;
};

const TransactionRuleFormActionsContainer = ({
  isSettingSplitAction,
  onSetUpSplitAction,
  onCancelSplitAction,
}: Props) => (
  <TransactionRuleFormSide wide={isSettingSplitAction}>
    {isSettingSplitAction ? (
      <TransactionRuleFormSplitActionsContainer onCancelSplitAction={onCancelSplitAction} />
    ) : (
      <TransactionRuleFormCoreActionsContainer onSetUpSplitAction={onSetUpSplitAction} />
    )}
  </TransactionRuleFormSide>
);

export default TransactionRuleFormActionsContainer;
