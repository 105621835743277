import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';

import NestedDropdownMenu from 'components/lib/ui/NestedDropdownMenu';
import NestedDropdownSubMenu from 'components/lib/ui/NestedDropdownSubMenu';

import getVersionApi from 'common/lib/api/version';
import { getUserId, getUser, getTokenExpirationDate } from 'common/state/user/selectors';
import api from 'lib/api';
import toast from 'lib/ui/toast';
import { copyToClipboard } from 'utils/clipboard';

const EmployeeMenuDebugInfo = () => {
  const userId = useSelector(getUserId);
  const user = useSelector(getUser);
  const tokenExpiration = useSelector(getTokenExpirationDate);
  const [version, setVersion] = useState('');

  const copyText = (text: string) => {
    copyToClipboard(text);
    toast({
      title: 'Copied to clipboard',
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const versionRes = await getVersionApi(api).getVersion();
      setVersion(versionRes);
    };
    fetchData();
  }, []);

  return (
    <NestedDropdownSubMenu title="Debug Info">
      <NestedDropdownMenu
        items={[
          {
            title: 'User ID',
            right: userId,
            onClick: () => userId && copyText(userId),
          },
          {
            title: 'External User ID',
            right: user?.external_id,
            onClick: () => user?.external_id && copyText(user.external_id),
          },
          {
            title: 'Session Expiration',
            right: tokenExpiration ? (
              <Countdown
                date={tokenExpiration}
                renderer={({ minutes, seconds }) => `${minutes}:${seconds}`}
              />
            ) : (
              'None'
            ),
          },
          {
            title: 'API Version',
            right: version,
            onClick: () => version && copyText(version),
          },
          {
            title: 'API Logs',
            right: 'View',
            onClick: () => {
              window.open(
                `https://app.datadoghq.com/logs?query=env%3Astaging%20version%3A${version}`,
              );
            },
          },
        ]}
      />
    </NestedDropdownSubMenu>
  );
};

export default EmployeeMenuDebugInfo;
