import { useState } from 'react';

import useExcludeFromBudget from 'common/lib/hooks/budget/useExcludeFromBudget';
import useEventCallback from 'common/lib/hooks/useEventCallback';
import useDeleteCategory from 'lib/hooks/category/useDeleteCategory';
import useKey from 'lib/hooks/useKey';
import useToast from 'lib/hooks/useToast';

import { MS_PER_SECOND } from 'common/constants/time';

const HIDE_FROM_BUDGET_TOAST_DURATION_MS = 6 * MS_PER_SECOND;

export const useCategoryActions = () => {
  const [editingCategoryId, setEditingCategoryId] = useState<string>();

  const { openErrorToast, openToast } = useToast();
  const excludeCategoryFromBudget = useExcludeFromBudget({ refetch: false });
  const [undoableCategoryId, setUndoableCategoryId] = useState<string>();
  const onKeyDown = useEventCallback((e: KeyboardEvent) => {
    e.preventDefault();

    // Only make the shortcut work if there is an undoable category id
    if (undoableCategoryId) {
      updateHideFromBudget(undoableCategoryId, false);
    }
  });

  const [
    DeleteCategoryConfirmationModal,
    { deleteCategoryWithConfirmation, confirmingDeleteCategoryId },
  ] = useDeleteCategory({
    onError: () => openErrorToast(),
    onDone: (maybeCategory) => {
      if (maybeCategory) {
        const disabledOrDeleted = maybeCategory.isSystemCategory ? 'disabled' : 'deleted';

        openToast({
          title: `Category ${disabledOrDeleted}`,
          description: `The category ${maybeCategory.icon} ${maybeCategory.name} was ${disabledOrDeleted} successfully.`,
        });
      }
    },
  });

  useKey('z', document.body, { onKeyDown, modifiers: { mod: true } });

  const updateHideFromBudget = useEventCallback((id: string, excludeFromBudget: boolean) => {
    const toastTitle = excludeFromBudget
      ? 'Category hidden from budget'
      : 'Category not hidden from budget';
    const toastDescription = excludeFromBudget
      ? 'Category will no longer be included in the budget.'
      : 'Category will be included in the budget.';
    const showUndo = excludeFromBudget;

    setUndoableCategoryId(showUndo ? id : undefined);

    excludeCategoryFromBudget({
      id,
      excludeFromBudget,
      onCompleted: () => {
        openToast({
          title: toastTitle,
          description: toastDescription,
          duration: HIDE_FROM_BUDGET_TOAST_DURATION_MS,
          actions: showUndo
            ? [
                {
                  text: 'Undo',
                  onClick: () => {
                    updateHideFromBudget(id, false);
                  },
                },
              ]
            : undefined,
        });

        setTimeout(() => {
          setUndoableCategoryId(undefined);
        }, HIDE_FROM_BUDGET_TOAST_DURATION_MS);
      },
    });
  });

  return {
    editingCategoryId,
    setEditingCategoryId,
    deleteCategoryWithConfirmation,
    confirmingDeleteCategoryId,
    updateHideFromBudget,
    DeleteCategoryConfirmationModal,
  };
};
