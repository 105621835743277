import type { OperationVariables, TypedDocumentNode } from '@apollo/client';
import { addMocksToSchema } from '@graphql-tools/mock';
import type { DocumentNode, GraphQLSchema, GraphQLArgs, ExecutionResult } from 'graphql';
import { graphql, buildClientSchema } from 'graphql';
import { print } from 'graphql/language/printer';

import MOCKS from 'common/utils/graphQl/__mocks__/schemaMocks';

import introspectionResult from 'common/generated/schema.json';

// Make a GraphQL schema with no resolvers
// See https://www.graphql-tools.com/docs/mocking/#mocking-a-schema-using-introspection
// @ts-ignore JSON result is untyped
const schema = buildClientSchema(introspectionResult);

// Create a new schema with mocks
const schemaWithMocks = addMocksToSchema({ schema, mocks: MOCKS });

const executeMockQueryFromSchema = async <TData>(
  schema: GraphQLSchema,
  query: DocumentNode,
  variables: GraphQLArgs['variableValues'] = undefined,
): Promise<ExecutionResult<TData>> =>
  // @ts-ignore - TS complains about TData possibly being unrelated to ObjMap<unknown>, but it works
  graphql({
    schema,
    variableValues: variables,
    source: print(query),
  });

/**
 * Execute a query against our graphql schema with custom mocks.
 */
export const executeMockQuery = async <TData, TVariables extends OperationVariables | undefined>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
) => executeMockQueryFromSchema<TData>(schemaWithMocks, query, variables);
