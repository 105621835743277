import React from 'react';
import type { CountUpProps } from 'react-countup';
import CountUp from 'react-countup';

const DEFAULT_DURATION_S = 0.5;

export type Props = Omit<CountUpProps, 'end'> & {
  value: number;
};

/**
 * Component to animate changes in a number.
 *
 * Powered by react-countup. Accepts all props listed here: https://github.com/glennreyes/react-countup
 *
 * Usage:
 * <AnimatedNumber value={100} />
 */
const AnimatedNumber = ({
  preserveValue = true,
  duration = DEFAULT_DURATION_S,
  value,
  ...props
}: Props) => <CountUp {...props} preserveValue={preserveValue} duration={duration} end={value} />;

export default AnimatedNumber;
