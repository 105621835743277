import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCancelButton from 'components/lib/ui/ModalCancelButton';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';

import { UPDATE_SPONSORSHIP_INVITE_MUTATION } from 'common/lib/graphQl/advisorPortal';
import { errorToast } from 'lib/ui/toast';

import type { PayloadErrorFields } from 'common/generated/graphQlTypes/PayloadErrorFields';
import type {
  UpdateSponsorshipInvite,
  UpdateSponsorshipInviteVariables,
} from 'common/generated/graphQlTypes/UpdateSponsorshipInvite';

const FormContent = styled.div`
  padding: ${({ theme }) => `${theme.spacing.default} ${theme.spacing.xlarge}`};

  :first-child {
    padding-bottom: 0;
  }
`;

type FormValues = {
  id: string;
  label?: string | null;
  sentToEmail?: string | null;
};

type Props = {
  onDone?: () => void;
  initiaValues?: FormValues;
};

const EditInviteModal = ({ initiaValues, onDone }: Props) => {
  const [updateInvite] = useMutation<UpdateSponsorshipInvite, UpdateSponsorshipInviteVariables>(
    UPDATE_SPONSORSHIP_INVITE_MUTATION,
  );

  const handleSubmit = async (values: FormValues) => {
    const { id, label } = values;
    const { data } = await updateInvite({
      variables: {
        input: {
          id,
          label,
        },
      },
    });
    const errors: PayloadErrorFields | undefined = R.path(
      ['updateSubscriptionSponsorshipInvite', 'errors'],
      data,
    );
    if (errors) {
      errorToast(errors.message);
    } else {
      onDone?.();
    }
  };

  return (
    <ModalCard title="Edit invite">
      <Form initialValues={initiaValues} onSubmit={handleSubmit}>
        <FormContext.Consumer>
          {({ values, isValid }) => (
            <>
              <FormContent>
                <TextField name="label" label="Name" placeholder="Enter a label..." required />
                <Text size="xsmall" color="textLight">
                  Give this invite a name that will make it easier to find later
                </Text>
              </FormContent>
              <FormContent>
                <TextField name="sentToEmail" disabled label="Email" placeholder="Email..." />
              </FormContent>
              <CardFooter>
                <ModalCancelButton />
                <FormSubmitButton size="small" disabled={!isValid}>
                  Save
                </FormSubmitButton>
              </CardFooter>
            </>
          )}
        </FormContext.Consumer>
      </Form>
    </ModalCard>
  );
};

export default EditInviteModal;
