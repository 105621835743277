import { DetailedAPIError } from 'common/errors';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import type { Column } from 'react-table';
import styled from 'styled-components';

import CsvTable from 'components/accounts/CsvTable';
import ImportMintLoadingModal from 'components/accounts/ImportMintLoadingModal';
import AccountSelectField from 'components/lib/form/AccountSelectField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import Banner from 'components/lib/ui/Banner';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import Table from 'components/lib/ui/table/Table';
import type { BalanceHistoryFileInfo } from 'components/routes/accounts/ImportMintBalanceHistory';
import {
  ImportPageSubtitle,
  ImportPageTitle,
} from 'components/routes/accounts/ImportMintBalanceHistory';

import { unescapeFieldName } from 'common/lib/form/field';
import useLoading from 'common/lib/hooks/useLoading';
import { formatThousands } from 'common/utils/Number';
import useUploadBalanceHistorySession from 'lib/hooks/statements/useUploadBalanceHistorySession';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';
import useModal from 'lib/hooks/useModal';

import { IMPORT_BALANCE_HISTORY } from 'constants/copy';

import type { ArrayType } from 'common/types/utility';

const NextButton = styled(FormSubmitButton).attrs({ size: 'large' })`
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
  width: 440px;
`;

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TableContainer = styled.div`
  max-width: 765px;
  width: 100%;
  text-align: left;
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  border-radius: ${({ theme }) => theme.radius.medium};
  margin: ${({ theme }) => theme.spacing.xxlarge} 0;

  > * {
    margin-bottom: 0;
  }
`;

const ClickableText = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: ${({ theme }) => theme.spacing.xxsmall};

  :hover {
    color: ${({ theme }) => theme.color.blue};
  }
`;

const StyledBanner = styled(Banner)`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  filesMap: Map<string, BalanceHistoryFileInfo>;
  onFinish: () => void;
  setTotalUploadedFiles: (total: number) => void;
};

const PREVIEW_ROW_COUNT = 100;

const FileAccountMapping = ({ filesMap, onFinish, setTotalUploadedFiles }: Props) => {
  const [LoadingModal, { open: openLoadingModal, close: closeLoadingModal }] = useModal();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const { startSession, startParsing } = useUploadBalanceHistorySession({
    onParseSuccess: () => {
      closeLoadingModal();
      onFinish?.();
    },
    onParseError: () => {
      closeLoadingModal();
    },
  });

  const [PreviewRowsModal, { open: openPreviewRowsModal, context: previewingAccountName }] =
    useModal<string>();

  const IGNORE_ACCOUNT_VALUE = 'ignore';

  const TABLE_INITIAL_STATE = { sortBy: [{ id: 'csvFileName', desc: true }] };

  const fileNames = useMemo(() => Array.from(filesMap.keys()), [filesMap]);

  // we have rules to prevent balance history uploading for manual accounts on the backend
  const [isLoadingAccounts, accountOptions, { refetch: refetchAccountOptions }] =
    useAccountSelectOptions();

  const initialValues = useMemo(
    () => (filesMap ? R.reduce((acc, fileName) => R.assoc(fileName, '', acc), {}, fileNames) : {}),
    [],
  );

  const tableData = useMemo(
    () =>
      filesMap
        ? (R.toPairs(
            R.reduce(
              (acc, fileName) => R.assoc(fileName, filesMap.get(fileName)?.totalCount, acc),
              {},
              fileNames,
            ),
          ).map(([name, daysOfHistory]) => ({
            name,
            daysOfHistory: daysOfHistory as number,
          })) as { name: string; daysOfHistory: number }[])
        : [],
    [fileNames],
  );

  const totalDaysOfHistory = useMemo(() => R.sum(R.pluck('daysOfHistory', tableData)), [tableData]);

  const columns = useMemo(
    (): Column<ArrayType<typeof tableData>>[] => [
      {
        accessor: 'name',
        Header: IMPORT_BALANCE_HISTORY.STEPS.FILE_ACCOUNT_MAPPING.FIELDS.CSV_FILE,
        Cell: ({ value }) => <Text>{value}</Text>,
      },
      {
        id: 'account',
        Header: IMPORT_BALANCE_HISTORY.STEPS.FILE_ACCOUNT_MAPPING.FIELDS.MONARCH_ACCOUNT,
        accessor: 'name',
        disableSortBy: true,
        Cell: ({ value: accountName }) => (
          <AccountSelectField
            accountName={accountName}
            options={accountOptions}
            isLoading={isLoadingAccounts}
            ignoreAccountValue={IGNORE_ACCOUNT_VALUE}
            onAccountCreated={refetchAccountOptions}
          />
        ),
      },
      {
        id: 'history',
        Header: IMPORT_BALANCE_HISTORY.STEPS.FILE_ACCOUNT_MAPPING.FIELDS.DAYS_OF_HISTORY,
        accessor: 'daysOfHistory',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <ClickableText onClick={() => openPreviewRowsModal(row.original.name)}>
            {formatThousands(value)}
          </ClickableText>
        ),
      },
    ],
    [isLoadingAccounts, accountOptions, refetchAccountOptions],
  );

  const [isLoading, uploadFiles] = useLoading(async (formData: FormData) => {
    setErrorMessage(undefined);

    try {
      const { sessionKey } = await startSession(formData);

      if (sessionKey) {
        await startParsing({ sessionKey });
      }
    } catch (e: any) {
      if (e instanceof DetailedAPIError) {
        const error = e?.message
          ? JSON.parse(e?.message).detail
          : IMPORT_BALANCE_HISTORY.DEFAULT_ERROR_MESSAGE;
        setErrorMessage(error);
      } else {
        setErrorMessage(IMPORT_BALANCE_HISTORY.DEFAULT_ERROR_MESSAGE);
      }

      closeLoadingModal();
    }
  });

  const previewHeader = useMemo(() => {
    const [firstKey] = filesMap.entries().next().value;

    return filesMap.get(firstKey)?.header ?? [];
  }, [filesMap]);

  const onSubmit = async (values: { mapping: Record<string, string>; importPriority: string }) => {
    openLoadingModal();

    // mapping is an object with keys as account names, it has only the files to be uploaded at this point.
    setTotalUploadedFiles(Object.keys(values?.mapping).length || 0);

    const formData = new FormData();
    filesMap?.forEach((value, key) => {
      formData.append(`files`, value.file, key);
    });

    formData.append('account_files_mapping', JSON.stringify(values.mapping));

    uploadFiles(formData);
  };

  return (
    <>
      <ImportPageTitle>{IMPORT_BALANCE_HISTORY.STEPS.FILE_ACCOUNT_MAPPING.TITLE}</ImportPageTitle>
      <ImportPageSubtitle>
        {IMPORT_BALANCE_HISTORY.STEPS.FILE_ACCOUNT_MAPPING.SUBTITLE}
      </ImportPageSubtitle>

      {RA.isNotNil(errorMessage) && <StyledBanner type="error">{errorMessage}</StyledBanner>}

      <StyledForm
        // @ts-ignore styled-components doesn't like generics
        onSubmit={onSubmit}
        initialValues={initialValues}
        transformValuesBeforeSubmit={(values) => ({
          mapping: R.pipe(
            R.pickBy((val) => val !== IGNORE_ACCOUNT_VALUE && val !== ''),
            RA.renameKeysWith(unescapeFieldName),
          )(R.omit([''], values)),
        })}
      >
        <TableContainer>
          <Table
            columns={columns}
            data={tableData}
            showFooter={false}
            initialState={TABLE_INITIAL_STATE}
            empty={{
              title: IMPORT_BALANCE_HISTORY.STEPS.FILE_ACCOUNT_MAPPING.NO_CSV_FILES.TITLE,
              subtitle: IMPORT_BALANCE_HISTORY.STEPS.FILE_ACCOUNT_MAPPING.NO_CSV_FILES.SUBTITLE,
            }}
          />
        </TableContainer>

        <NextButton pending={isLoading}>{IMPORT_BALANCE_HISTORY.NEXT_BUTTON}</NextButton>
      </StyledForm>
      <LoadingModal nonDismissable>
        <ImportMintLoadingModal
          title={IMPORT_BALANCE_HISTORY.STEPS.FILE_ACCOUNT_MAPPING.LOADING_MODAL_TITLE}
          steps={IMPORT_BALANCE_HISTORY.STEPS.FILE_ACCOUNT_MAPPING.LOADING_MODAL_STEPS}
          entriesToUpload={totalDaysOfHistory}
        />
      </LoadingModal>
      <PreviewRowsModal large>
        <ModalCard title={previewingAccountName}>
          <CsvTable
            header={previewHeader}
            rows={R.take(PREVIEW_ROW_COUNT, filesMap.get(previewingAccountName ?? '')?.rows ?? [])}
          />
        </ModalCard>
      </PreviewRowsModal>
    </>
  );
};

export default FileAccountMapping;
