import axios from 'axios';

export type InstatusActiveMaintenance = {
  id: string;
  name: string;
  start: Date;
  status: 'NOTSTARTEDYET' | 'INPROGRESS' | 'COMPLETED';
  duration: number;
  url: string;
};

export type InstatusActiveIncident = {
  id: string;
  name: string;
  started: Date;
  status: 'INVESTIGATING' | 'IDENTIFIED' | 'MONITORING' | 'RESOLVED';
  impact: string;
  url: string;
};

export type InstatusPage = {
  name: string;
  url: string;
  status: 'UP' | 'HASISSUES' | 'UNDERMAINTENANCE';
};

export type InstatusResponse = {
  page: InstatusPage;
  activeIncidents: InstatusActiveIncident[];
  activeMaintenances: InstatusActiveMaintenance[];
};

// this is not a real API, but just a JSON endpoint that summarizes the current app status
const InstatusApi = axios.create({
  baseURL: 'https://status.monarchmoney.com/summary.json',
});

/**
 * Wrapper for InStatus JSON endpoint.
 * https://instatus.com/help/widgets/custom
 */
const instatus = {
  get: async () => {
    const response = await InstatusApi.get('');
    return (response.data ?? {}) as InstatusResponse;
  },
};

export default instatus;
