import { singularSdk, SingularConfig } from 'singular-sdk';

export const initSingular = () => {
  const config = new SingularConfig(
    'monarch_money_85497080',
    process.env.REACT_APP_SINGULAR_KEY,
    'com.monarchmoney.web.app',
  ).withAutoPersistentSingularDeviceId('monarchmoney.com');

  singularSdk.init(config);
};

export const identifyUserToSingular = (userId: string) => {
  singularSdk.login(userId);
};
