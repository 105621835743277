import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import {
  GET_BUDGET_SETTINGS_QUERY,
  UPDATE_BUDGET_SETTINGS_MUTATION,
} from 'common/lib/graphQl/budgets';
import { GET_JOINT_PLANNING_DATA } from 'common/lib/graphQl/planning';
import useQuery from 'common/lib/hooks/useQuery';

import type { BudgetSystem, PayloadErrorFieldsFragment } from 'common/generated/graphql';
import type { QueryHookOptionsFromDocument } from 'common/types/graphql';

/*
  Allows fetching and updating the budget system.
  It also fetches values for the rollover period because the mutation requires all values.
*/

type UpdateOptions = {
  onError?: (errorMessage: string | null | undefined) => void;
  onDone?: () => void;
};

type Options = QueryHookOptionsFromDocument<typeof GET_BUDGET_SETTINGS_QUERY>;

const useBudgetSystem = (options?: Options) => {
  const {
    data,
    error,
    isLoadingInitialData: loading,
  } = useQuery(GET_BUDGET_SETTINGS_QUERY, options);

  const [updateBudgetSettings, { loading: updateLoading }] = useMutation(
    UPDATE_BUDGET_SETTINGS_MUTATION,
    {
      refetchQueries: [GET_JOINT_PLANNING_DATA],
      awaitRefetchQueries: true,
    },
  );

  const performMutation = async (
    selectedBudgetSystem: BudgetSystem,
    { onError, onDone }: UpdateOptions,
  ) => {
    const { data: updateData } = await updateBudgetSettings({
      variables: {
        input: {
          budgetSystem: selectedBudgetSystem,
          // Just pass the current values since the mutation requires all of them.
          // TODO: We should refactor this to accept a partial update.
          rolloverEnabled: RA.isNotNil(data?.flexExpenseRolloverPeriod),
          rolloverStartMonth: data?.flexExpenseRolloverPeriod?.startMonth,
          rolloverStartingBalance: data?.flexExpenseRolloverPeriod?.startingBalance,
        },
      },
    });

    const errors: PayloadErrorFieldsFragment | undefined = R.path(
      ['updateCategoryGroup', 'errors'],
      updateData,
    );
    if (errors) {
      onError?.(errors.message);
    } else {
      onDone?.();
    }
  };

  return {
    loading,
    data,
    error,
    updateBudgetSystem: performMutation,
    updateLoading,
  };
};

export default useBudgetSystem;
