import React from 'react';
import styled from 'styled-components';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import type { Props as TextInputProps } from 'components/lib/form/TextInput';
import TextInput from 'components/lib/form/TextInput';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';

import fieldErrorBorderMixin from 'lib/styles/fieldErrorBorderMixin';

const Text = styled(TextInput)<{ error?: boolean }>`
  ${fieldErrorBorderMixin}
`;

const TextField = ({
  className,
  onBlur,
  isSensitive,
  innerRef,
  ...props
}: AbstractFieldProps<TextInputProps>) => (
  <AbstractField {...props} className={className}>
    {({ id, value, displayErrors, handleChange, handleBlur }) => (
      <Text
        {...props}
        id={id}
        onChange={handleChange}
        onBlur={(e) => {
          handleBlur(e);
          onBlur?.(e);
        }}
        value={value ?? ''}
        error={displayErrors.length > 0}
        ref={innerRef}
        {...(isSensitive ? sensitiveClassProps : {})}
      />
    )}
  </AbstractField>
);

export default TextField;
