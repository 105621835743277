import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';

import MessageCard from 'components/assistant/MessageCard';
import type { TooltipComponentProps } from 'components/lib/charts/ChartTooltip';
import ChartTooltip from 'components/lib/charts/ChartTooltip';
import ChartTooltipDataRow from 'components/lib/charts/ChartTooltipDataRow';
import MultipleLineChart from 'components/lib/charts/MultipleLineChart';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import { LineChartBlock } from 'common/lib/assistant/blocks';
import { makeBlockRenderer } from 'common/lib/assistant/blocks/render';
import { formatXAxis } from 'common/lib/assistant/charts';
import { CHART_COLORS, CHART_HEIGHT_PX } from 'common/lib/assistant/constants';
import { formatCurrency, formatCurrencyMaybeThousands } from 'common/utils/Currency';
import useMeasure from 'lib/hooks/useMeasure';

const PADDING_PX = 16;

type LineInfo = {
  dataKey: string;
  lineColor: string;
  label: string;
  valueFormatter?: (value: number) => string;
};

const Root = styled.div`
  width: 70%;

  @media (max-width: ${({ theme }) => theme.breakPoints.xl}px) {
    width: 99%; /* weird bug where 100% width causes overflow */
  }
`;

const StyledMessageCard = styled(MessageCard)`
  padding-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true, column: true })`
  height: ${CHART_HEIGHT_PX}px;
  width: 100%;
`;

const TooltipContent =
  (lines: LineInfo[]): TooltipComponentProps<string, string, Record<string, any>> =>
  ({ payload, active }) => {
    const { label, ...values } = payload[0]?.payload ?? {};

    return (
      <ChartTooltip active={active} header={label}>
        {lines.map(({ dataKey, lineColor, label, valueFormatter }, i) => (
          <ChartTooltipDataRow
            key={i}
            dotFillColor={lineColor}
            label={label}
            value={valueFormatter ? valueFormatter(values[dataKey]) : values[dataKey]}
          />
        ))}
      </ChartTooltip>
    );
  };

const LineChartBlockRenderer = makeBlockRenderer(LineChartBlock)(({
  data: { chartData, columns },
  incomplete,
}) => {
  const theme = useTheme();

  const lines = useMemo(
    () =>
      columns.map(
        ({ label, valueType }, i): LineInfo => ({
          dataKey: `${label}-${i}`,
          lineColor: theme.color[CHART_COLORS[i % CHART_COLORS.length]],
          label,
          valueFormatter: (value) =>
            valueType === 'currency' ? formatCurrency(Math.abs(value ?? 0)) : `${value}`,
        }),
      ),
    [columns, theme],
  );

  const data = useMemo(
    () =>
      chartData.map(({ label, values }) => ({
        label: formatXAxis(label),
        ...lines.reduce(
          (acc, { dataKey }, i) => ({
            ...acc,
            // TODO: how to handle math.abs?
            [dataKey]: Math.abs(values[i] ?? 0),
          }),
          {},
        ),
      })),
    [chartData, lines],
  );

  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();

  return (
    <Root ref={ref}>
      <StyledMessageCard>
        {incomplete ? (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        ) : (
          <MultipleLineChart
            height={CHART_HEIGHT_PX}
            width={width - PADDING_PX * 2}
            // @ts-ignore it is not expecting label in the data
            data={data}
            yDataKeys={lines.map(({ dataKey }) => dataKey)}
            lineOptions={lines}
            xDataKey="label"
            tooltipComponent={TooltipContent(lines)}
            margin={{ left: 8 }}
            formatYAxis={
              columns[0]?.valueType === 'currency'
                ? formatCurrencyMaybeThousands
                : (value) => `${value}`
            }
            formatXAxis={formatXAxis}
            isAnimationActive={false}
          />
        )}
      </StyledMessageCard>
    </Root>
  );
});

export default LineChartBlockRenderer;
