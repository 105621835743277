import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import useToggle from 'common/lib/hooks/useToggle';
import useDownloadBalances from 'lib/hooks/accounts/useDownloadBalances';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';

const Spinner = styled(LoadingSpinner)`
  margin: 0 0 0 ${({ theme }) => theme.spacing.xxsmall};
  width: 21px;
  height: 21px;
`;

const DownloadAccountsBalancesForm = () => {
  const { downloadBalances } = useDownloadBalances();

  const { isDemoHousehold } = useDemoHousehold();

  const [isLoadingAccounts, accountOptions] = useAccountSelectOptions();

  const [isLoading, { setOn: setIsLoading, setOff: disableIsLoading }] = useToggle(false);

  const downloadAccountBalances = async (accountId: string) => {
    setIsLoading();
    await downloadBalances([accountId]);
    disableIsLoading();
  };

  return (
    <Form
      initialValues={{
        accounts: [],
      }}
      onSubmit={async ({ ...values }) => {
        const accountId = String(values?.accounts);
        downloadAccountBalances(accountId);
      }}
    >
      <SelectField
        name="accounts"
        label="Accounts"
        placeholder="Select an account"
        isLoading={isLoadingAccounts}
        options={accountOptions}
      />

      <FormSubmitButton disabled={isLoading || isDemoHousehold}>
        Download account balances {isLoading && <Spinner />}
      </FormSubmitButton>
    </Form>
  );
};

export default DownloadAccountsBalancesForm;
