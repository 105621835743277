import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';

export const CustomerIO = new AnalyticsBrowser();

export const initializeCustomerIO = () => {
  if (!process.env.REACT_APP_CUSTOMER_IO_WRITE_KEY || !process.env.REACT_APP_CUSTOMER_IO_SITE_ID) {
    return;
  }

  setTimeout(
    () =>
      CustomerIO.load(
        { writeKey: process.env.REACT_APP_CUSTOMER_IO_WRITE_KEY },
        {
          integrations: {
            'Customer.io In-App Plugin': {
              siteId: process.env.REACT_APP_CUSTOMER_IO_SITE_ID,
            },
          },
        },
      ),
    1000,
  );
};

export const identifyUserToCustomerIO = (id: string, email: string) => {
  setTimeout(() => CustomerIO.identify(id, { email }), 2000);
};
