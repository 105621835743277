import React, { useState } from 'react';

import RotateAnimation from 'components/lib/animations/RotateAnimation';
import Icon from 'components/lib/ui/Icon';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import useForceRefreshAllAccounts from 'common/lib/hooks/accounts/useForceRefreshAllAccounts';
import typewriter from 'lib/analytics/typewriter';
import toast, { dismissToast } from 'lib/ui/toast';

import {
  REFRESHING_CONNECTIONS_TOAST_TITLE,
  REFRESHING_CONNECTIONS_TOAST_SUBTITLE,
} from 'common/constants/copy';
import { MS_PER_MINUTE } from 'common/constants/time';

export const REFRESHING_CONNECTIONS_TOAST_TIMEOUT_MS = MS_PER_MINUTE * 10;

const RefreshAllButton = () => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>();
  const [refreshAllToastId, setRefreshAllToastId] = useState<string | undefined>();

  const [forceRefresh] = useForceRefreshAllAccounts({
    onDidStartRefresh: () => {
      setIsRefreshing(true);
      typewriter.credentialRefreshAllRequested();

      const toastId = toast({
        title: REFRESHING_CONNECTIONS_TOAST_TITLE,
        description: REFRESHING_CONNECTIONS_TOAST_SUBTITLE,
        duration: REFRESHING_CONNECTIONS_TOAST_TIMEOUT_MS,
        accessory: (
          <RotateAnimation>
            <Icon name="refresh" />
          </RotateAnimation>
        ),
      });
      setRefreshAllToastId(toastId);
    },
    onDidFinishRefresh: () => {
      setIsRefreshing(false);

      dismissToast(refreshAllToastId);
    },
  });

  return (
    <DefaultButton onClick={forceRefresh} disabled={isRefreshing}>
      <ButtonIcon name="refresh" />
      <span>Refresh all</span>
    </DefaultButton>
  );
};

export default RefreshAllButton;
