import pluralize from 'pluralize';
import { transparentize } from 'polished';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Dot from 'components/lib/ui/Dot';
import DragDots from 'components/lib/ui/DragDots';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import DefaultButton, { defaultButtonMixin } from 'components/lib/ui/button/DefaultButton';

import routes from 'constants/routes';

import type { GetHouseholdTransactionTags_householdTransactionTags } from 'common/generated/graphQlTypes/GetHouseholdTransactionTags';

const DEFAULT_SPACING_PX = 10;

const StyledDragDots = styled(DragDots)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  opacity: 0;
`;

const Root = styled(FlexContainer).attrs({ alignCenter: true })<{
  $isDeleting?: boolean;
  $isDragging?: boolean;
}>`
  padding: ${DEFAULT_SPACING_PX}px;
  padding-left: 0;
  border-radius: ${({ theme }) => theme.radius.small};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  transition:
    box-shadow 0.1s ease-in-out,
    opacity 0.2s ease-in-out;
  box-shadow: ${({ theme, $isDragging }) =>
    $isDragging
      ? `0 8px 16px ${transparentize(0.95, theme.color.black)};`
      : `0 4px 8px transparent`};
  opacity: ${({ $isDeleting, $isDragging }) => ($isDeleting || $isDragging ? 0.8 : 1)};
  background-color: ${({ theme }) => theme.color.white};
  cursor: pointer;

  &:hover,
  &:active {
    ${StyledDragDots} {
      opacity: 1;
    }
  }
`;

const ContentWrapper = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  @media (max-width: ${({ theme }) => theme.breakPoints.lg}px) {
    flex-direction: column;
  }
`;

const Content = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakPoints.lg}px) {
    flex-direction: column;
  }
`;

const Name = styled(Text)`
  transform: translateY(-1px);
`;

const StyledDot = styled(Dot)`
  margin-right: ${({ theme }) => theme.spacing.default};
  flex-shrink: 0;
`;

const Count = styled(Text).attrs({ size: 'xsmall', color: 'textLight', weight: 'bold' })`
  text-transform: uppercase;
  letter-spacing: 0.08em;

  &:before {
    content: '・';
    margin: 0 ${({ theme }) => theme.spacing.xxsmall};
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.lg}px) {
    margin: ${({ theme }) => theme.spacing.xsmall} 0;

    &:before {
      content: unset;
    }
  }
`;

const ButtonContainer = styled(FlexContainer)`
  --horizontal-spacing: ${DEFAULT_SPACING_PX}px;

  ${DefaultButton}:not(:last-child) {
    margin-right: var(--horizontal-spacing);
  }

  ${DefaultButton} {
    padding-right: var(--horizontal-spacing);
    padding-left: var(--horizontal-spacing);
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.lg}px) {
    margin-top: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const DeleteButton = styled(AsyncButton)`
  ${defaultButtonMixin}
`;

const StyledLink = styled(Link)<{ $disabled: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'initial' : 'pointer')};
`;

type Props = {
  tag: GetHouseholdTransactionTags_householdTransactionTags;
  className?: string;
  isDragging?: boolean;
  isDeleting?: boolean;
  onClickEdit: () => void;
  onClickDelete: () => void;
};

const TransactionTagSettingsRow = ({
  tag,
  className,
  isDragging,
  isDeleting,
  onClickEdit,
  onClickDelete,
}: Props) => {
  const { id, name, color, transactionCount } = tag;

  const canClick = transactionCount > 0;
  const history = useHistory();
  const onClickTransactionCount = () =>
    canClick ? history.push(routes.transactions({ queryParams: { tags: [id] } })) : null;

  return (
    <Root className={className} $isDeleting={isDeleting} $isDragging={isDragging}>
      <StyledDragDots />
      <Content>
        <ContentWrapper>
          <FlexContainer alignCenter>
            <StyledDot color={color} />
            <Name>{name}</Name>
          </FlexContainer>
          <Count>
            <StyledLink
              onClick={onClickTransactionCount}
              unstyled={!canClick}
              $disabled={!canClick}
              stealthy
            >
              {transactionCount} {pluralize('transaction', transactionCount)}
            </StyledLink>
          </Count>
        </ContentWrapper>
        <ButtonContainer>
          <DefaultButton size="small" onClick={onClickEdit} disabled={isDeleting}>
            Edit
          </DefaultButton>
          <DeleteButton onClick={onClickDelete} pending={isDeleting} disabled={isDeleting}>
            Delete
          </DeleteButton>
        </ButtonContainer>
      </Content>
    </Root>
  );
};

export default TransactionTagSettingsRow;
