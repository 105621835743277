import type { ReactNode } from 'react';
import React from 'react';
// import the carousel and its CSS
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import type { DotProps } from 'react-multi-carousel/lib/types';
import styled, { useTheme } from 'styled-components';

const Dot = styled.button<{ $isActive?: boolean }>`
  background: ${({ theme, $isActive }) => ($isActive ? theme.color.black : theme.color.gray)};
  width: 6px;
  height: 6px;
  border-radius: ${({ theme }) => theme.radius.xsmall};
  border: none;
  display: block;
  padding: 0;
  margin: -${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xxxsmall} 0;
`;

const CustomDot = ({ active, onClick }: DotProps) => (
  <Dot
    $isActive={active}
    onClick={(e) => {
      onClick && onClick();
      e.preventDefault();
    }}
  />
);

const DeviceCarousel = styled(Carousel)`
  width: 100%;
`;

type Props = {
  children: ReactNode;
};

const OnboardingCarousel = ({ children }: Props) => {
  const theme = useTheme();

  const carouselResponsiveConfig = {
    desktop: {
      breakpoint: { max: 99999, min: theme.breakPoints.sm }, // the Carousel requires a max, so I added a large one
      items: 1,
    },
    tablet: {
      breakpoint: { max: theme.breakPoints.sm, min: theme.breakPoints.xs },
      items: 1,
    },
    mobile: {
      breakpoint: { max: theme.breakPoints.xs, min: 0 },
      items: 1,
    },
  };
  return (
    <DeviceCarousel
      swipeable
      draggable
      showDots
      responsive={carouselResponsiveConfig}
      slidesToSlide={1}
      infinite
      autoPlay
      autoPlaySpeed={5000}
      keyBoardControl
      arrows={false}
      renderButtonGroupOutside
      customDot={<CustomDot />}
    >
      {children}
    </DeviceCarousel>
  );
};

export default OnboardingCarousel;
