import { sort, without } from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import Draggable from 'components/lib/dndKit/Draggable';
import Droppable from 'components/lib/dndKit/Droppable';
import CardTitle from 'components/lib/ui/CardTitle';
import EmptyStateCard from 'components/lib/ui/EmptyStateCard';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import ContextMenu, {
  ContextMenuItem,
  ContextMenuSeparator,
} from 'components/lib/ui/menu/ContextMenu';
import OnboardingVariabilityItem from 'components/plan/onboarding/OnboardingVariabilityItem';

import { BUDGET_VARIABILITY_TO_TITLE_MAPPING } from 'common/lib/budget/constants';
import { color, radius, spacing, transition } from 'common/lib/theme/dynamic';
import type { PlanCategory } from 'lib/plan/types';

import { FIXED_FLEXIBLE_NON_MONTHLY_INTRODUCTION } from 'common/constants/copy';

import type { BudgetVariability } from 'common/generated/graphql';

const Root = styled(FlexContainer).attrs({
  column: true,
  gap: 'xsmall',
})<{ $isLast: boolean }>`
  padding: ${spacing.large};
  border-bottom: ${({ $isLast }) => ($isLast ? 'none' : `1px solid ${color.grayFocus}`)};
`;

const Paragraph = styled(FlexContainer).attrs({
  column: true,
})`
  margin-bottom: ${spacing.xxsmall};
`;

const StyledDroppable = styled(Droppable)`
  background: ${color.grayBackground};
  transition: ${transition.fast};
  border-radius: ${radius.medium};
  padding: ${spacing.small};
  gap: ${spacing.small};
  flex-wrap: wrap;
  display: flex;
`;

type Props = {
  title: string;
  description: string;
  variability: BudgetVariability;
  items: PlanCategory[];
  isLast: boolean;
  onEditCategory: (id: string) => void;
  onHideFromBudget: (id: string) => void;
  onDeleteOrDisableCategory: (id: string) => void;
  onMoveTo: (id: string, variability: BudgetVariability) => void;
};

const OnboardingVariabilityCardSection = ({
  title,
  description,
  variability,
  items,
  isLast,
  onEditCategory,
  onHideFromBudget,
  onDeleteOrDisableCategory,
  onMoveTo,
}: Props) => {
  const containerId = variability;
  const visibleCategories = useMemo(
    () =>
      sort(
        // Make sure the most recently updated category is at the end of the list
        (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
        items.filter((item) => !item.excludeFromBudget),
      ),
    [items],
  );

  const optionsToMoveTo = useMemo(() => {
    // The constant has the correct order, so we use it to get the options
    const orderedVariabilities = FIXED_FLEXIBLE_NON_MONTHLY_INTRODUCTION.map(
      ({ variability }) => variability,
    );
    return without([variability], orderedVariabilities);
  }, [variability]);

  return (
    <Root $isLast={isLast}>
      <Paragraph>
        <Text weight="medium">{title}</Text>
        <Text size="small">{description}</Text>
      </Paragraph>
      <CardTitle>Your Categories</CardTitle>
      <StyledDroppable
        id={containerId}
        data={{
          // This is used in handleDragEnd to determine if we should fire a network request
          variability,
        }}
      >
        {visibleCategories.map((item) => (
          <ContextMenu
            key={item.id}
            items={
              <>
                {optionsToMoveTo.map((option) => (
                  <ContextMenuItem key={option} onClick={() => onMoveTo(item.id, option)}>
                    Move to {BUDGET_VARIABILITY_TO_TITLE_MAPPING[option]}
                  </ContextMenuItem>
                ))}
                <ContextMenuSeparator />
                <ContextMenuItem onClick={() => onEditCategory(item.id)}>
                  Edit category
                </ContextMenuItem>
                <ContextMenuItem onClick={() => onHideFromBudget(item.id)}>
                  Hide from budget
                </ContextMenuItem>
                <ContextMenuItem type="danger" onClick={() => onDeleteOrDisableCategory(item.id)}>
                  {item.isSystemCategory ? 'Disable' : 'Delete'} category
                </ContextMenuItem>
              </>
            }
          >
            <Draggable
              id={item.id.toString()}
              data={{
                droppableId: containerId,
                // This is used in handleDragEnd to determine if we should fire a network request
                variability,
              }}
            >
              <OnboardingVariabilityItem item={item} />
            </Draggable>
          </ContextMenu>
        ))}

        {!visibleCategories.length && (
          <EmptyStateCard title="No categories to show" width={380}>
            Move a category here to mark it as {BUDGET_VARIABILITY_TO_TITLE_MAPPING[variability]}.
          </EmptyStateCard>
        )}
      </StyledDroppable>
    </Root>
  );
};

export default OnboardingVariabilityCardSection;
