import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import Radio, { CircleIndicator } from 'components/lib/form/Radio';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import FilterMenuOptionAccessory from 'components/reports/filters/FilterMenuOptionAccessory';
import FilterMenuOptionRow from 'components/reports/filters/FilterMenuOptionRow';
import type {
  FilterMenuOption,
  WithPath,
  FilterMenuOptionInput,
} from 'components/reports/filters/types';
import { areAllChildrenSelected, getAllLeavesForOptions } from 'components/reports/filters/utils';

import { fontSize, spacing } from 'common/lib/theme/dynamic';

const Row = styled(FlexContainer)`
  align-items: center;
  padding: ${spacing.xxsmall} 0;
`;

const RadioRow = styled(Row)<{ isSelected?: boolean }>`
  cursor: pointer;
  font-size: ${fontSize.small};

  ${CircleIndicator} {
    margin-right: ${spacing.xsmall};
  }
`;

type Props = {
  /** Don't show indent hierarchy */
  flat?: boolean;
  options: WithPath<FilterMenuOption>[];
  onChangeSelected: (path: string[], selected: boolean) => void;
  onInputChange: (
    path: string[],
    optionId: string,
    input: FilterMenuOptionInput,
    value: unknown,
  ) => void;
};

const FilterMenuOptionsList = ({ options, onChangeSelected, flat, onInputChange }: Props) => {
  const selectedOptionsById = useMemo(() => {
    const selectedOptions = getAllLeavesForOptions(options).filter((option) => option.isSelected);
    return selectedOptions.reduce(
      (acc, option) => ({ ...acc, [option.id]: true }),
      {} as Record<string, boolean>,
    );
  }, [options]);

  return (
    <>
      {options.map(({ id, name, path, options: subOptions, isSelected, icon, accessory, type }) => (
        <React.Fragment key={id}>
          {type === 'radio' ? (
            <>
              <Row>
                <Text size="xsmall" weight="medium" color="textLight">
                  {name}
                </Text>
              </Row>
              <Radio
                RowComponent={RadioRow}
                options={
                  subOptions?.map((subOption: WithPath<FilterMenuOption>) => ({
                    value: subOption.id,
                    label: subOption.name,
                    accessory: selectedOptionsById[subOption.id] && (
                      <FilterMenuOptionAccessory option={subOption} onInputChange={onInputChange} />
                    ),
                  })) ?? []
                }
                value={{ value: subOptions?.find(({ isSelected }) => isSelected)?.id }}
                onChange={(value) => {
                  // first, deselect the current value in this section
                  onChangeSelected(path, false);

                  // then, this is hacky, but timeout so the deselect is applied first
                  setTimeout(() => {
                    const id = RA.isArray(value) ? value[0]?.value : value.value;
                    id && onChangeSelected([...path, id], true);
                  }, 0);
                }}
              />
            </>
          ) : (
            <>
              <FilterMenuOptionRow
                name={name}
                indent={flat ? 0 : path.length - 1}
                isGroup={!!subOptions?.length}
                isSelected={isSelected || (subOptions && areAllChildrenSelected(subOptions))}
                onChangeSelected={(selected) => onChangeSelected(path, selected)}
                accessory={accessory}
                icon={icon}
              />
              {subOptions && (
                <FilterMenuOptionsList
                  options={subOptions}
                  onChangeSelected={onChangeSelected}
                  onInputChange={onInputChange}
                />
              )}
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default FilterMenuOptionsList;
