import { useRef } from 'react';

/**
 * Hook to always maintain an updated reference to a value. This is useful in callbacks where
 * we want to access changing state.
 *
 * const [count, setCount] = useState(0)
 * const alwaysUpToDateCount = useCurrentReference(count)
 *
 * const cb = useCallback(() => {
 *   console.log(count) // 0
 *   setCount(count => count + 1)
 *
 *   console.log(count) // 0
 *   console.log(alwaysUpToDateCount.current) // 1
 * }, [count])
 * cb()
 *
 * https://stackoverflow.com/a/60643670/2974996
 */
const useCurrentReference = <T>(value: T) => {
  const ref = useRef<T>(value);
  ref.current = value;
  return ref;
};

export default useCurrentReference;
