import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import SummaryProgressBar from 'components/budget/SummaryProgressBar';
import FlexContainer from 'components/lib/ui/FlexContainer';
import { ColorIndicator } from 'components/lib/ui/ProgressBar';
import type { Tick } from 'components/lib/ui/ProgressBar';
import Text from 'components/lib/ui/Text';
import Currency from 'components/lib/ui/currency/Currency';
import RolloverIcon from 'components/plan/RolloverIcon';

import type { SummaryDisplayBudgetType } from 'common/lib/budget/Adapters';
import {
  ExcessBudgetType,
  getExcessAmount,
  getSafeToSpendAmount,
  getExpectedExpense,
} from 'common/lib/budget/Adapters';
import { getDisplayProgressPercent } from 'common/lib/budget/Amounts';
import { formatCurrencyNoCents } from 'common/utils/Currency';

import { BudgetVariability, CategoryGroupType } from 'common/generated/graphql';
import type { Color } from 'types/Styles';

const LOADING_DATA: SummaryDisplayBudgetType[] = [
  {
    title: 'Income',
    budgeted: null,
    actual: null,
    type: CategoryGroupType.INCOME,
  },
  {
    title: 'Expenses',
    budgeted: null,
    actual: null,
    type: CategoryGroupType.EXPENSE,
  },
  {
    title: 'Goals',
    budgeted: null,
    actual: null,
    type: undefined,
  },
];

type Props = {
  data: SummaryDisplayBudgetType[];
  ticks?: Tick[];
  animated?: boolean;
  isLoading?: boolean;
  shouldShowSafeToSpend?: boolean;
};

const Root = styled.div``;

const Row = styled.div`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const StyledRolloverIcon = styled(RolloverIcon)`
  width: 14px;
  height: 14px;
  margin-right: 6px;
  color: ${({ theme }) => theme.color.textLight};
`;

const Total = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.textLight};
`;

const Actual = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Title = styled(Text)`
  min-width: 40px;
  margin-right: ${({ theme }) => theme.spacing.default};
  color: ${({ theme }) => theme.color.textLight};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  text-transform: uppercase;
`;

const Variabilities = styled.div`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const VariabilityContainer = styled(FlexContainer)``;

const SafeToSpendLabel = styled(Text)`
  color: ${({ theme }) => theme.color.textLight};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const SafeToSpendCurrency = styled(Currency)`
  color: ${({ theme }) => theme.color.greenText};
`;

const TooltipRoot = styled.div`
  padding: ${({ theme }) => theme.spacing.xsmall};
  width: 150px;
`;

const TooltipTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const TooltipBody = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.book};
`;

const BaseTooltip = ({
  title,
  body,
  color,
  striped,
}: {
  title: React.ReactNode;
  body: React.ReactNode;
  color: Color;
  striped?: boolean;
}) => (
  <TooltipRoot>
    <TooltipTitle>
      <ColorIndicator color={color} striped={striped} />
      {title}
    </TooltipTitle>
    <TooltipBody>{body}</TooltipBody>
  </TooltipRoot>
);

const labelByType = (type: string | undefined): string => {
  switch (type) {
    case CategoryGroupType.INCOME:
      return 'earned';
    case CategoryGroupType.EXPENSE:
      return 'spent';
    case BudgetVariability.FIXED:
    case BudgetVariability.FLEXIBLE:
    case BudgetVariability.NON_MONTHLY:
      return 'spent';
    default:
      return 'saved';
  }
};

const getColorForType = (type?: string) => {
  switch (type) {
    case CategoryGroupType.INCOME:
      return 'green';
    case CategoryGroupType.EXPENSE:
      return 'red';
    default:
      return 'blue';
  }
};

const BudgetSummary = ({
  ticks,
  data: passedData,
  animated,
  isLoading,
  shouldShowSafeToSpend,
}: Props) => {
  const data = isLoading ? LOADING_DATA : passedData;
  const budgetSummaryData = data.filter(({ type }) => type !== ExcessBudgetType);

  const safeToSpend = getSafeToSpendAmount(data) ?? 0;
  const expected = getExpectedExpense(data);

  return (
    <Root>
      {budgetSummaryData.map(
        ({ title, actual, budgeted, remaining, rollover, type, budgetVariabilities }) => (
          <Row key={title}>
            <Text>{title}</Text>
            <SummaryProgressBar
              actual={isLoading ? undefined : actual ?? 0}
              total={isLoading ? undefined : (budgeted ?? 0) + (rollover ?? 0)}
              ticks={ticks}
              animated={animated}
              extraBar={
                type === CategoryGroupType.EXPENSE &&
                budgetVariabilities?.find((x) => x.title === 'Flex')
                  ? {
                      value: getDisplayProgressPercent(
                        expected,
                        (budgeted ?? 0) + (rollover ?? 0) ?? 0,
                      ),
                      color: 'redFocus',
                      striped: true,
                      tooltipContent: (
                        <BaseTooltip
                          color="redFocus"
                          striped
                          title={`${formatCurrencyNoCents(expected)} expected`}
                          body={
                            <>
                              This is the amount of remaining expenses you have budgeted in
                              &quot;Fixed&quot; and &quot;Yearly&quot; groups & categories.
                            </>
                          }
                        />
                      ),
                    }
                  : undefined
              }
              tooltipContent={
                <BaseTooltip
                  color={getColorForType(type)}
                  title={`${formatCurrencyNoCents(actual)} ${labelByType(type)}`}
                  body={<>This is the amount you have {labelByType(type)} so far this month.</>}
                />
              }
              backgroundBarTooltipContent={
                <Switch>
                  <Case
                    when={
                      type === CategoryGroupType.EXPENSE &&
                      budgetVariabilities?.find((x) => x.title === 'Flex') !== undefined
                    }
                  >
                    <BaseTooltip
                      color="grayBackground"
                      title={`${formatCurrencyNoCents(safeToSpend)} safe to spend`}
                      body={
                        <>
                          This is the amount of remaining expenses you have budgeted in “Flexible”
                          groups & categories.
                        </>
                      }
                    />
                  </Case>

                  <Case
                    when={
                      type === CategoryGroupType.EXPENSE &&
                      budgetVariabilities?.find((x) => x.title === 'Flex') === undefined
                    }
                  >
                    <BaseTooltip
                      color="grayBackground"
                      title={`${formatCurrencyNoCents(remaining)} remaining`}
                      body={<>This is the amount of remaining expenses you have budgeted.</>}
                    />
                  </Case>

                  <Case when={type === CategoryGroupType.INCOME}>
                    <BaseTooltip
                      color="grayBackground"
                      title={`${formatCurrencyNoCents(remaining)} remaining`}
                      body={<>This is the amount of remaining income you have budgeted.</>}
                    />
                  </Case>

                  <Case when={type === 'savings'}>
                    <BaseTooltip
                      color="grayBackground"
                      title={`${formatCurrencyNoCents(remaining)} remaining`}
                      body={<>This is the amount of remaining savings you have budgeted.</>}
                    />
                  </Case>

                  <Case default>{null}</Case>
                </Switch>
              }
              color={getColorForType(type)}
            />
            <FlexContainer justifyBetween alignCenter>
              <Actual>
                <Currency round value={actual ?? 0} /> {labelByType(type)}
              </Actual>
              <FlexContainer alignCenter>
                {!!rollover && <StyledRolloverIcon />}
                <Total>
                  <Currency
                    round
                    value={isLoading ? undefined : (budgeted ?? 0) + (rollover ?? 0)}
                  />{' '}
                  budget
                </Total>
              </FlexContainer>
            </FlexContainer>

            {RA.isNotNil(budgetVariabilities) && (
              <Variabilities>
                {budgetVariabilities.map(
                  ({ title, actual, budgeted, rollover, type, remaining }) => (
                    <VariabilityContainer key={title} justifyBetween alignCenter>
                      <Title>{title}</Title>

                      <Switch>
                        <Case
                          when={R.includes(type, [
                            BudgetVariability.FIXED,
                            BudgetVariability.NON_MONTHLY,
                          ])}
                        >
                          <SummaryProgressBar
                            actual={isLoading ? undefined : actual ?? 0}
                            total={isLoading ? undefined : (budgeted ?? 0) + (rollover ?? 0)}
                            color="red"
                            backgroundColor="redFocus"
                            striped
                            tooltipContent={
                              <BaseTooltip
                                color="red"
                                title={`${formatCurrencyNoCents(actual)} ${labelByType(type)}`}
                                body={
                                  <>
                                    This is the amount of expenses you have {labelByType(type)} so
                                    far this month.
                                  </>
                                }
                              />
                            }
                            backgroundBarTooltipContent={
                              <BaseTooltip
                                color="redFocus"
                                title={`${formatCurrencyNoCents(remaining)} expected`}
                                body={
                                  <>This is the amount of remaining expenses you have budgeted.</>
                                }
                              />
                            }
                            ticks={ticks}
                          />
                        </Case>

                        <Case when={type === BudgetVariability.FLEXIBLE}>
                          <SummaryProgressBar
                            actual={isLoading ? undefined : actual ?? 0}
                            total={isLoading ? undefined : (budgeted ?? 0) + (rollover ?? 0)}
                            color="red"
                            backgroundColor="grayFocus"
                            tooltipContent={
                              <BaseTooltip
                                color="red"
                                title={`${formatCurrencyNoCents(actual)} ${labelByType(type)}`}
                                body={
                                  <>
                                    This is the amount of expenses you have {labelByType(type)} so
                                    far this month.
                                  </>
                                }
                              />
                            }
                            backgroundBarTooltipContent={
                              <BaseTooltip
                                color="grayFocus"
                                title={`${formatCurrencyNoCents(remaining)} safe to spend`}
                                body={
                                  <>This is the amount of remaining expenses you have budgeted.</>
                                }
                              />
                            }
                            ticks={ticks}
                          />
                        </Case>
                      </Switch>
                    </VariabilityContainer>
                  ),
                )}
              </Variabilities>
            )}
          </Row>
        ),
      )}

      {shouldShowSafeToSpend && (
        <Row>
          <FlexContainer justifyCenter alignCenter>
            <SafeToSpendLabel>
              <SafeToSpendCurrency round value={getExcessAmount(data) ?? 0} /> left to budget
            </SafeToSpendLabel>
          </FlexContainer>
        </Row>
      )}
    </Root>
  );
};

export default BudgetSummary;
