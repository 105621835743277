import { useState, useCallback } from 'react';

import useTheme from 'lib/hooks/useTheme';
import { getBenchmarkDataKey } from 'lib/investments/adapters';

import type { SecurityInterface } from 'common/types/investments';

export type HoldingInfo = {
  id: string;
  ticker: string | null;
  color: string;
  name: string;
  dataKey: string;
  isBenchmark: boolean;
};

const usePerformanceChartColors = () => {
  const theme = useTheme();
  const [availableHoldingLineColors, setAvailableHoldingLineColors] = useState([
    theme.color.yellow,
    theme.color.green,
    theme.color.blue,
    theme.color.purple,
  ]);
  const [selectedAggregateHoldingInfos, setSelectedAggregateHoldingInfos] = useState<HoldingInfo[]>(
    [],
  );
  const [selectedBenchmarkHolding, setSelectedBenchmarkHolding] = useState<HoldingInfo | null>(
    null,
  );

  const selectHolding = useCallback(
    (security: SecurityInterface) => {
      const { id, ticker, name } = security;
      const maybeExistingHolding = selectedAggregateHoldingInfos.find(
        ({ id: existingHoldingId }) => existingHoldingId === id,
      );
      if (maybeExistingHolding) {
        const { color } = maybeExistingHolding;
        setAvailableHoldingLineColors([...availableHoldingLineColors, color]);
        setSelectedAggregateHoldingInfos(
          selectedAggregateHoldingInfos.filter(
            ({ id: existingHoldingId }) => id !== existingHoldingId,
          ),
        );
      } else if (availableHoldingLineColors.length > 0) {
        const [color, ...remaining] = availableHoldingLineColors;
        setAvailableHoldingLineColors(remaining);
        setSelectedAggregateHoldingInfos([
          ...selectedAggregateHoldingInfos,
          { id, color, ticker, name, dataKey: id, isBenchmark: false },
        ]);
      }
    },
    [
      availableHoldingLineColors,
      setAvailableHoldingLineColors,
      selectedAggregateHoldingInfos,
      setSelectedAggregateHoldingInfos,
    ],
  );

  const selectBenchmarkHolding = useCallback(
    (security: SecurityInterface | null) => {
      if (security) {
        const { id, ticker, name } = security;
        setSelectedBenchmarkHolding({
          id,
          color: theme.color.teal,
          ticker,
          name,
          dataKey: getBenchmarkDataKey(id),
          isBenchmark: true,
        });
      } else {
        setSelectedBenchmarkHolding(null);
      }
    },
    [theme.color.teal],
  );

  return {
    portfolioColor: theme.color.orange,
    comparisonBenchmarkColor: theme.color.blue,
    selectHolding,
    selectBenchmarkHolding,
    selectedAggregateHoldingInfos,
    selectedBenchmarks: selectedBenchmarkHolding ? [selectedBenchmarkHolding] : [],
  };
};

export default usePerformanceChartColors;
