import React from 'react';
import styled, { css } from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import MessageCard from 'components/assistant/MessageCard';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';
import IconButton from 'components/lib/ui/button/IconButton';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';

const Root = styled(MessageCard)`
  height: 58px;
  width: 80%;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: ${({ theme }) => theme.spacing.xsmall};
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakPoints.xl}px) {
    width: 100%;
  }
`;

const ChevronButton = styled(IconButton).attrs({ icon: 'chevron-right' })`
  margin: -${({ theme }) => theme.spacing.xsmall} 0;
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const Amount = styled(CashFlowCurrency)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  width: 100px;
  text-align: right;
`;

const TextGrow = styled(Text).attrs({ clampLines: 1 })<{ $hiddenSmall?: boolean }>`
  flex: 1;
  :not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing.xsmall};
  }

  ${({ $hiddenSmall }) =>
    $hiddenSmall &&
    css`
      @media (max-width: ${({ theme }) => theme.breakPoints.lg}px) {
        display: none;
      }
    `}
`;

const SmallLoadingSpinner = styled(LoadingSpinner)`
  width: 20px;
  height: 20px;
`;

type Props = {
  id: string;
  name: string;
  category: string;
  amount: number;
  onClickChevron?: () => void;
  loading?: boolean;
};

const TransactionMessageBlock = ({
  id,
  name,
  category,
  amount,
  onClickChevron,
  loading,
}: Props) => (
  <Root>
    <Switch>
      <Case when={!!loading}>
        <SmallLoadingSpinner />
      </Case>
      <Case default>
        <TextGrow>{name}</TextGrow>
        <TextGrow $hiddenSmall>{category}</TextGrow>
        <Amount value={amount} type="expense" emphasis={amount > 0} showSign={amount > 0} />
        <ChevronButton onClick={onClickChevron} />
      </Case>
    </Switch>
  </Root>
);

export default TransactionMessageBlock;
