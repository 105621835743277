import { gql } from '@apollo/client';

export const CREATE_PLAID_LINK_TOKEN_MUTATION = gql`
  mutation Common_CreatePlaidLinkTokenMutation(
    $credentialId: UUID
    $linkCustomizationName: String
    $plaidInstitutionId: String
    $redirectUri: String
    $androidPackageName: String
  ) {
    createPlaidLinkToken(
      credentialId: $credentialId
      linkCustomizationName: $linkCustomizationName
      plaidInstitutionId: $plaidInstitutionId
      redirectUri: $redirectUri
      androidPackageName: $androidPackageName
    ) {
      linkToken
      error
    }
  }
`;
