import * as R from 'ramda';
import * as React from 'react';

import type { CashFlowTimeRange } from 'common/lib/cashFlow/DashboardAdapters';
import { TIME_RANGE_LABELS } from 'common/lib/cashFlow/DashboardAdapters';
import { formatCurrency } from 'common/utils/Currency';
import useTheme from 'lib/hooks/useTheme';

import ChartTooltip from './ChartTooltip';
import ChartTooltipDataRow from './ChartTooltipDataRow';
import type { MultipleLineChartProps } from './MultipleLineChart';
import MultipleLineChart from './MultipleLineChart';

type Props<XAxisT, YAxisT, DataT extends Record<string, unknown>> = Omit<
  MultipleLineChartProps<XAxisT, YAxisT, DataT>,
  'yDataKeys' | 'lineOptions'
> & {
  currentPeriodDataKey: YAxisT;
  previousPeriodDataKey: YAxisT;
  forecastDataKey?: YAxisT;
  children?: React.ReactNode;
  selectedTimeRange: CashFlowTimeRange;
};

const ComparePreviousPeriodLineChart = <
  XAxisT extends string,
  YAxisT extends string,
  DataT extends { [x in XAxisT]: string | number } & { [y in YAxisT]: number },
>({
  currentPeriodDataKey,
  previousPeriodDataKey,
  forecastDataKey,
  selectedTimeRange,
  ...props
}: Props<XAxisT, YAxisT, DataT>) => {
  const theme = useTheme();
  const keys = R.reject(R.isNil, [
    previousPeriodDataKey,
    currentPeriodDataKey,
    forecastDataKey,
  ]) as YAxisT[]; // We're filtering out undefined, but Ramda can't tell that when typing
  return (
    <MultipleLineChart
      yDataKeys={keys}
      lineOptions={[
        {
          lineColor: theme.uiTheme === 'dark' ? theme.color.textLight : theme.color.gray,
          legendName: TIME_RANGE_LABELS[selectedTimeRange].previousPeriodLegend,
        },
        {
          lineColor: theme.color.orange,
          hasArea: true,
          legendName: TIME_RANGE_LABELS[selectedTimeRange].currentPeriodLegend,
        },
        { lineColor: theme.color.orange, isDashed: true },
      ]}
      {...props}
      tooltipComponent={({ payload, active }) => (
        <ChartTooltip
          active={active}
          header={`Total Spending by Day ${payload[0]?.payload[props.xDataKey]}`}
        >
          <ChartTooltipDataRow
            label={TIME_RANGE_LABELS[selectedTimeRange].tooltipCurrentPeriodLabel}
            value={
              payload[0]?.payload[currentPeriodDataKey]
                ? formatCurrency(payload[0]?.payload[currentPeriodDataKey])
                : '-'
            }
            dotFillColor={theme.color.orange}
          />
          <ChartTooltipDataRow
            label={TIME_RANGE_LABELS[selectedTimeRange].tooltipPreviousPeriodLabel}
            value={
              payload[0]?.payload[previousPeriodDataKey]
                ? formatCurrency(payload[0]?.payload[previousPeriodDataKey])
                : '-'
            }
            dotFillColor={theme.color.grayLight}
          />
        </ChartTooltip>
      )}
    />
  );
};

export default ComparePreviousPeriodLineChart;
