export const updateCachedCategory = (categoryId: string, fields: any) => (cache: any) => {
  cache.modify({
    fields: {
      categories: (existingCategories = []) => {
        const affectedCategory = existingCategories.find(
          (categoryRef: any) => categoryRef.__ref !== `Category:${categoryId}`,
        );
        cache.modify({
          id: cache.identify(affectedCategory),
          fields,
        });
      },
    },
  });
};
