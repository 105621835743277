import * as React from 'react';

import ContentLoader from 'components/lib/ui/ContentLoader';

type Props = {
  className?: string;
};

const SecuritySearchListItemSkeleton = ({ className }: Props) => (
  <ContentLoader className={className} height={70}>
    <circle cx="32" cy="28" r="18" />
    <rect x="64" y="6" rx="5" ry="5" width="40" height="18" />
    <rect x="64" y="33" rx="5" ry="5" width="80" height="14" />
    <rect x="385" y="20" rx="5" ry="5" width="45" height="24" />
    <rect x="460" y="18" rx="5" ry="5" width="50" height="30" />
  </ContentLoader>
);

export default SecuritySearchListItemSkeleton;
