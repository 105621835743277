import React from 'react';
import styled from 'styled-components';

import AccountMessageBlock from 'components/assistant/blocks/AccountMessageBlock';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { AccountsBlock } from 'common/lib/assistant/blocks';
import { makeBlockRenderer } from 'common/lib/assistant/blocks/render';
import { parseCurrency } from 'common/utils/Currency';

const Root = styled(FlexContainer)`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const DemoAccountsBlockRenderer = makeBlockRenderer(AccountsBlock)(({ data: { rows } }) => (
  <Root column>
    {rows.map(({ ID, Name, Type, Balance }) => (
      <AccountMessageBlock
        key={ID}
        id={ID}
        name={Name}
        subtype={Type}
        balance={parseCurrency(Balance)}
        icon=""
      />
    ))}
  </Root>
));

export default DemoAccountsBlockRenderer;
