import React from 'react';
import styled from 'styled-components';

import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import SecuritySearchForm from 'components/securities/SecuritySearchForm';

import type { ManualInvestmentAccountTrackingModeType } from 'common/constants/accounts';

type Props = {
  accountId?: string;
  accountName?: string;
  accountType?: string;
  accountSubtype?: string | null;
  accountInvestmentTrackingMode?: ManualInvestmentAccountTrackingModeType;
  skipAccountSelection?: boolean;
  securityId?: string;
  quantity?: number;
  next: (data: {
    accountId?: string;
    accountName?: string;
    accountType?: string;
    accountSubtype?: string | null;
    accountInvestmentTrackingMode?: string;
    skipAccountSelection?: boolean;
    securityId: string;
    quantity?: number;
  }) => void;
  goBack?: () => void;
};

const Title = styled(Text)`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding: ${({ theme }) => `0 ${theme.spacing.xlarge}`};
  padding-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const AddManualHoldingSecuritySearchFormModal = ({
  next,
  goBack,
  accountId,
  accountType,
  accountSubtype,
  accountName,
  accountInvestmentTrackingMode,
  skipAccountSelection,
  quantity,
}: Props) => {
  const handleSecurityClick = (securityId: string) => {
    next({
      securityId,
      accountId,
      accountType,
      accountSubtype,
      accountName,
      accountInvestmentTrackingMode,
      skipAccountSelection,
      quantity,
    });
  };

  return (
    <ModalCard onClickBackButton={goBack} hideBottomBorder>
      <Title>Add holding</Title>
      <SecuritySearchForm onSecurityClick={handleSecurityClick} />
    </ModalCard>
  );
};

export default AddManualHoldingSecuritySearchFormModal;
