import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import removeMarkdown from 'remove-markdown';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import AdviceCategoryIcon from 'components/advice/AdviceCategoryIcon';
import FlexContainer from 'components/lib/ui/FlexContainer';
import StepProgressBar from 'components/lib/ui/StepProgressBar';
import Text from 'components/lib/ui/Text';

import type { AdviceItemRowFields } from 'common/generated/graphQlTypes/AdviceItemRowFields';

const Root = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-left: 0;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition.default};

  :hover {
    background: ${({ theme }) => theme.color.grayBackground};
  }

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  }

  :last-child {
    border-radius: 0 0 ${({ theme }) => theme.radius.medium} ${({ theme }) => theme.radius.medium};
  }
`;

const Title = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const Description = styled(Text).attrs({ clampLines: 2 })`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.textLight};
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

// Ensure all icon circles are lined up vertically down the page
const IconContainer = styled(FlexContainer).attrs({ justifyCenter: true })`
  width: 96px;
  flex-shrink: 0;
`;

const StatusText = styled(Text).attrs({ size: 'xxsmall', weight: 'bold' })`
  letter-spacing: 0.08em;
  color: ${({ theme }) => theme.color.textLight};
  text-transform: uppercase;
  margin-top: 10px;
`;

const StyledStepProgressBar = styled(StepProgressBar)`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  item: AdviceItemRowFields;
  onClick?: () => void;
  className?: string;
};

const AdviceItemRow = ({
  item: {
    title,
    description,
    category,
    completedAt,
    numTasksCompleted,
    numTasksRemaining,
    numTasks,
  },
  onClick,
  className,
}: Props) => {
  const displayDescription = useMemo(() => removeMarkdown(description ?? ''), [description]);

  return (
    <Root onClick={onClick} className={className}>
      <IconContainer>
        <AdviceCategoryIcon adviceCategory={category} completed={!!completedAt} />
      </IconContainer>
      <FlexContainer column>
        <Title>{title}</Title>
        <Description>{displayDescription}</Description>
        <Switch>
          <Case when={!!completedAt}>
            <StatusText>
              {DateTime.fromISO(completedAt ?? '').toFormat('MMM yyyy')} ・ {numTasksCompleted}{' '}
              tasks completed
            </StatusText>
          </Case>
          <Case when={!numTasksCompleted}>
            <StatusText>Not started ・ {numTasksRemaining} tasks to complete</StatusText>
          </Case>
          <Case default>
            <StyledStepProgressBar numSteps={numTasks} numStepsCompleted={numTasksCompleted} />
          </Case>
        </Switch>
      </FlexContainer>
    </Root>
  );
};

AdviceItemRow.fragments = {
  AdviceItemRowFields: gql`
    fragment AdviceItemRowFields on AdviceItem {
      id
      title
      description
      category {
        ...AdviceCategoryIconFields
      }
      numTasksCompleted
      numTasksRemaining
      numTasks
      completedAt
    }
    ${AdviceCategoryIcon.fragments.AdviceCategoryIconFields}
  `,
};

export default AdviceItemRow;
