import { snakeCase } from 'change-case/dist';
import _ from 'lodash';
import * as R from 'ramda';

import { sort } from 'common/utils/Array';

/** R.omit() recursively */
export const omitDeep = R.curry((keys: string[], value: any): any => {
  if (_.isArray(value)) {
    return value.map((val) => omitDeep(keys, val));
  } else if (_.isObject(value)) {
    const omitted = R.omit(keys, value);
    return _.mapValues(omitted, (value) => omitDeep(keys, value));
  }
  return value;
});

/* Serializes an object to a key that can be used for equality comparison */
export function objToKey<T extends Record<string, any>>(obj: T = {} as T): T | string {
  if (!_.isPlainObject(obj)) {
    return obj;
  }

  const sortedObj = R.pipe(
    R.keys,
    sort,
    R.reduce((result: Record<string, any>, key) => {
      result[key] = objToKey(obj[key]);
      return result;
    }, {}),
  )(obj);

  return JSON.stringify(sortedObj);
}

export const indexByPath = <T extends Record<string, unknown>>(
  pathFunc: (obj: T) => string[],
  objs: T[],
): any => {
  const topLevels = objs.map((obj) => {
    const path = pathFunc(obj);
    return _indexByPath(path, obj);
  });
  return topLevels.reduce(R.mergeDeepRight, {});
};

const _indexByPath = <T extends Record<string, any>>(path: string[], obj: T): any => {
  const last = R.last(path);

  if (last === undefined) {
    throw new Error('recursion error!');
  }

  const rest = R.take(path.length - 1, path);

  if (rest.length === 0) {
    return { [last]: obj };
  }

  return _indexByPath(rest, { [last]: obj });
};

/** Used to convert keys of an object from camelCase to snake_case. */
export const convertKeysToSnakeCase = <T extends Record<string, unknown>>(
  obj: T,
): Record<string, any> =>
  Object.entries(obj)
    .map(([k, v]) => ({ [snakeCase(k)]: v }))
    .reduce((p, v) => ({ ...p, ...v }), {});

/**
 * Retrieves the first non-null/undefined value from an object based on a list of paths.
 *
 * This function takes an array of paths and an object, then attempts to retrieve
 * the value at each path in order. It returns the first non-null/undefined value found.
 * If no valid value is found, it returns undefined.
 *
 * @example
 * const user = {
 *   name: 'John Doe',
 *   contact: {
 *     email: 'john@example.com',
 *     phone: null
 *   },
 *   preferences: {
 *     newsletter: true
 *   }
 * };
 *
 * firstOfPaths(['contact.phone', 'contact.email'], user); // "john@example.com"
 * firstOfPaths(['address.street', 'contact.fax'], user); // undefined
 */
export const firstOfPaths = <T extends Record<string, unknown>>(paths: string[], obj: T) => {
  const values = paths.map((path) => R.path([path], obj));
  return R.head(values.filter(Boolean));
};
