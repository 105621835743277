import * as d3Sankey from 'd3-sankey';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useTabs } from 'common/components/tabs/TabsContext';
import CashFlowSankeyCardControls, {
  SANKEY_CONTAINER_ID,
} from 'components/cashFlows/CashFlowSankeyCardControls';
import CashFlowSankeyPlaceholder from 'components/cashFlows/CashFlowSankeyPlaceholder';
import { VIEW_TO_LABEL_MAP } from 'components/cashFlows/CashFlowViewSelect';
import SankeyDiagram from 'components/lib/charts/SankeyDiagram';
import Card from 'components/lib/ui/Card';
import CardBody from 'components/lib/ui/CardBody';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import useDebounce from 'common/lib/hooks/useDebounce';
import { formatCurrency } from 'common/utils/Currency';
import { formatPercent } from 'common/utils/Number';
import type { CashFlowLink, CashFlowNode, SankeyGroupMode } from 'lib/cashFlow/sankey';
import {
  assertNodeHasId,
  cashFlowNodeColorDeterminer,
  cashFlowSankeyDataAdapter,
  NodeType,
  SANKEY_GROUP_MODE_TO_LABEL,
  SAVINGS_NODE_ID,
} from 'lib/cashFlow/sankey';
import CashFlowContext from 'lib/contexts/CashFlowContext';
import useMeasure from 'lib/hooks/useMeasure';
import usePersistentFilter from 'lib/hooks/usePersistentFilter';
import type { NodeWithExtraProps } from 'lib/ui/sankey';

import routes from 'constants/routes';

import type { Web_GetCashFlowPageQuery } from 'common/generated/graphql';
import { CategoryGroupType } from 'common/generated/graphql';

const WIDTH_UPDATE_DEBOUNCE_MS = 150;

type CashFlowNodeWithExtraProps = NodeWithExtraProps<CashFlowNode, CashFlowLink>;

type SortableLink = {
  value: number;
  target: { id: string } | string | number;
  source: { id: string } | string | number;
};

const Content = styled(CardBody)`
  padding: 0;
`;

const LoadingContainer = styled(FlexContainer).attrs({
  center: true,
  column: true,
})`
  padding: ${({ theme }) => theme.spacing.xxxxlarge};
`;

type Props = {
  data: Web_GetCashFlowPageQuery;
  isLoading: boolean;
  hideHeader?: boolean;
  useParams?: boolean;
  disableOnClick?: boolean;
  hideCategoryIfZero?: boolean;
};

const CashFlowSankeyCard = ({
  data,
  isLoading,
  hideHeader,
  useParams = true,
  disableOnClick,
  hideCategoryIfZero,
}: Props) => {
  const [ref, { width }] = useMeasure();
  const debouncedWidth = useDebounce(width, WIDTH_UPDATE_DEBOUNCE_MS);

  const { hideAmounts } = useContext(CashFlowContext);

  const { push } = useHistory();

  const {
    activeFilters: { date, timeframe, sankey: sankeyView },
    updateFilter,
  } = usePersistentFilter('cashFlow', { useParams });

  const [activeIndex] = useTabs();
  const activeTab = useMemo(
    () => Object.keys(SANKEY_GROUP_MODE_TO_LABEL)[activeIndex] as SankeyGroupMode,
    [activeIndex],
  );

  useEffect(() => {
    // Make sure the active group mode is consistent with the active tab
    if (activeTab && activeTab !== sankeyView) {
      updateFilter({ sankey: activeTab });
    }
  }, [activeTab, sankeyView, updateFilter]);

  const sankeyFileName = useMemo(() => {
    const startDate = DateTime.fromISO(date);
    const endDate = startDate.endOf(timeframe);

    let result = `Monarch - Cash Flow Sankey - ${startDate.toFormat('yyyy-MM')}`;

    if (timeframe !== 'month') {
      result += ` to ${endDate.toFormat('yyyy-MM')}`;
    }

    return result;
  }, [date, timeframe]);

  const adaptedCashFlowData = cashFlowSankeyDataAdapter(data, activeTab);

  // Force the "Savings" node to be on the 4th column
  const alignCashFlowNodes = useCallback(
    <T extends { id: string; depth?: number }>(node: T, numColumns: number) =>
      node.id === SAVINGS_NODE_ID ? node.depth ?? 0 : d3Sankey.sankeyJustify(node, numColumns),
    [],
  );

  const sortLinks = useCallback(<T extends SortableLink>(a: T, b: T) => {
    assertNodeHasId(a.target);
    assertNodeHasId(b.target);

    // The top-level "Savings" node should always be the first one
    if (a.target.id === SAVINGS_NODE_ID) {
      return -1;
    }

    return b.value - a.value;
  }, []);

  const onClickNode = useCallback(
    (node: CashFlowNodeWithExtraProps) => {
      if (node.type === NodeType.Generic) {
        return;
      }

      const { id, type } = node;
      if (type === NodeType.Category) {
        push(routes.categories({ id, queryParams: { date, timeframe } }));
      } else if (type === NodeType.CategoryGroup) {
        push(routes.categoryGroups({ id, queryParams: { date, timeframe } }));
      }
    },
    [date, timeframe, push],
  );

  const formatNodeValue = useCallback(
    ({ value, percent, categoryGroupType, rawValue, id, label }: CashFlowNodeWithExtraProps) => {
      /*
        returns empty string if hideCategoryIfZero is true and rawValue is 0 or
        if there is no categoryGroupType and is not the savings node
      */
      if (
        (rawValue === 0 && hideCategoryIfZero) ||
        (!categoryGroupType && id !== SAVINGS_NODE_ID)
      ) {
        return '';
      }

      if (hideAmounts && percent) {
        return formatPercent(percent);
      }

      const prefix = categoryGroupType === CategoryGroupType.EXPENSE && rawValue > 0 ? '-' : '';
      return `${prefix}${formatCurrency(value)}${percent ? ` (${formatPercent(percent)})` : ''}`;
    },
    [hideAmounts],
  );

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      );
    } else if (adaptedCashFlowData.nodes.length <= 0) {
      return <CashFlowSankeyPlaceholder width={debouncedWidth} />;
    } else {
      return (
        <SankeyDiagram
          width={debouncedWidth}
          data={adaptedCashFlowData}
          formatValue={formatNodeValue}
          onClickNode={!disableOnClick ? onClickNode : undefined}
          onAlignNode={alignCashFlowNodes}
          onSortLinks={sortLinks}
          customNodesColorDeterminer={(nodes, links, theme) =>
            cashFlowNodeColorDeterminer(nodes, links, theme, activeTab)
          }
          highlightOnHover
        />
      );
    }
  }, [
    activeTab,
    adaptedCashFlowData,
    debouncedWidth,
    isLoading,
    onClickNode,
    formatNodeValue,
    alignCashFlowNodes,
    sortLinks,
  ]);

  return (
    <Card
      title={VIEW_TO_LABEL_MAP.sankey}
      hideHeader={hideHeader}
      controls={<CashFlowSankeyCardControls fileName={sankeyFileName} />}
    >
      <ScrollContainer mouseScroll={{ rubberBand: false }}>
        <Content ref={ref} id={SANKEY_CONTAINER_ID}>
          {content}
        </Content>
      </ScrollContainer>
    </Card>
  );
};

export default CashFlowSankeyCard;
