import { getType } from 'typesafe-actions';

import { appLoaded, login } from 'actions';
import { getActAsUser } from 'state/multiHousehold/selectors';
import type { Middleware } from 'state/types';
import { getUserTimezone, getUserIsLoggedIn } from 'state/user/selectors';
import { updateUserAction } from 'state/user/thunks';

const localizationMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action);
  const isLoggedIn = getUserIsLoggedIn(store.getState());
  const isActingAsUser = getActAsUser(store.getState());

  if (isLoggedIn && !isActingAsUser && [getType(appLoaded), getType(login)].includes(action.type)) {
    const userTimezone = getUserTimezone(store.getState());
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!userTimezone) {
      store.dispatch(updateUserAction({ timezone }));
    }
  }

  return result;
};

export default localizationMiddleware;
