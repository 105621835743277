import { gql } from '@apollo/client';
import { isNil } from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import GoalImage from 'components/goalsV2/GoalImage';
import Select from 'components/lib/form/Select';
import type { Props as SelectProps } from 'components/lib/form/Select';

import { sortGoalsByPriority } from 'common/lib/goalsV2/adapters';
import useQuery from 'common/lib/hooks/useQuery';

import type {
  Web_GoalSelectGoalsQuery,
  Web_GoalSelectTransactionQuery,
  Web_GoalSelectTransactionQueryVariables,
} from 'common/generated/graphql';

const RoundGoalImage = styled(GoalImage).attrs({ showGradient: false, size: 'small' })`
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 10px;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  transactionId?: string;
  accountIds?: string[];
  noGoalLabel?: string;
} & SelectProps;

const DEFAULT_NO_GOAL_LABEL = 'No goal';

const GoalSelect = ({ transactionId, accountIds, noGoalLabel, ...selectProps }: Props) => {
  const { data: transactionData } = useQuery<
    Web_GoalSelectTransactionQuery,
    Web_GoalSelectTransactionQueryVariables
  >(TRANSACTION_GOALS_QUERY, {
    variables: { transactionId: transactionId! },
    skip: !transactionId, // fetch goals for specific transaction
  });
  const { setFieldValue } = useFormContext();

  const { data: goalsData } = useQuery<Web_GoalSelectGoalsQuery>(GOALS_QUERY, {
    skip: !!transactionId, // fetch all goals
  });

  const goals = useMemo(() => {
    if (transactionId) {
      return transactionData?.getTransaction?.account.goalAllocations.map(({ goal }) => goal);
    }

    return goalsData?.goalsV2.filter(({ archivedAt, accountAllocations }) => {
      // If multiple accounts are selected, don't show goals. See ENG-7211 for details.
      if (archivedAt) {
        return false;
      }
      if (accountIds?.length === 1) {
        return accountAllocations.some(({ account }) => accountIds?.includes(account.id));
      }
      if (accountIds?.length ?? 0 > 1) {
        return false;
      }
      return true;
    });
  }, [transactionId, transactionData, goalsData, accountIds]);

  const goalOptions = useMemo(() => {
    const sortedGoals = sortGoalsByPriority(goals ?? []);

    return [
      {
        value: DEFAULT_NO_GOAL_LABEL,
        label: noGoalLabel || DEFAULT_NO_GOAL_LABEL,
        icon: undefined,
        smallIcon: undefined,
        disabled: false,
      },
      ...sortedGoals.map(
        ({ name, id, imageStorageProvider, imageStorageProviderId, archivedAt }) => ({
          value: id,
          label: name,
          disabled: !!archivedAt,
          icon: (
            <RoundGoalImage
              imageStorageProvider={imageStorageProvider}
              imageStorageProviderId={imageStorageProviderId}
            />
          ),
        }),
      ),
    ];
  }, [noGoalLabel, goals]);

  return (
    <Select
      options={goalOptions}
      {...selectProps}
      // @ts-ignore Select type is wrong...
      onChange={({ value }) => {
        // Remove goal if 'No goal' is selected
        setFieldValue(
          selectProps.name ?? 'goalId',
          value && value === DEFAULT_NO_GOAL_LABEL ? null : value,
        );
      }}
      value={isNil(selectProps.value) ? goalOptions[0] : selectProps.value}
    />
  );
};

const TRANSACTION_GOALS_QUERY = gql`
  query Web_GoalSelectTransaction($transactionId: UUID!) {
    getTransaction(id: $transactionId) {
      id
      account {
        id
        goalAllocations {
          id
          goal {
            id
            name
            imageStorageProvider
            imageStorageProviderId
            priority
            archivedAt
          }
        }
      }
      goal {
        id
        name
        imageStorageProvider
        imageStorageProviderId
      }
      pending
    }
  }
`;

const GOALS_QUERY = gql`
  query Web_GoalSelectGoals {
    goalsV2 {
      id
      name
      priority
      archivedAt
      imageStorageProvider
      imageStorageProviderId

      accountAllocations {
        id
        account {
          id
        }
      }
    }
  }
`;

export default GoalSelect;
