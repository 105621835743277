import { gql, useMutation, useQuery } from '@apollo/client';

import type { GetMigratedToGoalsV2 } from 'common/generated/graphQlTypes/GetMigratedToGoalsV2';
import type { MigrateToGoalsV2 } from 'common/generated/graphQlTypes/MigrateToGoalsV2';

const useMigrateToGoalsV2 = () => {
  const { data } = useQuery<GetMigratedToGoalsV2>(QUERY);
  const {
    migratedToGoalsV2,
    goals: legacyGoals = [], // these are the the legacy (v1) goals
  } = data ?? {};

  const hasLegacyGoal = legacyGoals.length > 0;
  const needsToMigrateToGoalsV2 = migratedToGoalsV2 === false && hasLegacyGoal;

  const [migrateToGoalsV2, { loading }] = useMutation<MigrateToGoalsV2>(MUTATION, {
    update: (cache, { data }) => {
      if (!data?.migrateToGoalsV2?.success) {
        return;
      }

      cache.writeQuery({
        query: QUERY,
        data: {
          migratedToGoalsV2: true,
          goals: legacyGoals,
        },
      });
    },
  });

  return {
    needsToMigrateToGoalsV2,
    migrateToGoalsV2,
    isMigrating: loading,
  };
};

const QUERY = gql`
  query GetMigratedToGoalsV2 {
    migratedToGoalsV2
    goals {
      id
    }
  }
`;

const MUTATION = gql`
  mutation Common_MigrateToGoalsV2 {
    migrateToGoalsV2 {
      success
    }
  }
`;

export default useMigrateToGoalsV2;
