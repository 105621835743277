import React, { useMemo } from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import RadioGroupField from 'components/lib/form/RadioGroupField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { formatThousands } from 'common/utils/Number';

export const IGNORE_ACCOUNT_VALUE = 'ignore';

const Root = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  max-width: 765px;
  width: 100%;
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyledSubmitButton = styled(FormSubmitButton)`
  width: 100%;
  max-width: 438px;
  margin-top: 24px;
  margin-bottom: 80px;
`;

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
`;

const Subtitle = styled(Text)`
  text-align: center;
`;

type Props = {
  onSubmit: (values: Record<string, any>) => Promise<any>;
  totalNotIgnoredTransactions: number;
};

const ImportMintPriority = ({ onSubmit, totalNotIgnoredTransactions }: Props) => {
  const initialValues = useMemo(
    () => ({
      importPriority: 'statement_transactions',
    }),
    [],
  );

  return (
    <Root>
      <Title>Tell us which transactions to prioritize</Title>
      <Subtitle>
        If you want to ensure all of your Mint transactions are imported, use the first option
        below. Use the second option if you are happy with your existing synced transactions and
        just want to backfill more data from Mint.
      </Subtitle>
      <StyledForm onSubmit={onSubmit} initialValues={initialValues}>
        <RadioGroupField
          name="importPriority"
          hideLabel
          options={[
            {
              value: 'statement_transactions',
              label: 'Prioritize Mint transactions (recommended)',
              description:
                'We’ll import all of your Mint transactions, and delete any duplicate transactions that have already synced to Monarch. This will preserve all of your Mint history.',
            },
            {
              value: 'synced_transactions',
              label: 'Prioritize Monarch transactions',
              description:
                'We’ll only import Mint transactions up to the date of your oldest transaction in your Monarch accounts, and ignore the rest. This means some of your Mint transactions may be ignored.',
            },
          ]}
        />

        <StyledSubmitButton
          size="large"
          pending={false} // Don't show the loading state for this button since it's handled by the modal
          disableWhenValuesUnchanged={false}
        >
          {`Import ${
            totalNotIgnoredTransactions > 0 ? formatThousands(totalNotIgnoredTransactions) : ''
          } transactions`}
        </StyledSubmitButton>
      </StyledForm>
    </Root>
  );
};

export default ImportMintPriority;
