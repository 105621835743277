import { gql } from '@apollo/client';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

export const CREATE_FINICITY_CONNECT_URL_MUTATION = gql`
  mutation Common_CreateFinicityConnectUrlMutation($finicityInstitutionId: String = null) {
    createFinicityConnectUrl(finicityInstitutionId: $finicityInstitutionId) {
      url
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const CREATE_FINICITY_CONNECT_FIX_URL_MUTATION = gql`
  mutation Common_CreateFinicityConnectFixUrlMutation($credentialId: UUID!) {
    createFinicityConnectFixUrl(credentialId: $credentialId) {
      url
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const CREATE_FINICITY_CREDENTIALS_MUTATION = gql`
  mutation Common_CreateFinicityCredentialsMutation {
    createFinicityCredentials {
      credentials {
        id
        accounts {
          id
        }
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;
