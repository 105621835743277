import React from 'react';

import DisconnectSpinwheelConfirmationModal from 'components/spinwheel/DisconnectSpinwheelConfirmationModal';
import DisconnectSpinwheelSuccessModal from 'components/spinwheel/DisconnectSpinwheelSuccessModal';
import SpinwheelConnectionModal from 'components/spinwheel/SpinwheelConnectionModal';
import type { Props as SpinwheelConnectionModalProps } from 'components/spinwheel/SpinwheelConnectionModal';
import Flow from 'components/utils/Flow';
import SkippableFlowStep from 'components/utils/SkippableFlowStep';

type Props = {
  skipConnectionModal?: boolean;
  onComplete?: () => void;
} & Pick<SpinwheelConnectionModalProps, 'isLoading' | 'hasGenericError' | 'hasFraudError'>;

const SpinwheelConnectionFlow = ({ skipConnectionModal = false, onComplete, ...props }: Props) => (
  <Flow
    initialProps={{ ...props }}
    // @ts-ignore SkippableFlowStep is not typed to work with Flow in a fully compatible way
    steps={[
      SkippableFlowStep(SpinwheelConnectionModal, () => skipConnectionModal),
      DisconnectSpinwheelConfirmationModal,
      DisconnectSpinwheelSuccessModal,
    ]}
    onComplete={onComplete}
  />
);

export default SpinwheelConnectionFlow;
