import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

const Group = styled.div<{ $borderless: boolean }>`
  padding: ${({ theme }) => theme.spacing.default};
  border: ${({ $borderless, theme }) => !$borderless && `1px solid ${theme.color.grayFocus}`};
  border-radius: ${({ theme }) => theme.radius.small};
  font-size: ${({ theme }) => theme.fontSize.small};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }
`;

export const GroupHorizontalContainer = styled(FlexContainer).attrs({
  alignCenter: true,
  justifyBetween: true,
})`
  gap: ${({ theme }) => theme.spacing.xlarge};
`;

const ChildrenContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const GroupSubtitle = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

export const GroupLeft = styled(FlexContainer).attrs({ column: true })`
  margin-right: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  rightAccessory?: React.ReactNode;
  className?: string;
  borderless?: boolean;
};

const FieldCell = ({ title, subtitle, children, rightAccessory, className, borderless }: Props) => (
  <Group $borderless={!!borderless} className={className}>
    <GroupHorizontalContainer>
      <GroupLeft>
        <Text weight="medium" className="title">
          {title}
        </Text>
        {!!subtitle && <GroupSubtitle>{subtitle}</GroupSubtitle>}
      </GroupLeft>
      {rightAccessory}
    </GroupHorizontalContainer>
    {!!children && <ChildrenContainer>{children}</ChildrenContainer>}
  </Group>
);

export default FieldCell;
