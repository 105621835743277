import type { MutationHookOptions } from '@apollo/client';
import { useMutation, useApolloClient, gql } from '@apollo/client';
import { useCallback } from 'react';

import { getOptimisticItemOrderForMove } from 'common/lib/dnd/DragAndDrop';

import type {
  UpdateCategoryOrder,
  UpdateCategoryOrderVariables,
} from 'common/generated/graphQlTypes/UpdateCategoryOrder';
import { Tuple } from 'common/types';

const useUpdateCategoryOrderMutation = (
  options?: MutationHookOptions<UpdateCategoryOrder, UpdateCategoryOrderVariables>,
) => {
  const client = useApolloClient();
  const [performMutation, mutationResult] = useMutation<
    UpdateCategoryOrder,
    UpdateCategoryOrderVariables
  >(UPDATE_CATEGORY_ORDER, options);

  const updateCategoryOrder = useCallback(
    async (destination: UpdateCategoryOrderVariables, source: UpdateCategoryOrderVariables) => {
      const optimisticOrder =
        destination.categoryGroupId === source.categoryGroupId
          ? getOptimisticItemOrderForMove(source.order, destination.order)
          : destination.order - 0.5;
      client.writeFragment({
        id: `Category:${destination.id}`,
        fragment: gql`
          fragment BudgetCategory on Category {
            order
            group {
              id
            }
          }
        `,
        data: {
          __typename: 'Category',
          order: optimisticOrder,
          group: {
            __typename: 'CategoryGroup',
            id: destination.categoryGroupId,
          },
        },
      });

      const response = await performMutation({ variables: destination });
      return response;
    },
    [performMutation, client],
  );

  return Tuple(updateCategoryOrder, mutationResult);
};

const UPDATE_CATEGORY_ORDER = gql`
  mutation Web_UpdateCategoryOrder($id: UUID!, $categoryGroupId: UUID!, $order: Int!) {
    updateCategoryOrderInCategoryGroup(id: $id, categoryGroupId: $categoryGroupId, order: $order) {
      category {
        id
      }
    }
  }
`;

export default useUpdateCategoryOrderMutation;
