import { gql } from '@apollo/client';
import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import GoalCard from 'components/goalsV2/GoalCard';
import GoalsAllocateBanner from 'components/goalsV2/GoalsAllocateBanner';
import GoalsHeaderControls from 'components/goalsV2/GoalsHeaderControls';
import GoalsV2MigrationPrompt from 'components/goalsV2/GoalsV2MigrationPrompt';
import EmptyCreateCard from 'components/lib/ui/EmptyCreateCard';
import Icon from 'components/lib/ui/Icon';
import PageWithNoAccountsEmptyState from 'components/lib/ui/PageWithNoAccountsEmptyState';
import TextButton from 'components/lib/ui/TextButton';

import { sortGoalsByPriority } from 'common/lib/goalsV2/adapters';
import useMigrateToGoalsV2 from 'common/lib/hooks/goalsV2/useMigrateToGoalsV2';
import useMockableQuery from 'common/lib/hooks/useMockableQuery';
import isV2Theme from 'common/lib/theme/isV2Theme';
import useModal from 'lib/hooks/useModal';
import { getDoesNotHaveAccounts } from 'state/emptyState/selectors';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

import type { Web_GoalsV2 } from 'common/generated/graphQlTypes/Web_GoalsV2';

const GoalsList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 227px;
  grid-column-gap: ${({ theme }) => theme.spacing.gutter};
  grid-row-gap: ${({ theme }) => theme.spacing.gutter};
  padding: ${({ theme }) => theme.spacing.default};
  ${isV2Theme(css`
    padding-top: 0;
  `)}

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    padding: ${({ theme }) => theme.spacing.gutter};
    grid-template-columns: repeat(2, 1fr);
    ${isV2Theme(css`
      padding-top: 0;
    `)}
  }
  @media (min-width: ${({ theme }) => theme.breakPoints.lg}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledGoalCard = styled(GoalCard)`
  height: 100%;
`;

const ExpandButton = styled(TextButton)`
  margin: auto;
  margin-top: ${({ theme }) => theme.spacing.large};
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
  color: ${({ theme }) => theme.color.text};
`;

const ExpandIcon = styled(Icon).attrs({ size: 16 })`
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const GoalsV2 = () => {
  const { push } = useHistory();

  const doesNotHaveAccounts = useSelector(getDoesNotHaveAccounts);
  const { needsToMigrateToGoalsV2 } = useMigrateToGoalsV2();
  const [MigrationModal] = useModal(true); // initially open

  const [isShowingArchivedGoals, setIsShowingArchivedGoals] = useState(false);

  const { data, isLoadingInitialData, refetch } = useMockableQuery<Web_GoalsV2>(
    QUERY,
    doesNotHaveAccounts,
  );

  const { goalsV2, accountsWithUnallocatedBalancesForGoals } = data ?? {};

  const goals = useMemo(() => sortGoalsByPriority(goalsV2 ?? []), [goalsV2]);
  const [archivedGoals, activeGoals] = useMemo(
    () => R.partition(({ archivedAt }) => !!archivedAt, goals),
    [goals],
  );
  const displayGoals = useMemo(
    () => (isShowingArchivedGoals ? [...activeGoals, ...archivedGoals] : activeGoals),
    [archivedGoals, activeGoals, isShowingArchivedGoals],
  );

  const showEmptyState = !goals.length && !isLoadingInitialData;

  return (
    <PageWithNoAccountsEmptyState
      name="Goals"
      emptyIcon="target"
      emptyTitle={COPY.ONBOARDING_GOALS_OVERLAY_TITLE}
      controls={<GoalsHeaderControls />}
      overlayEmptyComponent={
        showEmptyState && !needsToMigrateToGoalsV2 ? ( // Show goals creation cta if there are no goals
          <EmptyCreateCard
            icon="Target"
            title={COPY.GOALS.EMPTY.TITLE}
            subtitle={COPY.GOALS.EMPTY.SUBTITLE}
            button={{
              text: COPY.GOALS.EMPTY.BUTTON,
              onClick: () => push(routes.goalsV2.select()),
            }}
          />
        ) : undefined
      }
    >
      {!!accountsWithUnallocatedBalancesForGoals?.length && !needsToMigrateToGoalsV2 && (
        <GoalsAllocateBanner
          accounts={accountsWithUnallocatedBalancesForGoals}
          onFinishAllocation={refetch}
        />
      )}
      <Switch>
        <Case when={needsToMigrateToGoalsV2}>
          <MigrationModal nonDismissable>
            <GoalsV2MigrationPrompt />
          </MigrationModal>
        </Case>

        <Case default>
          <>
            <GoalsList>
              {displayGoals.map((goal) => (
                <Link to={routes.goalsV2.goalDetail({ id: goal.id })} key={goal.id}>
                  <StyledGoalCard key={goal.id} goal={goal} />
                </Link>
              ))}
            </GoalsList>
            {archivedGoals.length > 0 && (
              <ExpandButton onClick={() => setIsShowingArchivedGoals(R.not)}>
                <ExpandIcon name={isShowingArchivedGoals ? 'chevron-up' : 'chevron-down'} />
                {isShowingArchivedGoals ? 'Hide' : 'Show'} archived goals ({archivedGoals.length})
              </ExpandButton>
            )}
          </>
        </Case>
      </Switch>
    </PageWithNoAccountsEmptyState>
  );
};

const QUERY = gql`
  query Web_GoalsV2 {
    goalsV2 {
      id
      ...WebGoalCardFields
    }
    accountsWithUnallocatedBalancesForGoals {
      id
      ...Web_GoalsAllocateBannerFields
    }
  }
  ${GoalCard.fragments.WebGoalCardFields}
  ${GoalsAllocateBanner.fragments.Web_GoalsAllocateBannerFields}
`;

export default GoalsV2;
