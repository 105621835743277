import { useMutation } from '@apollo/client';
import React from 'react';

import Confirmation from 'components/lib/ui/Confirmation';
import Modal from 'components/lib/ui/Modal';

import { DELETE_RULE_MUTATION } from 'common/lib/graphQl/rules';

import type {
  DeleteTransactionRule,
  DeleteTransactionRuleVariables,
} from 'common/generated/graphQlTypes/DeleteTransactionRule';

type Props = {
  ruleId: string;
  onDone: () => void;
  onCancel: () => void;
};

const DeleteTransactionRuleConfirmation = ({ ruleId, onDone, onCancel }: Props) => {
  const [deleteRule, { loading }] = useMutation<
    DeleteTransactionRule,
    DeleteTransactionRuleVariables
  >(DELETE_RULE_MUTATION, {
    variables: {
      id: ruleId,
    },
    onCompleted: onDone,
  });

  return (
    <Modal onClose={onCancel}>
      {({ close }) => (
        <Confirmation
          title="Delete Rule"
          confirm="Delete"
          isLoading={loading}
          onCancel={close}
          useDangerButton
          onConfirm={() => {
            deleteRule();
          }}
        >
          Are you sure you want to delete this rule? Your transactions will not be affected.
        </Confirmation>
      )}
    </Modal>
  );
};

export default DeleteTransactionRuleConfirmation;
