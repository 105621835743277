import type { CategoryOrGroupFields, MonthlyAmountsData } from 'common/lib/budget/types';

import { CategoryGroupType } from 'common/generated/graphql';
import type { BudgetDataTotalsByMonthFieldsFragment } from 'common/generated/graphql';

export const isIncomeItem = (categoryOrGroup: CategoryOrGroupFields): boolean => {
  if (categoryOrGroup.__typename === 'Category') {
    return categoryOrGroup.group.type === CategoryGroupType.INCOME;
  }

  return categoryOrGroup.type === CategoryGroupType.INCOME;
};

export const getBudgetVariabilityForItem = (categoryOrGroup: CategoryOrGroupFields) =>
  categoryOrGroup.budgetVariability;

export const findPlannedAmountForMonth = (
  monthlyAmounts: MonthlyAmountsData,
  targetMonth: string,
): number =>
  monthlyAmounts.monthlyAmounts.find(({ month }) => month === targetMonth)?.plannedCashFlowAmount ??
  0;

export const calculateAmountDifference = (
  monthlyAmounts: MonthlyAmountsData,
  newAmount: number,
  targetMonth: string,
): number => {
  const currentAmount = findPlannedAmountForMonth(monthlyAmounts, targetMonth);
  return newAmount - currentAmount;
};

export const getUpdatedBudgetTotals = (
  currentTotal: BudgetDataTotalsByMonthFieldsFragment,
  difference: number,
): BudgetDataTotalsByMonthFieldsFragment => ({
  ...currentTotal,
  plannedAmount: currentTotal.plannedAmount + difference,
  remainingAmount: currentTotal.remainingAmount + difference,
});
