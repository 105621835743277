import * as R from 'ramda';

import { toast } from 'components/lib/ui/toast/Toast';

import { useContactSupportContext } from 'lib/contexts/ContactSupportContext';
import type { Options } from 'lib/ui/toast';

import { MS_PER_SECOND } from 'common/constants/time';

const ERROR_TOAST_DURATION_MS = 6 * MS_PER_SECOND;

type CustomErrorToastOptions = {
  onReport?: () => void;
  hideReportButton?: boolean;
};

const useToast = () => {
  const { openContactSupportModal } = useContactSupportContext();

  const openErrorToast = (options?: Partial<Options & CustomErrorToastOptions>) =>
    toast({
      title: 'Sorry, something went wrong',
      description: options?.description || 'Please, try again or report this issue.',
      duration: ERROR_TOAST_DURATION_MS,
      actions: [
        ...(options?.actions || []),
        ...(options?.hideReportButton
          ? []
          : [
              {
                text: 'Report',
                onClick: options?.onReport || openContactSupportModal,
              },
            ]),
      ],
      ...R.omit(['description', 'actions'], options),
    });

  return { openToast: toast, openErrorToast };
};

export default useToast;
