import React from 'react';
import styled from 'styled-components';

import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import CheckAnimation from 'components/lib/ui/CheckAnimation';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LeftToBudgetFooter from 'components/plan/onboarding/LeftToBudgetFooter';
import OnboardingGroupFooter from 'components/plan/onboarding/OnboardingGroupFooter';
import type { OnboardingSharedPageProps } from 'components/routes/budget/BudgetOnboardingFlow';

import {
  getAccumulatedAmountForVariabilities,
  getAccumulatedAmountsMap,
  getBudgetedAmountForType,
} from 'common/lib/budget/Adapters';
import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import { spacing } from 'common/lib/theme/dynamic';
import usePlanAdapter from 'lib/hooks/plan/usePlanAdapter';
import usePlanQuery from 'lib/hooks/plan/usePlanQuery';
import usePlanState from 'lib/hooks/plan/usePlanState';

import { BudgetSystem, BudgetVariability, CategoryGroupType } from 'common/generated/graphql';

const CHECK_ANIMATION_SIZE_PX = 140;

const CONTENT_MAX_WIDTH_PX = 600;

const HeaderContainer = styled(FlexContainer).attrs({ center: true })`
  margin-top: ${spacing.xxxxlarge};
  margin-bottom: -${spacing.large};
`;

const StyledFlexContainer = styled(FlexContainer).attrs({ column: true, full: true })`
  gap: ${spacing.default};
  max-width: ${CONTENT_MAX_WIDTH_PX}px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${({ theme }) => theme.spacing.xsmall} 0;
  background: ${({ theme }) => theme.color.grayFocus};
`;

type Props = {
  onBack: () => void;
  onCancel: () => void;
  onNext: () => void;
} & OnboardingSharedPageProps;

const OnboardingSuccess = ({ title, description, onBack, onCancel, onNext, progress }: Props) => {
  const [state] = usePlanState();
  const { data, fetchedDateRange } = usePlanQuery(state);

  const { budgetSystem, isLoadingInitialData: isLoadingPreferences } = useHouseholdPreferences();
  const usesFlexBudgeting = budgetSystem === BudgetSystem.FIXED_AND_FLEX;

  const { isLoadingInitialData, budgetSummaryData } = usePlanAdapter(data, state, fetchedDateRange);
  const isLoading = isLoadingInitialData || isLoadingPreferences;

  const incomeAmount = getBudgetedAmountForType(budgetSummaryData, CategoryGroupType.INCOME);
  const categoryExpensesAmount = getBudgetedAmountForType(
    budgetSummaryData,
    CategoryGroupType.EXPENSE,
  );

  const accumulatedAmountsMap = getAccumulatedAmountsMap(
    budgetSummaryData,
    BudgetVariability.FLEXIBLE,
  );

  const variabilitiesExpensesAmount = getAccumulatedAmountForVariabilities(accumulatedAmountsMap);

  const fixedExpensesAmount = accumulatedAmountsMap[BudgetVariability.FIXED];
  const nonMonthlyExpensesAmount = accumulatedAmountsMap[BudgetVariability.NON_MONTHLY];
  const flexibleExpensesAmount = accumulatedAmountsMap[BudgetVariability.FLEXIBLE];

  const leftToBudgetOrSave =
    incomeAmount - (usesFlexBudgeting ? variabilitiesExpensesAmount : categoryExpensesAmount);

  return (
    <FeatureOnboardingPage
      header={
        <HeaderContainer>
          <CheckAnimation size={CHECK_ANIMATION_SIZE_PX} />
        </HeaderContainer>
      }
      pageName="Budget Onboarding Success"
      title={title}
      description={description}
      progress={progress}
      onClickBack={onBack}
      onClickCancel={onCancel}
      onClickNext={onNext}
      nextButtonText="View my budget"
      descriptionMaxWidth={CONTENT_MAX_WIDTH_PX}
      isLoading={isLoading}
    >
      <StyledFlexContainer center>
        <OnboardingGroupFooter emphasis title="Budgeted income" value={incomeAmount} />

        {usesFlexBudgeting ? (
          <>
            <Divider />
            {!!fixedExpensesAmount && (
              <OnboardingGroupFooter title="Budgeted fixed expenses" value={fixedExpensesAmount} />
            )}
            {!!nonMonthlyExpensesAmount && (
              <OnboardingGroupFooter
                title="Budgeted non-monthly expenses"
                value={nonMonthlyExpensesAmount}
              />
            )}
            {!!flexibleExpensesAmount && (
              <OnboardingGroupFooter
                title="Budgeted flexible expenses"
                value={flexibleExpensesAmount}
              />
            )}
          </>
        ) : (
          <OnboardingGroupFooter title="Budgeted expenses" value={categoryExpensesAmount} />
        )}

        <Divider />

        <LeftToBudgetFooter value={leftToBudgetOrSave} />
      </StyledFlexContainer>
    </FeatureOnboardingPage>
  );
};

export default OnboardingSuccess;
