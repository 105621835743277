import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import QuestionnaireHeader from 'components/advice/QuestionnaireHeader';
import AdvisorOnboardingPlanSelect from 'components/advisorOnboarding/AdvisorOnboardingPlanSelect';
import AdvisorOnboardingSetupAccount from 'components/advisorOnboarding/AdvisorOnboardingSetupAccount';
import AdvisorOnboardingVerifyEmail from 'components/advisorOnboarding/AdvisorOnboardingVerifyEmail';
import AdvisorOnboardingWelcome from 'components/advisorOnboarding/AdvisorOnboardingWelcome';
import AdvisorOnboardingProvider from 'components/advisorOnboarding/context/AdvisorOnboardingProvider';
import OnboardingCardPage from 'components/lib/layouts/OnboardingCardPage';
import OnboardingPage from 'components/lib/layouts/OnboardingPage';
import type { RouteFlowInfo } from 'components/utils/RouteFlow';

import { clearProviderEphemeralData } from 'actions';
import { useDispatch } from 'lib/hooks';
import type { withSubRoutes } from 'lib/namedRoutes';
import { getIsSponsor, getProviderEphemeralData } from 'selectors';

import routes from 'constants/routes';
import MonarchForAdvisorsLogoDarkUri from 'static/images/monarch-for-advisors-logo-dark.svg';

export interface StepProps {
  component: React.ComponentType;
  route: ReturnType<typeof withSubRoutes>;
  title: string;
  pageName: string;
  excludeFromFederatedAuth?: boolean;
}

export const ADVISOR_ONBOARDING_STEPS: StepProps[] = [
  {
    component: AdvisorOnboardingWelcome,
    route: routes.advisors.signup,
    title: 'Start an account',
    pageName: 'Advisor Onboarding Welcome',
  },
  {
    component: AdvisorOnboardingVerifyEmail,
    route: routes.advisors.signup.verifyEmail,
    title: 'Verify your email',
    pageName: 'Advisor Onboarding Verify Email',
    excludeFromFederatedAuth: true,
  },
  {
    component: AdvisorOnboardingSetupAccount,
    route: routes.advisors.signup.setupAccount,
    title: 'Set up your account',
    pageName: 'Advisor Onboarding Setup Account',
  },
  {
    component: AdvisorOnboardingPlanSelect,
    route: routes.advisors.signup.selectPlan,
    title: 'Select a plan',
    pageName: 'Advisor Onboarding Select Plan',
  },
];

const MonarchForAdvisorsHeaderLogo = styled.img.attrs({ src: MonarchForAdvisorsLogoDarkUri })`
  margin: ${({ theme }) => theme.spacing.xxsmall} 0;
`;

const AdvisorOnboardingFlow = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const clearFederatedAuthTempData = () => dispatch(clearProviderEphemeralData);

  // Note that is_sponsor is only set after the user has completed the onboarding flow,
  // when we change that behavior, we need to rethink this check.
  const isAlreadyAdvisor = useSelector(getIsSponsor);
  useEffect(
    () => {
      if (isAlreadyAdvisor) {
        onComplete();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onComplete = () => {
    history.push(routes.advisorPortal.clients());
    clearFederatedAuthTempData();
  };

  const federatedAuthTempData = useSelector(getProviderEphemeralData);
  const steps = useMemo(
    () =>
      federatedAuthTempData
        ? ADVISOR_ONBOARDING_STEPS.filter(
            ({ excludeFromFederatedAuth }) => !excludeFromFederatedAuth,
          )
        : ADVISOR_ONBOARDING_STEPS,
    [federatedAuthTempData],
  );

  const stepRoutes: RouteFlowInfo[] = useMemo(
    () => steps.map((step) => [step.component, step.route]),
    [steps],
  );

  const activeStepIndex = R.clamp(
    0,
    steps.length - 1,
    steps.findIndex(({ route }) => history.location.pathname === route()),
  );

  const progress = useMemo(() => activeStepIndex / (steps.length - 1), [activeStepIndex, steps]);

  return (
    <AdvisorOnboardingProvider steps={stepRoutes} onComplete={onComplete}>
      <AdvisorOnboardingFlowContainer activeStep={steps[activeStepIndex]} progress={progress}>
        <Switch>
          {steps.map(({ component: Component, route }) => (
            <Route key={route()} path={route()} component={Component} exact />
          ))}
        </Switch>
      </AdvisorOnboardingFlowContainer>
    </AdvisorOnboardingProvider>
  );
};

const AdvisorOnboardingFlowContainer = ({
  activeStep,
  children,
  ...headerProps
}: React.PropsWithChildren<
  { activeStep: StepProps } & React.ComponentProps<typeof QuestionnaireHeader>
>) =>
  R.pipe(R.indexOf(activeStep), R.equals(0))(ADVISOR_ONBOARDING_STEPS) ? (
    <OnboardingCardPage
      name={activeStep.pageName}
      overrideTitle={activeStep.title}
      overrideLogo={<MonarchForAdvisorsHeaderLogo />}
    >
      {children}
    </OnboardingCardPage>
  ) : (
    <OnboardingPage
      name={activeStep.pageName}
      overrideTitle={activeStep.title}
      header={
        <QuestionnaireHeader {...headerProps} overrideLogo={<MonarchForAdvisorsHeaderLogo />} />
      }
    >
      {children}
    </OnboardingPage>
  );

export default React.memo(AdvisorOnboardingFlow);
