import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import CombinedColorTextField from 'components/lib/form/CombinedColorTextField';
import Form, { ErrorsContainer } from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { CREATE_TRANSACTION_TAG, UPDATE_TRANSACTION_TAG } from 'common/lib/graphQl/transactions';
import useTransactionTags from 'common/lib/hooks/transactions/useTransactionTags';
import { getFormValidationSchema } from 'common/lib/transactions/tags';
import { randomArrayElement } from 'common/utils/Random';
import { useModalContext } from 'lib/contexts/ModalContext';
import useTheme from 'lib/hooks/useTheme';

import { getTagColors } from 'common/constants/tags';

import type {
  CreateTransactionTag,
  CreateTransactionTagVariables,
  CreateTransactionTag_createTransactionTag_tag,
} from 'common/generated/graphQlTypes/CreateTransactionTag';
import type { GetHouseholdTransactionTags_householdTransactionTags } from 'common/generated/graphQlTypes/GetHouseholdTransactionTags';
import type {
  UpdateTransactionTag,
  UpdateTransactionTagVariables,
} from 'common/generated/graphQlTypes/UpdateTransactionTag';

const Root = styled(CardBody)`
  ${ErrorsContainer} {
    padding-bottom: 0;
  }
`;

const SubmitButton = styled(FormSubmitButton)`
  width: auto;
`;

type TagFields = Omit<CreateTransactionTag_createTransactionTag_tag, '__typename'>;

type Props = {
  tag?: GetHouseholdTransactionTags_householdTransactionTags;
  draft?: Partial<Omit<TagFields, 'id'>>;
  onDone?: (tag: Maybe<TagFields>) => any;
};

const REFETCH_QUERIES = ['GetHouseholdTransactionTags'];

const UpsertTransactionTagModal = ({ tag, draft, onDone }: Props) => {
  const theme = useTheme();
  const tagColors = useMemo(() => getTagColors(theme), [theme]);

  const { close } = useModalContext();
  const [createTransactionTag] = useMutation<CreateTransactionTag, CreateTransactionTagVariables>(
    CREATE_TRANSACTION_TAG,
    {
      refetchQueries: REFETCH_QUERIES,
    },
  );
  const [updateTransactionTag] = useMutation<UpdateTransactionTag, UpdateTransactionTagVariables>(
    UPDATE_TRANSACTION_TAG,
    {
      refetchQueries: REFETCH_QUERIES,
    },
  );

  const isInserting = R.isNil(tag);
  const initialValues = useMemo(
    () => ({
      ...(tag ? { id: tag.id } : {}),
      name: draft?.name ?? tag?.name ?? '',
      color: draft?.color ?? tag?.color ?? randomArrayElement(tagColors),
    }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { tags: allTags } = useTransactionTags();
  const validationSchema = useMemo(() => getFormValidationSchema(allTags, tag), [allTags, tag]);

  return (
    <ModalCard title={isInserting ? 'New tag' : 'Edit tag'}>
      <Form
        initialValues={initialValues}
        // @ts-ignore TODO: fix typing when passing in two different mutations
        mutation={isInserting ? createTransactionTag : updateTransactionTag}
        overrideValidationSchema={validationSchema}
        onSubmitSuccess={(response) => {
          close();

          if (response?.createTransactionTag?.tag) {
            onDone?.(response.createTransactionTag.tag);
          }
        }}
      >
        <Root>
          <CombinedColorTextField
            label="Color & Name"
            colorField={{ colors: tagColors, name: 'color' }}
            textField={{ name: 'name', placeholder: 'Name your tag...', autoFocus: true }}
          />
        </Root>
        <CardFooter>
          <DefaultButton onClick={close}>Cancel</DefaultButton>
          <SubmitButton size="small" disableWhenValuesUnchanged={false}>
            Save
          </SubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};
export default UpsertTransactionTagModal;
