import React from 'react';
import styled from 'styled-components';

import Droppable from 'components/lib/dnd/Droppable';
import TextButton from 'components/lib/ui/TextButton';

import { CATEGORY_GROUP_TYPE_TO_TITLE } from 'common/lib/categories/constants';

import type { CategoryGroupType } from 'common/generated/graphql';

const StyledDroppable = styled(Droppable)`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const CreateButton = styled(TextButton)`
  padding: 0 ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  type: CategoryGroupType;
  onClickCreateGroup: () => void;
  children?: React.ReactNode;
};

const ManageCategoryTypeSection = ({ type, onClickCreateGroup, children }: Props) => (
  <StyledDroppable droppableId={type} type={type}>
    <Header>
      {CATEGORY_GROUP_TYPE_TO_TITLE[type]}
      <CreateButton onClick={onClickCreateGroup}>Create group</CreateButton>
    </Header>
    {children}
  </StyledDroppable>
);

export default ManageCategoryTypeSection;
