import * as R from 'ramda';

// Same as array.sort(), but returns a new array rather than mutate the input
export const sort = R.sortBy(R.identity);

// moves the specific index to last and returns a new array
export const moveItemToLastByIndex = <T>(arr: T[], index: number): T[] => {
  if (index < 0 || index >= arr.length) {
    return arr;
  }

  const itemToMove = arr[index];

  return R.filter(R.complement(R.equals(itemToMove)))(arr).concat(itemToMove);
};
