import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import PlanColumns from 'components/plan/PlanColumns';
import {
  PlanGridColumn,
  LeftSticky,
  LEFT_WIDTH_PX,
  SECTION_HEADER_HEIGHT_PX,
  SECTION_HEADER_Z_INDEX,
} from 'components/plan/PlanGrid';
import PlanHeaderPastSeparator from 'components/plan/PlanHeaderPastSeparator';

import variables from 'common/lib/theme/variables';
import { usePlanContext } from 'lib/contexts/PlanContext';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import { getDisplayNameForAmountType } from 'lib/plan';

const Root = styled(FlexContainer).attrs({ alignEnd: true })`
  position: sticky;
  top: 0;
  width: 100%;
  height: ${SECTION_HEADER_HEIGHT_PX}px;
  padding-right: ${({ theme }) => theme.spacing.xlarge};
  background: ${variables.color.background.base};
  z-index: ${SECTION_HEADER_Z_INDEX}; /* stylelint-disable-line plugin/no-z-index */
`;

const BottomPadding = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.default};
`;

const Left = styled(BottomPadding)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-right: ${({ theme }) => theme.spacing.xlarge};
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const ColumnTitle = styled(BottomPadding)<{ isActive?: boolean; clickable?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme, isActive }) => (isActive ? theme.color.text : theme.color.textLight)};
  text-align: right;
  transition: ${({ theme }) => theme.transition.default};

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
      :hover {
        color: ${({ theme }) => theme.color.blue};
      }
    `}
`;

const StickyPlanHeaderPastSeparator = styled(PlanHeaderPastSeparator)`
  position: sticky;
  left: ${LEFT_WIDTH_PX + 5}px;
  right: -5px;
  margin-left: 30px;
  margin-right: -30px;
`;

type Props = {
  name: string;
  timeframe: PlanTimeframe;
  numColumns: number;
  onClickColumn: (column: number) => void;
};

const PlanSectionHeader = ({ name, timeframe, numColumns, onClickColumn }: Props) => {
  const { getDateForColumn, getAmountTypeForColumn } = usePlanContext();

  const amountTypeSameForAllColumns = useMemo(
    () =>
      R.all(
        (column) => getAmountTypeForColumn(column) === getAmountTypeForColumn(0),
        R.range(0, numColumns),
      ),
    [numColumns, getAmountTypeForColumn],
  );

  return (
    <Root>
      <LeftSticky>
        <Left>
          <Title>{name}</Title>
        </Left>
      </LeftSticky>
      <PlanColumns numColumns={numColumns} getDateForColumn={getDateForColumn}>
        {(column, date) => (
          <>
            {
              // All columns are planned, so we want to put the separator at the beginning
              column === 0 &&
                amountTypeSameForAllColumns &&
                getAmountTypeForColumn(0) === 'budgeted' && <StickyPlanHeaderPastSeparator />
            }
            <PlanGridColumn>
              <ColumnTitle
                onClick={() => onClickColumn(column)}
                clickable={timeframe !== PlanTimeframe.SingleMonth}
              >
                {timeframe === PlanTimeframe.SingleMonth
                  ? getDisplayNameForAmountType(getAmountTypeForColumn(column))
                  : date.toFormat(timeframe === PlanTimeframe.Yearly ? 'y' : 'MMM y')}
              </ColumnTitle>
            </PlanGridColumn>
            {
              // Columns transitioned from actual to budgeted
              ((getAmountTypeForColumn(column) === 'actual' &&
                getAmountTypeForColumn(column + 1) === 'budgeted') ||
                // All columns are actual, so we want to put the separator at the end
                (column === numColumns - 1 &&
                  amountTypeSameForAllColumns &&
                  getAmountTypeForColumn(column) === 'actual')) && <StickyPlanHeaderPastSeparator />
            }
          </>
        )}
      </PlanColumns>
    </Root>
  );
};

export default React.memo(PlanSectionHeader);
