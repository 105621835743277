import { lighten } from 'polished';
import React, { useRef, useCallback } from 'react';
import deepIsEqual from 'react-fast-compare';
import styled, { css } from 'styled-components';

const Root = styled.div<{ $enablePointer: boolean; $isLast: boolean; $isActiveOnDrawer?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  cursor: ${({ $enablePointer }) => ($enablePointer ? 'pointer' : 'inherit')};
  user-select: ${({ $enablePointer }) => ($enablePointer ? 'none' : 'auto')};
  transition: background-color 0.1s ease-out;
  background-color: ${({ $isActiveOnDrawer, theme }) =>
    $isActiveOnDrawer ? lighten(0.03, theme.color.grayBackground) : theme.color.white};
  overflow: clip;

  ${({ $isLast, theme }) =>
    !$isLast
      ? css`
          border-bottom-color: ${({ theme }) => theme.color.grayBackground};
        `
      : css`
          border-radius: 0 0 ${theme.radius.medium} ${theme.radius.medium};
        `}
`;

type Props = {
  children: React.ReactNode;
  className?: string;
  isLast?: boolean;
  isActiveOnDrawer?: boolean;
  onClickWhitespace?: () => void;
  onHover?: (e: React.MouseEvent) => void;
};

const TransactionsListRow = ({
  children,
  className,
  isLast = false,
  isActiveOnDrawer,
  onHover,
  onClickWhitespace,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const onClick = useCallback(
    ({ currentTarget }: any) => {
      if (currentTarget === ref.current) {
        onClickWhitespace?.();
      }
    },
    [onClickWhitespace],
  );

  return (
    <Root
      ref={ref}
      className={className}
      onClick={onClick}
      onMouseEnter={onHover}
      $enablePointer={!!onClickWhitespace}
      $isLast={isLast}
      $isActiveOnDrawer={isActiveOnDrawer}
    >
      {children}
    </Root>
  );
};

export default React.memo(TransactionsListRow, deepIsEqual);
