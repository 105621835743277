import React from 'react';

import { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import DropdownMenuButton from 'components/lib/ui/DropdownMenuButton';
import WithIndicatorContainer from 'components/lib/ui/WithIndicatorContainer';

import { DEFAULT_ORDER, ORDER_OPTIONS } from 'lib/hooks/transactions/useOrderTransactions';

import type { TransactionOrdering } from 'common/generated/graphql';

const ORDERING_TO_LABEL = ORDER_OPTIONS.reduce(
  (acc, { value, label }) => ({ ...acc, [value]: label }),
  {} as Record<TransactionOrdering, string>,
);

type Props = {
  value: Maybe<TransactionOrdering>;
  onChangeValue: (value: TransactionOrdering) => void;
};

const TransactionsListSortByMenu = ({ value, onChangeValue }: Props) => (
  <WithIndicatorContainer show={value && value !== DEFAULT_ORDER}>
    <DropdownMenuButton title="Sort">
      {Object.entries(ORDERING_TO_LABEL).map(([ordering, label]) => (
        <DropdownMenuItem
          key={ordering}
          value={ordering}
          onClick={() => onChangeValue(ordering as TransactionOrdering)}
          selected={value === ordering}
        >
          {label}
        </DropdownMenuItem>
      ))}
    </DropdownMenuButton>
  </WithIndicatorContainer>
);

export default TransactionsListSortByMenu;
