import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import FormContext from 'common/components/form/FormContext';
import DateField from 'components/lib/form/DateField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import DeleteSyncedSnapshotsConfirmation from 'components/routes/settings/dataSettings/DeleteSyncedSnapshotsConfirmation';

import useQuery from 'common/lib/hooks/useQuery';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';
import useModal from 'lib/hooks/useModal';

import type { OldestDeletableSyncedSnapshotDate } from 'common/generated/graphQlTypes/OldestDeletableSyncedSnapshotDate';
import type {
  PreviewDeleteSyncedSnapshots,
  PreviewDeleteSyncedSnapshotsVariables,
} from 'common/generated/graphQlTypes/PreviewDeleteSyncedSnapshots';

type Props = {
  setDeletedSnapshotsBeforeDate: (date: string) => void;
};

const DeleteSyncedSnapshotsForm = ({ setDeletedSnapshotsBeforeDate }: Props) => {
  const { isDemoHousehold } = useDemoHousehold();
  const [ConfirmationModal, { open, close }] = useModal();
  const [beforeDate, setBeforeDate] = useState<string>('');

  const { data: oldestSnapshotData, refetch: refetchOldestSnapshotData } =
    useQuery<OldestDeletableSyncedSnapshotDate>(GET_OLDEST_DELETABLE_SYNCED_SNAPSHOT_DATE);
  const snapshotOldestDeletableDate = oldestSnapshotData?.oldestDeletableSyncedSnapshotDate
    ? DateTime.fromISO(oldestSnapshotData.oldestDeletableSyncedSnapshotDate)
    : DateTime.local();

  const { data: previewData, isNetworkRequestInFlight: loadingPreviewData } = useQuery<
    PreviewDeleteSyncedSnapshots,
    PreviewDeleteSyncedSnapshotsVariables
  >(GET_PREVIEW_DELETE_SYNCED_SNAPSHOTS_COUNT, {
    variables: { beforeDate },
    skip: !beforeDate,
  });
  const snapshotsCount = beforeDate ? previewData?.previewDeleteSyncedSnapshots || 0 : 0;

  return (
    <Form onSubmit={open} preventSubmitWhileSubmitting>
      <DateField
        name="deleteSnapshotsBefore"
        label="Delete account balance history before..."
        placeholder="Select a date..."
        minDate={snapshotOldestDeletableDate.toJSDate()}
        maxDate={DateTime.local().toJSDate()}
        required
        onChange={(value) => {
          value && setBeforeDate(value);
        }}
      />
      <FormSubmitButton
        pending={loadingPreviewData}
        disabled={snapshotsCount === 0 || isDemoHousehold}
      >
        Delete account balance snapshots
      </FormSubmitButton>

      <FormContext.Consumer>
        {({ resetForm }) => (
          <ConfirmationModal>
            <DeleteSyncedSnapshotsConfirmation
              beforeDate={beforeDate}
              expectedAffectedSnapshotsCount={snapshotsCount!}
              onCancel={close}
              onDelete={() => {
                resetForm();
                setBeforeDate('');
                refetchOldestSnapshotData();
                setDeletedSnapshotsBeforeDate(beforeDate);
                close();
              }}
            />
          </ConfirmationModal>
        )}
      </FormContext.Consumer>
    </Form>
  );
};

const GET_OLDEST_DELETABLE_SYNCED_SNAPSHOT_DATE = gql`
  query OldestDeletableSyncedSnapshotDate {
    oldestDeletableSyncedSnapshotDate
  }
`;

const GET_PREVIEW_DELETE_SYNCED_SNAPSHOTS_COUNT = gql`
  query PreviewDeleteSyncedSnapshots($beforeDate: Date!) {
    previewDeleteSyncedSnapshots(beforeDate: $beforeDate)
  }
`;

export default DeleteSyncedSnapshotsForm;
