import React from 'react';
import styled from 'styled-components';

import { useAdvisorOnboardingFlowState } from 'components/advisorOnboarding/context/AdvisorOnboardingProvider';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import PremiumUpgradeFormTimelineFlow from 'components/premium/PremiumUpgradeFormTimelineFlow';

import useTrack from 'lib/hooks/useTrack';
import isEnvDevelopment from 'lib/isEnvDevelopment';

import { PremiumEventNames } from 'common/constants/analytics';
import * as COPY from 'common/constants/copy';

const Title = styled(OnboardingTitle)`
  margin: ${({ theme }) => theme.spacing.xxlarge} 0 ${({ theme }) => theme.spacing.xlarge};
  white-space: pre;
`;

const FormContainer = styled(OnboardingFormContainer)`
  padding-top: ${({ theme }) => theme.spacing.default};
  padding-left: 0;
  padding-right: 0;
`;

const AdvisorOnboardingPlanSelect = () => {
  const { next } = useAdvisorOnboardingFlowState();

  useTrack(PremiumEventNames.PaymentScreenViewed);

  return (
    <>
      <Title>{COPY.ADVISOR_ONBOARDING.SELECT_PLAN.TITLE}</Title>
      <OnboardingSubtitle>{COPY.ADVISOR_ONBOARDING.SELECT_PLAN.SUBTITLE}</OnboardingSubtitle>
      <FormContainer>
        <PremiumUpgradeFormTimelineFlow
          initialPromoCode={isEnvDevelopment() ? 'SPONSORDEV' : 'MAPBETAJAN'}
          onSuccess={() => next({})}
          isAdvisor
        />
      </FormContainer>
    </>
  );
};

export default AdvisorOnboardingPlanSelect;
