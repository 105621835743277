import React from 'react';
import styled from 'styled-components';

import MessageThreadListItem from 'components/assistant/MessageThreadListItem';
import Scroll from 'components/lib/ui/Scroll';
import RouteLink from 'components/lib/ui/link/RouteLink';

import routes from 'constants/routes';

import type { MessageThreadListItemFieldsFragment } from 'common/generated/graphql';

const StyledMessageThreadListItem = styled(MessageThreadListItem)`
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

type Props = {
  messageThreads: MessageThreadListItemFieldsFragment[];
  activeThreadId?: string;
  className?: string;
};

const MessageThreadsList = ({ messageThreads, activeThreadId, className }: Props) => (
  <Scroll className={className}>
    {messageThreads.map((thread) => (
      <RouteLink to={routes.assistant({ threadId: thread.id })} key={thread.id} unstyled>
        <StyledMessageThreadListItem
          messageThread={thread}
          isSelected={activeThreadId === thread.id}
        />
      </RouteLink>
    ))}
  </Scroll>
);

export default MessageThreadsList;
