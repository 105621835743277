import React, { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import type { DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import uuid from 'uuid/v4';

type Props<ItemT> = {
  items: ItemT[];
  keyExtractor: (item: ItemT) => string;
  indexExtractor: (item: ItemT) => number;
  renderItem: (item: ItemT, isDragging?: boolean) => React.ReactNode;
  onMoveItem: (info: { item: ItemT; destination: number; source: number }) => void;

  /** Vertical spacing between items. You should use this instead of applying a margin-top yourself
   * to avoid a jump when dragging.
   */
  interItemSpacing?: string;
  droppableId?: string; // We can use this in the future if we want support for multiple droppable lists
};

const Item = styled.div<{ interItemSpacing: string }>`
  margin-top: ${({ interItemSpacing }) => interItemSpacing};
`;

/*
  This is a wrapper around react-beautiful-dnd that simplifies usage for a single drag-and-drop list.

  If you need more flexibility you should use the full api provided by the library.
*/
// eslint-disable-next-line
const DragDropList = <ItemT extends {}>({
  items,
  keyExtractor,
  indexExtractor,
  renderItem,
  onMoveItem,
  interItemSpacing = '0',
  droppableId = uuid(),
}: Props<ItemT>) => {
  const onDragEnd = useCallback(
    ({ draggableId, destination, source }: DropResult) => {
      const item = items.find((item) => keyExtractor(item) === draggableId);
      if (!item) {
        throw new Error(`Unable to find draggable item with key ${draggableId}`);
      }

      if (destination && destination.index !== source.index) {
        onMoveItem({
          item,
          destination: destination.index,
          source: source.index,
        });
      }
    },
    [onMoveItem, items, keyExtractor],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {({ innerRef, droppableProps, placeholder }) => (
          <div ref={innerRef} {...droppableProps}>
            {items.map((item) => (
              <Draggable
                draggableId={keyExtractor(item)}
                index={indexExtractor(item)}
                key={keyExtractor(item)}
              >
                {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
                  <Item
                    ref={innerRef}
                    {...draggableProps}
                    {...dragHandleProps}
                    interItemSpacing={interItemSpacing}
                  >
                    {renderItem(item, isDragging)}
                  </Item>
                )}
              </Draggable>
            ))}
            {placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropList;
