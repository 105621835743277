import { when, propEq, reject } from 'ramda';
import { isNumber } from 'ramda-adjunct';
import { useEffect, useMemo } from 'react';

import { useFormContext } from 'common/components/form/FormContext';

import {
  getDefaultRolloverFrequency,
  getRolloverStartMonthOptions,
  getRolloverSuggestedBudgetAmount,
} from 'common/lib/budget/Rollovers';
import type { CreateEditCategoryFormValues } from 'common/lib/categories/form';
import useSharedConstants from 'common/lib/hooks/useSharedConstants';

import { ROLLOVER_ENABLED_FIELD_NAME } from 'common/constants/budget';

import { BudgetRolloverFrequency, BudgetVariability } from 'common/generated/graphql';

/**
 * Hook to handle the rollover form fields.
 * Currently only used on web code because the mobile form is still using the old form component
 * which isn't compatible with the form context defined in common.
 */
const useRolloverFormFields = () => {
  const { values, initialValues, setFieldTouched, setFieldValue } =
    useFormContext<CreateEditCategoryFormValues<any>>();

  // Start month
  const rolloverStartMonthOptions = useMemo(() => getRolloverStartMonthOptions(), []);

  // Frequency
  const [{ budgetRolloverFrequencies = [] }, { isLoading: isLoadingBudgetRolloverFrequencies }] =
    useSharedConstants();

  const rolloverFrequencyOptions = useMemo(
    () =>
      // If the budget variability is NON_MONTHLY, we don't want to show the "Every month" frequency option
      when(
        () => values.budgetVariability === BudgetVariability.NON_MONTHLY,
        reject(propEq('value', BudgetRolloverFrequency.MONTHLY)),
      )(budgetRolloverFrequencies),
    [values.budgetVariability, budgetRolloverFrequencies],
  );

  // Non-Monthly
  const isNonMonthlySelected = useMemo(
    () => values.budgetVariability === BudgetVariability.NON_MONTHLY,
    [values.budgetVariability],
  );
  useEffect(
    () => {
      if (!initialValues[ROLLOVER_ENABLED_FIELD_NAME]) {
        setFieldValue(ROLLOVER_ENABLED_FIELD_NAME, isNonMonthlySelected);
      }

      // Set field as touched so disabling an existing rollover works
      setFieldTouched(ROLLOVER_ENABLED_FIELD_NAME, true);
    },
    // We don't care about dependencies here. We only want to run this effect on initial render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isNonMonthlySelected],
  );

  useEffect(() => {
    // If the budget variability changes to NON_MONTHLY and the frequency is "Every month",
    // we want to clear the frequency field so the user has to select a new one.
    if (
      values.budgetVariability === BudgetVariability.NON_MONTHLY &&
      values.rolloverFrequency === BudgetRolloverFrequency.MONTHLY
    ) {
      setFieldValue('rolloverFrequency', getDefaultRolloverFrequency(values.budgetVariability));
      setFieldTouched('rolloverFrequency', true); // so we can show the error message
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.budgetVariability]);

  const calculatedMonthlyRolloverBudget = useMemo(() => {
    if (!values.rolloverFrequency || !values.rolloverTargetAmount) {
      return 0;
    }

    const frequency = values.rolloverFrequency as BudgetRolloverFrequency;
    return getRolloverSuggestedBudgetAmount(
      frequency,
      values.rolloverTargetAmount,
      values.rolloverStartingBalance,
    );
  }, [values.rolloverFrequency, values.rolloverTargetAmount, values.rolloverStartingBalance]);

  const shouldPromptApplyToFuture = useMemo(() => {
    const frequencyOrTargetAmountOrStartingBalanceChanged =
      values.rolloverFrequency !== initialValues.rolloverFrequency ||
      values.rolloverTargetAmount !== initialValues.rolloverTargetAmount ||
      values.rolloverStartingBalance !== initialValues.rolloverStartingBalance;

    const hasFrequencyAndTargetAmount =
      values.rolloverFrequency && values.rolloverTargetAmount && values.rolloverTargetAmount > 0;

    const frequencyAndTargetAmountAreValid =
      frequencyOrTargetAmountOrStartingBalanceChanged &&
      hasFrequencyAndTargetAmount &&
      values.rolloverFrequency !== BudgetRolloverFrequency.VARIABLE;

    return (
      frequencyAndTargetAmountAreValid &&
      isNumber(calculatedMonthlyRolloverBudget) &&
      calculatedMonthlyRolloverBudget > 0
    );
  }, [
    values.rolloverFrequency,
    values.rolloverTargetAmount,
    values.rolloverStartingBalance,
    initialValues.rolloverFrequency,
    initialValues.rolloverTargetAmount,
    initialValues.rolloverStartingBalance,
    calculatedMonthlyRolloverBudget,
  ]);

  return {
    rolloverStartMonthOptions,
    rolloverFrequencyOptions,
    isLoadingBudgetRolloverFrequencies,
    shouldPromptApplyToFuture,
    calculatedMonthlyRolloverBudget: calculatedMonthlyRolloverBudget ?? 0,
  };
};

export default useRolloverFormFields;
