import pluralize from 'pluralize';
import React, { useState } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import Select from 'components/lib/form/Select';
import TextAreaField from 'components/lib/form/TextAreaField';
import TransactionDrawerFieldRow from 'components/transactions/drawer/TransactionDrawerFieldRow';

const SELECT_OPTIONS = [
  { label: 'No change', value: 'noChange' },
  { label: 'Replace notes', value: 'replace' },
  { label: 'Remove notes', value: 'remove' },
];
const FIELD_NAME = 'notes';

const StyledSelect = styled(Select)`
  flex-grow: 1;
`;

const StyledTextAreaField = styled(TextAreaField)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  selectedCount: number;
};

const TransactionBulkUpdateNotesInput = ({ selectedCount }: Props) => {
  const [hideInput, setHideInput] = useState(true);
  const { setFieldValue } = useFormContext();

  return (
    <TransactionDrawerFieldRow label="Notes">
      <StyledSelect
        options={SELECT_OPTIONS}
        defaultValue={SELECT_OPTIONS[0]}
        onChange={({ value }: { value: string }) => {
          setHideInput(value !== 'replace');
          setFieldValue(FIELD_NAME, value === 'remove' ? null : undefined);
        }}
      />
      {!hideInput && (
        <StyledTextAreaField
          name={FIELD_NAME}
          placeholder={`Replace notes on ${selectedCount} ${pluralize(
            'transaction',
            selectedCount,
          )}`}
          minRows={2}
          hideResizeHandle
          hideLabel
        />
      )}
    </TransactionDrawerFieldRow>
  );
};

export default TransactionBulkUpdateNotesInput;
