import React from 'react';

import useFeatureEntitlement from 'common/lib/hooks/premium/useFeatureEntitlement';

import type { ProductFeature } from 'common/constants/premium';

type Props = {
  feature: ProductFeature | undefined;
  children: React.ReactNode;
  /** If false (default): children will be rendered only when user has the required entitlement.
   *  If true: children will be rendered only when user does not have the required entitlement. */
  showWhenNoAccess?: boolean;
};

/**
 * Component used to conditionally render children based on whether the user has the required entitlement for a feature.
 */
const FeatureEntitlementGate = ({ feature, children, showWhenNoAccess }: Props) => {
  const featureInfo = useFeatureEntitlement(feature);
  const { hasAccess } = featureInfo;

  const show = showWhenNoAccess ? !hasAccess : hasAccess;

  return show ? <>{children}</> : null;
};

export default FeatureEntitlementGate;
