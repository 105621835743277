import React from 'react';
import styled from 'styled-components';

import DemoOnboardingSubtitle from 'components/demo/DemoOnboardingSubtitle';
import DemoOnboardingTitle from 'components/demo/DemoOnboardingTitle';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { useQueryParam } from 'lib/hooks';

const IntroContainer = styled(FlexContainer).attrs({ column: true })`
  margin-top: 100px;
  width: 100%;
  align-items: center;
  ${DemoOnboardingTitle} {
    margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
  }
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const StyledOnboardingSubtitle = styled(DemoOnboardingSubtitle)`
  max-width: 438px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 438px;
  margin-top: ${({ theme }) => theme.spacing.xlarge};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: ${({ theme }) => theme.spacing.xxxlarge};
  }
`;

const CredentialRow = styled(Flex)`
  justify-content: space-between;
  width: 360px;
`;

const CredentialValue = styled(Text)`
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
`;

type Props = {
  next: () => void;
};

const DemoOnboardingUserDetails = ({ next }: Props) => {
  const username = useQueryParam('username') || '';
  const password = useQueryParam('password') || '';

  const handleContinueButtonClicked = () => {
    next();
  };

  return (
    <>
      <IntroContainer>
        <DemoOnboardingTitle>Welcome to Monarch!</DemoOnboardingTitle>
        <StyledOnboardingSubtitle>
          A new demo account was successfully created and populated with data so that you can have a
          taste of how it feels like to manage your money with Monarch. You can now use the
          credentials below to log in, or click on the button to go straight to the dashboard.
        </StyledOnboardingSubtitle>
      </IntroContainer>
      <CredentialRow>
        <Text weight="medium">Username</Text>
        <CredentialValue>{username}</CredentialValue>
      </CredentialRow>
      <CredentialRow>
        <Text weight="medium">Password</Text>
        <CredentialValue>{password}</CredentialValue>
      </CredentialRow>
      <StyledPrimaryButton size="large" onClick={handleContinueButtonClicked}>
        Continue
      </StyledPrimaryButton>
    </>
  );
};

export default DemoOnboardingUserDetails;
