import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  position: relative;

  ::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

type Props = {
  children?: React.ReactNode;
  className?: string;
};

/**
 * Div that maintains square aspect ratio.
 * https://spin.atomicobject.com/2015/07/14/css-responsive-square/
 */
const Square = ({ children, className }: Props) => (
  <Root>
    <Container className={className}>{children}</Container>
  </Root>
);

export default Square;
