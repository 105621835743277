import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import GoalsMapAccountsModal from 'components/goalsV2/GoalsMapAccountsModal';
import RankGoalsModal from 'components/goalsV2/RankGoalsModal';
import Controls from 'components/lib/ui/Controls';
import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import Icon from 'components/lib/ui/Icon';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import { OverlayTrigger } from 'components/lib/ui/popover';

import useModal from 'lib/hooks/useModal';

import routes from 'constants/routes';

const OptionsButton = styled(DefaultButton).attrs({ size: 'small' })`
  position: relative;
  padding-right: ${({ theme }) => theme.spacing.xxsmall};
`;

const PlusIcon = styled(Icon).attrs({ name: 'plus', size: 14 })`
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const GoalsHeaderControls = () => {
  const { push } = useHistory();
  const [MapAccountsModal, { open: openMapAccountsModal }] = useModal();
  const [RankModal, { open: openRankGoalsModal }] = useModal();

  return (
    <Controls>
      <OverlayTrigger
        overlay={
          <DropdownMenu>
            <DropdownMenuItem onClick={openMapAccountsModal}>Edit accounts</DropdownMenuItem>
            <DropdownMenuItem onClick={openRankGoalsModal}>Edit goal priorities</DropdownMenuItem>
          </DropdownMenu>
        }
        placement="bottom-end"
      >
        {({ toggleOpen, isOpen }) => (
          <OptionsButton onClick={toggleOpen} active={isOpen}>
            Actions
            <ButtonIcon name="chevron-down" />
          </OptionsButton>
        )}
      </OverlayTrigger>

      <PrimaryButton size="small" onClick={() => push(routes.goalsV2.select())}>
        <PlusIcon />
        Add goals
      </PrimaryButton>

      <MapAccountsModal>
        <GoalsMapAccountsModal />
      </MapAccountsModal>
      <RankModal>
        <RankGoalsModal />
      </RankModal>
    </Controls>
  );
};

export default GoalsHeaderControls;
