import { DateTime } from 'luxon';

const TODAY = DateTime.local();

const getRelativeMonths = (months: number) => TODAY.startOf('month').minus({ months }).toISODate();

export const MOCK_BUDGET_NEW = {
  budgetData: {
    monthlyAmountsByCategory: [
      {
        category: {
          id: '152839700055052349',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700031983635',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 1160.26,
            remainingAmount: -1160.26,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 870,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 870,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 870,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 870,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 870,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 870,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700030935057',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 100,
            remainingAmount: -100,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 80,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 80,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 80,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 80,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 80,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 80,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700045615149',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700038275100',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 320.47,
            remainingAmount: -320.47,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 320,
            plannedSetAsideAmount: 0,
            actualAmount: 320.47,
            remainingAmount: -0.47,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 320,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 320,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 320,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 320,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700040372257',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 499,
            remainingAmount: -499,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 570,
            plannedSetAsideAmount: 0,
            actualAmount: 233,
            remainingAmount: 337,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 570,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 570,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 570,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 570,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700048760884',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 500.23,
            remainingAmount: -500.23,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 500,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 500,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 500,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 500,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 500,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 500,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700046663729',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700036177945',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 1385,
            remainingAmount: -1385,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 1380,
            plannedSetAsideAmount: 0,
            actualAmount: 1385,
            remainingAmount: -5,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 1380,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 1380,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 1380,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 1380,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700028837901',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 12600,
            remainingAmount: -12600,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 8400,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 8400,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 8400,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 8400,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 8400,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 8400,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700043517993',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 120,
            remainingAmount: -120,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 80,
            plannedSetAsideAmount: 0,
            actualAmount: 85,
            remainingAmount: -5,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 80,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 80,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 80,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 80,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700046663727',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700058198086',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700041420836',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700052955194',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700055052350',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700052955195',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700045615150',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700043517994',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 500,
            remainingAmount: -500,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 500,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 500,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 500,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 500,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 500,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 500,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700058198087',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700047712306',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700046663728',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700042469413',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 280.97,
            remainingAmount: -280.97,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 120,
            plannedSetAsideAmount: 0,
            actualAmount: 40.97,
            remainingAmount: 79.03,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 120,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 120,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 120,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 120,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700049809461',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700031983634',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700029886478',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 24,
            remainingAmount: -24,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 20,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 20,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 20,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 20,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 20,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 20,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700033032212',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700037226522',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700041420834',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 75.35,
            remainingAmount: -75.35,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 50,
            plannedSetAsideAmount: 0,
            actualAmount: 701.71,
            remainingAmount: -651.71,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 50,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 50,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 50,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 50,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700039323677',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700058198088',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700029886479',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700041420835',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 30,
            remainingAmount: -30,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 20,
            plannedSetAsideAmount: 0,
            actualAmount: 15,
            remainingAmount: 5,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 20,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 20,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 20,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 20,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700055052351',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700050858038',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700047712307',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 39.99,
            remainingAmount: -39.99,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 40,
            plannedSetAsideAmount: 0,
            actualAmount: 39.99,
            remainingAmount: 0.01,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 40,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 40,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 40,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 40,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700044566571',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700033032213',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 197,
            remainingAmount: -197,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 190,
            plannedSetAsideAmount: 0,
            actualAmount: 77,
            remainingAmount: 113,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 190,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 190,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 190,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 190,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700039323678',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 130,
            remainingAmount: -130,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 120,
            plannedSetAsideAmount: 0,
            actualAmount: 108,
            remainingAmount: 12,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 120,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 120,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 120,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 120,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700037226523',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 320,
            remainingAmount: -320,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 320,
            plannedSetAsideAmount: 0,
            actualAmount: 298.91,
            remainingAmount: 21.09,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 320,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 320,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 320,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 320,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700054003772',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700042469414',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700034080790',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700056100928',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700050858039',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 100,
            remainingAmount: -100,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 100,
            plannedSetAsideAmount: 0,
            actualAmount: 40,
            remainingAmount: 60,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 100,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 100,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 100,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 100,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700045615148',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 140,
            remainingAmount: -140,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 120,
            plannedSetAsideAmount: 0,
            actualAmount: 100,
            remainingAmount: 20,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 120,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 120,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 120,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 120,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700040372255',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 115,
            remainingAmount: -115,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 120,
            plannedSetAsideAmount: 0,
            actualAmount: 115,
            remainingAmount: 5,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 120,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 120,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 120,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 120,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700030935056',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 2089.46,
            remainingAmount: -2089.46,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700042469415',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 150,
            remainingAmount: -150,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 150,
            plannedSetAsideAmount: 0,
            actualAmount: 150,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 150,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 150,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 150,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 150,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700056100929',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700043517992',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700051906616',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 201.45,
            remainingAmount: -201.45,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 200,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 200,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 200,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 200,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 200,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 200,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700034080791',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: -2000,
            remainingAmount: 2000,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700040372256',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 140,
            remainingAmount: -140,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 140,
            plannedSetAsideAmount: 0,
            actualAmount: 140,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 140,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 140,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 140,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 140,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700057149506',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700051906617',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700035129368',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 32,
            remainingAmount: -32,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 30,
            plannedSetAsideAmount: 0,
            actualAmount: 19,
            remainingAmount: 11,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 30,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 30,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 30,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 30,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700057149507',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700057149508',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
      {
        category: {
          id: '152839700057149509',
          __typename: 'Category',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            plannedSetAsideAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            previousMonthRolloverAmount: null,
            rolloverType: null,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryMonthlyAmounts',
      },
    ],
    monthlyAmountsByCategoryGroup: [
      {
        categoryGroup: {
          id: '152839699995283812',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 12624,
            remainingAmount: -12624,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 8420,
            actualAmount: 2089.46,
            remainingAmount: 6330.54,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 8420,
            actualAmount: 0,
            remainingAmount: 8420,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 8420,
            actualAmount: 0,
            remainingAmount: 8420,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839699995283813',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 100,
            remainingAmount: -100,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 80,
            actualAmount: 0,
            remainingAmount: 80,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 80,
            actualAmount: 0,
            remainingAmount: 80,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 80,
            actualAmount: 0,
            remainingAmount: 80,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839699996332390',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 1389.26,
            remainingAmount: -1389.26,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 1090,
            actualAmount: -1904,
            remainingAmount: 2994,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 1090,
            actualAmount: 0,
            remainingAmount: 1090,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 1090,
            actualAmount: 0,
            remainingAmount: 1090,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839699996332391',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 1705,
            remainingAmount: -1705,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 1700,
            actualAmount: 1683.91,
            remainingAmount: 16.09,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 1700,
            actualAmount: 0,
            remainingAmount: 1700,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 1700,
            actualAmount: 0,
            remainingAmount: 1700,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839699996332392',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 705.47,
            remainingAmount: -705.47,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 700,
            actualAmount: 683.47,
            remainingAmount: 16.53,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 700,
            actualAmount: 0,
            remainingAmount: 700,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 700,
            actualAmount: 0,
            remainingAmount: 700,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839699997380969',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 604.35,
            remainingAmount: -604.35,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 640,
            actualAmount: 949.71,
            remainingAmount: -309.71,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 640,
            actualAmount: 0,
            remainingAmount: 640,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 640,
            actualAmount: 0,
            remainingAmount: 640,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839699998429546',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 430.97,
            remainingAmount: -430.97,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 270,
            actualAmount: 190.97,
            remainingAmount: 79.03,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 270,
            actualAmount: 0,
            remainingAmount: 270,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 270,
            actualAmount: 0,
            remainingAmount: 270,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839699999478123',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 760,
            remainingAmount: -760,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 700,
            actualAmount: 185,
            remainingAmount: 515,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 700,
            actualAmount: 0,
            remainingAmount: 700,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 700,
            actualAmount: 0,
            remainingAmount: 700,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839700000526700',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839700001575277',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839700001575278',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 39.99,
            remainingAmount: -39.99,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 40,
            actualAmount: 39.99,
            remainingAmount: 0.01,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 40,
            actualAmount: 0,
            remainingAmount: 40,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 40,
            actualAmount: 0,
            remainingAmount: 40,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839700002623855',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 801.68,
            remainingAmount: -801.68,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 800,
            actualAmount: 40,
            remainingAmount: 760,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 800,
            actualAmount: 0,
            remainingAmount: 800,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 800,
            actualAmount: 0,
            remainingAmount: 800,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839700002623856',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839700003672433',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
      {
        categoryGroup: {
          id: '152839700003672434',
          __typename: 'CategoryGroup',
        },
        monthlyAmounts: [
          {
            month: getRelativeMonths(-1),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(0),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(1),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
          {
            month: getRelativeMonths(2),
            plannedCashFlowAmount: 0,
            actualAmount: 0,
            remainingAmount: 0,
            __typename: 'BudgetMonthlyAmounts',
          },
        ],
        __typename: 'BudgetCategoryGroupMonthlyAmounts',
      },
    ],
    totalsByMonth: [
      {
        month: getRelativeMonths(-1),
        totalIncome: {
          plannedAmount: 0,
          actualAmount: 12624,
          remainingAmount: -12624,
          previousMonthRolloverAmount: 0,
          __typename: 'BudgetTotals',
        },
        totalExpenses: {
          plannedAmount: 0,
          actualAmount: 6536.72,
          remainingAmount: -6536.72,
          previousMonthRolloverAmount: 0,
          __typename: 'BudgetTotals',
        },
        __typename: 'BudgetMonthTotals',
      },
      {
        month: getRelativeMonths(0),
        totalIncome: {
          plannedAmount: 8420,
          actualAmount: 2089.46,
          remainingAmount: 6330.54,
          previousMonthRolloverAmount: 0,
          __typename: 'BudgetTotals',
        },
        totalExpenses: {
          plannedAmount: 6020,
          actualAmount: 1869.05,
          remainingAmount: 4150.95,
          previousMonthRolloverAmount: 0,
          __typename: 'BudgetTotals',
        },
        __typename: 'BudgetMonthTotals',
      },
      {
        month: getRelativeMonths(1),
        totalIncome: {
          plannedAmount: 8420,
          actualAmount: 0,
          remainingAmount: 8420,
          previousMonthRolloverAmount: 0,
          __typename: 'BudgetTotals',
        },
        totalExpenses: {
          plannedAmount: 6020,
          actualAmount: 0,
          remainingAmount: 6020,
          previousMonthRolloverAmount: 0,
          __typename: 'BudgetTotals',
        },
        __typename: 'BudgetMonthTotals',
      },
      {
        month: getRelativeMonths(2),
        totalIncome: {
          plannedAmount: 8420,
          actualAmount: 0,
          remainingAmount: 8420,
          previousMonthRolloverAmount: 0,
          __typename: 'BudgetTotals',
        },
        totalExpenses: {
          plannedAmount: 6020,
          actualAmount: 0,
          remainingAmount: 6020,
          previousMonthRolloverAmount: 0,
          __typename: 'BudgetTotals',
        },
        __typename: 'BudgetMonthTotals',
      },
    ],
    __typename: 'BudgetData',
  },
  categoryGroups: [
    {
      id: '152839699995283812',
      name: 'Income',
      order: 0,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700028837901',
          name: 'Paychecks',
          icon: '💵',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700029886478',
          name: 'Interest',
          icon: '💸',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700029886479',
          name: 'Business Income',
          icon: '💰',
          order: 2,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700030935056',
          name: 'Other Income',
          icon: '💰',
          order: 3,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'income',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839699995283813',
      name: 'Gifts & Donations',
      order: 1,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700030935057',
          name: 'Charity',
          icon: '🎗',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700031983634',
          name: 'Gifts',
          icon: '🎁',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'expense',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839699996332390',
      name: 'Auto & Transport',
      order: 2,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700031983635',
          name: 'Auto Payment',
          icon: '🚗',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700033032212',
          name: 'Public Transit',
          icon: '🚃',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700033032213',
          name: 'Gas',
          icon: '⛽️',
          order: 2,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700034080790',
          name: 'Auto Maintenance',
          icon: '🔧',
          order: 3,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700034080791',
          name: 'Parking & Tolls',
          icon: '🏢',
          order: 4,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700035129368',
          name: 'Taxi & Ride Shares',
          icon: '🚕',
          order: 5,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'expense',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839699996332391',
      name: 'Housing',
      order: 3,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700036177945',
          name: 'Mortgage',
          icon: '🏠',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700037226522',
          name: 'Rent',
          icon: '🏠',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700037226523',
          name: 'Home Improvement',
          icon: '🔨',
          order: 2,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'expense',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839699996332392',
      name: 'Bills & Utilities',
      order: 4,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700038275100',
          name: 'Garbage',
          icon: '🗑',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700039323677',
          name: 'Water',
          icon: '💧',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700039323678',
          name: 'Gas & Electric',
          icon: '⚡️',
          order: 2,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700040372255',
          name: 'Internet & Cable',
          icon: '🌐',
          order: 3,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700040372256',
          name: 'Phone',
          icon: '📱',
          order: 4,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'expense',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839699997380969',
      name: 'Food & Dining',
      order: 5,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700040372257',
          name: 'Groceries',
          icon: '🍏',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700041420834',
          name: 'Restaurants & Bars',
          icon: '🍽',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700041420835',
          name: 'Coffee Shops',
          icon: '☕️',
          order: 2,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'expense',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839699998429546',
      name: 'Travel & Lifestyle',
      order: 6,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700041420836',
          name: 'Travel & Vacation',
          icon: '🏝',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700042469413',
          name: 'Entertainment & Recreation',
          icon: '🎥',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700042469414',
          name: 'Personal',
          icon: '👑',
          order: 2,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700042469415',
          name: 'Pets',
          icon: '🐶',
          order: 3,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700043517992',
          name: 'Fun Money',
          icon: '😜',
          order: 4,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'expense',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839699999478123',
      name: 'Shopping',
      order: 7,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700043517993',
          name: 'Shopping',
          icon: '🛍',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700043517994',
          name: 'Clothing',
          icon: '👕',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700044566571',
          name: 'Furniture & Housewares',
          icon: '🪑',
          order: 2,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700045615148',
          name: 'Electronics',
          icon: '🖥',
          order: 3,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'expense',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839700000526700',
      name: 'Children',
      order: 8,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700045615149',
          name: 'Child Care',
          icon: '👶',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700045615150',
          name: 'Child Activities',
          icon: '⚽️',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'expense',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839700001575277',
      name: 'Education',
      order: 9,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700046663727',
          name: 'Student Loans',
          icon: '🎓',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700046663728',
          name: 'Education',
          icon: '🏫',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'expense',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839700001575278',
      name: 'Health & Wellness',
      order: 10,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700046663729',
          name: 'Medical',
          icon: '💊',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700047712306',
          name: 'Dentist',
          icon: '🦷',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700047712307',
          name: 'Fitness',
          icon: '💪',
          order: 2,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'expense',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839700002623855',
      name: 'Financial',
      order: 11,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700048760884',
          name: 'Loan Repayment',
          icon: '💰',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700049809461',
          name: 'Financial & Legal Services',
          icon: '🗄',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700050858038',
          name: 'Financial Fees',
          icon: '🏦',
          order: 2,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700050858039',
          name: 'Cash & ATM',
          icon: '🏧',
          order: 3,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700051906616',
          name: 'Insurance',
          icon: '☂️',
          order: 4,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700051906617',
          name: 'Taxes',
          icon: '🏛️',
          order: 5,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'expense',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839700002623856',
      name: 'Other',
      order: 12,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700052955194',
          name: 'Uncategorized',
          icon: '❓',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700052955195',
          name: 'Check',
          icon: '💸',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700054003772',
          name: 'Miscellaneous',
          icon: '💲',
          order: 2,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'expense',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839700003672433',
      name: 'Business',
      order: 13,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700055052349',
          name: 'Advertising & Promotion',
          icon: '📣',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700055052350',
          name: 'Business Utilities & Communication',
          icon: '📞',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700055052351',
          name: 'Employee Wages & Contract Labor',
          icon: '💵',
          order: 2,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700056100928',
          name: 'Business Travel & Meals',
          icon: '🍴',
          order: 3,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700056100929',
          name: 'Business Auto Expenses',
          icon: '🚖',
          order: 4,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700057149506',
          name: 'Business Insurance',
          icon: '📁',
          order: 5,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700057149507',
          name: 'Office Supplies & Expenses',
          icon: '📎',
          order: 6,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700057149508',
          name: 'Office Rent',
          icon: '🏢',
          order: 7,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700057149509',
          name: 'Postage & Shipping',
          icon: '📦',
          order: 8,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'expense',
      __typename: 'CategoryGroup',
    },
    {
      id: '152839700003672434',
      name: 'Transfers',
      order: 14,
      groupLevelBudgetingEnabled: false,
      categories: [
        {
          id: '152839700058198086',
          name: 'Transfer',
          icon: '🔁',
          order: 0,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700058198087',
          name: 'Credit Card Payment',
          icon: '💳',
          order: 1,
          rolloverPeriod: null,
          __typename: 'Category',
        },
        {
          id: '152839700058198088',
          name: 'Balance Adjustments',
          icon: '⚖️',
          order: 2,
          rolloverPeriod: null,
          __typename: 'Category',
        },
      ],
      type: 'transfer',
      __typename: 'CategoryGroup',
    },
  ],
};
