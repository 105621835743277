import type { DropResult } from 'react-beautiful-dnd';

/**
 * Returns true if a DropResult needs a state update. Returns false if destination
 * is the same as source.
 */
export const dropResultRequiresChanges = (result: DropResult) => {
  if (!result.destination) {
    return false;
  }
  if (
    result.destination?.droppableId === result.source.droppableId &&
    result.destination?.index === result.source.index
  ) {
    return false;
  }

  return true;
};
