const SYSTEM_PREFIX = 'System:';
const AI_PREFIX = 'AI:';
const HUMAN_PREFIX = 'Human:';
const ROLE_PREFIXES = [SYSTEM_PREFIX, AI_PREFIX, HUMAN_PREFIX];

export const parsePromptToMessages = (prompt: string) => {
  const prefixes = ROLE_PREFIXES.join('|');
  const prefixesWithNewline = ROLE_PREFIXES.map((prefix) => `\n${prefix}`).join('|');
  const regex = new RegExp(`(${prefixes}) (.+?)(?=${prefixesWithNewline}|$)`, 'gs');

  const matches = [...prompt.matchAll(regex)];
  const messages = matches.map((match) => ({
    role: match[1].trim(),
    content: match[2].trim(),
  }));

  return messages;
};
