import { gql } from 'common/generated/gql';

export const CREATE_RULE_MUTATION_V2 = gql(`
  mutation Common_CreateTransactionRuleMutationV2($input: CreateTransactionRuleInput!) {
    createTransactionRuleV2(input: $input) {
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const UPDATE_RULE_MUTATION_V2 = gql(`
  mutation Common_UpdateTransactionRuleMutationV2($input: UpdateTransactionRuleInput!) {
    updateTransactionRuleV2(input: $input) {
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const DELETE_RULE_MUTATION = gql(`
  mutation Common_DeleteTransactionRule($id: ID!) {
    deleteTransactionRule(id: $id) {
      deleted
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);
