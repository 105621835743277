import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import type { Color } from 'components/cashFlows/BreakdownItem';
import BreakdownItem, { balance } from 'components/cashFlows/BreakdownItem';

import type { BreakdownEntry } from 'lib/cashFlow/BreakdownAdapters';

const Root = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing.xsmall};
  padding: ${({ theme }) => theme.spacing.default};
  padding-left: 0;
  position: relative;
`;

export type Props = {
  className?: string;
  items: BreakdownEntry[];
  total: number;
  color?: Color;
  empty?: React.ReactNode;
  hideAmounts: boolean;
};

const Breakdown = ({ className, items, total, color, empty, hideAmounts }: Props) => {
  const sortedItems = R.sortBy((entry) => -balance(entry), items);
  const max = sortedItems[0]?.value ?? 0;

  return (
    <Root className={className}>
      {sortedItems.length > 0
        ? sortedItems.map((entry, i) => (
            <BreakdownItem
              key={i}
              entry={entry}
              max={max}
              total={total}
              color={color}
              hideAmounts={hideAmounts}
            />
          ))
        : empty}
    </Root>
  );
};

export default Breakdown;
