import { AccountTypeName } from 'common/constants/accounts';
import { MS_PER_SECOND } from 'common/constants/time';

import type { AccountFilters } from 'common/generated/graphql';

export const RECURRING_SEARCH_TOAST_TIMEOUT_MS = MS_PER_SECOND * 30;

// Taken from RecurringTransactionFrequency
export type Frequency =
  | 'weekly'
  | 'biweekly'
  | 'four_weekly'
  | 'semimonthly_start_mid'
  | 'semimonthly_mid_end'
  | 'monthly'
  | 'bimonthly'
  | 'quarterly'
  | 'four_month'
  | 'semiyearly'
  | 'yearly';

export const RECURRING_ACCOUNT_FILTERS = {
  ignoreMappedToLiability: true,
  excludeAccountTypes: [
    AccountTypeName.BROKERAGE,
    AccountTypeName.OTHER_ASSET,
    AccountTypeName.DEPOSITORY,
    AccountTypeName.OTHER,
    AccountTypeName.VALUABLES,
    AccountTypeName.VEHICLE,
  ],
} as unknown as AccountFilters;

export const RECURRING_TRANSACTIONS_FREQUENCIES_INFO: {
  [key in Frequency]: string;
} = {
  weekly: 'Every week',
  biweekly: 'Every 2 weeks',
  four_weekly: 'Every 4 weeks',
  semimonthly_start_mid: 'Twice a month (1st & 15th)',
  semimonthly_mid_end: 'Twice a month (15th & last day)',
  monthly: 'Every month',
  bimonthly: 'Every 2 months',
  quarterly: 'Every 3 months',
  four_month: 'Every 4 months',
  semiyearly: 'Every 6 months',
  yearly: 'Every year',
};

export enum RecurringTransactionStreamType {
  Expense = 'expense',
  Income = 'income',
}

export enum SpinwheelCreditReportLiabilityType {
  HomeLoans = 'homeLoans',
  AutoLoans = 'autoLoans',
  PersonalLoans = 'personalLoans',
  StudentLoans = 'studentLoans',
  CreditCards = 'creditCards',
  miscellaneousLiabilities = 'miscellaneousLiabilities',
}

export type SpinwheelFrequency =
  | 'BIWEEKLY'
  | 'DEFERRED'
  | 'SEMI_MONTHLY'
  | 'BI_MONTHLY'
  | 'MONTHLY'
  | 'SINGLE_PAYMENT_LOAN'
  | 'QUARTERLY'
  | 'SEMI_ANNUALLY'
  | 'TRI_ANNUALLY'
  | 'WEEKLY'
  | 'ANNUALLY';

// converts Spinwheel frequency to RecurringTransactionFrequency
// this is used to get the default frequency during the mapping
export const SPINWHEEL_FREQUENCIES_TO_MONARCH_FREQUENCIES: {
  [key in SpinwheelFrequency]: string;
} = {
  WEEKLY: 'weekly',
  BIWEEKLY: 'biweekly',
  SEMI_MONTHLY: 'semimonthly_start_mid',
  MONTHLY: 'monthly',
  BI_MONTHLY: 'bimonthly',
  QUARTERLY: 'quarterly',
  SEMI_ANNUALLY: 'semiyearly',
  ANNUALLY: 'yearly',
  SINGLE_PAYMENT_LOAN: 'monthly', // we don't support "SINGLE PAYMENT LOAN"
  DEFERRED: 'monthly', // we don't support "DEFERRED"
  TRI_ANNUALLY: 'monthly', // we don't support "TRI ANNUALLY"
};

export const spinwheelFrequencyToMonarchFrequency = (frequency: SpinwheelFrequency): string =>
  frequency
    ? SPINWHEEL_FREQUENCIES_TO_MONARCH_FREQUENCIES[frequency]
    : SPINWHEEL_FREQUENCIES_TO_MONARCH_FREQUENCIES.MONTHLY;

// liability types
export type LiabilityType =
  | 'auto_loan'
  | 'home_loan'
  | 'credit_card'
  | 'personal_loan'
  | 'student_loan'
  | 'miscellaneous_liability';

export const SPINWHEEL_LIABILITY_TYPE_INFO: {
  [key in LiabilityType]: string;
} = {
  auto_loan: 'Auto loan',
  home_loan: 'Home loan',
  credit_card: 'Credit card',
  personal_loan: 'Personal loan',
  student_loan: 'Student loan',
  miscellaneous_liability: 'Miscellaneous',
};

export const getSpinwheelLiabilityType = (liabilityType: LiabilityType): string =>
  liabilityType
    ? SPINWHEEL_LIABILITY_TYPE_INFO[liabilityType]
    : SPINWHEEL_LIABILITY_TYPE_INFO.miscellaneous_liability;
