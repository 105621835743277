import type { Options } from 'lib/hooks/useFeatureFlag';
import useFeatureFlag from 'lib/hooks/useFeatureFlag';
import type { FeatureFlagName } from 'state/features/types';

export type Props = Options & {
  name: FeatureFlagName;
  children: (value: string) => JSX.Element | null;
};

const FeatureFlag = ({ name, children, ...options }: Props) => {
  const { treatment } = useFeatureFlag(name, options);
  return children(treatment);
};

export default FeatureFlag;
