import { useMutation, gql } from '@apollo/client';
import React from 'react';

import Confirmation from 'components/lib/ui/Confirmation';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import logger from 'lib/logger';

import type { DisableMfa } from 'common/generated/graphQlTypes/DisableMfa';

type Props = {
  onCancel: () => void;
  onDisable: () => void;
};

const DISABLE_MFA_QUERY = gql`
  mutation Web_DisableMfa {
    deleteTotpDevice {
      deleted
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

const DisableMfaConfirmation = ({ onCancel, onDisable }: Props) => {
  const [disableMfa, { loading }] = useMutation<DisableMfa>(DISABLE_MFA_QUERY);

  const performDisable = async () => {
    const { data, errors } = await disableMfa();

    if (data?.deleteTotpDevice?.deleted) {
      onDisable();
    } else {
      logger.error('MFA not disabled.', errors, data?.deleteTotpDevice?.errors);
      onCancel();
    }
  };

  return (
    <Confirmation
      title="Disable multi-factor authentication"
      confirm="Disable multi-factor authentication"
      isLoading={loading}
      onCancel={onCancel}
      onConfirm={performDisable}
      useDangerButton
    >
      <div>
        Are you sure you want to disable multi-factor authentication? This will remove the extra
        security layer for your account completely. You can re-enable multi-factor authentication at
        any time.
      </div>
    </Confirmation>
  );
};

export default DisableMfaConfirmation;
