import * as React from 'react';

import ContentLoader from 'components/lib/ui/ContentLoader';

type Props = {
  className?: string;
};

const MerchantSearchListLoading = ({ className }: Props) => (
  <ContentLoader className={className} height={56}>
    <circle cx="30" cy="28" r="27" />
    <rect x="70" y="6" rx="5" ry="5" width="175" height="22" />
    <rect x="70" y="33" rx="5" ry="5" width="130" height="18" />
  </ContentLoader>
);

export default MerchantSearchListLoading;
