import React from 'react';
import styled from 'styled-components';

import LinkAccountCheckDuplicatesModal from 'components/accounts/LinkAccountCheckDuplicatesModal';
import LinkAccountSelectDataProviderModal from 'components/accounts/LinkAccountSelectDataProviderModal';
import InstitutionSyncGraphic from 'components/institutions/InstitutionSyncGraphic';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Markdown from 'components/lib/ui/Markdown';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { useStackContext } from 'common/lib/contexts/StackContext';
import { useModalContext } from 'lib/contexts/ModalContext';

import * as COPY from 'common/constants/copy';

import { InstitutionStatus } from 'common/generated/graphql';
import type { LinkInstitutionFieldsFragment } from 'common/generated/graphql';

const Title = styled(Text).attrs({ size: 'large', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
`;

const Subtitle = styled(Text).attrs({ size: 'small' })`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const Container = styled(FlexContainer).attrs({ center: true, column: true })`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.xxxxlarge};
  padding-top: 0;
`;

type Props = {
  institution: LinkInstitutionFieldsFragment;
};

const LinkAccountInstitutionStatusModal = ({ institution }: Props) => {
  const { name, newConnectionsDisabled, linkFlowWarningTitle, linkFlowWarningMessage } =
    institution;

  const { push, pop } = useStackContext();
  const { close } = useModalContext();

  const next = () => push(LinkAccountCheckDuplicatesModal, { institution });

  return (
    <ModalCard onClickBackButton={pop} hideBottomBorder>
      <Container>
        <InstitutionSyncGraphic
          status={
            newConnectionsDisabled
              ? InstitutionStatus.DOWN
              : institution?.status ?? InstitutionStatus.HEALTHY
          }
          institutionLogo={institution?.logo ?? undefined}
        />
        <Title>
          {linkFlowWarningTitle ||
            COPY.ACCOUNTS.SELECT_DATA_PROVIDER.ISSUES_REPORTED_DEFAULT_TITLE(name)}
        </Title>
        <Subtitle>
          <Markdown source={linkFlowWarningMessage ?? ''} />
        </Subtitle>
      </Container>
      <CardFooter>
        <FlexContainer justifyBetween alignCenter full>
          <TextButton onClick={() => push(LinkAccountSelectDataProviderModal, { institution })}>
            Other options
          </TextButton>
          <FlexContainer alignCenter>
            <DefaultButton size="small" onClick={close}>
              Cancel
            </DefaultButton>
            {!newConnectionsDisabled && (
              <PrimaryButton size="small" onClick={next}>
                Continue
              </PrimaryButton>
            )}
          </FlexContainer>
        </FlexContainer>
      </CardFooter>
    </ModalCard>
  );
};

export default LinkAccountInstitutionStatusModal;
