import React, { useMemo } from 'react';
import styled from 'styled-components';

import BooleanField from 'components/advice/BooleanField';
import RankingField from 'components/advice/RankingField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import RadioField from 'components/lib/form/RadioField';
import { textButtonMixin } from 'components/lib/ui/TextButton';
import AsyncButton from 'components/lib/ui/button/AsyncButton';

import useLoading from 'common/lib/hooks/useLoading';

import { gql } from 'common/generated/gql';
import type { QuestionFormFieldsFragment } from 'common/generated/graphql';

const FIELD_NAME = 'value';

enum FieldType {
  Boolean = 'boolean',
  SingleSelect = 'single_select',
  MultiSelect = 'multi_select',
  Ranking = 'ranking',
}

const DEFAULT_VALUE: { [fieldType in FieldType]: any } = {
  [FieldType.Boolean]: undefined,
  [FieldType.SingleSelect]: undefined,
  [FieldType.MultiSelect]: [],
  [FieldType.Ranking]: [],
};

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 440px;
`;

const FieldContainer = styled.div`
  flex: 1;
  padding-top: 36px;
  padding-bottom: 21px;
`;

const SkipButton = styled(AsyncButton)`
  ${textButtonMixin}
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.default};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.base};
`;

const Field = ({ question: { type, options, required, answer } }: Pick<Props, 'question'>) => {
  if (type === FieldType.Boolean) {
    return <BooleanField name={FIELD_NAME} required={required} />;
  } else if (type === FieldType.SingleSelect) {
    return <RadioField hideLabel name={FIELD_NAME} options={options ?? []} required={required} />;
  } else if (type === FieldType.MultiSelect) {
    return (
      <RadioField
        hideLabel
        name={FIELD_NAME}
        options={options ?? []}
        required={required}
        allowMultiple
        isNoneSelectedInitial={answer?.answer === '[]' && !!answer.answeredAt && !answer?.dontKnow}
      />
    );
  } else if (type === FieldType.Ranking) {
    return <RankingField name={FIELD_NAME} options={options ?? []} />;
  } else {
    return null;
  }
};

type Props = {
  question: QuestionFormFieldsFragment;
  onSubmit: (value: string) => Promise<any>;
  onClickSkip: () => Promise<any>;
  allowSkip: boolean;
  nextButtonText: string;
};

const QuestionForm = ({ question, onSubmit, onClickSkip, allowSkip, nextButtonText }: Props) => {
  const { name, answer } = question;
  const { answer: answerJson } = answer ?? {};

  const initialValues = useMemo(
    () => ({
      [FIELD_NAME]: answerJson ? JSON.parse(answerJson) : DEFAULT_VALUE[question.type as FieldType],
    }),
    [answerJson, question],
  );

  const [isLoadingSkip, skip] = useLoading(onClickSkip);

  return (
    <StyledForm
      initialValues={initialValues}
      onSubmit={(values: any) => onSubmit(JSON.stringify(values[FIELD_NAME]))}
      key={name}
    >
      <FieldContainer>
        <Field question={question} />
      </FieldContainer>
      <FormSubmitButton size="large" disabled={isLoadingSkip} disableWhenValuesUnchanged={false}>
        {nextButtonText}
      </FormSubmitButton>
      {allowSkip && (
        <SkipButton onClick={skip} pending={isLoadingSkip}>
          I don&apos;t know
        </SkipButton>
      )}
    </StyledForm>
  );
};

QuestionForm.fragments = {
  QuestionFormFields: gql(`
    fragment QuestionFormFields on ProfileQuestion {
      name
      type
      required
      options {
        value
        label
      }
      answer {
        answer
        dontKnow
        answeredAt
      }
    }
  `),
};

export default QuestionForm;
