import { useMutation, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { getOptimisticItemOrderForMove } from 'common/lib/dnd/DragAndDrop';

import { CACHE_KEYS } from 'common/constants/cache';

import { gql } from 'common/generated/gql';
import { Tuple } from 'common/types';

const useUpdateCategoryGroupOrderMutation = (options?: any) => {
  const client = useApolloClient();
  const [performMutation, mutationResult] = useMutation(UPDATE_CATEGORY_GROUP_ORDER, {
    update: (cache) => {
      cache.evict({ fieldName: CACHE_KEYS.CATEGORIES });
      cache.evict({ fieldName: CACHE_KEYS.CATEGORY_GROUPS });
    },
    ...options,
  });

  const updateCategoryGroupOrder = useCallback(
    async (destination: any, source: any) => {
      const optimisticOrder = getOptimisticItemOrderForMove(source.order, destination.order);
      client.writeFragment({
        id: `CategoryGroup:${destination.id}`,
        fragment: gql(`
          fragment BudgetGroup on CategoryGroup {
            order
          }
        `),
        data: {
          __typename: 'CategoryGroup',
          order: optimisticOrder,
        },
      });

      const response = await performMutation({ variables: destination });
      return response;
    },
    [performMutation, client],
  );

  return Tuple(updateCategoryGroupOrder, mutationResult);
};

const UPDATE_CATEGORY_GROUP_ORDER = gql(`
  mutation Web_UpdateCategoryGroupOrder($id: UUID!, $order: Int!) {
    updateCategoryGroupOrder(id: $id, order: $order) {
      categoryGroups {
        id
      }
    }
  }
`);

export default useUpdateCategoryGroupOrderMutation;
