import React from 'react';

import type { MultipleLineChartProps } from 'components/lib/charts/MultipleLineChart';
import MultipleLineChart from 'components/lib/charts/MultipleLineChart';

type Props<XAxisT, YAxisT, DataT extends Record<string, unknown>> = Omit<
  MultipleLineChartProps<XAxisT, YAxisT, DataT>,
  'yDataKeys' | 'lineOptions'
> & {
  yDataKey: YAxisT;
  lineColor: string;
};

const SimpleLineChart = <
  XAxisT extends string,
  YAxisT extends string,
  DataT extends { [x in XAxisT]: string | number } & { [y in YAxisT]: number },
>({
  yDataKey,
  lineColor,
  ...props
}: Props<XAxisT, YAxisT, DataT>) => (
  <MultipleLineChart
    yDataKeys={[yDataKey]}
    lineOptions={[{ lineColor, hasArea: true }]}
    {...props}
  />
);

export default SimpleLineChart;
