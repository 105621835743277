import { gql } from '@apollo/client';
import * as R from 'ramda';
import type { SyntheticEvent } from 'react';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import ZillowPropertySearchResultListItem from 'components/accounts/ZillowPropertySearchResultListItem';
import TextInput from 'components/lib/form/TextInput';
import ModalCard from 'components/lib/ui/ModalCard';
import SectionHeader from 'components/lib/ui/SectionHeader';
import Text from 'components/lib/ui/Text';

import useDebounce from 'common/lib/hooks/useDebounce';
import useQuery from 'common/lib/hooks/useQuery';
import typewriter from 'lib/analytics/typewriter';

import { TYPEAHEAD_DEBOUNCE_TIME_MS } from 'common/constants/form';

import type {
  Web_GetZestimate,
  Web_GetZestimateVariables,
} from 'common/generated/graphQlTypes/Web_GetZestimate';

type Props = {
  onItemSelect: ({
    addressStreet,
    zestimate,
    zpid,
  }: {
    addressStreet: string;
    zestimate: number;
    zpid: string;
  }) => void;
};

const AddressInputContainer = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.xlarge}
    ${({ theme }) => theme.spacing.xlarge};
`;

const AddressInput = styled(TextInput)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  width: 100%;
`;

const ZillowLogo = styled.img`
  width: 92px;
  height: 40px;
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

const NoResultsMessagingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const NoResultsMessagingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
`;

const NoResultsMessagingBodyText = styled.span`
  margin-top: 8px;
`;
const SizingContainer = styled.div`
  height: 653px;
  display: flex;
  flex-direction: column;
`;

export const ZillowPropertySearchModalCard = ({
  children,
  onClickBackButton,
}: {
  children: React.ReactNode;
  onClickBackButton: (() => void) | undefined;
}) => (
  <ModalCard
    title="Search for your property"
    hideBottomBorder
    onClickBackButton={onClickBackButton}
  >
    {children}
  </ModalCard>
);

const ZillowPropertySearchModal = ({ onItemSelect }: Props) => {
  const [searchText, setSearchText] = useState('');
  const [addressSearchFired, setAddressSearchFired] = useState(false);
  const debouncedSearchText = useDebounce(searchText, TYPEAHEAD_DEBOUNCE_TIME_MS);
  const { data } = useQuery<Web_GetZestimate, Web_GetZestimateVariables>(QUERY, {
    variables: { address: debouncedSearchText },
  });
  const searchResults = data?.zestimates ?? [];

  useEffect(() => {
    typewriter.zillowConnectOpen();
  }, []);

  return (
    <SizingContainer>
      <AddressInputContainer>
        <Text weight="medium" size="xsmall">
          Address
        </Text>
        <AddressInput
          autoFocus
          name="address"
          placeholder="Search by address..."
          value={searchText}
          onChange={(e: SyntheticEvent<HTMLInputElement>) => {
            const target = e.target as HTMLInputElement;
            setSearchText(target.value);

            if (!addressSearchFired) {
              typewriter.zillowConnectSearch();
              setAddressSearchFired(true);
            }
          }}
        />
      </AddressInputContainer>
      <Switch>
        <Case when={R.isEmpty(searchResults) && R.isEmpty(debouncedSearchText)}>
          <NoResultsMessagingContainer>
            <NoResultsMessagingTextContainer>
              <ZillowLogo src={require('static/images/zillow-logo.svg')} />
              <Text weight="medium">Search for your property address</Text>
              <NoResultsMessagingBodyText>
                We&apos;ll look up the Zestimate for the address you provide and sync it
                automatically every month.
              </NoResultsMessagingBodyText>
            </NoResultsMessagingTextContainer>
          </NoResultsMessagingContainer>
        </Case>
        <Case when={R.isEmpty(searchResults)}>
          <SectionHeader>
            <span>Address</span>
            <span>Zestimate</span>
          </SectionHeader>
          <NoResultsMessagingContainer>
            <NoResultsMessagingTextContainer>
              <Text weight="medium">{`No results found for "${searchText}"`}</Text>
            </NoResultsMessagingTextContainer>
          </NoResultsMessagingContainer>
        </Case>
        <Case default>
          <SectionHeader>
            <span>Address</span>
            <span>Zestimate</span>
          </SectionHeader>
          {searchResults.map(
            ({
              zpid,
              addressStreet,
              addressCity,
              addressPostalCode,
              addressStateAbbr,
              zestimate,
            }) => (
              <ZillowPropertySearchResultListItem
                key={zpid}
                zpid={zpid}
                zestimate={zestimate}
                addressStreet={addressStreet}
                addressCity={addressCity}
                addressPostalCode={addressPostalCode}
                addressState={addressStateAbbr}
                onClick={({ zpid, addressStreet, zestimate }) => {
                  typewriter.zillowConnectResultSelected();
                  onItemSelect({ addressStreet, zpid, zestimate });
                }}
              />
            ),
          )}
        </Case>
      </Switch>
    </SizingContainer>
  );
};

export default ZillowPropertySearchModal;

const QUERY = gql`
  query Web_GetZestimate($address: String!) {
    zestimates(address: $address) {
      zpid
      addressStreet
      addressCity
      addressStateAbbr
      addressPostalCode
      zestimate
    }
  }
`;
