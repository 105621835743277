import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { GroupHorizontalContainer } from 'components/lib/form/FieldCell';
import FieldCellWithToggle from 'components/lib/form/FieldCellWithToggle';
import Form from 'components/lib/form/Form';
import FeatureFlagGate from 'components/lib/higherOrder/FeatureFlagGate';
import SettingsCard from 'components/lib/layouts/SettingsCard';
import Badge from 'components/lib/ui/Badge';
import Banner from 'components/lib/ui/Banner';
import Column from 'components/lib/ui/Column';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Link from 'components/lib/ui/Link';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';

import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import { getUserEmail, getUserId } from 'common/state/user/selectors';

import { TRANSACTIONS, ASSISTANT } from 'common/constants/copy';
import {
  HELP_CENTER_AI_ASSISTANT,
  INVESTMENT_TRANSACTIONS_SURVEY_URL,
} from 'common/constants/externalUrls';

const HELP_CIRCLE_ICON_SIZE_PX = 16;

const StyledFieldCellWithToggle = styled(FieldCellWithToggle)`
  padding: ${({ theme }) => theme.spacing.xlarge};
  border: none;
  border-radius: 0;

  .title {
    font-size: ${({ theme }) => theme.fontSize.base};
  }

  ${GroupHorizontalContainer} {
    align-items: flex-start;
  }
`;

const StyledSettingsCard = styled(SettingsCard)`
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  padding: ${({ theme }) => theme.spacing.xxlarge};
`;

const StyledBanner = styled(Banner)`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

const TransactionsSettings = () => {
  const { householdPreferences, isLoadingInitialData, updateHouseholdPreferences } =
    useHouseholdPreferences();
  const email = useSelector(getUserEmail);
  const userId = useSelector(getUserId) || '';

  const investmentTransactionSurveyFormUrl = useCallback(
    () => INVESTMENT_TRANSACTIONS_SURVEY_URL(email, userId),
    [email, userId],
  );

  return (
    <Column md={9}>
      <Form
        initialValues={{
          pendingTransactionsCanBeEdited: householdPreferences?.pendingTransactionsCanBeEdited,
          newTransactionsNeedReview: householdPreferences?.newTransactionsNeedReview,
          uncategorizedTransactionsNeedReview:
            householdPreferences?.uncategorizedTransactionsNeedReview,
          aiAssistantEnabled: householdPreferences?.aiAssistantEnabled,
          llmEnrichmentEnabled: householdPreferences?.llmEnrichmentEnabled,
          investmentTransactionsEnabled: householdPreferences?.investmentTransactionsEnabled,
        }}
        onSubmit={async (values) => {
          await updateHouseholdPreferences(values);
        }}
        enableReinitialize
        submitOnChange
        preventSubmitWhileSubmitting
      >
        {isLoadingInitialData ? (
          <StyledSettingsCard>
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          </StyledSettingsCard>
        ) : (
          <>
            <StyledSettingsCard title={TRANSACTIONS.SETTINGS.SECTION_HEADER}>
              <StyledFieldCellWithToggle
                fieldCellProps={{
                  title: TRANSACTIONS.SETTINGS.NEW_TRANSACTIONS_NEED_REVIEW.TITLE,
                  subtitle: TRANSACTIONS.SETTINGS.NEW_TRANSACTIONS_NEED_REVIEW.SUBTITLE,
                }}
                toggleFieldProps={{ name: 'newTransactionsNeedReview' }}
              />
              <StyledFieldCellWithToggle
                fieldCellProps={{
                  title: TRANSACTIONS.SETTINGS.UNCATEGORIZED_NEED_REVIEW.TITLE,
                  subtitle: TRANSACTIONS.SETTINGS.UNCATEGORIZED_NEED_REVIEW.SUBTITLE,
                }}
                toggleFieldProps={{ name: 'uncategorizedTransactionsNeedReview' }}
              />
              <StyledFieldCellWithToggle
                fieldCellProps={{
                  title: TRANSACTIONS.SETTINGS.PENDING.TITLE,
                  subtitle: (
                    <FlexContainer marginRight="xxxlarge" column>
                      <Text>{TRANSACTIONS.ALLOW_EDITS_TO_PENDING_SUBTITLE}</Text>
                      <StyledBanner type="info">
                        <FlexContainer>
                          <FlexContainer marginRight="small" marginTop="xxsmall">
                            <Icon name="help-circle" size={HELP_CIRCLE_ICON_SIZE_PX} />
                          </FlexContainer>
                          {TRANSACTIONS.ALLOW_EDITS_TO_PENDING_DISCLAIMER}
                        </FlexContainer>
                      </StyledBanner>
                    </FlexContainer>
                  ),
                }}
                toggleFieldProps={{ name: 'pendingTransactionsCanBeEdited' }}
              />
            </StyledSettingsCard>
            <StyledSettingsCard title="Monarch Labs">
              {householdPreferences?.aiAssistantEnabled && (
                <StyledFieldCellWithToggle
                  fieldCellProps={{
                    title: (
                      <FlexContainer alignCenter>
                        <Text>AI Assistant</Text>
                        <Badge color="blue">Beta</Badge>
                      </FlexContainer>
                    ),
                    subtitle: (
                      <Text>
                        {ASSISTANT.SETTINGS_TOGGLE.SUBTITLE}{' '}
                        <Link href={HELP_CENTER_AI_ASSISTANT} target="_blank">
                          Learn more.
                        </Link>
                      </Text>
                    ),
                  }}
                  toggleFieldProps={{ name: 'aiAssistantEnabled' }}
                />
              )}
              <FeatureFlagGate name="new-enrichment-pipeline">
                <StyledFieldCellWithToggle
                  fieldCellProps={{
                    title: (
                      <FlexContainer alignCenter>
                        <Text>LLM Enrichment</Text>
                        <Badge color="blue">Beta</Badge>
                      </FlexContainer>
                    ),
                    subtitle: (
                      <Text>
                        When turned on, Monarch will be authorized to send your transactions to
                        external LLM providers for enrichment. Transactions enriched by LLMs might
                        contain incorrect information and should be manually reviewed.
                      </Text>
                    ),
                  }}
                  toggleFieldProps={{ name: 'llmEnrichmentEnabled' }}
                />
              </FeatureFlagGate>
              <FeatureFlagGate name="investment-transactions-enabled">
                <StyledFieldCellWithToggle
                  fieldCellProps={{
                    title: (
                      <FlexContainer alignCenter>
                        <Text>{TRANSACTIONS.SETTINGS.INVESTMENT_TRANSACTIONS_ENABLED.TITLE}</Text>
                        <Badge color="blue">Beta</Badge>
                      </FlexContainer>
                    ),
                    subtitle: (
                      <>
                        <Text>
                          {TRANSACTIONS.SETTINGS.INVESTMENT_TRANSACTIONS_ENABLED.SUBTITLE}
                        </Text>
                        <br />
                        <br />
                        <Text>
                          To submit feedback about this feature, please fill out&nbsp;
                          <Link href={investmentTransactionSurveyFormUrl()} target="_blank">
                            this form
                          </Link>
                          .
                        </Text>
                      </>
                    ),
                  }}
                  toggleFieldProps={{ name: 'investmentTransactionsEnabled' }}
                />
              </FeatureFlagGate>
            </StyledSettingsCard>
          </>
        )}
      </Form>
    </Column>
  );
};

export default TransactionsSettings;
