import React from 'react';
import styled from 'styled-components';

import Empty from 'components/lib/ui/Empty';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

import { useContactSupportContext } from 'lib/contexts/ContactSupportContext';

import * as COPY from 'common/constants/copy';

import type { PayloadErrorFieldsFragment } from 'common/generated/graphql';
import { ContactSupportReason, ContactSupportTopic } from 'common/generated/graphql';

const NO_ACCOUNTS_ERROR_MESSAGE = `Credential didn't have any new accounts.`;

const ErrorIcon = styled(Icon)`
  width: 70px;
  height: 70px;
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  color: ${({ theme }) => theme.color.red};
`;

type Props = {
  error: PayloadErrorFieldsFragment;
};

const LinkAccountError = ({ error }: Props) => {
  const { openContactSupportModal } = useContactSupportContext();

  const errorMessage =
    error.message === NO_ACCOUNTS_ERROR_MESSAGE
      ? COPY.ACCOUNTS.NO_ACCOUNTS_ERROR
      : COPY.ACCOUNTS.DEFAULT_LINK_ERROR;

  return (
    <FlexContainer column center>
      <ErrorIcon name="alert-circle" />
      <Empty
        title="Something went wrong"
        subtitle={errorMessage}
        button={{
          title: 'Contact support',
          onClick: () => {
            openContactSupportModal({
              topic: ContactSupportTopic.CONNECTION_ISSUE,
              reason: ContactSupportReason.INITIAL_CONNECTION_FAILURE,
            });
          },
        }}
      />
    </FlexContainer>
  );
};

export default LinkAccountError;
