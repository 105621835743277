import React from 'react';
import styled from 'styled-components';

import RouteLink from 'components/lib/ui/link/RouteLink';

import routes from 'constants/routes';

type Props = {
  children: React.ReactNode;
  className?: string;
  id: string;
};

const Root = styled(RouteLink)``;

export const AccountLinkText = ({ children, className, id }: Props) => (
  <Root stealthy to={routes.accounts.accountDetails({ id })} className={className}>
    {children}
  </Root>
);
