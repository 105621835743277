import { useApolloClient } from '@apollo/client';
import type { DateTime } from 'luxon';
import { useCallback } from 'react';

import {
  getOptimisticMonthlyAmounts,
  getOptimisticTotalsAmounts,
  getOptimisticTotalsAmountsForFlexibleBudget,
} from 'common/lib/budget/OptimisticAdapter';
import {
  BUDGET_CATEGORY_FIELDS_FRAGMENT,
  BUDGET_CATEGORY_GROUP_FIELDS_FRAGMENT,
  BUDGET_MONTHLY_AMOUNTS_BY_CATEGORY_FIELDS_FRAGMENT,
  BUDGET_MONTHLY_AMOUNTS_BY_CATEGORY_GROUP_FIELDS_FRAGMENT,
  BUDGET_MONTHLY_AMOUNTS_FOR_FLEX_EXPENSE_FIELDS_FRAGMENT,
  BUDGET_TOTALS_BY_MONTH_FIELDS_FRAGMENT,
} from 'common/lib/graphQl/budgets';

import { BudgetVariability } from 'common/generated/graphql';

type OptimisticUpdateOptions = {
  startDate: DateTime;
  id: string;
  amount: number | undefined;
  isGroup?: boolean;
  applyToFuture?: boolean;
};

type Options = {
  householdUsesFixedFlexBudgeting?: boolean;
};

export const useOptimisticBudgetUpdates = (options: Options = {}) => {
  const client = useApolloClient();
  const { householdUsesFixedFlexBudgeting } = options;

  const optimisticUpdateCacheForCategoryOrGroup = useCallback(
    ({ startDate, id, amount, isGroup, applyToFuture }: OptimisticUpdateOptions) => {
      const reference = isGroup ? createCategoryGroupReference(id) : createCategoryReference(id);
      const cacheId = client.cache.identify(reference);
      const fragment = isGroup
        ? BUDGET_MONTHLY_AMOUNTS_BY_CATEGORY_GROUP_FIELDS_FRAGMENT
        : BUDGET_MONTHLY_AMOUNTS_BY_CATEGORY_FIELDS_FRAGMENT;
      const fragmentName = isGroup
        ? 'BudgetMonthlyAmountsByCategoryGroupFields'
        : 'BudgetMonthlyAmountsByCategoryFields';

      const cachedCategoryOrGroup = client.readFragment({
        id: client.cache.identify({ id, __typename: isGroup ? 'CategoryGroup' : 'Category' }),
        fragment: isGroup ? BUDGET_CATEGORY_GROUP_FIELDS_FRAGMENT : BUDGET_CATEGORY_FIELDS_FRAGMENT,
        fragmentName: isGroup ? 'BudgetCategoryGroupFields' : 'BudgetCategoryFields',
      });

      const shouldIgnoreOptimisticUpdate =
        cachedCategoryOrGroup?.excludeFromBudget ||
        (householdUsesFixedFlexBudgeting &&
          !isGroup &&
          cachedCategoryOrGroup?.budgetVariability === BudgetVariability.FLEXIBLE);

      const data = client.readFragment({
        id: cacheId,
        fragment,
        fragmentName,
      });

      if (data) {
        const writeData = getOptimisticMonthlyAmounts<typeof data>(
          data,
          amount,
          startDate,
          applyToFuture,
        );
        client.writeFragment({
          id: cacheId,
          fragment,
          data: writeData,
          fragmentName,
        });

        if (shouldIgnoreOptimisticUpdate) {
          return;
        }

        // Update totals so the summary widget is also updated
        const cachedTotals = client.readFragment({
          id: client.cache.identify({
            __typename: 'BudgetMonthTotals',
            month: startDate.toISODate(),
          }),
          fragment: BUDGET_TOTALS_BY_MONTH_FIELDS_FRAGMENT,
          fragmentName: 'BudgetTotalsByMonthFields',
        });

        if (cachedCategoryOrGroup && cachedTotals) {
          const writeData = getOptimisticTotalsAmounts(
            cachedCategoryOrGroup,
            cachedTotals,
            data,
            amount,
            startDate.toISODate(),
          );

          client.writeFragment({
            id: client.cache.identify({
              __typename: 'BudgetMonthTotals',
              month: startDate.toISODate(),
            }),
            fragment: BUDGET_TOTALS_BY_MONTH_FIELDS_FRAGMENT,
            data: writeData,
            fragmentName: 'BudgetTotalsByMonthFields',
          });
        }
      }
    },
    [client],
  );

  const optimisticUpdateCacheForFlexBudget = useCallback(
    ({ startDate, amount }: Pick<OptimisticUpdateOptions, 'startDate' | 'amount'>) => {
      const cacheId = client.cache.identify({
        __typename: 'BudgetFlexMonthlyAmounts',
        budgetVariability: 'flexible',
      });

      const data = client.readFragment({
        id: cacheId,
        fragment: BUDGET_MONTHLY_AMOUNTS_FOR_FLEX_EXPENSE_FIELDS_FRAGMENT,
        fragmentName: 'BudgetMonthlyAmountsForFlexExpenseFields',
      });

      if (data) {
        const writeData = getOptimisticMonthlyAmounts<typeof data>(data, amount, startDate);
        client.writeFragment({
          id: cacheId,
          fragment: BUDGET_MONTHLY_AMOUNTS_FOR_FLEX_EXPENSE_FIELDS_FRAGMENT,
          data: writeData,
          fragmentName: 'BudgetMonthlyAmountsForFlexExpenseFields',
        });
      }

      const cachedTotals = client.readFragment({
        id: client.cache.identify({
          __typename: 'BudgetMonthTotals',
          month: startDate.toISODate(),
        }),
        fragment: BUDGET_TOTALS_BY_MONTH_FIELDS_FRAGMENT,
        fragmentName: 'BudgetTotalsByMonthFields',
      });

      if (cachedTotals && data && amount) {
        const writeData = getOptimisticTotalsAmountsForFlexibleBudget(
          cachedTotals,
          data,
          amount,
          startDate.toISODate(),
        );

        client.writeFragment({
          id: client.cache.identify({
            __typename: 'BudgetMonthTotals',
            month: startDate.toISODate(),
          }),
          fragment: BUDGET_TOTALS_BY_MONTH_FIELDS_FRAGMENT,
          data: writeData,
          fragmentName: 'BudgetTotalsByMonthFields',
        });
      }
    },
    [client],
  );

  return { optimisticUpdateCacheForCategoryOrGroup, optimisticUpdateCacheForFlexBudget };
};

const createCategoryReference = (id: string) => ({
  __typename: 'BudgetCategoryMonthlyAmounts',
  category: {
    id,
    __typename: 'Category',
  },
});

const createCategoryGroupReference = (id: string) => ({
  __typename: 'BudgetCategoryGroupMonthlyAmounts',
  categoryGroup: {
    id,
    __typename: 'CategoryGroup',
  },
});
