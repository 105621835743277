import React from 'react';
import styled, { css } from 'styled-components';

import GoalBalancesCard from 'components/goalsV2/GoalBalancesCard';
import GoalCard from 'components/goalsV2/GoalCard';
import GoalContributionsCard from 'components/goalsV2/GoalContributionsCard';
import GoalDetailsCard from 'components/goalsV2/GoalDetailsCard';
import GoalImage from 'components/goalsV2/GoalImage';
import GoalProgressCard from 'components/goalsV2/GoalProgressCard';
import GoalTransactionsCard from 'components/goalsV2/GoalTransactionsCard';
import Grid, { GridItem } from 'components/lib/ui/Grid';
import Page from 'components/lib/ui/Page';
import GoalDetailControls from 'components/routes/goalsV2/GoalDetailControls';

import { goalOnlyHasInvestmentAccounts } from 'common/lib/goalsV2/adapters';
import useQuery from 'common/lib/hooks/useQuery';
import useReadFragment from 'common/lib/hooks/useReadFragment';
import isV2Theme from 'common/lib/theme/isV2Theme';

import type routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type { WebGoalCardFields } from 'common/generated/graphQlTypes/WebGoalCardFields';
import type RouteProps from 'types/RouteProps';

const SmallGoalImage = styled(GoalImage).attrs({ showGradient: false, size: 'small' })`
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => theme.radius.small};
  position: relative;
`;

const LargeGoalProgressCard = styled(GoalProgressCard)`
  height: 312px;
`;

const ColumnGridItem = styled(GridItem)`
  > *:not(:last-child) {
    margin-bottom: var(--spacing);
  }
`;

const StyledGrid = styled(Grid)`
  ${isV2Theme(css`
    padding-top: 0;
  `)}
`;

const GoalDetailsV2 = ({
  match: {
    params: { id: goalId },
  },
}: RouteProps<typeof routes.goalsV2.goalDetail>) => {
  // we may already have part of this goal in the cache from the goals screen, so we can
  // at least display some information while loading the rest
  const goalFromCache = useReadFragment<WebGoalCardFields>({
    id: `GoalV2:${goalId}`,
    fragment: GoalCard.fragments.WebGoalCardFields,
    fragmentName: 'WebGoalCardFields',
  });

  const { data, isLoadingInitialData } = useQuery(QUERY, {
    variables: {
      goalId,
    },
  });

  const { goalV2: goal } = data ?? {};
  const { imageStorageProvider, imageStorageProviderId } = goal ?? goalFromCache ?? {};

  const partialGoal = goal ?? goalFromCache;

  const onlyHasInvestmentAccounts = goal ? goalOnlyHasInvestmentAccounts(goal) : false;
  const hideContributions = onlyHasInvestmentAccounts;

  return (
    <Page
      name="Goal Details"
      overrideTitle={partialGoal?.name}
      icon={
        <SmallGoalImage
          imageStorageProvider={imageStorageProvider}
          imageStorageProviderId={imageStorageProviderId}
        />
      }
      controls={partialGoal ? <GoalDetailControls goal={partialGoal} /> : undefined}
    >
      <StyledGrid
        template={`"image image" "list summary" / 1fr 30%`}
        md={`"image" "summary" "list"`}
      >
        <GridItem area="image">
          {partialGoal && <LargeGoalProgressCard goal={partialGoal} />}
        </GridItem>
        <ColumnGridItem area="list">
          {hideContributions ? (
            <GoalBalancesCard large goal={goal} isLoading={isLoadingInitialData} />
          ) : (
            <>
              <GoalContributionsCard goal={goal} isLoading={isLoadingInitialData} />
              <GoalTransactionsCard goalId={goalId} goalName={partialGoal?.name} />
            </>
          )}
        </ColumnGridItem>
        <ColumnGridItem area="summary">
          {!hideContributions && <GoalBalancesCard goal={goal} isLoading={isLoadingInitialData} />}
          <GoalDetailsCard goal={goal} isLoading={isLoadingInitialData} />
        </ColumnGridItem>
      </StyledGrid>
    </Page>
  );
};

const QUERY = gql(/* GraphQL */ `
  query Web_GoalDetailV2($goalId: ID!) {
    goalV2(id: $goalId) {
      id
      imageStorageProvider
      imageStorageProviderId
      archivedAt
      ...WebGoalDetailsCardFields
      ...WebGoalBalancesCardFields
      ...WebGoalCardFields
      ...WebGoalContributionGraphFields
    }
  }
`);

export default GoalDetailsV2;
