import * as R from 'ramda';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import RouteModal from 'components/lib/routing/RouteModal';
import Card from 'components/lib/ui/Card';
import Column from 'components/lib/ui/Column';
import EmptyStateCard from 'components/lib/ui/EmptyStateCard';
import PageWithNoAccountsEmptyState from 'components/lib/ui/PageWithNoAccountsEmptyState';
import Row from 'components/lib/ui/Row';
import StickyScroll from 'components/lib/ui/StickyScroll';
import CollapsibleLayout from 'components/lib/ui/layout/CollapsibleLayout';
import AddTransactionModal from 'components/transactions/AddTransactionModal';
import NeedsReviewEmpty from 'components/transactions/NeedsReviewEmpty';
import TransactionsDownloadInvisibleButton from 'components/transactions/TransactionsDownloadInvisibleButton';
import TransactionsFilterCard from 'components/transactions/TransactionsFilterCard';
import TransactionsHeaderControls from 'components/transactions/TransactionsHeaderControls';
import TransactionsListContainer, {
  GET_TRANSACTION_LIST,
} from 'components/transactions/TransactionsListContainer';
import TransactionsListLoading from 'components/transactions/TransactionsListLoading';
import TransactionsSummaryCard from 'components/transactions/TransactionsSummaryCard';

import { setTransactionsSummaryColumnVisible } from 'actions';
import useLoading from 'common/lib/hooks/useLoading';
import useProfileFlag from 'common/lib/hooks/users/useProfileFlag';
import { track } from 'lib/analytics/segment';
import HighlightTextContext from 'lib/contexts/HighlightTextContext';
import refetchQueries from 'lib/graphQl/refetchQueries';
import useDownloadTransactions from 'lib/hooks/statements/useDownloadTransactions';
import useFilters from 'lib/hooks/transactions/useFilters';
import useDispatch from 'lib/hooks/useDispatch';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useMockDataWhenNoAccountsQuery from 'lib/hooks/useMockDataWhenNoAccountsQuery';
import { convertFiltersToInput, DEFAULT_FILTERS } from 'lib/transactions/Filters';
import { getIsTransactionsSummaryColumnVisible } from 'selectors';
import { downloadTransactions } from 'state/transactions/thunks';

import { DOWNLOAD_TRANSACTIONS_CSV_BUTTON_CLICKED } from 'common/constants/analytics';
import { ONBOARDING_TRANSACTIONS_OVERLAY_TITLE } from 'common/constants/copy';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type { TransactionOrdering } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

const LazyLoadedTransactionsUpdateWalkthrough = React.lazy(
  () => import('components/transactions/list/TransactionsUpdateWalkthrough'),
);

const TRANSACTIONS_PAGE_SIZE = 25;
const SUMMARY_COLUMN_WIDTH_PX = 320;

const Transactions = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isNewDownloadTransactionsOn = useIsFeatureFlagOn('new-download-transactions');
  const isTransactionsUiUpdatesEnabled = useIsFeatureFlagOn('transactions-ui-v2-web', {
    sendToMsClarity: true,
  });
  const isTransactionsUiUpdatesWalkthroughEnabled = useIsFeatureFlagOn(
    'transactions-ui-updates-walkthrough',
    {
      sendToMsClarity: true,
    },
  );
  const [hasSeenTransactionsUpdatesTour, updateTransactionsUpdateTourFlag] = useProfileFlag(
    'dismissedTransactionsListUpdatesTourAt',
  );

  const isSummaryColumnVisible = useSelector(getIsTransactionsSummaryColumnVisible);
  const toggleSummaryColumnVisibility = useCallback(() => {
    dispatch(setTransactionsSummaryColumnVisible(!isSummaryColumnVisible));
  }, [isSummaryColumnVisible, dispatch]);

  const [filters, setFilters] = useFilters({
    initialFilters: DEFAULT_FILTERS,
    includeFalsyValues: isTransactionsUiUpdatesEnabled,
  });

  const patchFilters = useCallback(
    (newFilters: Partial<TransactionFilters>, override?: boolean) => {
      if (override) {
        setFilters(newFilters);
      } else {
        setFilters({ ...filters, ...newFilters });
      }
    },
    [filters, setFilters],
  );

  const queryParams = R.reject(R.isEmpty, filters);

  const isAddingTransaction = useRouteMatch(routes.transactions.addTransaction);

  const match = useRouteMatch<{ id: string }>('/transactions/:id');
  const drawerTransactionId = (!isAddingTransaction && match?.params.id) || null;

  const setDrawerTransactionId = (id: GraphQlUUID | null) =>
    id && history.push(routes.transactions.details({ id, queryParams }));

  const onTransactionDrawerClose = () => history.push(routes.transactions({ queryParams }));

  const filtersInput = useMemo(() => convertFiltersToInput(filters), [filters]);

  const {
    currentSession,
    downloadTransactions: newDownloadTransactions,
    isLoading: isLoadingDownloadTransactions,
  } = useDownloadTransactions({
    filters,
  });

  const [isDownloadingCsv, onClickDownloadCsv] = useLoading(() => {
    track(DOWNLOAD_TRANSACTIONS_CSV_BUTTON_CLICKED);
    return dispatch(downloadTransactions(filtersInput));
  });

  const { data, isLoadingInitialData, refetch } = useMockDataWhenNoAccountsQuery(GET_SUMMARY, {
    variables: {
      filters: filtersInput,
    },
  });

  const emptyState = useMemo(
    () => (
      <EmptyStateCard
        iconName="credit-card"
        title={R.equals(filters, DEFAULT_FILTERS) ? 'No transactions yet' : 'No transactions found'}
      >
        {(() => {
          // IIFE avoids nested ternary
          if (R.equals(filters, DEFAULT_FILTERS)) {
            return 'Connect an account to see transactions here.';
          } else if (filters.search) {
            return `We couldn't find any transactions matching your search of "${filters.search}".`;
          }

          return `We couldn't find any transactions matching your filters.`;
        })()}
      </EmptyStateCard>
    ),
    [filters],
  );

  return (
    <PageWithNoAccountsEmptyState
      name="Transactions"
      emptyIcon="credit-card"
      emptyTitle={ONBOARDING_TRANSACTIONS_OVERLAY_TITLE}
      controls={
        <TransactionsHeaderControls
          filters={filters}
          onChangeFilters={patchFilters}
          toggleIsCollapsed={toggleSummaryColumnVisibility}
          isSummaryColumnVisible={isSummaryColumnVisible}
        />
      }
      onAccountAdded={() => {
        refetch();
        refetchQueries([GET_TRANSACTION_LIST]);
      }}
    >
      <ConditionalCollapsibleLayout
        isSummaryColumnVisible={isSummaryColumnVisible}
        enabled={isTransactionsUiUpdatesEnabled}
      >
        {isLoadingInitialData ? (
          <TransactionsListLoading />
        ) : (
          <HighlightTextContext.Provider
            value={{
              searchWords: filters.search ? [filters.search] : [],
            }}
          >
            <Card>
              <TransactionsListContainer
                transactionFilters={filtersInput}
                pageSize={TRANSACTIONS_PAGE_SIZE}
                isReviewModeActive={filters.needsReview}
                sortBy={filters.order as Maybe<TransactionOrdering>}
                onChangeSortBy={(sortBy) => patchFilters({ order: sortBy })}
                overrideDrawer={{
                  setDrawerTransactionId,
                  drawerTransactionId,
                  onTransactionDrawerClose,
                }}
                emptyComponent={
                  filters.needsReview ? (
                    <NeedsReviewEmpty onClickViewAll={() => setFilters({})} />
                  ) : (
                    emptyState
                  )
                }
                allowBulkUpdate
              />
            </Card>
          </HighlightTextContext.Provider>
        )}
        <div>
          {!isTransactionsUiUpdatesEnabled && (
            <TransactionsFilterCard filters={filters} onChangeFilters={setFilters} />
          )}
          {isNewDownloadTransactionsOn ? (
            <TransactionsSummaryCard
              isLoading={isLoadingInitialData}
              transactionsSummary={data?.aggregates[0]?.summary}
              onClickDownloadCsv={newDownloadTransactions}
              isDownloadingCsv={isLoadingDownloadTransactions}
              filters={filters}
              onChangeFilters={setFilters}
              errorMessage={currentSession?.errorMessage ?? undefined}
              empty={emptyState}
            />
          ) : (
            <TransactionsSummaryCard
              transactionsSummary={data?.aggregates[0]?.summary}
              onClickDownloadCsv={onClickDownloadCsv}
              isDownloadingCsv={isDownloadingCsv}
              filters={filters}
              onChangeFilters={setFilters}
              empty={emptyState}
            />
          )}
          <TransactionsDownloadInvisibleButton downloadUrl={currentSession?.url ?? undefined} />
        </div>
      </ConditionalCollapsibleLayout>

      <RouteModal path={routes.transactions.addTransaction.path}>
        {({ close }) => (
          <AddTransactionModal
            onDone={() => {
              close();
              refetch();
            }}
            onCancel={close}
          />
        )}
      </RouteModal>

      {isTransactionsUiUpdatesEnabled &&
        isTransactionsUiUpdatesWalkthroughEnabled &&
        hasSeenTransactionsUpdatesTour === null && (
          <Suspense fallback={null}>
            <LazyLoadedTransactionsUpdateWalkthrough
              onFinish={() => updateTransactionsUpdateTourFlag(new Date().toISOString())}
            />
          </Suspense>
        )}
    </PageWithNoAccountsEmptyState>
  );
};

/**
 * Temporary component to conditionally render a collapsible layout based on a feature flag.
 * Should be removed when we delete the flag "transactions-ui-v2-web".
 */
export const ConditionalCollapsibleLayout: React.FC<
  React.PropsWithChildren<{ enabled: boolean; isSummaryColumnVisible: boolean }>
> = ({ children, enabled, isSummaryColumnVisible }) =>
  enabled ? (
    <CollapsibleLayout
      isCollapsed={!isSummaryColumnVisible}
      collapsibleColumnWidth={SUMMARY_COLUMN_WIDTH_PX}
    >
      {children}
    </CollapsibleLayout>
  ) : (
    // All of this should be deleted when we delete the flag "transactions-ui-v2-web"
    <Row>
      {React.Children.map(children, (child, index) =>
        index === 0 ? (
          <Column md={7} lg={8} order={1}>
            {child}
          </Column>
        ) : (
          <Column md={5} lg={4} order={0}>
            <StickyScroll>{child}</StickyScroll>
          </Column>
        ),
      )}
    </Row>
  );

export default Transactions;

// If you change this query name, change in constants/graphql.ts as well
const GET_SUMMARY = gql(/* GraphQL */ `
  query Web_GetTransactionsPage($filters: TransactionFilterInput) {
    aggregates(filters: $filters) {
      summary {
        ...TransactionsSummaryFields
      }
    }
  }
`);
