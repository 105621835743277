import { useCallback } from 'react';

import useEventListener from 'lib/hooks/useEventListener';

/**
 * Hook to listen for click events that are outside of a reference element.
 * The callback will not be called if the click originated from inside of the element.
 *
 * This is useful for popover-like elements that should be dismissed when clicking anywhere else.
 */
const useOnClickOutside = (elements: (Element | null)[], callback?: (event: Event) => void) => {
  const handleClick = useCallback(
    (event: Event) => {
      if (elements.some((element) => element?.contains(event.target as Node))) {
        return;
      }
      callback?.(event);
    },
    [callback, elements],
  );

  useEventListener(document, 'mousedown', handleClick);
  useEventListener(document, 'touchstart', handleClick);
};

export default useOnClickOutside;
