import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { linkMixin } from 'components/lib/ui/Link';

// Link component for navigating to an internal route using the `to` property
const RouteLink = styled(RouterLink)<{
  /** Keep Link unstyled until hover. */
  stealthy?: boolean;
  /** Sets color and font-weight to inherit */
  unstyled?: boolean;
  small?: boolean;
}>`
  ${linkMixin}
`;

export default RouteLink;
