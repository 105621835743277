import React from 'react';

import useTheme from 'lib/hooks/useTheme';

type Props = {
  size?: number;
  className?: string;
};

const UnsplashLogo = ({ size = 12, className }: Props) => {
  const theme = useTheme();

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 3.375V0H8.25V3.375H3.75ZM8.25 5.25H12V12H0V5.25H3.75V8.625H8.25V5.25Z"
        fill={theme.uiTheme === 'dark' ? theme.color.textWhite : theme.color.trueBlack}
      />
    </svg>
  );
};

export default UnsplashLogo;
