import { gql } from '@apollo/client';
import type { DateTime } from 'luxon';
import pluralize from 'pluralize';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import SectionHeader from 'components/lib/ui/SectionHeader';
import Tag from 'components/lib/ui/Tag';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import RouteLink from 'components/lib/ui/link/RouteLink';
import RecurringMenu from 'components/recurring/RecurringMenu';
import RecurringStream from 'components/recurring/RecurringStream';
import AmountWarningBanner from 'components/recurring/calendar/AmountWarningBanner';

import { useLastStatements } from 'common/lib/hooks/recurring/useLastStatements';
import useQuery from 'common/lib/hooks/useQuery';
import { getFrequencyLabel } from 'common/lib/recurring';
import type { RecurringTransactionItem } from 'common/lib/recurring/types';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import formatTransactionAmount from 'common/utils/formatTransactionAmount';

import { RECURRING } from 'common/constants/copy';
import routes from 'constants/routes';

import type {
  Web_GetEventPopoverTransactions,
  Web_GetEventPopoverTransactionsVariables,
} from 'common/generated/graphQlTypes/Web_GetEventPopoverTransactions';

const MERCHANT_LOGO_SIZE_PX = 48;

const Root = styled.div`
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  border-radius: ${({ theme }) => theme.radius.medium};
  background: ${({ theme }) => theme.color.white};
  width: 420px;
`;

const Header = styled(FlexContainer).attrs({ justifyBetween: true, alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.default};
`;

const StyledSectionHeader = styled(SectionHeader)`
  letter-spacing: 0.1em;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: ${({ theme }) => theme.spacing.xxsmall} ${({ theme }) => theme.spacing.default};
  text-transform: uppercase;
`;

const TransactionRow = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  padding: ${({ theme }) => theme.spacing.default};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const CategoryIcon = styled.div`
  font-size: 14px;
  margin-right: ${({ theme }) => theme.spacing.small};
  transform: translateY(-1px);
`;

const Footer = styled(FlexContainer).attrs({ alignCenter: true, justifyCenter: true })`
  padding: ${({ theme }) => theme.spacing.default};
`;

const EmptyTransactionContainer = styled(FlexContainer).attrs({ center: true })`
  padding: ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.xsmall};
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const EmptyTransactionText = styled(Text).attrs({ color: 'grayDark' })`
  width: 60%;
  display: block;
  text-align: center;
`;

const SpinnerContainer = styled(FlexContainer).attrs({ center: true })`
  margin: ${({ theme }) => theme.spacing.xlarge} 0;
`;

const Amount = styled(Text).attrs({ weight: 'medium', ...maskClassProps })<{
  $isIncome: boolean;
}>`
  color: ${({ $isIncome, theme }) => ($isIncome ? theme.color.green : theme.color.text)};
  margin-right: ${({ theme }) => theme.spacing.default};
  cursor: default;
`;

const RecurringIcon = styled(Icon).attrs({ name: 'calendar', size: 12 })`
  margin: 0 ${({ theme }) => theme.spacing.default};
  display: block;
  cursor: default;
  color: ${({ theme }) => theme.color.textLight};
`;

const TransactionLink = styled(RouteLink).attrs({ stealthy: true })`
  display: flex;
  flex: 1;
`;

type Props = {
  date: GraphQlDate;
  amount?: number | null | undefined;
  stream: RecurringTransactionItem['stream'];
  startDate: Maybe<DateTime>;
  setIsMenuOpen?: (open: boolean) => void;
  amountDiff: number;
  refetch: () => void;
};

const EventPopover = ({
  stream,
  amount,
  amountDiff,
  startDate,
  date,
  setIsMenuOpen,
  refetch,
}: Props) => {
  const isLiabilityAccount = !!stream.creditReportLiabilityAccount;

  const { data, isLoadingInitialData: isLoadingTransactions } = useQuery<
    Web_GetEventPopoverTransactions,
    Web_GetEventPopoverTransactionsVariables
  >(GET_POPOVER_TRANSACTIONS_QUERY, {
    // @ts-ignore
    variables: { merchantId: stream?.merchant?.id, endDate: date },
    skip: !stream?.merchant?.id,
  });

  const { statements, isLoadingStatements } = useLastStatements({
    creditReportLiabilityAccountId: stream?.creditReportLiabilityAccount?.id,
    limit: 3,
  });

  const isLoadingInitialData = isLoadingTransactions || isLoadingStatements;

  const isIncome = (stream.amount ?? 0) > 0;

  const transactions = useMemo(() => data?.allTransactions.results ?? [], [data?.allTransactions]);
  const totalCount = data?.allTransactions.totalCount;

  const tag = isLiabilityAccount ? 'ACCOUNT' : 'MERCHANT';

  const content = useMemo(() => {
    if (isLoadingInitialData) {
      return (
        <SpinnerContainer>
          <LoadingSpinner />
        </SpinnerContainer>
      );
    }

    if (RA.isNilOrEmpty(transactions) && RA.isNilOrEmpty(statements)) {
      return (
        <EmptyTransactionContainer>
          <EmptyTransactionText>
            {isLiabilityAccount
              ? RECURRING.LIABILITY_RECENT_BALANCES_EMPTY
              : RECURRING.MERCHANT_TRANSACTIONS_EMPTY}
          </EmptyTransactionText>
        </EmptyTransactionContainer>
      );
    }

    if (isLiabilityAccount) {
      return (
        <>
          <StyledSectionHeader>{RECURRING.LIABILITY_RECENT_BALANCES_HEADER}</StyledSectionHeader>
          {statements.map(({ id, dueDate, billAmount }) => (
            <TransactionRow key={id}>
              <Text size="small">{isoDateToAbbreviatedMonthDayAndYear(dueDate)}</Text>
              <Amount $isIncome={!!billAmount && billAmount > 0} size="small">
                {billAmount ? formatTransactionAmount(billAmount ?? 0) : '-'}
              </Amount>
            </TransactionRow>
          ))}
        </>
      );
    }

    return (
      <>
        <StyledSectionHeader>Recent transactions</StyledSectionHeader>
        {transactions.map(({ id, date, amount, category, isRecurring }) => (
          <TransactionRow key={date}>
            <TransactionLink to={routes.transactions.details({ id })}>
              <CategoryIcon>{category?.icon}</CategoryIcon>
              <Text size="small">{isoDateToAbbreviatedMonthDayAndYear(date)}</Text>
            </TransactionLink>
            {!!isRecurring && (
              <Tooltip content="Recurring">
                <span>
                  <RecurringIcon />
                </span>
              </Tooltip>
            )}
            <Amount $isIncome={amount > 0} size="small">
              {formatTransactionAmount(amount)}
            </Amount>
          </TransactionRow>
        ))}
        <Footer>
          <RouteLink to={routes.merchants({ id: stream?.merchant?.id || '' })}>
            <Text size="small">
              View all {totalCount} {pluralize('transaction', totalCount)}
            </Text>
          </RouteLink>
        </Footer>
      </>
    );
  }, [isLoadingInitialData, totalCount, transactions, statements, stream]);

  return (
    <Root>
      <Header>
        <RecurringStream
          id={stream.merchant?.id ?? stream?.creditReportLiabilityAccount?.account?.id ?? ''}
          name={stream?.name ?? '-'}
          frequency={getFrequencyLabel(stream.frequency)}
          logoUrl={stream.logoUrl}
          logoSize={MERCHANT_LOGO_SIZE_PX}
          startDate={startDate}
          isMerchant={!!stream.merchant}
        />
        <FlexContainer justifyEnd alignCenter>
          <Tooltip
            opacity={1}
            content={stream.isApproximate ? RECURRING.AMOUNT_ESTIMATE_TOOLTIP : undefined}
          >
            <Switch>
              <Case when={!!stream.merchant}>
                <Amount $isIncome={!!amount && amount > 0}>
                  {stream.isApproximate ? '≈' : ''}
                  {formatTransactionAmount(amount || 0)}
                </Amount>
              </Case>
              <Case when={!!stream.creditReportLiabilityAccount}>
                <Tag>{tag}</Tag>
              </Case>
            </Switch>
          </Tooltip>
          <RecurringMenu
            streamId={stream.id}
            merchantId={stream.merchant?.id}
            accountId={stream.creditReportLiabilityAccount?.account?.id}
            name={stream?.name}
            setIsMenuOpen={setIsMenuOpen}
            refetch={refetch}
          />
        </FlexContainer>
      </Header>
      {!!amountDiff && (
        <AmountWarningBanner
          expectedAmount={stream.amount ?? 0}
          amountDiff={amountDiff}
          isIncome={isIncome}
        />
      )}
      {content}
    </Root>
  );
};

const GET_POPOVER_TRANSACTIONS_QUERY = gql`
  query Web_GetEventPopoverTransactions($merchantId: UUID!, $endDate: Date!) {
    allTransactions(filters: { merchants: [$merchantId], endDate: $endDate }) {
      totalCount
      results(limit: 3) {
        id
        date
        amount
        isRecurring
        category {
          id
          name
          icon
        }
      }
    }
  }
`;

export default EventPopover;
