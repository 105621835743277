import { partition, sort } from 'ramda';
import React, { useMemo } from 'react';
import deepIsEqual from 'react-fast-compare';

import FlexContainer from 'components/lib/ui/FlexContainer';
import { PlanGridGroup } from 'components/plan/PlanGrid';
import PlanGroupLeft from 'components/plan/PlanGroupLeft';
import PlanGroupRight from 'components/plan/PlanGroupRight';

import { usePlanContext } from 'lib/contexts/PlanContext';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import type {
  GridAmountsForGroup,
  PlanGroupData,
  PlanRowData,
  PlanSectionType,
} from 'lib/plan/Adapters';
import { getUnallocatedFlexibleBudgetAmount } from 'lib/planning';

import { BudgetVariability } from 'common/generated/graphql';

export const GROUP_HEADER_Z_INDEX = 1;

type Props = {
  amounts: GridAmountsForGroup;
  group: PlanGroupData;
  numColumns: number;
  sectionType: PlanSectionType;
};

const PlanGroup = ({ amounts, group, numColumns, sectionType }: Props) => {
  const { timeframe, getDateForColumn } = usePlanContext();
  const firstColumnDate = useMemo(() => getDateForColumn(0), [getDateForColumn]);
  const firstColumnDateIsoDate = firstColumnDate.toISODate();
  const isGroupFlexibleAndSingleMonth =
    timeframe === PlanTimeframe.SingleMonth && group.id === BudgetVariability.FLEXIBLE;

  const unallocatedFlexibleBudgetAmount = useMemo(
    () =>
      getUnallocatedFlexibleBudgetAmount(
        amounts,
        firstColumnDateIsoDate,
        isGroupFlexibleAndSingleMonth,
        sectionType,
      ),
    [amounts, isGroupFlexibleAndSingleMonth, sectionType, firstColumnDateIsoDate],
  );

  const groupWithSortedRows = useMemo(() => {
    // Do not sort other groups that are not Flexible
    if (group.id !== BudgetVariability.FLEXIBLE) {
      return group;
    }

    // Sort rows by actual amounts so that the row with the most spent is at the top
    const sortByActual = (a: PlanRowData, b: PlanRowData) => {
      const actualA = amounts[a.id]?.[firstColumnDateIsoDate]?.actual ?? 0;
      const actualB = amounts[b.id]?.[firstColumnDateIsoDate]?.actual ?? 0;
      return actualB - actualA; // descending
    };

    const sortAndOrganizeRows = (rows: PlanRowData[], childRows: PlanRowData[]): PlanRowData[] => {
      const sortedRows = sort(sortByActual, rows);
      return sortedRows.reduce((acc, row) => {
        if (row.groupLevelBudgetingEnabled) {
          const children = childRows.filter((child) => child.parentGroupId === row.id);
          const sortedChildren = sort(sortByActual, children);
          return [...acc, row, ...sortedChildren];
        }
        return [...acc, row];
      }, [] as PlanRowData[]);
    };

    // Separate top-level rows (no parent) from child rows (part of a category group)
    const [topLevelRows, childRows] = partition(
      (row: PlanRowData) => !row.parentGroupId,
      group.rows,
    );

    // Partition top-level rows into planned and unplanned
    const [plannedTopLevelRows, unplannedTopLevelRows] = partition(
      (row) => !row.isUnplanned,
      topLevelRows,
    );

    // Partition child rows into planned and unplanned
    const [plannedChildRows, unplannedChildRows] = partition((row) => !row.isUnplanned, childRows);

    // Sort and organize planned rows
    const plannedResult = sortAndOrganizeRows(plannedTopLevelRows, plannedChildRows);

    // Sort and organize unplanned rows
    const unplannedResult = sortAndOrganizeRows(unplannedTopLevelRows, unplannedChildRows);

    return {
      ...group,
      rows: [...plannedResult, ...unplannedResult],
    };
  }, [amounts, firstColumnDateIsoDate, group]);

  return (
    <PlanGridGroup>
      <FlexContainer>
        <PlanGroupLeft
          group={groupWithSortedRows}
          sectionType={sectionType}
          unallocatedFlexibleBudgetAmount={unallocatedFlexibleBudgetAmount}
        />
        <PlanGroupRight
          amounts={amounts}
          group={groupWithSortedRows}
          numColumns={numColumns}
          sectionType={sectionType}
          unallocatedFlexibleBudgetAmount={unallocatedFlexibleBudgetAmount}
        />
      </FlexContainer>
    </PlanGridGroup>
  );
};
export default React.memo(PlanGroup, deepIsEqual);
