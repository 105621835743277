import invariant from 'invariant';
import { useMemo } from 'react';

import { GET_DISPLAY_BALANCE_AT_DATE_QUERY } from 'common/lib/graphQl/snapshots';
import useMockableQuery from 'common/lib/hooks/useMockableQuery';
import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated';

type UseNetWorthChangesOptions = {
  accountType?: string | null;
  snapshotStartDate?: string | null;
  showEmptyState?: boolean;
  skip?: boolean;
};

/** Hook to calculate the balance changes for different account types. */
const useAccountChanges = (options: UseNetWorthChangesOptions) => {
  const { accountType, snapshotStartDate, showEmptyState = false, skip } = options || {};

  invariant(snapshotStartDate, 'snapshotStartDate is required');

  const {
    data: aggregateSnapshotsData,
    refetch: refetchAggregateSnapshots,
    isLoadingInitialData: isLoadingAggregateSnapshots,
    isNetworkRequestInFlight: isAggregateSnapshotsNetworkRequestInFlight,
  } = useQuery(GET_AGGREGATE_SNAPSHOTS, {
    variables: {
      filters: {
        accountType,
        startDate: snapshotStartDate,
        endDate: null,
        useAdaptiveGranularity: true,
      },
    },
    skip,
  });

  const firstSnapshot = aggregateSnapshotsData?.aggregateSnapshots?.[0];
  // If the first snapshot date is different from the start date, we should override the start date
  const shouldOverrideStartDate = firstSnapshot?.date && firstSnapshot?.date !== snapshotStartDate;

  const {
    data: balanceAtDateData,
    isLoadingInitialData: isLoadingBalanceAtDate,
    refetch: refetchBalanceAtDate,
  } = useMockableQuery(GET_DISPLAY_BALANCE_AT_DATE_QUERY, showEmptyState, {
    variables: {
      date: shouldOverrideStartDate ? firstSnapshot?.date : snapshotStartDate,
    },
  });

  const previousAmountsByAccountType: Record<string, number> = useMemo(
    () =>
      (balanceAtDateData?.accounts ?? []).reduce(
        (acc, account) => {
          if (!account.includeInNetWorth) {
            return acc;
          }

          const current = acc[account.type.name] ?? 0;
          return { ...acc, [account.type.name]: current + (account.displayBalance ?? 0.0) };
        },
        {} as Record<string, number>,
      ),
    [balanceAtDateData],
  );

  return {
    aggregateSnapshots: aggregateSnapshotsData?.aggregateSnapshots ?? [],
    previousAmountsByAccountType,
    isLoadingAggregateSnapshots,
    isLoadingBalanceAtDate,
    isAggregateSnapshotsNetworkRequestInFlight,
    refetchAggregateSnapshots,
    refetchBalanceAtDate,
  };
};

export const GET_AGGREGATE_SNAPSHOTS = gql(/* GraphQL */ `
  query Common_GetAggregateSnapshots($filters: AggregateSnapshotFilters) {
    aggregateSnapshots(filters: $filters) {
      date
      balance
      assetsBalance
      liabilitiesBalance
    }
  }
`);

export default useAccountChanges;
