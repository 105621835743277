import { rgba } from 'polished';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import AccountBalanceIndicator from 'components/accounts/AccountBalanceIndicator';
import BalanceDiffIndicator from 'components/accounts/BalanceDiffIndicator';
import ChartTooltip from 'components/lib/charts/ChartTooltip';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import type { NetWorthMonthData } from 'common/lib/accounts/netWorthCharts';
import { getAmountsByAccountType } from 'common/lib/accounts/netWorthCharts';
import useCachedAccountTypeOptions from 'common/lib/hooks/accounts/useCachedAccountTypeOptions';
import { formatCurrency } from 'common/utils/Currency';

import type { AccountType } from 'common/constants/accounts';

import { AccountTypeGroup } from 'common/generated/graphql';

const Tooltip = styled(ChartTooltip)`
  min-width: 280px;
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const StyledBalanceIndicator = styled(AccountBalanceIndicator)`
  font-size: ${({ theme }) => theme.fontSize.small};
  &:not(:last-child) {
    margin-bottom: -${(props) => props.theme.spacing.xxsmall};
  }
`;

const TooltipHeader = styled(Text)`
  display: block;
  padding: ${({ theme }) => theme.spacing.xxsmall} 0;
  font-size: ${({ theme }) => theme.fontSize.base};
`;

const TooltipFooter = styled(FlexContainer).attrs({ justifyBetween: true })`
  padding: ${({ theme }) => theme.spacing.xxsmall} 0;
  flex-direction: column;
`;

const StyledBalanceDiffIndicator = styled(BalanceDiffIndicator)`
  justify-content: flex-end;
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
    border-bottom: 1px solid ${({ theme }) => rgba(theme.color.black, 0.5)};
  }
`;

type Props = {
  title: string;
  currentMonthData?: NetWorthMonthData;
  previousMonthData?: NetWorthMonthData;
};

const AccountNetWorthChartTooltip = ({ title, currentMonthData, previousMonthData }: Props) => {
  const { accountTypes } = useCachedAccountTypeOptions();

  const getIsLiabilityFromAccountType = useCallback(
    (accountType: AccountType) => {
      const matchingType = accountTypes.find((type) => type.name === accountType);
      return matchingType?.group === AccountTypeGroup.LIABILITY;
    },
    [accountTypes],
  );

  const { month, net, ...accountTypeAmounts } = currentMonthData ?? {};
  const currentBalancesByAccountType = currentMonthData
    ? getAmountsByAccountType(currentMonthData)
    : {};
  const previousBalancesByAccountType = previousMonthData
    ? getAmountsByAccountType(previousMonthData)
    : {};

  return (
    <Tooltip
      header={<TooltipHeader>{title}</TooltipHeader>}
      footer={
        <TooltipFooter>
          <Flex justifyBetween>
            <Text>Net Worth</Text>
            <Text>{formatCurrency(net)}</Text>
          </Flex>
          <StyledBalanceDiffIndicator
            balance={net}
            previousBalance={previousMonthData?.net}
            inverted
          />
        </TooltipFooter>
      }
      active
    >
      {Object.entries(accountTypeAmounts)
        .filter(([key]) => key !== 'currentMonthNet')
        .map(([type]) => {
          const accountType = type as AccountType;
          const previousBalance = previousBalancesByAccountType[type];
          return (
            <div key={`${type}-${previousBalance}`}>
              <StyledBalanceIndicator
                key={type}
                type={accountType}
                balance={currentBalancesByAccountType[type]}
                inverted
              />
              <StyledBalanceDiffIndicator
                balance={currentBalancesByAccountType[type]}
                previousBalance={previousBalance}
                isLiability={getIsLiabilityFromAccountType(accountType)}
                inverted
              />
            </div>
          );
        })}
    </Tooltip>
  );
};

export default AccountNetWorthChartTooltip;
