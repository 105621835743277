import React from 'react';

import AddManualAccountFormModal from 'components/accounts/AddManualAccountFormModal';
import AddManualAccountTypeSelectionModal from 'components/accounts/AddManualAccountTypeSelectionModal';
import AddManualAccountTypeSpecificSubflow from 'components/accounts/AddManualAccountTypeSpecificSubflow';
import type { LinkAccountSuccessMetadata } from 'components/accounts/LinkAccountDataProviderModal';
import Flow from 'components/utils/Flow';

import { isVehicleAccount } from 'common/lib/accounts/accountTypes';

import type { ManualInvestmentAccountTrackingModeType } from 'common/constants/accounts';

type Props = {
  forceManualAccount?: boolean;
  initialProps?: { type?: string; subtype?: string | null; skipTypeSelection?: string };
  onComplete?: (data: LinkAccountSuccessMetadata) => void;
  onBack?: () => void;
};

export type AddManualAccountFlowData = {
  name?: string;
  type?: string;
  subtype?: string | null;
  balance?: number;
  investmentTrackingMode?: ManualInvestmentAccountTrackingModeType;
};

const AddManualAccountFlow = ({ onComplete, onBack, forceManualAccount, initialProps }: Props) => {
  if (initialProps?.type) {
    let steps = [AddManualAccountFormModal, AddManualAccountTypeSpecificSubflow];
    if (isVehicleAccount(initialProps?.type) && !forceManualAccount) {
      steps = [AddManualAccountTypeSpecificSubflow];
    }
    return (
      <Flow
        // @ts-ignore
        steps={steps}
        initialProps={{
          type: initialProps.type ?? '',
          subtype: initialProps?.subtype ?? null,
        }}
        onComplete={onComplete}
        onBack={onBack}
      />
    );
  }
  return (
    <Flow
      steps={[
        AddManualAccountTypeSelectionModal,
        AddManualAccountFormModal,
        AddManualAccountTypeSpecificSubflow,
      ]}
      initialProps={initialProps}
      onComplete={onComplete}
      onBack={onBack}
    />
  );
};

export default AddManualAccountFlow;
