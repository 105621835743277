// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 1255239280
import { createAction } from 'typesafe-actions';

import type { UploadStatementSession } from 'state/statements/types';

export const clearCurrentUploadSession = createAction('STATEMENTS/CLEAR_CURRENT_UPLOAD_SESSION')();

export const mergeCurrentUploadSession = createAction('STATEMENTS/MERGE_CURRENT_UPLOAD_SESSION')<
  Partial<UploadStatementSession>
>();

export const setCurrentUploadSession = createAction(
  'STATEMENTS/SET_CURRENT_UPLOAD_SESSION',
)<UploadStatementSession>();
