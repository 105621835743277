import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';

import { spacing } from 'common/lib/theme/dynamic';

const Controls = styled(FlexContainer).attrs({ alignCenter: true })`
  > *:not(:first-child) {
    margin-left: ${spacing.small};
  }
`;

export default Controls;
