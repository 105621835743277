import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useEffect, useCallback, useState, useMemo } from 'react';

import useToggle from 'common/lib/hooks/useToggle';

export type Item<ValueT> = {
  value: ValueT;
  remove: () => void;
  modify: (cb: (value: ValueT) => ValueT) => void;
};

/**
 * Hook to maintain an ordered collection of items that can be added, removed, and modified
 */
const useItemArray = <ValueT>(
  initialValues: ValueT[] | null = null,
): readonly [
  Item<ValueT>[],
  (value: ValueT) => symbol,
  (key: symbol) => Item<ValueT> | undefined,
] => {
  // Use an es6 map here rather than a plain obj since maps always preserve insertion order
  const [items, setItems] = useState<Map<symbol, Item<ValueT>>>(new Map());
  const [hasSetInitialValues, { setOn: lockInitialValues }] = useToggle(false);

  const getItem = useCallback((key: symbol) => items.get(key as symbol), [items]);

  const addItem = useCallback((value: ValueT) => {
    const key = Symbol();
    const item = {
      value,
      remove: () => {
        setItems((items) => {
          items.delete(key);
          return new Map(items);
        });
      },
      modify: (modifier: (value: ValueT) => ValueT) => {
        setItems((items) => {
          const item = items.get(key);
          if (R.isNil(item)) {
            throw new Error('tried to modify a deleted item');
          }
          return new Map(items.set(key, { ...item, value: modifier(item.value) }));
        });
      },
    };
    // Always return a new map or hook won't detect a change
    setItems((items) => new Map(items.set(key, item)));
    lockInitialValues();

    // Don't return item. Item lives in the callback closure and will NEVER be updated
    return key;
  }, []);

  useEffect(() => {
    if (RA.isNonEmptyArray(initialValues) && !hasSetInitialValues) {
      initialValues.forEach(addItem);
      lockInitialValues();
    }
  }, [initialValues]);

  const itemArray = useMemo(() => Array.from(items.values()), [items]);

  return [itemArray, addItem, getItem] as const;
};

export default useItemArray;
