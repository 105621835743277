import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { MXAnalyticsNameMapping, MXEventName } from 'common/lib/external/mx';
import useToggle from 'common/lib/hooks/useToggle';
import { track } from 'lib/analytics/segment';
import getStringEnvVar from 'lib/getStringEnvVar';
import useScript from 'lib/hooks/useScript';
import useToast from 'lib/hooks/useToast';

import { MX_CONNECTIVITY_ISSUE_EVENTS } from 'common/constants/institutions';

/**
 * The reference to "atrium" is a holdover from a legacy API.
 * Nevertheless, this is still the correct source for the widget loader
 * even in the context of the Platform API.
 * https://docs.mx.com/api#connect_connect_javascript_loader
 */
const MX_CONNECT_SCRIPT_URL = 'https://atrium.mx.com/connect.js';

const HEIGHT_PX = 664;
const CONTAINER_ID = 'connect-widget';

const StyledCard = styled(ModalCard)`
  overflow: hidden;
  height: ${HEIGHT_PX}px;
`;

const ContainerRoot = styled.div<{ isLoading: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  height: 100%;
`;

const ErrorContainer = styled(FlexContainer).attrs({ center: true, column: true })`
  flex: 1;
`;

declare global {
  interface Window {
    MXConnect?: any;
  }
}

type Props = {
  connectUrl?: string;
  onClose?: (hasConnectivityIssue?: boolean) => void;
  onSuccess: () => void;
};

const MXConnect = ({ connectUrl, onClose, onSuccess }: Props) => {
  const { openToast } = useToast();

  const [scriptLoading, scriptError] = useScript(MX_CONNECT_SCRIPT_URL);
  const [isLoading, { setOff: setDoneLoading }] = useToggle(true);
  const showLoading = isLoading || scriptLoading;
  const [hasConnectivityIssue, setHasConnectivityIssue] = useState<boolean>(false);

  const onExit = () => onClose?.(hasConnectivityIssue);

  useEffect(() => {
    if (scriptLoading || scriptError) {
      return;
    }

    if (window.MXConnect && connectUrl) {
      const mxConnect = new window.MXConnect({
        id: CONTAINER_ID,
        iframeTitle: 'Connect',
        onEvent: (eventName: MXEventName, metadata: any) => {
          if (eventName === MXEventName.ConnectLoaded) {
            setDoneLoading();
          }

          // any of the following mean the user has working credentials
          if (
            eventName === MXEventName.MemberConnected ||
            eventName === MXEventName.UpdateCredentials ||
            (eventName === MXEventName.ConnectLoaded && metadata.initial_step === 'connected') // credential is already connected
          ) {
            setHasConnectivityIssue(false);
            onSuccess();
            // Show a toast when users clicked to fix credentials but there was nothing to fix
            openToast({
              title: 'Success',
              description: 'Your credentials are now connected.',
            });
          }

          if (MX_CONNECTIVITY_ISSUE_EVENTS.includes(eventName)) {
            setHasConnectivityIssue(true);
          }

          const analyticsEventName = MXAnalyticsNameMapping[eventName];
          if (analyticsEventName) {
            track(analyticsEventName, metadata);
          }
        },
        config: {
          ui_message_version: 4,
          target_origin_referrer: getStringEnvVar('REACT_APP_BASE_URL'),
        },
        targetOrigin: '*',
      });

      mxConnect.load(connectUrl);
    }
  }, [scriptLoading, scriptError, connectUrl]);

  return (
    <StyledCard onClickBackButton={onExit} hideCloseButton hideBottomBorder>
      <Switch>
        <Case when={scriptError}>
          <ErrorContainer>
            <p>Sorry, something went wrong. Please try again.</p>
            <DefaultButton size="medium" onClick={onExit}>
              Close
            </DefaultButton>
          </ErrorContainer>
        </Case>
        <Case default>
          <>
            {showLoading && (
              <LoadingContainer>
                <LoadingSpinner />
              </LoadingContainer>
            )}
            <ContainerRoot id={CONTAINER_ID} isLoading={showLoading} />
          </>
        </Case>
      </Switch>
    </StyledCard>
  );
};

export default MXConnect;
