import queryString from 'query-string';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import { objToKey } from 'common/utils/Object';

import type { TransactionFilterInput } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

export const allAmounts = 'allAmounts';
export const greaterThan = 'greaterThan';
export const lessThan = 'lessThan';
export const equalTo = 'equalTo';
export const between = 'between';
export const minAmount = 'absAmountGte';
export const maxAmount = 'absAmountLte';

export const DEFAULT_FILTERS: TransactionFilters = {
  search: '',
  categories: [],
  accounts: [],
  absAmountGte: undefined,
  absAmountLte: undefined,
  amountFilter: undefined,
  tags: [],
  startDate: undefined,
  endDate: undefined,
  categoryGroups: undefined,
  order: undefined,
  goalId: undefined,
  hasAttachments: undefined,
  hasNotes: undefined,
  hideFromReports: undefined,
  householdMerchants: undefined,
  ids: undefined,
  importedFromMint: undefined,
  isRecurring: undefined,
  isSplit: undefined,
  isUncategorized: undefined,
  merchants: undefined,
  needsReview: undefined,
  uploadedStatement: undefined,
  needsReviewByUser: undefined,
  needsReviewUnassigned: undefined,
  isFlexSpending: undefined,
  syncedFromInstitution: undefined,
  categoryType: undefined,
  isInvestmentAccount: undefined,
  creditsOnly: undefined,
  debitsOnly: undefined,
  isPending: undefined,
  excludeIds: undefined,
};

export const resetAmountFilters = (
  values: TransactionFilters,
  amountFilter = '',
): TransactionFilters => {
  switch (amountFilter) {
    case equalTo:
      return { ...values, absAmountGte: values.absAmountLte };
    case allAmounts:
      return R.omit([minAmount, maxAmount], values) as TransactionFilters;
    case lessThan:
      return R.omit([minAmount], values) as TransactionFilters;
    case greaterThan:
      return R.omit([maxAmount], values) as TransactionFilters;
    default:
      return values;
  }
};

export const amountFilterOptions = [
  { label: 'All amounts', value: allAmounts },
  { label: 'Greater than', value: greaterThan },
  { label: 'Less than', value: lessThan },
  { label: 'Equal to', value: equalTo },
  { label: 'Between', value: between },
];

/**
 * 1. Omit `amountFilter` because the BE only needs the gte/lte amounts
 * (the amountFilter type can be inferred from the values of absAmountGte/Lte)
 * 2. Omit `order` because the BE doesn't need it on filters */
export const convertFiltersToInput = <T extends TransactionFilters>(filters: Partial<T>) => {
  const merged = R.mergeLeft(filters, DEFAULT_FILTERS);
  return R.omit(['amountFilter', 'order'])(merged) as unknown as TransactionFilterInput;
};

/**
 * Generate query parameters from the filters, overriding or keeping the current ones
 * if necessary (e.g. if the user is already on the page with a filter)
 */
export const getUpdatedQueryParams = (params: Record<string, unknown>, override?: boolean) => {
  const currentParams = override ? {} : queryString.parse(window.location.search);
  return { ...currentParams, ...params };
};

const stringifyFilters = <T extends Partial<TransactionFilters>>(filters: T) =>
  R.pipe(R.reject(RA.isNilOrEmpty), objToKey)(filters);

export const isAnyFilterApplied = <T extends Partial<TransactionFilters>>(filters: T) =>
  !R.equals(stringifyFilters(filters), stringifyFilters(DEFAULT_FILTERS));
