import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import CurrencyField from 'components/lib/form/CurrencyField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import Flex from 'components/lib/ui/Flex';

import type { GetNotificationPreferences_notificationPreferences } from 'common/generated/graphQlTypes/GetNotificationPreferences';

type Props = {
  notificationPreference: GetNotificationPreferences_notificationPreferences;
  // eslint-disable-next-line
  onSubmit: (values: {}) => Promise<void>;
  lastSuccessfulUpdate: string | undefined;
};

const StyledTextField = styled(TextField)`
  width: 169px;
`;

const StyledCurrencyField = styled(CurrencyField)`
  width: 169px;
`;

const FormContainer = styled(Flex)`
  align-items: center;
`;

const StyledFormSubmitButton = styled(FormSubmitButton)`
  width: auto;
  margin-left: ${({ theme }) => theme.spacing.default};
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const Success = styled.div`
  color: ${({ theme }) => theme.color.green};
`;

const NotificationAdditionalPreferences = ({
  notificationPreference,
  lastSuccessfulUpdate,
  onSubmit,
}: Props) => {
  const { additionalPreferencesMeta, additionalPreferences } = notificationPreference;

  if (R.isNil(additionalPreferencesMeta) || R.isEmpty(additionalPreferencesMeta)) {
    return null;
  }

  const initialValues = R.mapObjIndexed(
    ({ default: defaultValue }, key) => additionalPreferences?.[key] ?? defaultValue,
    additionalPreferencesMeta,
  );

  const renderFormField = (title: string, type: string, key: string): React.ReactNode => {
    if (type === 'number') {
      // TODO: Will this always be currency? How can we differentiate between numbers and currency?
      return <StyledCurrencyField key={key} name={key} label={title} />;
    }
    return <StyledTextField key={key} name={key} label={title} />;
  };

  const fieldNames = R.join(
    ' ',
    R.values(R.mapObjIndexed(({ title }, _) => title, additionalPreferencesMeta)),
  );

  const formFields = R.values(
    R.mapObjIndexed(
      ({ title, type }, key) => renderFormField(title.toString(), type.toString(), key),
      additionalPreferencesMeta,
    ),
  );

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      <FormContainer>
        {formFields}
        <StyledFormSubmitButton variant="default" size="medium">
          Save
        </StyledFormSubmitButton>
      </FormContainer>
      {lastSuccessfulUpdate === notificationPreference.id && (
        <Success>{fieldNames} updated</Success>
      )}
    </Form>
  );
};

export default NotificationAdditionalPreferences;
