import * as htmlToImage from 'html-to-image';
import React, { useCallback, useState } from 'react';

import Text from 'components/lib/ui/Text';
import IconButton from 'components/lib/ui/button/IconButton';

import typewriter from 'lib/analytics/typewriter';

type Props<TElement = unknown> = {
  element: TElement | undefined | null;
  fileName: string;
  className?: string;
  /** Used for analytics */
  type: string;
};

const ExportElementButton = <TElement extends HTMLElement>({
  element,
  fileName,
  className,
  type,
}: Props<TElement>) => {
  const [isExporting, setIsExporting] = useState(false);
  const onClick = useCallback(async () => {
    if (!element) {
      return;
    }

    typewriter.chartDownloaded({ chart: type });

    setIsExporting(true);
    try {
      // This is needed because we use a custom font, so we parse them before rendering the image
      // (without this, the first export in Safari doesn't render fonts)
      const font = await htmlToImage.getFontEmbedCSS(element);
      const dataUrl = await htmlToImage.toPng(element, { skipAutoScale: true, fontEmbedCSS: font });
      const hiddenLink = document.createElement('a');
      hiddenLink.href = dataUrl;
      hiddenLink.download = fileName;
      hiddenLink.click();
    } finally {
      setIsExporting(false);
    }
  }, [element]); // eslint-disable-line react-hooks/exhaustive-deps

  return isExporting ? (
    <Text weight="medium" color="textLight" className={className}>
      Shared via monarchmoney.com
    </Text>
  ) : (
    <IconButton icon="download" onClick={onClick} />
  );
};

export default ExportElementButton;
