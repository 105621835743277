import { randomNumber } from 'common/utils/Random';
import { MOCK_INSTITUTION_LOGOS } from 'common/utils/graphQl/__mocks__/institutionLogos';

export const MOCK_GOALS_V2 = [
  {
    id: '134718542845454358',
    imageStorageProvider: 'cloudinary',
    imageStorageProviderId: 'production/goals/defaults/vrfzplpa6e62iex9wqxx',
    priority: 1,
    accountAllocations: [
      {
        id: '134718594041129044',
        account: {
          id: '102198468136019997',
          dataProvider: 'plaid',
          type: {
            name: 'depository',
          },
          subtype: {
            name: 'checking',
          },
          logoUrl: `data:image/png;base64,${MOCK_INSTITUTION_LOGOS.WELLS_FARGO}`,
          institution: {
            id: '102172543240606147',
            primaryColor: '#7f57d8',
          },
        },
      },
    ],
    name: 'Emergency fund',
    targetAmount: 50000,
    currentAmount: 25000,
    completedAt: null,
    archivedAt: null,
  },
  {
    id: '134718542854891543',
    imageStorageProvider: 'cloudinary',
    imageStorageProviderId: 'production/goals/defaults/e4dfipehuimtcl2kdefx',
    priority: 2,
    accountAllocations: [
      {
        id: '134718605907863637',
        account: {
          id: '102198468255557667',
          dataProvider: 'plaid',
          type: {
            name: 'brokerage',
          },
          subtype: {
            name: 'st_401k',
          },
          logoUrl: `data:image/png;base64,${MOCK_INSTITUTION_LOGOS.WELLS_FARGO}`,
          institution: {
            id: '102172543240606147',
            primaryColor: '#7f57d8',
          },
        },
      },
      {
        id: '134718612668033110',
        account: {
          id: '102198366253230091',
          dataProvider: 'plaid',
          type: {
            name: 'depository',
          },
          subtype: {
            name: 'checking',
          },
          logoUrl: `data:image/png;base64,${MOCK_INSTITUTION_LOGOS.WELLS_FARGO}`,
          institution: {
            id: '102172541551702223',
            primaryColor: '#d11f37',
          },
        },
      },
    ],
    name: 'Buy a home',
    targetAmount: 130000,
    currentAmount: 112400,
    completedAt: null,
    archivedAt: null,
  },
  {
    id: '134718542859085848',
    imageStorageProvider: 'cloudinary',
    imageStorageProviderId: 'production/goals/defaults/t2mtni09ys9sagvxrd3x',
    priority: 3,
    accountAllocations: [
      {
        id: '134718617889941591',
        account: {
          id: '102198428141234198',
          dataProvider: 'plaid',
          type: {
            name: 'depository',
          },
          subtype: {
            name: 'cd',
          },
          logoUrl: `data:image/png;base64,${MOCK_INSTITUTION_LOGOS.WELLS_FARGO}`,
          institution: {
            id: '102172541781144812',
            primaryColor: '#007cc3',
          },
        },
      },
      {
        id: '134718625527769176',
        account: {
          id: '102173013882316805',
          dataProvider: 'plaid',
          type: {
            name: 'depository',
          },
          subtype: {
            name: 'cd',
          },
          logoUrl: `data:image/png;base64,${MOCK_INSTITUTION_LOGOS.AMEX}`,
          institution: {
            id: '102172542000382219',
            primaryColor: '#095aa6',
          },
        },
      },
    ],
    name: 'Pay off credit card debt',
    targetAmount: 10000,
    currentAmount: randomNumber(4000),
    completedAt: null,
    archivedAt: null,
  },
];
