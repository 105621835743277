import { rgba } from 'polished';
import * as RA from 'ramda-adjunct';

import type { Color as ColorName, ThemeType } from 'common/types/Styles';

type VariableContext = {
  theme: ThemeType;
};

export type Variable<T> = (context: VariableContext) => T;
type ValueOrVariable<T> = T | Variable<T>;

export const color =
  (light: ColorName, dark?: ColorName): Variable<string> =>
  ({ theme }) => {
    const { uiTheme } = theme;

    const colorName = uiTheme === 'light' ? light : dark ?? light;

    return theme.color[colorName];
  };

export const withOpacity =
  (color: ValueOrVariable<string>, opacity: ValueOrVariable<number>): Variable<string> =>
  (context) => {
    const colorValue = resolveValue(color, context);
    const opacityValue = resolveValue(opacity, context);

    return rgba(colorValue, opacityValue);
  };

export const ifTheme =
  <T>({ v1, v2 }: { v1: ValueOrVariable<T>; v2: ValueOrVariable<T> }): Variable<T> =>
  (context) => {
    const { isV2ThemeEnabled } = context.theme;

    return isV2ThemeEnabled ? resolveValue(v2, context) : resolveValue(v1, context);
  };

export const ifDark =
  <T>(dark: ValueOrVariable<T>, light: ValueOrVariable<T>): Variable<T> =>
  (context) =>
    context.theme.uiTheme === 'dark' ? resolveValue(dark, context) : resolveValue(light, context);

const resolveValue = <T>(value: ValueOrVariable<T>, context: VariableContext): T =>
  RA.isFunction(value) ? value(context) : value;
