import React from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import { SearchResultType } from 'components/globalSearch/types';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import MerchantLogo from 'components/merchants/MerchantLogo';

const LOGO_SIZE_PX = 32;

const ItemWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.default};
  border-radius: ${({ theme }) => theme.radius.small};
  cursor: pointer;

  ${({ selected, theme }) => selected && `background: ${theme.color.grayBackground}`}
`;

const Title = styled(Text).attrs({ color: 'text', weight: 'medium' })`
  margin-left: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const ResultType = styled(Text).attrs({ color: 'textLight' })`
  margin-left: ${({ theme }) => theme.spacing.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding-top: 2px;
  text-transform: capitalize;
`;

const TextIcon = styled.div`
  width: ${LOGO_SIZE_PX}px;
  text-align: center;
`;

type Props = {
  id: string;
  title: string;
  type: SearchResultType;
  logoUrl: string | null;
  icon: string | null;
  selected: boolean;
  onSelect: (id: string, type: SearchResultType) => void;
  onPointerMove: () => void;
};

const SearchResult = ({
  id,
  title,
  type,
  logoUrl,
  icon,
  selected,
  onSelect,
  onPointerMove,
}: Props) => (
  <ItemWrapper onClick={() => onSelect(id, type)} selected={selected} onPointerMove={onPointerMove}>
    <FlexContainer alignCenter>
      {type === SearchResultType.Account && (
        <AccountLogo size={LOGO_SIZE_PX} logoUrl={logoUrl} icon={icon} />
      )}
      {type === SearchResultType.Merchant && <MerchantLogo url={logoUrl} size={LOGO_SIZE_PX} />}
      {type === SearchResultType.Goal && <TextIcon>{icon}</TextIcon>}
      {type === SearchResultType.Category && <TextIcon>{icon}</TextIcon>}

      <Title>{title}</Title>

      <ResultType>{type}</ResultType>
    </FlexContainer>
  </ItemWrapper>
);

export default SearchResult;
