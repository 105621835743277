import React from 'react';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';

const NotSelectedText = styled(Text)`
  color: ${({ theme }) => theme.color.textLight};
`;

type NotSelectedHelpProps = {
  columnType: 'account' | 'category';
  isMerchant: boolean;
};

const NotSelectedHelp: React.FC<NotSelectedHelpProps> = ({ columnType, isMerchant }) => {
  if (isMerchant) {
    return <Text>-</Text>;
  }

  const tooltipContent = `To select a default payment ${columnType} for this bill, click the three dots on the right and edit account`;
  return (
    <Tooltip portal content={tooltipContent}>
      <NotSelectedText>Not selected</NotSelectedText>
    </Tooltip>
  );
};

export default NotSelectedHelp;
