import { createSelector } from 'reselect';

import type { RootState } from 'state/types';

const getOnboardingState = ({ onboarding }: RootState) => onboarding;

export const getTemporaryPromoCode = createSelector(
  getOnboardingState,
  ({ temporaryPromoCode }) => temporaryPromoCode,
);

export const getManageFinancesWithPartner = createSelector(
  getOnboardingState,
  ({ manageFinancesWithPartner }) => manageFinancesWithPartner,
);
