import React from 'react';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

type Props = {
  text: React.ReactNode;
};

const LoadingContainer = styled(Flex)`
  flex: 1;
  height: 100%;
`;

const LoadingSpinnerWithMargin = styled(LoadingSpinner)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const LoadingSpinnerWithText = ({ text }: Props) => (
  <LoadingContainer center column>
    <LoadingSpinnerWithMargin />
    {text}
  </LoadingContainer>
);

export default LoadingSpinnerWithText;
