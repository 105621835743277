import * as R from 'ramda';
import storage from 'redux-persist/lib/storage';
import { createReducer } from 'typesafe-actions';

import {
  togglePlanGroupCollapsed,
  toggleUnplannedExpandedForGroup,
  toggleExpandedGroupRow,
} from 'actions';

export const planPersistConfig = {
  key: 'plan',
  storage,
  whitelist: ['collapsedGroups'],
};

const INITIAL_STATE: PlanState = {
  collapsedGroups: {},
  isUnplannedExpandedByGroup: {},
  expandedGroupRows: {},
};

export type PlanState = {
  collapsedGroups: { [id: string]: boolean };
  isUnplannedExpandedByGroup: { [id: string]: boolean };
  expandedGroupRows: { [id: string]: boolean };
};

const plan = createReducer<PlanState>(INITIAL_STATE)
  .handleAction(togglePlanGroupCollapsed, (state, { payload: groupId }) =>
    R.evolve(
      {
        collapsedGroups: (prev) => ({
          ...prev,
          [groupId]: !prev[groupId],
        }),
      },
      state,
    ),
  )
  .handleAction(toggleUnplannedExpandedForGroup, (state, { payload: groupId }) =>
    R.evolve(
      {
        isUnplannedExpandedByGroup: (prev) => ({
          ...prev,
          [groupId]: !prev[groupId],
        }),
      },
      state,
    ),
  )
  .handleAction(toggleExpandedGroupRow, (state, { payload: groupId }) =>
    R.evolve(
      {
        expandedGroupRows: (prev) => ({
          ...prev,
          [groupId]: !prev[groupId],
        }),
      },
      state,
    ),
  );

export default plan;
