import boxShadow from 'common/lib/styles/boxShadow';

import type { StyleMixin } from 'common/types/Styles';

const buttonShadowMixin: StyleMixin = (context) => `
  ${boxShadow.small(context)}
  :active:not(:disabled), &.btn-active:not(:disabled) {
    ${boxShadow.inset(context)}
  }
`;

export default buttonShadowMixin;
