export const trimEmptyRows = (rows: string[][]): string[][] =>
  rows.filter((row) => row.some((cell) => cell.trim() !== ''));
export const handleEmptyColumns = (
  rows: string[][],
  columnIndex: number,
  defaultValue: any,
): string[][] => {
  // replace the value of columnIndex by defaultValue if it is empty
  rows.forEach((row) => {
    if (!row[columnIndex]) {
      row[columnIndex] = defaultValue;
    }
  });

  return rows;
};
