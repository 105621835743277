import { DateTime } from 'luxon';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import Badge from 'components/lib/ui/Badge';
import CollapsibleSection from 'components/lib/ui/CollapsibleSection';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { tryParseJson } from 'common/lib/assistant/parse';
import { parsePromptToMessages } from 'lib/assistant/logs';
import type { LogEvent } from 'lib/assistant/types';

const PRETTY_NAME: { [key: string]: string } = {
  llm_start: 'LLM Request',
  llm_end: 'LLM Response',
  llm_error: 'LLM Error',
  tool_start: 'Tool Request',
  tool_end: 'Tool Response',
  tool_error: 'Tool Error',
  agent_finish: 'Agent Finish',
};

const getTitleForLog = ({ event, data }: LogEvent) => {
  const title = PRETTY_NAME[event] || event;
  let badge: React.ReactNode | null = null;

  if (['tool_start', 'tool_end'].includes(event)) {
    badge = <Badge color="blue">{data.name}</Badge>;
  }

  if (event === 'llm_end') {
    badge = <Badge color="orange">{data.llm_output.model_name}</Badge>;
  }

  return (
    <FlexContainer alignCenter>
      <span>{title}</span>
      {badge}
    </FlexContainer>
  );
};

const StyledCollapsibleSection = styled(CollapsibleSection)`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const SectionContent = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  white-space: pre-wrap;
`;

const Inset = styled.div`
  padding: ${({ theme }) => theme.spacing.default};
  background: ${({ theme }) => theme.color.grayBackground};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  border-radius: ${({ theme }) => theme.radius.medium};
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

const HeaderBadge = styled(Badge).attrs({ color: 'grayFocus' })`
  margin-left: 0;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  display: inline-block;
`;

const LogContent = ({ log: { event, data } }: { log: LogEvent }) => {
  if (event === 'llm_start') {
    const [prompt] = data.prompts;
    const messages = parsePromptToMessages(prompt);

    return (
      <div>
        <HeaderBadge>Prompt Messages</HeaderBadge>
        {messages.map(({ role, content }, i) => (
          <div key={i}>
            <Inset>
              <HeaderBadge>{role.replace(':', '')}</HeaderBadge>
              <div>{content}</div>
            </Inset>
          </div>
        ))}
      </div>
    );
  }

  if (event === 'llm_end') {
    const response = data.generations[0][0].text;
    return (
      <div>
        <HeaderBadge>Response</HeaderBadge>
        <Inset>{response}</Inset>
      </div>
    );
  }

  if (event === 'tool_start') {
    const toolInput = data.input_str;
    const parsedInput = tryParseJson(toolInput);
    return (
      <div>
        <HeaderBadge>Tool Input</HeaderBadge>
        <Inset>
          <code>{parsedInput ? JSON.stringify(parsedInput, null, 2) : toolInput}</code>
        </Inset>
      </div>
    );
  }

  if (event === 'tool_end') {
    const { output } = data;
    return (
      <div>
        <HeaderBadge>Output</HeaderBadge>
        <Inset>{RA.isObject(output) ? JSON.stringify(output, null, 2) : output}</Inset>
      </div>
    );
  }

  if (event === 'agent_finish') {
    const { output } = data;
    return (
      <div>
        <HeaderBadge>Output</HeaderBadge>
        <Inset>{output}</Inset>
      </div>
    );
  }

  return <code>{JSON.stringify(data, null, 2)}</code>;
};

type Props = {
  logs: LogEvent[];
};

const DebugPrettyLogs = ({ logs }: Props) => (
  <>
    {logs.map((log, i) => (
      <StyledCollapsibleSection
        key={i}
        title={getTitleForLog(log)}
        subtitle={DateTime.fromISO(log.timestamp).toLocaleString({
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        })}
      >
        <SectionContent>
          <LogContent log={log} />
        </SectionContent>
      </StyledCollapsibleSection>
    ))}
  </>
);

export default DebugPrettyLogs;
