import * as R from 'ramda';
import React, { useMemo } from 'react';
import type { Column } from 'react-table';

import AllocationTableLoading from 'components/investments/InvestmentsAllocationTableLoading';
import InvestmentsIndicator from 'components/investments/InvestmentsIndicator';
import InvestmentsTableFooterCell from 'components/investments/InvestmentsTableFooterCell';
import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Text from 'components/lib/ui/Text';
import Table from 'components/lib/ui/table/Table';

import { getSecurityTypeColor } from 'common/lib/investments/table';
import type { CleanedAllocationEntry } from 'common/lib/investments/types';
import { formatCurrency } from 'common/utils/Currency';
import useTheme from 'lib/hooks/useTheme';

import { INVESTMENTS_TABLE_EMPTY_STATE } from 'common/constants/copy';

import type { Web_GetAllocation_portfolio_performance } from 'common/generated/graphQlTypes/Web_GetAllocation';

type Performance = Web_GetAllocation_portfolio_performance;

type ColumnConfig = Column<CleanedAllocationEntry>[];
type Props = {
  data: CleanedAllocationEntry[] | undefined;
  meta: Performance | undefined;
  isLoading?: boolean;
  onClickLinkAccount: () => void;
};

const InvestmentsAllocationTable = ({ data = [], meta, isLoading, onClickLinkAccount }: Props) => {
  const theme = useTheme();
  const { color } = theme;

  const columns: ColumnConfig = useMemo(
    () => [
      {
        accessor: 'typeDisplay',
        Header: 'Class',
        Cell: ({ value, row }) => (
          <InvestmentsIndicator
            color={getSecurityTypeColor(row.original.type, theme)}
            primaryText={value}
          />
        ),
      },
      {
        accessor: 'allocationPercent',
        Header: 'Percent',
        Cell: ({ value }) => <Text {...maskClassProps}>{value}%</Text>,
      },
      {
        accessor: 'totalValue',
        Header: 'Total Value',
        Cell: ({ value }) => <Text {...maskClassProps}>{formatCurrency(value)}</Text>,
        Footer: () => (
          <InvestmentsTableFooterCell
            value={meta?.totalValue ?? 0}
            formatter={formatCurrency}
            label="Total value"
            color={color.text}
          />
        ),
      },
    ],
    [meta, color], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const columnNames: string[] = R.reject(
    R.isNil,
    R.map(({ Header }) => Header as string, columns),
  );

  return (
    <>
      {isLoading ? (
        <AllocationTableLoading columnNames={columnNames} />
      ) : (
        <Table
          data={data}
          columns={columns}
          isLoading={isLoading}
          empty={{
            title: INVESTMENTS_TABLE_EMPTY_STATE.TITLE,
            subtitle: INVESTMENTS_TABLE_EMPTY_STATE.SUBTITLE,
            button: {
              title: INVESTMENTS_TABLE_EMPTY_STATE.BUTTON_TITLE,
              onClick: onClickLinkAccount,
            },
          }}
        />
      )}
    </>
  );
};

export default InvestmentsAllocationTable;
