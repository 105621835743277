import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import SubscriptionSetReminderSuccessModal from 'components/settings/billing/reminder/SubscriptionSetReminderSuccessModal';

import { useStackContext } from 'common/lib/contexts/StackContext';
import { track } from 'lib/analytics/segment';
import useTrack from 'lib/hooks/useTrack';

import { CancelFlowEventNames } from 'common/constants/analytics';

import { gql } from 'common/generated/gql';

export const RENEWAL_REMINDER_OPTIONS = [
  { label: '45 days before the renewal date', value: 45 },
  { label: '30 days before the renewal date', value: 30 },
  { label: '15 days before the renewal date', value: 15 },
  { label: '7 days before the renewal date', value: 7 },
];

const SaveButton = styled(FormSubmitButton).attrs({ size: 'small' })``;

const ModalCopy = styled.div``;

const ModalCopyText = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.base};
`;

const RenewalReminderDaysSelect = styled(SelectField)`
  margin: ${({ theme }) => theme.spacing.xxxlarge} 0;
`;

export type FormValues = {
  renewalReminderDays: number;
};

export type Props = {
  goBack?: () => void;
  currentPeriodEndsAt?: string;
  analyticsFreemiumSummaryStatus?: string | null;
};

const SubscriptionSetReminderModal = ({
  goBack,
  currentPeriodEndsAt,
  analyticsFreemiumSummaryStatus,
}: Props) => {
  const { push } = useStackContext();

  const [updateRenewalReminder] = useMutation(UPDATE_RENEWAL_REMINDER);

  useTrack(CancelFlowEventNames.CancelFlowSetReminderModalOpened, {
    analyticsFreemiumSummaryStatus,
  });

  const handleUpdateRenewalReminder = useCallback(
    async (values: FormValues) => {
      track(CancelFlowEventNames.CancelFlowSetReminderModalSaveButtonClicked, {
        analyticsFreemiumSummaryStatus,
      });

      const { data } = await updateRenewalReminder({
        variables: { input: values },
      });

      push(SubscriptionSetReminderSuccessModal, {
        goBack,
        currentPeriodEndsAt,
        renewalReminderDays: data?.updateRenewalReminder?.renewalReminderDays,
      });
    },
    [analyticsFreemiumSummaryStatus, currentPeriodEndsAt, goBack, push, updateRenewalReminder],
  );

  return (
    <ModalCard title="Set Your Reminder Date" onClickBackButton={goBack} hideBottomBorder>
      <Form onSubmit={handleUpdateRenewalReminder}>
        <CardBody>
          <ModalCopy>
            <ModalCopyText>
              Select how many days before your renewal date you&apos;d like to receive a reminder
              email. By default, we&apos;ll send a reminder 45 days prior, but you can personalize
              this timing to suit your needs.
            </ModalCopyText>
          </ModalCopy>

          <RenewalReminderDaysSelect
            name="renewalReminderDays"
            label="Select the date"
            options={RENEWAL_REMINDER_OPTIONS}
          />
        </CardBody>
        <CardFooter>
          <SaveButton>Save</SaveButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

const UPDATE_RENEWAL_REMINDER = gql(/* GraphQL */ `
  mutation Web_UpdateRenewalReminder($input: UpdateRenewalReminderInput!) {
    updateRenewalReminder(input: $input) {
      renewalReminderDays
    }
  }
`);

export default SubscriptionSetReminderModal;
