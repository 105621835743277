import { capitalCase } from 'change-case';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import TextButton from 'components/lib/ui/TextButton';
import BillingRow from 'components/settings/billing/BillingRow';

import type { PaymentMethod } from 'common/generated/graphql';
import { SubscriptionDetailsPaymentSource } from 'common/generated/graphql';

const StyledTextButton = styled(TextButton)`
  margin-left: 10px;
`;

const AlertIcon = styled(Icon).attrs({ name: 'alert-triangle' })`
  color: ${({ theme }) => theme.color.red};
  width: 16px;
  height: 16px;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.color.red};
  margin-top: ${({ theme }) => theme.spacing.default};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

type Props = {
  paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
  paymentMethod: PaymentMethod | null | undefined;
  onClickUpdatePaymentMethod: () => void;
  hasBillingIssue: boolean;
};

const PaymentMethodRow = ({
  paymentSource,
  onClickUpdatePaymentMethod,
  paymentMethod,
  hasBillingIssue,
}: Props) => {
  let billedThroughText = '';
  if (paymentSource === SubscriptionDetailsPaymentSource.STRIPE && R.isNil(paymentMethod)) {
    billedThroughText = 'Missing payment method';
  } else {
    billedThroughText =
      paymentSource === SubscriptionDetailsPaymentSource.STRIPE
        ? `${capitalCase(paymentMethod?.brand || '')} ending in ${paymentMethod?.lastFour}`
        : capitalCase(paymentSource || '');
  }

  const paymentMethodFailedText = `Your payment method failed. ${
    paymentSource === SubscriptionDetailsPaymentSource.STRIPE
      ? 'Please update it to keep using Monarch.'
      : `Please update it in the ${capitalCase(paymentSource || '')} to keep using Monarch`
  }`;

  const paymentMethodMissingText = `Your payment method is missing. ${
    paymentSource === SubscriptionDetailsPaymentSource.STRIPE
      ? 'Please update it to keep using Monarch.'
      : `Please update it in the ${capitalCase(paymentSource || '')} to keep using Monarch`
  }`;

  return (
    <BillingRow
      title={
        paymentSource === SubscriptionDetailsPaymentSource.APP_STORE ||
        paymentSource === SubscriptionDetailsPaymentSource.PLAY_STORE
          ? 'Billed Through'
          : 'Payment Method'
      }
      controls={
        paymentSource === SubscriptionDetailsPaymentSource.STRIPE && (
          <StyledTextButton onClick={onClickUpdatePaymentMethod}>
            Update payment method
          </StyledTextButton>
        )
      }
    >
      <Switch>
        <Case when={!paymentSource}>
          None
          <StyledTextButton onClick={onClickUpdatePaymentMethod}>
            Add payment method
          </StyledTextButton>
        </Case>
        <Case default>
          <FlexContainer column>
            <FlexContainer alignCenter>
              {(hasBillingIssue || (RA.isNotNil(paymentSource) && R.isNil(paymentMethod))) && (
                <AlertIcon />
              )}
              {paymentSource && billedThroughText}
            </FlexContainer>
            {RA.isNotNil(paymentMethod) && hasBillingIssue && (
              <ErrorMessage>{paymentMethodFailedText}</ErrorMessage>
            )}
            {R.isNil(paymentMethod) && hasBillingIssue && (
              <ErrorMessage>{paymentMethodMissingText}</ErrorMessage>
            )}
          </FlexContainer>
        </Case>
      </Switch>
    </BillingRow>
  );
};

export default PaymentMethodRow;
