import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import DateField from 'components/lib/form/DateField';
import Form from 'components/lib/form/Form';
import Confirmation from 'components/lib/ui/Confirmation';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import { UPDATE_ACCOUNT } from 'common/lib/graphQl/accounts';

import type {
  UpdateAccount,
  UpdateAccountVariables,
} from 'common/generated/graphQlTypes/UpdateAccount';

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  background-color: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.default};
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
`;

const List = styled.ul`
  padding-left: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: start;
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const X_CIRCLE_ICON_SIZE_PX = 22;

type Props = {
  account: {
    id: GraphQlUUID;
  };
  onCancel: () => void;
  onDone: () => void;
};

const CloseAccountConfirmation = ({ account, onCancel, onDone }: Props) => {
  const [updateAccount] = useMutation<UpdateAccount, UpdateAccountVariables>(UPDATE_ACCOUNT, {
    onCompleted: onDone,
  });

  return (
    <Form
      initialValues={{
        id: account.id,
        deactivatedAt: DateTime.local().toISODate(),
      }}
      mutation={updateAccount}
    >
      <FormContext.Consumer>
        {({ handleSubmit, isSubmitting }) => (
          <Confirmation
            title="Are you sure you want to close account?"
            confirm="Close account"
            isLoading={isSubmitting}
            onCancel={onCancel}
            onConfirm={handleSubmit}
            useDangerButton
          >
            <DateField
              displayFormat="MMMM D, yyyy"
              name="deactivatedAt"
              label="Date account closed"
              placeholder="Select a date..."
              maxDate={DateTime.local().toJSDate()}
            />
            <Content>
              <Text weight="medium">What will change:</Text>
              <List>
                <ListItem>
                  <StyledIcon name="x-circle" size={X_CIRCLE_ICON_SIZE_PX} />
                  <Text>
                    Account will no longer sync with bank and get new transactions and balances
                  </Text>
                </ListItem>
                <ListItem>
                  <StyledIcon name="x-circle" size={X_CIRCLE_ICON_SIZE_PX} />
                  <Text>
                    Balance will be set to $0.00 on the date selected and historical balances will
                    still show in net worth.
                  </Text>
                </ListItem>
              </List>
            </Content>
          </Confirmation>
        )}
      </FormContext.Consumer>
    </Form>
  );
};

export default CloseAccountConfirmation;
