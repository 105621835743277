import React from 'react';

import EmptyCreateCard from 'components/lib/ui/EmptyCreateCard';
import PremiumUpgradeFlow from 'components/premium/PremiumUpgradeFlow';

import useTrialStatusQuery from 'common/lib/hooks/premium/useTrialStatusQuery';
import useModal from 'lib/hooks/useModal';

import * as COPY from 'common/constants/copy';
import { ProductFeature, POPOVER_COPY } from 'common/constants/premium';

const ICON_FOR_FEATURE: { [feature in ProductFeature]?: string } = {
  [ProductFeature.tags]: 'tag',
  [ProductFeature.investments]: 'trending-up',
};

type Props = {
  feature: ProductFeature;
  useDescriptionAsTitle?: boolean;
  className?: string;
};

const PremiumFeatureBlockingOverlay = ({ feature, useDescriptionAsTitle, className }: Props) => {
  const { title = '', description = '' } = POPOVER_COPY[feature] ?? {};

  const { hasPremiumTrialAvailable } = useTrialStatusQuery();

  const [UpgradeModal, { open: openModal, close: closeModal }] = useModal();

  return (
    <>
      <EmptyCreateCard
        className={className}
        title={useDescriptionAsTitle ? description : title}
        subtitle={useDescriptionAsTitle ? undefined : description}
        icon={ICON_FOR_FEATURE[feature] ?? ''}
        button={{
          icon: 'diamond',
          text: hasPremiumTrialAvailable
            ? COPY.PREMIUM.UPGRADE_CTA.TRIAL_AVAILABLE
            : COPY.PREMIUM.UPGRADE_CTA.TRIAL_UNAVAILABLE,
          onClick: openModal,
        }}
      />
      <UpgradeModal>
        <PremiumUpgradeFlow analyticsName={feature} onBack={closeModal} onComplete={closeModal} />
      </UpgradeModal>
    </>
  );
};

export default PremiumFeatureBlockingOverlay;
