import React from 'react';
import styled from 'styled-components';

import { useTab } from 'common/components/tabs/TabsContext';

type Props = {
  index: number;
  underline?: boolean;
  children?: React.ReactNode;
  className?: string;
};

const Root = styled.div<{ isActive: boolean }>`
  --color: ${({ theme }) => theme.color.textLight};
  --activeColor: ${({ theme }) => theme.color.orange};
  --activeBackgroundColor: unset;

  transition: ${({ theme }) => theme.transition.default};
  color: ${({ isActive }) => (isActive ? 'var(--activeColor)' : 'var(--color)')};
  background-color: ${({ isActive }) =>
    isActive ? 'var(--activeBackgroundColor)' : 'transparent'};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  user-select: none;
`;

const Underline = styled.div<{ isActive: boolean }>`
  margin-top: ${({ theme }) => theme.spacing.small};
  height: 2px;
  width: 100%;
  transition: background 0.2s ease-out;
  background: ${({ isActive }) => (isActive ? 'var(--activeColor)' : 'transparent')};
`;

const Tab = ({ index, underline = true, children, className }: Props) => {
  const [isActive, setActive] = useTab(index);

  return (
    <Root isActive={isActive} onClick={setActive} className={className}>
      {children}
      {underline && <Underline isActive={isActive} />}
    </Root>
  );
};

export default Tab;
