import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import type {
  PaymentMethod,
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
} from '@stripe/stripe-js';
import * as RA from 'ramda-adjunct';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useIsOnAdvisorOnboarding } from 'components/advisorOnboarding/context/AdvisorOnboardingProvider';
import Divider from 'components/lib/ui/Divider';
import Text from 'components/lib/ui/Text';

import { track } from 'lib/analytics/segment';

import { AnalyticsEventNames } from 'common/constants/analytics';
import { CENTS_PER_DOLLAR } from 'common/constants/currency';

const PaymentRequestButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.default};
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

const StyledText = styled(Text)`
  display: block;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const StyledDivider = styled(Divider)`
  margin-top: ${({ theme }) => theme.spacing.large};
`;

const DEFAULT_PAYMENT_REQUEST_OPTIONS = {
  country: 'US',
  currency: 'usd',
  total: {
    label: 'Total',
    // This value is irrelevant and used only as a placeholder.
    // The actual value will be set later based on props.
    amount: 100,
  },
  requestPayerName: true,
  requestPayerEmail: true,
};

type Props = {
  selectedPlanAmount?: number | null;
  daysLeftOfTrial?: number | null;
  selectedPlanName?: string | null;
  onPaymentMethodSelected?: (paymentMethod: PaymentMethod) => Promise<void> | void;
};

const PayWithWalletButton = ({
  selectedPlanAmount,
  selectedPlanName,
  daysLeftOfTrial,
  onPaymentMethodSelected,
}: Props) => {
  const stripe = useStripe();
  const isOnAdvisorOnboarding = useIsOnAdvisorOnboarding();

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  const [isPaymentRequestReady, setPaymentRequestReady] = useState<boolean>(false);

  useEffect(() => {
    const setupPaymentRequest = async () => {
      const pr = stripe!.paymentRequest(DEFAULT_PAYMENT_REQUEST_OPTIONS);
      pr.on(
        'paymentmethod',
        async ({ paymentMethod, complete }: PaymentRequestPaymentMethodEvent) => {
          onPaymentMethodSelected?.(paymentMethod);
          complete('success');
          track(AnalyticsEventNames.PayWithWalletPaymentMethodSelected, {
            wallet: await getAvailableWalletFromPaymentRequest(pr),
          });
        },
      );
      pr.on('cancel', async () => {
        track(AnalyticsEventNames.PayWithWalletCanceled, {
          wallet: await getAvailableWalletFromPaymentRequest(pr),
        });
      });
      setPaymentRequest(pr);
    };
    if (stripe) {
      setupPaymentRequest();
    }

    return () => {
      if (paymentRequest) {
        // @ts-ignore
        paymentRequest.removeAllListeners();
      }
    };
  }, [stripe]);

  // Whenever the plan name or amount changes, set a flag indicating that
  // the PaymentRequest object is not ready for rendering, which should trigger
  // an update of the PaymentRequest.
  useEffect(() => {
    setPaymentRequestReady(false);
  }, [selectedPlanAmount, selectedPlanName]);

  useEffect(() => {
    const updatePaymentRequest = async () => {
      if (paymentRequest && RA.isNotNil(selectedPlanName) && RA.isNotNil(selectedPlanAmount)) {
        const paymentRequestOptions = getPaymentRequestOptionsFromPlanDetails(
          selectedPlanName,
          selectedPlanAmount,
          daysLeftOfTrial ?? 0,
        );
        paymentRequest.update(paymentRequestOptions);

        const result: any = await paymentRequest.canMakePayment();

        if (result) {
          setPaymentRequestReady(true);
          track(AnalyticsEventNames.PayWithWalletButtonViewed, {
            wallet: getAvailableWalletFromCanMakePaymentResult(result),
          });
        }
      }
    };
    if (!isPaymentRequestReady) {
      updatePaymentRequest();
    }
  }, [paymentRequest, isPaymentRequestReady]);

  return RA.isNotNil(paymentRequest) && isPaymentRequestReady ? (
    <PaymentRequestButtonContainer>
      <PaymentRequestButtonElement options={{ paymentRequest: paymentRequest! }} />
      <>
        {(daysLeftOfTrial ?? 0) > 0 && !isOnAdvisorOnboarding && (
          <StyledText>
            {`We'll email you before you ever get charged, and you can cancel anytime during the ${daysLeftOfTrial}-day trial.`}
          </StyledText>
        )}
        <StyledDivider text="or" />
      </>
    </PaymentRequestButtonContainer>
  ) : null;
};

type WalletType = 'googlePay' | 'applePay' | 'link';

const getAvailableWalletFromPaymentRequest = async (pr: PaymentRequest | null) => {
  const result: any = await pr?.canMakePayment();
  return getAvailableWalletFromCanMakePaymentResult(result ?? {});
};

const getAvailableWalletFromCanMakePaymentResult = (result: {
  [k: string]: boolean;
}): WalletType | undefined =>
  Object.keys(result).find((walletName) => result[walletName] === true) as WalletType;

const getPaymentRequestOptionsFromPlanDetails = (
  selectedPlanName: string,
  selectedPlanAmount: number,
  daysLeftOfTrial: number,
) => {
  const amount = Math.trunc(CENTS_PER_DOLLAR * selectedPlanAmount);
  return {
    displayItems: [
      {
        label: `Monarch ${selectedPlanName}`,
        amount,
      },
    ],
    total: {
      label: (daysLeftOfTrial ?? 0) > 0 ? `Total (billed in ${daysLeftOfTrial}d)` : 'Total',
      amount,
    },
  };
};

export default PayWithWalletButton;
