import React from 'react';

import Confirmation from 'components/lib/ui/Confirmation';
import DangerButton from 'components/lib/ui/button/DangerButton';

import useDeleteMessageThread from 'common/lib/hooks/assistant/useDeleteMessageThread';
import useModal from 'lib/hooks/useModal';

import * as COPY from 'common/constants/copy';

type Props = {
  threadId: string;
};

const MessageThreadControls = ({ threadId }: Props) => {
  const [ConfirmDeleteModal, { open: openConfirmDelete, close: closeConfirmDelete }] = useModal();

  const [deleteMessageThread, { loading }] = useDeleteMessageThread({
    refetchQueries: ['Web_GetMessageThreads'],
    awaitRefetchQueries: true,
    onCompleted: closeConfirmDelete,
  });

  return (
    <>
      <DangerButton onClick={openConfirmDelete}>Delete</DangerButton>
      <ConfirmDeleteModal>
        <Confirmation
          title={COPY.ASSISTANT.DELETE_THREAD_CONFIRMATION.TITLE}
          confirm="Delete"
          useDangerButton
          isLoading={loading}
          onConfirm={() =>
            deleteMessageThread({
              variables: {
                id: threadId,
              },
            })
          }
          onCancel={closeConfirmDelete}
        >
          {COPY.ASSISTANT.DELETE_THREAD_CONFIRMATION.DESCRIPTION}
        </Confirmation>
      </ConfirmDeleteModal>
    </>
  );
};

export default MessageThreadControls;
