import React from 'react';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import CardTitle from 'components/lib/ui/CardTitle';
import Skeleton from 'components/lib/ui/Skeleton';

const Root = styled(Card)`
  padding: ${({ theme }) => theme.spacing.xlarge};
  text-align: center;
  gap: ${({ theme }) => theme.spacing.xsmall};
  justify-content: center;
  align-items: center;
`;

const Value = styled.span`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

type Props = {
  value: React.ReactNode;
  label: string;
  className?: string;

  // Styling
  valueColor?: string;
  isLoading?: boolean;
};

const StatisticCard = ({ value, label, className, isLoading }: Props) => (
  <Root className={className}>
    {isLoading ? <Skeleton $width="80px" $height="24px" /> : <Value>{value}</Value>}
    <CardTitle>{label}</CardTitle>
  </Root>
);

export default StatisticCard;
