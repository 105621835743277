import { createAction } from 'typesafe-actions';

import type WindowSize from 'types/WindowSize';

const windowDidResize = createAction('UI/WINDOW_DID_RESIZE')<{
  currentSize: WindowSize;
  previousSize?: WindowSize;
}>();

export default windowDidResize;
