export const MOCK_TRANSACTIONS = [
  {
    category: {
      name: 'Restaurants',
      icon: '🍽',
    },
    merchant: {
      name: 'The Cheesecake Factory',
    },
  },
  {
    category: {
      name: 'Shopping',
      icon: '🛍',
    },
    merchant: {
      name: 'Target',
    },
  },
  {
    category: {
      name: 'Groceries',
      icon: '🥕',
    },
    merchant: {
      name: 'Walmart',
    },
  },
  {
    category: {
      name: 'Personal Care',
      icon: '👑',
    },
    merchant: {
      name: 'Canyon Ranch',
    },
  },
  {
    category: {
      name: 'Coffee',
      icon: '☕️',
    },
    merchant: {
      name: 'Starbucks',
    },
  },
  {
    category: {
      name: 'Subscriptions',
      icon: '🗓',
    },
    merchant: {
      name: 'Spotify',
    },
  },

  {
    category: {
      name: 'Insurance',
      icon: '🏥',
    },
    merchant: {
      name: 'Blue Cross Blue Shield',
    },
  },
];
