import _ from 'lodash';
import React, { useCallback } from 'react';

import type { FormContextType } from 'common/components/form/FormContext';
import FormItemContainer from 'components/lib/form/FormItemContainer';

import type { ValidationOptions } from 'common/lib/form/validation';
import useField from 'common/lib/hooks/form/useField';

export type ChildrenRenderProps = {
  id: string;
  value: any;
  displayErrors: string[];
} & Pick<FormContextType<any>, 'handleChange' | 'handleBlur' | 'setFieldValue' | 'setFieldTouched'>;

export type AbstractFieldProps<InputPropsT, TValue = any> = Omit<
  InputPropsT,
  'onChange' | 'value' | 'id'
> &
  ValidationOptions & {
    name: string;
    label?: string;
    hideLabel?: boolean;
    description?: string | React.ReactNode;
    labelRight?: React.ReactNode;
    children?: (props: ChildrenRenderProps) => React.ReactNode;
    className?: string;
    /** Called when this field's value in FormContext changes. Use this sparingly, most state should
     * only be kept in Form and accessed using useContext(FormContext) instead. */
    onChange?: (value: TValue) => void;
    /** Hide all client side validation errors for this field, but still show field-level errors from backend after submit */
    onlyShowApiErrors?: boolean;
    innerRef?: React.Ref<any>;
  } & Pick<React.ComponentProps<typeof FormItemContainer>, 'tooltip' | 'tooltipProps'>;

const AbstractField = <InputPropsT extends Record<string, unknown>>(
  props: AbstractFieldProps<InputPropsT>,
) => {
  const {
    name,
    label = _.startCase(name),
    hideLabel,
    description,
    className,
    tooltip,
    tooltipProps,
    children,
    labelRight,
  } = props;

  const childrenProps = useField(props);
  const { displayErrors, id } = childrenProps;
  const renderChildren = useCallback((childrenProps: any) => children?.(childrenProps), [children]);

  return (
    <FormItemContainer
      key={name}
      label={label}
      hideLabel={hideLabel}
      description={description}
      errors={displayErrors}
      name={name}
      fieldId={id}
      tooltip={tooltip}
      tooltipProps={tooltipProps}
      className={className}
      labelRight={labelRight}
    >
      {renderChildren(childrenProps)}
    </FormItemContainer>
  );
};

export default AbstractField;
