import React from 'react';
import styled from 'styled-components';

import AnimatedProgressSteps from 'components/accounts/AnimatedProgressSteps';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';

const ANIMATION_HEIGHT_PX = 218;

const STEPS = [
  'Reviewing your answers',
  'Analyzing your financial data',
  'Building personalized advice',
  'Prioritizing advice',
  'Wrapping up your plan',
];

const Container = styled(FlexContainer).attrs({ center: true })`
  padding: ${({ theme }) => theme.spacing.xxlarge} ${({ theme }) => theme.spacing.xlarge}
    ${({ theme }) => theme.spacing.xxxxlarge} ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  onAnimationEnd: () => void;
};

const AdviceQuestionnaireLoadingModal = ({ onAnimationEnd }: Props) => (
  <ModalCard>
    <Container>
      <AnimatedProgressSteps
        steps={STEPS}
        height={ANIMATION_HEIGHT_PX}
        onAnimationEnd={onAnimationEnd}
      />
    </Container>
  </ModalCard>
);

export default AdviceQuestionnaireLoadingModal;
