import * as R from 'ramda';
import React from 'react';
import type { ValueType, ActionMeta } from 'react-select';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import type { Props as SelectProps } from 'components/lib/form/Select';
import Select from 'components/lib/form/Select';

import type { OptionType } from 'types/select';
import { isOptionsType } from 'types/select';

export type SelectConformance<T extends OptionType = OptionType> = Pick<
  SelectProps<T>,
  'id' | 'onBlur'
> & {
  onChange: (value: ValueType<T> | null, action?: ActionMeta) => void;
  value: T;
};

export type Props<T extends OptionType = OptionType> = AbstractFieldProps<SelectProps<T>> & {
  InputComponent?: (props: SelectConformance<T>) => JSX.Element;
  afterChange?: (value: ValueType<T>) => void;
};

const SelectField = <T extends OptionType = OptionType>({
  className,
  onBlur,
  InputComponent = Select,
  afterChange,
  ...props
}: Props<T>) => (
  <AbstractField {...props} className={className}>
    {({ id, value, setFieldValue, handleBlur, handleChange }) => (
      <InputComponent
        {...props}
        id={id}
        onBlur={(e) => {
          e && handleBlur(e);
          onBlur?.(e);
        }}
        onChange={(selectValue, action) => {
          handleChange({ target: { value: selectValue } });

          let newValue;

          // When value changes to null and action is remove-value, it's a multi-select that was cleared
          // More at: https://react-select.com/advanced#action-meta
          if (selectValue === null && action?.action === 'remove-value') {
            newValue = [];
          } else {
            // react-select gives us {value: string; label: string} here but we only want to save value in state
            // If it's a multi select, we store an array of values
            newValue = isOptionsType(selectValue)
              ? selectValue.map(({ value }) => value)
              : selectValue?.value ?? selectValue;
          }

          if (R.equals(newValue, value)) {
            return;
          } else {
            setFieldValue(props.name, newValue);
          }

          afterChange?.(selectValue);
        }}
        value={value}
      />
    )}
  </AbstractField>
);

export default SelectField;
