import React from 'react';
import styled from 'styled-components';

import CheckAnimation from 'components/lib/ui/CheckAnimation';
import Empty from 'components/lib/ui/Empty';

import { TRANSACTIONS } from 'common/constants/copy';

const Root = styled(Empty)`
  padding: ${({ theme }) => theme.spacing.xxxlarge};
`;

type Props = {
  onClickViewAll: () => void;
  className?: string;
};

const NeedsReviewEmpty = ({ onClickViewAll, className }: Props) => (
  <Root
    icon={<CheckAnimation size={72} />}
    title={TRANSACTIONS.NEEDS_REVIEW_EMPTY_TITLE}
    subtitle={TRANSACTIONS.NEEDS_REVIEW_EMPTY_SUBTITLE}
    button={{ title: 'View all transactions', onClick: onClickViewAll }}
    className={className}
  />
);

export default NeedsReviewEmpty;
