import { shade } from 'polished';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import Card from 'components/lib/ui/Card';
import Dot from 'components/lib/ui/Dot';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';

import type { ValidationOptions } from 'common/lib/form/validation';
import fieldStyleMixin from 'lib/styles/fieldStyleMixin';

const INPUT_DEFAULT_WIDTH_PX = 45;

type Props = Pick<
  React.HTMLProps<HTMLInputElement>,
  'type' | 'onBlur' | 'autoFocus' | 'onFocus' | 'onClick' | 'placeholder' | 'id' | 'defaultValue'
> &
  Pick<ValidationOptions, 'name'> & {
    colors: readonly string[];
    value?: string;
    dismissOnChange?: boolean;
    className?: string;
    onChange?: (color: string) => void;
  };

const Input = styled.input`
  ${fieldStyleMixin}
  cursor: pointer;
  width: 100%;
`;

const ColorList = styled.div<{ $size: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $size }) => Math.round($size / 2)}, 1fr);
  padding: ${({ theme }) => theme.spacing.default};
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const ColorDot = styled(Dot).attrs({ size: 24 })`
  cursor: pointer;

  &:hover {
    background-color: ${({ color }) => (color ? shade(0.05, color) : 'transparent')};
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: ${INPUT_DEFAULT_WIDTH_PX}px;

  ${ColorDot} {
    --size: ${INPUT_DEFAULT_WIDTH_PX / 2.5}px;

    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
`;

const ColorSelectField = ({
  colors,
  className,
  type = 'text',
  dismissOnChange = true,
  ...props
}: AbstractFieldProps<Props>) => (
  <AbstractField className={className} {...props} hideLabel>
    {({ id, value, handleBlur, setFieldValue }) => (
      <OverlayTrigger
        placement="bottom-start"
        overlay={({ close }) => (
          <Popover>
            <Card>
              <ColorList $size={colors.length}>
                {colors.map((color) => (
                  <ColorDot
                    id={id}
                    key={color}
                    color={color}
                    onClick={(e) => {
                      setFieldValue(props.name, color);
                      handleBlur(e);
                      props.onChange?.(color);
                      if (dismissOnChange) {
                        close();
                      }
                    }}
                  />
                ))}
              </ColorList>
            </Card>
          </Popover>
        )}
      >
        {({ open }) => (
          <InputWrapper>
            <Input
              {...R.omit(['children'], props)}
              autoComplete="off"
              value=""
              id={id}
              type={type}
              name={props.name}
              onClick={(e) => {
                open();
                props.onClick?.(e);
              }}
              onFocus={(e) => {
                open();
                props.onFocus?.(e);
              }}
              onBlur={handleBlur}
              readOnly
            />
            <ColorDot color={value} />
          </InputWrapper>
        )}
      </OverlayTrigger>
    )}
  </AbstractField>
);

export default ColorSelectField;
