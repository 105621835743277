import { gql } from '@apollo/client';

import useQuery from 'common/lib/hooks/useQuery';

import type { GetTrialStatus } from 'common/generated/graphQlTypes/GetTrialStatus';

const useTrialStatusQuery = () => {
  const { data } = useQuery<GetTrialStatus>(QUERY, {
    fetchPolicy: 'cache-first', // This is used in a lot of places and shouldn't change, so just use cache-first
  });
  const { subscription } = data ?? {};
  const {
    eligibleForTrial = true, // default to true if backend hasn't loaded yet
    trialDurationDays = 7, // default to 7 if backend hasn't loaded yet
  } = subscription ?? {};

  return {
    hasPremiumTrialAvailable: eligibleForTrial,
    trialDurationDays,
  };
};

const QUERY = gql`
  query GetTrialStatus {
    subscription {
      id
      eligibleForTrial
      trialDurationDays
    }
  }
`;

export default useTrialStatusQuery;
