export const TRANSACTIONS_LIST_QUERY_NAME = 'Web_GetTransactionsList';
export const TRANSACTIONS_PAGE_QUERY_NAME = 'Web_GetTransactionsPage';
export const FILTERED_CASH_FLOW_PAGE_QUERY_NAME = 'Web_GetFilteredCashFlowPage';
const TRANSACTIONS_DASHBOARD_QUERY_NAME = 'GetTransactionsListDashboard';
const TRANSACTIONS_DASHBOARD_REVIEW_SELECT = 'Common_GetHouseholdMembers';
export const ACCOUNT_DETAILS_QUERY_NAME = 'AccountDetails_getAccount';
const GET_SUBSCRIPTION_QUERY_NAME = 'GetSubscription';
const REPORTS_QUERY = 'Common_GetReportsData';

/** Queries that should be refetched when a transaction's name or category is updated */
export const UPDATE_TRANSACTION_REFETCH_QUERIES = [
  TRANSACTIONS_PAGE_QUERY_NAME,
  FILTERED_CASH_FLOW_PAGE_QUERY_NAME,
  TRANSACTIONS_DASHBOARD_QUERY_NAME,
  TRANSACTIONS_DASHBOARD_REVIEW_SELECT,
  REPORTS_QUERY,
];

export const ACCOUNT_DETAILS_TRANSACTIONS_REFETCH_QUERIES = [
  ACCOUNT_DETAILS_QUERY_NAME,
  TRANSACTIONS_LIST_QUERY_NAME,
];

export const CHANGE_SUBSCRIPTION_REFETCH_QUERIES = [GET_SUBSCRIPTION_QUERY_NAME];
