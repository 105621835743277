import { getType } from 'typesafe-actions';

import { logout } from 'common/state/user/actions';
import ApolloClient from 'lib/graphQl/ApolloClient';
import { persistor } from 'state/store';
import type { Middleware } from 'state/types';

const authenticationMiddleware: Middleware = (store) => (next) => (action) => {
  const { type } = action;
  if (type === getType(logout)) {
    ApolloClient.clearStore();
    // Clear data from redux-persist that might have been left
    persistor.purge();
  }
  return next(action);
};

export default authenticationMiddleware;
