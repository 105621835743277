import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import TextInput from 'components/lib/form/TextInput';
import CardEmpty from 'components/lib/ui/CardEmpty';
import FlexContainer from 'components/lib/ui/FlexContainer';
import SectionHeader from 'components/lib/ui/SectionHeader';
import SecuritySearchListItem from 'components/securities/SecuritySearchListItem';
import SecuritySearchListItemSkeleton from 'components/securities/SecuritySearchListItemSkeleton';

import { SEARCH_SECURITIES_QUERY } from 'common/lib/graphQl/investments';
import useDebounce from 'common/lib/hooks/useDebounce';
import useQuery from 'common/lib/hooks/useQuery';
import HighlightTextContext from 'lib/contexts/HighlightTextContext';

import { TYPEAHEAD_DEBOUNCE_TIME_MS } from 'common/constants/form';

import type {
  SecuritySearch,
  SecuritySearchVariables,
  SecuritySearch_securities,
} from 'common/generated/graphQlTypes/SecuritySearch';

const Root = styled(FlexContainer)`
  flex-direction: column;
  min-height: 300px;
`;

const SearchInputContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: 0;
`;

const SearchInput = styled(TextInput)`
  width: 100%;
`;

const StyledEmpty = styled(CardEmpty)`
  margin: ${({ theme }) => theme.spacing.xlarge} auto ${({ theme }) => theme.spacing.xxxlarge};
`;

const StyledLoadingItem = styled(SecuritySearchListItemSkeleton)`
  padding: ${({ theme }) => theme.spacing.small} 0 0 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const isSearchValid = RA.lengthGte(1);
const SEARCH_SECURITIES_QUERY_QUERY_LIMIT = 5;

type Props = {
  title?: string;
  onSecurityClick: (id: string) => void;
};

const SecuritySearchModalCard = ({ title = 'Add holding', onSecurityClick }: Props) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, TYPEAHEAD_DEBOUNCE_TIME_MS);

  const { data, isNetworkRequestInFlight } = useQuery<SecuritySearch, SecuritySearchVariables>(
    SEARCH_SECURITIES_QUERY,
    {
      variables: {
        search: debouncedSearch,
        limit: SEARCH_SECURITIES_QUERY_QUERY_LIMIT,
        orderByPopularity: true,
      },
    },
  );

  const securities = R.pathOr<SecuritySearch_securities[]>([], ['securities'], data);

  const groupedSecurities = useMemo<{ [key: string]: SecuritySearch_securities[] }>(
    () =>
      isSearchValid(debouncedSearch)
        ? R.groupBy((el) => el.typeDisplay, securities)
        : { 'Popular holdings': securities },
    [securities, debouncedSearch],
  );

  return (
    <Root>
      <SearchInputContainer>
        <SearchInput
          name="security"
          placeholder="Search for a company or ticker symbol..."
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { target } = e;
            setSearch(target.value);
          }}
          autoFocus
        />
      </SearchInputContainer>
      <Switch>
        <Case when={isNetworkRequestInFlight}>
          {R.range(0, 3).map((i) => (
            <StyledLoadingItem key={i} />
          ))}
        </Case>
        <Case when={RA.isNotEmpty(securities)}>
          <HighlightTextContext.Provider
            value={{ searchWords: debouncedSearch ? [debouncedSearch] : [] }}
          >
            {Object.keys(groupedSecurities).map((k) => (
              <>
                <SectionHeader>{k}</SectionHeader>
                {groupedSecurities[k].map(
                  ({
                    id,
                    name,
                    logo,
                    ticker,
                    currentPrice,
                    closingPrice,
                    oneDayChangeDollars,
                    oneDayChangePercent,
                  }) => (
                    <SecuritySearchListItem
                      key={id}
                      name={name}
                      ticker={ticker}
                      logo={logo}
                      currentPrice={currentPrice}
                      closingPrice={closingPrice}
                      oneDayChangeDollars={oneDayChangeDollars}
                      oneDayChangePercent={oneDayChangePercent}
                      onClick={() => onSecurityClick(id)}
                    />
                  ),
                )}
              </>
            ))}
          </HighlightTextContext.Provider>
        </Case>
        <Case when={!isSearchValid(debouncedSearch)}>
          <StyledEmpty
            icon="search"
            title="Search for a company or ticker symbol..."
            subtitle="Enter a search term above to find securities."
          />
        </Case>
        <Case when={R.isEmpty(securities) && RA.isNotEmpty(search) && !isNetworkRequestInFlight}>
          <StyledEmpty
            icon="search"
            title={`Couldn't find any securities matching "${search}"`}
            subtitle="Enter a search term above to find securities."
          />
        </Case>
        <Case default>
          <StyledEmpty
            icon="search"
            title="Search for a company or ticker symbol..."
            subtitle="Enter a search term above to find securities."
          />
        </Case>
      </Switch>
    </Root>
  );
};

export default SecuritySearchModalCard;
