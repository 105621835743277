import React from 'react';
import styled from 'styled-components';

import GoalContributionGraph from 'components/goalsV2/GoalContributionGraph';
import Card from 'components/lib/ui/Card';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import type { WebGoalContributionGraphFieldsFragment } from 'common/generated/graphql';

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: ${({ theme }) => theme.spacing.xlarge};
  align-self: center;
`;

type Props = {
  goal: WebGoalContributionGraphFieldsFragment | null | undefined;
  isLoading: boolean;
};

const GoalContributionsCard = ({ goal, isLoading }: Props) => (
  <Card title="Contributions">
    {isLoading && <StyledLoadingSpinner />}
    {goal && <GoalContributionGraph goal={goal} />}
  </Card>
);

export default GoalContributionsCard;
