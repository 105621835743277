import React from 'react';
import styled from 'styled-components';

import ModalCard from 'components/lib/ui/ModalCard';

import useGetProductBoardToken from 'common/lib/hooks/auth/useGetProductBoardToken';
import getStringEnvVar from 'lib/getStringEnvVar';

const RequestFeatureBody = styled.iframe`
  height: 80vh;
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const RequestFeatureModalCard = () => {
  const productBoardToken = useGetProductBoardToken();

  return (
    <ModalCard hideCloseButton hideHeader>
      {productBoardToken && (
        <RequestFeatureBody
          title="request-feature"
          src={`${getStringEnvVar(
            'REACT_APP_REQUEST_FEATURE_IFRAME_URL',
          )}?token=${productBoardToken}`}
          frameBorder="0"
        />
      )}
    </ModalCard>
  );
};

export default RequestFeatureModalCard;
