import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { formatDiscountOfferLong } from 'common/lib/billing/Billing';
import { formatCurrency } from 'common/utils/Currency';
import { useModalContext } from 'lib/contexts/ModalContext';

import { DEFAULT_ERROR_MESSAGE } from 'common/constants/copy';

import { gql } from 'common/generated/gql';
import type { CancellationReason } from 'common/generated/graphql';

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  reason: CancellationReason;
};

/**
 * This is part of the new cancellation flow which is behind the feature flag: web-cancel-discount-flow
 */
const CancelSubscriptionAcceptOfferModal = ({ reason }: Props) => {
  const { close } = useModalContext();

  const [applyOffer, { data, loading }] = useMutation(MUTATION);
  const { applyStripeCancelDiscountOffer } = data ?? {};
  const { subscription, discountOffer, errors } = applyStripeCancelDiscountOffer ?? {};

  useEffect(() => {
    applyOffer({
      variables: {
        input: {
          reason,
        },
      },
    });
  }, []);

  if (loading) {
    return (
      <ModalCard title="Applying your discount...">
        <CardBody>
          <FlexContainer center>
            <LoadingSpinner />
          </FlexContainer>
        </CardBody>
      </ModalCard>
    );
  }

  if (!subscription || !discountOffer || errors || !subscription?.billingPeriod) {
    // Error state
    return (
      <ModalCard title="Something went wrong">
        <CardBody>{DEFAULT_ERROR_MESSAGE}</CardBody>
      </ModalCard>
    );
  }

  const { nextPaymentAmount, currentPeriodEndsAt, billingPeriod } = subscription;

  const formattedPeriodEnd =
    currentPeriodEndsAt && DateTime.fromISO(currentPeriodEndsAt).toLocaleString(DateTime.DATE_MED);

  return (
    <ModalCard title="Discount applied">
      <CardBody>
        <div>Great! You&apos;ve got {formatDiscountOfferLong(discountOffer, billingPeriod)}.</div>
        <div>
          Your next payment will be {formatCurrency(nextPaymentAmount)} on {formattedPeriodEnd}.
        </div>
      </CardBody>
      <CardFooter justifyEnd>
        <PrimaryButton onClick={close}>Done</PrimaryButton>
      </CardFooter>
    </ModalCard>
  );
};

const MUTATION = gql(`
  mutation ApplyStripeCancelDiscountOffer($input: ApplyStripeCancelDiscountOfferInput!) {
    applyStripeCancelDiscountOffer(input: $input) {
      discountOffer {
        discountPercent
        duration
        durationInMonths
      }
      subscription {
        id
        ...SubscriptionFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export default CancelSubscriptionAcceptOfferModal;
