import * as R from 'ramda';

import type { Web_GetMessageThread_messageThread_messages } from 'common/generated/graphQlTypes/Web_GetMessageThread';

type Message = {
  __typename: Web_GetMessageThread_messageThread_messages['__typename'];
  user?: {
    id: string;
  };
};

const messagesBelongInSameGroup = (a: Message, b: Message): boolean => {
  if (a.__typename !== 'UserMessage' && b.__typename !== 'UserMessage') {
    // Assistant messages are always grouped together (both status and final answer)
    return true;
  }

  if (a.__typename !== b.__typename) {
    // If one user message and one assistant message, they are not grouped together
    return false;
  }

  // User messages are grouped together if they are from the same user
  const isSameUser = a.user?.id === b.user?.id;

  return isSameUser;
};

/**
 * Groups a single list of messages into a list of lists of messages that should
 * be displayed in the same "group". For example, multiple assistant messages in
 * chronological order should be grouped together, so only one avatar / timestamp
 * is shown, with multiple bubbles for each individual message.
 */
export const groupMessages = <T extends Message>(messages: T[]): T[][] =>
  messages.reduce((acc, el) => {
    const lastGroup = R.last(acc);

    if (!lastGroup || !messagesBelongInSameGroup(lastGroup[0], el)) {
      return [...acc, [el]];
    }

    return R.append([...lastGroup, el], R.init(acc));
  }, [] as T[][]);
