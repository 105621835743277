import { gql } from '@apollo/client';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

export const CREATE_MX_CONNECT_URL_MUTATION = gql`
  mutation Common_CreateMXConnectUrlMutation(
    $mxInstitutionId: String = null
    $clientRedirectUrl: String = null
    $isDarkMode: Boolean
    $isMobileWebview: Boolean
  ) {
    createMxConnectUrl(
      mxInstitutionId: $mxInstitutionId
      isDarkMode: $isDarkMode
      isMobileWebview: $isMobileWebview
      clientRedirectUrl: $clientRedirectUrl
    ) {
      url
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const CREATE_MX_CONNECT_FIX_URL_MUTATION = gql`
  mutation Common_CreateMXConnectFixUrlMutation(
    $credentialId: UUID!
    $isDarkMode: Boolean
    $isMobileWebview: Boolean
  ) {
    createMxConnectFixUrl(
      credentialId: $credentialId
      isDarkMode: $isDarkMode
      isMobileWebview: $isMobileWebview
    ) {
      url
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const CREATE_MX_CREDENTIALS_MUTATION = gql`
  mutation Common_CreateMXCredentialsMutation {
    createMxCredentials {
      credentials {
        id
        accounts {
          id
        }
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;
