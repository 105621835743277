import { gql } from '@apollo/client';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

export const UPDATE_SPONSORSHIP_INVITE_MUTATION = gql`
  mutation Common_UpdateSponsorshipInvite($input: UpdateSponsorshipInviteInput!) {
    updateSubscriptionSponsorshipInvite(input: $input) {
      invite {
        id
        label
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;
