import type { Color } from 'common/types/Styles';

type TransactionType = 'income' | 'expense';

const BAR_COLORS: Record<TransactionType, Color> = {
  income: 'green',
  expense: 'red',
};

const getInvertedType = (type: TransactionType): TransactionType => {
  if (type === 'income') {
    return 'expense';
  } else if (type === 'expense') {
    return 'income';
  }

  throw new Error(`Invalid transaction type: ${type}`);
};

/** Returns the color name (not hex code) of the bar according to transaction type. **/
export const getBarColorName = <T extends TransactionType>(type: T, invert = false) =>
  BAR_COLORS[invert ? getInvertedType(type) : type];
