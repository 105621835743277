import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import type OverlayTrigger from 'components/lib/ui/popover/OverlayTrigger';
import PremiumFeatureOverlayTriggerShared from 'components/premium/PremiumFeatureOverlayTriggerShared';

import useFeatureEntitlementLimit from 'common/lib/hooks/premium/useFeatureEntitlementLimit';

import type { ProductFeatureLimit } from 'common/constants/premium';
import { getLimitPopoverCopy } from 'common/constants/premium';

const Container = styled.div``;

type Props = Omit<React.ComponentProps<typeof OverlayTrigger>, 'overlay' | 'children'> & {
  featureLimit: ProductFeatureLimit;
  /** i.e. user's current number of connected credentials to compare against their entitled limit */
  currentValue: number;
  children:
    | React.ReactNode
    | ((data: ReturnType<typeof useFeatureEntitlementLimit>) => React.ReactNode);
  /** Show popover when clicked, even if limit is not exceeded. */
  alwaysClickable?: boolean;
};

/**
 * Component used to display an upsell popover if the user has met or exceeded the limit for a feature.
 */
const PremiumFeatureLimitOverlayTrigger = ({
  featureLimit,
  currentValue,
  children,
  alwaysClickable,
  ...props
}: Props) => {
  const featureLimitInfo = useFeatureEntitlementLimit(featureLimit, currentValue);
  const { meetsOrExceedsLimit, limit } = featureLimitInfo;

  const { title, description } = getLimitPopoverCopy(featureLimit, limit ?? 0, currentValue);

  const childrenElement = RA.isFunction(children) ? children(featureLimitInfo) : children;

  return meetsOrExceedsLimit || alwaysClickable ? (
    <PremiumFeatureOverlayTriggerShared
      title={title}
      description={description}
      analyticsName={featureLimit}
      {...props}
    >
      {childrenElement}
    </PremiumFeatureOverlayTriggerShared>
  ) : (
    <Container>{childrenElement}</Container>
  );
};

export default PremiumFeatureLimitOverlayTrigger;
