import React from 'react';
import styled from 'styled-components';

import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import Testimonials from 'components/onboarding/Testimonials';

import { ONBOARDING } from 'common/constants/copy';

const StyledOnboardingTitle = styled(OnboardingTitle)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: 58px;
  }
`;

const StyledOnboardingSubtitle = styled(OnboardingSubtitle)`
  max-width: 438px;
`;

const StyledTestimonials = styled(Testimonials)`
  text-align: left;
  margin-top: ${({ theme }) => theme.spacing.xxlarge};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: ${({ theme }) => theme.spacing.xxxxlarge};
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 438px;
  margin-top: ${({ theme }) => theme.spacing.xlarge};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: ${({ theme }) => theme.spacing.xxxlarge};
  }
`;

type Props = {
  next: () => void;
};

const OnboardingTestimonials = ({ next }: Props) => (
  <>
    <StyledOnboardingTitle>{ONBOARDING.TESTIMONIALS.TITLE}</StyledOnboardingTitle>
    <StyledOnboardingSubtitle>{ONBOARDING.TESTIMONIALS.SUBTITLE}</StyledOnboardingSubtitle>
    <StyledTestimonials />
    <StyledPrimaryButton size="large" onClick={next}>
      {ONBOARDING.TESTIMONIALS.BUTTON}
    </StyledPrimaryButton>
  </>
);

export default OnboardingTestimonials;
