import Papa from 'papaparse';
import * as RA from 'ramda-adjunct';
import React, { useState } from 'react';
import styled from 'styled-components';

import FileDropzone from 'components/lib/ui/FileDropzone';
import type { BalanceHistoryFileInfo } from 'components/routes/accounts/ImportMintBalanceHistory';
import {
  ImportPageSubtitle,
  ImportPageTitle,
  NextButton,
} from 'components/routes/accounts/ImportMintBalanceHistory';

import { IMPORT_BALANCE_HISTORY } from 'constants/copy';

const StyledFileDropzone = styled(FileDropzone)`
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.xsmall};
  width: 100%;
  max-width: 440px;
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};

  > * {
    background: ${({ theme }) => theme.color.grayBackground};
  }
`;

type Props = {
  filesMap: Map<string, BalanceHistoryFileInfo>;
  setFilesMap: (filesMap: Map<string, BalanceHistoryFileInfo>) => void;
  onClickNext: () => void;
};

const UploadBalanceHistoryFiles = ({ filesMap, setFilesMap, onClickNext }: Props) => {
  const [fileList, setFileList] = useState<File[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>();

  return (
    <>
      <ImportPageTitle>{IMPORT_BALANCE_HISTORY.STEPS.UPLOAD_BALANCE_HISTORY.TITLE}</ImportPageTitle>
      <ImportPageSubtitle>
        {IMPORT_BALANCE_HISTORY.STEPS.UPLOAD_BALANCE_HISTORY.SUBTITLE}
      </ImportPageSubtitle>
      <StyledFileDropzone
        acceptFileTypes={['text/csv', '.csv']}
        title={IMPORT_BALANCE_HISTORY.STEPS.UPLOAD_BALANCE_HISTORY.FILE_UPLOADER_TITLE}
        files={fileList ?? []}
        errorMessage={errorMessage}
        onChangeFiles={async (files) => {
          setFilesMap(new Map<string, BalanceHistoryFileInfo>());
          setFileList(files);
          setErrorMessage(undefined);

          files?.forEach((file) => {
            // @ts-ignore parse() works with File type, but TS doesn't like it
            Papa.parse(file, {
              skipEmptyLines: true,
              complete: (results) => {
                const parsedRows = results.data as string[][];

                setFilesMap(
                  new Map(
                    filesMap.set(file.name, {
                      file,
                      totalCount: parsedRows.length - 1, // -1 to remove header row count
                      header: parsedRows[0],
                      rows: parsedRows.slice(1), // removes the header row
                    }),
                  ),
                );
              },
              error: setErrorMessage,
            });
          });
        }}
        onDropRejected={RA.noop}
      />
      <NextButton
        onClick={() => {
          if (RA.isNilOrEmpty(fileList)) {
            setErrorMessage(
              IMPORT_BALANCE_HISTORY.STEPS.UPLOAD_BALANCE_HISTORY.MIN_FILES_VALIDATION,
            );
            return;
          }

          onClickNext?.();
        }}
      >
        {IMPORT_BALANCE_HISTORY.NEXT_BUTTON}
      </NextButton>
    </>
  );
};

export default UploadBalanceHistoryFiles;
