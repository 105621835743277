import styled from 'styled-components';

import AbstractButton from 'components/lib/ui/button/AbstractButton';

import type { StyleMixin } from 'common/types/Styles';

export const textButtonMixin: StyleMixin = ({ theme }) => `
  background-color: transparent;
  color: ${theme.color.blue};
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.small};
  transition: ${theme.transition.default};

  :hover {
    color: ${theme.color.blueLight};
    background-color: transparent;
  }
`;

const TextButton = styled(AbstractButton)`
  ${textButtonMixin}
`;

export default TextButton;
