import storageSession from 'redux-persist/lib/storage/session';
import { createReducer } from 'typesafe-actions';

import { clearActAsUser, setActAsUser } from 'actions';

const INITIAL_MULTIHOUSEHOLD_STATE: MultiHouseholdState = {
  actAsUser: null,
};

export type MultiHouseholdState = {
  actAsUser: string | null;
};

const multiHousehold = createReducer<MultiHouseholdState>(INITIAL_MULTIHOUSEHOLD_STATE)
  .handleAction(clearActAsUser, (state) => INITIAL_MULTIHOUSEHOLD_STATE)
  .handleAction(setActAsUser, (state, { payload }) => ({ ...state, actAsUser: payload }));

export const multiHouseholdPersistConfig = {
  key: 'multiHousehold',
  storage: storageSession,
};

export default multiHousehold;
