import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import CollapsibleSection from 'components/lib/ui/CollapsibleSection';

import type { LogEvent } from 'lib/assistant/types';

const StyledCollapsibleSection = styled(CollapsibleSection)`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const SectionContent = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  white-space: pre-wrap;
`;

type Props = {
  logs: LogEvent[];
};

const DebugRawLogs = ({ logs }: Props) => (
  <>
    {logs.map(({ event, data, timestamp }, i) => (
      <StyledCollapsibleSection
        key={i}
        title={event}
        subtitle={DateTime.fromISO(timestamp).toLocaleString({
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        })}
      >
        <SectionContent>
          <code>{JSON.stringify(data, null, 2)}</code>
        </SectionContent>
      </StyledCollapsibleSection>
    ))}
  </>
);

export default DebugRawLogs;
