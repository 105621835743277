import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import IconButton from 'components/lib/ui/button/IconButton';

import { useStackContext } from 'common/lib/contexts/StackContext';

const Header = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacing.xsmall};
  padding-bottom: 0;
`;

const TitleContainer = styled(FlexContainer).attrs({ column: true, justifyCenter: true })`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  flex: 1;
  padding-right: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  title: string;
  children: React.ReactNode;
  subtitle?: string;
};

const NestedDropdownSubMenu = ({ title, children, subtitle }: Props) => {
  const { pop } = useStackContext();

  return (
    <>
      <Header>
        <IconButton icon="chevron-left" onClick={pop} />
        <TitleContainer>
          <Text weight="medium">{title}</Text>
          {!!subtitle && (
            <Text color="textLight" size="small">
              {subtitle}
            </Text>
          )}
        </TitleContainer>
      </Header>
      {children}
    </>
  );
};

export default NestedDropdownSubMenu;
