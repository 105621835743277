import type { DateTime } from 'luxon';
import React from 'react';
import Helmet from 'react-helmet';
import styled, { css } from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import TextButton from 'components/lib/ui/TextButton';
import RecurringActions from 'components/recurring/RecurringActions';
import RecurringCalendar from 'components/recurring/calendar/RecurringCalendar';
import RecurringEmptyPeriod from 'components/recurring/list/RecurringEmptyPeriod';
import RecurringUpcomingTable from 'components/recurring/list/RecurringUpcomingTable';

import { splitUpcomingCompleteItems } from 'common/lib/recurring';
import type { RecurringStreamToReview, RecurringTransactionItem } from 'common/lib/recurring/types';
import isV2Theme from 'common/lib/theme/isV2Theme';

const Root = styled(FlexContainer).attrs({ column: true })`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.gutter};
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
  ${isV2Theme(css`
    padding-top: 0;
  `)}
`;

const StreamsToReviewBanner = styled(Flex).attrs({ row: true, justifyBetween: true })`
  padding: ${({ theme }) => theme.spacing.default};
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.color.redDark} 0%,
    ${({ theme }) => theme.color.orangeDark} 100%
  );
  color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const ReviewButton = styled(TextButton)`
  color: ${({ theme }) => theme.color.white};
  text-decoration: underline;

  :hover {
    color: ${({ theme }) => theme.color.white};
    background-color: transparent;
  }
`;

export enum RecurringView {
  Calendar = 'calendar',
  List = 'list',
}

type Props = {
  items: RecurringTransactionItem[];
  streamsToReview: RecurringStreamToReview[];
  currentStartDate: DateTime;
  isLoading: boolean;
  setStartDate: (date: string) => void;
  refetch: () => void;
  onClickToReviewStreams?: () => void;
  openAddRecurringModal: () => void;
};

const RecurringUpcoming = ({
  items,
  currentStartDate,
  isLoading,
  setStartDate,
  streamsToReview,
  refetch,
  onClickToReviewStreams,
  openAddRecurringModal,
}: Props) => {
  const { upcomingItems, completeItems } = splitUpcomingCompleteItems(items);

  const [recurringView, setRecurringView] = React.useState<RecurringView>(RecurringView.List);

  const shouldShowCalendarView = recurringView === RecurringView.Calendar;
  const shouldShowListView = recurringView === RecurringView.List;

  const shouldShowUpcomingTable = shouldShowListView && !!upcomingItems.length;

  const shouldShowCompleteTable = shouldShowListView && !!completeItems.length;

  const shouldShowEmptyState =
    !shouldShowCalendarView && !shouldShowUpcomingTable && !shouldShowCompleteTable;

  const needsReviewCount = streamsToReview.length;

  return (
    <>
      <Helmet>
        <title>Recurring &middot; Upcoming</title>
      </Helmet>
      <Root>
        {needsReviewCount > 0 ? (
          <StreamsToReviewBanner>
            <Switch>
              <Case when={needsReviewCount > 1}>
                There are {needsReviewCount} new recurring merchants and accounts for you to review
              </Case>

              <Case when={needsReviewCount === 1}>
                There is 1 new recurring{' '}
                {streamsToReview[0]?.stream?.merchant ? 'merchant' : 'account'} for you to review
              </Case>
            </Switch>

            <ReviewButton onClick={onClickToReviewStreams}>Review now</ReviewButton>
          </StreamsToReviewBanner>
        ) : null}
        <RecurringActions
          startDate={currentStartDate}
          setStartDate={setStartDate}
          recurringView={recurringView}
          setRecurringView={setRecurringView}
        />

        {shouldShowCalendarView && (
          <RecurringCalendar
            items={items}
            startDate={currentStartDate}
            setStartDate={setStartDate}
            refetch={refetch}
          />
        )}

        {shouldShowUpcomingTable && (
          <RecurringUpcomingTable
            title="Upcoming"
            items={upcomingItems}
            isLoading={isLoading}
            startDate={currentStartDate}
            refetch={refetch}
          />
        )}

        {shouldShowCompleteTable && (
          <RecurringUpcomingTable
            title="Complete"
            items={completeItems}
            isLoading={isLoading}
            startDate={currentStartDate}
            refetch={refetch}
          />
        )}

        {shouldShowEmptyState && <RecurringEmptyPeriod onClickPrimary={openAddRecurringModal} />}
      </Root>
    </>
  );
};

export default RecurringUpcoming;
