import { useEffect } from 'react';

/**
 * Starts a timeout when `cond` is first set to true.
 *
 * The timeout is cancelled when `cond` is set to false or when the component
 * is unmounted.
 */
const useTimeoutWhen = (cond: boolean, ms: number, cb: () => void) => {
  useEffect(() => {
    if (!cond) {
      return;
    }

    const id = setTimeout(cb, ms);

    return () => {
      clearTimeout(id);
    };
  }, [cond, ms]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useTimeoutWhen;
