import pluralize from 'pluralize';
import React from 'react';

import Confirmation from 'components/lib/ui/Confirmation';

import * as COPY from 'common/constants/copy';

type Props = {
  selectedCount: number;
  isDeleting: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const TransactionBulkDeleteConfirmationModal = ({
  selectedCount,
  isDeleting,
  onCancel,
  onConfirm,
}: Props) => (
  <Confirmation
    title={`Are you sure you want to delete ${pluralize('transaction', selectedCount, true)}?`}
    confirm="Delete"
    isLoading={isDeleting}
    onCancel={onCancel}
    onConfirm={onConfirm}
    useDangerButton
  >
    <div>{COPY.SETTINGS_DATA.DISCLAIMER}</div>
  </Confirmation>
);

export default TransactionBulkDeleteConfirmationModal;
