import type { InputMaybe, Scalars, TransactionFilterInput } from 'common/generated/graphql';

export type TransactionFilters = TransactionFilterInput &
  Partial<{
    order: string;
    amountFilter: string;
  }>;

type ExtractBooleanScalarKeys<T> = {
  [K in keyof T]: T[K] extends InputMaybe<Scalars['Boolean']> ? K : never;
}[keyof T];

export type TransactionFiltersBooleanKeys = ExtractBooleanScalarKeys<TransactionFilterInput>;

export type SupportedQuickViewFilterKeys = keyof Pick<
  TransactionFilterInput,
  'categories' | 'categoryGroups' | 'startDate' | 'endDate' | 'merchants'
>;

export const SUPPORTED_QUICK_VIEW_FILTERS: SupportedQuickViewFilterKeys[] = [
  'categories',
  'categoryGroups',
  'startDate',
  'endDate',
  'merchants',
];
