import type { StyledComponent } from 'styled-components';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';

const LinkArrow = styled(Icon).attrs({ name: 'arrow-right' })<{
  hoverTarget?: StyledComponent<any, any>;
}>`
  width: 14px;
  height: 14px;
  margin-left: ${({ theme }) => theme.spacing.xxsmall};
  opacity: 0;
  transform: translateX(-10px);
  transition: ${({ theme }) => theme.transition.default};

  ${({ hoverTarget }) => hoverTarget}:hover & {
    opacity: 1;
    transform: none;
  }
`;

export default LinkArrow;
