import { Duration } from 'luxon';
import * as R from 'ramda';
import { useMemo, useCallback } from 'react';

import useQueryWithCacheExpiration from 'common/lib/hooks/useQueryWithCacheExpiration';

import { gql } from 'common/generated/gql';

export type Filters = {
  asset?: boolean;
  synced?: boolean;
  allowedTypes?: string[];
};

export type AccountTypeOption = { value: string; label: string };

const useCachedAccountTypeOptions = (filters?: Filters) => {
  const { asset, synced, allowedTypes } = filters ?? {};
  const { data, isLoadingInitialData } = useQueryWithCacheExpiration(GET_ACCOUNT_TYPES, {
    cacheExpiration: Duration.fromObject({ days: 1 }),
  });
  const { accountTypes = [] } = data ?? {};

  const typeOptions = useMemo(() => {
    let groupAllowedFilteredTypes = synced
      ? accountTypes.filter((type) => type.showForSyncedAccounts)
      : accountTypes;

    if (asset !== undefined) {
      groupAllowedFilteredTypes = groupAllowedFilteredTypes.filter((type) =>
        asset ? type.group === 'asset' : type.group === 'liability',
      );
    }

    if (allowedTypes) {
      groupAllowedFilteredTypes = groupAllowedFilteredTypes.filter((type) =>
        allowedTypes.includes(type.name),
      );
    }

    return groupAllowedFilteredTypes.map((type) => ({ value: type.name, label: type.display }));
  }, [accountTypes, asset, synced, allowedTypes]);

  const getSubtypeOptions = useCallback(
    (type: string) => {
      const accountType = accountTypes.find((accountType) => accountType.name === type);

      const options = R.map(
        (subtype) => ({ value: subtype.name, label: subtype.display }),
        accountType?.possibleSubtypes ?? [],
      );

      return R.sortBy(R.prop('label'), options);
    },
    [accountTypes],
  );

  const groupedOptions = useMemo(
    () =>
      typeOptions.map(({ value: type, label }) => ({
        label,
        options: getSubtypeOptions(type).map(({ value: subtype, label }) => ({
          label,
          value: JSON.stringify({ type, subtype }),
        })),
      })),
    [typeOptions, getSubtypeOptions],
  );

  return { typeOptions, groupedOptions, accountTypes, isLoadingInitialData, getSubtypeOptions };
};

const GET_ACCOUNT_TYPES = gql(`
  query Web_GetAccountTypes {
    accountTypes {
      name
      display
      group
      showForSyncedAccounts
      possibleSubtypes {
        display
        name
      }
    }
  }
`);

export default useCachedAccountTypeOptions;
