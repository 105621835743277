import * as React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import SingleCardPage from 'components/lib/layouts/SingleCardPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import ManualLink from 'components/lib/ui/link/ManualLink';
import ThemeProvider from 'components/utils/ThemeProvider';

import { useContactSupportContext } from 'lib/contexts/ContactSupportContext';

import routes from 'constants/routes';

const Root = styled(SingleCardPage)`
  margin: 0;
  display: flex;
  flex-direction: column;
`;
const AlertIcon = styled(Icon).attrs({ name: 'alert-circle' })`
  width: 60px;
  height: 60px;
  margin: ${({ theme }) => theme.spacing.xlarge} 0px;
  color: ${({ theme }) => theme.color.orange};
`;

const BodyContainer = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const HeaderText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.color.text};
`;

const StyledButton = styled(PrimaryButton)`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const NotFound = () => {
  const { openContactSupportModal } = useContactSupportContext();
  const history = useHistory();

  return (
    <ThemeProvider forceTheme="light">
      <Root name="Not Found">
        <BodyContainer alignCenter column padding="large">
          <AlertIcon />
          <HeaderText weight="medium" size="large">
            Page not found
          </HeaderText>
          <Text align="center" size="small" color="text">
            The page you are looking for does not exist.
          </Text>
          <Text align="center" size="small" color="text">
            If you believe you are seeing this in error, please{' '}
            <ManualLink onClick={() => openContactSupportModal()}>contact support</ManualLink>.
          </Text>
        </BodyContainer>
        <StyledButton size="medium" onClick={() => history.push(routes.dashboard())}>
          Back to Dashboard
        </StyledButton>
      </Root>
    </ThemeProvider>
  );
};

export default NotFound;
