import type { DateTime } from 'luxon';
import React, { useState } from 'react';

import Switch, { Case } from 'common/components/utils/Switch';
import AccountEditModal from 'components/accounts/AccountEditModal';
import { AccountLinkText } from 'components/accounts/AccountLinkText';
import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import Modal from 'components/lib/ui/Modal';
import IconButton from 'components/lib/ui/button/IconButton';
import { OverlayTrigger } from 'components/lib/ui/popover';
import MerchantLink from 'components/recurring/MerchantLink';
import EditMerchantModal from 'components/settings/merchants/EditMerchantModal';

import useCreditReportLiabilityStatementsOperations from 'common/lib/hooks/recurring/useCreditReportLiabilityStatementsOperations';
import useMarkStreamAsNotRecurring from 'common/lib/hooks/recurring/useMarkStreamAsNotRecurring';
import useToast from 'lib/hooks/useToast';
import { errorToast } from 'lib/ui/toast';

type Props = {
  merchantId?: string;
  creditReportLiabilityStatementId?: Maybe<string>;
  accountId?: Maybe<string>;
  name: string;
  className?: string;
  startDate?: Maybe<DateTime>;
  isPaid?: boolean;
  setIsMenuOpen?: (open: boolean) => void;
  refetch: () => void;
  setAmountValue?: (value: number) => void;
  streamId: string;
  setIsEditStatementVisible?: (open: boolean) => void;
  isAmountDifferentThanOriginal?: boolean;
};

const REFETCH_QUERIES = [
  'Web_GetUpcomingRecurringTransactionItems',
  'Web_GetAllRecurringTransactionItems',
];

const RecurringMenu = ({
  merchantId,
  creditReportLiabilityStatementId,
  accountId,
  name,
  className,
  startDate,
  isPaid,
  setIsMenuOpen,
  refetch,
  setAmountValue,
  streamId,
  setIsEditStatementVisible,
  isAmountDifferentThanOriginal,
}: Props) => {
  const { openToast } = useToast();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [markAsNotRecurringMutation] = useMarkStreamAsNotRecurring();

  const { revertLiabilityBillAmountToOriginal, updateLiabilityStatementPaymentStatus } =
    useCreditReportLiabilityStatementsOperations();

  const markAsNotRecurring = async () => {
    try {
      markAsNotRecurringMutation({
        refetchQueries: REFETCH_QUERIES,
        variables: {
          streamId,
        },
      });
      openToast({
        title: `Marked ${name} as not recurring`,
        description:
          'It will no longer show up on your recurring calendar and in the all recurring list.',
      });
    } catch (e) {
      errorToast();
    }
  };

  const revertToOriginalBillAmount = async () => {
    if (!creditReportLiabilityStatementId) {
      return;
    }

    const response = await revertLiabilityBillAmountToOriginal({
      creditReportLiabilityStatementId,
      onSuccess: () => {
        openToast({
          title: 'Success',
          description: 'Amount reverted to original.',
        });
        refetch();
      },
      onError: () => errorToast(),
    });

    const billAmount = response.data?.updateBillAmount?.creditReportLiabilityStatement?.billAmount;
    setAmountValue?.(Number(billAmount));
  };

  const togglePaymentStatus = async () => {
    if (!creditReportLiabilityStatementId) {
      return;
    }

    await updateLiabilityStatementPaymentStatus({
      creditReportLiabilityStatementId,
      isPaid: !isPaid,
      onSuccess: () => {
        openToast({
          title: isPaid ? 'Marked as unpaid' : 'Marked as paid',
          description: 'Statement payment status updated.',
        });
        refetch();
      },
      onError: () => errorToast(),
    });
  };

  const onToggleOpen = (toggleOpen: () => void) => {
    toggleOpen();
    setIsMenuOpen?.(true);
  };

  const queryParams = startDate ? { date: startDate.startOf('month').toISODate() } : {};

  return (
    <>
      {isEditModalOpen && (
        <Modal
          onClose={() => {
            setIsEditModalOpen(false);
            setIsMenuOpen?.(false);
          }}
        >
          {({ close }) => (
            <>
              {merchantId && (
                <EditMerchantModal
                  merchantId={merchantId}
                  afterUpdate={refetch}
                  afterDelete={refetch}
                />
              )}

              {accountId && (
                <AccountEditModal
                  accountId={accountId}
                  onDone={() => {
                    refetch();
                    close();
                  }}
                  onClose={close}
                />
              )}
            </>
          )}
        </Modal>
      )}
      <OverlayTrigger
        disableClickOutside={isEditModalOpen}
        overlay={
          <DropdownMenu>
            <Switch>
              <Case when={!!merchantId}>
                <MerchantLink id={merchantId!} queryParams={queryParams}>
                  <DropdownMenuItem icon="eye">View merchant</DropdownMenuItem>
                </MerchantLink>

                <DropdownMenuItem
                  onClick={() => {
                    setIsEditModalOpen(true);
                  }}
                  icon="edit"
                >
                  Edit merchant details
                </DropdownMenuItem>
              </Case>

              <Case when={!!accountId}>
                <AccountLinkText id={accountId!}>
                  <DropdownMenuItem icon="eye">View account</DropdownMenuItem>
                </AccountLinkText>
                <DropdownMenuItem
                  onClick={() => {
                    setIsEditModalOpen(true);
                  }}
                  icon="edit"
                >
                  Edit account details
                </DropdownMenuItem>
                {creditReportLiabilityStatementId ? (
                  <>
                    {setIsEditStatementVisible ? (
                      <DropdownMenuItem
                        onClick={() => {
                          setIsEditStatementVisible(true);
                        }}
                        icon="dollar-sign"
                      >
                        Edit statement amount
                      </DropdownMenuItem>
                    ) : null}

                    {isAmountDifferentThanOriginal && (
                      <DropdownMenuItem
                        onClick={() => {
                          revertToOriginalBillAmount();
                        }}
                        icon="undo"
                      >
                        Revert to original amount
                      </DropdownMenuItem>
                    )}

                    <DropdownMenuItem
                      onClick={() => {
                        togglePaymentStatus();
                      }}
                      icon={isPaid ? 'x' : 'check'}
                    >
                      {isPaid ? 'Mark as unpaid' : 'Mark as paid'}
                    </DropdownMenuItem>
                  </>
                ) : null}
              </Case>
            </Switch>

            <DropdownMenuItem
              type="danger"
              onClick={() => {
                markAsNotRecurring();
                setIsMenuOpen?.(false);
              }}
              icon="x"
            >
              Mark {merchantId ? 'merchant' : 'account'} as not recurring
            </DropdownMenuItem>
          </DropdownMenu>
        }
        placement="bottom-end"
      >
        {({ toggleOpen, isOpen }) => (
          <IconButton
            icon="more-horizontal"
            onClick={() => onToggleOpen(toggleOpen)}
            active={isOpen}
            className={className}
          />
        )}
      </OverlayTrigger>
    </>
  );
};

export default RecurringMenu;
