import hoistNonReactStatic from 'hoist-non-react-statics';
import type { ComponentType, ComponentProps } from 'react';
import React from 'react';

/**
 * Same as React.memo but maintains component type and hoists statics.
 *
 * Useful for when you're adding additional things like fragments to a component,
 * but still want to memoize it.
 */
export const memo = <T extends ComponentType<any>>(
  Component: T,
  propsAreEqual?: (
    prevProps: Readonly<ComponentProps<T>>,
    nextProps: Readonly<ComponentProps<T>>,
  ) => boolean,
): T => {
  const WrappedComponent = React.memo(Component, propsAreEqual);

  // Ensure static functions / variables are preserved on the wrapped component
  // @ts-ignore [REACT-NATIVE-UPGRADE] TS error goes away when we upgrade to React 18 in common (WrappedComponent)
  hoistNonReactStatic(WrappedComponent, Component);

  // @ts-ignore this is the right type
  return WrappedComponent;
};
