import { useEffect } from 'react';

import useCreditReportLiabilityStatementsOperations from 'common/lib/hooks/recurring/useCreditReportLiabilityStatementsOperations';
import { useQueryParam, useUpdateQueryParam } from 'lib/hooks/useQueryParams';
import toast from 'lib/ui/toast';

export const useRecurringActions = () => {
  const setMarkStatementAsPaid = useUpdateQueryParam('markAsPaid');
  const markStatementAsPaid = useQueryParam('markAsPaid');

  const { updateLiabilityStatementPaymentStatus } = useCreditReportLiabilityStatementsOperations();

  useEffect(() => {
    if (markStatementAsPaid) {
      updateLiabilityStatementPaymentStatus({
        creditReportLiabilityStatementId: markStatementAsPaid,
        isPaid: true,
        onSuccess: () => {
          setMarkStatementAsPaid(undefined);
          toast({
            title: 'Statement successfully marked as paid',
          });
        },
      });
    }
  }, [markStatementAsPaid, updateLiabilityStatementPaymentStatus]);
};
