import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import AbstractField from 'components/lib/form/AbstractField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import TogglePill from 'components/lib/form/TogglePill';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import {
  GET_ONBOARDING_FINANCIAL_GOALS_QUERY,
  SET_ONBOARDING_FINANCIAL_GOALS_MUTATION,
} from 'common/lib/graphQl/onboarding';
import useQuery from 'common/lib/hooks/useQuery';
import { formatFinancialPrioritiesMutationInput } from 'common/lib/onboarding/Adapters';
import { getOtherGoalId } from 'selectors/onboardingSelectors';

import { ONBOARDING } from 'common/constants/copy';

import type { GetOnboardingFinancialGoals } from 'common/generated/graphQlTypes/GetOnboardingFinancialGoals';
import type {
  SetUserOnboardingFinancialGoals,
  SetUserOnboardingFinancialGoalsVariables,
} from 'common/generated/graphQlTypes/SetUserOnboardingFinancialGoals';

const OTHER_FIELD_NAME = 'otherText';

const StyledOnboardingTitle = styled(OnboardingTitle)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: 58px;
  }
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
`;

type Props = {
  next: () => void;
};

const OnboardingPriorities = ({ next }: Props) => {
  const { data, isLoadingInitialData } = useQuery<GetOnboardingFinancialGoals>(
    GET_ONBOARDING_FINANCIAL_GOALS_QUERY,
  );
  const { onboardingFinancialGoals = [] } = data ?? {};
  const otherGoalId = getOtherGoalId(data);

  const [setUserOnboardingFinancialGoals] = useMutation<
    SetUserOnboardingFinancialGoals,
    SetUserOnboardingFinancialGoalsVariables
  >(SET_ONBOARDING_FINANCIAL_GOALS_MUTATION);

  return isLoadingInitialData ? (
    <StyledLoadingSpinner />
  ) : (
    <>
      <StyledOnboardingTitle>{ONBOARDING.PRIORITIES.TITLE}</StyledOnboardingTitle>
      <OnboardingSubtitle>{ONBOARDING.PRIORITIES.SUBTITLE}</OnboardingSubtitle>
      <OnboardingFormContainer>
        <Form
          onSubmit={async (values: Record<string, string | boolean>) => {
            const selectedIds = R.pipe(
              R.omit([OTHER_FIELD_NAME]),
              R.toPairs,
              R.filter(([, checked]) => checked),
              R.map(([id]) => id),
            )(values);
            const input = formatFinancialPrioritiesMutationInput(
              data,
              selectedIds,
              values.otherText as string,
            );
            await setUserOnboardingFinancialGoals({ variables: { input } });
          }}
          onSubmitSuccess={() => next()}
        >
          {onboardingFinancialGoals.map(({ name, id }) => (
            <AbstractField name={id} hideLabel key={id}>
              {({ value, setFieldValue }) => (
                <TogglePill multiLine value={value} onChange={(value) => setFieldValue(id, value)}>
                  {id === otherGoalId ? 'Something else' : name}
                </TogglePill>
              )}
            </AbstractField>
          ))}
          <FormContext.Consumer>
            {({ getFieldMeta }) =>
              RA.isNotNil(otherGoalId) &&
              !!getFieldMeta(otherGoalId).value && (
                <TextField
                  name={OTHER_FIELD_NAME}
                  label="Something else"
                  placeholder="Tell us more..."
                  autoFocus
                />
              )
            }
          </FormContext.Consumer>
          <FormSubmitButton variant="primary" size="medium" disableWhenValuesUnchanged={false}>
            {ONBOARDING.PRIORITIES.BUTTON}
          </FormSubmitButton>
        </Form>
      </OnboardingFormContainer>
    </>
  );
};

export default OnboardingPriorities;
