import React from 'react';
import { FiCircle } from 'react-icons/fi';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import Icon from 'components/lib/ui/Icon';

import useTheme from 'lib/hooks/useTheme';

type Props = {
  meetsCriteria: boolean;
  message: string;
};

const Root = styled(Flex)<{ meetsCriteria: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ meetsCriteria, theme }) =>
    meetsCriteria ? theme.color.greenDark : theme.color.textLight};
  padding-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const IconWrapper = styled(Flex)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.base};
`;

const PasswordValidationStatus = ({ meetsCriteria, message }: Props) => {
  const theme = useTheme();
  return (
    <Root meetsCriteria={meetsCriteria} justifyStart>
      <IconWrapper alignCenter>
        {meetsCriteria ? (
          <Icon name="check" />
        ) : (
          <FiCircle fill={theme.color.grayFocus} stroke={theme.color.grayFocus} />
        )}
      </IconWrapper>
      <Flex alignCenter>{message}</Flex>
    </Root>
  );
};

export default PasswordValidationStatus;
