// Disable because eslint doesn't recognize HOC wrapper as valid component

/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';

import type { Step, StepProps } from 'components/utils/Flow';

import { useFlowContext } from 'lib/contexts/FlowContext';

/**
 * Util component for use with Flow that lets a step be skipped (both going forward and backward).
 *
 * const SomeStep = (props) => ...
 *
 * export default SkippableFlowStep(SomeStep, props => ...);
 *
 * OR you can use in a <Flow> like this:
 *
 * <Flow
 *   steps={[
 *    FirstStep,
 *    SkippableFlowStep(SecondStep, props => ...),
 *    ThirdStep,
 *   ]}
 * />
 */
const SkippableFlowStep =
  <Params extends Record<string, unknown>>(
    Component: Step<Params, Params>,
    getShouldSkip: (props: StepProps<Params, Params>) => boolean,
  ): Step<Params, Params> =>
  (props) => {
    const { next, goBack, ...nextProps } = props;
    const shouldSkip = getShouldSkip(props);
    const { currentIndex, previousIndex } = useFlowContext();
    const didGoBack = (previousIndex ?? 0) > currentIndex;

    useEffect(() => {
      if (shouldSkip && didGoBack) {
        goBack?.();
      } else if (shouldSkip) {
        next(nextProps);
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return shouldSkip ? null : <Component {...props} />;
  };

export default SkippableFlowStep;
