import { useQuery, useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import TransactionSplitForm from 'components/transactions/TransactionSplitForm';
import TransactionSplitOriginalTransactionContainer from 'components/transactions/TransactionSplitOriginalTransactionContainer';

import {
  SPLIT_TRANSACTION_MUTATION,
  TRANSACTION_SPLIT_QUERY,
} from 'common/lib/graphQl/transactions';
import { breakPoints, spacing } from 'common/lib/theme/dynamic';
import type { FormValues } from 'common/lib/transactions/Splits';
import {
  getDefaultSplitDataValues,
  getInitialFormValues,
  invertSplitAmounts,
} from 'common/lib/transactions/Splits';
import { isIncome as getIsIncome } from 'common/utils/formatTransactionAmount';

const Root = styled(ModalCard)`
  margin: 0 auto;
  width: 100%;

  @media (min-width: ${breakPoints.md}px) {
    width: ${breakPoints.md}px;
  }
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  flex: 1;
  padding: ${spacing.xlarge};
`;

const SplitSectionHeader = styled(Text).attrs({
  size: 'xsmall',
  weight: 'bold',
  color: 'textLight',
})`
  padding: ${spacing.small} ${spacing.xlarge};
`;

type Props = {
  id: string;
  onDone: () => void;
  onCancel: () => void;
};

const TransactionSplitModal = ({ id, onDone, onCancel }: Props) => {
  const { data, loading } = useQuery(TRANSACTION_SPLIT_QUERY, {
    variables: { id: id ?? '' },
  });
  const { amount = 0, splitTransactions } = data?.getTransaction ?? {};
  const isIncome = getIsIncome(amount);

  // useState so that once the mutation is complete the ui doesn't update (while the refetching is happening)
  const [isEditing, setIsEditing] = useState<boolean | undefined>(undefined);

  const [initialFormValues, setInitialFormValues] = useState<FormValues | undefined>(undefined);

  useEffect(() => {
    if (splitTransactions && isEditing === undefined) {
      setIsEditing(splitTransactions.length > 0);
    }
  }, [splitTransactions, isEditing, setIsEditing]);

  useEffect(() => {
    if (data && data.getTransaction && initialFormValues === undefined) {
      const defaultSplit = getDefaultSplitDataValues(data.getTransaction);
      setInitialFormValues(getInitialFormValues(data.getTransaction, [defaultSplit, defaultSplit]));
    }
  }, [data, setInitialFormValues, initialFormValues]);

  const [splitTransaction] = useMutation(SPLIT_TRANSACTION_MUTATION, {
    refetchQueries: ['AccountDetails_getAccount', 'Common_TransactionSplitQuery'],
  });

  return (
    <Root
      title="Split Transaction"
      description="Splitting a transaction will create individual transactions that you can categorize and manage separately."
    >
      <Switch>
        <Case when={loading && !data}>
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        </Case>
        <Case default>
          <SplitSectionHeader>ORIGINAL</SplitSectionHeader>
          <TransactionSplitOriginalTransactionContainer
            originalTransaction={data?.getTransaction}
          />
          <SplitSectionHeader>SPLITS</SplitSectionHeader>
          {initialFormValues && (
            <TransactionSplitForm
              onCancel={onCancel}
              onSubmitSuccess={onDone}
              mutation={splitTransaction}
              initialValues={initialFormValues}
              originalTransaction={data?.getTransaction}
              isEditMode={isEditing ?? false}
              transformValuesBeforeSubmit={isIncome ? undefined : invertSplitAmounts}
            />
          )}
        </Case>
      </Switch>
    </Root>
  );
};

export default TransactionSplitModal;
