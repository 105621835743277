import { sentenceCase } from 'change-case';

import { ENTITY_TO_LABEL } from 'common/lib/reports';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import type { ReportsTab } from 'state/reports/types';

import type { ReportsGroupByEntity } from 'common/generated/graphql';

export const makeChartTitle = (groupBy: Maybe<ReportsGroupByEntity>, tab: ReportsTab) => {
  if (!groupBy) {
    return undefined;
  } else if (tab === 'sankey') {
    return 'Sankey Diagram';
  } else if (tab === 'cashFlow') {
    return 'Cash Flow';
  } else {
    const groupLabel = ENTITY_TO_LABEL[groupBy];
    return sentenceCase(`${tab} by ${groupLabel}`);
  }
};

export const makeChartDisplayDates = (startDate: ISODate, endDate: ISODate) =>
  [
    isoDateToAbbreviatedMonthDayAndYear(startDate),
    isoDateToAbbreviatedMonthDayAndYear(endDate),
  ] as const;

export const formatReportsValue = (value: Maybe<number>) => (value ? Math.abs(value) : 0);
