import React from 'react';

import useTheme from 'lib/hooks/useTheme';

import type { ReferenceLineLabelProps } from 'types/Recharts';

type ReferenceLineLabelTextProps = {
  anchorX: number;
  anchorY: number;
  labelOffsetXPx: number;
  labelOffsetYPx: number;
  left?: boolean;
  children?: React.ReactNode;
};

const ReferenceLineLabelText = ({
  anchorX,
  anchorY,
  labelOffsetXPx,
  labelOffsetYPx,
  left = false,
  children,
}: ReferenceLineLabelTextProps) => {
  const theme = useTheme();
  return (
    <text
      x={anchorX + (left ? -1 : 1) * labelOffsetXPx}
      y={anchorY - labelOffsetYPx}
      textAnchor="middle"
      fontSize={theme.fontSize.xsmall}
      fill={theme.color.textLight}
    >
      {children}
    </text>
  );
};

export type Props = {
  leftLabelText: string;
  rightLabelText: string;
  labelOffsetXPx: number;
  labelOffsetYPx: number;
} & ReferenceLineLabelProps;

/**
 * A label for Recharts' Reference line that extends the line into the top margin and
 * has text for the left and right side of the divider
 *
 *   leftLabelText | rightLabelText
 *                 |
 */
const ReferenceLineDividerLabel = ({
  labelOffsetXPx,
  labelOffsetYPx,
  leftLabelText,
  rightLabelText,
  viewBox: { x, y },
}: Props) => {
  const theme = useTheme();
  return (
    <g>
      <ReferenceLineLabelText
        labelOffsetXPx={labelOffsetXPx}
        labelOffsetYPx={labelOffsetYPx}
        anchorX={x}
        anchorY={y}
        left
      >
        {leftLabelText}
      </ReferenceLineLabelText>
      <ReferenceLineLabelText
        labelOffsetXPx={labelOffsetXPx}
        labelOffsetYPx={labelOffsetYPx}
        anchorX={x}
        anchorY={y}
      >
        {rightLabelText}
      </ReferenceLineLabelText>
      <line x1={x} x2={x} y1={y} stroke={theme.color.grayLight} />
    </g>
  );
};

export default ReferenceLineDividerLabel;
