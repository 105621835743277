import React from 'react';

import Flow from 'components/utils/Flow';

import UpdateEmailFormModal from './UpdateEmailFormModal';
import UpdateEmailVerificationModal from './UpdateEmailVerificationModal';

type Props = {
  onComplete?: () => void;
};

const UpdateEmailFlow = ({ onComplete }: Props) => (
  <Flow steps={[UpdateEmailFormModal, UpdateEmailVerificationModal]} onComplete={onComplete} />
);
export default UpdateEmailFlow;
