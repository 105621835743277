import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { clearProviderEphemeralData, login, setProviderEphemeralData } from 'actions';
import useAuthProviderBase from 'common/lib/hooks/auth/useAuthProviderBase';
import type { UserState } from 'common/state/user/reducer';
import api from 'lib/api';
import useFingerprint from 'lib/hooks/auth/useFingerprint';
import useDispatch from 'lib/hooks/useDispatch';
import { useQueryParam } from 'lib/hooks/useQueryParams';
import useUserAttributionData from 'lib/hooks/useUserAttributionData';
import { getProviderEphemeralData } from 'selectors';

import routes from 'constants/routes';

import type { AuthEndpointPayload, AuthProvider } from 'common/types/auth';

export type AuthProviderResponse = UserState & { exists?: boolean; is_new: boolean };

type Options = {
  provider: AuthProvider;
  staySignedIn?: boolean;
  extraData?: Record<string, any>;
  onConfirmedAuthentication?: (response: AuthProviderResponse) => void;
  onError?: (error?: { detail: string; code: string }) => void;
};

/**
 * Extend from base hook and implement specific handlers.
 *
 * We set an ephemeral data to redux so we can access it on the register confirmation page.
 * This is needed because we access this data from different pages (sign-in, sign-up, etc).
 */
const useAuthProvider = <TPayload extends Record<string, any>>({
  provider,
  onError,
  staySignedIn = false,
  onConfirmedAuthentication,
  extraData,
}: Options) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const fingerprint = useFingerprint();
  const userAttributionData = useUserAttributionData();

  const ephemeralData = useSelector(getProviderEphemeralData);
  const clearEphemeralData = useCallback(() => dispatch(clearProviderEphemeralData()), [dispatch]);

  // When a user is signing up with a Household invite
  const householdInviteToken = useQueryParam('token') || '';
  const householdInviteEmail = useQueryParam('email') || '';

  const referralCode = useQueryParam('r') || '';

  const { authenticate, isLoading } = useAuthProviderBase<
    TPayload & Pick<AuthEndpointPayload, 'skipConfirmation'>
  >({
    provider,
    apiWrapper: api,
    fingerprint,
    userAttributionData,
    getExtraData: () => ({
      client_platform: 'web',
      trusted_device: staySignedIn,
      householdInviteToken,
      householdInviteEmail,
      referralCode,
      // This is important so we know on backend when to use the current implementation
      // (decode an ID token) or the new one (exchange the access token for the user data).
      // This is temporary until we fully migrate to the new implementation.
      usingGis: true,
      ...extraData,
    }),
    onConfirmedSignup: (response) => {
      // Used to hide steps if user signs up from onboarding flow
      dispatch(setProviderEphemeralData({ provider }));
      // @ts-ignore signedUpAndVerified is not compatible with UserState type
      dispatch(login({ signedUpAndVerified: true, ...response }));

      if (onConfirmedAuthentication) {
        onConfirmedAuthentication?.(response);
      } else {
        // Preserve the current flow
        history.push(routes.signup.priorities());
      }
    },
    onShouldConfirmAccountCreation: (response, data) => {
      dispatch(
        setProviderEphemeralData({
          provider,
          email: response.email,
          ...data,
        }),
      );
      history.push(routes.authConfirmation({ provider }));
    },
    onUserSignedIn: (response) => {
      dispatch(login(response));
      history.push(routes.dashboard());
      clearEphemeralData();
    },
    onError,
  });

  return { authenticate, isLoading, ephemeralData, onCancel: clearEphemeralData };
};

export default useAuthProvider;
