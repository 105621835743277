import React from 'react';
import styled from 'styled-components';

import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Markdown from 'components/lib/ui/Markdown';
import Text from 'components/lib/ui/Text';

import type { BlockComponentProps } from 'common/types/support';

const APPEARANCE_TO_ICON_NAME: Record<
  BlockComponentProps<'ResolutionMessageBlock'>['data']['appearance'],
  string
> = {
  warning: 'alert-triangle',
  info: 'alert-triangle',
  success: 'check',
  error: 'alert-triangle',
  plain: '',
};

const Root = styled(Banner)`
  font-size: ${({ theme }) => theme.fontSize.small};
  padding: ${({ theme }) => theme.spacing.small};
  padding: ${({ theme, type }) => (type === 'plain' ? '0' : `${theme.spacing.small}`)};
`;

const Content = styled(FlexContainer).attrs({ alignStart: true })`
  gap: ${({ theme }) => theme.spacing.large};
`;

const Column = styled(FlexContainer).attrs({ column: true })`
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

const BannerIcon = styled(Icon).attrs({ size: 16 })`
  padding: ${({ theme }) => theme.spacing.xxsmall};
`;

const ResolutionMessageBlock = ({
  data: { title, appearance, text },
  onClickEvent, // not used here but passed down from Block
}: BlockComponentProps<'ResolutionMessageBlock'>) => (
  <Root type={appearance}>
    <Content>
      {APPEARANCE_TO_ICON_NAME[appearance] && (
        <Column>
          <BannerIcon name={APPEARANCE_TO_ICON_NAME[appearance]} />
        </Column>
      )}
      <Column>
        {title && <Text weight="medium">{title}</Text>}
        <Markdown source={text} />
      </Column>
    </Content>
  </Root>
);

export default ResolutionMessageBlock;
