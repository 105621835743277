import React from 'react';
import styled from 'styled-components';

import OverlayTrigger from 'components/lib/ui/popover/OverlayTrigger';
import PremiumFeaturePopover from 'components/premium/PremiumFeaturePopover';
import PremiumUpgradeFlow from 'components/premium/PremiumUpgradeFlow';

import useModal from 'lib/hooks/useModal';

const Container = styled.div``;

type Props = Omit<React.ComponentProps<typeof OverlayTrigger>, 'overlay'> & {
  title: string;
  description: string;
  analyticsName: string;
  onClickUpgrade?: () => void;
  children: React.ReactNode;
};

/** Base component. Should use PremiumFeatureOverlayTrigger or PremiumFeatureLimitOverlayTrigger instead. */
const PremiumFeatureOverlayTriggerShared = ({
  title,
  description,
  analyticsName,
  children,
  placement = 'left',
  onClickUpgrade,
  ...props
}: Props) => {
  const [UpgradeModal, { open: openModal, close: closeModal }] = useModal();

  return (
    <>
      <OverlayTrigger
        overlay={({ close: closePopover }) => (
          <PremiumFeaturePopover
            title={title}
            description={description}
            analyticsName={analyticsName}
            onClickUpgrade={() => {
              onClickUpgrade ? onClickUpgrade() : openModal();
              closePopover();
            }}
          />
        )}
        placement={placement}
        {...props}
      >
        {({ toggleOpen }) => <Container onClick={toggleOpen}>{children}</Container>}
      </OverlayTrigger>
      <UpgradeModal>
        <PremiumUpgradeFlow
          analyticsName={analyticsName}
          onBack={closeModal}
          onComplete={closeModal}
        />
      </UpgradeModal>
    </>
  );
};

export default PremiumFeatureOverlayTriggerShared;
