import pluralize from 'pluralize';
import React, { useContext } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import BulkUpdateTagLabels from 'components/transactions/tags/BulkUpdateTagLabels';

import useInitialValue from 'common/lib/hooks/useInitialValue';
import type { ConfirmationData } from 'common/lib/transactions/bulkUpdate';
import { isTagsField } from 'common/lib/transactions/bulkUpdate';
import { formatThousands } from 'common/utils/Number';
import { useModalContext } from 'lib/contexts/ModalContext';

const Subtitle = styled(Text).attrs({ size: 'base', color: 'text' })`
  display: block;
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const Row = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  padding: ${({ theme }) => `${theme.spacing.default} ${theme.spacing.xlarge}`};

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.color.grayFocus};
  }

  > ${Text}:first-child {
    margin-right: ${({ theme }) => theme.spacing.default};
  }
`;

const Footer = styled(CardFooter)`
  border-top: none;
`;

type Props = {
  selectedCount: number;
  initialTags?: string[];
  removedTags?: string[];
  confirmationData: ConfirmationData;
};

const TransactionBulkUpdateConfirmationModal = ({
  selectedCount,
  confirmationData,
  removedTags,
}: Props) => {
  const count = useInitialValue(selectedCount);
  const { submitForm, submitCount } = useContext(FormContext);
  const { close } = useModalContext();

  return (
    <ModalCard
      title="Does this look right?"
      hideBottomBorder
      description={
        <Subtitle>
          Confirm this looks right to you, you will be applying the following changes to{' '}
          <Text weight="medium">
            {formatThousands(count)} {pluralize('transaction', count)}
          </Text>
          .
        </Subtitle>
      }
    >
      <div>
        {Object.entries(confirmationData).map(([key, value]) => (
          <Row key={key}>
            <Text color="textLight">{key}</Text>
            {isTagsField(key, value) ? (
              <BulkUpdateTagLabels tagIds={value} removedIds={removedTags || []} />
            ) : (
              <Text>{value}</Text>
            )}
          </Row>
        ))}
      </div>
      <Footer justifyEnd>
        <DefaultButton onClick={close}>Cancel</DefaultButton>
        <FormSubmitButton
          size="small"
          onClick={submitForm}
          pending={submitCount > 0}
          className="bulk-update-submit"
          disableWhenValuesUnchanged={false}
        >
          Apply to all {formatThousands(count)}
        </FormSubmitButton>
      </Footer>
    </ModalCard>
  );
};

export default TransactionBulkUpdateConfirmationModal;
