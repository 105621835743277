import { isString } from 'ramda-adjunct';
import React, { useCallback, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

import { SelectIconWrapper, SelectTrigger } from 'components/lib/form/FullScreenSelect';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Tooltip from 'components/lib/ui/Tooltip';
import FullHeightMerchantSelect from 'components/merchants/FullHeightMerchantSelect';
import MerchantLogo from 'components/merchants/MerchantLogo';
import NarrowLinkButton from 'components/transactions/list/NarrowLinkButton';

import { updateTransactionOptimisticResponse } from 'common/lib/graphQl/transactions';
import { color, spacing } from 'common/lib/theme/dynamic';
import { useTransactionRuleToastContext } from 'lib/contexts/TransactionRuleToastContext';
import useToast from 'lib/hooks/useToast';

import routes from 'constants/routes';

import type {
  TransactionOverviewFieldsFragment,
  UpdateTransactionMutationInput,
  Web_UpdateTransactionOverviewDocument,
} from 'common/generated/graphql';
import type { MutationFunctionFromDocument } from 'common/types/graphql';
import type { TransactionRulePrefillValues } from 'types/rules';

const TOOLTIP_SHOW_DELAY_MS = 500;

const isMaybeString = (value: unknown): value is Maybe<string> => isString(value) || !value;

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

const Root = styled.div<{ $isLoading: boolean }>`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  gap: ${spacing.xxsmall};

  &:hover,
  &:focus-within {
    ${SelectIconWrapper} {
      opacity: 1;
      display: block;
    }

    ${SelectTrigger} {
      border-color: ${color.grayLight};

      &[data-disabled] {
        border-color: ${color.grayFocus};
        background-color: ${color.grayBackground};
      }

      &:hover:not([data-disabled]) {
        border-color: ${color.gray};
      }
    }

    ${NarrowLinkButton} {
      display: flex;
    }
  }

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      animation: ${pulse} ease-in-out 1.5s infinite;
    `}
`;

const MerchantValue = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  gap: ${spacing.xsmall};

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

type Props = {
  transaction: TransactionOverviewFieldsFragment;
  hideDetailsLink: boolean;
  isEditingDisabled: boolean;
  updateTransaction: MutationFunctionFromDocument<typeof Web_UpdateTransactionOverviewDocument>;
};

const TransactionMerchantSelect: React.FC<Props> = ({
  transaction,
  hideDetailsLink,
  isEditingDisabled,
  updateTransaction,
}) => {
  const { openErrorToast } = useToast();
  const { showToast: showCreateTransactionRuleToast } = useTransactionRuleToastContext();

  const { id: transactionId, merchant, dataProviderDescription } = transaction;

  // Used to show a loading animation when updating the transaction
  const [isUpdatingMerchant, setIsUpdatingMerchant] = useState(false);

  const onChangeMerchant = useCallback(
    async (
      name: string,
      showCreateTransactionRuleToast: (values: TransactionRulePrefillValues) => void,
    ) => {
      setIsUpdatingMerchant(true);
      updateTransaction({
        variables: {
          input: {
            name,
            id: transaction.id,
          } as UpdateTransactionMutationInput,
        },
        optimisticResponse: updateTransactionOptimisticResponse({
          ...transaction,
          merchant,
        }),
        onError: () => openErrorToast({ description: "Couldn't update transaction." }),
        onCompleted: () => {
          showCreateTransactionRuleToast({
            merchantName: transaction.merchant.name,
            newMerchantName: name,
          });
          setIsUpdatingMerchant(false);
        },
      });
    },
    [merchant, transaction, updateTransaction, openErrorToast],
  );

  return (
    <Root $isLoading={isUpdatingMerchant}>
      <>
        <FullHeightMerchantSelect
          disabled={isEditingDisabled}
          italicizeValueLabel={isEditingDisabled}
          transactionId={transactionId}
          transactionMerchantName={merchant.name}
          transactionProviderDescription={dataProviderDescription}
          alwaysShowBorder={false}
          value={{ value: merchant.id, label: merchant.name, icon: merchant.logoUrl }}
          onChange={async (selectedMerchantName: string) => {
            await onChangeMerchant(selectedMerchantName, showCreateTransactionRuleToast);
          }}
          renderSelectedOption={({ label, icon }) => (
            <MerchantValue>
              {isMaybeString(icon) && <MerchantLogo url={icon} size={20} />}
              <span>{label}</span>
            </MerchantValue>
          )}
          hideUntilHover
          fullWidthTrigger
        />
        {!hideDetailsLink && (
          <Tooltip content="View merchant" delayShow={TOOLTIP_SHOW_DELAY_MS} fitContent>
            <NarrowLinkButton
              linkTo={routes.merchants({
                id: merchant.id,
                queryParams: {
                  date: transaction.date,
                },
              })}
            />
          </Tooltip>
        )}
      </>
    </Root>
  );
};

export default React.memo(TransactionMerchantSelect);
