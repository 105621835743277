import * as R from 'ramda';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSessionstorageState } from 'rooks';

import type { RouteFlowInfo } from 'components/utils/RouteFlow';

import useCurrentReference from 'common/lib/hooks/useCurrentReference';

/** This represents the expected state throughout the AdvisorOnboardingFlow. */
interface AdvisorOnboardingState {
  name: string;
  email: string;
  code: string;
  /** User has signed up with one of our federated auth providers (e.g. Google, Apple) */
  isFederatedAuth?: boolean;
  currentStepIndex?: number;
}

interface AdvisorOnboardingContextType<TState, TParams> {
  next: (props: Partial<TState>, params?: TParams) => void;
  goBack: () => void;
  skipToComplete: () => void;
  state: Partial<TState>;
}

const AdvisorOnboardingContext = createContext(undefined as any);

type Props = {
  steps: RouteFlowInfo[];
  /** Called when last component calls next() */
  onComplete?: () => void;
};

// We might want to generalize this a bit more in the future
const AdvisorOnboardingProvider = ({
  steps: _steps,
  onComplete,
  children,
}: React.PropsWithChildren<Props>) => {
  const steps = useCurrentReference(_steps);

  const history = useHistory();
  const {
    location: { pathname },
    goBack,
    push,
  } = history;
  // Storing in sessionStorage so that if the user refreshes the page, we don't lose their progress
  const [state, setState, removeState] = useSessionstorageState<Partial<AdvisorOnboardingState>>(
    'onboarding',
    {},
  );

  const value = useMemo(
    (): AdvisorOnboardingContextType<AdvisorOnboardingState, unknown> => ({
      // @vanessa: This should be generalizable enough to live outside of AdvisorOnboardingFlow
      next: (nextState, queryParams) => {
        const currentStepIndex = steps.current.findIndex(([, route]) => route() === pathname);
        const isLastStep = currentStepIndex === steps.current.length - 1;

        setState(R.mergeLeft(nextState));

        if (isLastStep) {
          onComplete?.();
          removeState();
          return;
        }

        const [, nextStepRoute] = steps.current[currentStepIndex + 1];
        push(nextStepRoute({ queryParams }));
        setState(
          R.mergeLeft({ currentStepIndex: R.clamp(0, steps.current.length, currentStepIndex + 1) }),
        );
      },
      goBack: () => goBack(),
      skipToComplete: () => {
        removeState();
        onComplete?.();
      },
      state,
    }),
    [state, setState, push, pathname, onComplete, removeState, goBack, steps],
  );

  useEffect(
    () => {
      // In case the user has a step in their sessionStorage, we want to redirect them to that step
      if (state.currentStepIndex) {
        const [, route] = steps.current[state.currentStepIndex];
        push(route());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <AdvisorOnboardingContext.Provider value={value}>{children}</AdvisorOnboardingContext.Provider>
  );
};

export const useAdvisorOnboardingFlowState = <
  TState extends AdvisorOnboardingState,
  TParams extends Record<string, unknown> = Record<string, unknown>,
>() => {
  const context =
    useContext<AdvisorOnboardingContextType<TState, TParams>>(AdvisorOnboardingContext);
  if (!context) {
    throw new Error(
      'useAdvisorOnboardingFlowState used outside of AdvisorOnboardingContext. ' +
        'Ensure you have a <AdvisorOnboardingProvider> higher in the tree.',
    );
  }
  return context;
};

export const useIsOnAdvisorOnboarding = () => {
  const context = useContext(AdvisorOnboardingContext);
  return !!context;
};

export default AdvisorOnboardingProvider;
