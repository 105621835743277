import React from 'react';

import ErrorCard from 'components/errors/ErrorCard';
import LoggedErrorBoundary from 'components/lib/higherOrder/LoggedErrorBoundary';

import onError from 'lib/errors/onError';

const withDefaultErrorBoundary =
  <Props, _>(Component: React.ComponentType<Props>) =>
  (props: Props) => (
    <LoggedErrorBoundary errorComponent={<ErrorCard />} onError={onError}>
      {/* @ts-ignore */}
      <Component {...props} />
    </LoggedErrorBoundary>
  );

export default withDefaultErrorBoundary;
