import { createSelector } from 'reselect';

import type { RootState } from 'state/types';

const getAuthState = ({ auth }: RootState) => auth;

export const getProviderEphemeralData = createSelector(
  getAuthState,
  ({ ephemeralData }) => ephemeralData,
);

export const getOAuthStateString = createSelector(
  getAuthState,
  ({ oAuthStateString }) => oAuthStateString,
);
