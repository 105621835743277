import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import type OverlayTrigger from 'components/lib/ui/popover/OverlayTrigger';
import PremiumFeatureOverlayTriggerShared from 'components/premium/PremiumFeatureOverlayTriggerShared';

import useFeatureEntitlement from 'common/lib/hooks/premium/useFeatureEntitlement';

import type { ProductFeature } from 'common/constants/premium';
import { POPOVER_COPY } from 'common/constants/premium';

const Container = styled.div``;

type Props = Omit<React.ComponentProps<typeof OverlayTrigger>, 'overlay' | 'children'> & {
  feature: ProductFeature | undefined;
  children: React.ReactNode | ((data: ReturnType<typeof useFeatureEntitlement>) => React.ReactNode);
  onClickUpgrade?: () => void;
};

/**
 * Component used to display an upsell popover if the user does not have access to a feature.
 */
const PremiumFeatureOverlayTrigger = ({ feature, children, ...props }: Props) => {
  const featureInfo = useFeatureEntitlement(feature);
  const { hasAccess } = featureInfo;

  const { title = '', description = '' } = feature ? POPOVER_COPY[feature] : {};
  const childrenElement = RA.isFunction(children) ? children(featureInfo) : children;

  return hasAccess ? (
    <Container>{childrenElement}</Container>
  ) : (
    <PremiumFeatureOverlayTriggerShared
      title={title}
      description={description}
      analyticsName={feature ?? ''}
      {...props}
    >
      {childrenElement}
    </PremiumFeatureOverlayTriggerShared>
  );
};

export default PremiumFeatureOverlayTrigger;
