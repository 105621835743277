import pluralize from 'pluralize';
import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import type { DragStart } from 'react-beautiful-dnd';
import styled from 'styled-components';

import AccountListItem from 'components/accounts/AccountListItem';
import DragDropContext from 'components/lib/dnd/DragDropContext';
import Draggable from 'components/lib/dnd/Draggable';
import Droppable from 'components/lib/dnd/Droppable';
import ShowHideToggleDrawer from 'components/lib/ui/ShowHideToggleDrawer';

import useAccountsDrag from 'lib/hooks/accounts/useAccountsDrag';

import type { AccountsListFieldsFragment } from 'common/generated/graphql';

const sortByOrder = R.sortBy(R.prop('order'));

const HiddenAccountListItem = styled(AccountListItem)`
  background: ${({ theme }) => theme.color.grayBackground};

  &:hover {
    background: inherit;
  }

  && {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
    border-radius: 0;
  }
`;

const CustomDroppable = styled(Droppable)<{ $isDraggingOver: boolean }>`
  background: ${({ theme, $isDraggingOver }) =>
    $isDraggingOver ? theme.color.grayLightBackground : 'inherit'};
  transition: ${({ theme }) => theme.transition.default};
`;

type Props = {
  groupName: string;
  accounts: AccountsListFieldsFragment[];
  recentBalancesByAccount?: { [key: string]: number[] };
};

const AccountGroupList = ({ groupName, accounts, recentBalancesByAccount }: Props) => {
  const [dragStartEvent, setDragStartEvent] = useState<DragStart | null>(null);
  const [visibleAccounts, hiddenAccounts] = useMemo(
    () => R.partition(R.allPass([R.propEq('isHidden', false)]), accounts),
    [accounts],
  );
  const { handleOnDragEnd } = useAccountsDrag();

  const accountCopy = pluralize('account', hiddenAccounts.length);
  const sortedAccounts = useMemo(() => sortByOrder(visibleAccounts), [visibleAccounts]);

  return (
    <div>
      <DragDropContext
        onDragEnd={(result) => {
          handleOnDragEnd(result);
          setDragStartEvent(null);
        }}
        onDragStart={(initial) => setDragStartEvent(initial)}
      >
        <CustomDroppable
          droppableId={groupName}
          $isDraggingOver={dragStartEvent?.source.droppableId === groupName}
        >
          {sortedAccounts.map((account, index) => (
            <Draggable
              key={account.id}
              draggableId={`${account.id}`}
              // Because of optimistic updates, the order of the accounts in the list
              // might have some floats, and we need to use an integer here.
              index={parseInt(account.order.toString())}
              isDragDisabled={visibleAccounts.length <= 1}
            >
              <AccountListItem
                key={account.id}
                account={account}
                recentBalances={recentBalancesByAccount?.[account.id]}
                isLast={R.isEmpty(hiddenAccounts) && index === visibleAccounts.length - 1}
                isDraggable={visibleAccounts.length > 1}
                isDragging={dragStartEvent?.draggableId === account.id}
              />
            </Draggable>
          ))}
        </CustomDroppable>
      </DragDropContext>

      {hiddenAccounts.length > 0 && (
        <ShowHideToggleDrawer
          showText={`Show ${hiddenAccounts.length} hidden ${accountCopy}`}
          hideText={`Collapse ${hiddenAccounts.length} hidden ${accountCopy}`}
        >
          {hiddenAccounts.map((account) => (
            <HiddenAccountListItem
              key={account.id}
              account={account}
              recentBalances={recentBalancesByAccount?.[account.id]}
            />
          ))}
        </ShowHideToggleDrawer>
      )}
    </div>
  );
};

export default AccountGroupList;
