import routes from 'constants/routes';

/**
 * We use hyphens instead of underscores in the url because it looks neater
 */
export const getQuestionnaireRoute = (questionnaireName: string, questionName: string) =>
  routes.advice.questionnaire({ questionnaireName, questionName: questionName.replace(/_/g, '-') });

export const getQuestionNameFromRouteParameter = (questionName: string) =>
  questionName.replace(/-/g, '_');
