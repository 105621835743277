import * as Sentry from '@sentry/browser';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import useSpinwheel from 'common/lib/hooks/recurring/useSpinwheel';
import useToggle from 'common/lib/hooks/useToggle';
import { track } from 'lib/analytics/segment';
import useScript from 'lib/hooks/useScript';

import { BillTrackingEventNames } from 'common/constants/analytics';

declare global {
  interface Window {
    Spinwheel?: any;
    spinwheelFailedAuth?: boolean;
  }
}

const DIM_EXIT_EVENT_NAME = 'DROPIN_EXIT';
const CONTAINER_ID = 'spinwheel-container';
const CONNECT_SCRIPT_URL = 'https://cdn.spinwheel.io/dropin/v1/dim-initialize.js';
const HEIGHT_PX = 700;

const StyledCard = styled(Card)`
  overflow: hidden;
  height: ${HEIGHT_PX}px;
`;

const ContainerRoot = styled.div<{ isLoading: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  height: 100%;
`;

const ErrorContainer = styled(FlexContainer).attrs({ center: true, column: true })`
  flex: 1;
`;

type Props = {
  onClose?: () => void;
  onSuccess?: () => void;
};

const SpinwheelDIM = ({ onClose, onSuccess }: Props) => {
  const [connectDidError, { setOn: setConnectDidError }] = useToggle(false);
  const [scriptLoading, scriptError] = useScript(CONNECT_SCRIPT_URL);
  const [isLoading, { setOff: setDoneLoading, setOn: setIsLoading }] = useToggle(true);
  const showLoading = isLoading || scriptLoading;

  const { authSpinwheel } = useSpinwheel();

  useEffect(() => {
    window.spinwheelFailedAuth = false;
  }, []);

  const config = {
    containerId: CONTAINER_ID,
    env: 'prod',
    onSuccess: () => {
      track(BillTrackingEventNames.SpinwheelAuthFinished);
      onSuccess?.();
    },
    onLoad: () => {
      setDoneLoading();
    },
    onEvent: (e: any) => {
      /* eslint-disable-next-line no-console */
      console.log('onEvent', e);
      if (e.eventName.includes('IDENTITY_NOT_CONNECTED')) {
        window.spinwheelFailedAuth = true;
      }
    },
    onExit: (e: any) => {
      /* eslint-disable-next-line no-console */
      console.log('onExit', e);
      // If the original method of auth fails we can override
      // the module to use debt-connect as an alternative flow
      // and start the flow over again.
      const includesEventName = e.eventName.includes(DIM_EXIT_EVENT_NAME);
      if (includesEventName && window.spinwheelFailedAuth) {
        track(BillTrackingEventNames.SpinwheelAuthKBASelected);
        config.dropinConfig.module = 'debt-connect';
        window.spinwheelFailedAuth = false;
        setIsLoading();
        openSpinwheel();
      } else {
        onClose?.();
      }
    },
    onError: (e: { message: string }) => {
      /* eslint-disable-next-line no-console */
      console.log('onError', e);
      track(BillTrackingEventNames.SpinwheelAuthErrored);
      window.spinwheelFailedAuth = true;

      // TODO: just capturing exception for now. There is more we can and should do here eventually. Errors in
      // See docs: https://docs.spinwheel.io/docs/identity-connect-dim-callbacks
      // Errors in this phase of the flow are primarily UI/UX concernsm, known only on the client-side:
      // - token expiration
      // - API timeouts
      // NOTE: credit report failures will not be known here; these are async fire-and-forget calls and appear to be successful
      // to the DIM component and hooks
      const err = new Error('Error in Spinwheel DIM');
      Sentry.captureException(err, {
        extra: {
          spinwheelDIMError: e,
        },
      });
    },
    dropinConfig: {
      module: 'identity-connect',
      verificationStrategy: 'SMS_OTP',
      token: '',
      personalInfoText:
        'Instead of SMS verification, we can confirm your identity using the personal information above. Your sensitive information is never stored or shared by Monarch or Spinwheel.',
      legalText:
        'You also authorize Spinwheel Solutions, Inc. to receive your credit profile from any consumer reporting agency.',
    },
  };

  const openSpinwheel = async () => {
    const spinwheelToken = await authSpinwheel();

    config.dropinConfig = {
      ...config.dropinConfig,
      token: spinwheelToken,
    };

    try {
      const handler = window.Spinwheel && window.Spinwheel.create(config);
      handler.open();
      track(BillTrackingEventNames.SpinwheelAuthStarted);
    } catch (error) {
      setConnectDidError();
      track(BillTrackingEventNames.SpinwheelAuthErrored);
    }
  };

  // opens Spinwheel after the script loaded
  useEffect(() => {
    if (scriptLoading) {
      return;
    }

    openSpinwheel();
  }, [scriptLoading]);

  return (
    <StyledCard>
      <Switch>
        <Case when={connectDidError || scriptError}>
          <ErrorContainer>
            <p>Sorry, something went wrong. Please try again.</p>
            <DefaultButton size="medium" onClick={() => onClose?.()}>
              Close
            </DefaultButton>
          </ErrorContainer>
        </Case>
        <Case default>
          <>
            {showLoading && (
              <LoadingContainer>
                <LoadingSpinner />
              </LoadingContainer>
            )}
            <ContainerRoot id={CONTAINER_ID} isLoading={showLoading} />
          </>
        </Case>
      </Switch>
    </StyledCard>
  );
};

export default SpinwheelDIM;
