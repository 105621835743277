import { Duration } from 'luxon';

import { HAS_UNREAD_QUERY } from 'common/lib/graphQl/user';
import useQueryWithCacheExpiration from 'common/lib/hooks/useQueryWithCacheExpiration';

/**
 * Simple wrapper hook over `HAS_UNREAD_QUERY` to check if the user has any unread notifications.
 * Allows flexible control over the query and room for future enhancements in caching
 * strategies or other custom logic that may be needed.
 */
const useHasUnreadActivity = () => {
  const query = useQueryWithCacheExpiration(HAS_UNREAD_QUERY, {
    cacheExpiration: Duration.fromObject({ minutes: 10 }),
  });
  const hasUnreadActivity = query?.data?.me?.hasNewActivity ?? false;
  return [hasUnreadActivity, query] as const;
};

export default useHasUnreadActivity;
