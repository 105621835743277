import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import CategorySelect from 'components/categories/CategorySelect';
import GoalSelect from 'components/goalsV2/GoalSelect';
import SelectField from 'components/lib/form/SelectField';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Divider from 'components/lib/ui/Divider';
import Icon from 'components/lib/ui/Icon';
import MerchantSearchSelect from 'components/transactions/MerchantSearchSelect';
import {
  TransactionRuleFormTitle,
  TransactionRuleFormToggleCard,
} from 'components/transactions/rules/TransactionRuleFormComponents';
import TransactionTagSelect from 'components/transactions/tags/TransactionTagSelect';

import useHouseholdUsers, { ANYONE_ID } from 'common/lib/hooks/household/useHouseholdUsers';
import { color, spacing, fontWeight, radius } from 'common/lib/theme/dynamic';
import { REVIEW_STATUS_OPTIONS, ReviewStatus } from 'common/lib/transactions/review';
import useTransactionRulesForm from 'lib/hooks/transactions/useTransactionRulesForm';

const ICON_SIZE_PX = 20;

const StyledDivider = styled(Divider)`
  padding: ${spacing.xlarge} 0;
`;

const SplitTransactionCard = styled.div`
  border-radius: ${radius.medium};
  background: ${color.grayBackground};
`;

const SplitTransactionCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.small} ${spacing.default};
  font-weight: ${fontWeight.medium};
  cursor: pointer;
  user-select: none;
`;

const RightArrowIcon = styled(Icon).attrs({ size: ICON_SIZE_PX, name: 'arrow-right' })`
  color: ${color.grayDark};
`;

const TransactionRuleFormCoreActionsContainer = ({
  onSetUpSplitAction,
}: {
  onSetUpSplitAction: () => void;
}) => {
  const { values } = useContext(FormContext);
  const { toggleProps } = useTransactionRulesForm();

  const [{ users }] = useHouseholdUsers();

  const reviewersOptions = useMemo(
    () => [
      {
        value: ANYONE_ID,
        label: 'By anyone',
      },
      ...users.map((user) => ({
        value: user.id,
        label: `By ${user.name}`,
      })),
    ],
    [users],
  );

  return (
    <>
      <TransactionRuleFormTitle>Then apply these updates...</TransactionRuleFormTitle>
      <TransactionRuleFormToggleCard title="Rename merchant" {...toggleProps('setMerchantAction')}>
        <SelectField
          name="setMerchantAction"
          placeholder="Rename to..."
          hideLabel
          menuPortalTarget={document.body}
          InputComponent={MerchantSearchSelect}
          {...sensitiveClassProps}
        />
      </TransactionRuleFormToggleCard>
      <TransactionRuleFormToggleCard title="Update category" {...toggleProps('setCategoryAction')}>
        <SelectField
          name="setCategoryAction"
          placeholder="Change category to..."
          hideLabel
          menuPortalTarget={document.body}
          InputComponent={CategorySelect}
        />
      </TransactionRuleFormToggleCard>
      <TransactionRuleFormToggleCard title="Add tags" {...toggleProps('addTagsAction')}>
        <SelectField
          name="addTagsAction"
          hideLabel
          menuPortalTarget={document.body}
          InputComponent={TransactionTagSelect}
        />
      </TransactionRuleFormToggleCard>
      <TransactionRuleFormToggleCard
        title="Hide transaction"
        {...toggleProps('setHideFromReportsAction')}
      />
      <TransactionRuleFormToggleCard title="Review status" {...toggleProps('reviewStatusAction')}>
        <SelectField
          name="reviewStatusAction"
          hideLabel
          options={REVIEW_STATUS_OPTIONS}
          menuPortalTarget={document.body}
        />

        {values.reviewStatusAction === ReviewStatus.NeedsReview && (
          <SelectField
            name="needsReviewByUserAction"
            hideLabel
            menuPortalTarget={document.body}
            options={reviewersOptions}
          />
        )}
      </TransactionRuleFormToggleCard>
      <TransactionRuleFormToggleCard title="Link to goal" {...toggleProps('linkGoalAction')}>
        <SelectField
          name="linkGoalAction"
          isDisabled={!values.accountIds?.length}
          hideLabel
          menuPortalTarget={document.body}
          InputComponent={GoalSelect}
          accountIds={values.accountIds}
          noGoalLabel={!values.accountIds?.length && 'Need to select a goal account'}
        />
      </TransactionRuleFormToggleCard>
      <StyledDivider />
      <SplitTransactionCard>
        <SplitTransactionCardHeader onClick={onSetUpSplitAction}>
          <span>Split transaction</span>
          <RightArrowIcon />
        </SplitTransactionCardHeader>
      </SplitTransactionCard>
    </>
  );
};

export default TransactionRuleFormCoreActionsContainer;
