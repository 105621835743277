import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import CategoryRolloverFields from 'components/categories/CategoryRolloverFields';
import FixedFlexCategoryRolloverFields from 'components/categories/FixedFlexCategoryRolloverFields';
import CombinedEmojiAndTextFields from 'components/lib/form/CombinedEmojiAndTextFields';
import FieldCell from 'components/lib/form/FieldCell';
import RadioGroupField from 'components/lib/form/RadioGroupField';
import SelectField from 'components/lib/form/SelectField';
import ToggleField from 'components/lib/form/ToggleField';
import FeatureFlagGate from 'components/lib/higherOrder/FeatureFlagGate';
import Link from 'components/lib/ui/Link';

import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import useCategoryGroupSelectOptions from 'lib/hooks/useCategoryGroupSelectOptions';

import { BUDGET_VARIABILITY } from 'common/constants/copy';
import { HELP_CENTER_BUDGETS_ARTICLE_URL } from 'common/constants/externalUrls';

import { gql } from 'common/generated/gql';
import { BudgetSystem, CategoryGroupType, BudgetVariability } from 'common/generated/graphql';

export const CATEGORY_NAME_FIELD_NAME = 'name';
export const GROUP_FIELD_NAME = 'group';

const SpacelessSelectField = styled(SelectField)`
  && {
    margin-bottom: 0;
  }
`;

const SpacelessRadioGroupField = styled(RadioGroupField)`
  && {
    margin-bottom: 0;
  }
`;

type Props = {
  categoryType?: string;
  isSystemCategory?: boolean;
  systemCategoryDisplayName?: string | null;
};

const CreateEditCategoryFormFields = ({
  categoryType = undefined,
  isSystemCategory = false,
  systemCategoryDisplayName,
}: Props) => {
  const { budgetSystem } = useHouseholdPreferences();
  const [isLoadingGroups, groupOptions, categoryGroups] =
    useCategoryGroupSelectOptions(categoryType);

  const { getFieldMeta } = useFormContext();
  const { value: categoryName } = getFieldMeta(CATEGORY_NAME_FIELD_NAME);
  const { value: group } = getFieldMeta(GROUP_FIELD_NAME);

  const categoryGroup = R.find(R.propEq('id', group), categoryGroups ?? []);
  const isGroupBudgetingEnabledForGroup = categoryGroup?.groupLevelBudgetingEnabled ?? true;
  const groupType = categoryGroup?.type ?? categoryType;

  const textFieldDescription = `This system category automatically categorizes transactions related to ${
    systemCategoryDisplayName ?? categoryName
  }.`;

  const options = useMemo(() => {
    if (categoryType) {
      return groupOptions;
    }

    // Have to maintain both old and new values for now or type checking complains
    const groupedByType: Record<
      CategoryGroupType,
      { options: typeof groupOptions; label: string }
    > = {
      [CategoryGroupType.E]: {
        label: 'Expense',
        options: [],
      },
      [CategoryGroupType.EXPENSE]: {
        label: 'Expense',
        options: [],
      },
      [CategoryGroupType.I]: {
        label: 'Income',
        options: [],
      },
      [CategoryGroupType.INCOME]: {
        label: 'Income',
        options: [],
      },
      [CategoryGroupType.T]: {
        label: 'Transfer',
        options: [],
      },
      [CategoryGroupType.TRANSFER]: {
        label: 'Transfer',
        options: [],
      },
    };

    groupOptions.forEach((option) => {
      const groupType = option.type;

      if (!groupedByType[groupType]) {
        return;
      }

      groupedByType[groupType].options = [...groupedByType[groupType].options, option];
    });

    return Object.values(groupedByType);
  }, [categoryType, groupOptions]);

  const householdUsesFixedFlexBudgeting = budgetSystem === BudgetSystem.FIXED_AND_FLEX;
  const shouldShowBudgetVariabilityOptions =
    groupType === CategoryGroupType.EXPENSE &&
    !isGroupBudgetingEnabledForGroup &&
    householdUsesFixedFlexBudgeting;
  const shouldShowRolloverFields =
    groupType === CategoryGroupType.EXPENSE && !isGroupBudgetingEnabledForGroup;

  return (
    <>
      <CombinedEmojiAndTextFields
        label="Icon & Name"
        emojiField={{
          name: 'icon',
        }}
        textField={{
          name: CATEGORY_NAME_FIELD_NAME,
          placeholder: 'New Category',
          required: true,
          autoComplete: 'off',
        }}
        description={isSystemCategory && categoryName ? textFieldDescription : undefined}
      />

      <SpacelessSelectField name="group" options={options} isLoading={isLoadingGroups} required />

      {shouldShowBudgetVariabilityOptions && (
        <FeatureFlagGate name="fixed-flex-budgeting">
          <SpacelessRadioGroupField
            name="budgetVariability"
            label="Type"
            options={[
              {
                value: BudgetVariability.FIXED,
                label: BUDGET_VARIABILITY.fixed.label,
                description: BUDGET_VARIABILITY.fixed.description,
                bottomAccessory: shouldShowRolloverFields ? (
                  <FixedFlexCategoryRolloverFields />
                ) : undefined,
              },
              {
                value: BudgetVariability.FLEXIBLE,
                label: BUDGET_VARIABILITY.flex.label,
                description: BUDGET_VARIABILITY.flex.description,
                bottomAccessory: shouldShowRolloverFields ? (
                  <FixedFlexCategoryRolloverFields />
                ) : undefined,
              },
              {
                value: BudgetVariability.NON_MONTHLY,
                label: BUDGET_VARIABILITY.nonMonthly.label,
                description: BUDGET_VARIABILITY.nonMonthly.description,
                bottomAccessory: shouldShowRolloverFields ? (
                  <FixedFlexCategoryRolloverFields />
                ) : undefined,
              },
            ]}
            required
          />
        </FeatureFlagGate>
      )}

      {/* Rollover fields for Category & Groups budgeting */}
      {shouldShowRolloverFields && !householdUsesFixedFlexBudgeting && <CategoryRolloverFields />}

      <FieldCell
        rightAccessory={<ToggleField name="excludeFromBudget" hideLabel />}
        title="Exclude this category from the budget"
        subtitle={
          <>
            This category and any transactions linked to it will be hidden from your budget.{' '}
            <Link href={HELP_CENTER_BUDGETS_ARTICLE_URL} target="_blank">
              Learn more
            </Link>
          </>
        }
      />
    </>
  );
};

CreateEditCategoryFormFields.fragments = {
  CategoryFormFields: gql(`
    fragment CategoryFormFields on Category {
      id
      order
      name
      icon
      systemCategory
      systemCategoryDisplayName
      budgetVariability
      excludeFromBudget
      isSystemCategory
      isDisabled
      group {
        id
        type
        groupLevelBudgetingEnabled
      }
      rolloverPeriod {
        id
        startMonth
        startingBalance
        type
        frequency
        targetAmount
      }
    }
  `),
};

export default CreateEditCategoryFormFields;
