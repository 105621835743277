import * as RA from 'ramda-adjunct';
import * as React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import SettingsCard from 'components/lib/layouts/SettingsCard';
import Banner from 'components/lib/ui/Banner';
import Column from 'components/lib/ui/Column';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import ReferralTracker from 'components/settings/referrals/ReferralTracker';
import ShareLinkSection from 'components/settings/referrals/ShareLinkSection';

import useSubscriptionDetails from 'common/lib/hooks/billing/useSubscriptionDetails';
import useQuery from 'common/lib/hooks/useQuery';
import useSharedConstants from 'common/lib/hooks/useSharedConstants';
import { formatCurrency } from 'common/utils/Currency';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';

import { REFERRALS } from 'common/constants/copy';
import { HELP_CENTER_SWITCH_BILLING_ARTICLE_URL } from 'common/constants/externalUrls';

import { gql } from 'common/generated/gql';
import { SubscriptionDetailsPaymentSource } from 'common/generated/graphql';

const PAYMENT_SOURCE_TO_LABEL: Record<SubscriptionDetailsPaymentSource, string> = {
  STRIPE: 'Stripe',
  PLAY_STORE: 'Play Store',
  APP_STORE: 'App Store',
  PROMOTIONAL: 'Promotional',
};

const Card = styled(SettingsCard)`
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
`;

const CardInnerContent = styled.div`
  margin: ${({ theme }) => theme.spacing.default} 0;
`;

const List = styled.ul`
  line-height: 180%;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  list-style-position: outside;
  padding: 0 1.5em;

  li {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.xsmall};
    }
  }
`;

const PaymentSourceMessage = styled(Banner).attrs({ type: 'error' })`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const Line = styled.span`
  display: block;
`;

const Content = styled(FlexContainer).attrs({ column: true })`
  padding: 0 ${({ theme }) => theme.spacing.xlarge};
`;

const ReferralSettings = () => {
  const isReferralPromoCopyChangesActive = useIsFeatureFlagOn('referral-promo-test-copy-changes', {
    sendToMsClarity: true,
  });

  const { data, isLoadingInitialData: isLoadingStatistics } = useQuery(REFERRAL_SETTINGS_QUERY);
  const statistics = data?.referralStatistics;

  const { paymentSource, referralCode, isLoading } = useSubscriptionDetails();
  const [
    { monthlyPriceDollars, referralsTrialDurationDays, activeReferralCampaign },
    { isLoading: isLoadingConstants },
  ] = useSharedConstants();

  return (
    <React.Fragment>
      <Helmet>
        <title>Share Monarch</title>
      </Helmet>
      <Column md={9}>
        {RA.isNotNil(paymentSource) &&
          paymentSource !== SubscriptionDetailsPaymentSource.STRIPE && (
            <PaymentSourceMessage>
              <Line>
                You&apos;re being billed through the{' '}
                <Text weight="medium">{PAYMENT_SOURCE_TO_LABEL[paymentSource]}</Text>, which
                doesn&apos;t allow for referral rewards.
              </Line>
              <Line>
                Please switch to our online billing by following the instructions{' '}
                <Link
                  href={HELP_CENTER_SWITCH_BILLING_ARTICLE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </Link>{' '}
                to earn referral rewards.
              </Line>
            </PaymentSourceMessage>
          )}
        <Card
          title={
            isReferralPromoCopyChangesActive
              ? 'Extra rewards for sharing Monarch'
              : 'Earn rewards for sharing Monarch'
          }
          loading={isLoadingConstants}
        >
          <Content>
            {isReferralPromoCopyChangesActive ? (
              <CardInnerContent>
                <Text>
                  Have friends or family who would love Monarch? Now through July 10th, we&apos;re
                  sweetening the deal for both you and them.{' '}
                </Text>
                <List>
                  {activeReferralCampaign?.creditsEarned && (
                    <li>
                      You&apos;ll get ${activeReferralCampaign.creditsEarned.toFixed()} in credit
                      for every friend you refer to Monarch, once they become an annual subscriber.{' '}
                      <Text weight="medium">That&apos;s a year free for every 3 friends.</Text>
                    </li>
                  )}
                  <li>
                    The people you invite get an{' '}
                    <Text weight="medium">
                      extended 30-day trial, plus 50% off an annual subscription.
                    </Text>
                  </li>
                </List>
                <Text>
                  Visit this{' '}
                  <Link
                    href={REFERRALS.JUNE_2024_DISCOUNT.HELP_CENTER_ARTICLE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    help center article
                  </Link>{' '}
                  for more detail.
                </Text>
              </CardInnerContent>
            ) : (
              <List>
                <li>
                  Get <Text weight="medium">{formatCurrency(monthlyPriceDollars)} in credits</Text>{' '}
                  for every subscriber you refer to Monarch, after they pay for their first month
                </li>
                <li>
                  The people you invite get an{' '}
                  <Text weight="medium">extended {referralsTrialDurationDays}-day trial</Text>
                </li>
                <li>Share your unique link anywhere!</li>
              </List>
            )}
          </Content>
        </Card>
        <Card title="Share your referral link" loading={isLoading}>
          <ShareLinkSection code={referralCode ?? ''} />
        </Card>
        <Card title="Referrals" loading={isLoadingStatistics}>
          {RA.isNotNil(statistics) && <ReferralTracker statistics={statistics} />}
        </Card>
      </Column>
    </React.Fragment>
  );
};

const REFERRAL_SETTINGS_QUERY = gql(/* GraphQL */ `
  query Web_GetReferralSettings {
    referralStatistics {
      creditsEarned
      subscribed
      signedUp
    }
  }
`);

export default ReferralSettings;
