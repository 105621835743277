import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import ContentLoader from 'components/lib/ui/ContentLoader';
import FlexContainer from 'components/lib/ui/FlexContainer';

type Props = { columnNames: string[] };

const Root = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  overflow: hidden;
`;

const HeaderTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding: ${({ theme }) => `${theme.spacing.default}`} 2.3%;
  display: inline-block;
  text-align: left;
  flex-basis: 10%;
`;

const ItemRoot = styled.div`
  height: 80px;
  width: 100%;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const HeaderRoot = styled(FlexContainer)`
  ${HeaderTitle}:first-child {
    flex-basis: 20%;
    padding-left: ${({ theme }) => theme.spacing.xlarge};
  }
  ${HeaderTitle}:last-child {
    flex-basis: 20%;
    text-align: right;
    padding-right: ${({ theme }) => theme.spacing.xlarge};
  }
`;

const Header = ({ columnNames }: Props) => (
  <HeaderRoot justifyBetween alignCenter full>
    {R.map(
      (name) => (
        <HeaderTitle key={name}>{name}</HeaderTitle>
      ),
      columnNames,
    )}
  </HeaderRoot>
);

const Item = () => (
  <ItemRoot>
    <ContentLoader>
      <circle cx="44" cy="40" r="20" />
      <rect x="calc(10% - 24px)" y="22" rx="5" ry="5" width="12%" height="16" />
      <rect x="calc(10% - 24px)" y="44" rx="5" ry="5" width="6%" height="12" />
      <rect x="calc(31% - 24px)" y="30" rx="5" ry="5" width="5%" height="16" />
      <rect x="calc(49% - 24px)" y="30" rx="5" ry="5" width="8%" height="16" />
      <rect x="calc(66% - 24px)" y="30" rx="5" ry="5" width="14%" height="16" />
      <rect x="calc(93% - 24px)" y="30" rx="5" ry="5" width="7%" height="16" />
    </ContentLoader>
  </ItemRoot>
);

const RecurringTableLoading = ({ columnNames }: Props) => (
  <Root>
    <Header columnNames={columnNames} />
    {R.range(0, 9).map((i) => (
      <Item key={i} />
    ))}
  </Root>
);

export default RecurringTableLoading;
