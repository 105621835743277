import { propEq } from 'ramda';
import React from 'react';
import styled from 'styled-components';

import EditCategoryModal from 'components/categories/EditCategoryModal';
import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Modal from 'components/lib/ui/Modal';
import FooterButton from 'components/plan/onboarding/FooterButton';
import OnboardingVariabilityCard from 'components/plan/onboarding/OnboardingVariabilityCard';
import type { OnboardingSharedPageProps } from 'components/routes/budget/BudgetOnboardingFlow';

import { useBudgetOnboardingActions } from 'common/lib/hooks/budget/useBudgetOnboardingActions';
import { useUpdateCategoryBudgetVariability } from 'common/lib/hooks/budget/useUpdateCategoryBudgetVariability';
import useEventCallback from 'common/lib/hooks/useEventCallback';
import { spacing } from 'common/lib/theme/dynamic';
import { useCategoryActions } from 'lib/hooks/category/useCategoryActions';
import usePlanQuery from 'lib/hooks/plan/usePlanQuery';
import usePlanState from 'lib/hooks/plan/usePlanState';

import type { BudgetVariability } from 'common/generated/graphql';
import { CategoryGroupType } from 'common/generated/graphql';

const Column = styled(FlexContainer).attrs({ column: true, full: true })`
  max-width: 600px;
  gap: ${spacing.small};
  padding-bottom: 140px;
`;

type Props = {
  onBack: () => void;
  onCancel: () => void;
  onNext: () => void;
} & OnboardingSharedPageProps;

const OnboardingSelectVariabilities = ({
  title,
  description,
  progress,
  onBack,
  onCancel,
  onNext,
}: Props) => {
  const [state] = usePlanState();
  const { data } = usePlanQuery(state);
  const [updateCategoryBudgetVariability] = useUpdateCategoryBudgetVariability();

  const isLoading = data === undefined;
  const groups = data?.categoryGroups.filter(propEq('type', CategoryGroupType.EXPENSE)) ?? [];

  const updateBudgetVariability = useEventCallback(
    async (id: string, budgetVariability: BudgetVariability) => {
      await updateCategoryBudgetVariability(id, budgetVariability);
    },
  );

  const {
    editingCategoryId,
    setEditingCategoryId,
    deleteCategoryWithConfirmation,
    confirmingDeleteCategoryId,
    updateHideFromBudget,
    DeleteCategoryConfirmationModal,
  } = useCategoryActions();

  const { safeRecalculateFixedAndFlexExpensesBudget, isLoading: isMutationLoading } =
    useBudgetOnboardingActions();

  return (
    <FeatureOnboardingPage
      pageName="How do you want to budget?"
      title={title}
      description={description}
      headerMaxWidth={714}
      descriptionMaxWidth={690}
      progress={progress}
      onClickBack={onBack}
      onClickCancel={onCancel}
      hideNextButton
    >
      <Column>
        {isLoading ? (
          <FlexContainer marginVertical="xxxxlarge" full center>
            <LoadingSpinner />
          </FlexContainer>
        ) : (
          <OnboardingVariabilityCard
            groups={groups}
            onUpdateVariability={updateBudgetVariability}
            onEditCategory={setEditingCategoryId}
            onHideFromBudget={(id) => updateHideFromBudget(id, true)}
            onDeleteOrDisableCategory={deleteCategoryWithConfirmation}
          />
        )}
      </Column>

      <FooterButton
        isLoading={isMutationLoading}
        onClickNext={async () => {
          // Calculates the fixed and flexible budgets before going to the next page
          await safeRecalculateFixedAndFlexExpensesBudget();
          onNext();
        }}
      />

      {!!editingCategoryId && (
        <Modal onClose={() => setEditingCategoryId(undefined)}>
          {({ close }) => <EditCategoryModal categoryId={editingCategoryId} onDone={close} />}
        </Modal>
      )}
      {confirmingDeleteCategoryId && <DeleteCategoryConfirmationModal />}
    </FeatureOnboardingPage>
  );
};

export default OnboardingSelectVariabilities;
