import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

const ICON_SIZE_PX = 24;

const Root = styled(FlexContainer).attrs({ column: true })`
  background-color: ${({ theme }) => theme.color.grayLightBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
  padding: ${({ theme }) => theme.spacing.default};
`;

const List = styled(FlexContainer).attrs({ column: true })`
  margin-top: ${({ theme }) => theme.spacing.default};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  gap: ${({ theme }) => theme.spacing.large};
`;

const Item = styled.div`
  --icon-padding: ${({ theme }) => theme.spacing.default};

  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns:
    calc(${ICON_SIZE_PX}px + var(--icon-padding))
    1fr;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const CustomIcon = styled(Icon).attrs({ size: ICON_SIZE_PX })`
  margin: 0 auto;
`;

type ItemProps = {
  iconName: string;
  children: React.ReactNode;
};

type Props = {
  title: string;
  children: React.ReactNode;
  className?: string;
};

const InformationList = ({ title, children, className }: Props) => (
  <Root className={className}>
    <Text weight="medium" size="small">
      {title}
    </Text>
    <List>{children}</List>
  </Root>
);

const InformationItem = ({ iconName, children }: ItemProps) => (
  <Item>
    <CustomIcon name={iconName} />
    <Text>{children}</Text>
  </Item>
);

export default InformationList;
export { InformationItem };
