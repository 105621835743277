import React from 'react';

import Tag from 'components/transactions/Tag';

type Props = {
  merchantCriteriaUseOriginalStatement: boolean | null | undefined;
};

const MerchantCriteriaTag = ({ merchantCriteriaUseOriginalStatement }: Props) => {
  if (
    merchantCriteriaUseOriginalStatement === null ||
    merchantCriteriaUseOriginalStatement === undefined
  ) {
    return null;
  }

  const criteriaLabel = merchantCriteriaUseOriginalStatement
    ? 'original statement'
    : 'merchant name';

  return <Tag $marginLeft>{criteriaLabel}</Tag>;
};

export default MerchantCriteriaTag;
