import * as R from 'ramda';
import React, { useCallback, useContext, useMemo } from 'react';
import type { Components } from 'react-virtuoso';
import { GroupedVirtuoso } from 'react-virtuoso';
import styled from 'styled-components';

import HoldingsListFooter from 'components/holdings/HoldingsListFooter';
import HoldingsListHeader from 'components/holdings/HoldingsListHeader';
import { HoldingsRowsLoading } from 'components/holdings/HoldingsListLoading';
import HoldingsListRow from 'components/holdings/HoldingsListRow';
import HoldingDrawer from 'components/holdings/drawer/HoldingDrawer';
import Card from 'components/lib/ui/Card';
import CardHeader from 'components/lib/ui/CardHeader';
import Controls from 'components/lib/ui/Controls';
import FlexContainer from 'components/lib/ui/FlexContainer';
import SectionHeader from 'components/lib/ui/SectionHeader';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import ScrollContext from 'lib/contexts/ScrollContext';

import type HoldingSection from 'common/types/HoldingSection';
import type {
  Web_GetHoldingsQueryHoldingAggregateHolding,
  Web_GetHoldingsQueryHoldingAggregate,
} from 'common/types/investments';

const EmptyWrapper = styled.div`
  align-self: center;
`;

const HeaderContainer = styled.div`
  position: relative;
`;

const ListContainer = styled(FlexContainer).attrs({ column: true })``;

const HoldingSectionHeader = styled(SectionHeader)`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.large};
`;

const HoldingHeaderCard = styled(Card)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const HoldingsHeader = styled(CardHeader)`
  padding: ${({ theme }) => theme.spacing.large};
  height: 60px;
`;

type Props = {
  totalValue: number;
  sections: HoldingSection<NonNullable<Web_GetHoldingsQueryHoldingAggregate>>[];
  emptyComponent?: React.ReactNode;
  showSectionHeaders?: boolean;
  selectedHolding?: Web_GetHoldingsQueryHoldingAggregateHolding;
  onSelectHolding: (
    holding: NonNullable<Web_GetHoldingsQueryHoldingAggregateHolding> | undefined,
  ) => void;
  onHoldingDrawerClose: () => void;
  isLoadingHoldings?: boolean;
  onRequestNextPage?: () => void;
  hideHeader?: boolean;
  onAddHoldingButtonClick?: () => void;
};

const HoldingsList = ({
  sections,
  totalValue,
  emptyComponent,
  selectedHolding,
  onSelectHolding,
  onHoldingDrawerClose,
  onRequestNextPage,
  isLoadingHoldings,
  hideHeader,
  onAddHoldingButtonClick,
}: Props) => {
  const sectionCount = useMemo(() => sections.map(({ data }) => data.length), [sections]);
  const flatData = useMemo(() => sections.flatMap(({ data }) => data), [sections]);
  const selectedHoldingAggregate = useMemo(
    () => flatData.find((el) => el?.holdings[0]?.id === selectedHolding?.id),
    [flatData, selectedHolding],
  );

  const handleChevronClick = useCallback(
    (holding?: NonNullable<Web_GetHoldingsQueryHoldingAggregateHolding> | undefined) => {
      onSelectHolding(holding);
    },
    [onSelectHolding],
  );

  const { scrollRef } = useContext(ScrollContext);

  const components = useMemo(
    (): Components => ({
      ...(hideHeader
        ? {}
        : {
            TopItemList: ({ children, ...props }) => (
              <div {...props}>
                <HeaderContainer>
                  <HoldingsListHeader />
                </HeaderContainer>
                {children}
              </div>
            ),
          }),
      Group: ({ children, style, ...props }) => (
        // We need this so section headers don't overlap other elements (see ENG-5527)
        <div style={{ ...style, zIndex: 'auto' }} {...props}>
          {children}
        </div>
      ),
      Footer: () => <HoldingsListFooter hide={flatData.length === 0} totalValue={totalValue} />,
      EmptyPlaceholder: () =>
        !isLoadingHoldings && sections.length === 0 ? (
          <Card>
            <EmptyWrapper>{emptyComponent}</EmptyWrapper>
          </Card>
        ) : null,
    }),
    [emptyComponent, hideHeader, sections.length, flatData.length, totalValue, isLoadingHoldings],
  );

  return (
    <>
      {!R.isEmpty(sections) && (
        <HoldingHeaderCard>
          <HoldingsHeader
            title="Holdings"
            controls={
              <Controls>
                <DefaultButton className="fs-add-manual-holding" onClick={onAddHoldingButtonClick}>
                  Add holding
                </DefaultButton>
              </Controls>
            }
          />
        </HoldingHeaderCard>
      )}
      <ListContainer>
        <Card>
          {isLoadingHoldings ? (
            <HoldingsRowsLoading />
          ) : (
            <GroupedVirtuoso
              style={{ height: 'auto', minHeight: '164px' }} // Needs to set a minHeight, so it shows the emptyComponent properly
              customScrollParent={scrollRef?.current ?? undefined}
              groupCounts={sectionCount}
              components={components}
              rangeChanged={({ startIndex }) => false}
              groupContent={(index) => {
                const { typeDisplay } = sections[index];
                return <HoldingSectionHeader>{typeDisplay}</HoldingSectionHeader>;
              }}
              itemContent={(index) => {
                const aggregateHoldings = flatData[index];
                const [holding] = aggregateHoldings.holdings;
                return (
                  <div style={index === 0 && !hideHeader ? { marginTop: 56 } : {}}>
                    <HoldingsListRow
                      key={holding?.id}
                      holdingsAggregate={aggregateHoldings}
                      onHover={() => undefined}
                      onChevronClick={() => {
                        handleChevronClick(holding);
                      }}
                    />
                  </div>
                );
              }}
              endReached={onRequestNextPage}
            />
          )}
        </Card>
      </ListContainer>
      {!!selectedHolding?.id && (
        <HoldingDrawer
          holdingAggregate={selectedHoldingAggregate!}
          onClose={onHoldingDrawerClose}
        />
      )}
    </>
  );
};

export default HoldingsList;
