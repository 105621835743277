import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import type { ActionMeta } from 'react-select';
import styled from 'styled-components';

import CreateCategoryModal from 'components/categories/CreateCategoryModal';
import type { Props as FullScreenSelectProps } from 'components/lib/form/FullScreenSelect';
import { useSelectContext, FullScreenSelect } from 'components/lib/form/FullScreenSelect';
import type { Props as SelectProps } from 'components/lib/form/Select';
import ContentLoader from 'components/lib/ui/ContentLoader';
import Emoji from 'components/lib/ui/Emoji';
import Flex from 'components/lib/ui/Flex';
import Icon from 'components/lib/ui/Icon';
import Modal from 'components/lib/ui/Modal';

import { sortByOrder, sortCategoryGroups } from 'common/lib/categories/Adapters';
import useQuery from 'common/lib/hooks/useQuery';
import { includesIgnoreCaseAndSpaces } from 'common/utils/String';
import { track } from 'lib/analytics/segment';

import { AnalyticsEventNames } from 'common/constants/analytics';

import { gql } from 'common/generated/gql';
import type {
  CategoryGroupType,
  CreateCategoryInput,
  GetCategorySelectOptionsQuery,
  RecommendedCategory,
} from 'common/generated/graphql';
import type { Id } from 'common/types';

export type OptionType = {
  value: string;
  label: React.ReactNode | string;
  icon?: React.ReactNode;
  isRecommendedCategory?: boolean;
};

export type CategorySelectFilters = {
  type?: CategoryGroupType;
  excludeCategories?: Id[];
};

type Props = Omit<SelectProps, 'onChange'> & {
  filters?: CategorySelectFilters;
  onChange?: (
    value: OptionType,
    action: ActionMeta | undefined,
    categories: GetCategorySelectOptionsQuery['categories'],
  ) => void;
  /** Only supported if isMulti == false */
  isCreatable?: boolean;
  createInitialValues?: Partial<CreateCategoryInput>;
  /** Show "Search categories..." placeholder once menu is opened, even if there is a current value */
  showPlaceholderWhenMenuOpen?: boolean;
  className?: string;
  placeholder?: string;
  transactionId?: string;
  onBlur?: any;
  alwaysShowBorder?: boolean;
  fullWidthTrigger?: boolean;
  transactionProviderDescription?: string | null;
} & Pick<FullScreenSelectProps, 'disabled' | 'italicizeValueLabel'>;

const FullHeightCategorySelect = ({
  filters,
  onChange,
  value,
  onBlur,
  createInitialValues,
  transactionId,
  alwaysShowBorder = true,
  isCreatable,
  placeholder,
  fullWidthTrigger,
  transactionProviderDescription,
  ...props
}: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [newCategoryName, setNewCategoryName] = useState<string | undefined>();
  const [categoryRecommendations, setCategoryRecommendations] = useState<RecommendedCategory[]>([]);
  const [isLoadingCategoryRecommendations, setIsLoadingCategoryRecommendations] =
    useState<boolean>(true);

  // Queries
  const { data: householdCategoriesData } = useQuery(GET_CATEGORIES, {
    fetchPolicy: 'cache-first',
  });
  const { refetch: refetchRecommendedCategories, isLoadingInitialData } = useQuery(
    GET_RECOMMENDED_CATEGORIES,
    {
      skip: true,
      variables: {
        transactionId: transactionId ?? '',
      },
    },
  );

  const { categoryGroups: householdCategoryGroups = [], categories: householdCategories = [] } =
    householdCategoriesData ?? {};
  const originalCategory = householdCategories.find((category) => category.id === value?.value);

  useEffect(() => {
    setIsLoadingCategoryRecommendations(false);
  }, []);

  const handleFullScreenSelectOpened = () => {
    if (!transactionId) {
      return;
    }
    setIsLoadingCategoryRecommendations(true);
    refetchRecommendedCategories().then((resp) => {
      // @ts-ignore
      setCategoryRecommendations(resp?.data?.recommendedCategories ?? []);
      setIsLoadingCategoryRecommendations(false);
    });
  };

  const allGroups = useMemo(() => {
    const shouldShowLoadingCategoryRecommendations = isLoadingCategoryRecommendations;

    const shouldHideCategoryRecommendationsSection =
      !transactionProviderDescription ||
      !RA.isEmptyString(searchQuery) ||
      (!isLoadingCategoryRecommendations && R.isEmpty(categoryRecommendations));

    return [
      ...(shouldHideCategoryRecommendationsSection
        ? []
        : [
            {
              fixed: true,
              id: 'recommended',
              label: 'Recommended',
              options: categoryRecommendations
                .map((el) => ({
                  id: el.category.id,
                  name: el.category.name,
                  icon: el.category.icon,
                  occurrences: el.occurrences,
                }))
                .map(optionFromCategory),
              loading: shouldShowLoadingCategoryRecommendations,
              loadingComponent: <CategoryListSkeleton />,
            },
          ]),
      ...sortCategoryGroups(applyFilters(householdCategoryGroups, filters)).map(
        ({ id, name, categories }) => ({
          id,
          label: name,
          fixed: false,
          options: sortByOrder(categories).map(optionFromCategory),
          loading: false,
        }),
      ),
    ]
      .map((group) => ({
        ...group,
        options: group.options.filter((option) =>
          searchQuery ? includesIgnoreCaseAndSpaces(option.name, searchQuery) : true,
        ),
      }))
      .filter((group) => group.fixed || group.options.length > 0);
  }, [
    filters,
    searchQuery,
    isLoadingInitialData,
    categoryRecommendations,
    householdCategoryGroups,
    isLoadingCategoryRecommendations,
    transactionProviderDescription,
  ]);

  const handleSelectValueChanged = useCallback(
    (value: any) => {
      let isRecommendedCategory = false;
      const newCategory = householdCategories.find((el) => el.id === value);
      if (newCategory && onChange) {
        isRecommendedCategory =
          categoryRecommendations?.find(({ category }) => category.id === value) !== undefined;
        if (isRecommendedCategory) {
          track(AnalyticsEventNames.RecommendedCategorySelected, {
            transactionId,
            categoryName: newCategory.name,
            transactionProviderDescription,
            oldCategoryName: originalCategory?.name,
          });
        } else {
          track(AnalyticsEventNames.NonRecommendedCategorySelected, {
            transactionId,
            categoryName: newCategory.name,
            transactionProviderDescription,
            oldCategoryName: originalCategory?.name,
          });
        }
        onChange?.(
          {
            value,
            label: newCategory?.name ?? '',
            isRecommendedCategory,
          },
          undefined,
          [newCategory],
        );
      }
    },
    [householdCategories, onChange],
  );

  const handleCreateCategory = (category: GetCategorySelectOptionsQuery['categories'][number]) => {
    handleFullScreenSelectOpened();
    track(AnalyticsEventNames.CategoryCreatedWhileSelecting, {
      transactionId,
      categoryName: category.name,
      transactionProviderDescription,
      oldCategoryName: originalCategory?.name,
    });
    onChange?.(optionFromCategory(category), undefined, [category]);
  };

  const footerSection = isCreatable && (
    <CreateCategorySection
      onCreateCategory={() => {
        setNewCategoryName(searchQuery);
      }}
    />
  );

  const shouldShowNewCategoryModal = RA.isNotNil(newCategoryName);

  return (
    <>
      <FullScreenSelect
        options={allGroups}
        searchPlaceholder={placeholder ?? 'Search categories...'}
        value={getInitialValue({
          propValue: value,
          allGroups,
        })}
        footerSection={footerSection}
        alwaysShowBorder={alwaysShowBorder}
        fullWidthTrigger={fullWidthTrigger}
        onOpen={handleFullScreenSelectOpened}
        onChange={handleSelectValueChanged}
        onSearch={setSearchQuery}
        {...props}
      />
      {shouldShowNewCategoryModal && (
        <Modal
          onClose={() => {
            setNewCategoryName(undefined);
            onBlur?.();
          }}
        >
          {({ close }) => (
            <CreateCategoryModal
              initialValues={{ name: newCategoryName, ...(createInitialValues ?? {}) }}
              onCreate={(category) => {
                handleCreateCategory(category);
                setNewCategoryName(undefined);
                close();
              }}
            />
          )}
        </Modal>
      )}
    </>
  );
};

const CategoryListSkeleton = ({ className }: { className?: any }) => (
  <ContentLoader className={className} height={96}>
    <circle cx="32" cy="12" r="10" />
    <rect x="48" y="6" rx="5" ry="5" width="72" height="14" />

    <circle cx="32" cy="48" r="10" />
    <rect x="48" y="42" rx="5" ry="5" width="96" height="14" />

    <circle cx="32" cy="84" r="10" />
    <rect x="48" y="78" rx="5" ry="5" width="72" height="14" />
  </ContentLoader>
);

const CreateCategorySection = ({ onCreateCategory }: { onCreateCategory: () => void }) => {
  // This component must be rendered inside the SelectRoot hierarchy
  const context = useSelectContext();
  return (
    <CreateCategoryFooter
      onClick={() => {
        context.onOpenChange(false);
        onCreateCategory();
      }}
    >
      <CreateCategoryText>Create new category</CreateCategoryText>
    </CreateCategoryFooter>
  );
};

const applyFilters = (
  categoryGroups: GetCategorySelectOptionsQuery['categoryGroups'],
  filters?: CategorySelectFilters,
): typeof categoryGroups =>
  categoryGroups
    .map(
      R.evolve({
        categories: R.filter(({ id }: { id: string }) => !filters?.excludeCategories?.includes(id)),
      }),
    )
    .filter(({ type, categories }) => {
      if (filters?.type && filters.type !== type) {
        return false;
      }
      return categories.length > 0;
    });

const OccurrencesLabel = styled.div`
  color: ${({ theme }) => theme.color.textLight};
`;

const CategoryLabel = styled.div`
  line-height: 24px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const optionFromCategory = ({
  id,
  name,
  icon,
  occurrences,
}: {
  id: string;
  name: string;
  icon: string;
  occurrences?: number;
}) => ({
  value: id,
  label: (
    <Flex full row justifyBetween alignCenter>
      <CategoryLabel>
        <Emoji>{icon}</Emoji>
        <span>{name}</span>
      </CategoryLabel>

      {RA.isPositive(occurrences) && (
        <OccurrencesLabel data-select-hover>
          <Emoji>
            <Icon size={12} name="credit-card" />
          </Emoji>
          {occurrences}
        </OccurrencesLabel>
      )}
    </Flex>
  ),
  name,
});

const CreateCategoryFooter = styled(Flex).attrs({ alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.xsmall};
  border-top: 1px solid ${({ theme }) => theme.color.grayFocus};
  color: ${({ theme }) => theme.color.blue};
  cursor: pointer;
`;

const CreateCategoryText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  padding: 0 ${({ theme }) => theme.spacing.xsmall};
  display: flex;
  align-items: center;
  width: 100%;
  &:hover {
    color: ${({ theme }) => theme.color.blueDark};
    background-color: ${({ theme }) => theme.color.blueBackground};
  }
  height: 32px;
  border-radius: ${({ theme }) => theme.radius.small};
`;

const getInitialValue = <T extends { label: string; options: OptionType[] }[]>({
  propValue,
  allGroups,
}: {
  propValue: OptionType | string;
  allGroups: T;
}) => {
  if (!RA.isString(propValue)) {
    return propValue;
  }

  const nonRecommendedOptions = R.reject(R.propEq('label', 'Recommended'), allGroups);
  const found = R.find(R.propEq('value', propValue))(
    R.flatten(R.map(R.prop('options'), nonRecommendedOptions)),
  );
  return (
    (found as OptionType) ?? {
      value: propValue,
      label: '',
    }
  );
};

export const GET_CATEGORIES = gql(`
  query Web_GetCategorySelectOptions {
    categoryGroups {
      id
      name
      order
      type
      groupLevelBudgetingEnabled
      categories {
        id
        name
        order
        icon
      }
    }
    categories {
      id
      name
      order
      icon
      group {
        id
        type
      }
    }
  }
`);

export const GET_RECOMMENDED_CATEGORIES = gql(`
  query Web_GetRecommendedCategories($transactionId: ID!) {
    recommendedCategories(id: $transactionId) {
      occurrences
      category {
        id
        name
        order
        icon
        group {
          id
          type
        }
      }
    }
  }
`);

export default FullHeightCategorySelect;
