import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import CategorySelect from 'components/categories/CategorySelect';
import DateRangeField from 'components/lib/form/DateRangeField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import MerchantSelect from 'components/transactions/MerchantSelect';
import TransactionAmountFilter from 'components/transactions/TransactionAmountFilter';
import TransactionTagSelect from 'components/transactions/tags/TransactionTagSelect';

import { DEFAULT_FILTERS, resetAmountFilters } from 'lib/transactions/Filters';

import type { TransactionFilters } from 'types/filters';

const CARD_WIDTH_PX = 328;

const Root = styled.div`
  width: ${CARD_WIDTH_PX}px;
  padding-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const Header = styled(FlexContainer).attrs({ justifyBetween: true, alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const AmountLabelSpacer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
`;

const FilterSectionRoot = styled.div<{ unsetMarginBottom?: boolean }>`
  &:not(:last-child) {
    margin-bottom: ${({ theme, unsetMarginBottom = false }) =>
      unsetMarginBottom ? 'unset' : theme.spacing.default};
  }
`;

const FilterSectionContent = styled(FlexContainer)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};

  > * {
    flex: 1;
  }
`;

const ApplyButton = styled(FormSubmitButton).attrs({ size: 'small' })`
  width: fit-content;
  margin-top: unset;
`;

type FilterSectionProps = {
  title: string;
  children: React.ReactNode;
  unsetMarginBottom?: boolean;
};

const FilterSection = ({ title, children, unsetMarginBottom }: FilterSectionProps) => (
  <FilterSectionRoot unsetMarginBottom={unsetMarginBottom}>
    <Text weight="medium" size="small">
      {title}
    </Text>
    <FilterSectionContent>{children}</FilterSectionContent>
  </FilterSectionRoot>
);

const AmountsFilterSection = () => (
  <>
    <Text weight="medium" size="small">
      Amounts
    </Text>
    <AmountLabelSpacer />
    <TransactionAmountFilter hideLabel />
  </>
);

type Props = {
  activeFilters: TransactionFilters;
  onClickApply: (values: TransactionFilters) => void;
  onClickReset: () => void;
};

const AccountDetailFilterCard = ({ activeFilters, onClickApply, onClickReset }: Props) => (
  <Form
    initialValues={{
      ...activeFilters,
      dateRange: {
        startDate: activeFilters.startDate,
        endDate: activeFilters.endDate,
      },
    }}
    onSubmit={({ dateRange, ...values }) =>
      onClickApply({
        ...DEFAULT_FILTERS,
        ...resetAmountFilters(values, values.amountFilter),
        ...R.reject(R.isNil, dateRange),
      })
    }
  >
    <Root>
      <Header>
        <DefaultButton onClick={() => onClickReset()}>Reset</DefaultButton>
        <ApplyButton>Apply</ApplyButton>
      </Header>
      <Content>
        <FilterSection title="Search">
          <TextField name="search" placeholder="Find a transaction" hideLabel />
        </FilterSection>
        <FilterSection title="Categories">
          <SelectField
            name="categories"
            placeholder="All categories"
            isCreatable={false}
            isMulti
            hideLabel
            InputComponent={CategorySelect}
          />
        </FilterSection>
        <FilterSection title="Merchants">
          <SelectField
            name="merchants"
            placeholder="All merchants"
            hideLabel
            isMulti
            isCreatable={false}
            showTransactionCount={false}
            InputComponent={MerchantSelect}
          />
        </FilterSection>
        <AmountsFilterSection />
        <FilterSection title="Tags">
          <SelectField
            name="tags"
            InputComponent={TransactionTagSelect}
            isCreatable={false}
            placeholder="All tags..."
            hideLabel
            isMulti
          />
        </FilterSection>
        <DateRangeField
          name="dateRange"
          label="Date range"
          minDate={new Date(2000, 1, 1)}
          maxDate={new Date()}
          anchorDirection="right"
          openDirection="up"
        />
      </Content>
    </Root>
  </Form>
);

export default AccountDetailFilterCard;
