declare global {
  interface Window {
    UserLeap: any;
  }
}

export const identifyUserToUserLeap = (id: string, email: string) => {
  if (window.UserLeap) {
    window.UserLeap('setUserId', id);
    window.UserLeap('setEmail', email);
  }
};
