import * as R from 'ramda';
import { useMemo } from 'react';
import type { DropResult } from 'react-beautiful-dnd';

import useAccountGroupOrder from 'common/lib/hooks/accounts/useAccountGroupOrder';
import type { AccountTypeSummary } from 'lib/accounts/AccountGraphs';

import type { Web_GetAccountsPageQuery } from 'common/generated/graphql';

const getAccountGroupOrder = (
  defaultAccountGroupOrder: string[],
  currentAccountGroupOrder: string[] | undefined,
) => {
  if (!currentAccountGroupOrder || R.isEmpty(currentAccountGroupOrder)) {
    return defaultAccountGroupOrder;
  }

  const newAccountGroups = R.filter(
    (groupName: string) => !currentAccountGroupOrder.includes(groupName),
    defaultAccountGroupOrder,
  );
  const removedAccountGroups = R.filter(
    (groupName: string) => !defaultAccountGroupOrder.includes(groupName),
    currentAccountGroupOrder,
  );

  return R.concat(
    R.filter(
      (groupName: string) => !removedAccountGroups.includes(groupName),
      currentAccountGroupOrder,
    ),
    newAccountGroups,
  );
};

const useAccountGroupsDrag = (
  accountTypeSummaries: AccountTypeSummary[],
  householdPreferences: Web_GetAccountsPageQuery['householdPreferences'],
) => {
  const defaultAccountGroupOrder = accountTypeSummaries.map(({ type }) => type.display);
  const [currentAccountGroupOrder, updateAccountGroupOrder] =
    useAccountGroupOrder(householdPreferences);
  const accountGroupOrder = getAccountGroupOrder(
    defaultAccountGroupOrder,
    currentAccountGroupOrder,
  );

  const sortedAccountsGroup = useMemo(() => {
    if (R.isEmpty(currentAccountGroupOrder)) {
      return accountTypeSummaries;
    }

    return R.sort((first, second) => {
      const firstPosition = accountGroupOrder.indexOf(first.type.display);
      const secondPosition = accountGroupOrder.indexOf(second.type.display);
      return firstPosition - secondPosition;
    }, accountTypeSummaries);
  }, [accountTypeSummaries, accountGroupOrder, currentAccountGroupOrder]);

  const handleOnDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return;
    }

    // Remove from source & insert into destination position
    const sourceGroupName = accountGroupOrder[source.index];
    const newAccountGroupOrder = R.pipe(
      R.remove<string>(source.index, 1),
      R.insert(destination.index, sourceGroupName),
    )(accountGroupOrder);

    updateAccountGroupOrder(newAccountGroupOrder);
  };

  return { handleOnDragEnd, sortedAccountsGroup };
};

export default useAccountGroupsDrag;
