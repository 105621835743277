export const MS_PER_SECOND = 1000;
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;

export const MS_PER_MINUTE = MS_PER_SECOND * SECONDS_PER_MINUTE;

export const MONTHS_PER_YEAR = 12;
export const DAYS_PER_WEEK = 7;

/** Render time in milliseconds for single frame in 60 fps. */
export const FPS_60 = 1 / 60;

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
