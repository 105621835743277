import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LinkInstitutionFlow } from 'components/accounts/LinkAccountFlow';
import ReconnectCredentialPlaidLink from 'components/accounts/ReconnectCredentialPlaidLink';

import { getPlaidLinkInfo, getCurrentUploadSession } from 'selectors';

import { ParserName } from 'common/constants/statements';
import routes from 'constants/routes';

type Props = {
  onComplete: () => void;
  onClose: () => void;
};

/**
 * Component to handle redirect for Plaid Link Oauth.
 *
 * If a new account was being linked, this will go back to the add account flow.
 * If a credential was being re-linked, this will go to the reconnect flow.
 * */
const PlaidLinkOauth = ({ onComplete, onClose }: Props) => {
  const history = useHistory();

  const { token, reconnectCredentialId } = useSelector(getPlaidLinkInfo) ?? {};
  const receivedRedirectUri = window.location.href;

  const currentUploadSession = useSelector(getCurrentUploadSession);

  const next = () => {
    onComplete();

    if (currentUploadSession && currentUploadSession.parserName === ParserName.MintCSV) {
      history.push(routes.accounts.importMintTransactions());
    }
  };

  useEffect(() => {
    if (!token) {
      onClose();
    }
  }, []);

  if (!token) {
    return null;
  }

  if (reconnectCredentialId) {
    return (
      <ReconnectCredentialPlaidLink
        token={token}
        receivedRedirectUri={receivedRedirectUri}
        credentialId={reconnectCredentialId}
        onClose={onClose}
        onSuccess={onComplete}
      />
    );
  } else {
    return (
      <LinkInstitutionFlow
        cachedPlaidLinkToken={token}
        plaidReceivedRedirectUri={receivedRedirectUri}
        next={next}
        goBack={close}
      />
    );
  }
};

export default PlaidLinkOauth;
