import { useState } from 'react';

import type { Web_GetTransactionsListQuery } from 'common/generated/graphql';

const useTransactionDrawerState = (
  data: Web_GetTransactionsListQuery | null | undefined,
  overrideId: GraphQlUUID | null | undefined = undefined,
  overrideSetId: ((id: GraphQlUUID | null) => void) | undefined = undefined,
) => {
  const transactions = data?.allTransactions.results ?? [];
  const [internalDrawerTransactionId, internalSetDrawerTransactionId] =
    useState<GraphQlUUID | null>(null);
  const drawerTransactionId = overrideId ?? internalDrawerTransactionId;
  const setDrawerTransactionId = overrideSetId ?? internalSetDrawerTransactionId;

  const drawerTransactionIndex = transactions.findIndex(({ id }) => id === drawerTransactionId);
  const nextTransactionId = transactions[drawerTransactionIndex + 1]?.id ?? null;
  const previousTransactionId = transactions[drawerTransactionIndex - 1]?.id ?? null;

  return [
    drawerTransactionId,
    { setDrawerTransactionId, previousTransactionId, nextTransactionId },
  ] as const;
};

export default useTransactionDrawerState;
