import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import CancelSubscriptionAcceptOfferModal from 'components/settings/billing/cancel/CancelSubscriptionAcceptOfferModal';
import PerformCancelSubscriptionModal from 'components/settings/billing/cancel/PerformCancelSubscriptionModal';

import {
  formatDiscountOfferLong,
  formatDiscountOfferShort,
  formatPricePerPeriod,
} from 'common/lib/billing/Billing';
import { useStackContext } from 'common/lib/contexts/StackContext';
import { track } from 'lib/analytics/segment';

import { CancelFlowEventNames } from 'common/constants/analytics';

import { gql } from 'common/generated/gql';
import type { CancellationReason } from 'common/generated/graphql';

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const Strikethrough = styled.div`
  color: ${({ theme }) => theme.color.textLight};
  text-decoration: line-through;
`;

const Orange = styled.div`
  color: ${({ theme }) => theme.color.orange};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Description = styled.div`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  reason: CancellationReason;
  analyticsFreemiumSummaryStatus?: string | null;
};

/**
 * This is part of the new cancellation flow which is behind the feature flag: web-cancel-discount-flow
 */
const CancelSubscriptionDiscountOfferModal = ({
  reason,
  analyticsFreemiumSummaryStatus,
}: Props) => {
  const { pop, push, replace } = useStackContext();

  const { data, loading } = useQuery(QUERY, {
    variables: {
      reason,
    },
  });
  const { stripeCancellationDiscountOffer, subscription } = data ?? {};

  const discountAnalyticsInfo = R.pick(
    ['discountPercent', 'duration', 'durationInMonths', 'discountedPricePerPeriodDollars'],
    stripeCancellationDiscountOffer ?? {},
  );

  useEffect(() => {
    if (stripeCancellationDiscountOffer) {
      track(CancelFlowEventNames.CancelDiscountOffered, {
        ...discountAnalyticsInfo,
        analyticsFreemiumSummaryStatus,
      });
    }
  }, [stripeCancellationDiscountOffer]);

  useEffect(() => {
    if (!loading && !stripeCancellationDiscountOffer) {
      replace(PerformCancelSubscriptionModal, { reason, analyticsFreemiumSummaryStatus });
    }
  }, [loading, stripeCancellationDiscountOffer]);

  if (loading) {
    return (
      <ModalCard onClickBackButton={pop}>
        <CardBody>
          <FlexContainer center>
            <LoadingSpinner />
          </FlexContainer>
        </CardBody>
      </ModalCard>
    );
  }

  if (!stripeCancellationDiscountOffer || !subscription?.billingPeriod) {
    // will be redirected
    return null;
  }

  const { originalPricePerPeriodDollars, discountedPricePerPeriodDollars } =
    stripeCancellationDiscountOffer;
  const { billingPeriod } = subscription;

  // i.e. "one month free" or "50% off"
  const discountShortDescription = formatDiscountOfferShort(
    stripeCancellationDiscountOffer,
    billingPeriod,
  );

  return (
    <ModalCard
      onClickBackButton={pop}
      title={`Would ${discountShortDescription} help?`}
      description="We're adding new features every day, including personalized financial advice."
    >
      <CardBody>
        <Strikethrough>
          Original price {formatPricePerPeriod(originalPricePerPeriodDollars, billingPeriod)}
        </Strikethrough>
        <Orange>
          Your price {formatPricePerPeriod(discountedPricePerPeriodDollars, billingPeriod)}
        </Orange>
        <Description>
          Keep your subscription and we&apos;ll give you{' '}
          {formatDiscountOfferLong(stripeCancellationDiscountOffer, billingPeriod)}.
        </Description>
      </CardBody>
      <CardFooter justifyEnd>
        <DefaultButton
          onClick={() =>
            push(PerformCancelSubscriptionModal, { reason, analyticsFreemiumSummaryStatus })
          }
        >
          I still want to cancel
        </DefaultButton>
        <PrimaryButton
          onClick={() => {
            track(CancelFlowEventNames.CancelDiscountAccepted, {
              ...discountAnalyticsInfo,
              analyticsFreemiumSummaryStatus,
            });
            push(CancelSubscriptionAcceptOfferModal, { reason });
          }}
        >
          Get {discountShortDescription}
        </PrimaryButton>
      </CardFooter>
    </ModalCard>
  );
};

const QUERY = gql(`
  query GetStripeCancellationDiscountOffer($reason: CancellationReason!) {
    stripeCancellationDiscountOffer(reason: $reason) {
      discountPercent
      duration
      durationInMonths
      originalPricePerPeriodDollars
      discountedPricePerPeriodDollars
    }
    subscription {
      id
      billingPeriod
    }
  }
`);

export default CancelSubscriptionDiscountOfferModal;
