import { rgba } from 'polished';

import lightPalette from 'common/lib/theme/lightPalette';

const darkPalette: typeof lightPalette = {
  ...lightPalette,
  text: '#FFFFFF',
  textLight: rgba('#FFFFFF', 0.7),
  textWhite: '#FFFFFF',
  white: '#0D2C5C',
  grayDark: '#43546E',
  gray: '#344867',
  grayLight: '#2F4464',
  grayFocus: '#263D5F',
  grayBackground: '#082043',
  grayLightBackground: '#082043',
  navy: '#0D2C5C',
  navyDark: '#082043',
  navyLight: '#263D5F',
  orangeText: lightPalette.orangeFocus,
  yellowText: lightPalette.yellowFocus,
  blueText: lightPalette.blueFocus,
  redText: lightPalette.redFocus,
  greenText: lightPalette.greenFocus,
  // Focus colors
  orangeFocus: rgba(lightPalette.orange, 0.4),
  yellowFocus: rgba(lightPalette.yellow, 0.4),
  blueFocus: rgba(lightPalette.blue, 0.4),
  redFocus: rgba(lightPalette.red, 0.4),
  greenFocus: rgba(lightPalette.green, 0.4),
  // Background colors
  orangeBackground: rgba(lightPalette.orange, 0.2),
  yellowBackground: rgba(lightPalette.yellow, 0.2),
  blueBackground: rgba(lightPalette.blue, 0.2),
  redBackground: rgba(lightPalette.red, 0.2),
  greenBackground: rgba(lightPalette.green, 0.2),
  limeBackground: rgba(lightPalette.lime, 0.2),
  pinkBackground: rgba(lightPalette.pink, 0.2),
  purpleBackground: rgba(lightPalette.purple, 0.2),
};

export default darkPalette;
