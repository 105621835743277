import React, { useEffect, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

const IMAGE_URL =
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/account-cards-grid.svg';
const HEIGHT_PX = 289;
const ROTATION_DEG = -30;
const ROTATION_RAD = (Math.abs(ROTATION_DEG) * Math.PI) / 180;

const TRANSLATE_X_PX = Math.sin(ROTATION_RAD) * (HEIGHT_PX + 12);
const TRANSLATE_Y_PX = Math.cos(ROTATION_RAD) * (HEIGHT_PX + 12);
const DURATION_MS = 20000;
const RIGHT_PX = 12;
const TOP_PX = -90;

const AccountCardsImage = styled.img.attrs({ src: IMAGE_URL })`
  height: ${HEIGHT_PX}px;
  transform: rotate(${ROTATION_DEG}deg);
  opacity: 0.25;
`;

type Props = {
  className?: string;
};

const OnboardingAccountsGridAnimation = ({ className }: Props) => {
  const [index, setIndex] = useState(0);

  const transitions = useTransition(index, null, {
    initial: {
      transform: `translateY(0px) translateX(0px)`,
    },
    from: {
      transform: `translateY(${TRANSLATE_Y_PX}px) translateX(${TRANSLATE_X_PX}px)`,
    },
    enter: {
      transform: `translateY(0px) translateX(0px)`,
    },
    leave: {
      transform: `translateY(${-TRANSLATE_Y_PX}px) translateX(${-TRANSLATE_X_PX}px)`,
    },
    config: {
      duration: DURATION_MS,
    },
  });

  useEffect(() => {
    setIndex((current) => current + 1);

    const interval = setInterval(() => {
      setIndex((current) => current + 1);
    }, DURATION_MS);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {transitions.map(({ item, key, props }) => (
        <animated.div
          key={key}
          style={{
            ...props,
            position: 'absolute',
            right: RIGHT_PX,
            top: TOP_PX,
          }}
        >
          <AccountCardsImage />
        </animated.div>
      ))}
    </>
  );
};

export default OnboardingAccountsGridAnimation;
