import React from 'react';
import type { CartesianGridProps } from 'recharts';
import { CartesianGrid as RechartsCartesianGrid } from 'recharts';

import useTheme from 'lib/hooks/useTheme';

type Props = CartesianGridProps & { dashed?: boolean };

const CartesianGrid = ({ dashed, ...props }: Props) => {
  const theme = useTheme();
  const { stroke, ...restProps } = props;

  return (
    <RechartsCartesianGrid
      vertical={false}
      {...restProps}
      {...(dashed
        ? {
            strokeDasharray: '4 4',
            stroke: stroke ?? theme.color.grayLight,
          }
        : {
            stroke: stroke ?? theme.color.grayBackground,
          })}
    />
  );
};

// Fixes a bug where this wasn't rendered on prod
CartesianGrid.displayName = 'CartesianGrid';

export default CartesianGrid;
