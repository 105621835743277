import React from 'react';
import styled from 'styled-components';

import Drawer from 'components/lib/ui/Drawer';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import useToggle from 'common/lib/hooks/useToggle';

import type { Color } from 'types/Styles';

const Root = styled.div``;

const Button = styled(FlexContainer).attrs({ alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.large} 0;
  margin: 0 ${({ theme }) => theme.spacing.large};
  cursor: pointer;
  transition: ${({ theme }) => theme.transition.default};
  color: ${({ theme }) => theme.color.textLight};
  position: relative;

  :hover {
    color: ${({ theme }) => theme.color.blue};
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const StyledDrawer = styled(Drawer)<{ backgroundColor: Color }>`
  background: ${({ theme, backgroundColor }) => theme.color[backgroundColor]};
`;

const TriangleArrow = styled.div<{ visible: boolean }>`
  --size: 12px;
  content: '';
  position: absolute;
  width: var(--size);
  height: var(--size);
  left: 4px;
  top: calc(var(--size) / 2 * -1);
  transform: rotate(45deg);
  background: ${({ theme }) => theme.color.grayBackground};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  border-right: 1px solid ${({ theme }) => theme.color.grayFocus};

  transition: opacity 0.3s ease-out;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

type Props = {
  showText?: string;
  hideText?: string;
  initiallyOpen?: boolean;
  showIcon?: string;
  hideIcon?: string;
  drawerBackgroundColor?: Color;
  children?: React.ReactNode;
  className?: string;
};

const ShowHideToggleDrawer = ({
  showText = 'Show',
  hideText = 'Hide',
  initiallyOpen = false,
  showIcon = 'eye',
  hideIcon = 'eye-off',
  drawerBackgroundColor = 'grayBackground',
  children,
  className,
}: Props) => {
  const [open, { toggle }] = useToggle(initiallyOpen);

  return (
    <Root>
      <StyledDrawer
        open={open}
        shouldAnimateOnMount={false}
        backgroundColor={drawerBackgroundColor}
      >
        {children}
      </StyledDrawer>
      <Button className={className} onClick={toggle}>
        <TriangleArrow visible={open} />
        <StyledIcon name={open ? hideIcon : showIcon} size={16} />
        <Text size="small">{open ? hideText : showText}</Text>
      </Button>
    </Root>
  );
};

export default ShowHideToggleDrawer;
