import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';
import TransactionBulkUpdateConfirmationModal from 'components/transactions/TransactionBulkUpdateConfirmationModal';

import { BULK_UPDATE_TRANSACTIONS_MUTATION } from 'common/lib/graphQl/transactions';
import { ReviewStatus } from 'common/lib/transactions/review';
import { formatThousands } from 'common/utils/Number';
import { useTransactionListContext } from 'lib/contexts/TransactionsListContext';
import useModal from 'lib/hooks/useModal';
import { DEFAULT_FILTERS } from 'lib/transactions/Filters';

/**
 * Omit `amountFilter` because the BE only needs the gte/lte amounts.
 *
 * The `amountFilter` type can be inferred from the values of `absAmountGte/Lte`
 */
const omitAmountFilter = R.omit(['amountFilter']);

const Button = styled(AsyncButton)`
  ${primaryButtonMixin};
`;

type Props = {
  totalCount?: number;
};

const ReviewAllTransactionsButton = ({ totalCount = 0 }: Props) => {
  const { filters: rawFilters = {}, refetchAll } = useTransactionListContext();

  const [bulkUpdateTransactions, { loading }] = useMutation(BULK_UPDATE_TRANSACTIONS_MUTATION, {
    variables: {
      allSelected: true,
      expectedAffectedTransactionCount: totalCount,
      filters: omitAmountFilter(R.mergeLeft(rawFilters, DEFAULT_FILTERS)),
      selectedTransactionIds: [],
      excludedTransactionIds: [],
      updates: {
        reviewStatus: ReviewStatus.Reviewed,
        categoryId: undefined,
        date: undefined,
        goalId: undefined,
        hide: undefined,
        isRecurring: undefined,
        merchantName: undefined,
        needsReviewByUserId: undefined,
        notes: undefined,
        tags: undefined,
      },
    },
    onCompleted: refetchAll,
  });

  const [ReviewConfirmationModal, { open: openReviewConfirmationModal }] = useModal();

  return (
    <>
      <Button onClick={() => openReviewConfirmationModal()} pending={loading}>
        Mark all {totalCount ? `${formatThousands(totalCount)} ` : ''}as reviewed
      </Button>
      <ReviewConfirmationModal>
        <Form
          onSubmit={() => {
            bulkUpdateTransactions();
          }}
        >
          <TransactionBulkUpdateConfirmationModal
            selectedCount={totalCount}
            confirmationData={{ 'Review status': 'Reviewed' }}
          />
        </Form>
      </ReviewConfirmationModal>
    </>
  );
};

export default ReviewAllTransactionsButton;
