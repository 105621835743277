import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useContext } from 'react';

import FormContext from 'common/components/form/FormContext';

import { EMPTY_RULE, INITIAL_ENABLED_VALUES } from 'common/lib/transactions/Rules';
import type { RuleFieldName } from 'common/lib/transactions/Rules';

const useTransactionRulesForm = () => {
  const { setFieldValue, getFieldMeta } = useContext(FormContext);

  const resetFieldValue = async (fieldName: RuleFieldName) => {
    const { value, initialValue } = getFieldMeta(fieldName);
    const newValue = R.isNil(value)
      ? initialValue ?? INITIAL_ENABLED_VALUES[fieldName]
      : EMPTY_RULE[fieldName];
    await setFieldValue(fieldName, newValue);
  };

  const toggleProps = (fieldName: RuleFieldName) => {
    const enabled = RA.isNotNil(getFieldMeta(fieldName).value);

    return {
      onToggle: async () => {
        await resetFieldValue(fieldName);

        if (fieldName === 'reviewStatusAction') {
          await resetFieldValue('needsReviewByUserAction');
        } else if (fieldName === 'amountCriteria' && enabled) {
          await resetFieldValue(fieldName);
        }
      },
      enabled,
    };
  };

  return { resetFieldValue, toggleProps };
};

export default useTransactionRulesForm;
