import type { SankeyGroupMode } from 'lib/cashFlow/sankey';

import routes from 'constants/routes';

import type { TransactionFilterInput } from 'common/generated/graphql';

export enum ReportsChart {
  PieChart = 'pieChart',
  HorizontalBarChart = 'horizontalBarChart',
  BarChart = 'barChart',
  StackedBarChart = 'stackedBarChart',
  CashFlowChart = 'cashFlowChart',
  StackedCashFlowChart = 'stackedCashFlowChart',
  Sankey = 'sankey',
}

// Extend the TransactionFilterInput type with the `startDate` and `endDate` fields required.
export interface ReportsFilters extends Partial<TransactionFilterInput> {
  startDate: string;
  endDate: string;
}

export enum ReportsAmountFilter {
  AnyAmount = 'any-amount',
  LessThan = 'less-than',
  GreaterThan = 'greater-than',
  Between = 'between',
  EqualTo = 'equal-to',
}

/** For now, the different reports are determined by the current route, that's why we derive
this type from `routes.reports`. This might change in the future in case we want to implement
multiple types of reports per page. */
export type ReportsTab = Exclude<keyof typeof routes.reports, 'path'>;

export const isValidTab = (tab: string): tab is ReportsTab =>
  Object.keys(routes.reports).includes(tab);

export interface TabDisplayProperties {
  /* For now, `viewMode` is only used for spending/income */
  viewMode?: Maybe<'totalAmounts' | 'changeOverTime'>;
  chartType: ReportsChart;
}

export interface SankeyDisplayProperties extends Omit<TabDisplayProperties, 'viewMode'> {
  chartType: ReportsChart.Sankey;
  groupMode: SankeyGroupMode;
}
