import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import Radio, { CircleIndicator } from 'components/lib/form/Radio';
import FlexContainer from 'components/lib/ui/FlexContainer';
import TextButton from 'components/lib/ui/TextButton';
import type { Props as TooltipProps } from 'components/lib/ui/Tooltip';
import SplitFormValidationRow from 'components/transactions/SplitFormValidationRow';
import { TransactionRuleFormTitle } from 'components/transactions/rules/TransactionRuleFormComponents';
import FormSplitContainer from 'components/transactions/splits/FormSplitContainer';

import { color, spacing, fontSize } from 'common/lib/theme/dynamic';
import {
  AmountOperator,
  DEFAULT_SPLIT_INFO,
  getDescriptionForSplitTransactionsAmountType,
} from 'common/lib/transactions/Rules';
import { getAmountLeftToSplit } from 'common/lib/transactions/Splits';

import type {
  SplitTransactionsActionInfoInput,
  UpdateTransactionRuleInput,
} from 'common/generated/graphql';
import { SplitAmountType } from 'common/generated/graphql';

const SplitAmountTypeRadioContainer = styled.div`
  margin-top: ${spacing.default};
`;

const RadioRow = styled(FlexContainer).attrs({ alignCenter: true })`
  cursor: pointer;
  font-size: ${fontSize.small};
  margin-bottom: ${spacing.xsmall};

  ${CircleIndicator} {
    margin-right: ${spacing.small};
  }
`;

const DisabledRadioRow = styled(RadioRow)`
  color: ${color.textLight};
  pointer-events: none;
`;

const StyledFormSplitContainer = styled(FormSplitContainer)`
  margin-top: ${spacing.small};
`;

const TransactionRuleFormSplitActionsContainer = ({
  onCancelSplitAction,
}: {
  onCancelSplitAction: () => void;
}) => {
  const { values, setFieldValue, setFieldTouched, getFieldMeta } = useContext(FormContext);
  const splitTransactionsAction =
    getFieldMeta<UpdateTransactionRuleInput['splitTransactionsAction']>(
      'splitTransactionsAction',
    ).value;
  const { amountType, splitsInfo = [] } = splitTransactionsAction ?? {};

  const accountIds = useMemo((): string[] => {
    const accountIds = (values.accountIds ?? []) as string[];
    return accountIds.filter((value) => !R.isNil(value));
  }, [values.accountIds]);
  const isEqualOperator = getFieldMeta('amountCriteria.operator').value === AmountOperator.Equals;
  const amountCriteriaValue = getFieldMeta('amountCriteria.value').value as number;

  const isAmountTypeAbsoluteAllowed = useMemo(
    () => isEqualOperator && !R.isNil(amountCriteriaValue) && amountCriteriaValue !== 0,
    [amountCriteriaValue, isEqualOperator],
  );
  const isAmountTypeFieldDisabled = useMemo(
    () => !isAmountTypeAbsoluteAllowed,
    [isAmountTypeAbsoluteAllowed],
  );

  const isAmountTypePercentage = useMemo(
    () => amountType === SplitAmountType.PERCENTAGE,
    [amountType],
  );
  const isAmountTypeAbsolute = useMemo(() => amountType === SplitAmountType.ABSOLUTE, [amountType]);

  const amountTypeOptions = useMemo(() => {
    const tooltipProps = !isAmountTypeAbsoluteAllowed
      ? ({
          content: 'You must use an amount filter set to “Equals” to split by dollar amount.',
          opacity: 1.0,
          place: 'top',
        } as TooltipProps) // Casting to TooltipProps to avoid TS error with place prop which isn't exported from ReactTooltip
      : undefined;

    return [
      {
        value: SplitAmountType.ABSOLUTE,
        label: getDescriptionForSplitTransactionsAmountType(SplitAmountType.ABSOLUTE),
        tooltipProps,
      },
      {
        value: SplitAmountType.PERCENTAGE,
        label: getDescriptionForSplitTransactionsAmountType(SplitAmountType.PERCENTAGE),
      },
    ];
  }, [isAmountTypeAbsoluteAllowed]);

  const changeAmountType = useCallback(
    (value: { value: Maybe<string> } | { value: Maybe<string> }[]) => {
      const amountTypeOptionValue = RA.isArray(value) ? value[0]?.value : value.value;

      if (amountTypeOptionValue !== amountType) {
        setFieldValue('splitTransactionsAction.amountType', amountTypeOptionValue);

        // Whenever the user changes the amount type, clear the amount values for all splits and
        // reset the touched state, so errors are not shown.
        splitsInfo.forEach((_, i) => {
          setFieldValue(`splitTransactionsAction.splitsInfo[${i}].amount`, undefined);
          setFieldTouched(`splitTransactionsAction.splitsInfo[${i}].amount`, false);
        });
      }
    },
    [setFieldValue, setFieldTouched, amountType, splitsInfo],
  );

  useEffect(() => {
    // Users are only allowed to split by absolute values when the amount criteria is equal to a
    // non-zero value. Otherwise, the amount type field is disabled and forced to be percentage.
    if (!isAmountTypeAbsoluteAllowed && isAmountTypeAbsolute) {
      changeAmountType({ value: SplitAmountType.PERCENTAGE });
    }
  }, [setFieldValue, changeAmountType, isAmountTypeAbsoluteAllowed, isAmountTypeAbsolute]);

  useEffect(() => {
    // Users are only allowed to give splits a goal when one account id is set.
    // This effect synchronizes the goal id field if/when the accouns ids change,
    // i.e., it clears the split goal fields if needed.
    if (accountIds.length !== 1) {
      splitsInfo.forEach((_, i) => {
        const currentGoalId = getFieldMeta(`splitTransactionsAction.splitsInfo[${i}].goalId`).value;
        if (!R.isNil(currentGoalId)) {
          setFieldValue(`splitTransactionsAction.splitsInfo[${i}].goalId`, undefined);
        }
      });
    }
  }, [accountIds, splitsInfo, setFieldValue, getFieldMeta]);

  const [areSplitsExpanded, setAreSplitsExpanded] = useState<boolean[]>(
    splitsInfo.map((_, i) => i === 0), // Expand the first split by default
  );

  const toggleSplitExpanded = useCallback(
    (index: number) => () => {
      setAreSplitsExpanded(R.adjust(index, R.not, areSplitsExpanded));
    },
    [setAreSplitsExpanded, areSplitsExpanded],
  );

  const addSplit = useCallback(
    (data?: SplitTransactionsActionInfoInput) => {
      setAreSplitsExpanded([...areSplitsExpanded, false]);
      return setFieldValue('splitTransactionsAction.splitsInfo', [
        ...splitsInfo,
        {
          ...DEFAULT_SPLIT_INFO,
          ...data,
        },
      ]);
    },
    [setFieldValue, splitsInfo, areSplitsExpanded],
  );

  const adjustSplit = useCallback(
    (index: number) => async (data: SplitTransactionsActionInfoInput) =>
      setFieldValue(
        'splitTransactionsAction.splitsInfo',
        R.adjust(index, R.mergeLeft(data), splitsInfo),
      ),
    [setFieldValue, splitsInfo],
  );

  const removeSplit = useCallback(
    (index: number) => () => {
      setAreSplitsExpanded(R.remove(index, 1, areSplitsExpanded));
      return setFieldValue('splitTransactionsAction.splitsInfo', R.remove(index, 1, splitsInfo));
    },
    [areSplitsExpanded, setFieldValue, splitsInfo],
  );

  const amountLeftToSplit = useMemo(
    () =>
      getAmountLeftToSplit(
        splitsInfo,
        Math.abs((isAmountTypePercentage ? 1 : amountCriteriaValue) ?? 0),
      ),
    [splitsInfo, isAmountTypePercentage, amountCriteriaValue],
  );

  return (
    <>
      <FlexContainer justifyBetween>
        <TransactionRuleFormTitle>Then split the transaction...</TransactionRuleFormTitle>
        <TextButton onClick={onCancelSplitAction}>Cancel</TextButton>
      </FlexContainer>
      <SplitAmountTypeRadioContainer>
        <Radio
          RowComponent={isAmountTypeFieldDisabled ? DisabledRadioRow : RadioRow}
          value={{ value: amountType }}
          options={amountTypeOptions}
          onChange={changeAmountType}
        />
      </SplitAmountTypeRadioContainer>
      {splitsInfo.map((splitInfo, index) => (
        <StyledFormSplitContainer
          key={index}
          compact
          absoluteAmount={isAmountTypeAbsolute}
          expanded={areSplitsExpanded[index]}
          shouldAnimateDrawerOnMount={false}
          formFieldPrefix={`splitTransactionsAction.splitsInfo[${index}]`}
          accountIds={accountIds}
          split={splitInfo}
          onAdjustSplit={(data) => adjustSplit(index)(data as SplitTransactionsActionInfoInput)}
          onRemoveSplit={removeSplit(index)}
          onToggleSplitExpanded={toggleSplitExpanded(index)}
        />
      ))}
      <SplitFormValidationRow
        amountLeftToSplit={amountLeftToSplit}
        isPercentage={isAmountTypePercentage}
        onAddFormSplit={addSplit}
      />
    </>
  );
};

export default TransactionRuleFormSplitActionsContainer;
