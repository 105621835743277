import { DateTime } from 'luxon';
import React, { useMemo } from 'react';

import { formatRelativeTimeShort } from 'common/utils/date';

import { MS_PER_MINUTE } from 'common/constants/time';

type Props = {
  date: string; // ISO format
  /** i.e. '3m', '4d', '5y' */
  short?: boolean;
  className?: string;
};

const RelativeDate = ({ date, short, className }: Props) => {
  const relativeText = useMemo(() => {
    const dateTime = DateTime.fromISO(date);
    if (Math.abs(dateTime.diffNow().milliseconds) < MS_PER_MINUTE) {
      return 'Just now';
    }
    if (short) {
      return formatRelativeTimeShort(dateTime);
    }
    return dateTime.toRelative();
  }, [date, short]);

  return <span className={className}>{relativeText}</span>;
};

export default RelativeDate;
