import React from 'react';

import NestedDropdownMenu from 'components/lib/ui/NestedDropdownMenu';
import NestedDropdownSubMenu from 'components/lib/ui/NestedDropdownSubMenu';

import type { NetWorthChartType } from 'common/lib/accounts/accountCharts';
import { NET_WORTH_CHART_TYPES } from 'common/lib/accounts/accountCharts';

type AccountTypesMenuProps = {
  title: string;
  options: { display: string; name: string }[];
  onChange: (value: string) => void;
};

const AccountTypesMenu = ({ title, options, onChange }: AccountTypesMenuProps) => {
  const mappedOptions = options.map((option) => ({
    title: option.display,
    onClick: () => onChange(option.name),
  }));

  return (
    <NestedDropdownSubMenu key={title} title={title}>
      <NestedDropdownMenu items={mappedOptions} />
    </NestedDropdownSubMenu>
  );
};

type AccountTypeOption = { display: string; group: string; name: string };

type Props = {
  onChangeChartType: (option: NetWorthChartType) => void;
  onChangeSelectedAccountType: (accountType: string | null) => void;
  assetOptions?: AccountTypeOption[];
  liabilityOptions?: AccountTypeOption[];
};

const NetWorthChartOptions = ({
  assetOptions,
  liabilityOptions,
  onChangeChartType,
  onChangeSelectedAccountType,
}: Props) => (
  <NestedDropdownMenu
    items={[
      {
        title: 'Net worth performance',
        onClick: () => onChangeChartType(NET_WORTH_CHART_TYPES[0]),
      },
      {
        title: 'Net worth breakdown',
        onClick: () => onChangeChartType(NET_WORTH_CHART_TYPES[1]),
      },
      ...(assetOptions?.length || liabilityOptions?.length ? [{ divider: true }] : []),
      ...(assetOptions?.length
        ? [
            {
              title: 'Assets',
              component: AccountTypesMenu,
              componentProps: {
                title: 'Assets',
                options: assetOptions,
                onChange: onChangeSelectedAccountType,
              },
            },
          ]
        : []),
      ...(liabilityOptions?.length
        ? [
            {
              title: 'Liabilities',
              component: AccountTypesMenu,
              componentProps: {
                title: 'Liabilities',
                options: liabilityOptions,
                onChange: onChangeSelectedAccountType,
              },
            },
          ]
        : []),
    ]}
  />
);

export default NetWorthChartOptions;
