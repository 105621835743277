import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';

import type { Color } from 'types/Styles';

const Root = styled(FlexContainer)`
  height: 4px;
`;

const Pill = styled.div<{ backgroundColor: Color; maxWidth: number }>`
  background: ${({ theme, backgroundColor }) => theme.color[backgroundColor]};
  height: 100%;
  flex: 1;
  max-width: ${({ maxWidth }) => maxWidth}px;

  /* Make pill shaped https://stackoverflow.com/a/18795153 */
  border-radius: 500px;

  :not(:first-child) {
    margin-left: 4px;
  }
`;

type Props = {
  numStepsCompleted: number;
  numSteps: number;
  completeColor?: Color;
  incompleteColor?: Color;
  stepWidthPx?: number;
  className?: string;
};

/**
 * A progress bar with distinct steps, represented as individual pills.
 */
const StepProgressBar = ({
  numStepsCompleted,
  numSteps,
  completeColor = 'green',
  incompleteColor = 'gray',
  stepWidthPx = 44,
  className,
}: Props) => (
  <Root className={className}>
    {R.range(0, numSteps).map((index) => (
      <Pill
        key={index}
        backgroundColor={index < numStepsCompleted ? completeColor : incompleteColor}
        maxWidth={stepWidthPx}
      />
    ))}
  </Root>
);

export default StepProgressBar;
