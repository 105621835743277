import { rgba } from 'polished';
import React, { useContext } from 'react';
import HighlightWords from 'react-highlight-words';
import styled from 'styled-components';

import HighlightTextContext from 'lib/contexts/HighlightTextContext';

type Props = {
  children?: string;
  className?: string;
};

/*
  Use this component if you want to pass in props instead of using HighlightTextContext
*/
export const Highlight = styled(HighlightWords)`
  mark {
    background-color: ${({ theme }) => rgba(theme.color.yellow, 0.3)};
    color: inherit;
  }
`;

/*
  This component uses HighlightTextContext to retrieve which words to highlight.

  If you just want to pass in which words to highlight as a prop, you should use the Highlight component above.
*/
const Highlighter = ({ children = '', className }: Props) => {
  const { searchWords } = useContext(HighlightTextContext);

  if (!searchWords) {
    throw new Error(
      'Highlighter used outside of HighlightTextContext. Either add a HighlightTextContext.Provider higher in the tree or use Highlight component instead.',
    );
  }

  return (
    <Highlight
      textToHighlight={children}
      searchWords={searchWords}
      className={className}
      autoEscape
    />
  );
};

export default Highlighter;
