import React from 'react';
import styled from 'styled-components';

import CardFooter from 'components/lib/ui/CardFooter';
import CheckCircle from 'components/lib/ui/CheckCircle';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { useFlowContext } from 'lib/contexts/FlowContext';

import * as COPY from 'common/constants/copy';

const CHECK_CIRCLE_SIZE_PX = 45;
const CHECK_ICON_SIZE_PX = 22;

const Content = styled(FlexContainer).attrs({ column: true })`
  gap: ${({ theme }) => theme.spacing.default};
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const Check = styled(CheckCircle).attrs({ iconSizePx: CHECK_ICON_SIZE_PX })`
  width: ${CHECK_CIRCLE_SIZE_PX}px;
  height: ${CHECK_CIRCLE_SIZE_PX}px;
`;

const TextContainer = styled(FlexContainer).attrs({ column: true })`
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  goBack?: () => void;
};

const RequestRefundSuccessModal = ({ goBack }: Props) => {
  const { skipToComplete } = useFlowContext();

  return (
    <ModalCard title="Request submitted" hideBottomBorder>
      <Content>
        <Check />
        <TextContainer>
          <Text weight="medium" size="large">
            {COPY.SUPPORT.FORM_SUCCESS_TITLE}
          </Text>
          <Text>{COPY.SUPPORT.FORM_SUCCESS_SUBTITLE.join('\n\n')}</Text>
        </TextContainer>
      </Content>
      <CardFooter>
        <PrimaryButton onClick={() => skipToComplete()}>Okay</PrimaryButton>
      </CardFooter>
    </ModalCard>
  );
};

export default RequestRefundSuccessModal;
