import React, { useContext } from 'react';

import FormContext from 'common/components/form/FormContext';
import TransactionBulkUpdateConfirmationModal from 'components/transactions/TransactionBulkUpdateConfirmationModal';

import useGetCategory from 'common/lib/hooks/categories/useGetCategory';
import useGetGoal from 'common/lib/hooks/goalsV2/useGetGoal';
import useHouseholdUsers from 'common/lib/hooks/household/useHouseholdUsers';
import useTransactionTags from 'common/lib/hooks/transactions/useTransactionTags';
import { confirmationModalValuesAdapter } from 'common/lib/transactions/Rules';
import { transactionTagsAdapter } from 'common/lib/transactions/bulkUpdate';

type Props = {
  selectedCount: number;
  initialTags?: string[];
};

const TransactionRuleConfirmationModal = ({ selectedCount, initialTags = [] }: Props) => {
  const { values } = useContext(FormContext);

  const { getTags } = useTransactionTags();
  const [tagsAdded, removedTags] = transactionTagsAdapter(values.addTagsAction || [], initialTags);
  const tags = getTags([...tagsAdded, ...removedTags]);

  const { category } = useGetCategory(values.setCategoryAction);
  const { goal } = useGetGoal(values.linkGoalAction);
  const [{ users }] = useHouseholdUsers();

  const data = confirmationModalValuesAdapter(values, category, tags, goal, users);

  return (
    <TransactionBulkUpdateConfirmationModal
      selectedCount={selectedCount}
      confirmationData={data}
      removedTags={removedTags}
    />
  );
};

export default TransactionRuleConfirmationModal;
