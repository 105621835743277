import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo, useEffect } from 'react';
import type { Column } from 'react-table';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import CsvTable from 'components/accounts/CsvTable';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import Dot from 'components/lib/ui/Dot';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import Scroll from 'components/lib/ui/Scroll';
import Text from 'components/lib/ui/Text';
import Table from 'components/lib/ui/table/Table';

import { mergeCurrentUploadSession } from 'actions';
import { escapeFieldName, unescapeFieldName } from 'common/lib/form/field';
import useTransactionTags from 'common/lib/hooks/transactions/useTransactionTags';
import { MINT_TAGS_COLUMN_INDEX } from 'lib/csv/validation';
import useDispatch from 'lib/hooks/useDispatch';
import useModal from 'lib/hooks/useModal';

import type { ArrayType } from 'common/types/utility';

const TABLE_INITIAL_STATE = { sortBy: [{ id: 'transactionCount', desc: true }] };
export const IGNORE_VALUE = 'ignore';
export const ADD_VALUE = 'add_tag';
const PREVIEW_ROW_COUNT = 100;

const Root = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  max-width: 765px;
  width: 100%;
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyledSubmitButton = styled(FormSubmitButton)`
  width: 100%;
  max-width: 438px;
  margin-top: 0;
`;

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSelectField = styled(SelectField)`
  min-width: 280px;
  text-align: left;
`;

const TableContainer = styled.div`
  width: 100%;
  text-align: left;
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  border-radius: ${({ theme }) => theme.radius.medium};
  margin: ${({ theme }) => theme.spacing.xxlarge} 0;

  > * {
    margin-bottom: 0;
  }
`;

const LeftAlignHeader = styled(Text).attrs({ align: 'left ' })`
  display: block;
`;

const PreviewModalCard = styled(ModalCard)`
  overflow: hidden;
`;

const PreviewScroll = styled(Scroll)`
  max-height: 60vh;
`;

const ClickableText = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: ${({ theme }) => theme.spacing.xxsmall};

  :hover {
    color: ${({ theme }) => theme.color.blue};
  }
`;

const Subtitle = styled(Text)`
  text-align: center;
`;

const DOT_LABEL_SIZE_PX = 12;

const StyledDot = styled(Dot).attrs({ size: DOT_LABEL_SIZE_PX })`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

/** Saves form values in redux in case the user leaves the page and comes back */
const CacheFormValues = () => {
  const dispatch = useDispatch();
  const { values } = useFormContext<{ [key: string]: string }>();

  useEffect(() => {
    dispatch(mergeCurrentUploadSession({ accountNameMapping: values }));
  }, [values, dispatch]);

  return null;
};

type TagSelectFieldProps = {
  accountName: string;
  options: any;
  isLoading: boolean;
};

const TagSelectField = ({ accountName, options, isLoading }: TagSelectFieldProps) => {
  const fieldName = escapeFieldName(accountName);

  return (
    <>
      <StyledSelectField
        autoScrollToTop
        name={fieldName}
        hideLabel
        placeholder="Select a tag..."
        isLoading={isLoading}
        options={[
          { label: 'Add as a new tag', value: ADD_VALUE },
          { label: 'Ignore this tag', value: IGNORE_VALUE },
          ...(options ?? []),
        ]}
        required
      />
    </>
  );
};

type Props = {
  parsedRows?: string[][];
  tagMapping?: Record<string, string>;
  onSubmit: (values: Record<string, any>) => Promise<any>;
};

const ImportMintMapAccounts = ({ parsedRows, onSubmit, tagMapping }: Props) => {
  const header = useMemo(() => parsedRows?.[0] ?? [], [parsedRows]);

  const rowsByTag = useMemo(() => {
    const rows = R.drop(1, parsedRows ?? []).filter((val) => val.length > 1) as string[][];
    const groupedByTag: Record<string, any[]> = {};
    rows.forEach((row) => {
      const tags = row[MINT_TAGS_COLUMN_INDEX]?.split(' ') ?? [];
      tags
        .filter((x) => x?.length > 0)
        .forEach((tag) => {
          if (!groupedByTag[tag]) {
            groupedByTag[tag] = [];
          }
          groupedByTag[tag] = [...groupedByTag[tag], row];
        });
    });

    return groupedByTag;
  }, [parsedRows]);

  const tableData = useMemo(
    () =>
      R.toPairs(rowsByTag).map(([name, rows]) => ({
        name,
        transactionCount: rows.length,
      })),
    [rowsByTag],
  );

  const { tags, isLoadingInitialData, refetch } = useTransactionTags({
    includeTransactionCount: true,
  });

  const initialValues = useMemo(
    () =>
      tagMapping ||
      R.fromPairs(
        R.toPairs(rowsByTag).map(([tagName]) => [
          escapeFieldName(tagName),
          tags.find((x) => x.name.toLowerCase() === tagName.toLowerCase())?.name ?? ADD_VALUE,
        ]),
      ),
    [tagMapping, tags],
  );

  const [PreviewRowsModal, { open: openPreviewRowsModal, context: previewingAccountName }] =
    useModal<string>();

  const columns = useMemo(
    (): Column<ArrayType<typeof tableData>>[] => [
      {
        accessor: 'name',
        Header: 'Mint Tag',
      },
      {
        id: 'account',
        Header: () => <LeftAlignHeader>Monarch Tag</LeftAlignHeader>,
        accessor: 'name',
        defaultCanSort: false,
        Cell: ({ value }) => (
          <TagSelectField
            accountName={value}
            options={tags.map((tag) => ({
              value: tag.name,
              label: tag.name,
              icon: <StyledDot color={tag.color} />,
            }))}
            isLoading={isLoadingInitialData}
          />
        ),
      },
      {
        accessor: 'transactionCount',
        Header: 'Transactions',
        sortDescFirst: true,
        Cell: ({ value, row }) => (
          <ClickableText onClick={() => openPreviewRowsModal(row.original.name)}>
            {value}
          </ClickableText>
        ),
      },
    ],
    [isLoadingInitialData, tags, refetch, openPreviewRowsModal],
  );

  return (
    <Root>
      <Title>Assign your Mint tags to Monarch tags</Title>
      <Subtitle>
        Assign each of your Mint tags to a tag in Monarch. Mint separates tags by spaces, so there’s
        a chance there are extra tags in the list below that can be ignored. You can always rename
        tags later.
      </Subtitle>
      <StyledForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        transformValuesBeforeSubmit={(values) => ({
          mapping: R.pipe(
            R.pickBy((val) => val !== IGNORE_VALUE),
            RA.renameKeysWith(unescapeFieldName),
          )(values),
        })}
      >
        <CacheFormValues />
        <TableContainer>
          <Table
            columns={columns}
            data={tableData}
            showFooter={false}
            initialState={TABLE_INITIAL_STATE}
            empty={{
              title: 'No Mint tags found in your CSV upload',
              subtitle:
                'You can continue to the next step. If you think this is an error, make sure there is a column titled “Labels” in your CSV and try again.',
            }}
          />
        </TableContainer>

        <StyledSubmitButton size="large" disableWhenValuesUnchanged={false}>
          Next
        </StyledSubmitButton>
      </StyledForm>
      <PreviewRowsModal large>
        <PreviewModalCard title={previewingAccountName}>
          <PreviewScroll>
            <CsvTable
              header={header}
              rows={R.take(PREVIEW_ROW_COUNT, rowsByTag[previewingAccountName ?? ''] ?? [])}
            />
          </PreviewScroll>
        </PreviewModalCard>
      </PreviewRowsModal>
    </Root>
  );
};

export default ImportMintMapAccounts;
