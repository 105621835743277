import type { DurationLikeObject } from 'luxon';
import { DateTime } from 'luxon';
import * as R from 'ramda';

import type { ThemeType } from 'common/types/Styles';

export type DateRange = {
  display: string;
  longDisplay: string;
  longDisplaySingular?: string;
  duration: DurationLikeObject;
};

export const DATE_RANGES: DateRange[] = [
  { display: '1M', longDisplay: '1 month', duration: { months: 1 } },
  {
    display: '3M',
    longDisplay: '3 months',
    duration: { months: 3 },
    longDisplaySingular: '3 month',
  },
  {
    display: '6M',
    longDisplay: '6 months',
    duration: { months: 6 },
    longDisplaySingular: '6 month',
  },
  { display: '1Y', longDisplay: '1 year', duration: { years: 1 } },
  { display: 'ALL', longDisplay: 'All time', duration: { years: 150 } },
];

export const BREAKDOWN_DATE_RANGES: DateRange[] = [
  { display: '1M', longDisplay: 'Past Month', duration: { months: 1 } },
  { display: '3M', longDisplay: 'Past 3 Months', duration: { months: 3 } },
  { display: '6M', longDisplay: 'Past 6 Months', duration: { months: 6 } },
  { display: '1Y', longDisplay: 'Past Year', duration: { years: 1 } },
  { display: 'ALL', longDisplay: 'All Time', duration: { years: 150 } },
];

export const getDateRangesForChartType = (chartType: string) =>
  chartType === 'breakdown' ? BREAKDOWN_DATE_RANGES : DATE_RANGES;

export const NET_WORTH_CHART_TYPES = ['performance', 'breakdown'] as const;

export type NetWorthChartType = (typeof NET_WORTH_CHART_TYPES)[number];

export const getSnapshotDataForTimePeriod = <T extends SnapshotEntry>(
  duration: DurationLikeObject,
  data: T[],
  offset = 0,
): { data: T[]; hasOlderData: boolean } => {
  const oldestDate = R.reduce(
    R.minBy((date: DateTime) => date.valueOf()),
    DateTime.local(),
    data.map(({ date }) => DateTime.fromISO(date)),
  );

  const startDate = DateTime.max(
    DateTime.local().minus({
      months: (duration.months ?? 0) * (offset + 1),
      years: (duration.years ?? 0) * (offset + 1),
      weeks: (duration.weeks ?? 0) * (offset + 1),
    }),
    oldestDate,
  ).startOf('day');

  const endDate = startDate.plus(duration);

  const dateIsInTimeFrame = (dateISO: string) =>
    startDate <= DateTime.fromISO(dateISO) && DateTime.fromISO(dateISO) <= endDate;

  return {
    hasOlderData: oldestDate < startDate,
    data: data.filter(({ date }) => dateIsInTimeFrame(date)),
  };
};

export type SnapshotEntry = {
  balance: number;
  date: string;
  assetsBalance?: number | null;
  liabilitiesBalance?: number | null;
};

/**
 * Only show the longest date range choice that has an affect on the data. I.e. if we only have
 * 1 week of data, don't give users the option to specify 1 month etc.
 *
 * We do include a timeframe if we have data in between it and the previous timeframe
 * i.e. if we have 2 months of data, we will include 1 Week, 1 Month, 3 Months even though we don't
 * have the full 3 months. See earliestDateInBounds
 *
 * We also exclude recent timeframes that don't have data associated with them. For example, if our
 * latest data point is 12 days ago, we exclude the 1 Month time frame. See latestDateInBounds
 *
 * We always include the ALL option if users want a complete view of their data.
 */
export const getAvailableTimePeriodsForDateRanges =
  <T extends DateRange>(dateRanges: T[]) =>
  (data: SnapshotEntry[]) => {
    const now = DateTime.local();
    if (!(data.length > 0)) {
      return dateRanges.filter(({ display }) => display === 'ALL');
    }
    const [earliestDate, latestDate] = [data?.[0], data?.[data.length - 1]].map(({ date }) =>
      DateTime.fromISO(date),
    );

    const earliestDateInBounds = (prevDuration: DurationLikeObject) =>
      earliestDate < now.minus(prevDuration);
    const latestDateInBounds = (duration: DurationLikeObject) => latestDate > now.minus(duration);

    return dateRanges.filter(
      ({ display, duration }, i) =>
        (earliestDateInBounds(dateRanges[Math.max(0, i - 1)].duration) &&
          latestDateInBounds(duration)) ||
        display === 'ALL',
    );
  };

type GetTrendIndicatorLineColorArgs = {
  isAsset?: boolean;
  balance: number;
  theme: ThemeType;
};

export const getTrendIndicatorLineColor = ({
  isAsset,
  balance,
  theme,
}: GetTrendIndicatorLineColorArgs) => {
  if (balance === 0) {
    return theme.color.grayDark;
  }

  if (isAsset === undefined) {
    return balance < 0 ? theme.color.redText : theme.color.greenText;
  }

  if (isAsset) {
    return balance >= 0 ? theme.color.greenText : theme.color.redText;
  } else {
    return balance <= 0 ? theme.color.greenText : theme.color.redText;
  }
};

export const getTrendIndicatorIconGivenChange = (change: number) => {
  if (change === 0) {
    return 'arrow-right' as const;
  }

  return change < 0 ? ('arrow-down-right' as const) : ('arrow-up-right' as const);
};

export const getSnapshotDataForSnapshotGraph = <T extends SnapshotEntry[]>(
  signedSnapshots: T,
  isAsset: boolean,
) => {
  const snapshots = signedSnapshots.map(
    R.evolve({
      // invert display balance for liabilities
      balance: R.multiply(isAsset ? 1 : -1),
      // invert total liabilities balance
      liabilitiesBalance: (l) => R.multiply(-1, l),
    }),
  );
  return {
    snapshots,
    displayBalance: R.last(snapshots)?.balance ?? 0.0,
  };
};
