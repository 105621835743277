import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';

import * as actions from 'common/state/user/actions';

import type { UserHouseholdRole } from 'common/generated/graphQlTypes/globalTypes';
import type { Id } from 'common/types';

const { login, logout, updateUser, fetchUser, setTokenExpiration } = actions;

type UserAction = ActionType<typeof actions>;

const INITIAL_USER_STATE: UserState = {
  id: null,
  external_id: null,
  created_at: null,
  name: '',
  email: '',
  timezone: null,
  token: null,
  tokenExpiration: null,
  birthday: null,
  household: null,
  is_demo: false,
  is_temporary_demo: false,
  is_sponsor: false,
  household_role: null,
  should_force_redirect_sponsor: false,
  other_info: {
    is_superuser: false,
    is_employee: false,
  },
  split_attributes: {},
};

export type UpdateUserParams = {
  timezone?: string;
  name?: string;
  email?: string;
  birthday?: string | null;
  is_sponsor?: boolean;
};

export type UserState = {
  id: Id | null;
  external_id: Id | null;
  created_at: string | null;
  name: string;
  email: string;
  timezone: string | null;
  token: string | null;
  tokenExpiration: GraphQlDateTime | null;
  birthday: string | null;
  household: { id: Id; external_id: Id } | null;
  household_role: UserHouseholdRole | null;
  is_demo: boolean;
  is_temporary_demo: boolean;
  is_sponsor: boolean;
  should_force_redirect_sponsor: boolean;
  other_info: {
    is_superuser: boolean;
    is_employee: false;
  };
  split_attributes: {
    [key: string]: string | boolean | number;
  };
};

const user = createReducer<UserState, UserAction>(INITIAL_USER_STATE)
  .handleAction(login, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(setTokenExpiration, (state, { payload: tokenExpiration }) => ({
    ...state,
    tokenExpiration,
  }))
  .handleAction(logout, (state) => INITIAL_USER_STATE)
  .handleAction(fetchUser, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(updateUser, (state, { payload }) => ({
    ...state,
    ...payload,
  }));

export default user;
