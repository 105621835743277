import { isNotNil } from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import FieldCell, { GroupHorizontalContainer } from 'components/lib/form/FieldCell';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import { DangerAsyncButton } from 'components/lib/ui/button/DangerButton';
import { DefaultAsyncButton } from 'components/lib/ui/button/DefaultButton';

import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import { useContactSupportContext } from 'lib/contexts/ContactSupportContext';

import type { Web_GetSecuritySettingsQuery } from 'common/generated/graphql';

const Root = styled(FieldCell)`
  padding: ${({ theme }) => theme.spacing.xlarge};

  ${GroupHorizontalContainer} {
    align-items: flex-start;
  }
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Subtitle = styled(FlexContainer).attrs({
  gap: 'default',
  marginTop: 'xxsmall',
  column: true,
})``;

const ButtonContainer = styled(FlexContainer).attrs({
  justifyEnd: true,
})`
  min-width: 140px;
`;

type Props = {
  supportAccountAccessGrant: Web_GetSecuritySettingsQuery['me']['activeSupportAccountAccessGrant'];
  toggleSupportAccountAccess: (isEnabled: boolean) => void;
  isTogglingSupportAccountAccess: boolean;
};

const SupportSettingsCardContent = ({
  supportAccountAccessGrant,
  toggleSupportAccountAccess,
  isTogglingSupportAccountAccess,
}: Props) => {
  const { openContactSupportModal } = useContactSupportContext();

  return (
    <Root
      title={<Title>Account access</Title>}
      subtitle={
        <Subtitle>
          <span>
            To better help troubleshoot your issues, you may grant full account access to Monarch
            authorized support agents. Access will auto-disable after 14 days, or by you at any
            time.
          </span>
          <span>
            If you haven&apos;t already, you may also{' '}
            <Link onClick={() => openContactSupportModal()}>create a new support ticket</Link>.
          </span>
          {isNotNil(supportAccountAccessGrant) && (
            <Text color="greenText" italic>
              Access granted{' '}
              {isoDateToAbbreviatedMonthDayAndYear(supportAccountAccessGrant.createdAt)}. Expires{' '}
              {isoDateToAbbreviatedMonthDayAndYear(supportAccountAccessGrant.expiresAt)}.
            </Text>
          )}
        </Subtitle>
      }
      rightAccessory={
        <ButtonContainer>
          {!supportAccountAccessGrant ? (
            <DefaultAsyncButton
              onClick={() =>
                !isTogglingSupportAccountAccess &&
                toggleSupportAccountAccess(!supportAccountAccessGrant)
              }
              pending={isTogglingSupportAccountAccess}
              size="small"
            >
              Grant access
            </DefaultAsyncButton>
          ) : (
            <DangerAsyncButton
              onClick={() =>
                !isTogglingSupportAccountAccess &&
                toggleSupportAccountAccess(!supportAccountAccessGrant)
              }
              pending={isTogglingSupportAccountAccess}
              size="small"
            >
              Revoke access
            </DangerAsyncButton>
          )}
        </ButtonContainer>
      }
      borderless
    />
  );
};

export default SupportSettingsCardContent;
