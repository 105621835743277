import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';

import * as actions from 'common/state/analytics/actions';

const { setSegmentAnonymousUserId } = actions;

type AnalyticsAction = ActionType<typeof actions>;

export type AnalyticsState = {
  segmentAnonymousUserId?: string;
};

const INITIAL_STATE: AnalyticsState = {};

const analyticsReducer = createReducer<AnalyticsState, AnalyticsAction>(INITIAL_STATE).handleAction(
  setSegmentAnonymousUserId,
  (state, { payload: segmentAnonymousUserId }) => ({
    ...state,
    segmentAnonymousUserId,
  }),
);

export default analyticsReducer;
