import { useEffect } from 'react';

import { track } from 'lib/analytics/segment';

import type { AnalyticsEvent } from 'common/constants/analytics';

/**
 * Hook used to track an event. By default it will track only
 * once when a component first loads. This behavior can be changed
 * by passing in a deps array.
 */
const useTrack = (event: AnalyticsEvent, params = {}, deps = []) => {
  useEffect(() => {
    track(event, params);
  }, deps);
};

export default useTrack;
