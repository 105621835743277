import { DateTime } from 'luxon';
import * as R from 'ramda';
import React, { useState } from 'react';

import type { AbstractFieldProps, ChildrenRenderProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import type { DatePickerProps } from 'components/lib/ui/DatePicker';
import DatePicker from 'components/lib/ui/DatePicker';

import noop from 'common/utils/noop';

import { MS_PER_SECOND, SECONDS_PER_MINUTE } from 'common/constants/time';

type Props = AbstractFieldProps<DatePickerProps> & {
  valueFormatter?: (prevValue: string | undefined, nextValue: string) => string;
};

type WrapperProps = Props &
  Pick<ChildrenRenderProps, 'id' | 'value' | 'setFieldValue' | 'handleBlur'>;

/**
 * Proxy component between the form and the Datepicker component.
 * We want full control over the raw value in the component (value), but we only want to expose
 * valid ISODates to the form. This component proxies between the two, managing the internal state
 * of the UI in its state, while only setting the form value to valid values in the correct format.
 */
const DatePickerProxy = ({
  id,
  value,
  valueFormatter,
  setFieldValue,
  handleBlur,
  ...props
}: WrapperProps) => {
  const [prevValue, setPrevValue] = useState<string | undefined>(undefined);
  const [currentValue, setCurrentValue] = useState<string>('');

  if (value) {
    const maybeCurrentValue = DateTime.fromISO(value).toFormat(props.dateFormat);
    if (currentValue !== maybeCurrentValue) {
      setCurrentValue(maybeCurrentValue);
    }
  } else if (!value && currentValue) {
    // This condition is required because we want to be able to reset this field value to an empty string.
    // This was required because while trying to reset a form, this field was not cleaning up it's current value.
    setCurrentValue(value);
  }
  const formattedValue = valueFormatter ? valueFormatter(prevValue, currentValue) : currentValue;

  const dateValue = value && new Date(value);
  // react-datepicker assumes date is UTC, adjust value as per
  // https://github.com/Hacker0x01/react-datepicker/issues/1018
  const offsetValue = dateValue
    ? new Date(
        dateValue.getTime() + dateValue.getTimezoneOffset() * SECONDS_PER_MINUTE * MS_PER_SECOND,
      )
    : dateValue;

  return (
    <DatePicker
      {...props}
      id={id}
      onChangeRaw={({ target: { value: nextValue } }) => {
        setPrevValue(currentValue);
        setCurrentValue(nextValue);

        if (R.isNil(nextValue)) {
          return;
        }

        const dt = DateTime.fromFormat(nextValue, props.dateFormat);

        if (dt.isValid) {
          setFieldValue(props.name, dt.toISODate());
        } else {
          setFieldValue(props.name, undefined);
        }
      }}
      onChange={noop}
      onSelect={(selectedDate, event) => {
        if (event && event.type === 'click') {
          const dt = DateTime.fromJSDate(selectedDate);

          setPrevValue(currentValue);
          setCurrentValue(dt.toFormat(props.dateFormat));

          setFieldValue(props.name, dt.toISODate());
          handleBlur(event);
        }
      }}
      onBlur={handleBlur}
      value={formattedValue}
      // Selected set anytime onChange or onSelect is fired.
      selected={offsetValue}
    />
  );
};

const DateField = ({ className, valueFormatter, ...props }: Props) => (
  <AbstractField {...props} className={className}>
    {(innerProps) => <DatePickerProxy valueFormatter={valueFormatter} {...props} {...innerProps} />}
  </AbstractField>
);

export default DateField;
