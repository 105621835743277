/* eslint-disable no-nested-ternary */
import { rgba } from 'polished';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import type { CurrencyType } from 'components/lib/ui/currency/CashFlowCurrency';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';
import Currency from 'components/lib/ui/currency/Currency';

import variables from 'common/lib/theme/variables';

export const WIDTH_PX = 300;

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.xxxsmall} 0;
  width: ${WIDTH_PX}px;
`;

type RowProps = {
  $medium?: boolean;
};

const Row = styled(FlexContainer).attrs({ justifyBetween: true, alignCenter: true })<RowProps>`
  font-weight: ${({ theme, $medium }) =>
    $medium ? theme.fontWeight.medium : theme.fontWeight.book};
  font-size: ${({ theme }) => theme.fontSize.small};

  margin-top: ${({ theme }) => theme.spacing.xsmall};
  padding: 0 ${({ theme }) => theme.spacing.default};
`;

const MediumWeight = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const HeaderFooter = styled(Row)`
  --border-color: ${({ theme }) =>
    theme.uiTheme === 'dark' ? variables.color.border.base : rgba(0, 0, 0, 0.2)};

  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  :first-child {
    border-bottom: 1px solid var(--border-color);
    margin-top: 0;
  }

  :last-child {
    border-top: 1px solid var(--border-color);
  }
`;

const Placeholder = styled(Text)`
  color: ${({ theme }) => theme.color.textWhite};
  opacity: 0.5;
`;

export type AmountsTooltipRowProps =
  | {
      title: string;
      value: number | undefined;
      placeholder?: string;
      type?: CurrencyType;
      emphasis?: boolean;
    }
  | {
      component: React.ReactElement;
    };

const isRowComponent = (row: AmountsTooltipRowProps): row is { component: React.ReactElement } =>
  'component' in row;

const AmountsRow = React.memo((props: AmountsTooltipRowProps) =>
  isRowComponent(props) ? (
    props.component
  ) : (
    <>
      <Text>{props.title}</Text>
      <MediumWeight>
        {R.isNil(props.value) && !!props.placeholder ? (
          <Placeholder>{props.placeholder}</Placeholder>
        ) : props.type ? (
          <CashFlowCurrency round type={props.type} emphasis={props.emphasis} value={props.value} />
        ) : (
          <Currency round value={props.value} />
        )}
      </MediumWeight>
    </>
  ),
);

type Props = {
  rows: AmountsTooltipRowProps[];
  title?: string;
};

const AmountsTooltip = ({ title, rows }: Props) => {
  const footer = R.last(rows);

  return (
    <Root>
      {!!title && <HeaderFooter>{title}</HeaderFooter>}
      {R.dropLast(1, rows).map((row) => (
        <Row key={isRowComponent(row) ? row.component.key : row.title}>
          <AmountsRow {...row} />
        </Row>
      ))}
      {footer && (
        <HeaderFooter>
          <AmountsRow {...footer} />
        </HeaderFooter>
      )}
    </Root>
  );
};

export default AmountsTooltip;
