import React from 'react';
import { useHistory } from 'react-router-dom';

import ProductLaunchModal from 'components/lib/ui/ProductLaunchModal';

import useMigrateToGoalsV2 from 'common/lib/hooks/goalsV2/useMigrateToGoalsV2';
import toast from 'lib/ui/toast';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

const GoalsV2MigrationPrompt = () => {
  const { migrateToGoalsV2, isMigrating } = useMigrateToGoalsV2();

  const { push } = useHistory();

  return (
    <ProductLaunchModal
      icon="target"
      title={COPY.GOALS.MIGRATE_PROMPT.TITLE}
      rows={COPY.GOALS.MIGRATE_PROMPT.BULLET_POINTS}
      button={{
        title: COPY.GOALS.MIGRATE_PROMPT.BUTTON,
        pending: isMigrating,
        onClick: async () => {
          try {
            const { data } = await migrateToGoalsV2();
            if (!data?.migrateToGoalsV2?.success) {
              throw Error();
            }

            push(routes.goalsV2.rank());
          } catch (e) {
            toast({ title: COPY.DEFAULT_ERROR_MESSAGE });
          }
        },
      }}
    />
  );
};

export default GoalsV2MigrationPrompt;
