import React from 'react';

type ScrollContextType = {
  scrollRef?: React.RefObject<HTMLDivElement>;
  scrollContainerId?: string;
  /** Calls react-scroll scrollTo */
  scrollTo: (elementName: string, options?: any) => void;
};

const ScrollContext = React.createContext<ScrollContextType>(undefined as any);

export default ScrollContext;
