import { useSelector } from 'react-redux';

import useQuery from 'common/lib/hooks/useQuery';
import { getPlanOnboardingUserIsNew } from 'common/state/employee/selectors';

import { gql } from 'common/generated';

const useBudgetOnboardingState = () => {
  const { data } = useQuery(QUERY, {
    fetchPolicy: 'cache-first',
  });

  const hasBudget = data?.budgetStatus?.hasBudget;
  const isOnFreeTrial = data?.subscription?.isOnFreeTrial;

  // This flag indicates if the user is forced into the "new user" experience,
  // regardless of their actual status. It's set by the employee menu.
  const isForcedNewUserExperience = useSelector(getPlanOnboardingUserIsNew);

  // In the budget onboarding flow, we consider users that have a budget and
  // are not on a free trial to be "existing users" whereas "new users" are
  // those that are on a free trial or don't have a budget.
  const isExistingUser =
    isForcedNewUserExperience !== undefined
      ? !isForcedNewUserExperience
      : !!(hasBudget && !isOnFreeTrial);

  // This is just an alias, but useful to have in case we want to change any logic in the future.
  const skipConnectingAccounts = isExistingUser;

  return {
    isExistingUser,
    skipConnectingAccounts,
  };
};

const QUERY = gql(/* GraphQL */ `
  query Common_GetPlanOnboardingState {
    subscription {
      id
      isOnFreeTrial
    }

    budgetStatus {
      ...BudgetStatusFields
    }
  }
`);

export default useBudgetOnboardingState;
