import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import Dot from 'components/lib/ui/Dot';
import Tooltip from 'components/lib/ui/Tooltip';

import { color, radius, spacing, transition } from 'common/lib/theme/dynamic';
import { sortTags } from 'common/lib/transactions/tags';

import { TRANSACTION_ROW_ICON_SIZE_PX } from 'constants/transactions';

const MAX_DISPLAY_DOTS = 4;
const DOT_SIZE_DEFAULT_PX = 8;
const DOT_SIZE_SMALL_PX = 6;

const GRID_TEMPLATE_FOR_COUNT: { [key: number]: string } = {
  1: `'dot0'`,
  2: `'dot0 dot1'`,
  3: `'dot0 dot0'
      'dot1 dot2'`,
  4: `'dot0 dot1'
      'dot2 dot3'`,
};

type Tag = {
  id: string;
  name: string;
  color: string;
  order: number;
};

const Root = styled.div<{ $tagCount: number }>`
  flex-shrink: 0;
  width: ${TRANSACTION_ROW_ICON_SIZE_PX}px;
  height: ${TRANSACTION_ROW_ICON_SIZE_PX}px;
  border-radius: ${radius.round};
  transition: ${transition.default};
  user-select: none;

  display: grid;
  grid-template-areas: ${({ $tagCount }) =>
    GRID_TEMPLATE_FOR_COUNT[Math.min(MAX_DISPLAY_DOTS, $tagCount)]};
  grid-column-gap: ${spacing.xxxsmall};
  grid-row-gap: ${({ $tagCount }) => ($tagCount === 3 ? '1px' : '2px')};
  justify-content: center;
  justify-items: center;
  align-content: center;
  padding-bottom: ${({ $tagCount }) =>
    // hack to get 3 dots to look vertically aligned
    $tagCount === 3 ? '2px' : 0};

  :hover {
    background: ${color.grayBackground};
  }
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.xsmall} ${spacing.small};
  gap: ${spacing.xxxsmall};
`;

const TooltipRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xsmall};
  justify-content: flex-start;
`;

type Props<T> = {
  tags: T[];
  onClick?: () => void;
};

const TransactionTagsIcon = <T extends Tag>({ tags, onClick }: Props<T>) => {
  const sorted = useMemo(() => sortTags(tags), [tags]);

  return (
    <Tooltip
      content={
        <TooltipContent>
          {sorted.map(({ id, name, color }) => (
            <TooltipRow key={id}>
              <Dot color={color} size={DOT_SIZE_DEFAULT_PX} />
              <span>{name}</span>
            </TooltipRow>
          ))}
        </TooltipContent>
      }
    >
      <Root onClick={onClick} $tagCount={tags.length}>
        {R.take(MAX_DISPLAY_DOTS, sorted).map(({ id, color }, i) => (
          <Dot
            color={color}
            key={id}
            size={tags.length >= MAX_DISPLAY_DOTS ? DOT_SIZE_SMALL_PX : DOT_SIZE_DEFAULT_PX}
            style={{ gridArea: `dot${i}` }}
          />
        ))}
      </Root>
    </Tooltip>
  );
};

export default TransactionTagsIcon;
