import * as Sentry from '@sentry/browser';

import isEnvProduction from 'lib/isEnvProduction';
import logger from 'lib/logger';

// This version doesn't rethrow errors, because otherwise, in dev we can get stuck in a loop
export const onUnhandledError = (error: Error) => {
  logger.error(error);
  if (isEnvProduction()) {
    Sentry.captureException(error);
  }
};

const onError = (error: Error) => {
  logger.error(error);

  if (isEnvProduction()) {
    Sentry.captureException(error);
  } else {
    throw error;
  }
};

export const onWarning = (message: string) => {
  if (isEnvProduction()) {
    Sentry.captureMessage(message, Sentry.Severity.Warning);
  } else {
    logger.warn(message);
  }
};

export default onError;
