import { createSelector } from 'reselect';

import { getSplitUserAttributes } from 'state/user/selectors';

// Selectors from multiple state domains

export const getSplitAttributes = createSelector(getSplitUserAttributes, (splitUserAttributes) => ({
  platform: 'web',
  ...splitUserAttributes,
}));
