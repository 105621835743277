const lightPalette = {
  // ---------- Text ----------- //
  text: '#082864',
  textLight: '#7886A3',
  textWhite: '#FFFFFF',
  // ----- Primary Palette ----- //
  navy: '#1348A5',
  navyDark: '#103D8C',
  navyLight: '#2B5AAE',
  navyFocus: '#D0DAED',
  navyBackground: '#E7EDF6',
  orange: '#FF7369',
  orangeDark: '#FF685F',
  orangeLight: '#FF7E73',
  orangeFocus: '#FFE3E1',
  orangeBackground: '#FFF1F0',
  orangeText: '#F05C54',
  yellow: '#FFD278',
  yellowDark: '#FFCE6C',
  yellowLight: '#FFD684',
  yellowFocus: '#FFF6E4',
  yellowBackground: '#FFFAF1',
  yellowText: '#BC883F',
  blue: '#32AAF0',
  blueDark: '#2DA2EE',
  blueLight: '#37B2F1',
  blueFocus: '#D6EEFC',
  blueBackground: '#EAF6FD',
  blueText: '#3D92DE',
  iosDefaultBlue: '#007AFF',
  // ----- Gray Variations ----- //
  black: '#082864',
  trueBlack: '#000000',
  grayDark: '#7886A3',
  gray: '#C8D2E1',
  grayLight: '#DAE1EA',
  grayFocus: '#E4E9F0',
  grayBackground: '#F0F4F8',
  grayLightBackground: '#F7F8FB',
  white: '#FFFFFF',
  // ----- Secondary Palette ----- //
  red: '#F0648C',
  redDark: '#EE5A81',
  redLight: '#F16E97',
  redFocus: '#FCE0E8',
  redBackground: '#FDEFF3',
  redText: '#ED5685',
  green: '#19D2A5',
  greenDark: '#17CE9C',
  greenLight: '#1BD6AE',
  greenFocus: '#D1F6ED',
  greenBackground: '#E8FAF6',
  greenText: '#489D8C',
  teal: '#91DCEB',
  purple: '#6E87F0',
  purpleBackground: '#E2E7FC',
  lime: '#CDDC39',
  limeBackground: '#F5F8D7',
  pink: '#EA80FC',
  pinkBackground: '#FBE6FE',
  indigo: '#3D5AFE',
  indigoBackground: '#D8DEFF',
};

export default lightPalette;
