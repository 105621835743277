import { ellipsis } from 'polished';
import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';

import Select from 'components/lib/form/Select';
import Dot from 'components/lib/ui/Dot';
import Emoji from 'components/lib/ui/Emoji';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

import useHouseholdUsers, { ANYONE_ID } from 'common/lib/hooks/household/useHouseholdUsers';
import { TransactionsDropdownFilter } from 'common/lib/transactions/review';
import { formatThousands } from 'common/utils/Number';

const DOT_SIZE_PX = 12;
const TRANSACTION_COUNT_ICON_SIZE_PX = 10;

type Props = {
  className?: string;
  onChange: (value: any) => void;
  value: string;
};

const SelectLabel = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const NameWrapper = styled.div`
  flex-grow: 1;
  ${ellipsis()}
`;

const CountWrapper = styled.div`
  white-space: nowrap;
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

type OptionType = { value: string; label: string; needsReviewCount?: number };

const Option = (props: {
  label: string;
  count?: number;
  isSelected: boolean;
  showDot?: boolean;
}) => {
  const theme = useTheme();
  const isAllTransactionsOption = props.label === 'All transactions';
  const isAnyoneOption = props.label === 'Anyone';

  const shouldAddTitle = !(isAllTransactionsOption || isAnyoneOption);

  return (
    <FlexContainer justifyBetween alignCenter full gap="xsmall">
      {props.showDot && <Dot color={theme.color.orange} size={DOT_SIZE_PX} />}

      <NameWrapper title={shouldAddTitle ? props.label : undefined}>{props.label}</NameWrapper>

      {props.count !== undefined && props.count > 0 && !props.isSelected && (
        <CountWrapper>
          <Emoji>
            <Icon size={TRANSACTION_COUNT_ICON_SIZE_PX} name="credit-card" />
          </Emoji>
          {formatThousands(props.count ?? 0)}
        </CountWrapper>
      )}
    </FlexContainer>
  );
};

const ReviewSelect = (props: Props) => {
  const { onChange, value } = props;

  const [{ users, reviewSummaryByUser, currentUser }] = useHouseholdUsers();

  const currentUserHasNotificationsToReview = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    return reviewSummaryByUser?.[currentUser.id] > 0;
  }, [reviewSummaryByUser, currentUser]);

  const getLabelByValue = (value: string) => {
    if (value === TransactionsDropdownFilter.All) {
      return 'All transactions';
    }

    if (value === TransactionsDropdownFilter.NeedsReview) {
      return 'Anyone';
    }

    const user = users.find((user) => user.id === value);
    return user?.name ?? '';
  };

  const selectOptions = useMemo(
    () => [
      {
        id: 'all',
        options: [{ value: TransactionsDropdownFilter.All, label: 'All transactions' }],
      },
      {
        id: 'needs-review',
        label: 'Needs Review by...',
        options: [
          {
            value: TransactionsDropdownFilter.NeedsReview,
            label: 'Anyone',
            needsReviewCount: reviewSummaryByUser?.[ANYONE_ID] ?? 0,
          },
          ...users.map((user) => ({
            value: user.id,
            label: user.name,
            needsReviewCount: reviewSummaryByUser?.[user.id] ?? 0,
          })),
        ],
      },
    ],
    [users, reviewSummaryByUser],
  );

  const valueOption = useMemo(
    () => ({
      value,
      label: getLabelByValue(value),
    }),
    [value, users, currentUserHasNotificationsToReview],
  );

  return (
    <Select<OptionType>
      className={props.className}
      options={selectOptions}
      small
      // @ts-ignore for some reason TS is asking the value option to be an array which is not the case
      value={valueOption}
      onChange={onChange}
      renderOption={({ label, needsReviewCount, value }: OptionType, isSelected?: boolean) => (
        <Option
          showDot={(isSelected || currentUser?.id === value) && currentUserHasNotificationsToReview}
          label={label}
          count={needsReviewCount}
          isSelected={!!isSelected}
        />
      )}
      components={{
        GroupHeading: ({ children }: { children: React.ReactNode }) => (
          <SelectLabel>{children}</SelectLabel>
        ),
      }}
    />
  );
};

export default ReviewSelect;
