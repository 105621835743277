import { DateTime } from 'luxon';
import Papa from 'papaparse';
import * as R from 'ramda';
import { reverse, trim } from 'ramda';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import CurrencyInput from 'components/lib/form/CurrencyInput';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Link from 'components/lib/ui/Link';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';
import { ACCOUNT_DETAILS_GET_ACCOUNT_QUERY } from 'components/routes/AccountDetails';

import useLoading from 'common/lib/hooks/useLoading';
import { spacing } from 'common/lib/theme/dynamic';
import { formatFullDate } from 'common/utils/date';
import { track } from 'lib/analytics/segment';
import { useModalContext } from 'lib/contexts/ModalContext';
import refetchQueriesWithVariables from 'lib/graphQl/refetchQueriesWithVariables';
import useDownloadBalances from 'lib/hooks/accounts/useDownloadBalances';
import useUploadBalanceHistorySession from 'lib/hooks/statements/useUploadBalanceHistorySession';
import useTrack from 'lib/hooks/useTrack';

import { AccountEventNames } from 'common/constants/analytics';
import { HELP_CENTER_UPLOAD_BALANCE_HISTORY_ARTICLE_URL } from 'common/constants/externalUrls';

const DAYS_LIMIT = 90;
const CURRENCY_INPUT_MASK_OPTIONS = { allowDecimal: true, allowNegative: true };

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const SubmitButton = styled(AsyncButton)`
  ${primaryButtonMixin}
  margin-right: ${spacing.small};
`;

const StyledCurrencyInput = styled(CurrencyInput)`
  text-align: right;
`;

type Props = {
  accountId: string;
  accountName: string;
  date?: string;
  initialBalance?: number;
};

const EditBalanceHistoryModal = ({ accountId, accountName, date, initialBalance }: Props) => {
  const { close } = useModalContext();

  const { getBalancesCsv, downloadBalances } = useDownloadBalances();
  const downloadBalancesCsv = () => downloadBalances([accountId]);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [balances, setBalances] = useState<{ date: string; amount: number }[]>(
    date ? [{ date: date ?? '', amount: initialBalance ?? 0 }] : [],
  );

  useTrack(AccountEventNames.EditBalancesFormOpened);

  useEffect(() => {
    async function fetchData() {
      const balancesResponse: string = await getBalancesCsv([accountId]);
      const balancesTransformed = transformCsvToData(balancesResponse, DAYS_LIMIT);
      setBalances(balancesTransformed.slice(0, DAYS_LIMIT));
    }

    if (R.isNil(date)) {
      fetchData();
    }
  }, [accountId, date]);

  const { startSession, startParsing } = useUploadBalanceHistorySession({
    onParseSuccess: () => {
      refetchQueriesWithVariables([
        {
          query: ACCOUNT_DETAILS_GET_ACCOUNT_QUERY,
          variables: { id: accountId },
        },
      ]);
      close();
    },
  });

  const [isLoading, uploadFile] = useLoading(async () => {
    setErrorMessage('');

    const encodableBalances = [
      ['Date', 'Balance', 'Account'],
      ...balances.map(({ date, amount }) => [date, amount.toString(), accountName]),
    ];

    const csv = Papa.unparse(encodableBalances);
    const file = new File([new Blob([csv], { type: 'text/csv' })], 'balances');

    const data = new FormData();
    const accountFilesMapping = { [file.name]: accountId };
    data.append('files', file, file.name);
    data.append('account_files_mapping', JSON.stringify(accountFilesMapping));

    try {
      const { sessionKey } = await startSession(data);
      if (sessionKey) {
        await startParsing({ sessionKey });
        track(AccountEventNames.EditBalancesSaved);
      }
    } catch (e: any) {
      setErrorMessage('Something went wrong. Please try again.');
    }
  });

  const transformCsvToData = (csv: string, limit: number) => {
    const parsedCsv = Papa.parse<string[]>(trim(csv));
    const [_, ...balanceRows] = parsedCsv.data;
    const balancesReverseChronological = reverse(balanceRows);
    const balancesLatest = balancesReverseChronological.slice(0, limit);
    return balancesLatest.map((row: string[]) => ({ date: row[0], amount: parseFloat(row[1]) }));
  };

  const onUpdateBalance = (value: number, index: number) => {
    setBalances(R.update(index, { ...balances[index], amount: value })(balances));
  };

  const description = (
    <div>
      <p>
        {date
          ? `Note that this will overwrite the existing balance for the selected date.`
          : `Note that this will overwrite existing balances within the last ${DAYS_LIMIT} days of balance
        data.`}{' '}
        We recommend <Link onClick={downloadBalancesCsv}>downloading your existing balances</Link>{' '}
        beforehand just so you have a backup.
      </p>
      {!date && (
        <p>
          To overwrite balances prior to {DAYS_LIMIT} days ago, we recommend downloading, editing,
          and re-uploading your balances. Find out more about how to format your CSV for success{' '}
          <Link href={HELP_CENTER_UPLOAD_BALANCE_HISTORY_ARTICLE_URL} target="_blank">
            here
          </Link>
          .
        </p>
      )}
    </div>
  );

  return (
    <ModalCard title={`Edit account balances for ${accountName}`} description={description}>
      <Container>
        <FlexContainer justifyStart alignCenter marginBottom="large">
          <SubmitButton pending={isLoading} onClick={uploadFile}>
            Save changes
          </SubmitButton>
          {errorMessage && (
            <Text color="red">
              {errorMessage} <Icon name="alert-triangle" />
            </Text>
          )}
        </FlexContainer>
        {balances.map((balance, index) => (
          <FlexContainer key={index} justifyBetween alignCenter marginBottom="small">
            <Text>{formatFullDate(DateTime.fromISO(balance.date).toJSDate())}</Text>
            <StyledCurrencyInput
              name={`amount-${index}`}
              value={balance.amount}
              onChange={(value) => onUpdateBalance(value, index)}
              maskOptions={CURRENCY_INPUT_MASK_OPTIONS}
              autoComplete="off"
              placeholder="$0.00"
            />
          </FlexContainer>
        ))}
      </Container>
    </ModalCard>
  );
};

export default EditBalanceHistoryModal;
