import { useQuery as useApolloQuery } from '@apollo/client';
import type {
  OperationVariables,
  QueryResult,
  QueryHookOptions,
  TypedDocumentNode,
} from '@apollo/client';
import type { DocumentNode } from 'graphql';

export type Result<TData, TVariables extends OperationVariables> = Omit<
  QueryResult<TData, TVariables>,
  'loading'
> & {
  /**
   * Only true if there is no cached data and a network request is in flight (use this
   * instead of Apollo's default loading state)
   */
  isLoadingInitialData: boolean;

  /**
   * True whenever the network is being accessed, so on first hit and every subsequent
   * hit even if there is cached data (because of cache-and-network policy)
   */
  isNetworkRequestInFlight: boolean;

  /**
   * True if there is any data available, cached or otherwise. We can use this to
   * determine if we should show a loading state or not.
   */
  isDataAvailable: boolean;
};

/**
 * Custom wrapper around Apollo's useQuery that overrides the loading state to behave how we want.
 *
 * Because our default cache policy is cache-and-network, Apollo will set loading=true even if there
 * is cached data. In most cases, we don't want to show a loading state if there is cached data we
 * can display instead, so this wrapper bakes that behavior in by default.
 */
const useQuery = <TData, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): Result<TData, TVariables> => {
  const {
    loading: isNetworkRequestInFlight,
    data,
    ...queryResult
  } = useApolloQuery<TData, TVariables>(query, options);
  const { previousData } = queryResult;
  const isCachedDataAvailable = previousData !== undefined;

  return {
    ...queryResult,
    // Preserve our previous behavior of not triggering loading states if there is cached data
    // More at: https://github.com/apollographql/apollo-client/pull/6566
    data: data ?? previousData,
    isLoadingInitialData: isNetworkRequestInFlight && !isCachedDataAvailable && !data,
    isDataAvailable: !!data,
    isNetworkRequestInFlight,
  };
};

export default useQuery;
