import * as R from 'ramda';
import * as Yup from 'yup';

declare module 'yup' {
  export interface ObjectSchema {
    /** Require at least one property from the list to be non-nil */
    requireOneOf(keys: string[], path: string): ObjectSchema;
  }
}

Yup.addMethod(Yup.object, 'requireOneOf', function customTest(keys: string[], path: string) {
  return this.test(
    'requireOneOf',
    `Must include at least one ${keys.join(', ')}`,
    function testValue(value) {
      const valid = keys.some((key) => !R.isNil(value[key]));
      if (!valid) {
        return this.createError({ path });
      }
      return true;
    },
  );
});
