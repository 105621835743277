import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import Dot from 'components/lib/ui/Dot';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';
import RecurringFilters from 'components/recurring/RecurringFilters';

import useTheme from 'lib/hooks/useTheme';

import type { RecurringTransactionFilter } from 'common/generated/graphQlTypes/globalTypes';

const ACTIVE_FILTER_INDICATOR_DOT_SIZE_PX = 8;

const StyledPopover = styled(Popover)`
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const Divider = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${({ theme }) => theme.color.gray};
  margin: 0 ${({ theme }) => theme.spacing.default};
`;

const StyledIcon = styled(Icon)`
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const FilterButton = styled(DefaultButton).attrs({ size: 'small' })`
  position: relative;
`;

const ActiveFilterIndicator = styled(Dot).attrs({ size: ACTIVE_FILTER_INDICATOR_DOT_SIZE_PX })`
  position: absolute;
  right: 28px;
  top: 6px;
`;

type Props = {
  filters?: RecurringTransactionFilter;
  onChangeFilters: (filters: RecurringTransactionFilter) => void;
  onClickAddRecurring: () => void;
};

const RecurringHeaderControls = ({ filters, onChangeFilters, onClickAddRecurring }: Props) => {
  const theme = useTheme();

  return (
    <FlexContainer alignCenter justifyEnd>
      <OverlayTrigger
        placement="bottom-end"
        closeOnEscape={false}
        overlay={({ close }) => (
          <StyledPopover>
            <RecurringFilters
              filters={filters}
              onChangeFilters={onChangeFilters}
              afterSubmit={close}
            />
          </StyledPopover>
        )}
      >
        {({ toggleOpen, isOpen }) => (
          <FilterButton onClick={toggleOpen} active={isOpen}>
            {RA.isNotNilOrEmpty(filters) && <ActiveFilterIndicator color={theme.color.orange} />}
            <span>View options</span>
            <ButtonIcon name="chevron-down" />
          </FilterButton>
        )}
      </OverlayTrigger>
      <Divider />
      <PrimaryButton onClick={onClickAddRecurring}>
        <StyledIcon name="settings" size={14} />
        Manage recurring
      </PrimaryButton>
    </FlexContainer>
  );
};

export default RecurringHeaderControls;
