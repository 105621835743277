import { useMutation } from '@apollo/client';
import { useState } from 'react';

import getBalanceHistoryApi from 'common/lib/api/balanceHistory';
import usePollQueryUntil from 'common/lib/hooks/usePollQueryUntil';
import api from 'lib/api';

import { UploadSessionStatus } from 'common/constants/statements';
import { MS_PER_SECOND } from 'common/constants/time';

import { gql } from 'common/generated/gql';
import type {
  ParseBalanceHistoryInput,
  UploadBalanceHistorySession,
} from 'common/generated/graphql';

const POLL_INTERVAL_MS = MS_PER_SECOND;

type Options = {
  onParseSuccess?: (session: UploadBalanceHistorySession) => void;
  onParseError?: (session: UploadBalanceHistorySession) => void;
};

type Preview = {
  firstDate: string;
  lastDate: string;
  count: number;
};

// extend UploadBalanceHistorySession and add previews field
type UploadBalanceHistorySessionWithPreviews = UploadBalanceHistorySession & {
  previews: Preview[];
};

const useUploadBalanceHistorySession = ({ onParseSuccess, onParseError }: Options) => {
  const [currentSession, setCurrentSession] = useState<
    UploadBalanceHistorySessionWithPreviews | undefined
  >(undefined);
  const [previews, setPreviews] = useState<Preview[]>([]);

  const balanceHistoryApi = getBalanceHistoryApi(api);

  const { sessionKey } = currentSession ?? {};

  const [startPolling] = usePollQueryUntil(QUERY, {
    variables: {
      sessionKey: sessionKey ?? '',
    },
    pollUntil: (data) => {
      const status = data?.uploadBalanceHistorySession?.status;
      const hasCompleted =
        status === UploadSessionStatus.Completed || status === UploadSessionStatus.Errored;
      return hasCompleted;
    },
    onComplete: (data) => {
      const uploadBalanceHistorySession = data?.uploadBalanceHistorySession;

      if (uploadBalanceHistorySession) {
        const { status } = uploadBalanceHistorySession;

        const session = {
          ...currentSession!,
          ...uploadBalanceHistorySession,
        };

        if (status === UploadSessionStatus.Completed) {
          onParseSuccess?.(session);
        } else if (status === UploadSessionStatus.Errored) {
          onParseError?.(session);
        }

        setCurrentSession(session);
      }
    },
    skipWhenNotPolling: true,
    pollIntervalMs: POLL_INTERVAL_MS,
  });

  const [startParsingFiles] = useMutation(MUTATION);

  const startSession = async (formData: FormData) => {
    const response = await balanceHistoryApi.uploadBalanceHistory(formData);
    const { session_key, previews } = response;

    const session = {
      sessionKey: session_key,
      previews,
      status: UploadSessionStatus.Created,
    };

    setCurrentSession(session as UploadBalanceHistorySessionWithPreviews);
    setPreviews(previews);

    return session;
  };

  const startParsing = async (input: Partial<ParseBalanceHistoryInput>) => {
    await startParsingFiles({
      variables: {
        input: {
          sessionKey: sessionKey ?? '',
          accountFilesMapping: undefined,
          ...input,
        },
      },
    });
    startPolling();
  };

  return { currentSession, previews, startSession, startParsing };
};

export const UPLOAD_BALANCE_HISTORY_SESSION_FRAGMENT = gql(`
  fragment UploadBalanceHistorySessionFields on UploadBalanceHistorySession {
    sessionKey
    status
  }
`);

const QUERY = gql(`
  query Web_GetUploadBalanceHistorySession($sessionKey: String!) {
    uploadBalanceHistorySession(sessionKey: $sessionKey) {
      ...UploadBalanceHistorySessionFields
    }
  }
`);

const MUTATION = gql(`
  mutation Web_ParseUploadBalanceHistorySession($input: ParseBalanceHistoryInput!) {
    parseBalanceHistory(input: $input) {
      uploadBalanceHistorySession {
        ...UploadBalanceHistorySessionFields
      }
    }
  }
`);

export default useUploadBalanceHistorySession;
