export const HOME_CATEGORY = 'home';

export enum AdviceQuestionnaireName {
  General = 'general',
  Objectives = 'objectives',
}

export enum AdviceItemType {
  Essential = 'essential',
  Objective = 'objective',
}
