import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Markdown from 'components/lib/ui/Markdown';
import Text from 'components/lib/ui/Text';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';

const ROW_ICON_SIZE_PX = 24;

const TopIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.orange};
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const Container = styled(FlexContainer).attrs({ column: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const RowContainer = styled(FlexContainer)`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
  padding-right: ${({ theme }) => theme.spacing.large};
`;

const IconContainer = styled(FlexContainer).attrs({ center: true })`
  width: 42px;
  height: 42px;
  background-color: ${({ theme }) => theme.color.orangeBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
  color: ${({ theme }) => theme.color.orange};
`;

const TextContainer = styled(FlexContainer).attrs({ column: true })`
  flex: 1;
  margin-left: ${({ theme }) => theme.spacing.xlarge};
`;

const RowTitle = styled(Text).attrs({ weight: 'medium' })``;

const RowSubtitle = styled(Markdown)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

const AsyncPrimaryButton = styled(AsyncButton)`
  margin-top: ${({ theme }) => theme.spacing.default};
  ${primaryButtonMixin};
`;

const Row = ({
  icon,
  title,
  description,
}: {
  icon: string;
  title: React.ReactNode;
  description: string;
}) => (
  <RowContainer>
    <IconContainer>
      <Icon name={icon} color="orange" size={ROW_ICON_SIZE_PX} />
    </IconContainer>
    <TextContainer>
      <RowTitle>{title}</RowTitle>
      <RowSubtitle source={description} />
    </TextContainer>
  </RowContainer>
);

type Props = {
  icon: string;
  title: React.ReactNode;
  rows: {
    icon: string;
    title: React.ReactNode;
    description: string;
  }[];
  button: {
    title: React.ReactNode;
    onClick: () => void;
    pending?: boolean;
  };
  footer?: React.ReactNode;
};

const ProductLaunchPrompt = ({ icon, title, rows, button, footer }: Props) => (
  <Container>
    <TopIcon name={icon} size={24} />
    <Title>{title}</Title>

    {rows.map(({ icon, title, description }, i) => (
      <Row key={i} icon={icon} title={title} description={description} />
    ))}

    <AsyncPrimaryButton size="large" pending={button.pending} onClick={button.onClick}>
      {button.title}
    </AsyncPrimaryButton>
    {footer}
  </Container>
);

export default ProductLaunchPrompt;
