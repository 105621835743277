import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import React, { useState, useCallback } from 'react';

import DeleteOrDisableCategoryConfirmation from 'components/categories/DeleteOrDisableCategoryConfirmation';
import Modal from 'components/lib/ui/Modal';

import { updateCachedCategory } from 'common/lib/categories/graphQl';
import { PLAN_DATA_QUERY_NAME } from 'common/lib/graphQl/planning';
import useEventCallback from 'common/lib/hooks/useEventCallback';
import useQuery from 'common/lib/hooks/useQuery';

import { CACHE_KEYS } from 'common/constants/cache';

import { gql } from 'common/generated/gql';
import type { PayloadErrorFieldsFragment } from 'common/generated/graphql';
import type { ExtractResponseFromDocument } from 'common/types/graphql';

type Options = {
  onError?: (errorMessage: Maybe<string>) => void;
  onDone?: (category: ExtractResponseFromDocument<typeof CATEGORY_INFO_QUERY>['category']) => void;
};

const useDeleteCategory = (options?: Options) => {
  const { onError, onDone } = options ?? {};

  const [confirmingDeleteCategoryId, setConfirmingDeleteCategoryId] = useState<string>();
  const { data: categoryInfo } = useQuery(CATEGORY_INFO_QUERY, {
    skip: !confirmingDeleteCategoryId,
    variables: { id: String(confirmingDeleteCategoryId) },
    fetchPolicy: 'cache-only', // we should already have the category in the cache, so avoid an extra network call
  });

  const [disableOrDeleteCategory, { loading: loadingDisableOrDeleteCategory }] = useMutation(
    DELETE_CATEGORY,
    {
      update: (cache) => {
        cache.evict({ fieldName: CACHE_KEYS.CATEGORIES });
        cache.evict({ fieldName: CACHE_KEYS.CATEGORY_GROUPS });
      },
      refetchQueries: [PLAN_DATA_QUERY_NAME],
    },
  );

  const deleteCategoryWithConfirmation = (categoryId: string) => {
    setConfirmingDeleteCategoryId(categoryId);
  };

  const onConfirmDelete = useEventCallback(
    async (categoryId: string, moveToCategoryId?: string) => {
      if (!confirmingDeleteCategoryId) {
        return;
      }
      const { data } = await disableOrDeleteCategory({
        variables: { id: categoryId, moveToCategoryId },
        update: updateCachedCategory(categoryId, { isDisabled: () => true }),
      });
      const errors: PayloadErrorFieldsFragment | undefined = R.path(
        ['deleteCategory', 'errors'],
        data,
      );

      if (errors) {
        onError?.(errors.message);
      } else {
        onDone?.(categoryInfo?.category);
      }

      setConfirmingDeleteCategoryId(undefined);
    },
  );

  const DeleteCategoryConfirmationModal = useCallback(
    () =>
      confirmingDeleteCategoryId ? (
        <Modal onClose={() => setConfirmingDeleteCategoryId(undefined)}>
          {({ close }) => (
            <DeleteOrDisableCategoryConfirmation
              categoryId={confirmingDeleteCategoryId}
              onCancel={close}
              disableOrDeleteCategory={onConfirmDelete}
            />
          )}
        </Modal>
      ) : null,
    [confirmingDeleteCategoryId, onConfirmDelete],
  );

  return [
    DeleteCategoryConfirmationModal,
    { deleteCategoryWithConfirmation, confirmingDeleteCategoryId, loadingDisableOrDeleteCategory },
  ] as const;
};

const CATEGORY_INFO_QUERY = gql(/* GraphQL */ `
  query Web_GetCategoryForDeletion($id: UUID!) {
    category(id: $id) {
      id
      name
      icon
      isSystemCategory
    }
  }
`);

const DELETE_CATEGORY = gql(/* GraphQL */ `
  mutation Web_DeleteCategory($id: UUID!, $moveToCategoryId: UUID) {
    deleteCategory(id: $id, moveToCategoryId: $moveToCategoryId) {
      errors {
        ...PayloadErrorFields
      }
      deleted
    }
  }
`);

export default useDeleteCategory;
