import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import Toast from 'components/lib/ui/Toast';
import ManualLink from 'components/lib/ui/link/ManualLink';
import AnimatedPopInOverlay from 'components/lib/ui/popover/AnimatedPopInOverlay';
import OverlayTrigger from 'components/lib/ui/popover/OverlayTrigger';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import getUserApi from 'common/lib/api/user';
import api from 'lib/api';
import { useQueryParam } from 'lib/hooks';

import { ONBOARDING } from 'common/constants/copy';
import * as COPY from 'constants/copy';

type ResendOverlayContext = {
  success: boolean;
};

const StyledOnboardingTitle = styled(OnboardingTitle)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: 106px;
  }
`;

type Props = {
  next: (params: { queryParams: { email: string; code: string } }) => void;
};

const OnboardingVerifyEmail = ({ next }: Props) => {
  const email = useQueryParam('email') || '';

  const onSubmit = async ({ code }: { code: string }) => {
    await getUserApi(api).verifyEmail({ code, email });
    next({ queryParams: { email, code } });
  };

  return (
    <>
      <StyledOnboardingTitle>{ONBOARDING.VERIFY_EMAIL.TITLE}</StyledOnboardingTitle>
      <OnboardingSubtitle>{ONBOARDING.VERIFY_EMAIL.SUBTITLE(email)}</OnboardingSubtitle>
      <OnboardingFormContainer>
        <Form onSubmit={onSubmit}>
          <TextField
            name="code"
            required
            placeholder="123456"
            minLength={6}
            autoFocus
            autoFocusOnMobile={false}
          />
          <FlexContainer center marginTop="large" marginBottom="large">
            <Text size="small" align="center">
              Don&apos;t see a code?{' '}
              <OverlayTrigger<ResendOverlayContext>
                overlay={({ close, triggerContext }) => (
                  <AnimatedPopInOverlay>
                    <Toast
                      onClickClose={close}
                      headerColor={triggerContext?.success ? 'green' : 'red'}
                    >
                      <FlexContainer marginRight="large">
                        <Text size="small">
                          {triggerContext?.success
                            ? ONBOARDING.VERIFY_EMAIL.RESENT_EMAIL
                            : COPY.DEFAULT_ERROR_MESSAGE}
                        </Text>
                      </FlexContainer>
                    </Toast>
                  </AnimatedPopInOverlay>
                )}
                placement="right"
                showArrow
              >
                {({ open }) => (
                  <ManualLink
                    onClick={async () => {
                      try {
                        await getUserApi(api).createVerification(email);
                        open({ success: true });
                      } catch {
                        open({ success: false });
                      }
                    }}
                  >
                    Resend to email
                  </ManualLink>
                )}
              </OverlayTrigger>
            </Text>
          </FlexContainer>
          <FormSubmitButton size="medium" variant="primary">
            {ONBOARDING.VERIFY_EMAIL.BUTTON}
          </FormSubmitButton>
        </Form>
      </OnboardingFormContainer>
    </>
  );
};

export default OnboardingVerifyEmail;
