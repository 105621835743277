import { orderBy } from 'lodash';
import * as R from 'ramda';

import { CategoryGroupType } from 'common/generated/graphql';
import type TransactionSection from 'common/types/TransactionSection';

type GroupableTransactionType = {
  id: string;
  amount?: number;
  category?: TransactionCategory;
  hideFromReports?: boolean;
};

type TransactionCategory = {
  group: {
    type: CategoryGroupType;
  };
};

// TODO: write tests for this
type TransactionGroupingFunction<TransactionType> = (transaction: TransactionType) => string;

const groupTransactionsByDate = <TransactionType extends GroupableTransactionType>({
  transactions,
  groupingFn,
  totalCount,
  isDescending = true,
  accumulateTransfersAmounts = true,
}: {
  transactions: TransactionType[];
  groupingFn: TransactionGroupingFunction<TransactionType>;
  totalCount?: number;
  isDescending?: boolean;
  accumulateTransfersAmounts?: boolean;
}) => {
  const uniqueTransactions = R.uniqBy(R.prop('id'), transactions);
  const grouped = R.groupBy(groupingFn, uniqueTransactions);
  const mapped = R.mapObjIndexed(
    (data, key): TransactionSection<TransactionType> => ({
      data,
      key,
      date: key,
      amount: getSectionAmount(data, accumulateTransfersAmounts),
    }),
    grouped,
  );
  const values = R.values(mapped);
  const sections = orderBy(values, 'date', isDescending ? 'desc' : 'asc');

  if (totalCount && transactions.length > 0 && transactions.length < totalCount) {
    const lastSection = sections[sections.length - 1];
    lastSection.amount = undefined;
  }

  return sections;
};

const getSectionAmount = <TransactionType extends GroupableTransactionType>(
  data: TransactionType[],
  accumulateTransfersAmounts?: boolean,
) =>
  R.reduce(
    (acc, trx) => {
      const isHidden = trx.hideFromReports;
      const isTransfer = trx.category?.group?.type === CategoryGroupType.TRANSFER;
      const isAccumulatableTransfer = isTransfer && !accumulateTransfersAmounts;

      const amountContribution = isHidden || isAccumulatableTransfer ? 0 : trx.amount;
      const sum = acc + (amountContribution ?? 0);

      // Check for floating point errors
      return Math.abs(sum) <= Number.EPSILON ? 0 : sum;
    },
    0,
    data,
  );

export default groupTransactionsByDate;
