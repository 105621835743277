import { DateTime } from 'luxon';
import * as R from 'ramda';

import type {
  XAxisReferenceLineProps,
  BarColorFunctionProps,
} from 'components/lib/charts/StackedBarChart';

import { sort } from 'common/utils/Array';

import type { Timeframe } from 'common/constants/timeframes';

import type { TimeframeGroup } from 'common/types/aggregates';

export type ChartDataPoint = {
  startDate: string;
  income: number;
  expense: number;
  savingsRate: number;
  savings?: number;
  currentMonthSavings?: number;
};

export type FilteredChartDataPoint = {
  startDate: string;
  sum: number;
};

export const chartDataPointFormatter = (
  timeframe: Timeframe,
  data: TimeframeGroup,
): ChartDataPoint => {
  const startDate = R.path<string>([timeframe], data.groupBy);
  if (!startDate) {
    throw new Error('Missing start date');
  }

  const { sumExpense, sumIncome, savings, savingsRate } = data.summary;

  const today = DateTime.local();
  const date = DateTime.fromISO(startDate);
  const isSameTimeframe = today.hasSame(date, timeframe);
  const isPreviousTimeframe = today.minus({ [timeframe]: 1 }).hasSame(date, timeframe);

  // When we should show the dashed line (indicator that the month hasn't finished yet)
  const shouldAddCurrentMonthSavings = isSameTimeframe || isPreviousTimeframe;
  // When we should show the normal solid line for the month before the current one + past months
  const shouldAddSavings = !isSameTimeframe;

  return {
    startDate,
    income: sumIncome ?? 0,
    expense: sumExpense ?? 0,
    savingsRate: savingsRate ?? 0,
    ...(shouldAddCurrentMonthSavings ? { currentMonthSavings: savings ?? 0 } : {}),
    ...(shouldAddSavings ? { savings: savings ?? 0 } : {}),
  };
};

export const findActiveBarIndex = (chartData: { startDate: ISODate }[], activeDate: string) =>
  chartData.findIndex(({ startDate }) => activeDate === startDate);

export const cashFlowChartReferenceLineAdapter = <TData extends { startDate: ISODate }>(
  data: TData[],
): XAxisReferenceLineProps[] => {
  const sortedStartDates = R.pipe(R.map(R.prop('startDate')), sort)(data);
  const parsedStartDates = sortedStartDates.map((isoString) => DateTime.fromISO(isoString));
  const datePairs = R.aperture(2, parsedStartDates);
  return datePairs.reduce(
    (dividers, [prevDate, nextDate]) =>
      !prevDate.hasSame(nextDate, 'year')
        ? dividers.concat({
            x: nextDate.toISODate(),
            leftLabelText: `← ${prevDate.toFormat('yyyy')}`,
            rightLabelText: `${nextDate.toFormat('yyyy')} →`,
          })
        : dividers,
    [] as XAxisReferenceLineProps[],
  );
};

export const getBarColor =
  (activeColor: string, inactiveColor: string) =>
  ({ index, hoveredBarIndex, activeBarIndex }: BarColorFunctionProps) => {
    if (activeBarIndex !== index) {
      return hoveredBarIndex === index ? activeColor : inactiveColor;
    }
    return activeColor;
  };
