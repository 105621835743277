import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import { GET_USER_OPTED_IN } from 'components/lib/ui/SpendingInsightsBanner';

import useHasAccounts from 'common/lib/hooks/accounts/useHasAccounts';
import useCreateMessageThread from 'common/lib/hooks/assistant/useCreateMessageThread';
import useQuery from 'common/lib/hooks/useQuery';

import routes from 'constants/routes';

import type { Web_GetUserOptedInQuery } from 'common/generated/graphql';

const LoadingContainer = styled(FlexContainer).attrs({
  full: true,
  alignCenter: true,
  justifyCenter: true,
})`
  flex: 1;
`;

const SpendingInsights = () => {
  const history = useHistory();

  const DEFAULT_AGENT_TYPE = 'spending_insights';

  const { data, isLoadingInitialData: loading } =
    useQuery<Web_GetUserOptedInQuery>(GET_USER_OPTED_IN);
  const { hasAccounts, isLoadingInitialData: loadingHasAccounts } = useHasAccounts();

  const { householdPreferences, me } = data ?? {};

  const { aiAssistantEnabled } = householdPreferences ?? {};

  const { profile } = me ?? {};

  const { aiAssistantOptedInAt } = profile ?? {};

  const needsToOptIn = aiAssistantOptedInAt === null;

  const [createMessageThread] = useCreateMessageThread({
    variables: {
      agentType: DEFAULT_AGENT_TYPE,
    },
  });

  useEffect(() => {
    // if the user has opted in already it'll create the spending insights thread before redirecting them
    if (!loading && !loadingHasAccounts) {
      if (!hasAccounts) {
        // redirects the user to the accounts page if they don't have an account yet
        history.push(routes.dashboard());
      } else if (aiAssistantEnabled && !needsToOptIn) {
        const createThread = async () => {
          const { data } = await createMessageThread();
          const threadId = data?.createMessageThread?.messageThread?.id;

          // redirects to the assistant page with the threadId
          threadId && history.push(routes.assistant({ threadId }));
        };

        createThread();
      } else {
        // redirect the user to the assistant page so they can opt in
        history.push(
          routes.assistant({
            queryParams: { agentType: DEFAULT_AGENT_TYPE },
          }),
        );
      }
    }
  }, [loading, loadingHasAccounts, aiAssistantEnabled, createMessageThread, history, needsToOptIn]);

  return (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  );
};

export default SpendingInsights;
