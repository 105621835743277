import React from 'react';

import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import IconButton from 'components/lib/ui/button/IconButton';
import OverlayTrigger from 'components/lib/ui/popover/OverlayTrigger';

type Props = {
  onDeleteHolding?: () => void;
};

const HoldingDrawerMenu = ({ onDeleteHolding }: Props) => (
  <>
    <OverlayTrigger
      placement="bottom-start"
      overlay={
        <DropdownMenu>
          <DropdownMenuItem onClick={onDeleteHolding}>Delete</DropdownMenuItem>
        </DropdownMenu>
      }
    >
      {({ toggleOpen, isOpen }) => (
        <IconButton icon="more-horizontal" size="medium" onClick={toggleOpen} active={isOpen} />
      )}
    </OverlayTrigger>
  </>
);

export default HoldingDrawerMenu;
