import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import {
  defaultDropAnimationSideEffects,
  DragOverlay,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import React, { useState } from 'react';
import type { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

const PLACEHOLDER_OPACITY = '0.5';

type Props = PropsWithChildren<{
  onDragStart?: (event: DragStartEvent) => void;
  onDragEnd: (event: DragEndEvent) => void;
  /**
   * Presentational component that renders when dragging an item.
   *
   * It is recommended that this component not use useDraggable to avoid unexpected behavior.
   * See: https://docs.dndkit.com/api-documentation/draggable/drag-overlay#usage
   */
  dragOverlay: (activeId: string | number) => React.ReactNode;
}>;

const DndKitContext = ({ children, onDragStart, onDragEnd, dragOverlay }: Props) => {
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
  const [activeId, setActiveId] = useState<string | number | null>(null);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
    onDragStart?.(event);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setActiveId(null);
    onDragEnd(event);
  };

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      {children}
      {dragOverlay &&
        activeId &&
        createPortal(
          <DragOverlay
            dropAnimation={{
              sideEffects: defaultDropAnimationSideEffects({
                styles: {
                  active: {
                    opacity: PLACEHOLDER_OPACITY,
                  },
                },
              }),
            }}
          >
            {dragOverlay(activeId)}
          </DragOverlay>,
          document.body,
        )}
    </DndContext>
  );
};

export default DndKitContext;
