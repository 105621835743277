import { NavLink as RouterNavLink } from 'react-router-dom';
import styled from 'styled-components';

export const ACTIVE_CLASS_NAME = 'nav-item-active';

// Link with custom styles based on route match.
const NavLink = styled(RouterNavLink).attrs({ activeClassName: ACTIVE_CLASS_NAME })<{
  shouldHighlightWhenActive?: boolean;
}>`
  text-decoration: none;
  color: inherit;
  ${({ shouldHighlightWhenActive = true }) =>
    shouldHighlightWhenActive &&
    `&.${ACTIVE_CLASS_NAME} {
      background-color: inherit;
      color: inherit;
  }`}

  :hover {
    color: inherit;
  }
`;

export default NavLink;
