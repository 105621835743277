import React, { useState, useCallback } from 'react';

import EditBalanceHistoryModal from 'components/accounts/EditBalanceHistoryModal';

import useModal from 'lib/hooks/useModal';

type DateBalanceData = {
  date: string;
  balance: number;
};

const useEditBalancesModal = ({
  accountId,
  accountName,
}: {
  accountId: string;
  accountName: string;
}) => {
  const [date, setDate] = useState<string | undefined>(undefined);
  const [initialBalance, setInitialBalance] = useState<number | undefined>(undefined);

  const [Modal, { open: openModal, close, isOpen }] = useModal();

  const EditBalancesModal = useCallback(
    () => (
      <Modal key="edit-balances-modal">
        <EditBalanceHistoryModal
          accountId={accountId}
          accountName={accountName}
          date={date}
          initialBalance={initialBalance}
        />
      </Modal>
    ),
    [Modal, accountId, accountName, date, initialBalance],
  );

  const open = useCallback(
    (data?: DateBalanceData) => {
      if (data) {
        setDate(data.date);
        setInitialBalance(data.balance);
      }
      openModal();
    },
    [setDate, setInitialBalance, openModal],
  );

  return [EditBalancesModal, { open, close, isOpen }] as const;
};

export default useEditBalancesModal;
