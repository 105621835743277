export enum HoldingGroupByValues {
  Type = 'type',
  Institution = 'institution',
  Account = 'account',
  None = 'none',
}

export const holdingsGroupByOptions = [
  {
    label: 'Group by type',
    value: HoldingGroupByValues.Type,
  },
  {
    label: 'Group by institution',
    value: HoldingGroupByValues.Institution,
  },
  {
    label: 'Group by account',
    value: HoldingGroupByValues.Account,
  },
  {
    label: 'No grouping',
    value: HoldingGroupByValues.None,
  },
];
