import React from 'react';
import styled from 'styled-components';

import Tooltip from 'components/lib/ui/Tooltip';
import AuthButton from 'components/lib/ui/button/AuthButton';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';

import { capitalize } from 'common/utils/String';

import type { AuthProvider } from 'common/types/auth';

type Props = {
  provider: string;
  providerLabel: string;
  onClick: () => void;
  disabled?: boolean;
  disableTooltip?: boolean;
};

const StyledAuthButton = styled(AuthButton)`
  padding: ${({ theme }) => theme.spacing.small};
  height: 100%;
`;

const ProviderAuthButton = ({
  provider,
  providerLabel,
  onClick,
  disabled,
  disableTooltip,
}: Props) => (
  <Tooltip
    content={`Create a password and update your email to disconnect from ${capitalize(
      provider,
    )} login.`}
    place="top"
    opacity={1}
    disable={!!disableTooltip}
  >
    <div>
      <StyledAuthButton
        disabled={disabled}
        provider={provider as AuthProvider}
        text={`${providerLabel} is connected`}
        onClick={onClick}
        right={<ButtonIcon name="x" />}
      />
    </div>
  </Tooltip>
);

export default ProviderAuthButton;
