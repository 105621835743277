import useDebounce from 'common/lib/hooks/useDebounce';
import useQuery from 'common/lib/hooks/useQuery';
import useSupportedDataProviders from 'lib/hooks/institutions/useSupportedDataProviders';

import { gql } from 'common/generated/gql';

const SEARCH_DEBOUNCE_MS = 300;

const useSearchInstitutions = (search: string) => {
  const debouncedSearch = useDebounce(search, SEARCH_DEBOUNCE_MS);

  const dataProviders = useSupportedDataProviders();

  const { data: searchData, isNetworkRequestInFlight } = useQuery(SEARCH_INSTITUTIONS_QUERY, {
    variables: { search: debouncedSearch, dataProviders },
    skip: !debouncedSearch,
  });
  const { allInstitutions: searchInstitutions = [] } = searchData ?? {};

  return { debouncedSearch, searchInstitutions, isNetworkRequestInFlight };
};

const SEARCH_INSTITUTIONS_QUERY = gql(`
  query Web_SearchInstitutionsQuery($search: String, $dataProviders: [String]!) {
    allInstitutions(search: $search, dataProviders: $dataProviders) {
      id
      ...InstitutionRowFields
    }
  }
`);

export default useSearchInstitutions;
