import { useMutation, gql } from '@apollo/client';
import { DateTime } from 'luxon';
import { useCallback } from 'react';

import AdviceItemRow from 'components/advice/AdviceItemRow';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import type {
  Web_MarkAdviceTaskComplete,
  Web_MarkAdviceTaskCompleteVariables,
} from 'common/generated/graphQlTypes/Web_MarkAdviceTaskComplete';
import type {
  Web_MarkAdviceTaskIncomplete,
  Web_MarkAdviceTaskIncompleteVariables,
} from 'common/generated/graphQlTypes/Web_MarkAdviceTaskIncomplete';

type CompletableAdviceTask = {
  id: string;
  completedAt: string | null | undefined;
};

const useToggleCompleteAdviceTask = () => {
  const [markComplete] = useMutation<
    Web_MarkAdviceTaskComplete,
    Web_MarkAdviceTaskCompleteVariables
  >(MARK_COMPLETE_MUTATION);
  const [markIncomplete] = useMutation<
    Web_MarkAdviceTaskIncomplete,
    Web_MarkAdviceTaskIncompleteVariables
  >(MARK_INCOMPLETE_MUTATION);

  const toggle = useCallback(
    <T extends CompletableAdviceTask>({ id, completedAt }: T) => {
      const input = {
        adviceItemTaskId: id,
      };
      return completedAt
        ? markIncomplete({
            variables: { input },
            optimisticResponse: {
              // @ts-ignore - we don't want to include all of the adviceItem fields
              markAdviceItemTaskIncomplete: {
                __typename: 'MarkAdviceItemTaskIncomplete',
                adviceItemTask: {
                  __typename: 'AdviceItemTask',
                  id,
                  completedAt: null,
                },
                errors: null,
              },
            },
          })
        : markComplete({
            variables: { input },
            optimisticResponse: {
              // @ts-ignore - we don't want to include all of the adviceItem fields
              markAdviceItemTaskComplete: {
                __typename: 'MarkAdviceItemTaskComplete',
                adviceItemTask: {
                  __typename: 'AdviceItemTask',
                  id,
                  completedAt: DateTime.local().toISO(),
                },
                errors: null,
              },
            },
          });
    },
    [markComplete, markIncomplete],
  );

  return toggle;
};

const MARK_COMPLETE_MUTATION = gql`
  mutation Web_MarkAdviceTaskComplete($input: MarkAdviceItemTaskCompleteInput!) {
    markAdviceItemTaskComplete(input: $input) {
      adviceItemTask {
        id
        completedAt
      }
      adviceItem {
        id
        ...AdviceItemRowFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
  ${AdviceItemRow.fragments.AdviceItemRowFields}
`;

const MARK_INCOMPLETE_MUTATION = gql`
  mutation Web_MarkAdviceTaskIncomplete($input: MarkAdviceItemTaskIncompleteInput!) {
    markAdviceItemTaskIncomplete(input: $input) {
      adviceItemTask {
        id
        completedAt
      }
      adviceItem {
        id
        ...AdviceItemRowFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
  ${AdviceItemRow.fragments.AdviceItemRowFields}
`;

export default useToggleCompleteAdviceTask;
