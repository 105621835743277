import storage from 'redux-persist/lib/storage';
import { createReducer } from 'typesafe-actions';

import { GroupByValues } from 'common/lib/recurring';
import { setRecurringFilters } from 'state/recurring/actions';

export const recurringPersistConfig = {
  key: 'recurring',
  storage,
  whitelist: ['filters'],
};

export const groupByOptions = [
  {
    label: 'Category',
    value: GroupByValues.Category,
  },
  {
    label: 'Frequency',
    value: GroupByValues.Frequency,
  },
];

export type RecurringFilters = {
  accounts?: string[];
  groupBy?: GroupByValues;
};

export type RecurringState = {
  filters: RecurringFilters;
};

const INITIAL_STATE: RecurringState = {
  filters: {},
};

const recurring = createReducer<RecurringState>(INITIAL_STATE).handleAction(
  setRecurringFilters,
  (state, { payload: filters }) => ({
    ...state,
    filters,
  }),
);

export default recurring;
