import React from 'react';

import Card from 'components/lib/ui/Card';
import Empty, { Theme as EmptyTheme } from 'components/lib/ui/Empty';

import * as COPY from 'constants/copy';

type Props = {
  onClickPrimary: () => void;
};

const RecurringEmptyPeriod = ({ onClickPrimary }: Props) => (
  <Card>
    <Empty
      emptyTheme={EmptyTheme.NEW}
      title={COPY.RECURRING.EMPTY_PERIOD.TITLE}
      subtitle={COPY.RECURRING.EMPTY_PERIOD.SUBTITLE}
      button={{
        title: 'Manage recurring',
        onClick: onClickPrimary,
        isPrimary: true,
      }}
    />
  </Card>
);

export default RecurringEmptyPeriod;
