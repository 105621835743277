import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import SetupHeader from 'components/lib/ui/SetupHeader';

import { setCreatedByType } from 'actions';

import routes from 'constants/routes';

type Props = {
  progress: number;
  onClickBackButton?: () => void;
};

const GoalsSetupHeader = ({ progress, onClickBackButton }: Props) => {
  const { goBack, push } = useHistory();
  const dispatch = useDispatch();

  const onClickCancel = () => {
    push(routes.goalsV2());
    dispatch(setCreatedByType({}));
  };

  return (
    <SetupHeader
      progress={progress}
      onClickBack={onClickBackButton ?? goBack}
      onClickCancel={onClickCancel}
    />
  );
};

export default GoalsSetupHeader;
