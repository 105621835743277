import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';

import getStringEnvVar from 'lib/getStringEnvVar';

const STRIPE_PUBLIC_KEY = getStringEnvVar('REACT_APP_STRIPE_PUBLIC_KEY');
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

type Props = {
  children: React.ReactNode;
};

/**
 * Configure Stripe elements. This must be placed in the tree above any stripe hooks / elements.
 */
const StripeProvider = ({ children }: Props) => (
  <Elements stripe={stripePromise}>{children}</Elements>
);

export default StripeProvider;
