import { useQuery } from '@apollo/client';
import React from 'react';

import type { AddManualAccountFlowData } from 'components/accounts/AddManualAccountFlow';
import AddManualAccountModalTypeList from 'components/accounts/AddManualAccountModalTypeList';
import ModalCard from 'components/lib/ui/ModalCard';

import { GET_ACCOUNT_TYPE_OPTIONS } from 'common/lib/graphQl/accounts';

import type { GetAccountTypeOptions } from 'common/generated/graphQlTypes/GetAccountTypeOptions';

type Props = AddManualAccountFlowData & {
  next: (data: AddManualAccountFlowData) => void;
  goBack?: () => void;
};

const AddManualAccountTypeSelectionModal = ({ type, subtype, next, goBack }: Props) => {
  const { data, loading } = useQuery<GetAccountTypeOptions>(GET_ACCOUNT_TYPE_OPTIONS);

  if (!data || loading) {
    return <></>;
  }

  const { accountTypeOptions } = data;

  const handleSelectAccountType = (type: string, subtype: string | null) => {
    next({ type, subtype });
  };

  return (
    <ModalCard title="Add Manual Account" onClickBackButton={goBack}>
      <AddManualAccountModalTypeList
        accountTypeOptions={accountTypeOptions}
        selectAccountType={handleSelectAccountType}
      />
    </ModalCard>
  );
};

export default AddManualAccountTypeSelectionModal;
