import React from 'react';
import styled from 'styled-components';

import type { Props as BaseFormProps } from 'common/components/form/BaseForm';
import BaseForm from 'common/components/form/BaseForm';
import FormContext from 'common/components/form/FormContext';
import Banner from 'components/lib/ui/Banner';

import noop from 'common/utils/noop';
import defaultOnError from 'lib/errors/onError';

type Props<ValuesT, MutationT> = {
  children: React.ReactNode;
  className?: string;
  /** onSubmit will only be called if the form is not currently submitting */
  preventSubmitWhileSubmitting?: boolean;
  onError?: BaseFormProps<ValuesT, MutationT>['onError'];
} & Omit<BaseFormProps<ValuesT, MutationT>, 'focusField' | 'onUnhandledError'>;

export const ErrorsContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.default};
`;

const ErrorBanner = styled(Banner).attrs({ type: 'error' })`
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const Form = <ValuesT extends Record<string, any>, MutationT = unknown>({
  children,
  className,
  preventSubmitWhileSubmitting = false,
  onError = noop,
  ...formProps
}: Props<ValuesT, MutationT>) => (
  // @ts-ignore mismatch of types between the mutation and the form
  <BaseForm
    {...formProps}
    onUnhandledError={defaultOnError}
    onError={onError}
    focusField={(fieldName) => document.getElementById(fieldName)?.focus()}
  >
    <FormContext.Consumer>
      {({ handleSubmit, generalApiErrors, isSubmitting }) => (
        <>
          {!!generalApiErrors.length && (
            <ErrorsContainer>
              {generalApiErrors.map((error) => (
                <ErrorBanner key={error}>{error}</ErrorBanner>
              ))}
            </ErrorsContainer>
          )}
          <form
            className={className}
            onSubmit={
              isSubmitting && preventSubmitWhileSubmitting
                ? (e) => e.preventDefault()
                : handleSubmit
            }
          >
            {children}
          </form>
        </>
      )}
    </FormContext.Consumer>
  </BaseForm>
);

export default Form;
