import queryString from 'query-string';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// Hooks to parse query params (i.e. ?x=y&a=b).
// Note that if a query param is not set at all, its value is undefined.
// If the query param is there but has no value (ie ?foo), its value is null.
// Currently the value returned will be a string, number, or boolean, depending
// on the value and how query-string parses it.

// Return a query param as a single value (string, number, boolean).
export const useQueryParam = (key: string) => {
  const parsedValue = queryString.parse(useLocation().search);
  const keyValue = parsedValue[key];
  if (keyValue === undefined) {
    return undefined;
  }
  if (keyValue === null) {
    return null;
  }
  return makeArray(keyValue)[0];
};

export type UpdateQueryParamOptions = {
  /** Add an entry to the browser history so the back button will go back to the previous query params.
   * Default to false, which will use history.replace()
   */
  pushHistory?: boolean;
};

// Returns a function to set the value of the query param
export const useUpdateQueryParam = (key: string) => {
  const history = useHistory();

  const setValue = useCallback(
    (value: string | undefined | null, options?: UpdateQueryParamOptions) => {
      const queryParams = queryString.parse(window.location.search);
      const url = queryString.stringifyUrl({
        url: window.location.pathname,
        query: {
          ...queryParams,
          [key]: value,
        },
      });
      if (options?.pushHistory) {
        history.push(url);
      } else {
        history.replace(url);
      }
    },
    [key, history],
  );

  return setValue;
};

// Combine useQueryParam and useUpdateQueryParam into one hook
export const useUpdatableQueryParam = (key: string) => {
  const value = useQueryParam(key);
  const setValue = useUpdateQueryParam(key);
  return [value, setValue] as const;
};

// Return a query param as an array value (string, number, boolean).
// e.g. '?foo=1&foo=2&foo=3' => [1, 2, 3]
export const useArrayQueryParam = (key: string) => {
  const parsedValue = queryString.parse(useLocation().search);
  const keyValue = parsedValue[key];
  if (keyValue === undefined) {
    return undefined;
  }
  if (keyValue === null) {
    return null;
  }
  return makeArray(keyValue);
};

const cleanParams = (params: Record<string, unknown>) =>
  R.mapObjIndexed(
    (value: unknown) =>
      RA.isNonEmptyString(value) || RA.isNonEmptyArray(value) ? value : undefined,
    params,
  );

export const useUpdatableQueryParams = (initialParams?: Record<string, unknown>) => {
  const history = useHistory();

  const currentParams = queryString.parse(useLocation().search, { arrayFormat: 'comma' });

  const updateParams = useCallback(
    (newParams: typeof initialParams | null) => {
      const url = queryString.stringifyUrl(
        {
          url: window.location.pathname,
          // @ts-ignore - queryString.stringifyUrl doesn't have a type for query
          query:
            newParams !== null
              ? {
                  ...currentParams,
                  ...cleanParams(newParams ?? {}),
                }
              : {},
        },
        { arrayFormat: 'comma' },
      );

      history.replace(url);
    },
    [currentParams, history],
  );

  return [currentParams, updateParams] as const;
};

function makeArray<T>(value: T | Array<T>): Array<T> {
  if (Array.isArray(value)) {
    return value;
  } else {
    if (typeof value === 'string' && value.includes(',')) {
      return value.split(',') as unknown as Array<T>;
    }
    return [value];
  }
}
