import { useQuery, gql } from '@apollo/client';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import type { Props as SelectProps } from 'components/lib/form/Select';
import Select from 'components/lib/form/Select';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Tooltip from 'components/lib/ui/Tooltip';

import type { GetMerchants } from 'common/generated/graphQlTypes/GetMerchants';
import type { OptionType } from 'types/select';

export type MerchantOption = OptionType & {
  transactionCount?: number;
};

const CreditCardIcon = styled(Icon).attrs({ name: 'credit-card' })`
  width: ${({ theme }) => theme.spacing.small};
  height: ${({ theme }) => theme.spacing.small};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const MerchantCount = styled(FlexContainer).attrs({ alignCenter: true })`
  color: ${({ theme }) => theme.color.textLight};

  .react-select__option--is-focused & {
    color: ${({ theme }) => theme.color.blue};
  }
  .react-select__option--is-selected & {
    color: ${({ theme }) => theme.color.white};
  }
  .react-select__single-value & {
    display: none;
  }
`;

const OptionRow = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  flex: 1;
`;

type Props = SelectProps & {
  showTransactionCount?: boolean;
};

/**
 * This component is used for select preloaded merchants options
 * commonly used in transaction filter forms.
 * It works with isMulti.
 */
const MerchantSelect = ({
  isCreatable = false,
  showTransactionCount = true,
  ...selectProps
}: Props) => {
  const { data, loading } = useQuery<GetMerchants>(GET_HOUSEHOLD_MERCHANTS);

  const { merchants = [] } = data ?? {};

  const options: MerchantOption[] = useMemo(
    () =>
      merchants.map(({ id, name, transactionCount }) => ({
        label: name,
        value: id,
        transactionCount,
      })),
    [merchants],
  );

  return (
    <Select<MerchantOption>
      options={options}
      renderOption={({ label, transactionCount }) => (
        <OptionRow>
          <span>{label}</span>
          {!!transactionCount && showTransactionCount && (
            <Tooltip
              content={`${transactionCount} existing ${pluralize(
                'transaction',
                transactionCount,
              )} for this merchant`}
            >
              <MerchantCount>
                <CreditCardIcon />
                <span>{transactionCount}</span>
              </MerchantCount>
            </Tooltip>
          )}
        </OptionRow>
      )}
      menuPlacement="bottom"
      isCreatable={isCreatable}
      isLoading={loading}
      hideSelectedOptions
      isMulti
      {...selectProps}
    />
  );
};

const GET_HOUSEHOLD_MERCHANTS = gql`
  query GetMerchants {
    merchants {
      id
      name
      transactionCount
    }
  }
`;

export default MerchantSelect;
