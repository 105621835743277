import type { StyleMixin } from 'common/types/Styles';

export type ButtonSize = 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large';
export const DEFAULT_BUTTON_SIZE: ButtonSize = 'small';

const buttonSizeMixin: StyleMixin<{ size?: ButtonSize }> = ({
  theme,
  size = DEFAULT_BUTTON_SIZE,
}) => `
  font-size: ${
    {
      xxsmall: theme.fontSize.small,
      xsmall: theme.fontSize.small,
      small: theme.fontSize.small,
      medium: theme.fontSize.base,
      large: theme.fontSize.large,
    }[size]
  };

  padding: ${
    {
      xxsmall: '4px 8px',
      xsmall: '5.5px 12px',
      small: '7.5px 12px',
      medium: '8px 16px',
      large: '10.5px 16px',
    }[size]
  };
`;

export default buttonSizeMixin;
