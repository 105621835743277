import * as Sentry from '@sentry/browser';
import downloadFile from 'js-file-download';

import { convertKeysToSnakeCase } from 'common/utils/Object';
import api from 'lib/api';
import { errorToast } from 'lib/ui/toast';
import type { Thunk } from 'state/types';

import { MS_PER_MINUTE } from 'common/constants/time';

import type { TransactionFilters } from 'types/filters';

const TIMEOUT_MS = 10 * MS_PER_MINUTE; // 10 minutes

export const downloadTransactions =
  (filters: Partial<TransactionFilters>): Thunk =>
  async (dispatch, getState, thunkExtra) => {
    try {
      const response = await api.post('/download-transactions/', {
        data: convertKeysToSnakeCase(filters),
        timeout: TIMEOUT_MS,
      });

      if (response) {
        downloadFile(response, 'transactions.csv');
      }
    } catch (e) {
      errorToast(
        'You can download up to 10,000 transactions at once for a seamless experience. Please adjust your filters and try again.',
      );
      Sentry.captureException(e);
    }
  };
