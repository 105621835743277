import React from 'react';
import styled, { css } from 'styled-components';

import AbstractField from 'components/lib/form/AbstractField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Square from 'components/lib/ui/Square';

const Root = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 60px;
`;

const Button = styled(FlexContainer).attrs({ center: true, column: true })<{ isActive: boolean }>`
  border-radius: 6px;
  background: ${({ theme }) => theme.color.grayBackground};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  cursor: pointer;
  user-select: none;
  transition: ${({ theme }) => theme.transition.default};
  height: 100%;
  width: 100%;

  :hover {
    background: #eef1f6;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      && {
        color: ${({ theme }) => theme.color.orange};
        background: ${({ theme }) => theme.color.orangeBackground};
        box-shadow: inset 0px 0px 9px rgba(255, 104, 95, 0.4);
      }
    `}
`;

const StyledIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-bottom: 14px;
`;

type Props = {
  name: string;
  required?: boolean;
};

const BooleanField = ({ name, required }: Props) => (
  <AbstractField name={name} required={required} hideLabel>
    {({ value, setFieldValue }) => (
      <Root>
        <Square>
          <Button isActive={value === true} onClick={() => setFieldValue(name, true)}>
            <StyledIcon name="thumbs-up" />
            Yes
          </Button>
        </Square>
        <Square>
          <Button isActive={value === false} onClick={() => setFieldValue(name, false)}>
            <StyledIcon name="thumbs-down" />
            No
          </Button>
        </Square>
      </Root>
    )}
  </AbstractField>
);

export default BooleanField;
