import type { OperationVariables, QueryHookOptions, TypedDocumentNode } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import type { Duration } from 'luxon';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import useQuery from 'common/lib/hooks/useQuery';
import type { Result } from 'common/lib/hooks/useQuery';
import { isTimestampPastExpiration } from 'common/utils/date';

// static object to store last updated timestamps by cache key
const lastUpdatedByCacheKey: Record<string, string> = {};

type Options<TData, TVariables extends OperationVariables> = QueryHookOptions<TData, TVariables> & {
  cacheExpiration: Duration;
};

/**
 * This is a wrapper around useQuery that uses fetch policy cache-first and keeps
 * track of the timestamp of when the data was last fetched. If the data is older
 * than the expiration time, it will be refetched.
 *
 * The benefit of this over cache-and-network policy is there is less network fetching
 * for cases where the same query is used in a lot of places.
 *
 * Some inspiration taken from https://www.assurantlabs.com/blog/2020/07/20/cache-expiration-in-apollo-graphql-using-react-hooks/
 *
 * EXAMPLE:
 *
 * import { Duration } from 'luxon';
 *
 * const { data } = useQueryWithCacheExpiration(QUERY, {
 *   variables: { ... },
 *   cacheExpiration: Duration.fromObject({ hours: 1 })
 * })
 *
 */
const useQueryWithCacheExpiration = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: Options<TData, TVariables>,
): Result<TData, TVariables> => {
  const { cacheExpiration } = options;

  const cacheKey: string = useMemo(
    () =>
      // @ts-ignore - we know that the first definition will always be a query operation, but TS doesn't
      query.definitions[0]?.name?.value ?? uuid(),
    [query],
  );

  const lastUpdatedAt = lastUpdatedByCacheKey[cacheKey];

  const queryInfo = useQuery<TData, TVariables>(query, {
    fetchPolicy: 'cache-first',
    ...options,
  });
  const { data, refetch } = queryInfo;

  const updateLastUpdatedAt = () => {
    lastUpdatedByCacheKey[cacheKey] = DateTime.local().toISO();
  };

  useEffect(() => {
    if (data && !lastUpdatedAt) {
      // Update the lastUpdatedAt timestamp the first time the data is fetched
      updateLastUpdatedAt();
    }
  }, [data]);

  useEffect(() => {
    (async () => {
      if (lastUpdatedAt && isTimestampPastExpiration(lastUpdatedAt, cacheExpiration)) {
        // If we're past the expiration time, refetch the data from the network
        await refetch();
        updateLastUpdatedAt();
      }
    })();
  }); // No deps array because we want this to run every time

  return queryInfo;
};

export default useQueryWithCacheExpiration;
