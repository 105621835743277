import { useQuery, useMutation, gql } from '@apollo/client';
import * as R from 'ramda';
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import AddAccountsInstitutionSyncing from 'components/accounts/AddAccountsInstitutionSyncing';
import type { LinkAccountSuccessMetadata } from 'components/accounts/LinkAccountDataProviderModal';
import LinkAccountError from 'components/accounts/LinkAccountError';
import LinkAccountSuccessModal from 'components/accounts/LinkAccountSuccessModal';
import Flex from 'components/lib/ui/Flex';
import ModalCard from 'components/lib/ui/ModalCard';

import { useStackContext } from 'common/lib/contexts/StackContext';
import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import { CREATE_FINICITY_CREDENTIALS_MUTATION } from 'common/lib/graphQl/finicity';
import { GET_INSTITUTION_LOGO_BY_PLAID_ID } from 'common/lib/graphQl/institutions';
import { CREATE_MX_CREDENTIALS_MUTATION } from 'common/lib/graphQl/mx';
import { getCoinbaseRedirectUri } from 'lib/external/coinbase';
import useCreateAccountsFromPlaidTokenMutation from 'lib/hooks/useCreateAccountsFromPlaidTokenMutation';

import type { CreateFinicityCredentialsMutation } from 'common/generated/graphQlTypes/CreateFinicityCredentialsMutation';
import type { CreateMXCredentialsMutation } from 'common/generated/graphQlTypes/CreateMXCredentialsMutation';
import type {
  GetInstitutionLogoByPlaidId,
  GetInstitutionLogoByPlaidIdVariables,
} from 'common/generated/graphQlTypes/GetInstitutionLogoByPlaidId';
import type {
  Web_CreateCoinbaseAccount,
  Web_CreateCoinbaseAccountVariables,
} from 'common/generated/graphQlTypes/Web_CreateCoinbaseAccount';
import { DataProviderLegacy } from 'common/generated/graphQlTypes/globalTypes';

const Root = styled(Flex)`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  metadata: LinkAccountSuccessMetadata;
};

const LinkAccountLoadingModal = ({ metadata }: Props) => {
  const { push } = useStackContext();

  const { dataProvider, institution, plaidLinkToken, plaidLinkMetadata, coinbaseCode } = metadata;
  const { institution: plaidInstitution, link_session_id: linkSessionId = '' } =
    plaidLinkMetadata ?? {};
  const plaidInstitutionId = plaidInstitution?.institution_id ?? institution?.plaidInstitutionId;
  const institutionName = plaidInstitution?.name ?? institution?.name;

  const next = useCallback(
    (params: { institutionName?: string; metadata: LinkAccountSuccessMetadata }) =>
      push(LinkAccountSuccessModal, params),
    [push],
  );

  const [animationFinished, setAnimationFinished] = useState<boolean>(false);

  const [createAccountsFromPlaidToken, { data: plaidData }] =
    useCreateAccountsFromPlaidTokenMutation();
  const [createFinicityCredentials, { data: finicityData }] =
    useMutation<CreateFinicityCredentialsMutation>(CREATE_FINICITY_CREDENTIALS_MUTATION);
  const [createMxCredentials, { data: mxData }] = useMutation<CreateMXCredentialsMutation>(
    CREATE_MX_CREDENTIALS_MUTATION,
  );
  const [createCoinbaseAccount, { data: coinbaseData }] = useMutation<
    Web_CreateCoinbaseAccount,
    Web_CreateCoinbaseAccountVariables
  >(CREATE_COINBASE_ACCOUNT_MUTATION);

  const error =
    finicityData?.createFinicityCredentials?.errors ||
    plaidData?.createAccountsFromPlaidToken?.errors ||
    mxData?.createMxCredentials?.errors ||
    coinbaseData?.createCoinbaseAccount?.errors;

  const { data } = useQuery<GetInstitutionLogoByPlaidId, GetInstitutionLogoByPlaidIdVariables>(
    GET_INSTITUTION_LOGO_BY_PLAID_ID,
    {
      variables: {
        plaidId: plaidInstitutionId ?? '',
      },
      skip: !plaidInstitutionId,
    },
  );
  const { logo } = data?.institution ?? {};

  useEffect(() => {
    try {
      if (dataProvider === DataProviderLegacy.PLAID) {
        createAccountsFromPlaidToken({
          variables: {
            publicToken: plaidLinkToken ?? '',
            plaidInstitutionName: institutionName ?? '',
            plaidInstitutionId: plaidInstitutionId ?? '',
            linkSessionId,
          },
        });
      } else if (metadata.dataProvider === DataProviderLegacy.FINICITY) {
        createFinicityCredentials();
      } else if (metadata.dataProvider === DataProviderLegacy.MX) {
        createMxCredentials();
      } else if (metadata.dataProvider === DataProviderLegacy.COINBASE) {
        if (!coinbaseCode) {
          throw new Error('Missing coinbase code!');
        }

        createCoinbaseAccount({
          variables: {
            input: {
              code: coinbaseCode,
              redirectUri: getCoinbaseRedirectUri(),
            },
          },
        });
      }
    } catch (error) {
      // TODO
    }
  }, []);

  const isComplete =
    plaidData ||
    finicityData ||
    mxData ||
    coinbaseData ||
    dataProvider === DataProviderLegacy.ZILLOW;
  // Call next() once mutation is finished and animation is complete
  useEffect(() => {
    if (!error && isComplete && animationFinished) {
      next({
        institutionName,
        metadata: {
          ...metadata,
          accountIds: R.flatten([
            metadata.accountIds ??
              plaidData?.createAccountsFromPlaidToken?.accounts.map(R.prop('id')) ??
              coinbaseData?.createCoinbaseAccount?.account?.id ??
              finicityData?.createFinicityCredentials?.credentials.map(({ accounts }) =>
                accounts.map(R.prop('id')),
              ) ??
              mxData?.createMxCredentials?.credentials.map(({ accounts }) =>
                accounts.map(R.prop('id')),
              ),
          ]) as string[],
        },
      });
    }
  }, [isComplete, animationFinished, institutionName, next, error]);

  return (
    <ModalCard title={!error && 'Syncing Account'} hideCloseButton>
      {error ? (
        <LinkAccountError error={error} />
      ) : (
        <Root alignCenter column>
          <AddAccountsInstitutionSyncing
            institutionLogo={logo}
            onAnimationEnd={() => setAnimationFinished(true)}
          />
        </Root>
      )}
    </ModalCard>
  );
};

export default LinkAccountLoadingModal;

const CREATE_COINBASE_ACCOUNT_MUTATION = gql`
  mutation Web_CreateCoinbaseAccount($input: CreateCoinbaseAccountInput!) {
    createCoinbaseAccount(input: $input) {
      account {
        id
        displayName
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;
