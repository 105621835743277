import { DateTime } from 'luxon';
import type { DurationLikeObject } from 'luxon';
import pluralize from 'pluralize';
import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

import { useTabs } from 'common/components/tabs/TabsContext';
import AccountLogo from 'components/accounts/AccountLogo';
import Form from 'components/lib/form/Form';
import SelectField from 'components/lib/form/SelectField';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import PillTab from 'components/lib/ui/tabs/PillTab';

import useUpdateEffect from 'common/lib/hooks/useUpdateEffect';

import type { Web_GetInvestmentsAccountsQuery } from 'common/generated/graphql';
import type DateRange from 'common/types/DateRange';
import type { ElementOf } from 'common/types/utility';
import type { TransactionFilters } from 'types/filters';

type InvestmentsAccount = ElementOf<Web_GetInvestmentsAccountsQuery, 'accounts'>;

const MAX_ACCOUNT_SELECTIONS_TO_SHOW = 0;

type DateRangeShortcut = {
  title: '1W' | '1M' | '3M' | '6M' | 'YTD' | '1Y' | '5Y';
  duration?: DurationLikeObject;
};

export const INVESTMENTS_DATE_RANGE_SHORTCUTS: DateRangeShortcut[] = [
  {
    title: '1W',
    duration: { week: 1 },
  },
  {
    title: '1M',
    duration: { month: 1 },
  },
  {
    title: '3M',
    duration: { month: 3 },
  },
  {
    title: '6M',
    duration: { month: 6 },
  },
  {
    title: 'YTD',
    duration: { year: 1 },
  },
  {
    title: '1Y',
    duration: { year: 1 },
  },
  {
    title: '5Y',
    duration: { year: 5 },
  },
];

export const getDateRangeForShortcut = ({ duration, title }: DateRangeShortcut): DateRange => {
  const now = DateTime.local();

  if (title === 'YTD') {
    return {
      startDate: now.startOf('year').toISODate(),
      endDate: now.toISODate(),
    };
  } else if (duration) {
    return {
      startDate: now.minus(duration).toISODate(),
      endDate: now.toISODate(),
    };
  }

  return {
    startDate: undefined,
    endDate: undefined,
  };
};

const AccountLogoSmall = styled(AccountLogo).attrs({ size: 24 })`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const AccountSelectorContainer = styled(Flex)`
  width: 200px;
  margin-left: ${(props) => props.theme.spacing.large};
  > * {
    width: 100%;
  }
`;

const StyledSelectField = styled(SelectField)`
  .react-select {
    &__menu-list {
      span {
        max-width: 290px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }
    }

    &__menu {
      width: max-content;
      max-width: 360px;
      right: 0;
    }
  }
`;

type Props = {
  accounts: InvestmentsAccount[];
  activeAccountIds: string[];
  onApply: (id: string[]) => void;
  onDateRangeChange: (value: DateRange) => void;
  isLoadingAccounts: boolean;
  activeFilters: TransactionFilters;
};

const InvestmentsHeaderControls = ({
  accounts,
  isLoadingAccounts,
  activeAccountIds,
  activeFilters,
  onApply,
  onDateRangeChange,
}: Props) => {
  const [activeIndex, setActiveIndex] = useTabs();
  const selectedDateRange = INVESTMENTS_DATE_RANGE_SHORTCUTS[activeIndex];

  useEffect(() => {
    setActiveIndex(2);
  }, []);

  useUpdateEffect(() => {
    onDateRangeChange(getDateRangeForShortcut(selectedDateRange));
  }, [selectedDateRange]);

  const initialValues = useMemo(
    () => ({ ...R.pick(['accounts'], activeFilters) }),
    [activeFilters],
  );

  const accountOptions = useMemo(
    () =>
      R.map(
        (account) => ({
          value: account.id,
          label: <span>{account.displayName}</span>,
          icon: <AccountLogoSmall logoUrl={account.logoUrl} icon={account.icon} />,
        }),
        R.sortBy(R.prop('order'), accounts),
      ),
    [accounts],
  );

  return (
    <FlexContainer alignCenter justifyEnd>
      <Flex>
        {INVESTMENTS_DATE_RANGE_SHORTCUTS.map(({ title }, index) => (
          <PillTab key={title} index={index}>
            {title}
          </PillTab>
        ))}
      </Flex>
      <AccountSelectorContainer>
        <Form
          submitOnChange
          initialValues={initialValues}
          onSubmit={({ accounts }) => onApply(accounts ?? [])}
        >
          <StyledSelectField
            name="accounts"
            placeholder="All accounts"
            options={accountOptions}
            isLoading={isLoadingAccounts}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            components={{ MultiValue: SelectAccountMultiValueWithSelectionSummary }}
            isClearable
            hideLabel
            isMulti
          />
        </Form>
      </AccountSelectorContainer>
    </FlexContainer>
  );
};

const SelectAccountMultiValueWithSelectionSummary = (
  props: { index: number } & React.ComponentProps<typeof components.MultiValue>,
) => {
  const { index, getValue } = props;
  const hiddenCount = getValue().slice(MAX_ACCOUNT_SELECTIONS_TO_SHOW).length;
  const selectedCount = MAX_ACCOUNT_SELECTIONS_TO_SHOW + hiddenCount;

  if (R.isEmpty(hiddenCount)) {
    return <components.MultiValue {...props} />;
  }

  return index === 0 ? (
    <Text weight="medium">
      {selectedCount} {pluralize('account', selectedCount)}
    </Text>
  ) : null;
};

export default InvestmentsHeaderControls;
