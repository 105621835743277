import * as R from 'ramda';

type SortableCredential = {
  id: GraphQlUUID;
  institution: {
    name: string;
  };
};

export const sortCredentials = <TCredential extends SortableCredential>(
  credentials: TCredential[],
) => R.sortBy(({ institution }) => institution.name, credentials);
