import React from 'react';
import styled from 'styled-components';

import InvestmentsValue from 'components/investments/InvestmentsValue';
import ChartTooltip from 'components/lib/charts/ChartTooltip';
import ChartTooltipDataRow from 'components/lib/charts/ChartTooltipDataRow';

import useTheme from 'lib/hooks/useTheme';

const Tooltip = styled(ChartTooltip)`
  width: 280px;
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const Row = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacing.xlarge};
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  data: { label: string | null | undefined; value: number; color: string }[];
  header: string;
  active: boolean;
};

const InvestmentsPerformanceChartTooltip = ({ data, header, active }: Props) => {
  const theme = useTheme();

  return (
    <Tooltip active={active} header={header}>
      {data.map(({ label, value, color }) => (
        <Row key={JSON.stringify({ label, value, color })}>
          <ChartTooltipDataRow dotFillColor={color} value={label} />
          <InvestmentsValue value={value} defaultZeroColor={theme.color.textWhite}>
            {value}%
          </InvestmentsValue>
        </Row>
      ))}
    </Tooltip>
  );
};

export default InvestmentsPerformanceChartTooltip;
