import React from 'react';
import styled from 'styled-components';

import EmojiField from 'components/lib/form/EmojiField';
import { Label } from 'components/lib/form/FormItemContainer';
import TextField from 'components/lib/form/TextField';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Tooltip from 'components/lib/ui/Tooltip';

const CombinedFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  && > * {
    margin-bottom: 0;
  }
`;

const StyledEmojiField = styled(EmojiField)`
  width: 44px;
  input {
    border-radius: ${({ theme }) => theme.radius.small} 0 0 ${({ theme }) => theme.radius.small};
    text-align: center;
    :not(:focus) {
      border-right: none;
    }
    :focus {
      /* Set z-index so box shadow appears on top of name field when focused */
      z-index: 1; /* stylelint-disable-line plugin/no-z-index */
    }
  }
`;

const TextFieldWrapper = styled.div`
  flex: 1;
`;

const StyledTextField = styled(TextField)`
  input {
    border-radius: 0 ${({ theme }) => theme.radius.small} ${({ theme }) => theme.radius.small} 0;
  }
`;

const Description = styled.h3`
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
  margin-bottom: unset;
`;

type Props = {
  emojiField: React.ComponentProps<typeof EmojiField>;
  textField: React.ComponentProps<typeof TextField>;
  textFieldTooltip?: string;
  label?: string;
  isSensitive?: boolean;
  description?: string;
};

const CombinedEmojiAndTextFields = ({
  emojiField,
  textField,
  textFieldTooltip,
  label,
  isSensitive,
  description,
}: Props) => (
  <CombinedFieldContainer>
    {!!label && <Label>{label}</Label>}
    <InputContainer>
      <StyledEmojiField hideLabel {...emojiField} />
      <Tooltip content={textFieldTooltip}>
        <TextFieldWrapper>
          <StyledTextField hideLabel {...textField} {...(isSensitive ? sensitiveClassProps : {})} />
        </TextFieldWrapper>
      </Tooltip>
    </InputContainer>
    {!!description && <Description>{description}</Description>}
  </CombinedFieldContainer>
);

export default CombinedEmojiAndTextFields;
