import { useMutation, gql } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import Confirmation from 'components/lib/ui/Confirmation';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import { isoDateToMonthAndYear } from 'common/utils/date';

import type {
  Web_ResetRolloverMutation,
  Web_ResetRolloverMutationVariables,
} from 'common/generated/graphQlTypes/Web_ResetRolloverMutation';

const ConfirmationBody = styled.div``;

type Props = {
  categoryId: string;
  startMonth: string;
  onCompleted?: () => void;
};

const ResetRolloverConfirmation = ({ categoryId, startMonth, onCompleted }: Props) => {
  const [resetRollover, { loading }] = useMutation<
    Web_ResetRolloverMutation,
    Web_ResetRolloverMutationVariables
  >(MUTATION, {
    variables: {
      input: {
        categoryId,
        startMonth,
      },
    },
    onCompleted,
  });

  return (
    <Confirmation
      title="Are you sure?"
      confirm="Yes, I'm sure"
      onConfirm={resetRollover}
      isLoading={loading}
    >
      <ConfirmationBody>
        Resetting the rollover for a category will change the rollover balance to $0 for{' '}
        {isoDateToMonthAndYear(startMonth)}.
      </ConfirmationBody>
    </Confirmation>
  );
};

const MUTATION = gql`
  mutation Web_ResetRolloverMutation($input: ResetBudgetRolloverInput!) {
    resetBudgetRollover(input: $input) {
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default ResetRolloverConfirmation;
