import { DateTime } from 'luxon';
import * as R from 'ramda';

import { DATE_RANGES } from 'common/lib/accounts/accountCharts';
import type { SnapshotEntry } from 'common/lib/accounts/accountCharts';

import { ACCOUNT_TYPE_ORDER } from 'common/constants/accounts';
import type { AccountType } from 'common/constants/accounts';
import type { TimeframeBase } from 'common/constants/timeframes';

import type {
  Common_GetMonthlySnapshotsQuery,
  Common_GetSnapshotsByAccountTypeQuery,
} from 'common/generated/graphql';

type AccountTypes = Partial<{ [key in AccountType]: number }>;
export type NetWorthMonthData = {
  month: string;
  net?: number;
  currentMonthNet?: number;
} & AccountTypes;
export type NetWorthMonthDataDiff = {
  current?: NetWorthMonthData;
  previous?: NetWorthMonthData;
} & AccountTypes;
export type ChartDatum = NetWorthMonthData & { net?: number; currentMonthNet?: number };
export type ChartData = ChartDatum[];

export const getNetWorthChartStartDate = ({
  timeframe = 'month',
  value = 12,
}: {
  timeframe: TimeframeBase;
  value?: number;
}) =>
  DateTime.local()
    .minus({ [timeframe]: value })
    .startOf(timeframe)
    .toISODate();

export const getSortedKeysByAccountType = <
  TData extends Partial<{ [key in AccountType]: unknown }>,
>(
  data: TData,
) =>
  R.sortWith(
    [R.ascend((type) => R.indexOf(type as string, ACCOUNT_TYPE_ORDER))],
    R.keys(R.pick(ACCOUNT_TYPE_ORDER, data)),
  );

const groupByMonth = (
  data: Common_GetMonthlySnapshotsQuery['monthlySnapshotsByAccountType'],
): { [month: string]: typeof data } => R.groupBy(R.prop('month'), data);

/** Transform the list of balances by account type into a single dictionary with lowercased keys */
const consolidateBalancesByAccount = (
  balances: Common_GetMonthlySnapshotsQuery['monthlySnapshotsByAccountType'],
) =>
  balances.reduce(
    (p, { balance, accountType }) => ({ ...p, [accountType.toLocaleLowerCase()]: balance }),
    {} as NetWorthMonthData,
  );

const getNetWorthSum = (value: Omit<NetWorthMonthData, 'net'>): number =>
  R.sum(R.values(R.omit(['month'], value)) as number[]);

export const compileMonthlyNetWorthData = <
  TData extends Common_GetMonthlySnapshotsQuery['monthlySnapshotsByAccountType'],
>(
  data: TData,
  timeFrame: TimeframeBase = 'month',
  separateCurrentNet = false,
): ChartData => {
  const byMonth = groupByMonth(data);
  return Object.entries(byMonth).flatMap(([month, balancesByMonth]) => {
    const balances = consolidateBalancesByAccount(balancesByMonth);
    const keys = getSortedKeysByAccountType(balances);

    const today = DateTime.local();
    const date = DateTime.fromISO(month);
    const isSameTimeframe = today.hasSame(date, timeFrame);
    const isPreviousTimeframe = today.minus({ [timeFrame]: 1 }).hasSame(date, timeFrame);

    // When we should show the dashed line (indicator that the month hasn't finished yet)
    const shouldAddCurrentMonthNet = isSameTimeframe || isPreviousTimeframe;

    const shouldAddNet = !isSameTimeframe || !separateCurrentNet;

    return {
      month,
      net: shouldAddNet ? getNetWorthSum(balances) : undefined,
      ...R.pick(keys, balances),
      ...(shouldAddCurrentMonthNet ? { currentMonthNet: getNetWorthSum(balances) } : {}),
    };
  });
};

export const getNetWorthBalancesForBreakdownChart = (data: ChartData) => {
  const current = data[data.length - 1];
  const previous = data[data.length - 2];
  return [previous?.net ?? 0, current?.net ?? 0] as const;
};

export const getNetWorthBalancesForPerformanceChart = <T extends SnapshotEntry[]>(
  data: T,
  dataKey: 'assetsBalance' | 'liabilitiesBalance' | 'balance',
) => {
  const previous = R.head(data)?.[dataKey];
  const current = R.last(data)?.[dataKey];
  return [previous ?? 0, current ?? 0] as const;
};

const getFormattedSnapshotMonth = (dt: DateTime) => dt.toFormat('yyyy-MM');

const getDataForMonth = (data: NetWorthMonthData[], baseDate: DateTime): NetWorthMonthData => {
  const month = getFormattedSnapshotMonth(baseDate);
  const monthData = data.find((d) => d.month === month);
  return monthData ?? { month };
};

export const getComparativeDataForTimeframe = (
  snapshots: Common_GetSnapshotsByAccountTypeQuery['snapshotsByAccountType'],
  hoveredBarDate: DateTime,
  timeframe: TimeframeBase,
) => {
  const netWorthComposition = compileMonthlyNetWorthData(snapshots);
  const current = getDataForMonth(netWorthComposition, hoveredBarDate);
  const previous = getDataForMonth(
    netWorthComposition,
    hoveredBarDate.minus({ [timeframe]: 1 }).endOf(timeframe),
  );
  return { current, previous };
};

export const getComparativeDataFromStartOfPeriodToDate = <T extends SnapshotEntry[]>(
  aggregateSnapshotsData: T,
  hoveredBarDate: string,
  dataKey: keyof Omit<SnapshotEntry, 'date'>,
) => {
  const [initial] = aggregateSnapshotsData;

  const current = aggregateSnapshotsData.find((snapshot) => snapshot.date === hoveredBarDate);

  const currentBalance = current?.[dataKey];
  const initialBalance = initial?.[dataKey] ?? 0;

  const diff = Number(currentBalance) - Number(initialBalance);
  const percentageChange =
    Number(initialBalance) !== 0 ? (diff / Number(initialBalance)) * 100 : null;

  return {
    initialDate: initial?.date,
    activeDate: current?.date,
    diff,
    percentageChange,
  };
};

export const getAmountsByAccountType = (monthData: NetWorthMonthData): Record<string, number> =>
  Object.entries(R.omit(['month', 'net'], monthData)).reduce(
    (acc, [accountType, amount]) => ({
      ...acc,
      // Just make sure the amount is a number.
      // Let the component handle the correct sign for the account type.
      [accountType]: Math.abs(Number(amount)),
    }),
    {},
  );

export const getPreviousAmountsByAccountType = (
  data: Common_GetMonthlySnapshotsQuery['monthlySnapshotsByAccountType'],
  baseDate: DateTime,
  timeframe: TimeframeBase,
) => {
  const { previous } = getComparativeDataForTimeframe(data, baseDate, timeframe);
  return getAmountsByAccountType(previous);
};

export const getCurrentTimeframeText = (
  timeframe: string,
  dateRangeDisplayName: string,
  chartType: string,
) => {
  if (chartType === 'performance') {
    const dateRange = DATE_RANGES.find((d) => d.display === dateRangeDisplayName);
    const displayName = dateRange?.longDisplaySingular ?? dateRange?.longDisplay;
    return `${displayName} change`;
  }
  return `This ${timeframe}`;
};
