type Testimonial = {
  name: string;
  quote: string;
  platform?: 'apple' | 'google';
};

export const TESTIMONIALS: Testimonial[] = [
  {
    name: `Paul W.`,
    quote: `Excellent interface, super easy to use. By far the best money management and planning app I've used.`,
  },
  {
    name: `Aaron V.`,
    quote: `Makes it easy to see all my spending across accounts, with no ads and no unwanted advisors calling me.`,
  },
  {
    name: `Miten M.`,
    quote: `Love that I can easily see my net income by month across all of my accounts. Never been able to have this view before.`,
  },
  {
    name: `Joshua W.`,
    quote: `I tried YNAB, I tried Mint, I use Monarch. Soooo much more intuitive and the UI/UX is delightful.`,
    platform: 'google',
  },
  {
    name: `themidnighteagle`,
    quote: `This is hands down the best experience I’ve had with managing my money and expenses. I use it everyday and am super confident in what is going in and out.`,
    platform: 'apple',
  },
  {
    name: `Stephen C.`,
    quote: `It's beautifully designed and it just works, as advertised; Significantly fewer syncing issues than it's competitors.`,
    platform: 'google',
  },
];
