import { DateTime } from 'luxon';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import TabsContext from 'common/components/tabs/Tabs';
import LinkAccountFlow from 'components/accounts/LinkAccountFlow';
import InvestmentsHeaderControls from 'components/investments/InvestmentsHeaderControls';
import InvestmentsHeaderTabs from 'components/investments/InvestmentsHeaderTabs';
import Flex from 'components/lib/ui/Flex';
import PageWithNoAccountsEmptyState from 'components/lib/ui/PageWithNoAccountsEmptyState';
import PremiumFeatureBlockingOverlay from 'components/premium/PremiumFeatureBlockingOverlay';
import InvestmentsAllocation from 'components/routes/investments/InvestmentsAllocation';
import InvestmentsHoldings from 'components/routes/investments/InvestmentsHoldings';

import useFeatureEntitlement from 'common/lib/hooks/premium/useFeatureEntitlement';
import useQuery from 'common/lib/hooks/useQuery';
import useToggle from 'common/lib/hooks/useToggle';
import useFilters from 'lib/hooks/transactions/useFilters';
import useDateRangeQueryParams from 'lib/hooks/useDateRangeQueryParams';
import useModal from 'lib/hooks/useModal';
import { getUpdatedQueryParams } from 'lib/transactions/Filters';
import { setActiveAccountIds } from 'state/investments/actions';
import { getActiveAccountIds } from 'state/investments/selectors';

import { INVESTMENTS_EMPTY_STATE_TITLE } from 'common/constants/copy';
import { ProductFeature } from 'common/constants/premium';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';

const DEFAULT_ROUTE = routes.investments.holdings.path;

const Investments = () => {
  const dispatch = useDispatch();
  const activeAccountIds = useSelector(getActiveAccountIds);
  const updateActiveIds = useCallback(
    (ids: string[]) => dispatch(setActiveAccountIds(ids)),
    [dispatch],
  );

  const history = useHistory();
  const [filters, setFilters] = useFilters({
    onUpdate: (queryParams, override) => {
      const params = getUpdatedQueryParams(queryParams, override);
      const url = `${window.location.pathname}?${queryString.stringify(params)}`;

      history.push(url);
    },
  });

  const [dateRange, setDateRange] = useDateRangeQueryParams({
    startDate: DateTime.local().minus({ months: 1 }).toISODate(),
    endDate: DateTime.local().toISODate(),
  });

  const [
    shouldSelectNewAccounts,
    { setOn: setShouldSelectNewAccounts, setOff: disableShouldSelectNewAccounts },
  ] = useToggle(false);
  const selectAllAccounts = () => updateActiveIds([]);

  const {
    data: accountsData,
    refetch: refetchAccounts,
    isLoadingInitialData: isLoadingAccounts,
  } = useQuery(INVESTMENTS_ACCOUNTS_QUERY, {
    onCompleted: ({ accounts }) => {
      // If no accounts selected OR size of selected accounts is greater than the size of accounts
      // data (e.g. user deletes account while it was selected), select all by default
      if (activeAccountIds.length === 0 || activeAccountIds.length > accounts.length) {
        selectAllAccounts();
      }

      if (shouldSelectNewAccounts) {
        selectAllAccounts();
        disableShouldSelectNewAccounts();
      }
    },
  });

  const accounts = accountsData?.accounts ?? [];

  useEffect(() => {
    setFilters({ accounts: activeAccountIds });
  }, [activeAccountIds, history.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const [LinkModalWrapper, { open: openLink, close: closeLink }] = useModal();

  const { hasAccess } = useFeatureEntitlement(ProductFeature.investments);

  return (
    <TabsContext>
      <PageWithNoAccountsEmptyState
        name="Investments"
        controls={
          <InvestmentsHeaderControls
            accounts={accounts}
            isLoadingAccounts={isLoadingAccounts}
            onApply={(ids) => updateActiveIds(ids)}
            onDateRangeChange={setDateRange}
            activeAccountIds={activeAccountIds}
            activeFilters={filters}
          />
        }
        tabs={<InvestmentsHeaderTabs />}
        emptyIcon="trending-up"
        emptyTitle={INVESTMENTS_EMPTY_STATE_TITLE}
        onAccountAdded={refetchAccounts}
        overlayEmptyComponent={
          !hasAccess ? (
            <PremiumFeatureBlockingOverlay
              feature={ProductFeature.investments}
              useDescriptionAsTitle
            />
          ) : undefined
        }
      >
        <LinkModalWrapper onClose={refetchAccounts}>
          <LinkAccountFlow
            onClose={closeLink}
            onComplete={() => {
              closeLink();
              setShouldSelectNewAccounts();
            }}
            allowManualAccount={false}
          />
        </LinkModalWrapper>
        <Flex alignStart>
          <Switch>
            {/* This redirects the user to the default route if they try to access the page by
            the direct URL (/investments). Without this we'd render a blank page. */}
            <Route
              exact
              path={routes.investments.path}
              render={() => <Redirect to={DEFAULT_ROUTE} />}
            />
            <Route path={routes.investments.holdings.path}>
              <InvestmentsHoldings
                activeAccountIds={activeAccountIds}
                dateRange={dateRange}
                onClickLinkAccount={openLink}
                useMockData={!hasAccess}
              />
            </Route>
            <Route path={routes.investments.allocation.path}>
              <InvestmentsAllocation
                activeAccountIds={activeAccountIds}
                onClickLinkAccount={openLink}
              />
            </Route>
          </Switch>
        </Flex>
      </PageWithNoAccountsEmptyState>
    </TabsContext>
  );
};

const INVESTMENTS_ACCOUNTS_QUERY = gql(`
  query Web_GetInvestmentsAccounts {
    accounts(filters: { accountType: "brokerage", includeManual: true, includeHidden: false, ignoreHiddenFromNetWorth: true }) {
      id
      displayName
      isTaxable
      icon
      order
      logoUrl
      includeInNetWorth
      syncDisabled
      subtype {
        display
      }
    }
  }
`);

export default Investments;
