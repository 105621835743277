import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { YAxis } from 'recharts';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import ChartTooltip from 'components/lib/charts/ChartTooltip';
import ChartTooltipDataRow from 'components/lib/charts/ChartTooltipDataRow';
import StackedBarChart, { ARROW_WIDTH_PX } from 'components/lib/charts/StackedBarChart';
import CheckCircleOutline from 'components/lib/ui/CheckCircleOutline';
import CheckCircleTransparent from 'components/lib/ui/CheckCircleTransparent';
import Currency from 'components/lib/ui/currency/Currency';

import {
  ensureMinimumMonthlyContributionSummaries,
  getGraphDataFromMonthlyContributionSummaries,
} from 'common/lib/goalsV2/adapters';
import { formatCurrencyNoCents } from 'common/utils/Currency';
import { isoDateToMonthAbbreviation, isoDateToMonthAndYear, isSameMonth } from 'common/utils/date';
import { cashFlowChartReferenceLineAdapter } from 'lib/cashFlow/Adapters';
import useMeasure from 'lib/hooks/useMeasure';
import useTheme from 'lib/hooks/useTheme';

import type { WebGoalContributionGraphFieldsFragment } from 'common/generated/graphql';

const CHART_HEIGHT_PX = 200;
const PADDING_PX = 24;
const BAR_WIDTH_PX = 60;
const CHECK_SIZE_PX = 24;
const DOT_SIZE_PX = 8;
const PLACEHOLDER_BAR_PERCENT = 0.07;

const Root = styled.div`
  position: relative;
  height: ${CHART_HEIGHT_PX + PADDING_PX * 2}px;
`;
const Container = styled.div`
  position: absolute;
  top: ${PADDING_PX}px;
  left: ${PADDING_PX}px;
  right: ${PADDING_PX}px;
  bottom: ${PADDING_PX}px;
`;

type Props = {
  goal: WebGoalContributionGraphFieldsFragment;
};

const GoalContributionGraph = ({ goal }: Props) => {
  const { plannedMonthlyContribution } = goal;

  const theme = useTheme();

  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();
  const chartWidthPx = width - PADDING_PX * 2;

  const monthlyContributionSummaries = useMemo(() => {
    const displayBarCount = Math.floor((chartWidthPx - 2 * ARROW_WIDTH_PX) / (1.5 * BAR_WIDTH_PX));

    return ensureMinimumMonthlyContributionSummaries(
      goal.monthlyContributionSummaries,
      displayBarCount,
    );
  }, [goal, chartWidthPx]);

  const range = useMemo(
    () => getGraphDataFromMonthlyContributionSummaries(monthlyContributionSummaries).range,
    [monthlyContributionSummaries],
  );

  const domain = useMemo(
    // ensure top end of domain is always at least as large as plannedMonthlyContribution so the refernce line shows
    () => [Math.min(0, -range[0]), Math.max(1, plannedMonthlyContribution ?? 0, range[1])] as const,
    [range, plannedMonthlyContribution],
  );

  const chartData = useMemo(
    () =>
      // add placeholder bar if month has no data
      monthlyContributionSummaries.map((data) => ({
        ...data,
        label: isSameMonth(DateTime.fromISO(data.month), DateTime.local())
          ? formatCurrencyNoCents(data.sum)
          : '',
        placeholder:
          !data.sumCredit && !data.sumDebit ? domain[1] * PLACEHOLDER_BAR_PERCENT : undefined,
      })),
    [monthlyContributionSummaries, domain],
  );

  const hasSpendingOut = domain[0] < 0;

  const yearDividers = useMemo(
    () =>
      cashFlowChartReferenceLineAdapter(
        monthlyContributionSummaries.map(({ month }) => ({ startDate: month })),
      ),
    [monthlyContributionSummaries],
  );

  return (
    <Root ref={ref}>
      <Container>
        <StackedBarChart
          data={chartData}
          heightPx={CHART_HEIGHT_PX}
          widthPx={chartWidthPx}
          barWidthPx={BAR_WIDTH_PX}
          minBarWidthPx={BAR_WIDTH_PX}
          xAxisDataKey="month"
          labelDataKey="label"
          totalDataKey={hasSpendingOut ? 'sum' : undefined} // only show total line if we have at least 1 expense
          formatXAxis={isoDateToMonthAbbreviation}
          formatYAxis={formatCurrencyNoCents}
          xAxisReferenceLines={yearDividers}
          barDataKeys={[
            {
              name: 'sumDebit',
              color: theme.color.red,
            },
            {
              name: 'sumCredit',
              color: theme.color.green,
            },
            {
              name: 'placeholder',
              color: theme.color.gray,
            },
          ]}
          hideGrid
          roundBarCorners
          yAxis={<YAxis domain={domain} tickCount={0} />}
          yAxisReferenceLines={
            plannedMonthlyContribution
              ? [
                  { y: 0, labels: [] },
                  {
                    y: plannedMonthlyContribution,
                    labels: ['Budget', formatCurrencyNoCents(plannedMonthlyContribution)],
                    dashed: true,
                  },
                ]
              : undefined
          }
          xAxisProps={{
            height: 57,
            axisLine: false,
            tickLine: false,
            tick: ({ x, y, payload }) => {
              const index = payload.value;
              const data = monthlyContributionSummaries[index];
              const { month, sumCredit, plannedAmount } = data;

              return (
                <g transform={`translate(${x},${y})`}>
                  <text
                    x={0}
                    y={0}
                    fill={theme.color.textLight}
                    fontWeight={theme.fontWeight.medium}
                    fontSize={theme.fontSize.small}
                  >
                    <tspan dy="16" textAnchor="middle">
                      {isoDateToMonthAbbreviation(month)}
                    </tspan>
                  </text>
                  <Switch>
                    <Case when={sumCredit > 0}>
                      <CheckCircleTransparent
                        fill={theme.color.green}
                        x={`${-CHECK_SIZE_PX / 2}`}
                        y="30"
                        size={CHECK_SIZE_PX}
                      />
                    </Case>
                    <Case when={!!plannedAmount}>
                      <CheckCircleOutline x={`${-CHECK_SIZE_PX / 2}`} y="30" size={CHECK_SIZE_PX} />
                    </Case>
                    <Case default>
                      <circle cx="0" cy="42" r={`${DOT_SIZE_PX / 2}`} fill={theme.color.gray} />
                    </Case>
                  </Switch>
                </g>
              );
            },
          }}
          tooltipComponent={({ active, payload }) => {
            const data = payload?.[0]?.payload;
            if (!data) {
              return null;
            }

            const { month, sumCredit, sumDebit, sum } = data;

            return (
              <ChartTooltip active={active} header={isoDateToMonthAndYear(month)}>
                <ChartTooltipDataRow
                  label="Contributions"
                  value={<Currency value={sumCredit} />}
                  dotFillColor={theme.color.green}
                />
                <ChartTooltipDataRow
                  label="Deductions"
                  value={<Currency value={sumDebit} />}
                  dotFillColor={theme.color.red}
                />
                <ChartTooltipDataRow
                  label="Total"
                  value={<Currency value={sum} />}
                  strokeColor={theme.color.gray}
                />
              </ChartTooltip>
            );
          }}
        />
      </Container>
    </Root>
  );
};

GoalContributionGraph.fragments = {
  WebGoalContributionGraphFields: gql`
    fragment WebGoalContributionGraphFields on GoalV2 {
      id
      plannedMonthlyContribution
      monthlyContributionSummaries {
        month
        sum
        sumCredit
        sumDebit
        plannedAmount
      }
    }
  `,
};

export default GoalContributionGraph;
