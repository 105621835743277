import * as RA from 'ramda-adjunct';

import { getAmountWithSign, getInitialType } from 'common/lib/recurring';

import type { RecurringTransactionStreamType } from 'common/constants/recurringTransactions';

import type { Mobile_GetEditMerchant_merchant_recurringTransactionStream } from 'common/generated/graphQlTypes/Mobile_GetEditMerchant';
import type { UpdateMerchantInput } from 'common/generated/graphQlTypes/globalTypes';

export type FormValues = UpdateMerchantInput['recurrence'] & {
  name: string;
  merchantId: string;
  type?: RecurringTransactionStreamType;
};

export const getEditMerchantInitialValues = (
  merchantId: string,
  name: string | undefined | null,
  stream: Mobile_GetEditMerchant_merchant_recurringTransactionStream | undefined | null,
) => ({
  merchantId,
  name: name ?? '',
  isRecurring: RA.isNotNil(stream),
  frequency: stream?.frequency,
  amount: stream?.amount,
  baseDate: stream?.baseDate,
  type: getInitialType(stream?.amount),
  isActive: stream?.isActive ?? true,
});

export const transformFormValuesToInput = (values: FormValues): UpdateMerchantInput => {
  const { isRecurring, frequency, baseDate, amount, type, isActive, ...rest } = values;
  return {
    ...rest,
    recurrence: {
      isRecurring,
      frequency,
      baseDate,
      amount: getAmountWithSign(amount, type!),
      isActive,
    },
  };
};
