import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AnimatedProgressSteps from 'components/accounts/AnimatedProgressSteps';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';

import { setCreatedByType } from 'actions';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

const StyledAnimatedProgressSteps = styled(AnimatedProgressSteps)`
  margin: ${({ theme }) => theme.spacing.xxxxlarge};
`;

const StyledModalCard = styled(ModalCard)`
  min-height: 443px;
`;

const GoalsLoading = () => {
  const { push } = useHistory();
  const { LOADING_STEPS } = COPY.GOALS;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCreatedByType({})); // clear selected goals since this runs at the end of the creation flow
  }, [dispatch]);

  const onAnimationEnd = () => {
    push(routes.goalsV2());
  };

  return (
    <StyledModalCard>
      <FlexContainer center full>
        <StyledAnimatedProgressSteps onAnimationEnd={onAnimationEnd} steps={LOADING_STEPS} />
      </FlexContainer>
    </StyledModalCard>
  );
};

export default GoalsLoading;
