import React from 'react';

import PreTaxBudgetGoalsList from 'components/goalsV2/PreTaxBudgetGoalsList';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { useModalContext } from 'lib/contexts/ModalContext';

import * as COPY from 'common/constants/copy';

const PreTaxBudgetGoalsModal = () => {
  const { close } = useModalContext();

  return (
    <ModalCard
      title={COPY.GOALS.PRE_TAX_BUDGETS.TITLE}
      description={COPY.GOALS.PRE_TAX_BUDGETS.SUBTITLE}
      hideBottomBorder
    >
      <FlexContainer full>
        <PreTaxBudgetGoalsList />
      </FlexContainer>

      <CardFooter>
        <PrimaryButton onClick={close}>Done</PrimaryButton>
      </CardFooter>
    </ModalCard>
  );
};

export default PreTaxBudgetGoalsModal;
