import * as R from 'ramda';

type MaybeList<T> = T[] | null | undefined;

/**
 * Merges the next page of a relay-style edges/node response
 */
export const mergeNextRelayPage =
  <T extends { node: { id: string } | null }, P extends { edges: (T | null)[] | null }>(
    next: P | null | undefined,
  ) =>
  (previous: P | null) =>
    ({
      ...(previous ?? {}),
      ...(next ?? {}),
      edges: R.uniqBy(
        (edge) => edge?.node?.id,
        [...(previous?.edges ?? []), ...(next?.edges ?? [])],
      ),
    }) as P;

/** Merge next page of items, removing duplicates by ID */
export const mergeNextPage =
  <ItemT extends { id: string }>(next: MaybeList<ItemT>, idGetter?: (item: ItemT) => string) =>
  (previous: MaybeList<ItemT>): ItemT[] =>
    R.uniqBy(idGetter ?? R.prop('id'), [...(previous ?? []), ...(next ?? [])]);

/** Use a custom ID getter to merge next pages, removing duplicates */
export const customMergeNextPage =
  <ItemT>(next: MaybeList<ItemT>, idGetter: (item: ItemT) => string) =>
  (previous: MaybeList<ItemT>): ItemT[] =>
    R.uniqBy(idGetter, [...(previous ?? []), ...(next ?? [])]);
