import { DateTime } from 'luxon';
import * as R from 'ramda';

import type { Timeframe } from 'common/constants/timeframes';

import type {
  AggregateData,
  TimeframeGroup,
  TimeframeData,
  BaseTimeframeData,
} from 'common/types/aggregates';

const getDataFromTimeframe = <TData extends AggregateData>(
  data: TData,
  timeframe: Timeframe,
): TimeframeGroup[] => {
  switch (timeframe) {
    case 'year':
      return data.byYear;
    case 'month':
      return data.byMonth;
    case 'quarter':
      return data.byQuarter;
    default:
      throw new Error(`${timeframe} is not a valid timeframe.`);
  }
};

export const getDefaultDateForTimeframe = (timeframe: Timeframe) =>
  DateTime.local().startOf(timeframe).toISODate();

export const getDateForTimeFrame = (timeframe: Timeframe, date: string) =>
  DateTime.fromISO(date).startOf(timeframe).toISODate();

const defaultTimeframeFormatter = (
  timeframe: Timeframe,
  { groupBy, summary }: TimeframeGroup,
): TimeframeData => ({
  startDate: R.path([timeframe], groupBy) ?? '',
  expense: { amount: -(summary.sumExpense ?? 0) },
  income: { amount: summary.sumIncome ?? 0 },
  savings: { amount: summary.savings ?? 0 },
  savingsRate: { amount: summary.savingsRate ?? 0 },
});

export const aggregatesDataAdapter = <
  TData extends AggregateData,
  TResult extends BaseTimeframeData,
>(
  data: TData | null | undefined,
  timeframe: Timeframe,
  formatter?: (timeframe: Timeframe, data: TimeframeGroup) => TResult,
) => {
  if (!data) {
    return [];
  }

  const formatterFn = formatter ?? defaultTimeframeFormatter;
  const dataFromTimeframe = getDataFromTimeframe(data, timeframe);
  const sorted = R.sortBy(
    (group) => R.path(['groupBy', timeframe], group) ?? '',
    dataFromTimeframe,
  );
  return sorted.map((group) => formatterFn(timeframe, group) as TResult);
};

export const maxAmountByType = (values: TimeframeData[], type: 'income' | 'expense') =>
  R.reduce(R.max, 0, R.map(R.path([type, 'amount']), values)) as number;

export const getDateRangeForTimeframe = <TData extends BaseTimeframeData>(
  timeframe: Timeframe,
  data: TData[],
  activeDate: string,
) => {
  const activeTimeframe = data.find(({ startDate }) => startDate === activeDate);
  const startDate = activeTimeframe
    ? DateTime.fromISO(activeTimeframe.startDate)
    : DateTime.local().startOf(timeframe);
  const endDate = startDate.endOf(timeframe);
  return { startDate: startDate.toISODate(), endDate: endDate.toISODate() } as const;
};

export const formatFilteredChartDataPoint = <TData extends TimeframeGroup>(
  timeframe: Timeframe,
  data: TData,
  isExpense?: boolean,
): FilteredChartDataPoint => {
  const startDate = R.path<string>([timeframe], data.groupBy);
  if (!startDate) {
    throw new Error('Missing start date');
  }

  const { sum, count, avg } = data.summary ?? {};

  // sum is inverted for the chart
  return { startDate, sum: isExpense ? -(sum ?? 0) : sum ?? 0, count: count || 0, avg: avg || 0 };
};

export type FilteredChartDataPoint = {
  startDate: string;
  sum: number;
  avg: number;
  count: number;
};
