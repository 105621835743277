import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Avatar from 'components/lib/ui/Avatar';
import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import { OverlayTrigger } from 'components/lib/ui/popover';

import { setUiThemePreference } from 'actions';
import { color, radius, spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import typewriter from 'lib/analytics/typewriter';
import { useDispatch } from 'lib/hooks';
import useTheme from 'lib/hooks/useTheme';
import { getActAsUser } from 'state/multiHousehold/selectors';

import { WHATS_NEW_URL } from 'common/constants/externalUrls';
import { SIDEBAR_COLLAPSED_CLASS_NAME } from 'constants/classNames';
import routes from 'constants/routes';

import type { UiTheme } from 'common/types';

const Root = styled.div<{ $highlight: boolean; $isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${isV2Theme(spacing.xsmall, 0)} ${isV2Theme(spacing.xsmall, spacing.default)};
  flex: 1;
  cursor: pointer;
  user-select: none;
  background: ${({ $highlight }) => $highlight && 'red'};
  transition: ${({ theme }) => theme.transition.default};
  border-radius: ${isV2Theme(radius.small, 0)};
  color: ${isV2Theme(color.textLight, color.textWhite)};

  :hover {
    background: ${isV2Theme(color.gray4, color.navyLight)};
    color: ${isV2Theme(color.text, color.textWhite)};
  }
  :active {
    background-color: ${isV2Theme(color.gray5, color.navyDark)};
  }
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      && {
        background-color: ${isV2Theme(color.gray5, color.navyDark)};
      }
    `};
`;

const LinkIcon = styled(FlexContainer).attrs({ center: true })`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledIcon = styled(Icon)<{ $rotate: boolean }>`
  opacity: 0.7;
  color: ${isV2Theme(color.text, color.textWhite)};

  transition: all 0.5s cubic-bezier(0.176, 0.88, 0.32, 1.48);
  transform: ${({ $rotate }) => ($rotate ? 'rotate(180deg)' : 'rotate(0)')};

  .${SIDEBAR_COLLAPSED_CLASS_NAME} & {
    display: none;
  }

  ${Root}:hover & {
    opacity: 1;
  }
`;

const UserName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-right: ${({ theme }) => theme.spacing.xsmall};

  .${SIDEBAR_COLLAPSED_CLASS_NAME} & {
    display: none;
  }
`;

type Props = {
  userName: string;
  profilePictureUrl: Maybe<string>;
  hideSettingsButton: boolean;
};

const UserMenu = ({ userName, profilePictureUrl, hideSettingsButton }: Props) => {
  const dispatch = useDispatch();

  const actAsUser = useSelector(getActAsUser);

  const { uiTheme } = useTheme();

  return (
    <OverlayTrigger
      offsetDistance={10}
      placement="top-start"
      overlay={
        <DropdownMenu>
          <DropdownMenuItem
            icon={uiTheme === 'dark' ? 'sun' : 'moon'}
            onClick={() => {
              const preference: UiTheme = uiTheme === 'dark' ? 'light' : 'dark';
              dispatch(setUiThemePreference(preference));
              typewriter.visualAppearancePreferenceUpdated({ preference });
            }}
          >
            {uiTheme === 'dark' ? 'Light mode' : 'Dark mode'}
          </DropdownMenuItem>
          <DropdownMenuItem icon="zap" onClick={() => window.open(WHATS_NEW_URL, '_blank')}>
            What&apos;s new
          </DropdownMenuItem>
          {!hideSettingsButton && (
            <DropdownMenuItem icon="settings" linkTo={routes.settings.profile()}>
              Settings
            </DropdownMenuItem>
          )}
          {actAsUser ? (
            <DropdownMenuItem icon="log-out" linkTo={routes.actAsUser({})}>
              Exit
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem icon="log-out" linkTo={routes.logout()} type="danger">
              Sign out
            </DropdownMenuItem>
          )}
        </DropdownMenu>
      }
    >
      {({ toggleOpen, isOpen }) => (
        <Root onClick={toggleOpen} $highlight={!!actAsUser} $isOpen={isOpen}>
          <LinkIcon>
            <Avatar $url={profilePictureUrl} {...sensitiveClassProps} />
          </LinkIcon>
          <UserName {...sensitiveClassProps}>{userName}</UserName>
          <StyledIcon name="chevron-down" size={16} $rotate={isOpen} />
        </Root>
      )}
    </OverlayTrigger>
  );
};

export default UserMenu;
