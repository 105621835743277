declare global {
  interface Window {
    ga: any;
  }
}

// Return the Google Analytics client id.
// The "proper" way to do this is:
// ga(function(tracker) {
//     var clientId = tracker.get('clientId');
//   });
// as per https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id#getting_the_client_id_from_the_cookie
// But if ga is already loaded, this doesn't require callbacks, etc.
export const getGoogleAnalyticsClientId = () => {
  if (window.ga?.getAll && typeof window.ga?.getAll === 'function') {
    return window.ga?.getAll?.()?.[0]?.get('clientId');
  } else {
    return undefined;
  }
};
