import { createSelector } from 'reselect';

import type { FilterState } from 'state/filters/types';
import type { RootState } from 'state/types';

const getFilterState = ({ filters }: RootState) => filters;

export const getFilterForKey = createSelector(
  getFilterState,
  (filters) => (key: keyof FilterState) => filters[key],
);
