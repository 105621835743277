import React from 'react';
import styled, { css } from 'styled-components';

import { useDraggableContext } from 'components/lib/dnd/DraggableContext';
import Card from 'components/lib/ui/Card';
import CardTitle from 'components/lib/ui/CardTitle';
import DashboardWidgetAmountHeader, {
  StyledTimeframeTrendIndicator,
} from 'components/lib/ui/DashboardWidgetAmountHeader';
import DragDots from 'components/lib/ui/DragDots';
import Drawer from 'components/lib/ui/Drawer';
import ExpandMoreIconButton from 'components/lib/ui/ExpandMoreIconButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Currency from 'components/lib/ui/currency/Currency';

import { getCurrentTimeframeText } from 'common/lib/accounts/netWorthCharts';
import { color, fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import variables from 'common/lib/theme/variables';
import useIsV2Theme from 'lib/hooks/useIsV2Theme';
import usePersistentFilter from 'lib/hooks/usePersistentFilter';

const Root = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.gutter};
`;

const CustomCard = styled(Card).attrs({ hideHeader: true })<{
  $isDraggingThis: boolean;
  $isDragActive: boolean;
}>`
  --shadow-color: ${variables.color.shadow.card};

  transition: ${({ theme }) => theme.transition.default};
  opacity: ${({ $isDraggingThis, $isDragActive }) => ($isDragActive && !$isDraggingThis ? 0.7 : 1)};
  ${({ $isDraggingThis }) =>
    $isDraggingThis &&
    css`
      box-shadow: 0px 0px 0px 2px ${color.blue};
    `};
`;

const Dots = styled(DragDots)`
  margin-left: ${isV2Theme(0, spacing.small)};
  margin-right: ${isV2Theme(spacing.xxxsmall, 0)};
  transition: ${({ theme }) => theme.transition.default};
  opacity: 0;
`;

const Header = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })<{
  $bottomBorder: boolean;
}>`
  gap: ${isV2Theme(spacing.xxsmall, spacing.small)};

  ${({ $bottomBorder }) =>
    $bottomBorder &&
    css`
      border-bottom: 1px solid ${color.grayBackground};
    `}

  &:hover ${Dots}, &:active ${Dots} {
    opacity: 1;
  }
`;

const Content = styled(FlexContainer).attrs({ column: true })`
  flex-grow: 1;
  padding: ${spacing.default} ${isV2Theme(spacing.large, spacing.xlarge)} ${spacing.default} 0;

  ${isV2Theme(css<{ $column?: boolean }>`
    display: flex;
    gap: ${spacing.xsmall};
    flex-direction: row;
    align-items: center;
  `)}
`;

const StyledDashboardWidgetAmountHeader = styled(DashboardWidgetAmountHeader)`
  margin-top: 0;
`;

const ColumnWhenSmall = styled.div`
  flex: 1;
  display: flex;
  gap: ${spacing.xxxsmall};
  flex-direction: column;
  align-items: stretch;

  @media (min-width: ${({ theme }) => theme.breakPoints.lg}px) {
    flex-direction: row;
    align-items: center;
    gap: ${spacing.xsmall};
  }
`;

const ReStyledTimeframeTrendIndicator = styled(StyledTimeframeTrendIndicator)`
  margin-top: 0;
  margin-left: 0;
  flex: 1;
`;

const StyledCurrency = styled(Currency)`
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.medium};
`;

type Props = {
  draggable: boolean;
  isAsset: boolean;
  currentAmount: number;
  startAmount: number;
  title: string;
  isDraggingThis: boolean;
  isDragActive: boolean;
  onToggleCollapsed: () => void;
  isCollapsed?: boolean;
};

const AccountGroupCard = ({
  children,
  draggable,
  isAsset,
  title,
  currentAmount,
  startAmount,
  isDraggingThis,
  isDragActive,
  onToggleCollapsed,
  isCollapsed,
}: React.PropsWithChildren<Props>) => {
  const isV2ThemeEnabled = useIsV2Theme();
  const { dragHandleProps } = useDraggableContext();
  const {
    activeFilters: { timeframe, dateRange, chartType },
  } = usePersistentFilter('accounts');

  const timeframeText = getCurrentTimeframeText(timeframe, dateRange, chartType);

  return (
    <Root>
      <CustomCard $isDragActive={isDragActive} $isDraggingThis={isDraggingThis}>
        <Header $bottomBorder={!isCollapsed} {...dragHandleProps}>
          {draggable && <Dots />}
          {isV2ThemeEnabled && (
            <ExpandMoreIconButton expanded={!isCollapsed} onClick={onToggleCollapsed} />
          )}
          <Content>
            {isV2ThemeEnabled ? (
              <>
                <ColumnWhenSmall>
                  <CardTitle $largeWhenV2Enabled>{title}</CardTitle>
                  <ReStyledTimeframeTrendIndicator
                    startAmount={startAmount}
                    endAmount={currentAmount}
                    timeframeDisplay={timeframeText}
                    isAsset={isAsset}
                  />
                </ColumnWhenSmall>
                <StyledCurrency value={currentAmount} />
              </>
            ) : (
              <>
                <CardTitle $largeWhenV2Enabled>{title}</CardTitle>
                <StyledDashboardWidgetAmountHeader
                  currentAmount={currentAmount}
                  startAmount={startAmount}
                  timeframeText={timeframeText}
                  isAsset={isAsset}
                />
              </>
            )}
          </Content>
        </Header>
        <Drawer open={!isCollapsed || !isV2ThemeEnabled} shouldAnimateOnMount={false}>
          {children}
        </Drawer>
      </CustomCard>
    </Root>
  );
};

export default AccountGroupCard;
