import { rgba } from 'polished';
import React, { useState, useEffect } from 'react';
import { FiCheck } from 'react-icons/fi';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';

const DEFAULT_HEIGHT_PX = 136;
const LINE_HEIGHT_PX = 24;
const STEP_HEIGHT_PX = 55;
const STEP_ANIMATION_DURATION_MS = 250;
const STEP_DELAY_MS = 1000;

const Root = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  overflow: hidden;
  position: relative;
`;

const Gradient = styled.div<{ height: number }>`
  position: absolute;
  top: 0;
  height: ${({ height }) => height}px;
  background: ${({ theme }) =>
    `linear-gradient(${theme.color.white}, ${rgba(theme.color.white, 0)} 50%, ${
      theme.color.white
    } 100%)`};
  width: 100%;
`;

const CheckIcon = styled(FiCheck)`
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme.color.textWhite};
`;

const CheckContainer = styled(Flex).attrs({ center: true })`
  border-radius: ${({ theme }) => theme.radius.round};
  background-color: ${({ theme }) => theme.color.green};
  width: 24px;
  height: ${LINE_HEIGHT_PX}px;
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const UncheckedContainer = styled(CheckContainer)`
  background-color: ${({ theme }) => theme.color.grayFocus};
`;

const HeaderText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const StepItem = styled(Flex).attrs({ alignCenter: true })`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const StepsContainer = styled.div<{ offset: number }>`
  display: flex;
  flex-direction: column;
  transition: transform ${STEP_ANIMATION_DURATION_MS}ms ease-out;
  transform: translateY(${({ offset }) => offset}px);
`;

type Props = {
  className?: string;
  steps: string[];
  height?: number;
  stepAnimationDurationMs?: number;
  onAnimationEnd?: () => void;
};

const AnimatedProgressSteps = ({
  className,
  steps,
  height = DEFAULT_HEIGHT_PX,
  stepAnimationDurationMs = STEP_ANIMATION_DURATION_MS,
  onAnimationEnd,
}: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const midpoint = height / 2 - STEP_HEIGHT_PX / 2;
  const offset = midpoint - currentIndex * STEP_HEIGHT_PX;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => Math.min(steps.length - 1, prev + 1));
    }, STEP_DELAY_MS + stepAnimationDurationMs);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (currentIndex === steps.length - 1 && onAnimationEnd) {
      setTimeout(onAnimationEnd, stepAnimationDurationMs);
    }
  }, [currentIndex, steps, onAnimationEnd]);

  return (
    <Root className={className} height={height}>
      <StepsContainer offset={offset}>
        {steps.map((step, index) => (
          <StepItem key={step}>
            {index > currentIndex ? (
              <UncheckedContainer />
            ) : (
              <CheckContainer>
                <CheckIcon />
              </CheckContainer>
            )}
            <HeaderText>{step}</HeaderText>
          </StepItem>
        ))}
      </StepsContainer>
      <Gradient height={height} />
    </Root>
  );
};

export default AnimatedProgressSteps;
