import React from 'react';
import styled from 'styled-components';

import DragDots from 'components/lib/ui/DragDots';

type Props = {
  className?: string;
};

const HandleContainer = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: grab;
  opacity: 0.6;
  transition: ${({ theme }) => theme.transition.default};

  &:active {
    cursor: grabbing;
  }

  &:hover {
    opacity: 1;
  }
`;

const DragHandle = ({ className }: Props) => (
  <HandleContainer role="button" className={className}>
    <DragDots />
  </HandleContainer>
);

export default DragHandle;
