import React from 'react';
import Helmet from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import SelectField from 'components/lib/form/SelectField';
import SettingsCard from 'components/lib/layouts/SettingsCard';
import Column from 'components/lib/ui/Column';

import { setUiThemePreference } from 'actions';
import typewriter from 'lib/analytics/typewriter';
import { getUiThemePreference } from 'selectors';

import type { UiThemePreference } from 'common/types';

const OPTIONS: { value: UiThemePreference; label: string }[] = [
  { value: 'light', label: 'Light' },
  { value: 'dark', label: 'Dark' },
  { value: 'system', label: 'System preference' },
];

const FormContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const PreferenceSettings = () => {
  const dispatch = useDispatch();
  const uiThemePreference = useSelector(getUiThemePreference);

  return (
    <Column md={9}>
      <SettingsCard title="Display">
        <Helmet>
          <title>Display Settings</title>
        </Helmet>
        <FormContainer>
          <Form
            initialValues={{ visualAppearance: uiThemePreference }}
            onSubmit={({ visualAppearance }) => {
              dispatch(setUiThemePreference(visualAppearance));
              typewriter.visualAppearancePreferenceUpdated({ preference: visualAppearance });
            }}
            submitOnChange
            submitOnChangeDebounceMs={0}
          >
            <SelectField name="visualAppearance" options={OPTIONS} />
          </Form>
        </FormContainer>
      </SettingsCard>
    </Column>
  );
};

export default PreferenceSettings;
