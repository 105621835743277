import type { PaymentMethod } from '@stripe/stripe-js';
import { capitalCase } from 'change-case';
import * as RA from 'ramda-adjunct';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import StripeCardInputForm from 'components/lib/external/StripeCardInputForm';
import StripeProvider from 'components/lib/external/StripeProvider';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';
import UpgradeFormPlanOption from 'components/premium/UpgradeFormPlanOption';

import { getYearlyBannerText } from 'common/lib/billing/Billing';

import routes from 'constants/routes';

import {
  PaymentPeriod,
  SubscriptionDetailsPaymentSource,
} from 'common/generated/graphQlTypes/globalTypes';
import type { Web_GetSubscriptionModalQuery } from 'common/generated/graphql';

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.large};
`;

const Footer = styled(FlexContainer)`
  margin-top: ${({ theme }) => theme.spacing.large};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const SubmitButton = styled(AsyncButton)`
  ${primaryButtonMixin}
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const SubscriptionSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subtitle = styled.p`
  margin: 0;
  padding: 0 ${({ theme }) => theme.spacing.large};
  color: ${({ theme }) => theme.color.text};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  line-height: 150%;
`;

const StyledPlanOption = styled(UpgradeFormPlanOption)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const BilledThroughText = styled(Text)`
  color: ${({ theme }) => theme.color.textLight};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  hasPremiumEntitlement: boolean;
  willCancelAtPeriodEnd: boolean;
  plans: Web_GetSubscriptionModalQuery['subscriptionOffering']['plans'][0][];
  promoCodeDescription: string | undefined | null;
  promoCodeDuration: string | undefined | null;
  promoCodeDurationInMonths: number | undefined | null;
  hasPaymentMethod: boolean;
  paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
  setLoading: () => void;
  setNotLoading: () => void;
  appliedPromoCode: string | null;
  changeSubscription: (stripePriceId: string, stripePromoCode: string | null) => Promise<void>;
  createSubscription: (
    paymentMethod: PaymentMethod | null,
    stripePriceId: string,
    stripePromoCode: string | null,
  ) => Promise<void>;
  reactivateSubscription: (
    paymentMethod: PaymentMethod | null,
    stripePriceId: string,
    stripePromoCode: string | null,
  ) => Promise<void>;
  hasStripeSubscriptionId: boolean;
  paymentMethod: Web_GetSubscriptionModalQuery['subscription']['paymentMethod'] | null;
  onDone: () => void;
  isLoading: boolean;
};

// TODO: This is pretty much duplicating the SubscriptionModalBody file. It's used as an A/B test for the annual upsell.
// Refactor it if the A/B test shows good results.

const SubscriptionModalAnnualUpsellBody = ({
  hasPremiumEntitlement,
  willCancelAtPeriodEnd,
  plans,
  promoCodeDescription,
  promoCodeDuration,
  promoCodeDurationInMonths,
  hasPaymentMethod,
  paymentMethod,
  paymentSource,
  isLoading,
  setLoading,
  setNotLoading,
  appliedPromoCode,
  changeSubscription,
  createSubscription,
  reactivateSubscription,
  hasStripeSubscriptionId,
  onDone,
}: Props) => {
  const history = useHistory();
  const selectedPlan = plans.find((plan) => plan.period === PaymentPeriod.YEARLY);

  const handleStripeCardInputFormSuccess = async (paymentInfo: PaymentMethod | null) => {
    if (selectedPlan) {
      const paymentMethodPresent = hasPaymentMethod || RA.isNotNil(paymentInfo);
      setLoading();
      try {
        const { stripeId: priceId } = selectedPlan;
        const maybePromoCode = promoCodeDescription ? appliedPromoCode : null;

        if (!hasPremiumEntitlement) {
          // redirects the user to the billing page if they don't have premium entitlement
          history.push(routes.settings.billing.subscribe());
        } else if (willCancelAtPeriodEnd) {
          if (paymentMethodPresent && hasStripeSubscriptionId) {
            await reactivateSubscription(paymentInfo, priceId, maybePromoCode);
          } else {
            if (!paymentMethodPresent && selectedPlan?.requirePaymentMethod) {
              throw Error('No card entered! Something is wrong with the given subscription');
            }
            await createSubscription(paymentInfo, priceId, maybePromoCode);
          }
        } else {
          await changeSubscription(priceId, maybePromoCode);
        }
      } finally {
        setNotLoading();
      }

      onDone();
    }
  };

  const billedThroughText =
    paymentSource === SubscriptionDetailsPaymentSource.STRIPE
      ? `Subscribed with ${capitalCase(paymentMethod?.brand || '')} ending in ${
          paymentMethod?.lastFour
        }`
      : paymentSource || '';

  return (
    <>
      <Subtitle>
        Your monthly fee will be adjusted based on your unused time, which will be applied to your
        annual subscription.
      </Subtitle>
      <CardBody>
        <SubscriptionSection>
          {plans.map(({ pricePerPeriodDollars, discountedPricePerPeriodDollars, period }, i) => (
            <StyledPlanOption
              key={i}
              price={pricePerPeriodDollars}
              discountedPrice={discountedPricePerPeriodDollars}
              duration={promoCodeDuration}
              durationInMonths={promoCodeDurationInMonths}
              period={period}
              isSelected={period === PaymentPeriod.YEARLY}
              promoBadgeText={getYearlyBannerText(plans, period)}
              disabled={period === PaymentPeriod.MONTHLY}
            />
          ))}
        </SubscriptionSection>
        <StripeCardInputForm skipPaymentMethod onSuccess={handleStripeCardInputFormSuccess}>
          {({ isValid, isLoading: isStripeInputLoading }) => (
            <>
              <Footer column>
                <SubmitButton
                  size="large"
                  disabled={!isValid}
                  type="submit"
                  pending={isStripeInputLoading || isLoading}
                >
                  {`Switch to yearly`}
                </SubmitButton>
                <BilledThroughText>{billedThroughText}</BilledThroughText>
              </Footer>
            </>
          )}
        </StripeCardInputForm>
      </CardBody>
    </>
  );
};

const ProviderWrapper = (props: Props) => (
  <StripeProvider>
    <SubscriptionModalAnnualUpsellBody {...props} />
  </StripeProvider>
);

export default ProviderWrapper;
