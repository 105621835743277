import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCancelButton from 'components/lib/ui/ModalCancelButton';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import ManualLink from 'components/lib/ui/link/ManualLink';

import getUserApi from 'common/lib/api/user';
import api from 'lib/api';
import toast from 'lib/ui/toast';

const FormContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledText = styled(Text)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing.default};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyledLink = styled(ManualLink)`
  font-weight: ${({ theme }) => theme.fontWeight.book};
`;

type Props = {
  newEmail: string;
  next: () => void;
};

const UpdateEmailVerificationModal = ({ newEmail, next }: Props) => {
  const handleResendClick = async () => {
    await getUserApi(api).resendEmailUpdateVerification();
    toast({
      title: 'Verify your email',
      description: `A new verification code was sent to ${newEmail}`,
      dismissable: true,
    });
  };

  return (
    <ModalCard title="Verify your email">
      <Form
        initialValues={{ code: '' }}
        onSubmit={async ({ code }) => {
          await getUserApi(api).verifyNewEmail({ newEmail, code });
          next();
        }}
      >
        <FormContainer>
          <StyledText>We just sent a 6-digit code to {newEmail}, enter it below:</StyledText>
          <TextField name="code" label="Code" required autoComplete="code" placeholder="123456" />
          <StyledText>
            {`Don't see a code?`}{' '}
            <StyledLink onClick={handleResendClick}>{`Resend to email.`}</StyledLink>
          </StyledText>
        </FormContainer>
        <CardFooter>
          <ModalCancelButton />
          <FormSubmitButton size="small">Save</FormSubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

export default UpdateEmailVerificationModal;
