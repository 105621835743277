import type { QueryHookOptions } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { GET_CATEGORIES } from 'common/lib/graphQl/categories';
import useQuery from 'common/lib/hooks/useQuery';

import type { GetCategoriesQuery, GetCategoriesQueryVariables } from 'common/generated/graphql';

/**
 * Hook used to get a category on demand.
 *
 * We use the GET_CATEGORIES query so we only have to load once and just find by id on the client side.
 */
const useGetCategory = (
  categoryId?: string,
  options?: QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>,
) => {
  const { data, isLoadingInitialData } = useQuery(GET_CATEGORIES, {
    fetchPolicy: 'cache-first',
    ...options,
  });
  const { categories = [] } = data ?? {};

  const getCategory = useCallback(
    (categoryId: string) => categories.find(({ id }) => id === categoryId),
    [categories],
  );

  const category = useMemo(
    () => (categoryId ? getCategory(categoryId) : undefined),
    [categoryId, getCategory],
  );

  return {
    getCategory,
    isLoadingInitialData,
    category,
  };
};

export default useGetCategory;
