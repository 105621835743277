import { gql } from '@apollo/client';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

export const HOUSEHOLD_FIELDS_FRAGMENT = gql`
  fragment HouseholdFields on Household {
    address
    city
    id
    name
    state
    zipCode
  }
`;

export const GET_MY_HOUSEHOLD = gql`
  query GetMyHousehold {
    myHousehold {
      ...HouseholdFields
    }
  }
  ${HOUSEHOLD_FIELDS_FRAGMENT}
`;

export const UPDATE_MY_HOUSEHOLD = gql`
  mutation Common_UpdateMyHousehold($input: UpdateMyHouseholdInput!) {
    updateMyHousehold(input: $input) {
      household {
        ...HouseholdFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${HOUSEHOLD_FIELDS_FRAGMENT}
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const DELETE_HOUSEHOLD_MUTATION = gql`
  mutation Common_DeleteHousehold($input: DeleteHouseholdMutationInput!) {
    deleteHousehold(input: $input) {
      deleted
      errors {
        message
      }
    }
  }
`;
