import React from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

export const OptionPill = styled(FlexContainer).attrs({ center: true })<{ $active: boolean }>`
  height: 34px;
  padding: 0 ${({ theme }) => theme.spacing.xsmall};
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;
  letter-spacing: 0.08em;
  cursor: pointer;

  /* Make pill shaped https://stackoverflow.com/a/18795153 */
  border-radius: 500px;

  color: ${({ theme }) => theme.color.textLight};
  transition: ${({ theme }) => theme.transition.default};

  :hover {
    color: ${({ theme }) => theme.color.text};
  }

  ${({ $active }) =>
    $active &&
    css`
      background: ${({ theme }) => theme.color.grayBackground};
      color: ${({ theme }) => theme.color.text};
    `}
`;

const StyledIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

type Option<ValueT> = {
  title: string;
  value: ValueT;
  icon?: string;
};

type Props<ValueT> = {
  options: Option<ValueT>[];
  value: ValueT;
  onChange: (value: ValueT) => void;
  className?: string;
};

const SegmentedControl = <ValueT = any,>({
  options,
  value,
  onChange,
  className,
}: Props<ValueT>) => (
  <FlexContainer alignCenter className={className}>
    {options.map(({ title, icon, value: optionValue }) => (
      <OptionPill key={title} $active={optionValue === value} onClick={() => onChange(optionValue)}>
        {!!icon && <StyledIcon name={icon} />}
        <span>{title}</span>
      </OptionPill>
    ))}
  </FlexContainer>
);

export default SegmentedControl;
