import * as RA from 'ramda-adjunct';
import React, { useState } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import { Label } from 'components/lib/form/FormItemContainer';
import TextInput from 'components/lib/form/TextInput';
import CardEmpty from 'components/lib/ui/CardEmpty';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import SecuritySearchListItemSkeleton from 'components/securities/SecuritySearchListItemSkeleton';

import { useStackContext } from 'common/lib/contexts/StackContext';
import { SEARCH_VEHICLES_QUERY } from 'common/lib/graphQl/vehicles';
import useDebounce from 'common/lib/hooks/useDebounce';
import useQuery from 'common/lib/hooks/useQuery';
import { formatCurrency } from 'common/utils/Currency';
import HighlightTextContext from 'lib/contexts/HighlightTextContext';

import { AccountTypeName } from 'common/constants/accounts';
import * as COPY from 'common/constants/copy';
import { TYPEAHEAD_DEBOUNCE_TIME_MS } from 'common/constants/form';

import type { VehiclesSearchQuery } from 'common/generated/graphql';

import AddManualAccountFlow from './AddManualAccountFlow';

const Root = styled(FlexContainer)`
  flex-direction: column;
`;

const SearchInputRow = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: 0;
  > * + * {
    margin-top: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const SearchInput = styled(TextInput)`
  width: 100%;
  margin-right: ${({ theme }) => theme.spacing.medium};
`;

const StyledEmpty = styled(CardEmpty)`
  padding-top: ${({ theme }) => theme.spacing.xxlarge};
  padding-bottom: ${({ theme }) => theme.spacing.xxlarge};
  margin: ${({ theme }) => theme.spacing.xlarge} auto ${({ theme }) => theme.spacing.xxxlarge};
`;

const StyledLoadingItem = styled(SecuritySearchListItemSkeleton)`
  padding: ${({ theme }) => theme.spacing.small} 0 0 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.small};
  padding: ${({ theme }) => theme.spacing.xsmall} 0;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SEARCH_VEHICLES_QUERY_LIMIT = 5;

type Props = {
  title?: string;
  onVehicleClick: (vehicle: { vin?: string; name?: string; value?: number }) => void;
};

const VehicleSearchForm = ({ title = 'Add vehicle', onVehicleClick }: Props) => {
  const { pop, replace, onComplete } = useStackContext();
  const [search, setSearch] = useState('');
  const [previousSearch, setPreviousSearch] = useState('');
  const [vehicles, setVehicles] = useState<any[] | null>(null);
  const [isLoadingVehicles, setIsLoadingVehicles] = useState(false);
  const [shouldShowNoResultsFound, setShouldShowNoResultsFound] = useState(false);

  const debouncedSearch = useDebounce(search, TYPEAHEAD_DEBOUNCE_TIME_MS);

  const { refetch } = useQuery<VehiclesSearchQuery>(SEARCH_VEHICLES_QUERY, {
    skip: true,
    variables: {
      search: debouncedSearch,
      limit: SEARCH_VEHICLES_QUERY_LIMIT,
    },
  });

  return (
    <Root>
      <SearchInputContainer>
        <Label>VIN Number</Label>
        <SearchInputRow>
          <SearchInput
            name="vin"
            value={search}
            placeholder="Enter your VIN number..."
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              if (RA.isNotNilOrEmpty(vehicles) && shouldShowNoResultsFound) {
                setShouldShowNoResultsFound(false);
              }
              setSearch(target.value);
            }}
            autoFocus
          />
          <DefaultButton
            onClick={() => {
              setIsLoadingVehicles(true);
              setPreviousSearch(search);
              refetch({ search: debouncedSearch, limit: SEARCH_VEHICLES_QUERY_LIMIT }).then(
                (resp) => {
                  if (RA.isNilOrEmpty(resp.data?.vehicles)) {
                    setShouldShowNoResultsFound(true);
                  }
                  setVehicles(resp.data?.vehicles);
                  setIsLoadingVehicles(false);
                },
              );
            }}
          >
            Search
          </DefaultButton>
        </SearchInputRow>
      </SearchInputContainer>
      <Switch>
        <Case when={isLoadingVehicles}>
          <>
            <VehicleListHeader />
            <StyledLoadingItem />
          </>
        </Case>
        <Case when={RA.isNotNilOrEmpty(vehicles)}>
          <HighlightTextContext.Provider
            value={{ searchWords: debouncedSearch ? [debouncedSearch] : [] }}
          >
            <VehicleListHeader />
            {vehicles?.map((vehicle: any) => (
              <VehicleListItem
                key={vehicle.vin}
                vin={vehicle.vin}
                name={vehicle.name}
                value={vehicle.value}
                onClick={onVehicleClick}
              />
            ))}
          </HighlightTextContext.Provider>
        </Case>
        <Case when={shouldShowNoResultsFound}>
          <StyledEmpty
            title={`No results found for "${previousSearch}"`}
            subtitle={
              <StyledLink
                onClick={() => {
                  replace(AddManualAccountFlow, {
                    onBack: pop,
                    onComplete,
                    initialProps: {
                      type: AccountTypeName.VEHICLE,
                    },
                    forceManualAccount: true,
                  });
                }}
              >
                Track car value manually
              </StyledLink>
            }
          />
        </Case>
        <Case default>
          <EmptyContainer>
            <StyledEmpty
              title="Add your VIN number"
              subtitle={COPY.ACCOUNTS.VEHICLE_SEARCH_SUBTITLE}
            />
            <Flex justifyCenter>
              <Icon name="monarch-vinaudit-logo" size={88} />
            </Flex>
          </EmptyContainer>
        </Case>
      </Switch>
    </Root>
  );
};

const VehicleListHeaderRoot = styled(FlexContainer)`
  background: ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  > :first-child {
    margin-right: auto;
  }
`;

const VehicleListHeader = () => (
  <VehicleListHeaderRoot>
    <div>Details</div>
    <div>Value</div>
  </VehicleListHeaderRoot>
);

const VehicleListItemRoot = styled(FlexContainer)`
  width: 100%;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.large};
  :hover {
    background: ${({ theme }) => theme.color.grayBackground};
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const VehicleListItemLeft = styled.div`
  flex: 1;
  display: block;
`;

const VehicleListItemRight = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const VehicleListItem = ({
  vin,
  name,
  value,
  onClick,
}: {
  vin: string;
  name: string;
  value: number;
  onClick?: (vehicle: { vin: string; name: string; value: number }) => void;
}) => (
  <VehicleListItemRoot
    onClick={() => {
      onClick?.({ vin, name, value });
    }}
  >
    <VehicleListItemLeft>
      <Text as="div" weight="bold">
        {name}
      </Text>
      <Text>VIN: {vin}</Text>
    </VehicleListItemLeft>
    <VehicleListItemRight>
      <Text>{formatCurrency(value)}</Text>
    </VehicleListItemRight>
  </VehicleListItemRoot>
);

export default VehicleSearchForm;
