import { SPONSORSHIP } from 'common/constants/copy';

import type { Color } from 'common/types/Styles';

export enum SubscriptionSponsorshipInviteStatus {
  Redeemed = 'redeemed',
  Pending = 'pending',
  Revoked = 'revoked',
}

export type SubscriptionSponsorshipInviteStatusType =
  | SubscriptionSponsorshipInviteStatus.Pending
  | SubscriptionSponsorshipInviteStatus.Revoked
  | SubscriptionSponsorshipInviteStatus.Redeemed
  | 'demo';

export type SubscriptionSponsorshipStatusType =
  | SubscriptionSponsorshipStatus.Active
  | SubscriptionSponsorshipStatus.Canceled;

export enum SubscriptionSponsorshipStatus {
  Active = 'active',
  Canceled = 'canceled',
}

export const getTextForClientsListRowStatus = (
  inviteStatus: SubscriptionSponsorshipInviteStatusType,
  sponsorshipStatus?: SubscriptionSponsorshipStatusType | null,
): string | undefined => {
  if (inviteStatus === 'demo') {
    return 'Demo (Free)';
  }
  if (inviteStatus === SubscriptionSponsorshipInviteStatus.Pending) {
    return 'Not redeemed';
  }
  switch (sponsorshipStatus) {
    case SubscriptionSponsorshipStatus.Active:
      return 'Active';
    case SubscriptionSponsorshipStatus.Canceled:
      return 'Canceled';
  }
};

export const getBackgroundColorForClientsListRowStatus = (
  inviteStatus: SubscriptionSponsorshipInviteStatusType,
  sponsorshipStatus?: SubscriptionSponsorshipStatusType | null,
): Color | undefined => {
  if (inviteStatus === 'demo') {
    return 'purpleBackground';
  }
  if (inviteStatus === SubscriptionSponsorshipInviteStatus.Pending) {
    return 'grayBackground' as Color;
  }
  switch (sponsorshipStatus) {
    case SubscriptionSponsorshipStatus.Active:
      return 'greenBackground';
    case SubscriptionSponsorshipStatus.Canceled:
      return 'redBackground';
  }
};

export const getTextColorForClientsListRowStatus = (
  inviteStatus: SubscriptionSponsorshipInviteStatusType,
  sponsorshipStatus?: SubscriptionSponsorshipStatusType | null,
): Color | undefined => {
  if (inviteStatus === 'demo') {
    return 'purple';
  }
  if (inviteStatus === SubscriptionSponsorshipInviteStatus.Pending) {
    return 'textLight' as Color;
  }
  switch (sponsorshipStatus) {
    case SubscriptionSponsorshipStatus.Active:
      return 'green';
    case SubscriptionSponsorshipStatus.Canceled:
      return 'red';
  }
};

export const getInviteClientMailUrl = (
  advisorName: string,
  message: string,
  receiver?: string | null,
) => {
  const subject = SPONSORSHIP.INVITE_CLIENT_EMAIL_SUBJECT(advisorName);
  return `mailto:${receiver}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
    message,
  )}`;
};

export const getInviteClientMailBodyTemplate = (
  advisorName: string,
  code: string,
  baseUrl: string,
) => SPONSORSHIP.INVITE_CLIENT_EMAIL_BODY(advisorName, code, baseUrl);
