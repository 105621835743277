import { gql } from '@apollo/client';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import AccountMessageBlock from 'components/assistant/blocks/AccountMessageBlock';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { AccountsBlock } from 'common/lib/assistant/blocks';
import { makeBlockRenderer } from 'common/lib/assistant/blocks/render';
import useQuery from 'common/lib/hooks/useQuery';

import type {
  Web_AccountsMessageBlock,
  Web_AccountsMessageBlockVariables,
} from 'common/generated/graphQlTypes/Web_AccountsMessageBlock';

const Root = styled(FlexContainer)`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const AccountsBlockRenderer = makeBlockRenderer(AccountsBlock)(({ data: { ids } }) => {
  const { data, isLoadingInitialData } = useQuery<
    Web_AccountsMessageBlock,
    Web_AccountsMessageBlockVariables
  >(QUERY, {
    variables: { ids },
  });
  const { accounts = [] } = data ?? {};
  const accountsById = useMemo(() => R.indexBy(R.prop('id'), accounts), [accounts]);

  return (
    <Root column>
      {ids.map((id) => {
        const account = accountsById[id];
        const { displayName, subtype, icon, logoUrl, displayBalance } = account ?? {};

        if (!account && !isLoadingInitialData) {
          // this account does not exist, so don't show anything
          return null;
        }

        return (
          <AccountMessageBlock
            key={id}
            id={id}
            loading={!account}
            name={displayName}
            subtype={subtype?.display}
            icon={icon}
            balance={displayBalance}
            logoUrl={logoUrl}
          />
        );
      })}
    </Root>
  );
});

const QUERY = gql`
  query Web_AccountsMessageBlock($ids: [ID!]!) {
    accounts(filters: { ids: $ids }) {
      id
      displayName
      displayBalance
      icon
      logoUrl
      subtype {
        display
      }
    }
  }
`;

export default AccountsBlockRenderer;
