import { useState, useMemo, useCallback, useEffect } from 'react';

import useToggle from 'common/lib/hooks/useToggle';
import { toggleListItem } from 'common/lib/list';

export type BulkUpdateStateFunc = (state: Partial<BulkUpdateState>) => void;

type Options = {
  totalCount: number | undefined;
  onStateChange?: BulkUpdateStateFunc;
};

export type BulkUpdateState = {
  selectedTransactions: string[];
  excludedTransactions: string[];
  isAllSelected: boolean;
  totalSelected: number;
  toggleTransaction: (transactionId: string) => void;
  isChecked: (transactionId: string) => boolean;
  toggleIsAllSelected: () => void;
  selectAll: () => void;
  deselectAll: () => void;
};

const useBulkUpdateTransactionState = ({
  totalCount = 0,
  onStateChange,
}: Options): BulkUpdateState => {
  const [
    isAllSelected,
    { toggle: toggleIsAllSelected, setOff: setIsAllSelectedOff, setOn: setIsAllSelectedOn },
  ] = useToggle(false);
  const [selectedTransactions, setSelectedTransactions] = useState<string[]>([]);
  const [excludedTransactions, setExcludedTransactions] = useState<string[]>([]);

  const isChecked = useCallback(
    (transactionId: string) => {
      if (excludedTransactions.includes(transactionId)) {
        return false;
      } else if (isAllSelected) {
        return true;
      } else {
        return selectedTransactions.includes(transactionId);
      }
    },
    [excludedTransactions, selectedTransactions, isAllSelected],
  );

  const totalSelected = useMemo(() => {
    if (isAllSelected && excludedTransactions.length > 0) {
      return totalCount - excludedTransactions.length;
    } else if (isAllSelected) {
      return totalCount;
    } else {
      return selectedTransactions.length;
    }
  }, [isAllSelected, excludedTransactions, selectedTransactions, totalCount]);

  const toggleSelectedTransaction = useCallback((transactionId: string) => {
    setSelectedTransactions((prevSelectedTransactions) =>
      toggleListItem(transactionId, prevSelectedTransactions),
    );
  }, []);

  const toggleExcludedTransaction = useCallback((transactionId: string) => {
    setExcludedTransactions((prevExcludedTransactions) =>
      toggleListItem(transactionId, prevExcludedTransactions),
    );
  }, []);

  const selectAll = () => {
    setIsAllSelectedOn();
    setExcludedTransactions([]);
    setSelectedTransactions([]);
  };

  const deselectAll = useCallback(() => {
    setIsAllSelectedOff();
    setSelectedTransactions([]);
    setExcludedTransactions([]);
  }, [setIsAllSelectedOff]);

  const toggleTransaction = useCallback(
    (transactionId: string) => {
      if (isAllSelected) {
        toggleExcludedTransaction(transactionId);
        excludedTransactions.length === totalSelected && deselectAll();
      } else {
        toggleSelectedTransaction(transactionId);
      }
    },
    [
      isAllSelected,
      totalSelected,
      excludedTransactions.length,
      deselectAll,
      toggleExcludedTransaction,
      toggleSelectedTransaction,
    ],
  );

  useEffect(() => {
    onStateChange?.({
      selectedTransactions,
      excludedTransactions,
      isAllSelected,
      totalSelected,
    });
  }, [selectedTransactions, excludedTransactions, isAllSelected, totalSelected, onStateChange]);

  return {
    selectedTransactions,
    excludedTransactions,
    isAllSelected,
    totalSelected,
    toggleTransaction,
    isChecked,
    toggleIsAllSelected,
    selectAll,
    deselectAll,
  };
};

export default useBulkUpdateTransactionState;
