import { useMutation } from '@apollo/client';

import useUploadLogo from 'common/lib/hooks/cloudinary/useUploadLogo';

import { gql } from 'common/generated/gql';
import type { FileData } from 'common/types/File';

const SET_ACCOUNT_LOGO = gql(`
  mutation Common_SetAccountLogo($input: SetAccountLogoInput!) {
    setAccountLogo(input: $input) {
      account {
        id
        name
        logoUrl
        hasCustomizedLogo
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

const useUploadAccountLogo = () => {
  const [setAccountLogo] = useMutation(SET_ACCOUNT_LOGO);

  const handleSetLogo = async (file: FileData, cloudinaryPublicId: string, accountId?: string) => {
    if (!accountId) {
      throw new Error('Account ID is required');
    }

    const setAccountLogoResponse = await setAccountLogo({
      variables: {
        input: {
          accountId,
          cloudinaryPublicId,
        },
      },
    });
    const { account } = setAccountLogoResponse.data?.setAccountLogo ?? {};

    if (account?.logoUrl) {
      return account.logoUrl;
    } else {
      throw new Error('Account logo upload failed. Please try again later.');
    }
  };

  return useUploadLogo('account', handleSetLogo);
};

export default useUploadAccountLogo;
