import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import { color, spacing } from 'common/lib/theme/dynamic';

const ICON_SIZE_PX = 24;

const Root = styled(FlexContainer).attrs({ gap: 'xsmall', column: true, center: true })`
  text-align: center;
  margin-top: ${spacing.default};
`;

const SearchIcon = styled(Icon).attrs({ name: 'search', size: ICON_SIZE_PX })`
  color: ${color.gray};
  margin-bottom: ${spacing.xxsmall};
`;

type Props = {
  subtitle?: string;
};

const FilterMenuEmpty: React.FC<Props> = ({ subtitle = 'Try searching for something else.' }) => (
  <Root>
    <SearchIcon />
    <Text size="small" weight="medium">
      No results found
    </Text>
    <Text size="small">{subtitle}</Text>
  </Root>
);

export default FilterMenuEmpty;
