import { useContext } from 'react';

import type { OverlayContextType } from 'components/lib/ui/popover/OverlayContext';
import OverlayContext from 'components/lib/ui/popover/OverlayContext';

const useOverlay = <T = any>() => {
  const context = useContext<OverlayContextType<T>>(OverlayContext);
  if (!context) {
    throw new Error('usePopover() used outside of OverlayContext');
  }

  return context;
};

export default useOverlay;
