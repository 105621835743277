import React, { useContext } from 'react';
import InfiniteScroller from 'react-infinite-scroller';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import ScrollContext from 'lib/contexts/ScrollContext';

export type Props = {
  children?: React.ReactNode;
  hasNextPage?: boolean;
  onRequestNextPage?: () => void;
};

const Loader = styled(Flex)`
  padding-bottom: ${({ theme }) => theme.spacing.default};
`;

const Spinner = styled(LoadingSpinner)`
  margin-top: ${({ theme }) => theme.spacing.default};
  width: ${({ theme }) => theme.spacing.large};
  height: ${({ theme }) => theme.spacing.large};
`;

const InfiniteScroll = ({ children, hasNextPage, onRequestNextPage }: Props) => {
  const { scrollRef } = useContext(ScrollContext);

  return (
    <InfiniteScroller
      loadMore={(_) => onRequestNextPage?.()}
      hasMore={hasNextPage}
      useWindow={false}
      getScrollParent={() => scrollRef?.current ?? null}
      loader={
        <Loader key="loader" center>
          <Spinner />
        </Loader>
      }
    >
      {children}
    </InfiniteScroller>
  );
};

export default InfiniteScroll;
