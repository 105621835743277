import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextAreaField from 'components/lib/form/TextAreaField';
import Page from 'components/lib/ui/Page';
import Text from 'components/lib/ui/Text';
import CloseButton from 'components/lib/ui/button/CloseButton';

import getHackathonApi from 'common/lib/api/hackathon';
import variables from 'common/lib/theme/variables';
import api from 'lib/api';

import ButterflyImage from 'static/images/butterfly_1.svg';

const BUTTERFLY_SIZE_PX = 70;

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const AssistantContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ButterflyImg = styled.img<{ $isLoading: boolean }>`
  position: absolute;
  width: ${BUTTERFLY_SIZE_PX}px;
  height: ${BUTTERFLY_SIZE_PX}px;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      animation: ${flapAnimation} 2s ease-in-out infinite;
    `}
`;

const flapAnimation = keyframes`
0% {
  transform: rotateY(0deg);
}

50% {
  transform: rotateY(180deg);
}

100% {
  transform: rotateY(360deg);
}
`;

const StyledForm = styled(Form)`
  margin: 24px;
`;

const CircleContainer = styled.div`
  position: relative;
  width: 124px;
  height: 124px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Circle = styled.div`
  box-shadow: 0px 8px 16px ${variables.color.shadow.large};
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 1px solid #f0f4f8;
  background: linear-gradient(180deg, #ffffff 0%, #f0f4f8 100%);
`;

const animateIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-20px) translateY(-50%);
  }

  100% {
    opacity: 1;
    transform: translateX(0) translateY(-50%);
  }
`;

const SpeechBubble = styled.div`
  background-color: ${({ theme }) => theme.color.orange};
  position: absolute;
  width: 300px;
  border-radius: ${({ theme }) => theme.radius.medium};
  padding: ${({ theme }) => theme.spacing.default};
  left: 160px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.color.white};
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -12px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent ${({ theme }) => theme.color.orange} transparent transparent;
  }

  animation: ${animateIn} 0.3s ease-in-out;
`;

const TitleText = styled(Text)`
  margin: 12px 0;
`;

const SpeechHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const RelativeDiv = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

const StyledPage = styled(Page)`
  background-color: ${({ theme }) => theme.color.white};
`;

const Button = styled(CloseButton)`
  color: white;
`;

export const useInterval = (handler: () => void, ms: number) => {
  useEffect(() => {
    const timeout = setInterval(handler, ms);
    return () => clearInterval(timeout);
  }, []);
};

const HackathonAssistant = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<string>();
  const hackathonApi = getHackathonApi(api);

  const submit = async (values: any) => {
    setIsLoading(true);
    setResponse(undefined);

    try {
      const { result } = await hackathonApi.assistant({ input: values.input });
      setResponse(result);
    } catch (e) {
      setResponse('Sorry, I did not understand that question. Please try again.');
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledPage name="Assistant">
      <Root>
        <AssistantContainer>
          <CenterContainer>
            <RelativeDiv>
              <CircleContainer>
                <Circle />
                <ButterflyImg $isLoading={isLoading} src={ButterflyImage} />
              </CircleContainer>
              {response && (
                <SpeechBubble>
                  <SpeechHeaderContainer>
                    <Text weight="medium">Monarch says:</Text>
                    <Button onClick={() => setResponse(undefined)} />
                  </SpeechHeaderContainer>
                  <div>{response}</div>
                </SpeechBubble>
              )}
            </RelativeDiv>
            <TitleText weight="medium" size="large">
              Ask me a question about your finances
            </TitleText>
            <Text>Ask me anything and I will do my best to answer your question.</Text>
          </CenterContainer>
          <StyledForm onSubmit={submit}>
            <TextAreaField
              name="input"
              hideLabel
              cols={70}
              minRows={3}
              disabled={isLoading}
              placeholder="What's on your mind?"
            />
            <FormSubmitButton variant="gradient">Ask Monarch</FormSubmitButton>
          </StyledForm>
        </AssistantContainer>
      </Root>
    </StyledPage>
  );
};

export default HackathonAssistant;
