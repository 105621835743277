import type { MutationFunctionOptions } from '@apollo/client';
import type { ExecutionResult } from 'graphql';
import React from 'react';
import deepIsEqual from 'react-fast-compare';
import styled from 'styled-components';

import TransactionOverview from 'components/transactions/TransactionOverview';

import type { AutoFocusOptions } from 'lib/contexts/TransactionsListContext';

import type {
  TransactionsListFieldsFragment,
  Web_UpdateTransactionOverviewMutation,
  Web_UpdateTransactionOverviewMutationVariables,
} from 'common/generated/graphql';

type Props = {
  transaction: TransactionsListFieldsFragment;
  className?: string;
  onChevronClick: (id: GraphQlUUID, autoFocus?: Partial<AutoFocusOptions>) => void;
  onMarkedAsReviewed?: () => void;
  showReviewButton?: boolean | null;
  editsToPendingTransactionsEnabled?: boolean;
  updateTransaction: (
    options?: MutationFunctionOptions<
      Web_UpdateTransactionOverviewMutation,
      Web_UpdateTransactionOverviewMutationVariables
    >,
  ) => Promise<ExecutionResult<Web_UpdateTransactionOverviewMutation>>;
  isTransactionsUpdatesEnabled: boolean;
};

const Root = styled.div``;

const Transaction = ({
  transaction,
  className,
  updateTransaction,
  showReviewButton,
  editsToPendingTransactionsEnabled,
  onChevronClick,
  onMarkedAsReviewed,
  isTransactionsUpdatesEnabled,
}: Props) => (
  <Root className={className}>
    <TransactionOverview
      transaction={transaction}
      onChevronClick={onChevronClick}
      showReviewButton={showReviewButton}
      editsToPendingTransactionsEnabled={editsToPendingTransactionsEnabled}
      updateTransaction={updateTransaction}
      onMarkedAsReviewed={onMarkedAsReviewed}
      isTransactionsUpdatesEnabled={isTransactionsUpdatesEnabled}
    />
  </Root>
);

export default React.memo(Transaction, deepIsEqual);
