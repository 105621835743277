import { useHistory } from 'react-router-dom';

import routes from 'constants/routes';

const useRedirectToLogin = () => {
  const history = useHistory();

  const redirectToLogin = ({
    preserveLastRoute = true,
    message,
  }: {
    preserveLastRoute?: boolean;
    message?: string;
  } = {}) => {
    const queryParams: { [key: string]: any } = {};
    if (preserveLastRoute) {
      queryParams.route = `${location.pathname}${location.search}`;
    }
    if (message) {
      queryParams.message = message;
    }

    history.replace(routes.login({ queryParams }));
  };

  return redirectToLogin;
};

export default useRedirectToLogin;
