import React from 'react';

import AddManualHoldingDetailsFormModal from 'components/accounts/AddManualHoldingDetailsFormModal';
import AddManualHoldingSecuritySearchModal from 'components/accounts/AddManualHoldingSecuritySearchModal';
import Flow from 'components/utils/Flow';

import type { ManualInvestmentAccountTrackingModeType } from 'common/constants/accounts';

type Props = {
  initialProps?: {
    accountId?: string;
    accountType?: string;
    accountSubtype?: string | null;
    accountName?: string;
    accountInvestmentTrackingMode?: ManualInvestmentAccountTrackingModeType;
    securityId?: string;
    quantity?: number;
    skipAccountSelection?: boolean;
  };
  onComplete?: (props: AddManualHoldingFlowOutput) => Promise<void>;
  onBack?: () => void;
};

export type AddManualHoldingFlowOutput = {
  securityId?: string;
  quantity?: number;
  accountId?: string;
  accountName?: string;
  accountSubtype?: string;
};

const AddManualHoldingFlow = ({ onComplete, onBack, initialProps }: Props) => (
  <Flow
    steps={[AddManualHoldingSecuritySearchModal, AddManualHoldingDetailsFormModal]}
    initialProps={initialProps}
    onComplete={onComplete}
    onBack={onBack}
  />
);

export default AddManualHoldingFlow;
