import React from 'react';
import styled from 'styled-components';

import GoalCard from 'components/goalsV2/GoalCard';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { getGoalDisplayProgress, getGoalRemainingAmount } from 'common/lib/goalsV2/adapters';
import { formatCurrencyCentsOptional, formatCurrencyNoCents } from 'common/utils/Currency';
import { formatPercentNoDecimals } from 'common/utils/Number';

import { GoalType } from 'common/constants/goals';

import type { WebGoalCardFieldsFragment } from 'common/generated/graphql';

const Label = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.textWhite};
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
  text-align: right;
`;

const BottomLabel = styled(Label)`
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  goal: WebGoalCardFieldsFragment;
  className?: string;
};

const GoalProgressCard = ({ goal, className }: Props) => {
  const { targetAmount, startingAmount, type } = goal;
  const isDebtGoal = type === GoalType.Debt;
  const progress = getGoalDisplayProgress(goal);

  return (
    <GoalCard
      className={className}
      goal={goal}
      imageSize="large"
      right={
        <FlexContainer alignEnd column>
          {!isDebtGoal && (
            <Label>{formatCurrencyCentsOptional(getGoalRemainingAmount(goal))} left to save</Label>
          )}
          <BottomLabel>
            {isDebtGoal ? 'Paid off ' : 'Saved '}
            {formatPercentNoDecimals(progress)} of{' '}
            {formatCurrencyNoCents(isDebtGoal ? (startingAmount ?? 0) * -1 : targetAmount)}
          </BottomLabel>
        </FlexContainer>
      }
    />
  );
};

export default GoalProgressCard;
