import React, { useMemo } from 'react';
import styled from 'styled-components';

import ErrorBoundary from 'common/components/higherOrder/ErrorBoundary';
import AccountsBlockRenderer from 'components/assistant/blocks/AccountsBlockRenderer';
import BarChartBlockRenderer from 'components/assistant/blocks/BarChartBlockRenderer';
import DemoAccountsBlockRenderer from 'components/assistant/blocks/DemoAccountsBlockRenderer';
import DemoGoalsBlockRenderer from 'components/assistant/blocks/DemoGoalsBlockRenderer';
import DemoMultipleChoiceBlockRenderer from 'components/assistant/blocks/DemoMultipleChoiceBlockRenderer';
import DemoTransactionsBlockRenderer from 'components/assistant/blocks/DemoTransactionsBlockRenderer';
import GoalsBlockRenderer from 'components/assistant/blocks/GoalsBlockRenderer';
import LineChartBlockRenderer from 'components/assistant/blocks/LineChartBlockRenderer';
import MessageError from 'components/assistant/blocks/MessageError';
import MultipleChoiceMessageBlock from 'components/assistant/blocks/MultipleChoiceBlockRenderer';
import PieChartBlockRenderer from 'components/assistant/blocks/PieChartBlockRenderer';
import TextBlockRenderer from 'components/assistant/blocks/TextBlockRenderer';
import TransactionsBlockRenderer from 'components/assistant/blocks/TransactionsBlockRenderer';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { BlockRenderer, indexBlockRenderersByType } from 'common/lib/assistant/blocks/render';
import { getBlocksFromMessages } from 'common/lib/assistant/parse';
import { onUnhandledError } from 'lib/errors/onError';

const BlockContainer = styled(FlexContainer)`
  :not(:first-child):not(:empty) {
    margin-top: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const BLOCK_RENDERERS = indexBlockRenderersByType([
  TextBlockRenderer,
  TransactionsBlockRenderer,
  PieChartBlockRenderer,
  BarChartBlockRenderer,
  GoalsBlockRenderer,
  AccountsBlockRenderer,
  LineChartBlockRenderer,
  MultipleChoiceMessageBlock,
  // add new renderers here...
]);

/** These are used when demo=True (i.e. for stories / debugging) when we can't fetch entities by ID. */
const DEMO_BLOCK_RENDERERS = indexBlockRenderersByType([
  DemoTransactionsBlockRenderer,
  DemoAccountsBlockRenderer,
  DemoGoalsBlockRenderer,
  DemoMultipleChoiceBlockRenderer,
]);

type Props = {
  messages: { content: string }[];
  nextGroup?: { content: string }[];
  demo?: boolean;
};

const MessageBlocksRenderer = ({ messages, nextGroup, demo }: Props) => {
  const blocks = useMemo(() => getBlocksFromMessages(messages), [messages]);

  const renderers = useMemo(
    () => ({
      ...BLOCK_RENDERERS,
      ...(demo ? DEMO_BLOCK_RENDERERS : {}),
    }),
    [demo],
  );

  return (
    <div>
      {blocks.map((block, i) => (
        <BlockContainer key={i}>
          <ErrorBoundary errorComponent={<MessageError />} onError={onUnhandledError}>
            <BlockRenderer
              block={block}
              blockRenderers={renderers}
              previousBlockInGroup={blocks[i - 1]}
              nextBlockInGroup={blocks[i + 1]}
              nextGroup={nextGroup}
            />
          </ErrorBoundary>
        </BlockContainer>
      ))}
    </div>
  );
};

export default MessageBlocksRenderer;
