import type { Options } from 'lib/hooks/useFeatureFlag';
import useFeatureFlag from 'lib/hooks/useFeatureFlag';
import type { FeatureFlagName } from 'state/features/types';

/** Feature flags can technically be any string, but most commonly are "on" or "off" */
const useIsFeatureFlagOff = (flagName: FeatureFlagName, options?: Options): boolean => {
  const { treatment } = useFeatureFlag(flagName, options);
  return treatment === 'off';
};

export default useIsFeatureFlagOff;
