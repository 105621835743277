import React from 'react';
import styled from 'styled-components';

import InstitutionLogo from 'components/institutions/InstitutionLogo';
import TextButton from 'components/lib/ui/TextButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import type { NotificationProps } from './BaseNotification';
import BaseNotification from './BaseNotification';

type Props = {
  logo: string | null;
  actionLabel: string | null;
  onDismiss: () => void;
} & NotificationProps;

const ActionContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.default};
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const InstitutionIcon = styled(InstitutionLogo).attrs({ size: 32 })`
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const DismissButton = styled(TextButton)`
  color: ${({ theme }) => theme.color.textLight};
`;

const Notification = styled(BaseNotification)`
  user-select: none;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  }
`;

const PinnedNotification = ({ logo, actionLabel, onDismiss, onClick, ...props }: Props) => (
  <Notification
    {...props}
    icon={!!logo && <InstitutionIcon logo={logo} />}
    read={false}
    hover={false}
    onClick={undefined}
  >
    <ActionContainer>
      {!!actionLabel && <PrimaryButton onClick={onClick}>{actionLabel}</PrimaryButton>}
      <DismissButton onClick={onDismiss}>Dismiss</DismissButton>
    </ActionContainer>
  </Notification>
);

export default PinnedNotification;
