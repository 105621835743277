import React from 'react';
import styled from 'styled-components';

import AddManualAccountFlow from 'components/accounts/AddManualAccountFlow';
import LinkAccountDataProviderModal from 'components/accounts/LinkAccountDataProviderModal';
import Badge from 'components/lib/ui/Badge';
import Markdown from 'components/lib/ui/Markdown';
import ModalCard from 'components/lib/ui/ModalCard';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { useStackContext } from 'common/lib/contexts/StackContext';
import { getDisplayName } from 'common/lib/dataProvider';
import { getOrderedInstitutionDataProviders } from 'common/lib/institutions/fallback';

import * as COPY from 'common/constants/copy';

import type { LinkInstitutionFieldsFragment } from 'common/generated/graphql';

const Container = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.large};
  padding-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const StyledBadge = styled(Badge).attrs({ color: 'blue' })`
  margin-left: 0;
`;

const Button = styled(DefaultButton)`
  margin-top: ${({ theme }) => theme.spacing.default};
  width: 100%;
`;

const Header = styled.div`
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: ${({ theme }) => theme.spacing.default};
  color: ${({ theme }) => theme.color.textLight};
`;

type Props = {
  institution: LinkInstitutionFieldsFragment;
  hideBackButton?: boolean;
};

const LinkAccountSelectDataProviderModal = ({ institution, hideBackButton }: Props) => {
  const { linkFlowWarningMessage, newConnectionsDisabled, linkFlowWarningTitle, name } =
    institution;
  const { pop, push, onComplete } = useStackContext();

  const dataProviders = getOrderedInstitutionDataProviders(institution);

  const warningTitle =
    linkFlowWarningTitle || COPY.ACCOUNTS.SELECT_DATA_PROVIDER.ISSUES_REPORTED_DEFAULT_TITLE(name);

  return (
    <ModalCard
      onClickBackButton={hideBackButton ? undefined : pop}
      hideBottomBorder
      title={linkFlowWarningMessage ? warningTitle : COPY.ACCOUNTS.SELECT_DATA_PROVIDER.TITLE}
      description={
        <Markdown
          source={linkFlowWarningMessage || COPY.ACCOUNTS.SELECT_DATA_PROVIDER.DESCRIPTION}
        />
      }
    >
      <Container>
        {newConnectionsDisabled ? (
          <>
            <Header>Other Options</Header>
          </>
        ) : (
          <>
            <StyledBadge>Best Choice</StyledBadge>
            {dataProviders.map((dataProvider, i) => (
              <React.Fragment key={dataProvider.dataProvider}>
                <Button
                  key={dataProvider.dataProvider}
                  onClick={() =>
                    push(LinkAccountDataProviderModal, {
                      institution,
                      dataProvider: dataProvider.dataProvider,
                    })
                  }
                >
                  Connect with {getDisplayName(dataProvider.dataProvider)}
                </Button>
                {i === 0 && <Header>Other Options</Header>}
              </React.Fragment>
            ))}
          </>
        )}
        <Button
          onClick={() =>
            push(AddManualAccountFlow, {
              onBack: pop,
              onComplete,
            })
          }
        >
          Add manual account
        </Button>
      </Container>
    </ModalCard>
  );
};

export default LinkAccountSelectDataProviderModal;
