import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';
import Link from 'components/lib/ui/Link';

import type { BlockComponentProps } from 'common/types/support';

const ICON_SIZE_PX = 15;

const Root = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeight.book};
  font-size: ${({ theme }) => theme.fontSize.small};
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing.xsmall};
  align-items: center;
  line-height: 150%;
`;

const ExternalLinkIcon = styled(Icon).attrs({ name: 'external-link', size: ICON_SIZE_PX })``;

const ResolutionLinkBlock = ({
  data,
  onClickEvent,
}: BlockComponentProps<'ResolutionLinkBlock'>) => (
  <Root
    href={data.linkTo}
    target="_blank"
    rel="noopener noreferrer"
    onClick={() => onClickEvent?.()}
  >
    <ExternalLinkIcon />
    {data.text}
  </Root>
);

export default ResolutionLinkBlock;
