import type { UiTheme, UiThemePreference } from 'common/types';

const DEFAULT_THEME: UiTheme = 'light';

const resolveUiTheme = ({
  uiThemePreference,
  systemUiTheme,
}: {
  uiThemePreference: UiThemePreference;
  systemUiTheme: UiTheme | null | undefined;
}): UiTheme =>
  uiThemePreference === 'system' ? systemUiTheme ?? DEFAULT_THEME : uiThemePreference;

export default resolveUiTheme;
