import { DateTime } from 'luxon';
import React from 'react';
import type { ReactDatePickerProps } from 'react-datepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import fieldStyleMixin from 'lib/styles/fieldStyleMixin';

const TODAY_ICON_SIZE_PX = 18;
const ARROW_ICON_SIZE_PX = 20;

export type DatePickerProps = Omit<ReactDatePickerProps, 'dateFormat'> & {
  small?: boolean;
  className?: string;
  dateFormat: string;
  showToday?: boolean;
};

const Root = styled.div`
  user-select: none;

  .react-datepicker-wrapper {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker {
    border-radius: ${({ theme }) => theme.radius.medium};
    border: 1px solid ${({ theme }) => theme.color.gray};
    font-family: inherit;
    color: inherit;
  }

  .react-datepicker__header {
    border-top-left-radius: ${({ theme }) => theme.radius.medium};
    border-top-right-radius: ${({ theme }) => theme.radius.medium};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray};
    background-color: ${({ theme }) => theme.color.grayBackground};
  }

  .react-datepicker__current-month {
    color: ${({ theme }) => theme.color.text};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  .react-datepicker__month-container {
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.radius.medium};
  }

  .react-datepicker__day {
    color: ${({ theme }) => theme.color.text};
  }

  .react-datepicker__day-name {
    color: ${({ theme }) => theme.color.text};
  }

  .react-datepicker__day:hover:not(.react-datepicker__day--disabled):not(
      .react-datepicker__day--selected
    ) {
    border-radius: ${({ theme }) => theme.radius.small};
    background-color: ${({ theme }) => theme.color.grayFocus};
  }

  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.color.grayDark};
    cursor: not-allowed;
  }

  .react-datepicker__day--keyboard-selected {
    border-radius: ${({ theme }) => theme.radius.small};
    background-color: ${({ theme }) => theme.color.grayFocus};
  }

  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.color.orange};
    color: ${({ theme }) => theme.color.textWhite};
  }

  .react-datepicker__day--selected:hover {
    background-color: ${({ theme }) => theme.color.orangeDark};
  }

  .react-datepicker-popper .react-datepicker__triangle {
    transform: translate(220px, 0px) !important; /* stylelint-disable-line plugin/no-z-index */
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    border-top-color: ${({ theme }) => theme.color.gray};
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: ${({ theme }) => theme.color.grayBackground};

    &::before {
      border-bottom-color: ${({ theme }) => theme.color.gray};
    }

    &::after {
      border-bottom-color: ${({ theme }) => theme.color.grayBackground};
    }
  }
`;

const Input = styled.input`
  ${fieldStyleMixin}
  width: 100%;
`;

const Header = styled(FlexContainer).attrs({ justifyBetween: true, alignCenter: true })`
  margin: ${({ theme }) => theme.spacing.xxxsmall} ${({ theme }) => theme.spacing.small};
`;

const HeaderIcon = styled(Icon).attrs({ size: ARROW_ICON_SIZE_PX })<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  color: ${({ theme }) => theme.color.gray};
  transform: translateY(1px);

  :hover {
    color: ${({ theme }) => theme.color.grayDark};
  }
`;

const CalendarIcon = styled(Icon).attrs({ size: TODAY_ICON_SIZE_PX, name: 'calendar' })<{
  $disabled?: boolean;
}>`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  color: ${({ theme }) => theme.color.gray};

  :hover {
    color: ${({ theme }) => theme.color.grayDark};
  }
`;

// This is different than isSameMonth because it considers the year
const isDateInSameMonthAndYear = (date: Date) =>
  DateTime.fromJSDate(date).hasSame(DateTime.local(), 'month');

const StyledDatePicker = ({
  className,
  small = false,
  showToday = false,
  ...restProps
}: DatePickerProps) => (
  <Root className={className}>
    <DatePicker
      {...restProps}
      customInput={<Input small={small} />}
      popperModifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
      ]}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        changeMonth,
        changeYear,
      }) => (
        <Header>
          <HeaderIcon
            name="arrow-left"
            onClick={decreaseMonth}
            $disabled={prevMonthButtonDisabled}
          />
          <Text weight="medium" size="small">
            {DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL)}
          </Text>
          <HeaderIcon
            name="arrow-right"
            onClick={increaseMonth}
            $disabled={nextMonthButtonDisabled}
          />
          {showToday && (
            <CalendarIcon
              $disabled={isDateInSameMonthAndYear(date)}
              onClick={() => {
                const today = new Date();
                changeMonth(today.getMonth());
                changeYear(today.getFullYear());
              }}
            />
          )}
        </Header>
      )}
    />
  </Root>
);

export default StyledDatePicker;
