import React from 'react';
import styled from 'styled-components';

import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';

import formatTransactionAmount from 'common/utils/formatTransactionAmount';
import useTheme from 'lib/hooks/useTheme';

import { RECURRING } from 'common/constants/copy';

type Props = {
  amount: number;
  isPast?: boolean;
  isApproximate?: boolean;
};

const Amount = styled(Text).attrs({ weight: 'medium', ...maskClassProps })<{ $isIncome: boolean }>`
  color: ${({ $isIncome, theme }) => ($isIncome ? theme.color.green : theme.color.text)};
`;

const Container = styled(FlexContainer)`
  min-width: 200px;

  &:hover {
    cursor: default;
  }
`;

const IconWrapper = styled.div<{ $backgroundColor: string; $color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radius.round};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
  width: ${({ theme }) => theme.spacing.large};
  height: ${({ theme }) => theme.spacing.large};
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const RecurringAmountCell = ({ amount, isPast, isApproximate }: Props) => {
  const theme = useTheme();

  return (
    <Container justifyEnd alignCenter>
      {!!isPast && (
        <IconWrapper $backgroundColor={theme.color.greenFocus} $color={theme.color.greenText}>
          <Icon name="check" size={12} />
        </IconWrapper>
      )}

      <Tooltip opacity={1} content={isApproximate ? RECURRING.AMOUNT_ESTIMATE_TOOLTIP : undefined}>
        <Amount $isIncome={amount > 0}>
          {isApproximate ? '≈' : ''}
          {formatTransactionAmount(amount)}
        </Amount>
      </Tooltip>
    </Container>
  );
};

export default RecurringAmountCell;
