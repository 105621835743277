import React from 'react';

import MultipleChoiceMessageBlock from 'components/assistant/blocks/MultipleChoiceMessageBlock';

import { MultipleChoiceBlock } from 'common/lib/assistant/blocks';
import { makeBlockRenderer } from 'common/lib/assistant/blocks/render';

const DemoMultipleChoiceBlockRenderer = makeBlockRenderer(MultipleChoiceBlock)(
  ({ data: { choices } }) => <MultipleChoiceMessageBlock choices={choices} />,
);

export default DemoMultipleChoiceBlockRenderer;
