import { useState, useCallback, useEffect } from 'react';

/**
 * Useful when you want to manage state to pan some viewport left or right with some
 * fixed interval.
 */
const usePan = (totalIntervals: number, viewportSizeIntervals: number) => {
  const [panCount, setPanCount] = useState<number>(0);
  const [prevTotalInvervals, setPrevTotalIntervals] = useState<number>(0);

  const maxPanCount = totalIntervals - viewportSizeIntervals;
  const canPanLeft = panCount < maxPanCount;
  const canPanRight = panCount !== 0;

  useEffect(() => {
    setPrevTotalIntervals(totalIntervals);
  }, [totalIntervals]);

  useEffect(() => {
    // If we transition to a timeframe with fewer bars than our current pan count,
    // we have to update the pan count or we could end up with a negative lowerDomainBound
    if (totalIntervals < prevTotalInvervals) {
      setPanCount(totalIntervals - Math.min(viewportSizeIntervals, totalIntervals));
    }
  }, [totalIntervals]);

  const panLeft = useCallback(
    (numIntervals: number) => {
      if (canPanLeft) {
        const nextPanCount = Math.min(maxPanCount, panCount + numIntervals);
        setPanCount(nextPanCount);
      }
    },
    [panCount, canPanLeft, maxPanCount],
  );

  const panRight = useCallback(
    (numIntervals: number) => {
      if (canPanRight) {
        const nextPanCount = Math.max(0, panCount - numIntervals);
        setPanCount(nextPanCount);
      }
    },
    [panCount, canPanRight],
  );

  return { panLeft, panRight, panCount, canPanLeft, canPanRight, setPanCount };
};

export default usePan;
