import { gql } from 'common/generated';

export const CREATE_OR_UPDATE_GOAL_V2_PLANNED_CONTRIBUTION_MUTATION = gql(`
  mutation Common_CreateOrUpdateGoalV2PlannedContributionMutation(
    $input: CreateOrUpdateGoalPlannedContributionInput!
  ) {
    createOrUpdateGoalPlannedContribution(input: $input) {
      goalPlannedContribution {
        id
        amount
        month
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);
