import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { withProps } from 'recompose';

import LoggedInRoute from 'components/lib/routing/LoggedInRoute';

import type { Route as RouteType, RouteParams } from 'lib/namedRoutes';

type RouteFlowOptions = {
  requiresLogin?: boolean;
};

export type RouteFlowInfo = [React.ComponentType<any>, RouteType<any>, RouteFlowOptions?];

export type Props = {
  steps: RouteFlowInfo[];
  /** Called when last component calls next() */
  onComplete?: () => void;
};

const RouteFlow = ({ steps, onComplete }: Props) => {
  const history = useHistory();
  return (
    <Switch>
      {steps.map(([Component, route, options], i) => {
        const { requiresLogin } = options ?? {};
        const props = {
          exact: true,
          path: route(),
          component: withProps((...props) => ({
            next: (params: RouteParams<any>) =>
              i === steps.length - 1 ? onComplete?.() : history.push(steps[i + 1][1](params)),
            skipToComplete: onComplete,
            ...props,
          }))(Component),
        };
        return requiresLogin ? (
          <LoggedInRoute key={route()} {...props} />
        ) : (
          <Route key={route()} {...props} />
        );
      })}
    </Switch>
  );
};

export default React.memo(RouteFlow);
