import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import Toggle from 'components/lib/ui/Toggle';

type Props = {
  label: string;
  subtext: React.ReactNode;
  /** If provided, this will be shown instead of a toggle. */
  replaceToggle?: React.ReactNode;
  className?: string;
} & React.ComponentProps<typeof Toggle>;

export const ToggleFieldWithTextRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.default};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  border-radius: ${({ theme }) => theme.radius.small};
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const ToggleFieldSubText = styled.span`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.small};
  max-width: 300px;
`;

const ToggleWithText = ({ label, subtext, replaceToggle, className, ...props }: Props) => (
  <ToggleFieldWithTextRoot className={className}>
    <FlexContainer column>
      <Text weight="medium" size="small">
        {label}
      </Text>
      <ToggleFieldSubText>{subtext}</ToggleFieldSubText>
    </FlexContainer>
    {replaceToggle ?? <Toggle {...props} />}
  </ToggleFieldWithTextRoot>
);

export default ToggleWithText;
