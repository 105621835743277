import type { RawAPIError, RawFieldValidationAPIError } from 'common/types';

const isFieldValidationAPIError = (error: RawAPIError): error is RawFieldValidationAPIError => {
  if (error.response?.data) {
    return Object.values(error.response.data).every((value) => Array.isArray(value));
  }
  return false;
};

export default isFieldValidationAPIError;
