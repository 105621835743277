import { useCallback, useState } from 'react';
import { isMacOs } from 'react-device-detect';

import useEventListener from 'lib/hooks/useEventListener';

type ModifierKeys = {
  /** Cmd key on Mac, Ctrl on Windows */
  mod?: boolean;
  alt?: boolean;
  shift?: boolean;
};

type UseKeyOptions = {
  onKeyDown?: (e: KeyboardEvent) => void;
  onKeyUp?: (e: KeyboardEvent) => void;
  modifiers?: ModifierKeys;
};

/**
 * Hook to listen to keyboard events.
 *
 * const isPressed = useKey('Escape', {
 *    onKeyDown: event => console.log('Escape key pressed down')
 * });
 *
 * const isPressed = useKey('Escape', {
 *    onKeyDown: event => console.log('Escape key pressed down with ctrl'),
 *    modifiers: { mod: true }
 * });
 */
const useKey = (key: string, element: HTMLElement | null | undefined, options?: UseKeyOptions) => {
  const [isPressed, setIsPressed] = useState(false);

  const checkModifiers = useCallback(
    (event: KeyboardEvent): boolean => {
      const mods = options?.modifiers || {};
      return (
        (mods.alt === undefined || event.altKey === mods.alt) &&
        (mods.shift === undefined || event.shiftKey === mods.shift) &&
        (mods.mod === undefined || (isMacOs ? event.metaKey : event.ctrlKey) === mods.mod)
      );
    },
    [options?.modifiers],
  );

  const onDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key && checkModifiers(event)) {
        setIsPressed(true);
        options?.onKeyDown?.(event);
      }
    },
    [setIsPressed, key, options, checkModifiers],
  );

  const onUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) {
        setIsPressed(false);
        options?.onKeyUp?.(event);
      }
    },
    [setIsPressed, key, options],
  );

  useEventListener(element, 'keydown', onDown);
  useEventListener(element, 'keyup', onUp);

  return isPressed;
};

export default useKey;
