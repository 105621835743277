import { DateTime } from 'luxon';

export const MOCK_GOALS = [
  {
    name: 'Example Vacation Goal',
    icon: '✈️',
    completedAt: null,
    targetDate: DateTime.local().startOf('month').plus({ months: 10 }).toISODate(),
    targetAmount: 10000,
    contributedBalance: 8750,
  },
  {
    name: 'Example Emergency Fund',
    icon: '🏦',
    completedAt: null,
    targetDate: DateTime.local().startOf('month').plus({ months: 11 }).toISODate(),
    targetAmount: 25000,
    contributedBalance: 5865,
  },
  {
    name: 'Example Custom Goal',
    icon: '🏠',
    completedAt: null,
    targetDate: DateTime.local().startOf('month').plus({ months: 12 }).toISODate(),
    targetAmount: 600,
    contributedBalance: 100,
  },
];
