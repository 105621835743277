import React from 'react';

import type { Props as SubscriptionModalProps } from 'components/settings/billing/SubscriptionModal';
import SubscriptionModal from 'components/settings/billing/SubscriptionModal';
import CancelSubscriptionDiscountFlow from 'components/settings/billing/cancel/CancelSubscriptionDiscountFlow';
import SubscriptionEndReminderFlow from 'components/settings/billing/reminder/SubscriptionEndReminderFlow';
import Flow from 'components/utils/Flow';

import { getDaysLeftOfSubscription } from 'common/lib/billing/Billing';

import { PaymentPeriod } from 'common/generated/graphql';

const MIN_DAYS_SUBSCRIPTION_FOR_REMINDER_OFFER = 46;

const ManageSubscriptionFlow = (props: Omit<SubscriptionModalProps, 'next'>) => {
  const { billingPeriod, currentPeriodEndsAt } = props;

  const daysLeftOfSubscription = getDaysLeftOfSubscription(currentPeriodEndsAt);

  const isElligibleForABTest =
    billingPeriod === PaymentPeriod.YEARLY &&
    daysLeftOfSubscription >= MIN_DAYS_SUBSCRIPTION_FOR_REMINDER_OFFER;

  // The Reminder is offered only for users with yearly subscription and
  // more than MIN_DAYS_SUBSCRIPTION_FOR_REMINDER_OFFER days left
  const steps = isElligibleForABTest
    ? [SubscriptionModal, SubscriptionEndReminderFlow, CancelSubscriptionDiscountFlow]
    : [SubscriptionModal, CancelSubscriptionDiscountFlow];

  return (
    <Flow
      initialProps={props}
      // @ts-ignore
      steps={steps}
    />
  );
};

export default ManageSubscriptionFlow;
