import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';

import type { ValidationOptions } from 'common/lib/form/validation';
import { color, spacing } from 'common/lib/theme/dynamic';
import fieldStyleMixin from 'lib/styles/fieldStyleMixin';

export type Props = Pick<React.HTMLProps<HTMLSelectElement>, 'onChange' | 'value' | 'id'> &
  Pick<ValidationOptions, 'name'> & {
    children?: React.ReactNode;
    small?: boolean;
  };

const Root = styled(Flex)`
  position: relative;
`;

const SelectInput = styled.select`
  width: 100%;
  background-color: ${color.white};

  ${fieldStyleMixin};

  padding-right: 32px;

  ::-ms-expand {
    display: none; /* remove default arrow on ie10 and ie11 */
  }

  & option {
    color: ${color.text};
    background-color: ${color.white};
  }
`;

const Arrow = styled(FiChevronDown)`
  position: absolute;
  right: ${spacing.xsmall};
  pointer-events: none;
  color: ${color.textLight};
`;

const NativeSelect = (props: Props) => (
  <Root center>
    <SelectInput {...props} />
    <Arrow size={16} />
  </Root>
);

export default NativeSelect;
