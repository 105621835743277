import React, { useState } from 'react';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';

import { copyToClipboard } from 'utils/clipboard';

const DEFAULT_TEXT = 'Click to copy';
const COPIED_TEXT = 'Copied!';

const StyledText = styled(Text)`
  cursor: pointer;
`;

const ClickToCopyCode = ({ value }: { value: string }) => {
  const [text, setText] = useState<string>(DEFAULT_TEXT);
  return (
    <Tooltip
      place="top"
      effect="solid"
      content={text}
      fitContent={false}
      afterHide={() => setText(DEFAULT_TEXT)}
    >
      <StyledText
        onClick={() => {
          setText(COPIED_TEXT);
          copyToClipboard(value);
        }}
      >
        {value}
      </StyledText>
    </Tooltip>
  );
};

export default ClickToCopyCode;
