import * as R from 'ramda';

import { SecurityType } from 'common/lib/investments/types';
import type { CleanedAllocationEntry } from 'common/lib/investments/types';

import type { Web_GetPortfolio_portfolio_aggregateHoldings_edges_node } from 'common/generated/graphQlTypes/Web_GetPortfolio';
import type { Web_GetAllocationQuery } from 'common/generated/graphql';
import type { ThemeType } from 'common/types/Styles';

type Holding = Web_GetPortfolio_portfolio_aggregateHoldings_edges_node;
type SortableAllocationEntry = { allocationPercent: number };

export const SECURITY_TYPE_TABLE_ORDER: SecurityType[] = [
  SecurityType.ETF,
  SecurityType.FIXED_INCOME,
  SecurityType.MUTUAL_FUND,
  SecurityType.LOAN,
  SecurityType.DERIVATIVE,
  SecurityType.EQUITY,
  SecurityType.CRYPTOCURRENCY,
  SecurityType.CASH,
  SecurityType.OTHER,
];

export const SECURITY_DISPLAY_TYPE_TABLE_ORDER = [
  'ETF',
  'Fixed Income',
  'Mutual Fund',
  'Loan',
  'Derivative',
  'Stock',
  'Cryptocurrency',
  'Cash',
  'Other',
];

const getSecurityTypeColors = ({ color }: ThemeType): Record<SecurityType, string> => ({
  stock: color.purple,
  equity: color.purple,
  mutual_fund: color.green,
  etf: color.blue,
  derivative: color.green,
  other: color.yellow,
  cash: color.orange,
  cryptocurrency: color.red,
  fixed_income: color.indigo,
  loan: color.red,
});

const getSecurityTypeFromNode = (node: Holding) => {
  if (node.security) {
    return node.security.type ?? 'other';
  }
  if (node.holdings?.[0]) {
    return node.holdings[0].type ?? 'other';
  }
  return 'other';
};

export const sortHoldingsBySecurityType = (data: Holding[]) =>
  R.sort(
    R.ascend((node) => R.indexOf(getSecurityTypeFromNode(node), SECURITY_TYPE_TABLE_ORDER)),
    data,
  );

export const getCleanedAllocationData = (
  data: Pick<Web_GetAllocationQuery, 'portfolio'> | undefined,
) =>
  (data?.portfolio?.allocationSimple ?? []).filter(
    (item): item is CleanedAllocationEntry => !R.isNil(item),
  );

export const sortAllocationData = <TData extends SortableAllocationEntry>(data: TData[]) =>
  R.sort(R.descend(R.prop('allocationPercent')), data);

export const getSecurityTypeColor = (type: SecurityType, theme: ThemeType) =>
  getSecurityTypeColors(theme)[type] ?? theme.color.grayLight;

// eslint-disable-next-line @typescript-eslint/ban-types
export const sortByNullablePath = <T extends object>(path: string[], row1: T, row2: T) => {
  const firstField = R.pathOr(null, path, row1);
  const secondField = R.pathOr(null, path, row2);
  if (R.isNil(firstField)) {
    return -1;
  }
  if (R.isNil(secondField)) {
    return 1;
  }

  return firstField - secondField;
};
