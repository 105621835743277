import { useQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Checkbox from 'components/lib/form/Checkbox';
import SettingsCard from 'components/lib/layouts/SettingsCard';
import CardHeader from 'components/lib/ui/CardHeader';
import Column from 'components/lib/ui/Column';
import SettingsTitle from 'components/settings/SettingsTitle';
import NotificationAdditionalPreferences from 'components/settings/notifications/NotificationAdditionalPreferences';

import {
  GET_NOTIFICATION_PREFERENCES,
  UPDATE_NOTIFICATION_PREFERENCE,
} from 'common/lib/graphQl/notificationPreferences';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';

import type { GetNotificationPreferences } from 'common/generated/graphQlTypes/GetNotificationPreferences';
import type {
  UpdateNotificationPreferences,
  UpdateNotificationPreferencesVariables,
} from 'common/generated/graphQlTypes/UpdateNotificationPreferences';
import type { UpdateNotificationPreferencesInput } from 'common/generated/graphQlTypes/globalTypes';
import type { NotificationChannel } from 'common/types';

const SettingsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 0fr 0fr;
`;

const SettingsGridItem = styled.div<{ noFooter?: boolean }>`
  border-bottom: ${({ noFooter }) => (noFooter ? 0 : 1)}px solid
    ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-bottom: ${({ noFooter, theme }) =>
    noFooter ? theme.spacing.xsmall : theme.spacing.xlarge};
`;

const NotificationTypeInfo = styled(SettingsGridItem)``;

const PlatformLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSize.base};
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const AdditionalPreferences = styled(SettingsGridItem)`
  grid-column-end: span 3;
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: 0px;
`;

const CheckBoxGridItem = styled(SettingsGridItem)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CHANNELS: NotificationChannel[] = ['EMAIL', 'PUSH'];

const NotificationSettings = () => {
  const { isDemoHousehold } = useDemoHousehold();
  const { data } = useQuery<GetNotificationPreferences>(GET_NOTIFICATION_PREFERENCES);
  const [updatePreferences] = useMutation<
    UpdateNotificationPreferences,
    UpdateNotificationPreferencesVariables
  >(UPDATE_NOTIFICATION_PREFERENCE);

  const [lastSuccessfulUpdate, setLastSuccessfulUpdate] = useState<string | undefined>(undefined);

  const sendUpdate = async (input: UpdateNotificationPreferencesInput) => {
    const response = await updatePreferences({
      variables: { input },
      optimisticResponse: {
        updateNotificationPreferences: {
          __typename: 'UpdateNotificationPreferences',
          // @ts-ignore we don't have all the fields, but it still works...
          notificationPreference: {
            __typename: 'NotificationPreference',
            ...input,
          },
        },
      },
    });
    const errors = response?.data?.updateNotificationPreferences?.errors;
    if (errors) {
      // TODO: Handle errors
    } else if (input.additionalPreferences) {
      // If an additionalPreference was saved we want to show success label
      setLastSuccessfulUpdate(
        response.data?.updateNotificationPreferences?.notificationPreference?.id,
      );
    }
  };

  const handlePlatformToggle = (id: string, platform: string, isOn: boolean) =>
    sendUpdate({ id, [platform]: isOn });

  const notificationTypes = R.sortBy((x) => x.group || '', data?.notificationPreferences || []);
  return (
    <Column md={9}>
      <SettingsCard isDemo={isDemoHousehold}>
        <Helmet>
          <title>Notification Settings</title>
        </Helmet>
        {data && (
          <SettingsGrid>
            <CardHeader title="Notifications" />
            <PlatformLabel>Email</PlatformLabel>
            <PlatformLabel>Mobile</PlatformLabel>
            {notificationTypes.map((notificationType) => {
              const showAdditionalPreferences = !!notificationType.additionalPreferencesMeta;
              return (
                <React.Fragment key={notificationType.id}>
                  <NotificationTypeInfo noFooter={showAdditionalPreferences}>
                    <SettingsTitle
                      title={notificationType.title ?? ''}
                      description={notificationType.description ?? ''}
                    />
                  </NotificationTypeInfo>
                  {CHANNELS.map((channel) => (
                    <CheckBoxGridItem
                      key={`${notificationType.id}-${channel}`}
                      noFooter={showAdditionalPreferences}
                    >
                      <Checkbox
                        checked={notificationType[channel]}
                        onChange={({ target: { checked } }) =>
                          handlePlatformToggle(notificationType.id, channel, checked)
                        }
                      />
                    </CheckBoxGridItem>
                  ))}
                  {showAdditionalPreferences && (
                    <AdditionalPreferences>
                      <NotificationAdditionalPreferences
                        notificationPreference={notificationType}
                        onSubmit={(values) =>
                          sendUpdate({ id: notificationType.id, additionalPreferences: values })
                        }
                        lastSuccessfulUpdate={lastSuccessfulUpdate}
                      />
                    </AdditionalPreferences>
                  )}
                </React.Fragment>
              );
            })}
          </SettingsGrid>
        )}
      </SettingsCard>
    </Column>
  );
};

export default NotificationSettings;
