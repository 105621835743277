import React from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import RouteLink from 'components/lib/ui/link/RouteLink';

import type { RecurringTransactionItem } from 'common/lib/recurring/types';

import routes from 'constants/routes';

import type { Web_GetAllRecurringTransactionItems_recurringTransactionStreams_account } from 'common/generated/graphQlTypes/Web_GetAllRecurringTransactionItems';

const ACCOUNT_LOGO_SIZE_PX = 16;

type Props = {
  account:
    | RecurringTransactionItem['account']
    | Web_GetAllRecurringTransactionItems_recurringTransactionStreams_account;
};

const StyledAccountLogo = styled(AccountLogo)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const Container = styled(FlexContainer)`
  min-width: 200px;
`;

const RecurringAccount = ({ account }: Props) => (
  <RouteLink to={routes.accounts.accountDetails({ id: account!.id })} stealthy>
    <Container alignCenter>
      <StyledAccountLogo
        logoUrl={account?.logoUrl}
        icon={account?.icon}
        size={ACCOUNT_LOGO_SIZE_PX}
      />
      <Text>{account?.displayName}</Text>
    </Container>
  </RouteLink>
);

export default RecurringAccount;
