import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import { useCallback } from 'react';

import { CHANGE_SUBSCRIPTION_REFETCH_QUERIES } from 'constants/graphql';

import { gql } from 'common/generated/gql';
import type {
  ChangeStripeSubscriptionMutation,
  MutationChangeStripeSubscriptionArgs,
} from 'common/generated/graphql';

/** Hook used to change a user's stripe subscription to a new price id. */
const useChangeSubscription = () => {
  const [changeSubscriptionMutation] = useMutation<
    ChangeStripeSubscriptionMutation,
    MutationChangeStripeSubscriptionArgs
  >(CHANGE_SUBSCRIPTION, { refetchQueries: CHANGE_SUBSCRIPTION_REFETCH_QUERIES });

  const changeSubscription = useCallback(
    async (stripePriceId: string, stripePromoCode: string | null) => {
      const { data } = await changeSubscriptionMutation({
        variables: { input: { stripePriceId, stripePromoCode } },
      });
      // @ts-ignore
      const error = data?.changeStripeSubscription?.errors;
      if (error) {
        const capitalize = R.replace(/^./, R.toUpper);
        throw new Error(capitalize(error.message));
      }
    },
    [changeSubscriptionMutation],
  );

  return { changeSubscription };
};

const CHANGE_SUBSCRIPTION = gql(`
  mutation Web_ChangeSubscription($input: ChangeStripeSubscriptionMutationInput!) {
    changeStripeSubscription(input: $input) {
      subscription {
        id
        ...SubscriptionFields
      }
      errors {
        message
      }
    }
  }
`);

export default useChangeSubscription;
