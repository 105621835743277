import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { useDispatch } from 'lib/hooks';
import { changePasswordAction } from 'state/user/thunks';

const FormContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  onDone: () => void;
  onCancel: () => void;
};

const EditPasswordModal = ({ onDone, onCancel }: Props) => {
  const dispatch = useDispatch();

  return (
    <ModalCard title="Update Password">
      <Form
        initialValues={{ current_password: '', new_password: '' }}
        onSubmit={async (values) => {
          await dispatch(changePasswordAction(values));
          onDone();
        }}
      >
        <FormContainer>
          <TextField
            name="current_password"
            label="Current Password"
            password
            required
            autoComplete="current-password"
          />
          <TextField
            name="new_password"
            label="New Password"
            password
            required
            autoComplete="new-password"
          />
          <TextField
            name="confirm_new_password"
            label="Confirm New Password"
            sameAs="new_password"
            password
            required
            autoComplete="confirm-new-password"
          />
        </FormContainer>
        <CardFooter>
          <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
          <FormSubmitButton size="small">Save</FormSubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

export default EditPasswordModal;
