import React from 'react';

export type OverlayContextType<T = any> = {
  /** Close the popover in the current context */
  close: () => void;
  /** Open the popover in the current context */
  open: (triggerContext?: T) => void;
  /** Toggles the open state between opened/closed */
  toggleOpen: () => void;
  /** Whether the popover is currently visible */
  isOpen: boolean;
  triggerContext?: T;
};

const OverlayContext = React.createContext<OverlayContextType>(undefined as any);

export default OverlayContext;
