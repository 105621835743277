import * as R from 'ramda';
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import usePrevious from 'common/lib/hooks/usePrevious';
import noop from 'common/utils/noop';
import FlowContext from 'lib/contexts/FlowContext';

export type StepProps<Params, NextParams> = Params & {
  next: NextParams extends undefined ? () => void : (params: Omit<NextParams, 'next'>) => void;
  // Make sure components also type this as nullable
  goBack?: () => void;
};

export type Step<Params, NextParams> = React.FunctionComponent<StepProps<Params, NextParams>>;

type Props<First, Last> = {
  /** Called when last component calls next() */
  onComplete?: (params: Last) => void;
  /** Called when first component calls goBack() */
  onBack?: () => void;
  // steps: specified in overloads
  initialProps?: First;
};

/**
 * Component used to pass the result of one component as props to the next in a chain.
 * Provides forward/back methods to components that preserve existing state.
 * Also see: FlowExample.tsx
 *
 * Usage:
 * const StepOne = ({ next: (params: { passedFromOne: string }) => void} => (
 *  <button onClick={() => next({ passedFromOne: 'test' })}>Next</button>
 * )
 *
 * const StepTwo = ({ passedFromOne: string; next: () => void; goBack: () => void }) => (
 *  <div>{passedFromOne}</div>
 *  <button onClick={goBack}>Go back</button>
 * )
 *
 * const StepThree = ({ goBack: () => void }) => (
 *  <button onClick={goBack}>Go back</button>
 * )
 *
 * // And so on... as many steps as you want
 *
 * <Flow steps={[StepOne, StepTwo, StepThree]} />
 *
 * Currently we have overloads for up to 5 steps. If we need more in the future then we should add more overloads.
 * Variadic tuples may help with this....
 */
function Flow<First, Last = undefined>(
  props: Props<First, Last> & { steps: [Step<First, Last>] },
): React.ReactElement;
function Flow<First, B, Last = undefined>(
  props: Props<First, Last> & { steps: [Step<First, B>, Step<B, Last>] },
): React.ReactElement;
function Flow<First, B, C, Last = undefined>(
  props: Props<First, Last> & { steps: [Step<First, B>, Step<B, C>, Step<C, Last>] },
): React.ReactElement;
function Flow<First, B, C, D, Last = undefined>(
  props: Props<First, Last> & { steps: [Step<First, B>, Step<B, C>, Step<C, D>, Step<D, Last>] },
): React.ReactElement;
function Flow<First, B, C, D, E, Last = undefined>(
  props: Props<First, Last> & {
    steps: [Step<First, B>, Step<B, C>, Step<C, D>, Step<D, E>, Step<E, Last>];
  },
): React.ReactElement;
function Flow({
  steps,
  onComplete,
  onBack = noop,
  initialProps,
}: Props<any, any> & { steps: Step<any, any>[] }): React.ReactElement {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [propsByIndex, setPropsByIndex] = useState<{ [index: number]: any }>({ 0: initialProps });
  const previousIndex = usePrevious(currentIndex);

  useEffect(() => {
    setPropsByIndex((prev) => ({
      ...prev,
      0: initialProps,
    }));
  }, [initialProps]);

  const incrementStep = useCallback(
    (nextProps?: any, increment = 1) => {
      if (currentIndex + increment >= steps.length) {
        return onComplete?.(nextProps);
      }

      setCurrentIndex(R.add(increment));
    },
    [onComplete, setCurrentIndex, steps, currentIndex],
  );

  const next = useCallback(
    (nextProps?: any) => {
      setPropsByIndex((prev) => ({
        ...prev,
        [currentIndex + 1]: nextProps,
      }));

      incrementStep(nextProps);
    },
    [currentIndex, incrementStep],
  );

  const goBack = useCallback(() => setCurrentIndex((prev) => prev - 1), [setCurrentIndex]);

  const context = useMemo(
    () => ({
      currentIndex,
      previousIndex,
      next,
      goBack,
      skipStep: (props?: any) => incrementStep(props, 2),
      skipToComplete: (props?: any) => onComplete?.(props),
    }),
    [currentIndex, previousIndex, next, goBack, incrementStep, onComplete],
  );

  const CurrentComponent = steps[currentIndex];

  return (
    <FlowContext.Provider value={context}>
      <CurrentComponent
        {...propsByIndex[currentIndex]}
        next={next}
        goBack={currentIndex > 0 ? goBack : onBack}
      />
    </FlowContext.Provider>
  );
}

export default Flow;
