import * as RA from 'ramda-adjunct';

import { CollaboratesOnFinancesValue } from 'common/constants/onboarding';

import type { GetOnboardingFinancialGoals } from 'common/generated/graphQlTypes/GetOnboardingFinancialGoals';
import type { SetUserOnboardingFinancialGoalsVariables } from 'common/generated/graphQlTypes/SetUserOnboardingFinancialGoals';

type FinancialGoalsQueryData = GetOnboardingFinancialGoals | undefined;

export const getOtherFinancialGoalId = (data: FinancialGoalsQueryData): GraphQlUUID | undefined =>
  data?.onboardingFinancialGoals.find(({ name }) => name.toLowerCase() === 'other')?.id;

export const isOtherFinancialGoalOptionSelected = (
  data: FinancialGoalsQueryData,
  selectedIds: GraphQlUUID[],
) => {
  const otherId = getOtherFinancialGoalId(data);

  if (!otherId) {
    return false;
  }

  return selectedIds.includes(otherId);
};

export const formatFinancialPrioritiesMutationInput = (
  data: FinancialGoalsQueryData,
  selectedIds: GraphQlUUID[],
  otherText: string | null,
): SetUserOnboardingFinancialGoalsVariables['input'] => {
  const otherId = getOtherFinancialGoalId(data);

  return selectedIds.map((id) => ({
    onboardingFinancialGoal: id,
    ...(id === otherId && RA.isNotNil(otherText) ? { otherText } : {}),
  }));
};

export const getUserSelectedFinancialGoalsIds = (data: FinancialGoalsQueryData) =>
  data?.userOnboardingFinancialGoals.map(
    ({ onboardingFinancialGoal }) => onboardingFinancialGoal.id,
  ) ?? [];

export const getCollaboratesOnFinancesValueFromAnswers = (
  manageWithPartner: boolean,
  manageWithProfessional: boolean,
) => {
  if (manageWithPartner && manageWithProfessional) {
    return CollaboratesOnFinancesValue.YesWithPartnerAndProfessional;
  } else if (manageWithPartner) {
    return CollaboratesOnFinancesValue.YesWithPartner;
  } else if (manageWithProfessional) {
    return CollaboratesOnFinancesValue.YesWithProfessional;
  }

  return CollaboratesOnFinancesValue.NoJustMe;
};
