import React, { useState, useCallback, useRef } from 'react';

import type { Props, ModalHandles } from 'components/lib/ui/Modal';
import Modal from 'components/lib/ui/Modal';

/**
 * Hook to manage ephemeral modal state
 *
 * const [Modal, { open, close }] = useModal();
 *
 * <>
 *   <button onClick={open}>Open Modal</button>
 *   <Modal>
 *     <Card>
 *       Modal contents
 *       <button onClick={close}>Close Modal</button>
 *     </Card>
 *   </Modal>
 * </>
 */
const useModal = <ContextT extends unknown>(initiallyOpen = false) => {
  const ref = useRef<ModalHandles>(null);

  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const [context, setContext] = useState<ContextT | undefined>(undefined);

  const open = useCallback((context?: ContextT) => {
    setContext(context);
    setIsOpen(true);
  }, []);
  const close = useCallback(() => ref.current?.close(), []);
  const toggleOpen = useCallback(() => (isOpen ? close() : open()), [open, close, isOpen]);

  const ModalComponent = useCallback(
    ({ onClose, children, ...props }: Props) =>
      isOpen ? (
        <Modal
          {...props}
          ref={ref}
          onClose={() => {
            onClose?.();
            setIsOpen(false);
          }}
        >
          {children}
        </Modal>
      ) : null,
    [isOpen, setIsOpen],
  );

  return [ModalComponent, { open, close, toggleOpen, isOpen, context }] as const;
};

export default useModal;
