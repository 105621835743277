import React from 'react';

import MessageBubble from 'components/assistant/MessageBubble';
import Markdown from 'components/lib/ui/Markdown';

import { TextBlock } from 'common/lib/assistant/blocks';
import { makeBlockRenderer } from 'common/lib/assistant/blocks/render';
import useTheme from 'lib/hooks/useTheme';

const TextBlockRenderer = makeBlockRenderer(TextBlock)(({
  data: { content },
  nextBlockInGroup,
  previousBlockInGroup,
}) => {
  const theme = useTheme();

  return (
    <MessageBubble
      borderTopLeftRadius={previousBlockInGroup ? '0' : theme.radius.medium}
      borderBottomLeftRadius={nextBlockInGroup || !previousBlockInGroup ? '0' : theme.radius.medium}
    >
      <Markdown source={content} />
    </MessageBubble>
  );
});

export default TextBlockRenderer;
