import { useMutation } from '@apollo/client';
import React, { useState } from 'react';

import Confirmation from 'components/lib/ui/Confirmation';

import { DELETE_TRANSACTIONS } from 'common/lib/graphQl/transactions';
import { errorToast } from 'lib/ui/toast';

import { SETTINGS_DATA } from 'common/constants/copy';

import { ErrorCode } from 'common/generated/graphQlTypes/globalTypes';

type Props = {
  beforeDate: string;
  includeSynced: boolean;
  includeManual: boolean;
  includeUploaded: boolean;
  expectedAffectedTransactionsCount: number;
  onCancel: () => void;
  onDelete: () => void;
};

const DeleteTransactionConfirmation = ({
  beforeDate,
  includeSynced,
  includeManual,
  includeUploaded,
  expectedAffectedTransactionsCount,
  onCancel,
  onDelete,
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const [deleteTransactions] = useMutation(DELETE_TRANSACTIONS);

  const performDelete = async () => {
    setIsDeleting(true);

    const { data } = await deleteTransactions({
      variables: {
        input: {
          beforeDate,
          includeSynced,
          includeManual,
          includeUploaded,
          expectedAffectedTransactionsCount,
        },
      },
    });

    if (data?.deleteTransactions?.success) {
      onDelete();
    } else {
      const errors = data?.deleteTransactions?.errors;

      if (errors?.code === ErrorCode.DELETE_TRANSACTIONS_HISTORY_UNAVAILABLE) {
        errorToast(errors?.message);
      } else {
        errorToast(SETTINGS_DATA.ERRORS.DEFAULT);
      }

      onCancel();
    }
  };

  return (
    <Confirmation
      title={SETTINGS_DATA.CONFIRMATION_MESSAGE.TITLE(expectedAffectedTransactionsCount)}
      confirm={SETTINGS_DATA.BUTTONS.DELETE}
      isLoading={isDeleting}
      onCancel={onCancel}
      onConfirm={performDelete}
      useDangerButton
    >
      <div>{SETTINGS_DATA.DISCLAIMER}</div>
    </Confirmation>
  );
};

export default DeleteTransactionConfirmation;
