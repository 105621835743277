import * as React from 'react';

import ErrorMessage from 'components/errors/ErrorMessage';
import FlexContainer from 'components/lib/ui/FlexContainer';

const ErrorCard = () => (
  <FlexContainer column full center>
    <ErrorMessage buttonText="Refresh the page" onButtonClick={() => location.reload()} />
  </FlexContainer>
);

export default ErrorCard;
