import React from 'react';

import type { Props as RequestRefundModalProps } from 'components/support/RequestRefundModal';
import RequestRefundModal from 'components/support/RequestRefundModal';
import RequestRefundSuccessModal from 'components/support/RequestRefundSuccessModal';
import Flow from 'components/utils/Flow';

type Props = {
  onComplete: () => void;
  onBack?: () => void;
} & Pick<RequestRefundModalProps, 'initialValues'>;

const RequestRefundFlow = ({ onComplete, onBack, initialValues }: Props) => (
  <Flow
    initialProps={{ initialValues }}
    steps={[RequestRefundModal, RequestRefundSuccessModal]}
    onComplete={onComplete}
    onBack={onBack}
  />
);

export default RequestRefundFlow;
