// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 2200783312
import { createAction } from 'typesafe-actions';

export const toggleExpandedGroupRow = createAction('PLAN/TOGGLE_EXPANDED_GROUP_ROW')<string>();

export const togglePlanGroupCollapsed = createAction('PLAN/TOGGLE_PLAN_GROUP_COLLAPSED')<string>();

export const toggleUnplannedExpandedForGroup = createAction(
  'PLAN/TOGGLE_UNPLANNED_EXPANDED_FOR_GROUP',
)<string>();
