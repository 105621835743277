import type { Transition, Variants } from 'framer-motion';

export const DEFAULT_MOTION_TRANSITION: Transition = { duration: 0.2, ease: 'easeInOut' };

export const DEFAULT_MOTION_TRANSITION_FAST: Transition = { type: 'tween', duration: 0.06 };

export const POPOVER_ANIMATION_VARIANTS: Variants = {
  enter: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.96 },
};
