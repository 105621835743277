import * as RA from 'ramda-adjunct';

import type { FeatureFlags } from 'common/lib/external/splitio';

import type { DataProviderLegacy } from 'common/generated/graphQlTypes/globalTypes';

export type DataProviderConfig<FeatureFlagName> = {
  provider: DataProviderLegacy;
  isEnabled?: boolean;
  featureFlag?: FeatureFlagName;
};

const useSupportedDataProvidersCommon = <FeatureFlagName extends string>(
  config: DataProviderConfig<FeatureFlagName>[],
  useFeatureFlags: (names: FeatureFlagName[]) => FeatureFlags<any>,
): string[] => {
  const flagNames = config.map(({ featureFlag }) => featureFlag).filter(RA.isNotNil);
  const flags = useFeatureFlags(flagNames);

  const dataProviders = config.filter(({ isEnabled, featureFlag }) => {
    if (RA.isNotNil(isEnabled) && !isEnabled) {
      return false;
    }
    if (featureFlag && flags[featureFlag]?.treatment !== 'on') {
      return false;
    }
    return true;
  });

  return dataProviders.map(({ provider }) => provider.toLowerCase()); // backend expects lower case
};

export default useSupportedDataProvidersCommon;
