import styled from 'styled-components';

import variables from 'common/lib/theme/variables';

const Shield = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${variables.color.background.modalOverlay};
`;

export default Shield;
