import type { QueryHookOptions } from '@apollo/client';
import { useCallback } from 'react';

import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';

type MutationT = NonNullable<(typeof CATEGORIES_QUERY)['__apiType']>;
type QueryT = ReturnType<MutationT>;
type VariablesT = Parameters<MutationT>[0];

/**
 * Hook used to get categories and category groups on demand.
 *
 * Uses a single query, so we only have to load once and just find by id on the client side.
 */
const useCategories = (options?: QueryHookOptions<QueryT, VariablesT>) => {
  const { data, ...queryData } = useQuery(CATEGORIES_QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });
  const { categoryGroups = [], categories = [] } = data ?? {};

  const getCategoryGroup = useCallback(
    (groupId: string) => categoryGroups.find(({ id }) => id === groupId),
    [categoryGroups],
  );

  const getCategory = useCallback(
    (categoryId: string) => categories.find(({ id }) => id === categoryId),
    [categories],
  );

  return {
    categoryGroups,
    categories,
    getCategoryGroup,
    getCategory,
    ...queryData,
  };
};

const CATEGORIES_QUERY = gql(`
  query Common_GetCategories($includeSystemDisabledCategories: Boolean) {
    categoryGroups {
      id
      name
      order
      type
      groupLevelBudgetingEnabled
      budgetVariability
      rolloverPeriod {
        id
        startMonth
        startingBalance
        frequency
        targetAmount
        type
      }
      categories {
        id
      }
    }

    categories(includeDisabledSystemCategories: $includeSystemDisabledCategories) {
      id
      name
      order
      icon
      isSystemCategory
      excludeFromBudget
      budgetVariability
      isDisabled
      transactionsCount
      group {
        id
        type
      }
      rolloverPeriod {
        id
        startMonth
        startingBalance
        frequency
        targetAmount
        type
      }
    }
  }
`);

export default useCategories;
