import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';

import SharePopoverContent from 'components/cashFlows/SharePopoverContent';
import Icon from 'components/lib/ui/Icon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';

import CashFlowContext from 'lib/contexts/CashFlowContext';
import useTheme from 'lib/hooks/useTheme';
import type { DownloadChartOptions } from 'lib/ui/charts';
import { onDownloadChartAsPng, onGenerateChartForDownload } from 'lib/ui/charts';

import * as COPY from 'common/constants/copy';

const ChevronDownIcon = styled(Icon).attrs({ name: 'chevron-down', size: 12 })`
  transform: translateY(0);
`;

const Button = styled(DefaultButton)`
  gap: ${({ theme }) => theme.spacing.xsmall};
`;
export type Props<T extends HTMLElement = HTMLElement> = {
  onSelectElement: () => Maybe<T>;
} & Partial<Omit<DownloadChartOptions<T>, 'element'>> &
  Pick<DownloadChartOptions<T>, 'chartType' | 'fileName'>;

const ShareChartButton = ({ onSelectElement, ...props }: Props) => {
  const theme = useTheme();
  const { shareDisabled, hideAmounts, toggleHideAmounts, unhideAmounts } =
    useContext(CashFlowContext);

  const onClickShare = useCallback(
    async (transparentBg: boolean) => {
      const element = onSelectElement();

      const canvas = await onGenerateChartForDownload({
        element,
        backgroundColor: transparentBg ? 'transparent' : theme.color.white,
        ...props,
      });

      if (canvas && props.chartType) {
        await onDownloadChartAsPng(canvas, props.fileName, {
          chart: props.chartType,
          view: props.view,
        }).then(unhideAmounts);
      }
    },
    [onSelectElement, unhideAmounts, theme.color.white, props],
  );

  return (
    <OverlayTrigger
      placement="bottom-end"
      overlay={({ close }) => (
        <Popover>
          <SharePopoverContent
            hideAmounts={hideAmounts}
            toggleHideAmounts={toggleHideAmounts}
            onClickShare={(transparentBg) => onClickShare(transparentBg).then(close)}
          />
        </Popover>
      )}
    >
      {({ toggleOpen }) => (
        <Button onClick={!shareDisabled ? toggleOpen : undefined} disabled={shareDisabled}>
          {COPY.CASH_FLOW.SHARE_BUTTON} <ChevronDownIcon />
        </Button>
      )}
    </OverlayTrigger>
  );
};

export default React.memo(ShareChartButton);
