import pluralize from 'pluralize';
import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';
import Tooltip from 'components/lib/ui/Tooltip';

import { TRANSACTION_ROW_ICON_SIZE_PX } from 'constants/transactions';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: ${TRANSACTION_ROW_ICON_SIZE_PX}px;
  width: ${TRANSACTION_ROW_ICON_SIZE_PX}px;
  border-radius: ${({ theme }) => theme.radius.round};
  transition: ${({ theme }) => theme.transition.default};
  user-select: none;
`;

const AttachmentIcon = styled(Icon).attrs({ name: 'paperclip' })`
  width: ${({ theme }) => theme.spacing.small};
  height: ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.color.textLight};

  &:hover {
    color: ${({ theme }) => theme.color.text};
  }
`;

type Props = {
  count: number;
  onClick?: () => void;
};

const TransactionAttachmentIcon = ({ count, onClick }: Props) => (
  <Tooltip content={pluralize('attachment', count, true)}>
    <Root onClick={onClick}>
      <AttachmentIcon />
    </Root>
  </Tooltip>
);

export default TransactionAttachmentIcon;
