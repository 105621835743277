import React from 'react';

import InvestmentsHoldingDrawerBody from 'components/investments/drawer/InvestmentsHoldingDrawerBody';
import SideDrawer from 'components/lib/ui/SideDrawer';

import type { Web_GetPortfolio_portfolio_aggregateHoldings_edges_node } from 'common/generated/graphQlTypes/Web_GetPortfolio';

type Props = {
  holdingAggregate?: Web_GetPortfolio_portfolio_aggregateHoldings_edges_node;
  onClose: () => void;
};

const InvestmentsHoldingDrawer = ({ holdingAggregate, onClose }: Props) => {
  const [firstHolding] = holdingAggregate?.holdings ?? [];
  return (
    <SideDrawer onClose={onClose}>
      <InvestmentsHoldingDrawerBody
        totalQuantity={holdingAggregate?.quantity}
        totalValue={holdingAggregate?.totalValue}
        securityId={holdingAggregate?.security?.id}
        securityName={holdingAggregate?.security?.name ?? firstHolding?.name}
        securityTicker={holdingAggregate?.security?.ticker ?? firstHolding?.ticker}
        securityCurrentPrice={holdingAggregate?.security?.currentPrice}
        holdings={holdingAggregate?.holdings}
      />
    </SideDrawer>
  );
};

export default InvestmentsHoldingDrawer;
