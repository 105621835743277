import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { useCallback } from 'react';

import useDebounce from 'common/lib/hooks/useDebounce';
import { EMPTY_RULE } from 'common/lib/transactions/Rules';

import { gql } from 'common/generated/gql';
import type {
  TransactionRulePreviewInput,
  PreviewTransactionRuleQuery,
} from 'common/generated/graphql';

const DEBOUNCE_MS = 500;

const usePreviewRuleQuery = (rule?: TransactionRulePreviewInput) => {
  const debouncedRule = useDebounce(rule, DEBOUNCE_MS);

  // Query is skipped if rule is nil (invalid)
  const {
    data,
    loading: fetching,
    fetchMore,
  } = useQuery(PREVIEW_RULE_QUERY, {
    variables: {
      // @ts-ignore
      rule: R.evolve(
        {
          needsReviewByUserAction: R.when(R.whereEq('anyone'), R.always(null)) ?? undefined,
        },
        R.reject(R.isNil, R.omit(['id'], debouncedRule ?? {})),
      ),
    },
    skip: R.isNil(debouncedRule) || debouncedRule === EMPTY_RULE,
    // We don't use the default cache policy of "cache-and-network", because it triggers an infinite loop
    // of "fetchMores" via a weird interaction with react-infinite-scroller.
    // network-only matches what we do in TransactionsListContainer for the same issue
    fetchPolicy: 'network-only',
  });
  const { totalCount = 0, results = [] } = data?.transactionRulePreview ?? {};

  const onRequestNextPage = useCallback(
    () =>
      fetchMore({
        variables: {
          offset: results.length,
        },
        updateQuery: (prev: PreviewTransactionRuleQuery, { fetchMoreResult }: any) =>
          R.evolve(
            {
              transactionRulePreview: {
                results: (prevResults) => [
                  ...prevResults,
                  ...(fetchMoreResult?.transactionRulePreview?.results ?? []),
                ],
              },
            },
            prev,
          ),
      }),
    [fetchMore, results],
  );

  return {
    data: data?.transactionRulePreview,
    loading: (debouncedRule !== rule || fetching) && !R.isNil(debouncedRule),
    onRequestNextPage,
    hasNextPage: results.length < totalCount,
  };
};

const PREVIEW_RULE_QUERY = gql(/* GraphQL */ `
  query PreviewTransactionRule($rule: TransactionRulePreviewInput!, $offset: Int) {
    transactionRulePreview(input: $rule) {
      totalCount
      results(offset: $offset, limit: 30) {
        newName
        newSplitTransactions
        newCategory {
          id
          icon
          name
        }
        newHideFromReports
        newTags {
          id
          name
          color
          order
        }
        newGoal {
          id
          name
          imageStorageProvider
          imageStorageProviderId
        }
        newSplitTransactions
        transaction {
          id
          date
          amount
          merchant {
            id
            name
          }
          category {
            id
            name
            icon
          }
        }
      }
    }
  }
`);

export default usePreviewRuleQuery;
