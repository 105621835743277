import React, { useEffect } from 'react';

import { loadZendesk } from 'lib/external/zendesk';

const ZendeskProvider = () => {
  useEffect(() => {
    loadZendesk();
  }, []);

  return <></>;
};

export default ZendeskProvider;
