import { DateTime } from 'luxon';
import * as RA from 'ramda-adjunct';
import React, { useMemo, useRef } from 'react';
import deepIsEqual from 'react-fast-compare';
import styled from 'styled-components';

import EventPropagationBoundary from 'components/lib/higherOrder/EventPropagationBoundary';
import AmountPill from 'components/lib/ui/AmountPill';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import { GridItem } from 'components/lib/ui/Grid';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import IconButton from 'components/lib/ui/button/IconButton';

import { getHoldingPricingInfo } from 'common/lib/investments/holdings';
import { SecurityType } from 'common/lib/investments/types';
import { formatCurrency } from 'common/utils/Currency';
import { formatThousands, formatTwelveDecimalPlaces } from 'common/utils/Number';
import type { AutoFocusOptions } from 'lib/contexts/TransactionsListContext';

import type {
  Web_GetHoldingsQueryHoldingAggregateHolding,
  Web_GetHoldingsQueryHoldingAggregate,
} from 'common/types/investments';

const Root = styled.div<{ $enablePointer: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  cursor: ${({ $enablePointer }) => ($enablePointer ? 'pointer' : 'inherit')};
  user-select: ${({ $enablePointer }) => ($enablePointer ? 'none' : 'auto')};
  transition: background-color 0.1s ease-out;
  background-color: transparent;

  :not(:last-child) {
    border-bottom-color: ${({ theme }) => theme.color.grayBackground};
  }

  border-bottom-color: ${({ theme }) => theme.color.grayBackground};
`;

const RowContainer = styled(Flex)`
  height: 76px;
  padding: 0 ${({ theme }) => theme.spacing.default} 0 ${({ theme }) => theme.spacing.large};
  padding-top: ${({ theme }) => theme.spacing.xxsmall};

  background-color: ${(props) => props.theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.base};
  transition: border-color 0.1s ease-out;

  flex-grow: 2;

  /* Negative left/right margin lets the row borders overlap the surrounding list border. */
  margin: -1px -1px 0;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledGrid = styled.div`
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 4fr) 2fr 2fr 2fr 2fr 40px 40px;
  grid-template-areas: 'info price quantity variation value icon actions';
  grid-gap: ${(props) => props.theme.spacing.xxsmall};
  align-items: center;
`;

const HoldingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  > * + * {
    margin-top: ${(props) => props.theme.spacing.xxsmall};
  }
`;

const ChevronPropagationBoundary = styled(EventPropagationBoundary).attrs({ onClick: true })`
  flex: 0;
`;

const StyledGridItem = styled(GridItem)`
  display: flex;
  > * {
    width: 100%;
  }
`;

const StyledText = styled(Text)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
`;

const StyledAmountPill = styled(AmountPill)`
  padding-left: ${(props) => props.theme.spacing.small};
  padding-right: ${(props) => props.theme.spacing.small};
`;

type Props = {
  className?: string;
  holdingsAggregate: NonNullable<Web_GetHoldingsQueryHoldingAggregate>;
  onChevronClick: (
    holding: Web_GetHoldingsQueryHoldingAggregateHolding,
    autoFocus?: Partial<AutoFocusOptions>,
  ) => void;
  onHover?: (e: React.MouseEvent) => void;
};

const HoldingsListRow = ({ holdingsAggregate, className, onHover, onChevronClick }: Props) => {
  const {
    quantity,
    totalValue,
    security,
    securityPriceChangePercent,
    holdings: holdingsInfo,
  } = holdingsAggregate;

  const [holdingInfo] = holdingsInfo;

  const ref = useRef<HTMLDivElement>(null);

  const { ticker: securityTicker, name: securityName } = security ?? {};
  const [{ ticker: holdingTicker, name: holdingName }] = holdingsInfo;

  const secondaryTitle = securityName || holdingName;
  const primaryTitle = securityTicker || holdingTicker || secondaryTitle;

  const pricingInfo = useMemo(
    () => getHoldingPricingInfo(holdingInfo, security ?? null),
    [holdingInfo, security],
  );

  return (
    <Root ref={ref} className={className} onMouseEnter={onHover} $enablePointer={false}>
      <RowContainer>
        <StyledGrid>
          <StyledGridItem area="info">
            <HoldingInfoContainer>
              <StyledText weight="medium">{primaryTitle}</StyledText>
              {primaryTitle !== secondaryTitle && (
                <StyledText size="xsmall" weight="book" color="textLight">
                  {secondaryTitle}
                </StyledText>
              )}
            </HoldingInfoContainer>
          </StyledGridItem>
          <StyledGridItem area="price">
            <Tooltip
              content={`Updated ${DateTime.fromISO(
                pricingInfo.priceUpdatedAt,
              ).toRelativeCalendar()}`}
              fitContent
            >
              <Text align="right">{formatCurrency(pricingInfo.price)}</Text>
            </Tooltip>
          </StyledGridItem>
          <StyledGridItem area="quantity">
            <Text align="right">
              {security?.type === SecurityType.CRYPTOCURRENCY
                ? formatTwelveDecimalPlaces(quantity)
                : formatThousands(quantity)}
            </Text>
          </StyledGridItem>
          <StyledGridItem area="variation">
            <Text align="center">
              {RA.isNotNil(securityPriceChangePercent) ? (
                <FlexContainer center>
                  <StyledAmountPill
                    value={securityPriceChangePercent ?? null}
                    formatter={(value) => `${value?.toFixed(2)}%`}
                  />
                </FlexContainer>
              ) : (
                'N/A'
              )}
            </Text>
          </StyledGridItem>
          <StyledGridItem area="value">
            <Text align="right">{formatCurrency(totalValue)}</Text>
          </StyledGridItem>
          <StyledGridItem area="icon">
            {holdingInfo.isManual && (
              <Tooltip content="Manual holding" fitContent>
                <FlexContainer paddingHorizontal="default">
                  <Icon name="sheet" size={14} />
                </FlexContainer>
              </Tooltip>
            )}
          </StyledGridItem>
          <StyledGridItem area="actions">
            <ChevronPropagationBoundary>
              <IconButton
                className="fs-drawer-toggle"
                icon="chevron-right"
                onClick={() => onChevronClick(holdingInfo)}
              />
            </ChevronPropagationBoundary>
          </StyledGridItem>
        </StyledGrid>
      </RowContainer>
    </Root>
  );
};

export default React.memo(HoldingsListRow, deepIsEqual);
