import { useState } from 'react';

import useEventCallback from 'common/lib/hooks/useEventCallback';

/*
  This hook takes in a function that returns a Promise and returns [isLoading, fetch].
  When you call fetch() it will execute the async function and manage the isLoading state for you.

  A good place to use this is to connect ScrollView onRefresh methods and state to a function.

  Use like this:
  const [isRefreshing, onRefresh] = useLoading(() => dispatch(getData()));
  <ScrollView onRefresh={onRefresh} refreshing={isRefreshing} />
*/
const useLoading = <T, ArgsT extends Array<any>>(
  func: (...args: ArgsT) => Promise<T>,
): [boolean, (...args: ArgsT) => Promise<T>] => {
  const [isLoading, setIsLoading] = useState(false);

  const fetch = useEventCallback(async (...args: ArgsT) => {
    setIsLoading(true);
    try {
      const result = await func(...args);
      return result;
    } finally {
      setIsLoading(false);
    }
  });

  return [isLoading, fetch];
};

export default useLoading;
