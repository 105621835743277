import React from 'react';

import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

type Props = {
  title: string;
  isLoading?: boolean;
  dismissText?: string;
  children: React.ReactNode;

  onDismiss?: () => void;
};

const ConfirmationSuccess = ({
  title,
  isLoading = false,
  dismissText = 'Okay',
  children,
  onDismiss,
}: Props) => (
  <ModalCard title={title}>
    <CardBody>{children}</CardBody>
    <CardFooter>
      <DefaultButton onClick={onDismiss} disabled={isLoading}>
        {dismissText}
      </DefaultButton>
    </CardFooter>
  </ModalCard>
);

export default ConfirmationSuccess;
