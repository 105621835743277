import React from 'react';
import styled from 'styled-components';

import GoalMessageBlock from 'components/assistant/blocks/GoalMessageBlock';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { GoalsBlock } from 'common/lib/assistant/blocks';
import { makeBlockRenderer } from 'common/lib/assistant/blocks/render';
import { parseCurrency } from 'common/utils/Currency';

import { GoalType } from 'common/constants/goals';

const Root = styled(FlexContainer)`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const DemoGoalsBlockRenderer = makeBlockRenderer(GoalsBlock)(({ data: { rows } }) => (
  <Root column>
    {rows.map(({ ID, Name, ...info }) => (
      <GoalMessageBlock
        key={ID}
        goal={{
          __typename: 'GoalV2',
          id: ID,
          name: Name,
          currentAmount: parseCurrency(info['Current Amount']),
          completionPercent:
            ((parseCurrency(info['Current Amount']) ?? 0) /
              (parseCurrency(info['Target Amount']) ?? 1)) *
            100,
          archivedAt: null,
          type: GoalType.Asset,
          completedAt: null,
          imageStorageProvider: 'cloudinary',
          imageStorageProviderId: 'production/goals/defaults/a98indaoj1b5jvfys9pi',
          accountAllocations: [],
        }}
      />
    ))}
  </Root>
));

export default DemoGoalsBlockRenderer;
