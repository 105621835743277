import type {
  QueryHookOptions,
  OperationVariables,
  QueryResult,
  TypedDocumentNode,
} from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import * as R from 'ramda';
import { useCallback, useEffect, useState } from 'react';

import useCurrentReference from 'common/lib/hooks/useCurrentReference';
import useTimeoutWhen from 'common/lib/hooks/useTimeoutWhen';

const DEFAULT_POLL_INTERVAL_MS = 500;

type Options<TData, TVariables extends OperationVariables> = Omit<
  QueryHookOptions<TData, TVariables>,
  'onCompleted' | 'pollInterval'
> & {
  /** Polling will stop when this returns true */
  pollUntil: (data?: TData) => boolean;
  /** Polling interval in milliseconds */
  pollIntervalMs?: number;
  /** Maximum amount of time to wait for condition. Will wait indefinitely if not specified */
  pollTimeoutMs?: number;
  /** Skip the query if not currently polling. Defaults to true. */
  skipWhenNotPolling?: boolean;
  /** Called once condition is met and polling has stopped */
  onComplete?: (data?: TData) => void;
  /** Called if condition is met within pollTimeoutMs */
  onTimeoutReached?: (data?: TData) => void;
};

/**
 * Hook used to poll a query until the specified condition is met.
 *
 * Polling will not begin until startPolling() is called.
 */
const usePollQueryUntil = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  {
    pollUntil,
    pollIntervalMs = DEFAULT_POLL_INTERVAL_MS,
    pollTimeoutMs,
    skipWhenNotPolling = true,
    onComplete,
    onTimeoutReached,
    ...queryOptions
  }: Options<TData, TVariables>,
): [() => void, Omit<QueryResult<TData, TVariables>, 'startPolling' | 'stopPolling'>] => {
  const [isPolling, setIsPolling] = useState(false);
  const currentIsPolling = useCurrentReference(isPolling);

  const {
    startPolling: start,
    stopPolling: stop,
    ...queryResponse
  } = useQuery<TData, TVariables>(query, {
    ...queryOptions,
    skip: skipWhenNotPolling && !isPolling,
  });
  const { data } = queryResponse;

  const startPolling = useCallback(() => {
    setIsPolling(true);
    start(pollIntervalMs);
    // Return a promise that will resolve when polling finishes, either due to success or
    // timeout.
    return new Promise<void>((resolve) => {
      const intervalId = setInterval(() => {
        if (!currentIsPolling.current) {
          resolve();
          clearInterval(intervalId);
        }
      }, pollIntervalMs);
    });
  }, [setIsPolling, start, pollIntervalMs]);

  const stopPolling = useCallback(() => {
    setIsPolling(false);
    stop();
  }, [setIsPolling, stop]);

  // Check if condition is met when data changes
  useEffect(() => {
    const conditionMet = pollUntil(data);

    if (conditionMet && isPolling) {
      stopPolling();
      onComplete?.(data);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  // Timeout if condition is never met
  useTimeoutWhen(isPolling && !R.isNil(pollTimeoutMs), pollTimeoutMs ?? 0, () => {
    stopPolling();
    onTimeoutReached?.(data);
  });

  return [startPolling, queryResponse];
};

export default usePollQueryUntil;
