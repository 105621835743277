import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import type { Column, SortByFn, TableState } from 'react-table';
import styled from 'styled-components';

import HoldingsTableLoading from 'components/investments/InvestmentsHoldingsTableLoading';
import InvestmentsIndicator from 'components/investments/InvestmentsIndicator';
import InvestmentsTableFooterCell from 'components/investments/InvestmentsTableFooterCell';
import ReturnPercentPill from 'components/investments/ReturnPercentPill';
import InvestmentsHoldingDrawer from 'components/investments/drawer/InvestmentsHoldingDrawer';
import type { HoldingGroupByValues } from 'components/investments/holdingsGroupByOptions';
import EventPropagationBoundary from 'components/lib/higherOrder/EventPropagationBoundary';
import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import AmountPill from 'components/lib/ui/AmountPill';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import IconButton from 'components/lib/ui/button/IconButton';
import Table from 'components/lib/ui/table/Table';

import useToggle from 'common/lib/hooks/useToggle';
import { getHoldingPricingInfo } from 'common/lib/investments/holdings';
import {
  SECURITY_DISPLAY_TYPE_TABLE_ORDER,
  sortByNullablePath,
} from 'common/lib/investments/table';
import { SecurityType } from 'common/lib/investments/types';
import { formatCurrency, parseCurrency } from 'common/utils/Currency';
import { formatThousands, formatTwelveDecimalPlaces } from 'common/utils/Number';
import useTheme from 'lib/hooks/useTheme';

import type {
  Web_GetPortfolio_portfolio_aggregateHoldings_edges_node,
  Web_GetPortfolio_portfolio_performance,
  Web_GetPortfolio_portfolio_aggregateHoldings_edges_node_security,
} from 'common/generated/graphQlTypes/Web_GetPortfolio';

type ColumnConfig = Column<Web_GetPortfolio_portfolio_aggregateHoldings_edges_node>[];
type SortByFnReified = SortByFn<Web_GetPortfolio_portfolio_aggregateHoldings_edges_node>;

const ChevronPropagationBoundary = styled(EventPropagationBoundary).attrs({ onClick: true })`
  flex: 0;
  width: max-content;
  margin-left: auto;
`;

const ClickableFlexContainer = styled(FlexContainer)`
  cursor: pointer;
`;

type Props = {
  holdings: Web_GetPortfolio_portfolio_aggregateHoldings_edges_node[];
  groupBy: HoldingGroupByValues;
  selectedAggregateHoldingInfos: { id: string; color: string }[];
  meta: Web_GetPortfolio_portfolio_performance | undefined;
  isLoading?: boolean;
  selectedSecurityId?: GraphQlUUID;
  onClickHoldingChevron?: (id: GraphQlUUID) => void;
  onHoldingDrawerClose: () => void;
  onClickHolding: (
    security: Web_GetPortfolio_portfolio_aggregateHoldings_edges_node_security,
  ) => void;
  onClickAddHolding: () => void;
  noHoldingsEmptyStateTitle: string;
  noHoldingsEmptyStateSubtitle: string;
  noHoldingsEmptyStateButtonText: string;
  timeFrameText: string;
  sortBy: { id: string; desc?: boolean } | null;
  setSortBy: (sortBy: { id: string; desc?: boolean }) => void;
};

const skipSortIfGroup =
  (sorterFunc: SortByFnReified): SortByFnReified =>
  (row1, row2, columnId, desc) => {
    if (row1.isGrouped) {
      const { groupByVal: firstGroup } = row1;
      const { groupByVal: secondGroup } = row2;
      const factor = desc ? -1 : 1;
      return (
        factor *
        (SECURITY_DISPLAY_TYPE_TABLE_ORDER.indexOf(firstGroup) -
          SECURITY_DISPLAY_TYPE_TABLE_ORDER.indexOf(secondGroup))
      );
    }
    return sorterFunc(row1, row2, columnId, desc);
  };

const canSelectHoldingAggregate = (
  aggregateHolding: Web_GetPortfolio_portfolio_aggregateHoldings_edges_node | null,
): boolean =>
  RA.isNotNil(aggregateHolding?.security) &&
  isHistoricalDataAvailableForHoldingAggregate(aggregateHolding) &&
  aggregateHolding?.security?.type !== 'cryptocurrency';

const isHistoricalDataAvailableForHoldingAggregate = (
  aggregateHolding: Web_GetPortfolio_portfolio_aggregateHoldings_edges_node | null,
): boolean =>
  RA.isNotNil(aggregateHolding?.security) &&
  RA.isNotNilOrEmpty(aggregateHolding?.securityPriceChangePercent);

const HoldingsTable = ({
  holdings: holdingAggregates,
  groupBy,
  selectedAggregateHoldingInfos,
  meta,
  isLoading,
  selectedSecurityId,
  onClickHolding,
  onClickAddHolding,
  onClickHoldingChevron,
  onHoldingDrawerClose,
  noHoldingsEmptyStateTitle,
  noHoldingsEmptyStateSubtitle,
  noHoldingsEmptyStateButtonText,
  timeFrameText,
  sortBy,
  setSortBy,
}: Props) => {
  const { color } = useTheme();

  const [displaySecurityPriceChangeDollars, { toggle: toggleDisplaySecurityPriceChangeDollars }] =
    useToggle(false);

  const selectedHoldingAggregate = useMemo(
    () =>
      holdingAggregates ? holdingAggregates.find((el) => el.id === selectedSecurityId) : undefined,
    [holdingAggregates, selectedSecurityId],
  );

  const columns: ColumnConfig = useMemo(
    () => [
      {
        id: 'type',
        accessor: ({ security, holdings }) => {
          const [holding] = holdings;
          return security?.typeDisplay ?? holding.typeDisplay;
        },
      },
      {
        id: 'account',
        accessor: ({ holdings }) => {
          const [holding] = holdings;
          return holding.account?.displayName;
        },
      },
      {
        id: 'institution',
        accessor: ({ holdings }) => {
          const [holding] = holdings;
          return holding.account?.institution?.name;
        },
      },
      {
        id: 'security',
        Header: 'Security',
        accessor: ({ security, holdings }) => {
          const [holding] = holdings;
          const name = security?.name ?? holding.name;
          const ticker = security?.ticker ?? holding.ticker;

          return `${name}${ticker ? ` (${ticker})` : ''}`;
        },
        sortType: skipSortIfGroup((row1, row2) => {
          const firstValue = row1.values.security;
          const secondValue = row2.values.security;

          return firstValue.localeCompare(secondValue);
        }),
        sortDescFirst: true,
        Cell: ({
          value,
          row,
        }: {
          value: string | undefined;
          row: { original: Web_GetPortfolio_portfolio_aggregateHoldings_edges_node };
        }) => {
          const isHistoricalDataAvailable = isHistoricalDataAvailableForHoldingAggregate(
            row.original,
          );
          const { ticker: securityTicker, name: securityName } = row.original.security ?? {};
          const [{ ticker: holdingTicker, name: holdingName }] = row.original.holdings;

          const secondaryTitle = securityName || holdingName;
          const primaryTitle = securityTicker || holdingTicker || secondaryTitle;

          return value ? (
            <span style={{ display: 'flex', minWidth: '400px' }}>
              <InvestmentsIndicator
                primaryText={primaryTitle!}
                secondaryText={secondaryTitle !== primaryTitle ? secondaryTitle : undefined}
                color={
                  selectedAggregateHoldingInfos.find(({ id }) => id === row.original?.id)?.color
                }
                disabled={!isHistoricalDataAvailable}
                onClick={() => {
                  if (canSelectHoldingAggregate(row.original) && row.original.security) {
                    onClickHolding(row.original.security);
                  }
                }}
                canClick={canSelectHoldingAggregate(row.original)}
              />
            </span>
          ) : null;
        },
        Footer: () => <Text weight="medium">Total</Text>,
        Aggregated: ({ row, state }) => {
          const [groupedBy] = state.groupBy;
          const aggregatedTitle = row.values[groupedBy];
          return <Text>{aggregatedTitle}</Text>;
        },
      },
      {
        Header: 'Price',
        accessor: ({ security, holdings }) => {
          const { price, priceUpdatedAt } = getHoldingPricingInfo(holdings[0], security);

          return {
            price: formatCurrency(price),
            priceAsOf: priceUpdatedAt ? DateTime.fromISO(priceUpdatedAt).toRelative() : null,
          };
        },
        sortType: skipSortIfGroup((row1, row2, columnId, desc) => {
          const firstValue = parseCurrency(row1.values.Price.price);
          const secondValue = parseCurrency(row2.values.Price.price);

          return firstValue - secondValue;
        }),
        sortDescFirst: true,
        Cell: ({ value }: { value: { price: string; priceAsOf: string | null } }) =>
          value?.priceAsOf ? (
            <Tooltip
              content={`Price last updated ${value.priceAsOf}`}
              place="top"
              effect="solid"
              hyphens={false}
            >
              <Text {...maskClassProps}>{value?.price}</Text>
            </Tooltip>
          ) : (
            <Text {...maskClassProps}>{value?.price}</Text>
          ),
      },
      {
        Header: 'Quantity',
        accessor: ({ quantity, basis, security, holdings }) => ({
          quantity:
            security?.type === SecurityType.CRYPTOCURRENCY
              ? formatTwelveDecimalPlaces(quantity)
              : formatThousands(quantity),
          basis,
          ticker: security?.ticker ?? holdings[0]?.ticker ?? null,
        }),
        sortType: skipSortIfGroup((row1, row2, columnId, desc) =>
          sortByNullablePath(['original', 'quantity'], row1, row2),
        ),
        sortDescFirst: true,
        Cell: ({
          value,
        }: {
          value: { quantity: string; basis: number | null; ticker: string | null };
        }) =>
          value?.basis && value?.ticker ? (
            <Tooltip
              content={`Your total cost for ${value.ticker} is ${formatCurrency(value.basis)}`}
              place="top"
              effect="solid"
              hyphens={false}
            >
              <Text {...maskClassProps}>{value?.quantity}</Text>
            </Tooltip>
          ) : (
            <Text {...maskClassProps}>{value?.quantity}</Text>
          ),
      },
      {
        Header: timeFrameText,
        sortType: skipSortIfGroup((row1, row2, columnId, desc) =>
          sortByNullablePath(['original', 'securityPriceChangePercent'], row1, row2),
        ),
        accessor: ({ securityPriceChangePercent, securityPriceChangeDollars }) =>
          (RA.isNotNil(securityPriceChangeDollars) && displaySecurityPriceChangeDollars) ||
          (RA.isNotNil(securityPriceChangePercent) && !displaySecurityPriceChangeDollars) ? (
            <ClickableFlexContainer justifyEnd onClick={toggleDisplaySecurityPriceChangeDollars}>
              {displaySecurityPriceChangeDollars ? (
                <AmountPill value={securityPriceChangeDollars} formatter={formatCurrency} />
              ) : (
                <ReturnPercentPill returnPercent={securityPriceChangePercent} {...maskClassProps} />
              )}
            </ClickableFlexContainer>
          ) : (
            'N/A'
          ),
        sortDescFirst: true,
      },
      {
        Header: 'Value',
        accessor: ({ totalValue }) => formatCurrency(totalValue),
        sortType: skipSortIfGroup((row1, row2, columnId, desc) =>
          sortByNullablePath(['original', 'totalValue'], row1, row2),
        ),
        sortDescFirst: true,
        Cell: ({ value }: { value: string }) => <Text {...maskClassProps}>{value}</Text>,
        Footer: () => (
          <InvestmentsTableFooterCell
            value={meta?.totalValue ?? 0}
            formatter={formatCurrency}
            color={color.text}
            label=""
          />
        ),
      },
      {
        id: 'actions',
        disableSortBy: true,
        accessor: ({ id }: { id: string }) => id,
        Cell: ({ value }: { value: string }) =>
          value ? (
            <ChevronPropagationBoundary>
              <IconButton
                className="fs-drawer-toggle"
                icon="chevron-right"
                onClick={() => {
                  onClickHoldingChevron?.(value);
                }}
              />
            </ChevronPropagationBoundary>
          ) : null,
      },
    ],
    [onClickHolding, meta, selectedAggregateHoldingInfos, color, displaySecurityPriceChangeDollars],
  );

  const columnNames: string[] = R.reject(
    R.isNil,
    R.map(({ Header }) => Header as string, columns),
  );

  const useControlledState = React.useCallback(
    (state: TableState<Web_GetPortfolio_portfolio_aggregateHoldings_edges_node>) => {
      setSortBy(state?.sortBy?.[0]);
      return {
        ...state,
        groupBy: [groupBy],
        sortBy: sortBy ? [sortBy] : [],
      };
    },
    [groupBy, sortBy],
  );

  return (
    <>
      {isLoading ? (
        <HoldingsTableLoading columnNames={columnNames} />
      ) : (
        <Table
          isLoading={isLoading}
          empty={{
            title: noHoldingsEmptyStateTitle,
            subtitle: noHoldingsEmptyStateSubtitle,
            button: {
              title: noHoldingsEmptyStateButtonText,
              onClick: onClickAddHolding,
            },
          }}
          columns={columns}
          data={holdingAggregates}
          initialState={{
            groupBy: ['type'],
            hiddenColumns: ['type', 'account', 'institution'],
            sortBy: sortBy ? [sortBy] : [],
          }}
          useControlledState={useControlledState}
        />
      )}
      {!!selectedSecurityId && (
        <InvestmentsHoldingDrawer
          holdingAggregate={selectedHoldingAggregate}
          onClose={onHoldingDrawerClose}
        />
      )}
    </>
  );
};

export default HoldingsTable;
