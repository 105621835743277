import _ from 'lodash';
import type { DOMAttributes, SyntheticEvent } from 'react';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import noop from 'common/utils/noop';

type Props = Partial<
  Record<
    Exclude<keyof DOMAttributes<HTMLDivElement>, 'children' | 'dangerouslySetInnerHTML'>,
    boolean
  >
> & {
  preventDefault?: boolean;
  children?: React.ReactNode;
  className?: string;
};

const Root = styled.div`
  flex: 1;
`;

/*
  A component used to stop the propagation of events

  // Normally clicking on Button would also trigger this top level onClick. But with the boundary it won't be called
  <div onClick={...}>
    <EventPropagationBoundary onClick>
      <Button ... />
    </EventPropagationBoundary>
  </div>
*/
const EventPropagationBoundary = ({ preventDefault, children, className, ...events }: Props) => {
  const eventHandlers = useMemo(
    () =>
      _.mapValues(events, (enabled) =>
        enabled
          ? (e: SyntheticEvent<any, Event>) => {
              e.stopPropagation();
              if (preventDefault) {
                e.preventDefault();
              }
            }
          : noop,
      ),
    [events, preventDefault],
  );

  return (
    // @ts-ignore storybook seems to be globally affecting some types
    <Root {...eventHandlers} className={className}>
      {children}
    </Root>
  );
};

export default EventPropagationBoundary;
