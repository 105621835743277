import { Info } from 'luxon';
import * as R from 'ramda';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReferenceDot, Label } from 'recharts';
import styled from 'styled-components';

import ComparePreviousPeriodLineChart from 'components/lib/charts/ComparePreviousPeriodLineChart';

import { CashFlowTimeRange } from 'common/lib/cashFlow/DashboardAdapters';
import { formatCurrency, formatCurrencyThousandsDecimal } from 'common/utils/Currency';
import useMeasure from 'lib/hooks/useMeasure';
import useTheme from 'lib/hooks/useTheme';

import routes from 'constants/routes';

const Root = styled.div``;

const GRAPH_HEIGHT_PX = 232;
const MONTH_END_LABEL_CUTOFF_DAY = 25;

const getLabelPosition = (
  data: { day: number; balance: number | undefined; previousBalance: number }[],
): 'insideBottomRight' | 'insideBottomLeft' | 'top' | 'left' | 'right' => {
  const lastData = R.findLast(({ balance }) => !!balance, data);
  if (!lastData) {
    return 'top';
  }
  const { day: lastDay, balance: lastBalance } = lastData;
  const maxBalance = data.reduce(
    (acc, { balance }) => (balance !== undefined && balance > acc ? balance : acc),
    0,
  );

  if (lastBalance === maxBalance) {
    // At the top of the graph, position label to the left or right, top gets cut off
    return lastDay > MONTH_END_LABEL_CUTOFF_DAY ? 'left' : 'right';
  }
  if (lastDay > MONTH_END_LABEL_CUTOFF_DAY) {
    // At the right end of the graph
    return 'insideBottomRight';
  }
  if (lastBalance && lastBalance < maxBalance) {
    return 'insideBottomLeft';
  }

  // Top default
  return 'top';
};

type Datum = {
  day: number;
  balance: number;
  previousBalance: number;
  date: string;
  previousDate: string;
};

type Props = {
  mergedData: Datum[];
  lastBalance: Datum | undefined;
  selectedTimeRange: CashFlowTimeRange;
};

const CashFlowDashboardWidgetGraph = ({ mergedData, lastBalance, selectedTimeRange }: Props) => {
  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();
  const theme = useTheme();
  const history = useHistory();

  const handleDotClick = (index: number, isPreviousMonth: boolean) => {
    const date = mergedData[index]?.[isPreviousMonth ? 'previousDate' : 'date'];

    history.push(routes.transactions({ queryParams: { startDate: date, endDate: date } }));
  };

  const weekdays = Info.weekdays('short');

  return (
    <Root ref={ref}>
      <ComparePreviousPeriodLineChart
        width={width - 20}
        height={GRAPH_HEIGHT_PX}
        xDataKey="day"
        currentPeriodDataKey="balance"
        previousPeriodDataKey="previousBalance"
        data={mergedData}
        formatYAxis={formatCurrencyThousandsDecimal}
        formatXAxis={(day) =>
          selectedTimeRange === CashFlowTimeRange.ThisWeekVersusLastWeek
            ? weekdays[day - 1]
            : `Day ${day}`
        }
        selectedTimeRange={selectedTimeRange}
        onClickDot={(data: any) => handleDotClick(data.index, data.dataKey === 'previousBalance')}
      >
        {/* @ts-ignore issue with children prop */}
        <ReferenceDot
          x={lastBalance?.day}
          y={lastBalance?.balance}
          r={3}
          stroke={theme.color.orange}
          cursor="pointer"
          onClick={() => handleDotClick(lastBalance ? lastBalance.day - 1 : 0, false)}
        >
          <Label
            position={getLabelPosition(mergedData)}
            content={() => formatCurrency(lastBalance?.balance || 0)}
            offset={10}
            style={{
              fontSize: theme.fontSize.xsmall,
              fontWeight: theme.fontWeight.medium,
              fill: theme.color.orange,
            }}
          />
        </ReferenceDot>
      </ComparePreviousPeriodLineChart>
    </Root>
  );
};

export default CashFlowDashboardWidgetGraph;
