import * as React from 'react';
import { useSelector } from 'react-redux';
import type { RouteProps } from 'react-router-dom';
import { Route } from 'react-router-dom';

import useRedirectSponsorUser from 'lib/hooks/sponsor/useRedirectSponsorUser';
import useRedirectToLogin from 'lib/hooks/useRedirectToLogin';
import { getUserIsLoggedIn } from 'selectors';

const LoggedInRoute = ({ children, ...props }: RouteProps) => {
  const isUserLoggedIn = useSelector(getUserIsLoggedIn);
  const redirectToLogin = useRedirectToLogin();

  useRedirectSponsorUser();

  if (!isUserLoggedIn) {
    redirectToLogin();
  }

  return <Route {...props}>{children}</Route>;
};

export default LoggedInRoute;
