import { useMutation } from '@apollo/client';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CancelSubscriptionModal from 'components/settings/billing/CancelSubscriptionModal';
import type { CancelMutationOptions } from 'components/settings/billing/CancelSubscriptionModal';

import { logout } from 'actions';
import { DELETE_HOUSEHOLD_MUTATION } from 'common/lib/graphQl/household';
import useSubscriptionDetails from 'common/lib/hooks/billing/useSubscriptionDetails';
import { getUser } from 'common/state/user/selectors';
import { useDispatch } from 'lib/hooks';
import logger from 'lib/logger';

import {
  DELETE_HOUSEHOLD_CONFIRMATION_TITLE,
  DELETE_HOUSEHOLD_CONFIRMATION_SUBTITLE,
  DELETE_HOUSEHOLD_CONFIRMATION_SUBTITLE_REASON,
} from 'common/constants/copy';
import routes from 'constants/routes';

import type {
  DeleteHousehold,
  DeleteHouseholdVariables,
} from 'common/generated/graphQlTypes/DeleteHousehold';

type Props = {
  onCancel: () => void;
};

const DeleteHouseholdFinalConfirmation = ({ onCancel }: Props) => {
  const [deleteHouseholdMutation] = useMutation<DeleteHousehold, DeleteHouseholdVariables>(
    DELETE_HOUSEHOLD_MUTATION,
  );

  const user = useSelector(getUser);
  const history = useHistory();
  const { isTrialing } = useSubscriptionDetails();

  const dispatch = useDispatch();

  const performDelete = async ({ variables }: CancelMutationOptions) => {
    const { input } = variables ?? {};
    const householdId = user?.household?.id;
    if (!householdId) {
      logger.error('Household was blank.');
      onCancel();
      return {};
    }
    const { data, errors } = await deleteHouseholdMutation({
      variables: { input: { householdId, reason: input?.reason, feedback: input?.feedback } },
    });

    if (data?.deleteHousehold?.deleted) {
      dispatch(logout());
      history.push(`${routes.login()}?message=householdScheduledForDeletion`);
    } else {
      logger.error('Household did not delete.', errors, data?.deleteHousehold?.errors);
      onCancel();
    }

    return { data };
  };

  let subtitle = DELETE_HOUSEHOLD_CONFIRMATION_SUBTITLE;
  if (isTrialing) {
    subtitle = `${subtitle} ${DELETE_HOUSEHOLD_CONFIRMATION_SUBTITLE_REASON}`;
  }

  return (
    <CancelSubscriptionModal
      title={DELETE_HOUSEHOLD_CONFIRMATION_TITLE}
      subtitle={subtitle}
      confirm="Delete household"
      onCancelClick={onCancel}
      onCancelSuccess={onCancel}
      cancelMutation={performDelete}
      displayFormFields={isTrialing}
    />
  );
};

export default DeleteHouseholdFinalConfirmation;
