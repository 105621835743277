import clsx from 'clsx';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { formatCurrency } from 'common/utils/Currency';
import useTheme from 'lib/hooks/useTheme';

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })<{
  $inverted: boolean;
  $color?: string;
}>`
  color: ${({ $inverted, $color, theme }) =>
    $color ?? ($inverted ? theme.color.textWhite : theme.color.text)};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

type Props = {
  balance: number | string | undefined;
  previousBalance: number | string | undefined;
  inverted?: boolean;
  className?: string;
  isLiability?: boolean;
  valueFormatter?: (value: number) => string;
  children?: React.ReactNode | React.ReactNode[];
};

const BalanceDiffIndicator = ({
  balance,
  previousBalance,
  className,
  isLiability = false,
  inverted = false,
  valueFormatter = formatCurrency,
  children,
}: Props) => {
  const theme = useTheme();
  const diff = (Number(balance) - Number(previousBalance)) * (isLiability ? -1 : 1);

  const { color, sign } = getColorAndSign(diff, isLiability, {
    green: theme.color.green,
    red: theme.color.red,
  });

  const diffString = valueFormatter(diff).replace('-', '');

  return (
    <Root $color={color} $inverted={inverted} className={clsx(className, diff === 0 && 'zero')}>
      <span {...sensitiveClassProps}>
        {sign}
        {RA.isNotNil(balance) && RA.isNotNil(previousBalance) ? diffString : '--'}
        {children}
      </span>
    </Root>
  );
};

const getColorAndSign = (
  diff: number,
  isLiability = false,
  colors: {
    green: string;
    red: string;
  },
) => {
  if (diff > 0) {
    return isLiability ? { color: colors.green, sign: '-' } : { color: colors.green, sign: '+' };
  } else if (diff < 0) {
    return isLiability ? { color: colors.red, sign: '+' } : { color: colors.red, sign: '-' };
  }
  return {};
};

export default BalanceDiffIndicator;
