import type { QueryHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import useQuery from 'common/lib/hooks/useQuery';

import type { GetGoalsV2 } from 'common/generated/graphQlTypes/GetGoalsV2';

/**
 * Hook used to get a goal on demand.
 */
const useGetGoal = (goalId?: string, options?: QueryHookOptions<GetGoalsV2>) => {
  const { data: goalsData, isLoadingInitialData } = useQuery<GetGoalsV2>(GET_GOALS, options);

  const getGoal = useCallback(
    (goalId: string) => goalsData?.goalsV2.find(({ id }) => id === goalId),
    [goalsData],
  );

  const goal = useMemo(() => (goalId ? getGoal(goalId) : undefined), [goalId, getGoal]);

  return {
    getGoal,
    isLoadingInitialData,
    goal,
  };
};

const GET_GOALS = gql`
  query GetGoalsV2 {
    goalsV2 {
      id
      name
      imageStorageProvider
      imageStorageProviderId
    }
  }
`;

export default useGetGoal;
