import { gql } from 'common/generated/gql';

import useQuery from '../useQuery';

type Options = {
  creditReportLiabilityAccountId?: string;
  limit?: number;
};

export const useLastStatements = ({ creditReportLiabilityAccountId, limit = 1 }: Options) => {
  const { data: statementData, isLoadingInitialData: isLoadingStatements } = useQuery(
    LAST_STATEMENTS_QUERY,
    {
      variables: {
        filters: {
          creditReportLiabilityAccountId,
        },
        limit,
      },
      skip: !creditReportLiabilityAccountId,
    },
  );

  const statements = statementData?.creditReportLiabilityStatements ?? [];

  return { statements, isLoadingStatements };
};

const LAST_STATEMENTS_QUERY = gql(/* GraphQL */ `
  query Common_GetReviewedAccountLastStatements(
    $filters: LiabilityStatementFilterInput!
    $limit: Int!
  ) {
    creditReportLiabilityStatements(filters: $filters, limit: $limit) {
      id
      dueDate
      billAmount
    }
  }
`);
