import { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';

import { setPlaidLinkInfo } from 'actions';
import useStaticCallback from 'common/lib/hooks/useStaticCallback';
import useToggle from 'common/lib/hooks/useToggle';
import { isConnectivityIssue } from 'common/lib/plaid';
import { track } from 'lib/analytics/segment';
import useDispatch from 'lib/hooks/useDispatch';

import type { PlaidEventName } from 'common/constants/analytics';
import { PLAID_EVENT_NAMES } from 'common/constants/analytics';

import type {
  PlaidEventWithMetadata,
  PlaidLinkError,
  PlaidLinkEventMetadata,
  PlaidLinkMetadata,
  PlaidLinkOnExitInput,
  PlaidLinkSuccessMetadata,
} from 'common/types/Plaid';

type PlaidLinkSuccessInfo = { token: string; metadata: PlaidLinkSuccessMetadata };

type Props = {
  token: string;
  receivedRedirectUri?: string;
  reconnectCredentialId?: string;
  onSuccess: (info: PlaidLinkSuccessInfo) => void;
  onClose?: (hasConnectivityIssue?: boolean) => void;
  onError: (info: PlaidLinkOnExitInput) => void;
  laxConnectionIssueDetection?: boolean;
};

const PlaidLink = ({
  token,
  receivedRedirectUri,
  reconnectCredentialId,
  onSuccess,
  onClose,
  onError,
  laxConnectionIssueDetection = false,
}: Props) => {
  const dispatch = useDispatch();
  const [successInfo, setSuccessInfo] = useState<PlaidLinkSuccessInfo | undefined>(undefined);
  const [hasHandedOff, { setOn: setHasHandedOff }] = useToggle(false);
  const [hasExited, { setOn: setHasExited }] = useToggle(false);
  const [plaidEvents, setPlaidEvents] = useState<PlaidEventWithMetadata[]>([]);

  // these are necessary because usePlaidLink does not update them upon rerender.
  // It "freezes" its initial state when the iframe launches.
  const onErrorStatic = useStaticCallback(onError);

  const { open, ready } = usePlaidLink({
    token,
    receivedRedirectUri,
    onExit: (error: PlaidLinkError | null, metadata: PlaidLinkMetadata) => {
      if (error) {
        onErrorStatic({ error, metadata });
      } else {
        setHasExited();
      }
    },
    onSuccess: (token: string, metadata: PlaidLinkSuccessMetadata) =>
      setSuccessInfo({ token, metadata }),
    onEvent: (event: PlaidEventName, metadata: PlaidLinkEventMetadata) => {
      track(PLAID_EVENT_NAMES[event], metadata);
      const plaidEventWithMetadata = { event, metadata };
      setPlaidEvents((plaidEvents) => [...plaidEvents, plaidEventWithMetadata]);

      if (event === 'HANDOFF') {
        setHasHandedOff();
      }
    },
  });

  useEffect(() => {
    // Cache the token so we can retrieve it after an oauth redirect
    dispatch(
      setPlaidLinkInfo({
        token,
        reconnectCredentialId,
      }),
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => ready && open(), [ready, open]);

  useEffect(() => {
    if (successInfo && hasHandedOff) {
      onSuccess(successInfo);
    }
  }, [successInfo, hasHandedOff]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasExited) {
      // hasConnectivityIssue should be true if any error was encountered
      const hasConnectivityIssue: boolean = isConnectivityIssue(
        plaidEvents,
        laxConnectionIssueDetection,
      );
      onClose?.(hasConnectivityIssue);
    }
  }, [hasExited]);

  return null; // We don't actually want to render anything, it's handled by usePlaidLink
};

export default PlaidLink;
