import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import { useCallback, useEffect, useState } from 'react';

import { FORCE_REFRESH_ACCOUNT_MUTATION } from 'common/lib/graphQl/accounts';
import usePollQueryUntil from 'common/lib/hooks/usePollQueryUntil';

import { MS_PER_SECOND, MS_PER_MINUTE } from 'common/constants/time';

import { gql } from 'common/generated/gql';
import type { ErrorCode } from 'common/generated/graphql';
import type { Id } from 'common/types';

const POLL_INTERVAL_MS = MS_PER_SECOND * 20;
const POLL_INTERVAL_TIMEOUT_MS = MS_PER_MINUTE * 5;

export type Options = {
  /** Called before the mutation is executed. */
  beforeStartRefresh?: () => void | Promise<void>;
  /** Called when the refresh begins. */
  onDidStartRefresh?: () => void;
  /** Called when hasSyncOrRecentRefreshRequest goes from true -> false and polling ends. */
  onDidFinishRefresh?: () => void;
};

/**
 * Hook used to call the force refresh mutation and poll the query until the refresh is done.
 */
const useForceRefreshAccount = (accountId: Id, options?: Options) => {
  const { onDidStartRefresh, onDidFinishRefresh, beforeStartRefresh } = options ?? {};

  const [startPolling, { data, refetch }] = usePollQueryUntil(QUERY, {
    variables: { accountId },
    pollUntil: (data) => data?.account?.hasSyncOrRecentRefreshRequest === false,
    onComplete: onDidFinishRefresh,
    skipWhenNotPolling: false,
    pollIntervalMs: POLL_INTERVAL_MS,
    pollTimeoutMs: POLL_INTERVAL_TIMEOUT_MS,
  });
  const { account } = data ?? {};
  const { canBeForceRefreshed = false, hasSyncOrRecentRefreshRequest = false } = account ?? {};
  const [error, setError] = useState<ErrorCode | null | undefined>();

  useEffect(() => {
    if (hasSyncOrRecentRefreshRequest) {
      startPolling();
    }
  }, [hasSyncOrRecentRefreshRequest, startPolling]);

  const [performMutation] = useMutation(FORCE_REFRESH_ACCOUNT_MUTATION, {
    variables: { input: { accountId } },
    // @ts-ignore complaining about proxy having any type
    update: (proxy) => {
      const data = proxy.readQuery({ query: QUERY, variables: { accountId } });
      proxy.writeQuery({
        query: QUERY,
        variables: { accountId },
        data: R.assocPath(['account', 'hasSyncOrRecentRefreshRequest'], true, data),
      });
    },
  });

  const forceRefreshAccount = useCallback(async () => {
    await beforeStartRefresh?.();
    const { data } = await performMutation();
    if (data?.forceRefreshAccount?.errors) {
      setError(data.forceRefreshAccount.errors.code);
    } else {
      await refetch();
      onDidStartRefresh?.();
    }
  }, [performMutation, refetch, beforeStartRefresh, onDidStartRefresh]);

  return [
    forceRefreshAccount,
    { canBeForceRefreshed, hasSyncOrRecentRefreshRequest, error },
  ] as const;
};

const QUERY = gql(/* GraphQL */ `
  query Common_ForceRefreshAccountQuery($accountId: UUID!) {
    account(id: $accountId) {
      id
      canBeForceRefreshed
      hasSyncOrRecentRefreshRequest
    }
  }
`);

export default useForceRefreshAccount;
