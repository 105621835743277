import styled from 'styled-components';

import ToggleCard from 'components/lib/ui/ToggleCard';

import { color, spacing, breakPoints, fontSize, fontWeight } from 'common/lib/theme/dynamic';

export const TransactionRuleFormSide = styled.div<{ wide?: boolean }>`
  flex: ${({ wide }) => (wide ? 1.5 : 1)};
  padding: ${spacing.xlarge};

  :first-child {
    border-bottom: 1px solid ${color.grayBackground};
  }

  @media (min-width: ${breakPoints.sm}px) {
    :first-child {
      border-bottom: none;
      border-right: 1px solid ${color.grayBackground};
    }
  }

  transition: flex 1s;
`;

export const TransactionRuleFormTitle = styled.span`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.large};
`;

export const TransactionRuleFormToggleCard = styled(ToggleCard)`
  margin-top: ${spacing.default};
`;
