import type { Color } from 'common/types/Styles';

export const CHART_HEIGHT_PX = 250;

export const CHART_COLORS: Color[] = [
  'blue',
  'green',
  'yellow',
  'orange',
  'navy',
  'purple',
  'pink',
  'indigo',
  'teal',
  'red',
];

export enum FeedbackType {
  Positive = 'positive',
  Negative = 'negative',
}

export const FEEDBACK_OPTIONS: { [key in FeedbackType]: string[] } = {
  [FeedbackType.Positive]: ['Helpful', 'Accurate', 'Factually correct'],
  [FeedbackType.Negative]: ['Unhelpful', 'Inaccurate', 'Not factually correct'],
};

export const FEEDBACK_COPY: { [key in FeedbackType]: { placeholder: string; title: string } } = {
  [FeedbackType.Positive]: {
    placeholder: 'Tell us more about what you liked...',
    title: 'What did you like?',
  },
  [FeedbackType.Negative]: {
    placeholder: `Tell us more about what you didn't like...`,
    title: 'What was wrong with this response?',
  },
};
