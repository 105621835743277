import React, { useState } from 'react';
import styled from 'styled-components';

import TextInput from 'components/lib/form/TextInput';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { defaultButtonMixin } from 'components/lib/ui/button/DefaultButton';

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

const DefaultAsyncButton = styled(AsyncButton)`
  ${defaultButtonMixin};
`;

const SubtitleText = styled(Text).attrs({ size: 'medium' })`
  display: block;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  value: string | null;
  onChange: (value: string | null) => void;
  isLoading?: boolean;
  description?: string | null;
  error?: string | null;
};

const PromoCodeInput = ({ value, onChange, isLoading, description, error }: Props) => {
  const [localValue, setLocalValue] = useState(value);

  return (
    <>
      <FlexContainer gap="small">
        <StyledTextInput
          name="promoCode"
          id="promoCode"
          placeholder="Enter a promo code..."
          value={localValue ?? undefined}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setLocalValue(value.trim());
            onChange(null);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onChange(localValue);
              event.preventDefault();
            }
          }}
        />
        <DefaultAsyncButton
          onClick={() => {
            onChange(localValue);
          }}
          disabled={!localValue || localValue === value}
          pending={isLoading}
        >
          Apply
        </DefaultAsyncButton>
      </FlexContainer>
      {!!description && (
        <SubtitleText color="greenText">{`Promo code applied: ${description}`}</SubtitleText>
      )}
      {!!error && <SubtitleText color="red">{error}</SubtitleText>}
    </>
  );
};

export default PromoCodeInput;
