import React from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import Form from 'components/lib/form/Form';
import { Label } from 'components/lib/form/FormItemContainer';
import FormSection from 'components/lib/form/FormSection';
import SelectField from 'components/lib/form/SelectField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';
import useRouteMatch from 'lib/hooks/useRouteMatch';
import { groupByOptions } from 'state/recurring/reducer';

import routes from 'constants/routes';

import type { RecurringTransactionFilter } from 'common/generated/graphQlTypes/globalTypes';

type Props = {
  filters?: RecurringTransactionFilter;
  onChangeFilters: (filters: RecurringTransactionFilter) => void;
  afterSubmit: () => void;
};

const FormRoot = styled.div`
  width: 328px;
`;

const StyledFormSection = styled(FormSection)`
  padding-top: ${({ theme }) => theme.spacing.default};
`;

const Header = styled(FlexContainer).attrs({ justifyBetween: true })`
  border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
`;

const StyledSelectField = styled(SelectField)`
  ${Label} {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const RecurringFilters = ({ filters, onChangeFilters, afterSubmit }: Props) => {
  const [isLoadingAccounts, accountOptions] = useAccountSelectOptions();
  const allRouteMatch = useRouteMatch(routes.recurring.all);

  const resetFilters = () => {
    onChangeFilters({});
    afterSubmit();
  };

  return (
    <Form
      initialValues={filters}
      onSubmit={(values) => {
        onChangeFilters(values);
        afterSubmit();
      }}
    >
      <FormRoot>
        <FormContext.Consumer>
          {({ handleSubmit }) => (
            <>
              <Header>
                <DefaultButton size="small" onClick={() => resetFilters()}>
                  Reset
                </DefaultButton>
                <PrimaryButton size="small" onClick={() => handleSubmit()}>
                  Apply
                </PrimaryButton>
              </Header>
              <StyledFormSection>
                {allRouteMatch?.isExact && (
                  <StyledSelectField
                    name="groupBy"
                    label="Group by"
                    placeholder="None"
                    options={groupByOptions}
                  />
                )}
                <StyledSelectField
                  name="accounts"
                  label="Accounts"
                  placeholder="All accounts"
                  isMulti
                  isLoading={isLoadingAccounts}
                  options={accountOptions}
                />
              </StyledFormSection>
            </>
          )}
        </FormContext.Consumer>
      </FormRoot>
    </Form>
  );
};

export default RecurringFilters;
