import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { UPDATE_CATEGORY_EXCLUDE_FROM_BUDGET } from 'common/lib/graphQl/budgets';
import { PLAN_DATA_QUERY_NAME } from 'common/lib/graphQl/planning';

import type { ExtractResponseFromDocument } from 'common/types/graphql';

type ExcludeFromBudgetParams = {
  id: string;
  excludeFromBudget: boolean;
  onCompleted: () => void;
};

type Options = {
  refetch?: boolean;
};

const useExcludeFromBudget = (options?: Options) => {
  const { refetch } = options ?? {};
  const [updateCategoryExcludeFromBudget] = useMutation(UPDATE_CATEGORY_EXCLUDE_FROM_BUDGET);

  const excludeCategoryFromBudget = useCallback(
    ({ id, excludeFromBudget, onCompleted }: ExcludeFromBudgetParams) =>
      updateCategoryExcludeFromBudget({
        // @ts-expect-error: TODO: change graphql codegen to use optionals for nullable fields
        variables: { input: { id, excludeFromBudget } },
        refetchQueries: refetch ? [PLAN_DATA_QUERY_NAME] : undefined,
        optimisticResponse: getOptimisticResponse(id, excludeFromBudget),
        onCompleted,
      }),
    [updateCategoryExcludeFromBudget, refetch],
  );

  return excludeCategoryFromBudget;
};

const getOptimisticResponse = (
  id: string,
  excludeFromBudget: boolean,
): ExtractResponseFromDocument<typeof UPDATE_CATEGORY_EXCLUDE_FROM_BUDGET> => ({
  __typename: 'Mutation',
  updateCategory: {
    __typename: 'UpdateCategoryPayload',
    category: {
      __typename: 'Category',
      id,
      excludeFromBudget,
    },
  },
});

export default useExcludeFromBudget;
