import clsx from 'clsx';
import { DateTime } from 'luxon';
import moment from 'moment';
import * as R from 'ramda';
import React, { useState } from 'react';
import type { DateRangePickerShape } from 'react-dates';
import { DateRangePicker as DateRangePickerLib } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FiArrowRight } from 'react-icons/fi';
import styled from 'styled-components';

import { dateRangeStyleMixin } from 'components/lib/dates/mixins';
import NativeSelect from 'components/lib/form/NativeSelect';
import DateRangePickerShortcuts from 'components/lib/ui/DateRangePickerShortcuts';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Tooltip from 'components/lib/ui/Tooltip';
import IconButton from 'components/lib/ui/button/IconButton';

import { formatISODate } from 'common/utils/date';

import type DateRange from 'common/types/DateRange';

const CLEAR_DATES_BUTTON_SIZE_PX = 28;
const CLEAR_DATES_BUTTON_TOOLTIP_DELAY_MS = 600;

const DEFAULT_YEAR_DROPDOWN_RANGE = {
  start: 2000,
  end: DateTime.local().plus({ years: 3 }).year,
};

export type DateRangePickerProps = Partial<Omit<DateRangePickerShape, 'minDate' | 'maxDate'>> & {
  value: DateRange;
  onChange: (value: DateRange) => void;
  id?: string;
  showShortcuts?: boolean;

  /* Override these props from DateRangePickerShape so consumer doesn't have to use moment */
  minDate?: Date | string;
  maxDate?: Date | string;

  small?: boolean;

  className?: string;
};

const Arrow = styled(FiArrowRight)`
  color: ${({ theme }) => theme.color.textLight};
  vertical-align: middle;
  margin: 0 ${({ theme }) => theme.spacing.default};
`;

const Root = styled.div<{ $small: boolean; $focused: boolean }>`
  ${dateRangeStyleMixin}
`;

const SelectContainer = styled(FlexContainer).attrs({ justifyCenter: true })`
  margin-top: -7px;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const SmallNativeSelect = styled(NativeSelect).attrs({ small: true })`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const LeftArrowButton = styled(IconButton).attrs({ icon: 'arrow-left' })`
  position: absolute;
  left: ${({ theme }) => theme.spacing.small};
  top: ${({ theme }) => theme.spacing.small};
`;

const RightArrowButton = styled(IconButton).attrs({ icon: 'arrow-right' })`
  position: absolute;
  right: ${({ theme }) => theme.spacing.small};
  top: ${({ theme }) => theme.spacing.small};
`;

const ClearDatesIcon = styled(IconButton).attrs({ icon: 'x', iconSize: 14 })`
  width: ${CLEAR_DATES_BUTTON_SIZE_PX}px;
  height: ${CLEAR_DATES_BUTTON_SIZE_PX}px;
`;

const DateRangePicker = ({
  value: { startDate, endDate },
  onChange,
  id,
  showShortcuts = true,
  minDate: minDateProp,
  maxDate: maxDateProp,
  small = false,
  className = undefined,
  customArrowIcon,
  displayFormat,
  ...props
}: DateRangePickerProps) => {
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);

  const minDate = minDateProp ? moment(minDateProp) : undefined;
  const maxDate = maxDateProp ? moment(maxDateProp) : undefined;

  return (
    <Root
      $small={small}
      $focused={!!focusedInput}
      className={clsx(className, !!focusedInput && 'focused')}
    >
      <DateRangePickerLib
        {...props}
        startDate={startDate ? moment(startDate) : null}
        startDateId={id ?? 'date-picker-input--start'}
        endDate={endDate ? moment(endDate) : null}
        endDateId={`${id ?? 'date-picker-input'}--end`}
        onDatesChange={(value) => {
          const mappedValue: DateRange = R.mapObjIndexed(
            (maybeDate) => (maybeDate ? formatISODate(maybeDate.toDate()) : null),
            value,
          );
          onChange(mappedValue);
        }}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        inputIconPosition="after"
        customArrowIcon={customArrowIcon ?? <Arrow size={16} />}
        hideKeyboardShortcutsPanel
        noBorder
        minDate={minDate}
        maxDate={maxDate}
        navPrev={<LeftArrowButton />}
        navNext={<RightArrowButton />}
        customCloseIcon={
          <Tooltip
            content="Clear dates"
            place="top"
            delayShow={CLEAR_DATES_BUTTON_TOOLTIP_DELAY_MS}
            portal
          >
            <ClearDatesIcon />
          </Tooltip>
        }
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
          <SelectContainer>
            <SmallNativeSelect
              name="month"
              value={month.month()}
              onChange={(e) => onMonthSelect(month, e.currentTarget.value)}
            >
              {moment.months().map((label, value) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </SmallNativeSelect>
            <SmallNativeSelect
              name="year"
              value={month.year()}
              onChange={(e) => onYearSelect(month, e.currentTarget.value)}
            >
              {R.range(
                minDate?.year() ?? DEFAULT_YEAR_DROPDOWN_RANGE.start,
                (maxDate?.year() ?? DEFAULT_YEAR_DROPDOWN_RANGE.end) + 1, // add 1 because range is exclusive
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </SmallNativeSelect>
          </SelectContainer>
        )}
        isOutsideRange={
          minDate && maxDate
            ? (day) => !day.isBetween(moment(minDate), moment(maxDate).add(1, 'days'))
            : undefined
        }
        renderCalendarInfo={
          showShortcuts
            ? () => (
                <DateRangePickerShortcuts
                  onSelectDateRange={(value) => {
                    onChange(value);
                    setFocusedInput(null); // dismiss picker
                  }}
                />
              )
            : undefined
        }
        calendarInfoPosition="before"
        displayFormat={displayFormat ?? 'MM/DD/YYYY'}
        startDatePlaceholderText="Start date"
        endDatePlaceholderText="End date"
      />
    </Root>
  );
};

export default DateRangePicker;
