import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import React, { useState } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import CardFooter from 'components/lib/ui/CardFooter';
import Flex from 'components/lib/ui/Flex';
import ModalCancelButton from 'components/lib/ui/ModalCancelButton';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import IconButton from 'components/lib/ui/button/IconButton';

import { errorToast } from 'lib/ui/toast';

import { gql } from 'common/generated/gql';
import type { PayloadErrorFields } from 'common/generated/graphQlTypes/PayloadErrorFields';

const FormContent = styled.div`
  min-height: 280px;
`;

const FormSection = styled.div`
  padding: ${({ theme }) => `${theme.spacing.default} ${theme.spacing.xlarge}`};

  :not(:last-child) {
    padding-bottom: 0;
  }
`;

const StepsContainer = styled(Flex)`
  flex-direction: column;
  padding: ${(props) => props.theme.spacing.xsmall};
  padding-top: ${(props) => props.theme.spacing.small};
`;

const HighInviteInfo = styled.div<{ emphasis: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme, emphasis }) => (emphasis ? theme.color.redDark : theme.color.text)};
`;

const FamilyText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.text};
`;

const StyledTextInput = styled(TextField)`
  margin-top: 0;
  width: 200px;
  padding-right: ${({ theme }) => theme.spacing.small};

  :not(:last-child) {
    margin-bottom: 0;
  }
`;

const Row = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

export const Label = styled.label`
  width: 200px;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const STEPS = [
  'We’ll generate a unique code for each client',
  'We’ll email the codes to your clients (we also recommend you send them directly)',
  'You’ll only be charged when a code is redeemed',
  'You can revoke codes at any time',
];

const HIGH_INVITE_THRESHOLD = 15;

type Props = {
  onDone?: () => void;
};

const AddClientsModal = ({ onDone }: Props) => {
  const [quantity, setQuantity] = useState(1);
  const [highInvite, setHighInvite] = useState(false);
  const [createSubscriptionSponsorshipInvites] = useMutation(
    CREATE_SUBSCRIPTION_SPONSORSHIP_INVITES_MUTATION,
    {
      refetchQueries: ['Web_GetAdvisorClientsPage'],
    },
  );

  const onAddMoreRows = () => {
    if (quantity >= HIGH_INVITE_THRESHOLD) {
      setHighInvite(true);
      return;
    }
    setQuantity(quantity + 1);
  };

  const handleSubmit = async (values: Record<string, string>) => {
    setHighInvite(false);

    const invites = R.reduce(
      (acc, idx) => {
        const email = values[`client-email-${idx}`];
        const nickname = values[`client-nickname-${idx}`];

        if (email) {
          return [
            ...acc,
            {
              email,
              name: nickname?.length > 0 ? nickname : undefined,
            },
          ];
        }

        return acc;
      },
      [] as { email: string; name: string | undefined }[],
      R.range(0, quantity),
    );

    const { data } = await createSubscriptionSponsorshipInvites({
      variables: {
        input: {
          quantity: undefined,
          invites,
        },
      },
    });
    const errors: PayloadErrorFields | undefined = R.path(
      ['createSubscriptionSponsorshipInvites', 'errors'],
      data,
    );
    if (errors) {
      errorToast(errors.message);
    } else {
      onDone?.();
    }
  };

  return (
    <ModalCard title="Add clients">
      <Form onSubmit={handleSubmit}>
        <FormContext.Consumer>
          {({ isValid }) => (
            <>
              <FormContent>
                <FormSection>
                  <Text weight="medium">How it works</Text>
                  <StepsContainer>
                    {STEPS.map((el, idx) => (
                      <Text key={idx}>&bull; {el}</Text>
                    ))}
                  </StepsContainer>

                  <FamilyText>
                    If you work with families, enter only one email address for that family. Once
                    they sign up, they will be able to add their partner to their household at no
                    additional cost.
                  </FamilyText>
                </FormSection>

                <FormSection>
                  <Flex column>
                    <Flex row full alignCenter style={{ paddingRight: 50 }}>
                      <Label>Email</Label>

                      <Label>Name (optional)</Label>
                    </Flex>

                    {R.range(0, quantity).map((idx) => (
                      <Row key={idx} row full alignStart>
                        <Flex row alignStart>
                          <StyledTextInput
                            name={`client-email-${idx}`}
                            placeholder="Enter an email"
                            hideLabel
                            label="Email"
                            email
                          />

                          <StyledTextInput
                            name={`client-nickname-${idx}`}
                            placeholder="Enter an nickname"
                            hideLabel
                          />
                        </Flex>

                        {idx === quantity - 1 && (
                          <IconButton icon="plus-circle" onClick={onAddMoreRows} />
                        )}
                      </Row>
                    ))}
                  </Flex>

                  <HighInviteInfo emphasis={highInvite}>
                    Note that if you are inviting more than {HIGH_INVITE_THRESHOLD} clients, you can
                    just email us a list of names and email addresses at
                    advisorsupport@monarchmoney.com. We can add them for you.
                  </HighInviteInfo>
                </FormSection>
              </FormContent>

              <CardFooter>
                <ModalCancelButton />
                <FormSubmitButton size="small" disabled={quantity <= 0 || !isValid}>
                  Generate codes
                </FormSubmitButton>
              </CardFooter>
            </>
          )}
        </FormContext.Consumer>
      </Form>
    </ModalCard>
  );
};

const CREATE_SUBSCRIPTION_SPONSORSHIP_INVITES_MUTATION = gql(`
  mutation Web_CreateSubscriptionSponsorshipInvites($input: CreateSponsorshipInvitesInput!) {
    createSubscriptionSponsorshipInvites(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      invites {
        id
        code
      }
    }
  }
`);

export default AddClientsModal;
