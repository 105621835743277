import React, { useEffect } from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import styled from 'styled-components';

import { downloadIOSApp, downloadAndroidApp } from 'components/lib/external/downloadApp';
import BlockingPage from 'components/lib/layouts/BlockingPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { setDismissedMobileBrowserWarning, viewedMobileBrowserWarning } from 'actions';
import noop from 'common/utils/noop';
import { useDispatch } from 'lib/hooks';

const COPY_BY_PLATFORM = {
  ios: {
    title: `Looks like you're on iOS`,
    subtitle: 'Download Monarch from the App Store to access it on your iPhone.',
    image: 'https://monarch-static-assets.s3.amazonaws.com/onboarding/download-iphone.png',
    downloadImage: 'https://monarch-static-assets.s3.amazonaws.com/onboarding/app-store.svg',
    download: downloadIOSApp,
  },
  android: {
    title: `Looks like you're on Android`,
    subtitle: 'Download Monarch from the App Store to access it on your Android device.',
    image: 'https://monarch-static-assets.s3.amazonaws.com/onboarding/download-android.png',
    downloadImage: 'https://monarch-static-assets.s3.amazonaws.com/onboarding/play-store.svg',
    download: downloadAndroidApp,
  },
  other: {
    title: `Looks like you're on mobile`,
    subtitle: `Parts of this experience may not be ideal, but we're working on improving this.`,
    image: '',
    downloadImage: '',
    download: noop,
  },
};

const Wrapper = styled(FlexContainer)``;

const StyledPrimaryText = styled(Text)`
  padding-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const Image = styled.img`
  width: 251px;
  height: 257px;
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
`;

const DownloadImage = styled.img`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
  height: 48px;
`;

const MobileBrowserWarning = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewedMobileBrowserWarning());
  }, []);

  const { title, subtitle, image, downloadImage, download } = (() => {
    if (isIOS) {
      return COPY_BY_PLATFORM.ios;
    } else if (isAndroid) {
      return COPY_BY_PLATFORM.android;
    } else {
      return COPY_BY_PLATFORM.other;
    }
  })();

  return (
    <BlockingPage
      controls={
        <>
          {!!downloadImage && <DownloadImage src={downloadImage} onClick={download} />}
          <DefaultButton
            size="medium"
            onClick={() => dispatch(setDismissedMobileBrowserWarning(true))}
          >
            Open web version instead
          </DefaultButton>
        </>
      }
    >
      <Wrapper alignCenter column>
        <StyledPrimaryText weight="medium" size="large" align="center">
          {title}
        </StyledPrimaryText>
        <Text align="center">{subtitle}</Text>
        {!!image && <Image src={image} />}
      </Wrapper>
    </BlockingPage>
  );
};

export default MobileBrowserWarning;
