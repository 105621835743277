import * as RA from 'ramda-adjunct';
import React, { useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AccountEditModal from 'components/accounts/AccountEditModal';
import LinkAccountFlow from 'components/accounts/LinkAccountFlow';
import UndeleteAccountConfirmation from 'components/accounts/UndeleteAccountConfirmation';
import CredentialSettingsCard from 'components/institutions/CredentialSettingsCard';
import SettingsCard from 'components/lib/layouts/SettingsCard';
import RouteModal from 'components/lib/routing/RouteModal';
import Column from 'components/lib/ui/Column';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import RefreshAllButton from 'components/routes/RefreshAllButton';

import { sortCredentials } from 'common/lib/credentials/adapters';
import useQuery from 'common/lib/hooks/useQuery';
import useModal from 'lib/hooks/useModal';
import toast from 'lib/ui/toast';

import { HELP_CENTER_CONNECTION_ISSUES_URL } from 'common/constants/externalUrls';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type { Web_GetInstitutionSettingsQuery } from 'common/generated/graphql';

const SpinnerContainer = styled(FlexContainer).attrs({ center: true })`
  margin: ${({ theme }) => theme.spacing.xlarge} 0;
`;

const HeaderDescription = styled.p`
  padding: 0 ${({ theme }) => theme.spacing.large};
`;

const Controls = styled(FlexContainer)`
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledCredentialSettingsCard = styled(CredentialSettingsCard)`
  margin-top: ${({ theme }) => theme.spacing.gutter};
`;

const InstitutionAccount = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const InstitutionAccountDetails = styled(FlexContainer)<{ $isDeleted: boolean }>`
  flex-direction: column;
  opacity: ${({ $isDeleted }) => ($isDeleted ? 0.5 : 1)};
`;

const InstitutionAccountControls = styled(FlexContainer)`
  gap: ${({ theme }) => theme.spacing.xsmall};
  margin-left: auto;
`;

const InstitutionSettings = () => {
  const history = useHistory();

  const { data, isLoadingInitialData, refetch } = useQuery<Web_GetInstitutionSettingsQuery>(
    INSTITUTION_SETTINGS_QUERY,
  );

  const [
    ConfirmUndeleteAccountModal,
    { open: openConfirmUndeleteAccountModal, close: closeConfirmUndeleteAccountModal },
  ] = useModal();

  const { subscription } = data || {};

  const { hasPremiumEntitlement, isOnFreeTrial } = subscription || {};

  const [accountBeingUndeleted, setAccountBeingUndeleted] = useState<{
    id: string;
    displayName: string;
  } | null>();

  const [accountIdBeingEdited, setAccountIdBeingEdited] = useState<string | null>();

  const [EditAccountModal, { close: closeEditModal, open: openEditModal }] = useModal();

  const orderedAccounts = useMemo(
    () => [
      ...(data?.accounts || []).filter((a) => !a.deletedAt),
      ...(data?.accounts || []).filter((a) => a.deletedAt),
    ],
    [data?.accounts],
  );

  return (
    <Column md={9}>
      <Helmet>
        <title>Institutions Settings</title>
      </Helmet>
      <SettingsCard
        title="Institutions"
        controls={
          <Controls>
            <RefreshAllButton />
            {(hasPremiumEntitlement || isOnFreeTrial) && (
              <PrimaryButton
                onClick={() => history.push(routes.settings.institutions.addAccount())}
                size="small"
              >
                Add account
              </PrimaryButton>
            )}
          </Controls>
        }
      >
        <HeaderDescription>
          Link all of your financial accounts to get a complete view of your finances. For more
          information about institutions we connect to check out our{' '}
          <Link href={HELP_CENTER_CONNECTION_ISSUES_URL} target="_blank">
            help center
          </Link>
          .
        </HeaderDescription>
      </SettingsCard>

      {isLoadingInitialData ? (
        <SpinnerContainer>
          <LoadingSpinner />
        </SpinnerContainer>
      ) : (
        sortCredentials(data?.credentials || []).map((credential) => (
          <StyledCredentialSettingsCard
            credential={credential}
            refetch={refetch}
            key={credential.id}
          >
            {orderedAccounts
              .filter(
                ({ credential: accountCredential }) => accountCredential?.id === credential.id,
              )
              .map((account) => (
                <InstitutionAccount key={account.id}>
                  <InstitutionAccountDetails $isDeleted={RA.isNotNil(account.deletedAt)}>
                    <Text>{account.displayName}</Text>
                    <Text color="textLight" size="small">
                      {account.subtype.display} {account.mask}
                    </Text>
                  </InstitutionAccountDetails>

                  <InstitutionAccountControls>
                    {account.deletedAt && (
                      <DefaultButton
                        onClick={() => {
                          setAccountBeingUndeleted(account);
                          openConfirmUndeleteAccountModal();
                        }}
                      >
                        Undelete
                      </DefaultButton>
                    )}
                    {!account.deletedAt && (
                      <>
                        <DefaultButton linkTo={routes.accounts.accountDetails({ id: account.id })}>
                          View
                        </DefaultButton>
                        <DefaultButton
                          onClick={() => {
                            setAccountIdBeingEdited(account.id);
                            openEditModal();
                          }}
                        >
                          Edit
                        </DefaultButton>
                      </>
                    )}
                  </InstitutionAccountControls>
                </InstitutionAccount>
              ))}
          </StyledCredentialSettingsCard>
        ))
      )}
      {accountBeingUndeleted && (
        <ConfirmUndeleteAccountModal>
          <UndeleteAccountConfirmation
            account={accountBeingUndeleted}
            onCancel={closeConfirmUndeleteAccountModal}
            onUndelete={() => {
              setAccountBeingUndeleted(null);
              toast({
                title: `Undeleted account ${accountBeingUndeleted.displayName}}`,
                description: 'It will re-appear on the accounts list and start syncing again.',
              });
              refetch();
            }}
          />
        </ConfirmUndeleteAccountModal>
      )}
      {accountIdBeingEdited && (
        <EditAccountModal>
          <AccountEditModal
            accountId={accountIdBeingEdited}
            onDone={() => {
              closeEditModal();
            }}
            onClose={closeEditModal}
          />
        </EditAccountModal>
      )}

      <RouteModal path={routes.settings.institutions.addAccount.path} exact>
        {({ close }) => (
          <LinkAccountFlow
            onComplete={() => {
              refetch();
              close();
            }}
            onClose={close}
          />
        )}
      </RouteModal>
    </Column>
  );
};

const INSTITUTION_SETTINGS_QUERY = gql(`
  query Web_GetInstitutionSettings {
    credentials {
      id
      ...CredentialSettingsCardFields
    }
    accounts(filters: { includeDeleted: true }) {
      id
      displayName
      subtype {
        display
      }
      mask
      credential {
        id
      }
      deletedAt
    }
    subscription {
      isOnFreeTrial
      hasPremiumEntitlement
    }
  }
`);

export default InstitutionSettings;
