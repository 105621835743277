import React, { useMemo } from 'react';
import styled from 'styled-components';

import Dot from 'components/lib/ui/Dot';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Tooltip from 'components/lib/ui/Tooltip';

import { spacing } from 'common/lib/theme/dynamic';
import { sortTags } from 'common/lib/transactions/tags';

type Tag = {
  id: string;
  name: string;
  color: string;
  order: number;
};

const DOT_SIZE_PX = 14;

const StyledDot = styled(Dot).attrs({ size: DOT_SIZE_PX })`
  transition: margin-left 0.2s cubic-bezier(0.21, 1.02, 0.73, 1);
  border: 1px solid ${({ theme }) => theme.color.white};

  &:not(:first-child) {
    margin-left: -4px;
  }
`;

const Root = styled(FlexContainer).attrs({ center: true })<{ $animate: boolean }>`
  margin-right: ${spacing.small};
  padding-left: 4px;

  &:hover {
    cursor: pointer;

    ${StyledDot} {
      margin-left: ${({ $animate }) => ($animate ? -3 : 0)}px;
    }
  }
`;

type Props<TTag> = {
  tags: TTag[];
  onClick?: () => void;
};

const TransactionTagDots = <TTag extends Tag>({ tags, onClick }: Props<TTag>) => {
  const sorted = useMemo(() => sortTags(tags), [tags]);
  const names = useMemo(() => sorted.map(({ name }) => name).join(', '), [sorted]);

  return (
    <Tooltip content={names}>
      <Root onClick={() => onClick?.()} $animate={tags.length > 1}>
        {sorted.map(({ id, color }) => (
          <StyledDot key={id} color={color} />
        ))}
      </Root>
    </Tooltip>
  );
};

export default TransactionTagDots;
