import type { DateTime } from 'luxon';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

const Grow = styled.div`
  flex: 1;
`;

type Props = {
  numColumns: number;
  getDateForColumn: (column: number) => DateTime;
  children: (column: number, date: DateTime) => React.ReactNode;
};

/** Component to render a row of columns. This may be used in the future for virtualized scrolling. */
const PlanColumns = ({ numColumns, getDateForColumn, children }: Props) => (
  <>
    <Grow />
    {R.range(0, numColumns).map((column) => (
      <React.Fragment key={column}>{children(column, getDateForColumn(column))}</React.Fragment>
    ))}
  </>
);

export default PlanColumns;
