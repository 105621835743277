import { useMutation, gql } from '@apollo/client';
import { DateTime } from 'luxon';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import CancelSponsorshipConfirmation from 'components/advisorPortal/CancelSponsorshipConfirmation';
import ClickToCopyCode from 'components/advisorPortal/ClickToCopyCode';
import ClientStatusPill from 'components/advisorPortal/ClientStatusPill';
import ClientsListRowMenu from 'components/advisorPortal/ClientsListRowMenu';
import EditInviteModal from 'components/advisorPortal/EditInviteModal';
import RevokeInviteConfirmation from 'components/advisorPortal/RevokeInviteConfirmation';
import SendInviteModal from 'components/advisorPortal/SendInviteModal';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Modal from 'components/lib/ui/Modal';
import Text from 'components/lib/ui/Text';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import useToggle from 'common/lib/hooks/useToggle';
import {
  getInviteClientMailBodyTemplate,
  SubscriptionSponsorshipInviteStatus,
  SubscriptionSponsorshipStatus,
} from 'common/lib/sponsorship';
import type {
  SubscriptionSponsorshipStatusType,
  SubscriptionSponsorshipInviteStatusType,
} from 'common/lib/sponsorship';
import { formatRelativeTime } from 'common/utils/date';
import getStringEnvVar from 'lib/getStringEnvVar';

import routes from 'constants/routes';

import type {
  Web_CancelSponsorship,
  Web_CancelSponsorshipVariables,
} from 'common/generated/graphQlTypes/Web_CancelSponsorship';
import type { Web_GetAdvisorClientsPage_subscriptionSponsorshipInvites_sponsorship } from 'common/generated/graphQlTypes/Web_GetAdvisorClientsPage';
import type {
  Web_RevokeSponsorshipInvite,
  Web_RevokeSponsorshipInviteVariables,
} from 'common/generated/graphQlTypes/Web_RevokeSponsorshipInvite';

export const ROW_HEIGHT_PX = 76;

const Root = styled.div<{ $enablePointer: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  cursor: ${({ $enablePointer }) => ($enablePointer ? 'pointer' : 'inherit')};
  user-select: ${({ $enablePointer }) => ($enablePointer ? 'none' : 'auto')};
  transition: background-color 0.1s ease-out;
  background-color: transparent;
  :not(:last-child) {
    border-bottom-color: ${({ theme }) => theme.color.grayBackground};
  }
  border-bottom-color: ${({ theme }) => theme.color.grayBackground};
`;

const RowContainer = styled(Flex)`
  width: 100%;
  height: ${ROW_HEIGHT_PX}px;
  padding: 0 ${({ theme }) => theme.spacing.xlarge};
  padding-top: ${({ theme }) => theme.spacing.xxsmall};
  grid-gap: ${(props) => props.theme.spacing.xxsmall};
  background-color: ${(props) => props.theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.base};
  transition: border-color 0.1s ease-out;
  /* Negative left/right margin lets the row borders overlap the surrounding list border. */
  margin: -1px -1px 0;
  &:first-child {
    margin-top: 0;
  }
`;

type ColumnProps = {
  span?: number;
};

const Column = styled(Flex)<ColumnProps>`
  display: flex;
  align-items: center;
  flex: ${(props) => props.span ?? 1};
  > * {
    width: 100%;
  }
`;

const ActionsContainer = styled(Flex)`
  gap: ${(props) => props.theme.spacing.small};
`;

const NameLabel = styled(Text)`
  text-overflow: ellipsis;
  width: 100%;
`;

const EmailLabel = styled(Text)`
  text-overflow: ellipsis;
  width: 100%;
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const StyledButtonText = styled.span`
  white-space: nowrap;
`;

type Props = {
  id: string;
  code: string;
  label?: string | null;
  inviteStatus?: string;
  sponsorship?: Web_GetAdvisorClientsPage_subscriptionSponsorshipInvites_sponsorship | null;
  createdAt?: string;
  sponsor?: { name?: string | null } | null;
  userId?: string;
  sentToEmail?: string | null;
};

const ClientsListRow = ({
  id,
  code,
  label,
  inviteStatus,
  sponsor,
  sponsorship,
  userId,
  sentToEmail,
}: Props) => {
  const isDemo = inviteStatus === 'demo';

  const [revokeInvite, { loading: isRevokeInviteMutationLoading }] = useMutation<
    Web_RevokeSponsorshipInvite,
    Web_RevokeSponsorshipInviteVariables
  >(REVOKE_INVITE_MUTATION, { refetchQueries: ['Web_GetAdvisorClientsPage'] });

  const [cancelSponsorship, { loading: isCancelSponsorshipMutationLoading }] = useMutation<
    Web_CancelSponsorship,
    Web_CancelSponsorshipVariables
  >(CANCEL_SPONSORSHIP_MUTATION, { refetchQueries: ['Web_GetAdvisorClientsPage'] });

  const [
    isConfirmingInviteRevocation,
    { setOn: openRevokeInviteConfirmationModal, setOff: closeRevokeInviteConfirmationModal },
  ] = useToggle(false);
  const [
    isConfirmingSponsorshipCancellation,
    {
      setOn: openCancelSponsorshipConfirmationModal,
      setOff: closeCancelSponsorshipConfirmationModal,
    },
  ] = useToggle(false);
  const [isSendInviteModalOpen, { setOn: openSendInviteModal, setOff: closeSendInviteModal }] =
    useToggle(false);
  const [isEditInviteModalOpen, { setOn: openEditInviteModal, setOff: closeEditInviteModal }] =
    useToggle(false);

  const { status: sponsorshipStatus, household: sponsoredHousehold } = sponsorship ?? {};

  const handleRevokeInviteConfirmClick = async () => {
    await revokeInvite({
      variables: {
        input: {
          id,
        },
      },
    });
    closeRevokeInviteConfirmationModal();
  };

  const handleCancelSponsorshipConfirmClick = async () => {
    if (sponsorship?.id) {
      await cancelSponsorship({
        variables: {
          input: {
            id: sponsorship?.id,
          },
        },
      });
      closeCancelSponsorshipConfirmationModal();
    }
  };

  const handleSendCodeButtonClick = () => {
    openSendInviteModal();
  };

  return (
    <Root $enablePointer={false}>
      <RowContainer>
        <Column span={2}>
          <div>
            <ClickToCopyCode value={code} />
          </div>
        </Column>
        <Column span={3}>
          <FlexContainer column>
            <NameLabel align="left">{label ?? '-'}</NameLabel>
            {inviteStatus === SubscriptionSponsorshipInviteStatus.Pending && (
              <EmailLabel>Sent to: {sentToEmail}</EmailLabel>
            )}
            <Text color="textLight" size="small">
              {sponsoredHousehold?.lastActivityAt &&
                `Last active: ${formatRelativeTime(
                  DateTime.fromISO(sponsoredHousehold?.lastActivityAt),
                )} ago`}
            </Text>
          </FlexContainer>
        </Column>
        <Column span={2}>
          <div>
            <ClientStatusPill
              inviteStatus={inviteStatus as SubscriptionSponsorshipInviteStatusType}
              sponsorshipStatus={sponsorshipStatus as SubscriptionSponsorshipStatusType}
            />
          </div>
        </Column>
        <Column span={2}>
          <ActionsContainer justifyEnd>
            {RA.isNotNil(sponsorship) &&
              sponsorshipStatus === SubscriptionSponsorshipStatus.Active && (
                <ActionsContainer justifyEnd>
                  <DefaultButton
                    linkTo={routes.actAsUser({
                      id: userId,
                    })}
                    target="_blank"
                  >
                    <ButtonIcon name="external-link" />
                    <StyledButtonText>View household</StyledButtonText>
                  </DefaultButton>
                </ActionsContainer>
              )}
            {isDemo && (
              <ActionsContainer justifyEnd>
                <DefaultButton
                  linkTo={routes.actAsUser({
                    id: userId,
                  })}
                  target="_blank"
                >
                  <ButtonIcon name="external-link" />
                  <StyledButtonText>View household</StyledButtonText>
                </DefaultButton>
              </ActionsContainer>
            )}
            {inviteStatus === SubscriptionSponsorshipInviteStatus.Pending && (
              <DefaultButton onClick={handleSendCodeButtonClick}>
                <ButtonIcon name="mail" />
                <StyledButtonText>Send code</StyledButtonText>
              </DefaultButton>
            )}
            <ClientsListRowMenu
              onEditClient={openEditInviteModal}
              onRevokeInvite={openRevokeInviteConfirmationModal}
              onCancelSponsorship={openCancelSponsorshipConfirmationModal}
              hideRevokeAction={inviteStatus !== SubscriptionSponsorshipInviteStatus.Pending}
              hideCancelSponsorshipAction={
                sponsorshipStatus !== SubscriptionSponsorshipStatus.Active
              }
            />
          </ActionsContainer>
        </Column>
      </RowContainer>
      {isConfirmingInviteRevocation && (
        <Modal onClose={closeRevokeInviteConfirmationModal}>
          {({ close }) => (
            <RevokeInviteConfirmation
              isLoading={isRevokeInviteMutationLoading}
              onConfirm={handleRevokeInviteConfirmClick}
              onCancel={close}
            />
          )}
        </Modal>
      )}
      {isConfirmingSponsorshipCancellation && (
        <Modal onClose={closeCancelSponsorshipConfirmationModal}>
          {({ close }) => (
            <CancelSponsorshipConfirmation
              isLoading={isCancelSponsorshipMutationLoading}
              sponsoredHouseholdName={sponsoredHousehold?.name}
              onConfirm={handleCancelSponsorshipConfirmClick}
              onCancel={close}
            />
          )}
        </Modal>
      )}
      {isEditInviteModalOpen && (
        <Modal onClose={closeEditInviteModal}>
          {({ close }) => (
            <EditInviteModal initiaValues={{ id, label, sentToEmail }} onDone={close} />
          )}
        </Modal>
      )}
      {isSendInviteModalOpen && (
        <Modal onClose={closeSendInviteModal}>
          {({ close }) => (
            <SendInviteModal
              initialValues={{
                id,
                label,
                sentToEmail,
              }}
              templateMessage={getInviteClientMailBodyTemplate(
                sponsor?.name ?? '',
                code,
                getStringEnvVar('REACT_APP_BASE_URL'),
              )}
            />
          )}
        </Modal>
      )}
    </Root>
  );
};

const CANCEL_SPONSORSHIP_MUTATION = gql`
  mutation Web_CancelSponsorship($input: CancelSponsorshipInput!) {
    cancelSubscriptionSponsorship(input: $input) {
      canceled
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

const REVOKE_INVITE_MUTATION = gql`
  mutation Web_RevokeSponsorshipInvite($input: RevokeSponsorshipInviteInput!) {
    revokeSubscriptionSponsorshipInvite(input: $input) {
      revoked
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default ClientsListRow;
