import React from 'react';
import styled from 'styled-components';

import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import DangerButton from 'components/lib/ui/button/DangerButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { NUM_REQUIRED_SPLITS } from 'components/transactions/TransactionSplitForm';

import { color, spacing } from 'common/lib/theme/dynamic';

const Footer = styled.div`
  margin-top: ${spacing.xlarge};
  padding: ${spacing.default} ${spacing.xlarge};
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${color.grayBackground};
`;

const SubmitButton = styled(FormSubmitButton)`
  width: auto;
  margin-top: 0;
  margin-left: ${spacing.default};
`;

const FooterRight = styled(FlexContainer).attrs({ alignCenter: true })`
  margin-left: auto;
`;

type Props = {
  splitCount: number;
  onRemoveAllSplits: () => void;
  onCancel: () => void;
  isEditMode: boolean;
  isLoading?: boolean;
};

const TransactionSplitFormFooter = ({
  splitCount,
  onRemoveAllSplits,
  onCancel,
  isEditMode,
  isLoading,
}: Props) => (
  <Footer>
    {splitCount > 0 && (
      <DangerButton onClick={onRemoveAllSplits}>
        {isEditMode ? 'Unsplit' : 'Remove all splits'}
      </DangerButton>
    )}
    <FooterRight>
      {splitCount > 0 && splitCount < NUM_REQUIRED_SPLITS && (
        <FlexContainer marginRight="default">
          <Text color="red">You must add at least two splits</Text>
        </FlexContainer>
      )}
      <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
      <SubmitButton pending={isLoading} size="small">
        {isEditMode ? 'Save' : `Split into ${splitCount} transactions`}
      </SubmitButton>
    </FooterRight>
  </Footer>
);

export default TransactionSplitFormFooter;
