import React from 'react';
import styled from 'styled-components';

import { useAdvisorOnboardingFlowState } from 'components/advisorOnboarding/context/AdvisorOnboardingProvider';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import ManualLink from 'components/lib/ui/link/ManualLink';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import getUserApi from 'common/lib/api/user';
import api from 'lib/api';
import { toastPromise } from 'lib/ui/toast';

import * as COPY from 'common/constants/copy';

const StyledOnboardingTitle = styled(OnboardingTitle)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: 106px;
  }
`;

const StyledOnboardingSubtitle = styled(OnboardingSubtitle)`
  max-width: 390px;
`;

const AdvisorOnboardingVerifyEmail = () => {
  const { state, next } = useAdvisorOnboardingFlowState();
  const { email = '' } = state;

  const onSubmit = async ({ code }: { code: string }) => {
    await getUserApi(api).verifyEmail({ code, email });
    next({ email, code });
  };

  return (
    <>
      <StyledOnboardingTitle>{COPY.ONBOARDING.VERIFY_EMAIL.TITLE}</StyledOnboardingTitle>
      <StyledOnboardingSubtitle>
        {COPY.ONBOARDING.VERIFY_EMAIL.SUBTITLE(email)}
      </StyledOnboardingSubtitle>
      <OnboardingFormContainer>
        <Form onSubmit={onSubmit}>
          <TextField
            name="code"
            required
            placeholder="123456"
            minLength={6}
            autoFocus
            autoFocusOnMobile={false}
          />
          <FlexContainer center marginTop="large" marginBottom="large">
            <Text size="small" align="center">
              Don&apos;t see a code?{' '}
              <ManualLink
                onClick={() =>
                  toastPromise(getUserApi(api).createVerification(email), {
                    loading: {
                      title: COPY.ADVISOR_ONBOARDING.VERIFY_EMAIL.TOAST_RESEND_CODE_LOADING_TITLE,
                      description:
                        COPY.ADVISOR_ONBOARDING.VERIFY_EMAIL.TOAST_RESEND_CODE_LOADING_SUBTITLE,
                    },
                    success: {
                      title: COPY.ADVISOR_ONBOARDING.VERIFY_EMAIL.TOAST_RESEND_CODE_SUCCESS_TITLE,
                      description:
                        COPY.ADVISOR_ONBOARDING.VERIFY_EMAIL.TOAST_RESEND_CODE_SUCCESS_SUBTITLE,
                    },
                    error: {
                      title: COPY.ADVISOR_ONBOARDING.VERIFY_EMAIL.TOAST_RESEND_CODE_ERROR_TITLE,
                      description:
                        COPY.ADVISOR_ONBOARDING.VERIFY_EMAIL.TOAST_RESEND_CODE_ERROR_SUBTITLE,
                    },
                  })
                }
              >
                {COPY.ADVISOR_ONBOARDING.VERIFY_EMAIL.RESEND_CODE_BUTTON}
              </ManualLink>
            </Text>
          </FlexContainer>
          <FormSubmitButton size="medium" variant="primary">
            {COPY.ONBOARDING.VERIFY_EMAIL.BUTTON}
          </FormSubmitButton>
        </Form>
      </OnboardingFormContainer>
    </>
  );
};

export default AdvisorOnboardingVerifyEmail;
