const isEnumValue = <T extends Record<string, unknown>>(
  value: unknown,
  anEnum: T,
): value is T[keyof T] => Object.values(anEnum).includes(value);

/**
 * Ensure that a value is a member of an enum.
 *
 * Returns the value as the enum type if it is a member, otherwise returns defaultValue or
 * undefined if defaultValue is not provided.
 */
export const ensureEnumValue = <
  T extends string,
  EnumValueT extends string,
  DefaultValueT extends EnumValueT | undefined,
>(
  anEnum: { [key in T]: EnumValueT },
  value: string | null | undefined,
  defaultValue?: DefaultValueT,
) =>
  isEnumValue(value, anEnum)
    ? (value as EnumValueT)
    : (defaultValue as DefaultValueT extends undefined ? EnumValueT | undefined : EnumValueT);
