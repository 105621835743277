import React, { useState } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import Toggle from 'components/lib/ui/Toggle';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';

import * as COPY from 'common/constants/copy';

const PopoverContent = styled(FlexContainer).attrs({ column: true, gap: 'default' })`
  width: 240px;
  padding: ${({ theme }) => theme.spacing.default};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const Row = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  cursor: pointer;
  user-select: none;
`;

const Button = styled(AsyncButton)`
  ${primaryButtonMixin}
`;

type SharePopoverContentProps = {
  hideAmounts: boolean;
  toggleHideAmounts: () => void;
  onClickShare: (transparentBg: boolean) => void | Promise<void>;
};

const SharePopoverContent = ({
  hideAmounts,
  toggleHideAmounts,
  onClickShare,
}: SharePopoverContentProps) => {
  const [transparentBg, setTransparentBg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PopoverContent>
      <Text>{COPY.CASH_FLOW.SHARE_POPOVER_CONTENT}</Text>
      <Row
        onClick={(e) => {
          e.stopPropagation();
          toggleHideAmounts();
        }}
      >
        <Text weight="medium">{COPY.CASH_FLOW.SHARE_POPOVER_HIDE_AMOUNTS}</Text>
        <Toggle checked={hideAmounts} onChange={toggleHideAmounts} />
      </Row>
      <Row onClick={() => setTransparentBg(!transparentBg)}>
        <Text weight="medium">{COPY.CASH_FLOW.SHARE_POPOVER_TRANSPARENT_BACKGROUND}</Text>
        <Toggle checked={transparentBg} />
      </Row>
      <Button
        onClick={async () => {
          setIsLoading(true);
          await onClickShare(transparentBg);
          setIsLoading(false);
        }}
        pending={isLoading}
      >
        {COPY.CASH_FLOW.GENERATE_IMAGE_BUTTON}
      </Button>
    </PopoverContent>
  );
};
export default SharePopoverContent;
