import { gql } from '@apollo/client';

export const PAYLOAD_ERRORS_FRAGMENT = gql`
  fragment PayloadErrorFields on PayloadError {
    fieldErrors {
      field
      messages
    }
    message
    code
  }
`;
