import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import type { DateHeaderProps } from 'react-big-calendar';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';

import { isSameDay } from 'common/utils/date';

const Root = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  margin-bottom: ${({ theme }) => theme.spacing.xxxsmall};
`;

const Day = styled(Text)<{ $isToday: boolean }>`
  height: ${({ theme }) => theme.spacing.xlarge};
  width: ${({ theme }) => theme.spacing.xlarge};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $isToday, theme }) =>
    $isToday &&
    `
  border-radius: ${theme.radius.round};
  background-color: ${theme.color.orange};
  color: ${theme.color.textWhite};
  `}
`;

const DateHeader = ({ date, label }: DateHeaderProps) => {
  const isToday = useMemo(() => isSameDay(DateTime.local(), DateTime.fromJSDate(date)), [date]);

  return (
    <Root>
      <Day
        $isToday={isToday}
        color={isToday ? 'textLight' : 'text'}
        weight={isToday ? 'medium' : undefined}
        size="xsmall"
      >
        {parseInt(label, 10)}
      </Day>
    </Root>
  );
};

export default DateHeader;
