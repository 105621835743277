import { createSelector } from 'reselect';

import { getForceEmptyState } from 'common/state/employee/selectors';
import type { RootState } from 'state/types';

export const getEmptyState = ({ emptyState }: RootState) => emptyState;

// true if user has accounts
// false if user doesn't have accounts
// undefined if we don't know
export const getHasAccounts = createSelector(getEmptyState, ({ hasAccounts }) => hasAccounts);

// only true if we are sure the user doesn't have accounts
export const getDoesNotHaveAccounts = createSelector(
  getEmptyState,
  getForceEmptyState,
  ({ hasAccounts }, forceEmptyState) => forceEmptyState || hasAccounts === false,
);
