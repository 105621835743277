import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';

import type { NotificationProps } from './BaseNotification';
import BaseNotification from './BaseNotification';

const IconCircle = styled(FlexContainer).attrs({ center: true })`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.color.grayFocus};
  border-radius: ${({ theme }) => theme.radius.round};
  margin-right: ${({ theme }) => theme.spacing.default};
  text-align: center;
`;

const Emoji = styled.div`
  /* Fix centering on Chrome https://stackoverflow.com/questions/59558227/how-to-visually-horizontally-center-an-emoji-in-chrome */
  font-size: calc(${({ theme }) => theme.fontSize.base} * 2);
  transform: scale(0.5);
`;

const Notification = ({ title, ...props }: NotificationProps) => {
  const [icon, titleWithoutIcon] = title?.split(/ (.+)/) ?? [];

  return (
    <BaseNotification
      {...props}
      title={titleWithoutIcon}
      icon={
        <IconCircle>
          <Emoji>{icon}</Emoji>
        </IconCircle>
      }
    />
  );
};

export default Notification;
