import React from 'react';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  width: 419px;
  padding: ${({ theme }) => theme.spacing.xlarge};
  margin: 145px auto 0 auto;
  box-shadow: 0px 8px 16px rgba(0, 40, 100, 0.08);
  border-radius: ${({ theme }) => theme.radius.medium};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleText = styled(Text)`
  width: 100%;
`;

type Props = {
  title?: string;
  children?: React.ReactNode;
  className?: string;
};

const PageEmptyOverlayCard = ({ title, children, className }: Props) => (
  <Root className={className}>
    {!!title && (
      <TitleText weight="medium" align="center">
        {title}
      </TitleText>
    )}
    {children}
  </Root>
);

export default PageEmptyOverlayCard;
