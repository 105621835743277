import React from 'react';

import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';

import { HELP_CENTER_URL } from 'common/constants/externalUrls';

const ContactSupportHelpCenterText = () => (
  <Text>
    Please check out our{' '}
    <Link href={HELP_CENTER_URL} target="_blank" rel="noopener noreferrer">
      Help Center
    </Link>{' '}
    for answers to frequently asked questions. If you still need help, you can contact our support
    team below.
  </Text>
);

export default ContactSupportHelpCenterText;
