import { useMutation } from '@apollo/client';
import React, { useState, useMemo } from 'react';

import Tabs from 'common/components/tabs/Tabs';
import Form from 'components/lib/form/Form';
import DeleteTransactionRuleModal from 'components/transactions/DeleteTransactionRuleModal';
import TransactionRuleCard, {
  APPLY_TO_EXISTING_FIELD,
} from 'components/transactions/TransactionRuleCard';

import { CREATE_RULE_MUTATION_V2, UPDATE_RULE_MUTATION_V2 } from 'common/lib/graphQl/rules';
import {
  convertRuleToFormValues,
  validationSchema,
  EMPTY_RULE,
  transformRuleFormValuesBeforeSubmit,
} from 'common/lib/transactions/Rules';

import type {
  TransactionRuleFieldsFragment,
  UpdateTransactionRuleInput,
} from 'common/generated/graphql';

type Props = {
  onDone: () => void;
  editRule?: TransactionRuleFieldsFragment;
  initialValues?: Partial<UpdateTransactionRuleInput> & { id?: string };
  // Used to refetch transactions after the rule is created/updated
  refetchTransactions?: () => void;
};

const TransactionRuleModal = ({ editRule, initialValues, onDone, refetchTransactions }: Props) => {
  const [createRule] = useMutation(CREATE_RULE_MUTATION_V2, {
    onCompleted: refetchTransactions,
  });
  const [updateRule] = useMutation(UPDATE_RULE_MUTATION_V2, {
    onCompleted: refetchTransactions,
  });

  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const formInitialValues = useMemo(() => {
    const formValues = editRule
      ? convertRuleToFormValues(editRule)
      : { ...EMPTY_RULE, ...initialValues };
    return {
      ...formValues,
      [APPLY_TO_EXISTING_FIELD]: false,
    };
  }, [editRule, initialValues]);
  const isInitialValid = useMemo(
    () => validationSchema.isValidSync(formInitialValues),
    [formInitialValues],
  );

  return (
    <Tabs>
      <Form
        onSubmitSuccess={onDone}
        initialValues={formInitialValues}
        isInitialValid={isInitialValid}
        overrideValidationSchema={validationSchema}
        onSubmit={async (formValues: UpdateTransactionRuleInput) => {
          const values = transformRuleFormValuesBeforeSubmit(formValues);
          if (editRule) {
            await updateRule({
              variables: {
                input: {
                  ...values,
                  id: editRule.id,
                },
              },
              refetchQueries: ['GetTransactionRules'],
            });
          } else {
            await createRule({
              variables: {
                input: values,
              },
              refetchQueries: ['GetTransactionRules'],
            });
          }
        }}
      >
        <TransactionRuleCard
          onClickDelete={() => setConfirmingDelete(true)}
          showDeleteButton={!!editRule}
          title={editRule ? 'Edit rule' : 'New rule'}
        />
      </Form>
      {confirmingDelete && editRule && (
        <DeleteTransactionRuleModal
          ruleId={editRule.id}
          onCancel={() => setConfirmingDelete(false)}
          onDone={onDone}
        />
      )}
    </Tabs>
  );
};

export default TransactionRuleModal;
