import type { ErrorResponse } from '@apollo/client/link/error';
import type { GraphQLError } from 'graphql';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import type { PayloadErrorFields } from 'common/generated/graphQlTypes/PayloadErrorFields';
import type { ErrorCode } from 'common/generated/graphQlTypes/globalTypes';
import type { RawFieldValidationAPIError, RawAPIError, RawDetailedAPIError } from 'common/types';

export type MonarchError = APIError | GraphQlError | GraphQlMutationError | ValidationError;

export type GraphQlAndNetworkError = Pick<ErrorResponse, 'graphQLErrors' | 'networkError'>;

export class BaseError extends Error {
  get message() {
    return this.toString();
  }
}

export class APIError extends BaseError {
  _message: string;
  data: { [key: string]: string | string[] } | undefined;
  status?: number;
  constructor(error: RawAPIError) {
    super();
    this.name = this.constructor.name;
    this.stack = error.stack;
    this._message = error.message;
    this.data = error.response?.data;
    this.status = error.response?.status;
  }

  toString() {
    return JSON.stringify(this.data);
  }
}

export class FieldValidationAPIError extends APIError {
  data: { [key: string]: string[] };

  constructor(rawAPIError: RawFieldValidationAPIError) {
    super(rawAPIError);
    this.data = rawAPIError.response.data;
  }
}

export class DetailedAPIError extends APIError {
  data: { detail: string; error_code: string };
  constructor(rawAPIError: RawDetailedAPIError) {
    super(rawAPIError);
    this.data = rawAPIError.response.data;
  }
}

export class GraphQlError extends BaseError {
  errorResponse: GraphQlAndNetworkError;
  operationName: string;

  constructor(operationName: string, errorResponse: GraphQlAndNetworkError) {
    super();
    this.errorResponse = errorResponse;
    this.operationName = operationName;
  }

  toString() {
    const messages = this.errorResponse.graphQLErrors?.map(R.prop('message'));
    return `errorResponse(s) on operation: ${this.operationName}, ${messages} - ${this.errorResponse.networkError}`;
  }
}

export class ValidationError extends BaseError {
  data: any;

  constructor(data: any) {
    super();
    this.data = data;
  }
}

// SerializerMutations return these error types as part of the mutation response, where an entry
// means a field that was attempted to be updated caused one or more error messages.
// As of now, mutations create their own type for this
// (e.g. UpdateNotificationPreferences_updateNotificationPreferences_errors) but we might want
// to make this a custom, global scalar.
export type GraphQlMutationErrorInfo = PayloadErrorFields;

export class GraphQlMutationError extends BaseError {
  errors: GraphQlMutationErrorInfo;

  constructor(errors: GraphQlMutationErrorInfo) {
    super();
    this.errors = errors;
  }

  toString() {
    const fieldErrorString = this.errors.fieldErrors
      ? this.errors.fieldErrors.map((e) => `${e.field}: ${e.messages.join(', ')}`)
      : '';
    const errorMessage = this.errors.message || '';
    const codeMessage = this.errors.code || '';
    return RA.compact([errorMessage, fieldErrorString, codeMessage]).join(' - ');
  }
}

export const containsErrorCode = (errors: readonly GraphQLError[], code: ErrorCode) =>
  errors.find((e) => e?.message.includes(code));
