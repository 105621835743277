import type { MutationHookOptions } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';

import type { SendMessage, SendMessageVariables } from 'common/generated/graphQlTypes/SendMessage';

const useSendMessage = (options?: MutationHookOptions<SendMessage, SendMessageVariables>) => {
  const mutation = useMutation<SendMessage, SendMessageVariables>(SEND_MESSAGE, options);

  return mutation;
};

const SEND_MESSAGE = gql`
  mutation Common_SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      messageThread {
        id
        lastMessageSentAt
      }
    }
  }
`;

export default useSendMessage;
