import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';

import fieldStyleMixin from 'lib/styles/fieldStyleMixin';

/**
 * Autosizing text area using https://github.com/Andarist/react-textarea-autosize
 *
 * @param maxRows Maximum number of rows up to which the textarea can grow
 * @param minRows Minimum number of rows to show for textarea
 */
const Input = ({
  small,
  dynamic,
  hideResizeHandle,
  ...props
}: {
  small?: boolean;
  dynamic?: boolean;
  hideResizeHandle?: boolean;
} & Omit<React.ComponentProps<typeof TextareaAutosize>, 'ref'>) => <TextareaAutosize {...props} />;

const TextAreaInput = styled(Input)`
  ${fieldStyleMixin}
  box-sizing: content-box;

  ${({ hideResizeHandle }) =>
    hideResizeHandle &&
    css`
      resize: none;
    `}
`;

export default TextAreaInput;
