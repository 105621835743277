import styled from 'styled-components';

import Text from 'components/lib/ui/Text';

const OnboardingSubtitle = styled(Text)`
  max-width: 360px;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

export default OnboardingSubtitle;
