import { useMutation } from '@apollo/client';
import React from 'react';

import AccountLogo from 'components/accounts/AccountLogo';
import LogoSettings from 'components/settings/LogoSettings';

import useUploadAccountLogo from 'common/lib/hooks/account/useUploadAccountLogo';

import { gql } from 'common/generated/gql';

const DELETE_ACCOUNT_LOGO = gql(`
  mutation Web_DeleteAccountLogo($accountId: ID!) {
    deleteAccountLogo(accountId: $accountId) {
      success
      account {
        id
        logoUrl
        hasCustomizedLogo
      }
    }
  }
`);

type Props = {
  accountLogo?: string | null;
  accountId: string;
  hasCustomizedLogo?: boolean | null;
};

const AccountLogoSettings = ({ accountLogo, accountId, hasCustomizedLogo }: Props) => {
  const uploadAccountLogo = useUploadAccountLogo();

  const [deleteAccountLogo] = useMutation(DELETE_ACCOUNT_LOGO, {
    variables: { accountId },
  });

  return (
    <LogoSettings
      logo={accountLogo}
      id={accountId}
      onUpload={uploadAccountLogo}
      onDelete={deleteAccountLogo}
      isCustomImage={!!hasCustomizedLogo}
      getLogoComponent={(url, size) => <AccountLogo logoUrl={url} size={size} />}
    />
  );
};

export default AccountLogoSettings;
