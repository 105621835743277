import { CategoryGroupType } from 'common/generated/graphql';

export const CATEGORY_GROUP_TYPE_ORDER: string[] = [
  CategoryGroupType.INCOME,
  CategoryGroupType.EXPENSE,
  CategoryGroupType.TRANSFER,
];

export const CATEGORY_GROUP_TYPE_TO_TITLE: { [type: string]: string } = {
  [CategoryGroupType.INCOME]: 'Income',
  [CategoryGroupType.EXPENSE]: 'Expenses',
  [CategoryGroupType.TRANSFER]: 'Transfers',
};

export const CATEGORY_GROUP_BUDGET_TYPE_OPTIONS = [
  {
    label: 'By group',
    value: true,
  },
  {
    label: 'By category',
    value: false,
  },
];
