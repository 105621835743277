import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import HoldingSummary from 'components/holdings/HoldingSummary';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import NumericField from 'components/lib/form/NumericField';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCancelButton from 'components/lib/ui/ModalCancelButton';
import Text from 'components/lib/ui/Text';

import { supportsManualHoldings } from 'common/lib/accounts/accountTypes';
import { GET_ACCOUNT_TYPE_OPTIONS } from 'common/lib/graphQl/accounts';
import useQuery from 'common/lib/hooks/useQuery';
import {
  getDisplayForType,
  getDisplayOptionsFromAccountTypeOptions,
} from 'lib/accounts/ManualAccounts';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';

import { AccountTypeName } from 'common/constants/accounts';

import type { GetAccountTypeOptions } from 'common/generated/graphQlTypes/GetAccountTypeOptions';

export type AddManualHoldingFormValues = {
  accountId: string;
  accountName?: string;
  accountSubtype?: string;
  securityId: string;
  quantity: number;
};

type Props = {
  showNewAccountFields?: boolean;
  skipAccountSelection?: boolean;
  initialValues?: Partial<AddManualHoldingFormValues>;
  onNewAccountSelected?: () => Promise<void> | void;
  onSubmit: (values: AddManualHoldingFormValues) => Promise<void>;
};

const FormContent = styled.div`
  min-height: 280px;
`;

const FormSection = styled.div`
  padding: ${({ theme }) => `${theme.spacing.default} ${theme.spacing.xlarge}`};
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const Title = styled(Text)`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding: ${({ theme }) => `0 ${theme.spacing.xlarge}`};
`;

const AddManualHoldingForm = ({
  initialValues,
  skipAccountSelection,
  showNewAccountFields,
  onNewAccountSelected,
  onSubmit,
}: Props) => {
  const { data } = useQuery<GetAccountTypeOptions>(GET_ACCOUNT_TYPE_OPTIONS);

  const { accountTypeOptions = [] } = data ?? {};
  const optionsForType = getDisplayOptionsFromAccountTypeOptions(
    AccountTypeName.BROKERAGE,
    accountTypeOptions,
  );

  const displayName = getDisplayForType(AccountTypeName.BROKERAGE, accountTypeOptions);

  const [isLoadingAccounts, allAccounts] = useAccountSelectOptions();
  const investmentAccountOptionsSupportingManualHoldings = useMemo(() => {
    const investmentsAccounts = allAccounts.find((el) => el.label === 'Investments') || {
      options: [],
    };

    const options = [
      {
        label: 'Investments',
        options: R.append(
          {
            value: 'New Manual Account',
            // @ts-ignore
            label: <Text color="blue">New Manual Account</Text>,
            onSelected: () => {
              onNewAccountSelected?.();
            },
          },
          investmentsAccounts.options.filter(({ accountData }) =>
            supportsManualHoldings(accountData),
          ),
        ),
      },
    ];

    return options;
  }, [allAccounts]);

  const handleSubmit = (values: AddManualHoldingFormValues) => {
    onSubmit(values);
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      <FormContext.Consumer>
        {({ values, initialValues, isValid, setFieldValue }) => (
          <>
            <FormContent>
              <Title>Add holding</Title>
              <FormSection>
                <HoldingSummary securityId={values.securityId} quantity={values.quantity} />
              </FormSection>
              <FormSection>
                <NumericField name="quantity" label="Quantity" placeholder="0.00" required />

                {skipAccountSelection ||
                  (!initialValues.accountId && !showNewAccountFields && (
                    <SelectField
                      name="accountId"
                      placeholder="Select an account"
                      label="Account"
                      // @ts-ignore
                      afterChange={({ onSelected }) => {
                        if (RA.isNotNil(onSelected)) {
                          onSelected();
                          setFieldValue('accountId', undefined);
                        }
                      }}
                      menuPortalTarget={document.body}
                      required={!skipAccountSelection}
                      isLoading={isLoadingAccounts}
                      options={investmentAccountOptionsSupportingManualHoldings}
                    />
                  ))}
                {skipAccountSelection ||
                  (showNewAccountFields && (
                    <>
                      <>
                        <SelectField
                          name="accountSubtype"
                          label="Type"
                          options={optionsForType}
                          required
                        />
                        <TextField
                          name="accountName"
                          placeholder={`My ${displayName} Account`}
                          required
                        />
                      </>
                    </>
                  ))}
              </FormSection>
            </FormContent>
            <CardFooter>
              <ModalCancelButton />
              <FormSubmitButton size="small" disabled={values.quantity <= 0 || !isValid}>
                Save
              </FormSubmitButton>
            </CardFooter>
          </>
        )}
      </FormContext.Consumer>
    </Form>
  );
};

export default AddManualHoldingForm;
