import * as React from 'react';
import styled from 'styled-components';

import {
  HeaderCardTimeRangeText,
  HeaderCardMainText,
  HeaderCardRoot,
  HeaderCardDivider,
} from 'components/investments/InvestmentsBenchmarkHeaderCard';
import { BenchmarkDivider } from 'components/investments/InvestmentsHoldingsBenchmarksHeader';
import ContentLoader from 'components/lib/ui/ContentLoader';
import FlexContainer from 'components/lib/ui/FlexContainer';

import useTheme from 'lib/hooks/useTheme';

type InvestmentHoldingsBenchmarksHeaderLoadingProps = {
  title?: string;
  highlightColor: string | null;
  rightMargin: boolean;
};

const StyledFlexContainer = styled(FlexContainer)`
  flex-basis: 50px;
  flex-direction: column;
`;

const HeaderCardMainTextLoading = styled(HeaderCardMainText)`
  height: 27px;
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const InvestmentsHeaderCardLoading = ({
  title,
  highlightColor,
  rightMargin,
}: InvestmentHoldingsBenchmarksHeaderLoadingProps) => (
  <HeaderCardRoot $highlightColor={highlightColor} $rightMargin={rightMargin} $canSelect={false}>
    <HeaderCardMainTextLoading>
      {title || (
        <ContentLoader>
          <rect x="0" y="4" rx="5" ry="5" width="100%" height="23" />
        </ContentLoader>
      )}
    </HeaderCardMainTextLoading>
    <FlexContainer marginLeft="default">
      <StyledFlexContainer>
        <HeaderCardTimeRangeText>90-DAY</HeaderCardTimeRangeText>
        <ContentLoader>
          <rect x="0" y="4" rx="5" ry="5" width="100%" height="23" />
        </ContentLoader>
      </StyledFlexContainer>
      <HeaderCardDivider />
      <StyledFlexContainer>
        <HeaderCardTimeRangeText>1-day</HeaderCardTimeRangeText>
        <ContentLoader>
          <rect x="0" y="4" rx="5" ry="5" width="100%" height="23" />
        </ContentLoader>
      </StyledFlexContainer>
    </FlexContainer>
  </HeaderCardRoot>
);

const InvestmentHoldingsBenchmarksHeaderLoading = () => {
  const theme = useTheme();

  return (
    <>
      <InvestmentsHeaderCardLoading
        title="Your Portfolio"
        highlightColor={theme.color.orange}
        rightMargin={false}
      />
      <BenchmarkDivider />
      <InvestmentsHeaderCardLoading highlightColor={theme.color.teal} rightMargin />
      <InvestmentsHeaderCardLoading highlightColor={null} rightMargin />
      <InvestmentsHeaderCardLoading highlightColor={null} rightMargin={false} />
    </>
  );
};
export default InvestmentHoldingsBenchmarksHeaderLoading;
