import React from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import RouteLink from 'components/lib/ui/link/RouteLink';

import boxShadow from 'common/lib/styles/boxShadow';

const Root = styled(FlexContainer)<{ $clickable: boolean }>`
  border-radius: ${({ theme }) => theme.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  ${boxShadow.small}
  background: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.spacing.small};

  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
      transition: ${({ theme }) => theme.transition.default};

      :hover {
        ${boxShadow.medium}
      }
    `}
`;

type Props = {
  /** Turns entire card into a link. */
  linkTo?: string;
  children?: React.ReactNode;
  className?: string;
};

/** Interactive message bubble. */
const MessageCard = ({ linkTo, children, className }: Props) => {
  const MaybeLink = ({ children }: { children: React.ReactNode }) =>
    linkTo ? (
      <RouteLink to={linkTo} unstyled>
        {children}
      </RouteLink>
    ) : (
      <>{children}</>
    );

  return (
    <MaybeLink>
      <Root $clickable={!!linkTo} className={className}>
        {children}
      </Root>
    </MaybeLink>
  );
};

export default MessageCard;
