/**
 * Returns url of the first markdown style link
 *
 * @param content Text content to search
 * @returns First link found, if any
 *
 * @example
 * // returns http://google.com
 * findMarkdownLink('[some link](http://google.com)')
 */
export const findMarkdownLink = (content: string) => {
  const match = content?.match(/\[.*\]\(.*\)/);
  const matchedString = match?.[0];

  if (matchedString) {
    const url = matchedString.substring(
      matchedString.lastIndexOf('(') + 1,
      matchedString.lastIndexOf(')'),
    );
    return url;
  }
};
