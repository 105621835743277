import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import SingleCardPage from 'components/lib/layouts/SingleCardPage';
import Banner from 'components/lib/ui/Banner';

import { useDispatch } from 'lib/hooks';
import { resetPasswordAction } from 'state/user/thunks';

import routes from 'constants/routes';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.greenDark};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  &:hover {
    color: ${({ theme }) => theme.color.greenDark};
    text-decoration: underline;
  }
`;

const CreatePassword = () => {
  const dispatch = useDispatch();
  const { token = '' } = useParams<{ token: string | undefined }>();
  const [formSuccessful, setFormSuccessful] = useState<boolean>(false);

  return (
    <SingleCardPage
      name="Create Password"
      displayTitle="Create Password"
      description="Create a new password for your Monarch account by filling out the form below."
    >
      <Form
        initialValues={{ new_password: '' }}
        onSubmit={async (values) => {
          await dispatch(resetPasswordAction({ ...values, token }));
          setFormSuccessful(true);
        }}
      >
        {formSuccessful ? (
          <Banner type="success">
            Your password has been created.{' '}
            <StyledLink to={routes.dashboard()}>Click here</StyledLink> to continue to the
            Dashboard.
          </Banner>
        ) : (
          <>
            <TextField name="new_password" label="New Password" password required />
            <FormSubmitButton>Create Password</FormSubmitButton>
          </>
        )}
      </Form>
    </SingleCardPage>
  );
};

export default CreatePassword;
