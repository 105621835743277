import pluralize from 'pluralize';
import * as React from 'react';
import styled from 'styled-components';

import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DangerButton from 'components/lib/ui/button/DangerButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { useModalContext } from 'lib/contexts/ModalContext';

import type { GetHouseholdTransactionTags_householdTransactionTags as Tag } from 'common/generated/graphQlTypes/GetHouseholdTransactionTags';

const Content = styled(CardBody)`
  padding-top: 0;
`;

const TextWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.default} 0;
`;

type Props = {
  tag: Tag;
  onConfirm: (tagId: string) => void;
};

const DeleteTagModal = ({ tag, onConfirm }: Props) => {
  const { close } = useModalContext();

  return (
    <ModalCard title="Are you sure?">
      <Content>
        <TextWrapper>
          <Text>
            Deleting this tag will remove it from all {tag.transactionCount} historical{' '}
            {pluralize('transaction', tag.transactionCount)}.
          </Text>
        </TextWrapper>
      </Content>
      <CardFooter>
        <DefaultButton onClick={close}>Cancel</DefaultButton>
        <DangerButton
          onClick={() => {
            onConfirm(tag.id);
            close();
          }}
        >
          Yes, I&apos;m sure
        </DangerButton>
      </CardFooter>
    </ModalCard>
  );
};

export default DeleteTagModal;
