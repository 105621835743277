import React from 'react';
import styled from 'styled-components';

import CardFooter from 'components/lib/ui/CardFooter';
import Link from 'components/lib/ui/Link';
import ModalCard from 'components/lib/ui/ModalCard';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { useModalContext } from 'lib/contexts/ModalContext';

import { MINT_TRANSACTIONS_URL } from 'common/constants/externalUrls';

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const List = styled.ol`
  padding-left: ${({ theme }) => theme.spacing.xlarge};
`;

const ListItem = styled.li`
  margin: ${({ theme }) => theme.spacing.default} 0;
`;

const ImageContainer = styled.div`
  border-radius: ${({ theme }) => theme.radius.medium};
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
`;

const ImportMintHelpModal = () => {
  const { close } = useModalContext();

  return (
    <ModalCard title="How to export transactions from Mint">
      <CardBody>
        Use the steps below to export all of your transactions from Mint:
        <List>
          <ListItem>
            Sign in to your Mint account and go to the{' '}
            <Link href={MINT_TRANSACTIONS_URL} target="_blank">
              Transactions
            </Link>{' '}
            page.
          </ListItem>
          <ListItem>Scroll to the bottom and click the Export transactions link. </ListItem>
          <ListItem>A CSV file will download. Upload that file into Monarch.</ListItem>
        </List>
        <ImageContainer>
          <Image src="https://monarch-static-assets.s3.amazonaws.com/other/mint-export-2023-11-08.png" />
        </ImageContainer>
        <VideoContainer>
          <iframe
            id="mint-export-video"
            title="mint-export-video"
            width="100%"
            height="400"
            src="http://www.youtube.com/embed/ZuLz-CYmo5w?enablejsapi=1&origin=http://example.com"
            frameBorder="0"
          />
        </VideoContainer>
      </CardBody>
      <CardFooter>
        <PrimaryButton onClick={close}>Okay</PrimaryButton>
      </CardFooter>
    </ModalCard>
  );
};

export default ImportMintHelpModal;
