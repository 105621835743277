import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import Currency from 'components/lib/ui/currency/Currency';

import { gql } from 'common/generated/gql';
import type { OriginalTransactionFields } from 'common/generated/graphQlTypes/OriginalTransactionFields';

const Root = styled(Banner)`
  border: 1px solid ${({ theme }) => theme.color.blueLight};
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const LargeIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: ${({ theme }) => theme.spacing.xlarge};
  flex-shrink: 0;
`;

const Message = styled.div`
  margin-right: ${({ theme }) => theme.spacing.xxxlarge};
`;

const Button = styled(DefaultButton)`
  flex-shrink: 0;
`;

const Medium = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const MediumCurrency = styled(Currency)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

type Props = {
  originalTransaction: OriginalTransactionFields;
  onClickOpenSplits: () => void;
  className?: string;
};

const SplitTransactionBanner = ({
  originalTransaction: {
    merchant: { name },
    date,
    amount,
  },
  onClickOpenSplits,
  className,
}: Props) => (
  <Root type="info" className={className}>
    <FlexContainer alignCenter justifyBetween>
      <FlexContainer alignCenter>
        <LargeIcon name="split" />
        <Message>
          This transaction is a split, the original transaction on the account statement was from{' '}
          <Medium>{name}</Medium> on{' '}
          <Medium>{DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)}</Medium> with a total
          of <MediumCurrency value={Math.abs(amount)} />.
        </Message>
      </FlexContainer>
      <Button onClick={onClickOpenSplits}>Open splits</Button>
    </FlexContainer>
  </Root>
);

SplitTransactionBanner.fragments = {
  originalTransactionFields: gql(`
    fragment OriginalTransactionFields on Transaction {
      id
      date
      amount
      merchant {
        id
        name
      }
    }
  `),
};

export default SplitTransactionBanner;
