const LOAN_NO_TXN_COPY = {
  title: '',
  subtitle:
    'Sorry, we do not support individual transactions on some loan accounts. Your loan balances should be accurate, however.',
};
const INVESTMENT_NO_TXN_COPY = {
  title: '',
  subtitle:
    'Sorry, we do not support individual transactions on investment accounts yet. We hope to add this soon. Your investment balances should be accurate, however.',
};
const NO_TXN_COPY = {
  title: 'No transactions yet.',
  subtitle: "We couldn't find any transactions for this account.",
};

export const getNoTransactionCopy = (
  isSynced: boolean,
  isInvestments: boolean,
  isLoan: boolean,
  isDefaultFiltersApplied: boolean,
  searchFilter?: string | null,
) => {
  // This message may not show, since we'd show holdings for investment accounts.
  // Keeping it here for now, in case we decide to change that.
  if (isInvestments && isSynced) {
    return INVESTMENT_NO_TXN_COPY;
  }

  if (isLoan && isSynced) {
    return LOAN_NO_TXN_COPY;
  }

  if (isDefaultFiltersApplied) {
    return NO_TXN_COPY;
  }

  return {
    title: 'No transactions found.',
    subtitle: searchFilter
      ? `We couldn't find any transactions matching your search of "${searchFilter}".`
      : "We couldn't find any transactions matching your filters.",
  };
};
