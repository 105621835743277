import React, { useEffect } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import {
  logFinicityConnectComplete,
  logFinicityConnectErrored,
  logFinicityConnectEvent,
  logFinicityConnectOpened,
} from 'actions';
import { isConnectivityIssue } from 'common/lib/finicity';
import useToggle from 'common/lib/hooks/useToggle';
import useDispatch from 'lib/hooks/useDispatch';
import useScript from 'lib/hooks/useScript';

import type { ConnectEvent, ConnectRouteEvent } from 'common/types/Finicity';

const CONTAINER_ID = 'finicity-connect-container';
const CONNECT_SCRIPT_URL = 'https://connect2.finicity.com/assets/sdk/finicity-connect.min.js';
const HEIGHT_PX = 700;
const TIMEOUT_ERROR_REASON = 'timeout'; // https://docs.finicity.com/web-sdk-2-0-events/
const NO_ACTION_REQUIRED_ERROR_CODE = 201; // In Fix mode everything is already good

const StyledCard = styled(Card)`
  overflow: hidden;
  height: ${HEIGHT_PX}px;
  background: #f9fafc;
`;

const ContainerRoot = styled.div<{ isLoading: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};

  #finicityConnectIframe {
    width: 100%;
    height: 100%;
  }
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  height: 100%;
`;

const ErrorContainer = styled(FlexContainer).attrs({ center: true, column: true })`
  flex: 1;
`;

export type InstitutionFinicityConnect = {
  name?: string;
  id?: string;
};

type Props = {
  connectUrl?: string;
  onClose: (hasConnectivityIssue?: boolean) => void;
  onSuccess: () => void;
  institution?: InstitutionFinicityConnect;
};

const FinicityConnect = ({ institution, connectUrl, onClose, onSuccess }: Props) => {
  const dispatch = useDispatch();
  const [connectDidError, { setOn: setDidError }] = useToggle(false);
  const [scriptLoading, scriptError] = useScript(CONNECT_SCRIPT_URL);
  const [isLoading, { setOff: setDoneLoading }] = useToggle(true);
  const showLoading = isLoading || scriptLoading;

  const openConnect = () => {
    let hasConnectivityIssue = false;

    // @ts-ignore
    window.finicityConnect.launch(connectUrl, {
      selector: `#${CONTAINER_ID}`,
      success: () => {
        onSuccess();
        dispatch(logFinicityConnectComplete());
      },
      cancel: () => {
        onClose(hasConnectivityIssue);
      },
      error: (error: ConnectEvent) => {
        if (error.reason === TIMEOUT_ERROR_REASON) {
          hasConnectivityIssue = true;
          onClose(hasConnectivityIssue);
        } else if (error.code === NO_ACTION_REQUIRED_ERROR_CODE) {
          onSuccess();
        } else {
          setDidError();
          dispatch(
            logFinicityConnectErrored({
              ...error,
              institution_name: institution?.name,
              institution_id: institution?.id,
            }),
          );
        }
      },
      loaded: () => dispatch(logFinicityConnectOpened()),
      route: (event: ConnectRouteEvent) => {
        setDoneLoading();
        dispatch(
          logFinicityConnectEvent({
            type: event.type,
            ...event.data,
          }),
        );
      },
      user: (event: ConnectRouteEvent) => {
        if (!!event && isConnectivityIssue(event)) {
          hasConnectivityIssue = true;
        }
        dispatch(
          logFinicityConnectEvent({
            type: event.type,
            ...event.data,
          }),
        );
      },
    });
  };

  useEffect(() => {
    if (scriptLoading || !connectUrl) {
      return;
    }

    try {
      openConnect();
    } catch (e) {
      // @ts-ignore
      window.finicityConnect.destroy();
      openConnect();
    }
  }, [scriptLoading, connectUrl]);

  return (
    <StyledCard>
      <Switch>
        <Case when={connectDidError || scriptError}>
          <ErrorContainer>
            <p>Sorry, something went wrong. Please try again.</p>
            <DefaultButton size="medium" onClick={() => onClose()}>
              Close
            </DefaultButton>
          </ErrorContainer>
        </Case>
        <Case default>
          <>
            {showLoading && (
              <LoadingContainer>
                <LoadingSpinner />
              </LoadingContainer>
            )}
            <ContainerRoot id={CONTAINER_ID} isLoading={showLoading} />
          </>
        </Case>
      </Switch>
    </StyledCard>
  );
};

export default FinicityConnect;
