import * as R from 'ramda';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFilters } from 'actions';
import useToggle from 'common/lib/hooks/useToggle';
import { useUpdatableQueryParams } from 'lib/hooks/useQueryParams';
import { INITIAL_FILTERS_STATE } from 'state/filters/reducer';
import { getFilterForKey } from 'state/filters/selectors';
import type { FilterState } from 'state/filters/types';

type Options = {
  /** Whether to use query params for this filter or not */
  useParams?: boolean;
};

const usePersistentFilter = <TKey extends keyof FilterState>(key: TKey, options?: Options) => {
  const dispatch = useDispatch();
  const [queryParams, updateQueryParams] = useUpdatableQueryParams();

  const initialFilters = INITIAL_FILTERS_STATE[key];
  const { useParams = true } = options ?? {};

  // We need this so that we only change query params if the filter changes after mounting
  const [hasSetInitialValues, { setOn: lockInitialValues }] = useToggle(false);

  const filterValue = useSelector(getFilterForKey)(key);

  const activeFilters = useMemo(() => {
    if (isFilterValue<TKey>(filterValue)) {
      return filterValue;
    } else {
      throw new Error(`Filter value for key "${key}" is not a valid filter.`);
    }
  }, [filterValue, key]);

  const updateFilter = useCallback(
    (filters: Partial<FilterState[TKey]>) => {
      const result = filters !== null ? { ...activeFilters, ...filters } : null;

      if (filters === result) {
        return;
      }

      dispatch(setFilters({ key, filters: result }));

      if (useParams) {
        updateQueryParams(result);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFilters, updateQueryParams],
  );

  const resetFilter = useCallback(
    () => updateFilter(initialFilters),
    [updateFilter, initialFilters],
  );

  // Check whether current query params are different than filters
  const areParamsDifferent = !R.equals(queryParams ?? {}, R.reject(R.isNil, activeFilters) ?? {});

  useEffect(() => {
    // On mount, different query params should take precedence
    if (useParams && areParamsDifferent && isFilterValue<TKey>(queryParams)) {
      updateFilter(queryParams);
      lockInitialValues();
    }
  }, []);

  useEffect(
    () => {
      if (useParams && hasSetInitialValues && areParamsDifferent) {
        updateQueryParams(activeFilters);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFilters, hasSetInitialValues, areParamsDifferent],
  );

  const hasFilterChanged = useMemo(
    () => !R.equals(activeFilters, initialFilters),
    [activeFilters, initialFilters],
  );

  return {
    activeFilters,
    hasFilterChanged,
    updateFilter,
    resetFilter,
  };
};

const isFilterValue = <TKey extends keyof FilterState>(value: any): value is FilterState[TKey] =>
  value !== null && typeof value === 'object';
export default usePersistentFilter;
