import React from 'react';
import styled from 'styled-components';

import MessageCard from 'components/assistant/MessageCard';
import GoalDashboardRow from 'components/goalsV2/GoalDashboardRow';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import routes from 'constants/routes';

import type { Web_GoalDashboardRowFields } from 'common/generated/graphQlTypes/Web_GoalDashboardRowFields';

const Root = styled(MessageCard)`
  width: 50%;
  padding-top: 0;
  padding-bottom: 0;

  @media (max-width: ${({ theme }) => theme.breakPoints.lg}px) {
    width: 100%;
  }
`;

const StyledGoalDashboardRow = styled(GoalDashboardRow)`
  padding-left: 0;
  padding-right: 0;
  border-bottom: none;
  width: 100%;
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  height: 129px;
  flex: 1;
`;

const SmallLoadingSpinner = styled(LoadingSpinner)`
  width: 20px;
  height: 20px;
`;

type Props = {
  goal?: Web_GoalDashboardRowFields;
  loading?: boolean;
};

const GoalMessageBlock = ({ goal, loading }: Props) => (
  <Root linkTo={goal ? routes.goalsV2.goalDetail({ id: goal.id }) : undefined}>
    {loading || !goal ? (
      <LoadingContainer>
        <SmallLoadingSpinner />
      </LoadingContainer>
    ) : (
      <StyledGoalDashboardRow goal={goal} />
    )}
  </Root>
);

export default GoalMessageBlock;
