import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import CurrencyField from 'components/lib/form/CurrencyField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCancelButton from 'components/lib/ui/ModalCancelButton';
import Text from 'components/lib/ui/Text';

import { isInvestmentAccount, isManualHoldingsAccount } from 'common/lib/accounts/accountTypes';
import { GET_ACCOUNT_TYPE_OPTIONS } from 'common/lib/graphQl/accounts';
import {
  getDisplayOptionsFromAccountTypeOptions,
  getDisplayForType,
} from 'lib/accounts/ManualAccounts';

import {
  ManualInvestmentAccountTrackingMode,
  MANUAL_INVESTMENT_ACCOUNT_TRACK_OPTIONS,
} from 'common/constants/accounts';
import type { ManualInvestmentAccountTrackingModeType } from 'common/constants/accounts';

import type { GetAccountTypeOptions } from 'common/generated/graphQlTypes/GetAccountTypeOptions';

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: ${({ theme }) => theme.spacing.xsmall};
`;

const Title = styled(Text)`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

export type FormValues = {
  name: string;
  displayBalance: number;
  type: string;
  subtype: string;
  investmentTrackingMode?: ManualInvestmentAccountTrackingModeType;
  includeInNetWorth: boolean;
};

type Props = {
  type: string;
  subtype?: string | null;
  initialValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => Promise<void>;
  disableCurrentBalance?: boolean;
};

const AddManualAccountForm = ({
  type,
  subtype,
  initialValues,
  disableCurrentBalance,
  onSubmit,
}: Props) => {
  const { data } = useQuery<GetAccountTypeOptions>(GET_ACCOUNT_TYPE_OPTIONS);

  const { accountTypeOptions = [] } = data ?? {};
  const optionsForType = getDisplayOptionsFromAccountTypeOptions(type, accountTypeOptions);
  const displayName = getDisplayForType(type, accountTypeOptions);

  const formInitialValues = useMemo(
    () => ({
      ...initialValues,
      investmentTrackingMode: ManualInvestmentAccountTrackingMode.BALANCES,
      subtype: initialValues?.subtype ?? R.head(optionsForType)?.value,
    }),
    [initialValues, optionsForType],
  );

  const handleSubmit = (values: FormValues) => {
    onSubmit(values);
  };

  return (
    <Form initialValues={formInitialValues} onSubmit={handleSubmit}>
      <FormContext.Consumer>
        {({ values }) => {
          const isManualHoldingAccount = isManualHoldingsAccount(
            values.type,
            values.investmentTrackingMode,
          );
          const shouldDisplayTrackModeField = isInvestmentAccount(values.type);
          const shouldDisplayCurrentBalanceField = !isManualHoldingAccount;

          return (
            <>
              <Root>
                <Title>Add {displayName} Account</Title>
                <TextField name="name" placeholder={`My ${displayName} Account`} required />
                {!subtype && <SelectField name="subtype" label="Type" options={optionsForType} />}
                {shouldDisplayTrackModeField && (
                  <SelectField
                    name="investmentTrackingMode"
                    label="Track"
                    options={MANUAL_INVESTMENT_ACCOUNT_TRACK_OPTIONS}
                  />
                )}
                {shouldDisplayCurrentBalanceField && (
                  <CurrencyField
                    placeholder="$0.00"
                    name="displayBalance"
                    label="Balance"
                    maskOptions={{ allowDecimal: true }}
                    disabled={disableCurrentBalance}
                    required
                  />
                )}
              </Root>
              <CardFooter>
                <ModalCancelButton />
                <FormSubmitButton size="small" disableWhenValuesUnchanged={false}>
                  {isManualHoldingAccount ? 'Next' : 'Save'}
                </FormSubmitButton>
              </CardFooter>
            </>
          );
        }}
      </FormContext.Consumer>
    </Form>
  );
};

export default AddManualAccountForm;
