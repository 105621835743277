import * as R from 'ramda';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import RecoveryCodeLogin from 'components/routes/login/RecoveryCodeLogin';
import TotpLogin from 'components/routes/login/TotpLogin';
import UserNamePassword from 'components/routes/login/UsernamePassword';

import { useDispatch } from 'lib/hooks';
import { useParamState } from 'lib/hooks/useParamState';
import { requestLogin } from 'state/user/thunks';

export type LoginParams = {
  username: string;
  password: string;
  trusted_device: boolean;
  totp?: string;
  captcha_token?: string;
  recovery_code?: string;
};

type Params = {
  mfaStage?: string;
  captcha?: boolean;
};

const Login = () => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  // We keep this state here since it's a little sensitive, and we don't want to store it in redux.
  // We need to keep the password after the initial submission so we can resubmit it with the code
  const [loginInfo, setLoginInfo] = useState<LoginParams | undefined>();
  const [params, setParams] = useParamState<Params>(
    R.merge({
      mfaStage: undefined,
    }),
  );

  const submitLogin = async (values: LoginParams) => {
    let submitValues = values;
    if (params.captcha) {
      let token = '';
      if (!executeRecaptcha) {
        return;
      }

      token = await executeRecaptcha();

      submitValues = { ...values, captcha_token: token };
    }

    const result = await dispatch(requestLogin(submitValues));

    if (result === 'MFA_REQUIRED') {
      const { username, password, trusted_device } = values;
      setLoginInfo({ username, password, trusted_device });
      setParams({ mfaStage: 'totp' });
    }

    if (result === 'CAPTCHA_REQUIRED') {
      const { username, password, trusted_device } = values;
      setLoginInfo({ username, password, trusted_device });

      if (!params.captcha) {
        setParams({ captcha: true });
      }
    }
  };

  if (params.mfaStage === 'totp' && loginInfo) {
    return (
      <TotpLogin
        goToRecoveryCodeScreen={() => setParams({ mfaStage: 'recovery_code' })}
        onSubmit={({ totp }) => submitLogin({ totp, ...loginInfo })}
      />
    );
  } else if (params.mfaStage === 'recovery_code' && loginInfo) {
    return (
      <RecoveryCodeLogin
        email={loginInfo.username}
        onSubmit={({ recovery_code }) => submitLogin({ recovery_code, ...loginInfo })}
      />
    );
  } else {
    return <UserNamePassword onSubmit={submitLogin} />;
  }
};

export default Login;
