import { useQuery } from '@apollo/client';
import type { PaymentMethod } from '@stripe/stripe-js';
import * as RA from 'ramda-adjunct';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Card from 'components/lib/ui/ModalCard';
import { SUBSCRIPTION_MODAL_QUERY } from 'components/settings/billing/SubscriptionModal';
import SubscriptionModalAnnualUpsellBody from 'components/settings/billing/SubscriptionModalAnnualUpsellBody';

import useToggle from 'common/lib/hooks/useToggle';
import { useQueryParam } from 'lib/hooks';
import { getTemporaryPromoCode } from 'state/onboarding/selectors';

export type Props = {
  changeSubscription: (stripePriceId: string, stripePromoCode: string | null) => Promise<void>;
  createSubscription: (
    paymentMethod: PaymentMethod | null,
    stripePriceId: string,
    stripePromoCode: string | null,
  ) => Promise<void>;
  reactivateSubscription: (
    paymentMethod: PaymentMethod | null,
    stripePriceId: string,
    stripePromoCode: string | null,
  ) => Promise<void>;
  onDone: () => void;
  title?: string;
};

const SubscriptionModalAnnualUpsell = ({
  onDone,
  changeSubscription,
  createSubscription,
  reactivateSubscription,
  title,
}: Props) => {
  const temporaryPromoCode = useSelector(getTemporaryPromoCode);
  const [appliedPromoCode, setAppliedPromoCode] = useState<string | null>(null);
  const initialPromoCode = useQueryParam('promo-code');

  const [isLoading, { setOn: setLoading, setOff: setNotLoading }] = useToggle(false);

  const { data } = useQuery(SUBSCRIPTION_MODAL_QUERY, {
    variables: { promoCode: appliedPromoCode },
  });

  const { paymentMethod } = data?.subscription ?? {};

  const hasPaymentMethod = RA.isNotNil(paymentMethod);

  useEffect(() => {
    if (initialPromoCode || temporaryPromoCode) {
      setAppliedPromoCode(initialPromoCode || (temporaryPromoCode ?? null));
    }
  }, [initialPromoCode]);

  return (
    <Card title={title} hideBottomBorder>
      {data
        ? (() => {
            const {
              subscription: {
                paymentSource,
                paymentMethod,
                hasStripeSubscriptionId,
                hasPremiumEntitlement,
                willCancelAtPeriodEnd,
              },
              subscriptionOffering: {
                plans,
                promoCodeDescription,
                promoCodeDuration,
                promoCodeDurationInMonths,
              },
            } = data;

            return (
              <SubscriptionModalAnnualUpsellBody
                hasPremiumEntitlement={hasPremiumEntitlement}
                willCancelAtPeriodEnd={willCancelAtPeriodEnd}
                plans={plans}
                createSubscription={createSubscription}
                reactivateSubscription={reactivateSubscription}
                promoCodeDescription={promoCodeDescription}
                promoCodeDuration={promoCodeDuration}
                promoCodeDurationInMonths={promoCodeDurationInMonths}
                hasPaymentMethod={hasPaymentMethod}
                paymentMethod={paymentMethod}
                paymentSource={paymentSource}
                isLoading={isLoading}
                setLoading={setLoading}
                setNotLoading={setNotLoading}
                appliedPromoCode={appliedPromoCode}
                changeSubscription={changeSubscription}
                hasStripeSubscriptionId={hasStripeSubscriptionId}
                onDone={onDone}
              />
            );
          })()
        : null}
    </Card>
  );
};

export default SubscriptionModalAnnualUpsell;
