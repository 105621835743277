import _ from 'lodash';
import React, {
  useState,
  useRef,
  useLayoutEffect,
  useImperativeHandle,
  useMemo,
  useCallback,
} from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes, css } from 'styled-components';

import Shield from 'components/lib/ui/Shield';

import noop from 'common/utils/noop';
import ModalContext from 'lib/contexts/ModalContext';
import useEventListener from 'lib/hooks/useEventListener';

export interface Props {
  onClose?: () => void;
  large?: boolean;
  children?: React.ReactNode | ((handle: { close: () => void }) => React.ReactNode);
  nonDismissable?: boolean;
  /** This allows the modal container to take up the full width, so it's possible to control the exact modal width in child components. */
  full?: boolean;
}

export type ModalHandles = {
  close: () => void;
};

const FADE_MS = 200;

const FADE_IN = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const FADE_OUT = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const Root = styled.div<{ fadingOut?: boolean }>`
  animation: ${FADE_MS}ms ease-out ${({ fadingOut }) => (fadingOut ? FADE_OUT : FADE_IN)};
  opacity: ${({ fadingOut }) => (fadingOut ? 0 : 1)};
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${({ theme }) => theme.spacing.default};
  overflow: auto;
`;

const InnerModal = styled.div<{ $large?: boolean; $full?: boolean }>`
  position: relative;
  margin: auto;
  margin-top: ${({ $large }) => ($large ? 'auto' : '100px')};
  width: 100%;

  ${({ $full, $large }) =>
    $full
      ? css`
          width: 100%;
          max-width: 100%;
        `
      : css`
          max-height: ${$large ? '80%' : 'auto'};
          max-width: ${$large ? '80%' : '540px'};
        `}
`;

const Modal: React.ForwardRefRenderFunction<ModalHandles, Props> = (
  { onClose, large, children, nonDismissable = false, full },
  parentRef,
) => {
  const [render, setRender] = useState(false);
  const [fadingOut, setFadingOut] = useState(false);
  const close = useCallback(() => setFadingOut(true), [setFadingOut]);
  const dismissable = !nonDismissable;
  useLayoutEffect(() => setRender(true), []);

  useImperativeHandle(parentRef, () => ({
    close,
  }));

  const ref = useRef<HTMLDivElement>(null);

  // This fixes a weird bug where Modals stop scrolling on FF
  // https://monarchmoney.atlassian.net/browse/ENG-1259
  // eslint-disable-next-line
  useEventListener(document, 'wheel', () => {});

  // TODO: handle ESC key in a better way. Focusing the modal div can cause an inner form to lose focus
  useLayoutEffect(() => {
    ref.current?.focus();
  }, [render]);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (dismissable && e.key === 'Escape') {
      e.stopPropagation();
      close();
    }
  };

  const context = useMemo(
    () => ({
      close,
      isDismissable: dismissable,
    }),
    [close, dismissable],
  );

  if (!render) {
    return null;
  }

  return createPortal(
    <Root
      fadingOut={fadingOut}
      ref={ref}
      tabIndex={-1}
      onKeyDown={onKeyDown}
      onAnimationEnd={() => fadingOut && onClose?.()}
    >
      <Shield onClick={() => (dismissable ? close() : noop())} />

      <InnerModal $large={large} $full={full}>
        <ModalContext.Provider value={context}>
          {_.isFunction(children) ? children({ close }) : children}
        </ModalContext.Provider>
      </InnerModal>
    </Root>,
    document.body,
  );
};
export default React.forwardRef(Modal);
