import * as R from 'ramda';
import { useHistory } from 'react-router-dom';

import { useArrayQueryParam } from 'lib/hooks/useQueryParams';
import useRouteMatch from 'lib/hooks/useRouteMatch';

import routes from 'constants/routes';

const PREVIOUS_QUERY_PARAM = 'previous';

const useAdviceDrawerRoutes = ({ itemId, taskId }: { itemId: string; taskId?: string }) => {
  const { push } = useHistory();
  const { params } = useRouteMatch(routes.advice) ?? {};
  const { category } = params ?? {};
  const previous = useArrayQueryParam(PREVIOUS_QUERY_PARAM);
  const previousItemId = previous?.[0];
  const isOnTaskDetails = !!taskId;
  const isOnItemDetails = !taskId;

  const navigateToAdviceItem = (id: string) => () =>
    push(
      routes.advice.itemDetails({
        itemId: id,
        category,
        queryParams: { [PREVIOUS_QUERY_PARAM]: [itemId, ...(previous ?? [])] },
      }),
    );

  const navigateToAdviceTask = (id: string) => () =>
    push(
      routes.advice.taskDetails({
        itemId,
        taskId: id,
        category,
        queryParams: { [PREVIOUS_QUERY_PARAM]: previous },
      }),
    );

  const goBack = () => {
    if (previousItemId && isOnItemDetails) {
      push(
        routes.advice.itemDetails({
          itemId: previousItemId,
          category,
          queryParams: {
            [PREVIOUS_QUERY_PARAM]: R.drop(1, previous ?? []),
          },
        }),
      );
    } else if (isOnTaskDetails) {
      push(
        routes.advice.itemDetails({
          itemId,
          category,
          queryParams: { [PREVIOUS_QUERY_PARAM]: previous },
        }),
      );
    }
  };

  const onClose = () => push(routes.advice({ category }));

  return {
    previousItemId,
    navigateToAdviceItem,
    navigateToAdviceTask,
    goBack,
    onClose,
  };
};

export default useAdviceDrawerRoutes;
