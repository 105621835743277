import { useDispatch } from 'react-redux';

import { login } from 'common/actions';
import type Api from 'common/lib/api';
import getUserApi from 'common/lib/api/user';
import useLoading from 'common/lib/hooks/useLoading';

import type { AnalyticsEvent } from 'common/constants/analytics';
import { AnalyticsEventNames } from 'common/constants/analytics';

type Props = {
  api: Api;
  track: (event: AnalyticsEvent) => void;
  onComplete?: () => void;
  onError?: (error: unknown) => void;
};

const useCreateTemporaryDemoAccount = ({ api, track, onComplete, onError }: Props) => {
  const dispatch = useDispatch();

  const [isCreatingDemoAccount, createDemoAccount] = useLoading(async () => {
    try {
      const { user } = await getUserApi(api).createTemporaryDemoAccount();
      track(AnalyticsEventNames.DemoStarted);
      dispatch(login(user));
      onComplete?.();
    } catch (e) {
      onError?.(e);
    }
  });

  return [createDemoAccount, isCreatingDemoAccount] as const;
};

export default useCreateTemporaryDemoAccount;
