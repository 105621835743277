import type { Placement, Modifier } from '@popperjs/core';
import { motion, AnimatePresence } from 'framer-motion';
import _ from 'lodash';
import React, { useState, useCallback, useMemo, useContext } from 'react';
import ReactDOM from 'react-dom';

import type { OverlayContextType } from 'components/lib/ui/popover/OverlayContext';
import OverlayContext from 'components/lib/ui/popover/OverlayContext';
import type { PopperProps } from 'components/lib/ui/popover/Popper';
import Popper from 'components/lib/ui/popover/Popper';

import useCurrentReference from 'common/lib/hooks/useCurrentReference';
import useKey from 'lib/hooks/useKey';
import useOnClickOutside from 'lib/hooks/useOnClickOutside';

import { DEFAULT_MOTION_TRANSITION_FAST, POPOVER_ANIMATION_VARIANTS } from 'constants/animation';

import type { Color } from 'types/Styles';

class RefHolder extends React.Component<{ children: React.ReactNode }> {
  render() {
    return this.props.children;
  }
}

export type Props<T> = {
  children: React.ReactNode | ((context: OverlayContextType<T>) => React.ReactNode);
  /** Content displayed inside the popover */
  overlay: React.ReactNode | ((context: OverlayContextType<T>) => React.ReactNode);
  /** Displaces the popper away from, or toward, the reference element in the direction of
   * its placement. A positive number displaces it further away, while a negative number lets it overlap the reference.
   * https://popper.js.org/docs/v2/modifiers/offset/#distance-1
   */
  offsetDistance?: number;
  /** Displaces the popper along the reference element https://popper.js.org/docs/v2/modifiers/offset/#skidding-1 */
  offsetSkidding?: number;
  /** Close this popover when the escape key is pressed. Defaults to true. */
  closeOnEscape?: boolean;
  showArrow?: boolean;
  arrowColor?: Color;
  initiallyOpen?: boolean;
  disableClickOutside?: boolean;
  onClose?: () => void;
} & Pick<PopperProps, 'placement' | 'offset'>;

/**
 * General purpose component for displaying popovers attached to a reference element.
 *
 * <Popover content="This is in a popover!">
 *   {({ open }) => <button onClick={open}>Toggle Popover</button>}
 * </Popover>
 */
const OverlayTrigger = <T = any,>({
  overlay,
  children,
  closeOnEscape = true,
  placement,
  showArrow,
  arrowColor = 'white',
  offsetDistance = showArrow ? 10 : 8,
  offsetSkidding = 0,
  initiallyOpen = false,
  disableClickOutside = false,
  onClose,
}: Props<T>) => {
  // useState instead of useRef here because of https://github.com/popperjs/react-popper/issues/241#issuecomment-591411605
  const [anchorElement, setAnchorElement] = React.useState<RefHolder | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
  const [placementState, setPlacementState] = useState<Placement | undefined>(placement);
  const placementStateRef = useCurrentReference(placementState);

  const [isOpen, setIsOpen] = useState(initiallyOpen);

  const anchorNode = useMemo(
    // eslint-disable-next-line react/no-find-dom-node
    () => (!isOpen ? null : (ReactDOM.findDOMNode(anchorElement) as HTMLElement | null)),
    [anchorElement, isOpen],
  );

  const modifiers: Modifier<unknown>[] = useMemo(
    () => [
      {
        name: 'animation',
        enabled: true,
        phase: 'main',
        fn: ({ state }) => {
          if (state.placement !== placementStateRef.current) {
            setPlacementState(state.placement);
          }
        },
      },
    ],
    [],
  );

  const [triggerContext, setTriggerContext] = useState<any>(undefined);
  const open = useCallback(
    (triggerContext?: any) => {
      setTriggerContext(triggerContext);
      setIsOpen(true);
    },
    [setIsOpen],
  );
  const close = useCallback(() => {
    setIsOpen(false);
    onClose?.();
  }, [setIsOpen, onClose]);
  const toggleOpen = useCallback(() => setIsOpen((open) => !open), [setIsOpen]);

  const context: OverlayContextType = useMemo(
    () => ({ open, close, toggleOpen, isOpen, triggerContext }),
    [open, close, toggleOpen, isOpen, triggerContext],
  );

  return (
    <OverlayContext.Provider value={context}>
      <RefHolder ref={setAnchorElement}>
        {_.isFunction(children) ? children(context) : children}
      </RefHolder>

      <AnimatePresence>
        {isOpen && (
          <AnimatedPopperContent
            anchorNode={anchorNode}
            popperElement={popperElement}
            setPopperElement={setPopperElement}
            placement={placementState}
            offsetSkidding={offsetSkidding}
            offsetDistance={offsetDistance}
            showArrow={showArrow}
            arrowColor={arrowColor}
            overlay={overlay}
            context={context}
            closeOnEscape={closeOnEscape}
            disableClickOutside={disableClickOutside}
            anchorElement={anchorNode}
            modifiers={modifiers}
          />
        )}
      </AnimatePresence>
    </OverlayContext.Provider>
  );
};

const AnimatedPopperContent = ({
  anchorNode,
  popperElement,
  setPopperElement,
  placement,
  offsetSkidding,
  offsetDistance,
  showArrow,
  arrowColor,
  overlay,
  context,
  closeOnEscape,
  disableClickOutside,
  modifiers,
}: any) => (
  <>
    <Popper
      anchorElement={anchorNode}
      popperElement={popperElement}
      setPopperElement={setPopperElement}
      placement={placement}
      offset={[offsetSkidding, offsetDistance]}
      showArrow={showArrow}
      arrowColor={arrowColor}
      modifiers={modifiers}
    >
      <motion.div
        key="overlay-trigger"
        variants={POPOVER_ANIMATION_VARIANTS}
        initial="exit"
        animate="enter"
        exit="exit"
        transition={DEFAULT_MOTION_TRANSITION_FAST}
        style={{ transformOrigin: placementToTransformOrigin(placement) }}
      >
        {_.isFunction(overlay) ? overlay(context) : overlay}
      </motion.div>
    </Popper>
    <OverlayTriggerCloseHandler
      closeOnEscape={closeOnEscape}
      anchorNode={anchorNode}
      popperElement={popperElement}
      disableClickOutside={disableClickOutside}
    />
  </>
);

type CloseProps = {
  closeOnEscape: boolean;
  anchorNode: HTMLElement | null;
  popperElement: HTMLElement | null;
  disableClickOutside: boolean;
};

/**
 * This has to be a separate component in order for these hooks to only get called if the overlay is open
 * (can't conditionally call hooks otherwise)
 */
const OverlayTriggerCloseHandler = ({
  closeOnEscape,
  anchorNode,
  popperElement,
  disableClickOutside,
}: CloseProps) => {
  const { close, isOpen } = useContext(OverlayContext);

  // Close when Escape key is pressed
  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOpen && closeOnEscape) {
        e.stopPropagation();
        close();
      }
    },
    [isOpen, close, closeOnEscape],
  );

  useKey('Escape', anchorNode, { onKeyDown });
  useKey('Escape', popperElement, { onKeyDown });

  const onClose = useCallback(() => {
    if (!disableClickOutside) {
      close();
    }
  }, [disableClickOutside, close]);

  // Close whenever there is a click outside of the popper and anchor, if enabled
  useOnClickOutside([popperElement, anchorNode], onClose);

  return null;
};

const placementToTransformOrigin = (placement: Placement | undefined = 'auto'): string => {
  const [vertical, horizontal] = placement.split('-');
  const verticalMap: Record<string, string> = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
  };
  const horizontalMap: Record<string, string> = { start: 'left', end: 'right' };

  const verticalOrigin = verticalMap[vertical] || 'center';
  const horizontalOrigin = horizontalMap[horizontal] || 'center';

  return `${verticalOrigin} ${horizontalOrigin}`.trim();
};

export default OverlayTrigger;
