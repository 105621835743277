import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';

import * as actions from 'common/state/accounts/actions';

const { setHasAddedAccounts, toggleAccountGroupCollapsed } = actions;

type AccountsAction = ActionType<typeof actions>;

export const accountsPersistConfig = {
  key: 'accounts',
  // adds storage when using it, since it's different between web and mobile
};

const INITIAL_STATE: AccountsState = {
  hasAddedAccounts: undefined,
  collapsedAccountGroups: {},
};

export type AccountsState = {
  hasAddedAccounts?: boolean;
  /** Indexed by AccountType.name */
  collapsedAccountGroups?: { [id: string]: boolean };
};

const accountsReducer = createReducer<AccountsState, AccountsAction>(INITIAL_STATE)
  .handleAction(setHasAddedAccounts, (state, { payload }) => ({
    ...state,
    hasAddedAccounts: payload,
  }))
  .handleAction(toggleAccountGroupCollapsed, (state, { payload: accountTypeName }) => ({
    ...state,
    collapsedAccountGroups: {
      ...state.collapsedAccountGroups,
      [accountTypeName]: !state.collapsedAccountGroups?.[accountTypeName],
    },
  }));

export default accountsReducer;
