import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';

import SettingsCard from 'components/lib/layouts/SettingsCard';
import Column from 'components/lib/ui/Column';
import DragDropList from 'components/lib/ui/DragDropList';
import Empty from 'components/lib/ui/Empty';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Modal from 'components/lib/ui/Modal';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import PremiumFeatureBlockingOverlay from 'components/premium/PremiumFeatureBlockingOverlay';
import DeleteTagModal from 'components/transactions/tags/DeleteTagModal';
import TransactionTagSettingsRow from 'components/transactions/tags/TransactionTagSettingsRow';
import UpsertTransactionTagModal from 'components/transactions/tags/UpsertTransactionTagModal';

import { getOptimisticItemOrderForMove } from 'common/lib/dnd/DragAndDrop';
import {
  DELETE_TRANSACTION_TAG,
  UPDATE_TRANSACTION_TAG_ORDER,
} from 'common/lib/graphQl/transactions';
import useFeatureEntitlement from 'common/lib/hooks/premium/useFeatureEntitlement';
import useTransactionTags from 'common/lib/hooks/transactions/useTransactionTags';
import useToggle from 'common/lib/hooks/useToggle';
import THEME from 'common/lib/theme/staticTheme';
import toast, { errorToast } from 'lib/ui/toast';

import { ProductFeature } from 'common/constants/premium';

import type {
  DeleteHouseholdTransactionTag,
  DeleteHouseholdTransactionTagVariables,
} from 'common/generated/graphQlTypes/DeleteHouseholdTransactionTag';
import type { GetHouseholdTransactionTags_householdTransactionTags as Tag } from 'common/generated/graphQlTypes/GetHouseholdTransactionTags';
import type {
  UpdateTransactionTagOrder,
  UpdateTransactionTagOrderVariables,
} from 'common/generated/graphQlTypes/UpdateTransactionTagOrder';

const ListContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const Controls = styled(FlexContainer)`
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledSettingsCard = styled(SettingsCard)<{ $disabled: boolean }>`
  position: relative;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const StyledColumn = styled(Column)`
  position: relative;
`;

const StyledPremiumFeatureBlockingOverlay = styled(PremiumFeatureBlockingOverlay)`
  margin-top: 100px;
`;

const TransactionTagSettings = () => {
  const { tags, isLoadingInitialData, refetch } = useTransactionTags({
    includeTransactionCount: true,
  });

  const [updatingTag, setUpdatingTag] = useState<Tag | undefined>(undefined);
  const [deletingTag, setDeletingTag] = useState<Tag | undefined>(undefined);
  const [isCreating, { setOn: openCreateModal, setOff: closeCreateModal }] = useToggle(false);

  const shouldOpenUpsertModal = RA.isNotNil(updatingTag) || isCreating;
  const shouldOpenDeleteModal = RA.isNotNil(deletingTag) && deletingTag.transactionCount > 0;

  const [deleteTransactionTag] = useMutation<
    DeleteHouseholdTransactionTag,
    DeleteHouseholdTransactionTagVariables
  >(DELETE_TRANSACTION_TAG);
  const [updateTagOrder] = useMutation<
    UpdateTransactionTagOrder,
    UpdateTransactionTagOrderVariables
  >(UPDATE_TRANSACTION_TAG_ORDER);

  const onDeleteTag = useCallback(
    async (tagId: string) => {
      try {
        const response = await deleteTransactionTag({ variables: { tagId } });
        const maybeError = response.data?.deleteTransactionTag?.errors;
        if (R.isNil(maybeError)) {
          refetch();
        } else {
          errorToast(maybeError.message);
        }
      } catch (error) {
        setDeletingTag(undefined);
        toast({
          title: "Sorry, we couldn't delete this tag",
          description: error as string,
        });
      }
    },
    [deleteTransactionTag, refetch],
  );

  const { hasAccess } = useFeatureEntitlement(ProductFeature.tags);

  return (
    <React.Fragment>
      <StyledColumn md={9}>
        <StyledSettingsCard
          title="Tags"
          controls={
            <Controls>
              <PrimaryButton onClick={() => openCreateModal()}>New tag</PrimaryButton>
            </Controls>
          }
          loading={isLoadingInitialData}
          $disabled={!hasAccess}
        >
          <ListContainer>
            {tags.length > 0 ? (
              <DragDropList
                items={tags}
                keyExtractor={R.prop('id')}
                indexExtractor={R.prop('order')}
                interItemSpacing={THEME.spacing.xsmall}
                onMoveItem={({ item, source, destination }) =>
                  updateTagOrder({
                    variables: { tagId: item.id, order: destination },
                    optimisticResponse: {
                      updateTransactionTagOrder: {
                        __typename: 'UpdateTransactionTagOrder',
                        householdTransactionTags: [
                          {
                            ...item,
                            order: getOptimisticItemOrderForMove(source, destination),
                          },
                        ],
                      },
                    },
                  })
                }
                renderItem={(tag, isDragging) => (
                  <TransactionTagSettingsRow
                    key={tag.id}
                    tag={tag}
                    onClickDelete={() => {
                      setDeletingTag(tag);
                      if (tag.transactionCount <= 0) {
                        onDeleteTag(tag.id);
                      }
                    }}
                    onClickEdit={() => setUpdatingTag(tag)}
                    isDragging={isDragging}
                    isDeleting={deletingTag?.id === tag.id}
                  />
                )}
              />
            ) : (
              <Empty
                title="No tags found in this household"
                subtitle="Create a tag and start assigning it to transactions."
              />
            )}
          </ListContainer>
        </StyledSettingsCard>
        {!hasAccess && <StyledPremiumFeatureBlockingOverlay feature={ProductFeature.tags} />}
      </StyledColumn>
      {shouldOpenUpsertModal && (
        <Modal
          onClose={() => {
            setUpdatingTag(undefined);
            closeCreateModal();
          }}
        >
          <UpsertTransactionTagModal tag={updatingTag} onDone={() => refetch()} />
        </Modal>
      )}
      {shouldOpenDeleteModal && deletingTag && (
        <Modal onClose={() => setDeletingTag(undefined)}>
          <DeleteTagModal tag={deletingTag} onConfirm={onDeleteTag} />
        </Modal>
      )}
    </React.Fragment>
  );
};

export default TransactionTagSettings;
