import * as R from 'ramda';

import { AUTH_PROVIDER_TO_LABEL } from 'common/constants/auth';

import type { AuthProvider } from 'common/types/auth';

export const getProviderLabels = (providerNames: string[] | undefined) =>
  R.map(
    (provider: AuthProvider) => AUTH_PROVIDER_TO_LABEL[provider],
    providerNames as AuthProvider[],
  );
