import React from 'react';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { useModalContext } from 'lib/contexts/ModalContext';

import * as COPY from 'common/constants/copy';

const ConnectionModalRoot = styled(Flex).attrs({
  column: true,
  alignCenter: true,
  justifyCenter: true,
})`
  min-height: 400px;
  padding: ${({ theme }) => theme.spacing.xxxxlarge};
  margin-top: -64px;
`;

const ConnectionTitle = styled(Text).attrs({ size: 'large', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

const ConnectionSubtitle = styled(Text).attrs({ size: 'default' })`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

const AlertIcon = styled(Icon).attrs({ name: 'alert-circle' })`
  width: 64px;
  height: 64px;
  color: ${({ theme }) => theme.color.orange};
`;

const CardFooter = styled(Flex).attrs({ justifyBetween: true })`
  padding: ${({ theme }) => theme.spacing.default};
  border-top: ${({ theme }) => `1px solid ${theme.color.grayFocus}`};
`;

const DisconnectButtonText = styled.span`
  white-space: nowrap;
  color: ${({ theme }) => theme.color.redText};
`;

export type Props = {
  isLoading?: boolean;
  hasGenericError?: boolean;
  hasFraudError?: boolean;
  next: (params?: any) => void;
};

const SpinwheelConnectionModal = ({ isLoading, hasGenericError, hasFraudError, next }: Props) => {
  const { close } = useModalContext();

  if (isLoading) {
    return (
      <ModalCard hideBottomBorder>
        <ConnectionModalRoot>
          <LoadingSpinner $size={36} />
          <ConnectionTitle>Syncing with Spinwheel...</ConnectionTitle>
        </ConnectionModalRoot>
      </ModalCard>
    );
  }

  if (!hasGenericError && !hasFraudError) {
    return null;
  }

  const title = hasFraudError
    ? COPY.RECURRING.SPINWHEEL_ERRORS.FRAUD.TITLE
    : COPY.RECURRING.SPINWHEEL_ERRORS.GENERIC.TITLE;

  const subtitle = hasFraudError
    ? COPY.RECURRING.SPINWHEEL_ERRORS.FRAUD.SUBTITLE
    : COPY.RECURRING.SPINWHEEL_ERRORS.GENERIC.SUBTITLE;

  return (
    <ModalCard hideBottomBorder>
      <ConnectionModalRoot>
        <AlertIcon />
        <ConnectionTitle>{title}</ConnectionTitle>
        <ConnectionSubtitle>{subtitle}</ConnectionSubtitle>
      </ConnectionModalRoot>

      <CardFooter>
        <DefaultButton onClick={next}>
          <DisconnectButtonText>Disconnect Spinwheel</DisconnectButtonText>
        </DefaultButton>
        <PrimaryButton onClick={close}>Try again later</PrimaryButton>
      </CardFooter>
    </ModalCard>
  );
};

export default SpinwheelConnectionModal;
