import { useMutation } from '@apollo/client';
import React, { useState, useCallback } from 'react';

import DeleteCategoryGroupConfirmation from 'components/budget/DeleteCategoryGroupConfirmation';
import Modal from 'components/lib/ui/Modal';

import { DELETE_CATEGORY_GROUP } from 'common/lib/graphQl/categoryGroups';
import logger from 'lib/logger';

import { CACHE_KEYS } from 'common/constants/cache';

type CategoryT = {
  name: string;
  icon?: React.ReactNode | string;
};

export type CategoryGroupT = {
  id: string;
  name: string;
  categories?: CategoryT[];
};

type Options = {
  onStartDeleting?: () => void;
  onFailDeleting?: () => void;
  onDeleted?: () => void;
  onDone?: () => void;
};

/**
 * A custom hook for managing the deletion of category groups.
 *
 * This hook provides functionality to:
 * - Delete a category group directly if it has no categories
 * - Show a confirmation modal if the group has categories
 * - Handle the actual deletion process, including cache updates
 * - Manage state for the confirmation modal
 * - Provide callbacks for various stages of the deletion process
 *
 */
const useDeleteCategoryGroup = (options?: Options) => {
  const { onStartDeleting, onFailDeleting, onDeleted } = options ?? {};

  const [confirmingDeleteGroup, setConfirmingDeleteGroup] = useState<CategoryGroupT | undefined>(
    undefined,
  );

  const [deleteCategoryGroup] = useMutation(DELETE_CATEGORY_GROUP, {
    update: (cache) => {
      cache.evict({ fieldName: CACHE_KEYS.CATEGORIES });
      cache.evict({ fieldName: CACHE_KEYS.CATEGORY_GROUPS });
    },
  });

  const deleteGroup = async (categoryGroupId: string, moveToGroupId?: string) => {
    onStartDeleting?.();
    const { data } = await deleteCategoryGroup({
      variables: { id: categoryGroupId, moveToGroupId },
    });
    if (data?.deleteCategoryGroup?.deleted) {
      onDeleted?.();
      setConfirmingDeleteGroup(undefined);
    } else {
      onFailDeleting?.();
      logger.error('Group did not delete.', data?.deleteCategoryGroup?.errors);
    }
  };

  const deleteOrToggleConfirmation = (categoryGroup: CategoryGroupT) => {
    if (categoryGroup.categories?.length === 0) {
      deleteGroup(categoryGroup.id);
      return;
    }

    setConfirmingDeleteGroup(categoryGroup);
  };

  const DeleteCategoryGroupConfirmationModal = useCallback(
    () =>
      confirmingDeleteGroup ? (
        <Modal onClose={() => setConfirmingDeleteGroup(undefined)}>
          {({ close }) => (
            <DeleteCategoryGroupConfirmation
              group={confirmingDeleteGroup}
              onDelete={deleteGroup}
              onDone={close}
            />
          )}
        </Modal>
      ) : null,
    [confirmingDeleteGroup],
  );

  return [
    DeleteCategoryGroupConfirmationModal,
    { deleteGroup, deleteOrToggleConfirmation, confirmingDeleteGroup },
  ] as const;
};

export default useDeleteCategoryGroup;
