import useStaticCallback from 'common/lib/hooks/useStaticCallback';

/**
 * Identical to useStaticCallback but with a better name.
 *
 * You should use this instead of useCallback for event handlers. It helps avoid
 * useCallback dependency hell.
 *
 * The function returned by this hook will always be constant (using useRef), and upon invoking
 * it will always call the latest version of the function passed in.
 *
 * This should not be used for functions that are part of the render cycle, i.e. `renderItem` etc.
 */
const useEventCallback = useStaticCallback;

export default useEventCallback;
