import { transparentize } from 'polished';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { useFeatureFlag } from 'lib/hooks';

import * as COPY from 'common/constants/copy';

const SCREENSHOT_DESKTOP_DEVICE_URL =
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/connect-account/flat-desktop-cash-flow.png';

const SCREENSHOT_MOBILE_DEVICE_URL =
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/connect-account/flat-phone-cash-flow.png';

const StyledText = styled(Text)`
  width: 100%;
  max-width: 500px;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    max-width: 400px;
  }
`;

const PageTitle = styled(StyledText)`
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin: auto;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    font-size: 20px;
  }
`;

const PageSubtitle = styled(StyledText)`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  margin: ${({ theme }) => theme.spacing.small} auto ${({ theme }) => theme.spacing.xxxlarge};

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.small};
    margin: ${({ theme }) => theme.spacing.xsmall} auto ${({ theme }) => theme.spacing.xxlarge};
  }
`;

const PageContainer = styled(FlexContainer).attrs({ column: true })`
  margin-top: ${({ theme }) => theme.spacing.xxxxlarge};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: ${({ theme }) => theme.spacing.small};
  }
`;

const Device = styled(FlexContainer)`
  position: relative;
  width: 100%;
  max-width: 672px;
  margin: auto;
`;

const DeviceGradientOverlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 180px;
  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.color.white} 80%,
    ${({ theme }) => transparentize(1, theme.color.white)} 100%
  );

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.color.white} 50%,
      ${({ theme }) => transparentize(1, theme.color.white)} 100%
    );

    height: 120px;
  }
`;

// setting 92% width to balance with the transparent pixels in the phone image
const ContinueButton = styled(PrimaryButton)`
  width: 65%;
  margin: 0 auto;
  max-width: 450px;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    width: 92%;
    max-width: 100%;
  }
`;

const DeviceDesktopScreen = styled.img.attrs({
  src: SCREENSHOT_DESKTOP_DEVICE_URL,
})`
  width: 100%;
  height: auto;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    display: none;
  }
`;

const DeviceMobileScreen = styled.img.attrs({
  src: SCREENSHOT_MOBILE_DEVICE_URL,
})`
  width: 100%;
  height: auto;
  display: none;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    display: block;
  }
`;

type Props = {
  next: () => void;
  skipToComplete: () => void;
};

const OnboardingConnectAccountHypeScreen = ({ next, skipToComplete }: Props) => {
  const { treatment: abTestConnectSingleAccountBeforePaywall } = useFeatureFlag(
    'ab-test-connect-single-account-before-paywall',
    {
      trackImpression: true,
    },
  );

  /*
    skips the page when the ab-test-connect-accounts-before-paywall feature flag is on.
    This feature flag is used to let users connect accounts before the paywall. When that happens, we should skip this page.
  */
  useEffect(() => {
    if (abTestConnectSingleAccountBeforePaywall === 'on') {
      skipToComplete();
    }
  }, [abTestConnectSingleAccountBeforePaywall]);

  return (
    <PageContainer>
      {abTestConnectSingleAccountBeforePaywall !== 'on' && (
        <>
          <PageTitle>{COPY.ONBOARDING.CONNECT_ACCOUNT_HYPE.TITLE}</PageTitle>
          <PageSubtitle>{COPY.ONBOARDING.CONNECT_ACCOUNT_HYPE.SUBTITLE}</PageSubtitle>

          <Device>
            <DeviceDesktopScreen />
            <DeviceMobileScreen />
            <DeviceGradientOverlay>
              <ContinueButton size="large" type="button" onClick={next}>
                {COPY.ONBOARDING.SPENDING_INSIGHTS.BUTTON}
              </ContinueButton>
            </DeviceGradientOverlay>
          </Device>
        </>
      )}
    </PageContainer>
  );
};

export default OnboardingConnectAccountHypeScreen;
