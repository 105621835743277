import { rgba } from 'polished';
import React from 'react';
import { FiCheck } from 'react-icons/fi';
import styled, { css } from 'styled-components';

import Flex from 'components/lib/ui/Flex';

import { color, fontSize, fontWeight, radius, spacing } from 'common/lib/theme/dynamic';

export const DEFAULT_CHECKBOX_SIZE_PX = 20;

// Use "checked" prop instead of "value"
// "value" is only for use by Formik
export type Props = Pick<
  React.HTMLProps<HTMLInputElement>,
  'value' | 'checked' | 'id' | 'disabled'
> & {
  children?: React.ReactNode;
  className?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hideCheckbox?: boolean;
  size?: number;
};

const Label = styled.label`
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
`;

const Box = styled(Flex)<{ checked?: boolean; $size: number; $disabled?: boolean }>`
  position: relative;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  min-width: ${({ $size }) => $size}px;
  border-radius: ${radius.small};
  border: 1px solid ${color.gray};

  ${({ $disabled }) =>
    $disabled
      ? css`
          cursor: not-allowed;
          background-color: ${color.grayBackground};
          border-color: ${color.gray};
        `
      : css`
          ${Label}:hover & {
            background-color: ${color.grayFocus};
          }

          ${Label}:active & {
            box-shadow: inset 0 0 8px ${({ theme }) => rgba(theme.color.black, 0.2)};
          }
        `}

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${color.orange};
      border-color: ${color.orange};

      ${Label}:hover && {
        background-color: ${color.orangeDark};
      }
    `};
`;

export const Text = styled.span`
  margin-left: ${spacing.xsmall};
  font-size: ${fontSize.xsmall};
  font-weight: ${fontWeight.medium};
`;

const Input = styled.input`
  display: none;
  cursor: pointer;
`;

const CheckIcon = styled(FiCheck)`
  position: absolute;
  width: 70%;
  height: 70%;
  color: ${color.textWhite};
`;

const Checkbox = ({
  children,
  className,
  name,
  value,
  checked,
  hideCheckbox = false,
  size = DEFAULT_CHECKBOX_SIZE_PX,
  disabled,
  ...props
}: Props) => (
  <Label className={className}>
    {!hideCheckbox && (
      <Box checked={(checked || value) as boolean} $size={size} $disabled={disabled} center>
        <Input
          type="checkbox"
          {...props}
          name={name}
          checked={checked}
          value={value}
          disabled={disabled}
        />
        {checked || value ? <CheckIcon /> : null}
      </Box>
    )}
    {!!children && <Text>{children}</Text>}
  </Label>
);

export default Checkbox;
