import { MXEventName } from 'common/lib/external/mx';

import { DataProviderLegacy } from 'common/generated/graphQlTypes/globalTypes';
import type { ThemeType } from 'common/types/Styles';
import { InstitutionStatus } from 'common/types/institutions';

export const getColorForStatus = (status: InstitutionStatus, theme: ThemeType) =>
  ({
    [InstitutionStatus.Healthy]: theme.color.green,
    [InstitutionStatus.Degraded]: theme.color.yellow,
    [InstitutionStatus.Down]: theme.color.red,
    [InstitutionStatus.SyncDisabled]: theme.color.grayDark,
    [InstitutionStatus.NewConnectionsDisabled]: theme.color.grayDark,
  })[status];

export const TITLE_FOR_STATUS: { [status in InstitutionStatus]: string } = {
  [InstitutionStatus.Healthy]: 'Healthy',
  [InstitutionStatus.Degraded]: 'Issues reported',
  [InstitutionStatus.Down]: 'Offline',
  [InstitutionStatus.SyncDisabled]: 'Inactive',
  [InstitutionStatus.NewConnectionsDisabled]: 'Disabled',
};

export const APPLE_CARD_LOGO_URL =
  'https://monarch-static-assets.s3.amazonaws.com/institutions/apple_card.jpg';

export const DEFAULT_DATA_PROVIDER = DataProviderLegacy.PLAID;

// If the user engaged but didn't connect, consider it a connectivity issue
export const MX_CONNECTIVITY_ISSUE_EVENTS: MXEventName[] = [
  MXEventName.EnterCredentials,
  MXEventName.MemberCreateError,
  MXEventName.MemberError,
  MXEventName.OauthError,
  MXEventName.SubmitMFA,
];
