import React from 'react';

import type { Props as SingleCardPageProps } from 'components/lib/layouts/SingleCardPage';
import SingleCardPage from 'components/lib/layouts/SingleCardPage';

import { useQueryParam, useRedirectIfLoggedIn } from 'lib/hooks';

const LoggedOutCardPage = (props: SingleCardPageProps) => {
  const redirectRoute = useQueryParam('route');

  useRedirectIfLoggedIn(redirectRoute ?? undefined);

  return <SingleCardPage {...props} />;
};

export default LoggedOutCardPage;
