import type { OperationVariables, DataProxy } from '@apollo/client';

// In Apollo V2, trying to read a fragment can do 1 of three things:
// - if the key exists, and has all the requested fields, returns it's cached value
// - if the key doesn't exist, return null
// - if the key exists but doesn't have ALL requested fields, throw an error
//
// We want to safely try to read a fragment, and if any fields are missing, we want to
// just return null (as if the key didn't exist).
const readFragmentOrNull = <T = any, TVariables extends OperationVariables = OperationVariables>(
  apolloClient: DataProxy,
  options: DataProxy.Fragment<TVariables, T>,
  optimistic?: boolean,
) => {
  try {
    return apolloClient.readFragment<T>(options, optimistic);
  } catch (error) {
    return null;
  }
};

export default readFragmentOrNull;
