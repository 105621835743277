import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import type { Props as PageProps } from 'components/lib/ui/Page';
import Page from 'components/lib/ui/Page';

const StyledPage = styled(Page)`
  background-color: ${({ theme }) => theme.color.white};
  text-align: center;
  min-height: 100vh;
`;

const Container = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = PageProps & {
  quote?: { text: string; author: string };
  children: React.ReactNode;
};

const OnboardingPage = ({ children, quote, ...pageProps }: Props) => (
  <StyledPage {...pageProps}>
    <Container>{children}</Container>
  </StyledPage>
);

export default OnboardingPage;
