import React from 'react';
import styled from 'styled-components';

import GoalImage from 'components/goalsV2/GoalImage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { gql } from 'common/generated/gql';
import type { Web_GoalInfoRowFieldsFragment } from 'common/generated/graphql';

const Root = styled(FlexContainer).attrs({ alignCenter: true })``;

const StyledGoalImage = styled(GoalImage)`
  width: 42px;
  height: 42px;
  position: relative;
  border-radius: 2px;
  margin-right: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  data: Web_GoalInfoRowFieldsFragment;
  className?: string;
};

/**
 * Component to display a goal title, priority, and small image in a horizontal format.
 */
const GoalInfoRow = ({
  data: { name, priority, imageStorageProvider, imageStorageProviderId },
  className,
}: Props) => (
  <Root className={className}>
    <StyledGoalImage
      imageStorageProvider={imageStorageProvider}
      imageStorageProviderId={imageStorageProviderId}
      size="small"
      showGradient={false}
    />
    <FlexContainer column>
      <Text color="textLight" weight="bold" size="xsmall">
        #{priority}
      </Text>
      <Text color="text" weight="medium">
        {name}
      </Text>
    </FlexContainer>
  </Root>
);

GoalInfoRow.fragments = {
  GoalInfoRowFields: gql(/* GraphQL */ `
    fragment Web_GoalInfoRowFields on GoalV2 {
      id
      name
      priority
      imageStorageProvider
      imageStorageProviderId
    }
  `),
};

export default GoalInfoRow;
