import { REFERRALS } from 'common/constants/copy';

type Options = {
  isReferralPromoCopyChangesActive?: boolean;
};

export const formatReferralCode = (code: string) => `https://www.monarchmoney.com/referral/${code}`;

export const getShareTwitterUrl = (
  url: string,
  { isReferralPromoCopyChangesActive }: Options = {},
) => {
  const body = isReferralPromoCopyChangesActive
    ? REFERRALS.JUNE_2024_DISCOUNT.SHARE_TWEET_BODY(url)
    : REFERRALS.SHARE_TWEET_BODY(url);
  return `https://twitter.com/intent/tweet?text=${encodeURIComponent(body)}`;
};

export const getShareFacebookUrl = (
  url: string,
  { isReferralPromoCopyChangesActive }: Options = {},
) =>
  `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${encodeURIComponent(
    isReferralPromoCopyChangesActive
      ? REFERRALS.JUNE_2024_DISCOUNT.GENERAL_SHARE_BODY
      : REFERRALS.GENERAL_SHARE_BODY,
  )}`;

export const getShareLinkedInUrl = (url: string) =>
  `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`;

export const getShareMailUrl = (
  url: string,
  { isReferralPromoCopyChangesActive }: Options = {},
) => {
  const subject = REFERRALS.GENERAL_SHARE_SUBJECT;
  const body = `${
    isReferralPromoCopyChangesActive
      ? REFERRALS.JUNE_2024_DISCOUNT.GENERAL_SHARE_BODY
      : REFERRALS.GENERAL_SHARE_BODY
  } ${url}`;
  return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
};
