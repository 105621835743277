import React from 'react';
import styled from 'styled-components';

export type StyleProps = {
  padding?: string | number;
  rowGutterPx?: number;
  columnGutterPx?: number;
};

export type Props = {
  children: React.ReactNode;
  className?: string;
} & StyleProps;

export const GRID_COLUMNS = 12;

// Responsive grid component. Usually, you'd do something like:
// <Row>
//   <Column md={6} xl={4}/>YOUR COLUMN</Column>
// </Row>
//
// The grid has 12 slots by default, and by default, a Column takes all 12 slots.
// When you add a break-point (like md=6), that means that on md screens and above,
// the Column takes only 6 slots (so you could have two columns).
// So on the example above, Colum would take the full-width (12 slots) on smaller screens,
// half-width (6 slots) on medium and above, and third-width (4 slots) on xlarge.

const Root = styled.div<StyleProps>`
  display: grid;
  grid-template-columns: repeat(${GRID_COLUMNS}, 1fr);
  grid-column-gap: ${({ theme, columnGutterPx }) =>
    columnGutterPx ? `${columnGutterPx}px` : theme.spacing.gutter};
  grid-row-gap: ${({ theme, rowGutterPx }) => `${rowGutterPx}px` ?? theme.spacing.gutter};
  width: 100%;
  padding: ${({ theme, padding }) => padding ?? theme.spacing.gutter};

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    padding: ${({ theme, padding }) => padding ?? theme.spacing.xsmall};
    grid-column-gap: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const Row = ({ children, className, padding, rowGutterPx, columnGutterPx }: Props) => (
  <Root
    className={className}
    padding={padding}
    rowGutterPx={rowGutterPx}
    columnGutterPx={columnGutterPx}
  >
    {children}
  </Root>
);

export default Row;
