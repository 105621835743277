import { createAction } from 'typesafe-actions';

import type { UpdateUserParams, UserState } from './reducer';

export const login = createAction('USER/LOGIN')<UserState>();

export const logout = createAction('USER/LOGOUT')();

export const updateUser = createAction('USER/UPDATE_USER')<UpdateUserParams>();

export const fetchUser = createAction('USER/FETCH_USER')<UserState>();

export const setTokenExpiration = createAction('SESSION/SET_TOKEN_EXPIRATION')<string | null>();
