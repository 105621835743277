import { useMediaQuery } from '@react-hook/media-query';
import { ellipsis } from 'polished';
import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import Tooltip, { TooltipText } from 'components/lib/ui/Tooltip';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import TransactionLinkButton from 'components/transactions/list/TransactionLinkButton';

import { radius, spacing } from 'common/lib/theme/dynamic';
import theme from 'common/lib/theme/staticTheme';

import routes from 'constants/routes';

import type { TransactionOverviewFieldsFragment } from 'common/generated/graphql';

const ACCOUNT_LOGO_SIZE_PX = 20;
const ACCOUNT_BREAKPOINT_PX = theme.breakPoints.md;
const ACCOUNT_TOOLTIP_SHOW_DELAY_MS = 300;

const Name = styled.span`
  ${ellipsis()}
`;

const Root = styled(TransactionLinkButton)<{ $isNarrowScreen: boolean }>`
  flex: 0 0 auto;
  padding-left: ${spacing.xsmall};
  padding-right: ${spacing.xsmall};

  @media (max-width: ${ACCOUNT_BREAKPOINT_PX}px) {
    border-radius: ${radius.round};
    padding: 0;
    max-width: calc(${ACCOUNT_LOGO_SIZE_PX}px + ${spacing.default});
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    ${Name}, ${ButtonIcon} {
      display: none;
    }

    ${({ $isNarrowScreen }) =>
      $isNarrowScreen &&
      `
        &:hover {
          ${Name}, ${ButtonIcon} {
            display: none;
          }
        }
      `}
  }
`;

type Props = Pick<
  TransactionOverviewFieldsFragment['account'],
  'id' | 'displayName' | 'icon' | 'logoUrl'
> & { className?: string };

const TransactionAccount: FC<Props> = ({ id, displayName, icon, logoUrl, className }) => {
  const isNarrowScreen = useMediaQuery(`only screen and (max-width: ${ACCOUNT_BREAKPOINT_PX}px)`);

  return (
    <Tooltip
      content={isNarrowScreen ? <TooltipText>{displayName}</TooltipText> : null}
      delayShow={ACCOUNT_TOOLTIP_SHOW_DELAY_MS}
      fitContent
    >
      <Root
        linkTo={routes.accounts.accountDetails({ id })}
        title={displayName}
        className={className}
        $isNarrowScreen={isNarrowScreen}
        hideUntilHover
      >
        <AccountLogo logoUrl={logoUrl} icon={icon} size={ACCOUNT_LOGO_SIZE_PX} />
        <Name>{displayName}</Name>
      </Root>
    </Tooltip>
  );
};

export default TransactionAccount;
