import * as Yup from 'yup';

import type { Holding, Security } from 'common/generated/graphql';

export const getHoldingValue = (quantity: number | null, price: number | null) => {
  let value = (quantity ?? 1) * (price ?? 0);
  // Workaround for issue described here:
  // https://github.com/adamwdraper/Numeral-js/issues/596
  if (value < 1e-6) {
    value = 0;
  }
  return value;
};

const REQUIRED_MESSAGE = 'This field is required';
const POSITIVE_MESSAGE = 'This field should be a positive number';
const NONZERO_MESSAGE = 'This field cannot be zero';

export const editHoldingFormValidationSchema = Yup.object().shape({
  quantity: Yup.number()
    .required(REQUIRED_MESSAGE)
    .positive(POSITIVE_MESSAGE)
    .moreThan(0, NONZERO_MESSAGE),
  cost: Yup.number().required(REQUIRED_MESSAGE).positive(POSITIVE_MESSAGE),
});

export const getHoldingPricingInfo = (
  holding: Pick<Holding, 'closingPrice' | 'closingPriceUpdatedAt'>,
  security: Pick<
    Security,
    'currentPrice' | 'currentPriceUpdatedAt' | 'closingPrice' | 'closingPriceUpdatedAt'
  > | null,
) => {
  const price = security?.currentPrice ?? security?.closingPrice ?? holding?.closingPrice;
  const priceUpdatedAt =
    security?.currentPriceUpdatedAt ??
    security?.closingPriceUpdatedAt ??
    holding?.closingPriceUpdatedAt ??
    '';

  return {
    price,
    priceUpdatedAt,
  };
};
