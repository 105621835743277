import type { Web_TransactionsFilterQuery } from 'common/generated/graphql';
import type { ExtractTypedObjects } from 'common/types/utility';
import type { TransactionFilters } from 'types/filters';

export type IsSelectedFn = (id: string) => boolean;

export type RenderFunction<
  T extends keyof Omit<
    ExtractTypedObjects<Web_TransactionsFilterQuery>,
    'allMintTransactions' | 'myHousehold'
  >,
> = (item: Web_TransactionsFilterQuery[T][number]) => React.ReactNode;

export type AdapterRenderFunctions = {
  renderTag: RenderFunction<'householdTransactionTags'>;
  renderMerchantAccessory: RenderFunction<'merchants'>;
  renderAccountLogo: RenderFunction<'accounts'>;
};

export type SectionAdapterOptions = Partial<AdapterRenderFunctions> & {
  hasTransactionsImportedFromMint?: boolean;
  householdUsers?: Maybe<{ name: string; id: string }[]>;
};

export interface MakeRadioFilterOptions {
  label: string;
  optionLabel?: string;
  negativeOptionLabel?: string;
  filterPath: (keyof TransactionFilters)[];
  hide?: boolean;
  /** The filter is inverted, meaning that the option is selected when the filter is falsy. */
  inverse?: boolean;
}

export enum AmountFilter {
  LessThan = 'lessThan',
  GreaterThan = 'greaterThan',
  Between = 'between',
  EqualTo = 'equalTo',
  DebitsOnly = 'debitsOnly',
  CreditsOnly = 'creditsOnly',
}
