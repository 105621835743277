import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import ContactSupportFlow from 'components/support/ContactSupportFlow';

import { CANCELLATION_REASON_COPY } from 'common/lib/billing/Billing';
import { useStackContext } from 'common/lib/contexts/StackContext';
import { CANCEL_SUBSCRIPTION_MUTATION } from 'common/lib/graphQl/billing';
import { track } from 'lib/analytics/segment';
import { useModalContext } from 'lib/contexts/ModalContext';

import { CancelFlowEventNames } from 'common/constants/analytics';

import type { CancellationReason } from 'common/generated/graphql';

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  reason: CancellationReason;
  analyticsFreemiumSummaryStatus?: string | null;
  /** This is mostly a vanity option and does the same thing as cancelling, but
   * just changes the copy to say the user has switched to the free plan.
   */
};

/**
 * This is part of the new cancellation flow which is behind the feature flag: web-cancel-discount-flow
 *
 * This component actually calls the mutation to cancel the subscription.
 */
const PerformCancelSubscriptionModal = ({ reason, analyticsFreemiumSummaryStatus }: Props) => {
  const { close } = useModalContext();
  const { push } = useStackContext();

  const [cancelSubscription, { data, loading }] = useMutation(CANCEL_SUBSCRIPTION_MUTATION);
  const { cancelStripeSubscription } = data ?? {};
  const { canceled, subscription } = cancelStripeSubscription ?? {};
  const { currentPeriodEndsAt } = subscription ?? {};

  useEffect(() => {
    cancelSubscription({
      variables: {
        input: {
          // send copy instead of enum value to keep this consistent with previous implementation
          reason: CANCELLATION_REASON_COPY[reason],
          feedback: '',
        },
      },
    });

    track(CancelFlowEventNames.CancelFlowCompleted, { reason, analyticsFreemiumSummaryStatus });
  }, []);

  const title = (() => {
    if (loading) {
      return 'Canceling subscription...';
    } else if (canceled) {
      return 'Subscription canceled';
    } else {
      return 'Something went wrong';
    }
  })();

  const cancelSuccessful = !loading && canceled;
  const errorOccurred = !loading && !canceled;

  return (
    <ModalCard title={title}>
      {loading && (
        <CardBody>
          <FlexContainer center>
            <LoadingSpinner />
          </FlexContainer>
        </CardBody>
      )}
      {cancelSuccessful && (
        <>
          <CardBody>
            Your subscription has been canceled.{' '}
            {!!currentPeriodEndsAt && (
              <>
                You will have access to Monarch Premium until{' '}
                <Text weight="medium">
                  {DateTime.fromISO(currentPeriodEndsAt).toLocaleString({
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </Text>
                .
              </>
            )}
          </CardBody>
          <CardFooter justifyEnd>
            <PrimaryButton onClick={close}>Done</PrimaryButton>
          </CardFooter>
        </>
      )}
      {errorOccurred && (
        <>
          <CardBody>
            <div>An unknown error occurred. Please try again or contact support.</div>
          </CardBody>
          <CardFooter justifyEnd>
            <PrimaryButton onClick={() => push(ContactSupportFlow, { onComplete: close })}>
              Contact support
            </PrimaryButton>
          </CardFooter>
        </>
      )}
    </ModalCard>
  );
};

export default PerformCancelSubscriptionModal;
