import styled from 'styled-components';

import Text from 'components/lib/ui/Text';

const DemoOnboardingTitle = styled(Text).attrs({ weight: 'medium' })`
  font-size: 20px;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.large};
  }
`;

export default DemoOnboardingTitle;
