import { useApolloClient } from '@apollo/client';
import pluralize from 'pluralize';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import CardFooter from 'components/lib/ui/CardFooter';
import FileDropzone from 'components/lib/ui/FileDropzone';
import Link from 'components/lib/ui/Link';
import ModalCard from 'components/lib/ui/ModalCard';
import Toggle from 'components/lib/ui/Toggle';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';
import TransactionDrawerFieldCell from 'components/transactions/drawer/TransactionDrawerFieldCell';

import useLoading from 'common/lib/hooks/useLoading';
import typewriter from 'lib/analytics/typewriter';
import { useModalContext } from 'lib/contexts/ModalContext';
import useUploadStatementSession from 'lib/hooks/statements/useUploadStatementSession';
import toast from 'lib/ui/toast';

import { DEFAULT_ERROR_MESSAGE } from 'common/constants/copy';
import { HELP_CENTER_CSV_ARTICLE_URL } from 'common/constants/externalUrls';
import { ParserName, UploadSessionStatus } from 'common/constants/statements';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const SubmitButton = styled(AsyncButton)`
  ${primaryButtonMixin}
`;

type Props = {
  accountId: string;
  isManual: boolean;
};

const UploadTransactionsCsvModal = ({ accountId, isManual }: Props) => {
  const { close } = useModalContext();
  const apolloClient = useApolloClient();

  const [file, setFile] = useState<File>();
  const [shouldUpdateBalance, setShouldUpdateBalance] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { startSession, startParsing, currentSession, clearSession } = useUploadStatementSession({
    parserName: ParserName.MonarchCSV,
    // @ts-ignore
    onParseSuccess: ({ uploadedStatement }) => {
      const { transactionCount } = uploadedStatement ?? {};

      toast({
        title: 'Upload complete',
        description: `Successfully imported ${pluralize('transaction', transactionCount, true)}.`,
      });

      // We only need to refetch GetTransactionsList query here, but there doesn't appear to be
      // a way to do just that until we upgrade to apollo v3. So instead we refetch all active queries
      apolloClient.reFetchObservableQueries();

      close();
    },
    // @ts-ignore
    onParseError: ({ errorMessage }) => {
      setErrorMessage(errorMessage ?? DEFAULT_ERROR_MESSAGE);

      typewriter.statementUploadErrored({
        parserName: ParserName.MintCSV,
        errorMessage: errorMessage ?? undefined,
      });
    },
  });

  useEffect(() => {
    // Clear any existing session when the modal opens
    clearSession();
  }, []);

  const [isLoading, submit] = useLoading(async () => {
    if (!file) {
      return;
    }

    setErrorMessage(undefined);
    const session = await startSession(file);
    startParsing({ accountId, sessionKey: session.sessionKey, shouldUpdateBalance });
  });

  const isResubmittingDuplicateFile = !!currentSession?.skipCheckForDuplicates;

  const isProcessing =
    isLoading ||
    [UploadSessionStatus.Created, UploadSessionStatus.Started].includes(
      (currentSession?.status as UploadSessionStatus) ?? '',
    );

  return (
    <ModalCard
      title="Upload CSV"
      description={
        <span>
          Find out more about how to format your CSV for success{' '}
          <Link href={HELP_CENTER_CSV_ARTICLE_URL} target="_blank">
            here
          </Link>
          .
        </span>
      }
    >
      {/* Normal submission - it displays this container when it is loading so the user is aware the file is being submitted */}
      {(!isResubmittingDuplicateFile || isProcessing) && (
        <Container>
          <FileDropzone
            acceptFileTypes={['text/csv', '.csv']}
            maxNumFiles={1}
            title="Upload a CSV File"
            errorMessage={errorMessage}
            files={file ? [file] : []}
            onChangeFiles={(files) => setFile(R.head(files))}
            onDropRejected={(rejections) => {
              const [rejection] = rejections;
              typewriter.statementUploadErrored({
                parserName: ParserName.MonarchCSV,
                errorMessage: rejection?.errors[0]?.message,
                errorCode: rejection?.errors[0]?.message,
                filename: rejection?.file?.name,
              });
            }}
          />
        </Container>
      )}

      {isManual && (
        <Container>
          {/* Checkbox to update account balances */}
          <TransactionDrawerFieldCell
            title="Adjust account's balances based on these transactions"
            description="This will update the account's balance by the transactions amount"
            rightAccessory={
              <Toggle
                checked={shouldUpdateBalance}
                onChange={() => setShouldUpdateBalance(!shouldUpdateBalance)}
              />
            }
          />
        </Container>
      )}

      {/* User is resubmitting a file that returned with a duplicate file error  */}
      {isResubmittingDuplicateFile && !isProcessing && (
        <Container>
          <p>{errorMessage}</p>
        </Container>
      )}

      <CardFooter>
        <DefaultButton onClick={close}>Cancel</DefaultButton>
        <SubmitButton
          disabled={!file}
          onClick={submit}
          pending={
            isLoading ||
            [UploadSessionStatus.Created, UploadSessionStatus.Started].includes(
              // @ts-ignore
              (currentSession?.status as UploadSessionStatus) ?? '',
            )
          }
        >
          {isResubmittingDuplicateFile ? 'Confirm' : 'Add to account'}
        </SubmitButton>
      </CardFooter>
    </ModalCard>
  );
};

export default UploadTransactionsCsvModal;
