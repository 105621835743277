import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import ExplorePromptsModal from 'components/assistant/ExplorePromptsModal';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextAreaField from 'components/lib/form/TextAreaField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Markdown from 'components/lib/ui/Markdown';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { MultipleChoiceBlock } from 'common/lib/assistant/blocks';
import { getBlocksFromMessageContent } from 'common/lib/assistant/parse';
import useSendMessage from 'common/lib/hooks/assistant/useSendMessage';
import useModal from 'lib/hooks/useModal';

import * as COPY from 'common/constants/copy';

import type { Web_GetMessageThread_messageThread_messages } from 'common/generated/graphQlTypes/Web_GetMessageThread';

const SHOW_EXPLORE_BUTTON = false; // disable this for now

const Root = styled(FlexContainer).attrs({ column: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledForm = styled(Form)`
  width: 100%;
` as typeof Form;

const StyledTextAreaField = styled(TextAreaField)`
  flex: 1;
  && {
    margin-bottom: 0;
  }
`;

const StyledFormSubmitButton = styled(FormSubmitButton)`
  flex: 0;
  margin-left: ${({ theme }) => theme.spacing.default};
  && {
    margin-top: 0;
  }
`;

const SuggestedPrompts = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.default};
  overflow-x: auto;
  width: 100%;
  position: relative;
  height: 38px;

  &::-webkit-scrollbar {
    display: none; /* Chrome, Opera, Safari */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const SuggestedPromptsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
`;

const PillButton = styled(DefaultButton)<{ $circle?: boolean }>`
  border-radius: ${({ theme }) => theme.radius.pill};
  display: inline-block;

  :not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing.xsmall};
  }

  ${({ $circle }) =>
    $circle &&
    css`
      width: 38px;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    `}
`;

const Footer = styled(Markdown)`
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  line-height: 150%;

  a {
    color: ${({ theme }) => theme.color.textLight} !important;
    font-weight: ${({ theme }) => theme.fontWeight.book} !important;
    text-decoration: underline;
  }
`;

type Props = {
  threadId: string;
  lastMessage?: Web_GetMessageThread_messageThread_messages;
  className?: string;
};

const MessageInputContainer = ({ threadId, lastMessage, className }: Props) => {
  const [ExploreModal, { open: openExploreModal, close: closeExploreModal }] = useModal();

  const [sendMessageMutation] = useSendMessage();

  const sendMessage = (content: string, fromRecommendedPrompt?: boolean) =>
    sendMessageMutation({
      variables: {
        input: {
          threadId,
          content,
          fromRecommendedPrompt,
        },
      },
    });

  const lastMessageBlocks = useMemo(
    () => getBlocksFromMessageContent(lastMessage?.content ?? ''),
    [lastMessage],
  );

  const multipleChoiceBlock = useMemo(
    () => lastMessageBlocks.find(({ type }) => type === MultipleChoiceBlock.type),
    [lastMessageBlocks],
  );

  const suggestedPrompts = useMemo(() => {
    if (multipleChoiceBlock) {
      // if the last message has a multiple choice block, don't show suggested prompts
      return [];
    }

    if (lastMessage?.__typename === 'AssistantMessage') {
      return lastMessage.suggestedPrompts ?? [];
    }
  }, [multipleChoiceBlock, lastMessage]);

  return (
    <>
      <Root className={className}>
        <SuggestedPrompts>
          <SuggestedPromptsContainer>
            {SHOW_EXPLORE_BUTTON && (
              <PillButton $circle onClick={openExploreModal}>
                <span role="img" aria-label="Explore prompts">
                  🔎
                </span>
              </PillButton>
            )}
            {suggestedPrompts?.map((prompt, i) => (
              <PillButton key={i} onClick={() => sendMessage(prompt, true)}>
                {prompt}
              </PillButton>
            ))}
          </SuggestedPromptsContainer>
        </SuggestedPrompts>
        <StyledForm
          initialValues={{ threadId, content: '' }}
          onSubmit={async (input, { resetForm }) => {
            resetForm();
            await sendMessage(input.content);
          }}
        >
          <FlexContainer alignEnd>
            <StyledTextAreaField
              large
              name="content"
              hideLabel
              placeholder="Ask anything about your money..."
              hideResizeHandle
              required
              onlyShowApiErrors
              submitOnEnter
            />
            <StyledFormSubmitButton size="large">Send</StyledFormSubmitButton>
          </FlexContainer>
        </StyledForm>
        <Footer source={COPY.ASSISTANT.DISCLAIMER} />
      </Root>
      <ExploreModal>
        <ExplorePromptsModal
          onClickPrompt={(prompt) => {
            closeExploreModal();
            sendMessage(prompt, true);
          }}
        />
      </ExploreModal>
    </>
  );
};

export default MessageInputContainer;
