import type { OperationVariables } from '@apollo/client/core/types';
import type { DocumentNode } from 'graphql';

import ApolloClient from 'lib/graphQl/ApolloClient';

/**
 * Manually refetch a set of queries without a mutation.
 *
 * Most of the time, this should not be needed. Use the `refetchQueries` field on useMutation.
 */
const refetchQueriesWithVariables = async <
  TData,
  TVars extends OperationVariables = OperationVariables,
>(
  queries: Array<{ query: DocumentNode; variables?: TVars }>,
) => {
  queries.map(({ query, variables }) =>
    ApolloClient.query<TData, TVars>({ query, variables, fetchPolicy: 'network-only' }),
  );
};

export default refetchQueriesWithVariables;
