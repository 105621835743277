import { DateTime } from 'luxon';

import { ACCOUNT_SUMMARY_DATA } from 'common/utils/graphQl/__mocks__/mockAccounts';

const MONTH_START = DateTime.local().startOf('month');
const MOCK_CATEGORY = { name: 'Subscriptions', icon: '🗓️' };

const getSampleMerchantLogo = (name: string) =>
  `https://monarch-static-assets.s3.us-east-1.amazonaws.com/sample-merchant-logos/${name}.png`;

const creditCardAccount = ACCOUNT_SUMMARY_DATA.find((account) => account.type.name === 'credit')
  ?.accounts[0];

export const MOCK_RECURRING_TRANSACTION_ITEMS = [
  {
    __typename: 'RecurringTransactionCalendarItem',
    stream: {
      __typename: 'RecurringTransactionStream',
      id: '1',
      frequency: 'monthly',
      merchant: {
        __typename: 'Merchant',
        id: '1',
        name: 'Spotify',
        logoUrl: getSampleMerchantLogo('spotify'),
      },
    },
    account: creditCardAccount,
    amount: -9.99,
    date: MONTH_START.plus({ days: 4 }).toISODate(),
    category: MOCK_CATEGORY,
    isPast: false,
  },
  {
    stream: {
      id: '123900840733248590',
      frequency: 'monthly',
      merchant: {
        id: '104756601265000323',
        name: 'LinkedIn',
        logoUrl: getSampleMerchantLogo('linkedin'),
        __typename: 'Merchant',
      },
      __typename: 'RecurringTransactionStream',
    },
    isPast: false,
    date: MONTH_START.plus({ days: 7 }).toISODate(),
    category: MOCK_CATEGORY,
    amount: -139.99,
    account: creditCardAccount,
    __typename: 'RecurringTransactionCalendarItem',
  },
  {
    __typename: 'RecurringTransactionCalendarItem',
    stream: {
      __typename: 'RecurringTransactionStream',
      id: '2',
      frequency: 'annual',
      merchant: {
        __typename: 'Merchant',
        id: '2',
        name: 'Amazon Prime',
        logoUrl: getSampleMerchantLogo('prime-video'),
      },
    },
    account: creditCardAccount,
    amount: -119,
    date: MONTH_START.plus({ days: 12 }).toISODate(),
    category: MOCK_CATEGORY,
    isPast: false,
  },
  {
    __typename: 'RecurringTransactionCalendarItem',
    stream: {
      __typename: 'RecurringTransactionStream',
      id: '3',
      frequency: 'quarterly',
      merchant: {
        __typename: 'Merchant',
        id: '3',
        name: 'Adobe Creative Cloud',
        logoUrl: getSampleMerchantLogo('creative-cloud'),
      },
    },
    account: creditCardAccount,
    amount: -79.99,
    date: MONTH_START.plus({ days: 18 }).toISODate(),
    category: MOCK_CATEGORY,
    isPast: false,
  },
  {
    __typename: 'RecurringTransactionCalendarItem',
    stream: {
      __typename: 'RecurringTransactionStream',
      id: '4',
      frequency: 'monthly',
      merchant: {
        __typename: 'Merchant',
        id: '4',
        name: 'Netflix',
        logoUrl: getSampleMerchantLogo('netflix'),
      },
    },
    account: creditCardAccount,
    amount: -13.99,
    date: MONTH_START.plus({ days: 18 }).toISODate(),
    category: MOCK_CATEGORY,
    isPast: false,
  },
  {
    __typename: 'RecurringTransactionCalendarItem',
    stream: {
      __typename: 'RecurringTransactionStream',
      id: '5',
      frequency: 'monthly',
      merchant: {
        __typename: 'Merchant',
        id: '5',
        name: 'Zoom',
        logoUrl: getSampleMerchantLogo('zoom'),
      },
    },
    account: creditCardAccount,
    amount: -14.99,
    date: MONTH_START.plus({ days: 24 }).toISODate(),
    category: MOCK_CATEGORY,
    isPast: false,
  },
  {
    __typename: 'RecurringTransactionCalendarItem',
    stream: {
      __typename: 'RecurringTransactionStream',
      id: '6',
      frequency: 'monthly',
      merchant: {
        __typename: 'Merchant',
        id: '6',
        name: 'HBO Max',
        logoUrl: getSampleMerchantLogo('hbomax'),
      },
    },
    account: creditCardAccount,
    amount: -14.99,
    date: MONTH_START.plus({ days: 30 }).toISODate(),
    category: MOCK_CATEGORY,
    isPast: false,
  },
];
