import * as RA from 'ramda-adjunct';
import { useDispatch, useSelector } from 'react-redux';

import { setHasAddedAccounts } from 'common/actions';
import { GET_HAS_ACCOUNTS } from 'common/lib/graphQl/accounts';
import useQuery from 'common/lib/hooks/useQuery';
import { getHasAddedAccounts } from 'common/state/accounts/selectors';

import type { GetHasAccountsQueryVariables } from 'common/generated/graphql';

/** Hook to get if user has accounts. It reads from localStorage and only if there is no data available it runs a query */
const useHasAccounts = () => {
  const dispatch = useDispatch();

  const hasAccountsPersisted = useSelector(getHasAddedAccounts);

  const { data, refetch, error, isLoadingInitialData } = useQuery<GetHasAccountsQueryVariables>(
    GET_HAS_ACCOUNTS,
    {
      skip: hasAccountsPersisted,
      onCompleted({ hasAccounts }) {
        if (hasAccounts) {
          dispatch(setHasAddedAccounts(hasAccounts));
        }
      },
    },
  );

  const hasAccounts =
    (RA.isNotNilOrEmpty(hasAccountsPersisted) ? hasAccountsPersisted : data?.hasAccounts) ??
    undefined;

  return { hasAccounts, refetch, error, isLoadingInitialData };
};

export default useHasAccounts;
