import styled, { css } from 'styled-components';

import type { StyleMixin } from 'common/types/Styles';

export const linkMixin: StyleMixin<{ stealthy?: boolean; unstyled?: boolean; small?: boolean }> = ({
  theme,
  stealthy,
  unstyled,
  small,
}) => `
  transition: ${theme.transition.default};
  text-decoration: none;
  cursor: pointer;
  font-weight: ${stealthy ? 'inherit' : theme.fontWeight.medium};
  font-size: ${small ? theme.fontSize.small : 'inherit'};

  &,
  :visited {
    color: ${stealthy ? 'inherit' : theme.color.blue};
  }

  :hover {
    color: ${theme.color.blueLight};
  }

  ${
    unstyled
      ? css`
          &,
          :visited,
          :hover {
            color: inherit;
            font-weight: inherit;
          }
        `
      : ''
  }

`;

const Link = styled.a<{
  /** Keep Link unstyled until hover. */
  stealthy?: boolean;

  /** Sets color and font-weight to inherit */
  unstyled?: boolean;

  /** Allows for `<Link as={NavLink} to="some-path">`. */
  // DEPRECATED DO NOT USE, use <RouteLink to={route}> above
  to?: string;
}>`
  ${linkMixin}
`;

export default Link;
