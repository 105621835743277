import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import GoalImage from 'components/goalsV2/GoalImage';
import CardFooter from 'components/lib/ui/CardFooter';
import CheckCircleTransparent from 'components/lib/ui/CheckCircleTransparent';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import SearchBar from 'components/lib/ui/SearchBar';
import Text from 'components/lib/ui/Text';
import UnsplashLogo from 'components/lib/ui/UnsplashLogo';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import type { UnsplashPhoto } from 'common/lib/external/unsplash';
import unsplash from 'common/lib/external/unsplash';
import useUnsplashPhotos from 'common/lib/external/useUnsplashPhotos';
import useDebounce from 'common/lib/hooks/useDebounce';
import useTheme from 'lib/hooks/useTheme';

const SEARCH_DEBOUNCE_MS = 300;

const StyledSearchBar = styled(SearchBar)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const Content = styled(FlexContainer).attrs({ column: true })`
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.xlarge};
  min-height: 508px;
`;

const ImageList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${({ theme }) => theme.spacing.default};
  position: relative;
  height: 420px;
  overflow: auto;
`;

const ItemContainer = styled(FlexContainer).attrs({ alignCenter: true, justifyCenter: true })`
  aspect-ratio: 0.93;
  position: relative;
  border-radius: ${({ theme }) => theme.radius.medium};
  cursor: pointer;
`;

const ImageSubtitle = styled(Text).attrs({ size: 'xsmall', color: 'textLight' })`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledCardFooter = styled(CardFooter)`
  margin-top: auto;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: ${({ theme }) => theme.spacing.xxxlarge} auto;
`;

const StyledUnsplashLogo = styled(UnsplashLogo)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledCheckCircleTransparent = styled(CheckCircleTransparent)`
  position: relative;

  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

type Props = {
  onSelect: (photo: UnsplashPhoto) => void;
  onClose: () => void;
};

const UnsplashImagePickerModal = ({ onSelect, onClose }: Props) => {
  const searchBarRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  const [search, setSearch] = useState<string>();
  const debouncedSearch = useDebounce(search, SEARCH_DEBOUNCE_MS);
  const { photos, loading } = useUnsplashPhotos(debouncedSearch);

  const [selectedPhoto, setSelectedPhoto] = useState<UnsplashPhoto | null>(null);

  useEffect(() => {
    searchBarRef.current?.focus();
  }, []);

  return (
    <ModalCard
      onClickBackButton={onClose}
      title={
        <>
          <StyledUnsplashLogo />{' '}
          <Text color={theme.uiTheme === 'dark' ? 'textWhite' : 'trueBlack'}>Unsplash</Text>
        </>
      }
      hideBottomBorder
    >
      <Content>
        <StyledSearchBar
          ref={searchBarRef}
          onChange={setSearch}
          value={search}
          name="unsplash-search"
          placeholder="Search..."
          autoFocus
        />
        {loading ? (
          <StyledLoadingSpinner />
        ) : (
          <ImageList>
            {photos.map((photo) => {
              const isSelected = photo.id === selectedPhoto?.id;
              return (
                <div key={photo.id}>
                  <ItemContainer
                    column
                    onClick={() => setSelectedPhoto(!isSelected ? photo : null)}
                  >
                    <GoalImage
                      imageStorageProvider="unsplash"
                      imageStorageProviderId={photo.urls.small}
                      size="small"
                      showGradient={isSelected}
                    />
                    {isSelected && <StyledCheckCircleTransparent />}
                  </ItemContainer>
                  <ImageSubtitle>{photo.user.name}</ImageSubtitle>
                </div>
              );
            })}
          </ImageList>
        )}
      </Content>
      <StyledCardFooter>
        <DefaultButton onClick={onClose} size="small">
          Cancel
        </DefaultButton>
        <PrimaryButton
          disabled={!selectedPhoto}
          onClick={() => {
            // This will only be called if button is not disabled, meaning selectedPhoto definitely exists
            onSelect(selectedPhoto!);
            unsplash.trackDownload(selectedPhoto!);
            onClose();
          }}
          size="small"
        >
          Save
        </PrimaryButton>
      </StyledCardFooter>
    </ModalCard>
  );
};

export default UnsplashImagePickerModal;
