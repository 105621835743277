import type { MutationHookOptions } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';

import type {
  DeleteMessageThread,
  DeleteMessageThreadVariables,
} from 'common/generated/graphQlTypes/DeleteMessageThread';

const useDeleteMessageThread = (
  options?: MutationHookOptions<DeleteMessageThread, DeleteMessageThreadVariables>,
) => {
  const mutation = useMutation<DeleteMessageThread, DeleteMessageThreadVariables>(
    DELETE_MESSAGE_THREAD,
    options,
  );

  return mutation;
};

const DELETE_MESSAGE_THREAD = gql`
  mutation Common_DeleteMessageThread($id: ID!) {
    deleteMessageThread(id: $id) {
      success
    }
  }
`;

export default useDeleteMessageThread;
