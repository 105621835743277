import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Text from 'components/lib/ui/Text';

import useTheme from 'lib/hooks/useTheme';

const Value = styled(Text).attrs(maskClassProps)<{ $color?: string }>`
  color: ${({ $color }) => $color ?? 'inherit'};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  value: number | null | undefined;
  children: React.ReactNode;
  className?: string;
  color?: string;
  defaultZeroColor?: string;
};

const InvestmentsValue = ({
  value,
  children,
  className,
  color: overrideColor,
  defaultZeroColor,
}: Props) => {
  const theme = useTheme();

  return (
    <Value
      className={className}
      $color={
        overrideColor ??
        (() => {
          if (RA.isNotNil(value) && value > 0) {
            return theme.color.green;
          }
          if (RA.isNotNil(value) && value < 0) {
            return theme.color.red;
          } else {
            return defaultZeroColor ?? theme.color.text;
          }
        })()
      }
    >
      {children}
    </Value>
  );
};

export default InvestmentsValue;
