import getColorPalette from 'common/lib/theme/getColorPalette';
import staticTheme from 'common/lib/theme/staticTheme';
import type { ThemeContext } from 'common/lib/theme/types';

export const makeTheme = (context: ThemeContext) => {
  const { uiTheme, isV2ThemeEnabled } = context;
  const color = getColorPalette(context);

  const spacing = {
    ...staticTheme.spacing,
    gutter: isV2ThemeEnabled ? '16px' : '20px', // used for spacing between containers
  };

  return {
    ...staticTheme,
    spacing,
    uiTheme,
    isV2ThemeEnabled,
    color,
  } as const;
};
