import { AnimatePresence } from 'framer-motion';
import type { DateTime } from 'luxon';
import React, { useCallback } from 'react';

import BudgetCardControls from 'components/plan/onboarding/BudgetCardControls';
import OnboardingAmountCell from 'components/plan/onboarding/OnboardingAmountCell';
import type { BudgetedRowData } from 'components/plan/onboarding/OnboardingBudgetCardRow';
import OnboardingBudgetCardRow, {
  OnboardingCardRowAnimatedWrapper,
} from 'components/plan/onboarding/OnboardingBudgetCardRow';
import OnboardingCard from 'components/plan/onboarding/OnboardingCard';

import { filterVisibleRows } from 'lib/budget/onboardingAdapters';
import type { PlanSectionType } from 'lib/plan';
import type { PlanGroupData } from 'lib/plan/Adapters';

import type { CreateCategoryInput } from 'common/generated/graphql';
import { BudgetVariability, CategoryGroupType } from 'common/generated/graphql';

export type GroupDataWithBudgetedValue = PlanGroupData & {
  budgeted?: number | null;
  type?: CategoryGroupType;
} & {
  rows: BudgetedRowData[];
};

type Props = {
  group: GroupDataWithBudgetedValue;
  thisMonth: DateTime;
  initiallyOpen?: boolean;
  onAddCategory: (input?: Partial<CreateCategoryInput>) => void;
  onEditGroup: () => void;
  onDeleteGroup: () => void;
  onEditCategory: (categoryId: string) => void;
  onDeleteCategory: (categoryId: string) => void;
  onHideCategoryFromBudget: (categoryId: string) => void;
  updateBudgetedValue: (
    itemId: string,
    date: DateTime,
    sectionType: PlanSectionType,
    isCategoryGroupRow: boolean,
  ) => (amount: number, applyToFuture: boolean) => void;
};

const OnboardingBudgetCard = ({
  group,
  onAddCategory,
  onEditGroup,
  onDeleteGroup,
  onEditCategory,
  onDeleteCategory,
  onHideCategoryFromBudget,
  updateBudgetedValue,
  thisMonth,
  initiallyOpen,
}: Props) => {
  const { name, rows, id, budgeted, canBeBudget, type } = group;

  // Hide the group controls when using this component to show fix/flex/non-monthly groupings
  const isFixFlexGroup = Object.values(BudgetVariability).includes(id as BudgetVariability);

  // Fix/Flex is only possible inside expense groups
  const sectionType = !isFixFlexGroup && type ? type : CategoryGroupType.EXPENSE;

  const handleAddCategory = useCallback(() => {
    const groupId = isFixFlexGroup ? undefined : id;
    const budgetVariability = isFixFlexGroup ? (id as BudgetVariability) : undefined;

    onAddCategory({ group: groupId, type: sectionType, budgetVariability });
  }, [onAddCategory, isFixFlexGroup, id]);

  const onChangeGroupAmount = useCallback(
    (amount: Maybe<number>, appliedToFuture: boolean) => {
      if (amount) {
        updateBudgetedValue(id, thisMonth, sectionType, true)(amount, appliedToFuture);
      }
    },
    [updateBudgetedValue, thisMonth, sectionType, id],
  );

  const onChangeRowAmount = useCallback(
    (rowId: string, amount: number, appliedToFuture: boolean) => {
      updateBudgetedValue(rowId, thisMonth, sectionType, false)(amount, appliedToFuture);
    },
    [updateBudgetedValue, thisMonth, sectionType],
  );

  const visibleRows = filterVisibleRows(rows);

  return (
    <OnboardingCard
      title={name}
      titleAccessory={
        <BudgetCardControls
          showGroupControls={!isFixFlexGroup}
          onAddCategory={handleAddCategory}
          onEditGroup={onEditGroup}
          onDeleteGroup={onDeleteGroup}
        />
      }
      rightNode={
        <OnboardingAmountCell
          canEdit={canBeBudget}
          isGroup
          itemId={id}
          value={budgeted ?? 0}
          onChange={onChangeGroupAmount}
        />
      }
      initiallyOpen={initiallyOpen}
    >
      <AnimatePresence initial={false}>
        {visibleRows.map((rowData) => (
          <OnboardingCardRowAnimatedWrapper key={rowData.id}>
            <OnboardingBudgetCardRow
              rowData={rowData}
              onEditCategory={() => onEditCategory(rowData.id)}
              onHideFromBudget={() => onHideCategoryFromBudget(rowData.id)}
              onDeleteCategory={() => onDeleteCategory(rowData.id)}
              isIncome={sectionType === CategoryGroupType.INCOME}
              onChange={(amount: Maybe<number>, appliedToFuture: boolean) => {
                if (amount) {
                  onChangeRowAmount(rowData.id, amount, appliedToFuture);
                }
              }}
            />
          </OnboardingCardRowAnimatedWrapper>
        ))}
      </AnimatePresence>
    </OnboardingCard>
  );
};

export default React.memo(OnboardingBudgetCard);
