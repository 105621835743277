import type { FetchResult } from '@apollo/client';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

const LOGO_SIZE_PX = 56;

const LogoSection = styled(FlexContainer)`
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const LogoContainer = styled(FlexContainer).attrs({ center: true })`
  width: ${LOGO_SIZE_PX}px;
  height: ${LOGO_SIZE_PX}px;
`;

const ChooseLogoContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.default};
`;

const RemoveButtonContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

type LogoSettingsProps = {
  logo?: string | null;
  id: string;
  onUpload: (file: File, id: string) => Promise<void>;
  onDelete: () => Promise<FetchResult>;
  getLogoComponent: (url: string | null, size: number) => React.ReactNode;
  isCustomImage?: boolean;
};

const LogoSettings = ({
  logo,
  id,
  onUpload,
  onDelete,
  getLogoComponent,
  isCustomImage = undefined,
}: LogoSettingsProps) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const formHasCustomizedLogo = isCustomImage !== undefined ? isCustomImage : !!logo;

  const chooseFile = () => inputFile?.current?.click();

  const handleFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const [file] = Array.from(event.target.files);
    if (!file) {
      return;
    }

    setLoading(true);
    await onUpload(file, id);
    setLoading(false);
  };

  const handleDeleteLogo = async () => {
    setLoading(true);
    await onDelete();
    setLoading(false);
  };

  return (
    <LogoSection>
      <HiddenFileInput
        ref={inputFile}
        onChange={handleFileSelected}
        accept=".jpg,.jpeg,.png,.heif"
      />

      <LogoContainer>
        <Switch>
          <Case when={loading}>
            <LoadingSpinner />
          </Case>
          <Case default>{getLogoComponent(logo ?? null, LOGO_SIZE_PX)}</Case>
        </Switch>
      </LogoContainer>

      <ChooseLogoContainer>
        <DefaultButton onClick={chooseFile} disabled={loading}>
          Choose photo
        </DefaultButton>
      </ChooseLogoContainer>
      {!!logo && formHasCustomizedLogo && (
        <RemoveButtonContainer>
          <DefaultButton onClick={handleDeleteLogo} disabled={loading}>
            Remove
          </DefaultButton>
        </RemoveButtonContainer>
      )}
    </LogoSection>
  );
};

export default LogoSettings;
