import { startCase } from 'lodash';
import * as R from 'ramda';
import { useEffect, useMemo } from 'react';

import { useFormContext } from 'common/components/form/FormContext';

import { getDisplayErrorsForField } from 'common/lib/form/errors';
import type { ValidationOptions } from 'common/lib/form/validation';
import { validationOptionKeys } from 'common/lib/form/validation';
import useUnmount from 'common/lib/hooks/useUnmount';
import useUpdateEffect from 'common/lib/hooks/useUpdateEffect';

export type FieldProps<ValueT> = ValidationOptions & {
  name: string;
  label?: string;
  /** Called when this field's value in FormContext changes. Use this sparingly, most state should
   * only be kept in Form and accessed using useContext(FormContext) instead. */
  onChange?: (value: ValueT) => void;
  /** Hide all client side validation errors for this field, but still show field-level errors from backend after submit */
  onlyShowApiErrors?: boolean;
};

/**
 * Hook used by custom Field components, such as TextField, DateField, etc.
 * to interface with FormContext and handle validation, display errors, and more.
 *
 * If you are just trying to access the value of a field in form state, you
 * should use useFormContext() instead.
 */
const useField = <ValueT>(props: FieldProps<ValueT>) => {
  const { name, label = startCase(name), onChange, onlyShowApiErrors } = props;
  const {
    handleChange,
    handleBlur,
    getFieldMeta,
    registerFieldProps,
    unregisterFieldProps,
    getIdForFieldName,
    setFieldValue,
    setFieldTouched,
    fieldApiErrors,
  } = useFormContext();

  const validationOptions: ValidationOptions = useMemo(
    () => R.pick(validationOptionKeys, props),
    [props],
  );

  const id = getIdForFieldName(name);
  const { value, error, touched } = getFieldMeta<ValueT>(name);

  const displayErrors = getDisplayErrorsForField({
    name,
    touched,
    validationError: error,
    fieldApiErrors,
    onlyShowApiErrors,
  });

  useEffect(() => {
    registerFieldProps(validationOptions);
  }, [registerFieldProps, validationOptions]);

  useUnmount(() => unregisterFieldProps(validationOptions));

  useUpdateEffect(() => {
    onChange?.(value);
  }, [value]);

  return {
    id,
    label,
    value,
    displayErrors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  };
};

export default useField;
