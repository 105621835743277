import storage from 'redux-persist/lib/storage';
import { createReducer } from 'typesafe-actions';

import {
  setCurrentUploadSession,
  mergeCurrentUploadSession,
  clearCurrentUploadSession,
} from 'actions';
import type { UploadStatementSession } from 'state/statements/types';

export const statementsPersistConfig = {
  key: 'statements',
  storage,
  whitelist: ['currentSession'],
};

const INITIAL_STATE: StatementsState = {};

export type StatementsState = {
  currentSession?: UploadStatementSession;
};

const statements = createReducer<StatementsState>(INITIAL_STATE)
  .handleAction(setCurrentUploadSession, (state, { payload: currentSession }) => ({
    ...state,
    currentSession,
  }))
  .handleAction(mergeCurrentUploadSession, (state, { payload: mergeSession }) => ({
    ...state,
    currentSession: state.currentSession && { ...state.currentSession, ...mergeSession },
  }))
  .handleAction(clearCurrentUploadSession, (state) => ({ ...state, currentSession: undefined }));

export default statements;
