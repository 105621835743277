import React from 'react';
import styled from 'styled-components';

import Pill from 'components/lib/ui/Pill';

import {
  getBackgroundColorForClientsListRowStatus,
  getTextColorForClientsListRowStatus,
  getTextForClientsListRowStatus,
} from 'common/lib/sponsorship';
import type {
  SubscriptionSponsorshipStatusType,
  SubscriptionSponsorshipInviteStatusType,
} from 'common/lib/sponsorship';

const Root = styled(Pill)`
  width: max-content;
`;

type Props = {
  inviteStatus: SubscriptionSponsorshipInviteStatusType;
  sponsorshipStatus?: SubscriptionSponsorshipStatusType | null;
};

const ClientStatusPill = ({ inviteStatus, sponsorshipStatus }: Props) => (
  <Root
    backgroundColor={getBackgroundColorForClientsListRowStatus(inviteStatus, sponsorshipStatus)}
    textColor={getTextColorForClientsListRowStatus(inviteStatus, sponsorshipStatus)}
  >
    {getTextForClientsListRowStatus(inviteStatus, sponsorshipStatus)}
  </Root>
);

export default ClientStatusPill;
