import React, { useMemo } from 'react';
import { FiFacebook, FiTwitter, FiLinkedin, FiMail } from 'react-icons/fi';
import styled from 'styled-components';

import ClickToCopy from 'components/lib/ui/ClickToCopy';
import Text from 'components/lib/ui/Text';
import ShareLinkItem from 'components/settings/referrals/ShareLinkItem';

import {
  formatReferralCode,
  getShareTwitterUrl,
  getShareFacebookUrl,
  getShareLinkedInUrl,
  getShareMailUrl,
} from 'common/lib/referral';
import typewriter from 'lib/analytics/typewriter';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useTheme from 'lib/hooks/useTheme';

import { FACEBOOK_COLOR, TWITTER_COLOR, LINKEDIN_COLOR } from 'common/constants/social';

const LinkContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: ${({ theme }) => theme.color.grayBackground};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  gap: 1px;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    grid-template-columns: 1fr;
  }
`;

const CopyLinkContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const CopyLinkText = styled(Text).attrs({ weight: 'medium' })`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  code: string;
};

const ShareLinkSection = ({ code }: Props) => {
  const isReferralPromoCopyChangesActive = useIsFeatureFlagOn('referral-promo-test-copy-changes');
  const referralUrl = useMemo(() => formatReferralCode(code), [code]);
  const theme = useTheme();

  return (
    <div>
      <LinkContainer>
        <ShareLinkItem
          icon={<FiFacebook fill={theme.color.textWhite} color="transparent" />}
          title="Share on Facebook"
          color={FACEBOOK_COLOR}
          href={getShareFacebookUrl(referralUrl, { isReferralPromoCopyChangesActive })}
          platform="facebook"
        />
        <ShareLinkItem
          icon={<FiLinkedin fill={theme.color.textWhite} color="transparent" />}
          title="Share on LinkedIn"
          color={LINKEDIN_COLOR}
          href={getShareLinkedInUrl(referralUrl)}
          platform="linkedin"
        />
        <ShareLinkItem
          icon={<FiTwitter fill={theme.color.textWhite} color="transparent" />}
          title="Share on Twitter"
          color={TWITTER_COLOR}
          href={getShareTwitterUrl(referralUrl, { isReferralPromoCopyChangesActive })}
          platform="twitter"
        />
        <ShareLinkItem
          icon={<FiMail color={theme.color.textWhite} />}
          title="Share via Email"
          color={theme.color.orange}
          href={getShareMailUrl(referralUrl, { isReferralPromoCopyChangesActive })}
          platform="email"
        />
      </LinkContainer>
      <CopyLinkContainer>
        <CopyLinkText>Copy your referral link and share it anywhere</CopyLinkText>
        <ClickToCopy
          tooltipPlace="left"
          afterCopy={() => typewriter.referralCodeShared({ method: 'copy' })}
        >
          {referralUrl}
        </ClickToCopy>
      </CopyLinkContainer>
    </div>
  );
};

export default ShareLinkSection;
