import { DateTime } from 'luxon';

import { getQuarterForDate, isoDateToMonthAbbreviation } from 'common/utils/date';

import type { Timeframe } from 'common/constants/timeframes';

type ChartDateOptions = {
  showFirstQuarterWithYear?: boolean;
};

export const objectToColorDataKeys = (obj: Record<string, number | string>) =>
  Object.entries(obj).map(([key, val]) => ({ name: key, color: val }));

export const getChartDateFromTimeframe =
  (timeframe: Timeframe, options: ChartDateOptions = {}) =>
  (date: string) => {
    const parsedDate = DateTime.fromISO(date);
    const { showFirstQuarterWithYear } = options;
    const q = `Q${getQuarterForDate(parsedDate.startOf('quarter'))}`;

    switch (timeframe) {
      case 'year':
        return parsedDate.toFormat('yyyy');
      case 'month':
        return isoDateToMonthAbbreviation(date);
      case 'quarter':
        if (q === 'Q1' && showFirstQuarterWithYear) {
          return `${q} ${parsedDate.toFormat('yyyy')}`;
        } else {
          return q;
        }
      case 'day':
        return parsedDate.toFormat('MMM d');
      default:
        return date;
    }
  };
