import { DateTime } from 'luxon';
import storageSession from 'redux-persist/lib/storage/session';
import { createReducer } from 'typesafe-actions';

import { setFilters } from 'actions';
import { DATE_RANGES, NET_WORTH_CHART_TYPES } from 'common/lib/accounts/accountCharts';
import { ChartType } from 'lib/ui/charts';
import type { FilterState } from 'state/filters/types';

import { DEFAULT_ORDER } from 'common/constants/merchants';
import { DEFAULT_TIMEFRAME } from 'common/constants/timeframes';

// cashFlowSankey will exist only during the "ab-test-cash-flow-sankey-default" A/B test
export const INITIAL_FILTERS_STATE: FilterState = {
  merchants: { order: DEFAULT_ORDER, search: '' },
  cashFlow: {
    date: DateTime.local().startOf(DEFAULT_TIMEFRAME).toISODate(),
    timeframe: DEFAULT_TIMEFRAME,
    view: ChartType.CashFlowBreakdown,
    sankey: 'category',
    breakdown: 'category',
    tags: [],
    accounts: [],
  },
  cashFlowSankey: {
    date: DateTime.local().startOf(DEFAULT_TIMEFRAME).toISODate(),
    timeframe: DEFAULT_TIMEFRAME,
    view: ChartType.CashFlowSankey,
    sankey: 'category',
    breakdown: 'category',
    tags: [],
    accounts: [],
  },
  accounts: {
    timeframe: 'month',
    dateRange: DATE_RANGES[0].display,
    accountType: null,
    chartType: NET_WORTH_CHART_TYPES[0],
  },
};

export const filters = createReducer<typeof INITIAL_FILTERS_STATE>(
  INITIAL_FILTERS_STATE,
).handleAction(setFilters, (state, { payload: { key, filters } }) => ({
  ...state,
  [key]: filters,
}));

export const filterPersistConfig = {
  key: 'persistentFilter',
  storage: storageSession,
};

export default filters;
