import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import FormContext, { useFormContext } from 'common/components/form/FormContext';
import DateField from 'components/lib/form/DateField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import CardFooter from 'components/lib/ui/CardFooter';
import DateRangePickerShortcuts, {
  SHORTCUTS_WIDTH_PX,
} from 'components/lib/ui/DateRangePickerShortcuts';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { DEFAULT_SHORTCUTS } from 'common/lib/dateRange';
import { color, fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';

import type DateRange from 'common/types/DateRange';

type FormValues = { startDate: Maybe<DateTime>; endDate: Maybe<DateTime> };

const MENU_HEADER_HEIGHT_PX = 50;
const DATE_INPUT_WIDTH_PX = 360;

const Row = styled.div`
  display: grid;
  grid-template-columns: ${SHORTCUTS_WIDTH_PX}px 1fr;
`;

const Column = styled.div`
  &:not(:last-child) {
    border-right: 1px solid ${color.grayBackground};
    overflow: clip;
  }

  &:nth-child(2) {
    width: ${DATE_INPUT_WIDTH_PX}px;
    padding: ${spacing.large};
  }
`;

const Title = styled(Text)`
  display: flex;
  align-items: center;
  width: ${SHORTCUTS_WIDTH_PX}px;
  border-bottom: 1px solid ${color.grayBackground};
  padding: 0 ${spacing.default};
  height: 100%;
`;

const Footer = styled(CardFooter).attrs({ $size: 'small' })`
  ${DefaultButton}:first-child {
    margin-right: auto;
  }
`;

const Header = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  height: ${MENU_HEADER_HEIGHT_PX}px;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.small};
`;

const InputContainer = styled(FlexContainer).attrs({ column: true, justifyCenter: true })`
  flex: 1;
  height: 100%;
  gap: ${spacing.large};
`;

const StyledTextButton = styled(TextButton)`
  font-size: ${fontSize.small};
  padding: 0;
`;

export type Props = {
  dateRange: DateRange;
  requiredBoundedRange?: boolean;
  onChange: (dateRange: DateRange) => void;
  onClear: () => void;
  toggleOpen?: () => void;
};

const DateRangeInputContainer: React.FC<Props> = ({
  dateRange,
  requiredBoundedRange,
  onChange,
  onClear,
  toggleOpen,
}) => (
  <Form
    initialValues={dateRange}
    onSubmit={(values) => {
      onChange(values);
      toggleOpen?.();
    }}
    validate={(values) => {
      const errors: Partial<Record<keyof FormValues, string>> = {};

      if (requiredBoundedRange) {
        if (!values.startDate) {
          errors.startDate = 'Start date is required';
        }

        if (!values.endDate) {
          errors.endDate = 'End date is required';
        }
      }

      if (
        values.startDate &&
        values.endDate &&
        DateTime.fromISO(values.startDate) > DateTime.fromISO(values.endDate)
      ) {
        errors.startDate = 'Start date must be before end date';
      }

      return errors;
    }}
  >
    <Row>
      <Column>
        <Header>
          <Title>Date Range</Title>
        </Header>
        <DateRangePickerShortcutsField />
      </Column>
      <Column>
        <InputContainer>
          <FormContext.Consumer>
            {({ setFieldValue }) => (
              <>
                <DateField
                  label="Start date"
                  name="startDate"
                  placeholder="MM/DD/YYYY"
                  small
                  labelRight={
                    <StyledTextButton
                      onClick={() => {
                        setFieldValue('startDate', undefined);
                      }}
                    >
                      Clear
                    </StyledTextButton>
                  }
                />
                <DateField
                  label="End date"
                  name="endDate"
                  placeholder="MM/DD/YYYY"
                  small
                  labelRight={
                    <StyledTextButton
                      onClick={() => {
                        setFieldValue('endDate', undefined);
                      }}
                    >
                      Clear
                    </StyledTextButton>
                  }
                />
              </>
            )}
          </FormContext.Consumer>
        </InputContainer>
      </Column>
    </Row>
    <Footer>
      <DefaultButton
        size="xsmall"
        onClick={() => {
          onClear();
          toggleOpen?.();
        }}
      >
        Clear
      </DefaultButton>
      <DefaultButton size="xsmall" onClick={toggleOpen}>
        Cancel
      </DefaultButton>
      <FormSubmitButton size="xsmall">Apply</FormSubmitButton>
    </Footer>
  </Form>
);

const DateRangePickerShortcutsField = () => {
  const { setFieldValue, submitForm } = useFormContext();
  return (
    <DateRangePickerShortcuts
      shortcuts={DEFAULT_SHORTCUTS}
      onSelectDateRange={async (dateRange) => {
        await setFieldValue('startDate', dateRange.startDate);
        await setFieldValue('endDate', dateRange.endDate);
        await submitForm();
      }}
    />
  );
};

export default React.memo(DateRangeInputContainer);
