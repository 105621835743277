import { gql, useMutation } from '@apollo/client';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useCallback } from 'react';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import type {
  Mobile_UpdateGoalV2,
  Mobile_UpdateGoalV2Variables,
} from 'common/generated/graphQlTypes/Mobile_UpdateGoalV2';

/**
 * Hook used to update a goal and update the cache optimistically.
 */
const useUpdateGoal = <T extends { __typename: 'GoalV2'; id: string; name: string }>(
  goal: T | null,
) => {
  const [updateGoalMutation, mutationInfo] = useMutation<
    Mobile_UpdateGoalV2,
    Mobile_UpdateGoalV2Variables
  >(UPDATE_GOAL_MUTATION);

  const updateGoal = useCallback(
    (info: {
      name?: string;
      imageStorageProvider?: string;
      imageStorageProviderId?: string;
      targetAmount?: number;
      plannedMonthlyContribution?: number;
      plannedMonthlyPretaxContribution?: number;
      startingAmount?: number;
    }) => {
      if (!goal) {
        return;
      }

      updateGoalMutation({
        variables: {
          input: {
            id: goal.id,
            ...info,
          },
        },
        optimisticResponse: {
          updateGoalV2: {
            __typename: 'UpdateGoal',
            // @ts-ignore typescript doesn't like undefined in info, but we filter it out
            goal: {
              ...goal,
              ...R.pickBy(RA.isNotNil, info),
              estimatedCompletionMonth: null,
            },
            errors: null,
          },
        },
      });
    },
    [goal, updateGoalMutation],
  );

  return { updateGoal, ...mutationInfo };
};

const UPDATE_GOAL_MUTATION = gql`
  mutation Mobile_UpdateGoalV2($input: UpdateGoalInput!) {
    updateGoalV2(input: $input) {
      goal {
        id
        name
        imageStorageProvider
        imageStorageProviderId
        targetAmount
        estimatedCompletionMonth
        plannedMonthlyContribution
        plannedMonthlyPretaxContribution
        startingAmount
        completionPercent
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default useUpdateGoal;
