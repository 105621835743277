import React, { useContext } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import CurrencyField from 'components/lib/form/CurrencyField';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';

import { spacing } from 'common/lib/theme/dynamic';

import type { StyleMixin } from 'common/types/Styles';
import AmountType from 'types/AmountType';

export const amountMixin: StyleMixin = ({ theme }) => `
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.xlarge};
`;

const AmountField = styled(CurrencyField)<{ $green: boolean }>`
  && {
    margin-bottom: 0;
  }

  input {
    padding: 0;
    padding-right: ${spacing.xsmall};
    margin-right: -${spacing.xsmall};

    text-align: right;
    width: 160px;
    border-color: transparent;
    color: ${({ $green, theme }) => ($green ? theme.color.green : theme.color.text)};
    ${({ theme }) => amountMixin({ theme })};
  }
`;

const TransactionDrawerAmountField = () => {
  const { getFieldMeta } = useContext(FormContext);
  const amountType = getFieldMeta('amountType').value as AmountType;

  return (
    <AmountField
      {...sensitiveClassProps}
      key={amountType} // re-render when type changes to ensure the new prefix gets picked up
      name="amount"
      hideLabel
      $green={amountType === AmountType.Credit}
      maskOptions={{
        allowDecimal: true,
        prefix: amountType === AmountType.Credit ? '+$' : undefined,
      }}
    />
  );
};

export default TransactionDrawerAmountField;
