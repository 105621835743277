import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo, useEffect } from 'react';
import type { Column } from 'react-table';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import CsvTable from 'components/accounts/CsvTable';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import Scroll from 'components/lib/ui/Scroll';
import Text from 'components/lib/ui/Text';
import Table from 'components/lib/ui/table/Table';

import { mergeCurrentUploadSession } from 'actions';
import { sortByOrder, sortCategoryGroups } from 'common/lib/categories/Adapters';
import { escapeFieldName, unescapeFieldName } from 'common/lib/form/field';
import useQuery from 'common/lib/hooks/useQuery';
import { MINT_CATEGORY_COLUMN_INDEX } from 'lib/csv/validation';
import useDispatch from 'lib/hooks/useDispatch';
import useModal from 'lib/hooks/useModal';

import { gql } from 'common/generated/gql';
import type { ArrayType } from 'common/types/utility';

const TABLE_INITIAL_STATE = { sortBy: [{ id: 'transactionCount', desc: true }] };

export const ADD_EXPENSE_CATEGORY_VALUE = 'add_expense_category';
export const ADD_INCOME_CATEGORY_VALUE = 'add_income_category';

const PREVIEW_ROW_COUNT = 100;

const Root = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  max-width: 765px;
  width: 100%;
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyledSubmitButton = styled(FormSubmitButton)`
  width: 100%;
  max-width: 438px;
  margin-top: 0;
`;

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSelectField = styled(SelectField)`
  min-width: 280px;
  text-align: left;
`;

const TableContainer = styled.div`
  width: 100%;
  text-align: left;
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  border-radius: ${({ theme }) => theme.radius.medium};
  margin: ${({ theme }) => theme.spacing.xxlarge} 0;

  > * {
    margin-bottom: 0;
  }
`;

const LeftAlignHeader = styled(Text).attrs({ align: 'left ' })`
  display: block;
`;

const SelectFooterButton = styled.div`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
  border-top: 1px solid ${({ theme }) => theme.color.grayFocus};
  color: ${({ theme }) => theme.color.blue};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.blueDark};
  }
`;

const PreviewModalCard = styled(ModalCard)`
  overflow: hidden;
`;

const PreviewScroll = styled(Scroll)`
  max-height: 60vh;
`;

const ClickableText = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: ${({ theme }) => theme.spacing.xxsmall};

  :hover {
    color: ${({ theme }) => theme.color.blue};
  }
`;

const Subtitle = styled(Text)`
  text-align: center;
`;

/** Saves form values in redux in case the user leaves the page and comes back */
const CacheFormValues = () => {
  const dispatch = useDispatch();
  const { values } = useFormContext<{ [key: string]: string }>();

  useEffect(() => {
    dispatch(mergeCurrentUploadSession({ accountNameMapping: values }));
  }, [values, dispatch]);

  return null;
};

type CategorySelectFieldProps = {
  name: string;
  options: any;
  isLoading: boolean;
};

const CategorySelectField = ({ name, options, isLoading }: CategorySelectFieldProps) => {
  const { setFieldValue } = useFormContext();
  const fieldName = escapeFieldName(name);

  const onFooterButtonClick = (value: string) => {
    setFieldValue(fieldName, value);
    const element = document.activeElement;
    if (element instanceof HTMLElement) {
      element.blur(); // Get select menu to close
    }
  };

  return (
    <>
      <StyledSelectField
        autoScrollToTop
        name={fieldName}
        hideLabel
        placeholder="Select a category..."
        isLoading={isLoading}
        options={[
          ...(options ?? []),
          {
            label: 'Add new',
            options: [
              { label: 'Add as a new expense category', value: ADD_EXPENSE_CATEGORY_VALUE },
            ],
          },
          {
            label: 'Add new',
            options: [{ label: 'Add as a new income category', value: ADD_INCOME_CATEGORY_VALUE }],
          },
        ]}
        required
        menuFooterComponent={
          <>
            <SelectFooterButton
              onClick={() => {
                onFooterButtonClick(ADD_EXPENSE_CATEGORY_VALUE);
              }}
            >
              Add as a new expense category
            </SelectFooterButton>
            <SelectFooterButton
              onClick={() => {
                onFooterButtonClick(ADD_INCOME_CATEGORY_VALUE);
              }}
            >
              Add as a new income category
            </SelectFooterButton>
          </>
        }
      />
    </>
  );
};

const optionFromCategory = ({ name, icon }: any) => ({
  value: name,
  label: `${icon} ${name}`,
});

type Props = {
  parsedRows?: string[][];
  categoryMapping?: Record<string, string>;
  onSubmit: (values: Record<string, any>) => Promise<any>;
};

const ImportMintMapCategories = ({ parsedRows, onSubmit, categoryMapping }: Props) => {
  const header = useMemo(() => parsedRows?.[0] ?? [], [parsedRows]);

  const rowsByCategory = useMemo(() => {
    const rows = R.drop(1, parsedRows ?? []).filter((val) => val.length > 1) as string[][];
    const groupedByCategory: Record<string, any[]> = {};
    rows.forEach((row) => {
      const category = row[MINT_CATEGORY_COLUMN_INDEX] ?? [];
      if (!groupedByCategory[category]) {
        groupedByCategory[category] = [];
      }
      groupedByCategory[category] = [...groupedByCategory[category], row];
    });

    return groupedByCategory;
  }, [parsedRows]);

  const tableData = useMemo(
    () =>
      R.toPairs(rowsByCategory).map(([name, rows]) => ({
        name,
        transactionCount: rows.length,
      })),
    [rowsByCategory],
  );

  const { data, isLoadingInitialData, refetch } = useQuery(CATEGORIES_QUERY);
  const { categories = [], categoryGroups = [] } = data ?? {};

  const initialValues = useMemo(
    () =>
      categoryMapping ||
      R.fromPairs(
        R.toPairs(rowsByCategory).map(([categoryName]) => [
          escapeFieldName(categoryName),
          categories.find((x) => x.name.toLowerCase() === categoryName.toLowerCase())?.name ??
            ADD_EXPENSE_CATEGORY_VALUE,
        ]),
      ),
    [categoryMapping, categories],
  );

  const [PreviewRowsModal, { open: openPreviewRowsModal, context: previewingAccountName }] =
    useModal<string>();

  const options = useMemo(() => {
    const sortedGroups = sortCategoryGroups(categoryGroups);

    return sortedGroups.map(({ name, categories }) => ({
      label: name,
      options: sortByOrder(categories ?? []).map(optionFromCategory),
    }));
  }, [categoryGroups]);

  const columns = useMemo(
    (): Column<ArrayType<typeof tableData>>[] => [
      {
        accessor: 'name',
        Header: 'Mint Category',
      },
      {
        id: 'account',
        Header: () => <LeftAlignHeader>Monarch Category</LeftAlignHeader>,
        accessor: 'name',
        defaultCanSort: false,
        Cell: ({ value }) => (
          <CategorySelectField name={value} options={options} isLoading={isLoadingInitialData} />
        ),
      },
      {
        accessor: 'transactionCount',
        Header: 'Transactions',
        sortDescFirst: true,
        Cell: ({ value, row }) => (
          <ClickableText onClick={() => openPreviewRowsModal(row.original.name)}>
            {value}
          </ClickableText>
        ),
      },
    ],
    [isLoadingInitialData, categories, refetch, openPreviewRowsModal],
  );

  return (
    <Root>
      <Title>Assign Mint categories to Monarch categories</Title>
      <Subtitle>
        Assign all of your Mint transaction categories to Monarch’s existing categories, or
        automatically create new categories. You can customize the name and emoji for your
        categories later.
      </Subtitle>
      <StyledForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        transformValuesBeforeSubmit={(values) => ({
          mapping: R.pipe(RA.renameKeysWith(unescapeFieldName))(values),
        })}
      >
        <CacheFormValues />
        <TableContainer>
          <Table
            columns={columns}
            data={tableData}
            showFooter={false}
            initialState={TABLE_INITIAL_STATE}
            empty={{
              title: 'No Mint categories found in your CSV upload',
              subtitle:
                'You can continue to the next step. If you think this is an error, make sure there is a column titled “Category” in your CSV and try again.',
            }}
          />
        </TableContainer>

        <StyledSubmitButton size="large" disableWhenValuesUnchanged={false}>
          Next
        </StyledSubmitButton>
      </StyledForm>
      <PreviewRowsModal large>
        <PreviewModalCard title={previewingAccountName}>
          <PreviewScroll>
            <CsvTable
              header={header}
              rows={R.take(PREVIEW_ROW_COUNT, rowsByCategory[previewingAccountName ?? ''] ?? [])}
            />
          </PreviewScroll>
        </PreviewModalCard>
      </PreviewRowsModal>
    </Root>
  );
};

const CATEGORIES_QUERY = gql(`
  query Web_GetCategories {
    categoryGroups {
      id
      name
      order
      type
      groupLevelBudgetingEnabled
      categories {
        id
        name
        order
        icon
      }
    }
    categories {
      id
      name
      order
      icon
      group {
        id
        type
      }
    }
  }
`);

export default ImportMintMapCategories;
