import * as R from 'ramda';
import React, { useMemo } from 'react';
import type { Column } from 'react-table';
import styled from 'styled-components';

import Empty, { Theme as EmptyTheme } from 'components/lib/ui/Empty';
import FlexContainer from 'components/lib/ui/FlexContainer';
import RelativeDate from 'components/lib/ui/RelativeDate';
import Text from 'components/lib/ui/Text';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import Table from 'components/lib/ui/table/Table';

import routes from 'constants/routes';

import type { Web_GetAdvisorClientsPage_subscriptionSponsorAccessGrants } from 'common/generated/graphQlTypes/Web_GetAdvisorClientsPage';

const StyledButtonText = styled.span`
  white-space: nowrap;
`;

type Props = {
  isLoading: boolean;
  accessGrants: Web_GetAdvisorClientsPage_subscriptionSponsorAccessGrants[];
};

const SubscriptionSponsorAccessGrantsList = ({ isLoading, accessGrants }: Props) => {
  const isEmpty = !isLoading && R.isEmpty(accessGrants);

  const columns: Column<Web_GetAdvisorClientsPage_subscriptionSponsorAccessGrants>[] = useMemo(
    () => [
      {
        accessor: 'householdGivingAccessName',
        Header: 'Household',
        Cell: ({ value, row }) => (
          <FlexContainer column>
            <Text>{value}</Text>
            <Text color="textLight" size="small">
              Access expires <RelativeDate date={row.original.expiresAt} />
            </Text>
          </FlexContainer>
        ),
      },
      {
        accessor: 'userGivingAccessId',
        Header: 'Access',
        disableSortBy: true,
        Cell: ({ value }) => (
          <FlexContainer justifyEnd>
            <DefaultButton
              linkTo={routes.actAsUser({
                id: value,
              })}
              target="_blank"
            >
              <ButtonIcon name="external-link" />
              <StyledButtonText>View household</StyledButtonText>
            </DefaultButton>
          </FlexContainer>
        ),
      },
      {
        accessor: 'id',
        Cell: ({ value }) => <></>,
        // TODO: Add menu + revoke
      },
    ],
    [],
  );

  return (
    <>
      {isEmpty && <Empty emptyTheme={EmptyTheme.NEW} title="No access-only clients yet" />}
      {!isEmpty && (
        <Table columns={columns} data={accessGrants} isLoading={isLoading} showFooter={false} />
      )}
    </>
  );
};

export default SubscriptionSponsorAccessGrantsList;
