import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import BudgetSummary from 'components/budget/BudgetSummary';
import CreateBudgetButton from 'components/budget/CreateBudgetButton';
import DashboardWidget from 'components/lib/ui/DashboardWidget';
import Flex from 'components/lib/ui/Flex';
import Text from 'components/lib/ui/Text';

import { getMonthProgressForMonthStart } from 'common/lib/budget/Progress';
import useBudgetData from 'common/lib/hooks/budget/useBudgetData';
import { formatCurrencyNoCents } from 'common/utils/Currency';

import routes from 'constants/routes';

const EmptyStateContainer = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xlarge};
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
`;

const Bold = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const Action = styled(Flex)`
  padding-top: ${({ theme }) => theme.spacing.xlarge};
  padding-bottom: ${({ theme }) => theme.spacing.xxxlarge};
`;

const BudgetDashboardWidget = () => {
  const history = useHistory();
  const startDate = useMemo(() => DateTime.local().startOf('month'), []);

  const { budgetSummaryData, isLoadingInitialData, shouldShowSafeToSpend, safeToSpend } =
    useBudgetData(startDate, {});

  const ticks = [{ percent: getMonthProgressForMonthStart(startDate) }];
  const navigateToBudget = () => {
    history.push(routes.budget({ queryParams: { introModal: true } }));
  };

  return (
    <DashboardWidget
      title="Budget"
      description={
        shouldShowSafeToSpend
          ? `${formatCurrencyNoCents(safeToSpend)} safe to spend`
          : startDate.toFormat('MMMM')
      }
      loading={isLoadingInitialData}
      headerLink={routes.budget()}
    >
      {budgetSummaryData ? (
        <BudgetSummary
          data={budgetSummaryData}
          ticks={ticks}
          shouldShowSafeToSpend={shouldShowSafeToSpend}
        />
      ) : (
        <Flex center full>
          <EmptyStateContainer>
            <Bold>You don’t have a budget yet!</Bold>
            <Text color="textLight">We’ll create one for you based on your spending history.</Text>
            <Action center>
              <CreateBudgetButton date={startDate} onCompleted={navigateToBudget} />
            </Action>
          </EmptyStateContainer>
        </Flex>
      )}
    </DashboardWidget>
  );
};

export default BudgetDashboardWidget;
