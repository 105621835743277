import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

const DEFAULT_SIZE_PX = 32;

const calculateIconPadding = ({ $size }: { $size: number }) => `${$size / 2}px`;

const Root = styled(FlexContainer).attrs({ center: true })<{ $size: number; $url: Maybe<string> }>`
  background: ${({ theme, $url }) => ($url ? `url("${$url}")` : theme.color.grayFocus)};
  background-position: center;
  background-size: cover;
  border-radius: ${({ theme }) => theme.radius.round};
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
`;

const PlaceholderIcon = styled(Icon).attrs({ name: 'building' })<{ $size: number }>`
  color: ${({ theme }) => theme.color.textLight};
  width: calc(100% - ${calculateIconPadding});
  height: calc(100% - ${calculateIconPadding});
`;

type Props = {
  size?: number;
  logo?: string | null;
  className?: string;
};

const SecurityLogo = ({ logo, className, size = DEFAULT_SIZE_PX }: Props) => (
  <Root
    className={className}
    $size={size}
    $url={RA.isNotNil(logo) ? `data:image/png;base64,${logo}` : null}
  >
    {R.isNil(logo) && <PlaceholderIcon $size={size} />}
  </Root>
);

export default SecurityLogo;
