import { gql } from '@apollo/client';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';

import type { AccountMaskFieldsFragment } from 'common/generated/graphql';

const BULLET_UNICODE = `\u2022 `;

const Root = styled.span`
  color: ${({ theme }) => theme.color.textLight};
`;

type Props = {
  account: AccountMaskFieldsFragment;
  /** If no mask, show subtype instead. Defaults to true. */
  fallbackToSubtype?: boolean;
  className?: string;
};

const AccountMask = ({
  account: {
    mask,
    subtype: { display },
  },
  fallbackToSubtype = true,
  className,
}: Props) => (
  <Root className={className}>
    <span {...sensitiveClassProps}>
      {
        // eslint-disable-next-line
        mask ? [R.repeat(BULLET_UNICODE, 4), mask] : fallbackToSubtype ? display : ''
      }
    </span>
  </Root>
);

AccountMask.fragments = {
  AccountMaskFields: gql`
    fragment AccountMaskFields on Account {
      id
      mask
      subtype {
        display
      }
    }
  `,
};

export default AccountMask;
