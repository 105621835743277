import React from 'react';

import CancelSubscriptionReasonModal from 'components/settings/billing/cancel/CancelSubscriptionReasonModal';
import Stack from 'components/utils/Stack';

type Props = {
  isOnFreeTrial?: boolean;
  goBack?: () => void;
  analyticsFreemiumSummaryStatus?: string | null;
};

/**
 * New cancel subscription flow which offers the user a discount before they cancel under certain circumstances.
 *
 * See this doc for flowchart: https://www.notion.so/monarchmoney/In-product-cancel-prevention-c18be836abf843e5855ae490c22642c0
 */
const CancelSubscriptionDiscountFlow = ({
  isOnFreeTrial,
  goBack,
  analyticsFreemiumSummaryStatus,
}: Props) => (
  <Stack
    initial={{
      component: CancelSubscriptionReasonModal,
      props: { isOnFreeTrial, goBack, analyticsFreemiumSummaryStatus },
    }}
  />
);

export default CancelSubscriptionDiscountFlow;
