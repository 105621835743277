import * as R from 'ramda';

const MINT_HEADER = [
  'Date',
  'Description',
  'Original Description',
  'Amount',
  'Transaction Type',
  'Category',
  'Account Name',
  'Labels',
  'Notes',
];
export const MINT_ACCOUNT_NAME_COLUMN_INDEX = 6;
export const MINT_TAGS_COLUMN_INDEX = 7;
export const MINT_CATEGORY_COLUMN_INDEX = 5;

export const UNCATEGORIZED_CATEGORY = 'uncategorized';

/** Returns error message if invalid */
export const validateMintCSV = (parsedRows: string[][]) => {
  if (parsedRows.length <= 1) {
    throw new Error('File has no data.');
  }

  const [header, ...restOfRows] = parsedRows;

  if (!R.equals(header, MINT_HEADER)) {
    throw new Error('Invalid file. Please upload your file from Mint.');
  }

  restOfRows.forEach((row, index) => {
    if (row.length > 1 && !row[MINT_ACCOUNT_NAME_COLUMN_INDEX]) {
      throw new Error(
        `Invalid file. Missing value for account name on row ${index + 1}, column ${
          MINT_ACCOUNT_NAME_COLUMN_INDEX + 1
        }`,
      );
    }
  });
};
