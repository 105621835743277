/** 
 * We fudge the order here to be halfway between the two items where it was dropped.
  This makes it so we don't have to calculate new (integer) orders for every item, we
  just set this temporary value which makes the UI look right and then the network
  request will update `order` to the correct value on every item.

  i.e. [0, 1, 2, 3]
  We move [0] to be in between [2] and [3]
  We set the new order of [0] to be [2.5]  
*/
export const getOptimisticItemOrderForMove = (source: number, destination: number) =>
  destination + (destination > source ? 0.5 : -0.5);
