import React from 'react';
import styled from 'styled-components';

import TextButton from 'components/lib/ui/TextButton';
import type { AbstractButtonProps } from 'components/lib/ui/button/AbstractButton';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const FooterButton = styled(TextButton)`
  padding: ${({ theme }) => theme.spacing.default};
  flex: 1;
`;

const CardFooterTextButton = ({ children, ...props }: AbstractButtonProps) => (
  <Root>
    <FooterButton {...props}>{children}</FooterButton>
  </Root>
);

export default CardFooterTextButton;
