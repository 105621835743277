import React from 'react';
import styled, { css } from 'styled-components';

import isV2Theme from 'common/lib/theme/isV2Theme';

export type Template = string;

export type StyleProps = {
  template: Template;
  xs?: Template;
  sm?: Template;
  md?: Template;
  lg?: Template;
  xl?: Template;
};

const GridStyled = styled.div<StyleProps>`
  --spacing: ${({ theme }) => theme.spacing.gutter};
  display: grid;
  grid-template: ${({ template }) => template};
  gap: var(--spacing);
  padding: var(--spacing);

  @media (max-width: ${({ theme }) => theme.breakPoints.xl}px) {
    grid-template: ${({ xl }) => xl};
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.lg}px) {
    grid-template: ${({ lg }) => lg};
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.md}px) {
    grid-template: ${({ md }) => md};
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    grid-template: ${({ sm }) => sm};
    --spacing: ${({ theme }) => theme.spacing.xsmall};
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    grid-template: ${({ xs }) => xs};
  }
`;

export const GridItem = styled.div<{ area: string; sticky?: boolean }>`
  grid-area: ${({ area }) => area};
  ${({ sticky }) =>
    sticky
      ? css`
          > * {
            position: sticky;
            top: ${isV2Theme('0', 'var(--spacing)')};
          }
        `
      : null};
`;

const Grid = (props: React.PropsWithChildren<StyleProps>) => <GridStyled {...props} />;

export default React.memo(Grid);
