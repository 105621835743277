import type { MutationFunctionOptions } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import TextAreaField from 'components/lib/form/TextAreaField';
import CardFooter from 'components/lib/ui/CardFooter';
import Card from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import {
  CANCEL_SUBSCRIPTION_MODAL_TITLE,
  CANCEL_SUBSCRIPTION_MODAL_SUBTITLE,
} from 'common/constants/copy';

import type {
  Common_CancelSubscriptionForHouseholdMutation,
  Common_CancelSubscriptionForHouseholdMutationVariables,
  PaymentPeriod,
} from 'common/generated/graphql';

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const MainText = styled(Text)<{ $marginBottom?: boolean }>`
  display: inline-block;
  margin-bottom: ${({ $marginBottom, theme }) => ($marginBottom ? theme.spacing.xlarge : '0px')};
`;

export type CancelMutationOptions = MutationFunctionOptions<
  Common_CancelSubscriptionForHouseholdMutation,
  Common_CancelSubscriptionForHouseholdMutationVariables
>;

export type Props = {
  cancelMutation: (options: CancelMutationOptions) => Promise<any>;
  onCancelClick: () => void;
  onCancelSuccess: () => void;
  goBack?: () => void;
  title?: string;
  subtitle?: string;
  confirm?: string;
  displayFormFields?: boolean;
  isOnFreeTrial?: boolean;
  analyticsFreemiumSummaryStatus?: string | null;
  currentPeriodEndsAt?: string | null;
  billingPeriod?: PaymentPeriod | null;
};

const REASONS = [
  'Missing key features I need',
  'Not getting enough value for the price',
  'Connection issues with my financial institutions',
  'Other',
].map((reason) => ({ label: reason, value: reason }));

/**
 * This component is being replaced by CancelSubscriptionFlow.
 *
 * feature flag: web-cancel-discount-flow
 */
const CancelSubscriptionModal = ({
  goBack,
  onCancelClick,
  onCancelSuccess,
  cancelMutation,
  title = CANCEL_SUBSCRIPTION_MODAL_TITLE,
  subtitle = CANCEL_SUBSCRIPTION_MODAL_SUBTITLE,
  confirm = 'Cancel subscription',
  displayFormFields = true,
}: Props) => (
  <Card title={title} onClickBackButton={goBack} hideBottomBorder={false}>
    <Form mutation={cancelMutation} onSubmitSuccess={onCancelSuccess} isInitialValid>
      <CardBody>
        <MainText $marginBottom={displayFormFields}>{subtitle}</MainText>

        {displayFormFields && (
          <>
            <SelectField
              name="reason"
              options={REASONS}
              placeholder="Select a reason for cancelling your subscription..."
            />
            <TextAreaField
              minRows={3}
              name="feedback"
              placeholder="Tell us what we could have done better..."
            />
          </>
        )}
      </CardBody>
      <CardFooter>
        <DefaultButton onClick={onCancelClick}>Cancel</DefaultButton>
        <FormSubmitButton size="small" disableWhenValuesUnchanged={false}>
          {confirm}
        </FormSubmitButton>
      </CardFooter>
    </Form>
  </Card>
);

export default CancelSubscriptionModal;
