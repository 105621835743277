import { createReducer } from 'typesafe-actions';

import { setTemporaryPromoCode, setManageFinancesWithPartner } from 'state/onboarding/actions';

export type OnboardingState = {
  temporaryPromoCode: string | undefined;
  manageFinancesWithPartner?: boolean | undefined;
};

const INITIAL_STATE: OnboardingState = {
  temporaryPromoCode: undefined,
  manageFinancesWithPartner: undefined,
};

const onboarding = createReducer<OnboardingState>(INITIAL_STATE)
  .handleAction(setTemporaryPromoCode, (state, { payload: code }) => ({
    ...state,
    temporaryPromoCode: code,
  }))
  .handleAction(setManageFinancesWithPartner, (state, { payload: manageFinancesWithPartner }) => ({
    ...state,
    manageFinancesWithPartner,
  }));

export default onboarding;
