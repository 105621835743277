import type { Web_GetAllocation_portfolio_allocationSimple } from 'common/generated/graphQlTypes/Web_GetAllocation';

export enum SecurityType {
  STOCK = 'stock',
  ETF = 'etf',
  FIXED_INCOME = 'fixed_income',
  MUTUAL_FUND = 'mutual_fund',
  LOAN = 'loan',
  DERIVATIVE = 'derivative',
  EQUITY = 'equity',
  CASH = 'cash',
  CRYPTOCURRENCY = 'cryptocurrency',
  OTHER = 'other',
}

export type CleanedAllocationEntry = Omit<Web_GetAllocation_portfolio_allocationSimple, 'type'> & {
  type: SecurityType;
};
