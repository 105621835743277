export const zendeskWidgetKey = () => process.env.REACT_APP_ZENDESK_WIDGET_KEY;

export const loadZendesk = () => {
  const existingScript = document.querySelector('script#ze-snippet');
  if (!existingScript) {
    const s = document.createElement('script');
    const d = document;
    const [x] = d.getElementsByTagName('script');

    s.id = 'ze-snippet';
    s.defer = true;
    s.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskWidgetKey()}`;
    x.parentNode.insertBefore(s, x);

    s.onload = () => {
      // Hide the webWidget button that shows by default
      // eslint-disable-next-line no-undef
      zE('webWidget', 'hide');
    };
  }
};
