import { BudgetVariability } from 'common/generated/graphql';

// This is the default variability for a category/group if it's not set
export const DEFAULT_BUDGET_VARIABILITY_IF_UNSET = BudgetVariability.FLEXIBLE;

export const BUDGET_VARIABILITY_ORDER = {
  [BudgetVariability.FIXED]: 0,
  [BudgetVariability.FLEXIBLE]: 1,
  [BudgetVariability.NON_MONTHLY]: 2,
};

export const BUDGET_VARIABILITY_TO_TITLE_MAPPING = {
  [BudgetVariability.FIXED]: 'Fixed',
  [BudgetVariability.FLEXIBLE]: 'Flexible',
  [BudgetVariability.NON_MONTHLY]: 'Non-Monthly',
};

/**
 * During the Budget onboarding the user is asked to review the budget for each variability.
 * This mapping is used to calculated the accumulated amounts based on the onboarding flow steps.
 */
export const BUDGET_VARIABILITY_STACK_MAPPING = {
  [BudgetVariability.FIXED]: [BudgetVariability.FIXED],
  [BudgetVariability.NON_MONTHLY]: [BudgetVariability.FIXED, BudgetVariability.NON_MONTHLY],
  [BudgetVariability.FLEXIBLE]: [
    BudgetVariability.FIXED,
    BudgetVariability.NON_MONTHLY,
    BudgetVariability.FLEXIBLE,
  ],
};
