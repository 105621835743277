import { Duration } from 'luxon';

import useQueryWithCacheExpiration from 'common/lib/hooks/useQueryWithCacheExpiration';

import { gql } from 'common/generated/gql';

// Get a user's product board token to auto-log them in.
// Technically, tokens have an expiration of 12 hours, but we refresh every 1 hour.
// See: https://support.productboard.com/hc/en-us/articles/360058173373-Embed-your-Portal
const useGetProductboardToken = () => {
  const { data } = useQueryWithCacheExpiration(QUERY, {
    cacheExpiration: Duration.fromObject({ hours: 1 }),
  });
  return data?.productBoardToken;
};

export const QUERY = gql(/* GraphQL */ `
  query Common_GetProductboardCode {
    productBoardToken
  }
`);

export default useGetProductboardToken;
