import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import LoggedOutCardPage from 'components/lib/layouts/LoggedOutCardPage';
import Banner from 'components/lib/ui/Banner';

import { useDispatch, useQueryParam } from 'lib/hooks';
import { sendForgotPasswordEmailAction } from 'state/user/thunks';

import routes from 'constants/routes';

const TextLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const SuccessBanner = styled(Banner).attrs({ type: 'success' })``;

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const email = useQueryParam('email') || '';

  const [formSuccessful, setFormSuccessful] = useState<boolean>(false);

  const onSubmit = async (values: { email: string }) => {
    const data = await dispatch(sendForgotPasswordEmailAction(values));
    const externalProviders: string[] = data?.available_external_providers || [];

    if (externalProviders.length > 0) {
      history.push(`${routes.authProviderLogin.path}?providers=${externalProviders}`);
    } else {
      setFormSuccessful(true);
    }
  };

  return (
    <LoggedOutCardPage
      name="Forgot Password"
      displayTitle="Forgot Password"
      description="Enter the email address for your account so we can send you reset instructions."
    >
      <Form initialValues={{ email }} isInitialValid onSubmit={onSubmit}>
        {formSuccessful ? (
          <SuccessBanner>
            Thank you. If an account exists with this email address, you should receive an email to
            reset your password. Otherwise, please contact support.
          </SuccessBanner>
        ) : (
          <>
            <TextField name="email" label="Email Address" email required />
            <TextLink to={routes.login()}>Wait, I remember my password</TextLink>
            <FormSubmitButton disableWhenValuesUnchanged={false}>
              Send Reset Instructions
            </FormSubmitButton>
          </>
        )}
      </Form>
    </LoggedOutCardPage>
  );
};

export default ForgotPassword;
