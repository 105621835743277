import { useEffect } from 'react';

import useToggle from 'common/lib/hooks/useToggle';
import useScript from 'lib/hooks/useScript';

import type {
  TAppleID,
  AppleSignInSuccessResponse,
  AppleSignInErrorResponse,
} from 'types/external/AppleID';

declare global {
  interface Window {
    AppleID: TAppleID;
  }
}

const APPLE_ID_SCRIPT_URL =
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

type Options = {
  state?: string;
  onSuccess: (data: AppleSignInSuccessResponse) => void;
  onError?: (error: AppleSignInErrorResponse) => void;
};

/**
 * Loads Apple ID script and initializes it with our sign-in params.
 *
 * How to test locally: https://bit.ly/mnrch-test-apple-id
 * */
const useAppleId = ({ state, onSuccess, onError }: Options) => {
  const [initialized, { setOn: setInitialized }] = useToggle(false);
  const [isScriptLoading] = useScript(APPLE_ID_SCRIPT_URL);

  useEffect(() => {
    if (isScriptLoading === false) {
      window.AppleID?.auth.init({
        clientId: process.env.REACT_APP_APPLE_SIGN_IN_CLIENT_ID ?? '',
        scope: 'name email',
        redirectURI:
          process.env.REACT_APP_DEV_SERVER_PUBLIC_URL ??
          `${window.location.protocol}//${window.location.host}`,
        usePopup: true,
        state,
      });
      setInitialized();
    }
  }, [isScriptLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClick = async <TEvent extends React.SyntheticEvent>(event?: TEvent) => {
    event?.preventDefault();

    try {
      const data = await window.AppleID?.auth.signIn();
      onSuccess(data);
    } catch (e: any) {
      onError?.(e);
    }
  };

  return { isLoaded: initialized, onClick };
};

export default useAppleId;
