import { gql, useMutation } from '@apollo/client';
import type { MutationHookOptions } from '@apollo/client';

import type {
  CreateThreadMutation,
  CreateThreadMutationVariables,
} from 'common/generated/graphQlTypes/CreateThreadMutation';

const useCreateMessageThread = (options?: MutationHookOptions<CreateThreadMutation>) => {
  const mutation = useMutation<CreateThreadMutation, CreateThreadMutationVariables>(
    CREATE_THREAD_MUTATION,
    options,
  );

  return mutation;
};

const CREATE_THREAD_MUTATION = gql`
  mutation Common_CreateThreadMutation($agentType: String) {
    createMessageThread(agentType: $agentType) {
      messageThread {
        id
      }
    }
  }
`;

export default useCreateMessageThread;
