import { rgba } from 'polished';
import * as R from 'ramda';
import React, { useRef, useMemo } from 'react';
import { FiChevronDown, FiX } from 'react-icons/fi';
import type { GroupType, Props as SelectProps, SelectComponentsConfig } from 'react-select';
import ReactSelect, { components } from 'react-select';
import type { AsyncProps } from 'react-select/async';
import ReactSelectAsync from 'react-select/async';
import ReactSelectAsyncCreatable from 'react-select/async-creatable';
import type { CreatableProps } from 'react-select/creatable';
import ReactSelectCreatable from 'react-select/creatable';
import type { DefaultTheme } from 'styled-components';
import styled, { css } from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import { MenuItem } from 'components/lib/ui/menu/Menu';

import { getOptionValue } from 'lib/form/Select';
import useTheme from 'lib/hooks/useTheme';

import type { OptionType } from 'types/select';

export type Props<T extends OptionType = OptionType> = SelectProps<T> &
  Partial<AsyncProps<T>> &
  // Note: Current version of react-select has buggy onCreateOption
  // https://github.com/JedWatson/react-select/issues/3988
  // https://github.com/JedWatson/react-select/issues/4122
  CreatableProps<T> & {
    options?: T[] | GroupType<T>[];
    /** If you pass a string here it will be used to lookup the option with matching value */
    value?: string[] | string | T | null;
    loadOptions?: (search: string) => Promise<T[] | GroupType<T>[]>;
    className?: string;
    hideUntilHover?: boolean;
    small?: boolean;
    isCreatable?: boolean;
    isClearable?: boolean;
    autoScrollToTop?: boolean;
    menuHeaderComponent?: React.ReactNode;
    menuFooterComponent?: React.ReactNode;
    components?: SelectComponentsConfig<T>;
    renderOption?: (option: T, isSelected?: boolean) => React.ReactNode;
    onHoverOption?: (option: T) => void;
    innerRef?: React.Ref<ReactSelect>;
  };

const getPadding = (small?: boolean, large?: boolean) => {
  let verticalValue = '1px';
  if (small) {
    verticalValue = '1px';
  }
  if (large) {
    verticalValue = '3.5px';
  }

  return `${verticalValue} 4px`;
};

const getFontSize = (theme: DefaultTheme, small?: boolean, large?: boolean) => {
  let value: string = theme.fontSize.base;
  if (large) {
    value = theme.fontSize.large;
  }
  return value;
};

const Root = styled.div<{ hideUntilHover?: boolean; small?: boolean; large?: boolean }>`
  font-size: ${({ theme, small, large }) => getFontSize(theme, small, large)};
  line-height: 150%;

  .react-select {
    &__indicators {
      transition: ${({ theme }) => theme.transition.default};
      margin-right: 8px;
      color: ${({ theme }) => theme.color.textLight};
    }

    &__placeholder {
      white-space: nowrap;
    }
    &__control {
      transition: ${({ theme }) => theme.transition.default};
      padding: ${({ small, large }) => getPadding(small, large)};
      min-height: 32px;
      background-color: ${({ theme }) => theme.color.white};

      &--is-focused {
        .react-select__indicators {
          color: ${({ theme }) => theme.color.text};
        }
      }

      &--is-disabled {
        background: ${({ theme }) => rgba(theme.color.gray, 0.1)};
        color: ${({ theme }) => theme.color.textLight};
        border-color: ${({ theme }) => theme.color.grayLight};

        .react-select__single-value {
          color: ${({ theme }) => theme.color.textLight};
        }
      }

      &:not(.react-select__control--is-focused):hover {
        border-color: ${({ theme }) => rgba(theme.color.grayDark, 0.4)};
        .react-select__indicators {
          color: ${({ theme }) => theme.color.textLight};
        }
      }
    }
    &__value-container {
      ${({ small }) =>
        small &&
        css`
          padding: 0 8px;
        `}

      &--is-multi.react-select__value-container--has-value {
        padding: 1px 6px;
      }
    }
    &__multi-value {
      overflow: hidden;
      background-color: ${({ theme }) => theme.color.grayBackground};
      border-radius: ${({ theme }) => theme.radius.small};

      &__remove {
        transition: ${({ theme }) => theme.transition.default};
        color: ${({ theme }) => theme.color.textLight};

        &:hover {
          background-color: ${({ theme }) => theme.color.grayLight};
          color: ${({ theme }) => theme.color.text};
        }
      }
    }

    ${({ hideUntilHover }) =>
      hideUntilHover &&
      css`
        &__control:not(.react-select__control--is-focused) {
          border-color: transparent;

          .react-select__indicators {
            display: none;
          }
        }
        &__control:hover .react-select__indicators {
          display: flex;
        }
      `}
  }
`;

const LoadingIndicatorContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing.small};
`;

// Have to use static theme instead of prop because it doesn't get passed from react-select
const Menu = styled(components.Menu).attrs((props) => ({
  ...props,
  styledTheme: props.selectProps.styledTheme,
}))`
  &&& {
    border: 1px solid ${({ styledTheme }) => styledTheme.color.grayLight};
    box-shadow: 0 8px 16px
      ${({ styledTheme }) =>
        styledTheme.uiTheme === 'dark' ? rgba('#000', 0.2) : rgba(styledTheme.color.black, 0.08)};
    border-radius: ${({ styledTheme }) => styledTheme.radius.small};
    overflow: hidden;
    background: ${({ styledTheme }) => styledTheme.color.white};
  }

  .react-select {
    &__menu-list {
      padding-top: 0;
    }

    &__group {
      padding-top: 0;
      padding-bottom: 0;

      &-heading {
        background: ${({ styledTheme }) => styledTheme.color.grayBackground};
        color: ${({ styledTheme }) => styledTheme.color.textLight};
        font-size: ${({ styledTheme }) => styledTheme.fontSize.small};
        font-weight: ${({ styledTheme }) => styledTheme.fontWeight.medium};
        position: sticky;
        top: 0;
        padding: 6px 10px;
        padding-left: ${({ styledTheme }) => styledTheme.spacing.large};
        text-transform: none;
        z-index: 1; /* stylelint-disable-line plugin/no-z-index */
      }
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  margin: ${({ theme }) => theme.spacing.xxsmall} ${({ theme }) => theme.spacing.xsmall};
  :first-child {
    margin-top: ${({ theme }) => theme.spacing.xsmall};
  }
  :last-child {
    margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const DropdownIndicator = (props: React.ComponentProps<typeof components.DropdownIndicator>) => (
  <FiChevronDown size={16} />
);

const Option = (props: React.ComponentProps<typeof components.Option>) => (
  <StyledMenuItem
    ref={props.innerRef}
    {...props.innerProps}
    selected={props.isSelected}
    focused={props.isFocused}
    disabled={props.isDisabled}
    highlightOnHover={false}
    onMouseOver={() => props.selectProps.onHoverOption?.(props.data)}
    onFocus={() => props.selectProps.onHoverOption?.(props.data)}
  >
    {props.selectProps.renderOption ? (
      props.selectProps.renderOption(props.data)
    ) : (
      <Flex alignCenter>
        {props.data.icon}
        {props.children}
      </Flex>
    )}
  </StyledMenuItem>
);

const SingleValue = (props: React.ComponentProps<typeof components.SingleValue>) => (
  <components.SingleValue {...R.omit(['onMouseMove', 'onMouseOver'], props)}>
    {props.selectProps.renderOption ? (
      props.selectProps.renderOption(props.data, true)
    ) : (
      <Flex alignCenter>
        {props.data.icon}
        {props.children}
      </Flex>
    )}
  </components.SingleValue>
);

const MultiValueLabel = (props: React.ComponentProps<typeof components.MultiValueLabel>) => (
  <components.MultiValueLabel {...R.omit(['onMouseMove', 'onMouseOver'], props)}>
    <Flex alignCenter>
      {props.data.icon}
      {props.children}
    </Flex>
  </components.MultiValueLabel>
);

const MultiValueRemove = (props: React.ComponentProps<typeof components.MultiValueRemove>) => (
  <components.MultiValueRemove {...props}>
    <FiX size={14} />
  </components.MultiValueRemove>
);

const MenuList = (props: React.ComponentProps<typeof components.MenuList>) => {
  const { selectProps } = props;
  const { autoScrollToTop } = selectProps;

  const ref = useRef<HTMLDivElement>(null);
  const otherProps = autoScrollToTop ? { innerRef: ref } : {};

  return (
    <>
      {props.selectProps.menuHeaderComponent}
      <components.MenuList {...props} {...otherProps}>
        {props.children}
      </components.MenuList>
      {props.selectProps.menuFooterComponent}
    </>
  );
};

const LoadingIndicator = () => (
  <LoadingIndicatorContainer>
    <LoadingSpinner $size={20} />
  </LoadingIndicatorContainer>
);

const Select = <T extends OptionType>({
  className,
  autoScrollToTop = false,
  hideUntilHover,
  small = false,
  large = false,
  loadOptions,
  isClearable,
  isCreatable,
  components = {},
  value,
  innerRef,
  ...props
}: Props<T>) => {
  const theme = useTheme();

  const sharedProps = useMemo(
    (): SelectProps<T> => ({
      inputId: props.name,
      ...props,
      closeMenuOnSelect: props.closeMenuOnSelect ?? !props.isMulti, // Don't close menu on select for multi selects
      ref: innerRef,
      value: getOptionValue<T>(value, props.options ?? []),
      classNamePrefix: 'react-select',
      components: {
        IndicatorSeparator: null,
        DropdownIndicator,
        Option,
        SingleValue,
        MultiValueLabel,
        MultiValueRemove,
        MenuList,
        LoadingIndicator,
        // @ts-ignore react-select doesn't like styled-components
        Menu,
        ...components,
      },
      isClearable,
      styledTheme: theme,
      autoScrollToTop,
      theme: (selectTheme) => ({
        ...selectTheme,
        borderRadius: 4,
        colors: {
          ...selectTheme.colors,
          primary: theme.color.blue,
          primary25: theme.color.blueBackground,
          primary50: rgba(theme.color.blue, 0.5),
          primary75: rgba(theme.color.blue, 0.75),
          neutral20: theme.color.grayFocus,
          neutral50: theme.color.textLight,
          neutral80: theme.color.text,
          danger: theme.color.red,
          dangerLight: theme.color.redLight,
        },
      }),
    }),
    [innerRef, props, theme, components, theme],
  );

  let component: JSX.Element;
  if (isCreatable && loadOptions) {
    component = <ReactSelectAsyncCreatable {...sharedProps} loadOptions={loadOptions} />;
  } else if (isCreatable) {
    component = <ReactSelectCreatable {...sharedProps} />;
  } else if (loadOptions) {
    component = <ReactSelectAsync {...sharedProps} loadOptions={loadOptions} />;
  } else {
    component = <ReactSelect {...sharedProps} />;
  }

  return (
    <Root hideUntilHover={hideUntilHover} className={className} small={small} large={large}>
      {component}
    </Root>
  );
};

export default Select;
