import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import NestedDropdownMenu from 'components/lib/ui/NestedDropdownMenu';
import NestedDropdownSubMenu from 'components/lib/ui/NestedDropdownSubMenu';
import Toggle from 'components/lib/ui/Toggle';

import { forceRefreshEnabledStatus } from 'actions';
import { getForceRefreshEnabledStatus } from 'selectors';

import type {
  DisconnectCredentialMutation,
  DisconnectCredentialMutationVariables,
} from 'common/generated/graphQlTypes/DisconnectCredentialMutation';

const EmployeeMenuForceRefresh = () => {
  const dispatch = useDispatch();

  const forceRefreshEnabled = useSelector(getForceRefreshEnabledStatus);
  const history = useHistory();
  const accountPageMatch = useRouteMatch<{ id: string }>('/accounts/details/:id');
  const accountId = accountPageMatch?.params.id as GraphQlUUID;
  const [disconnectCredential] = useMutation<
    DisconnectCredentialMutation,
    DisconnectCredentialMutationVariables
  >(DISCONNECT_ACCOUNT_MUTATION, {
    variables: { accountId },
    onCompleted: (data) => {
      if (!data?.disconnectCredential?.success) {
        // eslint-disable-next-line no-alert
        window.alert('Something wrong happened.');
        return;
      }
      // eslint-disable-next-line no-alert
      window.alert('Credential disconnected, redirecting you to accounts list.');
      history.push('/accounts');
    },
  });

  return (
    <NestedDropdownSubMenu title="Force Refresh">
      <NestedDropdownMenu
        items={[
          {
            title: 'Force enabled status',
            right: (
              <Toggle
                onChange={({ target: { checked } }) => dispatch(forceRefreshEnabledStatus(checked))}
                checked={forceRefreshEnabled}
              />
            ),
          },
          {
            title: 'Disconnect this credential',
            icon: 'zap-off',
            disabled: !accountPageMatch,
            type: 'danger',
            onClick: disconnectCredential,
          },
        ]}
      />
    </NestedDropdownSubMenu>
  );
};

const DISCONNECT_ACCOUNT_MUTATION = gql`
  mutation Web_DisconnectCredentialMutation($accountId: UUID!) {
    disconnectCredential(accountId: $accountId) {
      success
    }
  }
`;

export default EmployeeMenuForceRefresh;
