import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CloseAccountConfirmation from 'components/accounts/CloseAccountConfirmation';
import DeleteAccountConfirmation from 'components/accounts/DeleteAccountConfirmation';
import EditAccountForm from 'components/accounts/EditAccountForm';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';

import useDisplayBalancePreview from 'common/lib/hooks/accounts/useDisplayBalancePreview';
import useQuery from 'common/lib/hooks/useQuery';
import useModal from 'lib/hooks/useModal';

import routes from 'constants/routes';

import { gql as newGql } from 'common/generated/gql';
import type { EditAccountFormFieldsFragment } from 'common/generated/graphql';

type Props = {
  account?: EditAccountFormFieldsFragment;
  accountId?: string;
  onDone: () => void;
  onClose: () => void;
};

const Root = styled(FlexContainer).attrs({ column: true })`
  min-height: 540px;
`;

const LoadingContainer = styled(FlexContainer).attrs({
  full: true,
  alignCenter: true,
  justifyCenter: true,
})`
  flex: 1;
`;

/**
 * Component to display different modals for editing an account. There's the edit account form
 * which needs account data, and the close account and delete account confirmation modals
 * which only requires the account id. A full account can be provided, or the component will fetch
 * it based on the account id.
 */
const AccountEditModal = ({ account, accountId, onDone, onClose }: Props) => {
  const history = useHistory();
  const { displayBalancePreview, refreshDisplayBalancePreview } = useDisplayBalancePreview(
    accountId || account?.id,
  );

  const [
    ConfirmCloseAccountModal,
    { open: openConfirmCloseAccountModal, close: closeConfirmCloseAccountModal },
  ] = useModal();
  const [
    ConfirmDeleteAccountModal,
    { open: openConfirmDeleteAccountModal, close: closeConfirmDeleteAccountModal },
  ] = useModal();
  const [isSearchingZillow, setIsSearchingZillow] = useState(false);

  const { data, isLoadingInitialData } = useQuery(QUERY, {
    variables: { id: accountId ?? '' },
    skip: !!account,
  });

  const accountData = account || data?.account;

  return (
    <ModalCard
      title={!isSearchingZillow ? 'Edit Account' : 'Search for your property'}
      onClickBackButton={isSearchingZillow ? () => setIsSearchingZillow(false) : undefined}
    >
      <Root>
        {!isLoadingInitialData && !!accountData ? (
          <>
            <EditAccountForm
              displayBalancePreview={displayBalancePreview}
              refreshDisplayBalancePreview={refreshDisplayBalancePreview}
              account={accountData}
              onClickDelete={openConfirmDeleteAccountModal}
              onClickCloseAccount={openConfirmCloseAccountModal}
              onClose={onClose}
              onDone={onDone}
              isSearchingZillow={isSearchingZillow}
              setIsSearchingZillow={setIsSearchingZillow}
            />
            <ConfirmCloseAccountModal>
              <CloseAccountConfirmation
                account={accountData}
                onCancel={closeConfirmCloseAccountModal}
                onDone={closeConfirmCloseAccountModal}
              />
            </ConfirmCloseAccountModal>
            <ConfirmDeleteAccountModal>
              <DeleteAccountConfirmation
                account={accountData}
                onCancel={closeConfirmDeleteAccountModal}
                onDelete={() => history.push(routes.accounts())}
              />
            </ConfirmDeleteAccountModal>
          </>
        ) : (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        )}
      </Root>
    </ModalCard>
  );
};

const QUERY = newGql(/* GraphQL */ `
  query Web_AccountEditModal($id: UUID!) {
    account(id: $id) {
      id
      ...EditAccountFormFields
    }
  }
`);

export default AccountEditModal;
