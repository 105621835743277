import { NavLink as RouterNavLink } from 'react-router-dom';
import styled from 'styled-components';

import { HEADER_HEIGHT } from 'components/routes/Header';

import isV2Theme from 'common/lib/theme/isV2Theme';

export const ACTIVE_CLASS_NAME = 'tab-nav-item-active';

/** Used on Page header horizontal menu to navigate between sub routes */
const TabNavLink = styled(RouterNavLink).attrs({ activeClassName: ACTIVE_CLASS_NAME })`
  height: ${isV2Theme('38px', HEADER_HEIGHT)};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.base};

  :hover {
    color: ${({ theme }) => theme.color.text};
  }

  &.${ACTIVE_CLASS_NAME} {
    color: ${({ theme }) => theme.color.orange};
    transition: unset;
    box-shadow: inset 0 -2px 0 0 ${({ theme }) => theme.color.orange};

    &:hover {
      color: ${({ theme }) => theme.color.orange};
    }
  }
`;

export default TabNavLink;
