import type { MutationFunctionOptions } from '@apollo/client';
import type { BaseError } from 'common/errors';
import { DetailedAPIError, FieldValidationAPIError, GraphQlMutationError } from 'common/errors';
import type { FormikConfig, FormikHelpers } from 'formik';
import { useFormik } from 'formik';
import type { ExecutionResult } from 'graphql';
import invariant from 'invariant';
import { debounce, isFunction, isEqual } from 'lodash';
import * as R from 'ramda';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import uuid from 'uuid/v4';
import type * as Yup from 'yup';

import type { FormContextType } from 'common/components/form/FormContext';
import FormContext from 'common/components/form/FormContext';

import { throwIfHasMutationErrors } from 'common/lib/form/errors';
import type { ValidationOptions } from 'common/lib/form/validation';
import { createValidationSchema } from 'common/lib/form/validation';
import usePrevious from 'common/lib/hooks/usePrevious';
import convertGraphQlErrorsToRestFormat from 'common/utils/convertGraphQlErrorsToRestFormat';

type ErrorT = DetailedAPIError | GraphQlMutationError | FieldValidationAPIError | Error;

const isValidError = (error: unknown): error is ErrorT =>
  error instanceof DetailedAPIError ||
  error instanceof GraphQlMutationError ||
  error instanceof FieldValidationAPIError ||
  error instanceof Error;

export type Props<ValuesT, MutationT, MutationArgsT = unknown, OnErrorT extends BaseError = any> = {
  children: React.ReactNode;
  initialValues?: Partial<ValuesT>;
  onSubmit?: (values: ValuesT, formikHelpers: FormikHelpers<ValuesT>) => void | Promise<void>;
  onSubmitSuccess?: (response?: MutationT | never) => void | Promise<void>;
  mutation?: (
    options: MutationFunctionOptions<MutationT, { input: ValuesT } & MutationArgsT>,
  ) => Promise<ExecutionResult<MutationT>>;
  additionalMutationVariables?: MutationArgsT | ((values: ValuesT) => MutationArgsT);
  /** Formik prop see: https://jaredpalmer.com/formik/docs/api/formik#enablereinitialize-boolean */
  enableReinitialize?: boolean;
  /** Formik prop see: https://jaredpalmer.com/formik/docs/api/formik#isinitialvalid-boolean */
  isInitialValid?: boolean;
  /** Whether the first field that has an api error should regain focus after submit */
  shouldRefocusOnError?: boolean;
  /** Submit the form every time a field blurs */
  submitOnBlur?: boolean;
  /** Submit the form every time a field changes. */
  submitOnChange?: boolean;
  /** Defaults to 2000 */
  submitOnChangeDebounceMs?: number;
  /** Reset the form to initial state every time a field blurs */
  resetOnBlur?: boolean;
  /** Escape hatch to provide a custom validation schema. If provided, this will override all validation options on specific fields. */
  overrideValidationSchema?: Yup.ObjectSchema<Record<string, unknown>>;
  /** Custom validation function. If you use this, you should not use overrideValidationSchema or validation options on specific fields. */
  validate?: FormikConfig<ValuesT>['validate'];
  /** Result of this will be passed to onSubmit or the mutation */
  transformValuesBeforeSubmit?: (values: ValuesT) => ValuesT;

  // Props only for components that extend this base component
  focusField: (fieldId: string) => void;
  onError?: (error: OnErrorT) => void;
  onUnhandledError: (error: Error) => void;
};

const BaseForm = <ValuesT extends Record<string, any>, MutationT extends Record<string, any>>({
  children,
  initialValues = {} as ValuesT,
  focusField,
  onSubmit: onSubmitProp,
  mutation,
  additionalMutationVariables,
  onUnhandledError,
  onError,
  onSubmitSuccess,
  enableReinitialize = true,
  isInitialValid,
  shouldRefocusOnError = true,
  submitOnBlur = false,
  submitOnChange = false,
  submitOnChangeDebounceMs = 2000,
  resetOnBlur = false,
  overrideValidationSchema,
  validate,
  transformValuesBeforeSubmit = R.identity,
}: Props<ValuesT, MutationT>) => {
  invariant(
    (onSubmitProp || mutation) && !(onSubmitProp && mutation),
    'Should provide only one of [onSubmit, mutation] to Form.',
  );

  const [generalApiErrors, setGeneralApiErrors] = useState<string[]>([]);
  const clearGeneralApiErrors = useCallback(() => setGeneralApiErrors([]), [setGeneralApiErrors]);

  const [fieldApiErrors, setFieldApiErrors] = useState<{ [name: string]: string[] }>({});
  const clearFieldApiError = useCallback(
    (field: string) => setFieldApiErrors((existing) => ({ ...existing, [field]: [] })),
    [setFieldApiErrors],
  );

  const [fields, setFields] = useState<{ [name: string]: ValidationOptions }>({});

  const [idSuffix] = useState(uuid());
  const getIdForFieldName = (fieldName: string) => `${fieldName}-${idSuffix}`;

  useEffect(() => {
    if (shouldRefocusOnError) {
      focusField(getIdForFieldName(Object.keys(fieldApiErrors)[0]));
    }
  }, [fieldApiErrors, shouldRefocusOnError]);

  const validationSchema = useMemo(
    () =>
      overrideValidationSchema ??
      R.pipe(
        R.keys,
        R.map((name) => fields[name]),
        createValidationSchema,
      )(fields),
    [overrideValidationSchema, fields],
  );

  const onSubmit = useCallback(
    async (submitValues: ValuesT, formikHelpers: FormikHelpers<ValuesT>) => {
      const values = transformValuesBeforeSubmit(submitValues);
      // Clear all errors on submit
      clearGeneralApiErrors();
      setFieldApiErrors({});

      try {
        if (onSubmitProp) {
          await onSubmitProp(values, formikHelpers);
          await onSubmitSuccess?.();
        } else if (mutation) {
          const additionalVariables = isFunction(additionalMutationVariables)
            ? additionalMutationVariables(values)
            : additionalMutationVariables;
          const { data } = await mutation({
            variables: { input: values, ...additionalVariables },
          });

          if (R.isNil(data)) {
            return;
          }

          throwIfHasMutationErrors(data);
          await onSubmitSuccess?.(data);
        }
      } catch (error) {
        if (!isValidError(error)) {
          return;
        }

        // Form only handles errors in 4xx range with a response that it understands. This is one of:
        // - { detail: 'Some error happened' }
        // - { fieldName: ['Invalid email address'] }
        // Everything else is thrown and should be handled elsewhere
        onError?.(error);
        if (error instanceof DetailedAPIError) {
          // General errors
          // i.e. { detail: 'Some error happened' }
          const {
            data: { detail, error_code },
          } = error;

          // Expect user to handle errors with a custom error_code
          !error_code && setGeneralApiErrors(R.flatten([detail]));
        } else if (error instanceof FieldValidationAPIError) {
          setFieldApiErrors(error.data);
        } else if (error instanceof GraphQlMutationError) {
          const { message, fieldErrors } = error.errors;
          if (message) {
            setGeneralApiErrors([message]);
          } else if (fieldErrors) {
            // Field-specific errors
            // i.e. { email: ['Invalid email address'] }
            const data = convertGraphQlErrorsToRestFormat(fieldErrors);
            setFieldApiErrors(data);
          } else {
            onUnhandledError(error);
          }
        } else {
          // Response is not a format Form understands, so we throw the error
          onUnhandledError(error);
        }
      }
    },
    [
      onSubmitProp,
      clearGeneralApiErrors,
      setGeneralApiErrors,
      setFieldApiErrors,
      focusField,
      transformValuesBeforeSubmit,
      additionalMutationVariables,
    ],
  );

  const formikContext = useFormik({
    // @ts-ignore
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize,
    isInitialValid,
    validateOnBlur: false, // Validation is already run when any value changes
    validate,
  });

  const { values, errors } = formikContext;
  const previousValues = usePrevious(values);

  useEffect(() => {
    // Clear all general api errors when any value changes
    clearGeneralApiErrors();

    // Clear api field errors when that field value changes
    Object.keys(values)
      .filter((key) => values[key] !== previousValues?.[key])
      .filter((key) => !!fieldApiErrors[key]?.length)
      .forEach(clearFieldApiError);
  }, [values]);

  useEffect(() => {
    // Needed because of this issue: https://github.com/jaredpalmer/formik/issues/2306
    formikContext.validateForm();
  }, [validationSchema]);

  const debouncedSubmit = useCallback(
    debounce(() => {
      formikContext.submitForm();
    }, submitOnChangeDebounceMs),
    [submitOnChangeDebounceMs],
  );

  const combinedContext: FormContextType<ValuesT> = useMemo(
    () => ({
      ...formikContext,
      errors: { ...errors, ...fieldApiErrors },
      fieldApiErrors,
      generalApiErrors,
      registerFieldProps: (props: ValidationOptions) => {
        if (!isEqual(fields[props.name], props)) {
          setFields((fields) => ({ ...fields, [props.name]: props }));
        }
      },
      unregisterFieldProps: (props: ValidationOptions) =>
        setFields((fields) => R.omit([props.name], fields)),
      getIdForFieldName,
      handleBlur: (...params: Parameters<typeof formikContext.handleBlur>) => {
        formikContext.handleBlur(...params);
        if (submitOnBlur || submitOnChange) {
          formikContext.submitForm();
        }
        if (resetOnBlur) {
          formikContext.resetForm();
        }
      },
      handleChange: (...params: Parameters<typeof formikContext.handleChange>) => {
        formikContext.handleChange(...params);
        if (submitOnChange) {
          debouncedSubmit();
        }
      },
      setFieldValue: async (...params: Parameters<typeof formikContext.setFieldValue>) => {
        formikContext.setFieldValue(...params);
        if (submitOnChange) {
          debouncedSubmit();
        }
      },
    }),
    [
      formikContext,
      fieldApiErrors,
      generalApiErrors,
      fields,
      setFields,
      submitOnBlur,
      submitOnChange,
      resetOnBlur,
    ],
  );

  // @ts-ignore [REACT-NATIVE-UPGRADE] TS error goes away when we upgrade to React 18 in common
  return <FormContext.Provider value={combinedContext}>{children}</FormContext.Provider>;
};

export default BaseForm;
