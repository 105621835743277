import React from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import IconButton from 'components/lib/ui/button/IconButton';

const StyledIconButton = styled(IconButton).attrs({ size: 'medium' })``;

const Root = styled(FlexContainer).attrs({ alignCenter: true })<{ $showBorder: boolean }>`
  ${({ $showBorder, theme }) =>
    $showBorder &&
    css`
      border-bottom: 1px solid ${theme.color.grayBackground};
    `}
`;

const BackButton = styled(StyledIconButton)`
  margin-right: ${({ theme }) => theme.spacing.xxsmall};
`;

const Title = styled(Text).attrs({ size: 'small', weight: 'medium' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing.default};
`;

export type NavigationHeaderProps = {
  title?: React.ReactNode;
  extraNode?: React.ReactNode;
  onClickClose?: () => void;
  onClickBack?: () => void;
  className?: string;
  showBorder?: boolean;
};

/**
 * Component used to display a back button & close button, along with an optional title in a horizontal layout.
 */
const NavigationHeader = ({
  title,
  extraNode,
  onClickClose,
  onClickBack,
  className,
  showBorder,
}: NavigationHeaderProps) => (
  <Root className={className} $showBorder={!!showBorder}>
    {onClickBack && <BackButton onClick={onClickBack} icon="arrow-left" />}
    <Title>{title}</Title>
    {extraNode}
    {onClickClose && <StyledIconButton onClick={onClickClose} icon="x" />}
  </Root>
);

export default NavigationHeader;
