import { gql } from '@apollo/client';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

export const SEARCH_SECURITIES_QUERY = gql`
  query SecuritySearch($search: String!, $limit: Int, $orderByPopularity: Boolean) {
    securities(search: $search, limit: $limit, orderByPopularity: $orderByPopularity) {
      id
      name
      type
      logo
      ticker
      typeDisplay
      currentPrice
      closingPrice
      oneDayChangeDollars
      oneDayChangePercent
    }
  }
`;

export const GET_HOLDING_SUMMARY_SECURITY_DETAILS_QUERY = gql`
  query GetHoldingSummarySecurityDetails($id: ID!) {
    security(id: $id) {
      id
      name
      logo
      ticker
      closingPrice
      currentPrice
      oneDayChangeDollars
    }
  }
`;

export const DELETE_HOLDING_MUTATION = gql`
  mutation Common_DeleteHolding($id: ID!) {
    deleteHolding(id: $id) {
      deleted
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const UPDATE_HOLDING_MUTATION = gql`
  mutation Common_UpdateHolding($input: UpdateHoldingInput!) {
    updateHolding(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      holding {
        id
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const GET_SECURITY_DETAILS_QUERY = gql`
  query GetHoldingDetailsFormSecurityDetails($id: ID!) {
    security(id: $id) {
      id
      name
      logo
      ticker
      type
      closingPrice
      currentPrice
      oneDayChangeDollars
    }
  }
`;
