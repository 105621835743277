import React, { useContext } from 'react';

type FlowContextType = {
  currentIndex: number;
  previousIndex: number | undefined;
  next: (nextProps?: any) => void;
  goBack: () => void;
  /** Skip the next step */
  skipStep: (props?: any) => void;
  /** Skips to the end of the flow, and passes props to the flow's onComplete() */
  skipToComplete: (props?: any) => void;
};

const FlowContext = React.createContext<FlowContextType>(undefined as any);

export const useFlowContext = () => {
  const context = useContext(FlowContext);
  if (!context) {
    throw new Error(
      'useFlowContext used outside of Flow. Ensure you have a <FlowContext.Provider> higher in the tree.',
    );
  }

  return context;
};

export default FlowContext;
