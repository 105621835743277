import { useMutation, useQuery, gql } from '@apollo/client';
import pluralize from 'pluralize';
import * as React from 'react';
import styled from 'styled-components';

import ManualAccountLogo from 'components/accounts/ManualAccountLogo';
import InstitutionLogo from 'components/institutions/InstitutionLogo';
import ActionCard from 'components/lib/ui/ActionCard';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Modal from 'components/lib/ui/Modal';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import type {
  DeleteUserFromHousehold,
  DeleteUserFromHouseholdVariables,
} from 'common/generated/graphQlTypes/DeleteUserFromHousehold';
import type {
  GetAccountsBelongingToUser,
  GetAccountsBelongingToUserVariables,
} from 'common/generated/graphQlTypes/GetAccountsBelongingToUser';

type Props = {
  memberInfo: { name: string; id: string };
  onClose: () => void;
  onDeleteSuccess: () => void;
};

const TextContainer = styled.span`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const AccountActionCard = styled(ActionCard)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
  padding: ${({ theme }) => theme.spacing.default};
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledInstitutionLogo = styled(InstitutionLogo)`
  width: 24px;
  height: 24px;
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const DELETE_USER_FROM_HOUSEHOLD = gql`
  mutation Web_DeleteUserFromHousehold($input: DeleteUserFromHouseholdMutationInput!) {
    deleteUserFromHousehold(input: $input) {
      deleted
      errors {
        message
      }
    }
  }
`;

const QUERY = gql`
  query GetAccountsBelongingToUser($userId: UUID!) {
    accountsBelongingTo(userId: $userId) {
      id
      displayName
      transactionsCount
      credential {
        id
        institution {
          id
          logo
        }
      }
    }
  }
`;

const DeleteMemberModal = ({ memberInfo: { id, name }, onClose, onDeleteSuccess }: Props) => {
  const { data } = useQuery<GetAccountsBelongingToUser, GetAccountsBelongingToUserVariables>(
    QUERY,
    {
      variables: { userId: id },
    },
  );
  const [deleteUserFromHousehold] = useMutation<
    DeleteUserFromHousehold,
    DeleteUserFromHouseholdVariables
  >(DELETE_USER_FROM_HOUSEHOLD);

  return (
    <Modal onClose={onClose}>
      <ModalCard title="Remove Member">
        <FlexContainer margin="large" column>
          <TextContainer>
            <Text>Are you sure you want to remove</Text>
            <Text weight="medium">{` ${name} `}</Text>
            <Text>
              from your household? All of the accounts and transactions they have added will be
              removed, and this action cannot be undone.
            </Text>
          </TextContainer>
          {data?.accountsBelongingTo.map(
            ({ displayName, transactionsCount, credential, id: accountId }) => (
              <AccountActionCard key={accountId}>
                <FlexContainer>
                  {credential?.institution.logo ? (
                    <StyledInstitutionLogo logo={credential.institution.logo} />
                  ) : (
                    <ManualAccountLogo icon="dollar-sign" />
                  )}
                  <Text weight="medium">{displayName}</Text>
                </FlexContainer>
                <Text weight="medium" color="textLight">
                  {pluralize('transaction', transactionsCount, true)}
                </Text>
              </AccountActionCard>
            ),
          )}
          <StyledButton
            onClick={async () => {
              await deleteUserFromHousehold({ variables: { input: { userId: id } } });
              onDeleteSuccess();
            }}
          >
            Remove member from household
          </StyledButton>
        </FlexContainer>
      </ModalCard>
    </Modal>
  );
};

export default DeleteMemberModal;
