import React from 'react';
import styled from 'styled-components';

import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { dangerButtonMixin } from 'components/lib/ui/button/DangerButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';

const ConfirmButton = styled(AsyncButton)`
  ${primaryButtonMixin}
`;

const DangerConfirmButton = styled(AsyncButton)`
  ${dangerButtonMixin}
`;

export type Props = {
  title: string;
  confirm: string;
  isLoading?: boolean;
  useDangerButton?: boolean;
  cancel?: string;
  children: React.ReactNode;

  onCancel?: () => void;
  onConfirm?: () => void;
};

const Confirmation = ({
  title,
  confirm,
  isLoading = false,
  useDangerButton = false,
  cancel,
  children,
  onCancel,
  onConfirm,
}: Props) => (
  <ModalCard title={title}>
    <CardBody>{children}</CardBody>
    <CardFooter>
      <DefaultButton onClick={onCancel} disabled={isLoading}>
        {cancel ?? 'Cancel'}
      </DefaultButton>
      {useDangerButton ? (
        <DangerConfirmButton onClick={onConfirm} pending={isLoading}>
          {confirm}
        </DangerConfirmButton>
      ) : (
        <ConfirmButton onClick={onConfirm} pending={isLoading}>
          {confirm}
        </ConfirmButton>
      )}
    </CardFooter>
  </ModalCard>
);

export default Confirmation;
