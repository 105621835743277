import { AccountTypeName, ManualInvestmentAccountTrackingMode } from 'common/constants/accounts';
import type { ManualInvestmentAccountTrackingModeType } from 'common/constants/accounts';

export const isInvestmentAccount = (type: string) => type === AccountTypeName.BROKERAGE;

export const isManualHoldingsAccount = (
  accountType: string,
  investmentTrackingMode?: ManualInvestmentAccountTrackingModeType,
) =>
  isInvestmentAccount(accountType) &&
  investmentTrackingMode === ManualInvestmentAccountTrackingMode.HOLDINGS;

export const supportsManualHoldings = (account?: {
  isManual: boolean;
  manualInvestmentsTrackingMethod?: string | null;
}) =>
  !account?.isManual ||
  account?.manualInvestmentsTrackingMethod === ManualInvestmentAccountTrackingMode.HOLDINGS;

export const isVehicleAccount = (type: string) => type === AccountTypeName.VEHICLE;
