import { transparentize } from 'polished';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

const DEFAULT_SIZE_PX = 56;

const calculateIconPadding = ({ $size }: { $size: number }) => `${$size / 2}px`;

const Root = styled(FlexContainer).attrs({ center: true })<{ $size: number; $url: Maybe<string> }>`
  background: ${({ theme, $url }) => ($url ? `url("${$url}")` : theme.color.grayFocus)};
  background-position: center;
  background-size: cover;
  border-radius: ${({ theme }) => theme.radius.round};
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  flex-shrink: 0;
  box-shadow: inset 0 0 0 1px ${({ theme }) => transparentize(0.8, theme.color.grayDark)};
`;

const PlaceholderIcon = styled(Icon).attrs({ name: 'building' })<{ $size: number }>`
  color: ${({ theme }) => theme.color.textLight};
  width: calc(100% - ${calculateIconPadding});
  height: calc(100% - ${calculateIconPadding});
`;

type Props = {
  url: Maybe<string>;
  size?: number;
  className?: string;
};

/** Just a component that falls back to default icon when a merchant doesn't have an URL set */
const MerchantLogo = ({ url, className, size = DEFAULT_SIZE_PX }: Props) => (
  <Root className={className} $size={size} $url={url}>
    {R.isNil(url) && <PlaceholderIcon $size={size} />}
  </Root>
);

export default MerchantLogo;
