import { gql } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import AccountMask from 'components/accounts/AccountMask';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import TimeframeTrendIndicator from 'components/lib/ui/TimeframeTrendIndicator';
import Currency from 'components/lib/ui/currency/Currency';

import { invertAmountIfDebtGoal } from 'common/lib/goalsV2/adapters';

import { GoalType } from 'common/constants/goals';
import { DECIMAL_TO_PERCENT } from 'common/constants/math';

import type { WebGoalAccountBalanceRowFieldsFragment } from 'common/generated/graphql';

const ACCOUNT_LOGO_SIZE_PX = 24;
const ACCOUNT_LOGO_LARGE_SIZE_PX = 48;
const LARGE_CLASS_NAME = 'goal-account-balance-row-large';

const Root = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  transition: ${({ theme }) => theme.transition.default};

  :hover {
    background-color: ${({ theme }) => theme.color.grayBackground};
  }

  &.${LARGE_CLASS_NAME} {
    padding: ${({ theme }) => theme.spacing.xlarge};
  }
`;

const TitleContainer = styled(FlexContainer).attrs({ column: true })`
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing.default};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  overflow: hidden;

  .${LARGE_CLASS_NAME} & {
    margin-left: ${({ theme }) => theme.spacing.default};
  }
`;

const StyledTimeframeTrendIndicator = styled(TimeframeTrendIndicator)`
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
`;

const Title = styled(Text)`
  .${LARGE_CLASS_NAME} & {
    font-size: ${({ theme }) => theme.fontSize.large};
  }
`;

const Subtitle = styled(Text).attrs({ size: 'small', color: 'textLight' })`
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

const StyledAccountMask = styled(AccountMask)`
  margin-left: ${({ theme }) => theme.spacing.xxsmall};
`;

const StyledCurrency = styled(Currency)`
  .${LARGE_CLASS_NAME} & {
    font-size: ${({ theme }) => theme.fontSize.large};
  }
`;

const HiddenAccountIcon = styled(Icon).attrs({ name: 'eye-off', size: 14 })`
  margin-left: ${({ theme }) => theme.spacing.small};
`;

type Props = {
  data: WebGoalAccountBalanceRowFieldsFragment;
  goalType: string;
  large?: boolean;
  className?: string;
};

const GoalAccountBalanceRow = ({ data, goalType, large, className }: Props) => {
  const { account, currentAmount, currentMonthChange } = data;
  const { subtype, displayName, icon, logoUrl } = account;
  const isAccountHidden = account.hideFromList || account.deactivatedAt;
  return (
    <Root className={`${className ?? ''} ${large ? LARGE_CLASS_NAME : ''}`}>
      <AccountLogo
        icon={icon}
        logoUrl={logoUrl}
        size={large ? ACCOUNT_LOGO_LARGE_SIZE_PX : ACCOUNT_LOGO_SIZE_PX}
      />
      <TitleContainer>
        <Flex alignCenter>
          <Title clampLines={1}>{displayName}</Title>
          {isAccountHidden && <HiddenAccountIcon />}
        </Flex>
        <Subtitle clampLines={1}>
          {subtype?.display ?? ''} <StyledAccountMask account={account} fallbackToSubtype={false} />
        </Subtitle>
      </TitleContainer>
      <FlexContainer column alignEnd>
        <StyledCurrency value={invertAmountIfDebtGoal(currentAmount, goalType)} />
        <StyledTimeframeTrendIndicator
          percentChange={(currentMonthChange?.percent ?? 0) / DECIMAL_TO_PERCENT}
          startAmount={0}
          endAmount={invertAmountIfDebtGoal(currentMonthChange?.amount, goalType)}
          isAsset={goalType !== GoalType.Debt}
        />
      </FlexContainer>
    </Root>
  );
};

GoalAccountBalanceRow.fragments = {
  WebGoalAccountBalanceRowFields: gql`
    fragment WebGoalAccountBalanceRowFields on GoalAccountAllocation {
      id
      currentAmount
      currentMonthChange {
        percent
        amount
      }
      account {
        id
        displayName
        hideFromList
        deactivatedAt
        icon
        logoUrl
        type {
          name
        }
        ...AccountMaskFields
      }
    }
    ${AccountMask.fragments.AccountMaskFields}
  `,
};

export default GoalAccountBalanceRow;
