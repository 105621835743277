import { useMutation } from '@apollo/client';

import { GET_ACCOUNTS } from 'common/lib/graphQl/accounts';

import { gql as newGql } from 'common/generated/gql';
import type { GetAccountsQuery } from 'common/generated/graphql';

const CREATE_ACCOUNTS_FROM_PLAID_TOKEN = newGql(/* GraphQL */ `
  mutation CreateAccountsFromPlaidToken(
    $publicToken: String!
    $plaidInstitutionId: String!
    $plaidInstitutionName: String!
    $linkSessionId: String!
  ) {
    createAccountsFromPlaidToken(
      publicToken: $publicToken
      plaidInstitutionId: $plaidInstitutionId
      plaidInstitutionName: $plaidInstitutionName
      linkSessionId: $linkSessionId
    ) {
      accounts {
        id
        ...AccountFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

const useCreateAccountsFromPlaidTokenMutation = () => {
  const result = useMutation(CREATE_ACCOUNTS_FROM_PLAID_TOKEN, {
    update(cache, { data }) {
      const accounts = data?.createAccountsFromPlaidToken?.accounts;
      if (accounts) {
        cache.writeQuery({
          query: GET_ACCOUNTS,
          data: { accounts } as GetAccountsQuery,
        });
      }
    },
  });

  return result;
};

export default useCreateAccountsFromPlaidTokenMutation;
