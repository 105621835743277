import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Root = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.textLight};
  background: ${({ theme }) => theme.color.grayFocus};
  padding: 0 ${({ theme }) => theme.spacing.xsmall};

  /* Make pill shaped https://stackoverflow.com/a/18795153 */
  border-radius: 500px;
`;

const Tag = ({ children, ...props }: Props) => <Root {...props}>{children}</Root>;

export default Tag;
