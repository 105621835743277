import React from 'react';
import styled from 'styled-components';

import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import IconButton from 'components/lib/ui/button/IconButton';
import { OverlayTrigger } from 'components/lib/ui/popover';

import { spacing } from 'common/lib/theme/dynamic';

const HoverIconButton = styled(IconButton).attrs({ icon: 'more-horizontal', size: 'xsmall' })`
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  margin-right: ${spacing.small};

  &:hover {
    opacity: 1;
  }
`;

type Props = {
  className?: string;
  isSystemCategory?: boolean;
  onEditCategory: () => void;
  onHideFromBudget: () => void;
  onDeleteCategory: () => void;
};

const BudgetRowControls = ({
  className,
  isSystemCategory,
  onEditCategory,
  onHideFromBudget,
  onDeleteCategory,
}: Props) => (
  <OverlayTrigger
    overlay={
      <DropdownMenu>
        <DropdownMenuItem onClick={onEditCategory}>Edit category</DropdownMenuItem>
        <DropdownMenuItem onClick={onHideFromBudget}>Hide from budget</DropdownMenuItem>
        <DropdownMenuItem type="danger" onClick={onDeleteCategory}>
          {isSystemCategory ? 'Disable' : 'Delete'} category
        </DropdownMenuItem>
      </DropdownMenu>
    }
    placement="bottom-start"
  >
    {({ toggleOpen, isOpen }) => (
      <HoverIconButton onClick={toggleOpen} active={isOpen} className={className} />
    )}
  </OverlayTrigger>
);

export default BudgetRowControls;
