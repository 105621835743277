import { useTreatments } from '@splitsoftware/splitio-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_SPLIT_TREATMENT_VALUE, parseFlagsFromSplit } from 'common/lib/external/splitio';
import type { FeatureFlags } from 'common/lib/external/splitio';
import { GET_ANONYMOUS_ID } from 'common/lib/graphQl/user';
import useQuery from 'common/lib/hooks/useQuery';
import { getSegmentAnonymousIdFromCookie, track } from 'lib/analytics/segment';
import isEnvDevelopment from 'lib/isEnvDevelopment';
import { getSplitAttributes } from 'selectors';
import type { FeatureFlagName } from 'state/features/types';

import { AnalyticsEventNames } from 'common/constants/analytics';

export const useFeatureFlags = <TConfig>(
  names: FeatureFlagName[],
  key: string | undefined = undefined,
): FeatureFlags<TConfig> => {
  const attrs = useSelector(getSplitAttributes);
  const flags = useTreatments(names, attrs, key);
  return parseFlagsFromSplit(flags);
};

export type Options = {
  trackImpression?: boolean;
  /** Whether to add the feature flag impression to Microsoft Clarity as a custom tag. */
  sendToMsClarity?: boolean;
  /** It uses the anonymousId as the key for the feature flag if it is an anonymous experiment */
  isAnonymous?: boolean;
};

const useFeatureFlag = <TConfig>(
  flagName: FeatureFlagName,
  { trackImpression, sendToMsClarity, isAnonymous }: Options = {},
) => {
  const cookieAnonymousId = getSegmentAnonymousIdFromCookie();

  const { data, isNetworkRequestInFlight: isAnonymousIdNetworkRequestInFlight } = useQuery(
    GET_ANONYMOUS_ID,
    {
      fetchPolicy: 'cache-first',
      skip: !isAnonymous, // request anonymous id only if it's an anonymous experiment
    },
  );

  const anonymousId = data?.me?.attributionData?.anonymousId ?? cookieAnonymousId;

  // override the key used to retrieve the feature flag treatment with the anonymous id if the experiment is anonymous
  const key = isAnonymous && anonymousId ? anonymousId : undefined;

  const result = useFeatureFlags<TConfig>([flagName], key)[flagName];
  const { treatment } = result;

  useEffect(() => {
    // do not track if the network request is still in flight - that means the anonymous id hasn't loaded yet
    if (trackImpression && treatment && (!isAnonymousIdNetworkRequestInFlight || !isAnonymous)) {
      track(AnalyticsEventNames.FeatureFlagImpression, { split: flagName, treatment });
    }

    if (sendToMsClarity && window.clarity && treatment !== DEFAULT_SPLIT_TREATMENT_VALUE) {
      sendTagToClarity(flagName, treatment);
    }
  }, [treatment, flagName, trackImpression]);

  return result;
};

const sendTagToClarity = (flagName: string, treatment: string) => {
  const clarityTag = `flag-${flagName}`;
  window.clarity('set', clarityTag, treatment);
  if (isEnvDevelopment()) {
    // eslint-disable-next-line no-console
    console.log('[Clarity] Flag sent as custom tag', { [clarityTag]: treatment });
  }
};

export default useFeatureFlag;
