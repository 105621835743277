import { gql } from '@apollo/client';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import CondensedAdviceItemRow from 'components/advice/CondensedAdviceItemRow';
import DashboardWidget from 'components/lib/ui/DashboardWidget';
import Empty from 'components/lib/ui/Empty';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';

import useMockDataWhenNoAccountsQuery from 'lib/hooks/useMockDataWhenNoAccountsQuery';
import { getDoesNotHaveAccounts } from 'state/emptyState/selectors';

import { HOME_CATEGORY, AdviceItemType, AdviceQuestionnaireName } from 'constants/advice';
import routes from 'constants/routes';

import type { Web_GetAdviceDashboardWidget } from 'common/generated/graphQlTypes/Web_GetAdviceDashboardWidget';

const MAX_DISPLAY_ITEMS = 4;

const StyledCondensedAdviceItemRow = styled(CondensedAdviceItemRow)`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
  :last-child {
    border-radius: 0 0 ${({ theme }) => theme.radius.medium} ${({ theme }) => theme.radius.medium};
  }
`;

const StyledTextButton = styled(TextButton)`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  justify-content: flex-start;
`;

const AdviceDashboardWidget = () => {
  const { push } = useHistory();

  const doesNotHaveAccounts = useSelector(getDoesNotHaveAccounts);

  const { data, isLoadingInitialData } =
    useMockDataWhenNoAccountsQuery<Web_GetAdviceDashboardWidget>(QUERY);
  const { adviceItems: items, profileQuestionnaire } = data ?? {};
  const adviceItems = useMemo(
    () => items?.filter(({ completedAt }) => R.isNil(completedAt)) ?? [],
    [items],
  );
  const { state } = profileQuestionnaire ?? {};
  const { numQuestionsAnswered, completed: questionnaireCompleted } = state ?? {};
  const hasStartedQuestionnaire = (numQuestionsAnswered ?? 0) > 0;

  const showEmptyState = !doesNotHaveAccounts && questionnaireCompleted === false;
  const hasCompletedItems = items?.some(({ completedAt }) => RA.isNotNil(completedAt));
  const hasItemsToDisplay = adviceItems.length > 0;

  return (
    <DashboardWidget
      headerLink={routes.advice({})}
      title="Advice"
      description={
        <Text color={isLoadingInitialData || showEmptyState ? 'textLight' : undefined}>
          Prioritized by you
        </Text>
      }
      loading={isLoadingInitialData}
    >
      <Switch>
        <Case when={showEmptyState}>
          <Empty
            title={`Get personalized advice by ${
              hasStartedQuestionnaire ? 'finishing' : 'answering'
            } a few questions`}
            subtitle="Answers to these questions will allow us to personalize financial advice for your household."
            button={{
              title: hasStartedQuestionnaire ? 'Resume' : 'Get started',
              onClick: () =>
                push(
                  routes.advice.questionnaireIntro({
                    questionnaireName: AdviceQuestionnaireName.General,
                  }),
                ),
            }}
          />
        </Case>
        <Case when={!hasItemsToDisplay && !!hasCompletedItems}>
          <Empty title="No items" subtitle="All of your priorities are complete" />
        </Case>
        <Case when={!hasItemsToDisplay}>
          <Empty title="No items" subtitle="There's nothing to show here yet..." />
        </Case>
        <Case default>
          {R.take(MAX_DISPLAY_ITEMS, adviceItems).map((item) => (
            <StyledCondensedAdviceItemRow
              key={item.id}
              item={item}
              onClick={() =>
                push(routes.advice.itemDetails({ itemId: item.id, category: HOME_CATEGORY }))
              }
            />
          ))}
          {adviceItems.length > MAX_DISPLAY_ITEMS && (
            <StyledTextButton
              linkTo={routes.advice({
                category: HOME_CATEGORY,
                queryParams: { scroll: AdviceItemType.Objective },
              })}
            >
              View all priorities ({adviceItems.length})
            </StyledTextButton>
          )}
        </Case>
      </Switch>
    </DashboardWidget>
  );
};

const QUERY = gql`
  query Web_GetAdviceDashboardWidget {
    adviceItems(group: "objective") {
      id
      ...CondensedAdviceItemRowFields
    }
    profileQuestionnaire(name: "general") {
      state {
        numQuestionsAnswered
        completed
      }
    }
  }
  ${CondensedAdviceItemRow.fragments.CondensedAdviceItemRowFields}
`;

export default AdviceDashboardWidget;
