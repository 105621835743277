import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

type Props = React.ComponentProps<typeof Prompt> & {
  /** Display an alert when user closes tab (browser controls content). Defaults to true. */
  blockWindowClose?: boolean;
};

/**
 * Component to prompt the user before navigating away or closing the tab. This is
 * a wrapper around react-router's <Prompt> component to add onbeforeunload functionality.
 * https://reactrouter.com/core/api/Prompt
 */
const BlockNavigationPrompt = ({ when, message, blockWindowClose = true }: Props) => {
  useEffect(() => {
    if (when && blockWindowClose) {
      window.onbeforeunload = () =>
        // This message is overridden by most browsers so we don't really have control over it.
        // https://stackoverflow.com/questions/2229942/how-to-block-users-from-closing-a-window-in-javascript
        'You have unsaved changes. Are you sure you want to leave this page?';
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [when, blockWindowClose]);

  return <Prompt when={when} message={message} />;
};

export default BlockNavigationPrompt;
