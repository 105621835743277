import * as R from 'ramda';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import type ReactSelect from 'react-select';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import ManualAccountLogo from 'components/accounts/ManualAccountLogo';
import InstitutionLogo from 'components/institutions/InstitutionLogo';
import InstitutionsList from 'components/institutions/InstitutionsList';
import AttachmentField from 'components/lib/form/AttachmentField';
import FieldCell from 'components/lib/form/FieldCell';
import SearchField from 'components/lib/form/SearchField';
import SelectField from 'components/lib/form/SelectField';
import TextAreaField from 'components/lib/form/TextAreaField';
import ToggleField from 'components/lib/form/ToggleField';
import Empty from 'components/lib/ui/Empty';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import TextButton from 'components/lib/ui/TextButton';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';
import RecommendedResolutionSection from 'components/support/RecommendedResolutionSection';

import useRecommendedResolution from 'common/lib/hooks/support/useRecommendedResolution';
import useSupportFormOptions from 'common/lib/hooks/support/useSupportFormOptions';
import useQuery from 'common/lib/hooks/useQuery';
import type { FormValues } from 'common/lib/support/form';
import { shouldShowCredentialField } from 'common/lib/support/form';
import useSearchInstitutions from 'lib/hooks/institutions/useSearchInstitutions';

import * as COPY from 'common/constants/copy';

import { gql } from 'common/generated/gql';

const INSTITUTION_LOGO_SIZE_PX = 16;

const GrantAccessField = styled(ToggleField).attrs({ hideLabel: true })`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const InstitutionLogoContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const InstitutionsListPopover = styled(Popover)`
  width: 500px;
`;

const SpinnerContainer = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacing.large};
  justify-content: center;
`;

const AddNewInstitutionButton = styled(TextButton)`
  width: 100%;
  color: ${({ theme }) => theme.color.blueLight};
  font-size: ${({ theme }) => theme.fontSize.small};
  padding: ${({ theme }) => theme.spacing.large};
`;

const EmptyInstitutionsList = styled(Empty)`
  padding: ${({ theme }) => theme.spacing.large};
`;

const RequestInstitutionIcon = styled(Icon).attrs({ name: 'plus-circle' })`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  onOpenRequestInstitution: (search: string) => void;
};

const ContactSupportInnerForm = ({ onOpenRequestInstitution }: Props) => {
  const [search, setSearch] = useState('');

  const { searchInstitutions, debouncedSearch, isNetworkRequestInFlight } =
    useSearchInstitutions(search);

  const isEmptyResultsState =
    !!debouncedSearch && !isNetworkRequestInFlight && !searchInstitutions.length;

  const { initialValues, isSubmitting, setFieldTouched, setFieldValue, values } =
    useFormContext<FormValues>();
  const { topic, reason, credentialId } = values;

  const { topicOptions, reasonOptions, impactOptions, requiredFields, isLoadingInitialData } =
    useSupportFormOptions({ topic, reason });

  const {
    prefetchRecommendedResolution,
    resolutionBlocks,
    isLoadingRecommendedResolution,
    hasResults: hasRecommendedResults,
  } = useRecommendedResolution({
    reason,
    credentialId,
  });
  const showRecommendedResolutionSection = reason && hasRecommendedResults;

  // Update reason field from initial values after the topic options have been loaded
  const initialReasonSet = useRef(false);
  useEffect(() => {
    if (!initialValues.reason || initialReasonSet.current) {
      return;
    }

    const matchingReason = reasonOptions.find((r) => r.value === initialValues.reason);
    if (!matchingReason) {
      return;
    }

    setFieldValue('reason', matchingReason.value);
    initialReasonSet.current = true;
  }, [topicOptions, reasonOptions, setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const reasonSelectRef = useRef<ReactSelect>(null);
  useEffect(() => {
    if (topic) {
      reasonSelectRef.current?.select.clearValue();
      setFieldValue('reason', undefined);
      setFieldTouched('reason', false);
    }
    // topic is the only dependency we want to watch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  useEffect(() => {
    if (R.any(R.isNil, [topic, reason])) {
      return;
    }

    if (requiredFields.credentialId) {
      // If the new reason requires an institution, reset the touched state of the institution field.
      // This prevents users from seeing error messages from any previous state.
      setFieldTouched('credentialId', !R.isNil(initialValues.credentialId));
    } else {
      // If the new reason does not require an institution and the institution field was not set initially,
      // clear the institution field value so that it's not included when submitting the form.
      setFieldValue(
        'credentialId',
        R.isNil(initialValues.credentialId) ? undefined : initialValues.credentialId,
      );
    }
    // reason and credentialId are the only dependencies we want to watch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reason, requiredFields.credentialId]);

  const { data: credentialsData, isLoadingInitialData: isLoadingCredentials } = useQuery(
    GET_LINKED_CREDENTIALS,
    {
      skip: R.all(R.isNil, [requiredFields.credentialId, initialValues.credentialId]),
    },
  );
  const credentials = R.pathOr<NonNullable<typeof credentialsData>['credentials']>(
    [],
    ['credentials'],
    credentialsData,
  );

  const credentialOptions = useMemo(
    () =>
      credentials.map(({ id, institution: { name, logo } }) => ({
        value: id,
        icon: (
          <InstitutionLogoContainer>
            {logo ? (
              <InstitutionLogo logo={logo} size={INSTITUTION_LOGO_SIZE_PX} />
            ) : (
              <ManualAccountLogo icon="dollar-sign" size={INSTITUTION_LOGO_SIZE_PX} />
            )}
          </InstitutionLogoContainer>
        ),
        label: name,
      })),
    [credentials],
  );

  return (
    <>
      <SelectField
        name="topic"
        label={COPY.SUPPORT.FORM_TOPIC_LABEL}
        placeholder={COPY.SUPPORT.FORM_TOPIC_PLACEHOLDER}
        options={topicOptions}
        isLoading={isLoadingInitialData}
        isDisabled={isSubmitting}
        required
      />
      <SelectField
        name="reason"
        label={COPY.SUPPORT.FORM_REASON_LABEL}
        placeholder={COPY.SUPPORT.FORM_REASON_PLACEHOLDER}
        options={reasonOptions}
        isLoading={isLoadingInitialData}
        isDisabled={isSubmitting}
        innerRef={reasonSelectRef}
        required={!!values.topic}
        noOptionsMessage={() => COPY.SUPPORT.FORM_REASON_NO_OPTIONS_MESSAGE}
        onHoverOption={({ value }: (typeof reasonOptions)[number]) =>
          prefetchRecommendedResolution({ reason: value })
        }
      />
      <SelectField
        name="impact"
        label={COPY.SUPPORT.FORM_IMPACT_LABEL}
        placeholder={COPY.SUPPORT.FORM_IMPACT_PLACEHOLDER}
        options={impactOptions}
        isLoading={isLoadingInitialData}
        isDisabled={isSubmitting}
        required
      />
      {shouldShowCredentialField(requiredFields, topic, reason, initialValues) && (
        <SelectField
          name="credentialId"
          label={COPY.SUPPORT.FORM_INSTITUTION_LABEL}
          options={credentialOptions}
          required={requiredFields.credentialId}
          isLoading={isLoadingCredentials}
          isDisabled={isSubmitting}
          onHoverOption={({ value }: (typeof credentialOptions)[number]) =>
            prefetchRecommendedResolution({ credentialId: value })
          }
        />
      )}

      {requiredFields.institutionName && (
        <>
          <OverlayTrigger
            overlay={({ close }) => (
              <InstitutionsListPopover>
                {search.length > 0 && searchInstitutions.length > 0 && (
                  <InstitutionsList
                    institutions={searchInstitutions.slice(0, 5)}
                    credentialCount={0} // TODO: decide what to do with this
                    search={debouncedSearch}
                    onSelectInstitution={(result) => {
                      setSearch(result.name);
                      setFieldValue('institutionName', result.name);
                      close();
                    }}
                  />
                )}

                {isNetworkRequestInFlight && !searchInstitutions.length && (
                  <SpinnerContainer>
                    <LoadingSpinner />
                  </SpinnerContainer>
                )}

                {isEmptyResultsState && (
                  <>
                    <EmptyInstitutionsList title={`No match for "${debouncedSearch}"`} />
                    <AddNewInstitutionButton
                      onClick={() => onOpenRequestInstitution(debouncedSearch)}
                    >
                      <RequestInstitutionIcon /> Add a new institution
                    </AddNewInstitutionButton>
                  </>
                )}
              </InstitutionsListPopover>
            )}
          >
            {({ open }) => (
              <SearchField
                name="institutionName"
                placeholder="Search institutions"
                autoComplete="off"
                label={COPY.SUPPORT.FORM_INSTITUTION_LABEL}
                required={requiredFields.institutionName}
                disabled={isSubmitting}
                onChange={(value) => {
                  if (value) {
                    open();
                  }
                  setSearch(value);
                }}
              />
            )}
          </OverlayTrigger>
        </>
      )}

      {showRecommendedResolutionSection && (
        <>
          {isLoadingRecommendedResolution ? (
            <SpinnerContainer>
              <LoadingSpinner />
            </SpinnerContainer>
          ) : (
            <RecommendedResolutionSection blocks={resolutionBlocks} />
          )}
        </>
      )}
      <TextAreaField
        name="description"
        label={COPY.SUPPORT.FORM_DESCRIPTION_LABEL}
        placeholder={COPY.SUPPORT.FORM_DESCRIPTION_PLACEHOLDER}
        disabled={isSubmitting}
        minRows={3}
        minLength={12}
        errorLabel="Description"
        required
      />
      <AttachmentField name="attachments" disabled={isSubmitting} />
      <FieldCell
        title="Grant support access to account"
        subtitle="Monarch authorized support agents may access my account to troubleshoot this issue."
        rightAccessory={
          <GrantAccessField name="grantSupportAccountAccess" disabled={isSubmitting} />
        }
      />
    </>
  );
};

const GET_LINKED_CREDENTIALS = gql(`
  query Web_GetLinkedCredentials {
    credentials {
      id

      institution {
        id
        logo
        name
      }
    }
  }
`);

export default ContactSupportInnerForm;
