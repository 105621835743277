import { gql } from '@apollo/client';

export const REPORT_INSTITUTION_ISSUE_MUTATION = gql`
  mutation Common_CreateInstitutionIssueReportMutation(
    $input: CreateInstitutionIssueReportMutationInput!
  ) {
    createInstitutionIssueReport(input: $input) {
      errors {
        message
      }
    }
  }
`;

export const TOP_INSTITUTIONS_QUERY = gql`
  query TopInstitutionsQuery($platform: Platform, $limit: Int) {
    topInstitutions(platform: $platform, limit: $limit) {
      id
      name
      url
      logo
      largeLogo {
        url
      }
      status
      hasIssuesReported
      hasIssuesReportedMessage
      linkFlowWarnBeforeConnecting
      linkFlowWarningMessage
      linkFlowWarningTitle
      plaidInstitutionId
      finicityInstitutionId
      mxInstitutionId
      preferredDataProvider
      firstBackupDataProvider
    }
  }
`;

export const GET_INSTITUTION_LOGO_BY_PLAID_ID = gql`
  query GetInstitutionLogoByPlaidId($plaidId: String!) {
    institution(plaidId: $plaidId) {
      id
      logo
    }
  }
`;

export const GET_INSTITUTION_BY_PROVIDER_ID_QUERY = gql`
  query GetInstitutionByProviderId(
    $id: String
    $plaidId: String
    $finicityId: String
    $mxId: String
  ) {
    institution(id: $id, plaidId: $plaidId, finicityId: $finicityId, mxId: $mxId) {
      id
      name
      logo
      largeLogo {
        url
      }
      url
      status
      preferredDataProvider
      firstBackupDataProvider
      plaidInstitutionId
      finicityInstitutionId
      mxInstitutionId
    }
  }
`;

export const REQUEST_INSTITUTION_MUTATION = gql`
  mutation Common_RequestInstitution($input: RequestInstitutionInput!) {
    requestInstitution(input: $input) {
      success
    }
  }
`;
