import { getMeetsOrExceedsLimit } from 'common/lib/premium/access';
import { DashboardWidgetName } from 'common/state/dashboard/types';

import type { GetFeatureEntitlementParams_subscription_entitlementParams_constants } from 'common/generated/graphQlTypes/GetFeatureEntitlementParams';
import type { SubscriptionEntitlement } from 'common/generated/graphQlTypes/globalTypes';
import { PaymentPeriod, ProductFeature } from 'common/generated/graphQlTypes/globalTypes';

import { PREMIUM } from './copy';

// convenience export
export { ProductFeature, SubscriptionEntitlement } from 'common/generated/graphQlTypes/globalTypes';

export const CHECK = '✔️';
export const PRICE_PLACEHOLDER = '-';
// Some of this may come from the backend in the future...
export const PLAN_COMPARISON_TABLE = [
  ['', PRICE_PLACEHOLDER], // This will be replaced by the fetched price
  ['Bank Connections', 'Unlimited'],
  ['Transaction Rules', 'Unlimited'],
  ['Investments & Crypto', CHECK],
  ['Zillow Home Values', CHECK],
  ['Custom Categories', CHECK],
  ['Tags & Attachments', CHECK],
  ['Rollover Budgets', CHECK],
  ['Monthly Financial Report', CHECK],
  ['Human Help & Support', CHECK],
];

export type EntitlementConstantKey = keyof Omit<
  GetFeatureEntitlementParams_subscription_entitlementParams_constants,
  'entitlement' | '__typename'
>;

/** These are features that have an associated constant (i.e. credential limit of 2) */
export enum ProductFeatureLimit {
  CredentialLimit = 'maxCredentials',
  TransactionRuleLimit = 'maxTransactionRules',
}

export const isProductFeature = (value: any): value is ProductFeature =>
  Object.values(ProductFeature).includes(value);

export const isProductFeatureLimit = (value: any): value is ProductFeatureLimit =>
  Object.values(ProductFeatureLimit).includes(value);

export type LimitByEntitlement = {
  [key in SubscriptionEntitlement]: number | null;
};

export const POPOVER_COPY: { [key in ProductFeature]: { title: string; description: string } } = {
  [ProductFeature.download_csv]: {
    title: 'Download CSV',
    description: 'Export and take all your data with you.',
  },
  [ProductFeature.rollovers]: {
    title: 'Monthly rollovers',
    description:
      'Carry forward any remaining amounts from previous months or smooth out monthly changes when tracking a yearly budget.',
  },
  [ProductFeature.investments]: {
    title: 'Investments',
    description: 'Analyze your portfolio performance across all of your investment accounts.',
  },
  [ProductFeature.customer_support]: {
    title: 'Human support',
    description: '',
  },
  [ProductFeature.custom_categories]: {
    title: 'Custom categories',
    description: 'Create your own categories to organize things exactly how you want to.',
  },
  [ProductFeature.weekly_review]: {
    title: 'Monthly review',
    description: 'Get a summary of how your cash flow and net worth is trending each month.',
  },
  [ProductFeature.tags]: {
    title: 'Tags',
    description:
      'Add another level of organization with tags that speed up searching and filtering of your cash flow.',
  },
  [ProductFeature.attachments]: {
    title: 'Receipts & attachments',
    description: 'Take a photo of a receipt or upload a PDF to keep track of more details.',
  },
  [ProductFeature.zillow]: {
    title: 'Zillow',
    description:
      'Connect to Zillow to see how much your home is worth and track changes over time using local public MLS data.',
  },
  [ProductFeature.coinbase]: {
    title: 'Coinbase',
    description: 'Connect your Coinbase account by upgrading to Premium.',
  },
  [ProductFeature.apple_card]: {
    title: 'Apple Card',
    description: 'Connect your Apple Card by upgrading to Premium.',
  },
};

export const getLimitPopoverCopy = (
  featureLimit: ProductFeatureLimit,
  limit: number,
  currentValue: number,
): { title: string; description: string } => {
  const meetsOrExceedsLimit = getMeetsOrExceedsLimit(limit, currentValue);

  const title = (() => {
    if (featureLimit === ProductFeatureLimit.CredentialLimit && !meetsOrExceedsLimit) {
      return 'Account connections';
    }
    return 'Limit has been reached';
  })();

  const description = (() => {
    switch (featureLimit) {
      case ProductFeatureLimit.CredentialLimit:
        return meetsOrExceedsLimit
          ? `You've hit your limit of ${limit} free connections. Upgrade to Premium to connect more.`
          : `You have ${currentValue} of ${limit} free connections. Upgrade to Premium for unlimited connections.`;
      case ProductFeatureLimit.TransactionRuleLimit:
        return `You've hit your limit of ${limit} free rules. Upgrade to Premium to create more.`;
    }
  })();

  return {
    title,
    description,
  };
};

/** This is a mapping of premium dashboard widgets to their associated product feature. */
export const DASHBOARD_WIDGET_ASSOCIATED_FEATURE: {
  [key in DashboardWidgetName]?: ProductFeature;
} = {
  [DashboardWidgetName.INVESTMENTS]: ProductFeature.investments,
  [DashboardWidgetName.REVIEW]: ProductFeature.weekly_review,
};

/** This will likely be replaced by a field on Institution. But just mock this for now to unblock FE. */
export const INSTITUTION_NAME_ASSOCIATED_FEATURE: { [key in string]: ProductFeature } = {
  Zillow: ProductFeature.zillow,
  Coinbase: ProductFeature.coinbase,
  'Apple Card': ProductFeature.apple_card,
};

export enum BillingVersion {
  V1 = 'v1',
  V2 = 'v2',
}

export enum ReferredFeatureAnalyticsName {
  Signup = 'signup',
  Navigation = 'navigation',
  Billing = 'billing',
}

export const PLAN_LABEL_FOR_PERIOD = {
  [PaymentPeriod.MONTHLY]: PREMIUM.MONTHLY_PLAN,
  [PaymentPeriod.YEARLY]: PREMIUM.ANNUAL_PLAN,
  [PaymentPeriod.LIFETIME]: PREMIUM.LIFETIME_PLAN,
  [PaymentPeriod.OTHER]: '',
};
