import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';

type Options = {
  /** Set this to true when we just want to ensure the query is cached, but we don't actually need the result.
   * Will use fetchPolicy cache-first and not do a network request if it's already cached.
   */
  prefetch?: boolean;
  promoCode?: string | null;
};

/**
 * Hook used to fetch stripe offerings from backend. This is a reusable
 * hook because it's used in a few places to ensure plans are loaded before
 * the user gets to the upgrade step.
 */
const usePremiumStripeOfferings = ({ prefetch, promoCode }: Options = {}) => {
  const { data, isLoadingInitialData, isNetworkRequestInFlight } = useQuery(QUERY, {
    variables: { promoCode },
    fetchPolicy: prefetch ? 'cache-first' : undefined,
  });
  const { subscriptionOffering, subscription } = data ?? {};
  const {
    plans = [],
    promoCodeDescription,
    promoCodeDuration,
    promoCodeDurationInMonths,
    promoCodeError,
  } = subscriptionOffering ?? {};
  const { trialEndsAt, referralRedemption } = subscription ?? {};

  // The referral campaign that was active when the user signed up
  const referralCampaign = referralRedemption?.campaign;

  return {
    plans,
    trialEndsAt,
    promoCodeDescription,
    promoCodeDuration,
    promoCodeDurationInMonths,
    promoCodeError,
    isLoadingInitialData,
    isNetworkRequestInFlight,
    referralCampaign,
  };
};

const QUERY = gql(`
  query Common_GetPremiumUpgradePlans($promoCode: String) {
    subscriptionOffering(stripePromoCode: $promoCode) {
      promoCodeError
      promoCodeDescription
      promoCodeDuration
      promoCodeDurationInMonths
      plans {
        name
        period
        pricePerPeriodDollars
        discountedPricePerPeriodDollars
        stripeId
        newTrialEndsAt
        requirePaymentMethod
        sponsoredBy {
          name
          email
          profilePictureUrl
        }
      }
    }
    subscription {
      id
      trialEndsAt
      referralRedemption {
        campaign
      }
    }
  }
`);

export default usePremiumStripeOfferings;
