import { useSelector } from 'react-redux';

import type { RootState } from 'state/types';

type Selector<TSelected> = (state: RootState) => TSelected;

function useSelectors<T1>(selectors: readonly [Selector<T1>]): [T1];
function useSelectors<T1, T2>(selectors: readonly [Selector<T1>, Selector<T2>]): [T1, T2];
function useSelectors<T1, T2, T3>(
  selectors: readonly [Selector<T1>, Selector<T2>, Selector<T3>],
): [T1, T2, T3];
function useSelectors<T1, T2, T3, T4>(
  selectors: readonly [Selector<T1>, Selector<T2>, Selector<T3>, Selector<T4>],
): [T1, T2, T3, T4];
function useSelectors<T1, T2, T3, T4, T5>(
  selectors: readonly [Selector<T1>, Selector<T2>, Selector<T3>, Selector<T4>, Selector<T5>],
): [T1, T2, T3, T4, T5];
function useSelectors<T1, T2, T3, T4, T5, T6>(
  selectors: readonly [
    Selector<T1>,
    Selector<T2>,
    Selector<T3>,
    Selector<T4>,
    Selector<T5>,
    Selector<T6>,
  ],
): [T1, T2, T3, T4, T5, T6];

function useSelectors<T1, T2, T3, T4, T5, T6, T7>(
  selectors: readonly [
    Selector<T1>,
    Selector<T2>,
    Selector<T3>,
    Selector<T4>,
    Selector<T5>,
    Selector<T6>,
    Selector<T7>,
  ],
): [T1, T2, T3, T4, T5, T6, T7];

function useSelectors<T1, T2, T3, T4, T5, T6, T7, T8>(
  selectors: readonly [
    Selector<T1>,
    Selector<T2>,
    Selector<T3>,
    Selector<T4>,
    Selector<T5>,
    Selector<T6>,
    Selector<T7>,
    Selector<T8>,
  ],
): [T1, T2, T3, T4, T5, T6, T7, T8];

function useSelectors<T1, T2, T3, T4, T5, T6, T7, T8, T9>(
  selectors: readonly [
    Selector<T1>,
    Selector<T2>,
    Selector<T3>,
    Selector<T4>,
    Selector<T5>,
    Selector<T6>,
    Selector<T7>,
    Selector<T8>,
    Selector<T9>,
  ],
): [T1, T2, T3, T4, T5, T6, T7, T8, T9];

function useSelectors<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10>(
  selectors: readonly [
    Selector<T1>,
    Selector<T2>,
    Selector<T3>,
    Selector<T4>,
    Selector<T5>,
    Selector<T6>,
    Selector<T7>,
    Selector<T8>,
    Selector<T9>,
    Selector<T10>,
  ],
): [T1, T2, T3, T4, T5, T6, T7, T8, T9, T10];

/**
 * Utility for multiple useSelector calls
 * const [selectedOne, selectedTwo] = useSelectors([selectorOne, selectorTwo]);
 */
// eslint-disable-next-line
function useSelectors(selectors: readonly Function[]) {
  // @ts-ignore treating tuple as an array
  return selectors.map((selector) => useSelector(selector)); // eslint-disable-line react-hooks/rules-of-hooks
}

export default useSelectors;
