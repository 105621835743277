import React from 'react';
import ReactToPrint from 'react-to-print';
import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { copyToClipboard } from 'utils/clipboard';

const StyledDefaultButton = styled(DefaultButton)<{
  marginRight?: keyof DefaultTheme['spacing'];
}>`
  margin-right: ${({ theme, marginRight }) => marginRight && theme.spacing[marginRight]};
`;

const downloadTokens = (tokens: string) => {
  const element = document.createElement('a');
  const file = new Blob([tokens], { type: 'text/plain', endings: 'native' });
  element.href = URL.createObjectURL(file);
  element.download = 'monarchRecoveryTokens.txt';
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

type ActionButtonsProps = {
  tokenArray: [string];
  codesRef: React.MutableRefObject<null>;
  allowNext: () => void;
};

const RecoveryCodesMfaActionButtons = ({ tokenArray, codesRef, allowNext }: ActionButtonsProps) => {
  const tokenString = tokenArray.join('\r\n');

  return (
    <FlexContainer justifyCenter marginBottom="large">
      <StyledDefaultButton
        marginRight="large"
        onClick={() => {
          allowNext();
          downloadTokens(tokenString);
        }}
      >
        Download
      </StyledDefaultButton>
      <ReactToPrint
        trigger={() => (
          <StyledDefaultButton marginRight="large" onClick={() => allowNext()}>
            Print
          </StyledDefaultButton>
        )}
        content={() => codesRef.current}
      />
      <StyledDefaultButton
        onClick={() => {
          allowNext();
          copyToClipboard(tokenString);
        }}
      >
        Copy to Clipboard
      </StyledDefaultButton>
    </FlexContainer>
  );
};

export default RecoveryCodesMfaActionButtons;
