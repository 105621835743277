import styled from 'styled-components';

import TransactionLinkButton from 'components/transactions/list/TransactionLinkButton';

const NarrowLinkButton = styled(TransactionLinkButton)`
  flex-shrink: 0;
  width: 24px;
  display: none;
`;

export default NarrowLinkButton;
