import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import AbstractField from 'components/lib/form/AbstractField';
import type { OptionType } from 'components/lib/form/Radio';
import { OptionRow } from 'components/lib/form/Radio';
import DragDots from 'components/lib/ui/DragDots';
import DragDropList from 'components/lib/ui/DragDropList';
import Text from 'components/lib/ui/Text';

import useTheme from 'lib/hooks/useTheme';

const Root = styled.div`
  width: 100%;
`;

const Row = styled(OptionRow)`
  position: relative;
  cursor: move;

  && {
    margin-top: 0;
  }
`;

const IndexLabel = styled(Text)`
  position: absolute;
  left: -${({ theme }) => theme.spacing.xxlarge};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: 0.08em;
`;

const Dots = styled(DragDots)`
  margin-right: ${({ theme }) => theme.spacing.default};
`;

type ItemType = { value: string };

type Props<ValueT> = {
  name: string;
  options: OptionType<ValueT>[];
};

const RankingField = <ValueT = any,>({ name, options }: Props<ValueT>) => {
  const theme = useTheme();

  return (
    <AbstractField name={name} hideLabel>
      {({ value: formValue, setFieldValue }) => {
        const items = (formValue ?? []) as ItemType[];

        const getItemIndex = ({ value: optionValue }: ItemType) =>
          items.findIndex(({ value }) => value === optionValue);

        return (
          <Root>
            <DragDropList
              items={items}
              keyExtractor={R.prop('value')}
              indexExtractor={getItemIndex}
              renderItem={(item) => (
                <Row alignCenter>
                  <IndexLabel>#{getItemIndex(item) + 1}</IndexLabel>
                  <Dots />
                  <Text>
                    {options.find(({ value }) => value === item.value)?.label ?? 'Option'}
                  </Text>
                </Row>
              )}
              onMoveItem={({ destination, source }) =>
                setFieldValue(name, R.move(source, destination, items))
              }
              interItemSpacing={theme.spacing.xsmall}
            />
          </Root>
        );
      }}
    </AbstractField>
  );
};

export default RankingField;
