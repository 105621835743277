import React from 'react';
import styled from 'styled-components';

import { color, fontSize, fontWeight } from 'common/lib/theme/dynamic';

const Root = styled.div`
  background: ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.small};
  border-radius: ${({ theme }) => theme.radius.pill};
  max-width: min-content;
  font-size: ${fontSize.xsmall};
  font-weight: ${fontWeight.medium};
  color: ${color.textLight};
`;

type Props = {
  children: React.ReactNode;
};

const TransactionDrawerStatusTag = ({ children }: Props) => <Root>{children}</Root>;

export default TransactionDrawerStatusTag;
