import React, { useContext } from 'react';

export type StackContextType = {
  push: <P = any>(component: React.ComponentType<P>, props: P) => void;
  /** Replace the current component in the stack with a new one. */
  replace: <P = any>(component: React.ComponentType<P>, props: P) => void;
  pop: () => void;
  onComplete?: (...props: any[]) => void;
};

const StackContext = React.createContext<StackContextType>(undefined as any);

export const useStackContext = () => {
  const context = useContext(StackContext);
  if (!context) {
    throw new Error(
      'useStackContext used outside of Stack. Ensure you have a <StackContext.Provider> higher in the tree.',
    );
  }

  return context;
};

export default StackContext;
