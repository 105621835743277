import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SingleCardPage from 'components/lib/layouts/SingleCardPage';

import getHealthApi from 'common/lib/api/health';
import api from 'lib/api';

const Root = styled(SingleCardPage)`
  display: flex;
  flex-direction: column;
`;

const Health = () => {
  const [response, setResponse] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const { health } = await getHealthApi(api).getSimpleHealth();
      setResponse(health);
    };
    fetchData();
  }, []);

  return (
    <Root name="Health" size="small">
      {response}
    </Root>
  );
};

export default Health;
