import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';
import PlanRowTitle from 'components/plan/PlanRowTitle';

const PlanRowTitleGray = styled(PlanRowTitle)`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.textLight};
  position: relative;
  height: 100%;
  overflow: visible;
`;

const TriangleArrow = styled.div`
  --size: 12px;
  content: '';
  position: absolute;
  width: var(--size);
  height: var(--size);
  left: 18px;
  top: calc(var(--size) / 2 * -1);
  transform: rotate(45deg);
  background: ${({ theme }) => theme.color.grayBackground};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  border-right: 1px solid ${({ theme }) => theme.color.grayFocus};
`;

const ICON_SIZE_PT = 16;

type Props = {
  unplannedCount: number;
  isExpanded: boolean;
  onClick: () => void;
};

const PlanRowTitleUnplanned = ({ unplannedCount, isExpanded, onClick }: Props) => (
  <PlanRowTitleGray
    onClick={onClick}
    icon={<Icon size={ICON_SIZE_PT} name={isExpanded ? 'eye-off' : 'eye'} />}
  >
    {isExpanded && <TriangleArrow />}
    {isExpanded ? 'Collapse' : 'Show'} {unplannedCount} unbudgeted
  </PlanRowTitleGray>
);

export default React.memo(PlanRowTitleUnplanned);
