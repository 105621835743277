import { MerchantOrdering } from 'common/generated/graphql';

export const DEFAULT_ORDER = MerchantOrdering.TRANSACTION_COUNT;

export const ORDER_OPTIONS = [
  {
    value: MerchantOrdering.TRANSACTION_COUNT,
    label: 'Transaction count',
  },
  {
    value: MerchantOrdering.NAME,
    label: 'Alphabetical',
  },
];
