import { gql } from '@apollo/client';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useState } from 'react';
import type { SyntheticEvent } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import TextInput from 'components/lib/form/TextInput';
import CardEmpty from 'components/lib/ui/CardEmpty';
import ModalCard from 'components/lib/ui/ModalCard';
import type { Metadata } from 'components/merchants/MerchantSearchFlow';
import MerchantSearchListItem from 'components/merchants/MerchantSearchListItem';
import MerchantSearchListLoading from 'components/merchants/MerchantSearchListLoading';

import useDebounce from 'common/lib/hooks/useDebounce';
import useQuery from 'common/lib/hooks/useQuery';
import HighlightTextContext from 'lib/contexts/HighlightTextContext';

import { TYPEAHEAD_DEBOUNCE_TIME_MS } from 'common/constants/form';

import type {
  Web_SearchMerchants,
  Web_SearchMerchantsVariables,
} from 'common/generated/graphQlTypes/Web_SearchMerchants';

const MerchantInputContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const MerchantInput = styled(TextInput)`
  width: 100%;
`;

const StyledEmpty = styled(CardEmpty)`
  margin: ${({ theme }) => theme.spacing.xlarge} auto ${({ theme }) => theme.spacing.xxxlarge};
`;

const StyledLoadingItem = styled(MerchantSearchListLoading)`
  padding: ${({ theme }) => theme.spacing.xlarge};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const isSearchValid = RA.lengthGte(3);

type Props = {
  title?: string;
  initialValues?: Metadata['initialValues'];
  next: ({ merchantId, initialValues }: Metadata) => void;
};

const MerchantSearchModal = ({ title = 'Search merchant', initialValues, next }: Props) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, TYPEAHEAD_DEBOUNCE_TIME_MS);
  const { data, isNetworkRequestInFlight } = useQuery<
    Web_SearchMerchants,
    Web_SearchMerchantsVariables
  >(SEARCH_MERCHANT_QUERY, {
    variables: { search: debouncedSearch },
    skip: !isSearchValid(debouncedSearch),
  });

  const results = R.pathOr([], ['merchants'], data);

  return (
    <ModalCard title={title}>
      <MerchantInputContainer>
        <MerchantInput
          name="merchant"
          placeholder="Enter 3 characters or more..."
          value={search}
          onChange={(e: SyntheticEvent<HTMLInputElement>) => {
            const target = e.target as HTMLInputElement;
            setSearch(target.value);
          }}
          autoFocus
        />
      </MerchantInputContainer>
      <Switch>
        <Case when={isNetworkRequestInFlight}>
          {R.range(0, 3).map((i) => (
            <StyledLoadingItem key={i} />
          ))}
        </Case>
        <Case when={RA.isNotEmpty(results)}>
          <HighlightTextContext.Provider
            value={{ searchWords: debouncedSearch ? [debouncedSearch] : [] }}
          >
            {results.map(({ id, name, transactionCount, logoUrl }) => (
              <MerchantSearchListItem
                key={id}
                name={name}
                transactionCount={transactionCount}
                logoUrl={logoUrl}
                onClick={() => next({ merchantId: id, initialValues })}
              />
            ))}
          </HighlightTextContext.Provider>
        </Case>
        <Case when={!isSearchValid(debouncedSearch)}>
          <StyledEmpty
            icon="search"
            title="Search for a merchant"
            subtitle="Enter a search term above to find merchants with recurring transactions."
          />
        </Case>
        <Case when={R.isEmpty(results) && RA.isNotEmpty(search) && !isNetworkRequestInFlight}>
          <StyledEmpty
            icon="search"
            title={`Couldn't find any merchants matching "${search}"`}
            subtitle="Enter a search term above to find merchants with recurring transactions."
          />
        </Case>
        <Case default>
          <StyledEmpty
            icon="search"
            title="Search for a merchant"
            subtitle="Enter a search term above to find merchants with recurring transactions."
          />
        </Case>
      </Switch>
    </ModalCard>
  );
};

const SEARCH_MERCHANT_QUERY = gql`
  query Web_SearchMerchants($search: String!) {
    merchants(search: $search) {
      id
      name
      transactionCount
      logoUrl
    }
  }
`;

export default MerchantSearchModal;
