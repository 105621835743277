import { useQuery, useMutation } from '@apollo/client';
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import DateField from 'components/lib/form/DateField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import SettingsCard from 'components/lib/layouts/SettingsCard';
import Column from 'components/lib/ui/Column';
import ProfilePictureSettings from 'components/settings/ProfilePictureSettings';

import { GET_ME, UPDATE_ME } from 'common/lib/graphQl/user';

import type { GetMe } from 'common/generated/graphQlTypes/GetMe';
import type { UpdateMe, UpdateMeVariables } from 'common/generated/graphQlTypes/UpdateMe';

const FormContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const DATE_FORMAT = 'MMMM D, yyyy';
const MIN_BIRTHDAY_DATE = new Date('1940-01-01');

// @ts-ignore
const supportedTimezones: string[] = Intl.supportedValuesOf('timeZone');

const TIMEZONE_OPTIONS: { value: string; label: string }[] = supportedTimezones.map(
  (timezone: string) => ({
    value: timezone,
    label: timezone,
  }),
);

const ProfileSettings = () => {
  const { data, refetch } = useQuery<GetMe>(GET_ME);
  const [updateMe] = useMutation<UpdateMe, UpdateMeVariables>(UPDATE_ME);

  const {
    name = '',
    birthday = '',
    profilePicture = null,
    profilePictureUrl,
    timezone,
  } = data?.me ?? {};

  return (
    <Column md={9}>
      <SettingsCard title="Profile">
        <Helmet>
          <title>Profile Settings</title>
        </Helmet>

        <FormContainer {...sensitiveClassProps}>
          <ProfilePictureSettings
            activeProfilePictureUrl={profilePictureUrl}
            profilePicture={profilePicture}
            refetchUser={refetch}
          />
          <Form
            initialValues={{
              name,
              birthday,
              timezone,
            }}
            mutation={updateMe}
          >
            <TextField name="name" label="Full Name" />
            <DateField
              displayFormat={DATE_FORMAT}
              name="birthday"
              label="Birthday"
              minDate={MIN_BIRTHDAY_DATE}
            />
            <SelectField name="timezone" label="Timezone" options={TIMEZONE_OPTIONS} />
            <FormSubmitButton>Update Profile</FormSubmitButton>
          </Form>
        </FormContainer>
      </SettingsCard>
    </Column>
  );
};

export default ProfileSettings;
