import { DateTime } from 'luxon';
import React from 'react';

import MonthlyBudgetsGoalsList from 'components/goalsV2/MonthlyBudgetsGoalsList';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import useQuery from 'common/lib/hooks/useQuery';
import { useModalContext } from 'lib/contexts/ModalContext';

import * as COPY from 'common/constants/copy';
import { GOAL_BUDGET_NUM_MONTHS_AVG_SAVINGS } from 'common/constants/goals';

import { gql } from 'common/generated/gql';
import type { Web_MonthlyBudgetGoalsModal } from 'common/generated/graphQlTypes/Web_MonthlyBudgetGoalsModal';

const MonthlyBudgetGoalsModal = () => {
  const { close } = useModalContext();

  const { data } = useQuery<Web_MonthlyBudgetGoalsModal>(QUERY, {
    variables: {
      startDate: DateTime.local()
        .startOf('month')
        .minus({ months: GOAL_BUDGET_NUM_MONTHS_AVG_SAVINGS })
        .toISODate(),
      endDate: DateTime.local().startOf('month').toISODate(),
    },
  });

  return (
    <ModalCard
      title={COPY.GOALS.MONTHLY_BUDGETS.TITLE}
      description={COPY.GOALS.MONTHLY_BUDGETS.SUBTITLE}
      hideBottomBorder
    >
      <MonthlyBudgetsGoalsList data={data} />

      <CardFooter>
        <PrimaryButton onClick={close}>Done</PrimaryButton>
      </CardFooter>
    </ModalCard>
  );
};

const QUERY = gql(`
  query Web_MonthlyBudgetGoalsModal($startDate: Date!, $endDate: Date!) {
    goalsV2 {
      id
      plannedMonthlyContribution
      objective
      ...Web_GoalInfoRowFields
    }
    aggregates(filters: { startDate: $startDate, endDate: $endDate }) {
      summary {
        savings
      }
    }
  }
`);

export default MonthlyBudgetGoalsModal;
