import type { ThemeType } from 'common/types/Styles';

export const getTagColors = ({ color }: ThemeType) => [
  color.red,
  color.orange,
  color.yellow,
  color.green,
  color.teal,
  color.blue,
  color.navy,
  color.purple,
];
