import React from 'react';
import type { DefaultTheme } from 'styled-components';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import useTheme from 'lib/hooks/useTheme';

import type { UiTheme } from 'common/types';

type Props = {
  forceTheme?: UiTheme;
  children: React.ReactNode;
};

const ThemeProvider = ({ forceTheme, children }: Props) => {
  const theme = useTheme(forceTheme);

  return <StyledThemeProvider theme={theme as DefaultTheme}>{children}</StyledThemeProvider>;
};

export default ThemeProvider;
