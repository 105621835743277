import * as R from 'ramda';

import type { CashFlowFilter } from 'state/filters/types';

import type { TransactionFilterInput } from 'common/generated/graphql';

export const convertCashFlowFilterToInput = (
  filter: Partial<CashFlowFilter>,
): Partial<TransactionFilterInput> => R.pick(['accounts', 'tags'], filter);

export const getMostRecentDateIfCurrentDateNonAvailable = (
  data: { startDate: string }[],
  date: string,
) => {
  const foundData = data.find((data) => data.startDate === date);
  if (!foundData && data.length > 0) {
    const newDate = data[data.length - 1].startDate;
    return newDate;
  }

  return date;
};
