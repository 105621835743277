import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import AccountSelectField from 'components/lib/form/AccountSelectField';
import CurrencyField from 'components/lib/form/CurrencyField';
import Form from 'components/lib/form/Form';
import { Label } from 'components/lib/form/FormItemContainer';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import ReviewSummaryCard from 'components/recurring/ReviewSummaryCard';
import { GET_RECURRING_UPCOMING_QUERY } from 'components/routes/recurring/Recurring';

import { GET_RECURRING_STREAMS } from 'common/lib/hooks/recurring/useRecurringStreams';
import {
  RECURRING_TYPE_OPTIONS,
  getAmountWithSign,
  getFrequencyOptions,
  getInitialType,
  USE_STATEMENT_DUE_DATE_VALUE,
} from 'common/lib/recurring';
import type { RecurringStreamToReview } from 'common/lib/recurring/types';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';

import { RECURRING_ACCOUNT_FILTERS } from 'common/constants/recurringTransactions';

import { gql } from 'common/generated/gql';
import { RecurringStreamReviewStatus } from 'common/generated/graphql';

import DayOfTheMonthField from './DayOfMonthField';

const Root = styled(FlexContainer).attrs({ column: true })`
  gap: ${({ theme }) => theme.spacing.xlarge};
`;

const SubmitButton = styled(FormSubmitButton)`
  font-size: ${({ theme }) => theme.fontSize.small};
`;

type Props = {
  streamsToReview: RecurringStreamToReview[];
  close: () => void;
};

const ReviewStreamModalCard = ({ streamsToReview, close }: Props) => {
  const [reviewStream] = useMutation(REVIEW_STREAM_MUTATION, {
    refetchQueries: [GET_RECURRING_STREAMS, GET_RECURRING_UPCOMING_QUERY],
  });
  const frequencyOptions = useMemo(() => getFrequencyOptions(), []);
  const [streamToReview, setStreamToReview] = useState(streamsToReview?.[0]?.stream);

  const [
    isLoadingAccounts,
    accountOptions,
    { refetch: refetchAccountOptions, isNetworkRequestInFlight },
  ] = useAccountSelectOptions({
    queryFilters: RECURRING_ACCOUNT_FILTERS,
  });

  useEffect(() => {
    if (streamsToReview.length === 0) {
      close();
    } else {
      setStreamToReview(streamsToReview[0].stream);
    }
  }, [streamsToReview]);

  if (!streamToReview) {
    return null;
  }

  const formInitialValues = {
    type: getInitialType(streamToReview.amount ?? 0),
    amount: streamToReview.amount,
    frequency: streamToReview.frequency,
    accountId: streamToReview.creditReportLiabilityAccount?.account?.id,
    baseDate: streamToReview.baseDate,
    dayOfTheMonth:
      streamToReview.dayOfTheMonth?.toString() ??
      (streamToReview?.creditReportLiabilityAccount ? USE_STATEMENT_DUE_DATE_VALUE : undefined),
  };

  const onIgnoreStream = () => {
    reviewStream({
      variables: {
        input: {
          streamId: streamToReview.id,
          reviewStatus: RecurringStreamReviewStatus.IGNORED,
          amount: undefined,
          frequency: undefined,
          accountId: undefined,
          baseDate: undefined,
          isActive: undefined,
          dayOfTheMonth: undefined,
        },
      },
    });
  };

  return (
    <ModalCard
      title={`Is this ${streamToReview?.merchant ? 'merchant' : 'account'} recurring?`}
      hideBottomBorder
      description={
        streamsToReview.length - 1 > 0 ? (
          <div>There are {streamsToReview.length - 1} more recurring items to review.</div>
        ) : null
      }
    >
      <Root>
        <Form
          initialValues={formInitialValues}
          enableReinitialize
          mutation={reviewStream}
          transformValuesBeforeSubmit={(values) =>
            R.omit(['type'], {
              ...values,
              // @ts-ignore
              amount: values.amount ? getAmountWithSign(values.amount, values.type) : undefined,
              streamId: streamToReview.id,
              reviewStatus: RecurringStreamReviewStatus.APPROVED,
            })
          }
          onSubmitSuccess={async (_) => {
            refetchAccountOptions();
          }}
        >
          <CardBody>
            <ReviewSummaryCard stream={streamToReview} />

            {streamToReview.merchant ? (
              <>
                <CurrencyField
                  name="amount"
                  placeholder="$0.00"
                  autoComplete="off"
                  maskOptions={{ allowDecimal: true }}
                  required
                  {...maskClassProps}
                />

                <SelectField
                  name="type"
                  label="Recurring type"
                  required
                  options={RECURRING_TYPE_OPTIONS}
                />

                <SelectField
                  name="frequency"
                  label="Recurring frequency"
                  required
                  options={frequencyOptions}
                />
              </>
            ) : null}

            {streamToReview.creditReportLiabilityAccount && !isNetworkRequestInFlight ? (
              <>
                <AccountSelectField
                  key={streamToReview.id}
                  label="Link to Monarch Account"
                  accountName="accountId"
                  options={accountOptions}
                  isLoading={isLoadingAccounts}
                  onAccountCreated={refetchAccountOptions}
                  hideIgnoreOption
                />

                <Label htmlFor="dayOfTheMonth">Payment Due Date</Label>
                <DayOfTheMonthField
                  name="dayOfTheMonth"
                  lastStatementDueDateDay={
                    DateTime.fromISO(
                      streamToReview.creditReportLiabilityAccount?.lastStatement?.dueDate ?? '',
                    ).day
                  }
                />
              </>
            ) : null}
          </CardBody>

          <CardFooter>
            <DefaultButton onClick={onIgnoreStream}>Not recurring</DefaultButton>
            <SubmitButton disableWhenValuesUnchanged={false}>Save as recurring</SubmitButton>
          </CardFooter>
        </Form>
      </Root>
    </ModalCard>
  );
};

const REVIEW_STREAM_MUTATION = gql(/* GraphQL */ `
  mutation Web_ReviewStream($input: ReviewRecurringStreamInput!) {
    reviewRecurringStream(input: $input) {
      stream {
        id
        reviewStatus
      }

      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export default ReviewStreamModalCard;
