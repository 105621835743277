import type { DocumentNode } from 'graphql';

import ApolloClient from 'lib/graphQl/ApolloClient';

/**
 * Manually refetch a set of queries without a mutation.
 *
 * Most of the time, this should not be needed. Use the `refetchQueries` field on useMutation.
 */
const refetchQueries = (queries: DocumentNode[]) => {
  queries.map((query) => ApolloClient.query({ query, fetchPolicy: 'network-only' }));
};

export default refetchQueries;
