import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import GoalsSetupHeader from 'components/goalsV2/GoalsSetupHeader';
import RankGoalsList from 'components/goalsV2/RankGoalsList';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Page from 'components/lib/ui/Page';
import SetupContentHeader from 'components/lib/ui/SetupContentHeader';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { getProgressPercent } from 'lib/goals/progress';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

const NextButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 438px;
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
`;

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${({ theme }) => theme.color.white};
`;

const StyledRankGoalsList = styled(RankGoalsList)`
  max-width: 438px;
`;

const ButtonContainer = styled(FlexContainer).attrs({ center: true, column: true })`
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
`;

const RankGoals = () => {
  const { push } = useHistory();
  const [loading, setLoading] = useState(true);

  return (
    <StyledPage name="Goals setup" header={<GoalsSetupHeader progress={getProgressPercent(2)} />}>
      <FlexContainer center column>
        <SetupContentHeader
          title={COPY.GOALS.RANK_GOALS.TITLE}
          description={COPY.GOALS.RANK_GOALS.SUBTITLE}
        />
        <StyledRankGoalsList onDoneLoading={() => setLoading(false)} />
      </FlexContainer>
      <ButtonContainer>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <NextButton size="large" onClick={() => push(routes.goalsV2.targetAmounts())}>
            Next
          </NextButton>
        )}
      </ButtonContainer>
    </StyledPage>
  );
};

export default RankGoals;
