import useActiveEntitlements from 'common/lib/hooks/premium/useActiveEntitlements';
import useFeatureEntitlementParams from 'common/lib/hooks/premium/useFeatureEntitlementParams';
import { getHasAccess } from 'common/lib/premium/access';

import type { ProductFeature } from 'common/constants/premium';

/**
 * Hook used to determine if the user has access to a specific feature.
 * If feature is undefined, returns hasAccess: true
 * */
const useFeatureEntitlement = (feature: ProductFeature | undefined) => {
  const activeEntitlements = useActiveEntitlements();
  const { features } = useFeatureEntitlementParams();
  const { requiredEntitlements: validEntitlements = [] } = feature ? features[feature] ?? {} : {};

  const hasAccess = getHasAccess(validEntitlements, activeEntitlements);

  return {
    hasAccess,
    validEntitlements,
  };
};

export default useFeatureEntitlement;
