import { gql } from 'common/generated';

export const GET_JOINT_PLANNING_DATA = gql(/* GraphQL */ `
  query Common_GetJointPlanningData($startDate: Date!, $endDate: Date!) {
    budgetSystem
    budgetData(startMonth: $startDate, endMonth: $endDate) {
      ...BudgetDataFields
    }
    categoryGroups {
      ...BudgetCategoryGroupFields
    }
    goalsV2 {
      ...BudgetDataGoalsV2Fields
    }
  }
`);

export const PLAN_DATA_QUERY_NAME = 'Common_GetJointPlanningData';
