import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import NetWorthBreakdownChart from 'components/accounts/NetWorthBreakdownChart';
import NetWorthChartControls, {
  NET_WORTH_CHART_TIMEFRAME_OPTIONS,
} from 'components/accounts/NetWorthChartControls';
import NetWorthPerformanceChart from 'components/accounts/NetWorthPerformanceChart';
import CardTitle from 'components/lib/ui/CardTitle';
import DashboardWidgetAmountHeader from 'components/lib/ui/DashboardWidgetAmountHeader';
import FlexContainer from 'components/lib/ui/FlexContainer';
import HelpIconTooltip from 'components/lib/ui/HelpIconTooltip';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import {
  getDateRangesForChartType,
  getSnapshotDataForSnapshotGraph,
  getSnapshotDataForTimePeriod,
} from 'common/lib/accounts/accountCharts';
import type { DateRange, NetWorthChartType } from 'common/lib/accounts/accountCharts';
import {
  compileMonthlyNetWorthData,
  getNetWorthBalancesForPerformanceChart,
  getNetWorthBalancesForBreakdownChart,
  getCurrentTimeframeText,
} from 'common/lib/accounts/netWorthCharts';
import THEME from 'common/lib/theme/staticTheme';
import useMeasure from 'lib/hooks/useMeasure';
import useTheme from 'lib/hooks/useTheme';

import * as COPY from 'common/constants/copy';
import type { TimeframeBase } from 'common/constants/timeframes';

import type { GetMonthlySnapshots_monthlySnapshotsByAccountType } from 'common/generated/graphQlTypes/GetMonthlySnapshots';
import type {
  Web_GetAccountsPageQuery,
  Web_GetAggregateSnapshotsQuery,
} from 'common/generated/graphql';

const ROOT_PADDING = parseInt(THEME.spacing.default, 10);
const CHART_HEIGHT_PX = 275;
const INFO_ICON_SIZE_PX = 12;

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.large};
`;

const TitleContainer = styled(FlexContainer).attrs({ alignCenter: true })`
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const LoadingContainer = styled(FlexContainer).attrs({ alignCenter: true, justifyCenter: true })`
  height: ${CHART_HEIGHT_PX}px;
`;

const ChartContainer = styled(FlexContainer).attrs({ column: true })`
  margin-top: -${({ theme }) => theme.spacing.xsmall};
`;

type AccountType = Web_GetAccountsPageQuery['accountTypeSummaries'][number]['type'];

type Props = {
  snapShotsDataByAccountType: GetMonthlySnapshots_monthlySnapshotsByAccountType[];
  aggregateSnapshotsData: Web_GetAggregateSnapshotsQuery['aggregateSnapshots'];
  availableAccountTypes: AccountType[];
  hideTitle?: boolean;
  className?: string;
  isLoadingSnapshotsByAccountType?: boolean;
  isSnapshotsNetworkRequestInFlight?: boolean;
  isLoadingAggregateSnapshots?: boolean;
  isAggreateSnapShotsNetworkRequestInFlight?: boolean;
  activeTimeframeIndex?: number;
  chartType: NetWorthChartType;
  setChartType: (chartType: NetWorthChartType) => void;
  selectedAccountType: string | null;
  selectedDateRange: DateRange;
  isAsset?: boolean;
  onChangeTimeframe?: (timeframe: TimeframeBase) => void;
  setSelectedDateRange: (dateRange: DateRange) => void;
  onChangeSelectedAccountType: (type: string | null) => void;
};

const AccountNetWorthCharts = ({
  snapShotsDataByAccountType,
  aggregateSnapshotsData,
  availableAccountTypes,
  hideTitle,
  className,
  isLoadingSnapshotsByAccountType,
  isSnapshotsNetworkRequestInFlight,
  isLoadingAggregateSnapshots,
  isAggreateSnapShotsNetworkRequestInFlight,
  chartType,
  isAsset,
  setChartType,
  selectedAccountType,
  selectedDateRange,
  activeTimeframeIndex = 0,
  onChangeTimeframe,
  onChangeSelectedAccountType,
  setSelectedDateRange,
}: Props) => {
  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();
  const theme = useTheme();

  const activeTimeframe = useMemo(
    () => NET_WORTH_CHART_TIMEFRAME_OPTIONS[activeTimeframeIndex].value,
    [activeTimeframeIndex],
  );

  const chartDataByAccountType = useMemo(
    () => compileMonthlyNetWorthData(snapShotsDataByAccountType, activeTimeframe),
    [snapShotsDataByAccountType],
  );

  const { snapshots: aggregateSnapshots } = useMemo(
    () => getSnapshotDataForSnapshotGraph(aggregateSnapshotsData, isAsset ?? false),
    [isAsset, aggregateSnapshotsData],
  );

  const dateRanges = useMemo(() => getDateRangesForChartType(chartType), [chartType]);

  // Show a loading label if the snapshots are loading but the chart data for the active timeframe wasn't set yet
  const lineChartData = useMemo(
    () =>
      aggregateSnapshots.length
        ? getSnapshotDataForTimePeriod(
            selectedDateRange?.duration ?? dateRanges[0].duration,
            aggregateSnapshots,
          ).data
        : [],
    [selectedDateRange, aggregateSnapshots],
  );

  const selectedAccountTypeDisplay = useMemo(() => {
    if (selectedAccountType === 'ALL_ASSETS') {
      return 'Assets';
    }
    if (selectedAccountType === 'ALL_LIABILITIES') {
      return 'Liabilities';
    }

    return (
      selectedAccountType &&
      availableAccountTypes.filter((acc) => acc.name === selectedAccountType)[0].display
    );
  }, [selectedAccountType, availableAccountTypes]);

  const yDataKey = useMemo(() => {
    switch (selectedAccountType) {
      case 'ALL_ASSETS':
        return 'assetsBalance';
      case 'ALL_LIABILITIES':
        return 'liabilitiesBalance';
      default:
        return 'balance';
    }
  }, [selectedAccountType]);

  const [previousNetWorth, currentNetWorth] = useMemo(
    () =>
      chartType === 'breakdown'
        ? getNetWorthBalancesForBreakdownChart(chartDataByAccountType)
        : getNetWorthBalancesForPerformanceChart(lineChartData, yDataKey),
    [chartType, chartDataByAccountType, lineChartData, yDataKey],
  );

  const title = useMemo(() => {
    if (!selectedAccountType) {
      return 'Net worth';
    }
    return `Total ${selectedAccountTypeDisplay}`;
  }, [selectedAccountType, selectedAccountTypeDisplay]);

  const currentTimeFrameText = useMemo(
    () => getCurrentTimeframeText(activeTimeframe, selectedDateRange.display, chartType),
    [activeTimeframe, chartType, selectedDateRange],
  );

  const chartWidth = useMemo(() => width - ROOT_PADDING, [width]);

  return (
    <Root ref={ref} className={className}>
      {!hideTitle && (
        <FlexContainer alignStart justifyBetween>
          <TitleContainer>
            <CardTitle>{title}</CardTitle>
            <HelpIconTooltip
              tooltip={COPY.ACCOUNTS.NET_WORTH_TITLE_TOOLTIP}
              sizePx={INFO_ICON_SIZE_PX}
              iconColor={theme.color.textLight}
              place="right"
            />
          </TitleContainer>
          {onChangeTimeframe && (
            <NetWorthChartControls
              selectedAccountTypeDisplay={selectedAccountTypeDisplay}
              activeTimeframeIndex={activeTimeframeIndex}
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={setSelectedDateRange}
              onChangeTimeframe={onChangeTimeframe}
              onChangeChartType={setChartType}
              chartType={chartType}
              onChangeSelectedAccountType={onChangeSelectedAccountType}
              availableAccountTypes={availableAccountTypes}
            />
          )}
        </FlexContainer>
      )}
      {isLoadingSnapshotsByAccountType || isLoadingAggregateSnapshots ? (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ) : (
        <ChartContainer>
          <DashboardWidgetAmountHeader
            startAmount={previousNetWorth}
            currentAmount={currentNetWorth}
            timeframeText={currentTimeFrameText}
            isAsset={isAsset}
          />
          {RA.isNotNilOrEmpty(chartDataByAccountType) && chartType === 'breakdown' && (
            <NetWorthBreakdownChart
              width={chartWidth}
              snapShotsDataByAccountType={snapShotsDataByAccountType}
              isSnapshotsNetworkRequestInFlight={isSnapshotsNetworkRequestInFlight}
              activeTimeframeIndex={activeTimeframeIndex}
              isLoadingSnapshotsByAccountType={isLoadingSnapshotsByAccountType}
            />
          )}

          {RA.isNotNilOrEmpty(lineChartData) && chartType === 'performance' && (
            <NetWorthPerformanceChart
              title={title}
              yDataKey={yDataKey}
              aggregateSnapshotsData={aggregateSnapshotsData}
              selectedAccountType={selectedAccountType}
              selectedDateRange={selectedDateRange}
              width={chartWidth}
              isAggreateSnapShotsNetworkRequestInFlight={isAggreateSnapShotsNetworkRequestInFlight}
              isAsset={isAsset}
            />
          )}
        </ChartContainer>
      )}
    </Root>
  );
};

export default AccountNetWorthCharts;
