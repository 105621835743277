import { color, ifDark, ifTheme, withOpacity } from 'common/lib/theme/variables/types';

const SHADOW_COLOR = ifTheme({
  v1: color('black', 'trueBlack'),
  v2: color('gray12', 'trueBlack'),
});

const _color = {
  background: {
    base: ifTheme({
      v1: color('grayBackground'),
      v2: color('gray2'),
    }),
    card: ifTheme({
      v1: color('white'),
      v2: color('gray1', 'gray3'),
    }),
    sidebar: ifTheme({
      v1: color('navy'),
      v2: color('gray2'),
    }),
    header: ifTheme({
      v1: color('white'),
      v2: color('gray2'),
    }),
    fullscreenHeader: ifTheme({
      v1: color('white'),
      v2: color('gray1'),
    }),
    tooltip: ifTheme({
      v1: color('black', 'trueBlack'),
      v2: color('gray12', 'gray1'),
    }),
    modalOverlay: withOpacity(SHADOW_COLOR, ifDark(0.5, 0.4)),
  },
  text: {
    primary: ifTheme({
      v1: color('text'),
      v2: color('gray12'),
    }),
    secondary: ifTheme({
      v1: color('textLight'),
      v2: color('gray11'),
    }),
    info: ifTheme({
      v1: color('blue'),
      v2: color('blue11'),
    }),
  },
  progress: {
    background: ifTheme({
      v1: withOpacity(color('black'), 0.4),
      v2: color('gray4', 'gray6'),
    }),
  },
  border: {
    base: ifTheme({
      v1: color('grayBackground'),
      v2: color('gray3', 'gray5'),
    }),
  },
  shadow: {
    small: withOpacity(SHADOW_COLOR, ifTheme({ v1: 0.1, v2: ifDark(0.1, 0.05) })),
    medium: withOpacity(SHADOW_COLOR, ifTheme({ v1: 0.08, v2: ifDark(0.2, 0.1) })),
    inset: withOpacity(SHADOW_COLOR, ifTheme({ v1: 0.1, v2: ifDark(0.2, 0.1) })),
    large: withOpacity(SHADOW_COLOR, ifTheme({ v1: 0.1, v2: ifDark(0.2, 0.1) })),
    card: withOpacity(SHADOW_COLOR, ifTheme({ v1: ifDark(0.2, 0.04), v2: ifDark(0.2, 0.1) })),
  },
  chart: {
    grid: ifTheme({
      v1: color('grayBackground'),
      v2: color('gray3'),
    }),
  },
  /** Mobile nav bar. */
  navBar: {
    primary: {
      background: ifTheme({
        v1: color('navyDark'),
        v2: color('gray2'),
      }),
      tint: ifTheme({
        v1: color('textWhite'),
        v2: color('gray12'),
      }),
    },
  },
  card: {
    section: ifTheme({
      v1: color('grayFocus'),
      v2: color('gray2', 'gray4'),
    }),
  },
};

export default _color;
