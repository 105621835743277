import { ReviewStatus } from 'common/lib/transactions/review';
import { getCachedCategory } from 'lib/categories/getCachedCategory';

import type {
  GetTransactionDrawerQuery,
  UpdateTransactionMutationInput,
} from 'common/generated/graphql';

type TransactionData = NonNullable<GetTransactionDrawerQuery['getTransaction']>;

export const transactionUpdateInputToOptimisticResponse = (
  transaction: TransactionData,
  input: Partial<UpdateTransactionMutationInput>,
): TransactionData => ({
  ...transaction,
  amount: input.amount ?? transaction.amount,
  date: input.date ?? transaction.date,
  notes: input.notes ?? transaction.notes,
  reviewStatus: input.reviewed ? ReviewStatus.Reviewed : transaction.reviewStatus,
  reviewedAt: input.reviewed ? new Date().toISOString() : transaction.reviewedAt,
  needsReview: input.needsReview ?? transaction.needsReview,
  needsReviewByUser: input.needsReviewByUser
    ? { __typename: 'User', id: input.needsReviewByUser }
    : transaction.needsReviewByUser,
  goal: input.goalId ? { __typename: 'GoalV2', id: input.goalId } : transaction.goal,
  category: getCachedCategory(input.category) ?? transaction.category,
});
