import React from 'react';
import ContentLoader from 'react-content-loader';

import useTheme from 'lib/hooks/useTheme';

const NetWorthChartLabelContentLoader = ({ x, y }: { x: number; y: number }) => {
  const theme = useTheme();
  const widthPx = 40;
  const heightPx = 18;
  const borderRadius = parseInt(theme.radius.small, 10);

  return (
    <g transform={`translate(${x - widthPx / 2},${y + 5})`}>
      <ContentLoader
        speed={2}
        width={widthPx}
        height={heightPx}
        viewBox={`0 0 ${widthPx} ${heightPx}`}
        backgroundColor={theme.color.grayLightBackground}
        foregroundColor={theme.color.grayBackground}
      >
        <rect x="0" y="0" rx={borderRadius} ry={borderRadius} width={widthPx} height={heightPx} />
      </ContentLoader>
    </g>
  );
};

export default NetWorthChartLabelContentLoader;
