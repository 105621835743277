import React from 'react';
import styled from 'styled-components';

import Checkbox, { Text as CheckboxText } from 'components/lib/form/Checkbox';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Highlighter from 'components/lib/ui/Highlighter';
import Text from 'components/lib/ui/Text';

import { spacing } from 'common/lib/theme/dynamic';

const INDENT_SIZE_PX = 10;
const CHECKBOX_SIZE_PX = 16;

const Root = styled(FlexContainer).attrs({ alignCenter: true })<{
  $indent?: number;
}>`
  padding: ${spacing.xxsmall} 0;
  padding-left: ${({ $indent }) => `${($indent ?? 0) * INDENT_SIZE_PX}px`};
`;

const StyledCheckbox = styled(Checkbox)`
  width: 100%;

  ${CheckboxText} {
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
`;

const NameIconContainer = styled(FlexContainer).attrs({ alignCenter: true })`
  overflow: hidden;

  ${Text} {
    line-height: 150%;
  }
`;

type Props = {
  name: string;
  indent?: number;
  isSelected?: boolean;
  /** Use group text style (smaller & gray) */
  isGroup?: boolean;
  accessory?: React.ReactNode;
  icon?: React.ReactNode;
  onChangeSelected?: (selected: boolean) => void;
};

const FilterMenuOptionRow = ({
  name,
  indent,
  isSelected,
  isGroup,
  onChangeSelected,
  accessory,
  icon,
}: Props) => (
  <Root $indent={indent}>
    <StyledCheckbox
      size={CHECKBOX_SIZE_PX}
      checked={isSelected}
      onChange={(e) => onChangeSelected?.(e.target.checked)}
    >
      <NameIconContainer>
        {icon}
        <Text
          clampLines={1}
          {...(isGroup
            ? {
                size: 'xsmall',
                weight: 'medium',
                color: 'textLight',
              }
            : {
                size: 'small',
                weight: 'book',
              })}
        >
          <Highlighter>{name}</Highlighter>
        </Text>
      </NameIconContainer>
      {accessory}
    </StyledCheckbox>
  </Root>
);

export default FilterMenuOptionRow;
