import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import type { FC } from 'react';
import React from 'react';

import Confirmation from 'components/lib/ui/Confirmation';
import { GET_TRANSACTION_LIST } from 'components/transactions/TransactionsListContainer';

import { DELETE_TRANSACTION_MUTATION } from 'common/lib/graphQl/transactions';
import { useTransactionListContext } from 'lib/contexts/TransactionsListContext';

import * as COPY from 'common/constants/copy';
import { ACCOUNT_DETAILS_QUERY_NAME } from 'constants/graphql';

import type { Web_GetTransactionsListQuery } from 'common/generated/graphql';

type Props = {
  transactionId: string;
  closeModal: () => void;
  afterDelete: () => void;
};

const DeleteTransactionConfirmation: FC<Props> = ({ transactionId, closeModal, afterDelete }) => {
  const { queryVariables } = useTransactionListContext();

  const [deleteTransaction, { loading }] = useMutation(DELETE_TRANSACTION_MUTATION, {
    variables: {
      input: {
        transactionId,
      },
    },
    update: (cache, { data }) => {
      const transactionListQuery = cache.readQuery<Web_GetTransactionsListQuery>({
        query: GET_TRANSACTION_LIST,
        variables: queryVariables,
      });

      const { deleted } = data?.deleteTransaction ?? {};

      // if the transaction is still not deleted for whatever reason, don't remove it from the transactions list
      if (!deleted || R.isNil(transactionListQuery)) {
        return;
      }

      const subtractOne = R.subtract(R.__, 1);

      const updatedAllTransactions = R.evolve(
        {
          results: R.reject(R.propEq('id', transactionId)),
          totalCount: subtractOne,
          totalSelectableCount: R.ifElse(R.isNil, R.identity, subtractOne),
        },
        transactionListQuery.allTransactions,
      );

      cache.writeQuery<Web_GetTransactionsListQuery>({
        query: GET_TRANSACTION_LIST,
        variables: queryVariables,
        data: { ...transactionListQuery, allTransactions: updatedAllTransactions },
      });
    },
    refetchQueries: [ACCOUNT_DETAILS_QUERY_NAME],
    onCompleted: afterDelete,
    awaitRefetchQueries: true,
  });

  return (
    <Confirmation
      title="Delete transaction"
      confirm="Delete"
      onCancel={closeModal}
      onConfirm={deleteTransaction}
      isLoading={loading}
    >
      <div>{COPY.TRANSACTIONS.DELETE_CONFIRMATION.TITLE}</div>
      <div>{COPY.TRANSACTIONS.DELETE_CONFIRMATION.SUBTITLE}</div>
    </Confirmation>
  );
};

export default DeleteTransactionConfirmation;
