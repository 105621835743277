import React, { useEffect } from 'react';
import styled from 'styled-components';

import InstitutionConnectionGraphic from 'components/institutions/InstitutionConnectionGraphic';
import CardFooter from 'components/lib/ui/CardFooter';
import Flex from 'components/lib/ui/Flex';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import RouteLink from 'components/lib/ui/link/RouteLink';

import { getDisplayName } from 'common/lib/dataProvider';
import { track } from 'lib/analytics/segment';

import { ConnectEventNames } from 'common/constants/analytics';
import routes from 'constants/routes';

import type { DataProviderLegacy } from 'common/generated/graphql';
import type { InstitutionDataProvider } from 'common/types/institutions';

type InstitutionFields = {
  id?: string;
  name?: string;
};

const Root = styled(Flex)`
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.xlarge};
  margin-top: 0;
`;

const Title = styled(Text).attrs({ size: 'large', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const BodyText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.color.text};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  text-align: center;
`;

type Props = {
  institution: InstitutionFields;
  onClose?: () => void;
  currentDataProvider: DataProviderLegacy;
  dataProviderPriority0: number;
  fallbackInstitutionDataProvider: InstitutionDataProvider;
};

const LinkInstitutionFallbackDataProviderModal = ({
  institution,
  onClose,
  currentDataProvider,
  dataProviderPriority0,
  fallbackInstitutionDataProvider,
}: Props) => {
  const { dataProvider, dataProviderInstitutionId } = fallbackInstitutionDataProvider;

  useEffect(() => {
    // This duplicates property names, one per {snake_case, camelCase}.
    // Other events in this flow use snake_case, but our frontend convention seems to be
    // camelCase. This allows this event to play nicely with both.
    track(ConnectEventNames.FallbackDataProviderOffered, {
      institutionId: institution.id,
      institution_id: institution.id,
      institutionName: institution.name,
      institution_name: institution.name,
      dataProvider: currentDataProvider,
      data_provider: currentDataProvider,
      dataProviderPriority: dataProviderPriority0,
      data_provider_priority: dataProviderPriority0,
      fallbackDataProvider: fallbackInstitutionDataProvider.dataProvider,
      fallback_data_provider: fallbackInstitutionDataProvider.dataProvider,
    });
  }, []);

  return (
    <ModalCard hideBottomBorder>
      <Root alignCenter column>
        <InstitutionConnectionGraphic />

        <Title>Try connecting with {getDisplayName(dataProvider)}</Title>
        <BodyText>
          Sorry the {getDisplayName(currentDataProvider)} connection didn&apos;t work.
          <br />A backup connection is available with {getDisplayName(dataProvider)}.
        </BodyText>
      </Root>
      <CardFooter>
        {onClose && <DefaultButton onClick={onClose}>Cancel</DefaultButton>}

        <RouteLink
          to={{
            pathname: routes.accounts.addAccountForDataProvider({
              dataProvider: dataProvider.toLocaleLowerCase(),
              institutionId: dataProviderInstitutionId ?? '',
            }),
          }}
        >
          <PrimaryButton>Connect using {getDisplayName(dataProvider)}</PrimaryButton>
        </RouteLink>
      </CardFooter>
    </ModalCard>
  );
};

export default LinkInstitutionFallbackDataProviderModal;
