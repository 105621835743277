import React from 'react';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import type { SingleDatePickerProps } from 'components/lib/ui/SingleDatePicker';
import SingleDatePicker from 'components/lib/ui/SingleDatePicker';

const DateField = ({
  name,
  minDate,
  maxDate,
  ...props
}: AbstractFieldProps<
  Omit<SingleDatePickerProps, 'date' | 'onDateChange'>,
  Parameters<SingleDatePickerProps['onDateChange']>[0]
>) => (
  <AbstractField {...props} name={name}>
    {({ id, value, setFieldValue, setFieldTouched }) => (
      <SingleDatePicker
        {...props}
        id={id}
        date={value}
        minDate={minDate}
        maxDate={maxDate}
        onDateChange={(value) => {
          setFieldValue(name, value);
          setFieldTouched(name, true);
        }}
      />
    )}
  </AbstractField>
);

export default DateField;
