import React from 'react';
import styled from 'styled-components';

import ExcludedFromPortfolioIndicator from 'components/investments/ExcludedFromPortfolioIndicator';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';

import useTheme from 'lib/hooks/useTheme';

const SECURITY_DOT_SIZE_PX = 12;

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })<{
  $canClick: boolean;
}>`
  cursor: ${({ $canClick }) => ($canClick ? 'pointer' : 'not-allowed')};
`;

const SecurityDot = styled.div<{ $color: string }>`
  width: ${SECURITY_DOT_SIZE_PX}px;
  height: ${SECURITY_DOT_SIZE_PX}px;
  border-radius: ${({ theme }) => theme.radius.round};
  background-color: ${({ $color }) => $color};
  flex-shrink: 0;
`;

const StyledText = styled(Text)`
  margin-left: ${({ theme }) => theme.spacing.default};
`;

const SecurityInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxsmall};
  > :last-child {
    padding-left: ${(props) => props.theme.spacing.small};
  }
`;

type Props = {
  primaryText?: string;
  secondaryText?: string | null;
  color?: string;
  canClick?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  tooltipText?: string | null;
};

const InvestmentsIndicator = ({
  primaryText,
  secondaryText,
  color: passedColor,
  canClick,
  disabled,
  onClick,
  tooltipText,
}: Props) => {
  const theme = useTheme();
  const color = passedColor ?? theme.color.grayLight;
  return (
    <Tooltip content={tooltipText} place="top" effect="solid">
      <Root onClick={onClick} $canClick={!!canClick}>
        <SecurityInfoContainer>
          <Flex alignCenter>
            {!disabled ? (
              <SecurityDot $color={color ?? 'grayBackground'} />
            ) : (
              <ExcludedFromPortfolioIndicator tooltipText="Historical data not available for this holding" />
            )}
            <StyledText weight="medium">{primaryText}</StyledText>
          </Flex>
          <StyledText weight="book" size="small">
            {secondaryText}
          </StyledText>
        </SecurityInfoContainer>
      </Root>
    </Tooltip>
  );
};

export default InvestmentsIndicator;
