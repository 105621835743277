import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { sortBy } from 'ramda';
import { useMemo } from 'react';

import { gql } from 'common/generated/gql';
import { BudgetVariability } from 'common/generated/graphql';
import type { Id } from 'common/types';
import { Tuple } from 'common/types';

export type AggregatesDataPoint = {
  month: string;
  sum: number;
};

/**
 * Hook to fetch transactions sum by month for a given category.
 *
 * NOTE: The query will proactively fetch data for ALL categories, not just the given
 * categoryId. It then filters the data and returns just the ones for the requested
 * category. This is by design so that the next query for a different categoryId will
 * already be in the cache and we only have to show the user a loading spinner once.
 */
const useAggregatesGraphQuery = (
  id: Id,
  startDate: DateTime,
  endDate: DateTime,
  isGroup = false,
) => {
  const variables = {
    startDate: startDate.toISODate(),
    endDate: endDate.toISODate(),
  };
  const isFlexSpending = id === BudgetVariability.FLEXIBLE;
  const groupQuery = useQuery(CATEGORY_GROUP_AGGREGATES_QUERY, {
    variables,
    skip: !isGroup || isFlexSpending,
    fetchPolicy: 'cache-first',
  });
  const categoryQuery = useQuery(AGGREGATES_QUERY, {
    variables,
    skip: isGroup || isFlexSpending,
    fetchPolicy: 'cache-first',
  });
  const flexQuery = useQuery(FLEX_EXPENSE_AGGREGATES_QUERY, {
    variables,
    skip: !isFlexSpending,
    fetchPolicy: 'cache-first',
  });

  const query = useMemo(() => {
    if (isFlexSpending) {
      return flexQuery;
    } else if (isGroup) {
      return groupQuery;
    } else {
      return categoryQuery;
    }
  }, [isFlexSpending, flexQuery, isGroup, groupQuery, categoryQuery]);

  const accessor = useMemo(() => {
    if (isFlexSpending) {
      return null;
    } else if (isGroup) {
      return 'categoryGroup';
    } else {
      return 'category';
    }
  }, [isFlexSpending, isGroup]);

  const { data } = query;

  const dataPoints: AggregatesDataPoint[] = useMemo(() => {
    if (!data) {
      return [];
    }

    const mappedData = data.aggregates
      .filter(({ groupBy }) => {
        if (!accessor) {
          return true;
        }

        if (!groupBy) {
          return false;
        }

        // @ts-expect-error - fix groupBy type (union of category and categoryGroup)
        return groupBy[accessor]?.id === id;
      })
      // .filter(({ groupBy }) => (accessor ? groupBy?.[accessor]?.id === id : true))
      .map(({ groupBy, summary }) => ({
        month: groupBy?.month ?? '',
        sum: summary.sum,
      }));

    return sortBy(({ month }) => DateTime.fromISO(month).diffNow().milliseconds, mappedData);
  }, [data, id, accessor]);

  return Tuple(dataPoints, query);
};

export const AGGREGATES_QUERY = gql(`
  query GetAggregatesGraph($startDate: Date, $endDate: Date) {
    aggregates(
      filters: { startDate: $startDate, endDate: $endDate }
      groupBy: ["category", "month"]
      fillEmptyValues: true
    ) {
      groupBy {
        category {
          id
        }
        month
      }
      summary {
        sum
      }
    }
  }
`);

export const CATEGORY_GROUP_AGGREGATES_QUERY = gql(`
  query GetAggregatesGraphCategoryGroup($startDate: Date, $endDate: Date) {
    aggregates(
      filters: { startDate: $startDate, endDate: $endDate }
      groupBy: ["categoryGroup", "month"]
      fillEmptyValues: true
    ) {
      groupBy {
        categoryGroup {
          id
        }
        month
      }
      summary {
        sum
      }
    }
  }
`);

export const FLEX_EXPENSE_AGGREGATES_QUERY = gql(`
  query GetAggregatesGraphFlexExpense($startDate: Date, $endDate: Date) {
    aggregates(
      filters: { startDate: $startDate, endDate: $endDate, isFlexSpending: true }
      groupBy: ["month"]
      fillEmptyValues: true
    ) {
      groupBy {
        month
      }
      summary {
        sum
      }
    }
  }
`);

export default useAggregatesGraphQuery;
