import _ from 'lodash';
import * as R from 'ramda';

import { CATEGORY_GROUP_TYPE_ORDER } from 'common/lib/categories/constants';

import type { CategoryGroupType } from 'common/generated/graphql';

type IndexedList<T> = { [index: string]: T[] };

type SortableCategoryGroup = {
  id: string;
  type: CategoryGroupType;
  order: number;
};

type SortableCategory = {
  id: string;
  order: number;
  group: { id: string };
};

type SortableCategoriesList<TCategory, TGroup> = {
  categories: TCategory[];
  categoryGroups: TGroup[];
};

export const categoryGroupTypeComparator = (a: { type: string }, b: { type: string }) =>
  CATEGORY_GROUP_TYPE_ORDER.indexOf(a.type) - CATEGORY_GROUP_TYPE_ORDER.indexOf(b.type);

export const sortByOrder = R.sortBy(R.prop('order'));

/** Sort income groups before expense groups. In each type, sort by order. */
export const sortCategoryGroups = <T extends SortableCategoryGroup>(groups: T[]): T[] => {
  const sortedByOrder = sortByOrder(groups);

  // using lodash because ramda sort had inconsistent results in android for this specific sort.
  // maybe related to https://github.com/facebook/hermes/issues/212
  // TODO: replace lodash when we upgrade to a react native version that includes hermes 0.9.0 or higher
  const sortedByType = _.sortBy(sortedByOrder, ({ type }) =>
    _.indexOf(CATEGORY_GROUP_TYPE_ORDER, type),
  );

  return sortedByType;
};

export const manageCategoriesAdapter = <
  TCategory extends SortableCategory,
  TGroup extends SortableCategoryGroup,
>(
  data: SortableCategoriesList<TCategory, TGroup> | undefined,
): readonly [IndexedList<TGroup>, IndexedList<TCategory>] => {
  const { categoryGroups = [], categories = [] } = data ?? {};

  const sortedGroups = sortByOrder(categoryGroups);
  const sortedCategories = sortByOrder(categories);

  const groupsByType = R.groupBy(R.prop('type'), sortedGroups);
  const categoriesByGroupId = R.groupBy(({ group }) => group?.id ?? '', sortedCategories);

  return [groupsByType, categoriesByGroupId] as const;
};
