import React from 'react';
import styled, { css } from 'styled-components';

import GoalImage from 'components/goalsV2/GoalImage';
import CheckCircleTransparent from 'components/lib/ui/CheckCircleTransparent';
import FlexContainer from 'components/lib/ui/FlexContainer';
import IncrementDecrementPill from 'components/lib/ui/IncrementDecrementPill';

import type { GoalOptions_goalOptions } from 'common/generated/graphQlTypes/GoalOptions';

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyCenter: true })<{
  $hoverTransition: boolean;
}>`
  aspect-ratio: 0.93;
  position: relative;
  border-radius: ${({ theme }) => theme.radius.medium};
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakPoints.lg}px) {
    min-width: 160px;
  }

  transition: all ${({ theme }) => theme.transition.default};

  ${({ $hoverTransition }) =>
    $hoverTransition &&
    css`
      :hover {
        opacity: 0.8;
      }
    `}
`;

const StyledCheckCircleTransparent = styled(CheckCircleTransparent)`
  position: relative;

  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

type Props = {
  onClick: () => void;
  value: number;
  onChangeValue: (value: number) => void;
  onPress?: () => void;
  data: GoalOptions_goalOptions;
};

const GoalTypeIncrementCard = ({
  data: { allowMultiSelect, defaultImageStorageProvider, defaultImageStorageProviderId },
  onClick,
  value,
  onChangeValue,
}: Props) => (
  <Root
    onClick={value > 0 && allowMultiSelect ? undefined : onClick}
    $hoverTransition={allowMultiSelect ? !value : true}
  >
    <GoalImage
      gradientOpacity={0.5}
      imageStorageProvider={defaultImageStorageProvider}
      imageStorageProviderId={defaultImageStorageProviderId}
      size="medium"
    />
    {value > 0 &&
      (allowMultiSelect ? (
        <IncrementDecrementPill value={value} onChangeValue={onChangeValue} />
      ) : (
        <StyledCheckCircleTransparent />
      ))}
  </Root>
);

export default GoalTypeIncrementCard;
