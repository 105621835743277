import styled from 'styled-components';

const AVATAR_IMAGE_SRC = 'https://monarch-static-assets.s3.amazonaws.com/advice/natalie-taylor.jpg';

const AdviceAvatar = styled.img.attrs({
  src: AVATAR_IMAGE_SRC,
})`
  width: 100px;
  height: 100px;
  border-radius: ${({ theme }) => theme.radius.round};
`;

export default AdviceAvatar;
