import { DateTime } from 'luxon';
import { assocPath, isNil, pick, evolve, mergeLeft, hasPath } from 'ramda';
import storage from 'redux-persist/lib/storage';
import { createReducer } from 'typesafe-actions';

import {
  setChartTypeForTab,
  setGroupByEntity,
  setViewModeForTab,
  setReportsFilters,
  patchReportsFilters,
  setReportsSankeyGroupMode,
  setReportsTransactionsSortBy,
} from 'actions';
import type {
  ReportsFilters,
  ReportsTab,
  SankeyDisplayProperties,
  TabDisplayProperties,
} from 'state/reports/types';
import { ReportsChart } from 'state/reports/types';

import { TransactionOrdering, ReportsGroupByEntity } from 'common/generated/graphql';

export const persistConfig = {
  key: 'reports',
  storage,
};

const getDefaultChartTypeForView = (view: string) =>
  view === 'totalAmounts' ? ReportsChart.PieChart : ReportsChart.BarChart;

export type ReportsState = {
  filters: ReportsFilters;
  groupBy?: ReportsGroupByEntity;
  sortBy: Maybe<TransactionOrdering>;
} & {
  [tab in Exclude<ReportsTab, 'sankey'>]?: TabDisplayProperties;
} & {
  // Sankey-specific display properties
  sankey: SankeyDisplayProperties;
};

export const INITIAL_STATE: ReportsState = {
  groupBy: ReportsGroupByEntity.CATEGORY,
  sortBy: TransactionOrdering.DATE,
  filters: {
    startDate: DateTime.local().minus({ months: 6 }).startOf('month').toISODate(),
    endDate: DateTime.local().toISODate(),
  },
  spending: {
    viewMode: 'totalAmounts',
    chartType: ReportsChart.PieChart,
  },
  income: {
    viewMode: 'totalAmounts',
    chartType: ReportsChart.PieChart,
  },
  cashFlow: {
    chartType: ReportsChart.CashFlowChart,
  },
  sankey: {
    chartType: ReportsChart.Sankey,
    groupMode: 'category',
  },
};

const reportsReducer = createReducer<ReportsState>(INITIAL_STATE)
  .handleAction(setChartTypeForTab, (state, { payload: { chartType, tab } }) =>
    assocPath([tab, 'chartType'], chartType, state),
  )
  .handleAction(setGroupByEntity, (state, { payload: groupBy }) =>
    assocPath(['groupBy'], groupBy, state),
  )
  .handleAction(setViewModeForTab, (state, { payload: { view, tab } }) => {
    if (tab === 'sankey') {
      return state;
    }

    let result = assocPath([tab, 'viewMode'], view, state);

    // Reset the chart type if the view mode is changed
    if (state[tab]?.viewMode !== view) {
      result = assocPath([tab, 'chartType'], getDefaultChartTypeForView(view), result);
    }

    return result;
  })
  .handleAction(setReportsFilters, (state, { payload: newFilters }) => {
    const startEndDates = pick(['startDate', 'endDate'], state.filters);
    return assocPath(
      ['filters'],
      isNil(newFilters)
        ? // never reset the start/end dates
          mergeLeft(startEndDates, INITIAL_STATE.filters)
        : // merge the new filters with the start/end dates
          { ...startEndDates, ...newFilters },
      state,
    );
  })
  .handleAction(patchReportsFilters, (state, { payload: filters }) =>
    evolve({ filters: mergeLeft(filters) })(state),
  )
  .handleAction(setReportsSankeyGroupMode, (state, { payload: view }) =>
    assocPath(['sankey', 'groupMode'], view, state),
  )
  .handleAction(setReportsTransactionsSortBy, (state, { payload: sortBy }) =>
    assocPath(['sortBy'], sortBy, state),
  );

/** Small type guard to check if an object is a TabDisplayProperties object for convenience. */
export const isTabDisplayProperties = (obj: unknown): obj is TabDisplayProperties =>
  hasPath(['viewMode'], obj);

export default reportsReducer;
