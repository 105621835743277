import * as RA from 'ramda-adjunct';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';

import type { Props as FeatureFlagProps } from 'components/lib/higherOrder/FeatureFlag';
import FeatureFlag from 'components/lib/higherOrder/FeatureFlag';

import { DEFAULT_SPLIT_TREATMENT_VALUE } from 'common/lib/external/splitio';

import routes from 'constants/routes';

type Props = Pick<FeatureFlagProps, 'name'> &
  RouteProps & {
    redirectTo?: string;
    onValue?: string | string[];
  };

/**
 * Works like FeatureFlagGate, but for routes instead of components.
 *
 * <FeatureFlagRoute name="investments" path={routes.investments.path} component={Investments} />
 */
const FeatureFlagRoute = ({
  name,
  onValue = 'on',
  redirectTo = routes.dashboard.path,
  ...props
}: Props) => (
  <FeatureFlag name={name}>
    {(treatment) => {
      // This check below is needed so the user isn't redirected while we're loading the flags.
      // As all flags are initially DEFAULT_SPLIT_TREATMENT_VALUE, we'll allow them to access the route
      // and only redirect if needed once the flag is loaded.
      if (treatment === DEFAULT_SPLIT_TREATMENT_VALUE) {
        return <Route {...props} />;
      } else if (RA.isString(onValue) && treatment === onValue) {
        return <Route {...props} />;
      } else if (RA.isArray(onValue) && onValue.includes(treatment)) {
        return <Route {...props} />;
      } else {
        return <Redirect to={redirectTo} />;
      }
    }}
  </FeatureFlag>
);

export default FeatureFlagRoute;
