import React, { useContext } from 'react';
import { __RouterContext } from 'react-router';
import { Route, useLocation } from 'react-router-dom';

import type { Props as ModalProps } from 'components/lib/ui/Modal';
import Modal from 'components/lib/ui/Modal';

export type Props = ModalProps & {
  path: string | string[];
  exact?: boolean;
};

const RouteModal = ({ path, exact, onClose, ...rest }: Props) => {
  const {
    match: { url: parentUrl },
  } = useContext(__RouterContext);

  const { search } = useLocation();

  return (
    <Route
      path={path}
      exact={exact}
      render={({ history }) => (
        <Modal
          {...rest}
          onClose={() => {
            history.push(parentUrl + search);
            onClose?.();
          }}
        />
      )}
    />
  );
};

export default RouteModal;
