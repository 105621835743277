import * as React from 'react';
import styled from 'styled-components';

import ColorSelectField from 'components/lib/form/ColorSelectField';
import { Label } from 'components/lib/form/FormItemContainer';
import TextField from 'components/lib/form/TextField';
import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';

const StyledColorSelectField = styled(ColorSelectField)``;

const StyledTextField = styled(TextField)``;

const InputWrapper = styled(FlexContainer).attrs({ alignStart: true })`
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
  position: relative;

  ${StyledTextField} input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    :focus {
      /* Needed so input borders don't overlap */
      z-index: 1; /* stylelint-disable-line plugin/no-z-index */
    }
  }

  ${StyledColorSelectField} {
    margin-bottom: 0;

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      :not(:focus) {
        border-right-color: transparent;
      }
    }
  }

  ${StyledTextField} {
    flex: 1;
  }
`;

type Props = {
  colorField: React.ComponentProps<typeof ColorSelectField>;
  textField: React.ComponentProps<typeof TextField>;
  label?: string;
  isMasked?: boolean;
};

const CombinedColorTextField = ({ colorField, textField, label, isMasked = true }: Props) => (
  <>
    {!!label && <Label>{label}</Label>}
    <InputWrapper>
      <StyledColorSelectField {...colorField} />
      <StyledTextField {...textField} hideLabel {...(isMasked ? maskClassProps : {})} />
    </InputWrapper>
  </>
);

export default CombinedColorTextField;
