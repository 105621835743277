import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';

const SectionHeader = styled(FlexContainer).attrs({ justifyBetween: true })`
  height: 36px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.grayBackground};
  padding: 0 ${({ theme }) => theme.spacing.large};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.textLight};
`;

export default SectionHeader;
