export type DashboardPlatform = 'web' | 'mobile';

export enum DashboardWidgetName {
  PLAN = 'plan',
  CASH_FLOW = 'cash_flow',
  NET_WORTH = 'net_worth',
  TRANSACTIONS = 'transactions',
  GOALS = 'goals',
  GETTING_STARTED = 'getting_started',
  REVIEW = 'review',
  INVESTMENTS = 'investments',
  RECURRING = 'recurring',
  ADVICE = 'advice',
}

export type DashboardColumnLayout = DashboardWidgetName[];

export type DashboardWidgetConfig = {
  enabled: boolean;
  filter?: string;
};

export type DashboardWidgetsConfig = {
  [name in DashboardWidgetName]: DashboardWidgetConfig;
};

export type DashboardPlatformConfig = {
  layout: DashboardColumnLayout[];
  widgets: DashboardWidgetsConfig;
};

export type DashboardConfig = {
  [platform in DashboardPlatform]: DashboardPlatformConfig;
};
