import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NestedDropdownMenu from 'components/lib/ui/NestedDropdownMenu';
import NestedDropdownSubMenu from 'components/lib/ui/NestedDropdownSubMenu';
import Toggle from 'components/lib/ui/Toggle';

import { setDismissedMobileBrowserWarning } from 'actions';
import useToast from 'lib/hooks/useToast';
import { getDismissedMobileBrowserWarning } from 'selectors';

const EmployeeMenuOther = () => {
  const dispatch = useDispatch();

  const dismissedMobileBrowserWarning = useSelector(getDismissedMobileBrowserWarning);

  const { openErrorToast } = useToast();

  return (
    <NestedDropdownSubMenu title="Other">
      <NestedDropdownMenu
        items={[
          {
            title: 'Test Sentry',
            onClick: () => {
              throw new Error('Testing Sentry');
            },
          },
          {
            title: 'Open Error Toast',
            onClick: openErrorToast,
          },
          {
            title: 'Has Dismissed Mobile Browser Warning',
            right: (
              <Toggle
                checked={dismissedMobileBrowserWarning}
                onChange={({ target: { checked } }) =>
                  dispatch(setDismissedMobileBrowserWarning(checked))
                }
              />
            ),
          },
        ]}
      />
    </NestedDropdownSubMenu>
  );
};

export default EmployeeMenuOther;
