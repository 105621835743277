import React, { useMemo } from 'react';
import styled from 'styled-components';

import MessageCard from 'components/assistant/MessageCard';
import type { BarInfo } from 'components/lib/charts/SimpleBarChart';
import SimpleBarChart from 'components/lib/charts/SimpleBarChart';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import { BarChartBlock } from 'common/lib/assistant/blocks';
import { makeBlockRenderer } from 'common/lib/assistant/blocks/render';
import { CHART_COLORS, CHART_HEIGHT_PX } from 'common/lib/assistant/constants';
import { formatCurrency, formatCurrencyMaybeThousands } from 'common/utils/Currency';
import useTheme from 'lib/hooks/useTheme';

const Root = styled(MessageCard)`
  width: 70%;

  @media (max-width: ${({ theme }) => theme.breakPoints.xl}px) {
    width: 100%;
  }
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true, column: true })`
  height: ${CHART_HEIGHT_PX}px;
  width: 100%;
`;

const BarChartBlockRenderer = makeBlockRenderer(BarChartBlock)(({
  data: { chartData, columns },
  incomplete,
}) => {
  const theme = useTheme();

  const bars = useMemo(
    () =>
      columns.map(
        ({ label, valueType }, i): BarInfo => ({
          label,
          dataKey: `${label}-${i}`,
          color: theme.color[CHART_COLORS[i % CHART_COLORS.length]],
          valueFormatter: (value) =>
            valueType === 'currency' ? formatCurrency(Math.abs(value ?? 0)) : `${value}`,
        }),
      ),
    [columns, theme],
  );

  const data = useMemo(
    () =>
      chartData.map(({ label, values }) => ({
        label,
        ...bars.reduce(
          (acc, { dataKey }, i) => ({
            ...acc,
            [dataKey]: Math.abs(values[i] ?? 0),
          }),
          {},
        ),
      })),
    [chartData, bars],
  );

  return (
    <Root>
      {incomplete ? (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ) : (
        <SimpleBarChart
          width="100%"
          height={CHART_HEIGHT_PX}
          data={data}
          bars={bars}
          yAxisTickFormatter={
            columns[0]?.valueType === 'currency' ? formatCurrencyMaybeThousands : undefined
          }
          isAnimationActive={false}
        />
      )}
    </Root>
  );
});

export default BarChartBlockRenderer;
