import { useMutation } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';

import useCreateMessageThread from 'common/lib/hooks/assistant/useCreateMessageThread';
import useQuery from 'common/lib/hooks/useQuery';
import boxShadow from 'common/lib/styles/boxShadow';
import { spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type { Web_GetUserOptedInQuery } from 'common/generated/graphql';

const Banner = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.color.redDark} 0%,
    ${({ theme }) => theme.color.orangeDark} 100%
  );
  ${boxShadow.medium}
  border-radius: ${({ theme }) => theme.radius.medium};

  padding: 0 0 0 ${({ theme }) => theme.spacing.xlarge};
`;

const Root = styled.div`
  width: 100%;
  padding: ${isV2Theme(0, spacing.gutter)} ${spacing.gutter} ${isV2Theme(spacing.gutter, 0)};

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    padding: ${isV2Theme(0, spacing.xsmall)} ${spacing.xsmall} ${isV2Theme(spacing.xsmall, 0)};
  }
`;

const TextContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: flex-start;
  align-items: center;
`;

const Text = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.white};
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const StarsIcon = styled(Icon).attrs({ name: 'stars-filled', size: 21 })`
  color: ${({ theme }) => theme.color.white};
`;

// increasing the clickable area of the close button
const CloseButtonIcon = styled(ButtonIcon).attrs({ name: 'x', size: 16 })`
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;

  height: 100%;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  padding: 0 ${({ theme }) => theme.spacing.xlarge};
`;

const SpendingInsightsBanner = () => {
  const history = useHistory();

  const [isBannerVisible, setIsBannerVisible] = React.useState(false);

  const DEFAULT_AGENT_TYPE = 'spending_insights';

  const { data, isLoadingInitialData: loading } =
    useQuery<Web_GetUserOptedInQuery>(GET_USER_OPTED_IN);

  const { householdPreferences, me } = data ?? {};

  const { aiAssistantEnabled } = householdPreferences ?? {};

  const { profile } = me ?? {};

  const { aiAssistantOptedInAt, dismissedSpendingInsightsBanner } = profile ?? {};

  const needsToOptIn = aiAssistantOptedInAt === null;

  const showSpendingInsightsBanner = !dismissedSpendingInsightsBanner;

  React.useEffect(() => {
    setIsBannerVisible(showSpendingInsightsBanner);
  }, [showSpendingInsightsBanner]);

  const [createMessageThread] = useCreateMessageThread({
    variables: {
      agentType: DEFAULT_AGENT_TYPE,
    },
  });

  const [updateSpendingInsightsBanner] = useMutation(UPDATE_SPENDING_INSIGHTS_BANNER_MUTATION);

  const hideBanner = async () => {
    await updateSpendingInsightsBanner({
      variables: {
        dismissedSpendingInsightsBanner: true,
      },
    });
  };

  return (
    <>
      {isBannerVisible && !loading && (
        <Root>
          <Banner
            onClick={() => {
              // create message thread if the user has opted in
              hideBanner();

              if (aiAssistantEnabled && !needsToOptIn) {
                const createThread = async () => {
                  const { data } = await createMessageThread();
                  const threadId = data?.createMessageThread?.messageThread?.id;

                  // redirects to the assistant page with the threadId
                  threadId && history.push(routes.assistant({ threadId }));
                };

                createThread();
              } else {
                // redirect the user to the assistant page so they can opt in
                history.push(
                  routes.assistant({
                    queryParams: { agentType: DEFAULT_AGENT_TYPE },
                  }),
                );
              }
            }}
          >
            <TextContainer>
              <StarsIcon />
              <Text>{COPY.DASHBOARD_AI_INSIGHTS_BANNER}</Text>
            </TextContainer>
            <CloseButtonIcon
              onClick={(event) => {
                event?.stopPropagation();
                setIsBannerVisible(false);
                hideBanner();
              }}
            />
          </Banner>
        </Root>
      )}
    </>
  );
};

// TODO: move to a shared file if we decide to keep the spending insights special link and banner
export const GET_USER_OPTED_IN = gql(`
  query Web_GetUserOptedIn {
    householdPreferences {
      id
      aiAssistantEnabled
    }
    me {
      id
      profile {
        id
        aiAssistantOptedInAt
        dismissedSpendingInsightsBanner
      }
    }
  }
`);

const UPDATE_SPENDING_INSIGHTS_BANNER_MUTATION = gql(`
  mutation UpdateDismissedSpendingInsightsBanner($dismissedSpendingInsightsBanner: Boolean!) {
    updateUserProfile(
      input: { dismissedSpendingInsightsBanner: $dismissedSpendingInsightsBanner }
    ) {
      userProfile {
        dismissedSpendingInsightsBanner
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export default SpendingInsightsBanner;
