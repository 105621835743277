import styled, { css } from 'styled-components';

import Icon from 'components/lib/ui/Icon';

type Props = {
  expanded: boolean;
  rotateDeg?: '180' | '-90';
};

const ChevronIcon = styled(Icon).attrs({
  name: 'chevron-down',
})<Props>`
  transition: transform 0.3s ease-out;
  cursor: pointer;
  ${({ expanded, rotateDeg = '180' }) =>
    ((expanded && rotateDeg === '180') || (!expanded && rotateDeg === '-90')) &&
    css`
      transform: rotate(${rotateDeg}deg);
    `}
`;

export default ChevronIcon;
