import { gql, useMutation } from '@apollo/client';
import type { MutationHookOptions } from '@apollo/client';
import { useCallback } from 'react';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import type {
  DeleteGoalV2,
  DeleteGoalV2Variables,
} from 'common/generated/graphQlTypes/DeleteGoalV2';

const useDeleteGoal = (options?: MutationHookOptions<DeleteGoalV2, DeleteGoalV2Variables>) => {
  const [performMutation, mutationInfo] = useMutation<DeleteGoalV2, DeleteGoalV2Variables>(
    MUTATION,
    options,
  );

  const deleteGoal = useCallback(
    (id: string) =>
      performMutation({
        variables: {
          input: {
            id,
          },
        },
      }),
    [performMutation],
  );

  return [deleteGoal, mutationInfo] as const;
};

const MUTATION = gql`
  mutation Common_DeleteGoalV2($input: DeleteGoalInput!) {
    deleteGoalV2(input: $input) {
      success
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default useDeleteGoal;
