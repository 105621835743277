import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';

import * as actions from 'common/state/dashboard/actions';
import type { DashboardConfig } from 'common/state/dashboard/types';
import { DashboardWidgetName } from 'common/state/dashboard/types';

const { setDashboardConfig } = actions;

type DashboardAction = ActionType<typeof actions>;

const DEFAULT_DASHBOARD_CONFIG: DashboardConfig = {
  mobile: {
    layout: [
      [
        DashboardWidgetName.REVIEW,
        DashboardWidgetName.PLAN,
        DashboardWidgetName.NET_WORTH,
        DashboardWidgetName.GOALS,
        DashboardWidgetName.CASH_FLOW,
        DashboardWidgetName.TRANSACTIONS,
      ],
    ],
    widgets: {
      [DashboardWidgetName.GETTING_STARTED]: { enabled: false },
      [DashboardWidgetName.PLAN]: { enabled: true },
      [DashboardWidgetName.NET_WORTH]: { enabled: true },
      [DashboardWidgetName.GOALS]: { enabled: true },
      [DashboardWidgetName.CASH_FLOW]: { enabled: true },
      [DashboardWidgetName.TRANSACTIONS]: { enabled: true },
      [DashboardWidgetName.REVIEW]: { enabled: true },
      [DashboardWidgetName.INVESTMENTS]: { enabled: false },
      [DashboardWidgetName.RECURRING]: { enabled: false },
      [DashboardWidgetName.ADVICE]: { enabled: false },
    },
  },
  web: {
    layout: [
      [DashboardWidgetName.PLAN, DashboardWidgetName.NET_WORTH, DashboardWidgetName.GOALS],
      [DashboardWidgetName.CASH_FLOW, DashboardWidgetName.TRANSACTIONS, DashboardWidgetName.ADVICE],
    ],
    widgets: {
      [DashboardWidgetName.GETTING_STARTED]: { enabled: false },
      [DashboardWidgetName.PLAN]: { enabled: true },
      [DashboardWidgetName.NET_WORTH]: { enabled: true },
      [DashboardWidgetName.GOALS]: { enabled: true },
      [DashboardWidgetName.CASH_FLOW]: { enabled: true },
      [DashboardWidgetName.TRANSACTIONS]: { enabled: true },
      [DashboardWidgetName.REVIEW]: { enabled: false },
      [DashboardWidgetName.INVESTMENTS]: { enabled: false },
      [DashboardWidgetName.RECURRING]: { enabled: false },
      [DashboardWidgetName.ADVICE]: { enabled: false }, // Change to true after launching
    },
  },
};

const INITIAL_DASHBOARD_STATE: DashboardState = {
  config: DEFAULT_DASHBOARD_CONFIG,
};

export type DashboardState = {
  config: DashboardConfig;
};

const dashboard = createReducer<DashboardState, DashboardAction>(
  INITIAL_DASHBOARD_STATE,
).handleAction(setDashboardConfig, (state, { payload: config }) => ({ ...state, config }));

export default dashboard;
