import React from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import GoalAccountAllocationRow from 'components/goalsV2/AccountAllocationRow';
import GoalAccountsMapRow from 'components/goalsV2/GoalAccountsMapRow';
import CardFooter from 'components/lib/ui/CardFooter';
import Empty from 'components/lib/ui/Empty';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import useSelectGoalAccounts from 'common/lib/hooks/goalsV2/useSelectGoalAccounts';
import { formatCurrencyNoCents } from 'common/utils/Currency';
import { useModalContext } from 'lib/contexts/ModalContext';

const Container = styled(FlexContainer).attrs({ column: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledText = styled(Text).attrs({ size: 'xsmall', color: 'textLight', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.default};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

const LegacyBalanceBox = styled.div`
  border-radius: ${({ theme }) => theme.radius.medium};
  background-color: ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.small};
  text-align: center;
  margin: ${({ theme }) => theme.spacing.default} 0;
`;

const SpinnerContainer = styled(FlexContainer).attrs({ center: true })`
  margin: ${({ theme }) => theme.spacing.xlarge} 0;
`;

type Props = {
  goalId: string;
  onClickBackButton?: () => void;
  onClickDone?: () => void;
};

const GoalSelectAccountsModal = ({ goalId, onClickBackButton, onClickDone }: Props) => {
  const {
    sections,
    allocationsByAccountId,
    initiallyHadAllocations,
    isLoadingInitialData,
    goal,
    legacyGoal,
    isDebtGoal,
  } = useSelectGoalAccounts({ goalId });

  const { close } = useModalContext();

  return (
    <ModalCard title="Assign your accounts" onClickBackButton={onClickBackButton}>
      <Container>
        {goal && <GoalAccountsMapRow data={goal} />}
        {
          // we only want to show the original legacy goal balance if the user hasn't set allocations yet
          legacyGoal && !initiallyHadAllocations && (
            <LegacyBalanceBox>
              <Text size="small" weight="medium" italic>
                Actual balance for this goal was{' '}
                {formatCurrencyNoCents(legacyGoal.contributedBalance)}
              </Text>
            </LegacyBalanceBox>
          )
        }
        <Switch>
          <Case when={isLoadingInitialData}>
            <SpinnerContainer>
              <LoadingSpinner />
            </SpinnerContainer>
          </Case>
          <Case when={sections.length === 0}>
            <Empty title="No accounts" />
          </Case>
          <Case default>
            {sections.map(({ title, accounts }) => (
              <React.Fragment key={title}>
                <StyledText>{title}</StyledText>
                {accounts.map((item) => {
                  const accountId = item.id;
                  const allocation = allocationsByAccountId[accountId];

                  return (
                    <GoalAccountAllocationRow
                      key={item.id}
                      goalId={goalId}
                      isDebtGoal={isDebtGoal}
                      account={item}
                      allocation={allocation}
                    />
                  );
                })}
              </React.Fragment>
            ))}
          </Case>
        </Switch>
      </Container>
      <CardFooter>
        <PrimaryButton onClick={onClickDone ?? close}>Done</PrimaryButton>
      </CardFooter>
    </ModalCard>
  );
};

export default GoalSelectAccountsModal;
