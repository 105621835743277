import React from 'react';
import styled from 'styled-components';

import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

const Container = styled.td`
  border-radius: ${({ theme }) => theme.radius.medium};
  padding: ${({ theme }) => theme.spacing.xxxlarge};
  text-align: center;
`;

const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.color.textLight};
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

const Button = styled(PrimaryButton)`
  margin: ${({ theme }) => theme.spacing.xlarge} auto 0;
`;

export type EmptyProps = {
  title: string;
  subtitle?: string;
  button?: {
    title: string;
    onClick: () => void;
  };
};

type Props = {
  columnsCount: number;
} & EmptyProps;

const TableEmptyContainer = ({ columnsCount, title, subtitle, button }: Props) => (
  <Container colSpan={columnsCount}>
    <Title>{title}</Title>
    {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
    {button && (
      <Button onClick={button.onClick} size="medium">
        {button.title}
      </Button>
    )}
  </Container>
);

export default TableEmptyContainer;
