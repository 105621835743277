import pluralize from 'pluralize';

import { DashboardWidgetName } from 'common/state/dashboard/types';

import { HELP_CENTER_AI_ASSISTANT } from 'common/constants/externalUrls';

import { BudgetVariability } from 'common/generated/graphql';

/** Credentials */
export const FORCE_REFRESH_TOOLTIP_COPY =
  'Monarch will automatically update information from the institution every 24 hours, sometimes more frequently.\n\n' +
  'Refreshing your accounts is available once within a 12 hour period.';
export const REFRESHING_CONNECTIONS_TOAST_TITLE = 'Refreshing your connections...';
export const REFRESHING_CONNECTIONS_TOAST_SUBTITLE =
  'This may take a couple minutes.\nFeel free to leave this page.';

/** Onboarding */
export const ONBOARDING_DASHBOARD_OVERLAY_TITLE =
  'See a snapshot of your most important financial details at a glance';

export const ONBOARDING_ACCOUNTS_OVERLAY_TITLE =
  'View all of your account balances and net worth history';
export const ONBOARDING_TRANSACTIONS_OVERLAY_TITLE =
  'See your transaction history across all of your accounts';
export const ONBOARDING_CASH_FLOW_OVERLAY_TITLE =
  'Understand your income, spending and savings history over time';
export const ONBOARDING_GOALS_OVERLAY_TITLE =
  'Contribute and track progress towards your financial goals';
export const ONBOARDING_PLAN_OVERLAY_TITLE =
  "Choose the budgeting method you'd like to use and we'll walk you through setting it up.";

export const ONBOARDING = {
  REGISTER_EMAIL: {
    TITLE: 'Add your email',
    SUBTITLE:
      'To get started, create an account. This helps us keep your financial information safe and secure.',
    BUTTON: 'Send verification code',
  },
  VERIFY_EMAIL: {
    TITLE: 'Verify your email',
    SUBTITLE: (email: string) => `We just sent a 6-digit code to ${email}, enter it below:`,
    BUTTON: 'Verify email',
    RESENT_EMAIL: 'Email sent, check your inbox.',
  },
  CREATE_PASSWORD: {
    TITLE: 'Create your password',
    SUBTITLE:
      'Your password must be at least 8 characters long, and include 1 symbol and 1 number.',
    BUTTON: 'Create password',
  },
  PRIORITIES: {
    TITLE: 'What brings you to Monarch?',
    SUBTITLE: "Select your financial priorities below and we'll help you achieve them in Monarch.",
    BUTTON: 'Continue',
  },
  ATTRIBUTION: {
    TITLE: 'How did you hear about Monarch?',
    BUTTON: 'Continue',
  },
  DEMOGRAPHICS: {
    TITLE: 'Tell us about yourself',
    SUBTITLE:
      'Share a few more details about your personal situation so we can tailor your experience.',
    FIELDS: {
      NAME: {
        LABEL: 'Full Name',
        PLACEHOLDER: 'Tell us your name...',
      },
      MANAGE_WITH_PARTNER: {
        LABEL: 'Do you manage your finances with a partner?',
      },
      MANAGE_WITH_PROFESSIONAL: {
        LABEL: 'Do you work with a financial professional?',
      },
      COUNTRY: {
        LABEL: 'Country of residence',
      },
    },
    BUTTON: 'Continue',
  },
  INVITE_PARTNER: {
    SCREEN_TITLE: 'Invite partner',
    TITLE: 'Invite your partner to collaborate at no extra cost',
    SUBTITLE:
      "Your partner will receive an email inviting them to your household account. They'll get their own login and have full access to your Monarch household.",
    FIELDS: {
      EMAIL: {
        LABEL: 'Email',
        PLACEHOLDER: "Your partner's email...",
        INVALID_EMAIL_ERROR: 'Please, enter a valid email address.',
      },
    },
    ERRORS: {
      DUPLICATE:
        "This user already has a Monarch account. They'll have to delete their existing\nhousehold before they can join yours and collaborate.",
    },
    BUTTON: 'Continue',
  },
  TESTIMONIALS: {
    TITLE: 'Welcome to Monarch!',
    SUBTITLE:
      'You now have one of the best tools to help make your money work harder for you. Here is what a few other people have said about Monarch:',
    BUTTON: 'Continue',
  },
  SPENDING_INSIGHTS: {
    TITLE: 'Track your spending with personalized insights from your assistant',
    BUTTON: 'Continue',
    CHART: {
      TITLE: '🍽️ Restaurants & Bars',
      ROW_ONE: {
        TITLE: 'You',
        VALUE: '$175',
        BAR_WIDTH: '70%',
      },
      ROW_TWO: {
        TITLE: 'Others like you',
        VALUE: '$300',
        BAR_WIDTH: '100%',
      },
    },
  },
  CONNECT_ACCOUNT_HYPE: {
    TITLE: 'Start feeling in control of your finances now',
    SUBTITLE:
      'Users who connect their accounts within 10 minutes report a greater sense of financial control.',
    BUTTON: 'Complete',
  },
  SPENDING_INSIGHTS_CONNECT_ACCOUNT: {
    TITLE: "Let's begin by connecting the account where most of your spending happens",
    SUBTITLE:
      "Securely give Monarch read-only access to automatically keep track of your accounts. We don't sell or share your data.",
    BUTTON: 'Add primary spending account',
    SKIP_BUTTON: 'Skip for now',
  },
  CONNECT_ACCOUNT_BEFORE_PAYWALL: {
    TITLE: "Let's begin by connecting the account where most of your spending happens",
    SUBTITLE:
      "Securely give Monarch read-only access to automatically keep track of your accounts. We don't sell or share your data.",
    SKIP_BUTTON: 'Skip for now',
  },
};

/** Billing  */
export const DELETE_HOUSEHOLD_BUTTON_TITLE = 'Delete my Monarch household';
export const DELETE_HOUSEHOLD_CONFIRMATION_TITLE = 'Delete your Monarch household';
export const DELETE_HOUSEHOLD_CONFIRMATION_SUBTITLE = `We're sorry to see you go! This will delete all of your financial accounts and transactions in Monarch, and cannot be undone. `;
export const DELETE_HOUSEHOLD_CONFIRMATION_SUBTITLE_REASON = `Please tell us your reason for canceling so we can continue to improve Monarch.`;

export const CANCEL_SUBSCRIPTION_MODAL_TITLE = 'Cancel subscription';
export const CANCEL_SUBSCRIPTION_MODAL_SUBTITLE =
  "We're sorry to see you go! We'd really appreciate it if you could let us know what " +
  "we could be doing better. We're always trying to improve and we value customer " +
  'feedback above all.';

/** Transactions */
export const TRANSACTIONS = {
  SPLIT_MESSAGE: 'This transaction has been split',
  NOT_FOUND: {
    TITLE: 'Transaction not found',
    SUBTITLE:
      'The transaction you are looking for no longer exists. This can happen when a pending transaction is replaced by an updated version of the same transaction.',
  },
  HIDE_TRANSACTION_HELP_TEXT:
    'If you hide a transaction, it will not show up in your spending and budget calculations.',
  NEEDS_REVIEW_HELP_TEXT:
    'If you mark a transaction as needing review, it will show up in your to review lists.',
  NEEDS_REVIEW_EMPTY_TITLE: 'You reviewed everything!',
  NEEDS_REVIEW_EMPTY_SUBTITLE:
    'Uncategorized and transactions flagged by custom rules will show up here.',
  ALLOW_EDITS_TO_PENDING_DISCLAIMER: `Please be aware that matching pending transactions to their posted version is not always feasible due to potential changes in amounts and other details. If we cannot match them, any changes you make to the pending transaction will be lost, and you will have to update the posted transaction again.`,
  ALLOW_EDITS_TO_PENDING_SUBTITLE: `When this setting is turned off, pending transactions will be read-only, and will not be included in your budget and cash flow reports. When it is turned on, pending transactions will be editable and included in all reports.`,
  SETTINGS: {
    SECTION_HEADER: 'How transactions work',
    NEW_TRANSACTIONS_NEED_REVIEW: {
      TITLE: 'Mark new transactions as Needs Review',
      SUBTITLE:
        'All newly synced transactions will be labeled as Needs Review and will be displayed in the Needs Review filter.',
    },
    UNCATEGORIZED_NEED_REVIEW: {
      TITLE: 'Mark uncategorized transactions as Needs Review',
      SUBTITLE:
        'Any new uncategorized synced transactions will be marked as Needs Review and will be displayed in the Needs Review filter.',
    },
    PENDING: {
      TITLE: 'Allow edits to pending transactions',
    },
    INVESTMENT_TRANSACTIONS_ENABLED: {
      TITLE: 'Investment Transactions',
      SUBTITLE:
        "When turned on, investment transactions will start to sync to your investment accounts that are supported. These transactions will be categorized just like ordinary transactions, so they'll impact your budget and reports.",
    },
  },
  DELETE_CONFIRMATION: {
    TITLE: 'Are you sure you want to delete this transaction?',
    SUBTITLE: 'By deleting this transaction, you will remove all of its data from Monarch.',
  },
};

/** Dashboard */
export const DASHBOARD_WIDGET_LONG_NAMES: { [name in DashboardWidgetName]: string } = {
  [DashboardWidgetName.CASH_FLOW]: 'Spending trend',
  [DashboardWidgetName.TRANSACTIONS]: 'Transactions',
  [DashboardWidgetName.NET_WORTH]: 'Net worth',
  [DashboardWidgetName.PLAN]: 'Budget',
  [DashboardWidgetName.GOALS]: 'Savings goals',
  [DashboardWidgetName.GETTING_STARTED]: 'Getting started guide',
  [DashboardWidgetName.REVIEW]: 'Monthly review', // TODO: finalize name
  [DashboardWidgetName.INVESTMENTS]: 'Investments',
  [DashboardWidgetName.ADVICE]: 'Advice',
  [DashboardWidgetName.RECURRING]: 'Recurring transactions',
};
export const DASHBOARD_AT_LEAST_ONE_WIDGET_ERROR = 'Please enable at least one widget.';

export const DASHBOARD_EMPTY_BUTTON = 'Connect an account to get started';

export const DASHBOARD_AI_INSIGHTS = {
  OVERLAY_TITLE: "Let's begin by connecting the account where most of your spending happens",
  OVERLAY_BUTTON: 'Connect your bank & credit cards',
};

export const DASHBOARD_AI_INSIGHTS_BANNER =
  'Get personalized insights on your spending trends from AI Assistant';

/** Categories */
export const CATEGORIES_TOUR = {
  INCOME: {
    TITLE: 'Income categories',
    SUBTITLE:
      'Customize your income categories to understand historical income and plan for future income.',
  },
  EXPENSE: {
    TITLE: 'Expense categories',
    SUBTITLE:
      'Customize your expense categories to understand historical spending and plan for future expenses.',
  },
  TRANSFER: {
    TITLE: 'Transfer categories',
    SUBTITLE:
      'Customize your transfer categories to use when you move money between your accounts or institutions.',
  },
};

/** Investments */
export const INVESTMENTS_EMPTY_STATE_TITLE =
  'Analyze your portfolio performance across all of your investment accounts';

export const INVESTMENTS_EMPTY_STATE_TITLE_NEW =
  'Analyze your portfolio performance across all of your accounts and holdings';

export const INVESTMENTS_TABLE_EMPTY_STATE = {
  TITLE: 'No holdings found in the selected accounts',
  SUBTITLE: 'Please sync an investment account to analyze your portfolio.',
  BUTTON_TITLE: 'Add investment account',
};

export const INVESTMENTS_TABLE_EMPTY_STATE_NEW = {
  TITLE: 'Holdings not found',
  SUBTITLE: 'Search for an investment and enter the quantity you own to track the value.',
  BUTTON_TITLE: 'Add holding',
};

/** Other */
export const DEFAULT_ERROR_MESSAGE = 'Sorry, something went wrong. Please try again.';
export const DEFAULT_REQUIRED_MESSAGE = 'This field is required';
export const BEFORE_UNLOAD_MESSAGE =
  'You have unsaved changes. Are you sure you want to leave this page?';

/* Accounts */
export const ACCOUNTS = {
  NO_ACCOUNTS_ERROR: `We couldn't find any accounts for your bank. This is likely a problem with our data provider's integration with your bank. Please try again or contact support.`,
  DEFAULT_LINK_ERROR: `We encountered an error when trying to sync with your bank. Please try again or contact support.`,
  ZILLOW_UP_TO_DATE: `We'll keep the value of this property up to date by periodically syncing from Zillow.`,
  NET_WORTH_TITLE_TOOLTIP: `This may not show your correct net worth if you haven't added all assets and liabilities.`,
  REFRESH_ALL_TOAST_TITLE: `Refreshing your connections...`,
  REFRESH_ALL_TOAST_SUBTITLE: `This may take a couple minutes.\nFeel free to leave this page.`,
  REFRESH_ALL_TOAST_BUTTON: `dismiss`,
  // Copy balances
  COPY_BALANCES_MODAL_TEXT:
    'The balance history you select will replace the balance history in the account you choose below. Please note that the balance history on this account will remain unchanged.',
  SELECT_DATA_PROVIDER: {
    TITLE: 'Try other connections',
    DESCRIPTION: `Monarch works with multiple account connectors. We always offer the best connection first based on success rate but you can try another connector at anytime.`,
    ISSUES_REPORTED_DEFAULT_TITLE: (institutionName: string) =>
      `${institutionName} is reporting connection issues`,
  },
  INVERT_SYNCED_BALANCE_TITLE: 'Invert account balance',
  USE_AVAILABLE_BALANCE_TITLE: 'Use available balance instead of current balance',
  INVERT_SYNCED_BALANCE_SUBTITLE:
    'This will invert your account balance if updates from your bank are not syncing correctly.',
  USE_AVAILABLE_BALANCE_SUBTITLE: `This will use the provider's available balance instead of the current balance for display purposes and when creating balance snapshots.`,
  VEHICLE_SEARCH_SUBTITLE:
    "We'll look up the estimated value of your vehicle based on your VIN number and sync its value automatically.",
  COMING_SOON: 'Coming soon!',
  INVESTMENT_TRANSACTIONS_NOT_AVAILABLE:
    "We're currently working on adding support for investment transactions. Please note that they won't be visible for now.",
  ACCOUNT_DETAIL_GRAPH_TITLE: 'CURRENT BALANCE',
  ACCOUNT_DETAIL_GRAPH_TITLE_SELECTED_DATE: 'BALANCE',
};

/* Demo household */
export const DEMO_HOUSEHOLD = {
  PAGE_DISABLED: 'This page is disabled in demo mode',
  FEATURE_DISABLED: 'This feature is disabled in demo mode',
  MODAL_SUBTITLE:
    'Please sign up to access all of the features Monarch has to offer. ' +
    'You can use Monarch for free for 7 days, no credit card required.',
};

/* Referrals */
export const REFERRALS = {
  TITLE: 'Give friends and family an extended trial to Monarch',
  TITLE_STRIPE: 'Get $14.99 in credits for every subscriber you refer',
  SUBTITLE:
    'When you refer others to Monarch, they get an extended 30-day trial. To learn more about referrals, check out our',
  SUBTITLE_STRIPE:
    'When you refer others to Monarch, they get an extended 30-day trial, and if they subscribe, you get $14.99 off your next bill.',
  GENERAL_SHARE_SUBJECT: 'Guest pass to Monarch',
  GENERAL_SHARE_BODY: `I love using Monarch to manage my money. You can get an extended 30-day free trial if you sign up with this link:`,
  SHARE_TWEET_BODY: (url: string) =>
    `I love using @monarch_money to manage my money. You can get an extended 30-day free trial if you sign up with this link: ${url}`,

  // Campaign-specific copy
  JUNE_2024_DISCOUNT: {
    GENERAL_SHARE_BODY:
      "Monarch is a true game changer for staying in sync with your finances and I don't know what I would do without it. " +
      'You can get an extended 30 day trial plus 50% off your first year if you sign up with this link.',
    SHARE_TWEET_BODY: (url: string) =>
      "Monarch (@monarch_money) is a true game changer for staying in sync with your finances and I don't know what I would do without it. " +
      `You can get an extended 30 day trial plus 50% off your first year if you sign up with this link: ${url}`,
    HELP_CENTER_ARTICLE_URL:
      'https://help.monarchmoney.com/hc/en-us/articles/27209106888212-June-2024-Referral-Offer',
  },
};

/* Recurring */
export const RECURRING = {
  EMPTY_STATE_TITLE: 'Automatically identify and track your recurring transactions',
  EMPTY_ACCOUNTS_BUTTON_TEXT: 'Connect an account to get started',
  MARK_NOT_RECURRING_TOAST_TITLE: 'Marked merchant as not recurring',
  MARK_NOT_RECURRING_TOAST_SUBTITLE:
    'It will no longer show up on you recurring calendar and in the all merchants list',
  AMOUNT_ESTIMATE_TOOLTIP:
    'This estimate is based on the most recent recurring transactions at this merchant',
  SEARCH: {
    EMPTY_TITLE: 'No recurring merchants found yet',
    EMPTY_SUBTITLE:
      'Monarch will keep scanning for new recurring merchants with new transactions. ' +
      'You can also add more recurring merchants manually.',
    FOUND_TITLE: (createdCount: number) =>
      `${createdCount} recurring ${pluralize('merchant', createdCount)} added`,
    FOUND_SUBTITLE:
      'All recurring merchants were added to the list and calendar. ' +
      'If any are missing you can add them manually.',
    TOAST_TITLE: 'Finding recurring merchants...',
    TOAST_SUBTITLE:
      'This may take a few minutes. Feel free to leave this page and come back later.',
  },
  AWAITING_BALANCE: 'We will receive your next balance within 7 days of the statement issue date.',
  LIABILITY_ACCOUNT_SUBTITLE: 'Synced via credit report',
  LIABILITY_RECENT_BALANCES_HEADER: 'Recent Balances',
  LIABILITY_RECENT_BALANCES_EMPTY: 'There are no recent statements available for this account.',
  MERCHANT_TRANSACTIONS_EMPTY: 'There are no transactions yet for this merchant.',
  CREDIT_CARD_NO_CENTS: 'Note: We may not receive full dollar amounts for credit card accounts.',

  RECURRING_ACCOUNT_TOOLTIP:
    'Accounts synced with Spinwheel showing statement balances and due dates',
  RECURRING_MERCHANT_TOOLTIP: 'Recurring merchants',

  SPINWHEEL_ERRORS: {
    FRAUD: {
      TITLE: 'Fraudulent flag',
      SUBTITLE:
        'There seems to be a fraudulent flag on your Equifax credit report. In order to sync your bills with Monarch, please remove your fraudulent flag by contacting Equifax.',
    },
    GENERIC: {
      TITLE: 'Something went wrong',
      SUBTITLE:
        'There was an issue syncing with Spinwheel. Please try again later by clicking manage recurring in the recurring page.',
    },
  },
};

/* Premium */
export const PREMIUM = {
  PREMIUM_PLAN_NAME: 'Premium',
  MONTHLY_PLAN: 'Monthly',
  ANNUAL_PLAN: 'Annual',
  LIFETIME_PLAN: 'Lifetime',
  PLAN_COMPARISION: {
    TITLE: {
      TRIAL_AVAILABLE: (trialDurationDays: number) =>
        `Try Monarch free for ${trialDurationDays} days.\nCancel anytime.`,
      TRIAL_UNAVAILABLE:
        'Subscribe and take your finances to the next step with Monarch. Cancel anytime.',
      HAS_SPONSOR_CODE: 'Welcome to Monarch Money',
    },
  },
  UPGRADE_MODAL: {
    TITLE: 'Select a plan',
    SUBTITLE: {
      TRIAL_AVAILABLE: `We'll email you before you ever get charged. Cancel anytime during the trial.`,
      TRIAL_UNAVAILABLE:
        'You have already used a trial for Monarch. If you subscribe, you will be billed immediately.',
      HAS_SPONSOR_CODE: '',
    },
  },
  ONBOARDING_PLAN_SELECT: {
    TITLE: {
      DEFAULT: 'Select a plan',
      HAS_SPONSOR_CODE: 'Confirm your plan',
      TEST_DEFAULT_TO_ANNUAL: 'Experience Monarch today',
    },
    SUBTITLE: {
      DEFAULT: `We'll email you before you ever get charged. Cancel anytime during the trial.`,
      HAS_SPONSOR_CODE: '',
      TEST_DEFAULT_TO_ANNUAL: `We'll email you before you are ever charged. You can cancel anytime during the trial.`,
    },
  },
  UPGRADE_CTA: {
    TRIAL_AVAILABLE: 'Subscribe',
    TRIAL_UNAVAILABLE: 'Subscribe',
    HAS_SPONSOR_CODE: `Subscribe`,
  },
  SUBSCRIBE_CTA: {
    TRIAL_AVAILABLE: 'Try free & subscribe',
    TRIAL_UNAVAILABLE: 'Subscribe',
  },
  SUBSCRIPTION_ENDED: {
    TITLE: 'Your subscription has ended',
    SUBTITLE:
      "If you'd like to continue your subscription select the plan that works best for you.",
    BUTTONS: {
      DELETE_ACCOUNT: 'Delete my account',
      SUBSCRIBE_NOW: 'Subscribe now',
    },
  },
  TIMELINE: {
    TITLE: 'How your free trial works',
    SUBTITLE: 'Select a plan that works for you',
    PROGRESS: {
      TODAY: {
        TITLE: 'Today',
        DESCRIPTION: 'Sync your accounts to start tracking all of your money in one place.',
      },
      BEFORE_LAST_DAY: {
        DESCRIPTION:
          "We'll send you a reminder email that your trial is ending soon. Cancel anytime.",
      },
      IN_X_DAYS: {
        TITLE: (trialDays: number) => `In ${trialDays} days`,
        DESCRIPTION: (formattedDate: string) =>
          `Your subscription starts ${formattedDate} unless you've canceled during the trial.`,
      },
    },
    PRICING: {
      TRIAL: (trialDurationDays: number, annualPricePerYear: string, annualPricePerMonth: string) =>
        `${trialDurationDays} days free, then ${annualPricePerYear} annually (${annualPricePerMonth})`,
    },
    CTA: (trialDays: number | undefined) =>
      `Start ${trialDays ? `${trialDays}-day` : 'your'} trial`,
    CONTINUE_BUTTON: 'Continue',
    CANCEL_ANYTIME_TEXT: 'Cancel anytime during your trial.',
    FREE_TRIAL_CTA: (trialDays: number) => `Start your free ${trialDays}-day trial`,
    TODAYS_TOTAL: "Today's total:",
    AFTER_TRIAL_TOTAL: (trialEndDate: string) => `After trial (Billed ${trialEndDate}):`,
    DEFAULT_TO_ANNUAL: {
      CTA: 'Try free & subscribe',
    },
  },
};

export const SPONSORSHIP = {
  INVITE_CLIENT_EMAIL_SUBJECT: (advisorName: string) => `${advisorName} wants you to join Monarch`,
  INVITE_CLIENT_EMAIL_BODY: (advisorName: string, code: string, baseUrl: string) => `Hey there,

I'd like to add you as a client and cover the cost of Monarch Money. Use this link to sign-up, receive 100% off and grant me access to your Monarch account:

  ${baseUrl}/signup?c=${code}

If you're an existing Monarch user, you can receive 100% off and grant me access to your Monarch account using this link:

  ${baseUrl}/settings/billing/subscribe?c=${code}

What is Monarch?

Monarch is the all-in-one personal finance platform. Track all of your accounts in one place, collaborate with your partner, and achieve your goals. Learn more at:

  https://www.monarchmoney.com

Thanks,

${advisorName}`,
};

export const GOALS = {
  SELECT_GOALS: {
    TITLE: 'What are your goals for the future?',
    SUBTITLE:
      'Adding all of your goals will help you organize your priorities, accounts, and contributions. You can always adjust them later.',
    ASSET_GOALS_TITLE: 'Save up',
    ASSET_GOALS_SUBTITLE:
      'These goals can help you keep track of your progress as you save money for large purchases and important life events.',
    DEBT_GOALS_TITLE: 'Pay down',
    DEBT_GOALS_SUBTITLE:
      'These goals will help you keep track of your progress as you pay off different kinds of debt.',
  },
  RANK_GOALS: {
    TITLE: 'How do you want to prioritize your goals?',
    SUBTITLE: 'This will help determine what order to work on your goals.',
  },
  CUSTOMIZE_GOAL: {
    TITLE: 'Customize your goal',
  },
  TARGET_AMOUNTS: {
    TITLE: `How much money do you want to save?`,
    SUBTITLE: `Setting targets for your goals can help you stay focused.`,
    PLACEHOLDER: 'How much you want to save...',
  },
  STARTING_AMOUNTS: {
    TITLE: `How much money do you want to pay off?`,
    SUBTITLE: `Setting targets for your goals can help you stay focused.`,
  },
  MAP_ACCOUNTS: {
    TITLE: 'Which accounts do you want to use to track your goals?',
    SUBTITLE:
      'Assign accounts to your goals to track your progress automatically as balances change.',
  },
  SELECT_ACCOUNTS: {
    TITLE: 'Assign your accounts',
  },
  EMPTY: {
    TITLE: 'Track the goals that matter most to you',
    SUBTITLE:
      'Use bank accounts to sync seamlessly with your goals. Create savings, debt and investment goals. Track your activity using transactions from your accounts.',
    BUTTON: 'Get started',
    OVERLAY_TITLE: 'Contribute and track progress towards your financial goals',
  },
  ARCHIVED: {
    TITLE: 'This goal has been archived',
    DESCRIPTION:
      'The history of the contributions and balances are still here but budgets and tracking are no longer on.',
  },
  ARCHIVE_CONFIRMATION: {
    TITLE: 'Archive goal',
    DESCRIPTION:
      'Archiving this goal will disconnect from the accounts you used, freeing them up for other goals. You can still look at this goal and its transaction history.',
  },
  DELETE_CONFIRMATION: {
    TITLE: 'Delete goal',
    DESCRIPTION:
      'Deleting this goal will disconnect it from the accounts you used, freeing them up for other goals. You will lose the history of this goal.',
  },
  CONTRIBUTIONS_TOOLTIP: {
    title: 'Contributions',
    text: `Add transactions to your goal to track your contributions and spending.

Investment account contributions coming soon.`,
  },
  MONTHLY_BUDGETS: {
    TITLE: 'How much do you currently allocate to each of your goals?',
    SUBTITLE: 'This amount will be added to your budget to help you track your progress.',
  },
  PRE_TAX_BUDGETS: {
    TITLE: `How much of your pre-paycheck income do you want to contribute to your retirement savings each month?`,
    SUBTITLE: `This will be added to your goal to help you track your pre-paycheck contributions.`,
  },
  LOADING_STEPS: [
    `Reviewing accounts`,
    `Building future budgets`,
    `Calculating timelines`,
    `Wrapping up`,
  ],
  MIGRATE_PROMPT: {
    TITLE: `What's new in goals`,
    BULLET_POINTS: [
      {
        icon: 'list',
        title: `Now you can easily track savings, debt, and investment goals`,
        description: `Use the expanded list of goals to track and measure your progress on what matters most to you.`,
      },
      {
        icon: 'trending-up',
        title: `Seamlessly sync your accounts with your goal balances`,
        description: `Now you can assign multiple bank accounts to a goal and the balance of the goal will be kept in sync.`,
      },
      {
        icon: 'link',
        title: `Track your savings contributions using your transactions`,
        description: `Link your savings transactions to goals. Investment and debt goal contributions coming soon!`,
      },
    ],
    BUTTON: 'Get started',
  },
  DASHBOARD_CARD: {
    TITLE: 'Goals',
    SUBTITLE: (goalCount: number) =>
      goalCount > 1 ? `Your top ${goalCount} priorities` : `Your top priorities`,
    MIGRATE_PROMPT: {
      TITLE: 'The new goals experience is here!',
      SUBTITLE: 'Upgrade to the new Goals experience.',
      BUTTON_TITLE: `See what's new`,
    },
    EMPTY: {
      TITLE: 'Start your first goal',
      SUBTITLE: 'Put your savings to work and start saving up for something great.',
      BUTTON_TITLE: 'Start a goal',
    },
  },
  ALLOCATE: {
    BANNER: `Your balances have been updated, and can be used to reach your goals.`,
    TITLE: `How would you like to allocate the money in this account?`,
  },
  BALANCES: {
    EMPTY: {
      ASSET: 'Track the progress of your savings goal by designating the accounts where you save.',
      DEBT: 'Track the progress of your debt goal by assigning the accounts where you make payments.',
    },
  },
};

export const INSTITUTION = {
  FALLBACK_ISSUES_REPORTED_MESSAGE: `We're looking into issues reported for this institution in the past 24 hours.`,
};

export const SUPPORT = {
  FORM_DESCRIPTION:
    'Our contact volume is currently higher than normal. We are working hard to respond as quickly as possible, but please expect delays in receiving a response.',
  ERROR_TOAST_SUBTEXT:
    "We couldn't submit your request. The Monarch team has been notified and will get started on a fix.",
  FORM_GRANT_SUPPORT_ACCESS_LABEL:
    'Monarch authorized support agents may access my account to troubleshoot this issue.',
  FORM_TOPIC_LABEL: 'What do you need help with?',
  FORM_TOPIC_PLACEHOLDER: 'Select a topic...',
  FORM_REASON_LABEL: "What's the issue you're having?",
  FORM_REASON_PLACEHOLDER: 'Select a reason...',
  FORM_REASON_NO_OPTIONS_MESSAGE: 'Select a topic to get started',
  FORM_IMPACT_LABEL: 'How is this impacting your use of Monarch?',
  FORM_IMPACT_PLACEHOLDER: 'Select how this impacts you...',
  FORM_DESCRIPTION_LABEL: 'Tell us more — how can we help?',
  FORM_INSTITUTION_LABEL: 'Which institution are you having trouble with?',
  FORM_DESCRIPTION_PLACEHOLDER:
    'If you are having an issue with specific account or transaction please add additional details such as the name, type, and date.',
  FORM_SUCCESS_TITLE: 'Successfully sent to Customer Success',
  FORM_SUCCESS_SUBTITLE: [
    'Your message has been sent and will be reviewed by our team.',
    'We are seeing higher than normal contact volume, and are working diligently to get back to each of you in a timely manner.',
    'Please be aware that it may take longer than usual to receive a response.',
  ],
  REQUEST_REFUND_FORM_REASON_LABEL: 'Refund Reason',
  REQUEST_REFUND_FORM_DESCRIPTION_LABEL: 'Please share a few details about your request:',
};

export const ASSISTANT = {
  MESSAGE_THREAD_SUBJECT_PLACEHOLDER: 'New chat',
  DISCLAIMER: `Answers from the Assistant may occasionally contain incorrect or inaccurate information. The Assistant is intended for informational purposes only and is not intended to provide personal financial advice. [Learn more](${HELP_CENTER_AI_ASSISTANT})`,
  DELETE_THREAD_CONFIRMATION: {
    TITLE: 'Delete chat',
    DESCRIPTION: 'Deleting this chat will remove it from your inbox and history on your account.',
  },
  OPT_IN: {
    ICON: 'stars',
    TITLE: 'Welcome to your AI Assistant',
    DISCLAIMER: `By clicking the “Confirm” button above, you understand and confirm that your use of Monarch AI Assistant (the “Assistant”) is subject to our current [Terms of Service](https://www.monarchmoney.com/terms) and [Privacy Policy](https://www.monarchmoney.com/privacy), and you confirm your understanding of the above.`,
    BUTTON: 'Confirm',
    ROWS: [
      {
        icon: 'thumbs-up',
        title: 'It will get better with your feedback',
        description: `Use of the Assistant may result in incorrect or inaccurate information. Please rate the responses and flag anything that is inaccurate or helpful. We'll use this anonymized feedback to improve. [Learn more](${HELP_CENTER_AI_ASSISTANT})`,
      },
      {
        icon: 'lock',
        title: 'Your data is protected',
        description: `Our AI Assistant respects your privacy and only accesses your financial data when you ask it questions. We use GPT-4 by OpenAI to generate responses by sending the relevant financial data to OpenAI when you ask questions. Your data is never used by OpenAI to train the language model. [Learn more](${HELP_CENTER_AI_ASSISTANT})`,
      },
      {
        icon: 'book',
        title: 'Intended for informational purposes only',
        description: `Information provided by the Assistant does not constitute or substitute for financial advice of any kind. You should not rely on any information generated through the Assistant without first verifying the accuracy of such information with a qualified financial professional.`,
      },
    ],
  },
  SETTINGS_TOGGLE: {
    SUBTITLE: `When turned on, Assistant will appear in the navigation for your entire household. Answers from the Assistant may occasionally contain incorrect or inaccurate information.`,
  },
};

export const HOUSEHOLD_ACCESS_GRANTS = {
  ACCESS_GRANT_DURATION_DAYS: 60,
};

export const MEMBERS = {
  TITLE: 'Household members',
  SUBTITLE:
    "Invite trusted members to your household for free. They'll have full access to view and modify your household's data.",
  EMAIL_TEMPLATE_PLAIN_TEXT: `Hello!
    \n\n
    I'd love to collaborate on our finances using Monarch Money.
    \n\n
    Join me by signing up for the Monarch Advisor program here: https://www.monarchmoney.com/advisors.
    \n\n
    Once you're in, I can invite you to our household.`,
  EMAIL_TEMPLATE: {
    GREETING: 'Hello!',
    LINE_1: "I'd love to collaborate on our finances using Monarch Money.",
    LINE_2: 'Join me by signing up for the Monarch Advisor program here:\n',
    LINE_3: "Once you're in, I can invite you to our household.",
  },
  SPONSOR_NOT_FOUND: {
    TITLE: 'Advisor account not found',
    SUBTITLE: (email: string) => `The email ${email} doesn't have a `,
    NOTE: 'They must sign up to collaborate with you. After signing up, you can invite them to your household.',
    SEND_EMAIL_TITLE: 'Email you can send to your advisor',
    BUTTON: 'Send email',
    MONARCH_ADVISOR_ACCOUNT: 'Monarch Advisor account.',
  },
  ADVISOR_INVITED: {
    TITLE: 'Advisor added',
    SUBTITLE: (email: string) =>
      `An invitation has been sent to ${email}.\n\nThey should receive an email in their inbox to join your household.`,
  },
};

export const SELECTED_GOALS = {
  DELETE_ERROR:
    'Unable to delete goal while decreasing number. Please review your goals in the goal list and remove if necessary.',
};

export const GROUP_BUDGET = {
  BUDGET_BY_CATEGORY: 'Budget by individual categories within this group.',
  BUDGET_BY_GROUP: 'Budget with a single number for all categories within this group.',
};

/* Cash Flow */
export const CASH_FLOW = {
  SANKEY_EMPTY: 'No cash flow data yet for this time period',
  SHARE_POPOVER_CONTENT:
    'Generate an image of this chart to share with others. You have the option to hide amounts.',
  SHARE_POPOVER_HIDE_AMOUNTS: 'Hide amounts',
  SHARE_POPOVER_TRANSPARENT_BACKGROUND: 'Transparent background',
  SHARE_BUTTON: 'Share',
  GENERATE_IMAGE_BUTTON: 'Generate image',
};

/* Needs Review By */
export const NEEDS_REVIEW_BY = {
  ANYONE_OPTION: 'Anyone',
  PICKER_TITLE: 'Needs review by...',
  MARK_AS_REVIEWED: 'Mark as reviewed',
  MARK_AS_NEEDS_REVIEW: 'Mark as needs review',
  REVIEWED_LABEL: (reviewerName: string | null | undefined, formattedDate: string) =>
    `Reviewed ${reviewerName ? `by ${reviewerName}` : 'by a transaction rule'} on ${formattedDate}`,
};

/* Advisor Onboarding */
export const ADVISOR_ONBOARDING = {
  WELCOME: {
    TITLE: 'Get started with the Monarch Advisor Program',
    SUBTITLE: 'Test for free, and only pay once you onboard clients.',
    LABEL_EMAIL: 'Your Email Address',
    PLACEHOLDER_EMAIL: 'Enter your email...',
    SUBMIT_BUTTON: 'Sign up with email',
  },
  VERIFY_EMAIL: {
    RESEND_CODE_BUTTON: 'Resend to email',
    TOAST_RESEND_CODE_LOADING_TITLE: 'Sending email...',
    TOAST_RESEND_CODE_LOADING_SUBTITLE: 'Please wait while we send you a new verification email.',
    TOAST_RESEND_CODE_SUCCESS_TITLE: '📨 Email sent!',
    TOAST_RESEND_CODE_SUCCESS_SUBTITLE:
      'Please check your spam folder if you do not see it in your inbox.',
    TOAST_RESEND_CODE_ERROR_TITLE: 'Something went wrong',
    TOAST_RESEND_CODE_ERROR_SUBTITLE: 'Please try again or report this issue.',
  },
  SETUP_ACCOUNT: {
    TITLE: 'Set up your account',
    SUBTITLE_WITH_SSO: 'Enter your name to start onboarding your clients to Monarch.',
    SUBTITLE_WITHOUT_SSO:
      'Enter your name and set a password to start onboarding your clients to Monarch.',
    SUBMIT_BUTTON: 'Continue',
    PLACEHOLDER_NAME: 'Tell us your name...',
    PLACEHOLDER_PASSWORD: 'Enter your password...',
  },
  SELECT_PLAN: {
    TITLE: 'Help your clients stay on top of their money',
    SUBTITLE: 'Test-drive Monarch for free, and only pay once you onboard clients.',
    SUBMIT_BUTTON: 'Continue',
  },
  ERRORS: {
    ALREADY_SIGNED_UP_TITLE: 'You have already signed up',
    ALREADY_SIGNED_UP_SUBTITLE: 'Please sign in to your account to continue.',
    GENERAL_ERROR: 'Please try again or reach out to us at support@monarchmoney.com',
  },
};

export const BUDGET_VARIABILITY = {
  fixed: {
    label: 'Fixed',
    description:
      'Spending is usually the same every month, and cannot be easily reduced. Great for utilities, mortgage, bills, etc.',
  },
  flex: {
    label: 'Flexible',
    description:
      'Spending changes monthly, and can be reduced when you want to save more money. Great for restaurants, entertainment, etc.',
  },
  nonMonthly: {
    label: 'Non-Monthly',
    description:
      'Spending typically happens yearly, or less frequently than monthly. Great for annual bills, quarterly payments, etc.',
  },
};

/* Settings */

// Data
export const SETTINGS_DATA = {
  FIELDS: {
    DATE: {
      LABEL: 'Delete transactions before...',
      PLACEHOLDER: 'Select a date...',
    },
    INCLUDE_SYNCED: {
      LABEL: 'Include synced transactions',
    },
    INCLUDE_MANUAL: {
      LABEL: 'Include manually added transactions',
    },
    INCLUDE_UPLOADED: {
      LABEL: 'Include transactions uploaded via CSV',
    },
  },
  BUTTONS: {
    SUBMIT: (transactionsCount: number | undefined) => {
      if (!transactionsCount || transactionsCount === 0) {
        return 'Delete transactions';
      }

      return `Delete ${transactionsCount} ${pluralize('transaction', transactionsCount)}`;
    },
    CANCEL: 'Cancel',
    DELETE: 'Delete',
  },
  ERRORS: {
    DEFAULT: "We couldn't delete the transactions history. Please try again or report this issue.",
  },
  CONFIRMATION_MESSAGE: {
    TITLE: (expectedAffectedTransactionsCount: number) =>
      `Are you sure you want to delete ${pluralize(
        'transaction',
        expectedAffectedTransactionsCount,
        true,
      )}?`,
    MESSAGE: (transactionsCount: number) =>
      `${pluralize('This', transactionsCount)} ${pluralize(
        'transaction',
        transactionsCount,
      )} will be permanently removed from Monarch, and this action cannot be undone.`,
  },
  SUCCESS_MESSAGE: {
    TITLE: (transactionsCount: number) =>
      `Successfully deleted ${pluralize('transaction', transactionsCount, true)}`,
    MESSAGE: (transactionsCount: number) =>
      `${pluralize('This', transactionsCount)} ${pluralize(
        'transactions',
        transactionsCount,
      )} have been permanently removed.`,
  },
  DISCLAIMER:
    'These transactions will be permanently removed from Monarch, and this action cannot be undone.',
};

/* Reports */
export const REPORTS = {
  EMPTY_STATE_TITLE: 'Build custom reports to better understand where your money is going',
  EMPTY_STATE_BUTTON: 'Add account',
  CHART_PLACEHOLDER_TITLE: 'No data to show for this time period yet',
};

/** Budget */
export const BUDGET = {
  // TODO(@vanessa): ENG-9672
  FLEXIBLE_BUDGET: {
    TOOLTIP_TITLE: 'Available Flexible Budget',
    TOOLTIP_TEXT:
      'This number tracks the difference between the Flexible budget value, and the sum of the underlying category values.',
  },

  FLEXIBLE_BUDGET_EXCEEDED: {
    TOOLTIP_TITLE: 'Budget Exceeded',
    TOOLTIP_BUTTON_TEXT: 'Tap here to set your flex budget to the sum of your category budgets.',
    TOOLTIP_TEXT: (flexBudget: string, categoriesBudget: string) =>
      `Your category budgets (${categoriesBudget}) are greater than your Flexible budget (${flexBudget}).`,
  },

  ROLLOVER_SETTINGS: {
    STARTING_MONTH_DESCRIPTION:
      'Your rollover balance will start carrying over from this month onward. Any excess budget amounts from before this date will not rollover.',
    STARTING_BALANCE_DESCRIPTION:
      'You can start with a pre-allocated balance which will start with the amount you enter above, and accrue going forward from the starting month.',
    FREQUENCY_DESCRIPTION: 'Choose how often you expect expenses to happen in this category.',
    TARGET_AMOUNT_DESCRIPTION: 'Choose how much you want to spend per frequency on this category.',
  },

  ONBOARDING: {
    ACCOUNTS: {
      TITLE: 'Confirm that your primary accounts are connected',
      DESCRIPTION:
        "We calculate your budget defaults based on your historical income and spending.\n Make sure all your accounts are connected, or add them now if you're just getting started.",
    },
    INCOME: {
      TITLE: 'Does this look right for your monthly income?',
      DESCRIPTION: (isExistingUser: boolean) =>
        isExistingUser
          ? "Before we get started, let's confirm your income looks correct. The numbers below are from your current budget settings, but you can adjust them now if anything looks inaccurate."
          : "We've auto-calculated your income budgets based on your historical transactions.\nMake sure these look right and feel free to customize them.",
    },
    METHOD: {
      TITLE: (isExistingUser: boolean) =>
        isExistingUser
          ? 'Two different ways to track your money'
          : "Decide how you'd like to budget",
      DESCRIPTION: (isExistingUser: boolean) =>
        isExistingUser
          ? 'Try flex budgeting if you’re looking for a simpler way to track your money. You can switch back to category budgeting whenever you want.'
          : 'Choose the method that will work best for your lifestyle. You can always switch later.',
    },
    FLEX_INTRODUCTION: {
      TITLE: 'How flex budgeting works',
      DESCRIPTION:
        "In the next step, you'll describe each of your expense categories as either Fixed, Non-Monthly, or Flexible. This lets you focus on tracking one number -- your **flexible number** -- throughout the month.",
    },
    EXPENSES: {
      TITLE: 'Does this look right for your monthly expenses?',
      DESCRIPTION: (isExistingUser: boolean) =>
        isExistingUser
          ? "Review your fixed monthly budgets and update if you need to. Any updates you make here will affect future months, but won't change past months."
          : "We've auto-calculated your monthly expenses based on your transactions over the last 6 months. Update them if you need to. You can also always adjust later.",
    },
    FIXED_EXPENSES: {
      TITLE: 'Does this look right for your fixed expenses?',
      DESCRIPTION: (isExistingUser: boolean) =>
        isExistingUser
          ? "Review your fixed monthly budgets and update if you need to. Any updates you make here will affect future months, but won't change past months."
          : "We've auto-calculated your fixed expenses based on your transactions over the last 6 months. Make sure these look right and update if you need to. You can always adjust later.",
    },
    NON_MONTHLY_EXPENSES: {
      TITLE: 'Tell us more about your non-monthly expenses',
      DESCRIPTION:
        "We'll help you plan for infrequent big expenses to avoid surprises throughout the year. If you don't spend the full amount in a month, we'll roll that budget into future months.",
      TOOLTIP_FREQUENCY: 'Choose how often you expect expenses to happen in each category.',
      TOOLTIP_TARGET_AMOUNT: 'Choose how much you want to spend per frequency on each category.',
    },
    FLEXIBLE_EXPENSES: {
      TITLE: 'Does this look right for your flexible expenses?',
      DESCRIPTION: (isExistingUser: boolean) =>
        isExistingUser
          ? "Review the total budget you've set for your flexible expenses. These category expenses can vary each month, but you only need to track your top-line flexible expense number."
          : "We've calculated your monthly flex number based on your historical spending. You can update the total if you need to — but you don't need to set budget numbers for each category. Instead, just track your progress toward your total flex number each month.",
    },
    SELECT_VARIABILITIES: {
      TITLE: 'Tell us about your expenses',
      DESCRIPTION:
        'Sort each of your expense categories into a Fixed, Non-Monthly, or Flexible bucket. We got you started by sorting each category into its most common bucket. You can change that as you need to.',
      DESCRIPTION_NO_LINK:
        'Sort each of your expense categories into a Fixed, Non-Monthly, or Flexible bucket. We got you started by sorting each category into its most common bucket. You can change that as you need to.',
    },
    SUCCESS: {
      TITLE: "You're all set!",
      DESCRIPTION:
        "Here's a high-level summary of how your budget shakes out right now. You can adjust it every month, whenever you need to.",
    },
  },

  ROLLOVER: {
    TOGGLE: {
      LABEL: 'Make your Flexible budget a rollover',
      DESCRIPTION:
        'Every month, the remaining balance on your Flexible budget will roll over to the next month.',
    },
    STARTING_BALANCE: {
      LABEL: 'Starting balance',
      DESCRIPTION:
        'Your rollover balance will start with the amount you enter above and accrue going forward from the starting month.',
    },
    STARTING_MONTH: {
      LABEL: 'Starting month',
      DESCRIPTION:
        'Your rollover balance will start carrying over from this month onward. Any excess budget amounts from previous months will not roll over.',
    },
  },
};

export const FIXED_FLEXIBLE_NON_MONTHLY_INTRODUCTION = [
  {
    title: 'Fixed',
    description: 'Use this when the amount must be paid each month and stays about the same.',
    greatFor: ['🏠 Rent', '⚡️ Utilities', '☂️ Insurance', '🔄 Subscriptions'],
    greatForMobile: ['🏠 Rent', '⚡️ Utilities', '🔄 Subscriptions'],
    variability: BudgetVariability.FIXED,
  },
  {
    title: 'Non-Monthly',
    description:
      'Use this when the amount you spend happens at least once a year, but less frequently than monthly.',
    greatFor: ['✈️ Travel', '🎁 Gifts', '🚗 Auto Maintenance'],
    greatForMobile: ['✈️ Travel', '🎁 Gifts', '🚗 Car Maintenance'],
    variability: BudgetVariability.NON_MONTHLY,
  },
  {
    title: 'Flexible',
    description:
      'Use this for categories where the amount you spend might vary from day to day or week to week.',
    greatFor: ['🍽️ Restaurants', '🛒  Groceries', '🎬 Entertainment'],
    greatForMobile: ['🍽️ Restaurants', '🛒  Groceries'],
    variability: BudgetVariability.FLEXIBLE,
  },
];
