import React from 'react';

import MultipleChoiceMessageBlock from 'components/assistant/blocks/MultipleChoiceMessageBlock';

import { MultipleChoiceBlock } from 'common/lib/assistant/blocks';
import { makeBlockRenderer } from 'common/lib/assistant/blocks/render';
import useSendMessage from 'common/lib/hooks/assistant/useSendMessage';
import { useMessagesListContext } from 'lib/contexts/MessagesListContext';

const MultipleChoiceBlockRenderer = makeBlockRenderer(MultipleChoiceBlock)(({
  data: { choices },
  nextGroup,
}) => {
  const { threadId } = useMessagesListContext();
  const [sendMessage] = useSendMessage();

  if (nextGroup) {
    // Don't show buttons if there's a user message after this block
    // because that means user has already responded.
    return null;
  }

  return (
    <MultipleChoiceMessageBlock
      choices={choices}
      onClickChoice={(choice) =>
        sendMessage({
          variables: {
            input: {
              threadId,
              content: choice,
            },
          },
        })
      }
    />
  );
});

export default MultipleChoiceBlockRenderer;
