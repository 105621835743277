import * as React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import SingleCardPage from 'components/lib/layouts/SingleCardPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import routes from 'constants/routes';

const Root = styled(SingleCardPage)`
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const AlertIcon = styled(Icon).attrs({ name: 'check-circle' })`
  width: 60px;
  height: 60px;
  margin: ${({ theme }) => theme.spacing.xlarge} 0px;
  color: ${({ theme }) => theme.color.green};
`;

const BodyContainer = styled(FlexContainer)<{ showBottomBorder: boolean }>`
  border-bottom: ${(props) => (props.showBottomBorder ? '1' : '0')}px solid
    ${({ theme }) => theme.color.grayBackground};
`;

const HeaderText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  text-align: center;
`;

const StyledButton = styled(PrimaryButton)`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const Oops = () => {
  const history = useHistory();
  return (
    <Root name="Sponsorship Invite Unsubscribed Success">
      <BodyContainer alignCenter column padding="large" showBottomBorder>
        <AlertIcon />
        <HeaderText weight="medium" size="large">
          You successfully unsubscribed
        </HeaderText>
        <Text align="center" size="small">
          We won&apos;t send you more e-mails about this invite.
        </Text>
      </BodyContainer>
      <StyledButton size="medium" onClick={() => history.push(routes.dashboard())}>
        Back to app
      </StyledButton>
    </Root>
  );
};

export default Oops;
