import React, { useContext } from 'react';
import type { DraggableProvidedDragHandleProps, DraggableStateSnapshot } from 'react-beautiful-dnd';

export type DraggableContextType = {
  dragHandleProps: DraggableProvidedDragHandleProps | undefined;
  state: DraggableStateSnapshot;
};

const DraggableContext = React.createContext<DraggableContextType>(undefined as any);

export const useDraggableContext = () => {
  const context = useContext(DraggableContext);
  if (!context) {
    throw new Error(
      'useDraggableContext used outside of DraggableContext. Ensure you have a <Draggable> component higher in the tree.',
    );
  }

  return context;
};

export default DraggableContext;
