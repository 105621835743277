import { DateTime } from 'luxon';
import * as R from 'ramda';
import { isNil } from 'ramda';
import React, { useMemo, useRef } from 'react';
import { Route, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import AccountNetWorthCharts from 'components/accounts/AccountNetWorthCharts';
import type { AccountsListInputRef } from 'components/accounts/AccountsList';
import AccountsList from 'components/accounts/AccountsList';
import AccountsSummaryCard from 'components/accounts/AccountsSummaryCard';
import AddManualAccountFlow from 'components/accounts/AddManualAccountFlow';
import type { LinkAccountSuccessMetadata } from 'components/accounts/LinkAccountDataProviderModalWithFallback';
import LinkAccountFlow from 'components/accounts/LinkAccountFlow';
import { NET_WORTH_CHART_TIMEFRAME_OPTIONS } from 'components/accounts/NetWorthChartControls';
import PlaidLinkOauth from 'components/lib/external/PlaidLinkOauth';
import RouteModal from 'components/lib/routing/RouteModal';
import Card from 'components/lib/ui/Card';
import Controls from 'components/lib/ui/Controls';
import { CurrentNetWorth, NetWorthContainer } from 'components/lib/ui/DashboardWidgetAmountHeader';
import Grid, { GridItem } from 'components/lib/ui/Grid';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinnerWithText from 'components/lib/ui/LoadingSpinnerWithText';
import PageWithNoAccountsEmptyState from 'components/lib/ui/PageWithNoAccountsEmptyState';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import DirectLinkAccountForDataProvider from 'components/routes/DirectLinkAccountForDataProvider';
import DirectLinkAccountSelectDataProvider from 'components/routes/DirectLinkAccountSelectDataProvider';
import RefreshAllButton from 'components/routes/RefreshAllButton';

import { getDateRangesForChartType } from 'common/lib/accounts/accountCharts';
import type { DateRange } from 'common/lib/accounts/accountCharts';
import { getNetWorthChartStartDate } from 'common/lib/accounts/netWorthCharts';
import { GET_SNAPSHOTS_BY_ACCOUNT_TYPE_QUERY } from 'common/lib/graphQl/snapshots';
import useAccountChanges from 'common/lib/hooks/snapshot/useAccountChanges';
import useLoading from 'common/lib/hooks/useLoading';
import useQuery from 'common/lib/hooks/useQuery';
import { spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import { ensureEnumValue } from 'common/utils/Enum';
import { track } from 'lib/analytics/segment';
import useDownloadBalances from 'lib/hooks/accounts/useDownloadBalances';
import useMockDataWhenNoAccountsQuery from 'lib/hooks/useMockDataWhenNoAccountsQuery';
import usePersistentFilter from 'lib/hooks/usePersistentFilter';

import { DOWNLOAD_TRANSACTIONS_CSV_BUTTON_CLICKED } from 'common/constants/analytics';
import { ONBOARDING_ACCOUNTS_OVERLAY_TITLE } from 'common/constants/copy';
import type { TimeframeBase } from 'common/constants/timeframes';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import { Timeframe } from 'common/generated/graphql';

const SummaryCardPositioner = styled.div`
  position: sticky;
  top: ${isV2Theme(0, spacing.gutter)};
`;

const PlusIcon = styled(Icon).attrs({ name: 'plus', size: 14 })`
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledCard = styled(Card)`
  ${CurrentNetWorth} {
    font-size: ${({ theme }) => theme.fontSize.xlarge};
  }

  ${NetWorthContainer} {
    margin-top: -${({ theme }) => theme.spacing.xsmall};
  }
`;

const StyledGrid = styled(Grid)`
  ${isV2Theme(css`
    padding-top: 0;
  `)}
`;

const getStartDateValueFromTimeframe = (timeframe: TimeframeBase) => {
  const today = DateTime.local();
  const twoYearsAgo = today.minus({ years: 2 });

  switch (timeframe) {
    case Timeframe.MONTH:
      return twoYearsAgo.diffNow().negate().as('months');
    case Timeframe.YEAR:
    case Timeframe.QUARTER:
      return 4;
    default:
      throw new Error(`Unhandled timeframe: ${timeframe}`);
  }
};

const Accounts = () => {
  const { downloadBalances } = useDownloadBalances();

  const {
    activeFilters: {
      timeframe: activeTimeframe,
      dateRange: activeDateRangeDisplay,
      accountType: activeAccountType,
      chartType = 'performance',
    },
    updateFilter,
  } = usePersistentFilter('accounts');

  const activeChartType = chartType;
  const isBreakdownChart = activeChartType === 'breakdown';
  const isPerformanceChart = activeChartType === 'performance';

  const activeTimeframeIndex = R.indexOf(
    activeTimeframe,
    R.map(R.prop('value'), NET_WORTH_CHART_TIMEFRAME_OPTIONS),
  );

  const activeDateRange = useMemo(() => {
    const dateRanges = getDateRangesForChartType(activeChartType);
    return (
      dateRanges.find((dateRange) => dateRange.display === activeDateRangeDisplay) || dateRanges[0]
    );
  }, [activeDateRangeDisplay, activeChartType]);

  const dateRangeStartDate = DateTime.local().minus(activeDateRange.duration).toISODate();

  const snapshotsStartDate = useMemo(() => {
    if (isBreakdownChart) {
      return DateTime.local().startOf(activeTimeframe).minus({ day: 1 }).toISODate();
    }

    return activeDateRange.display === 'ALL' ? undefined : dateRangeStartDate;
  }, [activeTimeframe, activeDateRange]);

  const {
    data,
    refetch,
    isLoadingInitialData: isLoadingAccounts,
  } = useMockDataWhenNoAccountsQuery(GET_ACCOUNTS_PAGE);
  const { data: snapshotData } = useQuery(GET_RECENT_SNAPSHOTS, {
    skip: R.isNil(data),
    variables: {
      startDate: snapshotsStartDate,
    },
  });

  const { householdPreferences, accountTypeSummaries = [] } = data ?? {};

  const {
    data: snapshotsByAccountTypeData,
    isLoadingInitialData: isLoadingSnapshotsByAccountType,
    isNetworkRequestInFlight: isSnapshotsNetworkRequestInFlight,
  } = useMockDataWhenNoAccountsQuery(GET_SNAPSHOTS_BY_ACCOUNT_TYPE_QUERY, {
    skip: R.isNil(data) || !isBreakdownChart,
    variables: {
      startDate: getNetWorthChartStartDate({
        timeframe: activeTimeframe,
        value: getStartDateValueFromTimeframe(activeTimeframe),
      }),
      timeframe: ensureEnumValue(Timeframe, activeTimeframe, Timeframe.MONTH),
    },
  });

  const { snapshotsByAccountType = [] } = snapshotsByAccountTypeData ?? {};

  const {
    previousAmountsByAccountType,
    aggregateSnapshots,
    isLoadingAggregateSnapshots,
    isAggregateSnapshotsNetworkRequestInFlight,
  } = useAccountChanges({
    accountType: !['ALL_ASSETS', 'ALL_LIABILITIES'].includes(activeAccountType || '')
      ? activeAccountType
      : null,
    snapshotStartDate: dateRangeStartDate,
    skip: isNil(data) || !isPerformanceChart,
  });

  const recentBalancesByAccount = useMemo(() => {
    const { accounts = [] } = snapshotData ?? {};
    const balancesIndexedByAccount: Record<string, number[]> = accounts.reduce((acc, account) => {
      const balances = account.recentBalances.filter(Boolean);
      return { ...acc, [account.id]: balances };
    }, {});
    return balancesIndexedByAccount;
  }, [snapshotData]);

  const history = useHistory();
  const accountsListRef = useRef<AccountsListInputRef>(null);

  const scrollToAccountType = (type: string) => {
    if (accountsListRef?.current) {
      accountsListRef.current.scrollToAccountType(type);
    }
  };

  const [isDownloadingCsv, onClickDownloadCsv] = useLoading(() => {
    track(DOWNLOAD_TRANSACTIONS_CSV_BUTTON_CLICKED);
    return downloadBalances();
  });

  const navigateToNewAccountDetailsPage = (data: LinkAccountSuccessMetadata, close: () => void) => {
    if (!data.dataProvider && data.accountIds?.length === 1) {
      history.push(routes.accounts.accountDetails({ id: data.accountIds[0] }));
    } else {
      close();
    }
  };

  const availableAccountTypes = useMemo(
    () => accountTypeSummaries.map((summary) => summary.type),
    [accountTypeSummaries],
  );

  const isAssetTypeSelected = useMemo(() => {
    if (activeAccountType === 'ALL_LIABILITIES') {
      return false;
    }
    const selected = availableAccountTypes.find(
      (accountType) => accountType.name === activeAccountType,
    )?.group;

    return selected ? selected === 'asset' : true;
  }, [availableAccountTypes, activeAccountType]);

  return (
    <PageWithNoAccountsEmptyState
      name="Accounts"
      emptyIcon="layers"
      emptyTitle={ONBOARDING_ACCOUNTS_OVERLAY_TITLE}
      controls={
        <Controls>
          <RefreshAllButton />

          <PrimaryButton onClick={() => history.push(routes.accounts.addAccount())} size="small">
            <PlusIcon />
            Add account
          </PrimaryButton>
        </Controls>
      }
      onAccountAdded={refetch}
    >
      {!isLoadingAccounts ? (
        <>
          <StyledGrid template={`"chart chart" "rec sum" / 1fr 30%`} md={`"chart" "sum" "rec"`}>
            <GridItem area="chart">
              <StyledCard>
                <AccountNetWorthCharts
                  chartType={activeChartType}
                  setChartType={(chartType) => {
                    updateFilter({ chartType, accountType: null });
                  }}
                  snapShotsDataByAccountType={snapshotsByAccountType}
                  availableAccountTypes={availableAccountTypes}
                  aggregateSnapshotsData={aggregateSnapshots}
                  isLoadingSnapshotsByAccountType={isLoadingSnapshotsByAccountType}
                  isLoadingAggregateSnapshots={isLoadingAggregateSnapshots}
                  isAggreateSnapShotsNetworkRequestInFlight={
                    isAggregateSnapshotsNetworkRequestInFlight
                  }
                  activeTimeframeIndex={activeTimeframeIndex}
                  onChangeTimeframe={(timeframe: TimeframeBase) => updateFilter({ timeframe })}
                  selectedAccountType={activeAccountType}
                  onChangeSelectedAccountType={(accountType: string | null) =>
                    updateFilter({ accountType, chartType: 'performance' })
                  }
                  isSnapshotsNetworkRequestInFlight={isSnapshotsNetworkRequestInFlight}
                  selectedDateRange={activeDateRange}
                  setSelectedDateRange={(dateRange: DateRange) =>
                    updateFilter({ dateRange: dateRange.display })
                  }
                  isAsset={isAssetTypeSelected}
                />
              </StyledCard>
            </GridItem>

            <GridItem area="rec">
              <AccountsList
                ref={accountsListRef}
                accountSummaries={accountTypeSummaries}
                householdPreferences={householdPreferences || null}
                recentBalancesByAccount={recentBalancesByAccount}
                previousBalancesByAccountType={previousAmountsByAccountType}
                snapshots={snapshotsByAccountType}
              />
            </GridItem>

            <GridItem area="sum" sticky>
              <SummaryCardPositioner>
                <AccountsSummaryCard
                  accountSummaries={accountTypeSummaries}
                  onClickAccountType={scrollToAccountType}
                  isDownloadingCsv={isDownloadingCsv}
                  onClickDownloadCsv={onClickDownloadCsv}
                />
              </SummaryCardPositioner>
            </GridItem>
          </StyledGrid>
        </>
      ) : (
        <LoadingSpinnerWithText text="Loading account data..." />
      )}
      <RouteModal path={routes.accounts.addAccount.path} exact onClose={refetch}>
        {({ close }) => (
          <LinkAccountFlow
            onComplete={(data) => {
              navigateToNewAccountDetailsPage(data, close);
            }}
            onClose={close}
            allowManualAccount
          />
        )}
      </RouteModal>
      <RouteModal path={routes.accounts.addManualAccount.path} exact onClose={refetch}>
        {({ close }) => (
          <AddManualAccountFlow
            onBack={close}
            onComplete={(data) => {
              navigateToNewAccountDetailsPage(data, close);
            }}
          />
        )}
      </RouteModal>
      <RouteModal path={routes.accounts.plaidLinkOauth.path} onClose={refetch}>
        {({ close }) => <PlaidLinkOauth onComplete={close} onClose={close} />}
      </RouteModal>
      <Route
        path={routes.accounts.addAccountForDataProvider.path}
        // @ts-ignore dataProvider is missing from props I think because props are generic {[key: string]: string}
        render={(props) => <DirectLinkAccountForDataProvider {...props} onComplete={refetch} />}
      />
      <Route
        path={routes.accounts.addAccountSelectDataProvider.path}
        render={(props) => (
          // @ts-ignore institutionId is missing from props I think because props are generic {[key: string]: string}
          <DirectLinkAccountSelectDataProvider
            {...props}
            onComplete={(data) =>
              navigateToNewAccountDetailsPage(data, () => history.push(routes.accounts()))
            }
          />
        )}
      />
    </PageWithNoAccountsEmptyState>
  );
};

// Using this query in the A/B test to allow users to add accounts before the paywall
// TODO: put it on a better place if we decide to move forward after the A/B test.
export const GET_ACCOUNTS_PAGE = gql(/* GraphQL */ `
  query Web_GetAccountsPage {
    hasAccounts
    accountTypeSummaries {
      type {
        name
        display
        group
      }
      accounts {
        id
        ...AccountsListFields
      }
      isAsset
      totalDisplayBalance
    }
    householdPreferences {
      id
      accountGroupOrder
    }
  }
`);

export const GET_RECENT_SNAPSHOTS = gql(/* GraphQL */ `
  query Web_GetAccountsPageRecentBalance($startDate: Date) {
    accounts {
      id
      recentBalances(startDate: $startDate)
      type {
        name
        display
        group
      }
      includeInNetWorth
    }
  }
`);

export const GET_AGGREGATE_SNAPSHOTS = gql(/* GraphQL */ `
  query Web_GetAggregateSnapshots($filters: AggregateSnapshotFilters) {
    aggregateSnapshots(filters: $filters) {
      date
      balance
      assetsBalance
      liabilitiesBalance
    }
  }
`);

export default Accounts;
