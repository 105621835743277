export enum Country {
  UNITED_STATES = 'US',
  CANADA = 'CA',
  OTHER = '',
}

export const COUNTRY_OPTIONS = [
  { label: 'United States', value: Country.UNITED_STATES },
  { label: 'Canada', value: Country.CANADA },
  { label: 'Other', value: Country.OTHER },
];

export const DEFAULT_COUNTRY = Country.UNITED_STATES;
