import * as R from 'ramda';
import { useCallback, useContext, useMemo } from 'react';

import FormContext from 'common/components/form/FormContext';

import type { FormValues, Split } from 'common/lib/transactions/Splits';
import { getAmountLeftToSplit, getDefaultSplitDataValues } from 'common/lib/transactions/Splits';

import type { Common_TransactionSplitQuery } from 'common/generated/graphql';

type Options = {
  originalTransaction?: Common_TransactionSplitQuery['getTransaction'];
};

const useSplitTransactionForm = ({ originalTransaction }: Options) => {
  const { values, setValues } = useContext(FormContext);
  const { splitData: splits = [] } = (values as FormValues) ?? {};

  const addSplit = useCallback(
    (data?: Split) => {
      const newValues = R.evolve(
        {
          splitData: R.append({
            ...getDefaultSplitDataValues(originalTransaction),
            ...data,
          }),
        },
        values,
      );

      return setValues(newValues);
    },
    [setValues, values, originalTransaction],
  );

  const adjustSplit = useCallback(
    (index: number) => (data: Split) =>
      setValues(
        R.evolve(
          {
            splitData: R.adjust(index, (split: Split) => ({ ...split, ...data })),
          },
          values,
        ),
      ),
    [setValues, values],
  );

  const removeSplit = useCallback(
    (index: number) => () =>
      setValues(
        R.evolve(
          {
            splitData: R.remove(index, 1),
          },
          values,
        ),
      ),
    [setValues, values],
  );

  const removeAllSplits = useCallback(
    () => setValues({ ...values, splitData: [] }),
    [setValues, values],
  );

  // TODO: get amount without og trx
  const amountLeftToSplit = useMemo(
    () => getAmountLeftToSplit(splits, Math.abs(originalTransaction?.amount ?? 0)),
    [splits, originalTransaction],
  );

  return {
    splits,
    addSplit,
    adjustSplit,
    removeSplit,
    removeAllSplits,
    amountLeftToSplit,
  };
};

export default useSplitTransactionForm;
