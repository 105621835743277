import { readableColor, transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

import { radius } from 'common/lib/theme/dynamic';
import useTheme from 'lib/hooks/useTheme';

const SQUARE_SIZE_PX = 40;

const SquareContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const Square = styled.div<{ $color: string }>`
  width: ${SQUARE_SIZE_PX}px;
  height: ${SQUARE_SIZE_PX}px;
  background-color: ${({ $color }) => $color};
  border-radius: ${radius.small};
  box-shadow: inset 0 0 0 1px ${({ theme }) => transparentize(0.9, theme.color.textWhite)};
`;

const CheckedIcon = styled(Icon).attrs({ name: 'check' })`
  color: ${({ color }) => color};
  position: absolute;
  user-select: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type Props = {
  colors: string[];
  className?: string;
};

const ColorField = ({ colors, className, ...props }: AbstractFieldProps<Props>) => {
  const theme = useTheme();

  return (
    <AbstractField {...props}>
      {({ id, value, handleBlur, setFieldValue }) => (
        <FlexContainer gap="small">
          {colors.map((color) => (
            <SquareContainer key={color}>
              <Square
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setFieldValue(props.name, color);
                    handleBlur(e);
                  }
                }}
                id={id}
                $color={color}
                onClick={(e) => {
                  setFieldValue(props.name, color);
                  handleBlur(e);
                }}
              />
              {value === color && (
                <CheckedIcon
                  color={readableColor(color, theme.color.black, theme.color.white, true)}
                />
              )}
            </SquareContainer>
          ))}
        </FlexContainer>
      )}
    </AbstractField>
  );
};

export default ColorField;
