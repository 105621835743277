import { gql } from '@apollo/client';

import { gql as newGql } from 'common/generated/gql';

export const ACCOUNT_FIELDS_FRAGMENT = newGql(/* GraphQL */ `
  fragment AccountFields on Account {
    id
    displayName
    syncDisabled
    deactivatedAt
    isHidden
    isAsset
    mask
    createdAt
    updatedAt
    displayLastUpdatedAt
    currentBalance
    displayBalance
    includeInNetWorth
    hideFromList
    hideTransactionsFromReports
    includeBalanceInNetWorth
    includeInGoalBalance
    dataProvider
    dataProviderAccountId
    isManual
    transactionsCount
    holdingsCount
    manualInvestmentsTrackingMethod
    order
    icon
    logoUrl
    deactivatedAt

    type {
      name
      display
      group
    }

    subtype {
      name
      display
    }

    credential {
      id
      updateRequired
      disconnectedFromDataProviderAt
      dataProvider
      institution {
        id
        plaidInstitutionId
        name
        status
        logo
      }
    }
    institution {
      id
      name
      logo
      primaryColor
      url
    }
  }
`);

export const GET_ACCOUNT = newGql(/* GraphQL */ `
  query Common_GetAccount($id: UUID!) {
    account(id: $id) {
      id
      displayName
      ...AccountLogoFields
    }
  }
`);

export const GET_ACCOUNTS = newGql(/* GraphQL */ `
  query GetAccounts {
    accounts {
      ...AccountFields
    }
    householdPreferences {
      id
      accountGroupOrder
    }
  }
`);

export const UPDATE_ACCOUNT = newGql(/* GraphQL */ `
  mutation Common_UpdateAccount($input: UpdateAccountMutationInput!) {
    updateAccount(input: $input) {
      account {
        ...AccountFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const BULK_UPDATE_ACCOUNTS = newGql(/* GraphQL */ `
  mutation Common_BulkUpdateAccounts($input: [UpdateAccountsMutationInput]!) {
    updateAccounts(input: $input) {
      accounts {
        ...AccountFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const DELETE_ACCOUNT = newGql(/* GraphQL */ `
  mutation Common_DeleteAccount($id: UUID!) {
    deleteAccount(id: $id) {
      deleted

      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const GET_ACCOUNT_TYPE_OPTIONS = gql`
  query GetAccountTypeOptions {
    accountTypeOptions {
      type {
        name
        display
        group
        possibleSubtypes {
          display
          name
        }
      }
      subtype {
        name
        display
      }
    }
  }
`;

export const GET_HAS_ACCOUNTS = newGql(/* GraphQL */ `
  query GetHasAccounts {
    hasAccounts
  }
`);

export const GET_HAS_SYNCED_ACCOUNTS = gql`
  query GetHasSyncedAccounts {
    hasAccounts(onlySynced: true)
  }
`;

export const INSTITUTION_STATUS_FIELDS_FRAGMENT = newGql(/* GraphQL */ `
  fragment InstitutionStatusFields on Institution {
    id
    newConnectionsDisabled
    hasIssuesReported
    hasIssuesReportedMessage
    plaidStatus
    status
    balanceStatus
    transactionsStatus
  }
`);

export const GET_INSTITUTION = newGql(/* GraphQL */ `
  query GetInstitution($plaidId: String!) {
    institution(plaidId: $plaidId) {
      name
      logo
      ...InstitutionStatusFields
    }
  }
`);

export const GET_ACCOUNTS_FOR_TRANSACTIONS_QUERY = newGql(/* GraphQL */ `
  query Common_GetAccountsForTransactions(
    $selectedTransactionIds: [ID!]
    $excludedTransactionIds: [ID!]
    $isAllSelected: Boolean!
    $filters: TransactionFilterInput
  ) {
    getAccountsForTransactions(
      params: {
        selectedTransactionIds: $selectedTransactionIds
        excludedTransactionIds: $excludedTransactionIds
        isAllSelected: $isAllSelected
        filters: $filters
      }
    ) {
      id
      displayName
      dataProvider
      icon
      logoUrl

      type {
        name
      }

      subtype {
        name
      }
      institution {
        id
        logo
        primaryColor
      }
    }
  }
`);

export const FORCE_REFRESH_ACCOUNT_MUTATION = newGql(/* GraphQL */ `
  mutation Common_ForceRefreshAccountMutation($input: ForceRefreshAccountInput!) {
    forceRefreshAccount(input: $input) {
      success
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const FORCE_REFRESH_ALL_ACCOUNTS_MUTATION = newGql(/* GraphQL */ `
  mutation Common_ForceRefreshAccountsMutation {
    forceRefreshAllAccounts {
      success
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const CREATE_MANUAL_INVESTMENTS_ACCOUNT = newGql(/* GraphQL */ `
  mutation Common_CreateManualInvestmentsAccount($input: CreateManualInvestmentsAccountInput!) {
    createManualInvestmentsAccount(input: $input) {
      account {
        id
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const ACCOUNT_LOGO_FIELDS_FRAGMENT = gql`
  fragment AccountLogoFields on Account {
    id
    dataProvider

    type {
      name
    }

    subtype {
      name
    }

    institution {
      id
      logo
      primaryColor
    }
  }
`;

export const NEW_ACCOUNT_LOGO_FIELDS_FRAGMENT = newGql(/* GraphQL */ `
  fragment NewAccountLogoFields on Account {
    id
    dataProvider

    type {
      name
    }

    subtype {
      name
    }

    institution {
      id
      logo
      primaryColor
    }
  }
`);

export const DELETE_CREDENTIAL_MUTATION = newGql(/* GraphQL */ `
  mutation Common_DeleteCredentialMutation($input: DeleteCredentialInput!) {
    deleteCredential(input: $input) {
      deleted
    }
  }
`);

export const UNDELETE_ACCOUNT_MUTATION = newGql(/* GraphQL */ `
  mutation Common_UndeleteAccount($input: UndeleteAccountMutationInput!) {
    undeleteAccount(input: $input) {
      undeleted
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const GET_DISPLAY_BALANCE_PREVIEW = newGql(/* GraphQL */ `
  query GetDisplayBalancePreview(
    $accountId: UUID!
    $invertSyncedBalance: Boolean
    $useAvailableBalance: Boolean
  ) {
    account(id: $accountId) {
      id
      displayBalancePreview(
        invertSyncedBalance: $invertSyncedBalance
        useAvailableBalance: $useAvailableBalance
      )
    }
  }
`);
