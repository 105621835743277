import type { QueryHookOptions } from '@apollo/client';
import { isNil } from 'ramda';

import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';
import type {
  Common_GetMerchantQuery,
  Common_GetMerchantQueryVariables,
} from 'common/generated/graphql';

/* Hook used to get a single merchant on demand. */
const useMerchant = (
  merchantId?: string,
  options?: QueryHookOptions<Common_GetMerchantQuery, Common_GetMerchantQueryVariables>,
) => {
  const { data, isLoadingInitialData } = useQuery(QUERY, {
    fetchPolicy: 'cache-first',
    skip: isNil(merchantId),
    variables: { id: merchantId ?? '' },
    ...options,
  });

  return {
    isLoadingInitialData,
    merchant: data?.merchant,
  };
};

const QUERY = gql(`
  query Common_GetMerchant($id: ID!) {
    merchant(id: $id) {
      id
      name
      logoUrl
    }
  }
`);

export default useMerchant;
