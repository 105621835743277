// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 4137459887
import { createAction } from 'typesafe-actions';

import type { PlaidEventName } from 'common/constants/analytics';

import type { PlaidLinkEventMetadata } from 'common/types/Plaid';

export const logErrorSurfaced = createAction('ANALYTICS/LOG_ERROR_SURFACED')<{
  error: Error;
  fromRoute: string;
}>();

export const logFinicityConnectComplete = createAction('ANALYTICS/LOG_FINICITY_CONNECT_COMPLETE')();

export const logFinicityConnectErrored = createAction(
  'ANALYTICS/LOG_FINICITY_CONNECT_ERRORED',
)<any>();

export const logFinicityConnectEvent = createAction('ANALYTICS/LOG_FINICITY_CONNECT_EVENT')<any>();

export const logFinicityConnectOpened = createAction('ANALYTICS/LOG_FINICITY_CONNECT_OPENED')();

export const logPlaidEvent = createAction('ANALYTICS/LOG_PLAID_EVENT')<{
  eventName: PlaidEventName;
  metadata: PlaidLinkEventMetadata;
}>();

export const setSegmentAnonymousUserId = createAction('ANALYTICS/SET_SEGMENT_ANONYMOUS_USER_ID')<
  string | undefined
>();
