import * as R from 'ramda';

type GroupableHoldingType = {
  id: string;
  typeDisplay: string;
};

const groupHoldingsByTypeDisplay = <HoldingType extends GroupableHoldingType>(
  holdings: HoldingType[],
) => {
  const uniqueHoldings = R.uniqBy(R.prop('id'), holdings);
  const grouped = R.groupBy(R.prop('typeDisplay'), uniqueHoldings);

  const mapped = R.mapObjIndexed(
    (data, key) => ({
      key,
      data,
      typeDisplay: key,
    }),
    grouped,
  );

  const values = R.values(mapped);
  const sections = R.sortBy(R.prop('typeDisplay'), values);

  return sections;
};

export default groupHoldingsByTypeDisplay;
