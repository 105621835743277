import React from 'react';
import styled from 'styled-components';

import ManualLink from 'components/lib/ui/link/ManualLink';

const Summary = styled.div`
  display: grid;
  grid-template: auto / auto auto;
  gap: ${({ theme }) => theme.spacing.default};
`;

export const SummaryLabel = styled.div<{ dim?: boolean }>`
  color: ${({ theme, dim }) => (dim ? theme.color.textLight : 'inherit')};
`;

export const SummaryValue = styled.div`
  text-align: right;
`;

const SummaryLinkValue = styled(ManualLink)`
  display: inline-block;
  text-align: right;
`;

export type PairProps = {
  label: React.ReactNode;
  dim?: boolean;
  value?: React.ReactNode;
  children?: React.ReactNode;
  link?: string;
};

export const SummaryPair = ({ dim, label, value, children, link }: PairProps) => (
  <>
    <SummaryLabel dim={dim}>{label}</SummaryLabel>
    {link ? (
      <SummaryLinkValue href={link} target="_blank">
        {children ?? value}
      </SummaryLinkValue>
    ) : (
      <SummaryValue>{children ?? value}</SummaryValue>
    )}
  </>
);

export default Summary;
