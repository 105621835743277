import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Tooltip from 'components/lib/ui/Tooltip';

const ICON_SIZE_PX = 12;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.gray};
`;

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })``;

const IconWrapper = styled.div`
  width: 11px;
  height: 11px;
  display: flex;
  background-color: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.round};
`;

const TooltipContent = styled.div`
  max-width: 160px;
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.small};
  text-align: left;
  font-size: 12px;
`;

type Props = {
  tooltipText: string;
  extraNode?: React.ReactNode;
};

const ExcludedFromPortfolioIndicator = ({ tooltipText, extraNode }: Props) => (
  <Tooltip content={<TooltipContent>{tooltipText}</TooltipContent>} place="right" effect="solid">
    <Root>
      <IconWrapper>
        <StyledIcon name="circle-slashed" size={ICON_SIZE_PX} />
      </IconWrapper>
      {extraNode}
    </Root>
  </Tooltip>
);

export default ExcludedFromPortfolioIndicator;
