import React from 'react';
import styled from 'styled-components';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import type { Props as TextInputProps } from 'components/lib/form/TextInput';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import SearchBar from 'components/lib/ui/SearchBar';

import fieldErrorBorderMixin from 'lib/styles/fieldErrorBorderMixin';

const SearchWithError = styled(SearchBar)<{ error?: boolean }>`
  ${fieldErrorBorderMixin}
`;

const SearchField = ({
  className,
  onBlur,
  onChange,
  isSensitive,
  ...props
}: AbstractFieldProps<TextInputProps>) => (
  <AbstractField {...props} className={className}>
    {({ id, value, displayErrors, handleChange, handleBlur, setFieldValue }) => (
      <SearchWithError
        {...props}
        id={id}
        onChange={(value) => {
          setFieldValue(props.name, value);
          onChange?.(value);
        }}
        onBlur={(e) => {
          handleBlur(e);
          onBlur?.(e);
        }}
        value={value ?? ''}
        error={displayErrors.length > 0}
        {...(isSensitive ? sensitiveClassProps : {})}
      />
    )}
  </AbstractField>
);

export default SearchField;
