import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';

import type { LinkAccountSuccessMetadata } from 'components/accounts/LinkAccountDataProviderModalWithFallback';
import LinkAccountLoadingModal from 'components/accounts/LinkAccountLoadingModal';
import FinicityConnect from 'components/lib/external/FinicityConnect';
import MXConnect from 'components/lib/external/MXConnect';
import Modal from 'components/lib/ui/Modal';

import { useStackContext } from 'common/lib/contexts/StackContext';
import { CREATE_FINICITY_CONNECT_URL_MUTATION } from 'common/lib/graphQl/finicity';
import { CREATE_MX_CONNECT_URL_MUTATION } from 'common/lib/graphQl/mx';

import { DataProviderLegacy } from 'common/generated/graphql';

type Props = {
  dataProvider: DataProviderLegacy;
};

const SearchAndLinkAccountFromDataProviderModal = ({ dataProvider }: Props) => {
  const { replace, pop } = useStackContext();

  const [createMxConnectUrl, { data: mxData }] = useMutation(CREATE_MX_CONNECT_URL_MUTATION);
  const { url: mxConnectUrl } = mxData?.createMxConnectUrl ?? {};

  const [createFinicityConnectUrl, { data: finicityData }] = useMutation(
    CREATE_FINICITY_CONNECT_URL_MUTATION,
  );
  const { url: finicityConnectUrl } = finicityData?.createFinicityConnectUrl ?? {};

  const next = (params: { metadata: LinkAccountSuccessMetadata }) => {
    replace(LinkAccountLoadingModal, params);
  };

  // gets the MX widget connect url
  useEffect(() => {
    if (dataProvider === DataProviderLegacy.MX) {
      createMxConnectUrl();
    } else if (dataProvider === DataProviderLegacy.FINICITY) {
      createFinicityConnectUrl();
    }
  }, [dataProvider]);

  return (
    <Modal onClose={pop}>
      {dataProvider === DataProviderLegacy.MX && (
        <MXConnect
          connectUrl={mxConnectUrl ?? undefined}
          onSuccess={() => {
            next({
              metadata: { dataProvider } as LinkAccountSuccessMetadata,
            });
          }}
          onClose={pop}
        />
      )}

      {dataProvider === DataProviderLegacy.FINICITY && (
        <FinicityConnect
          connectUrl={finicityConnectUrl ?? undefined}
          onClose={pop}
          onSuccess={() => {
            next({
              metadata: { dataProvider } as LinkAccountSuccessMetadata,
            });
          }}
        />
      )}
    </Modal>
  );
};

export default SearchAndLinkAccountFromDataProviderModal;
