import React from 'react';

import AddSyncedRealEstateFormModal from 'components/accounts/AddSyncedRealEstateFormModal';
import type { LinkAccountSuccessMetadata } from 'components/accounts/LinkAccountDataProviderModal';
import ZillowPropertySearchModal, {
  ZillowPropertySearchModalCard,
} from 'components/accounts/ZillowPropertySearchModal';
import Flow from 'components/utils/Flow';

type NextProps = {
  zpid: string;
  addressStreet: string;
  zestimate: number;
};

const PropertySearchStep = ({
  next,
  goBack,
}: {
  next: (props: NextProps) => void;
  goBack?: () => void;
}) => (
  <ZillowPropertySearchModalCard onClickBackButton={goBack}>
    <ZillowPropertySearchModal onItemSelect={next} />
  </ZillowPropertySearchModalCard>
);

type Props = {
  onComplete: (metadata: LinkAccountSuccessMetadata) => void;
  onBack?: () => void;
};

const LinkZillowFlow = ({ onComplete, onBack }: Props) => (
  <Flow
    steps={[PropertySearchStep, AddSyncedRealEstateFormModal]}
    onComplete={onComplete}
    onBack={onBack}
  />
);

export default LinkZillowFlow;
