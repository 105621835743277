import { useMutation } from '@apollo/client';
import type { PaymentMethod } from '@stripe/stripe-js';
import { DateTime } from 'luxon';
import * as RA from 'ramda-adjunct';
import React, { useCallback } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import Empty, { Theme as EmptyTheme } from 'components/lib/ui/Empty';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Page from 'components/lib/ui/Page';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import InvoicesCard from 'components/settings/billing/InvoicesCard';
import UpdatePaymentMethodModal from 'components/settings/billing/UpdatePaymentMethodModal';

import useQuery from 'common/lib/hooks/useQuery';
import { SubscriptionSponsorshipStatus } from 'common/lib/sponsorship';
import { formatCurrency } from 'common/utils/Currency';
import useModal from 'lib/hooks/useModal';

import { gql } from 'common/generated/gql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.spacing.xxlarge};
  padding-bottom: 0;
  > * {
    width: 640px;
    margin-bottom: ${(props) => props.theme.spacing.large};
  }
`;

const CardSection = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing.xlarge};
  border-bottom: 1px solid ${(props) => props.theme.color.grayBackground};
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.default};
`;

const AdvisorBilling = () => {
  const { data, isLoadingInitialData, refetch } = useQuery(GET_SUBSCRIPTION);
  const [updateOrCreatePaymentMethod] = useMutation(UPDATE_OR_CREATE_STRIPE_PAYMENT_METHOD);

  const [PaymentMethodModal, { open: openPaymentMethodModal, close: closePaymentMethodModal }] =
    useModal();

  const onUpdatePaymentMethodSuccess = useCallback(
    async ({ id: paymentMethodId }: PaymentMethod) => {
      await updateOrCreatePaymentMethod({
        variables: { input: { paymentMethodId } },
      });
      refetch();
    },
    [updateOrCreatePaymentMethod],
  );

  const paymentMethod = data?.subscription?.paymentMethod;
  const { upcomingSponsorInvoice } = data ?? {};
  const invoices = data?.sponsorInvoices ?? [];

  // TODO @edufschmidt: move filtering to the backend
  const activeSponsorships =
    data?.subscriptionSponsorships?.filter(
      ({ status }) => status === SubscriptionSponsorshipStatus.Active,
    ) ?? [];

  const isLoadingStateVisible = isLoadingInitialData;
  const isEmptyStateVisible = !isLoadingInitialData && !paymentMethod;
  const isBillingInfoVisible = !isLoadingInitialData && paymentMethod;

  const nextPaymentDate = upcomingSponsorInvoice?.date
    ? DateTime.fromISO(upcomingSponsorInvoice?.date)
    : undefined;

  const daysUntilNextPayment = nextPaymentDate
    ? nextPaymentDate.diffNow('days').days.toFixed(0)
    : undefined;

  return (
    <Page name="Billing">
      <Container>
        <Helmet>
          <title>Advisor Portal | Billing</title>
        </Helmet>
        {isLoadingStateVisible && (
          <FlexContainer full center marginVertical="large">
            <LoadingSpinner />
          </FlexContainer>
        )}
        {isEmptyStateVisible && (
          <Card>
            <Empty
              emptyTheme={EmptyTheme.NEW}
              title="Setup payment method"
              subtitle="Add a payment method to start inviting clients."
              button={{
                title: 'Add Payment Method',
                onClick: () => openPaymentMethodModal(),
                isPrimary: true,
              }}
            />
          </Card>
        )}
        {isBillingInfoVisible && (
          <>
            <Card title="Payment">
              <CardSection>
                <Text color="textLight" weight="medium" size="small">
                  Active clients
                </Text>
                <Text color="text" size="small">
                  {activeSponsorships.length}
                </Text>
              </CardSection>
              <CardSection>
                <Text color="textLight" weight="medium" size="small">
                  Payment method
                </Text>
                <Flex alignStart justifyBetween>
                  <Text color="text" size="small">
                    {paymentMethod?.brand?.toUpperCase()} ending in {paymentMethod?.lastFour}
                  </Text>
                  <TextButton onClick={openPaymentMethodModal}>Update payment method</TextButton>
                </Flex>
              </CardSection>
              {RA.isNotNil(upcomingSponsorInvoice?.date) && (
                <CardSection>
                  <Text color="textLight" weight="medium" size="small">
                    Next payment
                  </Text>
                  <Text color="text" size="small">
                    {`${formatCurrency(
                      upcomingSponsorInvoice?.amount,
                    )} on ${nextPaymentDate?.toLocaleString(
                      DateTime.DATE_MED,
                    )} (${daysUntilNextPayment} days)`}
                  </Text>
                </CardSection>
              )}
            </Card>
            {RA.isNotEmpty(invoices) ? (
              <InvoicesCard invoices={invoices} />
            ) : (
              <Card title="Invoices">
                <CardSection>
                  <Text color="textLight">No invoices yet</Text>
                </CardSection>
              </Card>
            )}
          </>
        )}
        <PaymentMethodModal>
          <UpdatePaymentMethodModal
            title={!paymentMethod ? 'Setup payment method' : 'Update payment method'}
            onSuccess={onUpdatePaymentMethodSuccess}
            onDone={closePaymentMethodModal}
          />
        </PaymentMethodModal>
      </Container>
    </Page>
  );
};

const GET_SUBSCRIPTION = gql(`
  query Web_GetAdvisorBillingPage {
    subscriptionSponsorships {
      id
      status
    }
    subscription {
      id
      ...SubscriptionFields
    }
    sponsorInvoices {
      id
      date
      amount
      receiptUrl
    }
    upcomingSponsorInvoice {
      date
      amount
    }
    creditBalance
    constants {
      monthlyPriceDollars
    }
  }
`);

const UPDATE_OR_CREATE_STRIPE_PAYMENT_METHOD = gql(`
  mutation Web_UpdateOrCreateAdvisorStripePaymentMethod(
    $input: UpdateOrCreateStripePaymentMethodMutationInput!
  ) {
    updateOrCreateStripePaymentMethod(input: $input) {
      subscription {
        id
        ...SubscriptionFields
      }
      errors {
        message
      }
    }
  }
`);

export default AdvisorBilling;
