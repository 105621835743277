import React from 'react';
import styled from 'styled-components';

import CardBody from 'components/lib/ui/CardBody';
import ModalCard from 'components/lib/ui/ModalCard';
import PremiumUpgradeForm from 'components/premium/PremiumUpgradeForm';

import useTrialStatusQuery from 'common/lib/hooks/premium/useTrialStatusQuery';
import { useQueryParam } from 'lib/hooks';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useTrack from 'lib/hooks/useTrack';

import { PremiumEventNames } from 'common/constants/analytics';
import * as COPY from 'common/constants/copy';

const DescriptionContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  text-align: center;
`;

type Props = {
  next: () => void;
  goBack?: () => void;
};

const PremiumSubscribeModal = ({ next, goBack }: Props) => {
  const isModifiedSubtitlePositionEnabled = useIsFeatureFlagOn(
    'premium-upgrade-form-copy-position',
    { trackImpression: true },
  );

  const { hasPremiumTrialAvailable } = useTrialStatusQuery();

  useTrack(PremiumEventNames.PaymentScreenViewed);

  const initialPromoCode = useQueryParam('promoCode');

  const title = COPY.PREMIUM.UPGRADE_MODAL.TITLE;
  const description = hasPremiumTrialAvailable
    ? COPY.PREMIUM.UPGRADE_MODAL.SUBTITLE.TRIAL_AVAILABLE
    : COPY.PREMIUM.UPGRADE_MODAL.SUBTITLE.TRIAL_UNAVAILABLE;

  return (
    <ModalCard
      onClickBackButton={goBack}
      hideBottomBorder
      title={title}
      description={!isModifiedSubtitlePositionEnabled && description}
    >
      <CardBody>
        <PremiumUpgradeForm initialPromoCode={initialPromoCode ?? undefined} onSuccess={next} />
        {isModifiedSubtitlePositionEnabled && (
          <DescriptionContainer>{description}</DescriptionContainer>
        )}
      </CardBody>
    </ModalCard>
  );
};

export default PremiumSubscribeModal;
