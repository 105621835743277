import { darken, rgba, transparentize } from 'polished';
import { clone } from 'ramda';
import React, { useMemo } from 'react';
import { toast as sonnerToast } from 'sonner';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';

import type { ExtendedToastOptions } from 'lib/ui/toast';

import { MS_PER_SECOND } from 'common/constants/time';

const TOAST_WIDTH_PX = 380;
const TOAST_BUTTON_WIDTH_PX = 118;
const DEFAULT_TOAST_DURATION_MS = 5 * MS_PER_SECOND;

const Root = styled.div`
  margin: 0 auto;
  width: ${TOAST_WIDTH_PX}px;
  transition: opacity 0.4s ease-in-out;
  background: ${({ theme }) =>
    theme.uiTheme === 'dark' ? rgba(theme.color.trueBlack, 0.9) : theme.color.black};
  backdrop-filter: blur(10px);
  border-radius: ${({ theme }) => theme.radius.medium};
  box-shadow: ${({ theme }) =>
    theme.uiTheme === 'dark'
      ? `inset 0 0 0 1px ${theme.color.grayFocus}`
      : `inset 0 0 0 1px ${transparentize(0.8, theme.color.grayDark)}`};
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakPoints.xxs - 1}px) {
    margin: 0 ${({ theme }) => theme.spacing.default} 0 0;
  }
`;

const ToastContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  color: ${({ theme }) => theme.color.textWhite};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  line-height: 150%;
  height: 100%;
`;

const Message = styled.div`
  padding: ${({ theme }) => theme.spacing.default};
`;

const ButtonsContainer = styled.div<{ $size: number }>`
  display: grid;
  grid-template-rows: ${({ $size }) => `repeat(${$size}, 1fr)`};
  border-left: 1px solid ${({ theme }) => transparentize(0.75, theme.color.grayDark)};
  min-width: ${TOAST_BUTTON_WIDTH_PX}px;
`;

const Button = styled.button`
  width: 100%;
  color: ${({ theme }) => theme.color.textWhite};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background: transparent;
  border: unset;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => transparentize(0.97, theme.color.white)};
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.uiTheme === 'dark'
        ? transparentize(0.3, theme.color.trueBlack)
        : darken(0.02, theme.color.black)};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => transparentize(0.75, theme.color.grayDark)};
  }
`;

const Title = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
`;

const Accessory = styled(FlexContainer).attrs({ alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.default};
  max-width: 32px;
`;

type Props = Omit<ExtendedToastOptions, 'title' | 'description'> & {
  children: React.ReactNode;
  onDismiss?: () => void;
};

export const Toast = ({ children, actions: _actions, accessory, onDismiss }: Props) => {
  const actions = useMemo(
    () => [
      ...clone(_actions ?? []),
      ...(onDismiss ? [{ text: 'Dismiss', onClick: onDismiss }] : []),
    ],
    [onDismiss, _actions],
  );

  return (
    <Root>
      <ToastContent>
        {accessory ? <Accessory>{accessory}</Accessory> : null}
        <FlexContainer alignCenter contentStretch>
          <Message>{children}</Message>
        </FlexContainer>
        {actions.length > 0 && (
          <ButtonsContainer $size={actions.length}>
            {actions.map(({ text, onClick, dismissOnClick = true }, i) => (
              <Button
                key={i}
                onClick={() => {
                  onClick();
                  if (dismissOnClick) {
                    onDismiss?.();
                  }
                }}
              >
                {text}
              </Button>
            ))}
          </ButtonsContainer>
        )}
      </ToastContent>
    </Root>
  );
};

export const toast = (options: {
  title?: string | React.ReactNode;
  description?: string;
  actions?: ExtendedToastOptions['actions'];
  duration?: number;
}) =>
  sonnerToast.custom(
    (t) => (
      <Toast key={t} onDismiss={() => sonnerToast.dismiss(t)} actions={options.actions}>
        {options.title && !options.description ? (
          <span>{options.title}</span>
        ) : (
          <>
            <Title>{options.title}</Title>
            <span>{options.description}</span>
          </>
        )}
      </Toast>
    ),
    { duration: options.duration || DEFAULT_TOAST_DURATION_MS },
  );
