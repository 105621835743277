import storage from 'redux-persist/lib/storage';
import { createReducer } from 'typesafe-actions';

import type { GoalByType as CommonGoalByType } from 'common/lib/hooks/goalsV2/useSelectGoals';
import { setCreatedByType } from 'state/goals/actions';

export const goalsPersistConfig = {
  key: 'goals',
  storage,
  whitelist: ['createdCountByType'],
};

export type GoalByType = CommonGoalByType;

export type GoalsState = {
  createdByType: GoalByType;
};

const INITIAL_STATE: GoalsState = {
  createdByType: {},
};

const goals = createReducer<GoalsState>(INITIAL_STATE).handleAction(
  setCreatedByType,
  (state, { payload: createdByType }) => ({
    ...state,
    createdByType,
  }),
);

export default goals;
