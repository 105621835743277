import React from 'react';
import styled from 'styled-components';

import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCancelButton from 'components/lib/ui/ModalCancelButton';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import ManualLink from 'components/lib/ui/link/ManualLink';

import { externalUrls } from 'constants/routes';

const StyledModalCard = styled(ModalCard)`
  width: 570px;
`;

const Root = styled(FlexContainer)``;

const Body = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledLink = styled(ManualLink)`
  font-weight: ${({ theme }) => theme.fontWeight.book};
`;

const AuthyLink = () => (
  <StyledLink href={externalUrls.authy} target="_blank">
    Authy
  </StyledLink>
);

const OnePassLink = () => (
  <StyledLink href={externalUrls.onePass} target="_blank">
    1Password
  </StyledLink>
);

const LastPassLink = () => (
  <StyledLink href={externalUrls.lastPassAuth} target="_blank">
    LastPass Authenticator
  </StyledLink>
);

const INFO =
  'Use an application on your phone to get two-factor authentication codes when prompted by Monarch when you log in.';

type Props = {
  next: () => void;
};

const EnableMfaInfoModal = ({ next }: Props) => (
  <StyledModalCard title="Enable multi-factor authentication">
    <Root column margin="large">
      <Body>{INFO}</Body>
      <Body>
        We recommend using an application such as <AuthyLink />, <OnePassLink />, <LastPassLink />.
        These applications support secure backup of your authentication codes in the cloud and can
        be restored if you lose access to your device.
      </Body>
    </Root>
    <CardFooter>
      <ModalCancelButton />
      <PrimaryButton onClick={next}>Set up using an app</PrimaryButton>
    </CardFooter>
  </StyledModalCard>
);

export default EnableMfaInfoModal;
