import { useRef, useEffect } from 'react';

/**
 * Run an effect on updates only - not the initial mount of a component.
 * Inspired by:
 * https://reactjs.org/docs/hooks-faq.html#can-i-run-an-effect-only-on-updates
 * https://stackoverflow.com/a/55075818
 */
const useUpdateEffect = (fn: () => void, dependencies: any[]) => {
  const initialMount = useRef(true);
  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
    } else {
      fn();
    }
  }, dependencies);
};
export default useUpdateEffect;
