import * as React from 'react';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';

export const HEADER_HEIGHT_PX = 57;

const Grid = styled.div`
  display: grid;
  height: ${HEADER_HEIGHT_PX}px;
  grid-template-columns: 4fr 2fr 2fr 2fr 2fr 40px 40px;
  grid-gap: ${(props) => props.theme.spacing.xxsmall};
  align-items: center;

  padding: 0 ${({ theme }) => theme.spacing.large};
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};

  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const Column = styled(Text).attrs({ weight: 'medium' })`
  font-size: ${(props) => props.theme.fontSize.small};
`;

const HoldingsListHeader = () => (
  <Grid>
    <Column>Security</Column>
    <Column align="right">Price</Column>
    <Column align="right">Quantity</Column>
    <Column align="center">Change</Column>
    <Column align="right">Value</Column>
  </Grid>
);

export default React.memo(HoldingsListHeader);
