import type Api from 'common/lib/api';

const getHackathonApi = (api: Api) => ({
  getOptions: () => api.get('/hackathon/options/'),
  getOptionResponse: (option: string, input: string) =>
    api.post(`/hackathon/options/response/`, { data: { option, input } }),
  assistant: (data: { input: string }) => api.post('/hackathon/assistant/', { data }),
});

export default getHackathonApi;
