import React from 'react';

import type { FormValues as AddVehicleAccountFormValues } from 'components/accounts/AddVehicleAccountDetailsForm';
import AddVehicleAccountForm from 'components/accounts/AddVehicleAccountDetailsForm';
import type { AddVehicleAccountFlowOutput } from 'components/accounts/AddVehicleAccountFlow';
import ModalCard from 'components/lib/ui/ModalCard';

type Props = {
  vin?: string;
  name?: string;
  value?: number;
  next: (props: AddVehicleAccountFlowOutput) => void;
  goBack?: () => void;
};

const AddVehicleAccountDetailsFormModal = ({ vin, name, value, next, goBack }: Props) => {
  const handleSubmit = async (
    values: Pick<AddVehicleAccountFormValues, 'vin' | 'name' | 'subtype'>,
  ) => {
    next(values);
  };

  const handleClickBackButton = async () => {
    goBack?.();
  };

  return (
    <ModalCard onClickBackButton={handleClickBackButton} hideBottomBorder>
      <AddVehicleAccountForm
        initialValues={{
          vin,
          value,
          name,
        }}
        onSubmit={handleSubmit}
      />
    </ModalCard>
  );
};

export default AddVehicleAccountDetailsFormModal;
