import { useMutation, gql } from '@apollo/client';
import * as R from 'ramda';
import { useCallback } from 'react';
import uuid from 'uuid/v4';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import readFragmentOrNull from 'common/lib/graphQl/readFragmentOrNull';

import type {
  CreateGoalAccountAllocation,
  CreateGoalAccountAllocationVariables,
} from 'common/generated/graphQlTypes/CreateGoalAccountAllocation';
import type { GoalAllocationCacheFields } from 'common/generated/graphQlTypes/GoalAllocationCacheFields';
import type { CreateGoalAccountAllocationInput } from 'common/generated/graphQlTypes/globalTypes';

/**
 * Hook used to create a goal account allocation and update the cache optimistically.
 */
const useCreateGoalAccountAllocation = () => {
  const [createAllocationMutation, mutationInfo] = useMutation<
    CreateGoalAccountAllocation,
    CreateGoalAccountAllocationVariables
  >(MUTATION);

  const createAllocation = useCallback(
    async (input: CreateGoalAccountAllocationInput) => {
      const { goalId, accountId, useEntireAccountBalance, amount } = input;

      const response = await createAllocationMutation({
        variables: {
          input,
        },
        optimisticResponse: {
          createGoalAccountAllocation: {
            __typename: 'CreateGoalAccountAllocation',
            goalAccountAllocation: {
              __typename: 'GoalAccountAllocation',
              id: uuid(), // fake temp id until server responds with real id
              useEntireAccountBalance: useEntireAccountBalance ?? false,
              amount: amount ?? null,
              currentAmount: amount ?? null,
              account: {
                __typename: 'Account',
                id: accountId,
                availableBalanceForGoals: 0, // will be replaced by backend response
              },
            },
            goal: null,
            errors: null,
          },
        },
        update: (cache, { data }) => {
          if (!data) {
            return;
          }

          const { createGoalAccountAllocation } = data;

          if (!createGoalAccountAllocation) {
            return;
          }

          const { goalAccountAllocation } = createGoalAccountAllocation;

          if (!goalAccountAllocation) {
            return;
          }

          const cacheId = `GoalV2:${goalId}`;
          const fragmentName = 'GoalAllocationCacheFields';

          const goal = readFragmentOrNull<GoalAllocationCacheFields>(cache, {
            id: cacheId,
            fragment: GOAL_CACHE_FRAGMENT,
            fragmentName,
          });

          if (!goal) {
            return;
          }

          cache.writeFragment({
            id: `GoalV2:${goalId}`,
            fragment: GOAL_CACHE_FRAGMENT,
            fragmentName,
            data: R.evolve(
              {
                accountAllocations: R.append(goalAccountAllocation),
              },
              goal,
            ),
          });
        },
      });

      return response;
    },
    [createAllocationMutation],
  );

  return [createAllocation, mutationInfo] as const;
};

export const GOAL_ALLOCATION_FIELDS_FRAGMENT = gql`
  fragment GoalAllocationFields on GoalAccountAllocation {
    id
    amount
    currentAmount
    useEntireAccountBalance
    account {
      id
      availableBalanceForGoals
    }
  }
`;

export const GOAL_CACHE_FRAGMENT = gql`
  fragment GoalAllocationCacheFields on GoalV2 {
    id
    accountAllocations {
      id
      ...GoalAllocationFields
    }
  }
  ${GOAL_ALLOCATION_FIELDS_FRAGMENT}
`;

const MUTATION = gql`
  mutation Common_CreateGoalAccountAllocation($input: CreateGoalAccountAllocationInput!) {
    createGoalAccountAllocation(input: $input) {
      goalAccountAllocation {
        id
        ...GoalAllocationFields
      }
      goal {
        id
        currentAmount
        completionPercent
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${GOAL_ALLOCATION_FIELDS_FRAGMENT}
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default useCreateGoalAccountAllocation;
