import * as RA from 'ramda-adjunct';
import React, { useCallback } from 'react';

import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import IconButton from 'components/lib/ui/button/IconButton';
import OverlayTrigger from 'components/lib/ui/popover/OverlayTrigger';
import type { Props as EditMerchantModalProps } from 'components/settings/merchants/EditMerchantModal';
import EditMerchantModalBody from 'components/settings/merchants/EditMerchantModal';

import useLoading from 'common/lib/hooks/useLoading';
import useModal from 'lib/hooks/useModal';

import type {
  GetTransactionDrawerQuery,
  Web_TransactionDrawerUpdateTransactionMutation,
  Web_TransactionDrawerUpdateTransactionMutationVariables,
} from 'common/generated/graphql';

type Props = {
  transaction: Maybe<GetTransactionDrawerQuery['getTransaction']>;
  updateTransaction: Mutation<
    Web_TransactionDrawerUpdateTransactionMutation,
    Web_TransactionDrawerUpdateTransactionMutationVariables
  >;
  refetchTransaction: () => Promise<unknown>;
  onClickSplit: (id: string) => void;
  onClickDelete: () => void;
};

type EditMerchantModalContext = Pick<EditMerchantModalProps, 'initialValues'>;

const TransactionDrawerMenu = ({
  transaction,
  updateTransaction,
  refetchTransaction,
  onClickSplit,
  onClickDelete,
}: Props) => {
  const [
    EditMerchantModal,
    { open: openEditMerchantModal, close: closeEditMerchantModal, context: editMerchantContext },
  ] = useModal<EditMerchantModalContext>();

  const {
    id: transactionId,
    merchant,
    isRecurring: isTransactionRecurring,
    pending,
  } = transaction ?? {};
  const { id: merchantId, recurringTransactionStream } = merchant ?? {};

  const isMerchantRecurring = RA.isNotNil(recurringTransactionStream);

  const updateTransactionRecurring = useCallback(async () => {
    await updateTransaction({
      variables: {
        input: {
          id: transactionId,
          isRecurring: !isTransactionRecurring,
        },
      },
      refetchQueries: ['GetTransactionDrawer'],
    });
    await refetchTransaction();
  }, [isTransactionRecurring, transactionId, updateTransaction, refetchTransaction]);

  const [isSaving, toggleTransactionRecurring] = useLoading(updateTransactionRecurring);

  return (
    <>
      <OverlayTrigger
        overlay={
          <DropdownMenu>
            <DropdownMenuItem icon="edit" onClick={openEditMerchantModal}>
              Edit merchant details
            </DropdownMenuItem>
            {!isMerchantRecurring && (
              <DropdownMenuItem
                icon="calendar"
                onClick={() =>
                  openEditMerchantModal({
                    initialValues: {
                      isRecurring: true,
                      amount: transaction?.amount,
                      baseDate: transaction?.date,
                    },
                  })
                }
              >
                Mark merchant as recurring
              </DropdownMenuItem>
            )}

            {isMerchantRecurring && (
              <DropdownMenuItem
                disabled={isSaving}
                icon="calendar"
                onClick={toggleTransactionRecurring}
              >
                {isTransactionRecurring
                  ? 'Mark transaction as not recurring'
                  : 'Mark transaction as recurring'}
              </DropdownMenuItem>
            )}

            {transaction?.id && (
              <>
                {!transaction?.isSplitTransaction && !pending && (
                  <DropdownMenuItem onClick={() => onClickSplit(transaction.id)} icon="split">
                    Split transaction
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem onClick={onClickDelete} type="danger" icon="trash">
                  Delete transaction
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenu>
        }
        placement="bottom-start"
      >
        {({ toggleOpen, isOpen }) => (
          <IconButton icon="more-horizontal" size="medium" onClick={toggleOpen} active={isOpen} />
        )}
      </OverlayTrigger>

      <EditMerchantModal onClose={closeEditMerchantModal}>
        {RA.isNotNil(merchantId) && (
          <EditMerchantModalBody
            merchantId={merchantId}
            afterUpdate={refetchTransaction}
            afterDelete={refetchTransaction}
            {...editMerchantContext}
          />
        )}
      </EditMerchantModal>
    </>
  );
};

export default TransactionDrawerMenu;
