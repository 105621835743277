import { DateTime } from 'luxon';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import Tabs from 'common/components/tabs/Tabs';
import CashFlowSankeyCard from 'components/cashFlows/CashFlowSankeyCard';
import Column from 'components/lib/ui/Column';
import Flex from 'components/lib/ui/Flex';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Row from 'components/lib/ui/Row';
import {
  GET_CASH_FLOW_BY_ENTITY_QUERY,
  GET_CASH_FLOW_BY_TIMEFRAME_QUERY,
} from 'components/routes/CashFlow';

import {
  aggregatesDataAdapter,
  getDateRangeForTimeframe,
  getDefaultDateForTimeframe,
} from 'common/lib/cashFlow/adapters';
import useQuery from 'common/lib/hooks/useQuery';
import useToggle from 'common/lib/hooks/useToggle';
import { chartDataPointFormatter } from 'lib/cashFlow/Adapters';
import { convertCashFlowFilterToInput } from 'lib/cashFlow/filter';
import type { CashFlowContextType } from 'lib/contexts/CashFlowContext';
import CashFlowContext from 'lib/contexts/CashFlowContext';
import { DEFAULT_FILTERS } from 'lib/transactions/Filters';
import { ChartType } from 'lib/ui/charts';

import type { TimeframeBase } from 'common/constants/timeframes';
import { TIMEFRAME_MONTH, TIMEFRAME_OPTIONS } from 'common/constants/timeframes';

const LoadingContainer = styled(Flex)`
  flex: 1;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.default};
`;

const OnboardingSankey = () => {
  const filterValue = {
    date: DateTime.local().startOf(TIMEFRAME_MONTH).toISODate(),
    timeframe: TIMEFRAME_MONTH,
    view: ChartType.CashFlowSankey,
    sankey: 'category',
    tags: [],
    accounts: [],
  };

  const { timeframe, date, view, sankey: sankeyView, ...aggregateFilters } = filterValue;
  const [hideAmounts, { toggle: toggleHideAmounts, setOff: unhideAmounts }] = useToggle(false);

  const initialTimeframeIndex = useMemo(
    () => TIMEFRAME_OPTIONS.findIndex(({ value }) => value === TIMEFRAME_MONTH),
    [],
  );

  const activeTimeframe = TIMEFRAME_MONTH as TimeframeBase;

  const activeDate = date || getDefaultDateForTimeframe(activeTimeframe);

  const cleanedFilterInput = convertCashFlowFilterToInput(aggregateFilters);

  const { data: aggregatesData } = useQuery(GET_CASH_FLOW_BY_TIMEFRAME_QUERY, {
    variables: { filters: R.mergeRight(DEFAULT_FILTERS, cleanedFilterInput) },
  });

  const timeframeData = useMemo(
    () => aggregatesDataAdapter(aggregatesData, activeTimeframe, chartDataPointFormatter),
    [aggregatesData, activeTimeframe],
  );

  const dateRange = useMemo(
    () => getDateRangeForTimeframe(activeTimeframe, timeframeData, activeDate),
    [activeDate, activeTimeframe, timeframeData],
  );

  const { data, isDataAvailable, isNetworkRequestInFlight } = useQuery(
    GET_CASH_FLOW_BY_ENTITY_QUERY,
    {
      variables: {
        filters: R.mergeRight(DEFAULT_FILTERS, { ...cleanedFilterInput, ...dateRange }),
      },
    },
  );

  const isLoadingSummary = !isDataAvailable && isNetworkRequestInFlight;

  const context: CashFlowContextType = useMemo(
    () => ({
      hideAmounts,
      shareDisabled: !data,
      toggleHideAmounts,
      unhideAmounts,
    }),
    [hideAmounts, data, toggleHideAmounts, unhideAmounts],
  );

  return (
    <Tabs initialIndex={initialTimeframeIndex}>
      {(() => {
        if (data && aggregatesData !== undefined) {
          return (
            <CashFlowContext.Provider value={context}>
              {
                <Row padding={0}>
                  <Column md={12}>
                    <Tabs initialIndex={2}>
                      <CashFlowSankeyCard
                        data={data}
                        isLoading={isLoadingSummary}
                        hideHeader
                        useParams={false}
                        disableOnClick
                        hideCategoryIfZero
                      />
                    </Tabs>
                  </Column>
                </Row>
              }
            </CashFlowContext.Provider>
          );
        } else {
          return (
            <LoadingContainer center column>
              <LoadingSpinner />
              Loading cash flow...
            </LoadingContainer>
          );
        }
      })()}
    </Tabs>
  );
};

export default OnboardingSankey;
