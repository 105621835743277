import type { ConnectRouteEvent } from 'common/types/Finicity';

const FINICITY_ACTION_SIGN_IN_ATTEMPT = 'SignInAttempt';
const FINICITY_ACTION_OAUTH_CONNECTION_FAILURE = 'OAuthConnectionFailure';
const FINICITY_ACTION_OAUTH_SIGN_IN_ATTEMPT = 'OAuthSignInAttempt';
const FINICITY_INVALID_CREDENTIALS_CODE = 103;

/**
 * Return true if and only if the event represents a connectivity issue.
 * This is a best guess approximation, which we'll improve over time.
 *
 * https://docs.finicity.com/user-events-2-0-optional/#user-events-2-0-optional-14
 */
export const isConnectivityIssue = <TEvent extends Record<string, any> = ConnectRouteEvent>(
  event: TEvent,
): boolean => {
  const isSignInConnectivityIssue =
    event.data?.action === FINICITY_ACTION_SIGN_IN_ATTEMPT &&
    event.data?.success === false &&
    event.data?.errorCode !== FINICITY_INVALID_CREDENTIALS_CODE;
  const isOAuthConnectionIssue = event.data?.action === FINICITY_ACTION_OAUTH_CONNECTION_FAILURE;
  const isUnsuccessfulOAuthSignInAttempt =
    event.data?.action === FINICITY_ACTION_OAUTH_SIGN_IN_ATTEMPT && event.data?.success === false;
  return isSignInConnectivityIssue || isOAuthConnectionIssue || isUnsuccessfulOAuthSignInAttempt;
};
