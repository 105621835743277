import React from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import CurrencyField from 'components/lib/form/CurrencyField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCancelButton from 'components/lib/ui/ModalCancelButton';
import Text from 'components/lib/ui/Text';

import { GET_ACCOUNT_TYPE_OPTIONS } from 'common/lib/graphQl/accounts';
import useQuery from 'common/lib/hooks/useQuery';
import {
  getDisplayOptionsFromAccountTypeOptions,
  getDisplayForType,
} from 'lib/accounts/ManualAccounts';

import { AccountTypeName } from 'common/constants/accounts';

import type { GetAccountTypeOptions } from 'common/generated/graphQlTypes/GetAccountTypeOptions';

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: ${({ theme }) => theme.spacing.xsmall};
`;

const Title = styled(Text)`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const StyledCardFooter = styled(CardFooter)`
  border: none;
`;

export type FormValues = {
  vin: string;
  name: string;
  subtype: string | null;
  value: number;
};

type Props = {
  initialValues?: Partial<FormValues>;
  onSubmit: (values: Pick<FormValues, 'vin' | 'name' | 'subtype'>) => Promise<void>;
};

const AddVehicleAccountDetailsForm = ({ initialValues, onSubmit }: Props) => {
  const { data } = useQuery<GetAccountTypeOptions>(GET_ACCOUNT_TYPE_OPTIONS);
  const { accountTypeOptions = [] } = data ?? {};
  const type = AccountTypeName.VEHICLE;
  const optionsForType = getDisplayOptionsFromAccountTypeOptions(type, accountTypeOptions);
  const displayName = getDisplayForType(type, accountTypeOptions);

  const handleSubmit = (values: FormValues) => {
    const { vin, name, subtype } = values;
    onSubmit({
      vin,
      name,
      subtype,
    });
  };

  return (
    <Form
      initialValues={{
        ...initialValues,
        subtype: optionsForType && optionsForType.length > 0 ? optionsForType[0].value : null,
      }}
      onSubmit={handleSubmit}
    >
      <FormContext.Consumer>
        {({ values }) => (
          <>
            <Root>
              <Title>Add a vehicle</Title>
              <SelectField required name="subtype" label="Type" options={optionsForType} />
              <TextField name="name" placeholder={`My ${displayName} Account`} required />
              <CurrencyField
                disabled
                placeholder="$0.00"
                name="value"
                label="Value"
                maskOptions={{ allowDecimal: true }}
                description="We'll sync the value of this vehicle periodically from VinAudit"
                required
              />
            </Root>
            <StyledCardFooter>
              <ModalCancelButton />
              <FormSubmitButton size="small" disableWhenValuesUnchanged={false}>
                Save
              </FormSubmitButton>
            </StyledCardFooter>
          </>
        )}
      </FormContext.Consumer>
    </Form>
  );
};

export default AddVehicleAccountDetailsForm;
