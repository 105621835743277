import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useDraggable } from '@dnd-kit/core';
import React from 'react';
import type { PropsWithChildren } from 'react';
import styled from 'styled-components';

import DragHandle from 'components/lib/dndKit/DragHandle';
import type { DroppableId } from 'components/lib/dndKit/Droppable';

type Props = PropsWithChildren<{
  id: string | number;
  data?: {
    // The id of the container that the item is being dragged to.
    // This is used to show an outline when dragging to a different container.
    droppableId: DroppableId;
  } & Record<string, unknown>;
  disabled?: boolean;
  className?: string;
  /** Custom drag handle component. If not provided, will use the default DragHandle */
  dragHandle?: React.ReactNode;
  /** When true, the entire element will be draggable even when using a drag handle */
  allowFullDrag?: boolean;
}>;

const DraggableRoot = styled.div<{ $isDragging: boolean; $isDraggable: boolean }>`
  /* Base styles that can be overridden by className */
  opacity: ${({ $isDragging }) => ($isDragging ? 0.5 : 1)};
  touch-action: none;

  /* Only show grab cursor if element is draggable */
  ${({ $isDragging, $isDraggable }) =>
    $isDraggable &&
    !$isDragging &&
    `
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  `}
`;

const Draggable = ({
  children,
  id,
  data,
  disabled,
  className,
  dragHandle,
  allowFullDrag = false,
}: Props) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id,
    data,
    disabled,
  });

  // Allow root listeners if no drag handle or allowFullDrag is true
  const rootListeners: DraggableSyntheticListeners | undefined =
    !dragHandle || allowFullDrag ? listeners : undefined;

  // Create drag handle element with listeners if using drag handle
  const dragHandleElement = dragHandle ? (
    <div {...listeners} role="button" tabIndex={0}>
      {dragHandle}
    </div>
  ) : (
    <DragHandle {...listeners} />
  );

  return (
    <DraggableRoot
      ref={setNodeRef}
      className={className}
      $isDragging={isDragging}
      $isDraggable={!dragHandle || allowFullDrag}
      {...attributes}
      {...rootListeners}
    >
      {dragHandle && dragHandleElement}
      {children}
    </DraggableRoot>
  );
};

export default Draggable;
