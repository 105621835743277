import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import { rgba } from 'polished';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import { FiArrowRight, FiBell, FiEyeOff } from 'react-icons/fi';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import GoalImage from 'components/goalsV2/GoalImage';
import Dot from 'components/lib/ui/Dot';
import DragDots from 'components/lib/ui/DragDots';
import Text from 'components/lib/ui/Text';
import MerchantCriteriaTag from 'components/transactions/MerchantCriteriaTag';
import Tag from 'components/transactions/Tag';

import {
  getDescriptionForStringOperator,
  getDescriptionForAmountOperator,
} from 'common/lib/transactions/Rules';
import { getDescriptionForReviewStatus, ReviewStatus } from 'common/lib/transactions/review';
import { formatCurrency } from 'common/utils/Currency';
import { formatRelativeTime } from 'common/utils/date';

import { gql } from 'common/generated/gql';
import type { TransactionRuleFieldsFragment } from 'common/generated/graphql';
import { SplitAmountType } from 'common/generated/graphql';

const Root = styled.div`
  border-radius: ${({ theme }) => theme.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  background: ${({ theme }) => theme.color.white};
  min-height: 90px;
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  user-select: none;

  transition: ${({ theme }) => theme.transition.default};
  :hover {
    background: ${({ theme }) => rgba(theme.color.grayBackground, 0.5)};
  }
`;

const Side = styled.div`
  flex: 1;
  padding: 10px ${({ theme }) => theme.spacing.xsmall} 10px 0;
`;

const RightSide = styled(Side)`
  padding-left: ${({ theme }) => theme.spacing.xlarge};
`;

const ArrowPositioner = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.grayFocus};
  border-right: 1px solid ${({ theme }) => theme.color.grayFocus};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  background: ${({ theme }) => theme.color.grayBackground};
  color: ${({ theme }) => theme.color.textLight};
`;

const StyledDot = styled(Dot).attrs({ size: 14 })`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const Row = styled.div``;

const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.default};
  cursor: move;
`;

const Bell = styled(FiBell).attrs({ size: 18 })`
  align-self: center;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.color.blue};
`;

const EyeOffIcon = styled(FiEyeOff).attrs({ size: 16 })`
  align-self: center;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledAccountLogo = styled(AccountLogo)`
  width: ${({ theme }) => theme.spacing.large};
  height: ${({ theme }) => theme.spacing.large};
  position: absolute;
`;

const RoundGoalImage = styled(GoalImage).attrs({ showGradient: false, size: 'small' })`
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 10px;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const AccountName = styled.div`
  margin-left: 28px;
`;

const RuleLastAppliedContainer = styled(Text).attrs({
  color: 'textLight',
  italic: true,
  size: 'small',
})`
  display: block;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  rule: TransactionRuleFieldsFragment;
  onClick: () => void;
};

const TransactionRule = ({
  rule: {
    merchantCriteriaUseOriginalStatement,
    merchantCriteria,
    amountCriteria,
    categories,
    accounts,
    setMerchantAction,
    setCategoryAction,
    sendNotificationAction,
    setHideFromReportsAction,
    addTagsAction,
    reviewStatusAction,
    recentApplicationCount,
    lastAppliedAt,
    linkGoalAction,
    needsReviewByUserAction,
    unassignNeedsReviewByUserAction,
    splitTransactionsAction,
  },
  onClick,
}: Props) => {
  const lastAppliedRelativeTime = useMemo(() => {
    if (R.isNil(lastAppliedAt)) {
      return null;
    }

    const datetime = DateTime.fromISO(lastAppliedAt);
    return formatRelativeTime(datetime);
  }, [lastAppliedAt]);

  const { amountType, splitsInfo } = splitTransactionsAction ?? {};

  return (
    <>
      <Root onClick={onClick}>
        <DotsContainer>
          <DragDots />
        </DotsContainer>
        <Side>
          {!!merchantCriteria?.length && (
            <Row>
              If{' '}
              <MerchantCriteriaTag
                merchantCriteriaUseOriginalStatement={merchantCriteriaUseOriginalStatement}
              />
              {merchantCriteria?.map(({ operator, value }, index) => (
                <React.Fragment key={index}>
                  {index > 0 && 'or '}
                  <Tag>{getDescriptionForStringOperator(operator)}</Tag>
                  <Tag>{value}</Tag>
                </React.Fragment>
              ))}
            </Row>
          )}
          {amountCriteria && (
            <Row>
              If <Tag>{amountCriteria.isExpense ? 'expense' : 'income'}</Tag>
              <Tag>{getDescriptionForAmountOperator(amountCriteria.operator)}</Tag>
              {!R.isNil(amountCriteria.valueRange) ? (
                <>
                  <Tag>{formatCurrency(amountCriteria.valueRange.lower ?? 0)}</Tag>and{' '}
                  <Tag>{formatCurrency(amountCriteria.valueRange.upper ?? 0)}</Tag>
                </>
              ) : (
                <Tag>{formatCurrency(amountCriteria.value ?? 0)}</Tag>
              )}
            </Row>
          )}
          {!!categories?.length && (
            <Row>
              If category <Tag>equals</Tag>
              {categories.map(({ id, icon, name }) => (
                <Tag key={id}>
                  {icon} {name}
                </Tag>
              ))}
            </Row>
          )}
          {!!accounts?.length && (
            <Row>
              If account <Tag>equals</Tag>{' '}
              {accounts.map((account) => (
                <Tag key={account.id}>
                  <StyledAccountLogo logoUrl={account.logoUrl} icon={account.icon} />
                  <AccountName>{account.displayName}</AccountName>
                </Tag>
              ))}
            </Row>
          )}
        </Side>
        <ArrowPositioner>
          <FiArrowRight />
        </ArrowPositioner>
        <RightSide>
          {setMerchantAction && (
            <Row>
              Rename to <Tag>{setMerchantAction.name}</Tag>
            </Row>
          )}
          {setCategoryAction && (
            <Row>
              Recategorize to{' '}
              <Tag>
                {setCategoryAction.icon} {setCategoryAction.name}
              </Tag>
            </Row>
          )}
          {sendNotificationAction && (
            <Row>
              <Tag>
                <Bell /> Send notification
              </Tag>
            </Row>
          )}
          {setHideFromReportsAction && (
            <Row>
              <Tag>
                <EyeOffIcon /> Hide transaction
              </Tag>
            </Row>
          )}
          {addTagsAction && (
            <Row>
              Add {pluralize('tag', addTagsAction.length)}{' '}
              {addTagsAction.map(({ name, color }) => (
                <Tag key={name}>
                  <StyledDot color={color} />
                  {name}
                </Tag>
              ))}
            </Row>
          )}
          {!!reviewStatusAction && (
            <Row>
              Set review status to <Tag>{getDescriptionForReviewStatus(reviewStatusAction)}</Tag>
              {reviewStatusAction === ReviewStatus.NeedsReview &&
                (!!needsReviewByUserAction || !!unassignNeedsReviewByUserAction) && (
                  <>
                    by<Tag>{needsReviewByUserAction?.name ?? 'anyone'}</Tag>
                  </>
                )}
            </Row>
          )}
          {linkGoalAction && (
            <Row>
              Link to goal{' '}
              <Tag>
                <RoundGoalImage {...linkGoalAction} /> {linkGoalAction.name}
              </Tag>
            </Row>
          )}
          {amountType && splitsInfo && (
            <Row>
              Split into <Tag>{splitsInfo.length} transactions</Tag>by{' '}
              <Tag>{amountType === SplitAmountType.PERCENTAGE ? 'percentage' : 'amount'}</Tag>
            </Row>
          )}
        </RightSide>
      </Root>
      {RA.isNotNil(lastAppliedRelativeTime) && (
        <RuleLastAppliedContainer>
          {recentApplicationCount} {pluralize('transaction', recentApplicationCount)} updated
          &middot; {lastAppliedRelativeTime} ago
        </RuleLastAppliedContainer>
      )}
    </>
  );
};

TransactionRule.fragments = {
  TransactionRuleFields: gql(`
    fragment TransactionRuleFields on TransactionRuleV2 {
      id
      merchantCriteriaUseOriginalStatement
      merchantCriteria {
        operator
        value
      }
      amountCriteria {
        operator
        isExpense
        value
        valueRange {
          lower
          upper
        }
      }
      categoryIds
      accountIds
      categories {
        id
        name
        icon
      }
      accounts {
        id
        displayName
        icon
        logoUrl
      }
      setMerchantAction {
        id
        name
      }
      setCategoryAction {
        id
        name
        icon
      }
      addTagsAction {
        id
        name
        color
      }
      linkGoalAction {
        id
        name
        imageStorageProvider
        imageStorageProviderId
      }
      needsReviewByUserAction {
        id
        name
      }
      unassignNeedsReviewByUserAction
      sendNotificationAction
      setHideFromReportsAction
      reviewStatusAction
      recentApplicationCount
      lastAppliedAt
      splitTransactionsAction {
        amountType
        splitsInfo {
          categoryId
          merchantName
          amount
          goalId
          tags
          hideFromReports
          reviewStatus
          needsReviewByUserId
        }
      }
    }
  `),
};

export default TransactionRule;
