import React from 'react';

export type Props = {
  children: React.ReactNode;
  errorComponent?: React.ReactNode;
  onError: (error: Error) => void;
};

class ErrorBoundary extends React.Component<Props> {
  state = { hasErrorOccurred: false };

  componentDidCatch(error: Error) {
    this.props.onError(error);
    this.setState({ hasErrorOccurred: true });
  }

  render = () => (this.state.hasErrorOccurred ? this.props.errorComponent : this.props.children);
}

export default ErrorBoundary;
