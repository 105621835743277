import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import type { PaymentTransactions } from 'common/lib/recurring/types';
import { formatCurrencyCentsOptional } from 'common/utils/Currency';
import { hasDatePassed, isoDateToMonthAndYear, isSameMonth } from 'common/utils/date';

import type { Frequency } from 'common/constants/recurringTransactions';
import {
  RecurringTransactionStreamType,
  RECURRING_TRANSACTIONS_FREQUENCIES_INFO,
} from 'common/constants/recurringTransactions';

import { CategoryGroupType } from 'common/generated/graphql';
import type { EditAccountFormFieldsFragment, Maybe } from 'common/generated/graphql';

type SortableRecurringItem = {
  date: string;
  isPast: boolean;
  stream: {
    name: string;
  };
};

type SortableRecurringStream = {
  nextForecastedTransaction: {
    date: string;
  };
  stream: {
    name: string;
  };
};

type FilterableRecurringItem = {
  isPast: boolean;
};

type ExtendedFilterableRecurringItem = {
  isCompleted: Maybe<boolean>;
};

type Option = {
  label: string;
  value: () => string;
};

export type AccountCreditReportLiabilityAccount =
  EditAccountFormFieldsFragment['creditReportLiabilityAccount'];

type RecurringTransactionStream = NonNullable<
  NonNullable<AccountCreditReportLiabilityAccount>['recurringTransactionStream']
>;

type EnrichedRecurringTransactionStream = Omit<RecurringTransactionStream, 'dayOfTheMonth'> & {
  dayOfTheMonth: string | undefined;
};

export enum GroupByValues {
  Frequency = 'frequency',
  Category = 'category',
  Type = 'type',
}

export const USE_STATEMENT_DUE_DATE_VALUE = 'statement_due_date';

export const getFrequencyOptions = () =>
  Object.entries(RECURRING_TRANSACTIONS_FREQUENCIES_INFO).map(([frequency, value]) => ({
    value: frequency,
    label: value,
  }));

export const getFrequencyLabel = <T extends string>(frequency: T) =>
  RECURRING_TRANSACTIONS_FREQUENCIES_INFO[frequency as Frequency];

export const getRecurringTooltipText = (frequency: Frequency | undefined) =>
  !frequency
    ? undefined
    : `Recurring ${RECURRING_TRANSACTIONS_FREQUENCIES_INFO[frequency]?.toLowerCase()}`;

export const DATE_RANGE_OPTIONS: Option[] = [
  { label: 'This month', value: () => DateTime.local().endOf('month').toISODate() },
  { label: 'This week', value: () => DateTime.local().endOf('week').toISODate() },
];

export const RECURRING_TYPE_OPTIONS = [
  { label: 'Expense', value: RecurringTransactionStreamType.Expense },
  { label: 'Income', value: RecurringTransactionStreamType.Income },
];

export const RECURRING_STATUS_OPTIONS = [
  { label: 'Active', value: true },
  { label: 'Canceled', value: false },
];

export const getWidgetDescription = (remainingDueAmount?: number) =>
  `${formatCurrencyCentsOptional((remainingDueAmount ?? 0) * -1)} remaining due`;

export const getMonthTitle = (date: DateTime) =>
  isSameMonth(date, DateTime.local()) ? 'This month' : isoDateToMonthAndYear(date.toISODate());

export const getMonthTitleWithPreposition = (date: DateTime) =>
  isSameMonth(date, DateTime.local())
    ? 'this month'
    : `in ${isoDateToMonthAndYear(date.toISODate())}`;

export const getSearchDisabledTooltipText = (
  startedAt: string | undefined | null,
  nextAvailableAt: string | undefined | null,
) => {
  if (!startedAt) {
    return null;
  }

  const nextSearchAt = nextAvailableAt ? DateTime.fromISO(nextAvailableAt) : DateTime.local();

  if (hasDatePassed(nextSearchAt)) {
    return null;
  }

  const elapsed = -getFormattedTooltipMinutes(DateTime.fromISO(startedAt));
  const nextAt = getFormattedTooltipMinutes(nextSearchAt);
  return `Last synced ${elapsed} ${pluralize(
    'minute',
    elapsed,
  )} ago. Please wait ${nextAt} ${pluralize('minute', nextAt)} before trying again.`;
};

const getFormattedTooltipMinutes = (date: DateTime) => Math.ceil(date.diffNow().as('minutes'));

export const sortItems = <TItem extends SortableRecurringItem>(items: TItem[]): TItem[] =>
  R.sortWith(
    [R.ascend(R.prop('isPast')), R.ascend(R.prop('date')), R.ascend(R.path(['stream', 'name']))],
    items,
  );

export const sortStreams = <TStream extends SortableRecurringStream>(streams: TStream[]) =>
  R.sortWith(
    [R.ascend(R.path(['nextForecastedTransaction', 'date'])), R.ascend(R.path(['stream', 'name']))],
    streams,
  );

export const sortStreamsByCategory = <TStream extends SortableRecurringStream>(
  streams: TStream[],
) => R.sort(R.ascend(R.path(['category', 'name'])), streams);

export const sortStreamsByFrequency = <TStream extends SortableRecurringStream>(
  streams: TStream[],
) => R.sort(R.ascend(R.path(['frequency'])), streams);

export const sortStreamsByGroupBy = <TStream extends SortableRecurringStream>(
  streams: TStream[],
  groupBy?: GroupByValues,
) => {
  switch (groupBy) {
    case GroupByValues.Category:
      return sortStreamsByCategory(streams);
    case GroupByValues.Frequency:
      return sortStreamsByFrequency(streams);
    default:
      return sortStreams(streams);
  }
};

export const formatNextPaymentDate = (date: string | undefined | null, showLateDaysAgo = false) => {
  if (R.isNil(date) || R.isEmpty(date)) {
    return null;
  }

  const nextPaymentDate = DateTime.fromISO(date);
  const nextPaymentDateFormatted = nextPaymentDate.toFormat('MMMM d, yyyy');

  const daysUntilNextPayment = Math.ceil(nextPaymentDate.diffNow().as('days'));

  const isLate = daysUntilNextPayment < 0;
  const daysDelta = Math.abs(daysUntilNextPayment);
  const daysUntilNextPaymentLabel = `${daysDelta} ${pluralize('day', daysDelta)}`;

  if (isLate) {
    if (showLateDaysAgo) {
      return `${nextPaymentDateFormatted} (${daysUntilNextPaymentLabel} ago)`;
    } else {
      return `${nextPaymentDateFormatted}`;
    }
  }

  return `${nextPaymentDateFormatted} (${daysUntilNextPaymentLabel})`;
};

export const splitUpcomingItems = <TItem extends FilterableRecurringItem>(items: TItem[]) => {
  const upcomingItems = items.filter((i) => !i.isPast);
  const pastItems = items.filter((i) => i.isPast);

  return { upcomingItems, pastItems };
};

export const splitUpcomingCompleteItems = <TItem extends ExtendedFilterableRecurringItem>(
  items: TItem[],
) => {
  const [completeItems, upcomingItems] = R.partition(R.propOr(false, 'isCompleted'), items);
  return { upcomingItems, completeItems };
};

export const splitActiveCanceledItems = <T extends { stream: { isActive: boolean } }>(
  items: T[],
): { active: T[]; canceled: T[] } => {
  const { true: activeItems = [], false: canceledItems = [] } = RA.isNotNilOrEmpty(items)
    ? R.groupBy((item) => R.toString(item.stream.isActive), items)
    : {};
  return { active: activeItems, canceled: canceledItems };
};

export const getInitialType = (
  amount: number | undefined,
): NonNullable<RecurringTransactionStreamType> =>
  R.isNil(amount) || amount <= 0
    ? RecurringTransactionStreamType.Expense
    : RecurringTransactionStreamType.Income;

/** Transform the stream amount in accordance to the stream type (expense/income) */
export const getAmountWithSign = (
  originalAmount: number | undefined | null,
  type: RecurringTransactionStreamType,
) => {
  if (!originalAmount) {
    return 0;
  }

  if (!type) {
    return originalAmount;
  }

  const amount = Math.abs(originalAmount);
  return type === RecurringTransactionStreamType.Expense ? -amount : amount;
};

export const getCreditReportLiabilityAccountFormFields = (
  creditReportLiabilityAccount: AccountCreditReportLiabilityAccount,
) => {
  if (!creditReportLiabilityAccount) {
    return undefined;
  }

  // Convert dayOfTheMonth to string and use USE_STATEMENT_DUE_DATE_VALUE as default value
  const dayOfTheMonth = (value: Maybe<number>) => value?.toString() ?? USE_STATEMENT_DUE_DATE_VALUE;

  const baseRecurringTransactionStream =
    creditReportLiabilityAccount?.recurringTransactionStream ?? ({} as RecurringTransactionStream);

  return {
    ...creditReportLiabilityAccount,
    recurringTransactionStream: R.evolve({ dayOfTheMonth }, baseRecurringTransactionStream),
  };
};

export const getRecurrenceFields = (
  recurringTransactionStream: Maybe<EnrichedRecurringTransactionStream>,
) => ({
  baseDate: undefined,
  dayOfTheMonth: recurringTransactionStream?.dayOfTheMonth,
  defaultPaymentAccountId: recurringTransactionStream?.defaultPaymentAccount?.id,
  defaultPaymentCategoryId: recurringTransactionStream?.defaultPaymentCategory?.id,
  isActive: recurringTransactionStream?.isActive,
});

export const getLastStatementDueDateDay = (lastStatement: Maybe<{ dueDate: string }>) => {
  const nowISO = DateTime.now().toISO();
  const lastStatementDueDate = lastStatement?.dueDate ?? nowISO;
  return DateTime.fromISO(lastStatementDueDate).day;
};

export const formatDueDate = (day: Maybe<string>, lastStatementDueDateDay: number) =>
  R.isNil(day) || day === USE_STATEMENT_DUE_DATE_VALUE
    ? `${lastStatementDueDateDay}${getSuffixForDay(lastStatementDueDateDay)} of the month`
    : `${day}${getSuffixForDay(+day)} of the month`;

export const getSuffixForDay = (day: number) => {
  if (day === 1 || day === 21 || day === 31) {
    return 'st';
  }
  if (day === 2 || day === 22) {
    return 'nd';
  }
  if (day === 3 || day === 23) {
    return 'rd';
  }
  return 'th';
};

export const getDueDateOptions = () =>
  R.range(1, 32).map((day) => ({
    value: `${day}`,
    label: `${day}${getSuffixForDay(day)} of the month`,
  }));

export const splitTransactionsBetweenPaymentsAndCredits = (
  paymentTransactions: PaymentTransactions,
) => {
  const [payments, credits] = R.partition(
    R.pathEq(['category', 'group', 'type'], CategoryGroupType.TRANSFER),
    paymentTransactions ?? [],
  );

  return { payments, credits };
};
