import React from 'react';
import { useHistory } from 'react-router-dom';

import { LinkInstitutionFlow } from 'components/accounts/LinkAccountFlow';
import Modal from 'components/lib/ui/Modal';

import { ensureEnumValue } from 'common/utils/Enum';

import { DEFAULT_DATA_PROVIDER } from 'common/constants/institutions';
import routes from 'constants/routes';

import { DataProviderLegacy } from 'common/generated/graphQlTypes/globalTypes';
import type RouteProps from 'types/RouteProps';

type Props = RouteProps<typeof routes.accounts.addAccountForDataProvider> & {
  onComplete: () => void;
};

const DirectLinkAccountForDataProvider = ({
  match: {
    params: { dataProvider, institutionId },
  },
  onComplete,
}: Props) => {
  const { goBack, push } = useHistory();

  const preferredDataProvider = ensureEnumValue(
    DataProviderLegacy,
    dataProvider.toUpperCase(),
    DEFAULT_DATA_PROVIDER,
  );

  return (
    <Modal onClose={goBack}>
      <LinkInstitutionFlow
        institution={{
          preferredDataProvider,
          finicityInstitutionId:
            preferredDataProvider === DataProviderLegacy.FINICITY ? institutionId ?? null : null,
          plaidInstitutionId:
            preferredDataProvider === DataProviderLegacy.PLAID ? institutionId ?? null : null,
          mxInstitutionId:
            preferredDataProvider === DataProviderLegacy.MX ? institutionId ?? null : null,
        }}
        next={() => {
          onComplete();
          push(routes.accounts());
        }}
        goBack={() => push(routes.accounts())}
        key={preferredDataProvider}
      />
    </Modal>
  );
};

export default DirectLinkAccountForDataProvider;
