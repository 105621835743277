import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import CardBody from 'components/lib/ui/CardBody';
import CardFooterTextButton from 'components/lib/ui/CardFooterTextButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import ExternalLink from 'components/lib/ui/Link';
import RelativeDate from 'components/lib/ui/RelativeDate';
import Summary, { SummaryPair } from 'components/lib/ui/Summary';

import { getZillowURLForDataProviderId } from 'common/constants/externalUrls';

import type { AccountFieldsFragment } from 'common/generated/graphql';
import { DataProviderLegacy } from 'common/generated/graphql';

export type Props = {
  account: AccountFieldsFragment;
  isManual: boolean;
  isVehicle: boolean;
  isRealEstate: boolean;
  isInvestments: boolean;
  transactionCount: number;
  holdingCount: number;
  isTrackingManualHoldings: boolean;
  isTrackingManualBalances: boolean;
};

const StyledExternalLinkIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  align-self: baseline;
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const AccountSummary = ({
  account,
  isManual,
  transactionCount,
  holdingCount,
  isVehicle,
  isRealEstate,
  isInvestments,
  isTrackingManualHoldings,
  isTrackingManualBalances,
}: Props) => {
  const isSynced = !isManual;

  const shouldShowTransactionCount =
    (isSynced && !isInvestments) || (isManual && isInvestments && !isTrackingManualHoldings);

  const shouldShowHoldingCount = isInvestments && !isTrackingManualBalances;

  const shouldShowTimestamps = (isManual || isRealEstate || isVehicle) && !isInvestments;

  const shouldShowViewOnZillowLink =
    !isManual && account.dataProvider === DataProviderLegacy.ZILLOW.toLowerCase();

  return (
    <Card title="Summary">
      <CardBody>
        <Summary>
          {!isManual && !isRealEstate && (
            <SummaryPair
              dim
              label="Institution"
              value={account.institution?.name}
              link={account.institution?.url}
            />
          )}
          <SummaryPair dim label="Account Type" value={account.subtype.display} />
          {shouldShowTransactionCount && (
            <SummaryPair dim label="Total Transactions" value={transactionCount.toLocaleString()} />
          )}
          {shouldShowHoldingCount && (
            <SummaryPair dim label="Total Holdings" value={holdingCount.toLocaleString()} />
          )}
          {shouldShowTimestamps && (
            <>
              <SummaryPair dim label="Last updated">
                <RelativeDate date={account.displayLastUpdatedAt} />
              </SummaryPair>
              <SummaryPair dim label="Created">
                {DateTime.fromISO(account.createdAt).toLocaleString(DateTime.DATE_MED)}
              </SummaryPair>
            </>
          )}
        </Summary>
      </CardBody>
      {shouldShowViewOnZillowLink && (
        <CardFooterTextButton>
          <ExternalLink
            href={
              account.dataProviderAccountId
                ? getZillowURLForDataProviderId(account.dataProviderAccountId)
                : undefined
            }
            target="_blank"
          >
            <FlexContainer alignCenter>
              View property on Zillow
              <StyledExternalLinkIcon name="external-link" />
            </FlexContainer>
          </ExternalLink>
        </CardFooterTextButton>
      )}
    </Card>
  );
};

export default AccountSummary;
