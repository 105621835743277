import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { CloudinaryRestApi } from 'common/lib/api';
import { generateFormDataForUpload } from 'common/lib/file';
import { getFileExtension, getFileBaseName } from 'common/utils/File';

import { gql } from 'common/generated/gql';
import type { FileData } from 'common/types/File';

const SIGN_UPLOAD_URL_MUTATION = gql(/* GraphQL */ `
  mutation Common_GetTransactionAttachmentUploadInfo($transactionId: UUID!) {
    getTransactionAttachmentUploadInfo(transactionId: $transactionId) {
      info {
        path
        requestParams {
          timestamp
          folder
          signature
          api_key
          upload_preset
        }
      }
    }
  }
`);

const ADD_TRANSACTION_ATTACHMENT_MUTATION = gql(/* GraphQL */ `
  mutation Common_AddTransactionAttachment($input: TransactionAddAttachmentMutationInput!) {
    addTransactionAttachment(input: $input) {
      attachment {
        id
        publicId
        extension
        sizeBytes
        filename
        originalAssetUrl
      }
      errors {
        message
      }
    }
  }
`);

const useUploadTransactionAttachment = (transactionId: GraphQlUUID) => {
  const [getUploadInfo] = useMutation(SIGN_UPLOAD_URL_MUTATION, {
    variables: { transactionId },
  });
  const [addTransactionAttachment] = useMutation(ADD_TRANSACTION_ATTACHMENT_MUTATION);

  return useCallback(
    async <TFile extends FileData>(file: TFile, onProgress?: (e: ProgressEvent) => void) => {
      const mutationResult = await getUploadInfo();
      const info = mutationResult.data?.getTransactionAttachmentUploadInfo?.info;

      if (!info) {
        throw new Error('Failed to sign upload');
      }
      const { path: cloudinaryUploadUrl, requestParams } = info;

      const formData = generateFormDataForUpload(file, requestParams);

      const sizeBytes = file.size;
      const extension = getFileExtension(file.name);
      const filename = getFileBaseName(file.name);
      const response = await CloudinaryRestApi.post(cloudinaryUploadUrl, formData, {
        onUploadProgress: onProgress,
      });

      const result = await addTransactionAttachment({
        variables: {
          input: {
            transactionId,
            filename,
            publicId: response.data.public_id,
            extension,
            sizeBytes,
          },
        },
      });

      const attachment = result.data?.addTransactionAttachment?.attachment;

      if (attachment) {
        return attachment;
      } else {
        // TODO remove attachment from cloudinary if this call fails
        throw Error('Transaction upload failed. Please try again later or use a different file');
      }
    },
    [transactionId, getUploadInfo, addTransactionAttachment],
  );
};

export default useUploadTransactionAttachment;
