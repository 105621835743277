import styled from 'styled-components';

import { linkMixin } from 'components/lib/ui/Link';

// Link component for navigating to an external site, or a manual onClick callback
const ManualLink = styled.a<{
  /** Keep Link unstyled until hover. */
  stealthy?: boolean;
  /** Sets color and font-weight to inherit */
  unstyled?: boolean;
}>`
  ${linkMixin}
`;

export default ManualLink;
