import React from 'react';
import styled from 'styled-components';

import Confirmation from 'components/lib/ui/Confirmation';
import Text from 'components/lib/ui/Text';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  rolloverCategories: { name: string; icon: string }[];
};

const Icon = styled.span`
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const Name = styled.span`
  flex-grow: 1;
`;

const RolloverCategoriesHeader = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small};
  padding: ${({ theme }) => theme.spacing.small} 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const Category = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const GroupRolloverConfirmation = ({ onCancel, onConfirm, rolloverCategories }: Props) => (
  <Confirmation
    title="Enable group-level budgets"
    confirm="Enable"
    onConfirm={onConfirm}
    onCancel={onCancel}
  >
    <div>
      There are two rollover categories in this group. if you enable budgeting by group, their
      rollover amounts will no longer be visible. Do you want to continue? You can always undo this
      action later.
      <RolloverCategoriesHeader>
        <Text weight="medium" size="small">
          Rollover categories in this group
        </Text>
      </RolloverCategoriesHeader>
      {rolloverCategories.map((category, idx) => (
        <Category key={idx}>
          <Icon>{category.icon}</Icon>
          <Name>{category.name}</Name>
        </Category>
      ))}
    </div>
  </Confirmation>
);

export default GroupRolloverConfirmation;
