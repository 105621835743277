import React from 'react';
import styled from 'styled-components';

import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

export type Props = {
  onCompleted: () => void;
};

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const Img = styled.img`
  width: 100%;
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const BudgetCreationSuccessCard = ({ onCompleted }: Props) => (
  <ModalCard
    title="Your plan has been created"
    description="Here's how you can use your historical spending to set your planned amounts for each category:"
  >
    <Root>
      <Img src={require('static/images/plan-creation.gif')} />
    </Root>
    <CardFooter>
      <PrimaryButton onClick={onCompleted}>Okay</PrimaryButton>
    </CardFooter>
  </ModalCard>
);

export default BudgetCreationSuccessCard;
