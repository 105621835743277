import React from 'react';
import styled from 'styled-components';

import MonarchForAdvisorsLogo from 'components/lib/ui/MonarchForAdvisorsLogo';
import NavLink from 'components/lib/ui/link/NavLink';

import routes from 'constants/routes';

const HeaderLink = styled(NavLink)`
  flex: 1;
`;

const SideBarAdvisorPortalHeader = () => (
  <>
    <HeaderLink to={routes.advisorPortal.clients()}>
      <MonarchForAdvisorsLogo dark />
    </HeaderLink>
  </>
);

export default SideBarAdvisorPortalHeader;
