import React, { useState, useEffect } from 'react';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

const HiddenPreloadImage = styled.img`
  display: none;
`;

type Props = {
  imageUrls: string[];
  intervalMs?: number;
};

/** Component used to rotate through images with a nice fade animation. */
const ImageFadeCarousel = ({ imageUrls, intervalMs = 4000 }: Props) => {
  const [index, setIndex] = useState(0);

  const transitions = useTransition(imageUrls[index], null, {
    from: { opacity: 0 },
    update: { opacity: 1 },
    leave: { opacity: 0 },
    initial: { opacity: 1 },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((current) => (current + 1) % imageUrls.length);
    }, intervalMs);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {transitions.map(({ item, key, props }) => (
        <animated.img key={key} style={{ ...props, position: 'absolute' }} src={item} />
      ))}

      {/* Preload images so they're cached when the animation first shows them. These elements are hidden from view. */}
      {imageUrls.map((src) => (
        <HiddenPreloadImage src={src} key={src} />
      ))}
    </>
  );
};

export default ImageFadeCarousel;
