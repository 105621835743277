/**
 * DEPRECATED: This component is deprecated and will be removed in the future.
 * Please use the `useToast` hook instead.
 */
import { transparentize, darken, rgba } from 'polished';
import React, { useMemo } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import type { Toast as ToastProps } from 'react-hot-toast';
import styled, { keyframes, css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';

import useTheme from 'lib/hooks/useTheme';
import type { ExtendedToastOptions } from 'lib/ui/toast';

const TOAST_DURATION_MS = 5 * 1000;
const TOAST_WIDTH_PX = 361;
const TOAST_BUTTON_WIDTH_PX = 118;
const TOAST_OFFSET_PX = 10;

type Props = ToastProps &
  Omit<ExtendedToastOptions, 'title' | 'description'> & {
    children: React.ReactNode;
    onDismiss?: () => void;
  };

const enter = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, ${TOAST_OFFSET_PX}px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const exit = keyframes`
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, ${TOAST_OFFSET_PX}px, 0);
  }
`;

const Root = styled.div<{ $visible: boolean }>`
  margin: 0 auto;
  width: ${TOAST_WIDTH_PX}px;
  overflow: hidden;
  transition: opacity 0.4s ease-in-out;
  opacity: ${({ $visible }) => ($visible ? '1' : '0.5')};
  background: ${({ theme }) =>
    theme.uiTheme === 'dark' ? rgba(theme.color.trueBlack, 0.9) : theme.color.black};
  border-radius: ${({ theme }) => theme.radius.medium};
  animation: ${({ $visible }) =>
    $visible
      ? css`0.35s forwards cubic-bezier(.21, 1.02, .73, 1) ${enter}`
      : css`0.4s forwards cubic-bezier(0.86, 0, 0.07, 1) ${exit}`};

  @media (min-width: ${({ theme }) => theme.breakPoints.xxs - 1}px) {
    margin: 0 ${({ theme }) => theme.spacing.default} 0 0;
  }
`;

const ToastContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  color: ${({ theme }) => theme.color.textWhite};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  line-height: 150%;
  height: 100%;
`;

const Message = styled.div`
  padding: ${({ theme }) => theme.spacing.default};
`;

const ButtonsContainer = styled.div<{ $size: number }>`
  display: grid;
  grid-template-rows: ${({ $size }) => `repeat(${$size}, 1fr)`};
  border-left: 1px solid ${({ theme }) => transparentize(0.75, theme.color.grayDark)};
  min-width: ${TOAST_BUTTON_WIDTH_PX}px;
`;

const Button = styled.button`
  width: 100%;
  color: ${({ theme }) => theme.color.textWhite};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background: transparent;
  border: unset;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => transparentize(0.97, theme.color.white)};
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.uiTheme === 'dark'
        ? transparentize(0.3, theme.color.trueBlack)
        : darken(0.02, theme.color.black)};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => transparentize(0.75, theme.color.grayDark)};
  }
`;

const Title = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
`;

const Accessory = styled(FlexContainer).attrs({ alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.default};
  max-width: 32px;
`;

const CustomToaster = () => {
  const theme = useTheme();

  return (
    <Toaster
      position="bottom-right"
      toastOptions={{
        duration: TOAST_DURATION_MS,
        style: { right: parseInt(theme.spacing.default, 10) },
      }}
      gutter={parseInt(theme.spacing.default, 10)}
    >
      {(t) => {
        const { dismissable = true, title, description } = t as ToastProps & ExtendedToastOptions;
        const onDismiss = dismissable ? () => toast.dismiss(t.id) : undefined;
        return (
          <Toast key={t.id} {...t} onDismiss={onDismiss}>
            {!description ? (
              <span>{title}</span>
            ) : (
              <>
                <Title>{title}</Title>
                <span>{description}</span>
              </>
            )}
          </Toast>
        );
      }}
    </Toaster>
  );
};

export const Toast = ({
  children,
  visible,
  actions: _actions = [],
  accessory,
  onDismiss,
}: Props) => {
  const actions = useMemo(
    () => _actions.concat(onDismiss ? [{ text: 'Dismiss', onClick: onDismiss }] : []),
    [onDismiss, _actions],
  );

  return (
    <Root $visible={visible}>
      <ToastContent>
        {accessory ? <Accessory>{accessory}</Accessory> : null}
        <FlexContainer alignCenter contentStretch>
          <Message>{children}</Message>
        </FlexContainer>
        {actions.length > 0 && (
          <ButtonsContainer $size={actions.length}>
            {actions.map(({ text, onClick, dismissOnClick = true }, i) => (
              <Button
                key={i}
                onClick={() => {
                  onClick();
                  if (dismissOnClick) {
                    onDismiss?.();
                  }
                }}
              >
                {text}
              </Button>
            ))}
          </ButtonsContainer>
        )}
      </ToastContent>
    </Root>
  );
};

export default CustomToaster;
