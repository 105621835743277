import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setBulkSelectState } from 'actions';
import usePrevious from 'common/lib/hooks/usePrevious';
import { getIsBulkSelectActive } from 'selectors';

type Options = {
  onChange?: (isActive: boolean) => void;
};

/**
 * Hook to manage transaction selection state for TransactionsList.
 * It's useful to have this state globally available so it's easily accessible higher in the tree.
 *
 * In the future, we may want to move all of the bulk transaction
 * select from useBulkUpdateTransactionState to here.
 */
const useIsBulkTransactionSelectActive = ({ onChange }: Options = {}) => {
  const dispatch = useDispatch();
  const isBulkSelectActive = useSelector(getIsBulkSelectActive);
  const previousIsBulkSelectActive = usePrevious(isBulkSelectActive);

  const setBulkSelectActive = useCallback(
    (newIsActive: boolean) => dispatch(setBulkSelectState(newIsActive)),
    [dispatch],
  );

  // We use useEffect here instead of in setBulkSelectActive because we want to
  // make sure we call onChange even if the state changed from another source.
  useEffect(() => {
    if (previousIsBulkSelectActive !== isBulkSelectActive) {
      onChange?.(isBulkSelectActive);
    }
  }, [isBulkSelectActive]); // eslint-disable-line react-hooks/exhaustive-deps

  return { isBulkSelectActive, setBulkSelectActive };
};

export default useIsBulkTransactionSelectActive;
