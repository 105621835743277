import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import TextButton from 'components/lib/ui/TextButton';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import { useOverlay } from 'components/lib/ui/popover';
import Popover from 'components/lib/ui/popover/Popover';

import useTrialStatusQuery from 'common/lib/hooks/premium/useTrialStatusQuery';
import useTrack from 'lib/hooks/useTrack';

import { PremiumEventNames } from 'common/constants/analytics';
import * as COPY from 'common/constants/copy';

const Root = styled(Popover)`
  padding: ${({ theme }) => theme.spacing.xlarge};
  display: flex;
  flex-direction: column;
  max-width: 345px;
`;

const Title = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const Description = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyledTextButton = styled(TextButton)`
  margin-left: 10px;
`;

type Props = {
  title: string;
  description: string;
  analyticsName: string;
  onClickUpgrade: () => void;
};

const PremiumFeaturePopover = ({ title, description, analyticsName, onClickUpgrade }: Props) => {
  const { close } = useOverlay();

  const { hasPremiumTrialAvailable } = useTrialStatusQuery();

  useTrack(PremiumEventNames.PremiumPopoverViewed, { feature: analyticsName });

  return (
    <Root>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <FlexContainer alignCenter>
        <PrimaryButton onClick={onClickUpgrade}>
          <ButtonIcon name="diamond" />
          <span>
            {hasPremiumTrialAvailable
              ? COPY.PREMIUM.UPGRADE_CTA.TRIAL_AVAILABLE
              : COPY.PREMIUM.UPGRADE_CTA.TRIAL_UNAVAILABLE}
          </span>
        </PrimaryButton>
        <StyledTextButton onClick={close}>Maybe later</StyledTextButton>
      </FlexContainer>
    </Root>
  );
};

export default PremiumFeaturePopover;
