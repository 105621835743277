import React from 'react';

import type { Props as FilterMenuProps } from 'components/reports/filters/FilterMenu';
import FilterMenu from 'components/reports/filters/FilterMenu';
import type { FilterMenuSection } from 'components/reports/filters/types';

type Props = {
  onCancel: () => void;
  onChangeSections: (sections: FilterMenuSection[]) => void;
} & Pick<
  FilterMenuProps,
  'sections' | 'isLoading' | 'isSearchLoading' | 'onSearchChange' | 'onClickApply'
>;

const TransactionFilterMenuPopover: React.FC<Props> = ({
  onCancel,
  onChangeSections,
  ...props
}) => <FilterMenu onChange={onChangeSections} onClickCancel={onCancel} {...props} />;

export default TransactionFilterMenuPopover;
