import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import type useTheme from 'lib/hooks/useTheme';
import type { NodeWithExtraProps } from 'lib/ui/sankey';
import { setColorNodeFromTarget, setNodeColorFromSource } from 'lib/ui/sankey';

import type { Web_GetCashFlowPageQuery } from 'common/generated/graphql';
import { CategoryGroupType } from 'common/generated/graphql';

export const INCOME_NODE_ID = 'income';
export const SAVINGS_NODE_ID = 'savings';
export const SANKEY_GROUP_MODE_TO_LABEL = {
  category: 'Category',
  group: 'Group',
  both: 'Both',
};

export type SankeyGroupMode = keyof typeof SANKEY_GROUP_MODE_TO_LABEL;

export enum NodeType {
  CategoryGroup = 'categoryGroup',
  Category = 'category',
  /** Generic groups don't necessarily fit into the above types, e.g. Income */
  Generic = 'generic',
}

export type CashFlowNode = {
  /** Unique identifier for the node */
  id: string;
  /** Display label for the node */
  label: string;

  /** Total amount associated with the category or category group */
  value: number;

  /** Original value before processing, used to keep transaction sign for further processing */
  rawValue: number;

  /** Optional property to force a fixed value for a node, useful for nodes like the Income node */
  fixedValue?: number;

  type: NodeType;

  /** Optional property indicating the type of the category group that the node represents */
  categoryGroupType?: CategoryGroupType;

  /** Optional property indicating whether the node is clickable */
  isClickable?: boolean;
};

export type CashFlowLink = {
  source: string;
  target: string;
  value: number;
};

export const cashFlowNodeColorDeterminer = <
  N extends {
    id: string;
    depth?: number;
    color?: string;
    categoryGroupType?: CategoryGroupType;
    type?: NodeType;
  },
  L extends { source: N; target: N },
>(
  nodes: N[],
  links: L[],
  theme: ReturnType<typeof useTheme>,
  processType: keyof typeof SANKEY_GROUP_MODE_TO_LABEL,
) => {
  const incomeColors = [theme.color.lime, theme.color.blue, theme.color.indigo, theme.color.pink];

  const expenseColors = [
    theme.color.orange,
    theme.color.indigo,
    theme.color.red,
    theme.color.purple,
    theme.color.pink,
    theme.color.yellow,
  ];

  nodes = R.sortBy((node) => node.depth ?? 0, nodes);

  const isGroupMode = processType === 'group';
  const isCategoryMode = processType === 'category';

  nodes.forEach((node, index) => {
    const isExpenseCategoryOrGroup = node.categoryGroupType === CategoryGroupType.EXPENSE;
    const isIncomeOrSavingsNode = node.id && [INCOME_NODE_ID, SAVINGS_NODE_ID].includes(node.id);
    const isCategoryGroup = node.type === NodeType.CategoryGroup;
    const isCategory = node.type === NodeType.Category;

    const isExpenseCategory = isExpenseCategoryOrGroup && isCategory && isCategoryMode;
    const isExpenseGroup = isExpenseCategoryOrGroup && isCategoryGroup;

    const isIncomeCategory = !isExpenseCategoryOrGroup && isCategory && !isGroupMode;
    const isIncomeGroup = !isExpenseCategoryOrGroup && isCategoryGroup && isGroupMode;

    const isExpenseCategoryOrGroupNode = isExpenseCategory || isExpenseGroup;
    const isIncomeCategoryOrGroupNode = isIncomeCategory || isIncomeGroup;

    if (isIncomeOrSavingsNode) {
      node.color = theme.color.green;
    } else if (isExpenseCategoryOrGroupNode) {
      node.color = expenseColors[index % expenseColors.length];
    } else if (isIncomeCategoryOrGroupNode) {
      node.color = incomeColors[index % incomeColors.length];
    } else {
      setNodeColorFromSource(node, links);
    }
  });

  if (processType === 'both') {
    // To set a color from the target node, we need to do it after the source node color has been set
    nodes.filter(({ depth }) => depth === 0).forEach((node) => setColorNodeFromTarget(node, links));
  }
};

export const cashFlowSankeyDataAdapter = (
  data: Web_GetCashFlowPageQuery,
  groupMode?: SankeyGroupMode,
) => {
  if (!groupMode) {
    return { nodes: [], links: [] };
  }

  // We need to call Math.abs here because the aggregates API returns negative
  // values for expenses, and we need to keep the sign for the Sankey links
  // calculation.
  const formatValue = (value: number) => Math.abs(value);

  const { byCategoryGroup, byCategory } = data;
  const [{ summary }] = data.summary;

  const validCategoryGroups = filterValidItems(byCategoryGroup);
  const validCategories = filterValidItems(byCategory);

  // Only include default nodes at the end, and only if there are other nodes
  const defaultNodes: CashFlowNode[] = [
    {
      id: INCOME_NODE_ID,
      label: 'Income',
      value: summary.sumIncome,
      rawValue: summary.sumIncome,
      // Needed to force a fixed value for the Income node
      fixedValue: summary.sumIncome,
      type: NodeType.Generic,
      isClickable: false,
    },
  ];

  let nodes: CashFlowNode[] = [];
  let links: CashFlowLink[] = [];

  if (groupMode === 'group') {
    // Income groups -> Income top-level node -> Savings | Expense groups
    nodes = [...nodes, ...toCategoryGroupNodes(validCategoryGroups)];

    links = [
      ...links,
      ...validCategoryGroups
        .filter(({ groupBy }) => groupBy?.categoryGroup?.type === CategoryGroupType.INCOME)
        .map(({ groupBy, summary }) => ({
          source: groupBy.categoryGroup!.id,
          target: INCOME_NODE_ID,
          value: formatValue(summary.sum),
        })),
      ...validCategoryGroups
        .filter(({ groupBy }) => groupBy?.categoryGroup?.type === CategoryGroupType.EXPENSE)
        .map(({ groupBy, summary }) => ({
          source: INCOME_NODE_ID,
          target: groupBy.categoryGroup!.id,
          value: formatValue(summary.sum),
        })),
    ];
  } else if (groupMode === 'category') {
    // Income categories -> Income top-level node -> Savings | Expense categories
    nodes = [...nodes, ...toCategoryNodes(validCategories)];

    links = [
      ...links,
      ...validCategories
        .filter(({ groupBy }) => groupBy?.category?.group?.type === CategoryGroupType.INCOME)
        .map(({ groupBy, summary }) => ({
          source: groupBy.category!.id,
          target: INCOME_NODE_ID,
          value: formatValue(summary.sum),
        })),
      ...validCategories
        .filter(({ groupBy }) => groupBy?.category?.group?.type === CategoryGroupType.EXPENSE)
        .map(({ groupBy, summary }) => ({
          source: INCOME_NODE_ID,
          target: groupBy.category!.id,
          value: formatValue(summary.sum),
        })),
    ];
  } else if (groupMode === 'both') {
    // Income categories -> Income groups -> Income top-level node -> Savings | Expense groups -> Expense categories
    nodes = [
      ...nodes,
      ...toCategoryGroupNodes(validCategoryGroups),
      ...toCategoryNodes(validCategories),
    ];

    links = [
      ...links,
      ...validCategories
        .filter(({ groupBy }) => groupBy?.category?.group?.type === CategoryGroupType.INCOME)
        .map(({ groupBy, summary }) => ({
          source: groupBy.category!.id,
          target: groupBy.category!.group.id,
          value: formatValue(summary.sum),
        })),
      ...validCategories
        .filter(({ groupBy }) => groupBy?.category?.group?.type === CategoryGroupType.EXPENSE)
        .map(({ groupBy, summary }) => ({
          source: groupBy.category!.group.id,
          target: groupBy.category!.id,
          value: formatValue(summary.sum),
        })),
      ...validCategoryGroups
        .filter(({ groupBy }) => groupBy?.categoryGroup?.type === CategoryGroupType.INCOME)
        .map(({ groupBy, summary }) => ({
          source: groupBy.categoryGroup!.id,
          target: INCOME_NODE_ID,
          value: formatValue(summary.sum),
        })),
      ...validCategoryGroups
        .filter(({ groupBy }) => groupBy?.categoryGroup?.type === CategoryGroupType.EXPENSE)
        .map(({ groupBy, summary }) => ({
          source: INCOME_NODE_ID,
          target: groupBy.categoryGroup!.id,
          value: formatValue(summary.sum),
        })),
    ];
  } else {
    // We should never get here, but evaluating just for safety
    throw new Error(`Invalid processType: ${groupMode}`);
  }

  if (nodes.length > 0) {
    nodes = [...nodes, ...defaultNodes];
  }

  if (summary.savings > 0) {
    nodes = [
      ...nodes,
      {
        id: SAVINGS_NODE_ID,
        label: 'Savings',
        value: summary.savings,
        rawValue: summary.savings,
        type: NodeType.Generic,
        isClickable: false,
      },
    ];

    links = [
      ...links,
      {
        source: INCOME_NODE_ID,
        target: SAVINGS_NODE_ID,
        value: summary.savings,
      },
    ];
  }

  return { nodes, links };
};

export function assertNodeHasId(
  node: Record<string, unknown> | string | number,
): asserts node is NodeWithExtraProps<CashFlowNode, CashFlowLink> {
  if (RA.isString(node) || RA.isNumber(node)) {
    throw new Error('Node must be an object');
  }

  if (!node.id) {
    throw new Error('Node must have an id');
  }
}

const isCategoryGroup = (
  groupBy: Record<string, unknown>,
): groupBy is NonNullable<
  Web_GetCashFlowPageQuery['byCategoryGroup'][number]['groupBy']['categoryGroup']
> => R.hasPath(['categoryGroup'], groupBy);

/* Filters out items with no money movement or nil groupBy. */
const filterValidItems = <
  T extends {
    summary: { sum: number };
    groupBy: {
      categoryGroup?: Web_GetCashFlowPageQuery['byCategoryGroup'][number]['groupBy']['categoryGroup'];
      category?: Web_GetCashFlowPageQuery['byCategory'][number]['groupBy']['category'];
    };
  },
>(
  items: T[],
) =>
  items.filter(({ summary, groupBy }) => {
    const isGroup = isCategoryGroup(groupBy);
    return RA.isNotNil(isGroup ? groupBy.categoryGroup : groupBy.category) && summary.sum !== 0;
  });

/* Map valid category groups to CashFlowNode format */
const toCategoryGroupNodes = (
  categoryGroups: ReturnType<typeof filterValidItems>,
): CashFlowNode[] =>
  categoryGroups.map(({ groupBy, summary }) => ({
    id: groupBy.categoryGroup!.id,
    label: groupBy.categoryGroup!.name,
    value: Math.abs(summary.sum),
    type: NodeType.CategoryGroup,
    categoryGroupType: groupBy.categoryGroup!.type,
    rawValue: summary.sum,
    fixedValue: Math.abs(summary.sum),
  }));

/* Map valid categories to CashFlowNode format */
const toCategoryNodes = (categories: ReturnType<typeof filterValidItems>): CashFlowNode[] =>
  categories.map(({ groupBy, summary }) => ({
    id: groupBy.category!.id,
    label: `${groupBy.category!.icon} ${groupBy.category!.name}`,
    value: Math.abs(summary.sum),
    type: NodeType.Category,
    categoryGroupType: groupBy.category!.group.type,
    rawValue: summary.sum,
    fixedValue: Math.abs(summary.sum),
  }));
