/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv';

import * as Segment from './segment';

export interface AppReviewResponded {
  response: string;
}
export interface ChartDownloaded {
  /**
   * Type of chart the user is downloading.
   */
  chart: string;
  /**
   * The type of view this chart is rendered with at the moment of the download (e.g. cash flow Sankey has "category", "group", and "both")
   */
  view?: string;
}
export interface ConnectInstitutionSelected {
  data_provider: string;
  institution_id: string;
  institution_name?: string;
}
export interface ErrorSurfaced {
  /**
   * String of the error message
   */
  error_string: string;
  /**
   * The route the error occurred on
   */
  from_route: string;
}
export interface FiltersApplied {
  page: string;
  sections: Record<string, any>;
}
export interface FinicityConnectErrored {
  code: number;
  institution_id?: string;
  institution_name?: string;
  reason: string;
}
export interface FinicityConnectEvent {
  /**
   * Additional information
   */
  params?: Record<string, any>;
  /**
   * Screen the user is on
   */
  screen?: string;
  /**
   * Route or user event
   */
  type: string;
}
export interface PageViewed {
  name?: any | null;
  path?: any | null;
  referrer?: any | null;
  search?: any | null;
  title?: any | null;
  url?: any | null;
}
export interface PinnedNotificationDismissed {
  /**
   * Pinned notification type
   */
  type: string;
}
export interface PinnedNotificationOpened {
  /**
   * Pinned notification type
   */
  type: string;
}
export interface PlaidLinkCredentialsSubmitted {
  error_code?: any | null;
  error_message?: any | null;
  error_type?: any | null;
  exit_status?: any | null;
  institution_id?: string;
  institution_name?: string;
  institution_search_query?: any | null;
  link_session_id?: string;
  mfa_type?: any | null;
  request_id?: string;
  search?: string;
  timestamp?: string;
  view_name?: any | null;
}
export interface PlaidLinkErrored {
  error_code?: string;
  error_message?: string;
  error_type?: string;
  exit_status?: string;
  institution_id?: string;
  institution_name?: string;
  link_session_id?: string;
  request_id?: string;
  search?: string;
  timestamp?: string;
}
export interface PlaidLinkExited {
  error_code?: string;
  error_message?: string;
  error_type?: string;
  exit_status?: string;
  institution_id?: string;
  institution_name?: string;
  institution_search_query?: any | null;
  link_session_id?: string;
  mfa_type?: any | null;
  request_id?: string;
  search?: string;
  timestamp?: string;
  view_name?: any | null;
}
export interface PlaidLinkHandedOff {
  error_code?: any | null;
  error_message?: any | null;
  error_type?: any | null;
  exit_status?: any | null;
  institution_id?: string;
  institution_name?: string;
  institution_search_query?: any | null;
  link_session_id?: string;
  mfa_type?: any | null;
  request_id?: string;
  search?: string;
  timestamp?: string;
  view_name?: any | null;
}
export interface PlaidLinkInstitutionSelected {
  error_code?: any | null;
  error_message?: any | null;
  error_type?: any | null;
  exit_status?: any | null;
  id?: string;
  institution_id?: string;
  institution_name?: string;
  institution_search_query?: any | null;
  institution_status?: string;
  link_session_id?: string;
  mfa_type?: any | null;
  name?: string;
  request_id?: string;
  search?: string;
  status?: string;
  timestamp?: string;
  view_name?: any | null;
}
export interface PlaidLinkInstitutionsSearched {
  error_code?: any | null;
  error_message?: any | null;
  error_type?: any | null;
  exit_status?: any | null;
  institution_search_query?: string;
  link_session_id?: string;
  mfa_type?: any | null;
  request_id?: string;
  search?: string;
  timestamp?: string;
  view_name?: any | null;
}
export interface PlaidLinkMfaSubmitted {
  error_code?: any | null;
  error_message?: any | null;
  error_type?: any | null;
  exit_status?: any | null;
  institution_id?: string;
  institution_name?: string;
  institution_search_query?: any | null;
  link_session_id?: string;
  mfa_type?: string;
  request_id?: string;
  search?: string;
  timestamp?: string;
  view_name?: any | null;
}
export interface PlaidLinkOpened {
  error_code?: any | null;
  error_message?: any | null;
  error_type?: any | null;
  exit_status?: any | null;
  institution_id?: string;
  institution_name?: string;
  institution_search_query?: any | null;
  link_session_id: string;
  mfa_type?: any | null;
  request_id?: any | null;
  search?: string;
  timestamp: string;
  view_name?: any | null;
}
export interface PlaidLinkViewTransitioned {
  error_code?: any | null;
  error_message?: any | null;
  error_type?: any | null;
  exit_status?: any | null;
  institution_id?: string;
  institution_name?: string;
  institution_search_query?: any | null;
  link_session_id?: string;
  mfa_type?: string;
  request_id?: string;
  search?: string;
  timestamp?: string;
  view_name?: string;
}
export interface ReferralCodeShared {
  method: string;
}
export interface SearchOpened {
  /**
   * The way the user opend the search prompt
   */
  method: string;
}
export interface SearchResultsReturned {
  /**
   * The list of what type of results were returned
   */
  result?: any[];
  /**
   * The user search query
   */
  searchTerm?: string;
}
export interface SearchResultsSelected {
  /**
   * The list of what type of results were selected
   */
  result?: any[];
  /**
   * The user search query
   */
  searchTerm?: string;
}
export interface StatementUploadErrored {
  errorCode?: string;
  errorMessage?: string;
  filename?: string;
  parserName?: string;
}
export interface TransactionsDateRangeChanged {
  endDate?: string;
  startDate?: string;
}
export interface TransactionsDownloadedToCsv {
  /**
   * A key-value object with the used filters and their respective counts.
   */
  filtersWithCounts: Record<string, any>;
  /**
   * Where was this button called from?
   */
  origin: string;
}
export interface TransactionsSearchChanged {
  search?: string;
}
export interface UserSignedUpAndVerifiedWeb {
  email: string;
  userId: string;
}
export interface ViewOnboardingTourPage {
  /**
   * Page number user viewed
   */
  index: number;
}
export interface ViewedAdviceCategoryPage {
  name: string;
}
export interface ViewedAdviceItem {
  name: string;
}
export interface ViewedAdviceTask {
  item_name: string;
  name: string;
}
export interface ViewedWalkthroughStep {
  /**
   * Useful so we can distinct the events by the type of walkthrough.
   */
  identifier: string;
  stepIndex?: number;
  stepTitle?: string;
}
export interface VisualAppearancePreferenceUpdated {
  /**
   * Either 'light', 'dark' or 'system'
   */
  preference: string;
}
export interface WalkthroughOpened {
  /**
   * Useful so we can distinct the events by the type of walkthrough.
   */
  identifier: string;
  totalSteps?: number;
}
export interface WalkthroughSkipped {
  /**
   * Index of the visible step when the user clicked to skip.
   */
  currentStepIndex?: number;
  /**
   * Useful so we can distinct the events by the type of walkthrough.
   */
  identifier: string;
}
export interface WebAppPromoCodeApplied {
  auto?: boolean;
  promoCode?: string;
}
export interface WebAppQueryParamPromoCodeLoaded {
  promoCode?: string;
}

export type ViolationHandler = (
  message: Record<string, any>,
  violations: Ajv.ErrorObject[],
) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (message, violations) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2,
  );

  console.warn(msg);
};

let onViolation = defaultValidationErrorHandler;

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: SegmentAnalytics.AnalyticsJS;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
  onViolation = options.onViolation || onViolation;
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true });
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'));

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '7.4.1',
      },
    },
  };
}

/**
 * @typedef AppReviewResponded
 * @property {string} response -
 */
/**
 * @typedef ChartDownloaded
 * @property {string} chart - Type of chart the user is downloading.
 * @property {string} [view] - The type of view this chart is rendered with at the moment of the download (e.g. cash flow Sankey has "category", "group", and "both")
 */
/**
 * @typedef ConnectInstitutionSelected
 * @property {string} data_provider -
 * @property {string} institution_id -
 * @property {string} [institution_name] -
 */
/**
 * @typedef ErrorSurfaced
 * @property {string} error_string - String of the error message
 * @property {string} from_route - The route the error occurred on
 */
/**
 * @typedef FiltersApplied
 * @property {string} page -
 * @property {Record<string, any>} sections -
 */
/**
 * @typedef FinicityConnectErrored
 * @property {number} code -
 * @property {string} [institution_id] -
 * @property {string} [institution_name] -
 * @property {string} reason -
 */
/**
 * @typedef FinicityConnectEvent
 * @property {Record<string, any>} [params] - Additional information
 * @property {string} [screen] - Screen the user is on
 * @property {string} type - Route or user event
 */
/**
 * @typedef PageViewed
 * @property {any | null} [name] -
 * @property {any | null} [path] -
 * @property {any | null} [referrer] -
 * @property {any | null} [search] -
 * @property {any | null} [title] -
 * @property {any | null} [url] -
 */
/**
 * @typedef PinnedNotificationDismissed
 * @property {string} type - Pinned notification type
 */
/**
 * @typedef PinnedNotificationOpened
 * @property {string} type - Pinned notification type
 */
/**
 * @typedef PlaidLinkCredentialsSubmitted
 * @property {any | null} [error_code] -
 * @property {any | null} [error_message] -
 * @property {any | null} [error_type] -
 * @property {any | null} [exit_status] -
 * @property {string} [institution_id] -
 * @property {string} [institution_name] -
 * @property {any | null} [institution_search_query] -
 * @property {string} [link_session_id] -
 * @property {any | null} [mfa_type] -
 * @property {string} [request_id] -
 * @property {string} [search] -
 * @property {string} [timestamp] -
 * @property {any | null} [view_name] -
 */
/**
 * @typedef PlaidLinkErrored
 * @property {string} [error_code] -
 * @property {string} [error_message] -
 * @property {string} [error_type] -
 * @property {string} [exit_status] -
 * @property {string} [institution_id] -
 * @property {string} [institution_name] -
 * @property {string} [link_session_id] -
 * @property {string} [request_id] -
 * @property {string} [search] -
 * @property {string} [timestamp] -
 */
/**
 * @typedef PlaidLinkExited
 * @property {string} [error_code] -
 * @property {string} [error_message] -
 * @property {string} [error_type] -
 * @property {string} [exit_status] -
 * @property {string} [institution_id] -
 * @property {string} [institution_name] -
 * @property {any | null} [institution_search_query] -
 * @property {string} [link_session_id] -
 * @property {any | null} [mfa_type] -
 * @property {string} [request_id] -
 * @property {string} [search] -
 * @property {string} [timestamp] -
 * @property {any | null} [view_name] -
 */
/**
 * @typedef PlaidLinkHandedOff
 * @property {any | null} [error_code] -
 * @property {any | null} [error_message] -
 * @property {any | null} [error_type] -
 * @property {any | null} [exit_status] -
 * @property {string} [institution_id] -
 * @property {string} [institution_name] -
 * @property {any | null} [institution_search_query] -
 * @property {string} [link_session_id] -
 * @property {any | null} [mfa_type] -
 * @property {string} [request_id] -
 * @property {string} [search] -
 * @property {string} [timestamp] -
 * @property {any | null} [view_name] -
 */
/**
 * @typedef PlaidLinkInstitutionSelected
 * @property {any | null} [error_code] -
 * @property {any | null} [error_message] -
 * @property {any | null} [error_type] -
 * @property {any | null} [exit_status] -
 * @property {string} [id] -
 * @property {string} [institution_id] -
 * @property {string} [institution_name] -
 * @property {any | null} [institution_search_query] -
 * @property {string} [institution_status] -
 * @property {string} [link_session_id] -
 * @property {any | null} [mfa_type] -
 * @property {string} [name] -
 * @property {string} [request_id] -
 * @property {string} [search] -
 * @property {string} [status] -
 * @property {string} [timestamp] -
 * @property {any | null} [view_name] -
 */
/**
 * @typedef PlaidLinkInstitutionsSearched
 * @property {any | null} [error_code] -
 * @property {any | null} [error_message] -
 * @property {any | null} [error_type] -
 * @property {any | null} [exit_status] -
 * @property {string} [institution_search_query] -
 * @property {string} [link_session_id] -
 * @property {any | null} [mfa_type] -
 * @property {string} [request_id] -
 * @property {string} [search] -
 * @property {string} [timestamp] -
 * @property {any | null} [view_name] -
 */
/**
 * @typedef PlaidLinkMfaSubmitted
 * @property {any | null} [error_code] -
 * @property {any | null} [error_message] -
 * @property {any | null} [error_type] -
 * @property {any | null} [exit_status] -
 * @property {string} [institution_id] -
 * @property {string} [institution_name] -
 * @property {any | null} [institution_search_query] -
 * @property {string} [link_session_id] -
 * @property {string} [mfa_type] -
 * @property {string} [request_id] -
 * @property {string} [search] -
 * @property {string} [timestamp] -
 * @property {any | null} [view_name] -
 */
/**
 * @typedef PlaidLinkOpened
 * @property {any | null} [error_code] -
 * @property {any | null} [error_message] -
 * @property {any | null} [error_type] -
 * @property {any | null} [exit_status] -
 * @property {string} [institution_id] -
 * @property {string} [institution_name] -
 * @property {any | null} [institution_search_query] -
 * @property {string} link_session_id -
 * @property {any | null} [mfa_type] -
 * @property {any | null} [request_id] -
 * @property {string} [search] -
 * @property {string} timestamp -
 * @property {any | null} [view_name] -
 */
/**
 * @typedef PlaidLinkViewTransitioned
 * @property {any | null} [error_code] -
 * @property {any | null} [error_message] -
 * @property {any | null} [error_type] -
 * @property {any | null} [exit_status] -
 * @property {string} [institution_id] -
 * @property {string} [institution_name] -
 * @property {any | null} [institution_search_query] -
 * @property {string} [link_session_id] -
 * @property {string} [mfa_type] -
 * @property {string} [request_id] -
 * @property {string} [search] -
 * @property {string} [timestamp] -
 * @property {string} [view_name] -
 */
/**
 * @typedef ReferralCodeShared
 * @property {string} method -
 */
/**
 * @typedef SearchOpened
 * @property {string} method - The way the user opend the search prompt
 */
/**
 * @typedef SearchResultsReturned
 * @property {any[]} [result] - The list of what type of results were returned
 * @property {string} [searchTerm] - The user search query
 */
/**
 * @typedef SearchResultsSelected
 * @property {any[]} [result] - The list of what type of results were selected
 * @property {string} [searchTerm] - The user search query
 */
/**
 * @typedef StatementUploadErrored
 * @property {string} [errorCode] -
 * @property {string} [errorMessage] -
 * @property {string} [filename] -
 * @property {string} [parserName] -
 */
/**
 * @typedef TransactionsDateRangeChanged
 * @property {string} [endDate] -
 * @property {string} [startDate] -
 */
/**
 * @typedef TransactionsDownloadedToCsv
 * @property {Record<string, any>} filtersWithCounts - A key-value object with the used filters and their respective counts.
 * @property {string} origin - Where was this button called from?
 */
/**
 * @typedef TransactionsSearchChanged
 * @property {string} [search] -
 */
/**
 * @typedef UserSignedUpAndVerifiedWeb
 * @property {string} email -
 * @property {string} userId -
 */
/**
 * @typedef ViewOnboardingTourPage
 * @property {number} index - Page number user viewed
 */
/**
 * @typedef ViewedAdviceCategoryPage
 * @property {string} name -
 */
/**
 * @typedef ViewedAdviceItem
 * @property {string} name -
 */
/**
 * @typedef ViewedAdviceTask
 * @property {string} item_name -
 * @property {string} name -
 */
/**
 * @typedef ViewedWalkthroughStep
 * @property {string} identifier - Useful so we can distinct the events by the type of walkthrough.
 * @property {number} [stepIndex] -
 * @property {string} [stepTitle] -
 */
/**
 * @typedef VisualAppearancePreferenceUpdated
 * @property {string} preference - Either 'light', 'dark' or 'system'
 */
/**
 * @typedef WalkthroughOpened
 * @property {string} identifier - Useful so we can distinct the events by the type of walkthrough.
 * @property {number} [totalSteps] -
 */
/**
 * @typedef WalkthroughSkipped
 * @property {number} [currentStepIndex] - Index of the visible step when the user clicked to skip.
 * @property {string} identifier - Useful so we can distinct the events by the type of walkthrough.
 */
/**
 * @typedef WebAppPromoCodeApplied
 * @property {boolean} [auto] -
 * @property {string} [promoCode] -
 */
/**
 * @typedef WebAppQueryParamPromoCodeLoaded
 * @property {string} [promoCode] -
 */

/**
 * Fires a 'All Plans Viewed' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function allPlansViewed(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'All Plans Viewed',
    type: 'object',
  };
  const message = {
    event: 'All Plans Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('All Plans Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'App Review Requested' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function appReviewRequested(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    labels: {},
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'App Review Requested',
    type: 'object',
  };
  const message = {
    event: 'App Review Requested',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('App Review Requested', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'App Review Responded' track call.
 *
 * @param {AppReviewResponded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function appReviewResponded(
  props: AppReviewResponded,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          response: {
            description: '',
            enum: ['No', 'Skip', 'Yes'],
            type: 'string',
          },
        },
        required: ['response'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'App Review Responded',
    type: 'object',
  };
  const message = {
    event: 'App Review Responded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('App Review Responded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * When someone clicks to export a chart as an image.
 *
 * @param {ChartDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chartDownloaded(
  props: ChartDownloaded,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When someone clicks to export a chart as an image.',
    properties: {
      context: {},
      properties: {
        properties: {
          chart: {
            description: 'Type of chart the user is downloading.',
            id: '/properties/properties/properties/chart',
            type: 'string',
          },
          view: {
            description:
              'The type of view this chart is rendered with at the moment of the download (e.g. cash flow Sankey has "category", "group", and "both")',
            id: '/properties/properties/properties/view',
            type: 'string',
          },
        },
        required: ['chart'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Chart Downloaded',
    type: 'object',
  };
  const message = {
    event: 'Chart Downloaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Chart Downloaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Coinbase Connect Opened' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function coinbaseConnectOpened(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Coinbase Connect Opened',
    type: 'object',
  };
  const message = {
    event: 'Coinbase Connect Opened',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Coinbase Connect Opened', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User selected an institution in the add account flow (regardless of data provider)
 *
 * @param {ConnectInstitutionSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function connectInstitutionSelected(
  props: ConnectInstitutionSelected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User selected an institution in the add account flow (regardless of data provider)',
    properties: {
      context: {},
      properties: {
        properties: {
          data_provider: {
            description: '',
            type: 'string',
          },
          institution_id: {
            description: '',
            type: 'string',
          },
          institution_name: {
            description: '',
            type: 'string',
          },
        },
        required: ['data_provider', 'institution_id'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Connect Institution Selected',
    type: 'object',
  };
  const message = {
    event: 'Connect Institution Selected',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Connect Institution Selected', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * When user requests to refresh all credentials
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function credentialRefreshAllRequested(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When user requests to refresh all credentials',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Credential Refresh All Requested',
    type: 'object',
  };
  const message = {
    event: 'Credential Refresh All Requested',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Credential Refresh All Requested',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user had an error surfaced to them in the UI
 *
 * @param {ErrorSurfaced} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function errorSurfaced(
  props: ErrorSurfaced,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user had an error surfaced to them in the UI',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          error_string: {
            description: 'String of the error message',
            type: 'string',
          },
          from_route: {
            description: 'The route the error occurred on',
            type: 'string',
          },
        },
        required: ['error_string', 'from_route'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Error Surfaced',
    type: 'object',
  };
  const message = {
    event: 'Error Surfaced',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Error Surfaced', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * When a user applies filters from our filter menu.
 *
 * @param {FiltersApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function filtersApplied(
  props: FiltersApplied,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user applies filters from our filter menu.',
    properties: {
      context: {},
      properties: {
        properties: {
          page: {
            description: '',
            id: '/properties/properties/properties/page',
            pattern: '',
            type: 'string',
          },
          sections: {
            description: '',
            id: '/properties/properties/properties/sections',
            type: 'object',
          },
        },
        required: ['page', 'sections'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Filters Applied',
    type: 'object',
  };
  const message = {
    event: 'Filters Applied',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Filters Applied', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User completed the Connect flow.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function finicityConnectComplete(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User completed the Connect flow.',
    labels: {},
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Finicity Connect Complete',
    type: 'object',
  };
  const message = {
    event: 'Finicity Connect Complete',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Finicity Connect Complete', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * An error occurred in the Connect flow
 *
 * @param {FinicityConnectErrored} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function finicityConnectErrored(
  props: FinicityConnectErrored,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'An error occurred in the Connect flow',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          code: {
            description: '',
            id: '/properties/properties/properties/code',
            type: 'integer',
          },
          institution_id: {
            description: '',
            id: '/properties/properties/properties/institution_id',
            type: 'string',
          },
          institution_name: {
            description: '',
            id: '/properties/properties/properties/institution_name',
            type: 'string',
          },
          reason: {
            description: '',
            id: '/properties/properties/properties/reason',
            type: 'string',
          },
        },
        required: ['code', 'reason'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Finicity Connect Errored',
    type: 'object',
  };
  const message = {
    event: 'Finicity Connect Errored',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Finicity Connect Errored', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * The user did something in Finicity Connect.
 *
 * @param {FinicityConnectEvent} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function finicityConnectEvent(
  props: FinicityConnectEvent,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user did something in Finicity Connect.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          params: {
            description: 'Additional information',
            type: 'object',
          },
          screen: {
            description: 'Screen the user is on',
            type: 'string',
          },
          type: {
            description: 'Route or user event',
            pattern: '',
            type: 'string',
          },
        },
        required: ['type'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Finicity Connect Event',
    type: 'object',
  };
  const message = {
    event: 'Finicity Connect Event',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Finicity Connect Event', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * The user has opened Finicity Connect.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function finicityConnectOpened(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user has opened Finicity Connect.',
    labels: {},
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Finicity Connect Opened',
    type: 'object',
  };
  const message = {
    event: 'Finicity Connect Opened',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Finicity Connect Opened', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Investment Account Menu Interacted' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function investmentAccountMenuInteracted(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Investment Account Menu Interacted',
    type: 'object',
  };
  const message = {
    event: 'Investment Account Menu Interacted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Investment Account Menu Interacted',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * Fires a 'Page Viewed' track call.
 *
 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
  props?: PageViewed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          name: {},
          path: {},
          referrer: {},
          search: {},
          title: {},
          url: {},
        },
        type: 'object',
      },
      traits: {},
    },
    title: 'Page Viewed',
    type: 'object',
  };
  const message = {
    event: 'Page Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Page Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Pinned Notification Dismissed' track call.
 *
 * @param {PinnedNotificationDismissed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pinnedNotificationDismissed(
  props: PinnedNotificationDismissed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          type: {
            description: 'Pinned notification type',
            id: '/properties/properties/properties/type',
            type: 'string',
          },
        },
        required: ['type'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Pinned Notification Dismissed',
    type: 'object',
  };
  const message = {
    event: 'Pinned Notification Dismissed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Pinned Notification Dismissed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Pinned Notification Opened' track call.
 *
 * @param {PinnedNotificationOpened} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pinnedNotificationOpened(
  props: PinnedNotificationOpened,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          type: {
            description: 'Pinned notification type',
            id: '/properties/properties/properties/type',
            type: 'string',
          },
        },
        required: ['type'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Pinned Notification Opened',
    type: 'object',
  };
  const message = {
    event: 'Pinned Notification Opened',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Pinned Notification Opened', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * The user has submitted credentials.
 *
 * @param {PlaidLinkCredentialsSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function plaidLinkCredentialsSubmitted(
  props?: PlaidLinkCredentialsSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user has submitted credentials.',
    properties: {
      context: {},
      properties: {
        properties: {
          error_code: {},
          error_message: {},
          error_type: {},
          exit_status: {},
          institution_id: {
            type: ['string'],
          },
          institution_name: {
            type: ['string'],
          },
          institution_search_query: {},
          link_session_id: {
            type: ['string'],
          },
          mfa_type: {},
          request_id: {
            type: ['string'],
          },
          search: {
            type: ['string'],
          },
          timestamp: {
            type: ['string'],
          },
          view_name: {},
        },
        type: 'object',
      },
      traits: {},
    },
    title: 'Plaid Link Credentials Submitted',
    type: 'object',
  };
  const message = {
    event: 'Plaid Link Credentials Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Plaid Link Credentials Submitted',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * A recoverable error occurred in the Link flow, see the error_code metadata.
 *
 * @param {PlaidLinkErrored} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function plaidLinkErrored(
  props?: PlaidLinkErrored,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A recoverable error occurred in the Link flow, see the error_code metadata.',
    properties: {
      context: {},
      properties: {
        properties: {
          error_code: {
            type: ['string'],
          },
          error_message: {
            type: ['string'],
          },
          error_type: {
            type: ['string'],
          },
          exit_status: {
            type: ['string'],
          },
          institution_id: {
            type: ['string'],
          },
          institution_name: {
            type: ['string'],
          },
          link_session_id: {
            type: ['string'],
          },
          request_id: {
            type: ['string'],
          },
          search: {
            type: ['string'],
          },
          timestamp: {
            type: ['string'],
          },
        },
        type: 'object',
      },
      traits: {},
    },
    title: 'Plaid Link Errored',
    type: 'object',
  };
  const message = {
    event: 'Plaid Link Errored',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Plaid Link Errored', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * The user has exited without completing the Link flow and the onExit callback is fired.
 *
 * @param {PlaidLinkExited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function plaidLinkExited(
  props?: PlaidLinkExited,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'The user has exited without completing the Link flow and the onExit callback is fired.',
    properties: {
      context: {},
      properties: {
        properties: {
          error_code: {
            type: ['string'],
          },
          error_message: {
            type: ['string'],
          },
          error_type: {
            type: ['string'],
          },
          exit_status: {
            type: ['string'],
          },
          institution_id: {
            type: ['string'],
          },
          institution_name: {
            type: ['string'],
          },
          institution_search_query: {},
          link_session_id: {
            type: ['string'],
          },
          mfa_type: {},
          request_id: {
            type: ['string'],
          },
          search: {
            type: ['string'],
          },
          timestamp: {
            type: ['string'],
          },
          view_name: {},
        },
        type: 'object',
      },
      traits: {},
    },
    title: 'Plaid Link Exited',
    type: 'object',
  };
  const message = {
    event: 'Plaid Link Exited',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Plaid Link Exited', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * The user has completed the Link flow and the onSuccess callback is fired.
 *
 * @param {PlaidLinkHandedOff} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function plaidLinkHandedOff(
  props?: PlaidLinkHandedOff,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user has completed the Link flow and the onSuccess callback is fired.',
    properties: {
      context: {},
      properties: {
        properties: {
          error_code: {},
          error_message: {},
          error_type: {},
          exit_status: {},
          institution_id: {
            type: ['string'],
          },
          institution_name: {
            type: ['string'],
          },
          institution_search_query: {},
          link_session_id: {
            type: ['string'],
          },
          mfa_type: {},
          request_id: {
            type: ['string'],
          },
          search: {
            type: ['string'],
          },
          timestamp: {
            type: ['string'],
          },
          view_name: {},
        },
        type: 'object',
      },
      traits: {},
    },
    title: 'Plaid Link Handed Off',
    type: 'object',
  };
  const message = {
    event: 'Plaid Link Handed Off',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Plaid Link Handed Off', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * The user selected an institution.
 *
 * @param {PlaidLinkInstitutionSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function plaidLinkInstitutionSelected(
  props?: PlaidLinkInstitutionSelected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user selected an institution.',
    properties: {
      context: {},
      properties: {
        properties: {
          error_code: {},
          error_message: {},
          error_type: {},
          exit_status: {},
          id: {
            type: ['string'],
          },
          institution_id: {
            type: ['string'],
          },
          institution_name: {
            type: ['string'],
          },
          institution_search_query: {},
          institution_status: {
            type: ['string'],
          },
          link_session_id: {
            type: ['string'],
          },
          mfa_type: {},
          name: {
            type: ['string'],
          },
          request_id: {
            type: ['string'],
          },
          search: {
            type: ['string'],
          },
          status: {
            type: ['string'],
          },
          timestamp: {
            type: ['string'],
          },
          view_name: {},
        },
        type: 'object',
      },
      traits: {},
    },
    title: 'Plaid Link Institution Selected',
    type: 'object',
  };
  const message = {
    event: 'Plaid Link Institution Selected',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Plaid Link Institution Selected',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user has searched for an institution.
 *
 * @param {PlaidLinkInstitutionsSearched} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function plaidLinkInstitutionsSearched(
  props?: PlaidLinkInstitutionsSearched,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user has searched for an institution.',
    properties: {
      context: {},
      properties: {
        properties: {
          error_code: {},
          error_message: {},
          error_type: {},
          exit_status: {},
          institution_search_query: {
            type: ['string'],
          },
          link_session_id: {
            type: ['string'],
          },
          mfa_type: {},
          request_id: {
            type: ['string'],
          },
          search: {
            type: ['string'],
          },
          timestamp: {
            type: ['string'],
          },
          view_name: {},
        },
        type: 'object',
      },
      traits: {},
    },
    title: 'Plaid Link Institutions Searched',
    type: 'object',
  };
  const message = {
    event: 'Plaid Link Institutions Searched',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Plaid Link Institutions Searched',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user has submitted MFA.
 *
 * @param {PlaidLinkMfaSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function plaidLinkMfaSubmitted(
  props?: PlaidLinkMfaSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user has submitted MFA.',
    properties: {
      context: {},
      properties: {
        properties: {
          error_code: {},
          error_message: {},
          error_type: {},
          exit_status: {},
          institution_id: {
            type: ['string'],
          },
          institution_name: {
            type: ['string'],
          },
          institution_search_query: {},
          link_session_id: {
            type: ['string'],
          },
          mfa_type: {
            type: ['string'],
          },
          request_id: {
            type: ['string'],
          },
          search: {
            type: ['string'],
          },
          timestamp: {
            type: ['string'],
          },
          view_name: {},
        },
        type: 'object',
      },
      traits: {},
    },
    title: 'Plaid Link MFA Submitted',
    type: 'object',
  };
  const message = {
    event: 'Plaid Link MFA Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Plaid Link MFA Submitted', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * The user has opened Link.
 *
 * @param {PlaidLinkOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function plaidLinkOpened(
  props?: PlaidLinkOpened,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user has opened Link.',
    properties: {
      context: {},
      properties: {
        properties: {
          error_code: {},
          error_message: {},
          error_type: {},
          exit_status: {},
          institution_id: {
            type: ['string'],
          },
          institution_name: {
            type: ['string'],
          },
          institution_search_query: {},
          link_session_id: {
            type: ['string'],
          },
          mfa_type: {},
          request_id: {},
          search: {
            type: ['string'],
          },
          timestamp: {
            type: ['string'],
          },
          view_name: {},
        },
        required: ['timestamp', 'link_session_id'],
        type: 'object',
      },
      traits: {},
    },
    title: 'Plaid Link Opened',
    type: 'object',
  };
  const message = {
    event: 'Plaid Link Opened',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Plaid Link Opened', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * The user has moved from one view to the next
 *
 * @param {PlaidLinkViewTransitioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function plaidLinkViewTransitioned(
  props?: PlaidLinkViewTransitioned,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user has moved from one view to the next',
    properties: {
      context: {},
      properties: {
        properties: {
          error_code: {},
          error_message: {},
          error_type: {},
          exit_status: {},
          institution_id: {
            type: ['string'],
          },
          institution_name: {
            type: ['string'],
          },
          institution_search_query: {},
          link_session_id: {
            type: ['string'],
          },
          mfa_type: {
            type: ['string'],
          },
          request_id: {
            type: ['string'],
          },
          search: {
            type: ['string'],
          },
          timestamp: {
            type: ['string'],
          },
          view_name: {
            type: ['string'],
          },
        },
        type: 'object',
      },
      traits: {},
    },
    title: 'Plaid Link View Transitioned',
    type: 'object',
  };
  const message = {
    event: 'Plaid Link View Transitioned',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Plaid Link View Transitioned', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Purchase Upsell Annual Button Clicked' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function purchaseUpsellAnnualButtonClicked(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Purchase Upsell Annual Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Purchase Upsell Annual Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Purchase Upsell Annual Button Clicked',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * Fires a 'Referral Code Shared' track call.
 *
 * @param {ReferralCodeShared} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralCodeShared(
  props: ReferralCodeShared,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          method: {
            description: '',
            type: 'string',
          },
        },
        required: ['method'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Referral Code Shared',
    type: 'object',
  };
  const message = {
    event: 'Referral Code Shared',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Referral Code Shared', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Search prompt opened
 *
 * @param {SearchOpened} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchOpened(
  props: SearchOpened,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Search prompt opened',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          method: {
            description: 'The way the user opend the search prompt',
            id: '/properties/properties/properties/method',
            type: 'string',
          },
        },
        required: ['method'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Search Opened',
    type: 'object',
  };
  const message = {
    event: 'Search Opened',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Search Opened', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Search prompt results
 *
 * @param {SearchResultsReturned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchResultsReturned(
  props?: SearchResultsReturned,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Search prompt results',
    properties: {
      context: {},
      properties: {
        properties: {
          result: {
            description: 'The list of what type of results were returned',
            id: '/properties/properties/properties/result',
            type: 'array',
          },
          searchTerm: {
            description: 'The user search query',
            id: '/properties/properties/properties/searchTerm',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Search Results Returned',
    type: 'object',
  };
  const message = {
    event: 'Search Results Returned',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Search Results Returned', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Search prompt results that were selected
 *
 * @param {SearchResultsSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchResultsSelected(
  props?: SearchResultsSelected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Search prompt results that were selected',
    properties: {
      context: {},
      properties: {
        properties: {
          result: {
            description: 'The list of what type of results were selected',
            id: '/properties/properties/properties/result',
            type: 'array',
          },
          searchTerm: {
            description: 'The user search query',
            id: '/properties/properties/properties/searchTerm',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Search Results Selected',
    type: 'object',
  };
  const message = {
    event: 'Search Results Selected',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Search Results Selected', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Statement Upload Errored' track call.
 *
 * @param {StatementUploadErrored} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function statementUploadErrored(
  props?: StatementUploadErrored,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          errorCode: {
            description: '',
            type: 'string',
          },
          errorMessage: {
            description: '',
            type: 'string',
          },
          filename: {
            description: '',
            type: 'string',
          },
          parserName: {
            description: '',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Statement Upload Errored',
    type: 'object',
  };
  const message = {
    event: 'Statement Upload Errored',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Statement Upload Errored', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Transactions Date Range Changed' track call.
 *
 * @param {TransactionsDateRangeChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionsDateRangeChanged(
  props?: TransactionsDateRangeChanged,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          endDate: {
            description: '',
            id: '/properties/properties/properties/endDate',
            type: 'string',
          },
          startDate: {
            description: '',
            id: '/properties/properties/properties/startDate',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Transactions Date Range Changed',
    type: 'object',
  };
  const message = {
    event: 'Transactions Date Range Changed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Transactions Date Range Changed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * When a user clicks to download their filtered transactions view to CSV.
 *
 * @param {TransactionsDownloadedToCsv} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionsDownloadedToCsv(
  props: TransactionsDownloadedToCsv,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user clicks to download their filtered transactions view to CSV.',
    properties: {
      context: {},
      properties: {
        properties: {
          filtersWithCounts: {
            description: 'A key-value object with the used filters and their respective counts.',
            id: '/properties/properties/properties/filtersWithCounts',
            type: 'object',
          },
          origin: {
            description: 'Where was this button called from?',
            id: '/properties/properties/properties/origin',
            type: 'string',
          },
        },
        required: ['filtersWithCounts', 'origin'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Transactions Downloaded To Csv',
    type: 'object',
  };
  const message = {
    event: 'Transactions Downloaded To Csv',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Transactions Downloaded To Csv',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * Fires a 'Transactions Search Changed' track call.
 *
 * @param {TransactionsSearchChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionsSearchChanged(
  props?: TransactionsSearchChanged,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          search: {
            description: '',
            id: '/properties/properties/properties/search',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Transactions Search Changed',
    type: 'object',
  };
  const message = {
    event: 'Transactions Search Changed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Transactions Search Changed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'User Signed Up And Verified Web' track call.
 *
 * @param {UserSignedUpAndVerifiedWeb} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userSignedUpAndVerifiedWeb(
  props: UserSignedUpAndVerifiedWeb,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          email: {
            description: '',
            type: 'string',
          },
          userId: {
            description: '',
            type: 'string',
          },
        },
        required: ['email', 'userId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'User Signed Up And Verified Web',
    type: 'object',
  };
  const message = {
    event: 'User Signed Up And Verified Web',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'User Signed Up And Verified Web',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * Interacted with onboarding tour
 *
 * @param {ViewOnboardingTourPage} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewOnboardingTourPage(
  props: ViewOnboardingTourPage,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Interacted with onboarding tour',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          index: {
            description: 'Page number user viewed',
            type: 'number',
          },
        },
        required: ['index'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'View Onboarding Tour Page',
    type: 'object',
  };
  const message = {
    event: 'View Onboarding Tour Page',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('View Onboarding Tour Page', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Viewed Advice Category Page' track call.
 *
 * @param {ViewedAdviceCategoryPage} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedAdviceCategoryPage(
  props: ViewedAdviceCategoryPage,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          name: {
            description: '',
            type: 'string',
          },
        },
        required: ['name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Viewed Advice Category Page',
    type: 'object',
  };
  const message = {
    event: 'Viewed Advice Category Page',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Viewed Advice Category Page', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Advice item details viewed
 *
 * @param {ViewedAdviceItem} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedAdviceItem(
  props: ViewedAdviceItem,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Advice item details viewed',
    properties: {
      context: {},
      properties: {
        properties: {
          name: {
            description: '',
            type: 'string',
          },
        },
        required: ['name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Viewed Advice Item',
    type: 'object',
  };
  const message = {
    event: 'Viewed Advice Item',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Viewed Advice Item', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Viewed advice task details
 *
 * @param {ViewedAdviceTask} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedAdviceTask(
  props: ViewedAdviceTask,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Viewed advice task details',
    properties: {
      context: {},
      properties: {
        properties: {
          item_name: {
            description: '',
            type: 'string',
          },
          name: {
            description: '',
            type: 'string',
          },
        },
        required: ['name', 'item_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Viewed Advice Task',
    type: 'object',
  };
  const message = {
    event: 'Viewed Advice Task',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Viewed Advice Task', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Viewed Investment Allocation Page' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedInvestmentAllocationPage(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Viewed Investment Allocation Page',
    type: 'object',
  };
  const message = {
    event: 'Viewed Investment Allocation Page',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Viewed Investment Allocation Page',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * Fires a 'Viewed Investment Performance Page' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedInvestmentPerformancePage(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Viewed Investment Performance Page',
    type: 'object',
  };
  const message = {
    event: 'Viewed Investment Performance Page',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Viewed Investment Performance Page',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * The user had our "not optimized for your mobile browser" warning displayed.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedMobileBrowserWarning(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The user had our "not optimized for your mobile browser" warning displayed.',
    labels: {},
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Viewed Mobile Browser Warning',
    type: 'object',
  };
  const message = {
    event: 'Viewed Mobile Browser Warning',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Viewed Mobile Browser Warning', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Viewed Walkthrough Step' track call.
 *
 * @param {ViewedWalkthroughStep} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedWalkthroughStep(
  props: ViewedWalkthroughStep,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          identifier: {
            description: 'Useful so we can distinct the events by the type of walkthrough.',
            id: '/properties/properties/properties/identifier',
            type: 'string',
          },
          stepIndex: {
            description: '',
            id: '/properties/properties/properties/stepIndex',
            type: 'integer',
          },
          stepTitle: {
            description: '',
            id: '/properties/properties/properties/stepTitle',
            type: 'string',
          },
        },
        required: ['identifier'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Viewed Walkthrough Step',
    type: 'object',
  };
  const message = {
    event: 'Viewed Walkthrough Step',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Viewed Walkthrough Step', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User changed their preference for dark mode
 *
 * @param {VisualAppearancePreferenceUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function visualAppearancePreferenceUpdated(
  props: VisualAppearancePreferenceUpdated,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User changed their preference for dark mode',
    properties: {
      context: {},
      properties: {
        properties: {
          preference: {
            description: "Either 'light', 'dark' or 'system'",
            type: 'string',
          },
        },
        required: ['preference'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Visual Appearance Preference Updated',
    type: 'object',
  };
  const message = {
    event: 'Visual Appearance Preference Updated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Visual Appearance Preference Updated',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * Fires a 'Walkthrough Opened' track call.
 *
 * @param {WalkthroughOpened} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walkthroughOpened(
  props: WalkthroughOpened,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          identifier: {
            description: 'Useful so we can distinct the events by the type of walkthrough.',
            id: '/properties/properties/properties/identifier',
            type: 'string',
          },
          totalSteps: {
            description: '',
            id: '/properties/properties/properties/totalSteps',
            type: 'integer',
          },
        },
        required: ['identifier'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Walkthrough Opened',
    type: 'object',
  };
  const message = {
    event: 'Walkthrough Opened',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Walkthrough Opened', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Walkthrough Skipped' track call.
 *
 * @param {WalkthroughSkipped} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walkthroughSkipped(
  props: WalkthroughSkipped,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          currentStepIndex: {
            description: 'Index of the visible step when the user clicked to skip.',
            id: '/properties/properties/properties/currentStepIndex',
            type: 'integer',
          },
          identifier: {
            description: 'Useful so we can distinct the events by the type of walkthrough.',
            id: '/properties/properties/properties/identifier',
            type: 'string',
          },
        },
        required: ['identifier'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Walkthrough Skipped',
    type: 'object',
  };
  const message = {
    event: 'Walkthrough Skipped',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Walkthrough Skipped', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User loaded the web app
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function webAppLoaded(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User loaded the web app',
    labels: {},
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Web App Loaded',
    type: 'object',
  };
  const message = {
    event: 'Web App Loaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Web App Loaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Web App Promo Code Applied' track call.
 *
 * @param {WebAppPromoCodeApplied} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function webAppPromoCodeApplied(
  props?: WebAppPromoCodeApplied,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          auto: {
            description: '',
            id: '/properties/properties/properties/auto',
            type: 'boolean',
          },
          promoCode: {
            description: '',
            id: '/properties/properties/properties/promoCode',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Web App Promo Code Applied',
    type: 'object',
  };
  const message = {
    event: 'Web App Promo Code Applied',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Web App Promo Code Applied', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Web App Query Param Promo Code Loaded' track call.
 *
 * @param {WebAppQueryParamPromoCodeLoaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function webAppQueryParamPromoCodeLoaded(
  props?: WebAppQueryParamPromoCodeLoaded,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          promoCode: {
            description: '',
            id: '/properties/properties/properties/promoCode',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Web App Query Param Promo Code Loaded',
    type: 'object',
  };
  const message = {
    event: 'Web App Query Param Promo Code Loaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Web App Query Param Promo Code Loaded',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * Fires a 'Zillow Connect Errored' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function zillowConnectErrored(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Zillow Connect Errored',
    type: 'object',
  };
  const message = {
    event: 'Zillow Connect Errored',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Zillow Connect Errored', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Zillow Connect Open' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function zillowConnectOpen(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Zillow Connect Open',
    type: 'object',
  };
  const message = {
    event: 'Zillow Connect Open',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Zillow Connect Open', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Zillow Connect Result Selected' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function zillowConnectResultSelected(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Zillow Connect Result Selected',
    type: 'object',
  };
  const message = {
    event: 'Zillow Connect Result Selected',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Zillow Connect Result Selected',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * Fires a 'Zillow Connect Search' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function zillowConnectSearch(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Zillow Connect Search',
    type: 'object',
  };
  const message = {
    event: 'Zillow Connect Search',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Zillow Connect Search', props || {}, withTypewriterContext(options), callback);
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,
  /**
   * Fires a 'All Plans Viewed' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  allPlansViewed,
  /**
   * Fires a 'App Review Requested' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  appReviewRequested,
  /**
   * Fires a 'App Review Responded' track call.
   *
   * @param {AppReviewResponded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  appReviewResponded,
  /**
   * When someone clicks to export a chart as an image.
   *
   * @param {ChartDownloaded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  chartDownloaded,
  /**
   * Fires a 'Coinbase Connect Opened' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  coinbaseConnectOpened,
  /**
   * User selected an institution in the add account flow (regardless of data provider)
   *
   * @param {ConnectInstitutionSelected} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  connectInstitutionSelected,
  /**
   * When user requests to refresh all credentials
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  credentialRefreshAllRequested,
  /**
   * The user had an error surfaced to them in the UI
   *
   * @param {ErrorSurfaced} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  errorSurfaced,
  /**
   * When a user applies filters from our filter menu.
   *
   * @param {FiltersApplied} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  filtersApplied,
  /**
   * User completed the Connect flow.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  finicityConnectComplete,
  /**
   * An error occurred in the Connect flow
   *
   * @param {FinicityConnectErrored} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  finicityConnectErrored,
  /**
   * The user did something in Finicity Connect.
   *
   * @param {FinicityConnectEvent} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  finicityConnectEvent,
  /**
   * The user has opened Finicity Connect.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  finicityConnectOpened,
  /**
   * Fires a 'Investment Account Menu Interacted' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  investmentAccountMenuInteracted,
  /**
   * Fires a 'Page Viewed' track call.
   *
   * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pageViewed,
  /**
   * Fires a 'Pinned Notification Dismissed' track call.
   *
   * @param {PinnedNotificationDismissed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pinnedNotificationDismissed,
  /**
   * Fires a 'Pinned Notification Opened' track call.
   *
   * @param {PinnedNotificationOpened} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pinnedNotificationOpened,
  /**
   * The user has submitted credentials.
   *
   * @param {PlaidLinkCredentialsSubmitted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  plaidLinkCredentialsSubmitted,
  /**
   * A recoverable error occurred in the Link flow, see the error_code metadata.
   *
   * @param {PlaidLinkErrored} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  plaidLinkErrored,
  /**
   * The user has exited without completing the Link flow and the onExit callback is fired.
   *
   * @param {PlaidLinkExited} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  plaidLinkExited,
  /**
   * The user has completed the Link flow and the onSuccess callback is fired.
   *
   * @param {PlaidLinkHandedOff} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  plaidLinkHandedOff,
  /**
   * The user selected an institution.
   *
   * @param {PlaidLinkInstitutionSelected} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  plaidLinkInstitutionSelected,
  /**
   * The user has searched for an institution.
   *
   * @param {PlaidLinkInstitutionsSearched} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  plaidLinkInstitutionsSearched,
  /**
   * The user has submitted MFA.
   *
   * @param {PlaidLinkMfaSubmitted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  plaidLinkMfaSubmitted,
  /**
   * The user has opened Link.
   *
   * @param {PlaidLinkOpened} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  plaidLinkOpened,
  /**
   * The user has moved from one view to the next
   *
   * @param {PlaidLinkViewTransitioned} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  plaidLinkViewTransitioned,
  /**
   * Fires a 'Purchase Upsell Annual Button Clicked' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  purchaseUpsellAnnualButtonClicked,
  /**
   * Fires a 'Referral Code Shared' track call.
   *
   * @param {ReferralCodeShared} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  referralCodeShared,
  /**
   * Search prompt opened
   *
   * @param {SearchOpened} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  searchOpened,
  /**
   * Search prompt results
   *
   * @param {SearchResultsReturned} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  searchResultsReturned,
  /**
   * Search prompt results that were selected
   *
   * @param {SearchResultsSelected} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  searchResultsSelected,
  /**
   * Fires a 'Statement Upload Errored' track call.
   *
   * @param {StatementUploadErrored} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  statementUploadErrored,
  /**
   * Fires a 'Transactions Date Range Changed' track call.
   *
   * @param {TransactionsDateRangeChanged} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  transactionsDateRangeChanged,
  /**
   * When a user clicks to download their filtered transactions view to CSV.
   *
   * @param {TransactionsDownloadedToCsv} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  transactionsDownloadedToCsv,
  /**
   * Fires a 'Transactions Search Changed' track call.
   *
   * @param {TransactionsSearchChanged} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  transactionsSearchChanged,
  /**
   * Fires a 'User Signed Up And Verified Web' track call.
   *
   * @param {UserSignedUpAndVerifiedWeb} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userSignedUpAndVerifiedWeb,
  /**
   * Interacted with onboarding tour
   *
   * @param {ViewOnboardingTourPage} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  viewOnboardingTourPage,
  /**
   * Fires a 'Viewed Advice Category Page' track call.
   *
   * @param {ViewedAdviceCategoryPage} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  viewedAdviceCategoryPage,
  /**
   * Advice item details viewed
   *
   * @param {ViewedAdviceItem} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  viewedAdviceItem,
  /**
   * Viewed advice task details
   *
   * @param {ViewedAdviceTask} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  viewedAdviceTask,
  /**
   * Fires a 'Viewed Investment Allocation Page' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  viewedInvestmentAllocationPage,
  /**
   * Fires a 'Viewed Investment Performance Page' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  viewedInvestmentPerformancePage,
  /**
   * The user had our "not optimized for your mobile browser" warning displayed.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  viewedMobileBrowserWarning,
  /**
   * Fires a 'Viewed Walkthrough Step' track call.
   *
   * @param {ViewedWalkthroughStep} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  viewedWalkthroughStep,
  /**
   * User changed their preference for dark mode
   *
   * @param {VisualAppearancePreferenceUpdated} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  visualAppearancePreferenceUpdated,
  /**
   * Fires a 'Walkthrough Opened' track call.
   *
   * @param {WalkthroughOpened} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  walkthroughOpened,
  /**
   * Fires a 'Walkthrough Skipped' track call.
   *
   * @param {WalkthroughSkipped} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  walkthroughSkipped,
  /**
   * User loaded the web app
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  webAppLoaded,
  /**
   * Fires a 'Web App Promo Code Applied' track call.
   *
   * @param {WebAppPromoCodeApplied} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  webAppPromoCodeApplied,
  /**
   * Fires a 'Web App Query Param Promo Code Loaded' track call.
   *
   * @param {WebAppQueryParamPromoCodeLoaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  webAppQueryParamPromoCodeLoaded,
  /**
   * Fires a 'Zillow Connect Errored' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  zillowConnectErrored,
  /**
   * Fires a 'Zillow Connect Open' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  zillowConnectOpen,
  /**
   * Fires a 'Zillow Connect Result Selected' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  zillowConnectResultSelected,
  /**
   * Fires a 'Zillow Connect Search' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  zillowConnectSearch,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/monarch/protocols/tracking-plans/rs_1bHZf0fVpnvlXN3CIHTeLZvPq5A`);
      const a = analytics();
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext(),
        );
      }
    };
  },
});
