import styled, { css } from 'styled-components';

import { Button as SubmitButton } from 'components/lib/form/FormSubmitButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import DangerButton from 'components/lib/ui/button/DangerButton';

import { spacing } from 'common/lib/theme/dynamic';

type Size = 'default' | 'small';

const sizeVariants: Record<Size, ReturnType<typeof css>> = {
  default: css`
    padding: ${spacing.default} ${spacing.xlarge};
  `,
  small: css`
    padding: ${spacing.small} ${spacing.default};
  `,
};

const CardFooter = styled(FlexContainer).attrs({ alignCenter: true, justifyEnd: true })<{
  $size?: Size;
  $hideBorder?: boolean;
}>`
  ${({ $size = 'default' }) => sizeVariants[$size]};
  border-top: ${({ $hideBorder, theme }) =>
    $hideBorder ? 'none' : `1px solid ${theme.color.grayBackground}`};

  ${SubmitButton} {
    margin-top: 0;
    width: auto;
  }

  *:not(:last-child) {
    margin-right: ${spacing.small};
  }

  /** Singles out danger button to the left if its the first one */
  ${DangerButton} {
    :first-child {
      margin-right: auto;
    }
  }
`;

export default CardFooter;
