import { AnimatePresence } from 'framer-motion';
import { isNotNil } from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import EditCategoryModal from 'components/categories/EditCategoryModal';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Modal from 'components/lib/ui/Modal';
import Text from 'components/lib/ui/Text';
import { TooltipV2 } from 'components/lib/ui/TooltipV2';
import type { GroupDataWithBudgetedValue } from 'components/plan/onboarding/OnboardingBudgetCard';
import { OnboardingCardRowAnimatedWrapper } from 'components/plan/onboarding/OnboardingBudgetCardRow';
import { CardWithHeavyShadow } from 'components/plan/onboarding/OnboardingCard';
import OnboardingNonMonthlyCardRow, {
  NonMonthlyCardRow,
} from 'components/plan/onboarding/OnboardingNonMonthlyCardRow';

import { filterVisibleRows } from 'lib/budget/onboardingAdapters';
import { useCategoryActions } from 'lib/hooks/category/useCategoryActions';

import { BUDGET } from 'common/constants/copy';

const CARD_MIN_WIDTH_PX = 820;
const HEADER_HEIGHT_PX = 48;

type HeaderAlignment = 'left' | 'right';

const Root = styled(CardWithHeavyShadow)`
  min-width: ${CARD_MIN_WIDTH_PX}px;
`;

const InfoIcon = styled(Icon).attrs({ name: 'info', size: 12 })`
  cursor: default;
`;

const HeaderRow = styled(NonMonthlyCardRow).attrs({ $withTopBorder: false })`
  height: ${HEADER_HEIGHT_PX}px;
`;

const HeaderContainer = styled(FlexContainer).attrs({
  gap: 'xsmall',
  alignCenter: true,
})<{ $align: HeaderAlignment }>`
  justify-content: ${({ $align }) => ($align === 'left' ? 'flex-start' : 'flex-end')};
`;

type Props = {
  data: GroupDataWithBudgetedValue;
};

const OnboardingNonMonthlyCard = ({ data }: Props) => {
  const {
    editingCategoryId,
    setEditingCategoryId,
    deleteCategoryWithConfirmation,
    confirmingDeleteCategoryId,
    updateHideFromBudget,
    DeleteCategoryConfirmationModal,
  } = useCategoryActions();

  const visibleRows = filterVisibleRows(data.rows);

  return (
    <>
      <Root>
        <HeaderRow>
          <Header title="Category" />
          <Header
            title="Expense Frequency"
            tooltip={BUDGET.ONBOARDING.NON_MONTHLY_EXPENSES.TOOLTIP_FREQUENCY}
          />
          <Header
            title="Target Amount"
            tooltip={BUDGET.ONBOARDING.NON_MONTHLY_EXPENSES.TOOLTIP_TARGET_AMOUNT}
            align="right"
          />
          <Header title="Monthly Budget" align="right" />
        </HeaderRow>

        <AnimatePresence initial={false}>
          {visibleRows?.map((row) => (
            <OnboardingCardRowAnimatedWrapper key={row.id}>
              <OnboardingNonMonthlyCardRow
                rowData={row}
                onEditCategory={() => setEditingCategoryId(row.id)}
                onDeleteCategory={() => deleteCategoryWithConfirmation(row.id)}
                onHideCategoryFromBudget={() => updateHideFromBudget(row.id, true)}
              />
            </OnboardingCardRowAnimatedWrapper>
          ))}
        </AnimatePresence>
      </Root>
      {confirmingDeleteCategoryId && <DeleteCategoryConfirmationModal />}
      {!!editingCategoryId && (
        <Modal onClose={() => setEditingCategoryId(undefined)}>
          {({ close }) => <EditCategoryModal categoryId={editingCategoryId} onDone={close} />}
        </Modal>
      )}
    </>
  );
};

const Header = ({
  title,
  tooltip,
  align = 'left',
}: {
  title: string;
  tooltip?: React.ReactNode;
  align?: HeaderAlignment;
}) => (
  <HeaderContainer $align={align}>
    <Text weight="medium" size="small">
      {title}
    </Text>
    {isNotNil(tooltip) && (
      <TooltipV2 content={tooltip}>
        <span>
          <InfoIcon />
        </span>
      </TooltipV2>
    )}
  </HeaderContainer>
);

export default OnboardingNonMonthlyCard;
