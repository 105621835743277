import * as R from 'ramda';
import { useEffect, useMemo, useRef } from 'react';

import { indexAllocationsByAccountId, sumAccountAllocations } from 'common/lib/goalsV2/adapters';
import useUpdateGoal from 'common/lib/hooks/goalsV2/useUpdateGoal';
import useQuery from 'common/lib/hooks/useQuery';
import useReadFragment from 'common/lib/hooks/useReadFragment';

import { GoalType } from 'common/constants/goals';

import { gql } from 'common/generated/gql';
import type { GoalAccountsMapRowFieldsFragment } from 'common/generated/graphql';

type Props = {
  goalId: string;
};

/** Hook used to handle select goal accounts logic. */
const useSelectGoalAccounts = ({ goalId }: Props) => {
  const goalFromCache = useReadFragment<GoalAccountsMapRowFieldsFragment>({
    id: `GoalV2:${goalId}`,
    fragment: GOAL_ACCOUNTS_MAP_ROW_FRAGMENT,
    fragmentName: 'GoalAccountsMapRowFields',
  });

  const { data, isLoadingInitialData } = useQuery(QUERY, {
    variables: {
      goalId,
    },
  });
  const fetchedGoal = data?.goalV2;
  const goal = fetchedGoal ?? goalFromCache;
  const isDebtGoal = goal?.type === GoalType.Debt;
  const {
    accountAllocations = [],
    eligibleAccounts: accounts = [],
    suggestedAccounts = [],
    legacyGoal,
  } = data?.goalV2 ?? {};

  const allocationsByAccountId = useMemo(
    () => indexAllocationsByAccountId(accountAllocations),
    [accountAllocations],
  );

  // Keep track of how accounts were allocated when user first opened the screen. This
  // determines what section we sort each account into ("Assigned" or "Available") and
  // prevents accounts from jumping between the two as the user makes changes.
  const initialAllocationsByAccountId = useRef<ReturnType<typeof indexAllocationsByAccountId>>();
  if (data && !initialAllocationsByAccountId.current) {
    initialAllocationsByAccountId.current = allocationsByAccountId;
  }

  const sections = useMemo(() => {
    const assignedAccounts = R.filter(
      ({ id }) => !!initialAllocationsByAccountId.current?.[id],
      accounts,
    );

    const availableAccounts = R.filter(
      ({ id }) =>
        !initialAllocationsByAccountId.current?.[id] &&
        !R.find(R.propEq('id', id), suggestedAccounts),
      accounts,
    );

    const nonAssignedSuggestedAccounts = R.filter(
      ({ id }) => !initialAllocationsByAccountId.current?.[id],
      suggestedAccounts,
    );

    const sections = [
      {
        title: 'Assigned accounts',
        accounts: assignedAccounts,
      },
      {
        title: 'Suggested accounts',
        accounts: nonAssignedSuggestedAccounts,
      },
      {
        title: 'Other accounts',
        accounts: availableAccounts,
      },
    ];

    return sections.filter(({ accounts }) => accounts.length > 0);
  }, [accounts]);

  // if the goal had allocations when the screen was first opened.
  const initiallyHadAllocations = useMemo(
    () => !!goalFromCache?.accountAllocations.length,
    [], // this is purposefully empty. only want value on first render
  );

  const { updateGoal } = useUpdateGoal(goal);

  useEffect(() => {
    /* For debt goals, we set the starting amount to the sum of the account allocations,
    only if the user hasn't set allocations yet. If they update allocations at a later time,
    the starting amount will not be updated automatically.
    */
    if (isDebtGoal && !initiallyHadAllocations) {
      const balanceSum = sumAccountAllocations(accountAllocations);
      updateGoal({
        startingAmount: balanceSum,
      });
    }
  }, [accountAllocations]);

  return {
    sections,
    allocationsByAccountId,
    initiallyHadAllocations,
    isLoadingInitialData,
    goal,
    legacyGoal,
    isDebtGoal,
  };
};

export const GOAL_ACCOUNTS_MAP_ROW_FRAGMENT = gql(/* GraphQL */ `
  fragment GoalAccountsMapRowFields on GoalV2 {
    id
    name
    priority
    imageStorageProvider
    imageStorageProviderId
    targetAmount
    startingAmount
    currentAmount
    completionPercent
    type
    defaultName
    accountAllocations {
      id
      account {
        id
        logoUrl
        icon
        dataProvider

        type {
          name
        }

        subtype {
          name
        }

        institution {
          id
          logo
          primaryColor
        }
      }
    }
  }
`);

export const GOAL_ACCOUNT_ALLOCATION_ROW_FIELDS = gql(/* GraphQL */ `
  fragment GoalAccountAllocationRowFields on Account {
    id
    hideFromList
    deactivatedAt
    displayName
    isAsset
    availableBalanceForGoals
    displayBalance
    logoUrl
    icon
    ...NewAccountLogoFields

    goalAllocations {
      id
      amount
      currentAmount
      useEntireAccountBalance
      goal {
        id
        name
      }
    }
  }
`);

export const GOAL_ALLOCATION_FIELDS = gql(/* GraphQL */ `
  fragment AllocationFields on GoalAccountAllocation {
    id
    amount
    currentAmount
    useEntireAccountBalance
    account {
      id
      hideFromList
      deactivatedAt
    }
  }
`);

const QUERY = gql(/* GraphQL */ `
  query GoalSelectAccounts($goalId: ID!) {
    goalV2(id: $goalId) {
      id
      type
      eligibleAccounts {
        id
        subtype {
          name
        }
        ...GoalAccountAllocationRowFields
      }
      suggestedAccounts {
        id
        subtype {
          name
        }
        ...GoalAccountAllocationRowFields
      }
      accountAllocations {
        id
        ...AllocationFields
      }
      legacyGoal {
        id
        contributedBalance
      }
      ...GoalAccountsMapRowFields
    }
  }
`);

export default useSelectGoalAccounts;
