import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import Block from 'components/support/blocks/Block';

import { track } from 'lib/analytics/segment';

import { AnalyticsEventNames } from 'common/constants/analytics';

import type { ResolutionBlocks } from 'common/types/support';

const Root = styled(FlexContainer).attrs({ column: true })`
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  border-radius: ${({ theme }) => theme.radius.small};
  padding: ${({ theme }) => theme.spacing.default};
  gap: ${({ theme }) => theme.spacing.small};
`;

const Title = styled(Text).attrs({ weight: 'medium' })``;

const MoreResourcesContainer = styled(FlexContainer).attrs({ column: true })``;

const MoreResourcesTitle = styled(Text).attrs({
  weight: 'medium',
  size: 'xsmall',
  color: 'grayDark',
})`
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  margin-top: ${({ theme }) => theme.spacing.small};
`;

type Props = {
  blocks: ResolutionBlocks | undefined;
};

const RecommendedResolutionSection = ({ blocks }: Props) => {
  useEffect(() => {
    if (RA.isNotNilOrEmpty(blocks)) {
      track(AnalyticsEventNames.RecommendedResolutionsViewed);
    }
  }, []);

  if (!blocks) {
    return null;
  }

  const { links, ...otherBlocks } = blocks;
  const hasLinks = links.length > 0;
  const nonLinkBlocks = R.flatten(R.values(R.filter(RA.isArrayLike, otherBlocks)));
  // Show "More resources" title above the links only if there are links AND other blocks
  const showMoreResources = hasLinks && R.any(RA.isNotNilOrEmpty, nonLinkBlocks);

  return (
    <Root>
      <Title>Recommended for this issue</Title>
      {nonLinkBlocks.map((block, index) => (
        <Block key={index} data={block} />
      ))}
      {hasLinks && (
        <MoreResourcesContainer>
          {showMoreResources && <MoreResourcesTitle>More resources</MoreResourcesTitle>}
          {links.map((block, index) => (
            <Block key={index} data={block} />
          ))}
        </MoreResourcesContainer>
      )}
    </Root>
  );
};

export default RecommendedResolutionSection;
