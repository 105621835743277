import * as RA from 'ramda-adjunct';
import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

const CIRCLE_SIZE_PX = 48;
const ARROW_SIZE_PX = 24;

const IconCircle = styled(FlexContainer).attrs({ center: true })`
  width: ${CIRCLE_SIZE_PX}px;
  height: ${CIRCLE_SIZE_PX}px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.grayBackground};
  color: ${({ theme }) => theme.color.text};
`;

const ArrowIcon = styled(Icon).attrs({ name: 'ArrowRight' })`
  width: ${ARROW_SIZE_PX}px;
  height: ${ARROW_SIZE_PX}px;
  transition: all 0.1s ease-in-out;
  color: ${({ theme }) => theme.color.gray};
`;

const ArrowContainer = styled(FlexContainer).attrs({ justifyEnd: true })``;

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  gap: ${({ theme }) => theme.spacing.default};
  padding: ${({ theme }) => theme.spacing.xlarge};
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }

  &:hover {
    ${ArrowIcon} {
      transform: translateX(3px);
      color: ${({ theme }) => theme.color.textLight};
    }
  }
`;

const Content = styled(FlexContainer).attrs({ column: true })`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

type Props = {
  title: string;
  subtitle?: string;
  icon?: string;
  onClick: () => void;
};

const CardLink = ({ title, subtitle, icon, onClick }: Props) => (
  <Root onClick={onClick}>
    {RA.isNotNil(icon) && (
      <IconCircle>
        <Icon name={icon} size={CIRCLE_SIZE_PX / 2.5} />
      </IconCircle>
    )}
    <Content>
      <Text weight="medium">{title}</Text>
      {RA.isNotNil(subtitle) && <Text size="small">{subtitle}</Text>}
    </Content>
    <ArrowContainer>
      <ArrowIcon />
    </ArrowContainer>
  </Root>
);

export default CardLink;
