import React from 'react';
import styled from 'styled-components';

import ChartTooltip from 'components/lib/charts/ChartTooltip';
import MultipleLineChart from 'components/lib/charts/MultipleLineChart';

import { formatCurrencyThousandsDecimal, formatCurrency } from 'common/utils/Currency';
import {
  isoDateToMonthAbbreviationAndDayWithToday,
  isoDateToAbbreviatedMonthDayAndYear,
} from 'common/utils/date';
import useMeasure from 'lib/hooks/useMeasure';
import useTheme from 'lib/hooks/useTheme';

const GRAPH_HEIGHT_PX = 180;

const Root = styled.div``;

export type BalanceDataPoint =
  | { date: string; balance: number; previewBalance?: number }
  | { date: string; previewBalance: number; balance?: number };

type Props = {
  data: BalanceDataPoint[];
  isLoading: boolean;
};

const CopyBalancePreviewGraph = ({ data, isLoading }: Props) => {
  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();
  const theme = useTheme();
  const padding = 2 * parseInt(theme.spacing.default, 10);

  return (
    <Root ref={ref}>
      <MultipleLineChart
        data={data}
        xDataKey="date"
        yDataKeys={['previewBalance', 'balance']}
        formatXAxis={isoDateToMonthAbbreviationAndDayWithToday}
        formatYAxis={formatCurrencyThousandsDecimal}
        width={width - padding / 2}
        yAxisProps={{ interval: 1 }}
        height={GRAPH_HEIGHT_PX}
        isLoadingNewData={isLoading}
        lineOptions={[
          { lineColor: theme.color.green, hasArea: true, areaColor: theme.color.green },
          { lineColor: theme.color.gray, hasArea: true, areaColor: theme.color.grayLight },
        ]}
        tooltipComponent={({ payload, active }) => {
          if (!payload) {
            return null;
          }

          const { date, balance, previewBalance } = payload[0]?.payload ?? {};
          return (
            <ChartTooltip header={isoDateToAbbreviatedMonthDayAndYear(date)} active={active}>
              {formatCurrency(previewBalance ?? balance)}
            </ChartTooltip>
          );
        }}
      />
    </Root>
  );
};

export default CopyBalancePreviewGraph;
