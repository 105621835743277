import { useMutation, gql } from '@apollo/client';
import React, { useEffect } from 'react';
import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCancelButton from 'components/lib/ui/ModalCancelButton';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import BarcodeBoxMfa from 'components/settings/security/QRCodeBoxMfa';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import type {
  confirmTotpDevice,
  confirmTotpDeviceVariables,
} from 'common/generated/graphQlTypes/confirmTotpDevice';
import type { createUnconfirmedTotpDevice } from 'common/generated/graphQlTypes/createUnconfirmedTotpDevice';

const StyledModalCard = styled(ModalCard)`
  width: 645px;
`;

const Root = styled(FlexContainer)``;

const Body = styled(Text)<{
  marginBottom?: keyof DefaultTheme['spacing'];
}>`
  margin-bottom: ${({ theme, marginBottom }) => marginBottom && theme.spacing[marginBottom]};
`;

const INFO = 'Scan the QR code below with your two-factor authentication app.';

const SIX_DIGIT_HEADER = 'Enter the six-digit code from the application';

const SIX_DIGIT_INFO =
  'After scanning the QR code above, the app will display a six-digit code. Enter it below to continue.';

type Props = {
  next: () => void;
};

const QRCodeMfaModal = ({ next }: Props) => {
  const [createUnconfirmedTotpDevice, { data, loading }] =
    useMutation<createUnconfirmedTotpDevice>(CREATE_DEVICE_MUTATION);

  const [confirmTotpDevice] = useMutation<confirmTotpDevice, confirmTotpDeviceVariables>(
    CONFIRM_DEVICE_MUTATION,
    {
      onCompleted: (data) => {
        if (data.confirmTotpDevice?.isTokenValid) {
          next();
        }
      },
    },
  );

  useEffect(() => {
    createUnconfirmedTotpDevice();
  }, []);

  const device = data?.createUnconfirmedTotpDevice?.device;

  return (
    <StyledModalCard title="Scan this QR code with your app" description={INFO}>
      <Form initialValues={{ token: '' }} mutation={confirmTotpDevice}>
        <Root column margin="large">
          <BarcodeBoxMfa device={device} loading={loading} />
          <Body marginBottom="small" size="large" weight="medium">
            {SIX_DIGIT_HEADER}
          </Body>
          <Body marginBottom="large">{SIX_DIGIT_INFO}</Body>
          <TextField
            name="token"
            label="Six-digit code from application"
            errorLabel="error"
            required
            autoFocus
            maxLength={6}
            isSensitive
          />
        </Root>
        <CardFooter>
          <ModalCancelButton />
          <FormSubmitButton size="small">Next</FormSubmitButton>
        </CardFooter>
      </Form>
    </StyledModalCard>
  );
};

const CREATE_DEVICE_MUTATION = gql`
  mutation Web_createUnconfirmedTotpDevice {
    createUnconfirmedTotpDevice {
      device {
        configUrl
        secret
      }
    }
  }
`;

const CONFIRM_DEVICE_MUTATION = gql`
  mutation Web_confirmTotpDevice($input: ConfirmTOTPDeviceInput!) {
    confirmTotpDevice(input: $input) {
      isTokenValid
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default QRCodeMfaModal;
