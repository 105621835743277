import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import type {
  MarkGoalIncomplete,
  MarkGoalIncompleteVariables,
} from 'common/generated/graphQlTypes/MarkGoalIncomplete';

const useMarkGoalIncomplete = () => {
  const [performMutation, mutationInfo] = useMutation<
    MarkGoalIncomplete,
    MarkGoalIncompleteVariables
  >(MUTATION);

  const markGoalIncomplete = useCallback(
    (id: string) =>
      performMutation({
        variables: {
          input: {
            id,
          },
        },
        optimisticResponse: {
          markGoalIncomplete: {
            __typename: 'MarkGoalIncomplete',
            goal: {
              __typename: 'GoalV2',
              id,
              completedAt: null,
            },
            errors: null,
          },
        },
      }),
    [performMutation],
  );

  return [markGoalIncomplete, mutationInfo] as const;
};

const MUTATION = gql`
  mutation Common_MarkGoalIncomplete($input: MarkGoalIncompleteInput!) {
    markGoalIncomplete(input: $input) {
      goal {
        id
        completedAt
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default useMarkGoalIncomplete;
