import { gql } from '@apollo/client';
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SearchInput from 'components/globalSearch/SearchInput';
import SearchResult from 'components/globalSearch/SearchResult';
import type { SearchResultType } from 'components/globalSearch/types';
import Card from 'components/lib/ui/Card';
import Modal from 'components/lib/ui/Modal';

import useDebounce from 'common/lib/hooks/useDebounce';
import useQuery from 'common/lib/hooks/useQuery';
import typewriter from 'lib/analytics/typewriter';
import { getRouteForResultType } from 'lib/globalSearch/routes';
import useGlobalSearch from 'lib/hooks/useGlobalSearch';

import { TYPEAHEAD_DEBOUNCE_TIME_MS } from 'common/constants/form';

import type {
  Web_GetGlobalSearch,
  Web_GetGlobalSearchVariables,
  Web_GetGlobalSearch_globalSearch_results,
} from 'common/generated/graphQlTypes/Web_GetGlobalSearch';

const StyledCard = styled(Card)`
  min-height: 72px;
`;

const ResultsWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.xsmall};
`;

const Empty = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.color.textLight};
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xxlarge} 0;
`;

const MINIMUM_SEARCH_QUERY_LENGTH = 3;

const GlobalSearch = () => {
  const { push } = useHistory();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [selectedItemIdx, setSelectedItemIdx] = useState(0);
  const debouncedSearchValue = useDebounce(searchInputValue, TYPEAHEAD_DEBOUNCE_TIME_MS);
  const searchQueryHasMinimumLength = debouncedSearchValue.length >= MINIMUM_SEARCH_QUERY_LENGTH;
  const { setGlobalSearchModalVisible } = useGlobalSearch();

  const close = useCallback(() => {
    setGlobalSearchModalVisible(false);
    setSearchInputValue('');
    setSelectedItemIdx(0);
  }, [setGlobalSearchModalVisible, setSearchInputValue, setSelectedItemIdx]);

  const { data, isNetworkRequestInFlight } = useQuery<
    Web_GetGlobalSearch,
    Web_GetGlobalSearchVariables
  >(GET_GLOBAL_SEARCH, {
    variables: { searchTerm: debouncedSearchValue },
    skip: !debouncedSearchValue || !searchQueryHasMinimumLength,
  });

  useEffect(() => {
    if (data && searchQueryHasMinimumLength) {
      typewriter.searchResultsReturned({
        searchTerm: data.globalSearch?.searchTerm,
        result: data?.globalSearch?.results.map((i) => i.type),
      });
    }
  }, [data]);

  const onSelectResult = useCallback(
    (id: string, type: SearchResultType) => {
      typewriter.searchResultsSelected({ searchTerm: debouncedSearchValue, result: [type] });
      close();
      push(getRouteForResultType(type, id));
    },
    [close, push, debouncedSearchValue],
  );

  const results = data?.globalSearch?.results;

  return (
    <Modal onClose={close}>
      <StyledCard>
        <SearchInput
          results={results}
          onSelectResult={onSelectResult}
          isLoading={isNetworkRequestInFlight}
          searchInputValue={searchInputValue}
          selectedItemIdx={selectedItemIdx}
          setSelectedItemIdx={setSelectedItemIdx}
          setSearchInputValue={setSearchInputValue}
        />
        {searchQueryHasMinimumLength && !!results?.length && (
          <ResultsWrapper>
            {results.map(
              (
                { id, name, logoUrl, icon, type }: Web_GetGlobalSearch_globalSearch_results,
                idx: number,
              ) => (
                <SearchResult
                  selected={idx === selectedItemIdx}
                  key={id}
                  id={id}
                  title={name}
                  logoUrl={logoUrl}
                  icon={icon}
                  type={type as SearchResultType}
                  onSelect={onSelectResult}
                  onPointerMove={() => {
                    setSelectedItemIdx(idx);
                  }}
                />
              ),
            )}
          </ResultsWrapper>
        )}
        {searchQueryHasMinimumLength && !results?.length && !isNetworkRequestInFlight && (
          <Empty>No results found for your search</Empty>
        )}
      </StyledCard>
    </Modal>
  );
};

const GET_GLOBAL_SEARCH = gql`
  query Web_GetGlobalSearch($searchTerm: String!) {
    globalSearch(searchTerm: $searchTerm) {
      searchTerm
      results {
        id
        name
        icon
        logoUrl
        type
      }
    }
  }
`;

export default GlobalSearch;
