import type { CardElementProps } from '@stripe/react-stripe-js';
import { CardElement } from '@stripe/react-stripe-js';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import useTheme from 'lib/hooks/useTheme';
import fieldErrorBorderMixin from 'lib/styles/fieldErrorBorderMixin';
import fieldStyleMixin, { fieldStyleFocus } from 'lib/styles/fieldStyleMixin';

const StripeInput = styled(CardElement)`
  ${fieldStyleMixin};

  &.StripeElement--focus {
    ${({ theme }) => fieldStyleFocus({ theme })};
  }
  &.StripeElement--invalid {
    ${({ theme }) => fieldErrorBorderMixin({ theme, error: true })};
  }
`;

type Props = Omit<CardElementProps, 'options'>;

/**
 * Wrapper around stripe CardElement to provide custom styling
 */
const StripeCardInput = (props: Props) => {
  const theme = useTheme();

  const options = useMemo(
    () => ({
      style: {
        base: {
          color: theme.color.text,
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: theme.color.textLight,
          },
        },
        invalid: {
          color: theme.color.red,
        },
      },
    }),
    [theme],
  );

  return <StripeInput {...props} options={options} />;
};

export default StripeCardInput;
