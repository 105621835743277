import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import LoggedOutCardPage from 'components/lib/layouts/LoggedOutCardPage';
import Banner from 'components/lib/ui/Banner';

import { useDispatch } from 'lib/hooks';
import { resetPasswordAction } from 'state/user/thunks';

import routes from 'constants/routes';

const TextLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const SuccessBanner = styled(Banner).attrs({ type: 'success' })``;

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { token = '' } = useParams<{ token: string | undefined }>();

  const [formSuccessful, setFormSuccessful] = useState<boolean>(false);

  return (
    <LoggedOutCardPage
      name="Reset Password"
      displayTitle="Reset Password"
      description="Create a new password for your Monarch account by filling out the form below."
    >
      <Form
        initialValues={{ new_password: '' }}
        onSubmit={async (values) => {
          await dispatch(resetPasswordAction({ ...values, token }));
          setFormSuccessful(true);
        }}
      >
        {formSuccessful ? (
          <SuccessBanner>
            Your password has been reset. <Link to={routes.login()}>Click here</Link> to go back to
            the log in page.
          </SuccessBanner>
        ) : (
          <>
            <TextField name="new_password" label="New Password" password required />
            <TextLink to={routes.login()}>Wait, I remember my password</TextLink>

            <FormSubmitButton>Reset Password</FormSubmitButton>
          </>
        )}
      </Form>
    </LoggedOutCardPage>
  );
};

export default ResetPassword;
