import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { isMacOs } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import GlobalSearch from 'components/globalSearch/GlobalSearch';
import ZendeskProvider from 'components/lib/external/ZendeskProvider';
import RouteModal from 'components/lib/routing/RouteModal';
import Flex from 'components/lib/ui/Flex';
import PremiumUpgradeFlow from 'components/premium/PremiumUpgradeFlow';
import { HEADER_HEIGHT } from 'components/routes/Header';
import AdvisorPortalSideBarContent from 'components/routes/SideBarAdvisorPortalContent';
import SideBarAdvisorPortalHeader from 'components/routes/SideBarAdvisorPortalHeader';
import DefaultSideBarContent from 'components/routes/SideBarDefaultContent';
import SideBarDefaultHeader from 'components/routes/SideBarDefaultHeader';
import HelpCenterModalCard from 'components/sidebar/HelpCenterModalCard';
import RequestFeatureModalCard from 'components/sidebar/RequestFeatureModalCard';
import UserMenu from 'components/sidebar/UserMenu';

import { useRecurringStreams } from 'common/lib/hooks/recurring/useRecurringStreams';
import { color, spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import variables from 'common/lib/theme/variables';
import typewriter from 'lib/analytics/typewriter';
import { useContactSupportContext } from 'lib/contexts/ContactSupportContext';
import useGlobalSearch from 'lib/hooks/useGlobalSearch';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useIsV2Theme from 'lib/hooks/useIsV2Theme';
import useModal from 'lib/hooks/useModal';
import { getIsSidebarExpanded, getTemporaryPromoCode } from 'selectors';

import { ReferredFeatureAnalyticsName } from 'common/constants/premium';
import { SIDEBAR_BREAKPOINT_PX } from 'constants/breakpoints';
import { SIDEBAR_COLLAPSED_CLASS_NAME } from 'constants/classNames';
import routes, { externalUrls } from 'constants/routes';
import easing from 'constants/timing/easing';

const SIDEBAR_WIDTH = isV2Theme('224px', '240px');
const SIDEBAR_COLLAPSED_WIDTH = isV2Theme('52px', '0');
const SIDEBAR_FOOTER_HEIGHT_PX = 57;

const Root = styled.div<{ expanded?: boolean }>`
  transition: width 0.3s ${easing};
  width: ${({ expanded, theme }) =>
    expanded ? SIDEBAR_WIDTH({ theme }) : SIDEBAR_COLLAPSED_WIDTH({ theme })};
  overflow: hidden;
  flex-shrink: 0;
  background-color: ${variables.color.background.sidebar};
  ${isV2Theme(
    undefined,
    css`
      color: ${color.textWhite};
    `,
  )}
  position: relative;

  /* We set the initial state for sidebarExpanded in window.onload. So before that happens,
     this media query takes over to ensure there isn't a "flash" in between the first render
     and when the initial state is set. */
  ${({ expanded }) =>
    expanded === undefined &&
    css`
      @media (min-width: ${SIDEBAR_BREAKPOINT_PX}px) {
        transition: none;
        width: ${SIDEBAR_WIDTH};
      }
    `}
`;

const Container = styled.div<{ $expanded?: boolean }>`
  width: ${({ $expanded, theme }) =>
    !$expanded && theme.isV2ThemeEnabled ? '100%' : SIDEBAR_WIDTH({ theme })};
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Section = styled(Flex)`
  display: flex;
  flex-direction: column;
  padding: ${isV2Theme(spacing.small, spacing.xsmall)};
  position: relative;
  ${isV2Theme(css`
    padding-right: 0;
    padding-top: 0;
  `)}
`;

const Header = styled(Section)`
  min-height: ${HEADER_HEIGHT};
  padding: 0 ${({ theme }) => theme.spacing.default} 0 ${({ theme }) => theme.spacing.default};
  ${isV2Theme(
    css`
      padding-right: 0;
    `,
    css`
      border-bottom: 1px solid ${color.navyDark};
    `,
  )}
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 0;
`;

const Content = styled(Section)`
  overflow-y: auto;
  height: 100%;
  scrollbar-width: none; /* For Firefox */

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  ${isV2Theme(css`
    padding-bottom: 0;
  `)}
`;

const Footer = styled(Section)`
  ${isV2Theme(
    undefined,
    css`
      padding: 0;
      min-height: ${SIDEBAR_FOOTER_HEIGHT_PX}px;
      border-top: 1px solid ${color.navyDark};
    `,
  )}
  position: sticky;
  bottom: 0;
`;

const SideBar = () => {
  const isSupportChatEnabled = useIsFeatureFlagOn('support-chat-bubble');

  const history = useHistory();
  const isV2Theme = useIsV2Theme();

  const temporaryPromoCode = useSelector(getTemporaryPromoCode);
  const { data } = useQuery(SIDEBAR_QUERY, {
    variables: { promoCode: temporaryPromoCode },
  });

  const { me, subscription, subscriptionOffering } = data ?? {};
  const { hasPremiumEntitlement } = subscription ?? {};
  const { name: username = '', profilePictureUrl } = me ?? {};
  const isSidebarExpanded = useSelector(getIsSidebarExpanded);

  const { pendingStreamsCount } = useRecurringStreams({
    skip: !hasPremiumEntitlement,
  });

  const [RequestFeatureModal, { open: openRequestFeatureModal }] = useModal();
  const [HelpCenterModal, { open: openHelpCenterModal, close: closeHelpCenterModal }] = useModal();
  const [UpgradeModal, { open: openUpgradeModal, close: closeUpgradeModal }] = useModal();

  const { openContactSupportModal, openSupportChat } = useContactSupportContext();
  const { isGlobalSearchModalVisible, setGlobalSearchModalVisible } = useGlobalSearch();

  const isInAdvisorPortal = history.location.pathname.startsWith(routes.advisorPortal.path);

  const openSearch = () => {
    if (!isInAdvisorPortal) {
      typewriter.searchOpened({ method: 'click' });
      setGlobalSearchModalVisible(true);
    }
  };

  const showGlobalSearch = !isInAdvisorPortal && isGlobalSearchModalVisible;

  return (
    <Root
      expanded={isSidebarExpanded}
      className={
        isSidebarExpanded === false && isV2Theme ? SIDEBAR_COLLAPSED_CLASS_NAME : undefined
      }
    >
      <Container $expanded={isSidebarExpanded}>
        <ZendeskProvider />
        <Header justifyCenter>
          {isInAdvisorPortal ? (
            <SideBarAdvisorPortalHeader />
          ) : (
            <SideBarDefaultHeader
              isMacOs={isMacOs}
              onOpenSearch={openSearch}
              isSidebarExpanded={isSidebarExpanded}
            />
          )}
        </Header>
        <Content>
          {isInAdvisorPortal ? (
            <AdvisorPortalSideBarContent onSupportButtonClick={openHelpCenterModal} />
          ) : (
            <DefaultSideBarContent
              subscription={subscription}
              subscriptionOffering={subscriptionOffering}
              onSupportButtonClick={openHelpCenterModal}
              onUpgradeButtonClick={openUpgradeModal}
              pendingStreamsCount={pendingStreamsCount}
            />
          )}
        </Content>
        <Footer>
          <UserMenu
            userName={username}
            profilePictureUrl={profilePictureUrl}
            hideSettingsButton={false}
          />
        </Footer>
      </Container>
      <RequestFeatureModal large>
        <RequestFeatureModalCard />
      </RequestFeatureModal>
      <RouteModal large path={routes.requestFeature.path}>
        <RequestFeatureModalCard />
      </RouteModal>
      <HelpCenterModal>
        <HelpCenterModalCard
          onClickHelpCenter={() => window.open(externalUrls.zendeskHelpCenter)}
          onClickFeatureRequest={() => {
            openRequestFeatureModal();
            closeHelpCenterModal();
          }}
          onClickContactSupport={() => {
            if (isSupportChatEnabled) {
              openSupportChat();
            } else {
              openContactSupportModal();
            }
            closeHelpCenterModal();
          }}
        />
      </HelpCenterModal>
      <UpgradeModal>
        <PremiumUpgradeFlow
          analyticsName={ReferredFeatureAnalyticsName.Navigation}
          onBack={closeUpgradeModal}
          onComplete={closeUpgradeModal}
        />
      </UpgradeModal>
      {showGlobalSearch && <GlobalSearch />}
    </Root>
  );
};

export const SIDEBAR_QUERY = gql(/* GraphQL */ `
  query Web_GetSidebarData($promoCode: String) {
    me {
      id
      name
      email
      profilePicture {
        id
        cloudinaryPublicId
      }
      profilePictureUrl
    }
    subscription {
      id
      billingPeriod
      paymentSource
      isOnFreeTrial
      hasPremiumEntitlement
      nextPaymentAmount

      activeSponsorship {
        id
      }

      ...FreeTrialDurationPanelData
    }
    subscriptionOffering(stripePromoCode: $promoCode) {
      promoCodeError
      promoCodeDescription
      plans {
        period
        pricePerPeriodDollars
      }
    }
  }
`);

export default SideBar;
