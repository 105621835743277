import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import GoalInfoRow from 'components/goalsV2/GoalInfoRow';
import CurrencyField from 'components/lib/form/CurrencyField';
import Form from 'components/lib/form/Form';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { sortGoalsByPriority } from 'common/lib/goalsV2/adapters';
import useUpdateGoal from 'common/lib/hooks/goalsV2/useUpdateGoal';
import useQuery from 'common/lib/hooks/useQuery';
import useUpdateEffect from 'common/lib/hooks/useUpdateEffect';

import * as COPY from 'common/constants/copy';
import { GoalType } from 'common/constants/goals';

import { gql } from 'common/generated/gql';
import type {
  Web_TargetAmountsGoalsList,
  Web_TargetAmountsGoalsList_goalsV2,
} from 'common/generated/graphQlTypes/Web_TargetAmountsGoalsList';

const List = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  width: 100%;
`;

const GoalRow = styled(FlexContainer).attrs({ column: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  width: 100%;
`;

const StyledGoalInfoRow = styled(GoalInfoRow)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const GoalTargetAmountInput = ({ goal }: { goal: Web_TargetAmountsGoalsList_goalsV2 }) => {
  const { updateGoal } = useUpdateGoal(goal);

  const debtGoalProps = {
    placeholder: 'This will be the total of all balances',
    disabled: true,
  };

  const isDebtGoal = goal.type === GoalType.Debt;

  return (
    <Form
      submitOnBlur
      onSubmit={updateGoal}
      initialValues={{
        targetAmount: !isDebtGoal && goal.targetAmount ? goal.targetAmount : undefined,
      }}
    >
      <CurrencyField
        name="targetAmount"
        hideLabel
        placeholder={COPY.GOALS.TARGET_AMOUNTS.PLACEHOLDER}
        {...(isDebtGoal && debtGoalProps)}
      />
    </Form>
  );
};

const GoalStartingAmountInput = ({ goal }: { goal: Web_TargetAmountsGoalsList_goalsV2 }) => {
  const { updateGoal } = useUpdateGoal(goal);

  return (
    <Form
      submitOnBlur
      onSubmit={({ startingAmount }) => updateGoal({ startingAmount: (startingAmount || 0) * -1 })}
      initialValues={{
        startingAmount: goal.startingAmount ?? undefined,
      }}
    >
      <CurrencyField
        name="startingAmount"
        hideLabel
        placeholder={COPY.GOALS.TARGET_AMOUNTS.PLACEHOLDER}
      />
    </Form>
  );
};

type Props = {
  /** Only show a single goal. */
  restrictToGoalId?: string;
  /** Update startingAmount instead of targetAmount, used for debt goals. */
  useStartingAmount?: boolean;
  onDoneLoading?: () => void;
};

const TargetAmountsGoalsList = ({ restrictToGoalId, useStartingAmount, onDoneLoading }: Props) => {
  const { data, isLoadingInitialData } = useQuery<Web_TargetAmountsGoalsList>(QUERY);

  useUpdateEffect(() => {
    if (!isLoadingInitialData) {
      onDoneLoading?.();
    }
  }, [isLoadingInitialData]);

  const goals = useMemo(() => {
    const goalsV2 = data?.goalsV2 ?? [];
    if (restrictToGoalId) {
      return goalsV2.filter(R.propEq('id', restrictToGoalId));
    } else {
      return sortGoalsByPriority(goalsV2);
    }
  }, [data, restrictToGoalId]);

  return (
    <List>
      {goals.map((item, idx) => (
        <GoalRow key={idx}>
          <StyledGoalInfoRow data={item} />
          {useStartingAmount ? (
            <GoalStartingAmountInput goal={item} />
          ) : (
            <GoalTargetAmountInput goal={item} />
          )}
        </GoalRow>
      ))}
    </List>
  );
};

const QUERY = gql(/* GraphQL */ `
  query Web_TargetAmountsGoalsList {
    goalsV2 {
      id
      targetAmount
      startingAmount
      type
      ...Web_GoalInfoRowFields
    }
  }
`);

export default TargetAmountsGoalsList;
