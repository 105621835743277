import * as RadixContextMenu from '@radix-ui/react-context-menu';
import { motion } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';

import NavLink from 'components/lib/ui/link/NavLink';

import boxShadow from 'common/lib/styles/boxShadow';
import useRouteMatch from 'lib/hooks/useRouteMatch';
import type { Route } from 'lib/namedRoutes';

import { POPOVER_ANIMATION_VARIANTS } from 'constants/animation';

import type { Color } from 'common/types/Styles';

const MIN_WIDTH_PX = 180;

type ContextMenuItemType = 'default' | 'danger';

const TEXT_COLOR_FOR_TYPE: { [key in ContextMenuItemType]: Color } = {
  default: 'text',
  danger: 'redText',
};

const HOVER_COLOR_FOR_TYPE: { [key in ContextMenuItemType]: Color } = {
  default: 'grayBackground',
  danger: 'redBackground',
};

const AnimatedContent = styled(motion.div).attrs({
  variants: POPOVER_ANIMATION_VARIANTS,
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
  transition: { type: 'tween', duration: 0.06 },
})`
  --context-menu-padding: ${({ theme }) => theme.spacing.xsmall};

  background: ${({ theme }) => theme.color.white};
  padding: var(--context-menu-padding);
  min-width: ${MIN_WIDTH_PX}px;
  border-radius: ${({ theme }) => theme.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  transform-origin: var(--radix-context-menu-content-transform-origin);

  ${boxShadow.large};
`;

export const ContextMenuItem = styled(RadixContextMenu.Item)<{
  selected?: boolean;
  focused?: boolean;
  disabled?: boolean;
  highlightOnHover?: boolean;
  type?: ContextMenuItemType;
}>`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.small};
  color: ${({ theme, type = 'default' }) => theme.color[TEXT_COLOR_FOR_TYPE[type]]};
  border-radius: ${({ theme }) => theme.radius.small};
  transition: ${({ theme }) => theme.transition.default};
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  ${(context) =>
    !context.disabled &&
    css`
      :active {
        ${boxShadow.inset}
      }
    `}

  ${({ highlightOnHover = true, disabled, type = 'default' }) =>
    highlightOnHover &&
    !disabled &&
    css`
      :hover {
        background: ${({ theme }) => theme.color[HOVER_COLOR_FOR_TYPE[type]]};
        color: ${({ theme }) => theme.color[TEXT_COLOR_FOR_TYPE[type]]};
      }
    `};

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.color.textLight};
      cursor: not-allowed;
    `}

  ${({ focused }) =>
    focused &&
    css`
      background: ${({ theme }) => theme.color.grayBackground};
    `};

  ${({ selected }) =>
    selected &&
    css`
      && {
        background: ${({ theme }) => theme.color.blueBackground};
        color: ${({ theme }) => theme.color.blueText};
      }
    `};
`;

type Props = React.ComponentPropsWithoutRef<typeof RadixContextMenu.Root> & {
  items: React.ReactNode;
};

/**
 * This is a very basic implementation of the context menu component using Radix UI.
 * It doesn't include submenus, separators, labels, etc. because we don't need them yet.
 *
 * If we need more functionality, we can extend this component.
 * See: https://www.radix-ui.com/primitives/docs/components/context-menu
 */
const ContextMenu = ({ children, items, ...props }: React.PropsWithChildren<Props>) => (
  <RadixContextMenu.Root {...props}>
    <RadixContextMenu.Trigger>{children}</RadixContextMenu.Trigger>

    <RadixContextMenu.Portal>
      <RadixContextMenu.Content>
        <AnimatedContent>{items}</AnimatedContent>
      </RadixContextMenu.Content>
    </RadixContextMenu.Portal>
  </RadixContextMenu.Root>
);

type ContextMenuItemLinkProps = {
  to: Route<any>;
  children?: React.ReactNode;
};

export const ContextMenuItemLink = ({ to, children }: ContextMenuItemLinkProps) => {
  const match = useRouteMatch(to);

  return (
    <NavLink to={to}>
      <ContextMenuItem selected={match?.isExact}>{children}</ContextMenuItem>
    </NavLink>
  );
};

export const ContextMenuSeparator = styled(RadixContextMenu.Separator)`
  height: 1px;
  background: ${({ theme }) => theme.color.grayLight};
  margin: var(--context-menu-padding) calc(-1 * var(--context-menu-padding));
`;

export default ContextMenu;
