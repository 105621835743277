import * as React from 'react';
import styled from 'styled-components';

import AmountPill from 'components/lib/ui/AmountPill';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Highlighter from 'components/lib/ui/Highlighter';
import Text from 'components/lib/ui/Text';

import { formatCurrency } from 'common/utils/Currency';

import SecurityLogo from './SecurityLogo';

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  padding: ${({ theme }) => theme.spacing.default};

  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};

  &:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.radius.medium};
    border-bottom-right-radius: ${({ theme }) => theme.radius.medium};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.grayBackground};
    cursor: pointer;
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template: auto / auto 1fr 80px 96px;
  grid-column-gap: ${(props) => props.theme.spacing.default};
  width: 100%;
`;

const SecurityInfoContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  width: 260px;
  > span {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const SecurityPriceContainer = styled(FlexContainer)`
  align-items: center;
  justify-content: flex-end;
`;

const SecurityPriceVariationContainer = styled(FlexContainer)`
  align-items: center;
  justify-content: center;
`;

type Props = {
  name: string;
  logo: string | null;
  ticker: string | null;
  currentPrice?: number | null;
  closingPrice?: number | null;
  oneDayChangeDollars?: number | null;
  oneDayChangePercent?: number | null;
  onClick?: () => void;
};

const SecuritySearchListItem = ({
  name,
  ticker,
  logo,
  closingPrice,
  currentPrice,
  oneDayChangeDollars,
  oneDayChangePercent,
  onClick,
}: Props) => (
  <Root onClick={onClick}>
    <Grid>
      <SecurityLogo logo={logo} />
      <SecurityInfoContainer>
        <Text weight="medium">
          <Highlighter>{ticker ?? ''}</Highlighter>
        </Text>
        <Text size="xsmall">
          <Highlighter>{name}</Highlighter>
        </Text>
      </SecurityInfoContainer>

      <SecurityPriceContainer>
        <Text size="base" color="text">
          {formatCurrency(currentPrice)}
        </Text>
      </SecurityPriceContainer>

      <SecurityPriceVariationContainer>
        {oneDayChangePercent ? (
          <AmountPill
            value={oneDayChangePercent ?? null}
            formatter={(value) => `${value?.toFixed(2)}%`}
          />
        ) : (
          <Text size="base" color="textLight" align="center">
            N/A
          </Text>
        )}
      </SecurityPriceVariationContainer>
    </Grid>
  </Root>
);

export default SecuritySearchListItem;
