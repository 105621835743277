import * as React from 'react';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import { GridItem } from 'components/lib/ui/Grid';
import Text from 'components/lib/ui/Text';

import { formatCurrency } from 'common/utils/Currency';

const Root = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  transition: background-color 0.1s ease-out;
  background-color: transparent;

  :not(:last-child) {
    border-bottom-color: ${({ theme }) => theme.color.grayBackground};
  }
`;

const RowContainer = styled(Flex)`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.large} 0;

  background-color: ${(props) => props.theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.base};
  transition: border-color 0.1s ease-out;

  flex-grow: 2;

  /* Negative left/right margin lets the row borders overlap the surrounding list border. */
  margin: -1px -1px 0;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledGrid = styled.div`
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 2fr 2fr 2fr 2fr 40px 40px;
  grid-gap: ${(props) => props.theme.spacing.xxsmall};
  grid-template-areas: 'info price quantity variation value icon actions';
  align-items: center;
  height: 64px;
`;

const StyledGridItem = styled(GridItem)`
  display: flex;
  > * {
    width: 100%;
  }
`;

type Props = {
  hide: boolean;
  totalValue: number;
};

const HoldingsListFooter = ({ hide, totalValue }: Props) =>
  !hide ? (
    <Root>
      <RowContainer>
        <StyledGrid>
          <StyledGridItem area="info">
            <Text weight="medium" align="left">
              Total
            </Text>
          </StyledGridItem>
          <StyledGridItem area="value">
            <Text weight="medium" align="right">
              {formatCurrency(totalValue)}
            </Text>
          </StyledGridItem>
        </StyledGrid>
      </RowContainer>
    </Root>
  ) : null;

export default HoldingsListFooter;
