import * as React from 'react';

import type { TransactionRulePrefillValues } from 'types/rules';

export type TransactionRuleToastContextType = {
  showToast: (values: TransactionRulePrefillValues) => void;
};

const TransactionRuleToastContext = React.createContext<TransactionRuleToastContextType>(
  undefined as any,
);

export const useTransactionRuleToastContext = () => {
  const context = React.useContext(TransactionRuleToastContext);

  if (!context) {
    throw new Error(
      'useTransactionRuleToastContext used outside of TransactionRuleToast. Ensure you have a <TransactionRuleToastContext.Provider> higher in the tree.',
    );
  }

  return context;
};

export default TransactionRuleToastContext;
