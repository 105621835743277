import * as React from 'react';

import Confirmation from 'components/lib/ui/Confirmation';
import type { Props as ConfirmationProps } from 'components/lib/ui/Confirmation';

type Props = Pick<ConfirmationProps, 'onConfirm' | 'onCancel' | 'isLoading'> & { name: string };

const DeleteMerchantConfirmation = ({ name, onConfirm, onCancel, isLoading }: Props) => (
  <Confirmation
    title={`Delete ${name}`}
    onConfirm={onConfirm}
    onCancel={onCancel}
    confirm="Delete merchant"
    isLoading={isLoading}
  >
    Are you sure you want to delete this merchant?
  </Confirmation>
);

export default DeleteMerchantConfirmation;
