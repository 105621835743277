// for SplitIO namespace to work
import * as _ from '@splitsoftware/splitio';

export const DEFAULT_SPLIT_TREATMENT_VALUE = 'control';
const DEFAULT_SPLIT = {
  treatment: DEFAULT_SPLIT_TREATMENT_VALUE,
  config: null,
};

export type FeatureFlagWithConfig<TConfig> = Omit<SplitIO.TreatmentWithConfig, 'config'> & {
  config: TConfig | null;
};

export type FeatureFlags<TConfig = unknown> = {
  [flag: string]: FeatureFlagWithConfig<TConfig>;
};

const parseFlagPair = ([key, treatment]: [string, SplitIO.TreatmentWithConfig]): FeatureFlags => ({
  [key]: { ...treatment, config: treatment.config !== null ? JSON.parse(treatment.config) : null },
});

/** Loop over received flags from Split and parse any stringified config, if present. */
export const parseFlagsFromSplit = (flags: SplitIO.TreatmentsWithConfig) =>
  Object.entries(flags).reduce(
    (previous, current) => ({ ...previous, ...parseFlagPair(current) }),
    {},
  );

/** Used to generate default split objects before the client is initialized. */
export const getDefaultSplitObjects = (names: string[]) =>
  names.reduce((p, v) => ({ ...p, [v]: DEFAULT_SPLIT }), {});
