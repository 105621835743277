import * as R from 'ramda';
import type { ReactNode } from 'react';
import type { OptionsType, OptionTypeBase, ValueType, GroupType } from 'react-select';

export type OptionType = {
  value: string;
  label: string | ReactNode;
  icon?: ReactNode;
  disabled?: boolean;
  dontFocusOnSearch?: boolean;
  onSelected?: () => Promise<void> | void;
  __isNew__?: boolean;
};

export const isOptionType = (value: any): value is OptionType =>
  !R.isNil(value) && R.has('value', value) && R.has('label', value);

export const isOptionsType = <T extends OptionTypeBase>(
  value: ValueType<T>,
): value is OptionsType<T> => Array.isArray(value);

export const isOptionGroupTypeArray = (options: any): options is GroupType<OptionType>[] =>
  !R.isNil(options[0]?.options);

/** Narrow type from
 * OptionType | OptionsType<OptionType> | null | undefined   ---->   OptionType
 * */
export const isSingleValue = (value: ValueType<OptionType>): value is OptionType =>
  !R.isNil(value) && !Array.isArray(value);
