import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import TransactionTagSelect from 'components/transactions/tags/TransactionTagSelect';

import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';
import type { CashFlowFilter } from 'state/filters/types';

const CARD_WIDTH_PX = 328;

const Root = styled.div`
  width: ${CARD_WIDTH_PX}px;
  padding-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const Header = styled(FlexContainer).attrs({ justifyBetween: true, alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
`;

const FilterSectionRoot = styled.div`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }
`;

const FilterSectionContent = styled(FlexContainer)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};

  > * {
    flex: 1;
  }
`;

const ApplyButton = styled(FormSubmitButton).attrs({ size: 'small' })`
  width: fit-content;
  margin-top: unset;
`;

type FilterSectionProps = {
  title: string;
  children: React.ReactNode;
};

const FilterSection = ({ title, children }: FilterSectionProps) => (
  <FilterSectionRoot>
    <Text weight="medium" size="small">
      {title}
    </Text>
    <FilterSectionContent>{children}</FilterSectionContent>
  </FilterSectionRoot>
);

type Props = {
  activeFilters: Pick<CashFlowFilter, 'accounts' | 'tags'>;
  onClickApply: (values: Partial<Props['activeFilters']>) => void;
  onClickReset: () => void;
};

const CashFlowFilterCard = ({ activeFilters, onClickApply, onClickReset }: Props) => {
  const [isLoadingAccounts, accountOptions] = useAccountSelectOptions();

  return (
    <Form initialValues={activeFilters} onSubmit={(values) => onClickApply(values)}>
      <Root>
        <Header>
          <DefaultButton onClick={() => onClickReset()}>Reset</DefaultButton>
          <ApplyButton>Apply</ApplyButton>
        </Header>
        <Content>
          <FilterSection title="Accounts">
            <SelectField
              name="accounts"
              placeholder="All accounts..."
              options={accountOptions}
              isLoading={isLoadingAccounts}
              isMulti
              hideLabel
            />
          </FilterSection>
          <FilterSection title="Tags">
            <SelectField
              name="tags"
              InputComponent={TransactionTagSelect}
              isCreatable={false}
              placeholder="All tags..."
              hideLabel
              isMulti
            />
          </FilterSection>
        </Content>
      </Root>
    </Form>
  );
};

export default CashFlowFilterCard;
