import { createSelector } from 'reselect';

import type { AnalyticsState } from 'common/state/analytics/reducer';

const getAnalyticsState = ({ analytics }: { analytics: AnalyticsState }) => analytics;

export const getSegmentAnonymousUserId = createSelector(
  getAnalyticsState,
  ({ segmentAnonymousUserId }) => segmentAnonymousUserId,
);
