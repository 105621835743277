import React from 'react';
import styled from 'styled-components';

import TransactionMessageBlock from 'components/assistant/blocks/TransactionMessageBlock';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { TransactionsBlock } from 'common/lib/assistant/blocks';
import { makeBlockRenderer } from 'common/lib/assistant/blocks/render';
import { parseCurrency } from 'common/utils/Currency';

const Root = styled(FlexContainer)`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const DemoTransactionsBlockRenderer = makeBlockRenderer(TransactionsBlock)(
  ({ data: { ids, rows } }) => (
    <Root column>
      {rows.map(({ ID, Merchant, Category, Amount }) => (
        <TransactionMessageBlock
          key={ID}
          id={ID}
          name={Merchant}
          category={Category}
          amount={parseCurrency(Amount)}
        />
      ))}
    </Root>
  ),
);

export default DemoTransactionsBlockRenderer;
