import React from 'react';
import { animated, useTransition } from 'react-spring';

import useMeasure from 'lib/hooks/useMeasure';

export type Action = 'opening' | 'opened' | 'closing' | 'closed';
type Props = {
  open: boolean;
  onOpening?: () => void;
  onOpened?: () => void;
  onClosing?: () => void;
  onClosed?: () => void;
  children: React.ReactNode;
  className?: string;
  /** Whether or not the drawer animates when it is first rendered. Defaults to true. */
  shouldAnimateOnMount?: boolean;
};

const Drawer = ({
  open,
  onOpening,
  onOpened,
  onClosing,
  onClosed,
  children,
  className,
  shouldAnimateOnMount = true,
}: Props) => {
  const [measureRef, { height }] = useMeasure<HTMLDivElement>();
  const transitions = useTransition(open, null, {
    from: { height: 0, overflow: 'hidden' },
    update: { height },
    leave: { height: 0 },
    initial: shouldAnimateOnMount ? undefined : { overflow: 'hidden' },
    onStart: () => (open ? onOpening?.() : onClosing?.()),
    onDestroyed: () => (open ? onOpened?.() : onClosed?.()),
  });
  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props} className={className}>
              <div ref={measureRef}>{children}</div>
            </animated.div>
          ),
      )}
    </>
  );
};

export default Drawer;
