import mousetrap from 'mousetrap';
import type { ExtendedKeyboardEvent } from 'mousetrap';
import { useEffect } from 'react';

/**
 * Like, useKey, but does not bind to a specific element's subtree. Use this for global hot keys.
 * Global keys do not trigger inside input or form elements by default
 *
 * @param  {(string | string[])} handlerKey - A key, key combo or array of combos according to Mousetrap documentation.
 * @param  { function } handlerCallback - A function that is triggered on key combo catch.
 * @param  { string } evtType - A string that specifies the type of event to listen for. It can be 'keypress', 'keydown' or 'keyup'.
 */
const useGlobalKey = (
  handlerKey: string | string[],
  handlerCallback: (e: ExtendedKeyboardEvent, combo: string) => void,
  evtType?: string,
) => {
  useEffect(() => {
    mousetrap.bind(handlerKey, handlerCallback, evtType);
    return () => {
      mousetrap.unbind(handlerKey);
    };
  }, [handlerKey, evtType, handlerCallback]);
};

// Override stopCallback to prevent keyboard shortcuts from firing in select dropdowns
// https://craig.is/killing/mice#api.stopCallback
const originalStopCallback = mousetrap.prototype.stopCallback;
mousetrap.prototype.stopCallback = function stopCallback(
  e: mousetrap.ExtendedKeyboardEvent,
  element: Element,
  combo: string,
): boolean {
  if (element.role === 'option') {
    // FullScreenSelect component transfers focus to select options (away from input), so we need to check for that
    return true; // disallow keyboard shortcut
  }

  // Original stopCallback from the lib handles input, select, and textarea elements
  return originalStopCallback(e, element, combo);
};

export default useGlobalKey;
