import { DateTime } from 'luxon';
import * as RA from 'ramda-adjunct';
import React, { useState, useCallback } from 'react';
import type { EventWrapperProps, Event } from 'react-big-calendar';
import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';

import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';
import EventPopover from 'components/recurring/calendar/EventPopover';

import type { RecurringTransactionItem } from 'common/lib/recurring/types';
import formatTransactionAmount from 'common/utils/formatTransactionAmount';
import useTheme from 'lib/hooks/useTheme';

const ICON_SIZE_PX = 12;

const Root = styled.div<{ $backgroundColor: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  margin: ${({ theme }) => theme.spacing.xxsmall};
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
  margin-bottom: 0;
  padding: ${({ theme }) => theme.spacing.xxsmall};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radius.small};
`;

const TitleIcon = styled(Icon).attrs({ size: ICON_SIZE_PX })<{ $color: string; name: string }>`
  color: ${({ $color }) => $color};
  margin-right: ${({ theme }) => theme.spacing.xxxsmall};
  margin-top: 2px; /* center check icon vertically */
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
`;

const Title = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Type `resource` and make it required
export type RecurringCalendarItem = Omit<Event, 'resource'> & {
  resource: RecurringTransactionItem;
};

type Props = EventWrapperProps<RecurringCalendarItem> & {
  refetch: () => void;
};

const EventWrapper = ({ event, refetch }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const theme = useTheme();

  const { isPast = false, amount, amountDiff, stream, date, isCompleted } = event.resource;
  const { title, start } = event;

  const correctAmountDiff = amountDiff ?? 0;

  const eventStartDate = start ? DateTime.fromJSDate(start) : undefined;

  const getColors = useCallback(
    (isOpen: boolean) => {
      let blueBackgroundColor = theme.color.blueBackground;
      let greenBackgroundColor = theme.color.greenBackground;
      let yellowBackgroundColor = theme.color.yellowBackground;
      let redBackgroundColor = theme.color.redBackground;

      if (isOpen) {
        blueBackgroundColor = theme.color.blue;
        greenBackgroundColor = theme.color.green;
        yellowBackgroundColor = theme.color.yellow;
        redBackgroundColor = theme.color.red;
      }

      let backgroundColor = blueBackgroundColor;
      let titleColor = 'blueDark' as keyof DefaultTheme['color'];
      let iconColor = theme.color.greenText;

      const hasCreditReportLiabilityAccount = !!stream?.creditReportLiabilityAccount;

      if (isPast && !hasCreditReportLiabilityAccount) {
        backgroundColor = correctAmountDiff < 0 ? yellowBackgroundColor : greenBackgroundColor;
        iconColor = correctAmountDiff < 0 ? theme.color.yellowText : theme.color.greenText;
        titleColor = correctAmountDiff < 0 ? 'yellowText' : 'greenText';
      } else if (isPast && hasCreditReportLiabilityAccount) {
        backgroundColor = isCompleted ? greenBackgroundColor : redBackgroundColor;
        iconColor = isCompleted ? theme.color.greenText : theme.color.red;
        titleColor = isCompleted ? 'greenText' : 'red';
      }

      if (isOpen) {
        iconColor = theme.color.white;
      }

      return { backgroundColor, iconColor, titleColor };
    },
    [isPast, theme, correctAmountDiff, isCompleted, stream],
  );

  return (
    <OverlayTrigger
      placement="bottom-start"
      closeOnEscape={false}
      disableClickOutside={isMenuOpen}
      overlay={() => (
        <Popover>
          <EventPopover
            date={date}
            amount={amount}
            stream={stream}
            startDate={eventStartDate}
            setIsMenuOpen={setIsMenuOpen}
            refetch={refetch}
            amountDiff={correctAmountDiff}
          />
        </Popover>
      )}
    >
      {({ toggleOpen, isOpen }) => {
        const { backgroundColor, iconColor, titleColor } = getColors(isOpen);

        const isMerchant = stream?.merchant;
        const isAccount = stream?.creditReportLiabilityAccount;
        const isPaid = !!isCompleted;

        const shouldShowCheck = (isPast && isMerchant) || (isAccount && isPaid);
        const shouldShowX = isAccount && isPast && !isCompleted;

        return (
          <Root onClick={toggleOpen} $backgroundColor={backgroundColor}>
            <TitleWrapper>
              {shouldShowCheck ? <TitleIcon name="check" $color={iconColor} /> : null}

              {shouldShowX ? <TitleIcon name="x" $color={iconColor} /> : null}

              <Title color={isOpen ? 'white' : titleColor} weight="medium" size="xsmall">
                {title}
              </Title>
            </TitleWrapper>

            {RA.isNotNil(amount) && (
              <Text
                color={isOpen ? 'white' : titleColor}
                weight="medium"
                size="xsmall"
                {...maskClassProps}
              >
                {stream?.isApproximate ? '≈' : ''}
                {formatTransactionAmount(amount)}
              </Text>
            )}
          </Root>
        );
      }}
    </OverlayTrigger>
  );
};

export default EventWrapper;
