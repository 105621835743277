import * as RA from 'ramda-adjunct';

/**
 * Formik allows for nesting and array access from field names (lodash style). These functions
 * should be used when we don't want to use this default behavior.
 * https://formik.org/docs/guides/arrays#nested-objects
 */
const FIELD_NAME_REPLACE = [
  ['[', 'FIELD_ESCAPE_LEFT_BRACKET'],
  [']', 'FIELD_ESCAPE_RIGHT_BRACKET'],
  ['.', 'FIELD_ESCAPE_DOT'],
];

export const escapeFieldName = (fieldName: string) =>
  FIELD_NAME_REPLACE.reduce((val, [from, to]) => RA.replaceAll(from, to, val), fieldName);

export const unescapeFieldName = (fieldName: string) =>
  FIELD_NAME_REPLACE.reduce((val, [to, from]) => RA.replaceAll(from, to, val), fieldName);
