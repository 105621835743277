import { withSubRoutes, withParams } from 'lib/namedRoutes';

const routes = withSubRoutes('', {
  dashboard: '/dashboard',
  contactSupport: '/dashboard/contact-support',
  refundRequest: '/dashboard/refund-request',
  assistant: withParams<{ threadId?: string }>('/assistant/:threadId?'),
  spendingInsights: '/spending-insights/',
  assistantEvaluation: '/assistant-evaluation',
  actAsUser: withParams<{ id?: string }>('/act-as-user/:id?'),
  accounts: withSubRoutes('/accounts', {
    addAccount: '/accounts/add-account',
    addManualAccount: '/accounts/add-manual-account',
    accountDetails: withSubRoutes(withParams<{ id: string }>('/accounts/details/:id'), {
      edit: withParams<{ id: string }>('/accounts/details/:id/edit'),
      addTransaction: withParams<{ id: string }>('/accounts/details/:id/add-transaction'),
      addHolding: withParams<{ id: string }>('/accounts/details/:id/add-holding'),
    }),
    addAccountForDataProvider: withParams<{ dataProvider: string; institutionId?: string }>(
      '/accounts/add-account/:dataProvider/:institutionId?',
    ),
    addAccountSelectDataProvider: withParams<{ institutionId: string }>(
      '/accounts/choose-provider/:institutionId',
    ),
    plaidLinkOauth: '/accounts/plaid-link',
    coinbaseAuth: '/accounts/add-account/coinbase',
    importMintData: '/accounts/import/mint',
    importMintTransactions: '/accounts/import/mint-transactions',
    importMintBalanceHistory: '/accounts/import/mint-balance-history',
  }),
  transactions: withSubRoutes('/transactions', {
    details: withParams<{ id: string }>('/transactions/:id'),
    addTransaction: '/transactions/add-transaction',
  }),
  cashFlow: '/cash-flow',
  sankey: '/sankey',
  categories: withParams<{ id: string }>('/categories/:id'),
  merchants: withParams<{ id: string }>('/merchants/:id'),
  categoryGroups: withParams<{ id: string }>('/category-groups/:id'),
  budget: withSubRoutes('/budget', {
    editCategoryGroup: '/budget/edit',
    onboarding: '/budget/onboarding',
  }),
  testing: withParams<{ id: string }>('/testing/:id'),
  settings: withSubRoutes('/settings', {
    profile: '/settings/profile',
    preferences: '/settings/preferences',
    notifications: withSubRoutes('/settings/notifications', {
      email: '/settings/notifications/email',
    }),
    security: '/settings/security',
    household: '/settings/household',
    institutions: withSubRoutes('/settings/institutions', {
      addAccount: '/settings/institutions/add-account',
    }),
    rules: withSubRoutes('/settings/rules', {
      transactionRule: '/settings/rules/transaction-rule',
    }),
    display: '/settings/display',
    data: '/settings/data',
    categories: '/settings/categories',
    members: '/settings/members',
    billing: withSubRoutes('/settings/billing', {
      subscribe: '/settings/billing/subscribe',
      upgrade: '/settings/billing/upgrade',
      premium: '/settings/billing/premium',
      subscriptionEnded: '/settings/billing/subscriptionEnded',
    }),
    guestPasses: '/settings/guest-pass',
    clients: '/settings/clients',
    tags: '/settings/tags',
    referrals: '/settings/referrals',
    merchants: '/settings/merchants',
    liabilities: '/settings/liabilities',
  }),
  requestFeature: '/dashboard/request-feature',
  reports: withSubRoutes('/reports', {
    spending: '/reports/spending',
    income: '/reports/income',
    cashFlow: '/reports/cash-flow',
    sankey: '/reports/sankey',
  }),
  logout: '/logout',
  login: '/login',
  links: '/links',
  authProviderLogin: withParams<{ provider: string }>('/auth/login'),
  authConfirmation: withParams<{ provider: string }>('/auth/:provider/confirmation'),
  signup: withSubRoutes('/signup', {
    createPassword: '/signup/create-password',
    verifyEmail: '/signup/verify-email',
    demographics: '/signup/demographics',
    goals: '/signup/goals',
    priorities: '/signup/priorities',
    attribution: '/signup/attribution',
    testimonials: '/signup/testimonials',
    connectSingleAccountBeforePaywall: '/signup/connect-account',
    connectSingleAccountDone: '/signup/connect-account-done',
    sankey: '/signup/sankey',
    premiumUpsell: '/signup/try-premium',
    selectPlan: '/signup/select-plan',
    spendingInsights: '/signup/spending-insights',
    connectSpendingAccountHype: '/signup/connect-spending-account-hype',
    connectSpendingAccount: '/signup/connect-spending-account',
    invitePartner: '/signup/invite-partner',
  }),
  demo: withSubRoutes('/demo', {
    startDemo: '/demo/start',
    userDetails: '/demo/user-details',
  }),
  goalsV2: withSubRoutes('/objectives', {
    goalDetail: withSubRoutes(withParams<{ id: string }>('/objectives/:id'), {
      edit: withParams<{ id: string }>('/objectives/:id/edit'),
    }),
    select: '/objectives/select',
    rank: '/objectives/rank',
    targetAmounts: '/objectives/target-amounts',
    mapAccounts: '/objectives/map-accounts',
    monthlyBudgets: '/objectives/monthly-budgets',
    preTaxBudgets: '/objectives/pre-tax-budgets',
  }),
  planning: '/planning',
  plan: withSubRoutes('/plan', {
    onboarding: '/plan/onboarding',
  }),
  forgotPassword: '/password/forgot',
  resetPassword: withParams<{ token: string }>('/password/reset/:token'),
  createPassword: withParams<{ token: string }>('/password/create/:token'),
  household: withSubRoutes('/household', {
    acceptInvite: '/household/accept-invite',
  }),
  investments: withSubRoutes('/investments', {
    allocation: '/investments/allocation',
    holdings: withSubRoutes('/investments/holdings', {
      addHolding: withParams('/investments/holdings/add-holding'),
    }),
  }),
  recurring: withSubRoutes('/recurring', {
    upcoming: '/recurring/upcoming',
    all: '/recurring/all',
    mapLiabilities: '/recurring/map-liabilities',
  }),
  advice: withSubRoutes(withParams<{ category?: string }>('/advice/:category?'), {
    questionnaire: withParams<{ questionnaireName: string; questionName: string }>(
      '/advice/profile/:questionnaireName/:questionName',
    ),
    questionnaireIntro: withParams<{ questionnaireName: string }>(
      '/advice/profile/:questionnaireName',
    ),
    itemDetails: withParams<{ itemId: string; category?: string }>(
      '/advice/:category?/item/:itemId',
    ),
    taskDetails: withParams<{ itemId: string; taskId: string; category?: string }>(
      '/advice/:category?/item/:itemId/task/:taskId',
    ),
  }),
  advisors: withSubRoutes('/advisors', {
    signup: withSubRoutes('/advisors/signup', {
      verifyEmail: '/advisors/signup/verify-email',
      setupAccount: '/advisors/signup/setup-account',
      selectPlan: '/advisors/signup/select-plan',
    }),
  }),
  advisorPortal: withSubRoutes('/advisor-portal', {
    profile: '/advisor-portal/profile',
    clients: withSubRoutes('/advisor-portal/clients', {
      addClients: withParams('/advisor-portal/clients/add-clients'),
      getAccessGrant: '/advisor-portal/clients/get-access-grant',
    }),
    billing: '/advisor-portal/billing',
    security: '/advisor-portal/security',
  }),
  oauth: withSubRoutes('/oauth', {
    coinbase: '/oauth/coinbase',
  }),
  oops: '/oops',
  notFound: '/not-found',
  health: '/health',
  version: '/version',
  hackathon: '/hackathon',
  hackathonAssistant: '/hackathon-assistant',
  sponsorshipInviteUnsubscribeSuccess: '/sponsorship/unsubscribe/success/',
});

const HELPCENTER_BASE_LINK = 'https://help.monarchmoney.com';

export const externalUrls = {
  marketingSite: 'https://www.monarchmoney.com',
  termsOfUse: 'https://www.monarchmoney.com/terms',
  privacyPolicy: 'https://www.monarchmoney.com/privacy',
  webApp: 'https://app.monarchmoney.com',
  webAppStaging: 'https://monarch-web-staging.herokuapp.com',
  zendeskHelpCenter: `${HELPCENTER_BASE_LINK}/hc/en-us`,
  iOSApp: 'https://apps.apple.com/us/app/monarch-money/id1459319842',
  androidApp: 'https://play.google.com/store/apps/details?id=com.monarchmoney.mobile',
  staticAssetsRoot: 'https://monarch-static-assets.s3.amazonaws.com',
  currentConnectionStatusUS: `${HELPCENTER_BASE_LINK}/hc/en-us/articles/13227606293908`,
  knownConnectionIssues: `${HELPCENTER_BASE_LINK}/hc/en-us/articles/360048393352`,
  accountRefresh: `${HELPCENTER_BASE_LINK}/hc/en-us/articles/360054839131`,
  recurringTransactions: `${HELPCENTER_BASE_LINK}/hc/en-us/articles/4890751141908`,
  authy: 'https://authy.com',
  onePass: 'https://1password.com',
  lastPassAuth: 'https://lastpass.com/auth',
  lastPass: 'https://www.lastpass.com',
  keeper: 'https://www.keepersecurity.com',
} as const;

export default routes;
