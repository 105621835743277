import { rgba } from 'polished';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import ContentLoader from 'components/lib/ui/ContentLoader';
import FlexContainer from 'components/lib/ui/FlexContainer';

type Props = { columnNames: string[] };

const Root = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  overflow: hidden;
`;

const HeaderTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding: ${({ theme }) => `${theme.spacing.default}`} 2.3%;
  display: inline-block;
  text-align: left;
`;

const SubHeaderRoot = styled.div`
  height: 40px;
  width: 100%;

  background-color: ${({ theme }) => rgba(theme.color.grayBackground, 0.7)};
`;

const FooterRoot = styled.div`
  height: 90px;
  width: 100%;
  margin: 25px 0;

  background: ${({ theme }) => rgba(theme.color.white, 1)};
  background-color: ${({ theme }) => rgba(theme.color.white, 1)};
`;

const HoldingRoot = styled.div`
  height: 56px;
  width: 100%;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const HeaderRoot = styled(FlexContainer)`
  ${HeaderTitle}:first-child {
    flex-basis: 39%;
    padding-left: ${({ theme }) => theme.spacing.xlarge};
  }
  ${HeaderTitle}:last-child {
    flex-basis: 17%;
    text-align: right;
    padding-right: ${({ theme }) => theme.spacing.xlarge};
  }
`;

const Header = ({ columnNames }: Props) => (
  <HeaderRoot justifyBetween alignCenter full>
    {R.map(
      (name) => (
        <HeaderTitle key={name}>{name}</HeaderTitle>
      ),
      columnNames,
    )}
  </HeaderRoot>
);

const SubHeaderLoading = () => (
  <SubHeaderRoot>
    <ContentLoader>
      <rect x="24" y="12" rx="5" ry="5" width="8%" height="16" />
    </ContentLoader>
  </SubHeaderRoot>
);

const HoldingLoading = () => (
  <HoldingRoot>
    <ContentLoader>
      <circle cx="34" cy="28" r="12" />
      <rect x="calc(7% - 24px)" y="20" rx="5" ry="5" width="12%" height="16" />
      <rect x="calc(45% - 24px)" y="17" rx="5" ry="5" width="8%" height="16" />
      <rect x="calc(59% - 24px)" y="17" rx="5" ry="5" width="8%" height="16" />
      <rect x="calc(72% - 24px)" y="17" rx="5" ry="5" width="8%" height="16" />
      <rect x="calc(92% - 24px)" y="17" rx="5" ry="5" width="9%" height="16" />
    </ContentLoader>
  </HoldingRoot>
);

const FooterLoading = () => (
  <FooterRoot>
    <ContentLoader>
      <rect x="calc(52% - 24px)" y="27" rx="5" ry="5" width="8%" height="16" />
      <rect x="calc(54% - 24px)" y="52" rx="5" ry="5" width="6%" height="16" />
      <rect x="calc(72% - 24px)" y="27" rx="5" ry="5" width="8%" height="16" />
      <rect x="calc(74% - 24px)" y="52" rx="5" ry="5" width="6%" height="16" />
      <rect x="calc(90% - 24px)" y="27" rx="5" ry="5" width="10%" height="16" />
      <rect x="calc(94% - 24px)" y="52" rx="5" ry="5" width="6%" height="16" />
    </ContentLoader>
  </FooterRoot>
);

const HoldingsTableLoading = ({ columnNames }: Props) => (
  <>
    <Root>
      <Header columnNames={columnNames} />
      <SubHeaderLoading />
      {R.range(0, 3).map((i) => (
        <HoldingLoading key={i} />
      ))}
      <SubHeaderLoading />
      {R.range(0, 5).map((i) => (
        <HoldingLoading key={i} />
      ))}
      <SubHeaderLoading />
      {R.range(0, 2).map((i) => (
        <HoldingLoading key={i} />
      ))}
    </Root>
    <FooterLoading />
  </>
);

export default HoldingsTableLoading;
