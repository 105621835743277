import _ from 'lodash';
import { compile } from 'path-to-regexp';
import queryString from 'query-string';

type NoParams = undefined;
type Params = Record<string, unknown>;
type OptionalParams = Params | NoParams;

// eslint-disable-next-line
export type RouteParams<P extends OptionalParams> = (P extends NoParams ? {} : P) & {
  queryParams?: {
    [key: string]: any;
  };
};

export type Route<P extends OptionalParams> = {
  (...params: P extends NoParams ? [RouteParams<P>?] : [RouteParams<P>]): string;
  /** Original template url */
  path: string;
};

export type GetRouteParams<R extends Route<any>> = R extends Route<infer P> ? P : never;

type StringRouteOrIdentity<T> = T extends string ? Route<NoParams> : T;

type SubRoutes<T extends Record<string, unknown>> = {
  [name in keyof T]: StringRouteOrIdentity<T[name]>;
};

const formatQueryString = <P extends OptionalParams>(options?: RouteParams<P>) => {
  if (options?.queryParams) {
    return `?${queryString.stringify(options.queryParams)}`;
  }
  return '';
};

export const withParams = <P extends OptionalParams = NoParams>(path: string): Route<P> => {
  const formatPath = compile(path);
  const formatUrl = (options?: RouteParams<P>) => {
    const params = _.mapValues(options, (value) => {
      // Matches behavior in useParams()
      if (value === undefined) {
        return 'undefined';
      } else if (value === null) {
        return 'null';
      }
      return value;
    });
    return formatPath(params) + formatQueryString(options);
  };
  formatUrl.path = path;
  return formatUrl;
};

export const withSubRoutes = <
  T extends Record<string, unknown>,
  P extends OptionalParams = NoParams,
>(
  base: string | Route<P>,
  subRoutes: T,
) => {
  const root: Record<string, any> & Route<P> = _.isString(base) ? withParams(base) : base;

  _.forEach(subRoutes, (value, key) => {
    if (_.isString(value)) {
      root[key] = withParams(value);
    } else {
      root[key] = value;
    }
  });

  return root as Route<P> & SubRoutes<T>;
};
