import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import TextAreaInput from 'components/lib/form/TextAreaInput';
import TextField from 'components/lib/form/TextField';
import CopyToClipboardButton from 'components/lib/ui/CopyToClipboardButton';
import Divider from 'components/lib/ui/Divider';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';

import { UPDATE_SPONSORSHIP_INVITE_MUTATION } from 'common/lib/graphQl/advisorPortal';

import type {
  UpdateSponsorshipInvite,
  UpdateSponsorshipInviteVariables,
} from 'common/generated/graphQlTypes/UpdateSponsorshipInvite';

type FormValues = {
  id?: string;
  label?: string | null;
  sentToEmail?: string | null;
};

type Props = {
  templateMessage?: string | null;
  initialValues?: FormValues;
};

const FormContent = styled.div`
  padding: ${({ theme }) => `${theme.spacing.default} ${theme.spacing.xlarge}`};
`;

const StyledTextAreaInput = styled(TextAreaInput)`
  width: 100%;
  background: ${(props) => props.theme.color.grayBackground};
  font-size: ${(props) => props.theme.fontSize.small};
  box-sizing: border-box;
  pointer-events: none;
`;

const FieldContainer = styled.div`
  > :first-child {
    margin-bottom: ${(props) => props.theme.spacing.xxxsmall};
  }
  margin-bottom: ${(props) => props.theme.spacing.small};
`;

const SendCodeToClientContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${(props) => props.theme.spacing.small};
  padding-top: ${(props) => props.theme.spacing.xsmall};
  padding-bottom: ${(props) => props.theme.spacing.small};

  > :last-child {
    padding-top: ${(props) => props.theme.spacing.xxsmall};
  }
`;

const ActionsRow = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.default};
`;

const SendInviteModal = ({ templateMessage, initialValues }: Props) => {
  const [updateInvite] = useMutation<UpdateSponsorshipInvite, UpdateSponsorshipInviteVariables>(
    UPDATE_SPONSORSHIP_INVITE_MUTATION,
  );

  const [message, setMessage] = useState<string>(templateMessage ?? '');

  useEffect(() => {
    setMessage(templateMessage ?? '');
  }, [templateMessage]);

  return (
    <ModalCard title="Edit client">
      <Form initialValues={initialValues} submitOnBlur mutation={updateInvite}>
        <FormContent>
          <FieldContainer>
            <TextField name="label" label="Name" placeholder="Enter a name..." />
            <Text size="small" color="textLight">
              This is just for your reference to keep track of who is receiving this code
            </Text>
          </FieldContainer>
          <FieldContainer>
            <TextField name="sentToEmail" label="Email" disabled placeholder="Email..." />
          </FieldContainer>
          <Divider text="" />
          <SendCodeToClientContainer>
            <Text weight="medium" size="large">
              Send code to client
            </Text>
            <Text size="small">
              We recommend you send your potential clients invite codes from your own email address
              as well. You can copy the template below.
            </Text>
            <ActionsRow>
              <CopyToClipboardButton messageToCopy={message} />
            </ActionsRow>
          </SendCodeToClientContainer>
          <StyledTextAreaInput
            value={message}
            onChange={({ target: { value } }) => setMessage(value)}
            hideResizeHandle
            readOnly
          />
        </FormContent>
      </Form>
    </ModalCard>
  );
};

export default SendInviteModal;
