import { useRef, useCallback } from 'react';

/**
 * Creates a constant reference for the given function.
 * Always returns the same function.
 *
 * @remarks
 *
 * `useCallback` closes over the deps at the time they're passed in, whereas `useStaticCallback`
 * always calls the latest callback. This is generally a good thing, but it's worth noting that it
 * could result in a race condition.
 *
 * This is useful if you want to preserve shallow prop comparison and `useCallback` is invalidating
 * function references too frequently.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStaticCallback = <T extends (...args: any[]) => any>(callback: T): T => {
  const cb = useRef<T>(callback);
  cb.current = callback;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useCallback((...args: any[]) => cb.current(...args), []) as T;
};

export default useStaticCallback;
