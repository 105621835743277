import { useMutation } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import RadioField from 'components/lib/form/RadioField';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import { setManageFinancesWithPartner, setTemporaryPromoCode } from 'actions';
import { throwIfHasMutationErrors } from 'common/lib/form/errors';
import { GET_ME, UPDATE_USER_PROFILE_MUTATION } from 'common/lib/graphQl/user';
import useQuery from 'common/lib/hooks/useQuery';
import { getCollaboratesOnFinancesValueFromAnswers } from 'common/lib/onboarding/Adapters';
import { demographicsValidationSchema } from 'common/lib/signup/SignupValidation';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import { getTemporaryPromoCode } from 'selectors';

import { ONBOARDING } from 'common/constants/copy';
import { COUNTRY_OPTIONS, DEFAULT_COUNTRY } from 'common/constants/onboarding/user';

import type {
  UpdateMeInput,
  UpdateMyHouseholdInput,
  UpdateUserProfileInput,
} from 'common/generated/graphql';

const SELECT_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const StyledOnboardingTitle = styled(OnboardingTitle)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: 58px;
  }
`;

const FormContainer = styled(OnboardingFormContainer)`
  padding-top: 58px;
`;

const SecondarySubmitButton = styled(FormSubmitButton).attrs({ variant: 'primary' })`
  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: 58px;
  }
`;

type Props = {
  next: () => void;
};
const WELCOME30_PROMO_CODE = 'WELCOME30';

const OnboardingDemographics = ({ next }: Props) => {
  const dispatch = useDispatch();

  const temporaryPromoCode = useSelector(getTemporaryPromoCode);
  // Checking for ab-test-goal-oriented-signup so it applies the WELCOME30 coupon before
  // the user sees the paywall
  const isGoalOrientedSignUpTestOn = useIsFeatureFlagOn('ab-test-goal-oriented-signup', {
    trackImpression: true,
  });

  if (isGoalOrientedSignUpTestOn && !temporaryPromoCode) {
    // sets the WELCOME30 promo code if the user has not applied another promo code
    // and the goal-oriented signup test is not off
    dispatch(setTemporaryPromoCode(WELCOME30_PROMO_CODE));
  }

  const { data, isLoadingInitialData } = useQuery(GET_ME);
  const { name } = data?.me ?? {};

  const [mutate] = useMutation(UPDATE_USER_PROFILE_MUTATION);

  /**
   *  Autofocus doesn't work with fields that have a "disabled" property.
   *
   *  To focus on the field when the page loads, we need to use a ref and useEffect based on what disable/enables the field.
   */
  const nameInputElementRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (nameInputElementRef.current) {
      nameInputElementRef.current.focus();
    }
  }, [isLoadingInitialData]);

  return (
    <>
      <StyledOnboardingTitle>{ONBOARDING.DEMOGRAPHICS.TITLE}</StyledOnboardingTitle>
      <OnboardingSubtitle>{ONBOARDING.DEMOGRAPHICS.SUBTITLE}</OnboardingSubtitle>
      <FormContainer>
        <Form
          onSubmit={async ({
            name,
            manageWithPartner,
            manageWithProfessional,
            country,
          }: {
            name: string;
            manageWithPartner: { value: boolean };
            manageWithProfessional: { value: boolean };
            country: string;
          }) => {
            const { data } = await mutate({
              variables: {
                updateProfileInput: {
                  collaboratesOnFinancesDetailed: getCollaboratesOnFinancesValueFromAnswers(
                    manageWithPartner.value,
                    manageWithProfessional.value,
                  ),
                } as UpdateUserProfileInput,
                updateMeInput: { name } as UpdateMeInput,
                updateMyHouseholdInput: {
                  country,
                } as UpdateMyHouseholdInput,
              },
            });

            dispatch(setManageFinancesWithPartner(manageWithPartner.value));

            throwIfHasMutationErrors(data);
            next();
          }}
          overrideValidationSchema={demographicsValidationSchema}
          initialValues={{ name, country: DEFAULT_COUNTRY }}
        >
          <TextField
            name="name"
            label={ONBOARDING.DEMOGRAPHICS.FIELDS.NAME.LABEL}
            placeholder={ONBOARDING.DEMOGRAPHICS.FIELDS.NAME.PLACEHOLDER}
            disabled={isLoadingInitialData}
            innerRef={nameInputElementRef}
            autoFocus
            autoFocusOnMobile={false}
            required
          />
          <SelectField
            name="country"
            label={ONBOARDING.DEMOGRAPHICS.FIELDS.COUNTRY.LABEL}
            options={COUNTRY_OPTIONS}
          />
          <RadioField
            options={SELECT_OPTIONS}
            name="manageWithPartner"
            label={ONBOARDING.DEMOGRAPHICS.FIELDS.MANAGE_WITH_PARTNER.LABEL}
            required
          />
          <RadioField
            options={SELECT_OPTIONS}
            name="manageWithProfessional"
            label={ONBOARDING.DEMOGRAPHICS.FIELDS.MANAGE_WITH_PROFESSIONAL.LABEL}
            required
          />
          <SecondarySubmitButton size="medium">
            {ONBOARDING.DEMOGRAPHICS.BUTTON}
          </SecondarySubmitButton>
        </Form>
      </FormContainer>
    </>
  );
};

export default OnboardingDemographics;
