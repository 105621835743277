import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import type { LimitByEntitlement, SubscriptionEntitlement } from 'common/constants/premium';

/** Returns true when:
 * - the required entitlements list is empty, meaning it is available to all users
 * - the user has an active entitlement matching one of the required entitlements
 */
export const getHasAccess = (
  validEntitlements: SubscriptionEntitlement[],
  activeEntitlements: SubscriptionEntitlement[],
) =>
  validEntitlements.length === 0 ||
  R.intersection(validEntitlements, activeEntitlements).length > 0;

/** Returns the active limit for a user's entitlement level. */
export const getActiveLimit = (
  limitConfig: LimitByEntitlement | undefined,
  activeEntitlements: SubscriptionEntitlement[],
): number | null => {
  // Since the user can have multiple active entitlements, we find all of the valid limits
  // and then return the highest one.
  const limits = activeEntitlements.map((entitlement) => limitConfig?.[entitlement] ?? null);

  if (limits.length === 0) {
    // If the user has no entitlements, return null
    return null;
  } else if (limits.includes(null)) {
    // Treat null as highest possible value
    return null;
  }

  // @ts-ignore - TS doesn't know that limits can't include null here
  return Math.max(...limits);
};

export const getMeetsOrExceedsLimit = (limit: number | null, currentValue: number) =>
  RA.isNotNil(limit) && currentValue >= limit;
