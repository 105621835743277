import { login, logout, fetchUser, setTokenExpiration, updateUser } from 'actions';
import getUserApi from 'common/lib/api/user';
import type { UpdateUserParams } from 'common/state/user/reducer';
import api from 'lib/api';
import type { Dispatch, GetState } from 'state/types';

type RequestLoginResult = 'LOGGED_IN' | 'MFA_REQUIRED' | 'CAPTCHA_REQUIRED';

export const requestLogin =
  (payload: {
    username: string;
    password: string;
    totp?: string;
    recovery_code?: string;
    captcha_token?: string;
  }) =>
  async (dispatch: Dispatch, _: GetState): Promise<RequestLoginResult> => {
    try {
      const response = await getUserApi(api).login({ ...payload, supports_mfa: true });
      dispatch(login(response));
      return 'LOGGED_IN';
    } catch (error: any) {
      if (error.data?.error_code === 'MFA_REQUIRED') {
        return 'MFA_REQUIRED';
      }
      if (error.data?.error_code === 'CAPTCHA_REQUIRED') {
        return 'CAPTCHA_REQUIRED';
      }
      throw error; // Rethrow for generic error handling
    }
  };

export const sendForgotPasswordEmailAction =
  (payload: { email: string }) => (dispatch: Dispatch, _: GetState) =>
    getUserApi(api).sendForgotPasswordEmail(payload);

export const sendCreatePasswordEmailAction = () => (dispatch: Dispatch, _: GetState) =>
  getUserApi(api).sendCreatePasswordEmail();

export const resetPasswordAction =
  (payload: { token: string; new_password: string }) => async (dispatch: Dispatch, _: GetState) => {
    await getUserApi(api).resetPassword(payload);
  };

export const changePasswordAction =
  (payload: { current_password: string; new_password: string }) =>
  async (dispatch: Dispatch, _: GetState) => {
    const response = await getUserApi(api).changePassword(payload);
    dispatch(login(response));
  };

export const logoutAction = () => async (dispatch: Dispatch) => {
  try {
    await getUserApi(api).logout();
  } catch (_) {
    // It's possible that the logout API call itself fails if the token has
    // expired or is expiring right now.
  }

  dispatch(logout());
};

export const fetchUserAction = () => async (dispatch: Dispatch) => {
  const response = await getUserApi(api).getUser();
  dispatch(fetchUser(response));
};

export const requestExtendToken = () => async (dispatch: Dispatch) => {
  const response = await getUserApi(api).extendToken();
  dispatch(setTokenExpiration(response.tokenExpiration));
};

export const updateUserAction =
  (payload: UpdateUserParams) => async (dispatch: Dispatch, _: GetState) => {
    const response = await getUserApi(api).updateUser(payload);
    dispatch(updateUser(response));
  };
