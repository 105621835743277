import React from 'react';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import { Description } from 'components/lib/ui/CardHeader';

import { formatFullDate, parseISODate } from 'common/utils/date';

type Props = {
  deactivatedAt: GraphQlDate;
};

const StyledCard = styled(Card)`
  ${Description} {
    color: ${({ theme }) => theme.color.grayDark};
  }
`;

const ClosedAccountCard = ({ deactivatedAt }: Props) => {
  const formattedDate = formatFullDate(parseISODate(deactivatedAt));

  return (
    <StyledCard
      title="Closed Account"
      description={`This account was closed on ${formattedDate}`}
      hideBottomBorder
    />
  );
};

export default ClosedAccountCard;
