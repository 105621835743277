import React from 'react';
import styled, { css } from 'styled-components';

import Markdown from 'components/lib/ui/Markdown';
import RelativeDate from 'components/lib/ui/RelativeDate';

const Root = styled.div<{ $read: boolean; $hover: boolean }>`
  display: flex;
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme, $read }) => ($read ? theme.color.textLight : theme.color.text)};

  transition: ${({ theme }) => theme.transition.default};
  ${({ $hover }) =>
    $hover &&
    css`
      cursor: pointer;
      :hover {
        background: ${({ theme }) => theme.color.grayBackground};
      }
    `}
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const TimestampContainer = styled.div`
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  padding-top: ${({ theme }) => theme.spacing.xxxsmall};
  margin-left: ${({ theme }) => theme.spacing.default};
  line-height: 150%;
`;

const Title = styled(Markdown)`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Description = styled(Markdown)`
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
  line-height: 150%;
`;

export type NotificationProps = {
  title?: string;
  date: string;
  read: boolean;
  body?: string;
  onClick?: () => void;
  onClickContent?: () => void;
  className?: string;
};

type BaseNotificationProps = {
  children?: React.ReactNode;
  icon: React.ReactNode;
  hover?: boolean;
} & NotificationProps;

const BaseNotification = ({
  children,
  title,
  body,
  icon,
  date,
  read,
  className,
  onClick,
  onClickContent,
  hover = true,
}: BaseNotificationProps) => (
  <Root
    className={className}
    $hover={hover}
    $read={read}
    role="button"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={(event) => {
      if (event.key === 'Enter') {
        onClick?.();
      }
    }}
  >
    {icon}
    <ContentContainer>
      <div
        onClick={onClickContent}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onClickContent?.();
          }
        }}
        role="button"
        tabIndex={onClickContent ? 0 : undefined}
      >
        {!!title && <Title source={title} />}
        {!!body && <Description source={body} />}
      </div>
      {children}
    </ContentContainer>
    <TimestampContainer>
      <RelativeDate date={date} short />
    </TimestampContainer>
  </Root>
);

export default BaseNotification;
