import React from 'react';

import CurrencyField from 'components/lib/form/CurrencyField';
import Form from 'components/lib/form/Form';

import useUpdateGoal from 'common/lib/hooks/goalsV2/useUpdateGoal';
import { formatCurrencyNoCents } from 'common/utils/Currency';

type Props = {
  goal: {
    __typename: 'GoalV2';
    id: string;
    name: string;
    plannedMonthlyContribution?: number | null;
    plannedMonthlyPretaxContribution?: number | null;
  };
  valueKey: 'plannedMonthlyContribution' | 'plannedMonthlyPretaxContribution';
};

const GoalMonthlyBudgetInput = ({ goal, valueKey }: Props) => {
  const { updateGoal } = useUpdateGoal(goal);

  return (
    <Form
      submitOnBlur
      onSubmit={(values) => updateGoal({ [valueKey]: values[valueKey] ?? null })}
      initialValues={{
        [valueKey]: goal[valueKey] ?? undefined,
      }}
    >
      <CurrencyField name={valueKey} hideLabel placeholder={formatCurrencyNoCents(0)} />
    </Form>
  );
};

export default GoalMonthlyBudgetInput;
