import { gql } from '@apollo/client';
import React from 'react';
import deepIsEqual from 'react-fast-compare';

import AssistantMessageListGroup from 'components/assistant/AssistantMessageListGroup';
import UserMessageListGroup from 'components/assistant/UserMessageListGroup';

import { memo } from 'common/utils/React';

import type { MessageListGroupFields } from 'common/generated/graphQlTypes/MessageListGroupFields';

const MESSAGE_TYPES: {
  [key in MessageListGroupFields['__typename']]: React.ComponentType<{
    messages: any;
    nextGroup?: any;
    demo?: boolean;
    className?: string;
  }>;
} = {
  UserMessage: UserMessageListGroup,
  AssistantMessage: AssistantMessageListGroup,
  AssistantStatusMessage: AssistantMessageListGroup,
};

type Props = {
  messages: MessageListGroupFields[];
  nextGroup?: MessageListGroupFields[];
  demo?: boolean;
  className?: string;
  children?: React.ReactNode;
};

const MessageListGroup = ({ messages, nextGroup, demo, className, children }: Props) => {
  const Component = MESSAGE_TYPES[messages[0].__typename];

  if (!Component) {
    // TODO: placeholder for unsupported message types?
    return null;
  }

  return (
    // @ts-ignore: [REACT-NATIVE-UPGRADE]
    <Component messages={messages} nextGroup={nextGroup} demo={demo} className={className}>
      {children}
    </Component>
  );
};

MessageListGroup.fragments = {
  MessageListGroupFields: gql`
    fragment MessageListGroupFields on Message {
      ...UserMessageListGroupFields
      ...AssistantMessageListGroupFields
      ...AssistantStatusMessageListGroupFields
    }
    ${UserMessageListGroup.fragments.UserMessageListGroupFields}
    ${AssistantMessageListGroup.fragments.AssistantMessageListGroupFields}
    ${AssistantMessageListGroup.fragments.AssistantStatusMessageListGroupFields}
  `,
};

export default memo(MessageListGroup, deepIsEqual);
