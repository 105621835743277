import * as Sentry from '@sentry/browser';

import isEnvProduction from 'lib/isEnvProduction';

import { externalUrls } from 'constants/routes';

const IGNORE_ERRORS = [
  // Expected when user session times out
  '{"detail":"Invalid token."}',
  '{"detail":"Authentication credentials were not provided."}',
  '{"detail":"Unauthorized access"}',

  'ResizeObserver loop limit exceeded',

  'window.analytics.load is not a function',

  // Apollo errors that aren't user facing, they just print to console
  'Invariant Violation: 17',
  `Cannot read property 'refetch' of undefined`,
  // Apollo error when user has no network connection, nothing we can do
  'Network error: Failed to fetch',
  'TypeError: Failed to fetch',
  'NetworkError when attempting to fetch resource',
  // t - No error message
  'No error message',
  // Not really a bug - https://github.com/juggle/resize-observer/blob/master/README.md#resize-loop-detection
  'ResizeObserver loop completed with undelivered notifications',
  "Can't find variable: ResizeObserver",
  // We treat this error on frontend
  'HOUSEHOLD_INVITE_USER_ALREADY_EXISTS',
];

const initializeSentry = () => {
  if (isEnvProduction()) {
    const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT;
    const dsn = process.env.REACT_APP_SENTRY_DSN;
    const release = process.env.HEROKU_RELEASE_VERSION;
    Sentry.init({
      dsn,
      environment,
      whitelistUrls: [externalUrls.webApp, externalUrls.webAppStaging],
      ignoreErrors: IGNORE_ERRORS,
      release,
    });
  }
};

export default initializeSentry;
