import React from 'react';
import styled from 'styled-components';

import MessageListGroupBase, { AVATAR_SIZE_PX } from 'components/assistant/MessageListGroupBase';
import MarkCircle from 'components/brand/MarkCircle';
import FlexContainer from 'components/lib/ui/FlexContainer';
import BouncingDots from 'components/lib/ui/loading/BouncingDots';

const StyledMessageListGroupBase = styled(MessageListGroupBase)`
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const DotsContainer = styled(FlexContainer).attrs({ alignCenter: true })`
  height: ${AVATAR_SIZE_PX}px;
`;

type Props = {
  className?: string;
};

const AssistantLoadingMessage = ({ className }: Props) => (
  <StyledMessageListGroupBase
    avatar={<MarkCircle size={AVATAR_SIZE_PX} padding={AVATAR_SIZE_PX / 4} />}
    className={className}
  >
    <DotsContainer>
      <BouncingDots />
    </DotsContainer>
  </StyledMessageListGroupBase>
);

export default AssistantLoadingMessage;
