import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import CurrencyField from 'components/lib/form/CurrencyField';
import DateField from 'components/lib/form/DateField';
import FieldCell from 'components/lib/form/FieldCell';
import { Label } from 'components/lib/form/FormItemContainer';
import SelectField from 'components/lib/form/SelectField';
import ToggleField from 'components/lib/form/ToggleField';
import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';

import {
  getFrequencyOptions,
  RECURRING_STATUS_OPTIONS,
  RECURRING_TYPE_OPTIONS,
} from 'common/lib/recurring';

import { externalUrls } from 'constants/routes';

const Root = styled.div`
  ${Label} {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const StyledFieldCell = styled(FieldCell)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const MerchantRecurringFields = () => {
  const { values } = useContext(FormContext);
  const { isRecurring } = values;

  const frequencyOptions = useMemo(() => getFrequencyOptions(), []);

  return (
    <Root>
      <StyledFieldCell
        title="Mark this merchant as recurring"
        subtitle={
          <span>
            This merchant will show on the Recurring section with expected upcoming transactions.{' '}
            <Link
              href={externalUrls.recurringTransactions}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </Link>
          </span>
        }
        rightAccessory={<ToggleField name="isRecurring" hideLabel />}
      >
        {isRecurring ? (
          <>
            <SelectField
              name="frequency"
              label="Recurring frequency"
              required={isRecurring}
              options={frequencyOptions}
            />

            <SelectField
              name="type"
              label="Recurring type"
              required={isRecurring}
              options={RECURRING_TYPE_OPTIONS}
            />

            <DateField
              displayFormat="MMMM D, yyyy"
              name="baseDate"
              label="Starting date"
              placeholder="Select a date..."
              required={isRecurring}
              description="This date is used to forecast future transactions at this merchant"
            />

            <CurrencyField
              name="amount"
              placeholder="$0.00"
              autoComplete="off"
              maskOptions={{ allowDecimal: true }}
              required={isRecurring}
              {...maskClassProps}
            />

            <SelectField
              name="isActive"
              label="Recurring status"
              required={isRecurring}
              options={RECURRING_STATUS_OPTIONS}
              description={
                <span>
                  If you set the status to <Text weight="medium">Canceled</Text>, we&apos;ll show
                  past transactions on the recurring calendar, but won&apos;t forecast any future
                  transactions.
                </span>
              }
            />
          </>
        ) : null}
      </StyledFieldCell>
    </Root>
  );
};

export default MerchantRecurringFields;
