import { useMutation, gql } from '@apollo/client';
import type { GraphQlMutationError } from 'common/errors';
import * as React from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Modal from 'components/lib/ui/Modal';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import useToggle from 'common/lib/hooks/useToggle';

import type { InviteEmail, InviteEmailVariables } from 'common/generated/graphQlTypes/InviteEmail';
import { ErrorCode } from 'common/generated/graphQlTypes/globalTypes';

type Props = {
  onClose: () => void;
  onInviteSuccess: () => void;
};

const WarningContainer = styled(Banner).attrs({ type: 'warning' })`
  display: flex;
  align-items: center;
`;

const ErrorContainer = styled(Banner).attrs({ type: 'error' })`
  display: flex;
  align-items: center;
`;

const InfoIcon = styled(Icon).attrs({ name: 'info' })`
  width: 32px;
  height: 32px;
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const CheckIcon = styled(Icon).attrs({ name: 'check' })`
  color: ${({ theme }) => theme.color.textWhite};
  width: 30px;
  height: 30px;
`;

const LineBreak = styled.hr`
  margin: ${({ theme }) => theme.spacing.xlarge} 0;
  width: 100%;
  height: 1px;
  color: ${({ theme }) => theme.color.grayBackground};
  background-color: ${({ theme }) => theme.color.grayBackground};
  border: none;
`;

const SuccessCard = styled(ModalCard)`
  width: 446px;
  margin: auto;
`;

const CheckContainer = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.color.green};
  width: 56px;
  height: 56px;
  border-radius: 100%;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const OkayButton = styled(PrimaryButton)`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const SecondaryText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
  width: 312px;
`;

/* This is temporarily being used in the OnboardingInvitePartner
   so we can a/b test inviting a partner during the onboarding flow

   I'll move this once we're done with the experiment if we decide to move forward with it
*/
export const INVITE_PARTNER_MUTATION = gql`
  mutation Web_InviteEmail($input: InviteUserToHouseholdMutationInput!) {
    inviteUserToHousehold(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      invite {
        id
        invitedEmail
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

const InviteMemberModal = ({ onClose, onInviteSuccess }: Props) => {
  const [inviteEmail] = useMutation<InviteEmail, InviteEmailVariables>(INVITE_PARTNER_MUTATION);
  const [isSuccess, { setOn: success }] = useToggle(false);
  const [isInviteExistsBannerShown, { setOn: setInviteExistsBannerShown }] = useToggle(false);

  return (
    <Modal onClose={onClose}>
      <Form
        mutation={inviteEmail}
        onSubmitSuccess={() => {
          onInviteSuccess();
          success();
        }}
        onError={({ errors: { code } }: GraphQlMutationError) => {
          if (code === ErrorCode.HOUSEHOLD_INVITE_USER_ALREADY_EXISTS) {
            setInviteExistsBannerShown();
          }
        }}
      >
        {!isSuccess ? (
          <ModalCard title="Invite Member to Household">
            <FlexContainer padding="large" column>
              {!isInviteExistsBannerShown ? (
                <WarningContainer>
                  <InfoIcon />
                  <span>
                    <Text size="small" weight="medium">
                      {'IMPORTANT: '}
                    </Text>
                    <Text size="small">
                      {"Members you invite will have full access to your household's data. You " +
                        'should only invite people you fully trust.'}
                    </Text>
                  </span>
                </WarningContainer>
              ) : (
                <ErrorContainer>
                  <Text size="small">
                    {"This user already has a Monarch account. They'll have to delete their existing" +
                      ' household before they can join yours and collaborate.'}
                  </Text>
                </ErrorContainer>
              )}
              <LineBreak />
              <TextField name="email" label="Invite via Email Address" email required />
              <FormSubmitButton>Send Invite</FormSubmitButton>
            </FlexContainer>
          </ModalCard>
        ) : (
          <SuccessCard>
            <FormContext.Consumer>
              {({ values }) => (
                <FlexContainer column center>
                  <CheckContainer center>
                    <CheckIcon />
                  </CheckContainer>
                  <span>
                    <Text weight="bold">{'Invite sent to '}</Text>
                    <Text color="orange">{values.email}</Text>
                  </span>
                  <SecondaryText size="small" color="textLight" align="center">
                    {"We'll notify you when they accept their invite and get access to your" +
                      ' household in Monarch'}
                  </SecondaryText>
                  <OkayButton onClick={onClose}>Okay</OkayButton>
                </FlexContainer>
              )}
            </FormContext.Consumer>
          </SuccessCard>
        )}
      </Form>
    </Modal>
  );
};

export default InviteMemberModal;
