import { camelCase } from 'change-case';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { isValidTab } from 'state/reports/types';

/**
 * Extracts the last segment of the URL pathname, transforms it into camel case,
 * and checks whether it represents a valid reports tab. If not, defaults to 'spending'.
 *
 * @returns {string} The current tab in camel case form. Defaults to 'spending' if the
 *                   pathname does not correspond to a valid tab.
 */
const useReportsCurrentTab = () => {
  const { pathname } = useLocation();

  const tab = useMemo(() => {
    const [lastSegment] = pathname.split('/').slice(-1);
    const camelCasedLastSegment = camelCase(lastSegment);

    if (!isValidTab(camelCasedLastSegment)) {
      return 'spending'; // Default to spending
    }

    return camelCasedLastSegment;
  }, [pathname]);

  return tab;
};

export default useReportsCurrentTab;
