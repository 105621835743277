import pluralize from 'pluralize';
import * as R from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import DashboardWidget from 'components/lib/ui/DashboardWidget';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import NeedsReviewEmpty from 'components/transactions/NeedsReviewEmpty';
import TransactionsList from 'components/transactions/TransactionsList';
import ReviewSelect from 'components/transactions/review/ReviewSelect';

import useDashboardConfig from 'common/lib/hooks/dashboard/useDashboardConfig';
import { TransactionsDropdownFilter } from 'common/lib/transactions/review';
import { DashboardWidgetName } from 'common/state/dashboard/types';
import { formatThousands } from 'common/utils/Number';
import type { TransactionsListContextType } from 'lib/contexts/TransactionsListContext';
import TransactionsListContext from 'lib/contexts/TransactionsListContext';
import useTransactionDrawerState from 'lib/hooks/transactions/useTransactionDrawerState';
import useIsV2Theme from 'lib/hooks/useIsV2Theme';
import useMockDataWhenNoAccountsQuery from 'lib/hooks/useMockDataWhenNoAccountsQuery';

import routes from 'constants/routes';

import { gql as newGql } from 'common/generated/gql';
import type { GetTransactionsListDashboardQueryVariables } from 'common/generated/graphql';

const TRANSACTION_COUNT = 5;

const CenteringContainer = styled(Flex)`
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.large};
`;

const TransactionsFooter = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const StyledSelect = styled(ReviewSelect)`
  min-width: 200px;
`;

const FullWidthDefaultButton = styled(DefaultButton)`
  width: 100%;
`;

const TransactionsDashboardWidget = () => {
  const history = useHistory();
  const isV2Theme = useIsV2Theme();

  const [dashboardConfig, setDashboardConfig] = useDashboardConfig('web');
  const filter =
    dashboardConfig.widgets[DashboardWidgetName.TRANSACTIONS].filter ??
    TransactionsDropdownFilter.All;

  const isReviewModeActive = filter !== TransactionsDropdownFilter.All;

  const getFilters = useCallback(() => {
    if (!isReviewModeActive) {
      return {
        hideFromReports: false,
      };
    }

    // needs review by enabled
    return {
      needsReview: true,
      needsReviewByUser: filter === TransactionsDropdownFilter.NeedsReview ? null : filter,
      needsReviewUnassigned: filter === TransactionsDropdownFilter.NeedsReview ? true : undefined,
    };
  }, [isReviewModeActive, filter]);

  const getParamsForTransactionList = useCallback(() => {
    if (!isReviewModeActive) {
      return undefined;
    }

    return {
      needsReview: true,
      needsReviewByUser: filter === TransactionsDropdownFilter.NeedsReview ? null : filter,
      needsReviewUnassigned: filter === TransactionsDropdownFilter.NeedsReview ? true : undefined,
    };
  }, [isReviewModeActive, filter]);

  const variables: GetTransactionsListDashboardQueryVariables = useMemo(
    () => ({
      // This is needed so we always show the next transaction
      // on the list when the user is in transaction review mode
      offset: undefined,
      limit: TRANSACTION_COUNT + 1,
      filters: getFilters() as GetTransactionsListDashboardQueryVariables['filters'],
    }),
    [filter],
  );

  const {
    data: transactionsData,
    isLoadingInitialData: loadingTransactions,
    refetch: refetchTransactions,
  } = useMockDataWhenNoAccountsQuery(QUERY, { variables });
  const totalCount = isReviewModeActive
    ? transactionsData?.allTransactions.totalCount // this can include hidden transactions
    : R.pathOr(0, ['aggregates', 0, 'summary', 'count'], transactionsData); // this does not include hidden transactions
  const currentRuleCount = transactionsData?.transactionRules?.length;

  const [
    drawerTransactionId,
    { setDrawerTransactionId, previousTransactionId, nextTransactionId },
  ] = useTransactionDrawerState(transactionsData);

  const context: TransactionsListContextType = useMemo(
    () => ({
      currentRuleCount,
      queryVariables: variables,
      showAccountColumn: false,
      refetchAll: refetchTransactions,
    }),
    [currentRuleCount, variables, refetchTransactions],
  );

  const countWithZero = totalCount ?? 0;

  const transactionsRouteLink = routes.transactions({
    queryParams: getParamsForTransactionList(),
  });

  const needReviewTitle = `${formatThousands(countWithZero)} need${countWithZero === 1 ? 's' : ''} review`;

  return (
    <DashboardWidget
      title="Transactions"
      description={filter === TransactionsDropdownFilter.All ? 'Most recent' : needReviewTitle}
      loading={loadingTransactions}
      headerLink={transactionsRouteLink}
      headerRight={
        <FlexContainer>
          <StyledSelect
            value={filter}
            onChange={({ value }: { value: string }) => {
              setDashboardConfig(
                R.mergeDeepLeft(
                  {
                    widgets: {
                      [DashboardWidgetName.TRANSACTIONS]: {
                        filter: value,
                      },
                    },
                  },
                  dashboardConfig,
                ),
                true,
              );
            }}
          />
        </FlexContainer>
      }
    >
      <Switch>
        <Case when={!transactionsData}>
          <CenteringContainer>There was an error loading your transactions</CenteringContainer>
        </Case>
        <Case when={totalCount === 0 && isReviewModeActive}>
          <NeedsReviewEmpty onClickViewAll={() => history.push(routes.transactions())} />
        </Case>
        <Case when={totalCount === 0}>
          <CenteringContainer>You have no transactions yet.</CenteringContainer>
        </Case>
        <Case default>
          <>
            <TransactionsListContext.Provider value={context}>
              <TransactionsList
                sections={[
                  {
                    data:
                      transactionsData?.allTransactions.results.slice(0, TRANSACTION_COUNT) || [],
                    key: '',
                    date: '',
                  },
                ]}
                showSectionHeaders={false}
                drawerTransactionId={drawerTransactionId}
                previousTransactionId={previousTransactionId}
                nextTransactionId={nextTransactionId}
                setDrawerTransactionId={setDrawerTransactionId}
                onDrawerClose={() => {
                  setDrawerTransactionId(null);
                  refetchTransactions();
                }}
                isReviewModeActive={isReviewModeActive}
                hideHeader
              />
            </TransactionsListContext.Provider>
            {!isV2Theme && (
              <TransactionsFooter center>
                <FullWidthDefaultButton linkTo={transactionsRouteLink}>
                  {[
                    'View',
                    countWithZero > 1 && 'all',
                    formatThousands(countWithZero),
                    pluralize('transaction', countWithZero),
                  ]
                    .filter(Boolean)
                    .join(' ')}
                </FullWidthDefaultButton>
              </TransactionsFooter>
            )}
          </>
        </Case>
      </Switch>
    </DashboardWidget>
  );
};

const QUERY = newGql(/* GraphQL */ `
  query GetTransactionsListDashboard($offset: Int, $limit: Int, $filters: TransactionFilterInput) {
    allTransactions(filters: $filters) {
      totalCount
      totalSelectableCount
      results(offset: $offset, limit: $limit) {
        id
        ...TransactionsListFields
      }
    }
    transactionRules {
      id
    }
    aggregates(filters: $filters) {
      summary {
        count
      }
    }
  }
`);

export default TransactionsDashboardWidget;
