import { gql as newGql } from 'common/generated/gql';

export const CATEGORY_FIELDS_FRAGMENT = newGql(/* GraphQL */ `
  fragment CategoryFields on Category {
    id
    order
    name
    icon
    systemCategory
    isSystemCategory
    isDisabled
    group {
      id
      name
      type
    }
  }
`);

export const GET_CATEGORIES = newGql(/* GraphQL */ `
  query GetCategories {
    categories {
      ...CategoryFields
    }
  }
`);
