import { rgba } from 'polished';
import type { DefaultTheme } from 'styled-components';

import type { StyleMixin } from 'common/types/Styles';

export const FIELD_SIDE_PADDING = '12px';

const getPadding = (small?: boolean, large?: boolean) => {
  // Note that border adds 1px to this, so this is purposefully 1px off of Figma
  let value = '7px';
  if (small) {
    value = '5px';
  }
  if (large) {
    value = '9.5px';
  }
  return value;
};

const getFontSize = (theme: DefaultTheme, small?: boolean, large?: boolean) => {
  let value: string = theme.fontSize.base;
  if (large) {
    value = theme.fontSize.large;
  }
  return value;
};

const fieldStyleMixin: StyleMixin<{ small?: boolean; large?: boolean; dynamic?: boolean }> = ({
  theme,
  small,
  large,
  dynamic = true,
}) => `
  padding: ${getPadding(small, large)} ${large ? theme.spacing.large : FIELD_SIDE_PADDING};
  border: 1px solid ${theme.color.grayFocus};
  border-radius: ${theme.radius.small};
  transition: ${theme.transition.default};
  appearance: none;
  background: transparent;

  font-size: ${getFontSize(theme, small, large)};
  line-height: 150%;
  color: ${theme.color.text};

  :hover:not(:disabled) {
    ${dynamic && fieldStyleHover({ theme })};
  }

  :focus:not(:disabled) {
    ${dynamic && fieldStyleFocus({ theme })};
  }

  ::placeholder {
    color: ${theme.color.textLight};
  }

  :disabled {
    background: ${rgba(theme.color.gray, 0.1)};
    color: ${theme.color.textLight};
  }
`;

export const fieldStyleHover: StyleMixin = ({ theme }) => `
  border-color: ${rgba(theme.color.grayDark, 0.4)};
`;

export const fieldStyleFocus: StyleMixin = ({ theme }) => `
  border-color: ${theme.color.blue};
  box-shadow: 0 0 0 1px ${theme.color.blue};
`;

export const fieldStyleHideUntilHoverMixin: StyleMixin<{ hideUntilHover?: boolean }> = ({
  theme,
  hideUntilHover = false,
}) =>
  hideUntilHover
    ? `
  border-color: transparent;

  &:hover {
    border-color: ${theme.color.grayLight};
  }
`
    : '';

export default fieldStyleMixin;
