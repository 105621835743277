import React from 'react';
import styled from 'styled-components';

import DropdownMenu from 'components/lib/ui/DropdownMenu';
import Icon from 'components/lib/ui/Icon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { OverlayTrigger } from 'components/lib/ui/popover';

const CHEVRON_ICON_SIZE_PX = 16;

const ChevronDownIcon = styled(Icon).attrs({ name: 'chevron-down', size: CHEVRON_ICON_SIZE_PX })`
  margin-left: ${({ theme }) => theme.spacing.xxsmall};
  transform: translateY(1px);
`;
type Props = {
  title: string;
};

const DropdownMenuButton = ({ children, title }: React.PropsWithChildren<Props>) => (
  <OverlayTrigger overlay={<DropdownMenu>{children}</DropdownMenu>} placement="bottom-end">
    {({ toggleOpen, isOpen }) => (
      <DefaultButton size="small" onClick={toggleOpen} active={isOpen}>
        {title}
        <ChevronDownIcon />
      </DefaultButton>
    )}
  </OverlayTrigger>
);

export default DropdownMenuButton;
