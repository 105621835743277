import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { FORCE_REFRESH_ALL_ACCOUNTS_MUTATION } from 'common/lib/graphQl/accounts';
import usePollQueryUntil from 'common/lib/hooks/usePollQueryUntil';

import { MS_PER_SECOND, MS_PER_MINUTE } from 'common/constants/time';

import { gql } from 'common/generated/gql';
import type { ErrorCode } from 'common/generated/graphql';

const POLL_INTERVAL_MS = MS_PER_SECOND * 20;
const POLL_INTERVAL_TIMEOUT_MS = MS_PER_MINUTE * 5;

export type Options = {
  /** Called before the mutation is executed. */
  beforeStartRefresh?: () => void;
  /** Called when the refresh begins. */
  onDidStartRefresh?: () => void;
  /** Called when hasSyncOrRecentRefreshRequest goes from true -> false and polling ends. */
  onDidFinishRefresh?: () => void;
};

/**
 * Hook used to call the force refresh mutation and poll the query until the refresh is done.
 * In this case that'll be determined by there being no accounts with sync in progress.
 */
const useForceRefreshAllAccounts = (options?: Options) => {
  const { onDidStartRefresh, onDidFinishRefresh, beforeStartRefresh } = options ?? {};

  const [startPolling, { data, refetch }] = usePollQueryUntil(FORCE_REFRESH_QUERY, {
    pollUntil: (data) => !data?.hasAccountsSyncing,
    onComplete: onDidFinishRefresh,
    skipWhenNotPolling: false,
    pollIntervalMs: POLL_INTERVAL_MS,
    pollTimeoutMs: POLL_INTERVAL_TIMEOUT_MS,
  });

  const [error, setError] = useState<ErrorCode | null>();

  useEffect(() => {
    if (data?.hasAccountsSyncing) {
      startPolling();
    }
  }, [data, startPolling]);

  const [performMutation] = useMutation(FORCE_REFRESH_ALL_ACCOUNTS_MUTATION, {
    optimisticResponse: {
      __typename: 'Mutation',
      forceRefreshAllAccounts: {
        __typename: 'ForceRefreshAllAccountsMutation',
        success: true,
        errors: {
          __typename: 'PayloadError',
          fieldErrors: [],
          message: null,
          code: null,
        },
      },
    },
    // @ts-ignore complaining about proxy having any type
    update: (proxy) => {
      const data = proxy.readQuery({ query: FORCE_REFRESH_QUERY });
      // This is necessary to spread this value down below without an error

      proxy.writeQuery({
        query: FORCE_REFRESH_QUERY,
        data: {
          ...data,
          hasAccountsSyncing: true,
        },
      });
    },
  });

  const forceRefreshAccounts = useCallback(async () => {
    await beforeStartRefresh?.();
    const { data } = await performMutation();
    if (data?.forceRefreshAllAccounts?.errors) {
      setError(data.forceRefreshAllAccounts.errors.code);
    } else {
      await refetch();
      onDidStartRefresh?.();
    }
  }, [performMutation, refetch, beforeStartRefresh, onDidStartRefresh]);

  return [forceRefreshAccounts, { error }] as const;
};

export const FORCE_REFRESH_QUERY = gql(/* GraphQL */ `
  query Common_ForceRefreshAccountsQuery {
    hasAccountsSyncing
  }
`);

export default useForceRefreshAllAccounts;
