import type { DateTimeUnit } from 'luxon';
import { DateTime } from 'luxon';

import type DateRange from 'common/types/DateRange';

// Return true if startDate and endDate align fully with specified date duration.
// For instance, the 1st of March and 31st of March would be true for 'month'.
// The 1st of Jan and the 31st of December would be true for 'year'.
const isAlignedDateDuration = (
  startDateTime: DateTime,
  endDateTime: DateTime,
  duration: DateTimeUnit,
) =>
  startDateTime.toISODate() === endDateTime.startOf(duration).toISODate() &&
  endDateTime.toISODate() === startDateTime.endOf(duration).toISODate();

// Get a date shorthand if one exists, or return null.
// Current date shorthands are 'Month YYYY' if we fully align with a month
// or 'YYYY' if we fully align with a year.
export const getDateShorthand = ({ startDate, endDate }: DateRange) => {
  if (!startDate || !endDate) {
    return null;
  }
  const startDateTime = DateTime.fromISO(startDate);
  const endDateTime = DateTime.fromISO(endDate);
  if (isAlignedDateDuration(startDateTime, endDateTime, 'month')) {
    return startDateTime.toLocaleString({ month: 'long', year: 'numeric' });
  }
  if (isAlignedDateDuration(startDateTime, endDateTime, 'year')) {
    return startDateTime.toLocaleString({ year: 'numeric' });
  }
  if (isAlignedDateDuration(startDateTime, endDateTime, 'quarter')) {
    return `${startDateTime.toFormat('MMMM')} - ${endDateTime.toFormat(
      'MMMM',
    )} ${endDateTime.toFormat('yyyy')}`;
  }
  return null;
};

const formatFull = (isoDateString: string) =>
  DateTime.fromISO(isoDateString).toLocaleString(DateTime.DATE_FULL);

// Format a date range using full style: "December 3, 2018 - January 5, 2020"
export const formatFullDateRange = ({ startDate, endDate }: DateRange) => {
  if (startDate && endDate) {
    return `${formatFull(startDate)} - ${formatFull(endDate)}`;
  }
  const startOrEndDate = startDate || endDate;
  if (startOrEndDate) {
    return formatFull(startOrEndDate);
  }
  return undefined;
};
