import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import InfiniteScroll from 'components/lib/ui/InfiniteScroll';
import Scroll from 'components/lib/ui/Scroll';
import SectionHeader from 'components/lib/ui/SectionHeader';
import PreviewTransactionChangeRow from 'components/transactions/PreviewTransactionChangeRow';

import groupTransactionsByDate from 'common/utils/groupTransactionsByDate';

import type { PreviewTransactionRuleQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

type Props = {
  results: ElementOf<PreviewTransactionRuleQuery['transactionRulePreview'], 'results'>[];
  hasNextPage: boolean;
  onRequestNextPage: () => void;
};

const Root = styled(Scroll)`
  max-height: 50vh;
`;

const Section = styled.div``;

const DateHeader = styled(SectionHeader)`
  position: sticky;
  top: 0;
  padding-left: ${({ theme }) => theme.spacing.xlarge};
`;

const Row = styled(PreviewTransactionChangeRow)`
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const PreviewTransactionChangesTable = ({ results, hasNextPage, onRequestNextPage }: Props) => {
  const sections = useMemo(() => {
    const withId = results.map((result) => ({ ...result, id: result.transaction.id }));
    return groupTransactionsByDate({
      transactions: withId,
      groupingFn: ({ transaction }) => transaction.date,
    });
  }, [results]);

  return (
    <Root>
      <InfiniteScroll hasNextPage={hasNextPage} onRequestNextPage={onRequestNextPage}>
        {sections.map(({ date, data, key }) => (
          <Section key={key}>
            <DateHeader>{DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)}</DateHeader>
            {data.map(
              ({
                transaction: { id, merchant, category, amount },
                newName,
                newCategory,
                newHideFromReports,
                newTags,
                newSplitTransactions,
              }) => (
                <Row
                  key={id}
                  name={merchant.name}
                  category={`${category.icon} ${category.name}`}
                  amount={amount}
                  newName={newName ?? undefined}
                  newCategory={newCategory ? `${newCategory.icon} ${newCategory.name}` : undefined}
                  newHideFromReports={newHideFromReports}
                  newTags={newTags}
                  newSplitTransactions={newSplitTransactions}
                />
              ),
            )}
          </Section>
        ))}
      </InfiniteScroll>
    </Root>
  );
};

export default PreviewTransactionChangesTable;
