import { ellipsis } from 'polished';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';

import type { AbstractButtonProps } from 'components/lib/ui/button/AbstractButton';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { color, fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';
import buttonShadowMixin from 'lib/styles/buttonShadowMixin';

const ICON_SIZE_PX = 12;
// Width of the button when there's no children
const WIDTH_WITHOUT_CHILDREN_PX = 28;

const Button = styled(DefaultButton).attrs({ size: 'xsmall' })<{
  $hideUntilHover: boolean;
  $hasChildren: boolean;
}>`
  --gap: ${spacing.xsmall};

  font-weight: ${fontWeight.book};
  display: flex;
  align-items: center;
  justify-content: ${({ $hasChildren }) => ($hasChildren ? 'space-between' : 'center')};
  gap: var(--gap);
  font-size: ${fontSize.base};
  width: ${({ $hasChildren }) => ($hasChildren ? 'auto' : `${WIDTH_WITHOUT_CHILDREN_PX}px`)};
  padding-left: 0;
  padding-right: 0;

  ${ButtonIcon} {
    margin: 0;
    transform: translateY(1px);
  }

  ${({ $hideUntilHover, theme }) =>
    $hideUntilHover &&
    css`
      border-color: transparent;
      box-shadow: none;

      ${ButtonIcon} {
        display: none;
      }

      &:hover {
        border-color: ${color.grayLight};
        ${buttonShadowMixin({ theme })};

        ${ButtonIcon} {
          display: inline-block;
        }
      }
    `}
`;

const ChildrenContainer = styled.div`
  ${ellipsis()}
  display: flex;
  align-items: center;
  gap: var(--gap);
`;

type Props = PropsWithChildren<
  Pick<AbstractButtonProps, 'linkTo' | 'className'> & {
    hideUntilHover?: boolean;
    title?: string;
  }
>;

const TransactionLinkButton: FC<Props> = ({ children, hideUntilHover = false, ...props }) => (
  <Button $hasChildren={!!children} $hideUntilHover={hideUntilHover} {...props}>
    {children && <ChildrenContainer>{children}</ChildrenContainer>}{' '}
    <ButtonIcon name="arrow-right" size={ICON_SIZE_PX} />
  </Button>
);

export default TransactionLinkButton;
