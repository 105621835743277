import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

const Root = styled(FlexContainer).attrs({ column: true })`
  padding: ${({ theme }) => theme.spacing.default};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  border-radius: ${({ theme }) => theme.radius.small};
`;

const TextContainer = styled(FlexContainer).attrs({ column: true })`
  margin-right: ${({ theme }) => theme.spacing.xlarge};
`;

const Description = styled(Text).attrs({ size: 'small' })`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

const ChildrenContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  title: React.ReactNode;
  description: React.ReactNode;
  rightAccessory?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
};

const TransactionDrawerFieldCell = ({
  title,
  description,
  rightAccessory,
  className,
  children,
}: Props) => (
  <Root className={className}>
    <FlexContainer alignCenter justifyBetween>
      <TextContainer>
        <Text weight="medium" size="small">
          {title}
        </Text>
        {!!description && <Description>{description}</Description>}
      </TextContainer>
      {rightAccessory}
    </FlexContainer>
    {!!children && <ChildrenContainer>{children}</ChildrenContainer>}
  </Root>
);

export default TransactionDrawerFieldCell;
