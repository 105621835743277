import { identity } from 'ramda';
import { isValidNumber, isNotNil } from 'ramda-adjunct';
import React from 'react';

import Text from 'components/lib/ui/Text';

import type useTheme from 'lib/hooks/useTheme';

type Props = {
  value: Maybe<number>;
  color?: keyof ReturnType<typeof useTheme>['color'];
  neutralIfZero?: boolean;
  formatter?: (value: number) => string;
};

const ReportsCashFlowValue: React.FC<Props> = ({
  value,
  neutralIfZero,
  color = 'text',
  formatter = identity,
}) => (
  <Text color={neutralIfZero && value === 0 ? 'text' : color}>
    {isValidNumber(value) && isNotNil(value) ? formatter(value) : '-'}
  </Text>
);

export default ReportsCashFlowValue;
