import prettyBytes from 'pretty-bytes';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback, useState } from 'react';
import type { FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import Banner from 'components/lib/ui/Banner';
import DragAndDropUploadInput from 'components/lib/ui/DragAndDropFileUploadInput';
import AttachmentItem from 'components/transactions/drawer/AttachmentItem';

import useUpdateEffect from 'common/lib/hooks/useUpdateEffect';

const StyledBanner = styled(Banner)`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const Container = styled.div``;

type Props = {
  acceptFileTypes?: string[];
  files: File[];
  onChangeFiles: (files: File[]) => void;
  onDropRejected: (rejectedFiles: FileRejection[]) => void;
  title?: string;
  maxNumFiles?: number;
  errorMessage?: string;
  height?: number;
  className?: string;
};

const FileDropzone = ({
  acceptFileTypes,
  files,
  onChangeFiles,
  onDropRejected: onDropRejectedProp,
  maxNumFiles,
  title = 'Upload a File',
  errorMessage: errorMessageProp,
  height,
  className,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>();

  // Allow parent to set error message
  useUpdateEffect(() => setErrorMessage(errorMessageProp), [errorMessageProp]);

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      onChangeFiles([...acceptedFiles, ...files].slice(0, maxNumFiles));
      setErrorMessage(undefined);
    },
    [files, onChangeFiles, setErrorMessage, maxNumFiles],
  );

  const onDropRejected = useCallback(
    (rejections: FileRejection[]) => {
      onDropRejectedProp?.(rejections);
      const [rejection] = rejections;
      const [error] = rejection.errors;
      setErrorMessage(error.message);
    },
    [setErrorMessage, onDropRejectedProp],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openUploadDialog,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxFiles: maxNumFiles,
    accept: acceptFileTypes,
    preventDropOnDocument: true,
    noClick: true,
  });

  const disableUploads = maxNumFiles ? files.length >= maxNumFiles : false;

  return (
    <Container className={className} {...getRootProps()}>
      {RA.isNotNil(errorMessage) && <StyledBanner type="error">{errorMessage}</StyledBanner>}
      {!disableUploads && (
        <DragAndDropUploadInput
          isDragActive={isDragActive}
          hasUpload={!!files.length}
          disableUploads={disableUploads}
          getInputProps={getInputProps}
          onUploadClick={openUploadDialog}
          title={title}
          height={height}
        />
      )}
      {files.map((file, index) => (
        <AttachmentItem
          key={`${file.name}-${index}`}
          name={file.name}
          displaySize={prettyBytes(file.size)}
          progressPercent={0}
          useThumbnailPlaceholder
          publicId="placeholder"
          onRemoveClick={() => {
            onChangeFiles(R.remove(index, 1, files));
            setErrorMessage(undefined);
          }}
          singleItem={files.length === 1}
        />
      ))}
    </Container>
  );
};

export default FileDropzone;
