import React from 'react';

import TargetAmountsGoalsList from 'components/goalsV2/TargetAmountsGoalsList';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { useModalContext } from 'lib/contexts/ModalContext';

import * as COPY from 'common/constants/copy';

type Props = {
  /** Only show a single goal. */
  restrictToGoalId?: string;
  /** Update startingAmount instead of targetAmount, used for debt goals. */
  useStartingAmount?: boolean;
};

const TargetAmountsModal = (props: Props) => {
  const { close } = useModalContext();

  return (
    <ModalCard
      title={
        props.useStartingAmount
          ? COPY.GOALS.STARTING_AMOUNTS.TITLE
          : COPY.GOALS.TARGET_AMOUNTS.TITLE
      }
      description={
        props.useStartingAmount
          ? COPY.GOALS.STARTING_AMOUNTS.SUBTITLE
          : COPY.GOALS.TARGET_AMOUNTS.SUBTITLE
      }
      hideBottomBorder
    >
      <FlexContainer full>
        <TargetAmountsGoalsList {...props} />
      </FlexContainer>

      <CardFooter>
        <PrimaryButton onClick={close}>Done</PrimaryButton>
      </CardFooter>
    </ModalCard>
  );
};

export default TargetAmountsModal;
