import React from 'react';
import styled from 'styled-components';

import type { CardProps } from 'components/lib/ui/Card';
import Card from 'components/lib/ui/Card';
import { Root as CardHeaderRoot } from 'components/lib/ui/CardHeader';
import FlexContainer from 'components/lib/ui/FlexContainer';
import IconButton from 'components/lib/ui/button/IconButton';

import { useModalContext } from 'lib/contexts/ModalContext';

const Root = styled(Card)`
  ${CardHeaderRoot} {
    align-items: flex-start;
  }
`;

const StyledIconButton = styled(IconButton).attrs({ size: 'medium' })`
  margin: -${({ theme }) => theme.spacing.xxsmall} -12px;
`;

const BackButton = styled.div<{ $includeBottomMargin: boolean }>`
  margin-bottom: ${({ $includeBottomMargin }) => ($includeBottomMargin ? '12px' : '0px')};
`;

const Title = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSize.base};
`;

const ControlsContainer = styled(FlexContainer).attrs({ alignCenter: true })`
  margin-left: ${({ theme }) => theme.spacing.small};
`;

type Props = CardProps & {
  hideCloseButton?: boolean;
  /** Back button is shown if this is not nil */
  onClickBackButton?: () => void;
};

const ModalCard = ({
  hideCloseButton = false,
  onClickBackButton,
  title,
  description,
  /** Defaults to true IFF there is nothing to display in the card header */
  hideBottomBorder = hideCloseButton && !title && !description && !onClickBackButton,
  ...props
}: Props) => {
  const { close, isDismissable } = useModalContext();

  return (
    <Root
      {...props}
      description={!!description && <Description>{description}</Description>}
      title={
        <>
          {onClickBackButton && (
            <BackButton onClick={onClickBackButton} $includeBottomMargin={!!title || !!description}>
              <StyledIconButton icon="arrow-left" />
            </BackButton>
          )}
          {!!title && <Title>{title}</Title>}
        </>
      }
      controls={
        isDismissable && !hideCloseButton ? (
          <ControlsContainer>
            {props.controls}
            <StyledIconButton onClick={close} icon="x" />
          </ControlsContainer>
        ) : undefined
      }
      hideBottomBorder={props.tabs ? false : hideBottomBorder}
    />
  );
};

export default ModalCard;
