import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import type { PaymentMethod } from '@stripe/stripe-js';
import _ from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import StripeCardInput from 'components/lib/external/StripeCardInput';

type Props = {
  onBeforeSubmit?: () => Promise<void>;
  onSuccess: (paymentMethod: PaymentMethod | null) => void;
  children?: (context: {
    isValid: boolean;
    isLoading: boolean;
  }) => React.ReactNode | React.ReactNode;
  skipPaymentMethod: boolean;
};

const ErrorLabel = styled.label`
  color: ${({ theme }) => theme.color.red};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

/**
 * Component to display a Stripe card input and create a PaymentMethod on submit.
 *
 * !! Must be wrapped into a StripeProvider !!
 */
const StripeCardInputForm = ({ onSuccess, onBeforeSubmit, skipPaymentMethod, children }: Props) => {
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (skipPaymentMethod) {
      setIsValid(true);
    }
  }, [skipPaymentMethod]);

  /** Follows reference implementation: https://stripe.com/docs/stripe-js/react#useelements-hook */
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!stripe || !elements) {
        // Disable form submission until Stripe.js has loaded
        return;
      }

      const card = elements.getElement(CardElement);
      if (!card && !skipPaymentMethod) {
        return;
      }

      setIsLoading(true);

      try {
        await onBeforeSubmit?.();
        if (skipPaymentMethod) {
          await onSuccess(null);
        } else if (card) {
          const { error, paymentMethod } = await stripe.createPaymentMethod({ type: 'card', card });

          if (error) {
            throw error;
          } else if (paymentMethod) {
            await onSuccess(paymentMethod);
          }
        }
      } catch (error: any) {
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [onSuccess, setIsLoading, elements, stripe, skipPaymentMethod, onBeforeSubmit, setErrorMessage],
  );

  return (
    // TODO @maushundb pull this out to custom form field to use with BaseForm
    <form onSubmit={handleSubmit}>
      {!skipPaymentMethod && (
        <StripeCardInput
          onChange={({ complete, error }) => {
            if (error) {
              setErrorMessage(error.message);
            } else {
              setErrorMessage(null);
            }
            complete !== isValid && setIsValid(complete);
          }}
        />
      )}
      {errorMessage !== null && <ErrorLabel>{errorMessage}</ErrorLabel>}
      {_.isFunction(children) ? children({ isValid, isLoading }) : children}
    </form>
  );
};

export default StripeCardInputForm;
