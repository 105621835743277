import { gql } from '@apollo/client';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import GoalMessageBlock from 'components/assistant/blocks/GoalMessageBlock';
import GoalDashboardRow from 'components/goalsV2/GoalDashboardRow';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { GoalsBlock } from 'common/lib/assistant/blocks';
import { makeBlockRenderer } from 'common/lib/assistant/blocks/render';
import useQuery from 'common/lib/hooks/useQuery';

import type {
  Web_GoalsMessageBlock,
  Web_GoalsMessageBlockVariables,
} from 'common/generated/graphQlTypes/Web_GoalsMessageBlock';

const Root = styled(FlexContainer)`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const GoalsBlockRenderer = makeBlockRenderer(GoalsBlock)(({ data: { ids } }) => {
  const { data, isLoadingInitialData } = useQuery<
    Web_GoalsMessageBlock,
    Web_GoalsMessageBlockVariables
  >(QUERY, {
    variables: { ids },
  });
  const { goalsV2: goals = [] } = data ?? {};
  const goalsById = useMemo(() => R.indexBy(R.prop('id'), goals), [goals]);

  return (
    <Root column>
      {ids.map((id) => {
        const goal = goalsById[id];

        if (!goal && !isLoadingInitialData) {
          // this goal does not exist, so don't show anything
          return null;
        }

        return <GoalMessageBlock key={id} goal={goal} loading={!goal} />;
      })}
    </Root>
  );
});

const QUERY = gql`
  query Web_GoalsMessageBlock($ids: [ID!]!) {
    goalsV2(ids: $ids) {
      id
      ...Web_GoalDashboardRowFields
    }
  }
  ${GoalDashboardRow.fragments.GoalDashboardRowFields}
`;

export default GoalsBlockRenderer;
