import React from 'react';

import Controls from 'components/lib/ui/Controls';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import useCreateMessageThread from 'common/lib/hooks/assistant/useCreateMessageThread';

type Props = {
  onCreateMessageThread: (threadId: string) => void;
};

const AssistantControls = ({ onCreateMessageThread }: Props) => {
  const [createMessageThread] = useCreateMessageThread();

  return (
    <Controls>
      <PrimaryButton
        size="small"
        onClick={async () => {
          const { data } = await createMessageThread();
          const threadId = data?.createMessageThread?.messageThread?.id;
          threadId && onCreateMessageThread(threadId);
        }}
      >
        <ButtonIcon name="plus" />
        <span>New chat</span>
      </PrimaryButton>
    </Controls>
  );
};

export default AssistantControls;
