import { useRef, useEffect } from 'react';

/**
 * Invoke a function when a component unmounts.
 */
const useUnmount = (fn: () => void) => {
  const ref = useRef(fn);
  ref.current = fn;
  useEffect(
    () => () => ref.current(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

export default useUnmount;
