import { formatCurrency } from 'common/utils/Currency';

export const isIncome = (amount: number) => amount > 0;

export const isExpense = (amount: number) => amount < 0;

const formatTransactionAmount = (amount: number) =>
  `${isIncome(amount) ? '+' : ''}${formatCurrency(Math.abs(amount))}`;

export default formatTransactionAmount;
