import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import TabPanel from 'common/components/tabs/TabPanel';
import Tabs from 'common/components/tabs/Tabs';
import DebugPrettyLogs from 'components/assistant/DebugPrettyLogs';
import DebugRawLogs from 'components/assistant/DebugRawLogs';
import ModalCard from 'components/lib/ui/ModalCard';
import Tab from 'components/lib/ui/tabs/Tab';

const StyledModalCard = styled(ModalCard)`
  overflow: hidden;
`;

type Props = {
  debugInfo: string | Record<string, any> | null;
};

const AssistantMessageDebugModal = ({ debugInfo }: Props) => {
  if (!debugInfo) {
    return <ModalCard title="No logs available" />;
  }

  const { logs } = RA.isString(debugInfo) ? JSON.parse(debugInfo) : debugInfo;

  return (
    <Tabs>
      <StyledModalCard
        title="AI Debug Info"
        tabs={
          <>
            <Tab index={0}>Pretty logs</Tab>
            <Tab index={1}>Raw logs</Tab>
          </>
        }
      >
        <TabPanel index={0}>
          <DebugPrettyLogs logs={logs} />
        </TabPanel>
        <TabPanel index={1}>
          <DebugRawLogs logs={logs} />
        </TabPanel>
      </StyledModalCard>
    </Tabs>
  );
};

export default AssistantMessageDebugModal;
