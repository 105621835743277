import React, { useCallback } from 'react';

import ResolutionButtonBlock from 'components/support/blocks/ResolutionButtonBlock';
import ResolutionLinkBlock from 'components/support/blocks/ResolutionLinkBlock';
import ResolutionMessageBlock from 'components/support/blocks/ResolutionMessageBlock';

import { track } from 'lib/analytics/segment';

import { AnalyticsEventNames } from 'common/constants/analytics';

import type {
  ResolutionBlockComponents,
  BlockComponentProps,
  BlockAnalyticsProps,
} from 'common/types/support';
import { isValidBlockComponent } from 'common/types/support';

/**
 * This maps the block type to the component that should be used to render it.
 * It's strongly typed so that if a new block type is added to the GraphQL type,
 * TypeScript will detect and error if the corresponding block is not added here.
 *
 * To automatically type the block component with the expected `data` prop,
 * use `BlockComponentProps`. See `ResolutionMessageBlock` for an example.
 */
const BLOCK_TYPE_TO_COMPONENT: ResolutionBlockComponents = {
  ResolutionMessageBlock,
  ResolutionButtonBlock,
  ResolutionLinkBlock,
};

/**
 * Base block component that takes the data with the block type (i.e. __typename), and
 * renders the corresponding block component.
 */
const Block = <TBlock extends keyof ResolutionBlockComponents>({
  data,
}: BlockComponentProps<TBlock>) => {
  const BlockComponent = BLOCK_TYPE_TO_COMPONENT[data.__typename];

  const trackResolutionSelected = useCallback(() => {
    const analyticsData: BlockAnalyticsProps = data;

    track(AnalyticsEventNames.RecommendedResolutionSelected, {
      type: analyticsData.__typename,
      text: analyticsData.text,
    });
  }, [data]);

  if (!isValidBlockComponent(BlockComponent)) {
    return null;
  }

  return <BlockComponent data={data} onClickEvent={trackResolutionSelected} />;
};

export default Block;
