import React from 'react';
import styled from 'styled-components';

import ErrorMessage from 'components/errors/ErrorMessage';
import SingleCardPage from 'components/lib/layouts/SingleCardPage';

import { externalUrls } from 'constants/routes';

type Props = {
  message?: string;
  description?: string;
  callToAction?: string;
  link?: string;
};

const Root = styled(SingleCardPage)`
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const BlockApp = ({
  message = 'We are undergoing some maintenance.',
  description = 'Please check back in the next 30 minutes.',
  callToAction = 'Visit our help center',
  link = externalUrls.zendeskHelpCenter,
}: Props) => (
  <Root name="Blocked">
    <ErrorMessage
      errorMessage={message}
      errorDescription={description}
      buttonText={callToAction}
      onButtonClick={() => {
        window.location.href = link;
      }}
    />
  </Root>
);

export default BlockApp;
