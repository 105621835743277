import { useMutation } from '@apollo/client';
import Papa from 'papaparse';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { setCurrentUploadSession, clearCurrentUploadSession } from 'actions';
import getStatementsApi from 'common/lib/api/statements';
import useQuery from 'common/lib/hooks/useQuery';
import api from 'lib/api';
import { handleEmptyColumns, trimEmptyRows } from 'lib/csv/utils';
import { MINT_CATEGORY_COLUMN_INDEX, UNCATEGORIZED_CATEGORY } from 'lib/csv/validation';
import useDispatch from 'lib/hooks/useDispatch';
import { getCurrentUploadSession } from 'selectors';
import type { UploadStatementSession } from 'state/statements/types';

import { CACHE_KEYS } from 'common/constants/cache';
import type { ParserName } from 'common/constants/statements';
import { UploadSessionStatus } from 'common/constants/statements';
import { MS_PER_SECOND } from 'common/constants/time';

import { gql } from 'common/generated/gql';
import type { ParseStatementInput } from 'common/generated/graphql';

const POLL_INTERVAL_MS = MS_PER_SECOND;

type Options = {
  parserName: ParserName;
  onParseSuccess?: (session: UploadStatementSession) => void;
  onParseError?: (session: UploadStatementSession) => void;
};

const useUploadStatementSession = ({ parserName, onParseSuccess, onParseError }: Options) => {
  const dispatch = useDispatch();

  const currentSession = useSelector(getCurrentUploadSession);
  const { sessionKey } = currentSession ?? {};

  const clearSession = () => dispatch(clearCurrentUploadSession());

  const { startPolling, stopPolling } = useQuery(QUERY, {
    variables: {
      sessionKey: sessionKey ?? '',
    },
    onCompleted: ({ uploadStatementSession }) => {
      if (uploadStatementSession) {
        const { status } = uploadStatementSession;

        const session = {
          ...currentSession!,
          ...uploadStatementSession,
        };

        if (status === UploadSessionStatus.Completed) {
          stopPolling();
          clearSession();
          onParseSuccess?.(session);
        } else if (status === UploadSessionStatus.Errored) {
          stopPolling();
          onParseError?.(session);
          dispatch(setCurrentUploadSession(session));
        } else {
          dispatch(setCurrentUploadSession(session));
        }
      }
    },
    skip: R.isNil(sessionKey),
    notifyOnNetworkStatusChange: true,
  });

  const [startParsingFile] = useMutation(MUTATION, {
    update: (cache) => {
      cache.evict({ fieldName: CACHE_KEYS.TAGS });
    },
  });

  const startSession = async (file: File) => {
    const data = new FormData();
    data.append('file', file, file.name);
    data.append('', String(currentSession?.skipCheckForDuplicates ?? false));

    const parsedRows = await new Promise<string[][]>((resolve, reject) => {
      // @ts-ignore parse() works with File type, but TS doesn't like it
      Papa.parse(file, {
        complete: (results) => {
          const parsedRows = trimEmptyRows(results.data as string[][]);

          resolve(
            handleEmptyColumns(parsedRows, MINT_CATEGORY_COLUMN_INDEX, UNCATEGORIZED_CATEGORY),
          );
        },
      });
    });

    const response = await getStatementsApi(api).startUploadSession(data);
    const { session_key } = response;

    const session = {
      parserName,
      sessionKey: session_key,
      parsedRows,
      errorMessage: null,
      uploadedStatement: null,
      status: UploadSessionStatus.Created,
    };

    dispatch(setCurrentUploadSession(session));

    return session;
  };

  const startParsing = async (input: Partial<ParseStatementInput>) => {
    await startParsingFile({
      variables: {
        input: {
          parserName,
          sessionKey: sessionKey ?? '',
          importPriority: undefined,
          accountId: undefined,
          accountNameMapping: undefined,
          tagMapping: undefined,
          categoryMapping: undefined,
          skipCheckForDuplicates: !!currentSession?.skipCheckForDuplicates,
          shouldUpdateBalance: false,
          ...input,
        },
      },
    });
    startPolling(POLL_INTERVAL_MS);
  };

  return { currentSession, startSession, startParsing, clearSession };
};

export const UPLOAD_STATEMENT_SESSION_FRAGMENT = gql(`
  fragment UploadStatementSessionFields on UploadStatementSession {
    sessionKey
    status
    errorMessage
    skipCheckForDuplicates
    uploadedStatement {
      id
      transactionCount
    }
  }
`);

const QUERY = gql(`
  query GetUploadStatementSession($sessionKey: String!) {
    uploadStatementSession(sessionKey: $sessionKey) {
      ...UploadStatementSessionFields
    }
  }
`);

const MUTATION = gql(`
  mutation Web_ParseUploadStatementSession($input: ParseStatementInput!) {
    parseUploadStatementSession(input: $input) {
      uploadStatementSession {
        ...UploadStatementSessionFields
      }
    }
  }
`);

export default useUploadStatementSession;
