import { useMutation } from '@apollo/client';
import { useState, useCallback, useMemo } from 'react';

import usePollQueryUntil from 'common/lib/hooks/usePollQueryUntil';
import useToggle from 'common/lib/hooks/useToggle';
import { track } from 'lib/analytics/segment';
import { convertFiltersToInput } from 'lib/transactions/Filters';

import { DOWNLOAD_TRANSACTIONS_CSV_BUTTON_CLICKED } from 'common/constants/analytics';
import { UploadSessionStatus } from 'common/constants/statements';
import { MS_PER_SECOND } from 'common/constants/time';

import { gql } from 'common/generated/gql';
import type { DownloadTransactionsSession } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

const POLL_INTERVAL_MS = MS_PER_SECOND;

type Options = {
  filters: TransactionFilters;
  onParseSuccess?: (session: DownloadTransactionsSession) => void;
  onParseError?: (session: DownloadTransactionsSession) => void;
};

type DownloadTransactionsSessionWithResult = DownloadTransactionsSession & {
  errorMessage: string;
  url: string;
};

const useDownloadTransactions = ({ filters, onParseSuccess, onParseError }: Options) => {
  const filtersInput = useMemo(() => convertFiltersToInput(filters), [filters]);

  const [currentSession, setCurrentSession] = useState<DownloadTransactionsSession | undefined>(
    undefined,
  );

  const [isLoading, { setOn: setIsLoading, setOff: disableIsLoading }] = useToggle(false);

  const { sessionKey } = currentSession ?? {};

  const [startPolling] = usePollQueryUntil(QUERY, {
    variables: {
      sessionKey: sessionKey ?? '',
    },
    pollUntil: (data) => {
      const status = data?.downloadTransactionsSession?.status;
      const hasCompleted =
        status === UploadSessionStatus.Completed || status === UploadSessionStatus.Errored;
      return hasCompleted;
    },
    onComplete: (data) => {
      disableIsLoading();
      const downloadTransactionsSession = data?.downloadTransactionsSession;

      if (downloadTransactionsSession) {
        const { status } = downloadTransactionsSession;

        const session = {
          ...currentSession!,
          ...downloadTransactionsSession,
        };

        if (status === UploadSessionStatus.Completed) {
          onParseSuccess?.(session);
        } else if (status === UploadSessionStatus.Errored) {
          onParseError?.(session);
        }

        setCurrentSession(session);
      }
    },
    skipWhenNotPolling: false,
    pollIntervalMs: POLL_INTERVAL_MS,
  });

  const [startSession] = useMutation(MUTATION, {
    variables: {
      filters: filtersInput,
      orderBy: filters.order,
    },
    onCompleted: ({ startDownloadTransactionsSession }) => {
      const session = {
        sessionKey: startDownloadTransactionsSession?.sessionKey,
        status: startDownloadTransactionsSession?.status,
        errorMessage: '',
        url: '',
      };

      setCurrentSession(session as DownloadTransactionsSessionWithResult);
    },
  });

  const downloadTransactions = useCallback(async () => {
    track(DOWNLOAD_TRANSACTIONS_CSV_BUTTON_CLICKED);
    setIsLoading();
    await startSession();
    startPolling();
  }, [startSession, startPolling, setIsLoading]);

  return { currentSession, downloadTransactions, isLoading };
};

const QUERY = gql(`
  query Web_GetDownloadTransactionsSession($sessionKey: String!) {
    downloadTransactionsSession(sessionKey: $sessionKey) {
      sessionKey
      status
      errorMessage
      url
    }
  }
`);

const MUTATION = gql(`
  mutation Web_DownloadTransactions(
    $filters: TransactionFilterInput!
    $orderBy: String
  ) {
    startDownloadTransactionsSession(filters: $filters, orderBy: $orderBy) {
      sessionKey
      status
    }
  }
`);

export default useDownloadTransactions;
