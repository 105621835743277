import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Page from 'components/lib/ui/Page';
import SecuritySettings from 'components/routes/settings/SecuritySettings';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.spacing.xxlarge};
  padding-bottom: 0;
  > * {
    width: 640px;
    margin-bottom: ${(props) => props.theme.spacing.large};
  }
`;

const AdvisorSecurity = () => (
  <Page name="Security">
    <Helmet>
      <title>Advisor Portal | Security</title>
    </Helmet>
    <Container>
      <SecuritySettings />
    </Container>
  </Page>
);

export default AdvisorSecurity;
