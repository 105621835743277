import React from 'react';
import styled from 'styled-components';

import CardBody from 'components/lib/ui/CardBody';
import ModalCard from 'components/lib/ui/ModalCard';
import TextButton from 'components/lib/ui/TextButton';
import PremiumUpgradeForm from 'components/premium/PremiumUpgradeForm';
import DeleteHouseholdFinalConfirmation from 'components/settings/billing/DeleteHouseholdFinalConfirmation';

import { useQueryParam } from 'lib/hooks';
import useModal from 'lib/hooks/useModal';
import useTrack from 'lib/hooks/useTrack';

import { PremiumEventNames } from 'common/constants/analytics';
import * as COPY from 'common/constants/copy';

const DeleteHouseholdContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const DeleteHouseholdButton = styled(TextButton)`
  color: ${({ theme }) => theme.color.textLight};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin: auto;
`;

type Props = {
  next: () => void;
  goBack?: () => void;
};

const PremiumSubscriptionEndedModal = ({ next }: Props) => {
  const [
    DeleteHouseholdFinalConfirmationModal,
    { open: openDeleteHouseholdFinalConfirmation, close: closeDeleteHouseholdFinalConfirmation },
  ] = useModal();

  const onClickDeleteHousehold = () => openDeleteHouseholdFinalConfirmation();

  useTrack(PremiumEventNames.PaymentScreenViewed);

  const initialPromoCode = useQueryParam('promoCode');

  return (
    <ModalCard
      title={COPY.PREMIUM.SUBSCRIPTION_ENDED.TITLE}
      description={COPY.PREMIUM.SUBSCRIPTION_ENDED.SUBTITLE}
    >
      <CardBody>
        <PremiumUpgradeForm
          useSubscribeNowButton
          initialPromoCode={initialPromoCode ?? undefined}
          onSuccess={next}
        />
        <DeleteHouseholdContainer>
          <DeleteHouseholdButton onClick={onClickDeleteHousehold}>
            {COPY.PREMIUM.SUBSCRIPTION_ENDED.BUTTONS.DELETE_ACCOUNT}
          </DeleteHouseholdButton>
        </DeleteHouseholdContainer>
        <DeleteHouseholdFinalConfirmationModal>
          <DeleteHouseholdFinalConfirmation onCancel={closeDeleteHouseholdFinalConfirmation} />
        </DeleteHouseholdFinalConfirmationModal>
      </CardBody>
    </ModalCard>
  );
};

export default PremiumSubscriptionEndedModal;
