import { DateTime } from 'luxon';

import type { SearchResultType } from 'components/globalSearch/types';

import routes from 'constants/routes';

export const getRouteForResultType = (type: SearchResultType, id: string) => {
  const startOfTheYearISO = DateTime.local().startOf('year').toISODate();
  const resultTypeRoutesMap = {
    account: routes.accounts.accountDetails({ id }),
    merchant: routes.merchants({
      id,
      queryParams: { date: startOfTheYearISO, timeframe: 'year' },
    }),
    goal: routes.goalsV2.goalDetail({ id }),
    category: routes.categories({
      id,
      queryParams: { date: startOfTheYearISO, timeframe: 'year' },
    }),
  };

  return resultTypeRoutesMap[type as keyof typeof resultTypeRoutesMap];
};
