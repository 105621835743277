import * as React from 'react';

type CasePropsDefault = {
  children: React.ReactNode | (() => React.ReactNode);
  default: true;
};

type CasePropsWhen = {
  children: React.ReactNode | (() => React.ReactNode);
  when: boolean | (() => boolean);
};

type SwitchProps = {
  children?: React.ReactNode;
};

export const Case = ({ children }: CasePropsDefault | CasePropsWhen) => (
  <>{typeof children === 'function' ? children() : children}</>
);

const Switch = ({ children }: SwitchProps) => {
  let found = false;
  const resultChildren = React.Children.toArray(children).filter((child) => {
    // @ts-ignore React.ReactNode type is really wide
    if (child.type === Case) {
      const {
        // @ts-ignore
        props: { when, default: isDefault },
      } = child;

      if (found) {
        return false;
      }
      if (isDefault) {
        return true;
      }
      if (typeof when === 'function' ? when() : when ?? false) {
        found = true;
        return true;
      } else {
        return false;
      }
    }
    return true;
  });
  // Wrap in fragment to fix type issue see https://github.com/microsoft/TypeScript/issues/33487
  return <>{resultChildren}</>;
};

export default Switch;
