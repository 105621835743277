import { gql } from '@apollo/client';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

export const NOTIFICATION_PREFERENCE_FRAGMENT = gql`
  fragment NotificationPreferenceFields on NotificationPreference {
    id
    group
    type
    additionalPreferences
    additionalPreferencesMeta
    title
    description
    EMAIL
    PUSH
  }
`;

export const GET_NOTIFICATION_PREFERENCE = gql`
  query GetNotificationPreference($id: String!) {
    notificationPreference(id: $id) {
      ...NotificationPreferenceFields
    }
  }
  ${NOTIFICATION_PREFERENCE_FRAGMENT}
`;

export const GET_NOTIFICATION_PREFERENCES = gql`
  query GetNotificationPreferences {
    notificationPreferences {
      ...NotificationPreferenceFields
    }
  }
  ${NOTIFICATION_PREFERENCE_FRAGMENT}
`;

export const UPDATE_NOTIFICATION_PREFERENCE = gql`
  mutation Common_UpdateNotificationPreferences($input: UpdateNotificationPreferencesInput!) {
    updateNotificationPreferences(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      notificationPreference {
        ...NotificationPreferenceFields
      }
    }
  }
  ${NOTIFICATION_PREFERENCE_FRAGMENT}
  ${PAYLOAD_ERRORS_FRAGMENT}
`;
