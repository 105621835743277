import React, { useMemo, useRef } from 'react';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';

import MessageListGroup from 'components/assistant/MessageListGroup';

import { groupMessages } from 'common/lib/assistant/adapters';
import MessagesListContext from 'lib/contexts/MessagesListContext';

import type { MessageListGroupFields } from 'common/generated/graphQlTypes/MessageListGroupFields';

const StyledVirtuoso = styled(Virtuoso)`
  flex: 1;
` as typeof Virtuoso;

const HeaderSpacer = styled.div`
  height: ${({ theme }) => theme.spacing.xlarge};
`;

const FooterSpacer = styled.div`
  height: ${({ theme }) => theme.spacing.default};
`;

const StyledMessageListGroup = styled(MessageListGroup)`
  padding-top: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  messages: MessageListGroupFields[];
  threadId: string;
  footer?: React.ReactNode;
  /** Use demo block renderers (i.e. for stories, debug, etc.) */
  demo?: boolean;
  alignToBottom?: boolean;
  className?: string;
};

const MessagesList = ({
  messages,
  threadId,
  footer,
  demo,
  alignToBottom = true,
  className,
}: Props) => {
  const ref = useRef(null);

  const groups = useMemo(() => groupMessages(messages), [messages]);

  const row = useMemo(
    () => (i: number, messages: MessageListGroupFields[]) => (
      <StyledMessageListGroup
        messages={messages}
        nextGroup={groups[i + 1]}
        demo={demo}
        key={messages[0].id}
      />
    ),
    [demo, groups],
  );

  const context = useMemo(
    () => ({
      threadId,
    }),
    [threadId],
  );

  return (
    <MessagesListContext.Provider value={context}>
      <StyledVirtuoso
        ref={ref}
        initialTopMostItemIndex={messages.length - 1}
        followOutput={() => 'smooth'}
        itemContent={row}
        alignToBottom={alignToBottom}
        data={groups}
        components={{
          Header: () => <HeaderSpacer />,
          Footer: () => (
            <>
              {footer}
              <FooterSpacer />
            </>
          ),
        }}
        className={className}
      />
    </MessagesListContext.Provider>
  );
};

export default MessagesList;
