import React from 'react';
import type { DropzoneInputProps } from 'react-dropzone';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

type Props = {
  className?: string;
  isDragActive: boolean;
  children?: React.ReactNode;
  hasUpload: boolean;
  disableUploads: boolean;
  getInputProps: () => DropzoneInputProps;
  onUploadClick: () => void;
  /** Still show input, but with gray background. */
  disabled?: boolean;
  title?: string;
  height?: number;
};

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 100%;
  outline-style: none;
`;

export const UploadRoot = styled.div<{
  hasUpload: boolean;
  isDragActive: boolean;
  $height?: number;
  $disabled?: boolean;
}>`
  display: flex;
  height: ${({ hasUpload }) => (hasUpload ? `56px` : '80px')};
  border-radius: ${({ theme }) => theme.radius.medium};
  border: ${({ theme, isDragActive }) =>
    isDragActive ? `1px solid ${theme.color.blue}` : `1px dashed ${theme.color.gray}`};
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.textLight};
  user-select: none;
  outline-style: none;
  background-color: ${({ theme, isDragActive }) =>
    isDragActive ? theme.color.blueBackground : 'inherit'};

  :hover {
    border: 1px dashed ${({ theme }) => theme.color.blue};
    cursor: pointer;
    color: ${({ theme }) => theme.color.blue};
  }

  ${({ $height }) =>
    $height &&
    css`
      height: ${$height}px;
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      background-color: ${({ theme }) => theme.color.grayBackground};
    `}
`;

const PaperClip = styled(Icon)`
  width: 10px;
  height: 10px;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

export const UploadText = styled.span`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const DragAndDropFileUploadField = ({
  className,
  isDragActive,
  children,
  hasUpload,
  getInputProps,
  disableUploads,
  onUploadClick,
  disabled,
  title = 'Upload a file',
  height,
}: Props) => (
  <Root className={className}>
    {children}
    {!disableUploads && (
      <UploadRoot
        isDragActive={isDragActive}
        hasUpload={hasUpload}
        onClick={onUploadClick}
        $height={height}
        $disabled={disabled}
      >
        <input {...getInputProps()} />
        <FlexContainer marginVertical="xlarge" alignCenter>
          <PaperClip name="paperclip" />
          <UploadText>{title}</UploadText>
        </FlexContainer>
      </UploadRoot>
    )}
  </Root>
);

export default DragAndDropFileUploadField;
