import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import QuestionnaireHeader from 'components/advice/QuestionnaireHeader';
import DemoOnboardingUserDetails from 'components/demo/DemoOnboardingUserDetails';
import DemoOnboardingWelcome from 'components/demo/DemoOnboardingWelcome';
import OnboardingCardPage from 'components/lib/layouts/OnboardingCardPage';
import OnboardingPage from 'components/lib/layouts/OnboardingPage';
import RouteFlow from 'components/utils/RouteFlow';

import { clearProviderEphemeralData } from 'actions';
import { getUserIsLoggedIn } from 'common/state/user/selectors';
import useDispatch from 'lib/hooks/useDispatch';

import routes from 'constants/routes';

const STEPS = [
  {
    component: DemoOnboardingWelcome,
    route: routes.demo,
    title: 'Create a Demo User',
    pageName: 'Demo Onboarding Welcome',
  },
  {
    component: DemoOnboardingUserDetails,
    route: routes.demo.userDetails,
    title: 'Demo user details',
    pageName: 'Demo User Details',
  },
];

const DemoOnboardingFlow = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isLoggedIn = useSelector(getUserIsLoggedIn);
  const clearEphemeralData = () => dispatch(clearProviderEphemeralData());

  const steps = STEPS;

  useEffect(() => {
    if (isLoggedIn) {
      history.replace(routes.dashboard() ?? '/');
    }
  }, []);

  const activeStepIndex = R.clamp(
    0,
    steps.length - 1,
    steps.findIndex(({ route }) => pathname === route()),
  );
  const { pageName, title } = steps[activeStepIndex];

  const progress = activeStepIndex / (STEPS.length - 1);

  const flow = (
    <RouteFlow
      steps={steps.map(({ component, route }) => [component, route])}
      onComplete={() => {
        history.push(routes.dashboard());
        clearEphemeralData();
      }}
    />
  );

  return activeStepIndex === 0 ? (
    <OnboardingCardPage name={pageName} overrideTitle={title}>
      {flow}
    </OnboardingCardPage>
  ) : (
    <OnboardingPage
      name={pageName}
      overrideTitle={title}
      header={
        <QuestionnaireHeader
          progress={progress}
          showBackButton
          onClickBackButton={() => history.goBack()}
        />
      }
    >
      {flow}
    </OnboardingPage>
  );
};

export default DemoOnboardingFlow;
