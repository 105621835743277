import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import LoggedOutCardPage from 'components/lib/layouts/LoggedOutCardPage';
import ManualLink from 'components/lib/ui/link/ManualLink';

const Subtext = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.text};
`;

type Props = {
  onSubmit: ({ recovery_code }: { recovery_code: string }) => void;
  email: string;
};

const RecoveryCodeLogin = ({ onSubmit }: Props) => (
  <LoggedOutCardPage
    displayTitle="Recovery Code"
    description="You can enter one of your recovery codes in case you lost access to your mobile device."
    name="Recovery Code"
    subtext={
      <Subtext>
        Still having problems?{' '}
        <ManualLink href="mailto:support@monarchmoney.com?subject=Issue with MFA">
          Contact us
        </ManualLink>
      </Subtext>
    }
  >
    <Form initialValues={{ recovery_code: '' }} onSubmit={onSubmit}>
      <TextField name="recovery_code" label="Recovery Code" required />
      <FormSubmitButton>Verify</FormSubmitButton>
    </Form>
  </LoggedOutCardPage>
);

export default RecoveryCodeLogin;
