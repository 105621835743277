import React, { useMemo, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import CurrencyField from 'components/lib/form/CurrencyField';
import SelectField from 'components/lib/form/SelectField';
import FlexContainer from 'components/lib/ui/FlexContainer';

import {
  amountFilterOptions,
  between,
  allAmounts,
  greaterThan,
  minAmount,
  maxAmount,
} from 'lib/transactions/Filters';

const AmountRow = styled(FlexContainer)<{ $showBottomMargin?: boolean }>`
  margin-bottom: ${({ theme, $showBottomMargin }) => $showBottomMargin && theme.spacing.default};
`;

const StyledSelectField = styled(SelectField)`
  flex: 1;
  margin-right: ${({ theme, showAmountInput }) => showAmountInput && theme.spacing.default};
`;

const StyledFlexContainer = styled(FlexContainer)`
  flex: 1;
`;

const StyledCurrencyField = styled(CurrencyField)<{ marginLeft?: boolean }>`
  flex: 1;
  margin-left: ${({ theme, marginLeft }) => marginLeft && theme.spacing.xlarge};

  input {
    width: 100%;
  }
`;

type Props = {
  hideLabel?: boolean;
};

const TransactionAmountFilter = ({ hideLabel }: Props) => {
  const exactInputRef = useRef<HTMLInputElement>(null);
  const minimumInputRef = useRef<HTMLInputElement>(null);
  const { getFieldMeta } = useFormContext();

  const amountFilterValue = getFieldMeta('amountFilter').value;

  const showBetween = useMemo(() => amountFilterValue === between, [amountFilterValue]);
  const showAmountInput = useMemo(
    () => amountFilterValue && amountFilterValue !== between && amountFilterValue !== allAmounts,
    [amountFilterValue],
  );

  useLayoutEffect(() => {
    if (amountFilterValue === between && minimumInputRef.current) {
      minimumInputRef.current.focus();
    } else if (amountFilterValue !== allAmounts && exactInputRef.current) {
      exactInputRef.current.focus();
    }
  }, [amountFilterValue, minimumInputRef, exactInputRef]);

  return (
    <>
      <AmountRow $showBottomMargin={showBetween || !showAmountInput}>
        <StyledSelectField
          name="amountFilter"
          label="Amounts"
          placeholder="All amounts"
          hideLabel={hideLabel}
          options={amountFilterOptions}
          showAmountInput={showAmountInput}
        />
        {showAmountInput && (
          <StyledFlexContainer alignEnd marginBottom="default">
            <StyledCurrencyField
              name={amountFilterValue === greaterThan ? minAmount : maxAmount}
              placeholder="$1.00"
              maskOptions={{ allowDecimal: true }}
              hideLabel
              ref={exactInputRef}
            />
          </StyledFlexContainer>
        )}
      </AmountRow>
      {showBetween && (
        <FlexContainer>
          <StyledCurrencyField
            name={minAmount}
            label="Minimum"
            placeholder="$1.00"
            maskOptions={{ allowDecimal: true }}
            ref={minimumInputRef}
          />
          <StyledCurrencyField
            name={maxAmount}
            label="Maximum"
            placeholder="$100.00"
            maskOptions={{ allowDecimal: true }}
            marginLeft
          />
        </FlexContainer>
      )}
    </>
  );
};

export default TransactionAmountFilter;
