import { GET_DISPLAY_BALANCE_PREVIEW } from 'common/lib/graphQl/accounts';
import useEventCallback from 'common/lib/hooks/useEventCallback';
import useQuery from 'common/lib/hooks/useQuery';

import type { GetDisplayBalancePreviewQueryVariables } from 'common/generated/graphql';

const useDisplayBalancePreview = (accountId?: string) => {
  const { data, refetch, error, isNetworkRequestInFlight } = useQuery(GET_DISPLAY_BALANCE_PREVIEW, {
    skip: !accountId,
    variables: {
      accountId,
    } as GetDisplayBalancePreviewQueryVariables,
  });

  const displayBalancePreview = data?.account?.displayBalancePreview ?? undefined;

  const refreshDisplayBalancePreview = useEventCallback(
    (invertSyncedBalance?: boolean, useAvailableBalance?: boolean) => {
      refetch({
        accountId,
        invertSyncedBalance,
        useAvailableBalance,
      });
    },
  );

  const isLoading = isNetworkRequestInFlight;

  return { displayBalancePreview, refreshDisplayBalancePreview, isLoading, error };
};

export default useDisplayBalancePreview;
