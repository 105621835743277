import React from 'react';

import AddManualHoldingForm from 'components/accounts/AddManualHoldingDetailsForm';
import type { AddManualHoldingFormValues } from 'components/accounts/AddManualHoldingDetailsForm';
import ModalCard from 'components/lib/ui/ModalCard';

import useToggle from 'common/lib/hooks/useToggle';

type Props = {
  accountId?: string;
  securityId: string;
  skipAccountSelection?: boolean;
  next: (props: { securityId: string; quantity: number; accountId?: string }) => void;
  goBack?: () => void;
};

const AddManualHoldingDetailsFormModal = ({
  accountId,
  skipAccountSelection,
  securityId,
  next,
  goBack,
}: Props) => {
  const [shouldCreateNewAccount, { setOn: showNewAccountFields, setOff: hideNewAccountFields }] =
    useToggle(false);

  const formInitialValues = {
    accountId,
    securityId,
    accountName: undefined,
    accountSubtype: undefined,
  };

  const handleSubmit = async (values: AddManualHoldingFormValues) => {
    next(values);
  };

  const handleClickBackButton = async () => {
    if (shouldCreateNewAccount) {
      hideNewAccountFields();
    } else {
      goBack?.();
    }
  };

  return (
    <ModalCard onClickBackButton={handleClickBackButton} hideBottomBorder>
      <AddManualHoldingForm
        initialValues={formInitialValues}
        showNewAccountFields={shouldCreateNewAccount}
        skipAccountSelection={skipAccountSelection}
        onNewAccountSelected={showNewAccountFields}
        onSubmit={handleSubmit}
      />
    </ModalCard>
  );
};

export default AddManualHoldingDetailsFormModal;
