import { useDroppable } from '@dnd-kit/core';
import React from 'react';
import type { PropsWithChildren } from 'react';
import styled from 'styled-components';

export type DroppableId = string | number;

type Props = PropsWithChildren<{
  id: DroppableId;
  data?: Record<string, unknown>;
  disabled?: boolean;
  className?: string;
}>;

const DroppableRoot = styled.div<{ $isOver: boolean }>`
  outline-offset: -2px;
  outline: 2px solid transparent;

  /* Base styles that can be overridden by className */
  ${({ theme, $isOver }) =>
    $isOver &&
    `
    outline: 2px solid ${theme.color.blue};
  `}
`;

const Droppable = ({ children, id, data, disabled, className }: Props) => {
  const { setNodeRef, isOver, active } = useDroppable({
    id,
    data,
    disabled,
  });

  // Only show outline if dragging from a different droppable
  const showOutline = isOver && active?.data.current?.droppableId !== id;

  return (
    <DroppableRoot ref={setNodeRef} className={className} $isOver={isOver && showOutline}>
      {children}
    </DroppableRoot>
  );
};

export default Droppable;
