import numbro from 'numbro';
import * as RA from 'ramda-adjunct';

export type CurrencyType = 'income' | 'expense' | 'savings';

export const formatCurrency = (currency?: number | null) =>
  !RA.isValidNumber(currency)
    ? '-'
    : numbro(currency).formatCurrency({ thousandSeparated: true, mantissa: 2 });

export const formatCurrencyNoCents = (currency?: number | null) => {
  if (!RA.isNumber(currency) || !RA.isValidNumber(currency)) {
    return '-';
  } else if (Math.round(currency) === 0) {
    // numbro formats -0.05 as -$ which we don't want
    return '$0';
  } else {
    return numbro(currency).formatCurrency({ thousandSeparated: true, mantissa: 0 });
  }
};

/** Only format cents if the value is not round - won't format cents if .00 */
export const formatCurrencyCentsOptional = (currency?: number | null) =>
  !RA.isValidNumber(currency)
    ? '-'
    : numbro(currency).formatCurrency({
        thousandSeparated: true,
        mantissa: 2,
        optionalMantissa: true,
      });

export const parseCurrency = (currency: string) => numbro.unformat(currency);

export const formatCurrencyWithPlusForIncome = (currency: number) =>
  `${currency > 0 ? '+' : ''}${formatCurrency(Math.abs(currency))}`;

/**
 * 2430 -> $2.4k
 * 2020 -> $2k
 * 2000 -> $2k
 * 345 -> $345
 * 1354000 -> $1.35M
 */
export const formatCurrencyThousandsDecimal = (currency: number) => {
  if (Math.abs(currency) > 1000000) {
    // show 2 decimal places for millions
    return numbro(currency).formatCurrency({ average: true, mantissa: 2 }).toUpperCase();
  }
  return numbro(currency)
    .formatCurrency({ average: true, mantissa: 1, optionalMantissa: true })
    .toUpperCase();
};

export const formatCurrencyWithCode = (currency: number, currencyCode: string) =>
  currency.toLocaleString(undefined, {
    style: 'currency',
    currency: currencyCode,
  });

/**
 * If greater than $10,000, format as $10k, etc.
 */
export const formatCurrencyMaybeThousands = (currency: number) => {
  if (Math.abs(currency) >= 10000) {
    return formatCurrencyThousandsDecimal(currency);
  } else {
    return formatCurrencyNoCents(currency);
  }
};

export const maybeRoundNumber = (value: number, round?: boolean) =>
  round ? Math.round(value) : value;

export const getTransactionDisplayAmount = (value: number, round?: boolean) => {
  const rounded = maybeRoundNumber(value, round);
  const absRounded = Math.abs(rounded);
  const formatted = round ? formatCurrencyNoCents(absRounded) : formatCurrency(absRounded);
  return `${rounded > 0 ? '+' : ''}${formatted}`;
};

export const unitValue = (type: CurrencyType) => (type === 'expense' ? -1 : 1);

export const getSign = (value: number, type: CurrencyType) => {
  if (value === 0) {
    return '';
  }

  if (type === 'savings') {
    return value > 0 ? '+' : '-';
  }

  return value < 0 ? '-' : '+';
};
