import { DateTime } from 'luxon';
import * as R from 'ramda';

import type {
  CategoryOrGroupFields,
  MonthlyAmounts,
  MonthlyAmountsData,
} from 'common/lib/budget/types';
import {
  getBudgetVariabilityForItem,
  isIncomeItem,
  getUpdatedBudgetTotals,
  calculateAmountDifference,
} from 'common/lib/budget/utils';

import type { BudgetTotalsByMonthFieldsFragment } from 'common/generated/graphql';
import { BudgetVariability } from 'common/generated/graphql';

const BUDGET_VARIABILITY_TO_KEY_MAPPING = {
  [BudgetVariability.FIXED]: 'totalFixedExpenses',
  [BudgetVariability.FLEXIBLE]: 'totalFlexibleExpenses',
  [BudgetVariability.NON_MONTHLY]: 'totalNonMonthlyExpenses',
} as const;

const shouldApplyUpdateToMonth = ({
  month,
  startDate,
  applyToFuture,
}: {
  month: string;
  startDate: DateTime;
  applyToFuture?: boolean;
}) => {
  const isCurrentMonthOrFuture = DateTime.fromISO(month) >= startDate;
  const isCurrentMonth = month === startDate.toISODate();
  return applyToFuture ? isCurrentMonthOrFuture : isCurrentMonth;
};

export const getOptimisticMonthlyAmounts = <
  T extends {
    monthlyAmounts: MonthlyAmounts[];
  },
>(
  data: T,
  plannedCashFlowAmount: number | undefined,
  startDate: DateTime,
  applyToFuture?: boolean,
) =>
  R.evolve(
    {
      monthlyAmounts: R.map(
        R.when(
          ({ month }) => shouldApplyUpdateToMonth({ month, startDate, applyToFuture }),
          (amounts: MonthlyAmounts) => ({
            ...amounts,
            plannedCashFlowAmount,
            // optimistically set remaining based on the difference between the new planned amount & old planned amount
            remainingAmount:
              (amounts.remainingAmount ?? 0) +
              ((plannedCashFlowAmount ?? 0) - (amounts.plannedCashFlowAmount ?? 0)),
          }),
        ),
      ),
    },
    data,
  ) as T;

export const getOptimisticTotalsAmounts = (
  categoryOrGroup: CategoryOrGroupFields,
  currentTotals: BudgetTotalsByMonthFieldsFragment,
  monthlyAmounts: MonthlyAmountsData,
  newAmount: number | undefined,
  targetMonth: string,
) => {
  const difference = calculateAmountDifference(monthlyAmounts, newAmount ?? 0, targetMonth);
  const updates: Partial<BudgetTotalsByMonthFieldsFragment> = {};

  // Update main total (income or expenses)
  const mainTotalKey = isIncomeItem(categoryOrGroup) ? 'totalIncome' : 'totalExpenses';
  updates[mainTotalKey] = getUpdatedBudgetTotals(currentTotals[mainTotalKey], difference);

  // Update variability total if applicable
  const budgetVariability = getBudgetVariabilityForItem(categoryOrGroup);
  if (budgetVariability) {
    const variabilityKey = BUDGET_VARIABILITY_TO_KEY_MAPPING[budgetVariability];
    updates[variabilityKey] = getUpdatedBudgetTotals(currentTotals[variabilityKey], difference);
  }

  return { ...currentTotals, ...updates };
};

export const getOptimisticTotalsAmountsForFlexibleBudget = (
  currentTotals: BudgetTotalsByMonthFieldsFragment,
  monthlyAmounts: MonthlyAmountsData,
  newAmount: number,
  targetMonth: string,
) => {
  const difference = calculateAmountDifference(monthlyAmounts, newAmount, targetMonth);

  return {
    ...currentTotals,
    totalFlexibleExpenses: getUpdatedBudgetTotals(currentTotals.totalFlexibleExpenses, difference),
    totalExpenses: getUpdatedBudgetTotals(currentTotals.totalExpenses, difference),
  };
};
