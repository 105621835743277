import type { DurationLikeObject } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';

import type { TooltipComponentProps } from 'components/lib/charts/ChartTooltip';
import DateValueTooltip from 'components/lib/charts/DateValueTooltip';
import SimpleLineChart from 'components/lib/charts/SimpleLineChart';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import Flex from 'components/lib/ui/Flex';
import Text from 'components/lib/ui/Text';
import TimeframeTrendIndicator from 'components/lib/ui/TimeframeTrendIndicator';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import IconButton from 'components/lib/ui/button/IconButton';
import { OverlayTrigger } from 'components/lib/ui/popover';

import type { SnapshotEntry } from 'common/lib/accounts/accountCharts';
import { getSnapshotDataForTimePeriod } from 'common/lib/accounts/accountCharts';
import THEME from 'common/lib/theme/staticTheme';
import { formatCurrency, formatCurrencyThousandsDecimal } from 'common/utils/Currency';
import { isoDateToMonthAbbreviationAndDay } from 'common/utils/date';
import { getLineColorByAccountType } from 'lib/accounts/AccountGraphs';
import useMeasure from 'lib/hooks/useMeasure';

const GRAPH_HEIGHT_PX = 275;
const ROOT_PADDING = parseInt(THEME.spacing.default, 10);
const LEFT_MARGIN = 10;

const Root = styled.div`
  padding: ${ROOT_PADDING}px;

  /* For some reason, the account balance graph has two strokes at the top */
  g.recharts-cartesian-grid-horizontal > line:nth-last-child(2) {
    stroke-opacity: 0;
  }
`;

const Header = styled(Flex)`
  margin: ${({ theme }) => theme.spacing.xsmall} 0px ${({ theme }) => theme.spacing.xlarge}
    ${({ theme }) => theme.spacing.xsmall};
`;

const HeaderSubtext = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  color: ${({ theme }) => theme.color.textLight};
`;

const StyledTimeframeTrendIndicator = styled(TimeframeTrendIndicator)`
  margin-bottom: 6px;
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const ARROW_WIDTH_PX = 32;
const PanArrow = styled(IconButton)<{ invisible: boolean }>`
  width: ${ARROW_WIDTH_PX}px;
  height: ${ARROW_WIDTH_PX}px;
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  border-radius: 100%;
  opacity: ${({ invisible }) => (invisible ? 0 : 1)};
  cursor: ${({ invisible }) => (invisible ? 'inherit' : 'pointer')};
  transition: opacity 0.2s ease-in-out;
`;

const StyledDefaultButton = styled(DefaultButton)`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  display: flex;
  justify-content: space-between;
`;

const DateRangeOptionButton = styled(StyledDefaultButton)`
  width: 160px;
  height: 32px;
`;

type Props<XAxisT, YAxisT, DataT extends Record<string, unknown>> = {
  accountTypeName: string;
  isAsset: boolean;
  snapshotData: SnapshotEntry[];
  currentBalanceLabel: string;
  displayBalance: number | undefined;
  roundCurrentBalance?: boolean;
  tooltipComponent?: TooltipComponentProps<XAxisT, YAxisT, SnapshotEntry>;
  availableTimePeriods: { display: string; duration: DurationLikeObject; longDisplay: string }[];
  selectedTimePeriodIndex: number;
  onSelectTimePeriod: (index: number) => Promise<void>;
  onClickBalance?: (data: { date: string; balance: number }) => void;
};

// TODO - dynamic left / offsetX based on decimal values
const AccountBalanceGraph = <
  XAxisT extends string,
  YAxisT extends number,
  DataT extends SnapshotEntry,
>({
  accountTypeName,
  isAsset,
  snapshotData,
  currentBalanceLabel,
  displayBalance,
  tooltipComponent,
  availableTimePeriods,
  selectedTimePeriodIndex,
  onSelectTimePeriod,
  onClickBalance,
}: Props<XAxisT, YAxisT, DataT>) => {
  const theme = useTheme();
  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();
  const [offset, setOffset] = useState(0);

  const currentBalance = R.last(snapshotData)?.balance ?? 0;

  const { duration, longDisplay } = availableTimePeriods[selectedTimePeriodIndex];

  useEffect(() => {
    setOffset(0);
  }, [selectedTimePeriodIndex]);

  const { data: chartData, hasOlderData } = getSnapshotDataForTimePeriod(
    duration,
    snapshotData,
    offset,
  );

  const currentPeriodStartAmount: number = useMemo((): number => {
    const { data: currentPeriodData } = getSnapshotDataForTimePeriod(duration, snapshotData, 0);
    return R.head(currentPeriodData)?.balance ?? currentBalance;
  }, [duration, snapshotData, currentBalance]);

  const lineColor = getLineColorByAccountType(accountTypeName, theme);

  const isRightArrowVisible = offset > 0;
  const isLeftArrowVisible = hasOlderData;

  const onPanLeft = () => {
    if (isLeftArrowVisible) {
      setOffset(offset + 1);
    }
  };

  const onPanRight = () => {
    if (isRightArrowVisible) {
      setOffset(offset - 1);
    }
  };

  const extraPaddingForArrows = ARROW_WIDTH_PX * 2 + ROOT_PADDING * 2;
  const isAnyArrowVisible = isRightArrowVisible || isLeftArrowVisible;

  const contextualMenuOptions = useMemo(
    () =>
      availableTimePeriods.map((dateRange, i) => ({
        value: dateRange,
        label: dateRange.longDisplay,
        onClick: () => onSelectTimePeriod(i),
      })),
    [onSelectTimePeriod],
  );

  const onClickDot = useCallback(
    (data: { payload: { date: string; balance: number } }) => {
      onClickBalance?.(data.payload);
    },
    [onClickBalance],
  );

  return (
    <Root ref={ref}>
      <Header justifyBetween>
        <Flex column>
          <HeaderSubtext>{currentBalanceLabel}</HeaderSubtext>
          <Flex alignEnd {...sensitiveClassProps}>
            <Text size="xlarge" weight="medium">
              {formatCurrency(displayBalance)}
            </Text>
            <StyledTimeframeTrendIndicator
              startAmount={currentPeriodStartAmount}
              endAmount={currentBalance}
              timeframeDisplay={`${longDisplay} change`}
              isAsset={isAsset}
            />
          </Flex>
        </Flex>

        <OverlayTrigger
          placement="bottom-end"
          overlay={
            <DropdownMenu>
              {contextualMenuOptions.map(({ label, onClick }) => (
                <DropdownMenuItem key={label} onClick={onClick}>
                  {label}
                </DropdownMenuItem>
              ))}
            </DropdownMenu>
          }
        >
          {({ toggleOpen, isOpen }) => (
            <DateRangeOptionButton onClick={toggleOpen} active={isOpen}>
              <span>{longDisplay}</span>
              <ButtonIcon name="chevron-down" />
            </DateRangeOptionButton>
          )}
        </OverlayTrigger>
      </Header>

      <Flex justifyBetween alignCenter>
        <PanArrow icon="arrow-left" invisible={!isLeftArrowVisible} onClick={onPanLeft} />

        <SimpleLineChart
          height={GRAPH_HEIGHT_PX}
          lineColor={theme.color.text}
          width={width - ROOT_PADDING - (isAnyArrowVisible ? extraPaddingForArrows : 0)}
          margin={{
            left: LEFT_MARGIN,
          }}
          data={chartData}
          tooltipComponent={
            tooltipComponent ||
            (({ active, payload }) =>
              RA.isNotNil(payload) ? (
                <DateValueTooltip
                  active={active}
                  value={payload[0]?.payload.balance}
                  date={payload[0]?.payload.date}
                  label="Balance"
                  dotFillColor={lineColor}
                />
              ) : null)
          }
          domain={['dataMin', 'dataMax']}
          yPadding={{ top: 3, bottom: 3 }}
          yDataKey="balance"
          xDataKey="date"
          formatYAxis={formatCurrencyThousandsDecimal}
          formatXAxis={isoDateToMonthAbbreviationAndDay}
          smoothGradient
          onClickDot={onClickDot}
        />

        <PanArrow icon="arrow-right" invisible={!isRightArrowVisible} onClick={onPanRight} />
      </Flex>
    </Root>
  );
};

export default AccountBalanceGraph;
