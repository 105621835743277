import React from 'react';
import styled, { keyframes } from 'styled-components';

import { radius } from 'common/lib/theme/dynamic';

const DEFAULT_CIRCLE_SIZE_PX = 20;

const Root = styled.div<{ $height?: string; $width?: string }>`
  height: ${({ $height }) => $height || '100%'};
  width: ${({ $width }) => $width || '100%'};
  min-height: 1em;
  border-radius: ${({ theme }) => theme.radius.small};
  background: ${({ theme }) => theme.color.grayLightBackground};
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.color.grayLightBackground} 0%,
    ${({ theme }) => theme.color.grayBackground} 20%,
    ${({ theme }) => theme.color.grayLightBackground} 40%,
    ${({ theme }) => theme.color.grayLightBackground} 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  animation: ${keyframes`
        0% {
            background-position: 100% 0;
        }
        100% {
            background-position: -100% 0;
        }
    `} 1s infinite ease-out;
`;

export const Skeleton = ({ width, height }: Partial<{ width: string; height: string }>) => (
  <Root $width={width} $height={height} />
);

export const SkeletonCircle = styled(Root)<{ $size?: number }>`
  border-radius: ${radius.round};
  width: ${({ $size }) => $size || DEFAULT_CIRCLE_SIZE_PX}px;
  height: ${({ $size }) => $size || DEFAULT_CIRCLE_SIZE_PX}px;
  flex-shrink: 0;
`;

/**
 * Element that conditionally renders a placeholder component if isLoading is true.
 *
 * Useful so you don't have to use ternary operators everywhere you use the loading state.
 */
export const WithLoadingPlaceholder = ({
  isLoading,
  children,
  placeholderComponent,
}: {
  isLoading: boolean;
  children: React.ReactNode;
  placeholderComponent: React.ReactNode;
}) => {
  if (isLoading) {
    return <>{placeholderComponent}</>;
  }
  return <>{children}</>;
};

export default Root;
