import { DateTime } from 'luxon';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import { CardInner, Row } from 'components/cashFlows/FilteredCashFlowSummaryCard';
import ResetRolloverConfirmation from 'components/categories/ResetRolloverConfirmation';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';
import Currency from 'components/lib/ui/currency/Currency';

import useModal from 'lib/hooks/useModal';

import { gql } from 'common/generated/gql';
import type { CategoryPlanSummaryFieldsFragment } from 'common/generated/graphql';

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const Footer = styled(FlexContainer).attrs({ center: true })`
  padding: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  categoryId: string;
  data?: CategoryPlanSummaryFieldsFragment | null;
  onResetRollover?: () => void;
  loading?: boolean;
  isCategoryGroup?: boolean;
  groupLevelBudgetingEnabled?: boolean | null;
  className?: string;
  rolloverStartingBalance?: number | null;
};

const CategoryPlanSummaryCard = ({
  categoryId,
  data,
  onResetRollover,
  loading,
  isCategoryGroup,
  className,
  rolloverStartingBalance,
  groupLevelBudgetingEnabled,
}: Props) => {
  const {
    month,
    previousMonthRolloverAmount,
    plannedAmount,
    actualAmount,
    remainingAmount,
    rolloverType,
  } = data ?? {};

  const isRollover = RA.isNotNil(rolloverType);

  const [ResetRolloverModal, { open: openResetRollover, close: closeResetRollover }] = useModal();

  return (
    <Card
      title="Budget"
      description={
        month && !loading ? (
          <Text color="textLight">{DateTime.fromISO(month).toFormat('MMMM y')}</Text>
        ) : undefined
      }
      className={className}
    >
      {loading ? (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ) : (
        <>
          <CardInner>
            {isRollover && (!isCategoryGroup || groupLevelBudgetingEnabled) && (
              <>
                <Row
                  text="Starting balance"
                  value={
                    <CashFlowCurrency
                      round
                      emphasis
                      type="income"
                      value={rolloverStartingBalance ?? 0}
                    />
                  }
                />
                <Row
                  text="Rolled over last month"
                  value={
                    <CashFlowCurrency
                      round
                      emphasis
                      type="income"
                      value={previousMonthRolloverAmount ?? 0}
                    />
                  }
                />
              </>
            )}
            <Row text="Budget" value={<Currency value={plannedAmount ?? undefined} round />} />
            <Row text="Actual" value={<Currency value={actualAmount ?? undefined} round />} />
            <Row
              text={isRollover ? 'Remaining to rollover' : 'Remaining'}
              value={<CashFlowCurrency round emphasis type="income" value={remainingAmount ?? 0} />}
            />
          </CardInner>
          {!isCategoryGroup && isRollover && !!month && (
            <Footer>
              <TextButton onClick={openResetRollover} disabled={!previousMonthRolloverAmount}>
                Reset Rollover
              </TextButton>
              <ResetRolloverModal>
                <ResetRolloverConfirmation
                  categoryId={categoryId}
                  startMonth={month}
                  onCompleted={() => {
                    closeResetRollover();
                    onResetRollover?.();
                  }}
                />
              </ResetRolloverModal>
            </Footer>
          )}
        </>
      )}
    </Card>
  );
};

CategoryPlanSummaryCard.fragments = {
  CategoryPlanSummaryFields: gql(/* GraphQL */ `
    fragment CategoryPlanSummaryFields on BudgetMonthlyAmounts {
      month
      plannedAmount
      actualAmount
      remainingAmount
      previousMonthRolloverAmount
      rolloverType
    }
  `),
};

export default CategoryPlanSummaryCard;
