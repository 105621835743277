import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Tooltip from 'components/lib/ui/Tooltip';

type Props = {
  name: string;
  fieldId: string;
  children?: React.ReactNode;
  label?: string;
  labelRight?: React.ReactNode;
  description?: string | React.ReactNode;
  hideLabel?: boolean;
  errors?: string[];
  className?: string;
  tooltip?: string | React.ReactNode;
  tooltipProps?: Omit<React.ComponentProps<typeof Tooltip>, 'content' | 'children'>;
};

const Root = styled(Flex)`
  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }
`;

export const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const ErrorLabel = styled.label`
  color: ${({ theme }) => theme.color.red};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

const Description = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.textLight};
  line-height: 150%;
`;

const TooltipIcon = styled(Icon).attrs({ name: 'info' })`
  margin-left: ${({ theme }) => theme.spacing.xxsmall};
`;

const LabelContainer = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const FormItemContainer = ({
  children,
  label,
  labelRight,
  hideLabel,
  errors,
  name,
  fieldId,
  description,
  tooltip,
  tooltipProps,
  className,
}: Props) => (
  <Root className={className} column>
    {(!hideLabel || !!labelRight) && (
      <LabelContainer>
        {!hideLabel && (
          <Label htmlFor={fieldId}>
            {label}{' '}
            {RA.isNotNil(tooltip) && (
              <Tooltip place="right" {...(tooltipProps ?? {})} content={tooltip}>
                <TooltipIcon />
              </Tooltip>
            )}
          </Label>
        )}
        {labelRight && <FlexContainer justifyEnd>{labelRight}</FlexContainer>}
      </LabelContainer>
    )}
    {children}
    {errors?.map((error) => (
      <ErrorLabel key={error} htmlFor={name}>
        {error}
      </ErrorLabel>
    ))}
    {!!description && !errors?.length && <Description>{description}</Description>}
  </Root>
);

export default FormItemContainer;
