import { sankeyLinkHorizontal } from 'd3-sankey';
import React from 'react';

import useTheme from 'lib/hooks/useTheme';
import type { Identifiable, Link, LinkWithExtraProps } from 'lib/ui/sankey';

type Props<N extends Identifiable, L extends Link> = {
  link: LinkWithExtraProps<N, L>;
};

const SankeyDiagramLink = <N extends Identifiable, L extends Link>({ link }: Props<N, L>) => {
  const theme = useTheme();
  const d = sankeyLinkHorizontal()(link);

  if (d === null) {
    throw new Error();
  }

  const { source, target, index, width } = link;

  return (
    <>
      <defs>
        <linearGradient
          id={`gradient-${index}`}
          gradientUnits="userSpaceOnUse"
          x1={source.x1}
          x2={target.x0}
        >
          <stop offset="0" stopColor={source.color ?? theme.color.grayFocus} />
          <stop offset="100%" stopColor={target.color ?? theme.color.grayFocus} />
        </linearGradient>
      </defs>
      <path
        d={d}
        fill="none"
        stroke={`url(#gradient-${index})`}
        strokeOpacity={0.25}
        strokeWidth={width}
        style={{ mixBlendMode: theme.uiTheme === 'dark' ? 'unset' : 'multiply' }}
        className="link"
        data-target-id={target.id}
        data-source-id={source.id}
      />
    </>
  );
};

export default SankeyDiagramLink;
