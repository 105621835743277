import { gql } from 'common/generated/gql';

export const CATEGORY_GROUP_FIELDS_FRAGMENT = gql(`
  fragment CategoryGroupFields on CategoryGroup {
    id
    name
    order
    type
    color
    groupLevelBudgetingEnabled
    budgetVariability
    rolloverPeriod {
      id
      startMonth
      endMonth
      startingBalance
    }
  }
`);

export const UPDATE_CATEGORY_GROUP = gql(`
  mutation Common_UpdateCategoryGroup($input: UpdateCategoryGroupInput!) {
    updateCategoryGroup(input: $input) {
      categoryGroup {
        ...CategoryGroupFields
      }
    }
  }
`);

export const CREATE_CATEGORY_GROUP = gql(`
  mutation Common_CreateCategoryGroup($input: CreateCategoryGroupInput!) {
    createCategoryGroup(input: $input) {
      categoryGroup {
        ...CategoryGroupFields
      }
    }
  }
`);

export const GET_CATEGORY_GROUP = gql(`
  query GetCategoryGroup($id: UUID!, $includeDisabledSystemCategories: Boolean) {
    categoryGroup(id: $id) {
      ...CategoryGroupFields
      categories(includeDisabledSystemCategories: $includeDisabledSystemCategories) {
        id
        name
        icon
      }
    }
  }
`);

export const GET_CATEGORY_GROUPS = gql(`
  query GetCategoryGroups {
    categoryGroups {
      ...CategoryGroupFields
    }
  }
`);

export const DELETE_CATEGORY_GROUP = gql(`
  mutation Common_DeleteCategoryGroup($id: UUID!, $moveToGroupId: UUID) {
    deleteCategoryGroup(id: $id, moveToGroupId: $moveToGroupId) {
      deleted
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);
