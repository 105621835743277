import * as RA from 'ramda-adjunct';
import { createContext } from 'react';

export type CashFlowContextType = {
  hideAmounts: boolean;
  shareDisabled: boolean;
  toggleHideAmounts: () => void;
  unhideAmounts: () => void;
};

const CashFlowContext = createContext<CashFlowContextType>({
  hideAmounts: false,
  shareDisabled: false,
  toggleHideAmounts: RA.noop,
  unhideAmounts: RA.noop,
});

export default CashFlowContext;
