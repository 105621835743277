import * as RA from 'ramda-adjunct';
import * as Yup from 'yup';

import { equalsIgnoreCase } from 'common/utils/String';

type Tag = { name: string; id: string };

export const getFormValidationSchema = <T extends Tag>(
  tags: T[],
  existingTag: Partial<T> | undefined,
) =>
  Yup.object().shape({
    name: Yup.string()
      .required('Name is required.')
      .max(45, ({ max }) => `Name must be at most ${max} characters.`)
      .test('unique-tag', 'A tag with this name already exists.', (value: string | undefined) => {
        const match = tags.find(
          ({ name, id }) => equalsIgnoreCase(name?.trim(), value?.trim()) && id !== existingTag?.id,
        );
        return !match;
      }),
    color: Yup.string().required(),
  });

export const getOptionFromTag = <TData extends Tag & { color: string }>({
  id,
  name,
  color,
}: TData) => ({
  value: id,
  label: name,
  icon: color,
});

export const sortTags = <T extends Tag & { order: number }>(tags: T[]) =>
  RA.sortByProps(['order'], tags) as T[];
