const launchZendesk = (email?: string) => {
  zE('webWidget', 'prefill', {
    email: {
      value: email,
    },
  });

  zE('webWidget', 'show');
  zE('webWidget', 'open');

  // This part is a little weird, we need to hide the zendesk button again after the
  // form is closed:
  // https://support.zendesk.com/hc/en-us/community/posts/360034863473-Hide-Web-Widget
  zE('webWidget:on', 'close', () => {
    zE('webWidget', 'hide');
  });
};

export default launchZendesk;
