import React from 'react';
import styled from 'styled-components';

import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { useModalContext } from 'lib/contexts/ModalContext';

const StyledDefaultButton = styled(DefaultButton)`
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const ModalCancelButton = () => {
  const { close } = useModalContext();

  return <StyledDefaultButton onClick={close}>Cancel</StyledDefaultButton>;
};

export default ModalCancelButton;
