import type { MutationHookOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { DateTime } from 'luxon';
import { useCallback } from 'react';

import { CREATE_OR_UPDATE_GOAL_V2_PLANNED_CONTRIBUTION_MUTATION } from 'common/lib/graphQl/goalsV2';

import type {
  CreateOrUpdateGoalV2PlannedContributionMutation,
  CreateOrUpdateGoalV2PlannedContributionMutationVariables,
} from 'common/generated/graphQlTypes/CreateOrUpdateGoalV2PlannedContributionMutation';

const useUpdateGoalV2PlannedContribution = (
  options?: MutationHookOptions<
    CreateOrUpdateGoalV2PlannedContributionMutation,
    CreateOrUpdateGoalV2PlannedContributionMutationVariables
  >,
) => {
  const [updateOrCreateGoalV2PlannedContribution] = useMutation<
    CreateOrUpdateGoalV2PlannedContributionMutation,
    CreateOrUpdateGoalV2PlannedContributionMutationVariables
  >(CREATE_OR_UPDATE_GOAL_V2_PLANNED_CONTRIBUTION_MUTATION, options);

  const updateGoalV2PlannedContribution = useCallback(
    async (date: DateTime, goalId: string, amount: number | null) => {
      await updateOrCreateGoalV2PlannedContribution({
        variables: {
          input: {
            month: date.toISODate(),
            goalId,
            amount: amount ?? 0,
          },
        },
      });
    },
    [updateOrCreateGoalV2PlannedContribution],
  );

  return { updateGoalV2PlannedContribution };
};

export default useUpdateGoalV2PlannedContribution;
