import React from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

import boxShadow from 'common/lib/styles/boxShadow';

import type { Color, ColorOrTransparent } from 'types/Styles';

const Root = styled(FlexContainer).attrs<{ active?: boolean }>(({ active, ...props }) => ({
  ...props,
  alignCenter: true,
  className: active ? 'active' : undefined,
}))<{
  backgroundColor: ColorOrTransparent;
  hoverBackgroundColor: ColorOrTransparent;
  textColor: Color;
  active?: boolean;
}>`
  padding: ${({ theme }) => theme.spacing.xxsmall} ${({ theme }) => theme.spacing.small};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor === 'transparent' ? 'transparent' : theme.color[backgroundColor]};
  color: ${({ textColor, theme }) => theme.color[textColor]};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  border-radius: ${({ theme }) => theme.radius.pill};
  user-select: none;
  transition: ${({ theme }) => theme.transition.default};

  ${({ onClick, hoverBackgroundColor, theme }) =>
    !!onClick &&
    css`
      cursor: pointer;

      :hover {
        background-color: ${hoverBackgroundColor === 'transparent'
          ? 'transparent'
          : theme.color[hoverBackgroundColor]};
      }
      :active,
      &.active {
        background-color: ${hoverBackgroundColor === 'transparent'
          ? 'transparent'
          : theme.color[hoverBackgroundColor]};
        ${boxShadow.inset}
      }
    `}
`;

const StyledIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

export type PillProps = {
  backgroundColor?: ColorOrTransparent;
  hoverBackgroundColor?: ColorOrTransparent;
  textColor?: Color;
  /** Icon name, will be displayed to the left of the text. */
  icon?: string;
  /** Adds 'active' class to simulate forcing :active */
  active?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
};

/** Component used to display text in a rounded rect with an optional icon. */
const Pill = ({
  backgroundColor = 'grayBackground',
  hoverBackgroundColor = 'grayLight',
  textColor = 'textLight',
  icon,
  active,
  onClick,
  children,
  className,
}: PillProps) => (
  <Root
    className={className}
    backgroundColor={backgroundColor}
    hoverBackgroundColor={hoverBackgroundColor}
    textColor={textColor}
    onClick={onClick}
    active={active}
  >
    {!!icon && <StyledIcon name={icon} />}
    <span>{children}</span>
  </Root>
);

export default Pill;
