import React from 'react';

import { ButtonGroup } from 'components/lib/ui/ButtonGroup';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { capitalize } from 'common/utils/String';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';

const TIMEFRAME_TABS = [PlanTimeframe.Monthly, PlanTimeframe.Yearly];

type Props = {
  timeframe: PlanTimeframe;
  onChangeTimeframe: (timeframe: PlanTimeframe) => void;
};

const PlanHeaderTimeframeToggle = ({ timeframe, onChangeTimeframe }: Props) => (
  <ButtonGroup>
    {TIMEFRAME_TABS.map((tab) => (
      <DefaultButton key={tab} onClick={() => onChangeTimeframe(tab)} active={timeframe === tab}>
        {capitalize(tab)}
      </DefaultButton>
    ))}
  </ButtonGroup>
);

export default React.memo(PlanHeaderTimeframeToggle);
