import { pascalCase } from 'change-case';
import * as R from 'ramda';
import React from 'react';
import type { IconType } from 'react-icons';
import * as FeatherIcons from 'react-icons/fi';
import styled, { css } from 'styled-components';

import useMeasure from 'lib/hooks/useMeasure';

import glyphmap from 'static/fonts/MonarchIcons/glyphmap.json';

const MONARCH_ICON_PREFIX = 'monarch-';

const MonarchIcon = styled.span<{ $size?: number }>`
  font-family: MonarchIcons, sans-serif !important;
  -webkit-font-smoothing: antialiased;

  ${({ $size }) =>
    !!$size &&
    css`
      font-size: ${$size}px;
      line-height: ${$size}px;
      width: ${$size}px;
      height: ${$size}px;
    `}
`;

/**
 * DEPRECATED in favor of custom MonarchIcons font
 */
const ICON_URI_MAP = {
  'apple-logo': require('static/icons/apple-logo.svg'),
  'google-logo': require('static/icons/google-logo.svg'),
  'google-play-logo': require('static/icons/google-play-logo.svg'),
  'vinaudit-logo': require('static/icons/vinaudit-logo.svg'),
  'piggy-bank': require('static/icons/advice/piggy-bank.svg'),
  'credit-check': require('static/icons/advice/credit-check.svg'),
  flower: require('static/icons/advice/flower.svg'),
  'hand-heart': require('static/icons/advice/hand-heart.svg'),
  'health-shield': require('static/icons/advice/health-shield.svg'),
  'shopping-bag': require('static/icons/advice/shopping-bag.svg'),
};

/** Returns true if an icon with matching name exists in the MonarchIcons font. Otherwise returns false. */
export const monarchIconExists = (iconName: string) => R.has(iconName, glyphmap);

export type IconProps = {
  name: string;
  size?: number;
  className?: string;
} & React.HTMLAttributes<HTMLSpanElement>;

const Icon = ({ name, size, className, ...props }: IconProps) => {
  const [ref, { height, width }] = useMeasure();

  if (monarchIconExists(name)) {
    /* Custom font icon (MonarchIcons)

    For icons that use our custom font, we size them with font-size. However, in a lot
    of places we use width/height with Icon, so we need to handle that. Basically, if
    size is not provided, then we measure the width/height of the icon and use that as font-size. */
    return (
      <MonarchIcon
        className={className}
        $size={size || height || width}
        ref={size ? undefined : ref}
        {...props}
      >
        {
          // @ts-ignore
          String.fromCharCode(glyphmap[name])
        }
      </MonarchIcon>
    );
  } else if (name.startsWith(MONARCH_ICON_PREFIX)) {
    /* DEPRECATED - custom svg icons
     */
    const iconName = R.tail(name.split('-')).join('-');

    // may cause issues if we want to do SSR, should lazy load this component
    // @ts-ignore
    const uri: string | undefined = ICON_URI_MAP[iconName];

    if (!uri) {
      throw new Error(`Icon with name ${name} does not exist`);
    }

    return (
      <img
        className={className}
        src={uri}
        alt={iconName}
        style={size ? { width: size, height: size } : undefined}
      />
    );
  } else {
    /* FeatherIcons
     */
    const iconName = pascalCase(`Fi/${name}`);
    // @ts-ignore
    const Icon: IconType | undefined = FeatherIcons[iconName];

    if (!Icon) {
      throw new Error(`Icon with name ${name} does not exist`);
    }
    return <Icon className={className} style={size ? { width: size, height: size } : undefined} />;
  }
};

export default Icon;
