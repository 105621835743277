import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Tooltip, { TooltipText } from 'components/lib/ui/Tooltip';
import RouteLink from 'components/lib/ui/link/RouteLink';

import { color, fontSize, fontWeight, radius } from 'common/lib/theme/dynamic';

import routes from 'constants/routes';
import { TRANSACTION_ROW_ICON_SIZE_PX } from 'constants/transactions';

const TOOLTIP_DELAY_SHOW_MS = 500;

const Root = styled(FlexContainer).attrs({
  children: 'P',
  alignCenter: true,
  justifyCenter: true,
})`
  --size: ${TRANSACTION_ROW_ICON_SIZE_PX}px;

  width: var(--size);
  height: var(--size);
  border-radius: ${radius.round};
  background: ${color.grayBackground};
  color: ${color.textLight};
  font-size: ${fontSize.xsmall};
  font-weight: ${fontWeight.medium};
  user-select: none;
`;

type Props = {
  isEditingDisabled: boolean;
};

const TransactionPendingBadge: React.FC<Props> = ({ isEditingDisabled }) => (
  <Tooltip
    content={
      isEditingDisabled ? (
        <TooltipText>
          This transaction is pending. You can make changes to it once it has posted to your
          account. You can enable edits to pending transactions in{' '}
          <RouteLink to={routes.settings.preferences()}>settings</RouteLink>.
        </TooltipText>
      ) : undefined
    }
    clickable={isEditingDisabled}
    delayShow={TOOLTIP_DELAY_SHOW_MS}
    place="top"
    effect="solid"
  >
    <Root />
  </Tooltip>
);

export default TransactionPendingBadge;
