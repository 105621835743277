enum AmountType {
  Debit = 'Debit',
  Credit = 'Credit',
}

export const UNIT_FOR_AMOUNT_TYPE = {
  [AmountType.Debit]: -1,
  [AmountType.Credit]: 1,
};

export const AMOUNT_TYPE_OPTIONS = [
  { value: AmountType.Debit, label: 'Debit' },
  { value: AmountType.Credit, label: 'Credit' },
];

export default AmountType;
