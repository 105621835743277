import { createReducer } from 'typesafe-actions';

import {
  setDemoHouseholdModalVisible,
  setGlobalSearchModalVisible,
  setIsSideDrawerVisible,
} from 'actions';

// Note that this state is NOT persisted across page loads.
// If you want UI state that persists, use persistentUi
const INITIAL_UI_STATE: UiState = {
  isSideDrawerVisible: false,
  isDemoHouseholdModalVisible: false,
  isGlobalSearchModalVisible: false,
};

export type UiState = {
  isSideDrawerVisible: boolean;
  isDemoHouseholdModalVisible: boolean;
  isGlobalSearchModalVisible: boolean;
};

const ui = createReducer<UiState>(INITIAL_UI_STATE)
  .handleAction(setIsSideDrawerVisible, (state, { payload: isSideDrawerVisible }) => ({
    ...state,
    isSideDrawerVisible,
  }))

  .handleAction(setDemoHouseholdModalVisible, (state, { payload: visible }) => ({
    ...state,
    isDemoHouseholdModalVisible: visible,
  }))
  .handleAction(setGlobalSearchModalVisible, (state, { payload: visible }) => ({
    ...state,
    isGlobalSearchModalVisible: visible,
  }));

export default ui;
