import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import TermsAndConditions from 'components/lib/ui/TermsAndConditions';
import Text from 'components/lib/ui/Text';
import { gradientButtonMixin } from 'components/lib/ui/button/GradientButton';
import ManualLink from 'components/lib/ui/link/ManualLink';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import { login } from 'common/actions';
import getUserApi from 'common/lib/api/user';
import api from 'lib/api';
import { useDispatch } from 'lib/hooks';

import routes from 'constants/routes';

const FormContainer = styled(OnboardingFormContainer)<{ $resetPadding: boolean }>`
  padding-top: ${({ $resetPadding }) => ($resetPadding ? 0 : 'initial')};
  padding-bottom: ${({ theme }) => theme.spacing.default};
`;

const GradientSubmitButton = styled(FormSubmitButton)`
  ${gradientButtonMixin}
`;

const IntroContainer = styled(FlexContainer).attrs({ column: true })`
  margin-top: 100px;
  width: 100%;

  ${OnboardingTitle} {
    margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
  }
`;

const TermsAndConditionsWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.textLight};
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
  max-width: 300px;
`;

type Props = {
  next: (params: { queryParams: { username: string; password: string } }) => void;
};

const DemoOnboardingWelcome = ({ next }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = async ({ demoPassword }: { demoPassword: string }) => {
    const { username, password, user } = await getUserApi(api).signupUserWithDemoPassword({
      demo_password: demoPassword,
    });
    dispatch(login(user));
    next({ queryParams: { username, password } });
  };

  return (
    <>
      <IntroContainer>
        <OnboardingTitle>Create a demo account</OnboardingTitle>
      </IntroContainer>
      <FormContainer $resetPadding>
        <Form enableReinitialize onSubmit={onSubmit} initialValues={{}}>
          <TextField name="demoPassword" label="Password" placeholder="" required autoFocus />
          <GradientSubmitButton size="medium" disableWhenValuesUnchanged={false}>
            Create
          </GradientSubmitButton>
        </Form>
      </FormContainer>
      <TermsAndConditionsWrapper>
        <TermsAndConditions />
      </TermsAndConditionsWrapper>
      <Text size="small">
        Already have an account?{' '}
        <ManualLink onClick={() => history.push(routes.login())}>Sign in</ManualLink>
      </Text>
    </>
  );
};

export default DemoOnboardingWelcome;
