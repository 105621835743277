import { gql, useMutation } from '@apollo/client';
import type { MutationHookOptions } from '@apollo/client';
import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import type { ArchiveGoal, ArchiveGoalVariables } from 'common/generated/graphQlTypes/ArchiveGoal';

/** Hook used to archive a goal and update cache optimistically. */
const useArchiveGoal = (options?: MutationHookOptions<ArchiveGoal, ArchiveGoalVariables>) => {
  const [archiveGoalMutation, mutationInfo] = useMutation<ArchiveGoal, ArchiveGoalVariables>(
    MUTATION,
    options,
  );

  const archiveGoal = useCallback(
    (id: string) =>
      archiveGoalMutation({
        variables: {
          input: {
            id,
          },
        },
        optimisticResponse: {
          archiveGoal: {
            __typename: 'ArchiveGoal',
            goal: {
              __typename: 'GoalV2',
              id,
              archivedAt: DateTime.local().toISO(),
            },
            errors: null,
          },
        },
      }),
    [archiveGoalMutation],
  );

  return [archiveGoal, mutationInfo] as const;
};

const MUTATION = gql`
  mutation Common_ArchiveGoal($input: ArchiveGoalInput!) {
    archiveGoal(input: $input) {
      goal {
        id
        archivedAt
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default useArchiveGoal;
