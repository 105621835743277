import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import Badge from 'components/lib/ui/Badge';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import { formatPricePerPeriod } from 'common/lib/billing/Billing';

import type { PaymentPeriod } from 'common/generated/graphQlTypes/globalTypes';

type Props = {
  name: string;
  checked: boolean;
  price: number;
  discountedPrice: number | undefined | null;
  period: PaymentPeriod;
  onClick: () => void;
  promoBadgeText?: string;
  infoBadgeText?: string;
};

const SubscriptionOptionRoot = styled.div<{ checked: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.default};
  border: 1px solid ${({ theme, checked }) => theme.color[checked ? 'orange' : 'grayFocus']};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  border-radius: ${({ theme }) => theme.radius.small};
  cursor: pointer;
`;

const EmptyCheck = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
`;

const SelectedCheck = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.color.orange};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.textWhite};
`;

const NameText = styled(Text)`
  margin-left: 14px;
`;

const StrikeThroughText = styled(Text)`
  text-decoration: line-through;
  color: ${({ theme }) => theme.color.textLight};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const SubscriptionOption = ({
  checked,
  name,
  price,
  discountedPrice,
  period,
  onClick,
  promoBadgeText,
  infoBadgeText,
}: Props) => {
  const priceText = formatPricePerPeriod(discountedPrice ?? price, period);

  return (
    <SubscriptionOptionRoot checked={checked} onClick={onClick}>
      <FlexContainer alignCenter>
        {checked ? (
          <SelectedCheck>
            <Icon name="check" />
          </SelectedCheck>
        ) : (
          <EmptyCheck />
        )}
        <NameText weight="medium">{name}</NameText>
        {!!infoBadgeText && <Badge color="green">{infoBadgeText}</Badge>}
        {!!promoBadgeText && <Badge color="orange">{promoBadgeText}</Badge>}
      </FlexContainer>
      <FlexContainer>
        {RA.isNotNil(discountedPrice) && <StrikeThroughText>{`$${price}`}</StrikeThroughText>}
        <span>{priceText}</span>
      </FlexContainer>
    </SubscriptionOptionRoot>
  );
};

export default SubscriptionOption;
