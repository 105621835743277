import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import IconButton from 'components/lib/ui/button/IconButton';

const Switch = styled(IconButton)``;

export type Props = {
  className?: string;
  onLeft: () => void;
  onRight: () => void;
};

const LeftRightSwitcher = ({ className, onLeft, onRight }: Props) => (
  <FlexContainer className={className} gap="xxsmall" alignCenter>
    <Switch onClick={onLeft} icon="arrow-left" />
    <Switch onClick={onRight} icon="arrow-right" />
  </FlexContainer>
);

export default LeftRightSwitcher;
