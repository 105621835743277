import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import CloseButton from 'components/lib/ui/button/CloseButton';

import useToggle from 'common/lib/hooks/useToggle';

import type { Id } from 'common/types';

type Props = {
  id: Id;
  invitedEmail: string;
  createdAt: string;
  revokeInvite: (id: Id) => void | Promise<void>;
  resendInvite: (id: Id) => void | Promise<void>;
};

const EmailIconBackground = styled(FlexContainer)`
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) => theme.color.blue};
  border-radius: 100%;
`;
const EmailIcon = styled(Icon).attrs({ name: 'mail' })`
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.color.textWhite};
`;

const CancelInviteButton = styled(CloseButton)`
  color: ${({ theme }) => theme.color.textLight};
`;

const PendingInvitationListItem = ({
  id,
  invitedEmail,
  createdAt,
  revokeInvite,
  resendInvite,
}: Props) => {
  const [hasInviteBeenResent, { setOn: setInviteHasBeenResent }] = useToggle(false);

  return (
    <FlexContainer key={id} padding="large" justifyBetween alignCenter>
      <FlexContainer>
        <EmailIconBackground center>
          <EmailIcon />
        </EmailIconBackground>
        <FlexContainer column marginHorizontal="default">
          <Text italic>{invitedEmail}</Text>
          <FlexContainer alignBaseline>
            <Text italic color="textLight" size="small">
              {`Invited ${createdAt}`}
            </Text>
            <TextButton
              onClick={async () => {
                await resendInvite(id);
                setInviteHasBeenResent();
              }}
            >
              <Text italic color={hasInviteBeenResent ? 'green' : 'blue'}>
                {hasInviteBeenResent ? 'Sent!' : 'Resend Invite'}
              </Text>
            </TextButton>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <CancelInviteButton onClick={() => revokeInvite(id)} />
    </FlexContainer>
  );
};

export default PendingInvitationListItem;
