import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';

import type { PlanState } from 'lib/hooks/plan/usePlanState';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import type { PlanAmountType } from 'lib/plan';

const SINGLE_MONTH_COLUMNS: PlanAmountType[] = ['budgeted', 'actual', 'remaining'];

/**
 * This hook handles converting between column indexes and dates.
 */
const usePlanAdapterColumns = (state: PlanState) => {
  const { timeframe, columnDisplayInterval, highlightedDate } = state;

  const timeframeUnit = timeframe === PlanTimeframe.Yearly ? 'year' : 'month';

  const columnDates = useMemo(
    () => columnDisplayInterval.splitBy({ [timeframeUnit]: 1 }).map(({ start }) => start),
    [timeframeUnit, columnDisplayInterval],
  );

  const numColumns =
    timeframe === PlanTimeframe.SingleMonth ? SINGLE_MONTH_COLUMNS.length : columnDates.length;

  const getDateForColumn = useCallback(
    (column: number) => {
      if (timeframe === PlanTimeframe.SingleMonth) {
        return highlightedDate;
      } else {
        return columnDates[column];
      }
    },
    [columnDates, timeframe, highlightedDate],
  );

  const getAmountTypeForColumn = useCallback(
    (column: number): PlanAmountType => {
      if (timeframe === PlanTimeframe.SingleMonth) {
        return SINGLE_MONTH_COLUMNS[column];
      } else {
        const date = getDateForColumn(column);
        const isPast =
          date && date.startOf(timeframeUnit) < DateTime.local().startOf(timeframeUnit);
        return isPast ? 'actual' : 'budgeted';
      }
    },
    [getDateForColumn, timeframeUnit, timeframe],
  );

  const getCanEditColumn = useCallback(
    (column: number) =>
      getAmountTypeForColumn(column) === 'budgeted' && timeframe !== PlanTimeframe.Yearly,
    [getAmountTypeForColumn, timeframe],
  );

  return {
    timeframeUnit,
    numColumns,
    getDateForColumn,
    getAmountTypeForColumn,
    getCanEditColumn,
    columnDates,
  } as const;
};

export default usePlanAdapterColumns;
