import { gql } from '@apollo/client';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import type { RouteProps } from 'react-router-dom';
import { Route, Redirect, useLocation } from 'react-router-dom';

import useQuery from 'common/lib/hooks/useQuery';
import { getIsEmployee, getIsSponsor } from 'state/user/selectors';

import routes from 'constants/routes';

import type { Web_AdvisorPortalGetSubscription } from 'common/generated/graphQlTypes/Web_AdvisorPortalGetSubscription';

const AdvisorRoute = ({ children, ...props }: RouteProps) => {
  const history = useHistory();
  const location = useLocation();

  const isSponsor = useSelector(getIsSponsor);
  const isEmployee = useSelector(getIsEmployee);
  const { data: subscriptionQueryData, isLoadingInitialData: isLoadingSubscription } =
    useQuery<Web_AdvisorPortalGetSubscription>(GET_SUBSCRIPTION_QUERY);

  useEffect(() => {
    if (
      !isLoadingSubscription &&
      R.isNil(paymentMethod) &&
      location.pathname.startsWith(routes.advisorPortal.path) &&
      location.pathname !== routes.advisorPortal.billing.path
    ) {
      history.push(routes.advisorPortal.billing());
    }
  }, [location, isLoadingSubscription]);

  const { paymentMethod } = subscriptionQueryData?.subscription ?? {};

  if (!isSponsor && !isEmployee) {
    return <Redirect to="/" />;
  }

  return <Route {...props}>{children}</Route>;
};

const GET_SUBSCRIPTION_QUERY = gql`
  query Web_AdvisorPortalGetSubscription {
    subscription {
      id
      paymentMethod {
        brand
        lastFour
      }
    }
  }
`;

export default AdvisorRoute;
