import * as React from 'react';

import BillingRow from 'components/settings/billing/BillingRow';

import type { PromoCode } from 'common/generated/graphql';

type Props = {
  promoCode: PromoCode | null | undefined;
};

const PromoCodeRow = ({ promoCode }: Props) => (
  <BillingRow title="Promo Code">
    {promoCode ? `${promoCode.code} (${promoCode.description})` : 'None'}
  </BillingRow>
);

export default PromoCodeRow;
