import { gql } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import AdviceCategoryIcon from 'components/advice/AdviceCategoryIcon';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import ProgressBar from 'components/lib/ui/ProgressBar';
import Text from 'components/lib/ui/Text';

import type { CondensedAdviceItemRowFields } from 'common/generated/graphQlTypes/CondensedAdviceItemRowFields';

const CATEGORY_ICON_SIZE_PX = 36;
const MIN_PROGRESS = 0.08;

const Root = styled(FlexContainer).attrs({ alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xxlarge};
  cursor: pointer;
  transition: ${({ theme }) => theme.transition.default};

  :hover {
    background: ${({ theme }) => theme.color.grayBackground};
  }
`;

const Chevron = styled(Icon).attrs({ name: 'chevron-right' })`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.color.textLight};
  transition: ${({ theme }) => theme.transition.default};

  ${Root}:hover & {
    color: ${({ theme }) => theme.color.text};
  }
`;

const Title = styled(Text)`
  flex: 1;
  margin: 0 ${({ theme }) => theme.spacing.default};
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 100px;
  margin-right: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  item: CondensedAdviceItemRowFields;
  onClick?: () => void;
  className?: string;
};

const CondensedAdviceItemRow = ({
  item: { title, category, numTasksCompleted, numTasks, completedAt },
  onClick,
  className,
}: Props) => (
  <Root onClick={onClick} className={className}>
    <AdviceCategoryIcon
      adviceCategory={category}
      showName={false}
      sizePx={CATEGORY_ICON_SIZE_PX}
      completed={!!completedAt}
    />
    <Title>{title}</Title>
    <StyledProgressBar
      color="green"
      value={Math.max(numTasksCompleted / (numTasks || 1), MIN_PROGRESS)}
      roundAppearance
    />
    <Chevron />
  </Root>
);

CondensedAdviceItemRow.fragments = {
  CondensedAdviceItemRowFields: gql`
    fragment CondensedAdviceItemRowFields on AdviceItem {
      id
      category {
        ...AdviceCategoryIconFields
      }
      title
      numTasksCompleted
      numTasks
      completedAt
    }
    ${AdviceCategoryIcon.fragments.AdviceCategoryIconFields}
  `,
};

export default CondensedAdviceItemRow;
