import * as R from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import ImportMintFileDropzone from 'components/accounts/ImportMintFileDropzone';
import ImportMintLoadingModal from 'components/accounts/ImportMintLoadingModal';
import ImportMintMapAccounts, {
  IGNORE_ACCOUNT_VALUE,
} from 'components/accounts/ImportMintMapAccounts';
import ImportMintMapCategories from 'components/accounts/ImportMintMapCategories';
import ImportMintMapTags from 'components/accounts/ImportMintMapTags';
import ImportMintPriority from 'components/accounts/ImportMintPriority';
import ImportMintSuccess from 'components/accounts/ImportMintSuccess';
import QuestionnaireHeader from 'components/advice/QuestionnaireHeader';
import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Page from 'components/lib/ui/Page';

import typewriter from 'lib/analytics/typewriter';
import { MINT_ACCOUNT_NAME_COLUMN_INDEX } from 'lib/csv/validation';
import useUploadStatementSession from 'lib/hooks/statements/useUploadStatementSession';
import useModal from 'lib/hooks/useModal';
import toast from 'lib/ui/toast';

import { ParserName } from 'common/constants/statements';
import routes from 'constants/routes';

import type { ParseStatementInput } from 'common/generated/graphql';

const MIN_DISPLAY_PROGRESS = 0.05;

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${({ theme }) => theme.color.white};
`;

const Container = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-bottom: 80px;
`;

enum ImportMintDataSteps {
  Upload = 'Upload',
  MapTags = 'MapTags',
  MapCategories = 'MapCategories',
  MapAccounts = 'MapAccounts',
  ImportPriority = 'ImportPriority',
  Success = 'Success',
}

const ImportMintTransactions = () => {
  const history = useHistory();

  const [finishedResults, setFinishedResults] = useState<{ transactionCount: number } | undefined>(
    undefined,
  );

  const [currentStep, setCurrentStep] = useState<ImportMintDataSteps>(ImportMintDataSteps.Upload);

  const { startSession, currentSession, startParsing, clearSession } = useUploadStatementSession({
    parserName: ParserName.MintCSV,
    // @ts-ignore
    onParseSuccess: ({ uploadedStatement }) => {
      const { transactionCount } = uploadedStatement ?? {};
      closeLoadingModal();

      setFinishedResults({ transactionCount: transactionCount ?? 0 });
      setCurrentStep(ImportMintDataSteps.Success);
    },
    // @ts-ignore
    onParseError: ({ errorMessage }) => {
      closeLoadingModal();

      typewriter.statementUploadErrored({
        parserName: ParserName.MintCSV,
        errorMessage: errorMessage ?? undefined,
      });
    },
  });

  useEffect(() => {
    if (currentSession) {
      setCurrentStep(ImportMintDataSteps.MapTags);
    }
  }, []);

  // @ts-ignore
  const { parsedRows, errorMessage } = currentSession ?? {};

  const [startParsingInput, setStartParsingInput] = useState<
    Partial<ParseStatementInput> | undefined
  >(undefined);
  const [accountNameMapping, setAccountNameMapping] = useState<Record<string, string> | undefined>(
    undefined,
  );
  const [LoadingModal, { open: openLoadingModal, close: closeLoadingModal }] = useModal();

  const onTagMappingSubmit = async (values: Record<string, any>) => {
    setStartParsingInput({
      ...startParsingInput,
      tagMapping: JSON.stringify(values?.mapping as Record<string, string>),
    });
    setCurrentStep(ImportMintDataSteps.MapCategories);
  };

  const onCategoryMappingSubmit = async (values: Record<string, any>) => {
    setStartParsingInput({
      ...startParsingInput,
      categoryMapping: JSON.stringify(values?.mapping as Record<string, string>),
    });
    setCurrentStep(ImportMintDataSteps.MapAccounts);
  };

  const onFinalSubmit = async (values: Record<string, any>) => {
    if (startParsingInput !== undefined) {
      openLoadingModal();
      startParsing({
        ...startParsingInput,
        importPriority: values.importPriority,
      });
    }
  };

  const onSubmitAccountsMap = async (values: {
    mapping: Record<string, string>;
    importPriority: string;
  }) => {
    setAccountNameMapping(values.mapping);
    setStartParsingInput({
      ...startParsingInput,
      accountNameMapping: JSON.stringify(values.mapping),
    });
    setCurrentStep(ImportMintDataSteps.ImportPriority);
  };

  const rowsByAccountName = useMemo(
    () =>
      R.groupBy(
        (row) => row[MINT_ACCOUNT_NAME_COLUMN_INDEX],
        R.drop(1, parsedRows ?? []).filter((val) => val.length > 1) as string[][], // First row is CSV header
      ),
    [parsedRows],
  );

  // Total of transactions the user already selected an account and it is different from IGNORE_ACCOUNT_VALUE
  const totalNotIgnoredTransactions = useMemo(() => {
    if (!accountNameMapping) {
      return 0;
    }

    const accountsToCount = R.filter(
      (account) =>
        account !== 'importPriority' && accountNameMapping[account] !== IGNORE_ACCOUNT_VALUE,
      R.keys(accountNameMapping),
    );

    return R.pipe(
      R.map((account) => R.length(rowsByAccountName[account as string])),
      R.sum,
    )(accountsToCount);
  }, [accountNameMapping, rowsByAccountName]);

  const progress = (() => {
    switch (currentStep) {
      case ImportMintDataSteps.Upload:
        return 0.15;
      case ImportMintDataSteps.MapTags:
        return 0.3;
      case ImportMintDataSteps.MapCategories:
        return 0.45;
      case ImportMintDataSteps.MapAccounts:
        return 0.6;
      case ImportMintDataSteps.ImportPriority:
        return 0.8;
      case ImportMintDataSteps.Success:
        return 1;
      default:
        return 1;
    }
  })();

  const onBackButton = () => {
    if (currentStep === ImportMintDataSteps.MapAccounts) {
      setCurrentStep(ImportMintDataSteps.MapCategories);
    }

    if (currentStep === ImportMintDataSteps.MapCategories) {
      setCurrentStep(ImportMintDataSteps.MapTags);
    }

    if (currentStep === ImportMintDataSteps.MapTags) {
      clearSession();
      setCurrentStep(ImportMintDataSteps.Upload);
    }

    if (currentStep === ImportMintDataSteps.Upload) {
      history.goBack();
    }

    if (currentStep === ImportMintDataSteps.ImportPriority) {
      setCurrentStep(ImportMintDataSteps.MapAccounts);
    }

    if (currentStep === ImportMintDataSteps.Success) {
      setCurrentStep(ImportMintDataSteps.Upload);
    }
  };

  return (
    <StyledPage
      name="Import Mint Data"
      header={
        <QuestionnaireHeader
          progress={Math.max(MIN_DISPLAY_PROGRESS, progress)}
          showBackButton
          onClickBackButton={onBackButton}
        />
      }
    >
      <Container>
        {!!errorMessage && <Banner type="error">{errorMessage}</Banner>}
        <Switch>
          <Case when={currentStep === ImportMintDataSteps.Upload}>
            <ImportMintFileDropzone
              onClickNext={async (file) => {
                await startSession(file);
                setCurrentStep(ImportMintDataSteps.MapTags);
              }}
            />
          </Case>
          <Case when={currentStep === ImportMintDataSteps.MapTags}>
            <ImportMintMapTags
              parsedRows={parsedRows}
              onSubmit={onTagMappingSubmit}
              tagMapping={
                startParsingInput?.tagMapping ? JSON.parse(startParsingInput.tagMapping) : undefined
              }
            />
          </Case>
          <Case when={currentStep === ImportMintDataSteps.MapCategories}>
            <ImportMintMapCategories
              parsedRows={parsedRows}
              onSubmit={onCategoryMappingSubmit}
              categoryMapping={
                startParsingInput?.categoryMapping
                  ? JSON.parse(startParsingInput.categoryMapping)
                  : undefined
              }
            />
          </Case>
          <Case when={currentStep === ImportMintDataSteps.MapAccounts}>
            <ImportMintMapAccounts parsedRows={parsedRows} onSubmit={onSubmitAccountsMap} />
          </Case>

          <Case when={currentStep === ImportMintDataSteps.ImportPriority}>
            <ImportMintPriority
              onSubmit={onFinalSubmit}
              totalNotIgnoredTransactions={totalNotIgnoredTransactions}
            />
          </Case>

          <Case when={currentStep === ImportMintDataSteps.Success}>
            <ImportMintSuccess
              onViewTransactions={async () => {
                toast({
                  title: 'Upload complete',
                  description: `Successfully imported ${
                    finishedResults?.transactionCount ?? 0
                  } transactions.`,
                });

                history.push(routes.transactions({ queryParams: { importedFromMint: true } }));
              }}
              onViewCashFlow={async () => {
                history.push(routes.cashFlow({ queryParams: { view: 'sankey' } }));
              }}
              onEditCategories={async () => {
                history.push(routes.settings.categories());
              }}
              transactionCount={finishedResults?.transactionCount ?? 0}
            />
          </Case>
        </Switch>
      </Container>
      <LoadingModal nonDismissable>
        <ImportMintLoadingModal />
      </LoadingModal>
    </StyledPage>
  );
};

export default ImportMintTransactions;
