import { DateTime } from 'luxon';

import type DateRange from 'common/types/DateRange';

export type Shortcut = DateRange & {
  title: string;
};

export const ALL_TIME_INDEX = 7;

// Keeping this here for now, until all references to this are removed and
// instead use the a function like mobile's getDefaultShortcuts
const now = DateTime.local();

export const DEFAULT_SHORTCUTS: Shortcut[] = [
  {
    title: 'Last 7 days',
    startDate: now.minus({ days: 7 }).toISODate(),
    endDate: now.toISODate(),
  },
  {
    title: 'Last 14 days',
    startDate: now.minus({ days: 14 }).toISODate(),
    endDate: now.toISODate(),
  },
  {
    title: 'Last 30 days',
    startDate: now.minus({ days: 30 }).toISODate(),
    endDate: now.toISODate(),
  },
  {
    title: 'This month',
    startDate: now.startOf('month').toISODate(),
    endDate: now.endOf('month').toISODate(),
  },
  {
    title: 'Last month',
    startDate: now.minus({ months: 1 }).startOf('month').toISODate(),
    endDate: now.minus({ months: 1 }).endOf('month').toISODate(),
  },
  {
    title: 'This year',
    startDate: now.startOf('year').toISODate(),
    endDate: now.endOf('year').toISODate(),
  },
  {
    title: 'Last year',
    startDate: now.minus({ years: 1 }).startOf('year').toISODate(),
    endDate: now.minus({ years: 1 }).endOf('year').toISODate(),
  },
];

export const getDateRangeForShortcut = (index: number): DateRange => {
  const { startDate, endDate } = DEFAULT_SHORTCUTS[index];
  return {
    startDate,
    endDate,
  };
};
