import type { GetAccountTypeOptions_accountTypeOptions } from 'common/generated/graphQlTypes/GetAccountTypeOptions';

export const findOptionForType = (
  type: string,
  accountTypeOptions: GetAccountTypeOptions_accountTypeOptions[],
) => accountTypeOptions.find(({ type: { name } }) => name === type);

export const getDisplayOptionsFromAccountTypeOptions = (
  type: string,
  accountTypeOptions: GetAccountTypeOptions_accountTypeOptions[],
) =>
  findOptionForType(type, accountTypeOptions)?.type.possibleSubtypes.map(({ name, display }) => ({
    value: name,
    label: display,
  })) || [];

export const getDisplayForType = (
  type: string,
  accountTypeOptions: GetAccountTypeOptions_accountTypeOptions[],
) => findOptionForType(type, accountTypeOptions)?.type.display || '';
