import { lighten } from 'polished';
import styled from 'styled-components';

import AbstractButton from 'components/lib/ui/button/AbstractButton';

import type { ButtonSize } from 'lib/styles/buttonSizeMixin';
import buttonSizeMixin from 'lib/styles/buttonSizeMixin';

import type { StyleMixin } from 'common/types/Styles';

export const gradientButtonMixin: StyleMixin<{ size?: ButtonSize }> = ({
  theme,
  size = 'small',
}) => `
  background: ${theme.color.orangeDark}; /* fallback color */
  background: linear-gradient(90deg, ${theme.color.redDark} 0%, ${theme.color.orangeDark} 100%);
  color: ${theme.color.textWhite};
  font-weight: ${theme.fontWeight.medium};

  :hover {
    background: linear-gradient(90deg, ${lighten(0.03, theme.color.redDark)} 0%, ${lighten(
      0.03,
      theme.color.orangeDark,
    )} 100%);
  }

  ${buttonSizeMixin({ theme, size })};
`;

const GradientButton = styled(AbstractButton)<{ size?: ButtonSize }>`
  ${gradientButtonMixin}
`;

export default GradientButton;
