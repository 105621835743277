import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import DragDropContext from 'components/lib/dnd/DragDropContext';
import Draggable from 'components/lib/dnd/Draggable';
import Droppable from 'components/lib/dnd/Droppable';
import Column from 'components/lib/ui/Column';
import DragDots from 'components/lib/ui/DragDots';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import Row, { GRID_COLUMNS } from 'components/lib/ui/Row';
import Toggle from 'components/lib/ui/Toggle';
import PremiumBadge from 'components/premium/PremiumBadge';
import PremiumFeatureOverlayTrigger from 'components/premium/PremiumFeatureOverlayTrigger';

import useDashboardConfig from 'common/lib/hooks/dashboard/useDashboardConfig';
import useDashboardDrag from 'lib/hooks/dashboard/useDashboardDrag';
import useTheme from 'lib/hooks/useTheme';

import { DASHBOARD_WIDGET_LONG_NAMES } from 'common/constants/copy';
import { DASHBOARD_WIDGET_ASSOCIATED_FEATURE } from 'common/constants/premium';
import * as COPY from 'constants/copy';

const WidgetCard = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.default};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  border-radius: ${({ theme }) => theme.radius.medium};
  background: ${({ theme }) => theme.color.grayBackground};
`;

const DroppableColumn = styled(Droppable)`
  height: 100%;
  > * {
    margin-bottom: ${({ theme }) => theme.spacing.gutter};
  }
`;

const Title = styled.span<{ $marginLeft?: number }>`
  flex-shrink: 1;
  margin-left: ${({ theme, $marginLeft }) => $marginLeft ?? theme.spacing.default};
`;

const CustomizeDashboardModal = () => {
  const [dashboardConfig, setDashboardConfig] = useDashboardConfig('web');
  const { onDragEnd } = useDashboardDrag();
  const { layout, widgets } = dashboardConfig;

  const numColumns = layout.length;

  const theme = useTheme();

  return (
    <ModalCard
      title={COPY.DASHBOARD.CUSTOMIZE_DASHBOARD.TITLE}
      description={COPY.DASHBOARD.CUSTOMIZE_DASHBOARD.DESCRIPTION}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Row rowGutterPx={0} padding={theme.spacing.xlarge}>
          {layout.map((widgetNames, column) => (
            <Column key={column} md={GRID_COLUMNS / numColumns}>
              <DroppableColumn droppableId={`${column}`}>
                {widgetNames.map((widgetName, index) => (
                  <Draggable key={widgetName} draggableId={widgetName} index={index}>
                    <PremiumFeatureOverlayTrigger
                      feature={DASHBOARD_WIDGET_ASSOCIATED_FEATURE[widgetName]}
                    >
                      {({ hasAccess }) => (
                        <WidgetCard>
                          <FlexContainer alignCenter>
                            {hasAccess && <DragDots />}
                            <Title $marginLeft={hasAccess ? undefined : 0}>
                              {DASHBOARD_WIDGET_LONG_NAMES[widgetName]}
                            </Title>
                          </FlexContainer>
                          {hasAccess ? (
                            <Toggle
                              checked={widgets[widgetName].enabled}
                              onChange={(event) =>
                                setDashboardConfig(
                                  R.mergeDeepLeft(
                                    {
                                      widgets: {
                                        [widgetName]: {
                                          enabled: event.target.checked,
                                        },
                                      },
                                    },
                                    dashboardConfig,
                                  ),
                                  true,
                                )
                              }
                            />
                          ) : (
                            <PremiumBadge />
                          )}
                        </WidgetCard>
                      )}
                    </PremiumFeatureOverlayTrigger>
                  </Draggable>
                ))}
              </DroppableColumn>
            </Column>
          ))}
        </Row>
      </DragDropContext>
    </ModalCard>
  );
};

export default CustomizeDashboardModal;
