// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 1480749969
import { createAction } from 'typesafe-actions';

export const setHasAddedAccounts = createAction('ACCOUNTS/SET_HAS_ADDED_ACCOUNTS')<
  boolean | undefined
>();

export const toggleAccountGroupCollapsed = createAction(
  'ACCOUNTS/TOGGLE_ACCOUNT_GROUP_COLLAPSED',
)<string>();
