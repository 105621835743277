import { gql, useMutation } from '@apollo/client';
import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import CopyBalanceInnerForm from 'components/accounts/CopyBalanceInnerForm';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { throwIfHasPayloadErrorMessage } from 'common/lib/form/errors';
import { ensureEnumValue } from 'common/utils/Enum';
import type { CopySnapshotsFormValues } from 'lib/accounts/snapshots';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';
import useTrack from 'lib/hooks/useTrack';
import toast, { errorToast } from 'lib/ui/toast';

import { AccountTypeName } from 'common/constants/accounts';
import { AccountEventNames } from 'common/constants/analytics';
import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

import type {
  Web_CopySnapshots,
  Web_CopySnapshotsVariables,
} from 'common/generated/graphQlTypes/Web_CopySnapshots';
import { AccountTypeGroup } from 'common/generated/graphql';

type Props = {
  onClose: () => void;
};

const CopyBalanceHistoryModalCard = ({
  fromAccount,
  onClose,
}: Props & Pick<React.ComponentProps<typeof CopyBalanceInnerForm>, 'fromAccount'>) => {
  const history = useHistory();
  const isInvestments = fromAccount.type.name === AccountTypeName.BROKERAGE;
  const group = ensureEnumValue(AccountTypeGroup, fromAccount.type.group.toLowerCase());

  const [isLoadingAccounts, accountOptions] = useAccountSelectOptions({
    includeSyncDisabled: true,
    excludeAccountId: fromAccount.id,
    shouldGroupByType: false,
    shouldIncludeAccount: (account) => shouldIncludeInvestmentAccount(!isInvestments, account),
    queryFilters: {
      groups: group ? [group] : undefined,
      includeManual: false,
    },
  });

  useTrack(AccountEventNames.CopyBalanceHistoryFormOpened);

  const [performMutation] = useMutation<Web_CopySnapshots, Web_CopySnapshotsVariables>(
    COPY_SNAPSHOTS_MUTATION,
  );

  const handleSubmit = useCallback(
    async (values: CopySnapshotsFormValues) => {
      const { cutoverDate, toAccountId } = values;

      if (R.any(R.isNil, [cutoverDate, toAccountId, fromAccount.id])) {
        return;
      }

      try {
        const result = await performMutation({
          variables: {
            input: {
              fromAccountId: fromAccount.id,
              toAccountId,
              cutoverDate,
            },
          },
        });

        throwIfHasPayloadErrorMessage(result.data);

        const { toAccount } = result.data?.copySnapshots ?? {};
        if (R.isNil(toAccount)) {
          return;
        }

        toast({
          title: `Copied to ${toAccount.displayName}`,
          description: 'View your balance history in the new account',
          actions: [
            {
              text: 'View account',
              onClick: () => history.push(routes.accounts.accountDetails({ id: toAccount.id })),
            },
          ],
        });
        onClose();
      } catch (error: any) {
        errorToast(error.message);
      }
    },
    [performMutation, fromAccount.id],
  );

  return (
    <ModalCard
      title="Copy balance history"
      description={COPY.ACCOUNTS.COPY_BALANCES_MODAL_TEXT}
      hideBottomBorder
    >
      <Form
        initialValues={
          {
            cutoverDate: undefined,
            toAccountId: undefined,
          } as Partial<CopySnapshotsFormValues>
        }
        onSubmit={handleSubmit}
        isInitialValid={false}
      >
        <CopyBalanceInnerForm
          accountOptions={accountOptions}
          isLoadingAccountOptions={isLoadingAccounts}
          fromAccount={fromAccount}
        />
        <CardFooter>
          <DefaultButton size="small" onClick={onClose}>
            Cancel
          </DefaultButton>
          <FormSubmitButton size="small">Copy balance history</FormSubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

const shouldIncludeInvestmentAccount = (
  excludeInvestments: boolean | undefined,
  account: { type: { name: string } },
) => {
  if (excludeInvestments === undefined) {
    return true;
  }

  return excludeInvestments
    ? account.type.name !== AccountTypeName.BROKERAGE
    : account.type.name === AccountTypeName.BROKERAGE;
};

const COPY_SNAPSHOTS_MUTATION = gql`
  mutation Web_CopySnapshots($input: CopySnapshotsInput!) {
    copySnapshots(input: $input) {
      numSnapshotsCopied
      toAccount {
        id
        displayName
      }
      errors {
        message
      }
    }
  }
`;

export default CopyBalanceHistoryModalCard;
