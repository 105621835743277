import * as React from 'react';

import AmountPill from 'components/lib/ui/AmountPill';

import { compare } from 'common/utils/Math';

type Props = {
  returnPercent: number | null;
  className?: string;
};

const ReturnPercentPill = ({ returnPercent, className }: Props) => (
  <AmountPill
    className={className}
    value={returnPercent}
    formatter={(value) => `${value?.toFixed(2) || '--'}%`}
    icon={compare(returnPercent ?? 0, 0, {
      equal: 'arrow-right',
      lessThan: 'arrow-down-right',
      greaterThan: 'arrow-up-right',
    })}
  />
);

export default ReturnPercentPill;
