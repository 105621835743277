import { PLAID_EVENT_NAMES_RAW } from 'common/constants/analytics';

import type { PlaidEventWithMetadata } from 'common/types/Plaid';

const PLAID_LINK_INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';

/**
 * Return true if and only if a connectivity issue was encountered
 * in the plaid event stream represented by plaidEvents.
 */
export const isConnectivityIssue = (
  plaidEvents: PlaidEventWithMetadata[],
  laxConnectionIssueDetection = false,
): boolean =>
  plaidEvents.filter(
    (plaidEvent) =>
      plaidEvent.event === PLAID_EVENT_NAMES_RAW.ERROR &&
      (laxConnectionIssueDetection ||
        plaidEvent.metadata?.error_code !== PLAID_LINK_INVALID_CREDENTIALS),
  ).length > 0;
