import { gql } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import GoalImage from 'components/goalsV2/GoalImage';
import UnsplashImagePickerModal from 'components/goalsV2/UnsplashImagePickerModal';
import Form from 'components/lib/form/Form';
import TextField from 'components/lib/form/TextField';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import type { UnsplashPhoto } from 'common/lib/external/unsplash';
import useUpdateGoal from 'common/lib/hooks/goalsV2/useUpdateGoal';
import { useModalContext } from 'lib/contexts/ModalContext';
import useModal from 'lib/hooks/useModal';

import type { GoalCustomizeModalFieldsFragment } from 'common/generated/graphql';

const HEIGHT_PT = 238;

const FormContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledGoalImage = styled(GoalImage)`
  width: 100%;
  max-width: 100%;
  max-height: ${HEIGHT_PT}px;
  position: relative;
  aspect-ratio: 2.14;
`;

const ImageContainer = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
  min-height: 238px;
  background-color: ${({ theme }) => theme.color.grayBackground};
`;

const CustomizeImageButton = styled(DefaultButton)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.default};
  right: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  data: GoalCustomizeModalFieldsFragment;
};

const GoalCustomizeModal = ({ data }: Props) => {
  const { close } = useModalContext();
  const { name, imageStorageProvider, imageStorageProviderId } = data;
  const [imageWasChanged, setImageWasChanged] = useState(false);

  const [UnsplashModal, { open: openUnsplashModal, close: closeUnsplashModal }] = useModal();

  const { updateGoal } = useUpdateGoal(data);

  return (
    <ModalCard title={name} hideBottomBorder>
      <Form
        initialValues={{ name }}
        onSubmit={({ name }) => {
          updateGoal({ name });
          close();
        }}
      >
        <FormContainer>
          <ImageContainer>
            <StyledGoalImage
              imageStorageProvider={imageStorageProvider}
              imageStorageProviderId={imageStorageProviderId}
              size="large"
              showGradient={false}
            />
            <CustomizeImageButton onClick={openUnsplashModal}>Customize image</CustomizeImageButton>
          </ImageContainer>

          <TextField name="name" label="Name your goal" />
        </FormContainer>
        <CardFooter>
          <DefaultButton size="small" onClick={close}>
            Cancel
          </DefaultButton>

          <FormContext.Consumer>
            {({ handleSubmit, dirty }) => (
              <PrimaryButton
                size="small"
                onClick={() => {
                  if (dirty) {
                    handleSubmit();
                  } else {
                    close();
                  }
                }}
                disabled={!dirty && !imageWasChanged}
              >
                Save
              </PrimaryButton>
            )}
          </FormContext.Consumer>
        </CardFooter>
      </Form>
      <UnsplashModal>
        <UnsplashImagePickerModal
          onSelect={(photo: UnsplashPhoto) => {
            updateGoal({
              imageStorageProvider: 'unsplash',
              imageStorageProviderId: photo.urls.raw,
            });
            setImageWasChanged(true);
          }}
          onClose={closeUnsplashModal}
        />
      </UnsplashModal>
    </ModalCard>
  );
};

GoalCustomizeModal.fragments = {
  GoalCustomizeModalFields: gql`
    fragment GoalCustomizeModalFields on GoalV2 {
      id
      name
      imageStorageProvider
      imageStorageProviderId
    }
  `,
};

export default GoalCustomizeModal;
