import React from 'react';
import styled from 'styled-components';

import type { Props as SelectFieldProps } from 'components/lib/form/SelectField';
import SelectField from 'components/lib/form/SelectField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import { MenuItem } from 'components/lib/ui/menu/Menu';

import {
  getDueDateOptions,
  getSuffixForDay,
  USE_STATEMENT_DUE_DATE_VALUE,
} from 'common/lib/recurring';
import { color, fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';

const StyledSelectField = styled(SelectField)`
  ${MenuItem} {
    padding: ${spacing.xsmall} ${spacing.small};
    margin: ${spacing.xxsmall} ${spacing.xsmall};
  }
`;

const StyledLabel = styled.span`
  text-align: left;
`;

const SyncedLabel = styled.span`
  color: ${color.textLight};
  margin-left: ${spacing.xxsmall};
`;

const getDayOfTheMonthOptions = ({
  lastStatementDueDateDay,
}: {
  lastStatementDueDateDay: number;
}) => {
  const labelText = `${lastStatementDueDateDay}${getSuffixForDay(lastStatementDueDateDay)} of the month`;
  return [
    {
      label: 'Pulled monthly from Spinwheel',
      options: [
        {
          value: USE_STATEMENT_DUE_DATE_VALUE,
          label: (
            <FlexContainer justifyBetween alignCenter>
              <StyledLabel>{labelText}</StyledLabel>
              <SyncedLabel>(synced)</SyncedLabel>
            </FlexContainer>
          ),
        },
      ],
    },
    {
      label: 'Custom due date',
      options: getDueDateOptions(),
    },
  ];
};

const GroupHeading = styled.div`
  font-size: ${fontSize.xsmall};
  font-weight: ${fontWeight.medium};
  color: ${color.textLight};
  padding: ${spacing.xsmall} ${spacing.small};
  padding-bottom: ${spacing.xxsmall};
  text-align: left;
`;

type Props = SelectFieldProps & {
  lastStatementDueDateDay: number;
};

const DayOfTheMonthField = ({ lastStatementDueDateDay, ...props }: Props) => {
  const options = getDayOfTheMonthOptions({ lastStatementDueDateDay });
  return (
    <StyledSelectField
      hideLabel
      options={options}
      components={{
        GroupHeading: ({ children }: any) => <GroupHeading>{children}</GroupHeading>,
      }}
      {...props}
    />
  );
};

export default DayOfTheMonthField;
