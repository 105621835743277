import React from 'react';

import type { AmountsTooltipRowProps } from 'components/plan/AmountsTooltip';
import AmountsTooltip from 'components/plan/AmountsTooltip';

type Props = {
  leftToBudget: number;
  rows: AmountsTooltipRowProps[];
};

const LeftToBudgetTooltip = ({ leftToBudget, rows }: Props) => (
  <AmountsTooltip
    title="Budget"
    rows={[
      ...rows,
      { title: 'Left to budget or save', value: leftToBudget, type: 'income', emphasis: true },
    ]}
  />
);

export default LeftToBudgetTooltip;
