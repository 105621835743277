import React, { useContext, useCallback } from 'react';

type TabsContextType = {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
};

const TabsContext = React.createContext<TabsContextType>(undefined as any);

export default TabsContext;

export const useTabs = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error(
      'Used <Tab> component outside of TabsContext. Ensure you have a <Tabs> component higher in the tree.',
    );
  }

  const { activeIndex, setActiveIndex } = context;
  return [activeIndex, setActiveIndex] as const;
};

export const useTab = (index: number) => {
  const [activeIndex, setActiveIndex] = useTabs();
  const isActive = activeIndex === index;

  const setActive = useCallback(() => {
    setActiveIndex(index);
  }, [index]);

  return [isActive, setActive] as const;
};
