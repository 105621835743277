import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import PageEmptyOverlayCard from 'components/lib/ui/PageEmptyOverlayCard';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import { gradientButtonMixin } from 'components/lib/ui/button/GradientButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';

const Root = styled(PageEmptyOverlayCard)`
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing.large} ${({ theme }) => theme.spacing.default};
  bottom: ${({ theme }) => theme.spacing.default};
  left: ${({ theme }) => theme.spacing.xxlarge};
  right: ${({ theme }) => theme.spacing.xxlarge};
  width: auto;
  top: auto;

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    width: 500px;
    margin: 219px auto 0 auto;
    padding: ${({ theme }) => theme.spacing.xlarge};
    top: 0;
    bottom: auto;
  }
`;

const Title = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.small};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.xlarge};
  }
`;

const Subtitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-top: ${({ theme }) => theme.spacing.default};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.base};
  }
`;

const SubmitButton = styled(AsyncButton).attrs({ size: 'medium' as const })<{ $gradient: boolean }>`
  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    flex: 1;
  }

  ${({ $gradient, ...props }) =>
    $gradient ? gradientButtonMixin(props) : primaryButtonMixin(props)}
`;

const SkipButton = styled(TextButton)`
  margin-left: ${({ theme }) => theme.spacing.xlarge};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const ButtonContainer = styled(FlexContainer).attrs({ alignCenter: true })`
  margin-top: 19px;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: ${({ theme }) => theme.spacing.xxlarge};
  }
`;

const StyledIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.color.orange};
  margin-bottom: 27px;
  display: none;

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    display: block;
  }
`;

type DefaultButton = {
  text: string;
  onClick?: () => void;
};

type Props = {
  icon: string;
  title: string;
  subtitle?: string;
  button: {
    text: string;
    onClick: () => void;
    icon?: string;
    loading?: boolean;
    gradient?: boolean;
  };
  defaultButton?: DefaultButton | React.ReactNode;
  className?: string;
};

const isDefaultButtonDict = (button?: Props['defaultButton']): button is DefaultButton =>
  button ? R.hasPath(['text'], button) : false;

const EmptyCreateCard = ({
  icon,
  title,
  subtitle,
  button: {
    text: primaryButtonText,
    icon: buttonIcon,
    onClick: primaryOnClick,
    loading = false,
    gradient = false,
  },
  defaultButton,
  className,
}: Props) => (
  <Root className={className}>
    <StyledIcon name={icon} />
    <Title weight="medium">{title}</Title>
    {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
    <ButtonContainer>
      <SubmitButton
        onClick={primaryOnClick}
        disabled={loading}
        pending={loading}
        $gradient={gradient}
      >
        {!!buttonIcon && <ButtonIcon name={buttonIcon} />}
        <span>{primaryButtonText}</span>
      </SubmitButton>
      {isDefaultButtonDict(defaultButton) ? (
        <SkipButton onClick={defaultButton?.onClick}>{defaultButton.text}</SkipButton>
      ) : (
        defaultButton
      )}
    </ButtonContainer>
  </Root>
);

export default EmptyCreateCard;
