import { DateTime } from 'luxon';
import * as R from 'ramda';

const BENCHMARK_ALLOWED_TICKERS = ['QQQ', 'IVV', '^GSPC', 'VTSAX'];

const generateMockAggregatedHolding = (
  type: string,
  ticker: string,
  name: string,
  qty: number,
  basis: number,
  current: number,
  index: number,
) => {
  const currentValue = qty * current;
  const basisValue = qty * basis;
  const percent = ((currentValue - basisValue) / basisValue) * 100;
  return {
    id: index,
    quantity: qty,
    basis,
    totalValue: currentValue,
    dayChangeDollars: currentValue - basisValue,
    dayChangePercent: percent,
    totalGainLossDollars: currentValue - basisValue,
    totalGainLossPercent: percent,
    security: {
      id: ticker,
      ticker,
      typeDisplay: type,
      currentPrice: current,
      name,
    },
  };
};

const generateMockHistoricalChartData = (daysToGenerate: number) => {
  const today = DateTime.local();
  return R.range(0, daysToGenerate).map((toAdd) => ({
    date: today.plus({ days: toAdd }).toISODate(),
    returnPercent: 20 * Math.random(),
  }));
};

const generateMockPerformanceData = (daysToGenerate: number) => ({
  totalBasis: 38.25,
  totalChangeDollars: 21.59,
  totalChangePercent: 70.3,
  totalValue: 250000,
  benchmarks: BENCHMARK_ALLOWED_TICKERS.map((ticker) => ({
    security: {
      id: ticker,
      ticker,
    },
    historicalChart: generateMockHistoricalChartData(daysToGenerate),
  })),
  historicalChart: generateMockHistoricalChartData(daysToGenerate),
  topMovers: () => [
    { name: 'Apple Inc.', ticker: 'AAPL', oneDayChangePercent: 1.87, currentPrice: 1.87 },
    { name: 'Alphabet Inc.', ticker: 'GOOG', oneDayChangePercent: 1.85, currentPrice: 2296.66 },
    { name: 'Cisco Systems Inc.', ticker: 'CSCO', oneDayChangePercent: -0.9, currentPrice: 738.85 },
  ],
});

const MOCK_ARGS = [
  // typeDisplay, ticker, name, qty, basis, currentPrice
  ['ETF', 'VTI', 'Vanguard Total Stock Market ETF', 10, 100, 105],
  ['ETF', 'VNQ', 'Vanguard Real Estate Index Fund ETF Shares', 20, 200, 202],
  ['ETF', 'VIG', 'Vanguard Dividend Appreciation Index Fund ETF Shares', 20, 300, 306],
  ['ETF', 'VEA', 'Vanguard FTSE Developed Markets Index Fund ETF Shares', 10, 100, 102],
  ['ETF', 'EMB', 'iShares J.P. Morgan USD Emerging Markets Bond ETF', 10, 50, 49],
  ['ETF', 'LQD', 'iShares iBoxx $ Investment Grade Corporate Bond ETF', 20, 200, 204],
  ['ETF', 'VWO', 'Vanguard FTSE Emerging Markets Index Fund ETF Shares', 10, 1000, 990],
  ['Stock', 'AAPL', 'Apple Inc.', 20, 1000, 1100],
  ['Stock', 'GOOGL', 'Alphabet Inc.', 20, 1500, 1650],
  ['Cryptocurrency', 'BTC-USD', 'Bitcoin', 5, 9000, 10000],
  ['Cryptocurrency', 'ETH-USD', 'Ethereum', 20, 1500, 1650],
];

export const MOCK_AGGREGATED_HOLDINGS = MOCK_ARGS.map((args, index) =>
  // @ts-ignore
  generateMockAggregatedHolding(...args, index),
);

export const MOCK_PERFORMANCE_DATA = generateMockPerformanceData(7);
