import { useQuery, useMutation } from '@apollo/client';
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSection from 'components/lib/form/FormSection';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import SettingsCard from 'components/lib/layouts/SettingsCard';
import Column from 'components/lib/ui/Column';
import Flex from 'components/lib/ui/Flex';

import { GET_MY_HOUSEHOLD, UPDATE_MY_HOUSEHOLD } from 'common/lib/graphQl/household';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';

import STATE_OPTIONS from 'common/constants/stateOptions';

import type { GetMyHousehold } from 'common/generated/graphQlTypes/GetMyHousehold';
import type {
  UpdateMyHousehold,
  UpdateMyHouseholdVariables,
} from 'common/generated/graphQlTypes/UpdateMyHousehold';

const SmallField = styled(TextField)`
  flex: 2;
  margin-left: ${({ theme }) => theme.spacing.xxlarge};
`;

const GrowSelectField = styled(SelectField)`
  flex: 3;
`;

const HouseholdSettings = () => {
  const { isDemoHousehold } = useDemoHousehold();
  const { data } = useQuery<GetMyHousehold>(GET_MY_HOUSEHOLD);
  const [updateMyHousehold] = useMutation<UpdateMyHousehold, UpdateMyHouseholdVariables>(
    UPDATE_MY_HOUSEHOLD,
  );

  const { name = '', address = '', city = '', state = '', zipCode = '' } = data?.myHousehold ?? {};

  return (
    <Column md={9}>
      <SettingsCard title="Household" isDemo={isDemoHousehold}>
        <Helmet>
          <title>Household Settings</title>
        </Helmet>
        <Form
          initialValues={{ name, address, city, state, zipCode }}
          mutation={updateMyHousehold}
          {...sensitiveClassProps}
        >
          <FormSection>
            <TextField name="name" label="Household Name" required />
          </FormSection>
          <FormSection>
            <TextField name="address" />
            <TextField name="city" />
            <Flex>
              <GrowSelectField name="state" options={STATE_OPTIONS} />
              <SmallField name="zipCode" maxLength={5} />
            </Flex>
            <FormSubmitButton>Update Household</FormSubmitButton>
          </FormSection>
        </Form>
      </SettingsCard>
    </Column>
  );
};

export default HouseholdSettings;
