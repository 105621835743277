import React from 'react';

import AddVehicleAccountDetailsFormModal from 'components/accounts/AddVehicleAccountDetailsFormModal';
import AddVehicleAccountSearchModal from 'components/accounts/AddVehicleAccountSearchModal';
import Flow from 'components/utils/Flow';

type Props = {
  initialProps?: {
    vin?: string;
    name?: string;
    subtype?: string;
  };
  onComplete?: (props: AddVehicleAccountFlowOutput) => Promise<void>;
  onBack?: () => void;
};

export type AddVehicleAccountFlowOutput = {
  vin?: string;
  name?: string;
  subtype?: string | null;
};

const AddVehicleAccountFlow = ({ onComplete, onBack, initialProps }: Props) => (
  <Flow
    steps={[AddVehicleAccountSearchModal, AddVehicleAccountDetailsFormModal]}
    initialProps={initialProps}
    onComplete={onComplete}
    onBack={onBack}
  />
);

export default AddVehicleAccountFlow;
