import { useMutation } from '@apollo/client';
import { debounce } from 'lodash/fp';
import type { DateTime } from 'luxon';
import { useMemo, useRef } from 'react';

import { useOptimisticBudgetUpdates } from 'common/lib/hooks/budget/useOptimisticBudgetUpdates';
import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';

import { gql } from 'common/generated/gql';
import { BudgetSystem, BudgetTimeframeInput } from 'common/generated/graphql';
import type {
  ExtractInputFromDocument,
  MutationHookOptionsFromDocument,
} from 'common/types/graphql';

const DEBOUNCE_TIME_MS = 1000;

type MutationKey = string;

type BudgetUpdaterContext = {
  startDate: DateTime;
  categoryId?: string;
  categoryGroupId?: string;
};

type BudgetUpdateDetails = {
  amount: number | undefined;
  applyToFuture?: boolean;
};

type Options = MutationHookOptionsFromDocument<typeof UPDATE_BUDGET_ITEM> & {
  onMutationsComplete?: () => Promise<unknown>;
};

const useUpdateBudgetItemMutation = (options: Options, optimisticCacheUpdate = true) => {
  const { budgetSystem } = useHouseholdPreferences();
  const { onMutationsComplete, ...mutationOptions } = options;
  const [performMutation, { loading }] = useMutation(UPDATE_BUDGET_ITEM, mutationOptions);
  const pendingUpdatesRef = useRef<Record<MutationKey, Promise<unknown>>>({});

  const debouncedOnMutationsComplete = useMemo(
    () =>
      debounce(DEBOUNCE_TIME_MS, async () => {
        if (Object.keys(pendingUpdatesRef.current).length === 0) {
          return;
        }

        // Wait for all pending mutations to complete
        const promises = Object.values(pendingUpdatesRef.current);
        const result = await Promise.all(promises);
        pendingUpdatesRef.current = {};

        onMutationsComplete?.();

        return result;
      }),
    [onMutationsComplete],
  );

  const householdUsesFixedFlexBudgeting = budgetSystem === BudgetSystem.FIXED_AND_FLEX;
  const { optimisticUpdateCacheForCategoryOrGroup } = useOptimisticBudgetUpdates({
    householdUsesFixedFlexBudgeting,
  });

  const createBudgetItemUpdater =
    (context: BudgetUpdaterContext) => async (updateDetails: BudgetUpdateDetails) => {
      const { startDate, categoryId, categoryGroupId } = context;
      const { amount, applyToFuture } = updateDetails;

      // Only allow one of categoryId or categoryGroupId to be provided
      if (categoryId && categoryGroupId) {
        throw new Error('Only one of categoryId or categoryGroupId can be provided');
      }
      // Make sure the mutation key is unique so that we don't fire multiple mutations for the same item
      const mutationKey = `${categoryId || categoryGroupId}-${startDate.toISODate()}`;

      const input: ExtractInputFromDocument<typeof UPDATE_BUDGET_ITEM> = {
        defaultAmount: undefined,
        startDate: startDate.toISODate(),
        timeframe: BudgetTimeframeInput.MONTH,
        amount,
        applyToFuture,
        categoryId,
        categoryGroupId,
      };

      if (categoryId || categoryGroupId) {
        // Add the mutation promise to the pending updates so that we can wait for it to complete
        pendingUpdatesRef.current[mutationKey] = performMutation({ variables: { input } });
      }

      if (optimisticCacheUpdate && (categoryId || categoryGroupId)) {
        optimisticUpdateCacheForCategoryOrGroup({
          startDate,
          id: categoryId || categoryGroupId!,
          amount,
          isGroup: !!categoryGroupId,
          applyToFuture,
        });
      }

      return debouncedOnMutationsComplete();
    };

  return { createBudgetItemUpdater, loading };
};

export const UPDATE_BUDGET_ITEM = gql(/* GraphQL */ `
  mutation Common_UpdateBudgetItem($input: UpdateOrCreateBudgetItemMutationInput!) {
    updateOrCreateBudgetItem(input: $input) {
      budgetItem {
        id
        plannedCashFlowAmount
      }
    }
  }
`);

export default useUpdateBudgetItemMutation;
