import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';
import Tooltip from 'components/lib/ui/Tooltip';

import HighlightTextContext from 'lib/contexts/HighlightTextContext';

import { TRANSACTION_ROW_ICON_SIZE_PX } from 'constants/transactions';

const Root = styled.div<{ clickable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: ${TRANSACTION_ROW_ICON_SIZE_PX}px;
  width: ${TRANSACTION_ROW_ICON_SIZE_PX}px;
  border-radius: ${({ theme }) => theme.radius.round};
  transition: ${({ theme }) => theme.transition.default};

  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  :hover {
    background: ${({ theme, clickable }) => (clickable ? theme.color.grayBackground : 'none')};
  }
`;

const FileIcon = styled(Icon).attrs({ name: 'file', size: 12 })<{ highlight: boolean }>`
  color: ${({ highlight, theme }) => (highlight ? theme.color.yellowDark : theme.color.textLight)};

  ${Root}:hover & {
    color: ${({ highlight, theme }) => (highlight ? theme.color.yellowDark : theme.color.text)};
  }
`;

type Props = {
  notes: string;
  onClick?: () => void;
};

const TransactionNotesIcon = ({ notes, onClick }: Props) => {
  const { searchWords } = useContext(HighlightTextContext);
  const shouldHighlight = useMemo(
    () => searchWords.some((search) => notes.toLowerCase().includes(search.toLowerCase())),
    [searchWords, notes],
  );
  const clickable = !!onClick;

  return (
    <Tooltip content={notes}>
      <Root onClick={onClick} clickable={clickable}>
        <FileIcon highlight={shouldHighlight} />
      </Root>
    </Tooltip>
  );
};

export default TransactionNotesIcon;
