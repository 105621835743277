import classnames from 'classnames';
import React from 'react';

/**
 * We need to add an `.fs-exclude` or `.fs-mask` to certain elements to make sure they don't get recorded
 * by our analytics tools. Right now, we're using Clarity and Segment, but at some point we used FullStory.
 * This is why the class names start with `fs`.
 *
 * If you want an easy syntax and aren't worried about className collisions, use:
 * <YourComponent {...sensitiveClassProps} />
 *
 * If you want to wrap an entire component definition and handle className collisions,
 * use the HOC.
 *
 * The best way to test this visually is to add something like the below to GlobalStyle.tsx
 * .fs-exclude {
 *   border: 1px dashed #FFAAAA;
 *   background: rgba(255, 0, 0, .1);
 *   opacity: 0.4;
 * }
 */

const FS_EXCLUDE_CLASSNAME = 'fs-exclude';
const FS_MASK_CLASSNAME = 'fs-mask';

export const sensitiveClassProps = { className: FS_EXCLUDE_CLASSNAME, 'data-clarity-mask': 'true' };
export const maskClassProps = { className: FS_MASK_CLASSNAME, 'data-clarity-mask': 'true' };

export const withSensitiveData =
  <P extends { className?: string }, _>(Component: React.ComponentType<P>) =>
  (props: P) => {
    const { className } = props;
    const newClassName = classnames(className, FS_EXCLUDE_CLASSNAME);

    return <Component {...props} className={newClassName} />;
  };

export default withSensitiveData;
