import { useMediaQuery } from '@react-hook/media-query';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import {
  DRAWER_WIDTH_PX,
  ANIMATION_DURATION_SECONDS as DRAWER_ANIMATION_DURATION_SECONDS,
} from 'components/lib/ui/SideDrawer';

import { spacing } from 'common/lib/theme/dynamic';
import useTheme from 'lib/hooks/useTheme';
import { getIsSideDrawerVisible } from 'selectors';

const Root = styled.div<{ $isDrawerOpen: boolean }>`
  position: fixed;
  bottom: 0;
  right: ${spacing.gutter};
  pointer-events: none;
  transform: ${({ $isDrawerOpen }) =>
    $isDrawerOpen ? `translateX(-${DRAWER_WIDTH_PX}px)` : 'none'};
  transition: transform ${DRAWER_ANIMATION_DURATION_SECONDS}s ease-out;
`;

const FooterButtonContainer = ({ children }: React.PropsWithChildren<unknown>) => {
  const theme = useTheme();

  const isSideDrawerVisible = useSelector(getIsSideDrawerVisible);
  const isNarrowScreen = useMediaQuery(`only screen and (max-width: ${theme.breakPoints.lg}px)`);

  return (
    <Root $isDrawerOpen={isSideDrawerVisible && !isNarrowScreen}>
      <FlexContainer gap="gutter" justifyEnd alignEnd>
        {children}
      </FlexContainer>
    </Root>
  );
};

export default React.memo(FooterButtonContainer);
