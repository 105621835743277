export enum ReviewStatus {
  NeedsReview = 'needs_review',
  Reviewed = 'reviewed',
}

const getNeedsReviewByLabelExtension = (
  needsReviewByUserAction?: string | null,
  unassignNeedsReviewByUserAction?: boolean,
) => {
  if (unassignNeedsReviewByUserAction) {
    return ` by anyone`;
  }

  if (!needsReviewByUserAction) {
    return '';
  }

  return ` by ${needsReviewByUserAction}`;
};

export const getDescriptionForReviewStatus = (
  status: string,
  needsReviewByUserAction?: string | null,
  unassignNeedsReviewByUserAction?: boolean,
) => {
  switch (status) {
    case ReviewStatus.NeedsReview:
      return `Needs review${getNeedsReviewByLabelExtension(
        needsReviewByUserAction,
        unassignNeedsReviewByUserAction,
      )}`;
    case ReviewStatus.Reviewed:
      return 'Reviewed';
    default:
      return '';
  }
};

export const REVIEW_STATUS_OPTIONS = Object.values(ReviewStatus).map((value) => ({
  value,
  label: getDescriptionForReviewStatus(value),
}));

export enum TransactionsDropdownFilter {
  All = 'all',
  NeedsReview = 'needs_review',
}

export const DROPDOWN_FILTER_OPTIONS = [
  { value: TransactionsDropdownFilter.All, label: 'All' },
  { value: TransactionsDropdownFilter.NeedsReview, label: 'Needs review' },
];
