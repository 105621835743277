import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import type { AnalyticsEvent } from 'common/constants/analytics';
import { PremiumEventNames } from 'common/constants/analytics';

import { gql as newGql } from 'common/generated/gql';

type Props = {
  track: (event: AnalyticsEvent) => void;
  onComplete?: () => void;
};

const useSkipPremiumUpsell = ({ track, onComplete }: Props) => {
  const [setSkippedPremiumUpsell] = useMutation(MUTATION);

  const skip = useCallback(() => {
    track(PremiumEventNames.PremiumSkipped);
    setSkippedPremiumUpsell();
    onComplete?.();
  }, [track, setSkippedPremiumUpsell, onComplete]);

  return skip;
};

const MUTATION = newGql(/* GraphQL */ `
  mutation Common_SetHasSkippedPremiumUpsell {
    updateUserProfile(input: { skippedPremiumUpsell: true }) {
      userProfile {
        id
        skippedPremiumUpsellAt
      }
    }
  }
`);

export default useSkipPremiumUpsell;
