import * as React from 'react';
import { isMacOs } from 'react-device-detect';
import styled from 'styled-components';

const Root = styled.kbd`
  color: ${({ theme }) => theme.color.textLight};
  font-family: inherit;
  font-size: ${({ theme }) => theme.fontSize.small};
`;

type Props = {
  /** The shortcut text (use "mod" for modifier key, it will be changed depending on user's platform) */
  text: string;
  className?: string;
};

const KeyboardShortcut = ({ text, className }: Props) => {
  const parsedText = text.replace(/mod/gi, isMacOs ? '⌘' : 'Ctrl + ');
  return <Root className={className}>{parsedText}</Root>;
};

export default KeyboardShortcut;
