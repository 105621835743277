import * as RA from 'ramda-adjunct';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import uuid from 'uuid/v4';

import { setOAuthStateString, clearOAuthStateString } from 'actions';
import typewriter from 'lib/analytics/typewriter';
import { getCoinbaseRedirectUri } from 'lib/external/coinbase';
import getStringEnvVar from 'lib/getStringEnvVar';
import { useQueryParam, useDispatch } from 'lib/hooks';
import { getOAuthStateString } from 'selectors';

import { COINBASE_SCOPES } from 'common/constants/coinbase';
import { getCoinbaseOAuthURL } from 'common/constants/externalUrls';

const useCoinbaseOAuth = ({ onSuccess }: { onSuccess: (code: string) => void }) => {
  const dispatch = useDispatch();
  const returnedCode = useQueryParam('code');
  const returnedOAuthStateString = useQueryParam('state');
  const currentOAuthStateString = useSelector(getOAuthStateString);

  const openCoinbaseConnect = useCallback(async () => {
    const stateString = uuid();
    await dispatch(setOAuthStateString(stateString));
    typewriter.coinbaseConnectOpened();
    window.location.href = getCoinbaseOAuthURL(
      getStringEnvVar('REACT_APP_COINBASE_CLIENT_ID'),
      getCoinbaseRedirectUri(),
      COINBASE_SCOPES,
      stateString,
    );
  }, [dispatch]);

  useEffect(() => {
    if (returnedCode && returnedOAuthStateString && currentOAuthStateString) {
      if (returnedOAuthStateString === currentOAuthStateString) {
        dispatch(clearOAuthStateString());
        onSuccess(returnedCode);
      } else {
        throw Error(
          `mismatched coinbase oauth csrf token returned: ${returnedOAuthStateString},` +
            `current: ${currentOAuthStateString}. Blocked!`,
        );
      }
    }
  }, [returnedCode, returnedOAuthStateString, currentOAuthStateString]);

  return { openCoinbaseConnect, hasCode: RA.isNotNil(returnedCode) };
};

export default useCoinbaseOAuth;
