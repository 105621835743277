import variables from 'common/lib/theme/variables';

import type { StyleMixin } from 'common/types/Styles';

const small: StyleMixin = (context) => `
  box-shadow: 0px 1px 2px ${variables.color.shadow.small(context)};
`;

const medium: StyleMixin = (context) =>
  context.theme.isV2ThemeEnabled
    ? `
      box-shadow: 0px 2px 4px ${variables.color.shadow.medium(context)};
    `
    : `
      box-shadow: 0px 4px 8px ${variables.color.shadow.medium(context)};
    `;

const inset: StyleMixin = (context) => `
  box-shadow: inset 0px 2px 4px ${variables.color.shadow.inset(context)};
`;

const large: StyleMixin = (context) =>
  context.theme.isV2ThemeEnabled
    ? `
      box-shadow: 0px 3px 6px ${variables.color.shadow.large(context)};
    `
    : `
      box-shadow: 0 10px 15px -3px ${variables.color.shadow.large(context)}, 0 4px 6px -4px ${variables.color.shadow.large(context)};
    `;

const card: StyleMixin = (context) =>
  context.theme.isV2ThemeEnabled
    ? medium(context)
    : `
  box-shadow: 0px 4px 8px ${variables.color.shadow.card(context)};
`;

const boxShadow = {
  small,
  medium,
  inset,
  large,
  card,
};

export default boxShadow;
