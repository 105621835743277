import styled from 'styled-components';

const Tag = styled.div<{ $marginLeft?: boolean }>`
  display: inline-flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.xxsmall} ${({ theme }) => theme.spacing.xsmall}
    ${({ theme }) => theme.spacing.xxsmall}
    ${({ $marginLeft, theme }) => ($marginLeft ? theme.spacing.xxsmall : 0)};
  background: ${({ theme }) => theme.color.grayBackground};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  border-radius: ${({ theme }) => theme.radius.small};
  padding: ${({ theme }) => `${theme.spacing.xsmall} ${theme.spacing.xsmall}`};
  position: relative;
  line-height: 100%;
`;

export default Tag;
