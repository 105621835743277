import * as R from 'ramda';

import type { PayloadErrorFields_fieldErrors } from 'common/generated/graphQlTypes/PayloadErrorFields';

const convertGraphQlErrorsToRestFormat = (
  errors: PayloadErrorFields_fieldErrors[],
): { [field: string]: string[] } => {
  const byField = R.indexBy(({ field }) => field, errors);
  return R.map(({ messages }) => messages, byField);
};

export default convertGraphQlErrorsToRestFormat;
