import React from 'react';
import styled from 'styled-components';

import Empty from 'components/lib/ui/Empty';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

const Root = styled(FlexContainer).attrs({ center: true, column: true })`
  padding: ${({ theme }) => theme.spacing.xlarge} 0;
  padding-top: ${({ theme }) => theme.spacing.xxxlarge};
`;

const StyledEmpty = styled(Empty)`
  padding-top: ${({ theme }) => theme.spacing.xlarge};
`;

const IconCircle = styled(FlexContainer).attrs({ center: true })`
  width: 68px;
  height: 68px;
  background: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.round};
`;

const StyledIcon = styled(Icon).attrs({ name: 'inbox' })`
  width: 24px;
  height: 24px;
`;

const NotificationCenterEmpty = () => (
  <Root>
    <IconCircle>
      <StyledIcon />
    </IconCircle>
    <StyledEmpty
      title="You're all set."
      subtitle="We'll keep you updated on any future notifications."
    />
  </Root>
);

export default NotificationCenterEmpty;
