import * as React from 'react';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import Text from 'components/lib/ui/Text';

export const HEADER_HEIGHT_PX = 57;

const Root = styled(Flex)`
  align-items: center;
  height: ${HEADER_HEIGHT_PX}px;
  padding: 0 ${({ theme }) => theme.spacing.xlarge};
  gap: ${(props) => props.theme.spacing.xxsmall};
  background: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

type ColumnProps = {
  span?: number;
};

const Column = styled(Text).attrs({ weight: 'medium' })<ColumnProps>`
  flex: ${(props) => props.span ?? 1};
  font-size: ${(props) => props.theme.fontSize.small};
`;

const ClientsListHeader = () => (
  <Root>
    <Column span={2}>Code</Column>
    <Column span={3}>Name</Column>
    <Column span={2}>Status</Column>
    <Column span={2} />
  </Root>
);

export default ClientsListHeader;
