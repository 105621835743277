import React from 'react';
import styled, { css } from 'styled-components';

import Icon from 'components/lib/ui/Icon';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radius.round};
  background-color: ${({ theme }) => theme.color.green};
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.color.textWhite};
`;

const Check = styled(Icon).attrs({ name: 'check ' })<{ sizePx?: number }>`
  ${({ sizePx }) =>
    sizePx &&
    css`
      width: ${sizePx}px;
      height: ${sizePx}px;
    `}
`;

type Props = {
  iconSizePx?: number;
  className?: string;
};

const CheckCircle = ({ iconSizePx, className }: Props) => (
  <Root className={className}>
    <Check sizePx={iconSizePx} />
  </Root>
);

export default CheckCircle;
