import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CardFooter from 'components/lib/ui/CardFooter';
import Modal from 'components/lib/ui/Modal';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import useDemoHousehold from 'lib/hooks/useDemoHousehold';

import { DEMO_HOUSEHOLD } from 'common/constants/copy';
import routes from 'constants/routes';

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const DemoHouseholdModal = () => {
  const history = useHistory();
  const { setDemoHouseholdModalVisible } = useDemoHousehold();
  const onDismiss = () => setDemoHouseholdModalVisible(false);

  return (
    <Modal onClose={onDismiss}>
      <ModalCard title={DEMO_HOUSEHOLD.FEATURE_DISABLED}>
        <Root>
          <Text>{DEMO_HOUSEHOLD.MODAL_SUBTITLE}</Text>
        </Root>
        <CardFooter>
          <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
          <PrimaryButton
            onClick={() => {
              onDismiss();
              history.push(routes.logout());
            }}
          >
            Sign up for Monarch
          </PrimaryButton>
        </CardFooter>
      </ModalCard>
    </Modal>
  );
};

export default DemoHouseholdModal;
