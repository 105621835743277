import React from 'react';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import type Toggle from 'components/lib/ui/Toggle';
import ToggleWithText from 'components/lib/ui/ToggleWithText';

const ToggleFieldWithText = ({
  label,
  subtext,
  className,
  ...props
}: AbstractFieldProps<React.ComponentProps<typeof Toggle>> & {
  subtext: React.ReactNode;
  label: string;
}) => (
  <AbstractField {...props} hideLabel className={className}>
    {({ id, value, setFieldValue, handleBlur, handleChange }) => (
      <ToggleWithText
        label={label}
        subtext={subtext}
        {...props}
        id={id}
        checked={value ?? false}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(props.name, e.target.checked);
          handleChange?.(e);
          handleBlur?.(e);
        }}
      />
    )}
  </AbstractField>
);

export default ToggleFieldWithText;
