import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';

import Cloudinary from 'lib/CloudinaryCore';

import {
  GOAL_IMAGE_GRADIENT_COLORS,
  GOAL_IMAGE_GRADIENT_COLORS_ARCHIVED,
} from 'common/constants/goals';

const Root = styled(FlexContainer).attrs({ justifyCenter: true })`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radius.medium};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const GradientOverlay = styled.div<{ $colors: string[]; $opacity?: number }>`
  background: linear-gradient(${({ $colors }) => $colors[0]}, ${({ $colors }) => $colors[1]});
  opacity: ${({ $opacity }) => $opacity ?? 1};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Image = styled.img`
  object-fit: cover;
  flex: 1;
  width: 100%;
  height: 100%;
`;

type Props = {
  imageStorageProvider?: string | null;
  imageStorageProviderId?: string | null;
  size: 'small' | 'medium' | 'large';
  showGradient?: boolean;
  archived?: boolean;
  className?: string;
  gradientOpacity?: number;
};

const GoalImage = ({
  imageStorageProvider,
  imageStorageProviderId,
  size,
  showGradient = true,
  archived,
  className,
  gradientOpacity,
}: Props) => {
  const width = {
    small: 500,
    medium: 900,
    large: 1400,
  }[size];

  const uri = (() => {
    if (!imageStorageProvider || !imageStorageProviderId) {
      return undefined;
    }

    if (imageStorageProvider === 'cloudinary') {
      return Cloudinary.url(imageStorageProviderId, {
        transformation: `goal-${size}`,
        secure: true,
      });
    }

    if (imageStorageProvider === 'unsplash') {
      return `${imageStorageProviderId}&w=${width}&fit=crop&q=80`;
    }
  })();

  const gradientColors = archived
    ? GOAL_IMAGE_GRADIENT_COLORS_ARCHIVED
    : GOAL_IMAGE_GRADIENT_COLORS;

  return (
    <Root className={className}>
      <Image src={uri} />
      {showGradient && <GradientOverlay $colors={gradientColors} $opacity={gradientOpacity} />}
    </Root>
  );
};
export default GoalImage;
