import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import type { EventWrapperProps } from 'react-big-calendar';
import { Calendar, luxonLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';

import DateHeader from 'components/recurring/calendar/DateHeader';
import EventWrapper from 'components/recurring/calendar/EventWrapper';
import type { RecurringCalendarItem } from 'components/recurring/calendar/EventWrapper';
import Header from 'components/recurring/calendar/Header';

import type { RecurringTransactionItem } from 'common/lib/recurring/types';
import boxShadow from 'common/lib/styles/boxShadow';

const localizer = luxonLocalizer(DateTime);

const EVENT_HEIGHT_PT = 27;
const MIN_MONTH_HEIGHT_PT = 48;

const Root = styled.div<{ $monthHeight: number }>`
  background-color: ${({ theme }) => theme.color.white};
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
  border-radius: ${({ theme }) => theme.radius.medium};
  ${boxShadow.medium}
  padding-bottom: ${({ theme }) => theme.spacing.xlarge};

  .rbc-calendar {
    min-height: 612px;
  }

  .rbc-header,
  .rbc-month-row,
  .rbc-month-view {
    border: none;
  }

  .rbc-month-header {
    padding: ${({ theme }) => theme.spacing.default} 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.grayLight};
  }

  .rbc-month-view {
    padding: 0 ${({ theme }) => theme.spacing.xlarge};
  }

  .rbc-today {
    background-color: ${({ theme }) => theme.color.white};
  }

  /* blocks row from being on top of popover */
  .rbc-row-content {
    z-index: unset;
  }

  .rbc-month-row {
    border-right: 1px solid ${({ theme }) => theme.color.grayLight};
    border-left: 1px solid ${({ theme }) => theme.color.grayLight};
    border-bottom: 1px solid ${({ theme }) => theme.color.grayLight};
    min-height: ${({ $monthHeight }) => $monthHeight}px;
  }

  /* hide dates off range */
  .rbc-off-range {
    opacity: 0;
  }

  .rbc-off-range-bg {
    background-color: ${({ theme }) => theme.color.white};
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${({ theme }) => theme.color.grayLight};
  }
`;

type Props = {
  items: RecurringTransactionItem[];
  startDate: DateTime;
  setStartDate: (date: string) => void;
  refetch: () => void;
};

const RecurringCalendar = ({ items = [], startDate, setStartDate, refetch }: Props) => {
  const { calendarDate, getNow, components } = useMemo(
    () => ({
      calendarDate: startDate.toJSDate(),
      getNow: () => DateTime.local().toJSDate(),
      components: {
        toolbar: () => null,
        header: Header,
        eventWrapper: (props: EventWrapperProps<RecurringCalendarItem>) => (
          <EventWrapper refetch={refetch} {...props} />
        ),
        month: {
          dateHeader: DateHeader,
        },
      },
    }),
    [startDate, setStartDate, refetch],
  );

  const itemsToEvents = useMemo(
    () =>
      items.map((item) => ({
        title: item?.stream?.name,
        start: DateTime.fromISO(item?.date || '').toJSDate(),
        end: DateTime.fromISO(item?.date || '').toJSDate(),
        id: item?.stream.id,
        resource: item,
      })),
    [items],
  );

  const groupedItems = groupBy(items, (item) => item?.date);
  const maxGroupItems = Math.max(...Object.values(groupedItems).map((el) => el.length));

  // we increase height for each event so none are hidden
  const monthHeight = MIN_MONTH_HEIGHT_PT + maxGroupItems * EVENT_HEIGHT_PT;

  return (
    <Root $monthHeight={monthHeight}>
      <Calendar
        components={components}
        date={calendarDate}
        getNow={getNow}
        events={itemsToEvents}
        localizer={localizer}
        showMultiDayTimes
        showAllEvents
      />
    </Root>
  );
};

export default RecurringCalendar;
