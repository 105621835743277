import { DateTime } from 'luxon';
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import SettingsCard from 'components/lib/layouts/SettingsCard';
import Banner from 'components/lib/ui/Banner';
import Column from 'components/lib/ui/Column';
import DeleteSyncedSnapshotsForm from 'components/routes/settings/dataSettings/DeleteSyncedSnapshotsForm';
import DeleteTransactionsForm from 'components/routes/settings/dataSettings/DeleteTransactionsForm';
import DownloadAccountsBalancesForm from 'components/routes/settings/dataSettings/DownloadAccountsBalancesForm';
import DownloadTransactionsButton from 'components/routes/settings/dataSettings/DownloadTransactionsButton';

import useDemoHousehold from 'lib/hooks/useDemoHousehold';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';

const StyledSettingsCard = styled(SettingsCard)`
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
`;

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const Description = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledBanner = styled(Banner)`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const DataSettings = () => {
  const { isDemoHousehold } = useDemoHousehold();
  const [deletedTransactionsCount, setDeletedTransactionsCount] = useState(0);
  const [deletedSnapshotsBeforeDate, setDeletedSnapshotsBeforeDate] = useState('');

  const isNewDownloadTransactionsOn = useIsFeatureFlagOn('new-download-transactions');

  return (
    <Column md={9}>
      <Helmet>
        <title>Delete transaction history in synced accounts</title>
      </Helmet>

      {isNewDownloadTransactionsOn && (
        <StyledSettingsCard title="Download transactions" isDemo={isDemoHousehold}>
          <CardBody>
            <Description>
              Easily download all your transactions in CSV format by clicking the button below.
            </Description>

            <DownloadTransactionsButton />
          </CardBody>
        </StyledSettingsCard>
      )}
      <StyledSettingsCard title="Download account balances" isDemo={isDemoHousehold}>
        <CardBody>
          <Description>
            Select an account to download all of its account balances in a CSV format.
          </Description>

          <DownloadAccountsBalancesForm />
        </CardBody>
      </StyledSettingsCard>
      <StyledSettingsCard
        title="Delete transaction history in synced accounts"
        isDemo={isDemoHousehold}
      >
        <CardBody>
          {deletedTransactionsCount > 0 && (
            <StyledBanner type="success">
              Successfully deleted {deletedTransactionsCount} transactions
            </StyledBanner>
          )}

          <Description>
            For synced accounts, the amount of historical data Monarch receives can vary. This can
            lead to different lengths of transaction history in your accounts.
          </Description>
          <Description>
            You can give yourself a clean start date for your transactions by specifying a date
            below, we&apos;ll delete any transactions that occurred before the date you specify.
            Manual accounts will not be affected.
          </Description>

          <DeleteTransactionsForm
            setDeletedTransactionsCount={(count: number) => {
              setDeletedTransactionsCount(count);
            }}
          />
        </CardBody>
      </StyledSettingsCard>

      <StyledSettingsCard title="Delete synced account balance snapshots" isDemo={isDemoHousehold}>
        <CardBody>
          {!!deletedSnapshotsBeforeDate && (
            <StyledBanner type="success">
              Successfully deleted balance snapshots before{' '}
              {DateTime.fromISO(deletedSnapshotsBeforeDate).toFormat('MMM d, yyyy')}
            </StyledBanner>
          )}

          <Description>
            Monarch saves a snapshot of your balances in synced accounts every day to track your net
            worth overtime. You can delete any balance history before the date you specify below.
            This is useful for cleaning up historical data when you didn’t have all of your accounts
            synced yet. Manual accounts will not be affected.
          </Description>

          <DeleteSyncedSnapshotsForm
            setDeletedSnapshotsBeforeDate={setDeletedSnapshotsBeforeDate}
          />
        </CardBody>
      </StyledSettingsCard>
    </Column>
  );
};

export default DataSettings;
