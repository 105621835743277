import React from 'react';

import ModalCard from 'components/lib/ui/ModalCard';
import ProductLaunchPrompt from 'components/lib/ui/ProductLaunchPrompt';

type Props = {
  icon: string;
  title: React.ReactNode;
  rows: {
    icon: string;
    title: React.ReactNode;
    description: string;
  }[];
  button: {
    title: React.ReactNode;
    onClick: () => void;
    pending?: boolean;
  };
  footer?: React.ReactNode;
};

const ProductLaunchModal = ({ icon, title, rows, button, footer }: Props) => (
  <ModalCard hideHeader hideCloseButton hideBottomBorder>
    <ProductLaunchPrompt icon={icon} title={title} rows={rows} button={button} footer={footer} />
  </ModalCard>
);

export default ProductLaunchModal;
