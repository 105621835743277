import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import type { Column } from 'react-table';
import styled, { css } from 'styled-components';

import AccountSelectField from 'components/lib/form/AccountSelectField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import AnimatedNumber from 'components/lib/ui/AnimatedNumber';
import Avatar from 'components/lib/ui/Avatar';
import Banner from 'components/lib/ui/Banner';
import Drawer from 'components/lib/ui/Drawer';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Link from 'components/lib/ui/Link';
import Pill from 'components/lib/ui/Pill';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import RouteLink from 'components/lib/ui/link/RouteLink';
import Table from 'components/lib/ui/table/Table';
import DayOfTheMonthField from 'components/recurring/DayOfMonthField';

import { unescapeFieldName } from 'common/lib/form/field';
import useLoading from 'common/lib/hooks/useLoading';
import useToggle from 'common/lib/hooks/useToggle';
import { USE_STATEMENT_DUE_DATE_VALUE } from 'common/lib/recurring';
import { fontSize, spacing } from 'common/lib/theme/dynamic';
import { formatCurrencyNoCents } from 'common/utils/Currency';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';

import { AccountTypeName } from 'common/constants/accounts';
import { HELP_CENTER_GETTING_STARTED_WITH_BILL_SYNCING } from 'common/constants/externalUrls';
import { getSpinwheelLiabilityType } from 'common/constants/recurringTransactions';
import type { LiabilityType } from 'common/constants/recurringTransactions';
import routes from 'constants/routes';

import { RecurringStreamReviewStatus } from 'common/generated/graphql';
import type {
  AccountMapping,
  Common_GetSpinwheelCreditReportQuery,
} from 'common/generated/graphql';
import type { ArrayType, ElementOf } from 'common/types/utility';

export const IGNORE_ACCOUNT_VALUE = 'ignore';
const AVATAR_SIZE_PX = 16;

type LiabilityAccount = ElementOf<
  Common_GetSpinwheelCreditReportQuery,
  'creditReportLiabilityAccounts'
>;

const getInitialAccountMappingForAccount = (liability: LiabilityAccount): string | undefined => {
  if (liability?.account?.id) {
    return liability.account.id;
  }

  // We should default to ignored fro closed accounts
  // so the user is not required to map those
  if (!liability?.isOpen) {
    return IGNORE_ACCOUNT_VALUE;
  }

  return liability?.recurringTransactionStream?.reviewStatus === RecurringStreamReviewStatus.IGNORED
    ? IGNORE_ACCOUNT_VALUE
    : undefined;
};

const Root = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  max-width: 1200px;
  width: 100%;
`;

const StyledRouteLink = styled(RouteLink)`
  text-decoration: underline;
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
  max-width: 400px;
  text-align: center;
`;

const Subtitle = styled(Text)`
  text-align: center;
  max-width: 700px;
`;

const LeftAlignHeader = styled(Text).attrs({ align: 'left ' })`
  display: block;
`;

const MonarchAccountHeader = styled(LeftAlignHeader)`
  min-width: 300px;
`;

const CreditReportAccountHeader = styled(LeftAlignHeader)`
  display: inline-block;
`;

const AccountBalanceHeader = styled(LeftAlignHeader)``;

const AccountBalanceValue = styled.div`
  text-align: left;
`;

const DayOfTheMonthHeader = styled(LeftAlignHeader)`
  min-width: 250px;
`;

const TableContainer = styled.div`
  min-width: 100%;
  text-align: left;
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  border-radius: ${({ theme }) => theme.radius.medium};
  margin: ${({ theme }) => theme.spacing.xxlarge} 0;

  > * {
    margin-bottom: 0;
  }
`;

const ClosedTableContainer = styled(TableContainer)`
  margin: 0;
`;

const StyledSubmitButton = styled(FormSubmitButton)`
  width: 100%;
  max-width: 438px;
  margin-top: 24px;
  margin-bottom: 80px;
`;

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TooltipContent = styled.div`
  padding: ${({ theme }) => theme.spacing.xsmall};
  text-align: left;
`;

const InfoIcon = styled(Icon).attrs({ name: 'info ', size: 12 })`
  position: relative;
  top: 1px;
`;

const OwnerInfo = styled(FlexContainer).attrs({ alignCenter: true })`
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

const LiabilityInfo = styled(Text).attrs({ size: 'base' })`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

// a lot of magic numbers here, but this is a custom-size pill only for this page
const LiabilityInfoPill = styled(Pill)`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  margin-right: ${({ theme }) => theme.spacing.xxsmall};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  height: 26px;
  line-height: 22px;
  padding: ${({ theme }) => theme.spacing.xxxsmall} ${({ theme }) => theme.spacing.xsmall};
`;

const ClosedAccountPill = styled(Pill)`
  margin-bottom: ${spacing.xxlarge};
`;

const ClosedPillContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xxsmall};
  font-size: ${fontSize.small};
`;

const ArrowIcon = styled(Icon).attrs({ name: 'chevron-down' })<{ rotate: boolean }>`
  width: 12px;
  height: 12px;
  transition: all 0.5s cubic-bezier(0.176, 0.88, 0.32, 1.48);
  transform: rotate(270deg);

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(360deg);
    `}
`;

const TooltipTrigger = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

const TooltipHeader = ({
  children,
  content,
}: {
  children: React.ReactNode & React.ReactElement;
  content: React.ReactNode;
}) => {
  if (!children) {
    return null;
  }

  return (
    <Tooltip
      content={<TooltipContent>{content}</TooltipContent>}
      place="top"
      opacity={1}
      fitContent
    >
      {children}
    </Tooltip>
  );
};

type Props = {
  creditReportLiabilityAccounts: Common_GetSpinwheelCreditReportQuery['creditReportLiabilityAccounts'];
  onSubmit: (values: { accountMapping: AccountMapping[] }) => Promise<any>;
};

// orders the table by account name in ascending order (A-Z)
const TABLE_INITIAL_STATE = { sortBy: [{ id: 'description', desc: false }] };

// The base date prefix is used to differentiate the baseDate field from the other fields
const DAY_OF_THE_MONTH_PREFIX = 'dayOfTheMonth-';

const MapSpinwheelLiabilitiesAccounts = ({ creditReportLiabilityAccounts, onSubmit }: Props) => {
  const [isOpenClosedAccount, { toggle: toggleClosedAccounts }] = useToggle(false);

  const [isLoadingAccounts, accountOptions, { refetch: refetchAccountOptions }] =
    useAccountSelectOptions({
      queryFilters: {
        excludeAccountTypes: [
          AccountTypeName.BROKERAGE,
          AccountTypeName.OTHER_ASSET,
          AccountTypeName.DEPOSITORY,
          AccountTypeName.OTHER,
          AccountTypeName.VALUABLES,
          AccountTypeName.VEHICLE,
        ],
      },
    });

  const mapLiabilities = (
    liability: Common_GetSpinwheelCreditReportQuery['creditReportLiabilityAccounts'][0],
  ) => ({
    spinwheelLiabilityId: liability?.spinwheelLiabilityId,
    description: liability?.description,
    userName: liability?.spinwheelUser.user.name,
    profilePictureUrl: liability?.spinwheelUser.user.profilePictureUrl,
    accountName: IGNORE_ACCOUNT_VALUE,
    liabilityType: liability?.liabilityType,
    dayOfTheMonth: liability?.recurringTransactionStream?.dayOfTheMonth,
    totalBalance: liability?.currentTotalBalance,
    lastStatementDueDate: liability?.lastStatement?.dueDate,
  });

  const openTableData = useMemo(
    () =>
      creditReportLiabilityAccounts
        ?.filter((liability) => liability?.isOpen)
        .map((liability) => mapLiabilities(liability)),
    [creditReportLiabilityAccounts],
  );

  const closedTableData = useMemo(
    () =>
      creditReportLiabilityAccounts
        ?.filter((liability) => !liability?.isOpen)
        .map((liability) => mapLiabilities(liability)),
    [creditReportLiabilityAccounts],
  );

  const initialValues = useMemo(
    () =>
      R.mergeAll([
        R.fromPairs(
          creditReportLiabilityAccounts.map((liability) => [
            liability?.spinwheelLiabilityId || '',
            getInitialAccountMappingForAccount(liability),
          ]),
        ),
        R.fromPairs(
          creditReportLiabilityAccounts.map((liability) => [
            `${DAY_OF_THE_MONTH_PREFIX}${liability?.spinwheelLiabilityId}`,
            liability?.recurringTransactionStream?.dayOfTheMonth?.toString() ||
              USE_STATEMENT_DUE_DATE_VALUE,
          ]),
        ),
      ]),
    [creditReportLiabilityAccounts],
  );

  const columns = useMemo(
    (): Column<ArrayType<typeof openTableData>>[] => [
      {
        accessor: 'description',
        Header: () => <CreditReportAccountHeader>Credit Report Account</CreditReportAccountHeader>,
        Cell: ({ row }) => (
          <>
            <LiabilityInfo>{row.original.description}</LiabilityInfo>
            <Flex row justifyStart wrap>
              <LiabilityInfoPill>
                {getSpinwheelLiabilityType(row.original.liabilityType as LiabilityType)}
              </LiabilityInfoPill>
              <LiabilityInfoPill>
                <OwnerInfo>
                  <Avatar $url={row.original.profilePictureUrl} $size={AVATAR_SIZE_PX} />
                  {row.original.userName}
                </OwnerInfo>
              </LiabilityInfoPill>
            </Flex>
          </>
        ),
      },
      {
        accessor: 'totalBalance',
        disableSortBy: true,
        Header: () => (
          <AccountBalanceHeader>
            <TooltipHeader content="The total balance on the account.">
              <TooltipTrigger>
                Account Balance <InfoIcon />
              </TooltipTrigger>
            </TooltipHeader>
          </AccountBalanceHeader>
        ),
        Cell: ({ value }) => (
          <AccountBalanceValue>
            {value ? (
              <AnimatedNumber
                value={value ?? 0}
                formattingFn={formatCurrencyNoCents}
                decimals={2}
              />
            ) : (
              '-'
            )}
          </AccountBalanceValue>
        ),
      },
      {
        id: 'dayOfTheMonth',
        Header: () => (
          <DayOfTheMonthHeader>
            <TooltipHeader
              content={
                <>
                  The payment due date each
                  <br /> month. You may either sync the
                  <br /> date with Spinwheel or choose
                  <br /> a custom due date.
                </>
              }
            >
              <TooltipTrigger>
                Payment Due Date <InfoIcon />
              </TooltipTrigger>
            </TooltipHeader>
          </DayOfTheMonthHeader>
        ),
        accessor: 'dayOfTheMonth',
        disableSortBy: true,
        Cell: ({ row }) => (
          <DayOfTheMonthField
            name={`${DAY_OF_THE_MONTH_PREFIX}${row.original.spinwheelLiabilityId}`}
            placeholder="Select a due date..."
            lastStatementDueDateDay={DateTime.fromISO(row.original.lastStatementDueDate ?? '').day}
          />
        ),
      },
      {
        id: 'account',
        Header: () => (
          <MonarchAccountHeader>
            <TooltipHeader
              content={
                <>
                  Accounts you have connected to Monarch.
                  <br />
                  Choose the one that matches your debt account for each row.
                </>
              }
            >
              <TooltipTrigger>
                Monarch Account <InfoIcon />
              </TooltipTrigger>
            </TooltipHeader>
          </MonarchAccountHeader>
        ),
        accessor: 'accountName',
        disableSortBy: true,
        Cell: ({ row }) => (
          <AccountSelectField
            accountName={row.original.spinwheelLiabilityId ?? ''}
            options={accountOptions}
            isLoading={isLoadingAccounts}
            ignoreAccountValue={IGNORE_ACCOUNT_VALUE}
            onAccountCreated={refetchAccountOptions}
          />
        ),
      },
    ],
    [isLoadingAccounts, accountOptions, refetchAccountOptions],
  );

  const [isSubmittingForm, submitForm] = useLoading(onSubmit);

  return (
    <Root>
      <Banner type="info">
        To link accounts in another person&apos;s name, you will need to{' '}
        <StyledRouteLink to={routes.settings.members()}>
          invite additional household members
        </StyledRouteLink>{' '}
        if they aren&apos;t already a part of your household.
      </Banner>
      <Title>Link to your accounts in Monarch</Title>
      <Subtitle>
        Assign each debt account to its corresponding account in Monarch so we can merge their
        details. If you choose not to link a debt account now, you can come back to this flow
        anytime to make changes.{' '}
        <Link href={HELP_CENTER_GETTING_STARTED_WITH_BILL_SYNCING} target="_blank">
          Learn more
        </Link>
        .
      </Subtitle>
      <StyledForm
        // @ts-ignore styled-components doesn't like generics
        onSubmit={submitForm}
        transformValuesBeforeSubmit={(values) => {
          // Split the form map in two different maps, one for Monarch Account and another for Base Date
          const monarchAccountLiabilityMap = R.pipe(
            R.pickBy(
              (val, key) => !key.startsWith(DAY_OF_THE_MONTH_PREFIX), // no need to remove ignored, we will send them as well
            ),
            RA.renameKeysWith(unescapeFieldName),
          )(values) as { [key: string | number]: string };

          const result = R.map(
            (suffix) => ({
              spinwheelLiabilityId: suffix,
              accountId: values[suffix],
              dayOfTheMonth: values[`${DAY_OF_THE_MONTH_PREFIX}${suffix}`].toString(),
            }),
            R.keys(monarchAccountLiabilityMap),
          ); // Filter out invalid keys

          return {
            accountMapping: result,
          };
        }}
        initialValues={initialValues}
      >
        <TableContainer>
          <Table
            columns={columns}
            data={openTableData}
            showFooter={false}
            initialState={TABLE_INITIAL_STATE}
          />
        </TableContainer>

        {closedTableData.length > 0 ? (
          <>
            <ClosedAccountPill onClick={toggleClosedAccounts}>
              <ClosedPillContent>
                <ArrowIcon rotate={isOpenClosedAccount} />
                {closedTableData.length} closed {pluralize('account', closedTableData.length)}
              </ClosedPillContent>
            </ClosedAccountPill>

            <Drawer open={isOpenClosedAccount} shouldAnimateOnMount={false}>
              <ClosedTableContainer>
                <Table
                  columns={columns}
                  data={closedTableData}
                  showFooter={false}
                  initialState={TABLE_INITIAL_STATE}
                />
              </ClosedTableContainer>
            </Drawer>
          </>
        ) : null}

        <StyledSubmitButton
          size="large"
          disableWhenValuesUnchanged={false}
          pending={isSubmittingForm}
        >
          Save and continue
        </StyledSubmitButton>
      </StyledForm>
    </Root>
  );
};

export default MapSpinwheelLiabilitiesAccounts;
