import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import NestedDropdownMenu from 'components/lib/ui/NestedDropdownMenu';
import NestedDropdownSubMenu from 'components/lib/ui/NestedDropdownSubMenu';
import Text from 'components/lib/ui/Text';
import Toggle from 'components/lib/ui/Toggle';

import { setPlanOnboardingUserStatus } from 'common/actions';
import useProfileFlag from 'common/lib/hooks/users/useProfileFlag';
import { spacing } from 'common/lib/theme/dynamic';
import { getPlanOnboardingUserIsNew } from 'common/state/employee/selectors';
import useDispatch from 'lib/hooks/useDispatch';
import useToast from 'lib/hooks/useToast';

const ToggleContainer = styled(FlexContainer).attrs({
  alignCenter: true,
  column: true,
})`
  margin-right: ${spacing.xsmall};
  gap: ${spacing.xxsmall};
  min-width: 50px;
`;

const EmployeeMenuPlan = () => {
  const { openToast } = useToast();

  const [, updateDismissedFlexBudgetingWalkthroughAt] = useProfileFlag(
    'dismissedFlexBudgetingWalkthroughAt',
  );

  const isNewUser = useSelector(getPlanOnboardingUserIsNew);
  const dispatch = useDispatch();
  const updatePlanOnboardingUserIsNew = () => dispatch(setPlanOnboardingUserStatus(!isNewUser));

  return (
    <NestedDropdownSubMenu title="Budget">
      <NestedDropdownMenu
        items={[
          {
            title: 'Reset Feature Walkthrough',
            icon: 'undo',
            onClick: async () => {
              await updateDismissedFlexBudgetingWalkthroughAt(null);
              openToast({
                title: '✅ Walkthrough reset',
                description: 'You should be able to see it again.',
              });
            },
          },
          {
            title: 'Toggle User Status',
            description: 'Toggle between existing and new user. Useful to test the copy changes.',
            right: (
              <ToggleContainer>
                <Toggle checked={isNewUser} onChange={updatePlanOnboardingUserIsNew} />
                <Text color="textLight" weight="bold" size="xsmall">
                  {isNewUser ? 'New' : 'Existing'}
                </Text>
              </ToggleContainer>
            ),
          },
        ]}
      />
    </NestedDropdownSubMenu>
  );
};

export default EmployeeMenuPlan;
