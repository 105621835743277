import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setImmediate } from 'timers';

import { setActAsUser, clearActAsUser } from 'actions';
import { useDispatch } from 'lib/hooks';
import { getActAsUser } from 'state/multiHousehold/selectors';

import routes from 'constants/routes';

import type RouteProps from 'types/RouteProps';

const ActAsUser = ({
  match: {
    params: { id },
  },
}: RouteProps<typeof routes.actAsUser>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const actAsUser = useSelector(getActAsUser);
  useEffect(() => {
    if (id) {
      dispatch(setActAsUser(id));
      history.push(routes.dashboard());
    } else if (actAsUser) {
      dispatch(clearActAsUser());
      // Reload the page to clear any remaining state
      setImmediate(() => window.location.reload());
    } else {
      // We have cleared actAsUser and reloaded, redirect to dashboard
      history.push(routes.dashboard());
    }
  }, []);
  return null;
};

export default ActAsUser;
