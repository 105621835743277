import * as React from 'react';
import styled from 'styled-components';

import InvestmentsBenchmarkHeaderCard from 'components/investments/InvestmentsBenchmarkHeaderCard';
import InvestmentHoldingsBenchmarksHeaderLoading from 'components/investments/InvestmentsHoldingsBenchmarksHeaderLoading';

import { deduplicateBenchmarks } from 'common/lib/investments/benchmarks';
import noop from 'common/utils/noop';
import useTheme from 'lib/hooks/useTheme';

import type { SecurityInterface } from 'common/types/investments';

const Root = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
`;

export const BenchmarkDivider = styled.div`
  background-color: ${({ theme }) => theme.color.grayLight};
  width: 4px;
  height: 86px;
  margin: 0px ${({ theme }) => theme.spacing.gutter};
`;

type Benchmark = {
  name: string;
  dateRangeReturn: number;
  oneDayChangePercent: number | null;
  security: SecurityInterface;
};

type Props = {
  portfolioData: { dateRangeReturn: number; oneDayReturn: number };
  benchmarkData: Benchmark[];
  activeBenchmarkIndex: number;
  setActiveBenchmark: (security: SecurityInterface | null, i: number) => void;
  timeFrameText: string;
  isLoading: boolean | null;
};

const InvestmentHoldingsBenchmarksHeader = ({
  benchmarkData,
  portfolioData,
  activeBenchmarkIndex,
  setActiveBenchmark,
  timeFrameText,
  isLoading,
}: Props) => {
  const theme = useTheme();

  const deduplicatedBenchmarkData = deduplicateBenchmarks(benchmarkData) as Benchmark[];

  return (
    <Root>
      {isLoading ? (
        <InvestmentHoldingsBenchmarksHeaderLoading />
      ) : (
        <>
          <InvestmentsBenchmarkHeaderCard
            title="Your Portfolio"
            dateRangeReturn={portfolioData.dateRangeReturn}
            oneDayChangePercent={portfolioData.oneDayReturn}
            highlightColor={theme.color.orange}
            timeFrameText={timeFrameText}
            onClick={noop}
            rightMargin={false}
          />
          <BenchmarkDivider />
          {deduplicatedBenchmarkData.map(
            ({ name, dateRangeReturn, oneDayChangePercent, security }, i) => (
              <InvestmentsBenchmarkHeaderCard
                key={name}
                title={name}
                rightMargin={i !== deduplicatedBenchmarkData.length - 1}
                dateRangeReturn={dateRangeReturn}
                oneDayChangePercent={oneDayChangePercent}
                highlightColor={i === activeBenchmarkIndex ? theme.color.teal : null}
                timeFrameText={timeFrameText}
                onClick={() => {
                  if (i === activeBenchmarkIndex) {
                    setActiveBenchmark(null, -1);
                  } else {
                    setActiveBenchmark(security, i);
                  }
                }}
                canSelect
              />
            ),
          )}
        </>
      )}
    </Root>
  );
};

export default InvestmentHoldingsBenchmarksHeader;
