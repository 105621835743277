import React from 'react';
import type { ThemeType } from 'styled-components';
import styled, { css } from 'styled-components';

import Tooltip from 'components/lib/ui/Tooltip';

import type { KeyOfThemeProp } from 'common/types/Styles';

const HEIGHT_PX = 8;
export const WIDTH_PX = 150;

const Root = styled.div`
  position: relative;
  height: ${HEIGHT_PX}px;
`;

const stripedStyle = css`
  &:before {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    content: '';
    background-size: 20px 20px;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
  }
`;

export const BackgroundBar = styled.div<{
  $roundAppearance: boolean;
  $backgroundColor: KeyOfThemeProp<'color'>;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme, $roundAppearance }) =>
    $roundAppearance ? `${HEIGHT_PX / 2}px` : theme.radius.xxsmall};
  overflow: hidden;
  background-color: ${({ theme, $backgroundColor }) => theme.color[$backgroundColor]};
`;

type InnerBarProps = {
  color: KeyOfThemeProp<'color'>;
  roundAppearance: boolean;
  size: number;
  animated?: boolean;
  striped?: boolean;
  position?: number;
  $hasRightBorder?: boolean;
  $rightBorderColor?: KeyOfThemeProp<'color'>;
};

const InnerBar = styled.div<InnerBarProps>`
  position: absolute;
  width: ${({ size }) => `${Math.min(1, Math.max(0, size)) * 100}%`};
  left: ${({ position }) => (position ? `${position * 100}%` : 0)};
  top: 0;
  bottom: 0;
  background: ${({ theme, color }) => theme.color[color]};
  border-radius: ${({ roundAppearance }) => (roundAppearance ? `${HEIGHT_PX / 2}px` : '0')};
  transition: ${({ animated }) => (animated ? 'all 0.3s ease-out' : 'none')};
  border-right: ${({ theme, $hasRightBorder, $rightBorderColor }) =>
    $hasRightBorder && $rightBorderColor ? `1px solid ${theme.color[$rightBorderColor]}` : 'none'};

  ${({ striped }) => striped && stripedStyle}
`;

const getMarkColor = (theme: ThemeType, emphasized: boolean) => {
  const isDarkTheme = theme.uiTheme === 'dark';

  if (emphasized) {
    return isDarkTheme ? theme.color.textWhite : theme.color.black;
  }

  return isDarkTheme ? theme.color.textWhite : theme.color.grayDark;
};

const Mark = styled.div<{ $x: number; $outlineTick?: boolean; $emphasized?: boolean }>`
  --color: ${({ theme, $emphasized = false }) => getMarkColor(theme, $emphasized)};
  --padding: ${({ theme }) => theme.spacing.xxsmall};

  left: ${({ $x }) => `${$x * 100}%`};
  box-sizing: content-box;
  position: absolute;
  margin: calc(var(--padding) * -1) calc(0px - var(--padding));
  padding: var(--padding);
  top: 0;
  bottom: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: ${({ $emphasized }) => ($emphasized ? 2 : 0.1)}px;
    border-radius: ${({ theme }) => theme.radius.pill};
    height: 100%;
    background-color: var(--color);
    box-shadow: ${({ theme, $outlineTick }) =>
      $outlineTick ? `0 0 0 1px ${theme.color.white}` : 'none'};
  }
`;

export const ColorIndicator = styled.div<{
  color: KeyOfThemeProp<'color'>;
  striped?: boolean;
}>`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background: ${({ theme, color }) => theme.color[color]};
  ${({ striped }) => striped && stripedStyle}
`;

export type Tick = {
  name?: string;
  percent: number;
};

export type ExtraBar = {
  value: number;
  color: KeyOfThemeProp<'color'>;
  striped?: boolean;
  tooltipContent?: React.ReactNode;
};

export type Props = {
  className?: string;
  value: number;
  color: KeyOfThemeProp<'color'>;
  backgroundColor?: KeyOfThemeProp<'color'>;
  max?: number;
  ticks?: Tick[];
  /** Make all the bars have round ends */
  roundAppearance?: boolean;
  roundInnerBar?: boolean;
  animated?: boolean;
  striped?: boolean;
  extraBar?: ExtraBar;
  tooltipContent?: React.ReactNode;
  backgroundBarTooltipContent?: React.ReactNode;
  outlineTick?: boolean;
  emphasizedTick?: boolean;
  rightBorderColor?: KeyOfThemeProp<'color'>;
};

const ProgressBar = ({
  className,
  value,
  tooltipContent,
  backgroundBarTooltipContent,
  max = 1,
  ticks = [],
  color,
  backgroundColor = 'grayFocus',
  rightBorderColor = 'white',
  roundAppearance = false,
  roundInnerBar = roundAppearance,
  animated,
  striped,
  extraBar,
  outlineTick = false,
  emphasizedTick = false,
}: Props) => (
  <Root className={className}>
    <BackgroundBar $roundAppearance={roundAppearance} $backgroundColor={backgroundColor}>
      {/* First bar */}
      <Tooltip key="first-bar" maxWidth={WIDTH_PX} opacity={1} content={tooltipContent} portal>
        <InnerBar
          size={value / max}
          color={color}
          roundAppearance={roundInnerBar}
          animated={animated}
          $hasRightBorder={max > 0 && value < max}
          $rightBorderColor={rightBorderColor}
        />
      </Tooltip>

      {/* Second bar */}
      {extraBar && (
        <Tooltip
          key="extra-bar"
          portal
          maxWidth={WIDTH_PX}
          opacity={1}
          content={extraBar.tooltipContent}
        >
          <InnerBar
            size={extraBar.value / max}
            color={extraBar.color}
            roundAppearance={roundInnerBar}
            animated={animated}
            striped={extraBar.striped}
            position={value / max}
            $hasRightBorder
            $rightBorderColor={rightBorderColor}
          />
        </Tooltip>
      )}

      {/* Background bar */}
      <Tooltip
        key="second-bar"
        portal
        maxWidth={WIDTH_PX}
        opacity={1}
        content={backgroundBarTooltipContent}
      >
        <InnerBar
          size={max - (value + (extraBar?.value ?? 0)) / max}
          color={backgroundColor}
          roundAppearance={false}
          striped={striped}
          position={(value + (extraBar?.value ?? 0)) / max}
        />
      </Tooltip>
    </BackgroundBar>
    {ticks.map(({ name, percent }, i) => (
      <Tooltip key={i} content={name}>
        <Mark
          $x={percent}
          $outlineTick={outlineTick}
          data-tip={name}
          $emphasized={emphasizedTick}
        />
      </Tooltip>
    ))}
  </Root>
);

export default ProgressBar;
