import { useApolloClient, useMutation, gql } from '@apollo/client';

import type {
  UpdateAccountOrder,
  UpdateAccountOrderVariables,
} from 'common/generated/graphQlTypes/UpdateAccountOrder';
import { Tuple } from 'common/types';

const useUpdateAccountOrderMutation = () => {
  const client = useApolloClient();
  const [performMutation, mutationResult] = useMutation<
    UpdateAccountOrder,
    UpdateAccountOrderVariables
  >(UPDATE_ACCOUNT_ORDER, {
    refetchQueries: ['Web_GetAccountsPage'],
    // We need to ignore the cache update because this was making the accounts ordering to not work properly. When the
    // BE returns and the this mutation updates the accounts cache, two accounts on the same group will have the same
    // value for `Account.order`. To fix this, we ignored the cache update from this mutation and update it
    // manually using `client.writeFragment`, this way we can set a decimal place so that the accounts don't have the
    // same `order`.
    fetchPolicy: 'no-cache',
  });

  const updateAccountOrder = async (
    accountId: string,
    sourcePosition: number,
    destinationPosition: number,
  ) => {
    let optimisticOrder: number = destinationPosition;
    if (destinationPosition > sourcePosition) {
      optimisticOrder += 0.5;
    } else {
      optimisticOrder -= 0.5;
    }

    client.writeFragment({
      id: `Account:${accountId}`,
      fragment: gql`
        fragment AccountOrder on Account {
          id
          order
        }
      `,
      data: {
        __typename: 'Account',
        id: accountId,
        order: optimisticOrder,
      },
    });

    const response = await performMutation({
      variables: { input: { id: accountId, destinationPosition } },
    });
    return response;
  };

  return Tuple(updateAccountOrder, mutationResult);
};

const UPDATE_ACCOUNT_ORDER = gql`
  mutation Web_UpdateAccountOrder($input: UpdateAccountOrderInput!) {
    updateAccountOrder(input: $input) {
      account {
        id
        order
      }
    }
  }
`;

export default useUpdateAccountOrderMutation;
