import { GraphQlMutationError } from 'common/errors';
import type { GraphQLError } from 'graphql';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import type { PayloadErrorFields } from 'common/generated/graphQlTypes/PayloadErrorFields';

interface PayloadErrorWrapper {
  errors: PayloadErrorFields;
}

/** Checks if the given data has an `errors` field in data. */
export const mutationHasErrors = <
  TData extends { errors?: PayloadErrorFields | readonly GraphQLError[] } | undefined | null,
>(
  data: TData,
): data is NonNullable<TData> => {
  if (R.isNil(data)) {
    return false;
  }

  // @ts-ignore - type narrowing is not correct
  const errors = R.map(R.prop('errors'), R.values(data));
  if (errors.some((error) => RA.isNotNilOrEmpty(error))) {
    return true;
  }

  return false;
};

interface PayloadErrorWrapper {
  errors: PayloadErrorFields;
}

export const throwIfHasMutationErrors = <MutationT extends Record<string, any> | undefined | null>(
  data: MutationT,
) => {
  if (R.isNil(data)) {
    return;
  }

  if (mutationHasErrors(data)) {
    const dataValues: PayloadErrorWrapper[] = data ? Object.values(data) : [];
    const errors: PayloadErrorFields[] = R.map(R.prop('errors'), dataValues);
    throw new GraphQlMutationError(errors[0]);
  }
};

/**
 * Throws if the given data has an `errors` field in it, using the `message` field
 * of the first error in the list for simplicity.
 *
 * @param data The mutation data to check for errors.
 */
export const throwIfHasPayloadErrorMessage = <
  MutationT extends Record<string, any> | undefined | null,
>(
  data: MutationT,
) => {
  if (R.isNil(data)) {
    return;
  }

  if (mutationHasErrors(data)) {
    const dataValues: PayloadErrorWrapper[] = data ? Object.values(data) : [];
    const errors: PayloadErrorFields[] = R.map(R.prop('errors'), dataValues);
    if (RA.isString(errors[0].message)) {
      throw new Error(errors[0].message);
    }
  }
};

export const getDisplayErrorsForField = ({
  name,
  touched,
  validationError,
  fieldApiErrors,
  onlyShowApiErrors,
}: {
  name: string;
  touched: boolean;
  validationError: string | undefined;
  fieldApiErrors: { [name: string]: string[] };
  onlyShowApiErrors?: boolean;
}): string[] => {
  let mergedErrors = R.flatten([fieldApiErrors[name] ?? []]);

  if (!onlyShowApiErrors) {
    const validationErrors = R.flatten(validationError ? [validationError] : []);
    mergedErrors = R.pipe(R.concat(validationErrors), R.uniq)(mergedErrors);
  }
  const displayErrors = touched ? mergedErrors : [];

  return displayErrors;
};
