import * as React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import * as COPY from 'common/constants/copy';

type Props = {
  onOkayClick: () => void;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px 64px 0 64px;
`;

const StyledIcon = styled(Icon).attrs({ name: 'credit-card' })`
  width: 48px;
  height: 36px;
  color: ${({ theme }) => theme.color.textLight};
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const BodyText = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const TransactionDrawerNotFoundMessage = ({ onOkayClick }: Props) => (
  <Root>
    <StyledIcon />
    <Text size="large" weight="medium">
      {COPY.TRANSACTIONS.NOT_FOUND.TITLE}
    </Text>
    <BodyText color="textLight" align="center">
      {COPY.TRANSACTIONS.NOT_FOUND.SUBTITLE}
    </BodyText>
    <DefaultButton onClick={onOkayClick}>Okay</DefaultButton>
  </Root>
);

export default TransactionDrawerNotFoundMessage;
