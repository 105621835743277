import { ellipsis } from 'polished';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import Select from 'components/lib/form/Select';
import SelectField from 'components/lib/form/SelectField';
import Emoji from 'components/lib/ui/Emoji';
import FlexContainer from 'components/lib/ui/FlexContainer';
import BudgetRowControls from 'components/plan/onboarding/BudgetRowControls';
import OnboardingAmountCell from 'components/plan/onboarding/OnboardingAmountCell';
import type { BudgetedRowData } from 'components/plan/onboarding/OnboardingBudgetCardRow';
import { CardRow } from 'components/plan/onboarding/OnboardingCard';
import type { FormValues, Field } from 'components/routes/budget/OnboardingNonMonthly';

import { getRolloverSuggestedBudgetAmount } from 'common/lib/budget/Rollovers';
import useSharedConstants from 'common/lib/hooks/useSharedConstants';
import { spacing } from 'common/lib/theme/dynamic';
import { ensureEnumValue } from 'common/utils/Enum';

import { BudgetRolloverFrequency } from 'common/generated/graphql';

const TITLE_CONTAINER_MIN_WIDTH_PX = 250;
const NON_MONTHLY_INPUT_HEIGHT_PX = 36;

export const NonMonthlyCardRow = styled(CardRow).attrs({
  paddingHorizontal: 'large',
  alignCenter: true,
})`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${spacing.gutter};
  grid-template-columns: ${TITLE_CONTAINER_MIN_WIDTH_PX}px 1.5fr 1fr 1fr;
  height: auto;
  padding: ${spacing.small};
  line-height: 100%;
`;

const StyledControls = styled(BudgetRowControls)`
  flex-shrink: 0;
`;

const CategoryTitleContainer = styled(FlexContainer).attrs({
  alignCenter: true,
  justifyBetween: true,
})`
  gap: ${spacing.xxsmall};
  cursor: default;

  ${StyledControls} {
    display: none;
    opacity: 0;
  }

  &:hover ${StyledControls} {
    display: block;
    opacity: 1;
  }
`;

const CategoryTitle = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  line-height: 150%;
  ${ellipsis()}
`;

const SpacelessSelectField = styled(SelectField)`
  && {
    margin-bottom: 0;
  }
`;

const CompactSelect = styled(Select)`
  width: 100%;
  margin-bottom: 0;

  .react-select {
    &__control {
      height: ${NON_MONTHLY_INPUT_HEIGHT_PX}px;
      padding: 0;
    }

    &__value-container {
      padding: 0 ${spacing.xsmall};
    }
  }
`;

type Props = {
  rowData: BudgetedRowData;
  onEditCategory: () => void;
  onDeleteCategory: () => void;
  onHideCategoryFromBudget: () => void;
};

const OnboardingNonMonthlyCardRow = ({
  rowData,
  onEditCategory,
  onDeleteCategory,
  onHideCategoryFromBudget,
}: Props) => {
  const { id, icon, name, rolloverPeriod, isSystemCategory } = rowData;
  const [{ budgetRolloverFrequencies = [] }, { isLoading: isLoadingBudgetRolloverFrequencies }] =
    useSharedConstants();

  const { values, setFieldValue, setFieldTouched } = useFormContext<FormValues>();
  const currentValue = useMemo(() => values[id] as Maybe<Field>, [values, id]);
  const getFieldName = useCallback((field: keyof Field) => `${id}.${field}`, [id]);

  const frequenciesWithoutMonthly = useMemo(
    () =>
      budgetRolloverFrequencies
        // Remove MONTHLY because it's not a valid option for non-monthly (!) categories
        .filter((frequency) => frequency.value !== BudgetRolloverFrequency.MONTHLY)
        .map((frequency) => ({ value: frequency.value, label: frequency.label })),
    [budgetRolloverFrequencies],
  );

  const previousFrequency = useRef(rolloverPeriod?.frequency);
  const previousTargetAmount = useRef(rolloverPeriod?.targetAmount);

  const recalculateMonthlyBudget = useCallback(() => {
    const rolloverFrequency = ensureEnumValue(
      BudgetRolloverFrequency,
      currentValue?.rolloverFrequency,
    );

    if (!rolloverFrequency || !currentValue?.rolloverTargetAmount) {
      return;
    }

    const suggestedMonthlyBudget = getRolloverSuggestedBudgetAmount(
      rolloverFrequency,
      currentValue.rolloverTargetAmount,
    );

    if (
      suggestedMonthlyBudget &&
      suggestedMonthlyBudget > 0 &&
      !Number.isNaN(suggestedMonthlyBudget)
    ) {
      setFieldValue(getFieldName('monthlyBudget'), suggestedMonthlyBudget);
      // Change previous values so we can compare in frequencyOrTargetChanged
      previousFrequency.current = currentValue?.rolloverFrequency;
      previousTargetAmount.current = currentValue?.rolloverTargetAmount;
    }
  }, [currentValue?.rolloverFrequency, currentValue?.rolloverTargetAmount]);

  useEffect(() => {
    // If the frequency or target amount changes recalculate it even if there's already a monthly budget
    if (currentValue?.rolloverFrequency && currentValue?.rolloverTargetAmount) {
      recalculateMonthlyBudget();
    }
  }, [
    currentValue?.rolloverFrequency,
    currentValue?.rolloverTargetAmount,
    recalculateMonthlyBudget,
  ]);

  return (
    <NonMonthlyCardRow $withTopBorder>
      <CategoryTitleContainer>
        <CategoryTitle>
          <Emoji>{icon}</Emoji>
          <span title={name}>{name}</span>
        </CategoryTitle>
        <StyledControls
          isSystemCategory={isSystemCategory}
          onEditCategory={onEditCategory}
          onDeleteCategory={onDeleteCategory}
          onHideFromBudget={onHideCategoryFromBudget}
        />
      </CategoryTitleContainer>
      <SpacelessSelectField
        name={getFieldName('rolloverFrequency')}
        InputComponent={CompactSelect}
        options={frequenciesWithoutMonthly}
        isLoading={isLoadingBudgetRolloverFrequencies}
        isDisabled={isLoadingBudgetRolloverFrequencies}
        hideLabel
      />
      {/* Target amount */}
      <OnboardingAmountCell
        value={currentValue?.rolloverTargetAmount}
        onChange={(value) => {
          setFieldValue(getFieldName('rolloverTargetAmount'), value);
        }}
        height={NON_MONTHLY_INPUT_HEIGHT_PX}
        onBlur={() => {
          setFieldTouched(getFieldName('rolloverTargetAmount'));
          setFieldTouched(getFieldName('rolloverFrequency'));
        }}
        canEdit
      />
      {/* Monthly budget */}
      <OnboardingAmountCell
        itemId={id}
        value={currentValue?.monthlyBudget === 0 ? undefined : currentValue?.monthlyBudget}
        onChange={(value, appliedToFuture) => {
          setFieldValue(getFieldName('monthlyBudget'), value);
          setFieldValue(getFieldName('appliedToFuture'), appliedToFuture);
        }}
        height={NON_MONTHLY_INPUT_HEIGHT_PX}
        onBlur={() => {
          setFieldTouched(getFieldName('monthlyBudget'));
          setFieldTouched(getFieldName('rolloverFrequency'));
        }}
        canEdit
      />
    </NonMonthlyCardRow>
  );
};

export default OnboardingNonMonthlyCardRow;
