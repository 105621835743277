import 'polyfills';
import { Providers } from 'providers';
import React from 'react';
import ReactDOM from 'react-dom';

import App from 'components/App';

import initializeSentry from 'lib/errors/initializeSentry';
import { onUnhandledError } from 'lib/errors/onError';

// Import these styles separately from GlobalStyle to prevent flickering with dev server
// eslint-disable-next-line import/extensions
import 'static/styles/fonts.css';

import * as serviceWorker from './serviceWorker';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Catch unhandled promise rejections
window.addEventListener('unhandledrejection', (event) => {
  const { promise } = event;
  promise.catch(onUnhandledError);
  event.preventDefault();
});

initializeSentry();

const render = (Component: React.ElementType) => {
  ReactDOM.render(
    // @ts-ignore some issue with children prop
    <Providers>
      <Component />
    </Providers>,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./components/App', () => {
    // eslint-disable-next-line
    const NextApp = require('./components/App').default;
    render(NextApp);
  });
}
