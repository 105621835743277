import styled from 'styled-components';

const OnboardingFormContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.xxlarge};
  text-align: left;
  width: 100%;
  max-width: 438px;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    padding: ${({ theme }) => theme.spacing.default} 0 0;
  }
`;

export default OnboardingFormContainer;
