import React from 'react';

import Confirmation from 'components/lib/ui/Confirmation';
import Text from 'components/lib/ui/Text';

type Props = {
  onCancel?: () => void;
  onConfirm?: () => void;
  isLoading?: boolean;
};

const RevokeInviteConfirmation = ({ onCancel, onConfirm, isLoading }: Props) => (
  <Confirmation
    title="Revoke invite"
    confirm="Revoke invite"
    isLoading={isLoading}
    onCancel={onCancel}
    onConfirm={onConfirm}
  >
    <Text>Are you sure you want to revoke this invite? This action cannot be undone.</Text>
  </Confirmation>
);

export default RevokeInviteConfirmation;
