import { useMutation, gql } from '@apollo/client';
import React, { useCallback } from 'react';

import type { FormValues } from 'components/accounts/AddManualAccountForm';
import AddManualAccountForm from 'components/accounts/AddManualAccountForm';
import type { LinkAccountSuccessMetadata } from 'components/accounts/LinkAccountDataProviderModal';
import ModalCard from 'components/lib/ui/ModalCard';

import { throwIfHasMutationErrors } from 'common/lib/form/errors';
import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import type {
  Web_CreateZillowAccount,
  Web_CreateZillowAccountVariables,
} from 'common/generated/graphQlTypes/Web_CreateZillowAccount';
import { DataProviderLegacy } from 'common/generated/graphQlTypes/globalTypes';

type Props = {
  zpid: string;
  addressStreet: string;
  zestimate: number;
  next: (metadata: LinkAccountSuccessMetadata) => void;
  goBack?: () => void;
};

const AddSyncedRealEstateFormModal = ({ zpid, addressStreet, zestimate, next, goBack }: Props) => {
  const [createZillowAccount] = useMutation<
    Web_CreateZillowAccount,
    Web_CreateZillowAccountVariables
  >(CREATE_ZILLOW_ACCOUNT);

  const onSubmit = useCallback(
    async ({ name, displayBalance, subtype, includeInNetWorth }: FormValues) => {
      if (!zpid) {
        throw new Error(`zpid required to create zillow account, received ${zpid}`);
      }

      // The CreateSyncedRealEstateAccount API takes currentBalance not displayBalance
      const { data } = await createZillowAccount({
        variables: {
          input: {
            name,
            currentBalance: displayBalance,
            subtype,
            includeInNetWorth,
            zpid,
          },
        },
      });
      throwIfHasMutationErrors(data);

      const accountId = data?.createSyncedRealEstateAccount?.account?.id;
      next({
        dataProvider: DataProviderLegacy.ZILLOW,
        accountIds: accountId ? [accountId] : [],
      });
    },
    [createZillowAccount, zpid, next],
  );

  return (
    <ModalCard onClickBackButton={goBack}>
      <AddManualAccountForm
        type="real_estate"
        disableCurrentBalance
        initialValues={{
          name: addressStreet ?? '',
          displayBalance: zestimate ?? 0,
          includeInNetWorth: true,
        }}
        onSubmit={onSubmit}
      />
    </ModalCard>
  );
};

const CREATE_ZILLOW_ACCOUNT = gql`
  mutation Web_CreateZillowAccount($input: CreateSyncedRealEstateAccountInput!) {
    createSyncedRealEstateAccount(input: $input) {
      account {
        id
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default AddSyncedRealEstateFormModal;
