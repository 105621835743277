import * as R from 'ramda';
import { useCallback } from 'react';
import type { DropResult } from 'react-beautiful-dnd';

import useDashboardConfig from 'common/lib/hooks/dashboard/useDashboardConfig';
import type { DashboardColumnLayout } from 'common/state/dashboard/types';

const useDashboardDrag = () => {
  const [dashboardConfig, setDashboardConfig] = useDashboardConfig('web');

  const onDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      const { layout } = dashboardConfig;

      if (destination) {
        const sourceColumn = parseInt(source.droppableId, 10);
        const destinationColumn = parseInt(destination.droppableId, 10);
        const widgetName = layout[sourceColumn][source.index];

        // Remove from source column & insert into destination column
        const newLayout = R.pipe(
          R.adjust<DashboardColumnLayout>(sourceColumn, R.remove(source.index, 1)),
          R.adjust(destinationColumn, R.insert(destination.index, widgetName)),
        )(layout);

        setDashboardConfig(
          {
            ...dashboardConfig,
            layout: newLayout,
          },
          true,
        );
      }
    },
    [setDashboardConfig, dashboardConfig],
  );

  return { onDragEnd };
};

export default useDashboardDrag;
