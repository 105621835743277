import * as R from 'ramda';
import { createReducer } from 'typesafe-actions';

import { setOffsetForKey } from 'actions';

const INITIAL_SCROLL_STATE: ScrollState = {
  offsetByKey: {},
};

export type ScrollOffset = { scrollTop: number; scrollLeft: number };

export type ScrollState = {
  offsetByKey: { [key: string]: ScrollOffset };
};

const scroll = createReducer<ScrollState>(INITIAL_SCROLL_STATE).handleAction(
  setOffsetForKey,
  (state, { payload: { key, offset } }) =>
    R.evolve(
      {
        offsetByKey: R.mergeLeft({
          [key]: offset,
        }),
      },
      state,
    ),
);

export default scroll;
