import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

type SortableAccount = {
  isAsset: boolean;
  type: {
    display: string;
    name: string;
  };
  displayName: string;
  order: number;
};

type GroupableAccount = SortableAccount & {
  displayBalance?: number | null;
};

export type Account = SortableAccount &
  GroupableAccount & {
    includeBalanceInNetWorth: boolean;
    deactivatedAt?: string | null;
  };

export type AccountGrouping<A extends GroupableAccount> = [string, A[]];

export const ACCOUNT_TYPE_ORDER = [
  // Assets
  'Cash',
  'Investments',
  'Real Estate',
  'Vehicles',
  'Valuables',

  // Liabilities
  'Credit Cards',
  'Loans',
  'Other',
];

export const includeBalanceInNetWorthFilter = (account: Account) =>
  account.includeBalanceInNetWorth;

export const accountType = ({ type: { display } }: SortableAccount) =>
  ACCOUNT_TYPE_ORDER.includes(display) ? display : 'Other';

export const typeOrder = (type: string) => R.indexOf(type, ACCOUNT_TYPE_ORDER);

export const accountOrder = (account: SortableAccount) => typeOrder(accountType(account));

export const isAsset = R.prop<'isAsset', boolean>('isAsset');

export const standardSort = <A extends SortableAccount>(accounts: A[]): A[] =>
  R.sortWith<A>(
    [
      // Assets should be first
      R.descend(isAsset),
      // Then by custom account type
      R.ascend(accountOrder),
      // Then by account order
      R.ascend(R.prop('order')),
    ],
    accounts,
  );

export const groupByType = <A extends GroupableAccount>(accounts: A[]): AccountGrouping<A>[] =>
  R.toPairs(R.groupBy(accountType, standardSort(accounts)));

export type OverviewData<A> = {
  title: string;
  amount: number;
  accounts: A[];
  type: string;
  isAsset: boolean;
  percentage: number;
};

export const getOverviewDataForAssetTypeDisplay = <A extends Account>(
  accounts: A[],
  accountGroupOrder: string[] | undefined,
  assets: boolean,
) => {
  if (!accounts) {
    return null;
  }

  const filteredAccounts = accounts
    .filter(({ isAsset }) => isAsset === assets)
    .map((account) => ({ ...account, displayType: account.type.display }));

  const totalAmount = R.sum(
    R.map(
      R.propOr(0.0, 'displayBalance'),
      R.filter(R.prop('includeBalanceInNetWorth'), filteredAccounts),
    ) as number[],
  );

  const sortedAccounts = R.sortWith(
    [
      // Sort by account type
      R.ascend(({ displayType }) => {
        const order = R.indexOf(displayType, ACCOUNT_TYPE_ORDER);
        return order === -1 ? Number.MAX_SAFE_INTEGER : order; // Put unknown types at end
      }),
      // Then by account name
      R.ascend(R.prop('order')),
    ],
    filteredAccounts,
  );
  const groupedAccounts = Object.values(R.groupBy(R.prop('displayType'), sortedAccounts));

  const initialValue: OverviewData<A> = {
    title: '',
    type: '',
    amount: 0,
    accounts: [],
    isAsset: true,
    percentage: 0,
  };

  const sections = groupedAccounts.map(
    R.reduce(({ accounts, amount }, account) => {
      const newAmount =
        account.includeBalanceInNetWorth && R.isNil(account.deactivatedAt)
          ? amount + (account.displayBalance ?? 0)
          : amount;

      return {
        title: account.displayType,
        type: account.type.name,
        amount: newAmount,
        isAsset: account.isAsset,
        accounts: [...accounts, account],
        percentage: totalAmount === 0 ? 0 : newAmount / totalAmount,
      };
    }, initialValue),
  );

  if (!accountGroupOrder || R.isEmpty(accountGroupOrder)) {
    return sections;
  }

  return R.sort((first, second) => {
    const firstPosition = accountGroupOrder.indexOf(first.title);
    const secondPosition = accountGroupOrder.indexOf(second.title);
    return firstPosition - secondPosition;
  }, sections);
};

export const isAccountDataGrouped = <
  AccountType extends Account,
  GroupedAccountType extends AccountGrouping<AccountType>,
>(
  data: AccountType[] | GroupedAccountType[],
): data is GroupedAccountType[] => RA.isPair(data[0]);
