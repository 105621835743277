import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setOffsetForKey } from 'actions';
import { store } from 'state/store';

const useRestoreScrollPosition = () => {
  const {
    action,
    location: { key = '' },
  } = useHistory();

  const dispatch = useDispatch();

  // Only update initialScrollOffset once on mount IF action is "POP" (went forward/back using browser buttons)
  const initialScrollOffset = useMemo(() => {
    if (action === 'POP') {
      const restoreScrollPosition = store.getState().scroll.offsetByKey[key];
      return restoreScrollPosition;
    }
    return undefined;
  }, [action]); // eslint-disable-line react-hooks/exhaustive-deps

  const onScroll = useCallback(
    (scrollTop: number, scrollLeft: number) =>
      dispatch(setOffsetForKey({ key, offset: { scrollLeft, scrollTop } })),
    [dispatch, key],
  );

  return [initialScrollOffset, onScroll] as const;
};

export default useRestoreScrollPosition;
