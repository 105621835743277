import NumberFlow from '@number-flow/react';
import React from 'react';

import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';

export type Props = Omit<React.ComponentProps<typeof NumberFlow>, 'locales' | 'format'>;

const AnimatedCurrency = ({ value, ...props }: Props) => (
  <span {...maskClassProps}>
    <NumberFlow
      value={value || 0}
      format={{
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }}
      locales="en-US"
      {...props}
    />
  </span>
);

export default AnimatedCurrency;
