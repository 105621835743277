import { gql, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import React from 'react';

import NestedDropdownMenu from 'components/lib/ui/NestedDropdownMenu';
import NestedDropdownSubMenu from 'components/lib/ui/NestedDropdownSubMenu';

import { toastPromise } from 'lib/ui/toast';

import type {
  RequestEmailReport,
  RequestEmailReportVariables,
} from 'common/generated/graphQlTypes/RequestEmailReport';

const EmployeeMenuEmailReport = () => {
  const months = R.range(0, 12).map((i) => DateTime.local().startOf('month').minus({ months: i }));

  const [requestEmailReport] = useMutation<RequestEmailReport, RequestEmailReportVariables>(
    MUTATION,
  );

  const sendEmail = async (month: DateTime) => {
    const { data } = await requestEmailReport({
      variables: {
        month: month.toISODate(),
      },
    });
    const success = data?.requestEmailReport?.success;

    if (!success) {
      throw new Error();
    }
  };

  return (
    <NestedDropdownSubMenu
      title="Monthly Email Report"
      subtitle="Pick a month to receive a report for:"
    >
      <NestedDropdownMenu
        items={months.map((month) => ({
          title: month.toLocaleString({ month: 'long', year: 'numeric' }),
          onClick: () => {
            toastPromise(sendEmail(month), {
              loading: { title: 'Sending...' },
              success: { title: 'Email sent!' },
              error: { title: 'Something went wrong' },
            });
          },
        }))}
      />
    </NestedDropdownSubMenu>
  );
};

const MUTATION = gql`
  mutation Web_RequestEmailReport($month: Date!) {
    requestEmailReport(month: $month) {
      success
    }
  }
`;

export default EmployeeMenuEmailReport;
