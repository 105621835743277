import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import { GRID_COLUMNS } from 'components/lib/ui/Row';

import type BreakPointSize from 'types/BreakPointSize';

type SpanProps = {
  [key in BreakPointSize]?: number;
};

type StyleProps = {
  spanProps: SpanProps;
  order: number;
};

type Props = {
  /** Determines the ordering of this column when collapsed. */
  order?: number;
  className?: string;
  children: React.ReactNode;
} & SpanProps;

const getMediaQueryCSS = (
  key: BreakPointSize,
  breakPoints: {
    [key in BreakPointSize]: number;
  },
  value?: number,
) => `
@media (min-width: ${breakPoints[key]}px) {
  grid-column-end: span ${value};
  order: 0;
}
`;

const Root = styled.div<StyleProps>`
  order: ${({ order }) => order};
  grid-column-end: span ${(props) => props.spanProps.xs || GRID_COLUMNS};
  ${({ spanProps, theme }) =>
    // TODO: FIX "as" below
    R.values(
      R.mapObjIndexed(
        (value, key) => getMediaQueryCSS(key as BreakPointSize, theme.breakPoints, value),
        spanProps,
      ),
    )}
`;

// Reponsive grid columns. See documentation in Row.tsx.

const Column = ({ xs, sm, md, lg, xl, order, className, children }: Props) => {
  const breakPoints = R.reject(R.isNil, { xs, sm, md, lg, xl });
  return (
    <Root spanProps={breakPoints} className={className} order={order ?? 0}>
      {children}
    </Root>
  );
};

export default Column;
