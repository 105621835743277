import styled, { css } from 'styled-components';

import AbstractButton from 'components/lib/ui/button/AbstractButton';
import AsyncButton from 'components/lib/ui/button/AsyncButton';

import buttonIconMixin from 'lib/styles/buttonIconMixin';
import buttonShadowMixin from 'lib/styles/buttonShadowMixin';
import type { ButtonSize } from 'lib/styles/buttonSizeMixin';
import buttonSizeMixin from 'lib/styles/buttonSizeMixin';

import type { StyleMixin } from 'common/types/Styles';

export const defaultButtonMixin: StyleMixin<{ size?: ButtonSize; disabled?: boolean }> = ({
  theme,
  size = 'small',
  disabled,
}) => `
  background-color: ${disabled ? theme.color.grayBackground : theme.color.white};
  color: ${disabled ? theme.color.textLight : theme.color.text};
  font-weight: ${theme.fontWeight.medium};
  border: 1px solid ${theme.color.grayLight};

  :hover:not(:disabled) {
    border-color: ${theme.color.gray};
    color: ${theme.color.text};
  }

  :active, &.btn-active {
    background-color: ${theme.color.grayBackground};
  }

  ${buttonShadowMixin({ theme })};
  ${buttonIconMixin({ theme, size })};
  ${buttonSizeMixin({ theme, size })};

  ${
    disabled &&
    css`
      opacity: 1;
      box-shadow: none;
    `
  };
`;

const DefaultButton = styled(AbstractButton)<{ size?: ButtonSize }>`
  ${defaultButtonMixin}
`;

export const DefaultAsyncButton = styled(AsyncButton)<{ size?: ButtonSize }>`
  ${defaultButtonMixin}
`;

export default DefaultButton;
