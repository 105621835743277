import React from 'react';
import styled from 'styled-components';

import MarkSquare from 'components/brand/MarkSquare';
import InstitutionIcon from 'components/institutions/InstitutionIcon';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

const SQUARE_SIZE = 48;
const ICON_SIZE = 24;

const Root = styled(FlexContainer).attrs({ alignCenter: true })``;

const ArrowsContainer = styled(FlexContainer).attrs({ alignCenter: true, column: true })`
  margin: 0 ${({ theme }) => theme.spacing.default};
`;

const GrayIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.gray};
`;

type Props = {
  className?: string;
};

const InstitutionConnectionGraphic = ({ className }: Props) => (
  <Root className={className}>
    <MarkSquare size={SQUARE_SIZE} />
    <ArrowsContainer>
      <GrayIcon name="arrow-right" size={ICON_SIZE} />
      <GrayIcon name="arrow-left" size={ICON_SIZE} />
    </ArrowsContainer>
    <InstitutionIcon size={SQUARE_SIZE} />
  </Root>
);

export default InstitutionConnectionGraphic;
