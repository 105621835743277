import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import ContentLoader from 'components/lib/ui/ContentLoader';

const HeaderRoot = styled.div`
  height: 57px;
  width: 100%;
`;

const Root = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  overflow: hidden;
`;

const HoldingRoot = styled.div`
  height: 67px;
  width: 100%;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const HoldingRowsHeader = styled.div`
  height: 40px;
  width: 100%;

  background: ${({ theme }) => theme.color.grayBackground};
`;

const HeaderLoading = () => (
  <HeaderRoot>
    <ContentLoader>
      <rect x="24" y="17" rx="5" ry="5" width="100" height="19" />
      <rect x="330" y="17" rx="5" ry="5" width="60" height="19" />
      <rect x="410" y="17" rx="5" ry="5" width="60" height="19" />
      <rect x="510" y="17" rx="5" ry="5" width="60" height="19" />
      <rect x="calc(89% - 64px)" y="17" rx="5" ry="5" width="11%" height="19" />
    </ContentLoader>
  </HeaderRoot>
);

const HoldingLoading = () => (
  <HoldingRoot>
    <ContentLoader>
      <rect x="20" y="20" rx="5" ry="5" width="48" height="14" />
      <rect x="20" y="40" rx="5" ry="5" width="140" height="10" />
      <rect x="340" y="17" rx="5" ry="5" width="40" height="19" />
      <rect x="420" y="17" rx="5" ry="5" width="40" height="19" />
      <rect x="515" y="17" rx="5" ry="5" width="50" height="19" />
      <rect x="calc(89% - 48px)" y="25" rx="5" ry="5" width="60" height="19" />
      <circle cx="calc(96%)" cy="34" r="12" />
    </ContentLoader>
  </HoldingRoot>
);

const HoldingsGroupLoading = ({ rowsCount }: { rowsCount: number }) => (
  <>
    <HoldingRowsHeader>
      <ContentLoader>
        <rect x="30" y="12" rx="5" ry="5" width="17%" height="16" />
      </ContentLoader>
    </HoldingRowsHeader>
    {R.range(0, rowsCount).map((i) => (
      <HoldingLoading key={i} />
    ))}
  </>
);

export const HoldingsRowsLoading = () => (
  <>
    <HoldingsGroupLoading rowsCount={3} />
    <HoldingsGroupLoading rowsCount={3} />
    <HoldingsGroupLoading rowsCount={6} />
  </>
);

const HoldingsListLoading = () => (
  <Root>
    <HeaderLoading />
    <HoldingsRowsLoading />
  </Root>
);

export default HoldingsListLoading;
