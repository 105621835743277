import React, { useMemo } from 'react';
import type { ReactMarkdownProps } from 'react-markdown';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import ExternalLink from 'components/lib/ui/Link';
import { textButtonMixin } from 'components/lib/ui/TextButton';
import NavLink from 'components/lib/ui/link/NavLink';

const Root = styled(ReactMarkdown)`
  p {
    margin: 0;

    :not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.xlarge};
    }
  }
  a {
    ${textButtonMixin}
    font-size: inherit;
  }
`;

type LinkProps = {
  href: string;
  children: React.ReactNode;
};

const Link =
  (onClick?: (link: string) => void) =>
  ({ href, children }: LinkProps) => {
    const isExternal = href.startsWith('http');
    return isExternal ? (
      <ExternalLink href={href} target="_blank" onClick={() => onClick?.(href)}>
        {children}
      </ExternalLink>
    ) : (
      <NavLink to={href} onClick={() => onClick?.(href)}>
        {children}
      </NavLink>
    );
  };

type Props = ReactMarkdownProps & {
  onClickLink?: (link: string) => void;
};

/** Wrapper around react-markdown that provides our custom styles and onClickLink handler */
const Markdown = ({ onClickLink, source, ...props }: Props) => {
  const renderers = useMemo(
    () => ({
      link: Link(onClickLink),
    }),
    [onClickLink],
  );

  return <Root {...props} renderers={renderers} source={source} />;
};

export default Markdown;
