import { useMutation } from '@apollo/client';
import React from 'react';

import HoldingDrawerBody from 'components/holdings/drawer/HoldingDrawerBody';
import HoldingDrawerMenu from 'components/holdings/drawer/HoldingDrawerMenu';
import SideDrawer from 'components/lib/ui/SideDrawer';

import { DELETE_HOLDING_MUTATION } from 'common/lib/graphQl/investments';

import type {
  DeleteHolding,
  DeleteHoldingVariables,
} from 'common/generated/graphQlTypes/DeleteHolding';
import type { Web_GetHoldingsQueryHoldingAggregate } from 'common/types/investments';

type Props = {
  holdingAggregate?: Web_GetHoldingsQueryHoldingAggregate;
  onClose: () => void;
};

const HoldingDrawerContainer = ({ holdingAggregate, onClose }: Props) => {
  const [deleteHolding] = useMutation<DeleteHolding, DeleteHoldingVariables>(
    DELETE_HOLDING_MUTATION,
    {
      refetchQueries: ['Web_GetHoldings', 'AccountDetails_getAccount'],
      onCompleted: () => {
        onClose();
      },
    },
  );

  const handleDeleteHolding = async () => {
    await deleteHolding({ variables: { id: holding.id } });
  };

  const [holding] = holdingAggregate?.holdings ?? [];

  return (
    <SideDrawer
      extraNode={<HoldingDrawerMenu onDeleteHolding={handleDeleteHolding} />}
      onClose={onClose}
    >
      <HoldingDrawerBody
        holding={holding}
        securityId={holdingAggregate?.security?.id}
        securityName={holdingAggregate?.security?.name ?? holding?.name}
        securityTicker={holdingAggregate?.security?.ticker ?? holding?.ticker}
        securityCurrentPrice={holdingAggregate?.security?.currentPrice ?? holding?.closingPrice}
        isManualHolding={holding.isManual ?? false}
      />
    </SideDrawer>
  );
};

export default HoldingDrawerContainer;
