import * as React from 'react';
import styled from 'styled-components';

import Emoji from 'components/lib/ui/Emoji';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import formatTransactionAmount from 'common/utils/formatTransactionAmount';

import * as COPY from 'common/constants/copy';

import { gql } from 'common/generated/gql';
import type { TransactionDrawerSplitMessageFields } from 'common/generated/graphQlTypes/TransactionDrawerSplitMessageFields';

type Props = {
  data: TransactionDrawerSplitMessageFields[];
  onTransactionPreviewClick: (id: GraphQlUUID) => void;
};

const Root = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px ${({ theme }) => theme.spacing.default};
`;

const SplitIcon = styled(Icon).attrs({ name: 'split' })`
  position: relative;
  color: ${({ theme }) => theme.color.blue};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  width: 48px;
  height: 48px;
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const EllipseText = styled(Text)`
  max-width: 104px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AmountContainer = styled.span`
  justify-self: end;
`;

const TransactionLinkRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: stretch;
  width: 100%;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  border-radius: ${({ theme }) => theme.radius.small};
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.color.blueLight};
  }
`;

const TransactionDrawerSplitMessage = ({ data, onTransactionPreviewClick }: Props) => (
  <Root>
    <SplitIcon />
    <StyledText size="large" weight="medium">
      {COPY.TRANSACTIONS.SPLIT_MESSAGE}
    </StyledText>
    {data.map(
      ({
        id,
        amount,
        merchant: { name: merchantName },
        category: { name: categoryName, icon },
      }) => (
        <TransactionLinkRoot key={id} onClick={() => onTransactionPreviewClick(id)}>
          <EllipseText>{merchantName}</EllipseText>
          <span>
            <Emoji>{icon}</Emoji>
            <Text>{categoryName}</Text>
          </span>
          <AmountContainer>{formatTransactionAmount(amount)}</AmountContainer>
        </TransactionLinkRoot>
      ),
    )}
  </Root>
);

TransactionDrawerSplitMessage.fragments = {
  fields: gql(`
    fragment TransactionDrawerSplitMessageFields on Transaction {
      id
      amount
      merchant {
        id
        name
      }
      category {
        id
        icon
        name
      }
    }
  `),
};

export default TransactionDrawerSplitMessage;
