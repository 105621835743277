import styled from 'styled-components';

import MonarchForAdvisorsLogoUri from 'static/images/monarch-for-advisors-logo.svg';

const LOGO_HEIGHT_PX = 42;

type Props = {
  dark?: boolean;
};

const MonarchForAdvisorsLogo = styled.img.attrs<Props>(({ dark, theme }) => ({
  src: dark && theme.uiTheme !== 'dark' ? MonarchForAdvisorsLogoUri : MonarchForAdvisorsLogoUri,
}))<Props>`
  display: block;
  height: ${LOGO_HEIGHT_PX}px;
`;

export default MonarchForAdvisorsLogo;
