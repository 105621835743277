import { useQuery, useMutation, gql } from '@apollo/client';
import * as R from 'ramda';
import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setDashboardConfig } from 'common/actions';
import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import { getDashboardConfig } from 'common/state/dashboard/selectors';
import type {
  DashboardPlatform,
  DashboardPlatformConfig,
  DashboardConfig,
} from 'common/state/dashboard/types';
import { omitDeep } from 'common/utils/Object';

import type { GetDashboardConfig } from 'common/generated/graphQlTypes/GetDashboardConfig';
import type {
  UpdateDashboardConfig,
  UpdateDashboardConfigVariables,
} from 'common/generated/graphQlTypes/UpdateDashboardConfig';

const deepOmitTypename = omitDeep(['__typename']);

/**
 * Hook used to fetch and update the user's dashboard configuration.
 *
 * The config is fetched using GQL/Apollo but stored in redux so it can be persisted. This
 * prevents us from showing the wrong dashboard layout while the query is loading.
 */
const useDashboardConfig = (platform: DashboardPlatform) => {
  const dispatch = useDispatch();
  const cachedConfig = useSelector(getDashboardConfig);

  const { data } = useQuery<GetDashboardConfig>(QUERY, {
    onCompleted: (data) => {
      const config = data.myHousehold.preferences?.dashboardConfig;
      if (config) {
        const withoutTypename = deepOmitTypename(config);
        dispatch(setDashboardConfig(withoutTypename));
      }
    },
  });
  const { dashboardConfig: fetchedConfigRaw } = data?.myHousehold.preferences ?? {};
  const fetchedConfig = useMemo(
    () => deepOmitTypename(fetchedConfigRaw) as DashboardConfig,
    [fetchedConfigRaw],
  );

  const dashboardConfig = fetchedConfig ?? cachedConfig;
  const platformConfig = dashboardConfig[platform];

  const [updateDashboardConfig] = useMutation<
    UpdateDashboardConfig,
    UpdateDashboardConfigVariables
  >(MUTATION);

  const updatePlatformConfig = useCallback(
    async (config: DashboardPlatformConfig, optimistic = false) => {
      const newConfig = {
        ...deepOmitTypename(dashboardConfig),
        [platform]: config,
      };
      await updateDashboardConfig({
        variables: {
          input: JSON.stringify(newConfig),
        },
        // @ts-ignore : TS doesn't know that data.myHousehold has all of the __typenames and is being merged with
        optimisticResponse:
          data && optimistic
            ? {
                updateDashboardConfig: {
                  __typename: 'UpdateDashboardConfigMutation',
                  household: R.mergeDeepRight(data.myHousehold, {
                    preferences: {
                      dashboardConfig: newConfig,
                    },
                  }),
                },
              }
            : undefined,
      });
      dispatch(setDashboardConfig(newConfig));
    },
    [updateDashboardConfig, dashboardConfig, platform, dispatch, data],
  );

  return [platformConfig, updatePlatformConfig] as const;
};

const DASHBOARD_CONFIG_FRAGMENT = gql`
  fragment DashboardConfig on PlatformDashboardConfig {
    layout
    widgets
  }
`;

const HOUSEHOLD_DASHBOARD_FRAGMENT = gql`
  fragment HouseholdDashboard on Household {
    id
    preferences {
      dashboardConfig {
        web {
          ...DashboardConfig
        }
        mobile {
          ...DashboardConfig
        }
      }
    }
  }
  ${DASHBOARD_CONFIG_FRAGMENT}
`;

const QUERY = gql`
  query GetDashboardConfig {
    myHousehold {
      id
      ...HouseholdDashboard
    }
  }
  ${HOUSEHOLD_DASHBOARD_FRAGMENT}
`;

const MUTATION = gql`
  mutation Common_UpdateDashboardConfig($input: JSONString!) {
    updateDashboardConfig(input: $input) {
      household {
        id
        ...HouseholdDashboard
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${HOUSEHOLD_DASHBOARD_FRAGMENT}
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default useDashboardConfig;
