const getImpersonatorId = () => {
  const data = sessionStorage.getItem('persist:multiHousehold');

  if (!data) {
    return;
  }
  const impersonatorUserId = JSON.parse(data).actAsUser;

  return impersonatorUserId !== 'null' ? JSON.parse(impersonatorUserId) : undefined;
};

export default getImpersonatorId;
