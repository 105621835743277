import React from 'react';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import type { Props as RadioProps } from 'components/lib/form/Radio';
import Radio from 'components/lib/form/Radio';

const RadioField = <ValueT = any,>({
  className,
  ...props
}: AbstractFieldProps<RadioProps<ValueT>>) => (
  <AbstractField {...props} className={className}>
    {({ value, setFieldValue }) => (
      <Radio {...props} value={value} onChange={(value) => setFieldValue(props.name, value)} />
    )}
  </AbstractField>
);

export default RadioField;
