import { useMutation } from '@apollo/client';
import React from 'react';

import PlaidLink from 'components/lib/external/PlaidLink';

import { UPDATE_CREDENTIAL_MUTATION } from 'common/lib/graphQl/credentials';

import type {
  UpdateCredential,
  UpdateCredentialVariables,
} from 'common/generated/graphQlTypes/UpdateCredential';

type Props = {
  token: string;
  credentialId: string;
  receivedRedirectUri?: string;
  onClose?: () => void;
  onSuccess?: () => void;
};

const ReconnectCredentialPlaidLink = ({
  token,
  credentialId,
  receivedRedirectUri,
  onClose,
  onSuccess,
}: Props) => {
  const [updateCredential] = useMutation<UpdateCredential, UpdateCredentialVariables>(
    UPDATE_CREDENTIAL_MUTATION,
  );

  const handleSuccess = () => {
    updateCredential({
      variables: {
        input: { id: credentialId, updateRequired: false },
      },
    });
    onSuccess?.();
  };

  return (
    <PlaidLink
      token={token}
      receivedRedirectUri={receivedRedirectUri}
      reconnectCredentialId={credentialId}
      onSuccess={handleSuccess}
      onError={({ error }) => {
        if (error?.error_code === 'item-no-error') {
          // If the account was fixed on the plaid side this error code will be returned
          // on exit
          handleSuccess();
        } else {
          onClose?.();
        }
      }}
      onClose={onClose}
    />
  );
};

export default ReconnectCredentialPlaidLink;
