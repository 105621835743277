import React from 'react';

import ManualLink from 'components/lib/ui/link/ManualLink';

import { externalUrls } from 'constants/routes';

const TermsAndConditions = () => (
  <>
    By clicking the button above, you agree to our{' '}
    <ManualLink target="_blank" href={externalUrls.termsOfUse}>
      Terms of Use
    </ManualLink>{' '}
    and{' '}
    <ManualLink target="_blank" href={externalUrls.privacyPolicy}>
      Privacy Policy
    </ManualLink>
    .
  </>
);

export default TermsAndConditions;
