import { darken } from 'polished';
import * as React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import typewriter from 'lib/analytics/typewriter';

const ICON_BOX_SIZE_PX = 32;

const IconBox = styled(FlexContainer).attrs({ center: true })<{ $color: string }>`
  width: ${ICON_BOX_SIZE_PX}px;
  height: ${ICON_BOX_SIZE_PX}px;
  border-radius: ${({ theme }) => theme.radius.medium};
  background-color: ${({ $color }) => $color};
  flex-shrink: 0;
`;

const ArrowContainer = styled(FlexContainer).attrs({ justifyEnd: true })`
  transition: all 0.1s ease-in-out;
  color: ${({ theme }) => theme.color.gray};
  flex-grow: 1;
`;

const ShareLinkRoot = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.spacing.xlarge};
  gap: ${({ theme }) => theme.spacing.default};
  color: ${({ theme }) => theme.color.text};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => darken(0.008, theme.color.white)};

    ${ArrowContainer} {
      transform: translateX(3px);
      color: ${({ theme }) => theme.color.textLight};
    }
  }
`;

type Props = {
  color: string;
  href: string;
  title: string;
  icon: React.ReactNode;
  platform: string;
};

const ShareLinkItem = ({ color, href, title, icon, platform }: Props) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    onClick={() => typewriter.referralCodeShared({ method: platform })}
  >
    <ShareLinkRoot>
      <IconBox $color={color}>{icon}</IconBox>
      <Text>{title}</Text>
      <ArrowContainer>
        <FiArrowRight size={24} />
      </ArrowContainer>
    </ShareLinkRoot>
  </a>
);

export default ShareLinkItem;
