import styled from 'styled-components';

import Tab from 'components/lib/ui/tabs/Tab';

import boxShadow from 'common/lib/styles/boxShadow';
import isV2Theme from 'common/lib/theme/isV2Theme';

import type { StyleMixin } from 'common/types/Styles';

const pillTabStyleMixin: StyleMixin = ({ theme }) => `
  --color: ${theme.color.textLight};
  --activeColor: ${theme.color.text};
  --activeBackgroundColor: ${isV2Theme(theme.color.gray5, theme.color.grayBackground)({ theme })};

  border-radius: ${theme.radius.pill};
  padding: ${theme.spacing.xsmall} ${theme.spacing.default};
  font-size: ${theme.fontSize.small};
  line-height: 150%;
  text-align: center;

  transition: ${theme.transition.default};
  :hover {
    background-color: ${theme.color.grayBackground};
  }
  :active {
    background-color: ${theme.color.grayBackground};
    ${boxShadow.inset({ theme })}
  }
`;

const PillTab = styled(Tab).attrs({ underline: false })`
  ${pillTabStyleMixin};
`;

export default PillTab;
