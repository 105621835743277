import React from 'react';
import styled from 'styled-components';

import ReconnectCredentialButton from 'components/accounts/ReconnectCredentialButton';
import Banner from 'components/lib/ui/Banner';
import Grid, { GridItem } from 'components/lib/ui/Grid';
import Icon from 'components/lib/ui/Icon';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { capitalize } from 'common/utils/String';

import type { AccountDetails_GetAccountQuery } from 'common/generated/graphql';

const Root = styled(Banner)`
  padding: 0; /* Reset banner padding */
  margin: ${({ theme }) => theme.spacing.default};
  margin-bottom: ${({ theme }) => `-${theme.spacing.xsmall}`};
`;

const StyledGrid = styled(Grid)`
  --spacing: ${({ theme }) => theme.spacing.xsmall};

  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding: ${({ theme }) => theme.spacing.default};
`;

const IconGridItem = styled(GridItem)`
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  display: inline-grid;
  align-items: center;
  justify-content: start;
`;

type Props = {
  account: NonNullable<AccountDetails_GetAccountQuery['account']>;
};

const AccountDisconnectedBanner = ({ account }: Props) => {
  const institutionName = account.credential?.institution.name || 'this institution';
  return (
    <Root type="error">
      <StyledGrid template={`"icon text button" / 32px 1fr 100px`}>
        <IconGridItem area="icon">
          <Icon name="alert-triangle" />
        </IconGridItem>
        <GridItem area="text">
          {account.credential?.disconnectedFromDataProviderAt
            ? `${capitalize(
                institutionName,
              )} has become disconnected from Monarch due to inactivity. You will need to add a new account to replace this one.`
            : `${capitalize(
                institutionName,
              )} is no longer syncing due to outdated credentials. Update the login settings to resume account syncing.`}
        </GridItem>
        <GridItem area="button" style={{ marginLeft: 'auto' }}>
          {!account.credential?.disconnectedFromDataProviderAt && (
            <ReconnectCredentialButton
              institution={{
                name: account.credential?.institution.name,
                id: account.credential?.institution.id,
              }}
              renderContent={(onClick) => <PrimaryButton onClick={onClick}>Update</PrimaryButton>}
              credential={account.credential as NonNullable<(typeof account)['credential']>}
            />
          )}
        </GridItem>
      </StyledGrid>
    </Root>
  );
};

export default AccountDisconnectedBanner;
