import React from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import { HEADER_HEIGHT } from 'components/routes/Header';

const Root = styled.div`
  width: 0;
  height: 100%;
  position: relative;
`;

const Inner = styled(FlexContainer).attrs({ alignStart: true })`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
`;

const Button = styled(FlexContainer).attrs({ alignCenter: true })`
  color: ${({ theme }) => theme.color.textLight};
  transition: ${({ theme }) => theme.transition.default};
  margin-top: ${({ theme }) => theme.spacing.small};

  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;
      :hover {
        color: ${({ theme }) => theme.color.blue};
      }
    `}
`;

const Label = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;
  letter-spacing: 0.08em;
`;

const SmallIcon = styled(Icon)`
  width: 8px;
  height: 8px;
  margin: 0 ${({ theme }) => theme.spacing.xxsmall};
`;

const LineContainer = styled.div`
  position: relative;
  width: 3px;
  margin: 0 ${({ theme }) => theme.spacing.xlarge};
`;

const Line = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: calc(-100vh + ${HEADER_HEIGHT} + ${({ theme }) => theme.spacing.xxlarge});
  background: ${({ theme }) => theme.color.grayLight};
`;

type Props = {
  onClickLeft?: () => void;
  onClickRight?: () => void;
  className?: string;
};

const PlanHeaderPastSeparator = ({ onClickLeft, onClickRight, className }: Props) => (
  <Root className={className}>
    <Inner>
      <Button onClick={onClickLeft}>
        <SmallIcon name="arrow-left" />
        <Label>Actual</Label>
      </Button>
      <LineContainer>
        <Line />
      </LineContainer>
      <Button onClick={onClickRight}>
        <Label>Budget</Label>
        <SmallIcon name="arrow-right" />
      </Button>
    </Inner>
  </Root>
);

export default React.memo(PlanHeaderPastSeparator);
