import { gql } from '@apollo/client';
import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import AssistantFeedbackModal from 'components/assistant/AssistantFeedbackModal';
import AssistantMessageDebugModal from 'components/assistant/AssistantMessageDebugModal';
import MessageListGroupBase, { AVATAR_SIZE_PX } from 'components/assistant/MessageListGroupBase';
import MarkCircle from 'components/brand/MarkCircle';

import { FeedbackType } from 'common/lib/assistant/constants';
import { getIsEmployee } from 'common/state/user/selectors';
import useModal from 'lib/hooks/useModal';

import type { AssistantMessageListGroupFields } from 'common/generated/graphQlTypes/AssistantMessageListGroupFields';
import type { AssistantStatusMessageListGroupFields } from 'common/generated/graphQlTypes/AssistantStatusMessageListGroupFields';
import type { MessageListGroupFields } from 'common/generated/graphQlTypes/MessageListGroupFields';

type Props = {
  messages: (AssistantMessageListGroupFields | AssistantStatusMessageListGroupFields)[];
  nextGroup?: MessageListGroupFields[];
  demo?: boolean;
  className?: string;
  children?: React.ReactNode;
};

const AssistantMessageListGroup = ({ messages, nextGroup, demo, className, children }: Props) => {
  const message = R.last(messages);
  const { createdAt, name } = message ?? {};

  const [DebugModal, { open: openDebugModal }] = useModal();
  const [FeedbackModal, { open: openFeedbackModal, context: feedbackContext }] = useModal<{
    feedbackType: FeedbackType;
  }>();

  const isEmployee = useSelector(getIsEmployee);

  const actions = [
    {
      icon: 'thumbs-up',
      onClick: () => openFeedbackModal({ feedbackType: FeedbackType.Positive }),
    },
    {
      icon: 'thumbs-down',
      onClick: () => openFeedbackModal({ feedbackType: FeedbackType.Negative }),
    },
    ...(isEmployee
      ? [
          {
            icon: 'info',
            onClick: openDebugModal,
          },
        ]
      : []),
  ];

  return (
    <>
      <MessageListGroupBase
        avatar={<MarkCircle size={AVATAR_SIZE_PX} padding={AVATAR_SIZE_PX / 4} />}
        name={name}
        timestamp={createdAt}
        messages={messages}
        nextGroup={nextGroup}
        demo={demo}
        badge="AI"
        actions={actions}
        className={className}
      >
        {children}
      </MessageListGroupBase>
      <DebugModal large>
        {message && message.__typename === 'AssistantMessage' && (
          <AssistantMessageDebugModal debugInfo={message.debugInfo} />
        )}
      </DebugModal>
      <FeedbackModal>
        {message && feedbackContext && (
          <AssistantFeedbackModal
            messageId={message.id}
            feedbackType={feedbackContext.feedbackType}
          />
        )}
      </FeedbackModal>
    </>
  );
};

AssistantMessageListGroup.fragments = {
  AssistantMessageListGroupFields: gql`
    fragment AssistantMessageListGroupFields on AssistantMessage {
      id
      threadId
      createdAt
      content
      name
      suggestedPrompts
      debugInfo
    }
  `,
  AssistantStatusMessageListGroupFields: gql`
    fragment AssistantStatusMessageListGroupFields on AssistantStatusMessage {
      id
      createdAt
      threadId
      content
      name
    }
  `,
};

export default AssistantMessageListGroup;
