import { DataProviderLegacy } from 'common/generated/graphql';

export const getDisplayName = (dataProvider: DataProviderLegacy): string => {
  if (dataProvider === DataProviderLegacy.MX) {
    return 'MX';
  }

  return dataProvider
    .toLowerCase()
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};
