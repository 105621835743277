import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

type DynamicTheme<T> = {
  [key in keyof T]: T[key] extends Record<string, any>
    ? DynamicTheme<T[key]>
    : (styledArgs: any) => T[key];
};

/**
 * Recursively turns every value in theme to a function that takes { theme } as an argument
 * and returns value from the passed in theme so it's dynamic.
 */
const makeDynamicTheme = <T extends Record<string, any>>(obj: T, path: string[]): DynamicTheme<T> =>
  R.mapObjIndexed(
    (value, key) =>
      RA.isObject(value)
        ? // if this is an object, recurse
          makeDynamicTheme(value, [...path, key])
        : // otherwise if it's a value, turn it into a function that takes
          // theme as an argument (intended for use with styled-components)
          ({ theme }) => R.path([...path, key], theme),
    obj,
  ) as DynamicTheme<T>;

export default makeDynamicTheme;
