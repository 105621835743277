import type { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import type { AccountLogoProps } from 'components/accounts/AccountLogo';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import MerchantLogo from 'components/merchants/MerchantLogo';
import MerchantLink from 'components/recurring/MerchantLink';

const LOGO_SIZE_PX = 40;
const SUB_AVATAR_SIZE_PX = 16;

const ComboAvatar = styled.div`
  padding: ${({ theme }) => theme.spacing.xxsmall};
  position: relative;
`;

const StyledAccountLogo = styled(AccountLogo)`
  outline: 4px solid ${({ theme }) => theme.color.white};
  position: absolute;
  right: 0;
  bottom: 0;
`;

const Root = styled(FlexContainer).attrs({ alignCenter: true })`
  gap: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  id?: string;
  name: string;
  description?: string;
  logoUrl?: string | null;
  logoSize?: number;
  account?: AccountLogoProps | null;
  className?: string;
  startDate?: Maybe<DateTime>;
  isMerchant?: boolean;
};

const RecurringReviewInfoCard = ({
  id,
  name,
  description,
  logoUrl,
  account,
  logoSize = LOGO_SIZE_PX,
  className,
  startDate,
  isMerchant,
}: Props) => {
  const queryParams = startDate ? { date: startDate.startOf('month').toISODate() } : {};

  const LinkComponent = isMerchant ? MerchantLink : React.Fragment;

  if (!id) {
    throw new Error('Entity id is required for link');
  }

  return (
    <Root className={className}>
      <LinkComponent queryParams={queryParams} id={id}>
        <ComboAvatar>
          <MerchantLogo url={logoUrl} size={logoSize} />
          {!!account && (
            <StyledAccountLogo
              logoUrl={account.logoUrl}
              icon={account.icon}
              size={SUB_AVATAR_SIZE_PX}
            />
          )}
        </ComboAvatar>
      </LinkComponent>
      <FlexContainer column>
        <LinkComponent queryParams={queryParams} id={id}>
          <Text weight="medium">{name}</Text>
        </LinkComponent>
        {description && <Text color="textLight">{description}</Text>}
      </FlexContainer>
    </Root>
  );
};

export default RecurringReviewInfoCard;
