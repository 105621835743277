import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import GoalsLoading from 'components/goalsV2/GoalsLoading';
import GoalsSetupHeader from 'components/goalsV2/GoalsSetupHeader';
import MonthlyBudgetsGoalsList from 'components/goalsV2/MonthlyBudgetsGoalsList';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Page from 'components/lib/ui/Page';
import SetupContentHeader from 'components/lib/ui/SetupContentHeader';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import useQuery from 'common/lib/hooks/useQuery';
import { getProgressPercent } from 'lib/goals/progress';
import useModal from 'lib/hooks/useModal';

import * as COPY from 'common/constants/copy';
import { GoalObjective, GOAL_BUDGET_NUM_MONTHS_AVG_SAVINGS } from 'common/constants/goals';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type { Web_GoalsMonthlyBudgets } from 'common/generated/graphQlTypes/Web_GoalsMonthlyBudgets';

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${({ theme }) => theme.color.white};
`;

const StyledSetupContentHeader = styled(SetupContentHeader)`
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

const NextButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 438px;
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
`;

const Container = styled(FlexContainer).attrs({ column: true, center: true })`
  max-width: 502px;
  margin: auto;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

const GoalsMonthlyBudgets = () => {
  const { push } = useHistory();
  const { data, isLoadingInitialData } = useQuery<Web_GoalsMonthlyBudgets>(QUERY, {
    variables: {
      startDate: DateTime.local()
        .startOf('month')
        .minus({ months: GOAL_BUDGET_NUM_MONTHS_AVG_SAVINGS })
        .toISODate(),
      endDate: DateTime.local().startOf('month').toISODate(),
    },
  });

  const [GoalsLoadingModal, { open: openGoalsLoadingModal }] = useModal();

  const hasRetirementGoal = useMemo(
    () => data?.goalsV2.some(({ objective }) => objective === GoalObjective.Retirement),
    [data],
  );

  return (
    <StyledPage name="Goals setup" header={<GoalsSetupHeader progress={getProgressPercent(5)} />}>
      <Container>
        <StyledSetupContentHeader
          title={COPY.GOALS.MONTHLY_BUDGETS.TITLE}
          description={COPY.GOALS.MONTHLY_BUDGETS.SUBTITLE}
        />

        {isLoadingInitialData ? (
          <StyledLoadingSpinner />
        ) : (
          <>
            <MonthlyBudgetsGoalsList data={data} />

            <FlexContainer center column full>
              <NextButton
                size="large"
                onClick={() => {
                  if (hasRetirementGoal) {
                    // go to pre-tax contribution screen
                    push(routes.goalsV2.preTaxBudgets());
                  } else {
                    openGoalsLoadingModal();
                  }
                }}
              >
                Next
              </NextButton>
            </FlexContainer>
          </>
        )}
        <GoalsLoadingModal>
          <GoalsLoading />
        </GoalsLoadingModal>
      </Container>
    </StyledPage>
  );
};

const QUERY = gql(/* GraphQL */ `
  query Web_GoalsMonthlyBudgets($startDate: Date!, $endDate: Date!) {
    goalsV2 {
      id
      plannedMonthlyContribution
      objective
      ...Web_GoalInfoRowFields
    }
    aggregates(filters: { startDate: $startDate, endDate: $endDate }) {
      summary {
        savings
      }
    }
  }
`);

export default GoalsMonthlyBudgets;
