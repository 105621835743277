import type { Color, StyleMixin } from 'common/types/Styles';

/* Mixin to apply the correct color to the amount, if it should be green. */
export const amountMixin: StyleMixin<{
  isGreen: boolean | undefined;
  defaultColor: Color;
}> = ({ theme: { uiTheme, color }, isGreen, defaultColor }) =>
  `color: ${(() => {
    if (!isGreen) {
      return color[defaultColor];
    }

    return uiTheme === 'dark' ? color.green : color.greenText;
  })()}`;
