export enum BudgetRolloverPeriodType {
  Monthly = 'monthly',
  NonMonthly = 'non_monthly',
  OneTime = 'one_time',
}

export const ROLLOVER_ENABLED_FIELD_NAME = 'rolloverEnabled';

export const ROLLOVER_APPLY_TO_FUTURE_MONTHS_FIELD_NAME = 'applyRolloverBudgetToFutureMonths';

// Disable since is not working on mobile and it was messing up with the refresh logic
export const IS_OPTIMISTIC_CACHE_UPDATE_ENABLED = false;
