import { Buffer } from 'buffer';
import * as RA from 'ramda-adjunct';
import { useCallback } from 'react';
import uuid from 'uuid/v4';

import { setOAuthStateString } from 'actions';
import typewriter from 'lib/analytics/typewriter';
import { getCoinbaseOAuthRedirectUri } from 'lib/external/coinbase';
import getStringEnvVar from 'lib/getStringEnvVar';
import { useQueryParam, useDispatch } from 'lib/hooks';

import { COINBASE_SCOPES } from 'common/constants/coinbase';
import { getCoinbaseOAuthURL } from 'common/constants/externalUrls';

type OpenCoinbaseOptions = {
  credentialId?: string;
  onSuccessRedirectTo?: string;
  withParams?: Record<string, string>;
};

export type CoinbaseOAuthFlowState = {
  uuid: string;
  credentialId?: string;
  onSuccessRedirectTo?: string;
  withParams?: Record<string, string>;
};

const useCoinbaseOAuthFlow = () => {
  const dispatch = useDispatch();
  const returnedCode = useQueryParam('code');

  const startCoinbaseOAuthFlow = useCallback(
    async (options?: OpenCoinbaseOptions) => {
      const { credentialId, onSuccessRedirectTo, withParams } = options || {};
      const state: CoinbaseOAuthFlowState = {
        uuid: uuid(),
        credentialId,
        onSuccessRedirectTo,
        withParams,
      };
      const stateString = Buffer.from(JSON.stringify(state)).toString('base64');

      await dispatch(setOAuthStateString(stateString));
      typewriter.coinbaseConnectOpened();

      window.location.href = getCoinbaseOAuthURL(
        getStringEnvVar('REACT_APP_COINBASE_CLIENT_ID'),
        getCoinbaseOAuthRedirectUri(),
        COINBASE_SCOPES,
        stateString,
      );
    },
    [dispatch],
  );

  return { startCoinbaseOAuthFlow, hasCode: RA.isNotNil(returnedCode) };
};

export default useCoinbaseOAuthFlow;
