import { useMutation, gql } from '@apollo/client';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCancelButton from 'components/lib/ui/ModalCancelButton';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import ManualLink from 'components/lib/ui/link/ManualLink';
import RecoveryCodesMfaBox from 'components/settings/security/RecoveryCodesMfaBox';

import useToggle from 'common/lib/hooks/useToggle';

import { externalUrls } from 'constants/routes';

import type { createRecoveryCode } from 'common/generated/graphQlTypes/createRecoveryCode';

const StyledModalCard = styled(ModalCard)`
  width: 570px;
`;

const Root = styled(FlexContainer)``;

const Body = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const StyledLink = styled(ManualLink)`
  font-weight: ${({ theme }) => theme.fontWeight.book};
`;

const KeeperLink = () => (
  <StyledLink href={externalUrls.keeper} target="_blank">
    Keeper
  </StyledLink>
);

const OnePassLink = () => (
  <StyledLink href={externalUrls.onePass} target="_blank">
    1Password
  </StyledLink>
);

const LastPassLink = () => (
  <StyledLink href={externalUrls.lastPass} target="_blank">
    LastPass
  </StyledLink>
);

const DISABLED_BUTTON_TOOLTIP_TEXT =
  'Download, print, or copy your recovery codes before continuing.';

const NextButton = ({ allowNext, next }: { allowNext: boolean; next: () => void }) => (
  <Tooltip content={!allowNext && DISABLED_BUTTON_TOOLTIP_TEXT}>
    <div>
      {/* Tooltip won't show without div */}
      <PrimaryButton onClick={next} disabled={!allowNext}>
        Next
      </PrimaryButton>
    </div>
  </Tooltip>
);

const INFO =
  'Recovery codes are used to access your account in the event you cannot receive two-factor authentication codes.';

const INFO_2 =
  'Treat your recovery codes with the same level of attention as you would your password. We ' +
  'recommend saving them with a password manager such as';

type Props = {
  next?: () => void;
  onDone?: () => void;
};

// Modal can either be popped up during the enable flow or just to review recovery codes
const SaveRecoveryCodesMfaModal = ({ next, onDone }: Props) => {
  const [allowNext, { setOn }] = useToggle(false);
  const [createRecoveryCodes, { data, loading }] = useMutation<createRecoveryCode>(MUTATION);

  useEffect(() => {
    createRecoveryCodes();
  }, []);

  const { staticTokens = [] } = data?.createRecoveryCodes ?? {};
  // @ts-ignore
  const tokenArray = R.map(R.prop('token'), staticTokens) as [string];

  return (
    <StyledModalCard
      title={onDone ? 'Recovery codes' : 'Save your recovery codes'}
      description={INFO}
    >
      <Root column margin="large">
        <RecoveryCodesMfaBox allowNext={setOn} tokenArray={tokenArray} loading={loading} />
        <Body>
          {INFO_2} <LastPassLink />, <OnePassLink />, or <KeeperLink />.
        </Body>
      </Root>
      <CardFooter>
        {next && (
          <>
            <ModalCancelButton />
            <NextButton allowNext={allowNext} next={next} />
          </>
        )}
        {onDone && <PrimaryButton onClick={onDone}>Done</PrimaryButton>}
      </CardFooter>
    </StyledModalCard>
  );
};

const MUTATION = gql`
  mutation Web_createRecoveryCode {
    createRecoveryCodes {
      staticTokens {
        id
        token
      }
    }
  }
`;

export default SaveRecoveryCodesMfaModal;
