import { DateTime } from 'luxon';
import { prop, sortBy } from 'ramda';
import { compact } from 'ramda-adjunct';
import { useMemo } from 'react';

import type { FeatureFlags } from 'common/lib/external/splitio';
import useEventCallback from 'common/lib/hooks/useEventCallback';
import useProfileFlag from 'common/lib/hooks/users/useProfileFlag';
import { useFeatureFlags } from 'lib/hooks/useFeatureFlag';
import type { FeatureFlagName } from 'state/features/types';

import routes from 'constants/routes';

const WHATS_NEW_ITEMS: WhatsNewItem[] = [
  {
    feature: 'flex-budgeting',
    description: 'Introducing Flex Budgeting',
    featureFlag: 'fixed-flex-budgeting',
    startDate: '2024-10-02',
    route: routes.plan(),
    callToAction: 'Check it out',
  },
  {
    feature: 'bill-tracking',
    description: 'Bill syncing and recurring merchant review.',
    featureFlag: null,
    startDate: '2024-10-29',
    route: routes.recurring(),
    callToAction: 'Check it out',
  },
];

const FLAG_NAMES = compact(WHATS_NEW_ITEMS.map(prop('featureFlag')));

export type WhatsNewItem = {
  feature: string;
  description: string;
  featureFlag: FeatureFlagName | null;
  startDate: string;
  route: string;
  callToAction: string;
};

export const useWhatsNew = () => {
  const featureFlags = useFeatureFlags(FLAG_NAMES);
  const [dismissedAt, updateDismissedAt, isLoading] = useProfileFlag('hasDismissedWhatsNewAt');

  const whatsNew = useMemo(
    () => (isLoading ? null : getWhatsNew(dismissedAt, featureFlags)),
    [dismissedAt, featureFlags, isLoading],
  );

  const dismissWhatsNew = useEventCallback(() => updateDismissedAt(DateTime.now().toISO()));

  return {
    whatsNew,
    dismissWhatsNew,
  };
};

const getWhatsNew = (dismissedAt: Maybe<string>, featureFlags: FeatureFlags) => {
  const activeItems = getActiveItems(dismissedAt, featureFlags);
  const sortedItems = sortBy(prop('startDate'), activeItems);
  return sortedItems.length > 0 ? sortedItems[0] : null;
};

const getActiveItems = (dismissedAtISO: Maybe<string>, featureFlags: FeatureFlags) =>
  WHATS_NEW_ITEMS.filter(({ featureFlag, startDate: startDateISO }) => {
    const now = DateTime.now();
    const startDate = DateTime.fromISO(startDateISO);
    const dismissedAt = dismissedAtISO ? DateTime.fromISO(dismissedAtISO) : null;

    // Check if the current item was already seen
    const seen = !!dismissedAt && dismissedAt > startDate;
    // Check if there was a dismissal in the last day
    const recentDismissals = !!dismissedAt && now.diff(dismissedAt).as('days') <= 1;
    // Check if the current item is active
    const isActive = now >= startDate;
    // Check if the current item is enabled
    const isEnabled = !featureFlag || featureFlags[featureFlag]?.treatment === 'on';

    return isActive && isEnabled && !seen && !recentDismissals;
  });
