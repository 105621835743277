import { gql } from '@apollo/client';
import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import TransactionMessageBlock from 'components/assistant/blocks/TransactionMessageBlock';
import FlexContainer from 'components/lib/ui/FlexContainer';
import TransactionDrawer from 'components/transactions/drawer/TransactionDrawer';

import { TransactionsBlock } from 'common/lib/assistant/blocks';
import { makeBlockRenderer } from 'common/lib/assistant/blocks/render';
import useQuery from 'common/lib/hooks/useQuery';

import type {
  Web_TransactionsMessageBlock,
  Web_TransactionsMessageBlockVariables,
} from 'common/generated/graphQlTypes/Web_TransactionsMessageBlock';

const Root = styled(FlexContainer)`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const TransactionsBlockRenderer = makeBlockRenderer(TransactionsBlock)(({ data: { ids } }) => {
  const { data, isLoadingInitialData } = useQuery<
    Web_TransactionsMessageBlock,
    Web_TransactionsMessageBlockVariables
  >(QUERY, {
    variables: { ids },
  });
  const { allTransactions } = data ?? {};
  const { results: transactions = [] } = allTransactions ?? {};
  const transactionsById = useMemo(() => R.indexBy(R.prop('id'), transactions), [transactions]);

  const [drawerId, setDrawerId] = useState<string>();

  return (
    <Root column>
      {ids.map((id) => {
        const transaction = transactionsById[id];
        const { merchant, category, amount } = transaction ?? {};

        if (!transaction && !isLoadingInitialData) {
          // this transaction is missing, so don't show anything
          return null;
        }

        return (
          <TransactionMessageBlock
            key={id}
            id={id}
            name={merchant?.name}
            category={`${category?.icon} ${category?.name}`}
            amount={amount}
            loading={!transaction}
            onClickChevron={() => setDrawerId(id)}
          />
        );
      })}
      {!!drawerId && (
        <TransactionDrawer
          id={drawerId}
          previousTransactionId={null}
          nextTransactionId={null}
          setId={setDrawerId}
          onClose={() => setDrawerId(undefined)}
        />
      )}
    </Root>
  );
});

const QUERY = gql`
  query Web_TransactionsMessageBlock($ids: [ID!]!) {
    allTransactions(filters: { ids: $ids }) {
      results {
        id
        amount
        merchant {
          id
          name
        }
        category {
          id
          name
          icon
        }
      }
    }
  }
`;

export default TransactionsBlockRenderer;
