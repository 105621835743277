import type { DetailedAPIError } from 'common/errors';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import type { ValidationError } from 'yup';

import { useAdvisorOnboardingFlowState } from 'components/advisorOnboarding/context/AdvisorOnboardingProvider';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import PasswordValidationGroup, {
  MergedPasswordSchema,
} from 'components/onboarding/PasswordValidationGroup';

import { login } from 'actions';
import getUserApi from 'common/lib/api/user';
import api from 'lib/api';
import { useDispatch } from 'lib/hooks';
import useToast from 'lib/hooks/useToast';
import useUserAttributionData from 'lib/hooks/useUserAttributionData';
import { onOpenReportEmail } from 'lib/support';
import { fetchUserAction, updateUserAction } from 'state/user/thunks';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

import { ErrorCode } from 'common/generated/graphql';

const StyledOnboardingTitle = styled(OnboardingTitle)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: 100px;
  }
`;

const AdvisorOnboardingSetupAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userAttributionData = useUserAttributionData();

  const { next, state } = useAdvisorOnboardingFlowState();
  const { email = '', code = '', isFederatedAuth } = state;

  const onSubmit = useCallback(
    async (values: { name: string; password: string }) => {
      if (isFederatedAuth) {
        await dispatch(updateUserAction({ name: values.name }));
        await dispatch(fetchUserAction());
      } else {
        const response = await getUserApi(api).verifyUser(
          email,
          code,
          values.name,
          values.password,
          undefined,
          userAttributionData,
        );
        dispatch(login({ signedUpAndVerified: true, ...response }));
      }

      next({});
    },
    [userAttributionData, email, code, dispatch, next, isFederatedAuth],
  );

  const { openErrorToast } = useToast();

  return (
    <>
      <StyledOnboardingTitle>{COPY.ADVISOR_ONBOARDING.SETUP_ACCOUNT.TITLE}</StyledOnboardingTitle>
      <OnboardingSubtitle>
        {isFederatedAuth
          ? COPY.ADVISOR_ONBOARDING.SETUP_ACCOUNT.SUBTITLE_WITH_SSO
          : COPY.ADVISOR_ONBOARDING.SETUP_ACCOUNT.SUBTITLE_WITHOUT_SSO}
      </OnboardingSubtitle>
      <OnboardingFormContainer>
        <Form
          initialValues={{ name: '', password: '' }}
          onSubmit={onSubmit}
          validate={(values) => {
            const errors: Partial<typeof values> = {};

            if (!isFederatedAuth) {
              if (!values.password) {
                errors.password = 'This field is required.';
              } else {
                try {
                  MergedPasswordSchema.validateSync(values.password);
                } catch (error) {
                  errors.password = (error as ValidationError).errors.join(' ');
                }
              }
            }

            if (!values.name) {
              errors.name = 'This field is required.';
            }

            return errors;
          }}
          onError={(e: DetailedAPIError) => {
            const isAccountAlreadyExistsError =
              e.data.error_code === ErrorCode.USER_ALREADY_SIGNED_UP;

            if (isAccountAlreadyExistsError) {
              openErrorToast({
                title: COPY.ADVISOR_ONBOARDING.ERRORS.ALREADY_SIGNED_UP_TITLE,
                description: COPY.ADVISOR_ONBOARDING.ERRORS.ALREADY_SIGNED_UP_SUBTITLE,
                duration: Infinity,
                actions: [
                  {
                    text: 'Sign In',
                    onClick: () => history.push(routes.login()),
                  },
                ],
                hideReportButton: true,
              });
            } else {
              openErrorToast({
                hideReportButton: true,
                description: COPY.ADVISOR_ONBOARDING.ERRORS.GENERAL_ERROR,
                actions: [
                  {
                    text: 'Contact',
                    onClick: () => {
                      onOpenReportEmail('Issue signing up');
                    },
                  },
                ],
              });
            }
          }}
        >
          <TextField
            name="name"
            placeholder={COPY.ADVISOR_ONBOARDING.SETUP_ACCOUNT.PLACEHOLDER_NAME}
            autoComplete="name"
            autoFocusOnMobile={false}
            autoFocus
            required
          />
          {!isFederatedAuth && (
            <>
              <TextField
                name="password"
                placeholder={COPY.ADVISOR_ONBOARDING.SETUP_ACCOUNT.PLACEHOLDER_PASSWORD}
                autoComplete="new-password"
                onlyShowApiErrors
                password
                required
              />
              <PasswordValidationGroup />
            </>
          )}
          <FormSubmitButton size="medium">
            {COPY.ADVISOR_ONBOARDING.SETUP_ACCOUNT.SUBMIT_BUTTON}
          </FormSubmitButton>
        </Form>
      </OnboardingFormContainer>
    </>
  );
};

export default AdvisorOnboardingSetupAccount;
