import { gql } from '@apollo/client';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import GoalSelectAccountsModal from 'components/goalsV2/GoalSelectAccountsModal';
import Card from 'components/lib/ui/Card';
import Empty from 'components/lib/ui/Empty';
import Link from 'components/lib/ui/Link';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import TimeframeTrendIndicator, { TimeframeText } from 'components/lib/ui/TimeframeTrendIndicator';
import NavLink from 'components/lib/ui/link/NavLink';
import GoalAccountBalanceRow from 'components/routes/goalsV2/GoalAccountBalanceRow';

import {
  getIncompleteGoalSetupLabel,
  invertAmountIfDebtGoal,
  sumCurrentMonthChangesForAllocations,
} from 'common/lib/goalsV2/adapters';
import useModal from 'lib/hooks/useModal';

import { GOALS } from 'common/constants/copy';
import { GoalType } from 'common/constants/goals';
import routes from 'constants/routes';

import type { WebGoalBalancesCardFieldsFragment } from 'common/generated/graphql';

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: ${({ theme }) => theme.spacing.xlarge};
  align-self: center;
`;

const StyledTimeframeTrendIndicator = styled(TimeframeTrendIndicator)`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${TimeframeText} {
    text-transform: none;
  }
`;

const StyledGoalAccountBalanceRow = styled(GoalAccountBalanceRow)<{ $topBorder: boolean }>`
  ${({ $topBorder }) =>
    $topBorder &&
    css`
      border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
    `}
`;

type Props = {
  goal: WebGoalBalancesCardFieldsFragment | null | undefined;
  isLoading: boolean;
  large?: boolean;
};

const GoalBalancesCard = ({ goal, isLoading, large }: Props) => {
  const { accountAllocations, defaultName, type = '', id: goalId = '' } = goal ?? {};

  const sumAllocationChange = useMemo(
    () => sumCurrentMonthChangesForAllocations(accountAllocations ?? []),
    [accountAllocations],
  );

  const [SelectAccountsModal, { open: openSelectAccountsModal, close: closeSelectAccountsModal }] =
    useModal();

  return (
    <>
      <Card
        title="Balances"
        controls={
          !isLoading && accountAllocations?.length ? (
            <StyledTimeframeTrendIndicator
              startAmount={0}
              endAmount={invertAmountIfDebtGoal(sumAllocationChange, type)}
              timeframeDisplay="this month"
              isAsset={type !== GoalType.Debt}
            />
          ) : undefined
        }
      >
        <Switch>
          <Case when={isLoading}>
            <StyledLoadingSpinner />
          </Case>
          <Case when={!accountAllocations?.length}>
            <Empty
              title={getIncompleteGoalSetupLabel(defaultName || '')}
              subtitle={GoalType.Debt ? GOALS.BALANCES.EMPTY.DEBT : GOALS.BALANCES.EMPTY.ASSET}
              button={{ title: 'Edit accounts', onClick: openSelectAccountsModal }}
            />
          </Case>
          <Case default>
            {accountAllocations?.map((data, i) => (
              <Link
                key={data.id}
                as={NavLink}
                to={routes.accounts.accountDetails({ id: data.account.id })}
                unstyled
              >
                <StyledGoalAccountBalanceRow
                  data={data}
                  goalType={type}
                  $topBorder={i !== 0}
                  large={large}
                />
              </Link>
            ))}
          </Case>
        </Switch>
      </Card>
      <SelectAccountsModal>
        <GoalSelectAccountsModal goalId={goalId} onClickDone={closeSelectAccountsModal} />
      </SelectAccountsModal>
    </>
  );
};

GoalBalancesCard.fragments = {
  WebGoalBalancesCardFields: gql`
    fragment WebGoalBalancesCardFields on GoalV2 {
      id
      type
      defaultName
      accountAllocations {
        id
        ...WebGoalAccountBalanceRowFields
      }
    }
    ${GoalAccountBalanceRow.fragments.WebGoalAccountBalanceRowFields}
  `,
};

export default GoalBalancesCard;
