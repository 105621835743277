import * as React from 'react';
import styled, { css } from 'styled-components';

import Icon from 'components/lib/ui/Icon';
import type { AbstractButtonProps } from 'components/lib/ui/button/AbstractButton';
import AbstractButton from 'components/lib/ui/button/AbstractButton';

type Props = {
  widthPx?: number;
  heightPx?: number;
} & Omit<AbstractButtonProps, 'children'>;

const XIcon = styled(Icon).attrs({ name: 'X' })<{ widthPx?: number; heightPx?: number }>`
  ${({ widthPx }) =>
    widthPx &&
    css`
      width: ${widthPx}px;
    `}
  ${({ heightPx }) =>
    heightPx &&
    css`
      height: ${heightPx}px;
    `}
`;

const TextButton = ({ widthPx, heightPx, ...props }: Props) => (
  <AbstractButton {...props}>
    <XIcon widthPx={widthPx} heightPx={heightPx} />
  </AbstractButton>
);

export default TextButton;
