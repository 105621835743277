import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import TabNavLink from 'components/lib/ui/link/TabNavLink';

import { spacing, fontSize } from 'common/lib/theme/dynamic';

import routes from 'constants/routes';

const Root = styled(FlexContainer).attrs({ alignStretch: true })`
  margin-left: ${spacing.xxlarge};
`;

const StyledLink = styled(TabNavLink)`
  margin-right: ${spacing.xlarge};
  font-size: ${fontSize.base};
  align-items: center;
  display: flex;
`;

const ReportsHeaderTabs = () => (
  <Root>
    <StyledLink to={routes.reports.cashFlow.path}>Cash Flow</StyledLink>
    <StyledLink to={routes.reports.spending.path}>Spending</StyledLink>
    <StyledLink to={routes.reports.income.path}>Income</StyledLink>
    <StyledLink to={routes.reports.sankey.path}>Sankey</StyledLink>
  </Root>
);

export default ReportsHeaderTabs;
