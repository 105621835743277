import { ellipsis } from 'polished';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

const BANNER_HEIGHT_PX = 40;

const Root = styled.div<{ $variant: string }>`
  width: 100%;
  align-items: center;
  justify-content: center;

  height: ${BANNER_HEIGHT_PX}px;
  line-height: ${BANNER_HEIGHT_PX}px;
  padding: 0 ${({ theme }) => theme.spacing.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
  ${R.omit(['display'], ellipsis())}

  ${({ theme, $variant }) =>
    $variant === 'status'
      ? `
      display: flex;
      background-color: ${theme.color.yellowFocus};
      color: ${theme.color.yellowText};
      
      a:link, a:visited, a:hover, a:active {
        color: ${theme.color.yellowText};
        text-decoration: underline;
      }
    `
      : `
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      background-color: ${theme.color.yellow};
      color: ${theme.color.text};
    `}

  @media (max-width: ${({ theme }) => theme.breakPoints.sm - 1}px) {
    font-size: ${({ theme }) => theme.fontSize.xsmall};
    display: flex;
  }
`;

type Props = {
  children: React.ReactNode;
  variant?: 'demo' | 'status';
};

const WindowBanner = ({ children, variant = 'demo' }: Props) => (
  <Root $variant={variant}>{children}</Root>
);

export default WindowBanner;
