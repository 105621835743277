import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { formatCurrencyNoCents } from 'common/utils/Currency';

type Props = {
  zpid: string;
  addressStreet: string;
  addressCity: string;
  addressState: string;
  addressPostalCode: string;
  zestimate: number;
  onClick: ({
    addressStreet,
    zestimate,
    zpid,
  }: {
    addressStreet: string;
    zestimate: number;
    zpid: string;
  }) => void;
};

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xlarge};
  cursor: pointer;

  :last-child {
    border-radius: 0px 0px ${({ theme }) => theme.radius.medium}
      ${({ theme }) => theme.radius.medium};
  }
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.grayBackground};
  }
`;

const AddressText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;
const ZillowPropertySearchResultListItem = ({
  addressStreet,
  addressCity,
  addressState,
  addressPostalCode,
  zestimate,
  zpid,
  onClick,
}: Props) => (
  <Root onClick={() => onClick({ zpid, zestimate, addressStreet })}>
    <FlexContainer column>
      <AddressText>{addressStreet}</AddressText>
      <Text>{`${addressCity}, ${addressState} ${addressPostalCode}`}</Text>
    </FlexContainer>
    <Text>{formatCurrencyNoCents(zestimate)}</Text>
  </Root>
);

export default ZillowPropertySearchResultListItem;
