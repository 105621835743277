import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';

/**
 * Icon component for use in buttons that have an icon next to text.
 */
const ButtonIcon = styled(Icon)`
  :first-child {
    margin-right: ${({ theme }) => theme.spacing.xsmall};
  }
  :last-child {
    margin-left: ${({ theme }) => theme.spacing.xsmall};
  }
`;

export default ButtonIcon;
