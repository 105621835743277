import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const InvisibleLink = styled.a`
  opacity: 0;
  position: absolute;
  height: 0;
`;

type Props = {
  downloadUrl?: string;
};

/**
 *
 * This component is used to trigger the download of CSV transactions.
 * It is used in the TransactionsSummaryCard component.
 *
 * This is necessary because if we open a link on a new window/tab without user interaction,
 * the browser will block it.
 *
 * If necessary, we can make it more generic and use it in other places.
 */

const TransactionsDownloadInvisibleButton = ({ downloadUrl }: Props) => {
  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);
  useEffect(() => {
    if (downloadUrl) {
      downloadLinkRef.current?.click();
    }
  }, [downloadUrl]);

  return (
    <InvisibleLink ref={downloadLinkRef} href={downloadUrl}>
      Download CSV
    </InvisibleLink>
  );
};

export default TransactionsDownloadInvisibleButton;
