import React from 'react';
import styled from 'styled-components';

import { defaultButtonMixin } from 'components/lib/ui/button/DefaultButton';

import type { BlockComponentProps } from 'common/types/support';

const LinkButton = styled.a`
  ${defaultButtonMixin}

  text-align: center;
  border-radius: ${({ theme }) => theme.radius.small};
`;

const ResolutionButtonBlock = ({
  data,
  onClickEvent,
}: BlockComponentProps<'ResolutionButtonBlock'>) => (
  <LinkButton
    href={data.linkTo}
    target="_blank"
    rel="noopener noreferrer"
    onClick={() => onClickEvent?.()}
  >
    {data.text}
  </LinkButton>
);

export default ResolutionButtonBlock;
