import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import styled from 'styled-components';

import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import Flex from 'components/lib/ui/Flex';
import ModalCard from 'components/lib/ui/ModalCard';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

type Props = {
  tokenExpiration: Date;
  onClickLogout: () => void;
  onClickContinueSession: () => void;
};

const CountdownTimer = styled(Flex)`
  font-size: ${({ theme }) => theme.fontSize.xxxlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 150%;
`;

const SessionTimeoutWarningModal = ({
  tokenExpiration,
  onClickContinueSession,
  onClickLogout,
}: Props) => (
  <ModalCard title="Session Timeout">
    <CardBody>
      You&apos;ve been inactive for more than 20 minutes. To protect your account, you will be
      automatically signed out soon.{' '}
      <CountdownTimer center>
        {' '}
        <Countdown
          date={tokenExpiration}
          renderer={({ minutes, seconds }) => `${minutes}:${zeroPad(seconds, 2)}`}
        />
      </CountdownTimer>
    </CardBody>
    <CardFooter>
      <DefaultButton onClick={onClickLogout}>Sign Out</DefaultButton>
      <PrimaryButton onClick={onClickContinueSession}>Continue Session</PrimaryButton>
    </CardFooter>
  </ModalCard>
);

export default SessionTimeoutWarningModal;
