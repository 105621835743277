import React from 'react';
import Helmet from 'react-helmet';

import ManageCategories from 'components/categories/ManageCategories';
import SettingsCard from 'components/lib/layouts/SettingsCard';
import Column from 'components/lib/ui/Column';

const CategorySettings = () => (
  <Column md={9}>
    <SettingsCard title="Categories">
      <Helmet>
        <title>Category Settings</title>
      </Helmet>
      <ManageCategories />
    </SettingsCard>
  </Column>
);

export default CategorySettings;
