import { lighten, rgba } from 'polished';
import * as React from 'react';
import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Tag from 'components/lib/ui/Tag';
import Text from 'components/lib/ui/Text';

import { color, spacing } from 'common/lib/theme/dynamic';

const ICON_SIZE_PX = 22;

const getHoverBackground = (theme: DefaultTheme, disabled: boolean) => {
  if (disabled) {
    return theme.color.grayBackground;
  }

  return theme.uiTheme === 'dark'
    ? lighten(0.02, theme.color.grayBackground)
    : rgba(theme.color.grayLight, 0.6);
};

const Root = styled(FlexContainer).attrs({ column: true })<{ $disabled: boolean }>`
  gap: ${({ theme }) => theme.spacing.default};
  background-color: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
  padding: ${({ theme }) => theme.spacing.xlarge};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  color: ${({ theme, $disabled }) => ($disabled ? theme.color.textLight : 'inherit')};
  transition: ${({ theme }) => theme.transition.default};

  :hover {
    background-color: ${({ theme, $disabled }) => getHoverBackground(theme, $disabled)};
  }
`;

const IconContainer = styled.div<{ $disabled: boolean }>`
  color: ${({ theme, $disabled }) => ($disabled ? theme.color.textLight : theme.color.orange)};
  font-size: ${ICON_SIZE_PX}px;
`;

const NewTag = styled(Tag)`
  background-color: ${color.orange};
  color: ${color.textWhite};
  padding: ${spacing.xxxsmall} ${spacing.xsmall};
`;

type Props = {
  icon: React.ReactNode;
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  isNew?: boolean;
};

const ModalCardButton = ({ icon, text, disabled = false, onClick, isNew = false }: Props) => (
  <Root onClick={onClick} $disabled={disabled}>
    <FlexContainer justifyBetween alignCenter>
      <IconContainer $disabled={disabled}>{icon}</IconContainer>
      {isNew ? <NewTag>NEW</NewTag> : null}
    </FlexContainer>
    <Text weight="medium">{text}</Text>
  </Root>
);

export default ModalCardButton;
