import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import ContentLoader from 'components/lib/ui/ContentLoader';

const NUM_GROUPS = 3;
const NUM_CATEGORIES_PER_GROUP = 3;

const PlaceholderAmounts = () => (
  <>
    <rect x="57%" y="24" rx="5" ry="5" width="50" height="20" />
    <rect x="72%" y="24" rx="5" ry="5" width="50" height="20" />
    <rect x="87%" y="24" rx="5" ry="5" width="50" height="20" />
  </>
);

const CategoryRowRoot = styled.div`
  height: 68px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const CategoryRowLoading = () => (
  <CategoryRowRoot>
    <ContentLoader>
      <circle cx="34" cy="34" r="12" />
      <rect x="64" y="24" rx="5" ry="5" width="23%" height="20" />
      <PlaceholderAmounts />
    </ContentLoader>
  </CategoryRowRoot>
);

const CategoryGroupHeaderRoot = styled.div`
  height: 68px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const CategoryGroupHeaderLoading = () => (
  <CategoryGroupHeaderRoot>
    <ContentLoader>
      <rect x="64" y="20" rx="5" ry="5" width="23%" height="28" />
      <PlaceholderAmounts />
    </ContentLoader>
  </CategoryGroupHeaderRoot>
);

const CategoryGroupFooterRoot = styled.div`
  height: 68px;
`;

const CategoryGroupFooterLoading = () => (
  <CategoryGroupFooterRoot>
    <ContentLoader>
      <PlaceholderAmounts />
    </ContentLoader>
  </CategoryGroupFooterRoot>
);

const CategoryGroupRoot = styled(Card)`
  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.gutter};
  }
`;

const CategoryGroupLoading = () => (
  <CategoryGroupRoot>
    <CategoryGroupHeaderLoading />
    {_.range(NUM_CATEGORIES_PER_GROUP).map((i) => (
      <CategoryRowLoading key={i} />
    ))}
    <CategoryGroupFooterLoading />
  </CategoryGroupRoot>
);

const Root = styled.div`
  margin-top: ${({ theme }) => theme.spacing.gutter};
`;

const BudgetListLoading = ({ className }: { className?: string }) => (
  <Root className={className}>
    {_.range(NUM_GROUPS).map((i) => (
      <CategoryGroupLoading key={i} />
    ))}
  </Root>
);

export default BudgetListLoading;
