import * as RA from 'ramda-adjunct';
import * as React from 'react';

import { useFormContext } from 'common/components/form/FormContext';
import TextButton from 'components/lib/ui/TextButton';

type Props = {
  name: string;
};

const ClearFieldButton = ({ name }: Props) => {
  const { setFieldValue, getFieldMeta } = useFormContext();
  const { value } = getFieldMeta(name);

  return (
    <TextButton
      onClick={() => setFieldValue(name, RA.isArray(value) ? [] : undefined)}
      disabled={RA.isNilOrEmpty(value)}
    >
      Clear
    </TextButton>
  );
};

export default ClearFieldButton;
