import type { DroppableStateSnapshot } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';

const DroppableOutline = styled.div<{ state?: DroppableStateSnapshot }>`
  border-radius: ${({ theme }) => theme.radius.medium};
  ${({ state }) =>
    state?.isDraggingOver &&
    css`
      box-shadow: 0 0 0 2px ${({ theme }) => theme.color.blue};
    `}
`;

export default DroppableOutline;
