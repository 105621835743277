import React from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import RouteLink from 'components/lib/ui/link/RouteLink';
import { INPUT_HEIGHT_PX } from 'components/plan/PlanGrid';

const Root = styled.div.attrs({ unstyled: true })<{ $hasIcon: boolean; $clickable: boolean }>`
  padding-left: ${({ theme, $hasIcon }) => ($hasIcon ? 0 : theme.spacing.default)};
  overflow: hidden;
  min-height: ${INPUT_HEIGHT_PX}px;
  display: flex;
  align-items: center;

  ${({ $clickable, theme }) =>
    $clickable &&
    css`
      cursor: pointer;
      transition: ${theme.transition.default};
      :hover {
        color: ${theme.color.blue};
      }
    `}
`;

const IconContainer = styled(FlexContainer).attrs({ center: true })`
  width: 48px;
  text-align: center;
  flex-shrink: 0;
  padding-left: ${({ theme }) => theme.spacing.xxsmall};
`;

const Title = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  user-select: none;
`;

type Props = Pick<React.HTMLProps<HTMLDivElement>, 'onClick'> & {
  icon?: React.ReactNode;
  href?: string;
  className?: string;
  children?: React.ReactNode;
  onClickIcon?: () => void;
};

const PlanRowTitle = ({ icon, onClick, onClickIcon, href, className, children }: Props) => (
  <Root
    alignCenter
    className={className}
    onClick={onClick}
    $hasIcon={!!icon}
    $clickable={!!onClick || !!href}
    to={href}
    as={href ? RouteLink : undefined}
  >
    {!!icon && <IconContainer onClick={onClickIcon}>{icon}</IconContainer>}
    <Title>{children}</Title>
  </Root>
);

export default React.memo(PlanRowTitle);
