import { getUpdatedQueryParams } from 'lib/transactions/Filters';

import routes from 'constants/routes';

import type { Web_GetCashFlowPageQuery } from 'common/generated/graphql';
import { CategoryGroupType } from 'common/generated/graphql';
import type DateRange from 'common/types/DateRange';
import type { ElementOf } from 'common/types/utility';

type ByCategoryItem = ElementOf<Web_GetCashFlowPageQuery, 'byCategory'>;
type ByCategoryGroupItem = ElementOf<Web_GetCashFlowPageQuery, 'byCategoryGroup'>;
type ByMerchantItem = ElementOf<Web_GetCashFlowPageQuery, 'byMerchant'>;

export type BreakdownEntry = {
  icon?: string;
  logoUrl?: string | null;
  name: string;
  type: 'income' | 'expense';
  value: number;
  link: string | null;
};

const hasValue = (entry: BreakdownEntry | null): entry is BreakdownEntry =>
  entry ? entry.value !== 0 : false;

const isCategoryGroup =
  (type: CategoryGroupType) =>
  ({ groupBy }: ByCategoryItem) =>
    groupBy?.category?.group.type === type;

const isCategoryGroupOfType =
  (type: CategoryGroupType) =>
  ({ groupBy }: ByCategoryGroupItem) =>
    groupBy?.categoryGroup?.type === type;

const asCategoryEntry =
  (dates: DateRange, timeframe: string) =>
  ({ groupBy, summary }: ByCategoryItem): BreakdownEntry | null => {
    if (!groupBy?.category) {
      return null;
    }

    const { icon, name, group, id } = groupBy.category;
    const { sum } = summary;

    return {
      icon,
      name,
      type: group.type === CategoryGroupType.INCOME ? 'income' : 'expense',
      value: sum,
      link: routes.categories({
        id,
        queryParams: getUpdatedQueryParams({ date: dates.startDate, timeframe }),
      }),
    };
  };

const asMerchantEntry =
  (dates: DateRange, type: CategoryGroupType, timeframe: string) =>
  ({ groupBy, summary }: ByMerchantItem): BreakdownEntry | null => {
    if (!groupBy?.merchant) {
      return null;
    }

    const isIncome = type === CategoryGroupType.INCOME;
    const { name, id, logoUrl } = groupBy.merchant;
    const { sumExpense, sumIncome } = summary;

    return {
      name,
      logoUrl,
      type: isIncome ? 'income' : 'expense',
      value: isIncome ? sumIncome : sumExpense,
      link: routes.merchants({
        id,
        queryParams: getUpdatedQueryParams({
          date: dates.startDate,
          timeframe,
        }),
      }),
    };
  };

const asCategoryGroupEntry =
  (type: CategoryGroupType, dates: DateRange, timeframe: string) =>
  ({ groupBy, summary }: ByCategoryGroupItem): BreakdownEntry | null => {
    if (!groupBy?.categoryGroup) {
      return null;
    }

    const { name, id } = groupBy.categoryGroup;
    const { sum } = summary;

    return {
      name,
      type: type === CategoryGroupType.EXPENSE ? 'expense' : 'income',
      value: sum,
      link: routes.categoryGroups({
        id,
        queryParams: getUpdatedQueryParams({ date: dates.startDate, timeframe }),
      }),
    };
  };

export const byCategoryAsEntries = (
  byCategory: ByCategoryItem[],
  dates: DateRange,
  type: CategoryGroupType,
  timeframe: string,
) =>
  byCategory.filter(isCategoryGroup(type)).map(asCategoryEntry(dates, timeframe)).filter(hasValue);

export const byCategoryGroupAsEntries = (
  byCategoryGroup: ByCategoryGroupItem[],
  type: CategoryGroupType,
  dates: DateRange,
  timeframe: string,
) =>
  byCategoryGroup
    .filter(isCategoryGroupOfType(type))
    .map(asCategoryGroupEntry(type, dates, timeframe))
    .filter(hasValue);

export const byMerchantAsEntries = (
  byMerchant: ByMerchantItem[],
  dates: DateRange,
  type: CategoryGroupType,
  timeframe: string,
) => byMerchant.map(asMerchantEntry(dates, type, timeframe)).filter(hasValue);
