import React from 'react';

import MessageCard from 'components/assistant/MessageCard';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

const MessageError = () => (
  <MessageCard>
    <Text color="red">
      <Icon name="alert-triangle" /> Error loading message
    </Text>
  </MessageCard>
);

export default MessageError;
