import { gql } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import TextAreaField from 'components/lib/form/TextAreaField';
import TextField from 'components/lib/form/TextField';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';

import useQuery from 'common/lib/hooks/useQuery';
import noop from 'common/utils/noop';

import type { TransactionExplainQuery } from 'common/generated/graphQlTypes/TransactionExplainQuery';

const Root = styled(Flex)`
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  id: string;
  description: string;
};

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  padding: ${({ theme }) => theme.spacing.xxxlarge};
`;

const TransactionExplainModal = ({ id, description }: Props) => {
  const { data, isLoadingInitialData: isLoading } = useQuery<TransactionExplainQuery>(QUERY, {
    variables: { id },
  });
  return (
    <ModalCard title="Transaction Explanation">
      <Root>
        <Form
          initialValues={{ description, explanation: data?.explainTransaction?.explanation }}
          onSubmit={noop}
        >
          <TextField name="description" label="Original Transaction" disabled />
          {isLoading ? (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          ) : (
            <TextAreaField name="explanation" disabled hideResizeHandle />
          )}
        </Form>
      </Root>
    </ModalCard>
  );
};

const QUERY = gql`
  query TransactionExplainQuery($id: UUID!) {
    explainTransaction(id: $id) {
      explanation
    }
  }
`;

export default TransactionExplainModal;
