export const SP_TICKER = 'IVV';
export const SP_TICKER_NEW = '^GSPC';
export const NASDAQ_TICKER = 'QQQ';
export const US_STOCK_TICKER = 'VTSAX';
export const US_STOCK_TICKER_NEW = 'VTI';
export const US_BOND_MARKET_TICKER = 'AGG';

export const BENCHMARK_SP_500 = 'S&P 500';
export const BENCHMARK_NASDAQ = 'NASDAQ';
export const BENCHMARK_US_STOCKS = 'US Stocks';
export const BENCHMARK_US_BONDS = 'US Bonds';

const DEFAULT_SELECTED_BENCHMARK_TICKER = SP_TICKER_NEW;

export type Benchmark = {
  security: {
    id?: string | null;
    ticker: string | null;
  };
};

// Map each benchmark name to a list of tickers. Tickers are sorted in descending
// order of preference. This object can be used to obtain the benchmark name for a ticker,
// as well as to gracefully handle situations involving the replacecement
// of the ticker used for a given benchmark. In such situations one should add
// the new ticker to the first position of the array associated with the benchmark
// name to be updated. This will result in new app versions using it, while old app
// versions will still display the correct benchmark name even though they will be
// using the old ticker. After most of/all app users have migrated to versions
// in which the new ticker is in the array, the old ticker can be safely removed.
export const BENCHMARK_NAME_TO_TICKERS: Record<string, string[]> = {
  [BENCHMARK_SP_500]: [SP_TICKER_NEW, SP_TICKER],
  [BENCHMARK_NASDAQ]: [NASDAQ_TICKER],
  [BENCHMARK_US_STOCKS]: [US_STOCK_TICKER_NEW, US_STOCK_TICKER],
  [BENCHMARK_US_BONDS]: [US_BOND_MARKET_TICKER],
};

export const TICKER_TO_BENCHMARK_NAME = Object.entries(BENCHMARK_NAME_TO_TICKERS).reduce<
  Record<string, string>
>((prev, [name, tickers]) => {
  tickers.forEach((ticker) => {
    prev[ticker] = name;
  });
  return prev;
}, {});

export const getBenchmarkNameFromTicker = (
  ticker: string | null | undefined,
): string | undefined => {
  if (!ticker) {
    return undefined;
  }

  const entry = Object.entries(BENCHMARK_NAME_TO_TICKERS).find(([name, tickers]) =>
    tickers.includes(ticker),
  );
  return entry ? entry[0] : undefined;
};

/**
 * This makes sure that there won't be two benchmarks objects with the same name.
 * If two or more benchmarks are received from the API with the same name, but with
 * different tickers, preference is given to the ticker coming first in the
 * BENCHMARK_NAME_TO_TICKERS object.
 **/
export const deduplicateBenchmarks = (benchmarks: Benchmark[]) => {
  const benchmarksByTicker: Record<string, Benchmark> = benchmarks.reduce(
    (acc, el) => ({ ...acc, [el.security.ticker ?? '']: el }),
    {},
  );

  return Object.entries(BENCHMARK_NAME_TO_TICKERS).reduce((results, [_name, tickers]) => {
    const benchmark = tickers
      .map((ticker) => benchmarksByTicker[ticker])
      .find((benchmark) => benchmark);
    return benchmark ? [...results, benchmark] : results;
  }, [] as Benchmark[]);
};

export const mapBenchmarkToOption = <T extends Benchmark>({ security }: T) => ({
  value: security.id || '',
  label: getBenchmarkNameFromTicker(security.ticker) || '',
});

export const findDefaultBenchmark = <T extends Benchmark>(benchmarks: T[]) =>
  benchmarks.find(({ security }) => security.ticker === DEFAULT_SELECTED_BENCHMARK_TICKER);
