import { readableColor, transparentize } from 'polished';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import { MonarchLogoSvg } from 'components/lib/ui/MonarchLogo';
import Text from 'components/lib/ui/Text';

import { spacing } from 'common/lib/theme/dynamic';
import lightPalette from 'common/lib/theme/lightPalette';
import ChartContext from 'lib/contexts/ChartContext';
import useMeasure from 'lib/hooks/useMeasure';
import useTheme from 'lib/hooks/useTheme';

const DEFAULT_SCALE_FACTOR = 0.7;

const Root = styled.div<{ $background: string }>`
  background-color: ${({ $background }) => $background};
  box-shadow: inset 0 0 0 1px ${({ theme }) => transparentize(0.9, theme.color.textWhite)};
  padding: ${spacing.xlarge} 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  pointer-events: none;
  gap: ${spacing.xlarge};
`;

const ScaledContainer = styled.div<{ scale: number; width: number; height: number }>`
  transform: scale(${({ scale }) => scale});
  transform-origin: top center;
  height: ${({ height, scale }) => height * scale}px;
  width: ${({ width }) => width}px;
`;

const PreviewText = styled(Text).attrs({ weight: 'medium' })<{ $color: string; $opacity?: number }>`
  color: ${({ $color }) => $color};
  opacity: ${({ $opacity }) => $opacity ?? 1};
`;

const PoweredByText = styled(PreviewText).attrs({ size: 'xsmall', as: 'div' })`
  display: flex;
  align-items: center;
  gap: ${spacing.xsmall};
`;

const Footer = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  width: 100%;
  padding: 0 ${spacing.xlarge};
`;

type Props = {
  chart: React.ReactNode;
  cardHeader?: React.ReactNode;
  background: string;
  className?: string;
  hideAmounts?: boolean;
  logoAppearance?: React.ComponentProps<typeof MonarchLogoSvg>['appearance'];
  scale?: number;
  title?: string;
};

/**
 * Basic component for rendering a preview of a share card.
 * This component was initially built for the Reports feature, but it can be used in other places as well.
 */
const ChartPreviewCard: React.FC<Props> = ({
  background,
  cardHeader,
  chart,
  className,
  hideAmounts = false,
  logoAppearance,
  scale = DEFAULT_SCALE_FACTOR,
  title,
}) => {
  const theme = useTheme();
  const [ref, { width = 0 }] = useMeasure();
  const [cardRef, { height = 0 }] = useMeasure();

  /**
   * Computes and returns the effective width for rendering a chart inside a preview card, accounting for a scale
   * factor and padding on each side of the card.
   *
   * The resulting width is larger than the actual chart width, but it fits perfectly when downscaled.
   * If the scale is 1, returns the original width without any calculations.
   */
  const calculatedWidth = useMemo(() => {
    // If the scale is 1, then we don't need to do any calculations
    if (scale === 1) {
      return width;
    }

    // This is the width of the chart card, minus the desired padding on each side
    const adjustedChartWidth = width - parseInt(theme.spacing.xlarge, 10) * 2;

    // Now, we scale down the chart width by the predefined scale factor
    return adjustedChartWidth / scale;
  }, [width]);

  // Determine the text color based on the logo appearance
  const textColor = useMemo(
    () =>
      // If a logo appearance is provided, use that to determine the text color
      logoAppearance && ['allWhite'].includes(logoAppearance)
        ? lightPalette.textWhite
        : readableColor(background, lightPalette.text, lightPalette.textWhite),
    [background, logoAppearance],
  );

  return (
    <Root ref={ref} $background={background} className={className}>
      {title && (
        <PreviewText $color={textColor} size="large">
          {title}
        </PreviewText>
      )}
      <ScaledContainer scale={scale} height={height} width={calculatedWidth}>
        <Card title={cardHeader} ref={cardRef}>
          <ChartContext.Provider value={{ isPreview: true, hideAmounts }}>
            {React.isValidElement(chart) ? React.cloneElement(chart) : null}
          </ChartContext.Provider>
        </Card>
      </ScaledContainer>
      <Footer>
        <PoweredByText $color={textColor}>
          <span>Powered by</span> <MonarchLogoSvg appearance={logoAppearance} height={20} />
        </PoweredByText>
        <PreviewText $color={textColor} size="small" $opacity={0.6}>
          monarchmoney.com
        </PreviewText>
      </Footer>
    </Root>
  );
};

export default React.memo(ChartPreviewCard);
