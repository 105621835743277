import React from 'react';
import styled from 'styled-components';

import VehicleSearchForm from 'components/accounts/VehicleSearchForm';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';

type Props = {
  vin?: string;
  next: (data: { value?: number; name?: string; vin?: string }) => void;
  goBack?: () => void;
};

const Title = styled(Text)`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding: ${({ theme }) => `0 ${theme.spacing.xlarge}`};
  padding-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const AddVehicleAccountSearchModal = ({ next, goBack }: Props) => {
  const handleVehicleClick = ({
    vin,
    name,
    value,
  }: {
    vin?: string;
    name?: string;
    value?: number;
  }) => {
    next({
      vin,
      name,
      value,
    });
  };

  return (
    <ModalCard onClickBackButton={goBack} hideBottomBorder>
      <Title>Add a vehicle</Title>
      <VehicleSearchForm onVehicleClick={handleVehicleClick} />
    </ModalCard>
  );
};

export default AddVehicleAccountSearchModal;
