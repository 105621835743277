import * as R from 'ramda';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { useAdvisorOnboardingFlowState } from 'components/advisorOnboarding/context/AdvisorOnboardingProvider';
import AppleSignInButton from 'components/lib/external/AppleSignInButton';
import GoogleSignInButton from 'components/lib/external/GoogleSignInButton';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import CardTitle from 'components/lib/ui/CardTitle';
import Divider from 'components/lib/ui/Divider';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinnerWithText from 'components/lib/ui/LoadingSpinnerWithText';
import TermsAndConditions from 'components/lib/ui/TermsAndConditions';
import Text from 'components/lib/ui/Text';
import ManualLink from 'components/lib/ui/link/ManualLink';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import getUserApi from 'common/lib/api/user';
import api from 'lib/api';
import type { AuthProviderResponse } from 'lib/hooks/auth/useAuthProvider';
import useFingerprint from 'lib/hooks/auth/useFingerprint';
import useUserAttributionData from 'lib/hooks/useUserAttributionData';

import { AUTH_PROVIDER_TO_LABEL } from 'common/constants/auth';
import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

import type { AuthProvider } from 'common/types/auth';

const MAX_WIDTH_CONTAINER_PX = 350;

const FormContainer = styled(OnboardingFormContainer)<{ $resetPadding: boolean }>`
  padding: ${({ $resetPadding }) => ($resetPadding ? 0 : 'initial')} 0
    ${({ theme }) => theme.spacing.large};
  max-width: ${MAX_WIDTH_CONTAINER_PX}px;
`;

const IntroContainer = styled(FlexContainer).attrs({ column: true })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  width: 100%;

  ${OnboardingTitle} {
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: ${({ theme }) => theme.spacing.small};
  }
`;

const WelcomePageSubtitle = styled.p`
  margin: ${({ theme }) => theme.spacing.xsmall} 0 ${({ theme }) => theme.spacing.xlarge};
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing.large} 0;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH_CONTAINER_PX}px;
  margin: 0 auto;
  padding: 0;

  > button:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }
`;

const TermsAndConditionsWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.textLight};
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
  max-width: 300px;
`;

const AdvisorOnboardingWelcome = () => {
  const { next } = useAdvisorOnboardingFlowState();
  const history = useHistory();

  const fingerprint = useFingerprint();
  const userAttributionData = useUserAttributionData();
  const [authenticatingWithProvider, setAuthenticatingWithProvider] = useState<AuthProvider>();
  // This is to make sure we activate the sponsor account on the backend
  const federatedAuthExtraData = useMemo(() => ({ isSponsor: true }), []);

  const onSubmit = useCallback(
    async ({ email }: { email: string }) => {
      const trimmedEmail = R.trim(email);

      await getUserApi(api).createUnverifiedUser({
        email: trimmedEmail,
        userAttributionData,
        isSponsor: true,
        fingerprint,
      });
      await getUserApi(api).createVerification(trimmedEmail);
      next({ email: trimmedEmail });
    },
    [fingerprint, next, userAttributionData],
  );

  const onFederatedLoginSuccess = useCallback(
    (response: AuthProviderResponse) => {
      const { email } = response;
      next({ email, isFederatedAuth: true });
    },
    [next],
  );

  if (authenticatingWithProvider) {
    return (
      <LoadingSpinnerWithText
        text={
          <CardTitle>
            Signing in with {AUTH_PROVIDER_TO_LABEL[authenticatingWithProvider]}
          </CardTitle>
        }
      />
    );
  }

  return (
    <>
      <IntroContainer>
        <OnboardingTitle>{COPY.ADVISOR_ONBOARDING.WELCOME.TITLE}</OnboardingTitle>
        <WelcomePageSubtitle>{COPY.ADVISOR_ONBOARDING.WELCOME.SUBTITLE}</WelcomePageSubtitle>
        <ButtonsContainer>
          <AppleSignInButton
            onConfirmedAuthentication={onFederatedLoginSuccess}
            extraData={federatedAuthExtraData}
            setIsPending={(isPending) =>
              setAuthenticatingWithProvider(isPending ? 'apple' : undefined)
            }
            skipConfirmation
          />
          <GoogleSignInButton
            onConfirmedAuthentication={onFederatedLoginSuccess}
            onError={() => history.push(`${routes.login()}?message=authError`)}
            extraData={federatedAuthExtraData}
            setIsPending={(isPending) =>
              setAuthenticatingWithProvider(isPending ? 'google' : undefined)
            }
            skipConfirmation
          />
          <StyledDivider />
        </ButtonsContainer>
      </IntroContainer>
      <FormContainer $resetPadding>
        <Form enableReinitialize onSubmit={onSubmit} initialValues={{ email: '' }}>
          <TextField
            name="email"
            label={COPY.ADVISOR_ONBOARDING.WELCOME.LABEL_EMAIL}
            placeholder={COPY.ADVISOR_ONBOARDING.WELCOME.PLACEHOLDER_EMAIL}
            email
            autoFocus
            autoFocusOnMobile={false}
          />
          <FormSubmitButton variant="gradient" size="medium">
            {COPY.ADVISOR_ONBOARDING.WELCOME.SUBMIT_BUTTON}
          </FormSubmitButton>
        </Form>
      </FormContainer>
      <TermsAndConditionsWrapper>
        <TermsAndConditions />
      </TermsAndConditionsWrapper>
      <>
        <Text size="small">
          Already have an account?{' '}
          <ManualLink onClick={() => history.push(routes.login())}>Sign in</ManualLink>
        </Text>
      </>
    </>
  );
};

export default AdvisorOnboardingWelcome;
