import _ from 'lodash';

import { windowDidResize } from 'actions';
import type { Middleware } from 'state/types';

import type WindowSize from 'types/WindowSize';

const WINDOW_RESIZE_THROTTLE_MS = 300;

const windowMiddleware: Middleware = (store) => {
  const getWindowSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  let previousSize: WindowSize | undefined;
  const onWindowResize = () => {
    const currentSize = getWindowSize();
    store.dispatch(windowDidResize({ currentSize, previousSize }));
    previousSize = currentSize;
  };

  window.addEventListener('resize', _.throttle(onWindowResize, WINDOW_RESIZE_THROTTLE_MS));
  window.addEventListener('load', onWindowResize);

  return (next) => (action) => next(action);
};

export default windowMiddleware;
