import React from 'react';

import type { PillProps } from 'components/lib/ui/Pill';
import Pill from 'components/lib/ui/Pill';

import { compare } from 'common/utils/Math';

import type { Color, ColorOrTransparent } from 'types/Styles';

type Props = Omit<PillProps, 'children'> & {
  /** This will determine the color of the pill.
   * (> 0 green, < 0 red, == 0 gray)
   * If you want to display a rounded number, ensure you pass the rounded number here so the right color is chosen. */
  value: number | null;
  formatter: (value: number | null) => string;

  /** These colors are set by default to green, red, gray but can be overridden */
  positiveBackgroundColor?: ColorOrTransparent;
  positiveHoverBackgroundColor?: Color;
  positiveTextColor?: Color;
  negativeBackgroundColor?: ColorOrTransparent;
  negativeTextColor?: Color;
  negativeHoverBackgroundColor?: Color;
  neutralBackgroundColor?: ColorOrTransparent;
  neutralTextColor?: Color;
  neutralHoverBackgroundColor?: Color;
};

/** Pill component used for numeric values. Handles colors according to value (red, green, gray). */
const AmountPill = ({
  value,
  formatter,
  positiveBackgroundColor = 'greenBackground',
  positiveHoverBackgroundColor = 'greenFocus',
  positiveTextColor = 'greenText',
  negativeBackgroundColor = 'redBackground',
  negativeTextColor = 'redText',
  negativeHoverBackgroundColor = 'redFocus',
  neutralBackgroundColor = 'grayFocus',
  neutralTextColor = 'textLight',
  neutralHoverBackgroundColor = 'grayLight',
  ...props
}: Props) => {
  const backgroundColor = compare<ColorOrTransparent>(value ?? 0, 0, {
    equal: neutralBackgroundColor,
    greaterThan: positiveBackgroundColor,
    lessThan: negativeBackgroundColor,
  });
  const hoverBackgroundColor = compare<Color>(value ?? 0, 0, {
    equal: neutralHoverBackgroundColor,
    greaterThan: positiveHoverBackgroundColor,
    lessThan: negativeHoverBackgroundColor,
  });
  const textColor = compare<Color>(value ?? 0, 0, {
    equal: neutralTextColor,
    greaterThan: positiveTextColor,
    lessThan: negativeTextColor,
  });

  return (
    <Pill
      backgroundColor={backgroundColor}
      hoverBackgroundColor={hoverBackgroundColor}
      textColor={textColor}
      {...props}
    >
      {formatter(value)}
    </Pill>
  );
};

export default AmountPill;
