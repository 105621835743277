import _ from 'lodash';
import React, { useContext } from 'react';

import FormContext from 'common/components/form/FormContext';
import PasswordValidationStatus from 'components/onboarding/PasswordValidationStatus';

import {
  SIGNUP_PASSWORD_SCHEMAS,
  getMergedSignupPasswordSchema,
  validateWithPasswordSchema,
} from 'common/lib/form/validation';

const VALIDATION_LABEL = 'Password';

const PasswordValidationGroup = () => {
  const formContext = useContext(FormContext);
  const { password } = formContext.values;

  return (
    <>
      {SIGNUP_PASSWORD_SCHEMAS.map((entry, i) => {
        const { meetsCriteria, criteriaMessage } = validateWithPasswordSchema(
          VALIDATION_LABEL,
          entry,
          password,
        );
        return (
          <PasswordValidationStatus
            meetsCriteria={meetsCriteria}
            message={criteriaMessage}
            key={i}
          />
        );
      })}
    </>
  );
};

export default PasswordValidationGroup;

export const MergedPasswordSchema = getMergedSignupPasswordSchema(VALIDATION_LABEL);
