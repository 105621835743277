import React, { useEffect } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Markdown from 'components/lib/ui/Markdown';
import SideDrawer from 'components/lib/ui/SideDrawer';
import Text from 'components/lib/ui/Text';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { defaultButtonMixin } from 'components/lib/ui/button/DefaultButton';

import useQuery from 'common/lib/hooks/useQuery';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import typewriter from 'lib/analytics/typewriter';
import useAdviceDrawerRoutes from 'lib/hooks/advice/useAdviceDrawerRoutes';
import useToggleCompleteAdviceTask from 'lib/hooks/advice/useToggleCompleteAdviceTask';

import type routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type RouteProps from 'types/RouteProps';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.xxxlarge} ${({ theme }) => theme.spacing.xxlarge};
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  flex: 1;
  height: 80%;
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  display: block;
`;

const Description = styled(Markdown)`
  font-size: ${({ theme }) => theme.fontSize.base};
  line-height: 150%;
  display: block;
  margin-top: ${({ theme }) => theme.spacing.small};
  white-space: pre-wrap;
  margin-bottom: 40px;
`;

const Button = styled(AsyncButton).attrs({ size: 'large' })`
  ${defaultButtonMixin};
  width: 100%;
`;

const CompleteContainer = styled(FlexContainer).attrs({ alignCenter: true, justifyCenter: true })`
  color: ${({ theme }) => theme.color.green};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const CompleteIcon = styled(Icon).attrs({ name: 'check-square' })`
  width: ${({ theme }) => theme.spacing.small};
  height: ${({ theme }) => theme.spacing.small};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const AdviceTaskDrawer = ({
  match: {
    params: { itemId, taskId },
  },
}: RouteProps<typeof routes.advice.taskDetails>) => {
  const { goBack, onClose } = useAdviceDrawerRoutes({ itemId, taskId });

  const { data, isLoadingInitialData } = useQuery(QUERY, {
    variables: { itemId, taskId },
  });
  const { adviceItem, adviceItemTask } = data ?? {};
  const { title: adviceItemTitle, name: itemName } = adviceItem ?? {};
  const { title, description, completedAt, name } = adviceItemTask ?? {};

  const toggleComplete = useToggleCompleteAdviceTask();

  useEffect(() => {
    name && itemName && typewriter.viewedAdviceTask({ item_name: itemName, name });
  }, [name, itemName]);

  return (
    <SideDrawer title={adviceItemTitle} onClose={onClose} onClickBack={goBack}>
      {isLoadingInitialData ? (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ) : (
        <Container>
          <Title>{title}</Title>
          {!!description && <Description source={description} />}
          {!!completedAt && (
            <CompleteContainer>
              <CompleteIcon />
              <Text size="xsmall">
                Completed on {isoDateToAbbreviatedMonthDayAndYear(completedAt)}
              </Text>
            </CompleteContainer>
          )}
          <Button size="large" onClick={() => adviceItemTask && toggleComplete(adviceItemTask)}>
            {completedAt ? 'Mark as incomplete' : 'Mark as complete'}
          </Button>
        </Container>
      )}
    </SideDrawer>
  );
};

const QUERY = gql(`
  query AdviceTaskDrawerQuery_Web($itemId: ID!, $taskId: ID!) {
    adviceItem(id: $itemId) {
      id
      name
      title
    }
    adviceItemTask(id: $taskId) {
      id
      name
      title
      description
      completedAt
    }
  }
`);

export default AdviceTaskDrawer;
