import React from 'react';
import styled from 'styled-components';

import CategoryPlanSummaryCard from 'components/cashFlows/CategoryPlanSummaryCard';
import EditCategoryModal from 'components/categories/EditCategoryModal';
import PageEmptyOverlayCard from 'components/lib/ui/PageEmptyOverlayCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import FilteredCashFlowPage from 'components/routes/FilteredCashFlowPage';

import useQuery from 'common/lib/hooks/useQuery';
import { useQueryParam } from 'lib/hooks';
import useModal from 'lib/hooks/useModal';

import type { Timeframe } from 'common/constants/timeframes';
import type routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type { GetCategoryDetailsQuery } from 'common/generated/graphql';
import { CategoryGroupType } from 'common/generated/graphql';
import type RouteProps from 'types/RouteProps';
import type { TransactionFilters } from 'types/filters';

const DEFAULT_TIMEFRAME: Timeframe = 'month';

const DeletedMessage = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

const PlanSummaryCard = styled(CategoryPlanSummaryCard)`
  margin-bottom: var(--spacing);
`;

const CategoryDetails = ({
  match: {
    params: { id },
  },
}: RouteProps<typeof routes.categories>) => {
  const date = useQueryParam('date');
  const timeframe = (useQueryParam('timeframe') ?? DEFAULT_TIMEFRAME) as Timeframe;

  // Only show budget amounts when a single month date range is selected
  const includeBudgetAmounts = timeframe === 'month';

  const transactionFilters: Partial<TransactionFilters> = { categories: [id] };
  const { data, refetch, error } = useQuery<GetCategoryDetailsQuery>(QUERY, {
    variables: {
      id,
      month: date,
      includeBudgetAmounts,
    },
    skip: !date,
  });
  const category = data?.category;
  const isExpense = category?.group?.type === CategoryGroupType.EXPENSE;
  const { budgetAmountsForMonth, rolloverPeriod } = category ?? {};

  const [EditModal, { open: openEditModal, close: closeEditModal }] = useModal();

  const showPlanSummaryCard = !category?.excludeFromBudget && includeBudgetAmounts;

  return (
    <>
      <FilteredCashFlowPage
        title={`${data?.category?.icon || ''} ${data?.category?.name || ''}`}
        transactionFilters={transactionFilters}
        displayAsExpense={isExpense}
        additionalControls={<DefaultButton onClick={openEditModal}>Edit category</DefaultButton>}
        overlayEmptyComponent={
          error ? (
            <PageEmptyOverlayCard title="Category does not exist">
              <DeletedMessage align="center" color="textLight">
                This category may have been disabled or deleted.
              </DeletedMessage>
            </PageEmptyOverlayCard>
          ) : undefined
        }
        additionalSummaryElement={
          showPlanSummaryCard && (
            <PlanSummaryCard
              categoryId={id}
              data={budgetAmountsForMonth}
              onResetRollover={refetch}
              loading={!budgetAmountsForMonth || budgetAmountsForMonth.month !== date}
              rolloverStartingBalance={rolloverPeriod?.startingBalance}
            />
          )
        }
      />

      {category && (
        <EditModal>
          <EditCategoryModal
            categoryId={category.id}
            onDone={() => {
              closeEditModal();
              refetch();
            }}
          />
        </EditModal>
      )}
    </>
  );
};

const QUERY = gql(/* GraphQL */ `
  query GetCategoryDetails($id: UUID, $month: Date!, $includeBudgetAmounts: Boolean!) {
    category(id: $id) {
      id
      order
      name
      icon
      isSystemCategory
      systemCategory
      excludeFromBudget
      isDisabled
      group {
        id
        name
        type
      }
      rolloverPeriod {
        id
        startingBalance
      }
      budgetAmountsForMonth(month: $month) @include(if: $includeBudgetAmounts) {
        ...CategoryPlanSummaryFields
      }
    }
  }
`);

export default CategoryDetails;
