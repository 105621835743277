import { getBudgetedAmountWithRollover } from 'common/lib/budget/Amounts';
import type { PlanAmounts } from 'common/lib/planning';

export const getSummaryWidgetProgressBarMax = (actual: number, budgeted: number) => {
  if (actual < 0) {
    return 0; // If the actual is negative, we want to show the progress bar as 0%
  }

  if (actual === 0) {
    return 1; // This will show an empty progress bar
  }

  return budgeted;
};

/**
 * Gets the max value for the progress bar of the groups in the Budget page.
 */
export const getGroupProgressBarMax = (amounts: PlanAmounts) => {
  // Actual is negative, so it's probably a category with refunds or something
  if (amounts?.actual && amounts?.actual < 0) {
    return 0;
  }

  const budgetedWithRollover = getBudgetedAmountWithRollover(amounts?.budgeted, amounts?.rollover);

  // If the budgeted with rollover is negative, we want any expense amount to fill up the bar.
  // Indicating that the user can't spend more. To achieve that, we set the max to 0.
  if (budgetedWithRollover && budgetedWithRollover < 0) {
    return 0;
  }

  return budgetedWithRollover;
};
