import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import ColorField from 'components/lib/form/ColorField';
import FieldCellWithToggle from 'components/lib/form/FieldCellWithToggle';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import ChartPreviewCard from 'components/lib/ui/share/ChartPreviewCard';

import { spacing } from 'common/lib/theme/dynamic';
import lightPalette from 'common/lib/theme/lightPalette';
import { onDownloadChartAsPng, onGenerateChartForDownload } from 'lib/ui/charts';
import type { ReportsState } from 'state/reports/reducer';
import { isTabDisplayProperties } from 'state/reports/reducer';

const MODAL_WIDTH_PX = 840;
// We don't want the colors to change when the theme changes, so we use the light palette
const AVAILABLE_COLORS = [
  lightPalette.grayLight,
  lightPalette.orange,
  lightPalette.navy,
  lightPalette.blue,
  lightPalette.green,
  lightPalette.yellow,
  lightPalette.black,
  lightPalette.purple,
  lightPalette.pink,
  lightPalette.lime,
];

const Root = styled(ModalCard)`
  width: ${MODAL_WIDTH_PX}px;
  margin: auto;
`;

const Content = styled(FlexContainer).attrs({ column: true, gap: 'gutter' })`
  padding: ${spacing.xlarge};
  padding-top: 0;
`;

/** Defining another component here because I don't want the margin to be included in the snapshot */
const ChartContainerWithMargin = styled.div`
  margin: 0 ${spacing.xlarge} ${spacing.xlarge};
`;

const TextFieldWithoutMargin = styled(TextField)`
  && {
    margin-bottom: 0;
  }
`;

type FormValues = {
  title: string;
  hideAmounts: boolean;
  background: string;
};

type Props = {
  chart: React.ReactNode;
  header: React.ReactNode;
  initialValues?: Partial<FormValues>;
  displayProperties: ReportsState['sankey'] | ReportsState['cashFlow'];
};

const ReportsChartShareModal: React.FC<Props> = ({
  chart,
  header,
  initialValues,
  displayProperties,
}) => {
  const getOverriddenLogoAppearance = useCallback((background: string) => {
    switch (background) {
      case lightPalette.grayLight:
      case lightPalette.yellow:
      case lightPalette.lime:
        return 'dark';
      default:
        return 'allWhite';
    }
  }, []);

  const chartRef = useRef<HTMLDivElement>(null);

  const onGenerateChart = useCallback(
    ({ title }: FormValues) =>
      onGenerateChartForDownload({
        element: chartRef.current,
        fileName: title,
        height: chartRef.current?.offsetHeight,
        includeWatermark: false,
        quality: 1,
        scale: 2,
        width: chartRef.current?.offsetWidth,
        chartType: displayProperties?.chartType,
      }),
    [displayProperties],
  );

  const onDownloadChart = useCallback(
    async (values: FormValues) => {
      const canvas = await onGenerateChart(values);

      if (canvas && displayProperties) {
        onDownloadChartAsPng(canvas, values.title, {
          chart: displayProperties.chartType,
          view:
            (isTabDisplayProperties(displayProperties)
              ? displayProperties?.viewMode
              : displayProperties?.groupMode) ?? undefined,
        });
      }
    },
    [onGenerateChart, displayProperties],
  );

  return (
    <Root title="Download Chart" hideBottomBorder>
      <Form
        onSubmit={onDownloadChart}
        initialValues={{
          title: '',
          hideAmounts: false,
          background: AVAILABLE_COLORS[0],
          ...(initialValues || {}),
        }}
        isInitialValid
      >
        <Content>
          <TextFieldWithoutMargin name="title" label="Chart Name" required />
          <FieldCellWithToggle
            toggleFieldProps={{
              name: 'hideAmounts',
              label: 'Hide dollar amounts',
            }}
            fieldCellProps={{
              title: 'Hide dollar amounts',
              subtitle:
                'This will hide dollar amounts so that actual values are not visible. ' +
                'This is great if you want to keep the amounts private, but still share the trends.',
            }}
          />
          <ColorField name="background" label="Background" colors={AVAILABLE_COLORS} />
        </Content>
        <ChartContainerWithMargin>
          {React.isValidElement(chart) && (
            <FormContext.Consumer>
              {({ values }) => (
                <div ref={chartRef}>
                  <ChartPreviewCard
                    title={values.title}
                    chart={chart}
                    cardHeader={header}
                    background={values.background}
                    logoAppearance={getOverriddenLogoAppearance(values.background)}
                    hideAmounts={values.hideAmounts}
                  />
                </div>
              )}
            </FormContext.Consumer>
          )}
        </ChartContainerWithMargin>
        <CardFooter>
          <FormSubmitButton size="small" disableWhenValuesUnchanged={false}>
            Download
          </FormSubmitButton>
        </CardFooter>
      </Form>
    </Root>
  );
};

export default React.memo(ReportsChartShareModal);
