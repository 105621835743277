import { gql } from '@apollo/client';
import { darken } from 'polished';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import useTheme from 'lib/hooks/useTheme';

import type { AdviceCategoryIconFields } from 'common/generated/graphQlTypes/AdviceCategoryIconFields';

const ICON_FOR_CATEGORY: { [key: string]: string } = {
  save: 'monarch-piggy-bank',
  spend: 'monarch-shopping-bag',
  invest: 'monarch-hand-heart',
  pay_down: 'monarch-credit-check',
  protect: 'monarch-health-shield',
  wellness: 'monarch-flower',
};

const Root = styled(FlexContainer).attrs({ column: true, alignCenter: true })``;

const Circle = styled(FlexContainer).attrs({ center: true })<{ color: string; sizePx: number }>`
  width: ${({ sizePx }) => sizePx}px;
  height: ${({ sizePx }) => sizePx}px;
  border-radius: ${({ theme }) => theme.radius.round};
  background: ${({ color }) => color};
  flex-shrink: 0;
  border: 2px solid ${({ color }) => darken(0.05, color)};
`;

const StyledIcon = styled(Icon)<{ sizePx: number }>`
  width: ${({ sizePx }) => sizePx}px;
  height: ${({ sizePx }) => sizePx}px;
  color: ${({ theme }) => theme.color.textWhite};
`;

const Title = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.color.textLight};
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  text-transform: uppercase;
  letter-spacing: 0.08em;
`;

const CompleteBorder = styled(Circle)`
  background: none;
  border: 3px solid ${({ theme }) => theme.color.green};
`;

const CompleteCircle = styled(Circle)`
  border: none;
`;

type Props = {
  adviceCategory: AdviceCategoryIconFields;
  completed?: boolean;
  showName?: boolean;
  sizePx?: number;
  className?: string;
};

const AdviceCategoryIcon = ({
  adviceCategory: { displayName, name, color },
  completed,
  showName = true,
  sizePx = 48,
  className,
}: Props) => {
  const theme = useTheme();

  return (
    <Root className={className}>
      {completed ? (
        <CompleteBorder sizePx={sizePx} color={theme.color.green}>
          <CompleteCircle color={theme.color.green} sizePx={sizePx - 10}>
            <StyledIcon name="check" sizePx={sizePx / 2} />
          </CompleteCircle>
        </CompleteBorder>
      ) : (
        <Circle color={color} sizePx={sizePx}>
          {!!ICON_FOR_CATEGORY[name] && (
            <StyledIcon name={ICON_FOR_CATEGORY[name]} sizePx={sizePx / 2} />
          )}
        </Circle>
      )}
      {showName && <Title>{displayName}</Title>}
    </Root>
  );
};

AdviceCategoryIcon.fragments = {
  AdviceCategoryIconFields: gql`
    fragment AdviceCategoryIconFields on AdviceItemCategory {
      name
      displayName
      color
    }
  `,
};

export default AdviceCategoryIcon;
