import * as RA from 'ramda-adjunct';
import React from 'react';

import type { Props as FeatureFlagProps } from 'components/lib/higherOrder/FeatureFlag';
import FeatureFlag from 'components/lib/higherOrder/FeatureFlag';

type Props = Omit<FeatureFlagProps, 'children'> & {
  value?: string | string[];
  children: JSX.Element | null;
};

/**
 * Higher level component that uses FeatureFlag to easily conditionally render based on a feature's value
 *
 * <FeatureFlagGate name="category-management">
 *   <DefaultButton>Manage Categories</DefaultButton>
 * </FeatureFlagGate>
 */
const FeatureFlagGate = ({ value = 'on', children, ...props }: Props) => (
  <FeatureFlag {...props}>
    {(feature) => {
      if (RA.isString(value)) {
        return feature === value ? children : null;
      } else if (RA.isArray(value)) {
        return value.includes(feature) ? children : null;
      } else {
        return null;
      }
    }}
  </FeatureFlag>
);

export default FeatureFlagGate;
