import * as React from 'react';
import { useHistory } from 'react-router-dom';

import SubscriptionModalAnnualUpsell from 'components/settings/billing/SubscriptionModalAnnualUpsell';

import useChangeSubscription from 'lib/hooks/billing/useChangeSubscription';
import useCreateSubscription from 'lib/hooks/billing/useCreateSubscription';
import useReactivateSubscription from 'lib/hooks/billing/useReactivateSubscription';

import routes from 'constants/routes';

type Props = {
  closeAnnualUpsellModal: () => void;
};

const AnnualUpsellModalCard = ({ closeAnnualUpsellModal }: Props) => {
  const { createSubscription } = useCreateSubscription();

  const { reactivateSubscriptionWithPriceId } = useReactivateSubscription();
  const { changeSubscription } = useChangeSubscription();

  const history = useHistory();

  return (
    <SubscriptionModalAnnualUpsell
      title="Switch to yearly and save"
      changeSubscription={changeSubscription}
      createSubscription={createSubscription}
      reactivateSubscription={reactivateSubscriptionWithPriceId}
      onDone={() => {
        closeAnnualUpsellModal();
        // redirect to the billing route since the user just changed their plan
        history.push(routes.settings.billing());
      }}
    />
  );
};

export default AnnualUpsellModalCard;
