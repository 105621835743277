import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import type { Props as TextInputProps } from 'components/lib/form/TextInput';
import TextInput from 'components/lib/form/TextInput';
import Icon from 'components/lib/ui/Icon';

const Root = styled.div`
  position: relative;
`;

export const Input = styled(TextInput)`
  padding-left: 38px;
  width: 100%;
`;

const SearchIcon = styled(Icon).attrs({ name: 'search' })`
  position: absolute;
  left: ${({ theme }) => theme.spacing.small};
  width: 16px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.color.textLight};
  pointer-events: none;
`;

const ClearIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: ${({ theme }) => theme.spacing.small};
  height: ${({ theme }) => theme.spacing.large};
  top: 50%;
  transform: translateY(-50%);
`;

const ClearIcon = styled(Icon).attrs({ name: 'x-circle', size: 16 })`
  color: ${({ theme }) => theme.color.textLight};

  :hover {
    color: ${({ theme }) => theme.color.text};
  }
`;

type Props = Omit<TextInputProps, 'onChange'> & {
  onChange?: (text: string) => void;
  className?: string;
  focusOnRender?: boolean;
};

/**
 * TextInput with a search icon
 */
const SearchBar: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { className, onChange, focusOnRender = false, ...props },
  ref,
) => {
  /**
   *  Wasn't possible to use the autofocus prop on the TextInput component because
   *  it doesn't work when it renders yourinto a detached element.
   *
   *  Also, I took the decision of just running it if the ref is not passed as a prop.
   */
  const searchInputElement = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (focusOnRender) {
      if (searchInputElement.current) {
        searchInputElement.current.focus();
      }
    }
  }, []);

  return (
    <Root className={className}>
      <SearchIcon />
      <Input
        {...props}
        ref={ref || searchInputElement}
        onChange={({ currentTarget: { value } }) => onChange?.(value)}
      />
      {!!props.value && (
        <ClearIconContainer onClick={() => onChange?.('')}>
          <ClearIcon />
        </ClearIconContainer>
      )}
    </Root>
  );
};

export default React.forwardRef(SearchBar);
