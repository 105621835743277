import { useMutation } from '@apollo/client';
import type { DateTime } from 'luxon';
import React from 'react';

import EmptyCreateCard from 'components/lib/ui/EmptyCreateCard';

import { getEmptyStateCopy } from 'common/lib/budget/Budget';
import { CREATE_BUDGET_MUTATION } from 'common/lib/graphQl/budgets';

import type { BudgetStatus } from 'common/generated/graphql';
import { BudgetTimeframeInput } from 'common/generated/graphql';

type Props = {
  date: DateTime;
  onCompleted: () => void;
  budgetStatus: BudgetStatus;
};

const CreateBudgetCard = ({ date, onCompleted, budgetStatus }: Props) => {
  const [createBudget, { loading }] = useMutation(CREATE_BUDGET_MUTATION, {
    variables: {
      input: {
        startDate: date.toISODate(),
        timeframe: BudgetTimeframeInput.MONTH,
      },
    },
    onCompleted,
  });

  return (
    <EmptyCreateCard
      icon="map"
      title="Create a budget"
      subtitle={getEmptyStateCopy(budgetStatus)}
      button={{ text: 'Create my budget', onClick: createBudget, loading }}
    />
  );
};
export default CreateBudgetCard;
