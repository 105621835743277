import React, { useContext } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import UnmapLiabilityAccountConfirmation from 'components/accounts/UnmapLiabilityAccountConfirmation';
import CategorySelect from 'components/categories/CategorySelect';
import FieldCell from 'components/lib/form/FieldCell';
import FormItemContainer, { Label } from 'components/lib/form/FormItemContainer';
import SelectField from 'components/lib/form/SelectField';
import TextInput from 'components/lib/form/TextInput';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import DayOfTheMonthField from 'components/recurring/DayOfMonthField';

import { RECURRING_STATUS_OPTIONS } from 'common/lib/recurring';
import { spacing } from 'common/lib/theme/dynamic';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';
import useModal from 'lib/hooks/useModal';

import { RECURRING_TRANSACTIONS_FREQUENCIES_INFO } from 'common/constants/recurringTransactions';

const RECURRING_FIELD_PREFIX = 'creditReportLiabilityAccount.recurringTransactionStream';

const Root = styled.div`
  ${Label} {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const Subtitle = styled.div`
  margin-bottom: ${spacing.default};
`;

type Props = {
  onDone?: () => void;
  lastStatementDueDateDay: number;
};

const AccountRecurringFields = ({ onDone, lastStatementDueDateDay }: Props) => {
  const [UnmapModal, { open: openUnmapModal, close: closeUnmapModal }] = useModal();

  const { values } = useContext(FormContext);

  const { creditReportLiabilityAccount, name } = values;

  const isRecurring = !!creditReportLiabilityAccount;

  const [isLoadingAccounts, accountOptions] = useAccountSelectOptions();

  return (
    <Root>
      {isRecurring ? (
        <>
          <Subtitle>
            This account has been mapped to your credit report and will show on the Recurring
            section with expected upcoming transactions.
          </Subtitle>

          <FormItemContainer
            name={`${RECURRING_FIELD_PREFIX}.frequency`}
            fieldId={`${RECURRING_FIELD_PREFIX}.frequency`}
            label="Recurring frequency"
            tooltip="We currently only support monthly bills. We'll be adding support for other types in the future."
          >
            <TextInput
              name={`${RECURRING_FIELD_PREFIX}.frequency`}
              value={RECURRING_TRANSACTIONS_FREQUENCIES_INFO.monthly}
              disabled
            />
          </FormItemContainer>

          <FormItemContainer
            name={`${RECURRING_FIELD_PREFIX}.dayOfTheMonth`}
            fieldId={`${RECURRING_FIELD_PREFIX}.dayOfTheMonth`}
            label="Payment Due Date"
            tooltip="Use the due date pulled from your credit report each month or set a custom override."
          >
            <DayOfTheMonthField
              name={`${RECURRING_FIELD_PREFIX}.dayOfTheMonth`}
              lastStatementDueDateDay={lastStatementDueDateDay}
            />
          </FormItemContainer>

          <FormItemContainer
            name={`${RECURRING_FIELD_PREFIX}.defaultPaymentAccount.id`}
            fieldId={`${RECURRING_FIELD_PREFIX}.defaultPaymentAccount.id`}
            label="Default payment account"
            tooltip="The account you typically use to pay off this bill amount."
          >
            <SelectField
              name={`${RECURRING_FIELD_PREFIX}.defaultPaymentAccount.id`}
              placeholder="Default payment account"
              hideLabel
              isLoading={isLoadingAccounts}
              options={accountOptions}
            />
          </FormItemContainer>

          <FormItemContainer
            name={`${RECURRING_FIELD_PREFIX}.defaultPaymentCategory.id`}
            fieldId={`${RECURRING_FIELD_PREFIX}.defaultPaymentCategory.id`}
            label="Default payment category"
            tooltip="The category that you typically use to categorize the bill payment transaction."
          >
            <SelectField
              name={`${RECURRING_FIELD_PREFIX}.defaultPaymentCategory.id`}
              placeholder="Default payment category"
              hideLabel
              isCreatable={false}
              InputComponent={CategorySelect}
            />
          </FormItemContainer>

          <FormItemContainer
            name={`${RECURRING_FIELD_PREFIX}.isActive`}
            fieldId={`${RECURRING_FIELD_PREFIX}.isActive`}
            label="Recurring status"
            tooltip="If you set the status to Canceled, we'll hide this account's statements on the recurring calendar."
          >
            <SelectField
              name={`${RECURRING_FIELD_PREFIX}.isActive`}
              hideLabel
              required={isRecurring}
              options={RECURRING_STATUS_OPTIONS}
            />
          </FormItemContainer>

          <FieldCell
            rightAccessory={<DefaultButton onClick={openUnmapModal}>Unmap</DefaultButton>}
            subtitle="Disable syncing this bill with Spinwheel"
            title="Unmap account"
          />

          <UnmapModal>
            <UnmapLiabilityAccountConfirmation
              streamId={creditReportLiabilityAccount.recurringTransactionStream.id}
              name={name}
              onConfirm={onDone}
              onCancel={closeUnmapModal}
            />
          </UnmapModal>
        </>
      ) : null}
    </Root>
  );
};

export default AccountRecurringFields;
