import React from 'react';

import useTheme from 'lib/hooks/useTheme';

const DragHandle = () => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="13" viewBox="0 0 6 13" fill="none">
      <path
        d="M5.25 2H0V3H5.25C5.66421 3 6 2.77614 6 2.5C6 2.22386 5.66421 2 5.25 2Z"
        fill={theme.color.grayDark}
      />
      <path
        d="M0 4H5.25C5.66421 4 6 4.22386 6 4.5C6 4.77614 5.66421 5 5.25 5H0V4Z"
        fill={theme.color.grayDark}
      />
      <path
        d="M5.25 6H0V7H5.25C5.66421 7 6 6.77614 6 6.5C6 6.22386 5.66421 6 5.25 6Z"
        fill={theme.color.grayDark}
      />
      <path
        d="M5.25 8H0V9H5.25C5.66421 9 6 8.77614 6 8.5C6 8.22386 5.66421 8 5.25 8Z"
        fill={theme.color.grayDark}
      />
      <path
        d="M5.25 10H0V11H5.25C5.66421 11 6 10.7761 6 10.5C6 10.2239 5.66421 10 5.25 10Z"
        fill={theme.color.grayDark}
      />
      <path
        d="M5.25 12H0V13H5.25C5.66421 13 6 12.7761 6 12.5C6 12.2239 5.66421 12 5.25 12Z"
        fill={theme.color.grayDark}
      />
    </svg>
  );
};

export default DragHandle;
