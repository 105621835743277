import React from 'react';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';

import { formatCurrency } from 'common/utils/Currency';
import formatTransactionAmount from 'common/utils/formatTransactionAmount';
import useTheme from 'lib/hooks/useTheme';

const Root = styled.div<{ $backgroundColor: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  margin: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.large};
  margin-top: 0;
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
  flex: 1;
  border-radius: ${({ theme }) => theme.radius.small};
`;

type Props = {
  isIncome: boolean;
  amountDiff: number;
  expectedAmount: number;
};

const AmountWarningBanner = ({ expectedAmount, isIncome, amountDiff }: Props) => {
  const theme = useTheme();

  let amountDiffText;
  if (isIncome) {
    amountDiffText = amountDiff < 0 ? 'lower' : 'higher';
  } else {
    amountDiffText = amountDiff > 0 ? 'lower' : 'higher';
  }

  return (
    <Root
      $backgroundColor={amountDiff > 0 ? theme.color.greenBackground : theme.color.yellowBackground}
    >
      <Text color={amountDiff > 0 ? 'greenText' : 'yellowText'}>
        This transaction was{' '}
        <Text weight="medium">
          {formatCurrency(Math.abs(amountDiff))} {amountDiffText}
        </Text>{' '}
        than the expected amount of{' '}
        <Text weight="medium">{formatTransactionAmount(expectedAmount)}</Text> for this merchant
      </Text>
    </Root>
  );
};

export default AmountWarningBanner;
