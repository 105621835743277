import { useState, useEffect } from 'react';

import getUserApi from 'common/lib/api/user';
import api from 'lib/api';

type Response = {
  isValid: boolean;
};

/**
 * Hook used to check if a referral code is valid.
 * It's currently used on the onboarding flow to change the button copy.
 */
const useIsReferralCodeValid = (code?: string | null) => {
  const [isValid, setIsValid] = useState<boolean | null>(null);

  useEffect(() => {
    const execute = async () => {
      if (!code) {
        setIsValid(false);
        return;
      }

      const data: Response = await getUserApi(api).getReferralCodeIsValid(code);
      setIsValid(data.isValid);
    };

    execute();
  }, [code]);

  return isValid === true;
};

export default useIsReferralCodeValid;
