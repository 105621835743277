import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import MapSpinwheelLiabilitiesAccounts from 'components/accounts/MapSpinwheelLiabilitiesAccounts';
import MapSpinwheelLiabilitiesStart from 'components/accounts/MapSpinwheelLiabilitiesStart';
import MapSpinwheelLiabilitiesSuccess from 'components/accounts/MapSpinwheelLiabilitiesSuccess';
import QuestionnaireHeader from 'components/advice/QuestionnaireHeader';
import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Page from 'components/lib/ui/Page';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import SpinwheelConnectionFlow from 'components/spinwheel/SpinwheelConnectionFlow';

import useSpinwheel from 'common/lib/hooks/recurring/useSpinwheel';
import { track } from 'lib/analytics/segment';
import useModal from 'lib/hooks/useModal';

import { BillTrackingEventNames } from 'common/constants/analytics';
import { DEFAULT_ERROR_MESSAGE } from 'common/constants/copy';
import routes from 'constants/routes';

import type {
  AccountMapping,
  Common_GetSpinwheelCreditReportQuery,
} from 'common/generated/graphql';

const MIN_DISPLAY_PROGRESS = 0.05;

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${({ theme }) => theme.color.white};
`;

const Container = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-bottom: 80px;
`;

const DisconnectButtonText = styled.span`
  white-space: nowrap;
  color: ${({ theme }) => theme.color.redText};
`;

enum MapSpinwheelLiabilitiesSteps {
  Start = 'Start',
  MapAccounts = 'MapAccounts',
  Success = 'Success',
}

const MapSpinwheelLiabilities = () => {
  const history = useHistory();
  const { createSpinwheelAccountMapping, isLoadingCreditReport } = useSpinwheel();

  const [ConnectionModal, { open: openConnectionModal, close: closeConnectionModal }] = useModal();

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [currentStep, setCurrentStep] = useState<MapSpinwheelLiabilitiesSteps>(
    MapSpinwheelLiabilitiesSteps.Start,
  );
  const [creditReportLiabilityAccounts, setCreditReportLiabilityAccounts] = useState<
    Common_GetSpinwheelCreditReportQuery['creditReportLiabilityAccounts'] | undefined | null
  >(undefined);

  const progress = (() => {
    switch (currentStep) {
      case MapSpinwheelLiabilitiesSteps.Start:
        return 0.1;
      case MapSpinwheelLiabilitiesSteps.MapAccounts:
        return 0.5;
      case MapSpinwheelLiabilitiesSteps.Success:
        return 1;
      default:
        return 1;
    }
  })();

  const syncCreditReport = (
    liabilityAccounts:
      | Common_GetSpinwheelCreditReportQuery['creditReportLiabilityAccounts']
      | null
      | undefined,
  ) => {
    setCurrentStep(MapSpinwheelLiabilitiesSteps.MapAccounts);
    setCreditReportLiabilityAccounts(liabilityAccounts);
    track(BillTrackingEventNames.CreditReportMappingStarted);
  };

  const onSubmitAccountsMap = async (values: { accountMapping: AccountMapping[] }) => {
    const response = await createSpinwheelAccountMapping(values.accountMapping);

    if (response?.success) {
      setErrorMessage(undefined);
      setCurrentStep(MapSpinwheelLiabilitiesSteps.Success);
    } else {
      setErrorMessage(response?.errors?.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const shouldShowDisconnect = currentStep === MapSpinwheelLiabilitiesSteps.MapAccounts;
  const shouldShowBack = currentStep === MapSpinwheelLiabilitiesSteps.Success;
  const shouldShowCancel = currentStep !== MapSpinwheelLiabilitiesSteps.Success;

  const DisconnectButton = ({
    visible,
    onClick,
  }: React.PropsWithChildren<{ visible?: boolean; onClick: () => void }>) =>
    visible ? (
      <DefaultButton linkTo={undefined} onClick={onClick}>
        <DisconnectButtonText>Disconnect Spinwheel</DisconnectButtonText>
      </DefaultButton>
    ) : (
      <></>
    );

  const onBack = () => {
    if (currentStep === MapSpinwheelLiabilitiesSteps.MapAccounts) {
      setCurrentStep(MapSpinwheelLiabilitiesSteps.Start);
    }

    if (currentStep === MapSpinwheelLiabilitiesSteps.Success) {
      setCurrentStep(MapSpinwheelLiabilitiesSteps.MapAccounts);
    }
  };

  return (
    <StyledPage
      name="Map Spinwheel Liabilities"
      isLoading={isLoadingCreditReport}
      header={
        <QuestionnaireHeader
          showBackButton={shouldShowBack}
          showCancelButton={shouldShowCancel}
          cancelButtonOnRight
          onClickCancelButton={() => {
            history.push(routes.recurring());
          }}
          onClickBackButton={onBack}
          progress={Math.max(MIN_DISPLAY_PROGRESS, progress)}
          rightButtons={
            <DisconnectButton visible={shouldShowDisconnect} onClick={openConnectionModal} />
          }
        />
      }
    >
      <Container>
        {errorMessage && <Banner type="error">{errorMessage}</Banner>}
        <Switch>
          <Case when={currentStep === MapSpinwheelLiabilitiesSteps.Start}>
            <MapSpinwheelLiabilitiesStart onClickNext={syncCreditReport} />
          </Case>
          <Case when={currentStep === MapSpinwheelLiabilitiesSteps.MapAccounts}>
            <MapSpinwheelLiabilitiesAccounts
              creditReportLiabilityAccounts={creditReportLiabilityAccounts ?? []}
              onSubmit={onSubmitAccountsMap}
            />
            <ConnectionModal>
              <SpinwheelConnectionFlow skipConnectionModal onComplete={closeConnectionModal} />
            </ConnectionModal>
          </Case>
          <Case when={currentStep === MapSpinwheelLiabilitiesSteps.Success}>
            <MapSpinwheelLiabilitiesSuccess />
          </Case>
        </Switch>
      </Container>
    </StyledPage>
  );
};

export default MapSpinwheelLiabilities;
