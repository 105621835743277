import _ from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getTokenExpirationDate } from 'common/state/user/selectors';
import { useDispatch } from 'lib/hooks';
import useEventListener from 'lib/hooks/useEventListener';
import { requestExtendToken } from 'state/user/thunks';

import { Tuple } from 'common/types';

// How often to possibly extend the token
const EXTEND_TOKEN_THROTTLE_SECONDS = 60;

const useTokenExtender = (isActive: boolean) => {
  const tokenExpiration = useSelector(getTokenExpirationDate);
  const dispatch = useDispatch();

  // Extend a user's token.
  const extendToken = (forceExtend?: boolean) => {
    if (forceExtend || isActive) {
      // Only extend a token if it has an expiration
      if (tokenExpiration) {
        dispatch(requestExtendToken());
      }
    }
  };

  const throttledExtendToken = useCallback(
    _.throttle(
      () => {
        isActive && extendToken();
      },
      EXTEND_TOKEN_THROTTLE_SECONDS * 1000,
      {
        leading: true,
        trailing: true,
      },
    ),
    [isActive],
  );
  useEventListener(document, 'mousemove', throttledExtendToken);
  useEventListener(document, 'load', throttledExtendToken);
  useEventListener(document, 'click', throttledExtendToken);

  return Tuple(tokenExpiration, extendToken);
};

export default useTokenExtender;
