import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { Suspense } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import WithIndicatorContainer from 'components/lib/ui/WithIndicatorContainer';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';

import { spacing } from 'common/lib/theme/dynamic';

const TransactionSearchPopover = React.lazy(
  () => import('components/transactions/header/TransactionSearchPopover'),
);

const SearchIcon = styled(Icon).attrs({ name: 'search', size: 14 })`
  margin-top: ${spacing.xxxsmall};
  margin-right: ${spacing.xsmall};
`;

const Fallback = styled(FlexContainer).attrs({ center: true })`
  width: 430px;
  height: 200px;
`;

type Props = {
  search: Maybe<string>;
  onChangeSearch: (search: Maybe<string>) => void;
};

const TransactionSearchButton: React.FC<Props> = ({ search, onChangeSearch }) => (
  <OverlayTrigger
    placement="bottom-end"
    overlay={({ toggleOpen }) => (
      <Popover>
        <Suspense
          fallback={
            <Fallback>
              <LoadingSpinner />
            </Fallback>
          }
        >
          <TransactionSearchPopover
            toggleOpen={toggleOpen}
            initialValue={search}
            onChangeSearch={onChangeSearch}
          />
        </Suspense>
      </Popover>
    )}
  >
    {({ toggleOpen, isOpen }) => (
      <WithIndicatorContainer show={isNotNilOrEmpty(search)}>
        <DefaultButton onClick={toggleOpen} active={isOpen}>
          <SearchIcon />
          <span>{search ? `"${search}"` : 'Search'}</span>
        </DefaultButton>
      </WithIndicatorContainer>
    )}
  </OverlayTrigger>
);

export default React.memo(TransactionSearchButton);
