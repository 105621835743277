import * as RA from 'ramda-adjunct';
import type React from 'react';
import type { ToastOptions } from 'react-hot-toast';
import { toast as _toast } from 'react-hot-toast';

import launchZendesk from 'components/lib/external/launchZendesk';

import { MS_PER_SECOND } from 'common/constants/time';

type ToastAction = {
  text: React.ReactNode;
  onClick: () => void | Promise<unknown>;
  dismissOnClick?: boolean;
};

export type ExtendedToastOptions = {
  title: string | React.ReactNode;
  description?: string;
  actions?: ToastAction[];
  dismissable?: boolean;
  accessory?: React.ReactNode;
};

export type Options = ToastOptions & ExtendedToastOptions;

const ERROR_TOAST_DURATION_MS = 6 * MS_PER_SECOND;

/** Wrapper around react-hot-toast's handler so we can extend it with our own options and
 * customize on the component. For more info, search for CustomToaster. */
const toast = (options: Options) => _toast('', options);

/**
 * Helper to show a toast with a loading message, and then update it with
 * a success or error message.
 * @param promise - promise to await
 * @param messages - object with loading, success and error messages
 * @param options - options to pass to the toast
 */
export const toastPromise = <TResult>(
  promise: Promise<TResult>,
  messages: {
    loading: ExtendedToastOptions;
    success: ExtendedToastOptions;
    error: ExtendedToastOptions;
  },
) => {
  const { loading, success, error } = messages;
  const toastId = toast({ ...loading, dismissable: false });

  promise
    .then((res) => {
      toast({
        id: toastId,
        dismissable: true,
        ...success,
        title: RA.isFunction(success) ? success(res) : success,
      });
      return res;
    })
    .catch((e) =>
      toast({
        id: toastId,
        dismissable: true,
        ...error,
        title: RA.isFunction(error) ? error(e) : error,
      }),
    );

  return promise;
};

export const errorToast = (errorMessage?: Maybe<string>, options?: Partial<Options>) => {
  toast({
    title: 'Sorry, something went wrong',
    description: errorMessage || 'Please, try again or report this issue',
    duration: ERROR_TOAST_DURATION_MS,
    actions: [
      {
        text: 'Report',
        onClick: () => launchZendesk(),
      },
    ],
    ...options,
  });
};

export const dismissToast = (id: string | undefined) => _toast.dismiss(id);

export default toast;
