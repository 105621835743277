/**
 * Returns an array with each item typed explicitly.
 */
function Tuple<A, B>(a: A, b: B): [A, B];
function Tuple<A, B, C>(a: A, b: B, c: C): [A, B, C];
function Tuple<A, B, C, D>(a: A, b: B, c: C, d: D): [A, B, C, D];
function Tuple<A, B, C, D, E>(a: A, b: B, c: C, d: D, e: E): [A, B, C, D, E];
function Tuple<A, B, C, D, E, F>(a: A, b: B, c: C, d: D, e: E, f: F): [A, B, C, D, E, F];
function Tuple(...params: any[]) {
  return params;
}

export default Tuple;
