import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';

import type {
  InstatusActiveIncident,
  InstatusActiveMaintenance,
} from 'common/lib/external/instatus';
import instatus from 'common/lib/external/instatus';
import useLoading from 'common/lib/hooks/useLoading';
import noop from 'common/utils/noop';

const SHOW_MAINTENANCE_BEFORE_IN_MINUTES = 60;
const USE_TEST_DATA =
  process.env.NODE_ENV === 'development' &&
  // Set this to false to disable the test data
  true;

export type AppStatus = {
  shouldShowStatus: boolean;
  loading: boolean;
  message?: string;
  link?: string;
  type?: AppStatusType;
  refetch: () => void;
};

enum AppStatusType {
  INCIDENT = 'INCIDENT',
  MAINTENANCE = 'MAINTENANCE',
}

const TEST_APP_STATUS: AppStatus = {
  shouldShowStatus: true,
  loading: false,
  message: "Test message that's only enabled in development mode.",
  link: 'https://status.monarchmoney.com',
  type: AppStatusType.INCIDENT,
  refetch: noop,
};

/**
 * Hook used to get current app status and possible incident or maintenance messages
 * */
const useAppStatus = (): AppStatus => {
  const [activeIncidents, setActiveIncidents] = useState<InstatusActiveIncident[]>([]);
  const [activeMaintenances, setActiveMaintenances] = useState<InstatusActiveMaintenance[]>([]);

  const [loading, fetchAppStatus] = useLoading(async () => {
    const instatusResponse = await instatus.get();

    setActiveIncidents(instatusResponse?.activeIncidents || []);
    setActiveMaintenances(instatusResponse?.activeMaintenances || []);
  });

  const getType = (hasActiveIncidents: boolean, hasActiveMaintenances: boolean) => {
    if (hasActiveIncidents) {
      return AppStatusType.INCIDENT;
    }
    if (hasActiveMaintenances) {
      return AppStatusType.MAINTENANCE;
    }
  };

  // gets the first maintenance and check if it has already started or
  // will happen in the next SHOW_MAINTENANCE_BEFORE_IN_MINUTES
  const nextMaintenance = useMemo(() => {
    const firstMaintenance = activeMaintenances?.[0];

    if (firstMaintenance) {
      const start = new Date(firstMaintenance.start);
      const now = new Date();
      const diff = start.getTime() - now.getTime();

      return diff <= SHOW_MAINTENANCE_BEFORE_IN_MINUTES * 60 * 1000 ? firstMaintenance : undefined;
    }
  }, [activeMaintenances]);

  const getLink = (type?: AppStatusType) => {
    if (type === AppStatusType.INCIDENT) {
      return currentIncident?.url;
    }
    if (type === AppStatusType.MAINTENANCE) {
      return nextMaintenance?.url;
    }
  };

  const getMessage = (
    type: AppStatusType | undefined,
    currentIncident: InstatusActiveIncident | undefined,
    nextMaintenance: InstatusActiveMaintenance | undefined,
  ) => {
    if (type === AppStatusType.INCIDENT) {
      return currentIncident?.name;
    }
    if (type === AppStatusType.MAINTENANCE) {
      // returns the maintenance name with the date, time and duration
      if (nextMaintenance?.start) {
        const dateTime = DateTime.fromISO(nextMaintenance.start.toString(), { zone: 'utc' });

        return `${nextMaintenance?.name} (${dateTime.toLocaleString(DateTime.DATETIME_FULL)} for ${
          nextMaintenance?.duration
        } minutes)`;
      }
    }
  };

  // gets current active incident
  const currentIncident = activeIncidents?.[0];

  // returns True if there is an active incident
  const hasActiveIncidents = !!currentIncident;

  // returns True if there is an active maintenance happening now or in the next 60 minutes
  const hasActiveMaintenances = !!nextMaintenance;

  const shouldShowStatus = hasActiveIncidents || hasActiveMaintenances;

  const type = getType(hasActiveIncidents, hasActiveMaintenances);

  const link = getLink(type);

  const message = getMessage(type, currentIncident, nextMaintenance);

  useEffect(() => {
    fetchAppStatus();
  }, []);

  if (USE_TEST_DATA) {
    return TEST_APP_STATUS;
  }

  return {
    shouldShowStatus,
    loading,
    link,
    message,
    type,
    refetch: fetchAppStatus,
  };
};

export default useAppStatus;
