import React from 'react';
import styled from 'styled-components';

import IconButton from 'components/lib/ui/button/IconButton';

import useEventCallback from 'common/lib/hooks/useEventCallback';
import { spacing } from 'common/lib/theme/dynamic';
import { useSideDrawerContext } from 'lib/contexts/SideDrawerContext';
import useGlobalKey from 'lib/hooks/useGlobalKey';

const Root = styled.div`
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  gap: ${spacing.default};
`;

type Props = {
  previousTransactionId: GraphQlUUID | null;
  nextTransactionId: GraphQlUUID | null;
  setTransactionId: (id: GraphQlUUID) => void;
};

const TransactionDrawerKeyboardControls = ({
  previousTransactionId,
  nextTransactionId,
  setTransactionId,
}: Props) => {
  const { close: closeDrawer } = useSideDrawerContext();

  const onUp = useEventCallback(() => {
    previousTransactionId && setTransactionId(previousTransactionId);
  });

  const onDown = useEventCallback(() => {
    nextTransactionId && setTransactionId(nextTransactionId);
  });

  useGlobalKey('escape', closeDrawer);
  useGlobalKey('up', (e) => {
    e.preventDefault();
    onUp();
  });
  useGlobalKey('down', (e) => {
    e.preventDefault();
    onDown();
  });

  return (
    <Root>
      <IconButton
        icon="arrow-up"
        size="large"
        bordered
        onClick={onUp}
        disabled={!previousTransactionId}
      />
      <IconButton
        icon="arrow-down"
        size="large"
        bordered
        onClick={onDown}
        disabled={!nextTransactionId}
      />
    </Root>
  );
};

export default TransactionDrawerKeyboardControls;
