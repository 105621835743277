import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';

import { spacing, radius, transition } from 'common/lib/theme/dynamic';

const Dot = styled.div<{ isActive: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: ${radius.round};
  background: ${({ isActive, theme }) => (isActive ? theme.color.black : theme.color.gray)};
  transition: ${transition.default};
`;

export const ClickableDot = styled.div`
  padding: ${spacing.xxxsmall};
  margin-right: ${spacing.xxsmall};
  cursor: pointer;
`;

type Props = {
  className?: string;
  count: number;
  currentIndex: number;
  onChangeIndex?: (index: number) => void;
};

const PageIndicatorDots = ({ className, count, currentIndex, onChangeIndex }: Props) => (
  <FlexContainer className={className}>
    {R.range(0, count).map((index) => (
      <ClickableDot key={index} onClick={() => onChangeIndex?.(index)}>
        <Dot key={index} isActive={index === currentIndex} />
      </ClickableDot>
    ))}
  </FlexContainer>
);

export default PageIndicatorDots;
