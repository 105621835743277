import { useQuery } from '@apollo/client';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import CategorySelect from 'components/categories/FullHeightCategorySelect';
import Confirmation from 'components/lib/ui/Confirmation';

import { color, fontWeight, spacing } from 'common/lib/theme/dynamic';

import routes from 'constants/routes';

import { gql } from 'common/generated/gql';

type Props = {
  categoryId: string;
  onCancel: () => void;
  disableOrDeleteCategory: (categoryId: string, moveToCategoryId?: string) => void;
};

const Root = styled.div``;

const Paragraph = styled.div`
  &:not(:last-child) {
    margin-bottom: ${spacing.default};
  }
`;

const Bold = styled.span`
  font-weight: ${fontWeight.medium};
`;

const ErrorMessage = styled.div`
  color: ${color.red};
  padding-bottom: ${spacing.default};
`;

const StyledLink = styled(Link)`
  && {
    color: ${color.blue};
  }
`;

const DeleteOrDisableCategoryConfirmation = ({
  categoryId,
  onCancel,
  disableOrDeleteCategory,
}: Props) => {
  const [moveToCategoryId, setMoveToCategoryId] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const { data } = useQuery(QUERY, {
    variables: { id: categoryId },
  });

  if (!data || !data.categoryDeletionInfo || !data.category) {
    return null;
  }

  const { category, categoryDeletionInfo } = data;
  const { id, isSystemCategory } = category;
  const { transactionsCount, rulesCount } = categoryDeletionInfo;

  const shouldSelectMoveToCategory = transactionsCount > 0 || rulesCount > 0;
  const clickButton = () => {
    if (shouldSelectMoveToCategory) {
      if (!moveToCategoryId) {
        setErrorMessage('Please select a category to move transactions and rules to.');
      } else {
        disableOrDeleteCategory(id, moveToCategoryId);
      }
    } else {
      disableOrDeleteCategory(id);
    }
  };

  const deleteOrDisableText = isSystemCategory ? 'Disable Category' : 'Delete Category';
  return (
    <Confirmation
      title={deleteOrDisableText}
      confirm={deleteOrDisableText}
      onCancel={onCancel}
      onConfirm={clickButton}
      useDangerButton
    >
      <Root>
        <Paragraph>
          You will no longer see{' '}
          <Bold>
            {category.icon} {category.name}
          </Bold>{' '}
          as a category anywhere in our app, including budgets, cash flow, or transactions.
        </Paragraph>
        {shouldSelectMoveToCategory && (
          <>
            <Paragraph>
              You have{' '}
              <Bold>
                <StyledLink to={routes.transactions({ queryParams: { categories: [id] } })}>
                  {pluralize('transaction', transactionsCount, true)}
                </StyledLink>
              </Bold>{' '}
              and <Bold>{pluralize('rule', rulesCount, true)}</Bold> for {category.icon}{' '}
              {category.name}. Existing transactions and rules will be assigned to the category you
              select below.{' '}
            </Paragraph>
            <Paragraph>
              <Bold>Where should we reassign these transactions and rules?</Bold>
            </Paragraph>
            {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <CategorySelect
              value={moveToCategoryId}
              alwaysShowBorder
              filters={{ excludeCategories: [id] }}
              onChange={(_, action, [category]) => setMoveToCategoryId(category.id)}
              fullWidthTrigger
              isCreatable
            />
          </>
        )}
      </Root>
    </Confirmation>
  );
};

const QUERY = gql(`
  query CategoryDeletionInfo($id: UUID!) {
    categoryDeletionInfo(id: $id) {
      transactionsCount
      rulesCount
    }
    category(id: $id) {
      id
      name
      icon
      isSystemCategory
    }
  }
`);

export default DeleteOrDisableCategoryConfirmation;
