import { camelCase, sentenceCase } from 'change-case';
import { ascend, head, pathEq, prop, sort } from 'ramda';
import { isNumber } from 'ramda-adjunct';

import type { FilterMenuOption, WithPath } from 'components/reports/filters/types';

import { ANYONE_ID } from 'common/lib/hooks/household/useHouseholdUsers';
import { formatCurrencyCentsOptional } from 'common/utils/Currency';
import { lowerFirstWordOnly } from 'common/utils/String';
import type { IsSelectedFn, MakeRadioFilterOptions } from 'lib/filters/types';
import { AmountFilter } from 'lib/filters/types';

import type { InputMaybe, Web_TransactionsFilterQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';
import type { TransactionFilters } from 'types/filters';

const NOT_PREFIX = 'not:';

export const sortByOrder = sort<any>(ascend(prop('order')));

/**
 * Creates a factory function to generate a radio filter menu option object for reports filters.
 *
 * The function accepts a `filters` object, which contains the current state of the filters.
 * It returns a function that takes an options object and returns a `FilterMenuOption` object.
 */
export const radioFilterFactory =
  (filters: Partial<TransactionFilters> | undefined) =>
  ({
    label,
    optionLabel = sentenceCase(label),
    negativeOptionLabel = `Not ${lowerFirstWordOnly(optionLabel)}`,
    filterPath,
    hide = false,
    inverse = false,
  }: MakeRadioFilterOptions): FilterMenuOption | undefined =>
    !hide
      ? {
          id: head(filterPath) as string,
          name: label,
          type: 'radio',
          options: [
            {
              id: camelCase(label),
              name: optionLabel,
              isSelected: pathEq(filterPath, !inverse, filters || {}),
              extra: { inverse },
            },
            {
              id: `${NOT_PREFIX}${camelCase(label)}`,
              name: negativeOptionLabel,
              isSelected: pathEq(filterPath, inverse, filters || {}),
              extra: { inverse },
            },
          ],
        }
      : undefined;

export const makeFilterFromRadioOption = (
  option: WithPath<FilterMenuOption>,
): Partial<TransactionFilters> => {
  const { path } = option;
  const [, filter, value] = path as ['other', keyof TransactionFilters, string];

  if (filter === 'needsReview') {
    return value === ANYONE_ID
      ? { needsReview: true, needsReviewByUser: undefined, needsReviewUnassigned: true }
      : { needsReview: true, needsReviewByUser: value, needsReviewUnassigned: undefined };
  }

  return {
    [filter]: getIsRadioOptionSelected(option),
  };
};

export const getIsRadioOptionSelected = (option: WithPath<FilterMenuOption>) => {
  const { path, extra = {} } = option;
  const [, , value] = path as ['other', keyof TransactionFilters, string];

  // This is pretty hacky, but it works without changing the structure of the filter options too much
  return extra.inverse ? value.startsWith(NOT_PREFIX) : !value.startsWith(NOT_PREFIX);
};

export const makeAmountOptions = (existingFilters?: Partial<TransactionFilters>) => {
  const { absAmountLte, absAmountGte, amountFilter } = existingFilters || {};

  const result = [
    {
      id: AmountFilter.GreaterThan,
      name: 'Greater than...',
      inputs: [
        { type: 'currency', name: 'amount', placeholder: '$10', defaultValue: absAmountGte },
      ],
      isSelected: amountFilter === AmountFilter.GreaterThan,
      formatLabel: ({ amount = absAmountGte }) =>
        isNumber(amount) ? `Greater than ${formatCurrencyCentsOptional(amount)}` : undefined,
    },
    {
      id: AmountFilter.LessThan,
      name: 'Less than...',
      inputs: [
        { type: 'currency', name: 'amount', placeholder: '$10', defaultValue: absAmountLte },
      ],
      isSelected: amountFilter === AmountFilter.LessThan,
      formatLabel: ({ amount = absAmountLte }) =>
        isNumber(amount) ? `Less than ${formatCurrencyCentsOptional(amount)}` : undefined,
    },
    {
      id: AmountFilter.EqualTo,
      name: 'Equal to...',
      inputs: [
        { type: 'currency', name: 'amount', placeholder: '$10', defaultValue: absAmountGte },
      ],
      isSelected: amountFilter === AmountFilter.EqualTo,
      formatLabel: ({ amount = absAmountGte }) =>
        isNumber(amount) ? `Equal to ${formatCurrencyCentsOptional(amount)}` : undefined,
    },
    {
      id: AmountFilter.Between,
      name: 'Between...',
      isSelected: amountFilter === AmountFilter.Between,
      inputs: [
        {
          type: 'currency',
          name: 'min',
          placeholder: '$0',
          defaultValue: absAmountGte,
        },
        {
          type: 'currency',
          name: 'max',
          placeholder: '$100',
          defaultValue: absAmountLte,
        },
      ],
      formatLabel: ({
        min: greaterThanOrEqual = absAmountGte,
        max: lessThanOrEqual = absAmountLte,
      }) =>
        isNumber(greaterThanOrEqual) && isNumber(lessThanOrEqual)
          ? `Between ${formatCurrencyCentsOptional(
              greaterThanOrEqual,
            )} and ${formatCurrencyCentsOptional(lessThanOrEqual)}`
          : undefined,
    },
  ];

  // Augment the result with the predefined inputValues, if it's selected
  return result.map((option) => {
    if (!option.isSelected) {
      return option;
    }

    const values = option.inputs?.reduce(
      (acc, input) => ({ ...acc, [input.name]: input.defaultValue }),
      {},
    );
    return { ...option, inputValues: values };
  });
};

export const generateAmountFilterFromOption = (
  option: FilterMenuOption,
): Partial<
  Pick<
    TransactionFilters,
    'absAmountGte' | 'absAmountLte' | 'amountFilter' | 'debitsOnly' | 'creditsOnly'
  >
> => {
  const { inputValues, id } = option;
  const amountFilter = id as AmountFilter;

  switch (amountFilter) {
    case AmountFilter.GreaterThan:
      return { amountFilter, absAmountGte: inputValues?.amount };
    case AmountFilter.LessThan:
      return { amountFilter, absAmountLte: inputValues?.amount };
    case AmountFilter.EqualTo:
      return {
        amountFilter,
        absAmountGte: inputValues?.amount,
        absAmountLte: inputValues?.amount,
      };
    case AmountFilter.Between:
      return {
        amountFilter,
        absAmountGte: inputValues?.min,
        absAmountLte: inputValues?.max,
      };
    case AmountFilter.DebitsOnly:
      return { debitsOnly: true };
    case AmountFilter.CreditsOnly:
      return { creditsOnly: true };
    default:
      return {};
  }
};

export const transformCategory = (
  category: ElementOf<ElementOf<Web_TransactionsFilterQuery, 'categoryGroups'>, 'categories'>,
  isCategorySelected: IsSelectedFn,
) => ({
  id: category.id,
  name: `${category.icon} ${category.name}`,
  isSelected: isCategorySelected(category.id),
});

export const makeIsSelected = (existing: InputMaybe<string[]>) => (id: string) => {
  if (!existing) {
    return false;
  }

  return existing.includes(id);
};
