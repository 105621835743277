import React from 'react';

import FlexContainer from 'components/lib/ui/FlexContainer';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

type Props = {
  onAddClients: () => Promise<void> | void;
};

const AdvisorPortalClientsHeaderControls = ({ onAddClients }: Props) => (
  <FlexContainer alignCenter justifyEnd>
    <PrimaryButton onClick={onAddClients}>
      <ButtonIcon name="plus" />
      <span>Add clients</span>
    </PrimaryButton>
  </FlexContainer>
);

export default AdvisorPortalClientsHeaderControls;
