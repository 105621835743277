import React from 'react';
import type { HeaderProps } from 'react-big-calendar';

import Text from 'components/lib/ui/Text';

const Header = ({ date }: HeaderProps) => (
  <Text color="textLight">{date.toLocaleString('en-US', { weekday: 'long' })}</Text>
);

export default Header;
