import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';

const SettingsSection = styled(Flex).attrs({ justifyBetween: true, alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

export default SettingsSection;
