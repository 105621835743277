import { gql } from '@apollo/client';
import { debounce } from 'lodash';
import { Duration } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useCallback } from 'react';

import useQueryWithCacheExpiration from 'common/lib/hooks/useQueryWithCacheExpiration';

import type {
  GetContactSupportFormRecommendedResolution,
  GetContactSupportFormRecommendedResolutionVariables,
} from 'common/generated/graphQlTypes/GetContactSupportFormRecommendedResolution';
import type { ContactSupportReason } from 'common/generated/graphQlTypes/globalTypes';
import type { ResolutionBlocks } from 'common/types/support';

const QUERY_CACHE_EXPIRATION = Duration.fromObject({ minutes: 10 });
const PREFETCH_DEBOUNCE_MS = 300;

type Options = {
  reason: string | undefined;
  credentialId: string | undefined;
};

/**
 * Load the recommended resolution links for the given reason and credentialId.
 * Should be used in the contact support form context.
 *
 * Note: Do not constraint the `reason` to the ContactSupportReason enum, as the values
 * might change on the backend but the frontend might not be updated yet.
 */
const useRecommendedResolution = ({ reason, credentialId }: Options) => {
  const { data, isLoadingInitialData, client } = useQueryWithCacheExpiration<
    GetContactSupportFormRecommendedResolution,
    GetContactSupportFormRecommendedResolutionVariables
  >(GET_SUPPORT_FORM_RECOMMENDED_RESOLUTION, {
    // Don't allow empty strings to be passed as variables
    variables: {
      // Make sure to cast here instead of using `ensureEnumValue`. See note above.
      reason: (reason as ContactSupportReason) || undefined,
      credentialId: credentialId || undefined,
    },
    cacheExpiration: QUERY_CACHE_EXPIRATION,
    skip: RA.isNilOrEmpty(reason),
  });

  const { recommendedResolutionLinks } = data || {};
  const { hasResults, ...blocks } = recommendedResolutionLinks || {};

  // Prefetch the links for the hovered reason and credentialId.
  // Debounce the prefetch to avoid unnecessary requests.
  const prefetchRecommendedResolution = useCallback(
    ({
      credentialId: hoveredCredentialId,
      reason: hoveredReason,
    }: Partial<Pick<Options, 'reason' | 'credentialId'>>) => {
      if (R.all(RA.isNilOrEmpty, [hoveredCredentialId, hoveredReason])) {
        return;
      }

      client.query({
        query: GET_SUPPORT_FORM_RECOMMENDED_RESOLUTION,
        variables: {
          // If one of the variables isn't provided, just use the current form value
          reason: hoveredReason || reason,
          credentialId: hoveredCredentialId || credentialId,
        },
        fetchPolicy: 'cache-first',
      });
    },
    [client, credentialId, reason],
  );

  return {
    hasResults,
    resolutionBlocks: blocks as ResolutionBlocks,
    prefetchRecommendedResolution: debounce(prefetchRecommendedResolution, PREFETCH_DEBOUNCE_MS),
    isLoadingRecommendedResolution: isLoadingInitialData,
  };
};

const GET_SUPPORT_FORM_RECOMMENDED_RESOLUTION = gql`
  query GetContactSupportFormRecommendedResolution(
    $reason: ContactSupportReason
    $credentialId: String
  ) {
    recommendedResolutionLinks(reason: $reason, credentialId: $credentialId) {
      hasResults
      messages {
        __typename
        appearance
        title
        text
      }
      links {
        __typename
        text
        linkTo
        url
      }
      buttons {
        __typename
        text
        linkTo
        url
      }
    }
  }
`;

export default useRecommendedResolution;
