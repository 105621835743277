import { useMutation } from '@apollo/client';
import * as RA from 'ramda-adjunct';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import ReconnectCredentialPlaidLink from 'components/accounts/ReconnectCredentialPlaidLink';
import type { InstitutionFinicityConnect } from 'components/lib/external/FinicityConnect';
import FinicityConnect from 'components/lib/external/FinicityConnect';
import MXConnect from 'components/lib/external/MXConnect';
import EventPropagationBoundary from 'components/lib/higherOrder/EventPropagationBoundary';
import Modal from 'components/lib/ui/Modal';
import ManualLink from 'components/lib/ui/link/ManualLink';

import { UPDATE_CREDENTIAL_MUTATION } from 'common/lib/graphQl/credentials';
import { CREATE_FINICITY_CONNECT_FIX_URL_MUTATION } from 'common/lib/graphQl/finicity';
import { CREATE_MX_CONNECT_FIX_URL_MUTATION } from 'common/lib/graphQl/mx';
import { CREATE_PLAID_LINK_TOKEN_MUTATION } from 'common/lib/graphQl/plaid';
import { getPlaidLinkRedirectUri } from 'lib/external/plaid';
import useCoinbaseOAuthFlow from 'lib/hooks/accounts/useCoinbaseOAuthFlow';
import useTheme from 'lib/hooks/useTheme';

import routes from 'constants/routes';

import type {
  CreateFinicityConnectFixUrlMutation,
  CreateFinicityConnectFixUrlMutationVariables,
} from 'common/generated/graphQlTypes/CreateFinicityConnectFixUrlMutation';
import type {
  CreateMXConnectFixUrlMutation,
  CreateMXConnectFixUrlMutationVariables,
} from 'common/generated/graphQlTypes/CreateMXConnectFixUrlMutation';
import type {
  CreatePlaidLinkTokenMutation,
  CreatePlaidLinkTokenMutationVariables,
} from 'common/generated/graphQlTypes/CreatePlaidLinkTokenMutation';
import type {
  UpdateCredential,
  UpdateCredentialVariables,
} from 'common/generated/graphQlTypes/UpdateCredential';
import { DataProviderLegacy } from 'common/generated/graphql';

type Props = {
  credential: {
    id: string;
    dataProvider: DataProviderLegacy;
  };
  className?: string;
  renderContent?: (onClick: () => void) => React.ReactNode;
  institution: InstitutionFinicityConnect;
};

const StyledLink = styled(ManualLink)`
  transition: inherit;
`;

const ReconnectCredentialButton = ({
  credential,
  className,
  renderContent,
  institution,
}: Props) => {
  const { dataProvider, id: credentialId } = credential;

  const theme = useTheme();

  const [updateCredential] = useMutation<UpdateCredential, UpdateCredentialVariables>(
    UPDATE_CREDENTIAL_MUTATION,
  );

  const [createLinkToken] = useMutation<
    CreatePlaidLinkTokenMutation,
    CreatePlaidLinkTokenMutationVariables
  >(CREATE_PLAID_LINK_TOKEN_MUTATION);

  const [createFinicityConnectFixUrl] = useMutation<
    CreateFinicityConnectFixUrlMutation,
    CreateFinicityConnectFixUrlMutationVariables
  >(CREATE_FINICITY_CONNECT_FIX_URL_MUTATION);

  const [createMxConnectFixUrl] = useMutation<
    CreateMXConnectFixUrlMutation,
    CreateMXConnectFixUrlMutationVariables
  >(CREATE_MX_CONNECT_FIX_URL_MUTATION);

  const { startCoinbaseOAuthFlow } = useCoinbaseOAuthFlow();

  const [reconnectUrlOrToken, setReconnectUrlOrToken] = useState<Maybe<string>>();
  const clearReconnectUrlOrToken = useCallback(() => setReconnectUrlOrToken(null), []);
  const onSuccess = useCallback(() => {
    updateCredential({
      variables: {
        input: { id: credentialId, updateRequired: false },
      },
    });
  }, [updateCredential, credentialId]);

  const reconnectCredentialMutation = async () => {
    if (dataProvider === DataProviderLegacy.FINICITY) {
      const result = await createFinicityConnectFixUrl({
        variables: {
          credentialId,
        },
      });
      const url = result.data?.createFinicityConnectFixUrl?.url;
      setReconnectUrlOrToken(url);
    } else if (dataProvider === DataProviderLegacy.MX) {
      const result = await createMxConnectFixUrl({
        variables: {
          credentialId,
          isDarkMode: theme.uiTheme === 'dark',
        },
      });
      const url = result.data?.createMxConnectFixUrl?.url;
      setReconnectUrlOrToken(url);
    } else if (dataProvider === DataProviderLegacy.COINBASE) {
      await startCoinbaseOAuthFlow({
        credentialId,
        onSuccessRedirectTo: routes.settings.institutions.path,
      });
    } else {
      const result = await createLinkToken({
        variables: { credentialId, redirectUri: getPlaidLinkRedirectUri() },
      });
      const linkToken = result.data?.createPlaidLinkToken?.linkToken;
      setReconnectUrlOrToken(linkToken);
    }
  };

  const onExit = (close: () => void) => {
    clearReconnectUrlOrToken();
    close();
  };

  return (
    <EventPropagationBoundary className={className} onClick preventDefault>
      {RA.isNotNil(renderContent) ? (
        renderContent(reconnectCredentialMutation)
      ) : (
        <StyledLink onClick={reconnectCredentialMutation}>Reconnect</StyledLink>
      )}
      {RA.isNotNil(reconnectUrlOrToken) && (
        <Switch>
          <Case when={dataProvider === DataProviderLegacy.PLAID}>
            <ReconnectCredentialPlaidLink
              token={reconnectUrlOrToken}
              credentialId={credential.id}
              onClose={clearReconnectUrlOrToken}
            />
          </Case>
          <Case when={dataProvider === DataProviderLegacy.FINICITY}>
            <Modal nonDismissable>
              {({ close }) => (
                <FinicityConnect
                  institution={institution}
                  connectUrl={reconnectUrlOrToken}
                  onClose={() => onExit(close)}
                  onSuccess={() => {
                    onSuccess();
                    onExit(close);
                  }}
                />
              )}
            </Modal>
          </Case>
          <Case when={dataProvider === DataProviderLegacy.MX}>
            <Modal nonDismissable>
              {({ close }) => (
                <MXConnect
                  connectUrl={reconnectUrlOrToken}
                  onClose={() => onExit(close)}
                  onSuccess={() => {
                    onSuccess();
                    onExit(close);
                  }}
                />
              )}
            </Modal>
          </Case>
        </Switch>
      )}
    </EventPropagationBoundary>
  );
};

export default ReconnectCredentialButton;
