import { useMutation } from '@apollo/client';
import { assocPath, indexBy, pathOr, prop } from 'ramda';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import FooterButton, { BUTTON_MAX_WIDTH_PX } from 'components/plan/onboarding/FooterButton';
import type { BudgetedRowData } from 'components/plan/onboarding/OnboardingBudgetCardRow';
import OnboardingNonMonthlyCard from 'components/plan/onboarding/OnboardingNonMonthlyCard';
import OnboardingNonMonthlyGroupFooter from 'components/plan/onboarding/OnboardingNonMonthlyGroupFooter';
import OnboardingNonMonthlyLeftToBudgetFooter from 'components/plan/onboarding/OnboardingNonMonthlyLeftToBudgetFooter';
import BlockNavigationPrompt from 'components/utils/BlockNavigationPrompt';

import {
  getBudgetedAmountForType,
  getAccumulatedAmountsMap,
  getBudgetVariabilityName,
} from 'common/lib/budget/Adapters';
import { spacing } from 'common/lib/theme/dynamic';
import { getFlexGroupDataWithBudgetedAmounts } from 'lib/budget/onboardingAdapters';
import usePlanAdapter from 'lib/hooks/plan/usePlanAdapter';
import usePlanQuery from 'lib/hooks/plan/usePlanQuery';
import usePlanState from 'lib/hooks/plan/usePlanState';

import { BudgetRolloverPeriodType } from 'common/constants/budget';
import { BEFORE_UNLOAD_MESSAGE } from 'common/constants/copy';

import { gql } from 'common/generated/gql';
import { BudgetVariability, CategoryGroupType } from 'common/generated/graphql';

const BUDGET_VARIABILITY = BudgetVariability.NON_MONTHLY;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${spacing.xlarge};
`;

const Container = styled(FlexContainer).attrs({ center: true, column: true })`
  margin-bottom: 120px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${({ theme }) => theme.spacing.xsmall} 0;
  background: ${({ theme }) => theme.color.grayFocus};
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const StyledFormSubmitButton = styled(FormSubmitButton)`
  max-width: ${BUTTON_MAX_WIDTH_PX}px;
  margin-top: 0;
`;

export type Field = {
  id: string;
  rolloverFrequency: Maybe<string>;
  rolloverTargetAmount: Maybe<number>;
  monthlyBudget: Maybe<number>;
  appliedToFuture: boolean;
};

export type FormValues = Record<string, Field>;

type Props = {
  title: string;
  description: string;
  progress: number;
  pageName: string;
  onBack: () => void;
  onCancel: () => void;
  onNext: () => void;
};

const OnboardingNonMonthly = ({
  title,
  description,
  pageName,
  progress,
  onBack,
  onCancel,
  onNext,
}: Props) => {
  const [state] = usePlanState();
  const { data, fetchedDateRange } = usePlanQuery(state);
  const { isLoadingInitialData, gridDisplayData, gridAmounts, budgetSummaryData } = usePlanAdapter(
    data,
    state,
    fetchedDateRange,
  );

  // gridDisplayData has the presentational info that we need, but gridAmounts has the budgeted amounts.
  const expenseDataWithAmountsForVariability = getFlexGroupDataWithBudgetedAmounts(
    gridDisplayData,
    gridAmounts,
    BUDGET_VARIABILITY,
    state.thisMonth,
  );

  const incomeAmount = getBudgetedAmountForType(budgetSummaryData, CategoryGroupType.INCOME);
  const accumulatedAmountsMap = getAccumulatedAmountsMap(budgetSummaryData, BUDGET_VARIABILITY);

  const groupName = getBudgetVariabilityName(BUDGET_VARIABILITY).toLowerCase();

  const nonMonthlyData = pathOr(
    {} as (typeof expenseDataWithAmountsForVariability)[number],
    [0],
    expenseDataWithAmountsForVariability,
  );

  const initialValues = useMemo(
    () =>
      nonMonthlyData?.rows?.reduce((acc, row: BudgetedRowData) => {
        acc[row.id] = {
          id: row.id,
          rolloverFrequency: row.rolloverPeriod?.frequency,
          rolloverTargetAmount: row.rolloverPeriod?.targetAmount,
          monthlyBudget: row.budgeted,
          appliedToFuture: true,
        };
        return acc;
      }, {} as FormValues),
    [nonMonthlyData],
  );

  const categoriesById = useMemo(
    () => indexBy(prop('id'), nonMonthlyData?.rows ?? []),
    [nonMonthlyData],
  );

  const handleValidation = useCallback((values: FormValues) => {
    let errors: Record<string, Record<string, string>> = {};

    Object.entries(values).forEach(([id, row]) => {
      if (!row.rolloverFrequency && !!row.rolloverTargetAmount) {
        errors = assocPath([id, 'rolloverFrequency'], 'Expense frequency is required.', errors);
      }
    });

    return errors;
  }, []);

  const [updateCategory, { called: updateCategoryMutationCalled }] = useMutation(
    UPDATE_CATEGORY_FROM_BUDGET_ONBOARDING,
  );

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      // Find only what the user has changed from initialValues
      const changedValues = Object.entries(values).reduce((acc, [id, row]) => {
        const initialRow = initialValues[id];
        if (
          initialRow.rolloverFrequency !== row.rolloverFrequency ||
          initialRow.rolloverTargetAmount !== row.rolloverTargetAmount ||
          initialRow.monthlyBudget !== row.monthlyBudget
        ) {
          acc = [...acc, { ...row, id }];
        }
        return acc;
      }, [] as Field[]);

      await Promise.all(
        changedValues.map((row) => {
          const existingCategory = categoriesById[row.id];
          return updateCategory({
            variables: {
              input: {
                id: row.id,
                icon: existingCategory.icon?.toString(),
                name: existingCategory.name,
                group: existingCategory.parentGroupId,
                rolloverEnabled: true,
                rolloverFutureBudgetAllocation: row.monthlyBudget,
                rolloverFrequency: row.rolloverFrequency,
                rolloverTargetAmount: row.rolloverTargetAmount,
                // Always pass in the current month as the rollover start month
                rolloverStartMonth: state.thisMonth.toISODate(),
                rolloverType: BudgetRolloverPeriodType.NonMonthly,
                rolloverStartingBalance: existingCategory?.rolloverPeriod?.startingBalance,
                type: CategoryGroupType.EXPENSE, // revisit if we allow non-monthly income
                budgetVariability: BudgetVariability.NON_MONTHLY,
                excludeFromBudget: existingCategory.excludeFromBudget,
                // TODO: needed due to a hotfix (api#6097)
                applyRolloverBudgetToFutureMonths: undefined,
              },
            },
          });
        }),
      );

      onNext();
    },
    [categoriesById, initialValues],
  );

  return (
    <FeatureOnboardingPage
      pageName={pageName}
      title={title}
      description={description}
      descriptionMaxWidth={658}
      progress={progress}
      onClickBack={onBack}
      onClickCancel={onCancel}
      hideNextButton
    >
      <StyledForm
        initialValues={initialValues}
        validate={handleValidation}
        onSubmit={handleSubmit}
        isInitialValid
      >
        <Container>
          {isLoadingInitialData ? (
            <StyledLoadingSpinner />
          ) : (
            <FlexContainer column gap="default">
              <OnboardingNonMonthlyCard data={nonMonthlyData} />
              {/* The group-related components for the Non-monthly page are different because they use the values from the form. */}
              <OnboardingNonMonthlyGroupFooter title={`Total ${groupName} budgeted expenses`} />
              <Divider />
              <OnboardingNonMonthlyLeftToBudgetFooter
                incomeAmount={incomeAmount}
                accumulatedAmountsMap={accumulatedAmountsMap}
              />
            </FlexContainer>
          )}
        </Container>
        <FooterButton customButton={<SubmitButton />} />
        <BeforeUnloadPrompt updateCategoryMutationCalled={updateCategoryMutationCalled} />
      </StyledForm>
    </FeatureOnboardingPage>
  );
};

const SubmitButton = () => {
  const { dirty } = useFormContext();
  return (
    <StyledFormSubmitButton size="large" disableWhenValuesUnchanged={false}>
      {dirty ? 'Save changes and continue' : 'Continue'}
    </StyledFormSubmitButton>
  );
};

const BeforeUnloadPrompt = ({
  updateCategoryMutationCalled,
}: {
  updateCategoryMutationCalled: boolean;
}) => {
  const { dirty } = useFormContext();

  // Don't show the prompt if the mutation has been called
  if (updateCategoryMutationCalled) {
    return null;
  }

  return <BlockNavigationPrompt when={dirty} message={BEFORE_UNLOAD_MESSAGE} />;
};

const UPDATE_CATEGORY_FROM_BUDGET_ONBOARDING = gql(/* GraphQL */ `
  mutation Web_UpdateCategoryFromBudgetOnboarding($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      category {
        id
        ...CategoryFormFields
      }
    }
  }
`);

export default OnboardingNonMonthly;
