import { isNilOrEmpty } from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import CurrencyInput from 'components/lib/form/CurrencyInput';
import FlexContainer from 'components/lib/ui/FlexContainer';
import type {
  FilterMenuOption,
  FilterMenuOptionInput,
  WithPath,
} from 'components/reports/filters/types';

import { fontSize, spacing } from 'common/lib/theme/dynamic';

const InputContainer = styled(FlexContainer).attrs({ gap: 'xsmall' })`
  margin-top: ${spacing.xxxsmall};
`;

const SmallCurrencyInput = styled(CurrencyInput)`
  width: 100%;
  font-size: ${fontSize.small};
  padding: ${spacing.xxsmall} ${spacing.small};
`;

type Props = {
  option: WithPath<FilterMenuOption>;
  onInputChange: (
    path: string[],
    optionId: string,
    input: FilterMenuOptionInput,
    value: unknown,
  ) => void;
};

const FilterMenuOptionAccessory = ({ option, onInputChange }: Props) => {
  if (isNilOrEmpty(option.inputs)) {
    return null;
  }

  return (
    <InputContainer>
      {option.inputs?.map((input) => {
        // We only support currency inputs for now. We can extend this later on.
        if (input.type !== 'currency') {
          return null;
        }

        return (
          <SmallCurrencyInput
            id={option.id}
            key={`${option.id}-${input.name}`}
            placeholder={input.placeholder}
            name={input.name}
            value={option.inputValues?.[input.name]}
            maskOptions={{ allowDecimal: true }}
            onChange={(value) => onInputChange(option.path, option.id, input, value)}
          />
        );
      })}
    </InputContainer>
  );
};

export default FilterMenuOptionAccessory;
