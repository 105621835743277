import { DateTime } from 'luxon';
import * as R from 'ramda';

import { isInvestmentAccount } from 'common/lib/accounts/accountTypes';
import { isoDateToMonthAbbreviation } from 'common/utils/date';

import { GoalType } from 'common/constants/goals';
import { DECIMAL_TO_PERCENT } from 'common/constants/math';

import type { GoalContributionGraphFieldsFragment } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

type GoalContributionGraphFields_monthlyContributionSummaries = ElementOf<
  GoalContributionGraphFieldsFragment,
  'monthlyContributionSummaries'
>;

export const filterOutArchivedAndSortByPriority = <
  T extends { archivedAt: string | null | undefined; priority: number },
>(
  goals: T[],
) => sortGoalsByPriority(filterOutArchivedGoals(goals));

export const sortGoalsByPriority = <T extends { priority: number }>(goals: T[]) =>
  R.sortBy(R.prop('priority'), goals);

export const filterOutArchivedGoals = <T extends { archivedAt: string | null | undefined }>(
  goals: T[],
) => goals.filter(({ archivedAt }) => !archivedAt);

/** Returns 1 if goal is completed or archived. */
export const getGoalDisplayProgress = <
  T extends {
    completionPercent?: number | null;
    completedAt?: string | null;
    archivedAt?: string | null;
  },
>(
  goal: T,
) => {
  if (goal.completedAt || goal.archivedAt) {
    return 1;
  }
  return (goal.completionPercent ?? 0) / DECIMAL_TO_PERCENT;
};

/** Invert debt goals currentAmount. */
export const getGoalDisplayAmount = <T extends { currentAmount?: number | null; type: string }>({
  currentAmount,
  type,
}: T) =>
  // debt goals have a negative current amount, but we want to display as positive
  invertAmountIfDebtGoal(currentAmount, type);

export const getGoalRemainingAmount = <
  T extends { targetAmount?: number | null; currentAmount?: number | null },
>({
  targetAmount,
  currentAmount,
}: T) => {
  const remainingAmount = (targetAmount ?? 0) - (currentAmount ?? 0);
  return Math.max(0, remainingAmount);
};

/** Given a list of GoalAccountAllocation, returns a dictionary keyed by account id. */
export const indexAllocationsByAccountId = <T extends { account: { id: string } }>(
  allocations: T[],
): { [accountId: string]: T } => R.indexBy(({ account: { id } }) => id, allocations);

export const filterAccountsForSubtypes = <T extends { subtype: { name: string } }>(
  accounts: T[],
  subtypes: string[],
) => accounts.filter(({ subtype: { name } }) => subtypes.includes(name));

export const isGoalSetupIncomplete = <T extends { accountAllocations: { id: string }[] }>(
  goal: T,
) => !goal.accountAllocations.length;

export const getGraphDataFromMonthlyContributionSummaries = <
  T extends {
    month: string;
    sum: number;
    sumCredit: number;
    sumDebit: number;
  },
>(
  monthlyContributionSummaries: T[],
) => {
  const data = R.reverse(
    monthlyContributionSummaries.map((item) => ({
      ...item,
      label: isoDateToMonthAbbreviation(item.month),
      key: item.month,
      sumDebit: item.sumDebit * -1,
    })),
  );
  const sections = [{ data }];

  const maxCredit = R.reduce(R.max, 0, data.map(R.prop('sumCredit')));
  const maxDebit = R.reduce(R.max, 0, data.map(R.prop('sumDebit'))); // negative

  const range = [maxDebit, maxCredit] as [number, number];

  return { sections, range };
};

/** Given a list of allocations, sum the currentAmountChange amount fields. */
export const sumCurrentMonthChangesForAllocations = <
  T extends {
    currentMonthChange?: {
      percent?: number | null;
      amount?: number | null;
    } | null;
  },
>(
  allocations: T[],
): number =>
  R.reduce(
    (acc, { currentMonthChange }) => acc + (currentMonthChange?.amount ?? 0),
    0,
    allocations,
  );

export const sumAccountAllocations = <
  T extends {
    currentAmount?: number | null;
  },
>(
  allocations: T[],
) => R.reduce((acc, { currentAmount }) => acc + (currentAmount ?? 0), 0, allocations);

export const getCurrentMonthContributionChange = <
  T extends {
    sum: number;
  },
>(
  monthlyContributionSummaries: T[],
) => {
  const currentMonth = monthlyContributionSummaries[monthlyContributionSummaries.length - 1];
  const previousMonth = monthlyContributionSummaries[monthlyContributionSummaries.length - 2];
  return (currentMonth?.sum ?? 0) - (previousMonth?.sum ?? 0);
};

export const getRemainingPlannedContributionsForCurrentMonthGoal = <
  T extends {
    monthlyContributionSummaries: {
      sumCredit: number;
      plannedAmount: number | null;
    }[];
  },
>(
  goal: T,
) => {
  const lastSummary = R.last(goal.monthlyContributionSummaries);
  const remainingPlannedAmount = (lastSummary?.plannedAmount ?? 0) - (lastSummary?.sumCredit ?? 0);

  return Math.max(0, remainingPlannedAmount);
};

/** Sum up remaining planned contributions for all goals. */
export const getRemainingPlannedContributionsForGoals = <
  T extends {
    monthlyContributionSummaries: {
      sumCredit: number;
      plannedAmount: number | null;
    }[];
  },
>(
  goals: T[],
) =>
  R.reduce(
    (acc, goal) => acc + getRemainingPlannedContributionsForCurrentMonthGoal(goal),
    0,
    goals,
  );

export const invertAmountIfDebtGoal = (amount: number | null | undefined, goalType: string) => {
  if (!amount) {
    return 0;
  }

  if (goalType === GoalType.Debt) {
    return -1 * amount;
  }

  return amount;
};

/** Returns true if a goal has at least one account alloction, and all
 * assigned accounts are investment accounts.
 */
export const goalOnlyHasInvestmentAccounts = <
  T extends {
    accountAllocations: {
      account: {
        type: {
          name: string;
        };
      };
    }[];
  },
>({
  accountAllocations,
}: T) => {
  if (!accountAllocations.length) {
    return false;
  }

  return accountAllocations.every(({ account }) => isInvestmentAccount(account.type.name));
};

/**
 * If there are less summaries than minCount, will append entires for future months with sum=0 to
 * ensure the array has at least minCount entries.
 */
export const ensureMinimumMonthlyContributionSummaries = (
  monthlyContributionSummaries: GoalContributionGraphFields_monthlyContributionSummaries[],
  minCount: number,
) => {
  if (monthlyContributionSummaries.length >= minCount) {
    return monthlyContributionSummaries;
  }

  const lastEntry = R.last(monthlyContributionSummaries);
  const maxDate = lastEntry ? DateTime.fromISO(lastEntry.month) : DateTime.local().startOf('month');

  const placeholders = R.range(0, minCount - monthlyContributionSummaries.length).map((i) => ({
    __typename: 'GoalContributionMonthlySummary' as const,
    month: maxDate.plus({ month: i + 1 }).toISODate(),
    sum: 0,
    sumCredit: 0,
    sumDebit: 0,
    plannedAmount: null,
  }));

  return monthlyContributionSummaries.concat(placeholders);
};

export const getIncompleteGoalSetupLabel = (goalDefaultName: string) => {
  const defaultLabel = 'Assign accounts';
  const copyMap = [
    {
      goalNames: [
        'emergency fund',
        'down payment',
        'car',
        'vacation',
        'wedding',
        'education',
        'savings',
      ],
      label: 'Assign savings accounts',
    },
    {
      goalNames: ['retirement'],
      label: 'Assign retirement accounts',
    },
    {
      goalNames: ['credit card'],
      label: 'Assign credit card accounts',
    },
    {
      goalNames: ['student loans', 'auto loan', 'mortgage'],
      label: 'Assign loan accounts',
    },
    {
      goalNames: ['other debt'],
      label: 'Assign debt accounts',
    },
  ];

  return (
    copyMap.find(({ goalNames }) => goalNames.includes(goalDefaultName.toLowerCase()))?.label ||
    defaultLabel
  );
};
