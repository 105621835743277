import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

type Props = {
  onButtonClick?: () => void;
  buttonText?: string;
  errorMessage?: string;
  errorDescription?: string;
};

const DEFAULT_ERROR_MESSAGE = 'Oops! Something went wrong.';
const DEFAULT_ERROR_DESCRIPTION =
  "An unexpected error occurred, sorry about that. We've been notified and will get started on a fix.";

const AlertIcon = styled(Icon).attrs({ name: 'alert-circle' })`
  width: 60px;
  height: 60px;
  margin: ${({ theme }) => theme.spacing.xlarge} 0px;
  color: ${({ theme }) => theme.color.orange};
`;

const BodyContainer = styled(FlexContainer)<{ showBottomBorder: boolean }>`
  border-bottom: ${(props) => (props.showBottomBorder ? '1' : '0')}px solid
    ${({ theme }) => theme.color.grayBackground};
`;

const HeaderText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  text-align: center;
`;

const StyledButton = styled(PrimaryButton)`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const ErrorMessage = ({
  onButtonClick,
  buttonText,
  errorMessage = DEFAULT_ERROR_MESSAGE,
  errorDescription = DEFAULT_ERROR_DESCRIPTION,
}: Props) => (
  <>
    <BodyContainer alignCenter column padding="large" showBottomBorder={!!buttonText}>
      <AlertIcon />
      <HeaderText weight="medium" size="large">
        {errorMessage}
      </HeaderText>
      <Text align="center" size="small">
        {errorDescription}
      </Text>
    </BodyContainer>
    {!!buttonText && (
      <StyledButton size="medium" onClick={onButtonClick}>
        {buttonText}
      </StyledButton>
    )}
  </>
);

export default ErrorMessage;
