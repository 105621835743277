import pluralize from 'pluralize';
import type { FileError } from 'react-dropzone';

/** This is mainly helpful to get contextual error messages with
 * more details than the default ones from react-dropzone */
export const getAttachmentErrorMessageFromCode = (
  code: FileError['code'] | undefined,
  maxFiles?: number,
) => {
  if (!code) {
    return null;
  }

  switch (code) {
    case 'file-too-large':
      return 'Sorry, the file you tried to upload is too large';
    case 'too-many-files':
      return maxFiles
        ? `Sorry, you can only upload ${maxFiles} ${pluralize('file', maxFiles)} at a time`
        : 'Sorry, you tried to upload too many files';
    default:
      return 'Sorry, something went wrong with your upload';
  }
};
