import type { Placement, Modifier } from '@popperjs/core';
import React, { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

import type { Color } from 'types/Styles';

const ARROW_SIZE_PX = 12;
const ARROW_OFFSET_PX = 5;

const Arrow = styled.div<{ color: Color }>`
  width: ${ARROW_SIZE_PX}px;
  height: ${ARROW_SIZE_PX}px;
  position: absolute;

  ::before {
    content: '';
    position: absolute;
    width: ${ARROW_SIZE_PX}px;
    height: ${ARROW_SIZE_PX}px;
    background: ${({ theme, color }) => theme.color[color]};
    border-radius: ${({ theme }) => theme.radius.xxsmall};
    transform: rotate(45deg);
  }
`;

const Root = styled.div`
  &[data-popper-placement^='right'] > ${Arrow} {
    left: 0;
    ::before {
      left: -${ARROW_OFFSET_PX}px;
    }
  }
  &[data-popper-placement^='left'] > ${Arrow} {
    right: 0;
    ::before {
      right: -${ARROW_OFFSET_PX}px;
    }
  }
  &[data-popper-placement^='bottom'] > ${Arrow} {
    top: 0;
    ::before {
      top: -${ARROW_OFFSET_PX}px;
    }
  }
  &[data-popper-placement^='top'] > ${Arrow} {
    bottom: 0;
    ::before {
      bottom: -${ARROW_OFFSET_PX}px;
    }
  }
`;

export type PopperProps = {
  anchorElement: Element | null;
  popperElement: HTMLElement | null;
  setPopperElement: (el: HTMLElement | null) => void;
  children: React.ReactNode;
  showArrow?: boolean;
  arrowColor?: Color;
  placement?: Placement;
  /** See: https://popper.js.org/docs/v2/modifiers/offset/#offset-1 */
  offset?: [number, number];
  modifiers?: Partial<Modifier<any>>[];
};

const makeOffsetModifiers = (offset: PopperProps['offset']): Partial<Modifier<any>>[] => {
  if (offset) {
    return [
      {
        name: 'offset',
        options: { offset },
      },
    ];
  }
  return [];
};

/**
 * Inner workings of the Popover component. Uses Popper.js for positioning. This can
 * be used standalone for advanced use cases or other non-Popover uses.
 *
 *  const [referenceElement, setReferenceElement] = React.useState<HTMLElement | null>(null);
 *
 *  <span ref={referenceElement}>Popper anchor</span>
 *  <Popper anchorElement={referenceElement}>
 *     This is in a raw popper! (no styling is applied)
 *  </Popper>
 */
const Popper = ({
  anchorElement,
  popperElement,
  setPopperElement,
  children,
  showArrow,
  arrowColor = 'white',
  placement = 'bottom',
  offset,
  modifiers = [],
}: PopperProps) => {
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const combinedModifiers = useMemo(
    () => [
      ...makeOffsetModifiers(offset),
      ...(arrowRef
        ? [
            {
              name: 'arrow',
              options: {
                element: arrowRef,
                padding: 10,
              },
            },
          ]
        : []),
      ...modifiers,
    ],
    [offset, modifiers, arrowRef],
  );

  const { styles, attributes } = usePopper(anchorElement, popperElement, {
    placement,
    modifiers: combinedModifiers,
  });

  return createPortal(
    <Root ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      {showArrow && <Arrow ref={setArrowRef} style={styles.arrow} color={arrowColor} />}
      {children}
    </Root>,
    document.body,
  );
};

export default Popper;
