import { ellipsis } from 'polished';
import { isNil } from 'ramda';
import React, { useCallback, useMemo } from 'react';
import type { ValueType } from 'react-select';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import type { Props as SelectProps } from 'components/lib/form/Select';
import Select from 'components/lib/form/Select';
import Emoji from 'components/lib/ui/Emoji';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

import useHouseholdUsers, { ANYONE_ID } from 'common/lib/hooks/household/useHouseholdUsers';
import { ReviewStatus } from 'common/lib/transactions/review';
import { formatThousands } from 'common/utils/Number';

const TRANSACTION_COUNT_ICON_SIZE_PX = 10;

const SelectLabel = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const NameWrapper = styled.div`
  flex-grow: 1;
  ${ellipsis()}
`;

const CountWrapper = styled.div`
  white-space: nowrap;
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

type ReviewSelectOptionType = {
  value: Maybe<string>;
  label: Maybe<React.ReactNode>;
  needsReviewCount?: number;
};

const Option = ({ value, label, needsReviewCount }: ReviewSelectOptionType) =>
  value ? (
    <FlexContainer justifyBetween alignCenter full gap="xsmall">
      <NameWrapper>{label}</NameWrapper>
      {needsReviewCount !== undefined && needsReviewCount > 0 && (
        <CountWrapper>
          <Emoji>
            <Icon size={TRANSACTION_COUNT_ICON_SIZE_PX} name="credit-card" />
          </Emoji>
          {formatThousands(needsReviewCount ?? 0)}
        </CountWrapper>
      )}
    </FlexContainer>
  ) : null;

type Props = {
  reviewStatusFieldName: string;
  needsReviewByIdFieldName: string;
} & SelectProps;

const ReviewStatusSelect = ({
  reviewStatusFieldName,
  needsReviewByIdFieldName,
  ...selectProps
}: Props) => {
  const { getFieldMeta, setFieldValue } = useFormContext();

  const [{ users, reviewSummaryByUser }] = useHouseholdUsers();

  const { value: reviewStatus } = getFieldMeta<Maybe<string>>(reviewStatusFieldName);
  const { value: needsReviewById } = getFieldMeta<Maybe<string>>(needsReviewByIdFieldName);

  const reviewStatusFieldValue = useMemo(() => {
    if (reviewStatus === ReviewStatus.NeedsReview) {
      return needsReviewById ?? ReviewStatus.NeedsReview;
    }

    return reviewStatus;
  }, [reviewStatus, needsReviewById]);

  const selectOptions = useMemo(
    () => [
      {
        id: 'review-status',
        options: [{ value: ReviewStatus.Reviewed, label: 'Mark as Reviewed' }],
      },
      {
        id: 'needs-review',
        label: 'Needs Review by...',
        options: [
          {
            value: ReviewStatus.NeedsReview,
            label: 'Anyone',
            needsReviewCount: reviewSummaryByUser?.[ANYONE_ID] ?? 0,
          },
          ...users.map((user) => ({
            value: user.id,
            label: user.name,
            needsReviewCount: reviewSummaryByUser?.[user.id] ?? 0,
          })),
        ],
      },
    ],
    [users, reviewSummaryByUser],
  );

  const getLabelByValue = useCallback(
    (reviewStatusFieldValue: Maybe<string>) => {
      if (isNil(reviewStatusFieldValue)) {
        return null;
      }

      if (reviewStatusFieldValue === ReviewStatus.Reviewed) {
        return 'Reviewed';
      }

      if (reviewStatusFieldValue === ReviewStatus.NeedsReview) {
        return 'Anyone to review';
      }

      const user = users.find((user) => user.id === reviewStatusFieldValue);
      return `${user?.name ?? '?'} to review`;
    },
    [users],
  );

  const valueOption = useMemo(
    () =>
      reviewStatusFieldValue
        ? { value: reviewStatusFieldValue, label: getLabelByValue(reviewStatusFieldValue) }
        : null,
    [reviewStatusFieldValue, getLabelByValue],
  );

  const renderOption = useCallback((option: ReviewSelectOptionType) => <Option {...option} />, []);

  const GroupHeading = useCallback(
    ({ children }: { children: React.ReactNode }) => <SelectLabel>{children}</SelectLabel>,
    [],
  );

  const onChangeReviewStatus = useCallback(
    (option: ValueType<ReviewSelectOptionType>) => {
      if (!option) {
        setFieldValue(reviewStatusFieldName, null);
        setFieldValue(needsReviewByIdFieldName, null);
        return;
      }

      const { value } = option as ReviewSelectOptionType;
      if (value === ReviewStatus.Reviewed) {
        setFieldValue(reviewStatusFieldName, ReviewStatus.Reviewed);
        setFieldValue(needsReviewByIdFieldName, null);
      } else if (value === ReviewStatus.NeedsReview) {
        setFieldValue(reviewStatusFieldName, ReviewStatus.NeedsReview);
        setFieldValue(needsReviewByIdFieldName, null);
      } else {
        setFieldValue(reviewStatusFieldName, ReviewStatus.NeedsReview);
        setFieldValue(needsReviewByIdFieldName, value);
      }
    },
    [reviewStatusFieldName, needsReviewByIdFieldName, setFieldValue],
  );

  return (
    <Select
      placeholder="Select status..."
      {...selectProps}
      value={valueOption}
      options={selectOptions}
      onChange={onChangeReviewStatus}
      renderOption={renderOption}
      components={{ GroupHeading }}
      isClearable
    />
  );
};

export default ReviewStatusSelect;
