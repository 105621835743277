import { useState, useEffect } from 'react';

/**
 * Returns cached value until it has not changed for a specified amount of time.
 *
 * @param value Value to debounce
 * @param delayMs Delay in milliseconds to wait before updating cached value
 */
const useDebounce = <T>(value: T, delayMs: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delayMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, delayMs, setDebouncedValue]);

  return debouncedValue;
};

export default useDebounce;
