import type { DetailedAPIError } from 'common/errors';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import Banner from 'components/lib/ui/Banner';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import PasswordValidationGroup, {
  MergedPasswordSchema,
} from 'components/onboarding/PasswordValidationGroup';

import { login } from 'actions';
import getUserApi from 'common/lib/api/user';
import useToggle from 'common/lib/hooks/useToggle';
import api from 'lib/api';
import { useDispatch, useQueryParam } from 'lib/hooks';
import useUserAttributionData from 'lib/hooks/useUserAttributionData';

import { ONBOARDING } from 'common/constants/copy';
import routes from 'constants/routes';

import { ErrorCode } from 'common/generated/graphQlTypes/globalTypes';

const StyledOnboardingTitle = styled(OnboardingTitle)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: 106px;
  }
`;

const CustomErrorBanner = styled(Banner)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const LogInLink = styled(Link)`
  text-decoration: underline;
  color: inherit;

  :hover {
    color: inherit;
  }
`;

type Props = {
  next: () => void;
};

const ADS_WIZZ_PIXEL_URL =
  'https://strategicmedia-20-adswizz.attribution.adswizz.com/fire?pixelId=bcac3ab5-c359-416d-806b-cfe7aaa956a5&type=sitevisit&subtype=Purchase1&aw_0_req.gdpr=true&redirectURL=aHR0cHM6Ly9waXhlbC50YXBhZC5jb20vaWRzeW5jL2V4L3JlY2VpdmU_cGFydG5lcl9pZD0yOTk0JjwjaWYgcmVxdWVzdC5saXN0ZW5lcklkP21hdGNoZXMoJ1swLTlhLWZdezh9LVswLTlhLWZdezR9LVswLTlhLWZdezR9LVswLTlhLWZdezR9LVswLTlhLWZdezEyfScpPnBhcnRuZXJfdHlwZWRfZGlkPSU3QiUyMkhBUkRXQVJFX0FORFJPSURfQURfSUQlMjIlM0ElMjIke3JlcXVlc3QubGlzdGVuZXJJZH0lMjIlN0Q8I2Vsc2VpZiByZXF1ZXN0Lmxpc3RlbmVySWQ_bWF0Y2hlcygnWzAtOUEtRl17OH0tWzAtOUEtRl17NH0tWzAtOUEtRl17NH0tWzAtOUEtRl17NH0tWzAtOUEtRl17MTJ9Jyk-cGFydG5lcl90eXBlZF9kaWQ9JTdCJTIySEFSRFdBUkVfSURGQSUyMiUzQSUyMiR7cmVxdWVzdC5saXN0ZW5lcklkfSUyMiU3RDwjZWxzZT5wYXJ0bmVyX2RldmljZV9pZD0ke3JlcXVlc3QubGlzdGVuZXJJZCF9PC8jaWY-';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

const OnboardingCreatePassword = ({ next }: Props) => {
  const code = useQueryParam('code') || '';
  const email = useQueryParam('email') || '';
  const userAttributionData = useUserAttributionData();

  const dispatch = useDispatch();
  const [
    isAccountAlreadyExistsErrorShown,
    { setOn: showAccountAlreadyExistsError, setOff: hideAccountAlreadyExistsError },
  ] = useToggle(false);

  const onSubmit = async (values: { password: string; name: string }) => {
    const response = await getUserApi(api).verifyUser(
      email,
      code,
      values.name,
      values.password,
      undefined,
      userAttributionData,
    );
    dispatch(login({ signedUpAndVerified: true, ...response }));
    next();
  };

  return (
    <>
      <StyledOnboardingTitle>{ONBOARDING.CREATE_PASSWORD.TITLE}</StyledOnboardingTitle>
      <OnboardingSubtitle>{ONBOARDING.CREATE_PASSWORD.SUBTITLE}</OnboardingSubtitle>
      <OnboardingFormContainer>
        {isAccountAlreadyExistsErrorShown && (
          <CustomErrorBanner type="error">
            You have already signed up. Please <LogInLink to={routes.login()}>log in</LogInLink>
          </CustomErrorBanner>
        )}
        <Form
          initialValues={{ name: '', password: '' }}
          onSubmit={onSubmit}
          overrideValidationSchema={Yup.object().shape({
            password: MergedPasswordSchema,
          })}
          onError={(e: DetailedAPIError) => {
            e.data?.error_code === ErrorCode.USER_ALREADY_SIGNED_UP
              ? showAccountAlreadyExistsError()
              : hideAccountAlreadyExistsError();
          }}
        >
          <TextField
            name="password"
            placeholder="New password"
            password
            required
            autoComplete="new-password"
            onlyShowApiErrors
            autoFocus
            autoFocusOnMobile={false}
          />
          <PasswordValidationGroup />
          <FormSubmitButton size="medium">{ONBOARDING.CREATE_PASSWORD.BUTTON}</FormSubmitButton>
        </Form>
      </OnboardingFormContainer>

      {
        /*
          This pixel is used to track the users signups. This is only used in production.
          We're keeping it here since the idea is to track only after the user has successfully registered.
        */

        IS_PRODUCTION && <img src={ADS_WIZZ_PIXEL_URL} /> // eslint-disable-line jsx-a11y/alt-text
      }
    </>
  );
};

export default OnboardingCreatePassword;
