import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import NetWorthPerformanceChart from 'components/accounts/NetWorthPerformanceChart';
import Select from 'components/lib/form/Select';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import DashboardWidget from 'components/lib/ui/DashboardWidget';
import DashboardWidgetAmountHeader, {
  CurrentNetWorth,
} from 'components/lib/ui/DashboardWidgetAmountHeader';
import TimeframeTrendIndicator from 'components/lib/ui/TimeframeTrendIndicator';
import { GET_AGGREGATE_SNAPSHOTS } from 'components/routes/Accounts';

import {
  DATE_RANGES,
  getSnapshotDataForSnapshotGraph,
  getSnapshotDataForTimePeriod,
} from 'common/lib/accounts/accountCharts';
import { getNetWorthBalancesForPerformanceChart } from 'common/lib/accounts/netWorthCharts';
import { getAccountsDashboardWidgetTitle } from 'common/lib/dashboard';
import useQuery from 'common/lib/hooks/useQuery';
import { fontSize } from 'common/lib/theme/dynamic';
import { formatCurrency } from 'common/utils/Currency';
import useIsV2Theme from 'lib/hooks/useIsV2Theme';
import useMeasure from 'lib/hooks/useMeasure';

import routes from 'constants/routes';

const ROOT_PADDING_PX = 32;

const Body = styled.div`
  padding: 0 0 ${({ theme }) => theme.spacing.xlarge};
`;

const StyledSelect = styled(Select)`
  min-width: 150px;
`;

const NetWorthPerformanceChartWrapper = styled.div`
  padding-left: ${({ theme }) => `${theme.spacing.default}`};
  padding-right: ${({ theme }) => `${theme.spacing.default}`};
`;

const StyledNetWorthHeader = styled(DashboardWidgetAmountHeader)`
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing.default};
  margin-top: -${({ theme }) => theme.spacing.small};
`;

const StyledTimeframeTrendIndicator = styled(TimeframeTrendIndicator)`
  font-size: ${fontSize.base};
`;

const AccountsDashboardWidget = () => {
  const isV2Theme = useIsV2Theme();
  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();

  const [selectedDateRange, setSelectedDateRange] = useState(DATE_RANGES[0]);

  const {
    data: aggregateSnapshotsData,
    isLoadingInitialData: isLoadingAggregateSnapshots,
    isNetworkRequestInFlight: isAggregateSnapshotsNetworkRequestInFlight,
  } = useQuery(GET_AGGREGATE_SNAPSHOTS, {
    variables: {
      filters: {
        startDate: DateTime.local().minus(selectedDateRange.duration).toISODate(),
        endDate: null,
        accountType: null,
        useAdaptiveGranularity: true,
      },
    },
  });

  const { snapshots: aggregateSnapshots } = useMemo(
    () => getSnapshotDataForSnapshotGraph(aggregateSnapshotsData?.aggregateSnapshots ?? [], true),
    [aggregateSnapshotsData],
  );

  const lineChartData = useMemo(
    () =>
      aggregateSnapshots.length
        ? getSnapshotDataForTimePeriod(
            selectedDateRange?.duration ?? DATE_RANGES[0].duration,
            aggregateSnapshots,
          ).data
        : [],
    [selectedDateRange, aggregateSnapshots],
  );

  const [previousNetWorth, currentNetWorth] = useMemo(
    () => getNetWorthBalancesForPerformanceChart(lineChartData, 'balance'),
    [lineChartData],
  );

  const currentTimeFrameText = useMemo(
    () => `${selectedDateRange.longDisplay} change`,
    [selectedDateRange.longDisplay],
  );

  const chartWidth = useMemo(() => width - ROOT_PADDING_PX, [width]);

  const contextualMenuOptions = useMemo(
    () =>
      DATE_RANGES.map((dateRange) => ({
        value: dateRange.display,
        label: dateRange.longDisplay,
        onClick: () => setSelectedDateRange(dateRange),
      })),
    [setSelectedDateRange],
  );

  const isLoading = isLoadingAggregateSnapshots || isAggregateSnapshotsNetworkRequestInFlight;

  return (
    <DashboardWidget
      title={getAccountsDashboardWidgetTitle({ isLoading, isV2Theme, currentNetWorth })}
      loading={isLoading}
      description={
        <Switch>
          <Case when={isV2Theme}>
            {!isLoading && (
              <StyledTimeframeTrendIndicator
                startAmount={previousNetWorth}
                endAmount={currentNetWorth}
                isAsset
              />
            )}
          </Case>
          <Case default>
            {!isLoadingAggregateSnapshots && (
              <CurrentNetWorth {...sensitiveClassProps}>
                {formatCurrency(currentNetWorth)}
              </CurrentNetWorth>
            )}
          </Case>
        </Switch>
      }
      headerLink={routes.accounts()}
      showHeaderBorder={isV2Theme}
      headerRight={
        <StyledSelect
          small
          options={contextualMenuOptions}
          value={selectedDateRange.display}
          onChange={({ value }: { value: string }) =>
            setSelectedDateRange(DATE_RANGES.find((r) => r.display === value) ?? DATE_RANGES[0])
          }
          isDisabled={isLoadingAggregateSnapshots}
        />
      }
    >
      <Body ref={ref}>
        {!isV2Theme && (
          <StyledNetWorthHeader
            startAmount={previousNetWorth}
            currentAmount={currentNetWorth}
            timeframeText={currentTimeFrameText}
            isAsset
            hideCurrentAmount
          />
        )}

        <NetWorthPerformanceChartWrapper>
          <NetWorthPerformanceChart
            title="Net Worth"
            aggregateSnapshotsData={aggregateSnapshotsData?.aggregateSnapshots ?? []}
            isAggreateSnapShotsNetworkRequestInFlight={isAggregateSnapshotsNetworkRequestInFlight}
            selectedAccountType={null}
            selectedDateRange={selectedDateRange}
            isAsset
            width={chartWidth}
            yDataKey="balance"
          />
        </NetWorthPerformanceChartWrapper>
      </Body>
    </DashboardWidget>
  );
};

export default AccountsDashboardWidget;
