import React from 'react';
import styled from 'styled-components';

import Link from 'components/lib/ui/Link';
import {
  ImportPageSubtitle,
  ImportPageTitle,
  NextButton,
} from 'components/routes/accounts/ImportMintBalanceHistory';

import boxShadow from 'common/lib/styles/boxShadow';

import { MINT_DATA_EXPORT_CHROME_EXTENSION_URL } from 'common/constants/externalUrls';
import { IMPORT_BALANCE_HISTORY } from 'constants/copy';

const GetChromeExtensionButton = styled(Link).attrs({
  href: MINT_DATA_EXPORT_CHROME_EXTENSION_URL,
  target: '_blank',
})`
  margin: ${({ theme }) => theme.spacing.xxlarge} auto;
  height: ${({ theme }) => theme.spacing.xxlarge};
  line-height: ${({ theme }) => theme.spacing.xxlarge};

  padding: 0 ${({ theme }) => theme.spacing.small};

  border-radius: ${({ theme }) => theme.radius.small};
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  background: ${({ theme }) => theme.color.white};
  ${boxShadow.small}
  font-size: ${({ theme }) => theme.fontSize.small};

  color: ${({ theme }) => theme.color.text};

  &:link,
  &:hover,
  &:visited,
  &:active {
    color: ${({ theme }) => theme.color.text};
  }
`;

const DownloadMintExporterChromeExtensionImage = styled.img.attrs({
  src: 'https://monarch-static-assets.s3.amazonaws.com/other/mint-import-chrome-extension.png',
})`
  max-width: 700px;
  height: auto;
`;

type Props = {
  onClickNext: () => void;
};

const DownloadMintExporter = ({ onClickNext }: Props) => (
  <>
    <ImportPageTitle>{IMPORT_BALANCE_HISTORY.STEPS.DOWNLOAD_MINT_EXPORTER.TITLE}</ImportPageTitle>
    <ImportPageSubtitle>
      {IMPORT_BALANCE_HISTORY.STEPS.DOWNLOAD_MINT_EXPORTER.SUBTITLE}
    </ImportPageSubtitle>

    <GetChromeExtensionButton>
      {IMPORT_BALANCE_HISTORY.STEPS.DOWNLOAD_MINT_EXPORTER.GET_EXPORTER_BUTTON}
    </GetChromeExtensionButton>

    <DownloadMintExporterChromeExtensionImage />

    <NextButton onClick={onClickNext}>{IMPORT_BALANCE_HISTORY.NEXT_BUTTON}</NextButton>
  </>
);

export default DownloadMintExporter;
