import React from 'react';
import styled from 'styled-components';

import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import IconButton from 'components/lib/ui/button/IconButton';
import OverlayTrigger from 'components/lib/ui/popover/OverlayTrigger';

type Props = {
  hideRevokeAction: boolean;
  hideCancelSponsorshipAction: boolean;
  onEditClient?: () => void;
  onRevokeInvite?: () => void;
  onCancelSponsorship?: () => void;
};

const DestructiveDropdownMenuItem = styled(DropdownMenuItem)`
  color: ${(props) => props.theme.color.red};
  :hover {
    color: ${(props) => props.theme.color.red};
    background-color: ${(props) => props.theme.color.redBackground};
  }
`;

const ClientsListRowMenu = ({
  onEditClient,
  onRevokeInvite,
  onCancelSponsorship,
  hideRevokeAction,
  hideCancelSponsorshipAction,
}: Props) => (
  <>
    <OverlayTrigger
      placement="bottom-start"
      overlay={
        <DropdownMenu>
          <DropdownMenuItem onClick={onEditClient} icon="edit">
            Edit client
          </DropdownMenuItem>
          {!hideRevokeAction && (
            <DestructiveDropdownMenuItem onClick={onRevokeInvite} icon="x">
              Revoke invite
            </DestructiveDropdownMenuItem>
          )}
          {!hideCancelSponsorshipAction && (
            <DestructiveDropdownMenuItem onClick={onCancelSponsorship} icon="x">
              Stop paying for client
            </DestructiveDropdownMenuItem>
          )}
        </DropdownMenu>
      }
    >
      {({ toggleOpen, isOpen }) => (
        <IconButton icon="more-horizontal" size="medium" onClick={toggleOpen} active={isOpen} />
      )}
    </OverlayTrigger>
  </>
);

export default ClientsListRowMenu;
