import _ from 'lodash';
import { useCallback } from 'react';

import type { AppStatus } from 'common/lib/hooks/useAppStatus';
import useAppStatus from 'common/lib/hooks/useAppStatus';
import useEventListener from 'lib/hooks/useEventListener';

const REFETCH_APP_STATUS_MILLISECONDS = 5 * 60 * 1000; // 5 minutes

/**
 * Hook used to get current app status and possible incident or maintenance messages
 * */
const useAppStatusWhileNavigating = (): AppStatus => {
  const { shouldShowStatus, loading, link, type, message, refetch } = useAppStatus();

  const throttledRefetchAppStatus = useCallback(
    _.throttle(
      () => {
        refetch();
      },
      REFETCH_APP_STATUS_MILLISECONDS,
      {
        leading: false,
        trailing: true,
      },
    ),
    [refetch],
  );

  useEventListener(document, 'mousemove', throttledRefetchAppStatus);
  useEventListener(document, 'load', throttledRefetchAppStatus);
  useEventListener(document, 'click', throttledRefetchAppStatus);

  return {
    shouldShowStatus,
    loading,
    link,
    type,
    message,
    refetch: throttledRefetchAppStatus,
  };
};

export default useAppStatusWhileNavigating;
