import React, { useState, useCallback } from 'react';

import TransactionSplitModal from 'components/transactions/TransactionSplitModal';

import useEventCallback from 'common/lib/hooks/useEventCallback';
import { useTransactionListContext } from 'lib/contexts/TransactionsListContext';
import useModal from 'lib/hooks/useModal';

const useSplitTransactionModal = (onDone?: () => void) => {
  const [transactionId, setTransactionId] = useState('');
  const [Modal, { open: openModal, close, isOpen }] = useModal();

  const { refetchAll } = useTransactionListContext();

  const onDoneInner = useEventCallback(async () => {
    await refetchAll();
    onDone?.();
    close();
  });

  const SplitModal = useCallback(
    () => (
      <Modal key="split-modal" full>
        <TransactionSplitModal id={transactionId} onCancel={close} onDone={onDoneInner} />
      </Modal>
    ),
    [Modal, transactionId, close, onDoneInner],
  );

  const open = useCallback(
    (id: string) => {
      setTransactionId(id);
      openModal();
    },
    [setTransactionId, openModal],
  );

  return [SplitModal, { open, close, isOpen }] as const;
};

export default useSplitTransactionModal;
