import React, { useMemo } from 'react';

import CurrencyField from 'components/lib/form/CurrencyField';
import FieldCellWithToggle from 'components/lib/form/FieldCellWithToggle';
import SelectField from 'components/lib/form/SelectField';
import ToggleField from 'components/lib/form/ToggleField';
import Link from 'components/lib/ui/Link';
import PremiumBadge from 'components/premium/PremiumBadge';
import PremiumFeatureOverlayTrigger from 'components/premium/PremiumFeatureOverlayTrigger';

import { getRolloverStartMonthOptions } from 'common/lib/budget/Rollovers';

import { ROLLOVER_ENABLED_FIELD_NAME } from 'common/constants/budget';
import { HELP_CENTER_ROLLOVER_ARTICLE_URL } from 'common/constants/externalUrls';
import { ProductFeature } from 'common/constants/premium';

const CategoryRolloverFields = () => {
  const rolloverStartMonthOptions = useMemo(() => getRolloverStartMonthOptions(), []);

  return (
    <PremiumFeatureOverlayTrigger feature={ProductFeature.rollovers} placement="bottom-end">
      {({ hasAccess }) => (
        <FieldCellWithToggle
          fieldCellProps={{
            title: 'Make this category a rollover fund',
            subtitle: (
              <>
                Carry over remaining balances or set due dates to better plan for future expenses.{' '}
                <Link href={HELP_CENTER_ROLLOVER_ARTICLE_URL} target="_blank">
                  Learn more
                </Link>
              </>
            ),
            rightAccessory: hasAccess ? (
              <ToggleField name={ROLLOVER_ENABLED_FIELD_NAME} hideLabel />
            ) : (
              <PremiumBadge />
            ),
          }}
          toggleFieldProps={{
            name: ROLLOVER_ENABLED_FIELD_NAME,
          }}
        >
          <SelectField
            name="rolloverStartMonth"
            label="Starting Month"
            options={rolloverStartMonthOptions}
          />

          <CurrencyField
            name="rolloverStartingBalance"
            label="Starting Balance"
            placeholder="$0.00"
            maskOptions={{
              prefix: '$',
              allowDecimal: true,
              decimalLimit: 2,
              allowNegative: false,
            }}
          />
        </FieldCellWithToggle>
      )}
    </PremiumFeatureOverlayTrigger>
  );
};

export default CategoryRolloverFields;
