import { DateTime } from 'luxon';
import React, { useState } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import Checkbox from 'components/lib/form/Checkbox';
import DateField from 'components/lib/form/DateField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import DeleteTransactionsConfirmation from 'components/routes/settings/dataSettings/DeleteTransactionConfirmation';

import {
  GET_OLDEST_DELETABLE_TRANSACTION_DATE,
  GET_PREVIEW_DELETE_TRANSACTIONS_COUNT,
} from 'common/lib/graphQl/transactions';
import useQuery from 'common/lib/hooks/useQuery';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';
import useModal from 'lib/hooks/useModal';

import { SETTINGS_DATA } from 'common/constants/copy';

type Props = {
  setDeletedTransactionsCount: (count: number) => void;
};

const CheckboxText = styled.span`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
  padding: ${({ theme }) => theme.spacing.xxsmall} 0;
`;

const CheckboxGroup = styled.div`
  padding: ${({ theme }) => theme.spacing.default};
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  border-radius: ${({ theme }) => theme.radius.small};
`;

const DeleteTransactionsForm = ({ setDeletedTransactionsCount }: Props) => {
  const { isDemoHousehold } = useDemoHousehold();
  const [ConfirmationModal, { open, close }] = useModal();
  const [beforeDate, setBeforeDate] = useState<string>('');
  const [includeSynced, setIncludeSynced] = useState<boolean>(true);
  const [includeManual, setIncludeManual] = useState<boolean>(true);
  const [includeUploaded, setIncludeUploaded] = useState<boolean>(true);

  const { data: oldestTransactionData, refetch: refetchOldestTransactionData } = useQuery(
    GET_OLDEST_DELETABLE_TRANSACTION_DATE,
    {
      variables: {
        includeSynced,
        includeUploaded,
        includeManual,
      },
    },
  );

  const transactionOldestDeletableDate = oldestTransactionData?.oldestDeletableTransactionDate
    ? DateTime.fromISO(oldestTransactionData.oldestDeletableTransactionDate)
    : DateTime.local();

  const { data: previewData, isNetworkRequestInFlight: loadingPreviewData } = useQuery(
    GET_PREVIEW_DELETE_TRANSACTIONS_COUNT,
    {
      variables: {
        beforeDate,
        includeSynced,
        includeUploaded,
        includeManual,
      },
      skip: !beforeDate || (!includeSynced && !includeUploaded && !includeManual),
    },
  );

  const transactionsCount = beforeDate
    ? previewData?.previewDeleteTransactions.totalCount || undefined
    : undefined;

  const isSubmitDisabled =
    !transactionsCount ||
    transactionsCount === 0 ||
    isDemoHousehold ||
    (!includeManual && !includeSynced && !includeUploaded);

  return (
    <Form onSubmit={open} preventSubmitWhileSubmitting>
      <DateField
        name="deleteTransactionsBefore"
        label={SETTINGS_DATA.FIELDS.DATE.LABEL}
        placeholder={SETTINGS_DATA.FIELDS.DATE.PLACEHOLDER}
        minDate={transactionOldestDeletableDate.toJSDate()}
        maxDate={DateTime.local().toJSDate()}
        onChange={(value) => {
          value && setBeforeDate(value);
        }}
        required
      />

      <CheckboxGroup>
        <Checkbox
          checked={includeSynced}
          onChange={({ target: { checked } }) => setIncludeSynced(checked)}
        >
          <CheckboxText>{SETTINGS_DATA.FIELDS.INCLUDE_SYNCED.LABEL}</CheckboxText>
        </Checkbox>

        <Checkbox
          checked={includeManual}
          onChange={({ target: { checked } }) => setIncludeManual(checked)}
        >
          <CheckboxText>{SETTINGS_DATA.FIELDS.INCLUDE_MANUAL.LABEL}</CheckboxText>
        </Checkbox>

        <Checkbox
          checked={includeUploaded}
          onChange={({ target: { checked } }) => setIncludeUploaded(checked)}
        >
          <CheckboxText>{SETTINGS_DATA.FIELDS.INCLUDE_UPLOADED.LABEL}</CheckboxText>
        </Checkbox>
      </CheckboxGroup>

      <FormSubmitButton pending={loadingPreviewData} disabled={isSubmitDisabled}>
        {SETTINGS_DATA.BUTTONS.SUBMIT(transactionsCount)}
      </FormSubmitButton>

      <FormContext.Consumer>
        {({ resetForm }) => (
          <ConfirmationModal>
            <DeleteTransactionsConfirmation
              beforeDate={beforeDate}
              includeSynced={includeSynced}
              includeManual={includeManual}
              includeUploaded={includeUploaded}
              expectedAffectedTransactionsCount={transactionsCount!}
              onCancel={close}
              onDelete={() => {
                resetForm();
                setBeforeDate('');
                refetchOldestTransactionData();
                setDeletedTransactionsCount(transactionsCount!);
                close();
              }}
            />
          </ConfirmationModal>
        )}
      </FormContext.Consumer>
    </Form>
  );
};

export default DeleteTransactionsForm;
