const newPalette = {
  // name: [light, dark]
  gray1: ['#ffffff', '#111110'],
  gray2: ['#f6f5f1', '#191918'],
  gray3: ['#f2f0ec', '#222221'],
  gray4: ['#ebe8e2', '#2a2a28'],
  gray5: ['#e4e1db', '#31312e'],
  gray6: ['#dcd9d3', '#3b3a37'],
  gray7: ['#d1cec8', '#494844'],
  gray8: ['#bebbb5', '#62605b'],
  gray9: ['#8f8c87', '#6f6d66'],
  gray10: ['#84827c', '#7c7a73'],
  gray11: ['#777570', '#b4b2ac'],
  gray12: ['#22201b', '#eeeeec'],
  orange1: ['#fefcfb', '#160f0d'],
  orange2: ['#fff5f0', '#1f1510'],
  orange3: ['#ffeadd', '#351c10'],
  orange4: ['#ffd7c1', '#4b1c03'],
  orange5: ['#ffc9ae', '#592508'],
  orange6: ['#ffb997', '#693318'],
  orange7: ['#fda782', '#804326'],
  orange8: ['#f28e63', '#a55630'],
  orange9: ['#f86713', '#f76b15'],
  orange10: ['#ec5900', '#e95f00'],
  orange11: ['#d44900', '#ff9c69'],
  orange12: ['#582d1a', '#ffdcca'],
  yellow1: ['#fefdfb', '#13110b'],
  yellow2: ['#fffae9', '#1c180f'],
  yellow3: ['#fff3c1', '#2c2207'],
  yellow4: ['#ffea9c', '#3c2a00'],
  yellow5: ['#ffdf78', '#483300'],
  yellow6: ['#f9d375', '#564102'],
  yellow7: ['#e7c268', '#695316'],
  yellow8: ['#d4ab3a', '#856a1f'],
  yellow9: ['#ffcb12', '#ffc700'],
  yellow10: ['#f6c223', '#f4bd00'],
  yellow11: ['#987100', '#ffcc16'],
  yellow12: ['#453a1e', '#fde8b3'],
  blue1: ['#fafdfe', '#0b161a'],
  blue2: ['#f2fafb', '#101b20'],
  blue3: ['#def7f9', '#082c36'],
  blue4: ['#caf1f6', '#003848'],
  blue5: ['#b5e9f0', '#004558'],
  blue6: ['#9ddde7', '#045468'],
  blue7: ['#7dcedc', '#12677e'],
  blue8: ['#3db9cf', '#11809c'],
  blue9: ['#00a2c7', '#00a2c7'],
  blue10: ['#0797b9', '#23afd0'],
  blue11: ['#107d98', '#4ccce6'],
  blue12: ['0d3c48', '#b6ecf7'],
  green1: ['#fbfefb', '#0e1511'],
  green2: ['#f5fbf5', '#141a15'],
  green3: ['#e9f6e9', '#1b2a1e'],
  green4: ['#daf1db', '#1d3a24'],
  green5: ['#c9e8ca', '#25482d'],
  green6: ['#b2ddb5', '#2d5736'],
  green7: ['#94ce9a', '#366740'],
  green8: ['#65ba74', '#3e7949'],
  green9: ['#46a758', '#46a758'],
  green10: ['#3e9b4f', '#53b365'],
  green11: ['#2a7e3b', '#71d083'],
  green12: ['#203c25', '#203c25'],
  pink1: ['#fffcfe', '#191117'],
  pink2: ['#fef7fb', '#21121d'],
  pink3: ['#fee9f5', '#37172f'],
  pink4: ['#fbdcef', '#4b143d'],
  pink5: ['#f6cee7', '#591c47'],
  pink6: ['#efbfdd', '#692955'],
  pink7: ['#e7acd0', '#833869'],
  pink8: ['#dd93c2', '#a84885'],
  pink9: ['#d6409f', '#d6409f'],
  pink10: ['#cf3897', '#de51a8'],
  pink11: ['#c2298a', '#ff8dcc'],
  pink12: ['#651249', '#fdd1ea'],
  red1: ['#fffcfc', '#181111'],
  red2: ['#fff8f7', '#1f1513'],
  red3: ['#feebe7', '#391714'],
  red4: ['#ffdcd3', '#4e1511'],
  red5: ['#ffcdc2', '#5e1c16'],
  red6: ['#fdbdaf', '#6e2920'],
  red7: ['#f5a898', '#853a2d'],
  red8: ['#ec8e7b', '#ac4d39'],
  red9: ['#e54d2e', '#e54d2e'],
  red10: ['#dd4425', '#ec6142'],
  red11: ['#d13415', '#ff977d'],
  red12: ['#5c271f', '#fbd3cb'],
  sky1: ['#f9feff', '#0d141f'],
  sky2: ['#f1fafd', '#111a27'],
  sky3: ['#e1f6fd', '#112840'],
  sky4: ['#d1f0fa', '#113555'],
  sky5: ['#bee7f5', '#154467'],
  sky6: ['#a9daed', '#1b537b'],
  sky7: ['#8dcae3', '#1f6692'],
  sky8: ['#60b3d7', '#197cae'],
  sky9: ['#7ce2fe', '#7ce2fe'],
  sky10: ['#74daf8', '#a8eeff'],
  sky11: ['#00749e', '#75c7f0'],
  sky12: ['#1d3e56', '#c2f3ff'],
  lime1: ['#fcfdfa', '#11130c'],
  lime2: ['#f8faf3', '#151a10'],
  lime3: ['#eef6d6', '#1f2917'],
  lime4: ['#e2f0bd', '#29371d'],
  lime5: ['#d3e7a6', '#334423'],
  lime6: ['#c2da91', '#3d522a'],
  lime7: ['#abc978', '#496231'],
  lime8: ['#8db654', '#577538'],
  lime9: ['#bdee63', '#bdee63'],
  lime10: ['#b0e64c', '#d4ff70'],
  lime11: ['#5c7c2f', '#bde56c'],
  lime12: ['#37401c', '#e3f7ba'],
  purple1: ['#fefcfe', '#18111b'],
  purple2: ['#fbf7fe', '#1e1523'],
  purple3: ['#f7edfe', '#301c3b'],
  purple4: ['#f2e2fc', '#3d224e'],
  purple5: ['#ead5f9', '#48295c'],
  purple6: ['#e0c4f4', '#54346b'],
  purple7: ['#d1afec', '#664282'],
  purple8: ['#be93e4', '#8457aa'],
  purple9: ['#8e4ec6', '#8e4ec6'],
  purple10: ['#8347b9', '#9a5cd0'],
  purple11: ['#8145b5', '#d19dff'],
  purple12: ['#402060', '#ecd9fa'],
  indigo1: ['#fdfdfe', '#11131f'],
  indigo2: ['#f7f9ff', '#141726'],
  indigo3: ['#edf2fe', '#182449'],
  indigo4: ['#e1e9ff', '#1d2e62'],
  indigo5: ['#d2deff', '#253974'],
  indigo6: ['#c1d0ff', '#304384'],
  indigo7: ['#abbdf9', '#3a4f97'],
  indigo8: ['#8da4ef', '#435db1'],
  indigo9: ['#3e63dd', '#3e63dd'],
  indigo10: ['#3358d4', '#5472e4'],
  indigo11: ['#3a5bc7', '#9eb1ff'],
  indigo12: ['#1f2d5c', '#d6e1ff'],
};

export default newPalette;
