import type { Duration, Interval } from 'luxon';
import { DateTime } from 'luxon';
import { isHoliday } from 'nyse-holidays';
import pluralize from 'pluralize';

type ISODate = string;

/**
 * Native JS date parsing assumes ISO strings (e.g. "2020-03-05") are UTC. Depending
 * on the local timezone, the date (Date) can be interpreted as the
 * previous day. This function considers date strings to be timezone-free, so
 * we use luxon's DateTime parsing to create the Date without tweaking the
 * hour.
 */
export const parseISODate = (isoDateString: ISODate) => DateTime.fromISO(isoDateString).toJSDate();

export const formatISODate = (date: Date) => DateTime.fromJSDate(date).toISODate();

export const formatFullDate = (date: Date) =>
  DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL);

export const isoDateToMonthAbbreviation = (isoDateString: ISODate) =>
  DateTime.fromISO(isoDateString).toFormat('MMM');

export const isoDateToMonthAndDay = (isoDateString: ISODate) =>
  DateTime.fromISO(isoDateString).toFormat('MMMM d');

export const isoDateToMonthAbbreviationAndDay = (isoDateString: ISODate) =>
  DateTime.fromISO(isoDateString).toFormat('MMM d');

export const isoDateToMonthAndYear = (isoDateString: ISODate) =>
  DateTime.fromISO(isoDateString).toFormat('MMMM yyyy');

export const isoDateToAbbreviatedMonthAndYear = (isoDateString: ISODate) =>
  `${DateTime.fromISO(isoDateString).toFormat(`MMM`)} '${DateTime.fromISO(isoDateString).toFormat(
    'yy',
  )}`;

export const isoDateToMonthAndAbbreviatedYear = (isoDateString: ISODate) =>
  `${DateTime.fromISO(isoDateString).toFormat(`MMMM`)} '${DateTime.fromISO(isoDateString).toFormat(
    'yy',
  )}`;

export const isoDateToAbbreviatedMonthDayAndYear = (isoDateString: string) =>
  DateTime.fromISO(isoDateString).toFormat('MMM d, yyyy');

export const formatMonthWithYear = (date: DateTime) =>
  date.toLocaleString({
    month: 'long',
    year: 'numeric',
  });

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const YEAR = DAY * 365;

/** i.e. '3m', '4h', '5d', '2y' */
export const formatRelativeTimeShort = (date: DateTime) => {
  const secondsAgo = Math.abs(date.diffNow('seconds').seconds);

  if (secondsAgo < MINUTE) {
    return `${Math.floor(secondsAgo)}s`;
  } else if (secondsAgo < HOUR) {
    return `${Math.floor(secondsAgo / MINUTE)}m`;
  } else if (secondsAgo < DAY) {
    return `${Math.floor(secondsAgo / HOUR)}h`;
  } else if (secondsAgo < YEAR) {
    return `${Math.floor(secondsAgo / DAY)}d`;
  } else {
    return `${Math.floor(secondsAgo / YEAR)}y`;
  }
};

export const formatRelativeTime = (date: DateTime) => {
  const secondsAgo = Math.abs(date.diffNow('seconds').seconds);
  let time: number | null = null;

  if (secondsAgo < MINUTE) {
    return 'less than a minute';
  } else if (secondsAgo < HOUR) {
    time = Math.floor(secondsAgo / MINUTE);
    return `${time} ${pluralize('minute', time)}`;
  } else if (secondsAgo < DAY) {
    time = Math.floor(secondsAgo / HOUR);
    return `${time} ${pluralize('hour', time)}`;
  } else if (secondsAgo < YEAR) {
    time = Math.floor(secondsAgo / DAY);
    return `${time} ${pluralize('day', time)}`;
  } else {
    time = Math.floor(secondsAgo / YEAR);
    return `${time} ${pluralize('year', time)}`;
  }
};

export const hasDatePassed = (date: DateTime) => date < DateTime.local();

export const getNumberOfMonthsToDate = (isoDateString: ISODate) =>
  DateTime.local().until(DateTime.fromISO(isoDateString)).count('months') - 1;

// Luxon's built-in includes is end-exlusive [start, end), this makes it inclusive, [start, end]
export const luxonIntervalContainsInclusive = (interval: Interval, date: DateTime): boolean =>
  interval.contains(date) || interval.end.equals(date);

export const isNYSEOpen = (date: DateTime): boolean =>
  !isWeekend(date) && !isHoliday(date.toJSDate());

export const isWeekend = (date: DateTime) => [0, 6].includes(date.toJSDate().getDay());

export const getQuarterForDate = (date: DateTime) => Math.floor((date.month + 3) / 3);

export const isSameDay = (date1: DateTime, date2: DateTime) =>
  date1.toISODate() === date2.toISODate();

export const isSameMonth = (date1: DateTime, date2: DateTime) => date1.month === date2.month;

export const isoDateToMonthAbbreviationAndDayWithToday = (isoDateString: ISODate) =>
  isSameDay(DateTime.fromISO(isoDateString), DateTime.local())
    ? 'Today'
    : isoDateToMonthAbbreviationAndDay(isoDateString);

export const getStartOfCurrentMonth = () => DateTime.local().startOf('month');

export const getStartOfCurrentMonthISO = () => getStartOfCurrentMonth().toISODate();

export const getStartOfPreviousMonth = () => DateTime.local().minus({ months: 1 }).startOf('month');

export const isTimestampPastExpiration = (timestamp: string, expiration: Duration) => {
  const dt = DateTime.fromISO(timestamp);
  const diff = dt.diffNow().milliseconds;

  return -diff > expiration.as('milliseconds');
};

// Removes the time of the date ignoring the second half of the ISO date
export const getISODateWithoutTimezone = (date: ISODate) => date?.split('T')[0];
