import React from 'react';
import styled from 'styled-components';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import type { Props as TimeframeTrendIndicatorProps } from 'components/lib/ui/TimeframeTrendIndicator';
import TimeframeTrendIndicator from 'components/lib/ui/TimeframeTrendIndicator';

import { formatCurrency } from 'common/utils/Currency';

type Props = {
  startAmount: number;
  currentAmount: number;
  timeframeText: string;
  className?: string;
  hideCurrentAmount?: boolean;
} & Pick<TimeframeTrendIndicatorProps, 'isAsset'>;

export const CurrentNetWorth = styled(Text).attrs({ weight: 'medium' })`
  transform: translateY(1px);
  font-size: 20px;

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-bottom: 0;
  }
`;

export const StyledTimeframeTrendIndicator = styled(TimeframeTrendIndicator)`
  font-size: ${({ theme }) => theme.fontSize.base};

  span {
    text-transform: initial;
    display: inline-block;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: 0;
    margin-left: ${({ theme }) => theme.spacing.xsmall};
  }
`;

export const NetWorthContainer = styled(FlexContainer)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    flex-direction: row;
    align-items: baseline;
  }
`;

const DashboardWidgetAmountHeader = ({
  className,
  startAmount,
  currentAmount,
  timeframeText,
  isAsset,
  hideCurrentAmount,
}: Props) => (
  <NetWorthContainer className={className}>
    {!hideCurrentAmount && (
      <CurrentNetWorth {...sensitiveClassProps}>{formatCurrency(currentAmount)}</CurrentNetWorth>
    )}
    <StyledTimeframeTrendIndicator
      startAmount={startAmount}
      endAmount={currentAmount}
      timeframeDisplay={timeframeText}
      isAsset={isAsset}
    />
  </NetWorthContainer>
);

export default DashboardWidgetAmountHeader;
