import { gql } from 'common/generated/gql';

export const GET_MERCHANTS_SEARCH = gql(`
  query GetMerchantsSearch($search: String, $limit: Int) {
    merchants(search: $search, limit: $limit, orderBy: TRANSACTION_COUNT) {
      id
      name
      transactionCount
    }
  }
`);

export const GET_MERCHANTS_SEARCH_WITH_LOGO = gql(`
  query GetMerchantsSearchWithLogo($search: String, $limit: Int) {
    merchants(search: $search, limit: $limit, orderBy: TRANSACTION_COUNT) {
      id
      name
      transactionCount
      logoUrl
    }
  }
`);
