import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import TextAreaField from 'components/lib/form/TextAreaField';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import RequestRefundHelpCenterText from 'components/support/RequestRefundHelpCenterText';

import getSupportApi from 'common/lib/api/support';
import useSupportFormOptions from 'common/lib/hooks/support/useSupportFormOptions';
import type { FormValues } from 'common/lib/support/form';
import { prepareSupportTicketFormData, SUPPORT_FORM_INITIAL_VALUES } from 'common/lib/support/form';
import { track } from 'lib/analytics/segment';
import api from 'lib/api';
import { useModalContext } from 'lib/contexts/ModalContext';
import useTrack from 'lib/hooks/useTrack';
import { errorToast } from 'lib/ui/toast';
import { getUserEmail } from 'state/user/selectors';

import { AnalyticsEventNames } from 'common/constants/analytics';
import * as COPY from 'common/constants/copy';

import { ContactSupportFormType, ContactSupportTopic } from 'common/generated/graphql';

const Content = styled(FlexContainer).attrs({ column: true })`
  padding: 0 ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.xxlarge};
  gap: ${({ theme }) => theme.spacing.large};

  > div:not(:last-child) {
    margin-bottom: unset;
  }
`;

const ReplyToContainer = styled(FlexContainer).attrs({ column: true })`
  gap: ${({ theme }) => theme.spacing.xxsmall};
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const RequestRefundInnerForm = () => {
  const { isSubmitting } = useFormContext();
  const { reasonOptions, isLoadingInitialData } = useSupportFormOptions({
    formType: ContactSupportFormType.REFUND_REQUEST,
    topic: ContactSupportTopic.REFUND,
  });

  return (
    <>
      <SelectField
        name="reason"
        label={COPY.SUPPORT.REQUEST_REFUND_FORM_REASON_LABEL}
        placeholder={COPY.SUPPORT.FORM_REASON_PLACEHOLDER}
        options={reasonOptions}
        isLoading={isLoadingInitialData}
        isDisabled={isSubmitting}
        required
      />
      <TextAreaField
        name="description"
        label={COPY.SUPPORT.REQUEST_REFUND_FORM_DESCRIPTION_LABEL}
        disabled={isSubmitting}
        minRows={3}
        minLength={12}
        errorLabel="Description"
        required
      />
    </>
  );
};

export type Props = {
  initialValues?: Partial<FormValues>;
  next: (params?: any) => void;
  goBack?: () => void;
};

const RequestRefundModal = ({ next, initialValues }: Props) => {
  const { close } = useModalContext();

  const email = useSelector(getUserEmail);

  const onSubmit = useCallback(
    async (values: Partial<FormValues>) => {
      try {
        const data = prepareSupportTicketFormData(values, email, window.location.pathname);
        await getSupportApi(api).createTicket(data);
        track(AnalyticsEventNames.RefundRequestCreated);
        next();
      } catch (e) {
        errorToast(COPY.SUPPORT.ERROR_TOAST_SUBTEXT);
      }
    },
    [email],
  );

  useTrack(AnalyticsEventNames.RefundRequestFormOpened);

  return (
    <ModalCard
      title="Submit a Refund Request"
      description={<RequestRefundHelpCenterText />}
      hideBottomBorder
    >
      <Form<Partial<FormValues>>
        initialValues={{
          ...SUPPORT_FORM_INITIAL_VALUES,
          topic: ContactSupportTopic.REFUND,
          grantSupportAccountAccess: false,
          ...initialValues,
        }}
        onSubmit={(values) => onSubmit(values)}
      >
        <Content>
          <ReplyToContainer>
            <Text weight="medium" size="small">
              Your email address
            </Text>
            <Text>{email}</Text>
          </ReplyToContainer>
          <RequestRefundInnerForm />
        </Content>
        <CardFooter>
          <DefaultButton onClick={close}>Cancel</DefaultButton>
          <FormSubmitButton size="small">Submit request</FormSubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

export default RequestRefundModal;
