import { DateTime } from 'luxon';
import { useMemo } from 'react';

import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';

export const useRecurringMerchantLastTransactions = (merchantId: string | undefined) => {
  const { data, ...queryResult } = useQuery(MERCHANT_LAST_TRANSACTIONS_QUERY, {
    fetchPolicy: 'cache-first',
    skip: !merchantId,
    variables: {
      endDate: DateTime.local().toISODate(),
      isMerchant: true,
      merchantId: merchantId ?? '',
    },
  });

  const totalCount = data?.allTransactions?.totalCount;

  const lastTransactions = useMemo(
    () => data?.allTransactions?.results ?? [],
    [data?.allTransactions],
  );

  return {
    ...queryResult,
    totalCount,
    lastTransactions,
  };
};

const MERCHANT_LAST_TRANSACTIONS_QUERY = gql(/* GraphQL */ `
  query Common_GetReviewedMerchantLastTransactions(
    $merchantId: UUID!
    $endDate: Date!
    $isMerchant: Boolean!
  ) {
    allTransactions(filters: { merchants: [$merchantId], endDate: $endDate })
      @include(if: $isMerchant) {
      totalCount
      results(limit: 3) {
        id
        date
        amount
        isRecurring
        category {
          id
          name
          icon
        }
      }
    }
  }
`);
