import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import FieldCell, { GroupLeft } from 'components/lib/form/FieldCell';
import ToggleField from 'components/lib/form/ToggleField';

import { fieldStyleHover } from 'lib/styles/fieldStyleMixin';

const StyledFieldCell = styled(FieldCell)``;

const Root = styled.div`
  cursor: default;

  &:hover ${StyledFieldCell} {
    ${fieldStyleHover}
  }

  ${GroupLeft} {
    flex-grow: 1;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const Title = styled.span`
  cursor: pointer;
  display: block;
  width: 100%;
`;

const Subtitle = styled.div``;

type Props = {
  toggleFieldProps: React.ComponentProps<typeof ToggleField>;
  fieldCellProps: Pick<
    React.ComponentProps<typeof FieldCell>,
    'title' | 'subtitle' | 'className' | 'rightAccessory'
  >;
  className?: string;
  children?: React.ReactNode;
};

const FieldCellWithToggle = ({ fieldCellProps, toggleFieldProps, className, children }: Props) => {
  const { title, subtitle, rightAccessory } = fieldCellProps;
  const { setFieldValue, values } = useFormContext();

  const { name: toggleFieldName } = toggleFieldProps;

  const handleInteraction = useCallback(
    () => setFieldValue(toggleFieldName, !values[toggleFieldName]),
    [toggleFieldName, values, setFieldValue],
  );

  return (
    <Root>
      <StyledFieldCell
        title={
          <Title
            role="button"
            tabIndex={0}
            onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
              e.stopPropagation();
              handleInteraction();
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.stopPropagation();
                handleInteraction();
              }
            }}
          >
            {title}
          </Title>
        }
        subtitle={subtitle && <Subtitle>{subtitle}</Subtitle>}
        rightAccessory={rightAccessory ?? <ToggleField {...toggleFieldProps} hideLabel />}
        className={className}
      >
        {!!values[toggleFieldName] && children}
      </StyledFieldCell>
    </Root>
  );
};

export default FieldCellWithToggle;
