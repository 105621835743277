import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';

/** For use with PillTab component. Matches the design system Figma for TabGroup. */
const TabGroup = styled(FlexContainer).attrs({ alignCenter: true })`
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

export default TabGroup;
