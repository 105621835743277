import * as R from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';

import withSensitiveData from 'components/lib/higherOrder/withSensitiveData';

import { formatCurrency, formatCurrencyNoCents, maybeRoundNumber } from 'common/utils/Currency';
import { compare } from 'common/utils/Math';

import type { Color } from 'types/Styles';

const Root = styled.span<{ color?: Color; $isBold: boolean }>`
  --color: ${({ theme, color }) => (color ? theme.color[color] : 'inherit')};

  color: var(--color);
  display: inline-block;

  ${({ theme, $isBold }) =>
    $isBold &&
    css`
      font-weight: ${theme.fontWeight.medium};
    `}
`;

export type CurrencyType = 'income' | 'expense' | 'savings';

export type Props = Pick<React.HTMLProps<HTMLSpanElement>, 'onClick'> & {
  className?: string;

  /**
   * Whether this Currency represents an income, expense or savings.
   * If you're using this for a transaction amount, you just want 'expense'
   */
  type: CurrencyType;

  /** Income is normally negative, and expense positive. */
  value?: number;

  /** Force a green color for positive values, and negative. */
  emphasis?: boolean;

  /** Force a + sign, and - sign and red for negative. */
  showSign?: boolean;

  /** Round to the nearest dollar. */
  round?: boolean;
  children?: React.ReactNode;

  /** Display with heavier font-weight. */
  bold?: boolean;
};

const unitValue = (type: Props['type']) => (type === 'expense' ? -1 : 1);

const getSign = (value: number, type: CurrencyType) => {
  if (value === 0) {
    return '';
  }

  if (type === 'savings') {
    return value > 0 ? '+' : '-';
  }

  return value < 0 ? '-' : '+';
};

const getColor = (value: number, type: CurrencyType) =>
  compare<Color>(value, 0, {
    equal: 'textLight',
    lessThan: type === 'savings' ? 'textLight' : 'redText',
    greaterThan: type === 'savings' ? 'blueText' : 'greenText',
  });

/**
 * By default, CashFlowCurrency renders _normal_ values without a sign (e.g. $10).
 * _Normal_ values match the result of `unitValue`: positive for income and negative for expense.
 *
 * `value`s that are not _normal_ always render a sign (e.g. -$10 for income, +$10 for expense).
 *
 * `emphasis` causes signs to always be displayed, and colors the text red or green appropriately.
 */
export const CashFlowCurrency = ({
  className,
  value,
  emphasis,
  round,
  type,
  showSign,
  bold,
  children,
  ...htmlProps
}: Props) => {
  let text;
  let color: Color;
  if (!R.isNil(value)) {
    const rounded = maybeRoundNumber(value, round);
    const sign = showSign || unitValue(type) * rounded < 0 ? getSign(rounded, type) : '';

    color = getColor(rounded, type);

    const abs = Math.abs(rounded);
    const formatted = round ? formatCurrencyNoCents(abs) : formatCurrency(abs);

    text = `${sign}${formatted}`;
  } else {
    text = '-';
    color = 'textLight';
  }

  return (
    <Root
      {...htmlProps}
      $isBold={!!bold}
      className={className}
      color={emphasis && value !== 0 ? color : undefined}
    >
      {text}
      {children}
    </Root>
  );
};

export default withSensitiveData(CashFlowCurrency);
