import * as R from 'ramda';
import React from 'react';

import withSensitiveData from 'components/lib/higherOrder/withSensitiveData';

import { formatCurrency, formatCurrencyNoCents, maybeRoundNumber } from 'common/utils/Currency';

export type Props = {
  className?: string;

  /** Income is normally negative, and expense positive. */
  value?: number;

  /** Round to the nearest dollar. */
  round?: boolean;
};

export const Currency = ({ className, value, round }: Props) => {
  let text;
  if (!R.isNil(value)) {
    const rounded = maybeRoundNumber(value, round);
    const formatted = round ? formatCurrencyNoCents(rounded) : formatCurrency(rounded);

    text = `${formatted}`;
  } else {
    text = '-';
  }

  return <span className={className}>{text}</span>;
};

export default withSensitiveData(Currency);
