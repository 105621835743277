import pluralize from 'pluralize';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as React from 'react';

import SideDrawer from 'components/lib/ui/SideDrawer';
import Text from 'components/lib/ui/Text';
import TransactionBulkUpdateDrawerAccounts from 'components/transactions/TransactionBulkUpdateDrawerAccounts';
import TransactionBulkUpdateDrawerForm from 'components/transactions/TransactionBulkUpdateDrawerForm';

import { GET_ACCOUNTS_FOR_TRANSACTIONS_QUERY } from 'common/lib/graphQl/accounts';
import useTransactionTags from 'common/lib/hooks/transactions/useTransactionTags';
import useQuery from 'common/lib/hooks/useQuery';
import { formatThousands } from 'common/utils/Number';
import { useTransactionListContext } from 'lib/contexts/TransactionsListContext';
import useGlobalKey from 'lib/hooks/useGlobalKey';

import type { TransactionFilterInput } from 'common/generated/graphql';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  selectedTransactionIds: string[];
  excludedTransactionIds: string[];
  isAllSelected: boolean;
  selectedCount: number;
};

const TransactionBulkUpdateDrawer = ({
  onClose,
  onSuccess,
  selectedTransactionIds,
  excludedTransactionIds,
  isAllSelected,
  selectedCount,
}: Props) => {
  const { filters: rawFilters } = useTransactionListContext();
  // Omit amountFilter because the BE only needs the gte/lte amounts
  // The amountFilter type can be inferred from the values of absAmountGte/Lte
  const filters = R.omit(['amountFilter'], rawFilters);

  useGlobalKey('esc', (e) => {
    e.stopPropagation(); // prevents the drawer from closing and bulk update state from being reset
  });

  const { data, isLoadingInitialData } = useQuery(GET_ACCOUNTS_FOR_TRANSACTIONS_QUERY, {
    variables: {
      selectedTransactionIds,
      excludedTransactionIds,
      isAllSelected,
      filters: filters as TransactionFilterInput,
    },
  });
  const accounts = data?.getAccountsForTransactions ?? [];
  const { tags, isNetworkRequestInFlight: isLoadingTags } = useTransactionTags({
    bulkParams: {
      isAllSelected,
      selectedTransactionIds,
      excludedTransactionIds,
      filters,
    },
  });

  return (
    <SideDrawer
      onClose={onClose}
      title={
        <Text size="xlarge" weight="medium">
          Edit {formatThousands(selectedCount)} {pluralize('transaction', selectedCount)}
        </Text>
      }
    >
      <TransactionBulkUpdateDrawerAccounts isLoading={isLoadingInitialData} accounts={accounts} />
      <TransactionBulkUpdateDrawerForm
        filters={filters}
        selectedIds={selectedTransactionIds}
        excludedIds={excludedTransactionIds}
        isAllSelected={isAllSelected}
        selectedCount={selectedCount}
        isLoadingTags={isLoadingTags}
        initialValues={{ tags: RA.isNotEmpty(tags) ? R.map(R.prop('id'), tags) : undefined }}
        onSuccess={onSuccess}
      />
    </SideDrawer>
  );
};

export default TransactionBulkUpdateDrawer;
