import type { DropResult } from 'react-beautiful-dnd';

import useUpdateAccountOrderMutation from 'lib/hooks/accounts/useUpdateAccountOrderMutation';

const useAccountsDrag = () => {
  const [updateAccountOrder] = useUpdateAccountOrderMutation();

  const handleOnDragEnd = ({ draggableId: accountId, source, destination }: DropResult) => {
    if (!destination) {
      return;
    }
    updateAccountOrder(accountId, source.index, destination.index);
  };

  return { handleOnDragEnd };
};

export default useAccountsDrag;
