import styled from 'styled-components';

import boxShadow from 'common/lib/styles/boxShadow';
import { color } from 'common/lib/theme/dynamic';

const Popover = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  ${boxShadow.large}
  border: 1px solid ${color.grayLight};
`;

export default Popover;
