import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextAreaField from 'components/lib/form/TextAreaField';
import TextField from 'components/lib/form/TextField';
import CardFooter from 'components/lib/ui/CardFooter';
import CheckCircle from 'components/lib/ui/CheckCircle';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import ManualLink from 'components/lib/ui/link/ManualLink';

import { REQUEST_INSTITUTION_MUTATION } from 'common/lib/graphQl/institutions';
import { institutionRequestSchema } from 'common/lib/institutions/request';
import { useContactSupportContext } from 'lib/contexts/ContactSupportContext';
import { useModalContext } from 'lib/contexts/ModalContext';

import type {
  RequestInstitution,
  RequestInstitutionVariables,
} from 'common/generated/graphQlTypes/RequestInstitution';

const FormWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: ${({ theme }) => theme.spacing.xxsmall};
`;

const Footer = styled(FlexContainer).attrs({ justifyEnd: true, alignCenter: true })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
`;

const StyledSubmitButton = styled(FormSubmitButton)`
  width: auto;
  margin-top: 0px;
  margin-left: 14.5px;
`;

const HeaderText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin: ${({ theme }) => theme.spacing.default} 0px;
`;

const BodyText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.color.text};
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
`;

type Props = {
  allowManualAccount: boolean;
  onBack: () => void;
  initialInstitutionName: string;
  onClickAddManualAccount?: () => void;
  onSearchAgain?: () => void;
};

const RequestInstitutionModal = ({
  allowManualAccount,
  onBack,
  initialInstitutionName,
  onClickAddManualAccount,
  onSearchAgain,
}: Props) => {
  const initialValues = {
    institutionName: initialInstitutionName,
  };

  const [requestInstitution] = useMutation<RequestInstitution, RequestInstitutionVariables>(
    REQUEST_INSTITUTION_MUTATION,
  );

  const { close } = useModalContext();

  const [submitted, setSubmitted] = useState<boolean>(false);

  const { openContactSupportModal } = useContactSupportContext();

  return submitted ? (
    <ModalCard title="Message sent" hideBottomBorder onClickBackButton={onSearchAgain}>
      <FlexContainer margin="xlarge" column>
        <CheckCircle />
        <HeaderText>Connection request sent</HeaderText>
        <BodyText>
          <p>Thank you for taking the time to submit this information.</p>
          <p>
            Our team reviews new connection requests on a quarterly basis, and we&apos;ll do our
            best to make it happen with our data providers. Please be aware that there&apos;s no
            guarantee or specific timeline for when your institution will be supported.
          </p>
          <p>
            In the meantime, we recommend adding a manual account to track your data from this
            institution.
          </p>
        </BodyText>
      </FlexContainer>

      <CardFooter>
        {allowManualAccount && (
          <DefaultButton onClick={onClickAddManualAccount}>Add manual account</DefaultButton>
        )}

        <PrimaryButton onClick={close}>Okay</PrimaryButton>
      </CardFooter>
    </ModalCard>
  ) : (
    <ModalCard
      title="Request new connection"
      onClickBackButton={onBack}
      description={
        <div>
          Financial companies connect to Monarch using a secure data provider. If you can&apos;t
          find your account we can ask the data provider to support it for you.
        </div>
      }
      hideBottomBorder
    >
      <FormWrapper>
        <Form
          initialValues={initialValues}
          onSubmit={async ({
            institutionName,
            website,
            description,
          }: {
            institutionName: string;
            website: string;
            description: string;
          }) => {
            await requestInstitution({
              variables: { input: { institutionName, website, description } },
            });
          }}
          onSubmitSuccess={() => setSubmitted(true)}
          overrideValidationSchema={institutionRequestSchema}
        >
          <FormContext.Consumer>
            {({ isSubmitting }) => (
              <>
                <TextField
                  name="institutionName"
                  label="Name of institution"
                  disabled={isSubmitting}
                  required
                />

                <TextField
                  name="website"
                  disabled={isSubmitting}
                  label="Website"
                  placeholder="e.g. https://www.examplebank.com"
                  required
                />

                <TextAreaField
                  name="description"
                  minRows={2}
                  label="Description"
                  disabled={isSubmitting}
                />

                <Text size="small">
                  Responses on this form are logged automatically and do not go to our customer
                  support team. Please fill out our{' '}
                  <ManualLink onClick={() => openContactSupportModal()}>contact form</ManualLink> to
                  receive assistance from our team.
                </Text>

                <Footer>
                  <DefaultButton size="medium" onClick={onBack}>
                    Cancel
                  </DefaultButton>
                  <StyledSubmitButton>Send</StyledSubmitButton>
                </Footer>
              </>
            )}
          </FormContext.Consumer>
        </Form>
      </FormWrapper>
    </ModalCard>
  );
};

export default RequestInstitutionModal;
