import JSON5 from 'json5';
import * as R from 'ramda';

import { BLOCKS } from 'common/lib/assistant/blocks';
import type { MessageBlockType } from 'common/lib/assistant/blocks/types';

type RawBlock = {
  type: string;
  content: string;
  incomplete: boolean;
};

export const tryParseJson = (value: string): Record<string, any> | undefined => {
  try {
    const parsed = JSON5.parse(value);
    return parsed;
  } catch (e) {
    return undefined;
  }
};

export const parseMessageContentToBlocks = (content: string): RawBlock[] => {
  // this regex is pretty cryptic, but hence is the nature of regexes...
  // basically it's extracting blocks delimited by ```, and anything else becomes a text block
  const regex = new RegExp(/(```(\S+)([\s\S]*?)(```|$))|([\s\S]+?((?=```\S+)|$))/g);
  const matches = [...content.matchAll(regex)];

  const blocks = R.flatten(
    matches.map((match) => {
      const type = match[2]?.trim();
      let content = type ? match[3] : match[5];
      content = content?.trim();

      const incomplete = !!type && match[4] !== '```';

      if (!content) {
        return [];
      }

      return [
        {
          type: type ?? 'text',
          content,
          incomplete,
        },
      ];
    }),
  );

  return blocks;
};

export const parseRawBlocks = (rawBlocks: RawBlock[]): MessageBlockType[] => {
  const blocks = R.flatten(
    rawBlocks.map(({ type, content, incomplete }) => {
      const parser = BLOCKS[type];

      if (!parser) {
        return [];
      }

      try {
        const data = parser.parse(content, incomplete);
        return [{ type, data, incomplete }];
      } catch (e) {
        return [];
      }
    }),
  );

  return blocks;
};

/** Get blocks from single message content. */
export const getBlocksFromMessageContent = (content: string): MessageBlockType[] => {
  const rawBlocks = parseMessageContentToBlocks(content);
  const blocks = parseRawBlocks(rawBlocks);

  return blocks;
};

/** Get blocks from multiple message (flat map). */
export const getBlocksFromMessages = <T extends { content: string }>(
  messages: T[],
): MessageBlockType[] =>
  R.flatten(messages.map(({ content }) => getBlocksFromMessageContent(content)));
