import React, { useState } from 'react';

import Confirmation from 'components/lib/ui/Confirmation';
import Text from 'components/lib/ui/Text';

import { useModalContext } from 'lib/contexts/ModalContext';

type Props = {
  attachmentId?: GraphQlUUID;
  remove: () => void;
  onCancelClick: () => void;
  onDeleteClick: (id: GraphQlUUID | undefined, remove: () => void) => void | Promise<void>;
};

const TransactionDrawerDeleteConfirmationModal = ({
  onCancelClick,
  onDeleteClick,
  attachmentId,
  remove,
}: Props) => {
  const { close } = useModalContext();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Confirmation
      title="Delete Attachment"
      confirm="Delete Attachment"
      useDangerButton
      onCancel={onCancelClick}
      isLoading={isLoading}
      onConfirm={async () => {
        setIsLoading(true);
        await onDeleteClick(attachmentId, remove);
        close();
      }}
    >
      <Text>
        Are you sure you want to delete this attachment? This will permanently remove the file from
        Monarch, and cannot be undone.
      </Text>
    </Confirmation>
  );
};

export default TransactionDrawerDeleteConfirmationModal;
