import pluralize from 'pluralize';
import * as React from 'react';
import styled from 'styled-components';

import Confirmation from 'components/lib/ui/Confirmation';
import Text from 'components/lib/ui/Text';

import { DELETE_HOUSEHOLD_CONFIRMATION_TITLE } from 'common/constants/copy';

const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  onCancel?: () => void;
  onConfirm: () => void;
  daysLeftOfTrial: number | null;
};

const DeleteHouseholdInitialConfirmation = ({ onConfirm, onCancel, daysLeftOfTrial }: Props) => (
  <Confirmation
    title={DELETE_HOUSEHOLD_CONFIRMATION_TITLE}
    confirm="Delete"
    onCancel={onCancel}
    onConfirm={onConfirm}
  >
    <Text>
      {daysLeftOfTrial && daysLeftOfTrial > 0
        ? `You have ${daysLeftOfTrial} ${pluralize('days', daysLeftOfTrial)} left in your trial.`
        : 'This action is not reversible.'}
    </Text>
    <StyledText>Are you sure you wish to delete all your data?</StyledText>
  </Confirmation>
);

export default DeleteHouseholdInitialConfirmation;
