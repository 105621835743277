import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { useMemo } from 'react';

import { GET_CATEGORY_GROUPS } from 'common/lib/graphQl/categoryGroups';

import type { CategoryGroupFieldsFragment } from 'common/generated/graphql';
import { Tuple } from 'common/types';

const useCategoryGroupSelectOptions = (categoryType?: string) => {
  const { data: categoryGroupData, loading } = useQuery(GET_CATEGORY_GROUPS);
  const { categoryGroups = [] } = categoryGroupData ?? {};

  const filteredCategoryGroups = R.filter((categoryGroup: CategoryGroupFieldsFragment) => {
    if (!categoryType) {
      return true;
    }
    return categoryGroup.type === categoryType;
  }, categoryGroups);

  const options = useMemo(
    () =>
      filteredCategoryGroups.map(({ id, name, type }) => ({
        value: id,
        label: name,
        type,
      })),
    [filteredCategoryGroups],
  );

  return Tuple(loading, options, categoryGroups);
};

export default useCategoryGroupSelectOptions;
