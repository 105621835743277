import * as RA from 'ramda-adjunct';
import React, { useState, useContext } from 'react';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';

import Empty from 'components/lib/ui/Empty';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Modal from 'components/lib/ui/Modal';
import TableLoadingFooter from 'components/lib/ui/table/TableLoadingFooter';
import EditMerchantModal from 'components/settings/merchants/EditMerchantModal';
import MerchantListItem from 'components/settings/merchants/MerchantListItem';

import ScrollContext from 'lib/contexts/ScrollContext';

import type { Web_GetMerchantSettingsPage_merchants } from 'common/generated/graphQlTypes/Web_GetMerchantSettingsPage';

const Root = styled(FlexContainer).attrs({ column: true })`
  width: 100%;
`;

const SpinnerContainer = styled(FlexContainer).attrs({ center: true })`
  margin: ${({ theme }) => theme.spacing.xlarge} 0;
`;

type Props = {
  merchants: Web_GetMerchantSettingsPage_merchants[] | null;
  isLoading: boolean;
  hideFooter: boolean;
  search: string;
  refetchMerchants: () => void;
  onFetchMore: () => void;
};

const MerchantList = ({
  merchants: maybeMerchants,
  isLoading,
  hideFooter,
  search,
  refetchMerchants,
  onFetchMore,
}: Props) => {
  const merchants = maybeMerchants ?? [];
  const [editingMerchantId, setEditingMerchantId] = useState<string | null>(null);
  const { scrollRef } = useContext(ScrollContext);

  if (isLoading) {
    return (
      <SpinnerContainer>
        <LoadingSpinner />
      </SpinnerContainer>
    );
  }

  if (RA.isNilOrEmpty(merchants)) {
    return (
      <Empty
        title="No merchants found"
        subtitle={
          RA.isNilOrEmpty(search)
            ? "You don't have merchants assigned to transactions yet."
            : `We couldn't find any merchants matching your search of "${search}".`
        }
      />
    );
  }

  return (
    <Root>
      <Virtuoso
        style={{ height: 'auto' }}
        data={merchants}
        endReached={onFetchMore}
        customScrollParent={scrollRef?.current ?? undefined}
        itemContent={(_, merchant) => (
          <MerchantListItem
            key={merchant.id}
            merchant={merchant}
            onClickEdit={() => setEditingMerchantId(merchant.id)}
          />
        )}
        components={{ Footer: () => <TableLoadingFooter hide={hideFooter} /> }}
      />
      {RA.isNotNil(editingMerchantId) && (
        <Modal onClose={() => setEditingMerchantId(null)}>
          <EditMerchantModal merchantId={editingMerchantId} afterDelete={refetchMerchants} />
        </Modal>
      )}
    </Root>
  );
};

export default MerchantList;
