import { Duration } from 'luxon';
import { evolve, pathSatisfies } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import { useCallback, useEffect, useState } from 'react';

import type { OnClickApplyOptions } from 'components/reports/filters/FilterMenu';
import type {
  FilterMenuOption,
  FilterMenuSection,
  WithPath,
} from 'components/reports/filters/types';
import { addPathToOptions, getAllLeavesForOptions } from 'components/reports/filters/utils';

import useQuery from 'common/lib/hooks/useQuery';
import useQueryWithCacheExpiration from 'common/lib/hooks/useQueryWithCacheExpiration';
import { mergeNextPage } from 'common/utils/pagination';
import { adaptSelectedOptionsToTransactionsFilters, getFilterSectionsFromQuery } from 'lib/filters';
import { trackFiltersApplied } from 'lib/filters/sections';
import type { SectionAdapterOptions } from 'lib/filters/types';

import { gql } from 'common/generated/gql';
import type { TransactionFilters } from 'types/filters';

type Options<TFilters extends Partial<TransactionFilters>> = {
  page: string;
  filters: TFilters;
  onApplyFilters: (filters: Partial<TransactionFilters>) => void;
} & Omit<SectionAdapterOptions, 'hasTransactionsImportedFromMint'>;

/**
 * Hook responsible for managing the state of transactions filters in the FilterMenu component.
 * It doesn't dispatch any actions or mutates anything, it just provides the state and the
 * logic to calculate the filters to update based on the selected options.
 */
export const useTransactionFilterMenu = <TFilters extends Partial<TransactionFilters>>(
  options: Options<TFilters>,
) => {
  const { page, onApplyFilters, filters, renderAccountLogo, renderMerchantAccessory, renderTag } =
    options;

  // isNetworkRequestInFlight doesn't change when we call fetchMore, so we track it manually.
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const { data, isLoadingInitialData, fetchMore } = useQuery(FILTER_MENU_QUERY, {
    variables: { search: undefined, includeIds: filters.merchants },
  });

  const onSearchChange = useCallback(
    (value: string) => {
      if (isNilOrEmpty(value)) {
        return;
      }

      setIsFetchingMore(true);
      return fetchMore({
        variables: { search: value },
        updateQuery: (prev, { fetchMoreResult }) =>
          evolve(
            {
              merchants: mergeNextPage(fetchMoreResult.merchants),
            },
            prev,
          ),
      }).then(() => setIsFetchingMore(false));
    },
    [fetchMore],
  );

  const { data: mintTransactionsCountQueryData } = useQueryWithCacheExpiration(
    MINT_TRANSACTIONS_COUNT_QUERY,
    {
      cacheExpiration: Duration.fromObject({ minutes: 5 }),
    },
  );
  const hasTransactionsImportedFromMint = pathSatisfies(
    (count: number | undefined = 0) => count > 0,
    ['allMintTransactions', 'totalCount'],
    mintTransactionsCountQueryData,
  );

  const users = data?.myHousehold?.users ?? [];

  // Start with empty sections, then update them when the data loads
  const [sections, setSections] = useState<FilterMenuSection[]>(
    getFilterSectionsFromQuery(data, filters, {
      hasTransactionsImportedFromMint,
      householdUsers: users,
    }),
  );

  const recalculateSections = useCallback(() => {
    const selectedOptions = sections.flatMap((section) =>
      getAllLeavesForOptions(section.options).filter(({ isSelected }) => isSelected),
    ) as WithPath<FilterMenuOption>[];

    const updated = getFilterSectionsFromQuery(data, filters, {
      renderAccountLogo,
      renderMerchantAccessory,
      renderTag,
      hasTransactionsImportedFromMint,
      householdUsers: users,
    });

    /**
     * Recursively updates the selection state of a list of options and their nested options.
     *
     * This function traverses through the given options array, updating each option's `isSelected`
     * property based on whether it matches an option in the `selectedOptions` array (defined outside
     * this function's scope). If an option has nested options (indicated by an `options` property),
     * the function recursively updates these nested options as well.
     */
    const updateOptions = (options: WithPath<FilterMenuOption>[]) =>
      options.map((option) => {
        const selectedOption = selectedOptions.find(({ id }) => id === option.id);
        if (selectedOption) {
          option.isSelected = true;
        }

        // Recursively update nested options
        if (option.options && option.options.length > 0) {
          option.options = updateOptions(option.options);
        }

        return option;
      });

    updated.forEach((section) => {
      // Only do this for the merchant section, as it's the one we load
      // the items by demand (when the user types in the search input)
      // and have to consolidate the selected options with the new data.
      if (section.id !== 'merchant') {
        return;
      }

      section.options = updateOptions(section.options);
    });

    setSections(updated);
  }, [data, filters, hasTransactionsImportedFromMint, sections, users]);

  const resetUnappliedChanges = useCallback(() => {
    recalculateSections();
  }, [recalculateSections]);

  useEffect(
    () => {
      // Whenever data or filters change (e.g. review filter changes from the transactions
      // list dropdown), run this effect to update the sections so the menu always has the
      // latest data to render.
      recalculateSections();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, filters],
  );

  const handleApply = useCallback(
    (options?: OnClickApplyOptions) => {
      const { reset } = options ?? {};

      if (reset) {
        resetUnappliedChanges();
        onApplyFilters({});
        return;
      }

      const sectionsWithPath = addPathToOptions(sections, []);
      const selectedOptions = sectionsWithPath.flatMap((section) =>
        getAllLeavesForOptions(section.options).filter(({ isSelected }) => isSelected),
      );

      const filters = adaptSelectedOptionsToTransactionsFilters(selectedOptions);
      onApplyFilters(filters);

      trackFiltersApplied(page, selectedOptions);
    },
    [onApplyFilters],
  );

  return {
    sections,
    isLoading: isLoadingInitialData,
    isFetchingMoreData: isFetchingMore,
    onChangeSections: setSections,
    onSearchChange,
    resetUnappliedChanges,
    handleApply,
  };
};

export const FILTER_MENU_QUERY = gql(/* GraphQL */ `
  query Web_TransactionsFilterQuery($search: String, $includeIds: [ID!]) {
    categoryGroups {
      id
      name
      order

      categories {
        id
        name
        icon
        order
      }
    }

    merchants(search: $search, includeIds: $includeIds) {
      id
      name
      transactionCount
    }

    accounts {
      id
      displayName
      logoUrl
      icon
      type {
        name
        display
      }
    }

    householdTransactionTags {
      id
      name
      order
      color
    }

    myHousehold {
      id

      users {
        id
        name
      }
    }
  }
`);

const MINT_TRANSACTIONS_COUNT_QUERY = gql(/* GraphQL */ `
  query Web_MintTransactionsCountQuery {
    allMintTransactions: allTransactions(filters: { importedFromMint: true }) {
      totalCount
    }
  }
`);

export default useTransactionFilterMenu;
