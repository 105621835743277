/** This will probably move to the backend in the future... */
export const EXPLORE_PROMPTS = {
  'Cash flow': [
    {
      text: 'Compare our cash flow from this year to this last year?',
      emoji: '📈',
    },
    {
      text: 'How much did I spend on food and drinks in the last three months?',
      emoji: '🍏',
    },
    {
      text: 'How has my restaurants spending changed over the last 5 months?',
      emoji: '🍽️',
    },
  ],
  Advice: [
    {
      text: 'What is the best way to save for college?',
      emoji: '📚',
    },
    {
      text: 'What is the difference between a traditional IRA and a Roth IRA?',
      emoji: '🏦',
    },
  ],
};
