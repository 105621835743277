import * as R from 'ramda';
import { useContext, useCallback, useState, useMemo } from 'react';

import ScrollContext from 'lib/contexts/ScrollContext';
import useEventListener from 'lib/hooks/useEventListener';

type State = {
  offset: number;
  scrollState: 'fixedTop' | 'fixedBottom' | 'scrollingUp' | 'scrollingDown';
  invertTriggerPoint: number;
};

type TransitionContext = {
  isScrollingDown: boolean;
  isScrollingUp: boolean;
  scrollOffset: number;
  contentOverflowHeight: number;
  padding: number;
  INITIAL_STATE: State;
};

export const stateTransition = (prevState: State, context: TransitionContext): State => {
  const { scrollState, invertTriggerPoint } = prevState;
  const {
    isScrollingDown,
    isScrollingUp,
    scrollOffset,
    contentOverflowHeight,
    padding,
    INITIAL_STATE,
  } = context;
  const offsetFromInvertPoint = scrollOffset - invertTriggerPoint;

  if (contentOverflowHeight <= 0) {
    // Scroll container is larger than content so we don't have to do anything special
    return INITIAL_STATE;
  } else if (
    (offsetFromInvertPoint > contentOverflowHeight && scrollState !== 'fixedBottom') ||
    (scrollState === 'scrollingUp' && offsetFromInvertPoint > 0)
  ) {
    // Scrolled to bottom
    // OR went back down
    return {
      ...prevState,
      offset: -(contentOverflowHeight - padding),
      scrollState: 'fixedBottom',
    };
  } else if (
    (offsetFromInvertPoint < -contentOverflowHeight && scrollState !== 'fixedTop') ||
    (scrollState === 'scrollingDown' && offsetFromInvertPoint < 0)
  ) {
    // Scrolled to top
    // OR went back up
    return {
      ...prevState,
      offset: padding,
      scrollState: 'fixedTop',
    };
  } else if (isScrollingUp && scrollState === 'fixedBottom') {
    // Started going up (after going down)
    return {
      ...prevState,
      offset: scrollOffset - contentOverflowHeight,
      scrollState: 'scrollingUp',
      invertTriggerPoint: scrollOffset,
    };
  } else if (isScrollingDown && scrollState === 'fixedTop') {
    // Started going down (after going up)
    return {
      ...prevState,
      offset: scrollOffset,
      scrollState: 'scrollingDown',
      invertTriggerPoint: scrollOffset,
    };
  }

  return prevState;
};

/**
 * Should probably only be used with StickyScroll component.
 */
const useSyncScrollSticky = <ElementT extends HTMLElement>(
  ref: React.RefObject<ElementT>,
  padding: number,
) => {
  const { scrollRef } = useContext(ScrollContext);

  const INITIAL_STATE: State = useMemo(
    () => ({
      offset: padding,
      scrollState: 'fixedTop',
      invertTriggerPoint: scrollRef?.current?.scrollTop ?? 0,
    }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const [state, setState] = useState<State>(INITIAL_STATE);
  const [prevScroll, setPrevScroll] = useState(state.offset);

  const getStateTransitionContext = useCallback((): TransitionContext => {
    const refHeight = ref.current?.offsetHeight ?? 0;
    const scrollContainerHeight = scrollRef?.current?.offsetHeight ?? 0;
    const scrollOffset = Math.max(0, scrollRef?.current?.scrollTop ?? 0);
    const contentOverflowHeight = refHeight - scrollContainerHeight + padding * 2;

    return {
      isScrollingUp: scrollOffset < prevScroll,
      isScrollingDown: scrollOffset > prevScroll,
      scrollOffset,
      contentOverflowHeight,
      padding,
      INITIAL_STATE,
    };
  }, [scrollRef, prevScroll, padding, INITIAL_STATE, ref]);

  const [frameTimeout, setFrameTimeout] = useState<number | undefined>(undefined);
  const onScroll = useCallback(() => {
    if (frameTimeout) {
      window.cancelAnimationFrame(frameTimeout);
    }
    const timeout = window.requestAnimationFrame(() => {
      const transitionContext = getStateTransitionContext();
      const newState = stateTransition(state, transitionContext);
      if (!R.equals(state, newState)) {
        setState(newState);
      }

      setPrevScroll(transitionContext.scrollOffset);
      setFrameTimeout(undefined);
    });
    setFrameTimeout(timeout);
  }, [state, setState, setPrevScroll, getStateTransitionContext, frameTimeout, setFrameTimeout]);

  useEventListener(scrollRef?.current, 'scroll', onScroll);

  return state;
};

export default useSyncScrollSticky;
