import useSupportedDataProvidersCommon from 'common/lib/hooks/institutions/useSupportedDataProvidersCommon';
import { useFeatureFlags } from 'lib/hooks/useFeatureFlag';
import type { FeatureFlagName } from 'state/features/types';

import { DataProviderLegacy } from 'common/generated/graphQlTypes/globalTypes';

const useSupportedDataProviders = () =>
  useSupportedDataProvidersCommon<FeatureFlagName>(
    [
      {
        provider: DataProviderLegacy.PLAID,
      },
      {
        provider: DataProviderLegacy.FINICITY,
      },
      {
        provider: DataProviderLegacy.ZILLOW,
      },
      {
        provider: DataProviderLegacy.COINBASE,
      },
      {
        provider: DataProviderLegacy.MX,
      },
    ],
    useFeatureFlags,
  );

export default useSupportedDataProviders;
