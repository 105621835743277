import React, { useState } from 'react';
import type { Place } from 'react-tooltip';
import styled, { css } from 'styled-components';

import Tooltip from 'components/lib/ui/Tooltip';
import IconButton from 'components/lib/ui/button/IconButton';

import useTimeoutWhen from 'common/lib/hooks/useTimeoutWhen';
import { fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';

import type { Color } from 'types/Styles';

const TOOLTIP_DELAY_SHOW_MS = 500;
const TOOLTIP_ENABLE_DELAY_MS = 100;

const StyledIconButton = styled(IconButton)<{ $badge?: boolean; $badgeBackgroundColor: Color }>`
  transition: ${({ theme }) => theme.transition.default};
  max-width: 40px;

  ${({ $badge, $badgeBackgroundColor, theme }) =>
    $badge &&
    css`
      background: ${theme.color[$badgeBackgroundColor]};
      width: auto;
      max-width: 120px;
      border-radius: 100px;
      padding: 0 ${spacing.small};

      ::before {
        display: none;
      }
    `}
`;

const Text = styled.span`
  margin-left: ${spacing.xsmall};
  font-size: ${fontSize.xsmall};
  font-weight: ${fontWeight.medium};
`;

type Props = {
  icon: string;
  iconSize?: number;
  tooltip?: React.ReactNode;
  tooltipPlace?: Place;
  badge?: boolean;
  badgeText?: string;
  badgeBackgroundColor?: Color;
  badgeTextColor?: Color;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
};

/**
 * Icon button that animates into a pill badge.
 */
const IconButtonBadge = ({
  icon,
  iconSize,
  tooltip,
  tooltipPlace = 'bottom',
  badge,
  badgeText,
  badgeBackgroundColor = 'grayBackground',
  badgeTextColor,
  disabled,
  onClick,
  className,
}: Props) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(true);

  // this makes it so when the button changes state to badge the tooltip goes away
  // until the user hovers again
  useTimeoutWhen(!tooltipEnabled, TOOLTIP_ENABLE_DELAY_MS, () => setTooltipEnabled(true));

  return (
    <Tooltip
      content={!tooltipEnabled ? null : tooltip}
      delayShow={TOOLTIP_DELAY_SHOW_MS}
      fitContent
      place={tooltipPlace}
      disable={!tooltipEnabled}
    >
      <StyledIconButton
        $badge={badge}
        $badgeBackgroundColor={badgeBackgroundColor}
        bordered
        icon={icon}
        color={badge ? badgeTextColor : 'text'}
        iconSize={iconSize}
        disabled={disabled}
        onClick={() => {
          setTooltipEnabled(false);
          onClick?.();
        }}
        className={className}
      >
        {badge ? <Text>{badgeText}</Text> : null}
      </StyledIconButton>
    </Tooltip>
  );
};

export default IconButtonBadge;
