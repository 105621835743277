import React from 'react';
import styled from 'styled-components';

import Menu, { MenuItem } from 'components/lib/ui/menu/Menu';

import type { Shortcut } from 'common/lib/dateRange';
import { DEFAULT_SHORTCUTS } from 'common/lib/dateRange';

import type DateRange from 'common/types/DateRange';

export const SHORTCUTS_WIDTH_PX = 140;

type ShortcutProps = {
  onSelectDateRange: (range: DateRange) => void;
  shortcuts?: Shortcut[];
};

const ShortcutMenu = styled(Menu)`
  height: 100%;
  min-width: ${SHORTCUTS_WIDTH_PX}px;
  border-right: 1px solid ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.color.text};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  padding: 5.5px ${({ theme }) => theme.spacing.xsmall};
  margin: ${({ theme }) => theme.spacing.xxxsmall} 0;
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const DateRangePickerShortcuts = ({
  onSelectDateRange,
  shortcuts = DEFAULT_SHORTCUTS,
}: ShortcutProps) => (
  <ShortcutMenu>
    {shortcuts.map(({ title, ...dateRange }) => (
      <StyledMenuItem key={title} onClick={() => onSelectDateRange(dateRange)}>
        {title}
      </StyledMenuItem>
    ))}
  </ShortcutMenu>
);

export default DateRangePickerShortcuts;
