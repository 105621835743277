import _ from 'lodash';
import type { GroupType } from 'react-select';

import type { OptionType } from 'types/select';
import { isOptionGroupTypeArray, isOptionType } from 'types/select';

// Map value in our state back to original options (which is what react-select expects)
// Pass through null to clear the select.
export const getOptionValue = <T extends OptionType>(
  value: string[] | string | T | null | undefined,
  options: T[] | GroupType<T>[],
) => {
  const findOptions = (valueToFind: string | undefined) =>
    isOptionGroupTypeArray(options)
      ? options.flatMap(({ options }) => options.filter(({ value }) => value === valueToFind))
      : options.filter(({ value }) => value === valueToFind);

  if (isOptionType(value)) {
    return value;
  } else if (Array.isArray(value)) {
    return value.flatMap(findOptions);
  } else if (value === null) {
    return null;
  }
  return findOptions(value)[0];
};
