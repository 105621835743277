import { parse, stringify } from 'query-string';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Tuple } from 'common/types';

export type Params = { [k: string]: string };
export type NewState<T> = T | ((state: T) => T);

const parseParams = (search: string): Params => parse(search) as Params;

const useParsedParams = (): Params => {
  const location = useLocation();
  return parseParams(location.search);
};

export const useParamState = <T extends { [k: string]: any }>(reify: (params: Params) => T) => {
  const history = useHistory();
  const params = useParsedParams();
  const state = reify(params);

  const setState = useCallback(
    (value: NewState<T>) => {
      // eslint-disable-next-line
      const newState = typeof value === 'function' ? (value as Function)(state) : value;

      const search = stringify(
        { ...params, ...newState },
        {
          arrayFormat: 'comma',
        },
      );
      history.replace(`${history.location.pathname}?${search}`);
    },
    [params, state],
  );

  return Tuple(state, setState);
};
