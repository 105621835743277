import { getType } from 'typesafe-actions';

import { appLoaded } from 'actions';
import { getUserToken } from 'selectors';
import type { Middleware } from 'state/types';
import { fetchUserAction } from 'state/user/thunks';

const appInitializationMiddleware: Middleware = (store) => (next) => (action) => {
  const { type } = action;
  if (type === getType(appLoaded) && getUserToken(store.getState())) {
    // Refetch the user on app load, in case any of their state has changed.
    store.dispatch(fetchUserAction());
  }
  return next(action);
};

export default appInitializationMiddleware;
