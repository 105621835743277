import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import React, { forwardRef, useContext, useImperativeHandle, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import AccountGroupCard from 'components/accounts/AccountGroupCard';
import AccountGroupList from 'components/accounts/AccountGroupList';
import AccountListItem from 'components/accounts/AccountListItem';
import DragDropContext from 'components/lib/dnd/DragDropContext';
import Draggable from 'components/lib/dnd/Draggable';
import Droppable from 'components/lib/dnd/Droppable';
import DroppableContext from 'components/lib/dnd/DroppableContext';
import Card from 'components/lib/ui/Card';
import Empty from 'components/lib/ui/Empty';

import { toggleAccountGroupCollapsed } from 'actions';
import { getPreviousAmountsByAccountType } from 'common/lib/accounts/netWorthCharts';
import { getCollapsedAccountGroups } from 'common/state/accounts/selectors';
import type { AccountTypeSummary } from 'lib/accounts/AccountGraphs';
import ScrollContext from 'lib/contexts/ScrollContext';
import useAccountGroupsDrag from 'lib/hooks/accounts/useAccountGroupsDrag';
import usePersistentFilter from 'lib/hooks/usePersistentFilter';

import type {
  Common_GetMonthlySnapshotsQuery,
  Web_GetAccountsPageQuery,
} from 'common/generated/graphql';
import { AccountTypeGroup } from 'common/generated/graphql';

const SCROLL_TO_OFFSET_PX = -32;

export type AccountsListInputRef = {
  scrollToAccountType(accountType: string): void;
};

const AccountsEmpty = styled(Empty)`
  padding: ${({ theme }) => theme.spacing.xxxlarge};
`;

type Props = {
  householdPreferences: Web_GetAccountsPageQuery['householdPreferences'];
  recentBalancesByAccount?: { [key: string]: number[] };
  previousBalancesByAccountType?: { [key: string]: number };
  accountSummaries: AccountTypeSummary[];
  snapshots: Common_GetMonthlySnapshotsQuery['monthlySnapshotsByAccountType'];
};

const AccountsList: React.ForwardRefRenderFunction<AccountsListInputRef, Props> = (
  {
    householdPreferences,
    recentBalancesByAccount,
    accountSummaries,
    snapshots,
    previousBalancesByAccountType,
  }: Props,
  ref,
) => {
  const { scrollTo } = useContext(ScrollContext);
  const { handleOnDragEnd, sortedAccountsGroup } = useAccountGroupsDrag(
    accountSummaries,
    householdPreferences,
  );

  const dispatch = useDispatch();
  const collapsedAccountGroups = useSelector(getCollapsedAccountGroups);

  useImperativeHandle(ref, () => ({
    scrollToAccountType: (accountType: string) => {
      scrollTo(accountType, {
        offset: SCROLL_TO_OFFSET_PX,
      });
    },
  }));

  const {
    activeFilters: { timeframe: activeTimeframe, chartType },
  } = usePersistentFilter('accounts');

  const previousAmountsByAccountType = useMemo(() => {
    if (chartType === 'performance' && previousBalancesByAccountType) {
      return previousBalancesByAccountType;
    }

    return getPreviousAmountsByAccountType(snapshots, DateTime.local(), activeTimeframe);
  }, [snapshots, activeTimeframe, chartType]);

  if (!sortedAccountsGroup.length) {
    return (
      <Card>
        <AccountsEmpty title="No accounts yet" subtitle="Add an account to get started." />
      </Card>
    );
  }

  return (
    <div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="accountGroups">
          <DroppableContext.Consumer>
            {({ state: { isDraggingOver, draggingOverWith } }) =>
              sortedAccountsGroup.map(
                ({ type: { display, group, name }, totalDisplayBalance, accounts }, index) => (
                  <Element name={display} key={name}>
                    <Draggable
                      key={name}
                      // TODO: display is not unique, this should use name see ENG-9799
                      draggableId={display}
                      index={index}
                      customDragHandle
                    >
                      <AccountGroupCard
                        isAsset={group === AccountTypeGroup.ASSET}
                        title={display}
                        currentAmount={totalDisplayBalance}
                        startAmount={previousAmountsByAccountType[name]}
                        isDraggingThis={draggingOverWith === display}
                        isDragActive={isDraggingOver}
                        draggable
                        isCollapsed={collapsedAccountGroups[name]}
                        onToggleCollapsed={() => dispatch(toggleAccountGroupCollapsed(name))}
                      >
                        <AccountGroupList
                          groupName={display}
                          accounts={accounts}
                          recentBalancesByAccount={recentBalancesByAccount}
                        />
                      </AccountGroupCard>
                    </Draggable>
                  </Element>
                ),
              )
            }
          </DroppableContext.Consumer>
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export const AccountsListFragments = {
  AccountsListFields: gql`
    fragment AccountsListFields on Account {
      id
      syncDisabled
      isHidden
      isAsset
      includeInNetWorth
      order
      type {
        name
        display
      }
      ...AccountListItemFields
    }
    ${AccountListItem.fragments.AccountListItemFields}
  `,
};

export default forwardRef(AccountsList);
