import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import { useMemo, useState } from 'react';

import useDeleteGoal from 'common/lib/hooks/goalsV2/useDeleteGoal';
import useQuery from 'common/lib/hooks/useQuery';

import { GoalType } from 'common/constants/goals';

import { gql } from 'common/generated/gql';
import type { CreateGoalInput, GoalOptionsQuery } from 'common/generated/graphql';

export type GoalByType = {
  [key: string]: { id: string; objective: string }[];
};

type CountByType = { [key: string]: number };

type Options = {
  onUpdateCount?: (count: number) => void;
  onChange?: (createdByType: GoalByType) => void;
  initialCountByType?: { [key: string]: number };
  initialCreatedByType?: GoalByType;
  onDeleteFailed?: () => void;
};

const SECTIONS = [GoalType.Asset, GoalType.Debt];

const createdByTypeToCount = (initialCreatedByType: GoalByType) =>
  Object.keys(initialCreatedByType ?? {}).reduce((acc, key) => {
    const count = (initialCreatedByType ?? {})[key as keyof GoalByType].length;
    return { ...acc, [key]: count };
  }, {});

const getGoalsToDelete = ({
  countByType,
  newCreatedByType,
}: {
  countByType: CountByType;
  newCreatedByType: GoalByType;
}) =>
  // Check if amount of goals created has decreased
  // and take last N goals to delete
  // this may happens if user goes back and changes the amount of goals
  R.toPairs(countByType).flatMap(([objective, count]) => {
    const typeList = newCreatedByType[objective] ?? [];
    const diffCount = count - (typeList.length ?? 0);

    if (diffCount < 0) {
      return R.takeLast(Math.abs(diffCount), typeList);
    }

    return [];
  });
const getGoalsToCreate = ({
  countByType,
  newCreatedByType,
  goalOptions,
}: {
  countByType: CountByType;
  newCreatedByType: GoalByType;
  goalOptions: GoalOptionsQuery['goalOptions'];
}) =>
  // this may happens if user goes back and changes the amount of goals
  R.toPairs(countByType).flatMap(([objective, count]) => {
    const template = goalOptions.find(R.propEq('objective', objective));

    if (!template) {
      return [];
    }

    const typeList = newCreatedByType[objective] ?? [];
    const countToCreate = count - typeList.length;

    if (countToCreate <= 0) {
      return [];
    }

    return R.range(0, Math.max(0, countToCreate)).map(
      () =>
        ({
          name: template.defaultName,
          objective,
          imageStorageProvider: template.defaultImageStorageProvider,
          imageStorageProviderId: template.defaultImageStorageProviderId,
        }) as CreateGoalInput,
    );
  });
const useSelectGoals = ({
  onUpdateCount,
  onChange: onCreateGoals,
  initialCreatedByType,
  onDeleteFailed,
}: Options = {}) => {
  const { data, isLoadingInitialData } = useQuery(QUERY);

  const initialCountByType = initialCreatedByType ? createdByTypeToCount(initialCreatedByType) : {};

  const [countByType, setCountByType] = useState<CountByType>(initialCountByType ?? {});

  const [createdByType, setCreatedByType] = useState<GoalByType>(initialCreatedByType ?? {});

  const { goalOptions = [] } = data ?? {};

  const sectionsData = SECTIONS.map((type) => ({
    type,
    goalOptions: goalOptions.filter(R.propEq('type', type)),
  }));

  const [createGoals, { loading }] = useMutation(CREATE_GOALS_MUTATION);

  const [deleteGoal] = useDeleteGoal({
    onError: onDeleteFailed,
  });

  const updateCountForItem = (item: { objective: string }) => (value: number) => {
    setCountByType({
      ...countByType,
      [item.objective]: value,
    });
    onUpdateCount?.(value);
  };

  const getCountForItem = (item: { objective: string }) => countByType[item.objective] ?? 0;

  const areAnySelected = useMemo(
    () => Object.values(countByType).some((value) => value > 0),
    [countByType],
  );

  const createGoalsForSelectedObjectives = async () => {
    let newCreatedByType = {
      ...createdByType,
    };
    let hasChanged = false;

    const goalsToDelete = getGoalsToDelete({ countByType, newCreatedByType });

    if (goalsToDelete.length) {
      await Promise.all(goalsToDelete.map((goal) => deleteGoal(goal.id)));

      const deletedGoalIds = goalsToDelete.map((goal) => goal.id);
      const nonDeletedGoals =
        Object.values(newCreatedByType)
          .flat()
          .filter((goal) => !deletedGoalIds.includes(goal.id)) ?? [];
      newCreatedByType = R.groupBy((goal) => goal?.objective ?? 'none', nonDeletedGoals);
      hasChanged = true;
    }

    const goalsToCreate = getGoalsToCreate({
      countByType,
      newCreatedByType,
      goalOptions,
    });

    if (goalsToCreate.length) {
      const { data } = await createGoals({
        variables: {
          input: {
            goals: goalsToCreate,
          },
        },
      });
      const goals = data?.createGoals?.goals;

      if (goals) {
        const nonNullGoals = goals.filter((goal) => goal !== null) ?? [];
        newCreatedByType = R.mergeWith(
          R.concat,
          newCreatedByType,
          R.groupBy((goal) => goal?.objective ?? 'none', nonNullGoals),
        );
        hasChanged = true;
      }
    }

    if (hasChanged) {
      onCreateGoals?.(newCreatedByType);
      const newCountByType = createdByTypeToCount(newCreatedByType);
      setCountByType(newCountByType);

      // Store recently created goals so if user goes back and changes the amount of goals
      // we can delete the last N goals
      setCreatedByType(newCreatedByType);
    }
  };

  return {
    sectionsData,
    isLoadingInitialData,
    loading,
    countByType,
    areAnySelected,
    updateCountForItem,
    getCountForItem,
    createGoalsForSelectedObjectives,
  };
};

const QUERY = gql(`
  query GoalOptions {
    goalOptions {
      defaultName
      objective
      type
      allowMultiSelect
      defaultImageStorageProvider
      defaultImageStorageProviderId
    }
  }
`);

const CREATE_GOALS_MUTATION = gql(`
  mutation Common_CreateGoals($input: CreateGoalsInput!) {
    createGoals(input: $input) {
      goals {
        id
        objective
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export default useSelectGoals;
