import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import IconButton from 'components/lib/ui/button/IconButton';

import boxShadow from 'common/lib/styles/boxShadow';

const Root = styled(FlexContainer)`
  padding: 0 ${({ theme }) => theme.spacing.xxsmall};
  gap: ${({ theme }) => theme.spacing.xxsmall};
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  transition: ${({ theme }) => theme.transition.default};

  :hover {
    ${boxShadow.medium}
  }
`;

const StyledIconButton = styled(IconButton)`
  ::before {
    border-radius: ${({ theme }) => theme.radius.small};
  }
`;

export type MessageAction = {
  icon: string;
  onClick: () => void;
};

type Props = {
  actions: MessageAction[];
  className?: string;
};

const MessageActions = ({ actions, className }: Props) => (
  <Root className={className}>
    {actions.map(({ icon, onClick }, i) => (
      <StyledIconButton icon={icon} onClick={onClick} key={i} />
    ))}
  </Root>
);

export default MessageActions;
