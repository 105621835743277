import crypto from 'crypto';

// Generate a hash that can be used to dedup events on the frontend and backend
// The implementation here should match backend's _get_dedup_event_id
// This dedups based on userId, so we shouldn't use it for events that a user might do multiple times
// If we want non-unique events, we should use a different deduping strategy
export const getDedupEventId = (userId: string) => {
  const hash = crypto.createHash('sha256');
  hash.update(`${userId}`);
  return hash.digest('hex');
};
