import React from 'react';
import styled from 'styled-components';

import ImageFadeCarousel from 'components/lib/animations/ImageFadeCarousel';
import FlexContainer from 'components/lib/ui/FlexContainer';
import OnboardingAccountsGridAnimation from 'components/onboarding/OnboardingAccountsGridAnimation';

const ACCOLADE_IMAGES = [
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/accolades-app-store.svg',
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/accolades-fast-company.svg',
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/accolades-business-insider.svg',
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/accolades-product-hunt.svg',
];

const Root = styled(FlexContainer).attrs({ justifyCenter: true })`
  height: 144px;
  width: 100%;
  background: linear-gradient(
    95.31deg,
    ${({ theme }) => theme.color.orange} -4.88%,
    ${({ theme }) => theme.color.navy} 168.96%
  );
  position: relative;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
`;

type Props = {
  className?: string;
};

const OnboardingAccoladesBanner = ({ className }: Props) => (
  <Root className={className}>
    <ImageFadeCarousel imageUrls={ACCOLADE_IMAGES} />
    <OnboardingAccountsGridAnimation />
  </Root>
);

export default OnboardingAccoladesBanner;
