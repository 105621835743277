import type { OperationVariables, QueryHookOptions, TypedDocumentNode } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import * as R from 'ramda';
import { useState, useEffect } from 'react';

import useQuery from 'common/lib/hooks/useQuery';
import { objToKey } from 'common/utils/Object';
import { executeMockQuery } from 'common/utils/graphQl/Mocking';
import noop from 'common/utils/noop';

/**
 * Wrapper around useQuery. If showMockData is true, issue the query
 * to our mock resolvers, otherwise functions the same as useQuery.
 */
const useMockableQuery = <TData = any, TVariables extends OperationVariables = any>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  showMockData: boolean,
  options: QueryHookOptions<TData, TVariables> = {},
) => {
  const [mockData, loading] = useMockedQueryData(query, {
    ...options,
    skip: !showMockData,
  });

  const realQueryResult = useQuery<TData, TVariables>(query, {
    ...options,
    skip: options.skip || showMockData,
  });

  return showMockData
    ? // Always refetch the underlying query to avoid accidentally "refetching" the mock
      // until we figure out why sometimes the mock gets refetched instead of the actual query.
      {
        data: mockData,
        isLoadingInitialData: loading,
        isNetworkRequestInFlight: loading,
        refetch: realQueryResult.refetch,
        fetchMore: noop,
      }
    : realQueryResult;
};

export const useMockedQueryData = <TData = any, TVariables extends OperationVariables = any>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> = {},
) => {
  const { variables } = options;
  const [mockData, setMockData] = useState<TData | null | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const serializedVariables = !R.isNil(variables) ? objToKey(variables) : undefined;

  useEffect(() => {
    if (!options.skip) {
      (async () => {
        const { data: mockResult } = await executeMockQuery<TData, TVariables>(query, variables);
        setMockData(mockResult);
        setLoading(false);
      })();
    }
  }, [JSON.stringify(options), query, setMockData, setLoading, serializedVariables]);

  return [mockData, loading] as const;
};

export default useMockableQuery;
