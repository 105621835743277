import React from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import Text from 'components/lib/ui/Text';
import RouteLink from 'components/lib/ui/link/RouteLink';

import { spacing } from 'common/lib/theme/dynamic';

import routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type { AccountLinkFieldsFragment } from 'common/generated/graphql';

const LOGO_SIZE_PX = 20;

const Root = styled(RouteLink)`
  display: flex;
  align-items: center;
`;

const Title = styled(Text).attrs({ clampLines: 1 })`
  margin-left: ${spacing.xsmall};
`;

type Props = {
  account: AccountLinkFieldsFragment;
  className?: string;
};

/**
 * Account logo & name that links to account details page.
 */
const AccountLink = ({ account: { id, displayName, logoUrl, icon }, className }: Props) => (
  <Root stealthy to={routes.accounts.accountDetails({ id })} className={className}>
    <AccountLogo logoUrl={logoUrl} icon={icon} size={LOGO_SIZE_PX} />
    <Title>{displayName}</Title>
  </Root>
);

AccountLink.fragments = {
  AccountLinkFields: gql(`
    fragment AccountLinkFields on Account {
      id
      displayName
      icon
      logoUrl
      id
    }
  `),
};

export default AccountLink;
