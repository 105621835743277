import React, { useContext } from 'react';

type SideDrawerContextType = {
  close: () => void;
};

const SideDrawerContext = React.createContext<SideDrawerContextType>(undefined as any);

export const useSideDrawerContext = () => {
  const context = useContext(SideDrawerContext);
  if (!context) {
    throw new Error(
      'useSideDrawerContext used outside of SideDrawerContext. ' +
        'Ensure you have a <SideDrawerContext.Provider> higher in the tree.',
    );
  }

  return context;
};

export default SideDrawerContext;
