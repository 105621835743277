import styled from 'styled-components';

const FormSection = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

export default FormSection;
