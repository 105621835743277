import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import AppleSignInButton from 'components/lib/external/AppleSignInButton';
import GoogleSignInButton from 'components/lib/external/GoogleSignInButton';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import LoggedOutCardPage from 'components/lib/layouts/LoggedOutCardPage';
import Banner from 'components/lib/ui/Banner';
import Divider from 'components/lib/ui/Divider';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import LoadingSpinnerWithText from 'components/lib/ui/LoadingSpinnerWithText';
import TermsAndConditions from 'components/lib/ui/TermsAndConditions';
import ManualLink from 'components/lib/ui/link/ManualLink';
import PasswordValidationGroup, {
  MergedPasswordSchema,
} from 'components/onboarding/PasswordValidationGroup';

import { login } from 'actions';
import getUserApi from 'common/lib/api/user';
import api from 'lib/api';
import { useQueryParam, useDispatch } from 'lib/hooks';

import { AUTH_PROVIDER_TO_LABEL } from 'common/constants/auth';
import routes from 'constants/routes';

import type { AuthProvider } from 'common/types/auth';

const PAGE_NAME = 'Accept Household Invite';

const ErrorBanner = styled(Banner).attrs({ type: 'error' })`
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const InviterName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const CenteredLoadingSpinner = styled(LoadingSpinner)`
  margin: auto;
`;

const AuthButtonsContainer = styled(FlexContainer).attrs({ column: true })`
  width: 100%;

  > button:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing.default} 0;
`;

const LoadingSpinnerContainer = styled.div`
  height: 400px;
`;

const ErrorPage = ({ children }: { children: React.ReactNode }) => (
  <LoggedOutCardPage name={PAGE_NAME} displayTitle={null} subtext={<span>monarchmoney.com</span>}>
    <ErrorBanner>{children}</ErrorBanner>
  </LoggedOutCardPage>
);

const AcceptHouseholdInvite = () => {
  // Technically, anyone arriving on this page should be using a link with the token and email
  // both populated.
  const token = useQueryParam('token') || '';
  const email = useQueryParam('email') || '';

  const history = useHistory();
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  const [isUsed, setIsUsed] = useState<boolean | undefined>(undefined);
  const [fromName, setFromName] = useState<string | undefined>(undefined);
  const [pendingAuthProvider, setPendingAuthProvider] = useState<AuthProvider | undefined>();

  const onSubmit = async (values: { password: string; name: string; email: string }) => {
    const response = await getUserApi(api).signupUserWithHouseholdInvite({
      email,
      token,
      name: values.name,
      password: values.password,
    });
    await dispatch(login(response));
    history.push(routes.dashboard());
  };

  const loadInviteStatus = async () => {
    try {
      const response = await getUserApi(api).checkHouseholdInvite({ token, email });
      setIsValid(true);
      if (response.from_name) {
        setFromName(response.from_name);
      }
    } catch (error: any) {
      if (error.data.is_used) {
        setIsUsed(true);
      }
      setIsValid(false);
    }
  };

  useEffect(() => {
    loadInviteStatus();
  }, []);

  if (isValid === undefined) {
    return (
      <LoggedOutCardPage name={PAGE_NAME}>
        <CenteredLoadingSpinner />
      </LoggedOutCardPage>
    );
  }

  if (isValid === false) {
    return (
      <ErrorPage>{`We're sorry, but the invitation to join this household is no longer valid.`}</ErrorPage>
    );
  }

  if (isUsed) {
    return (
      <ErrorPage>
        This invitation has already been used. Please{' '}
        <ManualLink onClick={() => history.push('/')}>login</ManualLink> or contact support.
      </ErrorPage>
    );
  }

  return (
    <LoggedOutCardPage
      name={PAGE_NAME}
      displayTitle="Join Household"
      description={
        <span>
          <InviterName>{fromName}</InviterName> has invited you to join their household on Monarch.
          Create your account to continue
        </span>
      }
      subtext={<TermsAndConditions />}
    >
      <Form
        enableReinitialize
        initialValues={{ name: '', email, password: '' }}
        onSubmit={onSubmit}
        overrideValidationSchema={Yup.object().shape({
          password: MergedPasswordSchema,
        })}
      >
        {pendingAuthProvider ? (
          <LoadingSpinnerContainer>
            <LoadingSpinnerWithText
              text={`Signing in with ${AUTH_PROVIDER_TO_LABEL[pendingAuthProvider]}`}
            />
          </LoadingSpinnerContainer>
        ) : (
          <>
            <AuthButtonsContainer>
              <AppleSignInButton
                skipConfirmation
                setIsPending={(isPending) =>
                  setPendingAuthProvider(isPending ? 'apple' : undefined)
                }
              />
              <GoogleSignInButton
                skipConfirmation
                setIsPending={(isPending) =>
                  setPendingAuthProvider(isPending ? 'google' : undefined)
                }
              />
              <StyledDivider />
            </AuthButtonsContainer>

            <TextField name="email" required disabled />
            <TextField name="name" required autoComplete="off" />
            <TextField
              name="password"
              password
              required
              autoComplete="current-password"
              onlyShowApiErrors
            />
            <PasswordValidationGroup />

            <FormSubmitButton>Create my account</FormSubmitButton>
          </>
        )}
      </Form>
    </LoggedOutCardPage>
  );
};

export default AcceptHouseholdInvite;
