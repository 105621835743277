import * as R from 'ramda';

import type { FileData } from 'common/types/File';

type RequestParams = {
  timestamp: number;
  folder: string;
  signature: string;
  api_key: string;
  upload_preset: string;
};

/** Generates the correct FormData using the file and request params. */
export const generateFormDataForUpload = <TFile extends FileData, TParams extends RequestParams>(
  file: TFile,
  requestParams: TParams,
) => {
  const data = new FormData();
  // @ts-ignore value not playing well with ts (we can have both FileData or Blob)
  data.append('file', file, file.name);

  const params = R.omit(['__typename'], requestParams);
  Object.entries(params).forEach(([key, val]: [string, string | number]) => {
    data.append(key, String(val));
  });

  return data;
};
