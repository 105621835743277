import { createSelector } from 'reselect';

import type { AccountsState } from 'common/state/accounts/reducer';

type RootStateAccounts = {
  accounts: AccountsState;
};

export const getAccountsState = ({ accounts }: RootStateAccounts) => accounts;

export const getHasAddedAccounts = createSelector(
  getAccountsState,
  ({ hasAddedAccounts }) => hasAddedAccounts,
);

export const getCollapsedAccountGroups = createSelector(
  getAccountsState,
  ({ collapsedAccountGroups }) => collapsedAccountGroups ?? {},
);
