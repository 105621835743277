import { ensureEnumValue } from 'common/utils/Enum';
import { useUpdatableQueryParam } from 'lib/hooks/useQueryParams';

import { TransactionOrdering } from 'common/generated/graphql';

export const ORDER_OPTIONS = [
  {
    value: TransactionOrdering.DATE,
    label: 'Date (new to old)',
  },
  {
    value: TransactionOrdering.INVERSE_DATE,
    label: 'Date (old to new)',
  },
  {
    value: TransactionOrdering.AMOUNT,
    label: 'Amount (high to low)',
  },
  {
    value: TransactionOrdering.INVERSE_AMOUNT,
    label: 'Amount (low to high)',
  },
];

export const DEFAULT_ORDER = TransactionOrdering.DATE;

const useOrderTransactions = (overrideValue?: Maybe<TransactionOrdering>) => {
  const [orderBy, setOrderBy] = useUpdatableQueryParam('order');
  const orderingByValue = ensureEnumValue(
    TransactionOrdering,
    overrideValue ?? orderBy,
    DEFAULT_ORDER,
  );

  return {
    orderBy: orderingByValue,
    setOrderBy,
    options: ORDER_OPTIONS,
    orderingByDateDescending: orderingByValue === TransactionOrdering.DATE,
    orderingByAmount:
      orderingByValue === TransactionOrdering.AMOUNT ||
      orderingByValue === TransactionOrdering.INVERSE_AMOUNT,
  };
};

export default useOrderTransactions;
