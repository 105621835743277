import { rgba } from 'polished';
import React from 'react';
import { FiEyeOff } from 'react-icons/fi';
import styled from 'styled-components';

import Draggable from 'components/lib/dnd/Draggable';
import DragDots from 'components/lib/ui/DragDots';
import Flex from 'components/lib/ui/Flex';

import type { ManageGetCategoryGroupsQuery } from 'common/generated/graphql';
import type { StyleMixin } from 'common/types/Styles';
import type { ElementOf } from 'common/types/utility';

const RowMixin: StyleMixin = ({ theme }) => `
  padding: 10px 12px;
  background: ${theme.color.white};
  border-radius: ${theme.radius.small};
  box-shadow: 0px 2px 4px ${rgba(theme.color.black, 0.04)};
  font-size: ${theme.fontSize.small};
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.xsmall};
  && {
    cursor: pointer;
  }
`;

const StyledDraggable = styled(Draggable)`
  ${RowMixin}
`;

const StyledNonDraggable = styled(Flex)`
  opacity: 0.5;
  ${RowMixin}
`;

const Dots = styled(DragDots)`
  cursor: move;
`;

const Icon = styled.span`
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.small};
  margin-left: ${({ theme }) => theme.spacing.default};
`;

const Name = styled.span`
  flex-grow: 1;
`;

const Label = styled.span`
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  display: flex;
`;

const EyeOffIcon = styled(FiEyeOff).attrs({ size: 16 })`
  color: ${({ theme }) => theme.color.textLight};
`;

type Props = {
  category: ElementOf<ManageGetCategoryGroupsQuery, 'categories'>;
  onClick?: () => void;
};

const ManageCategoryRow = ({
  category: { icon, name, id, order, isSystemCategory, isDisabled },
  onClick,
}: Props) => {
  const isDraggable = !isDisabled;
  const row = (
    <>
      {!isDisabled && <Dots />}
      <Icon>{icon}</Icon>
      <Name>{name}</Name>
      <Label>
        {!isSystemCategory && 'Custom'}
        {isDisabled && <EyeOffIcon />}
      </Label>
    </>
  );
  if (isDraggable) {
    return (
      <StyledDraggable
        draggableId={id}
        index={order}
        onClick={onClick}
        isDragDisabled={!isDraggable}
      >
        {row}
      </StyledDraggable>
    );
  }
  return <StyledNonDraggable onClick={onClick}>{row}</StyledNonDraggable>;
};
export default ManageCategoryRow;
