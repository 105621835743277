// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 2364506460
import { createAction } from 'typesafe-actions';

export const forceEmptyState = createAction('EMPLOYEE/FORCE_EMPTY_STATE')<boolean>();

export const forceRefreshEnabledStatus = createAction(
  'EMPLOYEE/FORCE_REFRESH_ENABLED_STATUS',
)<boolean>();

export const setDemoMode = createAction('EMPLOYEE/SET_DEMO_MODE')<boolean>();

export const setPlanOnboardingUserStatus = createAction(
  'EMPLOYEE/SET_PLAN_ONBOARDING_USER_STATUS',
)<boolean>();

export const setShowGoalsTour = createAction('EMPLOYEE/SET_SHOW_GOALS_TOUR')<boolean>();
