import * as React from 'react';
import styled from 'styled-components';

import AsyncButton from 'components/lib/ui/button/AsyncButton';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import { defaultButtonMixin } from 'components/lib/ui/button/DefaultButton';

import type { ButtonSize } from 'lib/styles/buttonSizeMixin';

import type { AuthProvider } from 'common/types/auth';

const Root = styled(AsyncButton)<{ id: string; size: ButtonSize }>`
  ${defaultButtonMixin};
  width: 100%;
`;

export type Props = {
  text: string;
  className?: string;
  provider?: AuthProvider;
  pending?: boolean;
  disabled?: boolean;
  onClick: ((e?: React.SyntheticEvent) => void) | ((e: React.SyntheticEvent) => Promise<void>);
  right?: React.ReactNode;
};

const AuthButton = ({ text, provider, className, pending, disabled, onClick, right }: Props) => (
  <Root
    disabled={disabled}
    onClick={onClick}
    pending={pending}
    className={className}
    size="medium"
    id={`continue-with-${provider}-btn`}
  >
    {provider ? <ButtonIcon name={`monarch-${provider}-logo`} /> : null}
    <span>{text}</span>
    {right}
  </Root>
);

export default AuthButton;
