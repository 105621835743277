import React from 'react';
import styled from 'styled-components';

import CardBody from 'components/lib/ui/CardBody';
import ModalCard from 'components/lib/ui/ModalCard';
import TextButton from 'components/lib/ui/TextButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import type { SubscriptionEndReminderFlowProps } from 'components/settings/billing/reminder/SubscriptionEndReminderFlow';
import SubscriptionSetReminderModal from 'components/settings/billing/reminder/SubscriptionSetReminderModal';

import { getDaysLeftOfSubscription } from 'common/lib/billing/Billing';
import { useStackContext } from 'common/lib/contexts/StackContext';
import useTrack from 'lib/hooks/useTrack';

import { CancelFlowEventNames } from 'common/constants/analytics';

const SetReminderPrimaryButton = styled(PrimaryButton).attrs({ size: 'large' })``;

const GrayTextButton = styled(TextButton)`
  color: ${({ theme }) => theme.color.textLight};
`;

const DaysLeftOfSubscription = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const ModalCopy = styled.div``;

const ModalCopyTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: bold;
  margin: 0;
`;

const ModalCopyText = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.base};
`;

const Buttons = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xxxxlarge};
  display: grid;
  gap: ${({ theme }) => theme.spacing.default};
`;

export type Props = {
  goBack?: () => void;
  next?: (params: SubscriptionEndReminderFlowProps) => void;
  isOnFreeTrial?: boolean;
  analyticsFreemiumSummaryStatus?: string | null;
  currentPeriodEndsAt?: string;
};

const SubscriptionOfferReminderModal = ({
  goBack,
  next,
  isOnFreeTrial,
  analyticsFreemiumSummaryStatus,
  currentPeriodEndsAt,
}: Props) => {
  const { push } = useStackContext();

  const daysLeftOfSubscription = getDaysLeftOfSubscription(currentPeriodEndsAt);

  useTrack(CancelFlowEventNames.CancelFlowReminderOffered, {
    analyticsFreemiumSummaryStatus,
    isOnFreeTrial,
  });

  return (
    <ModalCard
      title={
        <>
          You still have{' '}
          <DaysLeftOfSubscription>{daysLeftOfSubscription} days</DaysLeftOfSubscription> of saving
          much more than the annual fee.
        </>
      }
      onClickBackButton={goBack}
      hideBottomBorder
    >
      <CardBody>
        <ModalCopy>
          <ModalCopyTitle>Worried about being charged by surprise?</ModalCopyTitle>
          <ModalCopyText>
            Set a personalized reminder to continue getting the most out of Monarch and boosting
            your financial awareness—worry-free.
          </ModalCopyText>
        </ModalCopy>

        <Buttons>
          <SetReminderPrimaryButton
            onClick={() =>
              push(SubscriptionSetReminderModal, {
                goBack,
                currentPeriodEndsAt,
                analyticsFreemiumSummaryStatus,
              })
            }
          >
            Set reminder date
          </SetReminderPrimaryButton>

          <GrayTextButton
            onClick={() => {
              next?.({ goBack, isOnFreeTrial, analyticsFreemiumSummaryStatus });
            }}
          >
            Cancel subscription
          </GrayTextButton>
        </Buttons>
      </CardBody>
    </ModalCard>
  );
};

export default SubscriptionOfferReminderModal;
