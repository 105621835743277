import { useEffect, useState } from 'react';

import type { UnsplashPhoto } from 'common/lib/external/unsplash';
import unsplash from 'common/lib/external/unsplash';
import useLoading from 'common/lib/hooks/useLoading';

const DEFAULT_PER_PAGE = 20;

/**
 * Hook used to fetch images from the Unsplash API with an optional search term.
 * */
const useUnsplashPhotos = (
  search?: string,
  perPage: number = DEFAULT_PER_PAGE,
): { photos: UnsplashPhoto[]; loading: boolean } => {
  const [photos, setPhotos] = useState<UnsplashPhoto[]>([]);

  const [loading, fetchPhotos] = useLoading(async (search?: string) => {
    let images;
    if (search) {
      images = (await unsplash.searchPhotos({ query: search, per_page: perPage })).results;
    } else {
      images = await unsplash.listPhotos({ per_page: perPage });
    }
    setPhotos(images);
  });

  useEffect(() => {
    fetchPhotos(search);
  }, [search]);

  return {
    photos,
    loading,
  };
};

export default useUnsplashPhotos;
