import type Api from 'common/lib/api';

import type { Id } from 'common/types';

export type Notification = {
  id: Id;
  opened: boolean;
};

const getNotificationApi = (api: Api) => ({
  createDevice: (data: { platform: string; platform_vendor: string; token: string }) =>
    api.post('/notifications/devices/', { data }),
  updateSentNotification: (data: Notification) =>
    api.patch(`/notifications/sent/${data.id}/`, { data }),
  batchUpdate: (data: { seen: boolean }) => api.patch('/notifications/', { data }),
});

export default getNotificationApi;
