import { useMutation } from '@apollo/client';
import type { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import MoveMoneyInnerForm from 'components/budget/MoveMoneyInnerForm';
import Form from 'components/lib/form/Form';
import EventPropagationBoundary from 'components/lib/higherOrder/EventPropagationBoundary';
import Card from 'components/lib/ui/Card';

import {
  convertDisplayBudgetToCalculableBudget,
  getInitialFormValues,
  validateMoveMoneyForm,
} from 'common/lib/budget/MoveMoney';
import useBudgetData from 'common/lib/hooks/budget/useBudgetData';

import { gql } from 'common/generated/gql';
import type {
  BudgetTimeframeInput,
  CategoryGroupType,
  MoveMoneyMutationInput,
  Web_MoveMoneyMutation,
  Web_MoveMoneyMutationVariables,
} from 'common/generated/graphql';

const WIDTH_PX = 304;

const StyledCard = styled(Card)`
  width: ${WIDTH_PX}px;
`;

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.default};
`;

const Title = styled.span`
  color: ${({ theme }) => theme.color.textLight};
`;

type Props = {
  budgetStartDate: DateTime;
  onDone: () => Promise<void>;
  categoryType: CategoryGroupType;
  initialCategoryId?: string;
  isGroup: boolean;
};

const MoveMoney = ({
  budgetStartDate,
  onDone,
  categoryType,
  initialCategoryId,
  isGroup,
}: Props) => {
  const { budgetListData } = useBudgetData(budgetStartDate, {});
  const budget = useMemo(
    () => budgetListData && convertDisplayBudgetToCalculableBudget(budgetListData),
    [budgetListData],
  );

  const [mutation] = useMutation<Web_MoveMoneyMutation, Web_MoveMoneyMutationVariables>(
    MOVE_MONEY_MUTATION,
  );

  const initialValues = useMemo(
    () =>
      initialCategoryId && budget
        ? {
            ...getInitialFormValues(budget, initialCategoryId, categoryType, isGroup),
            startDate: budgetListData?.startDate ?? undefined,
            timeframe: 'month' as BudgetTimeframeInput,
          }
        : {
            startDate: budgetListData?.startDate ?? undefined,
            timeframe: 'month' as BudgetTimeframeInput,
          },
    [budget, budgetListData, initialCategoryId, categoryType, isGroup],
  );

  return (
    <EventPropagationBoundary onClick>
      <StyledCard title={<Title>Move Budget</Title>} small>
        <CardBody>
          <Form
            mutation={mutation}
            initialValues={initialValues}
            onSubmitSuccess={onDone}
            transformValuesBeforeSubmit={(values) => {
              const { fromCategoryId, toCategoryId } = values;
              const isFromGroup = budget?.details.byCategoryGroups.find(
                ({ id }) => id === fromCategoryId,
              );
              const isToGroup = budget?.details.byCategoryGroups.find(
                ({ id }) => id === toCategoryId,
              );

              const mutationInput: MoveMoneyMutationInput = {
                timeframe: values.timeframe,
                amount: values.amount,
                startDate: values.startDate,
                fromCategoryGroupId: isFromGroup ? fromCategoryId : undefined,
                fromCategoryId: isFromGroup ? undefined : fromCategoryId,
                toCategoryGroupId: isToGroup ? toCategoryId : undefined,
                toCategoryId: isToGroup ? undefined : toCategoryId,
              };

              return mutationInput;
            }}
            validate={(values) => (budget ? validateMoveMoneyForm(values, budget) : undefined)}
          >
            <MoveMoneyInnerForm budget={budget} categoryType={categoryType} />
          </Form>
        </CardBody>
      </StyledCard>
    </EventPropagationBoundary>
  );
};

const MOVE_MONEY_MUTATION = gql(`
  mutation Web_MoveMoneyMutation($input: MoveMoneyMutationInput!) {
    moveMoneyBetweenCategories(input: $input) {
      fromBudgetItem {
        id
        budgetAmount
      }
      toBudgetItem {
        id
        budgetAmount
      }
    }
  }
`);

export default MoveMoney;
