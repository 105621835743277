import React from 'react';

import CancelSubscriptionReasonModal from 'components/settings/billing/cancel/CancelSubscriptionReasonModal';
import SubscriptionOfferReminderModal from 'components/settings/billing/reminder/SubscriptionOfferReminderModal';
import Stack from 'components/utils/Stack';

import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';

export type SubscriptionEndReminderFlowProps = {
  isOnFreeTrial?: boolean;
  goBack?: () => void;
  analyticsFreemiumSummaryStatus?: string | null;
  next?: (params: SubscriptionEndReminderFlowProps) => void;
  currentPeriodEndsAt?: string;
};

/**
 * New cancel reminder flow which offers the user to set a reminder instead of cancelling it
 *
 * feature flag: ab-test-reminder-flow
 */
const SubscriptionEndReminderFlow = ({
  goBack,
  next,
  isOnFreeTrial,
  analyticsFreemiumSummaryStatus,
  currentPeriodEndsAt,
}: SubscriptionEndReminderFlowProps) => {
  const isAbTestReminderFlowOn = useIsFeatureFlagOn('ab-test-reminder-flow', {
    trackImpression: true,
  });

  // skips the reminder flow if the user is on the control ("off" or "backfill") group
  const initialComponent = isAbTestReminderFlowOn
    ? SubscriptionOfferReminderModal
    : CancelSubscriptionReasonModal;

  return (
    <Stack
      initial={{
        component: initialComponent,
        props: { goBack, next, isOnFreeTrial, analyticsFreemiumSummaryStatus, currentPeriodEndsAt },
      }}
    />
  );
};

export default SubscriptionEndReminderFlow;
