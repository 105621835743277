import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import type { MessageAction } from 'components/assistant/MessageActions';
import MessageActions from 'components/assistant/MessageActions';
import MessageBlocksRenderer from 'components/assistant/blocks/MessageBlocksRenderer';
import Badge from 'components/lib/ui/Badge';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { isoDateToMonthAbbreviationAndDayWithToday } from 'common/utils/date';

export const AVATAR_SIZE_PX = 36;

const Root = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacing.xxsmall} ${({ theme }) => theme.spacing.xlarge};
  flex: 1;
  position: relative;
`;

const AvatarCircle = styled.div`
  width: ${AVATAR_SIZE_PX}px;
  height: ${AVATAR_SIZE_PX}px;
  border-radius: ${({ theme }) => theme.radius.round};
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const ColumnContainer = styled(FlexContainer).attrs({ column: true })`
  flex: 1;
`;

const TitleContainer = styled(FlexContainer).attrs({ alignCenter: true })`
  flex: 1;
`;

const StyledBadge = styled(Badge).attrs({ color: 'grayFocus' })`
  padding: 3px 6px;
  margin-left: ${({ theme }) => theme.spacing.xxsmall};
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  flex-shrink: 0;
`;

const Title = styled(Text).attrs({ size: 'xsmall', color: 'textLight', clampLines: 2 })``;

const Timestamp = styled(Text).attrs({ size: 'xsmall', color: 'textLight' })`
  margin-left: ${({ theme }) => theme.spacing.xxsmall};
  flex-shrink: 0;
`;

const Content = styled.div`
  white-space: pre-wrap;

  :not(:first-child) {
    margin-top: 6px;
  }
`;

const StyledActions = styled(MessageActions)`
  position: absolute;
  top: ${({ theme }) => theme.spacing.xlarge};
  right: ${({ theme }) => theme.spacing.xlarge};
  opacity: 0;
  transition: opacity 0.1s ease-in-out;

  ${Root}:hover & {
    opacity: 1;
  }
`;

type Props = {
  avatar: React.ReactNode;
  name?: string;
  messages?: { content: string }[];
  nextGroup?: { content: string }[];
  timestamp?: string;
  badge?: string;
  actions?: MessageAction[];
  demo?: boolean;
  className?: string;
  children?: React.ReactNode;
};

const MessageListGroupBase = ({
  avatar,
  name,
  messages,
  nextGroup,
  timestamp,
  badge,
  actions,
  demo,
  className,
  children,
}: Props) => (
  <Root className={className} alignEnd>
    <AvatarCircle>{avatar}</AvatarCircle>
    <ColumnContainer>
      {!!(name || badge || timestamp) && (
        <TitleContainer>
          {!!name && <Title>{name}</Title>}
          {!!badge && <StyledBadge>{badge}</StyledBadge>}
          {!!timestamp && (
            <Timestamp>
              • {isoDateToMonthAbbreviationAndDayWithToday(timestamp)} at{' '}
              {DateTime.fromISO(timestamp).toLocaleString({
                // @ts-ignore I don't know why TS is complaining about this, the type is there...
                timeStyle: 'short',
              })}
            </Timestamp>
          )}
        </TitleContainer>
      )}
      <Content>
        {!!messages && (
          <MessageBlocksRenderer messages={messages} nextGroup={nextGroup} demo={demo} />
        )}
        {children}
      </Content>
    </ColumnContainer>
    {!!(actions && actions.length) && <StyledActions actions={actions} />}
  </Root>
);

export default MessageListGroupBase;
