import { mergeLeft, omit, pick } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import Controls from 'components/lib/ui/Controls';
import DividerLine from 'components/lib/ui/DividerLine';
import Icon from 'components/lib/ui/Icon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import IconButton from 'components/lib/ui/button/IconButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import TransactionDateRangeButton from 'components/transactions/header/TransactionDateRangeButton';
import TransactionFilterButton from 'components/transactions/header/TransactionFilterButton';
import TransactionSearchButton from 'components/transactions/header/TransactionSearchButton';

import { spacing } from 'common/lib/theme/dynamic';
import typewriter from 'lib/analytics/typewriter';
import type { SetFiltersFunction } from 'lib/hooks/transactions/useFilters';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';

import routes from 'constants/routes';

import type { TransactionFilters } from 'types/filters';

// By default, omit the fields below from filters since we already have separate handlers for those.
export const SEPARATE_FILTERS = ['search', 'startDate', 'endDate', 'order'] as const;

const pickDateRangeFromFilters = pick(['startDate', 'endDate']);

const PlusIcon = styled(Icon).attrs({ name: 'plus', size: 14 })`
  margin-top: ${spacing.xxxsmall};
  margin-right: ${spacing.xsmall};
`;

type Props = {
  filters: TransactionFilters;
  isSummaryColumnVisible: boolean;
  onChangeFilters: SetFiltersFunction;
  toggleIsCollapsed?: () => void;
};

const TransactionsHeaderControls: React.FC<Props> = ({
  filters,
  isSummaryColumnVisible,
  onChangeFilters,
  toggleIsCollapsed,
}) => {
  const isTransactionsUiUpdatesEnabled = useIsFeatureFlagOn('transactions-ui-v2-web');

  const onChangeSearch = useCallback(
    (search?: string | null) => onChangeFilters({ search }),
    [onChangeFilters],
  );

  const onChangeFiltersWithSeparateFields = useCallback(
    (changedFilters: Partial<TransactionFilters>) =>
      onChangeFilters(mergeLeft(pick(SEPARATE_FILTERS, filters), changedFilters), true),
    [filters, onChangeFilters],
  );

  return (
    <Controls>
      {isTransactionsUiUpdatesEnabled && (
        <>
          <TransactionSearchButton
            search={filters.search}
            onChangeSearch={(search) => {
              onChangeSearch(search);

              const isSearchDifferent = search !== filters.search;
              if (search && search?.length > 0 && isSearchDifferent) {
                typewriter.transactionsSearchChanged({ search });
              }
            }}
          />

          <TransactionDateRangeButton
            dateRange={pickDateRangeFromFilters(filters)}
            // The default range in this case is no range at all
            defaultRange={{
              startDate: undefined,
              endDate: undefined,
            }}
            onChangeDateRange={(filters) => {
              onChangeFilters(filters);

              if (filters.startDate || filters.endDate) {
                const { startDate, endDate } = pickDateRangeFromFilters(filters);
                typewriter.transactionsDateRangeChanged({
                  startDate: startDate || undefined,
                  endDate: endDate || undefined,
                });
              }
            }}
          />

          <TransactionFilterButton
            filters={
              omit(SEPARATE_FILTERS, filters) as Omit<
                TransactionFilters,
                keyof typeof SEPARATE_FILTERS
              >
            }
            onChangeFilters={onChangeFiltersWithSeparateFields}
          />
          <DividerLine />
        </>
      )}

      <DefaultButton linkTo={routes.settings.rules()} size="small">
        Edit rules
      </DefaultButton>

      <PrimaryButton linkTo={routes.transactions.addTransaction()} size="small">
        <PlusIcon />
        Add transaction
      </PrimaryButton>

      {isNotNil(toggleIsCollapsed) && isTransactionsUiUpdatesEnabled && (
        <IconButton
          icon="sidebar"
          onClick={toggleIsCollapsed}
          size="small"
          active={isSummaryColumnVisible}
        />
      )}
    </Controls>
  );
};

export default React.memo(TransactionsHeaderControls);
