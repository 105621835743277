import React, { useEffect } from 'react';

import { OverlayTrigger, useOverlay } from 'components/lib/ui/popover';
import CalloutPopover from 'components/lib/ui/popover/CalloutPopover';

import useProfileFlag from 'common/lib/hooks/users/useProfileFlag';

const CALLOUT_POPOVER_OFFSET_PX = 28;
const DELAY_SHOW_MS = 300;

type Props = {
  children: React.ReactNode;
};

/**
 * One-time use callout to inform users about the mark as reviewed / hide buttons moving.
 */
const MarkAsReviewedProductUpdateCallout = ({ children }: Props) => (
  <OverlayTrigger
    overlay={({ close }) => (
      <CalloutPopover onClose={close}>You can now mark as reviewed or hidden here.</CalloutPopover>
    )}
    placement="left"
    offsetDistance={CALLOUT_POPOVER_OFFSET_PX}
    showArrow
    disableClickOutside
  >
    <Inner>{children}</Inner>
  </OverlayTrigger>
);

const Inner = ({ children }: { children: React.ReactNode }) => {
  const { open } = useOverlay();

  const [viewedMarkAsReviewedUpdatesCalloutAt, updateViewedMarkAsReviewedUpdatesCalloutFlag] =
    useProfileFlag('viewedMarkAsReviewedUpdatesCalloutAt', undefined, {
      fetchPolicy: 'cache-first',
    });

  const showCallout = viewedMarkAsReviewedUpdatesCalloutAt === null;

  useEffect(() => {
    if (showCallout) {
      // delay showing a bit to ensure it's placed correctly
      setTimeout(open, DELAY_SHOW_MS);

      // update the flag when the callout is shown so we don't show it again ever
      updateViewedMarkAsReviewedUpdatesCalloutFlag(new Date().toISOString());
    }
  }, [showCallout]);

  return <>{children}</>;
};

export default MarkAsReviewedProductUpdateCallout;
