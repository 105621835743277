import { useMutation } from '@apollo/client';
import React from 'react';

import MerchantLogo from 'components/merchants/MerchantLogo';
import LogoSettings from 'components/settings/LogoSettings';

import useUploadMerchantLogo from 'common/lib/hooks/merchant/useUploadMerchantLogo';

import { gql } from 'common/generated/gql';

const DELETE_MERCHANT_LOGO = gql(`
  mutation web_DeleteMerchantLogo($merchantId: ID!) {
    deleteMerchantLogo(merchantId: $merchantId) {
      success
      merchant {
        id
        logoUrl
      }
    }
  }
`);

type Props = {
  merchantLogo?: string | null;
  merchantId: string;
};

const MerchantLogoSettings = ({ merchantLogo, merchantId }: Props) => {
  const uploadMerchantLogo = useUploadMerchantLogo();

  const [deleteMerchantLogo] = useMutation(DELETE_MERCHANT_LOGO, {
    variables: { merchantId },
  });

  return (
    <LogoSettings
      logo={merchantLogo}
      id={merchantId}
      onUpload={uploadMerchantLogo}
      onDelete={deleteMerchantLogo}
      getLogoComponent={(url, size) => <MerchantLogo url={url} size={size} />}
    />
  );
};

export default MerchantLogoSettings;
