import { pascalCase } from 'change-case';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import AddManualAccountModalListItem from 'components/accounts/AddManualAccountModalListItem';
import Flex from 'components/lib/ui/Flex';

import type { GetAccountTypeOptions_accountTypeOptions } from 'common/generated/graphQlTypes/GetAccountTypeOptions';

type Props = {
  accountTypeOptions: GetAccountTypeOptions_accountTypeOptions[];
  selectAccountType: (typeName: string, subtypeName: string | null) => void;
};

const SectionDivider = styled.div`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.xlarge};
  color: ${({ theme }) => theme.color.textLight};
  background-color: ${({ theme }) => theme.color.grayBackground};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const groupByGroup = R.groupBy(
  ({ type: { group } }: GetAccountTypeOptions_accountTypeOptions) => group,
);

const AddManualAccountModalTypeList = ({ accountTypeOptions, selectAccountType }: Props) => {
  const accountsByGroup = Object.entries(groupByGroup(accountTypeOptions));

  return (
    <Flex column>
      {accountsByGroup.map(([groupName, accounts]) => (
        <React.Fragment key={groupName}>
          <SectionDivider>{pascalCase(groupName)}</SectionDivider>
          {accounts.map(({ type: { name, display, group }, subtype }) => (
            <AddManualAccountModalListItem
              onClick={() => {
                selectAccountType(name, subtype?.name ?? null);
              }}
              key={`${display}:${group}:${subtype}`}
              name={subtype?.name ?? name}
              displayName={subtype?.display ?? display}
            />
          ))}
        </React.Fragment>
      ))}
    </Flex>
  );
};

export default AddManualAccountModalTypeList;
