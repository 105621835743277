import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import TextAreaInput from 'components/lib/form/TextAreaInput';

import fieldErrorBorderMixin from 'lib/styles/fieldErrorBorderMixin';

const TextArea = styled(
  ({ error, ...props }: { error?: boolean } & React.ComponentProps<typeof TextAreaInput>) => (
    <TextAreaInput {...props} />
  ),
)`
  ${fieldErrorBorderMixin}
`;

type Props = AbstractFieldProps<React.ComponentProps<typeof TextArea>> & {
  /**
   * If true, form will be submitted on enter instead of creating a newline.
   * shift+enter creates a newline. Defaults to false. */
  submitOnEnter?: boolean;
};

const TextAreaField = ({ className, onBlur, submitOnEnter, ...props }: Props) => {
  const { submitForm } = useFormContext();

  return (
    <AbstractField {...props} className={className}>
      {({ id, value, displayErrors, handleChange, handleBlur }) => (
        <TextArea
          {...R.omit(['hideLabel'], props)}
          id={id}
          onChange={handleChange}
          onBlur={(e: React.SyntheticEvent) => {
            handleBlur(e);
            onBlur?.(e);
          }}
          value={value ?? ''}
          error={displayErrors.length > 0}
          onKeyPress={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (submitOnEnter && e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              submitForm();
            }
          }}
        />
      )}
    </AbstractField>
  );
};

export default TextAreaField;
