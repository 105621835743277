import { useApolloClient } from '@apollo/client';
import type { OperationVariables } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { useMemo } from 'react';

import readFragmentOrNull from 'common/lib/graphQl/readFragmentOrNull';

type Props = {
  id: string;
  fragment: DocumentNode;
  fragmentName?: string;
  optimistic?: boolean;
};

const useReadFragment = <T = any, TVariables extends OperationVariables = OperationVariables>({
  id,
  fragment,
  fragmentName,
  optimistic,
}: Props) => {
  const client = useApolloClient();

  const result = useMemo(
    () =>
      readFragmentOrNull<T, TVariables>(
        client,
        {
          id,
          fragment,
          fragmentName,
        },
        optimistic,
      ),
    [id, fragment, client, fragmentName, optimistic],
  );

  return result;
};

export default useReadFragment;
