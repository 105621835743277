import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useCallback } from 'react';

import { CloudinaryRestApi } from 'common/lib/api';
import { generateFormDataForUpload } from 'common/lib/file';

import { gql } from 'common/generated/gql';
import type { FileData } from 'common/types/File';

const GET_SIGNED_CLOUDINARY_UPLOAD_INFO = gql(`
  mutation Common_GetCloudinaryUploadInfo($input: GetLogoCloudinaryUploadInfoMutationInput!) {
    getCloudinaryUploadInfo(input: $input) {
      info {
        path
        requestParams {
          timestamp
          folder
          signature
          api_key
          upload_preset
        }
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

const useUploadLogo = (
  entityType: 'user' | 'account' | 'merchant',
  handleSetLogo: (file: FileData, cloudinaryPublicId: string, entityId?: string) => Promise<any>,
) => {
  const [getCloudinaryUploadInfo] = useMutation(GET_SIGNED_CLOUDINARY_UPLOAD_INFO);

  return useCallback(
    async <TFile extends FileData>(
      file: TFile,
      entityId?: string,
      onUploadProgress?: (progress: ProgressEvent) => void,
    ) => {
      const uploadInfo = await getCloudinaryUploadInfo({
        variables: {
          input: {
            entityType,
          },
        },
      });
      const { info, errors } = uploadInfo.data?.getCloudinaryUploadInfo ?? {};

      if (R.isNil(info) || RA.isNotNil(errors)) {
        throw new Error('Failed to sign upload on provider.');
      }

      const { path: cloudinaryUploadUrl, requestParams } = info;
      const formData = generateFormDataForUpload(file, requestParams);

      const cloudinaryResponse = await CloudinaryRestApi.post(cloudinaryUploadUrl, formData, {
        onUploadProgress,
      });

      const logoResponse = await handleSetLogo(file, cloudinaryResponse.data.public_id, entityId);
      return logoResponse;
    },
    [entityType, getCloudinaryUploadInfo, handleSetLogo],
  );
};

export default useUploadLogo;
