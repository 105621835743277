import React, { useContext } from 'react';
import type { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';

export type DroppableContextType = {
  placeholder: DroppableProvided['placeholder'];
  state: DroppableStateSnapshot;
};

const DroppableContext = React.createContext<DroppableContextType>(undefined as any);

export const useDroppableContext = () => {
  const context = useContext(DroppableContext);
  if (!context) {
    throw new Error(
      'useDroppableContext used outside of DroppableContext. Ensure you have a <Droppable> component higher in the tree.',
    );
  }

  return context;
};

export default DroppableContext;
