import React from 'react';
import styled from 'styled-components';

import InvestmentsValue from 'components/investments/InvestmentsValue';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

const FooterContainer = styled(FlexContainer).attrs({ column: true, alignEnd: true })`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const FooterLabel = styled(Text).attrs({ color: 'textLight', size: 'small' })`
  display: block;
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
`;

const FooterValue = styled(InvestmentsValue)`
  font-size: ${({ theme }) => theme.fontSize.large};
`;

type Props = {
  value: number;
  label: string;
  color?: string;
  formatter?: (value: number) => string;
};

const InvestmentsTableFooterCell = ({ value, label, color, formatter = String }: Props) => (
  <FooterContainer>
    <FooterValue color={color} value={value}>
      {formatter(value)}
    </FooterValue>
    <FooterLabel>{label}</FooterLabel>
  </FooterContainer>
);

export default InvestmentsTableFooterCell;
