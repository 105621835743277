import { getType } from 'typesafe-actions';

import { routeChanged, setBulkSelectState } from 'actions';
import type { Middleware } from 'state/types';

const routeChangeMiddleware: Middleware = (store) => (next) => (action) => {
  const { type } = action;

  // Reset bulk select state when route changes
  if (type === getType(routeChanged)) {
    store.dispatch(setBulkSelectState(false));
  }

  return next(action);
};

export default routeChangeMiddleware;
