import useActiveEntitlements from 'common/lib/hooks/premium/useActiveEntitlements';
import useFeatureEntitlementParams from 'common/lib/hooks/premium/useFeatureEntitlementParams';
import { getActiveLimit, getMeetsOrExceedsLimit } from 'common/lib/premium/access';

import type { ProductFeatureLimit } from 'common/constants/premium';

/**
 * Hook used to determine the active limit for a feature based on the
 * user's current entitlement level and whether the user currently meets
 * or exceeds the limit.
 */
const useFeatureEntitlementLimit = (featureLimit: ProductFeatureLimit, currentValue: number) => {
  const activeEntitlements = useActiveEntitlements();
  const { constants } = useFeatureEntitlementParams();
  const limitConfig = constants[featureLimit];

  const limit = getActiveLimit(limitConfig, activeEntitlements);
  const meetsOrExceedsLimit = getMeetsOrExceedsLimit(limit, currentValue);

  return {
    meetsOrExceedsLimit,
    limit,
    activeEntitlements,
  };
};

export default useFeatureEntitlementLimit;
