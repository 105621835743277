import React, { useMemo } from 'react';
import styled from 'styled-components';

import Table from 'components/lib/ui/table/Table';

const Root = styled(Table)`
  margin-bottom: 0;
`;

type Props = {
  header: string[];
  rows: string[][];
};

const CsvTable = ({ header, rows }: Props) => {
  const columns = useMemo(
    () =>
      header.map((headerName, index) => ({
        Header: headerName,
        id: headerName,
        accessor: (row: string[]) => row[index],
      })),
    [header],
  );

  // @ts-ignore Table isn't typed for string[] data, but it seems to work
  return <Root columns={columns} data={rows} showFooter={false} />;
};

export default CsvTable;
