import { gql } from '@apollo/client';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import { gql as newGql } from 'common/generated/gql';

export const GET_CREDENTIAL_INSTITUTIONS_QUERY = gql`
  query GetCredentialInstitutions {
    credentials {
      id
      institution {
        id
        name
        plaidInstitutionId
        finicityInstitutionId
        preferredDataProvider
        mxInstitutionId
        logo
      }
    }
  }
`;

export const UPDATE_CREDENTIAL_MUTATION = gql`
  mutation Common_UpdateCredential($input: UpdateCredentialMutationInput!) {
    updateCredential(input: $input) {
      credential {
        id
        updateRequired
      }
    }
  }
`;

export const DISCONNECT_CREDENTIALS_MUTATION = gql`
  mutation Common_DisconnectCredentials($input: DisconnectCredentialsFromDataProviderInput!) {
    disconnectCredentialsFromDataProvider(input: $input) {
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const FIX_COINBASE_CREDENTIAL_MUTATION = newGql(/* GraphQL */ `
  mutation Common_FixCoinbaseCredential($input: FixCoinbaseCredentialInput!) {
    fixCoinbaseCredential(input: $input) {
      credential {
        id
        accounts {
          id
        }
      }
      errors {
        message
      }
    }
  }
`);
