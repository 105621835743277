import React from 'react';
import styled from 'styled-components';

import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

const Container = styled.td`
  height: 200px;

  ${LoadingSpinner} {
    margin: 0 auto;
  }
`;

const TableLoadingContainer = ({ columnsCount }: { columnsCount: number }) => (
  <Container colSpan={columnsCount}>
    <LoadingSpinner />
  </Container>
);

export default TableLoadingContainer;
