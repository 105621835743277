import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CheckAnimation from 'components/lib/ui/CheckAnimation';
import ConfirmationSuccess from 'components/lib/ui/ConfirmationSuccess';
import FlexContainer from 'components/lib/ui/FlexContainer';

import useToast from 'lib/hooks/useToast';

import routes from 'constants/routes';

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.xxlarge} 0px;
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const Container = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.xxlarge} 0px;
  flex: 1;
`;

const HeaderText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin: ${({ theme }) => theme.spacing.small} 0px;
  text-align: center;
`;

const BodyText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.color.text};
  text-align: center;
`;

const CHECK_ANIMATION_SIZE_PX = 72;

const DisconnectSpinwheelSuccessModal = ({ next }: { next: (params?: any) => void }) => {
  const history = useHistory();
  const { openToast } = useToast();

  const onDismiss = useCallback(() => {
    openToast({ title: 'Success', description: 'Disconnected from Spinwheel' });
    history.push(routes.recurring(), { refresh: true });
    next();
  }, [openToast, history, next]);

  return (
    <ConfirmationSuccess title="" dismissText="Done" onDismiss={onDismiss}>
      <Content>
        <Container>
          <CheckAnimation size={CHECK_ANIMATION_SIZE_PX} />
          <HeaderText>Spinwheel Disconnected</HeaderText>
          <BodyText>You have disconnected Monarch and Spinwheel.</BodyText>
        </Container>
      </Content>
    </ConfirmationSuccess>
  );
};

export default DisconnectSpinwheelSuccessModal;
