import { DateTime } from 'luxon';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import Switch, { Case } from 'common/components/utils/Switch';
import CopyBalancePreviewGraph from 'components/accounts/CopyBalancePreviewGraph';
import DateField from 'components/lib/form/Deprecated_DateField';
import FormItemContainer from 'components/lib/form/FormItemContainer';
import SelectField from 'components/lib/form/SelectField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import InformationList, { InformationItem } from 'components/lib/ui/InformationList';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';

import useQuery from 'common/lib/hooks/useQuery';
import { isoDateToMonthAndDay } from 'common/utils/date';
import { getAccountFromOptions } from 'lib/accounts/options';
import type { CopySnapshotsFormValues } from 'lib/accounts/snapshots';
import { snapshotsToPreviewBalanceDataPoints } from 'lib/accounts/snapshots';
import { useContactSupportContext } from 'lib/contexts/ContactSupportContext';
import type { AccountSelectOption } from 'lib/hooks/useAccountSelectOptions';

import { gql as newGql } from 'common/generated/gql';
import type { AccountDetails_GetAccountQuery } from 'common/generated/graphql';
import { ContactSupportReason, ContactSupportTopic } from 'common/generated/graphql';

const PreviewGraphEmpty = styled(FlexContainer).attrs({ center: true })`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xxlarge};
  background-color: ${({ theme }) => theme.color.grayLightBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const Content = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.xlarge};
`;

const AccountSelectField = styled(SelectField)`
  .react-select {
    &__value-container {
      padding-left: ${({ theme }) => theme.spacing.xxsmall};
    }
  }
`;

type Props = {
  fromAccount: NonNullable<AccountDetails_GetAccountQuery['account']>;
  accountOptions: AccountSelectOption[];
  isLoadingAccountOptions: boolean;
};

const CopyBalanceInnerForm = ({ fromAccount, accountOptions, isLoadingAccountOptions }: Props) => {
  const { openContactSupportModal } = useContactSupportContext();
  const { values, isValid } = useFormContext<CopySnapshotsFormValues>();
  const { toAccountId, cutoverDate } = values;
  const toAccount = getAccountFromOptions(accountOptions, toAccountId);

  const {
    data,
    isNetworkRequestInFlight: isLoadingPreview,
    error: previewQueryError,
  } = useQuery(PREVIEW_BALANCES_QUERY, {
    variables: {
      input: {
        fromAccountId: fromAccount.id,
        toAccountId,
        cutoverDate,
      },
    },
    skip: !cutoverDate || !toAccountId,
  });

  const { data: fromAccountSnapshots } = useQuery(ACCOUNT_SNAPSHOTS_QUERY, {
    variables: { accountId: fromAccount.id },
    skip: !toAccountId,
  });
  const { snapshotsForAccount = [] } = fromAccountSnapshots ?? {};
  const fromAccountFirstSnapshotDate = snapshotsForAccount[0]?.date;

  const balanceDataPoints = snapshotsToPreviewBalanceDataPoints(
    data?.previewCopySnapshots ?? [],
    cutoverDate,
  );

  const [minDate, maxDate] = useMemo(() => {
    const firstSnapshotDate = fromAccountFirstSnapshotDate
      ? DateTime.fromISO(fromAccountFirstSnapshotDate)
      : // If no account selected, we can't know the min date of balances, so we just use 1 year ago
        DateTime.local().minus({ year: 1 });
    return [firstSnapshotDate.toJSDate(), DateTime.local().minus({ day: 1 }).toJSDate()];
  }, [fromAccountFirstSnapshotDate]);

  return (
    <Content>
      <AccountSelectField
        name="toAccountId"
        placeholder="Select an account"
        label="Copy history to"
        tooltip="Accounts need to match as either assets or liabilities when copying balance history."
        tooltipProps={{ maxWidth: 214 }}
        menuPortalTarget={document.body}
        isLoading={isLoadingAccountOptions}
        disabled={isLoadingAccountOptions}
        options={accountOptions}
        required
      />
      <DateField
        name="cutoverDate"
        label="Cutover date"
        dateFormat="MMMM d, yyyy"
        placeholderText="Select a date..."
        minDate={minDate}
        maxDate={maxDate}
        showToday
        required
      />
      <FormItemContainer
        label={`Preview of new balance history ${toAccount ? `in ${toAccount.label}` : ''}`}
        name="preview"
        fieldId="preview"
      >
        <Switch>
          <Case when={RA.isNotNil(previewQueryError)}>
            <PreviewGraphEmpty>
              <Text size="small">
                Sorry, there was an error loading the preview.
                <br />
                Please try again or{' '}
                <Link
                  onClick={() =>
                    openContactSupportModal({
                      topic: ContactSupportTopic.SETTINGS,
                      reason: ContactSupportReason.SETTINGS_SOMETHING_ELSE,
                    })
                  }
                >
                  report this issue
                </Link>
                .
              </Text>
            </PreviewGraphEmpty>
          </Case>
          <Case when={isValid}>
            <CopyBalancePreviewGraph isLoading={isLoadingPreview} data={balanceDataPoints} />
          </Case>
          <Case default>
            <PreviewGraphEmpty>
              <Text size="small" italic>
                Select the cutover date and account you want to copy history to and this will show a
                preview of your new balance history.
              </Text>
            </PreviewGraphEmpty>
          </Case>
        </Switch>
      </FormItemContainer>
      {isValid && (
        <InformationList title="What will change:">
          {RA.isNotNil(toAccount) && (
            <InformationItem iconName="x-circle">
              Balance history before {isoDateToMonthAndDay(cutoverDate)} will be deleted in{' '}
              {toAccount.label}
            </InformationItem>
          )}
          <InformationItem iconName="check-circle">
            Balance history before {isoDateToMonthAndDay(cutoverDate)} will be copied from{' '}
            {fromAccount.displayName}
          </InformationItem>
          <InformationItem iconName="alert-triangle">This cannot be undone</InformationItem>
        </InformationList>
      )}
    </Content>
  );
};

const PREVIEW_BALANCES_QUERY = newGql(/* GraphQL */ `
  query Web_PreviewCopySnapshots($input: PreviewCopySnapshotsInput!) {
    previewCopySnapshots(input: $input) {
      date
      signedBalance
    }
  }
`);

const ACCOUNT_SNAPSHOTS_QUERY = newGql(/* GraphQL */ `
  query Web_SnapshotsForAccountCopyBalancePreview($accountId: UUID!) {
    snapshotsForAccount(accountId: $accountId) {
      date
    }
  }
`);

export default CopyBalanceInnerForm;
