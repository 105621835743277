import { useMutation } from '@apollo/client';
import type { MutationHookOptions } from '@apollo/client';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setHasAddedAccounts } from 'common/actions';
import { DELETE_ACCOUNT } from 'common/lib/graphQl/accounts';

import type {
  Common_DeleteAccountMutation,
  Common_DeleteAccountMutationVariables,
} from 'common/generated/graphql';

/** Hook used delete accounts */
const useDeleteAccount = (
  options?: MutationHookOptions<
    Common_DeleteAccountMutation,
    Common_DeleteAccountMutationVariables
  >,
) => {
  const dispatch = useDispatch();

  const [performMutation, mutationInfo] = useMutation(DELETE_ACCOUNT, options);

  const deleteAccount = useCallback(
    (id: string) =>
      performMutation({
        variables: {
          id,
        },
        onCompleted() {
          dispatch(setHasAddedAccounts(undefined));
        },
        ...options,
      }),
    [performMutation],
  );

  return [deleteAccount, mutationInfo] as const;
};

export default useDeleteAccount;
