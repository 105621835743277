import { useEffect } from 'react';

/**
 * Hook to handle adding/removing an event listener to an html element
 * Generic types match that of addEventListener. We can add more overloads as necessary
 */
function useEventListener<K extends keyof HTMLElementEventMap>(
  element: HTMLElement | null | undefined,
  type: K,
  listener: (ev: HTMLElementEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions,
): void;
function useEventListener<K extends keyof DocumentEventMap>(
  element: Document | null | undefined,
  type: K,
  listener: (ev: DocumentEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions,
): void;
function useEventListener<K extends keyof WindowEventMap>(
  element: Window | null | undefined,
  type: K,
  listener: (ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions,
): void;

function useEventListener(
  element: HTMLElement | Document | Window | null | undefined,
  type: any,
  listener: any,
  options: any,
) {
  useEffect(() => {
    element?.addEventListener(type, listener, options);
    return () => {
      element?.removeEventListener(type, listener, options);
    };
  }, [element, type, listener, options]);
}

export default useEventListener;
