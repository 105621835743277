import React from 'react';

import useTheme from 'lib/hooks/useTheme';

import type { Color } from 'common/types/Styles';

type Props = {
  size?: number;
  color?: Color;
  x?: string;
  y?: string;
};

const CheckCircleOutline = ({ size = 24, color = 'gray', ...props }: Props) => {
  const theme = useTheme();

  return (
    <svg width={size} height={size} viewBox="0 0 23 22" fill="none" {...props}>
      <path
        d="M14.9448 9.70711C15.3308 9.31658 15.3308 8.68342 14.9448 8.29289C14.5588 7.90237 13.933 7.90237 13.547 8.29289L10.2923 11.5858L9.01436 10.2929C8.62836 9.90237 8.00254 9.90237 7.61654 10.2929C7.23055 10.6834 7.23055 11.3166 7.61654 11.7071L9.59335 13.7071C9.97935 14.0976 10.6052 14.0976 10.9912 13.7071L14.9448 9.70711Z"
        fill={theme.color[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1531 11C22.1531 17.0751 17.2854 22 11.2807 22C5.27597 22 0.408203 17.0751 0.408203 11C0.408203 4.92487 5.27597 0 11.2807 0C17.2854 0 22.1531 4.92487 22.1531 11ZM20.1763 11C20.1763 15.9706 16.1936 20 11.2807 20C6.36773 20 2.38502 15.9706 2.38502 11C2.38502 6.02944 6.36773 2 11.2807 2C16.1936 2 20.1763 6.02944 20.1763 11Z"
        fill={theme.color[color]}
      />
    </svg>
  );
};

export default CheckCircleOutline;
