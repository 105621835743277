import { useMutation } from '@apollo/client';
import pluralize from 'pluralize';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import type { BulkUpdateState } from 'common/lib/hooks/transactions/useBulkUpdateTransactionState';
import { convertBulkUpdateStateToMutationInput } from 'common/lib/transactions/bulkUpdate';
import useIsBulkTransactionSelectActive from 'lib/hooks/transactions/useIsBulkTransactionSelectActive';
import type { AccountSelectOption } from 'lib/hooks/useAccountSelectOptions';
import toast, { errorToast } from 'lib/ui/toast';

import { DEFAULT_REQUIRED_MESSAGE } from 'common/constants/copy';
import { ACCOUNT_DETAILS_TRANSACTIONS_REFETCH_QUERIES } from 'constants/graphql';
import routes from 'constants/routes';

import { gql } from 'common/generated';
import type { TransactionFilterInput } from 'common/generated/graphql';

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: 0;
`;

const Intro = styled(Text)`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const AccountSelectField = styled(SelectField)`
  .react-select {
    &__value-container {
      padding-left: ${({ theme }) => theme.spacing.xxsmall};
    }
  }
`;

type FormValues = {
  accountId: string;
};

type Props = {
  accountId: string;
  bulkUpdateState: Partial<BulkUpdateState> | undefined;
  accountOptions: AccountSelectOption[];
  isLoadingAccountOptions: boolean;
  filters: TransactionFilterInput;
  transactionsCount: number | undefined;
  onClose: () => void;
};

const MoveTransactionsModal = ({
  accountId: fromAccountId,
  bulkUpdateState,
  accountOptions,
  isLoadingAccountOptions,
  filters,
  transactionsCount,
  onClose,
}: Props) => {
  const history = useHistory();

  const { setBulkSelectActive } = useIsBulkTransactionSelectActive();

  const [moveTransactions] = useMutation(MOVE_TRANSACTIONS_MUTATION, {
    refetchQueries: ACCOUNT_DETAILS_TRANSACTIONS_REFETCH_QUERIES,
  });

  const handleSubmit = async ({ accountId: toAccountId }: FormValues) => {
    try {
      const accountName = R.pipe(
        R.find(R.propEq('value', toAccountId)),
        R.propOr('', 'label'),
      )(accountOptions);

      const result = await moveTransactions({
        variables: {
          input: {
            fromAccountId,
            toAccountId,
            filters,
            ...convertBulkUpdateStateToMutationInput(bulkUpdateState),
          },
        },
      });

      const errorMessage = result.data?.moveTransactions?.errors?.message;
      if (RA.isNotNil(errorMessage)) {
        throw new Error(errorMessage);
      }

      setBulkSelectActive(false);
      onClose();
      toast({
        title: `Moved to ${accountName}`,
        description: 'View your transactions in the new account',
        actions: [
          {
            text: 'View account',
            onClick: () => history.push(routes.accounts.accountDetails({ id: toAccountId })),
          },
        ],
      });
    } catch (e) {
      errorToast((e as Error).message);
    }
  };

  const showCount = !!transactionsCount && transactionsCount > 1;
  // If `transactionsCount` is undefined, `transactionsLabel` will be 'transactions'
  const transactionsLabel = pluralize('transaction', transactionsCount, showCount);

  return (
    <ModalCard title="Move transactions to another account" hideBottomBorder>
      <Form
        initialValues={{ accountId: undefined }}
        onSubmit={(values: FormValues) => handleSubmit(values)}
        overrideValidationSchema={Yup.object().shape({
          accountId: Yup.string()
            .required(DEFAULT_REQUIRED_MESSAGE)
            .notOneOf([fromAccountId], 'Cannot move transactions to the same account'),
        })}
      >
        <Content>
          <Intro>
            {`The ${transactionsLabel} you selected will be moved to the account you choose below.`}
          </Intro>
          <AccountSelectField
            name="accountId"
            placeholder="Select an account"
            label={`Move ${transactionsLabel} to`}
            menuPortalTarget={document.body}
            isLoading={isLoadingAccountOptions}
            disabled={isLoadingAccountOptions}
            options={accountOptions}
            required
          />
        </Content>
        <CardFooter>
          <DefaultButton onClick={onClose}>Cancel</DefaultButton>
          <FormSubmitButton size="small">Move transactions</FormSubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

const MOVE_TRANSACTIONS_MUTATION = gql(/* GraphQL */ `
  mutation Web_MoveTransactions($input: MoveTransactionsInput!) {
    moveTransactions(input: $input) {
      numTransactionsMoved
      errors {
        message
      }
    }
  }
`);

export default MoveTransactionsModal;
