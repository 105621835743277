import { gql } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import Tabs from 'common/components/tabs/Tabs';
import CashFlowBarChart from 'components/cashFlows/CashFlowBarChart';
import CashFlowControls from 'components/cashFlows/CashFlowControls';
import CashFlowSankey from 'components/cashFlows/CashFlowSankeyDiagram';
import Flex from 'components/lib/ui/Flex';
import Grid, { GridItem } from 'components/lib/ui/Grid';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import PageWithNoAccountsEmptyState from 'components/lib/ui/PageWithNoAccountsEmptyState';

import {
  aggregatesDataAdapter,
  getDateRangeForTimeframe,
  getDefaultDateForTimeframe,
} from 'common/lib/cashFlow/adapters';
import useQuery from 'common/lib/hooks/useQuery';
import { chartDataPointFormatter } from 'lib/cashFlow/Adapters';
import {
  byCategoryAsEntries,
  byCategoryGroupAsEntries,
  byMerchantAsEntries,
} from 'lib/cashFlow/BreakdownAdapters';
import { getMostRecentDateIfCurrentDateNonAvailable } from 'lib/cashFlow/filter';
import useFilters from 'lib/hooks/transactions/useFilters';
import usePersistentFilter from 'lib/hooks/usePersistentFilter';
import { useUpdatableQueryParam } from 'lib/hooks/useQueryParams';
import { convertFiltersToInput } from 'lib/transactions/Filters';
import { formatFullDateRange, getDateShorthand } from 'utils/dateRange';

import { ONBOARDING_CASH_FLOW_OVERLAY_TITLE } from 'common/constants/copy';
import type { Timeframe } from 'common/constants/timeframes';
import { DEFAULT_TIMEFRAME, TIMEFRAME_OPTIONS } from 'common/constants/timeframes';

import type {
  GetCashFlowSankeyPageQuery,
  GetCashFlowSankeyPageQueryVariables,
  Web_CashFlowSankeyAggregatesQuery,
  Web_CashFlowSankeyAggregatesQueryVariables,
} from 'common/generated/graphql';
import { CategoryGroupType } from 'common/generated/graphql';

const LoadingContainer = styled(Flex)`
  flex: 1;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.default};
`;

const DateLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const CashFlow = () => {
  const {
    activeFilters: { timeframe },
    updateFilter,
  } = usePersistentFilter('cashFlow');
  const activeTimeframe = (timeframe ?? DEFAULT_TIMEFRAME) as Timeframe;
  const initialTimeframeIndex = useMemo(
    () => TIMEFRAME_OPTIONS.findIndex(({ value }) => value === activeTimeframe),
    [activeTimeframe],
  );

  const [date, setDate] = useUpdatableQueryParam('date');
  const activeDate = date || getDefaultDateForTimeframe(activeTimeframe);

  const [filters] = useFilters();
  const cleanedFilters = convertFiltersToInput(filters);
  const { data: aggregatesData } = useQuery<
    Web_CashFlowSankeyAggregatesQuery,
    Web_CashFlowSankeyAggregatesQueryVariables
  >(GET_AGGREGATES_QUERY, {
    variables: { filters: cleanedFilters },
  });
  const timeframeData = aggregatesDataAdapter(
    aggregatesData,
    activeTimeframe,
    chartDataPointFormatter,
  );
  const dateRange = useMemo(
    () => getDateRangeForTimeframe(activeTimeframe, timeframeData, activeDate),
    [activeDate, activeTimeframe, timeframeData],
  );

  const { data, refetch } = useQuery<
    GetCashFlowSankeyPageQuery,
    GetCashFlowSankeyPageQueryVariables
  >(GET_CASH_FLOW_DETAILS_QUERY, { variables: { filters: { ...cleanedFilters, ...dateRange } } });

  const breakdownItems = useMemo(() => {
    const { byCategory = [], byCategoryGroup = [], byMerchant = [] } = data ?? {};

    const incomesByCategory = byCategoryAsEntries(
      byCategory,
      dateRange,
      CategoryGroupType.INCOME,
      activeTimeframe,
    );
    const expensesByCategory = byCategoryAsEntries(
      byCategory,
      dateRange,
      CategoryGroupType.EXPENSE,
      activeTimeframe,
    );
    const expensesByMerchant = byMerchantAsEntries(
      byMerchant,
      dateRange,
      CategoryGroupType.EXPENSE,
      activeTimeframe,
    );
    const incomesByMerchant = byMerchantAsEntries(
      byMerchant,
      dateRange,
      CategoryGroupType.INCOME,
      activeTimeframe,
    );
    const incomesByCategoryGroup = byCategoryGroupAsEntries(
      byCategoryGroup,
      CategoryGroupType.INCOME,
      dateRange,
      activeTimeframe,
    );
    const expensesByCategoryGroup = byCategoryGroupAsEntries(
      byCategoryGroup,
      CategoryGroupType.EXPENSE,
      dateRange,
      activeTimeframe,
    );

    return {
      incomesByCategory,
      expensesByCategory,
      expensesByMerchant,
      incomesByMerchant,
      incomesByCategoryGroup,
      expensesByCategoryGroup,
    };
  }, [data, dateRange, activeTimeframe]);

  useEffect(() => {
    const newDate = getMostRecentDateIfCurrentDateNonAvailable(timeframeData, activeDate);
    if (newDate !== activeDate) {
      updateFilter({ date: newDate });
    }
  }, [timeframeData, activeDate]);

  const dateShorthand = useMemo(() => getDateShorthand(dateRange), [dateRange]);
  return (
    <Tabs initialIndex={initialTimeframeIndex}>
      <PageWithNoAccountsEmptyState
        name="Cash Flow"
        emptyIcon="bar-chart2"
        emptyTitle={ONBOARDING_CASH_FLOW_OVERLAY_TITLE}
        controls={<CashFlowControls />}
        onAccountAdded={refetch}
      >
        {(() => {
          if (data) {
            return (
              <Grid template={`"chart" "date" "group" "category" "merchant"`}>
                <GridItem area="chart">
                  <CashFlowBarChart
                    onBarClick={setDate}
                    data={timeframeData}
                    timeframe={activeTimeframe}
                    activeDate={activeDate}
                  />
                </GridItem>

                <GridItem area="date">
                  <DateLabel>{dateShorthand || formatFullDateRange(dateRange)}</DateLabel>
                </GridItem>

                <GridItem area="group">
                  <CashFlowSankey
                    expenses={breakdownItems.expensesByCategoryGroup}
                    incomes={breakdownItems.incomesByCategory}
                    title="Expenses by Category Group"
                  />
                </GridItem>

                <GridItem area="category">
                  <CashFlowSankey
                    expenses={breakdownItems.expensesByCategory}
                    incomes={breakdownItems.incomesByCategory}
                    title="Expenses by Category"
                  />
                </GridItem>
                <GridItem area="merchant">
                  <CashFlowSankey
                    expenses={breakdownItems.expensesByMerchant}
                    incomes={breakdownItems.incomesByCategory}
                    title="Expenses by Merchant"
                  />
                </GridItem>
              </Grid>
            );
          } else {
            return (
              <LoadingContainer center column>
                <LoadingSpinner />
                Loading cash flow...
              </LoadingContainer>
            );
          }
        })()}
      </PageWithNoAccountsEmptyState>
    </Tabs>
  );
};

const GET_AGGREGATES_QUERY = gql`
  query Web_CashFlowSankeyAggregates($filters: TransactionFilterInput) {
    byYear: aggregates(groupBy: ["year"], fillEmptyValues: true, filters: $filters) {
      groupBy {
        year
      }

      summary {
        savings
        savingsRate
        sumIncome
        sumExpense
      }
    }

    byMonth: aggregates(groupBy: ["month"], fillEmptyValues: true, filters: $filters) {
      groupBy {
        month
      }

      summary {
        savings
        savingsRate
        sumIncome
        sumExpense
      }
    }

    byQuarter: aggregates(groupBy: ["quarter"], fillEmptyValues: true, filters: $filters) {
      groupBy {
        quarter
      }

      summary {
        savings
        savingsRate
        sumIncome
        sumExpense
      }
    }
  }
`;

export const GET_CASH_FLOW_DETAILS_QUERY = gql`
  query GetCashFlowSankeyPage($filters: TransactionFilterInput) {
    byCategory: aggregates(filters: $filters, groupBy: ["category"]) {
      groupBy {
        category {
          id
          name
          icon

          group {
            id
            type
          }
        }
      }

      summary {
        sum
      }
    }

    byCategoryGroup: aggregates(filters: $filters, groupBy: ["categoryGroup"]) {
      groupBy {
        categoryGroup {
          id
          name
          type
        }
      }

      summary {
        sum
      }
    }

    byMerchant: aggregates(filters: $filters, groupBy: ["merchant"]) {
      groupBy {
        merchant {
          id
          name
          logoUrl
        }
      }

      summary {
        sumIncome
        sumExpense
      }
    }

    summary: aggregates(filters: $filters, fillEmptyValues: true) {
      summary {
        sumIncome
        sumExpense
        savings
        savingsRate
      }
    }
  }
`;

export default CashFlow;
