import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import useQuery from 'common/lib/hooks/useQuery';
import { sortTags } from 'common/lib/transactions/tags';

import type {
  GetHouseholdTransactionTags,
  GetHouseholdTransactionTags_householdTransactionTags,
  GetHouseholdTransactionTagsVariables,
} from 'common/generated/graphQlTypes/GetHouseholdTransactionTags';

interface Props extends GetHouseholdTransactionTagsVariables {
  includeTransactionCount?: boolean;
}

/** Hook used to fetch transaction tags once and get them on demand. */
const useTransactionTags = (variables?: Props) => {
  const { data, isLoadingInitialData, isNetworkRequestInFlight, refetch } = useQuery<
    GetHouseholdTransactionTags,
    GetHouseholdTransactionTagsVariables
  >(GET_HOUSEHOLD_TAGS, { variables, fetchPolicy: 'cache-first' });
  const { householdTransactionTags: tags = [] } = data ?? {};
  const sortedTags = useMemo(() => sortTags(tags), [tags]);

  const getTags = useCallback(
    (ids: string[] | undefined): GetHouseholdTransactionTags_householdTransactionTags[] => {
      const evolvedIds: Record<string, true> = (ids ?? []).reduce(
        (ids, currentId) => ({ ...ids, [currentId]: true }),
        {},
      );
      return sortedTags.filter(({ id }) => evolvedIds[id]);
    },
    [sortedTags],
  );

  return { tags: sortedTags, isLoadingInitialData, isNetworkRequestInFlight, getTags, refetch };
};

export const GET_HOUSEHOLD_TAGS = gql`
  query GetHouseholdTransactionTags(
    $search: String
    $limit: Int
    $bulkParams: BulkTransactionDataParams
    $includeTransactionCount: Boolean = false
  ) {
    householdTransactionTags(search: $search, limit: $limit, bulkParams: $bulkParams) {
      id
      name
      color
      order
      transactionCount @include(if: $includeTransactionCount)
    }
  }
`;

export default useTransactionTags;
