import { isNil } from 'ramda';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import PrimaryButton from './button/PrimaryButton';

export enum Theme {
  OLD = 'OLD', // Large title with dark gray subtitle
  NEW = 'NEW', // https://www.figma.com/file/T5GillIoFh2hETRIjYjqzB/Goals?node-id=1776%3A15894
}

type EmptyProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  button?: {
    title: string;
    onClick: () => void;
    isPrimary?: boolean;
  };
  children?: React.ReactNode;
};

type WrapperProps = EmptyProps & {
  emptyTheme?: Theme;
};

/** [OLD] Empty components */

const Root = styled.div`
  text-align: center;
`;

const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const Subtitle = styled.p`
  color: ${({ theme }) => theme.color.textLight};
`;

/** [NEW] Empty components */

export const EmptyRootContainer = styled(FlexContainer).attrs({
  column: true,
  alignCenter: true,
  justifyCenter: true,
})`
  padding: ${({ theme }) => theme.spacing.xxxlarge};
  text-align: center;

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }
`;

export const EmptyTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const EmptySubtitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.color.textLight};
`;

const EmptyIcon = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

/** Container components */

const Empty = ({ emptyTheme = Theme.NEW, ...props }: WrapperProps) =>
  emptyTheme === Theme.OLD ? <OldEmpty {...props} /> : <NewEmpty {...props} />;

const OldEmpty = ({ title, subtitle, className, children }: EmptyProps) => (
  <Root className={className}>
    {!!title && <Title>{title}</Title>}
    {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
    {children}
  </Root>
);

const NewEmpty = ({ title, subtitle, icon, className, button, children }: EmptyProps) => (
  <EmptyRootContainer className={className}>
    {!!icon && <EmptyIcon>{icon}</EmptyIcon>}
    {!!title && <EmptyTitle>{title}</EmptyTitle>}
    {!!subtitle && <EmptySubtitle>{subtitle}</EmptySubtitle>}
    {!isNil(button) &&
      (button.isPrimary ? (
        <PrimaryButton size="medium" onClick={button?.onClick}>
          {button?.title}
        </PrimaryButton>
      ) : (
        <DefaultButton size="medium" onClick={button?.onClick}>
          {button?.title}
        </DefaultButton>
      ))}
    {children}
  </EmptyRootContainer>
);

export default Empty;
