import React from 'react';
import { FiInfo } from 'react-icons/fi';
import styled from 'styled-components';

import type { Props as TooltipProps } from 'components/lib/ui/Tooltip';
import Tooltip from 'components/lib/ui/Tooltip';

type Props = Pick<TooltipProps, 'place'> & {
  tooltip: string | React.ReactNode;
  className?: string;
  sizePx?: number;
  iconColor?: string;
  clickable?: boolean;
};

const Root = styled.div`
  display: inline-block;
`;

const HelpIconTooltip = ({
  tooltip,
  className,
  sizePx,
  iconColor,
  clickable = false,
  ...tooltipProps
}: Props) => (
  <Tooltip place="top" effect="solid" content={tooltip} clickable={clickable} {...tooltipProps}>
    <Root className={className}>
      <FiInfo size={sizePx} color={iconColor} />
    </Root>
  </Tooltip>
);

export default HelpIconTooltip;
