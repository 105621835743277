import React from 'react';
import { FiLoader } from 'react-icons/fi';
import styled, { keyframes } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import type { AbstractButtonProps } from 'components/lib/ui/button/AbstractButton';
import AbstractButton from 'components/lib/ui/button/AbstractButton';

type Props = AbstractButtonProps & {
  pending?: boolean;
  pendingElement?: React.ReactNode;
};

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(FiLoader)`
  animation: 1.2s ease-in-out infinite ${spin};
  width: 1.5em;
  height: 1.5em;
`;

const AsyncButton: React.ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  { pending, pendingElement, children, disabled, ...props }: Props,
  ref,
) => (
  <AbstractButton ref={ref} {...props} disabled={pending || disabled}>
    {pending ? (
      <FlexContainer alignCenter>
        <Spinner />
        {pendingElement}
      </FlexContainer>
    ) : (
      children
    )}
  </AbstractButton>
);

export default React.forwardRef(AsyncButton);
