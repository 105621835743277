import { DateTime } from 'luxon';
import * as R from 'ramda';

import { sort } from 'common/utils/Array';

import type { GetCashFlowDashboardQuery, Maybe } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

export enum CashFlowTimeRange {
  ThisWeekVersusLastWeek = 'This week vs. last week',
  ThisMonthVersusLastMonth = 'This month vs. last month',
  ThisMonthVersusLastYear = 'This month vs. last year',
}

export const dropdownFilterOptions = (
  Object.keys(CashFlowTimeRange) as (keyof typeof CashFlowTimeRange)[]
).map((key) => ({
  label: CashFlowTimeRange[key],
  value: CashFlowTimeRange[key],
}));

export const TIME_RANGE_LABELS = {
  [CashFlowTimeRange.ThisWeekVersusLastWeek]: {
    previousPeriodLegend: 'Last week',
    currentPeriodLegend: 'This week',
    tooltipCurrentPeriodLabel: 'This week',
    tooltipPreviousPeriodLabel: 'Last week',
  },
  [CashFlowTimeRange.ThisMonthVersusLastMonth]: {
    previousPeriodLegend: 'Last month',
    currentPeriodLegend: 'This month',
    tooltipCurrentPeriodLabel: 'This month',
    tooltipPreviousPeriodLabel: 'Last month',
  },
  [CashFlowTimeRange.ThisMonthVersusLastYear]: {
    previousPeriodLegend: 'This month last year',
    currentPeriodLegend: 'This month',
    tooltipCurrentPeriodLabel: 'This month',
    tooltipPreviousPeriodLabel: 'This month last year',
  },
};

export const sortByDay = R.sortBy((entry: any) => entry.groupBy?.day);

const groupByPeriod = (
  byDayData: GetCashFlowDashboardQuery['byDay'],
  period: 'week' | 'month' | 'year',
) =>
  R.groupBy(
    (entry: ElementOf<GetCashFlowDashboardQuery, 'byDay'>) =>
      DateTime.fromISO(entry.groupBy?.day || '')
        .startOf(period)
        .toISODate(),
    byDayData,
  );

export const groupByWeekAndSortByDay = (byDayData: GetCashFlowDashboardQuery['byDay']) => {
  const grouped = groupByPeriod(byDayData, 'week');
  const months = sort(Object.keys(grouped));
  const groupedByMonth = R.map((month) => grouped[month], months);
  return R.map(sortByDay, groupedByMonth);
};

export const groupByMonthAndSortByDay = (byDayData: GetCashFlowDashboardQuery['byDay']) => {
  const grouped = groupByPeriod(byDayData, 'month');
  const months = sort(Object.keys(grouped));
  const groupedByMonth = R.map((month) => grouped[month], months);
  return R.map(sortByDay, groupedByMonth);
};

export const groupByYearAndSortByDay = (byDayData: GetCashFlowDashboardQuery['byDay']) => {
  const grouped = groupByPeriod(byDayData, 'year');
  const months = sort(Object.keys(grouped));
  const groupedByMonth = R.map((month) => grouped[month], months);
  return R.map(sortByDay, groupedByMonth);
};

type DayBalance = {
  day: number;
  balance: number;
  date: string;
};

type MergedBalanceDatum = {
  day: number;
  balance: number;
  previousBalance: number;
  date: string;
  previousDate: string;
};

// Turn the expense data each day into cumulative data (ie each day is the sum of it and all
// previous days).
export const asCumulativeEntries = (
  byDayData: GetCashFlowDashboardQuery['byDay'],
): DayBalance[] => {
  // Invert sumExpense (which is negative usually) so it displays as positive values
  const entries = R.map(
    (entry) => ({
      day: DateTime.fromISO(entry.groupBy?.day || '').day,
      balance: -entry.summary.sumExpense,
      date: entry.groupBy?.day || '',
    }),
    byDayData,
  );
  const cumulativeEntries = R.mapAccum(
    (e1, e2) => [{ balance: e1.balance + e2.balance }, { ...e2, balance: e1.balance + e2.balance }],
    { balance: 0 },
    entries,
  );
  return cumulativeEntries[1];
};

export const mergeBalanceData = (
  periodData: DayBalance[] | undefined = [],
  previousPeriodData: DayBalance[] | undefined = [],
): MergedBalanceDatum[] => {
  const maxDays = Math.max(periodData.length, previousPeriodData.length);
  return R.map(
    (index) => ({
      day: index + 1,
      balance: periodData[index]?.balance,
      previousBalance: previousPeriodData[index]?.balance,
      date: periodData[index]?.date,
      previousDate: previousPeriodData[index]?.date,
    }),
    [...Array(maxDays).keys()],
  );
};

export const getGroupedDataForRangeType = (
  byDayData: GetCashFlowDashboardQuery['byDay'],
  type: CashFlowTimeRange,
) => {
  switch (type) {
    case CashFlowTimeRange.ThisMonthVersusLastMonth:
      return groupByMonthAndSortByDay(byDayData);
    case CashFlowTimeRange.ThisWeekVersusLastWeek:
      return groupByWeekAndSortByDay(byDayData);
    case CashFlowTimeRange.ThisMonthVersusLastYear:
      return groupByYearAndSortByDay(byDayData);
  }
};

export const getMergedBalanceDataForRangeType = (
  previousYearMonthData: Maybe<GetCashFlowDashboardQuery>,
  data: Maybe<GetCashFlowDashboardQuery>,
  type: CashFlowTimeRange,
): MergedBalanceDatum[] | undefined => {
  const previousYearMonthDataByDay = previousYearMonthData?.byDay || [];
  const previousPeriodDataByDay = data?.byDay || [];

  const isThisMonthVersusLastYear = type === CashFlowTimeRange.ThisMonthVersusLastYear;

  const byDayData = isThisMonthVersusLastYear
    ? [...previousYearMonthDataByDay, ...previousPeriodDataByDay]
    : [...previousPeriodDataByDay];

  const groupedByPeriod = getGroupedDataForRangeType(byDayData, type);

  const [period1Data, period2Data] = groupedByPeriod.map(asCumulativeEntries);

  return mergeBalanceData(period2Data, period1Data);
};
