import * as R from 'ramda';
import type { TooltipPayload } from 'recharts';

import { getBenchmarkNameFromTicker } from 'common/lib/investments/benchmarks';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import type { HoldingInfo } from 'lib/hooks/investments/usePerformanceChartColors';

import type {
  Web_GetPortfolio_portfolio_performance_historicalChart,
  Web_GetPortfolio_portfolio_performance_benchmarks,
  Web_GetPortfolio_portfolio_performance,
} from 'common/generated/graphQlTypes/Web_GetPortfolio';
import type { Web_GetSecuritiesHistoricalPerformance_securityHistoricalPerformance } from 'common/generated/graphQlTypes/Web_GetSecuritiesHistoricalPerformance';
import type { HistoricalGraphDataPoint } from 'types/investments';

export const getBenchmarkDataKey = (id: string) => `${id}-benchmark`;

export const historicalPerformanceQueryToLineChartDataAdapter = (
  portfolioData: Web_GetPortfolio_portfolio_performance_historicalChart[],
  comparisonData: Web_GetSecuritiesHistoricalPerformance_securityHistoricalPerformance[],
  benchmarkData: Web_GetPortfolio_portfolio_performance_benchmarks[],
): HistoricalGraphDataPoint[] => {
  const cleanedPortfolioData = portfolioData.map(({ date, returnPercent }) => ({
    date,
    portfolioReturn: returnPercent,
  }));
  const comparisonDataWithSecurityId = comparisonData.map(({ security, historicalChart }) =>
    historicalChart.map(({ date, returnPercent }) => ({ date, [security.id]: returnPercent })),
  );
  const benchmarkDataWithSecurityId = benchmarkData.map(({ security, historicalChart }) =>
    historicalChart.map(({ date, returnPercent }) => ({
      date,
      [getBenchmarkDataKey(security.id)]: returnPercent,
    })),
  );

  comparisonDataWithSecurityId.forEach((data) => {
    if (data.length !== portfolioData.length) {
      // These numbers briefly do not match when changing GQL query. TODO @maushundb
      // throw new Error('Cannot zip portfolio data with comparison data, date ranges do not match!');
      return [];
    }
  });

  const dataToZip = [
    cleanedPortfolioData,
    ...comparisonDataWithSecurityId,
    ...benchmarkDataWithSecurityId,
  ];
  return dataToZip.reduce((acc, list) =>
    R.zipWith(
      ({ date, ...yAxisDataOne }, { date: _, ...yAxisDataTwo }) => ({
        date,
        ...yAxisDataOne,
        ...yAxisDataTwo,
      }),
      acc,
      list,
    ),
  );
};

export const getBenchmarkHeaderBenchmarkData = (
  data: Web_GetPortfolio_portfolio_performance | undefined,
): {
  name: string;
  dateRangeReturn: number;
  oneDayChangePercent: number | null;
  security: { id: string; ticker: string | null; name: string };
}[] =>
  data?.benchmarks.map(({ security, historicalChart }) => ({
    name: getBenchmarkNameFromTicker(security.ticker) ?? '',
    dateRangeReturn: R.last(historicalChart)?.returnPercent ?? 0,
    oneDayChangePercent: security.oneDayChangePercent,
    security,
  })) ?? [];

export const getBenchmarkHeaderPortfolioData = (
  data: Web_GetPortfolio_portfolio_performance | undefined,
) => {
  const historicalPerformance = data?.historicalChart ?? [];
  const dateRangeReturn = R.last(historicalPerformance)?.returnPercent ?? 0;
  const oneDayReturn = data?.oneDayChangePercent ?? 0;

  return { dateRangeReturn, oneDayReturn };
};

export const tickerHasAlias = (ticker: string | null | undefined): ticker is string =>
  getBenchmarkNameFromTicker(ticker) !== undefined;

export const getHoldingOrBenchmarkLabel = (holdingInfo: HoldingInfo) => {
  if (holdingInfo.isBenchmark && tickerHasAlias(holdingInfo.ticker)) {
    return getBenchmarkNameFromTicker(holdingInfo.ticker);
  }
  return holdingInfo.ticker;
};

export const getHistoricalChartTooltipData = <TTooltip extends Omit<TooltipPayload, 'name'>>(
  holdings: HoldingInfo[],
  chartData: HistoricalGraphDataPoint[],
  active: boolean,
  hoveredLines: TTooltip[] | undefined,
) => {
  if (!active || !hoveredLines || (hoveredLines ?? []).length <= 0) {
    return undefined;
  }

  const startDate = isoDateToAbbreviatedMonthDayAndYear(chartData[0].date);
  const hoveredDate = isoDateToAbbreviatedMonthDayAndYear(hoveredLines[0].payload?.date);

  const values = hoveredLines.map(({ dataKey, value, color }) => {
    const holdingInfo = holdings.find(({ dataKey: holdingDataKey }) => holdingDataKey === dataKey);
    const label = (() => {
      if (!holdingInfo) {
        return 'Your Portfolio';
      }

      if (tickerHasAlias(holdingInfo.ticker)) {
        return getHoldingOrBenchmarkLabel(holdingInfo);
      }

      if (holdingInfo.name) {
        return `${holdingInfo.name} (${holdingInfo.ticker})`;
      }

      return holdingInfo.ticker;
    })();

    return {
      label,
      value: value as number,
      color: color as string,
    };
  });

  return {
    header: `${startDate} - ${hoveredDate}`,
    data: values,
  };
};
