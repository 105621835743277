import * as R from 'ramda';
import React from 'react';
import deepIsEqual from 'react-fast-compare';

import type { Block, BlockData, MessageBlockType } from 'common/lib/assistant/blocks/types';
import { memo } from 'common/utils/React';

type BlockRenderFunction<T extends Block<any>> = (props: {
  data: BlockData<T>;
  incomplete?: boolean;
  previousBlockInGroup?: MessageBlockType;
  nextBlockInGroup?: MessageBlockType;
  nextGroup?: { content: string }[];
}) => React.ReactElement | null;

type BlockRenderer<T extends Block<any>> = {
  block: T;
  render: BlockRenderFunction<T>;
};

export const makeBlockRenderer =
  <T extends Block<any>>(block: T) =>
  (render: BlockRenderFunction<T>): BlockRenderer<T> => ({
    block,
    render: memo(render, deepIsEqual),
  });

export const indexBlockRenderersByType = (blockRenderers: BlockRenderer<any>[]) =>
  R.indexBy(({ block }) => block.type, blockRenderers);

type Props = {
  block: MessageBlockType;
  blockRenderers: Record<string, BlockRenderer<any>>;
  previousBlockInGroup?: MessageBlockType;
  nextBlockInGroup?: MessageBlockType;
  nextGroup?: { content: string }[];
};

export const BlockRenderer = ({
  block,
  blockRenderers,
  previousBlockInGroup,
  nextBlockInGroup,
  nextGroup,
}: Props) => {
  const Component = blockRenderers[block.type]?.render;
  return (
    Component && (
      // @ts-ignore [REACT-NATIVE-UPGRADE] TS error goes away when we upgrade to React 18 in common
      <Component
        data={block.data}
        incomplete={block.incomplete}
        previousBlockInGroup={previousBlockInGroup}
        nextBlockInGroup={nextBlockInGroup}
        nextGroup={nextGroup}
      />
    )
  );
};
