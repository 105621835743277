import * as React from 'react';
import { useSelector } from 'react-redux';
import type { RouteProps } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';

import { getIsEmployee } from 'state/user/selectors';

const EmployeeRoute = ({ children, ...props }: RouteProps) => {
  const isEmployee = useSelector(getIsEmployee);
  if (!isEmployee) {
    return <Redirect to="/" />;
  }

  return <Route {...props}>{children}</Route>;
};

export default EmployeeRoute;
