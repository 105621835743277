import type { SyntheticEvent } from 'react';
import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

export type AbstractButtonProps = {
  onClick?: (e: SyntheticEvent) => void;
  linkTo?: string;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  /** Adds 'btn-active' class to simulate forcing :active */
  active?: boolean;
  type?: 'button' | 'submit' | 'reset';
  target?: '_blank' | '_self' | '_parent' | '_top';
};

const Root = styled.button.attrs<{ active?: boolean }>(({ active, ...props }) => ({
  ...props,
  className: active ? 'btn-active' : undefined,
}))<{ to?: string }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.radius.small};
  border-style: none;
  line-height: 150%;
  transition: background-color 0.1s ease-out;
  appearance: none !important;
  background-color: transparent;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const mergeLinkToAndSearchParams = (linkTo: string, search: string) =>
  linkTo.includes('?') ? linkTo + search.replace('?', '&') : linkTo + search;

const AbstractButton: React.ForwardRefRenderFunction<HTMLButtonElement, AbstractButtonProps> = (
  {
    children,
    linkTo,
    type = 'button', // Default to "button" because otherwise the browser will treat any button inside a <form> as "submit"
    ...props
  },
  ref,
) => {
  const { search } = useLocation();

  const to = useMemo(
    () => (linkTo ? mergeLinkToAndSearchParams(linkTo, search) : undefined), // make sure query params are passed along
    [linkTo, search],
  );

  return (
    <Root {...props} type={type} ref={ref} as={linkTo ? NavLink : Root} to={to}>
      {children}
    </Root>
  );
};

export default React.forwardRef(AbstractButton);
