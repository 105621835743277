import React from 'react';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import EmojiPicker from 'components/lib/ui/EmojiPicker';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';

import type { ValidationOptions } from 'common/lib/form/validation';
import fieldStyleMixin from 'lib/styles/fieldStyleMixin';

export type Props = Pick<
  React.HTMLProps<HTMLInputElement>,
  'type' | 'onBlur' | 'autoFocus' | 'onFocus' | 'onClick' | 'placeholder' | 'id' | 'defaultValue'
> &
  Pick<ValidationOptions, 'name'> & {
    small?: boolean;
    value?: string;
    onChange?: (emoji: string) => void;
    dismissOnChange?: boolean;
  };

const Input = styled.input`
  ${fieldStyleMixin}
  cursor: pointer;
`;

const EmojiInput: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { id, name, type = 'text', small = false, value, onChange, dismissOnChange = true, ...props },
  ref,
) => (
  <OverlayTrigger
    placement="bottom-start"
    overlay={({ close }) => (
      <Popover>
        <Card>
          <EmojiPicker
            onClick={({ native }) => {
              if (dismissOnChange) {
                close();
              }
              onChange?.(native);
            }}
          />
        </Card>
      </Popover>
    )}
  >
    {({ open, close }) => (
      <Input
        {...props}
        autoComplete="off"
        value={value ?? ''}
        readOnly
        ref={ref}
        id={id}
        type={type}
        name={name}
        small={small}
        onClick={(e) => {
          open();
          props.onClick?.(e);
        }}
        onFocus={(e) => {
          open();
          props.onFocus?.(e);
        }}
      />
    )}
  </OverlayTrigger>
);

export default React.forwardRef(EmojiInput);
