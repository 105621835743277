import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import Confirmation from 'components/lib/ui/Confirmation';
import { DATE_FORMAT } from 'components/routes/settings/dataSettings/common';

import { DELETE_SYNCED_SNAPSHOTS_MUTATION } from 'common/lib/graphQl/snapshots';
import { errorToast } from 'lib/ui/toast';

import type {
  DeleteSyncedSnapshotsMutation,
  DeleteSyncedSnapshotsMutationVariables,
} from 'common/generated/graphQlTypes/DeleteSyncedSnapshotsMutation';
import { ErrorCode } from 'common/generated/graphQlTypes/globalTypes';

type Props = {
  beforeDate: string;
  expectedAffectedSnapshotsCount: number;
  onCancel: () => void;
  onDelete: () => void;
};

const DeleteSyncedSnapshotsConfirmation = ({
  beforeDate,
  expectedAffectedSnapshotsCount,
  onCancel,
  onDelete,
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSyncedSnapshots] = useMutation<
    DeleteSyncedSnapshotsMutation,
    DeleteSyncedSnapshotsMutationVariables
  >(DELETE_SYNCED_SNAPSHOTS_MUTATION);

  const performDelete = async () => {
    setIsDeleting(true);

    const { data } = await deleteSyncedSnapshots({
      variables: { input: { beforeDate, expectedAffectedSnapshotsCount } },
    });

    if (data?.deleteSyncedSnapshots?.success) {
      onDelete();
    } else {
      const errors = data?.deleteSyncedSnapshots?.errors;

      if (errors?.code === ErrorCode.DELETE_SNAPSHOTS_HISTORY_UNAVAILABLE) {
        errorToast(errors?.message);
      } else {
        errorToast(
          "We couldn't delete the snapshots history. Please try again or report this issue.",
        );
      }

      onCancel();
    }
  };

  return (
    <Confirmation
      title={`Are you sure you want to delete your account balance snapshots before ${DateTime.fromISO(
        beforeDate,
      ).toFormat(DATE_FORMAT)}?`}
      confirm="Delete"
      isLoading={isDeleting}
      onCancel={onCancel}
      onConfirm={performDelete}
      useDangerButton
    >
      <div>
        These snapshots will be permanently removed from Monarch, and this action cannot be undone.
      </div>
    </Confirmation>
  );
};

export default DeleteSyncedSnapshotsConfirmation;
