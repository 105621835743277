import { transparentize } from 'polished';
import { isNil } from 'ramda';
import * as RA from 'ramda-adjunct';
import { isNotNil } from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ProgressBar from 'components/lib/ui/ProgressBar';
import Text from 'components/lib/ui/Text';
import type { AmountsTooltipRowProps } from 'components/plan/AmountsTooltip';
import AmountsTooltip from 'components/plan/AmountsTooltip';

import { getBudgetedAmountWithRollover } from 'common/lib/budget/Amounts';
import { formatCurrencyNoCents } from 'common/utils/Currency';
import { formatPercentNoDecimals } from 'common/utils/Number';
import type { PlanAmounts } from 'lib/plan';
import { getAmountForType } from 'lib/plan';

import { BudgetRolloverPeriodType } from 'common/constants/budget';

export const WIDTH_PX = 300;

const Warning = styled(Banner).attrs({ type: 'warning' })`
  background: ${({ theme }) => transparentize(0.8, theme.color.yellowBackground)};
  color: ${({ theme }) => theme.color.yellow};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 150%;
`;

type Props = {
  amounts: PlanAmounts;
  title?: string;
  warningText?: string;
};

const PlanAmountsTooltip = ({ amounts, title, warningText }: Props) => {
  const footer: AmountsTooltipRowProps = useMemo(() => {
    const { rolloverType } = amounts;

    if (rolloverType !== BudgetRolloverPeriodType.NonMonthly) {
      return {
        title: 'Remaining',
        value: getAmountForType(amounts, 'remaining') ?? 0,
        type: 'income' as const,
        emphasis: true,
      };
    }

    return {
      component: <NonMonthlyFooter {...amounts} />,
    };
  }, [amounts]);

  return (
    <AmountsTooltip
      title={title}
      rows={[
        ...(warningText
          ? [
              {
                component: <Warning>{warningText}</Warning>,
              },
            ]
          : []),
        ...(RA.isNotNil(amounts.rolloverType) || !!amounts.rollover
          ? [
              ...(RA.isNotNil(amounts.rolloverStartingBalance) &&
              RA.isPositive(amounts.rolloverStartingBalance)
                ? [
                    {
                      title: 'Starting balance',
                      value: amounts.rolloverStartingBalance ?? 0,
                      type: 'income' as const,
                      emphasis: true,
                    },
                  ]
                : []),
              {
                title: 'Rollover from last month',
                value: amounts.rollover ?? 0,
                type: 'income' as const,
                emphasis: true,
              },
            ]
          : []),
        { title: 'Budget', value: getAmountForType(amounts, 'budgeted') },
        { title: 'Actual', value: getAmountForType(amounts, 'actual') },
        footer,
      ]}
    />
  );
};

const NonMonthlyFooter = ({
  cumulativeActualAmount,
  rollover,
  remaining,
  budgeted,
  rolloverTargetAmount,
}: PlanAmounts) => {
  if (isNil(cumulativeActualAmount) || isNil(rollover) || isNil(remaining)) {
    return null;
  }

  const rolloverSpentPercentage = rolloverTargetAmount
    ? cumulativeActualAmount / rolloverTargetAmount
    : cumulativeActualAmount / getBudgetedAmountWithRollover(budgeted, rollover);
  const max = rolloverTargetAmount ?? getBudgetedAmountWithRollover(budgeted, rollover);

  return (
    <FlexContainer gap="xsmall" full column>
      <FlexContainer justifyBetween alignCenter>
        <Text>Remaining</Text>
        <Text color={remaining > 0 ? 'greenText' : 'redText'}>
          {formatCurrencyNoCents(remaining)}
        </Text>
      </FlexContainer>
      <ProgressBar
        value={rolloverSpentPercentage > 1 ? max : cumulativeActualAmount}
        color={rolloverSpentPercentage > 1 ? 'red' : 'green'}
        max={max}
        backgroundColor="grayDark"
        rightBorderColor="black"
        roundAppearance
      />
      <FlexContainer justifyBetween alignCenter>
        <Text weight="book" size="xsmall">
          {formatPercentNoDecimals(rolloverSpentPercentage)} of rollover spent
        </Text>
        {isNotNil(rolloverTargetAmount) && (
          <Text weight="book" size="xsmall">
            {formatCurrencyNoCents(rolloverTargetAmount)} target
          </Text>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};
export default PlanAmountsTooltip;
