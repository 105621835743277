import React, { useMemo, useState } from 'react';

import TabsContext from 'common/components/tabs/TabsContext';

type Props = {
  initialIndex?: number;
  children?: React.ReactNode;

  /** If these props are provided, this will become a "controlled" component
   * and will no longer manage its own state.
   */
  index?: number;
  onChangeIndex?: (index: number) => void;
};

/*
  This component is used for showing a number of different tabs and only rendering the active one.
  You have freedom to style the tabs however you want. Use HorizontalTabContainer for the default side-by-side style.

  <Tabs>
    <HorizontalTabContainer>
      <Tab index={0}>Settings</Tab>
      <Tab index={1}>Other</Tab>
    </HorizontalTabContainer>

    <TabPanel index={0}>
      <div>You are on the Settings tab!</div>
    </TabPanel>
    <TabPanel index={1}>
      <div>You are on the Other tab!</div>
    </TabPanel>
  </Tabs>
*/
const Tabs = ({ initialIndex = 0, children, index, onChangeIndex }: Props) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex);

  const context = useMemo(
    () => ({
      activeIndex: index ?? activeIndex,
      setActiveIndex: onChangeIndex ?? setActiveIndex,
    }),
    [activeIndex, setActiveIndex, index, onChangeIndex],
  );

  // @ts-ignore [REACT-NATIVE-UPGRADE] TS error goes away when we upgrade to React 18 in common
  return <TabsContext.Provider value={context}>{children}</TabsContext.Provider>;
};

export default Tabs;
