import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

const Root = styled(FlexContainer).attrs({ center: true })<{
  $size: number;
}>`
  border-radius: ${({ theme }) => theme.radius.medium};
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  background-color: ${({ theme }) => theme.color.text};
`;

const IconWrapper = styled.span`
  color: ${({ theme }) => theme.color.white};
`;

type Props = {
  padding?: number;
  size?: number;
  className?: string;
};

const InstitutionIcon = ({ size = 56, padding = 8, className }: Props) => (
  <Root className={className} $size={size}>
    <IconWrapper>
      <Icon name="institution" size={size - padding * 2} />
    </IconWrapper>
  </Root>
);

export default InstitutionIcon;
