import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';

import { color, spacing } from 'common/lib/theme/dynamic';
import useToast from 'lib/hooks/useToast';
import { copyToClipboard } from 'utils/clipboard';

const StyledIcon = styled(Icon)`
  color: ${color.textLight};
  cursor: pointer;
  margin-left: ${spacing.xsmall};
  user-select: none;

  :hover {
    color: ${color.text};
  }
`;

type Props = {
  originalStatement: string;
  onExplainTransaction: () => void;
};

const TransactionDrawerOriginalStatement = ({ originalStatement, onExplainTransaction }: Props) => {
  const { openToast } = useToast();

  const copy = () => {
    copyToClipboard(originalStatement);
    openToast({ title: `"${originalStatement}" copied to clipboard` });
  };

  return (
    <>
      <FlexContainer full justifyBetween marginTop="xlarge">
        <Text color="textLight" weight="bold" size="xsmall" transform="uppercase">
          Original Statement
        </Text>
      </FlexContainer>
      <FlexContainer full justifyBetween marginTop="small">
        <span>
          {originalStatement}{' '}
          <Tooltip content="Copy">
            <StyledIcon name="copy" onClick={copy} />
          </Tooltip>
          <Tooltip content="Explain">
            <StyledIcon name="sparkle" onClick={onExplainTransaction} />
          </Tooltip>{' '}
        </span>
      </FlexContainer>
    </>
  );
};

export default TransactionDrawerOriginalStatement;
