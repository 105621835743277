import styled from 'styled-components';

const DividerLine = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${({ theme }) => theme.color.gray};
  align-self: center;
`;

export default DividerLine;
