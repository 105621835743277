import type { DateTime } from 'luxon';
import React, { useState } from 'react';
import styled from 'styled-components';

import CreateCategoryGroupModal from 'components/categories/CreateCategoryGroupModal';
import CreateCategoryModal from 'components/categories/CreateCategoryModal';
import EditCategoryGroupModal from 'components/categories/EditCategoryGroupModal';
import EditCategoryModal from 'components/categories/EditCategoryModal';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Modal from 'components/lib/ui/Modal';
import Text from 'components/lib/ui/Text';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import type { GroupDataWithBudgetedValue } from 'components/plan/onboarding/OnboardingBudgetCard';
import OnboardingBudgetCard from 'components/plan/onboarding/OnboardingBudgetCard';

import { useCategoryActions } from 'lib/hooks/category/useCategoryActions';
import useDeleteCategoryGroup from 'lib/hooks/category/useDeleteCategoryGroup';
import type { PlanSectionType } from 'lib/plan';

import type { CreateCategoryInput, CategoryGroupType } from 'common/generated/graphql';

const DividerWrapper = styled(FlexContainer).attrs({ alignCenter: true, full: true })``;

const TopDividerWrapper = styled(DividerWrapper)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const DividerText = styled(Text).attrs({ size: 'xsmall', color: 'textLight', weight: 'bold' })`
  text-wrap: nowrap;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin-left: ${({ theme }) => theme.spacing.large};
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.grayFocus};
  position: relative;
`;

const BottomDividerWrapper = styled(DividerWrapper)`
  margin: ${({ theme }) => theme.spacing.default} 0;
`;

const StyledButton = styled(DefaultButton).attrs({ size: 'small' })`
  text-wrap: nowrap;
  margin-right: ${({ theme }) => theme.spacing.large};
`;

type Props = {
  data: GroupDataWithBudgetedValue[];
  groupType: CategoryGroupType;
  thisMonth: DateTime;
  footer?: React.ReactNode;
  initiallyOpen?: boolean;
  updateBudgetedValue: (
    itemId: string,
    date: DateTime,
    sectionType: PlanSectionType,
    isCategoryGroupRow: boolean,
  ) => (amount: number, applyToFuture: boolean) => void;
};

const OnboardingBudgetList = ({
  data,
  groupType,
  thisMonth,
  footer,
  updateBudgetedValue,
  initiallyOpen,
}: Props) => {
  const [creatingGroupType, setCreatingGroupType] = useState<CategoryGroupType>();
  const [creatingCategory, setCreatingCategory] = useState<Partial<CreateCategoryInput>>();
  const [editingCategoryGroupId, setEditingCategoryGroupId] = useState<string>();

  const {
    editingCategoryId,
    setEditingCategoryId,
    deleteCategoryWithConfirmation,
    confirmingDeleteCategoryId,
    updateHideFromBudget,
    DeleteCategoryConfirmationModal,
  } = useCategoryActions();

  const [
    DeleteCategoryGroupConfirmationModal,
    { deleteOrToggleConfirmation, confirmingDeleteGroup },
  ] = useDeleteCategoryGroup();

  return (
    <div>
      <TopDividerWrapper>
        <Line />
        <DividerText size="small">monthly budget</DividerText>
      </TopDividerWrapper>
      <FlexContainer column gap="default">
        {data?.map((group) => (
          <OnboardingBudgetCard
            key={group.id}
            group={group}
            onAddCategory={(categoryData) => setCreatingCategory(categoryData)}
            onEditGroup={() => setEditingCategoryGroupId(group.id)}
            onDeleteGroup={() => deleteOrToggleConfirmation({ ...group, categories: group.rows })}
            onEditCategory={(id) => setEditingCategoryId(id)}
            onDeleteCategory={(id) => deleteCategoryWithConfirmation(id)}
            onHideCategoryFromBudget={(id) => updateHideFromBudget(id, true)}
            updateBudgetedValue={updateBudgetedValue}
            thisMonth={thisMonth}
            initiallyOpen={initiallyOpen}
          />
        ))}
      </FlexContainer>
      {!!footer && (
        <>
          <BottomDividerWrapper>
            <StyledButton onClick={() => setCreatingGroupType(groupType)}>
              <ButtonIcon name="plus-circle" size={14} />
              <span>Add group</span>
            </StyledButton>
            <Line />
          </BottomDividerWrapper>
          {footer}
        </>
      )}
      {!!creatingGroupType && (
        <Modal onClose={() => setCreatingGroupType(undefined)}>
          {({ close }) => (
            <CreateCategoryGroupModal categoryType={creatingGroupType} onDone={close} />
          )}
        </Modal>
      )}
      {!!creatingCategory && (
        <Modal onClose={() => setCreatingCategory(undefined)}>
          {({ close }) => (
            <CreateCategoryModal
              initialValues={creatingCategory}
              categoryGroupType={creatingCategory.type}
              onCreate={close}
            />
          )}
        </Modal>
      )}
      {!!editingCategoryGroupId && (
        <Modal onClose={() => setEditingCategoryGroupId(undefined)}>
          {({ close }) => (
            <EditCategoryGroupModal categoryGroupId={editingCategoryGroupId} onDone={close} />
          )}
        </Modal>
      )}
      {!!editingCategoryId && (
        <Modal onClose={() => setEditingCategoryId(undefined)}>
          {({ close }) => <EditCategoryModal categoryId={editingCategoryId} onDone={close} />}
        </Modal>
      )}
      {confirmingDeleteGroup && <DeleteCategoryGroupConfirmationModal />}
      {confirmingDeleteCategoryId && <DeleteCategoryConfirmationModal />}
    </div>
  );
};

export default OnboardingBudgetList;
