export const COINBASE_SCOPES = [
  'wallet:accounts:read',
  'wallet:transactions:read',
  'wallet:deposits:read',
  'wallet:withdrawals:read',
  'wallet:buys:read',
  'wallet:sells:read',
  'wallet:addresses:read',
];

export const COINBASE_IOS_MOBILE_REDIRECT_URI = 'com.monarchmoney.mobile://coinbase';
export const COINBASE_ANDROID_MOBILE_REDIRECT_URI = 'https://app.monarchmoney.com/oauth';

export const COINBASE_AUTH_URL = 'https://www.coinbase.com/oauth/authorize';
