import React, { useMemo } from 'react';
import styled from 'styled-components';

import GoalAccountsMapRow from 'components/goalsV2/GoalAccountsMapRow';
import GoalSelectAccountsModal from 'components/goalsV2/GoalSelectAccountsModal';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import Stack from 'components/utils/Stack';

import { useStackContext } from 'common/lib/contexts/StackContext';
import { filterOutArchivedAndSortByPriority } from 'common/lib/goalsV2/adapters';
import useQuery from 'common/lib/hooks/useQuery';
import { useModalContext } from 'lib/contexts/ModalContext';

import * as COPY from 'common/constants/copy';

import { gql } from 'common/generated/gql';
import type { GoalAccountsMapRowFields } from 'common/generated/graphQlTypes/GoalAccountsMapRowFields';

const GoalRow = styled(FlexContainer).attrs({ column: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  width: 100%;
`;

const GoalsMapAccountsModal = () => (
  <Stack initial={{ component: GoalsMapAccountsModalInitial, props: {} }} />
);

const GoalsMapAccountsModalInitial = () => {
  const { data } = useQuery(QUERY);
  const { close } = useModalContext();

  const { push, pop } = useStackContext();

  const goals = useMemo(
    () => filterOutArchivedAndSortByPriority(data?.goalsV2 ?? []) as GoalAccountsMapRowFields[],
    // type cast needed to avoid migrating all fragments and queries to new gql
    // can remove when that's done
    [data?.goalsV2],
  );

  return (
    <ModalCard title={COPY.GOALS.MAP_ACCOUNTS.TITLE} description={COPY.GOALS.MAP_ACCOUNTS.SUBTITLE}>
      <FlexContainer column>
        {goals.map((item, idx) => (
          <GoalRow key={idx}>
            <GoalAccountsMapRow
              data={item}
              onClickAddAccounts={() => {
                push(GoalSelectAccountsModal, {
                  goalId: item.id,
                  onClickBackButton: pop,
                  onClickDone: pop,
                });
              }}
            />
          </GoalRow>
        ))}
      </FlexContainer>

      <CardFooter>
        <PrimaryButton onClick={close}>Done</PrimaryButton>
      </CardFooter>
    </ModalCard>
  );
};

const QUERY = gql(`
  query Web_GoalsMapAccountsModal {
    goalsV2 {
      id
      archivedAt
     ...GoalAccountsMapRowFields
    }
  }
`);

export default GoalsMapAccountsModal;
