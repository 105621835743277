import React from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';
import OnboardingCard, { CardRow, Left, Right } from 'components/plan/onboarding/OnboardingCard';

import { spacing } from 'common/lib/theme/dynamic';

import routes from 'constants/routes';

import type { Web_BudgetOnboardingGetAccountsQuery } from 'common/generated/graphql';

const ACCOUNT_LOGO_SIZE_PX = 24;

const StyledAccountLogo = styled(AccountLogo)`
  margin-right: ${spacing.small};
`;

const TitleWrapper = styled(FlexContainer).attrs({ full: true, alignCenter: true })`
  padding: ${spacing.default};
`;

const EmptyState = styled(FlexContainer).attrs({ center: true, column: true })`
  padding: ${spacing.xxxlarge};

  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

type Account =
  Web_BudgetOnboardingGetAccountsQuery['accountTypeSummaries'][number]['accounts'][number];

type RowProps = {
  account: Account;
};

const Row = ({ account }: RowProps) => (
  <CardRow $withTopBorder>
    <Left $rightShadow>
      <Link
        href={routes.accounts.accountDetails({ id: account.id })}
        target="_blank"
        rel="noopener noreferrer"
        stealthy
      >
        <TitleWrapper>
          <StyledAccountLogo
            logoUrl={account?.logoUrl}
            icon={account?.icon}
            size={ACCOUNT_LOGO_SIZE_PX}
          />
          {account.displayName}
        </TitleWrapper>
      </Link>
    </Left>
    <Right>
      <CashFlowCurrency value={account.displayBalance || 0} round type="income" />
    </Right>
  </CardRow>
);

type Props = {
  title: string;
  totalBalance: number;
  accounts: Account[];
  emptyText: string;
};

const OnboardingAccountGroup = ({ title, totalBalance, accounts, emptyText }: Props) => (
  <OnboardingCard
    title={title}
    rightNode={
      totalBalance !== 0 ? (
        <CashFlowCurrency round value={totalBalance} bold type="income" />
      ) : undefined
    }
  >
    {accounts.length ? (
      accounts.map((account: Account) => <Row key={account.id} account={account} />)
    ) : (
      <EmptyState>
        <Text color="textLight">{emptyText}</Text>
      </EmptyState>
    )}
  </OnboardingCard>
);

export default React.memo(OnboardingAccountGroup);
