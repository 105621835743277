import { yupToFormErrors } from 'formik';
import * as R from 'ramda';
import * as Yup from 'yup';

import type { DisplayBudget, DisplayGroup, DisplayItem } from 'common/lib/budget/Adapters';

import type { CategoryGroupType, MoveMoneyMutationInput } from 'common/generated/graphql';

/** Minimum info needed to do calculations */
export type CalculableBudget = {
  details: {
    byCategory: DisplayItem[];
    byCategoryGroups: DisplayGroup[];
  };
};

export type FormValues = Pick<MoveMoneyMutationInput, 'fromCategoryId' | 'toCategoryId' | 'amount'>;

type DisplayError = {
  title: string;
  message: string;
};

export const getBudgetAmountsForCategoryOrGroup = (
  budget: CalculableBudget,
  categoryOrGroupId: FormValues['fromCategoryId'],
  isGroup?: boolean,
) => {
  const isMatchingId = R.propEq('id', categoryOrGroupId);

  return isGroup
    ? budget.details.byCategoryGroups.find(isMatchingId)?.budgetedAmounts
    : budget.details.byCategory.find(isMatchingId)?.amounts;
};

/*
  IF BADGE IS RED OR GRAY:

  - The category clicked on should be the one selected in the “To” field
  - The “From” field should default to the category with the most budget remaining

  IF BADGE IS RED:
  - Amount defaults to the amount the clicked on category is over budget

  IF BADGE IS GREEN:

  - IF CATEGORY CLICKED HAS THE LOWEST AMOUNT OF BUDGET REMAINING

  - it should be selected for the TO field. The category with the most budget should fill the FROM field
  - Amount should be blank since they still have budget left we have no idea how much budget they want to move

  IF CATEGORY DOES NOT HAVE THE LOWEST AMOUNT OF BUDGET REMAINING

  - We assume they want to move some of this budget to another category, so the clicked category should fill the “From” field

  - The “To” field should default to the category with the lowest budget remaining
*/
export const getInitialFormValues = (
  budget: CalculableBudget,
  initialId: string,
  categoryGroupType: CategoryGroupType,
  isGroup?: boolean,
) => {
  const { available } = getBudgetAmountsForCategoryOrGroup(budget, initialId, isGroup) ?? {};
  const availableRound =
    (available ?? 0) > 0 ? Math.floor(available ?? 0) : Math.round(available ?? 0);

  const mostAvailable = findCategoryWithMaxMinAvailableAmount(budget, categoryGroupType, true);
  const leastAvailable = findCategoryWithMaxMinAvailableAmount(budget, categoryGroupType, false);

  // Don't ever set "from" and "to" to same category id
  const dedupCategory = (id?: string) => (id === initialId ? undefined : id);

  if (availableRound < 0) {
    // Badge is red
    return {
      toCategoryId: initialId,
      fromCategoryId: dedupCategory(mostAvailable?.id),
      amount: Math.abs(availableRound),
    };
  } else if (availableRound > 0) {
    // Badge is green
    if (initialId === leastAvailable?.id) {
      // Category clicked has the lowest amount of budget remaining
      return {
        toCategoryId: initialId,
        fromCategoryId: dedupCategory(mostAvailable?.id),
        amount: undefined,
      };
    } else {
      // Category clicked does not have the lowest amount of budget remaining
      return {
        fromCategoryId: initialId,
        toCategoryId: dedupCategory(leastAvailable?.id),
        amount: Math.min(
          availableRound,
          getOverBudgetAmount(leastAvailable?.amounts?.available ?? 0),
        ),
      };
    }
  } else {
    // Badge is gray
    return {
      toCategoryId: initialId,
      fromCategoryId: dedupCategory(mostAvailable?.id),
      amount: undefined,
    };
  }
};

export const validateValues = (
  { fromCategoryId, toCategoryId, amount }: Partial<FormValues>,
  budget: CalculableBudget,
): [FormValues | undefined, DisplayError | undefined] => {
  if (!fromCategoryId) {
    return [
      undefined,
      {
        title: 'Invalid Selection',
        message: 'Please select a budget to transfer from.',
      },
    ];
  }
  if (!toCategoryId) {
    return [
      undefined,
      {
        title: 'Invalid Selection',
        message: 'Please select a budget to transfer to.',
      },
    ];
  }
  if (!amount || amount < 0) {
    return [
      undefined,
      {
        title: 'Invalid Amount',
        message: 'Please enter an amount greater than 0.',
      },
    ];
  }
  if (fromCategoryId === toCategoryId) {
    return [
      undefined,
      {
        title: 'Invalid Selection',
        message: 'From budget must be different than to budget.',
      },
    ];
  }

  const isFromGroup = budget?.details.byCategoryGroups.find(({ id }) => id === fromCategoryId);

  const amountForCategory =
    getBudgetAmountsForCategoryOrGroup(budget, fromCategoryId, !!isFromGroup)?.available ?? 0;
  const fromBudgetRemaining =
    amountForCategory > 0 ? Math.floor(amountForCategory) : Math.round(amountForCategory);

  if (amount > fromBudgetRemaining) {
    return [
      undefined,
      {
        title: 'Invalid Amount',
        message: `The amount you entered ($${amount}) is greater than the $${fromBudgetRemaining} remaining for this budget.`,
      },
    ];
  }
  return [
    {
      fromCategoryId,
      toCategoryId,
      amount,
    },
    undefined,
  ];
};

export const validateMoveMoneyForm = (values: FormValues, budget: CalculableBudget) => {
  const isGroup = !!budget.details.byCategoryGroups.find(({ id }) => id === values.fromCategoryId);

  const { available: fromCategoryAvailable } = values.fromCategoryId
    ? getBudgetAmountsForCategoryOrGroup(budget, values.fromCategoryId, isGroup) ?? { available: 0 }
    : { available: 0 };
  const available = fromCategoryAvailable ?? 0;

  const schema = Yup.object().shape({
    fromCategoryId: Yup.string().required('Please select a category.'),
    toCategoryId: Yup.string().required('Please select a category.'),
    amount: Yup.number()
      .required('Please enter an amount.')
      .moreThan(0, 'Please enter an amount greater than $0.')
      .lessThan(
        available + 1,
        available > 0
          ? `Please ensure the amount is lower than or equal to $${available}.`
          : 'Please ensure the "From" category has a balance greater than $0.',
      ),
  });

  try {
    schema.validateSync(values);
  } catch (error) {
    return yupToFormErrors(error);
  }

  return {};
};

export const getAvailableAmount = (
  budget: CalculableBudget | undefined | null,
  fromCategoryType: CategoryGroupType,
  fromCategoryId: string | undefined,
  toCategoryId: string | undefined,
) => {
  if (!budget || !fromCategoryId || !toCategoryId) {
    return 0;
  }

  const isFromGroup = !!budget.details.byCategoryGroups.find(({ id }) => id === fromCategoryId);

  const isToGroup = !!budget.details.byCategoryGroups.find(({ id }) => id === toCategoryId);

  const { available: fromAvailable } =
    getBudgetAmountsForCategoryOrGroup(budget, fromCategoryId, isFromGroup) ?? {};
  const { amount: toAmount } =
    getInitialFormValues(budget, toCategoryId, fromCategoryType, isToGroup) ?? {};

  const amount = Math.min(fromAvailable ?? 0, toAmount ?? 0);

  // Don't allow negative numbers
  return Math.floor(Math.max(0, amount));
};

/** Given an available amount, returns how much that available amount is over budget. Returns 0 if not over budget. */
const getOverBudgetAmount = (available: number) => Math.floor(Math.max(0, available * -1));

const findCategoryWithMaxMinAvailableAmount = (
  budget: CalculableBudget,
  categoryType: CategoryGroupType,
  maximum: boolean,
) => {
  const availableOptions = [
    ...budget.details.byCategory
      .filter(
        ({ groupType, groupLevelBudgetingEnabled }) =>
          groupType === categoryType && !groupLevelBudgetingEnabled,
      )
      .map(({ id, amounts }) => ({
        id,
        amounts,
      })),
    ...budget.details.byCategoryGroups
      .filter(
        ({ type, groupLevelBudgetingEnabled }) =>
          type === categoryType && groupLevelBudgetingEnabled,
      )
      .map(({ id, budgetedAmounts }) => ({
        id,
        amounts: budgetedAmounts,
      })),
  ];

  return availableOptions.reduce((extreme, current) => {
    if (maximum && (current.amounts?.available ?? 0) > (extreme.amounts?.available ?? 0)) {
      return current;
    } else if (!maximum && (current.amounts?.available ?? 0) < (extreme.amounts?.available ?? 0)) {
      return current;
    }
    return extreme;
  }, availableOptions[0]);
};

export const convertDisplayBudgetToCalculableBudget = (data: DisplayBudget): CalculableBudget => {
  const categories = R.flatten(
    data.displayTypes.map((type) => type.displayGroups.map((group) => group.budgetedCategories)),
  );

  const categoryGroups = R.flatten(data.displayTypes.map((type) => type.displayGroups));

  return {
    details: {
      byCategory: categories,
      byCategoryGroups: categoryGroups,
    },
  };
};
