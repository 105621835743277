import React from 'react';
import styled from 'styled-components';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import type { Props as CurrencyInputProps } from 'components/lib/form/CurrencyInput';
import CurrencyInput from 'components/lib/form/CurrencyInput';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';

import fieldErrorBorderMixin from 'lib/styles/fieldErrorBorderMixin';

const Currency = styled(CurrencyInput)<{ error?: boolean }>`
  ${fieldErrorBorderMixin}
`;

const CurrencyField: React.ForwardRefRenderFunction<
  HTMLElement,
  AbstractFieldProps<CurrencyInputProps> & { InputComponent?: typeof Currency }
> = ({ className, InputComponent = Currency, onBlur, ...props }, ref) => (
  <AbstractField {...props} {...sensitiveClassProps} className={className}>
    {({ id, value, handleBlur, displayErrors, setFieldValue }) => (
      <InputComponent
        {...props}
        id={id}
        value={parseFloat(value)} // for when the value is dynamically filled with a string
        onBlur={(e) => {
          handleBlur(e);
          onBlur?.(e);
        }}
        onChange={(value) => {
          setFieldValue(props.name, value ?? undefined);
        }}
        error={displayErrors.length > 0}
        ref={ref}
      />
    )}
  </AbstractField>
);

export default React.forwardRef(CurrencyField);
