import { gql } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import MonthlyBudgetGoalsModal from 'components/goalsV2/MonthlyBudgetGoalsModal';
import PreTaxBudgetGoalsModal from 'components/goalsV2/PreTaxBudgetGoalsModal';
import RankGoalsModal from 'components/goalsV2/RankGoalsModal';
import TargetAmountsModal from 'components/goalsV2/TargetAmountsModal';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';

import { formatCurrencyNoCents } from 'common/utils/Currency';
import useModal from 'lib/hooks/useModal';

import { GoalObjective, GoalType } from 'common/constants/goals';

import type { WebGoalDetailsCardFieldsFragment } from 'common/generated/graphql';

const CHEVRON_SIZE_PX = 16;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: ${({ theme }) => theme.spacing.xlarge};
  align-self: center;
`;

const Subtitle = styled(Text)`
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

const DetailRow = styled(
  ({
    title,
    subtitle,
    onClick,
    className,
  }: {
    title: string;
    subtitle: string;
    onClick: () => void;
    className?: string;
  }) => (
    <FlexContainer alignCenter justifyBetween className={className} onClick={onClick}>
      <FlexContainer column>
        <Text>{title}</Text>
        <Subtitle>{subtitle}</Subtitle>
      </FlexContainer>
      <Icon name="chevron-right" size={CHEVRON_SIZE_PX} />
    </FlexContainer>
  ),
)`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  cursor: pointer;
  transition: all ${({ theme }) => theme.transition.default};

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }

  :last-child {
    border-bottom-left-radius: ${({ theme }) => theme.radius.medium};
    border-bottom-right-radius: ${({ theme }) => theme.radius.medium};
  }

  :hover {
    background: ${({ theme }) => theme.color.grayBackground};
  }
`;

type Props = {
  goal: WebGoalDetailsCardFieldsFragment | null | undefined;
  isLoading: boolean;
};

const GoalDetailsCard = ({ goal, isLoading }: Props) => {
  const {
    priority,
    plannedMonthlyContribution,
    plannedMonthlyPretaxContribution,
    startingAmount,
    targetAmount,
    type = '',
    objective,
  } = goal ?? {};

  const isDebtGoal = type === GoalType.Debt;
  const isRetirementGoal = objective === GoalObjective.Retirement;

  const [RankModal, { open: openRankGoalsModal }] = useModal();
  const [TargetsModal, { open: openTargetsModal }] = useModal();
  const [MonthlyBudgetsModal, { open: openMonthlyBudgetsModal }] = useModal();
  const [PreTaxBudgetsModal, { open: openPreTaxBudgetsModal }] = useModal();
  const [StartingBalanceModal, { open: openStartingBalanceModal }] = useModal();

  return (
    <>
      <Card title="Details">
        {isLoading ? (
          <StyledLoadingSpinner />
        ) : (
          <>
            <DetailRow
              title="Goal priority"
              subtitle={`#${priority}`}
              onClick={openRankGoalsModal}
            />
            {isRetirementGoal && (
              <DetailRow
                title="Pre-paycheck contributions"
                subtitle={
                  plannedMonthlyPretaxContribution === null
                    ? 'Set your budget'
                    : `${formatCurrencyNoCents(plannedMonthlyPretaxContribution)}/mo`
                }
                onClick={openPreTaxBudgetsModal}
              />
            )}
            <DetailRow
              title={isRetirementGoal ? 'Post-paycheck contributions' : 'Budget contributions'}
              subtitle={
                plannedMonthlyContribution === null
                  ? 'Set your budget'
                  : `${formatCurrencyNoCents(plannedMonthlyContribution)}/mo`
              }
              onClick={openMonthlyBudgetsModal}
            />
            {isDebtGoal ? (
              <DetailRow
                title="Starting balance"
                subtitle={formatCurrencyNoCents((startingAmount ?? 0) * -1)}
                onClick={openStartingBalanceModal}
              />
            ) : (
              <DetailRow
                title="Target amount"
                subtitle={
                  targetAmount === null ? 'Set your target' : formatCurrencyNoCents(targetAmount)
                }
                onClick={openTargetsModal}
              />
            )}
          </>
        )}
      </Card>
      <RankModal>
        <RankGoalsModal />
      </RankModal>
      <TargetsModal>
        <TargetAmountsModal restrictToGoalId={goal?.id} />
      </TargetsModal>
      <MonthlyBudgetsModal>
        <MonthlyBudgetGoalsModal />
      </MonthlyBudgetsModal>
      <PreTaxBudgetsModal>
        <PreTaxBudgetGoalsModal />
      </PreTaxBudgetsModal>
      <StartingBalanceModal>
        <TargetAmountsModal restrictToGoalId={goal?.id} useStartingAmount />
      </StartingBalanceModal>
    </>
  );
};

GoalDetailsCard.fragments = {
  WebGoalDetailsCardFields: gql`
    fragment WebGoalDetailsCardFields on GoalV2 {
      id
      priority
      plannedMonthlyContribution
      plannedMonthlyPretaxContribution
      targetAmount
      startingAmount
      type
      objective
    }
  `,
};

export default GoalDetailsCard;
