import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import InstitutionLogo from 'components/institutions/InstitutionLogo';
import CardFooter from 'components/lib/ui/CardFooter';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import StatusIndicator from 'components/lib/ui/StatusIndicator';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import ManualLink from 'components/lib/ui/link/ManualLink';

import { GET_INSTITUTION } from 'common/lib/graphQl/accounts';
import useTheme from 'lib/hooks/useTheme';

import { getColorForStatus, TITLE_FOR_STATUS } from 'common/constants/institutions';
import { DEFAULT_ERROR_MESSAGE } from 'constants/copy';
import { externalUrls } from 'constants/routes';

import type {
  GetInstitution,
  GetInstitutionVariables,
} from 'common/generated/graphQlTypes/GetInstitution';
import { InstitutionStatus as InstitutionStatusType } from 'common/types/institutions';

const Logo = styled(InstitutionLogo)`
  width: 16px;
  height: 16px;
  margin: ${({ theme }) => theme.spacing.default};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const Root = styled(Flex)`
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.xlarge};
  margin-top: 0;
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  padding: ${({ theme }) => theme.spacing.xxxlarge};
`;

const ErrorBox = styled(Flex)`
  background-color: ${({ theme }) => theme.color.grayBackground};
  justify-content: center;
  border-radius: ${({ theme }) => theme.radius.small};
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

const WarningMessage = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-align: left;
  padding: 10px ${({ theme }) => theme.spacing.large};
  border-radius: ${({ theme }) => theme.radius.small};
  background: ${({ theme }) => theme.color.white};
  overflow: hidden;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 8px;
    background: ${({ theme }) => theme.color.yellow};
  }
`;

const ErrorCode = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.textLight};
  margin: ${({ theme }) => theme.spacing.small};
  font-family:
    Meslo LG L,
    sans-serif;
`;

const Header = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Body = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const InstitutionStatusHeader = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  height: ${({ theme }) => theme.spacing.xxxlarge};
`;

const InstitutionStatusBody = styled(Flex)`
  margin: ${({ theme }) => theme.spacing.default};
`;

const InstitutionStatusBox = styled(Flex)`
  background-color: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.small};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const ConnectionStatus = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const StatusRow = styled(
  ({ title, status, ...props }: { title: string; status: InstitutionStatusType }) => {
    const theme = useTheme();

    return (
      <FlexContainer alignCenter justifyBetween {...props}>
        <div>{title}</div>
        <StatusIndicator color={getColorForStatus(status, theme)}>
          {TITLE_FOR_STATUS[status]}
        </StatusIndicator>
      </FlexContainer>
    );
  },
)`
  align-self: stretch;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

type InstitutionStatusProps = {
  institutionId: string;
};

const InstitutionStatus = ({ institutionId }: InstitutionStatusProps) => {
  const { data, loading } = useQuery<GetInstitution, GetInstitutionVariables>(GET_INSTITUTION, {
    variables: { plaidId: institutionId },
  });
  const isLoading = R.isNil(data) && loading;
  const {
    name,
    logo,
    hasIssuesReported,
    hasIssuesReportedMessage,
    balanceStatus,
    transactionsStatus,
  } = data?.institution ?? {};

  return (
    <>
      <Header>Institution Status</Header>
      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ) : (
        <InstitutionStatusBox column>
          <InstitutionStatusHeader justifyBetween alignCenter>
            <Flex alignCenter>
              {!!logo && <Logo logo={logo} />}
              <Header>{name}</Header>
            </Flex>
            <ConnectionStatus size="xxsmall" color="textLight">
              CONNECTION STATUS
            </ConnectionStatus>
          </InstitutionStatusHeader>
          <InstitutionStatusBody column>
            <StatusRow
              title="Balances"
              status={(balanceStatus || InstitutionStatusType.Healthy) as InstitutionStatusType}
            />
            <StatusRow
              title="Transactions"
              status={
                (transactionsStatus || InstitutionStatusType.Healthy) as InstitutionStatusType
              }
            />
            {hasIssuesReported && (
              <WarningMessage>
                {hasIssuesReportedMessage ||
                  "We're looking into issues reported for this institution in the past 24 hours."}
              </WarningMessage>
            )}
          </InstitutionStatusBody>
        </InstitutionStatusBox>
      )}
    </>
  );
};

type Props = {
  errorMessage?: string;
  plaidInstitutionId?: string;
  onRetry?: () => void;
  onAddManualAccountClick?: () => void;
  onClose?: () => void;
};

const LinkInstitutionFailedModal = ({
  errorMessage,
  plaidInstitutionId,
  onRetry,
  onAddManualAccountClick, // only shows the add manual account button if this prop is passed in
  onClose, // only shows the close modal button if this prop is passed in
}: Props) => (
  <ModalCard title="We couldn’t connect to your financial institution">
    <Root>
      <Body>
        It looks like our data partner was not able to connect to your financial institution. We’re
        sorry for this, we’ve logged this error and will investigate it.
      </Body>
      <Body>
        View more details about the error and financial institution status from our data partner
        below. For more details on why this occurs, please see our{' '}
        <ManualLink href={externalUrls.knownConnectionIssues} target="_blank">
          knowledge base article
        </ManualLink>
        .
      </Body>
      <Header>Error Message</Header>
      <ErrorBox>
        <ErrorCode>{errorMessage || DEFAULT_ERROR_MESSAGE}</ErrorCode>
      </ErrorBox>
      {!!plaidInstitutionId && <InstitutionStatus institutionId={plaidInstitutionId} />}
    </Root>
    <CardFooter>
      {onClose && <DefaultButton onClick={onClose}>Close</DefaultButton>}
      {onAddManualAccountClick && (
        <DefaultButton onClick={onAddManualAccountClick}>Add manual account</DefaultButton>
      )}
      {onRetry && <PrimaryButton onClick={onRetry}>Retry syncing account</PrimaryButton>}
    </CardFooter>
  </ModalCard>
);

export default LinkInstitutionFailedModal;
