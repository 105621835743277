import styled from 'styled-components';

export type Align = 'left' | 'center' | 'right';

export type CellProps = {
  align?: Align;
};

export const Cell = styled.div<CellProps>`
  position: relative;
  text-align: ${({ align = 'left' }) => align};
  padding: ${({ theme }) => theme.spacing.default};
`;

export const Header = styled(Cell)`
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const Row = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing.xsmall};

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  }
`;

export const RowGroup = styled.div``;

const Table = styled.div``;

export default Table;
