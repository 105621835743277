import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CheckAnimation from 'components/lib/ui/CheckAnimation';
import {
  ImportPageSubtitle,
  ImportPageTitle,
  NextButton,
} from 'components/routes/accounts/ImportMintBalanceHistory';

import { IMPORT_BALANCE_HISTORY } from 'constants/copy';
import routes from 'constants/routes';

const CHECKMARK_ANIMATION_SIZE_PX = 120;

const StyledCheckAnimation = styled(CheckAnimation).attrs({ size: CHECKMARK_ANIMATION_SIZE_PX })`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

type Props = {
  totalAccounts: number;
};

const ImportBalanceHistorySuccess = ({ totalAccounts }: Props) => {
  const history = useHistory();

  return (
    <>
      <StyledCheckAnimation />

      <ImportPageTitle>
        {IMPORT_BALANCE_HISTORY.STEPS.IMPORT_SUCCESS.TITLE(totalAccounts)}
      </ImportPageTitle>
      <ImportPageSubtitle>
        {IMPORT_BALANCE_HISTORY.STEPS.IMPORT_SUCCESS.SUBTITLE}
      </ImportPageSubtitle>

      <NextButton
        onClick={() => {
          history.push(routes.accounts());
        }}
      >
        {IMPORT_BALANCE_HISTORY.STEPS.IMPORT_SUCCESS.BUTTON}
      </NextButton>
    </>
  );
};

export default ImportBalanceHistorySuccess;
