import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import ContentLoader from 'components/lib/ui/ContentLoader';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Skeleton, { SkeletonCircle } from 'components/lib/ui/Skeleton';

const HeaderRoot = styled.div`
  height: 57px;
  width: 100%;
`;

const Root = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.medium};
  overflow: hidden;
`;

const TransactionRoot = styled(FlexContainer).attrs({
  gap: 'gutter',
  alignCenter: true,
  justifyBetween: true,
})`
  height: 57px;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.gutter};

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const Column = styled(FlexContainer).attrs({ alignCenter: true, gap: 'small' })`
  flex: 2;

  &:first-child {
    flex: 1;
  }

  &:nth-child(2) {
    flex: 1;
  }

  &:nth-child(3) {
    flex: 1;
  }

  &:last-child {
    flex: 0.5;
  }
`;

const TransactionRowsHeader = styled.div`
  height: 36px;
  width: 100%;

  background: ${({ theme }) => theme.color.grayBackground};
`;

const HeaderLoading = () => (
  <HeaderRoot>
    <ContentLoader>
      <rect x="24" y="17" rx="5" ry="5" width="15%" height="19" />
      <rect x="calc(89% - 24px)" y="17" rx="5" ry="5" width="11%" height="19" />
    </ContentLoader>
  </HeaderRoot>
);

const TransactionLoading = () => (
  <TransactionRoot>
    <Column>
      <SkeletonCircle />
      <Skeleton />
    </Column>
    <Column>
      <Skeleton />
    </Column>
    <Column>
      <SkeletonCircle />
      <Skeleton />
    </Column>
    <Column>
      <Skeleton />
    </Column>
  </TransactionRoot>
);

const TransactionsGroupLoading = ({ rowsCount }: { rowsCount: number }) => (
  <>
    <TransactionRowsHeader>
      <ContentLoader>
        <rect x="30" y="12" rx="5" ry="5" width="17%" height="16" />
        <rect x="calc(88% - 24px)" y="12" rx="5" ry="5" width="12%" height="16" />
      </ContentLoader>
    </TransactionRowsHeader>
    {R.range(0, rowsCount).map((i) => (
      <TransactionLoading key={i} />
    ))}
  </>
);

export const TransactionsRowsLoading = () => (
  <>
    <TransactionsGroupLoading rowsCount={3} />
    <TransactionsGroupLoading rowsCount={3} />
    <TransactionsGroupLoading rowsCount={6} />
  </>
);

const TransactionsListLoading = () => (
  <Root>
    <HeaderLoading />
    <TransactionsRowsLoading />
  </Root>
);

export default TransactionsListLoading;
