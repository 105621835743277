import { gql } from '@apollo/client';
import { Duration } from 'luxon';

import useQueryWithCacheExpiration from 'common/lib/hooks/useQueryWithCacheExpiration';

import { SubscriptionEntitlement } from 'common/constants/premium';

import type { GetEntitlements } from 'common/generated/graphQlTypes/GetEntitlements';

// We default to Premium while the query is loading to ensure we don't accidentally
// restrict the user when they do in fact have premium.
const DEFAULT_ENTITLEMENTS = [SubscriptionEntitlement.premium];
const CACHE_EXPIRATION = Duration.fromObject({ hours: 1 });

/** Hook used to get which entitlements are active for the user's household. */
const useActiveEntitlements = () => {
  const { data } = useQueryWithCacheExpiration<GetEntitlements>(QUERY, {
    cacheExpiration: CACHE_EXPIRATION,
  });
  const { subscription } = data ?? {};
  const { entitlements } = subscription ?? {};

  return entitlements ?? DEFAULT_ENTITLEMENTS;
};

const QUERY = gql`
  query GetEntitlements {
    subscription {
      id
      entitlements
    }
  }
`;

export default useActiveEntitlements;
