import React, { useContext } from 'react';

type ModalContextType = {
  close: () => void;
  isDismissable: boolean;
};

const ModalContext = React.createContext<ModalContextType>(undefined as any);

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      'useModalContext used outside of Modal. Ensure you have a <ModalContext.Provider> higher in the tree.',
    );
  }

  return context;
};

export default ModalContext;
