import React from 'react';
import styled from 'styled-components';

import Confirmation from 'components/lib/ui/Confirmation';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import useMarkStreamAsNotRecurring from 'common/lib/hooks/recurring/useMarkStreamAsNotRecurring';
import useToast from 'lib/hooks/useToast';

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  background-color: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.default};
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
`;

const List = styled.ul`
  padding-left: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: start;
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const X_CIRCLE_ICON_SIZE_PX = 22;

type Props = {
  streamId: string;
  name: string;
  onCancel: () => void;
  onConfirm?: () => void;
};

const UnmapLiabilityAccountConfirmation = ({ streamId, name, onCancel, onConfirm }: Props) => {
  const [markAsNotRecurringMutation, { loading }] = useMarkStreamAsNotRecurring();

  const { openToast, openErrorToast } = useToast();

  const unmapAccount = async () => {
    try {
      markAsNotRecurringMutation({
        variables: {
          streamId,
        },
      });
      openToast({
        title: `Unmapped account ${name}.`,
        description:
          'It will no longer show up on your recurring calendar and in the all recurring list.',
      });
      onConfirm?.();
    } catch (e) {
      openErrorToast();
    }
  };

  return (
    <Confirmation
      title="Are you sure you want to unmap account?"
      confirm="Unmap account"
      isLoading={loading}
      onCancel={onCancel}
      onConfirm={unmapAccount}
      useDangerButton
    >
      <Content>
        <Text weight="medium">What will change:</Text>
        <List>
          <ListItem>
            <StyledIcon name="x-circle" size={X_CIRCLE_ICON_SIZE_PX} />
            <Text>Future statements will not appear in your recurring items</Text>
          </ListItem>
          <ListItem>
            <StyledIcon name="info" size={X_CIRCLE_ICON_SIZE_PX} />
            <Text>
              You will need to re-map your credit report under manage recurring if you want this
              account to show up again.
            </Text>
          </ListItem>
        </List>
      </Content>
    </Confirmation>
  );
};

export default UnmapLiabilityAccountConfirmation;
