import { useMutation, gql } from '@apollo/client';
import { useCallback } from 'react';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import { GOAL_ALLOCATION_FIELDS_FRAGMENT } from 'common/lib/hooks/goalsV2/useCreateGoalAccountAllocation';

import type {
  UpdateGoalAccountAllocation,
  UpdateGoalAccountAllocationVariables,
} from 'common/generated/graphQlTypes/UpdateGoalAccountAllocation';
import type { UpdateGoalAccountAllocationInput } from 'common/generated/graphQlTypes/globalTypes';

/** Hook used to update a goal account allocation and update the cache optimistically. */
const useUpdateGoalAccountAllocation = () => {
  const [updateAllocationMutation, mutationInfo] = useMutation<
    UpdateGoalAccountAllocation,
    UpdateGoalAccountAllocationVariables
  >(MUTATION);

  const updateAllocation = useCallback(
    async ({
      id,
      availableBalanceForGoals,
      ...input
    }: UpdateGoalAccountAllocationInput & { id: string; availableBalanceForGoals: number }) => {
      const response = await updateAllocationMutation({
        variables: {
          input,
        },
        optimisticResponse: {
          updateGoalAccountAllocation: {
            __typename: 'UpdateGoalAccountAllocation',
            goalAccountAllocation: {
              __typename: 'GoalAccountAllocation',
              id,
              amount: input.amount ?? null,
              currentAmount: input.amount ?? null,
              useEntireAccountBalance: input.useEntireAccountBalance ?? false,
              account: {
                __typename: 'Account',
                id: input.accountId,
                availableBalanceForGoals,
              },
            },
            goal: null,
            errors: null,
          },
        },
      });

      return response;
    },
    [],
  );

  return [updateAllocation, mutationInfo] as const;
};

const MUTATION = gql`
  mutation Common_UpdateGoalAccountAllocation($input: UpdateGoalAccountAllocationInput!) {
    updateGoalAccountAllocation(input: $input) {
      goalAccountAllocation {
        id
        ...GoalAllocationFields
      }
      goal {
        id
        currentAmount
        completionPercent
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${GOAL_ALLOCATION_FIELDS_FRAGMENT}
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default useUpdateGoalAccountAllocation;
