import * as R from 'ramda';

type GroupableTransactionType = {
  id: string;
  date: string;
  amount: number | undefined;
};

const groupTransactionsByAmount = <TransactionType extends GroupableTransactionType>(
  transactions: TransactionType[],
) => {
  const uniqueTransactions = R.uniqBy(R.prop('id'), transactions);
  const grouped = R.map((transaction) => transaction, uniqueTransactions);

  return R.map(
    (data) => ({
      data: [data],
      key: data.id,
      date: data.date,
      amount: data.amount,
    }),
    grouped,
  );
};

export default groupTransactionsByAmount;
