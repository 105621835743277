import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import LoggedOutCardPage from 'components/lib/layouts/LoggedOutCardPage';
import ManualLink from 'components/lib/ui/link/ManualLink';

const Subtext = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.text};
`;

type Props = {
  onSubmit: ({ totp }: { totp: string }) => void;
  goToRecoveryCodeScreen: () => void;
};

const TotpLogin = ({ onSubmit, goToRecoveryCodeScreen }: Props) => (
  <LoggedOutCardPage
    displayTitle="Authentication Code"
    description="Open the two-factor authentication app on your device to view your code."
    name="Authentication Code"
    subtext={
      <Subtext>
        Having problems?{' '}
        <ManualLink onClick={goToRecoveryCodeScreen}>Enter recovery codes</ManualLink>
      </Subtext>
    }
  >
    <Form initialValues={{ totp: '' }} onSubmit={onSubmit}>
      <TextField
        name="totp"
        autoComplete="off"
        placeholder="6-digit code"
        label="Authentication Code"
        required
        autoFocus
        maxLength={6}
        minLength={6}
      />
      <FormSubmitButton>Verify</FormSubmitButton>
    </Form>
  </LoggedOutCardPage>
);
export default TotpLogin;
