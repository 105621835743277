import { useDispatch as useDispatchRedux } from 'react-redux';

import type { Dispatch } from 'state/types';

/*
  This is the same as redux's useDispatch but with our thunk type
*/
const useDispatch = () => useDispatchRedux<Dispatch>();

export default useDispatch;
