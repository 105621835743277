import * as R from 'ramda';
import React from 'react';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import Toggle from 'components/lib/ui/Toggle';

const ToggleField = ({
  className,
  hideLabel,
  ...props
}: AbstractFieldProps<React.ComponentProps<typeof Toggle>>) => (
  <AbstractField {...props} hideLabel={hideLabel} className={className}>
    {({ id, value, setFieldValue, handleBlur, handleChange }) => (
      <Toggle
        {...R.omit(['children'], props)}
        id={id}
        checked={value ?? false}
        onChange={(e) => {
          setFieldValue(props.name, e.target.checked);
          handleChange?.(e);
          handleBlur?.(e);
        }}
      />
    )}
  </AbstractField>
);

export default ToggleField;
