import type { PropsWithChildren } from 'react';
import React, { useCallback, createContext, useContext, useMemo } from 'react';

import Confirmation from 'components/lib/ui/Confirmation';
import ContactSupportFlow from 'components/support/ContactSupportFlow';

import useToggle from 'common/lib/hooks/useToggle';
import type { FormValues } from 'common/lib/support/form';
import useModal from 'lib/hooks/useModal';

export type ModalContext = Partial<FormValues>;

type ContactSupportContextType = {
  openContactSupportModal: (context?: ModalContext) => void;
  openSupportChat: () => void;
  endSupportChat: () => void;
  openEndChatConfirmation: () => void;
  isChatOpen: boolean;
  isEndChatConfirmationOpen: boolean;
};

export const ContactSupportContext = createContext<ContactSupportContextType>(undefined as any);

export const ContactSupportContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isChatOpen, { setOn: openSupportChat, setOff: endSupportChat }] = useToggle(false);

  const [
    ContactSupportModalContainer,
    {
      open: openContactSupportModal,
      close: closeContactSupportModal,
      context: contactSupportFormContext,
    },
  ] = useModal<ModalContext>();

  const [
    EndChatConfirmationModalContainer,
    {
      open: openEndChatConfirmation,
      close: closeEndChatConfirmation,
      isOpen: isEndChatConfirmationOpen,
    },
  ] = useModal();

  const onConfirmEndChat = useCallback(() => {
    endSupportChat();
    closeEndChatConfirmation();
  }, [closeEndChatConfirmation, endSupportChat]);

  const contextValue = useMemo(
    (): ContactSupportContextType => ({
      openContactSupportModal,
      openSupportChat,
      endSupportChat,
      openEndChatConfirmation,
      isChatOpen,
      isEndChatConfirmationOpen,
    }),
    [
      openContactSupportModal,
      openSupportChat,
      isChatOpen,
      openEndChatConfirmation,
      endSupportChat,
      isEndChatConfirmationOpen,
    ],
  );

  return (
    <ContactSupportContext.Provider value={contextValue}>
      {children}
      <ContactSupportModalContainer>
        <ContactSupportFlow
          onComplete={closeContactSupportModal}
          initialValues={contactSupportFormContext}
        />
      </ContactSupportModalContainer>
      <EndChatConfirmationModalContainer>
        <Confirmation
          title="End support chat"
          confirm="Confirm"
          onCancel={closeEndChatConfirmation}
          onConfirm={onConfirmEndChat}
          useDangerButton
        >
          Are you sure you want to end the current chat?
        </Confirmation>
      </EndChatConfirmationModalContainer>
    </ContactSupportContext.Provider>
  );
};

export const useContactSupportContext = () => {
  const context = useContext(ContactSupportContext);

  if (!context) {
    throw new Error(
      'useContactSupportContext used outside of ContactSupport. ' +
        'Ensure you have a <ContactSupportContext.Provider> higher in the tree.',
    );
  }

  return context;
};
