import React from 'react';
import type { DraggableProps, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { Draggable as BeautifulDraggable } from 'react-beautiful-dnd';
import type { StyledComponent, DefaultTheme } from 'styled-components';
import styled from 'styled-components';

import DraggableContext from 'components/lib/dnd/DraggableContext';
// This is used in the docstring below.
// eslint-disable-next-line unused-imports/no-unused-imports
import DndKitDraggable from 'components/lib/dndKit/Draggable';

const Unstyled = styled.div<{ state?: DraggableStateSnapshot }>``;

type Props = Omit<DraggableProps, 'children'> & {
  children: React.ReactNode;
  className?: string;
  customDragHandle?: boolean;
  styledComponent?: StyledComponent<'div', DefaultTheme, { state?: DraggableStateSnapshot }, never>;
  onClick?: () => void;
};

/**
 * This is a custom wrapper around react-beautiful-dnd's Draggable. It abstracts away the ugly
 * render props and instead provides them in a custom context.
 *
 * -------------------
 * Basic Usage:
 * -------------------
 * <DragDropContext onDragEnd={...}>
 *   <Droppable droppableId="list">
 *     <Draggable draggableId="first" index={0}>First item!</Draggable>
 *     <Draggable draggableId="second" index={1}>Second item!</Draggable>
 *   </Droppable>
 * </DragDropContext>
 *
 * -------------------
 * Custom Drag Handle:
 * -------------------
 * const DragHandle = () => {
 *   const { dragHandleProps } = useDraggableContext();
 *   return <DragDots {...dragHandleProps} />;
 * }
 * <Draggable draggableId="first" index={0} customDragHandle>
 *   <DragHandle />
 *   This can only be dragged on the drag handle
 * </Draggable>
 *
 * @deprecated Use {@link DndKitDraggable} instead.
 */
const Draggable = ({
  children,
  className,
  customDragHandle = false,
  styledComponent,
  onClick,
  ...props
}: Props) => {
  const Root = styledComponent ?? Unstyled;

  return (
    <BeautifulDraggable {...props}>
      {({ innerRef, dragHandleProps, draggableProps }, state) => (
        <Root
          ref={innerRef}
          {...draggableProps}
          {...(customDragHandle ? {} : dragHandleProps)}
          state={state}
          className={className}
          onClick={onClick}
        >
          {/* dragHandleProps can be null but expected type is just undefined */}
          <DraggableContext.Provider
            value={{ dragHandleProps: dragHandleProps ?? undefined, state }}
          >
            {children}
          </DraggableContext.Provider>
        </Root>
      )}
    </BeautifulDraggable>
  );
};

export default Draggable;
