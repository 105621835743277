import { useMutation, gql } from '@apollo/client';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import type {
  UpdateAccountGroupOrder,
  UpdateAccountGroupOrderVariables,
} from 'common/generated/graphQlTypes/UpdateAccountGroupOrder';
import type { Web_GetAccountsPageQuery } from 'common/generated/graphql';

const useAccountGroupOrder = (
  // TODO: Move the fields to a fragment
  householdPreferences: Web_GetAccountsPageQuery['householdPreferences'],
) => {
  const accountGroupOrder: string[] | undefined = householdPreferences?.accountGroupOrder;
  const householdId = householdPreferences?.id || null;

  const [performUpdate] = useMutation<UpdateAccountGroupOrder, UpdateAccountGroupOrderVariables>(
    UPDATE_ACCOUNT_GROUP_ORDER,
  );
  const updateAccountGroupOrder = async (orderedGroups: string[]) => {
    await performUpdate({
      variables: {
        input: { accountGroupOrder: orderedGroups },
      },
      optimisticResponse: {
        updateAccountGroupOrder: {
          __typename: 'UpdateAccountGroupOrderMutation',
          household: {
            __typename: 'Household',
            preferences: {
              id: householdId,
              __typename: 'HouseholdPreferences',
              accountGroupOrder: orderedGroups,
            },
          },
          errors: null,
        },
      },
    });
  };

  return [accountGroupOrder, updateAccountGroupOrder] as const;
};

const UPDATE_ACCOUNT_GROUP_ORDER = gql`
  mutation Common_UpdateAccountGroupOrder($input: UpdateAccountGroupOrderInput!) {
    updateAccountGroupOrder(input: $input) {
      household {
        preferences {
          id
          accountGroupOrder
        }
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default useAccountGroupOrder;
