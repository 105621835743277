import { DateTime } from 'luxon';

import { MOCK_INSTITUTION_LOGOS } from 'common/utils/graphQl/__mocks__/institutionLogos';

export const MOCK_ACCOUNTS_LIST = [
  {
    displayName: 'Example Savings',
    signedBalance: 1000,
    isAsset: true,
    type: {
      name: 'depository',
      display: 'Cash',
    },
    subtype: {
      name: 'checking',
      display: 'Checking',
    },
  },
  {
    displayName: 'Example Checking',
    signedBalance: 4000,
    isAsset: true,
    type: {
      name: 'depository',
      display: 'Cash',
    },
    subtype: {
      name: 'checking',
      display: 'Checking',
    },
  },
  {
    displayName: 'Example IRA',
    signedBalance: 10000,
    isAsset: true,
    type: {
      name: 'brokerage',
      display: 'Investments',
    },
    subtype: {
      name: 'ira',
      display: 'IRA',
    },
    credential: {
      institution: {
        logo: MOCK_INSTITUTION_LOGOS.WEALTHFRONT,
      },
    },
  },
  {
    displayName: 'Example Home',
    signedBalance: 500000,
    isAsset: true,
    type: {
      name: 'real_estate',
      display: 'Real Estate',
    },
    subtype: {
      display: 'primary_home',
      name: 'Primary Home',
    },
    credential: {
      institution: {
        logo: undefined,
      },
    },
  },
  {
    displayName: 'Example Credit Card',
    signedBalance: 192000,
    isAsset: false,
    type: {
      name: 'credit',
      display: 'Credit Cards',
    },
    subtype: {
      name: 'credit_card',
      display: 'Credit Card',
    },
    credential: {
      institution: {
        logo: MOCK_INSTITUTION_LOGOS.CHASE,
      },
    },
  },
];

const updateMockDataMonths = (data: Record<string, unknown>[]) => {
  const now = DateTime.local();
  const uniqueMonths = Array.from(new Set(data.map((item) => item.month)));
  const updatedData = data.map((item) => {
    const index = uniqueMonths.indexOf(item.month);
    const relativeMonth = now.minus({ months: uniqueMonths.length - index - 1 });
    return {
      ...item,
      month: relativeMonth.toISODate().substring(0, 7),
    };
  });

  return updatedData;
};

export const NET_WORTH_CHART_MOCK_DATA = updateMockDataMonths([
  {
    __typename: 'SnapshotByType',
    accountType: 'depository',
    balance: 49800.84,
    month: '2023-04',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'brokerage',
    balance: 530000,
    month: '2023-04',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'real_estate',
    balance: 300000,
    month: '2023-04',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'loan',
    balance: -240000,
    month: '2023-04',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'vehicle',
    balance: 20000,
    month: '2023-04',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'brokerage',
    balance: 530000,
    month: '2023-05',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'loan',
    balance: -240000,
    month: '2023-05',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'real_estate',
    balance: 300000,
    month: '2023-05',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'depository',
    balance: 54908.56,
    month: '2023-05',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'vehicle',
    balance: 20000,
    month: '2023-05',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'brokerage',
    balance: 159134.18,
    month: '2023-06',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'depository',
    balance: 259893.28,
    month: '2023-06',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'loan',
    balance: -240000,
    month: '2023-06',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'credit',
    balance: 1908,
    month: '2023-06',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'real_estate',
    balance: 291000,
    month: '2023-06',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'vehicle',
    balance: 20000,
    month: '2023-06',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'real_estate',
    balance: 287000,
    month: '2023-07',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'credit',
    balance: 1902,
    month: '2023-07',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'vehicle',
    balance: 20000,
    month: '2023-07',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'depository',
    balance: 65110,
    month: '2023-07',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'brokerage',
    balance: 163292.46,
    month: '2023-07',
  },
  {
    __typename: 'SnapshotByType',
    accountType: 'loan',
    balance: 296302.06,
    month: '2023-07',
  },
]);

export const ACCOUNT_AMEX = {
  syncDisabled: false,
  isHidden: false,
  isAsset: false,
  includeInNetWorth: true,
  order: 1,
  type: {
    name: 'credit',
    display: 'Credit Cards',
    __typename: 'AccountType',
  },
  displayName: 'American Express',
  displayBalance: 1244,
  signedBalance: -1244,
  updatedAt: '2023-08-17T12:27:58.207374+00:00',
  icon: 'trending-up',
  logoUrl: `data:image/png;base64,${MOCK_INSTITUTION_LOGOS.AMEX}`,
  includeBalanceInNetWorth: true,
  displayLastUpdatedAt: '2023-08-17T12:27:58.207374+00:00',
  mask: null,
  subtype: {
    display: 'Credit Card',
    __typename: 'AccountSubtype',
  },
  __typename: 'Account',
  credential: null,
  institution: {
    __typename: 'Institution',
    id: '75115002753273306',
    name: 'American Express',
    status: 'DEGRADED',
  },
};

export const ACCOUNT_DEPOSITORY_WELLS_FARGO = {
  syncDisabled: false,
  isHidden: false,
  isAsset: true,
  includeInNetWorth: true,
  order: 10,
  type: {
    name: 'depository',
    display: 'Cash',
    __typename: 'AccountType',
  },
  displayName: 'Checking',
  displayBalance: 4301.48,
  signedBalance: 4301.48,
  updatedAt: '2023-08-08T17:49:43.939373+00:00',
  icon: 'dollar-sign',
  logoUrl: `data:image/png;base64,${MOCK_INSTITUTION_LOGOS.WELLS_FARGO}`,
  includeBalanceInNetWorth: true,
  displayLastUpdatedAt: '2023-08-08T17:49:43.939373+00:00',
  mask: null,
  subtype: {
    display: 'Checking',
    __typename: 'AccountSubtype',
  },
  __typename: 'Account',
  credential: null,
  institution: null,
};

export const ACCOUNT_SUMMARY_DATA = [
  {
    type: {
      name: 'depository',
      display: 'Cash',
      group: 'asset',
      __typename: 'AccountType',
    },
    accounts: [
      ACCOUNT_DEPOSITORY_WELLS_FARGO,
      {
        id: '139798527961665312',
        syncDisabled: false,
        isHidden: false,
        isAsset: true,
        includeInNetWorth: true,
        order: 12,
        type: {
          name: 'depository',
          display: 'Cash',
          __typename: 'AccountType',
        },
        displayName: 'Joint Savings',
        displayBalance: 328634.9,
        signedBalance: 328634.9,
        updatedAt: '2023-08-17T07:16:35.748405+00:00',
        icon: 'dollar-sign',
        logoUrl: `data:image/png;base64,${MOCK_INSTITUTION_LOGOS.WELLS_FARGO}`,
        includeBalanceInNetWorth: true,
        displayLastUpdatedAt: '2023-08-17T03:43:51.702000+00:00',
        mask: null,
        subtype: {
          display: 'Savings',
          __typename: 'AccountSubtype',
        },
        __typename: 'Account',
        credential: {
          id: '139798527366074218',
          updateRequired: false,
          dataProvider: 'PLAID',
          disconnectedFromDataProviderAt: null,
          __typename: 'Credential',
        },
        institution: {
          id: '75107456376287818',
          logo: null,
          name: 'Wells Fargo',
          status: 'HEALTHY',
        },
      },
    ],
    totalDisplayBalance: 332936.38,
    __typename: 'AccountTypeSummary',
  },
  {
    type: {
      name: 'real_estate',
      display: 'Real Estate',
      group: 'asset',
      __typename: 'AccountType',
    },
    totalDisplayBalance: 289000,
    accounts: [
      {
        id: '139798527961665313',
        syncDisabled: false,
        isHidden: false,
        isAsset: true,
        includeInNetWorth: true,
        order: 0,
        type: {
          name: 'real_estate',
          display: 'Real Estate',
          __typename: 'AccountType',
        },
        displayName: '1103 Terranova Ln, Houston, TX',
        displayBalance: 289000,
        signedBalance: 289000,
        icon: 'home',
        logoUrl: `data:image/png;base64,${MOCK_INSTITUTION_LOGOS.ZILLOW}`,
        includeBalanceInNetWorth: true,
        updatedAt: '2023-08-17T07:16:35.748405+00:00',
        displayLastUpdatedAt: '2023-08-17T03:43:51.702000+00:00',
        mask: null,
        subtype: {
          display: 'Primary Home',
          __typename: 'AccountSubtype',
        },
        institution: {
          __typename: 'Institution',
          id: '85695157187961151',
          name: 'Zillow',
          status: null,
        },
        credential: null,
      },
    ],
  },
  {
    type: {
      name: 'brokerage',
      display: 'Investments',
      group: 'asset',
      __typename: 'AccountType',
    },
    accounts: [
      {
        id: '102753458420387601',
        syncDisabled: false,
        isHidden: false,
        isAsset: true,
        includeInNetWorth: true,
        order: 0,
        type: {
          name: 'brokerage',
          display: 'Investments',
          __typename: 'AccountType',
        },
        displayName: 'Robinhood',
        displayBalance: 163292.46,
        signedBalance: 163292.46,
        updatedAt: '2023-08-14T01:33:39.937487+00:00',
        icon: 'trending-up',
        logoUrl: `data:image/png;base64,${MOCK_INSTITUTION_LOGOS.ROBINHOOD}`,
        includeBalanceInNetWorth: true,
        displayLastUpdatedAt: '2023-08-14T01:33:39.937487+00:00',
        mask: null,
        subtype: {
          display: 'Brokerage',
          __typename: 'AccountSubtype',
        },
        __typename: 'Account',
        credential: null,
        institution: {
          id: '75118740861242911',
          name: 'Robinhood',
          status: 'HEALTHY',
          __typename: 'Institution',
        },
      },
    ],
    totalDisplayBalance: 163292.46,
    __typename: 'AccountTypeSummary',
  },
  {
    type: {
      name: 'loan',
      display: 'Loans',
      group: 'liability',
      __typename: 'AccountType',
    },
    accounts: [
      {
        syncDisabled: false,
        isHidden: false,
        isAsset: false,
        includeInNetWorth: true,
        order: 4,
        type: {
          name: 'loan',
          display: 'Loans',
          __typename: 'AccountType',
        },
        displayName: 'Mortgage',
        displayBalance: 226501.82,
        signedBalance: -226501.82,
        updatedAt: '2023-07-23T14:10:06.613255+00:00',
        icon: 'home',
        logoUrl: `data:image/png;base64,${MOCK_INSTITUTION_LOGOS.WELLS_FARGO}`,
        includeBalanceInNetWorth: true,
        displayLastUpdatedAt: '2023-07-23T14:10:06.613255+00:00',
        mask: null,
        subtype: {
          display: 'Mortgage',
          __typename: 'AccountSubtype',
        },
        __typename: 'Account',
        credential: null,
        institution: null,
      },
    ],
    totalDisplayBalance: 226501.82,
    __typename: 'AccountTypeSummary',
  },
  {
    type: {
      name: 'credit',
      display: 'Credit Cards',
      group: 'liability',
      __typename: 'AccountType',
    },
    accounts: [ACCOUNT_AMEX],
    totalDisplayBalance: 1244,
    __typename: 'AccountTypeSummary',
  },
];
