import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { getIsSponsor, getShouldForceRedirectSponsorUser } from 'selectors';

import routes from 'constants/routes';

/**
 * Hook used to redirect sponsor users to the sponsor portal if they
 * meet some conditions defined on the backend.
 */
const useRedirectSponsorUser = () => {
  const { location, push } = useHistory();
  const isSponsor = useSelector(getIsSponsor);
  const shouldForceRedirectSponsorUser = useSelector(getShouldForceRedirectSponsorUser);

  const advisorPortalMatch = useRouteMatch(routes.advisorPortal.path);
  const logoutRouteMatch = useRouteMatch(routes.logout.path); // logout is a special case
  const notificationsSettingsMatch = useRouteMatch(routes.settings.notifications.path);

  useEffect(() => {
    const isAllowedForAdvisorRoutesMatch =
      advisorPortalMatch || logoutRouteMatch || notificationsSettingsMatch;
    if (isSponsor && shouldForceRedirectSponsorUser && !isAllowedForAdvisorRoutesMatch) {
      push(routes.advisorPortal.clients());
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useRedirectSponsorUser;
