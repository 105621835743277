import QRCode from 'qrcode.react';
import React from 'react';
import styled from 'styled-components';

import { Label } from 'components/lib/form/FormItemContainer';
import TextInput from 'components/lib/form/TextInput';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';
import ThemeProvider from 'components/utils/ThemeProvider';

import type { createUnconfirmedTotpDevice_createUnconfirmedTotpDevice_device } from 'common/generated/graphQlTypes/createUnconfirmedTotpDevice';

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const Box = styled(FlexContainer)`
  border-radius: ${({ theme }) => theme.radius.small};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
  background: ${({ theme }) => theme.color.textWhite};
`;

const StyledLabel = styled(Label)`
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.color.text};
`;

const StyledInput = styled(TextInput)`
  width: 100%;
`;

type Props = {
  device?: createUnconfirmedTotpDevice_createUnconfirmedTotpDevice_device | null;
  loading: boolean;
};

const QRCodeBoxMfa = ({ device, loading }: Props) =>
  loading && !device ? (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  ) : (
    <ThemeProvider forceTheme="light">
      <Box padding="xlarge">
        {!!device?.configUrl && (
          <QRCode value={device.configUrl} size={150} {...sensitiveClassProps} />
        )}
        <FlexContainer column marginLeft="xlarge" paddingVertical="small" justifyBetween>
          <Text color="text">
            If you’re unable to scan the QR code, enter the text code below instead.
          </Text>
          <div>
            <StyledLabel>Two-factor text code</StyledLabel>
            <StyledInput name="secret" value={device?.secret} disabled {...sensitiveClassProps} />
          </div>
        </FlexContainer>
      </Box>
    </ThemeProvider>
  );

export default QRCodeBoxMfa;
