import * as React from 'react';
import styled from 'styled-components';

import type { Tick, Props as ProgressBarProps } from 'components/lib/ui/ProgressBar';
import ProgressBar from 'components/lib/ui/ProgressBar';

import { getDisplayProgressPercent } from 'common/lib/budget/Amounts';

import type { Color } from 'types/Styles';

type Props = {
  actual?: number;
  total?: number;
  ticks?: Tick[];
  color: Color;
  backgroundColor?: Color;
  striped?: boolean;
  animated?: boolean;
};

const Root = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

const StyledProgressBar = styled(ProgressBar)`
  margin: ${({ theme }) => theme.spacing.small} 0;
`;

const SummaryProgressBar = ({
  actual,
  total,
  ticks,
  ...props
}: Props & Omit<ProgressBarProps, 'value'>) => (
  <Root>
    <StyledProgressBar
      value={getDisplayProgressPercent(actual ?? 0, total ?? 0)}
      roundAppearance
      roundInnerBar={false}
      ticks={ticks}
      {...props}
    />
  </Root>
);

export default SummaryProgressBar;
