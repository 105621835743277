import { gql } from 'common/generated/gql';

export const GET_MONTHLY_SNAPSHOTS_QUERY = gql(`
  query Common_GetMonthlySnapshots($startDate: Date) {
    monthlySnapshotsByAccountType(startDate: $startDate) {
      accountType
      month
      balance
    }
    accountTypes {
      name
      group
    }
  }
`);

export const GET_SNAPSHOTS_BY_ACCOUNT_TYPE_QUERY = gql(/* GraphQL */ `
  query Common_GetSnapshotsByAccountType($startDate: Date!, $timeframe: Timeframe!) {
    snapshotsByAccountType(startDate: $startDate, timeframe: $timeframe) {
      accountType
      month
      balance
    }

    accountTypes {
      name
      group
    }
  }
`);

export const GET_DISPLAY_BALANCE_AT_DATE_QUERY = gql(`
  query Common_GetDisplayBalanceAtDate($date: Date!) {
    accounts {
      id
      displayBalance(date: $date)
      includeInNetWorth
      type {
        name
      }
    }
  }
`);

export const DELETE_SYNCED_SNAPSHOTS_MUTATION = gql(`
  mutation Common_DeleteSyncedSnapshotsMutation($input: DeleteSyncedSnapshotsInput!) {
    deleteSyncedSnapshots(input: $input) {
      success
      errors {
        message
        code
      }
    }
  }
`);
