import storage from 'redux-persist/lib/storage';
import { createReducer } from 'typesafe-actions';

import {
  setProviderEphemeralData,
  clearProviderEphemeralData,
  clearOAuthStateString,
  setOAuthStateString,
} from 'actions';

import type { AuthProviderEphemeralData } from 'types/auth';

export const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['oAuthStateString'],
};

export type AuthState = {
  ephemeralData: AuthProviderEphemeralData | undefined;
  oAuthStateString: string | null;
};

const INITIAL_STATE: AuthState = {
  ephemeralData: undefined,
  oAuthStateString: null,
};

const auth = createReducer<AuthState>(INITIAL_STATE)
  .handleAction(setProviderEphemeralData, (state, { payload }) => ({
    ...state,
    ephemeralData: payload,
  }))
  .handleAction(clearProviderEphemeralData, (state) => ({ ...state, ephemeralData: undefined }))
  .handleAction(setOAuthStateString, (state, { payload }) => ({
    ...state,
    oAuthStateString: payload,
  }))
  .handleAction(clearOAuthStateString, (state) => ({ ...state, oAuthStateString: null }));

export default auth;
