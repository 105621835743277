import React from 'react';

import type { Props as ContactSupportModalProps } from 'components/support/ContactSupportModal';
import ContactSupportModal from 'components/support/ContactSupportModal';
import ContactSupportSuccessModal from 'components/support/ContactSupportSuccessModal';
import Flow from 'components/utils/Flow';

type Props = {
  onComplete: () => void;
  onBack?: () => void;
} & Pick<ContactSupportModalProps, 'initialValues'>;

const ContactSupportFlow = ({ onComplete, onBack, initialValues }: Props) => (
  <Flow
    initialProps={{ initialValues }}
    steps={[ContactSupportModal, ContactSupportSuccessModal]}
    onComplete={onComplete}
    onBack={onBack}
  />
);

export default ContactSupportFlow;
