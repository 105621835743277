import { camelCase, snakeCase } from 'change-case';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as Yup from 'yup';

import { DEFAULT_REQUIRED_MESSAGE } from 'common/constants/copy';

import type { Web_GetContactSupportFormDataQuery } from 'common/generated/graphql';
import { ContactSupportTopic } from 'common/generated/graphql';
import type { FileData } from 'common/types/File';

type ContactFormData = NonNullable<Web_GetContactSupportFormDataQuery['contactFormData']>;

export type FormValues<TFile extends FileData = FileData> = {
  topic: ContactSupportTopic;
  reason: string;
  description: string;
  grantSupportAccountAccess: boolean;
  attachments: TFile[];
  tags: string[];

  /** Fields that will be required depending on the reason */
  credentialId: string | undefined;
  institutionName: string | undefined; // free-text field launched on v1
};

export const SUPPORT_FORM_INITIAL_VALUES: Partial<FormValues> = {
  topic: undefined,
  reason: undefined,
  description: '',
  grantSupportAccountAccess: true,
  attachments: [],
  tags: [],
  institutionName: undefined,
  credentialId: undefined,
};

export type RequiredFieldsSchema = Partial<Record<keyof FormValues, true>>;

export const getTopicOptions = (topics: ContactFormData['topics'] | undefined = []) =>
  topics.map(({ value, label }) => ({ value, label }));

export const getImpactOptions = (impacts: ContactFormData['impacts'] | undefined = []) =>
  impacts.map(({ value, label }) => ({ value, label }));

export const getReasonOptions = (reasons: ContactFormData['reasonsForTopic'] | undefined = []) =>
  reasons.map(({ value, label }) => ({ value, label }));

export const parseRequiredFieldsForReason = (
  fields: string[] | undefined | null,
): RequiredFieldsSchema =>
  (fields ?? [])
    .map((field) => camelCase(field))
    .reduce((acc, field) => ({ ...acc, [field]: true }), {});

export const prepareSupportTicketFormData = (
  values: Partial<FormValues>,
  email: string,
  url?: string,
): FormData => {
  const { attachments, ...rest } = values;
  const formData = new FormData();

  if (url) {
    formData.append('url', url);
  }
  formData.append('email', email);

  Object.keys(rest).forEach((key) => {
    const value = values[key as keyof FormValues];
    if (R.isNil(value)) {
      return;
    }
    formData.append(snakeCase(key), RA.isObjLike(value) ? JSON.stringify(value) : String(value));
  });

  if (attachments) {
    // @ts-ignore - TS doesn't play well with FileData
    Array.from(attachments).forEach((file) => formData.append('attachments', file, file.name));
  }

  return formData;
};

export const makeSupportFormValidationSchema = (requiredFields: RequiredFieldsSchema) =>
  Yup.object().shape({
    topic: Yup.string().required(DEFAULT_REQUIRED_MESSAGE),
    reason: Yup.string().required(DEFAULT_REQUIRED_MESSAGE),
    impact: Yup.string().required(DEFAULT_REQUIRED_MESSAGE),
    description: Yup.string()
      .required(DEFAULT_REQUIRED_MESSAGE)
      .min(12, 'Description should be at least 12 characters long'),
    grantSupportAccountAccess: Yup.boolean().required(DEFAULT_REQUIRED_MESSAGE),
    attachments: Yup.array().of(Yup.object()),
    tags: Yup.array().of(Yup.string()),
    institutionName: Yup.string().test(
      'required',
      DEFAULT_REQUIRED_MESSAGE,
      (value) => !requiredFields.institutionName || !!value,
    ),
    credentialId: Yup.string().test(
      'required',
      DEFAULT_REQUIRED_MESSAGE,
      (value) => !requiredFields.credentialId || !!value,
    ),
  });

export const shouldShowCredentialField = (
  requiredFields: RequiredFieldsSchema,
  topic: ContactSupportTopic,
  reason: string,
  initialValues: Partial<FormValues>,
) =>
  // Form is opened with pre-filled credentialId
  requiredFields.credentialId ||
  (topic === ContactSupportTopic.CONNECTION_ISSUE && !!initialValues.credentialId && !reason);
