import React, { useContext } from 'react';

import TabsContext from 'common/components/tabs/TabsContext';

type Props = {
  index: number;
  children: React.ReactNode;
};

const TabPanel = ({ index, children }: Props) => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error(
      'Used <TabPanel> component outside of TabsContext. Ensure you have a <Tabs> component higher in the tree.',
    );
  }
  const { activeIndex } = context;

  return <>{activeIndex === index && children}</>;
};

export default TabPanel;
