import type { match } from 'react-router';
import { useRouteMatch as originalUseRouteMatch } from 'react-router';

import type { Route, GetRouteParams } from 'lib/namedRoutes';

/**
 * This is functionally equivalent to react-router's useRouteMatch, but with strong typing for our route types.
 *
 * Use like:
 * const match = useRouteMatch(routes.advice); // Note don't pass .path to this hook, like you would to react-router
 * match?.params.category // This is strongly typed
 */
const useRouteMatch = <R extends Route<any>>(route: R): match<GetRouteParams<R>> | null =>
  originalUseRouteMatch(route.path);

export default useRouteMatch;
