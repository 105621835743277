import { useMutation } from '@apollo/client';
import { SearchIndex } from 'emoji-mart';
import { omit } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';

import CreateEditCategoryFormFields from 'components/categories/CreateEditCategoryFormFields';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';

import {
  getCategoryFormInitialValues,
  maybeGetRolloverFutureBudgetAllocation,
} from 'common/lib/categories/form';

import { ROLLOVER_APPLY_TO_FUTURE_MONTHS_FIELD_NAME } from 'common/constants/budget';
import { CACHE_KEYS } from 'common/constants/cache';

import { gql } from 'common/generated/gql';
import type { CreateCategoryInput, Web_CreateCategoryMutation } from 'common/generated/graphql';

const DEFAULT_EMOJI = '❓';

type Category = NonNullable<NonNullable<Web_CreateCategoryMutation['createCategory']>['category']>;

type Props = {
  categoryGroupType?: Maybe<string>;
  initialValues?: Partial<Omit<CreateCategoryInput, '__typename' | 'id'>>;
  onCreate?: (category: Category) => void;
};

const CreateCategoryModal = ({ categoryGroupType, initialValues, onCreate }: Props) => {
  const [createCategory] = useMutation(CREATE_CATEGORY, {
    update: (cache) => {
      cache.evict({ fieldName: CACHE_KEYS.CATEGORIES });
      cache.evict({ fieldName: CACHE_KEYS.CATEGORY_GROUPS });
    },
    onQueryUpdated(observableQuery) {
      return observableQuery.refetch();
    },
  });

  const [emojiResult, setEmojiResult] = useState<string>(DEFAULT_EMOJI);
  useEffect(() => {
    const main = async () => {
      if (!initialValues?.icon && initialValues?.name) {
        const result = await SearchIndex.search(initialValues.name, {
          maxResults: 1,
          caller: 'create-category-modal',
        });

        if (result.length > 0) {
          setEmojiResult(result[0].skins[0].native);
        }
      }
    };

    main();
  }, [initialValues?.name, initialValues?.icon]);

  const formInitialValues = useMemo(() => {
    const values = getCategoryFormInitialValues(omit(['group'], initialValues));
    return { ...values, group: initialValues?.group ?? '', name: initialValues?.name ?? '' };
  }, [initialValues]);

  return (
    <ModalCard title="Create Category">
      <Form
        initialValues={{ ...formInitialValues, icon: emojiResult }}
        onSubmit={async (values) => {
          let mutationInput = omit([ROLLOVER_APPLY_TO_FUTURE_MONTHS_FIELD_NAME], values);
          const rolloverFutureBudgetAllocation = maybeGetRolloverFutureBudgetAllocation(values);

          if (rolloverFutureBudgetAllocation) {
            mutationInput = {
              ...mutationInput,
              rolloverFutureBudgetAllocation,
            };
          }

          const response = await createCategory({
            variables: {
              input: {
                ...mutationInput,
                // TODO: needed due to a hotfix (api#6097)
                applyRolloverBudgetToFutureMonths: undefined,
              },
            },
          });

          if (response?.data?.createCategory?.category) {
            onCreate?.(response.data.createCategory.category);
          }
        }}
      >
        <CardBody>
          <CreateEditCategoryFormFields categoryType={categoryGroupType ?? undefined} />
        </CardBody>
        <CardFooter>
          <FormSubmitButton disableWhenValuesUnchanged={false} size="small">
            Save
          </FormSubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

const CREATE_CATEGORY = gql(`
  mutation Web_CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      category {
        id
        ...CategoryFormFields
      }
    }
  }
`);

export default CreateCategoryModal;
