import React from 'react';
import styled, { css } from 'styled-components';

const Root = styled.div<{ $active: boolean; $multiLine: boolean }>`
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.xlarge};
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ $active, theme }) => ($active ? theme.color.textWhite : theme.color.text)};
  transition: ${({ theme }) => theme.transition.default};

  background: ${({ $active, theme }) =>
    $active
      ? `linear-gradient(90deg, ${theme.color.redDark} 0%, ${theme.color.orangeDark} 100%)`
      : theme.color.grayBackground};

  border-radius: ${({ theme }) => theme.radius.pill};

  ${({ $active, theme }) =>
    !$active &&
    css`
      :hover {
        background: ${theme.color.grayFocus};
      }
    `}

  ${({ $multiLine }) =>
    !$multiLine
      ? css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `
      : css`
          white-space: normal;
        `}
`;

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  children?: React.ReactNode;
  className?: string;
  multiLine?: boolean;
};

const TogglePill = ({ value, onChange, children, className, multiLine = false }: Props) => (
  <Root
    $active={value}
    $multiLine={multiLine}
    className={className}
    onClick={() => onChange(!value)}
  >
    {children}
  </Root>
);

export default TogglePill;
