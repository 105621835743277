import { gql as newGql } from 'common/generated/gql';

export const GET_CASH_FLOW_DASHBOARD_QUERY = newGql(/* GraphQL */ `
  query GetCashFlowDashboard($startDate: Date, $endDate: Date) {
    byDay: aggregates(
      filters: { startDate: $startDate, endDate: $endDate }
      fillEmptyValues: true
      groupBy: ["day"]
    ) {
      summary {
        sumExpense
      }
      groupBy {
        day
      }
    }
  }
`);
