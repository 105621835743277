import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

const SPINNER_SIZE_PX = 32;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  background-color: ${({ theme }) => theme.color.white};
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.default} 0;
`;

type Props = {
  hide: boolean;
};

/**
 * Useful when we want to display a loading footer while we're fetching more data.
 */
const TableLoadingFooter = ({ hide }: Props) =>
  !hide ? (
    <LoadingContainer>
      <LoadingSpinner $size={SPINNER_SIZE_PX} />
    </LoadingContainer>
  ) : null;

export default TableLoadingFooter;
