import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

const NUM_DOTS = 3;

const Root = styled.div`
  display: flex;
  justify-content: center;

  > div {
    width: 8px;
    height: 8px;
    margin: 1.5px 3px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.grayDark};
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
    transform: translateY(4px);
  }

  @keyframes bouncing-loader {
    to {
      opacity: 0.2;
      transform: translateY(-4px);
    }
  }

  > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  > div:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

type Props = {
  className?: string;
};

const BouncingDots = ({ className }: Props) => (
  <Root className={className}>
    {R.range(0, NUM_DOTS).map((i) => (
      <div key={i} />
    ))}
  </Root>
);

export default BouncingDots;
