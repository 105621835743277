import { ellipsis } from 'polished';
import { omit } from 'ramda';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import type {
  FilterMenuOption,
  FilterMenuOptionInput,
  FilterMenuSection,
  WithPath,
} from 'components/reports/filters/types';

import { color, fontSize, spacing } from 'common/lib/theme/dynamic';

const Root = styled(FlexContainer).attrs({ column: true })`
  gap: ${spacing.xxxsmall};
  padding: 0 ${spacing.small};
`;

const Row = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  height: 32px;
  font-size: ${fontSize.small};
  gap: ${spacing.xsmall};
`;

const StyledTextButton = styled(TextButton)`
  font-size: ${fontSize.xsmall};
  padding: 0;
`;

const XButton = styled(Icon).attrs({ name: 'x-circle' })`
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: ${color.textLight};

  :hover {
    color: ${color.text};
  }
`;

const IconName = styled(FlexContainer).attrs({ alignCenter: true })`
  ${omit(['display'], ellipsis())}

  ${Text} {
    line-height: 150%;
  }
`;

type Props = {
  activeSections: (WithPath<FilterMenuSection> & {
    selectedOptions: WithPath<FilterMenuOption>[];
  })[];
  onChangeSelected: (path: string[], selected: boolean) => void;
};

const FilterMenuSelectedList = ({ activeSections, onChangeSelected }: Props) => (
  <Root>
    {activeSections.map(({ id, path, name, selectedOptions }) => (
      <React.Fragment key={id}>
        <Row>
          <Text clampLines={1} size="xsmall" weight="medium" color="textLight">
            {name}
          </Text>
          <StyledTextButton onClick={() => onChangeSelected(path, false)}>Clear</StyledTextButton>
        </Row>
        {selectedOptions.map(({ id, name, path, icon, inputs, inputValues, formatLabel }) => {
          const values = getValuesFromInputs(inputs, inputValues);
          const label = formatLabel?.(values) || name;
          return (
            <Row key={id}>
              <IconName>
                <div>{icon}</div>
                <Text clampLines={1} title={label}>
                  {label}
                </Text>
              </IconName>
              <XButton onClick={() => onChangeSelected(path, false)} />
            </Row>
          );
        })}
      </React.Fragment>
    ))}
  </Root>
);

const getValuesFromInputs = (
  inputs: Maybe<FilterMenuOptionInput[]>,
  inputValues: Maybe<Record<string, any>>,
) =>
  (inputs ?? []).reduce(
    (acc, { name }) => ({ ...acc, [name]: inputValues?.[name] }),
    {} as Record<string, any>,
  );

export default FilterMenuSelectedList;
