import React from 'react';
import styled from 'styled-components';

import LogoAllWhite from 'static/images/logo-all-white.svg';
import LogoWithDarkText from 'static/images/logo-color.svg';

const DEFAULT_LOGO_HEIGHT_PX = 24;

const APPEARANCE_TO_SRC = {
  dark: '/logo-color.svg',
  white: '/logo.svg',
  mark: '/butterfly-logo.svg',
  allWhite: '/logo-all-white.svg',
};

const APPEARANCE_TO_DATA_URI = {
  dark: LogoWithDarkText,
  allWhite: LogoAllWhite,
};

type Props = {
  dark?: boolean;
  height?: number;
};

const BaseLogo = styled.img<Props>`
  display: block;
  height: ${({ height = DEFAULT_LOGO_HEIGHT_PX }) => height}px;
`;

const MonarchLogo = styled(BaseLogo).attrs<Props>(({ dark, theme }) => ({
  src: dark && theme.uiTheme !== 'dark' ? '/logo-color.svg' : '/logo.svg',
}))``;

/**
 * Renders a BaseLogo component with a specific appearance, not influenced by the current theme.
 * This gives more control over the logo's appearance and includes more options.
 */
export const DynamicMonarchLogo: React.FC<{
  appearance?: keyof typeof APPEARANCE_TO_SRC;
  height?: number;
}> = ({ appearance = 'dark', height }) => (
  <BaseLogo src={APPEARANCE_TO_SRC[appearance]} height={height} />
);

/**
 * Renders a Monarch logo using our SVG loader.
 *
 * This component is particularly useful in environments where image rendering is problematic, such as the
 * chart share modal in Safari, which tends to cut off images. By using an inline SVG, we can circumvent
 * this issue.
 */
export const MonarchLogoSvg: React.FC<
  {
    height?: number;
    appearance?: keyof typeof APPEARANCE_TO_DATA_URI;
  } & React.ImgHTMLAttributes<HTMLImageElement>
> = ({ height = DEFAULT_LOGO_HEIGHT_PX, appearance = 'dark', style, ...svgProps }) => {
  const data = APPEARANCE_TO_DATA_URI[appearance];
  return (
    <img
      alt="Monarch Logo"
      src={data}
      width="auto"
      height={height}
      // This makes it so that (apparently) the logo adjusts its surrounding space to match its height
      style={{ flex: 1, ...style }}
      {...svgProps}
    />
  );
};

export default MonarchLogo;
