import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import HelpIconTooltip from 'components/lib/ui/HelpIconTooltip';
import Text from 'components/lib/ui/Text';

type Props = {
  label: string;
  right?: React.ReactNode;
  children?: React.ReactNode;
  tooltip?: string;
  className?: string;
};

const StyledTooltip = styled(HelpIconTooltip)`
  margin-left: 4px;
  bottom: 1px;
  position: relative;
`;

const StyledFlexContainer = styled(FlexContainer)`
  flex-grow: 1;
`;

const FieldRow = ({ label, right, children, tooltip, className }: Props) => (
  <FlexContainer marginBottom="default" className={className} column full>
    <FlexContainer alignCenter justifyBetween marginBottom={children ? 'small' : undefined}>
      <StyledFlexContainer>
        <Text weight="medium" size="small">
          {label}
        </Text>
        {!!tooltip && <StyledTooltip sizePx={12} tooltip={tooltip} />}
      </StyledFlexContainer>
      {right}
    </FlexContainer>
    {children}
  </FlexContainer>
);

export default FieldRow;
