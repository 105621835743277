import isEnvProduction from 'lib/isEnvProduction';

// Get the API url. For dev runs, we point to the local ('') since requests get proxied, and for
// production, we use the REACT_APP_API_URL env var
const getApiUrl = (): string =>
  isEnvProduction() && process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

export const getWsUrl = () => {
  let url = process.env.REACT_APP_API_URL ?? 'ws://localhost:8000';
  url = url.replace('https', 'wss');
  url = url.replace('http', 'ws');
  return url;
};

export default getApiUrl;
