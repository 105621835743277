import pluralize from 'pluralize';
import { isNil } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import TabPanel from 'common/components/tabs/TabPanel';
import CheckboxField from 'components/lib/form/CheckboxField';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import Empty, { Theme as EmptyTheme } from 'components/lib/ui/Empty';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import Pill from 'components/lib/ui/Pill';
import DangerButton from 'components/lib/ui/button/DangerButton';
import Tab from 'components/lib/ui/tabs/Tab';
import PreviewTransactionChangesTable from 'components/transactions/PreviewTransactionChangesTable';
import TransactionRuleConfirmationModal from 'components/transactions/TransactionRuleConfirmationModal';
import TransactionRuleFormActionsContainer from 'components/transactions/rules/TransactionRuleFormActionsContainer';
import TransactionRuleFormCriteriaContainer from 'components/transactions/rules/TransactionRuleFormCriteriaContainer';

import usePreviewRuleQuery from 'common/lib/hooks/rules/usePreviewRuleQuery';
import { spacing, fontWeight, color, fontSize, breakPoints } from 'common/lib/theme/dynamic';
import {
  ACTION_FIELD_NAMES,
  DEFAULT_SPLIT_INFO,
  EMPTY_RULE,
  getEmptyListMessage,
  INITIAL_ENABLED_VALUES,
} from 'common/lib/transactions/Rules';
import useTransactionRulesForm from 'lib/hooks/transactions/useTransactionRulesForm';
import useModal from 'lib/hooks/useModal';

import type { UpdateTransactionRuleInput } from 'common/generated/graphql';

export const APPLY_TO_EXISTING_FIELD = 'applyToExistingTransactions';

const FormBody = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakPoints.sm}px) {
    flex-direction: row;
  }
`;

const SubmitButton = styled(FormSubmitButton)`
  width: auto;
  margin-top: 0;
  margin-left: ${spacing.default};
`;

const Footer = styled.div`
  padding: ${spacing.default} ${spacing.xlarge};
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${color.grayBackground};
`;

const FooterRight = styled(FlexContainer).attrs({ alignCenter: true })`
  margin-left: auto;
`;

const StyledCheckboxField = styled(CheckboxField)`
  font-weight: ${fontWeight.book};
  margin-left: ${spacing.default};

  && {
    margin-bottom: 0;
  }
`;

const Spinner = styled(LoadingSpinner).attrs({ $size: 14 })`
  display: inline-block;
  vertical-align: middle;
  margin-left: ${spacing.xsmall};
`;

const EmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: ${spacing.xlarge} ${spacing.xxxlarge};
`;

const TabPill = styled(Pill)`
  display: inline-block;
  font-size: ${fontSize.xsmall};
  line-height: ${fontSize.small};
  margin-left: ${spacing.xxsmall};
`;

type Props = {
  onClickDelete: () => void;
  showDeleteButton: boolean;
  title: string;
};

const TransactionRuleCard = ({ onClickDelete, showDeleteButton, title }: Props) => {
  const { values, isValid, getFieldMeta, setFieldValue } = useFormContext();
  const { value: applyToExisting } = getFieldMeta<boolean>(APPLY_TO_EXISTING_FIELD);
  const { initialValue: initialSplitTransactionsAction } =
    getFieldMeta<UpdateTransactionRuleInput['splitTransactionsAction']>('splitTransactionsAction');
  const { resetFieldValue } = useTransactionRulesForm();

  const {
    data,
    hasNextPage,
    onRequestNextPage,
    loading: loadingPreview,
  } = usePreviewRuleQuery(isValid ? (values as any) : undefined);

  const [ConfirmationModal, { open: openConfirmationModal }] = useModal();

  const { totalCount = 0, results = [] } = data ?? {};

  const emptyListMessage = getEmptyListMessage(data, applyToExisting);
  const tabCountDisplay = applyToExisting ? totalCount : 0;
  const pluralizedChanges = pluralize('change', tabCountDisplay);

  // Splits
  const shouldStartOnSplitsView =
    !isNil(initialSplitTransactionsAction) && initialSplitTransactionsAction?.splitsInfo.length > 0;
  const [isSettingSplitRule, setIsSettingSplitRule] = useState(shouldStartOnSplitsView);

  useEffect(() => {
    // Effect to synchronize correctly starting on the initial split rule view.
    // Initializing state with this value is not always consistent, especially
    // if reloading the page while editing a rule.
    if (!isSettingSplitRule && shouldStartOnSplitsView) {
      setIsSettingSplitRule(true);
    }
    // Must ignore future changes to isSettingSplitRule, since this should only happen once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldStartOnSplitsView]);

  const setUpSplitAction = useCallback(async () => {
    // Clear all other actions, even if it results in prior non-split actions being lost
    ACTION_FIELD_NAMES.forEach(async (field) => {
      await setFieldValue(field, EMPTY_RULE[field]);
    });
    await setFieldValue('splitTransactionsAction', {
      ...INITIAL_ENABLED_VALUES.splitTransactionsAction,
      splitsInfo: [{ ...DEFAULT_SPLIT_INFO }, { ...DEFAULT_SPLIT_INFO }],
    });
    setIsSettingSplitRule(true);
  }, [setFieldValue]);

  const cancelSplitAction = useCallback(async () => {
    await resetFieldValue('splitTransactionsAction');
    setIsSettingSplitRule(false);
  }, [resetFieldValue]);

  return (
    <ModalCard
      title={title}
      tabs={
        <>
          <Tab index={0}>Settings</Tab>
          <Tab index={1}>
            Preview {pluralizedChanges}{' '}
            {loadingPreview ? <Spinner /> : <TabPill>{tabCountDisplay}</TabPill>}
          </Tab>
        </>
      }
    >
      <TabPanel index={0}>
        <FormBody>
          <TransactionRuleFormCriteriaContainer />
          <TransactionRuleFormActionsContainer
            isSettingSplitAction={isSettingSplitRule}
            onSetUpSplitAction={setUpSplitAction}
            onCancelSplitAction={cancelSplitAction}
          />
        </FormBody>
      </TabPanel>
      <TabPanel index={1}>
        {emptyListMessage || loadingPreview ? (
          <EmptyList>
            {emptyListMessage ? (
              <Empty emptyTheme={EmptyTheme.OLD} title="No changes" subtitle={emptyListMessage} />
            ) : (
              <Spinner />
            )}
          </EmptyList>
        ) : (
          <PreviewTransactionChangesTable
            results={results}
            hasNextPage={hasNextPage}
            onRequestNextPage={onRequestNextPage}
          />
        )}
      </TabPanel>
      <Footer>
        {showDeleteButton && <DangerButton onClick={onClickDelete}>Delete</DangerButton>}
        <FooterRight>
          {totalCount > 0 && (
            <StyledCheckboxField name={APPLY_TO_EXISTING_FIELD} hideLabel>
              Apply {totalCount} {pluralizedChanges} to existing transactions
            </StyledCheckboxField>
          )}
          <SubmitButton
            disabled={loadingPreview || !isValid}
            size="small"
            disableWhenValuesUnchanged={false} // we need this to be able to submit the form when values are pre-filled
            onClick={(event) => {
              // Show confirmation modal if applying changes to 50 transactions or more. otherwise this will just submit regularly
              // TODO: Remove the split transactions check once we have a better way to handle bulk editing of split transactions.
              if (applyToExisting && isNil(values.splitTransactionsAction) && totalCount >= 50) {
                event.preventDefault();
                openConfirmationModal();
              }
            }}
          >
            Save
          </SubmitButton>
        </FooterRight>
      </Footer>
      <ConfirmationModal>
        <TransactionRuleConfirmationModal selectedCount={totalCount} />
      </ConfirmationModal>
    </ModalCard>
  );
};

export default TransactionRuleCard;
