import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCancelButton from 'components/lib/ui/ModalCancelButton';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';

import getUserApi from 'common/lib/api/user';
import api from 'lib/api';

const FormContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledText = styled(Text)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing.default};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  next: (params: { newEmail: string; currentPassword: string }) => void;
  goBack: () => void;
};

const EditEmailModal = ({ next, goBack }: Props) => (
  <ModalCard title="Change Email">
    <Form
      initialValues={{ new_email: '', confirm_new_email: '', current_password: '' }}
      onSubmit={async ({ new_email, current_password }) => {
        await getUserApi(api).createEmailUpdateVerification(new_email, current_password);
        next({ newEmail: new_email, currentPassword: current_password });
      }}
    >
      <FormContainer>
        <StyledText>
          Your email will not change until you&apos;ve verified the new email with a code.
        </StyledText>
        <TextField name="new_email" email label="New email" required autoComplete="new-email" />
        <TextField
          name="confirm_new_email"
          label="Confirm new email"
          sameAs="new_email"
          email
          required
          autoComplete="confirm-new-email"
        />
        <TextField
          name="current_password"
          label="Current password"
          type="password"
          required
          autoComplete="current-password"
        />
      </FormContainer>
      <CardFooter>
        <ModalCancelButton />
        <FormSubmitButton size="small">Save</FormSubmitButton>
      </CardFooter>
    </Form>
  </ModalCard>
);

export default EditEmailModal;
