import { ifTheme } from 'common/lib/theme/variables/types';

const typography = {
  fontFamily: ifTheme({
    v1: 'Graphik',
    v2: 'Oracle',
  }),
};

export default typography;
