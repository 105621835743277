export enum ParserName {
  AppleCard = 'apple_card',
  MonarchCSV = 'monarch_csv',
  MintCSV = 'mint_csv',
}

export enum UploadSessionStatus {
  Unknown = 'unknown',
  Created = 'created',
  Started = 'started',
  Completed = 'completed',
  Errored = 'errored',
}
