import * as R from 'ramda';
import React, { useContext } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { defaultButtonMixin } from 'components/lib/ui/button/DefaultButton';
import { gradientButtonMixin } from 'components/lib/ui/button/GradientButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';

import buttonSizeMixin from 'lib/styles/buttonSizeMixin';
import type { ButtonSize } from 'lib/styles/buttonSizeMixin';

export type FormSubmitButtonProps = Pick<
  React.ComponentProps<typeof AsyncButton>,
  'onClick' | 'pending'
> & {
  children?: React.ReactNode;
  disableWhenValuesUnchanged?: boolean;
  className?: string;
  disabled?: boolean;
  size?: ButtonSize;
  type?: 'button' | 'reset' | 'submit';
  variant?: 'primary' | 'default' | 'gradient';
};

export const Button = styled(AsyncButton)<{
  $variant: FormSubmitButtonProps['variant'];
  size: FormSubmitButtonProps['size'];
}>`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  ${buttonSizeMixin}
  ${({ $variant }) => {
    switch ($variant) {
      case 'primary':
        return primaryButtonMixin;
      case 'default':
        return defaultButtonMixin;
      case 'gradient':
        return gradientButtonMixin;
    }
  }};
`;

const FormSubmitButton = ({
  children,
  className,
  disableWhenValuesUnchanged = true,
  disabled,
  size = 'medium',
  pending,
  onClick,
  type = 'submit',
  variant = 'primary',
}: FormSubmitButtonProps) => {
  const { isValid, isSubmitting, initialValues, values } = useContext(FormContext);

  const isDisabled =
    disabled || !isValid || (disableWhenValuesUnchanged && R.equals(initialValues, values));

  return (
    <Button
      className={className}
      onClick={(e) => {
        // Don't call Form's handleSubmit() because type="submit" will cause <form> onSubmit to be called
        onClick?.(e);
      }}
      disabled={isDisabled}
      pending={pending ?? isSubmitting}
      type={type}
      size={size}
      $variant={variant}
    >
      {children}
    </Button>
  );
};

export default FormSubmitButton;
