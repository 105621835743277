import Papa from 'papaparse';
import * as R from 'ramda';
import React, { useState } from 'react';
import styled from 'styled-components';

import ImportMintHelpModal from 'components/accounts/ImportMintHelpModal';
import FileDropzone from 'components/lib/ui/FileDropzone';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';

import useLoading from 'common/lib/hooks/useLoading';
import typewriter from 'lib/analytics/typewriter';
import { trimEmptyRows } from 'lib/csv/utils';
import { validateMintCSV } from 'lib/csv/validation';
import useModal from 'lib/hooks/useModal';

import { MINT_TRANSACTIONS_URL } from 'common/constants/externalUrls';
import { ParserName } from 'common/constants/statements';

const DROPZONE_HEIGHT = 223;

const Root = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  text-align: center;
  max-width: 438px;
  width: 100%;
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyledFileDropzone = styled(FileDropzone)`
  width: 100%;
  margin: ${({ theme }) => theme.spacing.xxlarge} 0;
  background-color: ${({ theme }) => theme.color.grayBackground};
  padding: 0;
`;

const StyledButton = styled(AsyncButton)`
  ${primaryButtonMixin};
  width: 100%;
`;

const StyledHelpButton = styled(DefaultButton)`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  onClickNext: (file: File) => Promise<any>;
};

const ImportMintFileDropzone = ({ onClickNext }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [file, setFile] = useState<File>();

  const [HelpModal, { open: openHelpModal }] = useModal();

  const [isLoading, next] = useLoading(() => onClickNext(file!));

  return (
    <>
      <Root>
        <Title>Upload your Mint transactions CSV</Title>
        <Text>
          If you haven’t already, sign in to Mint and go to the{' '}
          <Link target="_blank" href={MINT_TRANSACTIONS_URL}>
            transactions page
          </Link>
          , scroll to the bottom of the page, and click the “Export all transactions” link. Click
          the button below for more guidance.
        </Text>

        <StyledHelpButton onClick={openHelpModal}>How to export Mint transactions</StyledHelpButton>

        <StyledFileDropzone
          acceptFileTypes={['text/csv', '.csv']}
          maxNumFiles={1}
          title="Upload your Mint CSV"
          errorMessage={errorMessage}
          files={file ? [file] : []}
          onChangeFiles={(files) => {
            const droppedFile = R.head(files);
            setErrorMessage(undefined);

            if (droppedFile) {
              // @ts-ignore parse() works with File type, but TS doesn't like it
              Papa.parse(droppedFile, {
                complete: (results) => {
                  try {
                    const parsedRows = trimEmptyRows(results.data as string[][]);
                    validateMintCSV(parsedRows);
                    setFile(droppedFile);
                  } catch (e) {
                    const { message } = e as Error;
                    setErrorMessage(message);
                  }
                },
                error: setErrorMessage,
              });
            } else {
              // Clear file
              setFile(undefined);
            }
          }}
          onDropRejected={(rejections) => {
            const [rejection] = rejections;
            typewriter.statementUploadErrored({
              parserName: ParserName.MintCSV,
              errorMessage: rejection?.errors[0]?.message,
              errorCode: rejection?.errors[0]?.message,
              filename: rejection?.file?.name,
            });
          }}
          height={DROPZONE_HEIGHT}
        />
        <StyledButton size="large" disabled={!file} pending={isLoading} onClick={next}>
          Next
        </StyledButton>
      </Root>
      <HelpModal>
        <ImportMintHelpModal />
      </HelpModal>
    </>
  );
};

export default ImportMintFileDropzone;
