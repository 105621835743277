import React, { useMemo } from 'react';
import styled from 'styled-components';

import MessageCard from 'components/assistant/MessageCard';
import PieChart from 'components/lib/charts/PieChart';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import { PieChartBlock } from 'common/lib/assistant/blocks';
import { makeBlockRenderer } from 'common/lib/assistant/blocks/render';
import { CHART_COLORS, CHART_HEIGHT_PX } from 'common/lib/assistant/constants';
import { formatCurrency } from 'common/utils/Currency';
import useTheme from 'lib/hooks/useTheme';

const Root = styled(MessageCard)`
  width: 50%;

  @media (max-width: ${({ theme }) => theme.breakPoints.xl}px) {
    width: 100%;
  }
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true, column: true })`
  height: ${CHART_HEIGHT_PX}px;
  width: 100%;
`;

const PieChartBlockRenderer = makeBlockRenderer(PieChartBlock)(({
  data: { chartData, columns },
  incomplete,
}) => {
  const theme = useTheme();

  const data = useMemo(
    () =>
      chartData.map(({ label, values }, i) => ({
        label,
        // TODO: how to handle Math.abs?
        value: Math.abs(values[0] ?? 0),
        formattedValue:
          columns[0]?.valueType === 'currency'
            ? formatCurrency(Math.abs(values[0] ?? 0))
            : undefined,
        color: theme.color[CHART_COLORS[i % CHART_COLORS.length]],
        labelColor: theme.color.text,
      })),
    [chartData, columns, theme],
  );

  return (
    <Root>
      {incomplete ? (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ) : (
        <PieChart data={data} width="100%" height={CHART_HEIGHT_PX} isAnimationActive={false} />
      )}
    </Root>
  );
});

export default PieChartBlockRenderer;
