import React from 'react';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';
import { CardWithHeavyShadow } from 'components/plan/onboarding/OnboardingCard';

const Root = styled(CardWithHeavyShadow)`
  padding: ${({ theme }) => theme.spacing.default};
  padding-left: ${({ theme }) => theme.spacing.large};
  padding-right: ${({ theme }) => theme.spacing.xlarge};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

type Props = {
  title: string;
  value: number;
  emphasis?: boolean;
};

const GroupFooter = ({ title, value, emphasis = false }: Props) => (
  <Root>
    <Text size="base" weight="book">
      {title}
    </Text>
    <CashFlowCurrency type="income" emphasis={emphasis} value={value} bold round />
  </Root>
);

export default GroupFooter;
