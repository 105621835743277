import React from 'react';
import styled from 'styled-components';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import type { Props as EmojiInputProps } from 'components/lib/form/EmojiInput';
import EmojiInput from 'components/lib/form/EmojiInput';

import fieldErrorBorderMixin from 'lib/styles/fieldErrorBorderMixin';

const Emoji = styled(EmojiInput)<{ error?: boolean }>`
  ${fieldErrorBorderMixin}
`;

const EmojiField = ({ className, onBlur, ...props }: AbstractFieldProps<EmojiInputProps>) => (
  <AbstractField {...props} className={className}>
    {({ id, value, displayErrors, setFieldValue, handleBlur }) => (
      <Emoji
        {...props}
        id={id}
        onChange={(value) => setFieldValue(props.name, value)}
        onBlur={(e) => {
          handleBlur(e);
          onBlur?.(e);
        }}
        value={value ?? ''}
        error={displayErrors.length > 0}
      />
    )}
  </AbstractField>
);

export default EmojiField;
