import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'This field is required';

// Onboarding Invite Partner
const email = Yup.string().required('');

export const invitePartnerValidationSchema = Yup.object().shape({
  email,
});

// Onboarding Demographics
const name = Yup.string().required(REQUIRED_MESSAGE);

export const demographicsValidationSchema = Yup.object().shape({
  name,
  manageWithPartner: Yup.object()
    .shape({
      value: Yup.boolean().required(),
    })
    .required(),
  manageWithProfessional: Yup.object()
    .shape({
      value: Yup.boolean().required(),
    })
    .required(),
});

/** Mobile has slightly different fields */
export const getDemographicsValidationSchemaMobile = (skipName?: boolean) =>
  Yup.object().shape({
    name: skipName ? Yup.string() : name,
    manageWithPartner: Yup.boolean().required(REQUIRED_MESSAGE),
    manageWithProfessional: Yup.boolean().required(REQUIRED_MESSAGE),
  });
