import React from 'react';
import styled from 'styled-components';

/**
 * There's an issue with emoji rendering on non-retina screens which cause them
 * to not have any spacing related to the text. This component fixes that by
 * adding a small margin to the right only for non-retina screens.
 */

type Props = {
  children: React.ReactNode;
};

const Root = styled.span`
  display: inline-block;
  margin-right: 5px;

  /* Safari-only hack to remove margin since it does it automatically */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      margin-right: auto;
    }
  }
`;

const Emoji = ({ children }: Props) => <Root>{children}</Root>;

export default Emoji;
