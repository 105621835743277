import styled from 'styled-components';

const ActionCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
`;

export default ActionCard;
