import React, { useContext } from 'react';
import styled from 'styled-components';

import DraggableContext from 'components/lib/dnd/DraggableContext';
import DragDots from 'components/lib/ui/DragDots';
import Drawer from 'components/lib/ui/Drawer';
import ProgressBar from 'components/lib/ui/ProgressBar';
import { Row as TableRow, Cell as TableCell } from 'components/lib/ui/Table';
import Currency from 'components/lib/ui/currency/CashFlowCurrency';

export const IconCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 24px;
  padding: ${({ theme }) => theme.spacing.default} 0;
  position: relative;
  text-align: center;
  margin-right: ${({ theme }) => theme.spacing.small};
`;

export const TitleCell = styled(TableCell)`
  padding: 0;
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;

export const RightCell = styled(TableCell).attrs({ align: 'right' })`
  padding-top: 22px;
  padding-bottom: 22px;
  width: 18%;
  min-width: 90px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const DragHandle = styled(DragDots)`
  display: inline-block;
  padding: 5px;
`;

export const DragCell = styled(TableCell)`
  flex-grow: 0;
  transition: ${({ theme }) => theme.transition.default};
  padding-left: 0;
  padding-right: 0;
  width: ${({ theme }) => theme.spacing.large};
  min-width: ${({ theme }) => theme.spacing.large};
  text-align: center;
`;

export const Row = ({ children, ...rest }: React.ComponentProps<typeof TableRow>) => (
  <TableRow {...rest}>
    <DragCell />
    {children}
  </TableRow>
);

const StyledRow = styled(TableRow)<{ isDragging: boolean }>`
  ${DragHandle} {
    opacity: ${({ isDragging }) => (isDragging ? 1 : 0)};
  }

  :hover ${DragHandle} {
    opacity: 1;
  }
`;

export const DraggableRow = ({ children, ...props }: React.ComponentProps<typeof TableRow>) => {
  const { dragHandleProps, state } = useContext(DraggableContext) ?? {};
  const { isDragging = false } = state ?? {};

  return (
    <StyledRow {...props} isDragging={isDragging}>
      <DragCell {...dragHandleProps}>{dragHandleProps && <DragHandle />}</DragCell>
      {children}
    </StyledRow>
  );
};

export const ChevronCell = styled(IconCell)`
  cursor: pointer;
`;

export const StyledTitleCell = styled(TitleCell)`
  padding: 10px 0;
`;

export const AmountCell = styled(RightCell)`
  padding-top: ${({ theme }) => theme.spacing.xsmall};
  padding-bottom: ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  display: flex;
  flex-direction: column;
  user-select: none;
`;

export const AmountHeader = styled.span`
  color: ${({ theme }) => theme.color.textLight};
`;

export const StyledCurrency = styled(Currency)`
  font-size: ${({ theme }) => theme.fontSize.base};
`;

export const HeadingStyledCurrency = styled(Currency)`
  font-size: ${({ theme }) => theme.fontSize.large};
`;

export const RoundDrawer = styled(Drawer)`
  border-radius: 0 0 ${({ theme }) => theme.radius.medium} ${({ theme }) => theme.radius.medium};
`;

export const FlatProgressBar = styled(ProgressBar)`
  height: 4px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const RootDraggable = styled(DraggableRow)`
  cursor: pointer;
`;

export const RootNotDraggable = styled(Row)`
  cursor: pointer;
`;
