import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getUserIsLoggedIn } from 'common/state/user/selectors';

const useRedirectIfLoggedIn = (redirectTo?: string) => {
  const isLoggedIn = useSelector(getUserIsLoggedIn);
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      history.replace(redirectTo ?? '/');
    }
  }, [isLoggedIn, redirectTo]);
};

export default useRedirectIfLoggedIn;
