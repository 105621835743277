// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 3385635831
import { createAction } from 'typesafe-actions';

import type { SankeyGroupMode } from 'lib/cashFlow/sankey';
import type { ReportsFilters, ReportsTab, ReportsChart } from 'state/reports/types';

import type { ReportsGroupByEntity, TransactionOrdering } from 'common/generated/graphql';

export const patchReportsFilters = createAction('REPORTS/PATCH_REPORTS_FILTERS')<
  Partial<ReportsFilters>
>();

export const setChartTypeForTab = createAction('REPORTS/SET_CHART_TYPE_FOR_TAB')<{
  tab: ReportsTab;
  chartType: ReportsChart;
}>();

export const setGroupByEntity = createAction('REPORTS/SET_GROUP_BY_ENTITY')<ReportsGroupByEntity>();

export const setReportsFilters = createAction(
  'REPORTS/SET_REPORTS_FILTERS',
)<ReportsFilters | null>();

export const setReportsSankeyGroupMode = createAction(
  'REPORTS/SET_REPORTS_SANKEY_GROUP_MODE',
)<SankeyGroupMode>();

export const setReportsTransactionsSortBy = createAction(
  'REPORTS/SET_REPORTS_TRANSACTIONS_SORT_BY',
)<TransactionOrdering>();

export const setViewModeForTab = createAction('REPORTS/SET_VIEW_MODE_FOR_TAB')<{
  tab: ReportsTab;
  view: string;
}>();
