import React from 'react';
import styled from 'styled-components';

import AddAccountsInstitutionSyncing from 'components/accounts/AddAccountsInstitutionSyncing';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';

import { MINT_LOGO_BASE_64 } from 'common/constants/accounts';

const STEPS = [
  'Uploading CSV files',
  'Mapping the accounts',
  'Processing overlap',
  'Mapping the categories',
  'Processing rules',
  'Wrapping everything up',
];

const TITLE = 'Importing transactions... this may take a minute';

const Container = styled(FlexContainer).attrs({ center: true, column: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const MAX_STEP_ANIMATION_DURATION_MS = 4000;
const MIN_STEP_ANIMATION_DURATION_MS = 250;

type Props = {
  steps?: string[];
  title?: string;
  entriesToUpload?: number;
  onAnimationEnd?: () => void;
};

const ImportMintLoadingModal = ({
  steps = STEPS,
  title = TITLE,
  entriesToUpload,
  onAnimationEnd,
}: Props) => {
  // calculate the animation duration based on the total days of history
  const stepAnimationDurationMs = entriesToUpload
    ? Math.min(
        MAX_STEP_ANIMATION_DURATION_MS,
        Math.max(MIN_STEP_ANIMATION_DURATION_MS, entriesToUpload / 10),
      )
    : MIN_STEP_ANIMATION_DURATION_MS;

  return (
    <ModalCard title={title} hideCloseButton>
      <Container>
        <AddAccountsInstitutionSyncing
          institutionLogo={MINT_LOGO_BASE_64}
          onAnimationEnd={onAnimationEnd}
          loadingSteps={steps}
          stepAnimationDurationMs={stepAnimationDurationMs}
        />
      </Container>
    </ModalCard>
  );
};

export default ImportMintLoadingModal;
