import Papa from 'papaparse';
import * as R from 'ramda';

import type { Block } from 'common/lib/assistant/blocks/types';
import { parseNumber } from 'common/utils/Number';

export const BLOCKS: Record<string, Block<any>> = {};

const makeBlock = <T = any>(block: Block<T>) => {
  BLOCKS[block.type] = block;
  return block;
};

export const TextBlock = makeBlock<{ content: string }>({
  type: 'text',
  parse: (content) => ({ content }),
});

const makeEntityBlock = (type: string) =>
  makeBlock<{ ids: string[]; rows: Record<string, any>[] }>({
    type,
    parse: (content) => {
      // this regex matches first entry in each line of csv format that is more than 4 characters
      // the ID will always be the first entry in the line
      const matches = [...content.matchAll(/^(\d{12,}?)[,;]/gm)];
      const ids = matches.map((match) => match[1]);

      let rows: Record<string, any>[] = [];
      try {
        const data = Papa.parse<Record<string, any>>(content, {
          header: true,
          delimiter: ';',
        });
        rows = data.data;
      } catch (e) {
        // ignore
      }

      return { ids, rows };
    },
  });

export const TransactionsBlock = makeEntityBlock('entity:transactions');
export const GoalsBlock = makeEntityBlock('entity:goals');
export const AccountsBlock = makeEntityBlock('entity:accounts');

type ValueType = 'currency' | 'number' | undefined;

const parseValue = (valueString: string | undefined): [number | undefined, ValueType] => {
  if (!valueString) {
    return [undefined, undefined];
  }

  const value = parseNumber(valueString); // undefined if invalid number

  let valueType: ValueType = valueString?.includes('$') ? 'currency' : 'number';
  if (value === undefined) {
    valueType = undefined;
  }

  return [value, valueType];
};

const makeChartBlock = (type: string) =>
  makeBlock<{
    chartData: {
      label: string;
      values: (number | undefined)[];
    }[];
    columns: {
      label: string;
      valueType: ValueType;
    }[];
  }>({
    type,
    parse: (content, incomplete) => {
      if (incomplete) {
        // streaming charts just show a loading spinner until the block is complete
        return { chartData: [], columns: [] };
      }
      const data = Papa.parse<string[]>(content, {
        delimiter: ';',
      });
      const { data: rows } = data;

      const columns = R.tail(rows[0]).map((label, i) => {
        const [, valueType] = parseValue(rows[1][i + 1]);
        return { label, valueType };
      });

      const chartData = R.tail(rows) // first row is header
        .map(([label, ...valueStrings]) => {
          const values = valueStrings.map((valueString, i) => {
            const [value] = parseValue(valueString);
            return value;
          });

          return { label, values };
        });

      return { chartData, columns };
    },
  });

export const PieChartBlock = makeChartBlock('chart:pie');
export const BarChartBlock = makeChartBlock('chart:bar');
export const LineChartBlock = makeChartBlock('chart:line');

export const MultipleChoiceBlock = makeBlock<{ choices: string[] }>({
  type: 'question:multiple-choice',
  parse: (content) => {
    const choices = content.trim().split('\n');
    return {
      choices,
    };
  },
});
