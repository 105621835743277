import * as R from 'ramda';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import type { SyntheticEvent, KeyboardEvent } from 'react';
import useTypewriter from 'react-typewriter-hook';
import styled from 'styled-components';

import type { SearchResultType } from 'components/globalSearch/types';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import fieldStyleMixin from 'lib/styles/fieldStyleMixin';

import type { Web_GetGlobalSearch_globalSearch_results } from 'common/generated/graphQlTypes/Web_GetGlobalSearch';

const SEARCH_ICON_SIZE_PX = 24;

const StyledInput = styled.input`
  ${({ theme }) => fieldStyleMixin({ theme, dynamic: false })};
  border: none;
  flex-grow: 1;
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  ::placeholder {
    color: ${({ theme }) => theme.color.textLight};
  }
  :focus {
    outline: none;
  }
`;

const Header = styled(FlexContainer)`
  min-height: 64px;
  font-size: ${({ theme }) => theme.fontSize.xxlarge};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.large};
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.textLight};
  margin-right: ${({ theme }) => theme.spacing.xxsmall};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const PLACEHOLDER_COMPLEMENTS = [
  'merchants...',
  'categories...',
  'accounts...',
  'goals...',
  'anything...',
];

const PLACEHOLDER_SWITCH_INTERVAL_MS = 4000;

type Props = {
  results?: Web_GetGlobalSearch_globalSearch_results[];
  onSelectResult: (id: string, type: SearchResultType) => void;
  isLoading: boolean;
  searchInputValue: string;
  selectedItemIdx: number;
  setSelectedItemIdx: (idx: number) => void;
  setSearchInputValue: (value: string) => void;
};

const SearchInput = ({
  results,
  onSelectResult,
  isLoading,
  searchInputValue,
  selectedItemIdx,
  setSelectedItemIdx,
  setSearchInputValue,
}: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const inputElement = useRef<HTMLInputElement>(null);
  const intervalRef = useRef<number | undefined>();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex((current) => (current + 1) % PLACEHOLDER_COMPLEMENTS.length);
    }, PLACEHOLDER_SWITCH_INTERVAL_MS);

    return () => clearInterval(intervalRef.current);
  }, []);

  const typedPlaceholder = useTypewriter(PLACEHOLDER_COMPLEMENTS[currentIndex]);

  const placeholder = `Search for ${typedPlaceholder}`;

  useEffect(() => {
    inputElement.current?.focus();
  }, []);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      if (!results?.length) {
        return;
      }

      switch (event.key) {
        case 'Enter': {
          const selected = results[selectedItemIdx];
          onSelectResult(selected.id, selected.type as SearchResultType);
          event.preventDefault();
          break;
        }
        case 'ArrowDown': {
          const newSelectedItemIdx = R.clamp(0, results.length - 1, selectedItemIdx + 1);
          setSelectedItemIdx(newSelectedItemIdx);
          event.preventDefault();
          break;
        }
        case 'ArrowUp': {
          const newSelectedItemIdx = R.clamp(0, results.length - 1, selectedItemIdx - 1);
          setSelectedItemIdx(newSelectedItemIdx);
          event.preventDefault();
          break;
        }
      }
    },
    [onSelectResult, setSelectedItemIdx, selectedItemIdx, results],
  );

  const onGlobalSearchClick = () => {
    inputElement.current?.focus();
  };

  return (
    <Header alignCenter justifyBetween onClick={onGlobalSearchClick}>
      <StyledIcon name="search" size={SEARCH_ICON_SIZE_PX} />

      <StyledInput
        ref={inputElement}
        placeholder={placeholder || ''}
        className="mousetrap" // allow mousetrap keyboard events
        name="search"
        value={searchInputValue}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
          const target = e.target as HTMLInputElement;
          setSearchInputValue(target.value);
        }}
        onKeyDown={onKeyDown}
      />

      {isLoading && <LoadingSpinner $size={SEARCH_ICON_SIZE_PX} />}
    </Header>
  );
};

export default SearchInput;
