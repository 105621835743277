import * as React from 'react';

import noop from 'common/utils/noop';

import type { Web_GetTransactionsListQueryVariables } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

export type AutoFocusOptions = { notes: boolean; tags: boolean };

export type TransactionsListContextType = {
  /** Refetch entire list through all paginations (0 -> transaction count) */
  refetchAll: () => Promise<unknown>;
  filters?: Partial<TransactionFilters>;
  autoFocus?: AutoFocusOptions;
  setAutoFocus?: (options: Partial<AutoFocusOptions>) => void;
  currentRuleCount?: number;
  queryVariables?: Partial<Web_GetTransactionsListQueryVariables>;
  showAccountColumn?: boolean;
};

const TransactionsListContext = React.createContext<TransactionsListContextType | null>(null);

export const useTransactionListContext = () => {
  const context = React.useContext(TransactionsListContext);

  if (!context) {
    // we want to be able to use TransactionDrawer outside of TransactionsList
    return { refetchAll: noop } as TransactionsListContextType;
  }

  return context;
};

export default TransactionsListContext;
