import { yupToFormErrors } from 'formik';
import * as R from 'ramda';
import * as Yup from 'yup';

import { isIncome } from 'common/utils/formatTransactionAmount';

import type {
  Common_TransactionSplitQuery,
  Maybe,
  TransactionSplitInputData,
  UpdateTransactionSplitMutationInput,
} from 'common/generated/graphql';
import { SplitAmountType } from 'common/generated/graphql';

export type Split = TransactionSplitInputData;
export type BaseSplit = Omit<Split, 'id' | 'notes' | 'date'>;
export type BaseSplitInput<T extends BaseSplit = BaseSplit> = { splitData: T[] };
export type FormValues<T extends BaseSplitInput = UpdateTransactionSplitMutationInput> = T;

const HALF_OF_ONE_CENT = 0.005;

export const calculateAmountLeftToSplit = (splitAmount: number, totalAmount: number) => {
  const amountLeft = totalAmount - splitAmount;
  if (Math.abs(amountLeft) < HALF_OF_ONE_CENT) {
    // Solve float issues when comparing with 0
    return 0;
  }
  return amountLeft;
};

export const getSplitsAmountTotal = <T extends { amount: Maybe<number> }>(splits: T[]) =>
  splits.reduce((acc, split) => acc + (split.amount ?? 0), 0);

export const getAmountLeftToSplit = <T extends { amount: Maybe<number> }>(
  splits: T[],
  totalAmount: number,
) => calculateAmountLeftToSplit(getSplitsAmountTotal(splits), totalAmount);

export const invertSplitAmounts = (values: FormValues): FormValues =>
  R.evolve(
    {
      splitData: R.map<Split, Split>((split) =>
        R.evolve(
          {
            amount: (a) => a && R.negate(a),
          },
          split,
        ),
      ),
    },
    values,
  );

export const invertAmounts = <T extends { amount: Maybe<number> }>(splits: T[]) =>
  splits.map((split) =>
    R.evolve(
      {
        amount: (a) => a && R.negate(a),
      },
      split,
    ),
  );

export const getDefaultSplitDataValues = (
  originalTransaction: Common_TransactionSplitQuery['getTransaction'],
): TransactionSplitInputData => {
  const defaultSplit = {
    merchantName: originalTransaction?.merchant?.name ?? '',
    reviewStatus: undefined,
    needsReviewByUserId: undefined,
    hideFromReports: false,
    amount: undefined,
    categoryId: undefined,
    date: undefined,
    goalId: undefined,
    notes: undefined,
    tags: undefined,
    id: undefined,
    needsReview: undefined, // Deprecated. Adjust reviewStatus instead.
  };

  return defaultSplit;
};

export const getInitialFormValues = (
  originalTransaction: Common_TransactionSplitQuery['getTransaction'],
  defaultSplits: Split[] = [],
): FormValues => {
  const splitTransactions = originalTransaction?.splitTransactions ?? [];

  const splitData = splitTransactions.length
    ? // eslint-disable-next-line fp/no-mutating-methods
      splitTransactions
        .map(
          ({
            id,
            category,
            merchant,
            amount,
            goal,
            hideFromReports,
            reviewStatus,
            needsReviewByUser,
            tags,
          }) => ({
            id,
            categoryId: category.id,
            merchantName: merchant.name,
            amount,
            goalId: goal?.id,
            hideFromReports,
            reviewStatus,
            needsReviewByUserId: needsReviewByUser?.id,
            tags: tags?.map((tag) => tag.id) ?? [],
          }),
        )
        .reverse() // BE reverses the order
    : defaultSplits;

  let formValues: FormValues = {
    transactionId: originalTransaction?.id ?? '',
    splitData: splitData as TransactionSplitInputData[],
  };

  if (!isIncome(originalTransaction?.amount ?? 0)) {
    formValues = invertSplitAmounts(formValues);
  }

  return formValues;
};

export const getIsSplitByAbsoluteAmount = (amountType: string) =>
  amountType === SplitAmountType.ABSOLUTE;

export const getIsSplitByPercentage = (amountType: string) =>
  amountType === SplitAmountType.PERCENTAGE;

const formValidationSchema = Yup.object().shape({
  splitData: Yup.array()
    .of(
      Yup.object().shape({
        merchantName: Yup.string().label('Merchant').required(),
        categoryId: Yup.string().label('Category').required(),
        amount: Yup.mixed().label('Amount').required(),
      }),
    )
    .test(
      'total-amount',
      'Your split must equal the original transaction amount',
      function validateTotalAmount(splits: BaseSplit[]) {
        if (splits.length === 0) {
          // Empty splits should be valid
          return true;
        }
        const { totalAmount } = (this.options.context as { totalAmount?: number }) ?? {};
        if (R.isNil(totalAmount)) {
          return false;
        }
        return getAmountLeftToSplit(splits, totalAmount) === 0;
      },
    )
    .test(
      'required-splits',
      'You must add at least two splits',
      (splits: BaseSplit[]) => splits.length !== 1,
    ),
});

export const validateFormValues =
  <T extends BaseSplitInput>(totalAmount: number) =>
  async (values: FormValues<T>) => {
    try {
      await formValidationSchema.validate(values, {
        context: { totalAmount },
        abortEarly: false,
      });
      return {}; // No errors
    } catch (error) {
      const fieldErrors = yupToFormErrors(error);
      return fieldErrors;
    }
  };
