import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import type { KeyOfThemeProp } from 'common/types/Styles';

const Root = styled(FlexContainer)``;

type Props = {
  padding?: KeyOfThemeProp<'spacing'>;
  spinnerSize?: number;
};

/**
 * Container that displays a loading spinner centered within it.
 */
const LoadingSpinnerContainer: React.FC<Props> = ({ padding, spinnerSize }) => (
  <Root padding={padding} center>
    <LoadingSpinner $size={spinnerSize} />
  </Root>
);

export default LoadingSpinnerContainer;
