import React from 'react';
import styled from 'styled-components';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Card from 'components/lib/ui/Card';
import Flex from 'components/lib/ui/Flex';

import { color, fontSize, spacing } from 'common/lib/theme/dynamic';
import { formatThousands } from 'common/utils/Number';
import formatTransactionAmount from 'common/utils/formatTransactionAmount';

import { gql } from 'common/generated/gql';
import type { TransactionsSummaryFieldsFragment } from 'common/generated/graphql';

const CardItem = styled(Flex).attrs({ justifyBetween: true })`
  font-size: ${fontSize.base};
  color: ${color.textLight};

  :not(:last-child) {
    margin-bottom: ${spacing.large};
  }
`;

export const CardInner = styled.div`
  padding: ${spacing.xlarge};

  :not(:last-child) {
    border-bottom: 1px solid ${color.grayBackground};
  }
`;

type ValueTextProps = {
  isGreen?: boolean;
};

const ValueText = styled.span<ValueTextProps>`
  font-size: ${fontSize.base};
  color: ${({ theme, isGreen }) => (isGreen ? theme.color.green : theme.color.text)};
`;

type Props = {
  transactionsSummary: Partial<TransactionsSummaryFieldsFragment>;
};

const AmountRow = ({ text, value }: { text: string; value: number }) => (
  <CardItem>
    <span>{text}</span>
    <ValueText isGreen={value > 0} {...sensitiveClassProps}>
      {formatTransactionAmount(value)}
    </ValueText>
  </CardItem>
);

export const Row = ({
  text,
  value,
}: {
  text: string;
  value: string | number | React.ReactNode;
}) => (
  <CardItem>
    <span>{text}</span>
    <ValueText>{value}</ValueText>
  </CardItem>
);

const FilteredCashFlowSummaryCard = ({ transactionsSummary: { count, avg, sum, max } }: Props) => (
  <Card title="Summary">
    <CardInner>
      {!!count && <Row text="Total Transactions" value={formatThousands(count)} />}
      {!!avg && <AmountRow text="Average Transaction" value={avg} />}
      {!!max && <AmountRow text="Largest Transaction" value={max} />}
      {!!sum && <AmountRow text="Total Amount" value={sum} />}
    </CardInner>
  </Card>
);

FilteredCashFlowSummaryCard.fragments = {
  FilteredCashFlowSummaryFields: gql(`
    fragment FilteredCashFlowSummaryFields on TransactionsSummary {
      count
      max
      avg
      sum
    }
  `),
};

export default FilteredCashFlowSummaryCard;
