import React from 'react';

import ExpandMoreIcon from 'components/lib/ui/ExpandMoreIcon';
import type { AbstractButtonProps } from 'components/lib/ui/button/AbstractButton';
import IconButton from 'components/lib/ui/button/IconButton';

type Props = AbstractButtonProps & {
  expanded: boolean;
};

const ExpandMoreIconButton = ({ expanded, ...props }: Props) => (
  <IconButton
    size="xsmall"
    iconComponent={<ExpandMoreIcon size={16} rotateDeg="-90" expanded={expanded} />}
    {...props}
  />
);

export default ExpandMoreIconButton;
