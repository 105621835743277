import { isFunction } from 'ramda-adjunct';
import React, { useMemo, useState, useCallback } from 'react';
import {
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from 'recharts';
import styled from 'styled-components';

import CartesianGrid from 'components/lib/charts/CartesianGrid';
import ChartTooltip from 'components/lib/charts/ChartTooltip';
import type { TooltipComponentProps } from 'components/lib/charts/ChartTooltip';
import ChartTooltipDataRow from 'components/lib/charts/ChartTooltipDataRow';
import Dot from 'components/lib/ui/Dot';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { transition } from 'common/lib/theme/dynamic';
import useTheme from 'lib/hooks/useTheme';

const DEFAULT_TICK_MARGIN_PX = 8;

const Root = styled.div`
  width: 100%;

  .recharts-rectangle {
    transition: ${transition.default};
  }

  .recharts-tooltip-wrapper {
    /** We can't adjust the index by just editing our tooltip */
    z-index: 1; /* stylelint-disable-line plugin/no-z-index */
  }
`;

const LegendContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.large};
  text-align: center;
  line-height: 1.8;

  > * {
    display: inline-flex;

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.large};
    }
  }
`;

const TooltipContent =
  (bars: BarInfo[]): TooltipComponentProps<string, string, Datum> =>
  ({ payload, active }) => {
    const { label, ...values } = payload[0]?.payload ?? {};

    return (
      <ChartTooltip active={active} header={label}>
        {bars.map(({ dataKey, color, label, valueFormatter }, i) => (
          <ChartTooltipDataRow
            key={i}
            dotFillColor={color}
            label={label}
            value={valueFormatter ? valueFormatter(values[dataKey]) : values[dataKey]}
          />
        ))}
      </ChartTooltip>
    );
  };

export type Datum = {
  label: string;
  [key: string]: any;
};

export type BarInfo = {
  label: string;
  color: string;
  dataKey: string;
  valueFormatter?: (value: number) => string;
};

type Props<T extends Datum = Datum> = {
  data: T[];
  bars: BarInfo[];
  width?: string | number;
  height?: string | number;
  isAnimationActive?: boolean;
  withLegend?: boolean;
  withCartesianGrid?: boolean;
  withHoverLegendEffect?: boolean;
  tooltipComponent?: TooltipComponentProps<string, string, T>;
  margin?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  yAxisTickFormatter?: (value: number) => string;
};

/**
 * Simple bar chart with option to include multiple bars per x axis item, similar
 * to this recharts example:
 *
 * https://recharts.org/en-US/examples/SimpleBarChart
 */
const SimpleBarChart = ({
  data,
  bars,
  width,
  height,
  yAxisTickFormatter = bars[0]?.valueFormatter,
  isAnimationActive,
  withLegend,
  withCartesianGrid,
  withHoverLegendEffect,
  tooltipComponent,
  margin = {},
}: Props) => {
  const [hoveredLegendDataKey, setHoveredLegendDataKey] = useState<
    BarInfo['dataKey'] | undefined
  >();
  const theme = useTheme();

  const DEFAULT_TICK_STYLE = useMemo(
    () => ({
      fontSize: theme.fontSize.xsmall,
      fill: theme.color.textLight,
      fontWeight: parseInt(theme.fontWeight.medium, 10),
    }),
    [theme],
  );

  const handleLegendItemMouseEnter = useCallback(
    (dataKey: BarInfo['dataKey']) => {
      if (!withHoverLegendEffect) {
        return;
      }
      setHoveredLegendDataKey(dataKey);
    },
    [withHoverLegendEffect],
  );

  const handleLegendItemMouseLeave = useCallback(() => {
    if (!withHoverLegendEffect) {
      return;
    }

    setHoveredLegendDataKey(undefined);
  }, [withHoverLegendEffect]);

  const getOpacityForBar = useCallback(
    (dataKey: BarInfo['dataKey']) => {
      if (!withHoverLegendEffect || hoveredLegendDataKey === undefined) {
        return 1;
      }

      return hoveredLegendDataKey === dataKey ? 1 : 0.2;
    },
    [withHoverLegendEffect, hoveredLegendDataKey],
  );

  return (
    <Root>
      <ResponsiveContainer width={width} height={height}>
        <RechartsBarChart data={data} margin={margin}>
          {withCartesianGrid && <CartesianGrid dashed />}
          <XAxis
            dataKey="label"
            tick={{
              ...DEFAULT_TICK_STYLE,
              transform: `translate(0, ${DEFAULT_TICK_MARGIN_PX})`,
            }}
            stroke={theme.color.gray}
          />
          <YAxis
            tick={{
              ...DEFAULT_TICK_STYLE,
              transform: `translate(${DEFAULT_TICK_MARGIN_PX * -1}, 0)`,
            }}
            stroke={theme.color.gray}
            tickFormatter={yAxisTickFormatter}
          />
          {bars.map(({ dataKey, color }, i) => (
            <Bar
              key={i}
              dataKey={dataKey}
              fill={color}
              isAnimationActive={isAnimationActive}
              fillOpacity={getOpacityForBar(dataKey)}
            />
          ))}
          <Tooltip
            cursor={false}
            content={tooltipComponent ?? TooltipContent(bars)}
            useTranslate3d
          />
          {withLegend && (
            <Legend
              content={() => (
                <LegendContainer>
                  {bars.map(({ label, color, dataKey }) => (
                    <FlexContainer
                      key={label}
                      gap="xsmall"
                      onMouseEnter={() => handleLegendItemMouseEnter(dataKey)}
                      onMouseLeave={handleLegendItemMouseLeave}
                      center
                    >
                      <Dot size={12} color={isFunction(color) ? theme.color.gray : color} />
                      <Text size="xsmall" color="textLight" weight="medium" clampLines={1}>
                        {label}
                      </Text>
                    </FlexContainer>
                  ))}
                </LegendContainer>
              )}
            />
          )}
        </RechartsBarChart>
      </ResponsiveContainer>
    </Root>
  );
};

export default React.memo(SimpleBarChart);
