import pluralize from 'pluralize';

export const makeRelationSentence = (transactionCount: number, ruleCount: number) => {
  let sentence = '';

  if (transactionCount > 0) {
    sentence += [
      pluralize('is', transactionCount),
      transactionCount,
      pluralize('transaction', transactionCount),
    ].join(' ');
  }

  if (ruleCount > 0) {
    sentence += [
      transactionCount > 0 ? ' and' : pluralize('is', ruleCount),
      ruleCount,
      pluralize('rule', ruleCount),
    ].join(' ');
  }

  return sentence;
};
