import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setDemoHouseholdModalVisible as _setModalVisible } from 'actions';
import useSelectors from 'lib/hooks/useSelectors';
import { getIsDemo, getIsDemoHouseholdModalVisible, getIsTemporaryDemo } from 'selectors';

const useDemoHousehold = () => {
  const dispatch = useDispatch();
  const [isDemoHousehold, isTemporaryDemo, isModalVisible] = useSelectors([
    getIsDemo,
    getIsTemporaryDemo,
    getIsDemoHouseholdModalVisible,
  ]);
  const shouldShowBanner = isDemoHousehold && isTemporaryDemo;

  const setDemoHouseholdModalVisible = useCallback(
    (state?: boolean) => dispatch(_setModalVisible(state || !isModalVisible)),
    [isModalVisible, dispatch],
  );

  const blockActionForDemoHousehold = useCallback(
    (action: () => void, showModal = true) => {
      if (isDemoHousehold) {
        if (showModal) {
          setDemoHouseholdModalVisible(true);
        }
        return;
      }

      action();
    },
    [isDemoHousehold, setDemoHouseholdModalVisible],
  );

  return {
    isDemoHousehold,
    isTemporaryDemo,
    isDemoHouseholdModalVisible: isModalVisible,
    setDemoHouseholdModalVisible,
    blockActionForDemoHousehold,
    shouldShowBanner,
  } as const;
};

export default useDemoHousehold;
