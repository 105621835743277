import React from 'react';
import styled from 'styled-components';

import AdviceAvatar from 'components/advice/AdviceAvatar';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { gql } from 'common/generated/gql';
import type { QuestionHeaderFieldsFragment } from 'common/generated/graphql';

const Root = styled(FlexContainer)`
  text-align: center;
`;

const Title = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  question: QuestionHeaderFieldsFragment;
};

const QuestionHeader = ({ question: { title, description } }: Props) => (
  <Root column alignCenter>
    <AdviceAvatar />
    <Title size="xlarge" weight="medium">
      {title}
    </Title>
    <Text>{description}</Text>
  </Root>
);

QuestionHeader.fragments = {
  QuestionHeaderFields: gql(`
    fragment QuestionHeaderFields on ProfileQuestion {
      title
      description
    }
  `),
};

export default QuestionHeader;
