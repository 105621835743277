import { motion, AnimatePresence } from 'framer-motion';
import React, { useMemo, useEffect, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { SIDE_DRAWER_ROOT_NODE_ID } from 'components/App';
import type { NavigationHeaderProps } from 'components/lib/ui/NavigationHeader';
import NavigationHeader from 'components/lib/ui/NavigationHeader';
import Scroll from 'components/lib/ui/Scroll';
import Shield from 'components/lib/ui/Shield';

import { setIsSideDrawerVisible } from 'actions';
import useInitialValue from 'common/lib/hooks/useInitialValue';
import { color } from 'common/lib/theme/dynamic';
import SideDrawerContext from 'lib/contexts/SideDrawerContext';
import { getIsSideDrawerVisible } from 'selectors';

import { DEFAULT_MOTION_TRANSITION } from 'constants/animation';

type Props = Omit<NavigationHeaderProps, 'onClickClose'> & {
  children: React.ReactNode;
  left?: React.ReactNode;
  onClose: () => void;
  className?: string;
};

export const ANIMATION_DURATION_SECONDS = 0.2;
export const DRAWER_WIDTH_PX = 560;

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
`;

const DrawerContainer = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
  position: relative;
`;

const DrawerRoot = styled(Scroll)`
  width: ${DRAWER_WIDTH_PX}px;
  background-color: ${color.white};
`;

export const SideDrawerNavigationHeader = styled(NavigationHeader)`
  padding: ${({ theme }) => theme.spacing.large} ${({ theme }) => theme.spacing.xlarge};
  padding-bottom: 0;
  background: ${color.white};
`;

const StyledMotionDiv = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

const SideDrawer = ({ onClose, children, left, className, ...headerProps }: Props) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const open = useSelector(getIsSideDrawerVisible);
  const initiallyOpen = useInitialValue(open);
  const close = useCallback(() => dispatch(setIsSideDrawerVisible(false)), [dispatch]);

  useEffect(() => {
    if (!initiallyOpen) {
      // Open on mount if not already open
      dispatch(setIsSideDrawerVisible(true));
    }
  }, []);

  useEffect(() => {
    if (open) {
      // Focus the drawer when it opens to enable keyboard navigation
      rootRef.current?.focus();
    }
  }, [open]);

  const context = useMemo(() => ({ close }), [close]);

  return createPortal(
    <Root tabIndex={0} ref={rootRef} className={className}>
      <SideDrawerContext.Provider value={context}>
        <AnimatePresence>
          {open && (
            <StyledMotionDiv
              transition={DEFAULT_MOTION_TRANSITION}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="shield"
            >
              <Shield onClick={close} />
              {left}
            </StyledMotionDiv>
          )}
        </AnimatePresence>
        <DrawerContainer>
          <AnimatePresence onExitComplete={onClose}>
            {open && (
              <StyledMotionDiv
                transition={DEFAULT_MOTION_TRANSITION}
                initial={{ x: DRAWER_WIDTH_PX }}
                animate={{ x: 0 }}
                exit={{ x: DRAWER_WIDTH_PX }}
                key="drawer"
              >
                <SideDrawerNavigationHeader {...headerProps} onClickClose={close} />
                <DrawerRoot>{children}</DrawerRoot>
              </StyledMotionDiv>
            )}
          </AnimatePresence>
        </DrawerContainer>
      </SideDrawerContext.Provider>
    </Root>,
    document.getElementById(SIDE_DRAWER_ROOT_NODE_ID) as HTMLElement,
  );
};

export default SideDrawer;
