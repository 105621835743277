import { DateTime, Duration } from 'luxon';
import * as R from 'ramda';

import { BudgetRolloverFrequency, BudgetVariability } from 'common/generated/graphql';

const NUM_MONTHS_OPTIONS = 60;

export const getRolloverStartMonthOptions = () => {
  const startDate = DateTime.local().startOf('month');

  return R.range(0, NUM_MONTHS_OPTIONS)
    .map((i) => startDate.minus({ months: i }))
    .map((date) => ({
      value: date.toISODate(),
      label: date.toFormat('MMMM yyyy'),
    }));
};

export const isDateWithinRolloverPeriod = (
  date: DateTime,
  rolloverPeriod: { startMonth: string; endMonth: string | null | undefined },
) => {
  const startDate = DateTime.fromISO(rolloverPeriod.startMonth);
  if (rolloverPeriod.endMonth) {
    const endDate = DateTime.fromISO(rolloverPeriod.endMonth);
    return date >= startDate && date < endDate;
  } else {
    return date >= startDate;
  }
};

export const getDefaultRolloverFrequency = (budgetVariability: BudgetVariability) =>
  budgetVariability !== BudgetVariability.NON_MONTHLY
    ? BudgetRolloverFrequency.MONTHLY
    : BudgetRolloverFrequency.VARIABLE;

export const getRolloverMonthsFromFrequency = (frequency: BudgetRolloverFrequency) => {
  const [prefix, interval, intervalType] = frequency.split('_'); // e.g., every_2_months
  const parsedInterval = parseInt(interval);
  let months = 0;

  if (prefix === 'every' && parsedInterval && !!intervalType) {
    const duration = Duration.fromObject({ [intervalType]: parsedInterval });

    if (!duration.isValid) {
      // eslint-disable-next-line no-console
      console.error(
        `Duration is invalid for this frequency: ${frequency}. It's probably caused by ` +
          `the intervalType (${intervalType}). Returning 0.`,
      );
      months = 0;
    } else {
      const { months: durationMonths } = duration;
      months = durationMonths;
    }
  } else if (frequency === BudgetRolloverFrequency.MONTHLY) {
    months = 1;
  }

  return months;
};

export const getRolloverSuggestedBudgetAmount = (
  frequency: BudgetRolloverFrequency,
  targetAmount: number,
  startingBalance?: number | null,
) => {
  // Frequency is variable which is not supported, return early
  if (frequency === BudgetRolloverFrequency.VARIABLE) {
    return;
  }

  const months = getRolloverMonthsFromFrequency(frequency);
  return Math.round((targetAmount - (startingBalance ?? 0)) / months);
};
