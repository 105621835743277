import { gql } from '@apollo/client';

export const MARK_ALL_AS_READ_MUTATION = gql`
  mutation Common_MarkAllActivityAsRead($first: Int) {
    markAllActivityAsRead {
      activityEvents(first: $first) {
        edges {
          node {
            id
            readAt
          }
        }
      }
    }
  }
`;

export const CLEAR_ALL_ACTIVITIES_MUTATION = gql`
  mutation Common_ClearAllActivities {
    clearAllActivities {
      success
    }
  }
`;

export const MARK_ACTIVITY_AS_READ_MUTATION = gql`
  mutation Common_MarkActivityAsRead($id: UUID!) {
    markActivityAsRead(id: $id) {
      node {
        id
        readAt
      }
    }
  }
`;

export const DISMISS_ACTIVITY_MUTATION = gql`
  mutation Common_DismissActivity($id: ID!) {
    dismissActivity(id: $id) {
      node {
        id
        dismissedAt
      }
    }
  }
`;
