import React from 'react';
import styled from 'styled-components';

import Confirmation from 'components/lib/ui/Confirmation';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import { GET_ME } from 'common/lib/graphQl/user';
import useSpinwheel from 'common/lib/hooks/recurring/useSpinwheel';
import useQuery from 'common/lib/hooks/useQuery';
import { useModalContext } from 'lib/contexts/ModalContext';
import useToast from 'lib/hooks/useToast';
import logger from 'lib/logger';
import { onOpenReportEmail } from 'lib/support';

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  background-color: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.default};
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
`;

const List = styled.ul`
  padding-left: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: start;
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const ICON_SIZE_PX = 22;

const DisconnectSpinwheelConfirmationModal = ({ next }: { next: (params?: any) => void }) => {
  const { openErrorToast } = useToast();
  const { close } = useModalContext();

  const { disconnectSpinwheel, isLoadingSpinwheelDisconnect } = useSpinwheel();

  const { data, isLoadingInitialData } = useQuery(GET_ME);

  const userFullName = data?.me.name;
  const displayNamePossesive = userFullName ? `${userFullName}'s` : 'your';

  const performDisconnect = async () => {
    const { data, errors } = await disconnectSpinwheel();

    if (data?.disconnectSpinwheel?.isDisconnected) {
      next();
      return;
    }

    logger.error(
      'Spinwheel account did not disconnect.',
      errors,
      data?.disconnectSpinwheel?.errors,
    );
    openErrorToast({
      title: 'Error',
      description: 'Something went wrong. Please try again or submit a ticket.',
      onReport: () => onOpenReportEmail(`Issue disconnecting Spinwheel account`),
    });
  };

  return (
    <Confirmation
      title="Are you sure you want to disconnect?"
      confirm="Disconnect"
      isLoading={isLoadingSpinwheelDisconnect || isLoadingInitialData}
      onCancel={close}
      onConfirm={performDisconnect}
      useDangerButton
    >
      <Content>
        <Text weight="medium">What will change:</Text>
        <List>
          <ListItem>
            <StyledIcon name="x-circle" size={ICON_SIZE_PX} />
            <Text>Remove {displayNamePossesive} connection between Spinwheel and Monarch.</Text>
          </ListItem>
          <ListItem>
            <StyledIcon name="x-circle" size={ICON_SIZE_PX} />
            <Text>
              Stop syncing {displayNamePossesive} new and existing bills and liability accounts from
              Spinwheel.
            </Text>
          </ListItem>
          <ListItem>
            <StyledIcon name="x-circle" size={ICON_SIZE_PX} />
            <Text>
              Delete all of {displayNamePossesive} bill data history in Monarch including bills,
              liability accounts, due dates, and payments sourced from Spinwheel.
            </Text>
          </ListItem>
          <ListItem>
            <StyledIcon name="info" size={ICON_SIZE_PX} />
            <Text>
              To resume syncing your bills after disconnecting, you will need to reconnect to
              Spinwheel.
            </Text>
          </ListItem>
        </List>
      </Content>
    </Confirmation>
  );
};

export default DisconnectSpinwheelConfirmationModal;
