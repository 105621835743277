import type { QueryHookOptions } from '@apollo/client';
import { gql } from '@apollo/client';

import useQuery from 'common/lib/hooks/useQuery';

import type { GetSubscriptionDetails } from 'common/generated/graphQlTypes/GetSubscriptionDetails';

/** This hook allows us to know if user has an active subscription on both web and mobile
 * and keep a single source of truth. It can be extended to add more statuses. */
const useSubscriptionDetails = (options?: QueryHookOptions<GetSubscriptionDetails>) => {
  const { data, isLoadingInitialData } = useQuery<GetSubscriptionDetails>(
    GET_SUBSCRIPTION_DETAILS_QUERY,
    options,
  );
  const { hasPremiumEntitlement, isOnFreeTrial, activeSponsorship, ...rest } =
    data?.subscription ?? {};

  return {
    isLoading: isLoadingInitialData,
    isSubscriptionActive: hasPremiumEntitlement,
    isTrialing: isOnFreeTrial,
    isSponsored: !!activeSponsorship,
    ...rest,
  };
};

const GET_SUBSCRIPTION_DETAILS_QUERY = gql`
  query GetSubscriptionDetails {
    subscription {
      id
      paymentSource
      referralCode
      isOnFreeTrial
      hasPremiumEntitlement

      activeSponsorship {
        id
      }
    }
  }
`;

export default useSubscriptionDetails;
