import React from 'react';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import type { Props as CheckboxProps } from 'components/lib/form/Checkbox';
import Checkbox from 'components/lib/form/Checkbox';

const CheckboxField = ({
  className,
  ...props
}: Omit<AbstractFieldProps<CheckboxProps>, 'children'> & { children?: React.ReactNode }) => (
  <AbstractField {...props} className={className}>
    {({ id, value, handleChange }) => (
      <Checkbox {...props} id={id} checked={value} onChange={handleChange} />
    )}
  </AbstractField>
);

export default CheckboxField;
