/* eslint-disable fp/no-loops */
import { DateTime, Duration } from 'luxon';

/**
 * Converts a generator constructor function to a constructor function returning a regular function
 * that can be called repeatedly.
 *
 * When the generator is exhausted, undefined is returned.
 */
export const genToFunc =
  <TReturn>(gen: (...args: any[]) => Generator<TReturn, void, unknown>) =>
  // @ts-ignore difficult to type vargs without variadic tuples
  (...args) => {
    let internalGen: Generator<TReturn, void, unknown>;
    return () => {
      if (!internalGen) {
        internalGen = gen(...args);
      }
      const { value } = internalGen.next();
      return value;
    };
  };

export function* genIncreasingNumber(
  start: number | undefined = 0.0,
  stop: number | undefined = undefined,
  step: number | undefined = 1.0,
) {
  let current = start;
  while (stop !== undefined ? current <= stop : true) {
    yield current;
    current += step;
  }
}

export function* genFromArray(array: any[]) {
  let index = 0;
  while (true) {
    yield array[index % array.length];
    index += 1;
  }
}

export function* genIncreasingIsoDate(
  start: DateTime | undefined = DateTime.local(),
  stop: DateTime | undefined = undefined,
  step: Duration | undefined = Duration.fromObject({ days: 1 }),
) {
  let current = start;
  while (stop !== undefined ? current <= stop : true) {
    yield current.toISODate();
    current = current.plus(step);
  }
}
