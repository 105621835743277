import { useMutation, gql } from '@apollo/client';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import Avatar from 'components/lib/ui/Avatar';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Tooltip from 'components/lib/ui/Tooltip';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { SIDEBAR_QUERY } from 'components/routes/SideBar';

import useUploadProfilePicture from 'common/lib/hooks/users/useUploadProfilePicture';
import refetchQueries from 'lib/graphQl/refetchQueries';

import type {
  DeleteUserProfilePicture,
  DeleteUserProfilePictureVariables,
} from 'common/generated/graphQlTypes/DeleteUserProfilePicture';

const ProfilePictureSection = styled(FlexContainer)`
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const ProfilePictureContainer = styled(FlexContainer).attrs({ center: true })`
  width: 48px;
  height: 48px;
`;

const ChoosePictureButtonContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.default};
`;

const RemoveButtonContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

type ProfilePictureType = {
  id: string;
  cloudinaryPublicId: string | null;
};

type Props = {
  profilePicture: ProfilePictureType | null;
  activeProfilePictureUrl: Maybe<string>;
  refetchUser: () => void;
};

const ProfilePictureSettings = ({
  profilePicture,
  activeProfilePictureUrl,
  refetchUser,
}: Props) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const uploadProfilePicture = useUploadProfilePicture();
  const [loading, setLoading] = useState(false);
  const hasExternalProfilePicture = !profilePicture && activeProfilePictureUrl;

  const profilePictureId = profilePicture?.id;

  const chooseFile = () => inputFile?.current?.click();

  const handleFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const [file] = Array.from(event.target.files);
    if (!file) {
      return;
    }

    setLoading(true);
    await uploadProfilePicture(file);
    setLoading(false);
    refetchUser();
    refetchQueries([SIDEBAR_QUERY]);
  };

  const handleDeleteProfilePicture = async () => {
    setLoading(true);
    await deleteProfilePicture();
    setLoading(false);
    refetchUser();
    refetchQueries([SIDEBAR_QUERY]);
  };

  const [deleteProfilePicture] = useMutation<
    DeleteUserProfilePicture,
    DeleteUserProfilePictureVariables
  >(DELETE_PROFILE_PICTURE, {
    variables: { id: profilePictureId ?? '' },
  });

  return (
    <ProfilePictureSection>
      <HiddenFileInput
        ref={inputFile}
        onChange={handleFileSelected}
        accept=".jpg,.jpeg,.png,.heif"
      />

      <ProfilePictureContainer>
        <Switch>
          <Case when={loading}>
            <LoadingSpinner />
          </Case>
          <Case default>
            <Tooltip
              content={
                hasExternalProfilePicture ? 'This profile picture is provided by Google' : undefined
              }
              fitContent
            >
              <Avatar $url={activeProfilePictureUrl} $size={48} />
            </Tooltip>
          </Case>
        </Switch>
      </ProfilePictureContainer>

      <ChoosePictureButtonContainer>
        <DefaultButton onClick={chooseFile} disabled={loading}>
          Choose picture
        </DefaultButton>
      </ChoosePictureButtonContainer>
      {profilePicture && (
        <RemoveButtonContainer>
          <DefaultButton onClick={handleDeleteProfilePicture} disabled={loading}>
            Remove
          </DefaultButton>
        </RemoveButtonContainer>
      )}
    </ProfilePictureSection>
  );
};

const DELETE_PROFILE_PICTURE = gql`
  mutation web_DeleteUserProfilePicture($id: ID!) {
    deleteUserProfilePicture(id: $id) {
      deleted
    }
  }
`;

export default ProfilePictureSettings;
