import * as R from 'ramda';

import type { FilterMenuOption, WithPath } from 'components/reports/filters/types';

export const getLeafOptionsForPath = (
  path: string[],
  options: FilterMenuOption[],
): FilterMenuOption[] => {
  if (path.length === 0) {
    // recursive base case
    return getAllLeavesForOptions(options);
  }

  const [first, ...rest] = path;
  const option = options.find(({ id }) => id === first);

  if (!option) {
    return [];
  }

  return getLeafOptionsForPath(rest, option.options ?? [option]);
};

export const areAllChildrenSelected = (options: FilterMenuOption[]): boolean => {
  const leaves = getLeafOptionsForPath([], options);
  return leaves.every((leaf) => leaf.isSelected);
};

export const getAllLeaves = <T extends FilterMenuOption>(option: T): T[] => {
  if (!option.options) {
    // recursive base case
    return [option];
  }

  return getAllLeavesForOptions(option.options as T[]);
};

export const getAllLeavesForOptions = <T extends FilterMenuOption>(options: T[]): T[] =>
  options.reduce((acc, option) => [...acc, ...getAllLeaves(option)], [] as T[]);

/**
 * Add path list to option and all of its children recursively.
 */
export const addPathToOption = <T extends FilterMenuOption>(
  option: T,
  parentPath: string[] = [],
): WithPath<T> => {
  const path = [...parentPath, option.id];

  return {
    ...option,
    path,
    options: option.options?.map((child) => addPathToOption(child as T, path)),
  };
};

export const addPathToOptions = <T extends FilterMenuOption>(
  options: T[],
  parentPath: string[] = [],
): WithPath<T>[] => options.map((option) => addPathToOption(option, parentPath));

/**
 * Recursively filter options by name.
 *
 * If an option's name includes the search string, it and all of its children will be included.
 * If an option's parent is not included, it will be moved up a level in the hierarchy.
 */
export const filterOptions = <T extends FilterMenuOption>(options: T[], search: string): T[] => {
  const [matched, unmatched] = R.partition(
    ({ name }) => name.toLowerCase().includes(search.toLowerCase()),
    options,
  );

  return [
    ...R.flatten(unmatched.map(({ options }) => filterOptions((options ?? []) as T[], search))),
    ...matched,
  ];
};
