import React from 'react';
import styled, { css } from 'styled-components';

import Drawer from 'components/lib/ui/Drawer';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import useToggle from 'common/lib/hooks/useToggle';

const Root = styled.div`
  width: 100%;
`;

const Header = styled(FlexContainer)<{ $showBorders?: boolean }>`
  cursor: pointer;
  transition: ${({ theme }) => theme.transition.default};
  padding: ${({ theme }) => theme.spacing.xlarge} 0;
  width: 100%;

  ${(props) =>
    props.$showBorders &&
    css`
      width: auto;
      padding: ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.xxlarge};
      border-top: 1px solid ${({ theme }) => theme.color.grayFocus};
      border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};

      :hover {
        background: ${({ theme }) => theme.color.grayBackground};
      }
    `}
`;

const TitleContainer = styled(FlexContainer).attrs({ column: true })`
  margin-right: ${({ theme }) => theme.spacing.xlarge};
  flex: 1;

  & > ${Text}:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const ArrowIcon = styled(Icon).attrs({ name: 'chevron-down' })<{ rotate: boolean }>`
  width: 24px;
  height: 24px;
  transition: all 0.5s cubic-bezier(0.176, 0.88, 0.32, 1.48);

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(180deg);
    `}
`;

type Props = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  initiallyExpanded?: boolean;
  children?: React.ReactNode;
  className?: string;
  showBorders?: boolean;
};

const CollapsibleSection = ({
  title,
  subtitle,
  initiallyExpanded = false,
  children,
  className,
  showBorders = true,
}: Props) => {
  const [open, { toggle }] = useToggle(initiallyExpanded);

  return (
    <Root>
      <Header className={className} onClick={toggle} $showBorders={showBorders}>
        <TitleContainer>
          {!!title && <Text weight="medium">{title}</Text>}
          {!!subtitle && <Text size="small">{subtitle}</Text>}
        </TitleContainer>
        <ArrowIcon rotate={open} />
      </Header>
      <Drawer open={open} shouldAnimateOnMount={false}>
        {children}
      </Drawer>
    </Root>
  );
};

export default CollapsibleSection;
