import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import React, { useState, useMemo, useCallback } from 'react';
import { createFilter } from 'react-select';
import type { ValueType, ActionMeta } from 'react-select';
import styled from 'styled-components';

import CreateCategoryModal from 'components/categories/CreateCategoryModal';
import type { Props as SelectProps } from 'components/lib/form/Select';
import Select from 'components/lib/form/Select';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Modal from 'components/lib/ui/Modal';
import PremiumBadge from 'components/premium/PremiumBadge';
import PremiumFeatureOverlayTrigger from 'components/premium/PremiumFeatureOverlayTrigger';
import PremiumUpgradeFlow from 'components/premium/PremiumUpgradeFlow';

import { sortByOrder, sortCategoryGroups } from 'common/lib/categories/Adapters';
import useFeatureEntitlement from 'common/lib/hooks/premium/useFeatureEntitlement';
import { isNotNil } from 'common/utils/Logic';
import { equalsIgnoreCase } from 'common/utils/String';
import useModal from 'lib/hooks/useModal';

import { ProductFeature } from 'common/constants/premium';

import { gql } from 'common/generated/gql';
import type {
  CategoryGroupType,
  CreateCategoryInput,
  GetCategorySelectOptionsQuery,
} from 'common/generated/graphql';
import type { Id } from 'common/types';
import type { ElementOf } from 'common/types/utility';
import type { OptionType } from 'types/select';
import { isSingleValue, isOptionsType } from 'types/select';

type Category = ElementOf<ElementOf<GetCategorySelectOptionsQuery, 'categoryGroups'>, 'categories'>;

export type CategorySelectFilters = {
  type?: CategoryGroupType;
  excludeCategories?: Id[];
};

type Props = Omit<SelectProps, 'onChange'> & {
  filters?: CategorySelectFilters;
  onChange?: (
    value: ValueType<OptionType>,
    action: ActionMeta | undefined,
    categories: GetCategorySelectOptionsQuery['categories'],
  ) => void;
  /** Only supported if isMulti == false */
  isCreatable?: boolean;
  createInitialValues?: Partial<CreateCategoryInput>;
  /** Show "Search categories..." placeholder once menu is opened, even if there is a current value */
  showPlaceholderWhenMenuOpen?: boolean;
  className?: string;
  placeholder?: string;
  collapseGroupBasedOnBudgeting?: boolean;
};

const applyFilters = (
  categoryGroups: GetCategorySelectOptionsQuery['categoryGroups'],
  filters?: CategorySelectFilters,
): typeof categoryGroups =>
  categoryGroups
    .map(
      R.evolve({
        categories: R.filter(({ id }: { id: string }) => !filters?.excludeCategories?.includes(id)),
      }),
    )
    .filter(({ type, categories }) => {
      if (filters?.type && filters.type !== type) {
        return false;
      }
      return categories.length > 0;
    });

const optionFromCategory = ({ id, name, icon }: Category) => ({
  value: id,
  label: `${icon} ${name}`,
});

const CreateCategoryFooter = styled(FlexContainer).attrs({ alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
  border-top: 1px solid ${({ theme }) => theme.color.grayFocus};
  color: ${({ theme }) => theme.color.blue};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.blueDark};
  }
`;

const CreateCategoryText = styled.span`
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const SELECT_FILTERS = createFilter({ ignoreAccents: false });

const CategorySelect = ({
  filters,
  onChange,
  isCreatable = true,
  createInitialValues,
  showPlaceholderWhenMenuOpen = true,
  className,
  onBlur,
  placeholder = 'Search categories...',
  collapseGroupBasedOnBudgeting = false,
  ...selectProps
}: Props) => {
  const [creatingCategoryForName, setCreatingCategoryForName] = useState<string | undefined>(
    undefined,
  );
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [showCreateCategoryFooter, setShowCreateCategoryFooter] = useState<boolean>(isCreatable);
  const [lastTypedInput, setLastTypedInput] = useState<string>('');
  const { data, loading, refetch } = useQuery<GetCategorySelectOptionsQuery>(GET_CATEGORY_GROUPS, {
    fetchPolicy: 'cache-first',
  });
  const { categoryGroups = [], categories = [] } = data ?? {};

  const options = useMemo(() => {
    const filteredGroups = applyFilters(categoryGroups, filters);
    const sortedGroups = sortCategoryGroups(filteredGroups);

    return sortedGroups.map(({ id: groupId, name, categories, groupLevelBudgetingEnabled }) => {
      const shouldCollapseGroup = groupLevelBudgetingEnabled && collapseGroupBasedOnBudgeting;

      if (shouldCollapseGroup) {
        const shouldHideGroup =
          filters?.excludeCategories?.find((id) => id === groupId) !== undefined;

        return {
          label: name,
          options: !shouldHideGroup
            ? [
                {
                  value: groupId,
                  isGroup: true,
                  label: name,
                },
              ]
            : [],
        };
      }

      return {
        label: name,
        options: sortByOrder(categories).map(optionFromCategory),
      };
    });
  }, [categoryGroups, filters]);

  const isValidNewOption = useCallback(
    (input: string) => {
      setLastTypedInput(input);
      // We need to refilter here to see if any options are visible.
      const allOptions = options.flatMap((group) => group.options);
      const filteredOptions = allOptions.filter(({ value, label }) =>
        SELECT_FILTERS(
          {
            value,
            label,
            data: {},
          },
          input,
        ),
      );
      const anyOptionsVisible = filteredOptions.length > 0;
      setShowCreateCategoryFooter(anyOptionsVisible);

      const anyMatch = categories.some(({ name }) => equalsIgnoreCase(name, input));
      return !anyMatch && !anyOptionsVisible;
    },
    [categories, options],
  );

  const { hasAccess: hasAccessToCustomCategories } = useFeatureEntitlement(
    ProductFeature.custom_categories,
  );

  const [UpgradeModal, { open: openUpgradeModal, close: closeUpgradeModal }] = useModal();

  const isLoading = loading && options.length === 0;

  return (
    <>
      <Select
        className={className}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        openMenuOnFocus
        controlShouldRenderValue={
          selectProps.isMulti || !showPlaceholderWhenMenuOpen || !isMenuOpen
        }
        placeholder={isLoading ? 'Loading...' : placeholder}
        isLoading={isLoading}
        options={options}
        menuPlacement="auto"
        filterOption={SELECT_FILTERS}
        menuFooterComponent={
          isCreatable &&
          showCreateCategoryFooter && (
            <PremiumFeatureOverlayTrigger
              feature={ProductFeature.custom_categories}
              onClickUpgrade={openUpgradeModal}
            >
              {({ hasAccess }) => (
                <CreateCategoryFooter
                  onClick={() => hasAccess && setCreatingCategoryForName(lastTypedInput)}
                >
                  <CreateCategoryText>Create new category</CreateCategoryText>
                  {!hasAccess && <PremiumBadge />}
                </CreateCategoryFooter>
              )}
            </PremiumFeatureOverlayTrigger>
          )
        }
        isCreatable={isCreatable && !selectProps.isMulti && hasAccessToCustomCategories}
        formatCreateLabel={(input: string) => `Create new category "${input}"`}
        isValidNewOption={isValidNewOption}
        tabSelectsValue={false} // We want users to be able to tab through transactions without changing category, see ENG-2131
        onChange={(value, action) => {
          const selectedCategories = categories.filter(({ id }) => {
            if (isSingleValue(value)) {
              return id === value.value;
            } else if (isOptionsType(value)) {
              return value.some(({ value }) => value === id);
            } else {
              return false;
            }
          });

          if (isSingleValue(value) && value.__isNew__) {
            setCreatingCategoryForName(value.value);
          } else {
            onChange?.(value, action, selectedCategories);
          }
        }}
        onBlur={(e) => !creatingCategoryForName && onBlur?.(e)}
        {...selectProps}
      />
      {isNotNil(creatingCategoryForName) && (
        <Modal
          onClose={() => {
            setCreatingCategoryForName(undefined);
            onBlur?.();
          }}
        >
          {({ close }) => (
            <CreateCategoryModal
              initialValues={{ name: creatingCategoryForName, ...(createInitialValues ?? {}) }}
              onCreate={(category) => {
                refetch();
                // @ts-ignore - while we dont migrate the create category mutation to use the new typegen
                onChange?.(optionFromCategory(category), undefined, [category]);
                close();
              }}
            />
          )}
        </Modal>
      )}
      <UpgradeModal>
        <PremiumUpgradeFlow
          onBack={closeUpgradeModal}
          onComplete={closeUpgradeModal}
          analyticsName={ProductFeature.custom_categories}
        />
      </UpgradeModal>
    </>
  );
};

const GET_CATEGORY_GROUPS = gql(`
  query GetCategorySelectOptions {
    categoryGroups {
      id
      name
      order
      type
      groupLevelBudgetingEnabled
      categories {
        id
        name
        order
        icon
      }
    }
    categories {
      id
      name
      order
      icon
      group {
        id
        type
      }
    }
  }
`);

export default CategorySelect;
