import { useMutation } from '@apollo/client';

import useUploadLogo from 'common/lib/hooks/cloudinary/useUploadLogo';
import { getFileExtension, getFileBaseName } from 'common/utils/File';

import { gql } from 'common/generated/gql';
import type { FileData } from 'common/types/File';

const SET_USER_PROFILE_PICTURE = gql(`
  mutation Common_SetUserProfilePicture($input: SetUserProfilePictureInput!) {
    setUserProfilePicture(input: $input) {
      profilePicture {
        id
        cloudinaryPublicId
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

const useUploadProfilePicture = () => {
  const [setUserProfilePicture] = useMutation(SET_USER_PROFILE_PICTURE);

  const handleSetLogo = async (file: FileData, cloudinaryPublicId: string) => {
    const [filename, extension] = [getFileBaseName(file.name!), getFileExtension(file.name!)];

    const setProfilePictureResponse = await setUserProfilePicture({
      variables: {
        input: {
          filename,
          extension,
          cloudinaryPublicId,
        },
      },
    });
    const { profilePicture } = setProfilePictureResponse.data?.setUserProfilePicture ?? {};

    if (profilePicture) {
      return profilePicture;
    } else {
      throw new Error('Profile picture upload failed. Please try again later.');
    }
  };

  return useUploadLogo('user', handleSetLogo);
};

export default useUploadProfilePicture;
