import { DateTime, Interval } from 'luxon';
import * as R from 'ramda';
import { isNotNil } from 'ramda-adjunct';

import type { PlanAmounts, PlanningAmountsByIdDate } from 'common/lib/planning';
import { getGoalsV2Amounts, mapValues } from 'common/lib/planning';
import type { GridAmountsForGroup } from 'lib/plan/Adapters';

import { MONTHS_PER_YEAR } from 'common/constants/time';

import type {
  Common_GetJointPlanningDataQuery,
  Common_GetJointPlanningDataQueryVariables,
} from 'common/generated/graphql';
import { BudgetVariability, CategoryGroupType } from 'common/generated/graphql';

export const COLUMNS_BEFORE_HIGHLIGHTED_DATE = 1;
export const FETCH_EXTRA_COLUMNS = 1;

export type PlanningDisplayData = {
  [CategoryGroupType.INCOME]: Common_GetJointPlanningDataQuery['categoryGroups'] | undefined;
  [CategoryGroupType.EXPENSE]: Common_GetJointPlanningDataQuery['categoryGroups'] | undefined;
};

export type AmountType = 'budgeted' | 'actual';

export enum PlanningTimeframe {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

const sortByOrder = <T extends { order: number }>(arr: T[]): T[] =>
  R.sortBy(({ order }) => order, arr);

export const getMonthsForYear = (yearISODate: GraphQlDate): DateTime[] => {
  const { year } = DateTime.fromISO(yearISODate);
  return R.range(0, MONTHS_PER_YEAR).map((month) =>
    DateTime.fromObject({ year, month: month + 1 }),
  );
};

const truncateMonth = (date: GraphQlDate): string =>
  DateTime.fromISO(date).set({ day: 1 }).toISODate();

const indexMonthlyAmountsByIdAndDate = (
  monthlyAmountsByCategory: any[],
  groupBy: 'category' | 'categoryGroup' = 'category',
  categoryGroups: Common_GetJointPlanningDataQuery['categoryGroups'] = [],
): PlanningAmountsByIdDate => {
  const allCategories = R.flatten(R.map(R.prop('categories'), categoryGroups));
  const groupedById = R.groupBy((amounts) => amounts[groupBy]?.id, monthlyAmountsByCategory);

  return R.mapObjIndexed((groupedAmounts, key) => {
    const flatMapped = R.flatten(R.map(({ monthlyAmounts }) => monthlyAmounts, groupedAmounts));
    const indexedByMonth = R.indexBy(({ month }) => month, flatMapped);
    const groupedByObject =
      groupBy === 'category'
        ? R.find(R.propEq('id', key), allCategories)
        : R.find(R.propEq('id', key), categoryGroups);
    const startingBalance = groupedByObject?.rolloverPeriod?.startingBalance;

    return R.mapObjIndexed(
      ({
        plannedCashFlowAmount,
        actualAmount,
        remainingAmount,
        previousMonthRolloverAmount,
        rolloverType,
        rolloverTargetAmount,
        cumulativeActualAmount,
      }) => ({
        budgeted: plannedCashFlowAmount,
        actual: actualAmount,
        remaining: remainingAmount,
        rollover: previousMonthRolloverAmount,
        rolloverType: rolloverType ?? undefined,
        rolloverStartingBalance: startingBalance,
        rolloverTargetAmount,
        cumulativeActualAmount,
      }),
      indexedByMonth,
    );
  }, groupedById);
};

// Return a map from [itemId][category] to cell data, merging the pending data with the original
// data (and taking into account things like "apply to future").
// We return one map with goal and budget data for simplicity, since their ids shouldn't collide.
export const getMergedPlanningAmountsFromQueryData = (
  fetchedDateRange?: Interval,
  data?: Common_GetJointPlanningDataQuery,
): PlanningAmountsByIdDate => {
  if (data && fetchedDateRange) {
    const { budgetData, goalsV2, categoryGroups } = data;
    const originalBudgetAmounts = indexMonthlyAmountsByIdAndDate(
      budgetData.monthlyAmountsByCategory,
      'category',
      categoryGroups,
    );
    const categoryGroupBudgetAmounts = indexMonthlyAmountsByIdAndDate(
      budgetData.monthlyAmountsByCategoryGroup,
      'categoryGroup',
      categoryGroups,
    );
    const flexBudgetAmounts: PlanningAmountsByIdDate = {
      [BudgetVariability.FLEXIBLE]: R.mapObjIndexed(
        ({
          plannedCashFlowAmount,
          actualAmount,
          remainingAmount,
          previousMonthRolloverAmount,
          rolloverType,
          cumulativeActualAmount,
          rolloverTargetAmount,
        }) => ({
          budgeted: plannedCashFlowAmount,
          actual: actualAmount,
          remaining: remainingAmount,
          rollover: previousMonthRolloverAmount,
          rolloverType: rolloverType ?? undefined,
          rolloverTargetAmount,
          cumulativeActualAmount,
        }),
        // @ts-ignore
        R.indexBy(({ month }) => month, budgetData.monthlyAmountsForFlexExpense.monthlyAmounts),
      ),
    };
    const originalGoalAmounts = getGoalsV2Amounts(goalsV2 ?? []);

    return R.mergeAll([
      originalBudgetAmounts,
      originalGoalAmounts,
      categoryGroupBudgetAmounts,
      flexBudgetAmounts,
    ]);
  }

  return {};
};

export const planningAmountsByCategoryIdDateToDisplay = (
  amountsByCategoryIdDate: PlanningAmountsByIdDate,
  categoryGroupInfo: Common_GetJointPlanningDataQuery['categoryGroups'],
): PlanningDisplayData => {
  // Filter out groups and categories that don't have a budget for any time period
  const relevantGroups = categoryGroupInfo.filter(({ categories }) =>
    categories.some(({ id }) => R.has(id, amountsByCategoryIdDate)),
  );
  const relevantGroupsWithRelevantCategories = relevantGroups.map(({ categories, ...rest }) => ({
    ...rest,
    categories: categories.filter(({ id }) => R.has(id, amountsByCategoryIdDate)),
  }));

  // Group by groupType and sort by order
  const sortedCategories = R.map(
    ({ categories, ...rest }) => ({ ...rest, categories: sortByOrder(categories) }),
    relevantGroupsWithRelevantCategories,
  );
  const groupedByType = R.groupBy(R.prop('type'), sortedCategories);

  type GroupedByTypeValueT = typeof groupedByType extends Record<string, infer T> ? T : never;

  const sortedGroups = mapValues(
    (categoryGroups: GroupedByTypeValueT) => sortByOrder(categoryGroups),
    groupedByType,
  );

  return sortedGroups as PlanningDisplayData;
};

const getAggregatedValue = (
  amountsByCategoryIdDate: PlanningAmountsByIdDate,
  categoryGroupInfo: Common_GetJointPlanningDataQuery['categoryGroups'],
  month: GraphQlDate,
  amountType: AmountType,
  timeframe: PlanningTimeframe,
  isGroupIncluded: (input: Common_GetJointPlanningDataQuery['categoryGroups'][number]) => boolean,
) => {
  const idSet = categoryGroupInfo.reduce(
    (acc: GraphQlUUID[], group) =>
      isGroupIncluded(group) ? acc.concat(group.categories.map(({ id }) => id)) : acc,
    [],
  );

  const monthsToSum =
    timeframe === PlanningTimeframe.MONTH
      ? [month]
      : getMonthsForYear(month).map((dt) => dt.toISODate());

  return monthsToSum.reduce((total, month) => {
    const sumForMonth = idSet.reduce(
      (sum, id) => sum + (amountsByCategoryIdDate[id]?.[month]?.[amountType] || 0),
      0,
    );
    return total + sumForMonth;
  }, 0);
};

export const getAggregateValueForCategoryGroupTypeMonth = (
  amountsByCategoryIdDate: PlanningAmountsByIdDate,
  categoryGroupInfo: Common_GetJointPlanningDataQuery['categoryGroups'],
  categoryGroupType: CategoryGroupType,
  month: GraphQlDate,
  amountType: AmountType,
): number =>
  getAggregatedValue(
    amountsByCategoryIdDate,
    categoryGroupInfo,
    month,
    amountType,
    PlanningTimeframe.MONTH,
    ({ type }: Common_GetJointPlanningDataQuery['categoryGroups'][number]) =>
      type === categoryGroupType,
  );

export const getAggregateValueForCategoryGroupMonth = (
  amountsByCategoryIdDate: PlanningAmountsByIdDate,
  categoryGroupInfo: Common_GetJointPlanningDataQuery['categoryGroups'],
  categoryGroupId: GraphQlUUID,
  month: GraphQlDate,
  amountType: AmountType,
): number =>
  getAggregatedValue(
    amountsByCategoryIdDate,
    categoryGroupInfo,
    month,
    amountType,
    PlanningTimeframe.MONTH,
    ({ id }: Common_GetJointPlanningDataQuery['categoryGroups'][number]) => id === categoryGroupId,
  );

export const getTotalMonthlySavings = (
  amountsByCategoryIdDate: PlanningAmountsByIdDate,
  categoryGroupInfo: Common_GetJointPlanningDataQuery['categoryGroups'],
  month: GraphQlDate,
  amountType: AmountType,
): number => {
  const totalIncome = getAggregateValueForCategoryGroupTypeMonth(
    amountsByCategoryIdDate,
    categoryGroupInfo,
    CategoryGroupType.INCOME,
    month,
    amountType,
  );
  const totalExpenses = getAggregateValueForCategoryGroupTypeMonth(
    amountsByCategoryIdDate,
    categoryGroupInfo,
    CategoryGroupType.EXPENSE,
    month,
    amountType,
  );

  return totalIncome - totalExpenses;
};

export const getAggregateValueForCategoryGroupTypeYear = (
  amountsByCategoryIdDate: PlanningAmountsByIdDate,
  categoryGroupInfo: Common_GetJointPlanningDataQuery['categoryGroups'],
  categoryGroupType: CategoryGroupType,
  year: GraphQlDate,
  amountType: AmountType,
): number =>
  getAggregatedValue(
    amountsByCategoryIdDate,
    categoryGroupInfo,
    year,
    amountType,
    PlanningTimeframe.YEAR,
    ({ type }: Common_GetJointPlanningDataQuery['categoryGroups'][number]) =>
      type === categoryGroupType,
  );

export const getAggregateValueForCategoryGroupYear = (
  amountsByCategoryIdDate: PlanningAmountsByIdDate,
  categoryGroupInfo: Common_GetJointPlanningDataQuery['categoryGroups'],
  categoryGroupId: GraphQlUUID,
  year: GraphQlDate,
  amountType: AmountType,
): number =>
  getAggregatedValue(
    amountsByCategoryIdDate,
    categoryGroupInfo,
    year,
    amountType,
    PlanningTimeframe.YEAR,
    ({ id }: Common_GetJointPlanningDataQuery['categoryGroups'][number]) => id === categoryGroupId,
  );

/** Sum the yearly amounts (budgeted or actual) for a single item. */
export const getAggregateValueForItemIdYear = (
  amounts: PlanningAmountsByIdDate,
  itemId: GraphQlUUID,
  yearISODate: GraphQlDate,
  amountType: AmountType,
): number => {
  const monthsToSum = getMonthsForYear(yearISODate).map((dt) => dt.toISODate());

  return monthsToSum.reduce(
    (total, month) => total + (amounts[itemId]?.[month]?.[amountType] || 0),
    0,
  );
};

export const getTotalYearlySavings = (
  amountsByCategoryIdDate: PlanningAmountsByIdDate,
  categoryGroupInfo: Common_GetJointPlanningDataQuery['categoryGroups'],
  year: GraphQlDate,
  amountType: AmountType,
): number => {
  const totalIncome = getAggregateValueForCategoryGroupTypeYear(
    amountsByCategoryIdDate,
    categoryGroupInfo,
    CategoryGroupType.INCOME,
    year,
    amountType,
  );
  const totalExpenses = getAggregateValueForCategoryGroupTypeYear(
    amountsByCategoryIdDate,
    categoryGroupInfo,
    CategoryGroupType.EXPENSE,
    year,
    amountType,
  );

  return totalIncome - totalExpenses;
};

export const getVisibleDateRange = (
  highlightDate: DateTime,
  columnCount: number,
  isTimeframeMonthly: boolean,
): Interval => {
  if (isTimeframeMonthly) {
    return Interval.fromDateTimes(
      highlightDate.minus({ months: COLUMNS_BEFORE_HIGHLIGHTED_DATE }),
      highlightDate.plus({ months: COLUMNS_BEFORE_HIGHLIGHTED_DATE * (columnCount - 2) }),
    );
  } else {
    return Interval.fromDateTimes(
      highlightDate
        .startOf('year')
        .minus({ months: COLUMNS_BEFORE_HIGHLIGHTED_DATE * MONTHS_PER_YEAR }),
      highlightDate
        .endOf('year')
        .plus({ months: COLUMNS_BEFORE_HIGHLIGHTED_DATE * MONTHS_PER_YEAR * (columnCount - 2) }),
    );
  }
};

export const getDateRangeToFetch = (
  visibleDateRange: Interval,
  isTimeframeMonthly: boolean,
): Interval => {
  const columnInterval = isTimeframeMonthly ? 'months' : 'years';
  return Interval.fromDateTimes(
    visibleDateRange.start.minus({
      [columnInterval]: FETCH_EXTRA_COLUMNS,
    }),
    visibleDateRange.end.plus({
      [columnInterval]: FETCH_EXTRA_COLUMNS,
    }),
  );
};

// Exclude previously a fetched range from a needed date range
export const excludePreviouslyFetchedFromDateRange = (
  neededDateRange: Interval,
  previouslyFetchedDateRange: Interval,
): Interval | null => {
  if (previouslyFetchedDateRange.engulfs(neededDateRange)) {
    // we've already fetched everything we need, just return
    return null;
  } else if (neededDateRange.engulfs(previouslyFetchedDateRange)) {
    // There are dates to fetch on both sides of previously fetched
    // Just naively fetch the entire range
    return neededDateRange;
  } else if (previouslyFetchedDateRange.contains(neededDateRange.start)) {
    // just fetch the new dates on the right
    return neededDateRange.difference(previouslyFetchedDateRange)[0];
  } else if (previouslyFetchedDateRange.contains(neededDateRange.end)) {
    // just fetch the new dates on the left
    return neededDateRange.difference(previouslyFetchedDateRange)[0];
  }
  return neededDateRange;
};

export const getDataQueryParameters = (
  neededDateRange: Interval,
  previouslyFetchedDateRange?: Interval,
): Common_GetJointPlanningDataQueryVariables | undefined => {
  const dateRangeToFetch = previouslyFetchedDateRange
    ? excludePreviouslyFetchedFromDateRange(neededDateRange, previouslyFetchedDateRange)
    : neededDateRange;

  if (!dateRangeToFetch) {
    // Don't need to fetch any new dates
    return undefined;
  }

  const fetchStartDate = dateRangeToFetch.start;
  const fetchEndDate = dateRangeToFetch.end;

  // We want to always fetch actuals and planned for the whole fetch range
  // so we can render the single month view
  return {
    startDate: fetchStartDate.startOf('month').toISODate(),
    endDate: fetchEndDate.plus({ months: 1 }).startOf('month').toISODate(),
  };
};

/** Sum the monthly/yearly goal contribution amounts for all goals. */
export const getAggregatedGoalsValue = (
  goalIds: string[],
  amounts: PlanningAmountsByIdDate,
  date: GraphQlDate,
  amountType: AmountType,
  timeframe: PlanningTimeframe,
) => {
  const monthsToSum =
    timeframe === PlanningTimeframe.MONTH
      ? // make sure we truncate the date up to the month so it doesn't affect calculation
        [truncateMonth(date)]
      : getMonthsForYear(date).map((dt) => dt.toISODate());

  return monthsToSum.reduce((total, month) => {
    const sumForMonth = goalIds.reduce(
      (sum, id) => sum + (amounts[id]?.[month]?.[amountType] || 0),
      0,
    );
    return total + sumForMonth;
  }, 0);
};

export const getUnallocatedFlexibleBudgetAmount = (
  amounts: GridAmountsForGroup,
  firstColumnDateIsoDate: string,
  isGroupFlexibleAndSingleMonth: boolean,
  sectionType: CategoryGroupType | 'savings',
) => {
  const rowsAggregateForFirstColumn = amounts.rowsAggregate[firstColumnDateIsoDate] ?? {};
  const aggregateForFirstColumn = amounts.aggregate[firstColumnDateIsoDate] ?? {};

  // We don't want to show the Available Flexible Budget column if there are no values defined
  const hasAtLeastOneValueDefined = getAtLeastOneValueDefined(rowsAggregateForFirstColumn);

  // We don't want to show the Available Flexible Budget column if there are no budgeted categories
  const hasBudgetedCategories = getHasBudgetedCategories(amounts, firstColumnDateIsoDate);

  if (
    !hasAtLeastOneValueDefined ||
    !hasBudgetedCategories ||
    !isGroupFlexibleAndSingleMonth ||
    sectionType !== CategoryGroupType.EXPENSE
  ) {
    return null;
  }

  const totalBudgetedAmountForRows = rowsAggregateForFirstColumn.budgeted ?? 0;
  const totalFlexibleBudgetAmount = aggregateForFirstColumn.budgeted ?? 0;
  return totalFlexibleBudgetAmount - totalBudgetedAmountForRows;
};

const getAtLeastOneValueDefined = (rowsAggregate: PlanAmounts) =>
  Object.values(rowsAggregate).some((value) => isNotNil(value) && value > 0);

const getHasBudgetedCategories = (amounts: GridAmountsForGroup, date: string) => {
  const rowsAmountsById = R.omit(['rowsAggregate', 'aggregate', 'aggregateUnplanned'], amounts);
  const rowsAmounts = Object.values(rowsAmountsById).map((amountsByDate) => amountsByDate[date]);
  return rowsAmounts.some((amounts) => !!amounts?.budgeted);
};
