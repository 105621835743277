import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import MarkAsReviewedProductUpdateCallout from 'components/transactions/MarkAsReviewedProductUpdateCallout';
import IconButtonBadge from 'components/transactions/drawer/IconButtonBadge';
import TransactionDrawerStatusTag from 'components/transactions/drawer/TransactionDrawerStatusTag';

import { updateTransactionOptimisticResponse } from 'common/lib/graphQl/transactions';
import useEventCallback from 'common/lib/hooks/useEventCallback';
import { getRecurringTooltipText } from 'common/lib/recurring';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';

import type { Frequency } from 'common/constants/recurringTransactions';
import { HIDDEN_TOOLTIP_TEXT } from 'constants/copy';

import type {
  GetTransactionDrawerQuery,
  Web_TransactionDrawerUpdateTransactionMutation,
  Web_TransactionDrawerUpdateTransactionMutationVariables,
} from 'common/generated/graphql';

const CHECKMARK_ICON_SIZE_PX = 18;
const EYE_ICON_SIZE_PX = 14;

const IconButtonBadgeDefaultCursor = styled(IconButtonBadge)`
  cursor: default;
`;

type Props = {
  transaction: NonNullable<GetTransactionDrawerQuery['getTransaction']>;
  updateTransaction: Mutation<
    Web_TransactionDrawerUpdateTransactionMutation,
    Web_TransactionDrawerUpdateTransactionMutationVariables
  >;
};

const TransactionDrawerHeaderStatusButtons = ({ transaction, updateTransaction }: Props) => {
  const { pending, isRecurring, reviewedAt, reviewedByUser, hideFromReports, merchant, account } =
    transaction;
  const { hideTransactionsFromReports } = account;

  const toggleReviewed = useEventCallback(() => {
    const newIsReviewed = !reviewedAt;

    updateTransaction({
      variables: {
        input: {
          id: transaction.id,
          ...(newIsReviewed ? { reviewed: true } : { needsReview: true }),
        },
      },
      optimisticResponse: updateTransactionOptimisticResponse({
        ...transaction,
        needsReview: !newIsReviewed,
        reviewedAt: newIsReviewed ? new Date().toISOString() : null,
      }),
    });
  });

  const toggleHidden = useEventCallback(() => {
    const newHideFromReports = !hideFromReports;
    updateTransaction({
      variables: {
        input: {
          id: transaction.id,
          hideFromReports: newHideFromReports,
        },
      },
      optimisticResponse: updateTransactionOptimisticResponse({
        ...transaction,
        hideFromReports: newHideFromReports,
      }),
    });
  });

  const hideTooltipText = (() => {
    if (hideTransactionsFromReports) {
      return `Your ${account.displayName} account is set to hide all transactions.`;
    } else if (hideFromReports) {
      return HIDDEN_TOOLTIP_TEXT;
    } else {
      return 'Hide transaction';
    }
  })();

  return (
    <MarkAsReviewedProductUpdateCallout>
      <FlexContainer alignCenter gap="xsmall">
        <IconButtonBadge
          tooltip={
            reviewedAt
              ? `Marked as reviewed by ${
                  reviewedByUser?.name ?? 'someone'
                } on ${isoDateToAbbreviatedMonthDayAndYear(reviewedAt)}. Click to undo.`
              : 'Mark as reviewed'
          }
          icon="check"
          iconSize={CHECKMARK_ICON_SIZE_PX}
          badge={!!reviewedAt}
          badgeText="Reviewed"
          badgeBackgroundColor="orangeBackground"
          badgeTextColor="orangeText"
          onClick={toggleReviewed}
        />

        <IconButtonBadge
          tooltip={hideTooltipText}
          icon={hideFromReports ? 'eye-off' : 'eye'}
          iconSize={EYE_ICON_SIZE_PX}
          badge={hideFromReports}
          badgeText="Hidden"
          badgeBackgroundColor="grayBackground"
          badgeTextColor="textLight"
          // if account is set to hide all transactions, user can't unhide individual transactions
          disabled={hideTransactionsFromReports}
          onClick={toggleHidden}
        />
        {pending && <TransactionDrawerStatusTag>Pending</TransactionDrawerStatusTag>}
        {isRecurring && (
          <IconButtonBadgeDefaultCursor
            icon="calendar"
            badge
            badgeText="Recurring"
            badgeBackgroundColor="grayBackground"
            badgeTextColor="textLight"
            tooltip={getRecurringTooltipText(
              merchant.recurringTransactionStream?.frequency as Frequency,
            )}
          />
        )}
      </FlexContainer>
    </MarkAsReviewedProductUpdateCallout>
  );
};

export default TransactionDrawerHeaderStatusButtons;
