import * as R from 'ramda';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import InstitutionLogo from 'components/institutions/InstitutionLogo';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { MINT_LOGO_BASE_64 } from 'common/constants/accounts';
import routes from 'constants/routes';

import type { Web_GetTopInstitutionGroupsQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

const INSTITUTION_LOGO_COUNT = 3;

const Root = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${({ theme }) => theme.spacing.xlarge};
  row-gap: ${({ theme }) => theme.spacing.xlarge};
`;

const Group = styled(FlexContainer).attrs({ column: true, justifyCenter: true })`
  border-radius: ${({ theme }) => theme.radius.medium};
  background: ${({ theme }) => theme.color.grayBackground};
  padding: 0 ${({ theme }) => theme.spacing.default};
  transition: ${({ theme }) => theme.transition.default};
  cursor: pointer;
  height: 180px;

  :hover {
    background: ${({ theme }) => theme.color.grayFocus};
  }
`;

const Title = styled(Text).attrs({ weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.default};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const Subtitle = styled(Text).attrs({
  size: 'xxsmall',
  weight: 'bold',
  transform: 'uppercase',
  color: 'textLight',
})`
  letter-spacing: 0.08em;
`;

const StyledInstitutionLogo = styled(InstitutionLogo)`
  width: 28px;
  height: 28px;
  margin-right: -${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  onSelectGroup: (
    group: ElementOf<Web_GetTopInstitutionGroupsQuery, 'topInstitutionGroups'>,
  ) => void;
  topInstitutionGroups: Web_GetTopInstitutionGroupsQuery['topInstitutionGroups'];
  className?: string;
};

const TopInstitutionGroups = ({ onSelectGroup, topInstitutionGroups, className }: Props) => {
  const { push } = useHistory();

  return (
    <Root className={className}>
      {topInstitutionGroups.map((group) => (
        <Group key={group.type} onClick={() => onSelectGroup(group)}>
          <FlexContainer>
            {R.take(INSTITUTION_LOGO_COUNT, group.institutions).map(
              (institution) =>
                institution.logo && (
                  <StyledInstitutionLogo key={institution.id} logo={institution.logo} />
                ),
            )}
          </FlexContainer>
          <FlexContainer column>
            <Title>{group.title}</Title>
            <Subtitle>{group.accountsConnectedCount} Added</Subtitle>
          </FlexContainer>
        </Group>
      ))}
      <Group onClick={() => push(routes.accounts.importMintData())}>
        <StyledInstitutionLogo logo={MINT_LOGO_BASE_64} />
        <FlexContainer column>
          <Title>Move from Mint to Monarch</Title>
          <Subtitle>Import CSV</Subtitle>
        </FlexContainer>
      </Group>
    </Root>
  );
};

export default TopInstitutionGroups;
