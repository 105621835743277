import React from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import CurrencyField from 'components/lib/form/CurrencyField';
import Form from 'components/lib/form/Form';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import useUpdateGoalAccountAllocation from 'common/lib/hooks/goalsV2/useUpdateGoalAccountAllocation';
import { formatCurrency } from 'common/utils/Currency';
import { fieldStyleFocus } from 'lib/styles/fieldStyleMixin';

import type { Web_GoalsAllocateBalanceModal_accountsWithUnallocatedBalancesForGoals_goalAllocations } from 'common/generated/graphQlTypes/Web_GoalsAllocateBalanceModal';

const StyledCurrencyField = styled(CurrencyField)`
  input {
    border: none;
    box-shadow: none;

    /* removing border and shadow to have the button look like its inside the input */
    :focus {
      border: none;
      box-shadow: none;
    }

    /* counter automatically added margin from FormItemContainer */
    margin-bottom: -${({ theme }) => theme.spacing.default};
  }
`;

const StyledForm = styled(Form)`
  /* Add the focus effect to the form so it contains the button */
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  display: flex;
  flex: 1;

  border-radius: ${({ theme }) => theme.radius.small};
  padding: 5px;
  min-height: 50px;

  /* stylelint-disable-next-line no-descending-specificity */
  :focus-within {
    ${fieldStyleFocus}
  }
`;

const StyledDefaultButton = styled(DefaultButton)`
  margin-left: auto;
`;

type Props = {
  allocation: Web_GoalsAllocateBalanceModal_accountsWithUnallocatedBalancesForGoals_goalAllocations;
};

const GoalAllocationAmountInput = ({ allocation }: Props) => {
  const {
    account: { id: accountId, availableBalanceForGoals },
    goal: { id: goalId },
    currentAmount,
    id,
  } = allocation;
  const [updateAllocation] = useUpdateGoalAccountAllocation();

  return (
    <StyledForm
      submitOnBlur
      onSubmit={(values) => {
        const { allocationAmount } = values as { allocationAmount: number };
        if (allocationAmount !== currentAmount) {
          updateAllocation({
            id,
            goalId,
            accountId,
            availableBalanceForGoals,
            amount: allocationAmount ?? 0,
          });
        }
      }}
      initialValues={{
        allocationAmount: currentAmount ?? undefined,
      }}
    >
      <StyledCurrencyField
        name="allocationAmount"
        hideLabel
        placeholder={formatCurrency(0)}
        maskOptions={{ allowDecimal: true }}
      />
      {!!availableBalanceForGoals && (
        <FormContext.Consumer>
          {({ setFieldValue, submitForm }) => (
            <StyledDefaultButton
              size="small"
              onClick={() => {
                setFieldValue('allocationAmount', (currentAmount ?? 0) + availableBalanceForGoals);
                submitForm();
              }}
            >
              Add {formatCurrency(availableBalanceForGoals)}
            </StyledDefaultButton>
          )}
        </FormContext.Consumer>
      )}
    </StyledForm>
  );
};

export default GoalAllocationAmountInput;
