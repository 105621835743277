import React, { useMemo } from 'react';
import styled from 'styled-components';

import GoalInfoRow from 'components/goalsV2/GoalInfoRow';
import GoalMonthlyBudgetInput from 'components/goalsV2/GoalMonthlyBudgetInput';
import FlexContainer from 'components/lib/ui/FlexContainer';

import useQuery from 'common/lib/hooks/useQuery';
import useUpdateEffect from 'common/lib/hooks/useUpdateEffect';

import { GoalObjective } from 'common/constants/goals';

import { gql } from 'common/generated/gql';
import type { Web_PreTaxBudgetGoalsList } from 'common/generated/graphQlTypes/Web_PreTaxBudgetGoalsList';

const List = styled(FlexContainer).attrs({ column: true, alignCenter: true, full: true })`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const GoalRow = styled(FlexContainer).attrs({ column: true, full: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const StyledGoalInfoRow = styled(GoalInfoRow)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  onDoneLoading?: () => void;
};

const PreTaxBudgetGoalsList = ({ onDoneLoading }: Props) => {
  const { data, isLoadingInitialData } = useQuery<Web_PreTaxBudgetGoalsList>(QUERY);

  useUpdateEffect(() => {
    if (!isLoadingInitialData) {
      onDoneLoading?.();
    }
  }, [isLoadingInitialData]);

  const goals = useMemo(
    () => data?.goalsV2.filter(({ objective }) => objective === GoalObjective.Retirement) ?? [],
    [data],
  );

  return (
    <List>
      {goals.map((item, idx) => (
        <GoalRow key={idx}>
          <StyledGoalInfoRow data={item} />
          <GoalMonthlyBudgetInput goal={item} valueKey="plannedMonthlyPretaxContribution" />
        </GoalRow>
      ))}
    </List>
  );
};

const QUERY = gql(/* GraphQL */ `
  query Web_PreTaxBudgetGoalsList {
    goalsV2 {
      id
      plannedMonthlyPretaxContribution
      objective
      ...Web_GoalInfoRowFields
    }
  }
`);

export default PreTaxBudgetGoalsList;
