import * as COPY from 'common/constants/copy';

export enum GoalType {
  Asset = 'asset',
  Debt = 'debt',
  Qualitative = 'qualitative',
}

export enum GoalObjective {
  EmergencyFund = 'emergency_fund',
  HomeDownPayment = 'home_down_payment',
  Car = 'car',
  Vacation = 'vacation',
  Wedding = 'wedding',
  Education = 'education',
  Retirement = 'retirement',
  Savings = 'savings',
  PayOffCreditCardDebt = 'pay_off_credit_card_debt',
  PayOffStudentLoans = 'pay_off_student_loans',
  PayOffCarLoan = 'pay_off_car_loan',
  PayOffMortgage = 'pay_off_mortgage',
  OtherDebt = 'other_debt',
}

export const GOAL_IMAGE_GRADIENT_COLORS = ['rgba(255, 115, 105, 0.25)', '#1348A5'];
export const GOAL_IMAGE_GRADIENT_COLORS_ARCHIVED = [
  'rgba(255, 255, 255, 0.51)',
  'rgba(0, 0, 0, 0.93)',
];

export const GOAL_BUDGET_NUM_MONTHS_AVG_SAVINGS = 3; // Show savings over last 3 months

export const COPY_FOR_GOAL_TYPE = {
  [GoalType.Asset]: {
    title: COPY.GOALS.SELECT_GOALS.ASSET_GOALS_TITLE,
    subtitle: COPY.GOALS.SELECT_GOALS.ASSET_GOALS_SUBTITLE,
  },
  [GoalType.Debt]: {
    title: COPY.GOALS.SELECT_GOALS.DEBT_GOALS_TITLE,
    subtitle: COPY.GOALS.SELECT_GOALS.DEBT_GOALS_SUBTITLE,
  },
} as { [GoalType: string]: { title: string; subtitle: string } };
