import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';

import AddManualHoldingFlow from 'components/accounts/AddManualHoldingFlow';
import type { AddManualHoldingFlowOutput } from 'components/accounts/AddManualHoldingFlow';
import ModalCard from 'components/lib/ui/ModalCard';

import { CREATE_MANUAL_INVESTMENTS_ACCOUNT } from 'common/lib/graphQl/accounts';
import { CREATE_MANUAL_HOLDING_MUTATION } from 'common/lib/graphQl/holdings';
import toast, { errorToast } from 'lib/ui/toast';

import type {
  CreateManualHolding,
  CreateManualHoldingVariables,
} from 'common/generated/graphQlTypes/CreateManualHolding';
import type {
  CreateManualInvestmentsAccount,
  CreateManualInvestmentsAccountVariables,
} from 'common/generated/graphQlTypes/CreateManualInvestmentsAccount';
import type { PayloadErrorFields } from 'common/generated/graphQlTypes/PayloadErrorFields';
import { ManualInvestmentsAccountTrackingMethod } from 'common/generated/graphQlTypes/globalTypes';

type Props = {
  accountId?: GraphQlUUID;
  onDone?: () => void;
  onAddMore?: () => void;
  onCancel?: () => void;
};

const AddHoldingModal = ({ accountId, onDone, onAddMore, onCancel }: Props) => {
  const initialValues = useMemo(() => ({ accountId }), [accountId]);

  const [createManualHolding] = useMutation<CreateManualHolding, CreateManualHoldingVariables>(
    CREATE_MANUAL_HOLDING_MUTATION,
    {
      refetchQueries: ['Web_GetHoldings'],
    },
  );

  const [createManualInvestmentsAccount] = useMutation<
    CreateManualInvestmentsAccount,
    CreateManualInvestmentsAccountVariables
  >(CREATE_MANUAL_INVESTMENTS_ACCOUNT, {
    refetchQueries: ['Web_GetHoldings'],
  });

  const handleAddManualHoldingFlowComplete = async (values: AddManualHoldingFlowOutput) => {
    const { securityId = '', quantity = 0, accountId } = values;
    let errors: PayloadErrorFields | undefined;
    let toastMessage = '';

    if (R.isNil(accountId)) {
      const { accountName: name, accountSubtype: subtype } = values;
      if (RA.isNotNil(name) && RA.isNotNil(subtype)) {
        const { data } = await createManualInvestmentsAccount({
          variables: {
            input: {
              name,
              subtype,
              manualInvestmentsTrackingMethod: ManualInvestmentsAccountTrackingMethod.holdings,
              initialHoldings: [{ securityId, quantity }],
            },
          },
        });
        errors = R.path(['createManualInvestmentsAccount', 'errors'], data);
        toastMessage = `Holding was added to account ${name}`;
      }
    } else {
      const { data } = await createManualHolding({
        variables: { input: { accountId, securityId: securityId ?? '', quantity: quantity ?? 0 } },
      });
      errors = R.path(['createManualHolding', 'errors'], data);
      toastMessage = `${data?.createManualHolding?.holding?.ticker} is now tracked in your portfolio`;
    }

    if (errors) {
      errorToast(errors.message);
    } else {
      toast({
        title: 'Holding added',
        description: toastMessage,
        duration: 3000,
        actions: [
          {
            text: 'Add More',
            onClick: () => {
              onAddMore?.();
            },
            dismissOnClick: true,
          },
        ],
      });
    }

    onDone?.();
  };

  return (
    <ModalCard title="Add holding" hideHeader>
      <AddManualHoldingFlow
        initialProps={initialValues}
        onComplete={handleAddManualHoldingFlowComplete}
        onBack={onCancel}
      />
    </ModalCard>
  );
};

export default AddHoldingModal;
