import { DateTime } from 'luxon';
import * as React from 'react';

import ChartTooltip from 'components/lib/charts/ChartTooltip';

import { formatCurrency } from 'common/utils/Currency';

import ChartTooltipDataRow from './ChartTooltipDataRow';

type Props = {
  date: string;
  value: number;
  label: string;
  dotFillColor: string;
  active: boolean;
};

const DateValueTooltip = ({ date, value, label, dotFillColor, active }: Props) => (
  <ChartTooltip header={DateTime.fromISO(date).toFormat('DDD')} active={active}>
    <ChartTooltipDataRow value={formatCurrency(value)} label={label} dotFillColor={dotFillColor} />
  </ChartTooltip>
);

export default DateValueTooltip;
