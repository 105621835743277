import React, { useMemo } from 'react';
import styled from 'styled-components';

import AccountLogosGroup from 'components/accounts/AccountLogosGroup';
import GoalInfoRow from 'components/goalsV2/GoalInfoRow';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ProgressBar from 'components/lib/ui/ProgressBar';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { formatCurrencyNoCents } from 'common/utils/Currency';

import { GoalType } from 'common/constants/goals';
import { DECIMAL_TO_PERCENT } from 'common/constants/math';

import type { GoalAccountsMapRowFieldsFragment } from 'common/generated/graphql';

const ACCOUNT_LOGO_SIZE_PT = 24;

const Root = styled(FlexContainer).attrs({ column: true })``;

const StyledProgressBar = styled(ProgressBar)`
  margin-top: ${({ theme }) => theme.spacing.default};
  height: 8px;
`;

const RowFooter = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  min-height: ${ACCOUNT_LOGO_SIZE_PT + 3}px;
`;

type Props = {
  data: GoalAccountsMapRowFieldsFragment;
  onClickAddAccounts?: () => void;
  className?: string;
  showAccountLogos?: boolean;
};

const PROGRESS_BAR_MIN_COMPLETION = 0.025; // just enough to see a dot

const GoalAccountsMapRow = ({
  data,
  className,
  onClickAddAccounts,
  showAccountLogos = true,
}: Props) => {
  const {
    currentAmount,
    targetAmount,
    startingAmount,
    accountAllocations,
    completionPercent,
    type,
  } = data;

  const isDebtGoal = type === GoalType.Debt;

  const progress = Math.max(
    PROGRESS_BAR_MIN_COMPLETION,
    (completionPercent ?? 0) / DECIMAL_TO_PERCENT,
  );

  const amountPaidOff = (currentAmount ?? 0) - (startingAmount ?? 0);

  const accounts = useMemo(
    () => accountAllocations.map(({ account }) => account),
    [accountAllocations],
  );

  return (
    <Root className={className}>
      <FlexContainer full justifyBetween alignCenter>
        <GoalInfoRow data={data} />

        {onClickAddAccounts &&
          (accounts.length ? (
            <DefaultButton onClick={onClickAddAccounts}>Edit accounts</DefaultButton>
          ) : (
            <PrimaryButton onClick={onClickAddAccounts}>Add accounts</PrimaryButton>
          ))}
      </FlexContainer>

      <StyledProgressBar value={progress} color="green" roundAppearance />
      <RowFooter>
        {isDebtGoal ? (
          <Text color="textLight" weight="medium">
            {formatCurrencyNoCents(Math.max(0, amountPaidOff))} paid of{' '}
            {formatCurrencyNoCents((startingAmount ?? 0) * -1)}
          </Text>
        ) : (
          <Text color="textLight" weight="medium">
            {formatCurrencyNoCents(currentAmount ?? 0)} saved of{' '}
            {formatCurrencyNoCents(targetAmount ?? 0)}
          </Text>
        )}

        {accounts && showAccountLogos && (
          <AccountLogosGroup accounts={accounts} size={ACCOUNT_LOGO_SIZE_PT} />
        )}
      </RowFooter>
    </Root>
  );
};

export default GoalAccountsMapRow;
