import React from 'react';

import EnableMfaInfoModal from 'components/settings/security/EnableMfaInfoModal';
import QRCodeMfaModal from 'components/settings/security/QRCodeMfaModal';
import SaveRecoveryCodesMfaModal from 'components/settings/security/SaveRecoveryCodesMfaModal';
import Flow from 'components/utils/Flow';

type Props = {
  onComplete: () => void;
};

const EnableMfaFlow = ({ onComplete }: Props) => (
  <Flow
    steps={[EnableMfaInfoModal, SaveRecoveryCodesMfaModal, QRCodeMfaModal]}
    onComplete={onComplete}
  />
);

export default EnableMfaFlow;
