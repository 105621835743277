import { useMutation } from '@apollo/client';
import { ProductFeature } from 'common/build/generated/graphQlTypes/globalTypes';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import CategorySelect from 'components/categories/FullHeightCategorySelect';
import CurrencyField from 'components/lib/form/CurrencyField';
import DateField from 'components/lib/form/DateField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SegmentedControlField from 'components/lib/form/SegmentedControlField';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import ToggleField from 'components/lib/form/ToggleField';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PremiumBadge from 'components/premium/PremiumBadge';
import PremiumFeatureOverlayTrigger from 'components/premium/PremiumFeatureOverlayTrigger';
import MerchantSearchSelect from 'components/transactions/MerchantSearchSelect';
import { SET_TRANSACTION_TAGS_MUTATION } from 'components/transactions/drawer/TransactionDrawer';
import TransactionDrawerFieldCell from 'components/transactions/drawer/TransactionDrawerFieldCell';
import TransactionDrawerFieldRow from 'components/transactions/drawer/TransactionDrawerFieldRow';
import TransactionTagSelect from 'components/transactions/tags/TransactionTagSelect';

import { CREATE_TRANSACTION_MUTATION } from 'common/lib/graphQl/transactions';
import { formatCurrencyCentsOptional } from 'common/utils/Currency';
import { isSameDay } from 'common/utils/date';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';

import { TRANSACTIONS_LIST_QUERY_NAME } from 'constants/graphql';

import type {
  Common_CreateTransactionMutation,
  CreateTransactionMutationInput,
} from 'common/generated/graphql';

const DATE_FORMAT = 'MMMM d, yyyy';
const INPUT_DATE_FORMAT = 'MMMM D, yyyy';

const FormContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: ${({ theme }) => theme.spacing.default};
`;

const SegmentedControlContainer = styled.div`
  max-width: 310px;
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

type FormValues = CreateTransactionMutationInput & {
  isCredit: boolean;
  tags: [string];
};

const transformFormValuesBeforeSubmit = ({
  isCredit,
  tags,
  ...values
}: FormValues): CreateTransactionMutationInput => ({
  ...values,
  amount: values.amount * (isCredit ? 1 : -1),
});

const getDescriptionForShouldUpdateBalance = (date: DateTime): string => {
  const isToday = isSameDay(DateTime.local(), date);
  if (isToday) {
    return "This will update the account's balance by the transaction amount";
  }

  return `This will update the account's balance by the transaction amount starting on ${date.toFormat(DATE_FORMAT)}`;
};

type Props = {
  accountId?: string;
  isManual?: boolean;
  onDone?: () => void;
  onCancel?: () => void;
};

const AddTransactionModal = ({ accountId, onDone, onCancel, isManual }: Props) => {
  const [createTransaction] = useMutation(CREATE_TRANSACTION_MUTATION, {
    refetchQueries: [TRANSACTIONS_LIST_QUERY_NAME],
  });
  const [setTransactionTags] = useMutation(SET_TRANSACTION_TAGS_MUTATION);

  const initialValues = useMemo(
    () => ({
      date: DateTime.local().toISODate(),
      accountId,
      isCredit: false,
      shouldUpdateBalance: true,
    }),
    [accountId],
  );

  const onSubmitSuccess = async (response?: Common_CreateTransactionMutation) => {
    const transactionId = response?.createTransaction?.transaction?.id;

    if (RA.isNotNil(tags) && transactionId) {
      await setTransactionTags({ variables: { input: { transactionId, tagIds: tags } } });
    }

    setTags([]);
    onDone?.();
  };

  const [isLoadingAccounts, allAccounts] = useAccountSelectOptions();
  const [tags, setTags] = useState([]);

  return (
    <ModalCard title="Add transaction">
      <Form
        onSubmitSuccess={onSubmitSuccess}
        initialValues={initialValues}
        // @ts-ignore - type narrowing with `isCredit` is not working between this function and mutation
        transformValuesBeforeSubmit={transformFormValuesBeforeSubmit}
        // @ts-ignore - type narrowing with `isCredit` is not working between this function and mutation
        mutation={createTransaction}
      >
        <FormContainer>
          <SegmentedControlContainer>
            <SegmentedControlField
              name="isCredit"
              options={[
                { title: 'Debit', value: false, icon: 'minus-circle' },
                { title: 'Credit', value: true, icon: 'plus-circle' },
              ]}
              hideLabel
            />
          </SegmentedControlContainer>
          <CurrencyField
            name="amount"
            placeholder="$0.00"
            autoComplete="off"
            maskOptions={{ allowDecimal: true }}
            required
          />
          <SelectField
            name="merchantName"
            label="Merchant"
            placeholder="Search merchants..."
            InputComponent={MerchantSearchSelect}
            required
            defaultOptions
          />
          <DateField name="date" displayFormat={INPUT_DATE_FORMAT} required />

          {!accountId && (
            <SelectField
              name="accountId"
              placeholder="Select an account"
              label="Account"
              menuPortalTarget={document.body}
              required={!accountId}
              isLoading={isLoadingAccounts}
              options={allAccounts}
            />
          )}

          <SelectField
            name="categoryId"
            label="Category"
            placeholder="Search categories..."
            required
            InputComponent={CategorySelect}
            isCreatable
          />
          <TextField name="notes" placeholder="Add a note..." autoComplete="off" />

          <PremiumFeatureOverlayTrigger feature={ProductFeature.tags}>
            {({ hasAccess }) => (
              <TransactionDrawerFieldRow label="Tags" right={!hasAccess && <PremiumBadge />}>
                <SelectField
                  name="tags"
                  InputComponent={TransactionTagSelect}
                  hideLabel
                  isDisabled={!hasAccess}
                  onChange={setTags}
                />
              </TransactionDrawerFieldRow>
            )}
          </PremiumFeatureOverlayTrigger>

          <FormContext.Consumer>
            {({ values }) => {
              // Coming from account details, account is already known
              if (accountId) {
                if (!isManual) {
                  return null;
                }
              }

              const transactionDate = DateTime.fromISO(values.date ?? '');
              if (!transactionDate) {
                return null;
              }

              const selectedAccount = !accountId
                ? R.find(
                    R.propEq('value', values.accountId),
                    R.flatten(R.map(R.prop('options'), allAccounts)),
                  )
                : undefined;

              if (selectedAccount && !selectedAccount?.accountData?.isManual) {
                return null;
              }

              return (
                <TransactionDrawerFieldCell
                  title={`Adjust the account balance by ${formatCurrencyCentsOptional(
                    values.amount ?? 0,
                  )}`}
                  description={getDescriptionForShouldUpdateBalance(transactionDate)}
                  rightAccessory={<ToggleField name="shouldUpdateBalance" hideLabel />}
                />
              );
            }}
          </FormContext.Consumer>
        </FormContainer>
        <CardFooter>
          <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
          <FormSubmitButton size="small">Add transaction</FormSubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

export default AddTransactionModal;
