import pluralize from 'pluralize';
import * as React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import type { AccountLogoProps } from 'components/accounts/AccountLogo';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { formatCurrency } from 'common/utils/Currency';
import { formatThousands } from 'common/utils/Number';

import routes from 'constants/routes';

type InvestmentsHoldingDrawerAccountFields = {
  id: string;
  displayName: string | null;
  subtype: { display: string | null };
};

type Props = {
  account: InvestmentsHoldingDrawerAccountFields & AccountLogoProps;
  quantity?: number | null;
  value?: number | null;
};

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  cursor: pointer;
  gap: ${({ theme }) => theme.spacing.small};
  padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.xlarge}`};
  transition: ${({ theme }) => theme.transition.default};
  &:hover {
    color: inherit;
    background-color: ${({ theme }) => theme.color.grayBackground};
  }
  :not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.color.grayLight};
  }
`;

const SecurityInfoContainer = styled(FlexContainer)`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  align-items: center;
  justify-content: space-between;
`;

const GeneralSecurityInfoContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  align-items: flex-start;
`;

const HoldingValueContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  align-items: center;
  > * {
    width: 100%;
  }
`;

const InvestmentsHoldingDrawerAccountRow = ({ account, quantity, value }: Props) => {
  const history = useHistory();
  const handleRowClick = () => {
    account?.id && history.push(routes.accounts.accountDetails({ id: account.id }));
  };
  return (
    <Root onClick={handleRowClick}>
      <AccountLogo logoUrl={account.logoUrl} icon={account.icon} size={24} />
      <SecurityInfoContainer>
        <GeneralSecurityInfoContainer>
          <Text>{account?.displayName}</Text>
          <Text size="xsmall" color="textLight">
            {account.subtype?.display}
          </Text>
        </GeneralSecurityInfoContainer>
        <HoldingValueContainer>
          <Text size="base" color="text" align="right">
            {formatCurrency(value)}
          </Text>
          <Text size="xsmall" color="textLight" align="right">
            {`${formatThousands(quantity ?? 0)} ${pluralize('share', quantity ?? 0)}`}
          </Text>
        </HoldingValueContainer>
      </SecurityInfoContainer>
    </Root>
  );
};

export default InvestmentsHoldingDrawerAccountRow;
