import { useState, useEffect } from 'react';

const STATUS_DATA_ATTRIBUTE = 'data-status';

type Status = 'loading' | 'ready' | 'error';

/**
 * Hook used to load an external script asynchronously at runtime.
 *
 * Inspiration taken from https://usehooks.com/useScript/
 */
const useScript = (src: string) => {
  const [status, setStatus] = useState<Status>('loading');

  useEffect(() => {
    // Fetch existing script element by src
    // It may have been added by another intance of this hook
    let script: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);

    if (!script) {
      // Create script
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute(STATUS_DATA_ATTRIBUTE, 'loading');
      document.body.appendChild(script);
    } else {
      // Grab existing script status from attribute and set to state.
      const existingStatus = (script.getAttribute(STATUS_DATA_ATTRIBUTE) as Status) ?? 'loading';
      setStatus(existingStatus);
    }

    const setStatusFromEvent = (event: Event) => {
      const newStatus = event.type === 'load' ? 'ready' : 'error';
      script?.setAttribute(STATUS_DATA_ATTRIBUTE, newStatus);
      setStatus(newStatus);
    };

    script.addEventListener('load', setStatusFromEvent);
    script.addEventListener('error', setStatusFromEvent);

    return () => {
      script?.removeEventListener('load', setStatusFromEvent);
      script?.removeEventListener('error', setStatusFromEvent);
    };
  }, [src]);

  const loading = status === 'loading';
  const error = status === 'error';

  return [loading, error] as const;
};

export default useScript;
