import { isNil } from 'ramda';
import React, { useState } from 'react';
import styled from 'styled-components';

import GoalImage from 'components/goalsV2/GoalImage';
import FormItemContainer from 'components/lib/form/FormItemContainer';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Column from 'components/lib/ui/Column';
import Dot from 'components/lib/ui/Dot';
import Drawer from 'components/lib/ui/Drawer';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Row from 'components/lib/ui/Row';
import Text from 'components/lib/ui/Text';
import IconButton from 'components/lib/ui/button/IconButton';
import MerchantLogo from 'components/merchants/MerchantLogo';

import formatCategory from 'common/lib/categories/formatCategory';
import { color, spacing, radius } from 'common/lib/theme/dynamic';
import { getDescriptionForReviewStatus, ReviewStatus } from 'common/lib/transactions/review';
import formatTransactionAmount, {
  isIncome as getIsIncome,
} from 'common/utils/formatTransactionAmount';

import type { Common_TransactionSplitQuery } from 'common/generated/graphql';

const COLUMN_GUTTER_PX = 49;
const DOT_SIZE_PX = 14;

const OriginalTransactionContainer = styled(FlexContainer).attrs({ column: true })`
  margin: ${spacing.xsmall} ${spacing.xlarge};
  border-radius: ${radius.medium};
  background-color: ${color.grayBackground};
`;

const OriginalTransactionHeaderContainer = styled(FlexContainer).attrs({
  alignCenter: true,
  padding: 'xsmall',
  gap: 'xsmall',
})<{
  $open: boolean;
}>`
  border-bottom: ${({ $open }) => ($open ? 1 : 0)}px solid ${color.grayFocus};
  padding-right: ${COLUMN_GUTTER_PX}px;
`;

const OriginalTransactionBodyContainer = styled.div`
  padding-top: ${spacing.small};
`;

const OriginalFieldColumn = styled(Column)`
  display: flex;
  align-items: center;
  padding: ${spacing.xsmall} ${spacing.small};
`;

const OriginalAmountColumn = styled(OriginalFieldColumn)`
  justify-content: flex-end;
`;

const Amount = styled.span<{ isIncome: boolean }>`
  color: ${({ isIncome, theme }) => (isIncome ? theme.color.green : theme.color.text)};
`;

const OriginalTransactionDetailsRowContainer = styled(FlexContainer).attrs({
  alignCenter: true,
  gap: 'xsmall',
})`
  padding: 0 ${COLUMN_GUTTER_PX}px ${spacing.large};
`;

const StyledFormItemContainer = styled(FormItemContainer)`
  align-self: flex-start;
`;

const StyledText = styled(Text).attrs({ size: 'base', weight: 'book' })<{ empty?: boolean }>`
  color: ${({ empty, theme }) => (empty ? theme.color.textLight : theme.color.text)};
`;

const TagsContainer = styled(FlexContainer).attrs({
  alignCenter: true,
  justifyStart: true,
  wrap: true,
})`
  row-gap: ${spacing.xxsmall};
  column-gap: ${spacing.default};
`;

const RoundedGoalImage = styled(GoalImage).attrs({ showGradient: false, size: 'small' })`
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 4px;
`;

const TransactionSplitOriginalTransactionContainer = ({
  originalTransaction,
}: {
  originalTransaction: Common_TransactionSplitQuery['getTransaction'];
}) => {
  const {
    merchant,
    category,
    amount = 0,
    dataProviderDescription,
    goal,
    tags,
    reviewedAt,
    needsReviewByUser,
    reviewStatus,
    notes,
  } = originalTransaction ?? {};
  const isIncome = getIsIncome(amount);

  const [isHeaderOpen, setIsHeaderOpen] = useState<boolean>(false);

  let reviewStatusText = '';
  if (reviewStatus === ReviewStatus.NeedsReview) {
    reviewStatusText = getDescriptionForReviewStatus(
      reviewStatus,
      needsReviewByUser?.name,
      isNil(needsReviewByUser),
    );
  } else if (reviewedAt) {
    reviewStatusText = 'Reviewed';
  } else {
    reviewStatusText = 'Not reviewed';
  }

  return (
    <OriginalTransactionContainer>
      <OriginalTransactionHeaderContainer $open={isHeaderOpen}>
        <IconButton
          icon={isHeaderOpen ? 'chevron-down' : 'chevron-right'}
          onClick={() => setIsHeaderOpen(!isHeaderOpen)}
        />
        <Row padding={0}>
          <OriginalFieldColumn xs={4} {...sensitiveClassProps}>
            <FlexContainer alignCenter gap="small">
              <FlexContainer alignCenter gap="xsmall">
                <MerchantLogo url={merchant?.logoUrl} size={20} />
                {merchant?.name}
              </FlexContainer>
            </FlexContainer>
          </OriginalFieldColumn>
          <OriginalFieldColumn xs={4}>{category && formatCategory(category)}</OriginalFieldColumn>
          <OriginalAmountColumn xs={4}>
            <Amount isIncome={isIncome} {...sensitiveClassProps}>
              {formatTransactionAmount(amount)}
            </Amount>
          </OriginalAmountColumn>
        </Row>
      </OriginalTransactionHeaderContainer>
      <Drawer open={isHeaderOpen}>
        <OriginalTransactionBodyContainer>
          <OriginalTransactionDetailsRowContainer>
            <Row padding={0}>
              <Column xs={4} {...sensitiveClassProps}>
                <StyledFormItemContainer
                  name="originalTransactionOriginalStatement"
                  fieldId="originalTransactionOriginalStatement"
                  label="Original statement"
                >
                  <StyledText empty={!dataProviderDescription}>
                    {dataProviderDescription || 'No original statement'}
                  </StyledText>
                </StyledFormItemContainer>
              </Column>
              <Column xs={4} {...sensitiveClassProps}>
                <StyledFormItemContainer
                  name="originalTransactionNotes"
                  fieldId="originalTransactionNotes"
                  label="Notes"
                >
                  <StyledText empty={!notes}>{notes || 'No notes'}</StyledText>
                </StyledFormItemContainer>
              </Column>
            </Row>
          </OriginalTransactionDetailsRowContainer>
          <OriginalTransactionDetailsRowContainer>
            <Row padding={0}>
              <Column xs={4} {...sensitiveClassProps}>
                <StyledFormItemContainer
                  name="originalTransactionTags"
                  fieldId="originalTransactionTags"
                  label="Tags"
                >
                  {tags?.length === 0 && <StyledText empty>No tags selected</StyledText>}
                  <TagsContainer>
                    {tags?.map((tag) => (
                      <FlexContainer key={tag.id} alignCenter justifyStart gap="xsmall">
                        <Dot size={DOT_SIZE_PX} color={tag.color} />
                        <Text size="small">{tag.name}</Text>
                      </FlexContainer>
                    ))}
                  </TagsContainer>
                </StyledFormItemContainer>
              </Column>
              <Column xs={4} {...sensitiveClassProps}>
                <StyledFormItemContainer
                  name="originalTransactionGoal"
                  fieldId="originalTransactionGoal"
                  label="Goal"
                >
                  <FlexContainer alignCenter gap="small">
                    {goal && (
                      <RoundedGoalImage
                        imageStorageProvider={goal.imageStorageProvider}
                        imageStorageProviderId={goal.imageStorageProviderId}
                      />
                    )}
                    <StyledText empty={!goal}>{goal?.name ?? 'Not linked to goal'}</StyledText>
                  </FlexContainer>
                </StyledFormItemContainer>
              </Column>
              <Column xs={4} {...sensitiveClassProps}>
                <StyledFormItemContainer
                  name="originalTransactionReviewStatus"
                  fieldId="originalTransactionReviewStatus"
                  label="Review status"
                >
                  <FlexContainer justifyStart alignCenter gap="xsmall">
                    {reviewedAt && <Icon name="check" size={16} />}
                    <StyledText empty={isNil(reviewStatus)}>{reviewStatusText}</StyledText>
                  </FlexContainer>
                </StyledFormItemContainer>
              </Column>
            </Row>
          </OriginalTransactionDetailsRowContainer>
        </OriginalTransactionBodyContainer>
      </Drawer>
    </OriginalTransactionContainer>
  );
};

export default TransactionSplitOriginalTransactionContainer;
