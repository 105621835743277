import * as React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import WindowBanner from 'components/lib/ui/WindowBanner';
import NavLink from 'components/lib/ui/link/NavLink';

import { useDispatch } from 'lib/hooks';
import { logoutAction } from 'state/user/thunks';

import routes from 'constants/routes';

const Text = styled.span`
  grid-column: 2 / 2;
  text-align: center;
`;

const StyledIcon = styled(Icon)`
  transform: translateY(2px);
  margin-left: ${({ theme }) => (!isMobile ? theme.spacing.xsmall : 0)};
`;

const LinkContainer = styled(FlexContainer).attrs({ alignCenter: true, justifyEnd: true })`
  text-align: right;
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const DemoBanner = () => {
  const dispatch = useDispatch();

  return (
    <WindowBanner>
      <Text>You are in demo mode, some features will be disabled.</Text>
      <LinkContainer>
        <NavLink to={routes.signup()} onClick={() => dispatch(logoutAction())}>
          Sign up {!isMobile ? 'for Monarch' : ''}
          <StyledIcon name="arrow-right" />
        </NavLink>
      </LinkContainer>
    </WindowBanner>
  );
};

export default styled(DemoBanner)``;
