import type { PaymentMethod } from '@stripe/stripe-js';
import pluralize from 'pluralize';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import StripeCardInputForm from 'components/lib/external/StripeCardInputForm';
import StripeProvider from 'components/lib/external/StripeProvider';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';
import PromoCodeInput from 'components/settings/billing/PromoCodeInput';
import SponsoredSubscriptionBanner from 'components/settings/billing/SponsoredSubscriptionBanner';
import SubscriptionOption from 'components/settings/billing/SubscriptionOption';

import { getDaysLeftOfTrial, getYearlyBannerText } from 'common/lib/billing/Billing';

import {
  PaymentPeriod,
  SubscriptionDetailsPaymentSource,
} from 'common/generated/graphQlTypes/globalTypes';
import type { Web_GetSubscriptionModalQuery } from 'common/generated/graphql';

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.large};
`;

const Footer = styled(FlexContainer)`
  margin-top: ${({ theme }) => theme.spacing.large};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const SubmitButton = styled(AsyncButton)`
  ${primaryButtonMixin}
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const SubscriptionSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaymentText = styled(Text)`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  margin-top: ${({ theme }) => theme.spacing.large};
`;

const GrayTextButton = styled(TextButton)`
  margin-top: ${({ theme }) => theme.spacing.large};
  color: ${({ theme }) => theme.color.textLight};
`;

const SponsoredSubscriptionBannerWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.large};
`;

type Props = {
  hasPremiumEntitlement: boolean;
  willCancelAtPeriodEnd: boolean;
  plans: Web_GetSubscriptionModalQuery['subscriptionOffering']['plans'][0][];
  promoCodeDescription: string | undefined | null;
  promoCodeError: string | undefined | null;
  daysLeftOfTrial: number | undefined | null;
  hasPaymentMethod: boolean;
  paymentSource: SubscriptionDetailsPaymentSource | undefined | null;
  currentBillingPeriod: PaymentPeriod | undefined | null;
  selectedPlanIndex: number;
  setSelectedPlanIndex: (i: number) => void;
  setLoading: () => void;
  setNotLoading: () => void;
  appliedPromoCode: string | null;
  setAppliedPromoCode: (code: string | null) => void;
  createSubscription: (
    paymentMethod: PaymentMethod | null,
    stripePriceId: string,
    stripePromoCode: string | null,
  ) => Promise<void>;
  reactivateSubscription: (
    paymentMethod: PaymentMethod | null,
    stripePriceId: string,
    stripePromoCode: string | null,
  ) => Promise<void>;
  changeSubscription: (stripePriceId: string, stripePromoCode: string | null) => Promise<void>;
  hasStripeSubscriptionId: boolean;
  onClickCancelSubscription: () => void;
  onDone: () => void;
  isLoading: boolean;
  referralRedemption: Web_GetSubscriptionModalQuery['subscription']['referralRedemption'];
};

const SubscriptionModalBody = ({
  hasPremiumEntitlement,
  willCancelAtPeriodEnd,
  plans,
  selectedPlanIndex,
  setSelectedPlanIndex,
  promoCodeDescription,
  promoCodeError,
  daysLeftOfTrial,
  hasPaymentMethod,
  paymentSource,
  currentBillingPeriod,
  isLoading,
  setLoading,
  setNotLoading,
  appliedPromoCode,
  createSubscription,
  reactivateSubscription,
  changeSubscription,
  hasStripeSubscriptionId,
  onClickCancelSubscription,
  setAppliedPromoCode,
  onDone,
  referralRedemption,
}: Props) => {
  const selectedPlan = plans[selectedPlanIndex] ?? {};
  const actualDaysLeftOfTrial = selectedPlan?.newTrialEndsAt
    ? getDaysLeftOfTrial(selectedPlan?.newTrialEndsAt)
    : daysLeftOfTrial;
  const hideCreditCardInput = hasPaymentMethod || !selectedPlan.requirePaymentMethod;

  const subscriptionPrice =
    selectedPlan.discountedPricePerPeriodDollars ?? selectedPlan.pricePerPeriodDollars;

  const handleStripeCardInputFormSuccess = async (paymentInfo: PaymentMethod | null) => {
    const paymentMethodPresent = hasPaymentMethod || RA.isNotNil(paymentInfo);
    setLoading();
    try {
      const { stripeId: priceId } = selectedPlan;
      const maybePromoCode = promoCodeDescription ? appliedPromoCode : null;

      if (!hasPremiumEntitlement) {
        if (!paymentMethodPresent && selectedPlan.requirePaymentMethod) {
          throw Error('No card entered! Something is wrong with the given subscription');
        }
        await createSubscription(paymentInfo, priceId, maybePromoCode);
      } else if (willCancelAtPeriodEnd) {
        if (paymentMethodPresent && hasStripeSubscriptionId) {
          await reactivateSubscription(paymentInfo, priceId, maybePromoCode);
        } else {
          if (!paymentMethodPresent && selectedPlan.requirePaymentMethod) {
            throw Error('No card entered! Something is wrong with the given subscription');
          }
          await createSubscription(paymentInfo, priceId, maybePromoCode);
        }
      } else {
        await changeSubscription(priceId, maybePromoCode);
      }
    } finally {
      setNotLoading();
    }
    onDone();
  };

  const userHasActiveReferralCampaign = !!referralRedemption?.campaign;

  return (
    <CardBody>
      <SubscriptionSection>
        {plans.map(
          (
            { name, pricePerPeriodDollars, discountedPricePerPeriodDollars, period, stripeId },
            i,
          ) => (
            <SubscriptionOption
              checked={selectedPlan.stripeId === stripeId}
              key={name}
              name={name}
              promoBadgeText={getYearlyBannerText(plans, period, {
                compareYearlyOnly: userHasActiveReferralCampaign,
              })}
              infoBadgeText={
                paymentSource === SubscriptionDetailsPaymentSource.STRIPE &&
                hasPremiumEntitlement &&
                currentBillingPeriod === period
                  ? 'current'
                  : undefined
              }
              price={pricePerPeriodDollars}
              discountedPrice={discountedPricePerPeriodDollars}
              period={period}
              onClick={() => setSelectedPlanIndex(i)}
            />
          ),
        )}
      </SubscriptionSection>

      {!hideCreditCardInput && (
        <PaymentText weight="medium" size="xsmall">
          Payment Method
        </PaymentText>
      )}
      <StripeCardInputForm
        skipPaymentMethod={hideCreditCardInput}
        onSuccess={handleStripeCardInputFormSuccess}
      >
        {({ isValid, isLoading: isStripeInputLoading }) => (
          <>
            <PaymentText weight="medium" size="xsmall">
              Promo Code (Optional)
            </PaymentText>
            <PromoCodeInput
              value={appliedPromoCode}
              onChange={setAppliedPromoCode}
              description={promoCodeDescription}
              error={promoCodeError}
            />
            {selectedPlan?.sponsoredBy && (
              <SponsoredSubscriptionBannerWrapper>
                <SponsoredSubscriptionBanner
                  hideRevokeAccessButton
                  sponsorName={selectedPlan?.sponsoredBy?.name}
                  sponsorProfilePictureUrl={selectedPlan?.sponsoredBy?.profilePictureUrl}
                />
              </SponsoredSubscriptionBannerWrapper>
            )}
            <Footer column>
              {!!actualDaysLeftOfTrial && actualDaysLeftOfTrial > 0 && subscriptionPrice > 0 && (
                <Text weight="medium" color="textLight">
                  {`You won't be charged until your trial ends in ` +
                    `${actualDaysLeftOfTrial} ${pluralize('days', actualDaysLeftOfTrial)}.`}
                </Text>
              )}
              {!!actualDaysLeftOfTrial && actualDaysLeftOfTrial <= 0 && (
                <Text weight="medium" color="orangeDark">
                  {`Your trial has ended. Your subscription will be charged today.`}
                </Text>
              )}
              <SubmitButton
                size="medium"
                disabled={!isValid}
                type="submit"
                pending={isStripeInputLoading || isLoading}
              >
                {`Subscribe for $${subscriptionPrice} / ${
                  selectedPlan.period === PaymentPeriod.MONTHLY ? 'month' : 'year'
                }`}
              </SubmitButton>
              {hasPremiumEntitlement && !willCancelAtPeriodEnd ? (
                <GrayTextButton onClick={onClickCancelSubscription}>
                  Cancel subscription
                </GrayTextButton>
              ) : null}
            </Footer>
          </>
        )}
      </StripeCardInputForm>
    </CardBody>
  );
};

const ProviderWrapper = (props: Props) => (
  <StripeProvider>
    <SubscriptionModalBody {...props} />
  </StripeProvider>
);

export default ProviderWrapper;
