import { useMutation } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import ManualAccountLogo from 'components/accounts/ManualAccountLogo';
import InstitutionLogo from 'components/institutions/InstitutionLogo';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { dangerButtonMixin } from 'components/lib/ui/button/DangerButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { DELETE_CREDENTIAL_MUTATION } from 'common/lib/graphQl/accounts';
import useToast from 'lib/hooks/useToast';

import markUrl from 'static/images/mark-white-large.png';

import type { DeleteCredentialMutation } from 'common/generated/graphQlTypes/DeleteCredentialMutation';
import type { Web_GetInstitutionSettingsQuery } from 'common/generated/graphql';

const DangerConfirmButton = styled(AsyncButton)`
  ${dangerButtonMixin}
`;

const Title = styled(Text).attrs({ weight: 'medium', size: 'medium', color: 'text' })`
  text-align: center;
`;

const Description = styled(Text)`
  text-align: center;
`;

const HeaderLessCardBody = styled(CardBody)`
  padding-top: 0;
`;

const LogoBackground = styled.div`
  background-color: ${({ theme }) => theme.color.orangeLight};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;

  border-radius: ${({ theme }) => theme.radius.medium};
`;

const MonarchLogo = styled.img`
  width: 30px;
  height: 30px;
`;

const Dash = styled.div`
  width: 10px;
  height: 0px;

  border: 1px solid ${({ theme }) => theme.color.redLight};
  border-radius: ${({ theme }) => theme.radius.small};
`;

const LogoHeader = styled(FlexContainer)`
  gap: 5px;
`;

const DisconnectIcon = styled(Icon).attrs({ name: 'x-circle', size: 22 })`
  color: ${({ theme }) => theme.color.redLight};
`;

type Props = {
  credential: Web_GetInstitutionSettingsQuery['credentials'][0];
  onCompleted: () => void;
};

const DeleteCredentialConfirmation = ({ credential, onCompleted }: Props) => {
  const [deleteCredential, { loading: deleteCredentialLoading }] =
    useMutation<DeleteCredentialMutation>(DELETE_CREDENTIAL_MUTATION);

  const { openErrorToast } = useToast();

  const onConfirmDeleteCredential = () => {
    deleteCredential({
      variables: { input: { credentialId: credential.id } },
      onError: () => {
        openErrorToast({
          description: 'An error occurred while deleting this credential. Please try again.',
        });
      },
      onCompleted: (data) => {
        if (!data.deleteCredential?.deleted) {
          openErrorToast({
            description: 'An error occurred while deleting this credential. Please try again.',
          });
          return;
        }

        onCompleted();
      },
    });
  };

  return (
    <>
      <ModalCard hideBottomBorder>
        <HeaderLessCardBody>
          <LogoHeader justifyCenter alignCenter>
            <LogoBackground>
              <MonarchLogo src={markUrl} alt="" />
            </LogoBackground>
            <Dash />
            <DisconnectIcon />
            <Dash />
            {credential.institution.logo ? (
              <InstitutionLogo logo={credential.institution.logo} />
            ) : (
              <ManualAccountLogo icon="dollar-sign" />
            )}
          </LogoHeader>
          <Title>Are you sure you want to delete this connection?</Title>
          <Description>
            This will delete all accounts and transactions connected to this institution. This
            action cannot be undone.
          </Description>
        </HeaderLessCardBody>
        <CardFooter>
          <DefaultButton onClick={onCompleted} disabled={deleteCredentialLoading}>
            Cancel
          </DefaultButton>
          <DangerConfirmButton
            onClick={onConfirmDeleteCredential}
            pending={deleteCredentialLoading}
          >
            Delete
          </DangerConfirmButton>
        </CardFooter>
      </ModalCard>
    </>
  );
};

export default DeleteCredentialConfirmation;
