import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import type { Spacing } from 'common/types/Styles';

const ICON_SIZE_PT = 16;
const STEPS = [
  {
    icon: 'institution',
    title: 'Find your institution',
  },
  {
    icon: 'lock',
    title: 'Connect it securely to Monarch',
  },
  {
    icon: 'pie-chart',
    title:
      'Monarch syncs your transactions and analyzes your balances, cash flow, and investments.',
  },
];

const Root = styled.div``;

const Section = styled(FlexContainer).attrs({ column: true })`
  padding: 19px ${({ theme }) => theme.spacing.large};

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  }
`;

const Title = styled(Text).attrs({ size: 'large', weight: 'medium' })``;

const Subtitle = styled(Text).attrs({ size: 'small', color: 'textLight' })<{
  $topMargin?: Spacing;
}>`
  margin-top: ${({ $topMargin, theme }) => ($topMargin ? theme.spacing[$topMargin] : '0')};
`;

const IconCircle = styled(FlexContainer).attrs({ center: true })`
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => theme.radius.round};
  background-color: ${({ theme }) => theme.color.grayBackground};
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const Step = styled(FlexContainer).attrs({ alignCenter: true })`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const LogosContainer = styled(FlexContainer).attrs({ center: true })`
  margin: ${({ theme }) => theme.spacing.xxlarge} 0;
`;

type Props = {
  className?: string;
};

const OnboardingConnectAccountSidebar = ({ className }: Props) => (
  <Root className={className}>
    <Section>
      <Title>Get started in under 5 minutes</Title>
      {STEPS.map(({ icon, title }, i) => (
        <Step key={i}>
          <IconCircle>
            <Icon name={icon} size={ICON_SIZE_PT} />
          </IconCircle>
          <Subtitle>{title}</Subtitle>
        </Step>
      ))}
    </Section>
    <Section>
      <Title>Trusted by thousands</Title>
      <Subtitle $topMargin="default">
        “I tried YNAB, I tried Mint, I use Monarch. Soooo much more intuitive and the UI/UX is
        delightful.”
      </Subtitle>
      <Subtitle align="right" $topMargin="small">
        - Joshua C.
      </Subtitle>
      <LogosContainer>
        <img
          src="https://monarch-static-assets.s3.amazonaws.com/onboarding/featured-in-logos.svg"
          alt="Business Insider, Fast Company, Product Hunt"
        />
      </LogosContainer>
    </Section>
  </Root>
);

export default OnboardingConnectAccountSidebar;
