import React from 'react';
import styled, { css } from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import Tooltip from 'components/lib/ui/Tooltip';

import useTheme from 'lib/hooks/useTheme';

type StatusIndicatorTheme = 'default' | 'colorIndicatorOnly';

const Root = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  /* @ts-ignore */
  user-select: none;
`;

const Indicator = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${({ color }) => color};
`;

const Content = styled.div<{ type: StatusIndicatorTheme; color?: string }>`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  text-align: right;

  ${({ type, theme, color }) =>
    type === 'colorIndicatorOnly'
      ? css`
          color: ${theme.color.text};
          font-weight: ${theme.fontWeight.book};
          font-size: ${theme.fontSize.base};
        `
      : css`
          color: ${color};
          font-size: ${theme.fontSize.small};
          font-weight: ${theme.fontWeight.medium};
        `}
`;

type Props = {
  color: string;
  tooltip?: string;
  className?: string;
  children?: React.ReactNode;
  isDisconnected?: boolean;
  type?: StatusIndicatorTheme;
};

const StatusIndicator = ({
  color,
  tooltip,
  className,
  children,
  isDisconnected = false,
  type = 'default',
}: Props) => {
  const theme = useTheme();
  const parsedColor = isDisconnected ? theme.color.red : color;
  return (
    <Tooltip place="top" effect="solid" content={tooltip}>
      <Root className={className}>
        <Indicator color={parsedColor} />
        <Switch>
          <Case when={isDisconnected}>
            <Content type={type} color={parsedColor}>
              Disconnected
            </Content>
          </Case>
          <Case when={Boolean(children && React.Children.count(children) > 0)}>
            <Content type={type} color={parsedColor}>
              {children}
            </Content>
          </Case>
        </Switch>
      </Root>
    </Tooltip>
  );
};

export default StatusIndicator;
