import color from 'common/lib/theme/variables/color';
import typography from 'common/lib/theme/variables/typography';

/**
 * Theme variables for the Monarch design system.
 *
 * Semantic and abstracted variables that can be used to style components.
 */
const variables = {
  color,
  typography,
};

export default variables;
