import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { Column } from 'react-table';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import CsvTable from 'components/accounts/CsvTable';
import InstitutionLogo from 'components/institutions/InstitutionLogo';
import AccountSelectField from 'components/lib/form/AccountSelectField';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import Scroll from 'components/lib/ui/Scroll';
import Text from 'components/lib/ui/Text';
import Table from 'components/lib/ui/table/Table';

import { mergeCurrentUploadSession } from 'actions';
import { escapeFieldName, unescapeFieldName } from 'common/lib/form/field';
import { formatThousands } from 'common/utils/Number';
import { MINT_ACCOUNT_NAME_COLUMN_INDEX } from 'lib/csv/validation';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';
import useDispatch from 'lib/hooks/useDispatch';
import useModal from 'lib/hooks/useModal';
import { getCurrentUploadSession } from 'selectors';

import { MINT_LOGO_BASE_64 } from 'common/constants/accounts';

import type { ArrayType } from 'common/types/utility';

export const IGNORE_ACCOUNT_VALUE = 'ignore';

const TABLE_INITIAL_STATE = { sortBy: [{ id: 'transactionCount', desc: true }] };

const PREVIEW_ROW_COUNT = 100;

const Root = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  max-width: 765px;
  width: 100%;
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyledSubmitButton = styled(FormSubmitButton)`
  width: 100%;
  max-width: 438px;
  margin-top: 24px;
  margin-bottom: 80px;
`;

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TableContainer = styled.div`
  width: 100%;
  text-align: left;
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  border-radius: ${({ theme }) => theme.radius.medium};
  margin: ${({ theme }) => theme.spacing.xxlarge} 0;

  > * {
    margin-bottom: 0;
  }
`;

const LeftAlignHeader = styled(Text).attrs({ align: 'left ' })`
  display: block;
`;

const PreviewModalCard = styled(ModalCard)`
  overflow: hidden;
`;

const PreviewScroll = styled(Scroll)`
  max-height: 60vh;
`;

const MinAccountCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const ClickableText = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: ${({ theme }) => theme.spacing.xxsmall};

  :hover {
    color: ${({ theme }) => theme.color.blue};
  }
`;

const Subtitle = styled(Text)`
  text-align: center;
`;

/** Saves form values in redux in case the user leaves the page and comes back */
const CacheFormValues = () => {
  const dispatch = useDispatch();
  const { values } = useFormContext<{ [key: string]: string }>();

  useEffect(() => {
    dispatch(mergeCurrentUploadSession({ accountNameMapping: values }));
  }, [values, dispatch]);

  return null;
};

type Props = {
  parsedRows?: string[][];
  onSubmit: (values: { mapping: Record<string, string>; importPriority: string }) => Promise<any>;
};

const ImportMintMapAccounts = ({ parsedRows, onSubmit }: Props) => {
  const { accountNameMapping } = useSelector(getCurrentUploadSession) ?? {};
  const header = useMemo(() => parsedRows?.[0] ?? [], [parsedRows]);

  const rowsByAccountName = useMemo(
    () =>
      R.groupBy(
        (row) => row[MINT_ACCOUNT_NAME_COLUMN_INDEX],
        R.drop(1, parsedRows ?? []).filter((val) => val.length > 1) as string[][], // First row is CSV header
      ),
    [parsedRows],
  );

  const initialValues = useMemo(
    () =>
      R.assoc(
        'importPriority',
        'statement_transactions',
        R.fromPairs(
          R.toPairs(rowsByAccountName).map(([accountName]) => [
            escapeFieldName(accountName),
            IGNORE_ACCOUNT_VALUE,
          ]),
        ),
      ),
    [],
  );

  // Total of transactions the user already selected an account and it is different from IGNORE_ACCOUNT_VALUE
  const totalNotIgnoredTransactions = useMemo(() => {
    if (!accountNameMapping) {
      return 0;
    }

    const accountsToCount = R.filter(
      (account) =>
        account !== 'importPriority' && accountNameMapping[account] !== IGNORE_ACCOUNT_VALUE,
      R.keys(accountNameMapping),
    );

    return R.pipe(
      R.map((account) => R.length(rowsByAccountName[account as string])),
      R.sum,
    )(accountsToCount);
  }, [accountNameMapping]);

  const tableData = useMemo(
    () =>
      R.toPairs(rowsByAccountName).map(([accountName, rows]) => ({
        accountName,
        transactionCount: rows.length,
      })),
    [rowsByAccountName],
  );

  const isSubmitDisabled = useMemo(
    () => totalNotIgnoredTransactions <= 0,
    [totalNotIgnoredTransactions],
  );

  const [isLoadingAccounts, accountOptions, { refetch: refetchAccountOptions }] =
    useAccountSelectOptions();

  const [PreviewRowsModal, { open: openPreviewRowsModal, context: previewingAccountName }] =
    useModal<string>();

  const columns = useMemo(
    (): Column<ArrayType<typeof tableData>>[] => [
      {
        accessor: 'accountName',
        Header: 'Mint Account',
        Cell: ({ value }) => (
          <MinAccountCell>
            <InstitutionLogo size={24} logo={MINT_LOGO_BASE_64} /> <Text>{value}</Text>
          </MinAccountCell>
        ),
      },
      {
        id: 'account',
        Header: () => <LeftAlignHeader>Monarch Account</LeftAlignHeader>,
        accessor: 'accountName',
        disableSortBy: true,
        Cell: ({ value: accountName }) => (
          <AccountSelectField
            accountName={accountName}
            options={accountOptions}
            isLoading={isLoadingAccounts}
            ignoreAccountValue={IGNORE_ACCOUNT_VALUE}
            onAccountCreated={refetchAccountOptions}
          />
        ),
      },
      {
        accessor: 'transactionCount',
        Header: 'Transactions',
        sortDescFirst: true,
        Cell: ({ value, row }) => (
          <ClickableText onClick={() => openPreviewRowsModal(row.original.accountName)}>
            {formatThousands(value)}
          </ClickableText>
        ),
      },
    ],
    [isLoadingAccounts, accountOptions, refetchAccountOptions, openPreviewRowsModal],
  );

  return (
    <Root>
      <Title>Assign Mint accounts to Monarch accounts</Title>
      <Subtitle>
        Assign each Mint account to a Monarch account so we can merge the transactions to the right
        place. You have the option to ignore Mint accounts if you want to skip those transactions.
      </Subtitle>
      <StyledForm
        // @ts-ignore styled-components doesn't like generics
        onSubmit={onSubmit}
        initialValues={initialValues}
        transformValuesBeforeSubmit={(values) => ({
          mapping: R.pipe(
            R.pickBy((val) => val !== IGNORE_ACCOUNT_VALUE),
            RA.renameKeysWith(unescapeFieldName),
          )(R.omit(['importPriority'], values)),
          importPriority: values.importPriority,
        })}
      >
        <CacheFormValues />
        <TableContainer>
          <Table
            columns={columns}
            data={tableData}
            showFooter={false}
            initialState={TABLE_INITIAL_STATE}
          />
        </TableContainer>

        <StyledSubmitButton
          size="large"
          pending={false} // Don't show the loading state for this button since it's handled by the modal
          disableWhenValuesUnchanged={false}
          disabled={isSubmitDisabled}
        >
          Next
        </StyledSubmitButton>
      </StyledForm>
      <PreviewRowsModal large>
        <PreviewModalCard title={previewingAccountName}>
          <PreviewScroll>
            <CsvTable
              header={header}
              rows={R.take(PREVIEW_ROW_COUNT, rowsByAccountName[previewingAccountName ?? ''] ?? [])}
            />
          </PreviewScroll>
        </PreviewModalCard>
      </PreviewRowsModal>
    </Root>
  );
};

export default ImportMintMapAccounts;
