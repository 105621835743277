import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SingleCardPage from 'components/lib/layouts/SingleCardPage';

import getVersionApi from 'common/lib/api/version';
import api from 'lib/api';

const Root = styled(SingleCardPage)`
  display: flex;
  flex-direction: column;
`;

const Version = () => {
  const [response, setResponse] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const version = await getVersionApi(api).getVersion();
      setResponse(version);
    };
    fetchData();
  }, []);

  return (
    <Root name="Version" size="small">
      {response}
    </Root>
  );
};

export default Version;
