import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import { formatCurrency } from 'common/utils/Currency';
import { formatPercent } from 'common/utils/Number';

import { CENTS_PER_DOLLAR } from 'common/constants/currency';
import { PERCENT_TO_DECIMAL } from 'common/constants/math';
import { MONTHS_PER_YEAR } from 'common/constants/time';

import type { SubscriptionPlan, StripeCancellationDiscountOffer } from 'common/generated/graphql';
import { PaymentPeriod, CancellationReason, StripeCouponDuration } from 'common/generated/graphql';

type GetYearlyBannerTextOptions = {
  /** Use discountedPricePerPeriodDollars instead of pricePerPeriodDollars for the yearly plan */
  compareMonthlyWithDiscountedYearlyPrice?: boolean;
  /** If true, only compare the yearly plan with the discounted yearly plan */
  compareYearlyOnly?: boolean;
};

export const getDaysLeftOfTrial = (trialEndsAt?: string | null) =>
  getDaysLeftUntilEndOfPeriod(trialEndsAt);

export const getDaysLeftOfSubscription = (currentPeriodEndsAt?: string | null) =>
  getDaysLeftUntilEndOfPeriod(currentPeriodEndsAt) || 0;

const getDaysLeftUntilEndOfPeriod = (currentPeriodEndsAt?: string | null) => {
  if (!currentPeriodEndsAt) {
    return null;
  }

  // The backend returns the dates as a string in UTC time
  const dt = DateTime.fromISO(currentPeriodEndsAt, { zone: 'utc' });
  return Math.ceil(dt.diffNow('days').days);
};

export const getSubscriptionModalTitle = (
  hasPremiumEntitlement: boolean,
  willCancelAtPeriodEnd: boolean,
) => {
  if (hasPremiumEntitlement && !willCancelAtPeriodEnd) {
    return 'Change Subscription';
  } else if (hasPremiumEntitlement && willCancelAtPeriodEnd) {
    return 'Reactivate Subscription';
  } else {
    return 'Subscribe to Monarch';
  }
};

export const getYearlyBannerText = (
  plans: Pick<
    SubscriptionPlan,
    'period' | 'pricePerPeriodDollars' | 'discountedPricePerPeriodDollars'
  >[],
  period: PaymentPeriod,
  options: GetYearlyBannerTextOptions = {},
): string | undefined => {
  const { compareMonthlyWithDiscountedYearlyPrice, compareYearlyOnly } = options;

  const yearlyPlan = plans.find(({ period }) => period === PaymentPeriod.YEARLY);
  const monthlyPlan = plans.find(({ period }) => period === PaymentPeriod.MONTHLY);
  const lifetimePlan = plans.find(({ period }) => period === PaymentPeriod.LIFETIME);

  if (
    R.isNil(yearlyPlan) ||
    R.isNil(monthlyPlan) ||
    period !== PaymentPeriod.YEARLY ||
    plans.length < 2
  ) {
    return undefined;
  }

  const yearlyCostPaidMonthly = MONTHS_PER_YEAR * monthlyPlan.pricePerPeriodDollars;
  const actualYearlyCost = compareMonthlyWithDiscountedYearlyPrice
    ? yearlyPlan.discountedPricePerPeriodDollars ?? yearlyPlan.pricePerPeriodDollars
    : yearlyPlan.pricePerPeriodDollars;

  if (lifetimePlan) {
    return 'Most popular';
  }

  let yearlyDiscountDollars;
  if (compareYearlyOnly && yearlyPlan.discountedPricePerPeriodDollars) {
    yearlyDiscountDollars =
      yearlyPlan.pricePerPeriodDollars - yearlyPlan.discountedPricePerPeriodDollars;
  } else {
    yearlyDiscountDollars = yearlyCostPaidMonthly - actualYearlyCost;
  }

  const discountPercentage = Math.round(
    (yearlyDiscountDollars /
      (compareYearlyOnly ? yearlyPlan.pricePerPeriodDollars : yearlyCostPaidMonthly)) *
      CENTS_PER_DOLLAR,
  );

  return `save ${discountPercentage}%`;
};

export const getTrialProgressPercent = (daysLeft: number, trialDurationDays: number) => {
  const minPercent = 0.04;
  const percent = 1 - daysLeft / trialDurationDays;

  return Math.max(percent, minPercent);
};

export const hasSponsoredPlanAvailable = (plans: SubscriptionPlan[]) =>
  plans.length === 1 && RA.isNotNil(plans[0].sponsoredBy);

const paymentPeriodSingular = (paymentPeriod: PaymentPeriod) =>
  paymentPeriod === PaymentPeriod.YEARLY ? 'year' : 'month';

export const formatPricePerPeriod = (price: number, period: PaymentPeriod) => {
  const formattedPrice = formatCurrency(price);

  if (period === PaymentPeriod.LIFETIME) {
    return formattedPrice;
  }

  const suffix = paymentPeriodSingular(period);

  return `${formattedPrice} / ${suffix}`;
};

export const CANCELLATION_REASON_COPY: { [reason in CancellationReason]: string } = {
  [CancellationReason.MISSING_FEATURES]: 'Missing key features I need',
  [CancellationReason.NOT_ENOUGH_VALUE]: 'Not getting enough value for the price',
  [CancellationReason.CONNECTION_ISSUES]: 'Connection issues with my financial institutions',
  [CancellationReason.WILL_TURN_ON_LATER]:
    "Turning off auto-renew for now. I'll turn it back on later.",
  [CancellationReason.OTHER]: 'Other',
};

/** i.e. "one month free" or "50% off" */
export const formatDiscountOfferShort = (
  {
    duration,
    durationInMonths,
    discountPercent,
  }: Pick<StripeCancellationDiscountOffer, 'duration' | 'durationInMonths' | 'discountPercent'>,
  billingPeriod: PaymentPeriod,
) => {
  const discountPercentFormatted = formatPercent(discountPercent * PERCENT_TO_DECIMAL);
  const singularPeriod = paymentPeriodSingular(billingPeriod);
  const isDiscountFree = discountPercent >= 100;

  if (isDiscountFree && duration === StripeCouponDuration.ONCE) {
    return `one ${singularPeriod} free`;
  } else if (isDiscountFree && durationInMonths) {
    return `${pluralize('month', durationInMonths, true)} free`;
  } else {
    return `${discountPercentFormatted} off`;
  }
};

/** i.e. "50% off for 3 months" */
export const formatDiscountOfferLong = (
  {
    duration,
    durationInMonths,
    discountPercent,
  }: Pick<StripeCancellationDiscountOffer, 'duration' | 'durationInMonths' | 'discountPercent'>,
  billingPeriod: PaymentPeriod,
) => {
  const discountPercentFormatted = formatPercent(discountPercent * PERCENT_TO_DECIMAL);
  const singularPeriod = paymentPeriodSingular(billingPeriod);
  const discountDurationCount = duration === StripeCouponDuration.ONCE ? 1 : durationInMonths ?? 0;

  return `${discountPercentFormatted} off for ${discountDurationCount} ${pluralize(
    singularPeriod,
    discountDurationCount,
  )}`;
};
