import React, { useRef } from 'react';
import styled from 'styled-components';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';
import FlexItem from 'components/lib/ui/FlexItem';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import RecoveryCodesMfaActionButtons from 'components/settings/security/RecoveryCodesMfaActionButtons';

const Box = styled(FlexContainer)`
  border-radius: ${({ theme }) => theme.radius.small};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  padding-left: 53px;
  padding-right: 53px;
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const Code = styled(FlexItem)`
  font-family:
    Meslo LG L,
    sans-serif;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  allowNext: () => void;
  tokenArray: [string];
  loading: boolean;
};

const RecoveryCodesMfaBox = ({ allowNext, tokenArray, loading }: Props) => {
  const codesRef = useRef(null);

  return loading && !tokenArray ? (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  ) : (
    <Box column>
      <FlexContainer
        wrap
        marginTop="large"
        marginBottom="default"
        ref={codesRef}
        {...sensitiveClassProps}
      >
        {tokenArray.map((token: string) => (
          <Code key={token} basis="33%" grow={1}>
            {token}
          </Code>
        ))}
      </FlexContainer>
      <RecoveryCodesMfaActionButtons
        tokenArray={tokenArray}
        codesRef={codesRef}
        allowNext={allowNext}
      />
    </Box>
  );
};

export default RecoveryCodesMfaBox;
