import * as R from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';

import CheckCircle from 'components/lib/ui/CheckCircle';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { color } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';

const TRANSITION = css`
  transition: all 0.3s ease-out;
`;

const Root = styled(FlexContainer).attrs({ alignCenter: true })`
  position: relative;
  height: 24px;
`;

const Bar = styled.div`
  height: 8px;
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
  position: relative;

  ${isV2Theme(
    css`
      background: ${color.green};
    `,
    css`
      background: linear-gradient(
        to right,
        ${({ theme }) => theme.color.redDark} 0%,
        ${({ theme }) => theme.color.orangeDark} 100%
      );
    `,
  )}
`;

const BarCover = styled.div<{ $progress: number }>`
  position: absolute;
  background: ${({ theme }) => theme.color.grayLight};
  top: 0;
  right: 0;
  bottom: 0;
  left: ${({ $progress }) => $progress * 100}%;
  ${TRANSITION};
`;

const Tick = styled.div<{ $percent: number }>`
  background: ${({ theme }) => theme.color.white};
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3px;
  left: calc(${({ $percent }) => $percent * 100}% - 1.5px);
`;

const StyledCheckCircle = styled(CheckCircle)<{ $progress: number }>`
  background: ${isV2Theme(color.green, color.orangeDark)};
  border: 2px solid ${({ theme }) => theme.color.white};
  box-sizing: content-box;
  position: absolute;
  left: calc(
    ${({ $progress }) => $progress * 100}% -
      ${({ $progress }) => ($progress >= 1 ? '24px' : '12px')}
  );
  opacity: ${({ $progress }) => ($progress > 0 ? 1 : 0)};
  ${TRANSITION};
`;

type Props = {
  stepCount: number;
  progress: number;
  className?: string;
};

const CheckProgressBar = ({ stepCount, progress, className }: Props) => (
  <Root className={className}>
    <Bar>
      <BarCover $progress={progress} />
      {R.range(1, stepCount).map((step) => (
        <Tick key={step} $percent={step / stepCount} />
      ))}
    </Bar>
    <StyledCheckCircle iconSizePx={14} $progress={progress} />
  </Root>
);

export default CheckProgressBar;
