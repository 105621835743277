import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import GoalCustomizeModal from 'components/goalsV2/GoalCustomizeModal';
import GoalSelectAccountsModal from 'components/goalsV2/GoalSelectAccountsModal';
import Confirmation from 'components/lib/ui/Confirmation';
import Controls from 'components/lib/ui/Controls';
import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import { OverlayTrigger } from 'components/lib/ui/popover';

import { isGoalSetupIncomplete } from 'common/lib/goalsV2/adapters';
import useArchiveGoal from 'common/lib/hooks/goalsV2/useArchiveGoal';
import useDeleteGoal from 'common/lib/hooks/goalsV2/useDeleteGoal';
import useMarkGoalComplete from 'common/lib/hooks/goalsV2/useMarkGoalComplete';
import useMarkGoalIncomplete from 'common/lib/hooks/goalsV2/useMarkGoalIncomplete';
import useUnarchiveGoal from 'common/lib/hooks/goalsV2/useUnarchiveGoal';
import useModal from 'lib/hooks/useModal';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

import type { WebGoalCardFieldsFragment } from 'common/generated/graphql';

const OptionsButton = styled(DefaultButton).attrs({ size: 'small' })`
  position: relative;
  padding-right: ${({ theme }) => theme.spacing.xxsmall};
`;

type Props = {
  goal: WebGoalCardFieldsFragment;
};

const GoalDetailControls = ({ goal }: Props) => {
  const { completedAt, archivedAt, id: goalId } = goal ?? {};

  const { push } = useHistory();

  const [SelectAccountsModal, { open: openSelectAccountsModal, close: closeSelectAccountsModal }] =
    useModal();
  const [ConfirmDeleteModal, { open: openConfirmDeleteModal, close: closeConfirmDeleteModal }] =
    useModal();
  const [ConfirmArchiveModal, { open: openConfirmArchiveModal, close: closeConfirmArchiveModal }] =
    useModal();
  const [CustomizeModal, { open: openCustomizeModal }] = useModal();

  const [archiveGoal, { loading: isArchiving }] = useArchiveGoal({
    onCompleted: closeConfirmArchiveModal,
  });
  const [unarchiveGoal] = useUnarchiveGoal();
  const [markGoalComplete] = useMarkGoalComplete();
  const [markGoalIncomplete] = useMarkGoalIncomplete();
  const [deleteGoal, { loading: isDeleting }] = useDeleteGoal({
    onCompleted: () => {
      closeConfirmDeleteModal();
      push(routes.goalsV2());
    },
  });

  const isSetupIncomplete = goal ? isGoalSetupIncomplete(goal) : false;

  const showMarkComplete = Boolean(!completedAt && !archivedAt && !isSetupIncomplete);
  const showMarkIncomplete = Boolean(completedAt && !archivedAt && !isSetupIncomplete);
  const showArchiveGoal = Boolean(!archivedAt && !isSetupIncomplete);
  const showUnarchiveGoal = Boolean(archivedAt && !isSetupIncomplete);

  return (
    <>
      <Controls>
        <OverlayTrigger
          overlay={
            <DropdownMenu>
              <DropdownMenuItem onClick={openSelectAccountsModal}>Edit accounts</DropdownMenuItem>
              {showMarkComplete && (
                <DropdownMenuItem onClick={() => markGoalComplete(goalId)}>
                  Mark as complete
                </DropdownMenuItem>
              )}
              {showMarkIncomplete && (
                <DropdownMenuItem onClick={() => markGoalIncomplete(goalId)}>
                  Mark as incomplete
                </DropdownMenuItem>
              )}
              {showArchiveGoal && (
                <DropdownMenuItem onClick={openConfirmArchiveModal}>Archive goal</DropdownMenuItem>
              )}
              {showUnarchiveGoal && (
                <DropdownMenuItem onClick={() => unarchiveGoal(goalId)}>
                  Unarchive goal
                </DropdownMenuItem>
              )}
              <DropdownMenuItem type="danger" onClick={openConfirmDeleteModal}>
                Delete goal
              </DropdownMenuItem>
            </DropdownMenu>
          }
          placement="bottom-end"
        >
          {({ toggleOpen, isOpen }) => (
            <OptionsButton onClick={toggleOpen} active={isOpen}>
              Actions <ButtonIcon name="chevron-down" />
            </OptionsButton>
          )}
        </OverlayTrigger>
        <PrimaryButton onClick={openCustomizeModal}>Customize goal</PrimaryButton>
      </Controls>
      <SelectAccountsModal>
        <GoalSelectAccountsModal goalId={goalId} onClickDone={closeSelectAccountsModal} />
      </SelectAccountsModal>
      <ConfirmDeleteModal>
        <Confirmation
          title={COPY.GOALS.DELETE_CONFIRMATION.TITLE}
          confirm="Delete"
          useDangerButton
          onCancel={closeConfirmDeleteModal}
          onConfirm={() => deleteGoal(goalId)}
          isLoading={isDeleting}
        >
          {COPY.GOALS.DELETE_CONFIRMATION.DESCRIPTION}
        </Confirmation>
      </ConfirmDeleteModal>
      <ConfirmArchiveModal>
        <Confirmation
          title={COPY.GOALS.ARCHIVE_CONFIRMATION.TITLE}
          confirm="Archive"
          useDangerButton
          onCancel={closeConfirmArchiveModal}
          onConfirm={() => archiveGoal(goalId)}
          isLoading={isArchiving}
        >
          {COPY.GOALS.ARCHIVE_CONFIRMATION.DESCRIPTION}
        </Confirmation>
      </ConfirmArchiveModal>
      <CustomizeModal>
        <GoalCustomizeModal data={goal} />
      </CustomizeModal>
    </>
  );
};

export default GoalDetailControls;
